import { CountryCode } from '__generated__/graphql';

type StateObject = {
  name?: string | null;
  code?: string | null;
};

export const countryWithStateInfo: { [key: string]: string } = {
  [CountryCode.USA]: 'State',
  [CountryCode.CAN]: 'Province',
  [CountryCode.AUS]: 'State',
  [CountryCode.CHN]: 'City',
  [CountryCode.POL]: 'Province',
  [CountryCode.VNM]: 'Province',
};

export const countryWithStateList = Object.keys(countryWithStateInfo);

export const getCountryStateAbbreviation = (countryCode: CountryCode): string =>
  countryWithStateInfo[countryCode];

/* Flip label and title in China city dropdown */
export const getStateTitle = (
  countryCode: CountryCode | undefined | null,
  stateObj: StateObject,
): string => {
  if (countryCode === CountryCode.CHN || countryCode === CountryCode.POL) {
    return stateObj?.name ?? '';
  }
  return stateObj?.code ?? '';
};

export const getStateLabel = (
  countryCode: CountryCode | undefined | null,
  stateObj: StateObject,
): string => {
  const stateName = stateObj?.name ?? '';
  const stateCode = stateObj?.code ?? '';
  if (countryCode === CountryCode.CHN || countryCode === CountryCode.POL) {
    return stateCode?.split('/')[1];
  }
  return stateName;
};
