/* eslint-disable import/prefer-default-export */
import {
  FieldAccessibilityRule,
  MemberPersonalDetailsSection,
  SectionAccessibilityResponse,
} from '__generated__/graphql';

const getSectionVisibilityAndRules = (
  section: MemberPersonalDetailsSection,
  sectionRules?: Map<
    MemberPersonalDetailsSection,
    SectionAccessibilityResponse
  >,
): [
  boolean | null | undefined,
  boolean | null | undefined,
  string | null | undefined,
  Map<string, FieldAccessibilityRule>,
] => [
  sectionRules?.get(section)?.visible,
  sectionRules?.get(section)?.accessibility?.editable,
  sectionRules?.get(section)?.accessibility?.reason,
  new Map<string, FieldAccessibilityRule>(
    sectionRules
      ?.get(section)
      ?.accessibility?.rules?.map((rule) => [rule.field ?? '', rule]) ?? [],
  ),
];

export { getSectionVisibilityAndRules };
