import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { LazyQueryHookOptions } from '@apollo/client';
import { isNil } from 'lodash';

import {
  Company,
  ConditionKey,
  Contract,
  GetOrgDirectoryQuery,
  GetOrgDirectoryQueryVariables,
  Manager,
  OrgDirectoryFilters,
  useGetCompanyEntitiesLazyQuery,
  useGetOrgDirectoryLazyQuery,
} from '../__generated__/graphql';
import { OrgEmployee } from '../types';
import { formatManager, mapEmployees, rulesConfig } from '../utils/common';

interface FilterOptions {
  label: string;
  value: any;
}

export interface OrgDirectoryFilterConfig {
  key: ConditionKey;
  label: string;
  options: FilterOptions[];
}

interface Props {
  excludeContractIds?: Contract['id'][]; // Exclude specific contracts from the list
  excludeManagerIds?: Manager['id'][]; // Exclude specific managers from the list
  queryOpts?: LazyQueryHookOptions<
    GetOrgDirectoryQuery,
    GetOrgDirectoryQueryVariables
  >;
  excludeManagersWithoutContracts?: boolean;
  shouldIncludeAllCountriesFilter?: boolean;
  enableEntity?: boolean;
}

const useGetOrgDirectory = ({
  excludeContractIds = [],
  excludeManagerIds = [],
  queryOpts = {},
  excludeManagersWithoutContracts = false,
  shouldIncludeAllCountriesFilter = true,
  enableEntity = false,
}: Props = {}): {
  fetchDirectory: (
    companyId: Company['id'],
    filters?: Omit<OrgDirectoryFilters, 'companyId'>,
  ) => void;
  loading: boolean;
  employees: OrgEmployee[]; // List of all employees (normal contracts + managers)
  contracts: Contract[]; // List of normal contracts only
  managers: Manager[]; // List of managers only
  filterConfig: OrgDirectoryFilterConfig[];
  refetchDirectory: (
    companyId: Company['id'],
    filters?: Omit<OrgDirectoryFilters, 'companyId'>,
  ) => void;
  entityList: Company['otherEntities'];
} => {
  const { t } = useTranslation('hris.common');
  const { t: tCommon } = useTranslation('common');

  const [trigger, { data, loading, refetch }] = useGetOrgDirectoryLazyQuery(
    queryOpts,
  );

  const [
    triggerEntityFetch,
    { data: entityList, loading: entityFetching },
  ] = useGetCompanyEntitiesLazyQuery();

  const contracts = useMemo(
    () =>
      (data?.orgDirectory?.contracts ?? []).filter(
        (x) => !excludeContractIds.includes(x.id as string),
      ) as Contract[],
    [data],
  );

  const managers = useMemo(
    () =>
      (data?.orgDirectory?.managers ?? [])
        .filter((x) => !excludeManagerIds.includes(x.id as string))
        .map((value) => formatManager(value as Manager))
        .filter(
          (x) =>
            !(
              Boolean(excludeManagersWithoutContracts) && isNil(x.contract?.id)
            ),
        ),
    [data, excludeManagersWithoutContracts],
  );

  const employees = useMemo(() => mapEmployees(contracts, managers), [
    contracts,
    managers,
  ]);

  const fetchDirectory = (
    companyId: Company['id'],
    filters = {} as Omit<OrgDirectoryFilters, 'companyId'>,
  ) => {
    if (!companyId) return;
    trigger({
      variables: {
        filters: {
          companyId,
          ...filters,
        },
      },
    });
    if (!enableEntity) return;
    triggerEntityFetch({
      variables: {
        id: companyId,
      },
    });
  };

  const refetchDirectory = (
    companyId: Company['id'],
    filters = {} as Omit<OrgDirectoryFilters, 'companyId'>,
  ) => {
    if (!companyId) return;
    refetch({
      filters: {
        companyId,
        ...filters,
      },
    });
  };

  const filterConfig = useMemo(
    () =>
      rulesConfig({
        t,
        tCommon,
        employees,
        entityList: entityList?.company?.otherEntities,
        shouldIncludeAllCountriesFilter,
      }),
    [employees, shouldIncludeAllCountriesFilter, entityList],
  );

  return {
    fetchDirectory,
    loading: loading || entityFetching,
    employees,
    filterConfig,
    contracts,
    managers,
    refetchDirectory,
    entityList: entityList?.company?.otherEntities,
  };
};

export default useGetOrgDirectory;
