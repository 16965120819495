import { useTranslation } from 'react-i18next';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  ContractOffboardMutationVariables,
  ContractType,
  useContractOffboardMutation,
} from '__generated__/graphql';

export default (): {
  contractOffboardLoading: boolean;
  onSubmit: (values: ContractOffboardMutationVariables) => void;
} => {
  const { t } = useTranslation('team.company');
  const [contractOffboard, { loading }] = useContractOffboardMutation({
    onCompleted: (data) => {
      if (data.contractOffboard?.type === ContractType.FREELANCER) {
        successNotification(
          '',
          t(
            'offboard-freelancer-dialog.notification.success',
            'Freelancer moved to offboarding',
          ),
          false,
        );
      } else {
        successNotification(
          '',
          t(
            'offboard-member-dialog.notification.success',
            'Member moved to offboarding',
          ),
          false,
        );
      }
    },
    onError: () => {
      errorNotification(
        '',
        t(
          'offboard-dialog.notification.failure',
          'Could not move to offboarding',
        ),
        false,
      );
    },
  });

  const onSubmit = async (values: ContractOffboardMutationVariables) => {
    await contractOffboard({ variables: { ...values } });
  };

  return {
    contractOffboardLoading: loading,
    onSubmit,
  };
};
