import { TimeOffUnit } from '../__generated__/graphql';

export const TimeOffUnitOrders: TimeOffUnit[] = [
  TimeOffUnit.DAYS,
  TimeOffUnit.WEEKS,
  TimeOffUnit.MONTHS,
  TimeOffUnit.YEARS,
];

export interface TimeOffConvertFunctionForUnit {
  convertNext?: (value: number) => number;
  convertBack?: (value: number) => number;
  nextUnit?: TimeOffUnit;
  prevUnit?: TimeOffUnit;
}

export const TimeOffConvertFunctions: Record<
  TimeOffUnit,
  TimeOffConvertFunctionForUnit
> = {
  [TimeOffUnit.DAYS]: {
    convertNext: (value: number) => value / 7,
    nextUnit: TimeOffUnit.WEEKS,
  },
  [TimeOffUnit.WEEKS]: {
    convertNext: (value) => value / 4,
    nextUnit: TimeOffUnit.MONTHS,
    convertBack: (value) => value * 7,
    prevUnit: TimeOffUnit.DAYS,
  },
  [TimeOffUnit.MONTHS]: {
    convertNext: (value) => value / 12,
    nextUnit: TimeOffUnit.YEARS,
    convertBack: (value) => value * 4,
    prevUnit: TimeOffUnit.WEEKS,
  },
  [TimeOffUnit.YEARS]: {
    convertBack: (value) => value * 12,
    prevUnit: TimeOffUnit.MONTHS,
  },
};

export const convertTimeUnit = (
  value: number,
  fromUnit: TimeOffUnit,
  toUnit: TimeOffUnit,
): number => {
  const unitOrderDiff =
    TimeOffUnitOrders.findIndex((unit) => unit === fromUnit) -
    TimeOffUnitOrders.findIndex((unit) => unit === toUnit);

  let result = value;
  let currentUnit = fromUnit;

  if (unitOrderDiff > 0) {
    while (currentUnit !== toUnit) {
      const unitLogic = TimeOffConvertFunctions[currentUnit];
      if (unitLogic.prevUnit && unitLogic.convertBack) {
        result = unitLogic.convertBack(result);
        currentUnit = unitLogic.prevUnit;
      } else return result;
    }
  } else if (unitOrderDiff < 0) {
    while (currentUnit !== toUnit) {
      const unitLogic = TimeOffConvertFunctions[currentUnit];
      if (unitLogic.nextUnit && unitLogic.convertNext) {
        result = unitLogic.convertNext(result);
        currentUnit = unitLogic.nextUnit;
      } else return result;
    }
  }

  return result;
};

export default {};
