import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { Contract, Member } from '../../../__generated__/graphql';
import useDeleteDialog from '../../hooks/delete-dialog';
import { ChecklistSectionHeader } from './layout';

const DeleteContractAction: React.FC<{
  contractId: Contract['id'];
  memberId: Member['id'];
  memberName: string;
  handlePostDeleteConfirmation?: () => void;
  setSecondaryDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  contractId,
  memberId,
  memberName,
  handlePostDeleteConfirmation,
  setSecondaryDialogOpen,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding');

  const { handleDeleteClick, Dialog: DeleteDialog } = useDeleteDialog(
    handlePostDeleteConfirmation,
    setSecondaryDialogOpen,
  );

  return (
    <div tw="flex flex-row items-center justify-between rounded-base border border-border-primary p-base">
      <div tw="flex flex-col gap-y-2">
        <ChecklistSectionHeader tw="text-text-negative">
          {t('onboarding-checklist.delete-contract.header', 'Delete contract')}
        </ChecklistSectionHeader>
        <p tw="text-ps text-text-tertiary">
          {t(
            'onboarding-checklist.delete-contract.description',
            'Permanently remove contract from the platform',
          )}
        </p>
      </div>
      <IconButton
        variant="outline"
        size="medium"
        name="bin"
        fill={!isNewThemeApplied ? 'transparent' : undefined}
        stroke={!isNewThemeApplied ? theme`colors.icon-negative` : undefined}
        tw="border-border-negative-subtle"
        iconProps={tw`h-base w-base`}
        onClick={() => {
          setSecondaryDialogOpen(true);
          handleDeleteClick({ contractId, memberId, memberName });
        }}
      />
      <DeleteDialog />
    </div>
  );
};

export default DeleteContractAction;
