import { gql } from '@apollo/client';

// TODO - Needs to be changed once API contracts are confirmed
export default gql`
  mutation ResetToken {
    resetToken(input: {})
      @rest(
        type: "JwtResponse"
        method: "POST"
        path: "reset/token"
        endpoint: "user"
      ) {
      id_token
    }
  }
`;
