/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Button } from '@multiplier/common';
import tw, { styled } from 'twin.macro';

import Loader from 'common/components/loader';

import { Contract, useGetConfigurationQuery } from '__generated__/graphql';

import { ContractPageType } from '../progress-widget-content/view-send-contract-action';
import NewStartDateMessage from './new-start-date-message';

interface RequestChangesActionProps {
  setContractModalPageType?: React.Dispatch<
    React.SetStateAction<ContractPageType>
  >;
  contractBlocked?: boolean;
  contractId: Contract['id'];
}

const MainContainer = styled.div<{ is3DTATFeatureOn?: boolean }>(
  ({ is3DTATFeatureOn }) => [
    tw`flex flex-row py-32 px-32 border-t-[1px] border-grey03 w-[824px] justify-between gap-12 bg-white fixed bottom-0 z-10`,
    !is3DTATFeatureOn && tw`relative pb-0`,
  ],
);

const TextContainer = tw.div`
     flex flex-col
`;

const Heading = tw.span`
flex font-semibold text-p text-background
`;

const SubHeading = tw.span`
flex flex-col justify-between gap-12 font-medium text-ps text-grey01
`;

const RequestChangesAction: React.FC<RequestChangesActionProps> = ({
  setContractModalPageType,
  contractBlocked,
  contractId,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { data } = useGetConfigurationQuery({
    variables: {
      filters: {
        contractId,
      },
    },
  });
  const is3DTATFeatureOn = useFeature('3d-tat-offline-contracts')?.on;

  const tatInHours =
    data?.configuration?.contractChangeRequestConfig?.turnAroundTimeInHours;
  const getTatTimeInDays = tatInHours && tatInHours / 24;

  return (
    <MainContainer is3DTATFeatureOn={is3DTATFeatureOn}>
      <TextContainer>
        <Heading>
          {t(
            'request-changes-action.heading',
            'Require assisstance in making changes to the contract?',
          )}
        </Heading>
        {getTatTimeInDays == null ? (
          <Loader.Spinner
            variant={Loader.SpinnerType.CUSTOM_LAYOUT}
            tw="h-[100%]"
          />
        ) : (
          <SubHeading>
            <div>
              <Trans t={t} i18nKey="request-changes-action.sub-heading">
                Any changes requested will require
                <span tw="text-primaryBlue800 mx-4">
                  minimum {{ getTatTimeInDays }} working days
                </span>{' '}
                to implement.
              </Trans>
            </div>
            <NewStartDateMessage contractId={contractId} />
          </SubHeading>
        )}
      </TextContainer>
      <Button
        variant="outline"
        size="medium"
        disabled={contractBlocked}
        onClick={() =>
          setContractModalPageType &&
          setContractModalPageType(ContractPageType.REQUEST_CHANGE_PAGE)
        }
        css={{ minWidth: 'fit-content' }}
      >
        {t(
          'onboarding-phase.contract-viewer.request-changes.request',
          'Request Changes',
        )}
      </Button>
    </MainContainer>
  );
};

const hideWithoutRequestChangesFeatureOnHOC = (
  Component: typeof RequestChangesAction,
) => (props: React.ComponentProps<typeof RequestChangesAction>) => {
  const isRequestChangesFeatureOn = useFeature('request-changes')?.on;

  if (!isRequestChangesFeatureOn) return null;

  return <Component {...props} />;
};

export default hideWithoutRequestChangesFeatureOnHOC(RequestChangesAction);
