import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { SideModal } from '@multiplier/common';
import tw, { css } from 'twin.macro';

import { Contract } from '../../../__generated__/graphql';
import useProcessedTasks from '../../hooks/processed-tasks';
import OnboardingTasks from '../onboarding-tasks';
import DeleteContractAction from './delete-contract-action';
import DueDateCard from './due-date-card';

const OnboardingChecklistSidePanel: React.FC<{
  open: boolean;
  onClose: () => void;
  contractId: Contract['id'];
}> = ({ open, onClose, contractId }) => {
  const { t } = useTranslation('contract-onboarding');

  const {
    processedTasks,
    unprocessedTasks,
    counts: { completed: completedCount, pending: pendingCount },
    memberId,
    memberName,
    payrollCutoffDate,
    complianceType,
    workStatus,
    contractType,
  } = useProcessedTasks(contractId);

  const [secondaryDialogOpen, setSecondaryDialogOpen] = useState(false);

  return (
    <SideModal
      open={open}
      onCloseClick={onClose}
      titleElement={
        <p
          css={[
            tw`text-h6 text-text-primary font-semibold`,
            css`
              inline-size: 448px;
              overflow-wrap: break-word;
            `,
          ]}
        >
          {t('onboarding-checklist.title', {
            defaultValue: 'Onboarding checklist for {{ memberName }}',
            replace: { memberName },
          })}
        </p>
      }
      isEscapeable={!secondaryDialogOpen}
    >
      <div tw="flex flex-col p-base gap-y-large max-h-[calc(100vh - 60px)] overflow-y-auto">
        <DueDateCard payrollCutoffDate={payrollCutoffDate} />
        <OnboardingTasks
          contractId={contractId ?? ''}
          processedTasks={processedTasks}
          unprocessedTasks={unprocessedTasks}
          completedCount={completedCount}
          pendingCount={pendingCount}
          complianceType={complianceType}
          workStatus={workStatus}
          contractType={contractType}
          handleCloseSidePanel={onClose}
        />
        {/* <ContractActions
          contractId={contractId}
          handleCloseSidePanel={onClose}
        /> */}
        <DeleteContractAction
          contractId={contractId}
          memberId={memberId}
          memberName={memberName}
          handlePostDeleteConfirmation={onClose}
          setSecondaryDialogOpen={setSecondaryDialogOpen}
        />
      </div>
    </SideModal>
  );
};

export default OnboardingChecklistSidePanel;
