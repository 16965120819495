/* eslint-disable import/prefer-default-export */
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';
import { UseGetAdditionalCompensationsMapperResult } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Compensation,
  Contract,
  ContractType,
  CurrencyCode,
  PayFrequency,
  PayType,
  RateFrequency,
} from '__generated__/graphql';

import {
  getFirstPayout,
  getSecondPayout,
  mapPayFrequencyDateToFormValues,
} from './helpers';

const getPayType = (
  contractType: Contract['type'],
  payType: Compensation['payType'],
) =>
  payType ??
  (contractType &&
  [ContractType.FREELANCER, ContractType.CONTRACTOR].includes(contractType)
    ? PayType.DYNAMIC
    : null);

export const mapToCompensationForm = (
  contractType: Contract['type'],
  compensation: Contract['compensation'],
  compliantCurrencies: Contract['currency'][],
  additionalCompensationsMapperResult: UseGetAdditionalCompensationsMapperResult,
): CompensationFormValues => {
  let currency = compensation?.basePay?.currency;

  if (!compliantCurrencies.includes(currency) && compliantCurrencies?.[0]) {
    if (compliantCurrencies.includes(CurrencyCode.USD)) {
      currency = CurrencyCode.USD;
    } else {
      [currency] = compliantCurrencies;
    }
  }

  return {
    payType: getPayType(contractType, compensation?.payType),
    basePay: {
      name: compensation?.basePay?.name ?? '',
      frequency: compensation?.basePay?.frequency ?? RateFrequency.MONTHLY,
      rateType: compensation?.basePay?.rateType,
      currency,
      amount: compensation?.basePay?.amount,
      paymentFrequency:
        compensation?.basePay?.paymentFrequency ?? PayFrequency.MONTHLY,
      paymentFrequencyDate:
        mapPayFrequencyDateToFormValues(
          compensation?.basePay?.paymentFrequencyDate,
        ) ?? undefined,
      firstPayoutDate: getFirstPayout(
        compensation?.basePay?.paymentFrequency,
        compensation?.basePay?.paymentFrequencyDate,
      ),
      secondPayoutDate: getSecondPayout(
        compensation?.basePay?.paymentFrequency,
        compensation?.basePay?.paymentFrequencyDate,
      ),
    },
    payrollStart: compensation?.payrollStart,
    esop: undefined,
    additionalPays: additionalCompensationsMapperResult.mapToAdditionalPayFormValues(
      compensation?.additionalPays,
    ),
  } as CompensationFormValues;
};
