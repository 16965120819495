/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, DropdownValue, Maybe } from '@multiplier/common';

import 'twin.macro';

import { assureNumber } from 'app/utils/number';
import currencyCountry from 'common/constants/currency-country';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Contract,
  CountryCode,
  CurrencyCode,
  DeductionDefinition,
  DeductionUnit,
  DeductionValue,
  FixedDeductionRequirement,
} from '__generated__/graphql';

import CurrencyView from '../currency-view';
import { DeductionConstraintFromQuery } from '../types';

const constraintDefaultLabel: { [key: string]: string } = {
  employerProvidentFund: "Employer's Provident Fund",
};

type EmployerProvidentFundComponent = React.FC<{
  groupKey: DeductionDefinition['groupKey'];
  deductionDefinitionId: string;
  constraint: DeductionConstraintFromQuery;
  currencyOptions: DropdownValue[];
  countryCode?: Contract['country'];
  postProbationBaseSalary?: number;
}>;

const getDropdownValueStringFromDeductionValue = (
  deductionValue: DeductionValue,
): string => `${deductionValue.value}:${deductionValue.unit}`;

const getDeductionValueFromDropdownValueString = (
  dropdownValueString: string,
): DeductionValue => {
  const [dropdownValue, dropdownUnit] = dropdownValueString.split(':');
  return {
    value: assureNumber(dropdownValue),
    unit: dropdownUnit as DeductionUnit,
  };
};

const shouldBeRemovedByIndiaPFRule = (
  countryCode?: Maybe<CountryCode>,
  postProbationBaseSalary = 0,
  unit?: DeductionUnit,
) =>
  countryCode === CountryCode.IND &&
  postProbationBaseSalary < 15000 &&
  unit === DeductionUnit.PERCENTAGE;

const useGetEmployerMatchOptions = (
  fixedDeduction: FixedDeductionRequirement,
  currencyOptions: DropdownValue[],
  constraint: DeductionConstraintFromQuery,
  countryCode?: Maybe<CountryCode>,
  postProbationBaseSalary = 0,
) => {
  const { t } = useTranslation('contract-onboarding.company');

  const unitLabels: { [key: string]: string } = {
    [DeductionUnit.FIXED_AMOUNT_PER_MONTH]: t(
      `deduction.requirement.unit.${DeductionUnit.FIXED_AMOUNT_PER_MONTH}`,
      `/Month`,
    ),
    [DeductionUnit.PERCENTAGE]: t(
      `deduction.requirement.unit.${DeductionUnit.PERCENTAGE}`,
      `% of Basic Pay`,
    ),
  };

  return useMemo<DropdownValue[]>(
    () =>
      fixedDeduction.options
        ?.filter(
          (option) =>
            !shouldBeRemovedByIndiaPFRule(
              countryCode,
              postProbationBaseSalary,
              option?.unit,
            ),
        )
        ?.filter((option) => option && option.value && option.unit)
        ?.map((option) => {
          const { value, unit } = option as DeductionValue;
          const title = `${value}${unit ? unitLabels[unit] : ''}`;
          const currencyOption = currencyOptions?.[0];
          const dropdownOption = {
            title,
            value: getDropdownValueStringFromDeductionValue(
              option as DeductionValue,
            ),
          } as DropdownValue;

          if (unit === DeductionUnit.FIXED_AMOUNT_PER_MONTH) {
            dropdownOption.preTitle = currencyOption &&
              currencyCountry[currencyOption.value] && (
                <CurrencyView
                  currencyCode={currencyOption.value as CurrencyCode}
                />
              );
            if (countryCode === CountryCode.IND) {
              dropdownOption.preTitle = null;
              dropdownOption.title = t(
                'deduction.india-pf-fixed-amount.title',
                '12% of Basic Pay capped at 1800/Month',
              );
            }
          }

          return dropdownOption;
        }) || [],
    [constraint, postProbationBaseSalary],
  );
};

const EmployerProvidentFund: EmployerProvidentFundComponent = ({
  groupKey,
  deductionDefinitionId,
  constraint,
  currencyOptions,
  countryCode,
  postProbationBaseSalary = 0,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const fixedDeduction = constraint as FixedDeductionRequirement;

  const { control, setValue, watch } = useFormContext<CompensationFormValues>();
  const currentDeductionValue = watch(`deductions.${deductionDefinitionId}`);

  const employerMatchOptions = useGetEmployerMatchOptions(
    fixedDeduction,
    currencyOptions,
    constraint,
    countryCode,
    postProbationBaseSalary,
  );

  useEffect(() => {
    if (!employerMatchOptions?.[0]) {
      return;
    }

    const matchedOption = employerMatchOptions.find(
      (option) =>
        currentDeductionValue &&
        option.value ===
          getDropdownValueStringFromDeductionValue(currentDeductionValue),
    );

    if (!matchedOption) {
      setValue(
        `deductions.${deductionDefinitionId}`,
        getDeductionValueFromDropdownValueString(employerMatchOptions[0].value),
      );
    }
  }, [employerMatchOptions, currentDeductionValue]);

  return (
    <div tw="w-full">
      <span tw="flex">
        <p tw="text-ps text-text-primary font-medium">
          {t(
            `deduction.requirement.${groupKey}.title.${constraint?.name}`,
            constraintDefaultLabel[constraint?.name ?? 'employerProvidentFund'],
          )}
        </p>
      </span>
      <div tw="max-w-[21rem] my-small">
        <Controller
          name={`deductions.${deductionDefinitionId}`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <ComboBox
              variant="default"
              dropdownValues={employerMatchOptions}
              showArrow
              value={
                value ? getDropdownValueStringFromDeductionValue(value) : ''
              }
              placeholder=""
              onChange={(onChangeValue) =>
                onChange(
                  getDeductionValueFromDropdownValueString(onChangeValue),
                )
              }
            />
          )}
        />
      </div>
    </div>
  );
};

export default EmployerProvidentFund;
