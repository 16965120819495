import { TFunction } from 'i18next';
import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import * as yup from 'yup';
import { NumberSchema, StringSchema } from 'yup';
import Lazy from 'yup/lib/Lazy';

import { notEmpty } from 'app/utils/array';
import { dateAsString } from 'app/utils/format';
import { getDeductionSchema } from 'contract-onboarding/company/services/deduction';

import {
  DataFieldDefinition,
  DeductionDefinition,
  DropDownTextField,
  LegalData,
  Maybe,
  TextField,
} from '__generated__/graphql';

import {
  LegalDataRequirementField,
  LegalDetailsFormParams,
} from '../member/pages/onboarding/pages/legal-details';

export const groupLegalDataParamDefinitionByKey = (
  data?: Maybe<Array<Maybe<DataFieldDefinition>>>,
): { [key: string]: DataFieldDefinition } =>
  data
    ?.filter(notEmpty)
    .reduce(
      (
        prev: { [key: string]: DataFieldDefinition },
        curr: DataFieldDefinition,
      ) => {
        if (curr?.key) prev[curr.key] = curr;
        return prev;
      },
      {},
    ) ?? {};

export const checkIfAnyControllingFieldsAreUnchecked = (
  requirement?: DataFieldDefinition | null,
  context?: LegalDetailsFormParams,
): boolean =>
  requirement?.dependsOn?.every(
    (controllingField) =>
      context?.legalData.find(
        (legalData: LegalDataRequirementField) =>
          legalData.key === controllingField.key,
      )?.value === 'false',
  ) ?? false;

export const getLegalFieldSchema = (
  t: TFunction,
  requirement?: DataFieldDefinition | null,
  // Lazy builder context is not yet typed properly. In fact, many of the
  // contexts are simply typed as `any` in the dist folder from @types/yup
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  context?: any,
): StringSchema<string | null | undefined> | NumberSchema => {
  if (
    checkIfAnyControllingFieldsAreUnchecked(
      requirement,
      context?.from?.[1]?.value,
    )
  )
    return yup.string().nullable();

  if (
    requirement?.dataType?.__typename === 'TextField' ||
    requirement?.dataType?.__typename === 'DropDownTextField'
  ) {
    // test is used because yup.number() fails to transform to a numeric value
    return (
      yup
        .string()
        .nullable()
        .test(
          'is-required',
          t('legal-data.validation.required', 'Input is required'),
          (val) => (requirement?.required ? !!val : true),
        )
        // FIXME: Temporary disable this validation; need to check and ensure it's working properly
        // .test(
        //   'is-text',
        //   t(
        //     'legal-data.validation.text',
        //     'Input should contains valid letters only',
        //   ),
        //   (val) => {
        //     if (
        //       (requirement?.dataType as TextField | DropDownTextField)?.type !==
        //       'TEXT'
        //     )
        //       return true;
        //     return /^[a-zA-Z\s]*$/.test(val ?? '');
        //   },
        // )
        .test(
          'is-numeric',
          t('legal-data.validation.number', 'Input should be a numeric value'),
          (val) => {
            if (
              (requirement?.dataType as TextField | DropDownTextField)?.type !==
              'NUMERIC'
            )
              return true;
            return !Number.isNaN(Number(val));
          },
        )
        .test(
          'is-whole-number',
          t('legal-data.validation.integer', 'Input should be a whole number'),
          (val) => {
            if (
              (requirement?.dataType as TextField | DropDownTextField)?.type !==
              'WHOLE_NUMBER'
            )
              return true;
            return (
              !Number.isNaN(Number(val)) && /^(0|\+?[1-9]\d*)$/.test(val ?? '')
            );
          },
        )
        .test(
          'is-alphanumeric',
          t(
            'legal-data.validation.alphanumeric',
            'Input should be alphanumeric',
          ),
          (val) => {
            if (
              (requirement?.dataType as TextField | DropDownTextField)?.type !==
              'ALPHANUMERIC'
            )
              return true;
            return /^[a-zA-Z\d]*$/.test(val ?? '');
          },
        )
        .min(
          requirement?.dataType?.minLength ?? 0,
          t('legal-data.validation.min', 'Input is too short'),
        )
        .max(
          requirement?.dataType?.maxLength ?? Number.MAX_VALUE,
          t('legal-data.validation.max', 'Input is too long'),
        )
    );
  }

  if (requirement?.dataType?.__typename === 'DropDownField') {
    return yup
      .string()
      .nullable()
      .test(
        'is-required',
        t('legal-data.validation.required', 'Input is required'),
        (val) => (requirement?.required ? !!val : true),
      )
      .test(
        'is-one-of',
        t(
          'legal-data.validation.one-of',
          'Input needs to be one of possible values',
        ),
        (val) =>
          requirement?.dataType?.__typename === 'DropDownField' &&
          requirement.dataType.values &&
          val
            ? requirement.dataType.values.includes(val)
            : true,
      );
  }

  if (requirement?.dataType?.__typename === 'CheckboxField') {
    return yup
      .string()
      .nullable()
      .test(
        'is-required',
        t('legal-data.validation.required', 'Input is required'),
        (val) =>
          requirement?.required
            ? !!val && ['true', 'false'].includes(val)
            : true,
      );
  }

  return yup.string().nullable();
};

export const getLegalDataSchema = (
  legalData: {
    [p: string]: DataFieldDefinition;
  },
  t: TFunction,
  deductionDefinitions?: Maybe<DeductionDefinition[]>,
): yup.ObjectSchema<{
  legalData: yup.ArraySchema<
    yup.ObjectSchema<{
      key: yup.StringSchema;
      value: Lazy<
        yup.StringSchema<string | null | undefined> | yup.NumberSchema
      >;
      description: Lazy<yup.StringSchema<string | null | undefined>>;
    }>
  >;
}> =>
  yup.object().shape({
    legalData: yup.array().of(
      yup.object().shape({
        key: yup.string().required(),
        value: yup.lazy((_value, ctx) =>
          getLegalFieldSchema(t, legalData?.[ctx.parent.key], ctx),
        ),
        description: yup.lazy((_value, ctx) => {
          if (
            legalData?.[ctx?.parent?.key]?.dataType?.__typename ===
            'DropDownTextField'
          )
            return yup
              .string()
              .nullable()
              .required(
                t(
                  'legal-data.validation.identifier-required',
                  'Identifier is required',
                ),
              );
          return yup.string().nullable();
        }),
      }),
    ),
    deductions: getDeductionSchema(deductionDefinitions),
  });

export const memberLegalDataMap = (
  legalData: Maybe<
    {
      __typename?: 'LegalData' | undefined;
    } & Pick<LegalData, 'country' | 'key' | 'value' | 'label' | 'identifier'>
  >[],
): { [key: string]: LegalData } =>
  flow(compact, keyBy<LegalData>('key'))(legalData);

export const getFormattedValue = (
  formValue: Maybe<string> | undefined,
  legalData: DataFieldDefinition,
  t: TFunction,
): Maybe<string> | undefined => {
  if (formValue && legalData?.dataType?.__typename === 'DateField') {
    return dateAsString(formValue);
  }

  if (legalData.dataType?.__typename === 'CheckboxField') {
    return formValue === 'true'
      ? t('legal-details.checkbox.yes', 'Yes')
      : formValue === 'false'
      ? t('legal-details.checkbox.no', 'No')
      : '';
  }

  return formValue;
};
