import React from 'react';

import type { ColumnDef } from '@tanstack/react-table';

import Checkbox from '../../checkbox';

const getSelectColumnDefinition = <TData extends unknown>(): ColumnDef<
  TData,
  any
> => ({
  id: 'select-col',
  header: ({ table }) => (
    <Checkbox
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      onChange={row.getToggleSelectedHandler()}
    />
  ),
});

export default getSelectColumnDefinition;
