import React from 'react';

import { useTranslation } from 'react-i18next';

import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const PayrollFormsTask: React.FC<TaskEntryProps> = ({ task }) => {
  const { t } = useTranslation('contract-onboarding');

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.payroll-forms.title',
        'Mandatory payroll & compliance forms',
      )}
      completed={task.completed}
    />
  );
};

export default PayrollFormsTask;
