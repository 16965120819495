import React from 'react';

import tw, { styled } from 'twin.macro';

export type OptionList = { value: string | number; label: string }[];

export interface DropdownProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  touched?: boolean;
  optionList: OptionList;
}

export const BaseDropdown = React.forwardRef<HTMLSelectElement, DropdownProps>(
  ({ optionList, placeholder, ...props }, ref) => (
    <select ref={ref} {...props} defaultValue={placeholder && 'DEFAULT'}>
      <option value="DEFAULT" disabled>
        {placeholder}
      </option>
      {optionList.map(({ label, value: optionValue }) => (
        <option key={optionValue} value={optionValue}>
          {label}
        </option>
      ))}
    </select>
  ),
);

const Dropdown = styled(BaseDropdown)(({ touched }) => [
  tw`p-small rounded-tiny text-ps border border-border-primary`,
  tw`focus:ring-transparent focus:outline-none`,
  touched ? tw`text-text-primary` : tw`text-text-tertiary`,
]);

export default Dropdown;
