import { Company } from './__generated__/graphql';

export enum Experience {
  COMPANY = 'company',
  MEMBER = 'member',
  PARTNER = 'partner',
  OPERATIONS = 'operations',
  DEVELOPER = 'developer',
}

export interface User {
  email?: string;
  firstName?: string;
  imageUrl?: string;
  langKey?: string;
  id?: number;
  lastName?: string;
  permissions?: string[];
  experiences: { current: Experience; authorized: Experience[] };
  // TODO: Move module configuration outside of User context as it should be workspace specific
  modules: any[];
  companyList?: Pick<Company, 'id' | 'displayName' | 'companyLogo'>[];
  selectedCompany?: Pick<Company, 'id' | 'displayName' | 'companyLogo'>;
  defaultCompanyId?: Company['id'];
  isManager?: boolean;
}

export type JwtToken = {
  experiences: string;
  auth: string;
  sub: string;
  exp: string;
  details: {
    langKey: string;
    imageUrl: string;
    firstName: string;
    id: number;
    lastName: string;
  };
};
