/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, DatePicker, TextInput } from '@multiplier/common';
import tw from 'twin.macro';

import timezoneRemove from 'timesheets/services/timezone-remove';

import { Contract, ContractType, RateFrequency } from '__generated__/graphql';

import { AdditionalPayFormValues, frequencyOptions } from '../../index';

const VariablePerformanceBonusFields: React.FC<{
  maxDate?: Date | string;
  minDate?: Date | string;
  contractType?: Contract['type'];
}> = ({ minDate, maxDate, contractType }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const {
    control,
    register,
    setValue,
    watch,
  } = useFormContext<AdditionalPayFormValues>();

  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  const frequencyOptionsWithoutOnce = frequencyOptions.filter(
    ({ value }) => value !== RateFrequency.ONCE,
  );
  return (
    <div css={[tw`grid grid-cols-2 gap-base mobile:grid-cols-1`]}>
      <TextInput.Container>
        {isNotFreelancer ? (
          <TextInput.Label>
            {t('additional-pay.frequency.label-feature-on', 'Frequency')}
          </TextInput.Label>
        ) : (
          <TextInput.Label>
            {t('additional-pay.frequency.label', 'Frequency (Every-)')}
          </TextInput.Label>
        )}
        {isNotFreelancer ? (
          <Controller
            name="frequency"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                variant="default"
                data-testid="allowance-pay-frequency-selector"
                disabled={frequencyOptionsWithoutOnce?.length === 1}
                dropdownValues={frequencyOptionsWithoutOnce ?? []}
                value={(value as string) ?? ''}
                onChange={(v) => {
                  onChange(v);
                }}
                placeholder={t('pay-frequency.placeholder', 'Select')}
              />
            )}
          />
        ) : (
          <TextInput
            data-testid="pay-frequency"
            type="number"
            placeholder="0"
            units={t('additional-pay.frequency.units', 'Months')}
            {...register('frequency')}
          />
        )}
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label>
          {t('additional-pay.payout-month.effective-from', 'Effective from')}
        </TextInput.Label>
        <DatePicker
          monthSelector
          value={
            watch('payoutMonth.month') + 1 && watch('payoutMonth.year')
              ? timezoneRemove(
                  `${watch('payoutMonth.year')}-${
                    watch('payoutMonth.month') + 1
                  }-1`,
                  true,
                )
              : null
          }
          min={minDate}
          max={maxDate}
          onChange={(v) => {
            setValue('payoutMonth.month', timezoneRemove(v, true).getMonth(), {
              shouldValidate: true,
            });
            setValue(
              'payoutMonth.year',
              timezoneRemove(v, true).getFullYear(),
              {
                shouldValidate: true,
              },
            );
          }}
          placeholder={t('additional-pay.payout-month.placeholder', 'Month')}
          rightAlign
        />
      </TextInput.Container>
    </div>
  );
};

export default VariablePerformanceBonusFields;
