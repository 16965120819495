import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const visaApplicationPreparing: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-workflow.visa-application-preparing.title', {
      defaultValue: 'Visa Application Preparing',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-workflow.visa-application-preparing.description', {
      defaultValue:
        'Reviewing and collecting additional data for Visa application',
      replace: {
        firstName,
      },
      context: gender,
    }),
  pendingOn: () => PendingOn.EMPLOYEE,
  pendingLabel: (t): string =>
    t(
      'onboarding-workflow.visa-application-preparing.status',
      'Pending on Member',
    ),
  statusLabel: (t) =>
    t(
      'onboarding-workflow.visa-application-preparing.label',
      'Member submitted visa documents',
    ),
};

export default visaApplicationPreparing;
