import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import {
  Checkbox,
  CheckboxLabel,
  Dialog,
  Loader,
  SpinnerType,
  useModalStatic,
} from '@multiplier/common';
import { merge } from 'lodash';
import concat from 'lodash/concat';
import tw from 'twin.macro';

import {
  Condition,
  ConditionKey,
  Contract,
  Manager,
  OrgDirectoryFilters,
  Rule,
  RuleType,
} from '../../__generated__/graphql';
import {
  OrgDirectoryFilterConfig,
  useGetCompanyBasicDetails,
  useGetOrgDirectory,
} from '../../hooks';
import {
  collectCountryOptions,
  collectEntityOptions,
  collectOptionsFromContracts,
  getEmptyFilterConfig,
  mapOptionsConfigToOrgDirectoryFilterConfig,
} from '../../utils/common';
import { filterContractsByConditions } from '../../utils/filters';
import AppliedRulesSection from './applied-rules-section';
import RulesDialog from './rules-dialog';

interface Props {
  title: string;
  show: boolean;
  onClose: () => void;
  onConfirm: (payload: {
    rules: Rule;
    contracts: Contract[];
    managers: Manager[];
    membersBelongsToAEntity: Contract[];
  }) => void;
  initialRules?: Rule;
  loading?: boolean;
  confirmDisabled?: boolean;
  currentManagerId?: Manager['id'];
  currentContractId?: Contract['id'];
  excludeManagersWithoutContracts?: boolean;
  orgDirectoryFilters?: Omit<OrgDirectoryFilters, 'companyId'>;
  enableEntity?: boolean;
}

const AddEmployeesDialog: React.FC<Props> = ({
  title,
  show,
  onClose,
  onConfirm,
  initialRules,
  loading,
  currentContractId,
  currentManagerId,
  confirmDisabled = false,
  excludeManagersWithoutContracts = false,
  orgDirectoryFilters,
  enableEntity = false,
}) => {
  const { t } = useTranslation('hris.common');
  const { t: tCommon } = useTranslation('common');

  const { fetchCompanyDetails, companyDetails } = useGetCompanyBasicDetails();
  const {
    fetchDirectory,
    loading: fetchDirectoryLoading,
    contracts,
    managers,
    entityList,
  } = useGetOrgDirectory({
    excludeContractIds: currentContractId ? [currentContractId] : [],
    excludeManagerIds: currentManagerId ? [currentManagerId] : [],
    excludeManagersWithoutContracts,
    enableEntity,
  });

  const managerContracts = managers.map((item) => item.contract as Contract);
  const allContracts = concat(contracts, managerContracts);

  const orgDirectoryFilterConfig = useMemo(
    () =>
      mapOptionsConfigToOrgDirectoryFilterConfig(
        merge(
          collectOptionsFromContracts({
            t,
            employeeContracts: allContracts,
            entityList,
          }),
          collectCountryOptions({
            tCommon,
            optionsConfig: getEmptyFilterConfig(t),
          }),
          collectEntityOptions({
            optionsConfig: getEmptyFilterConfig(t),
            entityList,
          }),
        ),
      ),
    [allContracts, entityList],
  );

  const openModal = useModalStatic();

  const [allEmployeesChecked, setAllEmployeesChecked] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState<Contract[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Condition[]>([]);

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  useEffect(() => {
    if (companyDetails?.id) {
      fetchDirectory(companyDetails.id, orgDirectoryFilters);
    }
  }, [companyDetails?.id, orgDirectoryFilters]);

  useEffect(() => {
    if (show && initialRules) {
      const { type, conditions } = initialRules;
      setAllEmployeesChecked(type === RuleType.ALL);
      setSelectedFilters(conditions as Condition[]);
    }
  }, [initialRules, show]);

  useDeepCompareEffect(() => {
    if (!fetchDirectoryLoading) {
      handleFilters(selectedFilters);
    }
  }, [selectedFilters, fetchDirectoryLoading, allContracts]);

  const handleFilters = useCallback(
    (filters: Condition[]) => {
      if (!filters.length) {
        setFilteredEmployees([]);
      } else {
        const result = filterContractsByConditions(allContracts, filters);
        setFilteredEmployees(result.map((contract) => contract as Contract));
      }
    },
    [allContracts],
  );

  const openRulesDialog = () => {
    let filteredConfig: OrgDirectoryFilterConfig[];
    if (!enableEntity) {
      filteredConfig = orgDirectoryFilterConfig.filter(
        (i) => i.key !== ConditionKey.ENTITY,
      );
    } else {
      filteredConfig = orgDirectoryFilterConfig?.filter(
        (i) => i.key === ConditionKey.ENTITY,
      );
    }

    openModal({
      renderAsStandalone: true,
      render({ onClose: onRulesModalClose }) {
        return (
          <RulesDialog
            show
            onClose={onRulesModalClose}
            filterConfig={filteredConfig}
            defaultFilters={selectedFilters}
            onApply={(filters) => {
              setSelectedFilters(filters);
              onRulesModalClose();
            }}
            enableEntity={enableEntity}
          />
        );
      },
    });
  };

  const handleConfirm = () => {
    const rules: Rule = allEmployeesChecked
      ? {
          type: RuleType.ALL,
          conditions: [],
        }
      : {
          type: RuleType.BY_CONDITION,
          conditions: selectedFilters.map(({ ...item }) => item),
        };

    const selectedContractIds = filteredEmployees?.map((item) => item.id) ?? [];

    const selectedContracts = allEmployeesChecked
      ? contracts
      : contracts.filter((item) => selectedContractIds.includes(item.id));
    const selectedManagers = allEmployeesChecked
      ? managers
      : managers.filter((item) =>
          selectedContractIds.includes(item?.contract?.id),
        );

    const selectedEntityList =
      selectedFilters
        .filter(({ key }) => key === ConditionKey.ENTITY)
        .flatMap((e) => e.values) ?? [];

    const selectedContractForEntity = filteredEmployees?.filter((item) =>
      selectedEntityList?.includes(item?.legalEntityId ?? ''),
    );
    onConfirm({
      rules,
      contracts: selectedContracts,
      managers: selectedManagers,
      membersBelongsToAEntity: selectedContractForEntity,
    });
  };

  return (
    <Dialog
      title={title}
      onClose={onClose}
      open={show}
      buttonText={t('add-employees-dialog.confirm-btn', 'Add')}
      headerStyle={tw`bg-background-white text-text-primary items-center`}
      containerStyle={tw`w-[740px]`}
      disabled={confirmDisabled}
      onConfirm={handleConfirm}
      loading={loading}
      explicitlyClose
    >
      {fetchDirectoryLoading ? (
        <Loader.Spinner variant={SpinnerType.CUSTOM_LAYOUT} tw="h-96" />
      ) : (
        <div tw="flex flex-col">
          <div tw="mb-extra-small">
            <Checkbox
              id="all-employees"
              checkboxSize="small"
              checked={allEmployeesChecked}
              onChange={(e) => setAllEmployeesChecked(e.target.checked)}
            />
            <CheckboxLabel htmlFor="all-employees" tw="font-semibold">
              {t(
                'add-employees-dialog.all-employees-checkbox',
                'All {{ total }} employees',
                {
                  replace: {
                    total: allContracts.length,
                  },
                },
              )}
            </CheckboxLabel>
          </div>
          <div tw="rounded border border-border-secondary bg-background-primary p-base flex flex-col">
            <AppliedRulesSection
              disabled={allEmployeesChecked}
              employees={filteredEmployees}
              rules={selectedFilters}
              rulesConfig={orgDirectoryFilterConfig}
              onClick={openRulesDialog}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default memo(AddEmployeesDialog);
