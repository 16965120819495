/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { logout } from 'login/services/jwt';

const SignOutButton = (): React.ReactElement => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.member');

  return (
    <Button
      variant="inline"
      size="medium"
      css={[
        tw`no-underline text-p text-text-inverted-secondary font-normal px-none`,
        !isNewThemeApplied && tw`text-grey01`,
      ]}
      onClick={() => logout()}
    >
      <div tw="flex items-center">
        {t('button.sign-out', 'Sign Out')}
        <Icon
          name="exit"
          fill={
            !isNewThemeApplied
              ? theme`colors.grey01`
              : theme`colors.icon-inverted-secondary`
          }
          height="24"
          width="24"
          tw="ml-tiny"
        />
      </div>
    </Button>
  );
};

export default SignOutButton;
