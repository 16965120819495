/** @jsxImportSource @emotion/react */
import React from 'react';
import 'twin.macro';

import { hasKey, includesIfNotEmpty } from '../../helper';
import {
  EditableCompoundDetailRowProps,
  EditableDetailRowProps,
  isDetailRowValueType,
  isEmptyValue,
} from '../../types';
import EditableSingleRow from '../editable-single-row';

const EditableDetailRow: React.FC<
  React.PropsWithChildren<EditableDetailRowProps>
> = ({
  id,
  label,
  value,
  isEditing = false,
  hideLabel = false,
  labelStyles,
  valueStyles,
  hide,
  renderValue,
  children,
  ...otherProps
}) => {
  if (isEmptyValue(value) && !isEditing) return null;

  if (isDetailRowValueType(value)) {
    return (
      <EditableSingleRow
        id={id}
        label={label as string | undefined}
        value={value}
        hideLabel={hideLabel}
        labelStyles={labelStyles}
        valueStyles={valueStyles}
        renderValue={renderValue as React.ReactElement}
        hide={hide as boolean}
        isEditing={isEditing}
      >
        {children}
      </EditableSingleRow>
    );
  }

  const {
    groupName,
    ignoreKeys,
  } = otherProps as EditableCompoundDetailRowProps;

  return (
    <>
      {value &&
        !isEditing &&
        Object.entries(value)
          .filter(([key]) => !includesIfNotEmpty(ignoreKeys, key))
          .filter(([, childValue]) => !isEmptyValue(childValue))
          .map(([childKey, childValue]) => (
            <EditableSingleRow
              id={`${id}.${childKey}`}
              key={`${id}.${childKey}`}
              label={hasKey(label, childKey) ? label[childKey] : undefined}
              value={childValue}
              renderValue={
                (renderValue as Record<string, React.ReactElement>)?.[childKey]
              }
              hideLabel={hideLabel}
              labelStyles={labelStyles}
              valueStyles={valueStyles}
              hide={(hide as Record<string, boolean>)?.[childKey]}
            />
          ))}
      {isEditing && (
        <EditableSingleRow
          id={id}
          label={groupName}
          isEditing
          hideLabel={hideLabel}
          labelStyles={labelStyles}
          valueStyles={valueStyles}
        >
          {children}
        </EditableSingleRow>
      )}
    </>
  );
};

export default EditableDetailRow;
