import React, { useContext, useEffect, useMemo } from 'react';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ComboBox,
  Dialog,
  DropdownValue,
  Icon,
  IconButton,
  ThemeContext,
} from '@multiplier/common';
import { cloneDeep } from 'lodash';
import tw, { theme } from 'twin.macro';
import * as yup from 'yup';

import {
  Condition,
  ConditionKey,
  ConditionOperator,
} from '../../../__generated__/graphql';
import { operatorLabel } from '../../../constants';
import { OrgDirectoryFilterConfig } from '../../../hooks/get-org-directory';

export interface FormValues {
  filters: Condition[];
}
interface Props {
  show: boolean;
  onClose: () => void;
  filterConfig: OrgDirectoryFilterConfig[];
  onApply: (filters: Condition[]) => void;
  defaultFilters: Condition[];
  enableEntity: boolean;
}

const getDefaultInput = (): Partial<Condition> => ({
  key: undefined,
  operator: undefined,
  values: [],
});

const RulesDialog: React.FC<Props> = ({
  show,
  onClose,
  filterConfig,
  onApply,
  defaultFilters,
  enableEntity,
}) => {
  const { t } = useTranslation('hris.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const propertyOptions: DropdownValue[] = useMemo(
    () =>
      filterConfig.map<DropdownValue>((config) => ({
        key: config.key,
        title: config.label,
        value: config.key,
      })),
    [filterConfig],
  );

  const operatorOptions: DropdownValue[] = enableEntity
    ? [
        {
          key: ConditionOperator.EQUALS,
          title: operatorLabel(t)[ConditionOperator.EQUALS],
          value: ConditionOperator.EQUALS,
        },
      ]
    : [
        {
          key: ConditionOperator.EQUALS,
          title: operatorLabel(t)[ConditionOperator.EQUALS],
          value: ConditionOperator.EQUALS,
        },
        {
          key: ConditionOperator.NOT_EQUALS,
          title: operatorLabel(t)[ConditionOperator.NOT_EQUALS],
          value: ConditionOperator.NOT_EQUALS,
        },
      ];

  const getValueOptions = (key: ConditionKey | null): DropdownValue[] => {
    const config = filterConfig.find((item) => item.key === key);
    return (
      config?.options.map((option) => ({
        key: option.value,
        title: option.label,
        label: option.label,
        value: option.value,
      })) ?? []
    );
  };

  const {
    watch,
    control,
    formState: { isValid },
    getValues,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        filters: yup.array().of(
          yup.object().shape({
            key: yup
              .string()
              .required(
                t(
                  'rules-dialog.validations.property',
                  'Please select property',
                ),
              ),
            operator: yup
              .string()
              .required(
                t(
                  'rules-dialog.validations.operator',
                  'Please select operator',
                ),
              ),
            values: yup
              .array()
              .min(
                1,
                t(
                  'rules-dialog.validations.value',
                  'Please select at least 1 value',
                ),
              ),
          }),
        ),
      }),
    ),
    defaultValues: {
      filters: [getDefaultInput()],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  });

  useEffect(() => {
    if (defaultFilters && defaultFilters.length) {
      reset({
        filters: cloneDeep(defaultFilters),
      });
    }
  }, [defaultFilters]);

  const handleAddCondition = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    append(getDefaultInput());
  };

  const handleConfirm = () => {
    const values = getValues();
    onApply(values.filters);
  };

  return (
    <Dialog.BaseDialog
      title={t('rules-dialog.title', 'Select by Condition')}
      onClose={onClose}
      open={show}
      buttonText={t('rules-dialog.confirm-btn', 'Apply')}
      headerStyle={
        isNewThemeApplied
          ? tw`bg-background-white mt-extra-small text-text-primary`
          : tw`bg-white mt-8 text-slateGrey900`
      }
      containerStyle={tw`w-[740px] overflow-visible`}
      disabled={!isValid}
      onConfirm={handleConfirm}
    >
      <div tw="flex flex-col gap-24">
        {fields.map((field, index) => (
          <div tw="flex items-center justify-between gap-small" key={field.id}>
            <div tw="flex flex-col gap-tiny w-full">
              <p tw="text-ps font-semibold">
                {t('rules-dialog.property-label', 'Property')}
              </p>
              <Controller
                name={`filters.${index}.key`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    variant="default"
                    placeholder={t('rules-dialog.select-placeholder', 'Select')}
                    dropdownValues={propertyOptions}
                    showArrow
                    value={value ?? ''}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div tw="flex flex-col gap-tiny w-full">
              <div tw="h-20" />
              <Controller
                name={`filters.${index}.operator`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    disabled={!watch(`filters.${index}.key`)}
                    variant="default"
                    placeholder={t('rules-dialog.select-placeholder', 'Select')}
                    dropdownValues={operatorOptions}
                    showArrow
                    value={value ?? ''}
                    onChange={onChange}
                    tw="w-full"
                  />
                )}
              />
            </div>
            <div tw="flex flex-col gap-tiny w-full">
              <p tw="text-ps font-semibold">
                {t('rules-dialog.value-label', 'Value')}
              </p>
              <Controller
                name={`filters.${index}.values`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    disabled={
                      !watch(`filters.${index}.key`) ||
                      !watch(`filters.${index}.operator`)
                    }
                    variant="default"
                    placeholder={t('rules-dialog.select-placeholder', 'Select')}
                    queryPlaceholder={t(
                      'rules-dialog.search-placeholder',
                      'Search',
                    )}
                    prependStyles={tw`pl-extra-large`}
                    dropdownValues={getValueOptions(
                      watch(`filters.${index}.key`),
                    )}
                    labelStyles={tw`[max-width: 130px]`}
                    showArrow
                    value={value}
                    onChange={onChange}
                    multiple
                  />
                )}
              />
            </div>
            <div tw="flex flex-col gap-tiny w-full max-w-extra-large">
              <div tw="h-20" />
              <IconButton
                size="small"
                name="trash-empty"
                fill={isNewThemeApplied ? undefined : 'none'}
                variant="outline"
                stroke={isNewThemeApplied ? undefined : theme`colors.primary`}
                iconProps={{
                  width: 16,
                  height: 16,
                }}
                tw="w-32 h-32 shrink-0"
                onClick={() => remove(index)}
              />
            </div>
          </div>
        ))}
        {!enableEntity && (
          <Button
            size="small"
            variant="secondary"
            tw="w-max disabled:text-text-tertiary"
            disabled={!isValid && fields.length > 0}
            onClick={handleAddCondition}
          >
            <div tw="flex items-center gap-extra-small">
              <Icon
                name="plus-circle-v2"
                width={16}
                height={16}
                fill={
                  isNewThemeApplied
                    ? isValid
                      ? undefined
                      : theme`colors.icon-tertiary`
                    : 'none'
                }
              />
              {t('rules-dialog.add-condition-btn', 'Add condition')}
            </div>
          </Button>
        )}
      </div>
    </Dialog.BaseDialog>
  );
};

export default RulesDialog;
