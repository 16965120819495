import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ApolloError } from '@apollo/client';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import { OptionalBankDetailsParams } from 'contract-onboarding/member/hooks/update-bank-details';
import { UpdateBankDetailsParams } from 'contract-onboarding/member/pages/onboarding/pages/bank-details';

import {
  ContractOnboardingStep,
  Maybe,
  useUpdateBankDetailsCompanyMutation,
} from '../../../__generated__/graphql';
import stepConfig from '../pages/definition-phase/step-config';

const useUpdateCompanyBankDetails = (
  onComplete?: () => void,
  contractId?: Maybe<string>,
  memberId?: Maybe<string>,
  onError?: (e: ApolloError) => void,
  shouldPropagateError?: boolean,
  isNavigateTrue?: boolean,
): {
  updateCompanyBankDetails: (
    isDynamicDetailData: boolean,
    values: UpdateBankDetailsParams,
    optionalBankDetails: OptionalBankDetailsParams,
  ) => void;
  loading: boolean;
} => {
  const navigate = useNavigate();
  const { t } = useTranslation('contract-onboarding.company');

  const [
    updateBankDetailsMutation,
    { loading },
  ] = useUpdateBankDetailsCompanyMutation({
    onCompleted() {
      successNotification(
        '',
        t('bank-details.updated-success-message', 'Bank details updated'),
        false,
      );
      if (onComplete) onComplete();
    },
    onError(e) {
      if (onError) {
        onError(e);
      } else {
        errorNotification(
          '',
          t(
            'bank-details.update-error-message',
            'Bank details updation failed',
          ),
          false,
        );
      }
    },
    context: {
      shouldPropagateError,
    },
  });

  const updateCompanyBankDetails = async (
    isDynamicDetailData: boolean,
    values: UpdateBankDetailsParams,
    optionalBankDetails: OptionalBankDetailsParams,
  ) => {
    if (!isDynamicDetailData) {
      await updateBankDetailsMutation({
        variables: {
          id: String(memberId),
          bankAccount: {
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            bankName: values.bankName,
            branchName: values.branchName,
            swiftCode: values.swiftCode,
            localBankCode: values.localBankCode,
            bankStatements: values.bankStatements,
            bankStatementDocIds: values.bankStatementDocIds,
            dynamicDetail: null,
          },
        },
      });
    } else if (isDynamicDetailData) {
      await updateBankDetailsMutation({
        variables: {
          id: String(memberId),
          bankAccount: {
            dynamicDetail: {
              accountType: optionalBankDetails.accountType,
              paymentAccountRequirementType:
                optionalBankDetails.paymentAccountRequirementType,
              sourceCurrency: optionalBankDetails.sourceCurrency,
              targetCurrency: optionalBankDetails.targetCurrency,
              transferType: optionalBankDetails.transferType,
              paymentPartner: optionalBankDetails.paymentPartner,
              fieldValues: values?.bankData,
            },
            bankStatements: values.bankStatements,
            bankStatementDocIds: values.bankStatementDocIds,
          },
        },
      });
    }

    if (isNavigateTrue)
      navigate(
        stepConfig[ContractOnboardingStep.DEFINITION_CONTRACT].directLink(
          contractId,
        ),
      );
  };

  return { updateCompanyBankDetails, loading };
};

export default useUpdateCompanyBankDetails;
