import { GrowthBook } from '@growthbook/growthbook-react';

/**
 * Initializes single growthBook instance and fetches available features from api.
 * There should be no other places for initializing GrowthBook (per workspace) to avoid data inconsistency.
 * Provided this instance is used to initialize GrowthbookProvider, we can access it via hook useGrowthBook
 */
const growthBook = new GrowthBook({});

// Load feature definitions from GrowthBook API (Feature Flags)
async function initializeGrowthBook() {
  try {
    if (process.env.NODE_ENV === 'test') return;

    const response = await fetch(
      `${process.env.VITE_GROWTHBOOK_URL as string}/${
        process.env.VITE_GROWTHBOOK_API_KEY as string
      }`,
    );
    const data = await response.json();
    growthBook?.setFeatures(data.features);
  } catch (e) {
    // No need to handle, will fail gracefully
  }
}

export const setOnboardingAttributes = ({
  country,
  contractType,
  companyId,
}: {
  country?: string | null;
  contractType?: string | null;
  companyId?: string | null;
}): void => {
  growthBook.setAttributes({
    ...growthBook.getAttributes(),
    onboardingCountry: country ?? growthBook.getAttributes()?.onboardingCountry,
    onboardingContractType:
      contractType ?? growthBook.getAttributes()?.onboardingContractType,
    company: companyId ?? growthBook.getAttributes()?.company,
  });
};

export const setMemberIdAttribute = (memberId: string | null): void => {
  growthBook.setAttributes({
    ...growthBook.getAttributes(),
    member_id: memberId,
  });
};

export const setCompanyIdAttribute = (companyId?: string): void => {
  growthBook.setAttributes({
    ...growthBook.getAttributes(),
    company: companyId,
  });
};

initializeGrowthBook();

export { default as AppFeature } from './features';

export default growthBook;
