/* eslint-disable import/prefer-default-export */
import { generateMtlHeader } from '@multiplier/instrumentation';
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';

import { USER_ID_HEADER } from '../constants';
import { JwtToken } from '../types';
import { userVar } from '../vars';

export const getAuthHeaders = (token: string): Record<string, string> => {
  let headers: Record<string, string> = {};
  const {
    experiences: { current: currentExperience },
  } = userVar();

  // Append MTL header with default config to all requests
  const mtlHeader = generateMtlHeader(currentExperience);

  if (token) {
    try {
      const { details: { id: userId } = {} } = jwtDecode<JwtToken>(token);
      headers.Authorization = `Bearer ${token}`;

      if (userId) {
        headers[USER_ID_HEADER] = `${userId}`;
      }

      if (!isEmpty(mtlHeader)) {
        headers = {
          ...headers,
          ...mtlHeader,
        };
      }
    } catch (err) {
      headers = {};
    }
  }

  return headers;
};
