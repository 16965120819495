/* eslint-disable no-underscore-dangle */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'twin.macro';

import { useTranslation } from 'react-i18next';

import { stringAsTitleCase } from '@multiplier/format';
import { handlers } from '@multiplier/notifications';

import { Company, CountryCode } from '../../__generated__/graphql';
import ChevronDown from '../../assets/chevron-down.svg';
import ChevronUp from '../../assets/chevron-up.svg';
import CompanyIcon from '../../assets/company.svg';
import { countryLabels } from '../../constants';
import { OrgEmployee } from '../../types';
import { Node, NodeData, NodeId } from '../../types/org-chart';
import { getAvatarDisplay } from '../../utils/common';
import Toolbar from './components/toolbar';
import D3OrgChart from './d3-org-chart';
import './style.css';

const DEFAULT_EXPAND_LEVEL = 1;

const MAX_TEXT_DISPLAY_LENGTH = 20;

const { errorNotification } = handlers;

export interface OrgChartHandlers {
  focusEmployee: (payload: {
    userId?: string;
    autoClear?: boolean;
    shouldExpandChild?: boolean;
  }) => void;
}

interface Props {
  company: Company;
  employees?: OrgEmployee[];
  filteredEmployeeIds?: string[];
  width?: string | number;
  height?: string | number;
  initialExpandLevel?: number;
  onLocateMe?: () => void;
  onViewEmployee?: (userId: string) => void;
  currentUserId: string;
  onClick?: (evt: any) => void;
  hasMyTeamFilter?: boolean;
}

const OrgChart = forwardRef<OrgChartHandlers, Props>(
  (
    {
      company,
      employees = [],
      filteredEmployeeIds,
      width = window.innerWidth,
      height = window.innerHeight,
      initialExpandLevel = DEFAULT_EXPAND_LEVEL,
      onLocateMe,
      onViewEmployee,
      currentUserId,
      onClick,
      hasMyTeamFilter = false,
    },
    ref,
  ) => {
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('hris.common');
    const container = useRef<HTMLDivElement>(null);

    const [chart, setChart] = useState<D3OrgChart>();
    const [zoomPercentage, setZoomPercentage] = useState('100');

    const rootNode = useMemo<NodeData>(
      () => ({
        id: company?.id ?? '',
        parentId: undefined,
        displayName: company?.displayName ?? '',
        isCompany: true,
        country: company?.primaryEntity?.address?.country,
      }),
      [company],
    );

    const formatChartData = (): NodeData[] => {
      const output: NodeData[] = [
        rootNode,
        ...employees.map((employee) => {
          const id = employee.isManager
            ? employee?.manager?.id
            : employee?.contract?.id;

          const parentId = employee.isManager
            ? employee?.manager?.reportsToManager?.id
            : employee?.contract?.reportsToManager?.id;

          return {
            ...employee.contract,
            id,
            parentId,
            userId: employee.userId,
          };
        }),
      ];

      output.forEach((item, _, arr) => {
        const isParentExisted = arr.find((x) => x.id === item.parentId);

        // Fallback parentId to root node
        if (item.id !== rootNode.id && !isParentExisted) {
          item.parentId = rootNode.id as string;
        }

        const memberName = `${item.member?.firstName ?? ''} ${
          item.member?.lastName ?? ''
        }`.trim();

        // Format display name on chart
        item.displayName = stringAsTitleCase(item.displayName ?? memberName);
      });

      return output;
    };

    const chartData: NodeData[] = useMemo(() => {
      if (!employees.length) return [];

      const data = formatChartData();

      return data;
    }, [employees, rootNode, currentUserId]);

    const getFocusingEmployee = () => {
      if (!chart) return null;
      const { allNodes } = chart.getState();

      return allNodes.find((x) => x.data._highlighted)?.data;
    };

    const handleFocusEmployee: OrgChartHandlers['focusEmployee'] = ({
      userId,
      autoClear = false,
      shouldExpandChild = false,
    }) => {
      if (!chart) return;
      chart.clearHighlighting();

      if (!userId) {
        return;
      }

      const nodeId = chartData.find((x) => x.userId === userId)?.id;
      if (!nodeId) {
        errorNotification(
          '',
          t(
            'org-chart.notification.locate-user-error',
            'Cannot locate employee on the chart',
          ),
          false,
        );
        return;
      }
      chart
        .setFocus(nodeId, { shouldExpandChild, shouldHighlight: true })
        .render();
      if (autoClear) {
        setTimeout(() => {
          chart.clearHighlightingNode(nodeId).render();
        }, 3000);
      }
    };

    const handleFilterEmployees = () => {
      if (!chart) return;
      chart.clearFading();

      if (filteredEmployeeIds === undefined) {
        return;
      }

      const fadeOutNodeIds: NodeId[] = chartData
        .filter(
          (x) =>
            x.id !== rootNode.id &&
            (filteredEmployeeIds.length === 0
              ? true
              : !filteredEmployeeIds.includes(x.userId as string)),
        )
        .map((x) => x.id) as NodeId[];

      // Handle to expand current user's team
      if (currentUserId && hasMyTeamFilter) {
        const currentUserNodeId = chartData.find(
          (x) => x.userId === currentUserId,
        )?.id;
        if (currentUserNodeId) {
          chart.setGroupFaded(fadeOutNodeIds, false);
          chart
            .setFocus(currentUserNodeId, {
              shouldExpandChild: true,
              shouldHighlight: false,
            })
            .render();
        }
      } else {
        chart.setGroupFaded(fadeOutNodeIds);
        chart.render().fit();
      }
    };

    const handleZoomToFit = () => {
      if (!chart) return;
      chart.render().fit();
    };

    const handleResetView = () => {
      if (!chart) return;
      chart.collapseAll(initialExpandLevel).fit();
    };

    const handleZoom = (type: 'zoom-out' | 'zoom-in') => {
      if (!chart) return;

      switch (type) {
        case 'zoom-out':
          chart.zoomOut();
          break;
        case 'zoom-in':
          chart.zoomIn();
          break;
        default:
          break;
      }
    };

    const handleViewEmployee = (node: Node) => {
      if (node.id === rootNode.id || !chart) return;

      const focusingEmployee = getFocusingEmployee();

      // Clear highlighting if current user is being focused
      if (
        node.data.userId !== currentUserId &&
        focusingEmployee?.userId === currentUserId
      ) {
        chart.clearHighlighting();
      }

      onViewEmployee?.(node?.data?.userId as string);
    };

    const getNodeContent = (d: Node): string => {
      const shortenText = (text?: string) => {
        if (!text) return '';
        return text.length > MAX_TEXT_DISPLAY_LENGTH
          ? `${text.substring(0, MAX_TEXT_DISPLAY_LENGTH)}...`
          : text;
      };

      const isCompanyNode = d.data.isCompany;

      const displayInitials = isCompanyNode
        ? `<img src=${CompanyIcon} />`
        : getAvatarDisplay(d.data.member);
      const countryCode = d.data.country ?? rootNode.country;

      const department = shortenText(
        isCompanyNode ? '' : d.data.orgAttributes?.department?.name,
      );
      const position = shortenText(
        isCompanyNode ? '' : (d.data.position as string),
      );
      const countryLabel =
        countryCode !== ('Unspecified' as CountryCode) &&
        shortenText(
          countryLabels(tCommon)[countryCode as CountryCode] ?? countryCode,
        );
      const displayName = shortenText(d.data.displayName ?? '');

      /**
       * FIXME: Temporary hack for avatar position since 'position: absolute` is not working for Safari
       * Will improve later once start on Safari improvement task
       */
      let avatarMargin = 40;
      if (!position) avatarMargin += 15;
      if (!department) avatarMargin += 15;
      if (!countryLabel) avatarMargin += 15;

      return `
        <div class="node-content" style="width:${d.width}px; height:${
        d.height
      }px;">
          <div class="node-content__avatar" style="margin-top: -${avatarMargin}px">${displayInitials}</div>
          <p class="node-content__name">
            ${displayName}
          </p>
          ${position ? `<p class="node-content__position">${position}</p>` : ''}
          ${
            department
              ? `<p class="node-content__department">${department}</p>`
              : ''
          }
          ${
            countryLabel
              ? `<p class="node-content__country">${countryLabel}</p>`
              : ''
          }
        </div>`;
    };

    const getExpandButtonContent = (d: Node): string => {
      let display = `${d.data._directSubordinates} direct reports`;
      if (d.data._totalSubordinates && d.data._totalSubordinates > 1) {
        display += ` (${d.data._totalSubordinates} total)`;
      }

      return `<div class="node-button">
        <span>${display}</span>
        <img src=${d.children ? ChevronUp : ChevronDown} />
      </div>`;
    };

    useImperativeHandle(ref, () => ({
      focusEmployee(params) {
        handleFocusEmployee(params);
      },
    }));

    useEffect(() => {
      handleFilterEmployees();
    }, [filteredEmployeeIds]);

    // Init chart
    useEffect(() => {
      setChart(
        new D3OrgChart({
          svgWidth: width,
          svgHeight: height,
          initialExpandLevel,
          nodeContent: getNodeContent,
          buttonContent: ({ node }) => getExpandButtonContent(node),
          onZoom(evt) {
            const { k } = evt.transform;
            setZoomPercentage((k * 100).toFixed(0));
          },
        }),
      );
    }, []);

    // Render chart
    useLayoutEffect(() => {
      if (container.current && chart) {
        chart
          .setState({
            container: container.current,
            data: chartData,
            onSvgClick: onClick,
            onNodeClick: handleViewEmployee,
          })
          .render()
          .fit();
      }
    }, [chartData, container.current, chart]);

    return (
      <div tw="relative">
        <div ref={container} />
        <Toolbar
          zoomLevel={zoomPercentage}
          onLocateMe={onLocateMe}
          onZoomToFit={handleZoomToFit}
          onReset={handleResetView}
          onZoomIn={() => handleZoom('zoom-in')}
          onZoomOut={() => handleZoom('zoom-out')}
        />
      </div>
    );
  },
);

export default OrgChart;
