/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Checkbox,
  ConfirmationDialog,
  TextInput,
  Textarea,
  ThemeContext,
  useModal,
} from '@multiplier/common';
import tw from 'twin.macro';

import stepConfig from 'contract-onboarding/member/pages/onboarding/step-config';
import routes from 'contract-onboarding/member/routes';
import { getNextStep } from 'contract-onboarding/utils/routing';

import {
  Contract,
  ContractBenefit,
  ContractOnboardingStep,
  useContractMemberBenefitCompletedMutation as useBenefitCompleted,
  useOptOutInsuranceMutation,
} from '__generated__/graphql';

export enum REASON_KEY {
  alreadyInsured = 'alreadyInsured',
  highContribution = 'highContribution',
  limitedBenefitsOrCoverage = 'limitedBenefitsOrCoverage',
  otherReasons = 'otherReasons',
}

export const SkipInsurance: React.FC<{
  onSubmit: (reasonsInput: string[]) => void;
  loading: boolean;
  show: boolean;
  handleOpenReasonsModal: () => void;
  handleCloseReasonsModal: () => void;
}> = ({
  onSubmit,
  loading,
  show,
  handleOpenReasonsModal,
  handleCloseReasonsModal,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.member');
  const [optOutReasons, setOptOutReasons] = useState<
    { key: REASON_KEY; value: string }[]
  >([]);
  const [customReason, setCustomReason] = useState<string>('');

  const REASONS_LIST = {
    [REASON_KEY.alreadyInsured]: {
      label: t(
        'skip-insurance.opt-out-modal.option.already-insured',
        'Already insured by another provider',
      ),
      value: 'Already insured by another provider',
      key: REASON_KEY.alreadyInsured,
    },
    [REASON_KEY.highContribution]: {
      label: t(
        'skip-insurance.opt-out-modal.option.high-contributions',
        'High employee contributions',
      ),
      value: 'High employee contributions',
      key: REASON_KEY.highContribution,
    },
    [REASON_KEY.limitedBenefitsOrCoverage]: {
      label: t(
        'skip-insurance.opt-out-modal.option.limited-coverage',
        'Limited benefits/coverage',
      ),
      value: 'Limited benefits/coverage',
      key: REASON_KEY.limitedBenefitsOrCoverage,
    },
    [REASON_KEY.otherReasons]: {
      label: t(
        'skip-insurance.opt-out-modal.option.other-reasons',
        'Other reason(s)',
      ),
      value: 'Other reason(s)',
      key: REASON_KEY.otherReasons,
    },
  };

  const handleChange = (checked: boolean, changeKey: REASON_KEY) => {
    if (checked) {
      setOptOutReasons([
        ...optOutReasons,
        { key: changeKey, value: REASONS_LIST[changeKey]?.value },
      ]);
    } else {
      const filteredReasons = optOutReasons.filter(
        (reason) => reason.key !== changeKey,
      );
      setOptOutReasons(filteredReasons);
    }
  };

  const includesOtherReasons = optOutReasons.some(
    (reason) => reason.key === REASON_KEY.otherReasons,
  );

  const handleSubmit = async () => {
    let reasonsInput: (REASON_KEY | string)[] = optOutReasons
      .filter((reason) => reason.key !== REASON_KEY.otherReasons)
      .map((reason) => reason.value);
    if (includesOtherReasons && customReason) {
      reasonsInput = [...reasonsInput, customReason];
    }

    setOptOutReasons([]);
    setCustomReason('');
    onSubmit(reasonsInput);
  };

  const isSubmitDisabled =
    optOutReasons.length === 1 &&
    optOutReasons?.[0].key === REASON_KEY.otherReasons
      ? customReason === ''
      : optOutReasons.length === 0;

  return (
    <>
      <div
        css={[
          tw`flex gap-x-tiny items-center text-ps justify-center text-text-secondary mt-small`,
          !isNewThemeApplied && tw`text-slateGrey500`,
        ]}
      >
        <div>
          {t(
            'skip-insurance.opt-out.title',
            'Have no need for sponsored insurance?',
          )}
        </div>
        <span
          css={[
            tw`p-none text-ps font-semibold underline text-text-primary`,
            !isNewThemeApplied && tw`text-primaryBlue600`,
          ]}
          tabIndex={0}
          role="button"
          onKeyDown={handleOpenReasonsModal}
          onClick={handleOpenReasonsModal}
        >
          {t('skip-insurance.opt-out.trigger', 'Opt-out of insurance')}
        </span>
      </div>

      <ConfirmationDialog
        containerStyle={tw`min-w-[480px] pt-extra-small px-large pb-large`}
        buttonContainerStyle={tw`m-none`}
        open={show}
        onClose={() => {
          setOptOutReasons([]);
          setCustomReason('');
          handleCloseReasonsModal();
        }}
        onConfirm={handleSubmit}
        title={t('skip-insurance.opt-out-modal.title', 'Opt-out of insurance')}
        description={t(
          'skip-insurance.opt-out-modal.description',
          'You’re about to skip health insurance provided by your employer. Please select a reason to skip and confirm.',
        )}
        buttonText={t('skip-insurance.opt-out-modal.continue', 'Continue')}
        loading={loading}
        disabled={loading || isSubmitDisabled}
      >
        <div tw="flex flex-col gap-y-base mt-base">
          {Object.keys(REASONS_LIST).map((reason) => (
            <TextInput.Container tw="flex flex-row items-center" key={reason}>
              <Checkbox
                data-testid={`option-${reason}`}
                tw="w-large h-large"
                onChange={(e) => {
                  handleChange(
                    e.target.checked,
                    REASONS_LIST[reason as REASON_KEY].key,
                  );
                }}
              />
              <div
                css={[
                  tw`text-ps font-semibold`,
                  !isNewThemeApplied && tw`text-slateGrey500`,
                ]}
              >
                {REASONS_LIST[reason as REASON_KEY]?.label}
              </div>
            </TextInput.Container>
          ))}
          {includesOtherReasons ? (
            <Textarea
              tw="resize-none"
              name="customReason"
              placeholder={t(
                'skip-insurance.opt-out-modal.customer-reason-placeholder',
                'Please describe your specific reason here',
              )}
              onChange={(e) => setCustomReason(e.target.value)}
              value={customReason}
            />
          ) : null}
        </div>
      </ConfirmationDialog>
    </>
  );
};

const SkipInsuranceWrapper: React.FC<{
  contractBenefit: ContractBenefit;
  contractId: Contract['id'];
}> = ({ contractBenefit, contractId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [benefitCompleted] = useBenefitCompleted();
  const [
    showReasonsModal,
    handleCloseReasonsModal,
    handleOpenReasonsModal,
  ] = useModal(false);

  const [optOutInsurance, { loading }] = useOptOutInsuranceMutation();

  const onSubmit = async (reasonsInput: string[]) => {
    if (contractBenefit?.id && reasonsInput?.length > 0) {
      await optOutInsurance({
        variables: {
          contractBenefitId: contractBenefit?.id,
          reasons: reasonsInput,
        },
        onCompleted: async () => {
          if (location.pathname.includes(routes.onboarding.benefitDetails)) {
            const benefitCompletedResult = await benefitCompleted({
              variables: {
                contractId: contractId ?? '',
              },
            });

            const onboarding =
              benefitCompletedResult?.data?.contractMemberBenefitCompleted
                ?.onboarding;

            const next = getNextStep(
              onboarding,
              ContractOnboardingStep.MEMBER_BENEFIT_DETAILS,
            );

            handleCloseReasonsModal();

            if (next && stepConfig[next]) {
              navigate(stepConfig[next].directLink());
            }
          } else {
            handleCloseReasonsModal();
          }
        },
      });
    }
  };

  return (
    <SkipInsurance
      show={showReasonsModal}
      handleOpenReasonsModal={handleOpenReasonsModal}
      handleCloseReasonsModal={handleCloseReasonsModal}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export default SkipInsuranceWrapper;
