import { isNil } from 'lodash';
import compact from 'lodash/compact';

import {
  Contract,
  useGetTimeOffContractQuery,
} from '../../__generated__/graphql';
import { UseGetTimeOffDataValues } from '../../types';
import {
  getLeaveEntitlements,
  getOptionsFromAssignedEntitlements,
  getOptionsFromAvailableEntitlements,
  getTimeOffTypeToDefinitionMap,
  isContractorOrFreelancer,
} from '../../utils/time-off';

export interface UseGetTimeOffDataParams {
  contractId: Contract['id'];
  contractType?: Contract['type'];
  skip?: boolean;
}

const useGetTimeOffData = ({
  contractId,
  contractType,
  skip = false,
}: UseGetTimeOffDataParams): UseGetTimeOffDataValues => {
  const shouldSkipByContractType =
    isNil(contractType) || isContractorOrFreelancer(contractType);

  const {
    data: timeOffData,
    loading: timeOffLoading,
    refetch: refetchTimeOffData,
  } = useGetTimeOffContractQuery({
    variables: {
      contractId: contractId ?? '',
    },
    skip: skip || shouldSkipByContractType || !contractId,
  });

  return {
    mandatoryFields: getLeaveEntitlements(
      timeOffData?.timeOffContractRequirements?.assignedEntitlements,
      true,
    ),

    customFields: getLeaveEntitlements(
      timeOffData?.timeOffContractRequirements?.assignedEntitlements,
      false,
    ),
    loading: timeOffLoading,

    assignedEntitlementOptions: getOptionsFromAssignedEntitlements(
      compact(timeOffData?.timeOffContractRequirements?.assignedEntitlements),
      true,
    ),
    availableEntitlementOptions: getOptionsFromAvailableEntitlements(
      compact(timeOffData?.timeOffContractRequirements?.availableEntitlements),
    ),
    availableCustomEntitlementOptions: getOptionsFromAvailableEntitlements(
      compact(timeOffData?.timeOffContractRequirements?.availableEntitlements),
      false,
    ),

    timeOffTypeToDefinitionMap: getTimeOffTypeToDefinitionMap(
      compact(
        timeOffData?.timeOffContractRequirements?.availableEntitlements?.map(
          (entitlement) => entitlement?.definition,
        ),
      ),
    ),
    clause: timeOffData?.timeOffContractRequirements?.clause,
    allAvailableDefinitions: compact(
      timeOffData?.timeOffContractRequirements?.availableEntitlements?.map(
        (entitlement) => entitlement?.definition,
      ),
    ),
    assignedTimeOffEntitlements:
      timeOffData?.timeOffContractRequirements?.assignedEntitlements,
    refetch: refetchTimeOffData,
  };
};

export default useGetTimeOffData;
