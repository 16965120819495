import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, PillTag } from '@multiplier/common';
import { Dictionary } from 'lodash';
import { theme } from 'twin.macro';

import {
  ContractOnboarding,
  ContractType,
  CountryWorkStatus,
  LegalBindingType,
  Maybe,
  OnboardingTask,
} from '../../../__generated__/graphql';
import { ChecklistSectionHeader } from '../onboarding-checklist-sidebar/layout';
import CompletedTaskSection from './completed-task-section';
import PendingTaskSection from './pending-task-section';

const TaskListHeader: React.FC<{ pendingCount: number }> = ({
  pendingCount,
}) => {
  const { t } = useTranslation('contract-onboarding');

  return (
    <div tw="flex flex-row items-center justify-between">
      <div tw="flex flex-row items-center gap-x-extra-small">
        <Icon
          tw="h-small w-base"
          name="list"
          fill={theme`colors.icon-primary`}
        />
        <ChecklistSectionHeader>
          {t('onboarding-checklist.task-list.header', 'Onboarding tasks')}
        </ChecklistSectionHeader>
      </div>
      <PillTag
        tw="text-pxs"
        label={t('onboarding-checklist.task-list.pending-tasks-left', {
          defaultValue: '{{count}} tasks left',
          replace: { count: pendingCount },
          count: pendingCount,
        })}
        variant="PREPROCESSING"
      />
    </div>
  );
};

const OnboardingTasks: React.FC<{
  contractId: string;
  processedTasks: Dictionary<Dictionary<OnboardingTask[]>>;
  unprocessedTasks: ContractOnboarding['tasks'];
  completedCount: number;
  pendingCount: number;
  complianceType?: Maybe<LegalBindingType>;
  workStatus?: Maybe<CountryWorkStatus>;
  contractType?: Maybe<ContractType>;
  handleCloseSidePanel?: () => void;
  showHeader?: boolean;
  viewOnly?: boolean;
}> = ({
  processedTasks,
  unprocessedTasks,
  completedCount,
  pendingCount,
  contractId,
  complianceType,
  workStatus,
  contractType,
  handleCloseSidePanel = () => ({}),
  showHeader = true,
  viewOnly = false,
  ...props
}) => (
  <div
    tw="flex flex-col gap-y-base text-ps font-semibold text-text-tertiary"
    {...props}
  >
    {showHeader && <TaskListHeader pendingCount={pendingCount} />}
    <div tw="rounded-base border border-border-primary overflow-hidden">
      <PendingTaskSection
        contractId={contractId}
        taskList={processedTasks.pending}
        unprocessedTasks={unprocessedTasks}
        handleCloseSidePanel={handleCloseSidePanel}
        complianceType={complianceType}
        workStatus={workStatus}
        contractType={contractType}
        viewOnly={viewOnly}
      />
      <CompletedTaskSection
        taskList={processedTasks.completed}
        completedCount={completedCount}
        handleCloseSidePanel={handleCloseSidePanel}
      />
    </div>
  </div>
);

export default OnboardingTasks;
