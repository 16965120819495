import { useEffect, useState } from 'react';

export interface ChangeWarningStatusArgs {
  sectionName: EmployeeDetailSections;
  value: boolean;
}

export enum EmployeeDetailSections {
  BASIC_DETAILS,
  EMPLOYMENT_DETAILS,
  COMPENSATION_DETAILS,
  CONTRIBUTION_DETAILS,
  PAYSLIP_DETAILS,
  BANK_DETAILS,
  INSURANCE_DETAILS,
  CONTRACT_DETAILS,
  DOCUMENT_DETAILS,
}

const useShowUncommittedChangesWarning = (): {
  shouldShowUncommittedChanges: boolean;
  changeWarningStatus: (values: ChangeWarningStatusArgs) => void;
} => {
  const [
    shouldShowUncommittedChanges,
    setShouldShowUncommittedChanges,
  ] = useState(false);

  const [uncommittedChanges, setIsUncommittedChanges] = useState({
    [EmployeeDetailSections.BASIC_DETAILS]: false,
    [EmployeeDetailSections.EMPLOYMENT_DETAILS]: false,
    [EmployeeDetailSections.COMPENSATION_DETAILS]: false,
    [EmployeeDetailSections.PAYSLIP_DETAILS]: false,
    [EmployeeDetailSections.BANK_DETAILS]: false,
    [EmployeeDetailSections.INSURANCE_DETAILS]: false,
    [EmployeeDetailSections.CONTRACT_DETAILS]: false,
    [EmployeeDetailSections.DOCUMENT_DETAILS]: false,
  });

  const changeWarningStatus = (values: ChangeWarningStatusArgs) => {
    setIsUncommittedChanges((prevState) => ({
      ...prevState,
      [values.sectionName]: values.value,
    }));
  };

  useEffect(() => {
    const sectionWarnings = Object.entries(uncommittedChanges).filter(
      ([key, value]) => key && value !== false,
    );
    if (sectionWarnings.length === 0) {
      setShouldShowUncommittedChanges(false);
    } else {
      setShouldShowUncommittedChanges(true);
    }
  }, [uncommittedChanges]);

  return { shouldShowUncommittedChanges, changeWarningStatus };
};

export default useShowUncommittedChangesWarning;
