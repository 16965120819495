import React, { useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { format, isValid, parse } from 'date-fns';

import {
  TableFilterConfigType,
  TableFilterFormMonthRangeValue,
  TableFilterFormRangeValue,
  TableFilterFormValue,
  TableFilterTypes,
} from '../../../../types';

const FilterText: React.FC<{
  selectedFilters?: { [key: string]: TableFilterFormValue };
  filters: TableFilterConfigType;
  leadingText?: string;
}> = ({ selectedFilters, filters, leadingText }) => {
  const { t } = useTranslation('common.table');

  const processedFilters = useMemo(
    () =>
      Object.keys(filters).reduce(
        (prev, curr) => {
          if (filters[curr].leadingFilterText) {
            prev.leading[curr] = filters[curr];
          } else {
            prev.following[curr] = filters[curr];
          }
          return prev;
        },
        {
          leading: {} as TableFilterConfigType,
          following: {} as TableFilterConfigType,
        },
      ),
    [filters],
  );

  const formatDate = (date?: Date | string | null) =>
    date && isValid(new Date(date))
      ? format(
          parse(
            new Date(date).toISOString().slice(0, 10),
            'yyyy-MM-dd',
            new Date(),
          ),
          'd MMM yyyy',
        )
      : '-';

  return (
    <div tw="w-full">
      <span tw="text-ps text-grey01">
        {t('filter-text.showing', 'Showing:')} &nbsp;
      </span>

      <span tw="text-ps text-foreground" data-testid="filter-text">
        <span data-testid="leading-text">
          {leadingText ?? t('filter-text.all', 'All')}&nbsp;
          {Object.keys(processedFilters.leading).map((leadingKey) => (
            <React.Fragment key={leadingKey}>
              {processedFilters.leading[leadingKey]?.options?.find(
                (option) => option.key === selectedFilters?.[leadingKey],
              ) ? (
                <span tw="font-semibold">
                  &nbsp;
                  {
                    processedFilters.leading[leadingKey]?.options?.find(
                      (option) => option.key === selectedFilters?.[leadingKey],
                    )?.filterText
                  }
                </span>
              ) : (
                <span>
                  &nbsp;{processedFilters.leading[leadingKey]?.filterDefault}
                </span>
              )}
            </React.Fragment>
          ))}
        </span>
        {selectedFilters &&
          Object.keys(selectedFilters).map((selectedFilter, idx) => (
            <React.Fragment key={selectedFilter}>
              {processedFilters.following[selectedFilter] ? (
                <span data-testid="following-text">
                  {filters[selectedFilter].prefix}&nbsp;
                  {filters[selectedFilter].type === TableFilterTypes.TEXT && (
                    <span tw="font-semibold">
                      &ldquo;{selectedFilters[selectedFilter]}&rdquo;
                    </span>
                  )}
                  {(filters[selectedFilter].type ===
                    TableFilterTypes.DROPDOWN ||
                    filters[selectedFilter].type ===
                      TableFilterTypes.CUSTOM) && (
                    <span tw="font-semibold">
                      {
                        filters[selectedFilter]?.options?.find(
                          (option) =>
                            String(option.key) ===
                            String(selectedFilters[selectedFilter]),
                        )?.label
                      }
                    </span>
                  )}
                  {filters[selectedFilter].type ===
                    TableFilterTypes.DATE_RANGE && (
                    <span tw="font-semibold">
                      {formatDate(
                        (selectedFilters[selectedFilter] as {
                          startDate?: Date | string | null;
                          endDate?: Date | string | null;
                        })?.startDate,
                      )}
                      &nbsp;{t('filter-text.to', 'to')}&nbsp;
                      {formatDate(
                        (selectedFilters[selectedFilter] as {
                          startDate?: Date | string | null;
                          endDate?: Date | string | null;
                        })?.endDate,
                      )}
                    </span>
                  )}
                  {filters[selectedFilter].type ===
                    TableFilterTypes.MULTI_DROPDOWN &&
                    Array.isArray(selectedFilters[selectedFilter]) && (
                      <>
                        {(selectedFilters[selectedFilter] as string[])?.map(
                          (value, ind, array) => (
                            <span tw="font-semibold" key={value}>
                              {
                                filters[selectedFilter]?.options?.find(
                                  (option) => String(option.key) === value,
                                )?.label
                              }
                              {ind !== array?.length - 1 && <>,</>}
                              &nbsp;
                            </span>
                          ),
                        )}
                      </>
                    )}
                  {filters[selectedFilter].type ===
                    TableFilterTypes.MULTI_TEXT_INPUT &&
                    Array.isArray(selectedFilters[selectedFilter]) && (
                      <>
                        {(selectedFilters[selectedFilter] as string[])?.map(
                          (value, ind, array) => (
                            <span tw="font-semibold" key={value}>
                              {value}
                              {ind !== array?.length - 1 && <>,</>}
                              &nbsp;
                            </span>
                          ),
                        )}
                      </>
                    )}
                  {filters[selectedFilter].type === TableFilterTypes.MONTH &&
                    isValid(
                      new Date(selectedFilters[selectedFilter] as string),
                    ) && (
                      <span tw="font-semibold">
                        {format(
                          new Date(selectedFilters[selectedFilter] as string),
                          'MMMM yyyy',
                        )}
                      </span>
                    )}
                  {filters[selectedFilter].type === TableFilterTypes.RANGE && (
                    <span tw="font-semibold">
                      {
                        (selectedFilters[
                          selectedFilter
                        ] as TableFilterFormRangeValue).minimum
                      }
                      &nbsp;{t('filter-range.to', 'to')}&nbsp;
                      {
                        (selectedFilters[
                          selectedFilter
                        ] as TableFilterFormRangeValue).maximum
                      }
                    </span>
                  )}
                  {filters[selectedFilter].type ===
                    TableFilterTypes.MONTH_RANGE && (
                    <span tw="font-semibold">
                      {
                        (selectedFilters[
                          selectedFilter
                        ] as TableFilterFormMonthRangeValue).startMonth
                      }
                      &nbsp;{t('filter-range.to', 'to')}&nbsp;
                      {
                        (selectedFilters[
                          selectedFilter
                        ] as TableFilterFormMonthRangeValue).endMonth
                      }
                    </span>
                  )}
                  {idx !== Object.keys(selectedFilters).length - 1 && (
                    <>&nbsp;{t('filter-text.and', 'and')}&nbsp;</>
                  )}
                </span>
              ) : (
                ''
              )}
            </React.Fragment>
          ))}
      </span>
    </div>
  );
};

export default FilterText;
