import React from 'react';

import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { CardSeparator, Icon } from '@multiplier/common';
import { Dictionary } from 'lodash';
import { theme } from 'twin.macro';

import { OnboardingTask } from '../../../__generated__/graphql';
import useTaskAssignee from '../../hooks/task-assignee';
import { TaskEntryWrapper } from './tasks';

const CompletedTaskSection: React.FC<{
  taskList: Dictionary<OnboardingTask[]>;
  completedCount: number;
  handleCloseSidePanel: () => void;
}> = ({ taskList, completedCount, handleCloseSidePanel }) => {
  const { t } = useTranslation('contract-onboarding');
  const [expanded, toggleExpanded] = useToggle(false);

  if (!taskList) return null;

  return (
    <>
      <button
        type="button"
        tw="p-base bg-background-primary text-text-action w-full border-b border-border-primary"
        onClick={toggleExpanded}
      >
        <div tw="flex flex-row items-center justify-between w-full">
          <p>
            {expanded
              ? t(
                  'onboarding-checklist.task-list.hide-completed-tasks',
                  'Hide completed tasks',
                )
              : t('onboarding-checklist.task-list.show-completed-tasks', {
                  defaultValue: 'Show completed tasks ({{ count }})',
                  replace: {
                    count: completedCount,
                  },
                  count: completedCount,
                })}
          </p>
          <Icon
            name={expanded ? 'caret-up' : 'caret-down'}
            fill={theme`colors.icon-primary`}
          />
        </div>
      </button>

      {expanded &&
        Object.entries(taskList).map(([pendingOn, groupedTasks]) => (
          <CompletedTaskGroup
            key={pendingOn}
            groupedTasks={groupedTasks}
            handleCloseSidePanel={handleCloseSidePanel}
          />
        ))}
    </>
  );
};
const CompletedTaskGroup: React.FC<{
  groupedTasks: OnboardingTask[];
  handleCloseSidePanel: () => void;
}> = ({ groupedTasks, handleCloseSidePanel }) => {
  const { t } = useTranslation('contract-onboarding');

  const completedBy = useTaskAssignee(groupedTasks[0]);

  if (groupedTasks?.length === 0) return null;

  return (
    <div tw="flex flex-col bg-background-primary">
      <div tw="flex flex-col gap-y-base p-base">
        <p>
          {t('onboarding-checklist.task-list.completed-by', {
            defaultValue: 'Completed by {{ completedBy }}',
            replace: { completedBy },
          })}
        </p>
        {groupedTasks.map((task) => (
          <TaskEntryWrapper
            key={`${task.name}${task.pendingOn?.id}`}
            task={task}
            handleCloseSidePanel={handleCloseSidePanel}
          />
        ))}
      </div>
      <CardSeparator />
    </div>
  );
};

export default CompletedTaskSection;
