import tw, { theme } from 'twin.macro';

import { StepperType } from './types';

export const colorStyles = {
  [StepperType.PROGRESS]: tw`bg-background-tertiary`,
  [StepperType.DEFAULT]: tw`bg-background-tertiary`,
  [StepperType.DONE]: tw`bg-background-action`,
  [StepperType.ERROR]: tw`bg-background-warning`,
};

export const iconStyleProps = {
  [StepperType.PROGRESS]: {
    iconName: 'circle',
    iconStyle: tw`h-base w-base`,
    iconFill: theme`colors.transparent`,
    iconStroke: theme`colors.white`,
  },
  [StepperType.DEFAULT]: {
    iconName: 'circle',
    iconStyle: tw`h-base w-base`,
    iconFill: theme`colors.transparent`,
    iconStroke: theme`colors.icon-primary`,
  },
  [StepperType.DONE]: {
    iconName: 'check',
    iconStyle: tw`h-base w-base`,
    iconFill: theme`colors.icon-inverted-primary`,
    iconStroke: theme`colors.icon-inverted-primary`,
  },
  [StepperType.ERROR]: {
    iconName: 'cross',
    iconStyle: tw`h-base w-base`,
    iconFill: theme`colors.icon-primary`,
    iconStroke: theme`colors.transparent`,
  },
};
