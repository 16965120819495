import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Column,
  Row,
  useExpanded,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useDeepCompareEffect, useMedia } from 'react-use';

import { isNil } from 'lodash';
import tw, { theme } from 'twin.macro';

import Card from '../card';
import Checkbox from '../checkbox';
import {
  useRemoveSearchParams,
  useSearchParams,
  useUpdateSearchParams,
} from '../hooks/url';
import Icon from '../icon';
import Loader from '../loader';
import CheckBoxTooltipWrapper from '../table-v2/components/check-box-tooltip-wrapper';
import { ThemeContext } from '../theme-provider';
import { TableBodyProps, TableDataObjectType } from '../types';
import { classified } from '../utils';
import { prependTableIdForUrlParams } from '../utils/table-utils';
import GroupedRow from './components/grouped-row';
import MobileTable from './components/mobile-table';
import Pagination from './components/pagination';
import StyledRow from './components/styled-row';
import TableCell from './components/table-cell';
import TableFilters from './components/table-filters';
import TableHead, { TableHeadProps } from './components/table-head';
import TableRow, { TableRowProps } from './components/table-row';

interface BaseTableProps
  extends Omit<
    TableBodyProps,
    'disableSortBy' | 'renderMobileItem' | 'mobileData'
  > {
  disableSortBy: boolean;
}

const Table: React.FC<React.PropsWithChildren<{ loading?: boolean }>> & {
  Body: React.FC<TableBodyProps>;
  Head: React.FC<TableHeadProps>;
  Row: React.FC<TableRowProps>;
  Cell: React.FC<React.HTMLProps<HTMLTableCellElement>>;
  Filters: typeof TableFilters;
  Pagination: typeof Pagination;
} = ({ loading = false, children, ...props }) => (
  <div data-testid="table" tw="w-full" {...props}>
    {loading ? <Loader.Table /> : children}
  </div>
);

const BaseTable = ({
  tableId,
  data,
  columns,
  disableSortBy,
  handleSort,
  handleRowClick,
  defaultRows = 5,
  shouldHighlightRow,
  handleSelectedRows,
  widget,
  disableSelection = true,
  // remove this selectedIds use defaultSelectedIds
  selectedIds,
  paginationType = 'client-side',
  bePageCount = -1,
  resetRows,
  renderExpandedRow,
  tableCellStyle,
  tableHeadCellStyle,
  tableHeaderRowStyle,
  autoResetSelectedRows = false,
  disablePagination = false,
  expandIconPosition = 'right',
  totalText,
  defaultSelectedIds = [],
  shouldRenderIndeterminateState = false,
  onCheckboxClick,
  boldColumnHeader = false,
  bgRowsType = 'even',
  checkboxStyle,
  rowStyles,
  onPageChange,
  disableUrlBinding = false,
  isAllRowsSelected,
  handleAllRowSelectClick,
  getDisabledTooltipMessage,
  ignoreDisabledRowsForSelectAllAction,
  horizontalScroll,
  additionalPageSizes,
  expandSubRows,
  renderSubRows,
  autoResetExpanded,
  groupBy,
  groupByLabel,
  defaultSort,
  ...props
}: BaseTableProps) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const rowsKey = prependTableIdForUrlParams('rows', tableId);
  const pageKey = prependTableIdForUrlParams('page', tableId);
  const sortByKey = prependTableIdForUrlParams('sortBy', tableId);
  const sortDirKey = prependTableIdForUrlParams('sortDir', tableId);

  const [rows, currentPage, sort, sortDir] = useSearchParams(
    rowsKey,
    pageKey,
    sortByKey,
    sortDirKey,
  );
  const updateSearchParams = useUpdateSearchParams();
  const removeSearchParams = useRemoveSearchParams();

  const selectedIdsRef = useRef<string[]>([]);

  useEffect(() => {
    if (resetRows) selectedIdsRef.current = [];
  }, [resetRows]);

  useEffect(() => {
    if (selectedIds) {
      selectedIdsRef.current = selectedIds;
    }
  }, [selectedIds]);

  const getCorrectToggleForSelectAll = (
    getToggleAllRowsSelectedPropsChecked?: boolean,
  ) => {
    if (ignoreDisabledRowsForSelectAllAction) {
      const nonDisabledRows = tableInstance.rows?.filter(
        (row) => !row?.original?.disableRowSelection,
      );
      return (
        !!nonDisabledRows?.length &&
        nonDisabledRows.every((row) => row?.isSelected)
      );
    }
    return getToggleAllRowsSelectedPropsChecked;
  };

  const pageSize = Number(
    !disableUrlBinding ? rows ?? defaultRows : defaultRows,
  );
  const pageCountClient = Math.ceil((data.length + pageSize - 1) / pageSize);

  const tableInstance = useTable(
    {
      columns: columns as Column<TableDataObjectType>[],
      data,
      manualSortBy: true,
      manualPagination: paginationType === 'server-side',
      autoResetSelectedRows:
        autoResetSelectedRows || paginationType === 'server-side',
      pageCount:
        paginationType === 'client-side' ? pageCountClient : bePageCount ?? -1,
      disableSortBy,
      initialState: {
        pageSize,
        pageIndex: Number(!disableUrlBinding ? currentPage ?? 1 : 1) - 1,
        sortBy:
          !disableUrlBinding && sort
            ? [{ id: sort, desc: sortDir === 'desc' }]
            : defaultSort ?? [],
        hiddenColumns: columns
          ?.filter((col) => col?.hide)
          ?.map((col) => col?.id ?? ''),
      },
      expandSubRows,
      autoResetExpanded,
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!disableSelection) {
        hooks.visibleColumns.push((col) => [
          // Let's make a column for selection
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <Checkbox
                  {...getToggleAllRowsSelectedProps()}
                  checked={
                    handleAllRowSelectClick
                      ? isAllRowsSelected
                      : getCorrectToggleForSelectAll(
                          getToggleAllRowsSelectedProps().checked,
                        )
                  }
                  css={checkboxStyle}
                  shouldRenderIndeterminateState={
                    shouldRenderIndeterminateState
                  }
                  onChange={(e) => {
                    e.persist();
                    if (handleAllRowSelectClick) {
                      handleAllRowSelectClick(e.target.checked);
                      return;
                    }
                    if (e.target.checked) {
                      const defaultSelected: string[] = [];
                      tableInstance.rows.forEach((row) => {
                        if (
                          row?.original?.disableRowSelection &&
                          row?.isSelected
                        ) {
                          defaultSelected.push(
                            (row.original as { id: string }).id,
                          );
                        } else if (!row?.original?.disableRowSelection) {
                          tableInstance.toggleRowSelected(row.id, true);
                          defaultSelected.push(
                            (row.original as { id: string }).id,
                          );
                        }
                      });
                      selectedIdsRef.current = defaultSelected;
                      if (handleSelectedRows)
                        handleSelectedRows(selectedIdsRef.current);
                    } else {
                      const defaultSelected: string[] = [];
                      tableInstance.rows.forEach((row) => {
                        if (row.isSelected && row.original?.disableRowSelection)
                          defaultSelected.push(
                            (row.original as { id: string }).id,
                          );
                        else tableInstance.toggleRowSelected(row.id, false);
                      });
                      selectedIdsRef.current = defaultSelected;
                      if (handleSelectedRows)
                        handleSelectedRows(selectedIdsRef.current);
                    }
                  }}
                  data-testid="table-header-checkbox"
                />
              </div>
            ),
            Cell: ({ row }: { row: Row }) => (
              <div>
                <CheckBoxTooltipWrapper
                  rowId={(row?.original as { id: string | number })?.id}
                  isDisabled={
                    (row.original as { disableRowSelection: boolean })
                      ?.disableRowSelection
                  }
                  getDisabledTooltipMessage={getDisabledTooltipMessage}
                >
                  <Checkbox
                    css={[
                      !isNewThemeApplied &&
                        tw`disabled:(bg-slateGrey100 checked:bg-primaryBlue300)`,
                      checkboxStyle,
                    ]}
                    disabled={
                      (row.original as { disableRowSelection: boolean })
                        .disableRowSelection
                    }
                    {...row?.getToggleRowSelectedProps()}
                    onChange={(e) => {
                      tableInstance.toggleRowSelected(row.id, e.target.checked);
                      if (
                        e.target.checked &&
                        (row.original as { id: string }).id &&
                        handleSelectedRows
                      ) {
                        selectedIdsRef.current = [
                          ...(selectedIdsRef.current ?? []),
                          String((row.original as { id: string }).id),
                        ];
                        handleSelectedRows(selectedIdsRef.current);
                      } else if (handleSelectedRows) {
                        selectedIdsRef.current = selectedIdsRef.current?.filter(
                          (id) =>
                            id !== String((row.original as { id: string }).id),
                        );
                        handleSelectedRows(selectedIdsRef.current);
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onCheckboxClick) onCheckboxClick(e);
                    }}
                  />
                </CheckBoxTooltipWrapper>
              </div>
            ),
          },
          ...col,
        ]);
      }
    },
  );

  useDeepCompareEffect(() => {
    if (paginationType !== 'server-side')
      tableInstance.toggleAllRowsSelected(false);
    const alreadySelectedRowIds = (Array.from(data) ?? []).reduce(
      (acc, row, ind) => {
        if (row?.id && defaultSelectedIds.includes(row?.id as string)) {
          acc[ind] = true;
          if (row?.subRows?.length) {
            row?.subRows.forEach((_, subRowIndex) => {
              acc[`${ind}.${subRowIndex}`] = true;
            });
          }
        }
        return acc;
      },
      {} as Record<number | string, boolean>,
    );
    if (Object.keys(alreadySelectedRowIds).length) {
      Object.keys(alreadySelectedRowIds)?.forEach((key) => {
        tableInstance.toggleRowSelected(key, true);
      });
    }
    if (Array.isArray(defaultSelectedIds)) {
      selectedIdsRef.current = defaultSelectedIds;
      if (handleSelectedRows) handleSelectedRows(defaultSelectedIds);
    }
  }, [defaultSelectedIds, data, paginationType]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    toggleGroupBy,
    page,
    state: { sortBy },
  } = tableInstance;

  useEffect(() => {
    if (data && !isNil(groupBy)) {
      toggleGroupBy(groupBy);
    }
  }, [data, groupBy]);

  useEffect(() => {
    if (handleSort) {
      handleSort(sortBy); // Allow to sort without url binding
      if (!disableUrlBinding) {
        if (sortBy.length > 0) {
          updateSearchParams([
            {
              key: sortByKey,
              value: sortBy[0].id,
            },
            {
              key: sortDirKey,
              value: sortBy[0].desc ? 'desc' : 'asc',
            },
          ]);
        } else {
          removeSearchParams([sortByKey, sortDirKey]);
        }
      }
    }
  }, [sortBy]);

  return (
    <Card data-testid={tableId} {...props}>
      <div css={[horizontalScroll && tw`overflow-x-auto`]}>
        {widget}
        <table {...getTableProps()} tw="w-full">
          {headerGroups.map((headerGroup) => (
            <colgroup {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, ind) => (
                <col
                  css={[tw`w-auto`]}
                  {...column.getHeaderProps({
                    style: {
                      width:
                        !disableSelection && ind === 0 ? 'auto' : column.width,
                    },
                  })}
                />
              ))}
            </colgroup>
          ))}
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                css={[tableHeaderRowStyle, tw`text-ps leading-normal`]}
              >
                {headerGroup.headers.map((column, ind) => (
                  <th
                    css={[
                      isNewThemeApplied
                        ? tw`pr-large py-large font-normal text-left text-text-tertiary text-ps first-of-type:pl-large last-of-type:pr-large`
                        : tw`pr-24 py-24 font-normal text-left text-grey02 first-of-type:pl-24 last-of-type:pr-24`,
                      column.id === 'actions' && tw`text-right`,
                      column.canSort && tw`cursor-pointer`,
                      boldColumnHeader &&
                        (isNewThemeApplied
                          ? tw`font-semibold`
                          : tw`text-neutral500 font-medium`),
                      tableCellStyle,
                      tableHeadCellStyle,
                    ]}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    {...column.getHeaderProps({
                      style: {
                        width:
                          !disableSelection && ind === 0
                            ? 'auto'
                            : column.width,
                      },
                    })}
                    data-testid={`columnheader-${column.id}`}
                  >
                    <span>{column.render('Header') as any}</span>
                    {column.isSorted && (
                      <div tw="inline opacity-50">
                        {column.isSortedDesc ? (
                          <Icon
                            name="caret-down"
                            css={[
                              tw`inline-block`,
                              isNewThemeApplied
                                ? tw`mt-tiny ml-tiny`
                                : tw`mb-6 ml-6`,
                            ]}
                          />
                        ) : (
                          <Icon
                            name="caret-up"
                            css={[
                              tw`inline-block`,
                              isNewThemeApplied
                                ? tw`mb-tiny ml-tiny`
                                : tw`mb-6 ml-6`,
                            ]}
                          />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            data-testid="table-body"
            tw="text-p text-text-primary mobile:border-none border-t border-border-primary"
            {...classified}
          >
            {page.map((row, i) => {
              prepareRow(row);
              // row grouping using react-table and rendering the subrows inside grouped rows
              if (row?.isGrouped) {
                return (
                  <React.Fragment key={row.getRowProps().key}>
                    <GroupedRow
                      row={row}
                      bgRowsType={bgRowsType}
                      rowStyles={rowStyles}
                      shouldHighlightRow={shouldHighlightRow}
                      handleRowClick={handleRowClick}
                      disableSelection={disableSelection}
                      tableCellStyle={tableCellStyle}
                      expandIconPosition={expandIconPosition}
                      renderExpandedRow={renderExpandedRow}
                      prepareRow={(currRow: Row<TableDataObjectType>) =>
                        prepareRow(currRow)
                      }
                      groupByLabel={groupByLabel}
                      isNewThemeApplied={isNewThemeApplied}
                    />
                  </React.Fragment>
                );
              }

              // rendering rows and subrows without grouping. also supports rendering expanded rows.
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <>
                    {row.original?.insert && row.original.insert(row.original) && (
                      <tr>
                        <td colSpan={row.cells.length}>
                          {row.original.insert(row.original)}
                        </td>
                      </tr>
                    )}
                    <TableRow
                      row={row}
                      itemIndex={i}
                      bgRowsType={bgRowsType}
                      rowStyles={rowStyles}
                      shouldHighlightRow={shouldHighlightRow}
                      handleRowClick={handleRowClick}
                      disableSelection={disableSelection}
                      tableCellStyle={tableCellStyle}
                      expandIconPosition={expandIconPosition}
                      renderExpandedRow={renderExpandedRow}
                      isNewThemeApplied={isNewThemeApplied}
                    />
                    {row?.isExpanded && renderExpandedRow && (
                      <StyledRow
                        key={`${row.getRowProps().key}-subRow`}
                        bgDark={row.index % 2 === 0}
                        highlight={
                          shouldHighlightRow && shouldHighlightRow(row.original)
                        }
                        isNewThemeApplied={isNewThemeApplied}
                      >
                        {renderExpandedRow(row, prepareRow)}
                      </StyledRow>
                    )}
                    {row?.isExpanded && renderSubRows && renderSubRows(row)}
                  </>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {!disablePagination && (
        <Pagination
          tableId={tableId}
          totalText={totalText}
          tableInstance={tableInstance}
          disableUrlBinding={disableUrlBinding}
          onPageChange={onPageChange}
          additionalPageSizes={additionalPageSizes}
        />
      )}
    </Card>
  );
};

Table.Body = ({
  tableId,
  data,
  mobileData,
  columns,
  disableSortBy = true,
  handleSort,
  renderMobileItem,
  handleRowClick,
  defaultRows,
  shouldHighlightRow,
  handleSelectedRows,
  widget,
  disableSelection = true,
  paginationType,
  bePageCount,
  resetRows,
  selectedIds,
  renderExpandedRow,
  tableCellStyle,
  tableHeadCellStyle,
  tableHeaderRowStyle,
  autoResetSelectedRows,
  disablePagination = false,
  expandIconPosition = 'right',
  totalText,
  defaultSelectedIds = [],
  shouldRenderIndeterminateState = false,
  onCheckboxClick,
  boldColumnHeader = false,
  bgRowsType = 'even',
  rowStyles,
  onPageChange,
  checkboxStyle,
  disableUrlBinding,
  isAllRowsSelected,
  handleAllRowSelectClick,
  getDisabledTooltipMessage,
  ignoreDisabledRowsForSelectAllAction,
  horizontalScroll = false,
  additionalPageSizes,
  expandSubRows = false,
  renderSubRows,
  autoResetExpanded,
  groupBy,
  groupByLabel,
  defaultSort,
  ...props
}) => {
  const isMobile = useMedia(`(max-width: ${theme`screens.mobile.max`})`);

  // This is a temporary solution to force a rerender which can be removed once pagination is handled in the BE
  const [mobileTableKey, setMobileTableKey] = useState(0);
  useEffect(() => {
    setMobileTableKey((prev) => prev + 1);
  }, [data]);

  return isMobile && renderMobileItem ? (
    <MobileTable
      data={mobileData ?? data}
      renderItem={renderMobileItem}
      key={mobileTableKey}
    />
  ) : (
    <BaseTable
      tableId={tableId}
      widget={widget}
      data={data}
      columns={columns}
      disableSortBy={disableSortBy}
      handleSort={handleSort}
      handleRowClick={handleRowClick}
      defaultRows={defaultRows}
      shouldHighlightRow={shouldHighlightRow}
      handleSelectedRows={handleSelectedRows}
      disableSelection={disableSelection}
      paginationType={paginationType}
      bePageCount={bePageCount}
      resetRows={resetRows}
      selectedIds={selectedIds}
      renderExpandedRow={renderExpandedRow}
      tableCellStyle={tableCellStyle}
      tableHeadCellStyle={tableHeadCellStyle}
      bgRowsType={bgRowsType}
      tableHeaderRowStyle={tableHeaderRowStyle}
      autoResetSelectedRows={autoResetSelectedRows}
      disablePagination={disablePagination}
      expandIconPosition={expandIconPosition}
      totalText={totalText}
      defaultSelectedIds={defaultSelectedIds}
      shouldRenderIndeterminateState={shouldRenderIndeterminateState}
      onCheckboxClick={onCheckboxClick}
      boldColumnHeader={boldColumnHeader}
      checkboxStyle={checkboxStyle}
      rowStyles={rowStyles}
      onPageChange={onPageChange}
      disableUrlBinding={disableUrlBinding}
      isAllRowsSelected={isAllRowsSelected}
      handleAllRowSelectClick={handleAllRowSelectClick}
      getDisabledTooltipMessage={getDisabledTooltipMessage}
      ignoreDisabledRowsForSelectAllAction={
        ignoreDisabledRowsForSelectAllAction
      }
      horizontalScroll={horizontalScroll}
      additionalPageSizes={additionalPageSizes}
      expandSubRows={expandSubRows}
      renderSubRows={renderSubRows}
      autoResetExpanded={autoResetExpanded}
      groupBy={groupBy}
      groupByLabel={groupByLabel}
      defaultSort={defaultSort}
      {...props}
    />
  );
};

Table.Head = TableHead;
Table.Filters = TableFilters;
Table.Pagination = Pagination;
Table.Cell = TableCell;
Table.Row = TableRow;

export default Table;
