import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { format, isValid as isValidDate, parse } from 'date-fns';

import DatePicker from '../../../../../date-picker';
import { TableFilterFormMonthRangeValue } from '../../../../types';
import Label from '../label';

const MonthRangeFilterInput: React.FC<{
  value: TableFilterFormMonthRangeValue;
  onChange: (val: TableFilterFormMonthRangeValue) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation('common.table');

  return (
    <div tw="grid grid-cols-2 gap-small">
      <div>
        <Label>{t('filters.startMonth', 'From:')}</Label>
        <DatePicker
          tw="mt-extra-small"
          data-testid="month-range-start"
          value={value?.startMonth}
          max={(value?.endMonth && new Date(value?.endMonth)) ?? undefined}
          onChange={(dateString) => {
            const date = parse(dateString, 'yyyy-MM-dd', new Date());
            onChange({
              startMonth: isValidDate(date)
                ? format(date, 'yyyy-MM')
                : date.toISOString(),
              endMonth: value?.endMonth,
            });
          }}
          loading={false}
          monthSelector
          placeholder="Month"
        />
      </div>
      <div>
        <Label>{t('filters.endMonth', 'To:')}</Label>
        <DatePicker
          tw="mt-extra-small"
          data-testid="month-range-end"
          value={value?.endMonth}
          min={(value?.startMonth && new Date(value?.startMonth)) ?? undefined}
          onChange={(dateString) => {
            const date = parse(dateString, 'yyyy-MM-dd', new Date());
            onChange({
              startMonth: value?.startMonth,
              endMonth: isValidDate(date)
                ? format(date, 'yyyy-MM')
                : date.toISOString(),
            });
          }}
          loading={false}
          monthSelector
          placeholder="Month"
        />
      </div>
    </div>
  );
};

export default MonthRangeFilterInput;
