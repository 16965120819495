import React from 'react';

import tw, { TwStyle, styled } from 'twin.macro';

import Icon from '../icon';
import ToolTip from '../tooltip';

interface ModuleHeaderProps {
  header: string | React.ReactNode;
  description?: React.ReactNode;
  descriptionStyle?: TwStyle;
  tooltipText?: string;
  variant?: 'default' | 'dense';
}

interface LayoutStyleProps {
  isForm?: boolean;
  fullWidth?: boolean;
  wide?: boolean;
}

const ModuleHeaderWrapper = styled.div(
  ({ variant }: { variant?: ModuleHeaderProps['variant'] }) => [
    variant === 'default' && tw`mb-extra-large`,
    tw`mobile:mx-base flex flex-col gap-y-small`,
  ],
);
const ModuleHeader = tw.h4`text-h5 tablet:text-h4 desktop:text-h4 font-semibold text-text-primary`;
const ModuleDescription = styled.p(
  ({
    descriptionStyle,
  }: {
    descriptionStyle?: ModuleHeaderProps['descriptionStyle'];
  }) => [tw`text-18 text-text-tertiary`, descriptionStyle],
);

const Header: React.FC<ModuleHeaderProps> = ({
  header,
  description,
  descriptionStyle,
  tooltipText,
  variant = 'default',
  ...props
}) => (
  <ModuleHeaderWrapper variant={variant} {...props}>
    <ModuleHeader>{header}</ModuleHeader>
    {description && (
      <div tw="flex flex-row items-center gap-x-small">
        <ModuleDescription descriptionStyle={descriptionStyle}>
          {description}
        </ModuleDescription>
        <div>
          {tooltipText && (
            <ToolTip
              styles={tw`text-center`}
              content={tooltipText}
              variant="bottom"
            >
              <Icon tw="h-20 w-20" name="info" />
            </ToolTip>
          )}
        </div>
      </div>
    )}
  </ModuleHeaderWrapper>
);

const Layout = styled.div(({ isForm, fullWidth, wide }: LayoutStyleProps) => [
  isForm && tw`m-auto desktop:width[740px] mobile:w-auto mb-base`,
  fullWidth
    ? tw`my-auto mx-extra-large w-full mb-base pt-base`
    : wide
    ? tw`tablet:(px-extra-large mx-auto max-width[960px]) desktop:(mx-auto max-width[1250px] pt-64) w-full p-base mb-base`
    : tw`tablet:px-extra-large desktop:(mx-auto width[960px] pt-64) w-full max-width[960px] py-base mb-base`,
]);

export default {
  Header,
  Layout,
  ModuleHeader,
  ModuleHeaderWrapper,
  ModuleDescription,
};
