/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppFeature } from '@multiplier/growthbook';
import { isNaN, isNil } from 'lodash';
import tw from 'twin.macro';
import * as yup from 'yup';

import Loader from 'common/components/loader';
import IncludeDependantsBenefit from 'contract-onboarding/company/components/include-dependants-benefit';
import {
  useContract,
  useSubmitDefinitionBenefits,
} from 'contract-onboarding/company/hooks';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import InsuranceDetails from 'insurance/components/details';
import ERSplit from 'insurance/components/er-split';
import InsuranceMandatoryCallout from 'insurance/components/insurance-mandatory-callout';
import InsuranceNotes from 'insurance/components/insurance-notes';
import InsuranceTypePartnerName from 'insurance/components/insurance-type-partner-name';
import { groupInsuranceByType } from 'insurance/services/insurance-service';

import {
  Benefit,
  BenefitFilters,
  BenefitInsuranceType,
  BenefitPartnerCountryFilters,
  BenefitPartnerStatus,
  BenefitStatus,
  BenefitType,
  ContractBenefit,
  ContractType,
  CountryCode,
  GetBenefitPartnerCountryDetailsQuery,
  GetCountryInsuranceQuery,
  GetSingleBenefitQuery,
  Maybe,
  PageRequest,
  useGetBenefitPartnerCountryDetailsLazyQuery,
  useGetCountryInsuranceLazyQuery,
  useGetSingleBenefitLazyQuery,
} from '__generated__/graphql';

import {
  getBenefitId,
  getDependentCount,
  getIncludeDependents,
} from '../../../../services/benefits';
import stepConfig from '../../step-config';
import BenefitsViewV2 from './benefit-v2';
import { BenefitFormValues } from './benefit-view-form';

interface GetCountryInsuranceValues {
  variables: {
    country?: CountryCode;
    countryStateCode?: Maybe<string>;
    filters: BenefitFilters;
  };
}

interface GetBenefitPartnerCountryDetailsValues {
  variables: {
    filters: BenefitPartnerCountryFilters;
    pageRequest: PageRequest;
  };
}

interface GetSingleBenefitValues {
  variables: { id: string };
}

export const BenefitsViewForm: React.FC<
  OnboardingStepProps & {
    id?: string;
    country?: Maybe<CountryCode>;
    benefits?: Maybe<Maybe<ContractBenefit>[]>;
    countryStateCode?: Maybe<string>;
    getCountryInsurance: (values: GetCountryInsuranceValues) => void;
    getBenefitPartnerCountryDetails: (
      values: GetBenefitPartnerCountryDetailsValues,
    ) => void;
    countryInsurance?: GetCountryInsuranceQuery;
    benefitPartnerCountryDetails?: GetBenefitPartnerCountryDetailsQuery;
    getSingleBenefit: (values: GetSingleBenefitValues) => void;
    singleBenefit?: GetSingleBenefitQuery;
    loading: boolean;
    onSubmit: (values: BenefitFormValues) => Promise<void>;
    contractType: Maybe<ContractType> | undefined;
    employerPayPercentage?: ContractBenefit['employerPayPercentage'];
  }
> = ({
  currentStep,
  onboardingSteps,
  id,
  country,
  benefits,
  countryStateCode,
  getCountryInsurance,
  getBenefitPartnerCountryDetails,
  countryInsurance,
  benefitPartnerCountryDetails,
  getSingleBenefit,
  singleBenefit,
  loading,
  onSubmit,
  contractType,
  employerPayPercentage,
}) => {
  const [type, setType] = useState<BenefitType>(BenefitType.INSURANCE);
  const { t } = useTranslation('insurance');

  const benefitPartnerCountryData =
    benefitPartnerCountryDetails?.benefitPartnerCountriesWithPagination
      ?.data?.[0] ?? {};

  const availableBenefits = countryInsurance?.benefits as Benefit[];

  useEffect(() => {
    if (country) {
      getCountryInsurance({
        variables: {
          filters: {
            country,
            contractType,
            status: BenefitStatus.ACTIVE,
            isDefault: true,
          },
        },
      });
    }
  }, [country, countryStateCode, contractType]);

  useEffect(() => {
    if (country && contractType) {
      getBenefitPartnerCountryDetails({
        variables: {
          filters: {
            countries: [country],
            memberType: [contractType],
            insuranceType:
              type === BenefitType.INSURANCE
                ? BenefitInsuranceType.INDIVIDUAL
                : BenefitInsuranceType.FAMILY_INSURANCE,
            statuses: [BenefitPartnerStatus.ACTIVE],
          },
          pageRequest: {
            pageSize: 20,
            pageNumber: 0,
          },
        },
      });
    }
  }, [country, contractType, type]);

  const { minimumErSplitPercentage = 0 } = benefitPartnerCountryData ?? {
    minimumErSplitPercentage: 0,
  };

  const methods = useForm<BenefitFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        benefitId: benefitPartnerCountryData?.isInsuranceMandatory
          ? yup.string().uuid().required()
          : yup.string().uuid().nullable(),
        includeDependents: yup.boolean(),
        dependentCount: yup.number().nullable(),
        employerPayPercentage: yup
          .number()
          .max(
            100,
            t(
              'insurance.er-split.max-validation',
              'Employer pay percentage cannot be more than 100%',
            ),
          )
          .test(
            'minimum-er-percentage',
            t('insurance.er-split.min-validation', {
              defaultValue:
                'Minimum contribution has to be {{minimumPercentage}}%',
              replace: {
                minimumPercentage: minimumErSplitPercentage,
              },
            }),
            (value) => {
              if (
                !isNil(value) &&
                benefitPartnerCountryData?.erSplitApplicable &&
                !isNil(minimumErSplitPercentage) &&
                value < minimumErSplitPercentage
              )
                return false;
              return true;
            },
          )
          .typeError(
            t(
              'insurance.er-split.must-be-number',
              'Employer pay percentage must be a number',
            ),
          )
          .nullable(),
      }),
    ),
    defaultValues: {
      employerPayPercentage: '100',
      dependentCount: '1',
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isValid, errors },
    setValue,
    getValues,
    trigger,
  } = methods;

  useEffect(() => {
    const dependantCount = getDependentCount(benefits);
    if (benefits && benefits.length > 0) {
      reset({
        ...getValues(),
        benefitId: getBenefitId(benefits),
        includeDependents: getIncludeDependents(benefits),
        employerPayPercentage: !isNil(employerPayPercentage)
          ? String(employerPayPercentage)
          : null,
      });

      if (dependantCount)
        reset({
          ...getValues(),
          dependentCount: `${dependantCount}`,
        });
    }
  }, [reset, JSON.stringify(benefits)]);

  const selectedBenefit = watch('benefitId');
  useEffect(() => {
    if (selectedBenefit) {
      getSingleBenefit({ variables: { id: selectedBenefit } });
    }
  }, [selectedBenefit]);

  useEffect(() => {
    if (singleBenefit) {
      setType(singleBenefit?.benefit?.type || BenefitType.INSURANCE);
      if (
        singleBenefit?.benefit?.type === BenefitType.FAMILY_INSURANCE &&
        singleBenefit?.benefit?.dependentMaxCount !== null
      ) {
        setValue(
          'dependentCount',
          `${singleBenefit?.benefit?.dependentMaxCount}`,
        );
        setValue('includeDependents', true);
      }
    }
  }, [singleBenefit]);

  const showDependents = React.useMemo(() => {
    if (
      watch('benefitId') && // safetywing
      type === BenefitType.INSURANCE &&
      !Object.keys(
        groupInsuranceByType(availableBenefits as Benefit[]),
      ).includes(BenefitType.FAMILY_INSURANCE)
    )
      return true;

    return false;
  }, [type, availableBenefits, watch('benefitId')]);

  useEffect(() => {
    const dependantCount = getDependentCount(benefits);
    const dependentCountInput = watch('dependentCount');
    if (isNil(dependantCount) && isNil(dependentCountInput) && showDependents) {
      setValue('dependentCount', '1'); // set default value to 1 if checkbox
    }
  }, [showDependents, watch('dependentCount')]);

  useEffect(() => {
    if (
      watch('benefitId') &&
      type === BenefitType.INSURANCE &&
      Object.keys(
        groupInsuranceByType(countryInsurance?.benefits as Benefit[]),
      ).includes(BenefitType.FAMILY_INSURANCE)
    ) {
      setValue('dependentCount', null);
      setValue('includeDependents', false);
    }
  }, [type, countryInsurance, watch('benefitId')]);

  const computedEmployeePayPercentage = useMemo(() => {
    const employerPayPercentageInput = watch('employerPayPercentage');
    const employerPayPercentageNumber = employerPayPercentageInput
      ? Number(employerPayPercentageInput)
      : 0;
    const employeePercentage = Math.abs(100 - employerPayPercentageNumber);
    return employeePercentage;
  }, [watch('employerPayPercentage')]);

  const showInsurancePartnerAndType = benefitPartnerCountryData?.benefitsDomain;

  const selectedBenefitDetails = countryInsurance?.benefits?.find(
    (benefit) => benefit?.id === selectedBenefit,
  );

  const shouldShowERSplit = Boolean(
    benefitPartnerCountryData?.erSplitApplicable && selectedBenefitDetails?.id,
  );

  const continueTooltip =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    isNil(selectedBenefit) ? (
      <div tw="font-medium text-pxs text-center py-8 px-12 self-stretch">
        {t(`benefits.${country}-insurance-continue-tooltip`, {
          defaultValue:
            'Select an insurance plan. Providing insurance is mandatory by law for employees in {{country}}.',
          replace: {
            country,
          },
        })}
      </div>
    ) : null;

  const shouldShowMandatoryInsuranceCallout =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    contractType !== ContractType.CONTRACTOR;

  return (
    <StepLayout data-testid="benefits-view">
      <FormProvider {...methods}>
        {shouldShowMandatoryInsuranceCallout ? (
          <InsuranceMandatoryCallout country={country} />
        ) : null}
        <FormLayout onSubmit={handleSubmit(onSubmit)}>
          {showInsurancePartnerAndType && (
            <div tw="w-full p-large flex gap-x-80 bg-background-white rounded-base ">
              <InsuranceTypePartnerName
                insuranceType={benefitPartnerCountryData?.benefitsDomain}
                insurancePartnerName={benefitPartnerCountryData?.partnerName}
              />
            </div>
          )}
          <FormCard tw="p-none bg-transparent">
            <div tw="py-large flex flex-col gap-large bg-background-white rounded-base">
              {availableBenefits && (
                <Controller
                  control={control}
                  name="benefitId"
                  render={() => (
                    <InsuranceDetails
                      country={country}
                      isTableExpand // for styling only
                      availableBenefits={availableBenefits}
                      variant="tablet"
                      selectable
                      selectedValue={selectedBenefit}
                      onChange={(selectedValue) => {
                        setValue('benefitId', selectedValue, {
                          shouldValidate: true,
                        });
                      }}
                      onTypeChange={(selectedType) => {
                        setType(selectedType as BenefitType);
                      }}
                      benefitType={type}
                      benefitsByType={
                        (availableBenefits ?? [])?.filter(
                          (item) => item?.type === type,
                        ) as Benefit[]
                      }
                    />
                  )}
                />
              )}
              <div tw="px-large flex flex-col gap-large">
                {showDependents && (
                  <IncludeDependantsBenefit
                    edit
                    amount={singleBenefit?.benefit?.cost}
                    currency={singleBenefit?.benefit?.currency}
                    costingType={singleBenefit?.benefit?.costingType}
                    frequency={selectedBenefitDetails?.frequency}
                    billingDuration={benefitPartnerCountryData?.billingDuration}
                  />
                )}
                <InsuranceNotes
                  refundPolicy={benefitPartnerCountryData?.refundPolicy}
                  platformFee={benefitPartnerCountryData?.platformFee}
                  platformFeeApplicable={
                    benefitPartnerCountryData?.platformFeeApplicable
                  }
                  billingDuration={benefitPartnerCountryData?.billingDuration}
                  billingCurrency={benefitPartnerCountryData?.billingCurrency}
                  contractType={contractType}
                />
              </div>
            </div>
          </FormCard>

          {shouldShowERSplit ? (
            <FormCard>
              <Controller
                control={control}
                name="employerPayPercentage"
                render={({ field: { value: currEmployerPayPercentage } }) => {
                  const processedEmployerPayPercentage =
                    currEmployerPayPercentage &&
                    !isNaN(Number(currEmployerPayPercentage))
                      ? Number(currEmployerPayPercentage)
                      : 0;
                  return (
                    <ERSplit
                      cost={selectedBenefitDetails?.cost}
                      billingCurrency={selectedBenefitDetails?.currency}
                      frequency={selectedBenefitDetails?.frequency}
                      onChange={(value: string) => {
                        if (value?.length > 2 && Number(value) > 100) return;
                        setValue(
                          'employerPayPercentage',
                          value === '' ? '0' : value,
                        );
                        trigger('employerPayPercentage');
                      }}
                      employeePayPercentage={computedEmployeePayPercentage}
                      employerPayPercentage={processedEmployerPayPercentage}
                      minimumErSplitPercentage={
                        benefitPartnerCountryData?.minimumErSplitPercentage
                      }
                      type={type}
                      country={country}
                      error={errors?.employerPayPercentage?.message}
                      platformFee={benefitPartnerCountryData?.platformFee}
                      platformFeeApplicable={
                        benefitPartnerCountryData?.platformFeeApplicable
                      }
                    />
                  );
                }}
              />
            </FormCard>
          ) : null}

          <StepNavigationFooter
            submitLoading={loading}
            disabled={!isValid}
            currentStep={currentStep}
            onboardingSteps={onboardingSteps}
            contractId={id}
            stepConfig={stepConfig}
            continueTooltip={continueTooltip}
          />
        </FormLayout>
      </FormProvider>
    </StepLayout>
  );
};

const BenefitsView: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { id } = useParams<{ id?: string }>();
  const {
    contract: {
      id: contractId,
      country,
      benefits,
      countryStateCode,
      type: contractType,
    },
  } = useContract(id);

  const [
    getCountryInsurance,
    { data: countryInsurance, loading: countryInsuranceLoading },
  ] = useGetCountryInsuranceLazyQuery();

  const [
    getBenefitPartnerCountryDetails,
    {
      data: benefitPartnerCountryDetails,
      loading: benefitPartnerCountryDetailsLoading,
    },
  ] = useGetBenefitPartnerCountryDetailsLazyQuery();

  const [
    getSingleBenefit,
    { data: singleBenefit },
  ] = useGetSingleBenefitLazyQuery();

  const { loading: isSubmitting, onSubmit } = useSubmitDefinitionBenefits(
    contractId,
  );

  const handleSubmit = async (values: BenefitFormValues) => {
    const erSplitApplicable =
      benefitPartnerCountryDetails?.benefitPartnerCountriesWithPagination
        ?.data?.[0]?.erSplitApplicable;
    const processedFormvalues = {
      ...values,
      employerPayPercentage: erSplitApplicable
        ? values.employerPayPercentage
        : null,
    };
    await onSubmit(processedFormvalues);
  };

  if (!contractId) {
    return null;
  }

  const loading =
    countryInsuranceLoading || benefitPartnerCountryDetailsLoading;

  return (
    <StepLayout data-testid="benefits-view">
      {loading && <Loader.Table />}
      <div css={[loading && tw`invisible`]}>
        <BenefitsViewForm
          currentStep={currentStep}
          onboardingSteps={onboardingSteps}
          id={id}
          country={country}
          benefits={benefits}
          countryStateCode={countryStateCode}
          getCountryInsurance={getCountryInsurance}
          getBenefitPartnerCountryDetails={getBenefitPartnerCountryDetails}
          benefitPartnerCountryDetails={benefitPartnerCountryDetails}
          countryInsurance={countryInsurance}
          getSingleBenefit={getSingleBenefit}
          singleBenefit={singleBenefit}
          loading={isSubmitting}
          onSubmit={handleSubmit}
          contractType={contractType}
          employerPayPercentage={benefits?.[0]?.employerPayPercentage}
        />
      </div>
    </StepLayout>
  );
};

const InsuranceView: React.FC<OnboardingStepProps> = (props) => {
  const isInsuranceExpandEnabled = useFeature(AppFeature.INSURANCE_EXPAND)?.on;
  if (isInsuranceExpandEnabled) return <BenefitsViewV2 {...props} />;
  return <BenefitsView {...props} />;
};

export default InsuranceView;
