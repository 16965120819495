import { Card } from '@multiplier/common';
import tw from 'twin.macro';

export const FormCard = tw(Card)`p-large flex flex-col gap-y-large`;
export const FormContainer = tw.div`flex flex-row items-center justify-between`;
export const FormTitle = tw.h6`text-pl font-semibold text-text-primary`;

export const StepLayout = tw.div`ml-auto mr-auto pb-large`;
export const FormLayout = tw.form`flex flex-col gap-y-large`;
export const OnboardingLayout = tw.div`w-full pb-large`;

export const DetailLabel = tw.label`text-text-primary font-semibold text-p`;
export const DetailValue = tw.span`col-span-3 text-ps`;
export const DetailValueOneColumn = tw.span`col-span-1 text-ps text-grey03`;
export const DetailValueTwoColumn = tw.span`col-span-2 text-ps text-grey01`;
export const DetailGrid = tw.div`grid grid-cols-4 gap-y-base items-start gap-x-tiny`;
export const DetailForm = tw.form`flex flex-col gap-y-extra-large`;
