/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import tw from 'twin.macro';

import { Member } from '__generated__/graphql';

const MainContainer = tw.div`flex flex-col bg-warningCallout px-32 py-24 border-b border-slateGrey200`;

const VisaDeposiInformation: React.FC<{
  firstName?: Member['firstName'];
  lastName?: Member['lastName'];
}> = ({ firstName, lastName }) => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <MainContainer>
      <div tw="flex text-slateGrey900 font-semibold">
        {t('visa-deposit-info.header', 'Visa invoice due')}
      </div>
      <div tw="flex text-neutral500 text-ps">
        <Trans t={t} i18nKey="visa-deposit-info.description">
          The visa invoice for {{ firstName }} {{ lastName }} will be mailed to
          you.
          <br />
          We will initiate the application process as soon as the visa invoice
          is cleared
        </Trans>
      </div>
    </MainContainer>
  );
};

export default VisaDeposiInformation;
