import { gql } from '@apollo/client';

export default gql`
  query GetCompanyName {
    company {
      id
      displayName
      companyLogo {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
        }
      }
      primaryEntity {
        id
        legalName
        address {
          country
        }
      }
    }
  }
`;
