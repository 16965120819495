import React, { useContext } from 'react';

import tw, { theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';

const BackButton: React.FC<
  { v2?: boolean; light?: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, v2 = false, light = false, ...props }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <button
      tw="focus:ring-transparent focus:outline-none"
      type="button"
      {...props}
    >
      <div
        css={[
          tw`flex flex-row items-center rounded-full bg-background-secondary py-2 px-small`,
          isNewThemeApplied &&
            tw`border border-border-high-contrast text-text-action bg-transparent pl-tiny`,
          v2 &&
            tw`bg-transparent border border-border-secondary rounded-base py-extra-small`,
          light && tw`border-white`,
        ]}
      >
        <Icon
          css={[
            v2 ? tw`mr-[10px]` : tw`mr-6`,
            isNewThemeApplied && tw`h-20 w-20 m-0`,
          ]}
          name="caret-left"
          fill={
            light
              ? theme`colors.icon-inverted-primary`
              : theme`colors.icon-primary`
          }
        />
        <span
          css={[
            tw`text-text-primary text-ps`,
            v2 && tw`font-semibold`,
            light && tw`text-text-inverted-primary`,
          ]}
        >
          {children}
        </span>
      </div>
    </button>
  );
};

export default BackButton;
