import {
  ContractAgreementType,
  ContractOnboardingStatus,
  ContractStatus,
  ContractType,
} from '../../../__generated__/graphql';
import { Experience } from '../../../app/models/module-config';
import { Compliance, SectionConfig } from './index';

export const allowEditForCompanyWhenEmployeeCreatedOrRevoked: SectionConfig = {
  editable: true,
  experiences: [Experience.COMPANY],
  contractStatus: '*',
  onboardingStatus: [
    ContractOnboardingStatus.CREATED,
    ContractOnboardingStatus.REVOKED,
  ],
  compliance: '*',
  type: [ContractType.EMPLOYEE, ContractType.CONTRACTOR],
};

export const allowEditForCompanyWhenEmployeeContractIsPreparing: SectionConfig = {
  editable: true,
  experiences: [Experience.COMPANY],
  contractStatus: '*',
  onboardingStatus: [ContractOnboardingStatus.CONTRACT_PREPARING],
  compliance: '*',
  type: [ContractType.EMPLOYEE],
};

export const allowEditForCompanyWhenEmployeeContractIsPreparingConformation: SectionConfig = {
  editable: true,
  experiences: [Experience.COMPANY],
  contractStatus: '*',
  onboardingStatus: [ContractOnboardingStatus.CONTRACT_PREPARING_CONFIRMATION],
  compliance: '*',
  type: [ContractType.EMPLOYEE],
};

export const allowEditForCompanyWhenMemberIsFreelancerAndHaveMultiplierLegalEntity: SectionConfig = {
  editable: true,
  experiences: [Experience.COMPANY],
  type: [ContractType.FREELANCER],
  compliance: (compliance: Compliance) =>
    compliance?.__typename === 'ComplianceFreelance' &&
    compliance.contractAgreementType ===
      ContractAgreementType.MULTIPLIER_TEMPLATE,
  contractStatus: '*',
  onboardingStatus: [
    ContractOnboardingStatus.CREATED,
    ContractOnboardingStatus.CONTRACT_PREPARING,
  ],
};

export const notAllowEditForCompanyWhenEmployeeActive: SectionConfig = {
  editable: false,
  experiences: [Experience.COMPANY],
  contractStatus: [ContractStatus.ACTIVE],
  onboardingStatus: [ContractOnboardingStatus.CREATED],
  compliance: '*',
  type: [ContractType.EMPLOYEE],
};

export const allowEditForMemberDuringOnboarding: SectionConfig = {
  compliance: '*',
  contractStatus: '*',
  editable: true,
  experiences: [Experience.MEMBER],
  onboardingStatus: [
    ContractOnboardingStatus.MEMBER_DATA_ADDED,
    ContractOnboardingStatus.MEMBER_DATA_REVIEWED,
  ],
  type: [ContractType.EMPLOYEE, ContractType.CONTRACTOR],
};

/**
 * When Member is active, he can go through an approval process to update
 * his info.
 */
export const allowEditForMemberWhenEmployeeActive: SectionConfig = {
  editable: true,
  experiences: [Experience.MEMBER],
  contractStatus: [ContractStatus.ACTIVE],
  onboardingStatus: '*',
  compliance: '*',
  type: [ContractType.EMPLOYEE],
};

export const allowEditForHRMemberAndFreelancerWhenActive: SectionConfig = {
  compliance: '*',
  contractStatus: [ContractStatus.ACTIVE],
  editable: true,
  experiences: [Experience.MEMBER],
  onboardingStatus: '*',
  type: [ContractType.FREELANCER, ContractType.HR_MEMBER],
};

export const allowEditForHRMemberAndFreelancerWhenCreateCustomStatusAndOtherStuff: SectionConfig = {
  compliance: '*',
  contractStatus: '*',
  editable: true,
  experiences: '*',
  onboardingStatus: [
    ContractOnboardingStatus.CREATED_CUSTOM,
    ContractOnboardingStatus.MEMBER_DATA_REVIEWED,
    ContractOnboardingStatus.MEMBER_COMPLETED,
  ],
  type: [ContractType.FREELANCER, ContractType.HR_MEMBER],
};
