import { useEffect, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import compact from 'lodash/fp/compact';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';

import {
  CountryCompliance,
  GetMemberQuery,
  GetOnboardingFormRequirementsQuery,
  GetOnboardingFormRequirementsQueryVariables,
  LegalDocumentCategory,
  LegalDocumentRequirement,
  useGetOnboardingFormRequirementsLazyQuery,
} from '__generated__/graphql';

const useOnboardingFormRequirements = (
  member: GetMemberQuery['member'],
): {
  onboardingFormRequirements: CountryCompliance['memberDocumentRequirements'];
  onboardingFormRequirementsMap: { [key: string]: LegalDocumentRequirement };
  loading: boolean;
  refetch: (
    variables?: Partial<GetOnboardingFormRequirementsQueryVariables>,
  ) => Promise<ApolloQueryResult<GetOnboardingFormRequirementsQuery>>;
} => {
  const [loaded, setLoaded] = useState(false);
  const [
    getOnboardingFormRequirements,
    { data, loading, refetch },
  ] = useGetOnboardingFormRequirementsLazyQuery();

  const { country, countryStateCode, startOn, status, term, type, workStatus } =
    member?.contracts?.[0] ?? {};

  useEffect(() => {
    if (country && workStatus && type && term && status && startOn) {
      getOnboardingFormRequirements({
        variables: {
          country,
          countryStateCode,
          workStatus,
          contractType: type,
          contractTerm: term,
          contractStatus: status,
          joinDate: startOn.substring(0, 10),
          financialYear: null,
        },
      }).then(() => setLoaded(true));
      // Cannot move this to onComplete because the view will get unloaded before action is committed
    }
  }, [member]);

  const onboardingFormRequirements = flow(
    compact,
    filter<LegalDocumentRequirement>(
      (requirement) => requirement.category === LegalDocumentCategory.PAYROLL,
    ),
  )(data?.country?.compliance?.memberDocumentRequirements);

  return {
    onboardingFormRequirements,
    onboardingFormRequirementsMap: flow(keyBy<LegalDocumentRequirement>('key'))(
      onboardingFormRequirements,
    ),
    loading: !loaded || loading,
    refetch,
  };
};

export default useOnboardingFormRequirements;
