import { TFunction } from 'i18next';

export default (
  t: TFunction<'contract-onboarding.member'> | TFunction<'direct-debit-setup'>,
  regexString: string,
): string => {
  const map: Record<string, string> = {
    '^[A-Za-z\\s]+$': t(
      'bank-account-schema.text.error-message.regex.^[A-Za-z\\s]+$',
      'Please use only standard Latin letters (A-Z, a-z) and remove any special characters',
    ),
    '^[A-Za-z]{4}0[A-Za-z0-9]{6}$': t(
      'bank-account-schema.text.error-message.regex.^[A-Za-z]{4}0[A-Za-z0-9]{6}$',
      'Value should be an 11-character code with the first four alphabetic characters; a zero; and the last six alphanumeric characters',
    ),
  };

  return (
    map[regexString] ??
    t(
      'bank-account-schema.text.error-message.wrong-format',
      'Input format is wrong',
    )
  );
};
