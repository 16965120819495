import countryIDD from 'common/constants/country-idd';

import {
  Member,
  useMemberUpdateBasicDetailsMutation,
  useUpdateMemberPrimaryEmailMutation,
} from '__generated__/graphql';

import { MemberContactDetailsFormValues } from '../components/contact-details-section';

const useSubmitContactDetails = (
  memberId?: Member['id'],
  onCompleted?: () => void,
): {
  loading: boolean;
  onSubmit: (values: MemberContactDetailsFormValues) => Promise<void>;
} => {
  const [
    updateMemberContactDetails,
    { loading: loadingUpdateMember },
  ] = useMemberUpdateBasicDetailsMutation();
  const [
    updatePrimaryEmail,
    { loading: loadingUpdatePrimaryEmail },
  ] = useUpdateMemberPrimaryEmailMutation({
    onCompleted: () => {
      if (onCompleted) {
        onCompleted();
      }
    },
  });

  const onSubmit = async (values: MemberContactDetailsFormValues) => {
    if (!memberId) return;

    await updateMemberContactDetails({
      variables: {
        id: memberId,
        input: {
          phone: {
            name: 'PRIMARY',
            value: `${
              values.phone.countryCode
                ? countryIDD[values.phone.countryCode]
                : ''
            } ${values.phone.number}`,
          },
          address: values.address,
        },
      },
    });

    await updatePrimaryEmail({
      variables: {
        id: memberId,
        input: {
          name: 'primary',
          value: values.email,
        },
      },
    });
  };

  return {
    loading: loadingUpdateMember || loadingUpdatePrimaryEmail,
    onSubmit,
  };
};

export default useSubmitContactDetails;
