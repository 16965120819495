import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { AppFeature } from '@multiplier/growthbook';

import { GET_CONTRACT, GET_CONTRACT_V2 } from 'contract-onboarding/queries';

import {
  Contract,
  GetContractQuery,
  GetContractQueryVariables,
  GetContractV2Query,
} from '__generated__/graphql';

const useContract = (
  contractId?: string | null,
  skipDepositPayable = false,
): { contract: Contract; loading: boolean } => {
  const hrisNewFieldsEnabled = useFeature(AppFeature.HRIS_NEW_EMPLOYEE_FIELDS)
    .on;
  const sorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;
  const shouldQueryNewFields = hrisNewFieldsEnabled || sorOnboardingEnabled;

  const [getContract, { data, loading }] = useLazyQuery<
    GetContractQuery | GetContractV2Query,
    GetContractQueryVariables
  >(shouldQueryNewFields ? GET_CONTRACT_V2 : GET_CONTRACT, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (contractId)
      getContract({
        variables: { id: contractId, skipDepositPayable },
      });
  }, [contractId]);

  if (data && data.contract) {
    return { contract: data.contract as Contract, loading };
  }

  return { contract: {}, loading };
};

export default useContract;
