/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, ToolTip } from '@multiplier/common';
import isNil from 'lodash/isNil';
import tw from 'twin.macro';

import {
  ContractOnboardingStep,
  ContractType,
  Maybe,
} from '__generated__/graphql';

export interface OnboardingStepProps {
  currentStep?: ContractOnboardingStep;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
}
export interface StepConfig {
  route: () => string; // navigation route
  directLink: (id?: string | null) => string; // direct link without path string
  relativeLink: (id?: string | null) => string; // relative link
  label: (contractType?: ContractType) => string; // navigation label
  view: React.FC<OnboardingStepProps>; // react component
}

interface StepFooterNavigationProps {
  disabled: boolean;
  submitLoading?: boolean;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
  currentStep?: ContractOnboardingStep;
  contractId?: string | null;
  hideSubmit?: boolean;
  stepConfig: { [key: string]: StepConfig };
  showDialogButton?: boolean | null | undefined;
  continueTooltip?: string | ReactNode | null;
  dialogButtonCallback?: () => void;
  backButtonCallback?: () => void;
}

export const StepNavigationFooter: React.FC<StepFooterNavigationProps> = ({
  disabled,
  submitLoading = false,
  onboardingSteps,
  currentStep,
  contractId,
  hideSubmit = false,
  stepConfig,
  showDialogButton = false,
  dialogButtonCallback = undefined,
  backButtonCallback = undefined,
  continueTooltip = undefined,
  ...props
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const navigate = useNavigate();

  const showBackButton =
    onboardingSteps &&
    currentStep &&
    onboardingSteps.indexOf(currentStep) !== 0;

  const handleBackButtonClick = () => {
    if (!(onboardingSteps && currentStep)) {
      return;
    }

    if (backButtonCallback) backButtonCallback();

    const previousStep =
      onboardingSteps[onboardingSteps?.indexOf(currentStep) - 1];

    if (previousStep && stepConfig[previousStep])
      navigate(stepConfig[previousStep].directLink(contractId));
  };

  const continueButton = (
    <Button
      tw="ml-auto"
      data-testid="continue"
      type="submit"
      size="medium"
      disabled={disabled || submitLoading}
      loading={submitLoading}
    >
      {t('step-navigation-footer.continue', 'Continue')}
    </Button>
  );

  const continueButtonWithTooltip = (
    <ToolTip tw="ml-auto" styles={tw`p-none`} content={continueTooltip}>
      {continueButton}
    </ToolTip>
  );

  const renderContinue = !isNil(continueTooltip)
    ? continueButtonWithTooltip
    : continueButton;

  return (
    <div tw="flex flex-row" {...props}>
      {showBackButton && (
        <Button
          size="medium"
          variant="outline"
          onClick={handleBackButtonClick}
          data-testid="back"
        >
          {t('step-navigation-footer.back', 'Back')}
        </Button>
      )}
      {!hideSubmit && !showDialogButton ? renderContinue : null}
      {showDialogButton && (
        <Button
          tw="ml-auto"
          data-testid="continue-dialog-btn"
          onClick={dialogButtonCallback}
          size="medium"
          disabled={disabled || submitLoading}
          loading={submitLoading}
        >
          {t('step-navigation-footer.continue-dialog-btn', 'Continue')}
        </Button>
      )}
    </div>
  );
};

export default StepNavigationFooter;
