import { useState } from 'react';

import { UseGetTimeOffDataValues } from 'types';

import useGetTimeOffData, {
  UseGetTimeOffDataParams,
} from './useGetTimeOffData';

type UseGetTimeOffLazyValues = [
  (_params: UseGetTimeOffDataParams) => void,
  UseGetTimeOffDataValues,
];

const useGetTimeOffDataLazy = (): UseGetTimeOffLazyValues => {
  const [params, setParams] = useState<Partial<UseGetTimeOffDataParams>>({});

  const [isPending, setIsPending] = useState(true);

  const data = useGetTimeOffData({
    ...(params as UseGetTimeOffDataParams),
    skip: isPending,
  });

  const fetchTimeOffDataLazy = (_params: UseGetTimeOffDataParams) => {
    setIsPending(false);
    setParams(_params);
  };

  return [fetchTimeOffDataLazy, data];
};

export default useGetTimeOffDataLazy;
