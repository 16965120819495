/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import 'twin.macro';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import {
  StatelessFileUpload,
  getFileFromUploadedDocument,
} from '@multiplier/common';

import { notEmpty } from 'app/utils/array';
import TextInput from 'common/components/text-input';
import { useGetCountryLegalRequirements } from 'contract-onboarding/member/hooks';

import {
  Contract,
  ContractOnboardingStep,
  ContractType,
  Document,
  LegalDocumentCategory,
  LegalDocumentRequirement,
  Maybe,
  Member,
} from '__generated__/graphql';

export interface LegalDocumentRequirementField
  extends Pick<
    LegalDocumentRequirement,
    'key' | 'label' | 'description' | 'acceptMultiple' | 'required' | 'category'
  > {
  readonly: boolean;
  value: Maybe<File> | Maybe<File[]>;
}

export interface DocumentFormParams {
  legalDocuments: LegalDocumentRequirementField[];
}

export interface OnboardingStepProps {
  currentStep?: ContractOnboardingStep;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
}

type fileErrorType = {
  message: string;
};
const getErrorMessages = (errorMessages: fileErrorType[] | fileErrorType) => {
  if (Array.isArray(errorMessages)) {
    return (
      <div>
        {(errorMessages as fileErrorType[]).map((err) => (
          <div>{err.message}</div>
        ))}
      </div>
    );
  }
  return (errorMessages as fileErrorType)?.message;
};

export const getFileToPreFillTheForm = async (
  key: string,
  documents?: Document[],
): Promise<{
  key: string;
  files: File[] | null;
}> => {
  const promiseArray = (documents ?? [])?.map((doc) =>
    getFileFromUploadedDocument(doc),
  );
  const allFiles = Promise.all(promiseArray);
  try {
    const files = await allFiles;
    return {
      key,
      files,
    };
  } catch (error) {
    return {
      key,
      files: null,
    };
  }
};
const LegalDocumentsFormFields: React.FC<{
  workStatus: Contract['workStatus'];
  country: Contract['country'];
  legalDocuments: Member['legalDocuments'];
  contractType?: Contract['type'];
}> = ({ workStatus, country, legalDocuments, contractType }) => {
  const { t } = useTranslation('contract-onboarding.member');

  const { getRequirements, requirements } = useGetCountryLegalRequirements(
    workStatus,
  );

  useEffect(() => {
    if (
      country &&
      (contractType === ContractType.FREELANCER ||
        contractType === ContractType.CONTRACTOR)
    ) {
      getRequirements(country, ContractType.FREELANCER);
    } else if (country) {
      getRequirements(country);
    }
  }, [country]);

  const {
    control,
    reset,
    trigger,
    formState: { errors },
  } = useFormContext<DocumentFormParams>();

  const { fields } = useFieldArray({
    control,
    name: 'legalDocuments',
  });

  useDeepCompareEffect(() => {
    const resetForm = async () => {
      const filesPromises = (legalDocuments ?? [])
        ?.filter(notEmpty)
        ?.map((cur) =>
          getFileToPreFillTheForm(
            cur?.key ?? '',
            (cur.files ?? []).filter(notEmpty),
          ),
        );

      const fileResults = Promise.all(filesPromises);

      fileResults.then((files) => {
        const fileMap = files.reduce(
          (acc, cur) => (!cur.files ? acc : { ...acc, [cur.key]: cur.files }),
          {} as { [key: string]: File[] },
        );
        reset({
          legalDocuments:
            requirements.memberDocumentRequirements
              ?.filter(notEmpty)
              .map((docReq) => {
                const category =
                  legalDocuments?.find((i) => i?.key === docReq.key)
                    ?.category ?? docReq.category;
                const readonly =
                  category != null &&
                  [
                    LegalDocumentCategory.VISA_PRIMARY,
                    LegalDocumentCategory.VISA_SECONDARY,
                  ].includes(category);

                return {
                  ...docReq,
                  readonly,
                  value: docReq?.acceptMultiple
                    ? fileMap[String(docReq?.key)]
                    : fileMap?.[String(docReq?.key)]?.[0],
                };
              }) ?? undefined,
        });
        trigger();
      });
    };
    resetForm();
  }, [requirements, legalDocuments]);

  return (
    <>
      {fields.map((field, index) => (
        <TextInput.Container
          key={field.key}
          tw="mt-large"
          data-testid="file-upload-component"
        >
          <TextInput.Label>
            {t(`legal-documents.${field.label}`, field.label ?? '')}{' '}
            {field.required === false &&
              t('legal-documents.optional', '(Optional)')}
          </TextInput.Label>
          <TextInput.Helper>
            {t(
              `legal-documents.${field.label}-description`,
              field.description ?? '',
            )}
          </TextInput.Helper>
          <Controller
            render={({ field: { onChange, value } }) => (
              <StatelessFileUpload
                files={
                  field.acceptMultiple
                    ? ((value ?? []) as File[])
                    : ((value ? [value] : []) as File[])
                }
                disabled={field.readonly}
                data-testid={field.key}
                multiple={Boolean(field.acceptMultiple)}
                minimal
                onFileDrop={(f: File[]) => {
                  if (field.acceptMultiple) {
                    onChange([
                      ...((value as File[])?.length ? (value as File[]) : []),
                      ...f,
                    ]);
                  } else {
                    onChange(f[0]);
                  }
                }}
                onRemoveFile={(f) => {
                  if (field.acceptMultiple)
                    onChange(
                      ((value as File[]) ?? [])?.filter(
                        (file) => f.name !== file.name,
                      ),
                    );
                  else onChange(null);
                }}
              />
            )}
            name={`legalDocuments.${index}.value` as 'legalDocuments.0.value'}
            control={control}
          />
          {errors.legalDocuments?.[index] && (
            <TextInput.Error tw="mb-base">
              {getErrorMessages(
                errors?.legalDocuments?.[index]?.value as fileErrorType,
              )}
            </TextInput.Error>
          )}
        </TextInput.Container>
      ))}
    </>
  );
};

export default LegalDocumentsFormFields;
