import { Contract, InvoiceStatus } from '__generated__/graphql';

export const hasUnpaidInvoices = (
  depositPayables: Contract['depositPayable'],
): boolean =>
  depositPayables?.some(
    (depositPayable) =>
      !(
        depositPayable?.invoice?.status &&
        [
          InvoiceStatus.PAID,
          InvoiceStatus.VOIDED,
          InvoiceStatus.DELETED,
        ].includes(depositPayable.invoice.status)
      ),
  ) ?? false;

export const getUnpaidInvoice = (
  depositPayables: Contract['depositPayable'],
): NonNullable<Contract['depositPayable']>[number] | null | undefined => {
  if (!depositPayables) return null;

  return depositPayables.find(
    (depositPayable) =>
      depositPayable?.invoice?.status &&
      ![
        InvoiceStatus.PAID,
        InvoiceStatus.VOIDED,
        InvoiceStatus.DELETED,
      ].includes(depositPayable.invoice.status),
  );
};

export const hasPaidInvoice = (
  depositPayables: Contract['depositPayable'],
): boolean =>
  depositPayables?.some(
    (depositPayable) => depositPayable?.invoice?.status === InvoiceStatus.PAID,
  ) ?? false;
