import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { Table } from '@tanstack/react-table';

import { prependTableIdForUrlParams } from '../../utils';
import { UseServerPaginatedTableOptions } from '../types';

const useServerPaginatedTable = <TData extends unknown>(
  options: UseServerPaginatedTableOptions<TData>,
): {
  tableInstance: Table<TData>;
} => {
  // Uses boolean to make sure the url param updates happen only after reading initial values from url params and updating the state
  const [isMounted, setIsMounted] = useState(false);

  const { tableId } = options;
  const {
    sorting,
    setSorting,
    pagination,
    setPagination,
    rowSelection,
    setRowSelection,
  } = options.tableState;

  const [params, setParams] = useSearchParams();

  const rowsKey = prependTableIdForUrlParams('rows', tableId);
  const pageKey = prependTableIdForUrlParams('page', tableId);
  const sortByKey = prependTableIdForUrlParams('sortBy', tableId);
  const sortDirKey = prependTableIdForUrlParams('sortDir', tableId);

  useEffect(() => {
    setPagination((p) => {
      const pageNumber = params.get(pageKey);
      const rowsPerPage = params.get(rowsKey);

      return {
        ...p,
        pageIndex:
          pageNumber && Number.isInteger(Number(pageNumber))
            ? Math.max(Number(pageNumber) - 1, 0)
            : p.pageIndex ?? 0,
        pageSize:
          rowsPerPage && Number.isInteger(Number(rowsPerPage))
            ? Number(rowsPerPage)
            : p.pageSize ?? 5,
      };
    });

    const sortKey = params.get(sortByKey);
    const sortDirection = params.get(sortDirKey);
    if (sortKey)
      setSorting([
        {
          desc: sortDirection === 'desc',
          id: sortKey,
        },
      ]);

    // timeout is required to make sure the url params are updated after the initial state is set
    setTimeout(() => {
      setIsMounted(true);
    }, 300);
  }, []);

  // This use effects just update the url params but does not update the table state
  useEffect(() => {
    if (isMounted) {
      if (pagination.pageSize)
        params.set(rowsKey, pagination.pageSize.toString());
      if (Number.isInteger(pagination.pageIndex))
        params.set(pageKey, (pagination.pageIndex + 1).toString());
      if (sorting?.[0]?.id && sorting?.[0]?.id !== 'undefined') {
        params.set(sortByKey, sorting?.[0]?.id);
        params.set(sortDirKey, sorting?.[0]?.desc ? 'desc' : 'asc');
      } else {
        params.delete(sortByKey);
        params.delete(sortDirKey);
      }
      setParams(params);
    }
  }, [params, sorting, pagination.pageIndex, pagination.pageSize, isMounted]);

  const tableInstance = useReactTable({
    ...options,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    pageCount: options.pageCount,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
  });
  return {
    tableInstance,
  };
};

export default useServerPaginatedTable;
