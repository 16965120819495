/** @jsxImportSource @emotion/react */
import React from 'react';

import tw from 'twin.macro';

import COMMON_LINKS from 'common/constants/common-links';

const WetInkKnowMoreLink: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <a
    {...{
      style: tw`text-primary underline`,
      href: COMMON_LINKS.WIKI_WET_INK_SIGNATURE,
      target: '_blank',
      rel: 'noopener noreferrer',
    }}
  >
    {children}
  </a>
);

export { WetInkKnowMoreLink as default };
