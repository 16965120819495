import * as FullStory from '@fullstory/browser';
import growthBook from '@multiplier/growthbook';
import { hasSpecificPermission } from '@multiplier/user';
import * as Sentry from '@sentry/react';

import apm from 'apm';
import { checkPermission } from 'app/hooks/permission';
import { Experience, ModuleConfig } from 'app/models/module-config';
import { GET_COMPANY_NAME } from 'app/queries';
import registeredModules from 'app/registered-modules';
import { successNotification } from 'app/services/notification-services';
import { getTestQueryParam } from 'app/utils/check-if-test-company-creation';
import history from 'app/utils/history';
import { userVar } from 'app/vars';
import countryLabels from 'common/constants/country-labels';
import importMetaEnv from 'import-meta-env';
import routes from 'login/routes';

import {
  BenefitType,
  Company,
  ContractStatus,
  ContractType,
  GetCompanyNameQuery,
  GetCompanyUsersAndContractsQuery,
  GetMemberSummaryQuery,
  GetMultipleCompaniesQuery,
} from '../../__generated__/graphql';
import { filterAsync } from '../../app/utils/array';
import client from '../../client';
import i18n from '../../i18n';
import {
  GET_COMPANY_USERS_AND_CONTRACTS,
  GET_MEMBER_SUMMARY,
  GET_MULTIPLE_COMPANY,
} from '../queries';
import { getCurrentExperience, getPriorityExperience } from './experience';
import {
  configureIntercomBoot,
  getUserIntercomHash,
  updateIntercomUserData,
} from './intercom';
import * as jwtService from './jwt';
import { refreshAccessToken } from './refresh';

type Permissions = string[];

interface FilterByPermissionArgs {
  modules: ModuleConfig[];
  permissions: Permissions;
  experience: Experience;
}

interface GrowthBookArgs {
  user: { id: number; email: string };
  companyId: string;
  currentExperience: Experience;
  memberSummary?: GetMemberSummaryQuery;
  isManager?: boolean;
}

const handleCompanyLocalStorage = (selectedCompanyId: string) => {
  if (!selectedCompanyId) {
    localStorage.removeItem('selected_company');
  } else {
    localStorage.setItem('selected_company', selectedCompanyId);
  }
};

const configureGrowthBookAttributes = ({
  user,
  companyId,
  currentExperience,
  memberSummary,
  isManager,
}: GrowthBookArgs) => {
  growthBook?.setAttributes({
    ...growthBook.getAttributes(),
    id: user?.id,
    email: user?.email,
    company: companyId,
    experience: currentExperience,
    country: memberSummary?.member?.contracts?.[0]?.country,
    member_id: memberSummary?.member?.id,
    role: isManager ? 'manager' : undefined,
    url: window?.location?.href,
  });
};

const filterModulesByPermission = ({
  modules = [],
  permissions,
  experience,
}: FilterByPermissionArgs) =>
  modules
    .filter((module) => !!module)
    .filter((module) => {
      const input = `use.${experience}.${module.permission}`;

      return (
        checkPermission(input, permissions) && module.experience === experience
      );
    });

const filterModulesByConfig = async (
  modules: ModuleConfig[] = [],
  experience: Experience,
) =>
  filterAsync(modules, async (module) => {
    if (module.experience !== experience) return true;
    if (typeof module.enabled === 'undefined') return true;
    if (typeof module.enabled === 'function') {
      return module.enabled();
    }

    return module.enabled;
  });

const filterModulesByFeatureConfig = (modules: ModuleConfig[] = []) => {
  const isGrowthBookInitialized = growthBook.isOn('growthbook-initialized');
  if (!isGrowthBookInitialized) {
    return modules.filter((module) =>
      module.featureFlag ? module.featureFlagDefaultValue : true,
    );
  }

  return modules.filter(({ featureFlag, id }) =>
    featureFlag
      ? growthBook.isOn(typeof featureFlag === 'string' ? featureFlag : id)
      : true,
  );
};

const checkIfUserIsManager = (
  experience: Experience,
  permissions: Permissions,
) =>
  experience === Experience.COMPANY &&
  !permissions.some(
    (permission) =>
      permission === 'ROLE_COMPANY_ADMIN' ||
      permission === 'ROLE_COMPANY_SUPERADMIN',
  );

const getCompanyList = async (permissions: Permissions) => {
  if (checkPermission('view.company.company.company-group', permissions)) {
    const multipleCompanyResponse = await client.query<GetMultipleCompaniesQuery>(
      {
        query: GET_MULTIPLE_COMPANY,
        fetchPolicy: 'network-only',
      },
    );

    return multipleCompanyResponse?.data?.companyGroup?.[0]?.children;
  }

  return [];
};

const getCompanyInfo = (permissions: Permissions) => {
  if (
    checkPermission('view.company.company', permissions) ||
    checkPermission('view.member.company', permissions)
  ) {
    return client.query<GetCompanyNameQuery>({
      query: GET_COMPANY_NAME,
      fetchPolicy: 'network-only',
    });
  }

  return null;
};

const getMemberData = async (): Promise<GetMemberSummaryQuery> => {
  const { data } = await client.query<GetMemberSummaryQuery>({
    query: GET_MEMBER_SUMMARY,
  });

  return data;
};

export const getFilteredModules = async (
  modules: ModuleConfig[] = [],
  permissions: Permissions,
  experience: Experience,
): Promise<ModuleConfig[]> => {
  const filteredBasedOnPermission = filterModulesByPermission({
    modules,
    permissions,
    experience,
  });

  const filteredBasedOnConfig = await filterModulesByConfig(
    filteredBasedOnPermission,
    experience,
  );

  return filterModulesByFeatureConfig(filteredBasedOnConfig);
};

export const startup = async (
  token: string,
  redirectUrl?: string,
  hideNotification?: true,
): Promise<void> => {
  jwtService.setSession(token);
  const {
    experiences,
    auth: permissions,
    user,
  } = await jwtService.getDecodedToken();

  const currentExperience = getPriorityExperience(experiences);
  const isManager = checkIfUserIsManager(currentExperience, permissions);
  const companyList = await getCompanyList(permissions);
  const companyInfo = await getCompanyInfo(permissions);
  const companyId = companyInfo?.data?.company?.id?.toString() ?? '';
  const localCompanyId = localStorage.getItem('selected_company');
  const localCompany =
    companyList?.find((company) => company?.id === localCompanyId) ??
    companyList?.[0];

  let memberSummary;
  if (currentExperience === Experience.MEMBER) {
    memberSummary = await getMemberData();
    configureGrowthBookAttributes({
      user,
      companyId,
      currentExperience,
      memberSummary,
    });
  } else {
    configureGrowthBookAttributes({
      user,
      companyId,
      currentExperience,
      isManager,
    });
  }

  const filteredModules = await getFilteredModules(
    registeredModules,
    permissions,
    currentExperience,
  );

  handleCompanyLocalStorage(localCompany?.id ?? '');

  userVar({
    ...user,
    permissions,
    experiences: {
      current: currentExperience,
      authorized: experiences,
    },
    modules: [...filteredModules],
    companyList: companyList as Company[],
    selectedCompany: (localCompany as Company) ?? companyInfo?.data?.company,
    defaultCompanyId: localCompany?.id ?? companyId,
    isManager,
  });

  const userHash = await getUserIntercomHash(user.id, token);

  await configureIntercomAndFullStory(
    currentExperience,
    user,
    userHash,
    {
      id: companyInfo?.data?.company?.id?.toString() ?? '',
      name: companyInfo?.data?.company?.displayName ?? '',
      primaryEntityCountry:
        companyInfo?.data?.company?.primaryEntity?.address?.country ?? '',
    },
    permissions,
    memberSummary,
    new Date().toDateString(),
  );

  if (hasSpecificPermission('*.company.zombie', permissions)) {
    history.push(
      `/${routes.root}/${routes.signUp}/${routes.signUp.company}/${routes.signUp.company.create}`,
    );
  } else if (
    redirectUrl &&
    redirectUrl !== '/' &&
    !redirectUrl.includes(
      `/${routes.root}/${routes.signUp}/${routes.signUp.company}`,
    )
  ) {
    history.push(redirectUrl);
  } else {
    history.push(`/${currentExperience}`);
  }

  if (!hideNotification) {
    successNotification(
      i18n.t('login:notification.login-success-title', 'Success'),
      i18n.t(
        'login:notification.login-success-message',
        'Logged in successfully',
      ),
      false,
    );
  }
};

export const refresh = async (
  pathname?: string,
  isTest = false,
): Promise<void> => {
  const token = await refreshAccessToken();

  const {
    experiences,
    auth: permissions,
    user,
  } = await jwtService.getDecodedToken();

  if (!experiences) {
    history.push(`/${routes.root}`, { redirect: pathname });
    return;
  }

  const currentExperience = getCurrentExperience(experiences, pathname);
  const isManager = checkIfUserIsManager(currentExperience, permissions);
  const companyList = await getCompanyList(permissions);
  const companyInfo = await getCompanyInfo(permissions);
  const companyId = companyInfo?.data?.company?.id?.toString() ?? '';
  const localCompanyId = localStorage.getItem('selected_company');
  const localCompany =
    companyList?.find((company) => company?.id === localCompanyId) ??
    companyList?.[0];

  let memberSummary;
  if (currentExperience === Experience.MEMBER) {
    memberSummary = await getMemberData();
    configureGrowthBookAttributes({
      user,
      companyId,
      currentExperience,
      memberSummary,
    });
  } else {
    configureGrowthBookAttributes({
      user,
      companyId,
      currentExperience,
      isManager,
    });
  }

  const filteredModules = await getFilteredModules(
    registeredModules,
    permissions,
    currentExperience,
  );

  handleCompanyLocalStorage(localCompany?.id ?? '');

  userVar({
    ...user,
    permissions,
    experiences: {
      current: currentExperience,
      authorized: experiences,
    },
    modules: [...filteredModules],
    companyList: companyList as Company[],
    selectedCompany: (localCompany as Company) ?? companyInfo?.data?.company,
    defaultCompanyId: localCompany?.id ?? companyId,
    isManager,
  });

  const userHash = await getUserIntercomHash(user.id, token);

  await configureIntercomAndFullStory(
    currentExperience,
    user,
    userHash,
    {
      id: companyId,
      name: companyInfo?.data?.company?.displayName ?? '',
      primaryEntityCountry:
        companyInfo?.data?.company?.primaryEntity?.address?.country ?? '',
    },
    permissions,
    memberSummary,
  );

  if (hasSpecificPermission('*.company.zombie', permissions)) {
    history.replace(
      `/${routes.root}/${routes.signUp}/${routes.signUp.company}/${
        routes.signUp.company.create
      }${isTest ? `?${getTestQueryParam}` : ''}`,
    );
    return;
  }

  if (pathname === '/') {
    history.replace(`/${currentExperience}`);
  }
};

export const configureIntercomAndFullStory = async (
  currentExperience: Experience,
  user: {
    langKey: string;
    imageUrl: string;
    firstName: string;
    id: number;
    lastName: string;
    email: string;
  },
  userHash: string,
  company: {
    id: string;
    name: string;
    primaryEntityCountry: string;
  },
  permissions: Permissions,
  memberSummary?: GetMemberSummaryQuery,
  signUpDate?: string,
): Promise<void> => {
  try {
    configureIntercomBoot(
      currentExperience,
      user?.id,
      user?.email,
      userHash,
      `${user?.firstName} ${user?.lastName}`,
      {
        id: company.id,
        name: company.name,
      },
    );
    updateIntercomUserData({
      'User Type': currentExperience?.toUpperCase(),
      'Sign Up Date': signUpDate,
    });
    if (!user) return;

    FullStory.identify(String(user.id), {
      displayName: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
    Sentry.setUser({ username: user.email, email: user.email, id: user?.id });

    if (importMetaEnv.VITE_ENV === 'prod') {
      // production environment
      apm?.setUserContext({
        id: user.id,
      });
    }

    if (currentExperience === Experience.MEMBER) {
      const userVars = {
        'Member Id': memberSummary?.member?.id?.toString() ?? '',
        'Member Type': memberSummary?.member?.contracts?.[0]?.type as string,
        'Job Role': memberSummary?.member?.contracts?.[0]?.position as string,
        'Country of Employment': memberSummary?.member?.contracts?.[0]?.country
          ? countryLabels[memberSummary?.member?.contracts?.[0]?.country]
          : '',
        'Onboarding Status':
          (memberSummary?.member?.contracts?.[0]?.onboarding
            ?.status as string) ??
          memberSummary?.member?.contracts?.[0]?.status,
        Insurance: memberSummary?.member?.contracts?.[0]?.benefits?.find(
          (benefit) =>
            benefit?.benefit?.type === BenefitType.INSURANCE ||
            benefit?.benefit?.type === BenefitType.FAMILY_INSURANCE,
        )?.benefit?.packageName as string,
        'Contract Id': memberSummary?.member?.contracts?.[0]?.id ?? '',
        'Contract Status': memberSummary?.member?.contracts?.[0]?.status ?? '',
        Currency: memberSummary?.member?.contracts?.[0]?.currency,
        'Payment Account Id':
          memberSummary?.member?.contracts?.[0]?.paymentAccountId,
        'Company Primary Entity Country': company.primaryEntityCountry,
        'Company Id': company?.id,
        'Company Display Name': company?.name,
      };
      updateIntercomUserData(userVars);
      FullStory.setUserVars(userVars);
    } else {
      let companyUsersAndContracts;
      if (
        checkPermission('view.company.company', permissions) ||
        checkPermission('view.member.company', permissions)
      ) {
        const response = await client.query<GetCompanyUsersAndContractsQuery>({
          query: GET_COMPANY_USERS_AND_CONTRACTS,
        });
        companyUsersAndContracts = response.data;
      }

      const currentUser = companyUsersAndContracts?.company?.managers?.find(
        (manager) => manager?.userId?.toString() === user.id.toString(),
      );

      const hasAnActiveFreelancer = companyUsersAndContracts?.company?.contracts?.some(
        (contract) =>
          contract?.status &&
          [
            ContractStatus.ACTIVE,
            ContractStatus.OFFBOARDING,
            ContractStatus.ONBOARDING,
          ].includes(contract?.status) &&
          contract.type === ContractType.FREELANCER,
      );

      if (currentUser) {
        const userVars = {
          'Company User Id': currentUser?.id?.toString() ?? '',
          'Company User Role': currentUser?.role?.replace('_', ' ') as string,
          'Is Billing Contact': currentUser?.isBillingContact ? 'Yes' : 'No',
          'Is Signatory': currentUser?.isSignatory ? 'Yes' : 'No',
          'Has An Active Freelancer': hasAnActiveFreelancer ? 'Yes' : 'No',
          'Company Primary Entity Country': company.primaryEntityCountry,
          'Company Id': company?.id,
          'Company Display Name': company?.name,
        };
        updateIntercomUserData(userVars);
        FullStory.setUserVars(userVars);
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};
