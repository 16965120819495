/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import tw from 'twin.macro';

const TextLabelContainer = tw.div`text-ps text-text-tertiary`;

const CurrencyNote: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  return (
    <TextLabelContainer>
      <Trans
        i18nKey="definition-phase.compensation.currency-default-notice-contractor"
        t={t}
      >
        <p>
          The{' '}
          <span tw="text-ps text-text-primary">
            default billing rate currency{' '}
          </span>
          is chosen according to the tax residency country of the contractor
          chosen in the previous step.
        </p>
      </Trans>
    </TextLabelContainer>
  );
};

export default CurrencyNote;
