/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';
import * as yup from 'yup';

import TextInput from 'common/components/text-input';
import Textarea from 'common/components/textarea';

import { ContractType } from '__generated__/graphql';

import { BasicDetailsFormValues } from '../basic-details';
import { buildRenderPropsForJobScope } from '../validation-schema/job-scope-validation-schema';

const JobDescriptionInput: React.FC<{
  schema?:
    | yup.ObjectSchema<{
        scope?: yup.StringSchema<string | null | undefined>;
      }>
    | undefined;
}> = ({ schema }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const formContext = useFormContext<BasicDetailsFormValues>();

  const {
    register,
    watch,
    formState: { errors, defaultValues },
  } = formContext;

  const contractType = watch('contractType');
  const scopeOptions = buildRenderPropsForJobScope({ schema, defaultValues });

  return !scopeOptions.show ? null : (
    <TextInput.Container>
      <TextInput.Label htmlFor="scope">
        {scopeOptions.required
          ? t(
              'definition-phase.basic-details.jd.contractor.label',
              'Job Description',
            )
          : t(
              'definition-phase.basic-details.jd.label',
              'Job Description (Optional)',
            )}
      </TextInput.Label>
      <Textarea
        id="scope"
        placeholder={t(
          'definition-phase.basic-details.jd.placeholder',
          'Add a job description',
        )}
        rows={5}
        {...register('scope')}
      />

      {contractType !== ContractType.HR_MEMBER && (
        <p
          css={[
            tw`text-ps font-normal text-text-tertiary mt-extra-small mb-extra-small`,
            !isNewThemeApplied && tw`text-grey01`,
          ]}
        >
          {contractType === ContractType.CONTRACTOR
            ? t(
                'definition-phase.basic-details.jd.subtext-contractor',
                'Job description will appear on the agreement under “Scope of Work / Job Responsibilities”',
              )
            : t(
                'definition-phase.basic-details.jd.subtext',
                'Job description will appear on the contract under “Scope of Work / Job Responsibilities”',
              )}
        </p>
      )}

      {errors.scope && (
        <TextInput.Error>{errors.scope.message}</TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default JobDescriptionInput;
