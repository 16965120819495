const routes = {
  root: 'member-onboard',
  visa: {
    root: 'visa',
    blockPage: 'block',
    visaEligibility: 'eligibility-documents',
    visaApplication: 'reviews-documents',
    visaApproved: 'approved',
  },
  onboarding: {
    toString: (): string => 'onboarding',
    basicDetails: 'basic-details',
    bankDetails: 'bank-details',
    legalDetails: 'legal-details',
    legalDocuments: 'legal-documents',
    benefitDetails: 'benefit-details',
  },
  verification: {
    toString: (): string => 'verification',
    reviewConfirm: 'review-confirm',
  },
};

export default routes;
