import { gql } from '@apollo/client';

export default gql`
  mutation ForgotPassword($input: string) {
    forgotPassword(input: $input)
      @rest(
        type: "Void"
        method: "POST"
        path: "account/reset-password/init"
        endpoint: "user"
        bodySerializer: "text"
      ) {
      void
    }
  }
`;
