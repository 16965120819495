import { FileLink } from '__generated__/graphql';

import { isEmptyValue } from './types';

export const areFileLinks = (value: unknown): value is FileLink[] =>
  !!value &&
  Array.isArray(value) &&
  value.length > 0 &&
  value.every((item) => !!item && item?.__typename === 'FileLink');

export const hasKey = (o: unknown, key: string): o is Record<string, string> =>
  !!o &&
  typeof o === 'object' &&
  Object.prototype.hasOwnProperty.call(o, key) &&
  typeof (o as Record<string, unknown>)[key] === 'string';

export const includesIfNotEmpty = <T extends unknown>(
  list?: T[],
  item?: T,
): boolean => {
  if (isEmptyValue(item)) return false;
  if (!list) return false;
  return list.includes(item);
};
