/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { Accordion, Icon } from '@multiplier/common';

import IconButton from 'common/components/icon-button';
import monthNames from 'common/constants/month-names';

import { Contract, Payslip } from '__generated__/graphql';

import { DetailForm } from '../layout';

const PayslipsSection: React.FC<{ payslips: Contract['payslips'] }> = ({
  payslips,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const sortedPayslips = useMemo(
    () =>
      [...(payslips ?? [])]
        .sort((a, b) =>
          a?.month?.year === b?.month?.year
            ? (b?.month?.month ?? 0) - (a?.month?.month ?? 0)
            : (a?.month?.year ?? 0) > (b?.month?.year ?? 0)
            ? -1
            : 1,
        )
        .slice(0, 6),
    [payslips],
  );

  const handleOpenPayslip = (payslip: Payslip) =>
    payslip?.payslipDocument?.downloadUrl &&
    window.open(
      payslip.payslipDocument.downloadUrl,
      '_blank',
      'noopener,noreferrer',
    );

  return (
    <Accordion
      name={t('member-details.payslips.header', 'Payslips')}
      description={t(
        'member-details.payslips.description',
        'View payslips for last 6 months.',
      )}
      edit={false}
      icon="coin-arrow"
    >
      <DetailForm>
        {sortedPayslips?.map((payslip) => (
          <div
            data-testid="payslip-entry"
            tw="bg-grey05 rounded-10 py-24 px-32 flex gap-x-48 items-center"
            key={`${payslip?.month?.month}-${payslip?.month?.year}-${payslip?.createdOn}`}
          >
            <Icon name="pdf" height="64" width="48" />
            <div tw="flex-grow">
              <h6 tw="text-h6 font-semibold">
                {`${
                  payslip?.month?.month &&
                  Object.keys(monthNames)[payslip.month.month - 1]
                    ? monthNames[
                        Object.keys(monthNames)[payslip.month.month - 1]
                      ]
                    : ''
                } ${payslip?.month?.year ?? ''}`}
              </h6>
              <p tw="text-ps text-grey01">
                {payslip?.payslipDocument?.name ?? ''}
              </p>
            </div>
            {payslip && (
              <IconButton
                onClick={() => handleOpenPayslip(payslip)}
                size="medium"
                name="open-in"
                variant="outline"
                tw="bg-white"
              />
            )}
          </div>
        ))}
      </DetailForm>
    </Accordion>
  );
};

export default PayslipsSection;
