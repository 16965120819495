import { format, formatDuration, isValid, parse } from 'date-fns';

export const dateAsString = (
  date: string | Date | null | undefined,
  formatString = 'dd MMM yyyy',
): string => {
  if (!date) return '';

  if (typeof date === 'string') {
    const parsedDate = parse(date.substring(0, 10), 'yyyy-MM-dd', new Date());
    return isValid(parsedDate) ? format(parsedDate, formatString) : date;
  }

  return isValid(new Date(date))
    ? format(new Date(date), formatString)
    : date?.toString();
};

export const parseDate = (
  mayBeDate: string | Date,
  formatString = 'yyyy-MM-dd',
): Date =>
  typeof mayBeDate !== 'string' && isValid(mayBeDate)
    ? new Date(mayBeDate)
    : parse(String(mayBeDate).substr(0, 10), formatString, new Date());

export const formatDateAndTime = (date: Date): string => {
  const formatAMPM = () => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours ?? 12; // the hour '0' should be '12'
    const strTime = `${hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${ampm}`;
    return strTime;
  };

  const monthAndDay = format(date, 'MMM dd');
  const year = format(date, 'yyyy');

  return `${formatAMPM()}, ${monthAndDay}, ${year}`;
};

export const formatMonthCountToDurationString = (
  maxTenureInMonths: number | null | undefined,
): string =>
  formatDuration(
    {
      years: Math.floor((maxTenureInMonths ?? 0) / 12),
      months: (maxTenureInMonths ?? 0) % 12,
    },
    { format: ['years', 'months'] },
  );
