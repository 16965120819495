/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import {
  Controller,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import { ApolloError } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { StatelessFileUpload, TextInput } from '@multiplier/common';
import { isNil, isUndefined, omit } from 'lodash';

import AppFeature from 'app/features';
import i18nextUtil from 'app/utils/i18next-util';
import swiftCodesWithAdditionalCharge from 'common/constants/swift-codes-with-additional-charge';
import useUpdateCompanyBankDetails from 'contract-onboarding/company/hooks/submit-bank-details';
import {
  BankAccountType,
  SwiftFeeInfo,
  useGetSwiftFeeField,
} from 'contract-onboarding/components/additional-swift-fee-info';
import ErrorDetails, {
  setChangeErrorDetails,
} from 'contract-onboarding/components/bank-details-section/components/error-details';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import {
  BANK_STATEMENT_MAX_SIZE,
  BankDetailRequirementField,
  BankDetailsFormParams,
} from 'contract-onboarding/member/pages/onboarding/pages/bank-details';
import DynamicDetailsForm from 'contract-onboarding/member/pages/onboarding/pages/bank-details/components/dynamic-details-form';
import StaticDetailsForm from 'contract-onboarding/member/pages/onboarding/pages/bank-details/components/static-details-form';
import {
  getBankStatementsForUpload,
  getDynamicFields,
} from 'contract-onboarding/member/services/bank-data';
import { getBankStatementsSchema } from 'contract-onboarding/services/bank-details-schema';

import {
  BankAccountDetail,
  Contract,
  ContractType,
  Maybe,
  PaymentAccountRequirement,
} from '__generated__/graphql';

import 'twin.macro';

import stepConfig from '../../step-config';

const BankDetailsView: React.FC<
  OnboardingStepProps & {
    contract: Contract;
    member: Contract['member'];
    contractId: Contract['id'];
  }
> = ({ currentStep, onboardingSteps, contract, member, contractId }) => {
  const { t } = useTranslation('contract-onboarding.company');

  const isForcedV1Account = useFeature(AppFeature.SHOW_UPDATE_BANK_DETAILS_V1)
    ?.on;

  const paymentAccountRequirements = contract?.paymentAccountRequirements;

  const currentPaymentAccountRequirementType =
    member?.bankAccounts?.[0]?.paymentAccountRequirementType;

  const paymentAccountRequirementsMap = useMemo(
    () =>
      new Map<string, PaymentAccountRequirement>(
        paymentAccountRequirements?.map((req) => [
          req?.paymentAccountRequirementType as string,
          req as PaymentAccountRequirement,
        ]),
      ),
    [paymentAccountRequirements],
  );

  const methods = useForm<BankDetailsFormParams>({
    mode: 'onChange',
  });

  const {
    control,
    reset,
    formState: { isValid },
    handleSubmit,
    trigger,
    watch,
    setValue,
  } = methods;

  const [errorDetails, setErrorDetails] = useState<
    | {
        errorMessages: string[];
        errorCount: number;
      }
    | undefined
  >();

  const { loading, updateCompanyBankDetails } = useUpdateCompanyBankDetails(
    undefined,
    contractId,
    member?.id,
    (e: ApolloError) => setChangeErrorDetails(e, setErrorDetails),
    true,
    true,
  );

  const formSubmitHandler = (data: BankDetailsFormParams) => {
    const modifyBankStatements = data.bankStatements?.map(
      (bankStatement) => bankStatement.statement,
    );

    const modifiedData = {
      ...data,
      bankStatements: modifyBankStatements,
    };

    const { existingDocIds, newDocs } = getBankStatementsForUpload(
      modifiedData.bankStatements,
    );

    const payload = {
      ...modifiedData,
      bankStatements: newDocs,
      bankStatementDocIds: existingDocIds,
    };

    if (!isForcedV1Account && paymentAccountRequirementsMap?.size) {
      let omitIndexFromBankData;
      if (payload?.bankData) {
        omitIndexFromBankData = payload?.bankData
          .map((obj) => omit(obj, ['index']))
          .filter((obj) => !isNil(obj.value));
      }

      const values = {
        ...payload,
        bankData: omitIndexFromBankData,
      };

      const selectedAccountRequirement = paymentAccountRequirementsMap.get(
        data?.paymentAccountRequirementType ?? '',
      );

      updateCompanyBankDetails(true, values, {
        accountType: selectedAccountRequirement?.accountType,
        sourceCurrency: selectedAccountRequirement?.sourceCurrency,
        targetCurrency: selectedAccountRequirement?.targetCurrency,
        transferType: selectedAccountRequirement?.transferType,
        paymentPartner: selectedAccountRequirement?.paymentPartner,
        paymentAccountRequirementType:
          selectedAccountRequirement?.paymentAccountRequirementType,
      });
    } else {
      updateCompanyBankDetails(false, payload, {});
    }
    setErrorDetails(undefined);
  };

  const paymentAccountRequirementType = useWatch({
    control,
    name: 'paymentAccountRequirementType',
  });

  const { append, replace } = useFieldArray({
    control,
    name: 'bankStatements',
  });

  const bankDetailObject = useMemo(
    () =>
      (member?.bankAccounts?.[0]?.accountDetails ?? []).reduce(
        (obj, accountDetail: Maybe<BankAccountDetail>) => {
          obj[accountDetail?.key ?? ''] = accountDetail?.value ?? '';
          return obj;
        },
        {} as Record<string, string>,
      ) ?? {},
    [member],
  );

  useDeepCompareEffect(() => {
    if (!isForcedV1Account && paymentAccountRequirementsMap?.size) {
      const bankAccountDetails = member?.bankAccounts?.[0];
      const bankStatements =
        (bankAccountDetails?.bankStatements as File[]) ?? undefined;

      const modifyBankStatements = bankStatements?.map((statement) => ({
        statement,
      }));

      reset({
        paymentAccountRequirementType,
        bankData: Array.from(
          getDynamicFields(
            paymentAccountRequirementsMap.get(
              paymentAccountRequirementType ?? '',
            )?.requirementFields,
          )?.values() ?? [],
        ).map((val: Maybe<BankDetailRequirementField>, index: number) => ({
          key: val?.key,
          value: val?.key ? bankDetailObject[val?.key] : null,
          index,
        })),
        bankStatements: modifyBankStatements,
      });
    } else if (member?.__typename === 'Member' && member.bankAccounts?.[0]) {
      const bankAccountDetails = member.bankAccounts[0];
      const bankStatements =
        (bankAccountDetails.bankStatements as File[]) ?? undefined;

      const modifyBankStatements = bankStatements?.map((statement) => ({
        statement,
      }));

      reset({
        accountName: bankAccountDetails.accountName ?? '',
        accountNumber: bankAccountDetails.accountNumber ?? '',
        bankName: bankAccountDetails.bankName ?? '',
        branchName: bankAccountDetails.branchName ?? '',
        localBankCode: bankAccountDetails.localBankCode ?? '',
        swiftCode: bankAccountDetails.swiftCode ?? '',
        bankStatements: modifyBankStatements,
      });
    }
  }, [contract, member, paymentAccountRequirementType, bankDetailObject]);

  const allBankStatements = useWatch({
    control,
    name: 'bankStatements',
  });

  const handleRemoveFile = (file: File) => {
    if (allBankStatements?.length) {
      const filteredFiles = allBankStatements.filter(
        (statementData: { statement: File }) =>
          !(
            statementData.statement.name === file.name &&
            statementData.statement.size === file.size &&
            statementData.statement.lastModified === file.lastModified
          ),
      );
      replace(filteredFiles);
    }
  };

  useEffect(() => {
    if (
      currentPaymentAccountRequirementType &&
      paymentAccountRequirementsMap?.has(currentPaymentAccountRequirementType)
    ) {
      setValue(
        'paymentAccountRequirementType',
        currentPaymentAccountRequirementType,
      );
    } else if (paymentAccountRequirementsMap?.size === 1) {
      setValue(
        'paymentAccountRequirementType',
        Array.from(paymentAccountRequirementsMap.keys())[0],
      );
    }
  }, [paymentAccountRequirementsMap, currentPaymentAccountRequirementType]);

  const swiftFeeField = useGetSwiftFeeField(
    !isForcedV1Account && contract?.paymentAccountRequirements?.length
      ? BankAccountType.DYNAMIC
      : BankAccountType.STATIC,
    paymentAccountRequirementsMap,
    paymentAccountRequirementType,
  );

  const shouldShowSwiftFeeInfo =
    contract?.type === ContractType.FREELANCER ||
    (contract?.type === ContractType.CONTRACTOR &&
      !!swiftFeeField &&
      swiftCodesWithAdditionalCharge.includes(
        watch(swiftFeeField as FieldPath<BankDetailsFormParams>) as string,
      ));

  return (
    <StepLayout data-testid="bank-details-view">
      <FormLayout onSubmit={handleSubmit((data) => formSubmitHandler(data))}>
        <FormProvider {...methods}>
          <FormCard>
            {!!errorDetails && (
              <ErrorDetails
                errorCount={errorDetails?.errorCount}
                errorMessages={errorDetails?.errorMessages}
              />
            )}
            <div tw="grid gap-x-16 gap-y-24">
              {!!paymentAccountRequirementsMap.size && !isForcedV1Account ? (
                <DynamicDetailsForm
                  paymentAccountRequirementsMap={paymentAccountRequirementsMap}
                  currency={contract?.currency}
                  bankDetailObject={bankDetailObject}
                  contractType={contract?.type}
                  selectedAccountRequirementType={paymentAccountRequirementType}
                />
              ) : (
                <StaticDetailsForm
                  country={contract?.country}
                  contractType={contract?.type}
                />
              )}
              {!isUndefined(contract?.type) &&
                contract?.type !== ContractType.HR_MEMBER && (
                  <TextInput.Container>
                    <TextInput.Label>
                      {t(
                        i18nextUtil.buildTransKeys(
                          'note',
                          'bank-details.proof-documents',
                          i18nextUtil.asSegment(contract?.country),
                        ),
                        'Attach Bank Statement / Cancelled Cheque',
                        { ns: 'contract-onboarding.common' },
                      )}
                    </TextInput.Label>
                    <TextInput.Helper>
                      {t(
                        'bank-details.proof-document.helper',
                        "The bank statement and/or cheque must clearly display the account holder's name and account number. We collect this information to verify the bank account number and ensure that payments are made to the correct account.",
                      )}
                    </TextInput.Helper>
                    <Controller
                      name="bankStatements"
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) => {
                          if (contract?.type === ContractType.HR_MEMBER) {
                            return Promise.resolve(true);
                          }
                          return getBankStatementsSchema(
                            t,
                            contract?.country,
                          )?.validate(value);
                        },
                      }}
                      render={({ field: { value } }) => (
                        <StatelessFileUpload
                          minimal
                          multiple
                          maxSize={BANK_STATEMENT_MAX_SIZE}
                          maxTotalSize={BANK_STATEMENT_MAX_SIZE}
                          files={value?.map(
                            (bankStatement) => bankStatement.statement,
                          )}
                          description={t(
                            'bank-details.bank-statement-cancelled-checque.field-description',
                            'Files Supported: PDF, PNG, JPG (Max 5mb)',
                          )}
                          data-testid="bank-statement"
                          onFileDrop={(files: File[]) => {
                            files.forEach((file) =>
                              append({ statement: file }),
                            );
                            trigger();
                          }}
                          onRemoveFile={handleRemoveFile}
                        />
                      )}
                    />
                  </TextInput.Container>
                )}
            </div>
            {shouldShowSwiftFeeInfo && <SwiftFeeInfo />}
          </FormCard>
        </FormProvider>
        <StepNavigationFooter
          disabled={!isValid}
          contractId={contractId}
          submitLoading={loading}
          onboardingSteps={onboardingSteps}
          currentStep={currentStep}
          stepConfig={stepConfig}
        />
      </FormLayout>
    </StepLayout>
  );
};

export default BankDetailsView;
