/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { useToggle } from 'react-use';

import { useFeature } from '@growthbook/growthbook-react';
import {
  IconBadge,
  IconButton,
  ReadMoreText,
  ThemeContext,
} from '@multiplier/common';
import { set } from 'date-fns';
import tw, { theme } from 'twin.macro';

import { DropdownValue } from 'common/components/combo-box';
import { Religion } from 'common/constants/default-labels';
import AdditionalPayForm, {
  AdditionalPayDropdownType,
  AdditionalPayFormValues,
  PaymentType,
} from 'contract-onboarding/company/components/additional-pay-form';
import { useGetCountryLabourStandards } from 'contract-onboarding/company/hooks';
import InfoNote from 'performance-reviews/components/info-note';
import { useSalaryRevisionNotes } from 'performance-reviews/hooks';

import {
  Company,
  Contract,
  CountryCode,
  CurrencyCode,
  FixedPayComponent,
  FixedPayComponentInput,
  Maybe,
  PayAmountType,
} from '__generated__/graphql';

import { MandatoryPill } from '../..';
import AdditionalPayTitle from '../additional-pay-title';

export type AdditionalPayItemProps = {
  contractId?: Contract['id'];
  contractType?: Contract['type'];
  basePayFrequency?: FixedPayComponent['frequency'];
  basePay?: number;
  currency?: Maybe<CurrencyCode>;
  currencyOptions: Maybe<DropdownValue[]>;
  payItem: AdditionalPayFormValues;
  deletePayItem: () => void;
  onUpdate: (payItem: AdditionalPayFormValues) => void;
  showEdit?: boolean;
  existingPays?: { payType: PaymentType; name: string }[];
  companyName: Company['displayName'];
  religion?: Maybe<Religion>;
  additionalPaysSupported?: AdditionalPayDropdownType[];
  uncommittedSubFormChangesCallback?: (values: boolean) => void;
  countryCode?: Maybe<CountryCode>;
  maxDate?: Date | string;
  minDate?: Date | string;
  countryStateCode: Contract['countryStateCode'];
  showRevisionNoteForPayTypes?: PaymentType[];
  basePayPaymentFrequency?: FixedPayComponentInput['paymentFrequency'];
};

const AdditionalPayItem: React.FC<AdditionalPayItemProps> = ({
  contractId,
  contractType,
  basePayFrequency,
  basePay,
  currency,
  currencyOptions,
  payItem,
  deletePayItem,
  onUpdate,
  showEdit = false,
  existingPays,
  companyName,
  religion,
  uncommittedSubFormChangesCallback,
  countryCode,
  additionalPaysSupported,
  maxDate,
  minDate,
  countryStateCode,
  showRevisionNoteForPayTypes = [],
  basePayPaymentFrequency,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const [edit, toggleEdit] = useToggle(false);
  const { getNote, message } = useSalaryRevisionNotes('fixed-pay');
  const isContractOnboardingHourlyPayEnabled = useFeature(
    'contract-onboarding-hourly-pay',
  )?.on;

  const { data: labourStandards } = useGetCountryLabourStandards({
    country: countryCode,
    countryStateCode,
  });

  useEffect(() => {
    if (
      showRevisionNoteForPayTypes.includes(payItem?.payType) &&
      contractId &&
      payItem.amountType === PayAmountType.FIXED_AMOUNT &&
      payItem.payoutMonth
    ) {
      const effectiveDate = set(new Date(), {
        date: 1,
        month: payItem?.payoutMonth?.month,
        year: payItem?.payoutMonth?.year,
      });

      getNote(contractId, effectiveDate);
    }
  }, [showRevisionNoteForPayTypes, payItem, contractId]);

  const conditions = useMemo(() => payItem?.conditions, [
    isContractOnboardingHourlyPayEnabled,
    contractType,
    basePayFrequency,
    labourStandards,
    countryCode,
  ]);

  const otherExistingPays = React.useMemo(
    () =>
      existingPays?.filter(
        (f) => f.name !== payItem.name && f.payType !== payItem.payType,
      ),
    [existingPays, payItem.name, payItem.payType],
  );

  if (edit) {
    return (
      <AdditionalPayForm
        contractType={contractType}
        countryCode={countryCode}
        basePay={basePay}
        payItem={payItem}
        currency={currency}
        currencyOptions={currencyOptions}
        uncommittedSubFormChangesCallback={uncommittedSubFormChangesCallback}
        onCancel={() => {
          toggleEdit(false);
        }}
        onSubmit={(v: AdditionalPayFormValues) => {
          onUpdate(v);
          toggleEdit(false);
        }}
        existingPays={otherExistingPays}
        additionalPaysSupported={additionalPaysSupported}
        maxDate={maxDate}
        minDate={minDate}
        basePayPaymentFrequency={basePayPaymentFrequency}
      />
    );
  }

  return (
    <div tw="w-full mt-small">
      <div
        data-testid="additional-pay-item"
        css={[!conditions && tw`rounded-large`]}
        tw="border border-border-secondary rounded-t-large px-base pt-base pb-small gap-x-small grid grid-cols-3 grid-template-columns[min-content 1fr min-content]"
      >
        <div>
          <IconBadge icon="dollar" tw="bg-background-secondary" />
        </div>
        <div tw="text-text-primary flex items-center ">
          <AdditionalPayTitle
            payItem={payItem}
            companyName={companyName}
            religion={religion}
            contractType={contractType}
            countryCode={countryCode}
          />
        </div>
        <div>
          {showEdit && payItem?.isDeletable && (
            <div tw="flex gap-x-small items-end">
              <IconButton
                name="pencil"
                fill={!isNewThemeApplied ? 'transparent' : undefined}
                stroke={
                  !isNewThemeApplied ? theme`colors.icon-brand` : undefined
                }
                data-testid="edit-pay-item"
                variant="outline"
                size="medium"
                onClick={() => {
                  toggleEdit(true);
                }}
              />
              <IconButton
                name="bin"
                fill={!isNewThemeApplied ? 'transparent' : undefined}
                stroke={
                  !isNewThemeApplied ? theme`colors.icon-brand` : undefined
                }
                data-testid="delete-pay-item"
                variant="outline"
                size="medium"
                onClick={deletePayItem}
              />
            </div>
          )}
          {!payItem?.isDeletable && <MandatoryPill />}
        </div>
      </div>
      {conditions && (
        <div
          data-testid="additional-pay-condition"
          tw="flex border border-border-secondary border-t-0 rounded-b-base p-base gap-x-base"
        >
          <span tw="font-semibold text-ps whitespace-nowrap">Note :</span>
          <ReadMoreText
            content={conditions}
            charLimit={175}
            textStyle={tw`text-ps text-text-tertiary overflow-ellipsis`}
          />
        </div>
      )}
      {message && <InfoNote tw="mt-extra-small">{message}</InfoNote>}
    </div>
  );
};

export default AdditionalPayItem;
