import React, { createContext, useEffect, useMemo, useState } from 'react';

import { useFeature } from '@growthbook/growthbook-react';
import { AppFeature } from '@multiplier/growthbook';

export enum FrontendTheme {
  Current = 'Current',
  New = 'Multiplier 2.2',
}

export const ThemeContext = createContext<{
  theme: string;
  isNewThemeApplied: boolean;
  toggleTheme: ({ forceTheme }: { forceTheme?: FrontendTheme }) => void;
}>({
  theme: 'Current',
  isNewThemeApplied: false,
  toggleTheme: () => null,
});

const attributeName = 'data-theme';

const getDataTheme = () => document.documentElement.getAttribute(attributeName);

const setDataTheme = (defaultTheme: FrontendTheme) => {
  document.documentElement.setAttribute(attributeName, defaultTheme);
};

const useDataThemeObserver = (
  setCurrentTheme: (
    value: ((prevState: FrontendTheme) => FrontendTheme) | FrontendTheme,
  ) => void,
  defaultTheme: FrontendTheme,
) => {
  useEffect(() => {
    if (defaultTheme && Object.values(FrontendTheme).includes(defaultTheme)) {
      setDataTheme(defaultTheme);
      setCurrentTheme(defaultTheme);
    }
  }, [defaultTheme]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === attributeName
        ) {
          const value = getDataTheme() as FrontendTheme;
          setCurrentTheme((prev) => (prev !== value ? value : prev));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);
};

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentTheme, setCurrentTheme] = useState<FrontendTheme>(
    getDataTheme() as FrontendTheme, // work for next provider (second, third)
  );

  const defaultTheme = useFeature(AppFeature.FRONTEND_THEME)?.value;
  useDataThemeObserver(setCurrentTheme, defaultTheme);

  const toggleTheme = ({ forceTheme }: { forceTheme?: FrontendTheme }) => {
    let updatedTheme;

    if (forceTheme === undefined) {
      updatedTheme =
        currentTheme === FrontendTheme.New
          ? FrontendTheme.Current
          : FrontendTheme.New;
    } else {
      updatedTheme = forceTheme;
    }

    setDataTheme(updatedTheme);
  };

  const isNewThemeApplied = useMemo(() => currentTheme === FrontendTheme.New, [
    currentTheme,
  ]);

  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
        isNewThemeApplied,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
