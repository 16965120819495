import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@multiplier/common';

import { Experience } from '../../../../types';
import routes from '../../../routes';
import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const CompleteContractCreationTask: React.FC<TaskEntryProps> = ({
  task,
  actionable,
  contractId,
  handleCloseSidePanel,
}) => {
  const { t } = useTranslation('contract-onboarding');

  const navigate = useNavigate();

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.complete-contract-creation.title',
        'Complete contract creation',
      )}
      action={
        <Button
          size="small"
          tw="text-ps"
          variant="secondary"
          disabled={!actionable}
          onClick={() => {
            if (handleCloseSidePanel) handleCloseSidePanel();
            navigate(`/${Experience.COMPANY}/${routes.team}/${contractId}`);
          }}
        >
          {t(
            'onboarding-checklist.task-list.complete-contract-creation.action',
            'Continue',
          )}
        </Button>
      }
      completed={task.completed}
    />
  );
};

export default CompleteContractCreationTask;
