import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const contractWetInkPreparing: ContractOnboardingStatusConfig = {
  title: (t) =>
    t(
      'onboarding-phase.contract-wet-ink-preparing.title',
      'Preparing contract',
    ),
  description: (t) =>
    t('onboarding-phase.contract-wet-ink-preparing.description', {
      defaultValue:
        'You are in the process of preparing Employment contract as per the employment details submitted.\n' +
        'You will be notified once the contract is ready for review and signatures.\n' +
        'For clarifications reach out to us at support@usemultiplier.com',
    }),
  statusLabel: (t) =>
    t('onboarding-phase.contract-wet-ink-preparing.label', ''),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.contract-wet-ink-preparing.status',
      'Pending on Multiplier',
    ),
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default contractWetInkPreparing;
