import React, { useState } from 'react';

import tw from 'twin.macro';

import TextInput from '../../../../../text-input';
import { TableFilterConfigValue } from '../../../../types';
import ShortenMultiValues from '../shorten-multi-values';

const MultiTextInput: React.FC<{
  values: string[];
  onChange: (val: string[]) => void;
  filterConfig: TableFilterConfigValue;
}> = ({ values, onChange, filterConfig }) => {
  const [input, setInput] = useState('');

  const updateValue = (val: string) => {
    if (values && !values.includes(val)) onChange([...values, val]);
    if (!values) onChange([val]);
    setInput('');
  };

  const onTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const endChar = currentValue?.slice(-1);
    if (endChar === ',' || endChar === ';') {
      const val = currentValue.slice(0, -1)?.trim();
      updateValue(val);
    } else {
      setInput(currentValue);
    }
  };

  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const val = input?.trim();
    if (e.key === 'Enter' && val) {
      updateValue(val);
    } else if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      !val &&
      values?.length
    ) {
      onChange(values.slice(0, -1));
    }
  };

  const onBlur = () => {
    const val = input?.trim();
    if (val) {
      updateValue(val);
    }
  };

  return (
    <div
      tw="rounded-tiny border border-border-primary p-6 flex flex-col gap-6  bg-background-white"
      data-testid="multiple-filter-input"
    >
      <ShortenMultiValues
        values={values}
        onRemove={(val) => onChange(values.filter((v) => v !== val))}
      />

      <TextInput
        divStyles={tw`flex-grow h-32`}
        placeholder={filterConfig?.placeholder ?? ''}
        value={input}
        onChange={onTextInput}
        onKeyDown={onKeydown}
        onBlur={onBlur}
        inputStyles={tw`rounded-6 border-0 text-ps`}
      />
    </div>
  );
};

export default MultiTextInput;
