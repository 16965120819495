import {
  Experience,
  ModuleConfig,
  NavGroups,
  WidgetVariant,
} from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'contract-onboarding',
  permission: 'contract-onboarding',
  folder: 'contract-onboarding/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Hire & Onboard',
    route: routes.root,
    icon: { name: 'globe' },
    activeIcon: { name: 'globe-filled' },
    sort: 1,
    home: false,
    group: NavGroups.MAIN,
  },
  widget: {
    sort: 1,
    list: [
      {
        show: true,
        file: 'contract-onboarding-widget',
        variant: WidgetVariant.FULL_WIDTH,
      },
    ],
  },
  preloadNs: [
    'insurance',
    'resources',
    'salary-calculator',
    'team.equipment',
    'equipment.company',
    'contract-onboarding',
    'contract-onboarding.member',
    'team.common',
    'contract-onboarding.company',
    'contract-onboarding.common',
    'insurance.common',
  ],
};

export default config;
