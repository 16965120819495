import growthBook from '@multiplier/growthbook';

import AppFeature from 'app/features';
import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import { showModuleBasedOnContractStatusAndContractStarted } from 'app/utils/module-checks';

import { ContractStatus } from '__generated__/graphql';

const config: ModuleConfig = {
  id: 'timesheets',
  permission: 'timesheets',
  folder: 'timesheets/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Timesheets',
    route: 'timesheets',
    sort: 3,
    home: false,
    icon: { name: 'calendar-add-on' },
    activeIcon: { name: 'calendar-add-on-filled' },
    group: NavGroups.MAIN,
  },
  enabled: async () =>
    (await showModuleBasedOnContractStatusAndContractStarted([
      ContractStatus.ACTIVE,
      ContractStatus.ONBOARDING,
      ContractStatus.OFFBOARDING,
    ])) && !growthBook.isOn(AppFeature.TIMESHEET_DISABLED),
};

export default config;
