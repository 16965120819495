import {
  Contract,
  ContractType,
  LegalBindingType,
  Maybe,
} from '__generated__/graphql';

export const isPartnerEOR = (
  legalBindType: Maybe<LegalBindingType> | undefined,
): boolean => legalBindType === LegalBindingType.PARTNER;

/* Requirement https://app.clickup.com/t/2adjtf7 */

export const isHrMember = (
  contractType: Maybe<ContractType> | undefined,
): boolean => contractType === ContractType.HR_MEMBER;

export const checkPartnerEorOrHrMember = (
  contract: Maybe<Contract>,
): boolean => {
  const contractUnderPartnerEOR = isPartnerEOR(contract?.compliance?.type);
  const revisionForHRMember = isHrMember(contract?.type);

  return contractUnderPartnerEOR || revisionForHRMember;
};
