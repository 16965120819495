import { TFunction } from 'i18next';

import { TeamViewAllSubTab } from '.';

enum TeamViewTab {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
  INACTIVE = 'INACTIVE',
  OFFBOARDING = 'OFFBOARDING',
  ENDED = 'ENDED',
  ALL = 'ALL',
  BLOCKED = 'BLOCKED',
}

const columnWidthMapper: { [key: string]: string[] } = {
  [TeamViewTab.ACTIVE]: ['10%', '40%', '20%', '15%', '15%', '0', '0'],
  [TeamViewTab.ONBOARDING]: ['10%', '25%', '15%', '15%', '15%', '7.5%', '7.5%'],
  [TeamViewTab.BLOCKED]: ['10%', '25%', '15%', '15%', '15%', '7.5%', '7.5%'],
  [TeamViewTab.INACTIVE]: ['10%', '25%', '15%', '15%', '15%', '7.5%', '7.5%'],
  [TeamViewTab.OFFBOARDING]: [
    '10%',
    '25%',
    '15%',
    '15%',
    '15%',
    '7.5%',
    '7.5%',
  ],
  [TeamViewTab.ENDED]: ['10%', '25%', '15%', '15%', '15%', '7.5%', '7.5%'],
  [TeamViewTab.ALL]: ['10%', '25%', '15%', '15%', '15%', '15%', '5%'],
};

const tColumns = ({
  selectedTab,
  selectedSubTab,
  t,
}: {
  selectedTab: TeamViewTab;
  selectedSubTab: TeamViewAllSubTab;
  t: TFunction<'team.company'>;
}): {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy: boolean;
}[] => {
  const widthData = columnWidthMapper[selectedTab];
  const columns = [
    {
      Header: t('main.columns.country', 'Country') as string,
      accessor: 'country',
      width: widthData[0],
      disableSortBy: selectedTab === TeamViewTab.ONBOARDING,
    },
    {
      Header: t('main.columns.name', 'Name') as string,
      accessor: 'name',
      width: widthData[1],
      disableSortBy: selectedTab === TeamViewTab.ONBOARDING,
    },
    {
      Header: t('main.columns.pay', 'Pay') as string,
      accessor: 'pay',
      width: widthData[2],
      disableSortBy: true,
    },
    {
      Header: t('main.columns.employment-type', 'Employment Type') as string,
      accessor: 'employmentType',
      width: widthData[3],
      disableSortBy: true,
    },
    {
      Header: t('main.columns.start-date', 'Start Date') as string,
      accessor: 'startDate',
      width: widthData[4],
      disableSortBy: selectedTab === TeamViewTab.ONBOARDING,
    },
  ];

  if (
    selectedTab === TeamViewTab.ALL &&
    (selectedSubTab === TeamViewAllSubTab.INACTIVE ||
      selectedSubTab === TeamViewAllSubTab.BLOCKED)
  ) {
    columns.splice(3, 0, {
      Header: t('main.columns.status', 'Status') as string,
      accessor: 'status',
      width: widthData[5],
      disableSortBy: true,
    });
    columns.push({
      Header: t('main.columns.actions', 'Actions') as string,
      accessor: 'actions',
      width: widthData[6],
      disableSortBy: true,
    });
  }

  if (selectedTab === TeamViewTab.ONBOARDING) {
    columns.splice(3, 0, {
      Header: t('main.columns.progres', 'Progress') as string,
      accessor: 'progress',
      width: widthData[5],
      disableSortBy: true,
    });
    columns.push({
      Header: t('main.columns.actions', 'Actions') as string,
      accessor: 'actions',
      width: widthData[6],
      disableSortBy: true,
    });
  }

  if (
    selectedTab === TeamViewTab.ALL &&
    selectedSubTab === TeamViewAllSubTab.ALL
  ) {
    columns.splice(3, 0, {
      Header: t('main.columns.contract-status', 'Contract Status') as string,
      accessor: 'contractStatus',
      width: widthData[5],
      disableSortBy: true,
    });
    columns.push({
      Header: t('main.columns.actions', 'Actions') as string,
      accessor: 'actions',
      width: widthData[6],
      disableSortBy: true,
    });
  }

  if (selectedTab === TeamViewTab.OFFBOARDING) {
    columns.splice(3, 0, {
      Header: t('main.columns.offboarding-status', 'Status') as string,
      accessor: 'offboardingStatus',
      width: widthData[5],
      disableSortBy: true,
    });

    columns.splice(4, 1, {
      Header: t('main.columns.end-date', 'Last Working Date') as string,
      accessor: 'endDate',
      width: widthData[5],
      disableSortBy: false,
    });

    columns.push({
      Header: t('main.columns.actions', 'Actions') as string,
      accessor: 'actions',
      width: widthData[6],
      disableSortBy: true,
    });
  }

  if (
    selectedTab === TeamViewTab.ALL &&
    selectedSubTab === TeamViewAllSubTab.ENDED
  ) {
    columns.splice(4, 1, {
      Header: t(
        'main.columns.employment-end-date',
        'Employment End Date',
      ) as string,
      accessor: 'endDate',
      width: widthData[5],
      disableSortBy: false,
    });
  }

  if (selectedTab === TeamViewTab.ONBOARDING) {
    columns.push({
      Header: t('main.columns.onboardingBucket', 'Onboarding Bucket') as string,
      accessor: 'onboardingBucket',
      width: widthData[0],
      disableSortBy: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      aggregate: 'count',
    });
    columns.push({
      Header: t(
        'main.columns.onboardingBucketNumber',
        'Onboarding Bucket Number',
      ) as string,
      accessor: 'onboardingBucketNumber',
      width: widthData[0],
      disableSortBy: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // sortDescFirst: true,
    });
  }

  return columns;
};

// eslint-disable-next-line import/prefer-default-export
export { tColumns };
