import React from 'react';

import { useTranslation } from 'react-i18next';

import { dateAsString, parseDate } from '@multiplier/format';
import tw from 'twin.macro';

import {
  Contract,
  useGetConfigurationQuery,
  useGetContractQuery,
} from '__generated__/graphql';

const Wrapper = tw.p`text-foreground`;

const NewStartDateMessage: React.FC<{
  contractId: Contract['id'];
}> = ({ contractId }) => {
  const { t } = useTranslation('contract-onboarding.company');

  const { data: contractData } = useGetContractQuery({
    variables: {
      id: contractId as string,
    },
  });

  const { data: configurationData } = useGetConfigurationQuery({
    variables: {
      filters: {
        contractId,
      },
    },
  });

  const contractStartDate =
    contractData?.contract?.startOn && parseDate(contractData.contract.startOn);

  const earliestJoiningDateWithTAT =
    configurationData?.configuration?.contractChangeRequestConfig
      ?.earliestJoiningDateWithTurnAround &&
    parseDate(
      configurationData.configuration.contractChangeRequestConfig
        .earliestJoiningDateWithTurnAround,
    );

  const shouldShowNewStartDateMessage =
    contractStartDate != null &&
    earliestJoiningDateWithTAT != null &&
    earliestJoiningDateWithTAT > contractStartDate;

  return shouldShowNewStartDateMessage ? (
    <Wrapper>
      {t('view-requested-changes.helpText', {
        defaultValue:
          'The current start date {{oldStartDate}} might not be viable based on the changes requested. It is recommended that you set the start date to {{newStartDate}}',
        replace: {
          oldStartDate: dateAsString(contractStartDate),
          newStartDate: dateAsString(earliestJoiningDateWithTAT),
        },
      })}
    </Wrapper>
  ) : null;
};

export default NewStartDateMessage;
