import React, { useContext } from 'react';

import tw, { TwStyle } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';

const IconBadge: React.FC<{
  icon: string;
  color?: string;
  stroke?: string;
  badgeContent?: React.ReactNode;
  badgeStyles?: TwStyle;
  iconStyle?: TwStyle;
  iconContainerStyle?: TwStyle[];
  wrapperStyle?: TwStyle[];
}> = ({
  icon,
  color,
  stroke,
  iconStyle,
  badgeStyles,
  badgeContent,
  iconContainerStyle,
  wrapperStyle,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <div css={[tw`relative inline-block`, wrapperStyle]}>
      {badgeContent ? (
        <div
          css={[
            tw`absolute text-pxs max-w-[36px] min-w-[20px] h-[20px] text-text-inverted-primary top-none right-none bg-background-action rounded-base`,
            tw`flex justify-center items-center -translate-y-1/2`,
            !isNewThemeApplied && tw`bg-error rounded-10`,
            badgeStyles,
          ]}
        >
          <div data-testid="badge-content">{badgeContent}</div>
        </div>
      ) : null}
      <div
        css={[
          tw`flex flex-row shrink-0 justify-center items-center w-48 h-48 rounded-base`,
          iconContainerStyle,
        ]}
        {...props}
      >
        <Icon name={icon} fill={color} stroke={stroke} css={[iconStyle]} />
      </div>
    </div>
  );
};

export default IconBadge;
