import {
  CountryCompliance,
  GetMemberQuery,
  LegalDocumentCategory,
} from '__generated__/graphql';

// eslint-disable-next-line import/prefer-default-export
export const haveCompletedOnboardingForms = ({
  onboardingFormRequirements,
  legalDocuments,
}: {
  onboardingFormRequirements: CountryCompliance['memberDocumentRequirements'];
  legalDocuments: NonNullable<
    NonNullable<GetMemberQuery['member']>
  >['legalDocuments'];
}): boolean =>
  onboardingFormRequirements?.length === 0 ||
  (
    legalDocuments?.filter(
      (legalDocument) =>
        legalDocument?.category === LegalDocumentCategory.PAYROLL,
    ) ?? []
  ).length > 0;
