/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Button, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { Experience } from '../../../../app/models/module-config';
import { userVar } from '../../../../app/vars';

const SwitchViewButton = (): React.ReactElement | null => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.member');
  const navigate = useNavigate();
  const { experiences } = useReactiveVar(userVar);

  if (!experiences.authorized.includes(Experience.COMPANY)) return null;
  return (
    <Button
      variant="inline"
      size="medium"
      css={[
        tw`no-underline text-p text-text-inverted-secondary font-normal px-none`,
        !isNewThemeApplied && tw`text-grey01`,
      ]}
      onClick={() => navigate('/company')}
    >
      <div tw="flex items-center">
        {t('button.back-to-team', 'Back To Team')}
      </div>
    </Button>
  );
};

export default SwitchViewButton;
