import { handlers } from '@multiplier/notifications';

const {
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
  uploadSuccessNotification,
  uploadErrorNotification,
  uploadSuccessPersistentNotification,
} = handlers;

export {
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
  uploadSuccessNotification,
  uploadErrorNotification,
  uploadSuccessPersistentNotification,
};
