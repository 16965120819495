import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'resources',
  permission: 'resources',
  folder: 'resources',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Resources',
    route: 'resources',
    icon: { name: 'box' },
    activeIcon: { name: 'box-filled' },
    sort: 3,
    home: false,
    group: NavGroups.MAIN,
  },
};

export default config;
