import React, { RefObject, useContext } from 'react';

import tw from 'twin.macro';

import Checkbox from '../../../checkbox';
import { ThemeContext } from '../../../theme-provider';
import { ComboBoxVariants, DropdownValue } from '../../types';

interface DropdownEntryProps {
  selectedValueRef: RefObject<HTMLTableRowElement> | null;
  selectedOptionRef: RefObject<HTMLTableRowElement> | null;
  dropdownValue: DropdownValue;
  index: number;
  selectedIndex: number;
  onValueSelected: (dropdownValue: DropdownValue) => void;
  selectDropdown: (e: React.KeyboardEvent) => void;
  variant: ComboBoxVariants;
  selectedValue?: DropdownValue | null;
  showCheckbox?: boolean;
}

const DropdownEntry: React.FC<DropdownEntryProps> = ({
  selectedValueRef,
  selectedOptionRef,
  dropdownValue,
  index,
  selectedIndex,
  onValueSelected,
  selectDropdown,
  variant,
  selectedValue,
  showCheckbox = false,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  const onKeyUp = (e: React.KeyboardEvent) => {
    e.preventDefault();
    selectDropdown(e);
  };
  const onClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onValueSelected(dropdownValue);
  };
  const isSelected = Boolean(selectedValue && dropdownValue === selectedValue);

  return (
    <tr
      ref={
        index === selectedIndex
          ? selectedOptionRef
          : isSelected
          ? selectedValueRef
          : undefined
      }
      key={
        typeof dropdownValue?.title === 'string'
          ? dropdownValue?.title
          : dropdownValue?.value
      }
      data-testid={`dropdown-entry-${dropdownValue.value}`}
      css={[
        tw`hover:(bg-background-secondary cursor-pointer) flex items-center p-small w-full`,
        index === selectedIndex && tw`bg-background-secondary`,
        dropdownValue === selectedValue &&
          tw`font-semibold text-text-primary bg-background-primary`,
        !isNewThemeApplied && tw`hover:(bg-grey05)`,
        !isNewThemeApplied && index === selectedIndex && tw`bg-grey05`,
        !isNewThemeApplied &&
          dropdownValue === selectedValue &&
          tw`text-foreground bg-grey04`,
      ]}
      onClick={onClick}
      onKeyUp={onKeyUp}
      role="button"
      tabIndex={0}
    >
      {showCheckbox && (
        <Checkbox
          tw="pointer-events-none"
          checked={isSelected}
          checkboxSize="small"
        />
      )}
      <td
        css={[
          tw`flex flex-row items-center gap-x-extra-small w-auto min-w-max`,
          variant !== 'default' && tw`font-semibold`,
        ]}
      >
        {dropdownValue.icon && (
          <td tw="w-large h-large">{dropdownValue.icon}</td>
        )}
        {dropdownValue.preTitle}
        {dropdownValue.title}
      </td>
      {variant !== 'default' && (
        <td
          css={[
            tw`w-full px-small text-text-secondary font-normal`,
            (!dropdownValue.title || dropdownValue.description) &&
              tw`text-text-primary`,
            !isNewThemeApplied &&
              (!dropdownValue.title || dropdownValue.description) &&
              tw`text-foreground`,
          ]}
        >
          {dropdownValue.label}
          {dropdownValue.description && (
            <p
              css={[
                tw`text-ps text-text-secondary`,
                !isNewThemeApplied && tw`text-grey02`,
              ]}
            >
              {dropdownValue.description}
            </p>
          )}
        </td>
      )}
    </tr>
  );
};

export default DropdownEntry;
