/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { Button, ThemeContext, ToolTip } from '@multiplier/common';

import { useModal } from 'app/hooks';
import {
  isWetInkFlowEnabled,
  isWetInkSignatureEnabled,
} from 'contract-onboarding/company/pages/onboarding-phase/status-config';
import RequestChangesAction from 'contract-onboarding/components/contract-request-changes/request-changes-action';
import RequestChangesForm from 'contract-onboarding/components/contract-request-changes/request-changes-form';
import useWetInkConfirmPreparing from 'contract-onboarding/hooks/wet-ink-confirm-preparing';

import {
  CompanyStatus,
  ComplianceContractor,
  Contract,
  ContractType,
  Member,
  useGetContractAgreementLazyQuery,
} from '__generated__/graphql';

import { useSendForSignature } from '../../../hooks';
import ContractModal, { AorDocumentType } from '../../contract-modal';
import AddSignatoryModal from '../add-signatory-modal';
import SendContractDisclaimer from '../send-contract-disclaimer';
import ShowSignatoryContent from '../show-signatory-content';
import SignMsaCallout from './sign-msa-callout';

export enum ContractPageType {
  MAIN_PAGE = 'main_page',
  REQUEST_CHANGE_PAGE = 'request_change_page',
}

const ViewSendContractAction: React.FC<{
  memberName: Member['firstName'];
  contractId: Contract['id'];
  memberEmail?: NonNullable<NonNullable<Member['emails']>[number]>['email'];
  uncommittedChanges?: boolean;
  contractBackdated?: boolean;
  contractBlocked?: boolean;
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
  contractType: Contract['type'];
}> = ({
  contractId,
  memberName,
  memberEmail,
  uncommittedChanges,
  contractBackdated,
  contractBlocked,
  toggleWorkflowUpdating,
  contractType,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const [showContract, closeContractModal, openContractModal] = useModal();
  const [
    contractModalPageType,
    setContractModalPageType,
  ] = useState<ContractPageType>(ContractPageType.MAIN_PAGE);
  const [requestChangeContent, setRequestChangeContent] = useState('');

  const [
    getContractAgreement,
    { data: contractAgreementResponse, loading: loadingContractAgreement },
  ] = useGetContractAgreementLazyQuery({ fetchPolicy: 'network-only' });

  const [
    showSignatoryModal,
    closeSignatoryModal,
    openSignatoryModal,
  ] = useModal();

  const {
    loading: loadingSendForSignature,
    handleSendForContractClick,
    handleAddSignatory,
    hasSignatories: hasSignatory,
    signatories,
  } = useSendForSignature({
    contractId,
    contractType,
    openSignatoryModal,
    closeSignatoryModal,
    closeContractModal,
  });

  const {
    loading: loadingWetinkConfirmPreparing,
    handleWetInkConfirmPreparing,
  } = useWetInkConfirmPreparing({
    contractId,
    closeContractModal,
  });

  const handleContractAgreementClick = () => {
    if (contractId) {
      getContractAgreement({
        variables: { id: contractId },
      });
      openContractModal();
    }
  };

  useEffect(() => {
    toggleWorkflowUpdating(
      loadingSendForSignature || loadingWetinkConfirmPreparing,
    );
  }, [loadingSendForSignature, loadingWetinkConfirmPreparing]);

  const company = signatories?.company;
  const signatory = company?.signatories?.[0];
  const allowSendingBasedOnCompanyStatus =
    company?.status === CompanyStatus.ACTIVE ||
    contractType !== ContractType.EMPLOYEE;

  return (
    <div tw="flex flex-col flex-1">
      <div tw="flex flex-col mb-24 gap-y-24 justify-start">
        <ShowSignatoryContent
          loadingSendForSignature={loadingSendForSignature}
          hasSignatory={hasSignatory}
          openSignatoryModal={openSignatoryModal}
          signatory={signatory}
        />
      </div>

      <div tw="flex flex-row gap-x-16 justify-between">
        <Button
          tw="flex-grow"
          size={!isNewThemeApplied ? 'medium' : 'large'}
          variant="outline"
          onClick={handleContractAgreementClick}
          disabled={
            loadingSendForSignature ||
            !hasSignatory ||
            !allowSendingBasedOnCompanyStatus
          }
        >
          {!contractBlocked
            ? contractType !== ContractType.CONTRACTOR
              ? t(
                  'onboarding-phase.optional-action.review-contract.button-text',
                  'Review Contract',
                )
              : t(
                  'onboarding-phase.optional-action.review-order-form.button-text',
                  'Review Order Form',
                )
            : t(
                'onboarding-phase.optional-action.blocked.review-contract.button-text',
                'View Contract',
              )}
        </Button>
        <ToolTip
          tw="flex-grow"
          variant="bottom"
          content={
            uncommittedChanges ? (
              <span>
                {t(
                  'onboarding-phase.primary-action.send-contract.tooltip',
                  'You have uncommitted changes below.',
                )}
              </span>
            ) : contractBackdated ? (
              <span>
                {t(
                  'onboarding-phase.primary-action.send-contract.backdated-tooltip',
                  'Employment start date cannot be backdated.',
                )}
              </span>
            ) : undefined
          }
        >
          <Button
            tw="w-full"
            size={!isNewThemeApplied ? 'medium' : 'large'}
            onClick={
              isWetInkSignatureEnabled() && isWetInkFlowEnabled()
                ? handleWetInkConfirmPreparing
                : handleSendForContractClick
            }
            loading={loadingSendForSignature || loadingWetinkConfirmPreparing}
            disabled={
              loadingSendForSignature ||
              uncommittedChanges ||
              contractBackdated ||
              contractBlocked ||
              !hasSignatory ||
              !allowSendingBasedOnCompanyStatus ||
              loadingWetinkConfirmPreparing
            }
            warning={uncommittedChanges || contractBackdated || undefined}
          >
            {contractType !== ContractType.CONTRACTOR
              ? t(
                  'onboarding-phase.primary-action.send-contract.button-text',
                  'Send Contract',
                )
              : t(
                  'onboarding-phase.primary-action.send-order-form.button-text',
                  'Send Order Form',
                )}
          </Button>
        </ToolTip>
      </div>

      {!allowSendingBasedOnCompanyStatus && <SignMsaCallout />}

      <ContractModal
        open={showContract}
        onClose={() => {
          setContractModalPageType(ContractPageType.MAIN_PAGE);
          setRequestChangeContent('');
          closeContractModal();
        }}
        handleCtaClick={
          isWetInkSignatureEnabled() && isWetInkFlowEnabled()
            ? handleWetInkConfirmPreparing
            : handleSendForContractClick
        }
        title={
          contractType === ContractType.CONTRACTOR
            ? t('onboarding-phase.order-form-viewer-header', 'Order Form')
            : t('onboarding-phase.contract-viewer-header', 'Contract')
        }
        header={<SendContractDisclaimer employeeName={memberName} />}
        footer={
          !contractBlocked ? (
            <RequestChangesAction
              contractId={contractId}
              setContractModalPageType={setContractModalPageType}
              contractBlocked={contractBlocked ?? false}
            />
          ) : undefined
        }
        loading={loadingContractAgreement || loadingSendForSignature}
        contractType={contractType}
        contractAgreement={
          contractAgreementResponse?.contract?.compliance?.contractAgreement
        }
        orderForm={
          contractType === ContractType.CONTRACTOR &&
          contractAgreementResponse?.contract?.compliance
            ? (contractAgreementResponse?.contract
                ?.compliance as ComplianceContractor)?.orderForm
            : null
        }
        esopAgreement={
          contractAgreementResponse?.contract?.compensation?.grant?.[0]
            ?.document
        }
        uncommittedChanges={uncommittedChanges}
        contractModalPageType={contractModalPageType}
        setContractModalPageType={setContractModalPageType}
        requestChangesPageContent={
          <RequestChangesForm
            contractId={contractId}
            setContractModalPageType={setContractModalPageType}
            requestChangeContent={requestChangeContent}
            setRequestChangeContent={setRequestChangeContent}
            onClose={() => {
              setContractModalPageType(ContractPageType.MAIN_PAGE);
              closeContractModal();
            }}
          />
        }
        contractBlocked={contractBlocked ?? false}
        contractBackdated={contractBackdated ?? false}
        aorDocumentType={AorDocumentType.ORDER_FORM}
      />
      <AddSignatoryModal
        open={showSignatoryModal}
        onClose={closeSignatoryModal}
        onSubmit={handleAddSignatory}
        loading={loadingSendForSignature}
        memberEmail={memberEmail}
      />
    </div>
  );
};

export default ViewSendContractAction;
