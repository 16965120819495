import React from 'react';

import { PillTag } from '@multiplier/common';
import { LegalDocument } from '@multiplier/common/src/__generated__/graphql';
import {
  differenceInWeeks,
  format,
  isEqual as isEqualDate,
  isValid,
} from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import objectHash from 'object-hash';

import { Experience } from 'app/models/module-config';
import { notEmpty } from 'app/utils/array';
import { BasicDetailsFormValues } from 'contract-onboarding/components/basic-details-section';
import { MemberContactDetailsFormValues } from 'contract-onboarding/components/contact-details-section';
import { UpdateBankDetailsParams } from 'contract-onboarding/member/pages/onboarding/pages/bank-details';
import { getBankStatementsForUpload } from 'contract-onboarding/member/services/bank-data';
import { getSectionVisibilityAndRules } from 'team/company/utils/util';

import {
  Address,
  BankAccount,
  EmailAddress,
  Gender,
  LegalData,
  Maybe,
  Member,
  MemberChangeCategory,
  MemberChangeItem,
  MemberChangeRequest,
  MemberChangeRequestStatus,
  MemberNationalogy,
  MemberPersonalDetailsSection,
  PhoneNumber,
  Scalars,
  SectionAccessibilityResponse,
} from '__generated__/graphql';

import countryIDD from '../../common/constants/country-idd';

export type UnionItem = {
  key: string;
  addressValue?: Maybe<Address>;
  emailValue?: Maybe<EmailAddress>;
  phoneValue?: Maybe<PhoneNumber>;
  strValue?: Maybe<string>;
  genderValue?: Maybe<Gender>;
  dateValue?: Maybe<Scalars['DateTime']>;
  nationalogyValue?: Maybe<MemberNationalogy>;
  bankAccountValue?: Maybe<BankAccount>;
  legalData?: Maybe<LegalData>[];
  legalDocuments?: Maybe<LegalDocument>[];
};

export type UnionKey = Exclude<
  | keyof MemberContactDetailsFormValues
  | keyof BasicDetailsFormValues
  | keyof UpdateBankDetailsParams,
  'documentsProof' | 'employeeId' | 'workEmail'
>;

const isLegalData = (
  item?: Maybe<MemberChangeItem>,
): item is { key?: Maybe<string>; legalValue: Maybe<LegalData> } =>
  item?.__typename === 'LegalDataParam';

export const getChangeRequestItemByKey = (
  key: UnionKey,
  changeRequest?: Maybe<MemberChangeRequest>,
): UnionItem | undefined => {
  const keyMap: Record<UnionKey, string> = {
    accountName: 'member.bankAccount',
    accountNumber: 'member.bankAccount',
    bankName: 'member.bankAccount',
    branchName: 'member.bankAccount',
    bankStatements: 'member.bankAccount',
    bankStatementDocIds: 'member.bankAccount',
    dateOfBirth: 'member.dateOfBirth',
    firstName: 'member.firstName',
    middleName: 'member.middleName',
    fullLegalName: 'member.fullLegalName',
    gender: 'member.gender',
    lastName: 'member.lastName',
    legalData: 'member.legalData',
    legalDocuments: 'member.legalDocument',
    localBankCode: 'member.bankAccount',
    nationality: 'member.nationality',
    swiftCode: 'member.bankAccount',
    address: 'member.address',
    email: 'member.email',
    phone: 'member.phone',
    bankData: 'member.bankAccount',
    paymentAccountRequirementType: 'member.bankAccount',
    version: 'member.bankAccount',
  };

  if (key === 'legalData') {
    return {
      key: keyMap.legalData,
      legalData: changeRequest?.items
        ?.filter(isLegalData)
        .map((item) => item.legalValue),
    };
  }

  return changeRequest?.items?.find((item) => item?.key === keyMap[key]) as
    | UnionItem
    | undefined;
};

export const haveSubmittedChangeRequestItem = (
  changeRequest?: Maybe<MemberChangeRequest>,
): boolean =>
  !!changeRequest &&
  changeRequest.status === MemberChangeRequestStatus.SUBMITTED &&
  !!changeRequest.items &&
  changeRequest.items.filter((item) => !!item).length > 0;

export const mapChangeRequestToRules = (
  key: MemberChangeCategory | undefined,
): string => {
  switch (key) {
    case MemberChangeCategory.BASIC_DETAILS:
      return 'basic-info';
    case MemberChangeCategory.BANK_DETAILS:
      return 'financial';
    case MemberChangeCategory.CONTACT_DETAILS:
      return 'contact';
    case MemberChangeCategory.IDENTIFICATION_DETAILS:
      return 'identification';
    default:
      return '';
  }
};

export const getCorrespondingPillTag = (
  changeRequest?: Maybe<MemberChangeRequest>,
  experience = Experience.MEMBER,
  sectionRules?:
    | Map<MemberPersonalDetailsSection, SectionAccessibilityResponse>
    | undefined,
  category?: MemberChangeCategory,
): React.ReactElement | null => {
  const [, , , changeRequestRules] = getSectionVisibilityAndRules(
    MemberPersonalDetailsSection.CHANGE_REQUESTS,
    sectionRules,
  );

  if (
    changeRequestRules.get(`${mapChangeRequestToRules(category)}.show`) &&
    !changeRequestRules.get(`${mapChangeRequestToRules(category)}.show`)
      ?.visible
  ) {
    return null;
  }

  if (
    !changeRequest ||
    changeRequest.status === MemberChangeRequestStatus.CANCELLED
  ) {
    return null;
  }

  if (
    changeRequest.status === MemberChangeRequestStatus.APPROVED &&
    !differenceInWeeks(new Date(), changeRequest.createdOn)
  ) {
    return <PillTag label="Approved" variant="SUCCESS" />;
  }

  if (changeRequest.status === MemberChangeRequestStatus.REJECTED) {
    return <PillTag label="Rejected" variant="FAIL" />;
  }

  if (changeRequest.status === MemberChangeRequestStatus.SUBMITTED) {
    return experience === Experience.COMPANY ? (
      <PillTag label="Changes requested" variant="PREPROCESSING" />
    ) : (
      <PillTag label="Verification in progress" variant="PROCESSING" />
    );
  }

  return null;
};

// Shallow equal with the ability to expand to deep equal
const isEqual = (
  first?: ObjectDataValue | ObjectDataValue[] | null,
  second?: ObjectDataValue | ObjectDataValue[] | null,
): ObjectDataCompareResult => {
  if (typeof first !== typeof second) {
    return ObjectDataCompareResult.NOT_EQUAL;
  }
  // Now first and second share same type

  if (first === second) {
    return ObjectDataCompareResult.EQUAL;
  }
  // Now first and second is not equal unless they are objects

  if (first instanceof Date && second instanceof Date) {
    return isEqualDate(first, second)
      ? ObjectDataCompareResult.EQUAL
      : ObjectDataCompareResult.NOT_EQUAL;
  }
  // Now first and second are not Date objects, but they can either be null, or list

  if (first === null || second === null)
    return ObjectDataCompareResult.NOT_EQUAL;
  // Now first and second are not null, but they can be lists

  if (
    (Array.isArray(first) && !Array.isArray(second)) ||
    (!Array.isArray(first) && Array.isArray(second))
  )
    return ObjectDataCompareResult.NOT_EQUAL;

  if (Array.isArray(first)) return ObjectDataCompareResult.IS_ARRAY;
  // Now first and second are not list

  if (typeof first === 'object') return ObjectDataCompareResult.IS_OBJECT;
  // Now first and second are not objects

  return ObjectDataCompareResult.NOT_EQUAL;
};

const getListDataDifference = (
  first?: ObjectDataValue[] | null,
  second?: ObjectDataValue[] | null,
): ObjectDataValue[] => {
  if (!second) return [];
  if (!first) return second;

  const initialValues = new Set();
  first.forEach((item) => initialValues.add(objectHash(item)));

  return second.filter((item) => !initialValues.has(objectHash(item)));
};

const cleanEmptyObject = (objectData: ObjectData): ObjectData => {
  const result = {} as ObjectData;

  Object.entries(objectData).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length === 0) return;
    if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof Date) &&
      !Array.isArray(value)
    ) {
      const cleanedObject = cleanEmptyObject(value);
      if (Object.keys(cleanedObject).length === 0) return;
      result[key] = cleanedObject;
      return;
    }
    result[key] = value;
  });

  return result;
};

export const getObjectDataDifferences = (
  origin: ObjectData,
  updatedValue: ObjectData,
): ObjectData => {
  const dirtyResult = Object.keys(updatedValue).reduce((prev, key) => {
    const result = isEqual(origin?.[key], updatedValue?.[key]);

    switch (result) {
      case ObjectDataCompareResult.IS_OBJECT:
        prev[key] = getObjectDataDifferences(
          origin[key] as ObjectData,
          updatedValue[key] as ObjectData,
        );
        return prev;
      case ObjectDataCompareResult.NOT_EQUAL:
        prev[key] = updatedValue[key];
        return prev;
      case ObjectDataCompareResult.IS_ARRAY:
        prev[key] = getListDataDifference(
          origin?.[key] as ObjectDataValue[],
          updatedValue?.[key] as ObjectDataValue[],
        );
        return prev;
      default:
        return prev;
    }
  }, {} as ObjectData);

  return cleanEmptyObject(dirtyResult);
};

type ObjectDataValue = string | number | Date | ObjectData;

type ObjectData = {
  [K: string]: ObjectDataValue | ObjectDataValue[] | null | undefined;
};

enum ObjectDataCompareResult {
  NOT_EQUAL,
  EQUAL,
  IS_OBJECT,
  IS_ARRAY,
}

type ChangeRequestVariables = Record<
  string,
  ObjectData | File[] | string[] | undefined
>;

export const transformToChangeRequests = (
  member?: Maybe<Member>,
  values?:
    | Partial<BasicDetailsFormValues>
    | Partial<UpdateBankDetailsParams>
    | Partial<MemberContactDetailsFormValues>,
  type?: MemberChangeCategory,
): ChangeRequestVariables => {
  if (!values || !member) return {};

  switch (type) {
    case MemberChangeCategory.CONTACT_DETAILS:
      return constructContactDetailsChangeRequest(
        member,
        values as MemberContactDetailsFormValues,
      );
    case MemberChangeCategory.BANK_DETAILS:
      return constructBankDetailsChangeRequest(
        member,
        values as UpdateBankDetailsParams,
      );
    default:
      return constructBasicDetailsChangeRequest(
        member,
        values as BasicDetailsFormValues,
      );
  }
};

const isRecord = (o: unknown): o is Record<string, unknown> =>
  !!o && !Array.isArray(o) && typeof o === 'object';

const updateObjectValues = (
  origin: ObjectData,
  values: ObjectData,
): ObjectData => {
  const differences = getObjectDataDifferences(origin, values);

  const mergeObjectValueForEachKey = (key: string) => {
    if (!(key in origin)) return;
    const originField = origin[key];
    const updatedField = differences[key];
    if (isRecord(originField) && isRecord(updatedField)) {
      differences[key] = {
        ...originField,
        ...updatedField,
      };
    }
  };
  Object.keys(differences).forEach(mergeObjectValueForEachKey);
  return differences;
};

const constructBasicDetailsChangeRequest = (
  member: Member,
  values: BasicDetailsFormValues,
): ChangeRequestVariables => {
  const currentMemberData = {
    firstName: member.firstName,
    middleName: member.middleName,
    lastname: member.lastName,
    gender: member.gender,
    fullLegalName: member.fullLegalName,
    dateOfBirth: member.dateOfBirth,
    nationality: {
      type: member.nationalities?.[0]?.type,
      country: member.nationalities?.[0]?.country,
    },
    legalData: member?.legalData?.filter(notEmpty)?.map((field) => ({
      key: field.key,
      value: field.value,
      identifier: field.identifier,
    })),
  };
  const updatedMemberData = {
    firstName: values.firstName,
    middleName: values.middleName,
    lastname: values.lastName,
    gender: values.gender,
    fullLegalName: values.fullLegalName,
    dateOfBirth:
      values.dateOfBirth && isValid(values.dateOfBirth)
        ? `${format(values.dateOfBirth, 'yyyy-MM-dd')}T00:00:00`
        : member?.dateOfBirth,
    nationality: {
      type: member?.nationalities?.[0]?.type,
      country: member?.nationalities?.[0]?.country,
    },
    legalData: values.legalData?.map((field) => ({
      identifier: field.description === '' ? null : field.description,
      key: field.key,
      value: field.value,
    })),
  };

  return {
    ...updateObjectValues(currentMemberData, updatedMemberData),
    documents: values?.documentsProof,
  };
};

const constructContactDetailsChangeRequest = (
  member: Member,
  values: MemberContactDetailsFormValues,
): ChangeRequestVariables => {
  const currentMemberData = {
    address: {
      street: member.addresses?.[0]?.street,
      line1: member.addresses?.[0]?.line1,
      line2: member.addresses?.[0]?.line2,
      city: member.addresses?.[0]?.city,
      state: member.addresses?.[0]?.state,
      province: member.addresses?.[0]?.province,
      country: member.addresses?.[0]?.country,
      zipcode: member.addresses?.[0]?.zipcode,
      postalCode: member.addresses?.[0]?.postalCode,
    },
    email: {
      name: member.emails?.[0]?.type,
      value: member.emails?.[0]?.email,
    },
    phone: {
      name: member?.phoneNos?.[0]?.type,
      value: member?.phoneNos?.[0]?.phoneNo,
    },
  };
  const updatedMemberData = {
    address: values.address,
    phone: {
      name: 'primary',
      value: `${
        values.phone.countryCode ? countryIDD[values.phone.countryCode] : ''
      } ${values.phone.number}`,
    },
    email: { name: 'primary', value: values.email },
  };
  return {
    ...updateObjectValues(currentMemberData, updatedMemberData),
    documents: values?.documentsProof,
  };
};

const constructBankDetailsChangeRequest = (
  member: Member,
  values: UpdateBankDetailsParams,
): ChangeRequestVariables => {
  const currentMemberData = {
    bankAccount: {
      accountName: member?.bankAccounts?.[0]?.accountName,
      accountNumber: member?.bankAccounts?.[0]?.accountNumber,
      bankName: member?.bankAccounts?.[0]?.bankName,
      branchName: member?.bankAccounts?.[0]?.branchName,
      localBankCode: member?.bankAccounts?.[0]?.localBankCode,
      swiftCode: member?.bankAccounts?.[0]?.swiftCode,
    },
  };

  const updatedMemberData = {
    bankAccount: {
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      bankName: values.bankName,
      branchName: values.branchName,
      localBankCode: values.localBankCode,
      swiftCode: values.swiftCode,
    },
  };

  const { existingDocIds, newDocs } = getBankStatementsForUpload(
    values.bankStatements,
  );

  const updatedBankAccountValues = updateObjectValues(
    currentMemberData,
    updatedMemberData,
  );

  let changeRequestPayload = {};

  if (isEmpty(updatedBankAccountValues)) {
    changeRequestPayload = {
      ...changeRequestPayload,
      ...currentMemberData,
    };
  } else {
    changeRequestPayload = {
      ...changeRequestPayload,
      ...updatedBankAccountValues,
    };
  }

  changeRequestPayload = {
    ...changeRequestPayload,
    documents: newDocs,
    documentIds: existingDocIds,
  };

  return changeRequestPayload;
};
