/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { RateFrequency } from '__generated__/graphql';

interface DateNoteFreelancerSemimonthlyProps {
  paymentFrequency: RateFrequency;
  firstPayoutDate?: string;
}

const DateNoteSemimonthlyLastTwoDates: React.FC<DateNoteFreelancerSemimonthlyProps> = ({
  paymentFrequency,
  firstPayoutDate,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const isLastTwoDatesOftheMonth = useMemo(
    () =>
      paymentFrequency === RateFrequency.SEMIMONTHLY &&
      (firstPayoutDate === '14' || firstPayoutDate === '15'),
    [firstPayoutDate],
  );

  if (
    paymentFrequency !== RateFrequency.SEMIMONTHLY ||
    !isLastTwoDatesOftheMonth
  ) {
    return null;
  }
  return (
    <p
      css={[
        tw`text-ps font-normal text-text-secondary mt-small mb-extra-small whitespace-pre-wrap`,
        !isNewThemeApplied && tw`text-grey01`,
      ]}
    >
      {t(
        'definition-phase.compensation.payout-date-notice',
        'If month is February, second payout date will be last date of month ',
      )}
    </p>
  );
};

export default DateNoteSemimonthlyLastTwoDates;
