import { isValid } from 'date-fns';
import { TFunction } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';

import { fileSchema } from 'app/utils/file-schema-utils';
import i18nextUtil from 'app/utils/i18next-util';

import {
  AllowedValue,
  Contract,
  ContractType,
  FileLink,
  Maybe,
  PaymentAccountRequirement,
  RequirementField,
  RequirementSubField,
} from '__generated__/graphql';

export const getBankFieldSchema = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  requirement?: Maybe<RequirementField>,
): yup.StringSchema<string | null | undefined> | yup.NumberSchema => {
  if (requirement?.type === 'text') {
    return yup
      .string()
      .nullable()
      .test(
        'is-required',
        t(
          'bank-account-schema.text.error-message.required',
          'Input is required',
        ),
        (val) => (requirement?.isMandatory ? !!val : true),
      )
      .test(
        'wrong-format',
        t(
          'bank-account-schema.text.error-message.wrong-format',
          'Input format is wrong',
        ),
        (val) => {
          if (!requirement?.isMandatory && !val) {
            return true;
          }
          return new RegExp(requirement?.regex ?? '').test(val ?? '');
        },
      );
  }
  // Commented out temporarily
  // if (requirement?.type === 'radio') {
  //   return yup
  //     .string()
  //     .required()
  //     .oneOf(
  //       Object.values(requirement?.allowedValues ?? '').map(
  //         (val: AllowedValue) => val.value,
  //       ),
  //     );
  // }
  if (requirement?.type === 'select' || requirement?.type === 'radio') {
    if (requirement?.isMandatory) {
      return yup
        .string()
        .oneOf(
          Object.values(requirement?.allowedValues ?? '').map(
            (val: AllowedValue) => val.value,
          ),
          t(
            'bank-account-schema.select.error-message.wrong-value',
            'Select a valid dropdown value',
          ),
        )
        .required(
          t(
            'bank-account-schema.select.error-message.required',
            'Input is required',
          ),
        );
    }
    return yup
      .string()
      .oneOf(
        Object.values(requirement?.allowedValues ?? '').map(
          (val: AllowedValue) => val.value,
        ),
      )
      .notRequired();
  }
  if (requirement?.type === 'date') {
    return yup
      .string()
      .nullable()
      .test(
        'is-required',
        t(
          'bank-account-schema.date.error-message.required',
          'Input is required',
        ),
        (val) => (requirement?.isMandatory ? !!val : true),
      )
      .test(
        'wrong-format',
        t(
          'bank-account-schema.date.error-message.wrong-format',
          'Input format is wrong',
        ),
        (val) => {
          if (!requirement?.isMandatory && !val) {
            return true;
          }
          return !!val && isValid(new Date(val));
        },
      );
  }
  return yup.string().nullable();
};

export const getDynamicBankDetailsSchema = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  contractType: Contract['type'],
  contractCountryCode: Contract['country'],
): yup.ObjectSchema<{
  bankStatements: Lazy<
    | yup.ArraySchema<yup.InferType<typeof fileSchema>>
    | yup.InferType<typeof fileSchema>
  >;
}> =>
  yup.object().shape({
    bankStatements: yup.lazy(() => {
      if (contractType === ContractType.HR_MEMBER)
        return yup.mixed().notRequired();

      return yup
        .array()
        .of(
          yup.object().shape({
            statement: fileSchema,
          }),
        )
        .min(
          1,
          t(
            i18nextUtil.buildTransKeys(
              'validation.required',
              'bank-details.proof-documents',
              i18nextUtil.asSegment(contractCountryCode),
            ),
            'Bank Statement / Cancelled Cheque is required',
            { ns: 'contract-onboarding.common' },
          ),
        )
        .required();
    }),
  });

const isUploadedBankStatement = (statement: File) =>
  'id' in statement && (statement as FileLink)?.__typename === 'FileLink';

export const getBankStatementsForUpload = (
  bankStatements: File[] | undefined,
): { existingDocIds?: string[]; newDocs?: File[] } => {
  if (bankStatements && isEmpty(bankStatements))
    return {
      existingDocIds: [],
      newDocs: [],
    };

  // existing docs, BE needs to be sent only Ids so that upload does not happen again
  const existingUploadedBankStatements = bankStatements
    ?.filter((statement) => isUploadedBankStatement(statement))
    .map((statement) => (statement as FileLink).id as string);

  // new docs selected by user which needs to be uploaded
  const newBankStatementsForUpload = bankStatements?.filter(
    (statement) => !isUploadedBankStatement(statement),
  );

  return {
    existingDocIds: existingUploadedBankStatements ?? [],
    newDocs: newBankStatementsForUpload ?? [],
  };
};

export type BankDynamicDetailFieldRequirement = {
  key: RequirementField['key'];
  parentField?: RequirementField['key'];
  targetValue?: string | number | Date | null;
  isMandatory: RequirementField['isMandatory'];
  regex?: RequirementField['regex'];
  label: RequirementField['label'];
  type: RequirementField['type'];
  allowedValues?: RequirementField['allowedValues'];
  errorMessage?: RequirementField['errorMessage'];
};

export type BankDynamicDetailField = {
  key: string;
  requirements: {
    default?: BankDynamicDetailFieldRequirement;
    subFields?: BankDynamicDetailFieldRequirement[];
  };
};

const addSubFieldsToMap = ({
  parentFieldKey,
  fields,
}: {
  fields?: Maybe<Maybe<RequirementSubField>[]>;
  parentFieldKey?: Maybe<string>;
}) => {
  const map = new Map() as Map<string, BankDynamicDetailField>;
  fields?.forEach((subFields) => {
    subFields?.value?.forEach((subField) => {
      const subFieldDefinition = {
        key: subField?.key ?? '',
        parentField: parentFieldKey,
        targetValue: subFields?.key,
        isMandatory: subField?.isMandatory,
        regex: subField?.regex,
        label: subField?.label,
        type: subField?.type,
        allowedValues: subField?.allowedValues,
        errorMessage: subField?.errorMessage,
      };

      if (
        map.has(subField?.key ?? '') &&
        map.get(subField?.key ?? '')?.requirements?.subFields?.length
      )
        map
          .get(subField?.key ?? '')
          ?.requirements?.subFields?.push(subFieldDefinition);
      else
        map.set(subField?.key ?? '', {
          key: subField?.key ?? '',
          requirements: {
            default: map.get(subField?.key ?? '')?.requirements?.default,
            subFields: [subFieldDefinition],
          },
        });

      if (subField?.hasSubFields) {
        const subFieldMap = addSubFieldsToMap({
          parentFieldKey: subField?.key,
          fields: subField?.subFields,
        });
        Array.from(subFieldMap.entries()).forEach((entry) => {
          if (
            map.has(entry[0] ?? '') &&
            map.get(entry[0] ?? '')?.requirements?.subFields?.length
          )
            map
              .get(entry[0] ?? '')
              ?.requirements?.subFields?.concat(
                entry[1]?.requirements.subFields ?? [],
              );
          else
            map.set(entry[0] ?? '', {
              key: entry[0] ?? '',
              requirements: {
                default: map.get(entry[0] ?? '')?.requirements?.default,
                subFields: entry[1]?.requirements.subFields,
              },
            });
        });
      }
    });
  });
  return map;
};

export const getDynamicFields = (
  fields: PaymentAccountRequirement['requirementFields'],
): Map<string, BankDynamicDetailField> | undefined =>
  (fields ?? [])
    .filter((field: Maybe<RequirementField>) => field?.key !== 'legalType')
    ?.reduce((map, field) => {
      const fieldDefinition = {
        key: field?.key ?? '',
        isMandatory: field?.isMandatory ?? false,
        regex: field?.regex,
        label: field?.label,
        type: field?.type,
        allowedValues: field?.allowedValues,
        errorMessage: field?.errorMessage,
      };

      map.set(field?.key ?? '', {
        key: field?.key ?? '',
        requirements: {
          default: fieldDefinition,
          subFields: map.get(field?.key ?? '')?.requirements?.subFields,
        },
      });
      if (field?.hasSubFields) {
        const subFieldMap = addSubFieldsToMap({
          parentFieldKey: field?.key,
          fields: field.subFields,
        });

        Array.from(subFieldMap.entries()).forEach((entry) => {
          if (
            map.has(entry[0] ?? '') &&
            map.get(entry[0] ?? '')?.requirements?.subFields?.length
          )
            map
              .get(entry[0] ?? '')
              ?.requirements?.subFields?.concat(
                entry[1]?.requirements.subFields ?? [],
              );
          else
            map.set(entry[0] ?? '', {
              key: entry[0] ?? '',
              requirements: {
                default: map.get(entry[0] ?? '')?.requirements?.default,
                subFields: entry[1]?.requirements.subFields,
              },
            });
        });
      }
      return map;
    }, new Map() as Map<string, BankDynamicDetailField>);
