/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, PillTag } from '@multiplier/common';
import { theme } from 'twin.macro';

import TableCell from 'common/components/table-cell';
import { contractTypeLabels } from 'common/constants/contract-labels';

import { ContractType, CountryCode, Maybe } from '__generated__/graphql';

export enum ContractTypeVariant {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

const useGetDefaultLabel = (contractType?: Maybe<ContractType>): string => {
  const { t } = useTranslation('contract-onboarding.common');

  if (!contractType) {
    return '';
  }
  return t('onboarding-header.common.add-placeholder', {
    defaultValue: 'Add {{contractTypeLabel}}',
    replace: {
      contractTypeLabel: contractTypeLabels[contractType],
    },
    interpolation: {
      escapeValue: false,
    },
  });
};

const OnboardingHeader: React.FC<{
  id?: Maybe<string>;
  country?: Maybe<CountryCode>;
  position?: Maybe<string>;
  contractType?: Maybe<ContractType>;
  contractTypeVariant?: ContractTypeVariant;
  additionalContent?: React.ReactElement;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
}> = ({
  id,
  country,
  position,
  contractType,
  contractTypeVariant = ContractTypeVariant.RIGHT,
  additionalContent,
  firstName,
  lastName,
  ...props
}) => {
  const defaultLabel = useGetDefaultLabel(contractType);

  return (
    <div tw="flex justify-between items-center">
      <h4
        tw="text-h5 tablet:text-h4 desktop:text-h4 font-semibold text-left text-text-inverted-primary"
        {...props}
      >
        {id ? (
          <div tw="flex items-center gap-x-small">
            {country && <TableCell.Country country={country} variant="dark" />}
            <div tw="flex flex-col gap-y-tiny">
              <div tw="flex items-center">
                <span tw="mr-small">{`${firstName} ${lastName}`}</span>
                {contractTypeVariant === ContractTypeVariant.CENTER &&
                  contractType &&
                  contractTypeLabels[contractType] && (
                    <PillTag
                      variant="PREPROCESSING"
                      label={contractTypeLabels[contractType]}
                    />
                  )}
              </div>
              <div tw="flex items-center">
                {contractTypeVariant === ContractTypeVariant.LEFT &&
                  contractType &&
                  contractTypeLabels[contractType] && (
                    <>
                      <Icon
                        name="businessman"
                        fill={theme`colors.white`}
                        height="14"
                        width="14"
                        tw="mr-tiny"
                      />
                      <span tw="text-p font-normal ml-tiny">
                        {contractTypeLabels[contractType]}&nbsp;&bull;&nbsp;
                      </span>
                    </>
                  )}
                {position && <span tw="text-p font-normal">{position}</span>}
              </div>
            </div>
          </div>
        ) : (
          defaultLabel
        )}
      </h4>
      {contractTypeVariant === ContractTypeVariant.RIGHT &&
        contractType &&
        contractTypeLabels[contractType] && (
          <PillTag
            variant="PREPROCESSING"
            label={contractTypeLabels[contractType]}
          />
        )}
      {additionalContent}
    </div>
  );
};

export default OnboardingHeader;
