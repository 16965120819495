/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import 'twin.macro';

import { ReactComponent as CompleteTick } from '@multiplier/assets/files/contract-onboarding/complete-tick.svg';
import { Loader, SpinnerType } from '@multiplier/common';

import OnboardingHeader, {
  ContractTypeVariant,
} from 'contract-onboarding/components/onboarding-header';
import SignOutButton from 'contract-onboarding/member/components/sign-out-button';
import SwitchViewButton from 'contract-onboarding/member/components/switch-view-button';

import {
  ContractOnboardingStep,
  LegalDocument,
  Member,
  useGetMemberDetailsForOnboardingQuery,
} from '__generated__/graphql';

import ContactSupport from './pages/visa-eligibility-documents/components/contact-support';

const Message: React.FC<{
  member: Member;
}> = ({ member }) => {
  const { t } = useTranslation('contract-onboarding.member');

  const submittedVisaPrimaryDocuments = useMemo(
    () => member.legalDocuments?.filter((doc) => doc?.files?.length) ?? [],
    [member.legalDocuments],
  ) as LegalDocument[];

  const currentStep = React.useMemo(
    () => member?.contracts?.[0]?.onboarding?.current,
    [member],
  );

  switch (currentStep) {
    case ContractOnboardingStep.MEMBER_BASIC_DETAILS:
    case ContractOnboardingStep.MEMBER_VISA_APPLICATION:
      return (
        <div tw="mx-auto width[740px] mt-24 bg-white rounded-10 p-24">
          <div tw="flex flex-row pb-24 border-b border-grey04">
            <CompleteTick />
            <div tw="flex flex-col ml-16">
              <span tw="text-[24px] font-semibold text-slateGrey900">
                {t(
                  'visa-block-page.header',
                  'Documents submitted successfully',
                )}
              </span>
              <span tw="text-ps font-normal text-slateGrey900">
                {t(
                  'visa-block-page.description',
                  'Your eligibility documents are under verification, We will reach out to you for any clarifications if required',
                )}
              </span>
            </div>
          </div>

          <div tw="mt-16">
            <span tw="text-p font-semibold text-slateGrey900">
              {t('visa-block-page.submitted-documents', 'Submitted documents')}
            </span>
            <ul tw="flex flex-col text-ps text-neutral500 list-disc ml-12">
              <li key="phone-number">
                {t('member.basic-details.phone.label', 'Phone Number')}
              </li>
              {submittedVisaPrimaryDocuments.map((doc) => (
                <li key={doc.key}>
                  {t(`legal-documents.${doc.label}`, doc.label || '')}
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    case ContractOnboardingStep.MEMBER_VISA_APPROVED:
      return (
        <div tw="mx-auto width[740px] mt-24 bg-white rounded-10 p-24">
          <div tw="flex flex-row">
            <CompleteTick />
            <div tw="flex flex-col ml-16">
              <span tw="text-[24px] font-semibold text-slateGrey900">
                {t('kyc-block-page.header', 'KYC done successfully')}
              </span>
              <span tw="text-ps font-normal text-slateGrey900">
                {t(
                  'kyc-block-page.description',
                  'We are filing your visa application. We will reach out to you for any clarifications if required ',
                )}
              </span>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div tw="mx-auto width[740px] mt-24 bg-white rounded-10 p-24">
          <div tw="flex flex-row">
            <div tw="flex flex-col ml-16">
              <span tw="text-ps font-normal text-slateGrey900">
                {t(
                  'message.member-visa-default',
                  'Please wait for our staffs to contact and provide guideline for next steps',
                )}
              </span>
            </div>
          </div>
        </div>
      );
  }
};

const BlockPage: React.FC = () => {
  const { data, loading } = useGetMemberDetailsForOnboardingQuery();

  const member = data?.member;
  const contract = member?.contracts?.[0];

  if (member == null || loading)
    return <Loader.Spinner variant={SpinnerType.EXPERIENCE} />;

  return (
    <div tw="flex justify-center min-h-screen h-full">
      <div tw="w-full">
        <div tw="flex h-[152px] bg-background text-center">
          <div
            tw="flex flex-col gap-y-36 items-stretch width[740px] ml-auto mr-auto justify-center"
            data-testid="onboarding-header"
          >
            <OnboardingHeader
              id={contract?.id}
              country={contract?.country}
              position={contract?.position}
              contractType={contract?.type}
              contractTypeVariant={ContractTypeVariant.CENTER}
              firstName={member?.firstName}
              lastName={member?.lastName}
              additionalContent={
                <>
                  <SwitchViewButton />
                  <SignOutButton />
                </>
              }
            />
          </div>
        </div>
        <Message member={member} />
        <ContactSupport />
      </div>
    </div>
  );
};

export default BlockPage;
