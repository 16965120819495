import {
  CountryPaymentFrequency,
  CountryPaymentFrequencyData,
  Maybe,
} from '__generated__/graphql';

export default (
  paymentFrequencyData: CountryPaymentFrequency['data'],
  basePayFrequency?: string,
): Maybe<CountryPaymentFrequencyData> | undefined =>
  paymentFrequencyData?.find(
    (paymentFrequencyDataItem) =>
      paymentFrequencyDataItem?.rateFrequency === basePayFrequency,
  );
