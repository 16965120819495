/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, FieldPath, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, Radio } from '@multiplier/common';
import tw from 'twin.macro';

import TextInput from 'common/components/text-input';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';
import { useIsNewAllowancesConfigEnabled } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Contract,
  CountryPaymentFrequency,
  FixedPayComponent,
  Maybe,
  PayFrequency,
} from '__generated__/graphql';

import { payFrequencyLabel } from '../../../../common/constants/default-labels';

interface PayrollFrequencyProps {
  fieldPath: FieldPath<CompensationFormValues>;
  payComponent: FixedPayComponent;
  contractType?: Contract['type'];
  countryPaymentFrequency?: Maybe<CountryPaymentFrequency>;
  inline?: boolean;
}

const PayrollFrequency: React.FC<PayrollFrequencyProps> = ({
  fieldPath,
  payComponent,
  contractType,
  countryPaymentFrequency,
  inline = false,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: contractType,
  });

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  if (
    isNewAllowancesConfigEnabled &&
    countryPaymentFrequency?.list &&
    countryPaymentFrequency?.list?.length < 2
  )
    return <div tw="mb-[-24px]" />;

  return (
    <TextInput.Container
      data-testid="payroll-frequency"
      css={[inline && tw`grid grid-cols-3 items-center`]}
    >
      <TextInput.Label
        htmlFor="payroll-frequency-select"
        css={[tw`font-semibold`, inline && tw`mb-0`]}
      >
        {t(
          `compensation.${payComponent.label}.payroll-frequency.label`,
          'Payroll frequency',
        )}
      </TextInput.Label>

      {isNewAllowancesConfigEnabled && (
        <div css={[tw`grid grid-cols-2 gap-x-16`, inline && tw`col-span-2`]}>
          {countryPaymentFrequency?.list?.map(
            (currFrequency) =>
              currFrequency && (
                <Radio
                  key={currFrequency}
                  aria-label={currFrequency.toLowerCase()}
                  value={currFrequency}
                  {...register(
                    `${fieldPath}.paymentFrequency` as FieldPath<CompensationFormValues>,
                  )}
                  defaultChecked={
                    currFrequency === countryPaymentFrequency?.defaultValue &&
                    !watch(`${fieldPath}.paymentFrequency`)
                  }
                >
                  <span tw="text-background">
                    {payFrequencyLabel[currFrequency]}
                  </span>
                </Radio>
              ),
          )}
        </div>
      )}
      {!isNewAllowancesConfigEnabled && (
        <Controller
          name={
            `${fieldPath}.paymentFrequency` as FieldPath<CompensationFormValues>
          }
          control={control}
          render={({ field: { value, onChange } }) => (
            <ComboBox
              id="payroll-frequency-select"
              tw="w-1/2"
              variant="default"
              value={value as string}
              placeholder={t(
                `compensation.${payComponent.label}.payroll-frequency.placeholder`,
                'Select Payroll frequency',
              )}
              dropdownValues={Object.values([
                PayFrequency.BIWEEKLY,
                PayFrequency.MONTHLY,
              ]).map((frequency) => ({
                title: payFrequencyLabel[frequency],
                value: frequency,
              }))}
              onChange={onChange}
            />
          )}
        />
      )}

      {errors.payrollConfigId && (
        <TextInput.Error>{errors.payrollConfigId.message}</TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default PayrollFrequency;
