import { gql } from '@apollo/client';

export default gql`
  query GetMultipleCompanies($id: ID, $userID: ID) {
    companyGroup(id: $id, userId: $userID) {
      id
      name
      children {
        id
        displayName
        companyLogo {
          id
          name
          extension
          contentType
          ... on DocumentReadable {
            link
          }
        }
      }
    }
  }
`;
