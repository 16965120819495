import React, { useContext } from 'react';

import tw from 'twin.macro';

import { ThemeContext } from '../theme-provider';
import { PillVariant } from '../types';

const variantStyles = {
  PRIMARY: [tw`bg-background-action text-text-inverted-primary`],
  SECONDARY: [tw`bg-background-tertiary text-text-inverted-primary`],
  SUCCESS: [tw`bg-background-positive-subtle text-text-positive`],
  PROCESSING: [tw`bg-background-primary text-text-primary`],
  PREPROCESSING: [tw`bg-background-brand-subtle text-text-secondary`],
  FAIL: [tw`bg-background-negative-subtle text-text-negative`],
  WARNING: [tw`bg-background-warning-subtle text-background-warning`],
  NEUTRAL: [tw`bg-background-primary text-text-secondary`],
};

const PillTag: React.FC<{
  label: React.ReactElement | string;
  variant: PillVariant;
}> = ({ label, variant, ...props }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div
      data-testid="pill"
      css={[
        // TODO: rebrand -- remove font-normal & set font-semibold as default when theme Multiplier 2.* is fully implemented
        tw`flex items-center w-max rounded-large bg-opacity-15 px-small py-2 text-ps whitespace-nowrap font-normal`,
        isNewThemeApplied && tw`font-semibold`,
        variantStyles[variant],
      ]}
      {...props}
    >
      {label}
    </div>
  );
};

export default PillTag;
