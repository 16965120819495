import React from 'react';

import 'twin.macro';

import ComboBox from '../../../../../combo-box';
import { TableFilterConfigValue } from '../../../../types';
import Label from '../label';
import ShortenMultiValues from '../shorten-multi-values';

const MultiDropdownFilterInput: React.FC<{
  values: string[];
  onChange: (val: string[]) => void;
  filterConfig: TableFilterConfigValue;
}> = ({ values, onChange, filterConfig }) => {
  const options = (filterConfig?.options ?? []).filter(
    (item) => !values?.includes(item?.value),
  );

  return (
    <>
      {filterConfig?.prefix && <Label>{filterConfig?.prefix}</Label>}
      <div
        tw="rounded-tiny border border-border-primary p-6 flex flex-row flex-wrap items-center gap-6 mt-extra-small"
        data-testid="multiple-filter-input"
      >
        <ShortenMultiValues
          values={values}
          onRemove={(val) => onChange(values.filter((v) => v !== val))}
        />

        <ComboBox
          tw="flex-grow"
          placeholder={filterConfig?.placeholder ?? ''}
          dropdownValues={options}
          onChange={(value) => {
            if (values && !values.includes(value)) onChange([...values, value]);
            if (!values) onChange([value]);
          }}
          variant="inline-text"
          value=""
        />
      </div>
    </>
  );
};

export default MultiDropdownFilterInput;
