/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import IncludeDependantsBenefit from 'contract-onboarding/company/components/include-dependants-benefit';
import { getIncludeDependents } from 'contract-onboarding/company/services/benefits';

import {
  BenefitCostingType,
  BenefitPartnerBillingFrequency,
  ContractBenefit,
  CurrencyCode,
  Maybe,
  RateFrequency,
} from '__generated__/graphql';

const DependentsSection: React.FC<{
  benefits: Maybe<Maybe<ContractBenefit>[]> | undefined;
  isFormEditing?: boolean;
  billingDuration: BenefitPartnerBillingFrequency | null | undefined;
  frequency: RateFrequency | null | undefined;
  costingType?: Maybe<BenefitCostingType> | undefined;
  cost?: Maybe<number> | undefined;
  currency?: Maybe<CurrencyCode> | undefined;
}> = ({
  benefits,
  isFormEditing = false,
  billingDuration,
  frequency,
  costingType,
  cost,
  currency,
}) => {
  if (!getIncludeDependents(benefits) && !isFormEditing) return null;
  return (
    <div>
      <IncludeDependantsBenefit
        amount={cost}
        currency={currency}
        edit={isFormEditing}
        costingType={costingType}
        billingDuration={billingDuration}
        frequency={frequency}
      />
    </div>
  );
};

export default DependentsSection;
