import React, { useContext } from 'react';

import tw, { TwStyle } from 'twin.macro';

import Card from '../card';
import { ThemeContext } from '../theme-provider';

export { default as WidgetSplash } from './components/widget-splash';
interface EmptySplashProps {
  splash: React.ReactNode;
  header?: string;
  description?: string;
  descriptionStyle?: TwStyle;
  cta?: React.ReactNode;
}

const EmptySplash: React.FC<EmptySplashProps> = ({
  splash,
  header,
  description,
  cta,
  descriptionStyle,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <Card data-testid="empty-splash" tw="p-extra-large" {...props}>
      <div tw="py-large w-full flex flex-col justify-center items-center">
        <div tw="mb-extra-large">{splash}</div>

        {header ? (
          <h4
            css={[
              tw`text-text-primary font-semibold h-auto mb-small`,
              tw`text-h4 mobile:text-h5`,
            ]}
          >
            {header}
          </h4>
        ) : null}

        <p
          css={[
            tw`text-text-tertiary text-center mobile:text-ps`,
            !isNewThemeApplied && tw`font-light`,
            descriptionStyle,
          ]}
        >
          {description}
        </p>

        {cta && <div tw="my-large">{cta}</div>}
      </div>
    </Card>
  );
};
export default EmptySplash;
