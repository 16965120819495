/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import 'twin.macro';

import { Contract, ContractType } from '__generated__/graphql';

interface PayoutDateNoteProps {
  contractType: Contract['type'];
}

const PayoutDateNote: React.FC<PayoutDateNoteProps> = ({ contractType }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const payoutDateNoteOne =
    contractType === ContractType.CONTRACTOR
      ? t(
          'definition-phase.compensation.payout-date-note-one-contractor',
          'Payout date to contractor is subject to invoice creation by contractor and payment received by Multiplier',
        )
      : t(
          'definition-phase.compensation.payout-date-note-one',
          'Payout date to freelancer is subject to invoice creation by freelancer and payment received by Multiplier',
        );
  const payoutDateNoteThree =
    contractType === ContractType.CONTRACTOR
      ? t(
          'definition-phase.compensation.payout-date-note-three-contractor',
          'Contractor would receive the payment within 2-3 working days after payout initiated by Multiplier',
        )
      : t(
          'definition-phase.compensation.payout-date-note-three',
          'Freelancer would receive the payment within 2-3 working days after payout initiated by Multiplier',
        );

  return (
    <div tw="flex flex-col">
      <div tw="text-ps font-semibold text-text-primary mb-extra-small">
        {t(
          'definition-phase.compensation.payout-date-notes',
          'Payout Date Notes',
        )}
      </div>
      <ol tw="list-decimal pl-large text-ps font-normal text-text-tertiary mt-extra-small mb-extra-small">
        <li>{payoutDateNoteOne}</li>
        <li>
          {t(
            'definition-phase.compensation.payout-date-note-two',
            'In case of bank holidays and Sundays, the payout will be initiated on the next working day',
          )}
        </li>
        <li>{payoutDateNoteThree}</li>
      </ol>
    </div>
  );
};

export default PayoutDateNote;
