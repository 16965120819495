/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import 'twin.macro';

import { Controller, useFormContext } from 'react-hook-form';

import { ComboBox } from '@multiplier/common';

import { FormCard } from 'contract-onboarding/components/layout';

import {
  Contract,
  ContractTerm,
  ContractType,
  Strategy,
  Template,
  useGetContractTemplatesLazyQuery,
} from '__generated__/graphql';

interface ContractTemplateInputProps {
  companyId: string;
  contractType: Contract['type'];
  contractTerm: Contract['term'];
  contractCountry: Contract['country'];
}

export const ContractTemplateInputWithWrapper: React.FC<ContractTemplateInputProps> = ({
  companyId,
  contractType,
  contractTerm,
  contractCountry,
}) => (
  <FormCard tw="flex flex-row bg-background-white bg-opacity-5 gap-x-base">
    <ContractTemplateInput
      companyId={companyId}
      contractType={contractType}
      contractTerm={contractTerm}
      contractCountry={contractCountry}
    />
  </FormCard>
);

const ContractTemplateInput: React.FC<ContractTemplateInputProps> = ({
  companyId,
  contractType,
  contractTerm,
  contractCountry,
}) => {
  const [getContractTemplates, { data }] = useGetContractTemplatesLazyQuery();

  const { control } = useFormContext();

  useEffect(() => {
    if (
      companyId &&
      contractType === ContractType.EMPLOYEE &&
      contractTerm &&
      contractCountry
    ) {
      getContractTemplates({
        variables: {
          id: companyId,
          filter: {
            entityId: companyId,
            strategy: Strategy.PANDA_DOC,
            country: contractCountry,
            types:
              contractTerm === ContractTerm.PERMANENT
                ? ['CONTRACT_EOR']
                : ['CONTRACT_EOR_FIXED'],
          },
        },
      });
    }
  }, [companyId, contractType, contractTerm, contractCountry]);

  const contractTemplateDropdownValues = useMemo(
    () =>
      ((data?.company?.templates?.filter((template) => !!template) ??
        []) as Template[]).map((template) => ({
        label: template.displayName ?? template.externalId ?? '',
        value: template.id,
        title: template.displayName ?? template.externalId ?? '',
      })) ?? [],
    [data?.company?.templates],
  );

  if (contractTemplateDropdownValues.length === 0) {
    return null;
  }

  return (
    <div tw="flex flex-col gap-y-tiny">
      <div tw="text-text-primary">Select contract template</div>
      <div tw="text-text-secondary text-ps">
        Use one of the custom templates for this employee
      </div>
      <Controller
        name="preferredContractAgreementTemplate"
        control={control}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            tw="mt-base"
            variant="default"
            dropdownValues={contractTemplateDropdownValues}
            placeholder="Select contract template"
            value={value}
            onChange={onChange}
            showArrow
          />
        )}
      />
    </div>
  );
};

export default ContractTemplateInput;
