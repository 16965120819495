import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const contractUpdating: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.contract-updating.title', {
      defaultValue: 'Preparing Contract',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.contract-updating.description', {
      defaultValue:
        'Multiplier is preparing the contract and will send out to the signatory and employee for signatures.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t('onboarding-phase.contract-updating.label', 'Preparing Contract'),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t('onboarding-phase.contract-updating.status', 'Pending on Multiplier'),
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default contractUpdating;
