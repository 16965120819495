import { useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { userVar } from '@multiplier/user';
import { isUndefined } from 'lodash';

import {
  Capability,
  Company,
  Exact,
  LegalEntity,
  LegalEntityStatus,
  useGetCompanyEntitiesLazyQuery,
} from '__generated__/graphql';

export interface UseGetCompanyEntitiesRequestOptions {
  companyId?: string | null;
  companyEntityId?: string | null;
  companyEntityStatuses?: LegalEntityStatus | Array<LegalEntityStatus> | null;
  companyEntityCapabilities?: Capability | Array<Capability> | null;
}

export interface UseGetCompanyEntitiesProps {
  company?: Company;
  companyEntityId?: string;
}

export interface UseGetCompanyEntitiesResult {
  get: (param?: UseGetCompanyEntitiesRequestOptions) => Promise<any>;
  data?: Array<LegalEntity> | null | undefined;
  loading?: boolean;
}

const getRequestOptions = ({
  options,
  hookOptions,
  company,
}: {
  options?: UseGetCompanyEntitiesRequestOptions;
  hookOptions?: UseGetCompanyEntitiesProps;
  company?: Company;
}): Exact<{ id?: string }> => ({
  id: options?.companyId ?? hookOptions?.company?.id ?? company?.id ?? '',
});

const getRequestFilterOptions = ({
  options,
  hookOptions,
  company,
}: {
  options?: UseGetCompanyEntitiesRequestOptions;
  hookOptions?: UseGetCompanyEntitiesProps;
  company?: Company;
}): UseGetCompanyEntitiesRequestOptions => ({
  companyId: options?.companyId ?? hookOptions?.company?.id ?? company?.id,
  companyEntityId: options?.companyEntityId ?? hookOptions?.companyEntityId,
  companyEntityStatuses: options?.companyEntityStatuses,
  companyEntityCapabilities: options?.companyEntityCapabilities,
});

const filterEntityInResponse = ({
  data,
  options,
}: {
  data?: LegalEntity | null | undefined;
  options?: UseGetCompanyEntitiesRequestOptions | null | undefined;
}): boolean =>
  !!data &&
  (!options?.companyEntityId || data?.id === options?.companyEntityId) &&
  (isUndefined(data?.status) ||
    !options?.companyEntityStatuses?.length ||
    (!!data?.status &&
      !!options?.companyEntityStatuses?.includes(data?.status))) &&
  (isUndefined(data?.capabilities) ||
    !options?.companyEntityCapabilities?.length ||
    (!!data?.capabilities &&
      !!data?.capabilities.some(
        (m) => m && options?.companyEntityCapabilities?.includes(m),
      )));

const getResponse = ({
  data,
  options,
}: {
  data?: Company | null | undefined;
  options?: UseGetCompanyEntitiesRequestOptions | null | undefined;
}): Array<LegalEntity> | null | undefined => {
  const result = (data?.otherEntities ?? []).filter((m) =>
    filterEntityInResponse({ data: m, options }),
  ) as LegalEntity[];

  return result;
};

export const useGetCompanyEntities = (
  options?: UseGetCompanyEntitiesProps,
): UseGetCompanyEntitiesResult => {
  const { selectedCompany: company } = useReactiveVar(userVar);
  const [executeRequest, rest] = useGetCompanyEntitiesLazyQuery();
  const [filters, setFilters] = useState<UseGetCompanyEntitiesRequestOptions>();

  const get = (param?: UseGetCompanyEntitiesRequestOptions) => {
    setFilters(
      getRequestFilterOptions({
        options: param,
        hookOptions: options,
        company,
      }),
    );

    return executeRequest({
      variables: getRequestOptions({
        options: param,
        hookOptions: options,
        company,
      }),
    });
  };

  return {
    get,
    ...rest,
    data: getResponse({
      data: rest.data?.company,
      options: filters,
    }),
  };
};

export const GP_CAPABILITIES = [
  Capability.GROSS_TO_NET,
  Capability.SALARY_DISBURSEMENT,
];

export const hasGpCapabilities = (legalEntity: LegalEntity): boolean =>
  GP_CAPABILITIES.some((capability) =>
    legalEntity.capabilities?.includes(capability),
  );
