import React from 'react';

import tw from 'twin.macro';

interface TabButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isActive?: boolean;
}

const TabButton: React.FC<TabButtonProps> = ({
  isActive,
  children,
  ...props
}) => (
  <button
    type="button"
    css={[
      tw`text-ps line-height[20px] bg-background-primary rounded-full px-small py-2 outline-none focus:outline-none mx-6`,
      isActive
        ? tw`text-text-inverted-primary bg-background-action`
        : tw`text-text-action`,
    ]}
    {...props}
  >
    {children}
  </button>
);

export default TabButton;
