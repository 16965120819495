import { useEffect, useMemo } from 'react';

import { LegalEntity, LegalEntityStatus } from '__generated__/graphql';

import { useGetCompanyEntities } from './get-company-entities';

const useGetCompanyEntityInfo = (
  companyId: string | undefined | null,
  legalEntityId: string | undefined | null,
): LegalEntity | undefined => {
  const { get, data: entitiesData } = useGetCompanyEntities({
    companyId,
    legalEntityId,
  });

  useEffect(() => {
    get({
      statuses: [LegalEntityStatus.ACTIVE],
      capabilities: [],
    });
  }, []);

  // filter out the entity based on the entityId
  const filteredEntity = useMemo(
    () => entitiesData?.find((entity) => entity.id === legalEntityId),
    [entitiesData],
  );

  return filteredEntity;
};

export default useGetCompanyEntityInfo;
