import { useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { userVar } from '@multiplier/user';
import { isUndefined } from 'lodash';

import {
  Capability,
  Company,
  Exact,
  LegalEntity,
  LegalEntityStatus,
  useGetCompanyEntitiesLazyQuery,
} from '__generated__/graphql';

export interface UseGetCompanyEntitiesRequestOptions {
  companyId?: string | null;
  legalEntityId?: string | null;
  statuses?: LegalEntityStatus | Array<LegalEntityStatus> | null;
  capabilities?: Capability | Array<Capability> | null;
}

export interface UseGetCompanyEntitiesProps {
  companyId: string | undefined | null;
  legalEntityId: string | undefined | null;
}

export interface UseGetCompanyEntitiesResult {
  get: (param?: UseGetCompanyEntitiesRequestOptions) => void;
  data?: Array<LegalEntity> | null | undefined;
  loading?: boolean;
}

const getRequestOptions = ({
  options,
  hookOptions,
  companyId,
}: {
  options?: UseGetCompanyEntitiesRequestOptions;
  hookOptions?: UseGetCompanyEntitiesProps;
  companyId: string | undefined | null;
}): Exact<{ id?: string }> => ({
  id: options?.companyId ?? hookOptions?.companyId ?? companyId ?? '',
});

const getRequestFilterOptions = ({
  options,
  hookOptions,
  companyId,
}: {
  options?: UseGetCompanyEntitiesRequestOptions;
  hookOptions?: UseGetCompanyEntitiesProps;
  companyId: string | undefined | null;
}): UseGetCompanyEntitiesRequestOptions => ({
  companyId: options?.companyId ?? hookOptions?.companyId ?? companyId,
  legalEntityId: options?.legalEntityId ?? hookOptions?.legalEntityId,
  statuses: options?.statuses,
  capabilities: options?.capabilities,
});

const filterEntityInResponse = ({
  data,
  options,
}: {
  data?: LegalEntity | null | undefined;
  options?: UseGetCompanyEntitiesRequestOptions | null | undefined;
}): boolean =>
  !!data &&
  (!options?.legalEntityId || data?.id === options?.legalEntityId) &&
  (isUndefined(data?.status) ||
    !options?.statuses?.length ||
    (!!data?.status && !!options?.statuses?.includes(data?.status))) &&
  (isUndefined(data?.capabilities) ||
    !options?.capabilities?.length ||
    (!!data?.capabilities &&
      !!data?.capabilities.some(
        (m) => m && options?.capabilities?.includes(m),
      )));

const getResponse = ({
  data,
  options,
}: {
  data?: Company | null | undefined;
  options?: UseGetCompanyEntitiesRequestOptions | null | undefined;
}): Array<LegalEntity> | null | undefined => {
  const result = (data?.otherEntities ?? []).filter((m) =>
    filterEntityInResponse({ data: m, options }),
  ) as LegalEntity[];

  return result;
};

export const useGetCompanyEntities = (
  options?: UseGetCompanyEntitiesProps,
): UseGetCompanyEntitiesResult => {
  const { selectedCompany: company } = useReactiveVar(userVar);
  const [executeRequest, rest] = useGetCompanyEntitiesLazyQuery();
  const [filters, setFilters] = useState<UseGetCompanyEntitiesRequestOptions>();

  const get = (param?: UseGetCompanyEntitiesRequestOptions) => {
    setFilters(
      getRequestFilterOptions({
        options: param,
        hookOptions: options,
        companyId: company?.id,
      }),
    );

    executeRequest({
      variables: getRequestOptions({
        options: param,
        hookOptions: options,
        companyId: company?.id,
      }),
    });
  };

  return {
    get,
    ...rest,
    data: getResponse({
      data: rest.data?.company,
      options: filters,
    }),
  };
};
