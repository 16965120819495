import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';
import client from 'client';
import { GET_EQUIPMENTS_FOR_COMPANY } from 'equipment/queries';
import { GET_COMPANY } from 'organization-settings/queries';

import {
  GetCompanyQuery,
  GetEquipmentsForCompanyQuery,
} from '__generated__/graphql';

const hideModuleBasedOnAllEquipments = async () => {
  const companyData = await client.query<GetCompanyQuery>({
    query: GET_COMPANY,
  });

  if (companyData?.data?.company) {
    const response = await client.query<GetEquipmentsForCompanyQuery>({
      query: GET_EQUIPMENTS_FOR_COMPANY,
      variables: {
        companyId: companyData?.data?.company.id,
      },
    });

    return !(
      response?.data?.company?.equipments?.length &&
      response?.data?.company?.equipments?.length > 0
    );
  }

  return false;
};

const config: ModuleConfig = {
  id: 'equipments',
  permission: 'equipments',
  folder: 'equipment/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Assets',
    route: 'equipments',
    sort: 3,
    home: false,
    group: CompanyNavGroups.COMPANY,
    hide: async () => hideModuleBasedOnAllEquipments(),
  },
};

export default config;
