import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Dialog, useModal } from '@multiplier/common';
import tw from 'twin.macro';

interface OnboardingSpecialistModalConfirmProps {
  email: string;
}

const useOnboardingSpecialistModalConfirm = (): {
  handleConfirm: () => void;
  OnboardingSpecialistModalConfirm: React.FC<OnboardingSpecialistModalConfirmProps>;
} => {
  const { t } = useTranslation('contract-onboarding.common');

  const [openConfirm, handleClose, handleConfirm] = useModal();

  const OnboardingSpecialistModalConfirm: React.FC<OnboardingSpecialistModalConfirmProps> = ({
    email,
  }) => (
    <Dialog
      containerStyle={tw`w-[512px]`}
      open={openConfirm}
      onClose={handleClose}
      title={t(
        'onboarding-specialist-card.modal.confirm.title',
        'Email sent to onboarding specialist',
      )}
      buttonText={t(
        'onboarding-specialist-card.modal.confirm.got-it-button',
        'Got it',
      )}
      onConfirm={handleClose}
      hideCancelButton
      btnWrapperStyle={tw`justify-center pt-16`}
      confirmBtnStyle={tw`h-46 text-18 rounded-8`}
    >
      <div tw="text-neutral500 gap-2 text-ps">
        <Trans
          t={t}
          i18nKey="onboarding-specialist-card.modal.confirm.confirm-message"
        >
          Thank you reaching out to us. We will get back to you on
          <span tw="text-black font-bold"> {email}</span>.
        </Trans>
      </div>
    </Dialog>
  );

  return { handleConfirm, OnboardingSpecialistModalConfirm };
};

export default useOnboardingSpecialistModalConfirm;
