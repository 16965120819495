import { gql } from '@apollo/client';

// TODO - Needs to be changed once API contracts are confirmed
export default gql`
  mutation VerifyHash($input: VerificationObject) {
    verifyHash(input: $input)
      @rest(
        type: "JwtResponse"
        method: "POST"
        path: "account/verify"
        endpoint: "user"
      ) {
      id_token
    }
  }
`;
