const routes = {
  root: 'payments',
  checkout: 'checkout',
  cart: 'cart',
  paymentStatus: 'payment-status',
  creditNotes: 'credit-notes',
  invoiceQueries: 'invoice-queries',
  invoiceReport: 'reports',
};

export default routes;
