/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Button, Icon } from '@multiplier/common';
import { theme } from 'twin.macro';

import FailedToLoadImage from 'app/components/experience-loader/assets/failed-to-load.gif';

const LoadingFailed: React.FC<{ onProceed: () => void }> = ({ onProceed }) => {
  const { t } = useTranslation('common');

  return (
    <div
      tw="w-[80%] h-[480px] tablet:h-[520px] mobile:h-[520px] flex items-center justify-center border-grey04 border rounded-8 bg-white p-12"
      data-testid="failed-to-load"
    >
      <div tw="flex flex-col items-center">
        <img src={FailedToLoadImage} alt="Failed to Load" tw="w-[200px] mb-6" />
        <div tw="flex flex-col items-center gap-y-24 text-background">
          <div tw="text-h3 font-semibold">
            {t('experience-loader.failed-to-load.title', 'Ooooops...')}
          </div>
          <div tw="text-p text-grey02 max-w-[520px] text-center">
            <Trans t={t} i18nKey="experience-loader.failed-to-load.description">
              <p>
                Something went wrong. <br />
                One or more of the libraries couldn’t be loaded due to a network
                issue.
                <br />
                <br />
                You can switch to a different network / web-browser or choose
                one of the following options below
              </p>
            </Trans>
          </div>
          <div tw="flex gap-x-12 tablet:(flex-col gap-y-6) mobile:(flex-col gap-y-6)">
            <Button size="medium" variant="outline" onClick={onProceed}>
              <div tw="flex items-center gap-x-6">
                <Icon
                  name="feather"
                  fill="transparent"
                  stroke={theme`colors.primary`}
                />
                {t(
                  'experience-loader.failed-to-load.continue',
                  'Proceed to use the lite version',
                )}
              </div>
            </Button>

            <Button
              size="medium"
              onClick={() => {
                window.location.reload();
              }}
            >
              <div tw="flex items-center gap-x-12 w-[220px] justify-center">
                <Icon name="reload" fill="transparent" stroke="white" />
                {t('experience-loader.failed-to-load.reload', 'Reload Page')}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingFailed;
