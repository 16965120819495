import { gql } from '@apollo/client';

export default gql`
  fragment contractBenefit on Benefit {
    type
    benefitStatus
    packageName
    packageTier {
      tier
    }
  }
`;
