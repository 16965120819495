import React from 'react';

import { DropdownValue } from '@multiplier/common';
import groupBy from 'lodash/groupBy';

import { Contract, DeductionDefinition } from '__generated__/graphql';

import DeductionsGroup from './deductions-group';

type DeductionsGroupsComponent = React.FC<{
  deductionDefinitions: DeductionDefinition[];
  currencyOptions: DropdownValue[];
  countryCode?: Contract['country'];
  postProbationBaseSalary?: number;
}>;

const DeductionsGroups: DeductionsGroupsComponent = ({
  deductionDefinitions,
  currencyOptions,
  postProbationBaseSalary,
  countryCode,
}) => {
  const definitionsByGroupKey = Object.entries(
    groupBy(deductionDefinitions, 'groupKey'),
  );

  return (
    <>
      {definitionsByGroupKey.map(([groupKey, definitions]) => (
        <DeductionsGroup
          key={groupKey}
          groupKey={groupKey}
          sameGroupDefinitions={definitions}
          currencyOptions={currencyOptions}
          countryCode={countryCode}
          postProbationBaseSalary={postProbationBaseSalary}
        />
      ))}
    </>
  );
};

export default DeductionsGroups;
