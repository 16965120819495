import tw, { TwStyle } from 'twin.macro';

export const getDefaultOrPrimaryStyles = (isNewTheme: boolean): TwStyle[] => [
  tw`bg-background-brand text-text-inverted-primary`,
  tw`hover:bg-background-brand-hover`,
  tw`disabled:(bg-background-tertiary border-none text-text-tertiary)`,
  !isNewTheme
    ? tw`bg-background-action hover:bg-background-action-hover`
    : tw``,
];

export const getSecondaryOrOutlineStyles = (isNewTheme: boolean): TwStyle[] => [
  tw`border text-text-primary border-border-secondary`,
  !isNewTheme ? tw`text-text-action` : tw``,
  tw`hover:bg-background-primary`,
  tw`disabled:(border-border-primary text-text-tertiary) disabled:hover:bg-transparent`,
];

export const tertiaryStyles = [
  tw`bg-background-primary text-text-action`,
  tw`hover:bg-background-secondary`,
  tw`disabled:(bg-background-tertiary text-text-tertiary)`,
];

export const inlineStyles = [tw`text-text-brand-link text-ps underline`];

export const contentStyles = [tw`px-0`];

export const dangerStyles = [
  tw`bg-red600 text-white`,
  tw`hover:bg-red700 disabled:(bg-grey05 shadow-none)`,
];

export const dangerOutlineStyles = [
  tw`bg-transparent text-red600 border border-red600`,
  tw`hover:(bg-red600 text-white) disabled:(bg-grey05 shadow-none)`,
  tw`hover:disabled:text-grey03`,
];
