import React from 'react';

import { createColumnHelper, flexRender } from '@tanstack/react-table';
import tw from 'twin.macro';

import Card from '../card';
import Icon from '../icon';
import Table from '../table';
import StyledRowType from '../table/components/styled-row';
import CheckBoxTooltipWrapper from './components/check-box-tooltip-wrapper';
import Pagination from './components/pagination';
import useClientPaginatedTable from './hooks/use-client-paginated-table';
import useClientPaginatedTableNoUrlState from './hooks/use-client-paginated-table-no-url-state';
import useServerPaginatedTable from './hooks/use-server-paginated-table';
import useTableState from './hooks/use-table-state';
import getSelectColumnDefinition from './services/get-select-column-definition';
import mapSortToGenerics from './services/map-sort-to-generic';
import { TableProps } from './types';

const TableV2 = <TData extends unknown>({
  tableInstance,
  horizontalScroll = false,
  disablePagination = false,
  widget,
  additionalPageSizes,
  totalText,
  handleRowClick,
  shouldHighlightRow,
  boldColumnHeader,
  bgRowsType,
  rowStyles,
  tableHeadCellStyle,
  tableHeaderRowStyle,
  tableCellStyle,
  renderSubRows,
  fixedPageSize,
  tableStyle,
}: TableProps<TData>): React.ReactElement => {
  const { getHeaderGroups } = tableInstance;
  const headerGroups = getHeaderGroups();

  return (
    <Card>
      <div css={[horizontalScroll && tw`overflow-x-auto`]}>
        {widget}
        <table css={[tw`w-full`, tableStyle]}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                key={headerGroup.id}
                css={[tw`text-ps leading-normal`, tableHeaderRowStyle]}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    css={[
                      tw`pr-large py-large font-normal text-left text-text-tertiary last-of-type:pr-none`,
                      header.column.id === 'actions' && tw`text-right`,
                      header.column.getIsSorted() && tw`cursor-pointer`,
                      boldColumnHeader && tw`font-medium`,
                      tableHeadCellStyle,
                    ]}
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={header.column.getToggleSortingHandler()}
                        css={[
                          header.column.getCanSort() &&
                            tw`cursor-pointer select-none`,
                        ]}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}{' '}
                        <div tw="inline opacity-50">
                          {header.column.getIsSorted() && (
                            <>
                              {header.column.getIsSorted() === 'desc' ? (
                                <Icon
                                  name="caret-down"
                                  tw="inline-block mb-6 ml-6"
                                />
                              ) : (
                                <Icon
                                  name="caret-up"
                                  tw="inline-block mb-6 ml-6"
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            data-testid="table-body"
            tw="text-p text-text-primary mobile:border-none border-t border-border-primary"
          >
            {tableInstance?.getRowModel()?.rows.map((row) => (
              <React.Fragment key={row.index}>
                <StyledRowType
                  onClick={() =>
                    handleRowClick && handleRowClick(row?.original)
                  }
                  key={row.id}
                  bgDark={isRowBackgroundDark(bgRowsType, row.index % 2)}
                  rowStyles={
                    typeof rowStyles === 'function' ? rowStyles(row) : rowStyles
                  }
                  hasHover={!!handleRowClick}
                  highlight={
                    shouldHighlightRow && shouldHighlightRow(row.original)
                  }
                  data-testid={
                    shouldHighlightRow && shouldHighlightRow(row.original)
                      ? `row-${row.id}-highlight`
                      : `row-${row.id}`
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      tw="py-base first-of-type:pl-none last-of-type:pr-none"
                      css={[tableCellStyle, tw`py-base`]}
                      style={{
                        width: cell.column.getSize(),
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Table.Cell>
                  ))}
                </StyledRowType>
                {row?.getIsExpanded() && renderSubRows && renderSubRows(row)}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {!disablePagination && (
        <Pagination
          totalText={totalText}
          tableInstance={tableInstance}
          additionalPageSizes={additionalPageSizes}
          fixedPageSize={fixedPageSize}
        />
      )}
    </Card>
  );
};

export default Object.assign(TableV2, {
  useServerPaginatedTable,
  useTableState,
  useClientPaginatedTable,
  useClientPaginatedTableNoUrlState,
  getSelectColumnDefinition,
  mapSortToGenerics,
  CheckBoxTooltipWrapper,
  createColumnHelper,
});

const isRowBackgroundDark = <TData extends unknown>(
  bgRowsType: TableProps<TData>['bgRowsType'],
  rowIndex: number,
): boolean => {
  if (bgRowsType === 'custom') return false;
  if (bgRowsType === 'even') return rowIndex % 2 === 0;
  return rowIndex % 2 === 1;
};
