import { useNavigate } from 'react-router-dom';

import { useSubmitCompliance } from 'contract-onboarding/hooks';

import {
  Contract,
  ContractAgreementType,
  ContractOnboardingStep,
  ContractType,
  useUploadCustomContractMutation,
} from '__generated__/graphql';

import {
  ContractInputType,
  CustomContractForm,
} from '../pages/definition-phase/pages/contract';
import stepConfig from '../pages/definition-phase/step-config';

const useSubmitCustomContract = (
  contractInputType?: ContractInputType,
  type?: Contract['type'],
  contractIdFromPath?: string,
): {
  loading: boolean;
  onSubmit: (values: CustomContractForm) => Promise<void>;
} => {
  const navigate = useNavigate();
  const [
    uploadContract,
    { loading: loadingUploadContract },
  ] = useUploadCustomContractMutation();
  const {
    onSubmit: updateCompliance,
    loading: loadingUpdateCompliance,
  } = useSubmitCompliance();
  const onSubmit = async (values: CustomContractForm) => {
    if (!contractIdFromPath) {
      return;
    }

    if (
      type === ContractType.FREELANCER &&
      contractInputType === ContractInputType.MULTIPLIER
    ) {
      await updateCompliance(
        { complianceParams: values.complianceParams },
        contractIdFromPath,
        ContractAgreementType.MULTIPLIER_TEMPLATE,
        true,
      );
    }

    const contract = await uploadContract({
      variables: {
        id: contractIdFromPath,
        file: values.customContract,
      },
    });

    if (contract.data?.contractAgreementCustomUpload?.onboarding?.current) {
      navigate(
        stepConfig[
          contract.data?.contractAgreementCustomUpload?.onboarding
            ?.current as ContractOnboardingStep
        ].directLink(contract.data?.contractAgreementCustomUpload?.id),
      );
    }
  };
  return {
    loading: loadingUploadContract || loadingUpdateCompliance,
    onSubmit,
  };
};

export default useSubmitCustomContract;
