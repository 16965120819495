/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import WetInkKnowMoreLink from './wet-ink-know-more-link';

export const WetInkOnboardingStatusHeaderDescription: React.FC<{
  firstName: string | undefined;
  country: string | undefined;
}> = ({ firstName, country }) => {
  const { t } = useTranslation('contract-onboarding.common');

  return (
    <Trans
      t={t}
      i18nKey="onboarding-phase.created-wet-ink-signature.description"
      defaults="Please review {{ firstName }}'s employment details and confirm them. Once you click Send Contract, Multiplier will send the employment contract to your Signatory and after it is signed by the Signatory, it will be sent to {{ firstName }}.<br/> <br/>Wet ink signatures are mandatory are as per {{ country }}'s labor laws. The turn around time for completion refers to the practice of physically signing a document using ink on paper, creating a unique and verifiable mark. <a>know more</a>"
      values={{ firstName, country }}
      components={{
        br: <br />,
        a: <WetInkKnowMoreLink />,
      }}
    />
  );
};

export { WetInkOnboardingStatusHeaderDescription as default };
