import { useEffect } from 'react';

// import SentryFullStory from '@sentry/fullstory';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import importMetaEnv from 'import-meta-env';

const initializeSentry = (): void => {
  if (importMetaEnv.VITE_ENV === 'prod' && importMetaEnv.PROD) {
    Sentry.init({
      dsn: importMetaEnv.VITE_CUSTOMER_SENTRY_DSN,
      integrations: [
        // new SentryFullStory(importMetaEnv.VITE_SENTRY_ORG_SLUG as string), find a solution for this
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.browserTracingIntegration({
          idleTimeout: 2000,
        }),
      ],
      beforeSendTransaction({ breadcrumbs, spans, ...rest }) {
        const newSpans = spans?.map((span) => {
          if (span.op !== 'http.client') return span;

          const matchingBreadCrumb = breadcrumbs?.find(
            // eslint-disable-next-line no-underscore-dangle
            (breadcrumb) => breadcrumb?.data?.__span === span.span_id,
          );
          return {
            ...span,
            data: {
              ...span.data,
              'http.url':
                matchingBreadCrumb?.data?.operation_name ??
                span.data?.[`http.url`],
            },
          };
        });

        return {
          ...rest,
          breadcrumbs,
          spans: newSpans,
        };
      },
      beforeBreadcrumb(breadcrumb, hint) {
        if (
          breadcrumb.category === 'fetch' &&
          breadcrumb.type === 'http' &&
          breadcrumb?.data?.url.includes('graphql')
        ) {
          const [, req] = hint?.input;
          const { operationName, query } = JSON.parse(req?.body);
          let operationType;
          if (query.includes('query')) {
            operationType = 'query';
          } else {
            operationType = 'mutation';
          }
          const modifiedBreadcrumb = {
            ...breadcrumb,
            type: 'http',
            category: operationType,
            data: {
              operation_name: operationName,
              operation_type: operationType,
              // eslint-disable-next-line no-underscore-dangle
              __span: breadcrumb?.data?.__span,
            },
          };
          return modifiedBreadcrumb;
        }
        return breadcrumb;
      },
      tracesSampleRate: 0.25,
      tracePropagationTargets: [
        importMetaEnv.VITE_EMPLOYER_SERVICE_URL as string,
        importMetaEnv.VITE_CONTRACTOR_SERVICE_URL as string,
        importMetaEnv.VITE_BENEFIT_SERVICE_URL as string,
        importMetaEnv.VITE_USER_SERVICE_URL as string,
        importMetaEnv.VITE_CORE_SERVICE_URL as string,
      ],
      environment: importMetaEnv.VITE_ENV,
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
        `Cannot read properties of null (reading 'endpoint')`, // Google Login
        'NotReadableError: The I/O read operation failed.', // Mobile Browser
        `null is not an object (evaluating 'this.nexusWebSocket.endpoint')`, // Intercom
        'Failed to fetch', // Vite
        'Importing a module script failed', // Vite
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }
};

export default initializeSentry;
