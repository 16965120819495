import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const memberCompleted: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.member-completed.title', {
      defaultValue: "Verifying {{ name }}'s details",
      replace: {
        name,
      },
    }),
  subtitle: (t) =>
    t('onboarding-phase.member-completed.subtitle', '1 - 2 business days'),
  description: (t, firstName, gender) =>
    t('onboarding-phase.member-completed.description', {
      defaultValue:
        "{{ firstName }} has provided details and we're currently verifying them.",
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.member-completed.label',
      'Details provided by employee',
    ),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t('onboarding-phase.member-completed.status', 'Pending on Multiplier'),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
};

export default memberCompleted;
