/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import tw from 'twin.macro';

import { FixedPayComponent, PayFrequency } from '__generated__/graphql';

const InfoText = tw.div`text-ps text-neutral500`;

const PaymentInfo: React.FC<{
  paymentFrequency?: FixedPayComponent['paymentFrequency'];
  detailed?: boolean;
}> = ({ paymentFrequency, detailed }) => {
  const { t } = useTranslation('contract-onboarding.company');

  if (!paymentFrequency) return null;

  if (paymentFrequency === PayFrequency.WEEKLY) {
    return detailed ? (
      <InfoText tw="text-text-primary">
        <Trans
          t={t}
          i18nKey="payment-frequency-info.weekly-detailed-highlighted"
        >
          The payroll frequency is <span tw="font-semibold">weekly</span>, and
          employee will be <span tw="font-semibold">paid weekly</span>
        </Trans>
      </InfoText>
    ) : (
      <InfoText>
        <Trans t={t} i18nKey="payment-frequency-info.weekly-highlighted">
          The employee will be <span tw="font-semibold">paid weekly</span>
        </Trans>
      </InfoText>
    );
  }

  if (paymentFrequency === PayFrequency.BIWEEKLY) {
    return detailed ? (
      <InfoText tw="text-text-primary">
        <Trans
          t={t}
          i18nKey="payment-frequency-info.bi-weekly-detailed-highlighted"
        >
          The payroll frequency is <span tw="font-semibold">bi-weekly</span>,
          and employee will be <span tw="font-semibold">paid biweekly</span>
        </Trans>
      </InfoText>
    ) : (
      <InfoText>
        <Trans t={t} i18nKey="payment-frequency-info.bi-weekly-highlighted">
          The employee will be <span tw="font-semibold">paid biweekly</span>
        </Trans>
      </InfoText>
    );
  }

  if (paymentFrequency === PayFrequency.SEMIMONTHLY) {
    return detailed ? (
      <InfoText tw="text-text-primary">
        <Trans
          t={t}
          i18nKey="payment-frequency-info.semimonthly-detailed-highlighted"
        >
          The payroll frequency is <span tw="font-semibold">semi-monthly</span>,
          and employee will be{' '}
          <span tw="font-semibold">
            paid twice by 15th and end of every month
          </span>
        </Trans>
      </InfoText>
    ) : (
      <InfoText>
        <Trans t={t} i18nKey="payment-frequency-info.semimonthly-highlighted">
          The employee will be{' '}
          <span tw="font-semibold">
            paid twice by 15th and end of every month
          </span>
        </Trans>
      </InfoText>
    );
  }

  if (paymentFrequency === PayFrequency.MONTHLY) {
    return detailed ? (
      <InfoText tw="text-text-primary">
        <Trans
          t={t}
          i18nKey="payment-frequency-info.monthly-detailed-highlighted"
        >
          The payroll frequency is <span tw="font-semibold">monthly</span>, and
          employee will be{' '}
          <span tw="font-semibold">paid once by the end of every month</span>
        </Trans>
      </InfoText>
    ) : (
      <InfoText>
        <Trans t={t} i18nKey="payment-frequency-info.monthly-highlighted">
          The employee will be{' '}
          <span tw="font-semibold">paid once by the end of every month</span>
        </Trans>
      </InfoText>
    );
  }
  return null;
};

export default PaymentInfo;
