import React from 'react';

import { useTranslation } from 'react-i18next';

import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const KycDetailsTask: React.FC<TaskEntryProps> = ({ task }) => {
  const { t } = useTranslation('contract-onboarding');

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.kyc-details.title',
        'KYC details & documents',
      )}
      completed={task.completed}
    />
  );
};

export default KycDetailsTask;
