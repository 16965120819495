import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractOnboardingStepConfig } from '../step-config';

const visaApplication: ContractOnboardingStepConfig = {
  title: (t): string =>
    t('onboarding-phase.visa_application.title', 'Visa Application'),
  status: (t): string =>
    t('onboarding-phase.visa_application.status', 'Pending on Partner'),
  variant: ProgressWidgetContentVariant.STATUSES,
};

export default visaApplication;
