import { gql } from '@apollo/client';

export default gql`
  query Logout {
    logout
      @rest(
        type: "Void"
        method: "GET"
        path: "logout-user"
        endpoint: "user"
      ) {
      void
    }
  }
`;
