import history from 'app/utils/history';
import { ComplianceFormValues } from 'contract-onboarding/components/contract-section';
import { useSubmitCompliance } from 'contract-onboarding/hooks';

import { ContractOnboardingStep } from '__generated__/graphql';

import stepConfig from '../pages/definition-phase/step-config';

const useSubmitDefinitionCompliance = (
  contractId?: string | null,
  onCompleted?: () => void,
): {
  loading: boolean;
  onSubmit: (
    values: ComplianceFormValues,
    skipUpdatingLeaveEntitlement?: boolean,
  ) => void;
} => {
  const { onSubmit: updateCompliance, loading } = useSubmitCompliance(
    onCompleted,
  );

  const onSubmit = async (
    values: ComplianceFormValues,
    skipUpdatingLeaveEntitlement?: boolean,
  ) => {
    if (!contractId) return;

    const complianceUpdate = await updateCompliance(
      values,
      contractId,
      undefined,
      skipUpdatingLeaveEntitlement,
    );

    if (complianceUpdate?.onboarding?.current)
      history.push(
        stepConfig[
          complianceUpdate.onboarding.current as ContractOnboardingStep
        ].directLink(complianceUpdate.id),
      );
  };

  return {
    loading,
    onSubmit,
  };
};

export default useSubmitDefinitionCompliance;
