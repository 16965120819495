import React, { useContext, useRef } from 'react';

import ReactDOM from 'react-dom';
import { useClickAway, useKey } from 'react-use';

import tw, { TwStyle, theme } from 'twin.macro';

import Button from '../button';
import Icon from '../icon';
import IconButton from '../icon-button';
import { ThemeContext } from '../theme-provider';
import { ButtonIntent, ButtonSize } from '../types';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  buttonText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  asset?: React.ReactNode;
  icon?: string;
  description?: string | React.ReactNode;
  cancelText?: string;
  loading?: boolean;
  disabled?: boolean;
  v2?: boolean;
  containerStyle?: TwStyle;
  headerStyle?: TwStyle;
  contentStyle?: TwStyle;
  buttonContainerStyle?: TwStyle;
  portalRoot?: string;
  closeOnClickAway?: boolean;
  showCancelButton?: boolean;
  buttonSize?: ButtonSize;
  confirmButtonIntent?: ButtonIntent;
  renderDialogContent?: ({
    onDialogClose,
  }: {
    onDialogClose: () => void;
  }) => React.ReactElement;
  hideButtons?: boolean;
}

const BaseConfirmationDialog: React.FC<
  React.PropsWithChildren<ConfirmationDialogProps>
> = ({
  onClose,
  onCancel,
  onConfirm,
  asset,
  icon,
  title,
  description,
  buttonText,
  cancelText = 'Cancel',
  containerStyle,
  headerStyle,
  buttonContainerStyle,
  contentStyle,
  loading = false,
  disabled = false,
  v2 = false,
  children,
  closeOnClickAway = true,
  showCancelButton = true,
  renderDialogContent,
  buttonSize = 'medium',
  confirmButtonIntent,
  hideButtons = false,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const dialogRef = useRef(null);
  useClickAway(dialogRef, () => {
    if (closeOnClickAway) onClose();
  });

  useKey('Escape', () => {
    if (closeOnClickAway) onClose();
  });

  if (typeof renderDialogContent === 'function') {
    return (
      <Lightbox>
        <Container
          data-testid="modal"
          ref={dialogRef}
          data-cy="modal"
          css={[containerStyle]}
        >
          {renderDialogContent({ onDialogClose: onClose })}
        </Container>
      </Lightbox>
    );
  }

  return (
    <Lightbox>
      <Container
        data-testid="modal"
        ref={dialogRef}
        data-cy="modal"
        css={[containerStyle]}
      >
        {v2 ? (
          <div
            css={[
              tw`flex flex-row items-center justify-between px-base py-small bg-background-primary text-text-primary`,
              !isNewThemeApplied && tw`text-background bg-grey05`,
              headerStyle,
            ]}
          >
            <p tw="text-h6 font-semibold">{title}</p>
            <Button variant="content" onClick={onClose}>
              <Icon
                tw="h-[18px] w-[18px]"
                name="cross"
                fill={theme`colors.icon-tertiary`}
              />
            </Button>
          </div>
        ) : (
          <>
            <div tw="absolute right-0 top-0">
              <IconButton
                size="medium"
                name="cross"
                fill={theme`colors.icon-tertiary`}
                onClick={onClose}
              />
            </div>
            <div tw="flex flex-col justify-center items-center p-base">
              {asset ||
                (icon ? (
                  <Icon name={icon} tw="h-105 w-105 mb-40 mt-small" />
                ) : (
                  ''
                ))}
              <h5
                css={[
                  tw`w-full text-center text-h5 font-semibold text-text-primary`,
                  !isNewThemeApplied && tw`text-background`,
                ]}
              >
                {title}
              </h5>
              {description && (
                <p
                  css={[
                    tw`w-10/12 text-center mt-small text-p text-text-secondary`,
                    !isNewThemeApplied && tw`text-grey02`,
                  ]}
                >
                  {description}
                </p>
              )}
            </div>
          </>
        )}
        <div css={[v2 ? tw`` : tw`m-base`, contentStyle]}>{children}</div>
        {!hideButtons && (
          <div
            css={[
              v2
                ? tw`flex flex-row justify-between items-center px-base pb-small pt-none`
                : tw`w-full text-center my-extra-large`,
              buttonContainerStyle,
            ]}
          >
            {showCancelButton && (
              <Button
                variant="secondary"
                size={buttonSize}
                css={v2 ? tw`` : tw`mr-small`}
                onClick={() =>
                  typeof onCancel === 'function' ? onCancel() : onClose()
                }
                data-testid="confirmation-dialog-cancel"
              >
                {cancelText}
              </Button>
            )}
            <Button
              data-cy="confirm-button"
              variant="primary"
              size={buttonSize}
              onClick={onConfirm}
              disabled={loading || disabled}
              loading={loading}
              intent={confirmButtonIntent}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </Container>
    </Lightbox>
  );
};

const ConfirmationDialog: React.FC<
  React.PropsWithChildren<ConfirmationDialogProps>
> = (props) => {
  const { open, portalRoot } = props;

  if (open) {
    return ReactDOM.createPortal(
      <BaseConfirmationDialog {...props} />,
      document.getElementById(portalRoot ?? 'dialog-root') as HTMLElement,
    );
  }
  return null;
};

const Lightbox = tw.div`h-screen w-screen fixed top-0 left-0 flex justify-center items-center bg-background-overlay z-40`;
const Container = tw.div`bg-background-white h-auto width[360px] rounded-base relative`;

export default Object.assign(ConfirmationDialog, {
  BaseConfirmationDialog,
  Lightbox,
  Container,
});
