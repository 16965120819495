/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import tw, { styled } from 'twin.macro';

import { CountryCode, RateFrequency } from '__generated__/graphql';

const MainContainer = styled.div([
  tw`text-text-tertiary text-ps pt-extra-small`,
  ({ isNewThemeApplied }: { isNewThemeApplied: boolean }) =>
    !isNewThemeApplied && tw`text-grey01`,
]);

const SalaryBreakdownNotes: React.FC<{
  frequency: RateFrequency | null | undefined;
  country: CountryCode | null | undefined;
}> = ({ frequency, country }) => {
  const { t } = useTranslation('salary-calculator');
  const { isNewThemeApplied } = useContext(ThemeContext);

  if (frequency === RateFrequency.HOURLY) {
    return (
      <MainContainer isNewThemeApplied={isNewThemeApplied}>
        <Trans t={t} i18nKey="compensation.salary-breakdown-notes-hourly">
          <div tw="font-semibold mr-extra-small">Note:</div>
          <ol tw="list-decimal pl-large">
            <li tw="py-small">
              The final amount you pay &amp; the employee gets may differ based
              upon the no. of billable hours entered by the employee in the
              timesheets & approved by employer.{' '}
            </li>
            <li>
              All Taxes and Contributions are estimated based on a specific
              persona in each country. They may differ depending on factors of
              the person being hired (such as age, marital status, region, etc).
            </li>
          </ol>
        </Trans>
      </MainContainer>
    );
  }

  if (country === CountryCode.IND) {
    return (
      <MainContainer isNewThemeApplied={isNewThemeApplied}>
        <Trans t={t} i18nKey="compensation.salary-breakdown-notes-ind">
          <div tw="font-semibold mr-extra-small">Note:</div>
          <ol tw="list-decimal pl-large flex flex-col gap-y-small">
            <li>Administration Fees are not part of the Gross Salary.</li>
            <li>
              All Taxes and Contributions are estimated based on a specific
              persona in each country. They may differ depending on factors of
              the person being hired (such as age, marital status, region, etc).
            </li>
          </ol>
        </Trans>
      </MainContainer>
    );
  }

  return (
    <MainContainer isNewThemeApplied={isNewThemeApplied}>
      <Trans t={t} i18nKey="compensation.salary-breakdown-note">
        <span tw="font-semibold mr-extra-small">Note:</span>
        All Taxes and Contributions are estimated based on a specific persona in
        each country. They may differ depending on factors of the person being
        hired (such as age, marital status, region, etc).
      </Trans>
    </MainContainer>
  );
};

export default SalaryBreakdownNotes;
