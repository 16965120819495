/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as TickMark } from '@multiplier/assets/files/team/tick-equipment.svg';
import tw from 'twin.macro';

import EquipmentAssignedContainer from 'equipment/components/equipment-assigned-container';

import {
  Contract,
  EquipmentProcurement,
  EquipmentProcurementItem,
  EquipmentProcurementStatus,
  Maybe,
} from '__generated__/graphql';

const Wrapper = tw.div``;

const MainContainer = tw.div`
  flex flex-col bg-background-brand-subtle rounded-base px-small py-[14px]
`;

const Header = tw.div`
  flex flex-row text-text-primary font-normal text-18 mb-base
`;

const Description = tw.div`
  flex text-p text-text-secondary mb-large
`;

const EquipmentList = tw.div`
  flex flex-col gap-y-6 max-w-[300px]
`;

interface RequestSubmittedContainerProps {
  procurementData?: Maybe<Array<EquipmentProcurement>>;
  contract: Contract;
}

interface EquipmentItemProps {
  equipmentType: Maybe<string> | undefined;
  equipmentName: Maybe<string> | undefined;
}

export const EquipmentItem = ({
  equipmentType,
  equipmentName,
}: EquipmentItemProps): React.ReactElement => (
  <div tw="grid grid-cols-2 text-ps text-text-primary gap-x-extra-large">
    <div tw="font-semibold">{equipmentType}</div>
    <div>{equipmentName}</div>
  </div>
);

const RequestSubmittedContainer: React.FC<RequestSubmittedContainerProps> = ({
  procurementData,
  contract,
}) => {
  const { t } = useTranslation('team.equipment');
  return (
    <Wrapper>
      {procurementData?.[0].status === EquipmentProcurementStatus.REQUESTED && (
        <MainContainer data-testid="procurement-status-requested">
          <Header>
            <TickMark />
            <div tw="ml-small">
              {t('request-submitted.header', 'Request Submitted')}
            </div>
          </Header>
          <Description>
            <span tw="text-text-primary mr-6">Note:</span>
            {t(
              'request-submitted.description',
              'Thanks for letting us know your interest We will be getting back to you soon with a quote. Equipment delivery will be scheduled post the invoice payment',
            )}
          </Description>
          <EquipmentList>
            {procurementData?.[0]?.items?.map(
              (equipment: EquipmentProcurementItem) => (
                <EquipmentItem
                  key={equipment.id}
                  equipmentType={equipment.type}
                  equipmentName={equipment.name}
                />
              ),
            )}
          </EquipmentList>
        </MainContainer>
      )}
      {procurementData?.[0].status === EquipmentProcurementStatus.COMPLETED && (
        <EquipmentAssignedContainer contract={contract} />
      )}
    </Wrapper>
  );
};

export default RequestSubmittedContainer;
