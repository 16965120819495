import { CountryCode } from '__generated__/graphql';

type countryIdd = {
  [key: string]: string;
};

const countryIDD: countryIdd = {
  [CountryCode.ABW]: '+297',
  [CountryCode.AFG]: '+93',
  [CountryCode.AGO]: '+244',
  [CountryCode.AIA]: '+1264',
  [CountryCode.ALA]: '+35818',
  [CountryCode.ALB]: '+355',
  [CountryCode.AND]: '+376',
  [CountryCode.ARE]: '+971',
  [CountryCode.ARG]: '+54',
  [CountryCode.ARM]: '+374',
  [CountryCode.ASM]: '+1684',
  // [CountryCode.ATA]: '',
  [CountryCode.ATF]: '+262',
  [CountryCode.ATG]: '+1268',
  [CountryCode.AUS]: '+61',
  [CountryCode.AUT]: '+43',
  [CountryCode.AZE]: '+994',
  [CountryCode.BDI]: '+257',
  [CountryCode.BEL]: '+32',
  [CountryCode.BEN]: '+229',
  [CountryCode.BFA]: '+226',
  [CountryCode.BGD]: '+880',
  [CountryCode.BGR]: '+359',
  [CountryCode.BHR]: '+973',
  [CountryCode.BHS]: '+1242',
  [CountryCode.BIH]: '+387',
  [CountryCode.BLM]: '+383',
  [CountryCode.SHN]: '+2',
  [CountryCode.BLR]: '+375',
  [CountryCode.BLZ]: '+501',
  [CountryCode.BMU]: '+1441',
  [CountryCode.BOL]: '+591',
  [CountryCode.BES]: '+599',
  [CountryCode.BRA]: '+55',
  [CountryCode.BRB]: '+1246',
  [CountryCode.BRN]: '+673',
  [CountryCode.BTN]: '+975',
  // [CountryCode.BVT]: '+47',
  [CountryCode.BWA]: '+267',
  [CountryCode.CAF]: '+236',
  [CountryCode.USA]: '+1', // Defaulting +1 to USA flag
  [CountryCode.CAN]: '+1',
  [CountryCode.CCK]: '+61',
  [CountryCode.CHE]: '+41',
  [CountryCode.CHL]: '+56',
  [CountryCode.CHN]: '+86',
  [CountryCode.CIV]: '+225',
  [CountryCode.CMR]: '+237',
  [CountryCode.COD]: '+243',
  [CountryCode.COG]: '+242',
  [CountryCode.COK]: '+682',
  [CountryCode.COL]: '+57',
  [CountryCode.COM]: '+269',
  [CountryCode.CPV]: '+238',
  [CountryCode.CRI]: '+506',
  [CountryCode.CUB]: '+53',
  [CountryCode.CUW]: '+599',
  [CountryCode.CXR]: '+61',
  [CountryCode.CYM]: '+1345',
  [CountryCode.CYP]: '+357',
  [CountryCode.CZE]: '+420',
  [CountryCode.DEU]: '+49',
  [CountryCode.DJI]: '+253',
  [CountryCode.DMA]: '+1767',
  [CountryCode.DNK]: '+45',
  [CountryCode.DOM]: '+1',
  [CountryCode.DZA]: '+213',
  [CountryCode.ECU]: '+593',
  [CountryCode.EGY]: '+20',
  [CountryCode.ERI]: '+291',
  // [CountryCode.ESH]: '+2',
  [CountryCode.ESP]: '+34',
  [CountryCode.EST]: '+372',
  [CountryCode.ETH]: '+251',
  [CountryCode.FIN]: '+358',
  [CountryCode.FJI]: '+679',
  [CountryCode.FLK]: '+500',
  [CountryCode.FRA]: '+33',
  // [CountryCode.FRO]: '+298',
  [CountryCode.FSM]: '+691',
  [CountryCode.GAB]: '+241',
  [CountryCode.GBR]: '+44',
  [CountryCode.GEO]: '+995',
  [CountryCode.GGY]: '+44',
  [CountryCode.GHA]: '+233',
  [CountryCode.GIB]: '+350',
  [CountryCode.GIN]: '+224',
  // [CountryCode.GLP]: '+590',
  [CountryCode.GMB]: '+220',
  [CountryCode.GNB]: '+245',
  [CountryCode.GNQ]: '+240',
  [CountryCode.GRC]: '+30',
  [CountryCode.GRD]: '+1473',
  [CountryCode.GRL]: '+299',
  [CountryCode.GTM]: '+502',
  // [CountryCode.GUF]: '+594',
  [CountryCode.GUM]: '+1671',
  [CountryCode.GUY]: '+592',
  [CountryCode.HKG]: '+852',
  // [CountryCode.HMD]: '',
  [CountryCode.HND]: '+504',
  [CountryCode.HRV]: '+385',
  [CountryCode.HTI]: '+509',
  [CountryCode.HUN]: '+36',
  [CountryCode.IDN]: '+62',
  [CountryCode.IMN]: '+44',
  [CountryCode.IND]: '+91',
  [CountryCode.IOT]: '+246',
  [CountryCode.IRL]: '+353',
  [CountryCode.IRN]: '+98',
  [CountryCode.IRQ]: '+964',
  [CountryCode.ISL]: '+354',
  [CountryCode.ISR]: '+972',
  [CountryCode.ITA]: '+39',
  [CountryCode.JAM]: '+1876',
  // [CountryCode.JEY]: '+44',
  [CountryCode.JOR]: '+962',
  [CountryCode.JPN]: '+81',
  [CountryCode.KAZ]: '+7',
  [CountryCode.KEN]: '+254',
  [CountryCode.KGZ]: '+996',
  [CountryCode.KHM]: '+855',
  [CountryCode.KIR]: '+686',
  [CountryCode.KNA]: '+1869',
  [CountryCode.KOR]: '+82',
  [CountryCode.KWT]: '+965',
  [CountryCode.LAO]: '+856',
  [CountryCode.LBN]: '+961',
  [CountryCode.LBR]: '+231',
  [CountryCode.LBY]: '+218',
  [CountryCode.LCA]: '+1758',
  [CountryCode.LIE]: '+423',
  [CountryCode.LKA]: '+94',
  [CountryCode.LSO]: '+266',
  [CountryCode.LTU]: '+370',
  [CountryCode.LUX]: '+352',
  [CountryCode.LVA]: '+371',
  [CountryCode.MAC]: '+853',
  // [CountryCode.MAF]: '+590',
  [CountryCode.MAR]: '+212',
  [CountryCode.MCO]: '+377',
  [CountryCode.MDA]: '+373',
  [CountryCode.MDG]: '+261',
  [CountryCode.MDV]: '+960',
  [CountryCode.MEX]: '+52',
  [CountryCode.MHL]: '+692',
  [CountryCode.MKD]: '+389',
  [CountryCode.MLI]: '+223',
  [CountryCode.MLT]: '+356',
  [CountryCode.MMR]: '+95',
  // [CountryCode.MNE]: '+382',
  [CountryCode.MNG]: '+976',
  // [CountryCode.MNP]: '+1670',
  [CountryCode.MOZ]: '+258',
  [CountryCode.MRT]: '+222',
  [CountryCode.MSR]: '+1664',
  [CountryCode.MTQ]: '+596',
  [CountryCode.MUS]: '+230',
  [CountryCode.MWI]: '+265',
  [CountryCode.MYS]: '+60',
  // [CountryCode.MYT]: '+262',
  [CountryCode.NAM]: '+264',
  // [CountryCode.NCL]: '+687',
  [CountryCode.NER]: '+227',
  [CountryCode.NFK]: '+672',
  [CountryCode.NGA]: '+234',
  [CountryCode.NIC]: '+505',
  [CountryCode.NIU]: '+683',
  [CountryCode.NLD]: '+31',
  [CountryCode.NOR]: '+47',
  [CountryCode.NPL]: '+977',
  [CountryCode.NRU]: '+674',
  [CountryCode.NZL]: '+64',
  [CountryCode.OMN]: '+968',
  [CountryCode.PAK]: '+92',
  [CountryCode.PAN]: '+507',
  [CountryCode.PCN]: '+64',
  [CountryCode.PER]: '+51',
  [CountryCode.PHL]: '+63',
  [CountryCode.PLW]: '+680',
  [CountryCode.PNG]: '+675',
  [CountryCode.POL]: '+48',
  [CountryCode.PRI]: '+1',
  [CountryCode.PRK]: '+850',
  [CountryCode.PRT]: '+351',
  [CountryCode.PRY]: '+595',
  [CountryCode.PSE]: '+970',
  [CountryCode.PYF]: '+689',
  [CountryCode.QAT]: '+974',
  // [CountryCode.REU]: '+262',
  [CountryCode.ROU]: '+40',
  [CountryCode.RUS]: '+7',
  [CountryCode.RWA]: '+250',
  [CountryCode.SAU]: '+966',
  [CountryCode.SDN]: '+249',
  [CountryCode.SEN]: '+221',
  [CountryCode.SGP]: '+65',
  // [CountryCode.SGS]: '+500',
  // [CountryCode.SJM]: '+4779',
  [CountryCode.SLB]: '+677',
  [CountryCode.SLE]: '+232',
  [CountryCode.SLV]: '+503',
  [CountryCode.SMR]: '+378',
  [CountryCode.SOM]: '+252',
  // [CountryCode.SPM]: '+508',
  [CountryCode.SRB]: '+381',
  [CountryCode.SSD]: '+211',
  [CountryCode.STP]: '+239',
  [CountryCode.SUR]: '+597',
  [CountryCode.SVK]: '+421',
  [CountryCode.SVN]: '+386',
  [CountryCode.SWE]: '+46',
  [CountryCode.SWZ]: '+268',
  [CountryCode.SXM]: '+1721',
  [CountryCode.SYC]: '+248',
  [CountryCode.SYR]: '+963',
  [CountryCode.TCA]: '+1649',
  [CountryCode.TCD]: '+235',
  [CountryCode.TGO]: '+228',
  [CountryCode.THA]: '+66',
  [CountryCode.TJK]: '+992',
  [CountryCode.TKL]: '+690',
  [CountryCode.TKM]: '+993',
  [CountryCode.TLS]: '+670',
  [CountryCode.TON]: '+676',
  [CountryCode.TTO]: '+1868',
  [CountryCode.TUN]: '+216',
  [CountryCode.TUR]: '+90',
  [CountryCode.TUV]: '+688',
  [CountryCode.TWN]: '+886',
  [CountryCode.TZA]: '+255',
  [CountryCode.UGA]: '+256',
  [CountryCode.UKR]: '+380',
  // [CountryCode.UMI]: '+268',
  [CountryCode.URY]: '+598',
  [CountryCode.UZB]: '+998',
  [CountryCode.VAT]: '+3',
  // [CountryCode.VCT]: '+1784',
  [CountryCode.VEN]: '+58',
  [CountryCode.VGB]: '+1284',
  [CountryCode.VIR]: '+1340',
  [CountryCode.VNM]: '+84',
  [CountryCode.VUT]: '+678',
  // [CountryCode.WLF]: '+681',
  [CountryCode.WSM]: '+685',
  [CountryCode.YEM]: '+967',
  [CountryCode.ZAF]: '+27',
  [CountryCode.ZMB]: '+260',
  [CountryCode.ZWE]: '+263',
};

export default countryIDD;
