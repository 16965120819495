import React, { useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, Icon, TextInput, ToolTip } from '@multiplier/common';
import { useGetCompanyManagers } from '@multiplier/hris-member-management';
import { theme } from 'twin.macro';

import { BasicDetailsFormValues } from '../basic-details';

interface ReportingManagerSelectorProps {
  companyId?: string | null;
}

const ReportingManagerSelector: React.FC<ReportingManagerSelectorProps> = ({
  companyId,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const { control } = useFormContext<BasicDetailsFormValues>();

  const {
    fetchCompanyManagers,
    companyManagers,
    loading,
  } = useGetCompanyManagers();

  useEffect(() => {
    if (!companyId) return;
    fetchCompanyManagers(companyId);
  }, []);

  const allManagers = useMemo(
    () =>
      companyManagers?.map((manager) => ({
        label: `${manager?.companyUser?.firstName} ${manager?.companyUser?.lastName}`,
        value: manager?.id,
        title: `${manager?.companyUser?.firstName} ${manager?.companyUser?.lastName}`,
      })) ?? [],
    [companyManagers],
  );

  const noManagers = companyManagers != null && allManagers.length === 0;

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="reporting-manager">
        {t(
          'definition-phase.basic-details.reporting-manager.label',
          'Reporting Manager (optional)',
        )}
      </TextInput.Label>
      <Controller
        control={control}
        name="reportingManager"
        render={({ field: { value, onChange } }) => (
          <ComboBox
            value={value}
            onChange={onChange}
            id="reporting-manager"
            placeholder={t(
              'definition-phase.basic-details.reporting-manager.placeholder',
              'Select reporting manager',
            )}
            variant="default"
            dropdownValues={allManagers}
            disabled={loading || companyManagers == null || noManagers}
            endAdornment={
              noManagers ? (
                <ToolTip
                  content={t(
                    'definition-phase.basic-details.reporting-manager.no-data-tooltip',
                    'No managers created yet. You can add managers from Settings',
                  )}
                >
                  <Icon
                    name="info"
                    height={16}
                    width={16}
                    tw="ml-4"
                    fill={theme`colors.background`}
                  />
                </ToolTip>
              ) : null
            }
          />
        )}
      />
    </TextInput.Container>
  );
};
export default ReportingManagerSelector;
