import {
  PaymentType,
  PaymentTypeLabels,
  PaymentTypeLabelsFeatureOn,
} from 'contract-onboarding/company/components/additional-pay-form';

export const additionalPaysSupported = [
  {
    title: PaymentTypeLabels[PaymentType.VARIABLE_PERFORMANCE_BONUS],
    value: PaymentType.VARIABLE_PERFORMANCE_BONUS,
  },
  {
    title: PaymentTypeLabels[PaymentType.ALLOWANCES],
    value: PaymentType.ALLOWANCES,
  },
  {
    title: PaymentTypeLabels[PaymentType.OTHER],
    value: PaymentType.OTHER,
  },
];

export const additionalPaysSupportedFeatureOn = [
  {
    title: PaymentTypeLabels[PaymentType.VARIABLE_PERFORMANCE_BONUS],
    value: PaymentType.VARIABLE_PERFORMANCE_BONUS,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.MOBILE_AND_PHONE_ALLOWANCE],
    value: PaymentType.MOBILE_AND_PHONE_ALLOWANCE,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.INTERNET_ALLOWANCE],
    value: PaymentType.INTERNET_ALLOWANCE,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.DE_MINIMIS_ALLOWANCE],
    value: PaymentType.DE_MINIMIS_ALLOWANCE,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.ALLOWANCES],
    value: PaymentType.ALLOWANCES,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.OTHER],
    value: PaymentType.OTHER,
  },
];
