import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { format, isValid as isValidDate, parse } from 'date-fns';

import DatePicker from '../../../../../date-picker';
import { TableFilterConfigValue } from '../../../../types';
import Label from '../label';

const MonthFilterInput: React.FC<{
  value: string;
  onChange: (val: string) => void;
  filterConfig?: TableFilterConfigValue;
}> = ({ value, onChange, filterConfig }) => {
  const { t } = useTranslation('common.table');

  return (
    <>
      <Label>
        {filterConfig?.prefix ?? t('filters.matching', 'Matching:')}
      </Label>
      <DatePicker
        tw="mt-extra-small"
        data-testid="table-filter-month"
        value={value}
        onChange={(dateString) => {
          const date = parse(dateString, 'yyyy-MM-dd', new Date());
          if (isValidDate(date)) {
            onChange(format(date, 'yyyy-MM'));
          } else {
            onChange(date.toISOString());
          }
        }}
        loading={false}
        monthSelector
        placeholder="Month"
      />
    </>
  );
};

export default MonthFilterInput;
