/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

import 'twin.macro';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';

import { notEmpty } from 'app/utils/array';
import countryLabels from 'common/constants/country-labels';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import OnboardingSpecialistCard from 'contract-onboarding/components/onboarding-specialist-card';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import {
  useGetCountryLegalRequirementsForMember,
  useSubmitMemberBasicDetails,
} from 'contract-onboarding/member/hooks';

import {
  Contract,
  ContractOnboardingStep,
  ContractType,
  CountryCode,
  MaritalStatus,
  Maybe,
  useGetCountryStatesLazyQuery,
  useGetMemberLazyQuery,
} from '__generated__/graphql';

import AppFeature from '../../../../../../app/features';
import {
  countryWithStateList,
  getStateLabel,
  getStateTitle,
} from '../../../../../company/services/eligibility-states';
import stepConfig from '../../step-config';
import AddressInput from './components/address-input';
import CityZipCodeInput from './components/city-zipcode-input';
import CountryStateSelector from './components/country-state-selector';
import DOBInput from './components/dob-input';
import FullLegalNameInput from './components/full-legal-name-input';
import LegalDataRequirements from './components/legal-data-requirements';
import MaritalStatusSelector from './components/marital-status-selector';
import NationalitySelector from './components/nationality-selector';
import PhoneNumberInput from './components/phone-number-input';
import { mapToFormValues } from './member-basic-details-mapper';
import { useGetMemberBasicDetailsSchema } from './member-basic-details-validation-schema';

export interface MemberBasicDetailsFormValues {
  legalName: Maybe<string>;
  nationality: Maybe<CountryCode>;
  dateOfBirth: Date | null;
  phone: Maybe<string>;
  legalData: {
    key: Maybe<string>;
    value: Maybe<string>;
    identifier: Maybe<string>;
  }[];
  city: Maybe<string>;
  postalCode: Maybe<string>;
  currentAddress: Maybe<string>;
  countryCode: Maybe<string>;
  addressLine2: Maybe<string>;
  country?: Maybe<CountryCode>;
  state?: Maybe<string>;
  maritalStatus?: Maybe<MaritalStatus>;
}

const BasicDetailsView: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const [
    getMember,
    { data: { member } = { member: {} }, loading: loadingContract },
  ] = useGetMemberLazyQuery();

  const contract = useMemo(() => member?.contracts?.[0], [member]);

  const [getCountryStates, { data }] = useGetCountryStatesLazyQuery();

  const sorOnboardingContext = useSorOnboardingContext({
    type: contract?.type,
    companyId: contract?.company?.id,
    legalEntityId: contract?.legalEntityId,
  });

  const {
    getRequirements,
    loading: loadingCountryRequirements,
    requirements,
  } = useGetCountryLegalRequirementsForMember({
    workStatus: contract?.workStatus,
    contractType: contract?.type,
  });

  const showMemberLegalData = !onboardingSteps?.includes(
    ContractOnboardingStep.MEMBER_LEGAL_DETAILS,
  );

  const {
    onSubmit,
    loading: loadingUpdateMember,
  } = useSubmitMemberBasicDetails();

  const schema = useGetMemberBasicDetailsSchema(
    showMemberLegalData ?? false,
    requirements?.memberLegalDataRequirements,
    requirements?.memberLegalDataDefinitionKeysToShow,
    contract as Contract,
  );

  const legalDataRequirements = useMemo(
    () => Object.values(requirements?.memberLegalDataRequirements),
    [requirements],
  );

  const methods = useForm<MemberBasicDetailsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = methods;

  useEffect(() => {
    getMember();
  }, []);

  useEffect(() => {
    if (!contract?.country || !contract?.type) {
      return;
    }
    if (
      contract?.type === ContractType.FREELANCER ||
      contract?.type === ContractType.CONTRACTOR
    ) {
      getRequirements(contract.country, contract.type);
      return;
    }
    getRequirements(contract.country);
  }, [contract]);

  useDeepCompareEffect(() => {
    if (member?.__typename !== 'Member') {
      return;
    }

    const formValues = mapToFormValues(
      member,
      requirements,
    ) as MemberBasicDetailsFormValues;

    reset(formValues, { keepErrors: true });
  }, [member, requirements]);

  const handleSave = (formData: MemberBasicDetailsFormValues) =>
    onSubmit(formData, member?.id, showMemberLegalData);

  const excludedCountries = useFeature(
    'contract-onboarding-countries-exclusion-list',
  )?.value?.MEMBER_ONBOARDING;

  const [selectedCountry] = useWatch({
    control,
    name: ['country'],
  });

  useEffect(() => {
    if (selectedCountry) {
      getCountryStates({
        variables: { country: selectedCountry as CountryCode },
      });
    }
    if (!countryWithStateList.includes(selectedCountry as CountryCode)) {
      reset({ ...getValues(), state: null });
    }
    if (member && selectedCountry === CountryCode.CHN) {
      reset({
        ...getValues(),
        state: member.addresses?.[0]?.city,
      });
    }
  }, [selectedCountry]);

  const countryDropdownValues = useMemo(
    () =>
      Object.values(CountryCode)
        .filter((c) => !excludedCountries?.includes(c))
        .map((c) => ({
          label: countryLabels[c],
          value: c,
          title: c,
        })),
    [excludedCountries],
  );

  const stateDropdownValues = useMemo(
    () =>
      (data?.country?.countryStates &&
        data.country.countryStates.filter(notEmpty).map((c) => ({
          label: getStateLabel(data?.country?.code, c),
          value: c?.code ?? '',
          title: getStateTitle(data?.country?.code, c),
          key: c?.code ?? '',
        }))) ??
      [],
    [data],
  );

  const showOnboardingSpecialist = useFeature(
    AppFeature.SHOW_ONBOARDING_SPECIALIST,
  )?.on;

  return (
    <div>
      {showOnboardingSpecialist &&
        !sorOnboardingContext.isSorOnboardingEnabled && (
          <div tw="mb-base">
            <OnboardingSpecialistCard
              contractId={contract?.id || ''}
              showHint={false}
              showTitleOnly={false}
            />
          </div>
        )}
      <StepLayout data-testid="basic-details-view">
        <FormProvider {...methods}>
          <FormLayout onSubmit={handleSubmit(handleSave)}>
            <FormCard>
              <FullLegalNameInput methods={methods} />
              <div tw="grid grid-cols-2 gap-x-base gap-y-large">
                <NationalitySelector
                  requirement={
                    requirements?.memberLegalDataRequirements?.nationality
                  }
                />
                <DOBInput
                  methods={methods}
                  requirement={
                    requirements?.memberLegalDataRequirements?.dateOfBirth
                  }
                  contract={contract as Contract}
                />
                <MaritalStatusSelector
                  methods={methods}
                  requirement={
                    requirements?.memberLegalDataRequirements?.maritalStatus
                  }
                />
              </div>
              <div tw="grid grid-cols-2 gap-x-base gap-y-large">
                {showMemberLegalData && legalDataRequirements.length > 0 && (
                  <LegalDataRequirements
                    methods={methods}
                    legalDataRequirements={legalDataRequirements}
                    memberLegalDataDefinitionKeysToShow={
                      requirements?.memberLegalDataDefinitionKeysToShow
                    }
                    contractCountry={contract?.country}
                    contractType={contract?.type}
                  />
                )}
              </div>
              <PhoneNumberInput
                methods={methods}
                requirement={
                  requirements?.memberLegalDataRequirements?.phoneNumber
                }
                contractType={contract?.type}
              />
              <AddressInput
                methods={methods}
                requirementMap={requirements?.memberLegalDataRequirements}
                contractType={contract?.type}
              />
              <CountryStateSelector
                methods={methods}
                requirementMap={requirements?.memberLegalDataRequirements}
                allCountriesDropdownValues={countryDropdownValues}
                stateDropdownValues={stateDropdownValues}
                contractType={contract?.type}
              />
              <CityZipCodeInput
                methods={methods}
                requirementMap={requirements?.memberLegalDataRequirements}
                contractType={contract?.type}
              />
            </FormCard>
            <StepNavigationFooter
              disabled={
                !isValid || loadingContract || loadingCountryRequirements
              }
              submitLoading={loadingUpdateMember}
              currentStep={currentStep}
              onboardingSteps={onboardingSteps}
              contractId="test"
              stepConfig={stepConfig}
            />
          </FormLayout>
        </FormProvider>
      </StepLayout>
    </div>
  );
};
export default BasicDetailsView;
