import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  LegalDocumentInput,
  useGetMemberIdQuery,
  useUpdateLegalDocumentsMutation,
} from '__generated__/graphql';

import { DocumentFormParams } from '../pages/onboarding/pages/legal-documents';
import stepConfig from '../pages/onboarding/step-config';

const useUpdateDocuments = (): {
  onSubmit: (documents: DocumentFormParams) => void;
  loading: boolean;
} => {
  const { data: memberResponse } = useGetMemberIdQuery();
  const { t } = useTranslation('contract-onboarding.member');
  const navigate = useNavigate();
  const [
    updateLegalDocumentsMutation,
    { loading },
  ] = useUpdateLegalDocumentsMutation({
    onCompleted() {
      successNotification(
        '',
        t('legal-document.updated-success-message', 'Documents updated'),
        false,
      );
    },
    onError() {
      errorNotification(
        '',
        t('legal-document.update-error-message', 'Failed to update documents'),
        false,
      );
    },
  });

  const onSubmit = async (documents: DocumentFormParams) => {
    const documentMap = documents.legalDocuments
      .filter((i) => !i.readonly)
      .map((i) =>
        i.acceptMultiple
          ? { key: i.key, documents: i.value }
          : { key: i.key, documents: [i.value] },
      );
    const member = await updateLegalDocumentsMutation({
      variables: {
        id: String(memberResponse?.member?.id),
        data: documentMap as LegalDocumentInput | LegalDocumentInput[],
      },
    });

    const current =
      member?.data?.memberUpdateDocument?.contracts?.[0]?.onboarding?.current;

    if (current && stepConfig[current]) {
      navigate(stepConfig[current].directLink());
    }
  };

  return {
    onSubmit,
    loading,
  };
};

export default useUpdateDocuments;
