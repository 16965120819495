import { gql } from '@apollo/client';

export default gql`
  fragment offboardingOps on ContractOffboarding {
    id
    contractId
    type
    initiatedOn
    initiatedBy
    lastWorkingDay
    notes
    documents {
      ...offboardingDocument
    }
    terminationClauses
    terminationReason
    severancePay
    verificationChecklist {
      id
      contractId
      contractOffboardingId
      pendingPaySupplements
      pendingExpenses
      insurance
      finalSettlementProcessed
    }
    status
  }

  fragment offboardingDocument on ContractOffboardingDocument {
    contractId
    contractOffboardingId
    id
    name
    type
    file {
      id
      name
      downloadUrl
      extension
      contentType
    }
  }
`;
