import { gql } from '@apollo/client';

export default gql`
  query GetSalaryCalculationParams {
    salaryCalculationParams {
      country
      states
      rateType
      currency
      frequency
      payableType
    }
  }
`;
