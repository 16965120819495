import growthBook from '@multiplier/growthbook';

import AppFeature from 'app/features';
import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'timesheets',
  permission: 'timesheets',
  folder: 'timesheets/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Timesheets',
    route: 'timesheets',
    sort: 1,
    home: false,
    group: CompanyNavGroups.ADMINISTRATION,
  },
  enabled: () => !growthBook.isOn(AppFeature.TIMESHEET_DISABLED),
};

export default config;
