/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { DropdownValue } from '@multiplier/common';

import { parseKeys } from 'app/utils/object';
import { useGetAndGroupDeductionDefinitions } from 'contract-onboarding/hooks';

import {
  Contract,
  ContractType,
  DeductionDefinition,
} from '__generated__/graphql';

import { FormCard } from '../../../components/layout';
import DeductionsGroups from './deductions-groups';

type ContributionsSectionComponent = React.FC<{
  country: Contract['country'];
  state: Contract['countryStateCode'];
  contractType: Contract['type'];
  contractTerm: Contract['term'];
  currencyOptions: DropdownValue[];
  postProbationBaseSalary?: number;
}>;

const ContributionsSection: ContributionsSectionComponent = ({
  country,
  state,
  contractType,
  contractTerm,
  currencyOptions,
  postProbationBaseSalary,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const { definitions } = useGetAndGroupDeductionDefinitions({
    country,
    state,
    contractType,
    contractTerm,
  });

  if (
    !definitions ||
    parseKeys(definitions).length === 0 ||
    contractType !== ContractType.EMPLOYEE
  ) {
    return null;
  }

  return (
    <FormCard>
      <p tw="font-semibold text-p text-text-primary">
        {t('deduction.title', 'Contributions')}
      </p>
      <DeductionsGroups
        deductionDefinitions={definitions as DeductionDefinition[]}
        currencyOptions={currencyOptions}
        countryCode={country}
        postProbationBaseSalary={postProbationBaseSalary}
      />
    </FormCard>
  );
};

export default ContributionsSection;
