import React, { useContext, useMemo, useState } from 'react';

import tw, { TwStyle, css, styled, theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: boolean;
  password?: boolean;
  currency?: boolean;
  divStyles?: TwStyle;
  inputStyles?: TwStyle;
  units?: string;
  prepend?: React.ReactElement;
  prependStyles?: TwStyle;
  append?: React.ReactElement;
  value?: any;
}
// should not use BaseTextInput directly, use TextInput instead
export const BaseTextInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      password,
      currency,
      divStyles,
      inputStyles,
      units,
      autoComplete,
      prepend,
      append,
      // remove error variable from rest of props
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error,
      ...props
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState('');
    const [amount, setAmount] = useState('');
    const [visibleInput, setVisibleInput] = useState(true);
    const { isNewThemeApplied } = useContext(ThemeContext);

    const iconFillColor = useMemo(() => {
      if (!isNewThemeApplied) {
        return value.length !== 0
          ? theme`colors.foreground`
          : theme`colors.grey03`;
      }
      return value.length !== 0
        ? theme`colors.text-primary`
        : theme`colors.text-tertiary`;
    }, [isNewThemeApplied, value]);

    return password ? (
      <div css={[tw`relative flex flex-row items-center`, divStyles]}>
        <input
          data-testid="password-input"
          tw="flex-grow"
          type={visible ? 'text' : 'password'}
          ref={ref}
          {...props}
          onChange={(e) => {
            setValue(e.target.value);
            if (props.onChange) props.onChange(e);
          }}
        />
        <button
          data-testid="visibility-toggle"
          css={[
            tw`absolute right-base`,
            tw`focus:(ring-transparent outline-none)`,
          ]}
          type="button"
          onClick={() => setVisible((prev) => !prev)}
          tabIndex={-1}
        >
          {visible ? (
            <Icon
              data-testid="visible-icon"
              fill={iconFillColor}
              name="eye-slash"
            />
          ) : (
            <Icon data-testid="hidden-icon" fill={iconFillColor} name="eye" />
          )}
        </button>
      </div>
    ) : currency ? (
      <div
        role="textbox"
        tabIndex={0}
        onKeyDown={() => setVisibleInput(true)}
        onClick={() => setVisibleInput(true)}
        css={[tw`relative flex flex-row items-center`, divStyles]}
      >
        {prepend && <div tw="absolute left-small">{prepend}</div>}
        {!visibleInput && (
          <div css={[tw`text-ps pl-extra-small`, prepend && tw`pl-48`]}>
            {amount}
          </div>
        )}
        <input
          step="any"
          css={[
            tw`flex-grow appearance-none`,
            css`
              ::-webkit-outer-spin-button,
              ::-webkit-inner-spin-button {
                --webkit-appearance: none;
                display: none;
              }
            `,
            visibleInput ? tw`visible` : tw`invisible`,
          ]}
          ref={ref}
          autoComplete={autoComplete ? 'off' : undefined}
          {...props}
          onBlur={(e) => {
            if (props.onBlur) {
              setAmount(
                e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              );
              if (e.target.value) setVisibleInput(false);
            }
          }}
        />
        <div tw="absolute right-base flex items-center justify-center">
          {units && (
            <span
              css={[
                tw`text-text-secondary text-ps`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {units}
            </span>
          )}
          {append && (
            <div
              css={[
                tw`text-text-secondary text-ps`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {append}
            </div>
          )}
        </div>
      </div>
    ) : (
      <div css={[tw`relative flex flex-row items-center`, divStyles]}>
        {prepend && <div tw="absolute left-base">{prepend}</div>}
        <input
          css={[
            tw`flex-grow appearance-none`,
            css`
              ::-webkit-outer-spin-button,
              ::-webkit-inner-spin-button {
                --webkit-appearance: none;
                display: none;
              }
            `,
            inputStyles,
          ]}
          ref={ref}
          autoComplete={autoComplete ? 'off' : undefined}
          {...props}
        />
        <div tw="absolute right-base flex items-center justify-center">
          {units && (
            <span
              css={[
                tw`text-text-secondary text-ps`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {units}
            </span>
          )}
          {append && (
            <div
              css={[
                tw`text-text-secondary text-ps`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {append}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export enum TextInputLabelVariant {
  BASE = 'BASE',
  LARGE = 'LARGE',
}

const Label = styled.label(
  ({ variant }: { variant?: TextInputLabelVariant }) => [
    tw`text-text-primary font-medium text-ps block`,
    variant === TextInputLabelVariant.LARGE && tw`text-p`,
  ],
);

const Error: React.FC<React.PropsWithChildren<any>> = ({
  children,
  iconInverse,
  iconProps,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div tw="flex flex-row items-center" {...props}>
      <Icon
        name={iconInverse ? 'error-reverse' : 'error'}
        tw="mr-6 shrink-0"
        fill={
          isNewThemeApplied ? theme`colors.icon-negative` : theme`colors.error`
        }
        {...iconProps}
      />
      <span
        css={[
          tw`text-text-negative text-ps`,
          !isNewThemeApplied && tw`text-error`,
        ]}
      >
        {children}
      </span>
    </div>
  );
};

const Container = tw.div`flex flex-col gap-y-small`;

const TextInput = styled(BaseTextInput)(
  ({ error, prepend, prependStyles, append, disabled }) => {
    const { isNewThemeApplied } = useContext(ThemeContext);
    const variantStyles = isNewThemeApplied
      ? {
          default: tw`text-text-primary border-border-primary`,
          error: tw`border-border-negative`,
        }
      : {
          default: tw`text-foreground border-grey03`,
          error: tw`text-error border-error bg-bgError shadow-error`,
        };

    return [
      tw`p-small rounded-6 text-ps border`,
      append && tw`pr-48`,
      tw`placeholder:text-text-tertiary`,
      tw`focus:(ring-0 border border-border-high-contrast outline-none)`,
      error ? variantStyles.error : variantStyles.default,
      prepend && (prependStyles || tw`pl-48`),
      disabled && tw`bg-background-secondary text-text-tertiary`,
      !isNewThemeApplied &&
        tw`placeholder:text-grey02 focus:(ring-1 ring-primaryBlue500 border-primaryBlue500 outline-none)`,
      !isNewThemeApplied &&
        disabled &&
        tw`bg-slateGrey200 text-slateGrey500 border border-grey03 opacity-50`,
    ];
  },
);

const Helper = styled.span(() => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return [
    tw`text-ps text-text-tertiary block`,
    !isNewThemeApplied && tw`text-grey02`,
  ];
});

const Warning: React.FC<
  React.PropsWithChildren<{ iconFillColor?: string }>
> = ({ children, iconFillColor, ...props }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <div tw="flex flex-row items-center" {...props}>
      <Icon
        name="error"
        tw="mr-small min-w-[20px]"
        fill={
          iconFillColor ??
          (isNewThemeApplied
            ? theme`colors.text-primary`
            : theme`colors.warning02`)
        }
      />
      <span
        css={[
          tw`text-text-primary text-ps`,
          !isNewThemeApplied && tw`text-warning02`,
        ]}
      >
        {children}
      </span>
    </div>
  );
};

export default Object.assign(TextInput, {
  Error,
  Label,
  Container,
  Helper,
  Warning,
});
