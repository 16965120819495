/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { endOfDay, isPast } from 'date-fns';

import { ContractType } from '__generated__/graphql';

import { BasicDetailsFormValues, InputInfo } from '../basic-details';

const StartDateWarning: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  const { watch } = useFormContext<BasicDetailsFormValues>();

  const contractType = watch('contractType');
  const startOn = watch('startOn');

  if (
    contractType === ContractType.EMPLOYEE &&
    startOn &&
    isPast(endOfDay(new Date(startOn)))
  ) {
    return (
      <InputInfo>
        {t(
          'definition-phase.basic-details.start-date-warning.label',
          'Warning: The start date of this employee has passed',
        )}
      </InputInfo>
    );
  }

  return null;
};

export default StartDateWarning;
