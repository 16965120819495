import React, { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { userVar } from '@multiplier/user';

import {
  BulkOnboardingContext,
  Company,
  ContractType,
  LegalEntity,
} from '__generated__/graphql';
import 'twin.macro';

import useSubmitCompanyEntityBulkOnboarding from '../../hooks/submit-company-entity-bulk-onboarding-upload';
import useValidateCompanyEntityBulkOnboarding from '../../hooks/validate-company-entity-bulk-onboarding';
import { FormCard } from '../layout';
import ActionBar from './components/action-bar';
import HeaderSection from './components/header-section';
import ReviewSubmittedDataSection from './components/review-submitted-data-section';
import TypeOfDataSection from './components/type-of-data-section';
import UploadDataSection from './components/upload-data-section';
import useBulkUploadFormValidation from './validation';

export const uploadDataLabel: { [key: string]: string } = {
  [BulkOnboardingContext.HRIS_PROFILE_DATA]: 'People Data',
  [BulkOnboardingContext.GLOBAL_PAYROLL]: 'Payroll Data',
};
export interface BulkUploadFormValues {
  bulkOnboardingUploadDataType: BulkOnboardingContext;
  legalEntityId?: string;
  files: File[];
}

const BulkUploadForm: React.FC<{
  company: Company;
  companyEntity?: LegalEntity;
  contractType?: ContractType;
  onSubmitCompletion: () => void;
}> = ({ company, companyEntity, contractType, onSubmitCompletion }) => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const [selectedCompanyEntity, setSelectedCompanyEntity] = useState<
    LegalEntity | undefined
  >(companyEntity);

  const schema = useBulkUploadFormValidation({ currentExperience: current });
  const methods = useForm<BulkUploadFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      bulkOnboardingUploadDataType: BulkOnboardingContext.HRIS_PROFILE_DATA,
    },
  });
  const { handleSubmit, watch } = methods;

  const bulkOnboardingUploadDataType = watch('bulkOnboardingUploadDataType');

  const {
    data: validateData,
    loading: loadingValidate,
    handleValidate,
  } = useValidateCompanyEntityBulkOnboarding({
    companyEntity: selectedCompanyEntity,
    company,
    contractType,
    context: bulkOnboardingUploadDataType,
  });

  const {
    loading: loadingSubmit,
    onSubmit,
  } = useSubmitCompanyEntityBulkOnboarding({
    company,
    companyEntity: selectedCompanyEntity,
    contractType,
    onSubmitCompletion,
    context: bulkOnboardingUploadDataType,
  });

  return (
    <FormProvider {...methods}>
      <form tw="flex flex-col gap-y-large" onSubmit={handleSubmit(onSubmit)}>
        <FormCard>
          <HeaderSection />
          <TypeOfDataSection />
          <UploadDataSection
            company={company}
            companyEntity={selectedCompanyEntity}
            loadingValidate={loadingValidate}
            handleValidate={handleValidate}
            onCompanyEntityChange={(newCompanyEntity) =>
              setSelectedCompanyEntity(newCompanyEntity)
            }
          />
          {validateData?.bulkOnboardingValidate && (
            <ReviewSubmittedDataSection
              validationResult={validateData?.bulkOnboardingValidate}
            />
          )}
        </FormCard>
        <ActionBar
          loading={loadingSubmit || loadingValidate}
          disabled={!!validateData?.bulkOnboardingValidate?.errorEmployeeCount}
        />
      </form>
    </FormProvider>
  );
};

export default BulkUploadForm;
