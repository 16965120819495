import growthBook from '@multiplier/growthbook';
import { TFunction } from 'i18next';
import * as yup from 'yup';

import AppFeature from 'app/features';

import { Contract, ContractType, CountryCode } from '__generated__/graphql';

interface ValidationContext {
  contract?: {
    type?: ContractType;
    country?: CountryCode;
  };
  features?: { [key: string]: boolean };
}

interface RenderProps {
  show?: boolean;
  required?: boolean;
}

const isEnabled = (context: ValidationContext) =>
  !(
    context.contract?.type &&
    [ContractType.HR_MEMBER].includes(context.contract?.type) &&
    context.features &&
    (context.features[AppFeature.SINGLE_ONBOARDING] ||
      context.features[AppFeature.SOR_ONBOARDING])
  );

const isRequired = (context: ValidationContext) =>
  context.contract?.type &&
  [ContractType.CONTRACTOR].includes(context.contract.type);

const contractScopeValidationsMap: any = {
  '': {
    isEnabled,
    isRequired,
  },
  [CountryCode.ESP.toString()]: {
    isEnabled: (context: ValidationContext) =>
      context.contract?.type &&
      [ContractType.EMPLOYEE].includes(context.contract?.type)
        ? false
        : isEnabled(context),
    isRequired: (context: ValidationContext) =>
      context.contract?.type &&
      [ContractType.EMPLOYEE].includes(context.contract?.type)
        ? false
        : isRequired(context),
  },
  [CountryCode.MEX.toString()]: {
    isEnabled: (context: ValidationContext) =>
      (context.contract?.type &&
        [ContractType.EMPLOYEE].includes(context.contract?.type)) ||
      isEnabled(context),
    isRequired: (context: ValidationContext) =>
      (context.contract?.type &&
        [ContractType.EMPLOYEE].includes(context.contract?.type)) ||
      isRequired(context),
  },
  [CountryCode.FRA.toString()]: {
    isEnabled: (context: ValidationContext) =>
      (context.contract?.type &&
        [ContractType.EMPLOYEE].includes(context.contract?.type)) ||
      isEnabled(context),
    isRequired: (context: ValidationContext) =>
      (context.contract?.type &&
        [ContractType.EMPLOYEE].includes(context.contract?.type)) ||
      isRequired(context),
  },
};

export function buildSchemaForJobScope(
  t: TFunction,
  context: ValidationContext,
): yup.StringSchema<string | null | undefined> {
  const config =
    contractScopeValidationsMap[context.contract?.country?.toString() ?? ''] ||
    contractScopeValidationsMap[''];

  let schema: yup.StringSchema<string | null | undefined> = yup.string();

  if (config.isRequired(context)) {
    schema = schema.required(
      t(
        'definition-phase.basic-details.jd.required.error',
        'Job Description is required',
      ),
    );
  }

  schema = schema.max(
    2500,
    t(
      'definition-phase.basic-details.jd.error',
      'Job Description must be less than 2500 characters',
    ),
  );

  if (!config.isEnabled(context)) {
    schema = schema.nullable();
  }

  return schema;
}

export function buildValidationContextForJobScope(params: {
  contract?: Contract | undefined;
}): ValidationContext {
  return {
    contract: {
      country: params?.contract?.country || undefined,
      type: params?.contract?.type || undefined,
    },
    features: {
      [AppFeature.SINGLE_ONBOARDING]: growthBook.isOn(
        AppFeature.SINGLE_ONBOARDING,
      ),
      [AppFeature.SOR_ONBOARDING]: growthBook.isOn(AppFeature.SOR_ONBOARDING),
    },
  };
}

export function buildRenderPropsForJobScope({
  schema,
  defaultValues,
}: {
  schema?:
    | yup.ObjectSchema<{
        scope?: yup.StringSchema<string | null | undefined>;
      }>
    | undefined;
  defaultValues?: {
    scope?: string | null | undefined;
  };
}): RenderProps {
  return {
    show: !schema?.fields?.scope?.spec.nullable || !!defaultValues?.scope,
    required: !!schema?.fields?.scope?.tests.some(
      (m) => m.OPTIONS.name === 'required',
    ),
  };
}
