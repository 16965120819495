const routes = {
  root: 'member-onboard',
  definitionPhase: {
    toString: (): string => 'definition',
    eligibility: 'eligibility',
    basicDetails: 'basic-details',
    compensation: 'compensation',
    insurance: 'insurance',
    compliance: 'compliance',
    contract: 'contract',
    bankDetails: 'bank-details',
  },
  onboardingPhase: {
    toString: (): string => 'onboarding',
  },
  engagementPhase: {
    toString: (): string => 'engagement',
  },
  bulkOnboarding: {
    toString: (): string => 'bulk-onboarding',
  },
  entitiesBulkOnboarding: {
    toString: (): string => 'entities/:id/bulk-onboarding',
  },
};

export default routes;
