import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { useGetLatestValidBirthdate } from '@multiplier/contract-onboarding';
import {
  endOfDay,
  isAfter,
  isEqual,
  isSaturday,
  isSunday,
  set,
  startOfDay,
} from 'date-fns';
import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import uniqBy from 'lodash/fp/uniqBy';
import * as yup from 'yup';
import { BaseSchema } from 'yup';
import { ResolveOptions } from 'yup/lib/Condition';
import Lazy from 'yup/lib/Lazy';

import AppFeature from 'app/features';
import countryLabels from 'common/constants/country-labels';
import { countryWithStateList } from 'contract-onboarding/company/services/eligibility-states';
import useContractDetailRestrictions from 'contract-onboarding/hooks/contract-detail-restrictions';

import {
  AutoCompleteField,
  Contract,
  ContractTerm,
  ContractType,
  CountryCode,
  CountryCompliance,
  DataFieldDefinition,
  DateField,
  DropDownField,
  Gender,
  TextField,
  WorkShiftInput,
} from '__generated__/graphql';

import useEndDateRestrictions from '../../../../hooks/end-date-restrictions';
import useGetContractStartDateLimit from '../../../../hooks/startdate-limit';
import getWorkShiftSchema from '../../../../services/work-shift-schema';
import {
  BasicDetailsFormValues,
  EmploymentStatus,
  PreDataRequirementType,
} from './basic-details';
import {
  buildSchemaForJobScope,
  buildValidationContextForJobScope,
} from './validation-schema/job-scope-validation-schema';

interface ContextFromType {
  from: {
    schema: BaseSchema;
    value: BasicDetailsFormValues;
  }[];
}

const useBasicDetailsValidationSchema = ({
  dataRequirements,
  country,
  countryStateCode,
  contractType,
  workStatus,
  legalEntityId,
  hasSupportedJobPositions = false,
}: {
  dataRequirements: CountryCompliance['memberDataRequirements'];
  country: Contract['country'];
  countryStateCode: Contract['countryStateCode'];
  contractType: Contract['type'];
  workStatus?: Contract['workStatus'];
  legalEntityId?: Contract['legalEntityId'];
  hasSupportedJobPositions?: boolean;
}): {
  memberDataRequirementFields: DataFieldDefinition[];
  schema: yup.ObjectSchema<{
    contractType: yup.StringSchema;
    countryWorkStatus: yup.StringSchema<string | null | undefined>;
    countryStateCode: yup.StringSchema<string | null | undefined>;
    firstName: yup.StringSchema;
    lastName: yup.StringSchema;
    gender: yup.StringSchema;
    employeeId: yup.StringSchema<string | null | undefined>;
    taxResidency: yup.StringSchema;
    email: yup.StringSchema;
    jobTitle: yup.StringSchema;
    contractTerm: yup.StringSchema;
    employmentStatus: yup.StringSchema<string | null | undefined>;
    startOn: yup.DateSchema<Date | null | undefined>;
    endOn: yup.DateSchema<Date | null | undefined>;
    scope: yup.StringSchema<string | null | undefined>;
    preDataRequirements: yup.ArraySchema<
      yup.ObjectSchema<{
        key: yup.StringSchema;
        value: Lazy<
          | yup.StringSchema<string | null | undefined>
          | yup.NumberSchema
          | yup.DateSchema
        >;
        type: yup.StringSchema;
      }>
    >;
    workShift: yup.SchemaOf<WorkShiftInput>;
  }>;
} => {
  const { t } = useTranslation('contract-onboarding.common');

  const { validateJobTitle } = useContractDetailRestrictions(country);

  const { earliestStartDate, limitedHolidays } = useGetContractStartDateLimit({
    country,
    countryStateCode,
    contractType,
    workStatus,
    legalEntityId,
  });

  const { getMaximumEndDate } = useEndDateRestrictions({
    selectedCountry: country,
    selectedCountryState: countryStateCode,
    selectedContractType: contractType,
  });

  const earliestStartDateOrCurrentDate = earliestStartDate || new Date();

  const memberDataRequirementFields =
    flow(
      compact,
      uniqBy((dataField) => (dataField as DataFieldDefinition).key),
    )(dataRequirements) ?? [];
  const requirementsMap = memberDataRequirementFields?.reduce(
    (acc, f) => (f.key ? { ...acc, [f.key]: f } : acc),
    {} as { [key: string]: DataFieldDefinition },
  );

  const EMAIL_REGX = /^(([^é"£<>()[\]\\.,;:\s@"]+(\.[^-é"£<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([^-é"£<>()[\]\\.,;:\s@"]+[a-zA-Z\-0-9]+[^-é"£<>()[\]\\.,;:\s@"]*\.)+[a-zA-Z]{2,}))$/;

  const isSorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;
  const singleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;

  const latestValidBirthdate = useGetLatestValidBirthdate();

  const schema = yup.object().shape({
    contractType: yup.string().oneOf(Object.values(ContractType)).required(),
    countryWorkStatus: yup
      .string()
      .nullable()
      .when('contractType', {
        is: (type: ContractType) =>
          type !== ContractType.FREELANCER &&
          type !== ContractType.CONTRACTOR &&
          !(
            type === ContractType.HR_MEMBER &&
            (isSorOnboardingEnabled || singleOnboardingEnabled)
          ),
        then: yup.string().required(),
      }),
    countryStateCode: yup
      .string()
      .nullable()
      .test(
        'state-required-if',
        t('definition-phase.state.required', 'State is required'),
        (value, ctx) =>
          ctx.parent.contractType !== ContractType.FREELANCER &&
          !(
            ctx.parent.contractType === ContractType.HR_MEMBER &&
            (isSorOnboardingEnabled || singleOnboardingEnabled)
          ) &&
          ctx.parent.taxResidency === CountryCode.USA
            ? yup.string().required().isValid(value)
            : true,
      ),
    firstName: yup
      .string()
      .required(
        t(
          'definition-phase.basic-details.first-name.error',
          'First Name is required field',
        ),
      ),
    lastName: yup.string(),
    gender: yup.string().oneOf(Object.values(Gender)).required(),
    taxResidency: yup.string().oneOf(Object.values(CountryCode)).required(),
    employeeId: yup.string().when('contractType', {
      is: (type: ContractType) =>
        !isSorOnboardingEnabled &&
        type === ContractType.HR_MEMBER &&
        singleOnboardingEnabled,
      then: yup
        .string()
        .required(
          t(
            'definition-phase.basic-details.employeeId.error',
            'Employee Id is a required field',
          ),
        ),
      otherwise: yup.string().nullable(),
    }),
    email: yup
      .string()
      .matches(
        EMAIL_REGX,
        t(
          'definition-phase.basic-details.invalid-email.error',
          'Invalid email address',
        ),
      )
      .required(
        t(
          'definition-phase.basic-details.email.error',
          'Email is required field',
        ),
      ),
    jobTitle: yup
      .string()
      .required(
        t(
          'definition-phase.basic-details.job-title.error',
          'Job Title is required field',
        ),
      )
      .test(
        'invalid-job-title',
        validateJobTitle({ hasSupportedJobPositions, contractType }),
      ),
    contractTerm: yup
      .string()
      .oneOf(Object.values(ContractTerm))
      .when('contractType', {
        is: (type: ContractType) => type !== ContractType.FREELANCER,
        then: yup.string().required(),
      }),
    employmentStatus: yup
      .string()
      .oneOf(Object.values(EmploymentStatus))
      .nullable()
      .notRequired(),
    startOn: yup
      .date()
      .when('contractType', {
        is: (type: ContractType) => type === ContractType.EMPLOYEE,
        then: yup.date().min(
          set(earliestStartDateOrCurrentDate, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
        ),
      })
      .when('contractType', {
        is: (type: ContractType) =>
          type === ContractType.EMPLOYEE || type === ContractType.HR_MEMBER,
        then: yup
          .date()
          .test(
            'not-weekends',
            t(
              'basic-details.leave-entitlement.not-weekends',
              'The selected date falls on the weekend. Please select the closest working day.',
            ),
            (value) => {
              if (!value) {
                return true;
              }
              return !isSaturday(value) && !isSunday(value);
            },
          ),
      })
      .test(
        'not-holidays',
        t('basic-details.leave-entitlement.not-holidays', {
          defaultValue:
            'The selected date is a Public Holiday in {{ country }}. Please select the closest working day.',
          replace: {
            country: country ? countryLabels[country] : country,
          },
        }),
        (value) => {
          if (!value || !limitedHolidays) return true;
          return limitedHolidays.every(
            (holiday) => !isEqual(holiday.date, value),
          );
        },
      )
      .required(
        t(
          'definition-phase.basic-details.start-date.error',
          'Start Date is required',
        ),
      ),
    endOn: yup
      .date()
      .nullable(true)
      .when('contractTerm', {
        is: (contractTerm: ContractTerm) => contractTerm === ContractTerm.FIXED,
        then: yup
          .date()
          .min(
            yup.ref('startOn'),
            t(
              'definition-phase.basic-details.min-end-date.error',
              'End Date must be later than Start Date',
            ),
          )
          .when('contractType', {
            is: (type: ContractType) => type !== ContractType.FREELANCER,
            then: yup
              .date()
              .required(
                t(
                  'definition-phase.basic-details.end-date.error',
                  'End Date is required',
                ),
              ),
          })
          .test(
            'max-date',
            t(
              'definition-phase.basic-details.end-date.max-period.error',
              'End date is falling out of period',
            ),
            (value, ctx) => {
              if (!value) return true;

              const maxDate = getMaximumEndDate(ctx.parent.startOn);

              return !isAfter(value, maxDate);
            },
          ),
      }),
    scope: buildSchemaForJobScope(
      t,
      buildValidationContextForJobScope({
        contract: { country, type: contractType },
      }),
    ),
    preDataRequirements: yup.array().of(
      yup.object().shape({
        key: yup.string().required(),
        type: yup.string(),
        value: yup.lazy((_value, ctx) => {
          if (
            requirementsMap?.[ctx.parent.key]?.dataType?.__typename ===
            'DateField'
          ) {
            // treating this as date field throws type error
            return yup
              .string()
              .when({
                is: () => requirementsMap?.[ctx.parent.key]?.required,
                then: yup
                  .string()
                  .required(
                    t('basic-details.validation.required', 'Input is required'),
                  ),
              })
              .test(
                'is_less_than_max_date',
                t(
                  'basic-details.validation.date.max',
                  'Date is after allowed range',
                ),
                (val) =>
                  Boolean(val) &&
                  new Date(val as string) <=
                    (ctx.parent.key === 'dateOfBirth'
                      ? latestValidBirthdate
                      : endOfDay(
                          new Date(
                            (requirementsMap?.[ctx.parent.key]
                              ?.dataType as DateField)?.maxDate ?? '2200-01-01',
                          ),
                        )),
              )
              .test(
                'is_greater_than_min_date',
                t(
                  'basic-details.validation.date.max',
                  'Date is before allowed range',
                ),
                (val) =>
                  Boolean(val) &&
                  new Date(val as string) >=
                    startOfDay(
                      new Date(
                        (requirementsMap?.[ctx.parent.key]
                          ?.dataType as DateField)?.minDate ?? '1900-01-01',
                      ),
                    ),
              );
          }

          if (
            requirementsMap?.[ctx.parent.key]?.dataType?.__typename ===
            'DropDownField'
          ) {
            return yup.string().when({
              is: () => requirementsMap?.[ctx.parent.key]?.required,
              then: yup
                .string()
                .required(
                  t('basic-details.validation.required', 'Input is required'),
                )
                .oneOf(
                  (requirementsMap?.[ctx.parent.key]?.dataType as DropDownField)
                    ?.values as string[],
                ),
            });
          }

          if (
            requirementsMap?.[ctx.parent.key]?.dataType?.__typename ===
            'AutoCompleteField'
          ) {
            return yup
              .string()
              .nullable()
              .when('key', {
                is: () => {
                  if (
                    (requirementsMap?.[ctx.parent.key]
                      ?.dataType as AutoCompleteField)?.optionListType ===
                    'STATE'
                  ) {
                    const preDataRequirements: PreDataRequirementType[] =
                      (ctx as ResolveOptions & ContextFromType).from?.[1]?.value
                        ?.preDataRequirements ?? [];
                    const nameOfCountry = preDataRequirements?.filter(
                      (item) => item.key === 'address.country',
                    )?.[0]?.value;

                    return countryWithStateList.includes(
                      nameOfCountry as CountryCode,
                    );
                  }

                  return requirementsMap?.[ctx.parent.key]?.required;
                },
                then: yup.string().required(),
              });
          }

          return yup.string().when('key', {
            is: (key: string) => key === 'address.line2',
            then: yup.string().nullable(),
            otherwise: yup
              .string()
              .when({
                is: () => requirementsMap?.[ctx.parent.key]?.required,
                then: yup
                  .string()
                  .required(
                    t('basic-details.validation.required', 'Input is required'),
                  ),
              })
              .test(
                'min_length',
                t('basic-details.validation.min', 'Input is too short'),
                (val) => {
                  const minLength = (requirementsMap?.[ctx.parent.key]
                    ?.dataType as TextField)?.minLength;
                  if (minLength && val?.length) return val?.length >= minLength;
                  return true;
                },
              )
              .max(
                (requirementsMap?.[ctx.parent.key]?.dataType as TextField)
                  ?.maxLength ?? Number.MAX_VALUE,
                t('basic-details.validation.max', 'Input is too long'),
              )
              .test(
                'is_valid_string',
                t('basic-details.validation.invalid', 'Invalid Input'),
                (val) => {
                  const pattern = (requirementsMap?.[ctx.parent.key]
                    ?.dataType as TextField)?.pattern;
                  if (!pattern) return true;

                  return new RegExp(pattern).test(val ?? '');
                },
              ),
          });
        }),
      }),
    ),
    workShift: getWorkShiftSchema(),
  });

  return {
    memberDataRequirementFields,
    schema,
  };
};

export default useBasicDetailsValidationSchema;
