import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { numberAsCurrency } from '@multiplier/format';
import tw, { TwStyle } from 'twin.macro';

import { CountryCode, CurrencyCode, Maybe } from '../__generated__/graphql';
import Icon from '../icon';
import ToolTip from '../tooltip';
import { ToolTipVariant } from '../types';
import { shortenText } from '../utils';

const Amount: React.FC<{
  currency: CurrencyCode | null | undefined;
  amount: number | null | undefined;
  decimalPlaces?: number;
}> = ({ currency, amount, decimalPlaces = 0 }) => {
  const { i18n } = useTranslation('common');
  return (
    <span>{`${currency} ${numberAsCurrency(
      i18n.language,
      amount,
      decimalPlaces,
    )}`}</span>
  );
};

const Country: React.FC<{
  country?: Maybe<CountryCode> | string;
  variant?: 'dark' | 'light';
  size?: 'medium' | 'large';
  countryLabels?: {
    [key: string]: string;
  };
}> = ({
  country,
  variant = 'light',
  size = 'medium',
  countryLabels,
  ...props
}) => {
  const { t } = useTranslation('common');
  const label = !country
    ? ''
    : countryLabels
    ? countryLabels[country as CountryCode]
    : t(`country.${country.toLowerCase()}`);

  return (
    <div {...props}>
      {country ? (
        <ToolTip variant="bottom" content={label}>
          <div
            tw="p-small"
            css={[
              tw`border border-border-primary rounded-tiny bg-background-white`,
              size === 'large' ? tw`p-small` : tw`p-tiny`,
            ]}
          >
            <Icon.Flag
              css={[
                variant === 'dark' && tw`bg-white border-none`,
                size === 'large' ? tw`w-40 h-40` : tw`w-32 h-32`,
              ]}
              name={country?.toLowerCase()}
            />
          </div>
        </ToolTip>
      ) : (
        <div tw="h-48" />
      )}
    </div>
  );
};

const Text: React.FC<{
  text?: string | null;
  maxLength?: number;
  tooltipVariant?: ToolTipVariant;
  textStyle?: TwStyle;
  tooltipTextStyle?: TwStyle;
}> = ({
  text,
  maxLength = 18,
  tooltipVariant = 'bottom',
  textStyle,
  tooltipTextStyle,
  ...props
}) => {
  const shortenedText = useMemo(
    () =>
      text && text?.length > maxLength
        ? `${text.substring(0, maxLength)}...`
        : text,
    [text],
  );

  return (
    <div tw="text-ps font-normal" {...props}>
      <ToolTip
        variant={tooltipVariant}
        content={
          shortenedText !== text ? (
            <div css={[tw`text-center`, tooltipTextStyle]}>
              <p tw="text-p">{text}</p>
            </div>
          ) : null
        }
      >
        <p css={[tw`overflow-hidden text-left`, textStyle]}>{shortenedText}</p>
      </ToolTip>
    </div>
  );
};

export const Name: React.FC<{
  name?: string | null;
  position?: string | null;
  maxNameLength?: number;
  maxPositionLength?: number;
  nameStyles?: TwStyle | TwStyle[];
  positionStyles?: TwStyle | TwStyle[];
  tooltipVariant?: ToolTipVariant;
}> = ({
  name,
  position,
  maxNameLength = 18,
  maxPositionLength = 22,
  nameStyles,
  positionStyles,
  tooltipVariant = 'bottom',
  ...props
}) => {
  const shortenedName =
    name && shortenText({ content: name, charLimit: maxNameLength });

  const shortenedPosition = useMemo(
    () =>
      position && position?.length > maxPositionLength
        ? `${position.substring(0, maxPositionLength)}...`
        : position,
    [position],
  );

  return (
    <div {...props}>
      <ToolTip
        variant={tooltipVariant}
        content={
          shortenedName !== name || shortenedPosition !== position ? (
            <div tw="text-center">
              <p tw="text-p">{name}</p>
              <p tw="text-ps">{position}</p>
            </div>
          ) : null
        }
      >
        <div tw="flex flex-col gap-y-tiny text-left">
          <p css={[tw`text-p overflow-hidden`, nameStyles]}>{shortenedName}</p>
          {shortenedPosition && (
            <p
              css={[
                tw`text-ps text-text-tertiary overflow-hidden min-height[10px]`,
                positionStyles,
              ]}
            >
              {shortenedPosition}
            </p>
          )}
        </div>
      </ToolTip>
    </div>
  );
};

const Email: React.FC<{
  text?: string | null;
  maxLength?: number;
  tooltipVariant?: ToolTipVariant;
}> = ({ text, maxLength = 18, tooltipVariant = 'bottom', ...props }) => {
  const shortenedText =
    text && shortenText({ content: text, charLimit: maxLength });

  return (
    <a tw="text-ps font-normal" href={`mailto:${text}`} {...props}>
      <ToolTip
        variant={tooltipVariant}
        content={
          shortenedText !== text ? (
            <div tw="text-center">
              <p tw="text-p">{text}</p>
            </div>
          ) : null
        }
      >
        <p tw="overflow-hidden">{shortenedText}</p>
      </ToolTip>
    </a>
  );
};

export default { Country, Amount, Text, Name, Email };
