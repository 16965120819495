import React, { useContext } from 'react';

import tw, { TwStyle, theme } from 'twin.macro';

import Icon from '../../../icon';
import { ThemeContext } from '../../../theme-provider';
import ToolTip from '../../../tooltip';
import { DropdownValue, DropdownValueType } from '../../types';

const rebrandVariantStyles: { [key: string]: TwStyle[] } = {
  common: [
    tw`text-text-primary border border-border-primary bg-background-white rounded-tiny`,
    tw`focus:(border-border-high-contrast)`,
  ],
  inline: [
    tw`flex items-center px-small h-full font-medium bg-background-primary border-none`,
    tw`disabled:bg-transparent`,
  ],
  default: [tw`w-full p-small `, tw`disabled:bg-background-secondary`],
  autocomplete: [tw`w-full p-small`],
  disabled: [
    tw`w-full border-border-secondary p-small bg-background-secondary`,
  ],
};

const variantStyles: { [key: string]: TwStyle[] } = {
  common: [
    tw`text-foreground`,
    tw`focus:(ring-1 ring-primaryBlue500 border-primaryBlue500)`,
  ],
  inline: [
    tw`flex items-center bg-grey04 px-12 h-full rounded-6 font-medium`,
    tw`disabled:bg-transparent border-none`,
  ],
  default: [
    tw`w-full border-grey03 border rounded-6 bg-white p-12 `,
    tw`disabled:(opacity-50)`,
  ],
  autocomplete: [tw`w-full border-grey03 border rounded-6 bg-white p-12`],
  disabled: [
    tw`w-full border-grey03 border rounded-6 bg-primary bg-opacity-10 p-12 `,
  ],
};

interface ButtonProps {
  variant: string;
  handleClick: () => void;
  selectDropdown: (e: React.KeyboardEvent) => void;
  selectedValue: DropdownValueType;
  showArrow: boolean;
  showDropdown: boolean;
  disabled: boolean;
  error: boolean;
  id?: string;
  placeholder?: string | React.ReactNode;
  buttonStyles?: TwStyle;
  arrowStyles?: TwStyle;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  labelStyles?: TwStyle;
  clearable?: boolean;
  handleClear: () => void;
  showTitleOnButton?: boolean;
}

const Button: React.FC<
  ButtonProps & {
    values: DropdownValue[] | null;
  }
> = ({
  id,
  handleClick,
  selectDropdown,
  disabled,
  variant,
  error,
  buttonStyles,
  startAdornment,
  selectedValue,
  values,
  endAdornment,
  showArrow,
  showDropdown,
  placeholder,
  labelStyles,
  clearable,
  handleClear,
  arrowStyles,
  showTitleOnButton,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <button
      id={id}
      data-testid="dropdown-button"
      type="button"
      onClick={handleClick}
      onKeyUp={selectDropdown}
      disabled={disabled}
      css={[
        rebrandVariantStyles.common,
        !isNewThemeApplied && variantStyles.common,
        rebrandVariantStyles[variant],
        !isNewThemeApplied && variantStyles[variant],
        error &&
          tw`text-text-negative border-border-negative bg-background-white`,
        !isNewThemeApplied &&
          error &&
          tw`text-error border-error bg-bgError shadow-error`,
        disabled && tw`bg-background-secondary text-text-primary`,
        tw`overflow-x-auto`,
        buttonStyles,
      ]}
    >
      <div tw="flex flex-row items-center gap-x-tiny justify-between">
        <div tw="flex items-center gap-x-tiny">
          {startAdornment}
          {values && values.length ? (
            <div tw="flex items-center">
              {values[0]?.icon && (
                <div tw="w-large h-large mr-6">{values[0].icon}</div>
              )}
              {values[0]?.preTitle}
              <span
                data-testid="dropdown-value-title"
                css={[
                  tw`flex-grow text-left whitespace-nowrap text-ellipsis overflow-hidden`,
                  labelStyles,
                ]}
              >
                {variant === 'autocomplete'
                  ? showTitleOnButton
                    ? values[0]?.title
                    : values[0]?.label
                  : values[0]?.title}
              </span>
            </div>
          ) : (
            variant !== 'inline' && (
              <span
                css={[
                  tw`flex-grow text-left text-text-tertiary`,
                  !isNewThemeApplied && tw`text-grey02`,
                  labelStyles,
                ]}
              >
                {placeholder}
              </span>
            )
          )}
        </div>
        <div tw="flex items-center gap-x-tiny">
          {values && values.length > 1 && <span>({values.length})</span>}
          {endAdornment}
          {clearable && selectedValue && !showDropdown && (
            <Icon
              name="cross"
              tw="h-[10px] w-[10px] mr-tiny"
              fill={
                !isNewThemeApplied
                  ? theme`colors.slateGrey500`
                  : theme`colors.icon-primary`
              }
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClear();
              }}
              data-testid="clear-button"
            />
          )}
          {(showArrow || variant === 'inline') && !disabled && (
            <div tw="flex items-center">
              <Icon
                css={[arrowStyles, showDropdown && tw`rotate-180`]}
                fill={theme`colors.transparent`}
                name="chevron-down"
              />
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

const DropdownButton: React.FC<ButtonProps> = ({ selectedValue, ...props }) => {
  const values = selectedValue
    ? Array.isArray(selectedValue)
      ? selectedValue
      : [selectedValue]
    : null;

  if ((values?.length ?? 0) > 1) {
    const tooltip = (values ?? []).map((value) => value?.label).join(', ');
    return (
      <ToolTip tw="w-full" content={<span tw="text-ps">{tooltip}</span>}>
        <Button values={values} selectedValue={selectedValue} {...props} />
      </ToolTip>
    );
  }

  return <Button values={values} selectedValue={selectedValue} {...props} />;
};

export default DropdownButton;
