import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import tw, { theme } from 'twin.macro';

import Button from '../button';
import Checkbox, { CheckboxContainer, CheckboxLabel } from '../checkbox';
import DropdownMenu from '../dropdown-menu';
import { useUrlFilterBinding } from '../hooks';
import Icon from '../icon';
import { ThemeContext } from '../theme-provider';
import {
  DropdownFilterProps,
  FilterCategoryProps,
  FilterOption,
  SelectedFilters,
} from './types';

const FilterCategoryLabel = tw.div`text-text-secondary font-semibold text-ps py-small px-base cursor-pointer flex items-center justify-between gap-x-extra-small`;

const getUniqueCategoriesCount = (filters: SelectedFilters) =>
  Object.keys(filters).length || 0;

const FilterCategory: React.FC<FilterCategoryProps> = ({
  optionContainerStyles,
  selectedCategoryOptions,
  expanded,
  onToggle,
  ...category
}) => (
  <>
    <FilterCategoryLabel
      onClick={() => {
        onToggle?.(category.categoryKey);
      }}
    >
      <span>
        {category.categoryLabel}
        {(selectedCategoryOptions?.length ?? 0) > 0
          ? ` (${selectedCategoryOptions?.length})`
          : ''}
      </span>
      <Icon
        name={expanded ? 'caret-down' : 'caret-right'}
        width={10}
        height={10}
      />
    </FilterCategoryLabel>

    {expanded && (
      <div css={[tw`max-h-[250px] overflow-y-scroll`, optionContainerStyles]}>
        {category.options.map((option) => (
          <CheckboxContainer key={option.value} tw="py-extra-small px-base">
            <Checkbox
              checkboxSize="small"
              checked={!!selectedCategoryOptions?.includes(option.value)}
              onChange={() =>
                category.onFilterChange(category.categoryKey, option)
              }
              id={option.label}
            />
            <CheckboxLabel
              tw="break-all white-space[break-spaces]"
              htmlFor={option.label}
            >
              {option.label}
            </CheckboxLabel>
          </CheckboxContainer>
        ))}
      </div>
    )}
  </>
);

const DropdownFilter = forwardRef<
  { toggleDropdown: (val: boolean) => void },
  DropdownFilterProps
>(
  (
    {
      filterConfig,
      onApply,
      defaultFilters,
      menuContainerStyles,
      optionContainerStyles,
      defaultExpandAll,
      loading,
      multipleExpanded = false,
    },
    ref,
  ) => {
    const { t } = useTranslation('common.select-filter');
    const { isNewThemeApplied } = useContext(ThemeContext);
    const [filterLoading, setFilterLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
    const [appliedFiltersCount, setAppliedFiltersCount] = useState<number>(0);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [expandedFilters, setExpandedFilters] = useState<string[]>([]);
    const { handleChange: urlFilterChange } = useUrlFilterBinding({
      key: 'filter',
      value: JSON.stringify(defaultFilters),
      resetPage: true,
    });

    useImperativeHandle(ref, () => ({
      toggleDropdown(value: boolean) {
        setToggleDropdown(value);
      },
    }));

    useEffect(() => {
      if (defaultFilters) {
        setSelectedFilters(defaultFilters);
        setAppliedFiltersCount(getUniqueCategoriesCount(defaultFilters));
        // onApply(defaultFilters);
      }
    }, [defaultFilters]);

    useEffect(() => {
      if (defaultExpandAll) {
        setExpandedFilters(
          filterConfig.map((category) => category.categoryKey),
        );
      }
    }, [defaultExpandAll]);

    const onFilterChange = (categoryKey: string, option: FilterOption) => {
      if (selectedFilters[categoryKey]?.includes(option.value)) {
        const newFiltersForCategory = selectedFilters[categoryKey].filter(
          (filterValue) => filterValue !== option.value,
        );
        const updatedFilters = {
          ...selectedFilters,
          [categoryKey]: newFiltersForCategory,
        };
        if (updatedFilters[categoryKey].length === 0)
          delete updatedFilters[categoryKey];
        setSelectedFilters(updatedFilters);
      } else {
        const updatedFilters = {
          ...selectedFilters,
          [categoryKey]: selectedFilters[categoryKey]?.length
            ? [
                ...(selectedFilters ? selectedFilters?.[categoryKey] : []),
                option.value,
              ]
            : [option.value],
        };
        setSelectedFilters(updatedFilters);
      }
    };

    const onFilterApply = async () => {
      setFilterLoading(true);
      if (onApply) {
        await onApply(selectedFilters);
        setAppliedFiltersCount(getUniqueCategoriesCount(selectedFilters));
        if (isEmpty(selectedFilters)) {
          urlFilterChange();
        } else {
          urlFilterChange(JSON.stringify(selectedFilters));
        }
      }
      setFilterLoading(false);
      setToggleDropdown(false);

      // Reset to default state if no filters are selected
      if (isEmpty(selectedFilters)) {
        setExpandedFilters([]);
      }
    };

    const onReset = () => {
      setSelectedFilters({});
    };

    const onToggleCategory = (categoryKey: string) => {
      if (expandedFilters.includes(categoryKey)) {
        setExpandedFilters(
          !multipleExpanded
            ? []
            : expandedFilters.filter((category) => category !== categoryKey),
        );
      } else {
        setExpandedFilters(
          !multipleExpanded ? [categoryKey] : [...expandedFilters, categoryKey],
        );
      }
    };

    const isLoading = loading || filterLoading;

    return (
      <DropdownMenu
        open={toggleDropdown}
        menuContainerStyles={[
          tw`overflow-auto overflow-y-scroll scrollbar-hide`,
          menuContainerStyles ?? tw``,
        ]}
        toggleOpen={() => setToggleDropdown(!toggleDropdown)}
        button={
          <Button
            loading={isLoading}
            type="button"
            data-testid="dropdown-filter-button"
            variant="outline"
            tw="bg-background-white"
          >
            <div tw="flex items-center">
              <Icon
                name="funnel"
                fill={!isNewThemeApplied ? 'transparent' : undefined}
                stroke={
                  !isNewThemeApplied ? theme`colors.icon-primary` : undefined
                }
                tw="w-base h-base"
              />
              <span tw="ml-extra-small">
                {t('filters.filter-button', 'Filters')}
              </span>
              {appliedFiltersCount > 0 && (
                <span tw="ml-extra-small">({appliedFiltersCount})</span>
              )}
            </div>
          </Button>
        }
      >
        {filterConfig.map((filterCategory) => (
          <div key={filterCategory.categoryKey}>
            <FilterCategory
              {...filterCategory}
              selectedCategoryOptions={
                selectedFilters[filterCategory?.categoryKey]
              }
              onToggle={onToggleCategory}
              onFilterChange={onFilterChange}
              expanded={expandedFilters.includes(filterCategory.categoryKey)}
              optionContainerStyles={optionContainerStyles}
            />
            <hr tw="w-full border-border-primary" />
          </div>
        ))}
        <div tw="flex justify-between p-base">
          <Button
            size="small"
            variant="secondary"
            onClick={onReset}
            disabled={isLoading}
          >
            {t('filters.reset-button', 'Reset')}
          </Button>
          <Button
            size="small"
            onClick={onFilterApply}
            disabled={isLoading}
            loading={isLoading}
          >
            {t('filters.apply-button', 'Apply')}
          </Button>
        </div>
      </DropdownMenu>
    );
  },
);

export default DropdownFilter;
