import { gql } from '@apollo/client';

export default gql`
  query GetSalaryRevisionNotes(
    $input: PerformanceReviewNoteInput
    $contractId: ID!
  ) {
    performanceReviewNotes(input: $input) {
      cutOffDate
      month
      year
      type
      semimonthlySegment
      payrollCyclePayDate
    }
    contract(id: $contractId) {
      id
      type
      compliance {
        type
      }
      legalEntityId
      compensation {
        basePay {
          paymentFrequency
        }
      }
    }
  }
`;
