/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ComboBox,
  DatePicker,
  DropdownTextContainer,
  DropdownTextInput,
  DropdownValue,
  IconButton,
  TextInput,
  ThemeContext,
  Toggle,
  ToolTip,
} from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import timezoneRemove from 'timesheets/services/timezone-remove';

import { Contract, CurrencyCode, Maybe } from '__generated__/graphql';

import { AdditionalPayFormValues } from '../../index';

interface InstallmentInputProps {
  currencyOptions?: Maybe<DropdownValue[]>;
  index: number;
  removeInstallment: (index: number) => void;
  maxDate?: Date | string;
  minDate?: Date | string;
}

const InstallmentInput: React.FC<InstallmentInputProps> = ({
  currencyOptions,
  index,
  removeInstallment,
  minDate,
  maxDate,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.common');

  const {
    control,
    setValue,
    watch,
    register,
    trigger,
  } = useFormContext<AdditionalPayFormValues>();

  const prevInstalmentMonth = watch(`instalments.${index - 1}.payOn.month`);
  const prevInstalmentYear = watch(`instalments.${index - 1}.payOn.year`);

  const getMinDateForCurrentInstallment = React.useMemo(() => {
    if (
      index !== 0 &&
      prevInstalmentMonth != null &&
      prevInstalmentYear != null
    )
      return new Date(prevInstalmentYear, prevInstalmentMonth + 1, 1);

    return minDate;
  }, [prevInstalmentMonth, prevInstalmentYear, index]);

  return (
    <div
      tw="grid grid-cols-2 gap-x-base"
      data-testid={`instalment-input-${index}-container`}
    >
      <TextInput.Container>
        <TextInput.Label tw="text-ps font-medium">
          {t(`additional-pay.installment-${index}.amount.label`, {
            defaultValue: 'Amount #{{index}}',
            replace: {
              index: index + 1,
            },
          })}
        </TextInput.Label>
        <DropdownTextContainer tw="bg-background-white">
          <Controller
            name={`instalments.${index}.currency`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="joining-bonus-currency-selector"
                variant="inline"
                disabled={currencyOptions?.length === 1}
                dropdownValues={currencyOptions ?? []}
                value={value ?? ''}
                onChange={onChange}
                placeholder={t(
                  'additional-pay.amount.currency-placeholder',
                  'Select Currency',
                )}
              />
            )}
          />
          <DropdownTextInput
            id="installmentAmount"
            currency
            type="number"
            step="0.01"
            placeholder="0"
            tw="appearance-none"
            data-testid="pay-amount"
            divStyles={tw`flex-grow`}
            {...register(`instalments.${index}.amount`)}
            onChange={(e) => {
              setValue(
                `instalments.${index}.amount`,
                parseInt(e.target.value, 10),
              );
              trigger();
            }}
          />
        </DropdownTextContainer>
        <CurrencyHelper
          tw="mt-6"
          referenceCurrency={watch('currency')}
          amount={watch(`instalments.${index}.amount`)}
        />
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label tw="text-ps font-medium">
          {t('additional-pay.payout-month.label-joining-bonus', 'Payout Month')}
        </TextInput.Label>
        <div tw="flex flex-row items-center">
          <DatePicker
            id="instalmentsDatePicker"
            monthSelector
            value={
              watch(`instalments.${index}.payOn.year`) != null &&
              watch(`instalments.${index}.payOn.month`) != null &&
              !Number.isNaN(watch(`instalments.${index}.payOn.month`)) &&
              !Number.isNaN(watch(`instalments.${index}.payOn.year`))
                ? timezoneRemove(
                    `${watch(`instalments.${index}.payOn.year`)}-${
                      (watch(`instalments.${index}.payOn.month`) ?? 0) + 1
                    }-1`,
                  )
                : null
            }
            min={getMinDateForCurrentInstallment}
            max={maxDate}
            onChange={(v) => {
              if (v === '') {
                setValue(`instalments.${index}.payOn.month`, undefined);
                setValue(`instalments.${index}.payOn.year`, undefined);
              } else {
                setValue(
                  `instalments.${index}.payOn.month`,
                  timezoneRemove(v).getMonth(),
                  {
                    shouldValidate: true,
                  },
                );
                setValue(
                  `instalments.${index}.payOn.year`,
                  timezoneRemove(v).getFullYear(),
                  {
                    shouldValidate: true,
                  },
                );
              }
            }}
            placeholder={t('additional-pay.payout-month.placeholder', 'Month')}
            css={[tw`w-full`, index > 1 && tw`w-[238px]`]}
            divStyles={tw`w-full`}
          />
          {index > 1 && (
            <IconButton
              size="small"
              name="trash"
              stroke={!isNewThemeApplied ? theme`colors.black` : undefined}
              fill={
                !isNewThemeApplied ? 'transparent' : theme`colors.icon-primary`
              }
              tw="border rounded-tiny border-border-primary ml-base h-extra-large w-extra-large"
              onClick={() => removeInstallment(index)}
            />
          )}
        </div>
      </TextInput.Container>
    </div>
  );
};

const JoiningBonusFields: React.FC<{
  contractType?: Contract['type'];
  currency?: Maybe<CurrencyCode>;
  currencyOptions: Maybe<DropdownValue[]>;
  maxDate?: Date | string;
  minDate?: Date | string;
}> = ({ currency, currencyOptions, maxDate, minDate }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const {
    control,
    register,
    setValue,
    watch,
    trigger,
    clearErrors,
    formState: { isValid },
  } = useFormContext<AdditionalPayFormValues>();

  const { fields, append, remove } = useFieldArray<AdditionalPayFormValues>({
    control,
    name: 'instalments',
  });

  const handleAddInstallment = async () => {
    if (fields.length === 4) return;
    if (fields.length !== 0) {
      await trigger();
      if (!isValid) return;
    }

    append({
      currency,
      amount: null,
      payOn: {
        month: null,
        year: null,
      },
    });
  };

  const handleRemoveInstallment = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (watch('payInInstallments') && fields.length === 0) {
      handleAddInstallment();
      handleAddInstallment();
      trigger();
    }
  }, [watch('payInInstallments')]);

  return (
    <>
      <PayInInstallmentsToggle>
        <div tw="flex flex-col">
          <InstallmentsHeading>
            {t(
              'additional-pay.joining-bonus.installments-toggle.heading',
              'Pay in Installments',
            )}
          </InstallmentsHeading>
          <InstallmentsSubHeading css={[!isNewThemeApplied && tw`text-grey01`]}>
            {t(
              'additional-pay.joining-bonus.installments-toggle.sub-heading',
              'Split the amount into more than 1 and a maximum of 4 payouts.',
            )}
          </InstallmentsSubHeading>
        </div>
        <Controller
          control={control}
          name="payInInstallments"
          render={({ field: { onChange } }) => (
            <Toggle
              data-testid="pay-in-instalments-toggle"
              id="payInInstallmentsToggle"
              checked={watch('payInInstallments') ?? false}
              onChange={(e) => {
                onChange(e.target.checked);
                clearErrors();
                trigger();
              }}
            />
          )}
        />
      </PayInInstallmentsToggle>
      {!watch('payInInstallments') && (
        <div tw="grid grid-cols-2 gap-x-base">
          <TextInput.Container>
            <TextInput.Label tw="text-ps font-medium">
              {t(
                'additional-pay.amount.label.one-time',
                'Amount (One Time Payment)',
              )}
            </TextInput.Label>
            <DropdownTextContainer tw="bg-background-white">
              <Controller
                name="currency"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    data-testid="joining-bonus-currency-selector"
                    variant="inline"
                    disabled={currencyOptions?.length === 1}
                    dropdownValues={currencyOptions ?? []}
                    value={value ?? ''}
                    onChange={onChange}
                    placeholder={t(
                      'additional-pay.amount.currency-placeholder',
                      'Select Currency',
                    )}
                  />
                )}
              />
              <DropdownTextInput
                currency
                type="number"
                step="0.01"
                placeholder="0"
                tw="appearance-none"
                data-testid="pay-amount"
                divStyles={tw`flex-grow`}
                {...register('amount')}
              />
            </DropdownTextContainer>
            <CurrencyHelper
              tw="mt-extra-small"
              referenceCurrency={watch('currency')}
              amount={watch('amount')}
            />
          </TextInput.Container>
          <TextInput.Container>
            <TextInput.Label tw="text-ps font-medium">
              {t(
                'additional-pay.payout-month.label-joining-bonus',
                'Payout Month',
              )}
            </TextInput.Label>
            <DatePicker
              monthSelector
              value={
                watch('payoutMonth.month') != null &&
                watch('payoutMonth.year') != null &&
                !Number.isNaN(watch('payoutMonth.month')) &&
                !Number.isNaN(watch('payoutMonth.year'))
                  ? timezoneRemove(
                      `${watch('payoutMonth.year')}-${
                        watch('payoutMonth.month') + 1
                      }-1`,
                    )
                  : null
              }
              min={minDate}
              max={maxDate}
              onChange={(v) => {
                setValue('payoutMonth.month', timezoneRemove(v).getMonth(), {
                  shouldValidate: true,
                });
                setValue('payoutMonth.year', timezoneRemove(v).getFullYear(), {
                  shouldValidate: true,
                });
              }}
              placeholder={t(
                'additional-pay.payout-month.placeholder',
                'Month',
              )}
            />
          </TextInput.Container>
        </div>
      )}
      {watch('payInInstallments') &&
        fields.map((item, index) => (
          <InstallmentInput
            key={item.id}
            currencyOptions={currencyOptions}
            index={index}
            minDate={minDate}
            maxDate={maxDate}
            removeInstallment={handleRemoveInstallment}
          />
        ))}
      {watch('payInInstallments') && (
        <ToolTip
          variant="bottom"
          styles={tw`p-0`}
          content={
            fields.length === 4 ? (
              <div tw="flex p-extra-small max-w-[275px] text-ps">
                {t(
                  'additional-pay.joining-bonus.max-add-installment.tooltip',
                  'You have reached the maximum number of instalments possible for Joining Bonus.',
                )}
              </div>
            ) : (
              ''
            )
          }
        >
          <Button
            size="small"
            onClick={handleAddInstallment}
            tw="max-w-[242px]"
            variant="outline"
            disabled={fields.length === 4}
          >
            {t(
              'additional-pay.joining-bonus.add-installment.button',
              '+ Add another instalment',
            )}
          </Button>
        </ToolTip>
      )}
    </>
  );
};

export default JoiningBonusFields;

const PayInInstallmentsToggle = tw.div`flex flex-row justify-between bg-background-primary rounded-tiny p-base`;

const InstallmentsHeading = tw.span`text-ps font-semibold text-text-primary`;

const InstallmentsSubHeading = tw.span`text-pxs font-normal text-text-tertiary`;
