import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { dateAsString, parseDate } from '@multiplier/format';
import { differenceInDays, startOfDay } from 'date-fns';
import tw, { css } from 'twin.macro';

const cardBorder = (status: DueDateUrgency | undefined) => {
  switch (status) {
    case DueDateUrgency.LOW:
      return tw`border-border-secondary`;
    case DueDateUrgency.HIGH:
      return tw`border-border-warning`;
    case DueDateUrgency.URGENT:
      return tw`border-border-negative`;
    default:
      return null;
  }
};

export enum DueDateUrgency {
  LOW = 'LOW',
  HIGH = 'HIGH',
  URGENT = 'URGENT',
}

const getUrgency = (daysToCutoff: number) => {
  if (Number.isNaN(daysToCutoff)) return DueDateUrgency.LOW;

  return daysToCutoff <= 0
    ? DueDateUrgency.URGENT
    : daysToCutoff >= 7
    ? DueDateUrgency.LOW
    : DueDateUrgency.HIGH;
};

const DueDateCard: React.FC<{
  payrollCutoffDate: string | undefined | null;
}> = ({ payrollCutoffDate }) => {
  const { t } = useTranslation('contract-onboarding');

  if (!payrollCutoffDate) return null;

  const cutoff = startOfDay(parseDate(payrollCutoffDate));

  const daysToCutoff = differenceInDays(cutoff, startOfDay(new Date()));

  const urgency = getUrgency(daysToCutoff);

  if (Number.isNaN(cutoff.getTime())) return null;

  return (
    <div
      css={[
        cardBorder(urgency),
        tw`rounded-base border-l-4 p-base max-w-[448px]`,
        tw`flex flex-col gap-y-2 items-start justify-between relative`,
        tw`text-ps`,
        css`
          box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
        `,
      ]}
    >
      <p tw="font-semibold text-text-primary">
        <Trans t={t} i18nKey="onboarding-checklist.due-date.header">
          Due date for {{ month: dateAsString(cutoff, 'MMMM') }} salary:{' '}
          <span>{{ dueDate: dateAsString(cutoff, 'do MMMM yyyy') }}</span>
        </Trans>
      </p>
      <p tw="text-text-tertiary text-pxs">
        <Trans t={t} i18nKey="onboarding-checklist.due-date.description">
          Missing the due date will delay in the{' '}
          <span tw="font-medium">
            first salary payout, start date, and insurance onboarding
          </span>
          .
        </Trans>
      </p>
    </div>
  );
};

export default DueDateCard;
