/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

const MainContainer = tw.div`
    flex bg-red100 border border-red300 p-16 text-p font-semibold text-black rounded-8 items-center
`;

const ChangesRequestedStrip: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <MainContainer>
      <Icon
        name="warning-circle-red"
        fill="transparent"
        stroke={theme`colors.red800`}
      />
      <Trans t={t} i18nKey="changes-requested-strip.header">
        <span tw="text-red800 mr-8 ml-[10px]">Changes requested: </span> We’re
        processing the contract changes
      </Trans>
    </MainContainer>
  );
};

export default ChangesRequestedStrip;
