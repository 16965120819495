import React, { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import { Icon, Loader, SpinnerType } from '@multiplier/common';
import { numberAsCurrency, timezoneRemove } from '@multiplier/format';
import { Experience, userVar } from '@multiplier/user';
import { format, startOfMonth } from 'date-fns';
import tw from 'twin.macro';

import currencyCountry from 'common/constants/currency-country';
import {
  Religion,
  payFrequencyLabel,
  rateFrequencyLabel,
} from 'common/constants/default-labels';
import AdditionalPaysTable from 'contract-onboarding/company/components/additional-pays-table';
import Esop from 'contract-onboarding/company/components/esop';
import { getEsopFromGrant } from 'contract-onboarding/company/pages/definition-phase/pages/compensation';
import {
  convertBasePayToMonthlyPay,
  getCurrencyOptions,
} from 'contract-onboarding/company/services/compensation';
import { DetailValue } from 'contract-onboarding/components/layout';
import { PerformanceReviewFormValues } from 'performance-reviews/components/performance-review-form';
import { useAdditionalCompensationsMapper } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';
import {
  additionalPaysSupported,
  additionalPaysSupportedFeatureOn,
} from 'performance-reviews/components/performance-review-form/utils';
import { showPaymentFrequency } from 'team/company/services/contracts';

import {
  Contract,
  ContractType,
  CountryCode,
  CurrencyCode,
  FixedPayComponent,
  Maybe,
  PerformanceReviewStatus,
  RateFrequency,
  useGetMemberCompensationLazyQuery,
  useGetSupportedCurrenciesQuery,
} from '__generated__/graphql';

import { DetailGrid, DetailRow } from '../../layout';

const CurrentCompensation: React.FC<{
  contract: Contract;
}> = ({ contract }) => {
  const { t, i18n } = useTranslation([
    'team.company',
    'contract-onboarding.common',
  ]);

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const [compensation, setCompensation] = useState<Contract['compensation']>(
    null,
  );

  const [getMemberCompensation] = useGetMemberCompensationLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setCompensation(data?.member?.contracts?.[0]?.compensation);
    },
  });

  const isMemberView = current === Experience.MEMBER;

  const activePerformanceReview = contract?.performanceReviews?.filter(
    (x) =>
      x?.status &&
      [
        PerformanceReviewStatus.APPROVED,
        PerformanceReviewStatus.ACTIVATED,
      ].includes(x.status),
  )?.[0];

  const isNotFreelancer = contract.type !== ContractType.FREELANCER;

  const {
    mapToAdditionalPayFormValues,
    fetchingCountry13th14thMonthPay,
    fetchingCountryAllowancesConfig,
  } = useAdditionalCompensationsMapper({
    contract,
  });

  const methods = useForm<PerformanceReviewFormValues>({
    mode: 'onChange',
  });

  const basePay = compensation?.postProbationBasePay;
  const probationBasePay = compensation?.probationBasePay;

  const { data: supportedCurrenciesData } = useGetSupportedCurrenciesQuery({
    variables: {
      country: contract?.country as CountryCode,
    },
    skip: !contract?.country,
  });

  const compliantCurrencies =
    supportedCurrenciesData?.country?.supportedCurrencies || [];

  const currencyOptions = useMemo(
    () => getCurrencyOptions(compliantCurrencies),
    [compliantCurrencies],
  );

  const esop = useMemo(() => {
    const grants = compensation?.grant;
    if (!grants || grants.length === 0) return null;
    return getEsopFromGrant(grants[0]);
  }, [compensation]);

  const getSalaryDisplay = (pay?: Maybe<FixedPayComponent>) => {
    if (!pay || !pay.currency || !pay.amount) return null;

    return `${pay.currency} ${numberAsCurrency(i18n.language, pay.amount, 2)} ${
      pay?.frequency
        ? showPaymentFrequency(t, contract.type, pay.frequency)
        : ''
    }`.trim();
  };

  const populateFormData = () => {
    if (
      compensation &&
      compensation.basePay &&
      compensation.postProbationBasePay
    ) {
      methods.reset({
        contractId: contract?.id || '',
        revisedSalary: compensation.basePay?.amount,
        currency: basePay?.currency,
        promotedDesignation: contract?.position ?? '',
        additionalPays: mapToAdditionalPayFormValues(
          compensation?.additionalPays,
        ),
        esop: compensation?.grant?.length
          ? getEsopFromGrant(compensation?.grant?.[0])
          : undefined,
      });
    }
  };

  useEffect(() => {
    if (!fetchingCountry13th14thMonthPay && !fetchingCountryAllowancesConfig) {
      populateFormData();
    }
  }, [
    compensation,
    fetchingCountry13th14thMonthPay,
    fetchingCountryAllowancesConfig,
  ]);

  useEffect(() => {
    if (!isMemberView) {
      setCompensation(contract?.compensation);
    } else {
      getMemberCompensation({
        variables: {
          id: contract?.id ?? '',
        },
      });
    }
  }, [contract, isMemberView]);

  if (!compensation) {
    return <Loader.Spinner variant={SpinnerType.CUSTOM_LAYOUT} tw="h-96" />;
  }

  return (
    <>
      <DetailGrid tw="mb-extra-small">
        <DetailRow
          id="designation"
          label={t(
            'employment-details.compensation.designation',
            'Designation',
          )}
          value={contract.position}
        />
        {probationBasePay?.amount && (
          <DetailRow
            id="probationSalary"
            label={t(
              'employment-details.compensation.probation-salary',
              'Probation salary',
            )}
            value={probationBasePay?.amount}
            renderValue={
              <div tw="flex items-center gap-extra-small col-span-3">
                {probationBasePay?.currency && (
                  <Icon.Flag
                    tw="w-large h-large"
                    name={currencyCountry[probationBasePay.currency]}
                  />
                )}
                <DetailValue>{getSalaryDisplay(probationBasePay)}</DetailValue>
              </div>
            }
          />
        )}
        <DetailRow
          id="baseSalary"
          label={t(
            'employment-details.compensation.base-salary',
            'Base salary',
          )}
          value={basePay?.amount}
          renderValue={
            <div tw="flex items-center gap-extra-small col-span-3">
              {basePay?.currency && (
                <Icon.Flag
                  tw="w-large h-large"
                  name={currencyCountry[basePay.currency]}
                />
              )}
              <DetailValue>{getSalaryDisplay(basePay)}</DetailValue>
            </div>
          }
        />
        <DetailRow
          id="payPeriod"
          label={t(
            'employment-details.compensation.pay-period',
            'Salary pay period',
          )}
          value={basePay?.frequency && rateFrequencyLabel[basePay?.frequency]}
        />
        <DetailRow
          id="frequency"
          label={t(
            'employment-details.compensation.frequency',
            'Salary frequency',
          )}
          value={
            basePay?.paymentFrequency &&
            payFrequencyLabel[basePay?.paymentFrequency]
          }
        />
        {activePerformanceReview && activePerformanceReview?.effectiveDate && (
          <DetailRow
            id="effectiveFrom"
            label={t(
              'employment-details.compensation.effective-from',
              'Effective from',
            )}
            value={format(
              timezoneRemove(activePerformanceReview?.effectiveDate),
              'MMM dd, yyyy',
            )}
          />
        )}
        <DetailRow
          id="currency"
          label={t('employment-details.compensation.currency', 'Currency')}
          value={basePay?.currency}
        />
      </DetailGrid>
      <FormProvider {...methods}>
        <form>
          <AdditionalPaysTable
            contractType={contract?.type}
            currencyOptions={currencyOptions}
            basePay={convertBasePayToMonthlyPay(
              basePay?.frequency ?? RateFrequency.MONTHLY,
              methods.watch('revisedSalary') ?? 0,
            )}
            religion={
              contract?.member?.legalData?.find((d) => d?.key === 'religion')
                ?.value as Religion
            }
            countryCode={contract?.country}
            currency={basePay?.currency}
            companyName={contract?.company?.displayName}
            edit={false}
            minDate={startOfMonth(new Date())}
            additionalPaysSupported={
              isNotFreelancer
                ? additionalPaysSupportedFeatureOn
                : additionalPaysSupported
            }
            titleStyle={tw`text-text-tertiary text-ps font-normal`}
            subtitleStyle={tw`w-full`}
            containerStyle={tw`px-none border-none shadow-none`}
            countryStateCode={contract?.countryStateCode}
            hideListTitle
            additionalCompensationSubText
          />
          {esop && (
            <Esop
              defaultCurrency={esop?.currency || CurrencyCode.USD}
              showEdit={false}
              containerStyle={tw`px-none border-none shadow-none`}
              titleStyle={tw`text-text-tertiary text-ps font-normal`}
              hideDescription
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default CurrentCompensation;
