import { gql } from '@apollo/client';

export default gql`
  mutation updateCompanyUser($user: UpdateUserInput) {
    companyUpdateUser(user: $user) {
      id
      firstName
      lastName
      emails {
        email
      }
      title
      role
      isSignatory
      isBillingContact
      userId
      status
    }
  }
`;
