import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'salary-calculator',
  permission: 'salary-calculator',
  folder: 'salary-calculator',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Salary Calculator',
    route: 'salary-calculator',
    icon: { name: 'coin' },
    sort: 2,
    home: false,
    group: NavGroups.MAIN,
    hide: true,
  },
};

export default config;
