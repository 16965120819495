/** @jsxImportSource @emotion/react */
import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { PillTag } from '@multiplier/common';

import { dateAsString } from 'app/utils/format';
import isNoJoiner from 'team/company/services/is-no-joiner';

import 'twin.macro';
import { Contract, ContractOffboarding } from '__generated__/graphql';

const LastWorkingDay = ({
  startOn,
  endedOn,
  lastWorkingDay,
}: {
  startOn: Contract['startOn'];
  endedOn: Contract['endedOn'];
  lastWorkingDay: ContractOffboarding['lastWorkingDay'];
}): ReactElement => {
  const { t } = useTranslation('team.company');

  if (isNoJoiner(startOn, endedOn)) {
    return (
      <div tw="flex justify-center">
        <PillTag
          label={t('last-working-day-no-joiner', 'N/A')}
          variant="PREPROCESSING"
        />
      </div>
    );
  }

  return <>{dateAsString(lastWorkingDay || endedOn)}</>;
};

export default LastWorkingDay;
