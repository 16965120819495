/** @jsxImportSource @emotion/react */
import React from 'react';
import 'twin.macro';

import { Icon } from '@multiplier/common';

import currencyCountry from 'common/constants/currency-country';

import { CurrencyCode } from '__generated__/graphql';

const CurrencyView: React.FC<{
  currencyCode: CurrencyCode;
}> = ({ currencyCode }) => (
  <div tw="flex flex-row items-center gap-x-extra-small text-text-primary font-semibold">
    <div tw="w-large">
      <Icon.Flag name={currencyCountry[currencyCode]} />
    </div>
    <p>{currencyCode}</p>
  </div>
);

export default CurrencyView;
