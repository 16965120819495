import React from 'react';

import useAsyncAssetLoader from '../utils/asset-loader';

const importAsset = async (
  name: string,
): Promise<{
  ReactComponent: React.FC<React.SVGProps<SVGSVGElement>>;
} | null> => {
  let asset;
  try {
    asset = await import(`./assets/${name}.svg`);
  } catch (e) {
    asset = null;
  }

  return asset;
};

const Pictogram: React.FC<{
  name: string;
  size: 'small' | 'medium' | 'large';
}> = ({ name, size, ...rest }) => {
  if (!name) return null;

  const sizeMap = {
    small: 60,
    medium: 80,
    large: 120,
  };

  const { asset, loading } = useAsyncAssetLoader({
    name,
    importFn: importAsset,
  });

  if (loading || !asset) {
    return null;
  }

  const ImportedAsset = asset?.ReactComponent;

  return (
    <ImportedAsset
      css={{
        height: `${sizeMap[size]}px`,
        width: `${sizeMap[size]}px`,
      }}
      {...rest}
    />
  );
};

export default Pictogram;
