import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFeature } from '@growthbook/growthbook-react';
import { parseDate } from '@multiplier/format';
import { AppFeature } from '@multiplier/growthbook';
import { eachWeekendOfMonth } from 'date-fns';

import { convertOrDefault } from 'app/utils/number';

import {
  Contract,
  ContractType,
  GetLegalEntityHolidaysQuery,
  GetStartDateRestrictionsQuery,
  Holiday,
  Maybe,
  useGetLegalEntityHolidaysLazyQuery,
  useGetStartDateRestrictionsLazyQuery,
} from '__generated__/graphql';

interface UseGetStartDateLimitResponse {
  getLimitForMonthOfDate: (date: string | Date) => void;
  limitedHolidays: ParsedHoliday[];
  limitedWeekends: Date[];
  loading: boolean;
  earliestStartDate: Date | null;
  earliestVisaCompletionDate: Date | null;
}

export interface ParsedHoliday {
  date: Date;
  holiday: Holiday['name'];
}

const parseHoliday = (holiday?: Maybe<Holiday>): ParsedHoliday => {
  const today = new Date();

  return {
    date: new Date(
      holiday?.year || today.getFullYear(),
      convertOrDefault(holiday?.month, (month) => month - 1, today.getMonth()),
      holiday?.date || today.getDate(),
    ),
    holiday: holiday?.name,
  };
};

const getHolidaysFromStartDateRestrictions = (
  startDateRestriction: GetStartDateRestrictionsQuery,
) =>
  startDateRestriction?.country?.compliance?.joiningDateRestriction?.holidays ||
  [];

const getHolidaysFromEntity = (entityHolidays?: GetLegalEntityHolidaysQuery) =>
  entityHolidays?.company?.otherEntities?.[0]?.holidays || [];

const useGetStartDateLimit = ({
  country,
  countryStateCode,
  contractType,
  workStatus,
  legalEntityId,
}: {
  country?: Contract['country'];
  countryStateCode?: Contract['countryStateCode'];
  contractType?: Contract['type'];
  workStatus?: Contract['workStatus'];
  legalEntityId?: Contract['legalEntityId'];
}): UseGetStartDateLimitResponse => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [limitedWeekends, setLimitWeekends] = useState<Date[]>([]);
  const [limitedHolidays, setLimitHolidays] = useState<ParsedHoliday[]>([]);
  const legalEntityHolidaysEnabled = useFeature(AppFeature.HOLIDAYS_BY_ENTITY)
    ?.on;

  const isContractor = contractType === ContractType.CONTRACTOR;
  const isFreelancer = contractType === ContractType.FREELANCER;

  const [
    getStartDateRestrictions,
    { data: startDateRestriction, loading: startDateRestrictionsLoading },
  ] = useGetStartDateRestrictionsLazyQuery();

  const [
    getLegalEntityHolidays,
    { data: legalEntityHolidays, loading: entityHolidaysLoading },
  ] = useGetLegalEntityHolidaysLazyQuery();

  useEffect(() => {
    if (!country) return;

    if (isFreelancer || isContractor) {
      return;
    }

    getStartDateRestrictions({
      variables: {
        country,
        countryStateCode,
        workStatus,
      },
    });
  }, [country, countryStateCode]);

  useEffect(() => {
    if (!legalEntityId || !legalEntityHolidaysEnabled) return;

    const currentYear = new Date().getFullYear();

    getLegalEntityHolidays({
      variables: {
        legalEntityId,
        input: {
          years: [currentYear, currentYear + 1],
        },
      },
    });
  }, [legalEntityId]);

  useEffect(() => {
    if (
      isFreelancer ||
      isContractor ||
      !startDateRestriction ||
      (legalEntityHolidaysEnabled && legalEntityId && !legalEntityHolidays)
    )
      return;

    const weekends = eachWeekendOfMonth(selectedDate);

    const holidays = (legalEntityHolidaysEnabled && legalEntityId
      ? getHolidaysFromEntity(legalEntityHolidays)
      : getHolidaysFromStartDateRestrictions(startDateRestriction)
    ).map(parseHoliday);

    setLimitWeekends(weekends);
    setLimitHolidays(holidays);
  }, [startDateRestriction, contractType, legalEntityHolidays]);

  const getLimitForMonthOfDate = useCallback((date: string | Date) => {
    if (!date) return;

    setSelectedDate(parseDate(date));
  }, []);

  useEffect(() => {
    getLimitForMonthOfDate(new Date());
  }, []);

  const earliestStartDate = useMemo(
    () =>
      startDateRestriction?.country?.compliance?.joiningDateRestriction
        ?.earliestJoiningDate
        ? parseDate(
            startDateRestriction.country.compliance.joiningDateRestriction
              .earliestJoiningDate,
          )
        : null,
    [
      startDateRestriction?.country?.compliance?.joiningDateRestriction
        ?.earliestJoiningDate,
    ],
  );

  const earliestVisaCompletionDate = useMemo(
    () =>
      startDateRestriction?.country?.compliance?.joiningDateRestriction
        ?.checkpoints?.earliestVisaCompletionDate
        ? parseDate(
            startDateRestriction?.country?.compliance?.joiningDateRestriction
              ?.checkpoints?.earliestVisaCompletionDate,
          )
        : null,
    [
      startDateRestriction?.country?.compliance?.joiningDateRestriction
        ?.checkpoints?.earliestVisaCompletionDate,
    ],
  );

  return {
    getLimitForMonthOfDate,
    limitedHolidays,
    limitedWeekends,
    loading: startDateRestrictionsLoading || entityHolidaysLoading,
    earliestStartDate,
    earliestVisaCompletionDate,
  };
};

export default useGetStartDateLimit;
