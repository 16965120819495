import { useMemo } from 'react';

import { LazyQueryHookOptions } from '@apollo/client';

import {
  Benefit,
  BenefitPartnerCountry,
  BenefitPartnerCountryFilters,
  GetBenefitPartnerCountriesQuery,
  GetBenefitPartnerCountriesQueryVariables,
  InsuranceCoverageGroup,
  Maybe,
  useGetBenefitPartnerCountriesLazyQuery,
} from '../__generated__/graphql';

export interface RecommendInsuranceCoverageGroup
  extends InsuranceCoverageGroup {
  isRecommended?: boolean;
}

export const getBenefitPartnerCoverageIndexer = (
  benefitPartnerCountries?: Maybe<Maybe<BenefitPartnerCountry>[]>,
): Record<string, BenefitPartnerCountry> =>
  (benefitPartnerCountries ?? [])?.reduce((prev, current) => {
    if (
      !current?.coverageGroup?.id ||
      ((prev?.[current?.coverageGroup?.id] as BenefitPartnerCountry)?.benefits
        ?.length || 0) > 0
    )
      return prev;
    return {
      ...prev,
      [current?.coverageGroup?.id]: current,
    };
  }, {} as Record<string, BenefitPartnerCountry>);

export const getAvailableBenefitsFromBenefitPartner = (
  benefitPartnerCountries?: Maybe<Maybe<BenefitPartnerCountry>[]>,
): Benefit[] =>
  (benefitPartnerCountries ?? [])?.reduce(
    (prev, current) => [...prev, ...((current?.benefits ?? []) as Benefit[])],
    [] as Benefit[],
  ) ?? [];

const useGetInsuranceBenefitAndCoverage = (
  baseOptions?: LazyQueryHookOptions<
    GetBenefitPartnerCountriesQuery,
    GetBenefitPartnerCountriesQueryVariables
  >,
) => {
  const [
    getBenefitPartnerCountries,
    { data: benefitPartnerCountries, loading: benefitPartnerCountriesLoading },
  ] = useGetBenefitPartnerCountriesLazyQuery(baseOptions);

  const onLoadBenefitAndCoverage = (params: BenefitPartnerCountryFilters) => {
    getBenefitPartnerCountries({ variables: { input: params } });
  };

  const insuranceCoverageGroups: Array<RecommendInsuranceCoverageGroup> = useMemo(
    () =>
      benefitPartnerCountries?.benefitPartnerCountries?.map(
        (item) =>
          ({
            ...item?.coverageGroup,
            isRecommended: item?.isRecommended,
          } as RecommendInsuranceCoverageGroup),
      ) ?? [],
    [benefitPartnerCountries],
  );

  return {
    insuranceCoverageGroups,
    benefitPartnerCountries: benefitPartnerCountries?.benefitPartnerCountries,
    benefitPartnerCountriesLoading,
    onLoadBenefitAndCoverage,
  };
};

export default useGetInsuranceBenefitAndCoverage;
