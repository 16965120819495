/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import {
  Avatar,
  Button,
  ButtonSize,
  Card,
  Icon,
  ThemeContext,
} from '@multiplier/common';
import { Experience, userVar } from '@multiplier/user';
import tw, { theme } from 'twin.macro';

import useOnboardingSpecialist from 'contract-onboarding/hooks/get-onboarding-specialist';

import useOnboardingSpecialistModal from './onboarding-specialist-modal';

interface Props {
  contractId: string;
  showTitleOnly?: boolean;
  showHint?: boolean;
  name?: string;
  avatar?: string;
  buttonSize?: ButtonSize;
}

const OnboardingSpecialistDescription: React.FC<{
  name: string | null;
  firstName: string;
}> = ({ firstName, name }) => {
  const { t } = useTranslation('contract-onboarding');

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  return current === Experience.COMPANY ? (
    <Trans t={t} i18nKey="onboarding-specialist-card.company.description.long">
      <span data-testid="handler-name" tw="text-black font-semibold">
        {{ name }}
      </span>{' '}
      from Multiplier is your new hire&apos;s Onboarding Manager and will guide
      them through the onboarding process. The employee can contact{' '}
      <span tw="text-black font-semibold">{{ firstName }}</span> directly once
      they sign up on Multiplier.
    </Trans>
  ) : (
    <Trans t={t} i18nKey="onboarding-specialist-card.member.description.long">
      Hi there,{' '}
      <span data-testid="handler-name" tw="text-black font-semibold">
        {{ name }}
      </span>{' '}
      from Multiplier is your Onboarding Manager. Feel free to reach out for any
      onboarding-related queries you may have.
    </Trans>
  );
};

const OnboardingSpecialistCard: React.FC<Props> = ({
  contractId,
  showTitleOnly = true,
  buttonSize = 'medium',
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding');

  const { handler, name, firstName } = useOnboardingSpecialist(contractId);

  const {
    handleOpenSendEmail,
    OnboardingSpecialistModal,
  } = useOnboardingSpecialistModal(contractId);

  return (
    <div>
      <Card tw="flex flex-row items-center px-extra-large py-large">
        <div tw="flex flex-col items-center relative">
          <Avatar
            data-testid="avatar"
            alt="avatar"
            circle
            imageUrl={handler?.displayPicture?.link || undefined}
            size="small"
            variant="image"
          />
        </div>
        <div tw="mx-base">
          {handler ? (
            <div
              css={[
                tw`text-text-secondary text-ps`,
                !isNewThemeApplied && tw`text-neutral500`,
              ]}
              data-testid="handler-description"
            >
              {showTitleOnly ? (
                <div data-testid="handler-title">
                  {t(
                    'onboarding-specialist-card.company.description.short',
                    'Onboarding Specialist',
                  )}
                </div>
              ) : (
                <div>
                  <OnboardingSpecialistDescription
                    name={name}
                    firstName={firstName}
                  />
                </div>
              )}
            </div>
          ) : (
            <div
              data-testid="handler-placeholder"
              css={[
                tw`text-text-secondary grow gap-80`,
                !isNewThemeApplied && tw`text-neutral500`,
              ]}
            >
              {t(
                'onboarding-specialist-card.description.placeholder',
                'We will assign an onboarding specialist soon. Meanwhile for all other queries, please reach to your CSM.',
              )}
            </div>
          )}
        </div>
        {handler && (
          <Button
            size={buttonSize}
            variant="outline"
            onClick={handleOpenSendEmail}
          >
            <div tw="flex flex-row items-center">
              {!isNewThemeApplied ? (
                <Icon name="mail" fill={theme`colors.primary`} />
              ) : (
                <Icon name="mail" />
              )}
              <div tw="min-w-max pl-tiny">
                {t('onboarding-specialist-card.reach-out', 'Reach out')}
              </div>
            </div>
          </Button>
        )}
      </Card>
      {OnboardingSpecialistModal}
    </div>
  );
};

export default OnboardingSpecialistCard;
