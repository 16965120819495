/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import tw, { styled } from 'twin.macro';

import ComboBox from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import {
  countryWithStateList,
  getCountryStateAbbreviation,
} from 'contract-onboarding/company/services/eligibility-states';

import {
  ContractType,
  CountryCode,
  DataFieldDefinition,
} from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '..';

interface CountryStateContainerProps {
  isStateVisible?: boolean;
}

interface CountryStateSelectorProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
  requirementMap?: { [key: string]: DataFieldDefinition };
  allCountriesDropdownValues: {
    label: string;
    value: CountryCode;
    title: CountryCode;
  }[];
  stateDropdownValues: {
    label: string;
    value: string;
    title: string;
    key: string;
  }[];
  contractType?: ContractType | null;
}

const CountryStateContainer = styled.div<CountryStateContainerProps>(
  ({ isStateVisible }) => [
    tw`grid grid-cols-1 gap-x-base gap-y-large`,
    isStateVisible && tw`grid-cols-2`,
  ],
);

const CountryStateSelector: React.FC<CountryStateSelectorProps> = ({
  methods,
  requirementMap,
  allCountriesDropdownValues,
  stateDropdownValues,
  contractType,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    reset,
    getValues,
    formState: { defaultValues },
  } = methods;

  const [selectedCountry, selectedState] = useWatch({
    control,
    name: ['country', 'state'],
  });

  useEffect(() => {
    if (selectedState && selectedCountry === CountryCode.CHN) {
      reset({ ...getValues(), city: selectedState.split('/')[1] });
    }
  }, [selectedState]);

  const isContractor = contractType === ContractType.CONTRACTOR;

  return (
    <CountryStateContainer
      isStateVisible={countryWithStateList.includes(
        selectedCountry as CountryCode,
      )}
    >
      <TextInput.Container>
        <TextInput.Label>
          {t('member.basic-details.country.label', {
            defaultValue: 'Country',
          })}
        </TextInput.Label>
        <Controller
          name="country"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ComboBox
              data-testid="select-country"
              variant="autocomplete"
              dropdownValues={allCountriesDropdownValues}
              placeholder={t(
                'member.basic-details.country.placeholder',
                'Select Country',
              )}
              value={value as string}
              disabled={
                (requirementMap?.['address.country']?.editable === false &&
                  !!defaultValues?.country) ||
                isContractor
              }
              onChange={onChange}
              showArrow
            />
          )}
        />
      </TextInput.Container>
      {countryWithStateList.includes(selectedCountry as CountryCode) && (
        <TextInput.Container>
          <TextInput.Label>
            {t(`member.basic-details.state.${selectedCountry}.label`, {
              defaultValue: '{{-state}}',
              replace: {
                state:
                  selectedCountry &&
                  getCountryStateAbbreviation(selectedCountry),
              },
            })}
          </TextInput.Label>
          <Controller
            name="state"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="select-state"
                variant="autocomplete"
                dropdownValues={stateDropdownValues}
                placeholder={t(
                  `member.basic-details.state.${selectedCountry}.placeholder`,
                  {
                    defaultValue: 'Select {{-state}}',
                    replace: {
                      state:
                        selectedCountry &&
                        getCountryStateAbbreviation(selectedCountry),
                    },
                  },
                )}
                showTitleOnButton={selectedCountry === CountryCode.CHN}
                value={value as string}
                onChange={onChange}
                disabled={
                  (requirementMap?.['address.state']?.editable === false &&
                    !!defaultValues?.state) ||
                  isContractor
                }
                showArrow
              />
            )}
          />
        </TextInput.Container>
      )}
    </CountryStateContainer>
  );
};

export default CountryStateSelector;
