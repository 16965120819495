import { graphql } from 'msw';

import { mockBenefitPartnerCountries } from '../entities/benefit-partner';

export default graphql.query('GetBenefitPartnerCountries', (req, res, ctx) =>
  res(
    ctx.data({
      benefitPartnerCountries: mockBenefitPartnerCountries({}),
    }),
  ),
);
