import { gql } from '@apollo/client';

export default gql`
  query GetMemberContractId {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
      }
    }
  }
`;
