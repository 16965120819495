/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ComboBox,
  DropdownTextContainer,
  DropdownTextInput,
  DropdownValue,
  TextInput,
} from '@multiplier/common';
import tw from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';

import { Maybe } from '__generated__/graphql';

import { AdditionalPayFormValues } from '../../index';

const ThrBonusFields: React.FC<{
  currencyOptions: Maybe<DropdownValue[]>;
}> = ({ currencyOptions }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<AdditionalPayFormValues>();

  return (
    <div>
      <TextInput.Container>
        <TextInput.Label>
          {t(
            'additional-pay.amount.label.one-time',
            'Amount (One Time Payment)',
          )}
        </TextInput.Label>
        <DropdownTextContainer tw="bg-background-white">
          <Controller
            name="currency"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                variant="inline"
                disabled
                dropdownValues={currencyOptions ?? []}
                value={value ?? ''}
                onChange={onChange}
                placeholder={t(
                  'additional-pay.amount.currency-placeholder',
                  'Select Currency',
                )}
              />
            )}
          />
          <DropdownTextInput
            currency
            type="number"
            step="0.01"
            placeholder="0"
            tw="appearance-none"
            data-testid="pay-amount"
            divStyles={tw`flex-grow`}
            {...register('amount')}
          />
        </DropdownTextContainer>
        {(!!errors?.amount || !!errors?.currency) && (
          <TextInput.Error>
            {errors?.amount?.message ?? errors?.currency?.message}
          </TextInput.Error>
        )}
        <CurrencyHelper
          tw="mt-6"
          referenceCurrency={watch('currency')}
          amount={watch('amount')}
        />
      </TextInput.Container>
    </div>
  );
};

export default ThrBonusFields;
