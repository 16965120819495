import MonthName from 'common/models/month-name';

import i18n from '../../i18n';

const monthNames: { [key: string]: string } = {
  [MonthName.JANUARY]: 'January',
  [MonthName.FEBRUARY]: 'February',
  [MonthName.MARCH]: 'March',
  [MonthName.APRIL]: 'April',
  [MonthName.MAY]: 'May',
  [MonthName.JUNE]: 'June',
  [MonthName.JULY]: 'July',
  [MonthName.AUGUST]: 'August',
  [MonthName.SEPTEMBER]: 'September',
  [MonthName.OCTOBER]: 'October',
  [MonthName.NOVEMBER]: 'November',
  [MonthName.DECEMBER]: 'December',
};

// https://github.com/i18next/react-i18next/issues/909
const fill = () => {
  monthNames[MonthName.JANUARY] = i18n.t(
    'common:month-name.january',
    'January',
  );
  monthNames[MonthName.FEBRUARY] = i18n.t(
    'common:month-name.february',
    'February',
  );
  monthNames[MonthName.MARCH] = i18n.t('common:month-name.march', 'March');
  monthNames[MonthName.APRIL] = i18n.t('common:month-name.april', 'April');
  monthNames[MonthName.MAY] = i18n.t('common:month-name.may', 'May');
  monthNames[MonthName.JUNE] = i18n.t('common:month-name.june', 'June');
  monthNames[MonthName.JULY] = i18n.t('common:month-name.july', 'July');
  monthNames[MonthName.AUGUST] = i18n.t('common:month-name.august', 'August');
  monthNames[MonthName.SEPTEMBER] = i18n.t(
    'common:month-name.september',
    'September',
  );
  monthNames[MonthName.OCTOBER] = i18n.t(
    'common:month-name.october',
    'October',
  );
  monthNames[MonthName.NOVEMBER] = i18n.t(
    'common:month-name.november',
    'November',
  );
  monthNames[MonthName.DECEMBER] = i18n.t(
    'common:month-name.december',
    'December',
  );
};

i18n.on('languageChanged init', () => {
  fill();
});

export default monthNames;
