import { format, isValid } from 'date-fns';
import { omit } from 'lodash';

import { assureNumber } from 'app/utils/number';
import { parseEntries } from 'app/utils/object';
import {
  AdditionalPayFormValues,
  PaymentType,
} from 'contract-onboarding/company/components/additional-pay-form';
import {
  EsopForm,
  extendGrantValueType,
} from 'contract-onboarding/company/components/esop-input';
import { mapAdditionalFormValueToCompensationPayComponent } from 'contract-onboarding/company/services/compensation';
import { DeductionFormValues } from 'contract-onboarding/company/services/deduction';

import {
  CompensationPayComponent,
  Contract,
  ContractType,
  DeductionValue,
  FixedPayComponentInput,
  GrantInput,
  GrantValueInput,
  Maybe,
  PayAmountType,
  PayType,
  ScheduleTimeUnit,
  VestingValueType,
  useDeleteCompensationGrantMutation,
  useUpdateCompensationMutation,
} from '__generated__/graphql';

export enum OccurrenceType {
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
}

export interface AdditionalPayForm extends CompensationPayComponent {
  occurrence?: Maybe<OccurrenceType>;
  amountType?: Maybe<PayAmountType>;
}

export interface FixedPayFormComponent extends FixedPayComponentInput {
  firstPayoutDate?: string | null;
  secondPayoutDate?: string | null;
}

export interface CompensationFormValues {
  basePay: FixedPayFormComponent;
  probationBasePay: FixedPayFormComponent | null;
  postProbationBasePay: FixedPayFormComponent;
  other: AdditionalPayForm[];
  payrollStart?: Maybe<Date>;
  additional: string | null;
  payType?: Maybe<PayType>;
  esop?: EsopForm;
  grantToDelete: string;
  additionalPays?: AdditionalPayFormValues[];
  deductions?: DeductionFormValues | null;
  payrollConfigId: string | null | undefined;
}

const useSubmitCompensation = (
  contractId: Contract['id'],
  isProbationToggleOn?: boolean,
  isFreelancerPayment?: boolean,
  contractType?: Contract['type'],
  onCompleted?: () => void,
): {
  loading: boolean;
  onSubmit: (
    values: CompensationFormValues,
  ) => Promise<{
    id: Contract['id'];
    onboarding: Contract['onboarding'];
  } | null>;
} => {
  const isNotFreelancer = contractType !== ContractType.FREELANCER;
  const [
    updateCompensation,
    { loading: loadingUpdateCompensation },
  ] = useUpdateCompensationMutation({
    onCompleted: () => {
      if (onCompleted) {
        onCompleted();
      }
    },
  });
  const [
    deleteGrant,
    { loading: loadingDeleteGrant },
  ] = useDeleteCompensationGrantMutation();

  const formatFixedPayFormValues = (
    basePay: FixedPayFormComponent,
  ): FixedPayComponentInput => {
    if (basePay.firstPayoutDate || basePay.secondPayoutDate)
      return omit(basePay, ['firstPayoutDate', 'secondPayoutDate']);
    return basePay;
  };

  const onSubmit = async (
    values: CompensationFormValues,
  ): Promise<{
    id: Contract['id'];
    onboarding: Contract['onboarding'];
  } | null> => {
    if (!contractId) return null;
    const grantType = values.esop?.esopAgreementOptions;
    const getGrantValues = (
      esops: CompensationFormValues['esop'],
    ): GrantValueInput[] => {
      if (grantType === extendGrantValueType.CASH) {
        return [
          {
            value: Number(esops?.amount),
            type: extendGrantValueType.CASH,
            currency: esops?.currency,
          },
        ];
      }
      if (grantType === extendGrantValueType.UNIT) {
        return [
          {
            value: Number(esops?.noOfEsops),
            type: extendGrantValueType.UNIT,
            currency: null,
          },
        ];
      }

      return [
        {
          value: Number(esops?.amount),
          type: extendGrantValueType.CASH,
          currency: esops?.currency,
        },
        {
          value: Number(esops?.noOfEsops),
          type: extendGrantValueType.UNIT,
          currency: null,
        },
      ];
    };

    const grant = values.esop
      ? ({
          id: values?.esop?.id,
          values: getGrantValues(values.esop),
          schedule: {
            cliffPeriod: {
              value: Number(values.esop.cliffPeriod),
              unit: ScheduleTimeUnit.MONTH,
            },
            vestingPeriod: {
              value: values.esop.vestedOver,
              unit: ScheduleTimeUnit.YEAR,
            },
            vestingSchedule: values.esop.vestingSchedule.map((schedule) => ({
              time: {
                value: schedule.value,
                unit: ScheduleTimeUnit.YEAR,
              },
              vestingValue: {
                value: Number(schedule.percentage),
                type: VestingValueType.PERCENTAGE,
              },
              frequency: values.esop?.vestingFrequency,
            })),
          },
          grantDate: format(new Date(), 'yyyy-MM-dd'),
          condition: values.esop.condition,
        } as GrantInput)
      : undefined;

    if (values?.grantToDelete) {
      await deleteGrant({
        variables: {
          contractId,
          grantId: values.grantToDelete,
        },
      });
    }

    const formattedBasePay = formatFixedPayFormValues(values.basePay);

    const contract = await updateCompensation({
      variables: {
        id: contractId,
        input: {
          basePay: formattedBasePay,
          probationBasePay: isFreelancerPayment
            ? null
            : {
                ...values?.probationBasePay,
                amount: isProbationToggleOn
                  ? values?.probationBasePay?.amount
                  : null,
              },
          otherFixedPayComponents: values?.additionalPays
            ?.filter(
              (item) =>
                ![
                  PaymentType.THIRTEENTH_MONTH_BONUS,
                  PaymentType.FOURTEENTH_MONTH_BONUS,
                  PaymentType.THR_BONUS,
                ].includes(item.payType),
            )
            .map((payItem) =>
              mapAdditionalFormValueToCompensationPayComponent({
                payItem,
                isNotFreelancer,
              }),
            ),
          additional: values.additional,
          payrollStart:
            values.payrollStart && isValid(values.payrollStart)
              ? `${format(values.payrollStart, 'yyyy-MM-dd')}T00:00:00`
              : undefined,
          payType: values.payType,
          grant: grant ? [grant] : null,
          deductions: parseEntries(values.deductions).map(
            ([deductionDefinitionId, deduction]) => ({
              deductionDefinitionId,
              value: assureNumber((deduction as DeductionValue)?.value),
              unit: (deduction as DeductionValue)?.unit,
            }),
          ),
          payrollConfigId: values.payrollConfigId,
        },
      },
    });

    return {
      id: contract.data?.contractUpdateCompensation?.id,
      onboarding: contract.data?.contractUpdateCompensation?.onboarding,
    };
  };

  return {
    loading: loadingUpdateCompensation || loadingDeleteGrant,
    onSubmit,
  };
};

export default useSubmitCompensation;
