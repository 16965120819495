import React from 'react';

import { Icon } from '@multiplier/common';
import tw from 'twin.macro';

import { OnboardingTask } from '../../../__generated__/graphql';

const TaskEntry: React.FC<{
  name: string;
  action?: React.ReactElement;
  completed: OnboardingTask['completed'];
}> = ({ name, action, completed }) => (
  <div tw="flex flex-row items-center justify-between h-[36px]">
    <div tw="flex flex-row items-center gap-x-extra-small">
      <Icon name={completed ? 'check-square' : 'square'} fill="transparent" />
      <p
        css={[
          tw`text-text-primary`,
          completed && tw`text-text-tertiary line-through`,
        ]}
      >
        {name}
      </p>
    </div>
    {!completed && action}
  </div>
);

export default TaskEntry;
