/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, IconBadge, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { useModal } from 'app/hooks';
import { Experience } from 'app/models/module-config';
import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import {
  SUB_TAB,
  TeamViewAllSubTab,
  TeamViewTab,
} from 'team/company/pages/team-view';
import routes from 'team/company/routes';

import { Contract, useContractRevokeMutation } from '__generated__/graphql';

import ImplicationInfo, {
  ImplicationInfoType,
} from '../../../company/components/implication-info';
import RevokeDeleteDialog from '../../../company/components/revoke-delete-dialog';

const RevokeContractAction: React.FC<{
  contractId: Contract['id'];
  workflowUpdating: boolean;
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
}> = ({ contractId, workflowUpdating, toggleWorkflowUpdating }) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const navigate = useNavigate();

  const [
    showRevokeContract,
    closeRevokeContractModal,
    openRevokeContractModal,
  ] = useModal();

  const [revokeContract, { loading }] = useContractRevokeMutation({
    onError: () => {
      errorNotification(
        '',
        t('revoke-contract.failed', 'Failed to Revoke Contract'),
        false,
      );
    },
    onCompleted: () => {
      navigate(
        `/${Experience.COMPANY}/${routes.root}?tab=${TeamViewTab.ALL}&${SUB_TAB}=${TeamViewAllSubTab.INACTIVE}`,
      );
      successNotification(
        '',
        t('revoke-contract.success', 'Successfully Revoked Contract'),
        false,
      );
    },
  });

  const handleRevokeContractClick = async () => {
    if (contractId) {
      await revokeContract({ variables: { id: contractId } });
    }
  };

  useEffect(() => {
    toggleWorkflowUpdating(loading);
    if (loading) closeRevokeContractModal();
  }, [loading]);

  return (
    <div>
      <div tw="flex flex-row items-center gap-x-base">
        <IconBadge
          iconContainerStyle={[tw`bg-background-brand-faded bg-opacity-5`]}
          icon="settings_backup_restore-fill"
          iconStyle={tw`h-large w-large`}
          color={!isNewThemeApplied ? 'transparent' : ''}
        />
        <div tw="flex flex-col flex-grow">
          <p tw="text-h6 font-semibold text-text-primary">
            {t(
              'onboarding-phase.progress-widget-content.revoke-action.header',
              'Revoke contract',
            )}
          </p>
          <p tw="text-ps font-normal text-text-tertiary">
            {t(
              'onboarding-phase.progress-widget-content.revoke-action.description',
              "You can cancel / annul the contract until the member's joining date. Post revoking, the contract can be accessed from the Revoked tab.",
            )}
          </p>
        </div>
        <Button
          tw="shrink-0 w-[185px]"
          size="medium"
          variant="outline"
          onClick={openRevokeContractModal}
          loading={workflowUpdating || loading}
          disabled={workflowUpdating || loading}
        >
          {t(
            'onboarding-phase.progress-widget-content.revoke-action.button',
            'Revoke Contract',
          )}
        </Button>
      </div>
      <RevokeDeleteDialog
        open={showRevokeContract}
        onClose={closeRevokeContractModal}
        onConfirm={handleRevokeContractClick}
        title={t(
          'revoke-dialog.title',
          'Are you sure you want to revoke their contract?',
        )}
        buttonText={t('revoke-dialog.button', 'Revoke Contract')}
        loading={loading}
        buttonVariant="default"
      >
        <div tw="flex flex-col items-start gap-y-base">
          <p tw="uppercase text-pxs text-text-tertiary tracking-widest">
            {t(
              'revoke-dialog.implications.header',
              'Implications of revoking contract',
            )}
          </p>
          <ImplicationInfo type={ImplicationInfoType.REVOKE} />
        </div>
      </RevokeDeleteDialog>
    </div>
  );
};

export default RevokeContractAction;
