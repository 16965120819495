/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import * as yup from 'yup';
import 'twin.macro';

import {
  Contract,
  ContractDetailRestrictionField,
  ContractDetailRestrictionOperator,
  ContractType,
  CountryCode,
  CountryCompliance,
  useGetContractDetailRestrictionsByCountryQuery,
} from '__generated__/graphql';

const compareFn = (
  value: string,
  term: string,
  operator: ContractDetailRestrictionOperator,
) => {
  switch (operator) {
    case ContractDetailRestrictionOperator.EQUAL:
      return term === value;
    case ContractDetailRestrictionOperator.START_WITH:
      return value.startsWith(term);
    case ContractDetailRestrictionOperator.CONTAIN:
      return new RegExp(`\\b${term}\\b`).test(value);
    default:
      return false;
  }
};

const useContractDetailRestrictions = (
  country: Contract['country'],
): {
  loading: boolean;
  contractDetailRestrictions: CountryCompliance['contractDetailRestrictions'];
  validateJobTitle: ({
    hasSupportedJobPositions,
    contractType,
  }: {
    hasSupportedJobPositions?: boolean;
    contractType?: Contract['type'];
  }) => yup.TestFunction<string | null | undefined, Record<string, any>>;
} => {
  const { t } = useTranslation('contract-onboarding.common');

  const { data, loading } = useGetContractDetailRestrictionsByCountryQuery({
    variables: {
      country: country as CountryCode,
    },
    skip: !country,
  });

  const contractDetailRestrictions =
    data?.country?.compliance?.contractDetailRestrictions ?? [];

  const jobTitleRestrictions =
    contractDetailRestrictions.filter(
      (x) => x.field === ContractDetailRestrictionField.JOB_TITLE,
    ) ?? [];

  const validateJobTitle = ({
    contractType,
    hasSupportedJobPositions,
  }: {
    hasSupportedJobPositions?: boolean;
    contractType?: Contract['type'];
  }): yup.TestFunction<string | null | undefined, Record<string, any>> => (
    value,
    ctx,
  ) => {
    /**
     * Skip validation when:
     * - Value is empty (for this, will need the required validation in main schema)
     * - Company has supported job positions
     * - Contract type is not EOR or AOR
     */
    if (
      !value ||
      hasSupportedJobPositions ||
      (contractType &&
        ![ContractType.EMPLOYEE, ContractType.CONTRACTOR].includes(
          contractType,
        ))
    ) {
      return true;
    }

    const jobTitle = value.replace(/\s+/g, ' ').trim().toLowerCase();

    const matchedRestriction = jobTitleRestrictions.find((item) => {
      const term = item.searchTerm?.toLowerCase() ?? '';
      const isMatched = compareFn(
        jobTitle,
        term,
        item.operator as ContractDetailRestrictionOperator,
      );
      return isMatched;
    });

    const alternatives = matchedRestriction?.alternatives ?? [];

    if (alternatives.length) {
      return ctx.createError({
        // @ts-expect-error Error message can be displayed with <Trans /> component
        message: (
          <Trans
            t={t}
            i18nKey="basic-details.job-title.restricted"
            defaults="You cannot hire an employee with a job title that includes <strong>{{ term }}</strong> due to compliance reasons. Please use <alternatives /> instead."
            values={{
              term: matchedRestriction?.searchTerm,
            }}
            components={{
              alternatives: (
                <span>
                  {alternatives.map((x, idx, arr) => (
                    <Fragment key={x}>
                      <span tw="font-semibold">{x}</span>
                      {idx === arr.length - 1 ? null : ', '}
                    </Fragment>
                  ))}
                </span>
              ),
            }}
          />
        ),
      });
    }

    return true;
  };

  return { contractDetailRestrictions, loading, validateJobTitle };
};

export default useContractDetailRestrictions;
