import React, { useContext, useEffect, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { GrowthBookProvider, useFeature } from '@growthbook/growthbook-react';
import {
  SystemDowntimeWarning,
  ThemeContext,
  Toggle,
} from '@multiplier/common';
import growthBook from '@multiplier/growthbook';
import { Notification } from '@multiplier/notifications';
import * as Sentry from '@sentry/react';
import tw from 'twin.macro';

import AppFeature from 'app/features';
import Error, { ErrorVariant } from 'common/components/error';
import { isChunkError } from 'error-code-mapping';
import routes from 'login/routes';
import { publicPagesList } from 'public-operations-config';

import { onboardingWrapper } from '../contract-onboarding/member/components/onboarding-wrapper';
import { removeHubspotScripts } from '../login/components/hubspot-tracking-code';
import { refresh } from '../login/services/startup';
import ProviderWrapper from '../provider';
import { Experience } from './models/module-config';
import { checkIfTestCompanyCreation } from './utils/check-if-test-company-creation';
import history from './utils/history';
import { userVar } from './vars';

const LoginView = React.lazy(() => import('../login/view'));
const ExperienceView = React.lazy(() => import('./components/experience'));
const DocumentsView = React.lazy(() => import('../documents/view'));
const PublicRoutes = React.lazy(() => import('../public'));

// Remove unnecessary extra trailing slash from URL that might cause page broken
const RemoveTrailingSlash: React.FC = () => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return (
      <Navigate
        replace
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
          search: location.search,
          hash: location.hash,
        }}
      />
    );
  }
  return null;
};

const Warning: React.FC = () => {
  const isSystemDown = useFeature(AppFeature.SYSTEM_DOWNTIME_WARNING).value;
  if (isSystemDown) {
    return <SystemDowntimeWarning>{isSystemDown}</SystemDowntimeWarning>;
  }
  return null;
};

export const App: React.FC = () => {
  const {
    experiences: { authorized, current },
  } = useReactiveVar(userVar);
  const navigate = useNavigate();

  const isThemeTogglerOn = useFeature(AppFeature.FRONTEND_THEME_TOGGLER)?.on;
  const { toggleTheme, isNewThemeApplied } = useContext(ThemeContext);

  const { pathname, search } = window.location;

  const [authorizedExperiences, setAuthorizedExperiences] = useState<
    { exp: Experience; element: React.ReactElement }[]
  >([]);

  const isMemberExperience = (
    experience: Experience,
    currentExperience: Experience,
  ) =>
    currentExperience === Experience.MEMBER && experience === Experience.MEMBER;

  useEffect(() => {
    if (
      !publicPagesList.some((str) => pathname.includes(str)) ||
      pathname ===
        `/${routes.root}/${routes.signUp}/${routes.signUp.company}/${routes.signUp.company.create}`
    ) {
      refresh(pathname.concat(search), checkIfTestCompanyCreation(search));
    }
  }, [pathname]);

  useEffect(() => {
    removeHubspotScripts();
  }, []);

  useEffect(() => {
    if (authorized && current && !authorized.includes(current)) {
      navigate(`${authorized[0]}/dashboard`);
    }
  }, [authorized, current]);

  useDeepCompareEffect(() => {
    setAuthorizedExperiences(
      authorized?.map((exp) => {
        if (isMemberExperience(exp, current)) {
          const OnboardingExperience = onboardingWrapper(ExperienceView, exp);
          return { exp, element: <OnboardingExperience experience={exp} /> };
        }
        return { exp, element: <ExperienceView experience={exp} /> };
      }),
    );
  }, [authorized]);

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        window.dispatchEvent(new window.Event('appOutdated'));
        const isChunkLoadError = isChunkError(error);
        if (!isChunkLoadError) {
          Sentry.captureException(error);
        }
        return (
          <Error
            variant={
              isChunkLoadError ? ErrorVariant.UPDATED : ErrorVariant.DEFAULT
            }
          />
        );
      }}
    >
      <Notification />
      <Warning />
      <RemoveTrailingSlash />
      {isThemeTogglerOn ? (
        <Toggle
          id="theme-toggler"
          onClick={() => toggleTheme({})}
          labelContainerStyle={tw`absolute bottom-36 right-10 z-[999]`}
          checked={isNewThemeApplied}
        />
      ) : null}
      <Routes>
        {authorizedExperiences.map((experience) => (
          <Route
            key={`${experience.exp}`}
            path={`${experience.exp}/*`}
            element={experience.element}
          />
        ))}
        <Route path="public/*" element={<PublicRoutes />} />
        <Route path="documents/session/:id" element={<DocumentsView />} />
        <Route path="login/*" element={<LoginView />} />
      </Routes>
    </ErrorBoundary>
  );
};

export const AppWrapper: React.FC = () => (
  <GrowthBookProvider growthbook={growthBook}>
    <ProviderWrapper history={history}>
      <App />
    </ProviderWrapper>
  </GrowthBookProvider>
);

export default AppWrapper;
