/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import { getCoverageGroupWithDependants } from '@multiplier/insurance';
import tw from 'twin.macro';

import { benefitTypeLabels } from 'common/constants/default-labels';
import DownloadItem from 'insurance/components/download-item';
import { getCoverDetails, getDownloadUrl } from 'insurance/utils/utils';

import {
  BenefitDocumentType,
  Contract,
  ContractBenefit,
} from '__generated__/graphql';

import Contributions from './contributions';

const InsurancePlanTitle = tw.p`text-p font-normal mb-base text-text-primary`;

type PlanInfoProps = {
  country: Contract['country'];
  contractBenefit: ContractBenefit;
  isReview: boolean;
};

const PlanInfo: React.FC<PlanInfoProps> = ({
  contractBenefit,
  country,
  isReview = false,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.member');
  const { t: insuranceT } = useTranslation('insurance');
  const benefit = contractBenefit?.benefit;

  const coverDetails = getCoverDetails({
    insuranceType: benefit?.type,
    maxDependentCount: contractBenefit?.dependentCount ?? 0,
    country,
    t: insuranceT,
  });

  const packageName = benefit?.packageName ?? benefit?.packageTier?.tier ?? '';

  const stepGuide =
    contractBenefit?.benefit?.memberOnboardingKit?.memberOnboardingKitFile
      ?.files?.[0]?.downloadUrl;
  const factSheet = getDownloadUrl({
    benefit: contractBenefit?.benefit,
    type: BenefitDocumentType.FACT_SHEET,
  });

  return (
    <div data-testid="plan-info">
      <InsurancePlanTitle
        css={[
          tw`text-h6 font-semibold`,
          !isNewThemeApplied && tw`text-slateGrey900`,
        ]}
      >
        {t(
          'onboarding-phase.insurance.health-plan.title',
          'Your Health Insurance Plan:',
        )}
      </InsurancePlanTitle>
      <div tw="flex flex-col gap-y-extra-large">
        <div
          css={[
            tw`border rounded-base flex flex-col border-border-secondary`,
            !isNewThemeApplied && tw`border-slateGrey200`,
          ]}
        >
          <div
            css={[
              tw`p-base border-b rounded-b-base items-center border-b-border-secondary`,
              !isNewThemeApplied && tw`border-b-slateGrey200`,
            ]}
          >
            <div>
              {packageName ? (
                <div tw="text-ps font-medium">
                  {t('onboarding-phase.insurance.health-plan.packageName', {
                    defaultValue: '{{packageName}} Plan',
                    replace: {
                      packageName,
                    },
                  })}
                </div>
              ) : null}
              <div tw="text-p font-semibold">
                {benefit?.features?.map((feature) => feature?.key).join(' + ')}
              </div>
            </div>
          </div>
          <div
            css={[
              tw`flex gap-x-base p-base bg-background-secondary`,
              !isNewThemeApplied && tw`bg-slateGrey50`,
            ]}
          >
            <div tw="flex flex-col flex-[1.6]">
              <div
                css={[
                  tw`text-ps font-medium`,
                  !isNewThemeApplied && tw`text-slateGrey500`,
                ]}
              >
                {t(
                  'onboarding-phase.insurance.health-plan.covers-label',
                  'Covers',
                )}
              </div>
              <div tw="flex items-center">
                <div
                  css={[
                    tw`font-semibold`,
                    !isNewThemeApplied && tw`text-slateGrey900`,
                  ]}
                >
                  {benefit?.provider?.coverageGroup ? (
                    getCoverageGroupWithDependants(
                      t,
                      benefit?.provider?.coverageGroup,
                      contractBenefit?.dependentCount,
                    )
                  ) : (
                    <>
                      {benefit?.type ? benefitTypeLabels[benefit?.type] : ''}
                      <span tw="text-ps ml-4 text-slateGrey900 font-normal ">
                        {coverDetails}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div tw="flex flex-col flex-[1.5]">
              <div
                css={[
                  tw`text-ps font-medium`,
                  !isNewThemeApplied && tw`text-slateGrey500`,
                ]}
              >
                {t(
                  'onboarding-phase.insurance.health-plan.insurance-partner-label',
                  'Insurance Partner',
                )}
              </div>
              <div
                css={[
                  tw`font-semibold`,
                  !isNewThemeApplied && tw`text-slateGrey900`,
                ]}
              >
                {benefit?.provider?.partnerName}
              </div>
            </div>
          </div>
        </div>
        {benefit?.provider?.erSplitApplicable ? (
          <Contributions
            insurancePremium={contractBenefit?.insurancePremium}
            ageBandApplicable={benefit?.provider?.ageBandApplicable}
            platformFee={benefit?.provider?.platformFee}
            platformFeeApplicable={benefit?.provider?.platformFeeApplicable}
            billingCurrency={benefit?.provider?.billingCurrency}
            type={benefit?.type}
            dependentCount={contractBenefit?.dependentCount}
            isReview={isReview}
          />
        ) : null}
        <div tw="flex flex-col gap-y-small">
          <div
            css={[
              tw`font-semibold`,
              !isNewThemeApplied && tw`text-slateGrey900`,
            ]}
          >
            {t(
              'onboarding-phase.insurance.health-plan.quick-actions',
              'Quick actions',
            )}
          </div>
          <div tw="flex flex-1 gap-x-base">
            {factSheet ? (
              <div
                css={[
                  tw`flex-1 border p-base rounded-base border-border-secondary`,
                  !isNewThemeApplied && tw`border-slateGrey200`,
                ]}
              >
                <DownloadItem
                  cardIconStyle={
                    !isNewThemeApplied
                      ? tw`max-h-[40px] max-w-[40px] min-w-[40px] bg-slateGrey100`
                      : tw`max-h-[40px] max-w-[40px] min-w-[40px] bg-background-secondary`
                  }
                  downloadCardStyle={tw`h-base w-base border-none p-none justify-self-center self-center ml-auto mr-none`}
                  iconStyle={tw`w-base h-base`}
                  downloadIcon="caret-right"
                  icon={
                    !isNewThemeApplied ? 'file-text-no-stroke' : 'file-text'
                  }
                  title={t(
                    'benefit.plan-downloads.factsheet-title',
                    'Policy Factsheet',
                  )}
                  description={t(
                    'benefit.plan-downloads.factsheet-description',
                    'View the list of all the benefits covered in your Insurance Policy',
                  )}
                  link={factSheet}
                />
              </div>
            ) : null}
            {stepGuide ? (
              <div
                css={[
                  tw`flex-1 border p-base rounded-base border-border-secondary`,
                  !isNewThemeApplied && tw`border-slateGrey200`,
                ]}
              >
                <DownloadItem
                  cardIconStyle={
                    !isNewThemeApplied
                      ? tw`max-h-[40px] max-w-[40px] min-w-[40px] bg-slateGrey100`
                      : tw`max-h-[40px] max-w-[40px] min-w-[40px] bg-background-secondary`
                  }
                  downloadCardStyle={tw`h-base w-base border-none p-none mr-none justify-self-center self-center`}
                  iconStyle={tw`w-base h-base`}
                  downloadIcon="caret-right"
                  title={t(
                    'benefit.plan-downloads.step-guide-title',
                    'Step-by-step Guide',
                  )}
                  description={t(
                    'benefit.plan-downloads.step-guide-description',
                    'Please follow the steps in the guide in order to claim Insurance',
                  )}
                  icon={!isNewThemeApplied ? 'book-no-stroke' : 'book'}
                  link={stepGuide}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanInfo;
