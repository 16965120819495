/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  IconButton,
  Modal,
  TextInput,
  Textarea,
  useModal,
} from '@multiplier/common';
import { theme } from 'twin.macro';
import * as yup from 'yup';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import { useSendEmailToOnboardingSpecialistMutation } from '__generated__/graphql';

import useOnboardingSpecialistModalConfirm from './onboarding-specialist-modal-confirm';

const useSendEmailToOnboardingSpecialist = (
  contractId: string,
  message: string,
) => {
  const { t } = useTranslation('contract-onboarding.common');

  const [sendEmail, { loading }] = useSendEmailToOnboardingSpecialistMutation({
    variables: {
      contractId,
      message,
    },
    onCompleted: (response) => {
      if (!response.sendEmailToOnboardingSpecialist?.success) {
        errorNotification(
          t(
            'onboarding-specialist-card.modal.error.message',
            'Failed to send email',
          ),
          '',
          false,
        );
        return;
      }

      successNotification(
        t(
          'onboarding-specialist-card.modal.success.message',
          'Email sent successfully',
        ),
        '',
        false,
      );
    },
    onError: () => {
      errorNotification(
        t(
          'onboarding-specialist-card.modal.error.message',
          'Failed to send email',
        ),
        '',
        true,
      );
    },
  });

  return { sendEmail, loading };
};

interface OnboardingSpecialistModalFormValues {
  message: string;
}

const useOnboardingSpecialistModal = (
  contractId: string,
): {
  handleOpenSendEmail: () => void;
  OnboardingSpecialistModal: React.ReactElement;
} => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<OnboardingSpecialistModalFormValues>({
    resolver: yupResolver(
      yup.object().shape({
        message: yup
          .string()
          .required(
            t(
              'onboarding-specialist-card.modal.message.required',
              'Please enter a description of your issue',
            ),
          ),
      }),
    ),
  });

  const [openSendEmail, handleCloseSendEmail, handleOpenSendEmail] = useModal();

  const message = watch('message');

  const { sendEmail, loading } = useSendEmailToOnboardingSpecialist(
    contractId,
    message,
  );

  const [email, setEmail] = useState('');

  const {
    handleConfirm,
    OnboardingSpecialistModalConfirm,
  } = useOnboardingSpecialistModalConfirm();

  const handleSendEmail = async (
    values: OnboardingSpecialistModalFormValues,
  ) => {
    const response = await sendEmail({
      variables: { contractId, message: values.message },
    });

    handleCloseSendEmail();

    if (response.data?.sendEmailToOnboardingSpecialist?.success) {
      setEmail(
        response.data?.sendEmailToOnboardingSpecialist?.requesterEmail || '',
      );
      handleConfirm();
    }
  };

  const OnboardingSpecialistModal = (
    <div>
      <Modal
        open={openSendEmail}
        onClose={handleCloseSendEmail}
        tw="w-[480px] overflow-y-visible p-0"
      >
        <div tw="w-full h-48 bg-grey05 rounded-t-8 flex justify-between items-center pl-16">
          <h6 tw="text-h6 font-semibold">Get help with onboarding</h6>
          <IconButton
            size="medium"
            name="cross"
            fill={theme`colors.grey03`}
            onClick={handleCloseSendEmail}
          />
        </div>
        <div tw="flex flex-col h-full p-16">
          <div tw="flex flex-col">
            <div tw="text-neutral500 gap-2 pb-24 text-ps">
              <Trans
                t={t}
                i18nKey="onboarding-specialist-card.modal.description"
              >
                Have any questions or need assistance with onboarding? Feel free
                to send an email directly to your dedicated onboarding
                specialist. We are here to make your onboarding experience
                smooth.
              </Trans>
            </div>
          </div>
          <form
            tw="flex flex-col gap-y-16"
            onSubmit={handleSubmit(handleSendEmail)}
          >
            <TextInput.Container>
              <Textarea
                placeholder={t(
                  'onboarding-specialist-card.modal.message.placeholder',
                  'Describe what’s your issue...',
                )}
                tw="w-full"
                rows={3}
                {...register('message')}
              />
              {errors.message && (
                <TextInput.Error>{errors.message.message}</TextInput.Error>
              )}
            </TextInput.Container>
            <div tw="flex justify-center">
              <Button type="submit" loading={loading} disabled={loading}>
                {t(
                  'onboarding-specialist-card.modal.send-message',
                  'Send message',
                )}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      {OnboardingSpecialistModalConfirm({ email })}
    </div>
  );
  return { handleOpenSendEmail, OnboardingSpecialistModal };
};

export default useOnboardingSpecialistModal;
