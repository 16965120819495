import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractType, CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const memberDataReviewed: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.member-data-reviewed.title', {
      defaultValue: '{{name}} has reviewed their data added.',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.member-data-reviewed.description', {
      defaultValue:
        'We are collecting basic, bank, legal details from {{firstName}} to setup payroll.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t, contractType) => {
    switch (contractType) {
      case ContractType.FREELANCER:
        return t(
          'onboarding-phase.freelancer-data-reviewed.label',
          'Details confirmed by Freelancer',
        );
      case ContractType.CONTRACTOR:
        return t(
          'onboarding-phase.contractor-data-reviewed.label',
          'Details confirmed by Contractor',
        );
      default:
        return t(
          'onboarding-phase.member-data-reviewed.label',
          'Employee has reviewed their data',
        );
    }
  },
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t('onboarding-phase.member-data-reviewed.status', 'Pending on Multiplier'),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
};

export default memberDataReviewed;
