/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Icon, ThemeContext, ToolTip } from '@multiplier/common';
import { isNil } from 'lodash';
import tw, { theme } from 'twin.macro';
import * as yup from 'yup';

import CurrencyHelper from 'app/components/currency-helper';
import { useModal } from 'app/hooks';
import ComboBox from 'common/components/combo-box';
import ConfirmationDialog from 'common/components/confirmation-dialog';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';
import currencyCountry from 'common/constants/currency-country';
import currencyLabels from 'common/constants/currency-labels';
import { vestingFrequency as vestingFrequencyLabel } from 'common/constants/default-labels';
import EsopPreviewStatement from 'contract-onboarding/company/components/esop-preview-statement';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Company,
  CurrencyCode,
  GrantValueType,
  Maybe,
  VestingFrequency,
} from '__generated__/graphql';

enum GrantValueTypeBoth {
  BOTH = 'BOTH',
}
export interface EsopAgreementOptions {
  value: string;
  title: string;
}

export const extendGrantValueType = {
  ...GrantValueType,
  ...GrantValueTypeBoth,
};

export type EsopForm = {
  id?: string | null;
  currency: CurrencyCode;
  amount?: number;
  vestedOver: number;
  vestingSchedule: { percentage: number; value: number }[];
  cliffPeriod: number;
  vestingFrequency: VestingFrequency;
  condition: string;
  esopAgreementOptions: string;
  noOfEsops?: number;
};

export const getRatioString = (
  vestingSchedule: EsopForm['vestingSchedule'],
): string =>
  vestingSchedule?.reduce((prev, curr, idx) => {
    prev += `${curr.percentage}${
      idx < vestingSchedule.length - 1 ? ' : ' : ''
    }`;
    return prev;
  }, '');

const EsopInput: React.FC<{
  defaultCurrency?: Maybe<CurrencyCode>;
  companyName?: Company['displayName'];
  onEsopAdd: (values: EsopForm) => void;
  handleClose: () => void;
  uncommittedSubFormChangesCallback?: (values: boolean) => void;
}> = ({
  defaultCurrency,
  companyName,
  onEsopAdd,
  handleClose,
  uncommittedSubFormChangesCallback,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const esopAgreementOptionsList: EsopAgreementOptions[] = [
    {
      value: extendGrantValueType.UNIT,
      title: t(
        'definition-phase.compensation.esop-agreement-options.esop',
        'No. Of ESOPs',
      ),
    },
    {
      value: extendGrantValueType.CASH,
      title: t(
        'definition-phase.compensation.esop-agreement-options.amount',
        'Amount',
      ),
    },
    {
      value: extendGrantValueType.BOTH,
      title: t(
        'definition-phase.compensation.esop-agreement-options.esop-and-amount',
        'Both (No. of ESOPs & Amount)',
      ),
    },
  ];

  const [
    openConfirmation,
    handleCloseConfirmation,
    handleOpenConfirmation,
  ] = useModal();

  const { control: parentControl } = useFormContext<CompensationFormValues>();
  const esop = useWatch({
    control: parentControl,
    name: 'esop',
  });

  const {
    control,
    register,
    handleSubmit,
    trigger,
    reset,
    setValue,
    resetField,
    formState: { isValid, errors, isDirty },
  } = useForm<EsopForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      vestedOver: 4,
      vestingSchedule: [
        {
          value: 1,
          percentage: 25,
        },
        {
          value: 2,
          percentage: 25,
        },
        {
          value: 3,
          percentage: 25,
        },
        {
          value: 4,
          percentage: 25,
        },
      ],
      esopAgreementOptions:
        esop?.esopAgreementOptions ?? esopAgreementOptionsList[0].value,
      vestingFrequency: VestingFrequency.YEARLY,
      cliffPeriod: 12,
    },
    resolver: yupResolver(
      yup.object().shape({
        esopAgreementOptions: yup
          .string()
          .required(
            t(
              'definition-phase.compensation.esop-options.required',
              'Required Field',
            ),
          ),
        noOfEsops: yup
          .number()
          .positive()
          .nullable(false)
          .transform((value, origin) => (origin === '' ? undefined : value))
          .test(
            'is-required',
            t(
              'definition-phase.compensation.no-of-esops.required',
              'Required Field',
            ),
            (value, ctx) => {
              if (
                ctx.parent.esopAgreementOptions === extendGrantValueType.UNIT ||
                ctx.parent.esopAgreementOptions === extendGrantValueType.BOTH
              ) {
                return !isNil(value);
              }
              return true;
            },
          ),
        amount: yup
          .number()
          .positive()
          .nullable(false)
          .transform((value, origin) => (origin === '' ? undefined : value))
          .test(
            'is-required',
            t(
              'definition-phase.compensation.no-of-esops.required',
              'Required Field',
            ),
            (value, ctx) => {
              if (
                ctx.parent.esopAgreementOptions === extendGrantValueType.CASH ||
                ctx.parent.esopAgreementOptions === extendGrantValueType.BOTH
              ) {
                return !isNil(value);
              }
              return true;
            },
          ),
        cliffPeriod: yup.number().integer().min(0).nullable(),
        vestingSchedule: yup.array().test(
          'is-valid-schedule',
          t(
            'definition-phase.compensation.esop-input.vesting-schedule.invalid',
            'Vesting schedule should add up to 100%',
          ),
          (value) =>
            Number(
              value?.reduce((prev, curr) => {
                prev += Number(curr.percentage);
                return prev;
              }, 0),
            ) === 100,
        ),
      }),
    ),
  });

  const { fields, replace } = useFieldArray({
    control,
    name: 'vestingSchedule',
  });

  useEffect(() => {
    if (uncommittedSubFormChangesCallback && isDirty)
      uncommittedSubFormChangesCallback(isDirty);
  }, [isDirty]);

  const [
    amount,
    currency,
    vestedOver,
    vestingSchedule,
    cliffPeriod,
    vestingFrequency,
    condition,
    watchAgreementOptions,
    noOfEsops,
  ] = useWatch({
    control,
    name: [
      'amount',
      'currency',
      'vestedOver',
      'vestingSchedule',
      'cliffPeriod',
      'vestingFrequency',
      'condition',
      'esopAgreementOptions',
      'noOfEsops',
    ],
  });

  useEffect(() => {
    if (esop) {
      reset(esop);
      replace(esop.vestingSchedule);
    }
  }, [esop]);

  useEffect(() => {
    resetField('amount');
    resetField('noOfEsops');
  }, [watchAgreementOptions]);

  const replaceVestingSchedule = (vestedOverInput: string) => {
    if (Number(vestedOverInput) === 3) {
      replace([
        {
          percentage: 33,
          value: 1,
        },
        {
          percentage: 33,
          value: 2,
        },
        {
          percentage: 34,
          value: 3,
        },
      ]);
    } else if (Number(vestedOverInput) === 6) {
      replace([
        {
          percentage: 16,
          value: 1,
        },
        {
          percentage: 16,
          value: 2,
        },
        {
          percentage: 16,
          value: 3,
        },
        {
          percentage: 16,
          value: 4,
        },
        {
          percentage: 16,
          value: 5,
        },
        {
          percentage: 20,
          value: 6,
        },
      ]);
    } else {
      replace(
        Array.from(Array(Number(vestedOverInput)).keys()).map((key) => ({
          percentage: Math.round((100 / Number(vestedOverInput)) * 100) / 100,
          value: key + 1,
        })),
      );
    }
  };

  useEffect(() => {
    if (vestedOver) {
      trigger('vestingSchedule');
    }
  }, [vestedOver]);

  const currencyOptions = useMemo(
    () =>
      Object.values(CurrencyCode).map((curr) => ({
        icon: <Icon.Flag name={currencyCountry[curr]} />,
        title: curr,
        label: currencyLabels[curr],
        value: curr,
      })),
    [],
  );

  const onSubmit = (values: EsopForm) => {
    onEsopAdd(values);
  };

  const esopsVestingOptions = [
    {
      title: vestingFrequencyLabel[VestingFrequency.MONTHLY],
      value: VestingFrequency.MONTHLY,
    },
    {
      title: vestingFrequencyLabel[VestingFrequency.QUARTERLY],
      value: VestingFrequency.QUARTERLY,
    },
    {
      title: vestingFrequencyLabel[VestingFrequency.YEARLY],
      value: VestingFrequency.YEARLY,
    },
    {
      title: vestingFrequencyLabel[VestingFrequency.SEMI_ANNUALLY],
      value: VestingFrequency.SEMI_ANNUALLY,
    },
  ];

  return (
    <div
      css={[
        tw`rounded-base bg-background-white p-small`,
        !isNewThemeApplied && tw`bg-grey05`,
      ]}
    >
      <div tw="flex flex-row justify-between items-start m-base mb-none">
        <p tw="text-p font-semibold">
          {t(
            'definition-phase.compensation.esop-input.header',
            'Add ESOP for Employee',
          )}
        </p>
        <button
          tw="right-base focus:(ring-transparent outline-none)"
          type="button"
          onClick={handleClose}
        >
          <Icon
            name="cross"
            fill={!isNewThemeApplied ? theme`colors.grey02` : undefined}
          />
        </button>
      </div>

      <div tw="grid grid-cols-1 gap-x-base p-base pb-0 gap-y-base">
        <TextInput.Container tw="col-span-2">
          <TextInput.Label
            htmlFor="esop-agreement"
            css={[
              tw`text-ps text-text-secondary font-normal`,
              !isNewThemeApplied && tw`text-grey01`,
            ]}
          >
            {t(
              'definition-phase.compensation.esop-input.esop-agreement.label',
              'Choose an option below to get it displayed on the ESOP Agreement',
            )}
          </TextInput.Label>
          <Controller
            name="esopAgreementOptions"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                tw="h-full"
                id="esop-agreement-options"
                data-testid="esop-agreement-options"
                variant="default"
                showArrow
                value={value}
                dropdownValues={esopAgreementOptionsList}
                placeholder={t(
                  'definition-phase.compensation.esop-input.esop-agreement-options.placeholder',
                  '',
                )}
                onChange={onChange}
              />
            )}
          />
        </TextInput.Container>
      </div>
      <div
        css={[
          tw`grid grid-cols-2 gap-x-base p-base gap-y-base pb-none`,
          watchAgreementOptions === extendGrantValueType.BOTH &&
            tw`grid-cols-3`,
        ]}
      >
        {(watchAgreementOptions === extendGrantValueType.UNIT ||
          watchAgreementOptions === extendGrantValueType.BOTH) && (
          <TextInput.Container data-testid="no-of-esops">
            <TextInput.Label
              htmlFor="noOfEsops"
              css={[
                tw`text-ps text-text-secondary font-normal`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {t(
                'definition-phase.compensation.esop-input.no-of-esops.label',
                'No. of ESOPs',
              )}
            </TextInput.Label>
            <TextInput.Container>
              <TextInput
                id="no-of-esops-input"
                data-testid="no-of-esops-input"
                type="number"
                step="0.01"
                placeholder={t(
                  'definition-phase.compensation.additional-pays.no-of-esops.placeholder',
                  '0',
                )}
                {...register('noOfEsops')}
              />
            </TextInput.Container>
            {((errors?.noOfEsops as unknown) as FieldError)?.message && (
              <TextInput.Error>
                {((errors?.noOfEsops as unknown) as FieldError)?.message}
              </TextInput.Error>
            )}
          </TextInput.Container>
        )}

        {(watchAgreementOptions === extendGrantValueType.CASH ||
          watchAgreementOptions === extendGrantValueType.BOTH) && (
          <TextInput.Container data-testid="esop-value">
            <TextInput.Label
              htmlFor="amount"
              css={[
                tw`text-ps text-text-secondary font-normal`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {t(
                'definition-phase.compensation.esop-input.amount.label',
                'Amount',
              )}
            </TextInput.Label>
            <DropdownText.Container tw="bg-white">
              <Controller
                name="currency"
                control={control}
                defaultValue={defaultCurrency ?? undefined}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    tw="h-full"
                    data-testid="currency-select"
                    variant="inline"
                    showArrow
                    value={value as string}
                    dropdownValues={currencyOptions || []}
                    placeholder={t(
                      'definition-phase.compensation.esop-input.amount.placeholder',
                      'Eg: SGD',
                    )}
                    onChange={onChange}
                  />
                )}
              />
              <DropdownText.Input
                id="amount"
                currency
                type="number"
                step="0.01"
                placeholder="0"
                tw="appearance-none"
                data-testid="currency-input"
                divStyles={tw`flex-grow`}
                {...register('amount')}
              />
            </DropdownText.Container>
            {amount && currency && (
              <TextInput.Helper tw="mt-6">
                <CurrencyHelper referenceCurrency={currency} amount={amount} />
              </TextInput.Helper>
            )}
            {((errors?.amount as unknown) as FieldError)?.message && (
              <TextInput.Error>
                {((errors?.amount as unknown) as FieldError)?.message}
              </TextInput.Error>
            )}
          </TextInput.Container>
        )}

        <TextInput.Container>
          <TextInput.Label
            htmlFor="vested-over"
            css={[
              tw`text-ps text-text-secondary font-normal`,
              !isNewThemeApplied && tw`text-grey01`,
            ]}
          >
            {t(
              'definition-phase.compensation.esop-input.vested-over.label.year',
              'Vested Over (Years)',
            )}
          </TextInput.Label>
          <Controller
            name="vestedOver"
            control={control}
            render={({ field: { value } }) => (
              <ComboBox
                tw="h-full"
                id="vested-over"
                variant="default"
                showArrow
                value={String(value)}
                dropdownValues={Array.from(Array(6).keys()).map((i) => {
                  const idx = i + 1;
                  return {
                    id: String(idx),
                    value: String(idx),
                    title: String(idx),
                  };
                })}
                placeholder={t(
                  'definition-phase.compensation.esop-input.vested-over.placeholder',
                  '',
                )}
                onChange={(e) => {
                  setValue('vestedOver', Number(e));
                  replaceVestingSchedule(e);
                }}
              />
            )}
          />
          <TextInput.Helper>
            {t(
              'definition-phase.compensation.esop-input.vested-over.helper',
              'Recommended: 3-5 years',
            )}
          </TextInput.Helper>
        </TextInput.Container>
      </div>
      <div tw="grid grid-cols-2 gap-x-base p-base gap-y-base">
        <div tw="col-span-2">
          <TextInput.Label
            css={[
              tw`text-ps text-text-secondary font-normal`,
              !isNewThemeApplied && tw`text-grey01`,
            ]}
          >
            {t(
              'definition-phase.compensation.esop-input.vesting-schedule.label',
              'Vesting Schedule',
            )}
          </TextInput.Label>

          <div tw="flex col-span-2 gap-x-base mt-tiny">
            {fields.map((field, index) => {
              const registeredField = register(
                `vestingSchedule.${index}.percentage`,
              );
              return (
                <TextInput.Container key={field.id} tw="w-1/2">
                  <TextInput.Label
                    css={[
                      tw`text-pxs text-text-secondary`,
                      !isNewThemeApplied && tw`text-grey01`,
                    ]}
                  >
                    {t(
                      'definition-phase.compensation.esop-input.vesting-schedule.year',
                      {
                        defaultValue: 'Year {{year}}',
                        replace: {
                          year: field.value,
                        },
                      },
                    )}
                  </TextInput.Label>
                  <TextInput
                    id="vesting-schedule"
                    data-testid="vesting-schedule"
                    tw="w-1/2"
                    type="text"
                    placeholder={t(
                      'definition-phase.compensation.additional-pays.vesting-schedule.placeholder',
                      '100',
                    )}
                    units="%"
                    {...registeredField}
                    onChange={(e) => {
                      registeredField
                        .onChange(e)
                        .then(() => trigger('vestingSchedule'));
                    }}
                  />
                </TextInput.Container>
              );
            })}
          </div>

          {((errors?.vestingSchedule as unknown) as FieldError)?.message && (
            <TextInput.Error>
              {((errors?.vestingSchedule as unknown) as FieldError)?.message}
            </TextInput.Error>
          )}
        </div>

        <TextInput.Container>
          <TextInput.Label
            tw="flex gap-x-6 items-center text-ps text-grey01 font-normal"
            css={[
              tw`text-text-secondary flex gap-x-6 items-center text-ps font-normal`,
              !isNewThemeApplied && tw`text-grey01`,
            ]}
            htmlFor="cliff-period"
          >
            {t(
              'definition-phase.compensation.esop-input.cliff-period.label',
              'Cliff Period',
            )}
            <ToolTip
              variant="top"
              content={t(
                'definition-phase.compensation.esop-input.cliff-period.tooltip',
                'Minimum time period employee should stay in company to be eligible for ESOPs',
              )}
            >
              <Icon
                name="info"
                height="16"
                width="16"
                fill={!isNewThemeApplied ? theme`colors.grey02` : undefined}
              />
            </ToolTip>
          </TextInput.Label>
          <TextInput
            id="cliff-period"
            tw="w-1/2"
            placeholder={t(
              'definition-phase.compensation.esop-input.cliff-period.placeholder',
              'Eg. 6',
            )}
            units={t(
              'definition-phase.compensation.esop-input.cliff-period.months',
              'Months',
            )}
            {...register('cliffPeriod')}
            error={!!errors.cliffPeriod}
            type="number"
            step="1"
          />
        </TextInput.Container>

        <TextInput.Container>
          <TextInput.Label
            htmlFor="vesting-frequency"
            css={[
              tw`text-ps text-text-secondary font-normal`,
              !isNewThemeApplied && tw`text-grey01`,
            ]}
          >
            {t(
              'definition-phase.compensation.esop-input.vesting-frequency.label',
              'Vesting Frequency',
            )}
          </TextInput.Label>
          <Controller
            name="vestingFrequency"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                tw="h-full"
                id="vesting-frequency"
                variant="default"
                showArrow
                value={value}
                dropdownValues={esopsVestingOptions}
                placeholder={t(
                  'definition-phase.compensation.esop-input.amount.placeholder',
                  'Eg: Annually',
                )}
                onChange={onChange}
              />
            )}
          />
        </TextInput.Container>

        <TextInput.Container tw="col-span-2">
          <TextInput.Label
            htmlFor="condition"
            css={[
              tw`text-ps text-text-secondary font-normal`,
              !isNewThemeApplied && tw`text-grey01`,
            ]}
          >
            {t(
              'definition-phase.compensation.esop-input.condition.label',
              'Notes (Optional)',
            )}
          </TextInput.Label>
          <TextInput
            id="condition"
            tw="w-full"
            type="text"
            placeholder={t(
              'definition-phase.compensation.esop-input.condition.placeholder',
              'Add a note.',
            )}
            {...register('condition')}
          />
        </TextInput.Container>

        <EsopPreviewStatement
          companyName={companyName}
          amount={amount}
          currency={currency}
          vestedOver={vestedOver}
          vestingSchedule={vestingSchedule}
          cliffPeriod={cliffPeriod}
          vestingFrequency={vestingFrequency}
          condition={condition}
          esopAgreementOption={watchAgreementOptions}
          noOfEsops={noOfEsops}
        />

        <div tw="flex gap-x-small items-center justify-center col-span-2">
          <Button variant="outline" size="medium" onClick={handleClose}>
            {t('definition-phase.compensation.esop-input.cancel', 'Cancel')}
          </Button>
          <Button
            variant="default"
            size="medium"
            disabled={!isValid}
            onClick={() => {
              if (Number(vestedOver) === 1) {
                handleOpenConfirmation();
              } else {
                handleSubmit(onSubmit)();
              }
            }}
          >
            {esop
              ? t('definition-phase.compensation.esop-input.update', 'Update')
              : t('definition-phase.compensation.esop-input.add', 'Add')}
          </Button>
        </div>
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={() => {
          handleSubmit(onSubmit)();
          handleCloseConfirmation();
        }}
        icon="info"
        title={t(
          'definition-phase.compensation.one-year-vesting-confirmation.header',
          'Vesting Period',
        )}
        description={t(
          'definition-phase.compensation.one-year-vesting-confirmation.description',
          'Vesting period chosen is 1 Year and employee will be eligible to exercise 100% of stock options after a year. Do you want to still go ahead?',
        )}
        buttonText={t(
          'definition-phase.compensation.one-year-vesting-confirmation.continue',
          'Continue',
        )}
        loading={false}
      />
    </div>
  );
};

export default EsopInput;
