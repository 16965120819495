/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from 'common/components/text-input';

import { MemberBasicDetailsFormValues } from '..';

interface FullLegalNameInputProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
}

const FullLegalNameInput: React.FC<FullLegalNameInputProps> = ({ methods }) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="legalName">
        {t('member.basic-details.name.label', 'Full Legal Name')}
      </TextInput.Label>
      <TextInput
        id="legalName"
        placeholder={t(
          'member.basic-details.name.placeholder',
          'John David Markus',
        )}
        {...register('legalName')}
        error={!!errors.legalName}
      />
    </TextInput.Container>
  );
};

export default FullLegalNameInput;
