import { gql } from '@apollo/client';

export default gql`
  query GetCountries($country: CountryCode, $mainCurrency: CurrencyCode) {
    countries(country: $country, mainCurrency: $mainCurrency) {
      name
      code
      region
      mainCurrency
      supportedCurrencies
      compliantCurrencies
    }
  }
`;
