const routes = {
  root: 'integrations',
  profileCreator: {
    entities: 'entities',
    entity: 'entity',
    fieldMap: 'field-map',
    edit: 'edit',
    view: 'view',
    toString: (): string => 'profile-creator',
  },
};
export default routes;
