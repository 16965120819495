import React, { useContext } from 'react';

import tw from 'twin.macro';

import Card from '../../../card';
import { ThemeContext } from '../../../theme-provider';

const Form: React.FC = () => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const getRange = (n: number) => Array.from(Array(n).keys());

  return (
    <Card>
      <div tw="flex flex-col p-base pt-large">
        {getRange(2).map((i) => (
          <div tw="w-full h-full flex" key={i}>
            <div tw="w-full flex">
              <div tw="w-full pt-base animate-pulse">
                {i === 0 ? (
                  <div
                    css={[
                      tw`h-small rounded-tiny w-1/6 ml-base bg-background-action-hover`,
                      !isNewThemeApplied && tw`bg-grey02`,
                    ]}
                  />
                ) : (
                  <div
                    css={[
                      tw`tablet:rounded-base desktop:rounded-base bg-background-white border p-base ml-base mr-base mb-base border-border-primary`,
                      !isNewThemeApplied && tw`border-grey02`,
                    ]}
                  >
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/5 ml-small border-border-secondary`,
                        !isNewThemeApplied && tw`border-grey04`,
                      ]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div tw="flex p-base pt-none">
        {getRange(3).map((i) => (
          <div tw="w-full h-full flex" key={i}>
            <div tw="w-1/2 flex">
              <div tw="w-full pt-tiny animate-pulse">
                {i === 0 && (
                  <div
                    css={[
                      tw`h-12 rounded-tiny w-full ml-base bg-background-action-hover`,
                      !isNewThemeApplied && tw`bg-grey02`,
                    ]}
                  />
                )}
                {i === 1 && (
                  <div
                    css={[
                      tw`h-12 rounded-tiny w-full ml-base bg-background-action-hover`,
                      !isNewThemeApplied && tw`bg-grey02`,
                    ]}
                  />
                )}
                {i === 2 && (
                  <div
                    css={[
                      tw`h-12 rounded-tiny w-full ml-base bg-background-action-hover`,
                      !isNewThemeApplied && tw`bg-grey02`,
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div tw="flex p-base pt-none">
        {getRange(3).map((i) => (
          <div
            css={[
              tw`w-full h-full flex tablet:rounded-base desktop:rounded-base bg-background-white border p-base m-base mt-tiny border-border-primary`,
              !isNewThemeApplied && tw`border-grey02`,
            ]}
            key={i}
          >
            <div tw="w-full flex">
              <div tw="w-full pt-tiny animate-pulse">
                {i === 0 && (
                  <div tw="flex flex-row w-full">
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/4 ml-base bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04`,
                      ]}
                    />
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/4 ml-tiny bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04`,
                      ]}
                    />
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-3/4 ml-tiny mr-80 bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04`,
                      ]}
                    />
                  </div>
                )}
                {i === 1 && (
                  <div tw="flex flex-row w-full">
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-3/4 ml-base bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04`,
                      ]}
                    />
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/4 ml-extra-large bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04 ml-80`,
                      ]}
                    />
                  </div>
                )}
                {i === 2 && (
                  <div tw="flex flex-row w-full">
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/4 ml-base bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04`,
                      ]}
                    />
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/4 ml-80 bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04`,
                      ]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Form;
