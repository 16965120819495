import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../../../text-input';
import { TableFilterFormRangeValue } from '../../../../types';
import Label from '../label';

const RangeFilterInput: React.FC<{
  value: TableFilterFormRangeValue;
  onChange: (val: TableFilterFormRangeValue) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation('common.table');

  return (
    <div tw="grid grid-cols-2 gap-small">
      <div>
        <Label>{t('filters.minimum', 'Minimum:')}</Label>
        <TextInput
          type="number"
          data-testid="minimum-matching"
          tw="mt-extra-small"
          placeholder="Days"
          value={value?.minimum ?? ''}
          onChange={(e) => {
            onChange({
              minimum: e.target.value,
              maximum: value?.maximum,
            });
          }}
        />
      </div>
      <div>
        <Label>{t('filters.maximum', 'Maximum:')}</Label>
        <TextInput
          type="number"
          data-testid="maximum-matching"
          tw="mt-extra-small"
          placeholder="Days"
          value={value?.maximum ?? ''}
          onChange={(e) => {
            onChange({
              minimum: value?.minimum,
              maximum: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default RangeFilterInput;
