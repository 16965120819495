import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { isEmpty, isEqual, isNil, omitBy } from 'lodash';

type UpdateSearchParamsReturn = (
  newParams: { key: string; value: string }[],
  paramsToRemove?: string[],
) => void;

type DeleteSearchParamsReturn = (newParams: string[]) => void;

// react-use only allows single retrieval
export const useSearchParams = (...params: string[]): (string | null)[] => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const results: (string | null)[] = [];

  params.forEach((param) => {
    results.push(searchParams.get(param));
  });

  return results;
};

export const useLazySearchParams = (): ((
  ...params: string[]
) => (string | null)[]) => {
  const { search } = useLocation();
  const nativeSearch = document.location.search;
  const searchParams = new URLSearchParams(
    search && search.trim() !== '' ? search : nativeSearch,
  );

  return (...params: string[]) => {
    const results: (string | null)[] = [];
    params.forEach((param) => {
      results.push(searchParams.get(param));
    });

    return results;
  };
};

export const useUpdateSearchParams = (): UpdateSearchParamsReturn => {
  const navigate = useNavigate();
  const { search, pathname, state } = useLocation();

  return (
    newParams: { key: string; value: string }[],
    paramsToRemove?: string[],
  ) => {
    const params = new URLSearchParams(search);
    const oldParamsString = params.toString();
    if (paramsToRemove && paramsToRemove?.length > 0) {
      paramsToRemove.forEach((key) => {
        params.delete(key);
      });
    }
    newParams.forEach((newValue) => {
      if (newValue?.key && newValue?.value) {
        params.set(newValue.key, newValue.value.toString());
      }
    });

    const newParamsString = params.toString();
    if (newParamsString !== oldParamsString) {
      if (state && !isEmpty(state)) {
        navigate(`${pathname}?${newParamsString}`, {
          state,
        });
      } else {
        navigate(`${pathname}?${newParamsString}`);
      }
    }
  };
};

export const useRemoveSearchParams = (): DeleteSearchParamsReturn => {
  const navigate = useNavigate();
  const { search, pathname, state } = useLocation();

  return (paramsToRemove: string[]) => {
    const params = new URLSearchParams(search);
    const oldParamsString = params.toString();
    paramsToRemove.forEach((key) => {
      params.delete(key);
    });
    const newParamsString = params.toString();
    if (newParamsString !== oldParamsString) {
      if (state && !isEmpty(state)) {
        navigate(`${pathname}?${newParamsString}`, {
          state,
        });
      } else {
        navigate(`${pathname}?${newParamsString}`);
      }
    }
  };
};

export const useUrlFilterBinding = ({
  key,
  value,
  onChange,
  resetPage,
}: {
  key: string;
  value?: string | null;
  resetPage?: boolean;
  onChange?: (val?: string) => void;
}) => {
  const [searchParam] = useSearchParams(key);
  const updateSearchParams = useUpdateSearchParams();
  const removeSearchParams = useRemoveSearchParams();

  useEffect(() => {
    if (
      !isEqual(omitBy(searchParam, isNil), omitBy(value, isNil)) &&
      onChange
    ) {
      onChange(searchParam ?? undefined);
    }
  }, [searchParam, value]);

  const handleChange = (newValue?: string) => {
    if (newValue === value) return;

    if (newValue) {
      const params = [
        {
          key,
          value: newValue,
        },
        ...(resetPage
          ? [
              {
                key: 'page',
                value: '1',
              },
            ]
          : []),
      ];
      updateSearchParams(params);
    } else if (resetPage) {
      updateSearchParams(
        [
          {
            key: 'page',
            value: '1',
          },
        ],
        [key],
      );
    } else {
      removeSearchParams([key]);
    }

    if (onChange) onChange(newValue);
  };

  const handleRemove = () => {
    removeSearchParams([key]);
    if (onChange) onChange(undefined);
  };

  return { handleChange, handleRemove };
};
