import React, { useContext, useId } from 'react';

import tw, { TwStyle } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';

type IconButtonVariant = 'outline' | 'default' | 'filled';
type IconButtonSize = 'atom' | 'small' | 'medium' | 'large';

const variantStyles = (newTheme: boolean) => ({
  default: [tw`bg-transparent`],
  outline: [
    tw`bg-transparent border border-border-primary`,
    !newTheme && tw`border-primary border-opacity-20`,
  ],
  filled: [
    tw`bg-background-white border border-border-primary`,
    !newTheme && tw`border-primary border-opacity-20`,
  ],
});

const sizeStyles = (newTheme: boolean) => ({
  atom: [tw`h-[28px] w-[28px] p-extra-small`, !newTheme && tw`h-24 w-24`], // new theme w-12 icon size
  small: [tw`h-[42px] w-[42px]`, !newTheme && tw`h-36 w-36`], // new theme w-16 icons size
  medium: [tw`h-[46px] w-[46px]`, !newTheme && tw`h-46 w-46`], // new theme w-20 icon size
  large: [tw`h-[50px] w-[50px]`, !newTheme && tw`h-64 w-64`], // new theme w-24 icon size
});

const iconSize = () => ({
  atom: [tw`h-[12px] w-[12px]`],
  small: [tw`h-[16px] w-[16px]`],
  medium: [tw`h-[20px] w-[20px]`],
  large: [tw`h-[24px] w-[24px]`],
});

export interface IconButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size: IconButtonSize;
  variant?: IconButtonVariant;
  name: string;
  fill?: string;
  loading?: boolean;
  stroke?: string;
  iconStyles?: TwStyle;
  iconProps?: React.SVGProps<SVGSVGElement> & { css?: TwStyle[] };
  buttonStyles?: TwStyle;
}

const IconButton: React.FC<IconButtonProps> = ({
  size,
  variant = 'default',
  name,
  fill,
  stroke,
  loading = false,
  iconStyles,
  iconProps,
  buttonStyles,
  ...props
}) => {
  const id = useId();
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <button
      type="button"
      css={[
        tw`flex flex-row items-center justify-center rounded-base disabled:opacity-30 focus:outline-none`,
        ...variantStyles(isNewThemeApplied)[variant],
        ...sizeStyles(isNewThemeApplied)[size],
        buttonStyles,
      ]}
      {...props}
    >
      <Icon
        key={`${id}-${name}-${loading}`}
        name={loading ? 'spinner' : name} // Don't prefer to use this convention, it need `key` to remounted
        fill={loading ? 'transparent' : fill}
        stroke={stroke}
        data-testid={loading ? 'spinner-icon' : `${name}-icon`}
        css={[iconSize()[size], loading && tw`animate-spin`, iconStyles]}
        {...iconProps}
      />
    </button>
  );
};

export default IconButton;
