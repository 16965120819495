import { gql } from '@apollo/client';

export default gql`
  mutation GenerateIntercomHash($input: IntercomHashInput) {
    intercomHash(input: $input)
      @rest(
        type: "IntercomHashResponse"
        method: "POST"
        path: "intercomhash"
        endpoint: "user"
      ) {
      hash
    }
  }
`;
