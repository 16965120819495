/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import 'twin.macro';

import { Trans, useTranslation } from 'react-i18next';

import { isNil } from 'lodash';

import { getEsopFromGrant } from 'contract-onboarding/company/pages/definition-phase/pages/compensation';
import { getCurrencyOptions } from 'contract-onboarding/company/services/compensation';
import { useSalaryRevisionNotes } from 'performance-reviews/hooks';
import CompensationCard from 'team/company/pages/compensation/components/compensation-card';

import {
  Contract,
  CountryCode,
  Maybe,
  PerformanceReview,
  PerformanceReviewStatus,
  useGetSupportedCurrenciesQuery,
} from '__generated__/graphql';

interface Props {
  performanceReview: Maybe<PerformanceReview>;
  contract: Maybe<Contract>;
}
const PerformanceReviewDetails: React.FC<Props> = ({
  performanceReview,
  contract,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { effectiveMonth, getNote } = useSalaryRevisionNotes();

  const { data: supportedCurrenciesData } = useGetSupportedCurrenciesQuery({
    variables: {
      country: contract?.country as CountryCode,
    },
    skip: !contract?.country,
  });

  const compliantCurrencies =
    supportedCurrenciesData?.country?.supportedCurrencies || [];

  const currencyOptions = useMemo(
    () => getCurrencyOptions(compliantCurrencies),
    [compliantCurrencies],
  );

  const esop = useMemo(() => {
    const grants = contract?.compensation?.grant;
    if (!grants || grants.length === 0) return null;
    return getEsopFromGrant(grants[0]);
  }, [contract]);

  useEffect(() => {
    getNote(contract?.id, performanceReview?.effectiveDate);
  }, [contract, performanceReview]);

  const shouldShowProposedRevision =
    !isNil(performanceReview?.status) &&
    [
      PerformanceReviewStatus.APPROVED,
      PerformanceReviewStatus.ACTIVATED,
      PerformanceReviewStatus.SENT_TO_OPS,
      PerformanceReviewStatus.SENT_FOR_MEMBER_APPROVAL,
    ].includes(performanceReview?.status as PerformanceReviewStatus);

  return (
    <div tw="flex flex-col gap-extra-large">
      {effectiveMonth && shouldShowProposedRevision && (
        <div tw="bg-background-primary border border-border-primary rounded-base p-base text-text-primary">
          <Trans
            t={t}
            key="compensation-section.revision-reflect"
            values={{ effectiveMonth }}
          >
            Revised salary update will start reflecting from{' '}
            <span tw="font-semibold">{{ effectiveMonth }}</span>
          </Trans>
        </div>
      )}
      <div tw="flex w-full gap-large">
        <CompensationCard
          title={t('compensation-section.current-salary', 'Current salary')}
          position={contract?.position}
          contractType={contract?.type}
          compensation={contract?.compensation}
          companyName={contract?.company?.displayName}
          esop={esop}
          currencyOptions={currencyOptions}
          country={contract?.country}
          countryStateCode={contract?.countryStateCode}
          probationBasePay={contract?.compensation?.probationBasePay}
          tw="flex-1"
        />

        {shouldShowProposedRevision && (
          <CompensationCard
            title={t(
              'compensation-section.proposed-header',
              'Proposed Revision',
            )}
            performanceReview={performanceReview}
            position={performanceReview?.designation?.name}
            contractType={contract?.type}
            compensation={performanceReview?.salaryReview?.revisedCompensation}
            companyName={contract?.company?.displayName}
            currencyOptions={currencyOptions}
            esop={esop}
            isRevisionCard
            country={contract?.country}
            countryStateCode={contract?.countryStateCode}
            probationBasePay={contract?.compensation?.probationBasePay}
            tw="flex-1"
          />
        )}
      </div>
    </div>
  );
};

export default PerformanceReviewDetails;
