import { useTranslation } from 'react-i18next';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  SalaryCalculationRequestEmailParam,
  useSalaryCalculatorRequestEmailMutation,
} from '__generated__/graphql';

export default (
  params: SalaryCalculationRequestEmailParam,
): {
  loading: boolean;
  handleSendEmailClick: () => void;
} => {
  const { t } = useTranslation('salary-calculator');
  const [
    sendSalaryCalculationRequestEmail,
    { loading },
  ] = useSalaryCalculatorRequestEmailMutation({
    onCompleted() {
      successNotification(
        t('breakdown-request.success.header', 'Done!'),
        t('breakdown-request.success.message', 'Request Sent Successfully!'),
        true,
      );
    },
    onError() {
      errorNotification(
        t('breakdown-request.error.header', 'Whoops!'),
        t(
          'breakdown-request.error.message',
          'We were unable to send the request. Please try again later.',
        ),
        true,
      );
    },
  });

  const handleSendEmailClick = async () => {
    await sendSalaryCalculationRequestEmail({
      variables: {
        params,
      },
    });
  };

  return { loading, handleSendEmailClick };
};
