import { TFunction } from 'i18next';

import OnboardingAction from 'contract-onboarding/models/onboarding-action';

import {
  Contract,
  ContractOnboardingStatus,
  ContractType,
  Member,
} from '__generated__/graphql';

import { PendingOn } from '../../../components/pending-on-pill';

type StatusLabelTFunction = (
  t: TFunction<'contract-onboarding.member'>,
) => string;

type ContractOnboardingStatusConfig = {
  title: (t: TFunction<'contract-onboarding.member'>, name?: string) => string;
  subtitle?: (t: TFunction<'contract-onboarding.member'>) => string;
  description: (
    t: TFunction<'contract-onboarding.member'>,
    name?: string,
    gender?: Member['gender'],
    contractType?: Contract['type'],
  ) => string;
  statusLabel: StatusLabelTFunction;
  pendingOn: PendingOn;
  primaryAction?: OnboardingAction;
  optionalAction?: OnboardingAction;
};

const memberVerificationCompleted: ContractOnboardingStatusConfig = {
  title: (t) =>
    t(
      'onboarding-phase.verification-completed.title',
      'Verification is completed',
    ),
  description: (t) =>
    t(
      'onboarding-phase.verification-completed.description',
      'We will be activating access to the platform very soon.',
    ),
  statusLabel: (t) =>
    t('onboarding-phase.verification-progress.label', 'Verification completed'),
  pendingOn: PendingOn.MULTIPLIER,
};

const statusConfig: {
  [key: string]: ContractOnboardingStatusConfig;
} = {
  [ContractOnboardingStatus.MEMBER_DATA_ADDED]: {
    title: (t) =>
      t('onboarding-phase.member-data-added.title', 'Review & Confirm'),
    description: (t, name, gender, contractType) =>
      contractType === ContractType.CONTRACTOR
        ? t(
            'onboarding-phase.member-data-added.description-contractor',
            'Please review the agreement details and your personal details carefully.\nOnce reviewed, click Confirm, so that our team can start the verification process.',
          )
        : t(
            'onboarding-phase.member-data-added.description',
            'You can review the details of your employment and all the data you have provided during the onboarding, in this page. Please confirm once you have reviewed everything and we will take care of the rest.',
          ),
    statusLabel: (t) => t('onboarding-phase.member-data-added.label', ''),
    pendingOn: PendingOn.YOU,
    primaryAction: OnboardingAction.CONFIRM,
  },
  [ContractOnboardingStatus.MEMBER_COMPLETED]: {
    title: (t) =>
      t(
        'onboarding-phase.member-completed.title',
        'We have a few things to do on our end',
      ),
    subtitle: (t) =>
      t('onboarding-phase.member-completed.subtitle', '1 - 2 business days'),
    description: (t) =>
      t(
        'onboarding-phase.member-completed.description',
        'Great job! You have completed your onboarding and now we will be taking the next steps to verify everything and complete your onboarding.',
      ),
    statusLabel: (t) =>
      t('onboarding-phase.member-completed.label', 'Starting verification'),
    pendingOn: PendingOn.MULTIPLIER,
    optionalAction: OnboardingAction.VIEW_DRAFT,
  },
  [ContractOnboardingStatus.MEMBER_VERIFICATION_IN_PROGRESS]: {
    title: (t) =>
      t(
        'onboarding-phase.verification-progress.title',
        'We are verifying the details',
      ),
    description: (t) =>
      t(
        'onboarding-phase.verification-progress.description',
        'We are verifying the details and the documents you have provided. We will get in touch if any clarification is needed. We will try to get this completed as soon as we can.',
      ),
    statusLabel: (t) =>
      t(
        'onboarding-phase.verification-progress.label',
        'Verification in progress',
      ),
    pendingOn: PendingOn.MULTIPLIER,
  },
  [ContractOnboardingStatus.MEMBER_VERIFICATION_COMPLETED]: memberVerificationCompleted,
  [ContractOnboardingStatus.ORDER_FORM_SENT_TO_MULTIPLIER]: memberVerificationCompleted,
  [ContractOnboardingStatus.ORDER_FORM_SIGNED_BY_MULTIPLIER]: memberVerificationCompleted,
  [ContractOnboardingStatus.SIGNATURE_MULTIPLIER_SENT]: memberVerificationCompleted,
  [ContractOnboardingStatus.SIGNATURE_MULTIPLIER_SIGNED]: memberVerificationCompleted,
};

export default statusConfig;
