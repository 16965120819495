/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, useModal } from '@multiplier/common';
import isNil from 'lodash/isNil';
import 'twin.macro';
import * as yup from 'yup';

import AppFeature from 'app/features';
import { successNotification } from 'app/services/notification-services';
import { EmployeeDetailSections } from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import { useSubmitBenefits } from 'contract-onboarding/hooks';
import { groupInsuranceByType } from 'insurance/services/insurance-service';
import { Sections } from 'team/components/employee-details-component';

import {
  Benefit,
  BenefitInsuranceType,
  BenefitPartnerStatus,
  BenefitStatus,
  BenefitType,
  useGetBenefitPartnerCountryDetailsLazyQuery,
  useGetCountryInsuranceLazyQuery,
  useGetSingleBenefitLazyQuery,
} from '__generated__/graphql';

import { BenefitFormValues } from '../../company/pages/definition-phase/pages/benefits/benefit-view-form';
import {
  getBenefitId,
  getDependentCount,
  getEmployerPayPercentage,
  getIncludeDependents,
} from '../../company/services/benefits';
import ContractReviewSectionModal, {
  FooterSection,
} from '../contract-review-section-modal';
import { DetailForm } from '../layout';
import SectionActionBar from '../section-action-bar';
import FormData from './form-data';
import InsuranceSectionV2, {
  InsuranceSectionProps,
} from './insurance-section-v2';

export enum InsuranceSectionVariant {
  MEMBER,
  COMPANY,
}

const InsuranceSection: React.FC<InsuranceSectionProps> = ({
  id,
  benefits,
  country,
  contractType,
  variant = InsuranceSectionVariant.COMPANY,
  edit,
  uncommittedChangesCallback,
  toggle,
  open,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const [benefitType, setBenefitType] = useState<BenefitType>(
    BenefitType.INSURANCE,
  );

  const [
    getCountryInsurance,
    { data: countryData },
  ] = useGetCountryInsuranceLazyQuery();

  const [
    getBenefitPartnerCountryDetails,
    { data: benefitPartnerCountryDetails },
  ] = useGetBenefitPartnerCountryDetailsLazyQuery();

  // Assume only one benefit of type insurance
  const benefit = benefits?.find(
    (contractBenefit) =>
      contractBenefit?.benefit?.type === BenefitType.INSURANCE,
  )?.benefit;

  const benefitPartnerCountryData = useMemo(
    () => ({
      ...(benefitPartnerCountryDetails?.benefitPartnerCountriesWithPagination
        ?.data?.[0] ?? {}),
      benefits: countryData?.benefits as Benefit[],
    }),
    [benefitPartnerCountryDetails, countryData],
  );
  const minimumErSplitPercentage =
    benefitPartnerCountryData?.minimumErSplitPercentage;

  const [
    getSingleBenefit,
    { data: singleBenefitData },
  ] = useGetSingleBenefitLazyQuery();

  const contractBenefit = useMemo(
    () => benefits?.filter((val) => !isNil(val))?.[0],
    [benefits],
  );

  useEffect(() => {
    if (benefit?.id) {
      getSingleBenefit({
        variables: {
          id: benefit.id,
        },
      });
    }
  }, [benefit]);

  useEffect(() => {
    if (variant === InsuranceSectionVariant.COMPANY && country) {
      getCountryInsurance({
        variables: {
          filters: {
            country,
            contractType,
            status: BenefitStatus.ACTIVE,
            isDefault: true,
          },
        },
      });
    }
  }, [country]);

  const [showEdit, toggleEdit] = useToggle(false);
  const [
    showEditFormModal,
    handleCloseEditFormModal,
    handleOpenEditFormModal,
  ] = useModal(false);

  const modalCloseHandler = () => {
    toggleEdit(false);
    reset();
    handleCloseEditFormModal();
  };

  const methods = useForm<BenefitFormValues>({
    mode: 'onChange',
    defaultValues: {
      benefitId: null,
      includeDependents: false,
    },
    resolver: yupResolver(
      yup.object().shape({
        benefitId: benefitPartnerCountryData?.isInsuranceMandatory
          ? yup.string().uuid().required()
          : yup.string().uuid().nullable(),
        includeDependents: yup.boolean().required(),
        dependentCount: yup.number().nullable(),
        employerPayPercentage: yup
          .number()
          .max(
            100,
            t(
              'insurance.er-split.max-validation',
              'Employer pay percentage cannot be more than 100%',
            ),
          )
          .test(
            'minimum-er-percentage',
            t('insurance.er-split.min-validation', {
              defaultValue:
                'Minimum contribution has to be {{minimumPercentage}}%',
              replace: {
                minimumPercentage: minimumErSplitPercentage,
              },
            }),
            (value) => {
              if (
                !isNil(value) &&
                benefitPartnerCountryData?.erSplitApplicable &&
                !isNil(minimumErSplitPercentage) &&
                value < minimumErSplitPercentage
              )
                return false;
              return true;
            },
          )
          .typeError(
            t(
              'insurance.er-split.must-be-number',
              'Employer pay percentage must be a number',
            ),
          )
          .nullable(),
      }),
    ),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid },
    setValue,
    getValues,
  } = methods;

  const selectedBenefit = useMemo(
    () =>
      benefitPartnerCountryData?.benefits?.find(
        (ele) => ele?.id === watch('benefitId'),
      ),
    [benefitPartnerCountryData?.benefits, watch('benefitId')],
  );

  useEffect(() => {
    const dependantCount = getDependentCount(benefits);

    if (benefits && benefits.length > 0) {
      const benefitId = getBenefitId(benefits);
      if (benefitId === getValues('benefitId')) return;
      const employerPayPercentage = getEmployerPayPercentage(benefits);
      reset({
        ...getValues(),
        benefitId: getBenefitId(benefits),
        includeDependents: getIncludeDependents(benefits),
        employerPayPercentage: !isNil(employerPayPercentage)
          ? String(employerPayPercentage)
          : null,
      });

      if (dependantCount) {
        reset({
          ...getValues(),
          dependentCount: `${dependantCount}`,
        });
      }
    }
  }, [benefits]);

  useEffect(() => {
    if (
      variant === InsuranceSectionVariant.COMPANY &&
      country &&
      contractType &&
      benefitType
    ) {
      getBenefitPartnerCountryDetails({
        variables: {
          filters: {
            countries: [country],
            memberType: [contractType],
            insuranceType:
              benefitType === BenefitType.INSURANCE
                ? BenefitInsuranceType.INDIVIDUAL
                : BenefitInsuranceType.FAMILY_INSURANCE,
            statuses: [BenefitPartnerStatus.ACTIVE],
          },
          pageRequest: {
            pageSize: 20,
            pageNumber: 0,
          },
        },
      });
    }
  }, [benefitType, country, contractType]);

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.INSURANCE_DETAILS,
        value: isDirty,
      });
  }, [isDirty]);

  useEffect(() => {
    if (selectedBenefit?.id !== benefit?.id) {
      setValue('includeDependents', false);
    } else {
      setValue('dependentCount', `${getDependentCount(benefits)}`);
    }
  }, [selectedBenefit, benefit, benefits]);

  useEffect(() => {
    if (watch('includeDependents')) {
      if (selectedBenefit?.id !== benefit?.id) {
        setValue('dependentCount', '1');
      }
    }
  }, [watch('includeDependents')]);
  useEffect(() => {
    if (selectedBenefit) {
      if (
        selectedBenefit?.type === BenefitType.FAMILY_INSURANCE &&
        selectedBenefit?.dependentMaxCount !== null
      ) {
        setValue('dependentCount', `${selectedBenefit?.dependentMaxCount}`);
        setValue('includeDependents', true);
      }
    }
  }, [selectedBenefit]);

  const { onSubmit, loading } = useSubmitBenefits(id, () => {
    modalCloseHandler();
    successNotification(
      t(
        'onboarding-phase.insurance-section.success-notification',
        'Insurance details have been updated in the contract.',
      ),
      '',
      false,
    );
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.INSURANCE_DETAILS,
        value: false,
      });
  });

  const processFormValues = async (values: BenefitFormValues) => {
    const erSplitApplicable =
      benefitPartnerCountryDetails?.benefitPartnerCountriesWithPagination
        ?.data?.[0]?.erSplitApplicable;
    const processedFormvalues = {
      ...values,
      employerPayPercentage: erSplitApplicable
        ? values.employerPayPercentage
        : null,
    };
    await onSubmit(processedFormvalues);
  };

  const showDependents = React.useMemo(() => {
    if (
      watch('benefitId') &&
      benefitType === BenefitType.INSURANCE &&
      !Object.keys(
        groupInsuranceByType(countryData?.benefits as Benefit[]),
      ).includes(BenefitType.FAMILY_INSURANCE)
    )
      return true;

    return false;
  }, [benefitType, countryData, watch('benefitId')]);

  useEffect(() => {
    if (
      watch('benefitId') &&
      benefitType === BenefitType.INSURANCE &&
      Object.keys(
        groupInsuranceByType(countryData?.benefits as Benefit[]),
      ).includes(BenefitType.FAMILY_INSURANCE)
    ) {
      setValue('dependentCount', null);
      setValue('includeDependents', false);
    }
  }, [benefitType, countryData, watch('benefitId')]);

  const tooltipContent =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    isNil(selectedBenefit?.id) ? (
      <div tw="font-medium text-pxs text-center py-8 px-12 self-stretch">
        {t(`benefits.${country}-insurance-continue-tooltip`, {
          defaultValue:
            'Select an insurance plan. Providing insurance is mandatory by law for employees in {{country}}.',
          replace: {
            country,
          },
        })}
      </div>
    ) : null;

  return (
    <Accordion
      name={t('onboarding-phase.insurance.header', 'Insurance')}
      description={t(
        'onboarding-phase.insurance.description',
        'Category description to better explain what this section is about.',
      )}
      edit={edit}
      icon="shield-check"
      onEditClick={() => {
        toggleEdit(true);
        handleOpenEditFormModal();
        if (toggle) toggle(Sections.INSURANCE);
      }}
      warning={uncommittedChangesCallback && isDirty}
      onClick={() => {
        if (toggle) toggle(Sections.INSURANCE);
      }}
      open={open}
    >
      <FormProvider {...methods}>
        <DetailForm onSubmit={handleSubmit(processFormValues)}>
          <FormData
            isFormEditing={false}
            variant={variant}
            features={singleBenefitData?.benefit?.features}
            benefitPartnerCountryData={benefitPartnerCountryData}
            selectedBenefit={selectedBenefit}
            contractBenefit={contractBenefit}
            setBenefitType={(type) => setBenefitType(type)}
            benefitType={benefitType}
            showDependents={showDependents}
            benefits={benefits}
            country={country}
            type={benefitType}
            contractType={contractType}
          />
        </DetailForm>
        {showEditFormModal && (
          <ContractReviewSectionModal
            modalTitle="Edit Insurance Details"
            onClose={modalCloseHandler}
          >
            <DetailForm onSubmit={handleSubmit(processFormValues)}>
              <div
                tw="flex flex-col p-extra-large py-[20px] gap-y-large"
                data-testid="edit-insurance-detail-modal"
              >
                <FormData
                  type={benefitType}
                  variant={variant}
                  isFormEditing={showEdit}
                  features={singleBenefitData?.benefit?.features}
                  benefitPartnerCountryData={benefitPartnerCountryData}
                  selectedBenefit={selectedBenefit}
                  contractBenefit={contractBenefit}
                  setBenefitType={(type) => setBenefitType(type)}
                  benefitType={benefitType}
                  showDependents={showDependents}
                  benefits={benefits}
                  country={country}
                  contractType={contractType}
                />
              </div>
              <FooterSection tw="z-[3]">
                <SectionActionBar
                  onCancelClick={modalCloseHandler}
                  submitLoading={loading}
                  tw="mx-auto"
                  disabled={!isValid || !isDirty}
                  primaryActionTooltip={tooltipContent}
                />
              </FooterSection>
            </DetailForm>
          </ContractReviewSectionModal>
        )}
      </FormProvider>
    </Accordion>
  );
};

const InsuranceSectionWrapper: React.FC<InsuranceSectionProps> = (props) => {
  const isInsuranceExpand = useFeature(AppFeature.INSURANCE_EXPAND)?.on;

  if (isInsuranceExpand) return <InsuranceSectionV2 {...props} />;
  return <InsuranceSection {...props} />;
};
export default InsuranceSectionWrapper;
