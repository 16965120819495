import { ContractTerm } from '../../__generated__/graphql';
import i18n from '../../i18n';

// eslint-disable-next-line import/prefer-default-export
export const contractTermLabels = {
  [ContractTerm.FIXED]: i18n.t(
    'contract-onboarding.common:contract-term.fixed',
    'Fixed Term',
  ),
  [ContractTerm.PERMANENT]: i18n.t(
    'contract-onboarding.common:contract-term.permanent',
    'Permanent',
  ),
};
