/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { addMonths } from 'date-fns';
import { isEqual } from 'lodash';
import tw from 'twin.macro';

import { DropdownValue } from 'common/components/combo-box';
import { Religion } from 'common/constants/default-labels';
import { PaymentType } from 'contract-onboarding/company/components/additional-pay-form';
import AdditionalPaysTable, {
  AdditionalPayComponentOnSalaryRevision,
} from 'contract-onboarding/company/components/additional-pays-table';
import {
  convertBasePayToMonthlyPay,
  isMonthEnabledForAdditionalPay,
} from 'contract-onboarding/company/services/compensation';

import {
  Company,
  Contract,
  ContractType,
  Maybe,
  RateFrequency,
} from '__generated__/graphql';

import { PerformanceReviewFormValues } from '../..';
import {
  additionalPaysSupported,
  additionalPaysSupportedFeatureOn,
} from '../../utils';

const AddtionalPaySection: React.FC<{
  contract?: Contract;
  company: Company;
  currencyOptions: Maybe<DropdownValue[]>;
  averageWorkingHoursPerMonth?: number;
  memberReligion: Religion;
}> = ({
  contract,
  currencyOptions,
  averageWorkingHoursPerMonth,
  memberReligion,
  company,
}) => {
  const { t } = useTranslation('performance-reviews');

  const isNotFreelancer = contract?.type !== ContractType.FREELANCER;

  const { watch } = useFormContext<PerformanceReviewFormValues>();

  const [effectiveMonth, additionalPays, currentAdditionalPays] = watch([
    'effectiveMonth',
    'additionalPays',
    'currentAdditionalPays',
  ]);

  const showRevisionNoteForPayTypes = useMemo(() => {
    if (!currentAdditionalPays || !currentAdditionalPays.length) {
      return additionalPays?.map((item) => item.payType);
    }

    return additionalPays
      ?.map((item) => {
        const existingPayItem = currentAdditionalPays?.find(
          (x) => x.payType === item.payType,
        );

        return !existingPayItem ||
          (existingPayItem && !isEqual(existingPayItem, item))
          ? item.payType
          : null;
      })
      .filter(Boolean);
  }, [additionalPays, currentAdditionalPays]);

  const effectiveDate = effectiveMonth ? new Date(effectiveMonth) : undefined;

  const enableCurrentMonth = useMemo<boolean>(
    () => isMonthEnabledForAdditionalPay(contract?.startOn ?? ''),
    [contract?.startOn],
  );

  const revisedAdditionalPays = additionalPays?.filter(
    (m) => m != null && !m.isDeletable,
  );

  return (
    <div tw="flex flex-col">
      <AdditionalPaysTable
        title={t('form.additional-pays.title-new', 'Add additional pay')}
        basePay={convertBasePayToMonthlyPay(
          contract?.compensation?.basePay?.frequency ?? RateFrequency.MONTHLY,
          contract?.compensation?.basePay?.amount ?? 0,
          averageWorkingHoursPerMonth,
        )}
        currency={contract?.compensation?.basePay?.currency}
        currencyOptions={currencyOptions}
        edit
        contractType={contract?.type}
        companyName={company?.displayName}
        additionalPaysSupported={
          isNotFreelancer
            ? additionalPaysSupportedFeatureOn
            : additionalPaysSupported
        }
        countryCode={contract?.country}
        religion={memberReligion}
        minDate={
          enableCurrentMonth
            ? new Date(new Date(contract?.startOn ?? ''))
            : addMonths(new Date(contract?.startOn ?? ''), 1)
        }
        countryStateCode={contract?.countryStateCode}
        containerStyle={tw`p-0 border-none shadow-none`}
        contractId={contract?.id}
        showRevisionNoteForPayTypes={
          showRevisionNoteForPayTypes as PaymentType[]
        }
        defaultEffectiveDate={effectiveDate}
        hideListTitle
        excludeUndeletable
      />

      {revisedAdditionalPays && revisedAdditionalPays.length > 0 && (
        <div tw="grid gap-x-small mt-large">
          <p tw="text-ps mb-small text-text-primary font-semibold">
            {t(
              'form.revised.additional-compensation',
              'Revised Additional Compensation',
            )}
          </p>
          {revisedAdditionalPays.map((additionalPay, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${index}-${additionalPay?.name}`}>
              <AdditionalPayComponentOnSalaryRevision
                companyName={company?.displayName}
                payItem={additionalPay}
                showEdit={false}
                deletePayItem={() => null}
                toggleEdit={() => null}
                religion={memberReligion}
                contractType={contract?.type}
                countryCode={contract?.country}
              />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddtionalPaySection;
