import { useReactiveVar } from '@apollo/client';

import { Experience } from 'app/models/module-config';
import { assureNumber } from 'app/utils/number';
import { parseEntries } from 'app/utils/object';
import { userVar } from 'app/vars';
import { GET_CONTRACT } from 'contract-onboarding/queries';

import {
  DeductionInput,
  DeductionValue,
  Maybe,
  useUpdateDeductionMutation,
} from '__generated__/graphql';

import { DeductionFormValues } from '../services/deduction';

interface SubmitDeductionResponse {
  onSubmit: (formValues: DeductionFormValues) => void;
  loading: boolean;
}

const mapInputValue = ([key, value]: [string, unknown]): DeductionInput => {
  const deduction = value as DeductionValue;

  return {
    deductionDefinitionId: key,
    value: assureNumber(deduction.value),
    unit: deduction.unit,
  };
};

const mapInputValues = (values: DeductionFormValues): DeductionInput[] =>
  parseEntries(values).map(mapInputValue);

const useSubmitDeductionValue = (
  contractId?: Maybe<string>,
  onCompleted?: () => void,
): SubmitDeductionResponse => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const isCompanyExperience = current === Experience.COMPANY;

  const [updateDeductionValue, { loading }] = useUpdateDeductionMutation({
    onCompleted: () => {
      if (onCompleted) {
        onCompleted();
      }
    },
    refetchQueries: isCompanyExperience
      ? [{ query: GET_CONTRACT, variables: { id: contractId } }]
      : [],
  });

  const onSubmit = (values: DeductionFormValues) => {
    if (!contractId || Object.keys(values).length === 0) return;

    updateDeductionValue({
      variables: {
        contractID: contractId,
        input: mapInputValues(values),
      },
    });
  };

  return {
    onSubmit,
    loading,
  };
};

export default useSubmitDeductionValue;
