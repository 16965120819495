import {
  Contract,
  CountryCode,
  ProductStatus,
  useGetProductsQuery,
} from '__generated__/graphql';

const isCountryServiceableForEquipments = (contract: Contract): boolean => {
  const { data } = useGetProductsQuery({
    variables: {
      code: contract?.country ?? CountryCode.USA,
      filter: {
        type: 'Laptop',
        status: ProductStatus.ACTIVE,
      },
    },
  });

  return (data?.country?.productList?.products?.length ?? 0) > 0;
};

export default isCountryServiceableForEquipments;
