import { useReactiveVar } from '@apollo/client';
import { userVar } from '@multiplier/user';

import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
  WidgetPosition,
  WidgetVariant,
} from 'app/models/module-config';
import routes from 'team/company/routes';

const config: ModuleConfig = {
  id: 'onboarding-awareness',
  permission: 'contract-onboarding',
  folder: 'team/company/onboarding-awareness-banner',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Onboarding Awareness Banner',
    route: `${routes.root}/${routes.details.onboarding_awareness}`,
    sort: 1,
    home: false,
    group: MemberNavGroups.EMPLOYMENT,
    hide: true,
  },
  widget: {
    sort: 3,
    list: [
      {
        show: (): boolean => {
          const { isManager } = useReactiveVar(userVar);

          return !isManager;
        },
        file: 'onboarding-awareness-widget',
        position: WidgetPosition.RIGHT,
        variant: WidgetVariant.HALF_WIDTH,
      },
    ],
  },
};

export default config;
