import { gql } from '@apollo/client';

export default gql`
  mutation UpdateBenefits($id: ID!, $input: ContractUpdateBenefitsInput!) {
    contractUpdateBenefits(id: $id, input: $input) {
      id
      benefits {
        id
        employeePayPercentage
        employerPayPercentage
        benefit {
          id
          type
        }
        params {
          key
          value
        }
        dependentCount
      }
      onboarding {
        status
        steps
        current
      }
    }
  }
`;
