/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as LogoV2 } from '@multiplier/assets/files/contract-onboarding/logo-v2.svg';
import { ReactComponent as VisaImageV2 } from '@multiplier/assets/files/contract-onboarding/visa-image-v2.svg';
import { ReactComponent as VisaImage } from '@multiplier/assets/files/contract-onboarding/visa-image.svg';
import { Button, Icon, ThemeContext } from '@multiplier/common';
import tw, { css, theme } from 'twin.macro';

import { useStartMemberOnboarding } from 'contract-onboarding/member/hooks';

import { useGetMemberQuery } from '__generated__/graphql';

const MemberStartVisa: React.FC = () => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { data: { member } = { member: {} } } = useGetMemberQuery();
  const contract = useMemo(() => member?.contracts?.[0], [member]);

  const companyName = contract?.company?.displayName ?? 'our company';

  const { t } = useTranslation('contract-onboarding.member');

  const onSubmit = useStartMemberOnboarding(contract?.id, contract?.onboarding);

  return (
    <div
      css={[
        tw`flex h-screen w-screen justify-center relative`,
        css`
          background: linear-gradient(
            to bottom,
            ${theme`colors.background-inverted`} 50%,
            #ffffff 50%
          );
        `,
        !isNewThemeApplied &&
          css`
            background: linear-gradient(
              to bottom,
              ${theme`colors.background`} 50%,
              #ffffff 50%
            );
          `,
      ]}
      data-testid="member-start"
    >
      <div tw="mt-[6%] text-center max-width[762px]">
        <div tw="flex flex-col items-center">
          <h3 tw="text-h3 font-semibold text-text-inverted-primary">
            <Trans
              t={t}
              tOptions={{ interpolation: { escapeValue: false } }}
              i18nKey="start.member-welcome"
              values={{ companyName }}
            >
              Welcome to {{ companyName }} on Multiplier
            </Trans>
          </h3>
          <p tw="text-p font-normal text-text-inverted-primary mt-base w-3/4">
            {t(
              'start.description-visa',
              'To complete your VISA we will need some information from you. Before continuing, make sure the following documents are ready.',
            )}
          </p>
          <p tw="font-size[18px] font-semibold mt-extra-large text-text-inverted-primary">
            {t('start.upload-documents', 'Upload Documents')}
          </p>
          <div tw="flex mt-large">
            <div tw="width[180px] height[206px] bg-background-white shadow border border-border-primary rounded-base mx-small flex flex-col items-center justify-between">
              {!isNewThemeApplied ? (
                <VisaImage tw="mt-48" />
              ) : (
                <VisaImageV2 tw="mt-48" />
              )}
              <p tw="text-p mb-large w-2/3">
                {t('start.eligibility-documents', 'Eligibility documents')}
              </p>
            </div>
          </div>
        </div>
        <Button size="medium" tw="mt-[74px]" onClick={onSubmit}>
          {t('start.get-started', 'Get Started')}
        </Button>
      </div>
      {!isNewThemeApplied ? (
        <Icon name="logo" tw="absolute bottom-extra-large" />
      ) : (
        <LogoV2 tw="absolute bottom-extra-large" />
      )}
    </div>
  );
};

export default MemberStartVisa;
