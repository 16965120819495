/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, TextInput } from '@multiplier/common';
import { debounce } from 'lodash';
import tw, { theme } from 'twin.macro';

export interface SearchBarProps {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ setSearchQuery }) => {
  const { t } = useTranslation('team-view');
  const [searchText, setSearchText] = useState('');

  const delayedSearchQuery = useRef(
    debounce((value) => {
      setSearchQuery(value);
    }, 500),
  ).current;

  return (
    <TextInput
      prepend={<Icon name="search" fill={theme`colors.slateGrey500`} />}
      css={tw`h-46 w-[239px] border-slateGrey200 text-slateGrey400`}
      placeholder={t('team-filters.search.placeholder', 'Search by name')}
      value={searchText}
      onChange={(event) => {
        setSearchText(event.target.value);
        delayedSearchQuery(event.target.value);
      }}
    />
  );
};

export default SearchBar;
