/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Callout,
  CalloutVariant,
  ComboBox,
  DropdownValue,
  Icon,
  ThemeContext,
  ToolTip,
} from '@multiplier/common';
import tw from 'twin.macro';

import { assureNumber } from 'app/utils/number';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import { FixedDeductionRequirement, Maybe } from '__generated__/graphql';

import { DeductionConstraintFromQuery } from '../types';

type EmployerMatchLimitComponent = React.FC<{
  deductionDefinitionId: string;
  groupKey?: Maybe<string>;
  constraint: DeductionConstraintFromQuery;
}>;

const EmployerMatchLimit: EmployerMatchLimitComponent = ({
  deductionDefinitionId,
  groupKey,
  constraint,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const fixedDeduction = constraint as FixedDeductionRequirement;

  const { control, setValue, watch } = useFormContext<CompensationFormValues>();

  const selectedDeductionValue = assureNumber(
    watch(`deductions.${deductionDefinitionId}.value`),
  );

  const employerMatchOptions = useMemo<DropdownValue[]>(
    () =>
      fixedDeduction.options?.map((option) => ({
        title: `${option?.value}`,
        value: `${option?.value}`,
      })) || [],
    [constraint],
  );

  const updateFormValue = (value: string) => {
    if (!deductionDefinitionId) return;

    const valueNumber = assureNumber(value);
    const selectedOption = fixedDeduction.options?.find(
      (option) => valueNumber === option?.value,
    );
    if (selectedOption) {
      setValue(`deductions.${deductionDefinitionId}`, selectedOption);
    }
  };

  return (
    <div>
      <span tw="flex">
        <p tw="text-ps text-text-primary font-medium">
          {t(
            `deduction.requirement.employer-match.${groupKey}.title`,
            'Employer Match Limit',
          )}
        </p>
        <ToolTip
          variant="right"
          content={
            <div tw="max-w-[19rem]">
              <Trans
                t={t}
                i18nKey={`deduction.requirement.employer-match.${groupKey}.help`}
              >
                <b tw="font-semibold text-p">Employer Match Limit</b>
                <p
                  css={[
                    tw`text-ps text-text-inverted-secondary`,
                    !isNewThemeApplied && tw`text-brand100`,
                  ]}
                >
                  This is the maximum percent of employee salary matched by the
                  employer, regardless of the amount employee decides to
                  contribute.
                </p>
                <br />
                <p
                  css={[
                    tw`text-ps text-text-inverted-secondary`,
                    !isNewThemeApplied && tw`text-brand100`,
                  ]}
                >
                  For example, let s assume the employer provides a 3%
                  contribution match of the employees s annual salary that you
                  contribute to your 401(k). If you have an annual salary of
                  $100,000 and contribute 6%, your contribution will be $6,000
                  and your employer s match will be $3,000 , for a total of
                  $9,000.
                </p>
              </Trans>
            </div>
          }
        >
          <div tw="ml-small flex items-center justify-center h-full">
            <Icon name="help" fill="transparent" />
          </div>
        </ToolTip>
      </span>
      <div tw="max-w-[21rem] my-small">
        <Controller
          name={`deductions.${deductionDefinitionId}`}
          control={control}
          render={() => (
            <ComboBox
              variant="default"
              dropdownValues={employerMatchOptions}
              showArrow={false}
              value={`${selectedDeductionValue}`}
              placeholder=""
              endAdornment={
                <p
                  css={[
                    tw`text-text-tertiary`,
                    !isNewThemeApplied && tw`text-grey03`,
                  ]}
                >
                  {t(
                    `deduction.requirement.employer-match.${groupKey}.adornment`,
                    'Percentage',
                  )}
                </p>
              }
              onChange={updateFormValue}
            />
          )}
        />
      </div>
      {!!selectedDeductionValue && (
        <Callout
          variant={CalloutVariant.INFO}
          css={[tw`text-ps`, !isNewThemeApplied && tw`bg-blue200`]}
        >
          <p
            css={[
              tw`font-normal text-text-primary`,
              !isNewThemeApplied && tw`text-grey06`,
            ]}
          >
            <Trans
              t={t}
              i18nKey={`deduction.requirement.employer-match.${groupKey}.callout`}
            >
              The employer contribution <b>matches 100%</b> of the employee
              contribution{' '}
              <b>
                limited upto first {{ employerMatch: selectedDeductionValue }}%{' '}
              </b>
              of the employee annual salary towards 401K contribution
            </Trans>
          </p>
        </Callout>
      )}
    </div>
  );
};

export default EmployerMatchLimit;
