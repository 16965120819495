/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton, Popover, ThemeContext } from '@multiplier/common';
import { theme } from 'twin.macro';

import isNoJoiner from 'team/company/services/is-no-joiner';

import { Contract } from '__generated__/graphql';

const Show = ({
  children,
  when,
}: {
  children: React.ReactNode;
  when: () => boolean;
}): React.ReactElement | null => {
  if (when()) return <>{children}</>;
  return null;
};

const LegacyOffboardingActions = ({
  handleCancelClick,
  handleEditClick,
  startOn,
  endedOn,
}: {
  handleCancelClick: () => void;
  handleEditClick: () => void;
  startOn: Contract['startOn'];
  endedOn: Contract['endedOn'];
}): React.ReactElement => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('team.company');

  return (
    <Popover
      key="offboarding.action-cell.popover"
      popOver={
        <div tw="flex flex-col">
          <Popover.Button
            data-testid="cancel-offboarding-button"
            onClick={(e) => {
              e.stopPropagation();
              handleCancelClick();
            }}
          >
            {t('offboarding.action-cell.cancel', 'Cancel offboarding')}
          </Popover.Button>
          <Show when={() => false && !isNoJoiner(startOn, endedOn)}>
            <Popover.Button
              data-testid="edit-last-date-button"
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick();
              }}
            >
              {t('offboarding.action-cell.edit', 'Edit last working day')}
            </Popover.Button>
          </Show>
        </div>
      }
    >
      <IconButton
        data-testid="offboarding.action-cell.trigger"
        variant="outline"
        size="medium"
        name="more-vertical"
        stroke={!isNewThemeApplied ? theme`colors.icon-primary` : undefined}
        fill={!isNewThemeApplied ? 'transparent' : undefined}
      />
    </Popover>
  );
};

export default LegacyOffboardingActions;
