import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';

import { errorNotification } from 'app/services/notification-services';
import { getNextStep } from 'contract-onboarding/utils/routing';
import i18n from 'i18n';

import {
  ContractOnboardingStep,
  Member,
  Nationalogy,
  useMemberUpdateBasicDetailsMutation,
  useMemberUpdateLegalDataMutation,
} from '__generated__/graphql';

import countryIDD from '../../../common/constants/country-idd';
import { MemberBasicDetailsFormValues } from '../pages/onboarding/pages/basic-details';
import stepConfig from '../pages/onboarding/step-config';

export const useSubmitMemberBasicDetails = (): {
  onSubmit: (
    values: MemberBasicDetailsFormValues,
    id?: Member['id'],
    shouldSubmitLegalData?: boolean,
  ) => Promise<void>;
  loading: boolean;
} => {
  const navigate = useNavigate();
  const [
    updateMember,
    { loading: loadingUpdateMember },
  ] = useMemberUpdateBasicDetailsMutation({
    onError() {
      errorNotification(
        i18n.t(
          'member.update.failed-message',
          'Failed to update member basic details',
        ),
        '',
        true,
      );
    },
  });

  const [
    updateLegalInfo,
    { loading: loadingLegalInfoUpdate },
  ] = useMemberUpdateLegalDataMutation({
    onError() {
      errorNotification(
        i18n.t(
          'member.legal-data-update.failed-message',
          'Failed to update member legal data',
        ),
        '',
        true,
      );
    },
  });

  const onSubmit = async (
    values: MemberBasicDetailsFormValues,
    id: Member['id'],
    shouldSubmitLegalData?: boolean,
  ) => {
    if (!id) return;

    const memberResponse = await updateMember({
      variables: {
        id,
        input: {
          fullLegalName: values.legalName,
          dateOfBirth: values.dateOfBirth
            ? `${format(values.dateOfBirth, 'yyyy-MM-dd')}T00:00:00`
            : null,
          nationality: {
            type: Nationalogy.CITIZEN,
            country: values.nationality,
          },
          phone: {
            name: 'PRIMARY',
            value: `${
              values.countryCode ? countryIDD[values.countryCode] : ''
            } ${values.phone}`,
          },
          address: {
            line1: values.currentAddress,
            line2: values.addressLine2,
            city: values.city,
            postalCode: values.postalCode,
            country: values.country,
            state: values.state,
          },
          maritalStatus: values.maritalStatus,
        },
      },
    });

    let legalResponse;

    if (values.legalData && shouldSubmitLegalData) {
      legalResponse = await updateLegalInfo({
        variables: {
          id,
          input: values.legalData.map((val) => ({
            key: val.key,
            value: val.value,
            identifier: val.identifier,
          })),
        },
      });
    }

    if (values.legalData && shouldSubmitLegalData ? legalResponse : true) {
      const onboarding =
        memberResponse?.data?.memberUpdateBasicDetails?.contracts?.[0]
          ?.onboarding;
      const next = getNextStep(
        onboarding,
        ContractOnboardingStep.MEMBER_BASIC_DETAILS,
      );
      if (next && stepConfig[next]) {
        navigate(stepConfig[next].directLink());
      }
    }
  };
  return {
    onSubmit,
    loading: loadingUpdateMember || loadingLegalInfoUpdate,
  };
};

export default useSubmitMemberBasicDetails;
