/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Button, Icon } from '@multiplier/common';
import { format } from 'date-fns';
import * as FileSaver from 'file-saver';

import AppFeature from 'app/features';
import { useModal } from 'app/hooks';
import { errorNotification } from 'app/services/notification-services';
import countryLabels from 'common/constants/country-labels';

import {
  CalculationPayableType,
  CountryCode,
  CurrencyCode,
  RateFrequency,
  RateType,
  useGetSalaryCalculationPdfLazyQuery,
} from '__generated__/graphql';

interface BreakdownFooterProps {
  country: CountryCode;
  state?: string;
  amount: number;
  currency: CurrencyCode;
  rateType: RateType;
  frequency: RateFrequency;
  payableType: CalculationPayableType[];
}

const BreakdownFooter: React.FC<BreakdownFooterProps> = ({
  country,
  state,
  amount,
  currency,
  rateType,
  frequency,
  payableType,
}) => {
  const { t } = useTranslation('salary-calculator');
  // Email feature is temporarily disabled https://app.clickup.com/t/4fheg6
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showEmailDialog, handleDialogClose] = useModal();

  const showPdfError = () =>
    errorNotification(
      t('breakdown.pdf-download.error-title', 'Whoops!'),
      t(
        'breakdown.pdf-download.error-message',
        'We were unable to start the PDF download. Please try again later.',
      ),
      true,
    );

  const [downloadPdf, { loading }] = useGetSalaryCalculationPdfLazyQuery({
    onCompleted(data) {
      if (data.salaryCalculationPdf?.link)
        FileSaver.saveAs(
          data.salaryCalculationPdf.link,
          data.salaryCalculationPdf?.name ?? 'salary-calculation.pdf',
        );
      else showPdfError();
    },
    onError() {
      showPdfError();
    },
    fetchPolicy: 'network-only',
  });

  const formatedDate = (date: Date): string => {
    const monthAndDay = format(date, 'MMMM dd');
    const year = format(date, 'yyyy');

    return `${monthAndDay}, ${year}`;
  };

  const salaryCalculationUpdate = useFeature(
    AppFeature.SALARY_CALCULATION_UPDATED_DATE,
  );

  const salaryCalculation = useMemo(() => {
    if (salaryCalculationUpdate?.value?.salaryCalculation)
      return salaryCalculationUpdate.value.salaryCalculation.find(
        (ele: { countryCode: CountryCode; lastUpdatedDate: Date }) =>
          ele?.countryCode === country,
      );

    return undefined;
  }, [salaryCalculationUpdate]);

  return (
    <div tw="flex flex-row justify-between p-large">
      <div tw="flex-row">
        <div tw="text-ps text-text-primary">
          {t(
            'breakdown.tax-terms',
            'All Taxes and Contributions are estimated based on a specific persona in each country. They may differ depending on factors of the person being hired (such as age, marital status, region, etc).',
          )}
        </div>
        {salaryCalculation && (
          <div tw="text-ps text-text-primary mt-small">
            {t('breakdown.last-updated-date', {
              defaultValue:
                '{{country}} salary calculator was updated on {{date}}.',
              replace: {
                country: countryLabels[country],
                date: salaryCalculation?.lastUpdatedDate
                  ? formatedDate(new Date(salaryCalculation.lastUpdatedDate))
                  : '',
              },
            })}
          </div>
        )}
      </div>
      <div tw="min-w-[50px] max-w-[100px]" />
      <div tw="flex flex-row flex-shrink-0">
        <Button
          data-cy="pdf-button"
          size="medium"
          variant="secondary"
          onClick={() =>
            downloadPdf({
              variables: {
                params: {
                  country,
                  state,
                  amount,
                  currency,
                  rateType,
                  frequency,
                  payableType,
                },
              },
            })
          }
          loading={loading}
        >
          <div tw="flex flex-row items-center">
            <Icon tw="mr-small" name="file" />
            <span>{t('breakdown.download-pdf-button', 'Download as PDF')}</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default BreakdownFooter;
