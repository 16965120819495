/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as Paper } from '@multiplier/assets/files/salary-calculator/paper.svg';
import { Button, EmptySplash, Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import countryLabels from 'common/constants/country-labels';
import { useSendSalaryCalculationRequestEmail } from 'salary-calculator/hooks';

import {
  CountryCode,
  SalaryCalculationRequestEmailParam,
} from '__generated__/graphql';

const CheckIcon: React.FC<{ variant: 'large' | 'small' }> = ({ variant }) => (
  <div
    css={[
      tw`bg-background-action rounded-full flex items-center justify-center`,
      variant === 'large'
        ? tw`width[92px] height[92px]`
        : tw`width[40px] height[40px]`,
    ]}
  >
    <Icon
      name="check"
      fill={theme`colors.white`}
      height={variant === 'large' ? 24 : 10}
      width={variant === 'large' ? 36 : 16}
    />
  </div>
);

interface SplashProps {
  salaryCalculationRequested: boolean;
  country: CountryCode;
  loading: boolean;
  handleContactClick: () => void;
}

const DefaultSplash: React.FC<SplashProps> = ({
  salaryCalculationRequested,
  country,
  loading,
  handleContactClick,
}) => {
  const { t } = useTranslation('salary-calculator');

  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <EmptySplash
      splash={
        salaryCalculationRequested ? (
          <CheckIcon variant="large" />
        ) : isNewThemeApplied ? (
          <Icon name="description-fill" tw="w-[92px] h-[92px]" />
        ) : (
          <Paper />
        )
      }
      header={
        salaryCalculationRequested
          ? t('contact-requested-splash-header', 'Calculation Requested')
          : t('contact-request-splash-header', 'Request Salary Cost')
      }
      description={
        salaryCalculationRequested
          ? t(
              'contact-splash-requested-description',
              'Your request for "{{ country }}" has been received. Our team will get in touch with you soon.',
              { replace: { country: countryLabels[country] } },
            )
          : t(
              'contact-splash-request-description',
              '{{ country }} salary calculation requires multiple factors like age and gender. Please message us to get the salary calculations for {{ country }}.',
              { replace: { country: countryLabels[country] } },
            )
      }
      cta={
        !salaryCalculationRequested && (
          <Button
            variant="default"
            size="medium"
            disabled={loading}
            onClick={handleContactClick}
          >
            {t('request-calculation', 'Request Salary Cost')}
          </Button>
        )
      }
    />
  );
};

const BreakdownSplash: React.FC<SplashProps> = ({
  salaryCalculationRequested,
  country,
  loading,
  handleContactClick,
}) => {
  const { t } = useTranslation('salary-calculator');
  return (
    <div tw="rounded-base bg-background-primary flex flex-col items-center justify-around p-extra-large">
      {salaryCalculationRequested && (
        <div tw="mb-12">
          <CheckIcon variant="small" />
        </div>
      )}
      <h5 tw="text-18 text-background font-semibold">
        {salaryCalculationRequested
          ? t('contact-breakdown-requested-header', 'Breakdown Requested')
          : t('contact-breakdown-contact-header', 'Contact Us')}
      </h5>
      <p tw="text-center text-text-tertiary my-small">
        {salaryCalculationRequested
          ? t(
              'contact-breakdown-requested-description',
              'Your request for the Employee breakdown has been received. Our team will get in touch with you soon.',
            )
          : t(
              'contact-splash-get-breakdown-description',
              'Please reach out to us to get the Employee Breakdown for "{{ country }}".',
              { replace: { country: countryLabels[country] } },
            )}
      </p>
      {!salaryCalculationRequested && (
        <Button
          variant="default"
          size="small"
          disabled={loading}
          onClick={handleContactClick}
        >
          {t('request-breakdown', 'Request Breakdown')}
        </Button>
      )}
    </div>
  );
};

export interface ContactSplashProps {
  variant: 'default' | 'breakdown';
  country: SalaryCalculationRequestEmailParam['country'];
  currency: SalaryCalculationRequestEmailParam['currency'];
  amount: SalaryCalculationRequestEmailParam['amount'];
  frequency: SalaryCalculationRequestEmailParam['frequency'];
  payableType?: SalaryCalculationRequestEmailParam['payableType'];
}

const ContactSplash: React.FC<ContactSplashProps> = ({
  variant,
  country,
  currency,
  amount,
  frequency,
  payableType,
}) => {
  const [salaryCalculationRequested, setSalaryCalculationRequested] = useState(
    false,
  );
  const {
    loading,
    handleSendEmailClick,
  } = useSendSalaryCalculationRequestEmail({
    country,
    currency,
    amount: Number(amount),
    frequency,
    payableType,
  });

  const handleContactClick = () => {
    setSalaryCalculationRequested(true);
    handleSendEmailClick();
  };

  useEffect(() => {
    setSalaryCalculationRequested(false);
  }, [country]);

  return (
    <div data-testid="salary-calculator-contact-splash">
      {variant === 'default' ? (
        <DefaultSplash
          salaryCalculationRequested={salaryCalculationRequested}
          country={country}
          loading={loading}
          handleContactClick={handleContactClick}
        />
      ) : (
        <BreakdownSplash
          salaryCalculationRequested={salaryCalculationRequested}
          country={country}
          loading={loading}
          handleContactClick={handleContactClick}
        />
      )}
    </div>
  );
};

export default ContactSplash;
