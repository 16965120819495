import { useMemo } from 'react';

import { Capability } from '__generated__/graphql';

import useGetCompanyEntityInfo from './get-company-entity-info';

const useHasGpCapabilities = (
  companyId: string | undefined | null,
  legalEntityId: string | undefined | null,
): boolean => {
  const legalEntity = useGetCompanyEntityInfo(companyId, legalEntityId);

  return useMemo(
    () =>
      !!legalEntity?.capabilities?.some(
        (capability) =>
          capability &&
          [Capability.GROSS_TO_NET, Capability.SALARY_DISBURSEMENT].includes(
            capability,
          ),
      ),
    [legalEntity?.capabilities],
  );
};

export default useHasGpCapabilities;
