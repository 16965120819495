import { FieldArrayWithId } from 'react-hook-form';

import { Compliance } from '../../__generated__/graphql';
import { ComplianceFormValues } from '../components/contract-section';

export const hasLeaveEntitlementsBesidesUnpaidType = (
  timeOffEntitlement: Compliance['timeOffEntitlement'],
): boolean =>
  (timeOffEntitlement &&
    timeOffEntitlement.filter(
      (entitlement) => entitlement?.timeOffType?.type !== 'unpaid',
    ).length > 0) ??
  false;

export const hasMandatoryFieldsBesidesUnpaidType = (
  mandatoryFields: FieldArrayWithId<ComplianceFormValues, 'mandatory', 'id'>[],
): boolean =>
  mandatoryFields &&
  mandatoryFields.filter((field) => field.key !== 'unpaid').length > 0;
