/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect, useToggle } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, Button, Callout, CalloutVariant } from '@multiplier/common';
import {
  useShouldDisplaySorFields,
  useSorOnboardingContext,
} from '@multiplier/hris-member-management';
import omit from 'lodash/omit';
import 'twin.macro';

import { useModal } from 'app/hooks';
import { Experience } from 'app/models/module-config';
import { successNotification } from 'app/services/notification-services';
import { userVar } from 'app/vars';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import { getMemberDataRequirementValues } from 'contract-onboarding/company/pages/definition-phase/pages/basic-details/helpers';
import { useSubmitBasicDetails } from 'contract-onboarding/hooks';
import { useGetCountryLegalRequirements } from 'contract-onboarding/member/hooks';
import { LegalDataRequirementField } from 'contract-onboarding/member/pages/onboarding/pages/legal-details';
import { LegalDocumentRequirementField } from 'contract-onboarding/member/pages/onboarding/pages/legal-documents/components/form-fields';
import useGetBasicDetailsSectionSchema from 'contract-onboarding/services/basic-details-section-schema';
import {
  getCorrespondingPillTag,
  haveSubmittedChangeRequestItem,
  transformToChangeRequests,
} from 'contract-onboarding/services/change-request';
import { Sections } from 'team/components/employee-details-component';
import { useCancelChangeRequest, useSendChangeRequest } from 'team/hooks';
import RequestMadeDialog from 'team/member/personal-details/components/request-made-dialog';

import {
  Contract,
  ContractType,
  CountryCode,
  DataFieldDefinition,
  DomainType,
  FetchStage,
  Gender,
  Maybe,
  MemberChangeCategory,
  MemberChangeRequest,
  MemberChangeRequestStatus,
} from '__generated__/graphql';

import { memberLegalDataMap } from '../../services/legal-data';
import { Paths } from '../../utils/types';
import ContractReviewSectionModal, {
  FooterSection,
} from '../contract-review-section-modal';
import { DetailForm } from '../layout';
import SectionActionBar from '../section-action-bar';
import FormData from './form-data';

export interface BasicDetailsFormValues {
  firstName: Maybe<string>;
  middleName?: Maybe<string>;
  lastName: Maybe<string>;
  gender: Maybe<Gender>;
  email: Maybe<string>;
  fullLegalName?: Maybe<string>;
  dateOfBirth?: Date;
  nationality?: Maybe<CountryCode>;
  legalData?: LegalDataRequirementField[];
  legalDocuments?: LegalDocumentRequirementField[];
  documentsProof?: File[];
}

const BasicDetailSection: React.FC<{
  member: Contract['member'];
  country: Contract['country'];
  workStatus: Contract['workStatus'];
  type: Contract['type'];
  legalEntityId: Contract['legalEntityId'];
  editable: boolean;
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  changeRequest?: Maybe<MemberChangeRequest>;
  lastSubmitOn?: Maybe<Date>;
  requireApproval?: boolean;
  editDisabledFields?: Paths<BasicDetailsFormValues>[];
  updateData?: () => void;
  toggle?: (key: string) => void;
  open?: boolean;
}> = ({
  member,
  country,
  workStatus,
  type,
  legalEntityId,
  editable,
  uncommittedChangesCallback,
  changeRequest,
  lastSubmitOn,
  requireApproval = false,
  editDisabledFields,
  updateData,
  toggle,
  open,
}) => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);
  const { t } = useTranslation([
    'contract-onboarding.common',
    'contract-onboarding.member',
  ]);
  const [isEditing, setIsEditing] = useToggle(false);
  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal(false);

  const [
    showEditFormModal,
    handleCloseEditFormModal,
    handleOpenEditFormModal,
  ] = useModal(false);

  const modalCloseHandler = () => {
    setIsEditing(false);
    reset();
    handleCloseEditFormModal();
  };

  const {
    getRequirements,
    loading: loadingCountryRequirements,
    requirements,
  } = useGetCountryLegalRequirements(
    workStatus,
    type,
    current === Experience.COMPANY
      ? FetchStage.CONTRACT_GENERATION
      : FetchStage.MEMBER_LEGAL_DATA_CAPTURE,
  );
  const sorOnboardingContext = useSorOnboardingContext({
    type,
    legalEntityId,
  });
  const { hideLegalDetailsSection } = useShouldDisplaySorFields({
    sorOnboardingContext,
  });

  const { onSubmit, loading } = useSubmitBasicDetails(member);

  const {
    sendChangeRequest,
    loading: sendRequestLoading,
  } = useSendChangeRequest(MemberChangeCategory.BASIC_DETAILS, () => {
    if (updateData) updateData();
    setIsEditing(false);
    handleOpenDialog();
  });

  const {
    cancelChangeRequest,
    loading: cancelLoading,
  } = useCancelChangeRequest(MemberChangeCategory.BASIC_DETAILS, updateData);

  const memberLegalDataRequirements = useMemo(
    () =>
      hideLegalDetailsSection ? {} : requirements?.memberLegalDataRequirements,
    [hideLegalDetailsSection, requirements],
  );

  useEffect(() => {
    if (country && type !== ContractType.FREELANCER) {
      getRequirements(country, type);
    }
  }, [country]);

  const shouldShowDOB =
    current === Experience.COMPANY
      ? memberLegalDataRequirements?.dateOfBirth != null
      : type !== ContractType.FREELANCER;

  const schema = useGetBasicDetailsSectionSchema(
    current,
    requireApproval,
    t,
    memberLegalDataRequirements,
  );

  const methods = useForm<BasicDetailsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    context: {
      shouldShowDOB,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;

  const submitForm = (values: BasicDetailsFormValues) => {
    if (requireApproval) {
      sendChangeRequest(
        transformToChangeRequests(
          member,
          values,
          MemberChangeCategory.BASIC_DETAILS,
        ),
      );
      return;
    }
    onSubmit(values).then(() => {
      modalCloseHandler();
      successNotification(
        t(
          'onboarding-phase.basic-details.success-notification',
          'Basic details have been updated in the contract.',
        ),
        '',
        false,
      );
    });
  };

  useDeepCompareEffect(() => {
    const legalDataMap =
      member?.legalData && memberLegalDataMap(member.legalData);

    const initialValues =
      current === Experience.MEMBER
        ? {
            firstName: member?.firstName,
            lastName: member?.lastName,
            gender: member?.gender,
            fullLegalName: member?.fullLegalName,
            dateOfBirth: member?.dateOfBirth,
            nationality: member?.nationalities?.[0]?.country,
            legalData:
              Object.values(memberLegalDataRequirements)
                .filter(
                  (val: DataFieldDefinition) =>
                    val.domainType === DomainType.LEGAL_DATA || !val.domainType,
                )
                .map((val: DataFieldDefinition) => ({
                  key: val.key,
                  label: val.label,
                  description: val.key
                    ? legalDataMap?.[val.key]?.identifier
                    : null,
                  value: val.key ? legalDataMap?.[val.key]?.value : null,
                })) ?? undefined,
          }
        : {
            firstName: member?.firstName,
            lastName: member?.lastName,
            gender: member?.gender,
            email: member?.emails?.[0]?.email,
            dateOfBirth: member?.dateOfBirth,
            legalData:
              Object.values(memberLegalDataRequirements).map(
                (val: DataFieldDefinition) => ({
                  key: val.key,
                  label: val.label,
                  description: val.key
                    ? legalDataMap?.[val.key]?.identifier
                    : null,
                  type: String(val.dataType?.__typename),
                  value: val.key
                    ? getMemberDataRequirementValues(val, member)
                    : null,
                }),
              ) ?? undefined,
          };

    const filteredValues = shouldShowDOB
      ? initialValues
      : omit(initialValues, 'dateOfBirth');

    reset(filteredValues);
  }, [member, memberLegalDataRequirements]);

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.BASIC_DETAILS,
        value: isDirty,
      });
  }, [isDirty]);

  return (
    <>
      <Accordion
        name={t('onboarding-phase.basic-details.header', 'Basic Details')}
        description={
          type === ContractType.FREELANCER
            ? t(
                'onboarding-phase.basic-details.freelancer-description',
                'Basic details of the freelancer',
              )
            : type === ContractType.CONTRACTOR
            ? t(
                'onboarding-phase.basic-details.contractor-description',
                'Basic details of the contractor',
              )
            : t(
                'onboarding-phase.basic-details.description',
                'Category description to better explain what this section is about.',
              )
        }
        renderAction={getCorrespondingPillTag(changeRequest)}
        edit={editable && !haveSubmittedChangeRequestItem(changeRequest)}
        icon="list"
        onEditClick={() => {
          setIsEditing();
          handleOpenEditFormModal();
          if (toggle) toggle(Sections.BASIC_DETAILS);
        }}
        warning={uncommittedChangesCallback && isDirty}
        onClick={() => {
          if (toggle) toggle(Sections.BASIC_DETAILS);
        }}
        open={open}
      >
        {changeRequest?.message &&
          changeRequest.status === MemberChangeRequestStatus.REJECTED && (
            <Callout tw="mb-large" variant={CalloutVariant.ERROR}>
              {changeRequest.message}
            </Callout>
          )}
        <FormProvider {...methods}>
          <DetailForm onSubmit={handleSubmit(submitForm)}>
            <>
              <FormData
                isFormEditing={current === Experience.MEMBER && isEditing}
                isEditing={isEditing}
                changeRequest={changeRequest}
                member={member}
                lastSubmitOn={lastSubmitOn}
                country={country}
                editDisabledFields={editDisabledFields}
                shouldShowDOB={shouldShowDOB}
                memberLegalDataRequirements={memberLegalDataRequirements}
                type={type}
                hideLegalDetailsSection={hideLegalDetailsSection}
                requireApproval={requireApproval}
                requirements={requirements}
              />
              {isEditing && showEditFormModal && current === Experience.MEMBER && (
                <SectionActionBar
                  disabled={!isValid}
                  submitLoading={
                    loading || loadingCountryRequirements || sendRequestLoading
                  }
                  onCancelClick={() => {
                    setIsEditing(false);
                    reset();
                  }}
                  requireApproval={requireApproval}
                />
              )}
            </>
            {changeRequest?.status &&
              changeRequest.status === MemberChangeRequestStatus.SUBMITTED && (
                <div tw="flex justify-center">
                  <Button
                    size="large"
                    variant="outline"
                    loading={cancelLoading}
                    onClick={() => cancelChangeRequest()}
                  >
                    {t(
                      'contract-onboarding.common.cancel-request',
                      'Cancel Request',
                    )}
                  </Button>
                </div>
              )}
          </DetailForm>
        </FormProvider>
        {showEditFormModal && current === Experience.COMPANY && (
          <ContractReviewSectionModal
            modalTitle="Edit Basic Details"
            onClose={modalCloseHandler}
          >
            <FormProvider {...methods}>
              <DetailForm onSubmit={handleSubmit(submitForm)} tw="gap-y-none">
                <div tw="flex flex-col p-extra-large py-none pt-large">
                  <FormData
                    isFormEditing={isEditing}
                    isEditing={isEditing}
                    changeRequest={changeRequest}
                    member={member}
                    lastSubmitOn={lastSubmitOn}
                    country={country}
                    editDisabledFields={editDisabledFields}
                    shouldShowDOB={shouldShowDOB}
                    memberLegalDataRequirements={memberLegalDataRequirements}
                    type={type}
                    hideLegalDetailsSection={hideLegalDetailsSection}
                    requireApproval={requireApproval}
                    requirements={requirements}
                  />
                </div>
                <FooterSection>
                  <SectionActionBar
                    disabled={!isValid || Object.keys(dirtyFields).length === 0}
                    submitLoading={
                      loading ||
                      loadingCountryRequirements ||
                      sendRequestLoading
                    }
                    onCancelClick={modalCloseHandler}
                    requireApproval={requireApproval}
                    tw="mx-auto"
                  />
                </FooterSection>
              </DetailForm>
            </FormProvider>
          </ContractReviewSectionModal>
        )}
      </Accordion>
      <RequestMadeDialog open={showDialog} onClose={handleCloseDialog} />
    </>
  );
};

export default BasicDetailSection;
