import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const signatureEmployerSigned: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.signature-employer-signed.title', {
      defaultValue: 'Waiting for {{name}} to receive the contract',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.signature-employer-signed.description', {
      defaultValue:
        "Contract is sent to {{firstName}} and we're waiting for signatures. Once signed, {{firstName}} will be asked to create a Multiplier account to provide additional details for payroll.",
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.signature-employer-signed.label',
      'Contract signed by signatory',
    ),
  pendingOn: () => PendingOn.SIGNATORY,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.signature-employer-signed.status',
      'Pending on Signatory',
    ),
  allowRevoke: () => true,
  allowDelete: () => true,
};

export default signatureEmployerSigned;
