import { makeVar } from '@apollo/client';

import {
  ContractOffboardingDidNotJoinReason,
  ContractOffboardingType,
} from './__generated__/graphql';

export const initialStep = makeVar<{
  type?: ContractOffboardingType;
}>({});

export const selectClauseStep = makeVar<{
  reason?: string;
  clauses?: string[];
}>({});

export const infoStep = makeVar<{
  lastWorkingDay?: Date;
  supportingDocuments?: File[];
}>({});

export const reasonStep = makeVar<{
  reason?: ContractOffboardingDidNotJoinReason;
  notes?: string | null;
}>({});
