/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useFormContext } from 'react-hook-form';

import { countryWithStateList } from 'contract-onboarding/company/services/eligibility-states';

import {
  Contract,
  CountryCode,
  DataFieldDefinition,
  GetCountryComplianceLimitationsQuery,
} from '__generated__/graphql';

import { BasicDetailsFormValues } from '../../basic-details';
import MemberLegalDetails from './member-legal-details';

interface MemberLegalDetailsListProps {
  contractType: Contract['type'];
  contractWorkStatus: Contract['workStatus'];
  contractCountry: Contract['country'];
  countryCompliance?: GetCountryComplianceLimitationsQuery;
  memberDataRequirementFields: DataFieldDefinition[];
}

const MemberLegalDetailsList: React.FC<MemberLegalDetailsListProps> = ({
  contractWorkStatus,
  contractCountry,
  countryCompliance,
  memberDataRequirementFields,
}) => {
  const { watch } = useFormContext<BasicDetailsFormValues>();

  if (!(memberDataRequirementFields?.length > 0)) {
    return null;
  }

  return (
    <div tw="grid grid-cols-2 gap-x-16 gap-y-24">
      {memberDataRequirementFields?.length > 0 &&
        memberDataRequirementFields.map((dataField, index) => {
          const dependsOnKey = dataField?.dependsOn?.[0]?.key;
          const dependsOnValue = watch('preDataRequirements')?.filter(
            (req) => req?.key === dependsOnKey,
          )?.[0]?.value;
          const isAddressStateRequired =
            dataField?.dataType?.__typename === 'AutoCompleteField' &&
            dataField?.dataType?.optionListType === 'STATE' &&
            dependsOnKey === 'address.country' &&
            countryWithStateList.includes(dependsOnValue as CountryCode);

          if (dependsOnKey && !isAddressStateRequired) return null;

          return (
            <MemberLegalDetails
              contractWorkStatus={contractWorkStatus}
              contractCountry={contractCountry}
              key={dataField?.key}
              dataField={dataField}
              countryCode={countryCompliance?.country?.code}
              index={index}
              addressStateRequired={isAddressStateRequired}
            />
          );
        })}
    </div>
  );
};

export default MemberLegalDetailsList;
