/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Icon,
  Maybe,
  TextInput,
  ThemeContext,
  ToolTip,
} from '@multiplier/common';
import { numberAsCurrency } from '@multiplier/format';
import tw, { theme } from 'twin.macro';

import {
  benefitTypeLabels,
  rateFrequencyLabel,
} from 'common/constants/default-labels';

import {
  Benefit,
  BenefitType,
  CountryCode,
  CurrencyCode,
  RateFrequency,
} from '__generated__/graphql';

const ERSplit: React.FC<{
  employerPayPercentage: number | null | undefined;
  employeePayPercentage: number | null | undefined;
  minimumErSplitPercentage?: number | null | undefined;
  billingCurrency?: CurrencyCode | null | undefined;
  readOnly?: boolean;
  country: Maybe<CountryCode> | undefined;
  type: BenefitType;
  cost?: Benefit['cost'];
  frequency?: Benefit['frequency'];
  onChange: (value: string) => void;
  error?: string;
  platformFee: number | null | undefined;
  platformFeeApplicable: boolean | null | undefined;
}> = ({
  readOnly = false,
  billingCurrency,
  minimumErSplitPercentage,
  employerPayPercentage,
  employeePayPercentage,
  cost,
  type,
  country,
  frequency,
  onChange,
  error,
  platformFeeApplicable,
  platformFee,
  ...props
}) => {
  const { t, i18n } = useTranslation('insurance');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const hasPlatformFee = platformFeeApplicable && (platformFee ?? 0) > 0;
  const actualCost =
    (cost ?? 0) + (hasPlatformFee ? (platformFee as number) : 0);

  const computedEmployerPayAmount =
    (actualCost ?? 0) * ((employerPayPercentage ?? 0) / 100);

  const computedEmployeePayAmount =
    (actualCost ?? 0) * ((employeePayPercentage ?? 0) / 100);

  const frequencyMap = useMemo(
    () =>
      ({
        [RateFrequency.HOURLY]: t('insurance.er-split.frequency-hourly', 'hr'),
        [RateFrequency.MONTHLY]: t(
          'insurance.er-split.frequency-monthly',
          'mo',
        ),
        [RateFrequency.ANNUALLY]: t(
          'insurance.er-split.frequency-hourly',
          'yr',
        ),
      } as Record<Partial<RateFrequency>, string>),
    [],
  );

  const frequencyShorthand =
    frequencyMap?.[frequency as RateFrequency] ??
    rateFrequencyLabel[frequency ?? ''];

  const mandatoryInsuranceEmployerLabel = (
    <div tw="flex items-center gap-x-8">
      {t('insurance.er-split.employer-contribution-input-with-min-er', {
        defaultValue:
          'Employer Contribution (min {{minimumErSplitPercentage}}%)',
        replace: {
          minimumErSplitPercentage,
        },
      })}
      <ToolTip
        styles={tw`p-0`}
        content={
          <div tw="text-ps max-w-[300px] text-center py-small px-extra-small">
            {t(
              `insurance.er-split.employer-contribution-input-with-min-er-tooltip-${type}-${country}`,
              {
                defaultValue:
                  'Employers must provide a minimum contribution of {{minimumErSplitPercentage}}% for {{insurancePlan}} plans.',
                replace: {
                  minimumErSplitPercentage,
                  insurancePlan: benefitTypeLabels[type]?.toLowerCase(),
                },
              },
            )}
          </div>
        }
      >
        <Icon
          name="info"
          width={16}
          height={16}
          fill={theme`colors.slateGrey900`}
        />
      </ToolTip>
    </div>
  );

  const shouldShowMinimumLabel = (minimumErSplitPercentage ?? 0) > 0;

  return (
    <div {...props} data-testid="er-split-input">
      <h1 css={[tw`font-semibold`, readOnly && tw`mb-16`]}>
        {t('insurance.er-split.title', 'Insurance Premium Contribution')}
      </h1>

      {!readOnly && (
        <div tw="text-ps mb-16 text-slateGrey400">
          {t(
            `insurance.er-split-${type}.subtext-v2`,
            'Select the employer contribution towards insurance premium.',
          )}
        </div>
      )}

      <div
        css={[
          tw`flex gap-8`,
          readOnly && tw`border border-slateGrey200 py-12 px-16 rounded-8`,
        ]}
      >
        <TextInput.Container tw="flex-1">
          <TextInput.Label
            css={[
              tw`text-ps text-slateGrey900 font-semibold`,
              readOnly && tw`mb-2`,
            ]}
          >
            {shouldShowMinimumLabel
              ? mandatoryInsuranceEmployerLabel
              : t(
                  'insurance.er-split.employer-contribution-input',
                  'Employer Contribution',
                )}
          </TextInput.Label>
          {readOnly ? (
            <div tw="font-semibold text-slateGrey900">
              {employerPayPercentage}%
              <span tw="text-slateGrey500 text-ps font-normal ml-2">
                ({billingCurrency}{' '}
                {numberAsCurrency(i18n.language, computedEmployerPayAmount, 2)}{' '}
                / {frequencyShorthand})
              </span>
            </div>
          ) : (
            <>
              <TextInput
                data-testid="er-split-employerpaypercentage-input"
                css={[
                  tw`text-text-secondary text-ps font-semibold mb-tiny disabled:text-text-tertiary`,
                  !isNewThemeApplied && tw`text-slateGrey900`,
                ]}
                value={employerPayPercentage ?? ''}
                onChange={(e) => onChange(e.target.value)}
                units="%"
              />
              <span tw="text-slateGrey500 text-ps font-normal ml-2">
                {billingCurrency}{' '}
                {numberAsCurrency(i18n.language, computedEmployerPayAmount, 2)}{' '}
                / {frequencyShorthand}
              </span>
              {error ? <TextInput.Error>{error}</TextInput.Error> : null}
            </>
          )}
        </TextInput.Container>

        <div tw="w-2 self-stretch rounded-4 h-auto bg-slateGrey100 mx-24" />

        <TextInput.Container tw="flex-1">
          <div tw="flex items-center">
            <TextInput.Label
              css={[
                tw`text-ps text-slateGrey900 font-semibold`,
                readOnly && tw`mb-2`,
              ]}
            >
              <div tw="flex items-center gap-x-8">
                {t(
                  'insurance.er-split.employee-contribution-input',
                  'Employee Contribution',
                )}
                <ToolTip
                  styles={tw`p-0`}
                  content={
                    <div tw="text-ps max-w-[300px] text-center py-small px-extra-small">
                      {t(
                        'insurance.er-split.employee-contribution-tooltip',
                        "Employee's contribution towards premium will be deducted from their salary",
                        {
                          interpolation: {
                            escapeValue: false,
                          },
                        },
                      )}
                    </div>
                  }
                >
                  <Icon
                    name="info"
                    width={16}
                    height={16}
                    fill={theme`colors.slateGrey900`}
                  />
                </ToolTip>
              </div>
            </TextInput.Label>
          </div>
          {readOnly ? (
            <div tw="font-semibold text-slateGrey900">
              {employeePayPercentage}%
              <span tw="text-slateGrey500 text-ps font-normal ml-2">
                ({billingCurrency}{' '}
                {numberAsCurrency(i18n.language, computedEmployeePayAmount, 2)}{' '}
                / {frequencyShorthand})
              </span>
            </div>
          ) : (
            <>
              <TextInput
                data-testid="er-split-employeepaypercentage-input"
                tw="text-slateGrey900 text-ps font-semibold mb-2"
                value={employeePayPercentage ?? ''}
                readOnly
                disabled
                units="%"
              />
              <span tw="text-slateGrey500 text-ps font-normal ml-2">
                {billingCurrency}{' '}
                {numberAsCurrency(i18n.language, computedEmployeePayAmount, 2)}{' '}
                / {frequencyShorthand}
              </span>
            </>
          )}
        </TextInput.Container>
      </div>
    </div>
  );
};

export default ERSplit;
