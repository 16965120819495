/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Callout,
  CalloutVariant,
  ComboBox,
  DropdownValue,
  Icon,
  Maybe,
  ThemeContext,
  ToolTip,
} from '@multiplier/common';
import tw from 'twin.macro';

import { assureNumber } from 'app/utils/number';
import { FormCard } from 'contract-onboarding/components/layout';

import {
  ContributionBy,
  DeductionDefinition,
  FixedDeductionRequirement,
} from '__generated__/graphql';

import { LegalDetailsFormParams } from '..';

interface CanadaRRSPProps {
  definitions?: DeductionDefinition[];
}

const MainContainer = tw.div`
    flex flex-col
`;

const Heading = tw.span`
    text-p text-text-primary font-semibold
`;

const SubHeading = tw.span`
    text-ps text-text-tertiary font-normal
`;

const ContributionInputContainer = tw.div`
    grid grid-cols-2 gap-x-[20px]
`;

const EmployerMatchLimit = tw.div`
    flex flex-col mt-[21px]
`;

const Title = tw.span`
    text-ps
`;

const EmployerMatchData = tw.div`
    flex px-base items-center h-[46px] py-[10px] mt-small rounded-tiny text-ps font-normal
`;

const CanadaRRSP: React.FC<CanadaRRSPProps> = ({ definitions }) => {
  const { t } = useTranslation('contract-onboarding.member');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const employeeDeductionDefinition = definitions?.find(
    (definition) => definition.contributionBy === ContributionBy.EMPLOYEE,
  );

  const employerDeductionDefinition = definitions?.find(
    (definition) => definition.contributionBy === ContributionBy.EMPLOYER,
  );

  const constraint = employeeDeductionDefinition?.deductionConstraint as Maybe<FixedDeductionRequirement>;

  const employeeMatchOptions = useMemo<DropdownValue[]>(
    () =>
      constraint?.options?.map((option) => ({
        title: `${option?.value}`,
        value: `${option?.value}`,
      })) || [],
    [constraint],
  );

  const { setValue, watch } = useFormContext<LegalDetailsFormParams>();

  const updateValue = (newValue: string) => {
    const valueNumber = assureNumber(newValue);
    const deductionDefinitionId = employeeDeductionDefinition?.id;

    if (!deductionDefinitionId) return;

    const selectedOption = constraint?.options?.find(
      (option) => valueNumber === option?.value,
    );
    if (selectedOption) {
      setValue(`deductions.${deductionDefinitionId}`, selectedOption, {
        shouldDirty: true,
      });
    }
  };

  const employeeDeductionValue = watch(
    `deductions.${employeeDeductionDefinition?.id}` || '',
  )?.value;

  const employerDeductionValue = watch(
    `deductions.${employerDeductionDefinition?.id}` || '',
  )?.value;

  const employerToolTipContent = (
    <div
      css={[
        !isNewThemeApplied && tw`bg-primaryBlue800 text-brand100`,
        tw`flex flex-col rounded-base m-none p-none max-w-[337px]`,
      ]}
    >
      <span tw="text-p font-semibold text-white mb-tiny">
        {t('canadaRRSP.employer-tooltip.heading', 'Employer Match Limit')}
      </span>
      <div tw="text-ps font-normal whitespace-pre-wrap">
        {t(
          'canadaRRSP.employer-tooltip.description',
          "This is the maximum percent of employee salary matched by the employer, regardless of the amount employee decides to contribute. \n\nFor example, let's assume the employer provides a 3% contribution match of the employees's annual salary that you contribute to your 401(k). If you have an annual salary of $100,000 and contribute 6%, your contribution will be $6,000 and your employer's match will be $3,000 , for a total of $9,000.",
        )}
      </div>
    </div>
  );

  return (
    <FormCard>
      <MainContainer>
        <Heading css={[!isNewThemeApplied && tw`text-background`]}>
          {t('canadaRRSP.heading', 'Contributions')}
        </Heading>
        <Heading
          css={[!isNewThemeApplied && tw`text-background`, tw`my-extra-small`]}
        >
          {t('canadaRRSP.rrsp', 'RRSP')}
        </Heading>
        <SubHeading css={[!isNewThemeApplied && tw`text-grey01`]}>
          {t(
            'canadaRRSP.subheading',
            'Upon commencement of the employment term, you will be enrolled in the company employee-contribution RRSP',
          )}
        </SubHeading>
        <ContributionInputContainer>
          <EmployerMatchLimit>
            <div tw="flex items-center">
              <Title css={[!isNewThemeApplied && tw`text-primaryBlue800`]}>
                {t(
                  'canadaRRSP.employer-match-limit.title',
                  'Employer Match Limit',
                )}
              </Title>
              <ToolTip variant="right" content={employerToolTipContent}>
                <Icon tw="h-base w-base ml-extra-small" name="help" />
              </ToolTip>
            </div>
            <EmployerMatchData
              css={[
                tw`bg-background-tertiary`,
                !isNewThemeApplied && tw`bg-grey05 text-primaryBlue800`,
              ]}
            >
              {`${employerDeductionValue} Percentage`}
            </EmployerMatchData>
          </EmployerMatchLimit>

          <EmployerMatchLimit>
            <div tw="flex items-center">
              <Title css={[!isNewThemeApplied && tw`text-primaryBlue800`]}>
                {t(
                  'canadaRRSP.employee-match-limit.title',
                  'Employee Match Limit',
                )}
              </Title>
            </div>
            <ComboBox
              variant="default"
              tw="mt-small"
              dropdownValues={employeeMatchOptions}
              showArrow
              value={`${employeeDeductionValue}`}
              placeholder="Select your contribution"
              endAdornment={
                <p
                  css={[
                    tw`text-text-tertiary`,
                    !isNewThemeApplied && tw`text-grey03`,
                  ]}
                >
                  {t(
                    `onboarding-phase.contribution.${employeeDeductionDefinition?.groupKey}.contribution-value.percentage`,
                    'Percentage',
                  )}
                </p>
              }
              onChange={updateValue}
            />
          </EmployerMatchLimit>
        </ContributionInputContainer>
      </MainContainer>
      <div tw="col-span-4 space-y-tiny">
        {!!assureNumber(employerDeductionValue) && (
          <Callout
            variant={CalloutVariant.INFO}
            css={[tw`text-ps`, !isNewThemeApplied && tw`bg-blue200`]}
          >
            <p css={[tw`font-normal`, !isNewThemeApplied && tw`text-grey06`]}>
              <Trans
                t={t}
                i18nKey="canadaRRSP.requirement.employer-match.callout"
                tw="font-normal"
              >
                Your employer{' '}
                <b>
                  matches 100% upto {{ employerMatch: employerDeductionValue }}%
                </b>{' '}
                of your <b>annual salary</b> towards RRSP contributions.
              </Trans>
            </p>
          </Callout>
        )}
        <Callout
          variant={CalloutVariant.INFO}
          css={[tw`text-ps`, !isNewThemeApplied && tw`bg-blue200`]}
        >
          <p css={[tw`font-normal`, !isNewThemeApplied && tw`text-grey06`]}>
            <Trans
              t={t}
              i18nKey="canadaRRSP.requirement.maximum-contribution.callout"
              tw="font-normal"
            >
              The maximum contribution amount is <b>18%</b> of your prior
              year&apos;s earnings upto <b>31,560 CAD</b>.
            </Trans>
          </p>
        </Callout>
        <Callout
          variant={CalloutVariant.INFO}
          css={[tw`text-ps`, !isNewThemeApplied && tw`bg-blue200`]}
        >
          <p css={[tw`font-normal`, !isNewThemeApplied && tw`text-grey06`]}>
            <Trans
              t={t}
              i18nKey="canadaRRSP.requirement.tax-free.callout"
              tw="font-normal"
            >
              This amount is tax free upto <b>30,000 CAD</b> as defined by the
              local laws.
            </Trans>
          </p>
        </Callout>
      </div>
    </FormCard>
  );
};

export default CanadaRRSP;
