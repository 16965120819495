import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'reports',
  permission: 'reports',
  folder: 'reports',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Reports',
    route: routes.root,
    sort: 6,
    home: false,
    group: NavGroups.MAIN,
    icon: { name: 'analytics' },
    activeIcon: { name: 'analytics-filled' },
  },
  preloadNs: ['reports'],
};

export default config;
