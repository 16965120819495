import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@multiplier/common';

import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const EmployeeIdAndWorkEmailTask: React.FC<TaskEntryProps> = ({
  contractId,
  task,
  actionable,
  handleCloseSidePanel,
}) => {
  const { t } = useTranslation('contract-onboarding');
  const navigate = useNavigate();
  const location = useLocation();

  const couldNavigateToContractPage =
    contractId && !location.pathname.includes(contractId);

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.employee-id-and-work-email.title',
        'Employee ID and Work Email',
      )}
      action={
        couldNavigateToContractPage ? (
          <Button
            size="small"
            tw="text-ps"
            variant="secondary"
            onClick={() => {
              handleCloseSidePanel?.();
              if (couldNavigateToContractPage) {
                navigate(`/company/team/${contractId}/details`);
              }
            }}
            disabled={!actionable}
          >
            {t(
              'onboarding-checklist.task-list.employee-id-and-work-email.action',
              'Add now',
            )}
          </Button>
        ) : undefined
      }
      completed={task.completed}
    />
  );
};

export default EmployeeIdAndWorkEmailTask;
