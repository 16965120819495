import { gql } from '@apollo/client';

export default gql`
  mutation SSORedirect($identityProvider: String, $code: String) {
    ssoRedirect(identityProvider: $identityProvider, code: $code)
      @rest(
        type: "JwtResponse"
        method: "GET"
        path: "sso/{args.identityProvider}/redirect?code={args.code}"
        endpoint: "user"
      ) {
      id_token
    }
  }
`;
