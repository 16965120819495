/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import {
  Callout,
  CalloutIcon,
  CalloutVariant,
  Maybe,
  ThemeContext,
} from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import AppFeature from 'app/features';

import { CountryCode } from '__generated__/graphql';

const InsuranceMandatoryCallout: React.FC<{
  country: Maybe<CountryCode> | undefined;
}> = ({ country }) => {
  const { t } = useTranslation('insurance.common');
  const isInsuranceExpand = useFeature(AppFeature.INSURANCE_EXPAND)?.on;
  const { isNewThemeApplied } = useContext(ThemeContext);
  if (isInsuranceExpand) {
    return (
      <Callout
        variant={
          isNewThemeApplied ? CalloutVariant.INFO : CalloutVariant.INFO_V3
        }
        shouldShowInfoIcon={false}
        tw="rounded-base"
        data-testid="insurance-mandatory-callout"
      >
        <div tw="flex gap-small">
          <div tw="flex gap-x-extra-small mb-tiny items-center">
            <CalloutIcon.Info
              iconProps={{
                fill: isNewThemeApplied ? undefined : theme`colors.primary`,
                height: 16,
                width: 16,
              }}
              variant={CalloutVariant.WARNING}
              iconStyle={tw`h-base w-base max-h-base max-w-base`}
            />
          </div>
          <div tw="text-ps text-slateGrey900 font-normal">
            {t(
              `benefits.${country}-insurance-mandatory-note-v2`,
              'Insurance is mandatory for all {{ country }} employees based on local labor laws. During onboarding, employees can choose to opt-out if needed.',
              { country },
            )}
          </div>
        </div>
      </Callout>
    );
  }

  return (
    <Callout
      variant={CalloutVariant.WARNING}
      shouldShowInfoIcon={false}
      tw="rounded-base"
      data-testid="insurance-mandatory-callout"
    >
      <Trans t={t} i18nKey={`benefits.${country}-insurance-mandatory-note`}>
        <div tw="flex flex-col">
          <div tw="flex gap-x-base mb-tiny items-center">
            <CalloutIcon.Info
              iconProps={{
                fill: theme`colors.slateGrey900`,
                height: 16,
                width: 16,
              }}
              variant={CalloutVariant.WARNING}
              iconStyle={tw`h-base w-base max-h-base max-w-base`}
            />
            <div tw="text-slateGrey900 font-semibold">
              Important note about mandatory insurance in {{ country }}
            </div>
          </div>
          <div tw="text-ps text-slateGrey900 font-normal">
            According to labor law, employers should mandatorily offer insurance
            to employees in the {{ country }}. During onboarding, employees can
            choose to opt-out if needed.
          </div>
        </div>
      </Trans>
    </Callout>
  );
};

export default InsuranceMandatoryCallout;
