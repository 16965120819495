import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractType, CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const memberInvited: ContractOnboardingStatusConfig = {
  title: (t, name, contractType, contractWorkStatus) => {
    if (
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    )
      return t('onboarding-phase.member-invited.visa-title', {
        defaultValue: '{{name}} is uploading documents',
        replace: {
          name,
        },
      });

    return t('onboarding-phase.member-invited.title', {
      defaultValue: 'Waiting for {{name}} to start',
      replace: {
        name,
      },
    });
  },
  description: (
    t,
    firstName,
    gender,
    country,
    contractType,
    contractWorkStatus,
  ) => {
    if (
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    )
      return t('onboarding-phase.member-invited.visa-description', {
        defaultValue:
          'We have requested few documents from member to check visa eligibility criteria for {{firstName}} to migrate to {{country}}.',
        replace: {
          firstName,
          country,
        },
        context: gender,
      });

    return contractType === ContractType.FREELANCER
      ? t('onboarding-phase.member-invited.freelancer-description', {
          defaultValue:
            'We have invited {{ firstName }} on Multiplier, to collect additional details such as bank details, legal documents for payment processing and compliance. Request {{firstName}} to submit their personal details to complete the onboarding process.',
          replace: {
            firstName,
          },
          context: gender,
        })
      : t('onboarding-phase.member-invited.description', {
          defaultValue:
            'We are waiting for {{ firstName }} to accept your invitation so we can start the onboarding process. We will update here if {{firstName}} starts the onboarding process. You can resend the invite if you feel they may have missed it.',
          replace: {
            firstName,
          },
          context: gender,
        });
  },
  statusLabel: (t, contractType) =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.member-invited.contractor.label',
          'Contractor invited on Multiplier',
        )
      : t(
          'onboarding-phase.member-invited.label',
          'Sent invite email to Member',
        ),
  pendingOn: (contractType) => {
    switch (contractType) {
      case ContractType.FREELANCER:
        return PendingOn.FREELANCER;
      case ContractType.CONTRACTOR:
        return PendingOn.CONTRACTOR;
      default:
        return PendingOn.EMPLOYEE;
    }
  },
  pendingLabel: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.member-invited.contractor.status',
          'Pending on Contractor',
        )
      : t('onboarding-phase.member-invited.status', 'Pending on Employee'),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: () => true,
};

export default memberInvited;
