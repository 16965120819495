/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { DatePicker, Icon, ToolTip } from '@multiplier/common';
import { useGetLatestValidBirthdate } from '@multiplier/contract-onboarding';
import { theme } from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import ComboBox, { DropdownValue } from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import countryLabels from 'common/constants/country-labels';
import {
  countryWithStateList,
  getCountryStateAbbreviation,
  getStateLabel,
  getStateTitle,
} from 'contract-onboarding/company/services/eligibility-states';
import i18n from 'i18n';

import {
  Contract,
  Country,
  CountryCode,
  CountryWorkStatus,
  DataFieldDefinition,
  DateField,
  DropDownField,
  useGetCountryStatesLazyQuery,
} from '__generated__/graphql';

import { BasicDetailsFormValues } from '../../basic-details';
import PhoneNumberInput from './phone-number-input';

const placeholders: { [key: string]: string } = {
  'address.line1': i18n.t(
    'contract-onboarding.common:address-line1-placeholder',
    'Lincoln Road, 40',
  ),
  'address.line2': i18n.t(
    'contract-onboarding.common:address-line2-placeholder',
    'Unit 02-15',
  ),
  'address.country': i18n.t(
    'contract-onboarding.common:address-country-placeholder',
    'Select Country',
  ),
  'address.city': i18n.t(
    'contract-onboarding.common:address-city-placeholder',
    'City',
  ),
  'address.postalCode': i18n.t(
    'contract-onboarding.common:address-postalCode-placeholder',
    '123456',
  ),
  nationalId: i18n.t(
    'contract-onboarding.common:nationalId-placeholder',
    'ABCD-123456-789',
  ),
  taxpayerID: i18n.t(
    'contract-onboarding.common:taxpayerID-placeholder',
    'ABCD123456EFGXYZ0A',
  ),
};

const CountryAutocompleteInput: React.FC<{
  index: number;
  dataField: DataFieldDefinition;
  allCountriesDropdownValuesForNationality: {
    label: string;
    title: string;
    value: string;
  }[];
  allCountriesDropdownValues: {
    label: string;
    title: string;
    value: string;
  }[];
}> = ({
  index,
  dataField,
  allCountriesDropdownValuesForNationality,
  allCountriesDropdownValues,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    control,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  return (
    <>
      <Controller
        control={control}
        name={`preDataRequirements.${index}.value`}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            variant="autocomplete"
            value={String(value)}
            onChange={onChange}
            data-testid={String(dataField.key)}
            placeholder={t(
              `definition-phase.basic-details.member-requirement.${dataField.label}.placeholder`,
              String(dataField.label),
            )}
            dropdownValues={
              dataField.key === 'nationality'
                ? allCountriesDropdownValuesForNationality
                : allCountriesDropdownValues
            }
          />
        )}
      />
      {errors?.preDataRequirements?.[index]?.value && (
        <TextInput.Error>
          {errors?.preDataRequirements?.[index]?.value?.message}
        </TextInput.Error>
      )}
    </>
  );
};

const MemberLegalDetails: React.FC<{
  contractWorkStatus: Contract['workStatus'];
  contractCountry: Contract['country'];
  dataField: DataFieldDefinition;
  countryCode: Country['code'];
  index: number;
  addressStateRequired: boolean;
}> = ({
  contractWorkStatus,
  contractCountry,
  addressStateRequired,
  countryCode,
  dataField,
  index,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<BasicDetailsFormValues>();

  const preDataRequirements = watch('preDataRequirements');

  const addressCountry = useMemo(
    () =>
      preDataRequirements?.filter((req) => req?.key === 'address.country')?.[0]
        ?.value,
    [JSON.stringify(preDataRequirements)],
  );

  const [
    getCountryStates,
    { data: stateData },
  ] = useGetCountryStatesLazyQuery();

  useEffect(() => {
    if (addressCountry) {
      getCountryStates({
        variables: { country: addressCountry as CountryCode },
      }).then();
    }

    if (!countryWithStateList.includes(addressCountry as CountryCode)) {
      preDataRequirements?.forEach((req, idx) =>
        req?.key === 'address.state'
          ? setValue(`preDataRequirements.${idx}.value`, null)
          : null,
      );
    }
  }, [addressCountry]);

  const visaWorkflowEnabled = useFeature('contract.onboarding.visa-workflow')
    .on;

  const allCountriesDropdownValues = useMemo(
    () =>
      Object.values(CountryCode).map((c) => ({
        label: countryLabels[c],
        value: c,
        title: c,
      })),
    [],
  );

  const allCountriesDropdownValuesForNationality = useMemo(() => {
    if (
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      visaWorkflowEnabled
    ) {
      return allCountriesDropdownValues.filter(
        (c) => c.value !== contractCountry,
      );
    }
    return allCountriesDropdownValues;
  }, [
    contractWorkStatus,
    contractCountry,
    visaWorkflowEnabled,
    allCountriesDropdownValues,
  ]);

  const stateDropdownValues = useMemo(
    () =>
      (stateData?.country?.countryStates &&
        stateData.country.countryStates.filter(notEmpty).map((c) => ({
          label: getStateLabel(stateData?.country?.code, c),
          value: c?.code ?? '',
          title: getStateTitle(stateData?.country?.code, c),
          key: c?.code ?? '',
        }))) ??
      [],
    [stateData],
  );

  const isCountryAutocompleteField =
    dataField.dataType?.__typename === 'AutoCompleteField' &&
    dataField.dataType?.optionListType === 'COUNTRY';

  const latestValidBirthdate = useGetLatestValidBirthdate();

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor={String(dataField.key)} tw="flex flex-row">
        {t(
          `definition-phase.basic-details.member-requirement.${dataField.label}.label`,
          String(dataField.label),
        )}
        {countryCode === CountryCode.IDN && dataField.key === 'religion' && (
          <ToolTip
            content={t(
              'definition-phase.basic-details.indonesia.religion.tooltip',
              "The government of Indonesia recognises six official religions to provide special Religious Bonus, as mandated by the country's labor laws.",
            )}
          >
            <Icon
              tw="pl-6"
              fill={theme`colors.background`}
              name="info"
              data-testid="info-icon-religion"
            />
          </ToolTip>
        )}
        {countryCode === CountryCode.ITA && dataField.key === 'nationalId' && (
          <ToolTip
            content={t(
              'definition-phase.basic-details.italy.national-id.tooltip',
              'The fiscal code officially known as Codice fiscale, is the tax code in Italy, It is an alphanumeric code of 16 characters.',
            )}
          >
            <Icon
              tw="pl-6"
              fill={theme`colors.background`}
              name="info"
              data-testid="info-icon-national-id"
            />
          </ToolTip>
        )}
      </TextInput.Label>
      {dataField.dataType?.__typename === 'DateField' && (
        <Controller
          control={control}
          name={`preDataRequirements.${index}.value`}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              data-testid={String(dataField.key)}
              id={String(dataField.key)}
              value={value as string | Date}
              onChange={(val) => {
                if (val === '') onChange(undefined);
                else onChange(val);
              }}
              min={(dataField?.dataType as DateField)?.minDate ?? undefined}
              max={
                dataField.key === 'dateOfBirth'
                  ? latestValidBirthdate
                  : (dataField?.dataType as DateField)?.maxDate ?? undefined
              }
              error={!!errors?.preDataRequirements?.[index]?.value}
              helperText={errors?.preDataRequirements?.[index]?.value?.message}
              loading={false}
            />
          )}
        />
      )}
      {(dataField.dataType?.__typename === 'DropDownField' ||
        dataField.dataType?.__typename === 'DropDownTextField') && (
        <>
          <Controller
            control={control}
            name={`preDataRequirements.${index}.value`}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                variant="default"
                value={String(value)}
                onChange={onChange}
                data-testid={String(dataField.key)}
                placeholder={t(
                  `definition-phase.basic-details.member-requirement.${dataField.label}.placeholder`,
                  String(dataField.label),
                )}
                dropdownValues={
                  (dataField?.dataType as DropDownField)?.values?.filter(
                    notEmpty,
                  )?.length
                    ? ((dataField?.dataType as DropDownField)?.values
                        ?.filter(notEmpty)
                        .map((v) => ({
                          title: t(
                            `definition-phase.basic-details.member-requirement.${dataField.label}.option.${v}`,
                            v,
                          ),
                          value: v,
                        })) as DropdownValue[])
                    : []
                }
              />
            )}
          />
          {errors?.preDataRequirements?.[index]?.value && (
            <TextInput.Error>
              {errors?.preDataRequirements?.[index]?.value?.message}
            </TextInput.Error>
          )}
        </>
      )}
      {isCountryAutocompleteField && (
        <CountryAutocompleteInput
          index={index}
          dataField={dataField}
          allCountriesDropdownValuesForNationality={
            allCountriesDropdownValuesForNationality
          }
          allCountriesDropdownValues={allCountriesDropdownValues}
        />
      )}
      {addressStateRequired && (
        <>
          <Controller
            control={control}
            name={`preDataRequirements.${index}.value`}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                variant="autocomplete"
                value={String(value)}
                onChange={onChange}
                data-testid={String(dataField.key)}
                placeholder={t(
                  `definition-phase.basic-details.member-requirement.${dataField.label}-${addressCountry}.placeholder`,
                  {
                    defaultValue: 'Select {{-state}}',
                    replace: {
                      state:
                        addressCountry &&
                        getCountryStateAbbreviation(
                          addressCountry as CountryCode,
                        ),
                    },
                  },
                )}
                showTitleOnButton={addressCountry === CountryCode.CHN}
                dropdownValues={stateDropdownValues}
                showArrow
              />
            )}
          />
          {errors?.preDataRequirements?.[index]?.value && (
            <TextInput.Error>
              {errors?.preDataRequirements?.[index]?.value?.message}
            </TextInput.Error>
          )}
        </>
      )}
      {dataField.dataType?.__typename === 'TextField' && (
        <TextFieldRenderer
          dataField={dataField}
          index={index}
          countryCode={countryCode}
        />
      )}
    </TextInput.Container>
  );
};

export default MemberLegalDetails;

const TextFieldRenderer: React.FC<{
  dataField: DataFieldDefinition;
  index: number;
  countryCode?: Country['code'];
}> = ({ dataField, index, countryCode }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    register,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  return (
    <>
      {dataField.key === 'phoneNumber' ? (
        <PhoneNumberInput
          dataField={dataField}
          index={index}
          countryCode={countryCode}
        />
      ) : (
        <TextInput
          id={String(dataField.key)}
          data-testid={String(dataField.key)}
          defaultValue=""
          placeholder={
            placeholders[String(dataField.key)] ??
            t('other-placeholder', 'Please fill in information')
          }
          {...register(`preDataRequirements.${index}.value`)}
          error={!!errors?.preDataRequirements?.[index]?.value}
          autoComplete="off"
        />
      )}

      {errors?.preDataRequirements?.[index]?.value && (
        <TextInput.Error>
          {errors?.preDataRequirements?.[index]?.value?.message}
        </TextInput.Error>
      )}
    </>
  );
};
