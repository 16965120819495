import { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';

import {
  GetPositionsForIntegrationsQuery,
  useGetCompanyCustomerIntegrationsQuery,
  useGetPositionsForIntegrationsLazyQuery,
} from '__generated__/graphql';

const useGetSupportedJobTitles = (): {
  loading: boolean;
  supportedJobPositions: GetPositionsForIntegrationsQuery['getPositionsForIntegrations'];
} => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const {
    data: connectedIntegrationsData,
    loading: companyIntegrationsLoading,
  } = useGetCompanyCustomerIntegrationsQuery({
    skip: current !== Experience.COMPANY,
  });

  const [
    getIntegrationPositions,
    { data: supportedJobPositions, loading: jobPositionIntegrationLoading },
  ] = useGetPositionsForIntegrationsLazyQuery();

  const connectedIntegrationId = connectedIntegrationsData?.company?.customerIntegrations?.find(
    (i) => i?.connected && i?.isPositionDropdownListEnabled,
  )?.id;

  useEffect(() => {
    if (connectedIntegrationId)
      getIntegrationPositions({
        variables: {
          integrationId: connectedIntegrationId,
        },
      });
  }, [connectedIntegrationId]);

  return {
    loading: companyIntegrationsLoading || jobPositionIntegrationLoading,
    supportedJobPositions: supportedJobPositions?.getPositionsForIntegrations,
  };
};

export default useGetSupportedJobTitles;
