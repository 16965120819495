/** @jsxImportSource @emotion/react */
import React from 'react';
import 'twin.macro';

interface LabelComponentFreelancerProps {
  labelText: string;
  description: string;
}

const LabelComponentFreelancer: React.FC<LabelComponentFreelancerProps> = ({
  labelText,
  description,
}) => (
  <div tw="flex flex-col">
    <div tw="text-ps font-semibold text-text-primary">{labelText}</div>
    <p tw="text-ps font-normal text-text-secondary mt-2 mb-extra-small whitespace-pre-wrap">
      {description}
    </p>
  </div>
);

export default LabelComponentFreelancer;
