import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import { yupResolver } from '@hookform/resolvers/yup';
import uniqBy from 'lodash/uniqBy';
import tw from 'twin.macro';
import * as yup from 'yup';

import { useLazySearchParams, useUpdateSearchParams } from '../../../hooks';
import TabButton from '../../../tab-button';
import { notEmpty } from '../../../utils/array';
import { prependTableIdForUrlParams } from '../../../utils/table-utils';
import {
  TableFilter,
  TableFilterConfigType,
  TableFilterFormParams,
  TableFilterFormValue,
  TableFilterTypes,
  TableFiltersHandlers,
  TableFiltersProps,
  isTableFilterFormDateValue,
} from '../../types';
import FilterForm from './components/filter-form';
import FilterText from './components/filter-text';
import MinimalFilterForm from './components/minimal-filter-form';

const TableFilters = forwardRef<TableFiltersHandlers, TableFiltersProps>(
  (
    {
      associatedTableIds,
      filters,
      handleFilterChange,
      loading = false,
      tabs,
      selectedTab,
      handleTabChange,
      leadingText,
      showAddNewFilter = true,
      defaultFilters: initialFilters,
      variant = 'default',
      minimalFilterWrapperStyles,
      minimalFilterButtonStyles,
      submitBtnText,
      validation,
      disable,
    },
    ref,
  ) => {
    const [selectedFilters, setSelectedFilters] = useState<{
      [key: string]: TableFilterFormValue;
    }>();
    const updateSearchParams = useUpdateSearchParams();
    const getSearchParams = useLazySearchParams();

    const { t } = useTranslation('common.table');

    const defaultFilters = useMemo(() => {
      if (!initialFilters && filters) {
        const primaryFilter = Object.keys(filters).find(
          (key) => filters?.[key]?.primary,
        );
        const nonPrimaryFilter = Object.keys(filters).find(
          (key) => !filters?.[key]?.primary,
        );
        if (!primaryFilter && nonPrimaryFilter) {
          return [
            {
              key: nonPrimaryFilter, // old logic, idkw
              value: '',
            },
          ];
        }
        return [];
      }
      return initialFilters || [];
    }, [initialFilters, filters]);

    const filterItemSchema = yup.object().shape({
      key: yup
        .string()
        .required(
          t(
            'filter-value-input.input-required',
            'Input string required',
          ) as string,
        ),
      value: yup.mixed().when('key', (key) => {
        const filterType = filteredFilters?.[key as any]?.type;
        if (filterType === TableFilterTypes.RANGE) {
          const requiredRangeMessage = t(
            'filter-value-input.range-value-required',
            'Minimum and maximum values required',
          ) as string;
          return yup
            .object()
            .shape({
              minimum: yup.number().required(requiredRangeMessage),
              maximum: yup.number().required(requiredRangeMessage),
            })
            .required(requiredRangeMessage);
        }
        if (filterType === TableFilterTypes.DATE_RANGE) {
          const requiredDateRangeMessage = t(
            'filter-value-input.date-range-required',
            'Date range required',
          ) as string;
          return yup
            .object()
            .shape({
              startDate: yup.mixed().required(requiredDateRangeMessage),
              endDate: yup.mixed().required(requiredDateRangeMessage),
            })
            .required(requiredDateRangeMessage);
        }
        if (filterType === TableFilterTypes.MONTH_RANGE) {
          const requiredMonthRangeMessage = t(
            'filter-value-input.month-range-required',
            'Month range required',
          ) as string;
          return yup
            .object()
            .shape({
              startMonth: yup.mixed().required(requiredMonthRangeMessage),
              endMonth: yup.mixed().required(requiredMonthRangeMessage),
            })
            .required(requiredMonthRangeMessage);
        }
        const requiredMessage = t(
          'filter-value-input.input-required',
          'Input string required',
        ) as string;
        if (
          [
            TableFilterTypes.MULTI_DROPDOWN,
            TableFilterTypes.MULTI_TEXT_INPUT,
          ].includes(filterType)
        ) {
          return yup.array().of(yup.string()).required(requiredMessage);
        }
        return yup.string().required(requiredMessage);
      }),
    });

    const formSchema = yup.object().shape({
      primaryFilter: yup.lazy((value) => {
        if (!value) return yup.mixed().notRequired();
        return filterItemSchema;
      }),
      filters: yup
        .array()
        .min(validation?.min || 0)
        .of(filterItemSchema),
    });

    useImperativeHandle(ref, () => ({
      resetSubmittedState() {
        methods.reset(methods.getValues()); // clear all formState
      },
      resetSelectedFilters() {
        applyNewFilters();
      },
      clearAllFilters() {
        handleClearForm();
      },
    }));

    const filteredFilters = useMemo(
      () =>
        Object.keys(filters).reduce((prev, curr) => {
          if (!filters[curr].disabled) {
            prev[curr] = filters[curr];
          }
          return prev;
        }, {} as TableFilterConfigType),
      [filters],
    );

    const onSelectedFilterChange = (val: TableFilter[]) => {
      if (handleFilterChange) {
        const filterObject = val.reduce(
          (prev: { [key: string]: TableFilterFormValue }, curr) => {
            if (filteredFilters[curr.key]?.type === TableFilterTypes.TEXT) {
              prev[curr.key] = (curr.value as string).trim();
            } else if (
              filteredFilters[curr.key]?.type === TableFilterTypes.DATE_RANGE &&
              isTableFilterFormDateValue(curr.value) &&
              curr.value.endDate
            ) {
              // If the filter is a Date Range, then this sets the time of the end date to be 23:59:59
              // so that BE can filter records correctly.
              const date = new Date(curr.value.endDate);
              const end = new Date(date.setUTCHours(23, 59, 59, 999));
              curr.value.endDate = end.toISOString();
              prev[curr.key] = curr.value;
            } else {
              prev[curr.key] = curr.value;
            }

            return prev;
          },
          {},
        );
        setSelectedFilters(filterObject);
        handleFilterChange(filterObject);
      }
    };

    const onSubmit = (val: TableFilterFormParams) => {
      const filterKeys = Object.keys(filteredFilters).reduce(
        (prev: string[], curr) => {
          if (filteredFilters[curr].type === TableFilterTypes.DATE_RANGE) {
            prev.push(`${curr}[startDate]`);
            prev.push(`${curr}[endDate]`);
          } else if (
            filteredFilters[curr].type === TableFilterTypes.MONTH_RANGE
          ) {
            prev.push(`${curr}[startMonth]`);
            prev.push(`${curr}[endMonth]`);
          } else {
            prev.push(curr);
          }
          return prev;
        },
        [],
      );

      const submittedFilters: TableFilter[] = [...val.filters];
      if (val.primaryFilter) submittedFilters.unshift(val.primaryFilter);

      // Update URL parameters with new selected filters
      const params = submittedFilters.reduce(
        (prev: { key: string; value: string | string[] }[], curr) => {
          if (
            curr?.value &&
            typeof curr.value !== 'string' &&
            'startDate' in curr.value &&
            'endDate' in curr.value
          ) {
            prev.push({
              key: `${curr.key}[startDate]`,
              value: curr.value.startDate?.toString() ?? '',
            });
            prev.push({
              key: `${curr.key}[endDate]`,
              value: curr.value.endDate?.toString() ?? '',
            });
          } else if (
            curr?.value &&
            typeof curr.value !== 'string' &&
            'startMonth' in curr.value &&
            'endMonth' in curr.value
          ) {
            prev.push({
              key: `${curr.key}[startMonth]`,
              value: curr.value.startMonth?.toString() ?? '',
            });
            prev.push({
              key: `${curr.key}[endMonth]`,
              value: curr.value.endMonth?.toString() ?? '',
            });
          } else if (
            curr?.value &&
            typeof curr.value !== 'string' &&
            'minimum' in curr.value &&
            'maximum' in curr.value
          ) {
            prev.push({
              key: `${curr.key}[minimum]`,
              value: curr.value.minimum?.toString() ?? '',
            });
            prev.push({
              key: `${curr.key}[maximum]`,
              value: curr.value.maximum?.toString() ?? '',
            });
          } else if (curr?.value && Array.isArray(curr.value)) {
            prev.push({
              key: `${curr.key}`,
              value: (curr.value as string[]) ?? [],
            });
          } else {
            prev.push(curr as { key: string; value: string });
          }
          return prev;
        },
        [],
      );

      // Resets the pagination to the first page
      if (associatedTableIds?.length) {
        associatedTableIds?.forEach((tableId) => {
          const tablePageParamKey = prependTableIdForUrlParams('page', tableId);
          const tablePageParamValue = getSearchParams(tablePageParamKey)?.[0];
          if (tablePageParamValue) {
            params.push({
              key: tablePageParamKey,
              value: '1',
            });
          }
        });
      } else {
        const page = getSearchParams('page');
        if (page?.[0]) {
          params.push({
            key: 'page',
            value: '1',
          });
        }
      }

      if (!disable?.urlBinding) {
        updateSearchParams(
          params as { key: string; value: string }[],
          filterKeys,
        );
      }

      onSelectedFilterChange(submittedFilters);
    };

    const methods = useForm<TableFilterFormParams>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: validation?.disable ? undefined : yupResolver(formSchema),
    });

    const fieldArray = useFieldArray<TableFilterFormParams>({
      control: methods.control,
      name: 'filters',
      shouldUnregister: false,
    });

    const handleClearForm = () => {
      methods.reset({
        primaryFilter: undefined,
        filters: [],
      });
      methods.handleSubmit(onSubmit)();
    };

    const handleFormResetToDefault = () => {
      methods.reset({
        primaryFilter: methods.getValues('primaryFilter'),
        filters: [],
      });

      methods.handleSubmit(onSubmit)();
    };

    const getAppliedFiltersFromUrl = (): {
      key: string;
      value: TableFilterFormValue;
    }[] => {
      const filterKeys = Object.keys(filteredFilters).reduce(
        (prev: string[], curr) => {
          if (filteredFilters[curr]?.type === TableFilterTypes.DATE_RANGE) {
            prev.push(`${curr}[endDate]`);
            prev.push(`${curr}[startDate]`);
          } else if (
            filteredFilters[curr]?.type === TableFilterTypes.MONTH_RANGE
          ) {
            prev.push(`${curr}[endMonth]`);
            prev.push(`${curr}[startMonth]`);
          } else if (filteredFilters[curr]?.type === TableFilterTypes.RANGE) {
            prev.push(`${curr}[minimum]`);
            prev.push(`${curr}[maximum]`);
          } else {
            prev.push(curr);
          }
          return prev;
        },
        [],
      );
      const urlFilterValues = getSearchParams(...filterKeys);
      return filterKeys
        .map((key, idx) => ({
          key,
          value: urlFilterValues[idx],
        }))
        .filter((x) => notEmpty(x.value))
        .reduce(
          (prev: { key: string; value: TableFilterFormValue }[], curr) => {
            const processDates = (dateKey: string) => {
              if (curr.key.includes(`[${dateKey}]`)) {
                const key = curr.key.replace(`[${dateKey}]`, '');
                const index = prev.findIndex((x) => x.key === key);
                if (
                  prev[index]?.value &&
                  typeof prev[index].value === 'object'
                ) {
                  prev[index].value = {
                    ...(prev[index].value as {
                      startDate?: Date | string | null;
                      endDate?: Date | string | null;
                    }),
                    [dateKey]: curr.value,
                  };
                } else {
                  prev.push({
                    key,
                    value: {
                      [dateKey]: curr.value,
                    },
                  });
                }
              }
            };

            const processMonths = (monthKey: string) => {
              if (curr.key.includes(`[${monthKey}]`)) {
                const key = curr.key.replace(`[${monthKey}]`, '');
                const index = prev.findIndex((x) => x.key === key);
                if (
                  prev[index]?.value &&
                  typeof prev[index].value === 'object'
                ) {
                  prev[index].value = {
                    ...(prev[index].value as {
                      startMonth?: string | null;
                      endMonth?: string | null;
                    }),
                    [monthKey]: curr.value,
                  };
                } else {
                  prev.push({
                    key,
                    value: {
                      [monthKey]: curr.value,
                    },
                  });
                }
              }
            };

            const processRange = (rangeKey: string) => {
              if (curr.key.includes(`[${rangeKey}]`)) {
                const key = curr.key.replace(`[${rangeKey}]`, '');
                const index = prev.findIndex((x) => x.key === key);
                if (
                  prev[index]?.value &&
                  typeof prev[index].value === 'object'
                ) {
                  prev[index].value = {
                    ...(prev[index].value as {
                      minimum?: string | null;
                      maximum?: string | null;
                    }),
                    [rangeKey]: curr.value,
                  };
                } else {
                  prev.push({
                    key,
                    value: {
                      [rangeKey]: curr.value,
                    },
                  });
                }
              }
            };

            if (curr.key.includes('[endDate]')) {
              processDates('endDate');
            } else if (curr.key.includes('[startDate]')) {
              processDates('startDate');
            } else if (curr.key.includes('[endMonth]')) {
              processMonths('endMonth');
            } else if (curr.key.includes('[startMonth]')) {
              processMonths('startMonth');
            } else if (curr.key.includes('[minimum]')) {
              processRange('minimum');
            } else if (curr.key.includes('[maximum]')) {
              processMonths('maximum');
            } else if (
              [
                TableFilterTypes.MULTI_DROPDOWN,
                TableFilterTypes.MULTI_TEXT_INPUT,
              ].includes(filteredFilters[curr.key].type)
            ) {
              prev.push({
                key: curr.key,
                value: curr.value?.split(',') as string[],
              });
            } else {
              prev.push(curr);
            }
            return prev;
          },
          [],
        );
    };

    const applyNewFilters = (
      // apply new filters
      additionalFilters?: { key: string; value: TableFilterFormValue }[],
    ) => {
      const hasAdditionalFilters =
        Array.isArray(additionalFilters) && additionalFilters.length > 0;

      const filtersToApply: { key: string; value: TableFilterFormValue }[] = [
        ...((hasAdditionalFilters ? additionalFilters : defaultFilters) ?? []),
      ];

      const primaryFilterKeys = Object.keys(filteredFilters).filter(
        (filter) => filteredFilters[filter].primary,
      );

      if (primaryFilterKeys.length > 0) {
        primaryFilterKeys.forEach((key) => {
          filtersToApply.push({
            key,
            value:
              filteredFilters[key]?.type === TableFilterTypes.TEXT ? '' : null,
          });
        });
      }

      const filtersGrouped = uniqBy(filtersToApply, 'key')
        .filter(
          (x) => filteredFilters[x.key]?.primary || (x.value && x.value !== ''),
        )
        .reduce(
          (prev, curr) => {
            if (filteredFilters[curr.key]?.primary) {
              prev.primary.push(curr);
            } else {
              prev.nonPrimary.push(curr);
            }
            return prev;
          },
          {
            primary: [] as { key: string; value: TableFilterFormValue }[],
            nonPrimary: [] as { key: string; value: TableFilterFormValue }[],
          },
        );

      const selectedPrimaryFilter =
        filtersGrouped.primary.length > 0 &&
        (filtersGrouped.primary.find((x) => x.value && x.value !== '') ||
          filtersGrouped.primary[0]);
      methods.reset({
        primaryFilter: selectedPrimaryFilter
          ? {
              key: selectedPrimaryFilter.key,
              value: selectedPrimaryFilter.value,
            }
          : undefined,
        filters:
          filtersGrouped.nonPrimary?.length === 0
            ? defaultFilters
            : filtersGrouped.nonPrimary,
      });

      if (!disable?.initialSubmission) {
        methods.handleSubmit(onSubmit)();
      }
    };

    // Runs on component load & on filter change
    // Gets selected filters from URL and submits with it
    useDeepCompareEffect(() => {
      if (filteredFilters) {
        const urlFilters = getAppliedFiltersFromUrl();
        const filterKeysFromCurrentFilterConfig = [
          ...methods.getValues('filters'),
        ].filter((ele) => Object.keys(filteredFilters).includes(ele.key));

        const filtersToApply = [
          ...urlFilters,
          ...filterKeysFromCurrentFilterConfig,
        ];

        applyNewFilters(filtersToApply);
      }
    }, [filteredFilters, defaultFilters]);

    const selectedFilterCount = useMemo(
      () =>
        (selectedFilters &&
          Object.keys(selectedFilters)?.reduce((prev, curr) => {
            if (selectedFilters[curr]) {
              prev += 1;
            }
            return prev;
          }, 0)) ??
        0,
      [selectedFilters],
    );

    const hasRequiredFilters = useMemo(
      () =>
        Object.keys(filters).reduce((prev, curr) => {
          if (filters[curr].required || filters[curr].primary) {
            prev = true;
          }
          return prev;
        }, false),
      [filters],
    );

    return (
      <FormProvider {...methods}>
        <div>
          {variant === 'default' && (
            <FilterForm
              loading={loading}
              filteredFilters={filteredFilters}
              handleFormResetToDefault={handleFormResetToDefault}
              fieldArray={fieldArray}
              onSubmit={onSubmit}
              showAddNewFilter={showAddNewFilter}
              submitBtnText={submitBtnText}
              disable={disable}
            />
          )}
          <div
            css={[
              tw`flex tablet:(flex-col) mobile:(flex-col) justify-between items-center mb-small`,
              { ...minimalFilterWrapperStyles },
            ]}
          >
            <div tw="tablet:(order-last mt-6) mobile:(order-last mt-6)">
              <FilterText
                selectedFilters={selectedFilters}
                filters={filteredFilters}
                leadingText={leadingText}
              />
            </div>
            <div tw="max-w-max w-full flex items-center tablet:(justify-between max-w-full) mobile:(justify-between max-w-full)">
              <div tw="divide-x divide-border-primary ">
                {tabs?.map((tab) => (
                  <TabButton
                    key={tab.key}
                    isActive={selectedTab === tab.key}
                    data-testid={selectedTab === tab.key ? 'active-tab' : 'tab'}
                    onClick={() => {
                      if (handleTabChange) {
                        updateSearchParams([{ key: 'page', value: '1' }]);
                        handleTabChange(tab.key, selectedFilters);
                      }
                    }}
                  >
                    {tab.title}
                  </TabButton>
                ))}
              </div>
              {variant === 'minimal' && (
                <MinimalFilterForm
                  loading={loading}
                  filteredFilters={filteredFilters}
                  handleFormResetToDefault={handleFormResetToDefault}
                  fieldArray={fieldArray}
                  onSubmit={onSubmit}
                  showAddNewFilter={showAddNewFilter}
                  selectedFilterCount={selectedFilterCount}
                  hasRequiredFilters={hasRequiredFilters}
                  resetFilters={handleClearForm}
                  minimalFilterButtonStyles={minimalFilterButtonStyles}
                  disable={disable}
                />
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    );
  },
);

export default TableFilters;
