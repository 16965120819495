/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Callout, CalloutVariant } from '@multiplier/common';

import AppFeature from 'app/features';
import Radio from 'common/components/radio';
import TextInput from 'common/components/text-input';
import countryLabels from 'common/constants/country-labels';
import { contractTermLabels } from 'contract-onboarding/constants/labels';

import { ContractTerm, ContractType, CountryCode } from '__generated__/graphql';

import { BasicDetailsFormValues } from '../basic-details';
import {
  GroupedComplianceLimitationDefinition,
  getDisabledFieldDescription,
  isFieldDisabled,
  isFieldDisabledWithPreconditions,
} from '../helpers';

interface ContractTermSelectorProps {
  disabledFields: GroupedComplianceLimitationDefinition;
  selectedCountry?: CountryCode | null;
}

const ContractTermSelector: React.FC<ContractTermSelectorProps> = ({
  disabledFields,
  selectedCountry,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const { watch, register } = useFormContext<BasicDetailsFormValues>();

  const contractType = watch('contractType');

  const isSorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;
  const singleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;

  if (
    contractType === ContractType.FREELANCER ||
    contractType === ContractType.CONTRACTOR ||
    (contractType === ContractType.HR_MEMBER &&
      !isSorOnboardingEnabled &&
      singleOnboardingEnabled)
  ) {
    return null;
  }

  const permanentTermSelectionDisabled = isFieldDisabledWithPreconditions(
    disabledFields,
    'contractTerm',
    ContractTerm.PERMANENT,
    { contractType },
  );

  return (
    <>
      <TextInput.Container>
        <TextInput.Label>
          {t(
            'definition-phase.basic-details.employment-type.label',
            'Employment Type',
          )}
        </TextInput.Label>
        <Radio
          disabled={permanentTermSelectionDisabled}
          value={ContractTerm.PERMANENT}
          info={
            permanentTermSelectionDisabled
              ? getDisabledFieldDescription(disabledFields, 'contractTerm') ||
                t(
                  'definition-phase.basic-details.employment-type.permanent-disabled',
                  {
                    defaultValue:
                      '{{ country }} does not provide Permanent Employment in the employee’s first year of employment',
                    replace: {
                      country:
                        selectedCountry && countryLabels[selectedCountry],
                    },
                  },
                )
              : undefined
          }
          {...register('contractTerm')}
        >
          {contractTermLabels[ContractTerm.PERMANENT]}
        </Radio>
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label tw="invisible">spacer</TextInput.Label>
        <Radio
          value={ContractTerm.FIXED}
          disabled={isFieldDisabled(
            disabledFields,
            'contractTerm',
            ContractTerm.FIXED,
          )}
          info={
            isFieldDisabled(disabledFields, 'contractTerm', ContractTerm.FIXED)
              ? getDisabledFieldDescription(disabledFields, 'contractTerm') ||
                t(
                  'definition-phase.basic-details.employment-type.fixed-disabled',
                  {
                    defaultValue:
                      '{{ country }} does not provide Fixed Employment at the moment',
                    replace: {
                      country:
                        selectedCountry && countryLabels[selectedCountry],
                    },
                  },
                )
              : undefined
          }
          {...register('contractTerm')}
        >
          {contractTermLabels[ContractTerm.FIXED]}
        </Radio>
      </TextInput.Container>
      {selectedCountry === CountryCode.ISR &&
        watch('contractTerm') === ContractTerm.FIXED && (
          <Callout
            variant={CalloutVariant.INFO}
            tw="text-ps bg-blue200 col-span-2 items-center p-12"
          >
            <p tw="font-normal text-foreground">
              {t(
                'definition-phase.basic-details.employment-type.israel-contract-term-warning',
                "The employment contract cannot be extended beyond the initial term specified. Premature termination is strictly prohibited, and if it occurs, the customer may be liable for the entire remaining term's cost.",
              )}
            </p>
          </Callout>
        )}
    </>
  );
};

export default ContractTermSelector;
