import { gql } from '@apollo/client';

export default gql`
  fragment paymentAccountRequirementFragment on PaymentAccountRequirement {
    accountType
    transferType
    sourceCurrency
    targetCurrency
    paymentPartner
    paymentAccountRequirementType
    requirementFields {
      key
      label
      isMandatory
      type
      regex
      allowedValues {
        key
        value
      }
      errorMessage
      hasSubFields
      subFields {
        key
        value {
          key
          label
          isMandatory
          type
          regex
          allowedValues {
            key
            value
          }
          errorMessage
          hasSubFields
          subFields {
            key
            value {
              key
              label
              isMandatory
              type
              regex
              errorMessage
              allowedValues {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;
