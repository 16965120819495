/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as LogoV2 } from '@multiplier/assets/files/common/logo.svg';
import { ReactComponent as CashBagV2 } from '@multiplier/assets/files/contract-onboarding/cash-bag-v2.svg';
import { ReactComponent as CashBag } from '@multiplier/assets/files/contract-onboarding/cash-bag.svg';
import { ReactComponent as CashBookV2 } from '@multiplier/assets/files/contract-onboarding/cash-book-v2.svg';
import { ReactComponent as CashBook } from '@multiplier/assets/files/contract-onboarding/cash-book.svg';
import { ReactComponent as IdentityCardV2 } from '@multiplier/assets/files/contract-onboarding/identity-card-v2.svg';
import { ReactComponent as IdentityCard } from '@multiplier/assets/files/contract-onboarding/identity-card.svg';
import { Button, Icon, ThemeContext } from '@multiplier/common';
import tw, { css, theme } from 'twin.macro';

import { useStartMemberOnboarding } from 'contract-onboarding/member/hooks';

import { useGetMemberLazyQuery } from '__generated__/graphql';

const MemberStart: React.FC = () => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.member');
  const [
    getMember,
    { data: { member } = { member: {} } },
  ] = useGetMemberLazyQuery();
  const contract = useMemo(() => member?.contracts?.[0], [member]);
  const onSubmit = useStartMemberOnboarding(contract?.id, contract?.onboarding);

  useEffect(() => {
    getMember();
  }, []);

  const companyName = contract?.company?.displayName ?? 'our company';

  return (
    <div
      css={[
        tw`flex h-screen w-screen justify-center relative`,
        css`
          background: linear-gradient(
            to bottom,
            ${theme`colors.background-inverted`} 50%,
            #ffffff 50%
          );
        `,
        !isNewThemeApplied &&
          css`
            background: linear-gradient(
              to bottom,
              ${theme`colors.background`} 50%,
              #ffffff 50%
            );
          `,
      ]}
      data-testid="member-start"
    >
      <div tw="mt-[6%] text-center max-width[762px]">
        <div tw="flex flex-col items-center">
          <h3 tw="text-h3 font-semibold text-text-inverted-primary">
            <Trans
              t={t}
              tOptions={{ interpolation: { escapeValue: false } }}
              i18nKey="start.member-welcome"
              values={{ companyName }}
            >
              Welcome to {{ companyName }} on Multiplier
            </Trans>
          </h3>
          <p tw="text-p font-normal text-text-inverted-primary mt-base w-3/4">
            {t(
              'start.description',
              'To complete your contract we will need some information from you. Before continuing, make sure the following documents are ready.',
            )}
          </p>
          <p tw="font-size[18px] font-semibold mt-extra-large text-text-inverted-primary">
            {t('start.items-to-prepare', 'Items To Prepare')}
          </p>
          <div tw="flex mt-large">
            <div tw="width[180px] height[206px] bg-background-white shadow border border-border-primary rounded-base mx-small flex flex-col items-center justify-between">
              {!isNewThemeApplied ? (
                <CashBag tw="mt-48" />
              ) : (
                <CashBagV2 tw="mt-48" />
              )}
              <p tw="text-p mb-large">
                {t('start.bank-details', 'Bank Details')}
              </p>
            </div>
            <div tw="width[180px] height[206px] bg-background-white shadow border border-border-primary rounded-base mx-small flex flex-col items-center justify-between">
              {!isNewThemeApplied ? (
                <CashBook tw="mt-48" />
              ) : (
                <CashBookV2 tw="mt-48" />
              )}
              <p tw="text-p mb-large">{t('start.bank-book', 'Bank Book')}</p>
            </div>
            <div tw="width[180px] height[206px] bg-background-white shadow border border-border-primary rounded-base mx-small flex flex-col items-center justify-between">
              {!isNewThemeApplied ? (
                <IdentityCard tw="mt-48" />
              ) : (
                <IdentityCardV2 tw="mt-48" />
              )}
              <p tw="text-p mb-large w-2/3">
                {t('start.identity-passport', 'Identity Card / Passport')}
              </p>
            </div>
          </div>
          <p tw="text-p font-normal mt-extra-large text-text-primary">
            {t(
              'start.onboarding-description',
              'Onboarding process will take approximately',
            )}{' '}
            <span tw="font-semibold underline">
              {t('start.onboarding-duration', '4 minutes')}
            </span>
          </p>
        </div>
        <Button size="medium" tw="mt-small" onClick={onSubmit}>
          {t('start.get-started', 'Get Started')}
        </Button>
      </div>
      {!isNewThemeApplied ? (
        <Icon name="logo" tw="absolute bottom-extra-large" />
      ) : (
        <LogoV2 tw="h-extra-large absolute bottom-extra-large" />
      )}
    </div>
  );
};

export default MemberStart;
