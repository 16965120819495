import { ReactElement, useState } from 'react';

import { useModal } from 'app/hooks';
import LegacyOffboardingForNotStartedMemberDialog from 'team/company/components/legacy-offboarding-for-not-started-member';

import {
  Contract,
  ContractOffboardMutationVariables,
  Maybe,
} from '__generated__/graphql';

import { useContractOffboard } from './index';

interface OffboardNotStartedMemberReturnTypes {
  offboardDialogOpen: boolean;
  offboardInProgress: boolean;
  handleOffboardClick: (contract?: Maybe<Contract>) => void;
  handleOffboardClose: () => void;
  handleOffboardConfirm: (values: ContractOffboardMutationVariables) => void;
  contractToOffboard?: Contract;
  Dialog: () => ReactElement;
}

export default (): OffboardNotStartedMemberReturnTypes => {
  const [contractToOffboard, setContractToOffboard] = useState<Contract>();
  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal();
  const { onSubmit, contractOffboardLoading } = useContractOffboard();

  const handleOffboardConfirm = async (
    values: ContractOffboardMutationVariables,
  ) => {
    if (contractToOffboard?.id) {
      await onSubmit({
        ...values,
      });
    }
    setContractToOffboard(undefined);
    handleCloseDialog();
  };

  const handleOffboardClick = (contract?: Maybe<Contract>) => {
    if (contract) {
      setContractToOffboard(contract);
      handleOpenDialog();
    }
  };

  const handleOffboardClose = () => {
    setContractToOffboard(undefined);
    handleCloseDialog();
  };

  const Dialog = () => (
    <LegacyOffboardingForNotStartedMemberDialog
      contract={contractToOffboard}
      show={showDialog}
      onClose={handleOffboardClose}
      onConfirm={handleOffboardConfirm}
      loading={contractOffboardLoading}
    />
  );

  return {
    offboardDialogOpen: showDialog,
    offboardInProgress: contractOffboardLoading,
    handleOffboardConfirm,
    handleOffboardClick,
    handleOffboardClose,
    contractToOffboard,
    Dialog,
  };
};
