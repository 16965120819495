import { gql } from '@apollo/client';

export default gql`
  query GetMemberWithLegalEntity {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        country
        compliance {
          type
          legalEntity {
            legalName
            address {
              line1
              line2
              country
            }
          }
        }
      }
    }
  }
`;
