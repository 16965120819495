/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton, ThemeContext } from '@multiplier/common';
import { theme } from 'twin.macro';

import {
  convert24HourTo12HourFormat,
  getWeekDayLabel,
} from 'contract-onboarding/company/services/work-shift-utils';

import { WorkShiftInput } from '__generated__/graphql';

export const WorkShiftValue: React.FC<{ workShift: WorkShiftInput }> = ({
  workShift,
  ...props
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  return (
    <div tw="text-ps" {...props}>
      <p>
        <span>{getWeekDayLabel(workShift?.startDay, t)}</span>
        &nbsp;-&nbsp;
        <span>{getWeekDayLabel(workShift?.endDay, t)}</span>
      </p>
      <p>
        <span>
          {convert24HourTo12HourFormat(workShift?.workingHours?.startTime, t)}
        </span>
        &nbsp;-&nbsp;
        <span>
          {convert24HourTo12HourFormat(workShift?.workingHours?.endTime, t)}
        </span>
      </p>
    </div>
  );
};

const WorkShiftInfo: React.FC<{
  workShift: WorkShiftInput;
  onEditClick: () => void;
  onDeleteClick: () => void;
}> = ({ workShift, onEditClick, onDeleteClick }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div
      data-testid="work-shift-info"
      tw="flex items-center justify-between p-base border rounded-base border-border-primary"
    >
      <WorkShiftValue
        workShift={workShift}
        tw="font-semibold text-text-primary"
      />
      <div tw="flex gap-x-small">
        <IconButton
          data-testid="edit-button"
          name="pencil"
          fill={isNewThemeApplied ? undefined : 'transparent'}
          stroke={isNewThemeApplied ? undefined : theme`colors.primary`}
          variant="outline"
          size="medium"
          onClick={onEditClick}
        />
        <IconButton
          data-testid="delete-button"
          name="bin"
          fill={isNewThemeApplied ? undefined : 'transparent'}
          stroke={isNewThemeApplied ? undefined : theme`colors.primary`}
          variant="outline"
          size="medium"
          onClick={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default WorkShiftInfo;
