import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as SpinnerSVG } from '../../../icon/icons/spinner.svg';
import LoaderGif from '../../assets/loader.gif';
import { SpinnerProps, SpinnerType } from '../../types';

const variantStyles = {
  [SpinnerType.EXPERIENCE]: tw``,
  [SpinnerType.CUSTOM_LAYOUT]: tw`w-full`,
  [SpinnerType.MODULE]: tw``,
  [SpinnerType.DOCUMENT_VIEWER]: tw`h-[80vh] w-full`,
  [SpinnerType.CALENDAR]: tw`w-full`,
};

const Spinner: React.FC<SpinnerProps> = ({
  variant = SpinnerType.EXPERIENCE,
  ...props
}) => {
  // Spinner shows before theme context is loaded. This will be the fallback.
  const themeAttribute = document.documentElement.getAttribute('data-theme');

  return (
    <div
      css={[tw`relative h-screen`, variantStyles[variant]]}
      data-testid="spinner"
      {...props}
    >
      {/* This component will use in Suspense so it need to directly */}
      {themeAttribute === 'Multiplier 2.2' ? (
        <img
          tw="w-[48px] h-[48px] absolute inset-0 m-auto"
          src={LoaderGif}
          alt="loading"
        />
      ) : (
        <SpinnerSVG
          tw="absolute inset-0 m-auto animate-spin"
          fill="transparent"
        />
      )}
    </div>
  );
};

export default Spinner;
