/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Callout,
  CalloutVariant,
  CardSeparator,
  ThemeContext,
} from '@multiplier/common';
import { isEmpty, isNil } from 'lodash';
import tw, { styled } from 'twin.macro';

import { InsuranceDetailsPrams } from 'contract-onboarding/member/pages/onboarding/pages/insurance-details';

import { Contract, CountryCode } from '__generated__/graphql';

import DependantHeader, {
  DependantContainer,
  DependantDescription,
  DependantTitle,
} from '../dependant-header';
import Form from './form';

const FormCard = styled.div(({ noPadding }: { noPadding: boolean }) => [
  tw`rounded-base flex flex-col mb-large bg-background-white p-large`,
  noPadding && tw`p-none mb-none`,
]);

const DependantForm: React.FC<{
  dependantList?: InsuranceDetailsPrams | null;
  dependantCount?: number;
  edit?: boolean;
  country?: Contract['country'];
}> = ({ dependantList, dependantCount, edit = false, country }) => {
  const { t } = useTranslation('contract-onboarding.member');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const isUSA = country === CountryCode.USA;

  const maxDependantCount = useMemo(() => {
    if (!dependantCount) return 0;

    if (dependantCount < 0) return Number.MAX_SAFE_INTEGER;

    return dependantCount;
  }, [dependantCount]);

  const methods = useFormContext<InsuranceDetailsPrams>();

  const {
    control,
    trigger,
    reset,
    getValues,
    formState: { errors, isValid },
  } = methods;

  const { fields, append, remove } = useFieldArray<InsuranceDetailsPrams>({
    control,
    name: 'insuranceDetails',
  });

  useEffect(() => {
    if (isEmpty(dependantList) && getValues('insuranceDetails')?.length === 0) {
      handleAddDependant();
    }
    if (!isEmpty(dependantList) && !isNil(dependantList)) reset(dependantList);
  }, [dependantList]);

  const handleAddDependant = async () => {
    if (fields.length === maxDependantCount) return;
    if (fields.length !== 0) {
      await trigger();
      if (!isValid) return;
    }

    append({
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      gender: null,
      relationship: null,
      nationalIdNumber: '',
    });
  };

  const addDependentsContent =
    fields.length < maxDependantCount ? (
      <div css={[tw`grid grid-cols-3`]}>
        <Button size="small" variant="secondary" onClick={handleAddDependant}>
          {t(
            'definition-phase.basic-details.add-dependant.button-secondary',
            'Add dependent',
          )}
        </Button>
      </div>
    ) : maxDependantCount > 0 ? (
      <div tw="mb-[30px]">
        <Callout variant={CalloutVariant.INFO}>
          <Trans
            t={t}
            i18nKey="definition-phase.basic-details.maximum-limit"
            values={{
              dependantCount,
            }}
          >
            <p tw="text-ps text-text-primary font-normal">
              You have reached the maximum limit of {{ dependantCount }}{' '}
              dependents.
            </p>
          </Trans>
        </Callout>
      </div>
    ) : null;

  if (maxDependantCount > 0) {
    return (
      <FormCard noPadding={edit}>
        {fields.map((item, index) => (
          <div key={item.id}>
            {index === 0 && !edit && <DependantHeader />}
            {index === 0 && edit && (
              <DependantContainer data-testid="dependant-header">
                <DependantTitle>
                  {t(
                    'onboarding-phase.insurance.dependant-details.review-edit-title-mandatory-insurance',
                    'Edit Dependent Details',
                  )}
                </DependantTitle>
                <DependantDescription>
                  {t(
                    'onboarding-phase.insurance.dependant-details.review-edit-sub-title',
                    'These details about your dependents (spouse / children / parents) will be verified post your joining date.',
                  )}
                </DependantDescription>
              </DependantContainer>
            )}
            <Form index={index} isUSA={isUSA} remove={(id) => remove(id)} />
            <CardSeparator tw="my-large" />
          </div>
        ))}
        {!isEmpty(errors) && fields.length !== 0 && (
          <div tw="mb-large">
            <Callout variant={CalloutVariant.WARNING}>
              <p
                css={[
                  tw`text-ps text-text-primary font-normal`,
                  !isNewThemeApplied && tw`text-primaryBlue800`,
                ]}
              >
                {t(
                  'definition-phase.basic-details.validate',
                  'Please fill all the required fields',
                )}
              </p>
            </Callout>
          </div>
        )}
        {addDependentsContent}
      </FormCard>
    );
  }

  return (
    <>
      {fields.map((item, index) => (
        <FormCard noPadding={edit} key={item.id}>
          {index === 0 && !edit && <DependantHeader />}
          {index === 0 && edit && (
            <DependantHeader.DependantContainer data-testid="dependant-header">
              <DependantHeader.DependantTitle>
                {t(
                  'onboarding-phase.insurance.dependant-details.review-edit-title',
                  'Edit Dependent Details',
                )}
              </DependantHeader.DependantTitle>
              <DependantHeader.DependantDescription>
                {t(
                  'onboarding-phase.insurance.dependant-details.review-edit-sub-title',
                  'These details about your dependents (spouse / children / parents) will be verified post your joining date.',
                )}
              </DependantHeader.DependantDescription>
            </DependantHeader.DependantContainer>
          )}
          <Form index={index} isUSA={isUSA} remove={(id) => remove(id)} />
        </FormCard>
      ))}
      {!isEmpty(errors) && fields.length !== 0 && (
        <div tw="mb-large">
          <Callout variant={CalloutVariant.WARNING}>
            <p tw="text-ps text-text-primary font-normal">
              {t(
                'definition-phase.basic-details.validate',
                'Please fill all the required fields',
              )}
            </p>
          </Callout>
        </div>
      )}
      {addDependentsContent}
    </>
  );
};

export default DependantForm;
