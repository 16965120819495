/** @jsxImportSource @emotion/react */
import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Loader } from '@multiplier/common';
import tw from 'twin.macro';

import {
  FormLayout as FormWrapper,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import DependantForm from 'contract-onboarding/member/components/insurance-section/components/dependant-form';
import InsurancePlanDetails from 'contract-onboarding/member/components/insurance-section/components/plan-details';
import useGetInsuranceDetails from 'contract-onboarding/member/hooks/get-insurance-details';
import useUpdateInsuranceDetails from 'contract-onboarding/member/hooks/update-dependant-details';
import insuranceSchema from 'contract-onboarding/member/services/insurance-details-schema';

import { CountryCode, Gender, Relationship } from '__generated__/graphql';

import stepConfig from '../../step-config';

const FormLayout = tw.form`flex flex-col mb-large`;
const FormCard = tw(Card)`flex flex-col mb-large p-large gap-y-large`;

export interface InsuranceFormValues {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: Date | null;
  gender?: Gender | null;
  relationship?: Relationship | null;
  nationalIdNumber?: string;
  identity?: File;
}

export interface InsuranceDetailsPrams {
  insuranceDetails: InsuranceFormValues[];
}

const InsuranceDetails: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    contractBenefit,
    contractId,
    country,
    loading: benefitLoading,
  } = useGetInsuranceDetails();

  const {
    onSubmit: handleUpdateDependant,
    loading: dependantUpdateLoading,
  } = useUpdateInsuranceDetails();

  const isUSA = country === CountryCode.USA;

  const schema = insuranceSchema(isUSA, t);

  const methods = useForm<InsuranceDetailsPrams>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const loading =
    benefitLoading || dependantUpdateLoading || methods.formState.isSubmitting;

  if (loading || !contractBenefit) {
    return (
      <>
        <Loader.Widget tw="min-h-[140px] mb-large" />
        <Loader.Form />
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <FormLayout
        onSubmit={methods.handleSubmit((formData) =>
          handleUpdateDependant(formData, contractBenefit?.id),
        )}
      >
        <FormCard>
          <InsurancePlanDetails
            providerName={contractBenefit?.benefit?.provider?.partnerName}
            country={country}
            contractBenefit={contractBenefit}
            contractId={contractId}
          />
        </FormCard>
        <DependantForm
          dependantCount={contractBenefit?.dependentCount ?? 0}
          country={country}
        />
        <StepNavigationFooter
          disabled={!methods.formState.isValid || loading}
          contractId={contractId}
          stepConfig={stepConfig}
          currentStep={currentStep}
          onboardingSteps={onboardingSteps}
          submitLoading={loading}
        />
      </FormLayout>
    </FormProvider>
  );
};

export const InsuranceDetailsWrapper: React.FC = () => (
  <StepLayout>
    <FormWrapper>
      <InsuranceDetails />
    </FormWrapper>
  </StepLayout>
);

export default InsuranceDetails;
