import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useClickAway, useWindowSize } from 'react-use';

import {
  endOfDay,
  format,
  isValid,
  parse,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import tw, { TwStyle, theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';
import { HighlightType } from '../types';
import CalendarWidget, { CalendarWidgetPortal } from './components/calendar';
import { isValidDate } from './utils/calendar-utils';

export interface DatePickerProps {
  id?: string;
  divStyles?: TwStyle;
  value?: Date | string | null;
  max?: Date | string;
  min?: Date | string;
  disabled?: boolean;
  disableDate?: (d: Date) => boolean;
  notes?: string | React.ReactNode;
  loading?: boolean;
  highlights?: HighlightType[];
  onChange?: (d: string) => void;
  error?: boolean;
  helperText?: string;
  onError?: (isError: boolean, errorText: string) => void;
  monthSelector?: boolean;
  placeholder?: string;
  rightAlign?: boolean;
  disabledInput?: boolean;
  isPortal?: boolean;
}

const parseDateFromString = (value: string, monthSelector = false) =>
  parse(
    value.substring(0, 10),
    monthSelector ? 'yyyy-MM' : 'yyyy-MM-dd',
    new Date(),
  );

const DatePicker: React.FC<DatePickerProps> = ({
  id,
  divStyles,
  value,
  onChange,
  max,
  min,
  loading,
  highlights,
  notes,
  disableDate,
  error,
  helperText,
  disabled,
  onError,
  monthSelector = false,
  placeholder,
  rightAlign,
  disabledInput,
  isPortal,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLInputElement>(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [boxFocused, setBoxFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [minDate, setMinDate] = useState(startOfDay(new Date(1900, 0, 1)));
  const [maxDate, setMaxDate] = useState(endOfDay(new Date(2100, 0, 1)));
  const [isInvalidDate, setInvalidDate] = useState({
    isInvalid: false,
    reason: '',
  });
  const [above, setAbove] = useState(false);
  const { height } = useWindowSize();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (max && isValidDate(max) && typeof max === 'string') {
      setMaxDate(endOfDay(parseDateFromString(max)));
    } else {
      setMaxDate(endOfDay(max as Date));
    }
  }, [max]);

  useEffect(() => {
    if (min && isValidDate(min) && typeof min === 'string') {
      setMinDate(parseDateFromString(min));
    } else {
      setMinDate(new Date(min as Date));
    }
  }, [min]);

  useEffect(() => {
    if (onError) {
      if (isInvalidDate?.isInvalid) {
        onError(true, isInvalidDate.reason);
      } else {
        onError(false, '');
      }
    }
  }, [isInvalidDate]);

  useClickAway(datePickerRef, () => {
    if (!isPortal) setOpenCalendar(false);
  });

  const currentDateObject = useMemo(() => {
    // Change getDateObjFromValue() to currentDateObject to generate value instantly
    if (value && isValidDate(value)) {
      if (typeof value === 'string') {
        return startOfDay(parseDateFromString(value, monthSelector));
      }

      return startOfDay(value);
    }
    return null;
  }, [value, monthSelector]);

  const getDateMonthYearFromDDMMYYYY = (s: string) => {
    const match = /(\d{1,2})-?(\d{0,2})?-?(\d{0,4})?/.exec(s);
    if (match) return [match[1], match[2], match[3]];
    return ['', '', ''];
  };

  const returnSelectedDate = (date: Date | null) => {
    if (onChange) {
      if (date) {
        if (monthSelector) {
          onChange(format(startOfMonth(date), 'yyyy-MM-dd'));
        }
        onChange(format(date, 'yyyy-MM-dd'));
      } else onChange('');
    }
    setInvalidDate({ isInvalid: false, reason: '' });
    // Remove Close Calendar: Every time user select or input new date, we will show the result in calendar instantly
  };

  const onInputFocus = useCallback(() => {
    setBoxFocused(true);
    if (currentDateObject && isValid(currentDateObject))
      setInputValue(format(currentDateObject, 'dd-MM-yyyy'));
  }, [height, currentDateObject]);

  const onInputBlur = () => {
    setBoxFocused(false);
    setInvalidDate({ isInvalid: false, reason: '' });
    setInputValue('');
  };

  const onInputClick = useCallback(
    (e: React.MouseEvent) => {
      // Always open the calendar when user input date
      // Reason: Help user compare inputted string and Date
      setAbove(e?.clientY < height / 2);
      setOpenCalendar(true);
    },
    [height],
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setOpenCalendar((p) => !p);
    }
  };

  const getDisplayValue = () => {
    if (boxFocused) return inputValue;
    if (value && isValidDate(value)) {
      if (typeof value === 'string') {
        return format(
          parseDateFromString(value, monthSelector),
          monthSelector ? 'MMMM yyyy' : 'dd-MMM-yyyy',
        );
      }

      return format(
        new Date(value),
        monthSelector ? 'MMMM yyyy' : 'dd-MMM-yyyy',
      );
    }

    return '';
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    if (/[^0-9-]/.test(v)) {
      setInvalidDate({ isInvalid: true, reason: 'Invalid input' });
      return;
    }
    if (/--/.test(v)) {
      setInvalidDate({ isInvalid: true, reason: 'Invalid input' });
      setInputValue(v);
      return;
    }
    if (v === '') {
      setInputValue('');
      setInvalidDate({ isInvalid: false, reason: '' });
      returnSelectedDate(null);
    }

    if (v) {
      if (v.length > 9) {
        const [date, month, year] = getDateMonthYearFromDDMMYYYY(v);
        const dateToCheck = parseDateFromString(`${year}-${month}-${date}`);
        if (isValidDate(dateToCheck)) {
          setInputValue(`${date}-${month}-${year}`);
          returnSelectedDate(dateToCheck);
        } else {
          setInvalidDate({ isInvalid: true, reason: 'Invalid Date' });
        }
      } else {
        if (/^\d{3}$/.test(v)) {
          setInputValue(v.replace(/(\d{2})(\d)/, '$1-$2'));
          return;
        }
        if (/^\d{2}-\d{3}$/.test(v)) {
          setInputValue(v.replace(/(\d{2})-(\d{2})(\d)/, '$1-$2-$3'));
          return;
        }
        setInputValue(v);
      }
    }
  };

  return (
    <div ref={datePickerRef} {...props}>
      <div
        css={[
          tw`relative flex text-text-primary`,
          !isNewThemeApplied && tw`text-foreground`,
          divStyles,
        ]}
      >
        <input
          id={id}
          data-testid="date-input-field"
          placeholder={
            placeholder ??
            (disabledInput
              ? t('date-picker.select-date', 'Select Date')
              : 'DD MM YYYY')
          }
          ref={textRef}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onKeyDown={onKeyDown}
          value={getDisplayValue()}
          onChange={onTextChange}
          onClick={onInputClick}
          disabled={disabled || disabledInput}
          autoComplete="off"
          css={[
            tw`p-small border border-border-primary rounded-tiny text-ps grow w-full placeholder:text-text-tertiary`,
            !isNewThemeApplied &&
              tw`border-grey03 placeholder:text-grey02 rounded-6`,
            tw`focus:(border-border-high-contrast outline-none ring-0)`,
            !isNewThemeApplied &&
              tw`focus:(ring-1 ring-primaryBlue500 border-primaryBlue500 outline-none)`,
            (error || isInvalidDate.isInvalid) &&
              tw`text-text-negative border-border-negative`,
            !isNewThemeApplied &&
              (error || isInvalidDate.isInvalid) &&
              tw`bg-bgError shadow-error`,
            disabled && tw`bg-background-secondary text-text-tertiary border`,
            !isNewThemeApplied && disabled && tw`opacity-50`,
          ]}
        />
        <button
          data-testid="calendar-widget-button"
          css={[
            tw`absolute right-12 top-1/2 -translate-y-1/2 focus:ring-transparent focus:outline-none disabled:opacity-50`,
            disabledInput && tw`pl-[calc(100% - 40px)]`,
          ]}
          type="button"
          disabled={disabled}
          onClick={(e) => {
            setAbove(e.clientY < height / 2);
            setOpenCalendar((p) => !p);
          }}
          tabIndex={-1}
        >
          <Icon
            fill={!isNewThemeApplied ? theme`colors.foreground` : undefined}
            name="calendar"
          />
        </button>
        {openCalendar ? (
          <>
            {isPortal ? (
              <CalendarWidgetPortal
                selectedDate={currentDateObject}
                setSelectedDate={returnSelectedDate}
                min={minDate}
                max={maxDate}
                disableDate={disableDate}
                highlights={highlights}
                notes={notes}
                loading={loading}
                monthSelector={monthSelector}
                open={openCalendar}
                datePickerRef={datePickerRef.current}
                above={above}
              />
            ) : (
              <div
                data-testid="calendar-widget-wrapper"
                css={[
                  tw`absolute flex flex-grow rounded-tiny bg-background-white z-10`,
                  above
                    ? tw`top-[calc(100% + 6px)]`
                    : tw`top-0 transform[translateY(calc(-100% - 6px))]`,
                  rightAlign ? tw`right-0` : tw`left-0`,
                ]}
              >
                <CalendarWidget
                  selectedDate={currentDateObject}
                  setSelectedDate={returnSelectedDate}
                  min={minDate}
                  max={maxDate}
                  disableDate={disableDate}
                  highlights={highlights}
                  notes={notes}
                  loading={loading}
                  monthSelector={monthSelector}
                />
              </div>
            )}
          </>
        ) : null}
      </div>

      {helperText && !error ? (
        <div
          css={[
            tw`text-text-secondary text-ps pl-small`,
            !isNewThemeApplied && tw`text-grey01`,
          ]}
        >
          {helperText}
        </div>
      ) : (
        (isInvalidDate.isInvalid || error) && (
          <div tw="flex flex-row items-start mt-small">
            <div>
              <Icon
                name="error"
                tw="mr-extra-small"
                fill={theme`colors.icon-negative`}
              />
            </div>
            <span tw="text-text-negative text-ps">
              {isInvalidDate.isInvalid ? isInvalidDate.reason : helperText}
            </span>
          </div>
        )
      )}
    </div>
  );
};

export { getYearRange } from './utils/calendar-utils';

export default DatePicker;
