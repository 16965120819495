/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';

import AppFeature from 'app/features';
import Radio from 'common/components/radio';
import TextInput from 'common/components/text-input';

import { ContractType } from '__generated__/graphql';

import { BasicDetailsFormValues, EmploymentStatus } from '../basic-details';

const EmploymentStatusSelector: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  const { watch, register } = useFormContext<BasicDetailsFormValues>();

  const contractType = watch('contractType');

  const isSorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;
  const singleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;

  if (
    contractType !== ContractType.HR_MEMBER ||
    isSorOnboardingEnabled ||
    singleOnboardingEnabled
  )
    return null;

  return (
    <>
      <TextInput.Container>
        <TextInput.Label>
          {t(
            'definition-phase.basic-details.employment-status.label',
            'Employment Status',
          )}
        </TextInput.Label>
        <Radio
          value={EmploymentStatus.NEW_EMPLOYEE}
          {...register('employmentStatus')}
        >
          {t(
            'definition-phase.basic-details.employment-status.new-employee',
            'New Employee',
          )}
        </Radio>
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label tw="invisible">spacer</TextInput.Label>
        <Radio
          value={EmploymentStatus.EXISTING_EMPLOYEE}
          {...register('employmentStatus')}
        >
          {t(
            'definition-phase.basic-details.employment-status.existing-employee',
            'Existing Employee',
          )}
        </Radio>
      </TextInput.Container>
    </>
  );
};

export default EmploymentStatusSelector;
