import React from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton, ToolTip, VerticalCardSeparator } from '@multiplier/common';
import { theme } from 'twin.macro';

interface Props {
  zoomLevel: string;
  onZoomToFit: () => void;
  onLocateMe?: () => void;
  onReset: () => void;
  onZoomOut: () => void;
  onZoomIn: () => void;
}

const Divider: React.FC = () => (
  <VerticalCardSeparator tw="w-2 h-40 mx-8 bg-slateGrey50" />
);

const Toolbar: React.FC<Props> = ({
  zoomLevel,
  onLocateMe,
  onReset,
  onZoomToFit,
  onZoomOut,
  onZoomIn,
}) => {
  const { t } = useTranslation('hris.common');

  return (
    <div tw="fixed flex items-center justify-between bottom-16 left-[60%] translate-x-[-50%] w-[350px] bg-white p-8 rounded-8 [box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20)]">
      <ToolTip
        variant="top"
        content={t('org-chart.toolbar.zoom-out-action', 'Zoom out')}
      >
        <IconButton
          name="minus"
          size="medium"
          variant="filled"
          tw="bg-slateGrey50 border-none flex-1"
          iconProps={{
            height: 24,
            width: 24,
          }}
          onClick={onZoomOut}
        />
      </ToolTip>
      <p tw="text-ps text-slateGrey900 font-semibold flex-1 text-center">
        {zoomLevel}%
      </p>
      <ToolTip
        variant="top"
        content={t('org-chart.toolbar.zoom-in-action', 'Zoom in')}
      >
        <IconButton
          name="plus-v2"
          size="medium"
          variant="filled"
          tw="bg-slateGrey50 border-none flex-1"
          iconProps={{
            height: 24,
            width: 24,
          }}
          onClick={onZoomIn}
        />
      </ToolTip>
      <Divider />
      <ToolTip
        variant="top"
        content={t('org-chart.toolbar.zoom-action', 'Zoom to fit')}
      >
        <IconButton
          name="maximize"
          size="medium"
          variant="filled"
          tw="bg-slateGrey50 border-none flex-1"
          iconProps={{
            height: 24,
            width: 24,
          }}
          onClick={onZoomToFit}
        />
      </ToolTip>
      <Divider />
      <ToolTip
        variant="top"
        content={t('org-chart.toolbar.locate-me-action', 'Locate me')}
      >
        <IconButton
          name="map-pin"
          size="medium"
          variant="filled"
          tw="bg-slateGrey50 border-none flex-1"
          iconProps={{
            height: 24,
            width: 24,
          }}
          onClick={onLocateMe}
        />
      </ToolTip>
      <Divider />
      <ToolTip
        variant="top"
        content={t('org-chart.toolbar.reset-action', 'Reset to default')}
      >
        <IconButton
          name="rotate"
          size="medium"
          variant="filled"
          fill={theme`colors.transparent`}
          stroke={theme`colors.neutral800`}
          iconProps={{
            height: 24,
            width: 24,
          }}
          tw="bg-slateGrey50 border-none flex-1"
          onClick={onReset}
        />
      </ToolTip>
    </div>
  );
};

export default Toolbar;
