/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Icon } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import { theme } from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import CardSeparator from 'common/components/card-separator';
import RevokeContractAction from 'contract-onboarding/components/progress-widget-content/revoke-contract-action';

import {
  Contract,
  ContractAgreementType,
  ContractOnboarding,
  ContractOnboardingStatus,
  ContractOnboardingStep,
  ContractStatus,
  ContractType,
  CountryWorkStatus,
  Member,
} from '__generated__/graphql';

import ContractDepositInformation from '../../../components/contract-deposit-information';
import DeleteAction from '../../../components/progress-widget-content/delete-contract-action';
import ReviseContractAction from '../../../components/progress-widget-content/revise-contract-action';
import RequestMsaCard from './request-msa-card';

const PendingStartDateHeader: React.FC<{ startOn: Contract['startOn'] }> = ({
  startOn,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <div tw="flex flex-col gap-y-12 items-center text-ps">
      <span>
        {t('onboarding-phase.activation-card.pending-start-date.header', {
          defaultValue:
            'Contract would be active on Joining day: {{ joiningDate }}',
          replace: {
            joiningDate: dateAsString(startOn),
          },
        })}
      </span>
      <div tw="rounded-4 bg-black bg-opacity-20 p-6 font-normal text-pxs">
        <Trans
          t={t}
          i18nKey="onboarding-phase.activation-card.pending-start-date.subtitle"
        >
          <span tw="font-semibold">Member Profile</span> would become Active in
          Teams from their joining day onwards.
        </Trans>
      </div>
    </div>
  );
};

const PendingActivationHeader: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <div tw="flex flex-col gap-y-12 items-center text-ps">
      <span>
        {t(
          'onboarding-phase.activation-card.pending-activation.header',
          'Pending Activation',
        )}
      </span>
      <div tw="rounded-4 bg-black bg-opacity-20 p-6 font-normal text-pxs">
        <Trans
          t={t}
          i18nKey="onboarding-phase.activation-card.pending-activation.subtitle"
        >
          <span tw="font-semibold">Member Profile</span> would become Active in
          Teams from their joining day onwards.
        </Trans>
      </div>
    </div>
  );
};

const ActivationCard: React.FC<{
  status: Contract['status'];
  workStatus: Contract['workStatus'];
  onboardingStatus: ContractOnboarding['status'];
  onboardingStep: ContractOnboarding['current'];
  contractId: Contract['id'];
  contractType: Contract['type'];
  memberId: Member['id'];
  firstName: Member['firstName'];
  lastName: Member['lastName'];
  workflowUpdating: boolean;
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
  startOn: Contract['startOn'];
  depositPayables: Contract['depositPayable'];
  compliance: Contract['compliance'];
}> = ({
  status,
  workStatus,
  onboardingStatus,
  contractId,
  contractType,
  memberId,
  firstName,
  lastName,
  workflowUpdating,
  toggleWorkflowUpdating,
  startOn,
  depositPayables,
  compliance,
  onboardingStep,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  if (!onboardingStatus && !status) return null;

  const getActivationCardTitle = () =>
    status === ContractStatus.ACTIVE ? (
      <PendingStartDateHeader startOn={startOn} />
    ) : onboardingStatus ===
        ContractOnboardingStatus.MEMBER_VERIFICATION_COMPLETED ||
      onboardingStep ===
        ContractOnboardingStep.ONBOARDING_MULTIPLIER_SIGNING ? (
      <PendingActivationHeader />
    ) : (
      <>
        <Icon name="info" fill={theme`colors.white`} />
        <span>
          {t(
            'onboarding-phase.activation-card.header',
            'Employee cannot be activated',
          )}
        </span>
      </>
    );

  return (
    <div tw="rounded-8 overflow-hidden bg-white shadow-high">
      <ContractDepositInformation
        contractId={contractId}
        contractType={contractType}
        depositPayables={depositPayables}
        firstName={firstName}
        lastName={lastName}
      />
      <div tw="bg-primary text-white font-semibold text-h6 py-24 flex flex-row items-center gap-x-12 justify-center">
        {getActivationCardTitle()}
      </div>
      {onboardingStatus ===
        ContractOnboardingStatus.MSA_SIGNING_IN_PROGRESS && (
        <MsaSigningInProgressCard />
      )}
      {onboardingStatus === ContractOnboardingStatus.MSA_SIGNING_PENDING && (
        <RequestMsaCard />
      )}
      {onboardingStatus ===
        ContractOnboardingStatus.MEMBER_VERIFICATION_COMPLETED && (
        <PendingActivationCard
          contractType={contractType}
          firstName={firstName}
        />
      )}
      {status === ContractStatus.ACTIVE && <PendingStartDateCard />}
      {status === ContractStatus.ONBOARDING &&
        contractType !== ContractType.CONTRACTOR && (
          <>
            <CardSeparator />
            <div tw="flex flex-col gap-y-24 p-24">
              {compliance?.contractAgreementType ===
                ContractAgreementType.MULTIPLIER_TEMPLATE &&
                workStatus !== CountryWorkStatus.REQUIRES_VISA && (
                  <>
                    <ReviseContractAction
                      contractId={contractId}
                      contractType={contractType}
                      workflowUpdating={workflowUpdating}
                      toggleWorkflowUpdating={toggleWorkflowUpdating}
                      currentOnboardingStatus={onboardingStatus}
                      contractDocumentWorkflowStatus={null}
                      depositPayables={depositPayables}
                    />
                    <CardSeparator />
                    <RevokeContractAction
                      contractId={contractId}
                      workflowUpdating={workflowUpdating}
                      toggleWorkflowUpdating={toggleWorkflowUpdating}
                    />
                    <CardSeparator />
                  </>
                )}
              <DeleteAction
                contractId={contractId}
                memberId={memberId}
                memberName={firstName}
                workflowUpdating={workflowUpdating}
                toggleWorkflowUpdating={toggleWorkflowUpdating}
              />
            </div>
          </>
        )}
    </div>
  );
};

const PendingActivationCard: React.FC<{
  contractType: Contract['type'];
  firstName: Member['firstName'];
}> = ({ contractType, firstName }) => {
  const { t } = useTranslation('contract-onboarding.company');

  const isSingleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;

  return (
    <div tw="flex flex-col items-center gap-y-16 p-32 text-ps text-background">
      <div tw="flex flex-row items-center gap-x-6">
        <Trans
          t={t}
          i18nKey="onboarding-phase.activation-card.pending-activation.congratulations"
        >
          <div tw="rounded-4 bg-bg01 p-6">🎉 Congratulations!</div> The
          verification process is complete.
        </Trans>
      </div>
      <span tw="text-center whitespace-pre-wrap">
        {contractType === ContractType.HR_MEMBER &&
        isSingleOnboardingEnabled ? (
          t(
            'onboarding-phase.activation-card.pending-activation-global-payroll.description',
            {
              defaultValue: `The member has been added to Multiplier.
                {{ firstName }} will be activated on the platform shortly.
                If you continue seeing this message, contact `,
              replace: {
                firstName,
              },
            },
          )
        ) : (
          <Trans
            t={t}
            i18nKey="onboarding-phase.activation-card.pending-activation.description"
          >
            The member has completed the onboarding process with Multiplier.
            {'\n'}
            Your employee will be activated on the platform shortly.{'\n'}If you
            continue seeing this message, contact{' '}
          </Trans>
        )}{' '}
        <span tw="font-semibold text-primary">support@usemultiplier.com</span>.
      </span>
    </div>
  );
};

const PendingStartDateCard: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <div tw="flex flex-col items-center gap-y-16 p-32 text-ps text-background">
      <div tw="flex flex-row items-center gap-x-6">
        <Trans
          t={t}
          i18nKey="onboarding-phase.activation-card.pending-start-date.congratulations"
        >
          <div tw="rounded-4 bg-bg01 p-6">🎉 Congratulations!</div> The Member
          has been successfully activated.
        </Trans>
      </div>
      <span tw="text-center whitespace-pre-wrap">
        <Trans
          t={t}
          i18nKey="onboarding-phase.activation-card.pending-start-date.description"
        >
          The Member profile would start appearing in the Active Tab under Teams
          once the contract has been activated, i.e. on their joining day.{'\n'}
          For any clarifications, please reach out to us at{' '}
          <span tw="font-semibold text-primary">support@usemultiplier.com</span>
          .
        </Trans>
      </span>
    </div>
  );
};

const MsaSigningInProgressCard: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <div tw="flex flex-col items-center gap-y-24 p-32 text-ps text-background">
      <span tw="font-semibold">
        {t(
          'onboarding-phase.activation-card.signing-in-progress.header',
          'MSA signing in progress',
        )}
      </span>
      <span tw="text-grey01 text-center">
        <Trans
          t={t}
          i18nKey="onboarding-phase.activation-card.signing-in-progress.description"
        >
          We will activate the employee as soon as your Master Service Agreement
          is signed. If you have any questions or clarifications, please reach
          out to{' '}
          <span tw="font-semibold text-primary">support@usemultiplier.com</span>
          .
        </Trans>
      </span>
    </div>
  );
};

export default ActivationCard;
