/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { CalloutIcon, CalloutVariant, ThemeContext } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import tw, { theme } from 'twin.macro';

import { BenefitsDomain } from '__generated__/graphql';

const InsuranceTypePartnerName: React.FC<{
  insuranceType: BenefitsDomain | null | undefined;
  insurancePartnerName?: string | null | undefined;
}> = ({ insuranceType, insurancePartnerName }) => {
  const { t } = useTranslation('insurance');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const isInsuranceExpand = useFeature(AppFeature.INSURANCE_EXPAND)?.on;
  const BenefitsDomainMap = {
    [BenefitsDomain.HEALTH_INSURANCE]: t(
      'benefits-domain.health-insurance.label',
      'Health Insurance',
    ),
  };
  if (isInsuranceExpand) {
    return (
      <div tw="flex gap-extra-small items-center px-large py-small rounded-base">
        <CalloutIcon.Info
          iconProps={{
            fill: theme`colors.slateGrey700`,
            height: 16,
            width: 16,
          }}
          variant={CalloutVariant.WARNING}
          iconStyle={tw`h-base w-base max-h-base max-w-base`}
        />
        <div tw="text-text-secondary text-ps">
          <Trans
            t={t}
            i18nKey="insurance-partner-detail.partner-information-v2"
            defaults="{{benefitDomanin}} partner: <span1>{{insurancePartnerName}}</span1>"
            components={{ span1: <strong tw="text-black" /> }}
            values={{
              benefitDomanin: insuranceType && BenefitsDomainMap[insuranceType],
              insurancePartnerName,
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {insuranceType ? (
        <div>
          <div css={styles.labelStyle(isNewThemeApplied)}>
            {t('insurance-partner-detail.type.label', 'Insurance Type')}{' '}
          </div>
          <div css={styles.valueStyle(isNewThemeApplied)}>
            {BenefitsDomainMap[insuranceType]}
          </div>
        </div>
      ) : null}
      {insurancePartnerName ? (
        <div>
          <div css={styles.labelStyle(isNewThemeApplied)}>
            {t('insurance-partner-detail.name.label', 'Insurance Partner')}
          </div>
          <div css={styles.valueStyle(isNewThemeApplied)}>
            {insurancePartnerName}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InsuranceTypePartnerName;

const styles = {
  labelStyle: (isNewThemeApplied: boolean) => [
    tw`text-text-tertiary text-ps font-medium`,
    !isNewThemeApplied && tw`text-slateGrey500`,
  ],
  valueStyle: (isNewThemeApplied: boolean) => [
    tw`text-text-primary font-semibold`,
    !isNewThemeApplied && tw`text-slateGrey900`,
  ],
};
