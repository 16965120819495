import {
  ComplianceContractor,
  ComplianceFreelance,
  ComplianceMultiplierEor,
  CompliancePartnerEor,
  CompliancePeo,
  Contract,
  ContractOnboardingStatus,
  Maybe,
} from '../../../__generated__/graphql';
import { Experience } from '../../../app/models/module-config';
import {
  allowEditForCompanyWhenEmployeeContractIsPreparing,
  allowEditForCompanyWhenEmployeeContractIsPreparingConformation,
  allowEditForCompanyWhenEmployeeCreatedOrRevoked,
  allowEditForCompanyWhenMemberIsFreelancerAndHaveMultiplierLegalEntity,
  allowEditForHRMemberAndFreelancerWhenActive,
  allowEditForHRMemberAndFreelancerWhenCreateCustomStatusAndOtherStuff,
  allowEditForMemberDuringOnboarding,
  allowEditForMemberWhenEmployeeActive,
  notAllowEditForCompanyWhenEmployeeActive,
} from './strategies';

const sectionConfigList: SectionConfig[] = [
  allowEditForCompanyWhenEmployeeCreatedOrRevoked,
  allowEditForCompanyWhenEmployeeContractIsPreparing,
  allowEditForCompanyWhenEmployeeContractIsPreparingConformation,
  notAllowEditForCompanyWhenEmployeeActive,
  allowEditForMemberWhenEmployeeActive,
  allowEditForCompanyWhenMemberIsFreelancerAndHaveMultiplierLegalEntity,
  allowEditForMemberDuringOnboarding,
  allowEditForHRMemberAndFreelancerWhenActive,
  allowEditForHRMemberAndFreelancerWhenCreateCustomStatusAndOtherStuff,
];

const filterUndefined = <T>(array: (T | undefined)[]): T[] =>
  array.filter((element) => element !== undefined) as T[];

/**
 * Compare criteria with the value,
 *  - if the value is '*', automatically pass
 *  - if the value is an array, shallow compare with each of the criteria
 *  - if it is a function, run it
 * @param context all the input of other criteria
 * @param config the criteria should be in a config
 * @param criteriaKey name of the criteria
 */
const matchCriteria = <K extends Exclude<keyof SectionConfig, 'editable'>>(
  context: SectionConfigInput,
  config: SectionConfig,
  criteriaKey: K,
) => {
  const criteria = config[criteriaKey];
  if (criteria === '*') return true;
  if (
    Array.isArray(criteria) &&
    filterUndefined(criteria).includes(context[criteriaKey])
  )
    return true;
  if (typeof criteria === 'function')
    return criteria(context[criteriaKey] as never, context);
  return false;
};

export const allowSectionEdit = (
  status: Contract['status'],
  onboarding: Contract['onboarding'],
  type: Contract['type'],
  compliance: Compliance,
  experience: Experience,
): boolean => {
  const matchedConfigs = sectionConfigList.filter((config) => {
    const input: SectionConfigInput = {
      contractStatus: status,
      onboardingStatus: onboarding?.status,
      experiences: experience,
      compliance,
      type,
    };

    const matchContractStatus = matchCriteria(input, config, 'contractStatus');
    const matchOnboardingStatus = matchCriteria(
      input,
      config,
      'onboardingStatus',
    );
    const matchContractType = matchCriteria(input, config, 'type');
    const matchExperience = matchCriteria(input, config, 'experiences');
    const matchCompliance = matchCriteria(input, config, 'compliance');

    return [
      matchContractStatus,
      matchOnboardingStatus,
      matchContractType,
      matchExperience,
      matchCompliance,
    ].every((matchedCriteria) => matchedCriteria);
  });

  // If match any config which set editable to false, return false immediately
  if (matchedConfigs.some((config) => !config.editable)) {
    return false;
  }

  return matchedConfigs.some((config) => config.editable);
};

type AnyCriteria = '*';

export type Compliance =
  | ComplianceMultiplierEor
  | CompliancePartnerEor
  | CompliancePeo
  | ComplianceFreelance
  | ComplianceContractor
  | null
  | undefined;

export interface SectionConfig {
  editable: boolean;
  experiences:
    | AnyCriteria
    | Experience[]
    | ((experience: Experience, context: SectionConfigInput) => boolean);
  contractStatus:
    | AnyCriteria
    | Contract['status'][]
    | ((status: Contract['status'], context: SectionConfigInput) => boolean);
  onboardingStatus:
    | AnyCriteria
    | (Maybe<ContractOnboardingStatus> | undefined)[]
    | ((
        status: Maybe<ContractOnboardingStatus> | undefined,
        context: SectionConfigInput,
      ) => boolean);
  compliance:
    | AnyCriteria
    | Compliance[]
    | ((compliance: Compliance, context: SectionConfigInput) => boolean);
  type:
    | AnyCriteria
    | Contract['type'][]
    | ((type: Contract['type'], context: SectionConfigInput) => boolean);
}

export interface SectionConfigInput {
  experiences: Experience;
  contractStatus: Contract['status'];
  onboardingStatus: Maybe<ContractOnboardingStatus> | undefined;
  compliance: Compliance;
  type: Contract['type'];
}
