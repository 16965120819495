import { PendingOn } from 'contract-onboarding/components/pending-on-pill';
import OnboardingAction from 'contract-onboarding/models/onboarding-action';

import { ContractType } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isNewGlobalPayrollFlowEnabled,
} from '../status-config';

const createdCustom: ContractOnboardingStatusConfig = {
  title: (t) =>
    t('onboarding-phase.created-custom.title', 'Request details from employee'),
  description: (
    t,
    firstName,
    gender,
    country,
    contractType,
    workStatus,
    sorOnboardingContext,
  ) =>
    contractType === ContractType.FREELANCER
      ? t('onboarding-phase.created-custom-freelancer.description', {
          defaultValue:
            "Review {{ firstName }}'s contract details and confirm. Once confirmed, {{ firstName }} will be invited to Multiplier to provide additional details for payment setup. For clarifications reach out to us at support@usemultiplier.com",
          replace: {
            firstName,
          },
          context: gender,
        })
      : sorOnboardingContext?.isGlobalPayrollOnlyFlow
      ? t(
          'onboarding-phase.created-custom-sor-global-payroll-only.description',
          {
            defaultValue:
              'Once confirmed, {{ firstName }}’s details submitted will be verified by Multiplier.',
            replace: {
              firstName,
            },
            context: gender,
          },
        )
      : sorOnboardingContext?.isHrisOnlyFlow
      ? t('onboarding-phase.created-custom-sor-hris-only.description', {
          defaultValue:
            'You can confirm the details and add the employee to your team. After confirming, you can invite {{ firstName }} to Multiplier.',
          replace: {
            firstName,
          },
          context: gender,
        })
      : sorOnboardingContext?.isGlobalPayrollAndHrisFlow
      ? t(
          'onboarding-phase.created-custom-sor-global-payroll-hris.description',
          {
            defaultValue:
              'Confirm the following details and next you can invite {{ firstName }} to Multiplier to provide additional details that’s required for payroll setup.',
            replace: {
              firstName,
            },
            context: gender,
          },
        )
      : contractType === ContractType.HR_MEMBER &&
        isNewGlobalPayrollFlowEnabled()
      ? t('onboarding-phase.created-custom-global-payroll.description', {
          defaultValue:
            'Request basic, bank, legal details from {{ firstName }} to setup global payroll.',
          replace: {
            firstName,
          },
          context: gender,
        })
      : t('onboarding-phase.created-custom.description', {
          defaultValue:
            'Request basic, bank, legal details from {{ firstName }} to setup payroll.',
          replace: {
            firstName,
          },
          context: gender,
        }),
  statusLabel: (t) =>
    t('onboarding-phase.created-custom.label', 'Invite Employee'),
  pendingOn: () => PendingOn.YOU,
  pendingLabel: (t): string =>
    t('onboarding-phase.created-custom.status', 'Pending on You'),
  primaryAction: OnboardingAction.INVITE,
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default createdCustom;
