/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { ComboBox, Icon, ThemeContext } from '@multiplier/common';
import { numberAsCurrency } from '@multiplier/format';
import tw, { styled, theme } from 'twin.macro';

import Checkbox, { CheckboxContainer } from 'common/components/checkbox';
import { rateFrequencyLabelFreelancer } from 'common/constants/default-labels';
import { BenefitFormValues } from 'contract-onboarding/company/pages/definition-phase/pages/benefits/benefit-view-form';
import { benefitPartnerBillingFrequencyLabel } from 'insurance/utils/utils';

import {
  Benefit,
  BenefitCostingType,
  BenefitPartnerBillingFrequency,
  CurrencyCode,
  Maybe,
  RateFrequency,
} from '__generated__/graphql';

const dropDownOptions = Array.from({ length: 10 }, (_, i) => ({
  title: `${i + 1}`,
  value: `${i + 1}`,
}));

const DependantLabel = tw.div`pb-8 text-ps font-medium`;

const DependantPicker = styled.div(({ edit = false }: { edit: boolean }) => [
  tw`flex items-center rounded-8 text-p text-slateGrey900 p-8 border-[1px] border-grey04`,
  edit && tw`border-none`,
]);

const BoldText = tw.span`font-semibold`;

const DependentCountSelect: React.FC<{
  edit: boolean;
}> = ({ edit }) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { control, watch } = useFormContext<BenefitFormValues>();
  const { isNewThemeApplied } = useContext(ThemeContext);
  const dependentCountInput = watch('dependentCount');

  return (
    <div>
      <DependantLabel>
        {edit
          ? t(
              'benefits.dependents.choose-dependents.title',
              'Choose no. of Insured persons',
            )
          : t(
              'benefits.dependents.insured-dependents.title',
              'No. of insured persons',
            )}
      </DependantLabel>
      <DependantPicker edit={edit}>
        <div tw="bg-background-brand-faded w-40 h-40 flex items-center justify-center rounded-tiny mr-base">
          <Icon
            name="dependent"
            fill={isNewThemeApplied ? undefined : theme`colors.grey05`}
            stroke={
              isNewThemeApplied ? undefined : theme`colors.icon-secondary`
            }
          />
        </div>
        <Trans t={t} i18nKey="benefits.dependents.select.text.prefix">
          Member
          <span tw="ml-8">+</span>
        </Trans>
        {edit ? (
          <Controller
            name="dependentCount"
            control={control}
            defaultValue={dropDownOptions?.[0]?.value}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="insurance-dependant-dropdown"
                tw="w-[100px] mx-8"
                buttonStyles={tw`border border-slateGrey200 rounded-4`}
                labelStyles={tw`text-slateGrey900 font-bold`}
                variant="default"
                value={value || ''}
                placeholder=""
                onChange={onChange}
                dropdownValues={dropDownOptions}
              />
            )}
          />
        ) : (
          <BoldText tw="mx-8">{dependentCountInput}</BoldText>
        )}
        <Trans
          t={t}
          i18nKey="benefits.dependents.select.text.postfix-updated"
          values={{
            dependentCount: dependentCountInput
              ? Number(dependentCountInput) + 1
              : '',
          }}
        >
          Dependents = <BoldText tw="mx-4">{{ dependentCountInput }}</BoldText>{' '}
          Total insured Person(s)
        </Trans>
      </DependantPicker>
    </div>
  );
};

const IncludeDependantsBenefit: React.FC<{
  edit?: boolean;
  amount?: Maybe<number>;
  currency?: Maybe<CurrencyCode>;
  costingType?: Maybe<BenefitCostingType>;
  frequency?: Benefit['frequency'];
  billingDuration?: BenefitPartnerBillingFrequency | null | undefined;
}> = ({
  edit = false,
  amount,
  currency,
  costingType,
  frequency,
  billingDuration,
}) => {
  const { t, i18n } = useTranslation('contract-onboarding.company');
  const isBenefitDependentEnabled = useFeature(
    'contract.onboarding.benefit-dependent-detail',
  )?.on;

  const { control, watch } = useFormContext();
  const checked = watch('includeDependents');
  const selectedBenefit = watch('benefitId');

  const formattedFrequency = (
    rateFrequencyLabelFreelancer[frequency as RateFrequency] ?? ''
  ).toLowerCase();

  const formattedBillingFrequency = benefitPartnerBillingFrequencyLabel[
    billingDuration ?? ''
  ]?.(t);

  const formattedAmount = numberAsCurrency(i18n.language, amount, 2);

  const dependentCostText = useMemo(
    () => (
      <div tw="text-ps text-slateGrey900" data-testid="dependent-message">
        <Trans t={t} i18nKey="benefits.dependents.dependent-cost-text-v3">
          Members can add their dependents (spouse/children/parents) to the
          insurance plan starting from{' '}
          <span tw="font-semibold">
            {{ currency }} {{ formattedAmount }}/ {{ formattedFrequency }} /
            member
          </span>
          &nbsp;for each dependant billed {{ formattedBillingFrequency }}.
        </Trans>
      </div>
    ),
    [
      costingType,
      amount,
      currency,
      frequency,
      billingDuration,
      formattedBillingFrequency,
    ],
  );

  const shouldShowDependentCountSelect =
    checked && selectedBenefit && isBenefitDependentEnabled;

  const dependentSectionLabel = t(
    'benefits.dependents.label.edit',
    'Include Dependants',
  );

  return (
    <div tw="border bg-background-primary border-border-secondary rounded-base p-base">
      <CheckboxContainer
        data-testid="include-dependants-benefit"
        tw="items-start"
      >
        <Controller
          name="includeDependents"
          control={control}
          defaultValue={checked}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              tw="h-20 w-20 disabled:checked:bg-primary disabled:cursor-not-allowed"
              aria-label="dependents"
              checkboxSize="small"
              disabled={!edit}
              checked={value}
              onChange={onChange}
            />
          )}
        />

        <div tw="flex flex-col">
          <span
            css={[
              tw`mb-[3px] font-semibold text-text-action text-p`,
              !edit && tw`text-text-tertiary`,
            ]}
          >
            {dependentSectionLabel}
          </span>
          {amount ? <>{dependentCostText}</> : null}
        </div>
      </CheckboxContainer>
      {shouldShowDependentCountSelect && (
        <>
          <div tw="h-[1px] w-full bg-slateGrey200 rounded-[2px] my-16" />
          <DependentCountSelect edit={edit} />
        </>
      )}
    </div>
  );
};

export default IncludeDependantsBenefit;
