import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import ComboBox from '../../../../../combo-box';
import { TableFilterConfigValue } from '../../../../types';
import Label from '../label';

const DropdownFilterInput: React.FC<{
  value: string;
  onChange: (val: string) => void;
  filterConfig: TableFilterConfigValue;
}> = ({ value, onChange, filterConfig }) => {
  const { t } = useTranslation('common.table');

  return (
    <>
      <Label>
        {filterConfig?.prefix ?? t('filters.matching', 'Matching:')}
      </Label>
      <ComboBox
        tw="mt-extra-small"
        data-testid="select-matching"
        showArrow
        placeholder={filterConfig?.placeholder ?? ''}
        value={value as string}
        variant="autocomplete"
        dropdownValues={filterConfig?.options ?? []}
        onChange={onChange}
      />
    </>
  );
};

export default DropdownFilterInput;
