import { useEffect } from 'react';

import {
  Contract,
  GetCountryLabourStandardsQuery,
  useGetCountryLabourStandardsLazyQuery,
} from '../../../__generated__/graphql';

const useGetCountryLabourStandards = ({
  country,
  countryStateCode,
}: {
  country: Contract['country'];
  countryStateCode: Contract['countryStateCode'];
}): { data: GetCountryLabourStandardsQuery | undefined; loading: boolean } => {
  const [
    getCountryLabourStandards,
    { data, loading },
  ] = useGetCountryLabourStandardsLazyQuery();

  useEffect(() => {
    if (country) {
      getCountryLabourStandards({
        variables: { country, stateCode: countryStateCode },
      });
    }
  }, [country, countryStateCode]);

  return { data, loading };
};

export default useGetCountryLabourStandards;
