import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';
import { useClickAway } from 'react-use';

import tw from 'twin.macro';

import ComboBox from '../../../combo-box';
import { useSearchParams, useUpdateSearchParams } from '../../../hooks/url';
import IconButton from '../../../icon-button';
import TextInput from '../../../text-input';
import { prependTableIdForUrlParams } from '../../../utils/table-utils';

const Pagination: React.FC<{
  tableId?: string;
  tableInstance: TableInstance;
  totalText?: string;
  disableUrlBinding?: boolean;
  onPageChange?: ({
    pageSize,
    pageIndex,
  }: {
    pageSize: number;
    pageIndex: number;
  }) => void;
  additionalPageSizes?: number[];
}> = ({
  tableId,
  tableInstance,
  totalText,
  disableUrlBinding = false,
  onPageChange,
  additionalPageSizes = [],
}) => {
  const [selectedPage, setSelectedPage] = useState('1');
  const pageTextRef = useRef(null);
  const [currentPage] = useSearchParams(
    prependTableIdForUrlParams('page', tableId),
  );
  const { t } = useTranslation('common');
  const updateSearchParams = useUpdateSearchParams();

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: { pageSize, pageIndex },
  } = tableInstance;

  const paginationOptions = Array.from(
    new Set(
      [5, 10, 15, 20, 30]
        .concat(additionalPageSizes)
        .sort((a, b) => (a > b ? 1 : -1)),
    ),
  );

  useEffect(() => {
    if (!disableUrlBinding && currentPage && currentPage !== selectedPage) {
      handlePageChange(currentPage);
    }
  }, [disableUrlBinding, currentPage, selectedPage]);

  useEffect(() => {
    const newPage = Number(pageIndex) + 1;
    setSelectedPage(newPage.toString());
    if (onPageChange) {
      onPageChange({
        pageSize,
        pageIndex,
      });
    }

    if (!disableUrlBinding) {
      updateSearchParams([
        {
          key: prependTableIdForUrlParams('page', tableId),
          value: newPage.toString(),
        },
        {
          key: prependTableIdForUrlParams('rows', tableId),
          value: pageSize.toString(),
        },
      ]);
    }
  }, [disableUrlBinding, pageIndex, pageSize, tableId]);

  const handlePageChange = (newPage: string | number) => {
    if (newPage && !Number.isNaN(Number(newPage))) {
      gotoPage(Number(newPage) - 1);
    }
  };

  const handlePageSizeChange = (val: string) => {
    setPageSize(Number(val));
  };

  useClickAway(pageTextRef, () => {
    handlePageChange(selectedPage);
  });

  return (
    <div tw="flex items-center justify-end border-t border-border-primary py-extra-small">
      <div tw="w-1/3 justify-start text-ps ml-large">{totalText}</div>
      <div tw="w-1/3 flex justify-center items-center">
        <IconButton
          size="small"
          name="caret-left"
          data-testid="pagination-previous"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />
        <TextInput
          css={[tw`h-extra-large w-48 ml-6 mr-small text-center`]}
          value={selectedPage}
          onChange={(e) => {
            setSelectedPage(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handlePageChange(selectedPage);
            }
          }}
          ref={pageTextRef}
        />
        <span tw="mr-6" data-testid="pagination-length">
          / {pageOptions.length}
        </span>
        <IconButton
          size="small"
          name="caret-right"
          data-testid="pagination-next"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        />
      </div>
      <div tw="w-1/3">
        <div tw="flex justify-end items-center mr-base">
          <span tw="text-ps mr-small">{t('table.show', 'Show')}: </span>
          <ComboBox
            data-testid="dropdown-select"
            showArrow
            placeholder=""
            value={String(pageSize)}
            variant="default"
            dropdownValues={paginationOptions.map((x) => ({
              title: `${x} ${t('table.rows', 'rows')}`,
              value: String(x),
            }))}
            onChange={(val) => {
              handlePageSizeChange(val);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
