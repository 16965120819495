import { format, isValid } from 'date-fns';

import { GET_CONTRACT } from 'contract-onboarding/queries';

import {
  Contract,
  ContractTerm,
  useUpdateContractEmploymentMutation,
} from '__generated__/graphql';

import { EmploymentDetailsForm } from '../components/employment-details-section';

const useSubmitEmploymentDetails = (
  id?: string | null,
  onCompleted?: () => void,
): {
  onSubmit: (values: EmploymentDetailsForm) => Promise<Contract['onboarding']>;
  loading: boolean;
} => {
  const [updateEmployment, { loading }] = useUpdateContractEmploymentMutation({
    onCompleted: () => {
      if (onCompleted) {
        onCompleted();
      }
    },
  });

  const onSubmit = async (values: EmploymentDetailsForm) => {
    if (!id) return null;

    const contract = await updateEmployment({
      variables: {
        id,
        input: {
          country: values.country,
          position: values.position,
          term: values.term,
          startOn:
            isValid(values.startOn) &&
            `${format(values.startOn, 'yyyy-MM-dd')}T00:00:00`,
          endOn:
            values.term === ContractTerm.FIXED && isValid(values.endOn)
              ? `${format(values.endOn, 'yyyy-MM-dd')}T00:00:00`
              : undefined,
          scope: values.scope,
          workShift: values?.workShift,
          employeeId: values.employeeId,
          workEmail: values.workEmail,
        },
      },
      // Possibility of contract properties getting reset in the backend due to
      // such a fundamental change (specifically contract term)
      // better to refetch everything in the cache.
      refetchQueries: [{ query: GET_CONTRACT, variables: { id } }],
    });

    return contract.data?.contractUpdateEmployment?.onboarding;
  };
  return { onSubmit, loading };
};

export default useSubmitEmploymentDetails;
