import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';

import { Experience } from 'app/models/module-config';
import localStorageService from 'common/services/local-storage-service';
import { LOGOUT } from 'login/queries';
import { JwtToken } from 'login/types';

import history from '../../app/utils/history';
import client from '../../client';
import { shutdownIntercom } from './intercom';

const getExperiences = (experiences: string) => {
  if (experiences.includes('*')) {
    return ['company', 'member', 'operations', 'partner'];
  }
  return [...experiences.split(',')];
};

export const isJwtExpired = (token: string): boolean => {
  const { exp } = jwtDecode(token) as { exp: string };
  return Number.parseInt(exp, 10) - 10 <= Date.now() / 1000;
};

export const getDecodedToken = async (): Promise<
  | {
      auth: string[];
      experiences: Experience[];
      user: {
        langKey: string;
        imageUrl: string;
        firstName: string;
        id: number;
        lastName: string;
        email: string;
      };
    }
  | Record<string, never>
> => {
  const token = localStorageService.getRawItem('jwt_token') ?? '';

  if (token && token !== '') {
    try {
      const { alg } = jwtDecode(token, { header: true }) as { alg: string };
      const { experiences, auth, details, sub } = jwtDecode(token) as JwtToken;

      if (alg !== 'ES512') {
        await forceLogout();
        return {};
      }

      return {
        experiences: getExperiences(experiences) as Experience[],
        auth: [...auth.split(',')],
        user: { ...details, email: sub },
      };
    } catch (err) {
      await forceLogout();
      return {};
    }
  }

  return {};
};

export const logout = async (): Promise<void> => {
  try {
    await client.query({
      query: LOGOUT,
    });
  } catch (e) {
    Sentry.captureException(e);
  }

  invalidateToken();
  await client.clearStore();
  shutdownIntercom();
  history.push('/login');
};

export const forceLogout = async (
  shouldPerformLogoutQuery = true,
): Promise<void> => {
  if (shouldPerformLogoutQuery) {
    try {
      await client.query({
        query: LOGOUT,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  invalidateToken();
  client.stop();
  await client.clearStore();
  shutdownIntercom();
  if (!history.location.pathname.startsWith('/login')) {
    history.push('/login', {
      tokenInvalid: true,
      redirect: { ...history.location }.pathname,
    });
  }
};

export const invalidateToken = (): void => {
  if (localStorage.getItem('jwt_token')) {
    localStorage.removeItem('jwt_token');
  }
};

export const setSession = (token: string): void => {
  localStorage.setItem('jwt_token', token);
};

export const storeOtpToken = (token: string): void => {
  localStorage.setItem('otp_jwt_token', token);
};

export const getOtpToken = (): string =>
  localStorage.getItem('otp_jwt_token') ?? '';
export const clearOtpToken = (): void =>
  localStorage.removeItem('otp_jwt_token');
