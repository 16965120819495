import { Contract } from '__generated__/graphql';

// TODO: Only retrieving the first package in contract.benefits for now
export const getIncludeDependents = (benefits: Contract['benefits']): boolean =>
  benefits?.[0]?.params?.find((param) => param?.key === 'includeDependents')
    ?.value === 'true';

export const getBenefitId = (
  benefits: Contract['benefits'],
): string | undefined => benefits?.[0]?.benefit?.id;

export const getDependentCount = (
  benefits: Contract['benefits'],
): number | null | undefined => benefits?.[0]?.dependentCount ?? 0;

export const getEmployerPayPercentage = (
  benefits: Contract['benefits'],
): number | null | undefined =>
  benefits?.[0]?.insurancePremium?.employerPayPercentage;
