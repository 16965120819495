/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as InsuranceIconNewV2 } from '@multiplier/assets/files/contract-onboarding/insurance-icon-new-v2.svg';
import { Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

const InsuranceBannerContainer = tw.div`flex flex-col h-[100px] mb-large rounded-tiny text-center justify-center -mt-base -mx-base`;

interface InsuranceBannerProps {
  providerName: string | null | undefined;
}

const InsuranceBanner: React.FC<InsuranceBannerProps> = ({ providerName }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.common');
  return (
    <InsuranceBannerContainer
      css={[
        tw`relative overflow-hidden rounded-bl-none bg-background-inverted`,
        !isNewThemeApplied && tw`bg-primaryBlue600`,
      ]}
    >
      {!isNewThemeApplied ? (
        <Icon
          name="insurance-icon-new"
          fill={theme`colors.icon-inverted-primary`}
          tw="[height: 108px] [width:96] [position: absolute]"
        />
      ) : (
        <InsuranceIconNewV2
          fill={theme`colors.icon-inverted-primary`}
          tw="[height: 108px] [width:96] [position: absolute]"
        />
      )}
      <div>
        <div
          css={[
            tw`text-ps text-text-inverted-primary font-semibold`,
            !isNewThemeApplied && tw`text-primaryBlue200`,
          ]}
        >
          {providerName}
        </div>
        <div
          css={[
            tw`text-h5 text-text-inverted-primary font-semibold`,
            !isNewThemeApplied && tw`text-primaryBlue50`,
          ]}
        >
          {t(
            'onboarding-phase.insurance.insurance-banner.title',
            'Insurance for you & your family',
          )}
        </div>
      </div>
    </InsuranceBannerContainer>
  );
};

export default InsuranceBanner;
