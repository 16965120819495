import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { Table } from '@tanstack/react-table';
import tw from 'twin.macro';

import ComboBox from '../../../combo-box';
import IconButton from '../../../icon-button';
import TextInput from '../../../text-input';

type PaginationProps<TData> = {
  tableInstance: Table<TData>;
  totalText?: string;
  additionalPageSizes?: number[];
  fixedPageSize?: number;
};

const Pagination = <TData extends unknown>({
  tableInstance,
  totalText,
  additionalPageSizes = [],
  fixedPageSize,
}: PaginationProps<TData>): React.ReactElement => {
  const [page, setPage] = useState('');
  const pageTextRef = useRef(null);

  const { t } = useTranslation('common');

  const {
    getPageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setPageIndex,
    getState,
    getCanNextPage,
    getCanPreviousPage,
  } = tableInstance;

  const pageOptions = getPageOptions();
  const { pageSize, pageIndex } = getState().pagination;

  useEffect(() => {
    setPage((pageIndex + 1).toString());
  }, [pageIndex]);

  const canNextPage = getCanNextPage();
  const canPreviousPage = getCanPreviousPage();

  const paginationOptions = Array.from(
    new Set(
      [5, 10, 15, 20, 30]
        .concat(additionalPageSizes)
        .sort((a, b) => (a > b ? 1 : -1)),
    ),
  );

  const handlePageSizeChange = (val: string) => {
    setPageSize(Number(val));
  };

  const handlePageChange = (val: string) => {
    const newPageNumber =
      val && Number.isInteger(Number(val)) ? Number(val) - 1 : 0;
    setPageIndex(newPageNumber);
  };

  useEffect(() => {
    if (fixedPageSize) {
      setPageSize(fixedPageSize);
    }
  }, [fixedPageSize]);

  return (
    <div tw="flex items-center justify-end border-t-2 border-border-primary py-6">
      <div tw="w-1/3 justify-start text-ps ml-large">{totalText}</div>
      <div tw="w-1/3 flex justify-center items-center">
        <IconButton
          size="small"
          name="caret-left"
          data-testid="pagination-previous"
          onClick={previousPage}
          disabled={!canPreviousPage}
        />
        <TextInput
          type="tel"
          css={[tw`h-extra-large w-48 ml-6 mr-small text-center`]}
          value={page}
          onChange={(e) => {
            setPage(e?.target?.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handlePageChange(e?.currentTarget?.value);
            }
          }}
          ref={pageTextRef}
        />
        <span tw="mr-6" data-testid="pagination-length">
          / {pageOptions.length}
        </span>
        <IconButton
          size="small"
          name="caret-right"
          data-testid="pagination-next"
          onClick={nextPage}
          disabled={!canNextPage}
        />
      </div>
      {fixedPageSize ? (
        <div tw="w-1/3 invisible" />
      ) : (
        <div tw="w-1/3">
          <div tw="flex justify-end items-center mr-base">
            <span tw="text-ps mr-small">{t('table.show', 'Show')}: </span>
            <ComboBox
              data-testid="dropdown-select"
              showArrow
              placeholder=""
              value={String(pageSize)}
              variant="default"
              dropdownValues={paginationOptions.map((x) => ({
                title: `${x} ${t('table.rows', 'rows')}`,
                value: String(x),
              }))}
              onChange={(val) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
