/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFeature } from '@growthbook/growthbook-react';
import { Icon, ThemeContext } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import { useGetTimeOffDataLazy } from '@multiplier/time-off';
import tw from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import TableCell from 'common/components/table-cell';
import countryLabels from 'common/constants/country-labels';
import {
  useCompliancePageInsights,
  useComplianceParamsDefinition,
  useContract,
  useSubmitDefinitionCompliance,
} from 'contract-onboarding/company/hooks';
import ComplianceEntry from 'contract-onboarding/components/compliance-entry';
import { ComplianceFormValues } from 'contract-onboarding/components/contract-section';
import { FormCard, StepLayout } from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import { getComplianceParamDependencies } from 'contract-onboarding/services/compliance';

import {
  Compliance,
  ComplianceDefinitionScope,
  ComplianceInsightDefinition,
  ComplianceParamDefinition,
  ComplianceParamPeriodLimit,
  ContractType,
  CountryCode,
  InsightCategory,
  InsightDefinition,
  Maybe,
  TimeOffInsightDefinition,
} from '__generated__/graphql';

import Toggle from '../../../../../../common/components/toggle';
import LeaveEntitlement from '../../../../../components/leave-entitlement';
import stepConfig from '../../step-config';
import { ContractTemplateInputWithWrapper } from './components/contract-template-input';

export type CustomTimeOffInsightDefinition = TimeOffInsightDefinition & {
  timeOffRecommendedValue: TimeOffInsightDefinition['recommendedValue'];
};

export type CustomComplianceInsightDefinition = ComplianceInsightDefinition & {
  complianceRecommendedValue: ComplianceInsightDefinition['recommendedValue'];
};

export interface CompliancePageInsightViewModel {
  [InsightCategory.TIME_OFF]?: {
    [key: string]: CustomTimeOffInsightDefinition;
  };
  [InsightCategory.COMPLIANCE]?: {
    [key: string]: CustomComplianceInsightDefinition;
  };
  [InsightCategory.BENEFIT]?: {
    [key: string]: InsightDefinition;
  };
  [InsightCategory.COMPENSATION]?: {
    [key: string]: InsightDefinition;
  };
}

interface ToggleState {
  isToggled: boolean;
  lastState?: {
    complianceParams: (number | null)[];
    mandatory?: (number | null)[];
    custom?: (number | null)[];
  };
}

const InsightsHeader: React.FC<{
  insight: CompliancePageInsightViewModel;
  country?: Maybe<CountryCode>;
  compliance?: Maybe<Compliance>;
}> = ({ insight, country, compliance }) => {
  const { t } = useTranslation('contract-onboarding.company');
  const [toggleState, setToggleState] = useState<ToggleState>({
    isToggled: false,
  });
  const { isNewThemeApplied } = useContext(ThemeContext);

  const {
    getValues,
    setValue,
    trigger,
  } = useFormContext<ComplianceFormValues>();

  const getRecommendationState = () => {
    const insightComplianceKeys = Object.keys(
      insight[InsightCategory.COMPLIANCE] ?? {},
    ).filter((key) =>
      compliance?.complianceParams?.map((param) => param?.key).includes(key),
    );
    const insightTimeOffKeys = Object.keys(
      insight[InsightCategory.TIME_OFF] ?? {},
    ).filter((key) =>
      compliance?.timeOffEntitlement?.map((param) => param?.type).includes(key),
    );

    const insightCompliances = insightComplianceKeys
      .map((key) => insight[InsightCategory.COMPLIANCE]?.[key])
      .filter(notEmpty)
      .map((insightDefinition: CustomComplianceInsightDefinition) => ({
        key: insightDefinition.complianceParam?.key,
        value: insightDefinition?.complianceRecommendedValue,
        unit: insightDefinition.complianceParamPeriodUnit,
        enabled: insightDefinition?.complianceRecommendedValue !== -1,
      }));

    const insightMandatoryTimeOffs = insightTimeOffKeys
      .map((key) => insight[InsightCategory.TIME_OFF]?.[key])
      .filter(notEmpty)
      .map((insightDefinition: CustomTimeOffInsightDefinition) => ({
        key: insightDefinition.timeOffTypeDefinition?.type,
        value: insightDefinition?.timeOffRecommendedValue,
        unit: insightDefinition.timeOffUnit,
        enabled: insightDefinition?.timeOffRecommendedValue !== -1,
      }));

    return {
      complianceParams: insightCompliances,
      mandatory: insightMandatoryTimeOffs,
    };
  };

  const stateMatchRecommendationState = () => {
    const currentValues = getValues();
    const currentState = {
      complianceParams: currentValues.complianceParams.reduce((prev, curr) => {
        if (!curr.key) return prev;
        return {
          ...prev,
          [curr.key]: curr.value,
        };
      }, {} as Record<string, number | null>),
      mandatory: currentValues?.mandatory?.reduce((prev, curr) => {
        if (!curr.key) return prev;
        return {
          ...prev,
          [curr.key]: curr.value
            ? curr.value + curr.defaultValue
            : curr.defaultValue,
        };
      }, {} as Record<string, number | null>),
    };
    const recommendationState = getRecommendationState();

    const matchCompliances = recommendationState.complianceParams.every(
      (param) => currentState.complianceParams[param.key || ''] === param.value,
    );
    const matchTimeOffs = recommendationState.mandatory.every(
      (param) => currentState?.mandatory?.[param.key || ''] === param.value,
    );

    return matchCompliances && matchTimeOffs;
  };

  useEffect(() => {
    if (!stateMatchRecommendationState() && toggleState.isToggled) {
      setToggleState({
        isToggled: false,
        lastState: {
          complianceParams: getValues().complianceParams.map(
            (param) => param.value,
          ),
          mandatory: getValues()?.mandatory?.map((param) => param.value),
          custom: getValues()?.custom?.map((param) => param.value),
        },
      });
    } else if (stateMatchRecommendationState() && !toggleState.isToggled) {
      setToggleState({
        isToggled: true,
        lastState: {
          complianceParams: getValues().complianceParams.map(
            (param) => param.value,
          ),
          mandatory: getValues()?.mandatory?.map((param) => param.value),
          custom: getValues()?.custom?.map((param) => param.value),
        },
      });
    }
  }, [getValues()]);

  const updateRecommendations = () => {
    const timeOffsMap: Record<string, number> =
      compliance?.timeOffEntitlement?.reduce(
        (prev, cur) => ({ ...prev, [cur?.type || '_']: cur?.value || 0 }),
        {} as Record<string, number>,
      ) ?? {};

    const recommendations = getRecommendationState();

    recommendations.complianceParams.forEach((param) => {
      setValue(
        `complianceParams.${getValues().complianceParams.findIndex(
          (cParam) => cParam.key === param.key,
        )}.value`,
        param?.value || null,
      );
    });

    recommendations.mandatory.forEach((param) => {
      setValue(
        `mandatory.${
          getValues().mandatory?.findIndex(
            (timeOff) => timeOff.key === param.key,
          ) || 0
        }.value`,
        param?.value && param?.key && param.key in timeOffsMap
          ? param.value - timeOffsMap[param.key]
          : null,
      );
    });

    trigger();
  };

  const rollbackUserValues = () => {
    if (!toggleState.lastState) return;

    toggleState.lastState.complianceParams.forEach((value, index) => {
      setValue(`complianceParams.${index}.value`, value);
    });
    toggleState?.lastState?.mandatory?.forEach((value, index) => {
      setValue(`mandatory.${index}.value`, value);
    });
  };

  const toggleRecommendation = () => {
    if (toggleState.isToggled) {
      rollbackUserValues();
      setToggleState({
        isToggled: false,
      });
    } else {
      setToggleState({
        isToggled: true,
        lastState: {
          complianceParams: getValues().complianceParams.map(
            (param) => param.value,
          ),
          mandatory: getValues()?.mandatory?.map((param) => param.value),
          custom: getValues()?.custom?.map((param) => param.value),
        },
      });
      updateRecommendations();
    }
  };

  return (
    <FormCard
      css={[
        tw`flex flex-row bg-background-white gap-x-base`,
        !isNewThemeApplied && tw`bg-primary bg-opacity-5`,
      ]}
    >
      <div
        css={[
          tw`rounded-tiny h-40 w-40 bg-background-brand-subtle flex flex-row items-center`,
          !isNewThemeApplied && tw`bg-white`,
        ]}
      >
        {toggleState.isToggled ? (
          isNewThemeApplied ? (
            <Icon tw="m-auto h-large w-large" name="new_releases-fill" />
          ) : (
            <i tw="m-auto">🎉</i>
          )
        ) : (
          <Icon tw="m-auto h-large w-large" name="lightbulb" />
        )}
      </div>
      <div tw="flex flex-col text-ps text-text-primary w-[352px]">
        {toggleState.isToggled ? (
          <span tw="text-text-primary font-normal text-p">
            {t(
              'definition-phase.compliance.insights-header.header-applied',
              'Recommendations Applied',
            )}
          </span>
        ) : (
          <span tw="text-text-primary font-normal text-p">
            {t(
              'definition-phase.compliance.insights-header.header',
              'Apply Recommendations',
            )}
          </span>
        )}
        <span tw="text-ps text-text-secondary">
          {t('definition-phase.compliance.insights-header.description', {
            defaultValue:
              'We made some recommendations for you based on the most commonly used policies in {{-country}}',
            replace: { country: country && countryLabels[country] },
          })}
        </span>
      </div>
      <div tw="ml-auto">
        <Toggle
          id="insight"
          checked={toggleState.isToggled}
          onChange={toggleRecommendation}
        />
      </div>
    </FormCard>
  );
};

const ComplianceView: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const isCompanyMultipleContractTemplatesEnabled = useFeature(
    AppFeature.COMPANY_MULTIPLE_CONTRACT_TEMPLATES_ONBOARDING,
  )?.on;
  const { t } = useTranslation([
    'contract-onboarding.company',
    'contract-onboarding.common',
  ]);
  const { id } = useParams<{ id?: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    contract: {
      id: contractId,
      type,
      country,
      countryStateCode,
      compliance,
      term,
      startOn,
      endOn,
      status,
      company,
      compensation,
    },
  } = useContract(id);

  const companyId = company?.id ?? '';

  const [
    getTimeOffData,
    {
      mandatoryFields: mandatory,
      customFields: custom,
      assignedTimeOffEntitlements,
      timeOffTypeToDefinitionMap,
      clause,
      availableCustomEntitlementOptions,
      refetch: refetchLeaveEntitlements,
    },
  ] = useGetTimeOffDataLazy();

  useEffect(() => {
    if (id && companyId && country) {
      getTimeOffData({
        contractId: id,
        contractType: type,
      });
    }
  }, [companyId, id, country, type, term, status]);

  const {
    loading,
    groupedComplianceParamDefinitions: {
      complianceParamDefinitions,
      usedScope,
    },
  } = useComplianceParamsDefinition(
    type,
    country,
    countryStateCode,
    term,
    startOn,
    endOn,
  );

  const { insights } = useCompliancePageInsights(country, type, term);

  const shouldShowInsightsHeader = useMemo(
    () =>
      type !== ContractType.CONTRACTOR &&
      Object.values(insights).flatMap((category) => category).length > 0,
    [insights],
  );

  const methods = useForm<ComplianceFormValues>({
    mode: 'onChange',
  });

  const { fields: complianceFields } = useFieldArray<ComplianceFormValues>({
    control: methods.control,
    name: 'complianceParams',
  });

  const { onSubmit } = useSubmitDefinitionCompliance(contractId, () => {
    setIsSubmitting(false);
    if (
      ![ContractType.CONTRACTOR, ContractType.FREELANCER].includes(
        type as ContractType,
      )
    )
      refetchLeaveEntitlements?.();
  });

  // re-trigger form validation when complianceParamsDefinition promise is returned
  useEffect(() => {
    methods.trigger();
  }, [complianceParamDefinitions]);

  useEffect(() => {
    if (isSubmitting) return;

    const complianceParams =
      (compliance?.complianceParams &&
        compliance.complianceParams
          ?.filter(notEmpty)
          .filter((param) => param.key !== 'leavePolicy') // filter out leave policy temporarily
          .map((param: ComplianceParamPeriodLimit) => {
            const defaultValue = param.key
              ? complianceParamDefinitions[param.key]?.validation?.find(
                  (definition) => param?.unit === definition?.unit,
                )?.defaultValue
              : undefined;

            return {
              key: param.key,
              value: param.value === -1 ? defaultValue : param.value,
              unit: param.unit,
              enabled: param.value !== -1,
            };
          })) ??
      [];

    /**
     * TODO:
     * Strange bug that causes the param.0.value to become undefined when triggering handleSubmit.
     * This seems to have been resolved when upgrading to react-hook-form@7.17.4 but that comes
     * with its own set of problems. The compliance-entry component must be refactored to work with
     * that version.
     *
     * For now, we insert a dummy element into the list so that the real complianceParams are unaffected.
     * Similar logic on contract definition page.
     */
    complianceParams.unshift({
      key: null,
      unit: null,
      value: null,
      enabled: false,
    });

    methods.reset({
      complianceParams,
      mandatory,
      custom,
    });
  }, [compliance, complianceParamDefinitions, assignedTimeOffEntitlements]);

  return (
    <StepLayout data-testid="contract-view">
      <div tw="flex flex-col gap-y-large gap-y-base">
        {usedScope === ComplianceDefinitionScope.COUNTRY_SPECIFIC &&
          type !== ContractType.CONTRACTOR && (
            <FormCard tw="flex flex-row items-center gap-x-24">
              {country && <TableCell.Country country={country} />}
              <span>
                {t('definition-phase.compliance.description', {
                  defaultValue:
                    'The clauses below and values set on those are based on minimum requirements for {{country}}',
                  replace: {
                    country: country && countryLabels[country],
                  },
                  interpolation: { escapeValue: false },
                })}
              </span>
            </FormCard>
          )}
        <FormProvider {...methods}>
          {shouldShowInsightsHeader && (
            <InsightsHeader
              insight={insights}
              country={country}
              compliance={compliance}
            />
          )}
          <form
            onSubmit={methods.handleSubmit((values) => {
              // onSubmit will submit time off then compliance, but there is a delay
              // between switching loading state between the 2 requests. Which resulted
              // in a glitching behavior
              // We can:
              // 1. Update workflow in backend to accept asynchronous process.
              // 2. Set a dummy lock to lock this behavior during submission
              // the second approach is simpler, therefore preferable
              setIsSubmitting(true);
              onSubmit(values, type === ContractType.CONTRACTOR);
            })}
            tw="flex flex-col gap-y-base"
          >
            {isCompanyMultipleContractTemplatesEnabled && (
              <ContractTemplateInputWithWrapper
                companyId={companyId}
                contractType={type}
                contractTerm={term}
                contractCountry={country}
              />
            )}
            <LeaveEntitlement
              country={country}
              insights={insights[InsightCategory.TIME_OFF]}
              assignedTimeOffEntitlements={assignedTimeOffEntitlements}
              timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
              clause={clause ?? ''}
              availableCustomEntitlementOptions={
                availableCustomEntitlementOptions
              }
            />
            {complianceFields.map(
              (field, index) =>
                field?.key && (
                  <ComplianceEntry
                    id={field.key}
                    key={field.id}
                    field={field}
                    index={index}
                    complianceParamDefinition={
                      (field.key && complianceParamDefinitions[field.key]) as
                        | Maybe<ComplianceParamDefinition>
                        | undefined
                    }
                    dependencies={getComplianceParamDependencies(
                      field.key,
                      complianceParamDefinitions,
                      complianceFields.map((element, elementIndex) => ({
                        key: element.key,
                        index: elementIndex,
                      })),
                    )}
                    scope={usedScope}
                    country={country}
                    insight={insights[InsightCategory.COMPLIANCE]?.[field.key]}
                    compensation={compensation}
                    contractTerm={term}
                    contractType={type}
                  />
                ),
            )}
            <StepNavigationFooter
              tw="mt-extra-small"
              disabled={!methods.formState.isValid || loading}
              currentStep={currentStep}
              onboardingSteps={onboardingSteps}
              contractId={id}
              submitLoading={isSubmitting}
              stepConfig={stepConfig}
            />
          </form>
        </FormProvider>
      </div>
    </StepLayout>
  );
};

export default ComplianceView;
