import { Icon } from '@multiplier/common';
import { getDate, getMonth, getYear } from 'date-fns';
import { TFunction } from 'i18next';
import isNumber from 'lodash/isNumber';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import Lazy from 'yup/lib/Lazy';

import { notEmpty } from 'app/utils/array';
import { DropdownValue } from 'common/components/combo-box';
import currencyCountry from 'common/constants/currency-country';
import currencyLabels from 'common/constants/currency-labels';
import monthNames from 'common/constants/month-names';
import {
  AdditionalPayFormValues,
  Occurrence,
  PaymentType,
} from 'contract-onboarding/company/components/additional-pay-form';

import {
  CompensationPayComponent,
  ContractTerm,
  ContractType,
  Country13th14thMonthPaySchedule,
  Country13th14thMonthPayType,
  CountryCode,
  CountryCompliance,
  CountryPaymentFrequency,
  CountryRateFrequency,
  CountryWorkShiftStandards,
  CountryWorkStatus,
  CurrencyCode,
  FixedPayComponent,
  GetCountryAllowancesConfigQuery,
  Instalment,
  Maybe,
  PayAmountType,
  PayFrequency,
  RateFrequency,
  RateType,
  Scalars,
  ScheduleTime,
  ScheduleTimeUnit,
} from '__generated__/graphql';

import getMatchedHrAllowanceConfig from './get-matched-hr-allowance-config';

export const getCurrencyOptions = (
  compliantCurrencies: (CurrencyCode | null | undefined)[],
): DropdownValue[] =>
  compliantCurrencies.filter(notEmpty).map((currency) => ({
    icon: <Icon.Flag name={currencyCountry[currency]} />,
    title: currency,
    label: currencyLabels[currency],
    value: currency,
  })) ?? [];

// ref from: https://www.notion.so/usemultiplier/VISA-Countrywise-documents-to-collected-4959304319b64a7e8d4de83a605caa0e
const MIN_ANNUM_PAY_FOR_VISA: Partial<Record<CountryCode, number>> = {
  [CountryCode.ESP]: 45_000,
  [CountryCode.ITA]: 24_789,
};

export const getFixedPayValidation = ({
  contractType,
  contractWorkStatus,
  compliantCurrencies,
  contractTerm,
  country,
  isContractOnboardingHourlyPayEnabled,
  countryRateFrequencyList,
  countryPaymentFrequencyList,
}: {
  contractType?: ContractType | null;
  contractWorkStatus?: CountryWorkStatus | null;
  compliantCurrencies?: (Maybe<CurrencyCode> | undefined)[];
  contractTerm?: ContractTerm | null | undefined;
  country?: CountryCode | null;
  isContractOnboardingHourlyPayEnabled?: boolean;
  countryRateFrequencyList?: CountryRateFrequency['list'];
  countryPaymentFrequencyList?: CountryPaymentFrequency['list'];
}): SchemaOf<{
  name?: string;
  amount?: number | null;
  currency?: string;
  frequency?: string;
  rateType?: string;
  firstPayoutDate?: string;
}> =>
  yup.object().shape({
    name: yup.string().required(),
    amount: yup
      .number()
      .nullable()
      .transform((_, val) => (val === '' ? null : Number(val)))
      .when('name', {
        is: (name: string) => name === 'basePay',
        then: yup
          .number()
          .positive()
          .required()
          .test(
            'min-visa-pay',
            (value: number | null | undefined, context: yup.TestContext) => {
              if (
                value != null &&
                context.parent.frequency != null &&
                contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
                country != null &&
                MIN_ANNUM_PAY_FOR_VISA[country] != null
              ) {
                const minAnnualPay = MIN_ANNUM_PAY_FOR_VISA[country] as number;
                const minPay =
                  context.parent.frequency === RateFrequency.MONTHLY
                    ? Math.round(minAnnualPay / 12)
                    : minAnnualPay;

                if (value < minPay)
                  return context.createError({
                    message: `The required minimum amount is ${minPay}`,
                    path: context.path,
                  });
              }

              return true;
            },
          ),
      }),
    currency: yup
      .string()
      .test(
        'currency-test',
        (value?: string) =>
          (compliantCurrencies &&
            yup
              .string()
              .oneOf(compliantCurrencies)
              .required()
              .isValid(value)) ??
          false,
      ),
    frequency: yup.string().test('frequency-test', (value?: string) => {
      if (isContractOnboardingHourlyPayEnabled) {
        return yup
          .string()
          .required()
          .oneOf(countryRateFrequencyList ?? [])
          .isValid(value);
      }
      if (contractTerm && contractTerm === ContractTerm.FIXED) {
        return yup
          .string()
          .oneOf([
            RateFrequency.HOURLY,
            RateFrequency.DAILY,
            RateFrequency.WEEKLY,
            RateFrequency.SEMIMONTHLY,
            RateFrequency.MONTHLY,
          ])
          .required()
          .isValid(value);
      }

      return yup
        .string()
        .oneOf([RateFrequency.MONTHLY, RateFrequency.ANNUALLY])
        .required()
        .isValid(value);
    }),
    paymentFrequency: yup
      .string()
      .test('payment-frequency-test', (value?: string) => {
        if (isContractOnboardingHourlyPayEnabled) {
          return yup
            .string()
            .oneOf(countryPaymentFrequencyList ?? [])
            .required()
            .isValid(value);
        }
        if (
          contractTerm === ContractTerm.PERMANENT &&
          contractType !== ContractType.FREELANCER &&
          [CountryCode.PHL, CountryCode.USA, CountryCode.GBR].includes(
            country as CountryCode,
          )
        ) {
          return yup
            .string()
            .oneOf([
              PayFrequency.BIWEEKLY,
              PayFrequency.SEMIMONTHLY,
              PayFrequency.MONTHLY,
            ])
            .required()
            .isValid(value);
        }
        return yup.string().nullable().isValid(value);
      })
      .nullable(),
    rateType: yup.string().test('rate-type-test', (value?: string) => {
      if (contractTerm && contractTerm === ContractTerm.PERMANENT) {
        return yup
          .string()
          .oneOf(Object.values(RateType))
          .required()
          .isValid(value);
      }

      return yup
        .string()
        .oneOf([RateType.GROSS, RateType.CTC])
        .required()
        .isValid(value);
    }),
    firstPayoutDate: yup
      .string()
      .test('firstPayoutDate-test', (value?: string) =>
        contractType === ContractType.FREELANCER ||
        contractType === ContractType.CONTRACTOR
          ? yup.string().required().isValid(value)
          : true,
      ),
  });

export const getProbationPayValidation = ({
  contractType,
  compliantCurrencies,
  contractTerm,
  country,
  isContractOnboardingHourlyPayEnabled,
  countryRateFrequencyList,
  countryPaymentFrequencyList,
  isPostProbationToggleOn,
}: {
  contractType?: ContractType | null;
  compliantCurrencies?: (Maybe<CurrencyCode> | undefined)[];
  contractTerm?: ContractTerm | null | undefined;
  country?: CountryCode | null;
  isContractOnboardingHourlyPayEnabled?: boolean;
  countryRateFrequencyList?: CountryRateFrequency['list'];
  countryPaymentFrequencyList?: CountryPaymentFrequency['list'];
  isPostProbationToggleOn?: boolean;
}): SchemaOf<{
  name?: string;
  amount?: number | null;
  currency?: string;
  frequency?: string;
  rateType?: string;
  firstPayoutDate?: string;
}> =>
  yup.object().shape({
    name: yup.string().required(),
    amount: yup
      .number()
      .transform((_, val) => (val === '' ? null : Number(val)))
      .test('amount-test', (value?: number) => {
        if (isPostProbationToggleOn)
          return yup.number().positive().required().isValid(value);

        return yup.number().nullable().notRequired().isValid(value);
      })
      .nullable(),
    currency: yup.string().test('currency-test', (value?: string) => {
      if (contractType && contractType === ContractType.FREELANCER) {
        return yup
          .string()
          .oneOf(Object.values(CurrencyCode))
          .required()
          .isValid(value);
      }

      return (
        (compliantCurrencies &&
          yup.string().oneOf(compliantCurrencies).required().isValid(value)) ??
        false
      );
    }),
    frequency: yup.string().test('frequency-test', (value?: string) => {
      if (!isPostProbationToggleOn) {
        return yup.string().nullable().notRequired().isValid(value);
      }

      if (isContractOnboardingHourlyPayEnabled) {
        return yup
          .string()
          .required()
          .oneOf(countryRateFrequencyList ?? [])
          .isValid(value);
      }
      if (contractTerm && contractTerm === ContractTerm.FIXED) {
        return yup
          .string()
          .oneOf([
            RateFrequency.HOURLY,
            RateFrequency.DAILY,
            RateFrequency.WEEKLY,
            RateFrequency.SEMIMONTHLY,
            RateFrequency.MONTHLY,
          ])
          .required()
          .isValid(value);
      }

      return yup
        .string()
        .oneOf([RateFrequency.MONTHLY, RateFrequency.ANNUALLY])
        .required()
        .isValid(value);
    }),
    paymentFrequency: yup
      .string()
      .test('payment-frequency-test', (value?: string) => {
        if (!isPostProbationToggleOn) {
          return yup.string().nullable().notRequired().isValid(value);
        }

        if (isContractOnboardingHourlyPayEnabled) {
          return yup
            .string()
            .oneOf(countryPaymentFrequencyList ?? [])
            .required()
            .isValid(value);
        }
        if (
          contractTerm === ContractTerm.PERMANENT &&
          contractType !== ContractType.FREELANCER &&
          [CountryCode.PHL, CountryCode.USA, CountryCode.GBR].includes(
            country as CountryCode,
          )
        ) {
          return yup
            .string()
            .oneOf([
              PayFrequency.BIWEEKLY,
              PayFrequency.SEMIMONTHLY,
              PayFrequency.MONTHLY,
            ])
            .required()
            .isValid(value);
        }
        return yup.string().nullable().isValid(value);
      })
      .nullable(),
    rateType: yup.string().test('rate-type-test', (value?: string) => {
      if (contractTerm && contractTerm === ContractTerm.PERMANENT) {
        return yup
          .string()
          .oneOf(Object.values(RateType))
          .required()
          .isValid(value);
      }

      return yup
        .string()
        .oneOf([RateType.GROSS, RateType.CTC])
        .required()
        .isValid(value);
    }),
    firstPayoutDate: yup
      .string()
      .test('firstPayoutDate-test', (value?: string) =>
        contractType === ContractType.FREELANCER ||
        contractType === ContractType.CONTRACTOR
          ? yup.string().required().isValid(value)
          : true,
      ),
  });

export const isFixedPayComponent = (
  value: CompensationPayComponent,
): value is FixedPayComponent =>
  (value as FixedPayComponent).__typename === 'FixedPayComponent';

export const convertBasePayToMonthlyPay = (
  frequency: RateFrequency,
  amount: number,
  averageWorkingHoursPerMonth?: number,
): number => {
  switch (frequency) {
    case RateFrequency.HOURLY:
      if (averageWorkingHoursPerMonth)
        return Math.ceil(amount * averageWorkingHoursPerMonth);
      return Math.ceil(amount * 8 * 27);
    case RateFrequency.DAILY:
      return Math.ceil(amount * 27);
    case RateFrequency.WEEKLY:
      return Math.ceil(amount * 4.5);
    case RateFrequency.ANNUALLY:
      return Math.ceil(amount / 12);
    case RateFrequency.SEMIMONTHLY:
      return Math.ceil(amount * 2.25);
    default:
      return amount;
  }
};

export const getAdditionalPaySchemaForFreelancer = (
  t: TFunction,
  currencyOptions: Maybe<DropdownValue[]>,
  basePay?: number,
): Lazy<
  yup.ObjectSchema<{
    payType?: yup.StringSchema;
    name?: yup.StringSchema;
    occurrence?: yup.StringSchema;
    frequency?: yup.NumberSchema;
    payoutMonth?: yup.ObjectSchema<{
      month?: yup.NumberSchema;
      year?: yup.NumberSchema;
    }>;
    currency?: yup.StringSchema;
    amount?: yup.NumberSchema;
    conditions?: yup.StringSchema;
  }>
> =>
  yup.lazy((_value) => {
    if (_value.payType === PaymentType.JOINING_BONUS) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name: yup.string().required(),
        occurrence: yup.string().required().oneOf([Occurrence.ONCE]),
        frequency: yup.number().notRequired(),
        payoutMonth: yup.object().shape({
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup.number().moreThan(0).required(),
        conditions: yup.string().notRequired().nullable(),
      });
    }
    if (_value.payType === PaymentType.VARIABLE_PERFORMANCE_BONUS) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name: yup.string().required(),
        occurrence: yup.string().required().oneOf([Occurrence.RECURRING]),
        frequency: yup
          .number()
          .typeError(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          )
          .positive(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          )
          .integer(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          )
          .max(
            12,
            t('additional-pay.errors.frequency.max', 'Should be less than 12'),
          )
          .required(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          ),
        payoutMonth: yup.object().shape({
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        currency: yup.string().notRequired().nullable(),
        amount: yup.number().notRequired().nullable(),
        conditions: yup.string().notRequired().nullable(),
      });
    }
    if (_value.payType === PaymentType.ALLOWANCES) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name: yup.string().required(),
        occurrence: yup.string().required().oneOf([Occurrence.RECURRING]),
        frequency: yup
          .number()
          .typeError(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          )
          .positive(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          )
          .integer(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          )
          .max(
            12,
            t('additional-pay.errors.frequency.max', 'Should be less than 12'),
          )
          .required(
            t(
              'additional-pay.errors.frequency.required',
              'Frequency is a required field',
            ),
          ),
        payoutMonth: yup.object().shape({
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup.number().moreThan(0).required(),
        conditions: yup.string().notRequired().nullable(),
      });
    }
    if (_value.payType === PaymentType.OTHER) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name: yup.string().required(),
        occurrence: yup.string().required().oneOf(Object.values(Occurrence)),
        frequency:
          _value?.occurrence === Occurrence.ONCE
            ? yup.number().notRequired().nullable()
            : yup
                .number()
                .typeError(
                  t(
                    'additional-pay.errors.frequency.required',
                    'Frequency is a required field',
                  ),
                )
                .positive(
                  t(
                    'additional-pay.errors.frequency.required',
                    'Frequency is a required field',
                  ),
                )
                .integer(
                  t(
                    'additional-pay.errors.frequency.required',
                    'Frequency is a required field',
                  ),
                )
                .max(
                  12,
                  t(
                    'additional-pay.errors.frequency.max',
                    'Should be less than 12',
                  ),
                )
                .required(
                  t(
                    'additional-pay.errors.frequency.required',
                    'Frequency is a required field',
                  ),
                ),
        payoutMonth: yup.object().shape({
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        currency: yup.string().notRequired().nullable(),
        amount: yup.number().notRequired().nullable(),
        conditions: yup.string().notRequired().nullable(),
      });
    }
    if (_value.payType === PaymentType.THR_BONUS) {
      return yup.object().shape({
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup
          .number()
          .min(basePay ?? 0)
          .required(),
      });
    }
    if (_value.payType === PaymentType.THIRTEENTH_MONTH_BONUS) {
      return yup.object().shape({
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup.number().moreThan(0).required(),
      });
    }
    return yup.object().shape({
      payType: yup.string().required().oneOf(Object.values(PaymentType)),
    });
  });

export const getAdditionalPaySchema = (
  t: TFunction,
  currencyOptions: Maybe<DropdownValue[]>,
  basePay?: number,
  isNewAllowancesConfigEnabled?: boolean,
): Lazy<
  yup.ObjectSchema<{
    payType?: yup.StringSchema;
    name?: yup.StringSchema;
    occurrence?: yup.StringSchema;
    frequency?: yup.StringSchema;
    payoutMonth?: yup.ObjectSchema<{
      month?: yup.NumberSchema;
      year?: yup.NumberSchema;
    }>;
    currency?: yup.StringSchema;
    amount?: yup.NumberSchema;
    conditions?: yup.StringSchema;
    instalments?: yup.ArraySchema<
      yup.ObjectSchema<{
        currency?: yup.StringSchema;
        amount?: yup.NumberSchema;
        payOn?: yup.ObjectSchema<{
          month?: yup.NumberSchema;
          year?: yup.NumberSchema;
        }>;
      }>
    >;
  }>
> =>
  yup.lazy((_value) => {
    if (_value.payType === PaymentType.JOINING_BONUS) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name: yup.string().required(),
        occurrence: yup.string().required().oneOf([Occurrence.ONCE]),
        frequency: yup.string().required().oneOf(Object.values(RateFrequency)),
        payInInstallments: yup.boolean(),
        payoutMonth: yup.object().when('payInInstallments', {
          is: true,
          then: yup.object().nullable().notRequired(),
          otherwise: yup.object().shape({
            month: yup.number().required(),
            year: yup.number().required(),
          }),
        }),
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup.number().when('payInInstallments', {
          is: true,
          then: yup
            .number()
            .nullable()
            .transform((_, val) => (val === '' ? null : Number(val)))
            .notRequired(),
          otherwise: yup.number().moreThan(0).required(),
        }),
        conditions: yup.string().notRequired().nullable(),
        instalments: yup.array().when('payInInstallments', {
          is: true,
          then: yup.array().of(
            yup.object().shape({
              currency: yup
                .string()
                .oneOf(
                  currencyOptions ? currencyOptions.map((v) => v.value) : [],
                ),
              amount: yup.number().required(),
              payOn: yup.object().shape({
                month: yup.number().required(),
                year: yup.number().required(),
              }),
            }),
          ),
          otherwise: yup.array().notRequired(),
        }),
      });
    }
    if (_value.payType === PaymentType.VARIABLE_PERFORMANCE_BONUS) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name: yup.string().required(),
        occurrence: yup.string().required().oneOf([Occurrence.RECURRING]),
        frequency: yup.string().required().oneOf(Object.values(RateFrequency)),
        payoutMonth: yup.object().shape({
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        currency: yup.string().notRequired().nullable(),
        amount: yup.number().notRequired().nullable(),
        conditions: yup.string().notRequired().nullable(),
      });
    }
    if (
      _value.payType === PaymentType.ALLOWANCES ||
      _value.payType === PaymentType.DE_MINIMIS_ALLOWANCE ||
      _value.payType === PaymentType.INTERNET_ALLOWANCE ||
      _value.payType === PaymentType.MOBILE_AND_PHONE_ALLOWANCE
    ) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name:
          _value.payType === PaymentType.ALLOWANCES
            ? yup.string().required()
            : yup.string(),
        occurrence: yup.string().required().oneOf([Occurrence.RECURRING]),
        frequency: yup.string().required().oneOf(Object.values(RateFrequency)),
        payoutMonth: yup.object().when({
          is: () => isNewAllowancesConfigEnabled,
          then: yup.object().nullable().notRequired(),
          otherwise: yup.object().shape({
            month: yup.number().required(),
            year: yup.number().required(),
          }),
        }),
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup.number().moreThan(0).required(),
        conditions: yup.string().notRequired().nullable(),
      });
    }
    if (_value.payType === PaymentType.OTHER) {
      return yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        name: yup.string().required(),
        occurrence: yup.string().required().oneOf(Object.values(Occurrence)),
        frequency:
          _value?.occurrence === Occurrence.ONCE
            ? yup.string().notRequired().oneOf(Object.values(RateFrequency))
            : yup.string().required().oneOf(Object.values(RateFrequency)),
        payoutMonth: yup.object().shape({
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        currency: yup.string().notRequired().nullable(),
        amount: yup.number().notRequired().nullable(),
        conditions: yup.string().notRequired().nullable(),
      });
    }
    if (_value.payType === PaymentType.THR_BONUS) {
      return yup.object().shape({
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup
          .number()
          .min(basePay ?? 0)
          .required(),
      });
    }
    if (_value.payType === PaymentType.THIRTEENTH_MONTH_BONUS) {
      return yup.object().shape({
        currency: yup
          .string()
          .oneOf(currencyOptions ? currencyOptions.map((v) => v.value) : []),
        amount: yup.number().moreThan(0).required(),
      });
    }
    return yup.object().shape({
      payType: yup.string().required().oneOf(Object.values(PaymentType)),
    });
  });

const mapAdditionalPayNameToType = (payName: string): PaymentType => {
  const map: {
    [key: string]: PaymentType;
  } = {
    joiningBonus: PaymentType.JOINING_BONUS,
    variablePerformanceBonus: PaymentType.VARIABLE_PERFORMANCE_BONUS,
    allowances: PaymentType.ALLOWANCES,
    thr: PaymentType.THR_BONUS,
    deMinimisAllowance: PaymentType.DE_MINIMIS_ALLOWANCE,
    internetAllowance: PaymentType.INTERNET_ALLOWANCE,
    mobileAndPhoneAllowance: PaymentType.MOBILE_AND_PHONE_ALLOWANCE,
    '13thMonth': PaymentType.THIRTEENTH_MONTH_BONUS,
    other: PaymentType.OTHER,
  };
  if (payName in map) return map[payName];
  return PaymentType.OTHER;
};

export const mapAdditionalPayTypeToName = (payType: PaymentType): string => {
  const map = {
    [PaymentType.JOINING_BONUS]: 'joiningBonus',
    [PaymentType.VARIABLE_PERFORMANCE_BONUS]: 'variablePerformanceBonus',
    [PaymentType.ALLOWANCES]: 'allowances',
    [PaymentType.THIRTEENTH_MONTH_BONUS]: '13thMonth',
    [PaymentType.THR_BONUS]: 'thr',
    [PaymentType.OTHER]: 'other',
    [PaymentType.DE_MINIMIS_ALLOWANCE]: 'deMinimisAllowance',
    [PaymentType.INTERNET_ALLOWANCE]: 'internetAllowance',
    [PaymentType.MOBILE_AND_PHONE_ALLOWANCE]: 'mobileAndPhoneAllowance',
    [PaymentType.FOURTEENTH_MONTH_BONUS]: '14thMonth',
  };
  return map[payType];
};

export const mapRateFrequencyToNumberOfMonths = (
  rateFrequency?: Maybe<RateFrequency>,
  payShedule?: Maybe<ScheduleTime>,
): number => {
  switch (rateFrequency) {
    case RateFrequency.ANNUALLY:
      return 12;
    case RateFrequency.HALFYEARLY:
      return 6;
    case RateFrequency.TRI_ANNUALLY:
      return 4;
    case RateFrequency.QUATERLY:
      return 3;
    case RateFrequency.BI_MONTHLY:
      return 2;
    case RateFrequency.MONTHLY:
      return 1;
    case RateFrequency.SEMIMONTHLY:
      return 1 / 2;
    case RateFrequency.BI_WEEKLY:
      return 1 / 4;
    case RateFrequency.ONCE:
      return 0;
    default:
      return payShedule?.value ?? 0;
  }
};

const mapAdditionalPayTypeToLabel = (payType: PaymentType, name: string) => {
  const map: { [key: string]: string } = {
    [PaymentType.JOINING_BONUS]: 'joining-bonus',
    [PaymentType.VARIABLE_PERFORMANCE_BONUS]: 'variable-performance-bonus',
    [PaymentType.THR_BONUS]: 'thr-bonus',
    [PaymentType.THIRTEENTH_MONTH_BONUS]: '13th-month',
    [PaymentType.DE_MINIMIS_ALLOWANCE]: 'de-minimis-allowance',
    [PaymentType.INTERNET_ALLOWANCE]: 'internet-allowance',
    [PaymentType.MOBILE_AND_PHONE_ALLOWANCE]: 'mobile-and-phone-allowance',
  };
  if (payType in map) return map[payType];
  return name;
};

const mapNumberOfMonthsToRateFrequency = (
  occurrence: Occurrence,
  numberOfMonths: number,
) => {
  if (occurrence === Occurrence.ONCE) return RateFrequency.ONCE;
  switch (numberOfMonths) {
    case 12:
      return RateFrequency.ANNUALLY;
    case 6:
      return RateFrequency.HALFYEARLY;
    case 4:
      return RateFrequency.TRI_ANNUALLY;
    case 3:
      return RateFrequency.QUATERLY;
    case 2:
      return RateFrequency.BI_MONTHLY;
    case 1:
      return RateFrequency.MONTHLY;
    case 1 / 2:
      return RateFrequency.SEMIMONTHLY;
    default:
      return RateFrequency.CUSTOM;
  }
};

const mapToAdditionalPayName = (
  targetPayType: PaymentType,
  payItem: CompensationPayComponent | FixedPayComponent,
): string =>
  [
    PaymentType.JOINING_BONUS,
    PaymentType.VARIABLE_PERFORMANCE_BONUS,
    PaymentType.THR_BONUS,
    PaymentType?.THIRTEENTH_MONTH_BONUS,
    PaymentType?.DE_MINIMIS_ALLOWANCE,
    PaymentType?.INTERNET_ALLOWANCE,
    PaymentType?.MOBILE_AND_PHONE_ALLOWANCE,
  ].includes(targetPayType)
    ? String(payItem.name)
    : String(payItem?.label);

export const mapCompensationPayComponentToAdditionalFormValue = ({
  payItem,
  overrideAmount,
  isNotFreelancer,
  isNewAllowancesConfigEnabled,
  countryAllowancesConfig,
}: {
  payItem: CompensationPayComponent | FixedPayComponent;
  overrideAmount?: Maybe<Scalars['Float']>;
  isNotFreelancer?: boolean;
  isNewAllowancesConfigEnabled: boolean;
  countryAllowancesConfig:
    | GetCountryAllowancesConfigQuery['countryAllowancesConfig']
    | undefined;
}): AdditionalPayFormValues => {
  const targetPayType = mapAdditionalPayNameToType(String(payItem?.name));

  const matchedHrAllowanceConfig = getMatchedHrAllowanceConfig({
    isNewAllowancesConfigEnabled,
    countryAllowancesConfig,
    payName: String(payItem.name),
    payLabel: payItem.label ?? undefined,
  });

  return {
    payType: matchedHrAllowanceConfig ? PaymentType.ALLOWANCES : targetPayType,
    name: matchedHrAllowanceConfig
      ? 'allowances'
      : mapToAdditionalPayName(targetPayType, payItem),
    label: matchedHrAllowanceConfig?.name,
    defaultDisplayLabel: matchedHrAllowanceConfig?.label,
    occurrence:
      payItem?.frequency === RateFrequency?.ONCE
        ? Occurrence.ONCE
        : Occurrence?.RECURRING,
    frequency: isNotFreelancer
      ? payItem.frequency
      : mapRateFrequencyToNumberOfMonths(
          payItem?.frequency,
          payItem?.paySchedule,
        ),
    payoutMonth: {
      month: payItem?.payOn?.month as number,
      year: payItem?.payOn?.year as number,
    },
    amount: overrideAmount || payItem?.amount,
    amountType: payItem.amountType,
    conditions: payItem?.condition ?? '',
    currency: payItem?.currency as CurrencyCode,
    isDeletable: payItem?.isDeletable,
    payInInstallments: !!(
      (payItem as FixedPayComponent)?.instalments &&
      (payItem as FixedPayComponent)?.instalments?.length
    ),
    instalments: (payItem as FixedPayComponent)?.instalments,
  };
};

export const mapInstalmentFormValueToCompensationPayComponent = (
  instalment: Instalment,
): {
  amount: Maybe<number> | undefined;
  currency: Maybe<CurrencyCode> | undefined;
  payOn: {
    month: Maybe<number> | undefined;
    year: Maybe<number> | undefined;
  };
} => ({
  amount: instalment?.amount,
  currency: instalment?.currency,
  payOn: {
    month: instalment?.payOn?.month,
    year: instalment?.payOn?.year,
  },
});

export const mapAdditionalFormValueToCompensationPayComponent = ({
  payItem,
  isNotFreelancer,
}: {
  payItem: AdditionalPayFormValues;
  isNotFreelancer: boolean;
}): CompensationPayComponent | FixedPayComponent => {
  const paymentFrequency = isNotFreelancer
    ? (payItem.frequency as RateFrequency)
    : mapNumberOfMonthsToRateFrequency(
        payItem?.occurrence,
        (payItem?.frequency as number) ?? 0,
      );

  return {
    name: mapAdditionalPayTypeToName(payItem?.payType),
    label:
      payItem.label ??
      mapAdditionalPayTypeToLabel(payItem?.payType, payItem?.name),
    amountType: payItem.amountType,
    amount: [
      PaymentType.OTHER,
      PaymentType.VARIABLE_PERFORMANCE_BONUS,
    ].includes(payItem?.payType)
      ? undefined
      : payItem?.amount,
    currency: [
      PaymentType.OTHER,
      PaymentType.VARIABLE_PERFORMANCE_BONUS,
    ].includes(payItem?.payType)
      ? undefined
      : payItem?.currency,
    frequency: paymentFrequency,
    paySchedule:
      paymentFrequency === RateFrequency.CUSTOM
        ? {
            value: payItem.frequency as number,
            unit: ScheduleTimeUnit.MONTH,
          }
        : null,
    payOn:
      [PaymentType.THIRTEENTH_MONTH_BONUS, PaymentType.THR_BONUS].includes(
        payItem?.payType,
      ) || payItem?.payInInstallments
        ? null
        : {
            month: payItem?.payoutMonth?.month,
            year: payItem?.payoutMonth?.year,
          },
    condition: payItem?.conditions,
    instalments: payItem?.payInInstallments
      ? payItem?.instalments?.map((item) =>
          mapInstalmentFormValueToCompensationPayComponent(item),
        )
      : null,
  };
};

export const notIncludesInCompensationList = (
  originList: { name: string | null | undefined }[],
) => (additionPay: AdditionalPayFormValues): boolean =>
  originList.findIndex((item) => additionPay.name === item.name) < 0;

export const isMonthEnabledForAdditionalPay = (startOn: string): boolean => {
  const joiningDate = getDate(new Date(startOn));
  if (joiningDate <= 15) {
    return true;
  }
  if (
    joiningDate > 15 &&
    joiningDate <= 20 &&
    getDate(Date.now()) <= 15 &&
    getMonth(new Date(startOn)) === getMonth(Date.now())
  ) {
    return true;
  }

  if (joiningDate <= 20 && getMonth(new Date(startOn)) > getMonth(Date.now())) {
    return true;
  }

  if (joiningDate <= 20 && getYear(new Date(startOn)) > getYear(Date.now())) {
    return true;
  }

  return false;
};

export const getAverageWorkingHoursPerMonth = (
  isContractOnboardingHourlyPayEnabled?: boolean,
  averageWorkingHoursPerMonth?: CountryWorkShiftStandards['averageWorkingHoursPerMonth'],
): number | undefined => {
  if (
    isContractOnboardingHourlyPayEnabled &&
    isNumber(averageWorkingHoursPerMonth)
  )
    return averageWorkingHoursPerMonth;
  return undefined;
};

const map13th14thMonthData = (
  type13th14thMonth: Country13th14thMonthPayType,
  payType: PaymentType,
  schedules: Maybe<Country13th14thMonthPaySchedule>[],
  description: string,
  note: Maybe<string>,
): AdditionalPayFormValues => {
  const filteredSchedules = schedules?.filter(
    (schedule) => schedule?.country13th14thMonthPayType === type13th14thMonth,
  );

  const totalFilteredSchedules = filteredSchedules?.length;

  const payMonthString = filteredSchedules?.reduce(
    (months, schedule, index) =>
      schedule?.payoutDay
        ? `${months}${
            index !== 0
              ? totalFilteredSchedules === index + 1
                ? ` and `
                : `, `
              : ``
          }${Object.values(monthNames)[schedule.payoutDay - 1]}`
        : months,
    '',
  );

  const additionalPayTitle = description.replace(
    '{{ param }}',
    payMonthString ?? '',
  );

  const additionalPayData = {
    payType,
    name: mapAdditionalPayTypeToName(payType),
    title: additionalPayTitle,
    occurrence: Occurrence?.RECURRING,
    frequency: RateFrequency.ANNUALLY,
    payoutMonth: {
      month: 0,
      year: 0,
    },
    amount: 0,
    amountType: PayAmountType.FIXED_AMOUNT,
    conditions: note ?? '',
    isDeletable: false,
    payInInstallments: false,
  };

  return additionalPayData;
};

export const mapCountry13th14thMonthPayToAdditionalFormValue = (
  country13th14thMonthPay?: CountryCompliance['country13th14thMonthPay'],
): AdditionalPayFormValues[] => {
  const additionalPayResult: AdditionalPayFormValues[] = [];
  if (!country13th14thMonthPay) return additionalPayResult;
  const {
    isIncludedInGross,
    note,
    schedules,
    is13thMonthPayApplicable,
    description13thMonthPay,
    is14thMonthPayApplicable,
    description14thMonthPay,
  } = country13th14thMonthPay;
  if (
    is13thMonthPayApplicable &&
    !isIncludedInGross &&
    description13thMonthPay &&
    schedules &&
    note
  ) {
    const thirteenthMonthData = map13th14thMonthData(
      Country13th14thMonthPayType.THIRTEENTH_MONTH_PAY,
      PaymentType.THIRTEENTH_MONTH_BONUS,
      schedules,
      description13thMonthPay,
      note,
    );
    additionalPayResult.push(thirteenthMonthData);
  }

  if (
    is14thMonthPayApplicable &&
    !isIncludedInGross &&
    description14thMonthPay &&
    schedules &&
    note
  ) {
    const fourteenthMonthData = map13th14thMonthData(
      Country13th14thMonthPayType.FOURTEENTH_MONTH_PAY,
      PaymentType.FOURTEENTH_MONTH_BONUS,
      schedules,
      description14thMonthPay,
      note,
    );
    additionalPayResult.push(fourteenthMonthData);
  }
  return additionalPayResult;
};
