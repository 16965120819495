const routes = {
  root: 'login',
  ssoLogin: 'sso',
  ssoInitiate: 'sso-initiate',
  forgotPassword: {
    toString: (): string => 'forgot-password',
    success: 'success',
  },
  resetPassword: 'reset-password',
  invite: {
    toString: (): string => 'invite',
    company: 'company', // TODO - Should be decommissioned
    companyUser: 'company-user',
    member: 'member',
  },
  signUp: {
    toString: (): string => 'sign-up',
    company: {
      toString: (): string => 'company',
      create: 'create',
    },
    member: {
      toString: (): string => 'member',
    },
  },
  companySetup: 'company-setup',
};

export default routes;
