/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import {
  ContractDocumentStatus,
  ContractDocumentWorkflow,
  ContractOnboarding,
  ContractOnboardingStatus,
} from '__generated__/graphql';

export enum ImplicationInfoType {
  DELETE = 'DELETE',
  REVOKE = 'REVOKE',
  RESEND = 'RESEND',
  RESIGNATURE = 'RESIGNATURE',
  DEPOSIT_INVOICE = 'DEPOSIT_INVOICE',
  CREDIT_NOTE = 'CREDIT_NOTE',
}

export enum SignatoryType {
  SIGNATORY = 'SIGNATORY',
  EMPLOYEE = 'EMPLOYEE',
  MULTIPLIER = 'MULTIPLIER',
}

export const getResignatureList = (
  currentOnboardingStatus: ContractOnboarding['status'],
  contractDocumentWorkflowStatus: ContractDocumentWorkflow['currentStatus'],
): SignatoryType[] => {
  if (!currentOnboardingStatus) return [];

  const resignatureList: SignatoryType[] = [];

  if (
    [
      ContractOnboardingStatus.SIGNATURE_EMPLOYER_SIGNED,
      ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SENT,
    ].includes(currentOnboardingStatus)
  )
    resignatureList.push(SignatoryType.SIGNATORY);

  // Assuming only Multiplier EOR onboarding flow will consume this function
  if (
    [
      ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SIGNED,
      ContractOnboardingStatus.MEMBER_INVITED,
      ContractOnboardingStatus.MEMBER_STARTED,
      ContractOnboardingStatus.MEMBER_DATA_ADDED,
      ContractOnboardingStatus.MEMBER_DATA_REVIEWED,
      ContractOnboardingStatus.MEMBER_COMPLETED,
      ContractOnboardingStatus.MEMBER_VERIFICATION_IN_PROGRESS,
      ContractOnboardingStatus.MSA_SIGNING_PENDING,
      ContractOnboardingStatus.MSA_SIGNING_IN_PROGRESS,
      ContractOnboardingStatus.MEMBER_VERIFICATION_COMPLETED,
    ].includes(currentOnboardingStatus)
  )
    resignatureList.push(SignatoryType.SIGNATORY, SignatoryType.EMPLOYEE);

  if (contractDocumentWorkflowStatus === ContractDocumentStatus.CONTRACT_SIGNED)
    resignatureList.push(SignatoryType.MULTIPLIER);

  return resignatureList;
};

const ImplicationInfo: React.FC<{
  type: ImplicationInfoType;
  currentOnboardingStatus?: ContractOnboarding['status'];
  contractDocumentWorkflowStatus?: ContractDocumentWorkflow['currentStatus'];
}> = ({ type }) => {
  const { t } = useTranslation('team.company');

  const config = {
    [ImplicationInfoType.DELETE]: {
      icon: {
        name: 'bin',
        fill: 'transparent',
        stroke: theme`colors.icon-negative`,
      },
      div: { background: tw`bg-background-negative-subtle` },
      badge: { background: tw`bg-background-negative-faded` },
      description: (
        <Trans t={t} i18nKey="implication-info.implications.delete.description">
          <span tw="font-semibold">
            Member Contract & record will be deleted
          </span>
        </Trans>
      ),
    },
    [ImplicationInfoType.REVOKE]: {
      icon: {
        name: 'file-remove',
        fill: 'transparent',
        stroke: theme`colors.icon-warning`,
      },
      div: { background: tw`bg-background-warning-subtle` },
      badge: { background: tw`bg-background-warning-faded` },
      description: (
        <Trans t={t} i18nKey="implication-info.implications.revoke.description">
          <span tw="font-semibold">Contract will be annulled</span>
        </Trans>
      ),
    },
    [ImplicationInfoType.RESEND]: {
      icon: {
        name: 'send',
        fill: 'transparent',
        stroke: theme`colors.icon-warning`,
      },
      div: { background: tw`bg-background-warning-subtle` },
      badge: { background: tw`bg-background-warning-faded` },
      description: (
        <Trans t={t} i18nKey="implication-info.implications.resend.description">
          <span tw="font-semibold">Resend the Contract</span>
        </Trans>
      ),
    },
    [ImplicationInfoType.RESIGNATURE]: {
      icon: {
        name: 'pen',
        fill: 'transparent',
        stroke: theme`colors.icon-warning`,
      },
      div: { background: tw`bg-background-warning-subtle` },
      badge: { background: tw`bg-background-warning-faded` },
      description: (
        <Trans
          t={t}
          i18nKey="implication-info.implications.revise.description-required"
        >
          <span tw="font-semibold">
            Re-signatures needed - Signatory and Employee
          </span>
        </Trans>
      ),
    },
    [ImplicationInfoType.DEPOSIT_INVOICE]: {
      icon: {
        name: 'dollar-alt',
        fill: 'transparent',
        stroke: theme`colors.icon-warning`,
      },
      div: { background: tw`bg-background-warning-subtle` },
      badge: { background: tw`bg-background-warning-faded` },
      description: (
        <Trans
          t={t}
          i18nKey="implication-info.implications.deposit-invoice.description"
        >
          <span tw="font-semibold">Pay Employee Deposit Invoice</span>
        </Trans>
      ),
    },
    [ImplicationInfoType.CREDIT_NOTE]: {
      icon: {
        name: 'credit-card',
        fill: 'transparent',
        stroke: theme`colors.icon-warning`,
      },
      div: { background: tw`bg-background-warning-subtle` },
      badge: { background: tw`bg-background-warning-faded` },
      description: (
        <Trans
          t={t}
          i18nKey="implication-info.implications.credit-note.description"
        >
          <span tw="font-semibold">Receive Credit Note</span>
        </Trans>
      ),
    },
  };

  return (
    <div tw="flex flex-col items-center gap-y-extra-small text-ps text-text-primary">
      <div tw="flex flex-row gap-x-extra-small">
        <div
          css={[
            tw`rounded-base flex flex-row items-center gap-x-extra-small p-tiny`,
            config[type].div.background,
          ]}
        >
          <div
            css={[
              tw`w-large h-large rounded-tiny flex items-center justify-center`,
              config[type].badge.background,
            ]}
          >
            <Icon
              tw="h-base"
              name={config[type].icon.name}
              fill={config[type].icon.fill}
              stroke={config[type].icon.stroke}
            />
          </div>
        </div>
        <div tw="flex flex-row items-center gap-x-extra-small">
          {config[type].description}
        </div>
      </div>
    </div>
  );
};

export default ImplicationInfo;
