import { isNull, isNumber, isString, isUndefined, toString } from 'lodash';

import { CountryCode } from '__generated__/graphql';

function asEnumString<TEnumValue extends string | number>(
  enumValues: { [key in string]: TEnumValue },
  token: string | number | null | undefined,
): string | null {
  return isUndefined(token) || isNull(token)
    ? null
    : isNumber(token) &&
      Object.prototype.hasOwnProperty.call(enumValues, toString(token))
    ? (enumValues[token] as string)
    : isString(token) && Object.prototype.hasOwnProperty.call(enumValues, token)
    ? (token as string)
    : null;
}

function asSegment(value: CountryCode | null | undefined): string | null {
  return asEnumString(CountryCode, value);
}

function buildKey(
  key: string,
  preFix: string,
  preSegments?: undefined | null | string | Array<string>,
): string {
  return [
    preFix,
    ...(!preSegments
      ? []
      : Array.isArray(preSegments)
      ? preSegments
      : [preSegments]),
    key,
  ]
    .filter((m) => !!m)
    .join('.');
}

function buildKeys(
  key: string,
  preFix: string,
  preSegments?: undefined | null | string | Array<string>,
): Array<string> {
  return [
    ...(!preSegments || !preSegments.length
      ? []
      : [buildKey(key, preFix, preSegments)]),
    buildKey(key, preFix),
  ];
}

const i18nextUtil = {
  asSegment,
  buildTransKeys: buildKeys,
};

export default i18nextUtil;
