import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Cell, Row } from 'react-table';

import tw from 'twin.macro';

import Icon from '../../../icon';
import {
  TableBodyProps,
  TableColumnType,
  TableDataObjectType,
} from '../../types';
import StyledRow from '../styled-row';
import TableCell from '../table-cell';

export type TableRowProps = DetailedHTMLProps<
  HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
> &
  Pick<
    TableBodyProps,
    | 'bgRowsType'
    | 'rowStyles'
    | 'shouldHighlightRow'
    | 'handleRowClick'
    | 'disableSelection'
    | 'tableCellStyle'
    | 'expandIconPosition'
    | 'renderExpandedRow'
  > & {
    row: Row<TableDataObjectType>;
    itemIndex: number;
    bgDark?: boolean;
    hasHover?: boolean;
    highlight?: boolean;
    isNewThemeApplied: boolean;
  };

const canExpandRow = (
  row: Row<TableDataObjectType>,
  cell: Cell<TableDataObjectType, any>,
) =>
  (!!row?.subRows?.length &&
    ((cell?.column as unknown) as TableColumnType)?.canExpand) ??
  false;

const TableRow: React.FC<TableRowProps> = ({
  bgRowsType,
  rowStyles,
  shouldHighlightRow,
  handleRowClick,
  disableSelection,
  tableCellStyle,
  expandIconPosition,
  renderExpandedRow,
  row,
  itemIndex,
  isNewThemeApplied,
}) => (
  <StyledRow
    bgDark={
      bgRowsType === 'custom'
        ? false
        : bgRowsType === 'even'
        ? itemIndex % 2 === 0
        : itemIndex % 2 === 1
    }
    rowStyles={typeof rowStyles === 'function' ? rowStyles(row) : rowStyles}
    hasHover={!!handleRowClick}
    highlight={shouldHighlightRow && shouldHighlightRow(row.original)}
    {...(typeof row?.getRowProps === 'function' ? row?.getRowProps() : {})}
    data-testid={
      shouldHighlightRow && shouldHighlightRow(row.original)
        ? `row-${row.id}-highlight`
        : `row-${row.id}`
    }
    onClick={() => handleRowClick && handleRowClick(row?.original)}
    isNewThemeApplied={isNewThemeApplied}
  >
    {row.cells.map((cell, ind) => (
      <TableCell
        {...cell.getCellProps({
          style: {
            width: !disableSelection && ind === 0 ? 'auto' : cell.column.width,
          },
        })}
        css={[tableCellStyle, tw`py-none`]}
      >
        <div tw="flex justify-between">
          {expandIconPosition === 'left' &&
            renderExpandedRow &&
            canExpandRow(row, cell) && (
              <button
                type="button"
                data-testid="row-expand-icon"
                tw="w-[40px] flex items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  row?.toggleRowExpanded();
                }}
              >
                <Icon name={row.isExpanded ? 'caret-down' : 'caret-right'} />
              </button>
            )}
          <div tw="py-base w-full">{cell.render('Cell') as any}</div>
          {expandIconPosition === 'right' &&
            renderExpandedRow &&
            canExpandRow(row, cell) && (
              <button
                type="button"
                data-testid="row-expand-icon"
                tw="w-[40px] flex items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  row?.toggleRowExpanded();
                }}
              >
                <Icon name={row.isExpanded ? 'caret-down' : 'caret-right'} />
              </button>
            )}
        </div>
      </TableCell>
    ))}
  </StyledRow>
);

export default TableRow;
