import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const visaInvoicePaid: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.visa-invoice-paid.title', {
      defaultValue: 'Waiting for VISA invoice to be paid',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.visa-invoice-paid.description', {
      defaultValue: 'VISA Invoice paid, preparing contract',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t('onboarding-phase.visa-invoice-paid.label', 'Paid Visa Deposit invoice'),
  pendingOn: () => PendingOn.YOU,
  pendingLabel: (t): string =>
    t('onboarding-phase.visa-invoice-paid.status', 'Pending on You'),
};

export default visaInvoicePaid;
