import React, { RefObject, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import tw, { TwStyle } from 'twin.macro';

import Button from '../../../button';
import { ThemeContext } from '../../../theme-provider';
import {
  ComboBoxVariants,
  DropdownListOnValueSelected,
  DropdownValue,
  DropdownValueType,
} from '../../types';
import DropdownEntry from '../dropdown-entry';
import QueryInput from '../query-input';

const rebrandVariantStyles: { [key: string]: TwStyle[] } = {
  common: [
    tw`top-0 absolute border-border-primary border rounded-tiny bg-background-white shadow-small overflow-hidden z-10`,
  ],
  autocomplete: [tw`w-full`],
  inline: [tw`max-w-max`],
  'inline-text': [tw`absolute top-[calc(100% + 6px)] w-full`],
  default: [tw`absolute top-[calc(100% + 6px)] w-full`],
};

const variantStyles: { [key: string]: TwStyle[] } = {
  common: [
    tw`top-0 absolute border-grey03 border rounded-6 bg-white shadow-high overflow-hidden z-10`,
  ],
  autocomplete: [tw`w-full`],
  inline: [tw`max-w-max`],
  'inline-text': [
    tw`absolute top-[calc(100% + 6px)] bg-white shadow-low w-full`,
    tw`border border-grey03 rounded-6`,
  ],
  default: [
    tw`absolute top-[calc(100% + 6px)] bg-white shadow-low w-full`,
    tw`border border-grey03 rounded-6`,
  ],
};

interface DropdownProps<IsMulti extends boolean> {
  divRef: RefObject<HTMLDivElement> | null;
  selectedValueRef: RefObject<HTMLTableRowElement> | null;
  selectedOptionRef: RefObject<HTMLTableRowElement> | null;
  inputRef: RefObject<HTMLInputElement> | null;
  placeholder: string;
  queryPlaceholder?: string | React.ReactNode;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectDropdown: (e: React.KeyboardEvent) => void;
  values: DropdownValue[];
  input: string;
  selectedIndex: number;
  onValueSelected: (
    dropdownValue: DropdownListOnValueSelected<IsMulti>,
  ) => void;
  variant: ComboBoxVariants;
  selectedValue?: DropdownValueType;
  isValuesFiltered?: boolean;
  customOption?: React.ReactNode;
  multiple?: IsMulti;
  prependStyles?: TwStyle;
  dropdownStyles?: TwStyle;
  onClose?: () => void;
}

const DropdownList = <IsMulti extends boolean = false>({
  divRef,
  selectedValueRef,
  selectedOptionRef,
  inputRef,
  placeholder,
  queryPlaceholder,
  onChangeInput,
  selectDropdown,
  values,
  input,
  selectedIndex,
  onValueSelected,
  variant,
  selectedValue,
  isValuesFiltered,
  customOption = null,
  multiple,
  onClose,
  prependStyles,
  dropdownStyles,
  ...props
}: DropdownProps<IsMulti>) => {
  const { t } = useTranslation('common');
  const [currentValues, setCurrentValues] = useState<DropdownValue[]>([]);
  const { isNewThemeApplied } = useContext(ThemeContext);

  useEffect(() => {
    if (!selectedValue) return;
    setCurrentValues(
      Array.isArray(selectedValue) ? selectedValue : [selectedValue],
    );
  }, [selectedValue]);

  const handleValueSelected = (data: DropdownValue) => {
    if (!multiple) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onValueSelected(data);
      return;
    }

    const valueIdx = currentValues.findIndex((x) => x.value === data.value);
    if (valueIdx !== -1) {
      setCurrentValues([
        ...currentValues.slice(0, valueIdx),
        ...currentValues.slice(valueIdx + 1, currentValues.length),
      ]);
    } else {
      setCurrentValues([...currentValues, data]);
    }
  };

  const onApply = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onValueSelected(currentValues);
  };

  const showSearchBox =
    multiple || ['autocomplete', 'inline'].includes(variant);

  return (
    <div
      ref={divRef}
      css={[
        tw`relative`,
        !isNewThemeApplied ? variantStyles.common : rebrandVariantStyles.common,
        !isNewThemeApplied
          ? variantStyles[variant]
          : rebrandVariantStyles[variant],
        dropdownStyles,
      ]}
      {...props}
    >
      {showSearchBox && (
        <QueryInput
          css={[
            tw`max-w-[-moz-available]`,
            tw`max-w-[-webkit-fill-available]`,
            tw`m-extra-small h-40 outline-none placeholder-text-tertiary text-ps text-text-primary`,
            !isNewThemeApplied && tw`placeholder-grey02 text-background`,
          ]}
          ref={inputRef}
          value={input}
          onChange={onChangeInput}
          onKeyDown={selectDropdown}
          prependStyles={prependStyles}
          placeholder={(queryPlaceholder || placeholder) as string}
          variant={variant}
        />
      )}
      <div
        css={[
          tw`[max-height: 200px] overflow-auto`,
          showSearchBox && tw`top-40`,
          multiple && tw`mb-64`,
        ]}
      >
        <table tw="w-full">
          <tbody>
            {isValuesFiltered && values.length && customOption ? (
              <tr
                css={[
                  tw`hover:(bg-background-secondary cursor-pointer)`,
                  !isNewThemeApplied && tw`hover:(bg-grey05)`,
                  selectedIndex === -1 && tw`bg-background-secondary`,
                  !isNewThemeApplied && selectedIndex === -1 && tw`bg-grey05`,
                ]}
              >
                <td
                  colSpan={Object.keys(values[0]).length - 1}
                  tw="rounded-tiny px-6 py-6"
                >
                  {customOption}
                </td>
              </tr>
            ) : null}
            {values?.length > 0 ? (
              values.map((dropdownValue, index) => (
                <DropdownEntry
                  showCheckbox={multiple}
                  variant={variant}
                  selectedValueRef={selectedValueRef}
                  selectedOptionRef={selectedOptionRef}
                  key={dropdownValue.key ?? index}
                  dropdownValue={dropdownValue}
                  index={index}
                  selectedIndex={selectedIndex}
                  onValueSelected={handleValueSelected}
                  selectDropdown={selectDropdown}
                  selectedValue={currentValues.find(
                    (x) => dropdownValue.value === x.value,
                  )}
                />
              ))
            ) : (
              <div tw="p-small text-text-tertiary">
                {t('combo-box.empty-options', 'No values found')}
              </div>
            )}
          </tbody>
        </table>
      </div>
      {multiple && (
        <div
          css={[
            tw`flex h-64 items-center justify-between p-small absolute bottom-0 w-full border-t border-border-secondary`,
            !isNewThemeApplied && tw`border-grey04`,
          ]}
        >
          <Button
            intent="negative"
            variant="outline"
            size="small"
            onClick={onClose}
          >
            {t('combo-box.cancel-button', 'Cancel')}
          </Button>
          <Button
            intent="positive"
            variant="default"
            size="small"
            onClick={onApply}
          >
            {t('combo-box.apply-button', 'Apply')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DropdownList;
