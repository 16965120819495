/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, DropdownValue } from '@multiplier/common';

import TextInput from 'common/components/text-input';

import {
  ContractType,
  GetPositionsForIntegrationsQuery,
} from '__generated__/graphql';

import { BasicDetailsFormValues } from '../basic-details';

interface LastNameInputProps {
  supportedJobPositions?: GetPositionsForIntegrationsQuery['getPositionsForIntegrations'];
}

const JobTitleInput: React.FC<LastNameInputProps> = ({
  supportedJobPositions,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const methods = useFormContext<BasicDetailsFormValues>();
  const {
    register,
    watch,
    formState: { errors },
  } = methods;

  const contractType = watch('contractType');

  const dropDownOptions: DropdownValue[] =
    supportedJobPositions?.map((i) => ({
      title: i?.designation ?? '',
      value: i?.designation ?? '',
    })) ?? [];

  if (supportedJobPositions) {
    return (
      <TextInput.Container>
        <TextInput.Label htmlFor="job-title">
          {contractType === ContractType.FREELANCER
            ? t(
                'definition-phase.basic-details.freelancer-title.label',
                'Title',
              )
            : t('definition-phase.basic-details.job-title.label', 'Job Title')}
        </TextInput.Label>
        <Controller
          control={methods.control}
          name="jobTitle"
          render={({ field: { value, onChange } }) => (
            <ComboBox
              data-testid="job-title"
              variant="default"
              value={value}
              dropdownValues={dropDownOptions}
              placeholder={t(
                'definition-phase.basic-details.job-title.placeholder',
                'Software Engineer',
              )}
              onChange={onChange}
            />
          )}
        />
        {errors.jobTitle && (
          <TextInput.Error>{errors.jobTitle.message}</TextInput.Error>
        )}
      </TextInput.Container>
    );
  }

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="job-title">
        {contractType === ContractType.FREELANCER
          ? t('definition-phase.basic-details.freelancer-title.label', 'Title')
          : t('definition-phase.basic-details.job-title.label', 'Job Title')}
      </TextInput.Label>
      <TextInput
        id="job-title"
        placeholder={t(
          'definition-phase.basic-details.job-title.placeholder',
          'Software Engineer',
        )}
        error={!!errors.jobTitle}
        {...register('jobTitle')}
        autoComplete="off"
      />
      {errors.jobTitle && (
        <TextInput.Error>{errors.jobTitle.message}</TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default JobTitleInput;
