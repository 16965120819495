/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';
import 'twin.macro';

const ContactSupport: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.member');

  return (
    <div tw="flex my-24 text-ps text-neutral500 items-center justify-center">
      {t(
        'support-team.text',
        'Please reach out to our support team if you have any queries',
      )}
      <Button
        tw="text-ps text-primaryBlue600 border-none p-0 underline pl-4"
        onClick={() =>
          window.open(
            'https://www.usemultiplier.com/contact-us',
            '_blank',
            'noopener,noreferrer',
          )
        }
        variant="outline"
      >
        {t('support-team.contact-us', 'Contact us')}
      </Button>
    </div>
  );
};

export default ContactSupport;
