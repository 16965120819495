import { gql } from '@apollo/client';

export default gql`
  query GetTeam {
    company {
      id
      contracts(includeAnyStatus: true) {
        id
        endOn
        endedOn
        offboardingNote
        member {
          id
          firstName
          lastName
          status
        }
        compensation {
          basePay {
            amount
            frequency
            currency
          }
        }
        onboarding {
          id
          status
          activationCutoff
          tasks {
            name
            order
            completed
            pendingOn {
              id
              persona
              firstName
              lastName
            }
          }
        }
        type
        term
        position
        status
        startOn
        country
        createdOn
        contractStarted
        contractBlocked
        lastWorkingDay
        offboarding {
          id
          type
          lastWorkingDay
          status
        }
      }
    }
  }
`;
