import { gql } from '@apollo/client';

export default gql`
  mutation VerifyOtp($input: VerifyOtpInput) {
    verifyOtp(input: $input)
      @rest(
        type: "JwtResponse"
        method: "POST"
        path: "verify/otp"
        endpoint: "user"
      ) {
      id_token
    }
  }
`;
