const routes = {
  root: 'offboarding',

  // resignation steps
  info: 'info',
  settlement: 'settlement',
  reschedule: 'reschedule',

  // termination steps
  clause: 'clause',

  // did not join steps
  reason: 'reason',
  confirm: 'confirm',
};

export default routes;
