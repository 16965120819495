import { gql } from '@apollo/client';

export default gql`
  mutation UpdateCompensation(
    $id: ID!
    $input: ContractUpdateCompensationInput!
  ) {
    contractUpdateCompensation(id: $id, input: $input) {
      id
      compensation {
        basePay {
          ...fixedPayComponent
        }
        postProbationBasePay {
          ...fixedPayComponent
        }
        probationBasePay {
          ...fixedPayComponent
        }
        additionalPays {
          ...compensationPayComponent
        }
        additional
        payrollStart
        grant(contractId: $id) {
          ...grantComponent
        }
        payType
        deductions {
          deductionDefinitionId
          value
          unit
        }
      }
      onboarding {
        status
        steps
        current
        activationCutoff
      }
    }
  }
`;
