export const stringAsTitleCase = (value?: string | null): string => {
  if (!value) return 'N/A';
  const lowerCase = String(value).replace(/_/g, ' ').toLowerCase();
  return lowerCase.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
};

export const stringAsKebabCase = (input: string | null | undefined): string =>
  input
    ?.replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase() ?? '';

export const padWithLeadingZeros = (
  length: number,
  num?: number | null,
): string => String(num ?? 0).padStart(length, '0');

export const removeTrailingSlash = (str: string): string =>
  str.endsWith('/') ? str.slice(0, -1) : str;
