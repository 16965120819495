import { AppFeature } from '@multiplier/growthbook';

import {
  CompanyNavGroups,
  Experience,
  MemberNavGroups,
  ModuleConfig,
} from 'app/models/module-config';

import routes from './routes';

export const companyConfig: ModuleConfig = {
  id: 'company-employee-directory',
  permission: 'dashboard',
  folder: 'organization-directory',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Employee directory',
    route: routes.root,
    sort: 5,
    home: false,
    group: CompanyNavGroups.COMPANY,
  },
  featureFlag: AppFeature.HRIS_ORG_DIRECTORY,
};

export const memberConfig: ModuleConfig = {
  id: 'member-employee-directory',
  permission: 'dashboard',
  folder: 'organization-directory',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Employee directory',
    route: routes.root,
    sort: 3,
    home: false,
    group: MemberNavGroups.COMPANY,
  },
  featureFlag: AppFeature.HRIS_ORG_DIRECTORY,
};
