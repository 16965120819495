import { gql } from '@apollo/client';

export default gql`
  mutation SalaryCalculatorRequestEmail(
    $params: SalaryCalculationRequestEmailParam
  ) {
    salaryCalculatorRequestEmail(params: $params) {
      success
      message
    }
  }
`;
