/** @jsxImportSource @emotion/react */
import React from 'react';

import tw from 'twin.macro';

import {
  Contract,
  Maybe,
  useGetEquipmentsForContractQuery,
} from '__generated__/graphql';

const MainContainer = tw.div`
  flex flex-col gap-y-extra-small max-w-[300px]
`;

interface AssetDetailInfoProps {
  keyItem: Maybe<string> | undefined;
  value: Maybe<string> | undefined;
}

const AssetDetailInfo = ({
  keyItem,
  value,
}: AssetDetailInfoProps): React.ReactElement => (
  <div tw="grid grid-cols-2 text-ps text-text-primary gap-x-extra-large">
    <div tw="font-semibold">{keyItem}</div>
    <div>{value}</div>
  </div>
);

const EquipmentAssignedContainer: React.FC<{
  contract: Contract;
}> = ({ contract }): React.ReactElement => {
  const { data } = useGetEquipmentsForContractQuery({
    variables: {
      contractId: contract?.id ?? '',
    },
  });

  return (
    <MainContainer data-testid="procurement-status-completed">
      <AssetDetailInfo
        keyItem={data?.contract?.equipments?.[0]?.type ?? ''}
        value={data?.contract?.equipments?.[0]?.name ?? ''}
      />
      <AssetDetailInfo
        keyItem="Serial Number"
        value={data?.contract?.equipments?.[0]?.serialNumber ?? ''}
      />
    </MainContainer>
  );
};

export default EquipmentAssignedContainer;
