import { faker } from '@faker-js/faker';
import { merge } from 'lodash';

import {
  ApprovalCategory,
  CategoryApproverInfo,
  Contract,
  ContractDocumentStatus,
  ContractDocumentWorkflow,
  ContractOnboarding,
  ContractOnboardingStatus,
  ContractOnboardingStep,
  ContractStatus,
  ContractTerm,
  ContractType,
  CountryCode,
  CountryWorkStatus,
  CurrencyCode,
  OnboardingTask,
  PaymentAccountRequirement,
  Payslip,
  Person,
  Persona,
} from '__generated__/graphql';

import { Experience } from '../types';
import { mockContractBenefit } from './benefits';
import { mockCompany } from './company';
import { mockDepositPayable } from './company-payable';
import { mockCompensation } from './compensation';
import { mockWorkShift } from './country';
import { mockManagerWithoutReports } from './manager';
import { mockMember } from './member';
import { mockOrgAttributes } from './org-attributes';
import { mockContractTimeOff } from './time-off';

export const defaultSteps = {
  [ContractType.EMPLOYEE]: [
    ContractOnboardingStep.DEFINITION_MEMBER_ELIGIBILITY,
    ContractOnboardingStep.DEFINITION_MEMBER_BASIC_DETAILS,
    ContractOnboardingStep.DEFINITION_COMPENSATION_DETAILS,
    ContractOnboardingStep.DEFINITION_BENEFITS_DETAILS,
    ContractOnboardingStep.DEFINITION_COMPLIANCE_DETAILS,
  ],
  [ContractType.HR_MEMBER]: [
    ContractOnboardingStep.DEFINITION_MEMBER_ELIGIBILITY,
    ContractOnboardingStep.DEFINITION_MEMBER_BASIC_DETAILS,
    ContractOnboardingStep.DEFINITION_COMPENSATION_DETAILS,
    ContractOnboardingStep.DEFINITION_BENEFITS_DETAILS,
    ContractOnboardingStep.DEFINITION_CONTRACT,
  ],
  [ContractType.FREELANCER]: [
    ContractOnboardingStep.DEFINITION_MEMBER_BASIC_DETAILS,
    ContractOnboardingStep.DEFINITION_COMPENSATION_DETAILS,
    ContractOnboardingStep.DEFINITION_BENEFITS_DETAILS,
    ContractOnboardingStep.DEFINITION_CONTRACT,
  ],
  [ContractOnboardingStep.ONBOARDING_REVIEW]: [
    ContractOnboardingStep.ONBOARDING_REVIEW,
    ContractOnboardingStep.ONBOARDING_MEMBER,
    ContractOnboardingStep.ONBOARDING_VERIFYING,
    ContractOnboardingStep.ONBOARDING_SIGNING,
  ],
  [ContractType.CONTRACTOR]: [],
};

const statusMap: { [key: string]: ContractOnboardingStatus[] } = {
  [ContractOnboardingStep.ONBOARDING_REVIEW]: [
    ContractOnboardingStatus.CREATED,
  ],
  [ContractOnboardingStep.ONBOARDING_MEMBER]: [
    ContractOnboardingStatus.MEMBER_INVITED,
    ContractOnboardingStatus.MEMBER_STARTED,
    ContractOnboardingStatus.MEMBER_DATA_ADDED,
    ContractOnboardingStatus.MEMBER_DATA_REVIEWED,
  ],
  [ContractOnboardingStep.ONBOARDING_VERIFYING]: [
    ContractOnboardingStatus.MEMBER_COMPLETED,
    ContractOnboardingStatus.MEMBER_VERIFICATION_IN_PROGRESS,
    ContractOnboardingStatus.MEMBER_VERIFICATION_COMPLETED,
  ],
  [ContractOnboardingStep.ONBOARDING_SIGNING]: [
    ContractOnboardingStatus.SIGNATURE_EMPLOYER_SENT,
    ContractOnboardingStatus.SIGNATURE_EMPLOYER_SIGNED,
    ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SENT,
    ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SIGNED,
  ],
  [ContractOnboardingStep.ONBOARDING_MSA_PENDING]: [
    ContractOnboardingStatus.MSA_SIGNING_PENDING,
    ContractOnboardingStatus.MSA_SIGNING_IN_PROGRESS,
  ],
  [ContractOnboardingStep.MEMBER_REVIEW]: [
    ContractOnboardingStatus.MEMBER_DATA_ADDED,
    ContractOnboardingStatus.MEMBER_DATA_REVIEWED,
  ],
};

const memberSteps = {
  DEFINITION: [
    ContractOnboardingStep.MEMBER_WELCOME,
    ContractOnboardingStep.MEMBER_BASIC_DETAILS,
    ContractOnboardingStep.MEMBER_BANK_DETAILS,
    ContractOnboardingStep.MEMBER_LEGAL_DETAILS,
    ContractOnboardingStep.MEMBER_LEGAL_DOCUMENTS,
  ],
  ONBOARDING: [
    ContractOnboardingStep.MEMBER_REVIEW,
    ContractOnboardingStep.ONBOARDING_VERIFYING,
  ],
};

export const mockContractDocumentWorkflow = (): ContractDocumentWorkflow => ({
  currentStatus: ContractDocumentStatus.CONTRACT_SIGNED,
  __typename: 'ContractDocumentWorkflow',
});

export const mockContractOnboarding = ({
  current,
  userType = Experience.COMPANY,
  status = ContractOnboardingStatus.DRAFT,
  contractType = ContractType.EMPLOYEE,
  steps = defaultSteps[contractType],
  tasks = [],
  activationCutoff = null,
}: {
  current?: ContractOnboardingStep;
  userType?: Experience;
  status?: ContractOnboardingStatus;
  contractType?: ContractType;
  steps?: ContractOnboardingStep[];
  tasks?: OnboardingTask[];
  activationCutoff?: string | null;
}): ContractOnboarding => ({
  id: faker.datatype.uuid(),
  activationCutoff,
  tasks,
  status,
  statuses: (current && statusMap[current]) ?? [ContractOnboardingStatus.DRAFT],
  steps: userType === Experience.MEMBER ? memberSteps.DEFINITION : steps,
  current,
  contractDocumentWorkflow: mockContractDocumentWorkflow(),
  __typename: 'ContractOnboarding',
});

export const mockPayslip = (): Payslip => ({
  id: faker.datatype.uuid(),
  month: {
    month: faker.datatype.number({ min: 1, max: 12 }),
    year: 2021,
    __typename: 'PayrollMonth',
  },
  netAmount: Number(faker.finance.amount()),
  currencyCode: CurrencyCode.SGD,
  createdOn: faker.date.past(),
  payslipDocument: {
    downloadUrl:
      'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
    name: 'dummyfile.pdf',
  },
  __typename: 'Payslip',
});

export const mockPerson = (override = {} as Partial<Person>): Person =>
  merge(
    {
      id: String(faker.datatype.number()),
      userId: String(faker.datatype.number()),
      firstName: faker.lorem.word(),
      lastName: faker.lorem.word(),
      persona: faker.helpers.arrayElement(Object.values(Persona)),
    },
    override,
  );

export const mockScope = (): string => 'This is the test job description';

export const mockCategoryApprover = (
  override = {} as Partial<CategoryApproverInfo>,
): CategoryApproverInfo =>
  merge(
    {
      approverUsers: [mockPerson(), mockPerson(), mockPerson()],
      category: faker.helpers.arrayElement(Object.values(ApprovalCategory)),
      __typename: 'CategoryApproverInfo',
    },
    override,
  );

export const mockContract = (
  {
    id,
    firstName,
    lastName,
    contractType = ContractType.EMPLOYEE,
    contractTerm = ContractTerm.PERMANENT,
    currentOnboardingStep = ContractOnboardingStep.DEFINITION_MEMBER_BASIC_DETAILS,
    contractOnboardingStatus = ContractOnboardingStatus.DRAFT,
    userType = Experience.COMPANY,
    status = ContractStatus.ACTIVE,
    country = CountryCode.SGP,
    countryStateCode = 'NY',
    categoryApproverInfos = [mockCategoryApprover(), mockCategoryApprover()],
    paymentAccountRequirements = [],
    compensation = mockCompensation({}),
    benefits = [
      mockContractBenefit({
        employeePayPercentage: 0,
        employerPayPercentage: 100,
      }),
    ],
    compliance = null,
    payslips = [mockPayslip(), mockPayslip(), mockPayslip()],
    startOn = faker.date.future(),
    endOn = faker.date.future(),
    endedOn = faker.date.past(),
    offboardingNote = faker.lorem.text(),
    workEmail = 'workemailtest@usemultiplier.com',
    workShift = mockWorkShift({}),
    contractStarted = false,
    createdOn = '2021-06-20T16:00:00.000Z',
    position = faker.name.jobTitle(),
    depositPayable = mockDepositPayable(),
    contractBlocked = false,
    unresolvedBlockEvents = [],
    lastWorkingDay = null,
    offboarding = null,
    performanceReviews = null,
    member = null,
    onboarding = null,
    orgAttributes = mockOrgAttributes(),
  }: Contract & {
    firstName?: string;
    lastName?: string;
    contractType?: ContractType;
    contractTerm?: ContractTerm;
    currentOnboardingStep?: ContractOnboardingStep;
    contractOnboardingStatus?: ContractOnboardingStatus;
    userType?: Experience;
    status?: ContractStatus;
    country?: CountryCode;
    countryStateCode?: string;
    categoryApproverInfos?: CategoryApproverInfo[];
    paymentAccountRequirements?: PaymentAccountRequirement[];
  } = {},
  hasManager = true,
): Contract => ({
  id: id ?? String(faker.datatype.number()),
  term: contractTerm,
  type: contractType,
  status,
  country,
  categoryApproverInfos: categoryApproverInfos ?? null,
  countryStateCode,
  currency: CurrencyCode.SGD,
  workStatus: CountryWorkStatus.RESIDENT,
  member: member ?? mockMember(firstName, lastName),
  company: mockCompany({}),
  position,
  onboarding:
    onboarding ??
    mockContractOnboarding({
      current: currentOnboardingStep,
      userType,
      status: contractOnboardingStatus,
    }),
  alreadyHired: false,
  startOn,
  endOn,
  endedOn,
  offboardingNote,
  scope: mockScope(),
  compensation,
  benefits,
  compliance,
  payslips,
  workEmail,
  paymentAccountRequirements,
  timeOff: mockContractTimeOff(),
  workShift,
  contractStarted,
  createdOn,
  depositPayable,
  contractBlocked,
  unresolvedBlockEvents,
  lastWorkingDay,
  offboarding,
  performanceReviews,
  orgAttributes,
  ...(hasManager ? { reportsToManager: mockManagerWithoutReports() } : {}),
  __typename: 'Contract',
});
