/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import * as format from 'app/utils/format';
import ButtonGroup from 'common/components/button-group';
import { rateFrequencyLabel } from 'common/constants/default-labels';

import { CurrencyCode, RateFrequency } from '__generated__/graphql';

interface BreakdownHeaderProps {
  frequency: RateFrequency;
  availableFrequencies: RateFrequency[];
  currency: CurrencyCode;
  amount: number;
  handleFrequencyChange: (frequency: RateFrequency) => void;
}

const BreakdownHeader: React.FC<
  React.PropsWithChildren<BreakdownHeaderProps>
> = ({
  frequency,
  availableFrequencies,
  currency,
  amount,
  handleFrequencyChange,
  ...props
}) => {
  const { t, i18n } = useTranslation('salary-calculator');

  // The rateFrequencyLabel object is filtered and used here to maintain the order of frequencies
  const frequencyOptions = useMemo(
    () =>
      Object.keys(rateFrequencyLabel)
        .filter((key) => availableFrequencies.includes(key as RateFrequency))
        .map((freq) => ({
          value: freq,
          label: rateFrequencyLabel[freq as RateFrequency],
        })),
    [availableFrequencies],
  );

  return (
    <div tw="flex flex-row justify-between items-center" {...props}>
      <div>
        <div tw="text-text-primary text-p text-opacity-75 mb-extra-small">
          {t('breakdown.breakdown-for', 'Breakdown For')}
        </div>
        <div tw="text-h4 text-text-primary font-semibold">
          <span tw="mr-tiny">{currency}</span>
          <span>{format.numberAsCurrency(i18n.language, amount)}</span>
        </div>
      </div>
      <ButtonGroup
        options={frequencyOptions}
        variant="pill"
        selected={frequency}
        onChange={(e) => {
          handleFrequencyChange(
            (e.target as HTMLInputElement).value as RateFrequency,
          );
        }}
      />
    </div>
  );
};

export default BreakdownHeader;
