/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@multiplier/common';
import 'twin.macro';

import { localBankCodeMap } from 'contract-onboarding/member/pages/onboarding/pages/bank-details';
import getAccountNumberFieldLabel from 'contract-onboarding/member/services/account-number-field-label';
import {
  getBankCodeSchema,
  getSwiftCodeSchema,
} from 'contract-onboarding/services/bank-details-schema';

import { Contract } from '__generated__/graphql';

import { BankDetailsSectionVariant } from '../../index';
import FormItem from '../bank-account-details-form-item';
import SwiftBICValueComponent from '../swift-bic-value-component';

const BankDetailsSubForm: React.FC<{
  isEditing?: boolean;
  member: Contract['member'];
  country: Contract['country'];
  contractType: Contract['type'];
  variant: BankDetailsSectionVariant;
}> = ({ isEditing, member, country, contractType, variant }) => {
  const { t } = useTranslation('contract-onboarding.member');
  const methods = useFormContext();

  const {
    register,
    formState: { errors },
    unregister,
  } = methods;

  useEffect(
    () => () => {
      unregister(
        [
          'accountName',
          'accountNumber',
          'bankName',
          'branchName',
          'localBankCode',
          'swiftCode',
        ],
        { keepError: false },
      );
    },
    [],
  );

  return (
    <>
      <FormItem
        id="account-holder-name"
        label={t('onboarding-phase.bank-details.account-name', 'Account Name')}
        value={member?.bankAccounts?.[0]?.accountName}
        isEditing={isEditing}
        variant={variant}
      >
        <TextInput.Container>
          <TextInput
            id="account-holder-name"
            placeholder={t(
              'bank-details.holder-name-placeholder',
              'John David Markus',
            )}
            {...register('accountName', {
              required: t(
                'bank-account-schema.text.error-message.required',
                'Input is required',
              ),
            })}
          />
          {errors?.accountName && (
            <TextInput.Error>{errors?.accountName?.message}</TextInput.Error>
          )}
        </TextInput.Container>
      </FormItem>

      <FormItem
        id="account-number"
        label={getAccountNumberFieldLabel(t, country)}
        value={member?.bankAccounts?.[0]?.accountNumber}
        isEditing={isEditing}
        variant={variant}
      >
        <TextInput.Container>
          <TextInput
            id="account-number"
            placeholder={t('bank-details.account-number-placeholder', '123456')}
            {...register('accountNumber', {
              required: t(
                'bank-account-schema.text.error-message.required',
                'Input is required',
              ),
            })}
          />
          {errors?.accountNumber && (
            <TextInput.Error>{errors?.accountNumber?.message}</TextInput.Error>
          )}
        </TextInput.Container>
      </FormItem>

      <FormItem
        id="bank-name"
        label={t('onboarding-phase.bank-details.bank-name', 'Bank Name')}
        value={member?.bankAccounts?.[0]?.bankName}
        isEditing={isEditing}
        variant={variant}
      >
        <TextInput.Container>
          <TextInput
            id="bank-name"
            placeholder={t(
              'bank-details.bank-name-placeholder',
              'Achme International Bank',
            )}
            {...register('bankName', {
              required: t(
                'bank-account-schema.text.error-message.required',
                'Input is required',
              ),
            })}
          />
          {errors?.bankName && (
            <TextInput.Error>{errors?.bankName?.message}</TextInput.Error>
          )}
        </TextInput.Container>
      </FormItem>

      <FormItem
        id="branch-name"
        label={t('onboarding-phase.bank-details.branch-name', 'Branch Name')}
        value={member?.bankAccounts?.[0]?.branchName}
        isEditing={isEditing}
        variant={variant}
      >
        <TextInput.Container>
          <TextInput
            id="branch-name"
            placeholder={t(
              'bank-details.branch-name-placeholder',
              'City Center Branch',
            )}
            {...register('branchName', {
              required: t(
                'bank-account-schema.text.error-message.required',
                'Input is required',
              ),
            })}
          />
          {errors?.branchName && (
            <TextInput.Error>{errors?.branchName?.message}</TextInput.Error>
          )}
        </TextInput.Container>
      </FormItem>
      <FormItem
        id="localBankCode"
        label={
          country && !!localBankCodeMap?.[country]
            ? localBankCodeMap[country].label(t)
            : t('bank-details.local-bank-code.default.label', 'Local Bank Code')
        }
        value={member?.bankAccounts?.[0]?.localBankCode}
        isEditing={isEditing}
        variant={variant}
      >
        <TextInput.Container>
          <TextInput
            id="localBankCode"
            placeholder={t(
              'bank-details.local-bank-code.default.placeholder',
              '123456',
            )}
            {...register('localBankCode', {
              validate: (value) =>
                getBankCodeSchema(t, country)
                  .validate(value)
                  .then(() => true)
                  .catch((e) => e.message),
            })}
            error={!!errors.localBankCode}
          />
          {errors?.localBankCode && (
            <TextInput.Error>{errors?.localBankCode?.message}</TextInput.Error>
          )}
        </TextInput.Container>
      </FormItem>
      <FormItem
        id="swift-code"
        label={t('bank-details.swift-code.label', 'SWIFT Code')}
        value={member?.bankAccounts?.[0]?.swiftCode}
        isEditing={isEditing}
        variant={variant}
        valueElement={
          <SwiftBICValueComponent
            value={member?.bankAccounts?.[0]?.swiftCode || ''}
            contractType={contractType}
          />
        }
      >
        <TextInput.Container>
          <TextInput
            id="swift-code"
            placeholder={t('bank-details.swift-code-placeholder', '123456')}
            {...register('swiftCode', {
              validate: (value) =>
                getSwiftCodeSchema(t, country, contractType)
                  .validate(value)
                  .then(() => true)
                  .catch((e) => e.message),
            })}
            error={!!errors.swiftCode}
          />
          {errors?.swiftCode && (
            <TextInput.Error>{errors?.swiftCode?.message}</TextInput.Error>
          )}
        </TextInput.Container>
      </FormItem>
    </>
  );
};

export default BankDetailsSubForm;
