import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const visaDocumentsStarted: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.visa-documents-started.title', {
      defaultValue: 'Collecting VISA documents from {{name}}',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.visa-documents-started.description', {
      defaultValue: '{{firstName}} has accessed Multiplier for the first time.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.visa-documents-started.label',
      'Employee has accessed Multiplier',
    ),
  pendingOn: () => PendingOn.EMPLOYEE,
  pendingLabel: (t): string =>
    t('onboarding-phase.visa-documents-started.status', 'Pending on Employee'),
};

export default visaDocumentsStarted;
