import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { useMedia } from 'react-use';

import { css } from '@emotion/react';
import tw, { theme } from 'twin.macro';

import { useDelayedUnmount } from '../hooks';
import Icon from '../icon';
import { ToolTipProps, ToolTipVariant } from '../types';

const MobileToolTip: React.FC<{
  content: React.ReactNode;
  styles?: ToolTipProps['styles'];
  closeMobileToolTip: () => void;
}> = ({ content, styles, closeMobileToolTip }) => (
  <div
    css={[
      tw`fixed bottom-none w-screen bg-background-inverted text-text-inverted-primary text-left padding[15px 20px 15px 20px] flex items-start justify-between`,
      styles,
    ]}
  >
    <div data-testid="tooltip-content" tw="text-left">
      {content}
    </div>
    <button
      data-testid="tooltip-close"
      type="button"
      tw="outline-none"
      onClick={closeMobileToolTip}
    >
      <span tw="font-bold">x</span>
    </button>
  </div>
);

const MobileToolTipV2: React.FC<{
  content: React.ReactNode;
  styles?: ToolTipProps['styles'];
  closeMobileToolTip: () => void;
  header?: ToolTipProps['header'];
}> = ({ content, closeMobileToolTip, header, styles }) => (
  <div
    data-testid="mobile-tool-tip-v2"
    tw="fixed bottom-0 w-screen bg-background-white"
  >
    {header && (
      <div tw="px-base py-small bg-background-primary text-text-primary flex items-center justify-between">
        <div>{header}</div>
        <button
          data-testid="tooltip-close"
          type="button"
          tw="outline-none"
          onClick={closeMobileToolTip}
        >
          <Icon name="cross" />
        </button>
      </div>
    )}
    <div
      data-testid="tooltip-content"
      css={[tw`text-text-primary text-left px-base py-extra-small`, styles]}
    >
      {content}
    </div>
  </div>
);

const ToolTipPortal: React.FC<{
  active: boolean;
  content: React.ReactNode;
  component: HTMLDivElement;
  variant?: ToolTipVariant;
  closeMobileToolTip: () => void;
  styles: ToolTipProps['styles'];
  fadeDelay?: number;
  v2: ToolTipProps['v2'];
  header: ToolTipProps['header'];
}> = ({
  active,
  content,
  component,
  variant = 'top',
  closeMobileToolTip,
  styles,
  fadeDelay,
  v2 = false,
  header,
}) => {
  const isMobile = useMedia(`(max-width: ${theme`screens.mobile.max`})`);
  const getBoundingClientRect = component.getBoundingClientRect();

  const toolTipStyles = useMemo(() => {
    const { left, right, top, bottom } = getBoundingClientRect;
    switch (variant) {
      case 'top':
        return [
          css`
            top: ${top - 6}px;
            left: ${left + (right - left) / 2}px;
            transform: translateY(-100%) translateX(-50%);
          `,
        ];
      case 'bottom':
        return [
          css`
            top: ${bottom + 6}px;
            left: ${left + (right - left) / 2}px;
            transform: translateX(-50%);
          `,
        ];
      case 'right':
        return [
          css`
            top: ${top + (bottom - top) / 2}px;
            left: ${right + 6}px;
            transform: translateY(-50%);
          `,
        ];
      case 'left':
        return [
          css`
            top: ${top + (bottom - top) / 2}px;
            left: ${left - 6}px;
            transform: translateX(-100%) translateY(-50%);
          `,
        ];
      default:
        return [];
    }
  }, [variant, getBoundingClientRect]);

  const arrowStyles = useMemo(() => {
    const { left, right, top, bottom } = getBoundingClientRect;
    switch (variant) {
      case 'top':
        return [
          tw`z-[99] absolute margin-left[-6px] border-width[6px] border-solid border-transparent pointer-events-none`,
          css`
            top: ${top - 6}px;
            left: ${left + (right - left) / 2}px;
            border-top-color: ${theme`colors.background-inverted`};
          `,
        ];
      case 'bottom':
        return [
          tw`z-[99] absolute margin-left[-6px] border-width[6px] border-solid border-transparent pointer-events-none`,
          css`
            top: ${bottom - 6}px;
            left: ${left + (right - left) / 2}px;
            border-bottom-color: ${theme`colors.background-inverted`};
          `,
        ];
      case 'left':
        return [
          tw`z-[99] absolute margin-top[-6px] border-width[6px] border-solid border-transparent pointer-events-none`,
          css`
            top: ${top + (bottom - top) / 2}px;
            left: ${left - 6}px;
            border-left-color: ${theme`colors.background-inverted`};
          `,
        ];
      case 'right':
        return [
          tw`z-[99] absolute margin-top[-6px] border-width[6px] border-solid border-transparent pointer-events-none`,
          css`
            top: ${top + (bottom - top) / 2}px;
            left: ${right - 6}px;
            border-right-color: ${theme`colors.background-inverted`};
          `,
        ];
      default:
        return [];
    }
  }, [variant, getBoundingClientRect]);

  if (isMobile) {
    return ReactDOM.createPortal(
      <div tw="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-60 z-50">
        {v2 ? (
          <MobileToolTipV2
            content={content}
            styles={styles}
            closeMobileToolTip={closeMobileToolTip}
            header={header}
          />
        ) : (
          <MobileToolTip
            content={content}
            styles={styles}
            closeMobileToolTip={closeMobileToolTip}
          />
        )}
      </div>,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }

  return ReactDOM.createPortal(
    <div
      style={
        active
          ? { opacity: 1, transition: `opacity ${fadeDelay}ms ease-in` }
          : { opacity: 0, transition: `opacity ${fadeDelay}ms ease-in` }
      }
    >
      <div
        css={[
          tw`z-50 fixed text-left padding[15px 20px 15px 20px] rounded-10 leading-none`,
          tw`bg-background-inverted text-text-inverted-primary`,
          toolTipStyles,
          styles,
        ]}
      >
        <div data-testid="tooltip-content" tw="relative">
          {content}
        </div>
      </div>
      <div css={arrowStyles} />
    </div>,
    document.getElementById('dialog-root') as HTMLElement,
  );
};

const ToolTip: React.FC<React.PropsWithChildren<ToolTipProps>> = ({
  variant = 'top',
  content,
  children,
  showTooltip = undefined,
  controlled = false,
  styles,
  v2,
  header,
  fadeDelay,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  let timeout: ReturnType<typeof setTimeout>;
  const [active, setActive] = useState(false);
  const isMobile = useMedia(`(max-width: ${theme`screens.mobile.max`})`);
  const shouldRenderTooltip = useDelayedUnmount(active, fadeDelay ?? 0);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 0);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  useEffect(
    () => () => {
      hideTip();
    },
    [],
  );

  useEffect(() => {
    if (typeof showTooltip !== 'undefined') {
      if (showTooltip) showTip();
      else hideTip();
    }
  }, [showTooltip]);

  useEffect(() => {
    if (ref.current && !controlled) {
      ref.current.addEventListener('mouseenter', showTip);
      ref.current.addEventListener('mouseleave', hideTip);
      if (isMobile) {
        ref.current.addEventListener('click', showTip);
      }
    }
    return () => {
      ref.current?.removeEventListener('mouseover', showTip);
      ref.current?.removeEventListener('mouseout', hideTip);
      ref.current?.removeEventListener('click', showTip);
    };
  }, [ref, isMobile]);

  return (
    <>
      {ref.current && content && shouldRenderTooltip && (
        <ToolTipPortal
          active={active}
          content={content}
          component={ref.current}
          variant={variant}
          closeMobileToolTip={hideTip}
          styles={styles}
          fadeDelay={fadeDelay}
          v2={v2}
          header={header}
        />
      )}
      <div ref={ref} tw="w-max" data-testid="tooltip" {...props}>
        {children}
      </div>
    </>
  );
};

export default ToolTip;
