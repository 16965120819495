/* eslint-disable import/prefer-default-export */
import {
  Condition,
  ConditionKey,
  ConditionOperator,
  Contract,
} from '../__generated__/graphql';
import { OrgEmployee } from '../types';

const filterByOperator = (
  operator: ConditionOperator,
  values: string[],
  item: any,
) =>
  operator === ConditionOperator.EQUALS
    ? values.includes(item)
    : !values.includes(item);

const filterRulesByConditionKey = (
  operator: ConditionOperator,
  values: string[],
): Record<ConditionKey, (employee: Contract) => boolean> => ({
  [ConditionKey.COUNTRY]: (employee: Contract): boolean => {
    const { country } = employee;
    return filterByOperator(operator, values, country);
  },
  [ConditionKey.DEPARTMENT]: (employee: Contract): boolean => {
    const { orgAttributes } = employee;
    return filterByOperator(operator, values, orgAttributes?.department?.id);
  },
  [ConditionKey.GENDER]: (employee: Contract): boolean => {
    const { member } = employee;
    return filterByOperator(operator, values, member?.gender);
  },
  [ConditionKey.NAME]: (employee: Contract): boolean => {
    const { member } = employee;
    const fullName = `${member?.firstName ?? ''} ${
      member?.lastName ?? ''
    }`.trim();

    return filterByOperator(operator, values, fullName);
  },
  [ConditionKey.ENTITY]: (employee: Contract): boolean => {
    const { legalEntityId } = employee;
    return filterByOperator(operator, values, legalEntityId);
  },
});

export const filterContractsByConditions = (
  contracts: Contract[],
  conditions: Condition[],
): Contract[] => {
  const filterFunctions = conditions.map(
    (item) =>
      filterRulesByConditionKey(item.operator, item.values ?? [])[item.key],
  );

  return contracts.filter((contract) =>
    filterFunctions.every((f) => f(contract as Contract)),
  );
};

export const filterEmployeesByConditions = (
  employees: OrgEmployee[],
  conditions: Condition[],
): OrgEmployee[] => {
  const filterFunctions = conditions.map(
    (item) =>
      filterRulesByConditionKey(item.operator, item.values ?? [])[item.key],
  );

  return employees.filter((employee) =>
    filterFunctions.every((f) => f(employee.contract as Contract)),
  );
};
