import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FileUpload } from '@multiplier/common';
import tw from 'twin.macro';

import { VerificationChecklistFormValues } from './index';

const FileUploadContainer = tw.div`flex flex-col gap-y-12`;
const FileUploadTitle = tw.h3`text-ps font-medium text-primaryBlue800`;

const FileUploadSection: React.FC = () => {
  const { t } = useTranslation('contract-offboarding');

  const { control } = useFormContext<VerificationChecklistFormValues>();

  return (
    <FileUploadContainer>
      <FileUploadTitle>
        {t(
          'verification-checklist.upload-checklist-documents.title',
          'Upload checklist documents',
        )}
      </FileUploadTitle>
      <Controller
        name="verificationChecklistDocuments"
        control={control}
        render={({ field: { onChange } }) => (
          <FileUpload minimal onChange={(files) => onChange(files)} />
        )}
      />
    </FileUploadContainer>
  );
};

export default FileUploadSection;
