/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import tw from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import { getMonthName } from 'app/utils/get-month-name';
import {
  checkPartnerEorOrHrMember,
  isHrMember,
} from 'performance-reviews/services/partner-eor-and-hr-members';
import timezoneFormat from 'timesheets/services/timezone-format';

import {
  Contract,
  ContractType,
  LegalBindingType,
  Maybe,
  PayFrequency,
  PerformanceReviewNoteType,
  SemimonthlySegment,
  useGetSalaryRevisionNotesLazyQuery,
} from '__generated__/graphql';

import useIsGlobalPayrollEnabled from './is-global-payroll-enabled';

type NoteType = 'fixed-pay' | 'salary';

const BoldText = tw.span`font-semibold`;

const useSalaryRevisionNotes = (
  type: NoteType = 'salary',
): {
  message: React.ReactNode | null;
  cutOffDate?: Date;
  effectiveMonth?: string;
  loading: boolean;
  getNote: (
    contractId: Contract['id'],
    effectiveDate: Maybe<Date | string>,
  ) => void;
} => {
  const { t } = useTranslation('performance-reviews');

  const [
    getPerformanceReviewNotes,
    { data, loading },
  ] = useGetSalaryRevisionNotesLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const isGlobalPayrollEnabled = useIsGlobalPayrollEnabled(
    data?.contract?.legalEntityId ?? '',
  );

  const getNote = async (
    contractId: Contract['id'],
    effectiveDate: Maybe<Date | string>,
  ) => {
    if (!contractId || !effectiveDate) return;
    getPerformanceReviewNotes({
      variables: {
        input: {
          contractId,
          effectiveDate: dateAsString(effectiveDate, 'yyyy-MM-dd'),
        },
        contractId,
      },
    });
  };

  const note = data?.performanceReviewNotes;

  const effectiveMonth = useMemo(() => {
    const monthName = getMonthName(note?.month);
    const currentYear = new Date().getFullYear();
    if (note?.year && currentYear !== note?.year) {
      return `${monthName} ${note.year}`;
    }

    return monthName;
  }, [note?.month, note?.year]);

  const getCutOffDateMessage = (
    payFrequency?: PayFrequency,
    semimonthlySegment?: SemimonthlySegment,
  ) => {
    if (!note) return '';

    const { cutOffDate } = note;
    if (cutOffDate) {
      return timezoneFormat(cutOffDate, 'do MMMM yyyy');
    }

    if (payFrequency === PayFrequency.SEMIMONTHLY) {
      if (semimonthlySegment === SemimonthlySegment.FIRST_HALF) {
        return `${effectiveMonth} first semi-monthly cut-off`;
      }
      return `${effectiveMonth} second semi-monthly cut-off`;
    }
    return `${effectiveMonth} cut-off`;
  };

  const getFixedPayMessage = (): React.ReactNode | null => {
    if (!note) return null;
    const contract = data?.contract;
    const payFrequency = contract?.compensation?.basePay?.paymentFrequency;
    const isParterEorOrHrMember = checkPartnerEorOrHrMember(
      contract as Contract,
    );
    const cutOffDateMessage = getCutOffDateMessage();

    // TODO: Hide fixed-pay message for BIWEEKLY till BE supports logic for it
    if (
      payFrequency === PayFrequency.BIWEEKLY ||
      (isHrMember(contract?.type) && !isGlobalPayrollEnabled)
    ) {
      return null;
    }

    // Effective
    if (note.type === PerformanceReviewNoteType.EFFECTIVE) {
      if (isParterEorOrHrMember) {
        return (
          <Trans
            t={t}
            values={{ effectiveMonth }}
            i18nKey="salary-revision-notes.fixed-pay.effective-eor-partner"
          >
            Updated fixed pay will be applicable from{' '}
            <BoldText>{{ effectiveMonth }}</BoldText> payroll onwards
          </Trans>
        );
      }
      return (
        <Trans
          t={t}
          values={{ effectiveMonth, cutOffDateMessage }}
          i18nKey="salary-revision-notes.fixed-pay.effective"
        >
          Updated fixed pay will be applicable from{' '}
          <BoldText>{{ effectiveMonth }}</BoldText> payroll onwards if the
          addendum is duly signed by all concerned parties before{' '}
          <BoldText>{{ cutOffDateMessage }}</BoldText>
        </Trans>
      );
    }

    // Reflective
    if (isParterEorOrHrMember) {
      return (
        <Trans
          t={t}
          values={{ effectiveMonth }}
          i18nKey="salary-revision-notes.fixed-pay.reflective-eor-partner"
        >
          Arrears for fixed pay will reflect in{' '}
          <BoldText>{{ effectiveMonth }}</BoldText>
        </Trans>
      );
    }
    return (
      <Trans
        t={t}
        values={{ effectiveMonth, cutOffDateMessage }}
        i18nKey="salary-revision-notes.fixed-pay.reflective"
      >
        Arrears for fixed pay will reflect in{' '}
        <BoldText>{{ effectiveMonth }}</BoldText> only if the addendum is signed
        by all the parties before the{' '}
        <BoldText>{{ cutOffDateMessage }}</BoldText>
      </Trans>
    );
  };

  const getSalaryRevisionMessage = (): React.ReactNode | null => {
    const contract = data?.contract;
    const contractType = contract?.type;

    if (!note || (isHrMember(contractType) && !isGlobalPayrollEnabled))
      return null;

    const payFrequency = contract?.compensation?.basePay?.paymentFrequency;
    const { semimonthlySegment, payrollCyclePayDate } = note;

    let message: React.ReactNode;

    const cutOffDateMessage = getCutOffDateMessage(
      payFrequency as PayFrequency,
      semimonthlySegment as SemimonthlySegment,
    );

    // FIXME: Refactor this function later to handle with custom message type (e.g: `[contractType]_[payFrequency]_[noteType]`)
    switch (contractType) {
      case ContractType.EMPLOYEE:
        if (contract?.compliance?.type === LegalBindingType.PARTNER) {
          if (note.type === PerformanceReviewNoteType.EFFECTIVE) {
            // EOR Partner - Effective
            message = (
              <Trans
                t={t}
                values={{
                  effectiveMonth,
                }}
                i18nKey="salary-revision-notes.eor-partner.effective-new"
              >
                Updated compensation will reflect in this{' '}
                <BoldText>{{ effectiveMonth }}</BoldText> payroll, if necessary
                action from all parties is completed. Our team will reach out to
                you for the same.
              </Trans>
            );
          } else {
            // EOR Partner - Reflective
            message = (
              <Trans
                t={t}
                values={{ effectiveMonth }}
                i18nKey="salary-revision-notes.eor-partner.reflective"
              >
                Arrears will reflect in{' '}
                <BoldText>{{ effectiveMonth }}</BoldText> payroll, if necessary
                action from all parties is completed. Our team will reach out to
                you for the same.
              </Trans>
            );
          }
          break;
        }

        if (note.type === PerformanceReviewNoteType.EFFECTIVE) {
          // EOR - Effective
          if (payFrequency === PayFrequency.SEMIMONTHLY) {
            if (semimonthlySegment === SemimonthlySegment.FIRST_HALF) {
              message = (
                <Trans
                  t={t}
                  values={{
                    effectiveMonth,
                    cutOffDateMessage,
                  }}
                  i18nKey="salary-revision-notes.eor-member.effective.semimonthly-first-half"
                >
                  Updated compensation will be applicable from{' '}
                  <BoldText>{{ effectiveMonth }}</BoldText> first semi-monthly
                  payroll onwards if the addendum is duly signed by all
                  concerned parties before{' '}
                  <BoldText>{{ cutOffDateMessage }}</BoldText>
                </Trans>
              );
            } else if (semimonthlySegment === SemimonthlySegment.SECOND_HALF) {
              message = (
                <Trans
                  t={t}
                  values={{
                    effectiveMonth,
                    cutOffDateMessage,
                  }}
                  i18nKey="salary-revision-notes.eor-member.effective.semimonthly-second-half"
                >
                  Updated compensation will be applicable from{' '}
                  <BoldText>{{ effectiveMonth }}</BoldText> second semi-monthly
                  payroll onwards if the addendum is duly signed by all
                  concerned parties before{' '}
                  <BoldText>{{ cutOffDateMessage }}</BoldText>
                </Trans>
              );
            }
          } else if (payFrequency === PayFrequency.BIWEEKLY) {
            if (payrollCyclePayDate) {
              message = (
                <Trans
                  t={t}
                  values={{
                    payrollCyclePayDate: timezoneFormat(
                      payrollCyclePayDate,
                      'do MMMM yyyy',
                    ),
                    cutOffDateMessage,
                  }}
                  i18nKey="salary-revision-notes.eor-member.effective.biweekly-data"
                >
                  Updated compensation will be applicable from{' '}
                  <BoldText>{{ payrollCyclePayDate }}</BoldText> payroll onwards
                  if the addendum is duly signed by all concerned parties before{' '}
                  <BoldText>{{ cutOffDateMessage }}</BoldText>
                </Trans>
              );
            } else {
              message = (
                <Trans
                  t={t}
                  i18nKey="salary-revision-notes.eor-member.effective.biweekly-no-data"
                >
                  Updated compensation will be applicable from the payroll
                  corresponding to effective date onwards, if the addendum is
                  duly signed by all concerned parties before payroll cut off
                  corresponding to the effective date
                </Trans>
              );
            }
          } else {
            message = (
              <Trans
                t={t}
                values={{
                  effectiveMonth,
                  cutOffDateMessage,
                }}
                i18nKey="salary-revision-notes.eor-member.effective.default"
              >
                Updated compensation will be applicable from{' '}
                <BoldText>{{ effectiveMonth }}</BoldText> payroll onwards if the
                addendum is duly signed by all concerned parties before{' '}
                <BoldText>{{ cutOffDateMessage }}</BoldText>
              </Trans>
            );
          }
        } else {
          // EOR - Reflective
          // eslint-disable-next-line no-lonely-if
          if (payFrequency === PayFrequency.SEMIMONTHLY) {
            if (semimonthlySegment === SemimonthlySegment.FIRST_HALF) {
              message = (
                <Trans
                  t={t}
                  values={{
                    effectiveMonth,
                    cutOffDateMessage,
                  }}
                  i18nKey="salary-revision-notes.eor-member.reflective.semimonthly-first-half"
                >
                  Arrears will be paid in the{' '}
                  <BoldText>{{ effectiveMonth }}</BoldText> first semi-monthly
                  payroll if the addendum is duly signed by all concerned
                  parties before <BoldText>{{ cutOffDateMessage }}</BoldText>
                </Trans>
              );
            } else if (semimonthlySegment === SemimonthlySegment.SECOND_HALF) {
              message = (
                <Trans
                  t={t}
                  values={{
                    effectiveMonth,
                    cutOffDateMessage,
                  }}
                  i18nKey="salary-revision-notes.eor-member.reflective.semimonthly-second-half"
                >
                  Arrears will be paid in the{' '}
                  <BoldText>{{ effectiveMonth }}</BoldText> second semi-monthly
                  payroll if the addendum is duly signed by all concerned
                  parties before <BoldText>{{ cutOffDateMessage }}</BoldText>
                </Trans>
              );
            }
          } else if (payFrequency === PayFrequency.BIWEEKLY) {
            if (payrollCyclePayDate) {
              message = (
                <Trans
                  t={t}
                  values={{
                    payrollCyclePayDate: timezoneFormat(
                      payrollCyclePayDate,
                      'do MMMM yyyy',
                    ),
                    cutOffDateMessage,
                  }}
                  i18nKey="salary-revision-notes.eor-member.reflective.biweekly-data"
                >
                  Arrears will be paid in the{' '}
                  <BoldText>{{ payrollCyclePayDate }}</BoldText> payroll if the
                  addendum is duly signed by all concerned parties before{' '}
                  <BoldText>{{ cutOffDateMessage }}</BoldText>
                </Trans>
              );
            } else {
              message = (
                <Trans
                  t={t}
                  i18nKey="salary-revision-notes.eor-member.reflective.biweekly-no-data"
                >
                  Arrears will be paid in next payroll if the addendum is duly
                  signed by all concerned parties before next payroll cut off.
                </Trans>
              );
            }
          } else {
            message = (
              <Trans
                t={t}
                values={{
                  effectiveMonth,
                  cutOffDateMessage,
                }}
                i18nKey="salary-revision-notes.eor-member.reflective.default"
              >
                Arrears will be paid in the{' '}
                <BoldText>{{ effectiveMonth }}</BoldText> payroll if the
                addendum is duly signed by all concerned parties before{' '}
                <BoldText>{{ cutOffDateMessage }}</BoldText>
              </Trans>
            );
          }
        }
        break;
      case ContractType.HR_MEMBER:
        if (note.type === PerformanceReviewNoteType.EFFECTIVE) {
          message = (
            <Trans
              t={t}
              values={{ effectiveMonth }}
              i18nKey="salary-revision-notes.hr-member.effective"
            >
              Updated compensation will be applicable from{' '}
              <BoldText>{{ effectiveMonth }}</BoldText> payroll onwards
            </Trans>
          );
        } else {
          message = (
            <Trans
              t={t}
              values={{ effectiveMonth }}
              i18nKey="salary-revision-notes.hr-member.reflective"
            >
              Arrears will be paid in <BoldText>{{ effectiveMonth }}</BoldText>{' '}
              payroll onwards
            </Trans>
          );
        }
        break;
      default:
        break;
    }

    return message;
  };

  const message = useMemo(() => {
    if (!data) return null;
    return type === 'fixed-pay'
      ? getFixedPayMessage()
      : getSalaryRevisionMessage();
  }, [data, type, isGlobalPayrollEnabled]);

  return {
    getNote,
    message,
    effectiveMonth,
    cutOffDate: data?.performanceReviewNotes?.cutOffDate,
    loading,
  };
};

export default useSalaryRevisionNotes;
