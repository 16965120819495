import React, { useMemo } from 'react';
import 'twin.macro';

import { useTranslation } from 'react-i18next';

import { CardSeparator } from '@multiplier/common';
import { Dictionary } from 'lodash';

import {
  ContractOnboarding,
  ContractType,
  CountryWorkStatus,
  LegalBindingType,
  Maybe,
  OnboardingTask,
} from '../../../__generated__/graphql';
import useTaskAssignee from '../../hooks/task-assignee';
import { TaskEntryWrapper, isOptionalTask } from './tasks';
import { TaskType } from './types';

const PendingTaskGroup: React.FC<{
  contractId: string;
  groupedTasks: OnboardingTask[];
  groupActionable: boolean;
  handleCloseSidePanel: () => void;
  unprocessedTasks: ContractOnboarding['tasks'];
  complianceType?: Maybe<LegalBindingType>;
  workStatus?: Maybe<CountryWorkStatus>;
  contractType?: Maybe<ContractType>;
  viewOnly?: boolean;
}> = ({
  groupedTasks,
  contractId,
  groupActionable,
  handleCloseSidePanel,
  unprocessedTasks,
  complianceType,
  workStatus,
  contractType,
  viewOnly,
}) => {
  const { t } = useTranslation('contract-onboarding');

  const pendingOn = useTaskAssignee(groupedTasks[0]);

  if (groupedTasks?.length === 0) return null;

  const sendContractTaskCompleted = useMemo(
    () =>
      unprocessedTasks
        ?.filter((task) => task.name === TaskType.SEND_CONTRACT)
        .every((sendContractTask) => sendContractTask.completed) ?? false,
    [unprocessedTasks],
  );

  return (
    <div tw="flex flex-col">
      <div tw="flex flex-col gap-y-base p-base">
        <p>
          {t('onboarding-checklist.task-list.pending-on', {
            defaultValue: 'Pending on {{ pendingOn }}',
            replace: { pendingOn },
          })}
        </p>
        {groupedTasks.map((task, index) => {
          const ifSecondTaskAndFirstTaskIsOptionalElseFirstTask =
            (index === 1 && isOptionalTask(groupedTasks[index - 1])) ||
            index === 0;

          const actionable =
            task.name === TaskType.PAY_DEPOSIT
              ? sendContractTaskCompleted
              : ifSecondTaskAndFirstTaskIsOptionalElseFirstTask &&
                groupActionable;

          const isResendInviteVisible =
            contractType === ContractType.EMPLOYEE
              ? workStatus === CountryWorkStatus.RESIDENT &&
                complianceType === LegalBindingType.MULTIPLIER
              : true;

          return (
            <TaskEntryWrapper
              actionable={viewOnly ? false : actionable}
              contractId={contractId}
              key={`${task.name}${task.pendingOn?.id}`}
              task={task}
              handleCloseSidePanel={handleCloseSidePanel}
              isResendInviteVisible={isResendInviteVisible}
            />
          );
        })}
      </div>
      <CardSeparator />
    </div>
  );
};

const getNextTaskPendingOnExcludingOptionalTasks = (
  taskList: Dictionary<OnboardingTask[]>,
) =>
  Object.entries(taskList).find(([, groupedTasks]) =>
    groupedTasks
      .filter((task) => !isOptionalTask(task))
      .some((task) => !task.completed),
  ) ?? [];

const PendingTaskSection: React.FC<{
  contractId: string;
  taskList: Dictionary<OnboardingTask[]> | undefined;
  unprocessedTasks: ContractOnboarding['tasks'];
  handleCloseSidePanel: () => void;
  complianceType?: Maybe<LegalBindingType>;
  workStatus?: Maybe<CountryWorkStatus>;
  contractType?: Maybe<ContractType>;
  viewOnly?: boolean;
}> = ({
  taskList,
  unprocessedTasks,
  contractId,
  handleCloseSidePanel,
  complianceType,
  workStatus,
  contractType,
  viewOnly = false,
}) => {
  if (!taskList) return null;

  const [nextTaskPendingOn] = getNextTaskPendingOnExcludingOptionalTasks(
    taskList,
  );

  return (
    <>
      {Object.entries(taskList).map(([personId, groupedTasks]) => (
        <PendingTaskGroup
          key={personId}
          groupedTasks={groupedTasks}
          contractId={contractId}
          groupActionable={personId === nextTaskPendingOn}
          handleCloseSidePanel={handleCloseSidePanel}
          unprocessedTasks={unprocessedTasks}
          complianceType={complianceType}
          workStatus={workStatus}
          contractType={contractType}
          viewOnly={viewOnly}
        />
      ))}
    </>
  );
};

export default PendingTaskSection;
