import { CountryCode } from '__generated__/graphql';

import i18n from '../../i18n';

const countryLabels: { [key: string]: string } = {
  [CountryCode.SGP]: 'Singapore',
  [CountryCode.AGO]: 'Angola',
  [CountryCode.USA]: 'United States of America',
  [CountryCode.IND]: 'India',
  [CountryCode.LKA]: 'Sri Lanka',
};

// https://github.com/i18next/react-i18next/issues/909
const fill = () => {
  countryLabels[CountryCode.ABW] = i18n.t('common:country.abw', 'Aruba');
  countryLabels[CountryCode.AFG] = i18n.t('common:country.afg', 'Afghanistan');
  countryLabels[CountryCode.AGO] = i18n.t('common:country.ago', 'Angola');
  countryLabels[CountryCode.AIA] = i18n.t('common:country.aia', 'Anguilla');
  countryLabels[CountryCode.ALA] = i18n.t(
    'common:country.ala',
    'Åland Islands',
  );
  countryLabels[CountryCode.ALB] = i18n.t('common:country.alb', 'Albania');
  countryLabels[CountryCode.AND] = i18n.t('common:country.and', 'Andorra');
  countryLabels[CountryCode.ARE] = i18n.t(
    'common:country.are',
    'United Arab Emirates',
  );
  countryLabels[CountryCode.ARG] = i18n.t('common:country.arg', 'Argentina');
  countryLabels[CountryCode.ARM] = i18n.t('common:country.arm', 'Armenia');
  countryLabels[CountryCode.ASM] = i18n.t(
    'common:country.asm',
    'American Samoa',
  );
  countryLabels[CountryCode.ATA] = i18n.t('common:country.ata', 'Antarctica');
  countryLabels[CountryCode.ATF] = i18n.t(
    'common:country.atf',
    'French Southern Territories',
  );
  countryLabels[CountryCode.ATG] = i18n.t(
    'common:country.atg',
    'Antigua and Barbuda',
  );
  countryLabels[CountryCode.AUS] = i18n.t('common:country.aus', 'Australia');
  countryLabels[CountryCode.AUT] = i18n.t('common:country.aut', 'Austria');
  countryLabels[CountryCode.AZE] = i18n.t('common:country.aze', 'Azerbaijan');
  countryLabels[CountryCode.BDI] = i18n.t('common:country.bdi', 'Burundi');
  countryLabels[CountryCode.BEL] = i18n.t('common:country.bel', 'Belgium');
  countryLabels[CountryCode.BEN] = i18n.t('common:country.ben', 'Benin');
  countryLabels[CountryCode.BES] = i18n.t(
    'common:country.bes',
    'Bonaire, Sint Eustatius and Saba',
  );
  countryLabels[CountryCode.BFA] = i18n.t('common:country.bfa', 'Burkina Faso');
  countryLabels[CountryCode.BGD] = i18n.t('common:country.bgd', 'Bangladesh');
  countryLabels[CountryCode.BGR] = i18n.t('common:country.bgr', 'Bulgaria');
  countryLabels[CountryCode.BHR] = i18n.t('common:country.bhr', 'Bahrain');
  countryLabels[CountryCode.BHS] = i18n.t('common:country.bhs', 'Bahamas');
  countryLabels[CountryCode.BIH] = i18n.t(
    'common:country.bih',
    'Bosnia and Herzegovina',
  );
  countryLabels[CountryCode.BLM] = i18n.t(
    'common:country.blm',
    'Saint Barthélemy',
  );
  countryLabels[CountryCode.BLR] = i18n.t('common:country.blr', 'Belarus');
  countryLabels[CountryCode.BLZ] = i18n.t('common:country.blz', 'Belize');
  countryLabels[CountryCode.BMU] = i18n.t('common:country.bmu', 'Bermuda');
  countryLabels[CountryCode.BOL] = i18n.t(
    'common:country.bol',
    'Bolivia (Plurinational State of)',
  );
  countryLabels[CountryCode.BRA] = i18n.t('common:country.bra', 'Brazil');
  countryLabels[CountryCode.BRB] = i18n.t('common:country.brb', 'Barbados');
  countryLabels[CountryCode.BRN] = i18n.t(
    'common:country.brn',
    'Brunei Darussalam',
  );
  countryLabels[CountryCode.BTN] = i18n.t('common:country.btn', 'Bhutan');
  countryLabels[CountryCode.BVT] = i18n.t(
    'common:country.bvt',
    'Bouvet Island',
  );
  countryLabels[CountryCode.BWA] = i18n.t('common:country.bwa', 'Botswana');
  countryLabels[CountryCode.CAF] = i18n.t(
    'common:country.caf',
    'Central African Republic',
  );
  countryLabels[CountryCode.CAN] = i18n.t('common:country.can', 'Canada');
  countryLabels[CountryCode.CCK] = i18n.t(
    'common:country.cck',
    'Cocos (Keeling) Islands',
  );
  countryLabels[CountryCode.CHE] = i18n.t('common:country.che', 'Switzerland');
  countryLabels[CountryCode.CHL] = i18n.t('common:country.chl', 'Chile');
  countryLabels[CountryCode.CHN] = i18n.t('common:country.chn', 'China');
  countryLabels[CountryCode.CIV] = i18n.t(
    'common:country.civ',
    "Côte d'Ivoire",
  );
  countryLabels[CountryCode.CMR] = i18n.t('common:country.cmr', 'Cameroon');
  countryLabels[CountryCode.COD] = i18n.t(
    'common:country.cod',
    'Congo (the Democratic Republic of the)',
  );
  countryLabels[CountryCode.COG] = i18n.t('common:country.cog', 'Congo');
  countryLabels[CountryCode.COK] = i18n.t('common:country.cok', 'Cook Islands');
  countryLabels[CountryCode.COL] = i18n.t('common:country.col', 'Colombia');
  countryLabels[CountryCode.COM] = i18n.t('common:country.com', 'Comoros');
  countryLabels[CountryCode.CPV] = i18n.t('common:country.cpv', 'Cabo Verde');
  countryLabels[CountryCode.CRI] = i18n.t('common:country.cri', 'Costa Rica');
  countryLabels[CountryCode.CUB] = i18n.t('common:country.cub', 'Cuba');
  countryLabels[CountryCode.CUW] = i18n.t('common:country.cuw', 'Curaçao');
  countryLabels[CountryCode.CXR] = i18n.t(
    'common:country.cxr',
    'Christmas Island',
  );
  countryLabels[CountryCode.CYM] = i18n.t(
    'common:country.cym',
    'Cayman Islands',
  );
  countryLabels[CountryCode.CYP] = i18n.t('common:country.cyp', 'Cyprus');
  countryLabels[CountryCode.CZE] = i18n.t('common:country.cze', 'Czechia');
  countryLabels[CountryCode.DEU] = i18n.t('common:country.deu', 'Germany');
  countryLabels[CountryCode.DJI] = i18n.t('common:country.dji', 'Djibouti');
  countryLabels[CountryCode.DMA] = i18n.t('common:country.dma', 'Dominica');
  countryLabels[CountryCode.DNK] = i18n.t('common:country.dnk', 'Denmark');
  countryLabels[CountryCode.DOM] = i18n.t(
    'common:country.dom',
    'Dominican Republic',
  );
  countryLabels[CountryCode.DZA] = i18n.t('common:country.dza', 'Algeria');
  countryLabels[CountryCode.ECU] = i18n.t('common:country.ecu', 'Ecuador');
  countryLabels[CountryCode.EGY] = i18n.t('common:country.egy', 'Egypt');
  countryLabels[CountryCode.ERI] = i18n.t('common:country.eri', 'Eritrea');
  countryLabels[CountryCode.ESH] = i18n.t(
    'common:country.esh',
    'Western Sahara',
  );
  countryLabels[CountryCode.ESP] = i18n.t('common:country.esp', 'Spain');
  countryLabels[CountryCode.EST] = i18n.t('common:country.est', 'Estonia');
  countryLabels[CountryCode.ETH] = i18n.t('common:country.eth', 'Ethiopia');
  countryLabels[CountryCode.FIN] = i18n.t('common:country.fin', 'Finland');
  countryLabels[CountryCode.FJI] = i18n.t('common:country.fji', 'Fiji');
  countryLabels[CountryCode.FLK] = i18n.t(
    'common:country.flk',
    'Falkland Islands [Malvinas]',
  );
  countryLabels[CountryCode.FRA] = i18n.t('common:country.fra', 'France');
  countryLabels[CountryCode.FRO] = i18n.t(
    'common:country.fro',
    'Faroe Islands',
  );
  countryLabels[CountryCode.FSM] = i18n.t(
    'common:country.fsm',
    'Micronesia (Federated States of)',
  );
  countryLabels[CountryCode.GAB] = i18n.t('common:country.gab', 'Gabon');
  countryLabels[CountryCode.GBR] = i18n.t(
    'common:country.gbr',
    'United Kingdom of Great Britain and Northern Ireland',
  );
  countryLabels[CountryCode.GEO] = i18n.t('common:country.geo', 'Georgia');
  countryLabels[CountryCode.GGY] = i18n.t('common:country.ggy', 'Guernsey');
  countryLabels[CountryCode.GHA] = i18n.t('common:country.gha', 'Ghana');
  countryLabels[CountryCode.GIB] = i18n.t('common:country.gib', 'Gibraltar');
  countryLabels[CountryCode.GIN] = i18n.t('common:country.gin', 'Guinea');
  countryLabels[CountryCode.GLP] = i18n.t('common:country.glp', 'Guadeloupe');
  countryLabels[CountryCode.GMB] = i18n.t('common:country.gmb', 'Gambia');
  countryLabels[CountryCode.GNB] = i18n.t(
    'common:country.gnb',
    'Guinea-Bissau',
  );
  countryLabels[CountryCode.GNQ] = i18n.t(
    'common:country.gnq',
    'Equatorial Guinea',
  );
  countryLabels[CountryCode.GRC] = i18n.t('common:country.grc', 'Greece');
  countryLabels[CountryCode.GRD] = i18n.t('common:country.grd', 'Grenada');
  countryLabels[CountryCode.GRL] = i18n.t('common:country.grl', 'Greenland');
  countryLabels[CountryCode.GTM] = i18n.t('common:country.gtm', 'Guatemala');
  countryLabels[CountryCode.GUF] = i18n.t(
    'common:country.guf',
    'French Guiana',
  );
  countryLabels[CountryCode.GUM] = i18n.t('common:country.gum', 'Guam');
  countryLabels[CountryCode.GUY] = i18n.t('common:country.guy', 'Guyana');
  countryLabels[CountryCode.HKG] = i18n.t('common:country.hkg', 'Hong Kong');
  countryLabels[CountryCode.HMD] = i18n.t(
    'common:country.hmd',
    'Heard Island and McDonald Islands',
  );
  countryLabels[CountryCode.HND] = i18n.t('common:country.hnd', 'Honduras');
  countryLabels[CountryCode.HRV] = i18n.t('common:country.hrv', 'Croatia');
  countryLabels[CountryCode.HTI] = i18n.t('common:country.hti', 'Haiti');
  countryLabels[CountryCode.HUN] = i18n.t('common:country.hun', 'Hungary');
  countryLabels[CountryCode.IDN] = i18n.t('common:country.idn', 'Indonesia');
  countryLabels[CountryCode.IMN] = i18n.t('common:country.imn', 'Isle of Man');
  countryLabels[CountryCode.IND] = i18n.t('common:country.ind', 'India');
  countryLabels[CountryCode.IOT] = i18n.t(
    'common:country.iot',
    'British Indian Ocean Territory',
  );
  countryLabels[CountryCode.IRL] = i18n.t('common:country.irl', 'Ireland');
  countryLabels[CountryCode.IRN] = i18n.t(
    'common:country.irn',
    'Iran (Islamic Republic of)',
  );
  countryLabels[CountryCode.IRQ] = i18n.t('common:country.irq', 'Iraq');
  countryLabels[CountryCode.ISL] = i18n.t('common:country.isl', 'Iceland');
  countryLabels[CountryCode.ISR] = i18n.t('common:country.isr', 'Israel');
  countryLabels[CountryCode.ITA] = i18n.t('common:country.ita', 'Italy');
  countryLabels[CountryCode.JAM] = i18n.t('common:country.jam', 'Jamaica');
  countryLabels[CountryCode.JEY] = i18n.t('common:country.jey', 'Jersey');
  countryLabels[CountryCode.JOR] = i18n.t('common:country.jor', 'Jordan');
  countryLabels[CountryCode.JPN] = i18n.t('common:country.jpn', 'Japan');
  countryLabels[CountryCode.KAZ] = i18n.t('common:country.kaz', 'Kazakhstan');
  countryLabels[CountryCode.KEN] = i18n.t('common:country.ken', 'Kenya');
  countryLabels[CountryCode.KGZ] = i18n.t('common:country.kgz', 'Kyrgyzstan');
  countryLabels[CountryCode.KHM] = i18n.t('common:country.khm', 'Cambodia');
  countryLabels[CountryCode.KIR] = i18n.t('common:country.kir', 'Kiribati');
  countryLabels[CountryCode.KNA] = i18n.t(
    'common:country.kna',
    'Saint Kitts and Nevis',
  );
  countryLabels[CountryCode.KOR] = i18n.t(
    'common:country.kor',
    'Korea (the Republic of)',
  );
  countryLabels[CountryCode.KWT] = i18n.t('common:country.kwt', 'Kuwait');
  countryLabels[CountryCode.LAO] = i18n.t('common:country.lao-alt', {
    defaultValue: 'Lao People\u2019s Democratic Republic',
    interpolation: { escapeValue: false },
  });
  countryLabels[CountryCode.LBN] = i18n.t('common:country.lbn', 'Lebanon');
  countryLabels[CountryCode.LBR] = i18n.t('common:country.lbr', 'Liberia');
  countryLabels[CountryCode.LBY] = i18n.t('common:country.lby', 'Libya');
  countryLabels[CountryCode.LCA] = i18n.t('common:country.lca', 'Saint Lucia');
  countryLabels[CountryCode.LIE] = i18n.t(
    'common:country.lie',
    'Liechtenstein',
  );
  countryLabels[CountryCode.LKA] = i18n.t('common:country.lka', 'Sri Lanka');
  countryLabels[CountryCode.LSO] = i18n.t('common:country.lso', 'Lesotho');
  countryLabels[CountryCode.LTU] = i18n.t('common:country.ltu', 'Lithuania');
  countryLabels[CountryCode.LUX] = i18n.t('common:country.lux', 'Luxembourg');
  countryLabels[CountryCode.LVA] = i18n.t('common:country.lva', 'Latvia');
  countryLabels[CountryCode.MAC] = i18n.t('common:country.mac', 'Macao');
  countryLabels[CountryCode.MAF] = i18n.t(
    'common:country.maf',
    'Saint Martin (French part)',
  );
  countryLabels[CountryCode.MAR] = i18n.t('common:country.mar', 'Morocco');
  countryLabels[CountryCode.MCO] = i18n.t('common:country.mco', 'Monaco');
  countryLabels[CountryCode.MDA] = i18n.t(
    'common:country.mda',
    'Moldova (the Republic of)',
  );
  countryLabels[CountryCode.MDG] = i18n.t('common:country.mdg', 'Madagascar');
  countryLabels[CountryCode.MDV] = i18n.t('common:country.mdv', 'Maldives');
  countryLabels[CountryCode.MEX] = i18n.t('common:country.mex', 'Mexico');
  countryLabels[CountryCode.MHL] = i18n.t(
    'common:country.mhl',
    'Marshall Islands',
  );
  countryLabels[CountryCode.MKD] = i18n.t(
    'common:country.mkd',
    'Republic of North Macedonia',
  );
  countryLabels[CountryCode.MLI] = i18n.t('common:country.mli', 'Mali');
  countryLabels[CountryCode.MLT] = i18n.t('common:country.mlt', 'Malta');
  countryLabels[CountryCode.MMR] = i18n.t('common:country.mmr', 'Myanmar');
  countryLabels[CountryCode.MNE] = i18n.t('common:country.mne', 'Montenegro');
  countryLabels[CountryCode.MNG] = i18n.t('common:country.mng', 'Mongolia');
  countryLabels[CountryCode.MNP] = i18n.t(
    'common:country.mnp',
    'Northern Mariana Islands',
  );
  countryLabels[CountryCode.MOZ] = i18n.t('common:country.moz', 'Mozambique');
  countryLabels[CountryCode.MRT] = i18n.t('common:country.mrt', 'Mauritania');
  countryLabels[CountryCode.MSR] = i18n.t('common:country.msr', 'Montserrat');
  countryLabels[CountryCode.MTQ] = i18n.t('common:country.mtq', 'Martinique');
  countryLabels[CountryCode.MUS] = i18n.t('common:country.mus', 'Mauritius');
  countryLabels[CountryCode.MWI] = i18n.t('common:country.mwi', 'Malawi');
  countryLabels[CountryCode.MYS] = i18n.t('common:country.mys', 'Malaysia');
  countryLabels[CountryCode.MYT] = i18n.t('common:country.myt', 'Mayotte');
  countryLabels[CountryCode.NAM] = i18n.t('common:country.nam', 'Namibia');
  countryLabels[CountryCode.NCL] = i18n.t(
    'common:country.ncl',
    'New Caledonia',
  );
  countryLabels[CountryCode.NER] = i18n.t('common:country.ner', 'Niger');
  countryLabels[CountryCode.NFK] = i18n.t(
    'common:country.nfk',
    'Norfolk Island',
  );
  countryLabels[CountryCode.NGA] = i18n.t('common:country.nga', 'Nigeria');
  countryLabels[CountryCode.NIC] = i18n.t('common:country.nic', 'Nicaragua');
  countryLabels[CountryCode.NIU] = i18n.t('common:country.niu', 'Niue');
  countryLabels[CountryCode.NLD] = i18n.t('common:country.nld', 'Netherlands');
  countryLabels[CountryCode.NOR] = i18n.t('common:country.nor', 'Norway');
  countryLabels[CountryCode.NPL] = i18n.t('common:country.npl', 'Nepal');
  countryLabels[CountryCode.NRU] = i18n.t('common:country.nru', 'Nauru');
  countryLabels[CountryCode.NZL] = i18n.t('common:country.nzl', 'New Zealand');
  countryLabels[CountryCode.OMN] = i18n.t('common:country.omn', 'Oman');
  countryLabels[CountryCode.PAK] = i18n.t('common:country.pak', 'Pakistan');
  countryLabels[CountryCode.PAN] = i18n.t('common:country.pan', 'Panama');
  countryLabels[CountryCode.PCN] = i18n.t('common:country.pcn', 'Pitcairn');
  countryLabels[CountryCode.PER] = i18n.t('common:country.per', 'Peru');
  countryLabels[CountryCode.PHL] = i18n.t('common:country.phl', 'Philippines');
  countryLabels[CountryCode.PLW] = i18n.t('common:country.plw', 'Palau');
  countryLabels[CountryCode.PNG] = i18n.t(
    'common:country.png',
    'Papua New Guinea',
  );
  countryLabels[CountryCode.POL] = i18n.t('common:country.pol', 'Poland');
  countryLabels[CountryCode.PRI] = i18n.t('common:country.pri', 'Puerto Rico');
  countryLabels[CountryCode.PRK] = i18n.t(
    'common:country.prk',
    "Korea (the Democratic People's Republic of)",
  );
  countryLabels[CountryCode.PRT] = i18n.t('common:country.prt', 'Portugal');
  countryLabels[CountryCode.PRY] = i18n.t('common:country.pry', 'Paraguay');
  countryLabels[CountryCode.PSE] = i18n.t(
    'common:country.pse',
    'Palestine, State of',
  );
  countryLabels[CountryCode.PYF] = i18n.t(
    'common:country.pyf',
    'French Polynesia',
  );
  countryLabels[CountryCode.QAT] = i18n.t('common:country.qat', 'Qatar');
  countryLabels[CountryCode.REU] = i18n.t('common:country.reu', 'Réunion');
  countryLabels[CountryCode.ROU] = i18n.t('common:country.rou', 'Romania');
  countryLabels[CountryCode.RUS] = i18n.t(
    'common:country.rus',
    'Russian Federation',
  );
  countryLabels[CountryCode.RWA] = i18n.t('common:country.rwa', 'Rwanda');
  countryLabels[CountryCode.SAU] = i18n.t('common:country.sau', 'Saudi Arabia');
  countryLabels[CountryCode.SDN] = i18n.t('common:country.sdn', 'Sudan');
  countryLabels[CountryCode.SEN] = i18n.t('common:country.sen', 'Senegal');
  countryLabels[CountryCode.SGP] = i18n.t('common:country.sgp', 'Singapore');
  countryLabels[CountryCode.SGS] = i18n.t(
    'common:country.sgs',
    'South Georgia and the South Sandwich Islands',
  );
  countryLabels[CountryCode.SHN] = i18n.t(
    'common:country.shn',
    'Saint Helena, Ascension and Tristan da Cunha',
  );
  countryLabels[CountryCode.SJM] = i18n.t(
    'common:country.sjm',
    'Svalbard and Jan Mayen',
  );
  countryLabels[CountryCode.SLB] = i18n.t(
    'common:country.slb',
    'Solomon Islands',
  );
  countryLabels[CountryCode.SLE] = i18n.t('common:country.sle', 'Sierra Leone');
  countryLabels[CountryCode.SLV] = i18n.t('common:country.slv', 'El Salvador');
  countryLabels[CountryCode.SMR] = i18n.t('common:country.smr', 'San Marino');
  countryLabels[CountryCode.SOM] = i18n.t('common:country.som', 'Somalia');
  countryLabels[CountryCode.SPM] = i18n.t(
    'common:country.spm',
    'Saint Pierre and Miquelon',
  );
  countryLabels[CountryCode.SRB] = i18n.t('common:country.srb', 'Serbia');
  countryLabels[CountryCode.SSD] = i18n.t('common:country.ssd', 'South Sudan');
  countryLabels[CountryCode.STP] = i18n.t(
    'common:country.stp',
    'Sao Tome and Principe',
  );
  countryLabels[CountryCode.SUR] = i18n.t('common:country.sur', 'Suriname');
  countryLabels[CountryCode.SVK] = i18n.t('common:country.svk', 'Slovakia');
  countryLabels[CountryCode.SVN] = i18n.t('common:country.svn', 'Slovenia');
  countryLabels[CountryCode.SWE] = i18n.t('common:country.swe', 'Sweden');
  countryLabels[CountryCode.SWZ] = i18n.t('common:country.swz', 'Eswatini');
  countryLabels[CountryCode.SXM] = i18n.t(
    'common:country.sxm',
    'Sint Maarten (Dutch part)',
  );
  countryLabels[CountryCode.SYC] = i18n.t('common:country.syc', 'Seychelles');
  countryLabels[CountryCode.SYR] = i18n.t(
    'common:country.syr',
    'Syrian Arab Republic',
  );
  countryLabels[CountryCode.TCA] = i18n.t(
    'common:country.tca',
    'Turks and Caicos Islands',
  );
  countryLabels[CountryCode.TCD] = i18n.t('common:country.tcd', 'Chad');
  countryLabels[CountryCode.TGO] = i18n.t('common:country.tgo', 'Togo');
  countryLabels[CountryCode.THA] = i18n.t('common:country.tha', 'Thailand');
  countryLabels[CountryCode.TJK] = i18n.t('common:country.tjk', 'Tajikistan');
  countryLabels[CountryCode.TKL] = i18n.t('common:country.tkl', 'Tokelau');
  countryLabels[CountryCode.TKM] = i18n.t('common:country.tkm', 'Turkmenistan');
  countryLabels[CountryCode.TLS] = i18n.t('common:country.tls', 'Timor-Leste');
  countryLabels[CountryCode.TON] = i18n.t('common:country.ton', 'Tonga');
  countryLabels[CountryCode.TTO] = i18n.t(
    'common:country.tto',
    'Trinidad and Tobago',
  );
  countryLabels[CountryCode.TUN] = i18n.t('common:country.tun', 'Tunisia');
  countryLabels[CountryCode.TUR] = i18n.t('common:country.tur', 'Turkey');
  countryLabels[CountryCode.TUV] = i18n.t('common:country.tuv', 'Tuvalu');
  countryLabels[CountryCode.TWN] = i18n.t(
    'common:country.twn',
    'Taiwan (Province of China)',
  );
  countryLabels[CountryCode.TZA] = i18n.t(
    'common:country.tza',
    'Tanzania, United Republic of',
  );
  countryLabels[CountryCode.UGA] = i18n.t('common:country.uga', 'Uganda');
  countryLabels[CountryCode.UKR] = i18n.t('common:country.ukr', 'Ukraine');
  countryLabels[CountryCode.UMI] = i18n.t(
    'common:country.umi',
    'United States Minor Outlying Islands',
  );
  countryLabels[CountryCode.URY] = i18n.t('common:country.ury', 'Uruguay');
  countryLabels[CountryCode.USA] = i18n.t(
    'common:country.usa',
    'United States of America',
  );
  countryLabels[CountryCode.UZB] = i18n.t('common:country.uzb', 'Uzbekistan');
  countryLabels[CountryCode.VAT] = i18n.t('common:country.vat', 'Holy See');
  countryLabels[CountryCode.VCT] = i18n.t(
    'common:country.vct',
    'Saint Vincent and the Grenadines',
  );
  countryLabels[CountryCode.VEN] = i18n.t(
    'common:country.ven',
    'Venezuela (Bolivarian Republic of)',
  );
  countryLabels[CountryCode.VGB] = i18n.t(
    'common:country.vgb',
    'Virgin Islands (British)',
  );
  countryLabels[CountryCode.VIR] = i18n.t(
    'common:country.vir',
    'Virgin Islands (U.S.)',
  );
  countryLabels[CountryCode.VNM] = i18n.t('common:country.vnm', 'Viet Nam');
  countryLabels[CountryCode.VUT] = i18n.t('common:country.vut', 'Vanuatu');
  countryLabels[CountryCode.WLF] = i18n.t(
    'common:country.wlf',
    'Wallis and Futuna',
  );
  countryLabels[CountryCode.WSM] = i18n.t('common:country.wsm', 'Samoa');
  countryLabels[CountryCode.YEM] = i18n.t('common:country.yem', 'Yemen');
  countryLabels[CountryCode.ZAF] = i18n.t('common:country.zaf', 'South Africa');
  countryLabels[CountryCode.ZMB] = i18n.t('common:country.zmb', 'Zambia');
  countryLabels[CountryCode.ZWE] = i18n.t('common:country.zwe', 'Zimbabwe');
};

i18n.on('languageChanged init', () => {
  fill();
});

export default countryLabels;
