import React, { HTMLAttributes } from 'react';

import tw, { TwStyle } from 'twin.macro';

import ToolTip from '../tooltip';
import { ProgressChildren, ProgressLine, ProgressNodeIcon } from './components';
import { StepperPosition, StepperType } from './types';

const VerticalProgressNode: React.FC<
  {
    renderNodeIcon?: (type: StepperType) => React.ReactNode;
    iconType?: StepperType;
    nodeColor?: StepperType;
    onClick?: () => void;
    active?: boolean;
    linePosition?: StepperPosition;
    lineColor?: StepperType;
    childPosition?: StepperPosition;
    children?: React.ReactNode;
    showLine?: boolean;
    lineStyles?: TwStyle;
    nodeStyles?: TwStyle;
    tooltip?: string;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  iconType = StepperType.PROGRESS,
  nodeColor = StepperType.PROGRESS,
  onClick,
  active = false,
  linePosition = StepperPosition.BOTTOM,
  childPosition = StepperPosition.RIGHT,
  lineColor = StepperType.PROGRESS,
  showLine = true,
  lineStyles,
  nodeStyles,
  children,
  renderNodeIcon,
  tooltip,
  ...props
}) => (
  <div
    data-testid="progress-node"
    css={[
      tw`relative`,
      [StepperPosition.TOP, StepperPosition.BOTTOM].includes(childPosition) &&
        tw`flex flex-col gap-y-small`,
      [StepperPosition.LEFT, StepperPosition.RIGHT].includes(childPosition) &&
        tw`flex flex-row gap-x-small`,
    ]}
    {...props}
  >
    {[StepperPosition.TOP, StepperPosition.LEFT].includes(childPosition) && (
      <ProgressChildren>{children}</ProgressChildren>
    )}
    <div
      tw="flex items-center"
      css={[
        [StepperPosition.LEFT, StepperPosition.RIGHT].includes(linePosition) &&
          tw`flex-row`,
        [StepperPosition.TOP, StepperPosition.BOTTOM].includes(linePosition) &&
          tw`flex-col`,
      ]}
    >
      {showLine &&
        [StepperPosition.LEFT, StepperPosition.TOP].includes(linePosition) && (
          <ProgressLine
            color={lineColor}
            position={linePosition}
            style={lineStyles}
          />
        )}
      <ToolTip
        variant="bottom"
        tw="w-full z-index[2]"
        content={
          tooltip ? (
            <div tw="max-width[150px] [word-wrap: break-word] text-ps">
              {tooltip}
            </div>
          ) : null
        }
      >
        <ProgressNodeIcon
          iconType={iconType}
          color={nodeColor}
          active={active}
          onClick={onClick}
          style={nodeStyles}
          renderIcon={renderNodeIcon}
        />
      </ToolTip>

      {showLine &&
        [StepperPosition.RIGHT, StepperPosition.BOTTOM].includes(
          linePosition,
        ) && <ProgressLine color={lineColor} position={linePosition} />}
    </div>
    {[StepperPosition.BOTTOM, StepperPosition.RIGHT].includes(
      childPosition,
    ) && <ProgressChildren>{children}</ProgressChildren>}
  </div>
);

export default VerticalProgressNode;
