import React, { useContext, useEffect } from 'react';

import tw, { css, theme } from 'twin.macro';

import { ThemeContext } from '../theme-provider';
import CheckIcon from './assets/check.svg';

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  checkboxSize?: 'small' | 'large';
  indeterminate?: boolean;
  shouldRenderIndeterminateState?: boolean;
}

const variantStyles = {
  small: tw`w-[22px] h-[22px]`,
  large: tw`w-[28px] h-[28px]`,
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checkboxSize = 'large',
      indeterminate = false,
      shouldRenderIndeterminateState = false,
      ...props
    },
    ref,
  ) => {
    const { isNewThemeApplied } = useContext(ThemeContext);
    const defaultRef = React.useRef(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (
        resolvedRef &&
        'current' in resolvedRef &&
        resolvedRef.current &&
        shouldRenderIndeterminateState
      ) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate, shouldRenderIndeterminateState]);

    return (
      <input
        css={[
          variantStyles[checkboxSize],
          tw`rounded-tiny focus:ring-transparent focus:ring-offset-0 ring-offset-transparent mr-small cursor-pointer disabled:cursor-not-allowed appearance-none!`,
          // color
          !isNewThemeApplied
            ? tw`text-background-action`
            : tw`text-background-brand-subtle`,
          // border
          !isNewThemeApplied
            ? tw`border border-grey03`
            : tw`border border-border-primary`,
          // disabled
          !isNewThemeApplied
            ? tw`disabled:(bg-grey05 border-none)! disabled:hover:(bg-grey05 border-none)!`
            : tw`disabled:(border-border-secondary bg-background-secondary)! disabled:hover:(border-border-secondary bg-background-secondary)!`,
          // checked
          isNewThemeApplied &&
            tw`checked:focus:border-border-brand checked:border-border-brand checked:hover:border-border-brand`,
          // checkmark icon
          css`
            color: ${!isNewThemeApplied
              ? theme`colors.background-action`
              : theme`colors.background-brand`};
            :checked {
              background-image: url('${CheckIcon}');
              background-size: 14px;
            }
          `,
        ]}
        type="checkbox"
        ref={resolvedRef}
        {...props}
      />
    );
  },
);

export const CheckboxLabel = tw.label`text-text-primary text-ps`;
export const CheckboxContainer = tw.div`flex flex-row items-center`;

export default Checkbox;
