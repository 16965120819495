/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropdownValue, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Contract,
  CountryCode,
  CurrencyCode,
  DeductionUnit,
  Maybe,
} from '__generated__/graphql';

import CurrencyView from '../currency-view';
import { DeductionConstraintFromQuery } from '../types';

const constraintDefaultLabel: { [key: string]: string } = {
  employeeProvidentFund: "Employee's Provident Fund",
};

type EmployeeProvidentFundComponent = React.FC<{
  groupKey?: Maybe<string>;
  employerDeductionDefinitionId?: Maybe<string>;
  deductionDefinitionId?: Maybe<string>;
  constraint: DeductionConstraintFromQuery;
  currencyOptions?: Maybe<DropdownValue[]>;
  countryCode?: Contract['country'];
}>;

const EmployeeProvidentFund: EmployeeProvidentFundComponent = ({
  groupKey,
  employerDeductionDefinitionId,
  deductionDefinitionId,
  constraint,
  currencyOptions,
  countryCode,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const { watch, setValue } = useFormContext<CompensationFormValues>();

  const selectedDeduction = watch(
    `deductions.${employerDeductionDefinitionId}`,
  );

  useEffect(() => {
    setValue(`deductions.${deductionDefinitionId}`, selectedDeduction);
  }, [selectedDeduction]);

  const unitLabels: { [key: string]: string } = {
    [DeductionUnit.FIXED_AMOUNT_PER_MONTH]: t(
      `deduction.requirement.unit.${DeductionUnit.FIXED_AMOUNT_PER_MONTH}`,
      `/Month`,
    ),
    [DeductionUnit.PERCENTAGE]: t(
      `deduction.requirement.unit.${DeductionUnit.PERCENTAGE}`,
      `% of Basic Pay`,
    ),
  };

  const valueWithUnit =
    selectedDeduction?.unit === DeductionUnit.FIXED_AMOUNT_PER_MONTH &&
    countryCode === CountryCode.IND
      ? t(
          'deduction.india-pf-fixed-amount.title',
          '12% of Basic Pay capped at 1800/Month',
        )
      : `${selectedDeduction?.value}${unitLabels[selectedDeduction?.unit]}`;

  const currency = currencyOptions?.[0]?.value;

  const shouldShowCurrency =
    currency &&
    selectedDeduction?.unit === DeductionUnit.FIXED_AMOUNT_PER_MONTH &&
    countryCode !== CountryCode.IND;

  return (
    <div tw="w-full">
      <div tw="flex flex-col gap-y-small">
        <p tw="text-ps text-text-primary font-medium">
          {t(
            `deduction.requirement.${groupKey}.title.${constraint?.name}`,
            constraintDefaultLabel[constraint?.name ?? 'employerProvidentFund'],
          )}
        </p>
        <div
          css={[
            tw`rounded-tiny bg-background-primary px-small py-small h-46`,
            tw`flex flex-row items-center gap-x-small`,
            tw`text-text-tertiary text-ps`,
            !isNewThemeApplied && tw`text-foreground bg-grey05`,
          ]}
        >
          {shouldShowCurrency && (
            <CurrencyView currencyCode={currency as CurrencyCode} />
          )}
          <p>{valueWithUnit}</p>
        </div>
      </div>
    </div>
  );
};

export default EmployeeProvidentFund;
