import React, { useContext } from 'react';

import tw, { styled } from 'twin.macro';

import { BaseDropdown } from '../dropdown';
import { BaseTextInput } from '../text-input';
import { ThemeContext } from '../theme-provider';

export const Left = styled(BaseDropdown)(({ touched }) => [
  tw`p-extra-small pr-large rounded-tiny text-ps bg-background-secondary border-none`,
  tw`focus:(ring-transparent outline-none)`,
  touched ? tw`text-text-primary` : tw`text-text-tertiary`,
]);

export const Input = styled(BaseTextInput)(({ disabled }) => [
  tw`p-extra-small rounded-tiny text-ps border-none flex-grow`,
  tw`placeholder:text-text-tertiary`,
  tw`focus:(ring-transparent outline-none)`,
  disabled && tw`bg-background-primary`,
]);

export const Container: React.FC<
  React.PropsWithChildren<{ error?: boolean }>
> = ({ error, children }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div
      css={[
        tw`h-[46px] flex flex-row items-stretch p-tiny rounded-tiny text-ps border border-border-primary`,
        !isNewThemeApplied && tw`border-grey03`,
        error && tw`text-error border-error`,
      ]}
    >
      {children}
    </div>
  );
};
