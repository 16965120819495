/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Callout, CalloutVariant } from '@multiplier/common';
import { format, startOfDay } from 'date-fns';

import { parseDate } from 'app/utils/format';

import { useGetMemberWithContractStartOnQuery } from '__generated__/graphql';

const PayrollExpect: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    data: memberWithContractStartOn,
    loading,
  } = useGetMemberWithContractStartOnQuery();

  if (loading || !memberWithContractStartOn) {
    return null;
  }

  const incomingPayrollCutoff =
    memberWithContractStartOn?.member?.contracts?.[0]
      ?.incomingPayrollCutoffDate;
  const activationCutoffDate =
    incomingPayrollCutoff && parseDate(incomingPayrollCutoff);
  const hasCutoffDatePassed =
    activationCutoffDate &&
    startOfDay(new Date()) > startOfDay(activationCutoffDate);
  const cutoffDate =
    activationCutoffDate && format(activationCutoffDate, 'do MMMM yyyy');

  return (
    <div tw="text-text-primary gap-y-base flex flex-col">
      <div tw="flex flex-col gap-y-small">
        <h1 tw="font-semibold text-p">
          {t('payroll-expect.title', 'What to expect next?')}
        </h1>
        <Callout variant={CalloutVariant.WARNING}>
          <div>
            <h1 tw="text-p font-bold">
              {t('payroll-expect.callout.title', 'Payroll & Compliance Forms')}
            </h1>
            <p tw="text-ps">
              {cutoffDate && !hasCutoffDatePassed ? (
                <Trans
                  t={t}
                  i18nKey="payroll-expect.callout.content-with-cutoff-date"
                >
                  {'Payroll & Compliance Forms need to be submitted '}
                  <b tw="font-semibold">before {{ cutoffDate }}</b>
                  {" to be considered for same month's payroll."}
                </Trans>
              ) : (
                t(
                  'payroll-expect.callout.content-without-cutoff-date',
                  "Payroll & Compliance Forms need to be submitted before cutoff date of the month to be considered for same month's payroll.",
                )
              )}
            </p>
          </div>
        </Callout>
      </div>
      <div tw="flex flex-col gap-y-small">
        <p tw="font-semibold text-ps">
          {t('payroll-expect.note.title', 'Note:')}
        </p>
        <ol tw="text-ps">
          <li>
            {t(
              'payroll-expect.note.1',
              '1. You will be re-directed to Payroll & Compliance Forms’ section on the Multiplier platform.',
            )}
          </li>
          <li>
            {cutoffDate && !hasCutoffDatePassed
              ? t(
                  'payroll-expect.note.2-with-cutoff-date',
                  '2. Please make sure to submit the payroll forms before {{ cutoffDate }}.',
                  { replace: { cutoffDate } },
                )
              : t(
                  'payroll-expect.note.2-without-cutoff-date',
                  '2. Please make sure to submit the payroll forms before cutoff date of the month.',
                )}
          </li>
          <li>
            {t(
              'payroll-expect.note.3',
              '3. Failure to do so will result in your salary to be processed in the next month’s payroll cycle.',
            )}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PayrollExpect;
