import { gql } from '@apollo/client';

export default gql`
  mutation ResendInviteEmployee($id: Int) {
    resendInviteEmployee(id: $id, input: {})
      @rest(
        type: "Void"
        method: "POST"
        path: "api/employees/{args.id}/resend-activation"
        endpoint: "alpha"
        bodySerializer: "text"
      ) {
      void
    }
  }
`;
