import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import locizeLastUsed from 'locize-lastused';

import importMetaEnv from 'import-meta-env';

const isStageEnv =
  !!importMetaEnv.VITE_ENV && ['stage', 'stg'].includes(importMetaEnv.VITE_ENV);

const shouldUploadMissingKeys = isStageEnv ?? false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const backends: { [key: string]: any[] } = {
  prod: [LocizeBackend],
  release: [LocizeBackend],
  stage: [LocizeBackend],
  stg: [LocizeBackend],
  local: [LocizeBackend],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const backendOptions: { [key: string]: any[] } = {
  prod: [
    {
      projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
      referenceLng: 'en-SG',
      version: 'production',
    },
  ],
  release: [
    {
      projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
      referenceLng: 'en-SG',
      version: 'release',
    },
  ],
  stage: [
    {
      projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
      apiKey: importMetaEnv.VITE_LOCIZE_API_KEY,
      allowedAddOrUpdateHosts: [
        importMetaEnv.VITE_CUSTOMER_BASE_URL,
        importMetaEnv.VITE_OPS_BASE_URL,
        importMetaEnv.VITE_PARTNER_BASE_URL,
      ],
      referenceLng: 'en-SG',
      version: 'latest',
    },
  ],
  stg: [
    {
      projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
      referenceLng: 'en-SG',
      version: 'latest',
      apiKey: importMetaEnv.VITE_LOCIZE_API_KEY,
      allowedAddOrUpdateHosts: [
        importMetaEnv.VITE_CUSTOMER_BASE_URL,
        importMetaEnv.VITE_OPS_BASE_URL,
        importMetaEnv.VITE_PARTNER_BASE_URL,
      ],
    },
  ],
  local: [
    {
      projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
      referenceLng: 'en-SG',
      version: 'latest',
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lastUsedOptions: { [key: string]: any } = {
  prod: {
    projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
    referenceLng: 'en-SG',
    version: 'production',
    debounceSubmit: 90000,
  },
  release: {
    projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
    referenceLng: 'en-SG',
    version: 'release',
    debounceSubmit: 90000,
  },
  stage: {
    projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
    referenceLng: 'en-SG',
    version: 'latest',
    debounceSubmit: 90000,
    apiKey: importMetaEnv.VITE_LOCIZE_API_KEY,
    allowedHosts: [
      importMetaEnv.VITE_CUSTOMER_BASE_URL as string,
      importMetaEnv.VITE_OPS_BASE_URL as string,
      importMetaEnv.VITE_PARTNER_BASE_URL as string,
    ],
  },
  stg: {
    projectId: importMetaEnv.VITE_LOCIZE_PROJECT_ID as string,
    referenceLng: 'en-SG',
    version: 'latest',
    debounceSubmit: 90000,
    apiKey: importMetaEnv.VITE_LOCIZE_API_KEY,
    allowedHosts: [
      importMetaEnv.VITE_CUSTOMER_BASE_URL as string,
      importMetaEnv.VITE_OPS_BASE_URL as string,
      importMetaEnv.VITE_PARTNER_BASE_URL as string,
    ],
  },
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'common',
      'login',
      'common.table',
      'contract-offboarding',
      'members.ops',
      'compliance',
      'team.company',
      'payroll.company',
      'hris.common',
      'sidebar.view',
      'app.company',
      'app',
      'app.member',
      'contract-onboarding.common',
    ],
    debug: shouldUploadMissingKeys,
    saveMissing: shouldUploadMissingKeys,
    supportedLngs: ['en', 'en-SG'],
    fallbackLng: 'en-SG',
    load: 'currentOnly',
    backend: {
      backends: backends[importMetaEnv.VITE_ENV as string],
      backendOptions: backendOptions[importMetaEnv.VITE_ENV as string],
    },
    locizeLastUsed: lastUsedOptions[importMetaEnv.VITE_ENV as string],
    detection: {
      // convertDetectedLanguage: (lng) => {
      //   // write custom logic to convert language
      // }
      convertDetectedLanguage: () => 'en-SG',
    },
  });

if (isStageEnv) {
  i18n.use(locizePlugin);
}

if (importMetaEnv.PROD) {
  i18n.use(locizeLastUsed);
}

export default i18n;
