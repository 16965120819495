/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { numberAsCurrency } from '@multiplier/format';
import tw from 'twin.macro';

import { assureNumber } from 'app/utils/number';
import monthNames from 'common/constants/month-names';
import { mapRateFrequencyToNumberOfMonths } from 'contract-onboarding/company/services/compensation';
import i18n from 'i18n';

import {
  Contract,
  ContractType,
  Maybe,
  RateFrequency,
} from '__generated__/graphql';

import {
  AdditionalPayFormValues,
  Occurrence,
  PaymentType,
  mapFrequency,
} from '../..';

const BoldText = tw.span`font-semibold`;
const TextWrapper = tw.div`text-text-primary text-ps`;

const PayoutContractStatement: React.FC<{
  payment: AdditionalPayFormValues;
  contractType?: Contract['type'];
}> = ({ payment, contractType }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  const {
    payType,
    name,
    occurrence,
    frequency: frequencyList,
    currency,
    payoutMonth: { month, year } = { month: undefined, year: undefined },
    amount,
    conditions,
    payInInstallments,
    instalments,
  } = payment;

  const frequency:
    | number
    | Maybe<RateFrequency>
    | string
    | undefined = isNotFreelancer
    ? mapFrequency({
        frequency: mapRateFrequencyToNumberOfMonths(
          frequencyList as RateFrequency,
        ),
      })
    : frequencyList;

  const freqCount = assureNumber(frequencyList);

  const monthName =
    month != null && Number.isInteger(month)
      ? Object.values(monthNames)[month]
      : '';
  const yearStr = String(year);
  const formattedAmount = numberAsCurrency(i18n.language, amount, 2, 0);

  const { totalJoiningBonusFromInstallments, totalInstalments } = (() => {
    if (instalments && instalments?.length > 0) {
      const totalBonus = instalments.reduce(
        (data, instalment) => {
          if (
            instalment?.amount &&
            instalment?.payOn?.month != null &&
            instalment?.payOn?.year != null
          ) {
            data.bonus += instalment?.amount;
            data.totalInstalments += 1;
          }
          return data;
        },
        { bonus: 0, totalInstalments: 0 },
      );

      return {
        totalJoiningBonusFromInstallments: numberAsCurrency(
          i18n.language,
          totalBonus.bonus,
          2,
          0,
        ),
        totalInstalments: totalBonus.totalInstalments,
      };
    }
    return { totalJoiningBonusFromInstallments: 0, totalInstalments: 0 };
  })();

  const instalmentDetails = (() => {
    const numToString = ['First', 'Second', 'Third', 'Fourth'];

    if (!instalments || instalments?.length === 0) return null;

    return instalments?.map((instalment, index) => {
      if (
        instalment?.amount &&
        instalment?.payOn?.month != null &&
        instalment?.payOn?.year != null
      ) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <TextWrapper key={index} data-testid="instalment-details">
            {index + 1}. <BoldText>{numToString[index]} instalment</BoldText> of
            Joining bonus of{' '}
            <BoldText>
              {instalment?.currency} {instalment?.amount}
            </BoldText>{' '}
            in{' '}
            <BoldText>
              {Object.values(monthNames)[instalment?.payOn?.month]}{' '}
              {instalment.payOn?.year}
            </BoldText>
          </TextWrapper>
        );
      }
      return null;
    });
  })();

  if (isNotFreelancer) {
    return useMemo(() => {
      switch (payType) {
        case PaymentType.JOINING_BONUS:
          if (payInInstallments) {
            if (contractType === ContractType.CONTRACTOR) {
              return (
                <>
                  <Trans
                    t={t}
                    i18nKey="additional-pay.contract-statement.joining-bonus-with-instalments-feature-on-contractor"
                  >
                    <TextWrapper data-testid="joining-bonus-with-instalments">
                      Contractor is entitled to a total joining bonus of{' '}
                      {{ currency }} {{ totalJoiningBonusFromInstallments }}{' '}
                      paid in the {{ totalInstalments }} instalments as follows:
                    </TextWrapper>
                  </Trans>
                  <div
                    tw="mt-24"
                    data-testid="joining-bonus-instalment-details"
                  >
                    {instalmentDetails}
                  </div>
                  <TextWrapper>{conditions}</TextWrapper>
                </>
              );
            }
            return (
              <>
                <Trans
                  t={t}
                  i18nKey="additional-pay.contract-statement.joining-bonus-with-instalments-feature-on"
                >
                  <TextWrapper data-testid="joining-bonus-with-instalments">
                    Employee is entitled to a total joining bonus of{' '}
                    {{ currency }} {{ totalJoiningBonusFromInstallments }} paid
                    in the {{ totalInstalments }} instalments as follows:
                  </TextWrapper>
                </Trans>
                <div tw="mt-24" data-testid="joining-bonus-instalment-details">
                  {instalmentDetails}
                </div>
                <TextWrapper>{conditions}</TextWrapper>
              </>
            );
          }
          if (contractType === ContractType.CONTRACTOR) {
            return (
              <Trans
                t={t}
                i18nKey="additional-pay.contract-statement.joining-bonus-feature-on-contractor"
              >
                <TextWrapper data-testid="joining-bonus">
                  Contractor is entitled to a <BoldText>Joining Bonus</BoldText>{' '}
                  of{' '}
                  <BoldText>
                    {{ currency }} {{ formattedAmount }}
                  </BoldText>{' '}
                  to be credited on{' '}
                  <BoldText>
                    {' '}
                    {{ monthName }} {{ yearStr }}
                  </BoldText>
                  .
                </TextWrapper>
                <TextWrapper>{{ conditions }}</TextWrapper>
              </Trans>
            );
          }
          return (
            <Trans
              t={t}
              i18nKey="additional-pay.contract-statement.joining-bonus-feature-on"
            >
              <TextWrapper data-testid="joining-bonus">
                Employee is entitled to a <BoldText>Joining Bonus</BoldText> of{' '}
                <BoldText>
                  {{ currency }} {{ formattedAmount }}
                </BoldText>{' '}
                to be credited on{' '}
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        case PaymentType.VARIABLE_PERFORMANCE_BONUS:
          if (contractType === ContractType.CONTRACTOR) {
            return (
              <Trans
                t={t}
                i18nKey="additional-pay.contract-statement.variable-performance-bonus-feature-on-contractor"
                count={freqCount}
                tOptions={{
                  interpolation: { escapeValue: false },
                }}
                values={{
                  frequency,
                  monthName,
                  yearStr,
                  conditions,
                }}
              >
                <TextWrapper data-testid="variable-performance-bonus">
                  Contractor is entitled to a{' '}
                  <BoldText>Variable Performance Bonus</BoldText>{' '}
                  <BoldText>{{ frequency }}</BoldText> starting from
                  <BoldText>
                    {' '}
                    {{ monthName }} {{ yearStr }}
                  </BoldText>
                  .
                </TextWrapper>
                <TextWrapper>{{ conditions }}</TextWrapper>
              </Trans>
            );
          }
          return (
            <Trans
              t={t}
              i18nKey="additional-pay.contract-statement.variable-performance-bonus-feature-on"
              count={freqCount}
              tOptions={{
                interpolation: { escapeValue: false },
              }}
              values={{
                frequency,
                monthName,
                yearStr,
                conditions,
              }}
            >
              <TextWrapper data-testid="variable-performance-bonus">
                Employee is entitled to a{' '}
                <BoldText>Variable Performance Bonus</BoldText>{' '}
                <BoldText>{{ frequency }}</BoldText> starting from
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        case PaymentType.ALLOWANCES:
          if (contractType === ContractType.CONTRACTOR) {
            return (
              <Trans
                t={t}
                count={freqCount}
                i18nKey="additional-pay.contract-statement.allowances-feature-on-contractor"
                tOptions={{
                  interpolation: { escapeValue: false },
                }}
                values={{
                  name: unescape(name),
                  currency,
                  formattedAmount,
                  frequency,
                  monthName,
                  yearStr,
                  conditions,
                }}
              >
                <TextWrapper data-testid="allowances">
                  Contractor is entitled to a <BoldText>{{ name }}</BoldText> of{' '}
                  <BoldText>
                    {{ currency }} {{ formattedAmount }}
                  </BoldText>{' '}
                  <BoldText>{{ frequency }}</BoldText> starting from
                  <BoldText>
                    {' '}
                    {{ monthName }} {{ yearStr }}
                  </BoldText>
                  .
                </TextWrapper>
                <TextWrapper>{{ conditions }}</TextWrapper>
              </Trans>
            );
          }
          return (
            <Trans
              t={t}
              count={freqCount}
              i18nKey="additional-pay.contract-statement.allowances-feature-on"
              tOptions={{
                interpolation: { escapeValue: false },
              }}
              values={{
                name: unescape(name),
                currency,
                formattedAmount,
                frequency,
                monthName,
                yearStr,
                conditions,
              }}
            >
              <TextWrapper data-testid="allowances">
                Employee is entitled to a <BoldText>{{ name }}</BoldText> of{' '}
                <BoldText>
                  {{ currency }} {{ formattedAmount }}
                </BoldText>{' '}
                <BoldText>{{ frequency }}</BoldText> starting from
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        case PaymentType.DE_MINIMIS_ALLOWANCE:
          return (
            <Trans
              t={t}
              count={freqCount}
              i18nKey="additional-pay.contract-statement.de-minimis-allowance-feature-on"
              tOptions={{
                interpolation: { escapeValue: false },
              }}
              values={{
                currency,
                formattedAmount,
                frequency,
                monthName,
                yearStr,
                conditions,
              }}
            >
              <TextWrapper data-testid="allowances">
                Employee is entitled to a{' '}
                <BoldText>De Minimis Allowance</BoldText> of{' '}
                <BoldText>
                  {{ currency }} {{ formattedAmount }}
                </BoldText>{' '}
                <BoldText>{{ frequency }}</BoldText> starting from
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        case PaymentType.INTERNET_ALLOWANCE:
          return (
            <Trans
              t={t}
              i18nKey="additional-pay.contract-statement.internet-allowances-feature-on"
              count={freqCount}
              tOptions={{ interpolation: { escapeValue: false } }}
              values={{
                currency,
                formattedAmount,
                frequency,
                monthName,
                yearStr,
                conditions,
              }}
            >
              <TextWrapper data-testid="allowances">
                Employee is entitled to a{' '}
                <BoldText>Internet Allowance</BoldText> of{' '}
                <BoldText>
                  {{ currency }} {{ formattedAmount }}
                </BoldText>{' '}
                <BoldText>{{ frequency }}</BoldText> starting from
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        case PaymentType.MOBILE_AND_PHONE_ALLOWANCE:
          return (
            <Trans
              t={t}
              i18nKey="additional-pay.contract-statement.mobile-and-phone-allowances-feature-on"
              count={freqCount}
              tOptions={{
                interpolation: { escapeValue: false },
              }}
              values={{
                currency,
                formattedAmount,
                frequency,
                monthName,
                yearStr,
                conditions,
              }}
            >
              <TextWrapper data-testid="allowances">
                Employee is entitled to a{' '}
                <BoldText>Mobile and Phone Allowance</BoldText> of{' '}
                <BoldText>
                  {{ currency }} {{ formattedAmount }}
                </BoldText>{' '}
                <BoldText>{{ frequency }}</BoldText> starting from
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        case PaymentType.OTHER:
          if (occurrence === Occurrence.ONCE) {
            if (contractType === ContractType.CONTRACTOR) {
              return (
                <Trans
                  t={t}
                  i18nKey="additional-pay.contract-statement.other.onetime-feature-on-contractor"
                  tOptions={{
                    interpolation: { escapeValue: false },
                  }}
                  values={{
                    name: unescape(name),
                    monthName,
                    yearStr,
                    conditions,
                  }}
                >
                  <TextWrapper data-testid="other-onetime">
                    Contractor is entitled to a{' '}
                    <BoldText>one time payment</BoldText> for{' '}
                    <BoldText>{{ name }}</BoldText> credited on
                    <BoldText>
                      {' '}
                      {{ monthName }} {{ yearStr }}
                    </BoldText>
                    .
                  </TextWrapper>
                  <TextWrapper>{{ conditions }}</TextWrapper>
                </Trans>
              );
            }
            return (
              <Trans
                t={t}
                i18nKey="additional-pay.contract-statement.other.onetime-feature-on"
                tOptions={{
                  interpolation: { escapeValue: false },
                }}
                values={{
                  name: unescape(name),
                  monthName,
                  yearStr,
                  conditions,
                }}
              >
                <TextWrapper data-testid="other-onetime">
                  Employee is entitled to a{' '}
                  <BoldText>one time payment</BoldText> for{' '}
                  <BoldText>{{ name }}</BoldText> credited on
                  <BoldText>
                    {' '}
                    {{ monthName }} {{ yearStr }}
                  </BoldText>
                  .
                </TextWrapper>
                <TextWrapper>{{ conditions }}</TextWrapper>
              </Trans>
            );
          }
          if (contractType === ContractType.CONTRACTOR) {
            return (
              <Trans
                t={t}
                i18nKey="additional-pay.contract-statement.other.recurring-feature-on-contractor"
                count={freqCount}
                tOptions={{
                  interpolation: { escapeValue: false },
                }}
                values={{
                  name: unescape(name),
                  frequency,
                  monthName,
                  yearStr,
                  conditions,
                }}
              >
                <TextWrapper data-testid="other-recurring">
                  Contractor is entitled to a <BoldText>{{ name }}</BoldText>{' '}
                  <BoldText>{{ frequency }}</BoldText> starting from{' '}
                  <BoldText>
                    {' '}
                    {{ monthName }} {{ yearStr }}
                  </BoldText>
                  .
                </TextWrapper>
                <TextWrapper>{{ conditions }}</TextWrapper>
              </Trans>
            );
          }
          return (
            <Trans
              t={t}
              i18nKey="additional-pay.contract-statement.other.recurring-feature-on"
              count={freqCount}
              tOptions={{
                interpolation: { escapeValue: false },
              }}
              values={{
                name: unescape(name),
                frequency,
                monthName,
                yearStr,
                conditions,
              }}
            >
              <TextWrapper data-testid="other-recurring">
                Employee is entitled to a <BoldText>{{ name }}</BoldText>{' '}
                <BoldText>{{ frequency }}</BoldText> starting from{' '}
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        default:
          return null;
      }
    }, [payment]);
  }
  return useMemo(() => {
    switch (payType) {
      case PaymentType.JOINING_BONUS:
        return (
          <Trans
            t={t}
            i18nKey="additional-pay.contract-statement.joining-bonus"
          >
            <TextWrapper data-testid="joining-bonus">
              Employee is entitled to a <BoldText>Joining Bonus</BoldText> of{' '}
              <BoldText>
                {{ currency }} {{ formattedAmount }}
              </BoldText>{' '}
              to be credited on{' '}
              <BoldText>
                {' '}
                {{ monthName }} {{ yearStr }}
              </BoldText>
              .
            </TextWrapper>
            <TextWrapper>{{ conditions }}</TextWrapper>
          </Trans>
        );
      case PaymentType.VARIABLE_PERFORMANCE_BONUS:
        return (
          <Trans
            t={t}
            i18nKey="additional-pay.contract-statement.variable-performance-bonus"
            count={freqCount}
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              frequency,
              monthName,
              yearStr,
              conditions,
            }}
          >
            <TextWrapper data-testid="variable-performance-bonus">
              Employee is entitled to a{' '}
              <BoldText>Variable Performance Bonus</BoldText>
              every <BoldText>{{ frequency }} months</BoldText> starting from
              <BoldText>
                {' '}
                {{ monthName }} {{ yearStr }}
              </BoldText>
              .
            </TextWrapper>
            <TextWrapper>{{ conditions }}</TextWrapper>
          </Trans>
        );
      case PaymentType.ALLOWANCES:
        return (
          <Trans
            t={t}
            count={freqCount}
            i18nKey="additional-pay.contract-statement.allowances-new"
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              name: unescape(name),
              currency,
              formattedAmount,
              frequency,
              monthName,
              yearStr,
              conditions,
            }}
          >
            <TextWrapper data-testid="allowances">
              Employee is entitled to a{' '}
              <BoldText>{{ name }} allowance</BoldText> of{' '}
              <BoldText>
                {{ currency }} {{ formattedAmount }}
              </BoldText>{' '}
              every <BoldText>{{ frequency }} months</BoldText> starting from
              <BoldText>
                {' '}
                {{ monthName }} {{ yearStr }}
              </BoldText>
              .
            </TextWrapper>
            <TextWrapper>{{ conditions }}</TextWrapper>
          </Trans>
        );
      case PaymentType.DE_MINIMIS_ALLOWANCE:
        return (
          <Trans
            t={t}
            count={freqCount}
            i18nKey="additional-pay.contract-statement.de-minimis-allowance"
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              currency,
              formattedAmount,
              frequency,
              monthName,
              yearStr,
              conditions,
            }}
          >
            <TextWrapper data-testid="allowances">
              Employee is entitled to a{' '}
              <BoldText>De Minimis Allowance</BoldText> of{' '}
              <BoldText>
                {{ currency }} {{ formattedAmount }}
              </BoldText>{' '}
              every <BoldText>{{ frequency }} months</BoldText> starting from
              <BoldText>
                {' '}
                {{ monthName }} {{ yearStr }}
              </BoldText>
              .
            </TextWrapper>
            <TextWrapper>{{ conditions }}</TextWrapper>
          </Trans>
        );
      case PaymentType.INTERNET_ALLOWANCE:
        return (
          <Trans
            t={t}
            i18nKey="additional-pay.contract-statement.internet-allowances"
            count={freqCount}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{
              currency,
              formattedAmount,
              frequency,
              monthName,
              yearStr,
              conditions,
            }}
          >
            <TextWrapper data-testid="allowances">
              Employee is entitled to a <BoldText>Internet Allowance</BoldText>{' '}
              of{' '}
              <BoldText>
                {{ currency }} {{ formattedAmount }}
              </BoldText>{' '}
              every <BoldText>{{ frequency }} months</BoldText> starting from
              <BoldText>
                {' '}
                {{ monthName }} {{ yearStr }}
              </BoldText>
              .
            </TextWrapper>
            <TextWrapper>{{ conditions }}</TextWrapper>
          </Trans>
        );
      case PaymentType.MOBILE_AND_PHONE_ALLOWANCE:
        return (
          <Trans
            t={t}
            i18nKey="additional-pay.contract-statement.mobile-and-phone-allowances"
            count={freqCount}
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              currency,
              formattedAmount,
              frequency,
              monthName,
              yearStr,
              conditions,
            }}
          >
            <TextWrapper data-testid="allowances">
              Employee is entitled to a{' '}
              <BoldText>Mobile and Phone Allowance</BoldText> of{' '}
              <BoldText>
                {{ currency }} {{ formattedAmount }}
              </BoldText>{' '}
              every <BoldText>{{ frequency }} months</BoldText> starting from
              <BoldText>
                {' '}
                {{ monthName }} {{ yearStr }}
              </BoldText>
              .
            </TextWrapper>
            <TextWrapper>{{ conditions }}</TextWrapper>
          </Trans>
        );
      case PaymentType.OTHER:
        if (occurrence === Occurrence.ONCE) {
          return (
            <Trans
              t={t}
              i18nKey="additional-pay.contract-statement.other.onetime"
              tOptions={{
                interpolation: { escapeValue: false },
              }}
              values={{
                name: unescape(name),
                monthName,
                yearStr,
                conditions,
              }}
            >
              <TextWrapper data-testid="other-onetime">
                Employee is entitled to a <BoldText>one time payment</BoldText>{' '}
                for <BoldText>{{ name }}</BoldText> credited on
                <BoldText>
                  {' '}
                  {{ monthName }} {{ yearStr }}
                </BoldText>
                .
              </TextWrapper>
              <TextWrapper>{{ conditions }}</TextWrapper>
            </Trans>
          );
        }
        return (
          <Trans
            t={t}
            i18nKey="additional-pay.contract-statement.other.recurring"
            count={freqCount}
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              name: unescape(name),
              frequency,
              monthName,
              yearStr,
              conditions,
            }}
          >
            <TextWrapper data-testid="other-recurring">
              Employee is entitled to a <BoldText>{{ name }}</BoldText> every{' '}
              <BoldText>{{ frequency }} months</BoldText> starting from{' '}
              <BoldText>
                {' '}
                {{ monthName }} {{ yearStr }}
              </BoldText>
              .
            </TextWrapper>
            <TextWrapper>{{ conditions }}</TextWrapper>
          </Trans>
        );
      default:
        return null;
    }
  }, [payment]);
};

export default PayoutContractStatement;
