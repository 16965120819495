/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ToolTip } from '@multiplier/common';

import 'twin.macro';
import swiftCodesWithAdditionalCharge from 'common/constants/swift-codes-with-additional-charge';

import { Contract, ContractType } from '__generated__/graphql';

export const checkIfSwiftKeyField = (key: string): boolean =>
  /swift|bic/i.test(key ?? '');

const SwiftBICValueComponent: React.FC<{
  value: string;
  contractType: Contract['type'];
}> = ({ value, contractType }) => {
  const { t } = useTranslation('contract-onboarding.member');
  if (
    contractType === ContractType.FREELANCER &&
    swiftCodesWithAdditionalCharge.includes(value)
  )
    return (
      <div tw="flex gap-x-base">
        <div>{value ?? ''}</div>
        {swiftCodesWithAdditionalCharge.includes(value) && (
          <ToolTip
            content={
              <div tw="w-[260px]">
                <div tw="text-p">
                  {t(
                    'swift-fee.info.swift-fee-applicable.tooltip-header',
                    'What is SWIFT Fees?',
                  )}
                </div>
                <div tw="text-ps text-text-tertiary leading-relaxed">
                  {t(
                    'swift-fee.info.swift-fee-applicable.tooltip-content',
                    'SWIFT fee refers to the exchange rate costs charged by the banks on a SWIFT transfer. It will be based on the amount you receive in each transactions',
                  )}
                </div>
              </div>
            }
          >
            {!!value && (
              <Trans
                t={t}
                i18nKey="swift-fee.info.swift-fee-applicable.content"
              >
                <p data-testid="extra-swift-fee-info" tw="text-primary">
                  <span tw="font-bold">$35</span>{' '}
                  <span tw="font-semibold">SWIFT Fee Applicable</span>
                </p>
              </Trans>
            )}
          </ToolTip>
        )}
      </div>
    );
  return <span>{value}</span>;
};

export default SwiftBICValueComponent;
