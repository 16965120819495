import { useEffect, useMemo } from 'react';

import {
  Contract,
  ContractType,
  useGetCountryComplianceRequirementsLazyQuery,
} from '__generated__/graphql';

import {
  GroupedComplianceParamDefinitions,
  groupComplianceParamDefinitionByKey,
} from '../../services/compliance';

export type ComplianceParamsDefinitionResponse = {
  loading: boolean;
  groupedComplianceParamDefinitions: GroupedComplianceParamDefinitions;
};

const useComplianceParamsDefinition = (
  type: Contract['type'],
  country: Contract['country'],
  countryStateCode: Contract['countryStateCode'],
  term: Contract['term'],
  start: Contract['startOn'],
  end: Contract['endOn'],
): ComplianceParamsDefinitionResponse => {
  const [
    getCountryCompliance,
    { data, loading },
  ] = useGetCountryComplianceRequirementsLazyQuery();

  useEffect(() => {
    if (type && country) {
      getCountryCompliance({
        variables: {
          country,
          countryStateCode,
          contractType: type as ContractType,
          inputParams: {
            contractType: type,
            contractTerm: term,
            contractStartDate: start,
            contractEndDate: end,
          },
        },
      });
    }
  }, [country, countryStateCode, type, term, start, end]);

  const groupedComplianceParamDefinitions = useMemo<GroupedComplianceParamDefinitions>(
    () => groupComplianceParamDefinitionByKey(data),
    [data],
  );

  return {
    loading,
    groupedComplianceParamDefinitions,
  };
};

export default useComplianceParamsDefinition;
