/* eslint-disable import/prefer-default-export */
import { getFirstCountryCodeBasedOnPhoneCode } from 'contract-onboarding/services/contact-details';
import { memberLegalDataMap } from 'contract-onboarding/services/legal-data';

import {
  CountryCode,
  DataFieldDefinition,
  GetMemberQuery,
  LegalDocumentRequirement,
  Maybe,
} from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '.';

const getMemberName = (member: GetMemberQuery['member']): string => {
  if (member?.fullLegalName) {
    return member.fullLegalName;
  }
  if (member?.firstName || member?.lastName) {
    return `${member?.firstName ?? ''} ${member?.lastName ?? ''}`.trim();
  }
  return '';
};

const getMemberCountry = (
  member: GetMemberQuery['member'],
): CountryCode | null =>
  member?.addresses?.[0]?.country ??
  member?.nationalities?.[0]?.country ??
  member?.contracts?.[0]?.country ??
  null;

const getMemberNationality = (
  member: GetMemberQuery['member'],
): CountryCode | null =>
  member?.nationalities?.[0]?.country ??
  member?.contracts?.[0]?.country ??
  null;

export const mapToFormValues = (
  member: GetMemberQuery['member'],
  requirements: {
    memberLegalDataRequirements: { [key: string]: DataFieldDefinition };
    memberDocumentRequirements:
      | Maybe<Array<Maybe<LegalDocumentRequirement>>>
      | undefined;
  },
): MemberBasicDetailsFormValues | null => {
  if (!member) {
    return null;
  }

  const phoneNo = member.phoneNos?.[0]?.phoneNo;
  const parsedPhoneCode = phoneNo?.split(' ')?.[0];
  const legalData = member.legalData && memberLegalDataMap(member.legalData);
  const country = getMemberCountry(member);

  const memberFormValues = {
    legalName: getMemberName(member),
    nationality: getMemberNationality(member),
    dateOfBirth: member.dateOfBirth,
    phone: parsedPhoneCode
      ? phoneNo?.replace(`${parsedPhoneCode} `, '')
      : phoneNo,
    currentAddress: member.addresses?.[0]?.line1 ?? '',
    addressLine2: member.addresses?.[0]?.line2 ?? '',
    city: member.addresses?.[0]?.city ?? '',
    postalCode: member.addresses?.[0]?.postalCode ?? '',
    country,
    state:
      member.contracts?.[0]?.countryStateCode ?? member.addresses?.[0]?.state,
    countryCode: getFirstCountryCodeBasedOnPhoneCode(parsedPhoneCode, country),
    legalData: (Object.values(requirements?.memberLegalDataRequirements).map(
      (val: DataFieldDefinition) => ({
        key: val.key,
        value: val.key ? legalData?.[val.key]?.value : null,
        identifier: val.key ? legalData?.[val.key]?.identifier : null,
      }),
    ) ?? undefined) as MemberBasicDetailsFormValues['legalData'],
    maritalStatus: member.maritalStatus,
  } as MemberBasicDetailsFormValues;

  return memberFormValues;
};
