import { gql } from '@apollo/client';

export default gql`
  query GetOfferLetter($id: ID!) {
    contract(id: $id) {
      id
      status
      compliance {
        ... on ComplianceMultiplierEOR {
          offerLetter {
            id
            name
            extension
            contentType
            blob
            htmlPreview
          }
        }
        ... on CompliancePartnerEOR {
          offerLetter {
            id
            name
            extension
            contentType
            blob
            htmlPreview
          }
        }
      }
    }
  }
`;
