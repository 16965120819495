/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { Loader, SpinnerType } from '@multiplier/common';

import LoadingFailed from './components/loading-failed';
import LoadingScreen from './components/loading-screen';

const ExperienceLoader: React.FC<{ onProceed: () => void }> = ({
  onProceed,
}) => {
  const [loadingFailed, setLoadingFailed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingFailed(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loadingFailed ? (
        <LoadingScreen loadingFailed={loadingFailed}>
          <LoadingFailed onProceed={onProceed} />
        </LoadingScreen>
      ) : (
        <Loader.Spinner variant={SpinnerType.EXPERIENCE} />
      )}
    </>
  );
};

export default ExperienceLoader;
