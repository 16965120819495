export const subtractOrDefault = <T>(
  a: unknown,
  b: unknown,
  defaultValue: T,
): number | T => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  return defaultValue;
};
export const addOrDefault = <T>(
  a: unknown,
  b: unknown,
  defaultValue: T,
): number | T => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a + b;
  }
  return defaultValue;
};

export const assureNumber = (maybeNumber: unknown): number => {
  if (typeof maybeNumber === 'number') return maybeNumber;
  if (typeof maybeNumber === 'string')
    return Number.parseFloat(maybeNumber) || 0;
  return 0;
};

export const convertOrDefault = (
  maybeNumber: unknown,
  converter: (x: number) => number,
  defaultValue: number,
): number => {
  if (typeof maybeNumber === 'number') return converter(maybeNumber);
  if (typeof maybeNumber === 'string')
    return converter(Number.parseFloat(maybeNumber) || 0);
  return defaultValue;
};
