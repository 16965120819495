import { useEffect } from 'react';

import { Contract } from '__generated__/graphql';

import { ContractInput } from '../components/contract-dropdown';

export default (
  id: Contract['id'],
  setMember: (
    value: ((prevState: ContractInput) => ContractInput) | ContractInput,
  ) => void,
  onChange: (contract: ContractInput) => void,
  team: Contract[],
): void => {
  useEffect(() => {
    const memberContract = team.find((contract) => contract?.id === id);
    if (memberContract) {
      const { member } = memberContract;
      const contractInput = {
        id: memberContract.id,
        memberId: member?.id,
        name: `${member?.firstName} ${member?.lastName}`,
        country: memberContract.country,
        position: String(memberContract.position),
        employmentType: memberContract.type,
        currency: memberContract.currency,
        term: memberContract?.term,
        countryStateCode: memberContract?.countryStateCode,
        contractStatus: memberContract?.status,
        companyId: memberContract.company?.id,
      };
      setMember(contractInput);
      onChange(contractInput);
    }
  }, [id, team]);
};
