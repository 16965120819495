import faker from 'faker';

import {
  Benefit,
  BenefitCostingType,
  BenefitDocument,
  BenefitDocumentType,
  BenefitInsuranceType,
  BenefitPartnerBillingFrequency,
  BenefitPartnerCountry,
  BenefitPartnerStatus,
  BenefitTier,
  BenefitType,
  BenefitsDomain,
  CountryCode,
  CurrencyCode,
  Document,
  Member,
  PartnerCapability,
  RateFrequency,
} from '../../__generated__/graphql';

const mockInsuranceDocument = (): BenefitDocument => ({
  benefitDocumentType: BenefitDocumentType.INSURANCE_DOCUMENT,
  files: [
    {
      id: '66296',
      name: 'DUMMY.pdf',
      downloadUrl:
        'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      __typename: 'Document',
    },
  ],
  __typename: 'BenefitDocument',
});

const mockFactSheet = (): BenefitDocument => ({
  benefitDocumentType: BenefitDocumentType.FACT_SHEET,
  files: [
    {
      id: '66380',
      name: 'gold_20220523.pdf',
      downloadUrl:
        'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      __typename: 'Document',
    },
  ],
  __typename: 'BenefitDocument',
});

// benefit partner country
const mockProvider = ({
  id = '1',
  partnerName = 'partner1',
  status = BenefitPartnerStatus.ACTIVE,
  emergencyPointOfContact = {
    firstName: 'Emergency',
    lastName: 'India',
    emails: [
      {
        type: 'primary',
        email: 'test_primary@test.com',
        __typename: 'EmailAddress',
      },
    ],
    phoneNos: [
      {
        type: 'PRIMARY',
        phoneNo: '+91 123456789',
        __typename: 'PhoneNumber',
      },
    ],
  },
  metaData = {
    pocName: 'Emergency India',
    emails: [
      {
        email: 'poc-india@gmail.com',
        type: 'poc',
      },
      {
        email: 'primary-india@gmail.com',
        type: 'primary',
      },
    ],
    phoneNos: [
      {
        phoneNo: '+91 123456789',
        type: 'poc',
      },
      {
        phoneNo: '+91 123456789',
        type: 'primary',
      },
    ],
  },
  brokerData = {
    name: 'Broker India',
  },
  entity = {
    legalName: 'Entity India',
  },
  platformFee = 20,
  insuranceType = BenefitInsuranceType.INDIVIDUAL,
  billingDuration = BenefitPartnerBillingFrequency.ANNUALLY,
  billingCurrency = CurrencyCode.USD,
  erSplitApplicable = true,
  minimumErSplitPercentage = 50,
  ageBandApplicable = false,
  refundPolicy = true,
  benefitsDomain = BenefitsDomain.HEALTH_INSURANCE,
  country = CountryCode.USA,
  partner = {
    id: faker.datatype.uuid(),
    name: 'BenefitPartnerCountry',
    countries: [
      {
        capability: PartnerCapability.BENEFIT,
        country: CountryCode.USA,
        id: faker.datatype.uuid(),
      },
    ],
  },
  platformFeeApplicable = false,
  isInsuranceMandatory = true,
  benefits,
  coverageGroup,
}: BenefitPartnerCountry): BenefitPartnerCountry => ({
  id,
  country,
  status,
  partnerName,
  emergencyPointOfContact,
  metaData,
  brokerData,
  entity,
  platformFee,
  erSplitApplicable,
  ageBandApplicable,
  refundPolicy,
  benefitsDomain,
  insuranceType,
  billingDuration,
  billingCurrency,
  minimumErSplitPercentage,
  isInsuranceMandatory,
  partner,
  platformFeeApplicable,
  benefits,
  coverageGroup,
  __typename: 'BenefitPartnerCountry',
});

export const mockBenefit = ({
  id = faker.datatype.uuid(),
  features = ['Inpatient', 'Outpatient'],
}: {
  id?: string;
  features?: ('Inpatient' | 'Outpatient' | 'Dental' | FeatureMockValue)[];
}): Benefit => ({
  id,
  type: BenefitType.INSURANCE,
  packageName: 'GOLD',
  description: '',
  startDate: '2022-01-01',
  endDate: '2023-01-01',
  currency: CurrencyCode.USD,
  costingType: BenefitCostingType.FIXED,
  cost: 100,
  frequency: RateFrequency.MONTHLY,
  packageTier: {
    isDefault: true,
    tier: BenefitTier.GOLD,
  },
  memberOnboardingKit: {
    memberOnboardingKitFile: {
      files: [mockInsuranceDocument()?.files?.[0] as Document],
    },
  },
  benefitDocuments: [mockInsuranceDocument(), mockFactSheet()],
  provider: mockProvider({
    id: '99104',
    emergencyPointOfContact: {
      firstName: 'Emergency',
      lastName: 'India',
      emails: [
        {
          type: 'primary',
          email: 'emergencyindia@example.com',
          __typename: 'EmailAddress',
        },
      ],
      phoneNos: [
        {
          type: 'PRIMARY',
          phoneNo: '+91 123456789',
          __typename: 'PhoneNumber',
        },
      ],
      __typename: 'Member',
    } as Member,
    partner: {
      id: '1',
      name: 'partner1',
      __typename: 'Partner',
    },
    coverageGroup: {
      id: '1',
      name: 'Employee',
      group: [
        {
          maxCount: 1,
          relation: {
            id: '1',
            familyMember: 'Employee',
          },
        },
      ],
    },
  }),
  features:
    features?.map((feature, index) => {
      if (typeof feature === 'string') {
        return {
          key: feature,
          label: faker.lorem.word(3),
          value: `${index + 1}`,
          __typename: 'BenefitFeatures',
        };
      }
      return {
        key: feature.name,
        label: faker.lorem.word(3),
        value: `${feature.value}`,
        __typename: 'BenefitFeatures',
      };
    }) || [],
  __typename: 'Benefit',
});

export type FeatureMockValue = {
  name: 'Inpatient' | 'Outpatient' | 'Dental';
  value: number;
};

const mockInsurance = ({
  id,
  benefitType = BenefitType.INSURANCE,
  packageName = 'Gold',
  description,
  country,
  countryStateCode,
  location,
  currency,
  cost = 9999,
  frequency = RateFrequency.ANNUALLY,
  isRecommended = false,
  features = ['Inpatient', 'Outpatient'],
  partnerName = faker.lorem.word(5),
  costingType = BenefitCostingType.FIXED,
  dependentMaxCount = 0,
  provider,
}: {
  id?: string;
  benefitType?: BenefitType;
  packageName?: string;
  description?: string;
  country?: CountryCode;
  countryStateCode?: string;
  location?: string;
  currency?: CurrencyCode;
  cost?: number;
  frequency?: RateFrequency;
  isRecommended?: boolean;
  features?: ('Inpatient' | 'Outpatient' | 'Dental' | FeatureMockValue)[];
  partnerName?: string;
  costingType?: BenefitCostingType;
  dependentMaxCount?: number;
  provider?: Benefit['provider'];
}): Benefit => ({
  id: id || faker.datatype.uuid(),
  type: benefitType,
  packageName,
  description: description || faker.lorem.word(4),
  factSheetUrl:
    'http://multiplier-public-assets.s3.ap-southeast-1.amazonaws.com/benefits/srilanka/bronze.pdf',
  country: country || CountryCode.SGP,
  countryStateCode,
  location: location || '',
  currency: currency || CurrencyCode.SGD,
  cost,
  costingType,
  frequency,
  isRecommended,
  compareBenefits: {
    comparisonFileUrl:
      'http://multiplier-public-assets.s3.ap-southeast-1.amazonaws.com/benefits/srilanka/comparison.pdf',
    __typename: 'BenefitComparison',
  },
  dependentMaxCount: dependentMaxCount || 0,
  features:
    features?.map((feature, index) => {
      if (typeof feature === 'string') {
        return {
          key: feature,
          label: faker.lorem.word(3),
          value: `${index + 1}`,
          __typename: 'BenefitFeatures',
        };
      }
      return {
        key: feature.name,
        label: faker.lorem.word(3),
        value: `${feature.value}`,
        __typename: 'BenefitFeatures',
      };
    }) || [],
  provider: {
    ...(provider || {}),
    id: id || faker.datatype.uuid(),
    entity: {
      legalName: faker.lorem.word(5),
      __typename: 'LegalEntity',
    },
    partner: {
      id: id || faker.datatype.uuid(),
      name: partnerName,
      __typename: 'Partner',
    },
    __typename: 'BenefitPartnerCountry',
  },
  __typename: 'Benefit',
});

export const mockBenefitPartnerCountries = ({
  erSplitApplicable,
  benefits,
}: Partial<BenefitPartnerCountry>): BenefitPartnerCountry[] => [
  mockProvider({
    erSplitApplicable: erSplitApplicable ?? true,
    benefits: Array.isArray(benefits)
      ? benefits
      : [
          mockInsurance({
            id: '417dd1c0-a29b-4a19-a83f-d57e60bff68a',
            isRecommended: true,
            features: ['Dental', 'Outpatient', 'Inpatient'],
            provider: {
              coverageGroup: {
                id: '1',
                name: 'Employee',
                group: [
                  {
                    maxCount: 1,
                    relation: {
                      id: '1',
                      familyMember: 'Self',
                    },
                  },
                ],
              },
            },
          }),
          mockInsurance({
            id: 'a67d4ed1-62c9-487c-9407-694f4889b6c3',
            packageName: 'SILVER',
            features: ['Outpatient', 'Inpatient', 'Dental'],
            cost: 999,
            dependentMaxCount: 1,
            provider: {
              coverageGroup: {
                id: '1',
                name: 'Employee',
                group: [
                  {
                    maxCount: 1,
                    relation: {
                      id: '1',
                      familyMember: 'Self',
                    },
                  },
                ],
              },
            },
          }),
          mockInsurance({
            id: '417dd1c0-a29b-4a19-a83f-d57e60bff70c',
            packageName: 'BRONZE',
            features: ['Inpatient'],
            cost: 99,
            provider: {
              coverageGroup: {
                id: '1',
                name: 'Employee',
                group: [
                  {
                    maxCount: 1,
                    relation: {
                      id: '1',
                      familyMember: 'Self',
                    },
                  },
                ],
              },
            },
          }),
        ],
  }),
];
