/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { DropdownValue, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import {
  Contract,
  ContributionBy,
  DeductionDefinition,
} from '__generated__/graphql';

import EmployeeDeductionInput from './deduction-input/employee-deduction-input';
import EmployerDeductionInput from './deduction-input/employer-deduction-input';

type DeductionsGroupComponent = React.FC<{
  groupKey: string;
  sameGroupDefinitions: DeductionDefinition[];
  currencyOptions: DropdownValue[];
  countryCode?: Contract['country'];
  postProbationBaseSalary?: number;
}>;

const DeductionsGroup: DeductionsGroupComponent = ({
  groupKey,
  sameGroupDefinitions,
  currencyOptions,
  countryCode,
  postProbationBaseSalary,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const employerDeduction = sameGroupDefinitions.find(
    (definition) => definition.contributionBy === ContributionBy.EMPLOYER,
  );
  const employeeDeduction = sameGroupDefinitions.find(
    (definition) => definition.contributionBy === ContributionBy.EMPLOYEE,
  );

  return (
    <div>
      <p tw="text-ps text-text-primary font-semibold">
        {t(`deduction.input.${groupKey}.title`)}
      </p>
      <p
        css={[
          tw`text-ps text-text-secondary`,
          !isNewThemeApplied && tw`text-grey02`,
        ]}
      >
        {t(`deduction.input.${groupKey}.subtitle`)}
      </p>

      {sameGroupDefinitions.length === 1 && (
        <div tw="mt-base">
          <EmployerDeductionInput
            groupKey={employerDeduction?.groupKey}
            deductionDefinitionId={employerDeduction?.id}
            constraint={employerDeduction?.deductionConstraint}
            currencyOptions={currencyOptions}
            countryCode={countryCode}
            postProbationBaseSalary={postProbationBaseSalary}
          />
        </div>
      )}

      {sameGroupDefinitions.length > 1 && (
        <div tw="mt-base flex justify-between">
          <EmployerDeductionInput
            groupKey={employerDeduction?.groupKey}
            deductionDefinitionId={employerDeduction?.id}
            constraint={employerDeduction?.deductionConstraint}
            currencyOptions={currencyOptions}
            countryCode={countryCode}
            postProbationBaseSalary={postProbationBaseSalary}
          />
          <EmployeeDeductionInput
            groupKey={employeeDeduction?.groupKey}
            employerDeductionDefinitionId={employerDeduction?.id}
            deductionDefinitionId={employeeDeduction?.id}
            constraint={employeeDeduction?.deductionConstraint}
            currencyOptions={currencyOptions}
            countryCode={countryCode}
          />
        </div>
      )}
    </div>
  );
};

export default DeductionsGroup;
