import * as yup from 'yup';

import i18n from 'i18n';

import { DocumentReadable } from '__generated__/graphql';

export const typeTest = (value: File | DocumentReadable): boolean => {
  if (!value) return true;
  let fileType = '';
  if ('type' in value) fileType = value.type;
  if ('contentType' in value) fileType = value.contentType as string;

  return ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'].includes(
    fileType,
  );
};

export const unsupportedFormatMessage = i18n.t(
  'contract-onboarding:document.unsupported-format-error',
  'Unsupported format',
);

export const fileSchema = yup
  .mixed()
  .test('type', unsupportedFormatMessage, typeTest);
