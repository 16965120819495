/** @jsxImportSource @emotion/react */
import React from 'react';

import { ReactComponent as BookV2 } from '@multiplier/assets/files/contract-onboarding/book-v2.svg';
import { ReactComponent as FileTextV2 } from '@multiplier/assets/files/contract-onboarding/file-text-v2.svg';
import { Icon, IconButton } from '@multiplier/common';
import tw, { TwStyle, theme } from 'twin.macro';

import { CardIcon } from 'insurance/member/components/card';

export const DownloadDetailsTitle = tw.p`text-text-primary text-p font-medium`;
export const DownloadDetailsDescription = tw.p`text-text-secondary text-ps font-normal`;

const DownloadItem: React.FC<{
  title: string;
  description: string;
  icon: string;
  link: string;
  downloadCardStyle?: TwStyle;
  cardIconStyle?: TwStyle;
  iconStyle?: TwStyle;
  downloadIcon?: string;
  downloadIconProps?: {
    fill?: string;
  };
}> = ({
  title,
  description,
  icon,
  link,
  cardIconStyle,
  iconStyle,
  downloadIcon,
  downloadIconProps,
  downloadCardStyle,
}) => {
  let IconComponent = (
    <Icon
      css={iconStyle}
      data-testid="insurance-icon"
      fill={theme`colors.transparent`}
      stroke={theme`colors.text-secondary`}
      name={icon}
    />
  );

  if (icon === 'book') {
    IconComponent = <BookV2 css={iconStyle} data-testid="insurance-icon" />;
  } else if (icon === 'file-text') {
    IconComponent = <FileTextV2 css={iconStyle} data-testid="insurance-icon" />;
  }

  return (
    <div tw="flex">
      <CardIcon variant="silver" css={cardIconStyle}>
        {IconComponent}
      </CardIcon>
      <div tw="mr-base">
        <DownloadDetailsTitle>{title}</DownloadDetailsTitle>
        <DownloadDetailsDescription>{description}</DownloadDetailsDescription>
      </div>
      <CardIcon variant="transparent" css={downloadCardStyle}>
        <IconButton
          data-testid="step-guide-download-icon"
          fill={downloadIconProps?.fill}
          name={downloadIcon ?? 'download-square'}
          size="small"
          onClick={() => {
            window.open(link, '_blank');
          }}
        />
      </CardIcon>
    </div>
  );
};

export default DownloadItem;
