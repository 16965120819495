import { useTranslation } from 'react-i18next';

import { ComplianceParamPeriodUnit } from '__generated__/graphql';

const useComplianceLabels = (): {
  [key: string]: (count: number) => string;
} => {
  const { t } = useTranslation('compliance');

  return {
    [ComplianceParamPeriodUnit.YEARS]: (count: number): string =>
      t('compliance.period.years', {
        defaultValue: 'years',
        count,
      }),
    [ComplianceParamPeriodUnit.MONTHS]: (count: number): string =>
      t('compliance.period.months', {
        defaultValue: 'months',
        count,
      }),
    [ComplianceParamPeriodUnit.DAYS]: (count: number): string =>
      t('compliance.period.days', {
        defaultValue: 'days',
        count,
      }),
    [ComplianceParamPeriodUnit.WEEKS]: (count: number): string =>
      t('compliance.period.weeks', {
        defaultValue: 'weeks',
        count,
      }),
    [ComplianceParamPeriodUnit.NONE]: (count: number): string =>
      t('compliance.period.none', {
        defaultValue: '',
        count,
      }),
  };
};

export default useComplianceLabels;
