/** @jsxImportSource @emotion/react */
import React from 'react';

import {
  Controller,
  FieldError,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StatelessFileUpload, TextInput } from '@multiplier/common';
import tw from 'twin.macro';

import i18nextUtil from 'app/utils/i18next-util';
import DetailRow from 'contract-onboarding/components/detail-row';
import { DetailRowType } from 'contract-onboarding/components/detail-row/types';
import {
  BANK_STATEMENT_MAX_SIZE,
  BankDetailsFormParams,
} from 'contract-onboarding/member/pages/onboarding/pages/bank-details';
import { getBankStatementsSchema } from 'contract-onboarding/services/bank-details-schema';

import {
  Contract,
  ContractType,
  Maybe,
  MemberChangeRequest,
} from '__generated__/graphql';

import { BankDetailsSectionVariant } from '../../index';
import UploadedBankStatementList from '../uploaded-bank-statement-list';

const DynamicBankDetailsSubForm: React.FC<{
  isEditing?: boolean;
  country: Contract['country'];
  member: Contract['member'];
  changeRequest?: Maybe<MemberChangeRequest>;
  haveSubmittedChangeRequestItem: (
    changeRequest?: Maybe<MemberChangeRequest> | undefined,
  ) => boolean;
  variant: BankDetailsSectionVariant;
  contractType: Contract['type'];
}> = ({
  isEditing,
  country,
  member,
  haveSubmittedChangeRequestItem,
  changeRequest,
  variant,
  contractType,
}) => {
  const { t } = useTranslation('contract-onboarding.member');
  const methods = useFormContext<BankDetailsFormParams>();

  const shouldShowMinimalView = React.useMemo(() => {
    const changeRequestDocsCount = (changeRequest?.files ?? []).length;
    const bankStatementDocsCount = (
      member?.bankAccounts?.[0]?.bankStatements ?? []
    ).length;
    const hasSubmittedChangeRequest = haveSubmittedChangeRequestItem(
      changeRequest,
    );

    let totalDocsCount = bankStatementDocsCount;

    if (hasSubmittedChangeRequest) totalDocsCount += changeRequestDocsCount;

    if (totalDocsCount > 2) {
      return true;
    }

    return false;
  }, [member, changeRequest]);

  const {
    formState: { errors },
    control,
    getValues,
    trigger,
  } = methods;

  const { append, replace } = useFieldArray({
    control,
    name: 'bankStatements',
  });

  const handleRemoveFile = (file: File) => {
    const allBankStatements = getValues('bankStatements');

    if (allBankStatements?.length) {
      const filteredFiles = allBankStatements.filter(
        (statementData: { statement: File }) =>
          !(
            statementData.statement.name === file.name &&
            statementData.statement.size === file.size &&
            statementData.statement.lastModified === file.lastModified
          ),
      );
      replace(filteredFiles);
      trigger();
    }
  };

  return (
    <DetailRow
      hideLabel={isEditing && variant === 'personal-details-view'}
      id="bank-statements"
      label={t(
        i18nextUtil.buildTransKeys(
          'label',
          'bank-details.proof-documents',
          i18nextUtil.asSegment(country),
        ),
        'Bank Statements',
        { ns: 'contract-onboarding.common' },
      )}
      value={member?.bankAccounts?.[0]?.bankStatements}
      newValue={changeRequest?.files}
      renderNewValue={
        <div
          css={
            variant === 'personal-details-view'
              ? tw`mt-large col-span-2`
              : tw`mt-large col-span-3`
          }
        >
          <UploadedBankStatementList
            shouldShowMinimalView={shouldShowMinimalView}
            files={changeRequest?.files}
          />
        </div>
      }
      renderValue={
        <div
          css={
            variant === 'personal-details-view'
              ? tw`mt-large col-span-2`
              : tw`mt-large col-span-3`
          }
        >
          <UploadedBankStatementList
            shouldShowMinimalView={shouldShowMinimalView}
            files={member?.bankAccounts?.[0]?.bankStatements}
          />
        </div>
      }
      isEditing={isEditing}
      type={
        haveSubmittedChangeRequestItem(changeRequest)
          ? DetailRowType.COMPARE_MODE
          : DetailRowType.BASIC_MODE
      }
    >
      <TextInput.Container
        css={
          variant === 'personal-details-view' ? tw`col-span-2` : tw`col-span-3`
        }
      >
        {isEditing && (
          <TextInput.Container>
            <TextInput.Label>
              {t(
                i18nextUtil.buildTransKeys(
                  'upload-note',
                  'bank-details.proof-documents',
                  i18nextUtil.asSegment(country),
                ),
                'Upload bank statement or blank cheque',
                { ns: 'contract-onboarding.common' },
              )}
            </TextInput.Label>
            <TextInput.Helper>
              {t(
                'bank-details.proof-document.helper',
                "The bank statement and/or cheque must clearly display the account holder's name and account number. We collect this information to verify the bank account number and ensure that payments are made to the correct account.",
              )}
            </TextInput.Helper>
          </TextInput.Container>
        )}
        <Controller
          name="bankStatements"
          control={control}
          rules={{
            validate: (value) => {
              if (!value?.length && contractType === ContractType.HR_MEMBER) {
                return true;
              }
              return getBankStatementsSchema(t, country)
                ?.validate(value)
                .then(() => true)
                .catch((e) => e?.message);
            },
          }}
          render={({ field: { value } }) => (
            <StatelessFileUpload
              minimal
              multiple
              maxSize={BANK_STATEMENT_MAX_SIZE}
              maxTotalSize={BANK_STATEMENT_MAX_SIZE}
              files={value?.map((bankStatement) => bankStatement.statement)}
              title={t(
                i18nextUtil.buildTransKeys(
                  'upload-note',
                  'bank-details.proof-documents',
                  i18nextUtil.asSegment(country),
                ),
                'Upload bank statement or blank cheque',
                { ns: 'contract-onboarding.common' },
              )}
              description={t(
                'upload-files.supported-files',
                'Files Supported: PDF, PNG, JPG (Max 5mb)',
              )}
              data-testid="bank-statement-upload"
              onFileDrop={(files: File[]) => {
                files.forEach((file) => append({ statement: file }));
                trigger();
              }}
              onRemoveFile={handleRemoveFile}
            />
          )}
        />
        {errors?.bankStatements && (
          <TextInput.Error>
            {((errors?.bankStatements as unknown) as FieldError)?.root?.message}
          </TextInput.Error>
        )}
      </TextInput.Container>
    </DetailRow>
  );
};

export default DynamicBankDetailsSubForm;
