/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import {
  Controller,
  FieldPath,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from '@multiplier/common';
import tw from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import { notEmpty } from 'app/utils/array';
import * as DropdownText from 'common/components/dropdown-text';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import { CurrencyCode, RateFrequency } from '__generated__/graphql';

import { FixedPayInputProps, filterRateFrequencies } from '../../index';

type PayInputCurrencyHelperProps = Pick<FixedPayInputProps, 'fieldPath'>;

export const PayInputCurrencyHelper: React.FC<PayInputCurrencyHelperProps> = ({
  fieldPath,
}) => {
  const { watch } = useFormContext();
  return (
    <CurrencyHelper
      tw="mt-12"
      referenceCurrency={
        watch(`${fieldPath}.currency` as FieldPath<CompensationFormValues>) as
          | CurrencyCode
          | undefined
      }
      amount={
        watch(`${fieldPath}.amount` as FieldPath<CompensationFormValues>) as
          | number
          | undefined
      }
    />
  );
};

type CurrencyInputProps = Pick<
  FixedPayInputProps,
  'fieldPath' | 'currencyOptions' | 'fieldArrayElement'
> & { disabled?: boolean };

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  fieldPath,
  currencyOptions,
  fieldArrayElement,
  disabled = false,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { control } = useFormContext();

  return (
    <Controller
      name={`${fieldPath}.currency` as FieldPath<CompensationFormValues>}
      control={control}
      defaultValue={fieldArrayElement?.currency}
      render={({ field: { value, onChange } }) => (
        <ComboBox
          data-testid="currency-select"
          variant="inline"
          showArrow
          value={value as string}
          dropdownValues={currencyOptions || []}
          placeholder={t(
            'compensation.currency.placeholder',
            'Type a currency/country',
          )}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    />
  );
};

type RateFrequencyInputProps = Pick<
  FixedPayInputProps,
  | 'fieldPath'
  | 'countryRateFrequencies'
  | 'payComponent'
  | 'contractType'
  | 'contractTerm'
  | 'country'
  | 'isRateFrequencyInputDisabled'
>;

export const RateFrequencyInput: React.FC<RateFrequencyInputProps> = ({
  fieldPath,
  countryRateFrequencies,
  payComponent,
  contractType,
  contractTerm,
  country,
  isRateFrequencyInputDisabled,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { control, setValue } = useFormContext();

  const rateFrequencyLabel: { [key: string]: string } = useMemo(
    () => ({
      [RateFrequency.HOURLY]: t('rate-frequency.hourly', 'Hour'),
      [RateFrequency.DAILY]: t('rate-frequency.daily', 'Daily'),
      [RateFrequency.WEEKLY]: t('rate-frequency.weekly', 'Weekly'),
      [RateFrequency.SEMIMONTHLY]: t(
        'rate-frequency.semi-monthly',
        'Semi Month',
      ),
      [RateFrequency.MONTHLY]: t('rate-frequency.monthly', 'Month'),
      [RateFrequency.ANNUALLY]: t('rate-frequency.annually', 'Annum'),
    }),
    [],
  );

  const filteredRateFrequencies = useMemo(
    () =>
      filterRateFrequencies(
        countryRateFrequencies || [],
        contractTerm,
        contractType,
        country,
      ).filter(notEmpty),
    [countryRateFrequencies, contractType, contractTerm, country],
  );

  const dropdownValues = useMemo(
    () =>
      filteredRateFrequencies?.map((frequency) => ({
        title: rateFrequencyLabel[frequency],
        value: frequency,
      })),
    [filteredRateFrequencies],
  );

  const probationBasePayFrequency = useWatch({
    control,
    name: 'probationBasePay.frequency',
  });

  useEffect(() => {
    if (isRateFrequencyInputDisabled && probationBasePayFrequency) {
      setValue('basePay.frequency', probationBasePayFrequency);
    }
  }, [isRateFrequencyInputDisabled, probationBasePayFrequency]);

  const getPlaceholder = () => {
    if (
      isRateFrequencyInputDisabled &&
      fieldPath === 'basePay' &&
      !probationBasePayFrequency
    )
      return t(`compensation.frequency.empty-placeholder`, '-- --');

    return t(
      `compensation.${payComponent?.label ?? ''}.frequency.placeholder`,
      'Select pay rate',
    );
  };

  return (
    <Controller
      name={`${fieldPath}.frequency` as FieldPath<CompensationFormValues>}
      control={control}
      render={({ field: { value, onChange } }) => (
        <ComboBox
          tw="w-[150px] h-[48px]"
          disabled={isRateFrequencyInputDisabled}
          data-testid="rate-frequency-select"
          variant="default"
          showArrow
          value={value as string}
          dropdownValues={dropdownValues || []}
          placeholder={getPlaceholder()}
          onChange={onChange}
        />
      )}
    />
  );
};

type AmountInputProps = Pick<
  FixedPayInputProps,
  'fieldPath' | 'fieldArrayElement'
>;

export const AmountInput: React.FC<AmountInputProps> = ({
  fieldPath,
  fieldArrayElement,
}) => {
  const { register, trigger } = useFormContext();
  return (
    <DropdownText.Input
      currency
      type="number"
      step="0.01"
      placeholder="0"
      tw="appearance-none"
      data-testid="currency-input"
      divStyles={tw`flex-grow`}
      {...register(`${fieldPath}.amount`)}
      onChange={(e) => {
        register(`${fieldPath}.amount`).onChange(e);
        trigger('additionalPays');
      }}
      defaultValue={fieldArrayElement?.amount || ''}
    />
  );
};
