/** @jsxImportSource @emotion/react */
import React from 'react';

import { EditableSingleDetailRowProps } from 'contract-onboarding/components/detail-row/types';
import {
  DetailLabel,
  DetailValue,
} from 'contract-onboarding/components/layout';

import 'twin.macro';

import { BankDetailsSectionVariant } from '../../index';

const FormItem: React.FC<
  React.PropsWithChildren<{
    id: EditableSingleDetailRowProps['id'];
    label: EditableSingleDetailRowProps['label'];
    value: EditableSingleDetailRowProps['value'];
    valueElement?: React.ReactNode;
    isEditing: EditableSingleDetailRowProps['isEditing'];
    variant?: BankDetailsSectionVariant;
  }>
> = ({ label, id, isEditing, value, valueElement, children, variant }) => {
  if (!isEditing && !value) return null;
  if (!isEditing) {
    if (!valueElement)
      return (
        <>
          <DetailLabel htmlFor={id}>{(label || '') as string}</DetailLabel>
          <DetailValue>{value as string}</DetailValue>
        </>
      );
    return (
      <>
        <DetailLabel htmlFor={id}>{(label || '') as string}</DetailLabel>
        <div tw="col-span-3 text-ps text-text-tertiary">{valueElement}</div>
      </>
    );
  }
  if (variant === 'member-onboarding') {
    return (
      <>
        <DetailLabel htmlFor={id}>{(label || '') as string}</DetailLabel>
        <div tw="col-span-3">{children}</div>
      </>
    );
  }
  return (
    <div>
      <DetailLabel htmlFor={id}>{(label || '') as string}</DetailLabel>
      {children}
    </div>
  );
};

export default FormItem;
