import { PendingOn } from 'contract-onboarding/components/pending-on-pill';
import OnboardingAction from 'contract-onboarding/models/onboarding-action';

import { ContractOnboardingStatusConfig } from '../status-config';

const revoked: ContractOnboardingStatusConfig = {
  title: (t) =>
    t('onboarding-phase.revoked.title', 'Send contract for signatures'),
  description: (t, firstName, gender) =>
    t('onboarding-phase.revoked.description', {
      defaultValue:
        'Invite {{firstName}} to join Multiplier and to collect details required for the completion of the contract. Once {{firstName}} provides his details we will verify and send you the completed contract between 1-2 working days.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) => t('onboarding-phase.revoked.label', ''),
  pendingOn: () => PendingOn.YOU,
  pendingLabel: (t): string =>
    t('onboarding-phase.created.status', 'Pending on You'),
  primaryAction: OnboardingAction.REVIEW_CONTRACT,
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default revoked;
