import { useGetTeam } from 'team/company/hooks';

import {
  PendingOnboardings,
  getPendingOnboardings,
} from './pending-onboardings';

export type OnboardingNeedsAttentionReturnTypes = {
  loading: boolean;
  pendingOnboardings: PendingOnboardings | null;
};

export const useOnboardingNeedsAttention = (): OnboardingNeedsAttentionReturnTypes => {
  const { team, loading } = useGetTeam();

  const pendingOnboardings = getPendingOnboardings(team);

  return {
    pendingOnboardings,
    loading,
  };
};
