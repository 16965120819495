import { isNil } from 'lodash';

const getDateTimeWithOffset = (date: string) => {
  const serverDate = new Date(date);
  const modifyDateToHaveConstantHours = new Date(
    serverDate.valueOf() + serverDate.getTimezoneOffset() * 60000,
  );
  return new Date(modifyDateToHaveConstantHours);
};

const getDateTimeWithoutOffset = () => {
  const modifyDateToHaveConstantHours = new Date(Date.now()).setHours(
    0,
    0,
    0,
    0,
  );
  return new Date(modifyDateToHaveConstantHours);
};

const setDateTimeWithoutOffset = (date: string) => {
  const modifyDateToHaveConstantHours = new Date(date).setHours(0, 0, 0, 0);
  return new Date(modifyDateToHaveConstantHours);
};

/* This is a duplication of setDateTimeWithoutOffset which has defined to fix a
cross browser issue in additional pay items. The date format yyyy-MM-dd is not
supported by Safari, so it should be converted the format yyyy/MM/dd.
Since the conversion could break some other flows, a separate method is defined.
ex: "2022/11/22T09:52:29.790769" returns an invalid date if this change was done
 */
const setDateTimeWithoutOffsetForAdditionalPayItem = (date: string) => {
  const modifyDateToHaveConstantHours = new Date(
    date.replace(/-/g, '/'),
  ).setHours(0, 0, 0, 0);
  return new Date(modifyDateToHaveConstantHours);
};

const timezoneRemove = (
  date?: Date | string,
  isFromAdditionalPayItem = false,
): Date => {
  const serverDateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  if (isNil(date)) {
    return getDateTimeWithoutOffset();
  }
  if (date instanceof Date) {
    return date;
  }
  if (serverDateRegex.test(date)) {
    return getDateTimeWithOffset(date);
  }
  return isFromAdditionalPayItem
    ? setDateTimeWithoutOffsetForAdditionalPayItem(date)
    : setDateTimeWithoutOffset(date);
};

export default timezoneRemove;
