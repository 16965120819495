/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from 'react';

import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import {
  useShouldDisplaySorFields,
  useSorOnboardingContext,
} from '@multiplier/hris-member-management';
import { format, parse } from 'date-fns';
import { isUndefined } from 'lodash';
import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import tw, { theme } from 'twin.macro';

import WorkShiftInputComponent from 'contract-onboarding/company/components/work-shift-input';
import {
  convertWorkShiftToWorkShiftInput,
  getDefaultWorkShiftStandards,
} from 'contract-onboarding/company/services/work-shift-utils';
import { eligibilityStep } from 'contract-onboarding/company/vars';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';

import {
  Contract,
  ContractTerm,
  ContractType,
  Country,
  CountryCode,
  CountryWorkStatus,
  FetchStage,
  Gender,
  GetCountryComplianceLimitationsQuery,
  GetPositionsForIntegrationsQuery,
  GetSupportedCountriesQuery,
  Manager,
  Maybe,
  WorkShiftInput,
  useGetCompanyNameQuery,
} from '__generated__/graphql';

import stepConfig from '../../step-config';
import ContractTermSelector from './components/contract-term-selector';
import DepartmentSelector from './components/department-selector';
import EmailInput from './components/email-input';
import EmployeeIdInput from './components/employee-id-input';
import EmploymentStatusSelector from './components/employment-status-selector';
import EndDateSelector from './components/end-date-selector';
import FirstNameInput from './components/first-name-input';
import GenderSelector from './components/gender-selector';
import JobDescriptionInput from './components/job-description-input';
import JobTitleInput from './components/job-title-input';
import LastNameInput from './components/last-name-input';
import LegalDetailSection from './components/legal-details-section';
import { OldPayrollCutoffDateHint } from './components/payroll-cutoff-date-hint';
import PeopleManagerSection from './components/people-manager-section';
import ReportingManagerSelector from './components/reporting-manager-selector';
import StartDateSelector from './components/start-date-selector';
import StartDateWarning from './components/start-date-warning';
import TaxResidencySelector from './components/tax-residency-selector';
import WorkEmailInput from './components/work-email-input';
import {
  getContractTerm,
  getMemberDataRequirementValues,
  mapDefaultLegalDataRequirementFields,
} from './helpers';
import useBasicDetailsValidationSchema from './validation-schema';

export enum EmploymentStatus {
  NEW_EMPLOYEE = 'NEW_EMPLOYEE',
  EXISTING_EMPLOYEE = 'EXISTING_EMPLOYEE',
}

export interface PreDataRequirementType {
  key: string;
  value?: string | number | Date | null;
  type: 'text' | 'date';
}

export interface BasicDetailsFormValues {
  contractType: Maybe<ContractType>;
  countryWorkStatus: Maybe<CountryWorkStatus>;
  countryStateCode: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  gender: Maybe<Gender>;
  taxResidency: Maybe<CountryCode>;
  email: Maybe<string>;
  legalEntityId: Maybe<string> | undefined;
  employeeId: Maybe<string> | undefined;
  workEmail: Maybe<string>;
  jobTitle: Maybe<string>;
  contractTerm: Maybe<ContractTerm>;
  employmentStatus: Maybe<EmploymentStatus>;
  startOn: Date;
  endOn: Date;
  preDataRequirements?: PreDataRequirementType[];
  scope: string;
  workShift?: WorkShiftInput;
  reportingManager?: string;
  isPeopleManager?: boolean;
  department?: string;
}

export type BasicDetailsProps = OnboardingStepProps & {
  contractType: ContractType | undefined;
  legalEntityId: Maybe<string> | undefined;
  country: Maybe<CountryCode> | undefined;
  state?: string;
  countryWorkStatus: Maybe<CountryWorkStatus> | undefined;
  countryCompliance: GetCountryComplianceLimitationsQuery | undefined;
  contract: Contract;
  existingContractId: string | undefined;
  loading: boolean;
  loadCountryComplianceLimitations: (variables: {
    country: CountryCode;
    countryStateCode?: string;
    workStatus: Maybe<CountryWorkStatus> | undefined;
    contractType?: ContractType;
    fetchStage: Maybe<FetchStage> | undefined;
  }) => void;
  saveDetails: (values: BasicDetailsFormValues) => void;
  countryLabourStandards?: Country['labourStandards'];
  supportedCountries?: GetSupportedCountriesQuery;
  supportedJobPositions?: GetPositionsForIntegrationsQuery['getPositionsForIntegrations'];
  manager?: Manager | undefined | null;
};

export const InputInfo: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => (
  <div tw="flex" data-testid="start-date-warning" {...props}>
    <Icon name="circle-warning" fill={theme`colors.warning03`} tw="mr-6" />
    <div tw="text-highlight text-ps">{children}</div>
  </div>
);

const useChnCountryPrefill = (
  methods: UseFormReturn<BasicDetailsFormValues>,
  selectedCountry: CountryCode | null | undefined,
) => {
  const { watch, setValue } = methods;

  const preDataRequirements = watch('preDataRequirements');

  const addressCountry = useMemo(
    () =>
      preDataRequirements?.filter((req) => req?.key === 'address.country')?.[0]
        ?.value,
    [JSON.stringify(preDataRequirements)],
  );

  useEffect(() => {
    if (selectedCountry === CountryCode.CHN) {
      const addressCity = preDataRequirements?.filter(
        (req) => req?.key === 'address.city',
      )?.[0]?.value;

      if (addressCity && addressCountry === CountryCode.CHN) {
        preDataRequirements?.forEach((req, idx) => {
          if (req?.key === 'address.state') {
            setValue(
              `preDataRequirements.${idx}.value`,
              (addressCity as string).split('/')[1],
            );
          }
        });
      }
    } else {
      const addressState = preDataRequirements?.filter(
        (req) => req?.key === 'address.state',
      )?.[0]?.value;

      if (addressState && addressCountry === CountryCode.CHN) {
        preDataRequirements?.forEach((req, idx) => {
          if (req?.key === 'address.city') {
            setValue(
              `preDataRequirements.${idx}.value`,
              (addressState as string).split('/')[1],
            );
          }
        });
      }
    }
  }, [JSON.stringify(preDataRequirements)]);
};

export type ComplianceLimitation = NonNullable<
  NonNullable<
    NonNullable<GetCountryComplianceLimitationsQuery['country']>['compliance']
  >['limitations']
>[number];

export const BasicDetailsView: React.FC<BasicDetailsProps> = ({
  contract,
  contractType,
  legalEntityId,
  country,
  countryCompliance,
  countryLabourStandards,
  countryWorkStatus,
  currentStep,
  existingContractId,
  loadCountryComplianceLimitations,
  loading,
  onboardingSteps,
  saveDetails,
  state,
  supportedCountries,
  supportedJobPositions,
  manager,
}) => {
  const disabledFields = flow(
    compact,
    keyBy<ComplianceLimitation>('key'),
  )(countryCompliance?.country?.compliance?.limitations);

  const selectedLegalEntityId = contract.legalEntityId ?? legalEntityId;
  const selectedCountry = contract.country ?? country;
  const selectedState = contract.countryStateCode ?? state;
  const selectedWorkStatus = contract?.workStatus ?? countryWorkStatus;
  /* contractType reactive variable is not set when reloading an onboarding contract. so we need to set it with contract.type */
  const selectedContractType = contract?.type ?? contractType;

  const { data: companyData } = useGetCompanyNameQuery();
  const companyId = companyData?.company?.id;

  const isSingleOnboardingContract =
    useFeature(AppFeature.SINGLE_ONBOARDING).on &&
    selectedContractType === ContractType.HR_MEMBER;

  const sorOnboardingContext = useSorOnboardingContext({
    type: selectedContractType,
    companyId,
    legalEntityId: selectedLegalEntityId,
  });

  const {
    shouldDisplayHrisFields,
    shouldDisplayEmployeeIdAndWorkEmail,
    hideLegalDetailsSection,
    shouldDisplayReportingManager,
  } = useShouldDisplaySorFields({
    sorOnboardingContext,
  });

  const {
    schema,
    memberDataRequirementFields,
  } = useBasicDetailsValidationSchema({
    dataRequirements: hideLegalDetailsSection
      ? undefined
      : countryCompliance?.country?.compliance?.memberDataRequirements,
    country: selectedCountry,
    countryStateCode: selectedState,
    contractType: selectedContractType,
    workStatus: selectedWorkStatus,
    legalEntityId: selectedLegalEntityId,
    hasSupportedJobPositions: Boolean(
      supportedJobPositions && supportedJobPositions.length > 0,
    ),
  });

  const methods = useForm<BasicDetailsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isValid },
  } = methods;

  const hasWorkshiftTiming = useMemo(
    () => !isUndefined(contract?.workShift) && !!contract.workShift,
    [contract?.workShift],
  );

  const setWorkShift = useCallback(
    (workShiftInput?: WorkShiftInput) => {
      setValue('workShift', workShiftInput, {
        shouldValidate: true,
      });
    },
    [setValue],
  );

  useEffect(() => {
    if (
      selectedCountry &&
      selectedContractType &&
      selectedContractType !== ContractType.FREELANCER
    ) {
      loadCountryComplianceLimitations({
        country: selectedCountry,
        countryStateCode: selectedState,
        workStatus: selectedWorkStatus,
        contractType: selectedContractType,
        fetchStage: FetchStage.CONTRACT_GENERATION,
      });
    }
  }, [
    selectedCountry,
    selectedState,
    selectedWorkStatus,
    selectedContractType,
  ]);

  useChnCountryPrefill(methods, selectedCountry);

  const reportsToManagerId =
    manager?.reportsToManager?.id ?? contract?.reportsToManager?.id;

  useDeepCompareEffect(() => {
    if (contract && contract.id) {
      reset({
        ...getValues(),
        legalEntityId: contract.legalEntityId,
        employeeId: contract.employeeId,
        contractType: contract.type,
        taxResidency: contract.country,
        countryWorkStatus: contract.workStatus,
        countryStateCode: contract.countryStateCode,
        contractTerm: contract.term,
        firstName: contract.member?.firstName,
        lastName: contract.member?.lastName,
        gender: contract.member?.gender,
        email:
          contract.member?.emails && contract.member.emails[0]
            ? contract.member.emails[0].email
            : '',
        jobTitle: contract.position,
        employmentStatus: contract.alreadyHired
          ? EmploymentStatus.EXISTING_EMPLOYEE
          : EmploymentStatus.NEW_EMPLOYEE,
        startOn: contract.startOn,
        endOn: contract.endOn || undefined,
        scope: contract.scope ? contract.scope : '',
        workEmail: contract.workEmail,
        preDataRequirements: memberDataRequirementFields.map((f) => ({
          key: String(f.key),
          value:
            f.key === 'dateOfBirth'
              ? contract?.member?.dateOfBirth
                ? format(
                    parse(
                      contract.member.dateOfBirth.substring(0, 10),
                      'yyyy-MM-dd',
                      new Date(),
                    ),
                    'yyyy-MM-dd',
                  )
                : ''
              : getMemberDataRequirementValues(f, contract?.member),
          type: f?.dataType?.__typename === 'DateField' ? 'date' : 'text',
        })),
        workShift: hasWorkshiftTiming
          ? convertWorkShiftToWorkShiftInput(contract?.workShift)
          : undefined,
        reportingManager: reportsToManagerId,
        isPeopleManager: Boolean(manager),
        department: contract.orgAttributes?.department?.id,
      });
    } else {
      reset({
        ...getValues(),
        legalEntityId: selectedLegalEntityId,
        contractType: selectedContractType,
        taxResidency: country,
        countryWorkStatus,
        countryStateCode: state,
        contractTerm:
          selectedContractType === ContractType.FREELANCER ||
          selectedContractType === ContractType.CONTRACTOR
            ? ContractTerm.FIXED
            : ContractTerm.PERMANENT,
        ...(selectedContractType === ContractType.HR_MEMBER && {
          employmentStatus: EmploymentStatus.NEW_EMPLOYEE,
        }),
        preDataRequirements: mapDefaultLegalDataRequirementFields(
          memberDataRequirementFields,
          selectedCountry,
        ),
        workShift: hasWorkshiftTiming
          ? getDefaultWorkShiftStandards(countryLabourStandards?.workShift)
          : undefined,
      });
    }
  }, [
    reset,
    JSON.stringify(contract),
    selectedContractType,
    country,
    countryWorkStatus,
    state,
    memberDataRequirementFields,
    countryLabourStandards,
    manager,
    reportsToManagerId,
  ]);

  useDeepCompareEffect(() => {
    if (contract.id) {
      setValue(
        'contractTerm',
        getContractTerm(contract.term === ContractTerm.FIXED, disabledFields),
      );
    } else {
      setValue(
        'contractTerm',
        getContractTerm(
          contractType === ContractType.FREELANCER ||
            contractType === ContractType.CONTRACTOR,
          disabledFields,
        ),
      );
    }
  }, [contract, disabledFields]);

  return (
    <StepLayout data-testid="basic-details-view">
      <FormProvider {...methods}>
        <FormLayout
          onSubmit={handleSubmit((values) => {
            saveDetails(values);
          })}
        >
          <FormCard>
            <div tw="grid grid-cols-2 gap-x-base gap-y-large">
              <FirstNameInput />
              <LastNameInput />
              <GenderSelector country={selectedCountry} />
              <TaxResidencySelector supportedCountries={supportedCountries} />
            </div>
            <div
              css={[
                tw`grid grid-cols-2 gap-x-base gap-y-large`,
                !shouldDisplayEmployeeIdAndWorkEmail && tw`grid-cols-1`,
              ]}
            >
              <EmailInput />
              {shouldDisplayEmployeeIdAndWorkEmail && <WorkEmailInput />}
            </div>
            {(shouldDisplayEmployeeIdAndWorkEmail ||
              isSingleOnboardingContract) && (
              <EmployeeIdInput
                optional={sorOnboardingContext.isSorOnboardingEnabled}
              />
            )}
          </FormCard>
          {!hideLegalDetailsSection && (
            <LegalDetailSection
              contractType={contract?.type ?? contractType}
              contractWorkStatus={selectedWorkStatus}
              contractCountry={selectedCountry}
              countryCompliance={countryCompliance}
              memberDataRequirementFields={memberDataRequirementFields}
            />
          )}
          <FormCard tw="rounded-base bg-background-white p-large flex flex-col gap-y-large">
            <div
              css={[
                tw`grid grid-cols-2 gap-x-base gap-y-large`,
                !shouldDisplayHrisFields && tw`grid-cols-1 gap-y-large`,
              ]}
            >
              <JobTitleInput supportedJobPositions={supportedJobPositions} />
              {shouldDisplayReportingManager && (
                <ReportingManagerSelector companyId={companyId} />
              )}
              {shouldDisplayHrisFields && <DepartmentSelector />}
            </div>
            <div tw="grid grid-cols-2 gap-x-base gap-y-large">
              <ContractTermSelector
                disabledFields={disabledFields}
                selectedCountry={selectedCountry}
              />
              <EmploymentStatusSelector />
              <StartDateSelector
                selectedCountry={selectedCountry}
                selectedCountryState={selectedState}
                selectedContractType={selectedContractType}
                selectedWorkStatus={selectedWorkStatus}
                selectedLegalEntityId={selectedLegalEntityId}
              />
              {watch('contractTerm') === ContractTerm.FIXED ? (
                <EndDateSelector
                  selectedCountry={selectedCountry}
                  selectedCountryState={selectedState}
                  selectedContractType={selectedContractType}
                />
              ) : (
                <div data-testid="spacer" />
              )}
              <StartDateWarning />
            </div>
            <OldPayrollCutoffDateHint
              startDate={watch('startOn')}
              contractType={contractType}
              sorOnboardingContext={sorOnboardingContext}
            />
            {hasWorkshiftTiming && (
              <WorkShiftInputComponent
                country={selectedCountry}
                workShift={watch('workShift')}
                onSave={setWorkShift}
                countryStateCode={selectedState}
              />
            )}
            <JobDescriptionInput schema={schema} />
          </FormCard>
          {shouldDisplayHrisFields && <PeopleManagerSection />}
          <StepNavigationFooter
            disabled={!isValid}
            submitLoading={loading}
            currentStep={currentStep}
            onboardingSteps={onboardingSteps}
            stepConfig={stepConfig}
            contractId={existingContractId}
            backButtonCallback={() => {
              eligibilityStep({});
            }}
          />
        </FormLayout>
      </FormProvider>
    </StepLayout>
  );
};
