import { useNavigate } from 'react-router-dom';

import {
  useCreateAndDeleteManager,
  useValidateAndAssignManager,
} from '@multiplier/hris-member-management';
import { format, isValid } from 'date-fns';

import {
  GetTeamDocument,
  GetTeamQuery,
  useCreateContractMutation,
  useCreateMemberMutation,
  useDepartmentAssignEmployeesMutation,
  useUpdatePreFetchDetailsMutation,
  useUpdateWorkEmailMutation,
} from '__generated__/graphql';

import {
  BasicDetailsFormValues,
  EmploymentStatus,
} from '../pages/definition-phase/pages/basic-details/basic-details';
import stepConfig from '../pages/definition-phase/step-config';

export const useSubmitBasicDetails = (): {
  onSubmit: (values: BasicDetailsFormValues) => Promise<void>;
  loading: boolean;
} => {
  const navigate = useNavigate();

  const [
    createMember,
    { loading: loadingCreateMember },
  ] = useCreateMemberMutation();
  const [
    updateLegalDetails,
    { loading: loadingLegalDetailsUpdate },
  ] = useUpdatePreFetchDetailsMutation();

  const [
    createContract,
    { loading: loadingCreateContract },
  ] = useCreateContractMutation({
    update: (cache, { data }) => {
      const teamData = cache.readQuery({
        query: GetTeamDocument,
      }) as GetTeamQuery;

      let company;

      if (teamData) company = teamData?.company;

      if (data?.contractCreate?.member?.id && company?.id) {
        cache.writeQuery({
          query: GetTeamDocument,
          data: {
            company: {
              id: company.id,
              contracts: [
                ...(company?.contracts ?? []),
                {
                  ...data?.contractCreate,
                  compensation: {
                    __typename: 'Compensation',
                    basePay: null,
                  },
                },
              ],
              __typename: 'Company',
            },
          },
        });
      }
    },
  });

  const {
    handleAssignManager,
    isValidating,
    assigningManager,
  } = useValidateAndAssignManager();

  const { creatingManager, createPeopleManager } = useCreateAndDeleteManager();

  const [
    assignEmployeesToDepartment,
    { loading: departmentAssignLoading },
  ] = useDepartmentAssignEmployeesMutation();

  const [
    updateWorkEmail,
    { loading: updatingWorkEmail },
  ] = useUpdateWorkEmailMutation();

  const onSubmit = async (values: BasicDetailsFormValues) => {
    const member = await createMember({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          gender: values.gender,
        },
      },
    });

    if (
      !member.data?.memberCreate?.id ||
      !values.contractType ||
      !values.taxResidency
    )
      return;

    const contract = await createContract({
      variables: {
        input: {
          memberId: member.data.memberCreate.id,
          type: values.contractType,
          country: values.taxResidency,
          workStatus: values.countryWorkStatus,
          countryStateCode: values.countryStateCode,
          alreadyHired:
            values.employmentStatus === EmploymentStatus.EXISTING_EMPLOYEE,
          position: values.jobTitle,
          term: values.contractTerm,
          startOn:
            isValid(values.startOn) &&
            `${format(values.startOn, 'yyyy-MM-dd')}T00:00:00`,
          endOn: isValid(values.endOn)
            ? `${format(values.endOn, 'yyyy-MM-dd')}T00:00:00`
            : undefined,
          scope: values.scope,
          workShift: values?.workShift,
          employeeId: values?.employeeId,
          legalEntityId: values?.legalEntityId,
          email: values.email ?? '',
        },
      },
    });

    if (values?.preDataRequirements && values?.preDataRequirements?.length)
      await updateLegalDetails({
        variables: {
          memberId: member.data.memberCreate.id,
          dateFieldInputs: values?.preDataRequirements
            ?.filter((req) => req.type === 'date')
            .map((req) => ({
              key: req.key,
              value: req.value,
            })),
          memberDataInputs: values?.preDataRequirements
            ?.filter((req) => req.type === 'text')
            .map((req) => ({
              key: req.key,
              value: req.value as string | null | undefined,
            })),
        },
      });

    // Org management data section - start
    let managerId;

    if (contract?.data?.contractCreate?.id && values.isPeopleManager) {
      await createPeopleManager(contract?.data?.contractCreate?.id).then(
        ({ data }) => {
          managerId = data?.managerCreate?.id;
        },
      );
    }

    if (contract.data?.contractCreate?.id && values.reportingManager) {
      await handleAssignManager(
        values.reportingManager,
        contract.data?.contractCreate.id,
        managerId,
      );
    }

    if (contract?.data?.contractCreate?.id && values.department) {
      await assignEmployeesToDepartment({
        variables: {
          input: {
            departmentId: values.department,
            employeeContractIds: managerId
              ? []
              : [contract?.data?.contractCreate?.id],
            employeeManagerIds: managerId ? [managerId] : [],
          },
        },
      });
    }
    // Org management data section - end

    if (contract?.data?.contractCreate?.id && values.workEmail) {
      await updateWorkEmail({
        variables: {
          id: contract.data?.contractCreate?.id,
          email: {
            name: 'work',
            value: values.workEmail,
          },
        },
      });
    }

    if (contract.data?.contractCreate?.onboarding?.current)
      navigate(
        stepConfig[
          contract.data?.contractCreate?.onboarding?.current
        ].directLink(contract.data?.contractCreate.id),
      );
  };
  return {
    onSubmit,
    loading:
      loadingCreateMember ||
      loadingLegalDetailsUpdate ||
      loadingCreateContract ||
      isValidating ||
      assigningManager ||
      creatingManager ||
      departmentAssignLoading ||
      updatingWorkEmail,
  };
};

export default useSubmitBasicDetails;
