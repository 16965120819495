/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';

import 'twin.macro';

import { Contract, useInviteMemberMutation } from '__generated__/graphql';

const RequestDetailsAction: React.FC<{
  contractId: Contract['id'];
  contractType: Contract['type'];
  legalEntityId: Contract['legalEntityId'];
}> = ({ contractId, contractType, legalEntityId }) => {
  const { t } = useTranslation('contract-onboarding.company');
  const sorOnboardingContext = useSorOnboardingContext({
    type: contractType,
    legalEntityId,
  });

  const [inviteMember, { loading }] = useInviteMemberMutation();

  const handleSubmit = async () => {
    if (contractId) await inviteMember({ variables: { id: contractId } });
  };

  // HRIS flow or GP flow
  if (
    sorOnboardingContext.isGlobalPayrollOnlyFlow ||
    sorOnboardingContext.isHrisOnlyFlow ||
    sorOnboardingContext.isGlobalPayrollAndHrisFlow
  ) {
    return (
      <Button
        size="medium"
        loading={loading}
        disabled={loading}
        onClick={handleSubmit}
      >
        {t('onboarding-phase.add-employee.button', 'Add employee')}
      </Button>
    );
  }

  return (
    <Button
      size="medium"
      loading={loading}
      disabled={loading}
      onClick={handleSubmit}
    >
      {t('onboarding-phase.request-details.button', 'Request Details')}
    </Button>
  );
};

export default RequestDetailsAction;
