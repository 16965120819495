import { gql } from '@apollo/client';

export default gql`
  fragment changeRequestItemsFragment on MemberChangeRequest {
    items {
      ... on StringParam {
        key
        strValue: value
      }
      ... on IntParam {
        key
        intValue: value
      }
      ... on DateParam {
        key
        dateValue: value
      }
      ... on GenderParam {
        key
        genderValue: value
      }
      ... on MemberNationalogyParam {
        key
        nationalogyValue: value {
          type
          country
        }
      }
      ... on PhoneNumberParam {
        key
        phoneValue: value {
          phoneNo
          type
        }
      }
      ... on AddressParam {
        key
        addressValue: value {
          street
          line1
          line2
          city
          state
          country
          province
          zipcode
          postalCode
        }
      }
      ... on EmailAddressParam {
        key
        emailValue: value {
          email
          type
        }
      }
      ... on BankAccountParam {
        key
        bankAccountValue: value {
          bankName
          accountName
          accountNumber
          branchName
          localBankCode
          swiftCode
          paymentAccountRequirementType
          version
          accountDetails {
            key
            value
          }
        }
      }
      ... on LegalDataParam {
        key
        legalValue: value {
          key
          value
          identifier
        }
      }
      ... on LegalDocumentParam {
        key
        legalDocumentValue: value {
          key
          label
          category
          financialYear
          files {
            id
            name
            extension
            contentType
            downloadUrl
            size
          }
        }
      }
    }
  }
`;
