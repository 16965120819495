import React from 'react';

import { useTranslation } from 'react-i18next';

import { OrgDirectoryFilterConfig } from 'hooks/get-org-directory';

import { ConditionInput, ConditionKey } from '__generated__/graphql';

import { conditionKeyLabel, operatorLabel } from '../../../constants';

import 'twin.macro';

interface RulesListProps {
  rules: ConditionInput[];
  rulesConfig: OrgDirectoryFilterConfig[];
}

const RulesList: React.FC<RulesListProps> = ({ rules, rulesConfig }) => {
  const { t } = useTranslation('hris.common');
  const getValuesLabel = (
    values: string[],
    conditionKey: ConditionKey,
  ): string[] => {
    const options =
      rulesConfig.find((item) => item.key === conditionKey)?.options ?? [];
    if (!options.length) return [];
    return values
      .map((value) => options.find((x) => x.value === value)?.label ?? '')
      .filter(Boolean);
  };
  return (
    <div
      data-testid="rules-list"
      tw="flex flex-col w-full pt-small mt-small border-t border-border-primary gap-extra-small overflow-auto"
    >
      {rules.map((rule, idx) => (
        <div tw="flex items-center gap-extra-small" key={rule.key}>
          <div tw="rounded-tiny border border-border-secondary bg-background-white py-tiny px-extra-small gap-tiny text-ps flex items-baseline min-w-max max-w-[360px]">
            <span tw="font-semibold">{conditionKeyLabel(t)[rule.key]}</span>
            <span tw="whitespace-nowrap">
              {operatorLabel(t)[rule.operator]}
            </span>
            <span tw="font-semibold max-w-[500px] text-ellipsis overflow-hidden whitespace-nowrap">
              {getValuesLabel(rule.values as string[], rule.key).join(', ')}
            </span>
          </div>
          {idx < rules.length - 1 && <span tw="text-ps">and</span>}
        </div>
      ))}
    </div>
  );
};

export default RulesList;
