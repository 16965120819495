import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { notEmpty } from 'app/utils/array';
import { assureNumber } from 'app/utils/number';

import {
  Compensation,
  Deduction,
  DeductionDefinition,
  DeductionValue,
  FixedDeductionRequirement,
  Maybe,
} from '__generated__/graphql';

export interface DeductionFormValues {
  [deductionDefinitionId: string]: DeductionValue;
}

export const getDeductionSchema = (
  definitions?: Maybe<DeductionDefinition[]>,
): yup.ObjectSchema<ObjectShape> => {
  if (!definitions || definitions.length === 0) {
    return yup.object().notRequired();
  }

  /** fixme: (cu-32cbfgf)
   *   const schemaShape = definitions.filter(notEmpty).reduce(
   *     (prev: ObjectShape, definition: DeductionDefinition) => ({
   *       ...prev,
   *       [String(definition.groupKey)]: yup
   *         .mixed()
   *         .oneOf(getAllowedDeductionOptions(definition))
   *         .required(),
   *     }),
   *     {} as ObjectShape,
   *   );
   *   return yup.object().shape(schemaShape).required();
   */

  return yup.object().required();
};

export const getAllowedDeductionOptions = (
  definition: Maybe<DeductionDefinition>,
): DeductionValue[] => {
  if (!definition || !definition.deductionConstraint) return [];

  const requirements = definition.deductionConstraint as FixedDeductionRequirement;
  if (!requirements.options) return [];

  return requirements.options.filter(notEmpty);
};

export const getDefaultDeductionValue = (
  definitions: Maybe<DeductionDefinition[]>,
): Maybe<DeductionFormValues> => {
  if (!definitions) return null;

  return definitions.map((def) => ({
    [def.id || '']: getAllowedDeductionOptions(def)[0],
  }))[0];
};

export const mapDeductionFromCompensation = (
  compensation?: Maybe<Compensation>,
  definitionMap?: Maybe<{
    [deductionDefinitionId: string]: DeductionDefinition;
  }>,
): Maybe<DeductionFormValues> => {
  if (!compensation?.deductions || !definitionMap) return null;

  return compensation.deductions.reduce(
    (acc: DeductionFormValues, current: Maybe<Deduction>) => {
      if (!current?.deductionDefinitionId) {
        return acc;
      }

      return {
        ...acc,
        [current.deductionDefinitionId]: {
          value: assureNumber(current.value),
          unit: current.unit,
        } as DeductionValue,
      };
    },
    {},
  );
};
