import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractType } from '__generated__/graphql';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingReview: ContractOnboardingStepConfig = {
  title: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.onboarding-review.contractor.title',
          'Send Order Form',
        )
      : t('onboarding-phase.onboarding-review.title', 'Invite'),
  status: (t): string =>
    t('onboarding-phase.onboarding-review.status', 'Pending on You'),
  variant: ProgressWidgetContentVariant.DESCRIPTION,
};

export default onboardingReview;
