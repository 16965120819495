import { useContext } from 'react';

import tw, { styled } from 'twin.macro';

import { ThemeContext } from '../theme-provider';

const Textarea = styled.textarea(() => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return [
    tw`p-small rounded-tiny text-ps border border-border-primary text-text-primary`,
    tw`placeholder:text-text-tertiary`,
    tw`disabled:(bg-background-secondary text-text-tertiary)`,
    tw`focus:(border-border-high-contrast outline-none ring-0)`,
    !isNewThemeApplied &&
      tw`focus:(ring-1 ring-primaryBlue500 border-primaryBlue500)`,
    !isNewThemeApplied && tw`border-grey03 placeholder:text-grey02`,
    !isNewThemeApplied && tw`disabled:(opacity-50)`,
  ];
});

export default Textarea;
