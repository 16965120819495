import { CurrencyCode } from '__generated__/graphql';

const currencyCountry: { [key: string]: string } = {
  [CurrencyCode.AED]: 'are',
  [CurrencyCode.AFN]: 'afg',
  [CurrencyCode.ALL]: 'alb',
  [CurrencyCode.AMD]: 'arm',
  [CurrencyCode.ANG]: 'cuw',
  [CurrencyCode.AOA]: 'ago',
  [CurrencyCode.ARS]: 'arg',
  [CurrencyCode.AUD]: 'aus',
  [CurrencyCode.AWG]: 'abw',
  [CurrencyCode.AZN]: 'aze',
  [CurrencyCode.BAM]: 'bih',
  [CurrencyCode.BBD]: 'brb',
  [CurrencyCode.BDT]: 'bgd',
  [CurrencyCode.BGN]: 'bgr',
  [CurrencyCode.BHD]: 'bhr',
  [CurrencyCode.BIF]: 'bdi',
  [CurrencyCode.BMD]: 'bmu',
  [CurrencyCode.BND]: 'brn',
  [CurrencyCode.BOB]: 'bol',
  [CurrencyCode.BOV]: 'bol',
  [CurrencyCode.BRL]: 'bra',
  [CurrencyCode.BSD]: 'bhs',
  [CurrencyCode.BTN]: 'btn',
  [CurrencyCode.BWP]: 'bwa',
  [CurrencyCode.BYN]: 'blr',
  [CurrencyCode.BZD]: 'blz',
  [CurrencyCode.CAD]: 'can',
  [CurrencyCode.CDF]: 'cod',
  [CurrencyCode.CHE]: 'che',
  [CurrencyCode.CHF]: 'che',
  [CurrencyCode.CHW]: 'che',
  [CurrencyCode.CLF]: 'chl',
  [CurrencyCode.CLP]: 'chl',
  [CurrencyCode.CNH]: 'chn',
  [CurrencyCode.CNY]: 'chn',
  [CurrencyCode.COP]: 'col',
  [CurrencyCode.COU]: 'col',
  [CurrencyCode.CRC]: 'cri',
  [CurrencyCode.CUC]: 'cub',
  [CurrencyCode.CUP]: 'cub',
  [CurrencyCode.CVE]: 'cpv',
  [CurrencyCode.CZK]: 'cze',
  [CurrencyCode.DJF]: 'dji',
  [CurrencyCode.DKK]: 'dnk',
  [CurrencyCode.DOP]: 'dom',
  [CurrencyCode.DZD]: 'dza',
  [CurrencyCode.EGP]: 'egy',
  [CurrencyCode.ERN]: 'eri',
  [CurrencyCode.ETB]: 'eth',
  [CurrencyCode.EUR]: 'european-union',
  [CurrencyCode.FJD]: 'fji',
  [CurrencyCode.FKP]: 'flk',
  [CurrencyCode.GBP]: 'gbr',
  [CurrencyCode.GEL]: 'geo',
  [CurrencyCode.GHS]: 'gha',
  [CurrencyCode.GIP]: 'gib',
  [CurrencyCode.GMD]: 'gmb',
  [CurrencyCode.GNF]: 'gin',
  [CurrencyCode.GTQ]: 'gtm',
  [CurrencyCode.GYD]: 'guy',
  [CurrencyCode.HKD]: 'hkg',
  [CurrencyCode.HNL]: 'hnd',
  [CurrencyCode.HRK]: 'hrv',
  [CurrencyCode.HTG]: 'hti',
  [CurrencyCode.HUF]: 'hun',
  [CurrencyCode.IDR]: 'idn',
  [CurrencyCode.ILS]: 'isr',
  [CurrencyCode.IMP]: 'imn',
  [CurrencyCode.INR]: 'ind',
  [CurrencyCode.IQD]: 'irq',
  [CurrencyCode.IRR]: 'irn',
  [CurrencyCode.ISK]: 'isl',
  [CurrencyCode.JMD]: 'jam',
  [CurrencyCode.JOD]: 'jor',
  [CurrencyCode.JPY]: 'jpn',
  [CurrencyCode.KES]: 'ken',
  [CurrencyCode.KGS]: 'kgz',
  [CurrencyCode.KHR]: 'khm',
  [CurrencyCode.KMF]: 'com',
  [CurrencyCode.KPW]: 'prk',
  [CurrencyCode.KRW]: 'kor',
  [CurrencyCode.KWD]: 'kwt',
  [CurrencyCode.KYD]: 'cym',
  [CurrencyCode.KZT]: 'kaz',
  [CurrencyCode.LAK]: 'lao',
  [CurrencyCode.LBP]: 'lbn',
  [CurrencyCode.LKR]: 'lka',
  [CurrencyCode.LRD]: 'lbr',
  [CurrencyCode.LSL]: 'lso',
  [CurrencyCode.LYD]: 'lby',
  [CurrencyCode.MAD]: 'mar',
  [CurrencyCode.MDL]: 'mda',
  [CurrencyCode.MGA]: 'mdg',
  [CurrencyCode.MKD]: 'mkd',
  [CurrencyCode.MMK]: 'mmr',
  [CurrencyCode.MNT]: 'mng',
  [CurrencyCode.MOP]: 'mac',
  [CurrencyCode.MRU]: 'mrt',
  [CurrencyCode.MUR]: 'mus',
  [CurrencyCode.MVR]: 'mdv',
  [CurrencyCode.MWK]: 'mwi',
  [CurrencyCode.MXN]: 'mex',
  [CurrencyCode.MXV]: 'mex',
  [CurrencyCode.MYR]: 'mys',
  [CurrencyCode.MZN]: 'moz',
  [CurrencyCode.NAD]: 'nam',
  [CurrencyCode.NGN]: 'nga',
  [CurrencyCode.NIO]: 'nic',
  [CurrencyCode.NOK]: 'nor',
  [CurrencyCode.NPR]: 'npl',
  [CurrencyCode.NZD]: 'nzl',
  [CurrencyCode.OMR]: 'omn',
  [CurrencyCode.PAB]: 'pan',
  [CurrencyCode.PEN]: 'per',
  [CurrencyCode.PGK]: 'png',
  [CurrencyCode.PHP]: 'phl',
  [CurrencyCode.PKR]: 'pak',
  [CurrencyCode.PLN]: 'pol',
  [CurrencyCode.PYG]: 'pry',
  [CurrencyCode.QAR]: 'qat',
  [CurrencyCode.RON]: 'rou',
  [CurrencyCode.RSD]: 'srb',
  [CurrencyCode.RUB]: 'rus',
  [CurrencyCode.RWF]: 'rwa',
  [CurrencyCode.SAR]: 'sau',
  [CurrencyCode.SBD]: 'slb',
  [CurrencyCode.SCR]: 'syc',
  [CurrencyCode.SDG]: 'sdn',
  [CurrencyCode.SEK]: 'swe',
  [CurrencyCode.SGD]: 'sgp',
  [CurrencyCode.SHP]: 'shn',
  [CurrencyCode.SLL]: 'sle',
  [CurrencyCode.SOS]: 'som',
  [CurrencyCode.SRD]: 'sur',
  [CurrencyCode.SSP]: 'ssd',
  [CurrencyCode.STN]: 'stp',
  [CurrencyCode.SVC]: 'slv',
  [CurrencyCode.SYP]: 'syr',
  [CurrencyCode.SZL]: 'swz',
  [CurrencyCode.THB]: 'tha',
  [CurrencyCode.TJS]: 'tjk',
  [CurrencyCode.TMT]: 'tkm',
  [CurrencyCode.TND]: 'tun',
  [CurrencyCode.TOP]: 'ton',
  [CurrencyCode.TRY]: 'tur',
  [CurrencyCode.TTD]: 'tto',
  [CurrencyCode.TWD]: 'twn',
  [CurrencyCode.TZS]: 'tza',
  [CurrencyCode.UAH]: 'ukr',
  [CurrencyCode.UGX]: 'uga',
  [CurrencyCode.USD]: 'usa',
  [CurrencyCode.USN]: 'usa',
  [CurrencyCode.UYI]: 'ury',
  [CurrencyCode.UYU]: 'ury',
  [CurrencyCode.UYW]: 'ury',
  [CurrencyCode.UZS]: 'uzb',
  [CurrencyCode.VES]: 'ven',
  [CurrencyCode.VND]: 'vnm',
  [CurrencyCode.VUV]: 'vut',
  [CurrencyCode.WST]: 'wsm',
  [CurrencyCode.XAF]: 'cmr',
  [CurrencyCode.XAG]: 'united-nations',
  [CurrencyCode.XAU]: 'united-nations',
  [CurrencyCode.XBA]: 'united-nations',
  [CurrencyCode.XBB]: 'united-nations',
  [CurrencyCode.XBC]: 'united-nations',
  [CurrencyCode.XBD]: 'united-nations',
  [CurrencyCode.XCD]: 'aia',
  [CurrencyCode.XDR]: 'united-nations',
  [CurrencyCode.XOF]: 'ben',
  [CurrencyCode.XPD]: 'united-nations',
  [CurrencyCode.XPF]: 'pyf',
  [CurrencyCode.XPT]: 'united-nations',
  [CurrencyCode.XSU]: 'ven',
  [CurrencyCode.XTS]: 'united-nations',
  [CurrencyCode.XUA]: 'united-nations',
  [CurrencyCode.YER]: 'yem',
  [CurrencyCode.ZAR]: 'zaf',
  [CurrencyCode.ZMW]: 'zmb',
  [CurrencyCode.ZWL]: 'zwe',
};

export default currencyCountry;
