/* eslint-disable import/prefer-default-export */
import tw, { TwStyle, theme } from 'twin.macro';

import { CalloutVariant } from '../types';

export const getVariantStyles = (
  variant: CalloutVariant,
  isNewThemeApplied: boolean,
): {
  icon: string;
  background: TwStyle;
  foreground: TwStyle;
  border?: TwStyle;
} => {
  switch (variant) {
    case CalloutVariant.INFO:
      return {
        icon: isNewThemeApplied
          ? theme`colors.icon-secondary`
          : theme`colors.icon-brand`,
        background: isNewThemeApplied
          ? tw`bg-background-primary`
          : tw`bg-warning`,
        foreground: isNewThemeApplied
          ? tw`text-text-primary`
          : tw`text-text-action`,
        border: isNewThemeApplied
          ? tw`border border-border-primary`
          : undefined,
      };
    // TODO: rebrand -- revisit INFO_V2 and INFO_V3 variants or better always use INFO and remove them from the codebase
    case CalloutVariant.INFO_V2:
      return {
        icon: theme`colors.slateGrey600`,
        background: tw`bg-grey05`,
        foreground: tw`text-ps font-normal text-slateGrey900`,
      };
    case CalloutVariant.INFO_V3:
      return {
        icon: theme`colors.primary`,
        background: tw`bg-primaryBlue100`,
        foreground: tw`text-foreground`,
      };
    case CalloutVariant.SECONDARY:
      return {
        icon: theme`colors.icon-primary`,
        background: tw`bg-background-secondary`,
        foreground: tw`text-text-primary`,
      };
    case CalloutVariant.MUTED:
      return {
        icon: theme`colors.icon-primary`,
        background: tw`bg-background-secondary`,
        foreground: tw`text-text-primary`,
      };
    case CalloutVariant.SUCCESS:
      return {
        icon: theme`colors.icon-positive`,
        background: tw`bg-backward-compatible-positive-secondary`,
        foreground: tw`text-text-positive`,
      };
    case CalloutVariant.WARNING:
      return {
        icon: theme`colors.icon-warning`,
        background: tw`bg-backward-compatible-warning-secondary`,
        foreground: tw`text-text-warning`,
      };
    case CalloutVariant.ERROR:
      return {
        icon: theme`colors.icon-negative`,
        background: tw`bg-backward-compatible-negative-secondary`,
        foreground: tw`text-text-negative`,
      };
    case CalloutVariant.NEGATIVE:
      return {
        icon: theme`colors.icon-negative`,
        background: tw`bg-backward-compatible-negative-secondary`,
        foreground: tw`text-text-negative`,
      };
    default:
      return {
        icon: theme`colors.icon-primary`,
        background: tw`bg-background-secondary`,
        foreground: tw`text-text-primary`,
      };
  }
};
