import { useTranslation } from 'react-i18next';

import { Experience } from 'app/models/module-config';

interface ExperienceInfo {
  name: string;
  experience: Experience;
}

const useGetExperiences = (
  authorizedEperiences: Experience[],
): ExperienceInfo[] => {
  if (!authorizedEperiences.includes(Experience.MEMBER)) return [];

  const { t } = useTranslation('app.common');

  const experiences: ExperienceInfo[] = [
    {
      name: t('sidebar.experience-dropdown.team', 'Team View'),
      experience: Experience.COMPANY,
    },
    {
      name: t('sidebar.experience-dropdown.member', 'Personal View'),
      experience: Experience.MEMBER,
    },
  ];

  return experiences;
};

export default useGetExperiences;
