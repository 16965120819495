import { gql } from '@apollo/client';

export default gql`
  query GetBenefit($id: String) {
    benefit(id: $id)
      @rest(
        type: "CountryInsurance"
        method: "GET"
        path: "benefit/{args.id}"
        endpoint: "benefit"
      ) {
      id
      provider
      name
      description
      benefitType
      country
      currency
      location
      frequency
      cost
      features @type(name: "BenefitFeatures") {
        description
        included
        name
        value
      }
    }
  }
`;
