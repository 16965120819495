import { gql } from '@apollo/client';

export default gql`
  mutation ContractRevoke($id: ID!) {
    contractRevoke(id: $id) {
      id
      onboarding {
        current
        status
        steps
      }
    }
  }
`;
