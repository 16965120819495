/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropdownValue } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import * as DropdownText from 'common/components/dropdown-text';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Contract,
  ContractTerm,
  ContractType,
  CountryCompensationStandards,
  CountryRateFrequency,
  FixedPayComponent,
  InputMaybe,
  Maybe,
  RateFrequency,
  RateType,
} from '__generated__/graphql';

import { getFixedPayInputLabelText } from '../fixed-pay-input';
import {
  AmountInput,
  CurrencyInput,
  RateFrequencyInput,
} from '../fixed-pay-input/components/amount-input';
import HourlyRateVariations from '../fixed-pay-input/components/hourly-rate-variations';
import PayInputHeaderTooltip from '../fixed-pay-input/components/pay-input-header-tool-tip';

const Heading = tw.div`
    font-normal text-background text-p font-semibold leading-[26px]
`;

const SubHeading = tw.div`
    font-normal text-ps text-grey01
`;

interface ProbationSalaryProps {
  contractType?: Maybe<ContractType>;
  contractTerm?: Maybe<ContractTerm>;
  inline: boolean;
  fieldArrayElement?: FieldArrayWithId<CompensationFormValues, 'other'>;
  country: Contract['country'];
  countryRateFrequencies: CountryRateFrequency['list'];
  payComponent: FixedPayComponent;
  currencyOptions: Maybe<DropdownValue[]>;
  frequency?: InputMaybe<RateFrequency> | undefined;
  rateType?: InputMaybe<RateType> | undefined;
  showNested?: boolean;
  countryHourlyRates?: CountryCompensationStandards['hourlyRates'];
}

const ProbationSalary: React.FC<ProbationSalaryProps> = ({
  currencyOptions,
  inline,
  fieldArrayElement,
  country,
  countryRateFrequencies,
  contractType,
  contractTerm,
  payComponent,
  frequency,
  rateType,
  showNested,
  countryHourlyRates,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const fieldPath = 'probationBasePay';

  const { watch } = useFormContext();

  const fixedPayInputLabel = useMemo(
    () =>
      getFixedPayInputLabelText({
        t,
        contractTerm,
        rateType,
        frequency,
        label: 'base',
        isProbationToggleOn: showNested,
      }),
    [rateType, frequency, showNested],
  );

  const getLabelForInput = (salaryType: string) => {
    if (showNested && contractTerm && frequency && rateType)
      return (
        <>
          <span tw="text-primaryBlue500">{salaryType}</span> Salary -{' '}
          {fixedPayInputLabel}
        </>
      );

    return t('probation-salary.heading', 'Probation Salary');
  };

  return (
    <div tw="flex flex-col">
      <div tw="flex flex-row gap-x-[10px] items-center">
        <Heading>{getLabelForInput('Probation')}</Heading>
        <PayInputHeaderTooltip
          payComponent={payComponent}
          contractTerm={contractTerm}
          fieldPath={fieldPath}
          color={theme`colors.background`}
          isProbationToggleOn={showNested}
          salaryType={t(
            'probation-period.salaryType',
            'during the probation period',
          )}
        />
      </div>
      <SubHeading>
        {t(
          'probation-salary.sub-heading',
          'Define the Probation salary of the employee',
        )}
      </SubHeading>

      <div tw="mt-12">
        <div
          css={
            inline
              ? tw`grid grid-cols-3 grid-template-columns[min-content min-content max-content] items-center gap-x-24`
              : tw`grid grid-cols-4 grid-template-columns[min-content min-content max-content 1fr] items-center gap-x-24`
          }
        >
          <DropdownText.Container
            tw="max-w-[300px] w-full"
            data-testid="fixed-pay-currency-input}"
          >
            <CurrencyInput
              fieldPath={fieldPath}
              currencyOptions={currencyOptions}
              fieldArrayElement={fieldArrayElement}
              disabled={currencyOptions?.length === 1}
            />
            <AmountInput
              fieldPath={fieldPath}
              fieldArrayElement={fieldArrayElement}
            />
          </DropdownText.Container>
          <span tw="text-p text-background font-semibold">Per</span>
          <RateFrequencyInput
            fieldPath={fieldPath}
            countryRateFrequencies={countryRateFrequencies}
            payComponent={payComponent}
            contractType={contractType}
            contractTerm={contractTerm}
            country={country}
          />
          {!inline && (
            <div tw="flex justify-center">
              {watch(`${fieldPath}.frequency`) === RateFrequency.HOURLY &&
                (!!countryHourlyRates?.helpUrl ||
                  !!countryHourlyRates?.values?.length) &&
                country && (
                  <HourlyRateVariations
                    country={country}
                    countryHourlyRates={countryHourlyRates}
                    currency={watch(`${fieldPath}.currency`)}
                    amount={watch(`${fieldPath}.amount`)}
                  />
                )}
            </div>
          )}
        </div>
        {/* <PayInputCurrencyHelper fieldPath={fieldPath} /> */}
      </div>
    </div>
  );
};

export default ProbationSalary;
