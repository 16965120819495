import React, { useContext } from 'react';

import tw, { TwStyle } from 'twin.macro';

import { ThemeContext } from '../theme-provider';

export type ToggleOption = { label: string; value: string };

interface ToggleProps {
  options: ToggleOption[];
  variant?: 'button' | 'large' | 'pill';
  selected: string;
  onChange?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  divStyles?: TwStyle;
}

const buttonStyle = (isNewThemeApplied: boolean) => ({
  common: tw`flex-grow flex-1 whitespace-nowrap px-small py-[7px] focus:(ring-transparent outline-none)`,
  button: {
    div: [
      tw`border border-border-primary rounded overflow-hidden`,
      !isNewThemeApplied && tw`border border-grey03 rounded overflow-hidden`,
    ],
    common: tw`text-ps`,
    selected: [
      tw`bg-background-action text-text-inverted-primary`,
      !isNewThemeApplied && tw`bg-primary text-white`,
    ],
    unselected: [
      tw`bg-background-white text-text-primary`,
      !isNewThemeApplied && tw`bg-white text-grey01`,
    ],
  },
  large: {
    div: [
      tw`border border-border-primary rounded overflow-hidden w-[416px] h-[46px]`,
      !isNewThemeApplied &&
        tw`border border-grey03 rounded overflow-hidden w-[416px] h-[46px]`,
    ],
    common: 'text-18',
    selected: [
      tw`bg-background-action text-text-inverted-primary font-medium`,
      !isNewThemeApplied && tw`bg-primary text-white font-medium`,
    ],
    unselected: [
      tw`bg-background-white text-text-primary`,
      !isNewThemeApplied && tw`bg-white text-primary font-medium`,
    ],
  },
  pill: {
    div: '',
    common: [
      tw`text-ps ml-extra-small px-base rounded-full`,
      !isNewThemeApplied && tw`rounded-base`,
    ],
    selected: [
      tw`bg-background-action text-text-inverted-primary`,
      !isNewThemeApplied && tw`bg-background text-white`,
    ],
    unselected: [
      tw`bg-background-white border border-border-high-contrast text-text-primary`,
      !isNewThemeApplied && tw`bg-white text-background border border-grey03`,
    ],
  },
});

const ButtonGroup = React.forwardRef<HTMLDivElement, ToggleProps>(
  (
    { options, variant = 'button', selected, onChange, divStyles, ...props },
    ref,
  ) => {
    const { isNewThemeApplied } = useContext(ThemeContext);

    return (
      <div
        css={[
          tw`flex flex-row flex-shrink-0`,
          buttonStyle(isNewThemeApplied)[variant].div,
          divStyles,
        ]}
        ref={ref}
      >
        {options.map(({ label, value }, index) => (
          <button
            data-testid={`button-group-entry-${index}`}
            key={value}
            css={[
              buttonStyle(isNewThemeApplied).common,
              buttonStyle(isNewThemeApplied)[variant].common,
              selected === value
                ? buttonStyle(isNewThemeApplied)[variant].selected
                : buttonStyle(isNewThemeApplied)[variant].unselected,
              variant === 'large' &&
                index === 0 &&
                tw`border-r border-border-high-contrast`,
            ]}
            type="button"
            value={value}
            aria-pressed={selected === value}
            onClick={onChange}
            {...props}
          >
            {label}
          </button>
        ))}
      </div>
    );
  },
);

export default ButtonGroup;
