import {
  Experience,
  ModuleConfig,
  SideNavGroupConfig,
} from 'app/models/module-config';

import { filterAsync } from '../../utils/array';
import { sidebarSections } from './config';

export const shouldModuleHide = async (
  module: ModuleConfig,
): Promise<boolean> => {
  const shouldHide = module.nav.hide ?? false;
  if (!shouldHide || typeof shouldHide === 'boolean') return shouldHide;
  return shouldHide();
};

const filterHidingModules = (experience: Experience, modules: ModuleConfig[]) =>
  filterAsync(modules, async (module) => !(await shouldModuleHide(module)));

export const groupModules = async (
  experience: Experience,
  enabledModules: ModuleConfig[],
): Promise<Array<SideNavGroupConfig | ModuleConfig>> => {
  const groupedModules = (
    await filterHidingModules(experience, enabledModules)
  ).reduce((prev: { [key: string]: ModuleConfig[] }, curr) => {
    const currentNavGroup =
      typeof curr.nav.group === 'function' ? curr.nav.group() : curr.nav.group;

    (prev[currentNavGroup] = prev[currentNavGroup] || []).push(curr);
    return prev;
  }, {});

  return Object.entries(groupedModules)
    .slice()
    .flatMap<SideNavGroupConfig | ModuleConfig>(([section, configList]) => {
      const isGroupedSection = Boolean(sidebarSections[section].name);
      if (isGroupedSection) {
        return {
          id: section,
          items: configList,
          nav: {
            defaultName: sidebarSections[section].name,
            sort: sidebarSections[section].sort,
            icon: sidebarSections[section].icon,
          },
        };
      }

      return configList;
    });
};
