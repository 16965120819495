import { ReactElement, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { useModal } from 'app/hooks';
import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import EditOffboardingDateDialog from 'team/company/components/legacy-rescheule-offboarding-dialog';

import {
  Contract,
  ContractOffboardMutationVariables,
  Maybe,
  useContractOffboardMutation,
} from '__generated__/graphql';

interface RescheduleDialogReturnTypes {
  Dialog: () => ReactElement;
  dialogOpen: boolean;
  rescheduleInProgress: boolean;
  handleRescheduleClick: (contract?: Maybe<Contract>) => void;
  handleDialogClose: () => void;
  handleRescheduleConfirm: (
    endDate: ContractOffboardMutationVariables['lastWorkingDay'],
  ) => void;
  contractToReschedule?: Contract;
}

export default (): RescheduleDialogReturnTypes => {
  const { t } = useTranslation('team.company');
  const [contractToEdit, setContractToEdit] = useState<Contract>();
  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal();

  const [onEdit, { loading }] = useContractOffboardMutation({
    onCompleted: () => {
      successNotification(
        '',
        t(
          'edit-offboarding-date.notification.success',
          'Last working day changed successfully',
        ),
        false,
      );
    },
    onError: () => {
      errorNotification(
        '',
        t(
          'edit-offboarding-date.notification.error',
          'Could not change last working day',
        ),
        false,
      );
    },
  });

  const handleRescheduleConfirm = async (
    endDate: ContractOffboardMutationVariables['lastWorkingDay'],
  ) => {
    if (contractToEdit?.id) {
      await onEdit({
        variables: {
          id: contractToEdit.id,
          lastWorkingDay: format(endDate, 'yyyy-MM-dd'),
          offboardingNote: contractToEdit.offboardingNote ?? '',
        },
      });
    }
    setContractToEdit(undefined);
    handleCloseDialog();
  };

  const handleRescheduleClick = (contract?: Maybe<Contract>) => {
    if (contract) {
      setContractToEdit(contract);
      handleOpenDialog();
    }
  };

  const handleDialogClose = () => {
    setContractToEdit(undefined);
    handleCloseDialog();
  };

  const Dialog = () => (
    <EditOffboardingDateDialog
      contract={contractToEdit}
      show={showDialog}
      onClose={handleDialogClose}
      onConfirm={handleRescheduleConfirm}
      loading={loading}
    />
  );

  return {
    dialogOpen: showDialog,
    rescheduleInProgress: loading,
    handleRescheduleConfirm,
    handleRescheduleClick,
    handleDialogClose,
    contractToReschedule: contractToEdit,
    Dialog,
  };
};
