class SessionStorageService {
  ls = window.sessionStorage;

  setItem(key: string, value: string) {
    this.ls.setItem(key, JSON.stringify(value));
    return true;
  }

  getItem(key: string) {
    const value = this.ls.getItem(key) as string;
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  getRawItem(key: string) {
    return this.ls.getItem(key);
  }

  removeItem(key: string) {
    if (this.ls.getItem(key)) {
      this.ls.removeItem(key);
      return true;
    }
    return false;
  }
}

export default new SessionStorageService();
