/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ToolTip } from '@multiplier/common';

import {
  CompanyStatus,
  Contract,
  ContractType,
  useContractPreparingConfirmationMutation,
  useGetMsaStatusQuery,
} from '__generated__/graphql';

const ConfirmContractPreparation: React.FC<{
  contractId: Contract['id'];
  contractType: Contract['type'];
  contractBackdated?: boolean;
}> = ({ contractId, contractType, contractBackdated }) => {
  const { t } = useTranslation('contract-onboarding.member');
  const [
    contractPreparingConfirmation,
    { loading: loadingConfirmation },
  ] = useContractPreparingConfirmationMutation();

  const { data: msaStatus, loading: loadingMsaStatus } = useGetMsaStatusQuery();

  const handleSubmit = async () => {
    if (contractId)
      await contractPreparingConfirmation({ variables: { id: contractId } });
  };

  const allowSendingBasedOnCompanyStatus =
    msaStatus?.company?.status === CompanyStatus.ACTIVE ||
    contractType !== ContractType.EMPLOYEE;

  const tooltipContent = React.useMemo(() => {
    if (!allowSendingBasedOnCompanyStatus)
      return t(
        'onboarding-phase.optional-action.send-contract.sign-msa-callout',
        'Please sign the MSA to proceed to next steps.',
      );

    if (contractBackdated)
      return t(
        'onboarding-phase.primary-action.send-contract.backdated-tooltip',
        'Employment start date cannot be backdated.',
      );

    return undefined;
  }, [t, allowSendingBasedOnCompanyStatus, contractBackdated]);

  const loading = loadingConfirmation || loadingMsaStatus;

  return (
    <div>
      <ToolTip
        variant="bottom"
        content={tooltipContent && <span>{tooltipContent}</span>}
      >
        <Button
          size="medium"
          disabled={
            loading || contractBackdated || !allowSendingBasedOnCompanyStatus
          }
          warning={contractBackdated}
          loading={loading || msaStatus == null}
          onClick={handleSubmit}
        >
          {t(
            'onboarding-phase.primary-action.confirm-contract',
            'Confirm and Proceed',
          )}
        </Button>
      </ToolTip>
    </div>
  );
};

export default ConfirmContractPreparation;
