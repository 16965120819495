import React from 'react';

import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';

// import { Button } from '@multiplier/common';

// import { Experience } from '../../../../types';
// import routes from '../../../routes';
import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const SendContractTask: React.FC<TaskEntryProps> = ({ task }) => {
  const { t } = useTranslation('contract-onboarding');

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.send-contract.title',
        'Send contract',
      )}
      // action={
      //   <Button
      //     size="small"
      //     tw="text-ps"
      //     variant="outline"
      //     onClick={() => {
      //       handleCloseSidePanel();
      //       navigate(`/${Experience.COMPANY}/${routes.team}/${contractId}`);
      //     }}
      //     disabled={!actionable}
      //   >
      //     {t(
      //       'onboarding-checklist.task-list.send-contract.action',
      //       'Send contract',
      //     )}
      //   </Button>
      // }
      completed={task.completed}
    />
  );
};

export default SendContractTask;
