/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { ToolTip } from '@multiplier/common';

import { Contract } from '../../../../__generated__/graphql';
import CurrencyHelper from '../../../../app/components/currency-helper';
import { numberAsCurrency } from '../../../../app/utils/format';
import { showPaymentFrequency } from '../../services/contracts';

const PayCell: React.FC<{
  type: Contract['type'];
  compensation: Contract['compensation'];
}> = ({ type, compensation }) => {
  const { i18n, t } = useTranslation('team.company');
  const paymentFrequency = useMemo(
    () => showPaymentFrequency(t, type, compensation?.basePay?.frequency),
    [type, compensation],
  );

  const payText = useMemo(
    () =>
      `${compensation?.basePay?.currency} ${numberAsCurrency(
        i18n.language,
        Number(compensation?.basePay?.amount),
        2,
      )} ${paymentFrequency}`,
    [compensation, paymentFrequency],
  );

  const shortenedPay = useMemo(
    () =>
      payText && payText?.length > 15
        ? `${payText.substring(0, 15)}...`
        : payText,
    [payText],
  );

  if (!compensation?.basePay?.amount) return null;

  return (
    <div>
      <ToolTip
        variant="bottom"
        content={
          shortenedPay !== payText ? (
            <div tw="text-center">
              <p tw="text-p">{payText}</p>
              <CurrencyHelper
                referenceCurrency={compensation?.basePay?.currency}
                amount={compensation?.basePay?.amount}
              />
            </div>
          ) : null
        }
      >
        <p tw="text-ps">{shortenedPay}</p>
      </ToolTip>

      <CurrencyHelper
        maxLength={15}
        referenceCurrency={compensation?.basePay?.currency}
        amount={compensation?.basePay?.amount}
      />
    </div>
  );
};

export default PayCell;
