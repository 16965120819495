import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { HighlightType } from '@multiplier/common';
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  isBefore,
  isSameDay,
  startOfMonth,
} from 'date-fns';
import flow from 'lodash/fp/flow';
import uniqWith from 'lodash/fp/uniqWith';

const useEndDateHighlights = ({
  minimum,
  maximum,
}: {
  minimum: Date;
  maximum: Date;
}): HighlightType[] => {
  const { t } = useTranslation('contract-onboarding.company');

  const getBeforeCurrentDateHighlights = (currentDate: Date) => {
    if (isSameDay(startOfMonth(currentDate), currentDate)) return [];

    return eachDayOfInterval({
      start: startOfMonth(currentDate),
      end: addDays(currentDate, -1),
    }).map((beforeDates) => ({
      date: beforeDates,
      hover: {
        content: t(
          'end-date.before-date',
          'Please select a date after the requested start date',
        ),
      },
    }));
  };

  const getMinimumRestrictionHighlights = (currentDate: Date) =>
    isBefore(currentDate, addDays(minimum, -1))
      ? eachDayOfInterval({
          start: currentDate,
          end: addDays(minimum, -1),
        }).map((minimumRestrictionDate) => ({
          date: minimumRestrictionDate,
          hover: {
            content: t(
              'end-date.minimum-renewal-info',
              'This date is not available due to renewal minimum restrictions',
            ),
          },
        }))
      : [];

  const getMaximumRestrictionHighlights = () => {
    const start = addDays(maximum, 1);
    const end = endOfMonth(maximum);

    return isBefore(start, end)
      ? eachDayOfInterval({
          start,
          end,
        }).map((minimumRestrictionDate) => ({
          date: minimumRestrictionDate,
          hover: {
            content: t(
              'end-date.maximum-renewal-info',
              'This date is not available due to renewal maximum restrictions',
            ),
          },
        }))
      : [];
  };

  const currentDate = new Date();

  const beforeCurrentDate = getBeforeCurrentDateHighlights(currentDate);

  const minimumRestrictionHighlights = getMinimumRestrictionHighlights(
    currentDate,
  );

  const maximumRestrictionHighlights = getMaximumRestrictionHighlights();

  return useMemo(
    () =>
      flow(
        uniqWith<HighlightType>((value, other) =>
          isSameDay(value.date as Date, other.date as Date),
        ),
      )([
        ...beforeCurrentDate,
        ...minimumRestrictionHighlights,
        ...maximumRestrictionHighlights,
      ]),
    [minimum, maximum],
  );
};

export default useEndDateHighlights;
