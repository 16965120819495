import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'member-payables',
  permission: 'member-payables',
  folder: 'member-payables/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Freelancer Invoices',
    route: routes.root,
    sort: 2,
    home: false,
    group: CompanyNavGroups.ADMINISTRATION,
  },
  preloadNs: ['member-payables.member'],
};

export default config;
