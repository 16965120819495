/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, PillTag } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import LabelComponentFreelancer from '../label-component';

interface CardLayoutProps {
  title?: string;
  description?: string;
  iconName?: string;
  selected?: boolean;
  disabled?: boolean;
  disabledText?: string;
}

export const CardLayout: React.FC<CardLayoutProps> = ({
  title,
  description,
  iconName = 'flag',
  selected = false,
  disabled,
  disabledText,
}) => (
  <div
    css={[
      tw`flex flex-col items-center rounded-base border h-[270px]`,
      selected
        ? tw`border-border-primary bg-background-brand-faded`
        : tw`border-border-primary bg-background-white`,
    ]}
  >
    <div
      css={[tw`h-[60px] flex items-center`, !disabled && tw`invisible py-2`]}
    >
      <PillTag variant="PREPROCESSING" label={disabledText ?? ''} />
    </div>

    <div tw="h-[110px] flex items-center">
      <div
        css={[
          tw`flex justify-center items-center rounded-full h-[78px] w-[78px]`,
          disabled
            ? tw`bg-background-brand-faded`
            : tw`bg-background-brand-subtle shadow-high`,
        ]}
      >
        <Icon
          tw="p-2 w-[36px] h-[42px]"
          name={iconName}
          fill={theme`colors.icon-primary`}
          fillOpacity={disabled ? 0.3 : 1}
        />
      </div>
    </div>

    <div
      css={[
        tw`flex flex-col justify-center items-center px-extra-large h-[90px]`,
        disabled ? tw`opacity-50` : tw``,
      ]}
    >
      <div tw="text-text-primary font-semibold ">{title}</div>
      <p tw="text-ps font-normal text-text-tertiary mt-small whitespace-pre-wrap">
        {description}
      </p>
    </div>
  </div>
);

const CompensationOptionsSection: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <>
      <LabelComponentFreelancer
        labelText={t(
          'definition-phase.compensation.compensation-type-label',
          'Compensation Type',
        )}
        description={t(
          'definition-phase.compensation-type-description',
          'Choose the compensation type for freelancer ',
        )}
      />

      <div
        data-testid="payment-form"
        tw="grid grid-cols-2 gap-x-base gap-y-large"
      >
        <CardLayout
          iconName="timer"
          selected
          title={t(
            'definition-phase.compensation.payment-time-based-title',
            'Time Based',
          )}
          description={t(
            'definition-phase.compensation.payment-time-based-description',
            'Pay freelancers periodically based on pre-defined billing rates.',
          )}
        />
        <CardLayout
          disabled
          disabledText={t(
            'definition-phase.compensation.disabled-text-label',
            'Coming soon',
          )}
          title={t(
            'definition-phase.compensation.payment-milestone-title',
            'Milestone Based',
          )}
          description={t(
            'definition-phase.compensation.payment-milestone-description',
            'Pay freelancers on the basis of project milestones.',
          )}
        />
      </div>
    </>
  );
};

export default CompensationOptionsSection;
