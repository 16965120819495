import { TFunction } from 'i18next';
import * as yup from 'yup';

import { fileSchema } from 'app/utils/file-schema-utils';
import i18nextUtil from 'app/utils/i18next-util';
import { localBankCodeMap } from 'contract-onboarding/member/pages/onboarding/pages/bank-details';

import { Contract, ContractType, CountryCode } from '__generated__/graphql';

export const getBankCodeSchema = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  country: Contract['country'],
): yup.StringSchema =>
  yup
    .string()
    .test(
      'required',
      t(
        'bank-details.local-bank-code.default.required',
        'Local Bank Code field is required',
      ),
      (value) => {
        if (country && localBankCodeMap[country]) {
          return yup.string().required().isValid(value);
        }
        return true;
      },
    )
    .test(
      'pattern',
      (country && localBankCodeMap[country]?.patternError(t)) ?? '',
      (value) => {
        if (value && country && localBankCodeMap[country]) {
          return localBankCodeMap[country].pattern(value);
        }
        return true;
      },
    );

export const getSwiftCodeSchema = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  country: Contract['country'],
  contractType: Contract['type'],
): yup.StringSchema =>
  yup
    .string()
    .test(
      'optional-for-employee',
      t('bank-details.swift-code.required-error', 'SWIFT code is required'),
      (value) => {
        if (
          contractType !== ContractType.EMPLOYEE &&
          country !== CountryCode.IND
        ) {
          return yup.string().required().isValid(value);
        }

        return true;
      },
    )
    .test(
      'swift-code-pattern',
      t(
        'bank-details.swift-code.pattern-error',
        'Should be either 8 or 11 characters long',
      ),
      (value) => {
        if (value) return /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value);
        return true;
      },
    );

export const getBankStatementsSchema = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  country: Contract['country'],
): yup.ArraySchema<yup.InferType<typeof fileSchema>> =>
  yup
    .array()
    .of(
      yup.object().shape({
        statement: fileSchema,
      }),
    )
    .min(
      1,
      t(
        i18nextUtil.buildTransKeys(
          'validation.required',
          'bank-details.proof-documents',
          i18nextUtil.asSegment(country),
        ),
        'Bank Statement / Cancelled Cheque is required',
        { ns: 'contract-onboarding.common' },
      ),
    )
    .required();

const getBankDetailsSchema = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  country: Contract['country'],
  contractType: Contract['type'],
  requireDocument?: boolean,
): yup.ObjectSchema<{
  accountName: yup.StringSchema;
  accountNumber: yup.StringSchema;
  bankName: yup.StringSchema;
  branchName: yup.StringSchema;
  localBankCode: yup.StringSchema;
  swiftCode: yup.StringSchema;
}> =>
  yup.object().shape({
    accountName: yup.string().required(),
    accountNumber: yup.string().required(),
    bankName: yup.string().required(),
    branchName: yup.string().required(),
    localBankCode: getBankCodeSchema(t, country),
    swiftCode: getSwiftCodeSchema(t, country, contractType),
    bankStatements: yup.lazy(() => {
      if (contractType === ContractType.HR_MEMBER)
        return yup.mixed().notRequired();
      return getBankStatementsSchema(t, country);
    }),
    documentsProof: yup.lazy(() => {
      if (requireDocument) {
        return yup
          .array()
          .of(fileSchema)
          .min(
            1,
            t(
              'member.basic-details.documents.required',
              'Identity proof is required',
            ),
          );
      }
      return yup.array().of(fileSchema).nullable();
    }),
  });

export default getBankDetailsSchema;
