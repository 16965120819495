import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingContractPreparingConfirmation: ContractOnboardingStepConfig = {
  title: (t): string =>
    t(
      'onboarding-phase.onboarding-contract-preparing-confirmation.title',
      'Preparing Confirmation',
    ),
  status: (t): string =>
    t(
      'onboarding-phase.onboarding-contract-preparing-confirmation.status',
      'Pending on You',
    ),
  variant: ProgressWidgetContentVariant.DESCRIPTION,
};

export default onboardingContractPreparingConfirmation;
