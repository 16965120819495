/** @jsxImportSource @emotion/react */
import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { TextInput } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import * as DropdownText from 'common/components/dropdown-text';

import { RateFrequency } from '__generated__/graphql';

import { FixedPayInputLabel, FixedPayInputProps } from '../../index';
import {
  AmountInput,
  CurrencyInput,
  RateFrequencyInput,
} from '../amount-input';
import HourlyRateVariations from '../hourly-rate-variations';
import PayInputHeaderTooltip from '../pay-input-header-tool-tip';

type BasicPayInputProps = Pick<
  FixedPayInputProps,
  | 'contractType'
  | 'contractTerm'
  | 'fieldPath'
  | 'payComponent'
  | 'currencyOptions'
  | 'localCurrency'
  | 'fieldArrayElement'
  | 'countryRateFrequencies'
  | 'country'
  | 'countryHourlyRates'
  | 'inline'
  | 'hideLabel'
  | 'isRateFrequencyInputDisabled'
  | 'sorOnboardingContext'
>;

const BasicPayInput: React.FC<BasicPayInputProps> = ({
  contractType,
  contractTerm,
  fieldPath,
  payComponent,
  currencyOptions,
  localCurrency,
  fieldArrayElement,
  countryRateFrequencies,
  country,
  countryHourlyRates,
  inline,
  hideLabel,
  isRateFrequencyInputDisabled,
  sorOnboardingContext,
  ...props
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { watch } = useFormContext();

  return (
    <div
      data-testid={`basic-pay-input-${payComponent.label}`}
      css={inline && tw``}
      {...props}
    >
      {!hideLabel && (
        <div tw="flex flex-row gap-x-[10px] items-center">
          <FixedPayInputLabel
            fieldPath={fieldPath}
            fixedPayComponent={payComponent}
            contractTerm={contractTerm}
          />
          {!sorOnboardingContext?.isHrisOnlyFlow && (
            <PayInputHeaderTooltip
              payComponent={payComponent}
              contractTerm={contractTerm}
              fieldPath={fieldPath}
              color={theme`colors.icon-primary`}
            />
          )}
        </div>
      )}
      {!inline && !hideLabel && (
        <div tw="mb-12">
          <TextInput.Helper>
            {t(
              'compensation.eor-pay-input.description',
              'Enter the salary of the employee',
            )}
          </TextInput.Helper>
        </div>
      )}
      <div>
        <div
          css={
            inline
              ? tw`grid grid-cols-3 grid-template-columns[min-content min-content max-content] items-center gap-x-large`
              : tw`grid grid-cols-4 grid-template-columns[min-content min-content max-content 1fr] items-center gap-x-large`
          }
        >
          <DropdownText.Container
            tw="max-w-[300px] w-full"
            data-testid={`fixed-pay-currency-input-${payComponent.label}`}
          >
            <CurrencyInput
              fieldPath={fieldPath}
              currencyOptions={currencyOptions}
              fieldArrayElement={fieldArrayElement}
              disabled={currencyOptions?.length === 1}
            />
            <AmountInput
              fieldPath={fieldPath}
              fieldArrayElement={fieldArrayElement}
            />
          </DropdownText.Container>
          <span tw="text-p text-text-primary font-semibold">Per</span>
          <RateFrequencyInput
            fieldPath={fieldPath}
            countryRateFrequencies={countryRateFrequencies}
            payComponent={payComponent}
            contractType={contractType}
            contractTerm={contractTerm}
            country={country}
            isRateFrequencyInputDisabled={isRateFrequencyInputDisabled}
          />
          {!inline && !isRateFrequencyInputDisabled && (
            <div tw="flex justify-center">
              {watch(`${fieldPath}.frequency`) === RateFrequency.HOURLY &&
                (!!countryHourlyRates?.helpUrl ||
                  !!countryHourlyRates?.values?.length) &&
                country && (
                  <HourlyRateVariations
                    country={country}
                    countryHourlyRates={countryHourlyRates}
                    currency={watch(`${fieldPath}.currency`)}
                    amount={watch(`${fieldPath}.amount`)}
                  />
                )}
            </div>
          )}
        </div>
        {/* <PayInputCurrencyHelper fieldPath={fieldPath} /> */}
      </div>
      {watch(`${fieldPath}.currency`) !== localCurrency && (
        <div tw="mt-extra-small">
          <Trans
            t={t}
            i18nKey="compensation.eor-pay-input.non-local-currency.info"
          >
            <TextInput.Helper tw="font-semibold mr-extra-small">
              Note:
            </TextInput.Helper>
            <TextInput.Helper>
              Please note that the salary will be paid out in the local
              currency.
            </TextInput.Helper>
          </Trans>
        </div>
      )}
    </div>
  );
};

export default BasicPayInput;
