/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { Accordion, CardSeparator } from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import 'twin.macro';

import AppFeature from 'app/features';
import { useModal } from 'app/hooks';
import { Experience } from 'app/models/module-config';
import { notEmpty } from 'app/utils/array';
import { parseKeys } from 'app/utils/object';
import { userVar } from 'app/vars';
import Loader from 'common/components/loader';
import { Religion } from 'common/constants/default-labels';
import useGetSupportedJobTitles from 'contract-onboarding/company/hooks/get-supported-job-titles';
import { ChangeWarningStatusArgs } from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import BankDetailsSection from 'contract-onboarding/components/bank-details-section';
import BankDetailsSectionHR from 'contract-onboarding/components/bank-details-section-hr';
import BasicDetailSection from 'contract-onboarding/components/basic-details-section';
import CompensationSection from 'contract-onboarding/components/compensation-section';
import CompensationSectionFreelancer from 'contract-onboarding/components/compensation-section-freelancer';
import ContactDetailsSection from 'contract-onboarding/components/contact-details-section';
import ContractSection, {
  showComplianceParams,
} from 'contract-onboarding/components/contract-section';
import DocumentsSection from 'contract-onboarding/components/documents-section';
import EmploymentDetailSection from 'contract-onboarding/components/employment-details-section';
import InsuranceSection, {
  InsuranceSectionVariant,
} from 'contract-onboarding/components/insurance-section';
import PayslipsSection from 'contract-onboarding/components/payslips-section';
import { useGetAndGroupDeductionDefinitions } from 'contract-onboarding/hooks';
import InsuranceMemberSection from 'contract-onboarding/member/pages/verification/insurance-details';
import { useLegacyOffboardNotStartedMemberDialog } from 'team/company/hooks';
import OffboardFreelancerDialog from 'team/company/pages/member-view/components/offboard-freelancer-dialog';
import OffboardMemberDialog from 'team/company/pages/member-view/components/offboard-member-dialog';

import {
  BenefitStatus,
  ComplianceFreelance,
  ComplianceMultiplierEor,
  CompliancePartnerEor,
  CompliancePeo,
  Contract,
  ContractAgreementType,
  ContractBenefitStatus,
  ContractOnboardingStep,
  ContractStatus,
  ContractType,
  FetchStage,
  useGetCountryComplianceLimitationsLazyQuery,
  useGetCountryInsuranceQuery,
} from '__generated__/graphql';

import { allowSectionEdit } from '../../services/section-config';

const ContributionsSection = React.lazy(
  () => import('contract-onboarding/components/contributions-section'),
);

export enum Sections {
  BASIC_DETAILS = 'basic-details',
  EMPLOYMENT_DETAILS = 'employment',
  COMPENSATION = 'compensation',
  COMPENSATION_FREELANCER = 'compensation-freelancer',
  CONTRIBUTIONS = 'contributions',
  INSURANCE = 'insurance',
  CONTRACT = 'contract',
}

const isFreelancerCustomAgreement = (
  type: Contract['type'],
  compliance:
    | ComplianceMultiplierEor
    | CompliancePartnerEor
    | CompliancePeo
    | ComplianceFreelance
    | null
    | undefined,
) =>
  type === ContractType.FREELANCER &&
  compliance?.contractAgreementType === ContractAgreementType.CUSTOM_TEMPLATE;

type EmployeeDetailsProps = {
  contract: Contract;
  member: Contract['member'];
  loading?: boolean;
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  workflowUpdating?: boolean;
  contractBackdated?: boolean;
  contractBlocked?: boolean;
};

const EmployeeDetailsCard: React.FC<EmployeeDetailsProps> = ({
  contract,
  member,
  loading = false,
  uncommittedChangesCallback,
  workflowUpdating = false,
  contractBackdated,
  contractBlocked,
  ...props
}) => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const singleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;
  const hrMemberWithSingleOnboarding =
    contract?.type === ContractType.HR_MEMBER && singleOnboardingEnabled;
  const sorOnboardingContext = useSorOnboardingContext({
    type: contract?.type,
    companyId: contract?.company?.id,
    legalEntityId: contract?.legalEntityId,
  });

  const [openSection, setOpenSection] = useState<string | null>();

  const { data: countryData } = useGetCountryInsuranceQuery({
    variables: {
      filters: {
        country: contract?.country,
        contractType: contract?.type,
        status: BenefitStatus.ACTIVE,
        isDefault: true,
      },
    },
    skip:
      !contract?.country ||
      !contract?.type ||
      contract?.onboarding?.current !==
        ContractOnboardingStep.ONBOARDING_REVIEW,
  });

  const { definitionMap } = useGetAndGroupDeductionDefinitions({
    country: contract?.country,
    state: contract?.countryStateCode,
    contractType: contract?.type,
    contractTerm: contract?.term,
  });

  const [
    getCountryComplianceLimitations,
    { data: countryCompliance, loading: countryComplianceLimitationsLoading },
  ] = useGetCountryComplianceLimitationsLazyQuery();

  const {
    loading: companyIntegrationsLoading,
    supportedJobPositions,
  } = useGetSupportedJobTitles();

  const complianceParamsVisible = useMemo(
    () => showComplianceParams(contract.compliance),
    [contract.compliance],
  );

  const shouldAllowEdit =
    !workflowUpdating &&
    !contractBlocked &&
    allowSectionEdit(
      contract.status,
      contract.onboarding,
      contract.type,
      contract.compliance,
      current,
    );

  const shouldShowBankDetailsOnCompanyExp =
    current === Experience.COMPANY &&
    (sorOnboardingContext.isSorOnboardingEnabled
      ? sorOnboardingContext.isGlobalPayrollOnlyFlow
      : hrMemberWithSingleOnboarding);

  const shouldShowInsuranceSection = useMemo(() => {
    if (current === Experience.MEMBER) return false;
    if (contract.benefits && contract.benefits.length !== 0) return true;
    if (
      (contract.type === ContractType.HR_MEMBER &&
        sorOnboardingContext.isSorOnboardingEnabled) ||
      hrMemberWithSingleOnboarding
    )
      return false;
    return (
      contract?.onboarding?.current ===
        ContractOnboardingStep.ONBOARDING_REVIEW &&
      countryData?.benefits?.length !== 0
    );
  }, [
    contract,
    countryData,
    sorOnboardingContext.isSorOnboardingEnabled,
    hrMemberWithSingleOnboarding,
  ]);

  const shouldShowMemberInsuranceSection = useMemo(() => {
    if (current !== Experience.MEMBER) return false;

    // Compatibility: Should hide this section if no benefits. Sync with <InsuranceMemberSection />
    if (!contract?.benefits || contract?.benefits?.length === 0) return false;

    if (contract?.benefits?.[0]?.status === ContractBenefitStatus?.OPTED_OUT)
      return false;

    return true;
  }, [contract]);

  const [showOffboardDialog, handleCloseOffboardDialog] = useModal(false);

  const {
    Dialog: OffboardNotStartedMemberDialog,
  } = useLegacyOffboardNotStartedMemberDialog();

  const OffboardDialog = useMemo(
    () =>
      contract.type === ContractType.FREELANCER || contract.endOn
        ? OffboardFreelancerDialog
        : OffboardMemberDialog,
    [contract],
  );

  const handleSectionToggle = (key: string) => {
    setOpenSection(key);
  };

  const showContributionSection =
    contract.type === ContractType.EMPLOYEE &&
    parseKeys(definitionMap).length > 0;

  useEffect(() => {
    if (contract?.country && contract?.type !== ContractType.FREELANCER) {
      getCountryComplianceLimitations({
        variables: {
          country: contract?.country,
          countryStateCode: contract?.countryStateCode,
          workStatus: contract?.workStatus,
          fetchStage: FetchStage.CONTRACT_GENERATION,
        },
      });
    }
  }, [contract?.country, contract?.countryStateCode, contract?.workStatus]);

  return loading ||
    countryComplianceLimitationsLoading ||
    companyIntegrationsLoading ? (
    <div tw="z-10">
      <Loader.List />
    </div>
  ) : (
    <>
      <Accordion.Container data-testid="employee-details-card" {...props}>
        <BasicDetailSection
          member={member}
          country={contract.country}
          workStatus={contract.workStatus}
          type={contract.type}
          legalEntityId={contract.legalEntityId}
          editable={shouldAllowEdit}
          uncommittedChangesCallback={uncommittedChangesCallback}
          toggle={handleSectionToggle}
          open={openSection === Sections.BASIC_DETAILS}
        />
        <CardSeparator />
        <EmploymentDetailSection
          contract={contract}
          countryCompliance={countryCompliance}
          edit={current === Experience.COMPANY && shouldAllowEdit}
          uncommittedChangesCallback={uncommittedChangesCallback}
          contractBackdated={contractBackdated}
          toggle={handleSectionToggle}
          open={openSection === Sections.EMPLOYMENT_DETAILS}
          supportedJobPositions={supportedJobPositions}
        />
        {current !== Experience.MEMBER && (
          <>
            <CardSeparator />
            {contract.type !== ContractType.FREELANCER &&
            contract.type !== ContractType.CONTRACTOR ? (
              <CompensationSection
                id={contract.id as string}
                type={contract.type}
                term={contract.term}
                country={contract.country}
                currency={contract.currency}
                compensation={contract.compensation}
                alreadyHired={contract.alreadyHired}
                companyName={contract?.company?.displayName}
                companyId={contract?.company?.id}
                religion={
                  member?.legalData?.find((d) => d?.key === 'religion')
                    ?.value as Religion
                }
                edit={shouldAllowEdit}
                uncommittedChangesCallback={uncommittedChangesCallback}
                startOn={contract.startOn}
                compliance={contract.compliance}
                countryStateCode={contract.countryStateCode}
                legalEntityId={contract.legalEntityId}
                toggle={handleSectionToggle}
                open={openSection === Sections.COMPENSATION}
              />
            ) : (
              <CompensationSectionFreelancer
                id={contract.id}
                type={contract.type}
                term={contract.term}
                country={contract.country}
                currency={contract.currency}
                compensation={contract.compensation}
                alreadyHired={contract.alreadyHired}
                companyName={contract?.company?.displayName}
                companyId={contract?.company?.id}
                religion={
                  member?.legalData?.find((d) => d?.key === 'religion')
                    ?.value as Religion
                }
                edit={shouldAllowEdit}
                uncommittedChangesCallback={uncommittedChangesCallback}
                startOn={contract.startOn}
                countryStateCode={contract.countryStateCode}
                legalEntityId={contract.legalEntityId}
                toggle={handleSectionToggle}
                open={openSection === Sections.COMPENSATION_FREELANCER}
              />
            )}
          </>
        )}
        {showContributionSection && (
          <>
            <CardSeparator />
            <ContributionsSection
              contractId={contract.id}
              type={contract.type}
              term={contract.term}
              country={contract.country}
              currency={contract.currency}
              edit={shouldAllowEdit}
              deductions={contract?.compensation?.deductions}
              uncommittedChangesCallback={uncommittedChangesCallback}
              state={contract.countryStateCode}
              toggle={handleSectionToggle}
              open={openSection === Sections.CONTRIBUTIONS}
              contractStatus={contract.status}
            />
          </>
        )}
        {current === Experience.COMPANY &&
          contract?.payslips &&
          contract?.payslips?.length > 0 && (
            <>
              <CardSeparator />
              <PayslipsSection payslips={contract?.payslips} />
            </>
          )}
        {current === Experience.MEMBER &&
          member?.bankAccounts &&
          member.bankAccounts?.length !== 0 && (
            <>
              <CardSeparator />
              <BankDetailsSection
                member={member}
                country={contract.country}
                contractType={contract?.type}
                editable={shouldAllowEdit}
                uncommittedChangesCallback={uncommittedChangesCallback}
                contractStatus={contract.status}
              />
            </>
          )}
        {shouldShowBankDetailsOnCompanyExp && (
          <>
            <CardSeparator />
            <BankDetailsSectionHR
              contract={contract}
              country={contract.country}
              contractType={contract?.type}
              editable={shouldAllowEdit}
              uncommittedChangesCallback={uncommittedChangesCallback}
              contractStatus={contract.status}
            />
          </>
        )}
        {((member?.addresses && member.addresses.length !== 0) ||
          (member?.phoneNos && member.phoneNos.length !== 0)) && (
          <>
            <CardSeparator />
            <ContactDetailsSection
              member={member}
              editable={current === Experience.MEMBER && shouldAllowEdit}
              uncommittedChangesCallback={uncommittedChangesCallback}
              workStatus={contract.workStatus}
              type={contract.type}
              country={contract.country}
            />
          </>
        )}
        {shouldShowInsuranceSection && (
          <>
            <CardSeparator />
            <InsuranceSection
              id={contract.id}
              benefits={contract.benefits}
              country={contract.country}
              contractType={contract.type}
              variant={InsuranceSectionVariant.COMPANY}
              edit={current === Experience.COMPANY && shouldAllowEdit}
              uncommittedChangesCallback={uncommittedChangesCallback}
              toggle={handleSectionToggle}
              open={openSection === Sections.INSURANCE}
            />
          </>
        )}
        {shouldShowMemberInsuranceSection && (
          <>
            <CardSeparator />
            <InsuranceMemberSection
              uncommittedChangesCallback={uncommittedChangesCallback}
              toggle={handleSectionToggle}
              open={openSection === Sections.INSURANCE}
              contractId={contract?.id}
              contractType={contract?.type}
            />
          </>
        )}
        {(contract.compliance?.contractAgreement?.id ||
          complianceParamsVisible) &&
          (current !== Experience.MEMBER ||
            contract.status === ContractStatus.ACTIVE) && (
            <>
              <CardSeparator />
              <ContractSection
                id={contract.id}
                type={contract.type}
                country={contract.country}
                countryStateCode={contract.countryStateCode}
                term={contract.term}
                start={contract.startOn}
                end={contract.endOn}
                compliance={contract.compliance}
                status={contract.status}
                onboardingStatus={contract?.onboarding?.status}
                companyId={contract.company?.id ?? ''}
                edit={
                  current === Experience.COMPANY &&
                  !isFreelancerCustomAgreement(
                    contract.type,
                    contract.compliance,
                  ) &&
                  shouldAllowEdit &&
                  (sorOnboardingContext.isSorOnboardingEnabled ||
                    !hrMemberWithSingleOnboarding)
                }
                uncommittedChangesCallback={uncommittedChangesCallback}
                compensation={contract.compensation}
                toggle={handleSectionToggle}
                open={openSection === Sections.CONTRACT}
              />
            </>
          )}
        {current === Experience.MEMBER &&
          member?.legalDocuments &&
          member.legalDocuments
            .flatMap((document) => document?.files)
            .filter(notEmpty).length !== 0 && (
            <>
              <CardSeparator />
              <DocumentsSection
                memberId={member?.id}
                documents={member?.legalDocuments}
                workStatus={contract?.workStatus}
                country={contract?.country}
                contractType={contract?.type}
                edit={current === Experience.MEMBER && shouldAllowEdit}
                uncommittedChangesCallback={uncommittedChangesCallback}
              />
            </>
          )}
      </Accordion.Container>
      {showOffboardDialog && (
        <OffboardDialog
          show={showOffboardDialog}
          onClose={handleCloseOffboardDialog}
          contractId={contract?.id}
        />
      )}
      <OffboardNotStartedMemberDialog />
    </>
  );
};

export default EmployeeDetailsCard;
