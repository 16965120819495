import React from 'react';

import {
  Company,
  ComplianceInsightDefinition,
  CustomerIntegrationDefinition,
  TimeOffInsightDefinition,
} from '../__generated__/graphql';

export enum Experience {
  COMPANY = 'company',
  MEMBER = 'member',
  PARTNER = 'partner',
  OPERATIONS = 'operations',
  DEVELOPER = 'developer',
}

export enum PackageName {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  BRONZE = 'BRONZE',
  GOLD_PLUS = 'GOLD_PLUS',
}

export enum CategoryOptions {
  MEALS_AND_ENTERTAINMENT = 'MEALS_AND_ENTERTAINMENT',
  OFFICE_EXPENSES = 'OFFICE_EXPENSES',
  PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
  RENT_UTILITIES_PHONE = 'RENT_UTILITIES_PHONE',
  TRAVEL_EXPENSES = 'TRAVEL_EXPENSES',
  PER_DIEM = 'PER_DIEM',
  OTHER = 'OTHER',
}

export type CustomTimeOffInsightDefinition = TimeOffInsightDefinition & {
  timeOffRecommendedValue: TimeOffInsightDefinition['recommendedValue'];
};

export type CustomComplianceInsightDefinition = ComplianceInsightDefinition & {
  complianceRecommendedValue: ComplianceInsightDefinition['recommendedValue'];
};

export interface IntegrationDefinition extends CustomerIntegrationDefinition {
  name: string;
  logo?: React.ReactElement;
  extendedLogo?: React.ReactElement;
}

export interface User {
  email?: string;
  firstName?: string;
  imageUrl?: string;
  langKey?: string;
  id?: number;
  lastName?: string;
  permissions?: string[];
  experiences: { current: Experience; authorized: Experience[] };
  // TODO: Move module configuration outside of User context as it should be workspace specific
  modules: any[];
  companyList?: Pick<Company, 'id' | 'displayName' | 'companyLogo'>[];
  selectedCompany?: Pick<Company, 'id' | 'displayName' | 'companyLogo'>;
  defaultCompanyId?: Company['id'];
  isManager?: boolean;
}
