import { TFunction } from 'i18next';
import * as yup from 'yup';

import { Gender, Relationship } from '__generated__/graphql';

const insuranceSchema = (
  isUSA: boolean,
  t: TFunction<'contract-onboarding.member'>,
): yup.ObjectSchema<{
  insuranceDetails: yup.ArraySchema<
    yup.ObjectSchema<{
      firstName: yup.StringSchema;
      lastName: yup.StringSchema;
      dateOfBirth: yup.StringSchema<string | null | undefined>;
      gender: yup.StringSchema<string | null | undefined>;
      relationship: yup.StringSchema<string | null | undefined>;
      nationalIdNumber: yup.StringSchema;
      identity: yup.AnySchema;
    }>
  >;
}> =>
  yup.object().shape({
    insuranceDetails: yup.array().of(
      yup.object().shape({
        firstName: yup
          .string()
          .required(t('first-name.required', 'First name required')),
        lastName: yup
          .string()
          .required(t('last-name.required', 'Last name required')),
        dateOfBirth: yup
          .string()
          .nullable()
          .required(
            t(
              'member.benefit-details.date-of-birth.required',
              'Date of birth required',
            ),
          ),
        gender: yup
          .string()
          .nullable()
          .oneOf(
            Object.values(Gender),
            t('gender.required', 'Gender required'),
          ),
        relationship: yup
          .string()
          .nullable()
          .oneOf(
            Object.values(Relationship),
            t('relationship.required', 'Relationship required'),
          ),
        nationalIdNumber: yup
          .string()
          .test(
            'relationship-required-if',
            isUSA
              ? t('national-id-number.required-usa', 'SSN number required')
              : t('national-id-number.required', 'National id number required'),
            (value, ctx) => {
              if (isUSA) {
                return yup.string().required().isValid(value);
              }
              const isNotSonORDaughter = ![
                Relationship.DAUGHTER,
                Relationship.SON,
              ].includes(ctx.parent.relationship);
              if (isNotSonORDaughter) {
                return yup.string().required().isValid(value);
              }
              return yup.string().isValid(value);
            },
          ),
        identity: yup.mixed(),
      }),
    ),
  });

export default insuranceSchema;
