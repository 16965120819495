import React from 'react';

import { Row } from 'react-table';

import 'twin.macro';

import Icon from '../../../icon';
import { TableBodyProps, TableDataObjectType } from '../../types';
import StyledRow from '../styled-row';
import TableCell from '../table-cell';
import TableRow from '../table-row';

type TableRowProps = Pick<
  TableBodyProps,
  | 'bgRowsType'
  | 'rowStyles'
  | 'shouldHighlightRow'
  | 'handleRowClick'
  | 'disableSelection'
  | 'tableCellStyle'
  | 'expandIconPosition'
  | 'renderExpandedRow'
  | 'groupByLabel'
> & {
  row: Row<TableDataObjectType>;
  prepareRow: (currRow: Row<TableDataObjectType>) => void;
  isNewThemeApplied: boolean;
};

const GroupedRow: React.FC<TableRowProps> = ({
  row,
  prepareRow,
  groupByLabel,
  isNewThemeApplied,
  ...props
}) => (
  <>
    <StyledRow
      isGroupedRow
      isNewThemeApplied={isNewThemeApplied}
      tw="text-ps font-semibold cursor-pointer"
      key={`row-grouped-${row.id}`}
      onClick={(e: any) => {
        e.stopPropagation();
        row?.toggleRowExpanded();
      }}
    >
      <TableCell tw="py-base w-full" colSpan={row.cells.length}>
        <div tw="flex items-center">
          <Icon name={row.isExpanded ? 'caret-down' : 'caret-right'} />
          <span tw="mx-small">
            {typeof groupByLabel === 'function'
              ? groupByLabel(row)
              : `${row.groupByVal} (${row.subRows?.length ?? 0})`}
          </span>
        </div>
      </TableCell>
    </StyledRow>

    {row.isExpanded &&
      row.subRows?.map((subRow, i) => {
        prepareRow(subRow);
        return (
          <TableRow
            {...props}
            row={subRow}
            itemIndex={i}
            isNewThemeApplied={isNewThemeApplied}
          />
        );
      })}
  </>
);

export default GroupedRow;
