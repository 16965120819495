import React from 'react';

import { useTranslation } from 'react-i18next';

import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const SignMsaTask: React.FC<TaskEntryProps> = ({ task }) => {
  const { t } = useTranslation('contract-onboarding');

  return (
    <TaskEntry
      name={t('onboarding-checklist.task-list.sign-msa.title', 'Sign MSA')}
      completed={task.completed}
    />
  );
};

export default SignMsaTask;
