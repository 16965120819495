import React from 'react';

import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import tw from 'twin.macro';

import Modal from 'common/components/confirmation-dialog';

import { CountryCode, Maybe } from '__generated__/graphql';

const RequirementInputModal = React.lazy(
  () => import('../requirement-input-modal'),
);

const AssetRequestButton = tw.button`
  text-ps text-text-action
`;

interface RequestAssetButtonProps {
  selectedCountry: Maybe<CountryCode>;
}

const RequestAssetButton: React.FC<RequestAssetButtonProps> = ({
  selectedCountry,
}) => {
  const { t } = useTranslation('equipment.company');
  const [showModal, setShowModal] = useToggle(false);

  return (
    <div>
      {showModal && (
        <Modal.Lightbox>
          <Modal.Container tw="w-[483px]">
            <RequirementInputModal
              selectedCountry={selectedCountry}
              onCancel={() => {
                setShowModal(false);
              }}
            />
          </Modal.Container>
        </Modal.Lightbox>
      )}
      <AssetRequestButton onClick={() => setShowModal(true)}>
        {t('asset-request.button', "Can't find what you are looking for?")}
      </AssetRequestButton>
    </div>
  );
};

export default RequestAssetButton;
