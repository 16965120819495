import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import tw, { TwStyle, theme } from 'twin.macro';

import Button from '../../../../../button';
import { VerticalCardSeparator } from '../../../../../card-separator';
import { useModal } from '../../../../../hooks';
import Icon from '../../../../../icon';
import Modal from '../../../../../modal';
import { ThemeContext } from '../../../../../theme-provider';
import ToolTip from '../../../../../tooltip';
import FilterForm, { FilterFormProps } from '../filter-form';

interface MinimalFilterFormProps extends FilterFormProps {
  selectedFilterCount: number;
  hasRequiredFilters: boolean;
  resetFilters: () => void;
  minimalFilterButtonStyles?: TwStyle;
}

const TOOLTIP_FADE_DELAY = 1500;

const MinimalFilterForm: React.FC<MinimalFilterFormProps> = ({
  filteredFilters,
  loading,
  showAddNewFilter,
  fieldArray,
  onSubmit,
  handleFormResetToDefault,
  selectedFilterCount,
  hasRequiredFilters,
  resetFilters,
  minimalFilterButtonStyles,
  disable,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('common.table');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipWasShown, setTooltipWasShown] = useState(false);
  const [
    showFilterDialog,
    handleCloseFilterDialog,
    handleOpenFilterDialog,
  ] = useModal(false);

  useEffect(() => {
    if (hasRequiredFilters && selectedFilterCount > 0 && !tooltipWasShown) {
      setTooltipWasShown(true);
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, TOOLTIP_FADE_DELAY);
    }
  }, [hasRequiredFilters, selectedFilterCount]);

  return (
    <div tw="flex items-center">
      <VerticalCardSeparator tw="w-6 h-large mx-small border-l-2" />
      <ToolTip
        content={
          <div tw="w-[200px]">
            <p tw="text-p font-semibold">
              {t('filters.filter-tooltip.header', 'Filters have been applied.')}
            </p>
            <p tw="text-ps text-[#D2DDFD] font-normal">
              {t(
                'filters.filter-tooltip.description',
                'Click here to change them.',
              )}
            </p>
          </div>
        }
        variant="bottom"
        controlled
        showTooltip={showTooltip}
        fadeDelay={TOOLTIP_FADE_DELAY}
      >
        <button
          type="button"
          css={[
            selectedFilterCount > 0
              ? [
                  tw`bg-background-inverted text-text-inverted-primary`,
                  !isNewThemeApplied && tw`bg-primary`,
                ]
              : tw`bg-background-white text-text-primary`,
            tw`flex items-center justify-center border-border-primary rounded-tiny px-[10px] h-extra-large`,
            { ...minimalFilterButtonStyles },
          ]}
          onClick={handleOpenFilterDialog}
        >
          <Icon
            name="funnel"
            fill="transparent"
            stroke={
              selectedFilterCount > 0
                ? theme`colors.icon-inverted-primary`
                : theme`colors.icon-primary`
            }
          />
          <span tw="ml-6 text-ps font-medium">
            {t('filters.filter-button', 'Filters')}
          </span>
          {selectedFilterCount > 0 && (
            <span tw="rounded-full width[20px] height[20px] text-pxs text-text-primary font-medium ml-extra-small bg-background-white">
              {selectedFilterCount}
            </span>
          )}
        </button>
      </ToolTip>
      {selectedFilterCount > 0 && (
        <Button
          variant="inline"
          size="small"
          tw="text-[#011241] text-ps font-normal"
          onClick={resetFilters}
        >
          {t('filters.filter-clear-button', 'Clear all')}
        </Button>
      )}
      <Modal
        open={showFilterDialog}
        onClose={handleCloseFilterDialog}
        tw="w-3/4 max-h-[90vh] overflow-y-visible"
      >
        <div tw="flex justify-between mb-base">
          <h5 tw="text-h5 text-text-primary font-semibold">
            {t('filters.filter-results', 'Filter Results')}
          </h5>
          <Button variant="content" onClick={handleCloseFilterDialog}>
            <Icon
              tw="h-[18px] w-[18px]"
              name="cross"
              fill={theme`colors.icon-secondary`}
            />
          </Button>
        </div>

        <FilterForm
          loading={loading}
          filteredFilters={filteredFilters}
          handleFormResetToDefault={handleFormResetToDefault}
          fieldArray={fieldArray}
          onSubmit={(val) => {
            onSubmit(val);
            handleCloseFilterDialog();
          }}
          showAddNewFilter={showAddNewFilter}
          disable={disable}
        />
      </Modal>
    </div>
  );
};

export default MinimalFilterForm;
