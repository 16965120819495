import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const preparingContract: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.preparing-contract.title', {
      defaultValue: 'We are preparing the contract',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.preparing-contract.description', {
      defaultValue:
        'We are working through the contract agreement and adding the final touches before we send it across to you for signature. We will reach out to you soon.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t('onboarding-phase.preparing-contract.label', 'Preparing Contract'),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t('onboarding-phase.preparing-contract.status', 'Pending on Multiplier'),
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default preparingContract;
