/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React, { useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';

import ComboBox from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import countryLabels from 'common/constants/country-labels';

import {
  Address,
  AddressInput,
  CountryCode,
  DataFieldDefinition,
  Maybe,
} from '__generated__/graphql';

import { Paths } from '../../utils/types';
import { DetailValue } from '../layout';

interface GetValueProps {
  section: AddressSubSectionDefinition;
  editExcludes?: Paths<AddressInput>[];
  value?: Maybe<Address>;
  stateDropdownValues: {
    label: string;
    value: string;
    title: string;
  }[];
  selectedCountry?: Maybe<CountryCode>;
  memberLegalDataRequirements?: { [key: string]: DataFieldDefinition };
  isContractor?: boolean;
}

const GetValue: React.FC<GetValueProps> = ({
  editExcludes,
  value,
  section,
  stateDropdownValues,
  selectedCountry,
  memberLegalDataRequirements,
  isContractor,
}) => {
  const { register, control } = useFormContext();

  const excludedEORCountries = useFeature(
    'contract-onboarding-countries-exclusion-list',
  )?.value?.EOR;

  const allCountriesDropdownValues = useMemo(
    () =>
      Object.values(CountryCode)
        .filter((c) => !excludedEORCountries?.includes(c))
        .map((c) => ({
          label: countryLabels[c],
          value: c,
          title: c,
        })),
    [],
  );

  if (editExcludes && editExcludes.includes(section.name)) {
    return (
      <DetailValue tw="text-text-tertiary my-extra-small">
        {value?.[section.name]
          ? (section.mapValue &&
              section.mapValue(value[section.name] as string)) ||
            value[section.name]
          : ''}
      </DetailValue>
    );
  }

  switch (section.name) {
    case 'country':
      return (
        <Controller
          name="address.country"
          control={control}
          render={({
            field: { value: renderValue, onChange: renderOnChange },
          }) => (
            <ComboBox
              data-testid={`${section.name}-input`}
              variant="autocomplete"
              dropdownValues={allCountriesDropdownValues}
              placeholder="Country"
              value={renderValue as string}
              disabled={
                memberLegalDataRequirements
                  ? memberLegalDataRequirements[`address.country`]?.editable ===
                      false || isContractor
                  : false
              }
              onChange={renderOnChange}
              showArrow
            />
          )}
        />
      );
    case 'state':
      return (
        <>
          {(selectedCountry === CountryCode.USA ||
            selectedCountry === CountryCode.CAN) && (
            <Controller
              name="address.state"
              control={control}
              render={({
                field: { value: stateValue, onChange: renderOnChange },
              }) => (
                <ComboBox
                  data-testid={`${section.name}-input`}
                  variant="autocomplete"
                  dropdownValues={stateDropdownValues}
                  placeholder={section.placeholder ?? ''}
                  value={stateValue as string}
                  disabled={
                    memberLegalDataRequirements
                      ? memberLegalDataRequirements[`address.state`]
                          ?.editable === false || isContractor
                      : false
                  }
                  onChange={renderOnChange}
                  showArrow
                />
              )}
            />
          )}
        </>
      );
    default: {
      return (
        <TextInput
          tw="my-6"
          data-testid={`${section.name}-input`}
          placeholder={section.placeholder}
          {...register(`address.${section.name}`)}
          disabled={
            memberLegalDataRequirements
              ? memberLegalDataRequirements[`address.${section.name}`]
                  ?.editable === false || isContractor
              : false
          }
        />
      );
    }
  }
};

const GetLabel: React.FC<{
  section: AddressSubSectionDefinition;
  editExcludes?: Paths<AddressInput>[];
  value?: Maybe<Address>;
  selectedCountry?: Maybe<CountryCode>;
}> = ({ section, editExcludes, value, selectedCountry }) => {
  if (
    editExcludes &&
    editExcludes.includes(section.name) &&
    !value?.[section.name]
  ) {
    return null;
  }

  if (
    section.name === 'state' &&
    !(
      selectedCountry === CountryCode.USA || selectedCountry === CountryCode.CAN
    )
  ) {
    return null;
  }

  return <p tw="text-text-primary text-ps font-normal">{section.label}</p>;
};

const AddressInputField: React.FC<{
  editExcludes?: Paths<AddressInput>[];
  value?: Maybe<Address>;
  onChange?: (...event: unknown[]) => void;
  stateDropdownValues: {
    label: string;
    value: string;
    title: string;
  }[];
  selectedCountry?: Maybe<CountryCode>;
  memberLegalDataRequirements?: { [key: string]: DataFieldDefinition };
  isContractor?: boolean;
}> = ({
  editExcludes,
  value,
  selectedCountry,
  stateDropdownValues,
  memberLegalDataRequirements,
  isContractor,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const addressSubSections = useMemo<AddressSubSectionDefinition[]>(
    () => [
      {
        name: 'street',
        label: t('street-label', 'Address Street (Optional)'),
        placeholder: t('address-street-placeholder', 'Address Street'),
      },
      {
        name: 'line1',
        label: t('line1-label', 'Address Line 1'),
        placeholder: t('address-line1-placeholder', 'Address Line 1'),
      },
      {
        name: 'line2',
        label: t('line2-label', 'Address Line 2 (Optional)'),
        placeholder: t('address-line2-placeholder', 'Address Line 2'),
      },
      {
        name: 'country',
        label: t('country-label', 'Country'),
        mapValue: (countryCode) => countryLabels[countryCode],
      },
      {
        name: 'state',
        label: t('state-label', 'Address State (Optional)'),
        placeholder: t('address-state-placeholder', 'Address State'),
      },
      {
        name: 'city',
        label: t('city-label', 'Address City'),
        placeholder: t('address-city-placeholder', 'Address City'),
      },
      {
        name: 'postalCode',
        label: t('postal-code-label', 'Postal Code/ Zip Code'),
        placeholder: t('postal-code-placeholder', 'Address Postal Code'),
      },
    ],
    [],
  );

  return (
    <div tw="col-span-3">
      {addressSubSections.map((section) => (
        <div key={section.name} tw="mb-small">
          <GetLabel
            editExcludes={editExcludes}
            section={section}
            value={value}
            selectedCountry={selectedCountry}
          />
          <GetValue
            editExcludes={editExcludes}
            section={section}
            value={value}
            selectedCountry={selectedCountry}
            stateDropdownValues={stateDropdownValues}
            memberLegalDataRequirements={memberLegalDataRequirements}
            isContractor={isContractor}
          />
        </div>
      ))}
    </div>
  );
};

interface AddressSubSectionDefinition {
  name: keyof AddressInput;
  label: string;
  placeholder?: string;
  mapValue?: (value: string) => string;
}

export default AddressInputField;
