import { gql } from '@apollo/client';

export default gql`
  fragment addressFields on Address {
    city
    country
    key
    landmark
    line1
    line2
    postalCode
    province
    state
    street
    zipcode
  }
`;
