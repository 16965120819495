import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingContractWetInkPreparingConfirmation: ContractOnboardingStepConfig = {
  title: (t): string =>
    t(
      'onboarding-phase.onboarding-contract-wetink-preparing-confirmation.title',
      'Send Contract',
    ),
  status: (t): string =>
    t(
      'onboarding-phase.onboarding-contract-wetink-preparing-confirmation.status',
      'Pending on You',
    ),
  variant: ProgressWidgetContentVariant.DESCRIPTION,
};

export default onboardingContractWetInkPreparingConfirmation;
