import { gql } from '@apollo/client';

export default gql`
  query GetCountryWithLegalEntity($country: CountryCode!) {
    country(country: $country) {
      code
      compliance {
        countryCode
        legalEntity(type: MULTIPLIER) {
          legalEntity {
            legalName
            address {
              line1
              line2
              country
            }
          }
        }
      }
    }
  }
`;
