/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from 'common/components/text-input';
import StartDateField from 'contract-onboarding/company/components/start-date-field';

import { Contract, ContractType } from '__generated__/graphql';

import { BasicDetailsFormValues } from '../basic-details';

interface StartDateSelectorProps {
  selectedCountry: Contract['country'];
  selectedCountryState: Contract['countryStateCode'];
  selectedContractType: Contract['type'];
  selectedWorkStatus?: Contract['workStatus'];
  selectedLegalEntityId?: Contract['legalEntityId'];
}

const useGetStartDateLabel = (selectedContractType: Contract['type']) => {
  const { t } = useTranslation('contract-onboarding.common');

  switch (selectedContractType) {
    case ContractType.FREELANCER:
      return t(
        'definition-phase.basic-details.freelancer-start-date.label',
        'Contract Start Date',
      );
    case ContractType.CONTRACTOR:
      return t(
        'definition-phase.basic-details.contractor-start-date.label',
        'Agreement Start Date',
      );
    default:
      return t(
        'definition-phase.basic-details.employment-start-date.label',
        'Employment Start Date',
      );
  }
};

const StartDateSelector: React.FC<StartDateSelectorProps> = ({
  selectedCountry,
  selectedCountryState,
  selectedContractType,
  selectedWorkStatus,
  selectedLegalEntityId,
}) => {
  const { watch } = useFormContext<BasicDetailsFormValues>();

  const contractType = watch('contractType');
  const taxResidency = watch('taxResidency');

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="start-on">
        {useGetStartDateLabel(contractType)}
      </TextInput.Label>
      <StartDateField
        selectedCountry={selectedCountry || taxResidency}
        selectedCountryState={selectedCountryState}
        selectedContractType={selectedContractType}
        selectedWorkStatus={selectedWorkStatus}
        selectedLegalEntityId={selectedLegalEntityId}
      />
    </TextInput.Container>
  );
};

export default StartDateSelector;
