/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import 'twin.macro';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Loader, SpinnerType } from '@multiplier/common';

import { FormCard, FormLayout } from 'contract-onboarding/components/layout';
import OnboardingHeader, {
  ContractTypeVariant,
} from 'contract-onboarding/components/onboarding-header';
import SignOutButton from 'contract-onboarding/member/components/sign-out-button';
import SwitchViewButton from 'contract-onboarding/member/components/switch-view-button';
import useUpdateVisaDocuments from 'contract-onboarding/member/hooks/update-visa-documents';
import {
  getLegalDocumentSchema,
  groupLegalDocumentDefinitionByKey,
} from 'contract-onboarding/member/services/legal-documents';

import {
  ContractOnboardingStep,
  LegalDocumentCategory,
  LegalDocumentRequirement,
  Maybe,
  useGetMemberDetailsForOnboardingQuery,
  useGetMemberVisaDocumentRequirementsLazyQuery,
} from '__generated__/graphql';

import ContactSupport from './components/contact-support';
import VisaDocumentsFormFields, {
  DocumentFormParams,
} from './components/form-fields';

export type { DocumentFormParams } from './components/form-fields';

export interface OnboardingStepProps {
  currentStep?: ContractOnboardingStep;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
}

const VisaDocuments: React.FC<OnboardingStepProps> = () => {
  const { t } = useTranslation('contract-onboarding.member');
  const {
    data: { member } = { member: {} },
    loading: loadingContract,
  } = useGetMemberDetailsForOnboardingQuery();

  const contract = useMemo(() => member?.contracts?.[0], [member]);

  const { onSubmit, loading: submitLoading } = useUpdateVisaDocuments();

  const [
    getVisaEligibityDocumentRequirements,
    { data: requirements, loading: loadingVisaRequirements },
  ] = useGetMemberVisaDocumentRequirementsLazyQuery();

  const visaRequirements = useMemo(
    () =>
      requirements?.visaDocumentRequirements.map((it) => ({
        ...it,
        required: false,
      })),
    [requirements?.visaDocumentRequirements],
  );

  useEffect(() => {
    if (contract?.country) {
      getVisaEligibityDocumentRequirements({
        variables: {
          destinationCountry: contract.country,
          originCountry:
            member?.nationalities?.[0]?.country ?? contract.country,
          categories: [LegalDocumentCategory.VISA_PRIMARY],
        },
      });
    }
  }, [contract]);

  const memberDocumentRequirements = useMemo<{
    [key: string]: LegalDocumentRequirement;
  }>(() => groupLegalDocumentDefinitionByKey(visaRequirements), [
    visaRequirements,
  ]);

  const methods = useForm<DocumentFormParams>({
    mode: 'onChange',
    resolver: yupResolver(
      getLegalDocumentSchema(memberDocumentRequirements, t),
    ),
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <div tw="flex justify-center min-h-screen h-full">
      <div tw="w-full">
        <div tw="flex h-[152px] bg-background text-center">
          <div
            tw="flex flex-col gap-y-36 items-stretch width[740px] ml-auto mr-auto justify-center"
            data-testid="onboarding-header"
          >
            <OnboardingHeader
              id={contract?.id}
              country={contract?.country}
              firstName={member?.firstName}
              lastName={member?.lastName}
              position={contract?.position}
              contractType={contract?.type}
              contractTypeVariant={ContractTypeVariant.CENTER}
              additionalContent={
                <>
                  <SwitchViewButton />
                  <SignOutButton />
                </>
              }
            />
          </div>
        </div>
        <FormLayout
          tw="grid gap-y-16 [width: 740px] mx-auto mt-24"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormCard>
            <div tw="grid gap-x-16">
              <FormProvider {...methods}>
                {visaRequirements == null ? (
                  <Loader.Spinner
                    tw="h-[129px]"
                    variant={SpinnerType.CUSTOM_LAYOUT}
                  />
                ) : (
                  <VisaDocumentsFormFields
                    requirements={visaRequirements}
                    legalDocuments={member?.legalDocuments}
                  />
                )}
              </FormProvider>
            </div>
          </FormCard>
          <ContactSupport />
          <div tw="flex flex-row">
            <Button
              tw="ml-auto mb-24"
              data-testid="continue"
              type="submit"
              size="medium"
              disabled={
                !isValid ||
                loadingVisaRequirements ||
                loadingContract ||
                submitLoading
              }
              loading={submitLoading}
            >
              {t('step-navigation-footer.continue', 'Continue')}
            </Button>
          </div>
        </FormLayout>
      </div>
    </div>
  );
};

export default VisaDocuments;
