import { differenceInHours, isFuture } from 'date-fns';

import {
  ContractOnboardingStatus,
  ContractStatus,
} from '../../__generated__/graphql';

export type PendingOnboardings = {
  numberOfContracts: number;
  cutoffDate: Date;
};

type Onboarding =
  | {
      activationCutoff?: string;
      status?: ContractOnboardingStatus | null;
    }
  | null
  | undefined;

export type ContractWithActivationCutoff = {
  status?: ContractStatus | null;
  contractStarted?: boolean | null;
  onboarding?: Onboarding;
  contractBlocked?: boolean | null;
};

export function getPendingOnboardings(
  contracts: ContractWithActivationCutoff[] | null | undefined,
): PendingOnboardings | null {
  if (!contracts) {
    return null;
  }

  const allOnboardings = contracts
    .filter(
      (contract) =>
        contract?.status &&
        !contract.contractBlocked &&
        [ContractStatus.ONBOARDING, ContractStatus.ACTIVE].includes(
          contract.status,
        ) &&
        !contract.contractStarted &&
        contract.onboarding?.status !== ContractOnboardingStatus.REVOKED,
    )
    ?.flatMap((contract) => contract?.onboarding);

  if (!allOnboardings.length) {
    return null;
  }

  const contractsThatNeedAttention = allOnboardings.filter((onboarding) =>
    needsAttention(onboarding),
  );

  if (contractsThatNeedAttention.length === 0) {
    return null;
  }

  const earliest = getEarliestFutureCutoffDate(contractsThatNeedAttention);

  return {
    numberOfContracts: contractsThatNeedAttention.length,
    cutoffDate: earliest,
  };
}

export function needsAttention(onboarding: Onboarding): boolean {
  const daysUntil = getDaysUntilCutoff(onboarding);
  return daysUntil !== null && daysUntil <= 10;
}

export function getDaysUntilCutoff(onboarding: Onboarding): number | null {
  const today = new Date();

  if (!onboarding?.activationCutoff) {
    return null;
  }

  const cutoff = new Date(onboarding.activationCutoff);
  return Math.round(differenceInHours(cutoff, today) / 24);
}

function getEarliestFutureCutoffDate(onboardings: Onboarding[]) {
  const activationCutoffs = (onboardings
    .map((onboarding) => onboarding?.activationCutoff)
    .filter((cutoff) => !!cutoff) as string[])
    .map((cutoff) => new Date(cutoff))
    .filter((cutoff) => isFuture(cutoff));

  activationCutoffs.sort((a, b) => a.getTime() - b.getTime());
  return activationCutoffs[0];
}
