import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../../../text-input';
import { TableFilterConfigValue } from '../../../../types';
import Label from '../label';

const TextFilterInput: React.FC<{
  isNumber?: boolean;
  value: string;
  onChange: (val: string) => void;
  filterConfig: TableFilterConfigValue;
}> = ({ isNumber, value, onChange, filterConfig }) => {
  const { t } = useTranslation('common.table');
  return (
    <>
      <Label>{filterConfig?.prefix ?? t('filters.equals', 'Equals:')}</Label>
      <TextInput
        type={isNumber ? 'number' : 'text'}
        data-testid="text-matching"
        tw="mt-extra-small"
        placeholder={filterConfig?.placeholder ?? ''}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </>
  );
};

export default TextFilterInput;
