import { useEffect } from 'react';

import {
  Contract,
  GetCountryAllowancesConfigQuery,
  useGetCountryAllowancesConfigLazyQuery,
} from '../../../__generated__/graphql';

const useGetCountryAllowancesConfig = ({
  country,
  isNewAllowancesConfigEnabled,
}: {
  country: Contract['country'];
  isNewAllowancesConfigEnabled: boolean;
}): {
  countryAllowancesConfig:
    | GetCountryAllowancesConfigQuery['countryAllowancesConfig']
    | undefined;
  loading: boolean;
} => {
  const [
    getCountryAllowancesConfig,
    { data, loading },
  ] = useGetCountryAllowancesConfigLazyQuery();

  useEffect(() => {
    if (country && isNewAllowancesConfigEnabled) {
      getCountryAllowancesConfig({
        variables: { country },
      });
    }
  }, [country, isNewAllowancesConfigEnabled]);

  return { countryAllowancesConfig: data?.countryAllowancesConfig, loading };
};

export default useGetCountryAllowancesConfig;
