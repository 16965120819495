/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as DocumentImage } from '@multiplier/assets/files/common/document.svg';
import {
  FileUpload,
  Icon,
  StatelessFileUpload,
  ThemeContext,
} from '@multiplier/common';
import { useGetTimeOffData } from '@multiplier/time-off';
import tw, { theme } from 'twin.macro';
import * as yup from 'yup';

import AppFeature from 'app/features';
import { useModal } from 'app/hooks';
import { notEmpty } from 'app/utils/array';
import ButtonGroup from 'common/components/button-group';
import ConfirmationDialog from 'common/components/confirmation-dialog';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';

import {
  ComplianceParamPeriodLimit,
  ComplianceParamPeriodUnit,
  ContractType,
} from '__generated__/graphql';

import LeaveEntitlement from '../../../../../components/leave-entitlement';
import {
  useComplianceParamsDefinition,
  useContract,
  useSubmitCustomContract,
} from '../../../../hooks';
import stepConfig from '../../step-config';

export enum ContractInputType {
  MULTIPLIER = 'MULTIPLIER',
  CUSTOM = 'CUSTOM',
  UNSELECTED = 'UNSELECTED',
}

export interface CustomContractForm {
  customContract?: File | null;
  complianceParams: {
    key: string | null;
    value: number | null;
    unit: ComplianceParamPeriodUnit | null;
    enabled: boolean;
  }[];
}

const ContractView: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { id: contractIdFromPath } = useParams<{ id?: string }>();
  const [contractInputType, setContractInputType] = useState(
    ContractInputType.UNSELECTED,
  );
  const { isNewThemeApplied } = useContext(ThemeContext);
  const {
    contract: {
      country,
      countryStateCode,
      term,
      type,
      compliance,
      startOn,
      endOn,
    },
  } = useContract(contractIdFromPath);

  const {
    assignedTimeOffEntitlements,
    timeOffTypeToDefinitionMap,
    availableCustomEntitlementOptions,
    clause,
  } = useGetTimeOffData({
    contractId: contractIdFromPath,
    contractType: type,
  });

  const isSorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;
  const singleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;

  const isNewHrMember =
    type === ContractType.HR_MEMBER &&
    (isSorOnboardingEnabled || singleOnboardingEnabled);

  const {
    groupedComplianceParamDefinitions: { complianceParamDefinitions },
  } = useComplianceParamsDefinition(
    type,
    country,
    countryStateCode,
    term,
    startOn,
    endOn,
  );

  useEffect(() => {
    if (type === ContractType.HR_MEMBER)
      setContractInputType(ContractInputType.CUSTOM);
  }, [type]);

  const shouldValidate = useMemo(
    () =>
      type &&
      type === ContractType.FREELANCER &&
      contractInputType === ContractInputType.CUSTOM,
    [contractInputType, type],
  );

  const methods = useForm<CustomContractForm>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        customContract: shouldValidate ? yup.mixed().required() : yup.mixed(),
      }),
    ),
  });

  const { isValid } = methods.formState;

  const { loading, onSubmit } = useSubmitCustomContract(
    contractInputType,
    type,
    contractIdFromPath,
  );

  // re-trigger form validation when complianceParamsDefinition promise is returned
  useEffect(() => {
    methods.trigger();
  }, [complianceParamDefinitions, contractInputType]);

  useEffect(() => {
    const complianceParams =
      (compliance?.complianceParams &&
        compliance.complianceParams
          ?.filter(notEmpty)
          .filter((param) => param.key !== 'leavePolicy') // filter out leave policy temporarily
          .map((param: ComplianceParamPeriodLimit) => {
            const defaultValue = param.key
              ? complianceParamDefinitions[param.key]?.validation?.find(
                  (definition) => param?.unit === definition?.unit,
                )?.defaultValue
              : undefined;

            return {
              key: param.key,
              value: param.value === -1 ? defaultValue : param.value,
              unit: param.unit,
              enabled: param.value !== -1,
            };
          })) ??
      [];

    /**
     * TODO:
     * Strange bug that causes the param.0.value to become undefined when triggering handleSubmit.
     * This seems to have been resolved when upgrading to react-hook-form@7.17.4 but that comes
     * with its own set of problems. The compliance-entry component must be refactored to work with
     * that version.
     *
     * For now, we insert a dummy element into the list so that the real complianceParams are unaffected.
     * Similar logic on compliance definition page.
     */
    complianceParams.unshift({
      key: null,
      unit: null,
      value: null,
      enabled: false,
    });

    methods.reset({
      customContract: null,
      complianceParams: [],
    });
  }, [compliance, complianceParamDefinitions]);

  const onContractTypeSelect = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const targetContractType = (e.target as HTMLInputElement)
      .value as ContractInputType;
    if (contractInputType !== targetContractType) {
      methods.setValue('customContract', null);
    }
    setContractInputType(targetContractType);
    handleCloseActivateDialog();
  };

  const [
    showActivateDialog,
    handleCloseActivateDialog,
    handleOpenActivateDialog,
  ] = useModal();

  const handleSubmitOnConfirmation = () => {
    onSubmit(methods.getValues());
  };

  return (
    <StepLayout data-testid="contract-view">
      <div tw="flex flex-col gap-y-large mb-large">
        {type === ContractType.FREELANCER && (
          <FormCard data-testid="contract-selection" tw="items-center">
            <div tw="text-18 text-text-primary font-semibold">
              {t(
                'definition-phase.contract.contract-selection.header',
                'Select a Contract',
              )}
            </div>
            <div
              css={[
                tw`text-text-secondary text-ps leading-[26px] max-w-[416px] text-center`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {t(
                'definition-phase.contract.contract-selection.description',
                'Choose between selecting a compliant contract generated by Multiplier or upload your own finalized contract.',
              )}
            </div>
            <ButtonGroup
              variant="large"
              selected={contractInputType}
              options={[
                {
                  label: t(
                    'definition-phase.contract.contract-selection.button.multiplier',
                    'Multiplier Contract',
                  ),
                  value: ContractInputType.MULTIPLIER,
                },
                {
                  label: t(
                    'definition-phase.contract.contract-selection.button.custom',
                    'Upload My Own',
                  ),
                  value: ContractInputType.CUSTOM,
                },
              ]}
              onChange={onContractTypeSelect}
            />
          </FormCard>
        )}
      </div>
      <FormProvider {...methods}>
        <FormLayout onSubmit={methods.handleSubmit(onSubmit)}>
          {contractInputType === ContractInputType.MULTIPLIER && (
            <LeaveEntitlement
              country={country}
              clause={clause ?? ''}
              assignedTimeOffEntitlements={assignedTimeOffEntitlements}
              timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
              availableCustomEntitlementOptions={
                availableCustomEntitlementOptions
              }
            />
          )}
          {contractInputType === ContractInputType.CUSTOM && (
            <FormCard
              data-testid="upload-card"
              css={[isNewHrMember && tw`gap-y-none`]}
            >
              <div
                css={[
                  tw`py-base text-18 text-center`,
                  isNewHrMember && tw`text-left py-none`,
                ]}
              >
                {type === ContractType.FREELANCER ? (
                  <Trans
                    t={t}
                    i18nKey="definition-phase.contract.upload-contract-freelancer"
                  >
                    <span tw="font-semibold text-text-primary">
                      Upload Contract
                    </span>
                  </Trans>
                ) : (
                  <Trans
                    t={t}
                    i18nKey="definition-phase.contract.upload-contract-custom"
                  >
                    <span tw="text-p font-semibold text-text-primary">
                      Signed employment contract (optional)
                    </span>
                  </Trans>
                )}
              </div>
              <div tw="flex flex-row gap-x-large">
                {!isNewHrMember && (
                  <div tw="flex-shrink-0 w-48 h-48 rounded-base shadow-high border border-border-secondary flex justify-center items-center">
                    <Icon name="error" fill={theme`colors.icon-primary`} />
                  </div>
                )}
                <div>
                  {type === ContractType.FREELANCER ? (
                    t(
                      'definition-phase.contract.disclaimer-freelancer',
                      'The contract you upload must be the final contract which is signed and approved by the Company and the freelancer.',
                    )
                  ) : (
                    <span tw="text-ps text-slateGrey400 ">
                      {t(
                        'definition-phase.contract.disclaimer',
                        'The contract you upload must be the final contract which is signed and approved by you and your employee. This step is optional.',
                      )}
                    </span>
                  )}
                </div>
              </div>
              {!isNewHrMember && (
                <FileUpload
                  multiple={false}
                  onChange={(files: File[]) => {
                    methods.setValue(
                      'customContract',
                      files?.length ? files[0] : null,
                      { shouldValidate: true },
                    );
                  }}
                  accept={['application/pdf']}
                  description={t(
                    'definition-phase.contract.description',
                    'Files Supported: PDF (Max 3mb)',
                  )}
                />
              )}
              {isNewHrMember && (
                <div tw="mt-large">
                  <Controller
                    name="customContract"
                    control={methods.control}
                    render={({ field: { value } }) => (
                      <StatelessFileUpload
                        minimal
                        hideIcon
                        buttonStyle={tw`text-ps px-extra-large h-large text-text-primary bg-transparent border border-border-primary border-opacity-20 hover:(bg-background-action-hover bg-opacity-10) disabled:bg-transparent shadow-none`}
                        buttonText={t(
                          'gp-single-onboarding.file-upload.button-label',
                          'Browse file',
                        )}
                        description={t(
                          'gp-single-onboarding.file-upload.description',
                          'File supported: PDF only (Max 30mb)',
                        )}
                        accept={['application/pdf']}
                        files={value ? [value as File] : undefined}
                        onFileDrop={(files: File[]) => {
                          methods.setValue(
                            'customContract',
                            files?.length ? files[0] : null,
                            { shouldValidate: true },
                          );
                        }}
                        onRemoveFile={() => {
                          methods.setValue(`customContract`, null);
                        }}
                      />
                    )}
                  />
                </div>
              )}
            </FormCard>
          )}
          <StepNavigationFooter
            submitLoading={loading}
            disabled={!isValid}
            currentStep={currentStep}
            onboardingSteps={onboardingSteps}
            contractId={contractIdFromPath}
            hideSubmit={contractInputType === ContractInputType.UNSELECTED}
            stepConfig={stepConfig}
            showDialogButton={shouldValidate}
            dialogButtonCallback={handleOpenActivateDialog}
          />
          {shouldValidate && (
            <ConfirmationDialog
              open={showActivateDialog}
              onClose={handleCloseActivateDialog}
              asset={<DocumentImage tw="mt-40 mb-40" />}
              disabled={!methods.formState.isValid}
              title={t(
                'definition-phase.contract.contract-selection.confirm-modal-title',
                'Review Contract',
              )}
              description={t(
                'definition-phase.contract.contract-selection.confirm-modal-description',
                'Please ensure that the contract is signed and approved by both company and freelancer',
              )}
              buttonText={t(
                'definition-phase.contract.contract-selection.confirm-modal-button',
                'Confirm',
              )}
              onConfirm={() => {
                handleSubmitOnConfirmation();
                handleCloseActivateDialog();
              }}
              loading={false}
            />
          )}
        </FormLayout>
      </FormProvider>
    </StepLayout>
  );
};

export default ContractView;
