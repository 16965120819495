import { gql } from '@apollo/client';

export default gql`
  query GetCompanyTimeOffTypes($filter: TimeOffTypeFilter) {
    company {
      id
      timeOffTypeInfos(filter: $filter) {
        description
        label
        status
        type
        typeId
        isPaidLeave
        usage
        companyId
        createdOn
      }
    }
  }
`;
