/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import {
  benefitPartnerBillingFrequencyLabel,
  premiumFrequencyLabelShort,
} from 'insurance/utils/utils';

import {
  BenefitPartnerBillingFrequency,
  Contract,
  ContractType,
  CurrencyCode,
} from '__generated__/graphql';

const InsuranceNote = tw.div`text-background text-ps leading-[24px]`;

const InsuranceNotes: React.FC<{
  refundPolicy: boolean | null | undefined;
  platformFee: number | null | undefined;
  platformFeeApplicable: boolean | null | undefined;
  billingDuration: BenefitPartnerBillingFrequency | null | undefined;
  billingCurrency: CurrencyCode | null | undefined;
  contractType: Contract['type'];
}> = ({
  platformFee,
  platformFeeApplicable,
  billingDuration,
  billingCurrency,
  refundPolicy,
  contractType,
}) => {
  const { t } = useTranslation('insurance');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const billingFrequencyLabel = billingDuration
    ? benefitPartnerBillingFrequencyLabel[
        billingDuration as BenefitPartnerBillingFrequency
      ]?.(t)
    : '';

  const billingFrequencyShortLabel = billingDuration
    ? premiumFrequencyLabelShort[
        billingDuration as BenefitPartnerBillingFrequency
      ]?.(t)
    : '';

  const refundApplicableContent = refundPolicy ? (
    contractType === ContractType.CONTRACTOR ? (
      <Trans
        t={t}
        i18nKey="insurance-annual-note.note-3-refund-applicable-contractor"
      >
        <p data-testid="refund-applicable-note" tw="ml-extra-small">
          Insurance premium will be invoiced {{ billingFrequencyLabel }} and
          will be refunded on a pro-rata basis if the contractor is off-boarded
          early.
        </p>
      </Trans>
    ) : (
      <Trans t={t} i18nKey="insurance-annual-note.note-3-refund-applicable">
        <p data-testid="refund-applicable-note" tw="ml-extra-small">
          Insurance premium will be invoiced {{ billingFrequencyLabel }} and
          will be refunded on a pro-rata basis if the employee is off-boarded
          early.
        </p>
      </Trans>
    )
  ) : contractType === ContractType.CONTRACTOR ? (
    <Trans
      t={t}
      i18nKey="insurance-annual-note.note-3-non-refundable-contractor"
    >
      <p data-testid="non-refund-applicable-note" tw="ml-extra-small">
        Insurance premium will be invoiced {{ billingFrequencyLabel }} and not
        refunded if the contractor is off-boarded early.
      </p>
    </Trans>
  ) : (
    <Trans t={t} i18nKey="insurance-annual-note.note-3-non-refundable">
      <p data-testid="non-refund-applicable-note" tw="ml-extra-small">
        Insurance premium will be invoiced {{ billingFrequencyLabel }} and not
        refunded if the employee is off-boarded early.
      </p>
    </Trans>
  );

  return (
    <InsuranceNote
      data-testid="insurance-annual-note"
      css={[
        tw`text-text-secondary`,
        !isNewThemeApplied && tw`text-slateGrey900`,
      ]}
    >
      <span tw="font-semibold text-pxs text-text-tertiary uppercase mb-extra-small">
        {t('insurance-annual-note-new.header', 'Note:')}
      </span>
      <ul tw="list-disc pl-base text-ps font-medium mt-small flex flex-col gap-y-small">
        {platformFeeApplicable && !!platformFee ? (
          <li>
            <Trans t={t} i18nKey="insurance-annual-note.note-1-platform-fee">
              <p data-testid="platform-fee-note" tw="ml-extra-small">
                A platform fee of{' '}
                <span tw="font-semibold">
                  {{ billingCurrency }} {{ platformFee }} /{' '}
                  {{ billingFrequencyShortLabel }} / member{' '}
                </span>{' '}
                will be applicable.{' '}
                <a
                  href="https://help.usemultiplier.com/en/articles/9618615-what-is-platform-fee-in-insurance"
                  tw="text-text-action underline font-semibold"
                >
                  Learn more
                </a>
              </p>
            </Trans>
          </li>
        ) : null}
        <li>
          {contractType === ContractType.CONTRACTOR ? (
            <Trans
              t={t}
              i18nKey="insurance-annual-note.note-2-activation-contractor"
            >
              <p data-testid="insurance-activation-note" tw="ml-extra-small">
                Insurance activation will begin after the contractor&#39;s
                onboarding is completed and will take{' '}
                <span tw="underline">7 - 10</span> business days. Note that the
                insurance and agreement start date may not match.
              </p>
            </Trans>
          ) : (
            <Trans t={t} i18nKey="insurance-annual-note.note-2-activation">
              <p data-testid="insurance-activation-note" tw="ml-extra-small">
                Insurance activation will begin after the employee&#39;s
                onboarding is completed and will take{' '}
                <span tw="underline">7 - 10</span> business days. Note that the
                insurance and employee start date may not match.
              </p>
            </Trans>
          )}
        </li>
        <li>{refundApplicableContent}</li>
      </ul>
    </InsuranceNote>
  );
};

export default InsuranceNotes;
