import { init as initApm } from '@elastic/apm-rum';

import importMetaEnv from 'import-meta-env';

const getEnvironment = () => {
  switch (importMetaEnv.VITE_ENV) {
    case 'stage':
    case 'stg':
      return 'staging';
    case 'prod':
      return 'production';
    default:
      return importMetaEnv.VITE_ENV;
  }
};

const environment = getEnvironment();

const apm =
  importMetaEnv.VITE_ENV === 'prod' // only run apm in production env
    ? initApm({
        serviceName: `frontend-customer-${environment}`,

        serverUrl: 'https://fleet.usemultiplier.cloud/',

        serviceVersion: '1.0',

        environment,

        distributedTracingOrigins: [
          `${importMetaEnv.VITE_USER_SERVICE_URL}`.slice(0, -1),
          `${importMetaEnv.VITE_CORE_SERVICE_URL}`.replace('/graphql/', ''),
        ],

        active: environment !== 'local',
      })
    : undefined;

export default apm;
