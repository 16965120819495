/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { FieldPath, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect, useToggle } from 'react-use';

import { ApolloError } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import {
  Accordion,
  Callout,
  CalloutVariant,
  PillTag,
} from '@multiplier/common';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import tw from 'twin.macro';

import AppFeature from 'app/features';
import swiftCodesWithAdditionalCharge from 'common/constants/swift-codes-with-additional-charge';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import useUpdateCompanyBankDetails from 'contract-onboarding/company/hooks/submit-bank-details';
import {
  BankAccountType,
  SwiftFeeInfo,
  useGetSwiftFeeField,
} from 'contract-onboarding/components/additional-swift-fee-info';
import {
  BankDetailRequirementField,
  BankDetailsFormParams,
} from 'contract-onboarding/member/pages/onboarding/pages/bank-details';
import {
  getBankStatementsForUpload,
  getDynamicFields,
} from 'contract-onboarding/member/services/bank-data';

import {
  BankAccount,
  BankAccountDetail,
  Contract,
  ContractType,
  Maybe,
  MemberChangeCategory,
  MemberChangeRequest,
  MemberChangeRequestStatus,
  PaymentAccountRequirement,
} from '__generated__/graphql';

import { useModal } from '../../../app/hooks';
import { useSendChangeRequest } from '../../../team/hooks';
import RequestMadeDialog from '../../../team/member/personal-details/components/request-made-dialog';
import {
  getCorrespondingPillTag,
  haveSubmittedChangeRequestItem,
  transformToChangeRequests,
} from '../../services/change-request';
import BankAccountDetailsForm from '../bank-details-section/components/bank-details-sub-form';
import BankAccountStatements from '../bank-details-section/components/bank-statements';
import ComparisonDetail from '../bank-details-section/components/comparison';
import DynamicBankAccountDetails from '../bank-details-section/components/dynamic-bank-account-details';
import ErrorDetails, {
  setChangeErrorDetails,
} from '../bank-details-section/components/error-details';
import { DetailForm, DetailGrid } from '../layout';
import SectionActionBar from '../section-action-bar';

const getBankAccountType = (
  paymentAccountRequirementsLength: number,
  shouldForceToV1: boolean,
  isEditing: boolean,
  bankAccount?: Maybe<BankAccount>,
) => {
  if (shouldForceToV1) return BankAccountType.STATIC;
  if (isEditing)
    return paymentAccountRequirementsLength
      ? BankAccountType.DYNAMIC
      : BankAccountType.STATIC;
  if (bankAccount?.version === 1) {
    return BankAccountType.STATIC;
  }

  if (bankAccount?.version === 2) {
    return BankAccountType.DYNAMIC;
  }

  return BankAccountType.STATIC;
};

export type BankDetailsSectionHRVariant =
  | 'personal-details-view'
  | 'member-onboarding';

const BankDetailsSectionHR: React.FC<{
  contract: Contract;
  country: Contract['country'];
  contractType: Contract['type'];
  contractStatus: Contract['status'];
  editable?: boolean;
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  changeRequest?: Maybe<MemberChangeRequest>;
  lastSubmitOn?: Maybe<Date>;
  requireApproval?: boolean;
  updateData?: () => void;
  variant?: BankDetailsSectionHRVariant;
}> = ({
  //   member,
  contract,
  country,
  contractType,
  editable = false,
  uncommittedChangesCallback,
  changeRequest,
  lastSubmitOn,
  requireApproval,
  updateData,
  variant = 'member-onboarding',
}) => {
  const { t } = useTranslation([
    'contract-onboarding.common',
    'contract-onboarding.member',
  ]);
  const shouldForceToV1 = useFeature(AppFeature.SHOW_UPDATE_BANK_DETAILS_V1)
    ?.on;
  const shouldShowUpdateBankAccountBanner = useFeature(
    AppFeature.SHOW_UPDATE_BANK_ACCOUNT_BANNER,
  )?.on;

  const [isEditing, setIsEditing] = useToggle(false);
  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal(false);

  const showComparisonView = useMemo(
    () => haveSubmittedChangeRequestItem(changeRequest),
    [changeRequest],
  );

  const [errorDetails, setErrorDetails] = useState<
    | {
        errorMessages: string[];
        errorCount: number;
      }
    | undefined
  >();

  const member = contract?.member;
  const contractCurrency = contract?.currency;
  const paymentAccountRequirements = contract?.paymentAccountRequirements;
  const bankAccount = member?.bankAccounts?.[0];
  const currentPaymentAccountRequirementType =
    bankAccount?.paymentAccountRequirementType;

  const bankDetailObject = React.useMemo(() => {
    if (bankAccount?.accountDetails) {
      return bankAccount?.accountDetails.reduce(
        (acc, accountDetail: Maybe<BankAccountDetail>) => {
          acc[accountDetail?.key ?? ''] = accountDetail?.value ?? '';
          return acc;
        },
        {} as Record<string, string>,
      );
    }
    return {};
  }, [bankAccount]);

  const paymentAccountRequirementsMap = useMemo(
    () =>
      new Map<string, PaymentAccountRequirement>(
        paymentAccountRequirements?.map((req) => [
          req?.paymentAccountRequirementType as string,
          req as PaymentAccountRequirement,
        ]),
      ),
    [paymentAccountRequirements],
  );

  const bankAccountType = getBankAccountType(
    paymentAccountRequirementsMap.size,
    shouldForceToV1,
    isEditing,
    bankAccount,
  );

  const { loading, updateCompanyBankDetails } = useUpdateCompanyBankDetails(
    () => setIsEditing(false),
    contract?.id,
    member?.id,
    (e: ApolloError) => setChangeErrorDetails(e, setErrorDetails),
    true,
    false,
  );

  const {
    sendChangeRequest,
    loading: sendRequestLoading,
  } = useSendChangeRequest(
    MemberChangeCategory.BANK_DETAILS,
    () => {
      if (updateData) updateData();
      setIsEditing(false);
      handleOpenDialog();
    },
    (e: ApolloError) => setChangeErrorDetails(e, setErrorDetails),
    true,
  );

  const showAccountDetailUpdatePill = useMemo(() => {
    if (shouldForceToV1) return false;
    return (
      shouldShowUpdateBankAccountBanner ||
      (!!paymentAccountRequirementsMap.size &&
        bankAccount?.version !== 2 &&
        !haveSubmittedChangeRequestItem(changeRequest))
    );
  }, [
    paymentAccountRequirementsMap,
    changeRequest?.__typename,
    shouldShowUpdateBankAccountBanner,
    shouldForceToV1,
  ]);

  const methods = useForm<BankDetailsFormParams>({
    mode: 'onChange',
  });

  const {
    control,
    reset,
    formState: { isValid, isDirty },
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const submitForm = (data: BankDetailsFormParams) => {
    const modifyBankStatements = data.bankStatements?.map(
      (bankStatement) => bankStatement.statement,
    );

    const modifiedData = {
      ...data,
      bankStatements: modifyBankStatements,
    };

    setErrorDetails(undefined);
    const { existingDocIds, newDocs } = getBankStatementsForUpload(
      modifiedData.bankStatements,
    );

    const payload = {
      ...modifiedData,
      bankStatements: newDocs,
      bankStatementDocIds: existingDocIds,
    };

    if (
      bankAccountType === BankAccountType.DYNAMIC &&
      paymentAccountRequirementsMap?.size
    ) {
      let omitIndexFromBankData;
      if (modifiedData?.bankData) {
        omitIndexFromBankData = modifiedData?.bankData
          .map((obj) => omit(obj, ['index']))
          .filter((obj) => !isNil(obj.value));
      }
      const values = {
        ...modifiedData,
        bankData: omitIndexFromBankData,
      };
      const selectedAccountRequirement = paymentAccountRequirementsMap.get(
        data?.paymentAccountRequirementType ?? '',
      );
      if (requireApproval) {
        sendChangeRequest({
          bankAccount: {
            dynamicDetail: {
              accountType: selectedAccountRequirement?.accountType,
              sourceCurrency: selectedAccountRequirement?.sourceCurrency,
              targetCurrency: selectedAccountRequirement?.targetCurrency,
              transferType: selectedAccountRequirement?.transferType,
              paymentAccountRequirementType:
                selectedAccountRequirement?.paymentAccountRequirementType,
              fieldValues: values?.bankData,
            },
          },
          documents: newDocs,
          documentIds: existingDocIds,
        });
      } else {
        const updatedValues = {
          ...payload,
          bankData: omitIndexFromBankData,
          paymentAccountRequirementType:
            selectedAccountRequirement?.paymentAccountRequirementType ??
            currentPaymentAccountRequirementType ??
            '',
        };
        updateCompanyBankDetails(true, updatedValues, {
          accountType: selectedAccountRequirement?.accountType,
          sourceCurrency: selectedAccountRequirement?.sourceCurrency,
          targetCurrency: selectedAccountRequirement?.targetCurrency,
          transferType: selectedAccountRequirement?.transferType,
          paymentAccountRequirementType: values.paymentAccountRequirementType,
        });
      }
    } else if (requireApproval) {
      sendChangeRequest(
        transformToChangeRequests(
          member,
          modifiedData,
          MemberChangeCategory.BANK_DETAILS,
        ),
      );
    } else {
      updateCompanyBankDetails(false, payload, {});
    }
  };

  const paymentAccountRequirementType = useWatch({
    control,
    name: 'paymentAccountRequirementType',
  });
  useDeepCompareEffect(() => {
    if (bankAccountType === BankAccountType.DYNAMIC) {
      const bankStatements =
        (bankAccount?.bankStatements as File[]) ?? undefined;

      const modifyBankStatements = bankStatements?.map((statement) => ({
        statement,
      }));

      reset({
        paymentAccountRequirementType,
        bankStatements: modifyBankStatements,
        bankData: Array.from(
          getDynamicFields(
            paymentAccountRequirementsMap.get(
              paymentAccountRequirementType ?? '',
            )?.requirementFields,
          )?.values() ?? [],
        ).map((val: Maybe<BankDetailRequirementField>, index: number) => ({
          key: val?.key,
          value: val?.key ? bankDetailObject[val?.key] : null,
          index,
        })),
      });
    } else if (
      member?.__typename === 'Member' &&
      bankAccountType === BankAccountType.STATIC &&
      bankAccount
    ) {
      const bankStatements =
        (bankAccount.bankStatements as File[]) ?? undefined;

      const modifyBankStatements = bankStatements?.map((statement) => ({
        statement,
      }));
      reset({
        accountName: bankAccount.accountName ?? '',
        accountNumber: bankAccount.accountNumber ?? '',
        bankName: bankAccount.bankName ?? '',
        branchName: bankAccount.branchName ?? '',
        swiftCode: bankAccount.swiftCode ?? '',
        localBankCode: bankAccount.localBankCode ?? '',
        bankStatements: modifyBankStatements,
      });
    }
  }, [
    member,
    bankAccount,
    paymentAccountRequirementsMap,
    paymentAccountRequirementType,
    bankAccountType,
  ]);

  useEffect(() => {
    if (
      currentPaymentAccountRequirementType &&
      paymentAccountRequirementsMap?.has(currentPaymentAccountRequirementType)
    ) {
      setValue(
        'paymentAccountRequirementType',
        currentPaymentAccountRequirementType,
      );
    } else if (paymentAccountRequirementsMap?.size === 1) {
      setValue(
        'paymentAccountRequirementType',
        Array.from(paymentAccountRequirementsMap.keys())[0],
      );
    }
  }, [
    paymentAccountRequirementsMap,
    currentPaymentAccountRequirementType,
    isEditing,
  ]);

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.BANK_DETAILS,
        value: isDirty,
      });
  }, [isDirty]);

  const showDynamicDetailsForm = useMemo(() => {
    const bankAccountVersion = bankAccount?.version;
    if (isEditing) return bankAccountType === BankAccountType.DYNAMIC;
    return (
      bankAccountVersion === 2 &&
      paymentAccountRequirementsMap.has(
        currentPaymentAccountRequirementType ?? '',
      )
    );
  }, [bankAccount, bankAccountType, isEditing]);

  const swiftFeeField = useGetSwiftFeeField(
    bankAccountType,
    paymentAccountRequirementsMap,
    paymentAccountRequirementType,
  );

  return (
    <>
      <Accordion
        name={t('onboarding-phase.bank-details.header', 'Bank Details')}
        description={
          contractType === ContractType.CONTRACTOR
            ? t(
                'onboarding-phase.bank-details.contractor-description',
                'Bank details of the contractor to initiate payroll',
              )
            : t(
                'onboarding-phase.bank-details.description',
                'Category description to better explain what this section is about.',
              )
        }
        icon="bank"
        renderAction={getCorrespondingPillTag(changeRequest)}
        edit={editable && !haveSubmittedChangeRequestItem(changeRequest)}
        onEditClick={setIsEditing}
        warning={uncommittedChangesCallback && isDirty}
        nestedStyles={
          isEditing && variant === 'personal-details-view'
            ? tw`ml-64`
            : undefined
        }
        nameHelper={
          showAccountDetailUpdatePill ? (
            <PillTag
              label={t(
                'onboarding-phase.bank-details.update-header-pill',
                'Update bank details',
              )}
              variant="PREPROCESSING"
              tw="border border-grey03 text-primary font-semibold"
            />
          ) : undefined
        }
      >
        {changeRequest?.message &&
          changeRequest.status === MemberChangeRequestStatus.REJECTED && (
            <Callout tw="mb-24" variant={CalloutVariant.ERROR}>
              {changeRequest.message}
            </Callout>
          )}
        {showComparisonView && (
          <ComparisonDetail
            member={member}
            changeRequest={changeRequest}
            lastSubmitOn={lastSubmitOn}
            paymentAccountRequirementsMap={paymentAccountRequirementsMap}
            updateData={updateData}
          />
        )}
        {!showComparisonView && (
          <DetailForm
            onSubmit={handleSubmit(submitForm)}
            css={
              isEditing &&
              variant === 'personal-details-view' &&
              tw`border border-[#E5EBF3] p-24 rounded-[8px]`
            }
          >
            {isEditing && !isUndefined(errorDetails) && (
              <ErrorDetails
                errorCount={errorDetails?.errorCount}
                errorMessages={errorDetails?.errorMessages}
              />
            )}
            <FormProvider {...methods}>
              <DetailGrid
                css={[
                  isEditing && variant === 'personal-details-view'
                    ? tw`grid-cols-2 gap-16`
                    : tw`grid-cols-4`,
                ]}
              >
                {showDynamicDetailsForm ? (
                  <DynamicBankAccountDetails
                    contractType={contractType}
                    paymentAccountRequirementsMap={
                      paymentAccountRequirementsMap
                    }
                    currentPaymentAccountRequirementType={
                      currentPaymentAccountRequirementType
                    }
                    isEditing={isEditing}
                    bankDetailObject={bankDetailObject}
                    selectedAccountRequirementType={
                      paymentAccountRequirementType
                    }
                    contractCurrency={contractCurrency}
                    variant={variant}
                  />
                ) : (
                  <BankAccountDetailsForm
                    isEditing={isEditing}
                    member={member}
                    country={country}
                    variant={variant}
                    contractType={contractType}
                  />
                )}
                {!isUndefined(contractType) &&
                  contractType !== ContractType.HR_MEMBER && (
                    <BankAccountStatements
                      variant={variant}
                      isEditing={isEditing}
                      country={country}
                      member={member}
                      changeRequest={changeRequest}
                      haveSubmittedChangeRequestItem={
                        haveSubmittedChangeRequestItem
                      }
                      contractType={contractType}
                    />
                  )}
              </DetailGrid>

              {isEditing &&
                contractType === ContractType.FREELANCER &&
                !!swiftFeeField &&
                swiftCodesWithAdditionalCharge.includes(
                  watch(
                    swiftFeeField as FieldPath<BankDetailsFormParams>,
                  ) as string,
                ) && <SwiftFeeInfo />}

              {isEditing && (
                <SectionActionBar
                  css={
                    variant === 'personal-details-view' && tw`justify-between`
                  }
                  disabled={!isValid}
                  submitLoading={loading || sendRequestLoading}
                  onCancelClick={() => {
                    setIsEditing(false);
                    reset();
                  }}
                  requireApproval={requireApproval}
                />
              )}
            </FormProvider>
          </DetailForm>
        )}
      </Accordion>
      <RequestMadeDialog open={showDialog} onClose={handleCloseDialog} />
    </>
  );
};

export default BankDetailsSectionHR;
