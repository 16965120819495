import { format } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const timezoneFormat = (date: Date, formatString: string): string =>
  format(
    new Date(
      new Date(date).valueOf() + new Date(date).getTimezoneOffset() * 60000,
    ),
    formatString,
  );

export const currentDateTimeInUTC = () => {
  const date = new Date();
  const tzOffset = date.getTimezoneOffset();
  return new Date(date.getTime() + tzOffset * 60 * 1000);
};

export const percentageDifference = (a: number, b: number): number =>
  Math.round(((a - b) / b) * 100);
