import React from 'react';

import { TwStyle } from 'twin.macro';

export type ToolTipVariant = 'left' | 'top' | 'bottom' | 'right';
export interface ToolTipProps {
  delay?: number;
  variant?: ToolTipVariant;
  content?: React.ReactNode;
  border?: boolean;
  showTooltip?: boolean;
  controlled?: boolean;
  styles?: TwStyle;
  fadeDelay?: number;
  v2?: boolean;
  header?: string | React.ReactNode;
}

export type Maybe<T> = T | null;

export interface UploadedFileFormat {
  name?: Maybe<string>;
  url?: Maybe<string>;
  contentType?: Maybe<string>;
  size?: Maybe<number>;
  blob?: Maybe<string>;
  link?: Maybe<string>;
}

export interface UploadedFileFormatExpense {
  name?: Maybe<string>;
  link?: Maybe<string>;
  contentType?: Maybe<string>;
  size?: string | number;
  blob?: Maybe<string>;
}

export type File = {
  id?: string | number | null;
  name?: string | null;
  extension?: string | null;
  contentType?: string | null;
};

export type DocumentReadable = File & {
  __typename?: 'DocumentReadable';
  id?: string | number | null;
  name?: string | null;
  extension?: string | null;
  contentType?: string | null;
  link?: string | null;
  blob?: string | null;
  htmlPreview?: string | null;
};

export type Document = {
  __typename?: 'Document';
  contentType?: Maybe<string>;
  downloadUrl?: Maybe<string>;
  extension?: Maybe<string>;
  id?: Maybe<string>;
  name?: Maybe<string>;
  size?: Maybe<number>;
  uploadUrl?: Maybe<string>;
  versionFiles?: Maybe<Array<Document>>;
  versionId?: Maybe<string>;
  versionedOn?: Maybe<Date>;
  viewUrl?: Maybe<string>;
};

export type PillVariant =
  | 'PRIMARY'
  | 'SECONDARY'
  | 'SUCCESS'
  | 'PROCESSING'
  | 'PREPROCESSING'
  | 'FAIL'
  | 'WARNING'
  | 'NEUTRAL';

export enum CalloutVariant {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  INFO_V2 = 'INFO_V2',
  INFO_V3 = 'INFO_V3',
  // @deprecated (use NEGATIVE instead)
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  // @deprecated (use MUTED instead)
  SECONDARY = 'SECONDARY',
  MUTED = 'MUTED',
  NEGATIVE = 'NEGATIVE',
}

export * from '../button/types';
export * from '../combo-box/types';
export * from '../dropdown-button/types';
export * from '../dropdown-filter/types';
export * from '../loader/types';
export * from '../table/types';
export * from '../vertical-progress-node/types';
export * from './utils';

export type CustomModalProps = {
  handleCancel: () => void;
  onClose: () => void;
  handleConfirm: () => void;
  loading: boolean;
};

export type HighlightType = {
  date: Date | string | number;
  hover?: ToolTipProps;
  disabled?: boolean;
  type?: 'bold' | 'strike-through';
};

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type { AvatarSize } from '../avatar';
