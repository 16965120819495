import React, { useContext, useMemo } from 'react';

import tw, { TwStyle, theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';
import ToolTip from '../tooltip';

interface WorkFlowProgressNodeProps {
  done?: boolean;
  current?: boolean;
  title?: string;
  description?: string;
  toolTipTitle?: string;
  toolTipDescription?: string;
  first?: boolean;
  last?: boolean;
  blocked?: boolean;
  dark?: boolean;
  titleStyles?: TwStyle[];
}

const WorkflowProgressNode: React.FC<WorkFlowProgressNodeProps> = ({
  first = false,
  last = false,
  done = false,
  current = false,
  blocked = false,
  title,
  description,
  toolTipTitle,
  toolTipDescription,
  dark = false,
  titleStyles,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  const toolTipContent = useMemo(
    () =>
      toolTipTitle || toolTipDescription ? (
        <div tw="max-w-[455px] leading-relaxed text-white">
          {toolTipTitle && <p tw="font-semibold">{toolTipTitle}</p>}
          {toolTipDescription && <p tw="text-ps">{toolTipDescription}</p>}
        </div>
      ) : undefined,
    [toolTipTitle, toolTipDescription],
  );

  const getIcon = () => {
    if (blocked) {
      return (
        <Icon
          tw="m-auto"
          name="warning-circle-red"
          fill="transparent"
          stroke={theme`colors.icon-inverted-primary`}
        />
      );
    }
    if (current) {
      return (
        <Icon
          tw="m-auto"
          name="circle"
          fill="transparent"
          stroke={theme`colors.icon-inverted-primary`}
        />
      );
    }

    if (done) {
      return (
        <Icon
          css={
            isNewThemeApplied
              ? tw`h-[20px] w-[20px] m-auto`
              : tw`h-[9px] w-[13px] m-auto`
          }
          name="check"
          fill={theme`colors.icon-inverted-primary`}
        />
      );
    }

    return '';
  };

  return (
    <div
      css={[
        tw`relative flex flex-row items-center text-text-inverted-tertiary`,
        !isNewThemeApplied && tw`text-grey02`,
        current && tw`text-text-inverted-primary`,
        dark && current && tw`text-text-primary`,
        !first && tw`flex-grow`,
        dark && tw`font-normal`,
      ]}
      data-testid={`progress-node${current ? '-active' : ''}`}
    >
      {!first && (
        <div
          css={[
            tw`top-[11px] left-2 w-full h-tiny bg-background-secondary`,
            (done || current) &&
              (isNewThemeApplied
                ? tw`bg-background-brand`
                : tw`bg-background-action`),
          ]}
        />
      )}
      <ToolTip content={toolTipContent}>
        <div
          css={[
            tw`w-extra-large h-extra-large`,
            tw`right-none bg-background-secondary rounded-full shrink-0`,
            tw`flex items-center`,
            (done || current) &&
              (isNewThemeApplied
                ? tw`bg-background-brand`
                : tw`bg-background-action`),
            blocked && tw`bg-background-negative`,
            !isNewThemeApplied &&
              (current ? tw`w-[36px] h-[36px]` : tw`w-[26px] h-[26px]`),
          ]}
        >
          {getIcon()}
        </div>
      </ToolTip>
      {title && (
        <span
          data-testid="progress-node-title"
          css={[
            tw`w-max absolute top-[42px] right-0 transform[translateX(calc(50% - 13px))] text-ps`,
            first &&
              (current
                ? tw`transform[translateX(calc(100% - 36px))]`
                : tw`transform[translateX(calc(100% - 26px))]`),
            last && tw`transform`,
            current && tw`font-semibold`,
            dark && (done || !current) && tw`text-text-secondary`,
            !isNewThemeApplied && dark && (done || !current) && tw`text-grey02`,
            titleStyles,
          ]}
        >
          {title}
        </span>
      )}
      {description && (!done || current) && (
        <span
          data-testid="progress-node-description"
          css={[
            tw`w-max absolute top-[72px] right-0 transform[translateX(calc(50% - 13px))]`,
            first &&
              (current
                ? tw`transform[translateX(calc(100% - 36px))]`
                : tw`transform[translateX(calc(100% - 26px))]`),
            last && tw`transform`,
            tw`text-ps`,
          ]}
        >
          {description}
        </span>
      )}
    </div>
  );
};

export default WorkflowProgressNode;
