/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@multiplier/common';

import {
  Contract,
  useGetContractAgreementLazyQuery,
  useRequestContractChangesMutation,
} from '__generated__/graphql';

import { useModal } from '../../../../app/hooks';
import {
  errorNotification,
  successNotification,
} from '../../../../app/services/notification-services';
import ContractModal, { AorDocumentType } from '../../contract-modal';
import { ContractPageType } from '../view-send-contract-action';

const ViewDraftAction: React.FC<{
  contractId: Contract['id'];
  compliance: Contract['compliance'];
}> = ({ contractId, compliance }) => {
  const { t } = useTranslation('contract-onboarding.member');
  const [
    showDraftContract,
    closeDraftContractModal,
    openDraftContractModal,
  ] = useModal();

  const [requestChanges] = useRequestContractChangesMutation({
    onError: () => {
      errorNotification(
        '',
        t(
          'onboarding-phase.request-changes.failed',
          'Failed to Requested for Changes',
        ),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t(
          'onboarding-phase.request-changes.success',
          'Successfully Requested for Changes',
        ),
        false,
      );
    },
  });
  const [
    getContractAgreement,
    { data: contractAgreementResponse, loading: loadingContractAgreement },
  ] = useGetContractAgreementLazyQuery({ fetchPolicy: 'network-only' });

  const handleContractAgreementClick = () => {
    if (contractId) {
      getContractAgreement({
        variables: { id: contractId },
      });
      openDraftContractModal();
    }
  };

  const handleRequestChanges = async () => {
    if (contractId) {
      await requestChanges({
        variables: {
          id: contractId,
        },
      });
    }
  };

  if (!compliance?.contractAgreement?.id) return null;

  return (
    <>
      <Button
        size="medium"
        variant="outline"
        onClick={handleContractAgreementClick}
      >
        <div tw="flex flex-row items-center gap-x-12">
          <Icon tw="w-[17.5px] h-[21px]" name="file-empty" />
          <span>
            {t(
              'onboarding-phase.optional-action.view-draft',
              'View Draft Contract',
            )}
          </span>
        </div>
      </Button>
      <ContractModal
        open={showDraftContract}
        onClose={closeDraftContractModal}
        handleCtaClick={handleRequestChanges}
        title={t('onboarding-phase.contract-viewer-header', 'Draft Contract')}
        header={
          <div tw="mx-64 rounded-8 flex flex-row items-start bg-primary bg-opacity-5 py-16 px-24 gap-x-24">
            <Icon tw="flex-shrink-0" name="error" />
            <span tw="text-ps text-background font-medium">
              {t(
                'onboarding-phase.contract-viewer.disclaimer',
                'This contract will be filled with information collected from your employee. You may request for changes until then. This will not be sent to the employee until you review and approve.',
              )}
            </span>
          </div>
        }
        contractType={null}
        orderForm={null}
        loading={loadingContractAgreement}
        contractAgreement={
          contractAgreementResponse?.contract?.compliance?.contractAgreement
        }
        esopAgreement={
          contractAgreementResponse?.contract?.compensation?.grant?.[0]
            ?.document
        }
        contractModalPageType={ContractPageType.MAIN_PAGE}
        aorDocumentType={AorDocumentType.AGREEMENT}
      />
    </>
  );
};

export default ViewDraftAction;
