/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import tw, { styled, theme } from 'twin.macro';

const CalloutContainer = styled.div([
  tw`rounded-8 p-16 flex flex-row items-center mt-24`,
  tw`border-t-amber400 bg-amber100`,
]);

const SignMsaCallout: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <CalloutContainer>
      <div tw="mr-12">
        <Icon name="info" fill={theme`colors.red700`} tw="w-24 h-24" />
      </div>

      <p tw="text-red700 text-ps font-normal">
        {t(
          'onboarding-phase.optional-action.review-contract.sign-msa-callout',
          'Please sign the MSA to view and send contract to the employee',
        )}
      </p>
    </CalloutContainer>
  );
};

export default SignMsaCallout;
