/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, IconBadge, ThemeContext } from '@multiplier/common';
import { companyHooks } from '@multiplier/contract-onboarding';
import tw from 'twin.macro';

import { Contract, Member } from '__generated__/graphql';

const DeleteAction: React.FC<{
  contractId: Contract['id'];
  memberId: Member['id'];
  memberName: Member['firstName'];
  workflowUpdating: boolean;
  contractBlocked?: boolean;
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
}> = ({
  contractId,
  memberId,
  memberName,
  workflowUpdating,
  contractBlocked,
  toggleWorkflowUpdating,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const {
    handleDeleteClick,
    deleteInProgress: loading,
    Dialog: DeleteDialog,
  } = companyHooks.useDeleteDialog();

  useEffect(() => {
    toggleWorkflowUpdating(loading);
  }, [loading]);

  return (
    <div tw="flex flex-row items-center gap-x-base">
      <IconBadge
        iconContainerStyle={[tw`bg-background-brand-faded bg-opacity-5`]}
        icon="trash"
        color={!isNewThemeApplied ? 'transparent' : ''}
      />
      <div tw="flex flex-col flex-grow">
        <p tw="text-h6 font-semibold text-text-primary">
          {t(
            'onboarding-phase.progress-widget-content.delete-contract.header',
            'Delete contract',
          )}
        </p>
        <p tw="text-text-tertiary text-ps font-normal">
          {t(
            'onboarding-phase.progress-widget-content.danger-zone.description',
            "You can delete the member's contract & remove their profile details",
          )}
        </p>
      </div>
      <Button
        variant="dangerOutline"
        onClick={() =>
          handleDeleteClick({
            contractId,
            memberId,
            memberName: `${memberName}`,
          })
        }
        loading={workflowUpdating || loading}
        disabled={workflowUpdating || contractBlocked || loading}
      >
        {t(
          'onboarding-phase.progress-widget-content.danger-zone.delete',
          'Delete',
        )}
      </Button>
      <DeleteDialog />
    </div>
  );
};

export default DeleteAction;
