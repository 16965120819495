import React from 'react';

import 'twin.macro';

import ToolTip from '../../../tooltip';
import { TableBodyProps } from '../../../types';

const CheckBoxTooltipWrapper: React.FC<
  React.PropsWithChildren<{
    rowId: string | number;
    isDisabled: boolean;
    getDisabledTooltipMessage: TableBodyProps['getDisabledTooltipMessage'];
  }>
> = ({ rowId, isDisabled, getDisabledTooltipMessage, children }) => {
  const tooltipMessage =
    isDisabled && getDisabledTooltipMessage
      ? getDisabledTooltipMessage(rowId)
      : undefined;

  if (tooltipMessage)
    return (
      <ToolTip
        delay={300}
        fadeDelay={300}
        content={tooltipMessage}
        data-testid="tooltip-wrapper"
      >
        {children}
      </ToolTip>
    );
  return <>{children}</>;
};

export default CheckBoxTooltipWrapper;
