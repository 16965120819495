import React, { useContext } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';

import tw, { css } from 'twin.macro';

import Icon from '../../../icon';
import { ThemeContext } from '../../../theme-provider';
import { TableDataObjectType } from '../../../types';
import { useFakePagination } from './hooks';

const MobileTable: React.FC<{
  data: TableDataObjectType[] | [];
  renderItem: (i: TableDataObjectType) => React.ReactElement;
}> = ({ data, renderItem }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { loading, items, hasNextPage, error, loadMore } = useFakePagination(
    data,
  );

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div>
      <ul tw="divide-border-primary divide-y bg-background-white mt-28">
        {items.map((member) => renderItem(member))}
      </ul>
      {(loading || hasNextPage) && (
        <div tw="flex justify-center mt-6" ref={sentryRef}>
          <div
            css={[
              css`
                padding: 10px;
              `,
              tw`bg-background-inverted rounded-full animate-spin`,
              !isNewThemeApplied && tw`bg-primary`,
            ]}
          >
            <Icon name="circular-loader" fill="transparent" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTable;
