import { gql } from '@apollo/client';

export default gql`
  fragment workShift on WorkShift {
    startDay
    endDay
    workingHours {
      startTime
      endTime
    }
  }
`;
