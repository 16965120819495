/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { timezoneRemove } from '@multiplier/format';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import tw from 'twin.macro';

import {
  Contract,
  ContractBenefitDependent,
  CountryCode,
} from '__generated__/graphql';

const DependantTitle = tw.p`text-ps font-semibold text-grey01 mb-8`;
const DependantContainer = tw.div`mt-24`;
const DependantValueContainer = tw.div`grid [grid-template-columns: 160px 1fr]`;
const DependantKey = tw.p`text-ps font-semibold text-background mb-4`;
const DependantValue = tw.p`text-ps font-normal text-background mb-4`;

const InsuranceDependent: React.FC<{
  dependant?: ContractBenefitDependent | null;
  dependantIndex: number;
  country?: Contract['country'];
}> = ({ dependant, dependantIndex, country }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const isUSA = country === CountryCode.USA;

  if (isEmpty(dependant)) return null;

  return (
    <div data-testid="dependant-details">
      <DependantContainer>
        <DependantTitle>
          {t(
            'onboarding-phase.insurance.dependant-details.dependant',
            'Dependent {{dependantIndex}}:',
            { dependantIndex: dependantIndex + 1 },
          )}
        </DependantTitle>
        <DependantValueContainer>
          <DependantKey>
            {t(
              'onboarding-phase.insurance.dependant-details.first-name',
              'First Name',
            )}
          </DependantKey>
          <DependantValue>{dependant?.firstName}</DependantValue>
          <DependantKey>
            {t(
              'onboarding-phase.insurance.dependant-details.last-name',
              'Last Name',
            )}
          </DependantKey>
          <DependantValue>{dependant?.lastName}</DependantValue>
          <DependantKey>
            {t(
              'onboarding-phase.insurance.dependant-details.date-of-birth',
              'Date of Birth',
            )}
          </DependantKey>
          <DependantValue>
            {format(timezoneRemove(dependant?.dateOfBirth), 'dd MMM yyyy')}
          </DependantValue>
          <DependantKey>
            {t('onboarding-phase.insurance.dependant-details.gender', 'Gender')}
          </DependantKey>
          <DependantValue>{dependant?.gender}</DependantValue>
          <DependantKey>
            {t(
              'onboarding-phase.insurance.dependant-details.relationship',
              'Relationship to you',
            )}
          </DependantKey>
          <DependantValue>{dependant?.relationshipToMember}</DependantValue>
          {isUSA ? (
            <DependantKey>
              {t(
                'onboarding-phase.insurance.dependant-details.nationalIdNumber-usa',
                'SSN number',
              )}
            </DependantKey>
          ) : (
            <DependantKey>
              {t(
                'onboarding-phase.insurance.dependant-details.nationalIdNumber',
                'National ID number',
              )}
            </DependantKey>
          )}
          <DependantValue>{dependant?.legalData?.[0]?.value}</DependantValue>
        </DependantValueContainer>
      </DependantContainer>
    </div>
  );
};

export default InsuranceDependent;
