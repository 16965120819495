import { getAuthHeaders } from '@multiplier/user';

import { Experience } from 'app/models/module-config';
import importMetaEnv from 'import-meta-env';

import { errorNotification } from '../../app/services/notification-services';
import client from '../../client';
import i18n from '../../i18n';
import { GENERATE_INTERCOM_HASH } from '../queries';

export const configureIntercomBoot = (
  currentExperience: Experience,
  id: number,
  email: string | null | undefined,
  userHash: string,
  displayName: string,
  company: {
    id: string;
    name: string;
  },
  signUpDate?: string,
): void => {
  if (window.Intercom) {
    window.Intercom('shutdown');
    const app_id =
      currentExperience === Experience.MEMBER
        ? importMetaEnv.VITE_INTERCOM_MEMBER_APP_ID
        : importMetaEnv.VITE_INTERCOM_COMPANY_APP_ID;

    if (window.intercomSettings?.app_id) {
      window.intercomSettings.app_id = app_id;
    }
    window.Intercom('boot', {
      app_id,
      email: email ?? '',
      name: displayName,
      user_id: String(id),
      user_hash: userHash,
      created_at: Date.now(),
      company: {
        id: company.id,
        name: company.name,
      },
    });

    if (signUpDate) {
      updateIntercomUserData({ 'Sign Up Date': signUpDate });
    }
  }
};

export const shutdownIntercom = (): void => {
  if (window.Intercom) {
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: importMetaEnv.VITE_INTERCOM_APP_ID,
    });
  }
};

export const updateIntercomUserData = (userData: {
  [key: string]: string | { [key: string]: string } | undefined | null;
}): void => {
  if (window.Intercom) {
    window.Intercom('update', userData);
  }
};

export const trackFreelancerOnboardingStarted = (): void => {
  if (window.Intercom) {
    window.Intercom('trackEvent', 'pay-a-freelancer');
  }
};

export const intercomShowNewMessage = (message: string): void => {
  if (window.Intercom) {
    window.Intercom('showNewMessage', message);
  }
};

export const getUserIntercomHash = async (
  userId: number,
  jwtToken: string | null | undefined,
) => {
  const intercomHashResponse = await client.mutate({
    mutation: GENERATE_INTERCOM_HASH,
    variables: {
      input: {
        userId,
      },
      headers: {
        ...getAuthHeaders(jwtToken as string),
      },
    },
  });
  if (intercomHashResponse?.errors) {
    errorNotification(
      i18n.t(
        'login:intercom.get-user-hash.failed.title',
        'Failed to Get Intercom Hash',
      ),
      i18n.t(
        'login:intercom.get-user-hash.failed.description',
        'Please try again.',
      ),
      false,
    );
    return '';
  }
  return intercomHashResponse?.data?.intercomHash?.hash;
};
