import React, { useContext } from 'react';

import tw, { styled } from 'twin.macro';

import DropdownMenu from '../../../../../dropdown-menu';
import Icon from '../../../../../icon';
import { ThemeContext } from '../../../../../theme-provider';

const DeleteValueButton = ({ onClick }: { onClick: () => void }) => (
  <button type="button" aria-label="remove" onClick={onClick}>
    <Icon tw="h-[10px]" name="cross" />
  </button>
);

interface ShortenMultiValuesProps {
  values: string[];
  onRemove: (value: string) => void;
}
const ShortenMultiValues: React.FC<ShortenMultiValuesProps> = ({
  values,
  onRemove,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  if (!values?.length) return null;

  const [firstItem, ...remainItems] = values;
  const numOfRemainItems = remainItems?.length;

  return (
    <div tw="flex flex-row flex-wrap gap-6">
      <SelectedItemContent
        isNewTheme={isNewThemeApplied}
        data-testid={`value-${firstItem}`}
      >
        <SelectedItemText
          isNewTheme={isNewThemeApplied}
          tw="block max-w-[150px] truncate "
        >
          {firstItem}
        </SelectedItemText>
        <DeleteValueButton onClick={() => onRemove(firstItem)} />
      </SelectedItemContent>
      {numOfRemainItems > 0 && (
        <DropdownMenu
          button={
            <SelectedItemContent
              isNewTheme={isNewThemeApplied}
              data-testid="remain-items"
            >
              <SelectedItemText isNewTheme={isNewThemeApplied}>
                <Icon tw="h-[10px]" name="plus" />
                {`${numOfRemainItems} more`}
              </SelectedItemText>
            </SelectedItemContent>
          }
        >
          <div tw="flex flex-col px-small py-extra-small max-w-[320px] max-h-[200px] overflow-auto">
            {remainItems.map((item) => (
              <DropdownValueContainer key={item}>
                <div tw="truncate hover:text-wrap">{item}</div>
                <DeleteValueButton onClick={() => onRemove(item)} />
              </DropdownValueContainer>
            ))}
          </div>
        </DropdownMenu>
      )}
    </div>
  );
};

export default ShortenMultiValues;

const SelectedItemContent = styled.div(
  ({ isNewTheme }: { isNewTheme: boolean }) => [
    tw`rounded-tiny bg-background-secondary px-small py-6 flex flex-row items-center gap-x-6`,
    !isNewTheme && tw`bg-primaryBlue100`,
  ],
);
const SelectedItemText = styled.div(
  ({ isNewTheme }: { isNewTheme: boolean }) => [
    tw`text-ps text-text-primary font-semibold flex gap-x-6 items-center`,
    !isNewTheme && tw`text-foreground`,
  ],
);
const DropdownValueContainer = tw.div`p-small flex justify-between items-center gap-x-6`;
