/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { ComplianceParamPeriodUnit, TimeOffUnit } from '__generated__/graphql';

import { periodLabels } from '../index';

const EditableClause: React.FC<{
  onClick: () => void;
  disabled?: boolean;
  editable?: boolean;
  paramValue: number;
  paramUnit: ComplianceParamPeriodUnit | TimeOffUnit | undefined | null;
}> = ({
  onClick,
  disabled = false,
  editable = false,
  paramValue,
  paramUnit,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div tw="flex flex-row gap-x-small mx-tiny inline-flex">
      <button
        type="button"
        css={[
          tw`focus:(outline-none ring-transparent)`,
          !isNewThemeApplied && tw`focus-visible:(outline-primary)`,
        ]}
        onClick={onClick}
        disabled={disabled || !editable}
      >
        <div
          css={[
            tw`flex flex-row gap-x-extra-small items-center bg-background-primary rounded-tiny py-tiny px-small`,
            !isNewThemeApplied && tw`bg-primary bg-opacity-10`,
          ]}
        >
          <span css={disabled ? tw`text-text-tertiary` : tw`text-text-primary`}>
            {`${paramValue} ${
              paramUnit && periodLabels[paramUnit](paramValue ?? 2)
            }`}
          </span>
          {editable && (
            <Icon
              css={[
                tw`h-base w-base`,
                !isNewThemeApplied && tw`w-small h-small`,
              ]}
              name="pencil"
              fill={
                isNewThemeApplied
                  ? disabled
                    ? theme`colors.icon-tertiary`
                    : undefined
                  : 'transparent'
              }
              stroke={
                isNewThemeApplied
                  ? undefined
                  : disabled
                  ? theme`colors.grey02`
                  : theme`colors.primary`
              }
            />
          )}
        </div>
      </button>
    </div>
  );
};

export default EditableClause;
