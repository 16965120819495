/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  CoverageGroupDescription,
  RecommendInsuranceCoverageGroup,
  getAvailableBenefitsFromBenefitPartner,
  getBenefitPartnerCoverageIndexer,
  isFamilyCoverage,
  isOnlyEmployeeCoverage,
} from '@multiplier/insurance';
import { isNaN, isNil } from 'lodash';
import tw from 'twin.macro';
import * as yup from 'yup';

import IncludeDependantsBenefit from 'contract-onboarding/company/components/include-dependants-benefit';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import i18n from 'i18n';
import InsuranceDetails from 'insurance/components/details';
import ERSplit from 'insurance/components/er-split';
import InsuranceMandatoryCallout from 'insurance/components/insurance-mandatory-callout';
import InsuranceNotes from 'insurance/components/insurance-notes';
import InsuranceTypePartnerName from 'insurance/components/insurance-type-partner-name';

import {
  Benefit,
  BenefitPartnerCountry,
  BenefitPartnerCountryFilters,
  BenefitPartnerStatus,
  BenefitType,
  ContractBenefit,
  ContractType,
  CountryCode,
  Maybe,
} from '__generated__/graphql';

import {
  getBenefitId,
  getDependentCount,
  getIncludeDependents,
} from '../../../../../services/benefits';
import stepConfig from '../../../step-config';

export interface BenefitFormValues {
  benefitId: string | undefined | null;
  includeDependents: boolean;
  dependentCount?: string | null | undefined;
  employerPayPercentage?: string | null | undefined;
}

const BenefitsViewFormV2: React.FC<
  OnboardingStepProps & {
    id?: string;
    country?: Maybe<CountryCode>;
    benefits?: Maybe<Maybe<ContractBenefit>[]>;
    countryStateCode?: Maybe<string>;
    onLoadData: (params: BenefitPartnerCountryFilters) => void;
    benefitPartnerCountries: BenefitPartnerCountry[];
    loading: boolean;
    onSubmit: (values: BenefitFormValues) => Promise<void>;
    contractType: Maybe<ContractType> | undefined;
    employerPayPercentage?: ContractBenefit['employerPayPercentage'];
    coverageGroups?: RecommendInsuranceCoverageGroup[];
  }
> = ({
  currentStep,
  onboardingSteps,
  id,
  country,
  benefits, // saved benefits from contract
  onLoadData,
  loading,
  onSubmit,
  contractType,
  employerPayPercentage,
  benefitPartnerCountries,
  coverageGroups,
}) => {
  const [coverageGroupId, setCoverageGroupId] = useState<string>('');
  const { t } = useTranslation('insurance');

  const benefitIndexer = getBenefitPartnerCoverageIndexer(
    benefitPartnerCountries,
  );
  const availableBenefits = getAvailableBenefitsFromBenefitPartner(
    benefitPartnerCountries,
  ); // hot-solution for new API response

  const benefitPartnerCountryData = benefitIndexer?.[coverageGroupId as string];

  useEffect(() => {
    if (country && contractType) {
      onLoadData({
        memberType: [contractType],
        countries: [country],
        statuses: [BenefitPartnerStatus.ACTIVE],
      });
    }
  }, [country, contractType]);

  const { minimumErSplitPercentage = 0 } = benefitPartnerCountryData ?? {
    minimumErSplitPercentage: 0,
  };

  const methods = useForm<BenefitFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        benefitId: benefitPartnerCountryData?.isInsuranceMandatory
          ? yup.string().uuid().required()
          : yup.string().uuid().nullable(),
        includeDependents: yup.boolean(),
        dependentCount: yup.number().nullable(),
        employerPayPercentage: yup
          .number()
          .max(
            100,
            t(
              'insurance.er-split.max-validation',
              'Employer pay percentage cannot be more than 100%',
            ),
          )
          .test(
            'minimum-er-percentage',
            t('insurance.er-split.min-validation', {
              defaultValue:
                'Minimum contribution has to be {{minimumPercentage}}%',
              replace: {
                minimumPercentage: minimumErSplitPercentage,
              },
            }),
            (value) => {
              if (
                !isNil(value) &&
                benefitPartnerCountryData?.erSplitApplicable &&
                !isNil(minimumErSplitPercentage) &&
                value < minimumErSplitPercentage
              )
                return false;
              return true;
            },
          )
          .typeError(
            t(
              'insurance.er-split.must-be-number',
              'Employer pay percentage must be a number',
            ),
          )
          .nullable(),
      }),
    ),
    defaultValues: {
      employerPayPercentage: '100',
      dependentCount: '1',
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isValid, errors },
    setValue,
    getValues,
    trigger,
  } = methods;

  const selectedBenefit = watch('benefitId');

  const selectedBenefitDetails = benefitPartnerCountryData?.benefits?.find(
    (benefit) => benefit?.id === selectedBenefit,
  );

  useEffect(() => {
    // setter from contract
    const dependantCount = getDependentCount(benefits);
    if (benefits && benefits.length > 0) {
      const benefitId = getBenefitId(benefits);
      if (benefitId === getValues('benefitId')) return;
      if (benefits?.[0]?.benefit?.provider?.coverageGroup?.id) {
        setCoverageGroupId(benefits?.[0]?.benefit?.provider?.coverageGroup?.id);
      }
      reset({
        ...getValues(),
        benefitId,
        includeDependents: getIncludeDependents(benefits),
        employerPayPercentage: !isNil(employerPayPercentage)
          ? String(employerPayPercentage)
          : null,
      });

      if (dependantCount)
        reset({
          ...getValues(),
          dependentCount: `${dependantCount}`,
        });
    }
  }, [reset, JSON.stringify(benefits)]);

  const showDependents =
    isOnlyEmployeeCoverage(coverageGroups) && Boolean(selectedBenefitDetails); // allow to show dependents in safewing

  useEffect(() => {
    if (selectedBenefitDetails) {
      setCoverageGroupId(
        selectedBenefitDetails?.provider?.coverageGroup?.id ?? '',
      );
    }
    const currentCoverageGroups =
      benefitIndexer?.[
        selectedBenefitDetails?.provider?.coverageGroup?.id as string
      ]?.coverageGroup;
    if (
      isFamilyCoverage(currentCoverageGroups) &&
      selectedBenefitDetails?.dependentMaxCount !== null
    ) {
      // pass the config dependent count to server if select family plan
      setValue(
        'dependentCount',
        `${selectedBenefitDetails?.dependentMaxCount}`,
      );
      setValue('includeDependents', true);
    } else {
      if (isOnlyEmployeeCoverage(coverageGroups)) {
        setValue('dependentCount', '1');
      } else {
        setValue('dependentCount', '0');
      }
      setValue('includeDependents', false);
    }
  }, [selectedBenefitDetails]);

  const computedEmployeePayPercentage = useMemo(() => {
    const employerPayPercentageInput = watch('employerPayPercentage');
    const employerPayPercentageNumber = employerPayPercentageInput
      ? Number(employerPayPercentageInput)
      : 0;
    const employeePercentage = Math.abs(100 - employerPayPercentageNumber);
    return employeePercentage;
  }, [watch('employerPayPercentage')]);

  const showInsurancePartnerAndType =
    benefitPartnerCountryData?.benefitsDomain &&
    benefitPartnerCountryData?.partnerName;

  const shouldShowERSplit = Boolean(
    benefitPartnerCountryData?.erSplitApplicable && selectedBenefitDetails?.id,
  );

  const continueTooltip =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    isNil(selectedBenefit) ? (
      <div tw="text-pxs text-center py-extra-small px-small self-stretch">
        {t(`benefits.${country}-insurance-continue-tooltip`, {
          defaultValue:
            'Select an insurance plan. Providing insurance is mandatory by law for employees in {{country}}.',
          replace: {
            country,
          },
        })}
      </div>
    ) : null;

  const shouldShowMandatoryInsuranceCallout =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    contractType !== ContractType.CONTRACTOR;

  return (
    <StepLayout data-testid="benefits-view">
      <FormProvider {...methods}>
        {shouldShowMandatoryInsuranceCallout && (
          <InsuranceMandatoryCallout country={country} />
        )}
        <FormLayout onSubmit={handleSubmit(onSubmit)}>
          <FormCard tw="p-none mt-large border border-border-primary overflow-hidden border-solid">
            {showInsurancePartnerAndType && (
              <div tw="w-full border-b border-border-primary">
                <InsuranceTypePartnerName
                  insuranceType={benefitPartnerCountryData?.benefitsDomain}
                  insurancePartnerName={benefitPartnerCountryData?.partnerName}
                />
              </div>
            )}
            <div
              css={[
                tw`py-large flex flex-col gap-large bg-background-white pt-none`,
              ]}
            >
              <Controller
                control={control}
                name="benefitId"
                render={() => (
                  <InsuranceDetails
                    availableBenefits={availableBenefits}
                    country={country}
                    variant="tablet"
                    selectable
                    isTableExpand // for styling only
                    selectedValue={selectedBenefit}
                    onChange={(selectedValue) => {
                      setValue('benefitId', selectedValue, {
                        shouldValidate: true,
                      });
                    }}
                    onTypeChange={(selectedValue) => {
                      setCoverageGroupId(selectedValue);
                    }}
                    benefitType={coverageGroupId}
                    typesDropdown={
                      coverageGroups?.map?.((item) => ({
                        value: item?.id as string,
                        title: (
                          <div tw="font-semibold flex items-center gap-tiny flex-wrap">
                            <CoverageGroupDescription
                              coverageGroup={item}
                              t={i18n.t}
                            />
                          </div>
                        ),
                        isRecommended: item?.isRecommended,
                      })) ?? []
                    }
                    benefitsByType={
                      (benefitPartnerCountryData?.benefits as Benefit[]) ?? []
                    }
                  />
                )}
              />
              <div tw="px-large flex flex-col gap-large">
                {showDependents && (
                  <IncludeDependantsBenefit
                    edit={Boolean(selectedBenefitDetails)}
                    amount={selectedBenefitDetails?.cost}
                    currency={selectedBenefitDetails?.currency}
                    costingType={selectedBenefitDetails?.costingType}
                    frequency={selectedBenefitDetails?.frequency}
                    billingDuration={benefitPartnerCountryData?.billingDuration}
                  />
                )}

                <InsuranceNotes
                  refundPolicy={benefitPartnerCountryData?.refundPolicy}
                  platformFee={benefitPartnerCountryData?.platformFee}
                  platformFeeApplicable={
                    benefitPartnerCountryData?.platformFeeApplicable
                  }
                  billingDuration={benefitPartnerCountryData?.billingDuration}
                  billingCurrency={benefitPartnerCountryData?.billingCurrency}
                  contractType={contractType}
                />
              </div>
            </div>
          </FormCard>

          {shouldShowERSplit ? (
            <FormCard tw="border border-border-primary border-solid">
              <Controller
                control={control}
                name="employerPayPercentage"
                render={({ field: { value: currEmployerPayPercentage } }) => {
                  const processedEmployerPayPercentage =
                    currEmployerPayPercentage &&
                    !isNaN(Number(currEmployerPayPercentage))
                      ? Number(currEmployerPayPercentage)
                      : 0;
                  return (
                    <ERSplit
                      cost={selectedBenefitDetails?.cost}
                      billingCurrency={selectedBenefitDetails?.currency}
                      frequency={selectedBenefitDetails?.frequency}
                      onChange={(value: string) => {
                        if (value?.length > 2 && Number(value) > 100) return;
                        setValue(
                          'employerPayPercentage',
                          value === '' ? '0' : value,
                        );
                        trigger('employerPayPercentage');
                      }}
                      employeePayPercentage={computedEmployeePayPercentage}
                      employerPayPercentage={processedEmployerPayPercentage}
                      minimumErSplitPercentage={
                        benefitPartnerCountryData?.minimumErSplitPercentage
                      }
                      type={selectedBenefitDetails?.type as BenefitType} // still use benefit type
                      country={country}
                      error={errors?.employerPayPercentage?.message}
                      platformFee={benefitPartnerCountryData?.platformFee}
                      platformFeeApplicable={
                        benefitPartnerCountryData?.platformFeeApplicable
                      }
                    />
                  );
                }}
              />
            </FormCard>
          ) : null}

          <StepNavigationFooter
            submitLoading={loading}
            disabled={!isValid}
            currentStep={currentStep}
            onboardingSteps={onboardingSteps}
            contractId={id}
            stepConfig={stepConfig}
            continueTooltip={continueTooltip}
          />
        </FormLayout>
      </FormProvider>
    </StepLayout>
  );
};

export default BenefitsViewFormV2;
