import React from 'react';

import { parseDate } from '@multiplier/format';
import { formatISO, isAfter, isPast } from 'date-fns';

import {
  AppSidebarNavItemName,
  SidebarEntryProps,
} from '../../app/components/sidebar';
import Sidebar from '../../common/components/sidebar';
import localStorageService from '../../common/services/local-storage-service';
import { useOnboardingNeedsAttention } from '../../dashboard/hooks/onboarding-needs-attention';

export const shouldShowNotificationCount = ({
  cutoffDate,
  sideNavDates,
  loading,
  numberOfRelevantContracts,
}: {
  cutoffDate: Date | null;
  sideNavDates: { cutoffDate: string; lastClicked: string } | null;
  loading: boolean;
  numberOfRelevantContracts: number | undefined;
}): boolean => {
  if (loading) return false;

  if (numberOfRelevantContracts == null || numberOfRelevantContracts === 0)
    return false;

  if (!sideNavDates) return true;

  const isNewCutOffDate =
    !!cutoffDate &&
    sideNavDates &&
    isAfter(cutoffDate, parseDate(sideNavDates.cutoffDate));

  const lastClickedInPast =
    sideNavDates && isPast(parseDate(sideNavDates.lastClicked));

  return isNewCutOffDate && lastClickedInPast;
};

const sideNavKey = 'team.side-nav';

export const useNotificationCount = (): {
  cutoffDate: Date | null;
  notificationCount: number | undefined;
  loading: boolean;
} => {
  const { pendingOnboardings, loading } = useOnboardingNeedsAttention();

  const cutoffDate = pendingOnboardings?.cutoffDate
    ? parseDate(pendingOnboardings.cutoffDate.toISOString())
    : null;

  const sideNavDates = localStorageService.getItem(sideNavKey);

  const showNotificationCount = shouldShowNotificationCount({
    cutoffDate,
    sideNavDates,
    loading,
    numberOfRelevantContracts: pendingOnboardings?.numberOfContracts,
  });

  const notificationCount = showNotificationCount
    ? pendingOnboardings?.numberOfContracts ?? 0
    : undefined;

  return { cutoffDate, notificationCount, loading };
};

const TeamSidebarEntry: React.FC<SidebarEntryProps> = ({
  id,
  nav,
  handleClick,
}) => {
  const { cutoffDate, notificationCount } = useNotificationCount();

  return (
    <Sidebar.SectionEntry
      route={nav.route}
      id={id}
      icon={nav.icon}
      activeIcon={nav.activeIcon}
      handleClick={() => {
        if (cutoffDate) {
          localStorageService.setItem(sideNavKey, {
            cutoffDate: formatISO(cutoffDate),
            lastClicked: formatISO(new Date()),
          });
        }

        if (handleClick) handleClick();
      }}
      notificationCount={notificationCount}
    >
      <AppSidebarNavItemName name={nav.defaultName} configId={id} />
    </Sidebar.SectionEntry>
  );
};

export default TeamSidebarEntry;
