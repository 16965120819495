class LocalStorageService {
  ls = window.localStorage;

  setItem(key: string, value: any) {
    this.ls.setItem(key, JSON.stringify(value));
    return true;
  }

  getItem(key: string) {
    const value = this.ls.getItem(key) as string;
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  getRawItem(key: string) {
    return this.ls.getItem(key);
  }
}

export default new LocalStorageService();
