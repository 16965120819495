/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["initializeEnterExitUpdatePattern", "isEdge"] }] */
import React from 'react';

import { cumsum, max, min, sum } from 'd3-array';
import { flextree } from 'd3-flextree';
import { stratify, tree } from 'd3-hierarchy';
import { select, selection } from 'd3-selection';
import { linkHorizontal } from 'd3-shape';
import { zoom, zoomIdentity } from 'd3-zoom';
import { identity } from 'lodash';

import {
  Attributes,
  Node,
  NodeData,
  NodeId,
  Point,
  PublicAttributes,
} from '../../types/org-chart';

export const d3 = {
  selection,
  select,
  max,
  min,
  sum,
  cumsum,
  tree,
  stratify,
  zoom,
  zoomIdentity,
  linkHorizontal,
  flextree,
};

export default class D3OrgChart {
  private attrs: Attributes;

  constructor(overrideAttrs: Partial<PublicAttributes>) {
    this.attrs = {
      id: `ID${Math.floor(Math.random() * 1000000)}`,
      firstDraw: true,
      ctx: document.createElement('canvas').getContext('2d'),
      lastTransform: { x: 0, y: 0, k: 1 },
      zoomBehavior: null,
      generateRoot: null,
      nodeId: (d) => d.id,
      parentNodeId: (d) => d.parentId,
      root: null,
      allNodes: [],
      duration: 400,
      createZoom: () => d3.zoom(),
      nodeButtonHeight: () => 36,
      nodeButtonX: () => -106,
      nodeButtonY: () => 0,
      nodeEnter: (d) => d,
      nodeExit: (d) => d,
      nodeUpdate() {
        d3.select<any, Node>(this)
          .select('.node-content')
          .attr('class', (d) => {
            let classes = 'node-content';
            if (d.data._highlighted || d.data._upToTheRootHighlighted) {
              classes += ' node-content--highlighted';
            }
            return classes;
          });
        d3.select<any, Node>(this)
          .select('.node-foreign-object-div-overlay')
          .style('opacity', (d) => (d.data._faded ? 0.8 : 0));
      },
      linkUpdate(d) {
        d3.select<any, Node>(this).attr('stroke-width', (node) =>
          node.data._upToTheRootHighlighted ? 5 : 1,
        );

        if (d.data._upToTheRootHighlighted) {
          d3.select<any, Node>(this).raise();
        }
      },
      /* Horizontal diagonal generation algorithm - https://observablehq.com/@bumbeishvili/curved-edges-compact-horizontal */
      hdiagonal(s, t, m) {
        // Define source and target x,y coordinates
        const { x } = s;
        const { y } = s;
        const ex = t.x;
        const ey = t.y;

        const mx = m && m.x != null ? m.x : x; // This is a changed line
        const my = m && m.y != null ? m.y : y; // This also is a changed line

        // Values in case of top reversed and left reversed diagonals
        const xrvs = ex - x < 0 ? -1 : 1;
        const yrvs = ey - y < 0 ? -1 : 1;

        // Define preferred curve radius
        const rdef = 35;

        // Reduce curve radius, if source-target x space is smaller
        let r = Math.abs(ex - x) / 2 < rdef ? Math.abs(ex - x) / 2 : rdef;

        // Further reduce curve radius, is y space is more small
        r = Math.abs(ey - y) / 2 < r ? Math.abs(ey - y) / 2 : r;

        // Defin width and height of link, excluding radius
        const w = Math.abs(ex - x) / 2 - r;

        // Build and return custom arc command
        return `
                          M ${mx} ${my}
                          L ${mx} ${y}
                          L ${x} ${y}
                          L ${x + w * xrvs} ${y}
                          C ${x + w * xrvs + r * xrvs} ${y} 
                            ${x + w * xrvs + r * xrvs} ${y} 
                            ${x + w * xrvs + r * xrvs} ${y + r * yrvs}
                          L ${x + w * xrvs + r * xrvs} ${ey - r * yrvs} 
                          C ${x + w * xrvs + r * xrvs}  ${ey} 
                            ${x + w * xrvs + r * xrvs}  ${ey} 
                            ${ex - w * xrvs}  ${ey}
                          L ${ex} ${ey}
               `;
      },
      /* Vertical diagonal generation algorithm - https://observablehq.com/@bumbeishvili/curved-edges-compacty-vertical */
      diagonal(s, t, m, offsets = { sy: 0 }) {
        const { x } = s;
        let { y } = s;

        const ex = t.x;
        const ey = t.y;

        const mx = m && m.x != null ? m.x : x; // This is a changed line
        const my = m && m.y != null ? m.y : y; // This also is a changed line

        const xrvs = ex - x < 0 ? -1 : 1;
        const yrvs = ey - y < 0 ? -1 : 1;

        y += offsets.sy + 20;

        const rdef = 35;
        let r = Math.abs(ex - x) / 2 < rdef ? Math.abs(ex - x) / 2 : rdef;

        r = Math.abs(ey - y) / 2 < r ? Math.abs(ey - y) / 2 : r;

        const h = Math.abs(ey - y) / 2 - r;
        const w = Math.abs(ex - x) - r * 2;
        // w=0;
        const path = `
                          M ${mx} ${my}
                          L ${x} ${my}
                          L ${x} ${y}
                          L ${x} ${y + h * yrvs}
                          C  ${x} ${y + h * yrvs + r * yrvs} ${x} ${
          y + h * yrvs + r * yrvs
        } ${x + r * xrvs} ${y + h * yrvs + r * yrvs}
                          L ${x + w * xrvs + r * xrvs} ${
          y + h * yrvs + r * yrvs
        }
                          C  ${ex}  ${y + h * yrvs + r * yrvs} ${ex}  ${
          y + h * yrvs + r * yrvs
        } ${ex} ${ey - h * yrvs}
                          L ${ex} ${ey}
               `;
        return path;
      },
      layoutBinding: {
        nodeLeftX: (node) => -node.width! / 2,
        nodeRightX: (node) => node.width! / 2,
        nodeTopY: () => 0,
        nodeBottomY: (node) => node.height!,
        nodeJoinX: (node) => node.x! - node.width! / 2,
        nodeJoinY: (node) => node.y! + node.height!,
        linkJoinX: (node) => node.x!,
        linkJoinY: (node) => node.y! + node.height!,
        linkX: (node) => node.x!,
        linkY: (node) => node.y!,
        linkParentX: (node) => node.parent!.x!,
        linkParentY: (node) => node.parent!.y! + node.parent!.height,
        buttonX: (node) => node.width! / 2,
        buttonY: (node) => node.height!,
        centerTransform: ({ rootMargin, scale, centerX, centerY }) =>
          `translate(${centerX},${centerY + rootMargin}) scale(${scale})`,
        nodeFlexSize: ({ height, width, siblingsMargin, childrenMargin }) => [
          width + siblingsMargin,
          height + childrenMargin,
        ],
        diagonal: this.diagonal.bind(this),
        nodeUpdateTransform: ({ x, y, width }) =>
          `translate(${x! - width! / 2},${y})`,
      },
      initialExpandLevel: 1,
      svgWidth: 800,
      svgHeight: window.innerHeight - 100,
      container: null,
      data: [],
      rootMargin: 20,
      nodeWidth: () => 212,
      nodeHeight: () => 105,
      neighbourMargin: () => 80,
      siblingsMargin: () => 25,
      childrenMargin: () => 80,
      scaleExtent: [0.01, 20],
      setActiveNodeCentered: true,
      onZoomStart: identity,
      onZoom: identity,
      onZoomEnd: identity,
      onSvgClick: identity,
      onNodeClick: (d) => d,
      onExpandOrCollapse: (d) => d,
      nodeContent: undefined,
      svg: undefined,
      calc: undefined,
      ...overrideAttrs,
    };

    this.initializeEnterExitUpdatePattern();
  }

  getState = (): Attributes => this.attrs;

  setState(attrs = {} as Partial<Attributes>): D3OrgChart {
    this.attrs = {
      ...this.attrs,
      ...attrs,
    };

    return this;
  }

  initializeEnterExitUpdatePattern(): void {
    d3.selection.prototype.patternify = function (params: {
      selector: string;
      tag: string;
      data?: any;
    }) {
      const { selector } = params;
      const elementTag = params.tag;
      const data = params.data || [selector];

      let elems = this.selectAll(`.${selector}`).data(
        data,
        (d: any, i: any) => {
          if (typeof d === 'object') {
            if (d.id) {
              return d.id;
            }
          }
          return i;
        },
      );
      elems.exit().remove();
      elems = elems.enter().append(elementTag).merge(elems);
      elems.attr('class', selector);
      return elems;
    };
  }

  // This method retrieves passed node's children IDs (including node)
  getNodeChildren(
    { data, children, _children }: Node,
    nodeStore: NodeData[],
  ): NodeData[] {
    // Store current node ID
    nodeStore.push(data);

    // Loop over children and recursively store descendants id (expanded nodes)
    if (children) {
      children.forEach((d) => {
        this.getNodeChildren(d, nodeStore);
      });
    }

    // Loop over _children and recursively store descendants id (collapsed nodes)
    if (_children) {
      _children.forEach((d) => {
        this.getNodeChildren(d, nodeStore);
      });
    }

    // Return result
    return nodeStore;
  }

  render(): D3OrgChart {
    const attrs = this.getState();
    if (!attrs.data || attrs.data.length === 0) {
      console.log('ORG CHART - Data is empty');
      if (attrs.container) {
        d3.select<any, Node>(attrs.container).select('.nodes-wrapper').remove();
        d3.select<any, Node>(attrs.container).select('.links-wrapper').remove();
        d3.select<any, Node>(attrs.container)
          .select('.connections-wrapper')
          .remove();
      }
      return this;
    }

    // Drawing containers
    const container = d3.select<any, Node>(attrs.container);
    const containerRect = container.node().getBoundingClientRect();
    if (containerRect.width > 0) attrs.svgWidth = containerRect.width;

    // Calculated properties
    const calc: Record<string, any> = {
      id: `ID${Math.floor(Math.random() * 1000000)}`, // id for event handlings,
      chartWidth: attrs.svgWidth,
      chartHeight: attrs.svgHeight,
    };
    attrs.calc = calc;

    // Calculate max node depth (it's needed for layout heights calculation)
    calc.centerX = calc.chartWidth / 2;
    calc.centerY = calc.chartHeight / 4;

    // ******************* BEHAVIORS  **********************
    if (attrs.firstDraw) {
      // Get zooming function
      attrs.zoomBehavior = attrs
        .createZoom()
        .clickDistance(10)
        .on('start', (event) => attrs.onZoomStart?.(event))
        .on('end', (event) => attrs.onZoomEnd?.(event))
        .on('zoom', (event) => {
          attrs.onZoom?.(event);
          this.zoomed(event);
        })
        .scaleExtent(attrs.scaleExtent as [number, number]);
    }

    //* ***************** ROOT node work ************************

    attrs.flexTreeLayout = d3
      .flextree<NodeData>({
        nodeSize: (node) => {
          const width = attrs.nodeWidth?.(node as Node);
          const height = attrs.nodeHeight?.(node as Node);
          const siblingsMargin = attrs.siblingsMargin?.(node as Node);
          const childrenMargin = attrs.childrenMargin?.(node as Node);

          return attrs.layoutBinding.nodeFlexSize({
            attrs,
            node,
            width: Number(width),
            height: Number(height),
            siblingsMargin: Number(siblingsMargin),
            childrenMargin: Number(childrenMargin),
          });
        },
      })
      .spacing((nodeA, nodeB) =>
        nodeA.parent === nodeB.parent
          ? 0
          : attrs.neighbourMargin?.(nodeA, nodeB) ?? 0,
      );

    this.setLayouts({ expandNodesFirst: false });

    // *************************  DRAWING **************************
    // Add svg
    const svg = container
      .patternify({
        tag: 'svg',
        selector: 'org-chart',
      })
      .attr('width', attrs.svgWidth as string | number)
      .attr('height', attrs.svgHeight as string | number)
      .attr('font-family', 'Hellix, sans-serif')
      .on('click', attrs.onSvgClick);

    if (attrs.firstDraw && attrs.zoomBehavior) {
      svg
        .call(attrs.zoomBehavior)
        .on('dblclick.zoom', null)
        .attr('cursor', 'move');
    }

    attrs.svg = svg;

    // Add container g element
    const chart = svg.patternify({
      tag: 'g',
      selector: 'chart',
    });

    // Add one more container g element, for better positioning controls
    attrs.centerG = chart.patternify({
      tag: 'g',
      selector: 'center-group',
    });

    attrs.linksWrapper = attrs.centerG?.patternify({
      tag: 'g',
      selector: 'links-wrapper',
    });

    attrs.nodesWrapper = attrs.centerG?.patternify({
      tag: 'g',
      selector: 'nodes-wrapper',
    });

    if (attrs.firstDraw) {
      attrs.centerG.attr('transform', () =>
        attrs.layoutBinding.centerTransform({
          centerX: calc.centerX,
          centerY: calc.centerY,
          scale: Number(attrs.lastTransform.k),
          rootMargin: Number(attrs.rootMargin),
        }),
      );
    }

    attrs.chart = chart;

    // Display tree contenrs
    this.update(attrs.root as Node);

    // #########################################  UTIL FUNCS ##################################
    // This function restyles foreign object elements ()

    d3.select(window).on(`resize.${attrs.id}`, () => {
      const rect = d3
        .select<any, Node>(attrs.container)
        .node()
        .getBoundingClientRect();
      attrs.svg?.attr('width', rect.width);
    });

    if (attrs.firstDraw) {
      attrs.firstDraw = false;
    }

    return this;
  }

  // This function basically redraws visible graph, based on nodes state
  update({ x0, y0, x = 0, y = 0, width, height }: Node): void {
    const attrs = this.getState();

    //  Assigns the x and y position for the nodes
    const treeData = attrs.flexTreeLayout(attrs.root);
    const nodes = treeData.descendants();

    // Get all links
    const links = treeData.descendants().slice(1);

    // --------------------------  LINKS ----------------------
    // Get links selection
    const linkSelection = attrs.linksWrapper
      .selectAll('path.link')
      .data(links, (d: Node) => attrs.nodeId(d.data));

    // Enter any new links at the parent's previous position.
    const linkEnter = linkSelection
      .enter()
      .insert('path', 'g')
      .attr('class', 'link')
      .attr('d', () => {
        const xo = attrs.layoutBinding.linkJoinX({
          x: x0,
          y: y0,
          width,
          height,
        });
        const yo = attrs.layoutBinding.linkJoinY({
          x: x0,
          y: y0,
          width,
          height,
        });
        const o = { x: xo, y: yo };
        return attrs.layoutBinding.diagonal(o, o, o);
      });

    // Get links update selection
    const linkUpdate = linkEnter.merge(linkSelection);

    // Styling links
    linkUpdate.attr('fill', 'none');

    if (this.isEdge()) {
      linkUpdate.style('display', 'auto');
    } else {
      linkUpdate.attr('display', 'auto');
    }

    // Allow external modifications
    linkUpdate.each(attrs.linkUpdate);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(attrs.duration)
      .attr('d', (d: Node) => {
        const n = {
          x: attrs.layoutBinding.linkX(d),
          y: attrs.layoutBinding.linkY(d),
        };

        const p = {
          x: attrs.layoutBinding.linkParentX(d),
          y: attrs.layoutBinding.linkParentY(d),
        };

        return attrs.layoutBinding.diagonal(n, p, n);
      });

    // Remove any  links which is exiting after animation
    linkSelection
      .exit()
      .transition()
      .duration(attrs.duration)
      .attr('d', () => {
        const xo = attrs.layoutBinding.linkJoinX({
          x,
          y,
          width,
          height,
        });
        const yo = attrs.layoutBinding.linkJoinY({
          x,
          y,
          width,
          height,
        });
        const o = { x: xo, y: yo };
        return attrs.layoutBinding.diagonal(o, o, null);
      })
      .remove();

    // --------------------------  NODES ----------------------
    // Get nodes selection
    const nodesSelection = attrs.nodesWrapper
      .selectAll('g.node')
      .data(nodes, ({ data }: Node) => attrs.nodeId(data));

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = nodesSelection
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d: Node) => {
        if (d === attrs.root) return `translate(${x0},${y0})`;
        const xj = attrs.layoutBinding.nodeJoinX({
          x: x0,
          y: y0,
          width,
          height,
        });
        const yj = attrs.layoutBinding.nodeJoinY({
          x: x0,
          y: y0,
          width,
          height,
        });
        return `translate(${xj},${yj})`;
      })
      .attr('cursor', 'pointer')
      .on('click.node', (event: any, node: Node) => {
        if (
          [...event.srcElement.classList].includes('node-button-foreign-object')
        ) {
          return;
        }
        attrs.onNodeClick?.(node);
      })
      //  Event handler to the expand button
      .on('keydown.node', (event: any, node: Node) => {
        if (
          event.key === 'Enter' ||
          event.key === ' ' ||
          event.key === 'Spacebar'
        ) {
          if (
            [...event.srcElement.classList].includes(
              'node-button-foreign-object',
            )
          ) {
            return;
          }
          if (
            event.key === 'Enter' ||
            event.key === ' ' ||
            event.key === 'Spacebar'
          ) {
            this.onButtonClick(event, node);
          }
        }
      });
    nodeEnter.each(attrs.nodeEnter);

    // Add background rectangle for the nodes
    nodeEnter.patternify({
      tag: 'rect',
      selector: 'node-rect',
      data: (d: Node) => [d],
    });

    // Node update styles
    const nodeUpdate = nodeEnter
      .merge(nodesSelection)
      .style('font', '12px sans-serif');

    // Add foreignObject element inside rectangle
    const fo = nodeUpdate
      .patternify({
        tag: 'foreignObject',
        selector: 'node-foreign-object',
        data: (d: Node) => [d],
      })
      .style('overflow', 'visible');

    // Add foreign object
    fo.patternify({
      tag: 'xhtml:div',
      selector: 'node-foreign-object-div',
      data: (d: Node) => [d],
    });

    // Add foreign object overlay
    fo.patternify({
      tag: 'xhtml:div',
      selector: 'node-foreign-object-div-overlay',
      data: (d: Node) => [d],
    });

    this.restyleForeignObjectElements();

    // Add Node button circle's group (expand-collapse button)
    const nodeButtonGroups = nodeEnter
      .patternify({
        tag: 'g',
        selector: 'node-button-g',
        data: (d: Node) => [d],
      })
      .on('click', (event: any, d: Node) => this.onButtonClick(event, d))
      .on('keydown', (event: any, d: Node) => {
        if (
          event.key === 'Enter' ||
          event.key === ' ' ||
          event.key === 'Spacebar'
        ) {
          this.onButtonClick(event, d);
        }
      });

    // Add expand collapse button content
    nodeButtonGroups
      .patternify({
        tag: 'foreignObject',
        selector: 'node-button-foreign-object',
        data: (d: Node) => [d],
      })
      .attr('width', (d: Node) => attrs.nodeWidth?.(d))
      .attr('height', (d: Node) => attrs.nodeButtonHeight(d))
      .attr('x', (d: Node) => attrs.nodeButtonX(d))
      .attr('y', (d: Node) => attrs.nodeButtonY(d))
      .style('overflow', 'visible')
      .patternify({
        tag: 'xhtml:div',
        selector: 'node-button-div',
        data: (d: Node) => [d],
      })
      .style('display', 'flex')
      .style('width', '100%')
      .style('height', '100%');

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .attr('opacity', 0)
      .duration(attrs.duration)
      .attr('transform', (node: Node) =>
        attrs.layoutBinding.nodeUpdateTransform({
          x: Number(node.x),
          y: Number(node.y),
          width: Number(node.width),
          height: Number(node.height),
        }),
      )
      .attr('opacity', 1);

    // Style node rectangles
    nodeUpdate
      .select('.node-rect')
      .attr('width', ({ width }: Node) => width)
      .attr('height', ({ height }: Node) => height)
      .attr('x', () => 0)
      .attr('y', () => 0)
      .attr('cursor', 'pointer')
      .attr('rx', 3)
      .attr('fill', 'none');

    nodeUpdate
      .select('.node-button-g')
      .attr('transform', ({ width, height }: Node) => {
        const x = attrs.layoutBinding.buttonX({ width, height });
        const y = attrs.layoutBinding.buttonY({ width, height });
        return `translate(${x},${y})`;
      })
      .attr('display', ({ data }: Node) =>
        (data._directSubordinates ?? 0) > 0 ? null : 'none',
      )
      .attr('opacity', ({ children, _children }: Node) => {
        if (children || _children) {
          return 1;
        }
        return 0;
      });

    // Restyle node button circle
    nodeUpdate
      .select('.node-button-foreign-object .node-button-div')
      .html((node: Node) => attrs.buttonContent?.({ node, attrs }));

    // Restyle button texts
    nodeUpdate
      .select('.node-button-text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-size', ({ children }: Node) => {
        if (children) return 40;
        return 26;
      })
      .text(({ children }: Node) => {
        if (children) return '-';
        return '+';
      })
      .attr('y', this.isEdge() ? 10 : 0);

    nodeUpdate.each(attrs.nodeUpdate);

    // Remove any exiting nodes after transition
    const nodeExitTransition = nodesSelection.exit();
    nodeExitTransition.each(attrs.nodeExit);

    const maxDepthNode = nodeExitTransition
      .data()
      .reduce((a: any, b: any) => (a.depth < b.depth ? a : b), {
        depth: Infinity,
      });

    nodeExitTransition
      .attr('opacity', 1)
      .transition()
      .duration(attrs.duration)
      .attr('transform', () => {
        const { x, y, width, height } = maxDepthNode.parent || {};
        const ex = attrs.layoutBinding.nodeJoinX({
          x,
          y,
          width,
          height,
        });
        const ey = attrs.layoutBinding.nodeJoinY({
          x,
          y,
          width,
          height,
        });
        return `translate(${ex},${ey})`;
      })
      .on('end', function () {
        // @ts-ignore
        d3.select<any, Node>(this).remove();
      })
      .attr('opacity', 0);

    // Store the old positions for transition.
    nodes.forEach((d: Node) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // CHECK FOR CENTERING
    const centeredNode = attrs.allNodes.filter((d) => d.data._centered)[0];
    if (centeredNode) {
      let centeredNodes = [centeredNode];
      if (centeredNode.data._centeredWithDescendants) {
        centeredNodes = centeredNode.descendants().filter((d, i, arr) => {
          const h = Math.round(arr.length / 2);
          const spread = 2;
          if (arr.length % 2) {
            return i > h - spread && i < h + spread - 1;
          }

          return i > h - spread && i < h + spread;
        });
      }
      centeredNode.data._centeredWithDescendants = undefined;
      centeredNode.data._centered = undefined;

      this.fit({
        animate: true,
        scale: true,
        nodes: centeredNodes,
      });
    }
  }

  // This function detects whether current browser is edge
  isEdge(): boolean {
    return window.navigator.userAgent.includes('Edge');
  }

  // Generate horizontal diagonal - play with it here - https://observablehq.com/@bumbeishvili/curved-edges-horizontal-d3-v3-v4-v5-v6
  hdiagonal(source: Point, target: Point, m: Point): string {
    const attrs = this.getState();
    return attrs.hdiagonal(source, target, m);
  }

  // Generate custom diagonal - play with it here - https://observablehq.com/@bumbeishvili/curved-edges
  diagonal(source: Point, target: Point, m: Point): string {
    const attrs = this.getState();
    return attrs.diagonal(source, target, m);
  }

  restyleForeignObjectElements(): void {
    const attrs = this.getState();
    if (!attrs.svg) return;

    attrs.svg
      .selectAll<any, Node>('.node-foreign-object')
      .attr('width', ({ width }) => width!)
      .attr('height', ({ height }) => height)
      .attr('x', () => 0)
      .attr('y', () => 10);

    attrs.svg
      .selectAll<any, Node>('.node-foreign-object-div')
      .style('width', ({ width }) => `${width}px`)
      .style('height', ({ height }) => `${height}px`)
      .html(function (d, i, arr) {
        return attrs.nodeContent?.bind(this)(
          d,
          i,
          arr as Node[],
          attrs,
        ) as string;
      });

    attrs.svg
      .selectAll<any, Node>('.node-foreign-object-div-overlay')
      .style('width', ({ width }) => `${width}px`)
      .style('height', ({ height }) => `${height + 28}px`)
      .style('position', 'absolute')
      .style('background-color', '#f4f6f7')
      .style('bottom', 0);
  }

  toggleExpandCollapse(d: Node): void {
    // If childrens are expanded
    if (d.children) {
      // Collapse them
      d._children = d.children;
      d.children = undefined;

      // Set descendants expanded property to false
      this.setExpansionFlagToChildren(d, false);
    } else {
      // Expand children
      d.children = d._children;
      d._children = undefined;

      // Set each children as expanded
      if (d.children) {
        d.children.forEach(({ data }) => {
          data._expanded = true;
        });
      }
    }

    // Redraw Graph
    this.update(d);
  }

  // Toggle children on click.
  onButtonClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: Node,
  ): void {
    const attrs = this.getState();
    if (attrs.setActiveNodeCentered) {
      d.data._centered = true;
      d.data._centeredWithDescendants = true;
    }

    this.toggleExpandCollapse(d);
    event.stopPropagation();

    // Trigger callback
    attrs.onExpandOrCollapse?.(d);
  }

  // This function changes `expanded` property to descendants
  setExpansionFlagToChildren(
    node: Node,
    isExpanded: boolean,
    depthLevel = Infinity,
  ): void {
    // Set flag to the current property
    node.data._expanded = isExpanded;

    const stopRecursion = depthLevel === 0;
    if (stopRecursion) return;

    // Clean up expanded childrens
    if (!isExpanded && node.children) {
      node._children = node.children;
      node.children = undefined;
    }

    // Loop over and recursively update expanded children's descendants
    if (node.children) {
      node.children.forEach((d) => {
        this.setExpansionFlagToChildren(d, isExpanded, depthLevel - 1);
      });
    }

    // Loop over and recursively update collapsed children's descendants
    if (node._children) {
      node._children.forEach((d) => {
        this.setExpansionFlagToChildren(d, isExpanded, depthLevel - 1);
      });
    }
  }

  // Method which only expands nodes, which have property set "expanded=true"
  expandSomeNodes(d: Node): void {
    // If node has expanded property set
    if (d.data._expanded) {
      // Retrieve node's parent
      let { parent } = d;

      // While we can go up
      while (parent && parent._children) {
        // Expand all current parent's children
        parent.children = parent._children;
        parent._children = undefined;
        // Replace current parent holding object
        parent = parent.parent;
      }
    }

    // Recursively do the same for collapsed nodes
    if (d._children) {
      d._children.forEach((ch) => this.expandSomeNodes(ch));
    }

    // Recursively do the same for expanded nodes
    if (d.children) {
      d.children.forEach((ch) => this.expandSomeNodes(ch));
    }
  }

  setLayouts({ expandNodesFirst = true }: { expandNodesFirst: boolean }): void {
    const attrs = this.getState();
    // Store new root by converting flat data to hierarchy

    attrs.generateRoot = d3
      .stratify<NodeData>()
      .id((d) => attrs.nodeId(d))
      .parentId((d) => attrs.parentNodeId(d));
    attrs.root = attrs.generateRoot?.(attrs.data) as Node;

    const descendantsBefore = attrs.root.descendants();
    if (attrs.initialExpandLevel > 1 && descendantsBefore.length > 0) {
      descendantsBefore.forEach((d) => {
        if (d.depth <= attrs.initialExpandLevel) {
          d.data._expanded = true;
        }
      });
      attrs.initialExpandLevel = 1;
    }

    const hiddenNodesMap: Record<string, boolean> = {};
    attrs.root.descendants().filter((node) => node.children);

    attrs.root.eachBefore((node) => {
      if (node.children) {
        node.children.forEach((child) => {
          if (child.parent?.id && hiddenNodesMap[child.parent.id]) {
            hiddenNodesMap[child.id as string] = true;
          }
          if (
            child.data._expanded ||
            child.data._centered ||
            child.data._highlighted ||
            child.data._upToTheRootHighlighted
          ) {
            let localNode = child;
            while (localNode.id && hiddenNodesMap[localNode.id]) {
              hiddenNodesMap[localNode.id] = false;

              localNode = localNode.parent as Node;
            }
          }
        });
      }
    });

    attrs.root = d3
      .stratify<NodeData>()
      .id((d) => attrs.nodeId(d))
      .parentId((d) => attrs.parentNodeId(d))(
      attrs.data.filter((d) => hiddenNodesMap[d.id!] !== true),
    );

    attrs.root.each((node) => {
      const _hierarchyHeight = node._hierarchyHeight || node.height;
      const width = attrs.nodeWidth?.(node);
      const height = attrs.nodeHeight?.(node);
      Object.assign(node, { width, height, _hierarchyHeight });
    });

    // Store positions, where children appear during their enter animation
    attrs.root.x0 = 0;
    attrs.root.y0 = 0;
    attrs.allNodes = attrs.root.descendants();

    // Store direct and total descendants count
    attrs.allNodes.forEach((d) => {
      Object.assign(d.data, {
        _directSubordinates: d.children ? d.children.length : 0,
        _totalSubordinates: d.descendants().length - 1,
      });
    });

    if (attrs.root.children) {
      if (expandNodesFirst) {
        // Expand all nodes first
        attrs.root.children.forEach(this.expand);
      }
      // Then collapse them all
      attrs.root.children.forEach((d) => this.collapse(d));

      // Collapse root if level is 0
      if (attrs.initialExpandLevel === 0) {
        attrs.root._children = attrs.root.children;
        attrs.root.children = undefined;
      }

      // Then only expand nodes, which have expanded property set to true
      [attrs.root].forEach((ch) => this.expandSomeNodes(ch as Node));
    }
  }

  // Function which collapses passed node and it's descendants
  collapse(d: Node): void {
    if (d.children) {
      d._children = d.children;
      d._children.forEach((ch) => this.collapse(ch));
      d.children = undefined;
    }
  }

  // Function which expands passed node and it's descendants
  expand(d: Node): void {
    if (d._children) {
      d.children = d._children;
      d.children.forEach((ch) => this.expand(ch));
      d._children = undefined;
    }
  }

  // Zoom handler function
  zoomed(event: any): void {
    const attrs = this.getState();
    const { chart } = attrs;

    // Get d3 event's transform object
    const { transform } = event;

    // Store it
    attrs.lastTransform = transform;

    // Reposition and rescale chart accordingly
    chart?.attr('transform', transform);

    // Apply new styles to the foreign object element
    if (this.isEdge()) {
      this.restyleForeignObjectElements();
    }
  }

  private zoomTreeBounds({
    x0,
    params,
    x1,
    y0,
    y1,
  }: {
    x0: number;
    x1: number;
    y0: number;
    y1: number;
    params: {
      animate?: boolean;
      scale?: boolean;
      scaleVal?: number;
      onCompleted?: () => void;
    };
  }) {
    const {
      centerG,
      svgWidth: w,
      svgHeight: h,
      svg,
      zoomBehavior,
      duration,
      lastTransform,
    } = this.getState();
    if (!w || !h) return;

    const scaleVal =
      params.scaleVal ??
      Math.min(1, 0.9 / Math.max((x1 - x0) / Number(w), (y1 - y0) / Number(h)));
    let identity = d3.zoomIdentity.translate(Number(w) / 2, Number(h) / 2);
    identity = identity.scale(
      Number(params.scale ? scaleVal : lastTransform.k),
    );

    identity = identity.translate(-(x0 + x1) / 2, -(y0 + y1) / 2);
    // Transition zoom wrapper component into specified bounds
    svg
      ?.transition()
      .duration(params.animate ? duration : 0)
      // @ts-ignore
      .call(zoomBehavior.transform, identity);
    centerG
      ?.transition()
      .duration(params.animate ? duration : 0)
      .attr('transform', 'translate(0,0)')
      .on('end', () => {
        params.onCompleted?.();
      });
  }

  fit(
    { animate = true, nodes, scale = true, onCompleted } = {} as {
      animate?: boolean;
      nodes?: Iterable<Node>;
      scale?: boolean;
      onCompleted?: () => void;
    },
  ): D3OrgChart {
    const attrs = this.getState();
    const { root } = attrs;
    const descendants = nodes || (root?.descendants() as Iterable<Node>);
    const minX = d3.min(
      descendants,
      (d) => (d.x ?? 0) + attrs.layoutBinding.nodeLeftX(d),
    );
    const maxX = d3.max(
      descendants,
      (d) => (d.x ?? 0) + attrs.layoutBinding.nodeRightX(d),
    );
    const minY = d3.min(
      descendants,
      (d) => (d.y ?? 0) + attrs.layoutBinding.nodeTopY(d),
    );
    const maxY = d3.max(
      descendants,
      (d) => (d.y ?? 0) + attrs.layoutBinding.nodeBottomY(d),
    );

    this.zoomTreeBounds({
      params: { animate, scale, onCompleted },
      x0: Number(minX) - 50,
      x1: Number(maxX) + 50,
      y0: Number(minY) - 50,
      y1: Number(maxY) + 50,
    });
    return this;
  }

  setExpanded(nodeId: NodeId, expandedFlag = true): D3OrgChart {
    const attrs = this.getState();
    const node = attrs.allNodes.filter(
      ({ data }) => attrs.nodeId(data) === nodeId,
    )[0];

    if (!node) {
      return this;
    }
    node.data._expanded = expandedFlag;
    if (expandedFlag === false) {
      const parent = node.parent || { descendants: () => [] };
      const descendants = parent.descendants().filter((d) => d !== parent);
      descendants.forEach((d) => {
        d.data._expanded = false;
      });
    }

    return this;
  }

  getNodeFromId(nodeId: NodeId): Node | null {
    const attrs = this.getState();
    const root = attrs.generateRoot?.(attrs.data);
    const descendants = root?.descendants();
    const node = descendants?.find(
      (d) => attrs.nodeId(d.data)?.toString() === nodeId?.toString(),
    );
    if (!node) {
      return null;
    }
    return node;
  }

  setFocus(
    nodeId: NodeId,
    {
      shouldExpandChild = false,
      shouldHighlight = false,
    }: { shouldExpandChild?: boolean; shouldHighlight?: boolean },
  ): D3OrgChart {
    const node = this.getNodeFromId(nodeId);
    if (!node) {
      return this;
    }
    const ancestors = node.ancestors();
    ancestors.forEach((d) => {
      d.data._expanded = true;
    });
    node.data._highlighted = shouldHighlight;
    node.data._expanded = true;
    node.data._centered = true;

    if (shouldExpandChild) {
      this.setExpansionFlagToChildren(node, true, 1);
    }

    return this;
  }

  clearHighlightingNode(nodeId: NodeId): D3OrgChart {
    const node = this.getNodeFromId(nodeId);
    if (!node) {
      return this;
    }

    node.data._highlighted = false;
    return this;
  }

  setUpToTheRootHighlighted(nodeId: NodeId): D3OrgChart {
    const node = this.getNodeFromId(nodeId);
    if (!node) {
      return this;
    }
    const ancestors = node.ancestors();
    ancestors.forEach((d) => {
      d.data._expanded = true;
    });
    node.data._upToTheRootHighlighted = true;
    node.data._expanded = true;
    node.ancestors().forEach((d) => {
      d.data._upToTheRootHighlighted = true;
    });
    return this;
  }

  setFaded(nodeId: NodeId, expanded = true): D3OrgChart {
    const node = this.getNodeFromId(nodeId);
    if (!node) {
      return this;
    }
    const ancestors = node.ancestors();
    ancestors.forEach((d) => {
      d.data._expanded = expanded;
    });
    node.data._faded = true;
    node.data._expanded = expanded;
    this.setExpansionFlagToChildren(node, expanded);
    return this;
  }

  setGroupFaded(nodeIds: NodeId[], expanded = true): D3OrgChart {
    if (!nodeIds.length) return this;
    nodeIds.forEach((nodeId) => this.setFaded(nodeId, expanded));
    return this;
  }

  clearHighlighting(): D3OrgChart {
    const attrs = this.getState();
    attrs.allNodes.forEach((d) => {
      d.data._highlighted = false;
      d.data._upToTheRootHighlighted = false;
    });
    this.update(attrs.root as Node);
    return this;
  }

  clearFading(): D3OrgChart {
    const attrs = this.getState();
    attrs.allNodes.forEach((d) => {
      d.data._faded = false;
    });
    this.update(attrs.root as Node);
    return this;
  }

  zoomIn(scale = 1.5): void {
    const { svg, zoomBehavior } = this.getState();
    if (svg && zoomBehavior) {
      // @ts-ignore
      svg.transition().call(zoomBehavior.scaleBy, scale);
    }
  }

  zoomOut(scale = 0.5): void {
    const { svg, zoomBehavior } = this.getState();
    if (svg && zoomBehavior) {
      // @ts-ignore
      svg.transition().call(zoomBehavior.scaleBy, scale);
    }
  }

  resetZoom(): D3OrgChart {
    const { calc } = this.getState();

    if (!calc) return this;

    this.zoomTreeBounds({
      params: { animate: true, scale: true, scaleVal: 1 },
      x0: 0,
      x1: 0,
      y0: 0,
      y1: calc?.centerY * 2,
    });

    return this;
  }

  expandAll(): D3OrgChart {
    const { data } = this.getState();
    data.forEach((d) => {
      d._expanded = true;
    });
    this.render();
    return this;
  }

  collapseAll(retainsExpandLevel = 0): D3OrgChart {
    const { allNodes } = this.getState();
    allNodes.forEach((d) => {
      d.data._expanded = false;
    });
    this.setState({ initialExpandLevel: retainsExpandLevel }).render();

    return this;
  }
}
