import { gql } from '@apollo/client';

export default gql`
  query GetContractDepositWithPdf($id: ID!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      depositPayable {
        id
        invoice {
          id
          dueDate
          externalSystem
          document {
            name
            extension
            contentType
            blob
          }
          status
        }
      }
    }
  }
`;
