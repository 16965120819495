import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { useClickAway, useDebounce, useToggle } from 'react-use';

import { css } from '@emotion/react';
import tw, { TwStyle } from 'twin.macro';

import { useWindowDimensions } from '../hooks';
import { ThemeContext } from '../theme-provider';

type PopOverVariant =
  | 'top'
  | 'topRight'
  | 'topLeft'
  | 'bottom'
  | 'bottomRight'
  | 'bottomLeft'
  | 'left'
  | 'right'
  | 'center';

interface PopOverTypes {
  popOver: React.ReactNode;
  disabled?: boolean;
  containerStyles?: TwStyle;
  trigger?: 'click' | 'hover';
  position?: PopOverVariant;
  collisionDetection?: boolean;
  open?: boolean;
  setOpen?: (val: boolean) => void;
}

const PopOverPortal = React.forwardRef<
  HTMLDivElement,
  {
    popOver: React.ReactNode;
    trigger: HTMLDivElement;
    position: PopOverVariant;
    containerStyles?: TwStyle;
    invisible: boolean;
  }
>(({ popOver, trigger, position, containerStyles, invisible }, ref) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  const getBoundingClientRect = trigger.getBoundingClientRect();

  const toolTipStyles = useMemo(() => {
    const { left, right, top, bottom } = getBoundingClientRect;
    switch (position) {
      case 'top':
        return [
          css`
            top: ${top - 6}px;
            left: ${left + (right - left) / 2}px;
            transform: translateY(-100%) translateX(-50%);
          `,
        ];
      case 'topRight':
        return [
          css`
            top: ${top - 6}px;
            left: ${right}px;
            transform: translateY(-100%) translateX(-100%);
          `,
        ];
      case 'topLeft':
        return [
          css`
            top: ${top - 6}px;
            left: ${left}px;
            transform: translateY(-100%);
          `,
        ];
      case 'bottom':
        return [
          css`
            top: ${bottom + 6}px;
            left: ${left + (right - left) / 2}px;
            transform: translateX(-50%);
          `,
        ];
      case 'bottomRight':
        return [
          css`
            top: ${bottom + 6}px;
            left: ${right}px;
            transform: translateX(-100%);
          `,
        ];
      case 'bottomLeft':
        return [
          css`
            top: ${bottom + 6}px;
            left: ${left}px;
          `,
        ];
      case 'right':
        return [
          css`
            top: ${top + (bottom - top) / 2}px;
            left: ${right + 6}px;
            transform: translateY(-50%);
          `,
        ];
      case 'left':
        return [
          css`
            top: ${top + (bottom - top) / 2}px;
            left: ${left - 6}px;
            transform: translateX(-100%) translateY(-50%);
          `,
        ];
      case 'center':
        return [
          css`
            top: ${top + (bottom - top) / 2}px;
            left: ${left + (right - left) / 2}px;
            transform: translateX(-50%) translateY(-50%);
          `,
        ];
      default:
        return [];
    }
  }, [position, getBoundingClientRect]);

  const portal = ReactDOM.createPortal(
    <div>
      <RemoveScroll>
        <div
          css={[
            tw`w-max z-40 fixed border border-border-primary bg-background-white rounded-base`,
            !isNewThemeApplied && tw`border-grey03`,
            toolTipStyles,
            containerStyles,
            invisible ? tw`invisible` : tw`visible`,
          ]}
          ref={ref}
        >
          <div data-testid="popover-content" tw="relative">
            {popOver}
          </div>
        </div>
      </RemoveScroll>
    </div>,
    document.getElementById('dialog-root') as HTMLElement,
  );
  return <div>{portal as any}</div>;
});

const PopOver: React.FC<React.PropsWithChildren<PopOverTypes>> = ({
  disabled,
  popOver,
  children,
  trigger = 'hover',
  containerStyles,
  position = 'bottomRight',
  collisionDetection = false,
  open,
  setOpen,
  ...props
}) => {
  const [show, setShow] = useToggle(false);
  const [invisible, setInvisible] = useState(false);
  const [internalPosition, setInternalPosition] = useState<PopOverVariant>(
    position,
  );
  const [internalWindowDimensions, setInternalWindowDimensions] = useState({
    width: 0,
    height: 0,
  });
  const triggerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  let timeout: ReturnType<typeof setTimeout>;
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    if (setOpen) {
      setShow(open);
    }
  }, [open, setShow]);

  const handleSetShow = (val: boolean) => {
    if (setOpen) {
      setOpen(val);
    } else {
      setShow(val);
    }
  };

  useDebounce(
    () => {
      // window dimensions are debounced to avoid unnecessary re-renders when window is being resized
      setInternalWindowDimensions(windowDimensions);
    },
    100,
    [windowDimensions],
  );

  const showPopOver = () => {
    handleSetShow(true);
    clearTimeout(timeout);
  };

  const hidePopOver = () => {
    handleSetShow(false);
  };

  const togglePopOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    handleSetShow(!show);
    // Internal position is reset once closed
    if (collisionDetection) {
      setInternalPosition(position);
    }
  };

  const handleMouseLeave = () => {
    timeout = setTimeout(hidePopOver, 100);
  };

  useClickAway(triggerRef, (e) => {
    if (!e.target) return;

    if (!popoverRef.current?.contains(e.target as HTMLDivElement)) {
      hidePopOver();
    }
  });

  useEffect(() => () => clearTimeout(timeout), []);

  useEffect(() => {
    setInternalPosition(position);
  }, [position]);

  useEffect(() => {
    // popover is invisible by default if popover collision is turned on
    // this prevents the popover from displaying in the initial position first before adjusting to avoid collisions
    if (collisionDetection) setInvisible(true);
  }, [collisionDetection, show]);

  useEffect(() => {
    // collision detection based repositioning
    if (!collisionDetection || !show) return;

    const screenHeight = internalWindowDimensions.height;
    const popOverDimensions = popoverRef.current?.getBoundingClientRect();
    const popOverBottom = popOverDimensions?.bottom ?? 0;

    if (screenHeight - popOverBottom < 0) {
      switch (position) {
        case 'bottomRight':
          setInternalPosition('topRight');
          break;
        case 'bottomLeft':
          setInternalPosition('topLeft');
          break;
        case 'bottom':
          setInternalPosition('top');
          break;

        default:
          break;
      }
    } else {
      setInternalPosition(position);
    }
    setInvisible(false);
  }, [show, collisionDetection, internalWindowDimensions, popoverRef]);

  return (
    <div
      onMouseLeave={trigger === 'hover' ? handleMouseLeave : undefined}
      onMouseOver={trigger === 'hover' ? showPopOver : undefined}
    >
      <div
        tw="w-min relative"
        // TODO temporary workaround until tw is upgraded https://app.clickup.com/t/85ztf906t
        style={{ height: 'max-content' }}
        ref={triggerRef}
        role="button"
        tabIndex={0}
        onClick={trigger === 'click' ? togglePopOver : undefined}
        onKeyPress={trigger === 'click' ? togglePopOver : undefined}
        onFocus={trigger === 'hover' ? showPopOver : undefined}
        {...props}
      >
        {children}
      </div>
      {!disabled && show && triggerRef?.current && (
        <PopOverPortal
          ref={popoverRef}
          popOver={popOver}
          trigger={triggerRef.current}
          position={internalPosition}
          containerStyles={containerStyles}
          invisible={invisible}
        />
      )}
    </div>
  );
};

const Button: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ onClick, children, ...props }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <button
      css={[
        tw`text-ps text-left bg-background-white rounded-tiny p-base text-text-primary`,
        tw`focus:outline-none`,
        tw`hover:(font-semibold cursor-pointer bg-background-secondary text-text-secondary)`,
        !isNewThemeApplied && tw`hover:(bg-grey05 text-foreground)`,
        tw`disabled:(bg-background-secondary text-text-tertiary)`,
        !isNewThemeApplied && tw`disabled:(text-grey03 bg-grey05)`,
      ]}
      type="button"
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Object.assign(PopOver, { Button });
