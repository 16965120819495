import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, Icon } from '@multiplier/common';
import tw from 'twin.macro';

import * as DropdownText from 'common/components/dropdown-text';
import countryIDD from 'common/constants/country-idd';
import countryLabels from 'common/constants/country-labels';

import { Country, DataFieldDefinition } from '__generated__/graphql';

import { BasicDetailsFormValues } from '../../basic-details';

const PhoneNumberInput: React.FC<{
  dataField: DataFieldDefinition;
  index: number;
  countryCode?: Country['code'];
}> = ({ dataField, index, countryCode }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<BasicDetailsFormValues>();

  const phoneCodeDropdownValues = useMemo(
    () =>
      Object.entries(countryIDD).map((c) => ({
        key: `${c[1]}-${c[0]}`,
        icon: <Icon.Flag name={c[0].toLowerCase()} />,
        title: c[1],
        value: c[0],
        label: countryLabels[c[0]],
      })),
    [],
  );

  const fieldValue = (watch(`preDataRequirements.${index}.value`) ??
    '') as string;
  const [phoneNoParts, setPhoneNoParts] = useState({
    countryPhoneCode: countryCode ?? '',
    phoneNumber: '',
  });

  useLayoutEffect(() => {
    if (fieldValue !== '' && phoneNoParts.phoneNumber === '') {
      setPhoneNoParts({
        countryPhoneCode:
          Object.keys(countryIDD).find(
            (k) =>
              countryIDD[k] ===
              fieldValue.substring(0, fieldValue.indexOf(' ')),
          ) ?? '',
        phoneNumber: fieldValue.substring(fieldValue.indexOf(' ') + 1),
      });
    }
  }, [fieldValue]);

  const setPhoneNumber = useCallback(
    (phonePartKey: keyof typeof phoneNoParts, newValue: string) => {
      setPhoneNoParts((oldPhoneNoParts) => {
        const newPhoneNoParts = {
          ...oldPhoneNoParts,
          [phonePartKey]: newValue,
        };

        if (
          newPhoneNoParts.countryPhoneCode === '' ||
          newPhoneNoParts.phoneNumber === ''
        )
          setValue(`preDataRequirements.${index}.value`, '', {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        else
          setValue(
            `preDataRequirements.${index}.value`,
            `${countryIDD[newPhoneNoParts.countryPhoneCode]} ${
              newPhoneNoParts.phoneNumber
            }`,
            { shouldValidate: true, shouldDirty: true, shouldTouch: true },
          );

        return newPhoneNoParts;
      });
    },
    [],
  );

  return (
    <DropdownText.Container>
      <ComboBox
        data-testid="select-phone-country-code"
        variant="inline"
        showArrow
        placeholder="+XXX"
        value={phoneNoParts.countryPhoneCode}
        dropdownValues={phoneCodeDropdownValues}
        error={
          phoneNoParts.countryPhoneCode === '' &&
          !!errors?.preDataRequirements?.[index]?.value
        }
        onChange={(value) => setPhoneNumber('countryPhoneCode', value)}
      />
      <DropdownText.Input
        id={String(dataField.key)}
        placeholder={t('basic-details.phone-number-placeholder', '000000000')}
        value={phoneNoParts.phoneNumber}
        error={
          phoneNoParts.phoneNumber === '' &&
          !!errors?.preDataRequirements?.[index]?.value
        }
        onChange={(e) => setPhoneNumber('phoneNumber', e.target.value)}
        divStyles={tw`grow`}
      />
    </DropdownText.Container>
  );
};

export default PhoneNumberInput;
