import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
  WidgetPosition,
} from 'app/models/module-config';
import {
  showModuleBasedOnContractStatusAndContractStarted,
  showModuleBasedOnContractType,
} from 'app/utils/module-checks';

import { ContractStatus, ContractType } from '__generated__/graphql';

import routes from './routes';

const config: ModuleConfig = {
  id: 'payslips',
  permission: 'payslips',
  folder: 'payslips',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Payslips',
    route: routes.root,
    sort: 2,
    home: false,
    group: MemberNavGroups.PAYMENTS,
  },
  widget: {
    sort: 1,
    list: [
      {
        show: true,
        file: 'payslips-widget',
        position: WidgetPosition.LEFT,
      },
    ],
  },
  featureFlag: 'member-payslips-module',
  enabled: async () => {
    const shouldShow = await Promise.all([
      showModuleBasedOnContractType([
        ContractType.EMPLOYEE,
        ContractType.HR_MEMBER,
      ]),
      showModuleBasedOnContractStatusAndContractStarted([
        ContractStatus.ACTIVE,
        ContractStatus.ONBOARDING,
        ContractStatus.OFFBOARDING,
        ContractStatus.ENDED,
      ]),
    ]);

    return shouldShow.every((show) => show);
  },
};

export default config;
