/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';
import 'twin.macro';

type DebugInfoType = {
  errorCode: string;
  errorParams: { [key: string]: number | string };
  exceptionType: string;
};

export const parseErrorMessage = (e: ApolloError): string => {
  const getWiseValidationError = e?.graphQLErrors?.find(
    (err) =>
      (err?.extensions?.debugInfo as DebugInfoType)?.errorCode === 'MPL0025',
  );
  return ((getWiseValidationError?.extensions?.debugInfo as DebugInfoType)
    ?.errorParams?.message as string)?.replace(
    'com.multiplier.member.exception.ChangeRequestCreateException: ',
    '',
  );
};

export const setChangeErrorDetails = (
  error: ApolloError,
  setErrorDetails: (
    value: React.SetStateAction<
      { errorMessages: string[]; errorCount: number } | undefined
    >,
  ) => void,
): void => {
  const message = parseErrorMessage(error);
  const messages = message?.split('\n');
  if (messages)
    setErrorDetails({
      errorMessages: messages,
      errorCount: messages?.length,
    });
};

const ErrorDetails: React.FC<{
  errorCount: number;
  errorMessages: string[];
}> = ({ errorCount, errorMessages }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('contract-onboarding.member');

  useEffect(() => {
    if (ref?.current) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errorMessages]);

  return (
    <div
      data-testid="update-request-error"
      tw="p-base text-text-primary rounded-base bg-background-negative-faded"
      ref={ref}
    >
      <p tw="font-medium mb-base">
        {t('bank-details.error-message.header', {
          defaultValue:
            'Oops, we’ve found an error with the information you added.',
          defaultValue_plural:
            'Oops, we’ve found {{ count }} errors with the information you added.',
          count: errorCount,
        })}
      </p>
      <ol tw="ml-base list-disc list-outside">
        {errorMessages?.map((message) => (
          <li key={message}>
            <span>{message}</span>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ErrorDetails;
