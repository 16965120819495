/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker } from '@multiplier/common';
import { isSameDay, isWeekend } from 'date-fns';
import { TwStyle } from 'twin.macro';

import {
  Contract,
  ContractType,
  CountryWorkStatus,
} from '__generated__/graphql';

import useGetContractStartDateLimit, {
  ParsedHoliday,
} from '../../hooks/startdate-limit';
import { BasicDetailsFormValues } from '../../pages/definition-phase/pages/basic-details/basic-details';
import useStartDateFieldHighlights from './calendar-highlights';
import CalendarNote from './calendar-notes';

const isDateDisabledInCalendar = (
  limitedHolidays: ParsedHoliday[],
  contractType: Contract['type'],
) => (date: Date) => {
  if (
    contractType === ContractType.FREELANCER ||
    contractType === ContractType.CONTRACTOR
  )
    return false;

  return (
    isWeekend(date) ||
    limitedHolidays.some((holiday) => isSameDay(date, holiday.date))
  );
};

const StartDateField: React.FC<{
  selectedCountry: Contract['country'];
  selectedCountryState: Contract['countryStateCode'];
  selectedContractType: Contract['type'];
  selectedWorkStatus?: Contract['workStatus'];
  selectedLegalEntityId?: Contract['legalEntityId'];
  datePickerStyles?: TwStyle;
}> = ({
  selectedCountry,
  selectedCountryState,
  selectedContractType,
  selectedWorkStatus,
  selectedLegalEntityId,
  datePickerStyles,
}) => {
  const {
    earliestStartDate,
    earliestVisaCompletionDate,
    getLimitForMonthOfDate,
    limitedHolidays,
    loading,
  } = useGetContractStartDateLimit({
    country: selectedCountry,
    countryStateCode: selectedCountryState,
    contractType: selectedContractType,
    workStatus: selectedWorkStatus,
    legalEntityId: selectedLegalEntityId,
  });

  const isEmployee = selectedContractType === ContractType.EMPLOYEE;
  const requireVisa = selectedWorkStatus === CountryWorkStatus.REQUIRES_VISA;

  const minDate = isEmployee ? earliestStartDate || new Date() : undefined;
  const visaCheckpointDate =
    isEmployee && requireVisa
      ? earliestVisaCompletionDate || new Date()
      : undefined;

  const {
    formState: { errors },
    trigger,
  } = useFormContext<BasicDetailsFormValues>();

  const highlights = useStartDateFieldHighlights(
    limitedHolidays,
    minDate,
    visaCheckpointDate,
    selectedContractType,
  );

  const notesProps = {
    contractType: selectedContractType,
    country: selectedCountry,
    earliestJoiningDate: minDate,
    visaCheckpointDate,
  };

  return (
    <Controller
      name="startOn"
      render={({ field: { value, onChange } }) => (
        <DatePicker
          css={datePickerStyles}
          data-testid="start-on"
          id="start-on"
          value={value}
          onChange={(val) => {
            if (val === '') onChange(undefined);
            else onChange(val);

            getLimitForMonthOfDate(String(val));
            trigger(['startOn']);
          }}
          min={minDate}
          error={!!errors.startOn}
          helperText={errors.startOn?.message}
          loading={loading}
          disableDate={isDateDisabledInCalendar(
            limitedHolidays,
            selectedContractType,
          )}
          notes={<CalendarNote {...notesProps} />}
          highlights={highlights}
        />
      )}
    />
  );
};

export default StartDateField;
