import { gql } from '@apollo/client';

export default gql`
  query GetCompanyStatusAndCSM {
    company {
      id
      status
      csmUser {
        id
      }
    }
  }
`;
