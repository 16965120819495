import React, { useState } from 'react';

import { TableDataObjectType } from '../../../../types';

export type TableData = { [key: string]: React.ReactNode | number };

const ARRAY_SIZE = 20;
const RESPONSE_TIME_IN_MS = 250;

interface Response {
  hasNextPage: boolean;
  data: TableData[];
}

function loadItems(team: TableData[], startCursor = 0): Promise<Response> {
  return new Promise((resolve) => {
    let newArray: TableData[] = [];
    let hasNextPage = true;

    setTimeout(() => {
      for (let i = startCursor; i < startCursor + ARRAY_SIZE; i += 1) {
        if (team[i] && team[i].id) {
          newArray = [...newArray, team[i]];
        }
        if (i === team.length - 1) {
          hasNextPage = false;
        }
      }

      resolve({ hasNextPage, data: newArray });
    }, RESPONSE_TIME_IN_MS);
  });
}

export default (
  tableData: TableDataObjectType[] | [],
): {
  loading: boolean;
  items: TableData[];
  hasNextPage: boolean;
  error: Error | undefined;
  loadMore: () => Promise<void>;
} => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<TableData[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  const loadMore = async () => {
    setLoading(true);
    try {
      if (tableData.length > 0) {
        const { data, hasNextPage: newHasNextPage } = await loadItems(
          tableData,
          items.length,
        );
        setItems((current) => [...current, ...data]);
        setHasNextPage(newHasNextPage);
      }
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, items, hasNextPage, error, loadMore };
};
