/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { FieldArrayWithId } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import regexifyString from 'regexify-string';
import tw from 'twin.macro';

import {
  ComplianceParamDefinition,
  ComplianceParamPeriodLimit,
  Contract,
  ContractType,
  Maybe,
} from '__generated__/graphql';

import { ComplianceFormValues } from '../../contract-section';
import EditableClause from './editable-clause';

const useComplianceParameterDescription = ({
  isNoticeAfterProbation,
  complianceParamDefinition,
  isMaltaFixedTermContract,
  contractType,
  isGermanyContract,
}: {
  isNoticeAfterProbation: boolean;
  complianceParamDefinition: Maybe<ComplianceParamDefinition> | undefined;
  isMaltaFixedTermContract: boolean;
  contractType: Contract['type'];
  isGermanyContract: boolean;
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const isContractor = contractType === ContractType.CONTRACTOR;

  if (isNoticeAfterProbation) {
    if (isMaltaFixedTermContract) {
      return t(
        'compliance.notice-after-probation-description.mlt-fixed-term',
        `The Terminating Party will be obliged to pay the other Party one-half of the employee's full wages that would have accrued in respect of the remainder of the time specifically agreed upon.`,
      );
    }

    if (isContractor) {
      return 'The agreement of the contractor may be terminated anytime by giving {{ param }} notice or payment in lieu of notice';
    }

    if (isGermanyContract) {
      return t(
        'compliance.notice-after-probation-description.deu',
        'Upon expiration of the probationary period the labor relationship can be terminated with 4-weeks-notice to the last day of the month.',
      );
    }
  }

  return (
    (complianceParamDefinition?.param &&
      (complianceParamDefinition.param as ComplianceParamPeriodLimit)
        .description) ??
    ''
  );
};
const ComplianceParamDescription: React.FC<{
  isNoticeAfterProbation: boolean;
  isMaltaFixedTermContract: boolean;
  paramEnabled: boolean;
  isClauseEditable: boolean;
  clauseDefaultValue: number;
  field: FieldArrayWithId<
    ComplianceFormValues,
    'complianceParams' | 'mandatory' | 'custom',
    'id'
  >;
  complianceParamDefinition?: Maybe<ComplianceParamDefinition>;
  setShowEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  contractType: Contract['type'];
  isGermanyContract: boolean;
}> = ({
  isNoticeAfterProbation,
  isMaltaFixedTermContract,
  paramEnabled,
  isClauseEditable,
  clauseDefaultValue,
  field,
  complianceParamDefinition,
  setShowEditMode,
  contractType,
  isGermanyContract,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const description = useComplianceParameterDescription({
    isNoticeAfterProbation,
    complianceParamDefinition,
    isMaltaFixedTermContract,
    contractType,
    isGermanyContract,
  });

  return (
    <span
      css={[
        tw`text-ps text-text-secondary`,
        !paramEnabled && tw`text-text-tertiary`,
        !isNewThemeApplied && tw`text-grey01`,
      ]}
    >
      {regexifyString({
        pattern: /{{\s*param\s*}}/g,
        decorator: (match) => (
          <EditableClause
            key={match}
            disabled={
              (!complianceParamDefinition?.editable || !paramEnabled) ?? true
            }
            editable={isClauseEditable}
            onClick={() => setShowEditMode(true)}
            paramValue={clauseDefaultValue}
            paramUnit={field?.unit}
          />
        ),
        input: description,
      })}
    </span>
  );
};

export default ComplianceParamDescription;
