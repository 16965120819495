import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractType } from '__generated__/graphql';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingSigning: ContractOnboardingStepConfig = {
  title: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.onboarding-signing.contractor.title',
          'Order Form Signing',
        )
      : t('onboarding-phase.onboarding-signing.title', 'Contract Signing'),
  status: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.onboarding-signing.contractor.status',
          'Pending on Signatory',
        )
      : t('onboarding-phase.onboarding-signing.status', 'Pending on You'),
  variant: ProgressWidgetContentVariant.STATUSES,
};

export default onboardingSigning;
