/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect } from 'react';

import 'twin.macro';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import {
  Loader,
  SpinnerType,
  StatelessFileUpload,
  getFileFromUploadedDocument,
} from '@multiplier/common';

import { notEmpty } from 'app/utils/array';
import TextInput from 'common/components/text-input';

import {
  ContractOnboardingStep,
  Document,
  LegalDocumentRequirement,
  Maybe,
  Member,
} from '__generated__/graphql';

export interface LegalDocumentRequirementField
  extends Pick<
    LegalDocumentRequirement,
    'key' | 'label' | 'description' | 'acceptMultiple' | 'required' | 'category'
  > {
  value: Maybe<File> | Maybe<File[]>;
}

export interface DocumentFormParams {
  legalDocuments: LegalDocumentRequirementField[];
}

export interface OnboardingStepProps {
  currentStep?: ContractOnboardingStep;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
}

type fileErrorType = {
  message: string;
};
const getErrorMessages = (errorMessages: fileErrorType[] | fileErrorType) => {
  if (Array.isArray(errorMessages)) {
    return (
      <div>
        {(errorMessages as fileErrorType[]).map((err) => (
          <div>{err.message}</div>
        ))}
      </div>
    );
  }
  return (errorMessages as fileErrorType)?.message;
};

export const getFileToPreFillTheForm = async (
  key: string,
  documents?: Document[],
): Promise<{
  key: string;
  files: File[] | null;
}> => {
  const promiseArray = (documents ?? [])?.map((doc) =>
    getFileFromUploadedDocument(doc),
  );
  const allFiles = Promise.all(promiseArray);
  try {
    const files = await allFiles;
    return {
      key,
      files,
    };
  } catch (error) {
    return {
      key,
      files: null,
    };
  }
};
const VisaDocumentsFormFields: React.FC<{
  requirements: LegalDocumentRequirement[];
  legalDocuments: Member['legalDocuments'];
}> = ({ requirements, legalDocuments }) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    reset,
    trigger,
    formState: { errors },
  } = useFormContext<DocumentFormParams>();

  const { fields } = useFieldArray({
    control,
    name: 'legalDocuments',
  });

  const [fetchingFiles, setFetchingFiles] = React.useState(false);

  useLayoutEffect(() => {
    reset({
      legalDocuments: requirements,
    });
  }, [requirements]);

  useDeepCompareEffect(() => {
    if (legalDocuments == null) return;

    setFetchingFiles(true);
    const filesPromises = legalDocuments
      ?.filter(notEmpty)
      ?.map((cur) =>
        getFileToPreFillTheForm(
          cur?.key ?? '',
          (cur.files ?? []).filter(notEmpty),
        ),
      );

    Promise.all(filesPromises).then((files) => {
      const fileMap = files.reduce(
        (acc, cur) => (!cur.files ? acc : { ...acc, [cur.key]: cur.files }),
        {} as { [key: string]: File[] },
      );

      reset({
        legalDocuments: requirements.map((docReq) => ({
          ...docReq,
          value: docReq?.acceptMultiple
            ? fileMap[String(docReq?.key)]
            : fileMap?.[String(docReq?.key)]?.[0],
        })),
      });

      trigger();

      setFetchingFiles(false);
    });
  }, [requirements, legalDocuments]);

  return (
    <div tw="relative grid gap-y-24">
      {fetchingFiles && (
        <Loader.Spinner
          tw="h-full w-full absolute"
          variant={SpinnerType.CUSTOM_LAYOUT}
        />
      )}
      {fields.map((field, index) => (
        <TextInput.Container
          key={field.key}
          data-testid="file-upload-component"
        >
          <TextInput.Label>
            {t(
              `legal-documents.${field.label}`,
              field.label ?? field.key ?? '',
            )}
          </TextInput.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <StatelessFileUpload
                disabled={fetchingFiles}
                files={
                  field.acceptMultiple
                    ? ((value ?? []) as File[])
                    : ((value ? [value] : []) as File[])
                }
                data-testid={field.key}
                multiple={Boolean(field.acceptMultiple)}
                minimal
                onFileDrop={(f: File[]) => {
                  if (field.acceptMultiple) {
                    onChange([
                      ...((value as File[])?.length ? (value as File[]) : []),
                      ...f,
                    ]);
                  } else {
                    onChange(f[0]);
                  }
                }}
                onRemoveFile={(f) => {
                  if (field.acceptMultiple)
                    onChange(
                      ((value as File[]) ?? [])?.filter(
                        (file) => f.name !== file.name,
                      ),
                    );
                  else onChange(null);
                }}
              />
            )}
            name={`legalDocuments.${index}.value` as 'legalDocuments.0.value'}
            control={control}
          />
          {errors.legalDocuments?.[index] && (
            <TextInput.Error>
              {getErrorMessages(
                errors?.legalDocuments?.[index]?.value as fileErrorType,
              )}
            </TextInput.Error>
          )}
        </TextInput.Container>
      ))}
    </div>
  );
};

export default VisaDocumentsFormFields;
