/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Invite } from '@multiplier/assets/files/team/invite.svg';
import {
  Button,
  EmptySplash,
  Icon,
  Module,
  Pictogram,
  TableCell,
  TableDataObjectType,
  ThemeContext,
  useUpdateSearchParams,
} from '@multiplier/common';
import {
  TeamViewOffboardingStatusCell,
  hooks as contractOffboardingHooks,
} from '@multiplier/contract-offboarding';
import {
  OnboardingProgressPill,
  companyHooks as contractOnboardingHooks,
} from '@multiplier/contract-onboarding';
import { usePermission } from '@multiplier/user';
import tw from 'twin.macro';

import { Experience } from 'app/models/module-config';
import { dateAsString } from 'app/utils/format';
import Table from 'common/components/table';
import { contractType } from 'common/constants/default-labels';
import contractOnboardingRoutes from 'contract-onboarding/company/routes';
import LastWorkingDay from 'team/company/components/last-working-day';
import MemberContractStatus from 'team/company/components/member-contract-status';
import TeamViewAllSubFilters from 'team/company/components/team-view-all-sub-filters';
import TeamViewFilters from 'team/company/components/team-view-filters';
import TeamViewSubFilters from 'team/company/components/team-view-subfilters';

import { ContractOnboarding, OnboardingTask } from '__generated__/graphql';

import {
  getDaysUntilCutoff,
  needsAttention,
} from '../../../../dashboard/hooks/pending-onboardings';
import ActionCell from '../../components/action-cell';
import MemberStatus from '../../components/member-status';
import PayCell from '../../components/pay-cell';
import {
  useFilterAndSortContracts,
  useGetTeam,
  useLegacyCancelOffboardingDialog,
  useLegacyRescheduleOffboardingDialog,
} from '../../hooks';
import { groupTeamView } from '../../services/contracts';
import { ContractFromTeamQuery } from '../../types';
import { tColumns } from './table.config';

export const SUB_TAB = 'subtab';

export enum TeamViewTab {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
  INACTIVE = 'INACTIVE',
  OFFBOARDING = 'OFFBOARDING',
  ENDED = 'ENDED',
  ALL = 'ALL',
  BLOCKED = 'BLOCKED',
}

export enum TeamViewSubTab {
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  DELAYED_ONBOARDING = 'DELAYED_ONBOARDING',
}

export enum TeamViewAllSubTab {
  INACTIVE = 'INACTIVE',
  ENDED = 'ENDED',
  BLOCKED = 'BLOCKED',
  ALL = 'ALL',
}

export const getOnboardingBucketNumber = (
  onboarding?: ContractOnboarding | null,
): number => {
  const daysToCutoff = getDaysUntilCutoff(onboarding);
  const urgent = needsAttention(onboarding);

  if (daysToCutoff === null) return -1;

  if (daysToCutoff < 0) return 400;

  if (daysToCutoff === 0) return 90;

  if (urgent && daysToCutoff >= 0) return 11 - daysToCutoff;

  return -1;
};

const TeamViewRoot: React.FC = () => {
  const { t } = useTranslation('team.company');
  const { team, loading } = useGetTeam();
  const [needsAttentionCount, setNeedsAttentionCount] = useState(0);
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const updateSearchParams = useUpdateSearchParams();
  const [allowViewOnboardingContracts] = usePermission(
    'use.company.contract-onboarding',
  );

  const { isNewThemeApplied } = useContext(ThemeContext);

  const {
    selectedSubTabAll,
    setSelectedSubTabAll,
    selectedTab,
    setSelectedTab,
    setSearchQuery,
    selectedSubTab,
    setSelectedSubTab,
    filteredContracts,
    handleTeamSort,
  } = useFilterAndSortContracts(team);

  const {
    handleDeleteClick,
    Dialog: DeleteDialog,
  } = contractOnboardingHooks.useDeleteDialog();

  const {
    handleCancelClick,
    Dialog: CancelOffboardingDialog,
  } = contractOffboardingHooks.useCancelOffboardingDialog();

  const {
    handleCancelClick: handleLegacyCancelOffboardingClick,
    Dialog: LegacyCancelOffboardingDialog,
  } = useLegacyCancelOffboardingDialog();

  const {
    handleRescheduleClick: handleLegacyRescheduleClick,
    Dialog: LegacyRescheduleOffboardingDialog,
  } = useLegacyRescheduleOffboardingDialog();

  const {
    Dialog: OnboardingChecklistPanel,
    handleExpandProgress,
  } = contractOnboardingHooks.useOnboardingChecklistSidebar();

  const tableColumns = useMemo(
    () => tColumns({ selectedTab, selectedSubTab: selectedSubTabAll, t }),
    [selectedTab, selectedSubTabAll],
  );

  useEffect(() => {
    if (selectedTab === TeamViewTab.ONBOARDING && needsAttentionCount) {
      setSelectedSubTab(TeamViewSubTab.NEEDS_ATTENTION);
    }
  }, [needsAttentionCount]);

  useEffect(() => {
    const needAttention = filteredContracts[
      TeamViewTab.ONBOARDING
    ].filter((contract) => needsAttention(contract.onboarding)).length;
    setNeedsAttentionCount(needAttention);
  }, [filteredContracts]);

  const getOnboardingBucket = (onbooarding?: ContractOnboarding | null) => {
    const daysToCutoff = getDaysUntilCutoff(onbooarding);
    const urgent = needsAttention(onbooarding);

    if (daysToCutoff === null) return 'OTHER PENDING ONBOARDING';

    if (urgent && daysToCutoff > 0) {
      return `COMPLETE IN ${daysToCutoff} ${
        daysToCutoff === 1 ? 'DAY' : 'DAYS'
      }`;
    }

    if (daysToCutoff < 0) return 'DELAYED ONBOARDING';

    if (daysToCutoff === 0) return 'COMPLETE TODAY';

    return 'OTHER PENDING ONBOARDING';
  };

  const getTasksProgress = (tasks: OnboardingTask[]) => {
    const counts = {
      pending: tasks.filter((task) => !task.completed).length,
      completed: tasks.filter((task) => task.completed).length,
      total: tasks.length,
    };

    const progress = counts.completed / counts.total;
    return progress;
  };

  const processTableData = (contract: ContractFromTeamQuery) => {
    const progress = getTasksProgress(contract?.onboarding?.tasks ?? []);

    return {
      id: contract.id,
      country: contract.country && (
        <TableCell.Country
          country={contract.country}
          css={[
            progress <= 0.5 &&
              tw`before:content-[''] before:absolute before:left-0 before:top-[22px] before:h-[36px] before:w-tiny before:bg-background-negative before:rounded-r-tiny before:rounded-l-none`,
          ]}
        />
      ),
      name: (
        <TableCell.Name
          name={`${contract.member?.firstName ?? ''} ${
            contract.member?.lastName ?? ''
          }`.trim()}
          position={contract.position ?? ''}
        />
      ),
      pay: (
        <PayCell type={contract.type} compensation={contract.compensation} />
      ),
      status: (
        <MemberStatus
          onboardingStatus={contract.onboarding}
          status={contract.status}
          contractStarted={contract.contractStarted}
          contractBlocked={contract.contractBlocked}
        />
      ),
      progress: (
        <OnboardingProgressPill
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          handleClick={(e: any) => {
            e.stopPropagation();

            if (handleExpandProgress) handleExpandProgress(contract.id);
          }}
          contractStatus={contract.status}
          tasks={contract.onboarding?.tasks}
        />
      ),
      contractStatus: <MemberContractStatus status={contract.status} />,
      offboardingStatus: (
        <TeamViewOffboardingStatusCell
          status={contract.status}
          offboardingStatus={contract.offboarding?.status}
        />
      ),
      employmentType: contract?.type && contractType[contract.type],
      startOn: contract.startOn,
      endedOn: contract.endedOn,
      createdOn: contract.createdOn,
      startDate: dateAsString(contract.startOn ?? ''),
      endDate: (
        <LastWorkingDay
          startOn={contract.startOn}
          endedOn={contract.endedOn}
          lastWorkingDay={contract.offboarding?.lastWorkingDay}
        />
      ),
      onboardingBucket: getOnboardingBucket(contract.onboarding),
      onboardingBucketNumber: getOnboardingBucketNumber(contract.onboarding),

      actions: (
        <ActionCell
          contractId={contract.id}
          contractStatus={contract.status}
          contractStarted={contract.contractStarted}
          contractBlocked={contract.contractBlocked}
          contractOffboarding={contract.offboarding}
          startOn={contract.startOn}
          endedOn={contract.endedOn}
          country={contract.country}
          handleDeleteContractClick={() =>
            handleDeleteClick({
              contractId: contract.id,
              memberId: contract.member?.id,
              memberName: `${contract.member?.firstName}`,
            })
          }
          handleCancelOffboardingClick={() =>
            handleCancelClick(contract.offboarding?.id, contract.id)
          }
          handleLegacyCancelOffboardingClick={() =>
            handleLegacyCancelOffboardingClick(contract)
          }
          handleLegacyOffboardingReschedule={() =>
            handleLegacyRescheduleClick(contract)
          }
        />
      ),
    };
  };

  const teamsForTable = useMemo(
    () => ({
      [TeamViewTab.ACTIVE]: filteredContracts[TeamViewTab.ACTIVE].map(
        processTableData,
      ),
      [TeamViewTab.ONBOARDING]: filteredContracts[TeamViewTab.ONBOARDING].map(
        processTableData,
      ),
      [TeamViewTab.INACTIVE]: filteredContracts[TeamViewTab.INACTIVE].map(
        processTableData,
      ),
      [TeamViewTab.OFFBOARDING]: filteredContracts[TeamViewTab.OFFBOARDING].map(
        processTableData,
      ),
      [TeamViewTab.ENDED]: filteredContracts[TeamViewTab.ENDED].map(
        processTableData,
      ),
      [TeamViewTab.ALL]: filteredContracts[selectedSubTabAll].map(
        processTableData,
      ),
      [TeamViewTab.BLOCKED]: filteredContracts[TeamViewTab.BLOCKED].map(
        processTableData,
      ),
    }),
    [filteredContracts, selectedSubTabAll],
  );

  useEffect(() => {
    if (selectedTab !== TeamViewTab.ONBOARDING) setSelectedSubTab(undefined);
    if (
      selectedTab === TeamViewTab.OFFBOARDING ||
      selectedTab === TeamViewTab.ACTIVE
    ) {
      updateSearchParams([], [SUB_TAB]);
    }

    if (selectedTab === TeamViewTab.ONBOARDING && needsAttentionCount) {
      setSelectedSubTab(TeamViewSubTab.NEEDS_ATTENTION);
      updateSearchParams([
        {
          key: SUB_TAB,
          value: TeamViewSubTab.NEEDS_ATTENTION,
        },
      ]);
    }

    if (selectedTab === TeamViewTab.ALL) {
      if (
        params.get(SUB_TAB) &&
        Object.keys(TeamViewAllSubTab).includes(params.get(SUB_TAB) as string)
      ) {
        setSelectedSubTabAll(params.get(SUB_TAB) as TeamViewAllSubTab);
        updateSearchParams([
          {
            key: SUB_TAB,
            value: params.get(SUB_TAB) as TeamViewAllSubTab,
          },
        ]);
      } else {
        setSelectedSubTabAll(TeamViewAllSubTab.ALL);
        updateSearchParams([
          {
            key: SUB_TAB,
            value: TeamViewAllSubTab.ALL,
          },
        ]);
      }
    }
  }, [selectedTab]);

  const handleTabChange = (tab: string) => setSelectedTab(tab as TeamViewTab);

  const renderMobileItem = (tableData: TableDataObjectType) => (
    <li key={tableData?.id?.toString()} tw="flex items-center py-large ml-base">
      {tableData?.country && (
        <TableCell.Country
          country={(tableData.country as React.ReactElement)?.props?.country}
          tw="w-2/12 mr-2"
        />
      )}
      <div tw="w-9/12 flex items-center justify-between">
        <TableCell.Name
          name={`${(tableData?.name as React.ReactElement)?.props?.name}`}
          position={
            (tableData.name as React.ReactElement)?.props.position ?? ''
          }
          css={[
            selectedTab === TeamViewTab.ACTIVE && tw`w-full`,
            selectedTab === TeamViewTab.ONBOARDING && tw`w-6/12`,
          ]}
        />
        {selectedTab === TeamViewTab.ONBOARDING && (
          <MemberStatus
            tw="w-6/12"
            onboardingStatus={
              (tableData?.status as React.ReactElement)?.props?.onboardingStatus
            }
          />
        )}
      </div>
      <Icon tw="w-1/12" name="caret-right-light" />
    </li>
  );

  const handleRowClick = (row: TableDataObjectType) => {
    if (!row?.id) return;
    navigate(`${row.id}`, {
      state: { prevTab: selectedTab },
      replace: true,
    });
  };

  const tabs = useMemo(() => {
    const groupedTeamData = groupTeamView(team);
    const tempTabs = [
      {
        name: t('main.tabs.active', 'Active'),
        key: TeamViewTab.ACTIVE,
      },
    ];

    if (allowViewOnboardingContracts) {
      tempTabs.push({
        name: t('main.tabs.onboarding', 'Onboarding'),
        key: TeamViewTab.ONBOARDING,
      });
    }

    if (groupedTeamData[TeamViewTab.OFFBOARDING].length > 0) {
      tempTabs.push({
        name: t('main.tabs.offboarding', 'Offboarding'),
        key: TeamViewTab.OFFBOARDING,
      });
    }

    tempTabs.push({
      name: t('main.tabs.all', 'All'),
      key: TeamViewTab.ALL,
    });

    return tempTabs;
  }, [team]);

  const allTabPills = [
    {
      count: filteredContracts[TeamViewTab.BLOCKED].length,
      tab: TeamViewAllSubTab.BLOCKED,
    },
    {
      count: filteredContracts[TeamViewTab.INACTIVE].length,
      tab: TeamViewAllSubTab.INACTIVE,
    },
    {
      count: filteredContracts[TeamViewTab.ENDED].length,
      tab: TeamViewAllSubTab.ENDED,
    },
  ];

  return (
    <Module.Layout data-testid="team-view">
      <Module.Header
        header={t('main.header', 'Team Overview')}
        description={t('main.description', 'Manage your team members')}
      />
      <Table loading={loading}>
        <div tw="flex flex-row justify-between">
          <Table.Head
            tabs={tabs}
            value={selectedTab}
            onChange={handleTabChange}
            defaultTab={TeamViewTab.ACTIVE}
          />
          <TeamViewFilters
            setSearchQuery={setSearchQuery}
            selectedTab={selectedTab}
          />
        </div>
        {selectedTab === TeamViewTab.ONBOARDING && (
          <TeamViewSubFilters
            selectedSubTab={selectedSubTab}
            setSelectedSubTab={setSelectedSubTab}
            needsAttentionCount={needsAttentionCount}
          />
        )}
        {selectedTab === TeamViewTab.ALL && (
          <TeamViewAllSubFilters
            pillList={allTabPills}
            selectedSubTab={selectedSubTabAll}
            setSelectedSubTab={setSelectedSubTabAll}
          />
        )}
        {team && filteredContracts[selectedTab].length > 0 ? (
          <Table.Body
            disableSortBy={false}
            data={teamsForTable[selectedTab]}
            mobileData={teamsForTable[selectedTab]}
            columns={tableColumns}
            handleSort={handleTeamSort}
            renderMobileItem={renderMobileItem}
            handleRowClick={handleRowClick}
            defaultRows={10}
          />
        ) : (
          <EmptySplash
            splash={
              isNewThemeApplied ? (
                <Pictogram name="team" size="medium" />
              ) : (
                <Invite />
              )
            }
            header={t('banner.header', 'Start inviting your team')}
            description={t(
              'banner.description',
              'Start onboarding and managing your global team.',
            )}
            cta={
              <Button
                size="small"
                variant="secondary"
                onClick={() => {
                  navigate(
                    `/${Experience.COMPANY}/${contractOnboardingRoutes.root}`,
                  );
                }}
              >
                {t('banner.hire', 'Hire & Onboard')}
              </Button>
            }
          />
        )}
      </Table>
      <DeleteDialog />
      <CancelOffboardingDialog />
      <LegacyCancelOffboardingDialog />
      <LegacyRescheduleOffboardingDialog />
      <OnboardingChecklistPanel />
      <Outlet />
    </Module.Layout>
  );
};

export default TeamViewRoot;
