import * as React from 'react';

import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  FieldPath,
  Merge,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

import { ComboBox, DatePicker, TextInput } from '@multiplier/common';
import { TFunction } from 'i18next';

import { BankDetailsFormParams } from 'contract-onboarding/member/pages/onboarding/pages/bank-details';

import { AllowedValue, Maybe, RequirementField } from '__generated__/graphql';

interface ErrorType {
  bankData?: Merge<
    FieldError,
    (
      | Merge<
          FieldError,
          FieldErrorsImpl<{ key: string; value: string; type: string }>
        >
      | undefined
    )[]
  >;
  accountName?: FieldError | undefined;
  accountNumber?: FieldError | undefined;
  bankName?: FieldError | undefined;
  branchName?: FieldError | undefined;
  localBankCode?: FieldError | undefined;
  swiftCode?: FieldError | undefined;
}
interface DynamicInputProps {
  t: TFunction<string[]> | TFunction<'contract-onboarding.member'>;
  index: number;
  field: Maybe<RequirementField>;
  register: UseFormRegister<BankDetailsFormParams>;
  control: Control<BankDetailsFormParams>;
  errors: ErrorType;
  registerOptions: RegisterOptions<BankDetailsFormParams>;
  disabled?: boolean;
}

const DynamicInput: React.FC<DynamicInputProps> = ({
  t,
  index,
  field,
  register,
  control,
  errors,
  registerOptions,
  disabled = false,
}) => {
  switch (field?.type) {
    case 'text':
      return (
        <>
          <TextInput
            data-testid="input-text"
            id={field.key ?? ''}
            placeholder={t(
              `bank-details.${field?.label}.placeholder`,
              `Add ${(field?.label ?? '').toLowerCase()}` ?? '',
            )}
            {...register(
              `bankData.${index}.value` as FieldPath<BankDetailsFormParams>,
              registerOptions,
            )}
            error={!!errors?.bankData?.[index]?.value?.message}
            disabled={disabled}
          />
          {errors.bankData?.[index] && (
            <TextInput.Error tw="flex flex-row mt-base">
              {errors.bankData[index]?.value?.message}
            </TextInput.Error>
          )}
        </>
      );
    // Commented out temporarily to convert radio to select
    // case 'radio':
    //   return (
    //     <div tw="grid grid-cols-2 gap-y-16">
    //       {field &&
    //         field?.allowedValues?.map((radioFields: Maybe<AllowedValue>) => (
    //           <Radio
    //             key={radioFields?.key ?? ''}
    //             value={radioFields?.value ?? ''}
    //             labelStyles={tw`bg-white`}
    //             {...register(
    //               `bankData.${index}.value` as FieldPath<BankDetailsFormParams>,
    //             )}
    //             onChange={(e) => {
    //               register(
    //                 `bankData.${index}.value` as FieldPath<BankDetailsFormParams>,
    //               ).onChange(e);
    //             }}
    //           >
    //             {t('definition', radioFields?.value ?? '')}
    //           </Radio>
    //         ))}
    //     </div>
    //   );
    case 'radio':
    case 'select': {
      const allowedValues = field && field?.allowedValues;
      return (
        <>
          <Controller
            name={`bankData.${index}.value` as FieldPath<BankDetailsFormParams>}
            control={control}
            rules={registerOptions}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid={`${field?.key}-dropdown`}
                id="frequency-select"
                variant={
                  Object.values(allowedValues ?? '')?.length > 10
                    ? 'autocomplete'
                    : 'default'
                }
                value={value as string}
                placeholder={t(
                  `bank-details.${field?.label}.placeholder`,
                  'Select',
                )}
                dropdownValues={Object.values(allowedValues ?? '').map(
                  (
                    dropdownValue: Maybe<AllowedValue>,
                    _index,
                    originalArray,
                  ) => ({
                    title:
                      originalArray?.length <= 10
                        ? dropdownValue?.key || ''
                        : undefined,
                    value: dropdownValue?.value || '',
                    label: dropdownValue?.key || '',
                  }),
                )}
                onChange={onChange}
                error={!!errors?.bankData?.[index]}
                disabled={disabled}
              />
            )}
          />
          {errors.bankData?.[index] && (
            <TextInput.Error tw="flex flex-row mt-base">
              {errors.bankData[index]?.value?.message}
            </TextInput.Error>
          )}
        </>
      );
    }
    case 'date':
      return (
        <Controller
          name={`bankData.${index}.value` as FieldPath<BankDetailsFormParams>}
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              data-testid="date-picker"
              id="date-select"
              value={value as string}
              placeholder={t(
                `bank-details.${field?.label}.placeholder`,
                'Select date',
              )}
              onChange={(val) => {
                if (val === '') onChange(undefined);
                else onChange(val);
              }}
              disabled={disabled}
            />
          )}
        />
      );
    default:
      return null;
  }
};

export default DynamicInput;
