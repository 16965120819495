/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, CardSeparator, Icon } from '@multiplier/common';
import { theme } from 'twin.macro';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  useCompanyMsaRequestMutation,
  useGetCompanyMsaRequirementsQuery,
} from '__generated__/graphql';

enum ActivationRequirementsRowType {
  LEGAL_NAME = 'LEGAL_NAME',
  REGISTRATION_NUMBER = 'REGISTRATION_NUMBER',
  ADDRESS = 'ADDRESS',
}

const ActivationRequirementRow: React.FC<{
  type: ActivationRequirementsRowType;
  done: boolean;
}> = ({ type, done }) => {
  const { t } = useTranslation('contract-onboarding.company');

  const typeMap = {
    [ActivationRequirementsRowType.LEGAL_NAME]: {
      icon: (
        <Icon
          tw="h-[13px] w-[13px]"
          name="pencil"
          fill="transparent"
          stroke={theme`colors.primary`}
        />
      ),
      description: t(
        'onboarding-phase.activation-card.request-msa-form.legal-name.label',
        'Legal name of the organization',
      ),
    },
    [ActivationRequirementsRowType.REGISTRATION_NUMBER]: {
      icon: (
        <Icon tw="h-[18px] w-16" name="file" fill={theme`colors.primary`} />
      ),
      description: t(
        'onboarding-phase.activation-card.request-msa-form.registration-number.label',
        'Business registration number',
      ),
    },
    [ActivationRequirementsRowType.ADDRESS]: {
      icon: (
        <Icon tw="h-[20px] w-[16px]" name="mail" fill={theme`colors.primary`} />
      ),
      description: t(
        'onboarding-phase.activation-card.request-msa-form.address.label',
        'Address',
      ),
    },
  };

  return (
    <div tw="flex flex-row items-center justify-between px-16">
      <div tw="flex flex-row items-center gap-x-32">
        {typeMap[type].icon}
        <div tw="flex flex-col">
          <span>{typeMap[type].description}</span>
          {!done && (
            <span tw="text-grey01">
              {t(
                'onboarding-phase.activation-card.request-msa-form.not-provided',
                'Not provided',
              )}
            </span>
          )}
        </div>
      </div>
      {done && (
        <div tw="flex flex-row items-center gap-x-16">
          <Icon
            tw="w-[13px] h-[9px]"
            name="check"
            fill={theme`colors.primary`}
          />
          <span tw="text-primary">
            {t(
              'onboarding-phase.activation-card.request-msa-form.added',
              'Added',
            )}
          </span>
        </div>
      )}
    </div>
  );
};

const RequestMsaCard: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');

  const {
    data,
    loading: loadingMsaRequirements,
  } = useGetCompanyMsaRequirementsQuery();

  const [
    requestMsa,
    { loading: loadingRequestMsa },
  ] = useCompanyMsaRequestMutation({
    onError: () => {
      errorNotification(
        '',
        t('onboarding-phase.request-msa.failed', 'Failed to request MSA'),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t(
          'onboarding-phase.request-msa.success',
          'Successfully requested for an MSA. A sales representative may reach out to you for more details.',
        ),
        false,
      );
    },
  });

  return (
    <div tw="p-24 text-background text-ps flex flex-col gap-y-24">
      <div tw="flex flex-col gap-y-24 pb-24">
        <span tw="font-semibold">
          {t(
            'onboarding-phase.activation-card.request-msa-form.header',
            'Sign Master Service Agreement (MSA) before you can onboard and activate your employee on Multiplier.',
          )}
        </span>
        <span>
          {t(
            'onboarding-phase.activation-card.request-msa-form.description',
            'To create your MSA you must provide the following information:',
          )}
        </span>
      </div>
      {!loadingMsaRequirements && (
        <div tw="flex flex-col gap-y-32">
          <ActivationRequirementRow
            type={ActivationRequirementsRowType.LEGAL_NAME}
            done={!!data?.company?.primaryEntity?.legalName}
          />
          <CardSeparator />
          <ActivationRequirementRow
            type={ActivationRequirementsRowType.REGISTRATION_NUMBER}
            done={!!data?.company?.primaryEntity?.registrationNo}
          />
          <CardSeparator />
          <ActivationRequirementRow
            type={ActivationRequirementsRowType.ADDRESS}
            done={!!data?.company?.primaryEntity?.address}
          />
          <CardSeparator />
        </div>
      )}
      <div tw="flex flex-row items-center gap-x-40">
        <span>
          {t(
            'onboarding-phase.activation-card.request-msa-form.footer',
            'Reach out to us and one of our sales representatives will get in touch with you as soon as possible to help you with an MSA.',
          )}
        </span>
        <Button
          tw="flex-shrink-0"
          variant="default"
          size="medium"
          loading={loadingMsaRequirements || loadingRequestMsa}
          onClick={() => requestMsa()}
        >
          {t(
            'onboarding-phase.activation-card.request-msa-form.submit',
            'Request MSA',
          )}
        </Button>
      </div>
    </div>
  );
};

export default RequestMsaCard;
