import { gql } from '@apollo/client';

export default gql`
  mutation UploadCustomContract($id: ID!, $file: Upload) {
    contractAgreementCustomUpload(contractId: $id, file: $file) {
      id
      compliance {
        ...compliance
      }
      onboarding {
        status
        steps
        current
      }
    }
  }
`;
