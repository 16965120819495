import { addMonths, format } from 'date-fns';

export enum CutoffInterval {
  StartDateBeforeCutoffDate,
  StartDateAfterCutoffDateButBefore20th,
  StartDateAfter20thOfMonth,
  TodayAfterCutoffDateOfJoiningMonth,
}

export interface CutoffDateParameter {
  joiningYear: number;
  joiningMonthCutoffDate: number;
  joiningMonth: string;
  joiningDate: number;
  payrollMonthCutoffDate: number;
  payrollMonth: string;
  payrollYear: number;
  subsequentMonthCutoffDate: number;
  subsequentMonth: string;
  subsequentYear: number;
}

export interface GetCutoffIntervalResponse {
  interval: CutoffInterval;
  params: CutoffDateParameter;
}

// cutoff date of December is 5th
const payrollCutoffDay = (month: number): number => (month === 11 ? 5 : 15);

export const joiningCutoffDate = 20;

export const getCutoffInterval = (
  contractStartOn: Date,
): GetCutoffIntervalResponse => {
  const today = new Date();
  const nextMonth = addMonths(contractStartOn, 1);

  const joiningMonth = contractStartOn.getMonth();
  const joiningDayOfMonth = contractStartOn.getDate();
  const joiningYear = contractStartOn.getFullYear();

  const isJoiningThisMonth = joiningMonth === today.getMonth();
  const isTodayAfterThisMonthsCutoffDate =
    today.getDate() > payrollCutoffDay(today.getMonth());

  const CutoffDateParameters = {
    joiningDate: joiningDayOfMonth,
    joiningMonthCutoffDate: payrollCutoffDay(contractStartOn.getMonth()),
    joiningMonth: format(contractStartOn, 'MMMM'),
    joiningYear,
    subsequentMonthCutoffDate: payrollCutoffDay(nextMonth.getMonth()),
    subsequentMonth: format(nextMonth, 'MMMM'),
    subsequentYear: nextMonth.getFullYear(),
  };

  const getPayrollMonthParams = (month: Date) => ({
    payrollMonthCutoffDate: payrollCutoffDay(month.getMonth()),
    payrollMonth: format(month, 'MMMM'),
    payrollYear: month.getFullYear(),
  });

  if (joiningDayOfMonth < payrollCutoffDay(contractStartOn.getMonth()))
    return {
      interval: CutoffInterval.StartDateBeforeCutoffDate,
      params: {
        ...CutoffDateParameters,
        ...getPayrollMonthParams(contractStartOn),
      },
    };

  if (isJoiningThisMonth && isTodayAfterThisMonthsCutoffDate)
    return {
      interval: CutoffInterval.TodayAfterCutoffDateOfJoiningMonth,
      params: {
        ...CutoffDateParameters,
        ...getPayrollMonthParams(nextMonth),
      },
    };

  if (joiningDayOfMonth > joiningCutoffDate)
    return {
      interval: CutoffInterval.StartDateAfter20thOfMonth,
      params: {
        ...CutoffDateParameters,
        ...getPayrollMonthParams(nextMonth),
      },
    };

  return {
    interval: CutoffInterval.StartDateAfterCutoffDateButBefore20th,
    params: {
      ...CutoffDateParameters,
      ...getPayrollMonthParams(nextMonth),
    },
  };
};
