/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Icon } from '@multiplier/common';
import isNaN from 'lodash/isNaN';
import { theme } from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import InsuranceDetails from 'insurance/components/details';
import {
  InsuranceFeature,
  featureLabels,
} from 'insurance/components/details/types';
import ERSplit from 'insurance/components/er-split';
import InsuranceMandatoryCallout from 'insurance/components/insurance-mandatory-callout';
import InsuranceNotes from 'insurance/components/insurance-notes';
import InsuranceTypePartnerName from 'insurance/components/insurance-type-partner-name';

import {
  Benefit,
  BenefitFeatures,
  BenefitPartnerCountry,
  BenefitType,
  Contract,
  ContractBenefit,
  ContractType,
  CountryCode,
  Maybe,
} from '__generated__/graphql';

import { InsuranceSectionVariant } from '.';
import DependentsSection from './dependents-section';

const FormData: React.FC<{
  variant: InsuranceSectionVariant;
  features: (BenefitFeatures | null)[] | null | undefined;
  isFormEditing: boolean;
  benefitPartnerCountryData: BenefitPartnerCountry | undefined;
  setBenefitType: (type: BenefitType) => void;
  benefitType?: Maybe<BenefitType>;
  contractBenefit: ContractBenefit | null | undefined;
  selectedBenefit: Benefit | null | undefined;
  showDependents: boolean;
  benefits: Maybe<Maybe<ContractBenefit>[]> | undefined;
  country: Maybe<CountryCode> | undefined;
  type: BenefitType;
  contractType: Contract['type'];
}> = ({
  variant,
  features,
  isFormEditing = false,
  benefitPartnerCountryData,
  setBenefitType,
  benefitType,
  contractBenefit,
  selectedBenefit,
  showDependents,
  benefits,
  country,
  type,
  contractType,
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const shouldShowERSplit =
    benefitPartnerCountryData?.erSplitApplicable && selectedBenefit?.id;

  useEffect(() => {
    setValue(
      'employerPayPercentage',
      String(contractBenefit?.employerPayPercentage ?? '100'),
    );
  }, [contractBenefit?.employeePayPercentage]);

  const showInsurancePartnerAndType =
    benefitPartnerCountryData?.benefitsDomain &&
    benefitPartnerCountryData.partnerName;

  const shouldShowMandatoryInsuranceCallout =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    contractType !== ContractType.CONTRACTOR;

  return (
    <>
      {variant === InsuranceSectionVariant.MEMBER ? (
        <div tw="flex flex-row items-center justify-between">
          {features?.filter(notEmpty).map((feature) => (
            <div tw="flex flex-row items-center gap-x-16" key={feature?.key}>
              <div tw="flex justify-center place-items-center rounded-full w-32 h-32 bg-primary bg-opacity-15">
                <Icon
                  data-testid="check-icon"
                  tw="w-[15px]"
                  name="check"
                  fill={theme`colors.primary`}
                />
              </div>
              <span tw="text-background text-ps">
                {feature?.key &&
                  featureLabels[feature.key as InsuranceFeature].label}
              </span>
            </div>
          ))}
        </div>
      ) : (
        benefitPartnerCountryData?.benefits &&
        (isFormEditing ? (
          <>
            {shouldShowMandatoryInsuranceCallout ? (
              <InsuranceMandatoryCallout country={country} />
            ) : null}
            {showInsurancePartnerAndType ? (
              <div tw="w-full flex gap-x-80">
                <InsuranceTypePartnerName
                  insuranceType={benefitPartnerCountryData?.benefitsDomain}
                  insurancePartnerName={benefitPartnerCountryData?.partnerName}
                />
              </div>
            ) : null}
            <Controller
              control={control}
              name="benefitId"
              render={() => (
                <InsuranceDetails
                  availableBenefits={benefitPartnerCountryData?.benefits}
                  variant="tablet"
                  country={country}
                  selectable
                  selectedValue={watch('benefitId')}
                  onChange={(selectedValue) => {
                    setValue('benefitId', selectedValue, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  onTypeChange={(selectedType) => {
                    setBenefitType(selectedType as BenefitType);
                  }}
                  benefitType={benefitType}
                  benefitsByType={
                    (benefitPartnerCountryData?.benefits ?? [])?.filter(
                      (item) => item?.type === type,
                    ) as Benefit[]
                  }
                />
              )}
            />
            {showDependents && (
              <DependentsSection
                benefits={benefits}
                isFormEditing
                cost={selectedBenefit?.cost}
                currency={selectedBenefit?.currency}
                billingDuration={benefitPartnerCountryData?.billingDuration}
                frequency={selectedBenefit?.frequency}
              />
            )}
            <InsuranceNotes
              refundPolicy={benefitPartnerCountryData?.refundPolicy}
              platformFee={benefitPartnerCountryData?.platformFee}
              platformFeeApplicable={
                benefitPartnerCountryData?.platformFeeApplicable
              }
              billingDuration={benefitPartnerCountryData?.billingDuration}
              billingCurrency={benefitPartnerCountryData?.billingCurrency}
              contractType={contractType}
            />
            {shouldShowERSplit && (
              <>
                <div tw="h-[1px] bg-slateGrey200 w-full my-24" />
                <Controller
                  control={control}
                  name="employerPayPercentage"
                  render={({ field: { value: currEmployerPayPercentage } }) => {
                    const processedEmployerPayPercentage =
                      currEmployerPayPercentage &&
                      !isNaN(Number(currEmployerPayPercentage))
                        ? Number(currEmployerPayPercentage)
                        : 0;

                    const employeePercentage = Math.abs(
                      100 - processedEmployerPayPercentage,
                    );

                    return (
                      <ERSplit
                        onChange={(value: string) => {
                          if (value?.length > 2 && Number(value) > 100) return;
                          setValue(
                            'employerPayPercentage',
                            value === '' ? '0' : value,
                            {
                              shouldValidate: true,
                            },
                          );
                        }}
                        type={type}
                        country={country}
                        billingCurrency={selectedBenefit?.currency}
                        frequency={selectedBenefit?.frequency}
                        cost={selectedBenefit?.cost}
                        employeePayPercentage={employeePercentage}
                        employerPayPercentage={processedEmployerPayPercentage}
                        error={errors?.employerPayPercentage?.message as string}
                        minimumErSplitPercentage={
                          benefitPartnerCountryData?.minimumErSplitPercentage
                        }
                        platformFee={benefitPartnerCountryData?.platformFee}
                        platformFeeApplicable={
                          benefitPartnerCountryData?.platformFeeApplicable
                        }
                      />
                    );
                  }}
                />
              </>
            )}
          </>
        ) : (
          <>
            {shouldShowMandatoryInsuranceCallout ? (
              <InsuranceMandatoryCallout country={country} />
            ) : null}
            {showInsurancePartnerAndType ? (
              <div tw="w-full flex gap-x-80">
                <InsuranceTypePartnerName
                  insuranceType={benefitPartnerCountryData?.benefitsDomain}
                  insurancePartnerName={benefitPartnerCountryData?.partnerName}
                />
              </div>
            ) : null}
            <InsuranceDetails
              availableBenefits={benefitPartnerCountryData?.benefits}
              variant="tablet"
              country={country}
              showSelected
              selectedValue={selectedBenefit?.id}
              onTypeChange={(selectedType) => {
                setBenefitType(selectedType as BenefitType);
              }}
              benefitType={benefitType}
              benefitsByType={
                (benefitPartnerCountryData?.benefits ?? [])?.filter(
                  (item) => item?.type === type,
                ) as Benefit[]
              }
            />
            {showDependents && (
              <DependentsSection
                benefits={benefits}
                cost={selectedBenefit?.cost}
                currency={selectedBenefit?.currency}
                billingDuration={benefitPartnerCountryData?.billingDuration}
                frequency={selectedBenefit?.frequency}
              />
            )}
            <div tw="h-[1px] bg-slateGrey200 w-full" />
            <InsuranceNotes
              refundPolicy={benefitPartnerCountryData?.refundPolicy}
              platformFee={benefitPartnerCountryData?.platformFee}
              platformFeeApplicable={
                benefitPartnerCountryData?.platformFeeApplicable
              }
              billingDuration={benefitPartnerCountryData?.billingDuration}
              billingCurrency={benefitPartnerCountryData?.billingCurrency}
              contractType={contractType}
            />
            {shouldShowERSplit ? (
              <>
                <div tw="h-[1px] bg-slateGrey200 w-full" />
                <ERSplit
                  type={type}
                  country={country}
                  cost={selectedBenefit?.cost}
                  billingCurrency={benefitPartnerCountryData?.billingCurrency}
                  onChange={(value: string) => {
                    if (value?.length > 2 && Number(value) > 100) return;
                    setValue(
                      'employerPayPercentage',
                      value === '' ? '0' : value,
                      {
                        shouldValidate: true,
                      },
                    );
                  }}
                  employeePayPercentage={contractBenefit?.employeePayPercentage}
                  frequency={selectedBenefit?.frequency}
                  employerPayPercentage={contractBenefit?.employerPayPercentage}
                  minimumErSplitPercentage={
                    benefitPartnerCountryData?.minimumErSplitPercentage
                  }
                  platformFee={benefitPartnerCountryData?.platformFee}
                  platformFeeApplicable={
                    benefitPartnerCountryData?.platformFeeApplicable
                  }
                  readOnly
                />{' '}
              </>
            ) : null}
          </>
        ))
      )}
    </>
  );
};

export default FormData;
