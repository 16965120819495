import { gql } from '@apollo/client';

export default gql`
  query GetCompany {
    company {
      id
      displayName
      companyLogo {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
          blob
        }
      }
      status
      financialYear
      primaryEntity {
        id
        legalName
        registrationNo
        address {
          line1
          line2
          city
          zipcode
          country
        }
        phone
      }
      contracts(status: null) {
        id
      }
      managers {
        ...companyUser
      }
      signatories {
        ...companyUser
      }
      billingContact {
        ...companyUser
      }
      approvers {
        id
        categoryContracts {
          category
          managedContracts {
            id
          }
        }
        approverUser {
          ...companyUser
          ... on Member {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on OperationsUser {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on EmergencyPointOfContact {
            id
            firstName
            lastName
            emails {
              email
            }
          }
        }
      }
      offerings {
        offering
        status
      }
    }
  }
  fragment companyUser on CompanyUser {
    id
    userId
    firstName
    lastName
    emails {
      email
    }
    title
    role
    roles
    isSignatory
    isBillingContact
    status
  }
`;
