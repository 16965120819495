import React from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton } from '@multiplier/common';
import tw, { css, styled, theme } from 'twin.macro';

import Modal from 'common/modal';

import { Maybe, Product, ProductDetail } from '__generated__/graphql';

const ModalContainer = tw.div`
  flex flex-col p-24
`;

const ModalHeader = tw.div`
  flex flex-row justify-between items-center text-center bg-background-primary px-large py-small
`;

const ModalTitle = tw.span`
  text-h6 text-text-primary font-semibold
`;

const ProductDetailsContainer = tw.div`
  flex flex-row gap-x-large
`;
const ImageContainer = tw.div`
  flex justify-center items-center w-[250px] h-[250px] bg-background-primary flex-shrink-0
`;
const Image = tw.img`
  max-w-[205px] max-h-[205px]
`;

const ProductInfo = tw.div`
  flex flex-col gap-y-large
`;
const ProductTitle = tw.div`
  flex text-h6 text-text-primary font-semibold
`;
const ProductDescription = tw.div`
  flex text-ps font-normal text-text-secondary
`;
const ProductSpecificationContainer = tw.div`
  flex flex-col mt-large
`;

const ProductSpecificationRow = styled.div(
  ({ backgroundColor }: { backgroundColor?: string }) => [
    tw`flex flex-row w-full py-base bg-background-primary px-extra-large`,
    backgroundColor &&
      css`
        background-color: ${backgroundColor};
      `,
  ],
);

const SpecificationKey = tw.div`
    w-1/2 text-ps font-normal text-text-primary
  `;
const SpecificationValue = tw(SpecificationKey)``;

const Title = tw.span`
    text-ps text-text-primary font-semibold
  `;

interface ProductDetailsModalProps {
  openModal: boolean;
  closeModal: () => void;
  product?: Omit<Product, 'sku' | 'countryCode' | 'status'>;
}

const ProductDetailsModal: React.FC<ProductDetailsModalProps> = ({
  product,
  openModal,
  closeModal,
}) => {
  const { t } = useTranslation('equipment.company');

  return (
    <Modal
      tw="w-[812px] p-none h-auto rounded-base pb-extra-large overflow-hidden max-h-[85%] overflow-y-auto"
      open={openModal}
      onClose={closeModal}
      data-testid="modal"
      header={
        <ModalHeader>
          <ModalTitle>
            {t('product-modal.modal-title', 'Product Details')}
          </ModalTitle>
          <IconButton size="medium" name="cross" onClick={closeModal} />
        </ModalHeader>
      }
    >
      <ModalContainer>
        <ProductDetailsContainer>
          <ImageContainer>
            <Image src={product?.images?.[0]?.url ?? ''} alt="laptop-image" />
          </ImageContainer>
          <ProductInfo>
            <ProductTitle>{product?.name ?? ''}</ProductTitle>
            <ProductDescription>
              {product?.description ?? ''}
            </ProductDescription>
          </ProductInfo>
        </ProductDetailsContainer>

        <ProductSpecificationContainer>
          <ProductSpecificationRow>
            <Title>{t('product-modal.specification', 'Specification')}</Title>
          </ProductSpecificationRow>
          {product?.dynamicDetails?.[0]?.details?.map(
            (details: Maybe<ProductDetail>, index: number) => (
              <ProductSpecificationRow
                key={details?.key}
                backgroundColor={
                  index % 2 === 0
                    ? theme`colors.background-white`
                    : theme`colors.background-primary`
                }
              >
                <SpecificationKey>{details?.key}</SpecificationKey>
                <SpecificationValue>{details?.value}</SpecificationValue>
              </ProductSpecificationRow>
            ),
          )}
        </ProductSpecificationContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ProductDetailsModal;
