/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ComboBox,
  Dialog,
  DropdownValue,
  Icon,
  Loader,
  SpinnerType,
  TextInput,
} from '@multiplier/common';
import tw from 'twin.macro';
import * as yup from 'yup';

import {
  convertDropdownValueToWorkShiftOption,
  convertWorkShiftOptionToDropdownValue,
  getDefaultWorkShiftFromCountryWorkShiftStandards,
  getWeekDayLabel,
  getWorkShiftDropdownValues,
  isValidTimeString,
  weekDays,
} from 'contract-onboarding/company/services/work-shift-utils';

import {
  Contract,
  CountryWorkShiftStandards,
  DayOfWeek,
  GetCountryLabourStandardsQuery,
  WorkShiftInput,
} from '__generated__/graphql';

import { useGetCountryLabourStandards } from '../../../../hooks';

const AverageWorkingHoursInfo: React.FC<{
  workingHoursInfoText: CountryWorkShiftStandards['workingHoursInfoText'];
}> = ({ workingHoursInfoText }) => {
  if (workingHoursInfoText)
    return (
      <div tw="flex items-center gap-x-base p-base rounded-base bg-background-brand-faded text-text-primary">
        <Icon name="circle-warning" />
        <p>{workingHoursInfoText}</p>
      </div>
    );
  return null;
};

const WorkShiftForm: React.FC<{
  defaultWorkShift: CountryWorkShiftStandards['defaultWorkingHours'];
}> = ({ defaultWorkShift }) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { control, setValue } = useFormContext<WorkShiftInput>();

  const weekDayDropdownValues: DropdownValue[] = useMemo(
    () =>
      weekDays.map((day) => ({
        title: getWeekDayLabel(day, t),
        label: getWeekDayLabel(day, t),
        value: day,
      })),
    [t, weekDays],
  );

  const workShiftDropdownValues: DropdownValue[] = useMemo(
    () => getWorkShiftDropdownValues(defaultWorkShift, t),
    [t, defaultWorkShift],
  );

  const setWorkingHours = useCallback(
    (val: string) => {
      if (val) {
        const { startTime, endTime } = convertDropdownValueToWorkShiftOption(
          val,
        );
        setValue('workingHours.startTime', startTime, {
          shouldValidate: true,
        });
        setValue('workingHours.endTime', endTime, {
          shouldValidate: true,
        });
      }
    },
    [setValue],
  );

  return (
    <div tw="mt-base grid grid-cols-2 mobile:(grid-cols-1) gap-base">
      <div tw="grid grid-cols-2 gap-x-small">
        <TextInput.Container>
          <TextInput.Label>
            {t('wor-kshift.dialog.form.from-date.label', 'From')}
          </TextInput.Label>
          <Controller
            name="startDay"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                variant="default"
                data-testid="select-from-day"
                dropdownValues={weekDayDropdownValues}
                value={value ?? ''}
                onChange={onChange}
                placeholder={t(
                  'work-shift.dialog.form.from-date.placeholder',
                  'Select a day',
                )}
              />
            )}
          />
        </TextInput.Container>
        <TextInput.Container>
          <TextInput.Label>
            {t('work-shift.dialog.form.to-date.label', 'To')}
          </TextInput.Label>
          <Controller
            name="endDay"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                variant="default"
                data-testid="select-to-day"
                dropdownValues={weekDayDropdownValues}
                value={value ?? ''}
                onChange={onChange}
                placeholder={t(
                  'work-shift.dialog.form.to-date.placeholder',
                  'Select a day',
                )}
              />
            )}
          />
        </TextInput.Container>
      </div>
      <TextInput.Container>
        <TextInput.Label>
          {t('work-shift.dialog.form.working-hours.label', 'Working Hours')}
          &nbsp;
          <span tw="font-normal">
            {t(
              'work-shift.dialog.form.working-hours.label-description',
              '(including 1 hour lunch break)',
            )}
          </span>
        </TextInput.Label>
        <Controller
          name="workingHours"
          control={control}
          render={({ field: { value } }) => (
            <ComboBox
              variant="default"
              data-testid="select-shift"
              dropdownValues={workShiftDropdownValues}
              value={convertWorkShiftOptionToDropdownValue(value)}
              onChange={setWorkingHours}
              placeholder={t(
                'work-shift.dialog.form.to-date.placeholder',
                'Select a day',
              )}
            />
          )}
        />
      </TextInput.Container>
    </div>
  );
};

const WorkShiftInputDialog: React.FC<{
  show: boolean;
  onClose: () => void;
  labourStandards?: GetCountryLabourStandardsQuery;
  onSave: (workShiftInput: WorkShiftInput) => void;
  workShift?: WorkShiftInput;
  loading?: boolean;
}> = ({
  show = false,
  onClose,
  labourStandards,
  onSave,
  workShift,
  loading = false,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const formProps = useForm<WorkShiftInput>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        endDay: yup.string().oneOf(Object.values(DayOfWeek)).required(),
        startDay: yup.string().oneOf(Object.values(DayOfWeek)).required(),
        workingHours: yup.object().shape({
          endTime: yup.string().required().test(isValidTimeString),
          startTime: yup.string().required().test(isValidTimeString),
        }),
      }),
    ),
  });
  const saveWorkShift = () => {
    if (formProps?.formState?.isValid) {
      onSave(formProps?.getValues());
      onClose();
    }
  };

  useEffect(() => {
    if (workShift) {
      formProps.reset(workShift);
    } else {
      formProps.reset(
        getDefaultWorkShiftFromCountryWorkShiftStandards(
          labourStandards?.country?.labourStandards?.workShift,
        ),
      );
    }
  }, [workShift, labourStandards]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      onConfirm={saveWorkShift}
      disabled={!formProps?.formState?.isValid || loading}
      buttonText={t('work-shift.dialog.confirm-button', 'Save')}
      title={t('work-shift.dialog.title', 'Working Hours (Optional)')}
      containerStyle={tw`overflow-visible max-w-[720px] w-full`}
      btnWrapperStyle={tw`justify-center gap-x-base`}
    >
      {loading ? (
        <Loader.Spinner tw="h-96" variant={SpinnerType.CUSTOM_LAYOUT} />
      ) : (
        <>
          <AverageWorkingHoursInfo
            workingHoursInfoText={
              labourStandards?.country?.labourStandards?.workShift
                ?.workingHoursInfoText
            }
          />
          <FormProvider {...formProps}>
            <WorkShiftForm
              defaultWorkShift={
                labourStandards?.country?.labourStandards?.workShift
                  ?.defaultWorkingHours
              }
            />
          </FormProvider>
        </>
      )}
    </Dialog>
  );
};

const WorkShiftInputDialogDialog: React.FC<{
  show: boolean;
  onClose: () => void;
  country: Contract['country'];
  workShift?: WorkShiftInput;
  onSave: (workShiftInput: WorkShiftInput) => void;
  countryStateCode: Contract['countryStateCode'];
}> = ({ show, onClose, country, workShift, onSave, countryStateCode }) => {
  const { data, loading } = useGetCountryLabourStandards({
    country,
    countryStateCode,
  });

  return (
    <WorkShiftInputDialog
      show={show}
      onClose={onClose}
      labourStandards={data}
      onSave={onSave}
      workShift={workShift}
      loading={loading}
    />
  );
};

export default WorkShiftInputDialogDialog;
