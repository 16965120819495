import { useMemo } from 'react';

import { useFeature } from '@growthbook/growthbook-react';
import { AppFeature } from '@multiplier/growthbook';

import { SorOnboardingContext } from 'types';

import { ContractType } from '__generated__/graphql';

import useHasGpCapabilities from './has-gp-capabilities';
import useIsHRISOfferingEnabled from './is-hris-offering-enabled';

const useSorOnboardingContext = ({
  type,
  companyId,
  legalEntityId,
}: {
  type: ContractType | undefined | null;
  companyId?: string | undefined | null;
  legalEntityId: string | undefined | null;
}): SorOnboardingContext => {
  const isSorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;
  const isHrisClient = useIsHRISOfferingEnabled();
  const hasGpCapability = useHasGpCapabilities(companyId, legalEntityId);

  const isSupportedTypesForHris =
    type === ContractType.HR_MEMBER || type === ContractType.EMPLOYEE;

  return useMemo(
    () => ({
      isSorOnboardingEnabled,
      isSupportedTypesForHris,
      isHrisEnabled:
        isSorOnboardingEnabled && isHrisClient && isSupportedTypesForHris,
      isGpEnabled:
        isSorOnboardingEnabled &&
        hasGpCapability &&
        type === ContractType.HR_MEMBER,
      isGlobalPayrollOnlyFlow:
        isSorOnboardingEnabled &&
        type === ContractType.HR_MEMBER &&
        hasGpCapability &&
        !isHrisClient,
      isHrisOnlyFlow:
        isSorOnboardingEnabled &&
        type === ContractType.HR_MEMBER &&
        isHrisClient &&
        !hasGpCapability,
      isGlobalPayrollAndHrisFlow:
        isSorOnboardingEnabled &&
        type === ContractType.HR_MEMBER &&
        hasGpCapability &&
        isHrisClient,
      isGlobalPayrollFlows:
        isSorOnboardingEnabled &&
        type === ContractType.HR_MEMBER &&
        hasGpCapability,
      isEORAndHrisFlow:
        isSorOnboardingEnabled &&
        type === ContractType.EMPLOYEE &&
        isHrisClient,
    }),
    [isSorOnboardingEnabled, isHrisClient, hasGpCapability, type],
  );
};

export default useSorOnboardingContext;
