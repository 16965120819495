/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import 'twin.macro';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';

import { Button } from '@multiplier/common';
import * as Sentry from '@sentry/react';
import * as FileSaver from 'file-saver';

import { errorNotification } from 'app/services/notification-services';
import DocumentViewer from 'common/components/document-viewer';
import Loader from 'common/components/loader';
import { isChunkError } from 'error-code-mapping';

import Error, { ErrorVariant } from '../../../common/components/error';
import i18n from '../../../i18n';

enum categoryName {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  BRONZE = 'BRONZE',
  COMPARE_PLANS = 'Compare Plans',
}

const categoryLabels = {
  [categoryName.GOLD]: i18n.t('insurance:modal.header.name.gold', 'Gold'),
  [categoryName.SILVER]: i18n.t('insurance:modal.header.name.silver', 'Silver'),
  [categoryName.BRONZE]: i18n.t('insurance:modal.header.name.bronze', 'Bronze'),
  [categoryName.COMPARE_PLANS]: i18n.t(
    'insurance:modal.header.name.compare',
    'Compare Plans',
  ),
};

const InsuranceModal: React.FC<{
  open: boolean;
  onClose: () => void;
  handleCtaClick: () => void;
  header?: string;
  factsheetPath: string;
  loading?: boolean;
}> = ({ open, onClose, header, factsheetPath }) => {
  const { t } = useTranslation('insurance');
  const [pageCount, setPageCount] = useState<number | null>(null);

  const handleDownloadClick = () => {
    if (factsheetPath) {
      FileSaver.saveAs(
        factsheetPath,
        `${categoryLabels[header as categoryName]} Factsheet.pdf`,
      );
    } else {
      errorNotification(
        t(
          'insurance-factsheet-viewer.download-insurance-factsheet.failed.title',
          'Failed to download Insurance Factsheet.',
        ),
        '',
        false,
      );
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        window.dispatchEvent(new window.Event('appOutdated'));
        const isChunkLoadError = isChunkError(error);
        if (isChunkLoadError) return <Error variant={ErrorVariant.UPDATED} />;
        Sentry.captureException(error);
        return <span />;
      }}
    >
      <DocumentViewer
        open={open}
        onClose={onClose}
        header={
          categoryLabels[header as categoryName] ??
          t('insurance-factsheet-viewer.header', 'Insurance')
        }
        primaryButton={
          <Button
            variant="default"
            size="medium"
            onClick={factsheetPath ? handleDownloadClick : undefined}
          >
            {t('insurance-factsheet-viewer.factsheet-download', {
              defaultValue: 'Download {{type}} Factsheet',
              type:
                header === 'Compare Plans'
                  ? ''
                  : categoryLabels[header as categoryName],
            })}
          </Button>
        }
        loading={false}
      >
        {factsheetPath && (
          <Document
            file={factsheetPath}
            onLoadSuccess={({ numPages }) => setPageCount(numPages)}
            loading={
              <Loader.Spinner variant={Loader.SpinnerType.DOCUMENT_VIEWER} />
            }
          >
            {Array.from(new Array(pageCount), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={809}
              />
            ))}
          </Document>
        )}
      </DocumentViewer>
    </ErrorBoundary>
  );
};

export default InsuranceModal;
