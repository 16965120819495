import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Checkbox,
  CheckboxContainer,
  CheckboxLabel,
  Dialog,
  Icon,
  Loader,
  Radio,
  SpinnerType,
  TableCell,
  TextInput,
} from '@multiplier/common';
import { isEmpty } from 'lodash';
import tw, { theme } from 'twin.macro';

import { Contract } from '../../__generated__/graphql';
import { getAvatarDisplay, getMemberFullname } from '../../utils/common';

type Employee = Contract & { disabled?: boolean };
export interface Props {
  data: Employee[]; // add disabled property to disable checkbox
  onChange?: (employee: Contract) => void;
  renderEmployeeItem?: (employee: Contract) => React.ReactNode;
  value?: Contract | Contract[];
  loading?: boolean;
  mode?: 'single' | 'multiple' | 'view';
}

const EmployeeItem: React.FC<{ employee: Contract }> = ({ employee }) => {
  const { member } = employee;
  const avatarDisplay = getAvatarDisplay(member);
  const fullName = getMemberFullname(member);

  return (
    <div tw="flex items-center gap-base">
      <Avatar
        name={avatarDisplay ?? ''}
        displayChars={2}
        circle
        greyBackground
        size="extraSmall"
        tw="shrink-0"
      />
      <div tw="flex flex-col">
        <TableCell.Text
          tw="text-ps text-text-primary"
          maxLength={30}
          text={fullName}
        />
        <TableCell.Text
          tw="text-pxs text-text-secondary"
          maxLength={30}
          text={employee.position}
        />
      </div>
    </div>
  );
};

const EmployeeList: React.FC<Props> = ({
  data = [],
  onChange,
  value,
  loading = false,
  mode = 'view',
  renderEmployeeItem,
  ...props
}) => {
  const { t } = useTranslation('hris.common');
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeList, setEmployeeList] = useState<
    (Employee & { isSelected: boolean })[]
  >([]);
  const showSearchBox = data.length > 5;

  const filteredEmployees = useMemo(
    () =>
      employeeList.filter((item) => {
        const fullName = `${item?.member?.firstName ?? ''} ${
          item?.member?.lastName ?? ''
        }`.trim();

        return fullName.toLowerCase().includes(searchQuery.toLowerCase());
      }),
    [employeeList, searchQuery],
  );

  const isSingleMode = mode === 'single';

  const isViewMode = mode === 'view';

  const isSelect = useCallback(
    (employeeId?: string) => {
      if (!isSingleMode) {
        return (value as Contract[])?.some?.(
          (item) => item?.member?.userId === employeeId,
        );
      }

      return employeeId === (value as Contract)?.member?.userId;
    },
    [value, isSingleMode],
  );

  const renderEmployee = (employee: Contract) => {
    // custom render
    if (typeof renderEmployeeItem === 'function')
      return renderEmployeeItem(employee);
    return <EmployeeItem employee={employee} />;
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const updatedData = data
        .map((item) => ({
          ...item,
          isSelected: isSelect(item?.member?.userId as string),
        }))
        .sort((a, b) => {
          // Prioritize isSelected
          if (a.isSelected !== b.isSelected) {
            return a.isSelected ? -1 : 1;
          }

          // Compare alphabetically when isSelected is the same
          const nameA = a?.member?.firstName?.toLowerCase() || '';
          const nameB = b?.member?.firstName?.toLowerCase() || '';
          return nameA.localeCompare(nameB);
        });

      setEmployeeList(updatedData);
    }
  }, [data]);

  return (
    <div
      tw="rounded-tiny border border-border-secondary bg-background-primary flex flex-col max-h-[500px] min-h-[420px] overflow-auto"
      {...props}
    >
      {showSearchBox && (
        <TextInput
          divStyles={tw`sticky top-none p-extra-small bg-background-primary z-10`}
          prepend={<Icon name="search" fill={theme`colors.icon-secondary`} />}
          placeholder={t('employee-list.search-input', 'Search')}
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
        />
      )}
      {loading ? (
        <Loader.Spinner variant={SpinnerType.CUSTOM_LAYOUT} />
      ) : (
        <>
          {filteredEmployees.length ? (
            filteredEmployees.map((employee) => (
              <div tw="py-small px-large" key={employee.member?.userId}>
                {isViewMode ? (
                  <EmployeeItem employee={employee} />
                ) : isSingleMode ? (
                  // Single Mode Radio
                  <Radio
                    checked={isSelect(employee?.member?.userId as string)}
                    borderlessButton
                    onChange={() => {
                      if (typeof onChange === 'function') {
                        onChange(employee);
                      }
                    }}
                    disabled={employee?.disabled}
                  >
                    {renderEmployee(employee)}
                  </Radio>
                ) : (
                  // Multiple Mode checkbox
                  <CheckboxContainer
                    css={[employee?.disabled && tw`opacity-60`]}
                  >
                    <Checkbox
                      id={`employee_${employee?.member?.userId}`}
                      data-testid={`employee_${employee?.member?.userId}`}
                      checked={isSelect(employee?.member?.userId as string)}
                      onChange={() => {
                        if (typeof onChange === 'function') {
                          onChange(employee);
                        }
                      }}
                      tw="disabled:bg-current"
                      disabled={employee?.disabled}
                    />
                    <CheckboxLabel
                      htmlFor={`employee_${employee?.member?.userId}`}
                    >
                      {renderEmployee(employee)}
                    </CheckboxLabel>
                  </CheckboxContainer>
                )}
              </div>
            ))
          ) : (
            <p tw="text-ps text-center p-large text-text-secondary">
              {t('employee-list.empty-data', 'No employees found')}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export const EmployeeListDialog: React.FC<
  Props & {
    title?: string;
    show: boolean;
    onClose: () => void;
  }
> = ({ show, onClose, title, ...props }) => {
  const { t } = useTranslation('hris.common');

  return (
    <Dialog.BaseDialog
      title={
        title ??
        t('employee-list-dialog.title', 'Selected employees: {{ total }}', {
          replace: {
            total: props.data?.length,
          },
        })
      }
      onClose={onClose}
      open={show}
      headerStyle={tw`bg-background-white mt-extra-small text-text-primary`}
      containerStyle={tw`w-[400px]`}
      hideButtons
    >
      <EmployeeList {...props} />
    </Dialog.BaseDialog>
  );
};

export default EmployeeList;
