/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';
import { Trans, useTranslation } from 'react-i18next';

import countryLabels from 'common/constants/country-labels';

import {
  CountryCode,
  useGetEmployeeCountryPricingQuery,
} from '__generated__/graphql';

const PlatformFee: React.FC<{
  country: CountryCode;
}> = ({ country }) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { data } = useGetEmployeeCountryPricingQuery({
    variables: {
      country,
    },
  });

  return (
    <div data-testid="platform-fee" tw="bg-white rounded-10 padding[21px 24px]">
      <h3 tw="font-semibold text-background">
        {t(
          'definition-phase.compensation.platform-fee.header',
          'Multiplier Platform Fee',
        )}
      </h3>
      <div tw="text-grey01 mt-12">
        <Trans t={t} i18nKey="compensation.platform-fee">
          For {{ countryLabel: countryLabels[country] }}, a{' '}
          <span tw="font-semibold text-background">
            USD {{ fee: data?.employeePricingForCountry?.[0]?.fixedRate }}
          </span>{' '}
          / month charge will be applied for the employee in your monthly
          invoice.
        </Trans>
      </div>
    </div>
  );
};

export default PlatformFee;
