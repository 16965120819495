/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import tw from 'twin.macro';

import ComboBox, { DropdownValue } from 'common/components/combo-box';
import DatePicker from 'common/components/date-picker';
import * as DropdownText from 'common/components/dropdown-text';
import Radio from 'common/components/radio';
import TextInput from 'common/components/text-input';
import countryIDD from 'common/constants/country-idd';
import { getMemberDataRequirementValues } from 'contract-onboarding/company/pages/definition-phase/pages/basic-details/helpers';

import {
  Contract,
  CountryCode,
  DataFieldDefinition,
  DateField,
  DropDownField,
  DropDownTextField,
  LegalDocumentRequirement,
  Maybe,
  Member,
  MemberChangeRequest,
  useGetCountryStatesLazyQuery,
} from '__generated__/graphql';

import { notEmpty } from '../../../app/utils/array';
import countryLabels from '../../../common/constants/country-labels';
import {
  countryWithStateList,
  getStateLabel,
  getStateTitle,
} from '../../company/services/eligibility-states';
import { haveSubmittedChangeRequestItem } from '../../services/change-request';
import { getFormattedValue } from '../../services/legal-data';
import { BasicDetailsFormValues } from '../basic-details-section';
import DetailRow from '../detail-row';
import { DetailRowType } from '../detail-row/types';

const PreDataRequirementsSection: React.FC<{
  requirements: {
    memberLegalDataRequirements: { [p: string]: DataFieldDefinition };
    memberDocumentRequirements:
      | Maybe<Array<Maybe<LegalDocumentRequirement>>>
      | undefined;
  };
  member: Contract['member'];
  isEditing: boolean;
  changeRequest?: Maybe<MemberChangeRequest>;
  changeRequestItemsByKey?: {
    legalData: Member['legalData'];
  };
}> = ({
  requirements: { memberLegalDataRequirements },
  member,
  isEditing,
  changeRequest,
  changeRequestItemsByKey,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useFormContext<BasicDetailsFormValues>();

  const [
    getCountryStates,
    { data: stateData },
  ] = useGetCountryStatesLazyQuery();

  const preDataRequirements = watch('legalData');

  const addressCountry = useMemo(
    () =>
      preDataRequirements?.filter((req) => req?.key === 'address.country')?.[0]
        ?.value,
    [JSON.stringify(preDataRequirements)],
  );

  useEffect(() => {
    if (addressCountry) {
      getCountryStates({
        variables: { country: addressCountry as CountryCode },
      }).then();
    }

    if (!countryWithStateList.includes(addressCountry as CountryCode)) {
      preDataRequirements?.forEach((req, idx) =>
        req?.key === 'address.state'
          ? setValue(`legalData.${idx}.value`, null)
          : null,
      );
    }
  }, [addressCountry]);

  useEffect(() => {
    const addressCity = preDataRequirements?.filter(
      (req) => req?.key === 'address.city',
    )?.[0]?.value;

    if (addressCity && addressCountry === CountryCode.CHN) {
      preDataRequirements?.forEach((req, idx) =>
        req?.key === 'address.state'
          ? setValue(
              `legalData.${idx}.value`,
              (addressCity as string).split('/')[1],
            )
          : null,
      );
    }
  }, [JSON.stringify(preDataRequirements)]);

  const allCountriesDropdownValues = useMemo(
    () =>
      Object.values(CountryCode).map((c) => ({
        label: countryLabels[c],
        value: c,
        title: c,
      })),
    [],
  );

  const stateDropdownValues = useMemo(
    () =>
      (stateData?.country?.countryStates &&
        stateData.country.countryStates.filter(notEmpty).map((c) => ({
          label: getStateLabel(stateData?.country?.code, c),
          value: c?.code ?? '',
          title: getStateTitle(stateData?.country?.code, c),
          key: c?.code ?? '',
        }))) ??
      [],
    [stateData],
  );

  return (
    <>
      {Object.values(memberLegalDataRequirements).map((legalData, index) => {
        const formValue = getMemberDataRequirementValues(legalData, member);

        const formIdentifier = legalData.label;

        const dependsOnKey = legalData?.dependsOn?.[0]?.key;
        const dependsOnValue = watch('legalData')?.filter(
          (req) => req?.key === dependsOnKey,
        )?.[0]?.value;
        const isAddressStateRequired =
          legalData?.dataType?.__typename === 'AutoCompleteField' &&
          legalData?.dataType?.optionListType === 'STATE' &&
          dependsOnKey === 'address.country' &&
          countryWithStateList.includes(dependsOnValue as CountryCode);

        if (dependsOnKey && !isAddressStateRequired) return null;

        return (
          <React.Fragment key={legalData?.key}>
            {legalData?.key && legalData?.key !== 'dateOfBirth' && (
              <DetailRow
                id={legalData?.key}
                label={
                  formIdentifier
                    ? t(`legal-details.${formIdentifier}.label`, formIdentifier)
                    : t(
                        `legal-details.${legalData?.label}.label`,
                        legalData?.label ?? '',
                      )
                }
                isEditing={isEditing}
                type={
                  haveSubmittedChangeRequestItem(changeRequest)
                    ? DetailRowType.COMPARE_MODE
                    : DetailRowType.BASIC_MODE
                }
                value={getFormattedValue(formValue, legalData, t)}
                newValue={getFormattedValue(
                  changeRequestItemsByKey?.legalData?.find(
                    (field) => field?.key === legalData.key,
                  )?.value,
                  legalData,
                  t,
                )}
              >
                <TextInput.Container key={legalData?.key ?? ''} tw="col-span-3">
                  {legalData.dataType?.__typename === 'DateField' &&
                    legalData?.key !== 'dateOfBirth' && (
                      <Controller
                        control={control}
                        name={`legalData.${index}.value`}
                        defaultValue={formValue}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            tw="w-1/2"
                            data-testid={String(legalData?.key)}
                            id="date-input"
                            value={value}
                            onChange={(val) => {
                              if (val === '') onChange(undefined);
                              else onChange(val);
                            }}
                            min={
                              (legalData.dataType as DateField).minDate ??
                              undefined
                            }
                            max={
                              (legalData.dataType as DateField).maxDate ??
                              undefined
                            }
                            error={!!errors?.legalData?.[index]?.value}
                            helperText={
                              errors?.legalData?.[index]?.value?.message
                            }
                            loading={false}
                          />
                        )}
                      />
                    )}
                  {legalData.dataType?.__typename === 'DropDownField' && (
                    <Controller
                      control={control}
                      name={`legalData.${index}.value`}
                      defaultValue={formValue}
                      render={({ field: { value, onChange } }) => (
                        <ComboBox
                          variant="default"
                          tw="w-1/2"
                          value={value ?? ''}
                          onChange={onChange}
                          data-testid={legalData.key}
                          placeholder={t(
                            `legal-details.${legalData.label}.placeholder`,
                            legalData.label ?? '',
                          )}
                          dropdownValues={
                            ((legalData.dataType as DropDownField).values?.map(
                              (dropdownValue) => ({
                                title: t(
                                  `legal-details.${
                                    legalData.label
                                  }.option.${dropdownValue?.toLowerCase()}`,
                                  dropdownValue ?? '',
                                ),
                                value: dropdownValue,
                              }),
                            ) as DropdownValue[]) ?? []
                          }
                        />
                      )}
                    />
                  )}
                  {legalData.dataType?.__typename === 'AutoCompleteField' &&
                    legalData.dataType?.optionListType === 'COUNTRY' && (
                      <Controller
                        control={control}
                        name={`legalData.${index}.value`}
                        render={({ field: { value, onChange } }) => (
                          <ComboBox
                            variant="autocomplete"
                            tw="w-1/2"
                            value={String(value)}
                            onChange={onChange}
                            data-testid={legalData.key}
                            placeholder={t(
                              `legal-details.${legalData.label}.placeholder`,
                              String(legalData.label),
                            )}
                            dropdownValues={allCountriesDropdownValues}
                          />
                        )}
                      />
                    )}
                  {isAddressStateRequired && (
                    <Controller
                      control={control}
                      name={`legalData.${index}.value`}
                      render={({ field: { value, onChange } }) => (
                        <ComboBox
                          variant="autocomplete"
                          tw="w-1/2"
                          value={String(value)}
                          onChange={onChange}
                          data-testid={legalData.key}
                          placeholder={t(
                            `legal-details.${legalData.label}.placeholder`,
                            String(legalData.label),
                          )}
                          showTitleOnButton={addressCountry === CountryCode.CHN}
                          dropdownValues={stateDropdownValues}
                        />
                      )}
                    />
                  )}
                  {legalData.dataType?.__typename === 'TextField' && (
                    <TextFieldRenderer
                      dataField={legalData}
                      index={index}
                      initialValue={formValue}
                    />
                  )}
                  {legalData.dataType?.__typename === 'DropDownTextField' && (
                    <div tw="flex flex-row gap-x-extra-small">
                      <Controller
                        control={control}
                        name={`legalData.${index}.description`}
                        render={({ field: { value, onChange } }) => (
                          <ComboBox
                            variant="default"
                            tw="h-6"
                            value={value ?? ''}
                            onChange={onChange}
                            data-testid={legalData.key}
                            placeholder={t(
                              `legal-details.${legalData.label}.placeholder`,
                              'Select from List',
                            )}
                            dropdownValues={
                              ((legalData.dataType as DropDownTextField).values?.map(
                                (dropdownValue) => ({
                                  title: t(
                                    `legal-details.${
                                      legalData.label
                                    }.option.${dropdownValue?.toLowerCase()}`,
                                    dropdownValue ?? '',
                                  ),
                                  value: dropdownValue,
                                }),
                              ) as DropdownValue[]) ?? []
                            }
                          />
                        )}
                      />
                      <TextInput
                        divStyles={tw`flex-grow`}
                        id={legalData.key ?? ''}
                        data-testid={legalData.key ?? ''}
                        defaultValue={formIdentifier ?? formValue ?? ''}
                        placeholder={t(
                          `legal-details.${legalData.key}.placeholder`,
                          'Add Number',
                        )}
                        {...register(`legalData.${index}.value`)}
                        onChange={(e) => {
                          register(`legalData.${index}.value`).onChange(e);
                          trigger(`legalData.${index}`);
                        }}
                        error={!!errors?.legalData?.[index]?.value?.message}
                      />
                    </div>
                  )}
                  {legalData.dataType?.__typename === 'CheckboxField' && (
                    <div tw="flex flex-row gap-x-base">
                      <Radio
                        id={legalData.key ?? ''}
                        labelStyles={tw`py-small flex-grow`}
                        value="true"
                        {...register(`legalData.${index}.value`)}
                      >
                        {t('legal-details.checkbox.yes', 'Yes')}
                      </Radio>
                      <Radio
                        id={legalData.key ?? ''}
                        labelStyles={tw`py-small flex-grow`}
                        value="false"
                        {...register(`legalData.${index}.value`)}
                      >
                        {t('legal-details.checkbox.no', 'No')}
                      </Radio>
                    </div>
                  )}
                  {errors.legalData?.[index] && (
                    <TextInput.Error tw="mb-base">
                      {errors.legalData[index]?.value?.message}
                    </TextInput.Error>
                  )}
                </TextInput.Container>
              </DetailRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default PreDataRequirementsSection;

const TextFieldRenderer: React.FC<{
  dataField: DataFieldDefinition;
  index: number;
  initialValue: Maybe<string> | undefined;
}> = ({ dataField, index, initialValue }) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    trigger,
    register,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  return dataField.key === 'phoneNumber' ? (
    <PhoneNumberFieldRenderer
      dataField={dataField}
      index={index}
      initialValue={initialValue || ''}
    />
  ) : (
    <TextInput
      id={dataField.key ?? ''}
      data-testid={dataField.key ?? ''}
      defaultValue={initialValue ?? ''}
      placeholder={t(
        `legal-details.${dataField.label}.number-placeholder`,
        'XXXXXX',
      )}
      {...register(`legalData.${index}.value`)}
      onChange={(e) => {
        register(`legalData.${index}.value`)
          .onChange(e)
          .then(() => trigger(`legalData.${index}`));
      }}
      error={!!errors?.legalData?.[index]?.value?.message}
    />
  );
};

export const PhoneNumberFieldRenderer: React.FC<{
  dataField: DataFieldDefinition;
  index: number;
  initialValue: string;
}> = ({ dataField, index, initialValue }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    formState: { errors },
    setValue,
  } = useFormContext<BasicDetailsFormValues>();

  const phoneCodeDropdownValues = useMemo(
    () =>
      Object.entries(countryIDD).map((c) => ({
        key: `${c[1]}-${c[0]}`,
        icon: <Icon.Flag name={c[0].toLowerCase()} />,
        title: c[1],
        value: c[0],
        label: countryLabels[c[0]],
      })),
    [],
  );

  const [phoneNoParts, setPhoneNoParts] = useState({
    countryPhoneCode:
      Object.keys(countryIDD).find(
        (k) =>
          countryIDD[k] ===
          initialValue.substring(0, initialValue.indexOf(' ')),
      ) || '',
    phoneNumber: initialValue.substring(initialValue.indexOf(' ') + 1),
  });

  const setPhoneNumber = useCallback(
    (phonePartKey: keyof typeof phoneNoParts, newValue: string) => {
      setPhoneNoParts((oldPhoneNoParts) => {
        const newPhoneNoParts = {
          ...oldPhoneNoParts,
          [phonePartKey]: newValue,
        };

        if (
          newPhoneNoParts.countryPhoneCode === '' ||
          newPhoneNoParts.phoneNumber === ''
        )
          setValue(`legalData.${index}.value`, '', {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        else
          setValue(
            `legalData.${index}.value`,
            `${countryIDD[newPhoneNoParts.countryPhoneCode]} ${
              newPhoneNoParts.phoneNumber
            }`,
            { shouldValidate: true, shouldDirty: true, shouldTouch: true },
          );

        return newPhoneNoParts;
      });
    },
    [],
  );

  return (
    <DropdownText.Container>
      <ComboBox
        data-testid="select-phone-country-code"
        variant="inline"
        showArrow
        placeholder="+XXX"
        value={phoneNoParts.countryPhoneCode}
        dropdownValues={phoneCodeDropdownValues}
        error={
          phoneNoParts.countryPhoneCode === '' &&
          !!errors?.legalData?.[index]?.value
        }
        onChange={(value) => setPhoneNumber('countryPhoneCode', value)}
      />
      <DropdownText.Input
        id={String(dataField.key)}
        placeholder={t('basic-details.phone-number-placeholder', '000000000')}
        value={phoneNoParts.phoneNumber}
        error={
          phoneNoParts.phoneNumber === '' && !!errors?.legalData?.[index]?.value
        }
        onChange={(e) => setPhoneNumber('phoneNumber', e.target.value)}
        divStyles={tw`grow`}
      />
    </DropdownText.Container>
  );
};
