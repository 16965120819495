import { getAuthHeaders } from '@multiplier/user';

import localStorageService from './local-storage-service';

export const getHeaders = (): { [key: string]: string } => ({
  'Content-Type': 'application/json',
});

export const getAuthHeader = (): { [key: string]: string } => {
  const token = localStorageService.getRawItem('jwt_token');
  return getAuthHeaders(token as string);
};
