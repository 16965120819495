/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import { addMonths } from 'date-fns';

import { Religion } from 'common/constants/default-labels';
import AdditionalPaysTable from 'contract-onboarding/company/components/additional-pays-table';
import Esop from 'contract-onboarding/company/components/esop';
import PlatformFee from 'contract-onboarding/company/components/platform-fee';
import { useSubmitDefinitionCompensation } from 'contract-onboarding/company/hooks';
import {
  convertBasePayToMonthlyPay,
  getCurrencyOptions,
  isMonthEnabledForAdditionalPay,
} from 'contract-onboarding/company/services/compensation';
import { freelancerCompensationSchema } from 'contract-onboarding/company/services/compensation-schema';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';
import { useAdditionalCompensationsMapper } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Company,
  Compensation,
  Contract,
  ContractTerm,
  ContractType,
  CountryCode,
  CurrencyCode,
  Member,
  RateFrequency,
  useGetCountryLocalCurrencyLazyQuery,
} from '__generated__/graphql';

import stepConfig from '../../../step-config';
import BillingRateSection from './billing-rate';
import { mapToCompensationForm } from './common/compensation-form-mapper';
import CompensationOptionsSection from './compensation-options';

export const FreelancerCompensationView: React.FC<
  OnboardingStepProps & {
    compliantCurrencies: (CurrencyCode | null | undefined)[];
    contractTerm?: ContractTerm | null;
    contractType?: ContractType | null;
    country?: CountryCode | null;
    compensation?: Compensation | null;
    contractId?: string | null;
    company?: Company | null;
    member?: Member | null;
    showPricing: boolean;
    startOn?: string;
    countryStateCode: Contract['countryStateCode'];
    legalEntityId: Contract['legalEntityId'];
  }
> = ({
  currentStep,
  onboardingSteps,
  compliantCurrencies,
  contractTerm,
  contractType,
  country,
  compensation,
  contractId,
  company,
  member,
  showPricing,
  startOn,
  countryStateCode,
  legalEntityId,
}) => {
  const { t: tCommon } = useTranslation('contract-onboarding.common');
  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  const sorOnboardingContext = useSorOnboardingContext({
    type: contractType,
    companyId: company?.id,
    legalEntityId,
  });

  const { id } = useParams<{ id?: string }>();
  const { onSubmit, loading } = useSubmitDefinitionCompensation(
    contractId,
    false,
    true,
    contractType,
  );

  const [
    getLocalCurrency,
    { data: countryLocalCurrency },
  ] = useGetCountryLocalCurrencyLazyQuery();

  useEffect(() => {
    if (country) {
      getLocalCurrency({ variables: { country } });
    }
  }, [country]);

  const currencyOptions = useMemo(
    () => getCurrencyOptions(compliantCurrencies),
    [compliantCurrencies],
  );

  const mapperParams = useMemo(
    () => ({
      contract: {
        country,
        countryStateCode,
        type: contractType,
      } as Contract,
    }),
    [country, countryStateCode, contractType],
  );

  const { mapToAdditionalPayFormValues } = useAdditionalCompensationsMapper(
    mapperParams,
  );

  const schema = useMemo(
    () =>
      freelancerCompensationSchema({
        t: tCommon,
        contractType,
        contractTerm,
        country,
        compliantCurrencies,
        currencyOptions,
        isAllowanceFeature: isNotFreelancer,
        sorOnboardingContext,
      }),
    [contractType, contractTerm, compliantCurrencies, currencyOptions],
  );

  const methods = useForm<CompensationFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id && compensation && compensation.basePay) {
      methods.reset(
        mapToCompensationForm(contractType, compensation, compliantCurrencies, {
          mapToAdditionalPayFormValues,
        }),
      );
    }
  }, [compensation, mapToAdditionalPayFormValues, compliantCurrencies]);

  const baseCurrency = methods.watch('basePay.currency');

  const enableCurrentMonth = useMemo<boolean>(
    () => isMonthEnabledForAdditionalPay(startOn ?? ''),
    [compensation, startOn],
  );

  const currencyOptionsForAdditionalPays = useMemo(
    () =>
      currencyOptions.filter(
        (selectedCurrency) => selectedCurrency.value === baseCurrency,
      ),
    [currencyOptions, baseCurrency],
  );

  return (
    <StepLayout data-testid="compensation-view">
      <FormProvider {...methods}>
        <FormLayout onSubmit={methods.handleSubmit(onSubmit)}>
          <FormCard>
            {contractType === ContractType.FREELANCER && (
              <CompensationOptionsSection />
            )}
            {compensation?.basePay && (
              <BillingRateSection
                currencyOptions={currencyOptions}
                compliantCurrencies={compliantCurrencies}
                countryLocalCurrency={
                  countryLocalCurrency?.country?.mainCurrency
                }
                contractType={contractType}
                contractId={id ?? contractId}
                startOn={startOn}
                legalEntityId={legalEntityId}
                companyId={company?.id}
              />
            )}
            <AdditionalPaysTable
              contractType={contractType}
              basePay={convertBasePayToMonthlyPay(
                methods.watch('basePay.frequency') ?? RateFrequency.MONTHLY,
                methods.watch('basePay.amount') ?? 0,
              )}
              basePayFrequency={methods.watch('basePay.frequency')}
              currency={
                methods.watch('basePay.currency') ??
                compensation?.basePay?.currency
              }
              currencyOptions={currencyOptionsForAdditionalPays}
              companyName={company?.displayName}
              religion={
                member?.legalData?.find((d) => d?.key === 'religion')
                  ?.value as Religion
              }
              edit
              countryCode={country}
              minDate={
                enableCurrentMonth
                  ? new Date(new Date(startOn ?? ''))
                  : addMonths(new Date(startOn ?? ''), 1)
              }
              countryStateCode={countryStateCode}
            />

            {contractType === ContractType.EMPLOYEE &&
              contractTerm === ContractTerm.PERMANENT && (
                <Esop defaultCurrency={CurrencyCode.USD} />
              )}
          </FormCard>
          {country && showPricing && <PlatformFee country={country} />}
          <StepNavigationFooter
            disabled={!methods.formState.isValid}
            submitLoading={loading}
            currentStep={currentStep}
            onboardingSteps={onboardingSteps}
            contractId={id}
            stepConfig={stepConfig}
          />
        </FormLayout>
      </FormProvider>
    </StepLayout>
  );
};

export default FreelancerCompensationView;
