import { SorOnboardingContext } from '@multiplier/hris-member-management';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import Lazy from 'yup/lib/Lazy';

import { DropdownValue } from 'common/components/combo-box';
import {
  AdditionalPayFormValues,
  Occurrence,
  PaymentType,
} from 'contract-onboarding/company/components/additional-pay-form';
import {
  convertBasePayToMonthlyPay,
  getFixedPayValidation,
  getProbationPayValidation,
} from 'contract-onboarding/company/services/compensation';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Contract,
  ContractType,
  CountryPaymentFrequency,
  CountryRateFrequency,
  CountryWorkShiftStandards,
  CurrencyCode,
  DeductionDefinition,
  Maybe,
  PayType,
  RateFrequency,
} from '__generated__/graphql';

import { isContractToPayrollConfigLinkingEnabled } from '../hooks/get-payroll-config';
import { DeductionFormValues, getDeductionSchema } from './deduction';

export const additionalPaySchema = (
  t: TFunction<'contract-onboarding.common'>,
  currencyOptions: DropdownValue[],
  averageWorkingHoursPerMonth?: CountryWorkShiftStandards['averageWorkingHoursPerMonth'],
  isAllowanceFeature?: boolean,
  isNewAllowancesConfigEnabled?: boolean,
): Lazy<
  yup.ArraySchema<
    SchemaOf<{
      payType: string;
      amount?: AdditionalPayFormValues['amount'];
      currency?: Maybe<string>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      frequency?: any;
      conditions?: Maybe<string>;
    }>
  >
> =>
  yup.lazy((_val, ctx) => {
    const baseMonthlyPay = convertBasePayToMonthlyPay(
      ctx.parent?.basePay?.frequency ?? RateFrequency.MONTHLY,
      ctx.parent?.basePay?.amount ?? 0,
      averageWorkingHoursPerMonth ?? undefined,
    );
    return yup.array().of(
      yup.object().shape({
        payType: yup.string().required().oneOf(Object.values(PaymentType)),
        amount: yup.number().when('payInInstallments', {
          is: true,
          then: yup
            .number()
            .nullable()
            .transform((_, val) => (val === '' ? null : Number(val)))
            .notRequired(),
          otherwise: yup
            .number()
            .nullable()
            .transform((_, val) => (val === '' ? null : Number(val)))
            .notRequired()
            .when('payType', {
              is: (payType: PaymentType) =>
                payType === PaymentType.THR_BONUS ||
                payType === PaymentType.THIRTEENTH_MONTH_BONUS,
              then: yup
                .number()
                .min(
                  baseMonthlyPay,
                  t(
                    'definition-phase.compensation.additional-pay.should-be-greater-than-base-pay',
                    'should be greater than min pay',
                  ),
                ),
            })
            .when('payType', {
              is: (payType: PaymentType) =>
                payType === PaymentType.JOINING_BONUS ||
                payType === PaymentType.ALLOWANCES,
              then: yup.number().moreThan(0),
            }),
        }),
        currency: yup
          .string()
          .notRequired()
          .nullable()
          .when('payType', {
            is: (payType: PaymentType) =>
              [
                PaymentType.JOINING_BONUS,
                PaymentType.ALLOWANCES,
                PaymentType.THR_BONUS,
                PaymentType.THIRTEENTH_MONTH_BONUS,
              ].includes(payType),
            then: yup
              .string()
              .oneOf(
                currencyOptions ? currencyOptions.map((v) => v.value) : [],
              ),
          }),
        frequency: isAllowanceFeature
          ? yup.string().required().oneOf(Object.values(RateFrequency))
          : yup
              .number()
              .nullable()
              .notRequired()
              .when(['payType', 'occurrence'], {
                is: (payType: PaymentType, occurrence: Occurrence) => {
                  if (
                    payType === PaymentType.VARIABLE_PERFORMANCE_BONUS ||
                    payType === PaymentType.ALLOWANCES
                  )
                    return true;
                  if (
                    payType === PaymentType.OTHER &&
                    occurrence === Occurrence.RECURRING
                  )
                    return true;
                  return false;
                },
                then: yup.number().integer().moreThan(0),
              }),
        conditions: yup.string().nullable(),
        payoutMonth: yup.object().when('payInInstallments', {
          is: true,
          then: yup.object().nullable().notRequired(),
          otherwise: yup
            .object()
            .nullable()
            .notRequired()
            .when('payType', {
              is: (payType: PaymentType) =>
                [
                  PaymentType.ALLOWANCES,
                  PaymentType.JOINING_BONUS,
                  PaymentType.VARIABLE_PERFORMANCE_BONUS,
                  PaymentType.OTHER,
                ].includes(payType) && !isNewAllowancesConfigEnabled,
              then: yup.object().shape({
                month: yup.number().required(),
                year: yup.number().required(),
              }),
            }),
        }),
      }),
    );
  });

export const freelancerCompensationSchema = ({
  t,
  contractType,
  contractTerm,
  country,
  compliantCurrencies,
  currencyOptions,
  isContractOnboardingHourlyPayEnabled,
  countryRateFrequencyList,
  countryPaymentFrequencyList,
  averageWorkingHoursPerMonth,
  isAllowanceFeature,
  sorOnboardingContext,
}: {
  t: TFunction<'contract-onboarding.common'>;
  contractType: Contract['type'];
  contractTerm: Contract['term'];
  country: Contract['country'];
  compliantCurrencies: (Maybe<CurrencyCode> | undefined)[];
  currencyOptions: DropdownValue[];
  isContractOnboardingHourlyPayEnabled?: boolean;
  countryRateFrequencyList?: CountryRateFrequency['list'];
  countryPaymentFrequencyList?: CountryPaymentFrequency['list'];
  averageWorkingHoursPerMonth?: CountryWorkShiftStandards['averageWorkingHoursPerMonth'];
  isPostProbationToggleOn?: boolean;
  isAllowanceFeature?: boolean;
  sorOnboardingContext: SorOnboardingContext | undefined;
}): SchemaOf<{
  basePay: {
    name?: string;
    amount?: number | null;
    currency?: string;
    frequency?: string;
    rateType?: string;
    firstPayoutDate?: string;
  };
  additional?: CompensationFormValues['additional'];
  payrollStart?: CompensationFormValues['payrollStart'];
  payType?: string | null;
  payrollConfigId: string | null | undefined;
}> =>
  yup.object().shape({
    basePay: getFixedPayValidation({
      contractType,
      compliantCurrencies,
      contractTerm,
      country,
      isContractOnboardingHourlyPayEnabled,
      countryRateFrequencyList,
      countryPaymentFrequencyList,
    }),
    additional: yup
      .string()
      .nullable()
      .max(
        250,
        t(
          'definition-phase.compensation.additional.max',
          'Additional Details cannot exceed 200 characters',
        ),
      ),
    payrollStart: yup.date().nullable(),
    payType: yup
      .string()
      .test('pay-type-test', (value?: string) =>
        contractType === ContractType.FREELANCER
          ? yup.string().oneOf(Object.values(PayType)).required().isValid(value)
          : true,
      )
      .nullable(),
    additionalPays: additionalPaySchema(
      t,
      currencyOptions,
      averageWorkingHoursPerMonth,
      isAllowanceFeature,
    ),
    payrollConfigId: isContractToPayrollConfigLinkingEnabled(
      contractType,
      sorOnboardingContext,
    )
      ? yup
          .string()
          .required(
            t(
              'definition-phase.compensation.payroll-config-id.required',
              'There is no payroll config matched with the selected payroll frequency',
            ),
          )
      : yup.string().nullable().optional(),
  });

export default ({
  t,
  contractType,
  contractTerm,
  contractWorkStatus,
  country,
  compliantCurrencies,
  currencyOptions,
  isContractOnboardingHourlyPayEnabled,
  countryRateFrequencyList,
  countryPaymentFrequencyList,
  averageWorkingHoursPerMonth,
  isPostProbationToggleOn,
  isAllowanceFeature,
  deductionDefinitions,
  isNewAllowancesConfigEnabled,
  sorOnboardingContext,
}: {
  t: TFunction<'contract-onboarding.common'>;
  contractType: Contract['type'];
  contractTerm: Contract['term'];
  contractWorkStatus: Contract['workStatus'];
  country: Contract['country'];
  compliantCurrencies: (Maybe<CurrencyCode> | undefined)[];
  currencyOptions: DropdownValue[];
  isContractOnboardingHourlyPayEnabled?: boolean;
  countryRateFrequencyList?: CountryRateFrequency['list'];
  countryPaymentFrequencyList?: CountryPaymentFrequency['list'];
  averageWorkingHoursPerMonth?: CountryWorkShiftStandards['averageWorkingHoursPerMonth'];
  isPostProbationToggleOn?: boolean;
  isAllowanceFeature?: boolean;
  deductionDefinitions?: Maybe<DeductionDefinition[]>;
  isNewAllowancesConfigEnabled?: boolean;
  sorOnboardingContext: SorOnboardingContext | undefined;
}): SchemaOf<{
  basePay: {
    name?: string;
    amount?: number | null;
    currency?: string;
    frequency?: string;
    rateType?: string;
    firstPayoutDate?: string;
  };
  probationBasePay: {
    name?: string;
    amount?: number | null;
    currency?: string;
    frequency?: string;
    rateType?: string;
    firstPayoutDate?: string;
  };
  additional?: CompensationFormValues['additional'];
  payrollStart?: CompensationFormValues['payrollStart'];
  payType?: string | null;
  deductions?: DeductionFormValues;
  payrollConfigId: string | null | undefined;
}> =>
  yup.object().shape({
    basePay: getFixedPayValidation({
      contractType,
      contractWorkStatus,
      compliantCurrencies,
      contractTerm,
      country,
      isContractOnboardingHourlyPayEnabled,
      countryRateFrequencyList,
      countryPaymentFrequencyList,
    }),
    probationBasePay: getProbationPayValidation({
      contractType,
      compliantCurrencies,
      contractTerm,
      country,
      isContractOnboardingHourlyPayEnabled,
      countryRateFrequencyList,
      countryPaymentFrequencyList,
      isPostProbationToggleOn,
    }),
    additional: yup
      .string()
      .nullable()
      .max(
        250,
        t(
          'definition-phase.compensation.additional.max',
          'Additional Details cannot exceed 200 characters',
        ),
      ),
    payrollStart: yup.date().nullable(),
    payType: yup
      .string()
      .test('pay-type-test', (value?: string) =>
        contractType === ContractType.FREELANCER
          ? yup.string().oneOf(Object.values(PayType)).required().isValid(value)
          : true,
      )
      .nullable(),
    additionalPays: additionalPaySchema(
      t,
      currencyOptions,
      averageWorkingHoursPerMonth,
      isAllowanceFeature,
      isNewAllowancesConfigEnabled,
    ),
    deductions: getDeductionSchema(deductionDefinitions),
    payrollConfigId: isContractToPayrollConfigLinkingEnabled(
      contractType,
      sorOnboardingContext,
    )
      ? yup
          .string()
          .required(
            t(
              'definition-phase.compensation.payroll-config-id.required',
              'There is no payroll config matched with the selected payroll frequency',
            ),
          )
      : yup.string().nullable().optional(),
  });
