import { useState } from 'react';

import {
  ExpandedState,
  PaginationState,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';

const useTableState = ({
  defaultPageSize = 5,
}: { defaultPageSize?: number } = {}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [expanded, setExpanded] = useState<ExpandedState>({});

  return {
    sorting,
    setSorting,
    pagination,
    setPagination,
    rowSelection,
    setRowSelection,
    expanded,
    setExpanded,
  };
};

export default useTableState;
