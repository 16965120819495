import { gql } from '@apollo/client';

export default gql`
  mutation SSOInitiate($identityProvider: String) {
    ssoInitiate(identityProvider: $identityProvider)
      @rest(
        type: "SSOInitiateResponse"
        method: "GET"
        path: "sso/{args.identityProvider}/initiate"
        endpoint: "user"
      ) {
      url
    }
  }
`;
