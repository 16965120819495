/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { ThemeContext } from '@multiplier/common';
import tw, { css, styled } from 'twin.macro';

import Radio from 'common/components/radio';
import ProductDetailsButton, {
  ModalSource,
} from 'equipment/components/product-details-button';

import { Product } from '__generated__/graphql';

const MainContainer = styled.div(
  ({ isNewThemeApplied }: { isNewThemeApplied?: boolean }) => [
    tw`flex flex-row w-[263px] h-[93px] p-small mr-base bg-[#F8F9FB] relative rounded-8 shadow-[0px 4px 9px rgba(19, 42, 151, 0.15)]`,
    css`
      :hover {
        background: #f5f9ff;
      }
    `,
    isNewThemeApplied &&
      tw`bg-background-white border border-border-primary shadow hover:(bg-background-brand-faded border  border-border-high-contrast)`,
  ],
);

const LeftContainer = tw.div`
  flex h-[40px] w-[40px] bg-background-white mr-extra-small justify-center items-center min-w-[40px]
`;

const InfoContainer = tw.div`
  flex flex-col items-start
`;

const Price = tw.div`
font-normal text-p mt-tiny text-text-secondary
`;

const Image = tw.img`
  max-w-[40px] max-h-[38px]
`;

const RadioLabel = styled.label`
  ${tw`w-full`}
  ${css`
    :hover {
      cursor: pointer;
    }
  `}
`;

interface LaptopCardProps {
  item: Omit<Product, 'sku' | 'countryCode' | 'status'>;
  setSelectedLaptop: (id: string) => void;
}

const LaptopCard: React.FC<LaptopCardProps> = ({ setSelectedLaptop, item }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <MainContainer
      isNewThemeApplied={isNewThemeApplied}
      data-testid="laptop-card"
    >
      <LeftContainer>
        <Image src={item?.images?.[0]?.url ?? ''} alt="laptop-image" />
      </LeftContainer>
      <RadioLabel>
        <Radio
          key={item.id}
          name="laptop-card-radio"
          data-testid={`laptop-select-${item.id}`}
          labelStyles={tw`border-none bg-transparent p-none absolute top-[10px] right-none`}
          onChange={() => {
            setSelectedLaptop(item.id);
          }}
        />
        <InfoContainer>
          <div tw="text-p font-semibold text-text-primary leading-[20px] truncate">
            {item?.name ?? ''}
          </div>
          <Price>{`${item?.currencyCode} ${item?.price}`}</Price>
          <ProductDetailsButton
            product={item}
            source={ModalSource.LAPTOP_CARD}
          />
        </InfoContainer>
      </RadioLabel>
    </MainContainer>
  );
};

export default LaptopCard;
