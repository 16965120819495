import { LocalStorageService } from '@multiplier/browser-storage-service';

import { ACTIVE_MODULE_STORAGE_KEY, MTL_HEADER } from '../constants';

export type MtlSourceComponents = {
  domain?: string;
  functionality?: string;
};

export const setDefaulActiveModule = (module: string): void => {
  if (module) {
    LocalStorageService.setItem(ACTIVE_MODULE_STORAGE_KEY, module);
  }
};

export const generateMtlHeader = (
  experience: string,
  payload: MtlSourceComponents = {},
): Record<string, string> => {
  const headers: Record<string, string> = {};
  // eslint-disable-next-line prefer-const
  let { domain, functionality = '' } = payload;

  // In case if no domain is passed, we will use the default active module from local storage
  if (!domain) {
    const activeModule = LocalStorageService.getItem(ACTIVE_MODULE_STORAGE_KEY);
    domain = activeModule;
  }

  const headerValues = [experience, domain, functionality].filter(Boolean);

  if (headerValues.length) {
    headers[MTL_HEADER] = headerValues.join(',');
  }

  return headers;
};
