import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Object: any;
  Time: any;
  UUID: any;
  Upload: any;
  _FieldSet: any;
  join__FieldSet: any;
};

export type AchCompanyPayinMethod = CompanyPayInMethod &
  PayInMethod & {
    __typename?: 'ACHCompanyPayinMethod';
    bankDetails?: Maybe<Array<Maybe<CompanyPayInMethodBankDetails>>>;
    chargeBackPeriod?: Maybe<Scalars['Int']>;
    company?: Maybe<Company>;
    context?: Maybe<PayInMethodContext>;
    currentStatus?: Maybe<CompanyPayInMethodWorkflowStatus>;
    currentStep?: Maybe<PayInMethodDefinitionStep>;
    data?: Maybe<Array<Maybe<KeyValue>>>;
    documents?: Maybe<Array<Maybe<DocumentData>>>;
    id: Scalars['ID'];
    isActive?: Maybe<Scalars['Boolean']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    payInMethodType?: Maybe<PayInMethodType>;
    workflowSteps: Array<Maybe<CompanyPayInMethodWorkflowStep>>;
  };

export type AccessibilityResponse = {
  __typename?: 'AccessibilityResponse';
  editable?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<FieldAccessibilityRule>>;
};

export type AccountDetails = {
  __typename?: 'AccountDetails';
  data?: Maybe<Array<Maybe<KeyValue>>>;
  documents?: Maybe<Array<Maybe<DocumentData>>>;
  payInAccountRequirementType?: Maybe<Scalars['String']>;
};

export type AccountRequirementContext = {
  __typename?: 'AccountRequirementContext';
  accountType?: Maybe<PaymentAccountType>;
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  sourceCurrency?: Maybe<CurrencyCode>;
  targetCountry?: Maybe<CountryCode>;
  targetCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export type AccountRequirementInput = {
  data?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  documents?: InputMaybe<Array<InputMaybe<DocumentKeyValueInput>>>;
  payInAccountRequirementType?: InputMaybe<Scalars['String']>;
};

export enum AccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export enum Action {
  ADMIN_TERMINATE = 'ADMIN_TERMINATE',
  CUSTOMER_APPROVE_INPUT = 'CUSTOMER_APPROVE_INPUT',
  CUSTOMER_APPROVE_OUTPUT = 'CUSTOMER_APPROVE_OUTPUT',
  CUSTOMER_RECONCILE_OUTPUT = 'CUSTOMER_RECONCILE_OUTPUT',
  OPS_APPROVE_INPUT = 'OPS_APPROVE_INPUT',
  OPS_APPROVE_OUTPUT = 'OPS_APPROVE_OUTPUT',
  OPS_APPROVE_PAYSLIPS = 'OPS_APPROVE_PAYSLIPS',
  OPS_CLOSE_WORKFLOW = 'OPS_CLOSE_WORKFLOW',
  OPS_PROCESS_INPUT = 'OPS_PROCESS_INPUT',
  OPS_RECONCILE_OUTPUT = 'OPS_RECONCILE_OUTPUT',
  OPS_UPLOAD_PAYSLIPS = 'OPS_UPLOAD_PAYSLIPS',
  PARTNER_SUBMIT_OUTPUT = 'PARTNER_SUBMIT_OUTPUT',
  PARTNER_UPLOAD_OUTPUT = 'PARTNER_UPLOAD_OUTPUT',
  PLATFORM_INIT = 'PLATFORM_INIT',
  PLATFORM_LOCK_INPUT = 'PLATFORM_LOCK_INPUT',
  PLATFORM_PROCESS_INPUT = 'PLATFORM_PROCESS_INPUT',
  PLATFORM_RECONCILE_OUTPUT = 'PLATFORM_RECONCILE_OUTPUT',
  PLATFORM_VALIDATE_INPUT = 'PLATFORM_VALIDATE_INPUT',
  PLATFORM_VALIDATE_OUTPUT = 'PLATFORM_VALIDATE_OUTPUT',
  PLATFORM_VALIDATE_PAYSLIPS = 'PLATFORM_VALIDATE_PAYSLIPS',
}

export type ActivationCutoffData = {
  __typename?: 'ActivationCutoffData';
  date?: Maybe<Scalars['Date']>;
  urgency?: Maybe<Urgency>;
};

export type ActivityDocument = {
  __typename?: 'ActivityDocument';
  document?: Maybe<Document>;
  errorMessages?: Maybe<Scalars['Object']>;
  /** @deprecated Use `document` instead */
  file?: Maybe<FileLink>;
  opsUserEmail?: Maybe<Scalars['String']>;
  partnerCountryId?: Maybe<Scalars['ID']>;
  status?: Maybe<ActivityDocumentStatus>;
  type?: Maybe<ActivityType>;
};

export type ActivityDocumentFilter = {
  partnerCountryId?: InputMaybe<Scalars['String']>;
};

export enum ActivityDocumentStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum ActivityType {
  PARTNER_COUNTRY_ADD = 'PARTNER_COUNTRY_ADD',
  PARTNER_COUNTRY_UPDATE = 'PARTNER_COUNTRY_UPDATE',
}

export type AddApproverInfoInput = {
  approverInfo?: InputMaybe<Array<InputMaybe<ApproverInfoInput>>>;
  companyId: Scalars['ID'];
  companyUserId: Scalars['ID'];
};

export type AddCompanyUserInput = {
  capabilities?: InputMaybe<Array<InputMaybe<CompanyUserCapability>>>;
  companyId: Scalars['ID'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  /** Deprecated. use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isBillingContact?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isSignatory?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CompanyUserRole>;
  title?: InputMaybe<Scalars['String']>;
};

export type AdditionalPayComponent = {
  __typename?: 'AdditionalPayComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PayComponentType>;
  value?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  key?: Maybe<Scalars['String']>;
  landmark?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  landmark?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AddressParam = {
  __typename?: 'AddressParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Address>;
};

export type Allocation = {
  __typename?: 'Allocation';
  count?: Maybe<Scalars['Int']>;
  user?: Maybe<OperationsUser>;
};

export enum AllocationBasis {
  ANNUALLY = 'ANNUALLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
  QUARTERLY = 'QUARTERLY',
  WEEKLY = 'WEEKLY',
}

export type AllocationConfig = {
  __typename?: 'AllocationConfig';
  basis?: Maybe<AllocationBasis>;
  prorated?: Maybe<Scalars['Boolean']>;
};

export type Allowance = {
  __typename?: 'Allowance';
  country: CountryCode;
  label: Scalars['String'];
  mandatory: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AllowedValue = {
  __typename?: 'AllowedValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Amount = {
  __typename?: 'Amount';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export type AmountWithCurrency = {
  __typename?: 'AmountWithCurrency';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export enum ApprovalCategory {
  EXPENSE = 'EXPENSE',
  LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
  MEMBER_DATA = 'MEMBER_DATA',
  /** @deprecated unused since mid 2022, when we stopped using the `approval framework` for memberPayable */
  MEMBER_PAYABLE = 'MEMBER_PAYABLE',
  TIME_OFF = 'TIME_OFF',
  TIME_SHEET = 'TIME_SHEET',
}

export type ApprovalItem = {
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<ApprovalItemStatus>;
};

export enum ApprovalItemStatus {
  ACTION_TAKEN = 'ACTION_TAKEN',
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type Approver = {
  __typename?: 'Approver';
  approverUser?: Maybe<Person>;
  categoryContracts?: Maybe<Array<Maybe<ApproverCategoryContract>>>;
  id?: Maybe<Scalars['ID']>;
};

export type ApproverCategoryContract = {
  __typename?: 'ApproverCategoryContract';
  category?: Maybe<ApprovalCategory>;
  managedContracts?: Maybe<Array<Maybe<Contract>>>;
};

export type ApproverInfoInput = {
  category?: InputMaybe<ApprovalCategory>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum AssessmentRiskScore {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}

export enum AssessmentType {
  COMPLIANCE = 'COMPLIANCE',
  FINANCIAL = 'FINANCIAL',
}

export type AttributeInput = {
  legalEntityId?: InputMaybe<Scalars['ID']>;
};

export type Audit = {
  __typename?: 'Audit';
  completedOn?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
};

export type AuditUser = {
  __typename?: 'AuditUser';
  experience?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type AutoCompleteField = {
  __typename?: 'AutoCompleteField';
  optionListType?: Maybe<Scalars['String']>;
};

export type AutoDebitInfo = {
  __typename?: 'AutoDebitInfo';
  id: Scalars['ID'];
  scheduledDebitDate?: Maybe<Scalars['Date']>;
};

export type AutoDebitInput = {
  bundleId: Scalars['ID'];
  executionDate?: InputMaybe<Scalars['Date']>;
};

export type AvailableSalaryCalculationParams = {
  __typename?: 'AvailableSalaryCalculationParams';
  country: CountryCode;
  currency: Array<CurrencyCode>;
  frequency: Array<RateFrequency>;
  payableType: Array<CalculationPayableType>;
  rateType: Array<RateType>;
  states: Array<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountDetails?: Maybe<Array<Maybe<BankAccountDetail>>>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountRequirementContext?: Maybe<AccountRequirementContext>;
  bankIdentifier?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankStatements?: Maybe<Array<Maybe<FileLink>>>;
  branchName?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  country?: Maybe<CountryCode>;
  /** @deprecated No longer supported */
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  localBankCode?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type BankAccountDetail = {
  __typename?: 'BankAccountDetail';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BankAccountFields = {
  __typename?: 'BankAccountFields';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankAccountInput = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  /**
   * null, [], [null], [null, null]...are considered "empty"<br>
   * The mutation will consider these "existing documents" and simply save them into DB along with "document ids" returned from uploading `bankStatements: [Upload]`<br>
   * If both `bankStatements` and `bankStatementDocIds` are empty, the mutation will not touch existing `bankStatementDocIds` in the DB
   */
  bankStatementDocIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /**
   * null, [], [null], [null, null]...are considered "empty"<br>
   * The mutation will upload these files and save into DB as "document ids" along with `bankStatementDocIds: [ID]`<br>
   * If both `bankStatements` and `bankStatementDocIds` are empty, the mutation will not touch existing `bankStatementDocIds` in the DB
   */
  bankStatements?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  branchName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  currency?: InputMaybe<CurrencyCode>;
  dynamicDetail?: InputMaybe<BankDetailDynamicInput>;
  localBankCode?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type BankAccountParam = {
  __typename?: 'BankAccountParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<BankAccount>;
};

export enum BankCode {
  HSBC = 'HSBC',
}

export type BankDetailDynamicInput = {
  accountType?: InputMaybe<PaymentAccountType>;
  fieldValues?: InputMaybe<Array<InputMaybe<RequirementFieldValue>>>;
  paymentAccountRequirementType?: InputMaybe<Scalars['String']>;
  paymentPartner?: InputMaybe<PaymentPartnerCode>;
  sourceCurrency?: InputMaybe<CurrencyCode>;
  targetCurrency?: InputMaybe<CurrencyCode>;
  transferType?: InputMaybe<TransferType>;
};

export type Benefit = {
  __typename?: 'Benefit';
  benefitDocuments?: Maybe<Array<Maybe<BenefitDocument>>>;
  benefitStatus?: Maybe<BenefitStatus>;
  billFrequency?: Maybe<RateFrequency>;
  compareBenefits?: Maybe<BenefitComparison>;
  contractType?: Maybe<ContractType>;
  cost?: Maybe<Scalars['Float']>;
  costingType?: Maybe<BenefitCostingType>;
  country?: Maybe<CountryCode>;
  countryStateCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  dependentMaxCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  factSheetUrl?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<BenefitFeatures>>>;
  frequency?: Maybe<RateFrequency>;
  id: Scalars['String'];
  isRecommended?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  memberOnboardingKit?: Maybe<BenefitMemberOnboardingKit>;
  packageName?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  packageTier?: Maybe<BenefitPackageTier>;
  provider?: Maybe<BenefitPartnerCountry>;
  showOpsOnly?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tier?: Maybe<InsuranceTier>;
  type?: Maybe<BenefitType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type Benefit_CompareBenefitsArgs = {
  benefitId?: InputMaybe<Scalars['String']>;
};

export type Benefit_MemberOnboardingKitArgs = {
  benefitId?: InputMaybe<Scalars['String']>;
};

export type BenefitBrokerData = {
  __typename?: 'BenefitBrokerData';
  /** @deprecated Use companyLogoUrl instead. */
  companyLogo?: Maybe<DocumentReadable>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  entity?: Maybe<LegalEntity>;
  name?: Maybe<Scalars['String']>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  scope?: Maybe<BenefitScope>;
};

export type BenefitComparison = {
  __typename?: 'BenefitComparison';
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  comparisonFile?: Maybe<BenefitDocument>;
  /** @deprecated use benefits.benefitDocuments instead */
  comparisonFileUrl?: Maybe<Scalars['String']>;
};

export enum BenefitCostingType {
  AGE_BASED = 'AGE_BASED',
  FIXED = 'FIXED',
}

export type BenefitDocument = {
  __typename?: 'BenefitDocument';
  benefitDocumentType?: Maybe<BenefitDocumentType>;
  /** @deprecated Use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  identifier?: Maybe<Scalars['String']>;
};

export type BenefitDocumentInput = {
  benefitDocumentType?: InputMaybe<BenefitDocumentType>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  identifier?: InputMaybe<Scalars['String']>;
};

export enum BenefitDocumentType {
  FACT_SHEET = 'FACT_SHEET',
  INSURANCE_COMPARISON_DOCUMENT = 'INSURANCE_COMPARISON_DOCUMENT',
  INSURANCE_DOCUMENT = 'INSURANCE_DOCUMENT',
}

export type BenefitFeatures = {
  __typename?: 'BenefitFeatures';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BenefitFeaturesInput = {
  key?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type BenefitFilters = {
  benefitId?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  contractType?: InputMaybe<ContractType>;
  /** @deprecated No longer supported */
  country?: InputMaybe<CountryCode>;
  /** @deprecated No longer supported */
  countryStateCode?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated No longer supported */
  packageName?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['ID']>;
  showOpsOnly?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<BenefitStatus>;
  /** @deprecated No longer supported */
  tier?: InputMaybe<BenefitTier>;
  /** @deprecated No longer supported */
  type?: InputMaybe<BenefitType>;
};

export type BenefitInput = {
  benefitStatus?: InputMaybe<BenefitStatus>;
  contractType?: InputMaybe<ContractType>;
  cost?: InputMaybe<Scalars['Float']>;
  costingType?: InputMaybe<BenefitCostingType>;
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  factSheetUrl?: InputMaybe<Scalars['String']>;
  features?: InputMaybe<Array<InputMaybe<BenefitFeaturesInput>>>;
  frequency?: InputMaybe<RateFrequency>;
  location?: InputMaybe<Scalars['String']>;
  packageName?: InputMaybe<Scalars['String']>;
  packageTier?: InputMaybe<BenefitTierInput>;
  providerId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<BenefitType>;
};

export type BenefitInsightDefinition = InsightDefinition & {
  __typename?: 'BenefitInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  benefitType?: Maybe<Scalars['String']>;
  category?: Maybe<InsightCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum BenefitInsuranceType {
  FAMILY_INSURANCE = 'FAMILY_INSURANCE',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type BenefitMemberOnboardingKit = {
  __typename?: 'BenefitMemberOnboardingKit';
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  memberOnboardingKitFile?: Maybe<BenefitDocument>;
};

export type BenefitMetaData = {
  __typename?: 'BenefitMetaData';
  brokerData?: Maybe<BenefitBrokerData>;
  companyLogo?: Maybe<DocumentReadable>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  pocName?: Maybe<Scalars['String']>;
};

export type BenefitPackageCost = {
  __typename?: 'BenefitPackageCost';
  ageHigherRange?: Maybe<Scalars['Int']>;
  ageLowerRange?: Maybe<Scalars['Int']>;
  benefitPackageId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  premium?: Maybe<Scalars['Float']>;
  premiumCurrency?: Maybe<CurrencyCode>;
  premiumFrequency?: Maybe<BenefitPremiumFrequency>;
  status?: Maybe<BenefitPackageCostStatus>;
};

export type BenefitPackageCostFilters = {
  benefitPackageCostId?: InputMaybe<Scalars['String']>;
  benefitPackageId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BenefitPackageCostStatus>;
};

export enum BenefitPackageCostStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type BenefitPackageTier = {
  __typename?: 'BenefitPackageTier';
  isDefault?: Maybe<Scalars['Boolean']>;
  tier?: Maybe<BenefitTier>;
};

export type BenefitParamInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum BenefitPartnerBillingFrequency {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  REAL_TIME = 'REAL_TIME',
}

export type BenefitPartnerCountriesResult = {
  __typename?: 'BenefitPartnerCountriesResult';
  data?: Maybe<Array<BenefitPartnerCountry>>;
  pageResult?: Maybe<PageResult>;
};

export type BenefitPartnerCountry = PartnerCountry & {
  __typename?: 'BenefitPartnerCountry';
  ageBandApplicable?: Maybe<Scalars['Boolean']>;
  backDatingDays?: Maybe<Scalars['Int']>;
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  benefitsDomain?: Maybe<BenefitsDomain>;
  billingCurrency?: Maybe<CurrencyCode>;
  billingDuration?: Maybe<BenefitPartnerBillingFrequency>;
  brokerData?: Maybe<BenefitBrokerData>;
  canMarkAsExpired?: Maybe<Scalars['Boolean']>;
  capability?: Maybe<PartnerCapability>;
  country?: Maybe<CountryCode>;
  coverageGroup?: Maybe<InsuranceCoverageGroup>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  dependentsAllowed?: Maybe<Scalars['Boolean']>;
  dependentsCutoffDays?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<Maybe<BenefitDocument>>>;
  emergencyPointOfContact?: Maybe<Person>;
  enrollmentProcess?: Maybe<BenefitPartnerEnrollmentProcess>;
  entity?: Maybe<LegalEntity>;
  erSplitApplicable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  insuranceType?: Maybe<BenefitInsuranceType>;
  isInsuranceMandatory?: Maybe<Scalars['Boolean']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  masterPolicyEndDate?: Maybe<Scalars['DateTime']>;
  masterPolicyStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use memberOnboardingKitUrl instead. */
  memberOnboardingKit?: Maybe<FileLink>;
  /** @deprecated No longer supported */
  memberOnboardingKitUrl?: Maybe<Scalars['String']>;
  memberType?: Maybe<ContractType>;
  metaData?: Maybe<BenefitMetaData>;
  minimumErSplitPercentage?: Maybe<Scalars['Float']>;
  operator?: Maybe<Array<Maybe<PartnerUser>>>;
  partner?: Maybe<Partner>;
  partnerDetails?: Maybe<BenefitPartnerData>;
  partnerName?: Maybe<Scalars['String']>;
  partnerPortalWeblink?: Maybe<Scalars['String']>;
  platformFee?: Maybe<Scalars['Int']>;
  platformFeeApplicable?: Maybe<Scalars['Boolean']>;
  policyRules?: Maybe<BenefitPolicyRules>;
  refundPolicy?: Maybe<Scalars['Boolean']>;
  showOpsOnly?: Maybe<Scalars['Boolean']>;
  status?: Maybe<BenefitPartnerStatus>;
  terminationType?: Maybe<BenefitTerminationType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type BenefitPartnerCountryFilters = {
  ageBandApplicable?: InputMaybe<Scalars['Boolean']>;
  backDatingDaysLower?: InputMaybe<Scalars['Int']>;
  backDatingDaysUpper?: InputMaybe<Scalars['Int']>;
  billingDuration?: InputMaybe<
    Array<InputMaybe<BenefitPartnerBillingFrequency>>
  >;
  brokerName?: InputMaybe<Scalars['String']>;
  countries?: InputMaybe<Array<InputMaybe<CountryCode>>>;
  coverageGroupId?: InputMaybe<Scalars['ID']>;
  dependentsAllowed?: InputMaybe<Scalars['Boolean']>;
  dependentsCutoffDaysLower?: InputMaybe<Scalars['Int']>;
  dependentsCutoffDaysUpper?: InputMaybe<Scalars['Int']>;
  erSplitApplicable?: InputMaybe<Scalars['Boolean']>;
  insuranceType?: InputMaybe<BenefitInsuranceType>;
  masterPolicyEndDateFrom?: InputMaybe<Scalars['DateTime']>;
  masterPolicyEndDateTo?: InputMaybe<Scalars['DateTime']>;
  masterPolicyStartDateFrom?: InputMaybe<Scalars['DateTime']>;
  masterPolicyStartDateTo?: InputMaybe<Scalars['DateTime']>;
  memberType?: InputMaybe<Array<InputMaybe<ContractType>>>;
  name?: InputMaybe<Scalars['String']>;
  platformFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<InputMaybe<BenefitPartnerStatus>>>;
};

export type BenefitPartnerCountryResponse = {
  __typename?: 'BenefitPartnerCountryResponse';
  benefitPartnerCountry?: Maybe<BenefitPartnerCountry>;
  response?: Maybe<TaskResponse>;
};

export type BenefitPartnerData = {
  __typename?: 'BenefitPartnerData';
  address?: Maybe<Address>;
  bankAccount?: Maybe<BankAccount>;
  companyLogo?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  scope?: Maybe<BenefitScope>;
};

export type BenefitPartnerDataDownloadInput = {
  benefitPartnerCountryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum BenefitPartnerEnrollmentProcess {
  MANUAL = 'MANUAL',
  ONLINE = 'ONLINE',
}

export enum BenefitPartnerStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type BenefitPolicyRules = {
  __typename?: 'BenefitPolicyRules';
  married?: Maybe<MarriedPolicyRules>;
  unmarried?: Maybe<UnmarriedPolicyRules>;
};

export enum BenefitPremiumFrequency {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
}

export enum BenefitScope {
  GLOBAL = 'GLOBAL',
  LOCAL = 'LOCAL',
}

export enum BenefitStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export enum BenefitTerminationType {
  IMMEDIATE = 'IMMEDIATE',
  NON_IMMEDIATE = 'NON_IMMEDIATE',
}

export enum BenefitTier {
  BRONZE = 'BRONZE',
  GOLD = 'GOLD',
  GOLD_PLUS = 'GOLD_PLUS',
  SILVER = 'SILVER',
}

export type BenefitTierInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  tier?: InputMaybe<BenefitTier>;
};

export enum BenefitType {
  FAMILY_INSURANCE = 'FAMILY_INSURANCE',
  INSURANCE = 'INSURANCE',
}

export enum BenefitsDomain {
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
}

export enum BillingRate {
  ANNUALLY = 'ANNUALLY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type BlockActor = {
  __typename?: 'BlockActor';
  domainUserId?: Maybe<Scalars['ID']>;
  type: BlockActorType;
  userId: Scalars['ID'];
};

export enum BlockActorType {
  COMPANY_USER = 'COMPANY_USER',
  MEMBER = 'MEMBER',
  OPS_USER = 'OPS_USER',
}

export type BlockData = ChangeRequestBlockData;

export type BlockEvent = {
  __typename?: 'BlockEvent';
  blockData?: Maybe<BlockData>;
  blockReason: Scalars['String'];
  blocker: BlockActor;
  contractId: Scalars['ID'];
  contractStatus: ContractStatus;
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  source: BlockSource;
  status: BlockStatus;
  turnAroundTimeInHours?: Maybe<Scalars['Int']>;
  unblockReason?: Maybe<Scalars['String']>;
  unblocker?: Maybe<BlockActor>;
  unblockerCandidates?: Maybe<Array<BlockActor>>;
};

export enum BlockSource {
  CONTRACT_CHANGE_REQUEST = 'CONTRACT_CHANGE_REQUEST',
}

export enum BlockStatus {
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED',
}

export type BooleanConfigValue = {
  __typename?: 'BooleanConfigValue';
  value: Scalars['Boolean'];
};

export enum BulkOnboardingContext {
  AOR = 'AOR',
  EOR = 'EOR',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL = 'GLOBAL_PAYROLL',
  HRIS_PROFILE_DATA = 'HRIS_PROFILE_DATA',
}

export type BulkOnboardingJob = {
  __typename?: 'BulkOnboardingJob';
  id: Scalars['String'];
  onboardingResult?: Maybe<BulkOnboardingResult>;
  startTime: Scalars['DateTime'];
  status: BulkOnboardingJobStatus;
  validationResult: BulkOnboardingValidationResult;
};

export enum BulkOnboardingJobStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export type BulkOnboardingMessage = {
  __typename?: 'BulkOnboardingMessage';
  columnName?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  employeeName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  rowNumber?: Maybe<Scalars['Int']>;
};

export type BulkOnboardingOptions = {
  companyId?: InputMaybe<Scalars['ID']>;
  context?: BulkOnboardingContext;
  contractType?: InputMaybe<ContractType>;
  countryCode?: InputMaybe<CountryCode>;
  entityId?: InputMaybe<Scalars['ID']>;
};

export type BulkOnboardingResult = {
  __typename?: 'BulkOnboardingResult';
  errors?: Maybe<Array<BulkOnboardingMessage>>;
  failedEmployeeCount: Scalars['Int'];
  onboardedEmployeeCount: Scalars['Int'];
  warnings?: Maybe<Array<BulkOnboardingMessage>>;
};

export type BulkOnboardingValidationResult = {
  __typename?: 'BulkOnboardingValidationResult';
  errorEmployeeCount: Scalars['Int'];
  errors?: Maybe<Array<BulkOnboardingMessage>>;
  totalEmployeeCount: Scalars['Int'];
  validEmployeeCount: Scalars['Int'];
  validationErrors?: Maybe<Array<BulkOnboardingMessage>>;
  validationResultFile?: Maybe<DocumentReadable>;
  warnings?: Maybe<Array<BulkOnboardingMessage>>;
};

export type BulkPayrollConfigUpdateInput = {
  calendarUpdateInput?: InputMaybe<CalendarUpdateInput>;
  configIds: Array<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BulkPayrollCycleConfigInput = {
  calendar: PayrollCalendarInput;
  frequency: PayFrequency;
  isDefault: Scalars['Boolean'];
  payrollConfigEntity: Array<PayrollConfigEntityInput>;
  type: PayrollCycleEntityType;
};

export enum BulkUploadItemType {
  BANK_DETAIL = 'BANK_DETAIL',
  DEDUCTION = 'DEDUCTION',
  EXPENSE = 'EXPENSE',
  HOLD_SALARY = 'HOLD_SALARY',
  OFF_BOARDING = 'OFF_BOARDING',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY_REVISION = 'SALARY_REVISION',
  TIME_OFF = 'TIME_OFF',
  TIME_SHEET = 'TIME_SHEET',
}

export type BulkUploadResponse = {
  __typename?: 'BulkUploadResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type BulkUploadValidationResponse = {
  __typename?: 'BulkUploadValidationResponse';
  bulkUploadFile?: Maybe<PayrollFile>;
  bulkUploadSummary?: Maybe<PayrollBulkUploadSummary>;
  errorReport?: Maybe<PayrollFileDownloadResponse>;
  validationResponse?: Maybe<ValidationResponse>;
};

export type CalculationBreakdown = {
  __typename?: 'CalculationBreakdown';
  entries: Array<CalculationEntryGroup>;
  frequency?: Maybe<RateFrequency>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  total: CalculationEntry;
};

export type CalculationEntry = {
  __typename?: 'CalculationEntry';
  amount: Scalars['Float'];
  currency?: Maybe<CurrencyCode>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type CalculationEntryGroup = {
  __typename?: 'CalculationEntryGroup';
  amount: Scalars['Float'];
  currency?: Maybe<CurrencyCode>;
  entries: Array<CalculationEntry>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export enum CalculationPayableType {
  COMPANY = 'COMPANY',
  EMPLOYEE = 'EMPLOYEE',
}

export type CalendarDateInput = {
  date: Scalars['Int'];
  relativeMonth: Scalars['Int'];
};

export type CalendarUpdateInput = {
  approvePayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  approvePayrollReportDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  approvePayrollReportDeadlineRelativeToPayDate?: InputMaybe<Scalars['Int']>;
  compensationDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  contractEndDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  contractStartDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  cutOffRelativeToPayDate?: InputMaybe<Scalars['Int']>;
  deductionDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  expenseDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  memberChangesDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  paySupplementDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payrollCompleteDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payrollProcessingDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  snapCutOffDates?: InputMaybe<Scalars['Boolean']>;
  snapDeadlineDates?: InputMaybe<Scalars['Boolean']>;
  snapPayDate?: InputMaybe<Scalars['Boolean']>;
  snapToDates?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  submitPayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  timeOffDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  timeSheetDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
};

export enum Capability {
  AOR = 'AOR',
  EOR = 'EOR',
  EXPENSES = 'EXPENSES',
  FREELANCER = 'FREELANCER',
  GROSS_TO_NET = 'GROSS_TO_NET',
  ORG_MANAGEMENT = 'ORG_MANAGEMENT',
  SALARY_DISBURSEMENT = 'SALARY_DISBURSEMENT',
  TIME_OFF = 'TIME_OFF',
  TIME_SHEET = 'TIME_SHEET',
}

export type CarryForwardConfig = {
  __typename?: 'CarryForwardConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  expiry?: Maybe<TimeOffDuration>;
  maxLimit?: Maybe<CarryForwardLimit>;
  minForEligibility?: Maybe<CarryForwardLimit>;
};

export type CarryForwardConfigInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  expiryConfig?: InputMaybe<CarryForwardExpiryInput>;
  maxLimit?: InputMaybe<TimeOffDurationInput>;
};

export type CarryForwardExpiryInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  expiry?: InputMaybe<TimeOffDurationInput>;
};

export type CarryForwardLimit = {
  __typename?: 'CarryForwardLimit';
  type?: Maybe<LimitValueType>;
  unit?: Maybe<TimeOffUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type CategoryApproverInfo = {
  __typename?: 'CategoryApproverInfo';
  approverUsers?: Maybe<Array<Maybe<Person>>>;
  category?: Maybe<ApprovalCategory>;
};

export type ChangeRequestBlockData = {
  __typename?: 'ChangeRequestBlockData';
  changeRequestId: Scalars['ID'];
};

export type ChangeRequestConfigFiltersInput = {
  contractId?: InputMaybe<Scalars['ID']>;
};

export enum ChangeRequestStatus {
  CREATED = 'CREATED',
  RESOLVED = 'RESOLVED',
  UPDATED = 'UPDATED',
  VIEWED = 'VIEWED',
}

export type CheckboxField = {
  __typename?: 'CheckboxField';
  defaultValue: Scalars['Boolean'];
};

export type ClearGpSyncFailedEventsInput = {
  companyId: Scalars['ID'];
  from?: InputMaybe<Scalars['DateTime']>;
  platformID: Scalars['ID'];
  to?: InputMaybe<Scalars['DateTime']>;
};

export type ClientDetail = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CompaniesCountRequest = {
  active?: InputMaybe<Scalars['Boolean']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  pending?: InputMaybe<Scalars['Boolean']>;
};

export type CompaniesCountResult = {
  __typename?: 'CompaniesCountResult';
  active?: Maybe<Scalars['Int']>;
  inactive?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
};

export type CompaniesResult = {
  __typename?: 'CompaniesResult';
  countResult?: Maybe<CompaniesCountResult>;
  data?: Maybe<Array<Maybe<Company>>>;
  pageResult?: Maybe<PageResult>;
};

export type Company = {
  __typename?: 'Company';
  accountManagerUser?: Maybe<OperationsUser>;
  approvers?: Maybe<Array<Maybe<Approver>>>;
  billingContact?: Maybe<CompanyUser>;
  channel?: Maybe<CompanyChannel>;
  companyLogo?: Maybe<DocumentReadable>;
  companyLogoId?: Maybe<Scalars['ID']>;
  /** @deprecated Use companyPayrollCycles instead */
  companyPayrollCycle?: Maybe<Array<CompanyPayrollCycle>>;
  companyPayrollCycles?: Maybe<Array<CompanyPayrollCycle>>;
  companyUsers?: Maybe<Array<CompanyUser>>;
  /**
   * # If includeAnyStatus = true => result will contain contracts with any statuses (ONBOARDING, ACTIVE, OFFBOARDING, ENDED, or DELETED)
   * # If includeAnyStatus = false => result will filter-out/skip any "ENDED" or "DELETED" contracts.
   */
  contracts?: Maybe<Array<Maybe<Contract>>>;
  /**
   * Similar to `contracts`, but will be dedicated for Team View page (as part of HRIS).<br>
   * The main difference is around Reporting Managers now being able to see Team View page (only their direct/indirect subordinates).<br>
   * Super Admin (or similar role) keeps seeing all contracts. Abac logic and most stuff are also copied over.<br>
   * Details: https://app.clickup.com/t/86cvd78qw | https://multiplier-group.slack.com/archives/C02B2S7A83T/p1715925057440889?thread_ts=1715854492.186609&cid=C02B2S7A83T<br>
   */
  contractsForTeamView?: Maybe<Array<Maybe<Contract>>>;
  createdOn?: Maybe<Scalars['DateTime']>;
  csmUser?: Maybe<OperationsUser>;
  currentMonthPayrollCutoffDate?: Maybe<Scalars['Date']>;
  customerIntegrations?: Maybe<Array<Maybe<CustomerIntegration>>>;
  displayName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  equipments?: Maybe<Array<Equipment>>;
  expenseReport?: Maybe<DocumentReadable>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  financialYear?: Maybe<Scalars['Int']>;
  fxSensitivity?: Maybe<FxSensitivity>;
  id?: Maybe<Scalars['ID']>;
  inactiveReason?: Maybe<CompanyInactiveReason>;
  isTest?: Maybe<Scalars['Boolean']>;
  managementFees?: Maybe<Array<Maybe<ManagementFee>>>;
  managers?: Maybe<Array<Maybe<CompanyUser>>>;
  memberPayableCompanyInvoices?: Maybe<
    Array<Maybe<MemberPayableCompanyInvoice>>
  >;
  memberPayables?: Maybe<Array<Maybe<MemberPayable>>>;
  msa?: Maybe<DocumentReadable>;
  /** @deprecated Should use msaAddendums */
  msaAddendum?: Maybe<MsaAddendum>;
  msaAddendums?: Maybe<Array<Maybe<MsaAddendum>>>;
  name?: Maybe<Scalars['String']>;
  nextPayrollCutoffDate?: Maybe<Scalars['Date']>;
  offerings?: Maybe<Array<Maybe<CompanyOffering>>>;
  onboarding?: Maybe<CompanyOnboarding>;
  otherEntities?: Maybe<Array<Maybe<LegalEntity>>>;
  payInSettings?: Maybe<CompanyPayInSettings>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payables?: Maybe<Array<Maybe<CompanyPayable>>>;
  paymentBundles?: Maybe<Array<Maybe<PaymentBundle>>>;
  payroll?: Maybe<Array<Maybe<CompanyPayroll>>>;
  /** @deprecated Should use currentMonthPayrollCutoffDate or nextPayrollCutoffDate */
  payrollCutOffDate?: Maybe<Scalars['Int']>;
  performanceReviews?: Maybe<Array<Maybe<PerformanceReview>>>;
  pricing?: Maybe<Pricing>;
  primaryEntity?: Maybe<LegalEntity>;
  requestsCreated?: Maybe<Array<Maybe<Request>>>;
  salaryReviews?: Maybe<Array<Maybe<SalaryReview>>>;
  salesUser?: Maybe<OperationsUser>;
  sfdcAccountNo?: Maybe<Scalars['String']>;
  signatories?: Maybe<Array<Maybe<CompanyUser>>>;
  ssoPreference?: Maybe<Array<Maybe<SsoPreference>>>;
  status?: Maybe<CompanyStatus>;
  statutoryReports?: Maybe<Array<CustomPayrollReportDetails>>;
  templates?: Maybe<Array<Maybe<Template>>>;
  timeOffBalanceReport?: Maybe<DocumentReadable>;
  timeOffPolicies?: Maybe<Array<Maybe<TimeOffTypeDefinition>>>;
  timeOffReport?: Maybe<DocumentReadable>;
  timeOffRequirements?: Maybe<TimeOffRequirements>;
  timeOffTypeInfos?: Maybe<Array<Maybe<TimeOffTypeInfo>>>;
  timeOffs?: Maybe<Array<Maybe<TimeOff>>>;
  timesheetSummaries?: Maybe<Array<ContractTimesheetSummary>>;
  timesheets?: Maybe<Array<Maybe<Timesheet>>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type Company_ApproversArgs = {
  category?: InputMaybe<ApprovalCategory>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Company_CompanyPayrollCycleArgs = {
  filter: CompanyPayrollCycleFilter;
};

export type Company_CompanyPayrollCyclesArgs = {
  filter: CompanyPayrollCycleFilter;
};

export type Company_CompanyUsersArgs = {
  statuses?: InputMaybe<Array<CompanyUserStatus>>;
};

export type Company_ContractsArgs = {
  category?: InputMaybe<ApprovalCategory>;
  companyContractsFilter?: InputMaybe<CompanyContractsFilter>;
  contractId?: InputMaybe<Scalars['ID']>;
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ContractStatus>;
};

export type Company_ContractsForTeamViewArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ContractStatus>;
};

export type Company_ExpenseReportArgs = {
  input: ExpenseReportInput;
};

export type Company_ExpensesArgs = {
  expenseId?: InputMaybe<Scalars['ID']>;
};

export type Company_ManagementFeesArgs = {
  contractFilters?: InputMaybe<ContractFilters>;
  monthYear?: InputMaybe<MonthYearInput>;
};

export type Company_ManagersArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type Company_MemberPayableCompanyInvoicesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  reference?: InputMaybe<Scalars['String']>;
};

export type Company_MemberPayablesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<MemberPayableStatus>>;
  types?: InputMaybe<Array<MemberPayableType>>;
};

export type Company_MsaAddendumsArgs = {
  filters: MsaAddendumFilters;
};

export type Company_OtherEntitiesArgs = {
  legalEntityId?: InputMaybe<Scalars['ID']>;
};

export type Company_PaySupplementsArgs = {
  paySupplementId?: InputMaybe<Scalars['ID']>;
};

export type Company_PayablesArgs = {
  filters?: InputMaybe<CompanyPayableFilters>;
  month?: InputMaybe<MonthYearInput>;
  payableId?: InputMaybe<Scalars['ID']>;
  payableQueryMode?: InputMaybe<PayableQueryMode>;
};

export type Company_PaymentBundlesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  statuses: Array<PaymentBundleStatus>;
};

export type Company_PayrollArgs = {
  payrollMonth?: InputMaybe<MonthYearInput>;
};

export type Company_PerformanceReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PerformanceReviewStatus>;
};

export type Company_RequestsCreatedArgs = {
  status?: InputMaybe<RequestStatus>;
};

export type Company_SalaryReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<SalaryReviewStatus>;
};

export type Company_StatutoryReportsArgs = {
  filter: CustomPayrollReportFilter;
};

export type Company_TemplatesArgs = {
  filter: TemplateFilter;
};

export type Company_TimeOffPoliciesArgs = {
  filter?: InputMaybe<CompanyTimeOffPolicyFilter>;
};

export type Company_TimeOffReportArgs = {
  input: TimeOffReportInput;
};

export type Company_TimeOffRequirementsArgs = {
  filters?: InputMaybe<TimeOffRequirementFilters>;
};

export type Company_TimeOffTypeInfosArgs = {
  filter?: InputMaybe<TimeOffTypeFilter>;
};

export type Company_TimeOffsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Company_TimesheetSummariesArgs = {
  filters?: InputMaybe<TimesheetSummaryFilter>;
};

export type Company_TimesheetsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  timesheetFilters?: InputMaybe<TimesheetFilters>;
};

export enum CompanyAssignTypeFilter {
  ALL = 'ALL',
  ME = 'ME',
  UNASSIGNED = 'UNASSIGNED',
}

export type CompanyBankAccount = {
  __typename?: 'CompanyBankAccount';
  id: Scalars['ID'];
};

export type CompanyBankOverrideInfo = {
  accountNumber: Scalars['String'];
  accountType?: InputMaybe<AccountType>;
  bank: Scalars['String'];
  companyName: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type CompanyBinding = {
  __typename?: 'CompanyBinding';
  company?: Maybe<Company>;
  /** @deprecated use customerId instead, when Netsuite is fully implemented this will be removed. */
  contactId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<ExternalSystemType>;
  id: Scalars['ID'];
};

export type CompanyBindingInput = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export enum CompanyChannel {
  ORGANIC = 'ORGANIC',
  SALES = 'SALES',
}

export type CompanyChargebackResult = {
  __typename?: 'CompanyChargebackResult';
  chargebackDays: Scalars['Int'];
  companyId: Scalars['ID'];
};

export type CompanyContractsFilter = {
  isApprovalRelated?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyCountryInvoiceSourceReport = {
  __typename?: 'CompanyCountryInvoiceSourceReport';
  companyCountryBillingTotal?: Maybe<Scalars['Float']>;
  country?: Maybe<CountryCode>;
  items?: Maybe<Array<Maybe<CompanyInvoiceSourceItem>>>;
};

export type CompanyEmployeePricingInput = {
  country?: InputMaybe<CountryCode>;
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
  region?: InputMaybe<Region>;
};

export type CompanyFilters = {
  assignType?: InputMaybe<CompanyAssignTypeFilter>;
  associatedEmailDomain?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyStatuses?: InputMaybe<Array<InputMaybe<CompanyStatus>>>;
  country?: InputMaybe<CountryCode>;
  createdDateRange?: InputMaybe<DateRange>;
  csmUserId?: InputMaybe<Scalars['ID']>;
  salesUserId?: InputMaybe<Scalars['ID']>;
};

export type CompanyGlCode = {
  __typename?: 'CompanyGLCode';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyGlobalPricingInput = {
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
};

export type CompanyGroup = {
  __typename?: 'CompanyGroup';
  children?: Maybe<Array<Maybe<Company>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type CompanyGroupAddCompanyInput = {
  children?: InputMaybe<Array<CompanyGroupNodeInput>>;
  id?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<CompanyGroupAddUserInput>>;
};

export type CompanyGroupAddRemoveCompanyInput = {
  children?: InputMaybe<Array<CompanyGroupNodeInput>>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanyGroupAddRemoveUserInput = {
  id?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<CompanyGroupUserInput>>;
};

export type CompanyGroupAddUserInput = {
  roles?: InputMaybe<Array<InputMaybe<CompanyGroupRole>>>;
  userId: Scalars['ID'];
};

export type CompanyGroupCreateInput = {
  children?: InputMaybe<Array<CompanyGroupNodeInput>>;
  name: Scalars['String'];
  userList?: InputMaybe<Array<CompanyGroupUserInput>>;
  users?: InputMaybe<Array<CompanyGroupAddUserInput>>;
};

export type CompanyGroupNodeInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum CompanyGroupRole {
  COMPANY_GROUP_PRIMARY_ADMIN = 'COMPANY_GROUP_PRIMARY_ADMIN',
}

export type CompanyGroupUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CompanyGroupUserInput = {
  userId: Scalars['ID'];
};

export type CompanyIdToForcedContractIdsInput = {
  companyId: Scalars['ID'];
  forcedContractIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum CompanyInactiveReason {
  CHURN = 'CHURN',
  DISQUALIFIED = 'DISQUALIFIED',
  DUPLICATE = 'DUPLICATE',
  INVALID = 'INVALID',
  LOST = 'LOST',
  NON_RESPONSIVE = 'NON_RESPONSIVE',
  PASSIVE = 'PASSIVE',
}

export type CompanyIntegration = {
  __typename?: 'CompanyIntegration';
  companyId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lastSyncCreatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncId?: Maybe<Scalars['ID']>;
  platformID?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['ID']>;
  syncInProgress?: Maybe<Scalars['Boolean']>;
};

export type CompanyIntegrationInfo = {
  __typename?: 'CompanyIntegrationInfo';
  companyId?: Maybe<Scalars['ID']>;
  incomingImportInProgress?: Maybe<Scalars['Boolean']>;
  incomingSyncEnabled?: Maybe<Scalars['Boolean']>;
  incomingSyncInProgress?: Maybe<Scalars['Boolean']>;
  lastIncomingSyncId?: Maybe<Scalars['String']>;
  lastIncomingSyncStartTime?: Maybe<Scalars['DateTime']>;
  lastOutgoingSyncId?: Maybe<Scalars['String']>;
  lastOutgoingSyncStartTime?: Maybe<Scalars['DateTime']>;
  outgoingSyncEnabled?: Maybe<Scalars['Boolean']>;
  outgoingSyncInProgress?: Maybe<Scalars['Boolean']>;
  platformID?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['ID']>;
};

export type CompanyInvoiceSourceItem = {
  __typename?: 'CompanyInvoiceSourceItem';
  additional?: Maybe<Array<Maybe<CompanyPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<CompanyPayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<CompanyPayComponent>>>;
  firstInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  firstInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  secondInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  secondInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBillingAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type CompanyInvoiceSourceRecord = {
  __typename?: 'CompanyInvoiceSourceRecord';
  additional?: Maybe<Array<Maybe<CompanyPayableComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<CompanyPayableComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<CompanyPayableComponent>>>;
  firstInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  firstInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  secondInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  secondInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBillingAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type CompanyInvoiceSourceReportInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /**
   * which invoice the data should be sourced from
   * 1 = First Invoice
   * 2 = Second Invoice
   */
  invoiceType?: InputMaybe<Scalars['Int']>;
  monthYearCycle?: InputMaybe<MonthYearCycleInput>;
};

export type CompanyInvoiceSourceReportResult = {
  __typename?: 'CompanyInvoiceSourceReportResult';
  company?: Maybe<Company>;
  companyTotalBillingAmount?: Maybe<Scalars['Float']>;
  invoiceSourceReport?: Maybe<Array<Maybe<CompanyCountryInvoiceSourceReport>>>;
};

export type CompanyMemberPay = MemberPay & {
  __typename?: 'CompanyMemberPay';
  additional?: Maybe<Array<Maybe<AdditionalPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountGrossCompanyCurrency?: Maybe<Scalars['Float']>;
  amountNet?: Maybe<Scalars['Float']>;
  amountNetCompanyCurrency?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  amountTotalCostCompanyCurrency?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  clientDeductions?: Maybe<Array<Maybe<PayComponent>>>;
  companyPayroll?: Maybe<CompanyPayroll>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<PayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<PayComponent>>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payrollCycle?: Maybe<PayrollCycle>;
  payslip?: Maybe<Payslip>;
  status?: Maybe<MemberPayStatus>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type CompanyMemberPayResult = {
  __typename?: 'CompanyMemberPayResult';
  companyCount?: Maybe<Scalars['Int']>;
  /** countryCount is the total number of payroll country in the whole result */
  countryCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<CompanyMemberPay>>>;
  employeeCount?: Maybe<Scalars['Int']>;
  pageResult?: Maybe<PageResult>;
};

export type CompanyOffering = {
  __typename?: 'CompanyOffering';
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  companyId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  offering?: Maybe<OfferingCode>;
  pricing?: Maybe<CompanyOfferingPricing>;
  status?: Maybe<OfferingStatus>;
};

export type CompanyOfferingFeeComponent = {
  __typename?: 'CompanyOfferingFeeComponent';
  companyOfferingFeeComponentTier?: Maybe<CompanyOfferingFeeComponentTier>;
  countryCode?: Maybe<CountryCode>;
  currencyCode?: Maybe<CurrencyCode>;
  discount?: Maybe<Discount>;
  fee: Scalars['Float'];
  feeComponentDefinitionId?: Maybe<Scalars['ID']>;
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id: Scalars['ID'];
  key: Scalars['String'];
  offeringCode: OfferingCode;
  variableBaseUnit?: Maybe<VariableBaseUnit>;
};

export type CompanyOfferingFeeComponentInput = {
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<CurrencyCode>;
  discount?: InputMaybe<DiscountInput>;
  fee: Scalars['Float'];
  feeComponentDefinitionId?: InputMaybe<Scalars['ID']>;
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  offeringCode: OfferingCode;
  variableBaseUnit?: InputMaybe<VariableBaseUnit>;
};

export type CompanyOfferingFeeComponentTier = {
  __typename?: 'CompanyOfferingFeeComponentTier';
  feeComponentTierDefinitionId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  offeringCode: OfferingCode;
  tierLowerBound?: Maybe<Scalars['Int']>;
  tierUpperBound?: Maybe<Scalars['Int']>;
};

export type CompanyOfferingFeeComponentTierInput = {
  companyOfferingFeeComponents: Array<CompanyOfferingFeeComponentInput>;
  feeComponentTierDefinitionId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  offeringCode: OfferingCode;
  tierLowerBound?: InputMaybe<Scalars['Int']>;
  tierUpperBound?: InputMaybe<Scalars['Int']>;
};

export type CompanyOfferingInput = {
  capabilities?: InputMaybe<Array<InputMaybe<Capability>>>;
  offering?: InputMaybe<OfferingCode>;
};

export type CompanyOfferingPricing = {
  __typename?: 'CompanyOfferingPricing';
  companyId: Scalars['ID'];
  companyOfferingFeeComponentTiers: Array<CompanyOfferingFeeComponentTier>;
  companyOfferingFeeComponents: Array<CompanyOfferingFeeComponent>;
  companyOfferingId: Scalars['ID'];
  discount?: Maybe<Discount>;
  id: Scalars['ID'];
  offeringCode: OfferingCode;
  pricingPlans: Array<PricingPlan>;
  status?: Maybe<CompanyOfferingPricingStatus>;
};

export type CompanyOfferingPricingFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  offeringCode?: InputMaybe<Array<OfferingCode>>;
};

export type CompanyOfferingPricingInput = {
  companyOfferingFeeComponentTiers: Array<CompanyOfferingFeeComponentTierInput>;
  companyOfferingId: Scalars['ID'];
  discount?: InputMaybe<DiscountInput>;
};

export enum CompanyOfferingPricingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CompanyOfferingsAddInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<Array<InputMaybe<CompanyOfferingInput>>>;
};

export type CompanyOfferingsUpdateInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<Array<InputMaybe<CompanyOfferingInput>>>;
};

export type CompanyOnboarding = {
  __typename?: 'CompanyOnboarding';
  hasDataChanged?: Maybe<Scalars['Boolean']>;
  msaGenerationRequirement?: Maybe<Array<Maybe<Scalars['String']>>>;
  msaWorkflow?: Maybe<MsaWorkflow>;
};

export type CompanyPayComponent = {
  __typename?: 'CompanyPayComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<CompanyPayComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum CompanyPayComponentType {
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  DEDUCTIONS = 'DEDUCTIONS',
  OTHER = 'OTHER',
}

export type CompanyPayInMethod = {
  bankDetails?: Maybe<Array<Maybe<CompanyPayInMethodBankDetails>>>;
  company?: Maybe<Company>;
  context?: Maybe<PayInMethodContext>;
  currentStatus?: Maybe<CompanyPayInMethodWorkflowStatus>;
  currentStep?: Maybe<PayInMethodDefinitionStep>;
  data?: Maybe<Array<Maybe<KeyValue>>>;
  documents?: Maybe<Array<Maybe<DocumentData>>>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  payInMethodType?: Maybe<PayInMethodType>;
  workflowSteps: Array<Maybe<CompanyPayInMethodWorkflowStep>>;
};

export type CompanyPayInMethodBankDetails = {
  __typename?: 'CompanyPayInMethodBankDetails';
  data?: Maybe<Array<Maybe<KeyValue>>>;
  documents?: Maybe<Array<Maybe<DocumentData>>>;
  payInAccountRequirementType?: Maybe<Scalars['String']>;
};

export type CompanyPayInMethodInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  payInMethodType?: InputMaybe<PayInMethodType>;
  setUpData?: InputMaybe<CompanyPayInMethodSetUpData>;
};

export type CompanyPayInMethodSetUpData = {
  accountRequirements?: InputMaybe<Array<InputMaybe<AccountRequirementInput>>>;
  step?: InputMaybe<PayInMethodDefinitionStep>;
};

export enum CompanyPayInMethodWorkflowStatus {
  /** @deprecated will use ACTIVATION PENDING to represent all the statuses till its activated */
  ACTIVATION_INITIATED = 'ACTIVATION_INITIATED',
  ACTIVATION_IN_PROGRESS = 'ACTIVATION_IN_PROGRESS',
  ACTIVATION_PENDING = 'ACTIVATION_PENDING',
  AGREEMENT_SENT_TO_SIGNATORY = 'AGREEMENT_SENT_TO_SIGNATORY',
  AGREEMENT_SIGNED = 'AGREEMENT_SIGNED',
  AGREEMENT_SIGNING_IN_PROGRESS = 'AGREEMENT_SIGNING_IN_PROGRESS',
  BANK_DETAILS_COLLECTED = 'BANK_DETAILS_COLLECTED',
  DELETED = 'DELETED',
  ENABLED = 'ENABLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  MANDATE_REGISTERED = 'MANDATE_REGISTERED',
  MANDATE_SUBMISSION_IN_PROGRESS = 'MANDATE_SUBMISSION_IN_PROGRESS',
  SETUP_INITIATED = 'SETUP_INITIATED',
}

export type CompanyPayInMethodWorkflowStep = PayInMethodWorkflowStep & {
  __typename?: 'CompanyPayInMethodWorkflowStep';
  /** @deprecated will not be populated as it is unnecessary */
  data: Array<Maybe<AccountDetails>>;
  status?: Maybe<CompanyPayInMethodWorkflowStatus>;
  step?: Maybe<PayInMethodDefinitionStep>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CompanyPayInPreference = {
  company: Company;
  id: Scalars['ID'];
  isPreferenceActive: Scalars['Boolean'];
  preferenceType: CompanyPayInPreferenceType;
};

export type CompanyPayInPreferenceAutoDebit = CompanyPayInPreference & {
  __typename?: 'CompanyPayInPreferenceAutoDebit';
  company: Company;
  defaultAutoDebitPayInMethod?: Maybe<CompanyPayInMethod>;
  id: Scalars['ID'];
  isAutoDebitEnabled: Scalars['Boolean'];
  isPreferenceActive: Scalars['Boolean'];
  preferenceType: CompanyPayInPreferenceType;
};

export enum CompanyPayInPreferenceType {
  AUTO_DEBIT = 'AUTO_DEBIT',
}

export type CompanyPayInSettings = {
  __typename?: 'CompanyPayInSettings';
  company?: Maybe<Company>;
  payInMethodRequirements?: Maybe<Array<Maybe<PayInMethodRequirement>>>;
  payInMethods: Array<Maybe<CompanyPayInMethod>>;
  payInPreferences?: Maybe<Array<Maybe<CompanyPayInPreference>>>;
};

export type CompanyPayInSettings_PayInMethodsArgs = {
  filters?: InputMaybe<PayInMethodFilters>;
};

export type CompanyPayable = {
  __typename?: 'CompanyPayable';
  company?: Maybe<Company>;
  createdOn?: Maybe<Scalars['DateTime']>;
  creditNote?: Maybe<CreditNote>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  items?: Maybe<Array<Maybe<PayableItem>>>;
  memberPayableCompanyInvoice?: Maybe<MemberPayableCompanyInvoice>;
  month?: Maybe<Scalars['Int']>;
  report?: Maybe<CompanyPayableReport>;
  status?: Maybe<PayableStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<CompanyPayableType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type CompanyPayableComponent = {
  __typename?: 'CompanyPayableComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<CompanyPayableComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum CompanyPayableComponentType {
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  DEDUCTIONS = 'DEDUCTIONS',
  OTHER = 'OTHER',
}

export type CompanyPayableFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdDateRange?: InputMaybe<DateRange>;
  invoiceNo?: InputMaybe<Scalars['String']>;
  monthYear?: InputMaybe<PayableMonthInput>;
  payableIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  payableType?: InputMaybe<CompanyPayableType>;
  queryMode?: InputMaybe<PayableQueryMode>;
  reference?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<PayableStatus>>>;
};

export type CompanyPayableInput = {
  autoSubmit?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Use companyIds instead */
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  forcedContractIds?: InputMaybe<Array<Scalars['ID']>>;
  invoiceDate?: InputMaybe<Scalars['DateTime']>;
  invoiceDueDate?: InputMaybe<Scalars['DateTime']>;
  invoiceMonth?: InputMaybe<PayableMonthInput>;
  invoiceReference?: InputMaybe<Scalars['String']>;
  invoiceType?: InputMaybe<Scalars['Int']>;
};

export type CompanyPayableInvoiceSourceReportExportInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /**
   * which invoice the data should be sourced from
   * 1 = First Invoice
   * 2 = Second Invoice
   */
  invoiceType?: InputMaybe<Scalars['Int']>;
  payableMonth?: InputMaybe<PayableMonthInput>;
  storeFile?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyPayableReport = {
  __typename?: 'CompanyPayableReport';
  companyId: Scalars['ID'];
  month: Scalars['Int'];
  type?: Maybe<CompanyPayableReportType>;
  year: Scalars['Int'];
};

export enum CompanyPayableReportType {
  GROSS_INVOICE_SOURCE_REPORT = 'GROSS_INVOICE_SOURCE_REPORT',
  SALARY_INVOICE_SOURCE_REPORT = 'SALARY_INVOICE_SOURCE_REPORT',
}

export type CompanyPayableSyncInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum CompanyPayableType {
  ANNUAL_PLAN = 'ANNUAL_PLAN',
  DEPOSIT = 'DEPOSIT',
  FIRST_INVOICE = 'FIRST_INVOICE',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL_FUNDING = 'GLOBAL_PAYROLL_FUNDING',
  INSURANCE = 'INSURANCE',
  SECOND_INVOICE = 'SECOND_INVOICE',
  UNKNOWN = 'UNKNOWN',
}

export type CompanyPayableWithErrors = {
  __typename?: 'CompanyPayableWithErrors';
  companyPayables?: Maybe<Array<Maybe<CompanyPayable>>>;
  errorResponses?: Maybe<Array<Maybe<TaskResponse>>>;
};

export type CompanyPayablesResult = {
  __typename?: 'CompanyPayablesResult';
  data?: Maybe<Array<Maybe<CompanyPayable>>>;
  pageResult?: Maybe<PageResult>;
};

export type CompanyPayroll = Payroll & {
  __typename?: 'CompanyPayroll';
  amountTotalCost?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  id?: Maybe<Scalars['ID']>;
  memberPays?: Maybe<Array<Maybe<CompanyMemberPay>>>;
  month?: Maybe<PayrollMonth>;
  status?: Maybe<CompanyPayrollStatus>;
};

export type CompanyPayrollConsolidatedReportDownloadRequest = {
  financialYears?: InputMaybe<Array<Scalars['Int']>>;
  isForced?: InputMaybe<Scalars['Boolean']>;
  payrollCycleEntityId?: InputMaybe<Scalars['ID']>;
  payrollType?: InputMaybe<PayrollCycleEntityType>;
  type: PayrollFileDownloadType;
};

export type CompanyPayrollCycle = {
  __typename?: 'CompanyPayrollCycle';
  company: Company;
  /** @deprecated No longer supported */
  companyId: Scalars['ID'];
  companyPayrollInput?: Maybe<CompanyPayrollInputResponse>;
  companyPayrollInputV2?: Maybe<CompanyPayrollInputContractResponse>;
  confirmByDate?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  cycleIds: Array<Scalars['ID']>;
  endDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<LegalEntity>;
  entityType: PayrollCycleEntityType;
  frequency?: Maybe<PayFrequency>;
  id: Scalars['ID'];
  payDate?: Maybe<Scalars['Date']>;
  payrollCutOffDate?: Maybe<Scalars['Date']>;
  payrollCycles?: Maybe<Array<PayrollCycle>>;
  payrollMonth?: Maybe<MonthYear>;
  startDate?: Maybe<Scalars['Date']>;
  status: CompanyPayrollCycleStatus;
  summary?: Maybe<CompanyPayrollCycleSummary>;
};

export type CompanyPayrollCycle_CompanyPayrollInputArgs = {
  filter?: InputMaybe<CompanyPayrollInputFilter>;
  pageRequest: PageRequest;
};

export type CompanyPayrollCycle_CompanyPayrollInputV2Args = {
  filter?: InputMaybe<CompanyPayrollInputContractFilter>;
  pageRequest: PageRequest;
};

export type CompanyPayrollCycleFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  endDate?: InputMaybe<Scalars['Date']>;
  frequencies?: InputMaybe<Array<PayFrequency>>;
  /** @deprecated Use query companyPayrollCycle with id instead */
  id?: InputMaybe<Scalars['ID']>;
  legalEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  payDate?: InputMaybe<DateRange>;
  /** @deprecated Use payrollCycleEntityTypes instead */
  payrollCycleEntityType?: InputMaybe<PayrollCycleEntityType>;
  payrollCycleEntityTypes?: InputMaybe<Array<PayrollCycleEntityType>>;
  payrollCycleTypes?: InputMaybe<Array<PayrollCycleType>>;
  payrollMonth?: InputMaybe<MonthYearInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<Array<CompanyPayrollCycleStatus>>;
};

export type CompanyPayrollCycleReportFilter = {
  companyPayrollCycleId: Scalars['ID'];
  reportType: CompanyPayrollReport;
};

export enum CompanyPayrollCycleStatus {
  COMPLETED = 'COMPLETED',
  CONFIRM_REPORT = 'CONFIRM_REPORT',
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  REPORT = 'REPORT',
  REVIEW_INPUT = 'REVIEW_INPUT',
}

export type CompanyPayrollCycleSummary = {
  __typename?: 'CompanyPayrollCycleSummary';
  countryTotalCounts?: Maybe<Array<Maybe<CountryCount>>>;
  memberTotalCount?: Maybe<Scalars['Int']>;
};

export type CompanyPayrollCyclesResult = {
  __typename?: 'CompanyPayrollCyclesResult';
  data?: Maybe<Array<CompanyPayrollCycle>>;
  pageResult: PageResult;
};

export type CompanyPayrollFileDownloadRequest = {
  companyPayrollCycleId: Scalars['ID'];
  isForced?: InputMaybe<Scalars['Boolean']>;
  type: PayrollFileDownloadType;
};

export type CompanyPayrollFilter = {
  companyId: Scalars['ID'];
  companyPayrollCycleId: Scalars['ID'];
  country?: InputMaybe<CountryCode>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanyPayrollInputContractFilter = {
  anyBankDetailsChange?: InputMaybe<Scalars['Boolean']>;
  anyCompensationChange?: InputMaybe<Scalars['Boolean']>;
  anyVariance?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<CountryCode>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
};

export type CompanyPayrollInputContractResponse = {
  __typename?: 'CompanyPayrollInputContractResponse';
  data?: Maybe<Array<PayrollInputContract>>;
  pageResult: PageResult;
};

export type CompanyPayrollInputEntry = {
  __typename?: 'CompanyPayrollInputEntry';
  allowance: Scalars['Float'];
  baseSalary: Scalars['Float'];
  companyCurrency: CurrencyCode;
  contract: Contract;
  /** @deprecated No longer supported */
  contractId: Scalars['ID'];
  contractPayrollLabels: Array<ContractPayrollLabel>;
  contractStatus: ContractStatus;
  country: CountryCode;
  expenses: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  memberCurrency: CurrencyCode;
  memberName: Scalars['String'];
  memberToCompanyCurrencyExchangeRate: Scalars['Float'];
  payFrequency: PayFrequency;
  paySuplements: Scalars['Float'];
  payrollContractTypes?: Maybe<Array<PayrollContractType>>;
  rateFrequency: RateFrequency;
  timeoff: Scalars['Float'];
  workDays: Scalars['Float'];
};

export type CompanyPayrollInputFilter = {
  anyBankDetailsChange?: InputMaybe<Scalars['Boolean']>;
  anyCompensationChange?: InputMaybe<Scalars['Boolean']>;
  anyVariance?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<CountryCode>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
};

export type CompanyPayrollInputReportFilter = {
  companyId: Scalars['ID'];
  companyPayrollCycleId: Scalars['ID'];
  companyPayrollInputFilter?: InputMaybe<CompanyPayrollInputFilter>;
};

export type CompanyPayrollInputResponse = {
  __typename?: 'CompanyPayrollInputResponse';
  data: Array<CompanyPayrollInputEntry>;
  pageResult: PageResult;
};

export enum CompanyPayrollReport {
  ALL_PAYROLL_REPORTS = 'ALL_PAYROLL_REPORTS',
  ALL_PAYSLIPS = 'ALL_PAYSLIPS',
  BANK_SUMMARY = 'BANK_SUMMARY',
  EMPLOYEE_SUMMARY = 'EMPLOYEE_SUMMARY',
  EMPLOYER_SUMMARY = 'EMPLOYER_SUMMARY',
  GENERAL_LEDGER = 'GENERAL_LEDGER',
  GROSS_TO_NET = 'GROSS_TO_NET',
  PAYMENT_INSTRUCTION_FILE = 'PAYMENT_INSTRUCTION_FILE',
  PAYROLL_INPUT = 'PAYROLL_INPUT',
  TOTAL_PAYROLL_COST = 'TOTAL_PAYROLL_COST',
  VARIANCE_INPUT = 'VARIANCE_INPUT',
  VARIANCE_OUTPUT = 'VARIANCE_OUTPUT',
}

export type CompanyPayrollReportExportInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  compare?: InputMaybe<ReportComparisonInput>;
  contractId?: InputMaybe<Scalars['ID']>;
  countryCode?: InputMaybe<CountryCode>;
  frequency?: InputMaybe<PayFrequency>;
  memberNameSearchTerm?: InputMaybe<Scalars['String']>;
  payDateRange?: InputMaybe<DateRange>;
  payPeriodDateRange?: InputMaybe<DateRange>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  payrollMonth?: InputMaybe<PayrollMonthInput>;
  payrollMonths?: InputMaybe<PayrollMonthRangeInput>;
  reportType?: InputMaybe<ExportPayrollReportType>;
};

export type CompanyPayrollReportInput = {
  filters: CompanyPayrollFilter;
  reportType: ExportPayrollReportType;
};

export enum CompanyPayrollStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type CompanyPreFillData = {
  __typename?: 'CompanyPreFillData';
  domain?: Maybe<Scalars['String']>;
  logo?: Maybe<DocumentReadable>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyPreFillDataInput = {
  domain: Scalars['String'];
};

export type CompanyPricingPlanMigrateInput = {
  companyId: Scalars['ID'];
  paymentTerm: PaymentTermInput;
};

export type CompanyPricingPlanMigrateResponse = {
  __typename?: 'CompanyPricingPlanMigrateResponse';
  companyId: Scalars['ID'];
  successful: Scalars['Boolean'];
};

export type CompanyQuotation = {
  __typename?: 'CompanyQuotation';
  billingCurrency?: Maybe<CurrencyCode>;
  companyCountry?: Maybe<CountryCode>;
  companyName?: Maybe<Scalars['String']>;
  costOverview?: Maybe<QuotationCostOverview>;
  countryWiseBreakdowns?: Maybe<
    Array<Maybe<QuotationCountryWiseCostBreakdown>>
  >;
  countryWiseSummaries?: Maybe<Array<Maybe<QuotationCountryWiseCostSummary>>>;
  createdBy?: Maybe<OperationsUser>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  includeDepositAmount?: Maybe<Scalars['Boolean']>;
  includeManagementFee?: Maybe<Scalars['Boolean']>;
  memberInputs?: Maybe<Array<Maybe<QuotationMemberInputData>>>;
  memberWiseSummaries?: Maybe<Array<Maybe<QuotationMemberWiseSummary>>>;
};

export type CompanyQuotationFilter = {
  companyName?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  createdOn?: InputMaybe<DateRange>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanyQuotationGenerationInput = {
  billingCurrency: CurrencyCode;
  companyCountry?: InputMaybe<CountryCode>;
  companyName: Scalars['String'];
  includeDepositAmount: Scalars['Boolean'];
  includeManagementFee: Scalars['Boolean'];
  memberInputs: Array<QuotationMemberInput>;
};

export type CompanyQuotationsResponse = {
  __typename?: 'CompanyQuotationsResponse';
  data?: Maybe<Array<Maybe<CompanyQuotation>>>;
  pageResult?: Maybe<PageResult>;
};

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
  INVITE_ACCEPTED = 'INVITE_ACCEPTED',
  UNVERIFIED = 'UNVERIFIED',
}

export type CompanySync = {
  __typename?: 'CompanySync';
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<SyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanySyncStatus = {
  __typename?: 'CompanySyncStatus';
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<SyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyTimeOffPolicyFilter = {
  entityIds?: InputMaybe<Array<Scalars['ID']>>;
  timeOffPolicyIds?: InputMaybe<Array<Scalars['ID']>>;
  timeOffTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanyUpdateBasicDetailsInput = {
  companyId: Scalars['ID'];
  currency: CurrencyCode;
  displayName: Scalars['String'];
  legalName: Scalars['String'];
  registrationNo: Scalars['String'];
  sfdcAccountNo?: InputMaybe<Scalars['String']>;
};

export type CompanyUpdateContactDetailsInput = {
  address: AddressInput;
  companyId: Scalars['ID'];
  phoneNo: Scalars['String'];
};

export type CompanyUpdateInput = {
  address?: InputMaybe<AddressInput>;
  companyId?: InputMaybe<Scalars['ID']>;
  displayName?: InputMaybe<Scalars['String']>;
  financialYear?: InputMaybe<Scalars['Int']>;
  fxSensitivity?: InputMaybe<FxSensitivity>;
  phoneNo?: InputMaybe<Scalars['String']>;
  sfdcAccountNo?: InputMaybe<Scalars['String']>;
};

export type CompanyUser = Person & {
  __typename?: 'CompanyUser';
  approver?: Maybe<Approver>;
  capabilities?: Maybe<Array<Maybe<CompanyUserCapability>>>;
  company?: Maybe<Company>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  hasLogin?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isBillingContact?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isSignatory?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  /** @deprecated Use 'roles' instead. Now compnay user can have multiple roles: Manager, Admin, etc. */
  role?: Maybe<CompanyUserRole>;
  roles?: Maybe<Array<Maybe<CompanyUserRole>>>;
  rolesWithAttributes?: Maybe<Array<Maybe<CompanyUserRoleWithAttributes>>>;
  status?: Maybe<CompanyUserStatus>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CompanyUserAssignRoleAndAttributesInput = {
  companyUserId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  legalEntityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  role: CompanyUserRole;
};

export type CompanyUserAssignRoleInput = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeInput>>>;
  companyUserIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  /** @deprecated use attributes instead */
  legalEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  role: CompanyUserRole;
};

export enum CompanyUserCapability {
  BILLING_CONTACT = 'BILLING_CONTACT',
  SIGNATORY = 'SIGNATORY',
}

export type CompanyUserEditRoleAndAttributesInput = {
  companyUserId?: InputMaybe<Scalars['ID']>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  legalEntityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  role?: InputMaybe<CompanyUserRole>;
};

export enum CompanyUserRole {
  ADMIN = 'ADMIN',
  BILLING_ADMIN = 'BILLING_ADMIN',
  HR_ADMIN = 'HR_ADMIN',
  MANAGER = 'MANAGER',
  PAYROLL_ADMIN = 'PAYROLL_ADMIN',
  PRIMARY_ADMIN = 'PRIMARY_ADMIN',
}

export type CompanyUserRoleWithAttributes = {
  __typename?: 'CompanyUserRoleWithAttributes';
  departmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  legalEntityIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  role?: Maybe<CompanyUserRole>;
};

export enum CompanyUserStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  INVITED = 'INVITED',
}

export type CompanyVisaGlobalPricingInput = {
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
};

export type CompanyVisaPricingInput = {
  country?: InputMaybe<CountryCode>;
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
  validUntil?: InputMaybe<Scalars['Date']>;
};

export type Compensation = {
  __typename?: 'Compensation';
  additional?: Maybe<Scalars['String']>;
  additionalPays?: Maybe<Array<Maybe<CompensationPayComponent>>>;
  basePay?: Maybe<FixedPayComponent>;
  deductions?: Maybe<Array<Maybe<Deduction>>>;
  grant?: Maybe<Array<Maybe<Grant>>>;
  /** @deprecated No longer supported */
  other?: Maybe<Array<Maybe<FixedPayComponent>>>;
  payType?: Maybe<PayType>;
  payrollStart?: Maybe<Scalars['DateTime']>;
  postProbationBasePay?: Maybe<FixedPayComponent>;
  probationBasePay?: Maybe<FixedPayComponent>;
};

export type Compensation_GrantArgs = {
  contractId: Scalars['ID'];
};

export type CompensationComponent = {
  __typename?: 'CompensationComponent';
  category: Scalars['String'];
  country: CountryCode;
  entityId: Scalars['ID'];
  id: Scalars['UUID'];
  isCash: Scalars['Boolean'];
  isFixed: Scalars['Boolean'];
  isProrated: Scalars['Boolean'];
  name: Scalars['String'];
  taxable: Scalars['Boolean'];
  validForTaxExemption: Scalars['Boolean'];
};

export type CompensationInsightDefinition = InsightDefinition & {
  __typename?: 'CompensationInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  compensationStructureDefinition?: Maybe<CompensationPayComponentDefinition>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type CompensationPayComponent = {
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<PayAmountType>;
  condition?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  frequency?: Maybe<RateFrequency>;
  id?: Maybe<Scalars['ID']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  paymentFrequency?: Maybe<PayFrequency>;
  paymentFrequencyDate?: Maybe<Array<Maybe<PayFrequencyDate>>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CompensationPayComponentDefinition = {
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<RateFrequency>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  rateType?: Maybe<RateType>;
};

/**
 * Except `id`, all are optional.
 * E.g. 1: to update label and amount: {id: 123, label: "New Label", amount: 5000}
 * E.g. 2: to update paymentFrequency and isActive: {id: 123, paymentFrequency: "SEMIMONTHLY", isActive: true}
 * E.g. 3: a useless request that does not update anything: {id: 123}
 */
export type CompensationRecordUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  amountType?: InputMaybe<PayAmountType>;
  condition?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  frequency?: InputMaybe<RateFrequency>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<PayFrequencyDateInput>>;
  rateType?: InputMaybe<RateType>;
  status?: InputMaybe<CompensationStatus>;
  validFromInclusive?: InputMaybe<Scalars['Date']>;
  validToExclusive?: InputMaybe<Scalars['Date']>;
};

export type CompensationSchema = {
  __typename?: 'CompensationSchema';
  components: Array<CompensationComponent>;
};

export type CompensationSnapshot = {
  __typename?: 'CompensationSnapshot';
  amount?: Maybe<Scalars['Float']>;
  compensationId: Scalars['ID'];
  currency?: Maybe<CurrencyCode>;
  rateType?: Maybe<RateType>;
  type?: Maybe<Scalars['String']>;
};

export enum CompensationStatus {
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  UNCHANGED = 'UNCHANGED',
}

export type CompensationStructureDefinition = {
  __typename?: 'CompensationStructureDefinition';
  additionalPays?: Maybe<Array<Maybe<CompensationPayComponentDefinition>>>;
  allowCustomFixedComponents?: Maybe<Scalars['Boolean']>;
  basePay?: Maybe<FixedPayComponentDefinition>;
  countryCode?: Maybe<CountryCode>;
  countryState?: Maybe<State>;
  deductions?: Maybe<Array<Maybe<DeductionDefinition>>>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  other?: Maybe<Array<Maybe<FixedPayComponentDefinition>>>;
  showAdditionalRequirementInput?: Maybe<Scalars['Boolean']>;
};

export type Compliance = {
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type Compliance_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceAssessmentDataInput = {
  data?: InputMaybe<Array<InputMaybe<ComplianceDataRequirementInput>>>;
  id: Scalars['ID'];
};

export type ComplianceAssessmentDocumentsInput = {
  documents?: InputMaybe<Array<InputMaybe<ComplianceDocumentRequirementInput>>>;
  id: Scalars['ID'];
  reports?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ComplianceContractor = Compliance & {
  __typename?: 'ComplianceContractor';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  orderForm?: Maybe<DocumentReadable>;
  orderFormTemplate?: Maybe<DocumentReadable>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type ComplianceContractor_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceDataInput = {
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
  value?: InputMaybe<Scalars['String']>;
};

export type ComplianceDataRequirementInput = {
  data: Array<InputMaybe<ComplianceDataInput>>;
  kybRequirementType: Scalars['String'];
};

export enum ComplianceDefinitionScope {
  COUNTRY_SPECIFIC = 'COUNTRY_SPECIFIC',
  DEFAULT = 'DEFAULT',
}

export type ComplianceDocumentInput = {
  documentList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
};

export type ComplianceDocumentRequirementInput = {
  data: Array<InputMaybe<ComplianceDocumentInput>>;
  kybRequirementType: Scalars['String'];
};

export type ComplianceFreelance = Compliance & {
  __typename?: 'ComplianceFreelance';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  freelancerType?: Maybe<FreelancerType>;
  legalEntity?: Maybe<LegalEntity>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type ComplianceFreelance_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceInsightDefinition = InsightDefinition & {
  __typename?: 'ComplianceInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  complianceParam?: Maybe<ComplianceParam>;
  complianceParamPeriodUnit?: Maybe<ComplianceParamPeriodUnit>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  recommendedValue?: Maybe<Scalars['Float']>;
};

export type ComplianceJoiningCheckpoints = {
  __typename?: 'ComplianceJoiningCheckpoints';
  earliestVisaCompletionDate?: Maybe<Scalars['Date']>;
};

export type ComplianceJoiningDateRestriction = {
  __typename?: 'ComplianceJoiningDateRestriction';
  checkpoints?: Maybe<ComplianceJoiningCheckpoints>;
  earliestJoiningDate: Scalars['Date'];
  holidays: Array<Holiday>;
};

export type ComplianceLimitationDefinition = {
  __typename?: 'ComplianceLimitationDefinition';
  countryState?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  preconditions?: Maybe<Array<ComplianceLimitationPrecondition>>;
  value: Scalars['String'];
};

export type ComplianceLimitationPrecondition = {
  __typename?: 'ComplianceLimitationPrecondition';
  contractTerm?: Maybe<ContractTerm>;
  contractType?: Maybe<ContractType>;
};

export type ComplianceMultiplierEor = Compliance & {
  __typename?: 'ComplianceMultiplierEOR';
  certificateOfEmployment?: Maybe<DocumentReadable>;
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  offerLetter?: Maybe<DocumentReadable>;
  preferredContractAgreementTemplate?: Maybe<PreferredContractAgreementTemplateResponse>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type ComplianceMultiplierEor_CertificateOfEmploymentArgs = {
  date: Scalars['Date'];
};

export type ComplianceMultiplierEor_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type CompliancePeo = Compliance & {
  __typename?: 'CompliancePEO';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type CompliancePeo_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceParam = {
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export enum ComplianceParamDataFieldType {
  DROPDOWN = 'DROPDOWN',
  TEXT = 'TEXT',
  TOGGLE = 'TOGGLE',
}

export type ComplianceParamDefinition = {
  __typename?: 'ComplianceParamDefinition';
  dataFieldType?: Maybe<ComplianceParamDataFieldType>;
  dependencies?: Maybe<Array<Maybe<ComplianceParam>>>;
  editable?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  param?: Maybe<ComplianceParam>;
  required?: Maybe<Scalars['Boolean']>;
  validation?: Maybe<Array<Maybe<ComplianceParamValidation>>>;
};

export type ComplianceParamPeriodLimit = ComplianceParam & {
  __typename?: 'ComplianceParamPeriodLimit';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  unit?: Maybe<ComplianceParamPeriodUnit>;
  value?: Maybe<Scalars['Int']>;
};

export type ComplianceParamPeriodLimitInput = {
  key?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<ComplianceParamPeriodUnit>;
  value?: InputMaybe<Scalars['Int']>;
};

export type ComplianceParamPeriodLimitUnitValidation = {
  __typename?: 'ComplianceParamPeriodLimitUnitValidation';
  defaultValue?: Maybe<Scalars['Int']>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  unit?: Maybe<ComplianceParamPeriodUnit>;
};

export enum ComplianceParamPeriodUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  NONE = 'NONE',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export type ComplianceParamType = {
  __typename?: 'ComplianceParamType';
  dataFieldType?: Maybe<ComplianceParamDataFieldType>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
};

export type ComplianceParamTypeInput = {
  dataFieldType?: InputMaybe<ComplianceParamDataFieldType>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceParamValidation = ComplianceParamPeriodLimitUnitValidation;

export type CompliancePartnerEor = Compliance & {
  __typename?: 'CompliancePartnerEOR';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  offerLetter?: Maybe<DocumentReadable>;
  partner?: Maybe<EorPartnerCountry>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type CompliancePartnerEor_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceRequirementDefinition = {
  __typename?: 'ComplianceRequirementDefinition';
  paramDefinitions?: Maybe<Array<Maybe<ComplianceParamDefinition>>>;
  usedScope?: Maybe<ComplianceDefinitionScope>;
};

export type ComplianceRiskAssessment = KybRiskAssessment & {
  __typename?: 'ComplianceRiskAssessment';
  collectedRiskData?: Maybe<KybCollectedRiskData>;
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  riskScore?: Maybe<AssessmentRiskScore>;
  schema?: Maybe<KybSchema>;
  status?: Maybe<RiskAssessmentStatus>;
  type: AssessmentType;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type ComponentAccessibilityResponse = {
  __typename?: 'ComponentAccessibilityResponse';
  page: MemberExperiencePage;
  sections?: Maybe<Array<Maybe<PageSection>>>;
};

export type ComponentCategory = {
  __typename?: 'ComponentCategory';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export enum ComponentType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
  VARIABLE_AMOUNT = 'VARIABLE_AMOUNT',
  VARIABLE_PERCENTAGE = 'VARIABLE_PERCENTAGE',
}

export type Condition = {
  __typename?: 'Condition';
  key: ConditionKey;
  operator: ConditionOperator;
  values?: Maybe<Array<Scalars['String']>>;
};

export type ConditionInput = {
  key: ConditionKey;
  operator: ConditionOperator;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export enum ConditionKey {
  COUNTRY = 'COUNTRY',
  DEPARTMENT = 'DEPARTMENT',
  ENTITY = 'ENTITY',
  GENDER = 'GENDER',
  NAME = 'NAME',
}

export enum ConditionOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
}

export type Configuration = {
  __typename?: 'Configuration';
  bankTransferFee?: Maybe<Scalars['Float']>;
  contractChangeRequestConfig?: Maybe<ContractChangeRequestConfig>;
  id?: Maybe<Scalars['ID']>;
};

export type Configuration_BankTransferFeeArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type Configuration_ContractChangeRequestConfigArgs = {
  filters?: InputMaybe<ChangeRequestConfigFiltersInput>;
};

export type ConfirmPayslipsResponse = {
  __typename?: 'ConfirmPayslipsResponse';
  confirmPayslipsSummary?: Maybe<ConfirmedPayslipsSummary>;
  confirmedPayslips?: Maybe<Array<Maybe<Payslip>>>;
};

export type ConfirmedPayslipsSummary = {
  __typename?: 'ConfirmedPayslipsSummary';
  confirmedPayslipsCount?: Maybe<Scalars['Int']>;
  nonConfirmedPayslipsCount?: Maybe<Scalars['Int']>;
  totalMemberCount?: Maybe<Scalars['Int']>;
};

export type ConstantOverride = {
  name: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
};

export type ContactEmailInput = {
  companyPayableID?: InputMaybe<Scalars['ID']>;
  contractID?: InputMaybe<Scalars['ID']>;
};

export type Contract = {
  __typename?: 'Contract';
  activatedOn?: Maybe<Scalars['DateTime']>;
  addendums?: Maybe<Array<Maybe<ContractAddendum>>>;
  alreadyHired?: Maybe<Scalars['Boolean']>;
  benefits?: Maybe<Array<Maybe<ContractBenefit>>>;
  /**
   * To know expense/timeoff approvers of the contract.<br>
   * Useful for the company admin when assigning members to managers, or for the member when submitting a timeoff/expense
   */
  categoryApproverInfos?: Maybe<Array<Maybe<CategoryApproverInfo>>>;
  changeRequests?: Maybe<Array<ContractChangeRequest>>;
  company?: Maybe<Company>;
  compensation?: Maybe<Compensation>;
  compliance?: Maybe<Compliance>;
  contractBlocked?: Maybe<Scalars['Boolean']>;
  contractDataChanges?: Maybe<Array<Maybe<DataChange>>>;
  contractLatestDataChange?: Maybe<Array<Maybe<DataChange>>>;
  contractPaymentAccount?: Maybe<ContractPaymentAccount>;
  contractStarted?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryCode>;
  countryStateCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  depositAmount?: Maybe<Scalars['Float']>;
  depositPayable?: Maybe<Array<Maybe<CompanyPayable>>>;
  employeeId?: Maybe<Scalars['String']>;
  endOn?: Maybe<Scalars['DateTime']>;
  endedOn?: Maybe<Scalars['DateTime']>;
  eorPartner?: Maybe<ContractEorPartner>;
  equipmentProcurements?: Maybe<Array<EquipmentProcurement>>;
  equipments?: Maybe<Array<Equipment>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fieldAccessibilityRules?: Maybe<Array<Maybe<FieldAccessibilityRule>>>;
  id?: Maybe<Scalars['ID']>;
  incomingPayrollCutoffDate?: Maybe<Scalars['Date']>;
  isOffboardingV2Supported?: Maybe<OffboardingCanInitializeValidation>;
  isPayrollActive?: Maybe<Scalars['Boolean']>;
  isPickedUpForPayroll?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isTestContract?: Maybe<Scalars['Boolean']>;
  isValidLastWorkingDay?: Maybe<LastWorkingDayValidation>;
  lastWorkingDay?: Maybe<Scalars['Date']>;
  legalEntityId?: Maybe<Scalars['ID']>;
  member?: Maybe<Member>;
  /**
   * MemberPayable list can filter by:
   * 1. id: memberPayable id
   * 2. status: memberPayable status
   * 3. fromDate: checks if payableFromDate greater than or equal to given date.
   * 4. toDate: checks if payableToDate less than or equal to given date.
   * 5. referenceId: company invoice referenceId, checks for referenceId starting with given value
   * 6. createdDateRange: checked against memberPayable createdOn date
   */
  memberPayables?: Maybe<Array<Maybe<MemberPayable>>>;
  multiplierLegalEntityId?: Maybe<Scalars['ID']>;
  offboarding?: Maybe<ContractOffboarding>;
  offboardingAudit?: Maybe<OffboardingAudit>;
  offboardingNote?: Maybe<Scalars['String']>;
  offboardingV2?: Maybe<Offboarding>;
  onboarding?: Maybe<ContractOnboarding>;
  orgAttributes?: Maybe<OrgAttributes>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  paymentAccount?: Maybe<PaymentAccount>;
  /**
   * Ideally it should be https://app.clickup.com/t/3rch1m9, but we need a quick solution now, so doing this.<br>
   * Of course can't define another `paymentAccount: PaymentAccount` because supergraph will fail to build due to "already defined in payment-account.graphql
   * @deprecated No longer supported
   */
  paymentAccountId?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  paymentAccountRequirements?: Maybe<Array<Maybe<PaymentAccountRequirement>>>;
  payrollNote?: Maybe<Scalars['String']>;
  payrollPartner?: Maybe<ContractPayrollPartner>;
  payrolls?: Maybe<Array<Maybe<MemberPay>>>;
  payslips?: Maybe<Array<Maybe<Payslip>>>;
  performanceReviews?: Maybe<Array<Maybe<PerformanceReview>>>;
  position?: Maybe<Scalars['String']>;
  renewal?: Maybe<ContractRenewalInfo>;
  reportsToManager?: Maybe<Manager>;
  salaryReviews?: Maybe<Array<Maybe<SalaryReview>>>;
  scope?: Maybe<Scalars['String']>;
  startOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ContractStatus>;
  term?: Maybe<ContractTerm>;
  timeOff?: Maybe<ContractTimeOff>;
  timeOffs?: Maybe<Array<Maybe<TimeOff>>>;
  timesheets?: Maybe<Array<Maybe<Timesheet>>>;
  type?: Maybe<ContractType>;
  unresolvedBlockEvents?: Maybe<Array<BlockEvent>>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  workEmail?: Maybe<Scalars['String']>;
  /** Contract's standard working days and hours. (E.g: from Monday to Friday, from 8:30 to 17:30) */
  workShift?: Maybe<WorkShift>;
  workStatus?: Maybe<CountryWorkStatus>;
};

export type Contract_AddendumsArgs = {
  addendumIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Contract_BenefitsArgs = {
  contractBenefitFilters?: InputMaybe<ContractBenefitFilters>;
};

export type Contract_CategoryApproverInfosArgs = {
  activeManagersOnly?: InputMaybe<Scalars['Boolean']>;
  returnAdminsIfNoManagers?: InputMaybe<Scalars['Boolean']>;
};

export type Contract_ChangeRequestsArgs = {
  changeRequestIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Contract_ContractDataChangesArgs = {
  status?: InputMaybe<DataChangeStatus>;
};

export type Contract_ExpensesArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<ExpenseStatus>;
  statuses?: InputMaybe<Array<ExpenseStatus>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Contract_IsOffboardingV2SupportedArgs = {
  offboardingType: OffboardingType;
};

export type Contract_IsPickedUpForPayrollArgs = {
  payslipMonth: MonthYearInput;
};

export type Contract_IsValidLastWorkingDayArgs = {
  dateToCheck: Scalars['Date'];
  offboardingType: ContractOffboardingType;
};

export type Contract_MemberPayablesArgs = {
  createdDateRange?: InputMaybe<DateRange>;
  externalId?: InputMaybe<Scalars['ID']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  paymentMethod?: InputMaybe<TransferType>;
  payoutDateRange?: InputMaybe<DateRange>;
  referenceId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MemberPayableStatus>;
  toDate?: InputMaybe<Scalars['Date']>;
  type?: InputMaybe<MemberPayableType>;
};

export type Contract_PaySupplementsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PaySupplementStatus>;
  statuses?: InputMaybe<Array<PaySupplementStatus>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Contract_PaymentAccountArgs = {
  id: Scalars['ID'];
};

export type Contract_PayrollsArgs = {
  memberPayId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<MonthYearInput>;
};

export type Contract_PayslipsArgs = {
  filters?: InputMaybe<PaySlipFilters>;
  payrollMonth?: InputMaybe<MonthYearInput>;
};

export type Contract_PerformanceReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PerformanceReviewStatus>;
};

export type Contract_SalaryReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<SalaryReviewStatus>;
};

export type Contract_TimeOffArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Contract_TimeOffsArgs = {
  filters?: InputMaybe<TimeOffFilter>;
};

export type Contract_TimesheetsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ContractAddendum = {
  __typename?: 'ContractAddendum';
  addendumOnboarding?: Maybe<ContractAddendumOnboarding>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<DocumentReadable>;
  id?: Maybe<Scalars['ID']>;
};

export type ContractAddendumOnboarding = {
  __typename?: 'ContractAddendumOnboarding';
  id?: Maybe<Scalars['ID']>;
  workflows?: Maybe<Array<Maybe<ContractAddendumWorkflow>>>;
};

export enum ContractAddendumStep {
  GENERATE = 'GENERATE',
  SENT = 'SENT',
  SIGNING = 'SIGNING',
}

export type ContractAddendumWorkflow = {
  __typename?: 'ContractAddendumWorkflow';
  currentStatus?: Maybe<ContractAddendumWorkflowStatus>;
  experience?: Maybe<Persona>;
};

export enum ContractAddendumWorkflowStatus {
  ADDENDUM_GENERATED = 'ADDENDUM_GENERATED',
  ADDENDUM_GENERATION_PENDING = 'ADDENDUM_GENERATION_PENDING',
  ADDENDUM_SENT_FOR_SIGNING = 'ADDENDUM_SENT_FOR_SIGNING',
  ADDENDUM_SIGNED_COMPANY = 'ADDENDUM_SIGNED_COMPANY',
  ADDENDUM_SIGNED_MEMBER = 'ADDENDUM_SIGNED_MEMBER',
  ADDENDUM_SIGNED_MULTIPLIER = 'ADDENDUM_SIGNED_MULTIPLIER',
}

export type ContractAddendums = {
  __typename?: 'ContractAddendums';
  addendums?: Maybe<Array<Maybe<ContractAddendum>>>;
};

export enum ContractAgreementType {
  CUSTOM_TEMPLATE = 'CUSTOM_TEMPLATE',
  MULTIPLIER_TEMPLATE = 'MULTIPLIER_TEMPLATE',
  UPLOADED_FINAL = 'UPLOADED_FINAL',
}

export type ContractBenefit = {
  __typename?: 'ContractBenefit';
  benefit?: Maybe<Benefit>;
  /** @deprecated No longer supported */
  benefitEndDate?: Maybe<Scalars['Date']>;
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  billAmount?: Maybe<Scalars['Float']>;
  changeRequests?: Maybe<Array<Maybe<ContractBenefitChangeRequestResponse>>>;
  contract?: Maybe<Contract>;
  dependentCount?: Maybe<Scalars['Int']>;
  dependents?: Maybe<Array<Maybe<ContractBenefitDependent>>>;
  discountTermPercentage?: Maybe<Scalars['Float']>;
  employeePayPercentage?: Maybe<Scalars['Float']>;
  employerPayPercentage?: Maybe<Scalars['Float']>;
  endOn?: Maybe<Scalars['DateTime']>;
  externalPolicyFileUrl?: Maybe<Scalars['String']>;
  externalPolicyId?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<ContractBenefitDocument>>>;
  id?: Maybe<Scalars['ID']>;
  insurancePremium?: Maybe<InsurancePremium>;
  params?: Maybe<Array<Maybe<ContractBenefitParam>>>;
  pendingChangeRequestsCount?: Maybe<Scalars['Int']>;
  startOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ContractBenefitStatus>;
};

export type ContractBenefit_ChangeRequestsArgs = {
  statuses?: InputMaybe<Array<ContractTypeChangeRequestStatus>>;
};

export type ContractBenefit_DependentsArgs = {
  dependentPlan?: InputMaybe<BenefitTier>;
};

export type ContractBenefitAddingDependentWorkflow = {
  __typename?: 'ContractBenefitAddingDependentWorkflow';
  currentStep?: Maybe<ContractBenefitAddingDependentWorkflowStep>;
  steps?: Maybe<Array<Maybe<ContractBenefitAddingDependentWorkflowStep>>>;
};

export enum ContractBenefitAddingDependentWorkflowStep {
  CAPTURE_BASIC_DETAILS = 'CAPTURE_BASIC_DETAILS',
  CAPTURE_PLAN_DETAILS = 'CAPTURE_PLAN_DETAILS',
  CONFIRM = 'CONFIRM',
  UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
}

export type ContractBenefitBanner = {
  __typename?: 'ContractBenefitBanner';
  contractId: Scalars['ID'];
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: ContractBenefitBannerStatus;
  type?: Maybe<ContractBenefitBannerType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ContractBenefitBannerFilters = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  statuses?: InputMaybe<Array<ContractBenefitBannerStatus>>;
  types?: InputMaybe<Array<ContractBenefitBannerType>>;
};

export type ContractBenefitBannerInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<ContractBenefitBannerType>;
  visibleToAllActiveContractsWithDependents?: InputMaybe<Scalars['Boolean']>;
};

export enum ContractBenefitBannerStatus {
  ACTIVE = 'ACTIVE',
  APPROVED_BY_MEMBER = 'APPROVED_BY_MEMBER',
  APPROVED_BY_OPS = 'APPROVED_BY_OPS',
  NO_ACTION_FROM_MEMBER = 'NO_ACTION_FROM_MEMBER',
  PARTIAL_REVIEW_BY_OPS = 'PARTIAL_REVIEW_BY_OPS',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED_BY_OPS = 'REJECTED_BY_OPS',
  SCHEDULED = 'SCHEDULED',
}

export enum ContractBenefitBannerType {
  DEPENDENT_DOB_CHANGE = 'DEPENDENT_DOB_CHANGE',
}

export type ContractBenefitChangeRequestChange = {
  __typename?: 'ContractBenefitChangeRequestChange';
  dependentChanges?: Maybe<
    Array<Maybe<ContractBenefitChangeRequestDependentDetailsChange>>
  >;
  /** @deprecated No longer supported */
  dependentDetailsChanges?: Maybe<ContractBenefitChangeRequestDependentDetailsChange>;
};

export type ContractBenefitChangeRequestChangeInput = {
  dependentDetailsChanges?: InputMaybe<
    Array<ContractBenefitChangeRequestDependentDetailsChangeInput>
  >;
};

export type ContractBenefitChangeRequestDependentDetails = {
  __typename?: 'ContractBenefitChangeRequestDependentDetails';
  field: Scalars['String'];
  newValue: Scalars['String'];
  oldValue: Scalars['String'];
};

export type ContractBenefitChangeRequestDependentDetailsChange = {
  __typename?: 'ContractBenefitChangeRequestDependentDetailsChange';
  changes?: Maybe<Array<Maybe<ContractBenefitChangeRequestDependentDetails>>>;
  contractBenefitDependentId: Scalars['ID'];
};

export type ContractBenefitChangeRequestDependentDetailsChangeInput = {
  changes: Array<ContractBenefitChangeRequestDependentDetailsInput>;
  contractBenefitDependentId: Scalars['ID'];
};

export type ContractBenefitChangeRequestDependentDetailsInput = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export type ContractBenefitChangeRequestFilters = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  dependentIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<ContractTypeChangeRequestStatus>;
};

export type ContractBenefitChangeRequestInput = {
  benefitContractBannerId?: InputMaybe<Scalars['ID']>;
  changes?: InputMaybe<ContractBenefitChangeRequestChangeInput>;
  contractId: Scalars['ID'];
  type: ContractBenefitChangeRequestType;
};

export type ContractBenefitChangeRequestResponse = {
  __typename?: 'ContractBenefitChangeRequestResponse';
  changes: ContractBenefitChangeRequestChange;
  contractId: Scalars['ID'];
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  status: ContractTypeChangeRequestStatus;
  type: ContractBenefitChangeRequestType;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum ContractBenefitChangeRequestType {
  CHANGE_DEPENDENT_DETAILS = 'CHANGE_DEPENDENT_DETAILS',
}

export type ContractBenefitChangeRequestUpdateInput = {
  id: Scalars['ID'];
  status: ContractTypeChangeRequestStatus;
};

export type ContractBenefitDependent = {
  __typename?: 'ContractBenefitDependent';
  addingWorkflow?: Maybe<ContractBenefitAddingDependentWorkflow>;
  benefit?: Maybe<Benefit>;
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  changeRequests?: Maybe<Array<Maybe<ContractBenefitChangeRequestResponse>>>;
  contractBenefit?: Maybe<ContractBenefit>;
  country?: Maybe<CountryCode>;
  countryStateCode?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<Maybe<ContractBenefitDependentDocument>>>;
  endOn?: Maybe<Scalars['DateTime']>;
  externalPolicyFileUrl?: Maybe<Scalars['String']>;
  externalPolicyId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  legalData?: Maybe<Array<Maybe<ContractBenefitDependentLegalData>>>;
  relationshipToMember?: Maybe<Relationship>;
  startOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ContractBenefitDependentStatus>;
};

export type ContractBenefitDependent_ChangeRequestsArgs = {
  statuses?: InputMaybe<Array<ContractTypeChangeRequestStatus>>;
};

export type ContractBenefitDependentDocument = {
  __typename?: 'ContractBenefitDependentDocument';
  /** @deprecated Use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  key?: Maybe<ContractBenefitDependentDocumentType>;
};

export enum ContractBenefitDependentDocumentType {
  HEALTH_CARD = 'HEALTH_CARD',
  OTHER = 'OTHER',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PROOF_OF_IDENTITY = 'PROOF_OF_IDENTITY',
  PROOF_OF_RELATIONSHIP = 'PROOF_OF_RELATIONSHIP',
}

export type ContractBenefitDependentLegalData = {
  __typename?: 'ContractBenefitDependentLegalData';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ContractBenefitDependentLegalDataInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum ContractBenefitDependentStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type ContractBenefitDocument = {
  __typename?: 'ContractBenefitDocument';
  /** @deprecated Use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  key?: Maybe<ContractBenefitDocumentType>;
};

export enum ContractBenefitDocumentType {
  HEALTH_CARD = 'HEALTH_CARD',
  OTHER = 'OTHER',
}

export type ContractBenefitFilters = {
  benefitType?: InputMaybe<BenefitType>;
  companyId?: InputMaybe<Scalars['ID']>;
  contractBenefitPlan?: InputMaybe<BenefitTier>;
  /** @deprecated. Use contractBenefitStatuses instead */
  contractBenefitStatus?: InputMaybe<ContractBenefitStatus>;
  contractBenefitStatuses?: InputMaybe<
    Array<InputMaybe<ContractBenefitStatus>>
  >;
  contractId?: InputMaybe<Scalars['ID']>;
  contractStartDateRange?: InputMaybe<DateRange>;
  contractStatuses?: InputMaybe<Array<InputMaybe<ContractStatus>>>;
  country?: InputMaybe<CountryCode>;
  coverageStartDateRange?: InputMaybe<DateRange>;
  dependentsPlan?: InputMaybe<BenefitTier>;
  hasDependents?: InputMaybe<Scalars['Boolean']>;
  memberNameContains?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<ContractOnboardingStatus>;
};

export type ContractBenefitParam = {
  __typename?: 'ContractBenefitParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ContractBenefitStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  NEW = 'NEW',
  OFFBOARDED = 'OFFBOARDED',
  ONBOARDING = 'ONBOARDING',
  OPTED_OUT = 'OPTED_OUT',
}

export type ContractBenefitWithPaginationFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractBenefitStatuses?: InputMaybe<Array<ContractBenefitStatus>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStartDateRange?: InputMaybe<DateRange>;
  contractStatus?: InputMaybe<Array<ContractStatus>>;
  countries?: InputMaybe<Array<CountryCode>>;
  coverageStartDateRange?: InputMaybe<DateRange>;
  hasPendingChangeRequests?: InputMaybe<Scalars['Boolean']>;
  onboardingStatuses?: InputMaybe<Array<ContractOnboardingStatus>>;
};

export type ContractBenefitsCountResult = {
  __typename?: 'ContractBenefitsCountResult';
  countriesCount?: Maybe<Scalars['Int']>;
  employeesCount?: Maybe<Scalars['Int']>;
};

export type ContractBenefitsResult = {
  __typename?: 'ContractBenefitsResult';
  countResult?: Maybe<ContractBenefitsCountResult>;
  data?: Maybe<Array<ContractBenefit>>;
  pageResult?: Maybe<PageResult>;
};

export type ContractBulkUpdateWorkShiftInput = {
  ids: Array<Scalars['ID']>;
  workShift?: InputMaybe<WorkShiftInput>;
};

export type ContractChangeRequest = {
  __typename?: 'ContractChangeRequest';
  contractId: Scalars['ID'];
  createdOn?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  status?: Maybe<ChangeRequestStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ContractChangeRequestConfig = {
  __typename?: 'ContractChangeRequestConfig';
  earliestJoiningDateWithTurnAround?: Maybe<Scalars['Date']>;
  turnAroundTimeInHours?: Maybe<Scalars['Int']>;
};

export type ContractChangeRequestUpdateInput = {
  changeRequestId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type ContractCreateInput = {
  alreadyHired?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['ID']>;
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  memberId: Scalars['ID'];
  position?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  term?: InputMaybe<ContractTerm>;
  type: ContractType;
  workEmail?: InputMaybe<Scalars['String']>;
  workShift?: InputMaybe<WorkShiftInput>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

/**
 * DB: country.contract_detail_restriction.
 * Case sensitive: No.
 */
export type ContractDetailRestriction = {
  __typename?: 'ContractDetailRestriction';
  alternatives?: Maybe<Array<Scalars['String']>>;
  countryCode?: Maybe<CountryCode>;
  field?: Maybe<ContractDetailRestrictionField>;
  id?: Maybe<Scalars['ID']>;
  operator?: Maybe<ContractDetailRestrictionOperator>;
  searchTerm?: Maybe<Scalars['String']>;
};

export enum ContractDetailRestrictionField {
  JOB_TITLE = 'JOB_TITLE',
}

export enum ContractDetailRestrictionOperator {
  CONTAIN = 'CONTAIN',
  EQUAL = 'EQUAL',
  START_WITH = 'START_WITH',
}

export enum ContractDocumentStatus {
  CONTRACT_NOT_SIGNED = 'CONTRACT_NOT_SIGNED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CUSTOM_CONTRACT = 'CUSTOM_CONTRACT',
  DRAFT_CONTRACT = 'DRAFT_CONTRACT',
  EMPLOYEE_SIGNED = 'EMPLOYEE_SIGNED',
  EMPLOYER_SIGNED = 'EMPLOYER_SIGNED',
  PREPARING_CONTRACT = 'PREPARING_CONTRACT',
  SIGNING_IN_PROGRESS = 'SIGNING_IN_PROGRESS',
}

export type ContractDocumentWorkflow = {
  __typename?: 'ContractDocumentWorkflow';
  currentStatus?: Maybe<ContractDocumentStatus>;
  statuses?: Maybe<Array<Maybe<ContractDocumentStatus>>>;
};

export type ContractDropInput = {
  contractId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
};

export type ContractEorPartner = {
  __typename?: 'ContractEORPartner';
  contract?: Maybe<Contract>;
  eorPartnerCountry?: Maybe<EorPartnerCountry>;
};

export type ContractFilters = {
  activationDateRange?: InputMaybe<DateRange>;
  assignedToOpsUser?: InputMaybe<Scalars['Boolean']>;
  benefitPackageName?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyNameContains?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['ID']>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  contractType?: InputMaybe<ContractType>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  country?: InputMaybe<CountryCode>;
  createdDateRange?: InputMaybe<DateRange>;
  csmUserId?: InputMaybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  depositInvoiceFullyPaidOnDateRange?: InputMaybe<DateRange>;
  /** @deprecated No longer supported */
  depositInvoiceId?: InputMaybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  depositInvoiceStatus?: InputMaybe<InvoiceStatus>;
  depositPayableFilters?: InputMaybe<DepositPayableFilters>;
  endedDateRange?: InputMaybe<DateRange>;
  eorPartnerId?: InputMaybe<Scalars['ID']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedStatuses?: InputMaybe<Array<ContractStatus>>;
  excludedTypes?: InputMaybe<Array<ContractType>>;
  isLegacyOffboarding?: InputMaybe<Scalars['Boolean']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  isPayrollActive?: InputMaybe<Scalars['Boolean']>;
  isTestContract?: InputMaybe<Scalars['Boolean']>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  memberEmailContains?: InputMaybe<Scalars['String']>;
  memberNameContains?: InputMaybe<Scalars['String']>;
  offboardingStatuses?: InputMaybe<Array<ContractOffboardingStatus>>;
  onboardingOperationsUserId?: InputMaybe<Scalars['ID']>;
  onboardingStatus?: InputMaybe<ContractOnboardingStatus>;
  onboardingStatuses?: InputMaybe<Array<ContractOnboardingStatus>>;
  payrollFormsUploadDateRange?: InputMaybe<DateRange>;
  payrollFormsUploadStatus?: InputMaybe<PayrollFormsUploadStatus>;
  payrollStartDateRange?: InputMaybe<DateRange>;
  requiresPayrollForms?: InputMaybe<Scalars['Boolean']>;
  startDateRange?: InputMaybe<DateRange>;
  toBeAssignedEorPartner?: InputMaybe<Scalars['Boolean']>;
  validTillDateRange?: InputMaybe<DateRange>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type ContractIdMappingUpload = {
  __typename?: 'ContractIdMappingUpload';
  contract?: Maybe<Contract>;
  inputJoiningDate?: Maybe<Scalars['DateTime']>;
  inputName?: Maybe<Scalars['String']>;
};

export type ContractOffboarding = {
  __typename?: 'ContractOffboarding';
  contractId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  didNotJoinReason?: Maybe<ContractOffboardingDidNotJoinReason>;
  documents?: Maybe<Array<Maybe<ContractOffboardingDocument>>>;
  id?: Maybe<Scalars['ID']>;
  initiatedBy?: Maybe<ContractOffboardingInitiator>;
  initiatedOn?: Maybe<Scalars['DateTime']>;
  lastWorkingDay?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
  severancePay?: Maybe<SeverancePayOption>;
  source?: Maybe<ContractOffboardingSource>;
  status?: Maybe<ContractOffboardingStatus>;
  terminationClauses?: Maybe<Array<Maybe<Scalars['String']>>>;
  terminationReason?: Maybe<Scalars['String']>;
  type?: Maybe<ContractOffboardingType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  verificationChecklist?: Maybe<ContractOffboardingVerificationChecklist>;
};

export type ContractOffboarding_DocumentsArgs = {
  type?: InputMaybe<ContractOffboardingDocumentType>;
};

export type ContractOffboardingCancelInput = {
  contractOffboardingId: Scalars['ID'];
};

export type ContractOffboardingCompleteInput = {
  contractOffboardingId: Scalars['ID'];
  finalSettlementProcessed?: InputMaybe<Scalars['String']>;
  insurance?: InputMaybe<Scalars['String']>;
  pendingExpenses?: InputMaybe<Scalars['String']>;
  pendingPaySupplements?: InputMaybe<Scalars['String']>;
  verificationChecklistDocuments?: InputMaybe<
    Array<InputMaybe<Scalars['Upload']>>
  >;
};

export type ContractOffboardingDidNotJoinInitialiseInput = {
  contractId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<ContractOffboardingDidNotJoinReason>;
};

export enum ContractOffboardingDidNotJoinReason {
  JOINED_ANOTHER_COMPANY = 'JOINED_ANOTHER_COMPANY',
  NO_INFORMATION = 'NO_INFORMATION',
  OTHERS = 'OTHERS',
}

export type ContractOffboardingDocument = {
  __typename?: 'ContractOffboardingDocument';
  contractId?: Maybe<Scalars['ID']>;
  contractOffboardingId?: Maybe<Scalars['ID']>;
  /** @deprecated Use `file` instead */
  document?: Maybe<DocumentReadable>;
  file?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ContractOffboardingDocumentType>;
};

export enum ContractOffboardingDocumentType {
  CHECKLIST = 'CHECKLIST',
  SUPPORTING = 'SUPPORTING',
}

export enum ContractOffboardingInitiator {
  EMPLOYER = 'EMPLOYER',
  OPERATIONS = 'OPERATIONS',
}

export type ContractOffboardingRescheduleInput = {
  contractOffboardingId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  revisedDate?: InputMaybe<Scalars['Date']>;
};

export type ContractOffboardingResignationInitialiseInput = {
  contractId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  lastWorkingDay?: InputMaybe<Scalars['Date']>;
  notes?: InputMaybe<Scalars['String']>;
};

export enum ContractOffboardingSource {
  EXTERNAL_SERVICE = 'EXTERNAL_SERVICE',
  MULTIPLIER_PLATFORM = 'MULTIPLIER_PLATFORM',
}

export enum ContractOffboardingStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FNF_PENDING = 'FNF_PENDING',
  INITIATED = 'INITIATED',
}

export type ContractOffboardingTerminationInitialiseInput = {
  clauses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contractId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  lastWorkingDay?: InputMaybe<Scalars['Date']>;
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  severancePay?: InputMaybe<SeverancePayOption>;
};

export enum ContractOffboardingType {
  DID_NOT_JOIN = 'DID_NOT_JOIN',
  RESIGNATION = 'RESIGNATION',
  TERMINATION = 'TERMINATION',
}

export type ContractOffboardingVerificationChecklist = {
  __typename?: 'ContractOffboardingVerificationChecklist';
  checklistDocuments?: Maybe<Array<Maybe<ContractOffboardingDocument>>>;
  contractId?: Maybe<Scalars['ID']>;
  contractOffboardingId?: Maybe<Scalars['ID']>;
  finalSettlementProcessed?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  insurance?: Maybe<Scalars['String']>;
  pendingExpenses?: Maybe<Scalars['String']>;
  pendingPaySupplements?: Maybe<Scalars['String']>;
};

export type ContractOffboardingVerifyInput = {
  contractOffboardingId: Scalars['ID'];
};

export type ContractOnboarding = {
  __typename?: 'ContractOnboarding';
  activationCutoff?: Maybe<Scalars['Date']>;
  activationCutoffData?: Maybe<ActivationCutoffData>;
  contract?: Maybe<Contract>;
  contractDocumentWorkflow?: Maybe<ContractDocumentWorkflow>;
  current?: Maybe<ContractOnboardingStep>;
  handler?: Maybe<OperationsUser>;
  id?: Maybe<Scalars['ID']>;
  payload?: Maybe<Array<Maybe<ContractOnboardingParam>>>;
  status?: Maybe<ContractOnboardingStatus>;
  statuses?: Maybe<Array<Maybe<ContractOnboardingStatus>>>;
  steps?: Maybe<Array<Maybe<ContractOnboardingStep>>>;
  tasks?: Maybe<Array<OnboardingTask>>;
};

export type ContractOnboardingInput = {
  completed?: InputMaybe<ContractOnboardingStep>;
  payload?: InputMaybe<ContractOnboardingParamInput>;
};

export type ContractOnboardingParam = {
  __typename?: 'ContractOnboardingParam';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ContractOnboardingParamInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum ContractOnboardingStatus {
  ACTIVE = 'ACTIVE',
  CONTRACT_PREPARING = 'CONTRACT_PREPARING',
  CONTRACT_PREPARING_CONFIRMATION = 'CONTRACT_PREPARING_CONFIRMATION',
  CONTRACT_UPDATING = 'CONTRACT_UPDATING',
  CONTRACT_WET_INK_PREPARING = 'CONTRACT_WET_INK_PREPARING',
  CREATED = 'CREATED',
  CREATED_CUSTOM = 'CREATED_CUSTOM',
  DRAFT = 'DRAFT',
  DROPPED = 'DROPPED',
  /** @deprecated No longer supported */
  ICA_SENT_TO_MULTIPLIER = 'ICA_SENT_TO_MULTIPLIER',
  /** @deprecated No longer supported */
  ICA_SIGNED_BY_MULTIPLIER = 'ICA_SIGNED_BY_MULTIPLIER',
  /** @deprecated No longer supported */
  INVITED = 'INVITED',
  MEMBER_COMPLETED = 'MEMBER_COMPLETED',
  MEMBER_DATA_ADDED = 'MEMBER_DATA_ADDED',
  MEMBER_DATA_REVIEWED = 'MEMBER_DATA_REVIEWED',
  MEMBER_INVITED = 'MEMBER_INVITED',
  MEMBER_INVITE_PENDING = 'MEMBER_INVITE_PENDING',
  MEMBER_STARTED = 'MEMBER_STARTED',
  MEMBER_VERIFICATION_COMPLETED = 'MEMBER_VERIFICATION_COMPLETED',
  MEMBER_VERIFICATION_IN_PROGRESS = 'MEMBER_VERIFICATION_IN_PROGRESS',
  MSA_SIGNING_IN_PROGRESS = 'MSA_SIGNING_IN_PROGRESS',
  MSA_SIGNING_PENDING = 'MSA_SIGNING_PENDING',
  NULL_STATE = 'NULL_STATE',
  ORDER_FORM_SENT_TO_EMPLOYER = 'ORDER_FORM_SENT_TO_EMPLOYER',
  ORDER_FORM_SENT_TO_MULTIPLIER = 'ORDER_FORM_SENT_TO_MULTIPLIER',
  ORDER_FORM_SIGNED_BY_EMPLOYER = 'ORDER_FORM_SIGNED_BY_EMPLOYER',
  ORDER_FORM_SIGNED_BY_MULTIPLIER = 'ORDER_FORM_SIGNED_BY_MULTIPLIER',
  /** @deprecated No longer supported */
  PREPARING_CONTRACT = 'PREPARING_CONTRACT',
  /** @deprecated No longer supported */
  RESOLUTION_IN_PROGRESS = 'RESOLUTION_IN_PROGRESS',
  REVOKED = 'REVOKED',
  SIGNATURE_EMPLOYEE_SENT = 'SIGNATURE_EMPLOYEE_SENT',
  SIGNATURE_EMPLOYEE_SIGNED = 'SIGNATURE_EMPLOYEE_SIGNED',
  SIGNATURE_EMPLOYER_SENT = 'SIGNATURE_EMPLOYER_SENT',
  SIGNATURE_EMPLOYER_SIGNED = 'SIGNATURE_EMPLOYER_SIGNED',
  SIGNATURE_MULTIPLIER_SENT = 'SIGNATURE_MULTIPLIER_SENT',
  SIGNATURE_MULTIPLIER_SIGNED = 'SIGNATURE_MULTIPLIER_SIGNED',
  /** @deprecated No longer supported */
  SIGNING_IN_PROGRESS = 'SIGNING_IN_PROGRESS',
  /** @deprecated No longer supported */
  VERIFICATION_DONE = 'VERIFICATION_DONE',
  /** @deprecated No longer supported */
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS',
  VISA_APPLICATION_APPROVED = 'VISA_APPLICATION_APPROVED',
  VISA_APPLICATION_FILING = 'VISA_APPLICATION_FILING',
  VISA_APPLICATION_PREPARING = 'VISA_APPLICATION_PREPARING',
  VISA_DOCUMENTS_STARTED = 'VISA_DOCUMENTS_STARTED',
  VISA_DOCUMENTS_SUBMITTED = 'VISA_DOCUMENTS_SUBMITTED',
  VISA_DOCUMENTS_VERIFIED = 'VISA_DOCUMENTS_VERIFIED',
  VISA_INVOICE_PAID = 'VISA_INVOICE_PAID',
}

export enum ContractOnboardingStep {
  DEFINITION_BANK_DETAILS = 'DEFINITION_BANK_DETAILS',
  DEFINITION_BENEFITS_DETAILS = 'DEFINITION_BENEFITS_DETAILS',
  DEFINITION_COMPENSATION_DETAILS = 'DEFINITION_COMPENSATION_DETAILS',
  DEFINITION_COMPLIANCE_DETAILS = 'DEFINITION_COMPLIANCE_DETAILS',
  DEFINITION_CONTRACT = 'DEFINITION_CONTRACT',
  DEFINITION_COUNTRY_SPECIFIC_DETAILS = 'DEFINITION_COUNTRY_SPECIFIC_DETAILS',
  DEFINITION_MEMBER_BASIC_DETAILS = 'DEFINITION_MEMBER_BASIC_DETAILS',
  DEFINITION_MEMBER_ELIGIBILITY = 'DEFINITION_MEMBER_ELIGIBILITY',
  MEMBER_BANK_DETAILS = 'MEMBER_BANK_DETAILS',
  MEMBER_BASIC_DETAILS = 'MEMBER_BASIC_DETAILS',
  MEMBER_BENEFIT_DETAILS = 'MEMBER_BENEFIT_DETAILS',
  MEMBER_LEGAL_DETAILS = 'MEMBER_LEGAL_DETAILS',
  MEMBER_LEGAL_DOCUMENTS = 'MEMBER_LEGAL_DOCUMENTS',
  MEMBER_REVIEW = 'MEMBER_REVIEW',
  MEMBER_VISA_APPLICATION = 'MEMBER_VISA_APPLICATION',
  MEMBER_VISA_APPROVED = 'MEMBER_VISA_APPROVED',
  MEMBER_VISA_ELIGIBILITY = 'MEMBER_VISA_ELIGIBILITY',
  MEMBER_WELCOME = 'MEMBER_WELCOME',
  MEMBER_WELCOME_VISA = 'MEMBER_WELCOME_VISA',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_ACTIVATION = 'ONBOARDING_ACTIVATION',
  ONBOARDING_CONTRACT = 'ONBOARDING_CONTRACT',
  ONBOARDING_CONTRACT_PREPARING_CONFIRMATION = 'ONBOARDING_CONTRACT_PREPARING_CONFIRMATION',
  ONBOARDING_CONTRACT_UPDATING = 'ONBOARDING_CONTRACT_UPDATING',
  ONBOARDING_CONTRACT_WET_INK_PREPARING = 'ONBOARDING_CONTRACT_WET_INK_PREPARING',
  ONBOARDING_CONTRACT_WET_INK_PREPARING_CONFIRMATION = 'ONBOARDING_CONTRACT_WET_INK_PREPARING_CONFIRMATION',
  ONBOARDING_MEMBER = 'ONBOARDING_MEMBER',
  ONBOARDING_MSA_PENDING = 'ONBOARDING_MSA_PENDING',
  ONBOARDING_MULTIPLIER_SIGNING = 'ONBOARDING_MULTIPLIER_SIGNING',
  ONBOARDING_REVIEW = 'ONBOARDING_REVIEW',
  ONBOARDING_SIGNING = 'ONBOARDING_SIGNING',
  ONBOARDING_VERIFYING = 'ONBOARDING_VERIFYING',
  START = 'START',
  VISA_APPLICATION = 'VISA_APPLICATION',
  VISA_ELIGIBILITY = 'VISA_ELIGIBILITY',
}

export type ContractPaymentAccount = {
  __typename?: 'ContractPaymentAccount';
  configurations?: Maybe<ContractPaymentAccountConfig>;
  id?: Maybe<Scalars['ID']>;
  paymentAccountId?: Maybe<Scalars['ID']>;
  supportedPaymentOptions?: Maybe<Array<Maybe<PaymentOption>>>;
};

export type ContractPaymentAccountConfig = {
  __typename?: 'ContractPaymentAccountConfig';
  preferredPayOutType?: Maybe<TransferType>;
};

export enum ContractPayrollLabel {
  ACTIVE = 'ACTIVE',
  EXCLUDED = 'EXCLUDED',
  INCLUDED = 'INCLUDED',
  NEW_HIRE = 'NEW_HIRE',
  OFFBOARDING = 'OFFBOARDING',
  ONBOARDING = 'ONBOARDING',
  TERMINATION = 'TERMINATION',
}

export type ContractPayrollPartner = {
  __typename?: 'ContractPayrollPartner';
  contract?: Maybe<Contract>;
  id?: Maybe<Scalars['ID']>;
  payrollPartnerCountry?: Maybe<PayrollPartnerCountry>;
  status?: Maybe<ContractPayrollPartnerStatus>;
};

export enum ContractPayrollPartnerStatus {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  OFFBOARDED = 'OFFBOARDED',
  REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS',
}

export type ContractRenewalInfo = {
  __typename?: 'ContractRenewalInfo';
  definition?: Maybe<FixedTermRestrictionDefinition>;
  remainingRenewalCount?: Maybe<Scalars['Int']>;
  remainingTenureInMonths?: Maybe<Scalars['Int']>;
};

export type ContractReviseInput = {
  isReviseByOpsAfterSend?: InputMaybe<Scalars['Boolean']>;
};

export type ContractSnapshot = {
  __typename?: 'ContractSnapshot';
  companyName?: Maybe<Scalars['String']>;
  contractActivatedDate?: Maybe<Scalars['Date']>;
  contractId?: Maybe<Scalars['ID']>;
  contractStartDate?: Maybe<Scalars['Date']>;
  contractStatus?: Maybe<ContractStatus>;
  exceptions?: Maybe<Array<Maybe<PayrollContractException>>>;
  expenses?: Maybe<Array<Maybe<ExpenseSnapshot>>>;
  id?: Maybe<Scalars['ID']>;
  lastWorkingDate?: Maybe<Scalars['Date']>;
  memberName?: Maybe<Scalars['String']>;
  notIncludedExpenses: Array<Expense>;
  notIncludedPaySupplements: Array<PaySupplement>;
  paySupplements?: Maybe<Array<Maybe<PaySupplementSnapshot>>>;
  payrollContractType?: Maybe<Array<Maybe<PayrollContractType>>>;
  /** Related payrollCycle of the contractSnapshot. Needs to be a child fetcher with data loader to avoid N+1 */
  payrollCycle?: Maybe<PayrollCycle>;
  payrollFormUploadStatus?: Maybe<PayrollFormsUploadStatus>;
  relatedCompanyPayables?: Maybe<Array<CompanyPayable>>;
  submittedDate?: Maybe<Scalars['Date']>;
};

export type ContractSnapshotFilter = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedPayrollCycleStatuses?: InputMaybe<Array<PayrollCycleStatus>>;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
};

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ENDED = 'ENDED',
  OFFBOARDING = 'OFFBOARDING',
  ONBOARDING = 'ONBOARDING',
}

export enum ContractTerm {
  FIXED = 'FIXED',
  PERMANENT = 'PERMANENT',
}

export type ContractTimeOff = {
  __typename?: 'ContractTimeOff';
  summary?: Maybe<Array<Maybe<TimeOffType>>>;
  timeOffs?: Maybe<Array<Maybe<TimeOff>>>;
};

export type ContractTimeOff_SummaryArgs = {
  period?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ContractTimeOffEntitlement = {
  __typename?: 'ContractTimeOffEntitlement';
  definition?: Maybe<TimeOffTypeDefinition>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  timeOffType?: Maybe<TimeOffTypeInfo>;
  /**
   * deprecated, to be removed once HRIS is live -> use timeoffType instead
   * @deprecated use timeOffType instead
   */
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<TimeOffUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type ContractTimesheetSummary = {
  __typename?: 'ContractTimesheetSummary';
  contract?: Maybe<Contract>;
  cutOffDate?: Maybe<Scalars['Date']>;
  totalDaysToApprove?: Maybe<Scalars['Int']>;
  totalSubmittedHoursToApprove?: Maybe<Scalars['Float']>;
};

export type ContractTriggerPayrollFormsReminderEmailInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  todayDateAs?: InputMaybe<Scalars['Date']>;
};

export enum ContractType {
  CONTRACTOR = 'CONTRACTOR',
  EMPLOYEE = 'EMPLOYEE',
  FREELANCER = 'FREELANCER',
  HR_MEMBER = 'HR_MEMBER',
}

export enum ContractTypeChangeRequestStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type ContractUnblockDataChangeInput = {
  category: Scalars['String'];
  changes?: InputMaybe<Array<Scalars['String']>>;
};

export type ContractUnblockInput = {
  blockEventId: Scalars['ID'];
  dataChanges?: InputMaybe<Array<ContractUnblockDataChangeInput>>;
  reason: Scalars['String'];
};

export type ContractUpdateBenefitDependentInput = {
  benefitId?: InputMaybe<Scalars['String']>;
  contractBenefitDependentId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  healthCard?: InputMaybe<HealthCardFile>;
  healthId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  legalData?: InputMaybe<Array<ContractBenefitDependentLegalDataInput>>;
  plan?: InputMaybe<Array<InputMaybe<BenefitParamInput>>>;
  relationshipToMember?: InputMaybe<Relationship>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ContractBenefitDependentStatus>;
};

export type ContractUpdateBenefitsInput = {
  benefitId?: InputMaybe<Scalars['String']>;
  dependentCount?: InputMaybe<Scalars['Int']>;
  discountTermPercentage?: InputMaybe<Scalars['Float']>;
  employeePayPercentage?: InputMaybe<Scalars['Float']>;
  employerPayPercentage?: InputMaybe<Scalars['Float']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  params?: InputMaybe<Array<InputMaybe<BenefitParamInput>>>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ContractBenefitStatus>;
};

export type ContractUpdateCompensationInput = {
  additional?: InputMaybe<Scalars['String']>;
  basePay: FixedPayComponentInput;
  deductions?: InputMaybe<Array<InputMaybe<DeductionInput>>>;
  grant?: InputMaybe<Array<InputMaybe<GrantInput>>>;
  other?: InputMaybe<Array<InputMaybe<FixedPayComponentInput>>>;
  otherFixedPayComponents?: InputMaybe<
    Array<InputMaybe<FixedPayComponentInput>>
  >;
  otherPercentPayComponents?: InputMaybe<
    Array<InputMaybe<PercentPayComponentInput>>
  >;
  payType?: InputMaybe<PayType>;
  payrollConfigId?: InputMaybe<Scalars['ID']>;
  payrollStart?: InputMaybe<Scalars['DateTime']>;
  probationBasePay?: InputMaybe<FixedPayComponentInput>;
};

export type ContractUpdateComplianceInput = {
  complianceParams?: InputMaybe<
    Array<InputMaybe<ComplianceParamPeriodLimitInput>>
  >;
  contractAgreementType?: InputMaybe<ContractAgreementType>;
  preferredContractAgreementTemplate?: InputMaybe<Scalars['ID']>;
};

export type ContractUpdateCountryInput = {
  alreadyHired?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type ContractUpdateEmploymentInput = {
  country?: InputMaybe<CountryCode>;
  employeeId?: InputMaybe<Scalars['String']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  position?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  term?: InputMaybe<ContractTerm>;
  workEmail?: InputMaybe<Scalars['String']>;
  workShift?: InputMaybe<WorkShiftInput>;
};

export type ContractUpdatePaymentAccountInput = {
  preferredPayOutType?: InputMaybe<TransferType>;
};

export type ContractUpdateTimeOffEntitlementsInput = {
  key?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<TimeOffUnit>;
  value?: InputMaybe<Scalars['Float']>;
};

export type ContractsCountRequest = {
  all?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<Scalars['Boolean']>;
  testUser?: InputMaybe<Scalars['Boolean']>;
  verification?: InputMaybe<Scalars['Boolean']>;
};

export type ContractsCountResult = {
  __typename?: 'ContractsCountResult';
  all?: Maybe<Scalars['Int']>;
  onboarding?: Maybe<Scalars['Int']>;
  testUser?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['Int']>;
};

export type ContractsResult = {
  __typename?: 'ContractsResult';
  countResult?: Maybe<ContractsCountResult>;
  data?: Maybe<Array<Maybe<Contract>>>;
  pageResult?: Maybe<PageResult>;
  payrollActiveCount?: Maybe<Scalars['Int']>;
};

export enum ContributionBy {
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYER = 'EMPLOYER',
}

export type ControllingField = {
  __typename?: 'ControllingField';
  key: Scalars['String'];
};

export type ControllingFieldInput = {
  key: Scalars['String'];
};

export type CostCenter = {
  __typename?: 'CostCenter';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  bankAccountFields?: Maybe<BankAccountFields>;
  code?: Maybe<CountryCode>;
  /** @deprecated No longer supported */
  compensationStructureDefinition?: Maybe<CompensationStructureDefinition>;
  compliance?: Maybe<CountryCompliance>;
  /** @deprecated No longer supported */
  complianceRequirements?: Maybe<Array<Maybe<ComplianceParamDefinition>>>;
  /** @deprecated No longer supported */
  compliantCurrencies?: Maybe<Array<Maybe<CurrencyCode>>>;
  configs?: Maybe<Array<CountryConfig>>;
  countryStates?: Maybe<Array<Maybe<State>>>;
  currency?: Maybe<Currency>;
  documents?: Maybe<Array<Maybe<CountryDocument>>>;
  fiscalYears?: Maybe<Array<Maybe<FiscalYear>>>;
  holidays?: Maybe<Array<Maybe<Holiday>>>;
  insights?: Maybe<Array<Maybe<InsightDefinition>>>;
  isMultiplierVisaAssistance?: Maybe<Scalars['Boolean']>;
  labourStandards?: Maybe<CountryLabourStandards>;
  legalEntityPayrollDataRequirements?: Maybe<LegalEntityPayrollDataRequirements>;
  mainCurrency?: Maybe<CurrencyCode>;
  /** @deprecated No longer supported */
  memberDocumentRequirements?: Maybe<Array<Maybe<LegalDocumentRequirement>>>;
  /** @deprecated No longer supported */
  memberLegalDataRequirements?: Maybe<Array<Maybe<LegalDataRequirement>>>;
  name?: Maybe<Scalars['String']>;
  payrollInputNotes?: Maybe<Array<PayrollInputNote>>;
  productFilterSpec?: Maybe<ProductFilterSpec>;
  productList?: Maybe<ProductList>;
  region?: Maybe<Scalars['String']>;
  supportedCurrencies?: Maybe<Array<Maybe<CurrencyCode>>>;
};

export type Country_CompensationStructureDefinitionArgs = {
  term?: InputMaybe<ContractTerm>;
  type?: InputMaybe<ContractType>;
};

export type Country_ComplianceArgs = {
  contractType?: InputMaybe<ContractType>;
  countryStateCode?: InputMaybe<Scalars['String']>;
};

export type Country_ComplianceRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  term?: InputMaybe<ContractTerm>;
};

export type Country_ConfigsArgs = {
  key?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type Country_DocumentsArgs = {
  filter?: InputMaybe<CountryDocumentFilter>;
};

export type Country_FiscalYearsArgs = {
  years?: InputMaybe<Array<Scalars['Int']>>;
};

export type Country_HolidaysArgs = {
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type Country_InsightsArgs = {
  filter?: InputMaybe<InsightDefinitionFilter>;
};

export type Country_LabourStandardsArgs = {
  filter?: InputMaybe<CountryLabourStandardsFilter>;
};

export type Country_LegalEntityPayrollDataRequirementsArgs = {
  country?: InputMaybe<CountryCode>;
};

export type Country_MemberDocumentRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type Country_MemberLegalDataRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type Country_ProductFilterSpecArgs = {
  status?: InputMaybe<ProductStatus>;
};

export type Country_ProductListArgs = {
  filter: ProductFilter;
};

export type Country_SupportedCurrenciesArgs = {
  contractType?: InputMaybe<ContractType>;
};

export type Country13th14thMonthPay = {
  __typename?: 'Country13th14thMonthPay';
  description13thMonthPay?: Maybe<Scalars['String']>;
  description14thMonthPay?: Maybe<Scalars['String']>;
  is13thMonthPayApplicable?: Maybe<Scalars['Boolean']>;
  is14thMonthPayApplicable?: Maybe<Scalars['Boolean']>;
  isIncludedInGross?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  payFrequencyType?: Maybe<Country13th14thMonthPayFrequencyType>;
  schedules?: Maybe<Array<Maybe<Country13th14thMonthPaySchedule>>>;
};

export enum Country13th14thMonthPayFrequencyType {
  INSTALLMENT = 'INSTALLMENT',
  PAYROLL = 'PAYROLL',
}

export type Country13th14thMonthPaySchedule = {
  __typename?: 'Country13th14thMonthPaySchedule';
  country13th14thMonthPayType?: Maybe<Country13th14thMonthPayType>;
  monthlySalaryMultiple?: Maybe<Scalars['Float']>;
  payoutDay?: Maybe<Scalars['Int']>;
  scheduleTimeUnit?: Maybe<ScheduleTimeUnit>;
};

export enum Country13th14thMonthPayType {
  FOURTEENTH_MONTH_PAY = 'FOURTEENTH_MONTH_PAY',
  THIRTEENTH_MONTH_PAY = 'THIRTEENTH_MONTH_PAY',
}

export type CountryCallingCodeDefinition = {
  __typename?: 'CountryCallingCodeDefinition';
  callingCode?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
};

export type CountryCallingCodeFilters = {
  countries?: InputMaybe<Array<InputMaybe<CountryCode>>>;
};

export enum CountryCode {
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALA = 'ALA',
  ALB = 'ALB',
  AND = 'AND',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATF = 'ATF',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  CZE = 'CZE',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESH = 'ESH',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUF = 'GUF',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HMD = 'HMD',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  PYF = 'PYF',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SGS = 'SGS',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  STP = 'STP',
  SUR = 'SUR',
  SVK = 'SVK',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TWN = 'TWN',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  UMI = 'UMI',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE',
}

export type CountryCompensationComponentInput = {
  amount?: InputMaybe<Scalars['Float']>;
  amountType?: InputMaybe<PayAmountType>;
  basedOn?: InputMaybe<Scalars['String']>;
  componentType: ComponentType;
  condition?: InputMaybe<Scalars['String']>;
  currency: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<RateFrequency>;
  isBase: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  key: Scalars['String'];
  label: Scalars['String'];
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  type?: InputMaybe<RateType>;
};

export type CountryCompensationDefinitionInput = {
  components?: InputMaybe<Array<CountryCompensationComponentInput>>;
  contractType: ContractType;
  country: CountryCode;
  id?: InputMaybe<Scalars['ID']>;
  term: ContractTerm;
};

export type CountryCompensationStandards = {
  __typename?: 'CountryCompensationStandards';
  hourlyRates?: Maybe<CountryHourlyRates>;
  paymentFrequency?: Maybe<CountryPaymentFrequency>;
  rateFrequency?: Maybe<CountryRateFrequency>;
};

export type CountryCompliance = {
  __typename?: 'CountryCompliance';
  availableDocuments?: Maybe<Array<Maybe<DocumentTemplate>>>;
  compensationStructureDefinition?: Maybe<CompensationStructureDefinition>;
  /** Priority: country-specific restrictions > global restrictions */
  contractDetailRestrictions?: Maybe<Array<ContractDetailRestriction>>;
  country13th14thMonthPay?: Maybe<Country13th14thMonthPay>;
  countryCode?: Maybe<CountryCode>;
  countryState?: Maybe<State>;
  currencies?: Maybe<Array<Maybe<CurrencyCode>>>;
  financialYearStartMonth?: Maybe<Scalars['Int']>;
  fixedTermRestriction?: Maybe<FixedTermRestrictionDefinition>;
  joiningDateRestriction?: Maybe<ComplianceJoiningDateRestriction>;
  legalEntity?: Maybe<CountryLegalEntity>;
  limitations?: Maybe<Array<Maybe<ComplianceLimitationDefinition>>>;
  /** @deprecated No longer supported */
  memberDataPreFetch?: Maybe<Array<Maybe<DataFieldDefinition>>>;
  memberDataRequirements?: Maybe<Array<Maybe<DataFieldDefinition>>>;
  /** @deprecated No longer supported */
  memberDocumentRequirements?: Maybe<Array<Maybe<LegalDocumentRequirement>>>;
  onboardingFlowEnabled?: Maybe<Scalars['Boolean']>;
  requirements?: Maybe<ComplianceRequirementDefinition>;
  timeOffRequirements?: Maybe<TimeOffRequirements>;
};

export type CountryCompliance_AvailableDocumentsArgs = {
  contractType?: InputMaybe<ContractType>;
  term?: InputMaybe<ContractTerm>;
};

export type CountryCompliance_CompensationStructureDefinitionArgs = {
  term?: InputMaybe<ContractTerm>;
  type?: InputMaybe<ContractType>;
};

export type CountryCompliance_FixedTermRestrictionArgs = {
  filter?: InputMaybe<FixedTermRestrictionFilter>;
};

export type CountryCompliance_JoiningDateRestrictionArgs = {
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type CountryCompliance_LegalEntityArgs = {
  type?: InputMaybe<LegalBindingType>;
};

export type CountryCompliance_MemberDataPreFetchArgs = {
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type CountryCompliance_MemberDataRequirementsArgs = {
  filter?: InputMaybe<MemberDataRequirementsFilter>;
};

export type CountryCompliance_MemberDocumentRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  filters: RequirementFiltersInput;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type CountryCompliance_RequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  inputParams?: InputMaybe<RequirementsInputs>;
  term?: InputMaybe<ContractTerm>;
};

export type CountryCompliance_TimeOffRequirementsArgs = {
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  filters?: InputMaybe<TimeOffRequirementFilters>;
  term?: InputMaybe<ContractTerm>;
};

export type CountryComplianceParameterInput = {
  complianceParamTypeId?: InputMaybe<Scalars['ID']>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  countryCode?: InputMaybe<CountryCode>;
  countryState?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['Int']>;
  dependencies?: InputMaybe<Array<ControllingFieldInput>>;
  editable?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  maximum?: InputMaybe<Scalars['Int']>;
  minimum?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<ComplianceParamPeriodUnit>;
};

export type CountryConfig = {
  __typename?: 'CountryConfig';
  country: CountryCode;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  key: Scalars['String'];
  preconditions?: Maybe<Array<CountryConfigPrecondition>>;
  stateCode?: Maybe<Scalars['String']>;
  value: CountryConfigValue;
};

export type CountryConfigPrecondition = {
  __typename?: 'CountryConfigPrecondition';
  contractTerm?: Maybe<ContractTerm>;
  contractType?: Maybe<ContractType>;
};

export type CountryConfigValue =
  | BooleanConfigValue
  | FloatConfigValue
  | IntConfigValue
  | StringConfigValue;

export type CountryContractClauseUpsertInput = {
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  countryCode: CountryCode;
  english: Scalars['String'];
  key: Scalars['String'];
  local?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type CountryCount = {
  __typename?: 'CountryCount';
  count?: Maybe<Scalars['Int']>;
  country?: Maybe<CountryCode>;
};

export type CountryDocument = {
  __typename?: 'CountryDocument';
  file?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<CountryDocumentType>;
};

export type CountryDocumentFilter = {
  stateCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CountryDocumentType>;
};

export enum CountryDocumentType {
  OFFBOARDING_GUIDE = 'OFFBOARDING_GUIDE',
  ONBOARDING_GUIDE = 'ONBOARDING_GUIDE',
  VISA_GUIDE = 'VISA_GUIDE',
}

export type CountryDocumentUpdateInput = {
  country: CountryCode;
  documentId?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CountryDocumentType>;
};

export type CountryEmployeePricing = EmployeePricing & {
  __typename?: 'CountryEmployeePricing';
  country?: Maybe<CountryCode>;
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type CountryFilter = {
  code?: InputMaybe<CountryCode>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type CountryHourlyRateValue = {
  __typename?: 'CountryHourlyRateValue';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CountryHourlyRates = {
  __typename?: 'CountryHourlyRates';
  helpUrl?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<CountryHourlyRateValue>>>;
};

export type CountryLabourStandards = {
  __typename?: 'CountryLabourStandards';
  compensation?: Maybe<CountryCompensationStandards>;
  countryCode?: Maybe<CountryCode>;
  countryStateCode?: Maybe<State>;
  workShift?: Maybe<CountryWorkShiftStandards>;
};

export type CountryLabourStandardsFilter = {
  stateCode?: InputMaybe<Scalars['String']>;
};

export type CountryLegalDataDefinitionUpsertInput = {
  applyTo: LegalDataRequirementApplicability;
  autoCompleteField?: InputMaybe<DataAutoCompleteFieldInput>;
  checkboxField?: InputMaybe<DataCheckboxFieldInput>;
  contractType: ContractType;
  countryCode?: InputMaybe<CountryCode>;
  dateField?: InputMaybe<DataDateFieldInput>;
  dependsOn?: InputMaybe<Array<InputMaybe<ControllingFieldInput>>>;
  description?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  domainType: DomainType;
  dropDownField?: InputMaybe<DataDropDownFieldInput>;
  dropDownTextField?: InputMaybe<DataDropDownTextFieldInput>;
  fetchStage: FetchStage;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isEditable?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label: Scalars['String'];
  textField?: InputMaybe<DataTextFieldInput>;
  type: LegalDataType;
};

export type CountryLegalDataInput = {
  applyTo: LegalDataRequirementApplicability;
  autoCompleteField?: InputMaybe<DataAutoCompleteFieldInput>;
  checkboxField?: InputMaybe<DataCheckboxFieldInput>;
  contractType?: InputMaybe<ContractType>;
  country: CountryCode;
  dateField?: InputMaybe<DataDateFieldInput>;
  dependsOn?: InputMaybe<Array<InputMaybe<ControllingFieldInput>>>;
  description?: InputMaybe<Scalars['String']>;
  domainType?: InputMaybe<DomainType>;
  dropDownField?: InputMaybe<DataDropDownFieldInput>;
  dropDownTextField?: InputMaybe<DataDropDownTextFieldInput>;
  fetchStage: FetchStage;
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  label: Scalars['String'];
  required: Scalars['Boolean'];
  textField?: InputMaybe<DataTextFieldInput>;
  type: LegalDataType;
};

export type CountryLegalEntity = {
  __typename?: 'CountryLegalEntity';
  legalEntity?: Maybe<LegalEntity>;
  type?: Maybe<LegalBindingType>;
};

export type CountryPaymentFrequency = {
  __typename?: 'CountryPaymentFrequency';
  data?: Maybe<Array<Maybe<CountryPaymentFrequencyData>>>;
  defaultValue?: Maybe<PayFrequency>;
  list?: Maybe<Array<Maybe<PayFrequency>>>;
  payDates?: Maybe<Array<Maybe<PayFrequencyDate>>>;
};

export type CountryPaymentFrequencyData = {
  __typename?: 'CountryPaymentFrequencyData';
  payFrequencies?: Maybe<Array<Maybe<PayFrequency>>>;
  rateFrequency?: Maybe<RateFrequency>;
};

export type CountryRateFrequency = {
  __typename?: 'CountryRateFrequency';
  defaultValue?: Maybe<RateFrequency>;
  list?: Maybe<Array<Maybe<RateFrequency>>>;
};

export type CountryWorkShiftStandards = {
  __typename?: 'CountryWorkShiftStandards';
  averageWorkingHoursPerMonth?: Maybe<Scalars['Int']>;
  defaultWorkingHours?: Maybe<WorkingHours>;
  endDay?: Maybe<DayOfWeek>;
  startDay?: Maybe<DayOfWeek>;
  workingHoursInfoText?: Maybe<Scalars['String']>;
};

export enum CountryWorkStatus {
  HAS_VISA = 'HAS_VISA',
  OBTAINED_VISA = 'OBTAINED_VISA',
  REQUIRES_VISA = 'REQUIRES_VISA',
  RESIDENT = 'RESIDENT',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type CreateCompanyInput = {
  address?: InputMaybe<AddressInput>;
  countryCode?: InputMaybe<CountryCode>;
  csmId?: InputMaybe<Scalars['ID']>;
  currency: CurrencyCode;
  displayName: Scalars['String'];
  fxSensitivity?: InputMaybe<FxSensitivity>;
  legalName: Scalars['String'];
  phoneNo?: InputMaybe<Scalars['String']>;
  pricing?: InputMaybe<PricingInput>;
  registrationNo?: InputMaybe<Scalars['String']>;
  salesUserId?: InputMaybe<Scalars['ID']>;
  sfdcAccountNo?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyLegalEntityInput = {
  address: AddressInput;
  capabilities?: InputMaybe<Array<Capability>>;
  companyId: Scalars['ID'];
  legalName: Scalars['String'];
  offeringCodes?: InputMaybe<Array<OfferingCode>>;
  payrollData?: InputMaybe<Array<DataFieldInput>>;
};

export type CreateDemoCyclesInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  untilDate: Scalars['Date'];
};

export type CreateDisputeInput = {
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  originId: Scalars['ID'];
  originType: DisputeOriginType;
  reasonCategory: DisputeReasonCategory;
  reasonSubCategory?: InputMaybe<DisputeReasonSubCategory>;
};

export type CreatePartnerInput = {
  name: Scalars['String'];
  partnerCountries: Array<PartnerCountryInput>;
  partnerUsers?: InputMaybe<Array<CreatePartnerUserInput>>;
};

export type CreatePartnerPayrollInput = {
  country?: InputMaybe<CountryCode>;
  month?: InputMaybe<PayrollMonthInput>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type CreatePartnerPayrollMemberPayInput = {
  amountGross?: InputMaybe<Scalars['Float']>;
  amountNet?: InputMaybe<Scalars['Float']>;
  amountTotalCost?: InputMaybe<Scalars['Float']>;
  contractId?: InputMaybe<Scalars['ID']>;
  contributions?: InputMaybe<
    Array<InputMaybe<CreatePartnerPayrollPayComponentInput>>
  >;
  currency?: InputMaybe<CurrencyCode>;
  deductions?: InputMaybe<
    Array<InputMaybe<CreatePartnerPayrollPayComponentInput>>
  >;
};

export type CreatePartnerPayrollPayComponentInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreatePartnerUserInput = {
  country: CountryCode;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  role: PartnerRole;
  type: PartnerUserType;
};

export type CreatePayrollPartnerInput = {
  name: Scalars['String'];
  partnerCountries: Array<PayrollPartnerCountryInput>;
  partnerUsers?: InputMaybe<Array<CreatePayrollPartnerUserInput>>;
};

export type CreatePayrollPartnerUserInput = {
  country: CountryCode;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  role: PartnerRole;
  type: PartnerUserType;
};

export type CreatePayrollWorkflowRequest = {
  configIds: Array<Scalars['ID']>;
  untilDate: Scalars['Date'];
};

export type CreditNote = {
  __typename?: 'CreditNote';
  amountApplied?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  amountUnapplied?: Maybe<Scalars['Float']>;
  appliedInvoices?: Maybe<Array<Invoice>>;
  company: Company;
  companyPayableId?: Maybe<Scalars['ID']>;
  createdDate?: Maybe<Scalars['Date']>;
  creditNoteNo?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  document?: Maybe<DocumentReadable>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Array<CreditNoteLineItem>>;
  month?: Maybe<Scalars['Int']>;
  reason?: Maybe<CreditNoteReason>;
  reference?: Maybe<Scalars['String']>;
  report?: Maybe<CompanyPayableReport>;
  status?: Maybe<CreditNoteStatus>;
  year?: Maybe<Scalars['Int']>;
};

export type CreditNoteCountByStatus = {
  __typename?: 'CreditNoteCountByStatus';
  count: Scalars['Int'];
  status: CreditNoteStatus;
};

export type CreditNoteFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  createdDateRange?: InputMaybe<DateRange>;
  creditNoteNo?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>;
  reason?: InputMaybe<CreditNoteReason>;
  reference?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CreditNoteStatus>;
};

export type CreditNoteLineItem = {
  __typename?: 'CreditNoteLineItem';
  billingCost?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  type?: Maybe<PayableItemType>;
};

export type CreditNoteQueryInput = {
  filters?: InputMaybe<CreditNoteFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export enum CreditNoteReason {
  DEPOSIT_REFUND = 'DEPOSIT_REFUND',
  DISCOUNTS = 'DISCOUNTS',
  DOUBLE_PAYMENT = 'DOUBLE_PAYMENT',
  EXPENSES_REFUNDS = 'EXPENSES_REFUNDS',
  FREELANCER = 'FREELANCER',
  INSURANCE_CANCELLATION = 'INSURANCE_CANCELLATION',
  INVOICE_CORRECTION = 'INVOICE_CORRECTION',
  INVOICE_VOIDED = 'INVOICE_VOIDED',
  SECOND_INVOICE = 'SECOND_INVOICE',
  WRITE_OFF = 'WRITE_OFF',
}

export enum CreditNoteStatus {
  DELETED = 'DELETED',
  /** @deprecated We don't have draft status in credit notes. */
  DRAFT = 'DRAFT',
  FULLY_APPLIED = 'FULLY_APPLIED',
  OPEN = 'OPEN',
}

export type CreditNoteTransaction = FinancialTransaction & {
  __typename?: 'CreditNoteTransaction';
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type CreditNotesResult = {
  __typename?: 'CreditNotesResult';
  data?: Maybe<Array<CreditNote>>;
  pageResult?: Maybe<PageResult>;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<CurrencyCode>;
  convert?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type Currency_ConvertArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  forDate?: InputMaybe<Scalars['Date']>;
  source?: InputMaybe<CurrencyConversionSource>;
  to?: InputMaybe<CurrencyCode>;
};

export enum CurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNH = 'CNH',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SPL = 'SPL',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TVD = 'TVD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  UYI = 'UYI',
  UYU = 'UYU',
  UYW = 'UYW',
  UZS = 'UZS',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWD = 'ZWD',
  ZWL = 'ZWL',
}

export type CurrencyConversionParams = {
  amount: Scalars['Float'];
  from: CurrencyCode;
  to: CurrencyCode;
};

export enum CurrencyConversionSource {
  FALLBACK = 'FALLBACK',
  INTERNALLY_FIXED = 'INTERNALLY_FIXED',
  ON_DEMAND = 'ON_DEMAND',
}

export enum CustomPayrollReportCategory {
  STATUTORY = 'STATUTORY',
}

export type CustomPayrollReportConfig = {
  __typename?: 'CustomPayrollReportConfig';
  country: CountryCode;
  id: Scalars['ID'];
  reportCategory: CustomPayrollReportCategory;
  reportCode: Scalars['String'];
  reportFormat?: Maybe<CustomPayrollReportFormat>;
  reportFrequency: CustomPayrollReportFrequency;
  reportName: Scalars['String'];
  status: CustomPayrollReportConfigStatus;
};

export type CustomPayrollReportConfigCreateInput = {
  country: CountryCode;
  reportCategory: CustomPayrollReportCategory;
  reportCode: Scalars['String'];
  reportFormat?: InputMaybe<CustomPayrollReportFormat>;
  reportFrequency: CustomPayrollReportFrequency;
  reportName: Scalars['String'];
};

export type CustomPayrollReportConfigFilter = {
  country?: InputMaybe<CountryCode>;
  reportCategory?: InputMaybe<CustomPayrollReportCategory>;
  reportFrequency?: InputMaybe<CustomPayrollReportFrequency>;
};

export enum CustomPayrollReportConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CustomPayrollReportConfigUpdateInput = {
  id: Scalars['ID'];
  status: CustomPayrollReportConfigStatus;
};

export type CustomPayrollReportCreateInput = {
  companyId: Scalars['ID'];
  employingLegalEntityId: Scalars['ID'];
  files: Array<CustomPayrollReportFileInput>;
  financialYearFrom: Scalars['Date'];
  financialYearTo: Scalars['Date'];
  month?: InputMaybe<MonthYearInput>;
  quarter?: InputMaybe<Scalars['Int']>;
  reportCategory: CustomPayrollReportCategory;
  reportFrequency: CustomPayrollReportFrequency;
};

export type CustomPayrollReportDetails = {
  company?: Maybe<Company>;
  country?: Maybe<CountryCode>;
  employingLegalEntity?: Maybe<LegalEntity>;
  files: Array<CustomPayrollReportFile>;
  financialYearFrom?: Maybe<Scalars['Date']>;
  financialYearTo?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  month?: Maybe<MonthYear>;
  quarter?: Maybe<Scalars['Int']>;
  reportCategory: CustomPayrollReportCategory;
  reportFrequency?: Maybe<CustomPayrollReportFrequency>;
};

export type CustomPayrollReportFile = {
  __typename?: 'CustomPayrollReportFile';
  config: CustomPayrollReportConfig;
  file: PayrollFile;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type CustomPayrollReportFileInput = {
  configId: Scalars['ID'];
  fileName: Scalars['String'];
};

export type CustomPayrollReportFileUpdateInput = {
  files: Array<CustomPayrollReportFileInput>;
  reportId: Scalars['ID'];
};

export type CustomPayrollReportFilter = {
  companyIds: Array<Scalars['ID']>;
  countries: Array<CountryCode>;
  employingLegalEntityIds: Array<Scalars['ID']>;
  financialYearFrom?: InputMaybe<DateRange>;
  financialYearTo?: InputMaybe<DateRange>;
};

export enum CustomPayrollReportFormat {
  CSV = 'CSV',
  PDF = 'PDF',
  TXT = 'TXT',
  XLS = 'XLS',
}

export enum CustomPayrollReportFrequency {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YTD = 'YTD',
}

export type CustomPayrollReportsResponse = {
  __typename?: 'CustomPayrollReportsResponse';
  page: PageResult;
  reports: Array<CustomPayrollReportDetails>;
};

export type CustomerIntegration = {
  __typename?: 'CustomerIntegration';
  category?: Maybe<PlatformCategory>;
  connected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isPositionDropdownListEnabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomerIntegrationDefinition = {
  __typename?: 'CustomerIntegrationDefinition';
  category?: Maybe<PlatformCategory>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logoLink?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  squareLogoLink?: Maybe<Scalars['String']>;
};

export type CustomerIntegrationFilter = {
  category?: InputMaybe<PlatformCategory>;
};

export type CustomerPayrollWorkflowRequest = {
  action: Action;
  companyPayrollCycleIds: Array<Scalars['ID']>;
};

export type CustomerPayrollWorkflowResponse = {
  __typename?: 'CustomerPayrollWorkflowResponse';
  companyPayrollCycles: Array<CompanyPayrollCycle>;
};

export type CycleContractSnapshotsResponse = {
  __typename?: 'CycleContractSnapshotsResponse';
  contracts?: Maybe<Array<ContractSnapshot>>;
  pageResult?: Maybe<PageResult>;
};

export type CycleContractsFilter = {
  activationDateRange?: InputMaybe<DateRange>;
  companyNames?: InputMaybe<Array<Scalars['String']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  endDateRange?: InputMaybe<DateRange>;
  excludedPayrollContractExceptionTypes?: InputMaybe<
    Array<PayrollContractExceptionType>
  >;
  excludedPayrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
  hasExceptions?: InputMaybe<Scalars['Boolean']>;
  memberNames?: InputMaybe<Array<Scalars['String']>>;
  payrollContractExceptionTypes?: InputMaybe<
    Array<PayrollContractExceptionType>
  >;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
  payrollFormStatuses?: InputMaybe<Array<PayrollFormsUploadStatus>>;
  startDateRange?: InputMaybe<DateRange>;
};

export type DataAutoCompleteFieldInput = {
  optionListType: Scalars['String'];
};

export type DataChange = {
  __typename?: 'DataChange';
  content?: Maybe<DataChangeContent>;
  createdBy?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  fieldCategory?: Maybe<Scalars['String']>;
  fieldCategoryObject?: Maybe<FieldCategoryObject>;
  id?: Maybe<Scalars['ID']>;
  metaData?: Maybe<DataChangeMetaData>;
  status?: Maybe<DataChangeStatus>;
};

export type DataChangeCompensationContent = {
  __typename?: 'DataChangeCompensationContent';
  newValue?: Maybe<FixedPayComponent>;
  oldValue?: Maybe<FixedPayComponent>;
};

export type DataChangeContent =
  | DataChangeCompensationContent
  | DataChangeDateContent
  | DataChangeLegalContent
  | DataChangeStringContent;

export type DataChangeDateContent = {
  __typename?: 'DataChangeDateContent';
  newValue?: Maybe<Scalars['DateTime']>;
  oldValue?: Maybe<Scalars['DateTime']>;
};

export type DataChangeFilter = {
  company?: InputMaybe<Scalars['String']>;
  contractID?: InputMaybe<Scalars['ID']>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  country?: InputMaybe<CountryCode>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eorPartnerId?: InputMaybe<Scalars['ID']>;
  field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fieldCategory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isMultiplierEntity?: InputMaybe<Scalars['Boolean']>;
  memberName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DataChangeLegalContent = {
  __typename?: 'DataChangeLegalContent';
  newValue?: Maybe<LegalData>;
  oldValue?: Maybe<LegalData>;
};

export type DataChangeMetaData = {
  __typename?: 'DataChangeMetaData';
  country?: Maybe<CountryCode>;
};

export type DataChangePageResults = {
  __typename?: 'DataChangePageResults';
  data?: Maybe<Array<Maybe<DataChange>>>;
  pageResult?: Maybe<PageResult>;
};

export enum DataChangeStatus {
  NOT_VIEWED = 'NOT_VIEWED',
  VIEWED = 'VIEWED',
}

export type DataChangeStringContent = {
  __typename?: 'DataChangeStringContent';
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export type DataCheckboxFieldInput = {
  defaultValue: Scalars['Boolean'];
};

export type DataDateFieldInput = {
  defaultValue?: InputMaybe<Scalars['Date']>;
  maxDate?: InputMaybe<Scalars['Date']>;
  minDate?: InputMaybe<Scalars['Date']>;
};

export type DataDropDownFieldInput = {
  values: Array<Scalars['String']>;
};

export type DataDropDownTextFieldInput = {
  defaultText?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<LegalDataType>;
  values: Array<Scalars['String']>;
};

/**
 * Defines a data field definition so the UI can render accordingly.
 * The intention is to replace all the dynamic UI generation definitions by this
 * i.e. LegalDataRequirement, LegalDocument etc...
 */
export type DataFieldDefinition = {
  __typename?: 'DataFieldDefinition';
  dataType?: Maybe<DataFieldType>;
  dependsOn?: Maybe<Array<ControllingField>>;
  description?: Maybe<Scalars['String']>;
  domainType?: Maybe<DomainType>;
  editable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type DataFieldInput = {
  documents?: InputMaybe<Array<Scalars['Upload']>>;
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type DataFieldType =
  | AutoCompleteField
  | CheckboxField
  | DateField
  | DropDownField
  | DropDownTextField
  | FileField
  | TextField;

export type DataFieldValue = {
  __typename?: 'DataFieldValue';
  documents?: Maybe<Array<Maybe<File>>>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DataTextFieldInput = {
  defaultValue?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['String']>;
  minLength?: InputMaybe<Scalars['String']>;
  pattern?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LegalDataType>;
};

export type DateField = {
  __typename?: 'DateField';
  defaultValue?: Maybe<Scalars['Date']>;
  maxDate?: Maybe<Scalars['Date']>;
  minDate?: Maybe<Scalars['Date']>;
};

export type DateFieldInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Date']>;
};

export type DateParam = {
  __typename?: 'DateParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['DateTime']>;
};

export type DateRange = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DateRangeOutput = {
  __typename?: 'DateRangeOutput';
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export enum DayType {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  REGULAR_DAY = 'REGULAR_DAY',
  REGULAR_HOLIDAY = 'REGULAR_HOLIDAY',
  REST_DAY = 'REST_DAY',
  REST_DAY_REGULAR_HOLIDAY = 'REST_DAY_REGULAR_HOLIDAY',
  REST_DAY_SPECIAL_HOLIDAY = 'REST_DAY_SPECIAL_HOLIDAY',
  SPECIAL_HOLIDAY = 'SPECIAL_HOLIDAY',
}

export type Deduction = {
  __typename?: 'Deduction';
  deductionDefinitionId: Scalars['ID'];
  unit?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type DeductionDefinition = {
  __typename?: 'DeductionDefinition';
  contributionBy?: Maybe<ContributionBy>;
  deductionConstraint?: Maybe<DeductionRequirement>;
  /** @deprecated Use deductionConstraint instead. */
  deductionRequirement?: Maybe<DeductionRequirement>;
  /** @deprecated It is no longer needed, as we handle description in Locize directly. */
  description?: Maybe<Scalars['String']>;
  groupKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
};

export type DeductionInput = {
  deductionDefinitionId: Scalars['ID'];
  unit?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type DeductionRequirement = {
  name?: Maybe<Scalars['String']>;
  /** @deprecated use 'name' property instead. */
  type?: Maybe<DeductionRequirementType>;
};

/** Deprecated. Use string instead. */
export enum DeductionRequirementType {
  /** @deprecated No longer supported */
  EMPLOYEE_MATCH_LIMIT = 'EMPLOYEE_MATCH_LIMIT',
  /** @deprecated Whole enum is deprecated. Instead of using the enum value the string is going to be used. */
  EMPLOYER_MATCH_LIMIT = 'EMPLOYER_MATCH_LIMIT',
  /** @deprecated Whole enum is deprecated. Instead of using the enum value the string is going to be used. */
  NOT_USED = 'NOT_USED',
}

export enum DeductionUnit {
  FIXED_AMOUNT_PER_MONTH = 'FIXED_AMOUNT_PER_MONTH',
  PERCENTAGE = 'PERCENTAGE',
}

export type DeductionValue = {
  __typename?: 'DeductionValue';
  unit: DeductionUnit;
  value: Scalars['Float'];
};

export type DefaultFinancialTransaction = FinancialTransaction & {
  __typename?: 'DefaultFinancialTransaction';
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type Department = {
  __typename?: 'Department';
  description?: Maybe<Scalars['String']>;
  employees?: Maybe<OrgDirectory>;
  hod?: Maybe<HeadOfDepartment>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<DepartmentStatus>;
};

export type Department_EmployeesArgs = {
  filters?: InputMaybe<DepartmentEmployeeFilters>;
};

export type DepartmentAssignEmployeesInput = {
  departmentId: Scalars['ID'];
  employeeContractIds: Array<Scalars['ID']>;
  employeeManagerIds: Array<Scalars['ID']>;
};

export type DepartmentCreateInput = {
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  hod?: InputMaybe<HeadOfDepartmentInput>;
  name: Scalars['String'];
};

export type DepartmentDeleteInput = {
  id: Scalars['ID'];
};

export type DepartmentEmployeeFilters = {
  contractTypes?: InputMaybe<Array<ContractType>>;
  countries?: InputMaybe<Array<CountryCode>>;
  designations?: InputMaybe<Array<Scalars['String']>>;
};

export type DepartmentFilters = {
  id?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<DepartmentStatus>>;
};

export enum DepartmentStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
}

export type DepartmentUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  hod?: InputMaybe<HeadOfDepartmentInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DepositPayableFilters = {
  invoiceStatuses?: InputMaybe<Array<InputMaybe<InvoiceStatus>>>;
};

export type DepositTerm = {
  __typename?: 'DepositTerm';
  maxDeposit?: Maybe<Scalars['Float']>;
  termInMonths?: Maybe<Scalars['Int']>;
};

export type DepositTermInput = {
  maxDeposit?: InputMaybe<Scalars['Float']>;
  termInMonths?: InputMaybe<Scalars['Int']>;
};

export type Designation = {
  __typename?: 'Designation';
  jobDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DesignationInput = {
  jobDescription: Scalars['String'];
  name: Scalars['String'];
};

export type DirectReport = {
  __typename?: 'DirectReport';
  contract?: Maybe<Contract>;
  manager?: Maybe<Manager>;
};

export type DirectReportValidationResult = {
  __typename?: 'DirectReportValidationResult';
  failedEntries?: Maybe<OrgDirectory>;
  successEntries?: Maybe<OrgDirectory>;
};

export type Discount = {
  __typename?: 'Discount';
  discountPolicy: DiscountPolicy;
  discountTerms: Array<DiscountTermV2>;
  id: Scalars['ID'];
};

export type DiscountInput = {
  discountPolicy: DiscountPolicy;
  discountTerms: Array<DiscountTermInputV2>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum DiscountPolicy {
  ADDITIVE = 'ADDITIVE',
  APPLY_FIRST = 'APPLY_FIRST',
  APPLY_LARGEST = 'APPLY_LARGEST',
  APPLY_LAST = 'APPLY_LAST',
}

export type DiscountRule =
  | DiscountRuleCountry
  | DiscountRuleDeadlineBased
  | DiscountRuleMemberBased
  | DiscountRuleRegion
  | DiscountRuleSalaryBased
  | DiscountRuleTimeBound;

export type DiscountRuleCountry = {
  __typename?: 'DiscountRuleCountry';
  anyCountry?: Maybe<Scalars['Boolean']>;
  countries: Array<Maybe<CountryCode>>;
  excludedCountries?: Maybe<Array<CountryCode>>;
  id: Scalars['ID'];
};

export type DiscountRuleCountryInput = {
  anyCountry: Scalars['Boolean'];
  countries: Array<InputMaybe<CountryCode>>;
  excludedCountries?: InputMaybe<Array<CountryCode>>;
};

export type DiscountRuleDeadlineBased = {
  __typename?: 'DiscountRuleDeadlineBased';
  deadline?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  type?: Maybe<DiscountRuleDeadlineType>;
};

export type DiscountRuleDeadlineBasedInput = {
  deadline: Scalars['Date'];
  deadlineType: DiscountRuleDeadlineType;
};

export enum DiscountRuleDeadlineType {
  MEMBER_ONBOARDED = 'MEMBER_ONBOARDED',
  MSA_SIGNED = 'MSA_SIGNED',
}

export type DiscountRuleInput = {
  countryBasedDiscount?: InputMaybe<DiscountRuleCountryInput>;
  deadlineBasedDiscount?: InputMaybe<DiscountRuleDeadlineBasedInput>;
  globalDiscount?: InputMaybe<Scalars['Boolean']>;
  memberBasedDiscount?: InputMaybe<DiscountRuleMemberBasedInput>;
  regionBasedDiscount?: InputMaybe<DiscountRuleRegionInput>;
  salaryBasedDiscount?: InputMaybe<DiscountRuleSalaryBasedInput>;
  timeBasedDiscount?: InputMaybe<DiscountRuleTimeBoundInput>;
};

export type DiscountRuleMemberBased = {
  __typename?: 'DiscountRuleMemberBased';
  id: Scalars['ID'];
  memberLowerBound?: Maybe<Scalars['Int']>;
  memberUpperBound?: Maybe<Scalars['Int']>;
};

export type DiscountRuleMemberBasedInput = {
  memberLowerBound?: InputMaybe<Scalars['Int']>;
  memberUpperBound?: InputMaybe<Scalars['Int']>;
};

export type DiscountRuleRegion = {
  __typename?: 'DiscountRuleRegion';
  id: Scalars['ID'];
  region?: Maybe<Region>;
};

export type DiscountRuleRegionInput = {
  region?: InputMaybe<Region>;
};

export type DiscountRuleSalaryBased = {
  __typename?: 'DiscountRuleSalaryBased';
  id: Scalars['ID'];
  salaryLowerBound?: Maybe<Scalars['Float']>;
  salaryUpperBound?: Maybe<Scalars['Float']>;
};

export type DiscountRuleSalaryBasedInput = {
  salaryLowerBound?: InputMaybe<Scalars['Float']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']>;
};

export type DiscountRuleTimeBound = {
  __typename?: 'DiscountRuleTimeBound';
  fromMonth?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  toMonth?: Maybe<Scalars['Int']>;
};

export type DiscountRuleTimeBoundInput = {
  fromMonth?: InputMaybe<Scalars['Int']>;
  toMonth?: InputMaybe<Scalars['Int']>;
};

export type DiscountTerm = {
  __typename?: 'DiscountTerm';
  discount: Scalars['Float'];
  discountRules?: Maybe<Array<Maybe<DiscountRule>>>;
  discountType: DiscountType;
  id?: Maybe<Scalars['ID']>;
};

export type DiscountTermCondition = {
  __typename?: 'DiscountTermCondition';
  condition?: Maybe<DiscountTermConditionValue>;
  id: Scalars['ID'];
  type?: Maybe<DiscountTermConditionType>;
};

export type DiscountTermConditionInput = {
  condition?: InputMaybe<DiscountTermConditionValueInput>;
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<DiscountTermConditionType>;
};

export enum DiscountTermConditionType {
  OFFERING_BUNDLE = 'OFFERING_BUNDLE',
  PROMOTIONAL_PERIOD = 'PROMOTIONAL_PERIOD',
}

export type DiscountTermConditionValue =
  | OfferingBundleDiscountTermCondition
  | PromotionalPeriodDiscountTermCondition;

export type DiscountTermConditionValueInput = {
  offeringBundle?: InputMaybe<OfferingBundleDiscountTermConditionInput>;
  promotionalPeriod?: InputMaybe<PromotionalPeriodDiscountTermConditionInput>;
};

export type DiscountTermInput = {
  discount: Scalars['Float'];
  discountRules?: InputMaybe<Array<InputMaybe<DiscountRuleInput>>>;
  discountType: DiscountType;
};

export type DiscountTermInputV2 = {
  conditions: Array<DiscountTermConditionInput>;
  customDiscount?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  type: DiscountTermType;
  value?: InputMaybe<Scalars['Float']>;
};

export enum DiscountTermType {
  CUSTOM = 'CUSTOM',
  FIXED_PRICE = 'FIXED_PRICE',
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
}

export type DiscountTermV2 = {
  __typename?: 'DiscountTermV2';
  conditions: Array<DiscountTermCondition>;
  customDiscount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: DiscountTermType;
  value?: Maybe<Scalars['Float']>;
};

export enum DiscountType {
  /** The 'discount' field contains the absolute discount value. i.e. fee = 500, discount 50, discounted value = 450 [500 - 50] */
  ABSOLUTE = 'ABSOLUTE',
  /** The 'discount' field contains the flat price value. i.e. fee = 500, discount 50, discounted value = 50 [regardless the fee] */
  FLAT_PRICE = 'FLAT_PRICE',
  /** The 'discount' field contains the percentage value. i.e. fee = 500, discount 50, discounted value = 250 [500 - (500 * 50%)] */
  PERCENT = 'PERCENT',
}

export type Dispute = {
  __typename?: 'Dispute';
  description?: Maybe<Scalars['String']>;
  origin?: Maybe<DisputeOrigin>;
  originId: Scalars['ID'];
  originType: DisputeOriginType;
  reasonCategory?: Maybe<DisputeReasonCategory>;
  reasonSubCategory?: Maybe<DisputeReasonSubCategory>;
  status: DisputeStatus;
};

export type DisputeFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  originType: DisputeOriginType;
  statuses?: InputMaybe<Array<DisputeStatus>>;
};

export type DisputeOrigin = CompanyPayable;

export enum DisputeOriginType {
  COMPANY_PAYABLE = 'COMPANY_PAYABLE',
}

export enum DisputeReasonCategory {
  INVOICE_INCORRECT_AMOUNT = 'INVOICE_INCORRECT_AMOUNT',
  INVOICE_INCORRECT_FORMAT = 'INVOICE_INCORRECT_FORMAT',
  INVOICE_QUERY = 'INVOICE_QUERY',
}

export enum DisputeReasonSubCategory {
  INVOICE_BILLING_CURRENCY_INCORRECT = 'INVOICE_BILLING_CURRENCY_INCORRECT',
  INVOICE_MANAGEMENT_FEE_INCORRECT = 'INVOICE_MANAGEMENT_FEE_INCORRECT',
  INVOICE_OTHER_FEES = 'INVOICE_OTHER_FEES',
  INVOICE_SERVICE_AMOUNT_INCORRECT = 'INVOICE_SERVICE_AMOUNT_INCORRECT',
}

export type DisputeResponse = {
  __typename?: 'DisputeResponse';
  data?: Maybe<Array<Dispute>>;
  page?: Maybe<PageResult>;
};

export enum DisputeStatus {
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  RESOLVED = 'RESOLVED',
}

export type Document = {
  __typename?: 'Document';
  contentType?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  uploadUrl?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Document>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  viewUrl?: Maybe<Scalars['String']>;
};

export type DocumentData = {
  __typename?: 'DocumentData';
  document?: Maybe<DocumentReadable>;
  key?: Maybe<Scalars['String']>;
};

export type DocumentKeyValueInput = {
  documentId?: InputMaybe<Scalars['ID']>;
  file?: InputMaybe<Scalars['Upload']>;
  key: Scalars['String'];
};

export type DocumentReadable = File & {
  __typename?: 'DocumentReadable';
  blob?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  htmlPreview?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  key?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  usedScope?: Maybe<ComplianceDefinitionScope>;
};

export enum DomainType {
  LEGAL_DATA = 'LEGAL_DATA',
  LEGAL_ENTITY_PAYROLL_BANK = 'LEGAL_ENTITY_PAYROLL_BANK',
  LEGAL_ENTITY_PAYROLL_BASIC_INFO = 'LEGAL_ENTITY_PAYROLL_BASIC_INFO',
  LEGAL_ENTITY_PAYROLL_CONTACT = 'LEGAL_ENTITY_PAYROLL_CONTACT',
  LEGAL_ENTITY_PAYROLL_DOCUMENT = 'LEGAL_ENTITY_PAYROLL_DOCUMENT',
  LEGAL_ENTITY_PAYROLL_INSURANCE = 'LEGAL_ENTITY_PAYROLL_INSURANCE',
  LEGAL_ENTITY_PAYROLL_TAX = 'LEGAL_ENTITY_PAYROLL_TAX',
  MEMBER_DATA = 'MEMBER_DATA',
}

export type DownloadCompanyPayableReportInput = {
  companyId: Scalars['ID'];
  payableMonth: PayableMonthInput;
  type: CompanyPayableReportType;
};

export type DropDownField = {
  __typename?: 'DropDownField';
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DropDownTextField = {
  __typename?: 'DropDownTextField';
  defaultText?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Duration = {
  __typename?: 'Duration';
  length?: Maybe<Scalars['Float']>;
  unit?: Maybe<TimeUnit>;
};

export type EorEmployeesToSyncResult = {
  __typename?: 'EOREmployeesToSyncResult';
  numberOfEmployees?: Maybe<Scalars['Int']>;
};

export type EorPartnerCountry = PartnerCountry & {
  __typename?: 'EORPartnerCountry';
  capability?: Maybe<PartnerCapability>;
  country?: Maybe<CountryCode>;
  entity?: Maybe<LegalEntity>;
  id?: Maybe<Scalars['ID']>;
  isMultiplierEntity?: Maybe<Scalars['Boolean']>;
  memberContracts?: Maybe<Array<Maybe<Contract>>>;
  operator?: Maybe<Array<Maybe<PartnerUser>>>;
  partner?: Maybe<Partner>;
};

export type EsopGrant = Grant & {
  __typename?: 'ESOPGrant';
  condition?: Maybe<Scalars['String']>;
  document?: Maybe<File>;
  grantDate: Scalars['Date'];
  id: Scalars['ID'];
  schedule: GrantSchedule;
  /** @deprecated No longer supported */
  value?: Maybe<GrantValue>;
  values?: Maybe<Array<GrantValue>>;
};

export type Education = {
  __typename?: 'Education';
  degree?: Maybe<Scalars['String']>;
  gpa?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['String']>;
  lastSchoolAttended?: Maybe<Scalars['String']>;
  yearOfPassing?: Maybe<Scalars['Date']>;
};

export type EducationInput = {
  degree: Scalars['String'];
  gpa?: InputMaybe<Scalars['Float']>;
  grade?: InputMaybe<Scalars['String']>;
  lastSchoolAttended: Scalars['String'];
  yearOfPassing: Scalars['Date'];
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EmailAddressInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type EmailAddressParam = {
  __typename?: 'EmailAddressParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<EmailAddress>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  defaultSubject?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  /** @deprecated Use the document */
  file?: Maybe<DocumentReadable>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  templateType?: Maybe<Scalars['String']>;
};

export type EmailTemplateUpsertInput = {
  defaultSubject: Scalars['String'];
  file: Scalars['Upload'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  templateType: Scalars['String'];
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumber>;
  relationship?: Maybe<Relationship>;
};

export type EmergencyContactInput = {
  mobileNo: PhoneNumberInput;
  name: Scalars['String'];
  relationship?: InputMaybe<Relationship>;
};

export type EmergencyPointOfContact = Person & {
  __typename?: 'EmergencyPointOfContact';
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  userId?: Maybe<Scalars['String']>;
};

export type EmergencyPointOfContactInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerUserId?: InputMaybe<Scalars['ID']>;
};

export type Employee = {
  __typename?: 'Employee';
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type EmployeeDataChanges = {
  __typename?: 'EmployeeDataChanges';
  bankDetails?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['Int']>;
};

export type EmployeeDataChangesV2 = {
  __typename?: 'EmployeeDataChangesV2';
  bankDetails?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['Int']>;
};

export type EmployeePricing = {
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type EmployeePricingDefault = {
  __typename?: 'EmployeePricingDefault';
  employeePricings?: Maybe<Array<Maybe<EmployeePricing>>>;
  globalPricing?: Maybe<Array<Maybe<GlobalPricing>>>;
};

export type Equipment = {
  __typename?: 'Equipment';
  companyId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<EquipmentProcurementDocument>>;
  dynamicDetails?: Maybe<Array<Maybe<EquipmentDetail>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  purchasedOn?: Maybe<Scalars['Date']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<EquipmentStatus>;
  type?: Maybe<Scalars['String']>;
};

export type EquipmentDetail = {
  __typename?: 'EquipmentDetail';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type EquipmentDownloadResponse = {
  __typename?: 'EquipmentDownloadResponse';
  file?: Maybe<DocumentReadable>;
  result: TaskResponse;
};

export type EquipmentFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  equipmentIds?: InputMaybe<Array<Scalars['ID']>>;
  procurementIds?: InputMaybe<Array<Scalars['ID']>>;
  statuses?: InputMaybe<Array<EquipmentStatus>>;
};

export type EquipmentProcurement = {
  __typename?: 'EquipmentProcurement';
  companyId?: Maybe<Scalars['ID']>;
  completedOn?: Maybe<Scalars['Date']>;
  contractId?: Maybe<Scalars['ID']>;
  currencyCode?: Maybe<CurrencyCode>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Array<EquipmentProcurementItem>>;
  requestedOn: Scalars['Date'];
  status?: Maybe<EquipmentProcurementStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type EquipmentProcurementDocument = {
  __typename?: 'EquipmentProcurementDocument';
  document: DocumentReadable;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type EquipmentProcurementFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  procurementIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EquipmentProcurementInput = {
  clients?: InputMaybe<Array<ClientDetail>>;
  detail?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EquipmentProcurementItem = {
  __typename?: 'EquipmentProcurementItem';
  amount?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export enum EquipmentProcurementStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  REQUESTED = 'REQUESTED',
}

export type EquipmentQuotationInput = {
  brand?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  detail?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum EquipmentStatus {
  ASSIGNED = 'ASSIGNED',
  EXPIRED = 'EXPIRED',
  FREE = 'FREE',
}

export type Expense = {
  __typename?: 'Expense';
  approverInfos?: Maybe<Array<ItemApproverInfo>>;
  /** @deprecated Legacy field. No implementations or usages found in the code */
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  changes?: Maybe<Array<Maybe<ExpenseChange>>>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<ExpenseItem>>>;
  payrollCutOffDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ExpenseStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  /** @deprecated Hardcoded with USD. No usages. Seems a legacy one. BE still calculates and returns this but will be removed */
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type Expense_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ExpenseStatus>;
};

export type ExpenseAmountInfo = {
  __typename?: 'ExpenseAmountInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export type ExpenseAmountInfoInput = {
  amount: Scalars['Float'];
  currency: CurrencyCode;
};

export type ExpenseAmountLimit = {
  __typename?: 'ExpenseAmountLimit';
  basis?: Maybe<ExpenseAmountLimitBasis>;
  currency?: Maybe<CurrencyCode>;
  limit?: Maybe<Scalars['Float']>;
  remaining?: Maybe<Scalars['Float']>;
};

export enum ExpenseAmountLimitBasis {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type ExpenseApprovalItem = ApprovalItem & {
  __typename?: 'ExpenseApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  expenseId?: Maybe<Scalars['ID']>;
  expenseItem?: Maybe<Expense>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<ApprovalItemStatus>;
};

export type ExpenseCategory = {
  __typename?: 'ExpenseCategory';
  glCode?: Maybe<CompanyGlCode>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ExpenseCategoryFilter = {
  contractId?: InputMaybe<Scalars['ID']>;
};

export type ExpenseChange = {
  __typename?: 'ExpenseChange';
  actionedBy?: Maybe<Person>;
  actionedOn?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<ExpenseStatus>;
};

export type ExpenseCreateInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  expenseId?: InputMaybe<Scalars['ID']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  items: Array<InputMaybe<ExpenseItemInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseDateRange = {
  __typename?: 'ExpenseDateRange';
  end?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  threshold?: Maybe<Scalars['Int']>;
  unit?: Maybe<ExpenseDateThresholdUnit>;
};

export enum ExpenseDateThresholdUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type ExpenseFilters = {
  companyIds: Array<Scalars['ID']>;
  contractIds: Array<Scalars['ID']>;
  contractStatus?: InputMaybe<ContractStatus>;
  /** @deprecated contractType is deprecated. Use contractTypes instead. */
  contractType?: InputMaybe<ContractType>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  country: Array<CountryCode>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  expenseIds: Array<Scalars['ID']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** @deprecated status is deprecated. Use statuses instead. */
  status?: InputMaybe<ExpenseStatus>;
  statuses?: InputMaybe<Array<ExpenseStatus>>;
};

export type ExpenseItem = {
  __typename?: 'ExpenseItem';
  amount?: Maybe<Scalars['Float']>;
  amountInForeignCurrency?: Maybe<ExpenseAmountInfo>;
  /** @deprecated Hardcoded with USD. No usages. Seems a legacy one. BE still calculates and returns this but will be removed */
  amountInFunctionalCurrency?: Maybe<Scalars['Float']>;
  /** @deprecated As part of 86cvptuja/86cvptv6d. Use `categoryV2` instead */
  category?: Maybe<Scalars['String']>;
  categoryV2?: Maybe<ExpenseCategory>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Found no usages. All existing records have it null. */
  merchant?: Maybe<Scalars['String']>;
  receipts?: Maybe<Array<Maybe<DocumentReadable>>>;
  receiptsInForeignCurrency?: Maybe<Array<DocumentReadable>>;
  title?: Maybe<Scalars['String']>;
};

export type ExpenseItemInput = {
  amount: Scalars['Float'];
  amountInForeignCurrency?: InputMaybe<ExpenseAmountInfoInput>;
  /** @deprecated As part of 86cvptuja/86cvptv6d. Use `categoryId` instead */
  category?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  /** @deprecated Found no usages. All existing records have it null. */
  merchant?: InputMaybe<Scalars['String']>;
  receipts?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  receiptsInForeignCurrency?: InputMaybe<Array<Scalars['Upload']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseMemberPayable = MemberPayable & {
  __typename?: 'ExpenseMemberPayable';
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationFrequency?: Maybe<RateFrequency>;
  durationValue?: Maybe<Scalars['Float']>;
  expense?: Maybe<Expense>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ExpenseMemberPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type ExpenseMemberPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type ExpenseMemberPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export type ExpenseReportInput = {
  category: Array<InputMaybe<Scalars['String']>>;
  country: Array<InputMaybe<CountryCode>>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  status?: InputMaybe<ExpenseStatus>;
};

export type ExpenseSaveAsDraftInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  items: Array<InputMaybe<ExpenseItemInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseSnapshot = {
  __typename?: 'ExpenseSnapshot';
  currency?: Maybe<CurrencyCode>;
  expenseId: Scalars['ID'];
  expenseTitle?: Maybe<Scalars['String']>;
  status?: Maybe<ExpenseStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export enum ExpenseSource {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export enum ExpenseStatus {
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  TO_BE_PAID = 'TO_BE_PAID',
}

export type ExpenseSubmitInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  expenseId?: InputMaybe<Scalars['ID']>;
  items: Array<InputMaybe<ExpenseItemInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseSummary = {
  __typename?: 'ExpenseSummary';
  paid?: Maybe<Array<Scalars['ID']>>;
  processing?: Maybe<Array<Scalars['ID']>>;
  revoked?: Maybe<Array<Scalars['ID']>>;
  toBePaid?: Maybe<Array<Scalars['ID']>>;
};

export type ExpenseUpdateBulkResponse = {
  __typename?: 'ExpenseUpdateBulkResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export type ExpenseUpdateInput = {
  expenseId: Scalars['ID'];
  items?: InputMaybe<Array<InputMaybe<ExpenseItemInput>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseValidationInfo = {
  __typename?: 'ExpenseValidationInfo';
  amountLimit?: Maybe<ExpenseAmountLimit>;
  dateLimit?: Maybe<ExpenseDateRange>;
};

export type ExpenseValidationInfoInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  expenseDate?: InputMaybe<Scalars['Date']>;
};

export type ExpensesResponse = {
  __typename?: 'ExpensesResponse';
  expenses?: Maybe<Array<Maybe<Expense>>>;
  page?: Maybe<PageResult>;
};

export enum ExportPayrollReportType {
  COUNTRY_DETAILED = 'COUNTRY_DETAILED',
  COUNTRY_SUMMARIZED = 'COUNTRY_SUMMARIZED',
  DETAILED = 'DETAILED',
  SUMMARIZED = 'SUMMARIZED',
}

export type ExternalLeaveType = {
  __typename?: 'ExternalLeaveType';
  id?: Maybe<Scalars['String']>;
  leaveType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ExternalSubField = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum ExternalSystemType {
  NETSUITE = 'NETSUITE',
  XERO = 'XERO',
}

export enum FeatureCategory {
  CANDIDATE_TYPE = 'CANDIDATE_TYPE',
  COUNTRY = 'COUNTRY',
  EXPERIENCE_LEVEL = 'EXPERIENCE_LEVEL',
  HIRING_DIVERSITY = 'HIRING_DIVERSITY',
  JOB_ROLE = 'JOB_ROLE',
}

export type FeeComponentDefinition = {
  __typename?: 'FeeComponentDefinition';
  countryCode?: Maybe<CountryCode>;
  currencyCode?: Maybe<CurrencyCode>;
  discount?: Maybe<Discount>;
  fee: Scalars['Float'];
  feeComponentTierDefinition?: Maybe<FeeComponentTierDefinition>;
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id: Scalars['ID'];
  key: Scalars['String'];
  offeringCode: OfferingCode;
  status: FeeComponentDefinitionStatus;
  variableBaseUnit?: Maybe<VariableBaseUnit>;
};

export type FeeComponentDefinitionFilter = {
  offeringCode?: InputMaybe<Array<OfferingCode>>;
  status?: InputMaybe<Array<FeeComponentDefinitionStatus>>;
};

export type FeeComponentDefinitionInput = {
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<CurrencyCode>;
  discount?: InputMaybe<DiscountInput>;
  fee: Scalars['Float'];
  feeComponentTierDefinitionId: Scalars['ID'];
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  offeringCode: OfferingCode;
  variableBaseUnit?: InputMaybe<VariableBaseUnit>;
};

export enum FeeComponentDefinitionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type FeeComponentTierDefinition = {
  __typename?: 'FeeComponentTierDefinition';
  feeComponentDefinitions?: Maybe<Array<FeeComponentDefinition>>;
  id: Scalars['ID'];
  offeringCode: OfferingCode;
  tierLowerBound?: Maybe<Scalars['Int']>;
  tierUpperBound?: Maybe<Scalars['Int']>;
};

export type FeeComponentTierDefinitionInput = {
  id?: InputMaybe<Scalars['ID']>;
  offeringCode: OfferingCode;
  tierLowerBound?: InputMaybe<Scalars['Int']>;
  tierUpperBound?: InputMaybe<Scalars['Int']>;
};

export enum FeeComponentType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export type FeeType = {
  __typename?: 'FeeType';
  amount?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Array<Maybe<FeeTypeMetadata>>>;
  name?: Maybe<Scalars['String']>;
};

export type FeeTypeMetadata = {
  __typename?: 'FeeTypeMetadata';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum FeeUnit {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
}

export type FetchEmployeesResult = {
  __typename?: 'FetchEmployeesResult';
  employees?: Maybe<Array<Maybe<Employee>>>;
  syncId?: Maybe<Scalars['String']>;
};

export enum FetchStage {
  CONTRACT_GENERATION = 'CONTRACT_GENERATION',
  MEMBER_LEGAL_DATA_CAPTURE = 'MEMBER_LEGAL_DATA_CAPTURE',
}

export type FiatTransaction = Transaction & {
  __typename?: 'FiatTransaction';
  creditor?: Maybe<LedgerSide>;
  debitor?: Maybe<LedgerSide>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTerminalTransaction?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Payment>;
  paymentPartner?: Maybe<PaymentPartner>;
  status?: Maybe<TransactionStatus>;
  transferValue?: Maybe<TransferValue>;
};

export type FieldAccessibilityRule = {
  __typename?: 'FieldAccessibilityRule';
  editable?: Maybe<Scalars['Boolean']>;
  field?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type FieldCategoryObject = Contract | Member;

export type FieldMapping = {
  __typename?: 'FieldMapping';
  children?: Maybe<Array<Maybe<FieldMapping>>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isCalculated?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  mappedField?: Maybe<Scalars['String']>;
  mappedFieldLabel?: Maybe<Scalars['String']>;
  originField?: Maybe<Scalars['String']>;
  originFieldLabel?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FieldMappingInput = {
  children?: InputMaybe<Array<InputMaybe<FieldMappingInput>>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isMappedByThirdParty?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  mappedField?: InputMaybe<Scalars['String']>;
  mappedFieldFromApp?: InputMaybe<Scalars['String']>;
  mappedFieldId?: InputMaybe<Scalars['String']>;
  mappedFieldLabel?: InputMaybe<Scalars['String']>;
  mappedSubFields?: InputMaybe<Array<InputMaybe<ExternalSubField>>>;
  originField?: InputMaybe<Scalars['String']>;
  originFieldLabel?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type File = {
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
};

export type FileDownload = {
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated use `payrollFile` instead */
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type FileDownloadHistoryFilter = {
  statuses?: InputMaybe<Array<PayrollFileDownloadStatus>>;
};

export type FileDownloadHistoryResponse = {
  __typename?: 'FileDownloadHistoryResponse';
  fileDownloads?: Maybe<Array<FileDownload>>;
};

export type FileField = {
  __typename?: 'FileField';
  acceptMultiple?: Maybe<Scalars['Boolean']>;
};

export type FileGenerationErrors = {
  __typename?: 'FileGenerationErrors';
  errorMessage?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  level?: Maybe<PaymentInstructionErrorLevel>;
  type?: Maybe<PaymentInstructionErrorType>;
};

export type FileGenerationRequestData = {
  bankCode: BankCode;
  countryCode: CountryCode;
  fileType: PaymentInstructionFileType;
  iso20022DirectDebitInstructionFileGenerateData?: InputMaybe<Iso20022DirectDebitInstructionFileGenerateData>;
  nachaDebitInstructionFileGenerateData?: InputMaybe<NachaDebitInstructionFileGenerateData>;
  nachaPrenoteInstructionFileGenerateData?: InputMaybe<NachaPrenoteInstructionFileGenerateData>;
  paymentFileSubType: Scalars['String'];
  paymentInstructionFileGenerateData?: InputMaybe<PaymentInstructionFileGenerateData>;
};

export type FileLink = File & {
  __typename?: 'FileLink';
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type FileWithVersion = File & {
  __typename?: 'FileWithVersion';
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type Filter = {
  __typename?: 'Filter';
  isMandatory?: Maybe<Scalars['Boolean']>;
  parameterName?: Maybe<Scalars['String']>;
  parameterTitle?: Maybe<Scalars['String']>;
  possibleValues: Array<Maybe<Scalars['String']>>;
  type?: Maybe<FilterTypes>;
};

export enum FilterTypes {
  DATE_RANGE = 'DATE_RANGE',
  DROPDOWN = 'DROPDOWN',
  MONTH = 'MONTH',
  MONTH_RANGE = 'MONTH_RANGE',
  MULTI_DROPDOWN = 'MULTI_DROPDOWN',
  MULTI_TEXT_INPUT = 'MULTI_TEXT_INPUT',
  NUMBER = 'NUMBER',
  RANGE = 'RANGE',
  TEXT = 'TEXT',
}

export type FinancialAssessmentDataInput = {
  data?: InputMaybe<Array<InputMaybe<FinancialDataRequirementInput>>>;
  id: Scalars['ID'];
};

export type FinancialAssessmentDocumentsInput = {
  documents?: InputMaybe<Array<InputMaybe<FinancialDocumentRequirementInput>>>;
  id: Scalars['ID'];
  reports?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type FinancialDataInput = {
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
  value?: InputMaybe<Scalars['String']>;
};

export type FinancialDataRequirementInput = {
  data: Array<InputMaybe<FinancialDataInput>>;
  kybRequirementType: Scalars['String'];
};

export type FinancialDocumentInput = {
  documentList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
};

export type FinancialDocumentRequirementInput = {
  data: Array<InputMaybe<FinancialDocumentInput>>;
  kybRequirementType: Scalars['String'];
};

export type FinancialRiskAssessment = KybRiskAssessment & {
  __typename?: 'FinancialRiskAssessment';
  collectedRiskData?: Maybe<KybCollectedRiskData>;
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  riskScore?: Maybe<AssessmentRiskScore>;
  schema?: Maybe<KybSchema>;
  status?: Maybe<RiskAssessmentStatus>;
  type: AssessmentType;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransaction = {
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type FinancialTransactionInput = {
  autoSubmit?: InputMaybe<Scalars['Boolean']>;
  companyIdToForcedContractIds?: InputMaybe<
    Array<CompanyIdToForcedContractIdsInput>
  >;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateRange?: InputMaybe<DateRange>;
  financialTransactionType?: InputMaybe<FinancialTransactionType>;
  frequency?: InputMaybe<Scalars['String']>;
  transactionDate?: InputMaybe<Scalars['DateTime']>;
};

export type FinancialTransactionInspectorResponse = {
  __typename?: 'FinancialTransactionInspectorResponse';
  financialTransactions?: Maybe<Array<Maybe<FinancialTransaction>>>;
  transactionCommands?: Maybe<Array<Maybe<TransactionCommand>>>;
};

export enum FinancialTransactionType {
  ANNUAL_PLAN_INVOICE = 'ANNUAL_PLAN_INVOICE',
  DEPOSIT_INVOICE = 'DEPOSIT_INVOICE',
  FIRST_INVOICE = 'FIRST_INVOICE',
  GLOBAL_PAYROLL_FUNDING_INVOICE = 'GLOBAL_PAYROLL_FUNDING_INVOICE',
  INSURANCE_INVOICE = 'INSURANCE_INVOICE',
  SECOND_INVOICE = 'SECOND_INVOICE',
}

export type FiscalYear = {
  __typename?: 'FiscalYear';
  endDate?: Maybe<Scalars['Date']>;
  fiscalYear?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FixTimeoffAllocationInput = {
  dryRun?: InputMaybe<Scalars['Boolean']>;
  timeoffSummaryIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type FixedAllowance = {
  __typename?: 'FixedAllowance';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  rateType?: Maybe<RateType>;
  type?: Maybe<Scalars['String']>;
};

export type FixedDeductionRequirement = DeductionRequirement & {
  __typename?: 'FixedDeductionRequirement';
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<DeductionValue>>>;
  /** @deprecated Use FixedDeductionRequirement.name instead. */
  type?: Maybe<DeductionRequirementType>;
};

export type FixedPayComponent = CompensationPayComponent & {
  __typename?: 'FixedPayComponent';
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<PayAmountType>;
  condition?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  frequency?: Maybe<RateFrequency>;
  id?: Maybe<Scalars['ID']>;
  instalments?: Maybe<Array<Instalment>>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  paymentFrequency?: Maybe<PayFrequency>;
  paymentFrequencyDate?: Maybe<Array<Maybe<PayFrequencyDate>>>;
  rateType?: Maybe<RateType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  validFromInclusive?: Maybe<Scalars['Date']>;
  validToExclusive?: Maybe<Scalars['Date']>;
};

export type FixedPayComponentDefinition = CompensationPayComponentDefinition & {
  __typename?: 'FixedPayComponentDefinition';
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<RateFrequency>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  rateType?: Maybe<RateType>;
};

export type FixedPayComponentInput = {
  amount?: InputMaybe<Scalars['Float']>;
  amountType?: InputMaybe<PayAmountType>;
  condition?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  frequency?: InputMaybe<RateFrequency>;
  instalments?: InputMaybe<Array<InstalmentInput>>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<InputMaybe<PayFrequencyDateInput>>>;
  rateType?: InputMaybe<RateType>;
};

/** Deprecated. Use `FixedDeductionRequirement` type instead. */
export type FixedPercentageDeductionRequirement = DeductionRequirement & {
  __typename?: 'FixedPercentageDeductionRequirement';
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use FixedDeductionRequirement.type instead. */
  type?: Maybe<DeductionRequirementType>;
  /** @deprecated Use FixedDeductionRequirement.options instead. */
  values?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FixedTermRestrictionDefinition = {
  __typename?: 'FixedTermRestrictionDefinition';
  maxRenewalCount?: Maybe<Scalars['Int']>;
  maxRenewalDurationInMonths?: Maybe<Scalars['Int']>;
  maxTenureInMonths?: Maybe<Scalars['Int']>;
  minRenewalDurationInMonths?: Maybe<Scalars['Int']>;
};

export type FixedTermRestrictionFilter = {
  contractType?: InputMaybe<ContractType>;
};

export type FloatConfigValue = NumberConfigValue & {
  __typename?: 'FloatConfigValue';
  unit?: Maybe<NumberConfigUnit>;
  value: Scalars['Float'];
};

export type FreelancerPayable = MemberPayable & {
  __typename?: 'FreelancerPayable';
  /** Expense has its own approval flow, and PaySupplement doesn't even have one. So, adding approvedAt to here only and not the interface */
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationFrequency?: Maybe<RateFrequency>;
  durationValue?: Maybe<Scalars['Float']>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type FreelancerPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type FreelancerPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type FreelancerPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export enum FreelancerType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum FrequencyUnit {
  AD_HOC = 'AD_HOC',
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
}

export enum FxSensitivity {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type GenderParam = {
  __typename?: 'GenderParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Gender>;
};

export type GenericPayableCost = {
  __typename?: 'GenericPayableCost';
  billingCost?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['Float']>;
  type?: Maybe<PayableItemType>;
};

export type GetPartnerPayrollInput = {
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type GlobalPricing = {
  __typename?: 'GlobalPricing';
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
};

export type Grant = {
  condition?: Maybe<Scalars['String']>;
  document?: Maybe<File>;
  grantDate: Scalars['Date'];
  id: Scalars['ID'];
  schedule: GrantSchedule;
  /** @deprecated No longer supported */
  value?: Maybe<GrantValue>;
  values?: Maybe<Array<GrantValue>>;
};

export type GrantInput = {
  condition?: InputMaybe<Scalars['String']>;
  grantDate: Scalars['Date'];
  id?: InputMaybe<Scalars['ID']>;
  schedule: GrantScheduleInput;
  value?: InputMaybe<GrantValueInput>;
  values?: InputMaybe<Array<GrantValueInput>>;
};

export type GrantSchedule = {
  __typename?: 'GrantSchedule';
  cliffPeriod?: Maybe<ScheduleTime>;
  vestingPeriod?: Maybe<ScheduleTime>;
  vestingSchedule?: Maybe<Array<Maybe<VestingSchedule>>>;
};

export type GrantScheduleInput = {
  cliffPeriod?: InputMaybe<ScheduleTimeInput>;
  vestingPeriod?: InputMaybe<ScheduleTimeInput>;
  vestingSchedule?: InputMaybe<Array<InputMaybe<VestingScheduleInput>>>;
};

export enum GrantType {
  ESOP = 'ESOP',
  RSU = 'RSU',
  STOCK = 'STOCK',
}

export type GrantValue = {
  __typename?: 'GrantValue';
  currency?: Maybe<CurrencyCode>;
  type: GrantValueType;
  value: Scalars['Float'];
};

export type GrantValueInput = {
  currency?: InputMaybe<CurrencyCode>;
  type: GrantValueType;
  value: Scalars['Float'];
};

export enum GrantValueType {
  CASH = 'CASH',
  UNIT = 'UNIT',
}

export type HeadOfDepartment = {
  __typename?: 'HeadOfDepartment';
  contract?: Maybe<Contract>;
  manager?: Maybe<Manager>;
};

export type HeadOfDepartmentInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  managerId?: InputMaybe<Scalars['ID']>;
};

export type HealthCardFile = {
  file?: InputMaybe<Scalars['Upload']>;
  fileId?: InputMaybe<Scalars['Int']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  countryCode?: Maybe<CountryCode>;
  date?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['ID']>;
  month?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** for a single holiday */
export type HolidayAddInput = {
  date: Scalars['Date'];
  name: Scalars['String'];
  type?: InputMaybe<HolidayType>;
};

export enum HolidayType {
  BANK = 'BANK',
  NATIONAL = 'NATIONAL',
  SPECIAL = 'SPECIAL',
}

/**
 * - countryCode + year must be provided (to avoid disaster)
 * - the other fields: nullable
 */
export type HolidaysDeleteFilter = {
  countryCode: CountryCode;
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<HolidayType>;
  year: Scalars['Int'];
};

export enum HoursType {
  NIGHT_SHIFT_HOURS = 'NIGHT_SHIFT_HOURS',
  OVERTIME_AT_NIGHT_HOURS = 'OVERTIME_AT_NIGHT_HOURS',
  OVERTIME_HOURS = 'OVERTIME_HOURS',
  REGULAR_HOURS = 'REGULAR_HOURS',
}

export enum IdentityProvider {
  TRINET = 'TRINET',
}

export type Image = File & {
  __typename?: 'Image';
  blob?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type InputFileDownload = FileDownload & {
  __typename?: 'InputFileDownload';
  createdOn?: Maybe<Scalars['DateTime']>;
  cycles?: Maybe<Array<PayrollCycle>>;
  description?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type InputHeadcountSummary = {
  __typename?: 'InputHeadcountSummary';
  leaver?: Maybe<Scalars['Int']>;
  newJoiner?: Maybe<Scalars['Int']>;
  previousCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type InputHeadcountSummaryV2 = {
  __typename?: 'InputHeadcountSummaryV2';
  leaver?: Maybe<Scalars['Int']>;
  newJoiner?: Maybe<Scalars['Int']>;
  previousCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type InsightAction = {
  __typename?: 'InsightAction';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum InsightCategory {
  BENEFIT = 'BENEFIT',
  COMPENSATION = 'COMPENSATION',
  COMPLIANCE = 'COMPLIANCE',
  TIME_OFF = 'TIME_OFF',
}

export type InsightDefinition = {
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type InsightDefinitionFilter = {
  categories?: InputMaybe<Array<InputMaybe<InsightCategory>>>;
  contractEndDate?: InputMaybe<Scalars['Date']>;
  contractStartDate?: InputMaybe<Scalars['Date']>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type Instalment = {
  __typename?: 'Instalment';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
};

export type InstalmentInput = {
  amount?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<CurrencyCode>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
};

export type InsuranceCoverage = {
  __typename?: 'InsuranceCoverage';
  maxCount: Scalars['Int'];
  relation?: Maybe<InsuranceRelation>;
};

export type InsuranceCoverageGroup = {
  __typename?: 'InsuranceCoverageGroup';
  group?: Maybe<Array<Maybe<InsuranceCoverage>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InsuranceCoverageInput = {
  maxCount: Scalars['Int'];
  relationId?: InputMaybe<Scalars['ID']>;
};

export type InsuranceIndividualPremium = {
  __typename?: 'InsuranceIndividualPremium';
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  billingPeriodInMonths?: Maybe<Scalars['Int']>;
  endOn?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  platformFee?: Maybe<Scalars['Float']>;
  startOn?: Maybe<Scalars['Date']>;
  subTotalAmount?: Maybe<Scalars['Float']>;
  subTotalPlatformFee?: Maybe<Scalars['Float']>;
};

export type InsurancePremium = {
  __typename?: 'InsurancePremium';
  benefitType?: Maybe<BenefitType>;
  billingDuration?: Maybe<BenefitPartnerBillingFrequency>;
  dependents?: Maybe<Array<Maybe<InsuranceIndividualPremium>>>;
  employeePayAmount?: Maybe<Scalars['Float']>;
  employeePayPercentage?: Maybe<Scalars['Float']>;
  employerPayAmount?: Maybe<Scalars['Float']>;
  employerPayPercentage?: Maybe<Scalars['Float']>;
  self?: Maybe<InsuranceIndividualPremium>;
  totalPlatformFee?: Maybe<Scalars['Float']>;
  totalPremium?: Maybe<Scalars['Float']>;
};

export type InsuranceRelation = {
  __typename?: 'InsuranceRelation';
  familyMember?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type InsuranceRelationInput = {
  familyMember: Scalars['String'];
};

export type InsuranceStatusResponse = {
  __typename?: 'InsuranceStatusResponse';
  contractBenefitId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  response?: Maybe<TaskResponse>;
  status: ContractBenefitStatus;
};

export type InsuranceStatuses = {
  currentStatus?: InputMaybe<ContractBenefitStatus>;
  nextStatus: ContractBenefitStatus;
};

export type InsuranceTier = {
  __typename?: 'InsuranceTier';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InsuranceTierInput = {
  name: Scalars['String'];
};

export type InsuranceUpdateInput = {
  benefitId?: InputMaybe<Scalars['String']>;
  contractBenefitId?: InputMaybe<Scalars['ID']>;
  discountTermPercentage?: InputMaybe<Scalars['Float']>;
  employeePayPercentage?: InputMaybe<Scalars['Float']>;
  employerPayPercentage?: InputMaybe<Scalars['Float']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  healthCard?: InputMaybe<HealthCardFile>;
  healthId?: InputMaybe<Scalars['String']>;
  includeDependents?: InputMaybe<Scalars['Boolean']>;
  insuranceTier?: InputMaybe<BenefitTier>;
  maxDependentCount?: InputMaybe<Scalars['Int']>;
  startOn?: InputMaybe<Scalars['DateTime']>;
};

export type IntConfigValue = NumberConfigValue & {
  __typename?: 'IntConfigValue';
  unit?: Maybe<NumberConfigUnit>;
  value: Scalars['Int'];
};

export type IntParam = {
  __typename?: 'IntParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type IntegrationEntityMappingStatusOutput = {
  __typename?: 'IntegrationEntityMappingStatusOutput';
  company?: Maybe<Company>;
  /** @deprecated No longer supported */
  companyId?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  entityId?: Maybe<Scalars['ID']>;
  entityMappingId?: Maybe<Scalars['ID']>;
  entityMappingStatus?: Maybe<LegalMappingStatus>;
  /** @deprecated No longer supported */
  entityName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['ID']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  legalEntity?: Maybe<LegalEntity>;
  /** @deprecated No longer supported */
  status?: Maybe<Scalars['String']>;
};

export type IntegrationFieldsMappingOutput = {
  __typename?: 'IntegrationFieldsMappingOutput';
  company?: Maybe<Company>;
  /** @deprecated No longer supported */
  companyId?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  entityId?: Maybe<Scalars['ID']>;
  entityMappingStatus?: Maybe<LegalMappingStatus>;
  fieldsMapping?: Maybe<Array<Maybe<FieldMapping>>>;
  integrationId?: Maybe<Scalars['ID']>;
  legalEntity?: Maybe<LegalEntity>;
  thirdPartyFields?: Maybe<Array<Maybe<UnmappedField>>>;
  /** @deprecated No longer supported */
  unmappedFields?: Maybe<Array<Maybe<UnmappedField>>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  companyPayableId?: Maybe<Scalars['ID']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  creditNotes?: Maybe<Array<CreditNote>>;
  document?: Maybe<DocumentReadable>;
  dueDate?: Maybe<Scalars['DateTime']>;
  externalLink?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<ExternalSystemType>;
  fullyPaidOnDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoiceId?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  reason?: Maybe<InvoiceReason>;
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<InvoiceType>;
};

export type InvoiceOverrideInfo = {
  dueDate: Scalars['Date'];
  id: Scalars['ID'];
  reference: Scalars['String'];
  value: Scalars['String'];
};

export enum InvoiceReason {
  CORRECTION_OF_INVOICE = 'CORRECTION_OF_INVOICE',
  DEPOSITS = 'DEPOSITS',
  FIRST_INVOICE = 'FIRST_INVOICE',
  FIRST_INVOICE_SUPPLEMENTARY = 'FIRST_INVOICE_SUPPLEMENTARY',
  INSURANCE = 'INSURANCE',
  INVOICE_VOIDED = 'INVOICE_VOIDED',
  OTHERS = 'OTHERS',
  SECOND_INVOICE = 'SECOND_INVOICE',
  VISA = 'VISA',
}

export type InvoiceSourceReportByHashInput = {
  hash: Scalars['String'];
};

export type InvoiceSourceReportByInvoiceIdInput = {
  invoiceId: Scalars['ID'];
};

export type InvoiceSourceReportFromLinkInput = {
  authCode: Scalars['String'];
  companyId: Scalars['ID'];
  invoiceMonth: Scalars['Int'];
  invoiceYear: Scalars['Int'];
};

export type InvoiceSourceReportHashInput = {
  hash: Scalars['String'];
};

export type InvoiceSourceReportUploadInput = {
  file?: InputMaybe<Scalars['Upload']>;
};

export enum InvoiceStatus {
  AUTHORIZED = 'AUTHORIZED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  VOIDED = 'VOIDED',
}

export type InvoiceStepData = {
  __typename?: 'InvoiceStepData';
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type InvoiceTransaction = FinancialTransaction & {
  __typename?: 'InvoiceTransaction';
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export enum InvoiceType {
  ANNUAL_PLAN = 'ANNUAL_PLAN',
  DEPOSIT = 'DEPOSIT',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL_FUNDING = 'GLOBAL_PAYROLL_FUNDING',
  GROSS = 'GROSS',
  INSURANCE = 'INSURANCE',
  SALARY = 'SALARY',
}

export type InvoiceWorkFlow = {
  __typename?: 'InvoiceWorkFlow';
  allSteps?: Maybe<Array<Maybe<InvoiceWorkflowStepDefinition>>>;
  currentStep?: Maybe<InvoiceWorkflowStepDefinition>;
};

export enum InvoiceWorkflowStep {
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_PAID = 'INVOICE_PAID',
  INVOICE_PAYMENT_INITIATED = 'INVOICE_PAYMENT_INITIATED',
  INVOICE_PAYMENT_RECEIVED = 'INVOICE_PAYMENT_RECEIVED',
  INVOICE_PAYOUT_INITIATED = 'INVOICE_PAYOUT_INITIATED',
}

export type InvoiceWorkflowStepDefinition = {
  __typename?: 'InvoiceWorkflowStepDefinition';
  currentStatus?: Maybe<MemberPayableStatus>;
  data?: Maybe<Array<Maybe<InvoiceStepData>>>;
  statuses?: Maybe<Array<Maybe<MemberPayableStatus>>>;
  step?: Maybe<InvoiceWorkflowStep>;
};

export type Iso20022DirectDebitInstructionFileGenerateData = {
  companyBankOverrideInfo?: InputMaybe<CompanyBankOverrideInfo>;
  companyId: Scalars['ID'];
  expectedPayoutDate: Scalars['Date'];
  invoiceIds: Array<InputMaybe<Scalars['ID']>>;
  invoiceOverrideInfo?: InputMaybe<Array<InputMaybe<InvoiceOverrideInfo>>>;
};

/**
 * This reflects a record of coredb.platform.item_approver table.<br>
 * The table lets us know who are the approvers of an item (and their decisions).<br>
 * Currently used in TimeOff & Expense (extends.graphql)
 */
export type ItemApproverInfo = {
  __typename?: 'ItemApproverInfo';
  /** currently this is just CompanyUser */
  approverUser?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ApprovalItemStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type JobCodeFilters = {
  contractType: ContractType;
  countryCode: CountryCode;
  postProbationBasePay?: InputMaybe<FixedPayComponentInput>;
  probationBasePay?: InputMaybe<FixedPayComponentInput>;
};

export type JobMetaData = {
  __typename?: 'JobMetaData';
  action: Scalars['String'];
  description: Scalars['String'];
  inputTypes?: Maybe<Array<Maybe<KeyValue>>>;
  ownerBy: Scalars['String'];
};

export type JobMetaDataPageResult = {
  __typename?: 'JobMetaDataPageResult';
  data?: Maybe<Array<Maybe<JobMetaData>>>;
  page?: Maybe<PageResult>;
};

export type Kyb = {
  __typename?: 'KYB';
  entity?: Maybe<KybEntity>;
  id: Scalars['ID'];
  kybAssessments?: Maybe<Array<Maybe<KybAssessment>>>;
};

export enum KybAdditionalRequirement {
  COMMENT = 'COMMENT',
  EMAIL = 'EMAIL',
}

export type KybAllowedValue = {
  __typename?: 'KYBAllowedValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KybAssessment = {
  __typename?: 'KYBAssessment';
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  reportExists?: Maybe<Scalars['Boolean']>;
  riskAssessment?: Maybe<Array<Maybe<KybRiskAssessment>>>;
  status?: Maybe<KybAssessmentStatus>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export enum KybAssessmentStatus {
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type KybCollectedData = {
  __typename?: 'KYBCollectedData';
  data?: Maybe<Array<Maybe<KybRiskData>>>;
  documents?: Maybe<Array<Maybe<KybRiskDocument>>>;
  kybRequirementType: Scalars['String'];
};

export type KybCollectedRiskData = {
  __typename?: 'KYBCollectedRiskData';
  fields?: Maybe<Array<Maybe<KybCollectedData>>>;
  report?: Maybe<KybPartnerReport>;
};

export type KybEmailRequirementInput = {
  kybRequirementType: Scalars['String'];
  requiredDocuments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type KybEntity = {
  __typename?: 'KYBEntity';
  company?: Maybe<Company>;
  legalEntity?: Maybe<LegalEntity>;
};

export type KybMetaData = {
  __typename?: 'KYBMetaData';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KybMetaDataInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type KybPartnerReport = {
  __typename?: 'KYBPartnerReport';
  reports?: Maybe<Array<Maybe<DocumentReadable>>>;
};

export type KybRequirement = {
  __typename?: 'KYBRequirement';
  fields?: Maybe<Array<Maybe<KybRequirementField>>>;
  kybRequirementType?: Maybe<Scalars['String']>;
};

export type KybRequirementField = {
  __typename?: 'KYBRequirementField';
  additionalRequirements?: Maybe<Array<Maybe<KybAdditionalRequirement>>>;
  allowedValues?: Maybe<Array<Maybe<KybAllowedValue>>>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type KybRequirementsInput = {
  kybAssessmentId: Scalars['ID'];
  requiredComplianceDocuments?: InputMaybe<
    Array<InputMaybe<KybEmailRequirementInput>>
  >;
  requiredFinancialDocuments?: InputMaybe<
    Array<InputMaybe<KybEmailRequirementInput>>
  >;
};

export type KybRiskAssessment = {
  collectedRiskData?: Maybe<KybCollectedRiskData>;
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  riskScore?: Maybe<AssessmentRiskScore>;
  schema?: Maybe<KybSchema>;
  status?: Maybe<RiskAssessmentStatus>;
  type: AssessmentType;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type KybRiskData = {
  __typename?: 'KYBRiskData';
  key?: Maybe<Scalars['String']>;
  metaData?: Maybe<Array<Maybe<KybMetaData>>>;
  value?: Maybe<Scalars['String']>;
};

export type KybRiskDocument = {
  __typename?: 'KYBRiskDocument';
  documents?: Maybe<Array<Maybe<DocumentReadable>>>;
  key?: Maybe<Scalars['String']>;
  metaData?: Maybe<Array<Maybe<KybMetaData>>>;
};

export type KybSchema = {
  __typename?: 'KYBSchema';
  requirements?: Maybe<Array<Maybe<KybRequirement>>>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type KnitAuthResponse = {
  __typename?: 'KnitAuthResponse';
  error?: Maybe<KnitAuthTokenError>;
  msg?: Maybe<KnitAuthToken>;
  success: Scalars['Boolean'];
};

export type KnitAuthToken = {
  __typename?: 'KnitAuthToken';
  token?: Maybe<Scalars['String']>;
};

export type KnitAuthTokenError = {
  __typename?: 'KnitAuthTokenError';
  msg?: Maybe<Scalars['String']>;
};

export enum Language {
  EN = 'EN',
}

export type LastWorkingDayRangeResponse = {
  __typename?: 'LastWorkingDayRangeResponse';
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type LastWorkingDayResponse = {
  __typename?: 'LastWorkingDayResponse';
  date?: Maybe<Scalars['Date']>;
};

export type LastWorkingDayValidation = {
  __typename?: 'LastWorkingDayValidation';
  isValid?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  minimumLastWorkingDay?: Maybe<Scalars['Date']>;
};

export type LatestSyncResult = {
  __typename?: 'LatestSyncResult';
  addedContracts?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  failedContracts?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['ID']>;
  status?: Maybe<SyncStatus>;
  syncId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedContracts?: Maybe<Scalars['Int']>;
};

export type LeaveTypeMappingDefinition = {
  __typename?: 'LeaveTypeMappingDefinition';
  externalTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  integrationId?: Maybe<Scalars['ID']>;
  internalTypeId?: Maybe<Scalars['String']>;
};

export type LeaveTypeMappingDefinitionInput = {
  externalTypeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  integrationId?: InputMaybe<Scalars['ID']>;
  internalTypeId?: InputMaybe<Scalars['String']>;
};

export type LedgerSide = {
  __typename?: 'LedgerSide';
  account?: Maybe<PaymentPartnerAccount>;
  transferValue?: Maybe<TransferValue>;
};

export enum LegalBindingType {
  CLIENT = 'CLIENT',
  MULTIPLIER = 'MULTIPLIER',
  PARTNER = 'PARTNER',
}

export type LegalData = {
  __typename?: 'LegalData';
  /** @deprecated No longer supported */
  country?: Maybe<CountryCode>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  identifier?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type LegalDataInput = {
  identifier?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type LegalDataParam = {
  __typename?: 'LegalDataParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<LegalData>;
};

export type LegalDataRequirement = {
  __typename?: 'LegalDataRequirement';
  applyTo?: Maybe<LegalDataRequirementApplicability>;
  dependsOn?: Maybe<Array<Maybe<ControllingField>>>;
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  maxChars?: Maybe<Scalars['Int']>;
  minChars?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export enum LegalDataRequirementApplicability {
  ALL = 'ALL',
  EXPAT_ONLY = 'EXPAT_ONLY',
  LOCAL_ONLY = 'LOCAL_ONLY',
}

export enum LegalDataType {
  ALPHA_NUMERIC = 'ALPHA_NUMERIC',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_TEXT = 'DROPDOWN_TEXT',
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT',
  WHOLE_NUMBER = 'WHOLE_NUMBER',
}

export type LegalDocument = {
  __typename?: 'LegalDocument';
  approver?: Maybe<Array<Maybe<OperationsUser>>>;
  category?: Maybe<LegalDocumentCategory>;
  comment?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  country?: Maybe<CountryCode>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use `files` instead */
  documents?: Maybe<Array<Maybe<File>>>;
  files?: Maybe<Array<Document>>;
  financialYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  status?: Maybe<LegalDocumentStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum LegalDocumentCategory {
  LEGAL = 'LEGAL',
  OTHER = 'OTHER',
  PAYROLL = 'PAYROLL',
  PROOF = 'PROOF',
  VISA_PRIMARY = 'VISA_PRIMARY',
  VISA_SECONDARY = 'VISA_SECONDARY',
}

export type LegalDocumentInput = {
  category?: InputMaybe<LegalDocumentCategory>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  financialYear?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
};

export type LegalDocumentParam = {
  __typename?: 'LegalDocumentParam';
  /** @deprecated Please use `value.key` and `value.files` instead */
  documents?: Maybe<Array<Maybe<DocumentReadable>>>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<LegalDocument>;
};

export type LegalDocumentRequirement = {
  __typename?: 'LegalDocumentRequirement';
  acceptMultiple?: Maybe<Scalars['Boolean']>;
  applyTo?: Maybe<LegalDataRequirementApplicability>;
  category?: Maybe<LegalDocumentCategory>;
  deadline?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  template?: Maybe<Array<Maybe<DocumentReadable>>>;
};

export type LegalDocumentRequirementInput = {
  acceptMultiple?: InputMaybe<Scalars['Boolean']>;
  applyTo?: InputMaybe<LegalDataRequirementApplicability>;
  category?: InputMaybe<LegalDocumentCategory>;
  countryCode?: InputMaybe<CountryCode>;
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  endDate?: InputMaybe<Scalars['Date']>;
  key?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum LegalDocumentStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type LegalDocumentsApprovalItem = ApprovalItem & {
  __typename?: 'LegalDocumentsApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  legalDocument?: Maybe<LegalDocument>;
  status?: Maybe<ApprovalItemStatus>;
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  address?: Maybe<Address>;
  bankAccount?: Maybe<BankAccount>;
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  holidays?: Maybe<Array<Holiday>>;
  id?: Maybe<Scalars['ID']>;
  kybId?: Maybe<Scalars['ID']>;
  legalName?: Maybe<Scalars['String']>;
  offeringCodes?: Maybe<Array<Maybe<OfferingCode>>>;
  payrollData?: Maybe<Array<Maybe<DataFieldValue>>>;
  phone?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  status?: Maybe<LegalEntityStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type LegalEntity_HolidaysArgs = {
  date?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<LegalEntityHolidayInput>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type LegalEntityHolidayInput = {
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Array<Scalars['Int']>>;
};

export type LegalEntityPayrollDataRequirements = {
  __typename?: 'LegalEntityPayrollDataRequirements';
  country?: Maybe<CountryCode>;
  requirements?: Maybe<Array<Maybe<DataFieldDefinition>>>;
};

export enum LegalEntityStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export enum LegalMappingStatus {
  FULLY_MAPPED = 'FULLY_MAPPED',
  PARTIALLY_MAPPED = 'PARTIALLY_MAPPED',
  UNMAPPED = 'UNMAPPED',
}

export enum LimitValueType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export type MsaWorkflow = {
  __typename?: 'MSAWorkflow';
  currentStatus?: Maybe<MsaWorkflowStatus>;
  currentStep?: Maybe<MsaWorkflowStep>;
  steps?: Maybe<Array<Maybe<MsaWorkflowStep>>>;
};

export enum MsaWorkflowStatus {
  MSA_GENERATED = 'MSA_GENERATED',
  MSA_GENERATION_PENDING = 'MSA_GENERATION_PENDING',
  MSA_SENT_FOR_SIGNING = 'MSA_SENT_FOR_SIGNING',
  MSA_SIGNED = 'MSA_SIGNED',
}

export enum MsaWorkflowStep {
  GENERATE_MSA = 'GENERATE_MSA',
  SEND_MSA = 'SEND_MSA',
  SIGNING_MSA = 'SIGNING_MSA',
}

export type ManagementFee = {
  __typename?: 'ManagementFee';
  appliedDiscount?: Maybe<Scalars['String']>;
  calculatedDateTime?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<Contract>;
  discountedFee?: Maybe<Scalars['Float']>;
  originalFee?: Maybe<Scalars['Float']>;
};

export type Manager = {
  __typename?: 'Manager';
  company?: Maybe<Company>;
  companyUser?: Maybe<CompanyUser>;
  contract?: Maybe<Contract>;
  directReports?: Maybe<Array<DirectReport>>;
  id: Scalars['ID'];
  orgAttributes?: Maybe<OrgAttributes>;
  reportsToManager?: Maybe<Manager>;
  totalSubordinates?: Maybe<Scalars['Int']>;
};

export type ManagerAssignDirectReportsInput = {
  directReportContractIds?: InputMaybe<Array<Scalars['ID']>>;
  directReportManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  managerId: Scalars['ID'];
  ruleInput?: InputMaybe<RuleInput>;
};

export type ManagerCreateInput = {
  companyId: Scalars['ID'];
  companyUserId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  orgAttributes?: InputMaybe<OrgAttributesInput>;
  reportsToManagerId?: InputMaybe<Scalars['ID']>;
};

export type ManagerDeleteInput = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type ManagerFilters = {
  contractId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ManagerUpdateInput = {
  id: Scalars['ID'];
  orgAttributes?: InputMaybe<OrgAttributesInput>;
};

export type ManagerValidateDirectReportsInput = {
  directReportContractIds?: InputMaybe<Array<Scalars['ID']>>;
  directReportManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  managerId: Scalars['ID'];
};

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type MarriedPolicyRules = {
  __typename?: 'MarriedPolicyRules';
  maxAllowedTotal?: Maybe<Scalars['Int']>;
  maxChildrenAllowed?: Maybe<Scalars['Int']>;
  maxParentsAllowed?: Maybe<Scalars['Int']>;
  spouse?: Maybe<Scalars['Boolean']>;
};

export enum MartialStatus {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type Member = Person & {
  __typename?: 'Member';
  /** @deprecated Moved to Contract. */
  activatedOn?: Maybe<Scalars['DateTime']>;
  addressDetails?: Maybe<MemberAddress>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  age?: Maybe<Scalars['Float']>;
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  basicInfo?: Maybe<MemberBasicInfo>;
  changeRequests?: Maybe<Array<Maybe<MemberChangeRequest>>>;
  contactInfo?: Maybe<MemberContactInfo>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated not required */
  customFields?: Maybe<Array<Maybe<MemberCustomField>>>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  education?: Maybe<Array<Maybe<Education>>>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  emergencyContact?: Maybe<EmergencyContact>;
  firstName?: Maybe<Scalars['String']>;
  fullLegalName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']>;
  identificationDetails?: Maybe<MemberIdentificationDetails>;
  lastName?: Maybe<Scalars['String']>;
  legalData?: Maybe<Array<Maybe<LegalData>>>;
  legalDocuments?: Maybe<Array<Maybe<LegalDocument>>>;
  maritalStatus?: Maybe<MaritalStatus>;
  /** @deprecated Typo in the field. New field `maritalStatus` introduced */
  martialStatus?: Maybe<MartialStatus>;
  middleName?: Maybe<Scalars['String']>;
  nationalities?: Maybe<Array<Maybe<MemberNationalogy>>>;
  payrollFormsUploadDate?: Maybe<Scalars['DateTime']>;
  payrollFormsUploadStatus?: Maybe<PayrollFormsUploadStatus>;
  persona?: Maybe<Persona>;
  personalInfo?: Maybe<MemberPersonalInfo>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  previousEmployers?: Maybe<Array<Maybe<PreviousEmployer>>>;
  profilePicture?: Maybe<Image>;
  status?: Maybe<MemberStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type Member_ContractsArgs = {
  includeAnyMemberStatus?: InputMaybe<Scalars['Boolean']>;
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ContractStatus>;
};

export type Member_LegalDocumentsArgs = {
  category?: InputMaybe<LegalDocumentCategory>;
};

export type MemberAddress = {
  __typename?: 'MemberAddress';
  currentAddress?: Maybe<Address>;
  permanentAddress?: Maybe<Address>;
};

export type MemberAddressInput = {
  currentAddress?: InputMaybe<AddressInput>;
  permanentAddress?: InputMaybe<AddressInput>;
};

export type MemberBasicInfo = {
  __typename?: 'MemberBasicInfo';
  firstName?: Maybe<Scalars['String']>;
  fullLegalName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type MemberBasicInfoInput = {
  firstName?: InputMaybe<Scalars['String']>;
  fullLegalName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export enum MemberChangeCategory {
  BANK_DETAILS = 'BANK_DETAILS',
  BASIC_DETAILS = 'BASIC_DETAILS',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  IDENTIFICATION_DETAILS = 'IDENTIFICATION_DETAILS',
}

export type MemberChangeItem =
  | AddressParam
  | BankAccountParam
  | DateParam
  | EmailAddressParam
  | GenderParam
  | IntParam
  | LegalDataParam
  | LegalDocumentParam
  | MemberNationalogyParam
  | PhoneNumberParam
  | StringParam;

export type MemberChangeRequest = {
  __typename?: 'MemberChangeRequest';
  category?: Maybe<MemberChangeCategory>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<MemberChangeItem>>>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<MemberChangeRequestStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MemberChangeRequestInput = {
  address?: InputMaybe<AddressInput>;
  bankAccount?: InputMaybe<BankAccountInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** proof document for this particullar request. Same behavior as BankAccountInput.bankStatements/bankStatementDocIds */
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  email?: InputMaybe<EmailAddressInput>;
  firstName?: InputMaybe<Scalars['String']>;
  fullLegalName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastname?: InputMaybe<Scalars['String']>;
  legalData?: InputMaybe<Array<LegalDataInput>>;
  legalDocuments?: InputMaybe<Array<LegalDocumentInput>>;
  middleName?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<MemberNationalogyInput>;
  passportNo?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<PhoneNumberInput>;
  workEmail?: InputMaybe<EmailAddressInput>;
};

export enum MemberChangeRequestStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type MemberContactInfo = {
  __typename?: 'MemberContactInfo';
  email?: Maybe<EmailAddress>;
  mobile?: Maybe<PhoneNumber>;
  phone?: Maybe<PhoneNumber>;
};

export type MemberContactInfoInput = {
  email?: InputMaybe<EmailAddressInput>;
  mobile?: InputMaybe<PhoneNumberInput>;
  phone?: InputMaybe<PhoneNumberInput>;
};

export type MemberCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export type MemberCustomField = {
  __typename?: 'MemberCustomField';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MemberCustomFieldInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type MemberDataApprovalItem = ApprovalItem & {
  __typename?: 'MemberDataApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  memberDataChangeRequest?: Maybe<MemberChangeRequest>;
  status?: Maybe<ApprovalItemStatus>;
};

export type MemberDataRequirementsFilter = {
  contractType?: InputMaybe<ContractType>;
  fetchStage?: InputMaybe<FetchStage>;
  onlyDynamicRequirements?: InputMaybe<Scalars['Boolean']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export enum MemberExperiencePage {
  EMPLOYMENT_DETAILS = 'EMPLOYMENT_DETAILS',
}

export type MemberFilters = {
  activationDateRanges?: InputMaybe<Array<DateRange>>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractFilters?: InputMaybe<ContractFilters>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  countries?: InputMaybe<Array<CountryCode>>;
  createdDateRanges?: InputMaybe<Array<DateRange>>;
  endedDateRanges?: InputMaybe<Array<DateRange>>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  hasChangeRequested?: InputMaybe<Scalars['Boolean']>;
  isLegacyOffboardingFilter?: InputMaybe<Scalars['Boolean']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  memberEmails?: InputMaybe<Array<Scalars['String']>>;
  memberNameContains?: InputMaybe<Array<Scalars['String']>>;
  offboardingStatuses?: InputMaybe<Array<ContractOffboardingStatus>>;
  onboardingStatuses?: InputMaybe<Array<ContractOnboardingStatus>>;
  payrollFormsUploadDateRanges?: InputMaybe<Array<DateRange>>;
  payrollFormsUploadStatuses?: InputMaybe<Array<PayrollFormsUploadStatus>>;
  startDateRanges?: InputMaybe<Array<DateRange>>;
  statuses?: InputMaybe<Array<MemberStatus>>;
  uploadedPayrollForms?: InputMaybe<Scalars['Boolean']>;
  validTillDateRanges?: InputMaybe<Array<DateRange>>;
};

export type MemberIdentificationDetails = {
  __typename?: 'MemberIdentificationDetails';
  legalData?: Maybe<Array<Maybe<LegalData>>>;
  legalDocuments?: Maybe<Array<Maybe<LegalDocument>>>;
};

export type MemberIdentificationDetailsInput = {
  legalData?: InputMaybe<Array<InputMaybe<LegalDataInput>>>;
  legalDocuments?: InputMaybe<Array<InputMaybe<LegalDocumentInput>>>;
};

export type MemberNationalogy = {
  __typename?: 'MemberNationalogy';
  country?: Maybe<CountryCode>;
  type?: Maybe<Nationalogy>;
};

export type MemberNationalogyInput = {
  country?: InputMaybe<CountryCode>;
  type?: InputMaybe<Nationalogy>;
};

export type MemberNationalogyParam = {
  __typename?: 'MemberNationalogyParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<MemberNationalogy>;
};

export type MemberPay = {
  additional?: Maybe<Array<Maybe<AdditionalPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountNet?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  clientDeductions?: Maybe<Array<Maybe<PayComponent>>>;
  companyPayroll?: Maybe<CompanyPayroll>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<PayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<PayComponent>>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payrollCycle?: Maybe<PayrollCycle>;
  payslip?: Maybe<Payslip>;
  status?: Maybe<MemberPayStatus>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export enum MemberPayStatus {
  NEW = 'NEW',
  PAID = 'PAID',
  PAYSLIP_GENERATION_FAILED = 'PAYSLIP_GENERATION_FAILED',
  PAYSLIP_GENERATION_IN_PROGRESS = 'PAYSLIP_GENERATION_IN_PROGRESS',
  PAYSLIP_GENERATION_SUCCESS = 'PAYSLIP_GENERATION_SUCCESS',
  PENDING = 'PENDING',
}

export type MemberPayable = {
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MemberPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type MemberPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type MemberPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export type MemberPayableAmount = {
  __typename?: 'MemberPayableAmount';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export type MemberPayableApprovalItem = ApprovalItem & {
  __typename?: 'MemberPayableApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  memberPayableId?: Maybe<Scalars['ID']>;
  memberPayableItem?: Maybe<MemberPayable>;
  status?: Maybe<ApprovalItemStatus>;
};

export enum MemberPayableAutoGenerationState {
  OFF = 'OFF',
  ON = 'ON',
}

export type MemberPayableChange = {
  __typename?: 'MemberPayableChange';
  actionedBy?: Maybe<Person>;
  actionedOn?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<MemberPayableStatus>;
};

/** @deprecated: No usage found in backend codebase */
export enum MemberPayableCompanyExperienceStatus {
  PAID = 'PAID',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  UNPAID = 'UNPAID',
}

export type MemberPayableCompanyInvoice = {
  __typename?: 'MemberPayableCompanyInvoice';
  bankAccount: BankAccount;
  bankTransferFee?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  company?: Maybe<Company>;
  companyPayable?: Maybe<CompanyPayable>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<DocumentReadable>;
  /**
   * @deprecated: No usage found in backend codebase
   * @deprecated No longer supported
   */
  experienceStatus?: Maybe<MemberPayableCompanyExperienceStatus>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<MemberPayableCompanyInvoiceStatus>;
  isReadyForPayouts?: Maybe<Scalars['Boolean']>;
  memberPayableInvoiceItems?: Maybe<
    Array<Maybe<MemberPayableCompanyInvoiceItem>>
  >;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  paidOn?: Maybe<Scalars['DateTime']>;
  payInInfo?: Maybe<PayInInfo>;
  paymentMethod?: Maybe<PaymentMethodInfo>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutPaymentMethodInfo?: Maybe<Array<Maybe<PaymentMethodInfo>>>;
  referenceId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MemberPayableCompanyInvoice_DocumentArgs = {
  format?: InputMaybe<MemberPayableCompanyInvoiceFormat>;
};

export type MemberPayableCompanyInvoiceFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  generatedDateRange?: InputMaybe<DateRange>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceStatus?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
  payInMethod?: InputMaybe<PaymentMethod>;
  referenceId?: InputMaybe<Scalars['String']>;
};

export enum MemberPayableCompanyInvoiceFormat {
  CSV = 'CSV',
  PDF = 'PDF',
}

export type MemberPayableCompanyInvoiceItem = {
  __typename?: 'MemberPayableCompanyInvoiceItem';
  billingAmount?: Maybe<Scalars['Float']>;
  managementFee?: Maybe<Scalars['Float']>;
  memberPayable?: Maybe<MemberPayable>;
};

export type MemberPayableCompanyInvoiceResult = {
  __typename?: 'MemberPayableCompanyInvoiceResult';
  data?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
};

export enum MemberPayableCompanyInvoiceStatus {
  /** when the payin is received without any mismatch */
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  /** when the payin is received but the amount doesn't match. Similar to MemberPayableStatus.PARTIAL_PAY_IN_RECEIVED */
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PROCESSING = 'PROCESSING',
  VOID = 'VOID',
}

export type MemberPayableCompanyInvoiceSubmitInput = {
  memberPayableIds: Array<InputMaybe<Scalars['ID']>>;
  paymentMethod?: InputMaybe<PaymentMethod>;
};

/** Deprecated. This was created for REMITTANCE_ADVICE feature but remittance advice feature is not in use */
export enum MemberPayableDocumentType {
  /** @deprecated No longer supported */
  INVOICE = 'INVOICE',
  /** @deprecated No longer supported */
  REMITTANCE_ADVICE = 'REMITTANCE_ADVICE',
}

export enum MemberPayableExtraFeeType {
  CRYPTO_FEE = 'CRYPTO_FEE',
  FLAT_FEE = 'FLAT_FEE',
  SWIFT_FEE = 'SWIFT_FEE',
  VARIABLE_FEE = 'VARIABLE_FEE',
}

export type MemberPayableInvoiceSourceReportExportInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  payableMonth?: InputMaybe<PayableMonthInput>;
};

export type MemberPayableItem = {
  __typename?: 'MemberPayableItem';
  amount?: Maybe<Scalars['Float']>;
  amountInFunctionalCurrency?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Not in use since when the `custom invoice upload` feature was dropped in Feb 2022 */
  supportingDocs?: Maybe<Array<Maybe<DocumentReadable>>>;
};

export type MemberPayableItemInput = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  /** @deprecated Not in use since when the `custom invoice upload` feature was dropped in Feb 2022 */
  supportingDocs?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
};

export type MemberPayablePayOutInfo = {
  __typename?: 'MemberPayablePayOutInfo';
  paidOn?: Maybe<Scalars['DateTime']>;
  paymentPartner?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type MemberPayableRejectInput = {
  additionalComment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type MemberPayableReportFilter = {
  companyIds: Array<Scalars['ID']>;
  companyInvoiceReferenceId?: InputMaybe<Scalars['String']>;
  contractIds: Array<Scalars['ID']>;
  countryCodes: Array<CountryCode>;
  createdDateRange?: InputMaybe<DateRange>;
  externalId?: InputMaybe<Scalars['ID']>;
  memberName?: InputMaybe<Scalars['String']>;
  memberPayableIds: Array<Scalars['ID']>;
  payablePeriod?: InputMaybe<PeriodInput>;
  paymentMethod?: InputMaybe<TransferType>;
  paymentStatuses: Array<MemberPayableStatus>;
  payoutDateRange?: InputMaybe<DateRange>;
  type?: InputMaybe<MemberPayableType>;
};

export enum MemberPayableStatus {
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  PARTIAL_PAY_IN_RECEIVED = 'PARTIAL_PAY_IN_RECEIVED',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYOUT_COMPLETED = 'PAYOUT_COMPLETED',
  PAYOUT_FAILED = 'PAYOUT_FAILED',
  PAYOUT_INITIATED = 'PAYOUT_INITIATED',
  PAY_IN_PROCESSING = 'PAY_IN_PROCESSING',
  PAY_IN_RECEIVED = 'PAY_IN_RECEIVED',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  UNPAID = 'UNPAID',
}

export type MemberPayableSubmitInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  dueDate?: InputMaybe<Scalars['Date']>;
  duration?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  items: Array<InputMaybe<MemberPayableItemInput>>;
  payablePeriod: PeriodInput;
  title?: InputMaybe<Scalars['String']>;
};

export type MemberPayableSummary = {
  __typename?: 'MemberPayableSummary';
  failedPayOutTotalCount?: Maybe<Scalars['Int']>;
  payInReceivedTotalCount?: Maybe<Scalars['Int']>;
  payOutInitiatedCount?: Maybe<Scalars['Int']>;
  payOutInitiatedTotalCount?: Maybe<Scalars['Int']>;
};

export type MemberPayableSummary_PayOutInitiatedCountArgs = {
  pendingDays?: InputMaybe<Scalars['Int']>;
};

export enum MemberPayableType {
  EXPENSE = 'EXPENSE',
  /** @deprecated No longer supported */
  FREELANCER = 'FREELANCER',
  INVOICE = 'INVOICE',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
}

export type MemberPayableUpdateBulkResponse = {
  __typename?: 'MemberPayableUpdateBulkResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export type MemberPayableUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  dueDate?: InputMaybe<Scalars['Date']>;
  duration?: InputMaybe<Scalars['Float']>;
  items: Array<InputMaybe<MemberPayableItemInput>>;
  payablePeriod: PeriodInput;
  title?: InputMaybe<Scalars['String']>;
};

export type MemberPayrollCost = {
  __typename?: 'MemberPayrollCost';
  billingCost?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  memberPay?: Maybe<MemberPay>;
  totalCost?: Maybe<Scalars['Float']>;
  type?: Maybe<PayableItemType>;
};

export type MemberPayrollCycle = {
  __typename?: 'MemberPayrollCycle';
  memberPay?: Maybe<MemberPay>;
  payrollCycle?: Maybe<PayrollCycle>;
};

export type MemberPayrollCycleDetail = {
  __typename?: 'MemberPayrollCycleDetail';
  compensations?: Maybe<Array<CompensationSnapshot>>;
  expenses?: Maybe<Array<ExpenseSnapshot>>;
  fixedAllowances?: Maybe<Array<FixedAllowance>>;
  memberPay?: Maybe<MemberPay>;
  paySupplements?: Maybe<Array<PaySupplementSnapshot>>;
  payrollCycle?: Maybe<PayrollCycle>;
  summary?: Maybe<MemberPayrollCycleSummary>;
  unpaidTimeOffs?: Maybe<Array<TimeOffSnapshot>>;
};

export type MemberPayrollCycleDetailFilter = {
  payrollCycleId: Scalars['ID'];
};

export type MemberPayrollCycleFilter = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MemberPayrollCycleSummary = {
  __typename?: 'MemberPayrollCycleSummary';
  compensation?: Maybe<MemberPayrollCycleSummaryItem>;
  deductions?: Maybe<MemberPayrollCycleSummaryItem>;
  expense?: Maybe<MemberPayrollCycleSummaryItem>;
  fixedAllowance?: Maybe<MemberPayrollCycleSummaryItem>;
  paySupplement?: Maybe<MemberPayrollCycleSummaryItem>;
  unpaidTimeOff?: Maybe<MemberPayrollCycleSummaryItem>;
};

export type MemberPayrollCycleSummaryItem = {
  __typename?: 'MemberPayrollCycleSummaryItem';
  count?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type MemberPayrollCyclesResponse = {
  __typename?: 'MemberPayrollCyclesResponse';
  payrollCycleDetails?: Maybe<Array<MemberPayrollCycleDetail>>;
  /** @deprecated Use payrollCycleDetails instead */
  payrollCycles?: Maybe<Array<MemberPayrollCycle>>;
};

export enum MemberPersonalDetailsSection {
  ADDRESS = 'ADDRESS',
  BASIC_INFO = 'BASIC_INFO',
  CHANGE_REQUESTS = 'CHANGE_REQUESTS',
  EDUCATION = 'EDUCATION',
  EMERGENCY = 'EMERGENCY',
  FINANCIAL = 'FINANCIAL',
  IDENTIFICATION_DETAILS = 'IDENTIFICATION_DETAILS',
  PERSONAL_CONTACT_DETAILS = 'PERSONAL_CONTACT_DETAILS',
  PERSONAL_INFO = 'PERSONAL_INFO',
  PREVIOUS_EMPLOYMENT = 'PREVIOUS_EMPLOYMENT',
}

export type MemberPersonalInfo = {
  __typename?: 'MemberPersonalInfo';
  age?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  maritalStatus?: Maybe<MaritalStatus>;
  nationalities?: Maybe<Array<Maybe<MemberNationalogy>>>;
  religion?: Maybe<Scalars['String']>;
};

export type MemberPersonalInfoInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  gender?: InputMaybe<Gender>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  nationalities?: InputMaybe<Array<MemberNationalogyInput>>;
  religion?: InputMaybe<Scalars['String']>;
};

export enum MemberStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  INVITED = 'INVITED',
  SUSPENDED = 'SUSPENDED',
}

export type MemberUpdateBasicDetailsInput = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullLegalName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  /** @deprecated Typo in the field. New field `maritalStatus` introduced */
  martialStatus?: InputMaybe<MartialStatus>;
  middleName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<MemberNationalogyInput>;
  phone?: InputMaybe<PhoneNumberInput>;
  status?: InputMaybe<MemberStatus>;
};

export type MemberUpdateInput = {
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export type MembersInput = {
  /** filters contains the list of filter and merge it by AND operation */
  filters?: InputMaybe<MemberFilters>;
};

export type MembersResult = {
  __typename?: 'MembersResult';
  activeCount?: Maybe<Scalars['Int']>;
  allCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Member>>>;
  endedCount?: Maybe<Scalars['Int']>;
  offboardingCount?: Maybe<Scalars['Int']>;
  onboardingCount?: Maybe<Scalars['Int']>;
  pageResult?: Maybe<PageResult>;
};

export type MonthYear = {
  __typename?: 'MonthYear';
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type MonthYearCycleInput = {
  cycle?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MonthYearInput = {
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MsaAddendum = {
  __typename?: 'MsaAddendum';
  addendumDocId?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<DocumentReadable>;
  id?: Maybe<Scalars['ID']>;
  msaAddendumChange?: Maybe<MsaAddendumChange>;
  msaAddendumChanges?: Maybe<Array<Maybe<MsaAddendumChange>>>;
  operationType?: Maybe<MsaAddendumOperationType>;
  status?: Maybe<MsaAddendumStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MsaAddendumChange = {
  __typename?: 'MsaAddendumChange';
  customDiscountTerm?: Maybe<MsaAddendumChangeCustomDiscountTerm>;
  discountTerms?: Maybe<MsaAddendumChangeDiscountTerms>;
  employeePricing?: Maybe<MsaAddendumChangeEmployeePricing>;
  fieldName?: Maybe<MsaDataChangeFieldName>;
  globalFreelancerPricing?: Maybe<MsaAddendumChangeGlobalFreelancerPricing>;
  paymentTermInDays?: Maybe<MsaAddendumChangePaymentTermInDays>;
  pricingPlan?: Maybe<MsaAddendumChangePricingPlan>;
};

export type MsaAddendumChangeCustomDiscountTerm = {
  __typename?: 'MsaAddendumChangeCustomDiscountTerm';
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export type MsaAddendumChangeDiscountTerms = {
  __typename?: 'MsaAddendumChangeDiscountTerms';
  newValue?: Maybe<Array<Maybe<DiscountTerm>>>;
  oldValue?: Maybe<Array<Maybe<DiscountTerm>>>;
};

export type MsaAddendumChangeEmployeePricing = {
  __typename?: 'MsaAddendumChangeEmployeePricing';
  newValue?: Maybe<Array<Maybe<EmployeePricing>>>;
  oldValue?: Maybe<Array<Maybe<EmployeePricing>>>;
};

export type MsaAddendumChangeGlobalFreelancerPricing = {
  __typename?: 'MsaAddendumChangeGlobalFreelancerPricing';
  newValue?: Maybe<Scalars['Float']>;
  oldValue?: Maybe<Scalars['Float']>;
};

export type MsaAddendumChangePaymentTermInDays = {
  __typename?: 'MsaAddendumChangePaymentTermInDays';
  newValue?: Maybe<Scalars['Int']>;
  oldValue?: Maybe<Scalars['Int']>;
};

export type MsaAddendumChangePricingPlan = {
  __typename?: 'MsaAddendumChangePricingPlan';
  newId?: Maybe<Scalars['Int']>;
  oldId?: Maybe<Scalars['Int']>;
};

export type MsaAddendumFilters = {
  statuses: Array<MsaAddendumStatus>;
};

export type MsaAddendumInput = {
  companyId: Scalars['ID'];
  pricingInput?: InputMaybe<PricingInput>;
};

export enum MsaAddendumOperationType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}

export enum MsaAddendumStatus {
  ADDENDUM_COMPLETED = 'ADDENDUM_COMPLETED',
  ADDENDUM_DELETED = 'ADDENDUM_DELETED',
  ADDENDUM_DRAFT = 'ADDENDUM_DRAFT',
  ADDENDUM_GENERATED = 'ADDENDUM_GENERATED',
  ADDENDUM_SENT_FOR_SIGNING = 'ADDENDUM_SENT_FOR_SIGNING',
  ADDENDUM_SIGNED_BY_CUSTOMER = 'ADDENDUM_SIGNED_BY_CUSTOMER',
  ADDENDUM_SIGNED_BY_MULTIPLIER = 'ADDENDUM_SIGNED_BY_MULTIPLIER',
}

export enum MsaDataChangeFieldName {
  CUSTOM_DISCOUNT_TERM = 'CUSTOM_DISCOUNT_TERM',
  DISCOUNT_TERMS = 'DISCOUNT_TERMS',
  EMPLOYEE_PRICING = 'EMPLOYEE_PRICING',
  GLOBAL_FREELANCER_PRICING = 'GLOBAL_FREELANCER_PRICING',
  PAYMENT_TERM_IN_DAYS = 'PAYMENT_TERM_IN_DAYS',
  PRICING_PLAN = 'PRICING_PLAN',
}

export type Mutation = {
  __typename?: 'Mutation';
  PaymentInstructionFileGenerate?: Maybe<TaskResponse>;
  PaymentInstructionFileGenerateV2?: Maybe<TaskResponse>;
  PennyTestInitiate?: Maybe<TaskResponse>;
  activateReviews?: Maybe<TaskResponse>;
  addOrAssociatePartnerCompanyUser?: Maybe<TaskResponse>;
  approveItem?: Maybe<ApprovalItem>;
  assignOperationUsers?: Maybe<Company>;
  assignPayrollSnapshotDomain?: Maybe<TaskResponse>;
  /** @deprecated no longer support, use payrollInputFileDownloadAsync instead */
  asyncPayrollInputFileDownload?: Maybe<TaskResponse>;
  /** @deprecated no longer support, use payrollInputFilePartnerBulkDownloadAsync instead */
  asyncPayrollInputFilePartnerBulkDownload?: Maybe<TaskResponse>;
  autoDebitManualTrigger?: Maybe<TaskResponse>;
  backFillKybForCompanies?: Maybe<TaskResponse>;
  backfillEntitlementDefinitionIds?: Maybe<TaskResponse>;
  backfillHrisManagersFromCompanyUsers?: Maybe<TaskResponse>;
  backfillRulesForExistingCompanyDefinitions?: Maybe<TaskResponse>;
  backfillTimeoffAllocationRecords?: Maybe<TaskResponse>;
  backfillTimeoffApprovedOn?: Maybe<TaskResponse>;
  backfillTimeoffCarryForwardRecords?: Maybe<TaskResponse>;
  benefitActivityAddOperation?: Maybe<TaskResponse>;
  benefitActivityAddOperationWrapper?: Maybe<BenefitPartnerCountryResponse>;
  /** @deprecated No longer supported */
  benefitActivityUpdateOperation?: Maybe<TaskResponse>;
  benefitActivityUpdateOperationWithId?: Maybe<TaskResponse>;
  benefitActivityUpdateOperations?: Maybe<TaskResponse>;
  benefitCreate?: Maybe<Array<Maybe<Benefit>>>;
  benefitDelete?: Maybe<Benefit>;
  benefitPackageMappingCSV?: Maybe<TaskResponse>;
  benefitPartnerCountryDelete?: Maybe<BenefitPartnerCountry>;
  benefitPartnerMappingBackfillCSV?: Maybe<TaskResponse>;
  benefitPartnerUpdateDocuments?: Maybe<Array<Maybe<BenefitDocument>>>;
  benefitSetTierPackage?: Maybe<Benefit>;
  benefitUpdate?: Maybe<Benefit>;
  benefitUpdateDocument?: Maybe<Benefit>;
  bulkCreatePayrollCycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  bulkOnboardingTrigger?: Maybe<BulkOnboardingJob>;
  bulkOnboardingValidate?: Maybe<BulkOnboardingValidationResult>;
  bulkPayrollCycleConfigUpdate?: Maybe<PayrollCycleConfigsResponse>;
  bulkUpdateCompanyInvoiceStatus?: Maybe<TaskResponse>;
  bulkUpdatePayrollCycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  bulkUploadMemberPayableExtraFees?: Maybe<TaskResponse>;
  bulkUploadMemberPayableFromExternalEntity?: Maybe<TaskResponse>;
  categoryBulkUpload: BulkUploadResponse;
  changeSyncState?: Maybe<TaskResponse>;
  /**
   * Typical use case: to move a timeoff to weekend/holiday as some contracts still work on those days. Doesn't change noOfDays, sessions or anything else.
   * To prevent complicated bugs related to timeoff summary/cycle, there are following rules you should ask the user to follow first:
   * - timeoff must be first submitted by the user (status = APPROVAL_IN_PROGRESS/APPROVED/TAKEN)
   * - can only change to a date range within the months of the old date range. E.g.:
   *     - if old date range is Jul N1 - Jul N2: new date range must be Jul N3 - Jul N4
   *     - if old date range is Jul N1 - Aug N2: new date range must be Jul N3 - Jul 4, or Jul N3 - Aug N4, or Aug N3 - Aug N4
   * If you're sure above validations are not relevant in your case (summary cycle is in another month etc...), pass ignoresValidations=true to "sudo" it (be careful!).
   * If you're not sure, please @Thang @Max for more details
   */
  changeTimeOffDate?: Maybe<TaskResponse>;
  clearGPSyncFailedEvents?: Maybe<TaskResponse>;
  clearGPSyncFailedEventsV2?: Maybe<TaskResponse>;
  companyAcceptInvite?: Maybe<Company>;
  companyBackfillEmployeePricing?: Maybe<TaskResponse>;
  companyBackfillPricing?: Maybe<TaskResponse>;
  companyBindingCreate?: Maybe<CompanyBinding>;
  companyCreate?: Maybe<Company>;
  companyGenerateMSA?: Maybe<Company>;
  /** @deprecated No longer supported */
  companyGroupAddCompany?: Maybe<CompanyGroup>;
  /** @deprecated No longer supported */
  companyGroupAddUsers?: Maybe<CompanyGroup>;
  companyGroupCompanyAdd?: Maybe<CompanyGroup>;
  companyGroupCompanyRemove?: Maybe<CompanyGroup>;
  companyGroupCreate?: Maybe<CompanyGroup>;
  companyGroupUpdate?: Maybe<CompanyGroup>;
  companyGroupUserAdd?: Maybe<CompanyGroup>;
  companyGroupUserRemove?: Maybe<CompanyGroup>;
  companyInactivate?: Maybe<Company>;
  companyInvite?: Maybe<Company>;
  companyLegalEntityActivate?: Maybe<LegalEntity>;
  companyLegalEntityCopyCountryHolidays?: Maybe<TaskResponse>;
  companyLegalEntityCreate?: Maybe<LegalEntity>;
  companyLegalEntityUpdate?: Maybe<LegalEntity>;
  companyMSARequest?: Maybe<Request>;
  companyMSASendForSignature?: Maybe<FileLink>;
  companyMsaUpload?: Maybe<Company>;
  companyOfferingFeeComponentDiscountUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingFeeComponentTierCreate?: Maybe<CompanyOfferingPricing>;
  companyOfferingFeeComponentTierUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingFeeComponentUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingPricingActivate?: Maybe<Array<Maybe<CompanyOfferingPricing>>>;
  companyOfferingPricingCreate?: Maybe<CompanyOfferingPricing>;
  companyOfferingPricingDeactivate?: Maybe<
    Array<Maybe<CompanyOfferingPricing>>
  >;
  companyOfferingPricingDiscountUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingsAdd?: Maybe<Array<Maybe<CompanyOffering>>>;
  companyOfferingsUpdate?: Maybe<Array<Maybe<CompanyOffering>>>;
  companyPayableGenerate?: Maybe<CompanyPayableWithErrors>;
  /** @deprecated Use the generateDepositForContract instead */
  companyPayableGenerateForContract?: Maybe<CompanyPayable>;
  companyPayableSyncFromExternalSystem?: Maybe<TaskResponse>;
  companyPayableUploadInvoiceSourceReport?: Maybe<TaskResponse>;
  companyPayrollConsolidatedReportDownloadAsync?: Maybe<PayrollConsolidatedReportDownloadResponse>;
  companyPayrollCycleRefresh?: Maybe<Array<Maybe<CompanyPayrollCycle>>>;
  companyPayrollFileDownloadAsync?: Maybe<PayrollFileDownloadResponse>;
  companyPayrollInputConfirm?: Maybe<CompanyPayrollCycle>;
  companyPayrollOutputConfirm?: Maybe<CompanyPayrollCycle>;
  companyPayrollPayslipPublish?: Maybe<PublishPayslipsResponse>;
  companyPayrollReportsRegenerate?: Maybe<TaskResponse>;
  /** This removes only the SIGNATORY role. Details: https://app.clickup.com/t/865cghnvm */
  companyRemoveSignatory?: Maybe<CompanyUser>;
  companyRemoveUser?: Maybe<CompanyUser>;
  companyRemoveUsers?: Maybe<Array<Maybe<CompanyUser>>>;
  companySlabFXRateUpload?: Maybe<TaskResponse>;
  companyUpdate?: Maybe<Company>;
  companyUpdateBasicDetails?: Maybe<Company>;
  companyUpdateContactDetails?: Maybe<Company>;
  companyUpdateUser?: Maybe<CompanyUser>;
  companyUpdateUsers?: Maybe<Array<Maybe<CompanyUser>>>;
  companyUserAcceptInvite?: Maybe<CompanyUser>;
  companyUserAdd?: Maybe<CompanyUser>;
  companyUserAddApproverInfo?: Maybe<CompanyUser>;
  companyUserAssignCapabilities?: Maybe<CompanyUser>;
  companyUserAssignRole?: Maybe<Array<Maybe<CompanyUser>>>;
  companyUserAssignRoleAndAttributes?: Maybe<CompanyUser>;
  companyUserEditRoleAndAttributes?: Maybe<CompanyUser>;
  companyUserResendApproverInvite?: Maybe<CompanyUser>;
  companyUserRevokeApproverRole?: Maybe<CompanyUser>;
  companyUserRevokeCapability?: Maybe<CompanyUser>;
  companyUserRevokeRole?: Maybe<CompanyUser>;
  companyUserUpdateApproverInfo?: Maybe<CompanyUser>;
  companyUsersAdd?: Maybe<Array<Maybe<CompanyUser>>>;
  /**
   * This directly updates the compensation record, setting columns based on the fields specified in the input (null/omitted = ignored).
   * This means, to really set a column to `null`, you have to raise db-update PRs for now (rare demand).
   * No other side effect except `compensationUpdateKafkaPublisher.publishUpdate(contractId);`.
   * More details on each field: check comments in CompensationRecordUpdateInput.
   *
   * Intended for Product Ops only (alternative of database-updater PRs). Once we have a better way to do this, we can deprecate this
   */
  compensationRecordUpdate?: Maybe<TaskResponse>;
  completeComplianceAssessment?: Maybe<ComplianceRiskAssessment>;
  completeFinancialAssessment?: Maybe<FinancialRiskAssessment>;
  confirmPayrollInput?: Maybe<PayrollCycle>;
  confirmPayslips?: Maybe<ConfirmPayslipsResponse>;
  contractActivate?: Maybe<Contract>;
  contractAddendumGenerate: ContractAddendum;
  contractAddendumSendForSignature?: Maybe<ContractAddendum>;
  contractAgreementCustomUpload?: Maybe<Contract>;
  contractAgreementRegenerate?: Maybe<TaskResponse>;
  contractAgreementReplace?: Maybe<Contract>;
  contractAgreementReplaceWetInk?: Maybe<Contract>;
  contractAssignOperationsUser?: Maybe<Contract>;
  contractChangeCountry?: Maybe<Contract>;
  contractChangeRequestStatusUpdate?: Maybe<Contract>;
  contractChangeRequestUpdate?: Maybe<Contract>;
  contractCompleteEmployeeSignature?: Maybe<Contract>;
  contractCompleteEmployerSignature?: Maybe<Contract>;
  contractCompleteVerification?: Maybe<Contract>;
  contractCompleteVisaEligibilityReview?: Maybe<Contract>;
  contractConfirmContractPrepare?: Maybe<Contract>;
  contractConfirmMemberDetails?: Maybe<Contract>;
  contractConfirmVisaApplicationFiling?: Maybe<Contract>;
  contractConfirmVisaApproved?: Maybe<Contract>;
  contractConfirmVisaInvoicePaid?: Maybe<Contract>;
  contractConfirmWetInkContractPrepare?: Maybe<Contract>;
  contractCreate?: Maybe<Contract>;
  contractCreateBenefitChangeRequests?: Maybe<ContractBenefitChangeRequestResponse>;
  contractCreateBulk?: Maybe<TaskResponse>;
  contractCreateBulkPaymentAccount?: Maybe<TaskResponse>;
  contractDelete?: Maybe<Contract>;
  contractDeleteBenefitDependentDocuments?: Maybe<Contract>;
  contractDeleteCompensationGrant?: Maybe<Array<Maybe<Grant>>>;
  contractDeleteProbationBasePay?: Maybe<Contract>;
  contractDocumentRequestChanges?: Maybe<Contract>;
  contractDrop?: Maybe<Contract>;
  contractEnd?: Maybe<Contract>;
  contractInviteMember?: Maybe<Contract>;
  contractInviteMemberBulk?: Maybe<TaskResponse>;
  contractInviteMemberResend?: Maybe<Contract>;
  contractLinkTemplate?: Maybe<TaskResponse>;
  contractMemberBenefitCompleted?: Maybe<Contract>;
  contractMemberCompleted?: Maybe<Contract>;
  contractMemberStarted?: Maybe<Contract>;
  contractOffboard?: Maybe<Contract>;
  contractOffboardingCancel?: Maybe<Contract>;
  contractOffboardingCancelV2?: Maybe<ContractOffboarding>;
  contractOffboardingComplete?: Maybe<ContractOffboarding>;
  contractOffboardingDidNotJoinInitialise?: Maybe<ContractOffboarding>;
  contractOffboardingReschedule?: Maybe<ContractOffboarding>;
  contractOffboardingResignationInitialise?: Maybe<ContractOffboarding>;
  contractOffboardingTerminationInitialise?: Maybe<ContractOffboarding>;
  contractOffboardingTriggerEndContractScheduler?: Maybe<TaskResponse>;
  contractOffboardingVerify?: Maybe<ContractOffboarding>;
  contractOfflineSignatureProcess?: Maybe<Contract>;
  /** @deprecated No longer supported */
  contractOnboardingUpdate?: Maybe<ContractOnboarding>;
  contractOrderFormSendForSignature?: Maybe<Contract>;
  contractRequestChangesFromMember?: Maybe<Contract>;
  contractRevise?: Maybe<Contract>;
  contractRevoke?: Maybe<Contract>;
  contractSendAgreementToMemberForSignature?: Maybe<Contract>;
  contractSendForSignature?: Maybe<Contract>;
  contractSendOfferLetter?: Maybe<TaskResponse>;
  contractSendReminderForSignature?: Maybe<Contract>;
  contractSetTest?: Maybe<Contract>;
  contractStartVerification?: Maybe<Contract>;
  contractTriggerCompleteOnboardingReminderEmail?: Maybe<TaskResponse>;
  contractTriggerFixedTermEndingEmail?: Maybe<TaskResponse>;
  contractTriggerJoingDayComplianceEmail?: Maybe<TaskResponse>;
  contractTriggerJoingDayEmail?: Maybe<TaskResponse>;
  contractTriggerPayrollFormsReminderEmail?: Maybe<TaskResponse>;
  contractUnassignOperationsUser?: Maybe<Contract>;
  contractUnblock?: Maybe<Contract>;
  contractUpdateBenefitDependentBasicDetails?: Maybe<Contract>;
  contractUpdateBenefitDependentDocuments?: Maybe<Contract>;
  contractUpdateBenefitDependentPlan?: Maybe<Contract>;
  contractUpdateBenefits?: Maybe<Contract>;
  contractUpdateCompensation?: Maybe<Contract>;
  contractUpdateCompliance?: Maybe<Contract>;
  contractUpdateDeductions?: Maybe<Array<Maybe<Deduction>>>;
  contractUpdateEmployment?: Maybe<Contract>;
  contractUpdateLegalEntity?: Maybe<Contract>;
  contractUpdatePayrollStartDateBulk?: Maybe<Array<Maybe<Contract>>>;
  contractUpdateTimeOffEntitlements?: Maybe<Contract>;
  contractWorkEmailUpdate?: Maybe<Contract>;
  contractWorkShiftBulkUpdate?: Maybe<TaskResponse>;
  countryCompensationDefinitionDelete?: Maybe<TaskResponse>;
  countryCompensationDefinitionUpsert?: Maybe<CompensationStructureDefinition>;
  countryComplianceParamTypeUpdate?: Maybe<ComplianceParamType>;
  countryComplianceParameterUpdate?: Maybe<ComplianceParamDefinition>;
  countryLegalDataDelete?: Maybe<TaskResponse>;
  countryLegalDataUpsert?: Maybe<DataFieldDefinition>;
  countryUpdateLegalDocumentRequirement?: Maybe<LegalDocumentRequirement>;
  countryUpdateMemberDataDate?: Maybe<Member>;
  countryUpdateMemberDataText?: Maybe<Member>;
  countryUpdateOnboardingFlowEnablement?: Maybe<CountryCompliance>;
  createDemoCycles: Array<PayrollCycle>;
  createInsuranceCoverageGroup?: Maybe<InsuranceCoverageGroup>;
  createInsuranceRelation?: Maybe<InsuranceRelation>;
  createInsuranceTier?: Maybe<InsuranceTier>;
  createPayrollContractException?: Maybe<PayrollContractException>;
  createPayrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
  createPayrollWorkflow: PayrollWorkflowResponse;
  currencyRefreshFXRates?: Maybe<TaskResponse>;
  currencyUploadExchangeRates?: Maybe<TaskResponse>;
  customPayrollReportConfigsCreate: Array<CustomPayrollReportConfig>;
  customPayrollReportConfigsUpdate: Array<CustomPayrollReportConfig>;
  customPayrollReportCreate: Array<CustomPayrollReportDetails>;
  customPayrollReportFileUpdate: CustomPayrollReportDetails;
  customerIntegrationDisconnect?: Maybe<CustomerIntegration>;
  customerUpdatePayrollWorkflow: CustomerPayrollWorkflowResponse;
  deleteAssessmentDocument?: Maybe<TaskResponse>;
  deleteCompanyPayInMethod?: Maybe<TaskResponse>;
  deletePayrollContractException?: Maybe<TaskResponse>;
  deletePayrollPaymentBatch?: Maybe<TaskResponse>;
  deletePerformanceReview?: Maybe<Array<Maybe<PerformanceReview>>>;
  deleteRecruiter?: Maybe<TaskResponse>;
  deleteSalaryReview?: Maybe<Array<Maybe<SalaryReview>>>;
  departmentAssignEmployees?: Maybe<Department>;
  departmentCreate?: Maybe<Department>;
  departmentDelete?: Maybe<Department>;
  departmentUpdate?: Maybe<Department>;
  dismissSyncResult?: Maybe<TaskResponse>;
  disputeCreate: Dispute;
  disputeResolve: Dispute;
  /** @deprecated Use saveAndDownloadCompanyQuotation instead */
  downloadCompanyQuotation?: Maybe<FileLink>;
  dummy?: Maybe<Scalars['Boolean']>;
  dummyAccessibilityMutation?: Maybe<Scalars['Boolean']>;
  emailTemplateUpsert?: Maybe<TaskResponse>;
  enableCompanyPayInMethod?: Maybe<CompanyPayInMethod>;
  equipmentProcurementCreate?: Maybe<EquipmentProcurement>;
  equipmentProductsUpdate?: Maybe<TaskResponse>;
  equipmentQuotationCreate?: Maybe<TaskResponse>;
  equipmentsUpdate?: Maybe<TaskResponse>;
  executeBatchPayment?: Maybe<Array<Maybe<PayrollPaymentBatch>>>;
  /** @deprecated No longer supported */
  expenseApprove?: Maybe<Expense>;
  expenseBulkRevoke?: Maybe<ExpenseUpdateBulkResponse>;
  /** @deprecated No longer supported */
  expenseCreate?: Maybe<Expense>;
  expenseDelete?: Maybe<Expense>;
  expenseItemMigrateToCategoryId?: Maybe<TaskResponse>;
  /** @deprecated No longer supported */
  expenseReject?: Maybe<Expense>;
  expenseRevoke?: Maybe<Expense>;
  expenseSaveAsDraft?: Maybe<Expense>;
  expenseSubmit?: Maybe<Expense>;
  expenseUpdate?: Maybe<Expense>;
  expenseUpdateBulkAsPaid?: Maybe<ExpenseUpdateBulkResponse>;
  expenseUpdateBulkAsToBePaid?: Maybe<ExpenseUpdateBulkResponse>;
  feeComponentDefinitionActivate?: Maybe<Array<Maybe<FeeComponentDefinition>>>;
  feeComponentDefinitionCreate?: Maybe<FeeComponentDefinition>;
  feeComponentDefinitionDeactivate?: Maybe<
    Array<Maybe<FeeComponentDefinition>>
  >;
  feeComponentDefinitionTierCreate?: Maybe<FeeComponentTierDefinition>;
  feeComponentDefinitionTierUpdate?: Maybe<FeeComponentTierDefinition>;
  feeComponentDefinitionUpdate?: Maybe<FeeComponentDefinition>;
  fetchLatestEmployeesFromPlatform?: Maybe<FetchEmployeesResult>;
  financialTransactionCollect?: Maybe<Scalars['Boolean']>;
  financialTransactionGenerate: Array<TransactionCommand>;
  fixEntitlementChangeRecords?: Maybe<TaskResponse>;
  /**  temporary mutation to fix non-prorated timeoff allocation  */
  fixTimeoffAllocations?: Maybe<TaskResponse>;
  forceUploadPayslip?: Maybe<Payslip>;
  forwardItem?: Maybe<ApprovalItem>;
  generateCompanyQuotation?: Maybe<CompanyQuotation>;
  generateCompensationGrantDocument?: Maybe<Grant>;
  generateDepositForContract?: Maybe<CompanyPayable>;
  /** @deprecated Use `verifyTriNetCredential` instead */
  getTrinetCredential?: Maybe<TrinetCredentialResult>;
  /** Throws exception if there's already a holiday on the given day/country. If you want to edit it, first use holidaysDelete() */
  holidaysAdd?: Maybe<TaskResponse>;
  /** WARNING: There's no holiday_aud table for you to easily recover the records. It's recommended to take an export first */
  holidaysDelete?: Maybe<TaskResponse>;
  importEmployees?: Maybe<TaskResponse>;
  initiateAutoDebit?: Maybe<TaskResponse>;
  linkContractsToPayrollCycleConfig?: Maybe<TaskResponse>;
  managerAssignDirectReports?: Maybe<Manager>;
  managerCreate?: Maybe<Manager>;
  managerDelete?: Maybe<Manager>;
  managerUpdate?: Maybe<Manager>;
  managerValidateDirectReports?: Maybe<DirectReportValidationResult>;
  markFailCompanyPayInMethod?: Maybe<CompanyPayInMethod>;
  memberBackFillBankIdentifier?: Maybe<TaskResponse>;
  memberCancelUpdateRequest?: Maybe<Member>;
  memberChangePrimaryEmail?: Maybe<Member>;
  memberCreate?: Maybe<Member>;
  /** @deprecated No longer supported */
  memberDelete?: Maybe<Member>;
  memberPayBackfillBillingCurrency?: Maybe<TaskResponse>;
  /**
   * For Finance Ops to initiate the payouts after the payins are confirmed received.<br>
   * Ideally FE should send ids of valid member-payables (i.e. the status must be PAY_IN_RECEIVED, the contract must have paymentAccountId etc...)<br>
   * BE will validate again and exclude invalid member-payables from the process.<br>
   *
   * ids: the member payable ids to be initiated
   */
  memberPayableBulkInitiatePayout?: Maybe<TaskResponse>;
  memberPayableCompanyInvoiceConfirmPayment?: Maybe<
    Array<Maybe<MemberPayable>>
  >;
  memberPayableCompanyInvoiceCreatePayIn?: Maybe<PayInInfo>;
  memberPayableCompanyInvoiceMarkAsReadyForPayouts?: Maybe<MemberPayableCompanyInvoice>;
  /**
   * To generate the invoices, triggered by customer
   * If an invoice is already created, it is skipped
   * The invoice must be raised for the same member payable invoice items
   * Case:
   * Company user may not complete the payment for the invoice and stop midway. Hence the invoice is created and kept in draft until revisited
   */
  memberPayableCompanyInvoiceSubmit?: Maybe<MemberPayableCompanyInvoice>;
  memberPayableDelete?: Maybe<MemberPayable>;
  memberPayableGeneration?: Maybe<TaskResponse>;
  memberPayableSubmit?: Maybe<MemberPayable>;
  memberPayableToggleAutoGeneration?: Maybe<TaskResponse>;
  memberPayableUpdate?: Maybe<MemberPayable>;
  memberPayableUpdateBulkAsApproved?: Maybe<Array<Maybe<MemberPayable>>>;
  memberPayableUpdateBulkAsPaid?: Maybe<MemberPayableUpdateBulkResponse>;
  memberPayableUpdateBulkAsRejected?: Maybe<Array<Maybe<MemberPayable>>>;
  memberRemoveProfilePicture?: Maybe<Member>;
  memberSendUpdateRequest?: Maybe<Member>;
  memberUpdate?: Maybe<Member>;
  memberUpdateBankDetails?: Maybe<Member>;
  memberUpdateBasicDetails?: Maybe<Member>;
  memberUpdateDocument?: Maybe<Member>;
  memberUpdateLegalData?: Maybe<Member>;
  memberUpdateVisaDocument?: Maybe<Member>;
  memberUpsertAddress?: Maybe<Member>;
  memberUpsertBasicInfo?: Maybe<Member>;
  memberUpsertContactInfo?: Maybe<Member>;
  /** @deprecated not required */
  memberUpsertCustomFields?: Maybe<Member>;
  memberUpsertEducation?: Maybe<Member>;
  memberUpsertEmergencyContact?: Maybe<Member>;
  memberUpsertIdentificationDetails?: Maybe<Member>;
  memberUpsertPersonalInfo?: Maybe<Member>;
  memberUpsertPreviousEmployer?: Maybe<Member>;
  memberUpsertProfilePicture?: Maybe<Member>;
  /** to update end_date/end_session based on back_to_work_date/back_to_work_session */
  migrateFromBackToWorkDateToEndDate?: Maybe<TaskResponse>;
  migrateOldPayslips?: Maybe<TaskResponse>;
  migrateSeatId?: Maybe<TaskResponse>;
  moveToNextPayslipUploadWorkflowStep?: Maybe<PayrollCycle>;
  moveToNextStatus?: Maybe<PayrollCycle>;
  msaAddendumConfirm?: Maybe<MsaAddendum>;
  msaAddendumDocumentGenerate?: Maybe<MsaAddendum>;
  msaAddendumDocumentRevoke?: Maybe<MsaAddendum>;
  msaAddendumDocumentSendForSignature?: Maybe<MsaAddendum>;
  msaAddendumDraftDelete?: Maybe<Scalars['ID']>;
  msaAddendumDraftEdit?: Maybe<MsaAddendum>;
  msaAddendumUploadCustom?: Maybe<MsaAddendum>;
  offboardingClientApproveLwd?: Maybe<Offboarding>;
  offboardingConfirmAssetsRecovery?: Maybe<Offboarding>;
  offboardingExperienceInitiatialise: OffboardingExperienceState;
  offboardingInitialize?: Maybe<Offboarding>;
  offboardingInputLwd?: Maybe<Offboarding>;
  offboardingNextState?: Maybe<Offboarding>;
  offboardingOpsApproveLwd?: Maybe<Offboarding>;
  ok?: Maybe<Scalars['Boolean']>;
  operationsUserAcceptInvite?: Maybe<OperationsUser>;
  operationsUserCreate?: Maybe<OperationsUser>;
  operationsUserDelete?: Maybe<TaskResponse>;
  operationsUserUpdate?: Maybe<OperationsUser>;
  operationsUserUpdateRoles?: Maybe<OperationsUser>;
  opsUpdatePayrollWorkflow: PayrollWorkflowResponse;
  optOutInsurance?: Maybe<ContractBenefit>;
  orderFormRegenerate?: Maybe<TaskResponse>;
  orgDirectoryAssignReportsTo?: Maybe<OrgDirectory>;
  orgDirectoryValidateReportsTo?: Maybe<ReportsToValidationResult>;
  /** Assign users to a role */
  orgRoleAssignManagers?: Maybe<OrgRole>;
  orgRoleCreate?: Maybe<OrgRole>;
  orgRoleDelete?: Maybe<OrgRole>;
  orgRoleUpdate?: Maybe<OrgRole>;
  partnerAcceptInvite?: Maybe<Partner>;
  partnerAddCountryCapability?: Maybe<Partner>;
  partnerComponentBulkUpload: BulkUploadResponse;
  partnerCreate?: Maybe<Partner>;
  partnerEORAssignContract?: Maybe<ContractEorPartner>;
  partnerInvite?: Maybe<Partner>;
  partnerMappingBackfillCSV?: Maybe<TaskResponse>;
  partnerPayrollAssignContract?: Maybe<ContractPayrollPartner>;
  /** @deprecated No longer supported */
  partnerPayrollCreate?: Maybe<PartnerPayroll>;
  partnerPayrollPayslipGenerateForPayrollCycle?: Maybe<TaskResponse>;
  partnerPayrollRegistrationCompleted?: Maybe<ContractPayrollPartner>;
  partnerPayrollSendForReview?: Maybe<PartnerPayroll>;
  partnerPayrollStartRegistration?: Maybe<ContractPayrollPartner>;
  /** @deprecated No longer supported */
  partnerPayrollUploadPayslip?: Maybe<TaskResponse>;
  partnerUpdatePayrollWorkflow: PayrollWorkflowResponse;
  partnerUserAcceptInvite?: Maybe<PartnerUser>;
  partnerUserChangeRole?: Maybe<PartnerUser>;
  partnerUserCreate?: Maybe<PartnerUser>;
  partnerUserDelete?: Maybe<TaskResponse>;
  partnerUserUpdateOperator?: Maybe<PartnerUser>;
  payInProcess?: Maybe<Scalars['Boolean']>;
  paySupplementBulkUpload?: Maybe<TaskResponse>;
  paySupplementCreate?: Maybe<PaySupplement>;
  paySupplementPaid?: Maybe<PaySupplement>;
  paySupplementReject?: Maybe<PaySupplement>;
  paySupplementRevoke?: Maybe<PaySupplement>;
  paySupplementUpdate?: Maybe<PaySupplement>;
  paySupplementUpdateBulkAsPaid?: Maybe<PaySupplementUpdateBulkResponse>;
  paySupplementUpdateBulkAsRevoked?: Maybe<PaySupplementUpdateBulkResponse>;
  paySupplementUpdateBulkAsToBePaid?: Maybe<PaySupplementUpdateBulkResponse>;
  paymentBundleCreate?: Maybe<PaymentBundle>;
  paymentBundleDelete?: Maybe<PaymentBundle>;
  paymentBundleMarkAsPaid?: Maybe<PaymentBundle>;
  paymentBundleMarkAsProcessing?: Maybe<PaymentBundle>;
  paymentBundleUpdate?: Maybe<PaymentBundle>;
  payrollBulkUploadConfirm?: Maybe<TaskResponse>;
  payrollBulkUploadCreate?: Maybe<UploadUrlResponse>;
  payrollBulkUploadTemplatesRefresh?: Maybe<TaskResponse>;
  payrollBulkUploadValidate?: Maybe<BulkUploadValidationResponse>;
  payrollCycleConfigBulkCreate?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleConfigCreate?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleConfigUpdate?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleCreate?: Maybe<PayrollCycle>;
  payrollCycleSendForReview?: Maybe<PayrollCycle>;
  payrollCycleStatusBulkUpdate?: Maybe<Array<Maybe<PayrollCycle>>>;
  payrollCycleUpdateBulkAsClosed?: Maybe<Array<Maybe<PayrollCycle>>>;
  payrollCycleUpdateBulkAsSuspended?: Maybe<Array<Maybe<PayrollCycle>>>;
  payrollInputAuditTrailsRemove?: Maybe<TaskResponse>;
  payrollInputFileDownloadAsync?: Maybe<InputFileDownload>;
  payrollInputFilePartnerBulkDownloadAsync?: Maybe<InputFileDownload>;
  payrollNoteForContract?: Maybe<Contract>;
  payrollOffCycleCreate?: Maybe<PayrollCycle>;
  /** 86cuedugx - For Ops to sync offline-signed salary revisions to the system. Note: only EMPLOYEE (EOR) & HR_MEMBER (PEO) are supported */
  performanceReviewBulkCreateAsApproved?: Maybe<TaskResponse>;
  placeholder?: Maybe<Scalars['Boolean']>;
  platformBambooHRAccountTokenUpdate?: Maybe<TaskResponse>;
  platformBatchedUpdate: Array<PlatformUpdateTaskResponse>;
  platformCompanyTokenUpdate?: Maybe<TaskResponse>;
  platformUpdate: PlatformUpdateTaskResponse;
  pricingPlanActivate: PricingPlan;
  pricingPlanAutofill?: Maybe<PricingPlan>;
  pricingPlanCloseSeats?: Maybe<PricingPlan>;
  pricingPlanCreate?: Maybe<PricingPlan>;
  pricingPlanDelete?: Maybe<PricingPlan>;
  pricingPlanExpand?: Maybe<PricingPlan>;
  pricingPlanMigrate: PricingPlanMigrateResponse;
  pricingPlanUpdate?: Maybe<PricingPlan>;
  providerKnitAuthToken?: Maybe<KnitAuthResponse>;
  publishPayslips?: Maybe<PublishPayslipsResponse>;
  /** Use for manually trigger only */
  reGeneratePayrollCycleInputFile?: Maybe<TaskResponse>;
  reInitiateActivation?: Maybe<TaskResponse>;
  recreatePayrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
  refreshActivePayrollSnapshots?: Maybe<TaskResponse>;
  refreshContractReferencesForPayrollCycle?: Maybe<TaskResponse>;
  refreshPaymentAccountRequirements?: Maybe<Scalars['Boolean']>;
  refreshPayrollSnapshot?: Maybe<TaskResponse>;
  refreshPayrollSnapshotByWatermark?: Maybe<TaskResponse>;
  /** @deprecated use updateDomainItems instead */
  refreshPayrollSnapshotDomainForce?: Maybe<TaskResponse>;
  refreshPayrollSnapshots?: Maybe<TaskResponse>;
  /** @deprecated Use contractAgreementRegenerate instead */
  regenerateAgreement?: Maybe<TaskResponse>;
  regeneratePayslipZip?: Maybe<TaskResponse>;
  registerDirectDebitMandate?: Maybe<CompanyPayInMethod>;
  rejectItem?: Maybe<ApprovalItem>;
  relinkContractToAnotherPayrollCycleConfig?: Maybe<TaskResponse>;
  salaryCalculationEmail?: Maybe<TaskResponse>;
  salaryCalculatorRequestEmail?: Maybe<TaskResponse>;
  saveAndDownloadCompanyQuotation?: Maybe<CompanyQuotation>;
  saveIntegrationEntityMappingStatus?: Maybe<TaskResponse>;
  saveIntegrationFieldsMapping?: Maybe<TaskResponse>;
  seatAssign?: Maybe<PricingPlan>;
  seatRelease?: Maybe<PricingPlan>;
  sendEmailToOnboardingSpecialist?: Maybe<SendEmailToOnboardingSpecialistResponse>;
  sendImportedMemberInvites?: Maybe<TaskResponse>;
  sendInvoiceReminderNotificationToCustomer?: Maybe<TaskResponse>;
  sendInvoiceReminderNotificationToMember?: Maybe<TaskResponse>;
  sendInvoiceToContactEmail?: Maybe<TaskResponse>;
  sendKYBRequirementsEmail?: Maybe<TaskResponse>;
  sendSyncedMemberInvites?: Maybe<TaskResponse>;
  sendToSignatoryPerformanceReview?: Maybe<PerformanceReview>;
  sendToSignatorySalaryReview?: Maybe<SalaryReview>;
  setDisabledPayrollCycleConfigToContract?: Maybe<TaskResponse>;
  startIncomingSync?: Maybe<TaskResponse>;
  startNewKYBAssessment?: Maybe<KybAssessment>;
  syncEORManually?: Maybe<SyncEorManuallyResult>;
  syncEmployees?: Maybe<TaskResponse>;
  syncPayrollCycle?: Maybe<TaskResponse>;
  syncTurnOff?: Maybe<TaskResponse>;
  /** @deprecated The name is misleading and parameters aren't according to best practices */
  syncXeroInvoicesToCore?: Maybe<TaskResponse>;
  templateDelete?: Maybe<Template>;
  templateUpsert?: Maybe<Template>;
  terminatePayrollWorkflow: PayrollWorkflowResponse;
  test?: Maybe<Scalars['Boolean']>;
  timeOffBulkRevoke?: Maybe<TaskResponse>;
  /**
   * # i.e. submit (send for approval). The timeoff can be a new one or existing DRAFT one, based on `input.id`. Refer to input TimeOffCreateInput.
   * # Note: FE is sending `contractId: ID` even in "member" exp (personal view) which is a security issue: MemberA can raise a timeoff for memberB by mistake or intention!.
   * # TODO: 1. FE: not to pass `contractId` if in member exp. 2. BE: will ignore `contractId` if in member exp, and query the contract from `currentUser` instead.
   * # In company exp (team view): `contractId` is still used as usual, to indicate whom the companyUser is raising on behalf of.
   */
  timeOffCreate?: Maybe<TimeOff>;
  timeOffDelete?: Maybe<TaskResponse>;
  timeOffPolicyAssignUsers?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyCreate?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyDelete?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyUpdate?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyValidateUsers?: Maybe<TimeOffPolicyUsersValidationResult>;
  /** to set the status back to DRAFT. */
  timeOffRevoke?: Maybe<TimeOff>;
  /** to create a (new) timeoff with status=DRAFT. If you want to update an existing DRAFT timeoff, use `timeOffUpdate`, not this */
  timeOffSaveAsDraft?: Maybe<TimeOff>;
  timeOffTypeCreate?: Maybe<TimeOffTypeInfo>;
  timeOffTypeDelete?: Maybe<TimeOffTypeInfo>;
  timeOffTypeUpdate?: Maybe<TimeOffTypeInfo>;
  /** Update. Owner who created it. Does not change status */
  timeOffUpdate?: Maybe<TimeOff>;
  timeOffUpdateCompanyDefinitions?: Maybe<TaskResponse>;
  timeOffUpdateCountryDefinitions?: Maybe<TaskResponse>;
  timesheetChangeStatus?: Maybe<Timesheet>;
  /** @deprecated Use timesheetUploadBulk instead */
  timesheetCreateBulk?: Maybe<TaskResponse>;
  timesheetCreateOrUpdate?: Maybe<Timesheet>;
  timesheetEntriesApprove?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesCreateOrUpdate?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesDelete?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesReject?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesRevoke?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesSubmit?: Maybe<Array<Maybe<TimesheetEntry>>>;
  /** @deprecated Use timesheetEntriesCreateOrUpdate instead */
  timesheetEntryCreateOrUpdate?: Maybe<TimesheetEntry>;
  timesheetGenerateIfMissing?: Maybe<TaskResponse>;
  timesheetUploadBulk?: Maybe<TimesheetUploadResult>;
  toggleAutoDebit?: Maybe<CompanyPayInPreferenceAutoDebit>;
  triggerCarryForwardExpiration?: Maybe<TaskResponse>;
  triggerChangeRequestDelayedEmailProcess?: Maybe<TaskResponse>;
  triggerCompleteWorkEmailAndEmployeeIdReminderEmailProcess?: Maybe<TaskResponse>;
  triggerContractBenefitSetEndedStatus?: Maybe<TaskResponse>;
  triggerContractWorkEmailReminderOnStartDayEmailProcess?: Maybe<TaskResponse>;
  triggerEmployeeTimesheetEntryAutoApproval?: Maybe<TaskResponse>;
  triggerEmployeeTimesheetsAutoSubmission?: Maybe<TaskResponse>;
  triggerEmployeeTimesheetsGeneration?: Maybe<TaskResponse>;
  triggerEndContractForFreelancerAfterLWD?: Maybe<TaskResponse>;
  triggerExpenseReminderEmailProcess?: Maybe<TaskResponse>;
  triggerLegacyTimeoffReallocation?: Maybe<TaskResponse>;
  triggerMemberExpenseSubmissionReminders?: Maybe<TaskResponse>;
  triggerMemberTimeCardDailyReminders?: Maybe<TaskResponse>;
  triggerMemberTimesheetApprovedHoursReminders?: Maybe<TaskResponse>;
  triggerOffboardingReminderEmailToOps?: Maybe<TaskResponse>;
  triggerOnboardingReminderEmailProcess?: Maybe<TaskResponse>;
  triggerPaySupplementAutoCreateProcess?: Maybe<TaskResponse>;
  triggerPaySupplementMonthlyReminderEmailProcess?: Maybe<TaskResponse>;
  triggerPaySupplementReminderEmailProcess?: Maybe<TaskResponse>;
  triggerProbationEndReminderEmailProcess?: Maybe<TaskResponse>;
  triggerReminderEmailForSalaryRevisionSignature?: Maybe<TaskResponse>;
  triggerReviewPayrollInputReminderEmail?: Maybe<TaskResponse>;
  triggerReviewPayrollReportReminderEmail?: Maybe<TaskResponse>;
  triggerTimeoffAllocation?: Maybe<TaskResponse>;
  triggerTimeoffCarryForward?: Maybe<TaskResponse>;
  triggerTimeoffUpdateProcess?: Maybe<TaskResponse>;
  triggerTimesheetApproveSubmittedHoursReminderEmailProcess?: Maybe<TaskResponse>;
  triggerTimesheetApproveSubmittedHoursReminderEmailsToAdmins?: Maybe<TaskResponse>;
  triggerTimesheetsAutoSubmissionEmailProcess?: Maybe<TaskResponse>;
  triggerUnpaidPaymentBundleReminderEmailProcess?: Maybe<TaskResponse>;
  triggerUpcomingMonthlyInvoiceEmailProcess?: Maybe<TaskResponse>;
  triggerUpdateExpiredBanners?: Maybe<TaskResponse>;
  triggerUpdateScheduledBanners?: Maybe<TaskResponse>;
  unlinkContractFromPayrollCycleConfig?: Maybe<TaskResponse>;
  updateAndFixTimeOffs?: Maybe<TaskResponse>;
  updateCompanyChargebackPeriod?: Maybe<CompanyChargebackResult>;
  updateCompanyPayInMethodChargeBack?: Maybe<CompanyPayInMethod>;
  updateCompanyPricing?: Maybe<Pricing>;
  updateCompensationPaymentInfo?: Maybe<TaskResponse>;
  updateComplianceAssessmentData?: Maybe<ComplianceRiskAssessment>;
  updateComplianceAssessmentDocuments?: Maybe<ComplianceRiskAssessment>;
  updateContractBenefitChangeRequest?: Maybe<
    Array<Maybe<ContractBenefitChangeRequestResponse>>
  >;
  updateContractPaymentDetails?: Maybe<ContractPaymentAccount>;
  updateDemoCycles: Array<PayrollCycle>;
  updateDomainItems?: Maybe<TaskResponse>;
  updateFinancialAssessmentData?: Maybe<FinancialRiskAssessment>;
  updateFinancialAssessmentDocuments?: Maybe<FinancialRiskAssessment>;
  updateInsurance?: Maybe<ContractBenefit>;
  updateInsuranceStatus?: Maybe<InsuranceStatusResponse>;
  updateInvoiceAndBundleOnExternalInvoicePaid?: Maybe<TaskResponse>;
  updateOrCreateCompanyBinding?: Maybe<CompanyBinding>;
  updatePayrollParserConfig?: Maybe<PayrollParserConfig>;
  updatePayrollPartnerMemberIdToContractId?: Maybe<TaskResponse>;
  updatePayrollPaymentBatchStatus?: Maybe<Array<Maybe<PayrollPaymentBatch>>>;
  updatePayrollPaymentStatus?: Maybe<Array<Maybe<PayrollPayment>>>;
  updatePayrollReconciliationResultStatus: Scalars['ID'];
  updatePayrollReportGeneratorConfig?: Maybe<ReportGeneratorConfig>;
  updatePayrollWorkflow: PayrollWorkflowResponse;
  /** @deprecated No longer supported */
  updateTimeoffDefinition?: Maybe<TaskResponse>;
  uploadAssessmentDocument?: Maybe<Scalars['ID']>;
  uploadContractIdMappingFile?: Maybe<Array<Maybe<ContractIdMappingUpload>>>;
  /** @deprecated No longer supported */
  uploadPayrollFile?: Maybe<PayrollReportUpload>;
  uploadPayrollOutputReport?: Maybe<PayrollReportUpload>;
  uploadSignedAgreement?: Maybe<CompanyPayInMethod>;
  upsertCompanyPayInMethodBankDetails?: Maybe<CompanyPayInMethod>;
  upsertContractBenefitBanners?: Maybe<Array<Maybe<ContractBenefitBanner>>>;
  upsertCountryContractClauses?: Maybe<TaskResponse>;
  upsertCountryDocument?: Maybe<FileLink>;
  upsertCountryLegalDataDefinitions?: Maybe<TaskResponse>;
  upsertPerformanceReview?: Maybe<PerformanceReview>;
  upsertRecruiter?: Maybe<Recruiter>;
  upsertSSOPreferenceForCompany?: Maybe<SsoPreference>;
  upsertSalaryReview?: Maybe<SalaryReview>;
  validatePayslips?: Maybe<TaskResponse>;
  verifyTriNetCredential?: Maybe<TrinetCredentialResult>;
};

export type Mutation_PaymentInstructionFileGenerateArgs = {
  data: PaymentInstructionFileGenerateInput;
};

export type Mutation_PaymentInstructionFileGenerateV2Args = {
  data: FileGenerationRequestData;
};

export type Mutation_PennyTestInitiateArgs = {
  data: PennyTestInitiateRequestData;
};

export type Mutation_ActivateReviewsArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
};

export type Mutation_ApproveItemArgs = {
  category: ApprovalCategory;
  comment?: InputMaybe<Scalars['String']>;
  itemId: Scalars['ID'];
};

export type Mutation_AssignOperationUsersArgs = {
  accountManagerUserId?: InputMaybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  csmUserId?: InputMaybe<Scalars['ID']>;
  salesUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_AssignPayrollSnapshotDomainArgs = {
  value: PayrollSnapshotAssignDomainInput;
};

export type Mutation_AsyncPayrollInputFileDownloadArgs = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_AsyncPayrollInputFilePartnerBulkDownloadArgs = {
  filter?: InputMaybe<PayrollCycleFilter>;
};

export type Mutation_AutoDebitManualTriggerArgs = {
  invoiceDueDate: Scalars['Date'];
};

export type Mutation_BackFillKybForCompaniesArgs = {
  companyIds: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_BackfillEntitlementDefinitionIdsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_BackfillHrisManagersFromCompanyUsersArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_BackfillRulesForExistingCompanyDefinitionsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  forAll?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_BackfillTimeoffAllocationRecordsArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_BackfillTimeoffApprovedOnArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_BackfillTimeoffCarryForwardRecordsArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_BenefitActivityAddOperationArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitActivityAddOperationWrapperArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitActivityUpdateOperationArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitActivityUpdateOperationWithIdArgs = {
  file: Scalars['Upload'];
  id: Scalars['String'];
};

export type Mutation_BenefitActivityUpdateOperationsArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
};

export type Mutation_BenefitCreateArgs = {
  input: Array<BenefitInput>;
};

export type Mutation_BenefitDeleteArgs = {
  id: Scalars['String'];
};

export type Mutation_BenefitPackageMappingCsvArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitPartnerCountryDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_BenefitPartnerMappingBackfillCsvArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitPartnerUpdateDocumentsArgs = {
  data: Array<BenefitDocumentInput>;
  id: Scalars['ID'];
};

export type Mutation_BenefitSetTierPackageArgs = {
  id: Scalars['String'];
  tier: BenefitTierInput;
};

export type Mutation_BenefitUpdateArgs = {
  id: Scalars['String'];
  input: BenefitInput;
};

export type Mutation_BenefitUpdateDocumentArgs = {
  documents: Array<Scalars['Upload']>;
  id: Scalars['String'];
  key: BenefitDocumentType;
};

export type Mutation_BulkCreatePayrollCyclesArgs = {
  value: PayrollCycleBulkCreateInput;
};

export type Mutation_BulkOnboardingTriggerArgs = {
  employeeDataFile: Scalars['Upload'];
  options: BulkOnboardingOptions;
};

export type Mutation_BulkOnboardingValidateArgs = {
  employeeDataFile: Scalars['Upload'];
  options: BulkOnboardingOptions;
};

export type Mutation_BulkPayrollCycleConfigUpdateArgs = {
  input: BulkPayrollConfigUpdateInput;
};

export type Mutation_BulkUpdateCompanyInvoiceStatusArgs = {
  companyInvoiceIds: Array<InputMaybe<Scalars['ID']>>;
  status: MemberPayableCompanyInvoiceStatus;
};

export type Mutation_BulkUpdatePayrollCyclesArgs = {
  value: PayrollCycleUpdateInput;
};

export type Mutation_BulkUploadMemberPayableExtraFeesArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BulkUploadMemberPayableFromExternalEntityArgs = {
  file: Scalars['Upload'];
};

export type Mutation_CategoryBulkUploadArgs = {
  input: Scalars['Upload'];
};

export type Mutation_ChangeSyncStateArgs = {
  integrationId: Scalars['ID'];
  on: Scalars['Boolean'];
  syncType: SyncType;
};

export type Mutation_ChangeTimeOffDateArgs = {
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  ignoresValidations?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
};

export type Mutation_ClearGpSyncFailedEventsArgs = {
  input: ClearGpSyncFailedEventsInput;
};

export type Mutation_ClearGpSyncFailedEventsV2Args = {
  companyId: Scalars['ID'];
  platformID: Scalars['ID'];
};

export type Mutation_CompanyAcceptInviteArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_CompanyBackfillEmployeePricingArgs = {
  employeePricing: Scalars['Upload'];
};

export type Mutation_CompanyBackfillPricingArgs = {
  employeePricing: Scalars['Upload'];
  pricing: Scalars['Upload'];
};

export type Mutation_CompanyBindingCreateArgs = {
  input: CompanyBindingInput;
};

export type Mutation_CompanyCreateArgs = {
  company?: InputMaybe<CreateCompanyInput>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_CompanyGenerateMsaArgs = {
  companyId: Scalars['ID'];
};

export type Mutation_CompanyGroupAddCompanyArgs = {
  input?: InputMaybe<CompanyGroupAddCompanyInput>;
};

export type Mutation_CompanyGroupAddUsersArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Array<CompanyGroupAddUserInput>>;
};

export type Mutation_CompanyGroupCompanyAddArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveCompanyInput>;
};

export type Mutation_CompanyGroupCompanyRemoveArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveCompanyInput>;
};

export type Mutation_CompanyGroupCreateArgs = {
  input: CompanyGroupCreateInput;
};

export type Mutation_CompanyGroupUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: CompanyGroupUpdateInput;
};

export type Mutation_CompanyGroupUserAddArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveUserInput>;
};

export type Mutation_CompanyGroupUserRemoveArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveUserInput>;
};

export type Mutation_CompanyInactivateArgs = {
  companyId: Scalars['ID'];
  inactivateReason?: InputMaybe<CompanyInactiveReason>;
};

export type Mutation_CompanyInviteArgs = {
  companyId: Scalars['ID'];
  companyUserIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Mutation_CompanyLegalEntityActivateArgs = {
  id: Scalars['ID'];
};

export type Mutation_CompanyLegalEntityCopyCountryHolidaysArgs = {
  id: Scalars['ID'];
  year: Scalars['Int'];
};

export type Mutation_CompanyLegalEntityCreateArgs = {
  input: CreateCompanyLegalEntityInput;
};

export type Mutation_CompanyLegalEntityUpdateArgs = {
  input: UpdateCompanyLegalEntityInput;
};

export type Mutation_CompanyMsaSendForSignatureArgs = {
  companyId: Scalars['ID'];
};

export type Mutation_CompanyMsaUploadArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type Mutation_CompanyOfferingFeeComponentDiscountUpdateArgs = {
  companyOfferingFeeComponentId: Scalars['ID'];
  input: DiscountInput;
};

export type Mutation_CompanyOfferingFeeComponentTierCreateArgs = {
  companyOfferingPricingId: Scalars['ID'];
  input: CompanyOfferingFeeComponentTierInput;
};

export type Mutation_CompanyOfferingFeeComponentTierUpdateArgs = {
  companyOfferingPricingId: Scalars['ID'];
  input: CompanyOfferingFeeComponentTierInput;
};

export type Mutation_CompanyOfferingFeeComponentUpdateArgs = {
  companyOfferingFeeComponentId: Scalars['ID'];
  input: CompanyOfferingFeeComponentInput;
};

export type Mutation_CompanyOfferingPricingActivateArgs = {
  companyOfferingPricingIds: Array<Scalars['ID']>;
};

export type Mutation_CompanyOfferingPricingCreateArgs = {
  input: CompanyOfferingPricingInput;
};

export type Mutation_CompanyOfferingPricingDeactivateArgs = {
  companyOfferingPricingIds: Array<Scalars['ID']>;
};

export type Mutation_CompanyOfferingPricingDiscountUpdateArgs = {
  companyOfferingPricingId: Scalars['ID'];
  input: DiscountInput;
};

export type Mutation_CompanyOfferingsAddArgs = {
  input?: InputMaybe<CompanyOfferingsAddInput>;
};

export type Mutation_CompanyOfferingsUpdateArgs = {
  input?: InputMaybe<CompanyOfferingsUpdateInput>;
};

export type Mutation_CompanyPayableGenerateArgs = {
  input: CompanyPayableInput;
};

export type Mutation_CompanyPayableGenerateForContractArgs = {
  contractID: Scalars['ID'];
  types: Array<PayableItemType>;
};

export type Mutation_CompanyPayableSyncFromExternalSystemArgs = {
  input: CompanyPayableSyncInput;
};

export type Mutation_CompanyPayableUploadInvoiceSourceReportArgs = {
  input: InvoiceSourceReportUploadInput;
};

export type Mutation_CompanyPayrollConsolidatedReportDownloadAsyncArgs = {
  request: CompanyPayrollConsolidatedReportDownloadRequest;
};

export type Mutation_CompanyPayrollCycleRefreshArgs = {
  value?: InputMaybe<RefreshCompanyPayrollCycleInput>;
};

export type Mutation_CompanyPayrollFileDownloadAsyncArgs = {
  request: CompanyPayrollFileDownloadRequest;
};

export type Mutation_CompanyPayrollInputConfirmArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Mutation_CompanyPayrollOutputConfirmArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Mutation_CompanyPayrollPayslipPublishArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Mutation_CompanyPayrollReportsRegenerateArgs = {
  payrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_CompanyRemoveSignatoryArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyRemoveUserArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyRemoveUsersArgs = {
  companyUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_CompanySlabFxRateUploadArgs = {
  companySlabFXRates: Scalars['Upload'];
};

export type Mutation_CompanyUpdateArgs = {
  company?: InputMaybe<CompanyUpdateInput>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_CompanyUpdateBasicDetailsArgs = {
  companyLogo?: InputMaybe<Scalars['Upload']>;
  input: CompanyUpdateBasicDetailsInput;
};

export type Mutation_CompanyUpdateContactDetailsArgs = {
  input: CompanyUpdateContactDetailsInput;
};

export type Mutation_CompanyUpdateUserArgs = {
  user?: InputMaybe<UpdateUserInput>;
};

export type Mutation_CompanyUpdateUsersArgs = {
  users?: InputMaybe<Array<InputMaybe<UpdateUserInput>>>;
};

export type Mutation_CompanyUserAcceptInviteArgs = {
  companyUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_CompanyUserAddArgs = {
  user?: InputMaybe<AddCompanyUserInput>;
};

export type Mutation_CompanyUserAddApproverInfoArgs = {
  input: AddApproverInfoInput;
};

export type Mutation_CompanyUserAssignCapabilitiesArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CompanyUserCapability>>>;
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserAssignRoleArgs = {
  input?: InputMaybe<CompanyUserAssignRoleInput>;
};

export type Mutation_CompanyUserAssignRoleAndAttributesArgs = {
  input?: InputMaybe<CompanyUserAssignRoleAndAttributesInput>;
};

export type Mutation_CompanyUserEditRoleAndAttributesArgs = {
  input?: InputMaybe<CompanyUserEditRoleAndAttributesInput>;
};

export type Mutation_CompanyUserResendApproverInviteArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserRevokeApproverRoleArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserRevokeCapabilityArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CompanyUserCapability>>>;
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserRevokeRoleArgs = {
  companyUserId: Scalars['ID'];
  role?: InputMaybe<CompanyUserRole>;
};

export type Mutation_CompanyUserUpdateApproverInfoArgs = {
  input: UpdateApproverInfoInput;
};

export type Mutation_CompanyUsersAddArgs = {
  users?: InputMaybe<Array<InputMaybe<AddCompanyUserInput>>>;
};

export type Mutation_CompensationRecordUpdateArgs = {
  input: CompensationRecordUpdateInput;
};

export type Mutation_CompleteComplianceAssessmentArgs = {
  id: Scalars['ID'];
  riskScore: AssessmentRiskScore;
};

export type Mutation_CompleteFinancialAssessmentArgs = {
  id: Scalars['ID'];
  riskScore: AssessmentRiskScore;
};

export type Mutation_ConfirmPayrollInputArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_ConfirmPayslipsArgs = {
  value: PayslipConfirmInput;
};

export type Mutation_ContractActivateArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractAddendumGenerateArgs = {
  addendumId: Scalars['ID'];
};

export type Mutation_ContractAddendumSendForSignatureArgs = {
  addendumId: Scalars['ID'];
};

export type Mutation_ContractAgreementCustomUploadArgs = {
  contractId: Scalars['ID'];
  file?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_ContractAgreementRegenerateArgs = {
  input?: InputMaybe<RegenerateAgreementInput>;
};

export type Mutation_ContractAgreementReplaceArgs = {
  contract: Scalars['Upload'];
  contractId: Scalars['ID'];
};

export type Mutation_ContractAgreementReplaceWetInkArgs = {
  contract: Scalars['Upload'];
  contractId: Scalars['ID'];
};

export type Mutation_ContractAssignOperationsUserArgs = {
  contractId: Scalars['ID'];
  operationsUserId: Scalars['ID'];
};

export type Mutation_ContractChangeCountryArgs = {
  id: Scalars['ID'];
  input: ContractUpdateCountryInput;
};

export type Mutation_ContractChangeRequestStatusUpdateArgs = {
  changeRequestId: Scalars['ID'];
  status: ChangeRequestStatus;
};

export type Mutation_ContractChangeRequestUpdateArgs = {
  input?: InputMaybe<ContractChangeRequestUpdateInput>;
};

export type Mutation_ContractCompleteEmployeeSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCompleteEmployerSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCompleteVerificationArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCompleteVisaEligibilityReviewArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmContractPrepareArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmMemberDetailsArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmVisaApplicationFilingArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmVisaApprovedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmVisaInvoicePaidArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmWetInkContractPrepareArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCreateArgs = {
  input: ContractCreateInput;
};

export type Mutation_ContractCreateBenefitChangeRequestsArgs = {
  input: ContractBenefitChangeRequestInput;
};

export type Mutation_ContractCreateBulkArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  contractType?: InputMaybe<ContractType>;
  file: Scalars['Upload'];
  legalEntityId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_ContractCreateBulkPaymentAccountArgs = {
  file: Scalars['Upload'];
};

export type Mutation_ContractDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractDeleteBenefitDependentDocumentsArgs = {
  contractBenefitDependentId: Scalars['ID'];
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  key?: InputMaybe<ContractBenefitDependentDocumentType>;
};

export type Mutation_ContractDeleteCompensationGrantArgs = {
  contractId: Scalars['ID'];
  grantId: Scalars['ID'];
};

export type Mutation_ContractDeleteProbationBasePayArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_ContractDocumentRequestChangesArgs = {
  id: Scalars['ID'];
  request?: InputMaybe<Scalars['String']>;
};

export type Mutation_ContractDropArgs = {
  input: ContractDropInput;
};

export type Mutation_ContractEndArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['ID'];
  lastWorkingDay?: InputMaybe<Scalars['Date']>;
};

export type Mutation_ContractInviteMemberArgs = {
  id: Scalars['ID'];
  offerLetter?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractInviteMemberBulkArgs = {
  contractIds: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_ContractInviteMemberResendArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractLinkTemplateArgs = {
  contractId: Scalars['ID'];
  templateReference: Scalars['String'];
};

export type Mutation_ContractMemberBenefitCompletedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractMemberCompletedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractMemberStartedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractOffboardArgs = {
  id: Scalars['ID'];
  lastWorkingDay: Scalars['Date'];
  offboardingNote?: InputMaybe<Scalars['String']>;
};

export type Mutation_ContractOffboardingCancelArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractOffboardingCancelV2Args = {
  input: ContractOffboardingCancelInput;
};

export type Mutation_ContractOffboardingCompleteArgs = {
  input: ContractOffboardingCompleteInput;
};

export type Mutation_ContractOffboardingDidNotJoinInitialiseArgs = {
  input: ContractOffboardingDidNotJoinInitialiseInput;
};

export type Mutation_ContractOffboardingRescheduleArgs = {
  input: ContractOffboardingRescheduleInput;
};

export type Mutation_ContractOffboardingResignationInitialiseArgs = {
  input: ContractOffboardingResignationInitialiseInput;
};

export type Mutation_ContractOffboardingTerminationInitialiseArgs = {
  input: ContractOffboardingTerminationInitialiseInput;
};

export type Mutation_ContractOffboardingVerifyArgs = {
  input: ContractOffboardingVerifyInput;
};

export type Mutation_ContractOfflineSignatureProcessArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractOnboardingUpdateArgs = {
  contractId: Scalars['ID'];
  input: ContractOnboardingInput;
};

export type Mutation_ContractOrderFormSendForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractRequestChangesFromMemberArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Mutation_ContractReviseArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<ContractReviseInput>;
};

export type Mutation_ContractRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendAgreementToMemberForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendOfferLetterArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendReminderForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSetTestArgs = {
  id: Scalars['ID'];
  isClientTest?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractStartVerificationArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractTriggerCompleteOnboardingReminderEmailArgs = {
  bypassStartOn?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type Mutation_ContractTriggerJoingDayComplianceEmailArgs = {
  id: Scalars['ID'];
  overrideStartOn?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractTriggerJoingDayEmailArgs = {
  id: Scalars['ID'];
  isBatch?: InputMaybe<Scalars['Boolean']>;
  overrideStartOn?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractTriggerPayrollFormsReminderEmailArgs = {
  input?: InputMaybe<ContractTriggerPayrollFormsReminderEmailInput>;
};

export type Mutation_ContractUnassignOperationsUserArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_ContractUnblockArgs = {
  inputs: Array<ContractUnblockInput>;
};

export type Mutation_ContractUpdateBenefitDependentBasicDetailsArgs = {
  contractBenefitId: Scalars['ID'];
  input: Array<InputMaybe<ContractUpdateBenefitDependentInput>>;
};

export type Mutation_ContractUpdateBenefitDependentDocumentsArgs = {
  contractBenefitDependentId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  key?: InputMaybe<ContractBenefitDependentDocumentType>;
};

export type Mutation_ContractUpdateBenefitDependentPlanArgs = {
  contractBenefitId: Scalars['ID'];
  input: Array<InputMaybe<ContractUpdateBenefitDependentInput>>;
};

export type Mutation_ContractUpdateBenefitsArgs = {
  id: Scalars['ID'];
  input: ContractUpdateBenefitsInput;
};

export type Mutation_ContractUpdateCompensationArgs = {
  id: Scalars['ID'];
  input: ContractUpdateCompensationInput;
};

export type Mutation_ContractUpdateComplianceArgs = {
  contractId: Scalars['ID'];
  input: ContractUpdateComplianceInput;
};

export type Mutation_ContractUpdateDeductionsArgs = {
  id: Scalars['ID'];
  input: Array<DeductionInput>;
};

export type Mutation_ContractUpdateEmploymentArgs = {
  id: Scalars['ID'];
  input: ContractUpdateEmploymentInput;
};

export type Mutation_ContractUpdateLegalEntityArgs = {
  id: Scalars['ID'];
  legalEntityId: Scalars['ID'];
};

export type Mutation_ContractUpdatePayrollStartDateBulkArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  startDate: Scalars['Date'];
};

export type Mutation_ContractUpdateTimeOffEntitlementsArgs = {
  id: Scalars['ID'];
  input: Array<InputMaybe<ContractUpdateTimeOffEntitlementsInput>>;
};

export type Mutation_ContractWorkEmailUpdateArgs = {
  email: EmailAddressInput;
  id: Scalars['ID'];
};

export type Mutation_ContractWorkShiftBulkUpdateArgs = {
  inputs: Array<ContractBulkUpdateWorkShiftInput>;
};

export type Mutation_CountryCompensationDefinitionDeleteArgs = {
  compensationId: Scalars['ID'];
};

export type Mutation_CountryCompensationDefinitionUpsertArgs = {
  input: CountryCompensationDefinitionInput;
};

export type Mutation_CountryComplianceParamTypeUpdateArgs = {
  input?: InputMaybe<ComplianceParamTypeInput>;
};

export type Mutation_CountryComplianceParameterUpdateArgs = {
  input?: InputMaybe<CountryComplianceParameterInput>;
};

export type Mutation_CountryLegalDataDeleteArgs = {
  legalDataId: Scalars['ID'];
};

export type Mutation_CountryLegalDataUpsertArgs = {
  input: CountryLegalDataInput;
};

export type Mutation_CountryUpdateLegalDocumentRequirementArgs = {
  countryCode?: InputMaybe<CountryCode>;
  requirementInput?: InputMaybe<LegalDocumentRequirementInput>;
};

export type Mutation_CountryUpdateMemberDataDateArgs = {
  dateFieldInputs: Array<InputMaybe<DateFieldInput>>;
  memberId: Scalars['ID'];
};

export type Mutation_CountryUpdateMemberDataTextArgs = {
  memberId: Scalars['ID'];
  textFieldInputs: Array<InputMaybe<TextFieldInput>>;
};

export type Mutation_CountryUpdateOnboardingFlowEnablementArgs = {
  input: UpdateCountryOnboardingEnablementInput;
};

export type Mutation_CreateDemoCyclesArgs = {
  value: CreateDemoCyclesInput;
};

export type Mutation_CreateInsuranceCoverageGroupArgs = {
  input: Array<InputMaybe<InsuranceCoverageInput>>;
};

export type Mutation_CreateInsuranceRelationArgs = {
  input: InsuranceRelationInput;
};

export type Mutation_CreateInsuranceTierArgs = {
  input: InsuranceTierInput;
};

export type Mutation_CreatePayrollContractExceptionArgs = {
  value: PayrollContractExceptionInput;
};

export type Mutation_CreatePayrollPaymentBatchArgs = {
  payrollPaymentBatch: PayrollPaymentBatchInput;
};

export type Mutation_CreatePayrollWorkflowArgs = {
  value: CreatePayrollWorkflowRequest;
};

export type Mutation_CurrencyUploadExchangeRatesArgs = {
  effectiveDate?: InputMaybe<Scalars['Date']>;
  file: Scalars['Upload'];
};

export type Mutation_CustomPayrollReportConfigsCreateArgs = {
  values: Array<CustomPayrollReportConfigCreateInput>;
};

export type Mutation_CustomPayrollReportConfigsUpdateArgs = {
  values: Array<CustomPayrollReportConfigUpdateInput>;
};

export type Mutation_CustomPayrollReportCreateArgs = {
  values: Array<CustomPayrollReportCreateInput>;
};

export type Mutation_CustomPayrollReportFileUpdateArgs = {
  input: CustomPayrollReportFileUpdateInput;
};

export type Mutation_CustomerIntegrationDisconnectArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Mutation_CustomerUpdatePayrollWorkflowArgs = {
  value: CustomerPayrollWorkflowRequest;
};

export type Mutation_DeleteAssessmentDocumentArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeleteCompanyPayInMethodArgs = {
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_DeletePayrollContractExceptionArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeletePayrollPaymentBatchArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeletePerformanceReviewArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeleteRecruiterArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeleteSalaryReviewArgs = {
  id: Scalars['ID'];
};

export type Mutation_DepartmentAssignEmployeesArgs = {
  input: DepartmentAssignEmployeesInput;
};

export type Mutation_DepartmentCreateArgs = {
  input: DepartmentCreateInput;
};

export type Mutation_DepartmentDeleteArgs = {
  input: DepartmentDeleteInput;
};

export type Mutation_DepartmentUpdateArgs = {
  input: DepartmentUpdateInput;
};

export type Mutation_DismissSyncResultArgs = {
  syncId: Scalars['String'];
};

export type Mutation_DisputeCreateArgs = {
  input: CreateDisputeInput;
};

export type Mutation_DisputeResolveArgs = {
  input: ResolveDisputeInput;
};

export type Mutation_DownloadCompanyQuotationArgs = {
  quotationId: Scalars['ID'];
};

export type Mutation_EmailTemplateUpsertArgs = {
  data: EmailTemplateUpsertInput;
};

export type Mutation_EnableCompanyPayInMethodArgs = {
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_EquipmentProcurementCreateArgs = {
  input: EquipmentProcurementInput;
};

export type Mutation_EquipmentProductsUpdateArgs = {
  file: Scalars['Upload'];
};

export type Mutation_EquipmentQuotationCreateArgs = {
  input: EquipmentQuotationInput;
};

export type Mutation_EquipmentsUpdateArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_ExecuteBatchPaymentArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_ExpenseApproveArgs = {
  id: Scalars['ID'];
};

export type Mutation_ExpenseBulkRevokeArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_ExpenseCreateArgs = {
  input: ExpenseCreateInput;
};

export type Mutation_ExpenseDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_ExpenseRejectArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_ExpenseRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_ExpenseSaveAsDraftArgs = {
  input: ExpenseSaveAsDraftInput;
};

export type Mutation_ExpenseSubmitArgs = {
  input: ExpenseSubmitInput;
};

export type Mutation_ExpenseUpdateArgs = {
  input: ExpenseUpdateInput;
};

export type Mutation_ExpenseUpdateBulkAsPaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_ExpenseUpdateBulkAsToBePaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_FeeComponentDefinitionActivateArgs = {
  feeComponentDefinitionIds: Array<Scalars['ID']>;
};

export type Mutation_FeeComponentDefinitionCreateArgs = {
  input: FeeComponentDefinitionInput;
  offeringCode: OfferingCode;
};

export type Mutation_FeeComponentDefinitionDeactivateArgs = {
  feeComponentDefinitionIds: Array<Scalars['ID']>;
};

export type Mutation_FeeComponentDefinitionTierCreateArgs = {
  input: FeeComponentTierDefinitionInput;
  offeringCode: OfferingCode;
};

export type Mutation_FeeComponentDefinitionTierUpdateArgs = {
  feeComponentTierDefinitionId: Scalars['ID'];
  input: FeeComponentTierDefinitionInput;
};

export type Mutation_FeeComponentDefinitionUpdateArgs = {
  feeComponentDefinitionId: Scalars['ID'];
  input: FeeComponentDefinitionInput;
};

export type Mutation_FetchLatestEmployeesFromPlatformArgs = {
  integrationId: Scalars['ID'];
};

export type Mutation_FinancialTransactionCollectArgs = {
  input: FinancialTransactionInput;
};

export type Mutation_FinancialTransactionGenerateArgs = {
  input: FinancialTransactionInput;
};

export type Mutation_FixEntitlementChangeRecordsArgs = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_FixTimeoffAllocationsArgs = {
  input?: InputMaybe<FixTimeoffAllocationInput>;
};

export type Mutation_ForceUploadPayslipArgs = {
  file: Scalars['Upload'];
  value: PayslipForceUploadInput;
};

export type Mutation_ForwardItemArgs = {
  category: ApprovalCategory;
  comment?: InputMaybe<Scalars['String']>;
  forwardUserId: Scalars['ID'];
  itemId: Scalars['ID'];
};

export type Mutation_GenerateCompanyQuotationArgs = {
  input: CompanyQuotationGenerationInput;
};

export type Mutation_GenerateCompensationGrantDocumentArgs = {
  grantId: Scalars['ID'];
  grantType: GrantType;
};

export type Mutation_GenerateDepositForContractArgs = {
  contractID: Scalars['ID'];
};

export type Mutation_GetTrinetCredentialArgs = {
  input: TrinetCredentialInput;
};

export type Mutation_HolidaysAddArgs = {
  countryCode: Scalars['String'];
  inputs?: InputMaybe<Array<HolidayAddInput>>;
};

export type Mutation_HolidaysDeleteArgs = {
  filter?: InputMaybe<HolidaysDeleteFilter>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_ImportEmployeesArgs = {
  employees: Array<InputMaybe<Scalars['String']>>;
  syncId: Scalars['String'];
};

export type Mutation_InitiateAutoDebitArgs = {
  input: AutoDebitInput;
};

export type Mutation_LinkContractsToPayrollCycleConfigArgs = {
  contractIds: Array<Scalars['ID']>;
  payrollCycleConfigId?: InputMaybe<Scalars['ID']>;
  validFrom: Scalars['Date'];
};

export type Mutation_ManagerAssignDirectReportsArgs = {
  input: ManagerAssignDirectReportsInput;
};

export type Mutation_ManagerCreateArgs = {
  input: ManagerCreateInput;
};

export type Mutation_ManagerDeleteArgs = {
  input: ManagerDeleteInput;
};

export type Mutation_ManagerUpdateArgs = {
  input: ManagerUpdateInput;
};

export type Mutation_ManagerValidateDirectReportsArgs = {
  input: ManagerValidateDirectReportsInput;
};

export type Mutation_MarkFailCompanyPayInMethodArgs = {
  companyPayInMethodId: Scalars['ID'];
  reason: Scalars['String'];
};

export type Mutation_MemberBackFillBankIdentifierArgs = {
  country: CountryCode;
  memberIds: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_MemberCancelUpdateRequestArgs = {
  category: MemberChangeCategory;
};

export type Mutation_MemberChangePrimaryEmailArgs = {
  email?: InputMaybe<EmailAddressInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberCreateArgs = {
  value: MemberCreateInput;
};

export type Mutation_MemberDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayBackfillBillingCurrencyArgs = {
  memberPayIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_MemberPayableBulkInitiatePayoutArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_MemberPayableCompanyInvoiceConfirmPaymentArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableCompanyInvoiceCreatePayInArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableCompanyInvoiceMarkAsReadyForPayoutsArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableCompanyInvoiceSubmitArgs = {
  input: MemberPayableCompanyInvoiceSubmitInput;
};

export type Mutation_MemberPayableDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableGenerationArgs = {
  generateSecondHalfOnly?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_MemberPayableSubmitArgs = {
  input: MemberPayableSubmitInput;
};

export type Mutation_MemberPayableToggleAutoGenerationArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  state: MemberPayableAutoGenerationState;
};

export type Mutation_MemberPayableUpdateArgs = {
  id: Scalars['ID'];
  input: MemberPayableUpdateInput;
};

export type Mutation_MemberPayableUpdateBulkAsApprovedArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_MemberPayableUpdateBulkAsPaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_MemberPayableUpdateBulkAsRejectedArgs = {
  input: Array<InputMaybe<MemberPayableRejectInput>>;
};

export type Mutation_MemberRemoveProfilePictureArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberSendUpdateRequestArgs = {
  category: MemberChangeCategory;
  requestInput: MemberChangeRequestInput;
};

export type Mutation_MemberUpdateArgs = {
  id: Scalars['ID'];
  memberChange?: InputMaybe<MemberUpdateInput>;
};

export type Mutation_MemberUpdateBankDetailsArgs = {
  data: BankAccountInput;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateBasicDetailsArgs = {
  data: MemberUpdateBasicDetailsInput;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateDocumentArgs = {
  data: Array<LegalDocumentInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateLegalDataArgs = {
  data: Array<LegalDataInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateVisaDocumentArgs = {
  data: Array<LegalDocumentInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberUpsertAddressArgs = {
  id: Scalars['ID'];
  input: MemberAddressInput;
};

export type Mutation_MemberUpsertBasicInfoArgs = {
  id: Scalars['ID'];
  input: MemberBasicInfoInput;
};

export type Mutation_MemberUpsertContactInfoArgs = {
  id: Scalars['ID'];
  input: MemberContactInfoInput;
};

export type Mutation_MemberUpsertCustomFieldsArgs = {
  id: Scalars['ID'];
  input: MemberCustomFieldInput;
};

export type Mutation_MemberUpsertEducationArgs = {
  id: Scalars['ID'];
  input: EducationInput;
};

export type Mutation_MemberUpsertEmergencyContactArgs = {
  id: Scalars['ID'];
  input: EmergencyContactInput;
};

export type Mutation_MemberUpsertIdentificationDetailsArgs = {
  id: Scalars['ID'];
  input: MemberIdentificationDetailsInput;
};

export type Mutation_MemberUpsertPersonalInfoArgs = {
  id: Scalars['ID'];
  input: MemberPersonalInfoInput;
};

export type Mutation_MemberUpsertPreviousEmployerArgs = {
  id: Scalars['ID'];
  input: PreviousEmployerInput;
};

export type Mutation_MemberUpsertProfilePictureArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
  key?: InputMaybe<Scalars['String']>;
};

export type Mutation_MigrateSeatIdArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_MoveToNextPayslipUploadWorkflowStepArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_MoveToNextStatusArgs = {
  action?: InputMaybe<PayrollWorkflowAction>;
  payrollCycleId: Scalars['ID'];
};

export type Mutation_MsaAddendumConfirmArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDocumentGenerateArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDocumentRevokeArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDocumentSendForSignatureArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDraftDeleteArgs = {
  companyId: Scalars['ID'];
};

export type Mutation_MsaAddendumDraftEditArgs = {
  msaAddendumInput: MsaAddendumInput;
};

export type Mutation_MsaAddendumUploadCustomArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type Mutation_OffboardingClientApproveLwdArgs = {
  input: OffboardingClientApproveLwdInput;
};

export type Mutation_OffboardingConfirmAssetsRecoveryArgs = {
  input: OffboardingConfirmAssetsRecoveryInput;
};

export type Mutation_OffboardingExperienceInitiatialiseArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_OffboardingInitializeArgs = {
  input: OffboardingInitializeInput;
};

export type Mutation_OffboardingInputLwdArgs = {
  input: OffboardingInputLwdInput;
};

export type Mutation_OffboardingNextStateArgs = {
  offboardingId: Scalars['ID'];
};

export type Mutation_OffboardingOpsApproveLwdArgs = {
  input: OffboardingOpsApproveLwdInput;
};

export type Mutation_OperationsUserCreateArgs = {
  operationsUser: OperationsUserCreateInput;
};

export type Mutation_OperationsUserDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_OperationsUserUpdateArgs = {
  id: Scalars['ID'];
  operationsUserChange: OperationsUserUpdateInput;
};

export type Mutation_OperationsUserUpdateRolesArgs = {
  id: Scalars['ID'];
  roles: Array<OperationsUserRole>;
};

export type Mutation_OpsUpdatePayrollWorkflowArgs = {
  value: UpdatePayrollWorkflowRequest;
};

export type Mutation_OptOutInsuranceArgs = {
  contractBenefitId: Scalars['ID'];
  reasons: Array<Scalars['String']>;
};

export type Mutation_OrderFormRegenerateArgs = {
  input?: InputMaybe<RegenerateOrderFormInput>;
};

export type Mutation_OrgDirectoryAssignReportsToArgs = {
  input: OrgDirectoryAssignReportsToInput;
};

export type Mutation_OrgDirectoryValidateReportsToArgs = {
  input: OrgDirectoryValidateReportsToInput;
};

export type Mutation_OrgRoleAssignManagersArgs = {
  input: OrgRoleAssignManagersInput;
};

export type Mutation_OrgRoleCreateArgs = {
  role: OrgRoleCreateInput;
};

export type Mutation_OrgRoleDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_OrgRoleUpdateArgs = {
  role: OrgRoleUpdateInput;
};

export type Mutation_PartnerAcceptInviteArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerAddCountryCapabilityArgs = {
  partnerCountry?: InputMaybe<PartnerCountryInput>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerComponentBulkUploadArgs = {
  input: Scalars['Upload'];
};

export type Mutation_PartnerCreateArgs = {
  partner?: InputMaybe<CreatePartnerInput>;
};

export type Mutation_PartnerEorAssignContractArgs = {
  contractId: Scalars['ID'];
  partnerId: Scalars['ID'];
};

export type Mutation_PartnerInviteArgs = {
  partnerId: Scalars['ID'];
};

export type Mutation_PartnerMappingBackfillCsvArgs = {
  file: Scalars['Upload'];
};

export type Mutation_PartnerPayrollAssignContractArgs = {
  contractId: Scalars['ID'];
  partnerId: Scalars['ID'];
  partnerMemberId?: InputMaybe<Scalars['String']>;
};

export type Mutation_PartnerPayrollCreateArgs = {
  payrollData: CreatePartnerPayrollInput;
};

export type Mutation_PartnerPayrollPayslipGenerateForPayrollCycleArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PartnerPayrollRegistrationCompletedArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerPayrollSendForReviewArgs = {
  input: SendReportForReviewInput;
};

export type Mutation_PartnerPayrollStartRegistrationArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerPayrollUploadPayslipArgs = {
  file: Scalars['Upload'];
  value: PartnerPayrollUploadPayslipInput;
};

export type Mutation_PartnerUpdatePayrollWorkflowArgs = {
  value: UpdatePayrollWorkflowRequest;
};

export type Mutation_PartnerUserChangeRoleArgs = {
  newRole?: InputMaybe<Scalars['String']>;
  partnerUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerUserCreateArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
  partnerUser?: InputMaybe<CreatePartnerUserInput>;
};

export type Mutation_PartnerUserDeleteArgs = {
  partnerUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerUserUpdateOperatorArgs = {
  countryCode: CountryCode;
  partnerUserId?: InputMaybe<Scalars['ID']>;
  userType: PartnerUserType;
};

export type Mutation_PayInProcessArgs = {
  currency?: InputMaybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  paymentPartnerCode: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type Mutation_PaySupplementBulkUploadArgs = {
  file: Scalars['Upload'];
};

export type Mutation_PaySupplementCreateArgs = {
  paySupplementData?: InputMaybe<PaySupplementCreateInput>;
};

export type Mutation_PaySupplementPaidArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaySupplementRejectArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_PaySupplementRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaySupplementUpdateArgs = {
  id: Scalars['ID'];
  paySupplementChange?: InputMaybe<PaySupplementUpdateInput>;
};

export type Mutation_PaySupplementUpdateBulkAsPaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PaySupplementUpdateBulkAsRevokedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PaySupplementUpdateBulkAsToBePaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PaymentBundleCreateArgs = {
  input: PaymentBundleCreateInput;
};

export type Mutation_PaymentBundleDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaymentBundleMarkAsPaidArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaymentBundleMarkAsProcessingArgs = {
  id: Scalars['ID'];
  paymentAdvices?: InputMaybe<Array<Scalars['Upload']>>;
};

export type Mutation_PaymentBundleUpdateArgs = {
  input?: InputMaybe<PaymentBundleUpdateInput>;
};

export type Mutation_PayrollBulkUploadConfirmArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollBulkUploadCreateArgs = {
  fileName: Scalars['String'];
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollBulkUploadTemplatesRefreshArgs = {
  countries?: InputMaybe<Array<CountryCode>>;
};

export type Mutation_PayrollBulkUploadValidateArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollCycleConfigBulkCreateArgs = {
  input?: InputMaybe<BulkPayrollCycleConfigInput>;
};

export type Mutation_PayrollCycleConfigCreateArgs = {
  input?: InputMaybe<PayrollCycleConfigInput>;
};

export type Mutation_PayrollCycleConfigUpdateArgs = {
  input?: InputMaybe<PayrollCycleConfigUpdateInput>;
};

export type Mutation_PayrollCycleCreateArgs = {
  value: PayrollCycleInput;
};

export type Mutation_PayrollCycleSendForReviewArgs = {
  input: SendPayrollCycleForReviewInput;
};

export type Mutation_PayrollCycleStatusBulkUpdateArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  publishEvent?: InputMaybe<Scalars['Boolean']>;
  status: PayrollCycleStatus;
};

export type Mutation_PayrollCycleUpdateBulkAsClosedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PayrollCycleUpdateBulkAsSuspendedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PayrollInputAuditTrailsRemoveArgs = {
  input: PayrollInputAuditTrailsRemoveInput;
};

export type Mutation_PayrollInputFileDownloadAsyncArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollInputFilePartnerBulkDownloadAsyncArgs = {
  filter: PayrollCycleFilter;
};

export type Mutation_PayrollNoteForContractArgs = {
  contractId: Scalars['ID'];
  payrollNote?: InputMaybe<Scalars['String']>;
};

export type Mutation_PayrollOffCycleCreateArgs = {
  value: PayrollOffCycleCreateInput;
};

export type Mutation_PerformanceReviewBulkCreateAsApprovedArgs = {
  inputs: Array<PerformanceReviewBulkInput>;
};

export type Mutation_PlatformBambooHrAccountTokenUpdateArgs = {
  companyId: Scalars['ID'];
  token: Scalars['String'];
};

export type Mutation_PlatformBatchedUpdateArgs = {
  input: PlatformBatchedUpdateInput;
};

export type Mutation_PlatformCompanyTokenUpdateArgs = {
  companyId: Scalars['ID'];
  platformId: Scalars['ID'];
  token: Scalars['String'];
};

export type Mutation_PlatformUpdateArgs = {
  input: PlatformUpdateInput;
};

export type Mutation_PricingPlanActivateArgs = {
  id: Scalars['ID'];
};

export type Mutation_PricingPlanAutofillArgs = {
  input: PricingPlanAutofillInput;
};

export type Mutation_PricingPlanCloseSeatsArgs = {
  input: PricingPlanCloseSeatsInput;
};

export type Mutation_PricingPlanCreateArgs = {
  input: PricingPlanCreateInput;
};

export type Mutation_PricingPlanDeleteArgs = {
  input: PricingPlanDeleteInput;
};

export type Mutation_PricingPlanExpandArgs = {
  input: PricingPlanExpandInput;
};

export type Mutation_PricingPlanMigrateArgs = {
  input: PricingPlanMigrateInput;
};

export type Mutation_PricingPlanUpdateArgs = {
  input: PricingPlanUpdateInput;
};

export type Mutation_ProviderKnitAuthTokenArgs = {
  clearErrors?: InputMaybe<Scalars['Boolean']>;
  originOrgId: Scalars['String'];
  originOrgName: Scalars['String'];
  originUserEmail: Scalars['String'];
  originUserName: Scalars['String'];
  platformId: Scalars['String'];
};

export type Mutation_PublishPayslipsArgs = {
  value: PayslipPublishInput;
};

export type Mutation_ReGeneratePayrollCycleInputFileArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_ReInitiateActivationArgs = {
  companyPayInMethodId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_RecreatePayrollPaymentBatchArgs = {
  id: Scalars['ID'];
};

export type Mutation_RefreshContractReferencesForPayrollCycleArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_RefreshPayrollSnapshotArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_RefreshPayrollSnapshotByWatermarkArgs = {
  value: PayrollSnapshotWatermarkRefreshInput;
};

export type Mutation_RefreshPayrollSnapshotDomainForceArgs = {
  value: PayrollSnapshotRefreshDomainByIdInput;
};

export type Mutation_RefreshPayrollSnapshotsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_RegenerateAgreementArgs = {
  input?: InputMaybe<RegenerateAgreementInput>;
};

export type Mutation_RegeneratePayslipZipArgs = {
  payrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_RegisterDirectDebitMandateArgs = {
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_RejectItemArgs = {
  category: ApprovalCategory;
  itemId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_RelinkContractToAnotherPayrollCycleConfigArgs = {
  contractId: Scalars['ID'];
  disableConfig?: InputMaybe<Scalars['Boolean']>;
  payrollCycleConfigId: Scalars['ID'];
  validFrom: Scalars['Date'];
};

export type Mutation_SalaryCalculationEmailArgs = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  params?: InputMaybe<SalaryCalculationParam>;
};

export type Mutation_SalaryCalculatorRequestEmailArgs = {
  params?: InputMaybe<SalaryCalculationRequestEmailParam>;
};

export type Mutation_SaveAndDownloadCompanyQuotationArgs = {
  input: CompanyQuotationGenerationInput;
};

export type Mutation_SaveIntegrationEntityMappingStatusArgs = {
  enableDataSync?: InputMaybe<Scalars['Boolean']>;
  entityMappingId: Scalars['ID'];
};

export type Mutation_SaveIntegrationFieldsMappingArgs = {
  input?: InputMaybe<SaveIntegrationFieldsMappingInput>;
};

export type Mutation_SeatAssignArgs = {
  input: SeatAssignInput;
};

export type Mutation_SeatReleaseArgs = {
  input: SeatReleaseInput;
};

export type Mutation_SendEmailToOnboardingSpecialistArgs = {
  contractId: Scalars['ID'];
  message: Scalars['String'];
};

export type Mutation_SendImportedMemberInvitesArgs = {
  employees: Array<InputMaybe<Scalars['String']>>;
  syncId?: InputMaybe<Scalars['String']>;
};

export type Mutation_SendInvoiceToContactEmailArgs = {
  input: ContactEmailInput;
};

export type Mutation_SendKybRequirementsEmailArgs = {
  input: KybRequirementsInput;
};

export type Mutation_SendSyncedMemberInvitesArgs = {
  employees: Array<InputMaybe<Scalars['ID']>>;
  syncId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_SendToSignatoryPerformanceReviewArgs = {
  reviewId: Scalars['ID'];
};

export type Mutation_SendToSignatorySalaryReviewArgs = {
  reviewId: Scalars['ID'];
};

export type Mutation_SetDisabledPayrollCycleConfigToContractArgs = {
  configToContractLinkId: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
};

export type Mutation_StartIncomingSyncArgs = {
  integrationId: Scalars['ID'];
};

export type Mutation_StartNewKybAssessmentArgs = {
  kybId: Scalars['ID'];
  reportExists: Scalars['Boolean'];
};

export type Mutation_SyncEorManuallyArgs = {
  integrationId: Scalars['ID'];
};

export type Mutation_SyncEmployeesArgs = {
  employees: Array<InputMaybe<Scalars['ID']>>;
  syncId: Scalars['ID'];
};

export type Mutation_SyncPayrollCycleArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_SyncTurnOffArgs = {
  syncId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_SyncXeroInvoicesToCoreArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Mutation_TemplateDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_TemplateUpsertArgs = {
  input: TemplateInput;
};

export type Mutation_TerminatePayrollWorkflowArgs = {
  value: TerminatePayrollWorkflowRequest;
};

export type Mutation_TimeOffBulkRevokeArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_TimeOffCreateArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  input: TimeOffCreateInput;
};

export type Mutation_TimeOffDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_TimeOffPolicyAssignUsersArgs = {
  input: TimeOffPolicyAssignUsersInput;
};

export type Mutation_TimeOffPolicyCreateArgs = {
  input: TimeOffPolicyCreateInput;
};

export type Mutation_TimeOffPolicyDeleteArgs = {
  policyId: Scalars['ID'];
};

export type Mutation_TimeOffPolicyUpdateArgs = {
  input: TimeOffPolicyUpdateInput;
};

export type Mutation_TimeOffPolicyValidateUsersArgs = {
  input: TimeOffPolicyValidateUsersInput;
};

export type Mutation_TimeOffRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_TimeOffSaveAsDraftArgs = {
  input: TimeOffSaveAsDraftInput;
};

export type Mutation_TimeOffTypeCreateArgs = {
  input: TimeOffTypeCreateInput;
};

export type Mutation_TimeOffTypeDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_TimeOffTypeUpdateArgs = {
  input: TimeOffTypeUpdateInput;
};

export type Mutation_TimeOffUpdateArgs = {
  id: Scalars['ID'];
  input: TimeOffUpdateInput;
};

export type Mutation_TimeOffUpdateCompanyDefinitionsArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type Mutation_TimeOffUpdateCountryDefinitionsArgs = {
  file: Scalars['Upload'];
};

export type Mutation_TimesheetChangeStatusArgs = {
  comment?: InputMaybe<Scalars['String']>;
  status: TimesheetStatus;
  timesheetId: Scalars['ID'];
};

export type Mutation_TimesheetCreateBulkArgs = {
  endDate: Scalars['Date'];
  file: Scalars['Upload'];
  startDate: Scalars['Date'];
};

export type Mutation_TimesheetCreateOrUpdateArgs = {
  input?: InputMaybe<TimesheetCreateUpdateInput>;
  timesheetId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesApproveArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesCreateOrUpdateArgs = {
  input: Array<TimesheetEntryInput>;
  timesheetId: Scalars['ID'];
};

export type Mutation_TimesheetEntriesDeleteArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesRejectArgs = {
  comment?: InputMaybe<Scalars['String']>;
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesRevokeArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesSubmitArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntryCreateOrUpdateArgs = {
  input: TimesheetEntryInput;
  timesheetId: Scalars['ID'];
};

export type Mutation_TimesheetGenerateIfMissingArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_TimesheetUploadBulkArgs = {
  file: Scalars['Upload'];
  input: TimesheetUploadBulkInput;
};

export type Mutation_ToggleAutoDebitArgs = {
  companyId: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type Mutation_TriggerCarryForwardExpirationArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_TriggerCompleteWorkEmailAndEmployeeIdReminderEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  simulatedSendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerContractBenefitSetEndedStatusArgs = {
  endOn?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerContractWorkEmailReminderOnStartDayEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  simulatedSendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerEmployeeTimesheetEntryAutoApprovalArgs = {
  currentDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerEmployeeTimesheetsAutoSubmissionArgs = {
  currentDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerMemberExpenseSubmissionRemindersArgs = {
  simulatedExecutionDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerOnboardingReminderEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  simulatedSendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerPaySupplementAutoCreateProcessArgs = {
  filters?: InputMaybe<PaySupplementAutoCreationFilters>;
};

export type Mutation_TriggerProbationEndReminderEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  sendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerReminderEmailForSalaryRevisionSignatureArgs = {
  simulatedExecutionDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerReviewPayrollInputReminderEmailArgs = {
  companyPayrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_TriggerReviewPayrollReportReminderEmailArgs = {
  companyPayrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_TriggerTimeoffAllocationArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_TriggerTimeoffCarryForwardArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_TriggerTimesheetApproveSubmittedHoursReminderEmailsToAdminsArgs = {
  currentDate: Scalars['Date'];
};

export type Mutation_TriggerUpcomingMonthlyInvoiceEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  invoiceMonth?: InputMaybe<MonthYearInput>;
};

export type Mutation_UnlinkContractFromPayrollCycleConfigArgs = {
  contractId: Scalars['ID'];
  validTo: Scalars['Date'];
};

export type Mutation_UpdateAndFixTimeOffsArgs = {
  input?: InputMaybe<UpdateAndFixTimeOffsInput>;
};

export type Mutation_UpdateCompanyChargebackPeriodArgs = {
  request: UpdateCompanyChargebackRequest;
};

export type Mutation_UpdateCompanyPayInMethodChargeBackArgs = {
  request: UpdateCompanyPayInMethodChargeBackRequest;
};

export type Mutation_UpdateCompanyPricingArgs = {
  companyId: Scalars['ID'];
  pricing: PricingInput;
  skipCreatingMsaAddendum?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_UpdateCompensationPaymentInfoArgs = {
  input: UpdateCompensationPayInfoInput;
};

export type Mutation_UpdateComplianceAssessmentDataArgs = {
  input: ComplianceAssessmentDataInput;
};

export type Mutation_UpdateComplianceAssessmentDocumentsArgs = {
  input: ComplianceAssessmentDocumentsInput;
};

export type Mutation_UpdateContractBenefitChangeRequestArgs = {
  input: Array<ContractBenefitChangeRequestUpdateInput>;
};

export type Mutation_UpdateContractPaymentDetailsArgs = {
  id: Scalars['ID'];
  input: ContractUpdatePaymentAccountInput;
};

export type Mutation_UpdateDemoCyclesArgs = {
  value: UpdateDemoCyclesInput;
};

export type Mutation_UpdateDomainItemsArgs = {
  domain: PayrollInputDomainType;
  domainIds: Array<Scalars['ID']>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_UpdateFinancialAssessmentDataArgs = {
  input: FinancialAssessmentDataInput;
};

export type Mutation_UpdateFinancialAssessmentDocumentsArgs = {
  input: FinancialAssessmentDocumentsInput;
};

export type Mutation_UpdateInsuranceArgs = {
  benefitUpdate: InsuranceUpdateInput;
  contractId: Scalars['ID'];
  dependentsUpdate?: InputMaybe<Array<ContractUpdateBenefitDependentInput>>;
};

export type Mutation_UpdateInsuranceStatusArgs = {
  contractId: Scalars['ID'];
  statuses: InsuranceStatuses;
};

export type Mutation_UpdateInvoiceAndBundleOnExternalInvoicePaidArgs = {
  externalInvoiceId: Scalars['String'];
};

export type Mutation_UpdateOrCreateCompanyBindingArgs = {
  input: UpdateOrCreateCompanyBindingInput;
};

export type Mutation_UpdatePayrollParserConfigArgs = {
  input: PayrollParserConfigInput;
};

export type Mutation_UpdatePayrollPartnerMemberIdToContractIdArgs = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  country?: InputMaybe<CountryCode>;
};

export type Mutation_UpdatePayrollPaymentBatchStatusArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  payrollPaymentBatchStatus: PayrollPaymentBatchStatus;
};

export type Mutation_UpdatePayrollPaymentStatusArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  status: PayrollPaymentStatus;
};

export type Mutation_UpdatePayrollReconciliationResultStatusArgs = {
  resultId: Scalars['ID'];
  resultStatus: ReconciliationResultStatus;
};

export type Mutation_UpdatePayrollReportGeneratorConfigArgs = {
  input: PayrollReportGeneratorConfigInput;
};

export type Mutation_UpdatePayrollWorkflowArgs = {
  value: UpdatePayrollWorkflowRequest;
};

export type Mutation_UpdateTimeoffDefinitionArgs = {
  file: Scalars['Upload'];
};

export type Mutation_UploadAssessmentDocumentArgs = {
  document: Scalars['Upload'];
};

export type Mutation_UploadContractIdMappingFileArgs = {
  input: Scalars['Upload'];
};

export type Mutation_UploadPayrollFileArgs = {
  country: CountryCode;
  input: Scalars['Upload'];
  partnerId: Scalars['ID'];
  payrollMonth: PayrollMonthInput;
};

export type Mutation_UploadPayrollOutputReportArgs = {
  input: Scalars['Upload'];
  partnerId: Scalars['ID'];
  payrollCycle: Scalars['ID'];
};

export type Mutation_UploadSignedAgreementArgs = {
  agreement?: InputMaybe<Scalars['Upload']>;
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_UpsertCompanyPayInMethodBankDetailsArgs = {
  input?: InputMaybe<CompanyPayInMethodInput>;
};

export type Mutation_UpsertContractBenefitBannersArgs = {
  input: ContractBenefitBannerInput;
};

export type Mutation_UpsertCountryContractClausesArgs = {
  inputs: Array<CountryContractClauseUpsertInput>;
};

export type Mutation_UpsertCountryDocumentArgs = {
  file: Scalars['Upload'];
  input?: InputMaybe<CountryDocumentUpdateInput>;
};

export type Mutation_UpsertCountryLegalDataDefinitionsArgs = {
  inputs: Array<CountryLegalDataDefinitionUpsertInput>;
};

export type Mutation_UpsertPerformanceReviewArgs = {
  review: PerformanceReviewInput;
};

export type Mutation_UpsertRecruiterArgs = {
  input?: InputMaybe<RecruiterInput>;
  logo?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_UpsertSsoPreferenceForCompanyArgs = {
  companyId: Scalars['ID'];
  input: UpsertSsoPreferenceInput;
};

export type Mutation_UpsertSalaryReviewArgs = {
  review: SalaryReviewInput;
};

export type Mutation_ValidatePayslipsArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_VerifyTriNetCredentialArgs = {
  input: TrinetCredentialInputV2;
};

export type NachaDebitInstructionFileGenerateData = {
  companyBankOverrideInfo?: InputMaybe<CompanyBankOverrideInfo>;
  companyId: Scalars['ID'];
  expectedPayoutDate: Scalars['Date'];
  fileDataOverrideInfo?: InputMaybe<NachaFileDataOverride>;
  invoiceIds: Array<InputMaybe<Scalars['ID']>>;
  invoiceOverrideInfo?: InputMaybe<Array<InputMaybe<InvoiceOverrideInfo>>>;
};

export type NachaFileDataOverride = {
  fileIdModifier?: InputMaybe<Scalars['String']>;
};

export type NachaPrenoteInstructionFileGenerateData = {
  companyBankOverrideInfo?: InputMaybe<CompanyBankOverrideInfo>;
  companyId: Scalars['ID'];
  fileDataOverrideInfo?: InputMaybe<NachaFileDataOverride>;
};

export enum Nationalogy {
  CITIZEN = 'CITIZEN',
  PERMANENT_RESIDENT = 'PERMANENT_RESIDENT',
  TEMPORARY_RESIDENT = 'TEMPORARY_RESIDENT',
}

export enum NotificationType {
  ACHDIRECTDEBITOPSEMAIL = 'ACHDirectDebitOpsEmail',
  ACHDIRECTDEBITPRENOTEEMAIL = 'ACHDirectDebitPreNoteEmail',
  ACHDIRECTDEBITSETUPOPSEMAIL = 'ACHDirectDebitSetupOpsEmail',
  ADDCOMPANYMEMBER = 'AddCompanyMember',
  ADMINCONTRACTDELETED = 'AdminContractDeleted',
  ADMINCONTRACTREVOKED = 'AdminContractRevoked',
  APPROVEPEOPAYROLLREPORTREMINDER = 'ApprovePeoPayrollReportReminder',
  APPROVERUPDATIONEMAILTOOLDAPPROVER = 'ApproverUpdationEmailToOldApprover',
  AUTODEBITDISPUTERECREATIONNOTIFICATION = 'AutoDebitDisputeRecreationNotification',
  AUTODEBITMANUALCREATIONNOTIFICATION = 'AutoDebitManualCreationNotification',
  AUTODEBITPRENOTIFICATION = 'AutoDebitPreNotification',
  BENEFITCHANGEREQUESTRECEIVEDEMAILTOMEMBER = 'BenefitChangeRequestReceivedEmailToMember',
  BENEFITCHANGEREQUESTUPDATEDEMAILTOMEMBER = 'BenefitChangeRequestUpdatedEmailToMember',
  BULKONBOARDINGFAILEDTOSLACK = 'BulkOnboardingFailedToSlack',
  BULKONBOARDINGSUCCESSTOOPS = 'BulkOnboardingSuccessToOps',
  BUNDLEPAYMENTRECEIVEDEMAILTOOPS = 'BundlePaymentReceivedEmailToOps',
  CHANGEPASSWORD = 'ChangePassword',
  COMPANYADMINACTIVATEDTOOPS = 'CompanyAdminActivatedToOps',
  COMPANYBILLINGCONTACTUSERINVITATION = 'CompanyBillingContactUserInvitation',
  COMPANYCREATIONOPSEMAILORGANIC = 'CompanyCreationOpsEmailOrganic',
  COMPANYINVITATION = 'CompanyInvitation',
  COMPANYUSERINVITATIONADMIN = 'CompanyUserInvitationAdmin',
  COMPANYUSERINVITATIONMANAGER = 'CompanyUserInvitationManager',
  /** @deprecated No longer supported */
  CONTRACTACTIVATEDTOEMPLOYER = 'ContractActivatedToEmployer',
  CONTRACTACTIVATEDTOMEMBER = 'ContractActivatedToMember',
  CONTRACTCHANGEREQUESTBLOCKEDEMAILTOCOMPANYUSER = 'ContractChangeRequestBlockedEmailToCompanyUser',
  CONTRACTCHANGEREQUESTDELAYEDEMAILTOCOMPANYUSER = 'ContractChangeRequestDelayedEmailToCompanyUser',
  CONTRACTCHANGEREQUESTUNBLOCKEDEMAILTOCOMPANYUSER = 'ContractChangeRequestUnblockedEmailToCompanyUser',
  CONTRACTEMPLOYEEIDANDWORKEMAILREMINDERTOCOMPANY = 'ContractEmployeeIdAndWorkEmailReminderToCompany',
  CONTRACTFIXEDTERMNONRENEWABLEEXPIRINGREMINDER = 'ContractFixedTermNonRenewableExpiringReminder',
  CONTRACTFIXEDTERMRENEWABLEEXPIRINGREMINDER = 'ContractFixedTermRenewableExpiringReminder',
  CONTRACTORDERFORMTOCOMPANY = 'ContractOrderFormToCompany',
  CONTRACTORDERFORMTOMULTIPLIER = 'ContractOrderFormToMultiplier',
  CONTRACTQUESTIONNAIREEMAILTOCOMPANY = 'ContractQuestionnaireEmailToCompany',
  CONTRACTQUESTIONNAIREEMAILTOOPS = 'ContractQuestionnaireEmailToOps',
  CONTRACTWORKEMAILREMINDERONSTARTDAYTOCOMPANY = 'ContractWorkEmailReminderOnStartDayToCompany',
  DAILYTIMECARDREMINDERTOMEMBER = 'DailyTimecardReminderToMember',
  DATACHANGENOTIFYTOOPS = 'DataChangeNotifyToOps',
  DEPARTMENTCREATEDEMAILTOADMIN = 'DepartmentCreatedEmailToAdmin',
  DEPARTMENTDELETEDEMAILTOADMIN = 'DepartmentDeletedEmailToAdmin',
  DEPOSITINVOICEPAIDSLACKEMAIL = 'DepositInvoicePaidSlackEmail',
  DIRECTDEBITFAILEDSTATUSEMAILTOFINANCE = 'DirectDebitFailedStatusEmailToFinance',
  DIRECTDEBITPROCESSINGFAILEDEMAILTOFINANCE = 'DirectDebitProcessingFailedEmailToFinance',
  DISPUTEINVOICEEMAILTOUSER = 'DisputeInvoiceEmailToUser',
  EMPLOYEECONTRACTREVISED = 'EmployeeContractRevised',
  EORINVOICERAISEDTOCOMPANYADMIN = 'EorInvoiceRaisedToCompanyAdmin',
  EQUIPMENTPROCUREMENTCREATEDEMAILTOOPS = 'EquipmentProcurementCreatedEmailToOps',
  EQUIPMENTQUOTATIONCREATEDEMAILTOOPS = 'EquipmentQuotationCreatedEmailToOps',
  ESOPEMAILTOMEMBER = 'EsopEmailToMember',
  ESOPEMAILTOSIGNATORY = 'EsopEmailToSignatory',
  EXPENSEAPPROVEDTOMEMBER = 'ExpenseApprovedToMember',
  EXPENSEAPPROVEDTOOPS = 'ExpenseApprovedToOps',
  EXPENSECREATEDTOCOMPANYUSERS = 'ExpenseCreatedToCompanyUsers',
  EXPENSEDELETETOEMPLOYER = 'ExpenseDeleteToEmployer',
  /** @deprecated No longer supported */
  EXPENSEDELETETOOPS = 'ExpenseDeleteToOps',
  EXPENSEREJECTEDTOMEMBER = 'ExpenseRejectedToMember',
  EXPENSEREMINDERTOEMPLOYER = 'ExpenseReminderToEmployer',
  EXPENSESUBMISSIONREMINDERTOMEMBER = 'ExpenseSubmissionReminderToMember',
  EXPENSEUPDATETOEMPLOYER = 'ExpenseUpdateToEmployer',
  /** @deprecated No longer supported */
  EXPENSEUPDATETOOPS = 'ExpenseUpdateToOps',
  FIRSTCONTRACTONBOARDINGREMINDERTOCOMPANY = 'FirstContractOnboardingReminderToCompany',
  FIRSTCONTRACTONBOARDINGREMINDERTOMEMBER = 'FirstContractOnboardingReminderToMember',
  FREELANCERINVOICEPAIDCONFIRMEDTOOPS = 'FreeLancerInvoicePaidConfirmedToOps',
  FREELANCERINVOICEPAYINCONFIRMEDTOOPS = 'FreeLancerInvoicePayInConfirmedToOps',
  FREELANCERINVOICEAPPROVEDTOMEMBER = 'FreelancerInvoiceApprovedToMember',
  FREELANCERINVOICEAPPROVEDTOOPS = 'FreelancerInvoiceApprovedToOps',
  FREELANCERINVOICEFIRSTREMINDERTOMEMBER = 'FreelancerInvoiceFirstReminderToMember',
  FREELANCERINVOICEPAIDTOCUSTOMER = 'FreelancerInvoicePaidToCustomer',
  FREELANCERINVOICEPAIDTOMEMBER = 'FreelancerInvoicePaidToMember',
  FREELANCERINVOICEPARTIALPAYINRECEIVEDTOCOMPANY = 'FreelancerInvoicePartialPayInReceivedToCompany',
  FREELANCERINVOICEPARTIALPAYINRECEIVEDTOOPS = 'FreelancerInvoicePartialPayInReceivedToOps',
  FREELANCERINVOICEPAYMENTRECEIVEDTOCUSTOMER = 'FreelancerInvoicePaymentReceivedToCustomer',
  FREELANCERINVOICERAISEDTOCUSTOMER = 'FreelancerInvoiceRaisedToCustomer',
  FREELANCERINVOICEREJECTEDTOMEMBER = 'FreelancerInvoiceRejectedToMember',
  FREELANCERINVOICEREMINDERTOCUSTOMER = 'FreelancerInvoiceReminderToCustomer',
  FREELANCERINVOICEREVISEDSUCCESSFULLYTOMEMBER = 'FreelancerInvoiceRevisedSuccessfullyToMember',
  FREELANCERINVOICESECONDREMINDERTOMEMBER = 'FreelancerInvoiceSecondReminderToMember',
  /** @deprecated Use FreelancerInvoiceRaisedToCustomer instead */
  FREELANCERINVOICESUBMITTEDTOCOMPANY = 'FreelancerInvoiceSubmittedToCompany',
  FREELANCERINVOICESUBMITTEDTOMEMBER = 'FreelancerInvoiceSubmittedToMember',
  FREELANCERINVOICESUBMITTEDTOOPS = 'FreelancerInvoiceSubmittedToOps',
  FREELANCERINVOICETHIRDREMINDERTOMEMBER = 'FreelancerInvoiceThirdReminderToMember',
  FREELANCERINVOICEUPDATEDTOCUSTOMER = 'FreelancerInvoiceUpdatedToCustomer',
  FREELANCERPAYOUTFAILCLIENT = 'FreelancerPayoutFailClient',
  FREELANCERPAYOUTFAILFREELANCER = 'FreelancerPayoutFailFreelancer',
  FUNDCLEAREDWITHCHARGEBACKPERIOD = 'FundClearedWithChargebackPeriod',
  FUNDCLEAREDWITHOUTCHARGEBACKPERIOD = 'FundClearedWithoutChargebackPeriod',
  GPANDHRISMEMBERINVITE = 'GPAndHRISMemberInvite',
  HRISONLYMEMBERINVITE = 'HRISOnlyMemberInvite',
  HRISROLECHANGEEMAILTOEMPLOYEE = 'HRISRoleChangeEmailToEmployee',
  INPUTFILEDOWNLOADFAILEDTOOPS = 'InputFileDownloadFailedToOps',
  INPUTFILEDOWNLOADSUCCESSTOOPS = 'InputFileDownloadSuccessToOps',
  INSURANCECLIENTEMAILSFORAGEBASEDCOST = 'InsuranceClientEmailsForAgeBasedCost',
  INTEGRATIONAUTOSYNCFAILEDEMAILTOADMIN = 'IntegrationAutoSyncFailedEmailToAdmin',
  INTEGRATIONCONNECTEDEMAILTOADMIN = 'IntegrationConnectedEmailToAdmin',
  INTEGRATIONCREDENTIALSEXPIREDEMAILTOADMIN = 'IntegrationCredentialsExpiredEmailToAdmin',
  INTEGRATIONDISCONNECTEDEMAILTOADMIN = 'IntegrationDisconnectedEmailToAdmin',
  INTEGRATIONEMPLOYEEIMPORTCOMPLETEDEMAILTOADMIN = 'IntegrationEmployeeImportCompletedEmailToAdmin',
  INTEGRATIONEMPLOYEESYNCTURNEDONEMAILTOADMIN = 'IntegrationEmployeeSyncTurnedOnEmailToAdmin',
  INTEGRATIONSYNCRESULTSEMAILTOADMIN = 'IntegrationSyncResultsEmailToAdmin',
  INVOICEPAIDEMAILTOCOMPANY = 'InvoicePaidEmailToCompany',
  LEGALDOCUMENTAPPROVEDEMAILTOMEMBER = 'LegalDocumentApprovedEmailToMember',
  LEGALDOCUMENTCREATEDEMAILTOOPS = 'LegalDocumentCreatedEmailToOps',
  LEGALDOCUMENTREJECTEDEMAILTOMEMBER = 'LegalDocumentRejectedEmailToMember',
  LEGALENTITYACTIVATEDTOADMIN = 'LegalEntityActivatedToAdmin',
  MEMBERCHANGEREQUESTSTATUS = 'MemberChangeRequestStatus',
  MEMBERCHANGEREQUESTSTATUSAPPROVED = 'MemberChangeRequestStatusApproved',
  MEMBERCHANGEREQUESTSTATUSREJECTED = 'MemberChangeRequestStatusRejected',
  MEMBERCHANGEREQUESTTOCOMPANYADMIN = 'MemberChangeRequestToCompanyAdmin',
  MEMBERCOMPLETEONBOARDINGREMINDER = 'MemberCompleteOnboardingReminder',
  MEMBERCOMPLETEDONBOARDINGTOMEMBERANDADMINS = 'MemberCompletedOnboardingToMemberAndAdmins',
  MEMBERINVITE = 'MemberInvite',
  MEMBERJOININGDAY = 'MemberJoiningDay',
  MEMBERJOININGDAYBATCH = 'MemberJoiningDayBatch',
  MEMBERJOININGDAYPAYROLLFORMS = 'MemberJoiningDayPayrollForms',
  MEMBEROFFBOARDINGCANCELEMAILTOMEMBER = 'MemberOffBoardingCancelEmailToMember',
  MEMBEROFFBOARDINGCANCELEMAILTOOPS = 'MemberOffBoardingCancelEmailToOps',
  MEMBEROFFBOARDINGEMAILTOMEMBER = 'MemberOffBoardingEmailToMember',
  MEMBEROFFBOARDINGEMAILTOOPS = 'MemberOffBoardingEmailToOps',
  MEMBEROFFBOARDINGFUTUREDATECONTRACTENDEMAILTOMEMBER = 'MemberOffBoardingFutureDateContractEndEmailToMember',
  MEMBERPENDINGPAYROLLFORMSSUBMISSIONREMINDERTOMEMBER = 'MemberPendingPayrollFormsSubmissionReminderToMember',
  MEMBERVERIFICATIONCOMPLETE = 'MemberVerificationComplete',
  MSAEMAILTOCOMPANY = 'MsaEmailToCompany',
  MSASIGNEDEMAILTOCXLEADERS = 'MsaSignedEmailToCXLeaders',
  OFFERLETTEREMAIL = 'OfferLetterEmail',
  ONBOARDINGSPECIALISTEMAIL = 'OnboardingSpecialistEmail',
  OPSASSIGNCUSTOMERTOCSM = 'OpsAssignCustomerToCsm',
  OPSCOMPANYISFXSENSITIVE = 'OpsCompanyIsFxSensitive',
  OPSCONTRACTASSIGNEDTOONBOARDINGSPECIALIST = 'OpsContractAssignedToOnboardingSpecialist',
  OPSCONTRACTDELETED = 'OpsContractDeleted',
  OPSCONTRACTREQUESTCHANGESFORMEMBER = 'OpsContractRequestChangesForMember',
  OPSCONTRACTREVOKED = 'OpsContractRevoked',
  OPSEMPLOYERONBOARDEDMEMBER = 'OpsEmployerOnboardedMember',
  OPSMEMBERCHANGEREQUEST = 'OpsMemberChangeRequest',
  OPSMEMBERCOMPLETEDONBOARDING = 'OpsMemberCompletedOnboarding',
  OPSMEMBERONBOARDINGFORACOUNTRYWITHWETINKREQUIREMENT = 'OpsMemberOnboardingForACountryWithWetInkRequirement',
  OPSMEMBERONBOARDINGFORACOUNTRYWITHOUTCONTRACT = 'OpsMemberOnboardingForACountryWithoutContract',
  OPSMEMBERRETIREMENTPLANDETAILS = 'OpsMemberRetirementPlanDetails',
  OPSOFFBOARDINGCONTRACTSREMINDER = 'OpsOffboardingContractsReminder',
  OPSPAYSUPPLEMENTADDED = 'OpsPaySupplementAdded',
  OPSPAYSUPPLEMENTREVOKED = 'OpsPaySupplementRevoked',
  OPSPAYSUPPLEMENTUPDATED = 'OpsPaySupplementUpdated',
  OPSREPORTSENTFORREVIEW = 'OpsReportSentForReview',
  OPSSTARTDATECHANGED = 'OpsStartDateChanged',
  PARTNERPAYROLLINITIATIONWITHUPDATES = 'PartnerPayrollInitiationWithUpdates',
  PARTNERPAYROLLINITIATIONWITHOUTUPDATES = 'PartnerPayrollInitiationWithoutUpdates',
  PAYSUPPLEMENTREMINDERTOCOMPANYADMINS = 'PaySupplementReminderToCompanyAdmins',
  PAYSUPPLEMENTREMINDERTOCOMPANYADMINSMONTHLY = 'PaySupplementReminderToCompanyAdminsMonthly',
  PAYSUPPLEMENTREMINDERTOCOMPANYADMINSMONTHLYCOUNTRYSPECIFICCUTOFF = 'PaySupplementReminderToCompanyAdminsMonthlyCountrySpecificCutOff',
  PAYMENTBUNDLEMARKASPAIDEMAILTOOPS = 'PaymentBundleMarkAsPaidEmailToOps',
  PAYMENTBUNDLEUNPAIDREMINDEREMAILTOCOMPANY = 'PaymentBundleUnpaidReminderEmailToCompany',
  PAYROLLCYCLEOUTPUTAPPROVEDEMAILTOOPS = 'PayrollCycleOutputApprovedEmailToOps',
  PAYROLLMEMBERINVITE = 'PayrollMemberInvite',
  PAYROLLPARTNERUSERINVITE = 'PayrollPartnerUserInvite',
  PAYSLIPFILEVALIDATIONRESULTEMAIL = 'PayslipFileValidationResultEmail',
  PAYSLIPMEMBEREMAIL = 'PayslipMemberEmail',
  PENDINGAPPROVALEMAILTONEWAPPROVER = 'PendingApprovalEmailToNewApprover',
  PERFORMANCEREVIEWREMINDEREMAILTOCOMPANYSIGNATORY = 'PerformanceReviewReminderEmailToCompanySignatory',
  PERFORMANCEREVIEWREMINDEREMAILTOEMPLOYEE = 'PerformanceReviewReminderEmailToEmployee',
  PERFORMANCEREVIEWSIGNATORYEMAILTOEMPLOYEE = 'PerformanceReviewSignatoryEmailToEmployee',
  PREREGISTRATIONCONTRACTACTIVATIONEMAILTOOPS = 'PreRegistrationContractActivationEmailToOps',
  PROBATIONENDREMINDERTOCOMPANY = 'ProbationEndReminderToCompany',
  RESETPASSWORD = 'ResetPassword',
  REVIEWEORPAYROLLREMINDER = 'ReviewEorPayrollReminder',
  REVIEWEORPAYROLLREPORTREMINDER = 'ReviewEorPayrollReportReminder',
  REVIEWPEOPAYROLLREMINDER = 'ReviewPeoPayrollReminder',
  SALARYCALCULATORREQUESTEMAIL = 'SalaryCalculatorRequestEmail',
  SECONDCONTRACTONBOARDINGREMINDERTOCOMPANY = 'SecondContractOnboardingReminderToCompany',
  SECONDCONTRACTONBOARDINGREMINDERTOMEMBER = 'SecondContractOnboardingReminderToMember',
  SENDCONTRACTADDENDUMFORSIGNINGTOCOMPANY = 'SendContractAddendumForSigningToCompany',
  SENDCONTRACTADDENDUMFORSIGNINGTOMEMBER = 'SendContractAddendumForSigningToMember',
  SENDCONTRACTADDENDUMFORSIGNINGTOMULTIPLIER = 'SendContractAddendumForSigningToMultiplier',
  SENDTOADMINAFTERMEMBERSIGNCONTRACT = 'SendToAdminAfterMemberSignContract',
  SENDTOCOMPANYFORSIGNING = 'SendToCompanyForSigning',
  SENDTOCOMPANYFORWETINKCONTRACT = 'SendToCompanyForWetInkContract',
  SENDTOMEMBERFORSIGNING = 'SendToMemberForSigning',
  SENDTOMEMBERFORWELCOME = 'SendToMemberForWelcome',
  SENDTOMEMBERFORWELCOMECONTRACTOR = 'SendToMemberForWelcomeContractor',
  SENDTOMEMBERFORWETINKCONTRACT = 'SendToMemberForWetInkContract',
  SENDTOMULTIPLIERFORSIGNING = 'SendToMultiplierForSigning',
  TRSEMAILTOEMPLOYEE = 'TRSEmailToEmployee',
  TRSEMAILTOEMPLOYER = 'TRSEmailToEmployer',
  TRSEMAILTOMULTIPLIERFORSIGNATURE = 'TRSEmailToMultiplierForSignature',
  TRSEMAILTOOPS = 'TRSEmailToOps',
  TRSHRMEMBEREMAILTOOPS = 'TRSHrMemberEmailToOps',
  TRSPARTNEREOREMAILTOEMPLOYEE = 'TRSPartnerEOREmailToEmployee',
  TRSPARTNEREOREMAILTOOPS = 'TRSPartnerEOREmailToOps',
  TRSREMINDEREMAILTOEMPLOYER = 'TRSReminderEmailToEmployer',
  THIRDCONTRACTONBOARDINGREMINDERTOCOMPANY = 'ThirdContractOnboardingReminderToCompany',
  THIRDCONTRACTONBOARDINGREMINDERTOMEMBER = 'ThirdContractOnboardingReminderToMember',
  TIMEOFFAPPROVEEMAILTOMEMBER = 'TimeoffApproveEmailToMember',
  TIMEOFFCREATEDEMAILTOAPPROVER = 'TimeoffCreatedEmailToApprover',
  TIMEOFFPOLICYASSIGNMENTEMAILTOADMIN = 'TimeoffPolicyAssignmentEmailToAdmin',
  TIMEOFFPOLICYCREATEDEMAILTOADMIN = 'TimeoffPolicyCreatedEmailToAdmin',
  TIMEOFFPOLICYDELETEDEMAILTOADMIN = 'TimeoffPolicyDeletedEmailToAdmin',
  TIMEOFFPOLICYUPDATEDEMAILTOADMIN = 'TimeoffPolicyUpdatedEmailToAdmin',
  TIMEOFFREJECTEMAILTOMEMBER = 'TimeoffRejectEmailToMember',
  TIMEOFFTYPECREATEDEMAILTOADMIN = 'TimeoffTypeCreatedEmailToAdmin',
  TIMEOFFTYPEDELETEDEMAILTOADMIN = 'TimeoffTypeDeletedEmailToAdmin',
  TIMEOFFTYPEUPDATEDEMAILTOADMIN = 'TimeoffTypeUpdatedEmailToAdmin',
  TIMESHEETAPPROVESUBMITTEDHOURSEMAILONENDCYCLETOADMIN = 'TimesheetApproveSubmittedHoursEmailOnEndCycleToAdmin',
  TIMESHEETAPPROVESUBMITTEDHOURSEMAILONMIDCYCLETOADMIN = 'TimesheetApproveSubmittedHoursEmailOnMidCycleToAdmin',
  TIMESHEETAPPROVEDEMAILTOMEMBER = 'TimesheetApprovedEmailToMember',
  TIMESHEETAPPROVEDEMAILTOOPS = 'TimesheetApprovedEmailToOps',
  TIMESHEETAPPROVEDHOURSEMAILTOHOURLYMEMBER = 'TimesheetApprovedHoursEmailToHourlyMember',
  TIMESHEETAPPROVEDHOURSEMAILTONONHOURLYMEMBER = 'TimesheetApprovedHoursEmailToNonHourlyMember',
  TIMESHEETAUTOSUBMITEMAILTOHOURLYRATEMEMBERS = 'TimesheetAutoSubmitEmailToHourlyRateMembers',
  TIMESHEETAUTOSUBMITEMAILTONONHOURLYRATEMEMBERS = 'TimesheetAutoSubmitEmailToNonHourlyRateMembers',
  TIMESHEETREJECTEDEMAILTOMEMBER = 'TimesheetRejectedEmailToMember',
  TIMESHEETREJECTEDHOURSEMAILTOMEMBER = 'TimesheetRejectedHoursEmailToMember',
  TIMESHEETSUBMITTEDEMAILTOCOMPANYUSER = 'TimesheetSubmittedEmailToCompanyUser',
  UPCOMINGMONTHLYINVOICEEMAILTOCOMPANY = 'UpcomingMonthlyInvoiceEmailToCompany',
  UPDATEBANKDETAILSONMULTIPLIEREMAILTOMEMBER = 'UpdateBankDetailsOnMultiplierEmailToMember',
  UPDATEBASICDETAILSONMULTIPLIEREMAILTOMEMBER = 'UpdateBasicDetailsOnMultiplierEmailToMember',
  VISAAPPROVEDTOMEMBER = 'VisaApprovedToMember',
  VISADOCUMENTSSUBMITTEDTOMEMBER = 'VisaDocumentsSubmittedToMember',
  VISADOCUMENTSSUBMITTEDTOOPS = 'VisaDocumentsSubmittedToOps',
  VISADOCUMENTSVERIFIEDTOMEMBER = 'VisaDocumentsVerifiedToMember',
  VISAINVITEFORELIGIBILITYTOMEMBER = 'VisaInviteForEligibilityToMember',
  VISAMEMBERADDEDTOOPS = 'VisaMemberAddedToOps',
  VISASENDCONTRACTREMINDERTOCOMPANY = 'VisaSendContractReminderToCompany',
}

export enum NumberConfigUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export type NumberConfigValue = {
  unit?: Maybe<NumberConfigUnit>;
};

export enum OffBoardingExperienceStep {
  COMPLETED = 'COMPLETED',
  EMPLOYEE_RESIGNATION_LETTER_SIGNED = 'EMPLOYEE_RESIGNATION_LETTER_SIGNED',
  INITIATED_OFFBOARDING = 'INITIATED_OFFBOARDING',
  NOT_STARTED = 'NOT_STARTED',
  RESIGNATION_LETTER_SENT = 'RESIGNATION_LETTER_SENT',
}

export type Offboarding = {
  __typename?: 'Offboarding';
  connectingTransitions: Array<OffboardingTransition>;
  contractId?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  lastWorkingDay?: Maybe<Scalars['Date']>;
  state?: Maybe<OffboardingState>;
  type?: Maybe<OffboardingType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum OffboardingAction {
  CLIENT_APPROVE_LWD = 'CLIENT_APPROVE_LWD',
  CLIENT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT = 'CLIENT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT',
  CONFIRM_ASSETS_RECOVERED = 'CONFIRM_ASSETS_RECOVERED',
  INPUT_LWD = 'INPUT_LWD',
  LWD_PASSED = 'LWD_PASSED',
  MEMBER_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT = 'MEMBER_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT',
  MEMBER_SIGNS_RESIGNATION_LETTER = 'MEMBER_SIGNS_RESIGNATION_LETTER',
  MT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT = 'MT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT',
  OPS_APPROVE_LWD = 'OPS_APPROVE_LWD',
}

export type OffboardingActionStatus = {
  __typename?: 'OffboardingActionStatus';
  currentStatus?: Maybe<OffboardingTriggerStatus>;
  currentTriggerResult?: Maybe<OffboardingTriggerResult>;
  isDone?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  requiredAction?: Maybe<OffboardingAction>;
  requiredTriggerResult?: Maybe<OffboardingTriggerResult>;
};

export type OffboardingAudit = {
  __typename?: 'OffboardingAudit';
  initiatedBy?: Maybe<Person>;
  initiatedOn?: Maybe<Scalars['DateTime']>;
};

export type OffboardingCanInitializeValidation = {
  __typename?: 'OffboardingCanInitializeValidation';
  canInitialize?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export type OffboardingClientApproveLwdInput = {
  offboardingId: Scalars['ID'];
};

export type OffboardingConfirmAssetsRecoveryInput = {
  offboardingId: Scalars['ID'];
};

export type OffboardingExperienceDetails = {
  __typename?: 'OffboardingExperienceDetails';
  additionalComments?: Maybe<Scalars['String']>;
  contractId: Scalars['ID'];
  desiredLastWorkingDay?: Maybe<Scalars['Date']>;
  documentIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  nextStepsSummmaries?: Maybe<Array<Scalars['String']>>;
  noticePeriod?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  submittedOn?: Maybe<Scalars['DateTime']>;
  tentativeLastWorkingDay?: Maybe<Scalars['Date']>;
};

export type OffboardingExperienceState = {
  __typename?: 'OffboardingExperienceState';
  completedSteps?: Maybe<Array<OffBoardingExperienceStep>>;
  contractId: Scalars['ID'];
  currentStep: OffBoardingExperienceStep;
  errorMessage?: Maybe<Scalars['String']>;
  offboardingDetails: OffboardingExperienceDetails;
  offboardingStatus: OffboardingExperienceStatus;
  remainingSteps?: Maybe<Array<OffBoardingExperienceStep>>;
};

export enum OffboardingExperienceStatus {
  APPROVED = 'APPROVED',
  AWAITING_COMPANY_CONFIRMATION = 'AWAITING_COMPANY_CONFIRMATION',
  AWAITING_EMPLOYEE_SIGNATURE = 'AWAITING_EMPLOYEE_SIGNATURE',
  CANCELLED = 'CANCELLED',
  CANNOT_START = 'CANNOT_START',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  REJECTED = 'REJECTED',
}

export enum OffboardingFeeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum OffboardingFeeType {
  COMPLEX = 'COMPLEX',
  STANDARD = 'STANDARD',
}

export type OffboardingGlobalPricing = {
  __typename?: 'OffboardingGlobalPricing';
  employeeType: ContractType;
  offboardingFeeType: OffboardingFeeType;
  status: OffboardingFeeStatus;
};

export type OffboardingGlobalPricingInput = {
  employeeType: ContractType;
  offboardingFeeType: OffboardingFeeType;
  status: OffboardingFeeStatus;
};

export type OffboardingInitializeInput = {
  contractId: Scalars['ID'];
  type: OffboardingType;
};

export type OffboardingInputLwdInput = {
  lastWorkingDay: Scalars['Date'];
  offboardingId: Scalars['ID'];
};

export type OffboardingOpsApproveLwdInput = {
  offboardingId: Scalars['ID'];
};

export enum OffboardingState {
  ASSETS_RECOVERING = 'ASSETS_RECOVERING',
  DRAFT = 'DRAFT',
  LWD_PENDING = 'LWD_PENDING',
  MUTUAL_UNDERSTANDING_AGREEMENT_PENDING = 'MUTUAL_UNDERSTANDING_AGREEMENT_PENDING',
  RESIGNATION_LETTER_PENDING = 'RESIGNATION_LETTER_PENDING',
}

export type OffboardingTransition = {
  __typename?: 'OffboardingTransition';
  actionStatuses: Array<OffboardingActionStatus>;
  from?: Maybe<OffboardingState>;
  to?: Maybe<OffboardingState>;
};

export enum OffboardingTriggerResult {
  ASSETS_RECOVERED = 'ASSETS_RECOVERED',
  CLIENT_APPROVED_LWD = 'CLIENT_APPROVED_LWD',
  CLIENT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT = 'CLIENT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT',
  LWD_EQ_COMPLIANCE_NOTICE_PERIOD = 'LWD_EQ_COMPLIANCE_NOTICE_PERIOD',
  LWD_GT_COMPLIANCE_NOTICE_PERIOD = 'LWD_GT_COMPLIANCE_NOTICE_PERIOD',
  LWD_LT_COMPLIANCE_NOTICE_PERIOD = 'LWD_LT_COMPLIANCE_NOTICE_PERIOD',
  LWD_PASSED = 'LWD_PASSED',
  MEMBER_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT = 'MEMBER_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT',
  MEMBER_SIGNED_RESIGNATION_LETTER = 'MEMBER_SIGNED_RESIGNATION_LETTER',
  MT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT = 'MT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT',
  OPS_APPROVED_LWD = 'OPS_APPROVED_LWD',
}

export enum OffboardingTriggerStatus {
  GUARD_FAILED = 'GUARD_FAILED',
  SUCCESS = 'SUCCESS',
}

export enum OffboardingType {
  RESIGNATION = 'RESIGNATION',
}

export type Offering = {
  __typename?: 'Offering';
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  code?: Maybe<OfferingCode>;
  name?: Maybe<Scalars['String']>;
};

export type OfferingBundleDiscountTermCondition = {
  __typename?: 'OfferingBundleDiscountTermCondition';
  excludes?: Maybe<Array<Maybe<OfferingCode>>>;
  includes?: Maybe<Array<Maybe<OfferingCode>>>;
};

export type OfferingBundleDiscountTermConditionInput = {
  excludes?: InputMaybe<Array<InputMaybe<OfferingCode>>>;
  includes?: InputMaybe<Array<InputMaybe<OfferingCode>>>;
};

export enum OfferingCode {
  AOR = 'AOR',
  EOR = 'EOR',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL = 'GLOBAL_PAYROLL',
  HRIS = 'HRIS',
}

export enum OfferingStatus {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
}

export type OnboardingAuditFilter = {
  contractId: Scalars['ID'];
};

export type OnboardingTask = {
  __typename?: 'OnboardingTask';
  completed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pendingOn?: Maybe<OnboardingTaskUser>;
};

export type OnboardingTaskUser = {
  __typename?: 'OnboardingTaskUser';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  userId?: Maybe<Scalars['String']>;
};

export type OperationsDashboard = {
  __typename?: 'OperationsDashboard';
  aeAllocation?: Maybe<Array<Maybe<Allocation>>>;
  aeUnassigned?: Maybe<Scalars['Int']>;
  createdStateContractCount?: Maybe<Scalars['Int']>;
  csmAllocation?: Maybe<Array<Maybe<Allocation>>>;
  csmUnassigned?: Maybe<Scalars['Int']>;
  inviteStateContractCount?: Maybe<Scalars['Int']>;
  offboardingInitiatedContractCount?: Maybe<Scalars['Int']>;
  sentContractCount?: Maybe<Scalars['Int']>;
  signedMSACount?: Maybe<Scalars['Int']>;
  willEndContractCount?: Maybe<Scalars['Int']>;
};

export type OperationsUser = Person & {
  __typename?: 'OperationsUser';
  calendlyLink?: Maybe<Scalars['String']>;
  /** @deprecated Use `profilePicture` instead */
  displayPicture?: Maybe<DocumentReadable>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  onboardingContracts?: Maybe<Array<Contract>>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  profilePicture?: Maybe<Document>;
  roles?: Maybe<Array<Maybe<OperationsUserRole>>>;
  userId?: Maybe<Scalars['String']>;
};

export type OperationsUser_OnboardingContractsArgs = {
  status?: InputMaybe<ContractStatus>;
};

export type OperationsUserCreateInput = {
  calendlyLink?: InputMaybe<Scalars['String']>;
  displayPicture?: InputMaybe<Scalars['Upload']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<OperationsUserRole>>>;
};

export enum OperationsUserRole {
  ACCOUNT_EXECUTIVE = 'ACCOUNT_EXECUTIVE',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  ADMIN = 'ADMIN',
  CONTRACT_MANAGER = 'CONTRACT_MANAGER',
  CUSTOMER_SUCCESS_LEAD = 'CUSTOMER_SUCCESS_LEAD',
  CUSTOMER_SUCCESS_SPECIALIST = 'CUSTOMER_SUCCESS_SPECIALIST',
  DEVELOPER_ADMIN = 'DEVELOPER_ADMIN',
  FINANCE_OPS_SPECIALIST = 'FINANCE_OPS_SPECIALIST',
  OFFBOARDING_SPECIALIST = 'OFFBOARDING_SPECIALIST',
  ONBOARDING_LEAD = 'ONBOARDING_LEAD',
  ONBOARDING_SPECIALIST = 'ONBOARDING_SPECIALIST',
  PAYROLL_LEAD = 'PAYROLL_LEAD',
  PAYROLL_SPECIALIST = 'PAYROLL_SPECIALIST',
  SALES_OPS = 'SALES_OPS',
}

export type OperationsUserUpdateInput = {
  calendlyLink?: InputMaybe<Scalars['String']>;
  displayPicture?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
};

export type OpsPayslipUploadWorkflow = {
  __typename?: 'OpsPayslipUploadWorkflow';
  allSteps?: Maybe<Array<Maybe<OpsPayslipUploadWorkflowStep>>>;
  currentStep?: Maybe<OpsPayslipUploadWorkflowStep>;
};

export enum OpsPayslipUploadWorkflowStep {
  PAYSLIPS_CONFIRMED = 'PAYSLIPS_CONFIRMED',
  PAYSLIPS_VALIDATION_IN_PROGRESS = 'PAYSLIPS_VALIDATION_IN_PROGRESS',
  PAYSLIPS_VALIDATION_REPORT = 'PAYSLIPS_VALIDATION_REPORT',
  PAYSLIPS_VALIDATION_REPORT_DOWNLOADED = 'PAYSLIPS_VALIDATION_REPORT_DOWNLOADED',
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OrgAttributes = {
  __typename?: 'OrgAttributes';
  costCenter?: Maybe<CostCenter>;
  department?: Maybe<Department>;
};

export type OrgAttributesInput = {
  costCenterId?: InputMaybe<Scalars['ID']>;
  departmentId?: InputMaybe<Scalars['ID']>;
};

export type OrgDirectory = {
  __typename?: 'OrgDirectory';
  contracts?: Maybe<Array<Contract>>;
  managers?: Maybe<Array<Manager>>;
};

export type OrgDirectoryAssignReportsToInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  managerId?: InputMaybe<Scalars['ID']>;
  reportsToManagerId: Scalars['ID'];
};

export type OrgDirectoryFilters = {
  companyId: Scalars['ID'];
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  costCenterIds?: InputMaybe<Array<Scalars['ID']>>;
  departmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** @deprecated Now BE decide this and will only include test contracts for test company and non-test contracts for non-test company. */
  excludeTestContracts?: InputMaybe<Scalars['Boolean']>;
  includeNotStartedContracts?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<RuleInput>;
};

export type OrgDirectoryValidateReportsToInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  managerId?: InputMaybe<Scalars['ID']>;
  reportsToManagerId: Scalars['ID'];
};

export type OrgRole = {
  __typename?: 'OrgRole';
  companyId: Scalars['ID'];
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  managers?: Maybe<Array<Manager>>;
  name: Scalars['String'];
  permissions?: Maybe<Array<Permission>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type OrgRoleAssignManagersInput = {
  managerIds: Array<Scalars['ID']>;
  roleId: Scalars['ID'];
};

export type OrgRoleCreateInput = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Scalars['ID']>;
};

export type OrgRoleFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type OrgRoleUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Scalars['ID']>;
};

export type OutputHeadcountSummary = {
  __typename?: 'OutputHeadcountSummary';
  active?: Maybe<Scalars['Int']>;
  leaver?: Maybe<Scalars['Int']>;
  starter?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Override = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type OverrideInfo = {
  payrollOverride?: InputMaybe<Array<InputMaybe<Override>>>;
};

export type PageRequest = {
  /** pageNumber is the zero-based page number */
  pageNumber?: InputMaybe<Scalars['Int']>;
  /** pageSize is the size of page */
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type PageResult = {
  __typename?: 'PageResult';
  count?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PageSection = {
  __typename?: 'PageSection';
  editable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Section>;
  reason?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<FieldAccessibilityRule>>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type ParsedMemberPay = {
  __typename?: 'ParsedMemberPay';
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  designation?: Maybe<Scalars['String']>;
  employeeDeductions?: Maybe<Scalars['Float']>;
  employerContributions?: Maybe<Scalars['Float']>;
  grossSalary?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  netSalary?: Maybe<Scalars['Float']>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
  rowId?: Maybe<Scalars['ID']>;
  totalPayrollCost?: Maybe<Scalars['Float']>;
};

export type Partner = {
  __typename?: 'Partner';
  countries?: Maybe<Array<Maybe<PartnerCountry>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Partner_CountriesArgs = {
  capability?: InputMaybe<PartnerCapability>;
  country?: InputMaybe<CountryCode>;
};

export type PartnerAccountRequirement = {
  __typename?: 'PartnerAccountRequirement';
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  requirementFields?: Maybe<Array<Maybe<RequirementField>>>;
};

export enum PartnerCapability {
  BENEFIT = 'BENEFIT',
  EOR = 'EOR',
  PAYROLL = 'PAYROLL',
}

export type PartnerCountry = {
  capability?: Maybe<PartnerCapability>;
  country?: Maybe<CountryCode>;
  id?: Maybe<Scalars['ID']>;
  partner?: Maybe<Partner>;
};

export type PartnerCountryInput = {
  capability: PartnerCapability;
  countryCode: CountryCode;
  currency?: InputMaybe<CurrencyCode>;
  emergencyPointOfContact?: InputMaybe<EmergencyPointOfContactInput>;
};

export type PartnerMemberPay = MemberPay & {
  __typename?: 'PartnerMemberPay';
  additional?: Maybe<Array<Maybe<AdditionalPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountNet?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  clientDeductions?: Maybe<Array<Maybe<PayComponent>>>;
  companyPayroll?: Maybe<CompanyPayroll>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<PayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<PayComponent>>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payrollCycle?: Maybe<PayrollCycle>;
  payslip?: Maybe<Payslip>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
  status?: Maybe<MemberPayStatus>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type PartnerPayroll = Payroll & {
  __typename?: 'PartnerPayroll';
  amountTotalCost?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  memberPays?: Maybe<Array<Maybe<PartnerMemberPay>>>;
  month?: Maybe<PayrollMonth>;
  partner?: Maybe<PayrollPartnerCountry>;
  payrollProcess?: Maybe<PayrollProcess>;
  status?: Maybe<PartnerPayrollStatus>;
};

export type PartnerPayrollComponent = {
  __typename?: 'PartnerPayrollComponent';
  category: Scalars['String'];
  country: CountryCode;
  displayName: Scalars['String'];
  id: Scalars['UUID'];
  mandatory?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partnerId: Scalars['ID'];
};

export type PartnerPayrollGeneratePayslipsInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  country?: InputMaybe<CountryCode>;
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth: PayrollMonthInput;
};

export type PartnerPayrollSchema = {
  __typename?: 'PartnerPayrollSchema';
  components: Array<PartnerPayrollComponent>;
};

export type PartnerPayrollSchemaInput = {
  country: CountryCode;
  partnerId: Scalars['ID'];
};

export type PartnerPayrollSendPayslipsInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  country?: InputMaybe<CountryCode>;
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth: PayrollMonthInput;
};

export enum PartnerPayrollStatus {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
}

export type PartnerPayrollUploadPayslipInput = {
  contractId: Scalars['ID'];
  currencyCode: CurrencyCode;
  netAmount: Scalars['Float'];
  payrollCycle?: InputMaybe<Scalars['Int']>;
  payrollMonth: PayrollMonthInput;
};

export type PartnerPayrollVersion = {
  __typename?: 'PartnerPayrollVersion';
  memberPays?: Maybe<Array<Maybe<PartnerMemberPay>>>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
  status?: Maybe<PartnerPayrollVersionStatus>;
  version?: Maybe<Scalars['Int']>;
};

export enum PartnerPayrollVersionStatus {
  CLOSED = 'CLOSED',
  GENERATED_PAYSLIPS = 'GENERATED_PAYSLIPS',
  MEMBER_PAYMENT_DONE = 'MEMBER_PAYMENT_DONE',
  MEMBER_PAYMENT_IN_PROGRESS = 'MEMBER_PAYMENT_IN_PROGRESS',
  NULL_STATUS = 'NULL_STATUS',
  PARTNER_PAYMENT_DONE = 'PARTNER_PAYMENT_DONE',
  PARTNER_PAYMENT_IN_PROGRESS = 'PARTNER_PAYMENT_IN_PROGRESS',
  PAYSLIPS_APPROVED = 'PAYSLIPS_APPROVED',
  PAYSLIPS_CHANGES_REQUESTED = 'PAYSLIPS_CHANGES_REQUESTED',
  PAYSLIPS_REVIEW_IN_PROGRESS = 'PAYSLIPS_REVIEW_IN_PROGRESS',
  REPORT_APPROVED = 'REPORT_APPROVED',
  REPORT_CHANGES_REQUESTED = 'REPORT_CHANGES_REQUESTED',
  REPORT_REVIEW_IN_PROGRESS = 'REPORT_REVIEW_IN_PROGRESS',
  REPORT_SENT_FOR_REVIEW = 'REPORT_SENT_FOR_REVIEW',
  UPLOADED_PAYSLIPS = 'UPLOADED_PAYSLIPS',
}

export enum PartnerPayrollVersionStep {
  PAYMENT = 'PAYMENT',
  PAYROLL_HISTORY = 'PAYROLL_HISTORY',
  PAYROLL_REPORT = 'PAYROLL_REPORT',
  PAYROLL_UPDATE = 'PAYROLL_UPDATE',
  REPORT_REVIEW = 'REPORT_REVIEW',
  UPLOAD_PAYSLIP = 'UPLOAD_PAYSLIP',
}

export enum PartnerRole {
  ADMIN = 'ADMIN',
  PRIMARY_ADMIN = 'PRIMARY_ADMIN',
}

export type PartnerSupportInfo = {
  __typename?: 'PartnerSupportInfo';
  accountType?: Maybe<PaymentAccountType>;
  paymentAccountRequirements?: Maybe<PaymentAccountPartnerRequirement>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  paymentProcessingMethods?: Maybe<Array<Maybe<PaymentProcessingMethod>>>;
  sourceCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export type PartnerUser = {
  __typename?: 'PartnerUser';
  countryCode?: Maybe<CountryCode>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  partner?: Maybe<Partner>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  role?: Maybe<Scalars['String']>;
  type?: Maybe<PartnerUserType>;
  userId?: Maybe<Scalars['ID']>;
};

export enum PartnerUserStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  INVITED = 'INVITED',
}

export enum PartnerUserType {
  PAYMENTS = 'PAYMENTS',
  PAYROLL_PRIMARY = 'PAYROLL_PRIMARY',
  PAYROLL_SECONDARY = 'PAYROLL_SECONDARY',
}

export enum PayAmountType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  FIXED_PERCENTAGE = 'FIXED_PERCENTAGE',
  VARIABLE_AMOUNT = 'VARIABLE_AMOUNT',
  VARIABLE_PERCENTAGE = 'VARIABLE_PERCENTAGE',
}

export type PayComponent = {
  __typename?: 'PayComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PayComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum PayComponentType {
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  DEDUCTIONS = 'DEDUCTIONS',
  OTHER = 'OTHER',
}

export type PayDetail = {
  __typename?: 'PayDetail';
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<BillingRate>;
  type?: Maybe<PayComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum PayFrequency {
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  WEEKLY = 'WEEKLY',
}

export type PayFrequencyDate = {
  __typename?: 'PayFrequencyDate';
  dateOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  identifier?: Maybe<PayFrequencyDateIdentifier>;
};

export enum PayFrequencyDateIdentifier {
  FIRST_PAYOUT_DATE = 'FIRST_PAYOUT_DATE',
  PAYOUT_DATE = 'PAYOUT_DATE',
  PAYOUT_DAY = 'PAYOUT_DAY',
  SECOND_PAYOUT_DATE = 'SECOND_PAYOUT_DATE',
}

export type PayFrequencyDateInput = {
  dateOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  identifier?: InputMaybe<PayFrequencyDateIdentifier>;
};

export type PayInInfo = {
  __typename?: 'PayInInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Array<Maybe<PayInMetadata>>>;
};

export type PayInMetadata = {
  __typename?: 'PayInMetadata';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PayInMethod = {
  context?: Maybe<PayInMethodContext>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  payInMethodType?: Maybe<PayInMethodType>;
  workflowSteps: Array<Maybe<PayInMethodWorkflowStep>>;
};

export type PayInMethodAccountRequirement = {
  __typename?: 'PayInMethodAccountRequirement';
  fields?: Maybe<Array<Maybe<PaymentRequirementField>>>;
  payInAccountRequirementType?: Maybe<Scalars['String']>;
};

export type PayInMethodContext = {
  __typename?: 'PayInMethodContext';
  country?: Maybe<CountryCode>;
  sourceCurrency?: Maybe<CurrencyCode>;
  targetCurrency?: Maybe<CurrencyCode>;
};

export type PayInMethodDefinition = {
  __typename?: 'PayInMethodDefinition';
  requirement?: Maybe<Array<Maybe<PayInMethodAccountRequirement>>>;
  step?: Maybe<PayInMethodDefinitionStep>;
};

export enum PayInMethodDefinitionStep {
  ACTIVATION = 'ACTIVATION',
  COLLECTING_BANK_DETAILS = 'COLLECTING_BANK_DETAILS',
  DELETED = 'DELETED',
  DOCUMENT_SIGNING = 'DOCUMENT_SIGNING',
  MANDATE_SUBMISSION = 'MANDATE_SUBMISSION',
}

export type PayInMethodFilters = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  payInMethodTypes?: InputMaybe<Array<InputMaybe<PayInMethodType>>>;
};

export type PayInMethodRequirement = {
  __typename?: 'PayInMethodRequirement';
  context?: Maybe<PayInMethodContext>;
  definition?: Maybe<Array<Maybe<PayInMethodDefinition>>>;
  id: Scalars['ID'];
  partner?: Maybe<Scalars['String']>;
  payInMethodType?: Maybe<PayInMethodType>;
};

export enum PayInMethodType {
  ACH_DIRECT_DEBIT = 'ACH_DIRECT_DEBIT',
  SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
}

export type PayInMethodWorkflowStep = {
  data: Array<Maybe<AccountDetails>>;
  step?: Maybe<PayInMethodDefinitionStep>;
};

export enum PayInStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type PaySlipFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  csmUserId?: InputMaybe<Scalars['ID']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  memberNameContains?: InputMaybe<Scalars['String']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  payrollMonth?: InputMaybe<MonthYearInput>;
  payslipId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PayslipStatus>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type PaySummary = {
  __typename?: 'PaySummary';
  absolute?: Maybe<Scalars['Float']>;
  hourly?: Maybe<Scalars['Boolean']>;
};

export type PaySupplement = {
  __typename?: 'PaySupplement';
  amount?: Maybe<Scalars['Float']>;
  amountInFunctionalCurrency?: Maybe<Scalars['Float']>;
  changes?: Maybe<Array<Maybe<PaySupplementChange>>>;
  contract?: Maybe<Contract>;
  createdDate?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  status?: Maybe<PaySupplementStatus>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<PaySupplementType>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type PaySupplement_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
};

export type PaySupplementAutoCreationFilters = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PaySupplementChange = {
  __typename?: 'PaySupplementChange';
  actionedBy?: Maybe<Person>;
  actionedOn?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<PaySupplementStatus>;
};

export type PaySupplementCreateInput = {
  amount: Scalars['Float'];
  compensationId?: InputMaybe<Scalars['ID']>;
  contractId: Scalars['ID'];
  currency?: InputMaybe<CurrencyCode>;
  description?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  title?: InputMaybe<Scalars['String']>;
  type: PaySupplementType;
};

export type PaySupplementFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatus?: InputMaybe<ContractStatus>;
  /** @deprecated Use contractTypes instead */
  contractType?: InputMaybe<ContractType>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  country?: InputMaybe<Array<CountryCode>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  paySupplementIds?: InputMaybe<Array<Scalars['ID']>>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** @deprecated Use statuses instead */
  status?: InputMaybe<PaySupplementStatus>;
  statuses?: InputMaybe<Array<PaySupplementStatus>>;
};

export type PaySupplementMemberPayable = MemberPayable & {
  __typename?: 'PaySupplementMemberPayable';
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationFrequency?: Maybe<RateFrequency>;
  durationValue?: Maybe<Scalars['Float']>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  paySupplement?: Maybe<PaySupplement>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PaySupplementMemberPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type PaySupplementMemberPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type PaySupplementMemberPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export type PaySupplementSnapshot = {
  __typename?: 'PaySupplementSnapshot';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paySupplementId: Scalars['ID'];
  status?: Maybe<PaySupplementStatus>;
  type?: Maybe<PaySupplementType>;
};

export enum PaySupplementStatus {
  CREATED = 'CREATED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  TO_BE_PAID = 'TO_BE_PAID',
}

export type PaySupplementSummary = {
  __typename?: 'PaySupplementSummary';
  paid?: Maybe<Array<Scalars['ID']>>;
  processing?: Maybe<Array<Scalars['ID']>>;
  revoked?: Maybe<Array<Scalars['ID']>>;
  toBePaid?: Maybe<Array<Scalars['ID']>>;
};

export enum PaySupplementType {
  ALLOWANCE = 'ALLOWANCE',
  BONUS = 'BONUS',
  COMMISSION = 'COMMISSION',
  OTHER = 'OTHER',
  VARIABLE_PERFORMANCE_BONUS = 'VARIABLE_PERFORMANCE_BONUS',
}

export type PaySupplementUpdateBulkResponse = {
  __typename?: 'PaySupplementUpdateBulkResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export type PaySupplementUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<CurrencyCode>;
  description?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaySupplementType>;
};

export type PaySupplementsResponse = {
  __typename?: 'PaySupplementsResponse';
  page?: Maybe<PageResult>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
};

export enum PayType {
  DYNAMIC = 'DYNAMIC',
  FIXED = 'FIXED',
}

export type PayableItem = GenericPayableCost | MemberPayrollCost;

export enum PayableItemType {
  ANNUAL_MEMBER_MANAGEMENT_FEE = 'ANNUAL_MEMBER_MANAGEMENT_FEE',
  MEMBER_DEPOSIT = 'MEMBER_DEPOSIT',
  MEMBER_GLOBAL_PAYROLL_FUNDING_COST = 'MEMBER_GLOBAL_PAYROLL_FUNDING_COST',
  MEMBER_GROSS_SALARY = 'MEMBER_GROSS_SALARY',
  MEMBER_GROSS_SALARY_REVERSAL = 'MEMBER_GROSS_SALARY_REVERSAL',
  MEMBER_INSURANCE_COST = 'MEMBER_INSURANCE_COST',
  MEMBER_MANAGEMENT_FEE = 'MEMBER_MANAGEMENT_FEE',
  MEMBER_MANAGEMENT_FEE_FOR_FREELANCER = 'MEMBER_MANAGEMENT_FEE_FOR_FREELANCER',
  MEMBER_MANAGEMENT_FEE_REVERSAL = 'MEMBER_MANAGEMENT_FEE_REVERSAL',
  MEMBER_PAYROLL_COST = 'MEMBER_PAYROLL_COST',
  MEMBER_PAYROLL_COST_FOR_FREELANCER = 'MEMBER_PAYROLL_COST_FOR_FREELANCER',
  /** @deprecated use MEMBER_GLOBAL_PAYROLL_FUNDING_COST to sync with business terms */
  MEMBER_PEO_PAYROLL_COST = 'MEMBER_PEO_PAYROLL_COST',
  MEMBER_PROCESSING_FEE_FOR_EOR = 'MEMBER_PROCESSING_FEE_FOR_EOR',
  MEMBER_PROCESSING_FEE_FOR_FREELANCER = 'MEMBER_PROCESSING_FEE_FOR_FREELANCER',
  MEMBER_PRORATED_PAYROLL_COST = 'MEMBER_PRORATED_PAYROLL_COST',
  MEMBER_TOTAL_EXPENSE_COST = 'MEMBER_TOTAL_EXPENSE_COST',
  MEMBER_TOTAL_PAYROLL_LESS_EXPENSE_COST = 'MEMBER_TOTAL_PAYROLL_LESS_EXPENSE_COST',
}

export type PayableMonthInput = {
  cycle?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export enum PayableQueryMode {
  DEFAULT = 'DEFAULT',
  DRYRUN_DETAILED = 'DRYRUN_DETAILED',
  DRYRUN_SUMMARIZED = 'DRYRUN_SUMMARIZED',
}

export enum PayableStatus {
  AUTHORIZED = 'AUTHORIZED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  VOIDED = 'VOIDED',
}

export type Payment = {
  __typename?: 'Payment';
  audit?: Maybe<Audit>;
  id: Scalars['ID'];
  status?: Maybe<PaymentStatus>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  transferValue?: Maybe<TransferValue>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  id: Scalars['ID'];
  paymentPartnerAccounts?: Maybe<Array<Maybe<PaymentPartnerAccount>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  sourceSystem?: Maybe<Scalars['String']>;
};

export type PaymentAccountPartnerRequirement = {
  __typename?: 'PaymentAccountPartnerRequirement';
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  requirementFields?: Maybe<Array<Maybe<PaymentRequirementField>>>;
};

export type PaymentAccountRequirement = {
  __typename?: 'PaymentAccountRequirement';
  accountType?: Maybe<PaymentAccountType>;
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  requirementFields?: Maybe<Array<Maybe<RequirementField>>>;
  sourceCurrency?: Maybe<CurrencyCode>;
  targetCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export enum PaymentAccountType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

export type PaymentBundle = {
  __typename?: 'PaymentBundle';
  bankAccount?: Maybe<BankAccount>;
  company?: Maybe<Company>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  id: Scalars['ID'];
  instructionFile?: Maybe<DocumentReadable>;
  items: Array<PaymentBundleItem>;
  payInInfo?: Maybe<PayInInfo>;
  paymentAdvices?: Maybe<Array<DocumentReadable>>;
  paymentMethodInfo?: Maybe<PaymentMethodInfo>;
  referenceId?: Maybe<Scalars['String']>;
  sourceType?: Maybe<PaymentBundleSourceType>;
  status?: Maybe<PaymentBundleStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PaymentBundleCreateInput = {
  bundleItems?: InputMaybe<Array<PaymentBundleItemInput>>;
  companyId?: InputMaybe<Scalars['ID']>;
  payInMethodId?: InputMaybe<Scalars['ID']>;
  paymentMethod: PaymentMethod;
  source?: InputMaybe<PaymentBundleSourceType>;
};

export type PaymentBundleFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  createdDateRange?: InputMaybe<DateRange>;
  /** Whether bundles of test companies should be returned. By default only non-test are respected */
  includesTestCompanies?: InputMaybe<Scalars['Boolean']>;
  paymentBundleId?: InputMaybe<Scalars['ID']>;
  referenceId?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<PaymentBundleSourceType>;
};

export type PaymentBundleItem = {
  __typename?: 'PaymentBundleItem';
  companyPayable?: Maybe<CompanyPayable>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<PaymentBundleItemType>;
};

export type PaymentBundleItemInput = {
  id: Scalars['ID'];
  type: PaymentBundleItemType;
};

export enum PaymentBundleItemType {
  COMPANY_PAYABLE = 'COMPANY_PAYABLE',
  FREELANCER_PAYABLE = 'FREELANCER_PAYABLE',
}

export type PaymentBundlePreview = {
  __typename?: 'PaymentBundlePreview';
  currency?: Maybe<CurrencyCode>;
  items: Array<PaymentBundlePreviewItem>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type PaymentBundlePreviewItem = {
  __typename?: 'PaymentBundlePreviewItem';
  billingAmount?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['ID']>;
  item?: Maybe<PaymentBundlePreviewItemPayable>;
  managementFee?: Maybe<Scalars['Float']>;
  payoutProcessingFee?: Maybe<Scalars['Float']>;
  type?: Maybe<PaymentBundleItemType>;
};

export type PaymentBundlePreviewItemInput = {
  id: Scalars['ID'];
  type: PaymentBundleItemType;
};

export type PaymentBundlePreviewItemPayable =
  | CompanyPayable
  | ExpenseMemberPayable
  | FreelancerPayable
  | PaySupplementMemberPayable;

export enum PaymentBundleSourceType {
  AUTO_DEBIT_SERVICE = 'AUTO_DEBIT_SERVICE',
  COMPANY_EXPERIENCE = 'COMPANY_EXPERIENCE',
}

export enum PaymentBundleStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
}

export type PaymentBundleUpdateInput = {
  id: Scalars['ID'];
  payInMethodId?: InputMaybe<Scalars['ID']>;
  paymentMethod: PaymentMethod;
};

export type PaymentExecutionInfo = {
  __typename?: 'PaymentExecutionInfo';
  executionType?: Maybe<Scalars['String']>;
};

export enum PaymentInstructionErrorLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum PaymentInstructionErrorType {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export type PaymentInstructionFileGenerateData = {
  contractIDs: Array<InputMaybe<Scalars['ID']>>;
  overrideInfo?: InputMaybe<OverrideInfo>;
  paymentExecutionDate?: InputMaybe<Scalars['Date']>;
  paymentFileSubType?: InputMaybe<Scalars['String']>;
  payrollDate?: InputMaybe<Scalars['Date']>;
};

export type PaymentInstructionFileGenerateInput = {
  bank: BankCode;
  contractIDs: Array<InputMaybe<Scalars['ID']>>;
  countryCode: CountryCode;
  overrideInfo?: InputMaybe<OverrideInfo>;
  paymentExecutionDate?: InputMaybe<Scalars['Date']>;
  payrollDate?: InputMaybe<Scalars['Date']>;
};

export type PaymentInstructionFileGenerationReport = {
  __typename?: 'PaymentInstructionFileGenerationReport';
  errors?: Maybe<Array<Maybe<FileGenerationErrors>>>;
  file?: Maybe<Document>;
  pifId: Scalars['ID'];
  status: PaymentInstructionStatus;
};

export enum PaymentInstructionFileType {
  ISO20022XML = 'ISO20022XML',
  ISO20022XML_DIRECT_DEBIT = 'ISO20022XML_DIRECT_DEBIT',
  NACHA_DEBIT = 'NACHA_DEBIT',
  NACHA_PRENOTE = 'NACHA_PRENOTE',
}

export enum PaymentInstructionStatus {
  FILE_GENERATED = 'FILE_GENERATED',
  FILE_GENERATION_FAILED = 'FILE_GENERATION_FAILED',
  FILE_GENERATION_IN_PROGRESS = 'FILE_GENERATION_IN_PROGRESS',
}

export enum PaymentMethod {
  ACH_DIRECT_DEBIT = 'ACH_DIRECT_DEBIT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CARD = 'CARD',
  CHEQUE = 'CHEQUE',
  CRYPTO = 'CRYPTO',
  SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
}

export type PaymentMethodInfo = {
  __typename?: 'PaymentMethodInfo';
  autoDebitInfo?: Maybe<AutoDebitInfo>;
  bundleId: Scalars['ID'];
  /** @deprecated No longer supported */
  fee?: Maybe<Scalars['Float']>;
  payInMethodId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<PaymentMethod>;
  processingFee?: Maybe<ProcessingFee>;
  processingFeePercentage?: Maybe<Scalars['Float']>;
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  accountType?: Maybe<PaymentAccountType>;
  partnerAccountRequirements?: Maybe<PartnerAccountRequirement>;
  payOutCountry?: Maybe<CountryCode>;
  payOutCurrency?: Maybe<CurrencyCode>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  sourceCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export type PaymentOptionFilters = {
  contractType?: InputMaybe<ContractType>;
  paymentPartners?: InputMaybe<Array<InputMaybe<PaymentPartnerCode>>>;
  taxResidency: CountryCode;
};

export type PaymentOptionInfo = {
  __typename?: 'PaymentOptionInfo';
  partnerSupport?: Maybe<PartnerSupportInfo>;
  paymentCountry?: Maybe<CountryCode>;
  paymentCurrency?: Maybe<CurrencyCode>;
};

export type PaymentOptions = {
  __typename?: 'PaymentOptions';
  contractType?: Maybe<ContractType>;
  options?: Maybe<Array<Maybe<PaymentOptionInfo>>>;
  taxResidency?: Maybe<CountryCode>;
};

export type PaymentPartner = {
  __typename?: 'PaymentPartner';
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paymentPartnerType?: Maybe<PaymentPartnerType>;
};

export type PaymentPartnerAccount = {
  __typename?: 'PaymentPartnerAccount';
  accountType?: Maybe<PaymentAccountType>;
  beneficaryID?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentAccount?: Maybe<PaymentAccount>;
  paymentPartner?: Maybe<PaymentPartner>;
  transferType?: Maybe<TransferType>;
};

export enum PaymentPartnerCode {
  HSBC = 'HSBC',
  WISE = 'WISE',
}

export enum PaymentPartnerType {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
}

export enum PaymentPriorityType {
  NON_URGENT_PAYMENT = 'NON_URGENT_PAYMENT',
  URGENT_PAYMENT = 'URGENT_PAYMENT',
}

export type PaymentProcessingMethod = {
  __typename?: 'PaymentProcessingMethod';
  automated?: Maybe<Scalars['Boolean']>;
  executionInfo?: Maybe<PaymentExecutionInfo>;
  paymentSLA?: Maybe<PaymentSla>;
};

export type PaymentRequirementAllowedValue = {
  __typename?: 'PaymentRequirementAllowedValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PaymentRequirementField = {
  __typename?: 'PaymentRequirementField';
  allowedValues?: Maybe<Array<Maybe<PaymentRequirementAllowedValue>>>;
  errorMessage?: Maybe<Scalars['String']>;
  hasSubFields?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  regex?: Maybe<Scalars['String']>;
  subFields?: Maybe<Array<Maybe<PaymentRequirementSubField>>>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentRequirementSubField = {
  __typename?: 'PaymentRequirementSubField';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<PaymentRequirementField>>>;
};

export type PaymentSla = {
  __typename?: 'PaymentSLA';
  cost?: Maybe<Amount>;
  estimatedDeliveryTime?: Maybe<Duration>;
};

export enum PaymentStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  NOT_WHITELISTED = 'NOT_WHITELISTED',
  PROCESSING = 'PROCESSING',
}

export type PaymentTerm = {
  __typename?: 'PaymentTerm';
  interval: Scalars['Int'];
  planId: Scalars['ID'];
  timeUnit: PaymentTermTimeUnit;
};

export type PaymentTermInput = {
  interval: Scalars['Int'];
  timeUnit: PaymentTermTimeUnit;
};

export enum PaymentTermTimeUnit {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type Payroll = {
  amountTotalCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  memberPays?: Maybe<Array<Maybe<MemberPay>>>;
  month?: Maybe<PayrollMonth>;
};

export type PayrollBulkUpload = {
  __typename?: 'PayrollBulkUpload';
  bulkUploadSummary?: Maybe<PayrollBulkUploadSummary>;
  errors?: Maybe<Array<PayrollBulkUploadError>>;
  inputFile: PayrollFile;
  paySupplements?: Maybe<Array<PaySupplement>>;
};

export enum PayrollBulkUploadConfirmStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type PayrollBulkUploadError = {
  __typename?: 'PayrollBulkUploadError';
  contractId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
};

export type PayrollBulkUploadItemCount = {
  __typename?: 'PayrollBulkUploadItemCount';
  employee: Scalars['Int'];
  error: Scalars['Int'];
  itemType: BulkUploadItemType;
  success: Scalars['Int'];
  warn: Scalars['Int'];
};

export type PayrollBulkUploadSummary = {
  __typename?: 'PayrollBulkUploadSummary';
  counts?: Maybe<Array<PayrollBulkUploadItemCount>>;
  /** @deprecated already exists inside counts field */
  totalExpenses?: Maybe<Scalars['Int']>;
  /** @deprecated already exists inside counts field */
  totalPaySupplements?: Maybe<Scalars['Int']>;
  /** @deprecated already exists inside counts field */
  totalTimeOff?: Maybe<Scalars['Int']>;
};

export type PayrollCalendar = {
  __typename?: 'PayrollCalendar';
  approvePayrollInputDeadlineRelativeToCutOff?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  approvePayrollReportDeadlineRelativeToEndDate?: Maybe<Scalars['Int']>;
  approvePayrollReportDeadlineRelativeToPayDate?: Maybe<Scalars['Int']>;
  compensationDateRelativeToEndDate: Scalars['Int'];
  contractEndDateRelativeToEndDate: Scalars['Int'];
  contractStartDateRelativeToEndDate: Scalars['Int'];
  cutOffRelativeToPayDate: Scalars['Int'];
  deductionDateRelativeToEndDate: Scalars['Int'];
  expenseDateRelativeToEndDate: Scalars['Int'];
  memberChangesDateRelativeToEndDate: Scalars['Int'];
  payDateRelativeToEndDate: Scalars['Int'];
  paySupplementDateRelativeToEndDate: Scalars['Int'];
  payrollCompleteDeadlineRelativeToEndDate?: Maybe<Scalars['Int']>;
  payrollProcessingDeadlineRelativeToEndDate?: Maybe<Scalars['Int']>;
  snapCutOffDates: Scalars['Boolean'];
  snapDeadlineDates?: Maybe<Scalars['Boolean']>;
  snapPayDate: Scalars['Boolean'];
  snapToDates: Scalars['Boolean'];
  startDate: Scalars['Date'];
  submitPayrollInputDeadlineRelativeToCutOff?: Maybe<Scalars['Int']>;
  timeOffDateRelativeToEndDate: Scalars['Int'];
  timeSheetDateRelativeToEndDate: Scalars['Int'];
};

export type PayrollCalendarFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyPayrollCycleId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  payFrequency: PayFrequency;
  payrollCycleEntityTypes?: InputMaybe<Array<PayrollCycleEntityType>>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  payrollPartnerId?: InputMaybe<Scalars['ID']>;
};

export type PayrollCalendarInput = {
  approvePayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  approvePayrollReportDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  approvePayrollReportDeadlineRelativeToPayDate?: InputMaybe<Scalars['Int']>;
  compensationDateRelativeToEndDate: Scalars['Int'];
  contractEndDateRelativeToEndDate: Scalars['Int'];
  contractStartDateRelativeToEndDate: Scalars['Int'];
  cutOffRelativeToPayDate: Scalars['Int'];
  deductionDateRelativeToEndDate: Scalars['Int'];
  expenseDateRelativeToEndDate: Scalars['Int'];
  memberChangesDateRelativeToEndDate: Scalars['Int'];
  payDateRelativeToEndDate: Scalars['Int'];
  paySupplementDateRelativeToEndDate: Scalars['Int'];
  payrollCompleteDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payrollProcessingDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  snapCutOffDates?: InputMaybe<Scalars['Boolean']>;
  snapDeadlineDates?: InputMaybe<Scalars['Boolean']>;
  snapPayDate?: InputMaybe<Scalars['Boolean']>;
  snapToDates?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
  submitPayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  timeOffDateRelativeToEndDate: Scalars['Int'];
  timeSheetDateRelativeToEndDate: Scalars['Int'];
};

export type PayrollConfigEntityInput = {
  country: CountryCode;
  payrollCycleEntityId?: InputMaybe<Scalars['ID']>;
  payrollPartnerCountryId: Scalars['ID'];
  title: Scalars['String'];
};

export type PayrollConsolidatedReportDownloadResponse = FileDownload & {
  __typename?: 'PayrollConsolidatedReportDownloadResponse';
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated use `payrollFile` instead */
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PayrollContract = {
  __typename?: 'PayrollContract';
  employeeId?: Maybe<Scalars['String']>;
  input?: Maybe<PayrollInputContract>;
  name: Scalars['String'];
  position: Scalars['String'];
  status: ContractStatus;
};

export type PayrollContractException = {
  __typename?: 'PayrollContractException';
  contractId?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['DateTime']>;
  exceptionType?: Maybe<PayrollContractExceptionType>;
  id?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  reasonCategory?: Maybe<PayrollContractExceptionReasonCategory>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollContractExceptionInput = {
  contractId: Scalars['ID'];
  exceptionType?: InputMaybe<PayrollContractExceptionType>;
  payrollCycleId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  reasonCategory?: InputMaybe<PayrollContractExceptionReasonCategory>;
};

export enum PayrollContractExceptionReasonCategory {
  DID_NOT_JOIN = 'DID_NOT_JOIN',
  ELIGIBLE_BEFORE_ACTIVE = 'ELIGIBLE_BEFORE_ACTIVE',
  END_DATE_CHANGED = 'END_DATE_CHANGED',
  MISSED_CUTOFF = 'MISSED_CUTOFF',
  OFFBOARDING_CANCELLED = 'OFFBOARDING_CANCELLED',
  OTHER = 'OTHER',
  PRE_REGISTRATION = 'PRE_REGISTRATION',
  START_DATE_CHANGED = 'START_DATE_CHANGED',
}

export type PayrollContractExceptionReasonCategoryRequest = {
  contractSnapshotId: Scalars['ID'];
  type: PayrollContractType;
};

export enum PayrollContractExceptionType {
  ACTIVE_EXCLUDE = 'ACTIVE_EXCLUDE',
  ACTIVE_INCLUDE = 'ACTIVE_INCLUDE',
  NEW_HIRE_EXCLUDE = 'NEW_HIRE_EXCLUDE',
  NEW_HIRE_INCLUDE = 'NEW_HIRE_INCLUDE',
  TERMINATION_EXCLUDE = 'TERMINATION_EXCLUDE',
  TERMINATION_INCLUDE = 'TERMINATION_INCLUDE',
}

export type PayrollContractFilter = {
  contractId: Scalars['ID'];
  payrollCycleId: Scalars['ID'];
};

export enum PayrollContractType {
  ACTIVE = 'ACTIVE',
  NEW_HIRE = 'NEW_HIRE',
  TERMINATION = 'TERMINATION',
}

export type PayrollCountryEntityType = {
  __typename?: 'PayrollCountryEntityType';
  countryCode: CountryCode;
  entityType: PayrollCycleEntityType;
};

export type PayrollCycle = {
  __typename?: 'PayrollCycle';
  approvePayrollInputDeadline?: Maybe<Scalars['Date']>;
  approvePayrollReportDeadline?: Maybe<Scalars['Date']>;
  bulkUpload?: Maybe<PayrollBulkUpload>;
  compensationDateFrom?: Maybe<Scalars['Date']>;
  compensationDateTo?: Maybe<Scalars['Date']>;
  configId?: Maybe<Scalars['ID']>;
  contractEndDateFrom?: Maybe<Scalars['Date']>;
  contractEndDateTo?: Maybe<Scalars['Date']>;
  contractStartDateFrom?: Maybe<Scalars['Date']>;
  contractStartDateTo?: Maybe<Scalars['Date']>;
  contracts?: Maybe<Array<PayrollContract>>;
  country?: Maybe<CountryCode>;
  createdBy?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  cutOffFrom?: Maybe<Scalars['Date']>;
  cutOffTo?: Maybe<Scalars['Date']>;
  cycleType?: Maybe<PayrollCycleType>;
  deductionFrom?: Maybe<Scalars['Date']>;
  deductionTo?: Maybe<Scalars['Date']>;
  defaultApprovePayrollReportDeadline?: Maybe<Scalars['Date']>;
  defaultCutOffTo?: Maybe<Scalars['Date']>;
  defaultEndDate?: Maybe<Scalars['Date']>;
  defaultPayDate?: Maybe<Scalars['Date']>;
  defaultStartDate?: Maybe<Scalars['Date']>;
  employeeDataChanges?: Maybe<EmployeeDataChanges>;
  employeeDataChangesV2?: Maybe<EmployeeDataChangesV2>;
  endDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<PayrollCycleEntity>;
  expenseFrom?: Maybe<Scalars['Date']>;
  expenseSummary?: Maybe<ExpenseSummary>;
  expenseTo?: Maybe<Scalars['Date']>;
  frequency?: Maybe<PayFrequency>;
  id: Scalars['ID'];
  inputHeadCountSummaryV2?: Maybe<InputHeadcountSummaryV2>;
  inputHeadcountSummary?: Maybe<InputHeadcountSummary>;
  memberChangesFrom?: Maybe<Scalars['Date']>;
  memberChangesTo?: Maybe<Scalars['Date']>;
  offCycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  /** @deprecated No longer supported */
  oneTimePaymentDateFrom?: Maybe<Scalars['Date']>;
  /** @deprecated No longer supported */
  oneTimePaymentDateTo?: Maybe<Scalars['Date']>;
  opsPayslipUploadWorkflow?: Maybe<OpsPayslipUploadWorkflow>;
  outputHeadcountSummary?: Maybe<OutputHeadcountSummary>;
  outputVariance?: Maybe<PayrollOutputDataVariance>;
  payDate?: Maybe<Scalars['Date']>;
  paySupplementFrom?: Maybe<Scalars['Date']>;
  paySupplementSummary?: Maybe<PaySupplementSummary>;
  paySupplementTo?: Maybe<Scalars['Date']>;
  payrollCompleteDeadline?: Maybe<Scalars['Date']>;
  payrollInput?: Maybe<FileDownload>;
  payrollMonth?: Maybe<MonthYear>;
  payrollOutputFile?: Maybe<PayrollReportUpload>;
  payrollOutputSummary?: Maybe<PayrollOutputSummary>;
  payrollProcessingDeadline?: Maybe<Scalars['Date']>;
  payslipSummary?: Maybe<PayslipSummary>;
  payslipUploadDetails?: Maybe<PayslipUploadDetails>;
  payslips?: Maybe<Array<Payslip>>;
  previousCycle?: Maybe<PayrollCycle>;
  primaryCycle?: Maybe<PayrollCycle>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<PayrollCycleStatus>;
  submitPayrollInputDeadline?: Maybe<Scalars['Date']>;
  timeOffFrom?: Maybe<Scalars['Date']>;
  timeOffTo?: Maybe<Scalars['Date']>;
  timeSheetDateFrom?: Maybe<Scalars['Date']>;
  timeSheetDateTo?: Maybe<Scalars['Date']>;
  workflow?: Maybe<PayrollCycleWorkflow>;
};

export type PayrollCycleBulkCreateInput = {
  configIds: Array<Scalars['ID']>;
  untilDate: Scalars['Date'];
};

export type PayrollCycleConfig = {
  __typename?: 'PayrollCycleConfig';
  calendar?: Maybe<PayrollCalendar>;
  country?: Maybe<CountryCode>;
  frequency?: Maybe<PayFrequency>;
  id?: Maybe<Scalars['ID']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  payrollCycleByReferenceDate?: Maybe<PayrollCycle>;
  payrollCycleEntity?: Maybe<PayrollCycleEntity>;
  payrollCycles: Array<PayrollCycle>;
  payrollPartnerCountry?: Maybe<PayrollPartnerCountry>;
  title: Scalars['String'];
  type?: Maybe<PayrollCycleEntityType>;
};

export type PayrollCycleConfig_PayrollCycleByReferenceDateArgs = {
  domain: PayrollInputDomainType;
  referenceDate: Scalars['Date'];
  returnClosestToReferenceDateUpcomingCutOff: Scalars['Boolean'];
};

export type PayrollCycleConfig_PayrollCyclesArgs = {
  pageNumber: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type PayrollCycleConfigInput = {
  calendar: PayrollCalendarInput;
  country: CountryCode;
  frequency: PayFrequency;
  isDefault: Scalars['Boolean'];
  payrollCycleEntityId?: InputMaybe<Scalars['ID']>;
  payrollPartnerCountryId: Scalars['ID'];
  title: Scalars['String'];
  type: PayrollCycleEntityType;
};

export enum PayrollCycleConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type PayrollCycleConfigUpdateInput = {
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PayrollCycleConfigStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type PayrollCycleConfigsFilter = {
  countries?: InputMaybe<Array<CountryCode>>;
  payFrequency?: InputMaybe<PayFrequency>;
  payrollCycleEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<PayrollCycleEntityType>>;
};

export type PayrollCycleConfigsResponse = {
  __typename?: 'PayrollCycleConfigsResponse';
  configs?: Maybe<Array<PayrollCycleConfig>>;
};

export type PayrollCycleEntity = {
  __typename?: 'PayrollCycleEntity';
  company?: Maybe<Company>;
  entityType?: Maybe<PayrollCycleEntityType>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum PayrollCycleEntityType {
  EOR = 'EOR',
  EOR_PARTNER = 'EOR_PARTNER',
  GLOBAL_PAYROLL = 'GLOBAL_PAYROLL',
  PEO = 'PEO',
}

export type PayrollCycleFilter = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  createdBy?: InputMaybe<Scalars['ID']>;
  dateRange?: InputMaybe<DateRange>;
  entityId?: InputMaybe<Scalars['ID']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedStatuses?: InputMaybe<Array<PayrollCycleStatus>>;
  frequencies?: InputMaybe<Array<PayFrequency>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** @deprecated No longer supported */
  partnerName?: InputMaybe<Scalars['String']>;
  payDate?: InputMaybe<DateRange>;
  /** @deprecated Use payrollCycleEntityTypes instead */
  payrollCycleEntityType?: InputMaybe<PayrollCycleEntityType>;
  payrollCycleEntityTypes?: InputMaybe<Array<PayrollCycleEntityType>>;
  payrollCycleTypes?: InputMaybe<Array<PayrollCycleType>>;
  payrollMonth?: InputMaybe<MonthYearInput>;
  primaryCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  statuses?: InputMaybe<Array<PayrollCycleStatus>>;
};

export type PayrollCycleInput = {
  configId: Scalars['ID'];
  entityId?: InputMaybe<Scalars['ID']>;
  payrollMonth: MonthYearInput;
};

export type PayrollCyclePayment = {
  __typename?: 'PayrollCyclePayment';
  id: Scalars['ID'];
  payrollCycle?: Maybe<PayrollCycle>;
  payrollPaymentsPaginated?: Maybe<PayrollPaymentResult>;
  status?: Maybe<PayrollCyclePaymentStatus>;
  type?: Maybe<PayrollCyclePaymentType>;
};

export type PayrollCyclePayment_PayrollPaymentsPaginatedArgs = {
  filters?: InputMaybe<PayrollPaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type PayrollCyclePaymentFilters = {
  country?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payrollCycleId?: InputMaybe<Scalars['String']>;
  payrollMonth?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Array<InputMaybe<PayrollCyclePaymentStatus>>>;
  types?: InputMaybe<Array<InputMaybe<PayrollCyclePaymentType>>>;
};

export type PayrollCyclePaymentResult = {
  __typename?: 'PayrollCyclePaymentResult';
  data?: Maybe<Array<Maybe<PayrollCyclePayment>>>;
  pageResult?: Maybe<PageResult>;
};

export enum PayrollCyclePaymentStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  VOID = 'VOID',
}

export enum PayrollCyclePaymentType {
  EOR = 'EOR',
  PEO = 'PEO',
}

export enum PayrollCycleStatus {
  CLOSED = 'CLOSED',
  CLOSE_PAYROLL = 'CLOSE_PAYROLL',
  CREATED = 'CREATED',
  CUSTOMER_REVIEW = 'CUSTOMER_REVIEW',
  INPUT_IN_CONFIRMATION = 'INPUT_IN_CONFIRMATION',
  INPUT_IN_PROGRESS = 'INPUT_IN_PROGRESS',
  OPS_RECONCILIATION = 'OPS_RECONCILIATION',
  OUTPUT_APPROVED = 'OUTPUT_APPROVED',
  PARTNER_RECONCILIATION = 'PARTNER_RECONCILIATION',
  PAYSLIPS_CONFIRMED = 'PAYSLIPS_CONFIRMED',
  PAYSLIPS_UPLOAD = 'PAYSLIPS_UPLOAD',
  SENT_FOR_REVIEW = 'SENT_FOR_REVIEW',
  SENT_TO_PARTNER = 'SENT_TO_PARTNER',
  SUSPENDED = 'SUSPENDED',
}

export enum PayrollCycleStep {
  CLOSURE = 'CLOSURE',
  INITIATION = 'INITIATION',
  INPUT_GENERATION = 'INPUT_GENERATION',
  PAYROLL_PROCESSING = 'PAYROLL_PROCESSING',
  /** @deprecated It's merged with CLOSURE */
  PAY_SLIPS = 'PAY_SLIPS',
}

export type PayrollCycleToDates = {
  approvePayrollInputDeadline?: InputMaybe<Scalars['Date']>;
  approvePayrollReportDeadline?: InputMaybe<Scalars['Date']>;
  compensationDateTo?: InputMaybe<Scalars['Date']>;
  contractEndDateTo?: InputMaybe<Scalars['Date']>;
  contractStartDateTo?: InputMaybe<Scalars['Date']>;
  cutOffTo?: InputMaybe<Scalars['Date']>;
  deductionTo?: InputMaybe<Scalars['Date']>;
  expenseTo?: InputMaybe<Scalars['Date']>;
  memberChangesTo?: InputMaybe<Scalars['Date']>;
  payDate?: InputMaybe<Scalars['Date']>;
  paySupplementTo?: InputMaybe<Scalars['Date']>;
  payrollCompleteDeadline?: InputMaybe<Scalars['Date']>;
  payrollProcessingDeadline?: InputMaybe<Scalars['Date']>;
  submitPayrollInputDeadline?: InputMaybe<Scalars['Date']>;
  timeOffTo?: InputMaybe<Scalars['Date']>;
  timeSheetDateTo?: InputMaybe<Scalars['Date']>;
};

export type PayrollCycleToDatesInput = {
  cycleId: Scalars['ID'];
  payrollCycleToDates: PayrollCycleToDates;
};

export enum PayrollCycleType {
  OFF_CYCLE = 'OFF_CYCLE',
  PRIMARY_CYCLE = 'PRIMARY_CYCLE',
}

export type PayrollCycleUpdateInput = {
  payrollCycleToDatesInput: Array<PayrollCycleToDatesInput>;
};

export type PayrollCycleWorkflow = {
  __typename?: 'PayrollCycleWorkflow';
  allSteps?: Maybe<Array<Maybe<PayrollCycleStep>>>;
  currentStep?: Maybe<PayrollCycleStep>;
};

export type PayrollCyclesResponse = {
  __typename?: 'PayrollCyclesResponse';
  cycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  page?: Maybe<PageResult>;
};

export type PayrollDocument = {
  __typename?: 'PayrollDocument';
  downloadUrl: Scalars['String'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type PayrollDocumentsResponse = {
  __typename?: 'PayrollDocumentsResponse';
  documents?: Maybe<Array<PayrollDocument>>;
};

export type PayrollFile = {
  __typename?: 'PayrollFile';
  contentType?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  downloadUrl?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type PayrollFileDownloadResponse = FileDownload & {
  __typename?: 'PayrollFileDownloadResponse';
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated use `payrollFile` instead */
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum PayrollFileDownloadStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  RECEIVED = 'RECEIVED',
  TIMED_OUT = 'TIMED_OUT',
}

export enum PayrollFileDownloadType {
  BULK_UPLOAD_ERROR_REPORT = 'BULK_UPLOAD_ERROR_REPORT',
  COMPANY_PAYROLL_INPUT = 'COMPANY_PAYROLL_INPUT',
  COMPANY_PAYROLL_INPUT_VARIANCE = 'COMPANY_PAYROLL_INPUT_VARIANCE',
  INPUT_FILE = 'INPUT_FILE',
  YEAR_TO_DATE_REPORT = 'YEAR_TO_DATE_REPORT',
}

export type PayrollFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  /** Wildcard search */
  companyName?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  frequency?: InputMaybe<PayFrequency>;
  /** Wildcard search */
  memberName?: InputMaybe<Scalars['String']>;
  payDateRange?: InputMaybe<DateRange>;
  payPeriodDateRange?: InputMaybe<DateRange>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  /** this filter allow client to filter by month and year */
  payrollMonth?: InputMaybe<PayrollMonthInput>;
  payrollMonths?: InputMaybe<PayrollMonthRangeInput>;
};

export enum PayrollFormsUploadStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
}

export enum PayrollInputAuditTrailEntityType {
  EXPENSE = 'EXPENSE',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
}

export type PayrollInputAuditTrailsRemoveInput = {
  entityIds: Array<Scalars['ID']>;
  entityType: PayrollInputAuditTrailEntityType;
};

export type PayrollInputContract = {
  __typename?: 'PayrollInputContract';
  additionalAllowances?: Maybe<Array<PayDetail>>;
  baseSalaryBreakdowns?: Maybe<Array<PayDetail>>;
  currency: CurrencyCode;
  deductions?: Maybe<Array<PayDetail>>;
  employeeId?: Maybe<Scalars['String']>;
  expenses?: Maybe<Array<PayDetail>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  paySupplements?: Maybe<Array<PayDetail>>;
  status: ContractStatus;
  totalAdditionalAllowances?: Maybe<PaySummary>;
  totalBaseSalary?: Maybe<Scalars['Float']>;
  totalDeductions?: Maybe<Scalars['Float']>;
  totalExpenses?: Maybe<Scalars['Float']>;
  totalPaySupplements?: Maybe<Scalars['Float']>;
  totalTimeOffs?: Maybe<Scalars['Float']>;
};

export enum PayrollInputDomainType {
  COMPANY_UPLOADED_DEDUCTION = 'COMPANY_UPLOADED_DEDUCTION',
  COMPENSATION = 'COMPENSATION',
  CONTRACT = 'CONTRACT',
  CONTRACT_ONBOARDING = 'CONTRACT_ONBOARDING',
  DATA_CHANGE = 'DATA_CHANGE',
  DEDUCTION = 'DEDUCTION',
  EXPENSE = 'EXPENSE',
  MEMBER = 'MEMBER',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY_REVISION = 'SALARY_REVISION',
  TIMESHEET = 'TIMESHEET',
  TIME_OFF = 'TIME_OFF',
}

/** In DB, `notes` are different for every combination of (countryCode, domain, experience)  */
export type PayrollInputNote = {
  __typename?: 'PayrollInputNote';
  countryCode: CountryCode;
  domain: PayrollInputNoteDomain;
  experience: Persona;
  id?: Maybe<Scalars['ID']>;
  notes: Array<Scalars['String']>;
};

/** For now only applies to expense, but later can be in freelancer invoice, time off etc... */
export enum PayrollInputNoteDomain {
  EXPENSE = 'EXPENSE',
}

export type PayrollMonth = {
  __typename?: 'PayrollMonth';
  cycle?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type PayrollMonthInput = {
  cycle?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type PayrollMonthRangeInput = {
  from: PayrollMonthInput;
  to: PayrollMonthInput;
};

export type PayrollOffCycleCreateInput = {
  cutOffToDate: Scalars['Date'];
  payDate: Scalars['Date'];
  primaryPayrollCycleId: Scalars['ID'];
};

export type PayrollOutputDataVariance = {
  __typename?: 'PayrollOutputDataVariance';
  employeeDeductionsVariance?: Maybe<Scalars['Float']>;
  employerContributionsVariance?: Maybe<Scalars['Float']>;
  expensesVariance?: Maybe<Scalars['Float']>;
  grossSalaryVariance?: Maybe<Scalars['Float']>;
  netPayVariance?: Maybe<Scalars['Float']>;
  totalPayrollCostVariance?: Maybe<Scalars['Float']>;
};

export type PayrollOutputSummary = {
  __typename?: 'PayrollOutputSummary';
  employeeDeductions: Scalars['Float'];
  employerContributions: Scalars['Float'];
  expenses: Scalars['Float'];
  grossSalary: Scalars['Float'];
  netPay: Scalars['Float'];
  totalPayrollCost: Scalars['Float'];
};

export type PayrollParserConfig = {
  __typename?: 'PayrollParserConfig';
  configString?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PayrollParserConfigFilter = {
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollParserConfigInput = {
  configString?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollPartnerCountry = PartnerCountry & {
  __typename?: 'PayrollPartnerCountry';
  capability?: Maybe<PartnerCapability>;
  contracts?: Maybe<Array<Maybe<ContractPayrollPartner>>>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  operator?: Maybe<Array<Maybe<PartnerUser>>>;
  partner?: Maybe<Partner>;
  payroll?: Maybe<Array<Maybe<PartnerPayroll>>>;
};

export type PayrollPartnerCountry_ContractsArgs = {
  status?: InputMaybe<ContractPayrollPartnerStatus>;
};

export type PayrollPartnerCountry_PayrollArgs = {
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type PayrollPartnerCountryInput = {
  capability: PartnerCapability;
  countryCode: CountryCode;
  currency?: InputMaybe<CurrencyCode>;
};

export type PayrollPayment = {
  __typename?: 'PayrollPayment';
  contract?: Maybe<Contract>;
  firstInvoiceStatus?: Maybe<InvoiceStatus>;
  id: Scalars['ID'];
  paidOn?: Maybe<Scalars['DateTime']>;
  payrollCyclePayment?: Maybe<PayrollCyclePayment>;
  payrollPaymentInfo?: Maybe<PayrollPaymentInfo>;
  payrollPaymentStatus?: Maybe<PayrollPaymentStatus>;
  payrollPaymentType?: Maybe<PayrollPaymentType>;
};

export type PayrollPaymentBatch = {
  createdOn?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<Array<Maybe<PayrollPaymentBatchError>>>;
  id: Scalars['ID'];
  payrollPaymentBatchName?: Maybe<Scalars['String']>;
  payrollPaymentBatchPaymentPriorityType?: Maybe<PaymentPriorityType>;
  payrollPaymentBatchPayoutDate?: Maybe<Scalars['DateTime']>;
  payrollPaymentBatchStatus?: Maybe<PayrollPaymentBatchStatus>;
  payrollPaymentBatchType?: Maybe<PayrollPaymentBatchType>;
  payrollPaymentsPaginated?: Maybe<PayrollPaymentResult>;
};

export type PayrollPaymentBatch_PayrollPaymentsPaginatedArgs = {
  filters?: InputMaybe<PayrollPaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type PayrollPaymentBatchError = {
  __typename?: 'PayrollPaymentBatchError';
  errorType?: Maybe<PayrollPaymentBatchErrorType>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  payrollPayment?: Maybe<PayrollPayment>;
};

export enum PayrollPaymentBatchErrorType {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export type PayrollPaymentBatchFilters = {
  batchName?: InputMaybe<Scalars['String']>;
  batchStatuses?: InputMaybe<Array<InputMaybe<PayrollPaymentBatchStatus>>>;
  batchStepStatusFilters?: InputMaybe<
    Array<InputMaybe<PayrollPaymentBatchStepStatusFilter>>
  >;
  payrollCyclePaymentId?: InputMaybe<Scalars['ID']>;
};

export type PayrollPaymentBatchInput = {
  payoutDate: Scalars['Date'];
  payrollPayments: Array<InputMaybe<Scalars['ID']>>;
  priority?: InputMaybe<PaymentPriorityType>;
};

export type PayrollPaymentBatchResult = {
  __typename?: 'PayrollPaymentBatchResult';
  data?: Maybe<Array<Maybe<PayrollPaymentBatch>>>;
  pageResult?: Maybe<PageResult>;
};

export enum PayrollPaymentBatchStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
}

export enum PayrollPaymentBatchStep {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  EXECUTE = 'EXECUTE',
}

export type PayrollPaymentBatchStepStatusFilter = {
  batchStatuses: Array<InputMaybe<PayrollPaymentBatchStatus>>;
  batchStep: PayrollPaymentBatchStep;
};

export enum PayrollPaymentBatchType {
  FILE = 'FILE',
}

export type PayrollPaymentFileBatch = PayrollPaymentBatch & {
  __typename?: 'PayrollPaymentFileBatch';
  batchFile?: Maybe<DocumentReadable>;
  createdOn?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<Array<Maybe<PayrollPaymentBatchError>>>;
  id: Scalars['ID'];
  payrollPaymentBatchName?: Maybe<Scalars['String']>;
  payrollPaymentBatchPaymentPriorityType?: Maybe<PaymentPriorityType>;
  payrollPaymentBatchPayoutDate?: Maybe<Scalars['DateTime']>;
  payrollPaymentBatchStatus?: Maybe<PayrollPaymentBatchStatus>;
  payrollPaymentBatchType?: Maybe<PayrollPaymentBatchType>;
  payrollPaymentsPaginated?: Maybe<PayrollPaymentResult>;
};

export type PayrollPaymentFileBatch_PayrollPaymentsPaginatedArgs = {
  filters?: InputMaybe<PayrollPaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type PayrollPaymentFilters = {
  companyId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['ID']>;
  firstInvoiceStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollPaymentStatus?: InputMaybe<Array<InputMaybe<PayrollPaymentStatus>>>;
};

export type PayrollPaymentInfo = {
  __typename?: 'PayrollPaymentInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  id: Scalars['ID'];
  payrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
};

export type PayrollPaymentResult = {
  __typename?: 'PayrollPaymentResult';
  data?: Maybe<Array<Maybe<PayrollPayment>>>;
  pageResult?: Maybe<PageResult>;
};

export enum PayrollPaymentStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  VOID = 'VOID',
}

export enum PayrollPaymentType {
  EXPENSES_PAYMENT = 'EXPENSES_PAYMENT',
  PAY_SUPPLEMENT_PAYMENT = 'PAY_SUPPLEMENT_PAYMENT',
  SALARY_PAYMENT = 'SALARY_PAYMENT',
}

export type PayrollProcess = {
  __typename?: 'PayrollProcess';
  currentStep?: Maybe<PartnerPayrollVersionStep>;
  payrollReportUpload?: Maybe<PayrollReportUpload>;
  payrollUpdates?: Maybe<Array<PayrollUpdateBatch>>;
  versions?: Maybe<Array<Maybe<PartnerPayrollVersion>>>;
  workflow?: Maybe<Array<Maybe<PayrollVersionWorkFlowNode>>>;
};

export type PayrollReconciliationData = {
  __typename?: 'PayrollReconciliationData';
  payrollCycle: PayrollCycle;
  payrollDataId?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<PayrollReconciliationResult>>>;
};

export type PayrollReconciliationResult = {
  __typename?: 'PayrollReconciliationResult';
  category: ReconciliationCategory;
  contractIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  group: ReconciliationGroup;
  id: Scalars['ID'];
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  severityLevel?: Maybe<ReconciliationResultSeverity>;
  status: ReconciliationResultStatus;
};

export type PayrollReportGeneratorConfigFilter = {
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollReportGeneratorConfigInput = {
  configString?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollReportUpload = {
  __typename?: 'PayrollReportUpload';
  country?: Maybe<CountryCode>;
  document?: Maybe<Document>;
  /** @deprecated please use document instead of file */
  file?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  month?: Maybe<PayrollMonth>;
  parsedMemberPays?: Maybe<Array<Maybe<ParsedMemberPay>>>;
  partnerId?: Maybe<Scalars['ID']>;
  payrollCycle?: Maybe<PayrollCycle>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
};

export type PayrollSnapshotAssignDomainInput = {
  domain: PayrollInputDomainType;
  domainIds: Array<Scalars['ID']>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type PayrollSnapshotRefreshDomainByIdInput = {
  domain: PayrollInputDomainType;
  domainIds: Array<Scalars['ID']>;
};

export type PayrollSnapshotWatermarkRefreshInput = {
  domain: PayrollInputDomainType;
  until?: InputMaybe<Scalars['DateTime']>;
};

export type PayrollTimeOffFilter = {
  contractIds: Array<InputMaybe<Scalars['ID']>>;
  timeOffReportInput: TimeOffReportInput;
  timeOffStatus: Array<InputMaybe<TimeOffStatus>>;
};

export type PayrollUpdateBatch = {
  __typename?: 'PayrollUpdateBatch';
  createdOn: Scalars['DateTime'];
  updateItems?: Maybe<Array<PayrollUpdateItem>>;
};

export type PayrollUpdateBatchCreateFilter = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  expenseIds?: InputMaybe<Array<Scalars['ID']>>;
  paySupplementIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PayrollUpdateItem = {
  __typename?: 'PayrollUpdateItem';
  data?: Maybe<PayrollUpdateItemData>;
  type?: Maybe<PayrollUpdateType>;
};

export type PayrollUpdateItemData = Contract | Expense | PaySupplement;

export type PayrollUpdateTaskResponse = {
  __typename?: 'PayrollUpdateTaskResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export enum PayrollUpdateType {
  EXPENSE = 'EXPENSE',
  MEMBER_OFFBOARDED = 'MEMBER_OFFBOARDED',
  NEW_MEMBER = 'NEW_MEMBER',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY_CHANGE = 'SALARY_CHANGE',
  START_DATE = 'START_DATE',
}

export type PayrollVersionWorkFlowNode = {
  __typename?: 'PayrollVersionWorkFlowNode';
  status?: Maybe<PartnerPayrollVersionStatus>;
  statuses?: Maybe<Array<Maybe<PartnerPayrollVersionStatus>>>;
  step?: Maybe<PartnerPayrollVersionStep>;
};

export enum PayrollWorkflowAction {
  CONFIRMINPUT = 'ConfirmInput',
  CONFIRMOUTPUTREPORT = 'ConfirmOutputReport',
  CONFIRMPAYSLIPS = 'ConfirmPayslips',
  INITIATEPAYROLLCLOSURE = 'InitiatePayrollClosure',
  REVIEWOUTPUTREPORT = 'ReviewOutputReport',
  SENDFORREVIEW = 'SendForReview',
  SENDTOCUSTOMER = 'SendToCustomer',
  UPLOADOUTPUTREPORT = 'UploadOutputReport',
}

export type PayrollWorkflowResponse = {
  __typename?: 'PayrollWorkflowResponse';
  payrollCycles: Array<PayrollCycle>;
};

export type Payslip = {
  __typename?: 'Payslip';
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  /** @deprecated Use payslipDocument instead. Keeping this field for backward compatibility */
  document?: Maybe<Document>;
  /** @deprecated Use payslipDocument instead. Keeping this field for backward compatibility */
  file?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  month?: Maybe<PayrollMonth>;
  netAmount?: Maybe<Scalars['Float']>;
  payCycle?: Maybe<PayrollCycle>;
  /** @deprecated Use payCycle instead. Keeping this field for backward compatibility */
  payrollCycle?: Maybe<Scalars['Int']>;
  payslipDocument?: Maybe<PayslipDocument>;
  status?: Maybe<PayslipStatus>;
};

export type PayslipConfirmInput = {
  payrollCycleId: Scalars['ID'];
};

export type PayslipDocument = {
  __typename?: 'PayslipDocument';
  downloadUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PayslipForceUploadInput = {
  contractId: Scalars['ID'];
  payrollCycleId: Scalars['ID'];
};

export type PayslipPublishInput = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  payrollCycleId: Scalars['ID'];
};

export enum PayslipStatus {
  GENERATION_FAILED = 'GENERATION_FAILED',
  GENERATION_IN_PROGRESS = 'GENERATION_IN_PROGRESS',
  GENERATION_SUCCESSFUL = 'GENERATION_SUCCESSFUL',
  PUBLISHED = 'PUBLISHED',
  READY_TO_PUBLISH = 'READY_TO_PUBLISH',
  UPLOADED = 'UPLOADED',
  VALIDATED = 'VALIDATED',
  VERIFIED = 'VERIFIED',
}

export type PayslipSummary = {
  __typename?: 'PayslipSummary';
  published?: Maybe<Scalars['Int']>;
  readyToPublish?: Maybe<Scalars['Int']>;
  totalMembers?: Maybe<Scalars['Int']>;
};

export type PayslipUploadDetails = {
  __typename?: 'PayslipUploadDetails';
  confirmed?: Maybe<ConfirmedPayslipsSummary>;
  uploadUrl?: Maybe<Scalars['String']>;
  validationRequest?: Maybe<PayslipValidationRequest>;
};

export enum PayslipValidationErrorType {
  CONTRACT_ID_MISMATCH = 'CONTRACT_ID_MISMATCH',
  CONTRACT_ID_SKIPPED = 'CONTRACT_ID_SKIPPED',
  FILE_READ_ERROR = 'FILE_READ_ERROR',
  MEMBER_FIRST_NAME_MISMATCH = 'MEMBER_FIRST_NAME_MISMATCH',
  MEMBER_LAST_NAME_MISMATCH = 'MEMBER_LAST_NAME_MISMATCH',
  NO_MATCHING_FILE = 'NO_MATCHING_FILE',
}

export type PayslipValidationRequest = {
  __typename?: 'PayslipValidationRequest';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<PayslipValidationRequestStatus>;
  validationReport?: Maybe<PayrollFile>;
  validationResults?: Maybe<Array<Maybe<PayslipValidationResult>>>;
};

export enum PayslipValidationRequestStatus {
  ENDED = 'ENDED',
  INITIATED = 'INITIATED',
}

export type PayslipValidationResult = {
  __typename?: 'PayslipValidationResult';
  contractId?: Maybe<Scalars['ID']>;
  errorTypes?: Maybe<Array<Maybe<PayslipValidationErrorType>>>;
  fileName?: Maybe<Scalars['String']>;
};

export type PayslipsResponse = {
  __typename?: 'PayslipsResponse';
  page?: Maybe<PageResult>;
  payslips?: Maybe<Array<Maybe<Payslip>>>;
};

export type PennyTestInitiateRequestData = {
  amount: TransferValueAmount;
  batchPaymentFileId: Scalars['String'];
};

export type PercentPayComponent = CompensationPayComponent & {
  __typename?: 'PercentPayComponent';
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<PayAmountType>;
  basedOn?: Maybe<FixedPayComponent>;
  condition?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  frequency?: Maybe<RateFrequency>;
  id?: Maybe<Scalars['ID']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  paymentFrequency?: Maybe<PayFrequency>;
  paymentFrequencyDate?: Maybe<Array<Maybe<PayFrequencyDate>>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PercentPayComponentDefinition = CompensationPayComponentDefinition & {
  __typename?: 'PercentPayComponentDefinition';
  basedOn?: Maybe<FixedPayComponentDefinition>;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<RateFrequency>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  rateType?: Maybe<RateType>;
};

export type PercentPayComponentInput = {
  amountType?: InputMaybe<PayAmountType>;
  basedOn?: InputMaybe<FixedPayComponentInput>;
  condition?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  frequency?: InputMaybe<RateFrequency>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<InputMaybe<PayFrequencyDateInput>>>;
  ratio?: InputMaybe<Scalars['Float']>;
};

export type PerformanceReview = {
  __typename?: 'PerformanceReview';
  comment?: Maybe<Scalars['String']>;
  companySignedOn?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<Designation>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memberSignedOn?: Maybe<Scalars['DateTime']>;
  payrollCycle?: Maybe<PayrollCycle>;
  previousDesignation?: Maybe<Designation>;
  reviewDocument?: Maybe<DocumentReadable>;
  salaryReview?: Maybe<SalaryReview>;
  status?: Maybe<PerformanceReviewStatus>;
};

export type PerformanceReviewBulkInput = {
  addendumDocument?: InputMaybe<Scalars['Upload']>;
  companySignedOn?: InputMaybe<Scalars['DateTime']>;
  memberSignedOn?: InputMaybe<Scalars['DateTime']>;
  performanceReview: PerformanceReviewInput;
};

export type PerformanceReviewInput = {
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  designation?: InputMaybe<DesignationInput>;
  effectiveDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  salaryReview?: InputMaybe<SalaryReviewInput>;
};

export type PerformanceReviewNote = {
  __typename?: 'PerformanceReviewNote';
  cutOffDate?: Maybe<Scalars['Date']>;
  /** @deprecated FE should build the notes. BE will be returning null to avoid any confusion */
  message?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  payrollCyclePayDate?: Maybe<Scalars['Date']>;
  semimonthlySegment?: Maybe<SemimonthlySegment>;
  type?: Maybe<PerformanceReviewNoteType>;
  year?: Maybe<Scalars['Int']>;
};

export type PerformanceReviewNoteInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  currentDate?: InputMaybe<Scalars['Date']>;
  effectiveDate: Scalars['Date'];
};

export enum PerformanceReviewNoteType {
  EFFECTIVE = 'EFFECTIVE',
  REFLECTIVE = 'REFLECTIVE',
}

export enum PerformanceReviewStatus {
  ACTIVATED = 'ACTIVATED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DRAFT = 'DRAFT',
  SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
  SENT_FOR_MEMBER_APPROVAL = 'SENT_FOR_MEMBER_APPROVAL',
  SENT_TO_OPS = 'SENT_TO_OPS',
}

export type PeriodInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

/** Platform permission (pre-defined) */
export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Person = {
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  userId?: Maybe<Scalars['String']>;
};

export enum Persona {
  COMPANY = 'COMPANY',
  MEMBER = 'MEMBER',
  OPERATIONS = 'OPERATIONS',
  PARTNER = 'PARTNER',
}

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  phoneNo?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PhoneNumberParam = {
  __typename?: 'PhoneNumberParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<PhoneNumber>;
};

export type PlatformBatchedUpdateInput = {
  action: Scalars['String'];
  payload: Array<Scalars['String']>;
  ticketId: Scalars['String'];
};

export enum PlatformCategory {
  ACCOUNTING = 'ACCOUNTING',
  ATS = 'ATS',
  CRM = 'CRM',
  EXPENSES = 'EXPENSES',
  HRIS = 'HRIS',
  TICKETING = 'TICKETING',
}

export type PlatformJobMetaDataFilter = {
  action?: InputMaybe<Array<Scalars['String']>>;
  ownerBy?: InputMaybe<Scalars['String']>;
};

export type PlatformUpdateInput = {
  action: Scalars['String'];
  payload: Scalars['String'];
  ticketId: Scalars['String'];
};

export type PlatformUpdateTaskResponse = {
  __typename?: 'PlatformUpdateTaskResponse';
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  request?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  ticketId?: Maybe<Scalars['String']>;
};

export type Position = {
  __typename?: 'Position';
  department?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
};

export type PreferredContractAgreementTemplateResponse = {
  __typename?: 'PreferredContractAgreementTemplateResponse';
  documentId?: Maybe<Scalars['ID']>;
  template?: Maybe<Template>;
};

export type PreviousEmployer = {
  __typename?: 'PreviousEmployer';
  designation?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  jobDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type PreviousEmployerInput = {
  designation: Scalars['String'];
  endDate: Scalars['Date'];
  jobDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['Date'];
};

export type Pricing = {
  __typename?: 'Pricing';
  /** removing the entire section from the front-end */
  billingCurrencyCode?: Maybe<CurrencyCode>;
  company?: Maybe<Company>;
  customDiscountTerm?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  deposit?: Maybe<Scalars['Float']>;
  depositTerm?: Maybe<DepositTerm>;
  discountTerms?: Maybe<Array<Maybe<DiscountTerm>>>;
  employeePricing?: Maybe<Array<Maybe<EmployeePricing>>>;
  globalPricing?: Maybe<Array<Maybe<GlobalPricing>>>;
  id?: Maybe<Scalars['ID']>;
  offboardingGlobalPricing?: Maybe<Array<Maybe<OffboardingGlobalPricing>>>;
  paymentTermInDays?: Maybe<Scalars['Int']>;
  /**
   * deposit is no longer a fixed value. Use depositTerm instead
   * @deprecated No longer supported
   */
  resourceClaim?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  serviceFee?: Maybe<Scalars['Float']>;
  services?: Maybe<Scalars['String']>;
  setupFee?: Maybe<Scalars['Float']>;
  visaGlobalPricing?: Maybe<Array<Maybe<VisaGlobalPricing>>>;
  visaPricing?: Maybe<Array<Maybe<EmployeePricing>>>;
};

export type PricingCountryPlan = {
  __typename?: 'PricingCountryPlan';
  country: CountryCode;
  id: Scalars['ID'];
  seats: Array<PricingPlanSeat>;
  workStatus?: Maybe<CountryWorkStatus>;
};

export type PricingInput = {
  billingCurrencyCode?: InputMaybe<CurrencyCode>;
  customDiscountTerm?: InputMaybe<Scalars['String']>;
  deposit?: InputMaybe<Scalars['Float']>;
  depositTerm?: InputMaybe<DepositTermInput>;
  discountTerms: Array<InputMaybe<DiscountTermInput>>;
  employeePricing: Array<InputMaybe<CompanyEmployeePricingInput>>;
  globalPricing?: InputMaybe<Array<InputMaybe<CompanyGlobalPricingInput>>>;
  offboardingGlobalPricing?: InputMaybe<Array<OffboardingGlobalPricingInput>>;
  paymentTermInDays?: InputMaybe<Scalars['Int']>;
  resourceClaim?: InputMaybe<Scalars['String']>;
  serviceFee?: InputMaybe<Scalars['Float']>;
  services: Scalars['String'];
  setupFee: Scalars['Float'];
  visaGlobalPricing?: InputMaybe<
    Array<InputMaybe<CompanyVisaGlobalPricingInput>>
  >;
  visaPricing?: InputMaybe<Array<InputMaybe<CompanyVisaPricingInput>>>;
};

export type PricingPlan = {
  __typename?: 'PricingPlan';
  autoSeatExpansion: Scalars['Boolean'];
  companyId: Scalars['ID'];
  companyOfferingId: Scalars['ID'];
  companyOfferingPricingId: Scalars['ID'];
  contractIds: Array<Scalars['ID']>;
  discount?: Maybe<Discount>;
  end?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  paymentTerm?: Maybe<PaymentTerm>;
  pricingCountryPlans: Array<PricingCountryPlan>;
  start?: Maybe<Scalars['Date']>;
  status?: Maybe<PricingPlanStatus>;
};

export type PricingPlanAutofillInput = {
  expandIfNecessary?: InputMaybe<Scalars['Boolean']>;
  pricingPlanId: Scalars['ID'];
};

export type PricingPlanCloseSeatsInput = {
  country: CountryCode;
  numberOfSeatsToClose: Scalars['Int'];
  pricingPlanId: Scalars['ID'];
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type PricingPlanCreateInput = {
  autoSeatExpansion?: InputMaybe<Scalars['Boolean']>;
  companyOfferingId: Scalars['ID'];
  discount?: InputMaybe<DiscountInput>;
  end: Scalars['Date'];
  generateAndAutofillSeats?: InputMaybe<Scalars['Boolean']>;
  initialContractIds?: InputMaybe<Array<Scalars['ID']>>;
  paymentTerm?: InputMaybe<PaymentTermInput>;
  seatConfigurations?: InputMaybe<Array<SeatConfigurationInput>>;
  start: Scalars['Date'];
};

export type PricingPlanDeleteInput = {
  forceDeleteIfActive: Scalars['Boolean'];
  id: Scalars['ID'];
  vacateAndCloseAllSeats: Scalars['Boolean'];
};

export type PricingPlanExpandInput = {
  /** @deprecated Use seatConfigurations[i].count instead */
  count?: InputMaybe<Scalars['Int']>;
  country: CountryCode;
  pricingPlanId: Scalars['ID'];
  seatConfigurations?: InputMaybe<Array<SeatConfigurationInput>>;
  /** @deprecated Use seatConfigurations[i].workStatus instead */
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type PricingPlanMigrateInput = {
  pricingPlans: Array<CompanyPricingPlanMigrateInput>;
};

export type PricingPlanMigrateResponse = {
  __typename?: 'PricingPlanMigrateResponse';
  pricingPlans: Array<CompanyPricingPlanMigrateResponse>;
};

export type PricingPlanSeat = {
  __typename?: 'PricingPlanSeat';
  contractId?: Maybe<Scalars['ID']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  events: Array<PricingPlanSeatEvent>;
  id: Scalars['ID'];
  pricingCountryPlanId: Scalars['ID'];
  status?: Maybe<SeatStatus>;
};

export type PricingPlanSeatEvent = {
  __typename?: 'PricingPlanSeatEvent';
  contractId?: Maybe<Scalars['ID']>;
  eventTime?: Maybe<Scalars['DateTime']>;
  eventType?: Maybe<SeatEventType>;
  id: Scalars['ID'];
  seatId: Scalars['ID'];
};

export enum PricingPlanStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

export type PricingPlanUpdateInput = {
  autoSeatExpansion?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<DiscountInput>;
  end?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  initialContractIds?: InputMaybe<Array<Scalars['ID']>>;
  paymentTerm?: InputMaybe<PaymentTermInput>;
  seatConfigurations?: InputMaybe<Array<SeatConfigurationInput>>;
  start?: InputMaybe<Scalars['Date']>;
};

export type ProcessingFee = {
  __typename?: 'ProcessingFee';
  feeTotal?: Maybe<Scalars['Float']>;
  feeTypes?: Maybe<Array<Maybe<FeeType>>>;
};

export type Product = {
  __typename?: 'Product';
  brand: Scalars['String'];
  color?: Maybe<ProductColor>;
  countryCode: CountryCode;
  currencyCode: CurrencyCode;
  description?: Maybe<Scalars['String']>;
  dynamicDetails?: Maybe<Array<ProductDetailCategory>>;
  id: Scalars['ID'];
  images?: Maybe<Array<ProductImage>>;
  modelNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sku: Scalars['String'];
  status: ProductStatus;
  type: Scalars['String'];
};

export type ProductColor = {
  __typename?: 'ProductColor';
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ProductDetail = {
  __typename?: 'ProductDetail';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProductDetailCategory = {
  __typename?: 'ProductDetailCategory';
  details?: Maybe<Array<ProductDetail>>;
  key: Scalars['String'];
};

export type ProductFilter = {
  brand?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  productId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<ProductStatus>;
  type?: InputMaybe<Scalars['String']>;
};

export type ProductFilterSpec = {
  __typename?: 'ProductFilterSpec';
  brands?: Maybe<Array<Scalars['String']>>;
  countries?: Maybe<Array<CountryCode>>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductList = {
  __typename?: 'ProductList';
  filterSpec?: Maybe<ProductFilterSpec>;
  products?: Maybe<Array<Product>>;
};

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type PromotionalPeriodDiscountTermCondition = {
  __typename?: 'PromotionalPeriodDiscountTermCondition';
  event?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  relativeDurationFromEvent?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Date']>;
};

export type PromotionalPeriodDiscountTermConditionInput = {
  event?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  relativeDurationFromEvent?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type PublishPayslipsResponse = {
  __typename?: 'PublishPayslipsResponse';
  publishPayslipsSummary?: Maybe<PublishPayslipsSummary>;
  publishedPayslips?: Maybe<Array<Maybe<Payslip>>>;
};

export type PublishPayslipsSummary = {
  __typename?: 'PublishPayslipsSummary';
  nonPublishedPayslipsCount?: Maybe<Scalars['Int']>;
  publishedPayslipsCount?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  GetPaymentInstructionDocument?: Maybe<Document>;
  GetPaymentInstructionDocumentV2?: Maybe<PaymentInstructionFileGenerationReport>;
  /** @deprecated Use PaymentInstructionFile */
  GetPaymentInstructionFile?: Maybe<DocumentReadable>;
  benefit?: Maybe<Benefit>;
  benefitActivityLogs?: Maybe<Array<Maybe<ActivityDocument>>>;
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  benefitPackageCosts?: Maybe<Array<Maybe<BenefitPackageCost>>>;
  benefitPartnerAddOperationActivityTemplate: DocumentReadable;
  /** @deprecated No longer supported */
  benefitPartnerAddOperationTemplate: DocumentReadable;
  benefitPartnerCountries?: Maybe<Array<Maybe<BenefitPartnerCountry>>>;
  benefitPartnerCountriesWithPagination?: Maybe<BenefitPartnerCountriesResult>;
  benefitPartnerCountry?: Maybe<BenefitPartnerCountry>;
  benefitPartnerCountryCheckExpirationStatus?: Maybe<Scalars['Boolean']>;
  benefitPartnerDataDownload: DocumentReadable;
  benefitPartnerEditOperationSheetDownload: DocumentReadable;
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  bulkOnboardingTemplateDownload?: Maybe<DocumentReadable>;
  companies?: Maybe<Array<Maybe<Company>>>;
  companiesWithPagination?: Maybe<CompaniesResult>;
  company?: Maybe<Company>;
  companyChargebackPeriod?: Maybe<CompanyChargebackResult>;
  companyGroup?: Maybe<Array<Maybe<CompanyGroup>>>;
  companyHasDuplicatedDomain?: Maybe<Scalars['Boolean']>;
  companyHasIntegration?: Maybe<Scalars['Boolean']>;
  companyIntegration?: Maybe<CompanyIntegration>;
  companyIntegrationInfo?: Maybe<CompanyIntegrationInfo>;
  companyOfferingPricing?: Maybe<Array<Maybe<CompanyOfferingPricing>>>;
  companyPayableDownloadInvoiceSourceReport: DocumentReadable;
  /** @deprecated Use the companyPayableDownloadInvoiceSourceReportByInvoiceId instead */
  companyPayableDownloadInvoiceSourceReportByHash: DocumentReadable;
  /** @deprecated Use the companyPayableDownloadInvoiceSourceReportFromLink to get ISR for both Invoice and CN */
  companyPayableDownloadInvoiceSourceReportByInvoiceId: DocumentReadable;
  companyPayableDownloadInvoiceSourceReportFromHash: DocumentReadable;
  companyPayableDownloadInvoiceSourceReportFromLink: DocumentReadable;
  companyPayableDownloadInvoiceSourceReports: DocumentReadable;
  companyPayableInvoiceSourceDetailedReport?: Maybe<
    Array<Maybe<CompanyInvoiceSourceItem>>
  >;
  companyPayableInvoiceSourceDetailedReportFetch?: Maybe<
    Array<Maybe<CompanyInvoiceSourceRecord>>
  >;
  companyPayableInvoiceSourceReport?: Maybe<
    Array<Maybe<CompanyInvoiceSourceReportResult>>
  >;
  companyPayablesWithPagination?: Maybe<CompanyPayablesResult>;
  /** @deprecated No longer supported */
  companyPayroll?: Maybe<CompanyPayroll>;
  companyPayrollCycle?: Maybe<CompanyPayrollCycle>;
  companyPayrollCycleReportDownload?: Maybe<PayrollFile>;
  companyPayrollCycleReports?: Maybe<Array<CompanyPayrollReport>>;
  companyPayrollCycles: CompanyPayrollCyclesResult;
  companyPayrollEntityTypes?: Maybe<Array<Maybe<PayrollCycleEntityType>>>;
  companyPayrollInputConfirmRules?: Maybe<AccessibilityResponse>;
  companyPayrollInputReportDownload?: Maybe<DocumentReadable>;
  companyPayrollMemberPays?: Maybe<CompanyMemberPayResult>;
  companyPayrollOutputConfirmRules?: Maybe<AccessibilityResponse>;
  companyPayrollPayslipPublishRules?: Maybe<AccessibilityResponse>;
  companyPayrollReportDownload?: Maybe<DocumentReadable>;
  companyPrePopulatedPaySupplementReport?: Maybe<DocumentReadable>;
  companyQuotation?: Maybe<CompanyQuotation>;
  companyQuotations?: Maybe<CompanyQuotationsResponse>;
  companySync?: Maybe<CompanySync>;
  compensationComponents: CompensationSchema;
  /** @deprecated No longer supported */
  compensationStructureDefinition?: Maybe<CompensationStructureDefinition>;
  /** @deprecated No longer supported */
  complianceParamsDefinition?: Maybe<Array<Maybe<ComplianceParamDefinition>>>;
  componentCategories: Array<ComponentCategory>;
  configuration?: Maybe<Configuration>;
  contract?: Maybe<Contract>;
  contractBenefitBanners?: Maybe<Array<Maybe<ContractBenefitBanner>>>;
  contractBenefitChangeRequests?: Maybe<
    Array<Maybe<ContractBenefitChangeRequestResponse>>
  >;
  /** @deprecated No longer supported */
  contractBenefitDocument?: Maybe<DocumentReadable>;
  contractBenefitReport?: Maybe<DocumentReadable>;
  contractBenefitsReport?: Maybe<DocumentReadable>;
  contractBenefitsWithPagination?: Maybe<ContractBenefitsResult>;
  contractDetailsReport?: Maybe<DocumentReadable>;
  contractJobCode?: Maybe<Scalars['String']>;
  contractPayrollDetailsReport?: Maybe<DocumentReadable>;
  /** Mainly for "Support 360" feature */
  contractSnapshots?: Maybe<CycleContractSnapshotsResponse>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  contractsWithPagination?: Maybe<ContractsResult>;
  /** @deprecated No longer supported */
  convertCurrency?: Maybe<Scalars['Float']>;
  countries?: Maybe<Array<Maybe<Country>>>;
  country?: Maybe<Country>;
  countryAllowancesConfig: Array<Allowance>;
  countryCallingCodes?: Maybe<Array<Maybe<CountryCallingCodeDefinition>>>;
  countryLegalData?: Maybe<Array<Maybe<LegalData>>>;
  creditNoteCountByStatus?: Maybe<Array<CreditNoteCountByStatus>>;
  creditNotesWithPagination?: Maybe<CreditNotesResult>;
  currency?: Maybe<Currency>;
  customPayrollReport: CustomPayrollReportDetails;
  customPayrollReportConfigs: Array<CustomPayrollReportConfig>;
  customPayrollReports: CustomPayrollReportsResponse;
  customerIntegrationDefinitions?: Maybe<
    Array<Maybe<CustomerIntegrationDefinition>>
  >;
  cycleContracts?: Maybe<CycleContractSnapshotsResponse>;
  dataChangeReport?: Maybe<DocumentReadable>;
  departments: Array<Department>;
  disputes?: Maybe<DisputeResponse>;
  downloadCompanyPayableReport: DocumentReadable;
  downloadCustomPayrollReport: DocumentReadable;
  downloadPayrollReconciliationData?: Maybe<DocumentReadable>;
  downloadSalaryInvoiceSourceReport?: Maybe<Array<DocumentReadable>>;
  dummy?: Maybe<Scalars['Boolean']>;
  eligibleCountriesForEor: Array<PayrollCountryEntityType>;
  eligibleEntitiesForPeo: Array<PayrollCycleEntity>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplateByType?: Maybe<EmailTemplate>;
  emailTemplates: Array<Maybe<EmailTemplate>>;
  employeePricingDefaultCountries?: Maybe<EmployeePricingDefault>;
  employeePricingForCountry?: Maybe<Array<Maybe<EmployeePricing>>>;
  employeePricingForRegion?: Maybe<Array<Maybe<EmployeePricing>>>;
  equipmentIncompleteProcurementsDownload?: Maybe<EquipmentDownloadResponse>;
  equipmentsDownload?: Maybe<EquipmentDownloadResponse>;
  expense?: Maybe<Expense>;
  expenseCategories?: Maybe<Array<ExpenseCategory>>;
  expenseValidationInfo: ExpenseValidationInfo;
  expenses?: Maybe<ExpensesResponse>;
  expensesReport?: Maybe<DocumentReadable>;
  feeComponentDefinition?: Maybe<Array<Maybe<FeeComponentDefinition>>>;
  feeComponentTierDefinition?: Maybe<Array<Maybe<FeeComponentTierDefinition>>>;
  fileDownloadHistory?: Maybe<FileDownloadHistoryResponse>;
  filteredDataChange?: Maybe<DataChangePageResults>;
  financialTransactionInspect: FinancialTransactionInspectorResponse;
  generateSalaryInvoiceSourceReports?: Maybe<TaskResponse>;
  getAllApprovers?: Maybe<Array<Maybe<Approver>>>;
  getApplicableExceptionCategories: Array<
    Maybe<PayrollContractExceptionReasonCategory>
  >;
  getAvailableReports: Array<Maybe<ReportConfig>>;
  getCompanyPayInMethod?: Maybe<CompanyPayInMethod>;
  getCompanyPrefillData?: Maybe<CompanyPreFillData>;
  getComponentAccessibilityRules?: Maybe<ComponentAccessibilityResponse>;
  getEmployeesToSendInviteTo?: Maybe<Array<Maybe<Employee>>>;
  getEmployeesToSync?: Maybe<SyncEmployees>;
  getEmployeesToSyncEOR?: Maybe<EorEmployeesToSyncResult>;
  getExternalLeaveTypes?: Maybe<Array<Maybe<ExternalLeaveType>>>;
  getIntegrationEntityMappingStatus?: Maybe<
    Array<Maybe<IntegrationEntityMappingStatusOutput>>
  >;
  getIntegrationFieldsMapping?: Maybe<IntegrationFieldsMappingOutput>;
  getInternalLeaveTypes?: Maybe<Array<Maybe<ExternalLeaveType>>>;
  getLastWorkingDayRange?: Maybe<LastWorkingDayRangeResponse>;
  getLatestEmployeesForImport?: Maybe<FetchEmployeesResult>;
  getLatestSyncResultForIntegration?: Maybe<LatestSyncResult>;
  getLeaveTypeMappingDefinition?: Maybe<
    Array<Maybe<LeaveTypeMappingDefinition>>
  >;
  getMemberPersonalDetailsAccessibilityRules?: Maybe<
    Array<Maybe<SectionAccessibilityResponse>>
  >;
  getPaymentOptions?: Maybe<Array<Maybe<PaymentOptions>>>;
  getPayrollReconciliationData?: Maybe<PayrollReconciliationData>;
  getPendingEmployeesToSync?: Maybe<Array<Maybe<Employee>>>;
  getPositionsForIntegrations?: Maybe<Array<Maybe<Position>>>;
  getReport: Scalars['String'];
  getSEPAIBANDetailsFieldInformation?: Maybe<SepaibanDetailsFieldInformationResponse>;
  getTentativeLastWorkingDay?: Maybe<LastWorkingDayResponse>;
  getTimeOffsForPayroll?: Maybe<Array<Maybe<TimeOff>>>;
  holidays?: Maybe<Array<Maybe<Holiday>>>;
  insuranceCoverageGroups?: Maybe<Array<Maybe<InsuranceCoverageGroup>>>;
  insuranceRelation?: Maybe<Array<Maybe<InsuranceRelation>>>;
  insuranceTier?: Maybe<Array<Maybe<InsuranceTier>>>;
  /** @deprecated Not implemented. Do not use this. */
  isNonHrisManager: Scalars['Boolean'];
  kyb?: Maybe<Kyb>;
  kybList?: Maybe<Array<Maybe<Kyb>>>;
  latestMemberPayrollCycles?: Maybe<MemberPayrollCyclesResponse>;
  manager?: Maybe<Manager>;
  managerDirectReportsRule?: Maybe<Rule>;
  member?: Maybe<Member>;
  memberPayableCompanyInvoices?: Maybe<MemberPayableCompanyInvoiceResult>;
  /** @deprecated No longer supported */
  memberPayableDownloadInvoiceSourceReport: DocumentReadable;
  memberPayableReport?: Maybe<DocumentReadable>;
  memberPayableSummary?: Maybe<MemberPayableSummary>;
  memberPayrollCycleDetail?: Maybe<MemberPayrollCycleDetail>;
  memberPayrollCycles?: Maybe<MemberPayrollCyclesResponse>;
  /** membersWithPagination will return the list of member by filter and pagination */
  membersWithPagination?: Maybe<MembersResult>;
  msaAddendumDraftSample?: Maybe<MsaAddendum>;
  nothing?: Maybe<Scalars['String']>;
  offboardingExperienceState: OffboardingExperienceState;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  ok?: Maybe<Scalars['Boolean']>;
  onboarding?: Maybe<ContractOnboarding>;
  onboardingAudit?: Maybe<Array<Maybe<Scalars['Object']>>>;
  operationsDashboard?: Maybe<OperationsDashboard>;
  operationsUser?: Maybe<OperationsUser>;
  operationsUsers?: Maybe<Array<Maybe<OperationsUser>>>;
  orgDirectory: OrgDirectory;
  /** @deprecated Not implemented. Use 'orgDirectory' query instead. */
  orgHierarchy: Manager;
  /** Get all users that can be assigned to a role (TO-BE discussed!!!) */
  orgRoleAvailableManagers?: Maybe<Array<Manager>>;
  orgRoles?: Maybe<Array<OrgRole>>;
  partner?: Maybe<Partner>;
  /** @deprecated No longer supported */
  partnerPayroll?: Maybe<PartnerPayroll>;
  partnerPayrollSchema: PartnerPayrollSchema;
  partnerPayrolls?: Maybe<Array<Maybe<PartnerPayroll>>>;
  partners?: Maybe<Array<Maybe<Partner>>>;
  paySupplement?: Maybe<PaySupplement>;
  paySupplements?: Maybe<PaySupplementsResponse>;
  paySupplementsReport?: Maybe<DocumentReadable>;
  paymentAccount?: Maybe<PaymentAccount>;
  paymentBundlePreview?: Maybe<PaymentBundlePreview>;
  paymentBundles?: Maybe<Array<Maybe<PaymentBundle>>>;
  paymentOk?: Maybe<Scalars['Boolean']>;
  payrollBulkUploadConfirmRules?: Maybe<AccessibilityResponse>;
  payrollBulkUploadConfirmStatus?: Maybe<PayrollBulkUploadConfirmStatus>;
  payrollBulkUploadCreateRules?: Maybe<AccessibilityResponse>;
  payrollBulkUploadTemplate?: Maybe<DocumentReadable>;
  payrollBulkUploadValidateRules?: Maybe<AccessibilityResponse>;
  payrollConfigsWithNextPayrollCycles: Array<PayrollCycleConfig>;
  payrollContract?: Maybe<PayrollContract>;
  payrollCycle?: Maybe<PayrollCycle>;
  payrollCycleConfigs?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleForDomain?: Maybe<PayrollCycle>;
  payrollCyclePayment?: Maybe<PayrollCyclePayment>;
  payrollCyclePayments?: Maybe<PayrollCyclePaymentResult>;
  payrollCycles?: Maybe<PayrollCyclesResponse>;
  payrollCyclesForCalendar: Array<PayrollCycle>;
  payrollDocuments?: Maybe<PayrollDocumentsResponse>;
  payrollFileDownload?: Maybe<PayrollFileDownloadResponse>;
  payrollInputFile?: Maybe<DocumentReadable>;
  payrollInputFileDownload?: Maybe<DocumentReadable>;
  payrollInputFilePartnerBulkDownload?: Maybe<DocumentReadable>;
  payrollParserConfig?: Maybe<PayrollParserConfig>;
  payrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
  payrollPaymentBatches?: Maybe<PayrollPaymentBatchResult>;
  payrollReportDownload?: Maybe<DocumentReadable>;
  payrollReportDownloadForCustomer?: Maybe<DocumentReadable>;
  payrollReportGeneratorConfig?: Maybe<ReportGeneratorConfig>;
  /** payrollReports use to get the list of Payroll Report */
  payrollReports?: Maybe<CompanyMemberPayResult>;
  payslips?: Maybe<PayslipsResponse>;
  performanceReviewNotes?: Maybe<PerformanceReviewNote>;
  permissions?: Maybe<Array<Permission>>;
  platformMergeDevLinkToken?: Maybe<Scalars['String']>;
  platformUpdaterJobs: JobMetaDataPageResult;
  recruiters?: Maybe<Array<Maybe<Recruiter>>>;
  salaryCalculation?: Maybe<SalaryCalculation>;
  salaryCalculationMinimumWage?: Maybe<SalaryCalculationMinimumWage>;
  salaryCalculationParams?: Maybe<Array<AvailableSalaryCalculationParams>>;
  salaryCalculationPdf?: Maybe<FileLink>;
  supportedCountries?: Maybe<Array<Maybe<Country>>>;
  syncStatus?: Maybe<CompanySyncStatus>;
  syncSummaryResultDownload?: Maybe<SyncSummaryResultDownloadOutput>;
  templates?: Maybe<Array<Maybe<Template>>>;
  timeOffContractRequirements?: Maybe<TimeOffContractRequirements>;
  timeOffPolicyAssignmentDetails?: Maybe<TimeOffPolicyAssignmentDetailsResult>;
  timeOffSummaries?: Maybe<TimeOffSummariesResponse>;
  timeOffs?: Maybe<TimeOffsResponse>;
  timesheetConfigs?: Maybe<TimesheetConfigs>;
  timesheetReport?: Maybe<DocumentReadable>;
  timesheetWithPagination?: Maybe<TimesheetResult>;
  validateIntegrationCredentials?: Maybe<TaskResponse>;
  visaDocumentRequirements: Array<LegalDocumentRequirement>;
};

export type Query_GetPaymentInstructionDocumentArgs = {
  paymentInstructionId: Scalars['ID'];
};

export type Query_GetPaymentInstructionDocumentV2Args = {
  paymentInstructionId: Scalars['ID'];
};

export type Query_GetPaymentInstructionFileArgs = {
  paymentInstructionId: Scalars['ID'];
};

export type Query_BenefitArgs = {
  id: Scalars['String'];
};

export type Query_BenefitActivityLogsArgs = {
  filters: ActivityDocumentFilter;
};

export type Query_BenefitPackageCostArgs = {
  id: Scalars['String'];
};

export type Query_BenefitPackageCostsArgs = {
  filters?: InputMaybe<BenefitPackageCostFilters>;
};

export type Query_BenefitPartnerAddOperationTemplateArgs = {
  input: BenefitPartnerDataDownloadInput;
};

export type Query_BenefitPartnerCountriesArgs = {
  input?: InputMaybe<BenefitPartnerCountryFilters>;
};

export type Query_BenefitPartnerCountriesWithPaginationArgs = {
  filters?: InputMaybe<BenefitPartnerCountryFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_BenefitPartnerCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_BenefitPartnerCountryCheckExpirationStatusArgs = {
  id: Scalars['ID'];
};

export type Query_BenefitPartnerDataDownloadArgs = {
  input: BenefitPartnerDataDownloadInput;
};

export type Query_BenefitPartnerEditOperationSheetDownloadArgs = {
  input: BenefitPartnerDataDownloadInput;
};

export type Query_BenefitsArgs = {
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<BenefitFilters>;
  type?: InputMaybe<BenefitType>;
};

export type Query_BulkOnboardingTemplateDownloadArgs = {
  options: BulkOnboardingOptions;
};

export type Query_CompaniesArgs = {
  filters?: InputMaybe<CompanyFilters>;
};

export type Query_CompaniesWithPaginationArgs = {
  countRequest?: InputMaybe<CompaniesCountRequest>;
  filters?: InputMaybe<CompanyFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyChargebackPeriodArgs = {
  companyId: Scalars['ID'];
};

export type Query_CompanyGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyHasDuplicatedDomainArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyHasIntegrationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyIntegrationArgs = {
  companyId: Scalars['ID'];
};

export type Query_CompanyIntegrationInfoArgs = {
  integrationId: Scalars['ID'];
};

export type Query_CompanyOfferingPricingArgs = {
  filter: CompanyOfferingPricingFilter;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportArgs = {
  input: CompanyPayableInvoiceSourceReportExportInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportByHashArgs = {
  input: InvoiceSourceReportByHashInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportByInvoiceIdArgs = {
  input: InvoiceSourceReportByInvoiceIdInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportFromHashArgs = {
  input: InvoiceSourceReportHashInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportFromLinkArgs = {
  input: InvoiceSourceReportFromLinkInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportsArgs = {
  input: CompanyInvoiceSourceReportInput;
};

export type Query_CompanyPayableInvoiceSourceDetailedReportArgs = {
  input: CompanyInvoiceSourceReportInput;
};

export type Query_CompanyPayableInvoiceSourceDetailedReportFetchArgs = {
  input: CompanyPayableInvoiceSourceReportExportInput;
};

export type Query_CompanyPayableInvoiceSourceReportArgs = {
  input: CompanyInvoiceSourceReportInput;
};

export type Query_CompanyPayablesWithPaginationArgs = {
  filters?: InputMaybe<CompanyPayableFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanyPayrollArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type Query_CompanyPayrollCycleArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyPayrollCycleReportDownloadArgs = {
  filter: CompanyPayrollCycleReportFilter;
};

export type Query_CompanyPayrollCycleReportsArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollCyclesArgs = {
  filter: CompanyPayrollCycleFilter;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanyPayrollInputConfirmRulesArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollInputReportDownloadArgs = {
  filter: CompanyPayrollInputReportFilter;
};

export type Query_CompanyPayrollMemberPaysArgs = {
  pageRequest?: InputMaybe<PageRequest>;
  payrollFilter: CompanyPayrollFilter;
};

export type Query_CompanyPayrollOutputConfirmRulesArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollPayslipPublishRulesArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollReportDownloadArgs = {
  filter: CompanyPayrollReportInput;
};

export type Query_CompanyPrePopulatedPaySupplementReportArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyQuotationArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyQuotationsArgs = {
  filters?: InputMaybe<CompanyQuotationFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanySyncArgs = {
  id: Scalars['ID'];
};

export type Query_CompensationComponentsArgs = {
  entityId: Scalars['ID'];
};

export type Query_CompensationStructureDefinitionArgs = {
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<ContractTerm>;
  type?: InputMaybe<ContractType>;
};

export type Query_ComplianceParamsDefinitionArgs = {
  contractType: ContractType;
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<ContractTerm>;
};

export type Query_ContractArgs = {
  id: Scalars['ID'];
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  isApprovalRelated?: InputMaybe<Scalars['Boolean']>;
};

export type Query_ContractBenefitBannersArgs = {
  filters?: InputMaybe<ContractBenefitBannerFilters>;
};

export type Query_ContractBenefitChangeRequestsArgs = {
  filters?: InputMaybe<ContractBenefitChangeRequestFilters>;
};

export type Query_ContractBenefitDocumentArgs = {
  id: Scalars['ID'];
};

export type Query_ContractBenefitReportArgs = {
  id: Scalars['ID'];
};

export type Query_ContractBenefitsReportArgs = {
  filters: ContractBenefitFilters;
};

export type Query_ContractBenefitsWithPaginationArgs = {
  filters?: InputMaybe<ContractBenefitWithPaginationFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ContractDetailsReportArgs = {
  filters?: InputMaybe<ContractFilters>;
};

export type Query_ContractJobCodeArgs = {
  filter: JobCodeFilters;
};

export type Query_ContractPayrollDetailsReportArgs = {
  filters?: InputMaybe<ContractFilters>;
};

export type Query_ContractSnapshotsArgs = {
  filter?: InputMaybe<ContractSnapshotFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ContractsArgs = {
  filters?: InputMaybe<ContractFilters>;
  onboardingOperationsUserId?: InputMaybe<Scalars['ID']>;
  onboardingStatus?: InputMaybe<ContractOnboardingStatus>;
};

export type Query_ContractsWithPaginationArgs = {
  countRequest?: InputMaybe<ContractsCountRequest>;
  filters?: InputMaybe<ContractFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ConvertCurrencyArgs = {
  params: CurrencyConversionParams;
};

export type Query_CountriesArgs = {
  countries?: InputMaybe<Array<InputMaybe<CountryCode>>>;
  country?: InputMaybe<CountryCode>;
  mainCurrency?: InputMaybe<CurrencyCode>;
};

export type Query_CountryArgs = {
  country: CountryCode;
};

export type Query_CountryAllowancesConfigArgs = {
  country: CountryCode;
};

export type Query_CountryCallingCodesArgs = {
  filters?: InputMaybe<CountryCallingCodeFilters>;
};

export type Query_CountryLegalDataArgs = {
  country: CountryCode;
};

export type Query_CreditNotesWithPaginationArgs = {
  input?: InputMaybe<CreditNoteQueryInput>;
};

export type Query_CurrencyArgs = {
  code?: InputMaybe<CurrencyCode>;
};

export type Query_CustomPayrollReportArgs = {
  id: Scalars['ID'];
};

export type Query_CustomPayrollReportConfigsArgs = {
  filter: CustomPayrollReportConfigFilter;
};

export type Query_CustomPayrollReportsArgs = {
  filter: CustomPayrollReportFilter;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CustomerIntegrationDefinitionsArgs = {
  filters?: InputMaybe<CustomerIntegrationFilter>;
};

export type Query_CycleContractsArgs = {
  cycleId: Scalars['ID'];
  filter?: InputMaybe<CycleContractsFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_DataChangeReportArgs = {
  filter?: InputMaybe<DataChangeFilter>;
};

export type Query_DepartmentsArgs = {
  filters?: InputMaybe<DepartmentFilters>;
};

export type Query_DisputesArgs = {
  filter?: InputMaybe<DisputeFilter>;
  page?: InputMaybe<PageRequest>;
};

export type Query_DownloadCompanyPayableReportArgs = {
  input: DownloadCompanyPayableReportInput;
};

export type Query_DownloadCustomPayrollReportArgs = {
  id: Scalars['ID'];
};

export type Query_DownloadPayrollReconciliationDataArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_DownloadSalaryInvoiceSourceReportArgs = {
  input: SalaryInvoiceSourceReportDownloadInput;
};

export type Query_EligibleCountriesForEorArgs = {
  payrollCycleEntityTypes: Array<PayrollCycleEntityType>;
};

export type Query_EligibleEntitiesForPeoArgs = {
  payrollCycleEntityType: PayrollCycleEntityType;
};

export type Query_EmailTemplateArgs = {
  id: Scalars['ID'];
};

export type Query_EmailTemplateByTypeArgs = {
  type: Scalars['String'];
};

export type Query_EmployeePricingForCountryArgs = {
  country?: InputMaybe<CountryCode>;
};

export type Query_EmployeePricingForRegionArgs = {
  region?: InputMaybe<Region>;
};

export type Query_EquipmentIncompleteProcurementsDownloadArgs = {
  filter?: InputMaybe<EquipmentProcurementFilter>;
};

export type Query_EquipmentsDownloadArgs = {
  filter?: InputMaybe<EquipmentFilter>;
};

export type Query_ExpenseArgs = {
  id: Scalars['ID'];
};

export type Query_ExpenseCategoriesArgs = {
  filter?: InputMaybe<ExpenseCategoryFilter>;
};

export type Query_ExpenseValidationInfoArgs = {
  input: ExpenseValidationInfoInput;
};

export type Query_ExpensesArgs = {
  filters?: InputMaybe<ExpenseFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ExpensesReportArgs = {
  filters: ExpenseFilters;
};

export type Query_FeeComponentDefinitionArgs = {
  filter: FeeComponentDefinitionFilter;
};

export type Query_FeeComponentTierDefinitionArgs = {
  offeringCode?: InputMaybe<OfferingCode>;
};

export type Query_FileDownloadHistoryArgs = {
  filter: FileDownloadHistoryFilter;
};

export type Query_FilteredDataChangeArgs = {
  filter?: InputMaybe<DataChangeFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_FinancialTransactionInspectArgs = {
  transactionId: Scalars['String'];
};

export type Query_GenerateSalaryInvoiceSourceReportsArgs = {
  input: SalaryInvoiceSourceReportGenerationInput;
};

export type Query_GetAllApproversArgs = {
  category: ApprovalCategory;
  itemId: Scalars['ID'];
};

export type Query_GetApplicableExceptionCategoriesArgs = {
  request?: InputMaybe<PayrollContractExceptionReasonCategoryRequest>;
};

export type Query_GetAvailableReportsArgs = {
  input?: InputMaybe<ReportId>;
};

export type Query_GetCompanyPayInMethodArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_GetCompanyPrefillDataArgs = {
  input?: InputMaybe<CompanyPreFillDataInput>;
};

export type Query_GetComponentAccessibilityRulesArgs = {
  contractID?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<MemberExperiencePage>;
};

export type Query_GetEmployeesToSendInviteToArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetEmployeesToSyncArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetEmployeesToSyncEorArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetExternalLeaveTypesArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetIntegrationEntityMappingStatusArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetIntegrationFieldsMappingArgs = {
  entityId: Scalars['ID'];
  integrationId: Scalars['ID'];
};

export type Query_GetInternalLeaveTypesArgs = {
  companyId: Scalars['ID'];
};

export type Query_GetLastWorkingDayRangeArgs = {
  contractId: Scalars['ID'];
};

export type Query_GetLatestEmployeesForImportArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetLatestSyncResultForIntegrationArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetLeaveTypeMappingDefinitionArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetMemberPersonalDetailsAccessibilityRulesArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
};

export type Query_GetPaymentOptionsArgs = {
  filters: PaymentOptionFilters;
};

export type Query_GetPayrollReconciliationDataArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_GetPendingEmployeesToSyncArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetPositionsForIntegrationsArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetReportArgs = {
  input?: InputMaybe<Scalars['String']>;
};

export type Query_GetSepaibanDetailsFieldInformationArgs = {
  iban?: InputMaybe<Scalars['String']>;
};

export type Query_GetTentativeLastWorkingDayArgs = {
  contractId: Scalars['ID'];
};

export type Query_GetTimeOffsForPayrollArgs = {
  input: PayrollTimeOffFilter;
};

export type Query_HolidaysArgs = {
  countries: Array<InputMaybe<CountryCode>>;
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type Query_InsuranceCoverageGroupsArgs = {
  input: BenefitPartnerCountryFilters;
};

export type Query_IsNonHrisManagerArgs = {
  companyUserId: Scalars['ID'];
};

export type Query_KybArgs = {
  id: Scalars['ID'];
};

export type Query_KybListArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Query_ManagerArgs = {
  filters: ManagerFilters;
};

export type Query_ManagerDirectReportsRuleArgs = {
  managerId: Scalars['ID'];
};

export type Query_MemberArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_MemberPayableCompanyInvoicesArgs = {
  filters?: InputMaybe<MemberPayableCompanyInvoiceFilters>;
};

export type Query_MemberPayableDownloadInvoiceSourceReportArgs = {
  input: MemberPayableInvoiceSourceReportExportInput;
};

export type Query_MemberPayableReportArgs = {
  filters?: InputMaybe<MemberPayableReportFilter>;
};

export type Query_MemberPayrollCycleDetailArgs = {
  filter: MemberPayrollCycleDetailFilter;
};

export type Query_MemberPayrollCyclesArgs = {
  filter: MemberPayrollCycleFilter;
};

export type Query_MembersWithPaginationArgs = {
  input?: InputMaybe<MembersInput>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_MsaAddendumDraftSampleArgs = {
  msaAddendumInput: MsaAddendumInput;
};

export type Query_OffboardingExperienceStateArgs = {
  contractId: Scalars['ID'];
};

export type Query_OnboardingArgs = {
  legalEntityId?: InputMaybe<Scalars['ID']>;
  type: ContractType;
};

export type Query_OnboardingAuditArgs = {
  filter: OnboardingAuditFilter;
};

export type Query_OperationsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_OperationsUsersArgs = {
  roles?: InputMaybe<Array<OperationsUserRole>>;
};

export type Query_OrgDirectoryArgs = {
  filters: OrgDirectoryFilters;
};

export type Query_OrgHierarchyArgs = {
  companyId: Scalars['ID'];
};

export type Query_OrgRoleAvailableManagersArgs = {
  companyId: Scalars['ID'];
};

export type Query_OrgRolesArgs = {
  filter: OrgRoleFilters;
};

export type Query_PartnerArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_PartnerPayrollArgs = {
  countryCode?: InputMaybe<CountryCode>;
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type Query_PartnerPayrollSchemaArgs = {
  input: PartnerPayrollSchemaInput;
};

export type Query_PartnerPayrollsArgs = {
  input?: InputMaybe<GetPartnerPayrollInput>;
};

export type Query_PartnersArgs = {
  capability?: InputMaybe<PartnerCapability>;
  country?: InputMaybe<CountryCode>;
};

export type Query_PaySupplementArgs = {
  id: Scalars['ID'];
};

export type Query_PaySupplementsArgs = {
  filters?: InputMaybe<PaySupplementFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PaySupplementsReportArgs = {
  filters: PaySupplementFilters;
};

export type Query_PaymentAccountArgs = {
  id: Scalars['ID'];
};

export type Query_PaymentBundlePreviewArgs = {
  items?: InputMaybe<Array<InputMaybe<PaymentBundlePreviewItemInput>>>;
};

export type Query_PaymentBundlesArgs = {
  filters?: InputMaybe<PaymentBundleFilters>;
};

export type Query_PayrollBulkUploadConfirmRulesArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollBulkUploadConfirmStatusArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollBulkUploadCreateRulesArgs = {
  fileName?: InputMaybe<Scalars['String']>;
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollBulkUploadTemplateArgs = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type Query_PayrollBulkUploadValidateRulesArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollConfigsWithNextPayrollCyclesArgs = {
  companyLegalEntityId: Scalars['ID'];
  multiplierLegalEntityId?: InputMaybe<Scalars['ID']>;
  payFrequency?: InputMaybe<PayFrequency>;
};

export type Query_PayrollContractArgs = {
  filter: PayrollContractFilter;
};

export type Query_PayrollCycleArgs = {
  id: Scalars['ID'];
};

export type Query_PayrollCycleConfigsArgs = {
  filter?: InputMaybe<PayrollCycleConfigsFilter>;
};

export type Query_PayrollCycleForDomainArgs = {
  contractId: Scalars['ID'];
  domain: PayrollInputDomainType;
  domainId?: InputMaybe<Scalars['ID']>;
  referenceDate?: InputMaybe<Scalars['Date']>;
  returnClosestToReferenceDateUpcomingCutOff: Scalars['Boolean'];
};

export type Query_PayrollCyclePaymentArgs = {
  id: Scalars['ID'];
};

export type Query_PayrollCyclePaymentsArgs = {
  filters?: InputMaybe<PayrollCyclePaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PayrollCyclesArgs = {
  filter?: InputMaybe<PayrollCycleFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PayrollCyclesForCalendarArgs = {
  filter: PayrollCalendarFilters;
};

export type Query_PayrollDocumentsArgs = {
  cycleId: Scalars['ID'];
  filter?: InputMaybe<CycleContractsFilter>;
};

export type Query_PayrollFileDownloadArgs = {
  fileDownloadId: Scalars['ID'];
};

export type Query_PayrollInputFileArgs = {
  filters?: InputMaybe<ContractFilters>;
};

export type Query_PayrollInputFileDownloadArgs = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type Query_PayrollInputFilePartnerBulkDownloadArgs = {
  filter?: InputMaybe<PayrollCycleFilter>;
};

export type Query_PayrollParserConfigArgs = {
  filter?: InputMaybe<PayrollParserConfigFilter>;
};

export type Query_PayrollPaymentBatchArgs = {
  id: Scalars['ID'];
};

export type Query_PayrollPaymentBatchesArgs = {
  filters?: InputMaybe<PayrollPaymentBatchFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PayrollReportDownloadArgs = {
  filter: CompanyPayrollReportExportInput;
};

export type Query_PayrollReportDownloadForCustomerArgs = {
  input: Scalars['String'];
};

export type Query_PayrollReportGeneratorConfigArgs = {
  filter?: InputMaybe<PayrollReportGeneratorConfigFilter>;
};

export type Query_PayrollReportsArgs = {
  pageRequest?: InputMaybe<PageRequest>;
  payrollFilter?: InputMaybe<PayrollFilter>;
};

export type Query_PayslipsArgs = {
  filters?: InputMaybe<PaySlipFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PerformanceReviewNotesArgs = {
  input?: InputMaybe<PerformanceReviewNoteInput>;
};

export type Query_PlatformMergeDevLinkTokenArgs = {
  companyId: Scalars['ID'];
  platformId: Scalars['ID'];
};

export type Query_PlatformUpdaterJobsArgs = {
  input: PlatformJobMetaDataFilter;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_RecruitersArgs = {
  filters?: InputMaybe<RecruiterFilters>;
};

export type Query_SalaryCalculationArgs = {
  params?: InputMaybe<SalaryCalculationParam>;
};

export type Query_SalaryCalculationMinimumWageArgs = {
  params: SalaryCalculationMinimumWageParam;
};

export type Query_SalaryCalculationParamsArgs = {
  country?: InputMaybe<CountryCode>;
  payableType?: InputMaybe<Array<CalculationPayableType>>;
};

export type Query_SalaryCalculationPdfArgs = {
  params?: InputMaybe<SalaryCalculationParam>;
};

export type Query_SupportedCountriesArgs = {
  contractType: ContractType;
};

export type Query_SyncStatusArgs = {
  integrationId: Scalars['ID'];
  syncType?: InputMaybe<SyncType>;
};

export type Query_SyncSummaryResultDownloadArgs = {
  input: SyncSummaryResultDownloadInput;
};

export type Query_TemplatesArgs = {
  filter: TemplateFilter;
};

export type Query_TimeOffContractRequirementsArgs = {
  contractId: Scalars['ID'];
};

export type Query_TimeOffPolicyAssignmentDetailsArgs = {
  policyId: Scalars['ID'];
};

export type Query_TimeOffSummariesArgs = {
  filter?: InputMaybe<TimeOffSummaryFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_TimeOffsArgs = {
  filter?: InputMaybe<TimeOffFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_TimesheetConfigsArgs = {
  filters?: InputMaybe<TimesheetConfigFilters>;
};

export type Query_TimesheetReportArgs = {
  filters: TimesheetFilters;
};

export type Query_TimesheetWithPaginationArgs = {
  filters: TimesheetFilters;
  pageRequest: PageRequest;
};

export type Query_ValidateIntegrationCredentialsArgs = {
  integrationId: Scalars['ID'];
};

export type Query_VisaDocumentRequirementsArgs = {
  categories: Array<LegalDocumentCategory>;
  destinationCountry: CountryCode;
  originCountry: CountryCode;
};

export type QuotationCostCalculation = {
  __typename?: 'QuotationCostCalculation';
  entryGroups?: Maybe<Array<Maybe<QuotationCostEntryGroup>>>;
  total?: Maybe<QuotationCostEntry>;
};

export type QuotationCostEntry = {
  __typename?: 'QuotationCostEntry';
  amountInBillingCurrency?: Maybe<AmountWithCurrency>;
  amountInLocalCurrency?: Maybe<AmountWithCurrency>;
  name?: Maybe<Scalars['String']>;
};

export type QuotationCostEntryGroup = {
  __typename?: 'QuotationCostEntryGroup';
  entries?: Maybe<Array<Maybe<QuotationCostEntry>>>;
  total?: Maybe<QuotationCostEntry>;
};

export type QuotationCostOverview = {
  __typename?: 'QuotationCostOverview';
  currency?: Maybe<CurrencyCode>;
  totalAnnualCost?: Maybe<Scalars['Float']>;
  totalMemberCount?: Maybe<Scalars['Int']>;
  totalMemberDeposit?: Maybe<Scalars['Float']>;
  totalMonthlyCost?: Maybe<Scalars['Float']>;
};

export type QuotationCountryWiseCostBreakdown = {
  __typename?: 'QuotationCountryWiseCostBreakdown';
  annually?: Maybe<QuotationCostCalculation>;
  country?: Maybe<CountryCode>;
  memberDeposit?: Maybe<AmountWithCurrency>;
  monthly?: Maybe<QuotationCostCalculation>;
};

export type QuotationCountryWiseCostSummary = {
  __typename?: 'QuotationCountryWiseCostSummary';
  annually?: Maybe<Scalars['Float']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<CurrencyCode>;
  memberCount?: Maybe<Scalars['Int']>;
  memberDeposit?: Maybe<Scalars['Float']>;
  monthly?: Maybe<Scalars['Float']>;
};

export enum QuotationDiscountPlanType {
  ANNUAL_PAYMENT = 'ANNUAL_PAYMENT',
}

export enum QuotationDiscountType {
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
}

export type QuotationMemberDiscountInput = {
  amount: Scalars['Float'];
  currency: CurrencyCode;
  discountType: QuotationDiscountType;
  planType: QuotationDiscountPlanType;
};

export type QuotationMemberDiscountInputData = {
  __typename?: 'QuotationMemberDiscountInputData';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  discountType?: Maybe<QuotationDiscountType>;
  planType?: Maybe<QuotationDiscountPlanType>;
};

export type QuotationMemberInput = {
  benefitPackageId?: InputMaybe<Scalars['String']>;
  depositAmount?: InputMaybe<Scalars['Float']>;
  depositAmountCurrency?: InputMaybe<CurrencyCode>;
  discounts?: InputMaybe<Array<InputMaybe<QuotationMemberDiscountInput>>>;
  /** @deprecated Use grossSalary and grossSalaryFrequency */
  grossMonthly?: InputMaybe<Scalars['Float']>;
  grossSalary?: InputMaybe<Scalars['Float']>;
  grossSalaryFrequency?: InputMaybe<RateFrequency>;
  managementFee?: InputMaybe<Scalars['Float']>;
  managementFeeCurrency?: InputMaybe<CurrencyCode>;
  memberCountry: CountryCode;
  memberCountryState?: InputMaybe<Scalars['String']>;
  memberCurrency: CurrencyCode;
};

export type QuotationMemberInputData = {
  __typename?: 'QuotationMemberInputData';
  benefitPackageId?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['Float']>;
  depositAmountCurrency?: Maybe<CurrencyCode>;
  discounts?: Maybe<Array<Maybe<QuotationMemberDiscountInputData>>>;
  /** @deprecated Use grossSalary and grossSalaryFrequency */
  grossMonthly?: Maybe<Scalars['Float']>;
  grossSalary?: Maybe<Scalars['Float']>;
  grossSalaryFrequency?: Maybe<RateFrequency>;
  managementFee?: Maybe<Scalars['Float']>;
  managementFeeCurrency?: Maybe<CurrencyCode>;
  memberCountry?: Maybe<CountryCode>;
  memberCountryState?: Maybe<Scalars['String']>;
  memberCurrency?: Maybe<CurrencyCode>;
};

export type QuotationMemberWiseSummary = {
  __typename?: 'QuotationMemberWiseSummary';
  benefitsCost?: Maybe<Scalars['Float']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<CurrencyCode>;
  managementFee?: Maybe<Scalars['Float']>;
  monthlyPayrollCost?: Maybe<Scalars['Float']>;
  totalAnnualCost?: Maybe<Scalars['Float']>;
  totalMonthlyCost?: Maybe<Scalars['Float']>;
};

export enum RateFrequency {
  ANNUALLY = 'ANNUALLY',
  BI_MONTHLY = 'BI_MONTHLY',
  BI_WEEKLY = 'BI_WEEKLY',
  CUSTOM = 'CUSTOM',
  DAILY = 'DAILY',
  HALFYEARLY = 'HALFYEARLY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
  QUATERLY = 'QUATERLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  TRI_ANNUALLY = 'TRI_ANNUALLY',
  WEEKLY = 'WEEKLY',
}

export enum RateType {
  CTC = 'CTC',
  GROSS = 'GROSS',
  NET = 'NET',
}

export enum ReconciliationCategory {
  ACTIVE_MEMBER_INVALID = 'ACTIVE_MEMBER_INVALID',
  ACTIVE_MEMBER_MISSING = 'ACTIVE_MEMBER_MISSING',
  EXPENSE_INVALID = 'EXPENSE_INVALID',
  EXPENSE_MISMATCH = 'EXPENSE_MISMATCH',
  EXPENSE_MISSING = 'EXPENSE_MISSING',
  MEMBER_INVALID = 'MEMBER_INVALID',
  MEMBER_MISSING = 'MEMBER_MISSING',
  NEW_HIRE_MEMBER_INVALID = 'NEW_HIRE_MEMBER_INVALID',
  NEW_HIRE_MEMBER_MISSING = 'NEW_HIRE_MEMBER_MISSING',
  SALARY_INVALID = 'SALARY_INVALID',
  SALARY_MISMATCH = 'SALARY_MISMATCH',
  SALARY_MISSING = 'SALARY_MISSING',
  TERMINATING_MEMBER_INVALID = 'TERMINATING_MEMBER_INVALID',
  TERMINATING_MEMBER_MISSING = 'TERMINATING_MEMBER_MISSING',
}

export enum ReconciliationGroup {
  EXPENSE = 'EXPENSE',
  MEMBER = 'MEMBER',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY = 'SALARY',
}

export type ReconciliationResult = {
  __typename?: 'ReconciliationResult';
  details?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  messageKey?: Maybe<Scalars['String']>;
  type?: Maybe<ReconciliationResultType>;
};

export enum ReconciliationResultSeverity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum ReconciliationResultStatus {
  DISCARDED = 'DISCARDED',
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
}

export enum ReconciliationResultType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

export type Recruiter = {
  __typename?: 'Recruiter';
  description?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  features?: Maybe<Array<Maybe<RecruiterFeature>>>;
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<DocumentReadable>;
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<PhoneNumber>>>;
};

export type RecruiterFeature = {
  __typename?: 'RecruiterFeature';
  category?: Maybe<FeatureCategory>;
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecruiterFeatureInput = {
  category: FeatureCategory;
  values: Array<InputMaybe<Scalars['String']>>;
};

export type RecruiterFilters = {
  features?: InputMaybe<Array<InputMaybe<RecruiterFeatureInput>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RecruiterInput = {
  description?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<InputMaybe<EmailAddressInput>>>;
  features?: InputMaybe<Array<InputMaybe<RecruiterFeatureInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<InputMaybe<PhoneNumberInput>>>;
};

export type RefreshCompanyPayrollCycleInput = {
  companyPayrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RegenerateAgreementInput = {
  contractId: Scalars['ID'];
  createNewDocument?: InputMaybe<Scalars['Boolean']>;
  ignoreStatusCheck?: InputMaybe<Scalars['Boolean']>;
  preferredContractAgreementTemplate?: InputMaybe<Scalars['ID']>;
};

export type RegenerateOrderFormInput = {
  contractId: Scalars['ID'];
};

export enum Region {
  AFRICA = 'AFRICA',
  ASIA = 'ASIA',
}

export type RegionEmployeePricing = EmployeePricing & {
  __typename?: 'RegionEmployeePricing';
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  region?: Maybe<Region>;
};

export enum Relationship {
  DAUGHTER = 'DAUGHTER',
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  SON = 'SON',
  SPOUSE = 'SPOUSE',
}

export type ReportComparisonInput = {
  payrollMonth: PayrollMonthInput;
};

export type ReportConfig = {
  __typename?: 'ReportConfig';
  description?: Maybe<Scalars['String']>;
  filters: Array<Maybe<Filter>>;
  id?: Maybe<Scalars['String']>;
  reportSource?: Maybe<ReportSource>;
  title?: Maybe<Scalars['String']>;
};

export type ReportGeneratorConfig = {
  __typename?: 'ReportGeneratorConfig';
  configString?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ReportId = {
  id?: InputMaybe<Scalars['String']>;
};

export enum ReportSource {
  DOMAIN_SPECIFIC = 'DOMAIN_SPECIFIC',
  HYBRID = 'HYBRID',
  WAREHOUSE = 'WAREHOUSE',
}

export type ReportsToValidationResult = {
  __typename?: 'ReportsToValidationResult';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Request = {
  __typename?: 'Request';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  recipient?: Maybe<RequestUser>;
  requester?: Maybe<RequestUser>;
  status?: Maybe<RequestStatus>;
  type?: Maybe<RequestType>;
};

export enum RequestStatus {
  REQUESTED = 'REQUESTED',
  RESOLVED = 'RESOLVED',
}

export enum RequestType {
  GENERATE_MSA = 'GENERATE_MSA',
}

export type RequestUser = CompanyUser | OperationsUser;

export type RequirementField = {
  __typename?: 'RequirementField';
  allowedValues?: Maybe<Array<Maybe<AllowedValue>>>;
  errorMessage?: Maybe<Scalars['String']>;
  hasSubFields?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  regex?: Maybe<Scalars['String']>;
  subFields?: Maybe<Array<Maybe<RequirementSubField>>>;
  type?: Maybe<Scalars['String']>;
};

export type RequirementFieldValue = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type RequirementFiltersInput = {
  applyTo?: InputMaybe<LegalDataRequirementApplicability>;
  category?: InputMaybe<LegalDocumentCategory>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  financialYear?: InputMaybe<Scalars['Int']>;
  joinDate?: InputMaybe<Scalars['Date']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type RequirementSubField = {
  __typename?: 'RequirementSubField';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<RequirementField>>>;
};

export type RequirementsInputs = {
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
};

export type ResolveDisputeInput = {
  disputeId: Scalars['ID'];
};

export enum RiskAssessmentStatus {
  COMPLETED = 'COMPLETED',
  INITIATED = 'INITIATED',
  OUTDATED = 'OUTDATED',
}

export type Rule = {
  __typename?: 'Rule';
  conditions?: Maybe<Array<Condition>>;
  type?: Maybe<RuleType>;
};

export type RuleInput = {
  conditions?: InputMaybe<Array<ConditionInput>>;
  type?: InputMaybe<RuleType>;
};

export enum RuleType {
  ALL = 'ALL',
  BY_CONDITION = 'BY_CONDITION',
}

export type SepaCompanyPayinMethod = CompanyPayInMethod &
  PayInMethod & {
    __typename?: 'SEPACompanyPayinMethod';
    bankDetails?: Maybe<Array<Maybe<CompanyPayInMethodBankDetails>>>;
    chargeBackPeriod?: Maybe<Scalars['Int']>;
    company?: Maybe<Company>;
    context?: Maybe<PayInMethodContext>;
    currentStatus?: Maybe<CompanyPayInMethodWorkflowStatus>;
    currentStep?: Maybe<PayInMethodDefinitionStep>;
    data?: Maybe<Array<Maybe<KeyValue>>>;
    documents?: Maybe<Array<Maybe<DocumentData>>>;
    id: Scalars['ID'];
    isActive?: Maybe<Scalars['Boolean']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    payInMethodType?: Maybe<PayInMethodType>;
    workflowSteps: Array<Maybe<CompanyPayInMethodWorkflowStep>>;
  };

export type SepaibanDetailsFieldInformationResponse = {
  __typename?: 'SEPAIBANDetailsFieldInformationResponse';
  data?: Maybe<Array<Maybe<KeyValue>>>;
};

export type SsoPreference = {
  __typename?: 'SSOPreference';
  company?: Maybe<Company>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identityProvider?: Maybe<IdentityProvider>;
};

export type SalaryCalculation = {
  __typename?: 'SalaryCalculation';
  companyPayable: Array<CalculationBreakdown>;
  employeePayable: Array<CalculationBreakdown>;
};

export type SalaryCalculationMinimumWage = {
  __typename?: 'SalaryCalculationMinimumWage';
  amount: Scalars['Float'];
  currency: CurrencyCode;
  frequency: RateFrequency;
};

export type SalaryCalculationMinimumWageParam = {
  country: CountryCode;
  currency?: InputMaybe<CurrencyCode>;
  frequency: RateFrequency;
  state?: InputMaybe<Scalars['String']>;
};

export type SalaryCalculationParam = {
  amount: Scalars['Float'];
  constantOverrides?: InputMaybe<Array<ConstantOverride>>;
  country: CountryCode;
  currency: CurrencyCode;
  frequency: RateFrequency;
  payableType: Array<CalculationPayableType>;
  rateType: RateType;
  state?: InputMaybe<Scalars['String']>;
};

export type SalaryCalculationRequestEmailParam = {
  amount: Scalars['Float'];
  country: CountryCode;
  currency: CurrencyCode;
  frequency: RateFrequency;
  payableType?: InputMaybe<Array<CalculationPayableType>>;
};

export type SalaryInvoiceSourceReportDownloadInput = {
  companyId: Scalars['ID'];
  companyPayableId?: InputMaybe<Scalars['ID']>;
  payableMonth: PayableMonthInput;
};

export type SalaryInvoiceSourceReportGenerationInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  payableMonth: PayableMonthInput;
};

export type SalaryReview = {
  __typename?: 'SalaryReview';
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currentCompensation?: Maybe<Compensation>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  reviewDocument?: Maybe<DocumentReadable>;
  revisedCompensation?: Maybe<Compensation>;
  state?: Maybe<SalaryReviewStatus>;
};

export type SalaryReviewInput = {
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  effectiveDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  revisedCompensation: ContractUpdateCompensationInput;
};

export enum SalaryReviewStatus {
  ACTIVATED = 'ACTIVATED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DRAFT = 'DRAFT',
  SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
  SENT_FOR_MEMBER_APPROVAL = 'SENT_FOR_MEMBER_APPROVAL',
  SENT_TO_OPS = 'SENT_TO_OPS',
}

export type SaveIntegrationFieldsMappingInput = {
  entityId: Scalars['ID'];
  integrationId: Scalars['ID'];
  mapping?: InputMaybe<Array<InputMaybe<FieldMappingInput>>>;
};

export type SaveLeaveTypesMappingInput = {
  integrationId: Scalars['ID'];
  mapping?: InputMaybe<Array<InputMaybe<LeaveTypeMappingDefinitionInput>>>;
};

export type ScheduleTime = {
  __typename?: 'ScheduleTime';
  unit: ScheduleTimeUnit;
  value: Scalars['Int'];
};

export type ScheduleTimeInput = {
  unit: ScheduleTimeUnit;
  value: Scalars['Int'];
};

export enum ScheduleTimeUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export type SeatAssignInput = {
  contractId: Scalars['ID'];
  effectiveDate: Scalars['Date'];
  pricingPlanId: Scalars['ID'];
  seatId?: InputMaybe<Scalars['ID']>;
};

export type SeatConfigurationInput = {
  count: Scalars['Int'];
  country: CountryCode;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  workStatus: CountryWorkStatus;
};

export enum SeatEventType {
  CLOSE = 'CLOSE',
  FILL = 'FILL',
  OPEN = 'OPEN',
  RELEASE = 'RELEASE',
}

export type SeatReleaseInput = {
  contractId: Scalars['ID'];
  pricingPlanId: Scalars['ID'];
};

export enum SeatStatus {
  CLOSED = 'CLOSED',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
}

export enum Section {
  ALLOCATED_IT_ASSETS = 'ALLOCATED_IT_ASSETS',
  COMPENSATION = 'COMPENSATION',
  CONTRACT = 'CONTRACT',
  CONTRIBUTION = 'CONTRIBUTION',
  DOCUMENTS = 'DOCUMENTS',
  PERFORMANCE_REVIEW = 'PERFORMANCE_REVIEW',
  WORK_INFO = 'WORK_INFO',
}

export type SectionAccessibilityResponse = {
  __typename?: 'SectionAccessibilityResponse';
  accessibility?: Maybe<AccessibilityResponse>;
  section: MemberPersonalDetailsSection;
  visible?: Maybe<Scalars['Boolean']>;
};

export enum SemimonthlySegment {
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
}

export type SendEmailToOnboardingSpecialistResponse = {
  __typename?: 'SendEmailToOnboardingSpecialistResponse';
  message?: Maybe<Scalars['String']>;
  requesterEmail?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SendPayrollCycleForReviewInput = {
  payrollCycleId: Scalars['ID'];
};

export type SendReportForReviewInput = {
  country?: InputMaybe<CountryCode>;
  month?: InputMaybe<PayrollMonthInput>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export enum SeverancePayOption {
  FOUR_WEEKS = 'FOUR_WEEKS',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NO_SEVERANCE = 'NO_SEVERANCE',
  ONE_MONTH = 'ONE_MONTH',
  ONE_WEEK = 'ONE_WEEK',
  THREE_MONTHS = 'THREE_MONTHS',
  THREE_WEEKS = 'THREE_WEEKS',
  TWO_MONTHS = 'TWO_MONTHS',
  TWO_WEEKS = 'TWO_WEEKS',
}

export type Signature = {
  __typename?: 'Signature';
  employerSignatory?: Maybe<Scalars['String']>;
  employerSignedDate?: Maybe<Scalars['DateTime']>;
  memberSignedDate?: Maybe<Scalars['DateTime']>;
};

export type Sort = {
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum StateCode {
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  CALIFORNIA = 'CALIFORNIA',
  COLORADO = 'COLORADO',
  CONNECTICUT = 'CONNECTICUT',
  DELAWARE = 'DELAWARE',
  FLORIDA = 'FLORIDA',
  GEORGIA = 'GEORGIA',
  HAWAII = 'HAWAII',
  IDAHO = 'IDAHO',
  ILLINOIS = 'ILLINOIS',
  INDIANA = 'INDIANA',
  IOWA = 'IOWA',
  KANSAS = 'KANSAS',
  KENTUCKY = 'KENTUCKY',
  LOUISIANA = 'LOUISIANA',
  MAINE = 'MAINE',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MONTANA = 'MONTANA',
  NEBRASKA = 'NEBRASKA',
  NEVADA = 'NEVADA',
  NEWYORK = 'NEWYORK',
  NEW_HAMPSHIRE = 'NEW_HAMPSHIRE',
  NEW_JERSEY = 'NEW_JERSEY',
  NEW_MEXICO = 'NEW_MEXICO',
  NORTH_CAROLINA = 'NORTH_CAROLINA',
  NORTH_DAKOTA = 'NORTH_DAKOTA',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  OREGON = 'OREGON',
  PENNSYLVANIA = 'PENNSYLVANIA',
  RHODE_ISLAND = 'RHODE_ISLAND',
  SOUTH_CAROLINA = 'SOUTH_CAROLINA',
  SOUTH_DAKOTA = 'SOUTH_DAKOTA',
  TENNESSEE = 'TENNESSEE',
  TEXAS = 'TEXAS',
  UTAH = 'UTAH',
  VERMONT = 'VERMONT',
  VIRGINIA = 'VIRGINIA',
  WASHINGTON = 'WASHINGTON',
  WASHINGTON_DC = 'WASHINGTON_DC',
  WEST_VIRGINIA = 'WEST_VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING',
}

export type StatutoryReportDetails = CustomPayrollReportDetails & {
  __typename?: 'StatutoryReportDetails';
  company?: Maybe<Company>;
  country?: Maybe<CountryCode>;
  employingLegalEntity?: Maybe<LegalEntity>;
  files: Array<CustomPayrollReportFile>;
  financialYearFrom?: Maybe<Scalars['Date']>;
  financialYearTo?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  month?: Maybe<MonthYear>;
  quarter?: Maybe<Scalars['Int']>;
  reportCategory: CustomPayrollReportCategory;
  reportFrequency?: Maybe<CustomPayrollReportFrequency>;
  uploadedCount?: Maybe<UploadedFileCount>;
};

export enum Strategy {
  PANDA_DOC = 'PANDA_DOC',
  PDF_MONKEY = 'PDF_MONKEY',
  STATIC = 'STATIC',
}

export type StringConfigValue = {
  __typename?: 'StringConfigValue';
  value: Scalars['String'];
};

export type StringParam = {
  __typename?: 'StringParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SyncEorManuallyResult = {
  __typename?: 'SyncEORManuallyResult';
  integrationId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  syncId?: Maybe<Scalars['String']>;
};

export type SyncEmployees = {
  __typename?: 'SyncEmployees';
  employees?: Maybe<Array<Maybe<Employee>>>;
  extSyncId?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  syncId?: Maybe<Scalars['ID']>;
};

export enum SyncStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export type SyncSummaryResultDownloadInput = {
  syncId: Scalars['String'];
};

export type SyncSummaryResultDownloadOutput = {
  __typename?: 'SyncSummaryResultDownloadOutput';
  isDownloaded?: Maybe<Scalars['Boolean']>;
  reportFile?: Maybe<DocumentReadable>;
};

export enum SyncType {
  INCOMING = 'INCOMING',
  MANUAL_OUTGOING = 'MANUAL_OUTGOING',
  OUTGOING = 'OUTGOING',
}

export type TaskResponse = {
  __typename?: 'TaskResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Template = {
  __typename?: 'Template';
  contractId?: Maybe<Scalars['ID']>;
  country?: Maybe<CountryCode>;
  countryState?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  link?: Maybe<Scalars['String']>;
  metadata?: Maybe<TemplateMetadata>;
  strategy: Strategy;
  type: Scalars['String'];
  version: Scalars['Int'];
  workspace?: Maybe<Workspace>;
};

export type TemplateFilter = {
  contractId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  entityId?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Language>;
  stateCode?: InputMaybe<Scalars['String']>;
  strategy?: InputMaybe<Strategy>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type TemplateInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  countryState?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['ID']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Language>;
  metadata?: InputMaybe<TemplateMetadataInput>;
  strategy: Strategy;
  type: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
  workspace?: InputMaybe<Workspace>;
};

export type TemplateMetadata = {
  __typename?: 'TemplateMetadata';
  languagesCount?: Maybe<Scalars['Int']>;
};

export type TemplateMetadataInput = {
  languagesCount?: InputMaybe<Scalars['Int']>;
};

export type TerminatePayrollWorkflowRequest = {
  payrollCycleIds: Array<Scalars['ID']>;
};

export type TextField = {
  __typename?: 'TextField';
  defaultValue?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TextFieldInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TimeOff = {
  __typename?: 'TimeOff';
  approverInfos?: Maybe<Array<ItemApproverInfo>>;
  approvers?: Maybe<Array<Maybe<Person>>>;
  /** @deprecated use endDate instead. https://app.clickup.com/t/2kchkk4 */
  backToWorkDate?: Maybe<TimeOffDate>;
  changeReason?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<TimeOffDate>;
  id: Scalars['ID'];
  isActionable?: Maybe<Scalars['Boolean']>;
  noOfDays?: Maybe<Scalars['Float']>;
  startDate?: Maybe<TimeOffDate>;
  status?: Maybe<TimeOffStatus>;
  type?: Maybe<TimeOffType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type TimeOffAllocationInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedContractIds?: InputMaybe<Array<Scalars['ID']>>;
  expiryDate?: InputMaybe<Scalars['Date']>;
};

export type TimeOffApprovalItem = ApprovalItem & {
  __typename?: 'TimeOffApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<ApprovalItemStatus>;
  timeOffId?: Maybe<Scalars['ID']>;
  timeOffItem?: Maybe<TimeOff>;
};

export type TimeOffContractRequirements = {
  __typename?: 'TimeOffContractRequirements';
  assignedEntitlements?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  availableEntitlements?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  clause?: Maybe<Scalars['String']>;
};

export type TimeOffCreateInput = {
  /** deprecated, to be removed once FE completely migrates to `endDate` */
  backToWorkDate?: InputMaybe<TimeOffDateInput>;
  description?: InputMaybe<Scalars['String']>;
  /** to replace `backToWorkDate` - https://app.clickup.com/t/2kchkk4 */
  endDate?: InputMaybe<TimeOffDateInput>;
  /** id is optional. a) id=null -> submitting a new time off. b) id=123 -> submitting an existing DRAFT time off with id=123 */
  id?: InputMaybe<Scalars['ID']>;
  /** deprecated, don't use isDraft! The mutation will ignore isDraft and always submit for approval */
  isDraft?: InputMaybe<Scalars['Boolean']>;
  noOfDays: Scalars['Float'];
  startDate: TimeOffDateInput;
  type: Scalars['String'];
};

export type TimeOffDate = {
  __typename?: 'TimeOffDate';
  /** @deprecated All time values are always 00:00:00. Use `dateOnly` instead */
  date?: Maybe<Scalars['DateTime']>;
  dateOnly?: Maybe<Scalars['Date']>;
  session?: Maybe<TimeOffSession>;
};

export type TimeOffDateInput = {
  /** deprecated. All time values are always 00:00:00. Use `dateOnly` instead */
  date?: InputMaybe<Scalars['DateTime']>;
  dateOnly?: InputMaybe<Scalars['Date']>;
  session?: InputMaybe<TimeOffSession>;
};

export type TimeOffDuration = {
  __typename?: 'TimeOffDuration';
  unit?: Maybe<TimeOffUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type TimeOffDurationInput = {
  unit?: InputMaybe<TimeOffUnit>;
  value?: InputMaybe<Scalars['Float']>;
};

export type TimeOffFilter = {
  contractCountry?: InputMaybe<CountryCode>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatus?: InputMaybe<ContractStatus>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  startDateRange?: InputMaybe<DateRange>;
  timeOffStatus?: InputMaybe<Array<TimeOffStatus>>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type TimeOffFixedValidation = {
  __typename?: 'TimeOffFixedValidation';
  allowedContractStatuses?: Maybe<Array<Maybe<ContractStatus>>>;
  defaultValue?: Maybe<Scalars['Float']>;
  isUnlimitedLeavesAllowed?: Maybe<Scalars['Boolean']>;
  maximum?: Maybe<Scalars['Float']>;
  minimum?: Maybe<Scalars['Float']>;
  unit?: Maybe<TimeOffUnit>;
};

export type TimeOffInsightDefinition = InsightDefinition & {
  __typename?: 'TimeOffInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  recommendedValue?: Maybe<Scalars['Float']>;
  timeOffTypeDefinition?: Maybe<TimeOffTypeDefinition>;
  timeOffUnit?: Maybe<TimeOffUnit>;
};

export type TimeOffPolicyAssignUsersInput = {
  contractIds: Array<Scalars['ID']>;
  timeOffPolicyId: Scalars['ID'];
  timeOffPolicyRule?: InputMaybe<RuleInput>;
  timeOffTypeId: Scalars['ID'];
};

export type TimeOffPolicyAssignmentDetailsResult = {
  __typename?: 'TimeOffPolicyAssignmentDetailsResult';
  assignedContracts?: Maybe<Array<Contract>>;
  rule?: Maybe<Rule>;
};

export type TimeOffPolicyCreateInput = {
  allocatedCount: TimeOffDurationInput;
  carryForwardConfig: CarryForwardConfigInput;
  isUnlimitedLeavesAllowed?: InputMaybe<Scalars['Boolean']>;
  policyName: Scalars['String'];
  timeOffTypeId: Scalars['ID'];
};

export type TimeOffPolicyUpdateInput = {
  allocatedCount: TimeOffDurationInput;
  carryForwardConfig?: InputMaybe<CarryForwardConfigInput>;
  id: Scalars['ID'];
  isUnlimitedLeavesAllowed?: InputMaybe<Scalars['Boolean']>;
  policyName: Scalars['String'];
};

export type TimeOffPolicyUsersValidationResult = {
  __typename?: 'TimeOffPolicyUsersValidationResult';
  invalidContractsToAssign: Array<Contract>;
  invalidContractsToUnassigned: Array<Contract>;
  validContractsToAssign: Array<Contract>;
};

export type TimeOffPolicyValidateUsersInput = {
  contractIds: Array<Scalars['ID']>;
  timeOffPolicyId: Scalars['ID'];
  timeOffTypeId: Scalars['ID'];
};

export type TimeOffReportInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type TimeOffRequirementFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  country?: InputMaybe<CountryFilter>;
  term?: InputMaybe<ContractTerm>;
};

export type TimeOffRequirements = {
  __typename?: 'TimeOffRequirements';
  clause?: Maybe<Scalars['String']>;
  definitions?: Maybe<Array<Maybe<TimeOffTypeDefinition>>>;
};

export type TimeOffSaveAsDraftInput = {
  /** deprecated, to be removed once FE completely migrates to `endDate` */
  backToWorkDate?: InputMaybe<TimeOffDateInput>;
  /** contractId is optional. a) null -> this is a member. Always leave it null in member exp!; b) 123 -> this is a companyUser saving as draft a timeoff on behalf of contractId=123 */
  contractId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  /** to replace `backToWorkDate` - https://app.clickup.com/t/2kchkk4 */
  endDate?: InputMaybe<TimeOffDateInput>;
  noOfDays: Scalars['Float'];
  startDate: TimeOffDateInput;
  type: Scalars['String'];
};

export enum TimeOffSession {
  AFTERNOON = 'AFTERNOON',
  MORNING = 'MORNING',
}

export type TimeOffSnapshot = {
  __typename?: 'TimeOffSnapshot';
  description?: Maybe<Scalars['String']>;
  noOfDays?: Maybe<Scalars['Int']>;
  status?: Maybe<TimeOffStatus>;
  timeOffId: Scalars['ID'];
};

export enum TimeOffStatus {
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  /** @deprecated Time off was created. Don't use SUBMITTED. Use APPROVAL_IN_PROGRESS instead */
  SUBMITTED = 'SUBMITTED',
  TAKEN = 'TAKEN',
}

export type TimeOffSummariesResponse = {
  __typename?: 'TimeOffSummariesResponse';
  page: PageResult;
  timeOffSummaries?: Maybe<Array<TimeOffType>>;
};

export type TimeOffSummaryFilter = {
  contractCountry?: InputMaybe<CountryCode>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
};

export type TimeOffType = {
  __typename?: 'TimeOffType';
  allocated?: Maybe<Scalars['Float']>;
  carried?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  definition?: Maybe<TimeOffTypeDefinition>;
  entitled?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['Float']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  remaining?: Maybe<Scalars['Float']>;
  taken?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['ID']>;
};

export type TimeOffTypeCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  isPaidLeave: Scalars['Boolean'];
  name: Scalars['String'];
};

export type TimeOffTypeDefinition = {
  __typename?: 'TimeOffTypeDefinition';
  assignedEmployeeCount?: Maybe<Scalars['Int']>;
  assignedEntityNames?: Maybe<Array<Scalars['String']>>;
  basis?: Maybe<Scalars['String']>;
  configuration?: Maybe<TimeoffConfiguration>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  policyName?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  status?: Maybe<TimeOffTypeDefinitionStatus>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  validation?: Maybe<Array<Maybe<TimeOffValidation>>>;
};

export enum TimeOffTypeDefinitionStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TimeOffTypeFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isPaidLeave?: InputMaybe<Scalars['Boolean']>;
};

export type TimeOffTypeInfo = {
  __typename?: 'TimeOffTypeInfo';
  companyId?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  isPaidLeave?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  status?: Maybe<TimeOffTypeStatus>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  usage?: Maybe<Scalars['Int']>;
};

export enum TimeOffTypeStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TimeOffTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  isPaidLeave: Scalars['Boolean'];
  name: Scalars['String'];
  typeId: Scalars['ID'];
};

export enum TimeOffUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export type TimeOffUpdateInput = {
  /** deprecated, to be removed once FE completely migrates to `endDate` */
  backToWorkDate?: InputMaybe<TimeOffDateInput>;
  description?: InputMaybe<Scalars['String']>;
  /** to replace `backToWorkDate` - https://app.clickup.com/t/2kchkk4 */
  endDate?: InputMaybe<TimeOffDateInput>;
  /** deprecated, don't use isDraft! BE doesn't change the timeoff status in `timeOffUpdate` */
  isDraft?: InputMaybe<Scalars['Boolean']>;
  noOfDays: Scalars['Float'];
  startDate: TimeOffDateInput;
  type: Scalars['String'];
};

export type TimeOffValidation = TimeOffFixedValidation;

export type TimeOffsResponse = {
  __typename?: 'TimeOffsResponse';
  allCount: Scalars['Int'];
  approvedCount: Scalars['Int'];
  page: PageResult;
  pendingCount: Scalars['Int'];
  timeOffs?: Maybe<Array<TimeOff>>;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  endTime?: Maybe<Scalars['Time']>;
  startTime?: Maybe<Scalars['Time']>;
};

export type TimeRangeInput = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export enum TimeUnit {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export enum TimeValueInputMethod {
  START_TIME_END_TIME = 'START_TIME_END_TIME',
  TOTAL_HOURS = 'TOTAL_HOURS',
}

export type TimeoffConfiguration = {
  __typename?: 'TimeoffConfiguration';
  allocation?: Maybe<AllocationConfig>;
  carryForward?: Maybe<CarryForwardConfig>;
};

export type Timesheet = {
  __typename?: 'Timesheet';
  approvedOn?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  cutOffDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<TimesheetStatus>;
  submittedOn?: Maybe<Scalars['DateTime']>;
  timeValueInputMethod?: Maybe<TimeValueInputMethod>;
  timesheetEntries?: Maybe<Array<Maybe<TimesheetEntry>>>;
  totalApprovedDays?: Maybe<Scalars['Int']>;
  /** @deprecated Now calculated on FE. The column in DB shouldn't be used anywhere. Details: 865cqtgah */
  totalClockedInDays?: Maybe<Scalars['Int']>;
  totalDays?: Maybe<Scalars['Int']>;
  /** @deprecated Use TimesheetTotalTimeSummary.totalTimeInMinutes instead */
  totalHours?: Maybe<Scalars['Float']>;
  totalRejectedDays?: Maybe<Scalars['Int']>;
  totalSubmittedDays?: Maybe<Scalars['Int']>;
  totalTimeSummary?: Maybe<TimesheetTotalTimeSummary>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type TimesheetConfigFilters = {
  countryCode?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<StateCode>;
};

export type TimesheetConfigs = {
  __typename?: 'TimesheetConfigs';
  countryConfigs?: Maybe<TimesheetCountryConfigs>;
};

export type TimesheetCountryConfigs = {
  __typename?: 'TimesheetCountryConfigs';
  countryCode?: Maybe<CountryCode>;
  countryStateCode?: Maybe<StateCode>;
  dayTypes?: Maybe<Array<Maybe<DayType>>>;
  hoursTypes?: Maybe<Array<Maybe<HoursType>>>;
};

export type TimesheetCreateUpdateInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type TimesheetEntry = {
  __typename?: 'TimesheetEntry';
  addedByInfo?: Maybe<AuditUser>;
  approvedByInfo?: Maybe<AuditUser>;
  approvedOn?: Maybe<Scalars['DateTime']>;
  breakTimes?: Maybe<Array<Maybe<TimeRange>>>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  dayType?: Maybe<DayType>;
  /** @deprecated Use TimesheetEntry.regularTimeInMinutes instead */
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isPaidLeave?: Maybe<Scalars['Boolean']>;
  lateTimeInMinutes?: Maybe<Scalars['Int']>;
  nightShiftTimeInMinutes?: Maybe<Scalars['Int']>;
  overtimeAtNightInMinutes?: Maybe<Scalars['Int']>;
  overtimeTotalInMinutes?: Maybe<Scalars['Int']>;
  regularTimeInMinutes?: Maybe<Scalars['Int']>;
  rejectedByInfo?: Maybe<AuditUser>;
  rejectedOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TimesheetEntryStatus>;
  submittedOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  workTimes?: Maybe<Array<Maybe<TimeRange>>>;
};

export type TimesheetEntryInput = {
  breakTimes?: InputMaybe<Array<InputMaybe<TimeRangeInput>>>;
  date?: InputMaybe<Scalars['Date']>;
  dayType?: InputMaybe<DayType>;
  hours?: InputMaybe<Scalars['Float']>;
  isPaidLeave?: InputMaybe<Scalars['Boolean']>;
  lateTimeInMinutes?: InputMaybe<Scalars['Int']>;
  nightShiftTimeInMinutes?: InputMaybe<Scalars['Int']>;
  overtimeAtNightInMinutes?: InputMaybe<Scalars['Int']>;
  overtimeTotalInMinutes?: InputMaybe<Scalars['Int']>;
  regularTimeInMinutes?: InputMaybe<Scalars['Int']>;
  workTimes?: InputMaybe<Array<InputMaybe<TimeRangeInput>>>;
};

export enum TimesheetEntryStatus {
  APPROVED = 'APPROVED',
  PAID = 'PAID',
  PARTIALLY_CREATED = 'PARTIALLY_CREATED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type TimesheetFilters = {
  approvedDateRange?: InputMaybe<DateRange>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  country?: InputMaybe<CountryCode>;
  hoursType?: InputMaybe<HoursType>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  memberName?: InputMaybe<Scalars['String']>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  startDateRange?: InputMaybe<DateRange>;
  timesheetIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  timesheetStatus?: InputMaybe<TimesheetStatus>;
  updateDateRange?: InputMaybe<DateRange>;
};

export type TimesheetResult = {
  __typename?: 'TimesheetResult';
  data?: Maybe<Array<Maybe<Timesheet>>>;
  pageResult?: Maybe<PageResult>;
};

export enum TimesheetStatus {
  APPROVED = 'APPROVED',
  OPEN = 'OPEN',
  /** @deprecated No longer supported */
  PARTIALLY_SUBMITTED = 'PARTIALLY_SUBMITTED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type TimesheetSummaryFilter = {
  contractTypes?: InputMaybe<Array<ContractType>>;
};

export type TimesheetTotalTimeSummary = {
  __typename?: 'TimesheetTotalTimeSummary';
  totalLateTimeInMinutes?: Maybe<Scalars['Int']>;
  totalTimeInMinutes?: Maybe<Scalars['Int']>;
  totalTimeSummaryItems?: Maybe<Array<Maybe<TimesheetTotalTimeSummaryItem>>>;
};

export type TimesheetTotalTimeSummaryItem = {
  __typename?: 'TimesheetTotalTimeSummaryItem';
  dayType?: Maybe<DayType>;
  hoursType?: Maybe<HoursType>;
  totalTimeInMinutes?: Maybe<Scalars['Int']>;
};

export type TimesheetUploadBulkInput = {
  companyId: Scalars['ID'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type TimesheetUploadError = {
  __typename?: 'TimesheetUploadError';
  contractId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type TimesheetUploadResult = {
  __typename?: 'TimesheetUploadResult';
  errors?: Maybe<Array<Maybe<TimesheetUploadError>>>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type TracingContextEntry = {
  __typename?: 'TracingContextEntry';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Transaction = {
  creditor?: Maybe<LedgerSide>;
  debitor?: Maybe<LedgerSide>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTerminalTransaction?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Payment>;
  paymentPartner?: Maybe<PaymentPartner>;
  status?: Maybe<TransactionStatus>;
  transferValue?: Maybe<TransferValue>;
};

export type TransactionCommand = {
  __typename?: 'TransactionCommand';
  companyId?: Maybe<Scalars['ID']>;
  cycle?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRangeOutput>;
  tracingContext?: Maybe<Array<Maybe<TracingContextEntry>>>;
  transactionAction?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
  transactionType?: Maybe<FinancialTransactionType>;
};

export type TransactionPayableItem = {
  __typename?: 'TransactionPayableItem';
  amountInBaseCurrency?: Maybe<Scalars['Float']>;
  baseCurrency?: Maybe<Scalars['String']>;
  billableCost?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  cycle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemCount?: Maybe<Scalars['Int']>;
  lineItemType?: Maybe<Scalars['String']>;
  periodEndDate?: Maybe<Scalars['String']>;
  periodStartDate?: Maybe<Scalars['String']>;
  seatId?: Maybe<Scalars['ID']>;
  taxType?: Maybe<Scalars['String']>;
};

export enum TransactionStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PENDING_ON_MULTIPLIER = 'PENDING_ON_MULTIPLIER',
  PENDING_ON_PARTNER = 'PENDING_ON_PARTNER',
  PROCESSING = 'PROCESSING',
  REFUNDED = 'REFUNDED',
  REFUND_INITIATED = 'REFUND_INITIATED',
  REFUND_PROCESSING = 'REFUND_PROCESSING',
}

export enum TransferType {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
}

export type TransferValue = {
  __typename?: 'TransferValue';
  amount?: Maybe<Scalars['Float']>;
  destinationCurrency?: Maybe<Scalars['String']>;
  type?: Maybe<TransferType>;
};

export type TransferValueAmount = {
  amount?: InputMaybe<Scalars['Float']>;
  destinationCurrency?: InputMaybe<Scalars['String']>;
};

export type TransferValueBreakdown = {
  __typename?: 'TransferValueBreakdown';
  component?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type TrinetCredentialInput = {
  companyId: Scalars['ID'];
  companyUserName: Scalars['String'];
  companyUserPassword: Scalars['String'];
  externalCompanyId?: InputMaybe<Scalars['String']>;
};

export type TrinetCredentialInputV2 = {
  companyUserName: Scalars['String'];
  companyUserPassword: Scalars['String'];
  externalCompanyId: Scalars['String'];
};

export type TrinetCredentialResult = {
  __typename?: 'TrinetCredentialResult';
  message: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export type UnmappedField = {
  __typename?: 'UnmappedField';
  children?: Maybe<Array<Maybe<UnmappedField>>>;
  fieldFromApp?: Maybe<Scalars['String']>;
  fieldId?: Maybe<Scalars['String']>;
  isCustomField?: Maybe<Scalars['Boolean']>;
  isMappedByThirdParty?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  subFields?: Maybe<Array<Maybe<UnmappedField>>>;
  type?: Maybe<Scalars['String']>;
};

export type UnmarriedPolicyRules = {
  __typename?: 'UnmarriedPolicyRules';
  maxAllowedTotal?: Maybe<Scalars['Int']>;
  maxParentsAllowed?: Maybe<Scalars['Int']>;
};

export type UpdateAndFixTimeOffsInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  isTestContracts: Scalars['Boolean'];
  shouldCreateNonRequiredEntitlementIfMissing?: InputMaybe<Scalars['Boolean']>;
  shouldCreateRequiredEntitlementIfMissing?: InputMaybe<Scalars['Boolean']>;
  shouldCreateUpdateAllocationSummary?: InputMaybe<Scalars['Boolean']>;
  shouldSetComplianceLeavePolicyToCreatedDefaultEntitlement?: InputMaybe<
    Scalars['Boolean']
  >;
  shouldSetComplianceLeavePolicyToUpdatedDefaultEntitlement?: InputMaybe<
    Scalars['Boolean']
  >;
  statuses?: InputMaybe<Array<ContractStatus>>;
};

export type UpdateApproverInfoInput = {
  approverInfo?: InputMaybe<Array<InputMaybe<ApproverInfoInput>>>;
  companyUserId: Scalars['ID'];
};

export type UpdateCompanyChargebackRequest = {
  chargebackDays: Scalars['Int'];
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyLegalEntityInput = {
  address: AddressInput;
  capabilities?: InputMaybe<Array<Capability>>;
  id: Scalars['ID'];
  legalName: Scalars['String'];
  offeringCodes?: InputMaybe<Array<OfferingCode>>;
  payrollData?: InputMaybe<Array<DataFieldInput>>;
};

export type UpdateCompanyPayInMethodChargeBackRequest = {
  chargebackPeriodDays: Scalars['Int'];
  companyPayInMethodId: Scalars['ID'];
  description: Scalars['String'];
};

export type UpdateCompensationPayInfoInput = {
  contractId: Array<Scalars['ID']>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<PayFrequencyDateInput>>;
};

export type UpdateCountryOnboardingEnablementInput = {
  country: CountryCode;
  enableStatus: Scalars['Boolean'];
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateDemoCyclesInput = {
  payrollCycleIds: Array<Scalars['ID']>;
  status: PayrollCycleStatus;
};

export type UpdateOffboardingInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  completedStep: OffBoardingExperienceStep;
  contractId: Scalars['ID'];
  documentIds?: InputMaybe<Array<Scalars['ID']>>;
  reason?: InputMaybe<Scalars['String']>;
};

export type UpdateOrCreateCompanyBindingInput = {
  companyId: Scalars['ID'];
  externalId: Scalars['String'];
};

export type UpdatePayrollWorkflowRequest = {
  action: Action;
  payrollCycleIds: Array<Scalars['ID']>;
};

export type UpdateUserInput = {
  companyUserId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  /** Deprecated. use 'companyUser[Assign|Revoke]Capabilities' instead. Will be removed by 1st of December 2021 */
  isBillingContact?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. use 'companyUser[Assign|Revoke]Capabilities' instead. Will be removed by 1st of December 2021 */
  isSignatory?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CompanyUserRole>;
  title?: InputMaybe<Scalars['String']>;
};

export type UploadUrlResponse = {
  __typename?: 'UploadUrlResponse';
  url: Scalars['String'];
};

export type UploadedFileCount = {
  __typename?: 'UploadedFileCount';
  total: Scalars['Int'];
  uploaded: Scalars['Int'];
};

export type UpsertSsoPreferenceInput = {
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  identityProvider: IdentityProvider;
};

export enum Urgency {
  DELAYED = 'DELAYED',
  OTHER = 'OTHER',
  URGENT = 'URGENT',
}

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  results?: Maybe<Array<ValidationResult>>;
  succeeded: Scalars['Boolean'];
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  message: Scalars['String'];
  type: ValidationResultType;
};

export enum ValidationResultType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

export enum VariableBaseUnit {
  ACTIVE_MEMBER = 'ACTIVE_MEMBER',
  COMPANY_ACTIVATION = 'COMPANY_ACTIVATION',
  HOUR = 'HOUR',
  MEMBER_ACTIVATION = 'MEMBER_ACTIVATION',
  MEMBER_OFFBOARD = 'MEMBER_OFFBOARD',
  MEMBER_OFFCYCLE_RUN = 'MEMBER_OFFCYCLE_RUN',
  MEMBER_PAYROLL_CYCLE = 'MEMBER_PAYROLL_CYCLE',
}

export enum VestingFrequency {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  YEARLY = 'YEARLY',
}

export type VestingSchedule = {
  __typename?: 'VestingSchedule';
  frequency?: Maybe<VestingFrequency>;
  time?: Maybe<ScheduleTime>;
  vestingValue?: Maybe<VestingValue>;
};

export type VestingScheduleInput = {
  frequency: VestingFrequency;
  time: ScheduleTimeInput;
  vestingValue: VestingValueInput;
};

export type VestingValue = {
  __typename?: 'VestingValue';
  type: VestingValueType;
  value: Scalars['Float'];
};

export type VestingValueInput = {
  type: VestingValueType;
  value: Scalars['Float'];
};

export enum VestingValueType {
  CASH = 'CASH',
  PERCENTAGE = 'PERCENTAGE',
  UNIT = 'UNIT',
}

export type VisaEmployeePricing = EmployeePricing & {
  __typename?: 'VisaEmployeePricing';
  country?: Maybe<CountryCode>;
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  validUntil?: Maybe<Scalars['Date']>;
};

export type VisaGlobalPricing = {
  __typename?: 'VisaGlobalPricing';
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
};

export type WorkShift = {
  __typename?: 'WorkShift';
  breakHours?: Maybe<WorkingHours>;
  endDay?: Maybe<DayOfWeek>;
  startDay?: Maybe<DayOfWeek>;
  workingHours?: Maybe<WorkingHours>;
};

export type WorkShiftInput = {
  breakHours?: InputMaybe<WorkingHoursInput>;
  endDay: DayOfWeek;
  startDay: DayOfWeek;
  workingHours: WorkingHoursInput;
};

export type WorkingHours = {
  __typename?: 'WorkingHours';
  endTime?: Maybe<Scalars['Time']>;
  startTime?: Maybe<Scalars['Time']>;
};

export type WorkingHoursInput = {
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export enum Workspace {
  DEFAULT = 'DEFAULT',
  PANDA_DOC = 'PANDA_DOC',
  PANDA_DOC_MSA = 'PANDA_DOC_MSA',
}

export enum Core__Purpose {
  /** `EXECUTION` features provide metadata necessary to for operation execution. */
  EXECUTION = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  SECURITY = 'SECURITY',
}

export enum Join__Graph {
  ANALYTICS = 'ANALYTICS',
  COMPANY = 'COMPANY',
  COMPENSATION = 'COMPENSATION',
  CONTRACT = 'CONTRACT',
  CONTRACT_OFFBOARDING = 'CONTRACT_OFFBOARDING',
  CONTRACT_ONBOARDING = 'CONTRACT_ONBOARDING',
  CORE = 'CORE',
  COUNTRY = 'COUNTRY',
  CUSTOMER_INTEGRATION = 'CUSTOMER_INTEGRATION',
  DOCGEN = 'DOCGEN',
  EQUIPMENT = 'EQUIPMENT',
  EXPENSE = 'EXPENSE',
  FREELANCER = 'FREELANCER',
  MEMBER = 'MEMBER',
  MEMBER_EXPERIENCE = 'MEMBER_EXPERIENCE',
  OFFBOARDING_EXPERIENCE = 'OFFBOARDING_EXPERIENCE',
  ORG_MANAGEMENT = 'ORG_MANAGEMENT',
  PAYABLE = 'PAYABLE',
  PAYMENT = 'PAYMENT',
  PAYMENT_INSTRUCTION = 'PAYMENT_INSTRUCTION',
  PAYROLL = 'PAYROLL',
  PAYROLL_INPUT = 'PAYROLL_INPUT',
  PAYROLL_ORCHESTRATOR = 'PAYROLL_ORCHESTRATOR',
  PAYROLL_PAYMENT = 'PAYROLL_PAYMENT',
  PAYROLL_SCHEMA_SERVICE = 'PAYROLL_SCHEMA_SERVICE',
  PIGEON = 'PIGEON',
  PLATFORM_UTILITY = 'PLATFORM_UTILITY',
  PRICING = 'PRICING',
  RECRUITMENT = 'RECRUITMENT',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  SALARY_CALCULATOR = 'SALARY_CALCULATOR',
  TIMEOFF = 'TIMEOFF',
  TIMESHEET = 'TIMESHEET',
}

export type AssignDirectReportsMutationVariables = Exact<{
  input: ManagerAssignDirectReportsInput;
}>;

export type AssignDirectReportsMutation = {
  __typename?: 'Mutation';
  managerAssignDirectReports?: {
    __typename?: 'Manager';
    id: string;
    totalSubordinates?: number | null;
    companyUser?: {
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
      } | null> | null;
      rolesWithAttributes?: Array<{
        __typename?: 'CompanyUserRoleWithAttributes';
        legalEntityIds?: Array<string | null> | null;
        role?: CompanyUserRole | null;
      } | null> | null;
    } | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      status?: ContractStatus | null;
      legalEntityId?: string | null;
      position?: string | null;
      workEmail?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        userId?: string | null;
        gender?: Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
      } | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        costCenter?: {
          __typename?: 'CostCenter';
          id?: string | null;
          description?: string | null;
          name?: string | null;
        } | null;
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
    } | null;
    directReports?: Array<{
      __typename?: 'DirectReport';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
          rolesWithAttributes?: Array<{
            __typename?: 'CompanyUserRoleWithAttributes';
            legalEntityIds?: Array<string | null> | null;
            role?: CompanyUserRole | null;
          } | null> | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    }> | null;
    reportsToManager?: {
      __typename?: 'Manager';
      id: string;
      companyUser?: {
        __typename?: 'CompanyUser';
        id?: string | null;
        userId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        title?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
        rolesWithAttributes?: Array<{
          __typename?: 'CompanyUserRoleWithAttributes';
          legalEntityIds?: Array<string | null> | null;
          role?: CompanyUserRole | null;
        } | null> | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AssignManagerMutationVariables = Exact<{
  input: OrgDirectoryAssignReportsToInput;
}>;

export type AssignManagerMutation = {
  __typename?: 'Mutation';
  orgDirectoryAssignReportsTo?: {
    __typename?: 'OrgDirectory';
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      reportsToManager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
          rolesWithAttributes?: Array<{
            __typename?: 'CompanyUserRoleWithAttributes';
            legalEntityIds?: Array<string | null> | null;
            role?: CompanyUserRole | null;
          } | null> | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    managers?: Array<{
      __typename?: 'Manager';
      id: string;
      reportsToManager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
          rolesWithAttributes?: Array<{
            __typename?: 'CompanyUserRoleWithAttributes';
            legalEntityIds?: Array<string | null> | null;
            role?: CompanyUserRole | null;
          } | null> | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateManagerMutationVariables = Exact<{
  input: ManagerCreateInput;
}>;

export type CreateManagerMutation = {
  __typename?: 'Mutation';
  managerCreate?: {
    __typename?: 'Manager';
    id: string;
    totalSubordinates?: number | null;
    companyUser?: {
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
      } | null> | null;
      rolesWithAttributes?: Array<{
        __typename?: 'CompanyUserRoleWithAttributes';
        legalEntityIds?: Array<string | null> | null;
        role?: CompanyUserRole | null;
      } | null> | null;
    } | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      status?: ContractStatus | null;
      legalEntityId?: string | null;
      position?: string | null;
      workEmail?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        userId?: string | null;
        gender?: Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
      } | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        costCenter?: {
          __typename?: 'CostCenter';
          id?: string | null;
          description?: string | null;
          name?: string | null;
        } | null;
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
    } | null;
    directReports?: Array<{
      __typename?: 'DirectReport';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
          rolesWithAttributes?: Array<{
            __typename?: 'CompanyUserRoleWithAttributes';
            legalEntityIds?: Array<string | null> | null;
            role?: CompanyUserRole | null;
          } | null> | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    }> | null;
    reportsToManager?: {
      __typename?: 'Manager';
      id: string;
      companyUser?: {
        __typename?: 'CompanyUser';
        id?: string | null;
        userId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        title?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
        rolesWithAttributes?: Array<{
          __typename?: 'CompanyUserRoleWithAttributes';
          legalEntityIds?: Array<string | null> | null;
          role?: CompanyUserRole | null;
        } | null> | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteManagerMutationVariables = Exact<{
  input: ManagerDeleteInput;
}>;

export type DeleteManagerMutation = {
  __typename?: 'Mutation';
  managerDelete?: {
    __typename?: 'Manager';
    id: string;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      reportsToManager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
          rolesWithAttributes?: Array<{
            __typename?: 'CompanyUserRoleWithAttributes';
            legalEntityIds?: Array<string | null> | null;
            role?: CompanyUserRole | null;
          } | null> | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BasicContractFieldsFragment = {
  __typename?: 'Contract';
  id?: string | null;
  country?: CountryCode | null;
  type?: ContractType | null;
  status?: ContractStatus | null;
  legalEntityId?: string | null;
  position?: string | null;
  workEmail?: string | null;
  member?: {
    __typename?: 'Member';
    id?: string | null;
    userId?: string | null;
    gender?: Gender | null;
    firstName?: string | null;
    lastName?: string | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      email?: string | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      phoneNo?: string | null;
    } | null> | null;
  } | null;
  orgAttributes?: {
    __typename?: 'OrgAttributes';
    costCenter?: {
      __typename?: 'CostCenter';
      id?: string | null;
      description?: string | null;
      name?: string | null;
    } | null;
    department?: {
      __typename?: 'Department';
      id: string;
      description?: string | null;
      name: string;
    } | null;
  } | null;
};

export type CompanyUserFieldsFragment = {
  __typename?: 'CompanyUser';
  id?: string | null;
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  emails?: Array<{
    __typename?: 'EmailAddress';
    email?: string | null;
  } | null> | null;
  phoneNos?: Array<{
    __typename?: 'PhoneNumber';
    phoneNo?: string | null;
  } | null> | null;
  rolesWithAttributes?: Array<{
    __typename?: 'CompanyUserRoleWithAttributes';
    legalEntityIds?: Array<string | null> | null;
    role?: CompanyUserRole | null;
  } | null> | null;
};

export type ManagerFragmentFragment = {
  __typename?: 'Manager';
  id: string;
  totalSubordinates?: number | null;
  companyUser?: {
    __typename?: 'CompanyUser';
    id?: string | null;
    userId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      email?: string | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      phoneNo?: string | null;
    } | null> | null;
    rolesWithAttributes?: Array<{
      __typename?: 'CompanyUserRoleWithAttributes';
      legalEntityIds?: Array<string | null> | null;
      role?: CompanyUserRole | null;
    } | null> | null;
  } | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    status?: ContractStatus | null;
    legalEntityId?: string | null;
    position?: string | null;
    workEmail?: string | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      userId?: string | null;
      gender?: Gender | null;
      firstName?: string | null;
      lastName?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
      } | null> | null;
    } | null;
    orgAttributes?: {
      __typename?: 'OrgAttributes';
      costCenter?: {
        __typename?: 'CostCenter';
        id?: string | null;
        description?: string | null;
        name?: string | null;
      } | null;
      department?: {
        __typename?: 'Department';
        id: string;
        description?: string | null;
        name: string;
      } | null;
    } | null;
  } | null;
  directReports?: Array<{
    __typename?: 'DirectReport';
    manager?: {
      __typename?: 'Manager';
      id: string;
      companyUser?: {
        __typename?: 'CompanyUser';
        id?: string | null;
        userId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        title?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
        rolesWithAttributes?: Array<{
          __typename?: 'CompanyUserRoleWithAttributes';
          legalEntityIds?: Array<string | null> | null;
          role?: CompanyUserRole | null;
        } | null> | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    } | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      status?: ContractStatus | null;
      legalEntityId?: string | null;
      position?: string | null;
      workEmail?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        userId?: string | null;
        gender?: Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
      } | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        costCenter?: {
          __typename?: 'CostCenter';
          id?: string | null;
          description?: string | null;
          name?: string | null;
        } | null;
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
    } | null;
  }> | null;
  reportsToManager?: {
    __typename?: 'Manager';
    id: string;
    companyUser?: {
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
      } | null> | null;
      rolesWithAttributes?: Array<{
        __typename?: 'CompanyUserRoleWithAttributes';
        legalEntityIds?: Array<string | null> | null;
        role?: CompanyUserRole | null;
      } | null> | null;
    } | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      status?: ContractStatus | null;
      legalEntityId?: string | null;
      position?: string | null;
      workEmail?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        userId?: string | null;
        gender?: Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
      } | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        costCenter?: {
          __typename?: 'CostCenter';
          id?: string | null;
          description?: string | null;
          name?: string | null;
        } | null;
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type OrgAttributesFragmentFragment = {
  __typename?: 'OrgAttributes';
  costCenter?: {
    __typename?: 'CostCenter';
    id?: string | null;
    description?: string | null;
    name?: string | null;
  } | null;
  department?: {
    __typename?: 'Department';
    id: string;
    description?: string | null;
    name: string;
  } | null;
};

export type ProfilePictureFragmentFragment = {
  __typename?: 'Image';
  blob?: string | null;
  contentType?: string | null;
  extension?: string | null;
  id?: string | null;
  name?: string | null;
  versionId?: string | null;
  link?: string | null;
};

export type GetCompanyBasicQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyBasicQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
  } | null;
};

export type GetCompanyEntitiesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyEntitiesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      capabilities?: Array<Capability | null> | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
    otherEntities?: Array<{
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      capabilities?: Array<Capability | null> | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyOfferingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyOfferingsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    offerings?: Array<{
      __typename?: 'CompanyOffering';
      offering?: OfferingCode | null;
      status?: OfferingStatus | null;
    } | null> | null;
  } | null;
};

export type GetContractQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    country?: CountryCode | null;
    status?: ContractStatus | null;
    company?: {
      __typename?: 'Company';
      id?: string | null;
      displayName?: string | null;
    } | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    reportsToManager?: {
      __typename?: 'Manager';
      id: string;
      companyUser?: {
        __typename?: 'CompanyUser';
        id?: string | null;
        userId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        title?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
        rolesWithAttributes?: Array<{
          __typename?: 'CompanyUserRoleWithAttributes';
          legalEntityIds?: Array<string | null> | null;
          role?: CompanyUserRole | null;
        } | null> | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetManagerDirectReportsRuleQueryVariables = Exact<{
  managerId: Scalars['ID'];
}>;

export type GetManagerDirectReportsRuleQuery = {
  __typename?: 'Query';
  managerDirectReportsRule?: {
    __typename?: 'Rule';
    type?: RuleType | null;
    conditions?: Array<{
      __typename?: 'Condition';
      key: ConditionKey;
      operator: ConditionOperator;
      values?: Array<string> | null;
    }> | null;
  } | null;
};

export type GetManagerQueryVariables = Exact<{
  filters: ManagerFilters;
}>;

export type GetManagerQuery = {
  __typename?: 'Query';
  manager?: {
    __typename?: 'Manager';
    id: string;
    totalSubordinates?: number | null;
    companyUser?: {
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
      } | null> | null;
      rolesWithAttributes?: Array<{
        __typename?: 'CompanyUserRoleWithAttributes';
        legalEntityIds?: Array<string | null> | null;
        role?: CompanyUserRole | null;
      } | null> | null;
    } | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      status?: ContractStatus | null;
      legalEntityId?: string | null;
      position?: string | null;
      workEmail?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        userId?: string | null;
        gender?: Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
      } | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        costCenter?: {
          __typename?: 'CostCenter';
          id?: string | null;
          description?: string | null;
          name?: string | null;
        } | null;
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
    } | null;
    directReports?: Array<{
      __typename?: 'DirectReport';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
          rolesWithAttributes?: Array<{
            __typename?: 'CompanyUserRoleWithAttributes';
            legalEntityIds?: Array<string | null> | null;
            role?: CompanyUserRole | null;
          } | null> | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    }> | null;
    reportsToManager?: {
      __typename?: 'Manager';
      id: string;
      companyUser?: {
        __typename?: 'CompanyUser';
        id?: string | null;
        userId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        title?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
        rolesWithAttributes?: Array<{
          __typename?: 'CompanyUserRoleWithAttributes';
          legalEntityIds?: Array<string | null> | null;
          role?: CompanyUserRole | null;
        } | null> | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetManagersQueryVariables = Exact<{
  filters: OrgDirectoryFilters;
}>;

export type GetManagersQuery = {
  __typename?: 'Query';
  orgDirectory: {
    __typename?: 'OrgDirectory';
    managers?: Array<{
      __typename?: 'Manager';
      id: string;
      companyUser?: {
        __typename?: 'CompanyUser';
        id?: string | null;
        userId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        title?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
        rolesWithAttributes?: Array<{
          __typename?: 'CompanyUserRoleWithAttributes';
          legalEntityIds?: Array<string | null> | null;
          role?: CompanyUserRole | null;
        } | null> | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetMemberCompanyOfferingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberCompanyOfferingsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    contracts?: Array<{
      __typename?: 'Contract';
      company?: {
        __typename?: 'Company';
        id?: string | null;
        offerings?: Array<{
          __typename?: 'CompanyOffering';
          id?: string | null;
          offering?: OfferingCode | null;
          status?: OfferingStatus | null;
          companyId?: string | null;
          capabilities?: Array<Capability | null> | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetMemberProfilePictureQueryVariables = Exact<{
  memberId?: InputMaybe<Scalars['ID']>;
}>;

export type GetMemberProfilePictureQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    basicInfo?: {
      __typename?: 'MemberBasicInfo';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    profilePicture?: {
      __typename?: 'Image';
      blob?: string | null;
      contentType?: string | null;
      extension?: string | null;
      id?: string | null;
      name?: string | null;
      versionId?: string | null;
      link?: string | null;
    } | null;
  } | null;
};

export type GetOrgDirectoryQueryVariables = Exact<{
  filters: OrgDirectoryFilters;
}>;

export type GetOrgDirectoryQuery = {
  __typename?: 'Query';
  orgDirectory: {
    __typename?: 'OrgDirectory';
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      status?: ContractStatus | null;
      legalEntityId?: string | null;
      position?: string | null;
      workEmail?: string | null;
      reportsToManager?: {
        __typename?: 'Manager';
        id: string;
        totalSubordinates?: number | null;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        userId?: string | null;
        gender?: Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
      } | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        costCenter?: {
          __typename?: 'CostCenter';
          id?: string | null;
          description?: string | null;
          name?: string | null;
        } | null;
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
    }> | null;
    managers?: Array<{
      __typename?: 'Manager';
      id: string;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        costCenter?: {
          __typename?: 'CostCenter';
          id?: string | null;
          description?: string | null;
          name?: string | null;
        } | null;
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
      directReports?: Array<{
        __typename?: 'DirectReport';
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
        manager?: { __typename?: 'Manager'; id: string } | null;
      }> | null;
      companyUser?: {
        __typename?: 'CompanyUser';
        roles?: Array<CompanyUserRole | null> | null;
        id?: string | null;
        userId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        title?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
        phoneNos?: Array<{
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
        } | null> | null;
        rolesWithAttributes?: Array<{
          __typename?: 'CompanyUserRoleWithAttributes';
          legalEntityIds?: Array<string | null> | null;
          role?: CompanyUserRole | null;
        } | null> | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      } | null;
      reportsToManager?: {
        __typename?: 'Manager';
        id: string;
        totalSubordinates?: number | null;
        companyUser?: {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type RemoveMemberProfilePictureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveMemberProfilePictureMutation = {
  __typename?: 'Mutation';
  memberRemoveProfilePicture?: {
    __typename?: 'Member';
    id?: string | null;
    profilePicture?: {
      __typename?: 'Image';
      blob?: string | null;
      contentType?: string | null;
      extension?: string | null;
      id?: string | null;
      name?: string | null;
      versionId?: string | null;
      link?: string | null;
    } | null;
  } | null;
};

export type UpdateMemberProfilePictureMutationVariables = Exact<{
  file: Scalars['Upload'];
  id: Scalars['ID'];
}>;

export type UpdateMemberProfilePictureMutation = {
  __typename?: 'Mutation';
  memberUpsertProfilePicture?: {
    __typename?: 'Member';
    id?: string | null;
    profilePicture?: {
      __typename?: 'Image';
      blob?: string | null;
      contentType?: string | null;
      extension?: string | null;
      id?: string | null;
      name?: string | null;
      versionId?: string | null;
      link?: string | null;
    } | null;
  } | null;
};

export type ValidateDirectReportsMutationVariables = Exact<{
  input: ManagerValidateDirectReportsInput;
}>;

export type ValidateDirectReportsMutation = {
  __typename?: 'Mutation';
  managerValidateDirectReports?: {
    __typename?: 'DirectReportValidationResult';
    failedEntries?: {
      __typename?: 'OrgDirectory';
      contracts?: Array<{
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      }> | null;
      managers?: Array<{
        __typename?: 'Manager';
        id: string;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      }> | null;
    } | null;
    successEntries?: {
      __typename?: 'OrgDirectory';
      contracts?: Array<{
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        status?: ContractStatus | null;
        legalEntityId?: string | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        orgAttributes?: {
          __typename?: 'OrgAttributes';
          costCenter?: {
            __typename?: 'CostCenter';
            id?: string | null;
            description?: string | null;
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'Department';
            id: string;
            description?: string | null;
            name: string;
          } | null;
        } | null;
      }> | null;
      managers?: Array<{
        __typename?: 'Manager';
        id: string;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          status?: ContractStatus | null;
          legalEntityId?: string | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
          orgAttributes?: {
            __typename?: 'OrgAttributes';
            costCenter?: {
              __typename?: 'CostCenter';
              id?: string | null;
              description?: string | null;
              name?: string | null;
            } | null;
            department?: {
              __typename?: 'Department';
              id: string;
              description?: string | null;
              name: string;
            } | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type ValidateReportingManagerMutationVariables = Exact<{
  input: OrgDirectoryValidateReportsToInput;
}>;

export type ValidateReportingManagerMutation = {
  __typename?: 'Mutation';
  orgDirectoryValidateReportsTo?: {
    __typename?: 'ReportsToValidationResult';
    message?: string | null;
    success: boolean;
  } | null;
};

export const CompanyUserFieldsFragmentDoc = gql`
  fragment companyUserFields on CompanyUser {
    id
    userId
    firstName
    lastName
    title
    emails {
      email
    }
    phoneNos {
      phoneNo
    }
    userId
    rolesWithAttributes {
      legalEntityIds
      role
    }
  }
`;
export const OrgAttributesFragmentFragmentDoc = gql`
  fragment orgAttributesFragment on OrgAttributes {
    costCenter {
      id
      description
      name
    }
    department {
      id
      description
      name
    }
  }
`;
export const BasicContractFieldsFragmentDoc = gql`
  fragment basicContractFields on Contract {
    id
    country
    type
    status
    legalEntityId
    member {
      id
      userId
      gender
      firstName
      lastName
      emails {
        email
      }
      phoneNos {
        phoneNo
      }
      userId
    }
    position
    workEmail
    orgAttributes {
      ...orgAttributesFragment
    }
  }
  ${OrgAttributesFragmentFragmentDoc}
`;
export const ManagerFragmentFragmentDoc = gql`
  fragment managerFragment on Manager {
    id
    totalSubordinates
    companyUser {
      ...companyUserFields
    }
    contract {
      ...basicContractFields
    }
    directReports {
      manager {
        id
        companyUser {
          ...companyUserFields
        }
        contract {
          ...basicContractFields
        }
      }
      contract {
        ...basicContractFields
      }
    }
    reportsToManager {
      id
      companyUser {
        ...companyUserFields
      }
      contract {
        ...basicContractFields
      }
    }
  }
  ${CompanyUserFieldsFragmentDoc}
  ${BasicContractFieldsFragmentDoc}
`;
export const ProfilePictureFragmentFragmentDoc = gql`
  fragment profilePictureFragment on Image {
    blob
    contentType
    extension
    id
    name
    versionId
    link
  }
`;
export const AssignDirectReportsDocument = gql`
  mutation AssignDirectReports($input: ManagerAssignDirectReportsInput!) {
    managerAssignDirectReports(input: $input) {
      ...managerFragment
    }
  }
  ${ManagerFragmentFragmentDoc}
`;
export type AssignDirectReportsMutationFn = Apollo.MutationFunction<
  AssignDirectReportsMutation,
  AssignDirectReportsMutationVariables
>;

/**
 * __useAssignDirectReportsMutation__
 *
 * To run a mutation, you first call `useAssignDirectReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDirectReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDirectReportsMutation, { data, loading, error }] = useAssignDirectReportsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignDirectReportsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignDirectReportsMutation,
    AssignDirectReportsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignDirectReportsMutation,
    AssignDirectReportsMutationVariables
  >(AssignDirectReportsDocument, options);
}
export type AssignDirectReportsMutationHookResult = ReturnType<
  typeof useAssignDirectReportsMutation
>;
export type AssignDirectReportsMutationResult = Apollo.MutationResult<AssignDirectReportsMutation>;
export type AssignDirectReportsMutationOptions = Apollo.BaseMutationOptions<
  AssignDirectReportsMutation,
  AssignDirectReportsMutationVariables
>;
export const AssignManagerDocument = gql`
  mutation AssignManager($input: OrgDirectoryAssignReportsToInput!) {
    orgDirectoryAssignReportsTo(input: $input) {
      contracts {
        id
        reportsToManager {
          id
          companyUser {
            ...companyUserFields
          }
          contract {
            ...basicContractFields
          }
        }
      }
      managers {
        id
        reportsToManager {
          id
          companyUser {
            ...companyUserFields
          }
          contract {
            ...basicContractFields
          }
        }
      }
    }
  }
  ${CompanyUserFieldsFragmentDoc}
  ${BasicContractFieldsFragmentDoc}
`;
export type AssignManagerMutationFn = Apollo.MutationFunction<
  AssignManagerMutation,
  AssignManagerMutationVariables
>;

/**
 * __useAssignManagerMutation__
 *
 * To run a mutation, you first call `useAssignManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignManagerMutation, { data, loading, error }] = useAssignManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignManagerMutation,
    AssignManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignManagerMutation,
    AssignManagerMutationVariables
  >(AssignManagerDocument, options);
}
export type AssignManagerMutationHookResult = ReturnType<
  typeof useAssignManagerMutation
>;
export type AssignManagerMutationResult = Apollo.MutationResult<AssignManagerMutation>;
export type AssignManagerMutationOptions = Apollo.BaseMutationOptions<
  AssignManagerMutation,
  AssignManagerMutationVariables
>;
export const CreateManagerDocument = gql`
  mutation CreateManager($input: ManagerCreateInput!) {
    managerCreate(input: $input) {
      ...managerFragment
    }
  }
  ${ManagerFragmentFragmentDoc}
`;
export type CreateManagerMutationFn = Apollo.MutationFunction<
  CreateManagerMutation,
  CreateManagerMutationVariables
>;

/**
 * __useCreateManagerMutation__
 *
 * To run a mutation, you first call `useCreateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagerMutation, { data, loading, error }] = useCreateManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManagerMutation,
    CreateManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManagerMutation,
    CreateManagerMutationVariables
  >(CreateManagerDocument, options);
}
export type CreateManagerMutationHookResult = ReturnType<
  typeof useCreateManagerMutation
>;
export type CreateManagerMutationResult = Apollo.MutationResult<CreateManagerMutation>;
export type CreateManagerMutationOptions = Apollo.BaseMutationOptions<
  CreateManagerMutation,
  CreateManagerMutationVariables
>;
export const DeleteManagerDocument = gql`
  mutation DeleteManager($input: ManagerDeleteInput!) {
    managerDelete(input: $input) {
      id
      contract {
        id
        reportsToManager {
          id
          companyUser {
            ...companyUserFields
          }
          contract {
            ...basicContractFields
          }
        }
      }
    }
  }
  ${CompanyUserFieldsFragmentDoc}
  ${BasicContractFieldsFragmentDoc}
`;
export type DeleteManagerMutationFn = Apollo.MutationFunction<
  DeleteManagerMutation,
  DeleteManagerMutationVariables
>;

/**
 * __useDeleteManagerMutation__
 *
 * To run a mutation, you first call `useDeleteManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagerMutation, { data, loading, error }] = useDeleteManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteManagerMutation,
    DeleteManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteManagerMutation,
    DeleteManagerMutationVariables
  >(DeleteManagerDocument, options);
}
export type DeleteManagerMutationHookResult = ReturnType<
  typeof useDeleteManagerMutation
>;
export type DeleteManagerMutationResult = Apollo.MutationResult<DeleteManagerMutation>;
export type DeleteManagerMutationOptions = Apollo.BaseMutationOptions<
  DeleteManagerMutation,
  DeleteManagerMutationVariables
>;
export const GetCompanyBasicDocument = gql`
  query GetCompanyBasic {
    company {
      id
      displayName
    }
  }
`;

/**
 * __useGetCompanyBasicQuery__
 *
 * To run a query within a React component, call `useGetCompanyBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBasicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyBasicQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyBasicQuery,
    GetCompanyBasicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyBasicQuery, GetCompanyBasicQueryVariables>(
    GetCompanyBasicDocument,
    options,
  );
}
export function useGetCompanyBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyBasicQuery,
    GetCompanyBasicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyBasicQuery,
    GetCompanyBasicQueryVariables
  >(GetCompanyBasicDocument, options);
}
export type GetCompanyBasicQueryHookResult = ReturnType<
  typeof useGetCompanyBasicQuery
>;
export type GetCompanyBasicLazyQueryHookResult = ReturnType<
  typeof useGetCompanyBasicLazyQuery
>;
export type GetCompanyBasicQueryResult = Apollo.QueryResult<
  GetCompanyBasicQuery,
  GetCompanyBasicQueryVariables
>;
export const GetCompanyEntitiesDocument = gql`
  query GetCompanyEntities($id: ID) {
    company(id: $id) {
      id
      primaryEntity {
        id
        legalName
        address {
          country
        }
        capabilities
      }
      otherEntities {
        id
        legalName
        address {
          country
        }
        capabilities
      }
    }
  }
`;

/**
 * __useGetCompanyEntitiesQuery__
 *
 * To run a query within a React component, call `useGetCompanyEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyEntitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyEntitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >(GetCompanyEntitiesDocument, options);
}
export function useGetCompanyEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >(GetCompanyEntitiesDocument, options);
}
export type GetCompanyEntitiesQueryHookResult = ReturnType<
  typeof useGetCompanyEntitiesQuery
>;
export type GetCompanyEntitiesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyEntitiesLazyQuery
>;
export type GetCompanyEntitiesQueryResult = Apollo.QueryResult<
  GetCompanyEntitiesQuery,
  GetCompanyEntitiesQueryVariables
>;
export const GetCompanyOfferingsDocument = gql`
  query GetCompanyOfferings {
    company {
      id
      offerings {
        offering
        status
      }
    }
  }
`;

/**
 * __useGetCompanyOfferingsQuery__
 *
 * To run a query within a React component, call `useGetCompanyOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOfferingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyOfferingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >(GetCompanyOfferingsDocument, options);
}
export function useGetCompanyOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >(GetCompanyOfferingsDocument, options);
}
export type GetCompanyOfferingsQueryHookResult = ReturnType<
  typeof useGetCompanyOfferingsQuery
>;
export type GetCompanyOfferingsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyOfferingsLazyQuery
>;
export type GetCompanyOfferingsQueryResult = Apollo.QueryResult<
  GetCompanyOfferingsQuery,
  GetCompanyOfferingsQueryVariables
>;
export const GetContractDocument = gql`
  query GetContract($id: ID!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      country
      status
      company {
        id
        displayName
      }
      member {
        id
        userId
        firstName
        lastName
      }
      reportsToManager {
        id
        companyUser {
          ...companyUserFields
        }
        contract {
          ...basicContractFields
        }
      }
    }
  }
  ${CompanyUserFieldsFragmentDoc}
  ${BasicContractFieldsFragmentDoc}
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  );
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  );
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<
  typeof useGetContractLazyQuery
>;
export type GetContractQueryResult = Apollo.QueryResult<
  GetContractQuery,
  GetContractQueryVariables
>;
export const GetManagerDirectReportsRuleDocument = gql`
  query GetManagerDirectReportsRule($managerId: ID!) {
    managerDirectReportsRule(managerId: $managerId) {
      conditions {
        key
        operator
        values
      }
      type
    }
  }
`;

/**
 * __useGetManagerDirectReportsRuleQuery__
 *
 * To run a query within a React component, call `useGetManagerDirectReportsRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerDirectReportsRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerDirectReportsRuleQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useGetManagerDirectReportsRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagerDirectReportsRuleQuery,
    GetManagerDirectReportsRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManagerDirectReportsRuleQuery,
    GetManagerDirectReportsRuleQueryVariables
  >(GetManagerDirectReportsRuleDocument, options);
}
export function useGetManagerDirectReportsRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagerDirectReportsRuleQuery,
    GetManagerDirectReportsRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManagerDirectReportsRuleQuery,
    GetManagerDirectReportsRuleQueryVariables
  >(GetManagerDirectReportsRuleDocument, options);
}
export type GetManagerDirectReportsRuleQueryHookResult = ReturnType<
  typeof useGetManagerDirectReportsRuleQuery
>;
export type GetManagerDirectReportsRuleLazyQueryHookResult = ReturnType<
  typeof useGetManagerDirectReportsRuleLazyQuery
>;
export type GetManagerDirectReportsRuleQueryResult = Apollo.QueryResult<
  GetManagerDirectReportsRuleQuery,
  GetManagerDirectReportsRuleQueryVariables
>;
export const GetManagerDocument = gql`
  query GetManager($filters: ManagerFilters!) {
    manager(filters: $filters) {
      ...managerFragment
    }
  }
  ${ManagerFragmentFragmentDoc}
`;

/**
 * __useGetManagerQuery__
 *
 * To run a query within a React component, call `useGetManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetManagerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagerQuery,
    GetManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagerQuery, GetManagerQueryVariables>(
    GetManagerDocument,
    options,
  );
}
export function useGetManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagerQuery,
    GetManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagerQuery, GetManagerQueryVariables>(
    GetManagerDocument,
    options,
  );
}
export type GetManagerQueryHookResult = ReturnType<typeof useGetManagerQuery>;
export type GetManagerLazyQueryHookResult = ReturnType<
  typeof useGetManagerLazyQuery
>;
export type GetManagerQueryResult = Apollo.QueryResult<
  GetManagerQuery,
  GetManagerQueryVariables
>;
export const GetManagersDocument = gql`
  query GetManagers($filters: OrgDirectoryFilters!) {
    orgDirectory(filters: $filters) {
      managers {
        id
        companyUser {
          ...companyUserFields
        }
        contract {
          ...basicContractFields
        }
      }
    }
  }
  ${CompanyUserFieldsFragmentDoc}
  ${BasicContractFieldsFragmentDoc}
`;

/**
 * __useGetManagersQuery__
 *
 * To run a query within a React component, call `useGetManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetManagersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagersQuery,
    GetManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagersQuery, GetManagersQueryVariables>(
    GetManagersDocument,
    options,
  );
}
export function useGetManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagersQuery,
    GetManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagersQuery, GetManagersQueryVariables>(
    GetManagersDocument,
    options,
  );
}
export type GetManagersQueryHookResult = ReturnType<typeof useGetManagersQuery>;
export type GetManagersLazyQueryHookResult = ReturnType<
  typeof useGetManagersLazyQuery
>;
export type GetManagersQueryResult = Apollo.QueryResult<
  GetManagersQuery,
  GetManagersQueryVariables
>;
export const GetMemberCompanyOfferingsDocument = gql`
  query GetMemberCompanyOfferings {
    member {
      contracts {
        company {
          id
          offerings {
            id
            offering
            status
            companyId
            capabilities
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberCompanyOfferingsQuery__
 *
 * To run a query within a React component, call `useGetMemberCompanyOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberCompanyOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberCompanyOfferingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberCompanyOfferingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberCompanyOfferingsQuery,
    GetMemberCompanyOfferingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberCompanyOfferingsQuery,
    GetMemberCompanyOfferingsQueryVariables
  >(GetMemberCompanyOfferingsDocument, options);
}
export function useGetMemberCompanyOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberCompanyOfferingsQuery,
    GetMemberCompanyOfferingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberCompanyOfferingsQuery,
    GetMemberCompanyOfferingsQueryVariables
  >(GetMemberCompanyOfferingsDocument, options);
}
export type GetMemberCompanyOfferingsQueryHookResult = ReturnType<
  typeof useGetMemberCompanyOfferingsQuery
>;
export type GetMemberCompanyOfferingsLazyQueryHookResult = ReturnType<
  typeof useGetMemberCompanyOfferingsLazyQuery
>;
export type GetMemberCompanyOfferingsQueryResult = Apollo.QueryResult<
  GetMemberCompanyOfferingsQuery,
  GetMemberCompanyOfferingsQueryVariables
>;
export const GetMemberProfilePictureDocument = gql`
  query GetMemberProfilePicture($memberId: ID) {
    member(id: $memberId) {
      id
      basicInfo {
        firstName
        lastName
      }
      profilePicture {
        ...profilePictureFragment
      }
    }
  }
  ${ProfilePictureFragmentFragmentDoc}
`;

/**
 * __useGetMemberProfilePictureQuery__
 *
 * To run a query within a React component, call `useGetMemberProfilePictureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberProfilePictureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberProfilePictureQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetMemberProfilePictureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberProfilePictureQuery,
    GetMemberProfilePictureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberProfilePictureQuery,
    GetMemberProfilePictureQueryVariables
  >(GetMemberProfilePictureDocument, options);
}
export function useGetMemberProfilePictureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberProfilePictureQuery,
    GetMemberProfilePictureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberProfilePictureQuery,
    GetMemberProfilePictureQueryVariables
  >(GetMemberProfilePictureDocument, options);
}
export type GetMemberProfilePictureQueryHookResult = ReturnType<
  typeof useGetMemberProfilePictureQuery
>;
export type GetMemberProfilePictureLazyQueryHookResult = ReturnType<
  typeof useGetMemberProfilePictureLazyQuery
>;
export type GetMemberProfilePictureQueryResult = Apollo.QueryResult<
  GetMemberProfilePictureQuery,
  GetMemberProfilePictureQueryVariables
>;
export const GetOrgDirectoryDocument = gql`
  query GetOrgDirectory($filters: OrgDirectoryFilters!) {
    orgDirectory(filters: $filters) {
      contracts {
        ...basicContractFields
        reportsToManager {
          id
          totalSubordinates
          companyUser {
            id
            userId
            firstName
            lastName
          }
        }
      }
      managers {
        id
        orgAttributes {
          ...orgAttributesFragment
        }
        directReports {
          contract {
            id
          }
          manager {
            id
          }
        }
        companyUser {
          ...companyUserFields
          roles
        }
        contract {
          ...basicContractFields
        }
        reportsToManager {
          id
          totalSubordinates
          companyUser {
            id
            userId
            firstName
            lastName
          }
        }
      }
    }
  }
  ${BasicContractFieldsFragmentDoc}
  ${OrgAttributesFragmentFragmentDoc}
  ${CompanyUserFieldsFragmentDoc}
`;

/**
 * __useGetOrgDirectoryQuery__
 *
 * To run a query within a React component, call `useGetOrgDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgDirectoryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetOrgDirectoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgDirectoryQuery,
    GetOrgDirectoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgDirectoryQuery, GetOrgDirectoryQueryVariables>(
    GetOrgDirectoryDocument,
    options,
  );
}
export function useGetOrgDirectoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgDirectoryQuery,
    GetOrgDirectoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgDirectoryQuery,
    GetOrgDirectoryQueryVariables
  >(GetOrgDirectoryDocument, options);
}
export type GetOrgDirectoryQueryHookResult = ReturnType<
  typeof useGetOrgDirectoryQuery
>;
export type GetOrgDirectoryLazyQueryHookResult = ReturnType<
  typeof useGetOrgDirectoryLazyQuery
>;
export type GetOrgDirectoryQueryResult = Apollo.QueryResult<
  GetOrgDirectoryQuery,
  GetOrgDirectoryQueryVariables
>;
export const RemoveMemberProfilePictureDocument = gql`
  mutation RemoveMemberProfilePicture($id: ID!) {
    memberRemoveProfilePicture(id: $id) {
      id
      profilePicture {
        ...profilePictureFragment
      }
    }
  }
  ${ProfilePictureFragmentFragmentDoc}
`;
export type RemoveMemberProfilePictureMutationFn = Apollo.MutationFunction<
  RemoveMemberProfilePictureMutation,
  RemoveMemberProfilePictureMutationVariables
>;

/**
 * __useRemoveMemberProfilePictureMutation__
 *
 * To run a mutation, you first call `useRemoveMemberProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberProfilePictureMutation, { data, loading, error }] = useRemoveMemberProfilePictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMemberProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMemberProfilePictureMutation,
    RemoveMemberProfilePictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMemberProfilePictureMutation,
    RemoveMemberProfilePictureMutationVariables
  >(RemoveMemberProfilePictureDocument, options);
}
export type RemoveMemberProfilePictureMutationHookResult = ReturnType<
  typeof useRemoveMemberProfilePictureMutation
>;
export type RemoveMemberProfilePictureMutationResult = Apollo.MutationResult<RemoveMemberProfilePictureMutation>;
export type RemoveMemberProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberProfilePictureMutation,
  RemoveMemberProfilePictureMutationVariables
>;
export const UpdateMemberProfilePictureDocument = gql`
  mutation UpdateMemberProfilePicture($file: Upload!, $id: ID!) {
    memberUpsertProfilePicture(file: $file, id: $id) {
      id
      profilePicture {
        ...profilePictureFragment
      }
    }
  }
  ${ProfilePictureFragmentFragmentDoc}
`;
export type UpdateMemberProfilePictureMutationFn = Apollo.MutationFunction<
  UpdateMemberProfilePictureMutation,
  UpdateMemberProfilePictureMutationVariables
>;

/**
 * __useUpdateMemberProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateMemberProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberProfilePictureMutation, { data, loading, error }] = useUpdateMemberProfilePictureMutation({
 *   variables: {
 *      file: // value for 'file'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMemberProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberProfilePictureMutation,
    UpdateMemberProfilePictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberProfilePictureMutation,
    UpdateMemberProfilePictureMutationVariables
  >(UpdateMemberProfilePictureDocument, options);
}
export type UpdateMemberProfilePictureMutationHookResult = ReturnType<
  typeof useUpdateMemberProfilePictureMutation
>;
export type UpdateMemberProfilePictureMutationResult = Apollo.MutationResult<UpdateMemberProfilePictureMutation>;
export type UpdateMemberProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberProfilePictureMutation,
  UpdateMemberProfilePictureMutationVariables
>;
export const ValidateDirectReportsDocument = gql`
  mutation ValidateDirectReports($input: ManagerValidateDirectReportsInput!) {
    managerValidateDirectReports(input: $input) {
      failedEntries {
        contracts {
          ...basicContractFields
        }
        managers {
          id
          contract {
            ...basicContractFields
          }
        }
      }
      successEntries {
        contracts {
          ...basicContractFields
        }
        managers {
          id
          contract {
            ...basicContractFields
          }
        }
      }
    }
  }
  ${BasicContractFieldsFragmentDoc}
`;
export type ValidateDirectReportsMutationFn = Apollo.MutationFunction<
  ValidateDirectReportsMutation,
  ValidateDirectReportsMutationVariables
>;

/**
 * __useValidateDirectReportsMutation__
 *
 * To run a mutation, you first call `useValidateDirectReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateDirectReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateDirectReportsMutation, { data, loading, error }] = useValidateDirectReportsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateDirectReportsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateDirectReportsMutation,
    ValidateDirectReportsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateDirectReportsMutation,
    ValidateDirectReportsMutationVariables
  >(ValidateDirectReportsDocument, options);
}
export type ValidateDirectReportsMutationHookResult = ReturnType<
  typeof useValidateDirectReportsMutation
>;
export type ValidateDirectReportsMutationResult = Apollo.MutationResult<ValidateDirectReportsMutation>;
export type ValidateDirectReportsMutationOptions = Apollo.BaseMutationOptions<
  ValidateDirectReportsMutation,
  ValidateDirectReportsMutationVariables
>;
export const ValidateReportingManagerDocument = gql`
  mutation ValidateReportingManager(
    $input: OrgDirectoryValidateReportsToInput!
  ) {
    orgDirectoryValidateReportsTo(input: $input) {
      message
      success
    }
  }
`;
export type ValidateReportingManagerMutationFn = Apollo.MutationFunction<
  ValidateReportingManagerMutation,
  ValidateReportingManagerMutationVariables
>;

/**
 * __useValidateReportingManagerMutation__
 *
 * To run a mutation, you first call `useValidateReportingManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateReportingManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateReportingManagerMutation, { data, loading, error }] = useValidateReportingManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateReportingManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateReportingManagerMutation,
    ValidateReportingManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateReportingManagerMutation,
    ValidateReportingManagerMutationVariables
  >(ValidateReportingManagerDocument, options);
}
export type ValidateReportingManagerMutationHookResult = ReturnType<
  typeof useValidateReportingManagerMutation
>;
export type ValidateReportingManagerMutationResult = Apollo.MutationResult<ValidateReportingManagerMutation>;
export type ValidateReportingManagerMutationOptions = Apollo.BaseMutationOptions<
  ValidateReportingManagerMutation,
  ValidateReportingManagerMutationVariables
>;
