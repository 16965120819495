export const isObject = (
  maybeObject?: unknown,
): maybeObject is Record<string, unknown> => {
  if (!maybeObject) return false;
  return typeof maybeObject === 'object';
};

export const parseKeys = (maybeObject?: unknown): string[] => {
  if (!isObject(maybeObject)) return [];
  return Object.keys(maybeObject);
};

export const parseEntries = (maybeObject?: unknown): [string, unknown][] => {
  if (!isObject(maybeObject)) return [];
  return Object.entries(maybeObject);
};

export const hashCodeFromString = (input: string): number => {
  if (input.length === 0) return 0;

  let hash = 0;
  let chr = 0;
  for (let i = 0; i < input.length; i += 1) {
    chr = input.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }

  return hash;
};
