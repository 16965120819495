import {
  Contract,
  ContractType,
  CountryCode,
  CountryWorkStatus,
  DataFieldDefinition,
  GetMemberCountryRequirementsQueryVariables,
  Maybe,
} from '__generated__/graphql';

import useGetCountryLegalRequirementsForMember, {
  MemberCountryLegalRequirements,
} from './get-country-legal-requirements-for-member';

const useGetCountryLegalRequirements = (
  workStatus?: Maybe<CountryWorkStatus>,
  contractType?: Contract['type'],
  fetchStage?: GetMemberCountryRequirementsQueryVariables['fetchStage'],
  domainTypes?: DataFieldDefinition['domainType'][],
  shouldIncludePayrollDocs?: boolean,
): {
  getRequirements: (
    country: CountryCode,
    contractType?: ContractType | null,
  ) => void;
  loading: boolean;
  requirements: MemberCountryLegalRequirements;
} =>
  useGetCountryLegalRequirementsForMember({
    workStatus,
    contractType,
    fetchStage,
    legalDataDomainTypes: domainTypes ?? [],
    shouldIncludePayrollDocs,
  });

export default useGetCountryLegalRequirements;
