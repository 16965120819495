import { gql } from '@apollo/client';

export default gql`
  mutation companyUpdate($company: CompanyUpdateInput!, $companyLogo: Upload) {
    companyUpdate(company: $company, companyLogo: $companyLogo) {
      id
      displayName
      companyLogo {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
          blob
        }
      }
      status
      financialYear
      primaryEntity {
        id
        legalName
        registrationNo
        address {
          line1
          line2
          zipcode
          country
        }
        phone
      }
    }
  }
`;
