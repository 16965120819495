/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CardSeparator, ThemeContext } from '@multiplier/common';
import { capitalize } from 'lodash';
import tw from 'twin.macro';

import TextInput from 'common/components/text-input';

import { TimeOffTypeDefinition } from '__generated__/graphql';

import { CustomTimeOffInsightDefinition } from '../../../company/pages/definition-phase/pages/compliance';
import { periodLabels } from '../../compliance-entry';
import { LeaveEntitlementFormValues } from '../index';

const RecommendationPill = React.lazy(
  () => import('../../recommendation-pill'),
);

const MandatoryLeaveEntry: React.FC<{
  field: FieldArrayWithId<LeaveEntitlementFormValues, 'mandatory'>;
  index: number;
  insight?: CustomTimeOffInsightDefinition;
  timeOffTypeToDefinitionMap: { [key: string]: TimeOffTypeDefinition };
}> = ({ field, index, insight, timeOffTypeToDefinitionMap }) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<LeaveEntitlementFormValues>();

  const { fields } = useFieldArray({ name: 'mandatory', control });

  const error = errors.mandatory?.[index];

  const mandatoryLeaveEntitlementValue: number =
    useWatch({
      control,
      name: `mandatory.${index}.value`,
    }) ?? 0;

  return (
    <>
      <div tw="flex flex-col items-start gap-y-tiny">
        <span tw="text-text-primary font-medium">
          {t(
            `compliance.leave-entitlement.mandatory-${field.key}`,
            capitalize(timeOffTypeToDefinitionMap[field.key]?.label ?? ''),
          )}
        </span>
        {insight && (
          <RecommendationPill
            insight={insight}
            value={field.defaultValue + mandatoryLeaveEntitlementValue}
            unit={field.unit}
          />
        )}
        {field?.key === 'annual' && (
          <span
            tw="flex rounded-tiny bg-background-warning-faded p-extra-small text-xs text-text-primary"
            data-testid="annual-leave-callout"
          >
            {t(
              'annual-leave-callout',
              'Additional leaves will result in an additional deposit amount to be paid.',
            )}
          </span>
        )}
      </div>
      <div
        css={[
          tw`rounded-tiny p-small bg-background-primary`,
          !isNewThemeApplied && tw`bg-primary bg-opacity-10`,
        ]}
      >{`${field.defaultValue} ${periodLabels[field.unit](
        field.value ?? 0,
      )}`}</div>
      <TextInput
        data-testid={`mandatory-${field.key}`}
        placeholder={t(
          `compliance.leave-entitlement.mandatory.placeholder`,
          '0',
        )}
        tw="w-[95px]"
        {...register(`mandatory.${index}.value`, {
          setValueAs: (value) =>
            Number.isNaN(parseFloat(value)) ? null : parseFloat(value),
          max: {
            value:
              (field?.maximum ?? Number.MAX_SAFE_INTEGER) - field.defaultValue,
            message: t('compliance.leave-entitlement.value.max-error', {
              defaultValue: 'Total value should be below {{value}}',
              replace: {
                value: field.maximum,
              },
            }),
          },
          min: {
            value: 0,
            message: t(
              'compliance.leave-entitlement.mandatory.min-error',
              'Cannot reduce mandatory entitlements',
            ),
          },
          validate: {
            rounded: (value) => {
              if (value === null || value % 0.5 === 0) return true;
              return t(
                'compliance.leave-entitlement.mandatory.rounded',
                'Only half (0.5) values accepted',
              ) as string;
            },
          },
        })}
        units={periodLabels[field.unit](2)} // force plural
        error={error?.value ? true : undefined}
        type="number"
        step="0.1"
      />
      <span
        tw="text-text-primary"
        data-testid="mandatory-leave-entry-total"
      >{`${field.defaultValue + mandatoryLeaveEntitlementValue} ${periodLabels[
        field.unit
      ](field.defaultValue + mandatoryLeaveEntitlementValue)}`}</span>
      {error?.value && (
        <TextInput.Error tw="col-start-2 col-span-3">
          {error.value.message}
        </TextInput.Error>
      )}
      {index + 1 !== fields.length && (
        <CardSeparator tw="col-span-4 my-extra-small" />
      )}
    </>
  );
};

export default MandatoryLeaveEntry;
