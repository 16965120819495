import React, { useContext, useRef } from 'react';

import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useClickAway, useKey } from 'react-use';

import tw, { TwStyle, theme } from 'twin.macro';

import Button from '../button';
import ConfirmationDialog from '../confirmation-dialog';
import Icon from '../icon';
import IconButton from '../icon-button';
import { ThemeContext } from '../theme-provider';
import { ButtonProps } from '../types';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  description?: string;
  buttonText?: string;
  loading?: boolean;
  disabled?: boolean;
  btnWrapperStyle?: TwStyle;
  confirmBtnStyle?: TwStyle;
  containerStyle?: TwStyle;
  contentStyle?: TwStyle;
  lightBoxStyle?: TwStyle;
  headerTextStyle?: TwStyle;
  headerStyle?: TwStyle;
  descriptionStyle?: TwStyle;
  hideCancelButton?: boolean;
  hideButtons?: boolean;
  hideConfirmButton?: boolean;
  explicitlyClose?: boolean;
  buttonProps?: ButtonProps;
}

const BaseDialog: React.FC<React.PropsWithChildren<DialogProps>> = ({
  onClose,
  onConfirm,
  title,
  description,
  buttonText,
  loading = false,
  disabled = false,
  btnWrapperStyle,
  confirmBtnStyle,
  containerStyle,
  contentStyle,
  lightBoxStyle,
  headerTextStyle,
  headerStyle,
  descriptionStyle,
  hideCancelButton,
  hideButtons,
  hideConfirmButton,
  explicitlyClose = false,
  children,
  buttonProps = {
    size: 'small',
  },
}) => {
  const { t } = useTranslation('common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const dialogRef = useRef(null);
  useClickAway(dialogRef, () => {
    if (!explicitlyClose) {
      onClose();
    }
  });

  useKey('Escape', () => {
    if (!explicitlyClose) {
      onClose();
    }
  });

  return (
    <ConfirmationDialog.Lightbox css={[lightBoxStyle]}>
      <ConfirmationDialog.Container
        css={[tw`width[450px]`, containerStyle]}
        data-testid="modal"
        ref={dialogRef}
        data-cy="modal"
      >
        <IconButton
          size="medium"
          name="cross"
          fill={
            isNewThemeApplied
              ? theme`colors.icon-primary`
              : theme`colors.grey03`
          }
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          tw="absolute top-tiny right-tiny"
        />
        <div
          tw="pt-small pb-extra-small rounded-t-base px-base"
          css={[tw`bg-background-primary `, headerStyle]}
        >
          <h6 css={[tw`text-h6 font-semibold max-w-[85%]`, headerTextStyle]}>
            {title}
          </h6>
        </div>
        {description && (
          <p
            css={[
              tw`w-10/12 m-base text-ps text-text-tertiary`,
              descriptionStyle,
            ]}
          >
            {description}
          </p>
        )}

        <div css={[tw`m-base`, contentStyle]}>{children}</div>
        {!hideButtons && (
          <div css={[tw`flex justify-between m-base`, btnWrapperStyle]}>
            {!hideCancelButton && (
              <Button
                variant="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                {...buttonProps}
              >
                {t('dialog.button.cancel.label', 'Cancel')}
              </Button>
            )}
            {!hideConfirmButton && (
              <Button
                css={[confirmBtnStyle]}
                data-cy="confirm-button"
                variant="default"
                onClick={(e) => {
                  e.stopPropagation();
                  onConfirm?.();
                }}
                disabled={disabled || loading}
                {...buttonProps}
              >
                {loading ? (
                  <Icon name="spinner" fill="none" tw="animate-spin" />
                ) : (
                  buttonText
                )}
              </Button>
            )}
          </div>
        )}
      </ConfirmationDialog.Container>
    </ConfirmationDialog.Lightbox>
  );
};

const Dialog: React.FC<React.PropsWithChildren<DialogProps>> = (props) => {
  const { open } = props;

  if (open) {
    return ReactDOM.createPortal(
      <BaseDialog {...props} />,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }
  return null;
};

export default Object.assign(Dialog, { BaseDialog });
