import React, { useContext } from 'react';

import tw, { theme } from 'twin.macro';

import { ThemeContext } from '../theme-provider';
import useAsyncIconLoader from './async-icon-loader';
import AllFlags from './icons/flags';
import rebrandConfig from './rebrand-config';
import { IconStyle } from './types';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name?: string;
  fill?: string;
  materialStyle?: IconStyle;
  forceCustom?: boolean;
}

const getStyle = (name: string, isNewTheme: boolean) => {
  if (!isNewTheme || !Object.keys(rebrandConfig).includes(name)) {
    return undefined;
  }

  const sizeOverride = rebrandConfig[name]?.size;
  if (sizeOverride) {
    return {
      height: `${sizeOverride.toString()}px`,
      width: `${sizeOverride.toString()}px`,
    };
  }

  return tw`w-24 h-24`;
};

const getFill = (
  isNewTheme: boolean,
  fill: string | undefined,
  name: string,
) => {
  if (!isNewTheme) {
    return fill || theme`colors.icon-primary`;
  }

  if (fill) {
    return fill;
  }

  if (rebrandConfig[name]?.fill) {
    return rebrandConfig[name].fill;
  }

  return theme`colors.icon-primary`;
};

const getStroke = (
  isNewTheme: boolean,
  stroke: string | undefined,
  name: string,
) => {
  if (!isNewTheme) {
    return stroke || 'transparent';
  }

  if (stroke) {
    return stroke;
  }

  if (rebrandConfig[name]?.stroke) {
    return rebrandConfig[name].stroke;
  }

  return 'transparent';
};

const Icon: React.FC<IconProps> & { Flag: React.FC<IconProps> } = ({
  name,
  fill,
  stroke,
  materialStyle,
  forceCustom = false,
  ...rest
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  if (!name) return null;

  const { icon, loading } = useAsyncIconLoader({
    name,
    materialStyle,
    forceCustom,
  });

  if (loading || !icon) {
    return null;
  }

  const ImportedIcon = icon?.ReactComponent;

  const style = getStyle(name, isNewThemeApplied);

  const fillIntercept = getFill(isNewThemeApplied, fill, name);

  const strokeIntercept = getStroke(isNewThemeApplied, stroke, name);

  return (
    <ImportedIcon
      css={style}
      fill={fillIntercept}
      stroke={strokeIntercept}
      {...rest}
    />
  );
};

Icon.Flag = ({ name, ...props }) =>
  name && AllFlags.includes(name as typeof AllFlags[number]) ? (
    <Icon name={`flags/${name}`} {...props} />
  ) : (
    <div data-testid="empty-icon-div" />
  );

export default Icon;
