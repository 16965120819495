/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import 'twin.macro';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';

import { notEmpty } from 'app/utils/array';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import OnboardingSpecialistCard from 'contract-onboarding/components/onboarding-specialist-card';
import StepNavigationFooter from 'contract-onboarding/components/step-navigation-footer';
import {
  useGetCountryLegalRequirements,
  useUpdateDocuments,
} from 'contract-onboarding/member/hooks';
import stepConfig from 'contract-onboarding/member/pages/onboarding/step-config';
import {
  getLegalDocumentSchema,
  groupLegalDocumentDefinitionByKey,
} from 'contract-onboarding/member/services/legal-documents';

import {
  ContractOnboardingStep,
  ContractType,
  FetchStage,
  LegalDocumentRequirement,
  Maybe,
  useGetMemberLazyQuery,
} from '__generated__/graphql';

import AppFeature from '../../../../../../app/features';
import LegalDocumentsFormFields, {
  DocumentFormParams,
} from './components/form-fields';

export type { DocumentFormParams } from './components/form-fields';

export interface OnboardingStepProps {
  currentStep?: ContractOnboardingStep;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
}

const LegalDocuments: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { t } = useTranslation('contract-onboarding.member');
  const [
    getMember,
    { data: { member } = { member: {} }, loading: loadingContract },
  ] = useGetMemberLazyQuery();

  const contract = useMemo(() => member?.contracts?.[0], [member]);
  const { loading: submitLoading, onSubmit } = useUpdateDocuments();

  const sorOnboardingContext = useSorOnboardingContext({
    type: contract?.type,
    companyId: contract?.company?.id,
    legalEntityId: contract?.legalEntityId,
  });

  const {
    getRequirements,
    loading: loadingCountryRequirements,
    requirements,
  } = useGetCountryLegalRequirements(
    contract?.workStatus,
    contract?.type,
    FetchStage.MEMBER_LEGAL_DATA_CAPTURE,
  );

  useEffect(() => {
    getMember();
  }, []);

  useEffect(() => {
    if (contract?.country && contract?.type) {
      if (
        contract?.type === ContractType.FREELANCER ||
        contract?.type === ContractType.CONTRACTOR
      ) {
        getRequirements(contract.country, ContractType.FREELANCER);
      } else {
        getRequirements(contract.country);
      }
    }
  }, [contract]);

  const memberDocumentRequirements = useMemo<{
    [key: string]: LegalDocumentRequirement;
  }>(
    () =>
      groupLegalDocumentDefinitionByKey(
        requirements.memberDocumentRequirements,
      ),
    [requirements],
  );

  const methods = useForm<DocumentFormParams>({
    mode: 'onChange',
    resolver: yupResolver(
      getLegalDocumentSchema(memberDocumentRequirements, t),
    ),
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  useDeepCompareEffect(() => {
    reset({
      legalDocuments:
        requirements.memberDocumentRequirements?.filter(notEmpty) ?? undefined,
    });
  }, [requirements]);

  const showOnboardingSpecialist = useFeature(
    AppFeature.SHOW_ONBOARDING_SPECIALIST,
  )?.on;

  return (
    <div>
      {showOnboardingSpecialist &&
        !sorOnboardingContext.isSorOnboardingEnabled && (
          <div tw="mb-base">
            <OnboardingSpecialistCard
              contractId={contract?.id || ''}
              showHint={false}
              showTitleOnly={false}
            />
          </div>
        )}
      <StepLayout data-testid="legal-data">
        <FormLayout
          tw="grid gap-y-base"
          onSubmit={handleSubmit((v) => {
            onSubmit(v);
          })}
        >
          <FormCard>
            <div tw="grid gap-x-base">
              <FormProvider {...methods}>
                <LegalDocumentsFormFields
                  workStatus={member?.contracts?.[0]?.workStatus}
                  country={member?.contracts?.[0]?.country}
                  legalDocuments={member?.legalDocuments}
                  contractType={member?.contracts?.[0]?.type}
                />
              </FormProvider>
            </div>
          </FormCard>
          <StepNavigationFooter
            tw="mt-tiny"
            disabled={!isValid || loadingCountryRequirements || loadingContract}
            currentStep={currentStep}
            onboardingSteps={onboardingSteps}
            submitLoading={submitLoading}
            stepConfig={stepConfig}
          />
        </FormLayout>
      </StepLayout>
    </div>
  );
};

export default LegalDocuments;
