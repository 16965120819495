/** @jsxImportSource @emotion/react */
import React, { HTMLAttributes, useContext, useState } from 'react';

import { CardSeparator, Icon, ThemeContext } from '@multiplier/common';
import { isNil } from 'lodash';
import tw, { TwStyle, theme } from 'twin.macro';

import ContractOnboardingDetailRow from 'contract-onboarding/components/detail-row';
import { DetailRowProps } from 'contract-onboarding/components/detail-row/types';

export const DetailGrid = tw.div`grid grid-cols-4 gap-y-12 items-center gap-x-2 text-ps`;

export const SectionContainer: React.FC<
  React.PropsWithChildren<
    {
      header: string;
      expandable?: boolean;
      action?: React.ReactNode;
    } & HTMLAttributes<HTMLDivElement>
  >
> = ({ header, expandable = false, children, action = null, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div tw="flex flex-col gap-y-small" {...props}>
      <div tw="flex items-center justify-between">
        <p
          tw="text-background font-semibold"
          css={[
            tw`text-text-primary`,
            !isNewThemeApplied && tw`text-background`,
          ]}
        >
          {header}
        </p>
        <div tw="flex items-center gap-small">
          {action}
          {expandable && (
            <Icon
              css={[
                tw`h-large w-large cursor-pointer`,
                !isExpanded && tw`rotate-180`,
              ]}
              stroke={isNewThemeApplied ? undefined : theme`colors.primary`}
              fill={theme`colors.transparent`}
              name="chevron-down"
              onClick={() => setIsExpanded(!isExpanded)}
            />
          )}
        </div>
      </div>
      <CardSeparator />
      {isExpanded && children}
    </div>
  );
};

export const DetailRow: React.FC<
  DetailRowProps & { visible?: boolean | null }
> = ({
  id,
  value,
  visible = true,
  emptyValue = ' ', // Setting empty field as blank as requested from product
  ...props
}) => {
  if (!visible) return null;
  const { isNewThemeApplied } = useContext(ThemeContext);

  const isEmpty = isNil(value) || value === '';

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ContractOnboardingDetailRow
      id={id}
      labelStyles={[
        tw`text-ps font-normal`,
        isNewThemeApplied ? tw`text-text-tertiary` : tw`text-slateGrey400 `,
      ]}
      valueStyles={[
        tw`text-ps font-normal`,
        isEmpty && !isNewThemeApplied
          ? tw`text-slateGrey400`
          : tw`text-slateGrey900`,
        isEmpty && isNewThemeApplied
          ? tw`text-text-tertiary`
          : tw`text-text-primary`,
      ]}
      value={!isEmpty ? value : emptyValue}
      {...props}
    />
  );
};

export const TabHeader: React.FC<{
  header: string;
  headerStyle?: TwStyle;
  subheader?: string;
  separator?: boolean;
  hide?: boolean;
  renderAction?: React.ReactElement | null;
  icon?: React.ReactNode;
}> = ({
  header,
  headerStyle = tw``,
  subheader,
  separator = false,
  hide = false,
  renderAction,
  icon = null,
  ...props
}) => {
  if (hide) return null;
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div tw="flex flex-col gap-y-tiny" {...props}>
      <div tw="flex flex-row justify-between">
        <div tw="flex gap-x-base items-center">
          {icon}
          <div tw="flex flex-col gap-y-tiny">
            <span
              css={[
                tw`text-text-primary text-18`,
                !isNewThemeApplied && tw`text-background`,
                headerStyle,
              ]}
            >
              {header}
            </span>
            {subheader && (
              <span
                tw=" text-ps"
                css={[
                  tw`text-text-secondary`,
                  !isNewThemeApplied && tw`text-grey01`,
                ]}
              >
                {subheader}
              </span>
            )}
          </div>
        </div>
        <div>{renderAction}</div>
      </div>
      {separator && <CardSeparator tw="mt-small" />}
    </div>
  );
};
