import { useTranslation } from 'react-i18next';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import { GET_PROCUREMENTS_FOR_CONTRACT } from 'equipment/queries';

import {
  GetProcurementsForContractQuery,
  useCreateEquipmentProcurementMutation,
} from '__generated__/graphql';

const useCreateEquipmentProcurement = (): {
  createProcurementRequest: (
    selectedLaptop: string,
    assetDetails: string,
    contractId: string,
  ) => void;
} => {
  const { t } = useTranslation('team.equipment');
  const [
    createProcurementRequestMutation,
  ] = useCreateEquipmentProcurementMutation({
    onError: () => {
      errorNotification(
        '',
        t('request-asset.create-quotation.failed', 'Failed to request asset'),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t('request-asset.create-quotation.success', 'Request successfull.'),
        false,
      );
    },
  });

  const createProcurementRequest = async (
    selectedLaptop: string,
    assetDetails: string,
    contractId: string,
  ) => {
    await createProcurementRequestMutation({
      variables: {
        input: {
          productIds: [selectedLaptop],
          detail: assetDetails,
          clients: [
            {
              id: contractId,
              type: 'contract.contract',
            },
          ],
        },
      },
      update: (cache, { data }) => {
        if (data?.equipmentProcurementCreate) {
          cache.writeQuery<GetProcurementsForContractQuery>({
            query: GET_PROCUREMENTS_FOR_CONTRACT,
            variables: {
              contractId,
            },
            data: {
              contract: {
                id: contractId,
                equipmentProcurements: [
                  { ...data?.equipmentProcurementCreate, completedOn: null },
                ],
                __typename: 'Contract',
              },
            },
          });
        }
      },
    });
  };

  return { createProcurementRequest };
};

export default useCreateEquipmentProcurement;
