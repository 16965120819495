import { addDays, format, parse } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { keyBy, merge } from 'lodash/fp';
import compact from 'lodash/fp/compact';
import concat from 'lodash/fp/concat';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import mapValues from 'lodash/fp/mapValues';
import toPairs from 'lodash/fp/toPairs';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
  Compliance,
  Contract,
  ContractTimeOff,
  ContractTimeOffEntitlement,
  ContractType,
  ContractUpdateTimeOffEntitlementsInput,
  Maybe,
  TimeOffType,
  TimeOffTypeDefinition,
  TimeOffTypeInfo,
  TimeOffUnit,
} from '../__generated__/graphql';
import {
  LeaveEntitlementFormEntryValue,
  TimeOffUpdateFormValues,
  TimeOffValue,
  TimeOffViewModel,
  TimeOffViewModelValue,
} from '../types';
import { convertTimeUnit } from './time';

export const mapEntitlementToLeaveFormEntry = ({
  type,
  value,
  unit,
  definition,
  isMandatory,
}: {
  type: string;
  value: number;
  unit: TimeOffUnit;
  definition: TimeOffTypeDefinition;
  isMandatory: boolean;
}): LeaveEntitlementFormEntryValue => {
  const defaultValue =
    definition.validation?.find((validation) => unit === validation?.unit)
      ?.defaultValue ?? 0;
  const maximum = definition.validation?.find(
    (validation) => unit === validation?.unit,
  )?.maximum;

  const mandatoryValue =
    value - defaultValue === 0 ? null : value - defaultValue;

  return {
    key: type,
    value: isMandatory ? mandatoryValue : value,
    unit,
    defaultValue,
    maximum,
  };
};

export const getLeaveEntitlements = (
  timeOffEntitlement: Compliance['timeOffEntitlement'],
  required: boolean,
): LeaveEntitlementFormEntryValue[] =>
  timeOffEntitlement
    ?.filter((entitlement): entitlement is {
      timeOffType: TimeOffTypeInfo;
      value: number;
      unit: TimeOffUnit;
      isMandatory: boolean;
      definition: TimeOffTypeDefinition;
    } =>
      Boolean(
        entitlement?.isMandatory === required &&
          entitlement.timeOffType?.type &&
          entitlement.unit &&
          entitlement.value != null,
      ),
    )
    .map((entitlement) =>
      mapEntitlementToLeaveFormEntry({
        type: entitlement.timeOffType.type ?? '',
        isMandatory: entitlement.isMandatory,
        definition: entitlement.definition,
        value: entitlement.value,
        unit: entitlement.unit,
      }),
    ) ?? [];

/**
 * Common
 */

export const getTimeOffSummaryMap = (
  timeOffSummaries: TimeOffType[],
): { [key: string]: { prorated: TimeOffValue; remaining: TimeOffValue } } =>
  flow(
    compact,
    map<
      TimeOffType,
      {
        type: TimeOffType['key'];
        prorated: {
          value: TimeOffType['entitled'];
          unit: Maybe<TimeOffUnit> | undefined;
        };
        remaining: {
          value: TimeOffType['remaining'];
          unit: Maybe<TimeOffUnit> | undefined;
        };
      }
    >((timeOffType) => ({
      type: timeOffType?.key,
      prorated: {
        value: timeOffType?.entitled,
        // time off summary only supports days
        // hardcoding because validation in summary isn't wired up on backend
        unit: TimeOffUnit.DAYS,
      },
      remaining: {
        value: timeOffType?.remaining,
        unit: TimeOffUnit.DAYS, // time off summary only supports days
      },
    })),
    keyBy('type'),
  )(timeOffSummaries);

export const getTimeOffEntitlementMap = (
  timeOffEntitlement: Compliance['timeOffEntitlement'],
): {
  [key: string]: { total: TimeOffValue };
} =>
  flow(
    compact,
    map<
      ContractTimeOffEntitlement,
      {
        type: TimeOffTypeInfo['type'];
        definition: ContractTimeOffEntitlement['definition'];
        total: {
          value: ContractTimeOffEntitlement['value'];
          unit: ContractTimeOffEntitlement['unit'];
        };
      }
    >((entitlement) => ({
      type: entitlement.timeOffType?.type,
      definition: entitlement.definition,
      total: {
        value: entitlement.value,
        unit: entitlement.unit,
      },
    })),
    keyBy('type'),
  )(timeOffEntitlement);

const convertProratedAndRemainingUnit = (
  baseViewModel: TimeOffViewModel,
): TimeOffViewModel =>
  flow(
    mapValues<TimeOffViewModelValue, TimeOffViewModelValue>((model) => ({
      ...model,
      prorated: {
        unit: model?.total?.unit || TimeOffUnit.DAYS,
        value: convertTimeUnit(
          model?.prorated?.value || 0,
          model?.prorated?.unit || TimeOffUnit.DAYS,
          model?.total?.unit || TimeOffUnit.DAYS,
        ),
      },
      remaining: {
        unit: model?.total?.unit || TimeOffUnit.DAYS,
        value: convertTimeUnit(
          model?.remaining?.value || 0,
          model?.remaining?.unit || TimeOffUnit.DAYS,
          model?.total?.unit || TimeOffUnit.DAYS,
        ),
      },
    })),
  )(baseViewModel);

export const getTimeOffEntitlementToSummaryMap = (
  timeOffEntitlement: Compliance['timeOffEntitlement'],
  timeOff: Contract['timeOff'],
): TimeOffViewModel => {
  const timeOffEntitlementMap = getTimeOffEntitlementMap(timeOffEntitlement);

  const timeOffSummariesFromEntitlement =
    timeOff?.summary?.filter(
      (summary) => !isEmpty(timeOffEntitlementMap[summary?.key ?? '']),
    ) ?? [];

  const timeOffSummaryMap = getTimeOffSummaryMap(
    timeOffSummariesFromEntitlement as TimeOffType[],
  );

  const baseModel = flow(merge(timeOffSummaryMap))(timeOffEntitlementMap);

  return convertProratedAndRemainingUnit(baseModel);
};

export const getTimeOffTypeToDefinitionMap = (
  timeOffTypeDefinitions: TimeOffTypeDefinition[],
): Record<string, TimeOffTypeDefinition> =>
  timeOffTypeDefinitions?.reduce(
    (prev: { [key: string]: TimeOffTypeDefinition }, curr) => {
      if (curr?.type) {
        prev[curr.type] = curr;
      }
      return prev;
    },
    {},
  ) ?? {};

export const getOptionsFromAssignedEntitlements = (
  entitlements: ContractTimeOffEntitlement[],
  isHrisOn = false,
): {
  title: string;
  value: string;
}[] =>
  entitlements.map((entitlement) => ({
    title: capitalize(
      (isHrisOn
        ? entitlement?.timeOffType?.label
        : entitlement.definition?.label) ?? '',
    ),
    value:
      (isHrisOn
        ? entitlement?.timeOffType?.type
        : entitlement.definition?.type) ?? '',
  })) ?? [];

export const getOptionsFromAvailableEntitlements = (
  entitlements: ContractTimeOffEntitlement[],
  isMandatory?: boolean,
): {
  title: string;
  value: string;
}[] => {
  const allEntitlements = isNil(isMandatory)
    ? entitlements
    : entitlements?.filter(
        (entitlement) => entitlement?.isMandatory === isMandatory,
      );
  return (
    allEntitlements.map((availableEntitlement) => ({
      title: capitalize(availableEntitlement?.timeOffType?.label ?? ''),
      value: availableEntitlement?.timeOffType?.type ?? '',
    })) ?? []
  );
};

// This is for legacy support. Should be deleted and only use `getOptionsFromAvailableEntitlements` once hris-timeoff is `ON` for all users
export const getOptionsFromAvailableDefinitions = (
  definitions: TimeOffTypeDefinition[],
  required?: boolean,
): {
  title: string;
  value: string;
}[] => {
  const allDefinitions = isNil(required)
    ? definitions
    : definitions?.filter((definition) => definition?.required === required);
  return (
    allDefinitions.map((definition) => ({
      title: capitalize(definition?.label ?? ''),
      value: definition?.type ?? '',
    })) ?? []
  );
};

export const getTimeOffRequirementsNotYetAdded = (
  timeOffMap: TimeOffViewModel,
  definitions: { [key: string]: TimeOffTypeDefinition },
): TimeOffTypeDefinition[] =>
  flow(
    toPairs,
    filter(([key]) => timeOffMap[key] == null),
    map(([, definition]) => definition),
  )(definitions);

export const getPeriodResetDate = (
  timeOffSummary: ContractTimeOff['summary'],
): string => {
  let periodEnd = timeOffSummary?.find((type) => type?.key === 'annual')
    ?.periodEnd;

  if (!periodEnd) {
    periodEnd = timeOffSummary?.[0]?.periodEnd;
  }

  if (!periodEnd) return '';

  const dateString = periodEnd?.substring(0, 10);

  const date = parse(dateString, 'yyyy-MM-dd', new Date());

  const datePlusOneDay = addDays(date, 1);

  return format(datePlusOneDay, 'd MMM yyyy');
};

export const mergeWithExistingTimeOffEntitlements = ({
  timeOffEntitlements,
  formValues,
  replace = false,
}: {
  timeOffEntitlements: ContractTimeOffEntitlement[];
  formValues: TimeOffUpdateFormValues;
  replace?: boolean;
}): ContractUpdateTimeOffEntitlementsInput[] =>
  flow(
    filter<ContractTimeOffEntitlement>(
      (entitlement: ContractTimeOffEntitlement) =>
        entitlement.timeOffType?.type !== formValues.key,
    ),
    map((entitlement) => ({
      key: entitlement.timeOffType?.type,
      value: entitlement?.value,
      unit: entitlement?.unit,
    })),
    concat([
      {
        key: formValues.key,
        value: replace
          ? formValues.value
          : (timeOffEntitlements?.find(
              (entitlement) =>
                entitlement.timeOffType?.type === formValues?.key,
            )?.value ?? 0) + Number(formValues?.value),
        unit: formValues?.unit,
      },
    ]),
  )(timeOffEntitlements as ContractTimeOffEntitlement[]);

export const isContractorOrFreelancer = (type: ContractType): boolean =>
  [ContractType.FREELANCER, ContractType.CONTRACTOR].includes(type);
