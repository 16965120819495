import { useLayoutEffect, useMemo } from 'react';

import { dateAsString, parseDate } from '@multiplier/format';
import growthBook, { AppFeature } from '@multiplier/growthbook';
import { SorOnboardingContext } from '@multiplier/hris-member-management';
import { startOfDay } from 'date-fns';

import {
  Contract,
  ContractType,
  Maybe,
  PayFrequency,
  PayrollInputDomainType,
  useGetContractEntityLazyQuery,
  useGetPayrollConfigsForCompanyLegalEntityLazyQuery,
} from '__generated__/graphql';

export type PayrollConfigWithCutoffDate = {
  id: string;
  cutoffDate: Date;
};

export const isContractToPayrollConfigLinkingEnabled = (
  contractType: Contract['type'],
  sorOnboardingContext: SorOnboardingContext | null | undefined,
): boolean =>
  contractType != null &&
  ![ContractType.CONTRACTOR, ContractType.FREELANCER].includes(contractType) &&
  !sorOnboardingContext?.isHrisOnlyFlow &&
  growthBook.isOn(AppFeature.LINK_CONTRACT_TO_PAYROLL_CONFIG);

const getReferenceDate = (
  sorOnboardingContext: SorOnboardingContext | null | undefined,
  startDate: Date,
  today: Date,
): Date => {
  if (
    sorOnboardingContext?.isGlobalPayrollFlows &&
    startOfDay(startDate) < startOfDay(today)
  )
    return today;

  return startDate;
};

const useGetPayrollConfig = ({
  contractId,
  payFrequency,
  sorOnboardingContext,
}: {
  contractId: Contract['id'];
  payFrequency: Maybe<PayFrequency> | undefined;
  sorOnboardingContext: SorOnboardingContext | null | undefined;
}): PayrollConfigWithCutoffDate | undefined => {
  const [
    getContractEntity,
    { data: contractEntityData },
  ] = useGetContractEntityLazyQuery();

  const [
    getPayrollConfigs,
    { data: payrollConfigsData },
  ] = useGetPayrollConfigsForCompanyLegalEntityLazyQuery();

  const contractToConfigLinkingEnabled = isContractToPayrollConfigLinkingEnabled(
    contractEntityData?.contract?.type,
    sorOnboardingContext,
  );

  useLayoutEffect(() => {
    if (contractId) {
      getContractEntity({
        variables: {
          id: contractId,
        },
      });
    }
  }, [contractId]);

  useLayoutEffect(() => {
    if (!contractToConfigLinkingEnabled) return;
    if (sorOnboardingContext?.isHrisOnlyFlow) return;

    const { legalEntityId, multiplierLegalEntityId, startOn } =
      contractEntityData?.contract ?? {};
    if (!legalEntityId && !multiplierLegalEntityId) return;

    const today = new Date();
    const referenceDate = getReferenceDate(
      sorOnboardingContext,
      parseDate(startOn),
      today,
    );

    getPayrollConfigs({
      variables: {
        companyLegalEntityId: legalEntityId ?? '0',
        multiplierLegalEntityId,
        domain: PayrollInputDomainType.CONTRACT_ONBOARDING,
        referenceDate: dateAsString(referenceDate, 'yyyy-MM-dd'),
        payFrequency: null,
      },
    });
  }, [
    contractToConfigLinkingEnabled,
    contractEntityData,
    sorOnboardingContext,
  ]);

  const result = useMemo(() => {
    const matchingConfig = payrollConfigsData?.payrollConfigsWithNextPayrollCycles?.find(
      (config) =>
        config.frequency === payFrequency &&
        !!config.referencePayrollCycle?.cutOffTo,
    );

    return (
      matchingConfig && {
        id: matchingConfig.id as string,
        cutoffDate: parseDate(
          matchingConfig.referencePayrollCycle?.cutOffTo as string,
        ),
      }
    );
  }, [payFrequency, payrollConfigsData?.payrollConfigsWithNextPayrollCycles]);

  return result;
};

export default useGetPayrollConfig;
