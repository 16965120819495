enum AppFeature {
  CONTRACT_ONBOARDING_CONTRACT_STARTED = 'contract-onboarding-contract-started',
  INVOICES_DUMMY_PDF_URL = 'invoices-dummy-pdf-url',
  TIMESHEETS_V2 = 'timesheets-v2',
  TIMESHEETS_OVERTIME_HOURS = 'timesheets-overtime-hours',
  SALARY_REVISION_NOTES = 'salary-revision-notes',
  CREDIT_CARD_PAYMENT = 'credit-card-payment',
  INTEGRATIONS_CATEGORY_FILTER = 'integration-category-filter',
  INTEGRATIONS_PLATFORM_FEATURES = 'integrations-platform-features',
  PROBATION_SALARY_FEATURE = 'probation-salary',
  SHOW_UPDATE_BANK_ACCOUNT_BANNER = 'show-update-bank-account-banner',
  SHOW_UPDATE_BANK_DETAILS_V1 = 'show-update-bank-details-v1',
  TIMESHEET_DISABLED = 'timesheet-disabled',
  CONTRACT_OFFBOARDING_WORKFLOW = 'contract-offboarding-new-workflow',
  COMPANY_PAYROLL_DEMO = 'company-payroll-demo-data',
  COMPANY_PAYROLL_EOR_PARTNER_BANNER = 'company-payroll-eor-partner-banner',
  TIMESHEET_CLOCK_IN_CLOCK_OUT = 'timesheet-clock-in-clock-out',
  MEMBER_PAYROLL_DEMO = 'member-payroll-demo-data',
  TIMESHEETS_LATE_HOURS = 'timesheets-late-hours',
  SYSTEM_DOWNTIME_WARNING = 'system-downtime-warning',
  INSURANCE_MEMBER_PREMIUM_CONTRIBUTION = 'insurance-member-premium-contribution',
  SERVE_DUMMY_INVOICE = 'serve-dummy-invoice',
  ISR_PUBLIC_VIEW = 'isr-public-view',
  CREDIT_NOTE_VIEW = 'credit-note-view',
  ISR_CX_DOWNLOAD = 'isr-cx-download',
  ISR_OPS_DOWNLOAD = 'isr-ops-download',
  MEMBER_PAYSLIPS_MODULE = 'member-payslips-module',
  AOR_ONBOARDING = 'aor-contract',
  SALARY_REVISION_NEW = 'salary-revision-v1.5',
  INTEGRATIONS_MODULE = 'integrations',
  HIDE_ONBOARDING_EOR = 'hide-onboarding-eor',
  SALARY_CALCULATION_UPDATED_DATE = 'salary-calculation-updated-date',
  HRIS_MANAGER = 'hris-manager',
  SINGLE_ONBOARDING = 'contract.onboarding.single-onboarding',
  SOR_ONBOARDING = 'contract.onboarding.sor-onboarding',
  MEMBER_PAYROLL_LATEST_PAY_STATEMENTS = 'member-payroll-latest-pay-statements',
  SHOW_ONBOARDING_SPECIALIST = 'show-onboarding-specialist',
  FREELANCER_INITIATE_PAYOUT = 'freelancer-initiate-payout',
  INVOICING_SOURCE_REPORT_TABLE = 'invoicing-source-report-table',
  PAYROLL_FILE_DOWNLOAD_NO_START_DATE = 'payroll-file-download-no-start-date',
  PAYROLL_FILE_INPUT_ELIGIBLE_COUNTRIES = 'payroll-input-file-eligible-countries',
  THREE_DAY_TAT_OFFLINE_CONTRACTS = '3d-tat-offline-contracts',
  THREE_DAY_TAT_OFFLINE_CONTRACTS_OPS = '3d-tat-offline-contracts-ops',
  PAYROLL_FILE_GENERIC_INPUT_ELIGIBLE_COUNTRIES = 'payroll-generic-input-file-eligible-countries',
  PEO_MILESTONE_2 = 'peo-milestone-2',
  PAYMENTS_VIEW_OPS = 'payments-view-ops',
  SHOW_SFDC_ACCOUNT = 'show-sfdc-account',
  SHOW_TOTAL_UNPAID_INVOICE_AMOUNT = 'invoice-total-unpaid-amount',
  AUTOMATED_SALARY_PAYOUTS = 'automated-salary-payouts',
  SUPPORT_360 = 'support-360',
  MSA_ADDENDUM = 'msa-addendum',
  OPS_PAYSLIPS_BE_PAGINATION = 'ops-payslips-be-pagination',
  CREDIT_NOTE_VIEW_OPS = 'credit-note-view-ops',
  OPS_GLOBAL_PAYROLL_DASHBOARD = 'ops-global-payroll-dashboard',
  COMPANY_OPS_FX_SENSITIVITY = 'company-ops-fx-sensitivity',
  OPS_ADMIN_DASHBOARD = 'ops-invoice-dashboard',
  OPS_ADMIN_DASHBOARD_NEW_ENGINE = 'ops-invoice-dashboard-new-engine',
  ISR_UPLOAD_UTILITY = 'isr-upload-utility',
  ASP_COMPANY_GROUPING = 'asp-company-grouping',
  OPS_INVOICE_SYNC_BUTTON = 'ops-invoice-sync-button',
  DD_SETUP_AUTOMATION = 'direct-debit-automation-enabled',
  HRIS_REPORT_METADATA = 'hris-report-metadata',
  HRIS_REPORT_DEMO_SHEET_URL = 'hris-report-demo-sheet-url',
  HRIS_ORG_CHART = 'hris-org-chart',
  HRIS_ORG_DIRECTORY = 'hris-org-directory',
  HRIS_NEW_EMPLOYEE_FIELDS = 'hris-new-employee-fields',
  COUNTRY_DOCUMENTS_ONBOARDING_GUIDES_COUNTRIES = 'country-documents-onboarding-guides-countries',
  COUNTRY_DOCUMENTS_VISA_GUIDES_COUNTRIES = 'country-documents-visa-guides-countries',
  RESOURCE_VISA_GUIDES = 'resource-visa-guides',
  HRIS_DEPARTMENTS = 'hris-departments',
  PAYSLIP_WORKFLOW_ENABLED = 'payslip-workflow-enabled',
  DEPOSIT_INVOICE_EXCEPTION = 'deposit-invoice-exception',
  CONTRACT_ONBOARDING_WET_INK_ENABLED = 'contract.onboarding.wet-ink-enabled',
  CONTRACT_ONBOARDING_WITH_WET_INK_ENABLED = 'contract.onboarding.with-wet-ink-enabled',
  CUSTOMER_RAISE_DISPUTE_ENABLED = 'customer-raise-dispute',
  HRIS_ROLES_PERMISSIONS = 'hris-roles-permissions',
  AUTO_DIRECT_DEBIT_ENABLED = 'auto-direct-debit-enabled',
  CUSTOMER_PAYROLL_CALENDAR = 'customer-payroll-calendar',
  CUSTOMER_INVOICE_QUERIES = 'customer-invoice-queries',
  CUSTOMER_AUTO_DEBIT_LIST_VIEW = 'customer-auto-debit-list-view',
  HRIS_DEPARTMENTS_ENABLED_INTEGRATIONS = 'hris-departments-enabled-integrations',
  ANNUAL_PRICING_SETUP = 'annual-pricing-setup',
  SEND_MSA_EMAIL = 'send-msa-email',
  OPS_INVOICE_QUERIES = 'ops-invoice-queries',
  TRINET_SSO = 'trinet-sso',
  MICROSOFT_SSO = 'microsoft-sso',
  ISR_SECURE_LINK = 'isr-secure-link',
  PROCEED_SSO_LOGIN_BUTTON = 'proceed-sso-login-button',
  FRONTEND_THEME = 'frontend-theme',
  PRICING_PLAN_PARTIAL_ALLOCATION = 'pricing-plan-partial-allocation',
  SEPA_DIRECT_DEBIT_ENABLE = 'sepa-direct-debit-enable',
  ACH_BANNER = 'ach-banner',
  FRONTEND_THEME_TOGGLER = 'frontend-theme-toggler',
  HRIS_REPORT_V1_POC = 'hris-report-v1-poc',
  ANNUAL_PRICING_EXPAT_FILTER = 'annual-pricing-expat-filter',
  SHOW_PAY_FREQUENCY_SECTION = 'show_pay_frequency_section',
  ASP_GLOBAL_PAYROLL = 'asp-global-payroll',
  EXPENSE_CATEGORIES = 'expense-categories-gl-codes',
  HOLIDAYS_BY_ENTITY = 'holidays-by-entity',
  DOH_USER_SERVICE = 'doh-user-service',
  OPS_PAYROLL_CALENDAR = 'ops-payroll-calendar',
  COMPANY_ACH_DEBIT_GP_DISABLE = 'company-ach-debit-gp-disable',
  INTEGRATION_FIELD_MAPPING = 'integration-field-mappings',
  INSURANCE_EXPAND = 'insurance-expand',
  INSURANCE_CUSTOM_NOTE_PARTNER_LIST = 'insurance-custom-note-partner-list',
  OPS_COMPANY_PRICING_OFFBOARDING_FEE = 'ops-company-pricing-offboarding-fee',
  GLOBAL_PAYROLL_BANNER = 'global-payroll-banner',
  COMPANY_MULTIPLE_CONTRACT_TEMPLATES = 'company-multiple-contract-templates',
  COMPANY_MULTIPLE_CONTRACT_TEMPLATES_ONBOARDING = 'company-multiple-contract-templates-onboarding',
  USE_PAYROLL_SERVICE_TO_GET_PAYROLL_CYCLES = 'use-payroll-service-to-get-payroll-cycles',
  LINK_CONTRACT_TO_PAYROLL_CONFIG = 'link-contract-to-payroll-config',
  COMPLIANT_OFFBOARDING = 'compliant-offboarding',
}

export default AppFeature;
