import { gql } from '@apollo/client';

export default gql`
  query GetTimeOffContractRequirements($contractId: ID!) {
    timeOffContractRequirements(contractId: $contractId) {
      assignedEntitlements {
        isMandatory
        timeOffType {
          typeId
          type
        }
        definition {
          label
          type
          validation {
            ... on TimeOffFixedValidation {
              allowedContractStatuses
              defaultValue
              isUnlimitedLeavesAllowed
              maximum
              minimum
              unit
            }
          }
          configuration {
            allocation {
              basis
              prorated
            }
          }
          assignedEntityNames
        }
        value
        unit
      }
      availableEntitlements {
        isMandatory
        timeOffType {
          typeId
          type
        }
        definition {
          label
          type
          configuration {
            allocation {
              basis
              prorated
            }
          }

          validation {
            ... on TimeOffFixedValidation {
              allowedContractStatuses
              defaultValue
              isUnlimitedLeavesAllowed
              maximum
              minimum
              unit
            }
          }
          assignedEntityNames
        }
        value
        unit
      }
      clause
    }
  }
`;
