import React, { useContext, useRef } from 'react';

import ReactDOM from 'react-dom';
import { useKey } from 'react-use';

import tw, { theme } from 'twin.macro';

import CardSeparator from '../card-separator';
import ConfirmationDialog from '../confirmation-dialog';
import Icon from '../icon';
import Loader from '../loader';
import { ThemeContext } from '../theme-provider';
import { SpinnerType } from '../types';

interface BaseDocummentPreviewProps {
  open: boolean;
  onClose: () => void;
  header: string;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
  primaryButtonText?: string;
  primaryButtonClick?: () => void;
  loading: boolean;
  tabs?: { key: string; name: string }[];
  selectedTab?: string;
  setSelectedTab?: (key: string) => void;

  requestChangesPageContent?: React.ReactElement;
}

interface DocumentViewerMainPageProps {
  header: string;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
  primaryButtonText?: string;
  primaryButtonClick?: () => void;
  loading: boolean;
  tabs?: { key: string; name: string }[];
  selectedTab?: string;
  setSelectedTab?: (key: string) => void;
}

const TabButton: React.FC<{
  isActive: boolean;
  onClick: () => void;
  name: string;
}> = ({ isActive = false, name, onClick }) => {
  // TODO: Rebrand -> see to update this tab button to common tab button
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <button
      type="button"
      css={[
        tw`flex-grow text-ps bg-background-primary rounded-t-tiny py-small text-center`,
        !isNewThemeApplied && tw`bg-grey05`,
        isActive
          ? [
              isNewThemeApplied
                ? tw`text-text-primary font-semibold`
                : tw`text-primary`,
            ]
          : [isNewThemeApplied ? tw`text-text-secondary` : tw`text-grey01`],
        isActive && [
          tw`border-b-2`,
          isNewThemeApplied
            ? tw`border-border-high-contrast`
            : tw`border-primary`,
        ],
      ]}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

const DocumentViewerMainPage: React.FC<
  React.PropsWithChildren<DocumentViewerMainPageProps>
> = ({
  header,
  secondaryButton,
  primaryButton,
  loading,
  tabs,
  selectedTab,
  setSelectedTab,
  children,
}) => (
  <>
    <div tw="flex flex-row items-center justify-between px-extra-large py-28">
      <span tw="text-h5 font-semibold text-text-primary">{header}</span>
      <div tw="flex flex-row items-center gap-x-small">
        {secondaryButton}
        {primaryButton}
      </div>
    </div>
    {tabs?.length ? (
      <div
        data-testid="document-viewer-tabs"
        tw="px-extra-large flex flex-row gap-x-base"
      >
        {tabs?.map((tab) => (
          <TabButton
            isActive={tab.key === selectedTab}
            key={tab.key}
            onClick={() => {
              if (setSelectedTab) setSelectedTab(tab.key);
            }}
            name={tab.name}
          />
        ))}
      </div>
    ) : null}
    <CardSeparator />
    {loading ? (
      <Loader.Spinner variant={SpinnerType.DOCUMENT_VIEWER} />
    ) : (
      <div tw="py-large flex flex-col gap-y-large overflow-y-auto h-[calc(100vh - 160px)] overflow-x-hidden">
        {children}
      </div>
    )}
  </>
);

export const BaseDocumentViewer: React.FC<
  React.PropsWithChildren<BaseDocummentPreviewProps>
> = ({
  onClose,
  header,
  secondaryButton,
  primaryButton,
  loading,
  tabs,
  selectedTab,
  setSelectedTab,
  children,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const dialogRef = useRef(null);

  useKey('Escape', () => {
    onClose();
  });

  return (
    <ConfirmationDialog.Lightbox>
      <div tw="relative self-start flex flex-row items-start mt-56">
        <div
          tw="bg-background-white width[824px] rounded-t-base mb-tiny"
          data-testid="document-viewer"
          ref={dialogRef}
        >
          <DocumentViewerMainPage
            header={header}
            secondaryButton={secondaryButton}
            primaryButton={primaryButton}
            loading={loading}
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          >
            {children}
          </DocumentViewerMainPage>
        </div>
        <button
          type="button"
          css={[
            tw`ml-base rounded-full bg-background-primary w-extra-large h-extra-large flex justify-center place-items-center`,
            !isNewThemeApplied && tw`bg-grey05`,
          ]}
          onClick={onClose}
        >
          {/* TODO: Rebrand -> set the icon size for new UI */}
          <Icon
            name="cross"
            fill={
              isNewThemeApplied
                ? theme`colors.icon-primary`
                : theme`colors.background`
            }
          />
        </button>
      </div>
    </ConfirmationDialog.Lightbox>
  );
};
const DocumentViewer: React.FC<
  React.PropsWithChildren<BaseDocummentPreviewProps>
> = (props) => {
  const { open, children } = props;

  if (open) {
    return ReactDOM.createPortal(
      <BaseDocumentViewer {...props}>{children}</BaseDocumentViewer>,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }
  return null;
};

export default DocumentViewer;
