import { notEmpty } from 'app/utils/array';

import {
  ComplianceDefinitionScope,
  ComplianceParamDefinition,
  GetCountryComplianceRequirementsQuery,
  Maybe,
} from '__generated__/graphql';

export interface GroupedComplianceParamDefinitions {
  complianceParamDefinitions: {
    [key: string]: ComplianceParamDefinition;
  };
  usedScope?: Maybe<ComplianceDefinitionScope>;
}

export const groupComplianceParamDefinitionByKey = (
  data?: GetCountryComplianceRequirementsQuery,
): GroupedComplianceParamDefinitions => ({
  complianceParamDefinitions:
    (data?.country?.compliance?.requirements?.paramDefinitions &&
      data?.country?.compliance?.requirements?.paramDefinitions
        ?.filter(notEmpty)
        .reduce(
          (
            prev: { [key: string]: ComplianceParamDefinition },
            curr: ComplianceParamDefinition,
          ) => {
            if (curr?.param?.key) prev[curr.param.key] = curr;
            return prev;
          },
          {},
        )) ??
    {},
  usedScope: data?.country?.compliance?.requirements?.usedScope,
});

/**
 * Using BFS due to higher likelihood of high-breadth dependencies rather than
 * deep nested dependencies.
 *
 * Guards against cyclical dependencies even though that declaration should not occur.
 */
export const getComplianceParamDependencies = (
  key: string | null,
  definitions: {
    [key: string]: ComplianceParamDefinition;
  },
  fields: { key: string | null; index: number }[],
): number[] => {
  const result = [];
  const visited: string[] = [];
  const queue = [...(key ? definitions[key]?.dependencies ?? [] : [])];

  while (queue.length > 0) {
    const current = queue.shift();

    if (current?.key && !visited.includes(current.key)) {
      const dependencyIndex = fields.find((field) => field.key === current?.key)
        ?.index;
      if (dependencyIndex !== undefined) result.push(dependencyIndex);

      queue.push(
        ...(current?.key ? definitions[current.key]?.dependencies ?? [] : []),
      );
      visited.push(current.key);
    }
  }

  return result;
};
