import { gql } from '@apollo/client';

export default gql`
  query GetDepartmentDetails($filters: DepartmentFilters!) {
    departments(filters: $filters) {
      id
      name
      description
      hod {
        manager {
          id
          companyUser {
            firstName
            lastName
            userId
          }
        }
        contract {
          id
          member {
            firstName
            lastName
            userId
          }
        }
      }
      employees {
        contracts {
          id
          country
          type
          member {
            id
            userId
            gender
            firstName
            lastName
            emails {
              email
            }
            phoneNos {
              phoneNo
            }
          }
          position
          workEmail
          reportsToManager {
            id
            companyUser {
              userId
            }
            contract {
              member {
                userId
              }
            }
          }
        }
        managers {
          id
          contract {
            id
            country
            type
            member {
              id
              userId
              gender
              firstName
              lastName
              emails {
                email
              }
              phoneNos {
                phoneNo
              }
            }
            position
            workEmail
          }
          companyUser {
            userId
            firstName
            lastName
            title
            emails {
              email
            }
            phoneNos {
              phoneNo
            }
          }
          reportsToManager {
            id
            companyUser {
              userId
            }
            contract {
              member {
                userId
              }
            }
          }
        }
      }
    }
  }
`;
