import { useMemo } from 'react';

import {
  CompanyUserRole,
  Manager,
  useGetManagersLazyQuery,
} from '__generated__/graphql';

import { formatManager } from '../utils/common';

const useGetCompanyManagers = (): {
  fetchCompanyManagers: (companyId: string) => void;
  companyManagers: Manager[];
  loading: boolean;
} => {
  const [trigger, { data, loading }] = useGetManagersLazyQuery();

  const fetchCompanyManagers = (companyId: string) => {
    if (!companyId) return;
    trigger({
      variables: {
        filters: {
          companyId,
        },
      },
    });
  };

  const managers = useMemo(
    () =>
      (data?.orgDirectory.managers ?? [])
        .map(formatManager)
        .filter((manager) =>
          manager?.companyUser?.rolesWithAttributes?.some(
            (m) => m?.role === CompanyUserRole?.MANAGER,
          ),
        ),
    [data],
  ) as Manager[];

  return {
    fetchCompanyManagers,
    companyManagers: managers,
    loading,
  };
};

export default useGetCompanyManagers;
