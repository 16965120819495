/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import {
  Controller,
  FieldPath,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { CardSeparator, Icon, Maybe } from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import tw from 'twin.macro';

import { getNumberWithSuffix } from 'app/utils/format';
import ComboBox, { DropdownValue } from 'common/components/combo-box';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';
import {
  rateFrequencyLabel,
  rateFrequencyLabelFreelancer,
} from 'common/constants/default-labels';
import { useGetPayrollConfig } from 'contract-onboarding/company/hooks';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Contract,
  CurrencyCode,
  DayOfWeek,
  PayFrequency,
  PayFrequencyDateIdentifier,
  RateFrequency,
} from '__generated__/graphql';

import PayrollCutoffDateHint from '../../../basic-details/components/new-payroll-cutoff-date-hint';
import {
  firstPayoutDateOptionsForPayoutFrequencyMap,
  frequencyOptionsForBillingDurationsMap,
  payoutDateLabelsDefinition,
} from '../common/constants';
import { getPayoutDateLabelValues } from '../common/helpers';
import LabelComponentFreelancer from '../label-component';
import ButtonSwitch from './components/button-switch';
import CurrencyNote from './components/currency-note';
import DateNoteMonthly from './components/date-note-monthly';
import DateNoteSemimonthly from './components/date-note-semimonthly';
import DateNoteSemimonthlyLastTwoDates from './components/date-note-semimonthly-last-two-dates';
import PayoutDateNote from './components/payout-date-note';

export interface BillingRateProps {
  contractType: Contract['type'];
  contractId: Contract['id'];
  startOn: Contract['startOn'];
  legalEntityId: Contract['legalEntityId'];
  companyId: Maybe<string> | undefined;
  currencyOptions: Maybe<DropdownValue[]>;
  compliantCurrencies: (CurrencyCode | null | undefined)[];
  countryLocalCurrency?: Maybe<CurrencyCode>;
  inline?: boolean;
}

const BannerContainer = tw.div`flex bg-highlight bg-opacity-10 p-12 items-center rounded-[3px] border border-transparent`;

const BillingRateSection: React.FC<BillingRateProps> = ({
  currencyOptions,
  compliantCurrencies,
  inline = false,
  countryLocalCurrency,
  contractType,
  contractId,
  startOn,
  legalEntityId,
  companyId,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const {
    control,
    register,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { replace: replacePaymentFrequencyDateFieldArray } = useFieldArray({
    control,
    name: 'basePay.paymentFrequencyDate',
  });

  const resetPayoutDates = () => {
    setValue('basePay.firstPayoutDate', undefined);
    setValue('basePay.secondPayoutDate', undefined);
    replacePaymentFrequencyDateFieldArray([]);
  };

  const calculateSecondPayoutDate = (
    number: string | undefined,
  ): string | undefined => {
    if (firstPayoutDate && number) {
      const i = parseInt(number, 10);
      return (i + 15).toString();
    }
    return undefined;
  };

  const firstPayoutDate = useWatch({
    name: 'basePay.firstPayoutDate',
    control,
  });

  const secondPayoutDate = useWatch({
    name: 'basePay.secondPayoutDate',
    control,
  });

  const paymentFrequency = useWatch({
    name: 'basePay.paymentFrequency',
    control,
  });

  const rateFrequency = useWatch({
    name: 'basePay.frequency',
    control,
  });

  const selectedCurrency = useWatch({
    name: 'basePay.currency',
    control,
  });

  const isDefaultCompliantCurrency = useMemo(
    () => compliantCurrencies.includes(selectedCurrency),
    [compliantCurrencies, selectedCurrency],
  );

  const showNonLocalCurrencyCallout =
    !!countryLocalCurrency &&
    !!selectedCurrency &&
    countryLocalCurrency !== selectedCurrency;

  const firstPayoutDateText = useMemo(
    () => getNumberWithSuffix(firstPayoutDate),
    [firstPayoutDate],
  );

  const secondPayoutDateText = useMemo(
    () => getNumberWithSuffix(secondPayoutDate),
    [firstPayoutDate, secondPayoutDate],
  );

  const handlePaymentDateOne = (dateValue: string) => {
    switch (paymentFrequency) {
      case PayFrequency.WEEKLY:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.PAYOUT_DAY,
            dateOfMonth: null,
            dayOfWeek: dateValue,
          },
        ]);

        break;
      case PayFrequency.SEMIMONTHLY:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.FIRST_PAYOUT_DATE,
            dateOfMonth: parseInt(dateValue, 10),
            dayOfWeek: null,
          },
          {
            identifier: PayFrequencyDateIdentifier.SECOND_PAYOUT_DATE,
            dateOfMonth: parseInt(dateValue, 10) + 15,
            dayOfWeek: null,
          },
        ]);
        setValue(
          'basePay.secondPayoutDate',
          calculateSecondPayoutDate(dateValue),
        );
        break;
      case PayFrequency.MONTHLY:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.PAYOUT_DATE,
            dateOfMonth: parseInt(dateValue, 10),
            dayOfWeek: null,
          },
        ]);

        break;
      default:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.PAYOUT_DAY,
            dateOfMonth: null,
            dayOfWeek: DayOfWeek.MONDAY,
          },
        ]);
    }
  };

  useEffect(() => {
    handlePaymentDateOne(firstPayoutDate);
  }, [firstPayoutDate]);

  const monthlyPayoutLabels = useMemo(
    () => getPayoutDateLabelValues(1, 31),
    [],
  );

  const rateFrequencyDropdownOptions = useMemo(
    () =>
      [
        RateFrequency.HOURLY,
        RateFrequency.DAILY,
        RateFrequency.SEMIMONTHLY,
        RateFrequency.MONTHLY,
      ].map((frequency) => ({
        title: rateFrequencyLabelFreelancer[frequency],
        value: frequency,
      })),
    [],
  );

  const sorOnboardingContext = useSorOnboardingContext({
    type: contractType,
    companyId,
    legalEntityId,
  });

  const payrollConfig = useGetPayrollConfig({
    contractId,
    payFrequency: paymentFrequency,
    sorOnboardingContext,
  });

  return (
    <>
      <LabelComponentFreelancer
        labelText={t(
          'definition-phase.compensation.billing-rate-label',
          'Billing Rate',
        )}
        description={t(
          'definition-phase.compensation.billing-rate-description',
          'Rate that contractor charges excluding taxes, fees, and discounts',
        )}
      />
      <div tw="flex flex-row">
        <div tw="w-1/2">
          <DropdownText.Container
            tw="flex-grow"
            data-testid="fixed-pay-currency-input-base"
          >
            <Controller
              name={`basePay.currency` as FieldPath<CompensationFormValues>}
              control={control}
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  data-testid="currency-select"
                  variant="inline"
                  showArrow
                  value={value as string}
                  dropdownValues={currencyOptions || []}
                  placeholder={t(
                    'compensation.currency.placeholder',
                    'Type a currency/country',
                  )}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name={`basePay.amount` as FieldPath<CompensationFormValues>}
              render={({ field: { value, onChange } }) => (
                <DropdownText.Input
                  currency
                  type="number"
                  value={value}
                  step="0.01"
                  placeholder="0"
                  tw="appearance-none"
                  {...register(`basePay.amount`)}
                  data-testid="basePay-amount-input"
                  divStyles={tw`flex-grow`}
                  onChange={(e) => {
                    onChange(e);
                    trigger('additionalPays');
                  }}
                />
              )}
            />
          </DropdownText.Container>
          {inline && <div data-testid="spacer" />}
        </div>
        <div tw="w-1/2">
          <TextInput.Container
            data-testid="pay-rate-select"
            tw="flex flex-row items-center"
          >
            <span tw="mx-small font-normal text-ps">
              {t(
                `definition-phase.compensation.billing-frequency-label`,
                'Per',
              )}
            </span>
            <Controller
              name={`basePay.frequency` as FieldPath<CompensationFormValues>}
              control={control}
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  data-testid="frequency-dropdown"
                  id="frequency-select"
                  css={[tw`w-1/2`, inline && tw`col-span-3 flex flex-row`]}
                  variant="default"
                  value={value as string}
                  placeholder={t(
                    `compensation.base.frequency.placeholder`,
                    'Select pay rate',
                  )}
                  dropdownValues={rateFrequencyDropdownOptions}
                  onChange={(e) => {
                    setValue('basePay.paymentFrequency', undefined);
                    resetPayoutDates();
                    onChange(e);
                  }}
                />
              )}
            />
          </TextInput.Container>
        </div>
      </div>
      <CurrencyNote />
      {showNonLocalCurrencyCallout && (
        <BannerContainer>
          <Icon name="info-warning" tw="w-[70px] px-small" />
          <Trans
            i18nKey="definition-phase.compensation.not-local-currency-warning"
            t={t}
          >
            <span
              tw="text-ps text-text-primary"
              data-testid="not-local-currency-warning"
            >
              You have chosen <strong>{{ selectedCurrency }}</strong> which is
              not a local currency for the freelancer. Sending{' '}
              <strong>{{ selectedCurrency }}</strong> to this freelancer may
              result in SWIFT charges levied by intermediary banks involved
              <br />
              We suggest you to choose local currency as sending funds in local
              currency will ensure that the freelancers receive the full
              payment.
            </span>
          </Trans>
        </BannerContainer>
      )}
      {!isDefaultCompliantCurrency && (
        <BannerContainer>
          <Icon name="info-warning" tw="w-[70px] px-small" />
          <Trans
            i18nKey="definition-phase.compensation.currency-change-warning-notice"
            t={t}
          >
            <span
              tw="text-ps text-text-primary"
              data-testid="currency-change-warning"
            >
              Please ensure that freelancer&apos;s bank account can receive
              funds in <strong>{{ selectedCurrency }}</strong> . <br />
              Freelancer will be asked bank account details accordingly during
              their onboarding.
            </span>
          </Trans>
        </BannerContainer>
      )}
      <CardSeparator tw="mt-12" />
      <LabelComponentFreelancer
        labelText={t(
          'definition-phase.compensation.payout-frequency-label',
          'Payout Frequency',
        )}
        description={t(
          'definition-phase.compensation.payout-frequency-description-contractor',
          `Choose how frequently you'd like to pay the contractor`,
        )}
      />
      <div>
        <div tw="grid grid-cols-2 gap-x-base">
          {[PayFrequency.MONTHLY, PayFrequency.SEMIMONTHLY].map((frequency) => (
            <ButtonSwitch
              data-testid={`button-switch-${frequency.toLowerCase()}`}
              divStyle={
                frequency === paymentFrequency
                  ? tw`bg-background-action`
                  : tw`bg-transparent border border-border-high-contrast border-opacity-20`
              }
              labelStyles={
                frequency === paymentFrequency
                  ? tw`text-text-inverted-primary`
                  : tw`text-text-primary`
              }
              disabled={
                rateFrequency &&
                !frequencyOptionsForBillingDurationsMap[rateFrequency].includes(
                  frequency,
                )
              }
              key={frequency}
              value={frequency}
              {...register(`basePay.paymentFrequency`)}
              onChange={(e) => {
                register('basePay.paymentFrequency').onChange(e);
                resetPayoutDates();
              }}
            >
              <span>{rateFrequencyLabel[frequency]}</span>
            </ButtonSwitch>
          ))}
          {errors.payrollConfigId && (
            <div tw="mt-extra-small">
              <TextInput.Error>
                {errors.payrollConfigId.message}
              </TextInput.Error>
            </div>
          )}

          <PayrollCutoffDateHint
            startDate={startOn}
            contractType={contractType}
            sorOnboardingContext={sorOnboardingContext}
            payrollConfig={payrollConfig}
          />
        </div>
        <div tw="grid grid-cols-2 gap-x-base mt-extra-large">
          <div>
            <TextInput.Container data-testid="first-payout-date">
              <TextInput.Label>
                {payoutDateLabelsDefinition[paymentFrequency]}
              </TextInput.Label>
              <Controller
                name="basePay.firstPayoutDate"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    data-testid="first-payment-date-dropdown"
                    variant={paymentFrequency ? 'default' : 'disabled'}
                    disabled={!paymentFrequency}
                    dropdownValues={
                      firstPayoutDateOptionsForPayoutFrequencyMap[
                        paymentFrequency
                      ] ?? [
                        {
                          title: t(
                            'definition-phase.compensation.first-payout-date-default',
                            'Select',
                          ),
                          value: '',
                        },
                      ]
                    }
                    showArrow
                    value={value || ''}
                    placeholder={t(
                      'definition-phase.compensation.first-payout-date-placeholder',
                      'Select',
                    )}
                    onChange={(e) => {
                      onChange(e);
                      handlePaymentDateOne(e);
                    }}
                    error={!!errors.firstPayoutDate}
                  />
                )}
              />
              {errors.firstPayoutDate && (
                <TextInput.Error>
                  {errors.firstPayoutDate.message as string}
                </TextInput.Error>
              )}
            </TextInput.Container>
          </div>

          {paymentFrequency === PayFrequency.SEMIMONTHLY && (
            <div>
              <TextInput.Container data-testid="second-payout-date">
                <TextInput.Label>
                  {t(
                    'definition-phase.compensation.second-payout-date-label',
                    'Second Payout Date',
                  )}
                </TextInput.Label>
                <Controller
                  name="basePay.secondPayoutDate"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ComboBox
                      data-testid="second-payment-date-dropdown"
                      variant="disabled"
                      disabled
                      dropdownValues={
                        monthlyPayoutLabels ?? [
                          {
                            title: t(
                              'definition-phase.compensation.second-payout-date-default',
                              'Select',
                            ),
                            value: '',
                          },
                        ]
                      }
                      showArrow
                      value={value || ''}
                      placeholder={t(
                        'definition-phase.compensation.second-payout-date-placeholder',
                        'Select',
                      )}
                      onChange={onChange}
                      error={!!errors.secondPayoutDate}
                    />
                  )}
                />
                {errors.secondPayoutDate && (
                  <TextInput.Error>
                    {errors.secondPayoutDate.message as string}
                  </TextInput.Error>
                )}
              </TextInput.Container>
            </div>
          )}
        </div>
        <DateNoteSemimonthly
          paymentFrequency={paymentFrequency}
          firstPayoutDate={firstPayoutDate}
          firstPayoutDateText={firstPayoutDateText}
          secondPayoutDateText={secondPayoutDateText}
        />
        <DateNoteMonthly
          paymentFrequency={paymentFrequency}
          firstPayoutDate={firstPayoutDate}
          firstPayoutDateText={firstPayoutDateText}
        />
        <DateNoteSemimonthlyLastTwoDates
          paymentFrequency={paymentFrequency}
          firstPayoutDate={firstPayoutDate}
        />
      </div>
      <PayoutDateNote contractType={contractType} />
    </>
  );
};

export default BillingRateSection;
