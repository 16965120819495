import { theme } from 'twin.macro';

import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'dashboard',
  permission: 'dashboard',
  folder: 'dashboard/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Dashboard',
    route: routes.root,
    icon: {
      name: 'house',
      fill: theme`colors.black`,
    },
    activeIcon: {
      name: 'house-filled',
    },
    sort: 0,
    home: true,
    group: NavGroups.MAIN,
  },
};

export default config;
