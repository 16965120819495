/** @jsxImportSource @emotion/react */
import React from 'react';

import tw from 'twin.macro';

import { classified } from 'app/security';

import { DetailLabel, DetailValue } from '../../../layout';
import { EditableSingleDetailRowProps } from '../../types';

const EditableSingleRow: React.FC<
  React.PropsWithChildren<EditableSingleDetailRowProps>
> = ({
  id,
  label,
  value,
  isEditing = false,
  hideLabel = false,
  labelStyles,
  valueStyles,
  renderValue,
  hide = false,
  children,
}) => (
  <>
    {!hideLabel && (
      <DetailLabel
        css={[isEditing && tw`self-start`, labelStyles]}
        htmlFor={id}
      >
        {(label || '') as string}
      </DetailLabel>
    )}
    {isEditing && !hide ? (
      <span tw="col-span-3" {...classified}>
        {children}
      </span>
    ) : (
      renderValue || (
        <DetailValue
          {...classified}
          css={[isEditing && hide && tw`text-text-tertiary`, valueStyles]}
        >
          {value as string}
        </DetailValue>
      )
    )}
  </>
);

export default EditableSingleRow;
