/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Icon, ThemeContext } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import tw from 'twin.macro';

import {
  ComplianceParamPeriodUnit,
  Contract,
  ContractType,
  TimeOffUnit,
} from '__generated__/graphql';

import useComplianceLabels from '../../../company/hooks/compliance-labels';

const EorTooltip: React.FC<{
  convertedNoticePeriodValue: number;
}> = ({ convertedNoticePeriodValue }) => {
  const { t } = useTranslation('contract-onboarding.company');

  const labels = useComplianceLabels();

  return (
    <span>
      <Trans
        t={t}
        i18nKey="compliance.notice-after-probation.disclaimer"
        count={convertedNoticePeriodValue}
      >
        You will be reached out to pay a one time deposit equivalent to the
        Gross salary of{' '}
        <span tw="font-semibold text-primary">
          {{ convertedNoticePeriodValue }}
        </span>{' '}
        {{
          unit: labels[ComplianceParamPeriodUnit.MONTHS](
            convertedNoticePeriodValue,
          ),
        }}
        .
      </Trans>
    </span>
  );
};

const ContractorTooltip: React.FC<{
  paramValue: number;
  unit: ComplianceParamPeriodUnit | TimeOffUnit | null;
}> = ({ paramValue, unit }) => {
  const { t } = useTranslation('contract-onboarding.company');

  const labels = useComplianceLabels();

  if (!unit) return null;

  return (
    <span>
      <Trans
        t={t}
        i18nKey="compliance.notice-after-probation.disclaimer-contractor"
      >
        You will be reached out to pay a one time deposit equivalent to the
        contractor’s compensation of
        <span tw="font-semibold text-text-primary"> {{ paramValue }} </span>
        {{ unit: labels[unit](paramValue) }}.
      </Trans>
    </span>
  );
};

const getConvertCeilValue = ({
  value,
  divider,
}: {
  value: number;
  divider: number;
}): number => {
  const newValue = Math.ceil(value / divider);
  return newValue < 1 ? 1 : newValue;
};

const convertNoticePeriodValuesToMonth = ({
  value,
  unit,
}: {
  value: number;
  unit: ComplianceParamPeriodUnit;
}): number => {
  const unitType = {
    DAYS: getConvertCeilValue({ value, divider: 30 }),
    MONTHS: getConvertCeilValue({ value, divider: 1 }),
    WEEKS: getConvertCeilValue({ value, divider: 4 }),
    YEARS: getConvertCeilValue({ value, divider: 1 / 12 }),
    NONE: 1,
  };

  return unitType[unit] ?? unitType.MONTHS;
};

const NoticePeriodTooltip: React.FC<{
  contractType?: Contract['type'];
  paramValue: number;
  unit: ComplianceParamPeriodUnit | TimeOffUnit | null;
  isMaltaFixedTermContract: boolean;
  isGermanyContract: boolean;
}> = ({
  contractType,
  paramValue,
  unit,
  isMaltaFixedTermContract,
  isGermanyContract,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const convertedNoticePeriodValue = useMemo(
    () =>
      convertNoticePeriodValuesToMonth({
        value: paramValue,
        unit: (unit as ComplianceParamPeriodUnit) ?? 'MONTHS',
      }),
    [paramValue, unit],
  );

  const shouldHideDepositInvoiceTooltip = useFeature(
    AppFeature.DEPOSIT_INVOICE_EXCEPTION,
  ).on;

  if (isMaltaFixedTermContract) return null;

  if (isGermanyContract) return null;

  if (shouldHideDepositInvoiceTooltip) return null;

  return (
    <div
      data-testid="notice-period-tooltip"
      css={[
        tw`rounded-base flex flex-row p-small items-center text-ps text-text-primary gap-x-small bg-background-primary`,
        !isNewThemeApplied && tw`bg-primary bg-opacity-5`,
      ]}
    >
      <Icon name="info" />
      {contractType === ContractType.CONTRACTOR ? (
        <ContractorTooltip paramValue={paramValue} unit={unit} />
      ) : (
        <EorTooltip convertedNoticePeriodValue={convertedNoticePeriodValue} />
      )}
    </div>
  );
};

export default NoticePeriodTooltip;
