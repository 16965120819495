import { gql } from '@apollo/client';

export default gql`
  mutation ResetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input)
      @rest(
        type: "Void"
        method: "POST"
        path: "account/reset-password/finish"
        endpoint: "user"
      ) {
      void
    }
  }
`;
