import { gql } from '@apollo/client';

export default gql`
  query GetSalaryCalculationPdf($params: SalaryCalculationParam) {
    salaryCalculationPdf(params: $params) {
      link
      contentType
      name
    }
  }
`;
