/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { CardSeparator, Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import TextInput from 'common/components/text-input';

import {
  CalculationBreakdownFragment,
  CountryCode,
  CurrencyCode,
  RateFrequency,
} from '__generated__/graphql';

import SalaryCalculatorBreakdown from './components/breakdown';
import Loader from './components/loader';
import SalaryBreakdownNotes from './components/salary-breakdown-notes';

interface SalaryCalculatorViewProps {
  title?: string;
  show: boolean;
  salaryCalculation: {
    companyPayable?: CalculationBreakdownFragment;
    employeePayable?: CalculationBreakdownFragment;
  };
  country?: CountryCode | null;
  amount: number;
  frequency?: RateFrequency | null;
  currency?: CurrencyCode | null;
  inline?: boolean;
  loading: boolean;
  averageHours?: number | null;
}

const SalaryCalculatorView: React.FC<SalaryCalculatorViewProps> = ({
  title,
  show,
  salaryCalculation,
  country,
  amount,
  frequency,
  currency,
  inline = false,
  loading,
  averageHours,
  ...props
}) => {
  const { t } = useTranslation('salary-calculator');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const [showNested, setShowNested] = useState(false);

  const showBreakdown =
    showNested &&
    salaryCalculation.companyPayable &&
    salaryCalculation.employeePayable;

  return (
    <>
      {show && country && currency && (
        <div tw="mt-12">
          <div
            data-testid="salary-calculator-view"
            tw="flex flex-col"
            {...props}
          >
            <div
              css={[
                inline && tw`grid grid-cols-4 items-center gap-x-2`,
                showBreakdown &&
                  tw`pb-small border border-border-primary rounded-tiny`,
              ]}
            >
              <div
                data-testid="expand"
                role="presentation"
                onClick={() => setShowNested((prev) => !prev)}
                css={[
                  tw`flex justify-between items-center py-extra-small rounded-tiny pr-2`,
                  tw`bg-background-primary  p-small`,
                  showNested && tw`rounded-b-none`,
                  !isNewThemeApplied && tw`bg-grey05`,
                ]}
              >
                <TextInput.Label
                  css={[tw`mb-auto font-semibold cursor-pointer`, tw`mb-none`]}
                >
                  {title ||
                    t(
                      'compensation.salary-breakdown-main-label',
                      ' Salary Breakdown',
                    )}
                </TextInput.Label>
                <Icon
                  tw="w-base h-base"
                  name={showNested ? 'caret-down' : 'caret-right'}
                  fill={theme`colors.icon-primary`}
                />
              </div>
              {showBreakdown && (
                <div
                  tw="px-small"
                  data-testid="salary-breakdown-hourly-heading"
                >
                  {frequency === RateFrequency.HOURLY && (
                    <>
                      <div tw="pt-small">
                        <Trans
                          i18nKey="compensation.salary-breakdown-sub-heading-one"
                          t={t}
                        >
                          <p tw="text-ps text-text-primary">
                            <span tw="border-b border-border-brand border-dotted">
                              Approx. <strong>Monthly</strong> Salary Breakdown
                              for
                            </span>
                            <br />
                            <strong>
                              {{ currency }} {{ amount }}
                            </strong>{' '}
                            per hour (avg. {{ averageHours }} hours per month)
                          </p>
                        </Trans>
                      </div>
                      <CardSeparator tw="mt-small" />
                    </>
                  )}
                  <SalaryCalculatorBreakdown
                    data={salaryCalculation}
                    country={country}
                    frequency={frequency ?? undefined}
                  />
                  <CardSeparator tw="mt-small" />

                  <SalaryBreakdownNotes
                    frequency={frequency}
                    country={country}
                  />
                </div>
              )}
              {loading && showNested && <Loader />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalaryCalculatorView;
