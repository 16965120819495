/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { percentageDifference } from '@multiplier/common';
import tw from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import AppFeature from 'app/features';
import { numberAsCurrency } from 'app/utils/format';
import ComboBox, { DropdownValue } from 'common/components/combo-box';
import DatePicker from 'common/components/date-picker';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';
import { rateFrequencyLabel } from 'common/constants/default-labels';
import InfoNote from 'performance-reviews/components/info-note';
import { PerformanceReviewFormValues } from 'performance-reviews/components/performance-review-form';
import SalaryChangesNote from 'performance-reviews/components/performance-review-form/components/salary-changes-note';
import { useSalaryRevisionNotes } from 'performance-reviews/hooks';
import { getMinimumEffectiveDate } from 'performance-reviews/hooks/performance-review-schema';

import {
  Contract,
  FixedPayComponent,
  RateFrequency,
} from '__generated__/graphql';

interface MonthlyPayInputProps {
  isEditing?: boolean;
  basePay?: FixedPayComponent | null;
  currencyOptions?: DropdownValue[];
  employeeStartAfterThisMonth?: boolean;
  startOn?: Contract['startOn'];
  hasProbationAmount?: boolean;
  comparing: boolean;
}

const MonthlyPayInput: React.FC<MonthlyPayInputProps> = ({
  isEditing,
  basePay,
  currencyOptions,
  employeeStartAfterThisMonth,
  startOn,
  comparing,
}) => {
  const { t, i18n } = useTranslation('team.company');

  if (!isEditing) {
    return (
      <div css={[tw`text-ps`, !comparing && tw`col-span-3`]}>
        <p data-testid="member-salary" tw="text-text-primary">
          <strong tw="mr-6">{basePay?.currency}</strong>
          {`${numberAsCurrency(i18n.language, basePay?.amount, 2)} / ${
            rateFrequencyLabel[basePay?.frequency || RateFrequency.MONTHLY]
          }`}
        </p>
        <CurrencyHelper
          amount={basePay?.amount}
          referenceCurrency={basePay?.currency}
        />
      </div>
    );
  }

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<PerformanceReviewFormValues>();

  const enableSalaryRevisionNotes = useFeature(AppFeature.SALARY_REVISION_NOTES)
    .on;

  const { getNote, message } = useSalaryRevisionNotes();

  const [revisedSalary, contractId, effectiveMonth] = watch([
    'revisedSalary',
    'contractId',
    'effectiveMonth',
  ]);

  useEffect(() => {
    getNote(contractId, effectiveMonth);
  }, [contractId, effectiveMonth]);

  const salaryDifference = useMemo(() => {
    const currentSalary = basePay?.amount ?? 0;
    return Number(revisedSalary) && Number(currentSalary)
      ? percentageDifference(Number(revisedSalary), Number(currentSalary))
      : 0;
  }, [revisedSalary, basePay]);

  return (
    <div tw="col-span-3">
      <div tw="flex flex-col gap-y-6 w-2/3">
        <DropdownText.Container tw="flex-grow mt-small">
          <Controller
            name="currency"
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="currency-select"
                variant="inline"
                showArrow
                value={value as string}
                dropdownValues={currencyOptions || []}
                placeholder=""
                onChange={onChange}
                disabled
              />
            )}
          />
          <DropdownText.Input
            id="revised-salary"
            currency
            type="number"
            step="0.01"
            placeholder={t(
              'compensation-tab.revised-salary.placeholder',
              'Amount',
            )}
            tw="appearance-none"
            data-testid="currency-input"
            divStyles={tw`flex-grow`}
            {...register('revisedSalary')}
          />
        </DropdownText.Container>
        {salaryDifference !== 0 && (
          <SalaryChangesNote salaryDifference={salaryDifference} />
        )}
        <CurrencyHelper
          tw="mb-12"
          amount={watch('revisedSalary')}
          referenceCurrency={watch('currency')}
        />
      </div>
      <TextInput.Container tw="w-2/3 mt-large">
        <TextInput.Label htmlFor="effective-month">
          {t('form.effective-month.label', 'Effective date')}
        </TextInput.Label>
        <Controller
          name="effectiveMonth"
          render={({ field: { value, onChange } }) => (
            <DatePicker
              data-testid="effective-month"
              id="effective-month"
              tw="mt-2"
              min={getMinimumEffectiveDate(
                enableSalaryRevisionNotes,
                startOn,
                employeeStartAfterThisMonth,
                basePay?.validFromInclusive,
              )}
              value={value}
              onChange={onChange}
              error={!!errors.effectiveMonth}
              helperText={errors.effectiveMonth?.message}
              loading={false}
              placeholder={t('form.effective-month.placeholder', 'DD MM YYYY')}
            />
          )}
        />
      </TextInput.Container>
      {enableSalaryRevisionNotes && message && (
        <InfoNote tw="mt-small">{message}</InfoNote>
      )}
    </div>
  );
};

export default MonthlyPayInput;
