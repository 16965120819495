import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import { useBeforeUnload } from 'app/hooks';
import importMetaEnv from 'import-meta-env';

export const removeHubspotScripts = (): void => {
  const hubspotPortalId = importMetaEnv.VITE_HUBSPOT_PORTAL_ID;

  if (!hubspotPortalId) {
    return;
  }

  [
    `hs-ads-pixel-${hubspotPortalId}`,
    `cookieBanner-${hubspotPortalId}`,
    `CollectedForms-${hubspotPortalId}`,
    'hs-analytics',
  ].forEach((elementId) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.remove();
    }
  });
};

const useRemoveHubspotScriptsOnUnmount = (): void => {
  useEffect(
    () => () => {
      removeHubspotScripts();
    },
    [],
  );
};

const HubspotTrackingCode: React.FC = () => {
  useRemoveHubspotScriptsOnUnmount();

  useBeforeUnload(() => removeHubspotScripts());

  return (
    <Helmet>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src={`//js.hs-scripts.com/${importMetaEnv.VITE_HUBSPOT_PORTAL_ID}.js`}
      />
    </Helmet>
  );
};

export default HubspotTrackingCode;
