/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Card, Icon } from '@multiplier/common';
import { numberAsCurrency } from '@multiplier/format';
import { theme } from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import { Experience } from 'app/models/module-config';
import { Religion } from 'common/constants/default-labels';
import { AdditionalPayComponentOnSalaryRevision } from 'contract-onboarding/company/components/additional-pays-table';
import { getFixedPayInputLabelText } from 'contract-onboarding/company/components/fixed-pay-input';
import paySupplementRoutes from 'pay-supplements/routes';

import { Company, Contract } from '__generated__/graphql';

import { PerformanceReviewFormValues } from '../..';

const CompensationComponentInfo: React.FC<{
  title: string;
  value?: string | number;
  subValue?: React.ReactElement;
}> = ({ title, value, subValue }) => (
  <>
    <span tw="text-text-tertiary">{title}</span>
    <span>
      <span tw="font-semibold text-text-primary">{value}</span>
      {subValue}
    </span>
  </>
);

const CurrentCompensationSection: React.FC<{
  contract: Contract;
  company: Company;
  memberReligion: Religion;
  hasProbationAmount: boolean;
}> = ({ contract, company, memberReligion, hasProbationAmount }) => {
  const { t, i18n } = useTranslation('performance-reviews');
  const { t: tContractOnboarding } = useTranslation(
    'contract-onboarding.common',
  );

  const { watch } = useFormContext<PerformanceReviewFormValues>();

  const [currentAdditionalPays] = watch(['currentAdditionalPays']);

  const [showCompensation, setShowCompensation] = useState(true);

  return (
    <Card tw="p-large grid grid-cols-1 mobile:px-base mt-base">
      <div
        tw="flex items-center justify-between text-[18px] font-semibold text-text-primary hover:cursor-pointer"
        aria-hidden="true"
        onClick={() => setShowCompensation(!showCompensation)}
      >
        {t(
          'form.current-salary.total-current-compensation',
          'Total Current Compensation',
        )}
        <Icon
          tw="w-base h-base"
          fill={theme`colors.icon-primary`}
          name={showCompensation ? 'caret-up' : 'caret-down'}
        />
      </div>
      {showCompensation && (
        <div>
          <p tw="text-ps mb-small mt-large text-text-primary font-semibold">
            {t('form.current-salary.prefix', 'Current')}{' '}
            {getFixedPayInputLabelText({
              contractTerm: contract.term,
              rateType: contract.compensation?.basePay?.rateType,
              frequency: contract.compensation?.basePay?.frequency,
              label: contract.compensation?.basePay?.label,
              t: tContractOnboarding,
            })}
          </p>
          <div tw="flex items-center">
            <div tw="border border-border-primary mb-extra-small" />
            <div tw="grid grid-cols-2 gap-y-extra-small text-ps w-[700px]">
              <CompensationComponentInfo
                title="Position"
                value={contract?.position ?? ''}
              />
              <CompensationComponentInfo
                title="Payment Form"
                value="Fixed Pay"
              />
              {hasProbationAmount && (
                <CompensationComponentInfo
                  title={`Probation ${getFixedPayInputLabelText({
                    contractTerm: contract?.term,
                    rateType: contract?.compensation?.basePay?.rateType,
                    frequency: contract?.compensation?.basePay?.frequency,
                    label: contract?.compensation?.basePay?.label,
                    t: tContractOnboarding,
                  })}`}
                  value={`${
                    contract.compensation?.probationBasePay?.currency
                  } ${numberAsCurrency(
                    i18n.language,
                    contract.compensation?.probationBasePay?.amount,
                    2,
                  )}`}
                  subValue={
                    <CurrencyHelper
                      amount={contract.compensation?.probationBasePay?.amount}
                      referenceCurrency={
                        contract.compensation?.probationBasePay?.currency
                      }
                    />
                  }
                />
              )}
              <CompensationComponentInfo
                title={`${
                  hasProbationAmount
                    ? t('form.revised-salary.post-probation', 'Post-Probation')
                    : ''
                } ${getFixedPayInputLabelText({
                  contractTerm: contract?.term,
                  rateType: contract?.compensation?.basePay?.rateType,
                  frequency: contract?.compensation?.basePay?.frequency,
                  label: contract?.compensation?.basePay?.label,
                  t: tContractOnboarding,
                })}`}
                value={`${
                  contract.compensation?.postProbationBasePay?.currency
                } ${numberAsCurrency(
                  i18n.language,
                  contract.compensation?.postProbationBasePay?.amount,
                  2,
                )}`}
                subValue={
                  <CurrencyHelper
                    amount={contract.compensation?.postProbationBasePay?.amount}
                    referenceCurrency={
                      contract.compensation?.postProbationBasePay?.currency
                    }
                  />
                }
              />
            </div>
          </div>
          {currentAdditionalPays && currentAdditionalPays.length > 0 && (
            <div tw="mt-large">
              <p tw="text-ps mt-extra-small mb-small text-text-primary font-semibold">
                {t(
                  'form.current-salary.additional-compensation',
                  'Current Additional Compensation',
                )}
              </p>
              {currentAdditionalPays?.map((additionalPay, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${index}-${additionalPay?.name}`}>
                  <AdditionalPayComponentOnSalaryRevision
                    companyName={company?.displayName}
                    payItem={additionalPay}
                    showEdit={false}
                    deletePayItem={() => null}
                    toggleEdit={() => null}
                    religion={memberReligion}
                    contractType={contract?.type}
                    countryCode={contract.country}
                  />
                </React.Fragment>
              ))}
              <div tw="flex mt-large">
                <span tw="flex text-ps mr-extra-small text-text-primary font-semibold">
                  Note:{' '}
                </span>
                <p tw="text-ps text-text-tertiary">
                  <Trans
                    t={t}
                    i18nKey="form.current-salary.additional-compensation-notice"
                  >
                    The current Additional compensation such as Variable
                    Performance Bonus, Commission etc. can be provided through
                    Pay supplements{' '}
                    <Link
                      tw="underline text-text-primary font-semibold"
                      to={`/${Experience.COMPANY}/${paySupplementRoutes.root}`}
                      target="_blank"
                    >
                      here
                    </Link>
                  </Trans>
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default CurrentCompensationSection;
