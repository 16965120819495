import countryIDD from 'common/constants/country-idd';

import { CountryCode } from '__generated__/graphql';

// eslint-disable-next-line import/prefer-default-export
export const getFirstCountryCodeBasedOnPhoneCode = (
  parsedPhoneCode: string | undefined,
  defaultCountryCode?: CountryCode | null,
): string => {
  let code = Object.entries(countryIDD).find(
    ([, phoneCode]) => phoneCode === parsedPhoneCode,
  )?.[0];
  if (!code && defaultCountryCode) {
    code = defaultCountryCode;
  }
  return code ?? '';
};
