import React from 'react';

import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import { userVar } from '@multiplier/user';
import tw from 'twin.macro';

import { Experience } from '../../../mocks/types';

const SectionHeader = tw.h6`text-h6 text-left text-text-primary font-semibold`;

const HeaderSection: React.FC = () => {
  const { t } = useTranslation('contract-onboarding');
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  return (
    <div>
      <SectionHeader>
        {t(
          'company-entity-bulk-onboarding-flow.bulk-upload-section.header.title',
          'Upload employee data',
        )}
      </SectionHeader>
      {current === Experience.COMPANY && (
        <p tw="text-ps text-text-tertiary text-left">
          {t(
            'company-entity-bulk-onboarding-flow.bulk-upload-section.header.description',
            'Bulk upload employee details and compensation data',
          )}
        </p>
      )}
    </div>
  );
};

export default HeaderSection;
