import { TFunction } from 'i18next';

import { GetDeductionDefinitionQuery } from '__generated__/graphql';

export type DeductionFromQuery = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GetDeductionDefinitionQuery['country']>['compliance']
    >['compensationStructureDefinition']
  >['deductions']
>[number];

export type DeductionConstraintFromQuery = NonNullable<DeductionFromQuery>['deductionConstraint'];

export enum DeductionLabel {
  EMPLOYER_MATCH_LIMIT = 'employerMatchLimit',
  EMPLOYEE_MATCH_LIMIT = 'employeeMatchLimit',
  EMPLOYER_PROVIDENT_FUND = 'employerProvidentFund',
  EMPLOYEE_PROVIDENT_FUND = 'employeeProvidentFund',
}

export const getDeductionLabel: {
  [key: string]: {
    label: (t: TFunction<'contract-onboarding.common'>) => string;
  };
} = {
  [DeductionLabel.EMPLOYEE_MATCH_LIMIT]: {
    label: (t) =>
      t('deduction.employee-match-limit.label', 'Employee Contribution'),
  },
  [DeductionLabel.EMPLOYER_MATCH_LIMIT]: {
    label: (t) =>
      t('deduction.employer-match-limit.label', 'Employer Match Limit'),
  },
};
