/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, DatePicker, Radio, TextInput } from '@multiplier/common';
import tw from 'twin.macro';

import timezoneRemove from 'timesheets/services/timezone-remove';

import { Contract, ContractType, RateFrequency } from '__generated__/graphql';

import {
  AdditionalPayFormValues,
  Occurrence,
  frequencyOptions,
} from '../../index';

const OtherPayFields: React.FC<{
  maxDate?: Date | string;
  minDate?: Date | string;
  contractType?: Contract['type'];
}> = ({ minDate, maxDate, contractType }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const {
    control,
    register,
    setValue,
    watch,
    trigger,
  } = useFormContext<AdditionalPayFormValues>();

  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  if (!isNotFreelancer) {
    return (
      <div tw="flex flex-col gap-base">
        <TextInput.Container tw="col-span-2">
          <TextInput.Label>
            {t('additional-pay.name.label.other', 'Title of Pay type')}
          </TextInput.Label>
          <TextInput data-testid="pay-name" {...register('name')} />
        </TextInput.Container>
        <TextInput.Container>
          <TextInput.Label htmlFor="amount">
            {t(
              'definition-phase.compensation.additional-pays.occurrence.label',
              'Occurrence',
            )}
          </TextInput.Label>
          <div tw="grid grid-cols-2 gap-x-base">
            <Radio
              value={Occurrence.RECURRING}
              labelStyles={tw`bg-background-white`}
              {...register('occurrence')}
              onChange={(e) => {
                register('occurrence').onChange(e);
                trigger(['frequency', 'occurrence']);
              }}
            >
              {t(
                'definition-phase.compensation.additional-pays.occurrence.recurring',
                'Recurring',
              )}
            </Radio>
            <Radio
              value={Occurrence.ONCE}
              labelStyles={tw`bg-background-white`}
              {...register('occurrence')}
              onChange={(e) => {
                register('occurrence').onChange(e);
                setValue('frequency', null);
                trigger(['frequency', 'occurrence']);
              }}
            >
              {t(
                'definition-phase.compensation.additional-pays.occurrence.one-time',
                'One Time',
              )}
            </Radio>
          </div>
        </TextInput.Container>
        <div
          css={[
            tw`grid gap-x-base`,
            watch('occurrence') === Occurrence.RECURRING && tw`grid-cols-2`,
          ]}
        >
          {watch('occurrence') === Occurrence.RECURRING && (
            <TextInput.Container>
              <TextInput.Label>
                {t('additional-pay.frequency.label', 'Frequency (Every-)')}
              </TextInput.Label>
              <TextInput
                data-testid="pay-frequency"
                type="number"
                placeholder="0"
                units={t('additional-pay.frequency.units', 'Months')}
                {...register('frequency')}
              />
            </TextInput.Container>
          )}
          <TextInput.Container>
            <TextInput.Label>
              {watch('occurrence') === Occurrence.RECURRING
                ? t(
                    'additional-pay.payout-month.initial-label',
                    'Starting From',
                  )
                : t(
                    'additional-pay.payout-month.label-other-once',
                    'Payout Month',
                  )}
            </TextInput.Label>
            <DatePicker
              monthSelector
              value={
                watch('payoutMonth.month') && watch('payoutMonth.year')
                  ? timezoneRemove(
                      `${watch('payoutMonth.year')}-${
                        watch('payoutMonth.month') + 1
                      }-1`,
                    )
                  : null
              }
              min={minDate}
              max={maxDate}
              onChange={(v) => {
                setValue('payoutMonth.month', timezoneRemove(v).getMonth(), {
                  shouldValidate: true,
                });
                setValue('payoutMonth.year', timezoneRemove(v).getFullYear(), {
                  shouldValidate: true,
                });
              }}
              placeholder={t(
                'additional-pay.payout-month.placeholder',
                'Month',
              )}
            />
          </TextInput.Container>
        </div>
      </div>
    );
  }

  return (
    <>
      <TextInput.Container tw="col-span-2">
        <TextInput.Label>
          {t('additional-pay.name.label.other', 'Title of Pay type')}
        </TextInput.Label>
        <TextInput data-testid="pay-name" {...register('name')} />
      </TextInput.Container>
      <div css={[tw`grid grid-cols-2 gap-base mobile:grid-cols-1`]}>
        <TextInput.Container>
          <TextInput.Label>
            {t('additional-pay.frequency.label-feature-on', 'Frequency')}
          </TextInput.Label>
          <Controller
            name="frequency"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                variant="default"
                data-testid="allowance-pay-frequency-selector"
                disabled={frequencyOptions?.length === 1}
                dropdownValues={frequencyOptions ?? []}
                value={(value as string) ?? ''}
                onChange={(v) => {
                  onChange(v);
                  if (v === RateFrequency.ONCE) {
                    setValue('occurrence', Occurrence.ONCE);
                  } else {
                    setValue('occurrence', Occurrence.RECURRING);
                  }
                }}
                placeholder={t('pay-frequency.placeholder', 'Select')}
              />
            )}
          />
        </TextInput.Container>
        <TextInput.Container>
          <TextInput.Label>
            {watch('occurrence') === Occurrence.RECURRING
              ? t(
                  'additional-pay.payout-month.effective-from',
                  'Effective from',
                )
              : t(
                  'additional-pay.payout-month.label-other-once',
                  'Payout Month',
                )}
          </TextInput.Label>
          <DatePicker
            monthSelector
            value={
              watch('payoutMonth.month') + 1 && watch('payoutMonth.year')
                ? timezoneRemove(
                    `${watch('payoutMonth.year')}-${
                      watch('payoutMonth.month') + 1
                    }-1`,
                    true,
                  )
                : null
            }
            min={minDate}
            max={maxDate}
            onChange={(v) => {
              setValue(
                'payoutMonth.month',
                timezoneRemove(v, true).getMonth(),
                {
                  shouldValidate: true,
                },
              );
              setValue(
                'payoutMonth.year',
                timezoneRemove(v, true).getFullYear(),
                {
                  shouldValidate: true,
                },
              );
            }}
            placeholder={t('additional-pay.payout-month.placeholder', 'Month')}
            rightAlign
          />
        </TextInput.Container>
      </div>
    </>
  );
};

export default OtherPayFields;
