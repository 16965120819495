import { gql } from '@apollo/client';

export default gql`
  query GetApprover($id: ID) {
    company {
      id
      approvers(userId: $id) {
        id
        categoryContracts {
          category
          managedContracts {
            id
            position
            country
            member {
              id
              firstName
              lastName
              emails {
                email
              }
            }
            # orgAttributes {
            #   department {
            #     name
            #   }
            # }
          }
        }
        approverUser {
          ...companyUser
          ... on Member {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on OperationsUser {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on EmergencyPointOfContact {
            id
            firstName
            lastName
            emails {
              email
            }
          }
        }
      }
    }
  }
  fragment companyUser on CompanyUser {
    id
    userId
    firstName
    lastName
    emails {
      email
    }
    title
    role
    roles
    isSignatory
    isBillingContact
    status
  }
`;
