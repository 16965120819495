/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import {
  Controller,
  FieldError,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import 'twin.macro';

import { ApolloError } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { StatelessFileUpload } from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import { TFunction } from 'i18next';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';

import AppFeature from 'app/features';
import i18nextUtil from 'app/utils/i18next-util';
import TextInput from 'common/components/text-input';
import swiftCodesWithAdditionalCharge from 'common/constants/swift-codes-with-additional-charge';
import {
  BankAccountType,
  SwiftFeeInfo,
  useGetSwiftFeeField,
} from 'contract-onboarding/components/additional-swift-fee-info';
import ErrorDetails, {
  setChangeErrorDetails,
} from 'contract-onboarding/components/bank-details-section/components/error-details';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import OnboardingSpecialistCard from 'contract-onboarding/components/onboarding-specialist-card';
import StepNavigationFooter from 'contract-onboarding/components/step-navigation-footer';
import { useUpdateBankDetails } from 'contract-onboarding/member/hooks';
import {
  getBankStatementsForUpload,
  getDynamicFields,
} from 'contract-onboarding/member/services/bank-data';
import { OnboardingStepProps } from 'contract-onboarding/member/view';
import { getBankStatementsSchema } from 'contract-onboarding/services/bank-details-schema';

import {
  BankAccountDetail,
  BankAccountInput,
  Contract,
  ContractType,
  CountryCode,
  CurrencyCode,
  Maybe,
  PaymentAccountRequirement,
  RequirementField,
  useGetMemberLazyQuery,
} from '__generated__/graphql';

import stepConfig from '../../step-config';
import DynamicDetailsForm from './components/dynamic-details-form';
import StaticDetailsForm from './components/static-details-form';

export type BankAccountFormValues = {
  accountName?: string;
  accountNumber?: string;
  bankName?: string;
  branchName?: string;
  localBankCode?: string;
  swiftCode?: string;
};

export type BankDetailRequirementField = {
  key?: Maybe<string>;
  value?: Maybe<string>;
  type?: Maybe<string>;
};

export interface BankDetailsFormParams {
  bankData?: BankDetailRequirementField[];
  accountName?: string;
  accountNumber?: string;
  bankName?: string;
  branchName?: string;
  localBankCode?: string;
  swiftCode?: string;
  paymentAccountRequirementType?: string;
  documentsProof?: File[];
  bankStatements?: {
    statement: File;
  }[];
}

export interface UpdateBankDetailsFormParams {
  bankData?: BankDetailRequirementField[];
  accountName?: string;
  accountNumber?: string;
  bankName?: string;
  branchName?: string;
  localBankCode?: string;
  swiftCode?: string;
  paymentAccountRequirementType?: string;
  documentsProof?: File[];
  bankStatements?: File[];
}

export interface UpdateBankDetailsParams extends UpdateBankDetailsFormParams {
  bankStatementDocIds?: BankAccountInput['bankStatementDocIds'];
  // NOTE: This won't be used for update; just for comparing change request
  version?: number;
}

export const BANK_STATEMENT_MAX_SIZE = 5 * 1024 * 1024;

export const accountRequirementTypeLabel = ({
  targetCurrency,
  requirementType,
  t,
}: {
  targetCurrency: Contract['currency'];
  requirementType: string;
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>;
}): string => {
  const requirementLabel: Record<string, Record<string, string>> = {
    [CurrencyCode.USD]: {
      ACH: t(
        'bank-account.requirement-type.label.USD-ACH',
        'Bank account inside US (ACH)',
      ),
      Wire: t(
        'bank-account.requirement-type.label.USD-Wire',
        'Bank account inside US (Wire)',
      ),
      SWIFT: t(
        'bank-account.requirement-type.label.USD-SWIFT',
        'Bank account outside US',
      ),
    },
    [CurrencyCode.EUR]: {
      'Inside Europe': t(
        'bank-account.requirement-type.label.EUR-IBAN',
        'Bank account within EUROPE',
      ),
      'Outside Europe': t(
        'bank-account.requirement-type.label.EUR-SWIFT_CODE',
        'Bank account outside EUROPE',
      ),
    },
    [CurrencyCode.GBP]: {
      IBAN: t(
        'bank-account.requirement-type.label.GBP-IBAN',
        'Bank account outside UK',
      ),
      'Local bank account': t(
        'bank-account.requirement-type.label.GBP-SORT_CODE',
        'UK Bank account',
      ),
    },
  };

  if (targetCurrency && requirementType)
    return (
      requirementLabel?.[targetCurrency]?.[requirementType] ?? requirementType
    );
  return requirementType;
};

export const localBankCodeMap: {
  [key: string]: {
    label: (
      t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
    ) => string;
    pattern: (value: string) => boolean;
    patternError: (
      t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
    ) => string;
  };
} = {
  [CountryCode.IND]: {
    label: (t) => t('bank-details.local-bank-code.ind.label', 'IFSC Code'),
    pattern: (value) => /^[A-Z]{4}0[A-Z0-9]{6}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.ind.pattern-error',
        'IFSC Code must contain 11 alphanumeric characters without space or dash',
      ),
  },
  [CountryCode.CAN]: {
    label: (t) =>
      t('bank-details.local-bank-code.can.label', 'Bank Routing Number'),
    pattern: (value) => /^\d{5}-?\d{3}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.can.pattern-error',
        'Bank Routing Number must have a 5 digit branch number and 3 digit financial instruction number',
      ),
  },
  [CountryCode.USA]: {
    label: (t) =>
      t('bank-details.local-bank-code.usa.label', 'ABA Routing Transit Number'),
    pattern: (value) => /^\d{9}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.usa.pattern-error',
        'ABA Routing Transit Number must have 9 digits',
      ),
  },
  [CountryCode.MEX]: {
    label: (t) =>
      t('bank-details.local-bank-code.mex.label', 'CLABE Bank Code'),
    pattern: (value) => /^\d{18}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.mex.pattern-error',
        'Bank Code must have 18 digits',
      ),
  },
  [CountryCode.CHE]: {
    label: (t) =>
      t('bank-details.local-bank-code.che.label', 'Bank Clearing Number'),
    pattern: (value) => /^\d{3,5}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.che.pattern-error',
        'Bank Clearing Number must be between 3 to 5 digits',
      ),
  },
  [CountryCode.LIE]: {
    label: (t) =>
      t('bank-details.local-bank-code.lie.label', 'Bank Clearing Number'),
    pattern: (value) => /^\d{3,5}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.lie.pattern-error',
        'Bank Clearing Number must be between 3 to 5 digits',
      ),
  },
  [CountryCode.DEU]: {
    label: (t) =>
      t('bank-details.local-bank-code.deu.label', 'Bank Routing Number'),
    pattern: (value) => /^\d{8}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.deu.pattern-error',
        'Bank Routing Number must have 8 digits',
      ),
  },
  [CountryCode.AUT]: {
    label: (t) =>
      t('bank-details.local-bank-code.aut.label', 'Bank Routing Number'),
    pattern: (value) => /^\d{5}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.aut.pattern-error',
        'Bank Routing Number must have 5 digits',
      ),
  },
  [CountryCode.GBR]: {
    label: (t) => t('bank-details.local-bank-code.gbr.label', 'Sort Code'),
    pattern: (value) => /^\d{6}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.gbr.pattern-error',
        'Sort Code must have 6 digits',
      ),
  },
  [CountryCode.IRL]: {
    label: (t) => t('bank-details.local-bank-code.irl.label', 'Sort Code'),
    pattern: (value) => /^\d{6}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.irl.pattern-error',
        'Sort Code must have 6 digits',
      ),
  },
  [CountryCode.NZL]: {
    label: (t) =>
      t(
        'bank-details.local-bank-code.nzl.label',
        'NCC (National Clearing Code)',
      ),
    pattern: (value) => /^\d{2}-?\d{4}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.nzl.pattern-error',
        'NCC must have 6 digits',
      ),
  },
  [CountryCode.AUS]: {
    label: (t) =>
      t('bank-details.local-bank-code.aus.label', 'Bank State Branch'),
    pattern: (value) => /^\d{3}-?\d{3}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.aus.pattern-error',
        'Bank State Branch must have 6 digits',
      ),
  },
  [CountryCode.HKG]: {
    label: (t) => t('bank-details.local-bank-code.hkg.label', 'HK Bank Code'),
    pattern: (value) => /^\d{3}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.hkg.pattern-error',
        'HK Bank Code must have 3 digits',
      ),
  },
  [CountryCode.LKA]: {
    label: (t) =>
      t('bank-details.local-bank-code.lka.label', 'Local Bank Code'),
    pattern: (value) => /[^\n]+$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.lka.pattern-error',
        'Local bank code is required',
      ),
  },
  [CountryCode.BGD]: {
    label: (t) =>
      t('bank-details.local-bank-code.bgd.label', 'Bank Routing Number'),
    pattern: (value) => /^\d{9}$/.test(value),
    patternError: (t) =>
      t(
        'bank-details.local-bank-code.bgd.pattern-error',
        'Bank routing number must have 9 digits',
      ),
  },
};

export const filterRequirementsField = (
  field: Maybe<RequirementField>,
): boolean => field?.key !== 'legalType' && !field?.key?.includes('address');

const BankDetailsForm: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const isForcedV1Account = useFeature(AppFeature.SHOW_UPDATE_BANK_DETAILS_V1)
    ?.on;

  const [errorDetails, setErrorDetails] = useState<
    | {
        errorMessages: string[];
        errorCount: number;
      }
    | undefined
  >();

  const { loading, updateDetails } = useUpdateBankDetails(
    undefined,
    (e: ApolloError) => setChangeErrorDetails(e, setErrorDetails),
    true,
  );

  const [
    getMember,
    { data: { member } = { member: {} }, loading: loadingMember },
  ] = useGetMemberLazyQuery();
  const contract = member?.contracts?.[0];
  const paymentAccountRequirements = contract?.paymentAccountRequirements;
  const currentPaymentAccountRequirementType =
    member?.bankAccounts?.[0]?.paymentAccountRequirementType;

  const sorOnboardingContext = useSorOnboardingContext({
    type: contract?.type,
    companyId: contract?.company?.id,
    legalEntityId: contract?.legalEntityId,
  });

  const paymentAccountRequirementsMap = useMemo(
    () =>
      new Map<string, PaymentAccountRequirement>(
        paymentAccountRequirements?.map((req) => [
          req?.paymentAccountRequirementType as string,
          req as PaymentAccountRequirement,
        ]),
      ),
    [paymentAccountRequirements],
  );

  const formSubmitHandler = (data: BankDetailsFormParams) => {
    const modifyBankStatements = data.bankStatements?.map(
      (bankStatement) => bankStatement.statement,
    );

    const modifiedData = {
      ...data,
      bankStatements: modifyBankStatements,
    };

    const { existingDocIds, newDocs } = getBankStatementsForUpload(
      modifiedData.bankStatements,
    );

    const payload = {
      ...modifiedData,
      bankStatements: newDocs,
      bankStatementDocIds: existingDocIds,
    };

    if (!isForcedV1Account && paymentAccountRequirementsMap?.size) {
      let omitIndexFromBankData;
      if (payload?.bankData) {
        omitIndexFromBankData = payload?.bankData
          .map((obj) => omit(obj, ['index']))
          .filter((obj) => !isNil(obj.value));
      }

      const values = {
        ...payload,
        bankData: omitIndexFromBankData,
      };

      const selectedAccountRequirement = paymentAccountRequirementsMap.get(
        data?.paymentAccountRequirementType ?? '',
      );

      updateDetails(true, values, {
        accountType: selectedAccountRequirement?.accountType,
        sourceCurrency: selectedAccountRequirement?.sourceCurrency,
        targetCurrency: selectedAccountRequirement?.targetCurrency,
        transferType: selectedAccountRequirement?.transferType,
        paymentPartner: selectedAccountRequirement?.paymentPartner,
        paymentAccountRequirementType:
          selectedAccountRequirement?.paymentAccountRequirementType,
      });
    } else {
      updateDetails(false, payload, {});
    }
    setErrorDetails(undefined);
  };

  useEffect(() => {
    getMember();
  }, []);

  const methods = useForm<BankDetailsFormParams>({
    mode: 'onChange',
  });

  const {
    control,
    reset,
    formState: { isValid, errors },
    handleSubmit,
    trigger,
    watch,
    setValue,
  } = methods;

  const paymentAccountRequirementType = useWatch({
    control,
    name: 'paymentAccountRequirementType',
  });

  const { append, replace } = useFieldArray({
    control,
    name: 'bankStatements',
  });

  const bankDetailObject = useMemo(
    () =>
      (member?.bankAccounts?.[0]?.accountDetails ?? []).reduce(
        (obj, accountDetail: Maybe<BankAccountDetail>) => {
          obj[accountDetail?.key ?? ''] = accountDetail?.value ?? '';
          return obj;
        },
        {} as Record<string, string>,
      ) ?? {},
    [member],
  );

  useDeepCompareEffect(() => {
    if (!isForcedV1Account && paymentAccountRequirementsMap?.size) {
      const bankAccountDetails = member?.bankAccounts?.[0];
      const bankStatements =
        (bankAccountDetails?.bankStatements as File[]) ?? undefined;

      const modifyBankStatements = bankStatements?.map((statement) => ({
        statement,
      }));

      reset({
        paymentAccountRequirementType,
        bankData: Array.from(
          getDynamicFields(
            paymentAccountRequirementsMap.get(
              paymentAccountRequirementType ?? '',
            )?.requirementFields,
          )?.values() ?? [],
        ).map((val: Maybe<BankDetailRequirementField>, index: number) => ({
          key: val?.key,
          value: val?.key ? bankDetailObject[val?.key] : null,
          index,
        })),
        bankStatements: modifyBankStatements,
      });
    } else if (member?.__typename === 'Member' && member.bankAccounts?.[0]) {
      const bankAccountDetails = member.bankAccounts[0];
      const bankStatements =
        (bankAccountDetails.bankStatements as File[]) ?? undefined;

      const modifyBankStatements = bankStatements?.map((statement) => ({
        statement,
      }));

      reset({
        accountName: bankAccountDetails.accountName ?? '',
        accountNumber: bankAccountDetails.accountNumber ?? '',
        bankName: bankAccountDetails.bankName ?? '',
        branchName: bankAccountDetails.branchName ?? '',
        localBankCode: bankAccountDetails.localBankCode ?? '',
        swiftCode: bankAccountDetails.swiftCode ?? '',
        bankStatements: modifyBankStatements,
      });
    }
  }, [contract, member, paymentAccountRequirementType, bankDetailObject]);

  const allBankStatements = useWatch({
    control,
    name: 'bankStatements',
  });

  const handleRemoveFile = (file: File) => {
    if (allBankStatements?.length) {
      const filteredFiles = allBankStatements.filter(
        (statementData: { statement: File }) =>
          !(
            statementData.statement.name === file.name &&
            statementData.statement.size === file.size &&
            statementData.statement.lastModified === file.lastModified
          ),
      );
      replace(filteredFiles);
      trigger();
    }
  };

  useEffect(() => {
    if (
      currentPaymentAccountRequirementType &&
      paymentAccountRequirementsMap?.has(currentPaymentAccountRequirementType)
    ) {
      setValue(
        'paymentAccountRequirementType',
        currentPaymentAccountRequirementType,
      );
    } else if (paymentAccountRequirementsMap?.size === 1) {
      setValue(
        'paymentAccountRequirementType',
        Array.from(paymentAccountRequirementsMap.keys())[0],
      );
    }
  }, [paymentAccountRequirementsMap, currentPaymentAccountRequirementType]);

  const swiftFeeField = useGetSwiftFeeField(
    !isForcedV1Account && contract?.paymentAccountRequirements?.length
      ? BankAccountType.DYNAMIC
      : BankAccountType.STATIC,
    paymentAccountRequirementsMap,
    paymentAccountRequirementType,
  );

  const shouldShowSwiftFeeInfo =
    contract?.type === ContractType.FREELANCER ||
    (contract?.type === ContractType.CONTRACTOR &&
      !!swiftFeeField &&
      swiftCodesWithAdditionalCharge.includes(
        watch(swiftFeeField as FieldPath<BankDetailsFormParams>) as string,
      ));

  const showOnboardingSpecialist = useFeature(
    AppFeature.SHOW_ONBOARDING_SPECIALIST,
  )?.on;

  return (
    <div>
      {showOnboardingSpecialist &&
        !sorOnboardingContext.isSorOnboardingEnabled && (
          <div tw="mb-base">
            <OnboardingSpecialistCard
              contractId={contract?.id || ''}
              showHint={false}
              showTitleOnly={false}
            />
          </div>
        )}
      <StepLayout data-testid="bank-details-view">
        <FormLayout onSubmit={handleSubmit((data) => formSubmitHandler(data))}>
          <FormProvider {...methods}>
            <FormCard>
              {!!errorDetails && (
                <ErrorDetails
                  errorCount={errorDetails?.errorCount}
                  errorMessages={errorDetails?.errorMessages}
                />
              )}
              <div tw="grid gap-x-base gap-y-large">
                {!!paymentAccountRequirementsMap.size && !isForcedV1Account ? (
                  <DynamicDetailsForm
                    paymentAccountRequirementsMap={
                      paymentAccountRequirementsMap
                    }
                    currency={contract?.currency}
                    bankDetailObject={bankDetailObject}
                    contractType={contract?.type}
                    selectedAccountRequirementType={
                      paymentAccountRequirementType
                    }
                  />
                ) : (
                  <StaticDetailsForm
                    country={contract?.country}
                    contractType={contract?.type}
                  />
                )}
                {!isUndefined(contract?.type) && (
                  <TextInput.Container>
                    <TextInput.Label>
                      {t(
                        i18nextUtil.buildTransKeys(
                          'note',
                          'bank-details.proof-documents',
                          i18nextUtil.asSegment(contract?.country),
                        ),
                        'Attach Bank Statement / Cancelled Cheque',
                        { ns: 'contract-onboarding.common' },
                      )}
                    </TextInput.Label>
                    <TextInput.Helper>
                      {t(
                        'bank-details.proof-document.helper',
                        "The bank statement and/or cheque must clearly display the account holder's name and account number. We collect this information to verify the bank account number and ensure that payments are made to the correct account.",
                      )}
                    </TextInput.Helper>
                    <Controller
                      name="bankStatements"
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (
                            !value?.length &&
                            contract?.type === ContractType.HR_MEMBER
                          ) {
                            return true;
                          }
                          return getBankStatementsSchema(t, contract?.country)
                            ?.validate(value)
                            .then(() => true)
                            .catch((e) => e?.message);
                        },
                      }}
                      render={({ field: { value } }) => (
                        <StatelessFileUpload
                          minimal
                          multiple
                          maxSize={BANK_STATEMENT_MAX_SIZE}
                          maxTotalSize={BANK_STATEMENT_MAX_SIZE}
                          files={value?.map(
                            (bankStatement) => bankStatement.statement,
                          )}
                          description={t(
                            'bank-details.bank-statement-cancelled-checque.field-description',
                            'Files Supported: PDF, PNG, JPG (Max 5mb)',
                          )}
                          data-testid="bank-statement"
                          onFileDrop={(files: File[]) => {
                            files.forEach((file) =>
                              append({ statement: file }),
                            );
                            trigger();
                          }}
                          onRemoveFile={handleRemoveFile}
                        />
                      )}
                    />
                    {errors?.bankStatements && (
                      <TextInput.Error>
                        {
                          ((errors?.bankStatements as unknown) as FieldError)
                            ?.root?.message
                        }
                      </TextInput.Error>
                    )}
                  </TextInput.Container>
                )}
              </div>
              {shouldShowSwiftFeeInfo && <SwiftFeeInfo />}
            </FormCard>
          </FormProvider>
          <StepNavigationFooter
            disabled={!isValid}
            submitLoading={loading || loadingMember}
            onboardingSteps={onboardingSteps}
            currentStep={currentStep}
            stepConfig={stepConfig}
          />
        </FormLayout>
      </StepLayout>
    </div>
  );
};

export default BankDetailsForm;
