/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ComboBox from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import { genderLabel } from 'common/constants/default-labels';

import { CountryCode, Gender } from '__generated__/graphql';

import { BasicDetailsFormValues } from '../basic-details';

const GenderSelector: React.FC<{
  country: CountryCode | undefined | null;
}> = ({ country }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    control,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  const genderValues = React.useMemo(() => {
    if (country === CountryCode.ITA) {
      return [
        { title: genderLabel[Gender.MALE], value: Gender.MALE },
        {
          title: genderLabel[Gender.FEMALE],
          value: Gender.FEMALE,
        },
      ];
    }

    return [
      { title: genderLabel[Gender.MALE], value: Gender.MALE },
      {
        title: genderLabel[Gender.FEMALE],
        value: Gender.FEMALE,
      },
      {
        title: genderLabel[Gender.UNSPECIFIED],
        value: Gender.UNSPECIFIED,
      },
    ];
  }, [country]);

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="gender">
        {t('definition-phase.basic-details.gender.label', 'Gender')}
      </TextInput.Label>
      <Controller
        name="gender"
        control={control}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            id="gender"
            data-testid="select-gender"
            variant="default"
            dropdownValues={genderValues}
            showArrow
            value={value || ''}
            placeholder={t(
              'definition-phase.basic-details.gender.placeholder',
              'Select',
            )}
            onChange={onChange}
            error={!!errors.gender}
          />
        )}
      />
      {errors.gender && (
        <TextInput.Error>{errors.gender.message}</TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default GenderSelector;
