import { useTranslation } from 'react-i18next';

import { OnboardingTask } from '../../__generated__/graphql';

const useTaskAssignee = (
  firstTaskFromGroup: OnboardingTask | undefined,
): string => {
  const { t } = useTranslation('contract-onboarding');

  if (firstTaskFromGroup == null) return '';

  if (firstTaskFromGroup.pendingOn?.firstName === 'BILLING_ADMIN')
    return t('onboarding-checklist.billing-admin', 'billing admin');

  if (firstTaskFromGroup.pendingOn?.firstName === 'COMPANY_SIGNATORY') {
    return t('onboarding-checklist.company-signatory', 'company signatory');
  }

  return `${firstTaskFromGroup.pendingOn?.firstName} ${firstTaskFromGroup.pendingOn?.lastName}`;
};

export default useTaskAssignee;
