import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const visaDocumentsVerified: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.visa-documents-verified.title', {
      defaultValue: 'We are verifying the documents',
      replace: {
        name,
      },
    }),
  description: () => '',
  statusLabel: (t) =>
    t(
      'onboarding-phase.visa-documents-verified.label',
      'Member documents verified',
    ),
  pendingOn: () => PendingOn.YOU,
  pendingLabel: (t): string =>
    t('onboarding-phase.visa-documents-verified.status', 'Pending on You'),
};

export default visaDocumentsVerified;
