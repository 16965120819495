/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@multiplier/common';

import getAccountNumberFieldLabel from 'contract-onboarding/member/services/account-number-field-label';
import {
  getBankCodeSchema,
  getSwiftCodeSchema,
} from 'contract-onboarding/services/bank-details-schema';

import { Contract, CountryCode } from '__generated__/graphql';

import { BankDetailsFormParams, localBankCodeMap } from '../../index';

import 'twin.macro';

const StaticDetailsForm: React.FC<{
  country: Contract['country'];
  contractType: Contract['type'];
}> = ({ country, contractType }) => {
  const { t } = useTranslation('contract-onboarding.member');
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext<BankDetailsFormParams>();

  useEffect(
    () => () => {
      unregister(
        [
          'accountName',
          'accountNumber',
          'bankName',
          'branchName',
          'localBankCode',
          'swiftCode',
        ],
        { keepError: false },
      );
    },
    [],
  );

  return (
    <>
      <TextInput.Container>
        <TextInput.Label htmlFor="account-holder-name">
          {t('bank-details.account-holder-name.label', 'Account Holder Name')}
        </TextInput.Label>
        <TextInput
          id="account-holder-name"
          placeholder={t(
            'bank-details.holder-name.placeholder',
            'John David Markus',
          )}
          {...register('accountName', {
            required: t(
              'bank-account-schema.text.error-message.required',
              'Input is required',
            ),
          })}
        />
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label htmlFor="account-number">
          {getAccountNumberFieldLabel(t, country)}
        </TextInput.Label>
        <TextInput.Helper>
          {t(
            'bank-details.account-number.description',
            'Please ensure you provide the correct account number to ensure you receive the salary to the right bank account',
          )}
        </TextInput.Helper>
        <TextInput
          id="account-number"
          placeholder={t('bank-details.account-number.placeholder', '123456')}
          {...register('accountNumber', {
            required: t(
              'bank-account-schema.text.error-message.required',
              'Input is required',
            ),
          })}
        />
      </TextInput.Container>
      <div tw="flex flex-row mobile:flex-col">
        <div tw="flex-grow desktop:pr-large tablet:pr-large">
          <TextInput.Container>
            <TextInput.Label htmlFor="bank-name">
              {t('bank-details.bank-name.label', 'Bank Name')}
            </TextInput.Label>
            <TextInput
              id="bank-name"
              placeholder={t(
                'bank-details.bank-name.placeholder',
                'Achme International Bank',
              )}
              {...register('bankName', {
                required: t(
                  'bank-account-schema.text.error-message.required',
                  'Input is required',
                ),
              })}
            />
          </TextInput.Container>
        </div>
        <div tw="flex-grow mobile:mt-large">
          <TextInput.Container>
            <TextInput.Label htmlFor="branch-name">
              {t('bank-details.branch-name.label', 'Branch Name')}
            </TextInput.Label>
            <TextInput
              id="branch-name"
              placeholder={t(
                'bank-details.branch-name.placeholder',
                'City Center Branch',
              )}
              {...register('branchName', {
                required: t(
                  'bank-account-schema.text.error-message.required',
                  'Input is required',
                ),
              })}
            />
          </TextInput.Container>
        </div>
      </div>
      <TextInput.Container>
        <TextInput.Label htmlFor="localBankCode">
          {country && !!localBankCodeMap?.[country]
            ? localBankCodeMap[country].label(t)
            : t(
                'bank-details.local-bank-code.default.label',
                'Local Bank Code',
              )}
        </TextInput.Label>
        <TextInput
          id="localBankCode"
          placeholder={t(
            'bank-details.local-bank-code.default.placeholder',
            '123456',
          )}
          {...register('localBankCode', {
            validate: (value) =>
              getBankCodeSchema(t, country)
                .validate(value)
                .then(() => true)
                .catch((e) => e.message),
          })}
          error={!!errors.localBankCode}
        />
        {errors.localBankCode && (
          <TextInput.Error>{errors.localBankCode.message}</TextInput.Error>
        )}
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label htmlFor="swift-code">
          {country === CountryCode.IND
            ? t(
                'bank-details.swift-code-optional.label',
                'SWIFT Code (Optional)',
              )
            : t('bank-details.swift-code.label', 'SWIFT Code')}
        </TextInput.Label>
        <TextInput
          id="swift-code"
          placeholder={t('bank-details.swift-code.placeholder', '123456')}
          {...register('swiftCode', {
            validate: (value) =>
              getSwiftCodeSchema(t, country, contractType)
                .validate(value)
                .then(() => true)
                .catch((e) => e.message),
          })}
          error={!!errors.swiftCode}
        />
        {errors.swiftCode && (
          <TextInput.Error>{errors.swiftCode.message}</TextInput.Error>
        )}
      </TextInput.Container>
    </>
  );
};

export default StaticDetailsForm;
