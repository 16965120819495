/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import i18n from 'i18n';

import {
  ContractOnboardingStep,
  Maybe,
  useGetMemberQuery,
} from '__generated__/graphql';

import config from './config';
import MemberOnboarding from './pages/onboarding';
import BlockPage from './pages/onboarding/BlockPage';
import VisaDocuments from './pages/onboarding/pages/visa-eligibility-documents';
import onboardingStepConfig from './pages/onboarding/step-config';
import MemberStart from './pages/start';
import MemberStartVisa from './pages/startVisa';
import MemberVerification from './pages/verification';
import routes from './routes';

export interface OnboardingStepProps {
  currentStep?: ContractOnboardingStep;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
}
if ((config?.preloadNs?.length || 0) > 0) {
  // onboarding don't use useModules
  i18n?.loadNamespaces?.(config?.preloadNs as string[]);
}
const ContractMemberOnboardingView: React.FC = () => {
  const navigate = useNavigate();
  const { data: { member } = { member: {} } } = useGetMemberQuery();
  const contract = useMemo(() => member?.contracts?.[0], [member]);

  useDeepCompareEffect(() => {
    if (!contract?.onboarding?.current) return;
    if (onboardingStepConfig[contract.onboarding.current]) {
      navigate(
        `${onboardingStepConfig[contract?.onboarding?.current].relativeLink()}`,
        { replace: true },
      );
    }
  }, [contract ?? {}]);

  return (
    <div tw="bg-bg01" data-testid="member-onboarding-view">
      <Routes>
        <Route path={`${routes.onboarding}/*`} element={<MemberOnboarding />} />
        <Route
          path={`${routes.verification}`}
          element={<MemberVerification />}
        />
        <Route
          path={`${routes.visa.root}/${routes.visa.visaEligibility}`}
          element={<VisaDocuments />}
        />
        <Route path={`${routes.visa.blockPage}/*`} element={<BlockPage />} />
        <Route path={`${routes.visa.root}/*`} element={<MemberStartVisa />} />
        <Route path="*" element={<MemberStart />} />
      </Routes>
    </div>
  );
};

export default ContractMemberOnboardingView;
