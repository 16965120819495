/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';

import ComboBox, { DropdownValue } from 'common/components/combo-box';
import TextInput from 'common/components/text-input';

import { TimeOffTypeDefinition } from '__generated__/graphql';

import { periodLabels } from '../../compliance-entry';
import {
  LeaveEntitlementFormEntryValue,
  LeaveEntitlementFormValues,
} from '../index';

const CustomLeaveEntry: React.FC<{
  index: number;
  field: FieldArrayWithId<LeaveEntitlementFormValues, 'custom'>;
  availableCustomEntitlementOptions: DropdownValue[];
  remove: (index?: number | number[] | undefined) => void;
  timeOffTypeToDefinitionMap: { [key: string]: TimeOffTypeDefinition };
}> = ({
  index,
  field,
  availableCustomEntitlementOptions,
  remove,
  timeOffTypeToDefinitionMap,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<LeaveEntitlementFormValues>();

  const error = errors.custom?.[index];

  const maximum = useMemo(
    () =>
      timeOffTypeToDefinitionMap[
        watch(`custom.${index}.key`)
      ]?.validation?.find((v) => v?.unit === field.unit)?.maximum,
    [timeOffTypeToDefinitionMap, watch(`custom.${index}.key`)],
  );

  return (
    <>
      <Controller
        control={control}
        name={`custom.${index}.key`}
        defaultValue={field.key}
        rules={{
          validate: {
            unique: (value) =>
              watch('custom')?.filter(
                (customField: LeaveEntitlementFormEntryValue) =>
                  customField.key === value,
              ).length === 1 ||
              (t(
                'compliance.leave-entitlement.custom-unique',
                'Custom leave types must be unique',
              ) as string),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            variant="default"
            value={value ?? ''}
            placeholder={t(
              'compliance.leave-entitlement.leave-type-placeholder',
              'Select Leave Type',
            )}
            dropdownValues={availableCustomEntitlementOptions}
            onChange={onChange}
            error={!!error?.key}
          />
        )}
      />
      <Controller
        control={control}
        name={`custom.${index}.value`}
        defaultValue={field.value ?? 0}
        rules={{
          max: {
            value: maximum ?? Number.MAX_SAFE_INTEGER - field.defaultValue,
            message: t('compliance.leave-entitlement.value.max-error', {
              defaultValue: 'Total value should be below {{value}}',
              replace: {
                value: maximum,
              },
            }),
          },
          min: {
            value: 0,
            message: t(
              'compliance.leave-entitlement.custom.min-error',
              'Cannot have negative custom leave entitlements',
            ),
          },
          validate: {
            rounded: (value) =>
              (value && value % 0.5 === 0) ||
              (t(
                'compliance.leave-entitlement.custom.rounded',
                'Only half (0.5) values accepted',
              ) as string),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <TextInput
            data-testid="custom-leave-input"
            units={field.unit && periodLabels[field.unit](2)} // force plural
            error={!!error?.value}
            value={value ?? ''}
            onChange={onChange}
            type="number"
            step="0.1"
          />
        )}
      />
      <Button
        variant="secondary"
        size="medium"
        disabled={watch(`custom.${index}.key`) === ''}
        onClick={() => remove(index)}
      >
        {t('compliance.leave-entitlement.remove', 'Remove')}
      </Button>
      {error?.key && (
        <TextInput.Error tw="col-span-3 mt-none">
          {error.key.message}
        </TextInput.Error>
      )}
      {error?.value && (
        <TextInput.Error tw="col-span-3 mt-none">
          {error.value.message}
        </TextInput.Error>
      )}
    </>
  );
};

export default CustomLeaveEntry;
