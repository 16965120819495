/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@multiplier/common';
import { useGetLatestValidBirthdate } from '@multiplier/contract-onboarding';

import TextInput from 'common/components/text-input';

import {
  Contract,
  ContractType,
  DataFieldDefinition,
} from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '..';

interface DOBInputProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
  requirement?: DataFieldDefinition;
  contract?: Contract;
}

const DOBInput: React.FC<DOBInputProps> = ({
  methods,
  requirement,
  contract,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    formState: { errors, defaultValues },
  } = methods;

  const latestValidBirthdate = useGetLatestValidBirthdate();

  return (
    <TextInput.Container>
      <TextInput.Label>
        {t('member.basic-details.date-of-birth.label', 'Date of Birth')}
      </TextInput.Label>
      <Controller
        control={control}
        name="dateOfBirth"
        render={({ field: { value, onChange } }) => (
          <DatePicker
            data-testid="date-of-birth"
            value={value as never}
            onChange={onChange}
            disabledInput
            disabled={
              requirement?.editable === false && !!defaultValues?.dateOfBirth
            }
            error={!!errors.dateOfBirth?.message}
            helperText={errors.dateOfBirth?.message}
            max={
              contract?.type !== ContractType.FREELANCER
                ? latestValidBirthdate
                : undefined
            }
          />
        )}
      />
    </TextInput.Container>
  );
};

export default DOBInput;
