import { isBefore } from 'date-fns';

import {
  GET_COMPANY_CSM_AND_STATUS,
  GET_MEMBER_COMPLIANCE_TYPE,
  GET_MEMBER_CONTRACT_STATUS,
} from 'app/queries';
import client from 'client';
import {
  GET_COUNTRY_WITH_LEGAL_ENTITY,
  GET_MEMBER_WITH_LEGAL_ENTITY,
} from 'contact-us/member/queries';
import { GET_MEMBER_CONTRACT_TYPE } from 'login/queries';
import * as jwtService from 'login/services/jwt';
import { GET_COMPANY_OFFERINGS } from 'organization-settings/queries';
import { GET_COMPANY_USERS_ROLE } from 'team/company/queries';

import {
  CompanyStatus,
  CompanyUserRole,
  ContractStatus,
  ContractTerm,
  ContractType,
  GetCompanyOfferingsQuery,
  GetCompanyStatusAndCsmQuery,
  GetCompanyUsersRoleQuery,
  GetCountryWithLegalEntityQuery,
  GetMemberComplianceTypeQuery,
  GetMemberContractStatusQuery,
  GetMemberContractTypeQuery,
  GetMemberWithLegalEntityQuery,
  LegalBindingType,
  OfferingCode,
} from '__generated__/graphql';

export const hideModuleBasedOnMemberLegalEntity = async (): Promise<boolean> => {
  const legalEntityResponse = await client.query<GetMemberWithLegalEntityQuery>(
    {
      query: GET_MEMBER_WITH_LEGAL_ENTITY,
    },
  );

  const legalEntity =
    legalEntityResponse.data.member?.contracts?.[0]?.compliance?.legalEntity;
  const country = legalEntityResponse.data.member?.contracts?.[0]?.country;

  if (!legalEntity && country) {
    const legalEntityByCountryResponse = await client.query<GetCountryWithLegalEntityQuery>(
      {
        query: GET_COUNTRY_WITH_LEGAL_ENTITY,
        variables: {
          country,
        },
      },
    );

    return !legalEntityByCountryResponse.data.country?.compliance?.legalEntity
      ?.legalEntity;
  }

  return !legalEntity;
};

export const hideModuleBasedOnFixedTermEndDate = async (): Promise<boolean> => {
  const response = await client.query<GetMemberContractTypeQuery>({
    query: GET_MEMBER_CONTRACT_TYPE,
  });

  const contract = response.data.member?.contracts?.[0];
  const today = new Date();
  const endOnDate = new Date(contract?.endOn);

  return (
    !(
      contract?.term === ContractTerm.PERMANENT ||
      (contract?.term === ContractTerm.FIXED && isBefore(today, endOnDate))
    ) || contract?.type !== ContractType.EMPLOYEE
  );
};

export const hideModuleBasedOnComplianceType = async (): Promise<boolean> => {
  const response = await client.query<GetMemberComplianceTypeQuery>({
    query: GET_MEMBER_COMPLIANCE_TYPE,
  });

  const contract = response.data.member?.contracts?.[0];

  return contract?.compliance?.type !== LegalBindingType.MULTIPLIER;
};

export const showModuleBasedOnContractType = async (
  contractTypes: ContractType[],
): Promise<boolean> => {
  const response = await client.query<GetMemberContractTypeQuery>({
    query: GET_MEMBER_CONTRACT_TYPE,
  });

  const contract = response.data.member?.contracts?.[0];

  return contract?.type ? contractTypes.includes(contract.type) : false;
};

const showIfStatusIsDeletedAndAllowedStatusIncludesDeleted = (
  contractStatus: ContractStatus,
  allowedStatuses: ContractStatus[],
) =>
  contractStatus === ContractStatus.DELETED &&
  allowedStatuses.includes(ContractStatus.DELETED);

const showIfContractStartedAndStatusNotDeleted = (
  allowedStatuses: ContractStatus[],
  contractStatus: ContractStatus,
  contractStarted: boolean,
) =>
  allowedStatuses
    .filter((status) =>
      [
        ContractStatus.ONBOARDING,
        ContractStatus.ACTIVE,
        ContractStatus.OFFBOARDING,
        ContractStatus.ENDED,
      ].includes(status),
    )
    .includes(contractStatus) && contractStarted;

export const showModuleBasedOnOffering = async (
  offeringCode: OfferingCode,
): Promise<boolean> => {
  const response = await client.query<GetCompanyOfferingsQuery>({
    query: GET_COMPANY_OFFERINGS,
  });

  return (
    response?.data.company?.offerings?.some(
      (offer) => offer?.offering === offeringCode,
    ) ?? false
  );
};

export const shouldShowModuleForHrisManagers = async (): Promise<boolean> => {
  const companyUsersRole = await client.query<GetCompanyUsersRoleQuery>({
    query: GET_COMPANY_USERS_ROLE,
  });

  const { user } = await jwtService.getDecodedToken();

  const currentCompanyUser =
    companyUsersRole?.data?.company?.companyUsers?.filter(
      (cu) => Number(cu?.userId) === user.id,
    )?.[0] ?? null;

  if (!currentCompanyUser) return false;

  const isNonHrisManager =
    currentCompanyUser?.roles?.length === 1 &&
    currentCompanyUser?.roles?.[0] === CompanyUserRole.MANAGER;

  if (isNonHrisManager) return false;

  return true;
};

export const showModuleBasedOnContractStatusAndContractStarted = async (
  allowedStatuses: ContractStatus[],
): Promise<boolean> => {
  const response = await client.query<GetMemberContractStatusQuery>({
    query: GET_MEMBER_CONTRACT_STATUS,
  });

  const contractStatus = response.data.member?.contracts?.[0]?.status;

  const contractStarted =
    response.data.member?.contracts?.[0]?.contractStarted || false;

  return contractStatus
    ? showIfContractStartedAndStatusNotDeleted(
        allowedStatuses,
        contractStatus,
        contractStarted,
      ) ||
        showIfStatusIsDeletedAndAllowedStatusIncludesDeleted(
          contractStatus,
          allowedStatuses,
        )
    : true;
};

export const showModuleBasedOnCompanyStatus = async (
  allowedStatus: CompanyStatus[],
): Promise<boolean> => {
  const response = await client.query<GetCompanyStatusAndCsmQuery>({
    query: GET_COMPANY_CSM_AND_STATUS,
  });

  const companyStatus = response.data?.company?.status;

  if (!companyStatus) return false;

  return allowedStatus.includes(companyStatus);
};

export const showModuleBasedOnCsm = async (): Promise<boolean> => {
  const response = await client.query<GetCompanyStatusAndCsmQuery>({
    query: GET_COMPANY_CSM_AND_STATUS,
  });
  return !!response.data?.company?.csmUser?.id;
};
