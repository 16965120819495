/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { WorkFlowProgressNode } from '@multiplier/common';
import { parseDate } from '@multiplier/format';
import { setOnboardingAttributes } from '@multiplier/growthbook';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import { isBefore } from 'date-fns';
import tw from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import { userVar } from 'app/vars';
import countryLabels from 'common/constants/country-labels';
import OnboardingSpecialistCard from 'contract-onboarding/components/onboarding-specialist-card';
import SelectLaptopSection from 'contract-onboarding/components/select-laptop-section';
import { isLastStep, isVisited } from 'contract-onboarding/utils/routing';
import isCountryServiceableForEquipments from 'equipment/utlis/utils';
import EmployeeDetailsCard from 'team/components/employee-details-component';
import { allowSectionEdit } from 'team/services/section-config';

import {
  ContractAgreementType,
  ContractOnboardingStatus,
  ContractOnboardingStep,
  ContractType,
} from '__generated__/graphql';

import AppFeature from '../../../../app/features';
import { OnboardingLayout } from '../../../components/layout';
import OnboardingHeader from '../../../components/onboarding-header';
import OnboardingPrimaryCard from '../../../components/onboarding-primary-card';
import ProgressWidgetContent from '../../../components/progress-widget-content';
import ActivationCard from '../../components/activation-card';
import AdditionalActionsSection from '../../components/additional-actions-section';
import {
  useContract,
  useGetContractStartDateLimit,
  useShowAdditionalPaysWarning,
  useShowUncommittedChangesWarning,
} from '../../hooks';
import statusConfig from './status-config';
import stepConfig from './step-config';

const SectionHeader = tw.div`
  flex flex-col text-text-primary text-h6
`;

const Title = tw.h6`
  font-semibold
`;

const SubTitle = tw.span`
  text-ps mt-tiny
`;

interface EmployeeDetailsHeaderProps {
  edit?: boolean;
}

const EmployeeDetailsHeader: React.FC<EmployeeDetailsHeaderProps> = ({
  edit,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <SectionHeader>
      <Title>
        {edit
          ? t(
              'onboarding-phase.review-employee-details.title',
              'Review Contract Details',
            )
          : t(
              'onboarding-phase.view-employee-details.title',
              'View Contract Details',
            )}
      </Title>
      {edit && (
        <SubTitle>
          {t(
            'onboarding-phase.employee-details.sub-title',
            'Click on the pencil icon to make instant changes to the Employee contract.',
          )}
        </SubTitle>
      )}
    </SectionHeader>
  );
};

const OnboardingPhase: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.company');
  const { id: contractIdFromPath } = useParams<{ id?: string }>();
  const { contract, loading } = useContract(contractIdFromPath);
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  useEffect(() => {
    if (contract?.country && contract?.type) {
      setOnboardingAttributes({
        country: contract.country,
        contractType: contract.type,
        companyId: contract.company?.id,
      });
    }
  }, [contract]);

  const statusList = useMemo(
    () =>
      (contract.onboarding?.statuses ?? [ContractOnboardingStatus.DRAFT])
        .filter(notEmpty)
        .map((status) => ({
          status,
          label: statusConfig[status]?.statusLabel(
            t,
            contract?.type,
            contract?.member?.firstName ?? 'Contractor',
          ),
        })),
    [contract.onboarding],
  );

  const isReviewContractStep =
    contract.onboarding?.current &&
    [ContractOnboardingStep.ONBOARDING_REVIEW].includes(
      contract.onboarding.current,
    );

  const isContractPreparingConfirmationStep =
    contract.onboarding?.current &&
    [
      ContractOnboardingStep.ONBOARDING_CONTRACT_PREPARING_CONFIRMATION,
    ].includes(contract.onboarding.current);

  const isCustomFreelancerOrHRMember =
    (contract?.type === ContractType.FREELANCER &&
      contract?.compliance?.contractAgreementType ===
        ContractAgreementType.CUSTOM_TEMPLATE) ||
    contract?.type === ContractType.HR_MEMBER;

  const isVerifyingContractor =
    contract.onboarding?.current ===
      ContractOnboardingStep.ONBOARDING_VERIFYING &&
    contract.onboarding?.status ===
      ContractOnboardingStatus.MEMBER_VERIFICATION_IN_PROGRESS &&
    contract.type === ContractType.CONTRACTOR;

  const shouldShowOnboardingStatusElements =
    (contract.onboarding?.current &&
      ![
        ContractOnboardingStep.ONBOARDING_MSA_PENDING,
        ContractOnboardingStep.ONBOARDING_ACTIVATION,
        ContractOnboardingStep.ONBOARDING_MULTIPLIER_SIGNING,
      ].includes(contract.onboarding.current)) ||
    isVerifyingContractor;

  const {
    shouldShowAdditionalPaysWarning,
    loading: loadingShowAdditionalPaysWarning,
  } = useShowAdditionalPaysWarning(
    contract?.country,
    contract?.countryStateCode,
    contract?.type,
    contract?.term,
    contract?.compensation,
    contract?.onboarding?.status,
  );

  const {
    shouldShowUncommittedChanges,
    changeWarningStatus,
  } = useShowUncommittedChangesWarning();

  const [workflowUpdating, toggleWorkflowUpdating] = useToggle(false);

  const isCountryServiceable = isCountryServiceableForEquipments(contract);

  const { earliestStartDate } = useGetContractStartDateLimit({
    country: contract.country,
    countryStateCode: contract.countryStateCode,
    contractType: contract.type,
    workStatus: contract.workStatus,
    legalEntityId: contract.legalEntityId,
  });

  const contractBackdated = useMemo(() => {
    if (!contract) {
      return false;
    }

    if (contract.type === ContractType.EMPLOYEE && earliestStartDate) {
      return isBefore(parseDate(contract.startOn), earliestStartDate);
    }

    return false;
  }, [contract.type, contract.startOn, earliestStartDate]);

  const shouldAllowEdit =
    !workflowUpdating &&
    !contract.contractBlocked &&
    allowSectionEdit(
      contract.status,
      contract.onboarding,
      contract.type,
      contract.compliance,
      current,
    );

  const totalSteps =
    contract.onboarding?.steps &&
    contract.onboarding.steps
      .filter(
        (step) => step !== ContractOnboardingStep.ONBOARDING_MULTIPLIER_SIGNING,
      )
      .filter(notEmpty).length;

  const showOnboardingSpecialist = useFeature(
    AppFeature.SHOW_ONBOARDING_SPECIALIST,
  )?.on;

  const sorOnboardingContext = useSorOnboardingContext({
    type: contract.type,
    legalEntityId: contract.legalEntityId,
  });

  return (
    <OnboardingLayout data-testid="onboarding-phase">
      <div
        css={[
          tw`pt-56 h-[356px] bg-background-inverted text-center`,
          totalSteps === 1 && tw`h-[144px]`,
        ]}
      >
        <div
          css={[
            tw`flex flex-col gap-y-extra-large items-stretch [width: 1200px] ml-auto mr-auto`,
            totalSteps && totalSteps < 5 && tw`[width: 740px]`,
          ]}
          data-testid="onboarding-phase-header"
        >
          <OnboardingHeader
            id={contract.id}
            country={contract.country}
            firstName={contract?.member?.firstName}
            lastName={contract?.member?.lastName}
            contractType={contract.type}
            position={contract.position}
          />
          {shouldShowOnboardingStatusElements && (
            <div
              data-testid="onboarding-progress-bar"
              tw="flex flex-row w-full"
            >
              {contract.onboarding?.steps &&
                contract.onboarding?.steps?.length > 1 &&
                contract.onboarding.steps
                  .filter(
                    (step) =>
                      step !==
                      ContractOnboardingStep.ONBOARDING_MULTIPLIER_SIGNING,
                  )
                  .filter(notEmpty)
                  .map((step, index) => {
                    /** TODO: Add new onboarding step in onboarding workflow
                     * Currently, both CREATED and CREATED_CUSTOM share the same onboarding step, ONBOARDING_REVIEW
                     * Because of that, they both share the same progress node titles and layout types.
                     *
                     * They should ideally have distinct onboarding steps,
                     * but that's a bigger change that involves BE workflow changes.
                     * */
                    const title =
                      step === ContractOnboardingStep.ONBOARDING_REVIEW &&
                      (contract?.compliance?.contractAgreementType ===
                        ContractAgreementType.CUSTOM_TEMPLATE ||
                        contract?.compliance?.contractAgreementType === null)
                        ? t(
                            'onboarding-phase.onboarding-review-created-custom.title',
                            'Review Details',
                          )
                        : stepConfig[step] &&
                          stepConfig[step].title(t, contract.type);

                    return (
                      contract?.onboarding?.steps && (
                        <WorkFlowProgressNode
                          key={step}
                          first={index === 0}
                          last={isLastStep(contract.onboarding.steps, index)}
                          done={isVisited(
                            index,
                            contract.onboarding.steps ?? [],
                            contract.onboarding.current,
                          )}
                          current={step === contract.onboarding.current}
                          title={title}
                          description={
                            step === contract.onboarding.current &&
                            contract.onboarding?.status
                              ? statusConfig[
                                  contract.onboarding.status
                                ]?.pendingLabel(t, contract.type)
                              : stepConfig[step] &&
                                stepConfig[step].status(t, contract.type)
                          }
                          blocked={
                            (step === contract.onboarding.current &&
                              (contract.contractBlocked ||
                                contract.onboarding.status ===
                                  ContractOnboardingStatus.VISA_DOCUMENTS_VERIFIED)) ??
                            false
                          }
                        />
                      )
                    );
                  })}
            </div>
          )}
        </div>
      </div>
      <div
        css={[
          tw`w-[740px] ml-auto mr-auto flex flex-col gap-y-large`,
          shouldShowOnboardingStatusElements ? tw`mt-[-76px]` : tw`mt-[-212px]`,
          totalSteps === 1 && tw`mt-large`,
        ]}
      >
        {!shouldShowOnboardingStatusElements && (
          <ActivationCard
            memberId={contract?.member?.id}
            firstName={contract.member?.firstName}
            lastName={contract.member?.lastName}
            contractId={contractIdFromPath}
            contractType={contract?.type}
            workflowUpdating={workflowUpdating}
            toggleWorkflowUpdating={toggleWorkflowUpdating}
            onboardingStatus={contract.onboarding?.status}
            onboardingStep={contract.onboarding?.current}
            status={contract.status}
            workStatus={contract.workStatus}
            startOn={contract.startOn}
            depositPayables={contract.depositPayable}
            compliance={contract.compliance}
          />
        )}
        {shouldShowOnboardingStatusElements && (
          <OnboardingPrimaryCard
            header={
              contract.onboarding?.status &&
              statusConfig[contract.onboarding.status] &&
              statusConfig[contract.onboarding.status].title(
                t,
                `${contract.member?.firstName} ${contract.member?.lastName}`,
                contract.type,
                contract.workStatus,
              )
            }
            subtitle={
              contract.onboarding?.status &&
              statusConfig[contract.onboarding.status] &&
              statusConfig[contract.onboarding.status].subtitle &&
              statusConfig[contract.onboarding.status].subtitle?.(
                t,
                `${contract.member?.firstName}`,
              )
            }
            pendingOn={
              contract.onboarding?.status &&
              statusConfig[contract.onboarding.status]?.pendingOn?.(
                contract?.type,
              )
            }
            loading={loading}
            contractId={contract.id}
            contractType={contract.type}
            depositPayable={contract.depositPayable}
            firstName={contract.member?.firstName}
            lastName={contract.member?.lastName}
            contractBlocked={contract.contractBlocked}
            unresolvedBlockEvents={contract.unresolvedBlockEvents}
            currentStatus={contract?.onboarding?.status}
          >
            {contract.onboarding?.status &&
              contract.onboarding.current &&
              statusConfig[contract.onboarding.status] && (
                <ProgressWidgetContent
                  memberName={contract?.member?.firstName}
                  memberEmail={contract?.member?.emails?.[0]?.email}
                  currentStatus={contract.onboarding.status}
                  variant={stepConfig[contract.onboarding.current].variant}
                  description={statusConfig[
                    contract.onboarding.status
                  ].description(
                    t,
                    `${contract.member?.firstName}`,
                    contract.member?.gender,
                    countryLabels[contract?.country || ''],
                    contract?.type,
                    contract.workStatus,
                    sorOnboardingContext,
                  )}
                  primaryAction={
                    statusConfig[contract.onboarding.status].primaryAction
                  }
                  optionalAction={
                    statusConfig[contract.onboarding.status].optionalAction
                  }
                  statuses={statusList}
                  steps={contract.onboarding.steps}
                  contractId={contractIdFromPath}
                  compliance={contract.compliance}
                  showAdditionalPaysWarning={
                    shouldShowAdditionalPaysWarning &&
                    !loadingShowAdditionalPaysWarning
                  }
                  uncommittedChanges={shouldShowUncommittedChanges}
                  workflowUpdating={workflowUpdating}
                  toggleWorkflowUpdating={toggleWorkflowUpdating}
                  contractBackdated={contractBackdated}
                  allowRevoke={statusConfig[
                    contract.onboarding.status
                  ]?.allowRevoke?.(contract.workStatus)}
                  contractDocumentWorkflowStatus={
                    contract.onboarding.contractDocumentWorkflow?.currentStatus
                  }
                  contractBlocked={contract.contractBlocked}
                  unresolvedBlockEvents={contract.unresolvedBlockEvents}
                  depositPayables={contract.depositPayable}
                  activationCutoff={contract.onboarding.activationCutoff}
                  contractType={contract.type}
                  workStatus={contract.workStatus}
                  legalEntityId={contract?.legalEntityId}
                  currentOnboardingStep={contract.onboarding.current}
                />
              )}
          </OnboardingPrimaryCard>
        )}
        {showOnboardingSpecialist &&
          !sorOnboardingContext.isSorOnboardingEnabled && (
            <OnboardingSpecialistCard
              contractId={contract.id || ''}
              showHint={false}
              showTitleOnly={false}
            />
          )}
        {!isReviewContractStep &&
          !isContractPreparingConfirmationStep &&
          isCountryServiceable &&
          contract?.type !== ContractType.CONTRACTOR && (
            <SelectLaptopSection contract={contract} />
          )}

        <EmployeeDetailsHeader edit={shouldAllowEdit} />

        <EmployeeDetailsCard
          contract={contract}
          member={contract.member}
          loading={loading}
          uncommittedChangesCallback={
            isReviewContractStep && !isCustomFreelancerOrHRMember
              ? changeWarningStatus
              : undefined
          }
          workflowUpdating={workflowUpdating}
          contractBackdated={contractBackdated}
          contractBlocked={contract.contractBlocked ?? false}
        />
        {contract.onboarding?.status &&
          statusConfig[contract.onboarding.status] &&
          contract.type !== ContractType.CONTRACTOR && (
            <AdditionalActionsSection
              contractId={contract.id}
              memberId={contract.member?.id}
              memberFirstName={contract?.member?.firstName}
              compliance={contract.compliance}
              allowRevoke={statusConfig[
                contract.onboarding.status
              ]?.allowRevoke?.(contract.workStatus)}
              allowDelete={statusConfig[
                contract.onboarding.status
              ].allowDelete?.(contract.workStatus)}
              workflowUpdating={workflowUpdating}
              toggleWorkflowUpdating={toggleWorkflowUpdating}
              contractBlocked={contract.contractBlocked}
            />
          )}
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingPhase;
