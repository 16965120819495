export enum SpinnerType {
  EXPERIENCE = 'EXPERIENCE',
  MODULE = 'MODULE',
  CUSTOM_LAYOUT = 'CUSTOM_LAYOUT',
  DOCUMENT_VIEWER = 'DOCUMENT_VIEWER',
  CALENDAR = 'CALENDAR',
}

export interface SpinnerProps {
  variant: SpinnerType;
}
