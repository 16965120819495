/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import {
  Controller,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon, ToolTip } from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import groupBy from 'lodash/groupBy';
import tw from 'twin.macro';

import ComboBox, { DropdownValue } from 'common/components/combo-box';
import DatePicker from 'common/components/date-picker';
import Radio from 'common/components/radio';
import TextInput from 'common/components/text-input';
import {
  DeductionFormValues,
  getDefaultDeductionValue,
  mapDeductionFromCompensation,
} from 'contract-onboarding/company/services/deduction';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import OnboardingSpecialistCard from 'contract-onboarding/components/onboarding-specialist-card';
import StepNavigationFooter from 'contract-onboarding/components/step-navigation-footer';
import { useGetAndGroupDeductionDefinitions } from 'contract-onboarding/hooks';
import {
  useGetCountryLegalRequirementsForMember,
  useUpdateLegalDetails,
} from 'contract-onboarding/member/hooks';
import stepConfig from 'contract-onboarding/member/pages/onboarding/step-config';
import {
  getLegalDataSchema,
  memberLegalDataMap,
} from 'contract-onboarding/services/legal-data';

import {
  ContractOnboardingStep,
  DataFieldDefinition,
  Maybe,
  useGetMemberQuery,
} from '__generated__/graphql';

import AppFeature from '../../../../../../app/features';
import CanadaRRSP from './components/canadaRRSP';

export type LegalDataRequirementField = {
  value: Maybe<string>;
  type?: Maybe<string>;
} & Pick<DataFieldDefinition, 'key' | 'label' | 'description'>;

export interface LegalDetailsFormParams {
  legalData: LegalDataRequirementField[];
  deductions?: DeductionFormValues | null;
}

export interface OnboardingStepProps {
  currentStep?: ContractOnboardingStep;
  onboardingSteps?: Maybe<ContractOnboardingStep>[];
}

const LegalDetailsView: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    data: { member } = { member: {} },
    loading: loadingContract,
  } = useGetMemberQuery();

  const contract = useMemo(() => member?.contracts?.[0], [member]);

  const { definitions, definitionMap } = useGetAndGroupDeductionDefinitions({
    country: contract?.country,
    state: contract?.countryStateCode,
    contractType: contract?.type,
    contractTerm: contract?.term,
  });

  const sorOnboardingContext = useSorOnboardingContext({
    type: contract?.type,
    companyId: contract?.company?.id,
    legalEntityId: contract?.legalEntityId,
  });

  const definitionsByGroupKey = useMemo(
    () => Object.entries(groupBy(definitions, 'groupKey')),
    [definitions],
  );

  const { loading, onSubmit } = useUpdateLegalDetails(contract?.id, member?.id);
  const {
    getRequirements,
    loading: loadingCountryRequirements,
    requirements,
  } = useGetCountryLegalRequirementsForMember({
    workStatus: contract?.workStatus,
    contractType: contract?.type,
  });

  useEffect(() => {
    if (contract?.country) {
      getRequirements(contract.country, contract.type);
    }
  }, [contract]);

  const methods = useForm<LegalDetailsFormParams>({
    mode: 'onChange',
    resolver: yupResolver(
      getLegalDataSchema(
        requirements?.memberLegalDataRequirements,
        t,
        definitions,
      ),
    ),
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    trigger,
    formState: { isValid, errors },
    watch,
  } = methods;

  const { fields } = useFieldArray<LegalDetailsFormParams>({
    control,
    name: 'legalData',
  });

  // re-trigger form validation when memberLegalDataRequirements promise is returned
  useDeepCompareEffect(() => {
    trigger();
  }, [requirements]);

  useDeepCompareEffect(() => {
    const legalData = member?.legalData && memberLegalDataMap(member.legalData);
    reset({
      legalData:
        Object.values(requirements?.memberLegalDataRequirements)
          .filter((m) =>
            requirements?.memberLegalDataDefinitionKeysToShow.includes(
              m?.key || '',
            ),
          )
          .map((val: DataFieldDefinition) => ({
            key: val.key,
            label: val.label,
            description: val.key ? legalData?.[val.key]?.identifier : null,
            value: val.key ? legalData?.[val.key]?.value : null,
          })) ?? undefined,
      deductions:
        mapDeductionFromCompensation(contract?.compensation, definitionMap) ||
        getDefaultDeductionValue(definitions),
    });
  }, [requirements, member, memberLegalDataMap, definitions]);

  const showOnboardingSpecialist = useFeature(
    AppFeature.SHOW_ONBOARDING_SPECIALIST,
  )?.on;

  return (
    <div>
      {showOnboardingSpecialist &&
        !sorOnboardingContext.isSorOnboardingEnabled && (
          <div tw="mb-base">
            <OnboardingSpecialistCard
              contractId={contract?.id || ''}
              showHint={false}
              showTitleOnly={false}
            />
          </div>
        )}
      <StepLayout data-testid="legal-data">
        <FormLayout onSubmit={handleSubmit(onSubmit)} tw="gap-y-base">
          <FormProvider {...methods}>
            <FormCard>
              <div tw="grid gap-x-base gap-y-large">
                {fields.map((field, index) => {
                  if (!field?.key) return null;

                  const dataType =
                    requirements?.memberLegalDataRequirements[field.key]
                      ?.dataType;

                  const dependsOn =
                    requirements?.memberLegalDataRequirements[field.key]
                      ?.dependsOn;

                  const disabled =
                    !!field.value &&
                    !(
                      requirements?.memberLegalDataRequirements[field.key]
                        ?.editable ?? true
                    );

                  // dependsOn can only be validated on CheckboxFields at the moment
                  if (
                    dependsOn?.some(
                      (controllingField) =>
                        controllingField.key &&
                        watch('legalData')?.find(
                          (fieldElement) =>
                            fieldElement.key === controllingField.key,
                        )?.value !== 'true',
                    )
                  )
                    return null;

                  const description = t(
                    `legal-details.${field.label}.description`,
                    field.description ?? '',
                  );

                  return (
                    <TextInput.Container key={field.id}>
                      <div tw="flex flex-row gap-x-small">
                        <TextInput.Label htmlFor={field.key ?? ''}>
                          {t(
                            `legal-details.${field.label}.label`,
                            field.label ?? '',
                          )}
                        </TextInput.Label>
                        {description && description.length >= 100 && (
                          <ToolTip
                            content={
                              <div tw="max-w-[600px]">{description}</div>
                            }
                          >
                            <Icon data-testid="description-info" name="info" />
                          </ToolTip>
                        )}
                      </div>
                      {description &&
                        description.length > 0 &&
                        description.length < 100 && (
                          <TextInput.Helper>{description}</TextInput.Helper>
                        )}
                      {dataType?.__typename === 'DateField' && (
                        <Controller
                          control={control}
                          name={`legalData.${index}.value`}
                          defaultValue={field.value}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              tw="w-1/2"
                              data-testid="date-input"
                              id="date-input"
                              value={value}
                              onChange={(val) => {
                                if (val === '') onChange(undefined);
                                else onChange(val);
                              }}
                              min={dataType?.minDate ?? undefined}
                              max={dataType?.maxDate ?? undefined}
                              error={!!errors?.legalData?.[index]?.value}
                              helperText={
                                errors?.legalData?.[index]?.value?.message
                              }
                              loading={false}
                              disabled={disabled}
                            />
                          )}
                        />
                      )}
                      {dataType?.__typename === 'DropDownField' && (
                        <Controller
                          control={control}
                          name={`legalData.${index}.value`}
                          defaultValue={field.value}
                          render={({ field: { value, onChange } }) => (
                            <ComboBox
                              variant="default"
                              tw="w-1/2"
                              value={value ?? ''}
                              onChange={onChange}
                              data-testid={field.key}
                              placeholder={t(
                                `legal-details.${field.label}.placeholder`,
                                field.label ?? '',
                              )}
                              dropdownValues={
                                (dataType?.values?.map((dropdownValue) => ({
                                  title: t(
                                    `legal-details.${
                                      field.label
                                    }.option.${dropdownValue?.toLowerCase()}`,
                                    dropdownValue ?? '',
                                  ),
                                  value: dropdownValue,
                                })) as DropdownValue[]) ?? []
                              }
                              disabled={disabled}
                            />
                          )}
                        />
                      )}
                      {dataType?.__typename === 'DropDownTextField' && (
                        <div tw="flex flex-row gap-x-tiny">
                          <Controller
                            control={control}
                            name={`legalData.${index}.description`}
                            render={({ field: { value, onChange } }) => (
                              <ComboBox
                                variant="default"
                                tw="h-6"
                                value={value ?? ''}
                                onChange={onChange}
                                data-testid={field.key}
                                placeholder={t(
                                  `legal-details.${field.label}.placeholder`,
                                  'Select from List',
                                )}
                                dropdownValues={
                                  (dataType.values?.map((dropdownValue) => ({
                                    title: t(
                                      `legal-details.${
                                        field.label
                                      }.option.${dropdownValue?.toLowerCase()}`,
                                      dropdownValue ?? '',
                                    ),
                                    value: dropdownValue,
                                  })) as DropdownValue[]) ?? []
                                }
                                disabled={disabled}
                              />
                            )}
                          />
                          <TextInput
                            divStyles={tw`flex-grow`}
                            id={field.key ?? ''}
                            data-testid={field.key ?? ''}
                            defaultValue={field.value ?? ''}
                            placeholder={t(
                              `legal-details.${field.key}.placeholder`,
                              'Add Number',
                            )}
                            disabled={disabled}
                            {...register(`legalData.${index}.value`)}
                            onChange={(e) => {
                              register(`legalData.${index}.value`).onChange(e);
                              trigger(`legalData.${index}`);
                            }}
                            error={!!errors?.legalData?.[index]?.value?.message}
                          />
                        </div>
                      )}
                      {dataType?.__typename === 'TextField' && (
                        <TextInput
                          id={field.key ?? ''}
                          defaultValue={field.value ?? ''}
                          placeholder={t(
                            'legal-details.numeric.number-placeholder',
                            'Add Number',
                          )}
                          {...register(
                            `legalData.${index}.value` as FieldPath<LegalDetailsFormParams>,
                          )}
                          disabled={disabled}
                          error={!!errors?.legalData?.[index]?.value?.message}
                        />
                      )}
                      {dataType?.__typename === 'CheckboxField' && (
                        <div tw="flex flex-row gap-x-base">
                          <Radio
                            id={field.key ?? ''}
                            labelStyles={tw`py-small flex-grow`}
                            value="true"
                            disabled={disabled}
                            {...register(
                              `legalData.${index}.value` as FieldPath<LegalDetailsFormParams>,
                            )}
                          >
                            {t('legal-details.checkbox.yes', 'Yes')}
                          </Radio>
                          <Radio
                            id={field.key ?? ''}
                            labelStyles={tw`py-small flex-grow`}
                            value="false"
                            disabled={disabled}
                            {...register(
                              `legalData.${index}.value` as FieldPath<LegalDetailsFormParams>,
                            )}
                          >
                            {t('legal-details.checkbox.no', 'No')}
                          </Radio>
                        </div>
                      )}
                      {errors.legalData?.[index] && (
                        <div>
                          {errors.legalData[index]?.description && (
                            <TextInput.Error>
                              {errors.legalData[index]?.description?.message}
                            </TextInput.Error>
                          )}
                          {errors.legalData[index]?.value && (
                            <TextInput.Error>
                              {errors.legalData[index]?.value?.message}
                            </TextInput.Error>
                          )}
                        </div>
                      )}
                    </TextInput.Container>
                  );
                })}
              </div>
            </FormCard>
            {definitionsByGroupKey.map(
              ([groupKey, sameGroupDefinitions]) =>
                groupKey === 'rrsp' && (
                  <CanadaRRSP definitions={sameGroupDefinitions} />
                ),
            )}
            <StepNavigationFooter
              tw="mt-tiny"
              disabled={!isValid}
              currentStep={currentStep}
              onboardingSteps={onboardingSteps}
              contractId={contract?.id}
              submitLoading={
                loading || loadingContract || loadingCountryRequirements
              }
              stepConfig={stepConfig}
            />
          </FormProvider>
        </FormLayout>
      </StepLayout>
    </div>
  );
};

export default LegalDetailsView;
