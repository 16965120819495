// import teamMemberRewardsStatementsConfig from 'team/member/rewards-statements/config';
import accountSettingsCompanyConfig from 'account-settings/company/config';
import accountSettingsMemberConfig from 'account-settings/member/config';
import calendarCompanyConfig from 'calendar/company/config';
import calendarMemberConfig from 'calendar/member/config';
import contactUsCompanyConfig from 'contact-us/company/config';
import contactUsMemberConfig from 'contact-us/member/config';
import contractOnboardingConfig from 'contract-onboarding/company/config';
import onboardingGuidesConfig from 'country-documents/company/config';
import dashboardCompanyConfig from 'dashboard/company/config';
import dashboardMemberConfig from 'dashboard/member/config';
import memberOnboardingFormsConfig from 'document-management/member/onboarding-forms/config';
import equipmentsConfig from 'equipment/company/config';
import expensesCompanyConfig from 'expenses/company/config';
import expensesMemberConfig from 'expenses/member/config';
import insuranceConfig from 'insurance/config';
import insuranceMemberConfig from 'insurance/member/config';
import integationsConfig from 'integrations/config';
import marketplaceConfig from 'marketplace/config';
import memberInvoiceCompanyConfig from 'member-payables/company/config';
import memberInvoiceMemberConfig from 'member-payables/member/config';
import {
  companyConfig as orgChartCompanyConfig,
  memberConfig as orgChartMemberConfig,
} from 'organization-chart/config';
import {
  companyConfig as orgDirectoryCompanyConfig,
  memberConfig as orgDirectoryMemberConfig,
} from 'organization-directory/config';
import organizationSettingsConfig from 'organization-settings/config';
import paySupplementsConfig from 'pay-supplements/config';
import paymentsConfig from 'payments/config';
import payrollCompanyConfig from 'payroll/company/config';
import payrollMemberConfig from 'payroll/member/config';
import paySlipsConfig from 'payslips/config';
import performanceReviewsConfig from 'performance-reviews/config';
import reportsConfig from 'reports/config';
import resourcesConfig from 'resources/config';
import salaryCalculatorConfig from 'salary-calculator/config';
import teamCompanyConfig from 'team/company/config';
import teamCompanyOnboardingAwarenessConfig from 'team/company/onboarding-awareness-banner/config';
import teamMemberEmploymentCertificateConfig from 'team/member/employment-certificate/config';
import teamMemberEmploymentContractConfig from 'team/member/employment-contract/config';
import teamMemberOnboardingSpecialistConfig from 'team/member/onboarding-specialist/config';
import teamMemberPersonalDetailsConfig from 'team/member/personal-details/config';
import timeOffCompanyConfig from 'time-off/company/config';
import timeOffMemberConfig from 'time-off/member/config';
import timesheetsCompanyConfig from 'timesheets/company/config';
import timesheetsMemberConfig from 'timesheets/member/config';

const registeredModules = [
  expensesCompanyConfig,
  expensesMemberConfig,
  timeOffCompanyConfig,
  timeOffMemberConfig,
  dashboardCompanyConfig,
  dashboardMemberConfig,
  salaryCalculatorConfig,
  teamCompanyConfig,
  teamCompanyOnboardingAwarenessConfig,
  teamMemberPersonalDetailsConfig,
  teamMemberEmploymentContractConfig,
  teamMemberEmploymentCertificateConfig,
  // teamMemberRewardsStatementsConfig,
  insuranceConfig,
  paySupplementsConfig,
  organizationSettingsConfig,
  paySlipsConfig,
  contractOnboardingConfig,
  timesheetsMemberConfig,
  timesheetsCompanyConfig,
  accountSettingsMemberConfig,
  accountSettingsCompanyConfig,
  calendarCompanyConfig,
  calendarMemberConfig,
  memberOnboardingFormsConfig,
  reportsConfig,
  memberInvoiceMemberConfig,
  memberInvoiceCompanyConfig,
  performanceReviewsConfig,
  contactUsMemberConfig,
  contactUsCompanyConfig,
  resourcesConfig,
  insuranceMemberConfig,
  integationsConfig,
  equipmentsConfig,
  onboardingGuidesConfig,
  marketplaceConfig,
  paymentsConfig,
  payrollCompanyConfig,
  payrollMemberConfig,
  orgChartCompanyConfig,
  orgChartMemberConfig,
  orgDirectoryCompanyConfig,
  orgDirectoryMemberConfig,
  teamMemberOnboardingSpecialistConfig,
];

export default registeredModules;
