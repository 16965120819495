/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { Icon, Popover } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import countryLabels from 'common/constants/country-labels';

import {
  ContractType,
  CountryCode,
  InputMaybe,
  Maybe,
  Member,
  RateFrequency,
  useGetContractJobCodeLazyQuery,
} from '__generated__/graphql';

export interface JobCodeCalloutProps {
  countryCode?: Maybe<CountryCode>;
  contractType?: ContractType | null;
  frequency?: Maybe<RateFrequency>;
  probationBasePayAmount?: InputMaybe<number>;
  postProbationBasePayAmount?: InputMaybe<number>;
  member?: Member | null;
}

const JobCodeCallout: React.FC<JobCodeCalloutProps> = ({
  countryCode,
  contractType,
  frequency,
  probationBasePayAmount,
  postProbationBasePayAmount,
  member,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const [
    getContractJobCode,
    { data, loading },
  ] = useGetContractJobCodeLazyQuery();

  const isItaly = countryCode === CountryCode.ITA;
  const isEmployee = contractType === ContractType.EMPLOYEE;
  const isSalaryNull = !probationBasePayAmount && !postProbationBasePayAmount;

  useDebounce(
    () => {
      if (!isItaly || !isEmployee || !frequency || isSalaryNull) {
        return;
      }

      if (!countryCode || !contractType) return;

      getContractJobCode({
        variables: {
          filter: {
            countryCode,
            contractType,
            probationBasePay: {
              amount: probationBasePayAmount
                ? parseFloat(probationBasePayAmount.toString())
                : null,
              frequency,
            },
            postProbationBasePay: {
              amount: postProbationBasePayAmount
                ? parseFloat(postProbationBasePayAmount.toString())
                : null,
              frequency,
            },
          },
        },
      });
    },
    500,
    [
      countryCode,
      contractType,
      frequency,
      probationBasePayAmount,
      postProbationBasePayAmount,
    ],
  );

  const employeeName = useMemo(
    () =>
      member?.fullLegalName ||
      [member?.firstName, member?.lastName].filter((n) => !!n).join(' '),
    [member],
  );
  const contractJobCode = useMemo(() => data?.contractJobCode, [data]);

  if (loading || !contractJobCode) {
    return null;
  }

  return (
    <div
      data-testid="job-code-callout"
      tw="rounded-base p-base flex flex-row bg-background-primary items-center w-full mt-large"
    >
      <Icon
        name="info"
        fill={theme`colors.icon-primary`}
        tw="min-w-[20px] h-[20px]"
      />
      <div tw="ml-base text-text-primary">
        <Trans t={t} i18nKey="job-code-callout.italy.description">
          <span tw="font-semibold">
            {{ employeeName: employeeName || 'Employee' }}
          </span>
          &nbsp; will be considered as&nbsp;
          <span tw="font-semibold">Level {{ contractJobCode }} Employee</span>
          &nbsp; according to Job codes in{' '}
          {{ countryCode: countryCode ? countryLabels[countryCode] : '' }}
        </Trans>
      </div>
      <Popover
        containerStyles={tw`max-w-[270px]`}
        tw="ml-auto pl-tiny"
        popOver={
          <div tw="flex flex-col bg-background-inverted text-text-inverted-primary p-small rounded-base">
            <div tw="text-text-inverted-primary">
              {t(
                'job-code-callout.italy.popover.description',
                'Job codes in Italy are as per the minimum salary ranges as defined',
              )}
              &nbsp;
              <a
                tw="text-text-inverted-primary font-semibold underline"
                href="https://help.usemultiplier.com/en/articles/7958246-job-codes-in-italy-an-employer-should-know"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('job-code-callout.italy.popover.link', 'here')}
              </a>
            </div>
          </div>
        }
      >
        <Icon
          name="help"
          fill={theme`colors.icon-primary`}
          tw="ml-auto min-w-[20px] h-[20px] text-text-primary"
        />
      </Popover>
    </div>
  );
};

export default JobCodeCallout;
