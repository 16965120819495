import React, { useEffect, useRef } from 'react';

import { useClickAway } from 'react-use';

import tw, { css, theme } from 'twin.macro';

import Button from '../../../button';
import ComboBox from '../../../combo-box';
import IconButton from '../../../icon-button';
import { getYearRange, months } from '../../utils/calendar-utils';

interface YearSelectorProps {
  year: number;
  month: number;
  max: Date;
  min: Date;
  close: () => void;
  setYear: (year: number) => void;
  setMonth: (month: number) => void;
  hideBackArrow?: boolean;
}

const currentYear = new Date().getFullYear();

const YearSelector: React.FC<YearSelectorProps> = ({
  year,
  month,
  max,
  min,
  close,
  setYear,
  setMonth,
  hideBackArrow = false,
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const btnGrpWrapperRef = useRef<HTMLDivElement>(null);
  const years = getYearRange(min.getFullYear(), max.getFullYear());

  useEffect(() => {
    if (!years.some((i) => i === year)) {
      setYear(Number(years[0]));
    }
  }, [min, max]);

  useEffect(() => {
    if (btnRef?.current) {
      btnRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [btnRef]);

  useClickAway(btnGrpWrapperRef, close);

  return (
    <div
      tw="flex flex-col border rounded-tiny border-border-secondary shadow"
      ref={btnGrpWrapperRef}
    >
      <div tw="flex justify-center relative my-base">
        {!hideBackArrow && (
          <div
            data-testid="year-selector-back-btn"
            css={[
              tw`absolute left-base`,
              css`
                top: calc(50% - 18px);
              `,
            ]}
          >
            <IconButton
              size="small"
              onClick={close}
              name="caret-left"
              fill={theme`colors.icon-primary`}
            />
          </div>
        )}
        <div tw="w-96 z-10">
          <ComboBox
            variant="default"
            showArrow
            placeholder=""
            dropdownValues={years.map((y) => ({
              value: `${y}`,
              title: `${y}`,
            }))}
            value={
              years.some((i) => i === year)
                ? String(year)
                : currentYear in years
                ? String(currentYear)
                : String(years[0])
            }
            onChange={(v) => {
              if (v) setYear(Number(v));
            }}
          />
        </div>
      </div>
      <div tw="grid grid-cols-3 gap-6 m-base">
        {months.map((m, i) => (
          <Button
            size="small"
            variant={month === i ? 'default' : 'outline'}
            disabled={
              (year <= min.getFullYear() && min.getMonth() > i) ||
              (year >= max.getFullYear() && max.getMonth() <= i)
            }
            key={m}
            onClick={() => {
              setMonth(i);
              close();
            }}
          >
            {m.slice(0, 3)}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default YearSelector;
