import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import tw, { TwStyle, theme } from 'twin.macro';

import { useSearchParams } from '../../../hooks';
import Icon from '../../../icon';
import { ThemeContext } from '../../../theme-provider';
import { prependTableIdForUrlParams } from '../../../utils/table-utils';

export interface TableHeadProps {
  associatedTableIds?: string[];
  tabs: {
    name: string;
    key: string;
    count?: number;
    icon?: string;
    text?: string;
    disabled?: boolean;
  }[];
  value: string;
  onChange: (val: string) => void;
  defaultTab?: string;
  defaultTabSwitchingEnabled?: boolean;
  disableUrlUpdate?: boolean;
  tabStyle?: (isActive: boolean) => TwStyle | undefined;
  tabKey?: string;
  styles?: TwStyle;
  borderBottom?: boolean;
}

const TableHead: React.FC<TableHeadProps> = ({
  associatedTableIds,
  tabs,
  value,
  defaultTab,
  onChange,
  defaultTabSwitchingEnabled = true,
  disableUrlUpdate = false,
  tabStyle,
  tabKey = 'tab',
  styles,
  borderBottom = false,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [currentTab] = useSearchParams(tabKey);

  useEffect(() => {
    if (currentTab && currentTab !== value && !disableUrlUpdate) {
      onChange(currentTab);
    }
  }, [currentTab, disableUrlUpdate]);

  const handleTabClick = useCallback(
    (tab: string) => {
      const params = new URLSearchParams(search);
      if (!disableUrlUpdate) {
        params.set(tabKey, tab);
        if (associatedTableIds?.length)
          associatedTableIds?.forEach((tableId) => {
            params.set(prependTableIdForUrlParams('page', tableId), '1');
          });
        else params.set('page', '1');
        navigate(`${pathname}?${params.toString()}`);
      }
      onChange(tab);
    },
    [disableUrlUpdate, search],
  );

  const isFirstRun = useRef(true);

  useDeepCompareEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else if (defaultTabSwitchingEnabled) {
      const selectedTab = tabs.find((tab) => tab.key === value);
      if (selectedTab?.count === 0) {
        const otherTab = tabs.find(
          (tab) => tab.key !== value && tab.count !== 0,
        );
        if (otherTab) {
          handleTabClick(otherTab.key);
        } else if (defaultTab) {
          handleTabClick(defaultTab);
        }
      }
    }
  }, [tabs, isFirstRun, defaultTabSwitchingEnabled]);

  const tabIsSelected = (tab: string) => value === tab;

  return (
    <div
      data-testid="header"
      css={[tw`flex flex-row`, !borderBottom && tw`mb-base`]}
    >
      {tabs.map((tab) => (
        <button
          data-testid={`tab-${tab.key}`}
          key={tab.key}
          type="button"
          disabled={tab.disabled}
          onClick={() => handleTabClick(tab.key)}
          css={[
            tw`focus:outline-none disabled:cursor-not-allowed border-b-2 border-border-primary`,
            tw`flex flex-row items-center p-base -mb-2 font-normal text-18 text-text-tertiary`,
            tabIsSelected(tab.key) &&
              (isNewThemeApplied
                ? tw`border-b-4 border-border-action text-text-action font-semibold`
                : tw`border-b-4 border-foreground text-foreground`),
            tabStyle && tabStyle(tabIsSelected(tab.key)),
            styles,
          ]}
        >
          {tab.name}
          {tab.count ? (
            <span
              css={[
                tw`px-small bg-background-secondary rounded-full text-p ml-tiny font-semibold text-text-action`,
                !isNewThemeApplied &&
                  tw`px-16 py-2 ml-6 rounded-30 bg-grey04 text-ps bg-grey04 text-grey02 font-normal`,
                tabIsSelected(tab.key) &&
                  (isNewThemeApplied
                    ? tw`bg-text-action text-text-inverted-primary`
                    : tw`text-white bg-primary`),
              ]}
              data-testid={
                tabIsSelected(tab.key) ? 'selected-span' : 'unselected-span'
              }
            >
              {tab.count}
            </span>
          ) : null}
          {tab.text ? (
            <span
              css={[
                tw`px-small bg-background-brand rounded-full text-pxs ml-tiny font-semibold text-text-inverted-primary`,
                !isNewThemeApplied &&
                  tw`px-16 py-2 ml-6 rounded-30 bg-grey04 text-ps bg-grey04 text-grey02 font-normal`,
                tabIsSelected(tab.key) &&
                  tw`bg-background-brand text-text-inverted-primary`,
                tabIsSelected(tab.key) &&
                  !isNewThemeApplied &&
                  tw`text-white bg-primary`,
              ]}
              data-testid={
                tabIsSelected(tab.key) ? 'selected-span' : 'unselected-span'
              }
            >
              {tab.text}
            </span>
          ) : null}
          {tab.icon && (
            <Icon
              name={tab.icon}
              tw="ml-6"
              fill={
                tabIsSelected(tab.key)
                  ? theme`colors.primary`
                  : theme`colors.grey02`
              }
            />
          )}
        </button>
      ))}
    </div>
  );
};

export default TableHead;
