/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import {
  Card,
  ComboBox,
  Icon,
  Loader,
  SpinnerType,
  TextInput,
  ThemeContext,
} from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import Radio from 'common/components/radio';
import { payFrequencyLabel } from 'common/constants/default-labels';
import getPaymentFrequenciesForRateFrequency from 'contract-onboarding/services/get-payment-frequencies-for-rate-frequency';

import {
  Contract,
  CountryLabourStandards,
  FixedPayComponent,
  Maybe,
  PayFrequency,
} from '__generated__/graphql';

import { useGetCountryLabourStandards } from '../../hooks';
import PaymentInfo from './components/payout-info';

const CardSelectOptions = tw.div`bg-background-white rounded-base overflow-hidden shadow-high`;

const PayrollFrequencySelectorItem: React.FC<{
  frequency: PayFrequency;
  paymentFrequency: Maybe<PayFrequency> | undefined;
  setPaymentFrequency: (frequency: PayFrequency) => void;
}> = ({ frequency, paymentFrequency, setPaymentFrequency }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <Radio
      value={frequency}
      checked={paymentFrequency === frequency}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
      onClick={() => setPaymentFrequency(frequency)}
      labelStyles={[
        tw`border-border-secondary items-start rounded-base`,
        paymentFrequency === frequency
          ? !isNewThemeApplied
            ? tw`bg-grey05`
            : tw`bg-background-primary`
          : tw`bg-background-white`,
      ]}
    >
      <div tw="text-text-primary font-semibold">
        {payFrequencyLabel[frequency]}
      </div>
      <div tw="mt-extra-small">
        <PaymentInfo paymentFrequency={frequency} />
      </div>
    </Radio>
  );
};

export const PayrollFrequencySelector: React.FC<{
  paymentFrequency?: FixedPayComponent['paymentFrequency'];
  setPaymentFrequency: (frequency: PayFrequency) => void;
  baseFrequency: FixedPayComponent['frequency'];
  countryCompensationStandards: CountryLabourStandards['compensation'];
  loading?: boolean;
  simpleSelector?: boolean;
}> = ({
  paymentFrequency,
  setPaymentFrequency,
  baseFrequency,
  countryCompensationStandards,
  loading = false,
  simpleSelector = false,
  ...props
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const isContractOnboardingHourlyPayEnabled = useFeature(
    'contract-onboarding-hourly-pay',
  )?.on;

  const supportedPaymentFrequencies =
    useMemo(() => {
      const supportedFrequencies = getPaymentFrequenciesForRateFrequency(
        countryCompensationStandards?.paymentFrequency?.data,
        baseFrequency as string,
      );
      return isContractOnboardingHourlyPayEnabled && // true
        supportedFrequencies?.payFrequencies?.length // true
        ? supportedFrequencies?.payFrequencies?.filter(notEmpty)
        : countryCompensationStandards?.paymentFrequency?.list?.filter(
            notEmpty,
          );
    }, [countryCompensationStandards, baseFrequency]) ?? [];

  useEffect(() => {
    if (
      isContractOnboardingHourlyPayEnabled &&
      countryCompensationStandards?.paymentFrequency?.defaultValue &&
      !paymentFrequency
    ) {
      setPaymentFrequency(
        countryCompensationStandards?.paymentFrequency
          ?.defaultValue as PayFrequency,
      );
    }
  }, [countryCompensationStandards]);

  if (loading) {
    return (
      <Card tw="p-base" data-testid="loading">
        <Loader.Spinner tw="h-[129px]" variant={SpinnerType.CUSTOM_LAYOUT} />
      </Card>
    );
  }

  if (simpleSelector) {
    return (
      <ComboBox
        data-testid="payment-frequency-input"
        variant="default"
        value={paymentFrequency ?? ''}
        dropdownValues={(supportedPaymentFrequencies ?? [])?.map(
          (frequency) => ({
            value: frequency,
            title: payFrequencyLabel[frequency],
            label: payFrequencyLabel[frequency],
          }),
        )}
        onChange={(val) => setPaymentFrequency(val as PayFrequency)}
        placeholder={t(
          `definition-phase.compensation.payment-frequency.placeholder`,
          'Select Payroll frequency',
        )}
        {...props}
      />
    );
  }

  if (!isContractOnboardingHourlyPayEnabled) return null;

  if (supportedPaymentFrequencies?.length < 2) {
    if (
      paymentFrequency &&
      supportedPaymentFrequencies.includes(paymentFrequency)
    )
      return (
        <Card tw="p-base" data-testid="payout-info">
          <PaymentInfo paymentFrequency={paymentFrequency} detailed />
        </Card>
      );
    return null;
  }

  return (
    <CardSelectOptions data-testid="payment-frequency-input">
      {!paymentFrequency ? (
        <div tw="bg-background-negative-subtle text-text-negative text-pxs font-medium p-extra-small flex flex-row items-center gap-x-extra-small">
          <Icon
            name="error-reverse"
            width="14"
            fill={theme`colors.icon-negative`}
          />
          <span>
            {t(
              'definition-phase.compensation.payment-frequency.error',
              'Select one of the options below',
            )}
          </span>
        </div>
      ) : null}
      <div tw="p-[20px]">
        <TextInput.Container tw="mb-base gap-y-none">
          <TextInput.Label>
            {t(
              'definition-phase.compensation.payment-frequency.label',
              'Choose a Payroll Frequency',
            )}
          </TextInput.Label>
          <TextInput.Helper>
            {t(
              'definition-phase.compensation.payment-frequency.description',
              'Select how frequently employee receives salary',
            )}
          </TextInput.Helper>
        </TextInput.Container>
        <div tw="flex gap-x-base">
          {supportedPaymentFrequencies?.map((frequency) => (
            <PayrollFrequencySelectorItem
              key={frequency}
              paymentFrequency={paymentFrequency}
              frequency={frequency}
              setPaymentFrequency={setPaymentFrequency}
            />
          ))}
        </div>
      </div>
    </CardSelectOptions>
  );
};

const PayrollFrequencySelectorWrapper: React.FC<{
  paymentFrequency?: FixedPayComponent['paymentFrequency'];
  setPaymentFrequency: (frequency: PayFrequency) => void;
  baseFrequency: FixedPayComponent['frequency'];
  country: Contract['country'];
  countryStateCode: Contract['countryStateCode'];
}> = ({
  paymentFrequency,
  setPaymentFrequency,
  baseFrequency,
  country,
  countryStateCode,
}) => {
  const { loading, data } = useGetCountryLabourStandards({
    country,
    countryStateCode,
  });

  return (
    <PayrollFrequencySelector
      paymentFrequency={paymentFrequency}
      setPaymentFrequency={setPaymentFrequency}
      baseFrequency={baseFrequency}
      countryCompensationStandards={
        data?.country?.labourStandards?.compensation
      }
      loading={loading}
    />
  );
};

export default PayrollFrequencySelectorWrapper;
