import { TFunction } from 'i18next';

import { Contract, ContractOnboardingStep } from '__generated__/graphql';

import ProgressWidgetContentVariant from '../../../models/progress-widget-content-variant';
import onboardingContract from './onboarding-step-config/onboarding-contract';
import onboardingContractPreparingConfirmation from './onboarding-step-config/onboarding-contract-preparing-confirmation';
import onboardingContractUpdating from './onboarding-step-config/onboarding-contract-updating';
import onboardingContractWetInkPreparing from './onboarding-step-config/onboarding-contract-wet-ink-preparing';
import onboardingContractWetInkPreparingConfirmation from './onboarding-step-config/onboarding-contract-wet-ink-preparing-confirmation';
import onboardingMember from './onboarding-step-config/onboarding-member';
import onboardingReview from './onboarding-step-config/onboarding-review';
import onboardingSigning from './onboarding-step-config/onboarding-signing';
import onboardingVerifying from './onboarding-step-config/onboarding-verifying';
import visaApplication from './onboarding-step-config/visa-application';
import visaEligibility from './onboarding-step-config/visa-eligibility';

export type ContractOnboardingStepConfig = {
  title: (
    t: TFunction<'contract-onboarding.company'>,
    contractType?: Contract['type'],
  ) => string;
  status: (
    t: TFunction<'contract-onboarding.company'>,
    contractType?: Contract['type'],
  ) => string;
  variant: ProgressWidgetContentVariant;
};

const stepConfig: {
  [key: string]: ContractOnboardingStepConfig;
} = {
  [ContractOnboardingStep.ONBOARDING_REVIEW]: onboardingReview,
  [ContractOnboardingStep.ONBOARDING_CONTRACT_PREPARING_CONFIRMATION]: onboardingContractPreparingConfirmation,
  [ContractOnboardingStep.ONBOARDING_CONTRACT_WET_INK_PREPARING_CONFIRMATION]: onboardingContractWetInkPreparingConfirmation,
  [ContractOnboardingStep.ONBOARDING_CONTRACT_WET_INK_PREPARING]: onboardingContractWetInkPreparing,
  [ContractOnboardingStep.VISA_ELIGIBILITY]: visaEligibility,
  [ContractOnboardingStep.ONBOARDING_CONTRACT]: onboardingContract,
  [ContractOnboardingStep.VISA_APPLICATION]: visaApplication,
  [ContractOnboardingStep.ONBOARDING_CONTRACT_UPDATING]: onboardingContractUpdating,
  [ContractOnboardingStep.ONBOARDING_SIGNING]: onboardingSigning,
  [ContractOnboardingStep.ONBOARDING_MEMBER]: onboardingMember,
  [ContractOnboardingStep.ONBOARDING_VERIFYING]: onboardingVerifying,
};

export default stepConfig;
