/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from 'common/components/text-input';

import { ContractType, DataFieldDefinition } from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '..';

interface AddressInputProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
  requirementMap?: { [key: string]: DataFieldDefinition };
  contractType?: ContractType | null;
}

const AddressInput: React.FC<AddressInputProps> = ({
  methods,
  requirementMap,
  contractType,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    register,
    formState: { errors, defaultValues },
  } = methods;

  const isContractor = contractType === ContractType.CONTRACTOR;

  return (
    <div tw="grid grid-cols-2 gap-x-base gap-y-large">
      <TextInput.Container>
        <TextInput.Label htmlFor="current-address">
          {t('member.basic-details.current-address.label', 'Current Address')}
        </TextInput.Label>
        <TextInput
          id="current-address"
          placeholder={t(
            'member.basic-details.current-address.placeholder',
            'Lincoln Road, 40',
          )}
          {...register('currentAddress')}
          disabled={
            (requirementMap?.['address.line1']?.editable === false &&
              !!defaultValues?.currentAddress) ||
            isContractor
          }
          error={!!errors.currentAddress}
        />
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label htmlFor="address-line-2">
          {t(
            'member.basic-details.address-line-2.label',
            'Address Line 2 (Optional)',
          )}
        </TextInput.Label>
        <TextInput
          id="address-line-2"
          placeholder={t(
            'member.basic-details.address-line-2.placeholder',
            'Unit 02-15',
          )}
          {...register('addressLine2')}
          disabled={
            (requirementMap?.['address.line2']?.editable === false &&
              !!defaultValues?.addressLine2) ||
            isContractor
          }
          error={!!errors.addressLine2}
        />
      </TextInput.Container>
    </div>
  );
};

export default AddressInput;
