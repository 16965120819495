/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, TextInput } from '@multiplier/common';
import isUndefined from 'lodash/isUndefined';

import { RenderFormItem } from 'contract-onboarding/components/bank-details-section/components/dynamic-bank-account-details';
import { getDynamicFields } from 'contract-onboarding/member/services/bank-data';

import { Contract, PaymentAccountRequirement } from '__generated__/graphql';

import 'twin.macro';

import {
  BankDetailsFormParams,
  accountRequirementTypeLabel,
} from '../../index';

const DynamicDetailsForm: React.FC<{
  paymentAccountRequirementsMap: Map<string, PaymentAccountRequirement>;
  currency: Contract['currency'];
  bankDetailObject: Record<string, string>;
  contractType: Contract['type'];
  selectedAccountRequirementType: string | undefined;
}> = ({
  paymentAccountRequirementsMap,
  currency,
  bankDetailObject,
  contractType,
  selectedAccountRequirementType,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const { control, unregister } = useFormContext<BankDetailsFormParams>();

  useEffect(
    () => () => {
      unregister('bankData');
    },
    [],
  );

  const dynamicFieldsToRender = useMemo(
    () =>
      !isUndefined(selectedAccountRequirementType)
        ? Array.from(
            getDynamicFields(
              paymentAccountRequirementsMap.get(selectedAccountRequirementType)
                ?.requirementFields,
            )?.values() ?? [],
          )
        : [],
    [paymentAccountRequirementsMap, selectedAccountRequirementType],
  );

  return (
    <>
      {paymentAccountRequirementsMap?.size > 1 && (
        <TextInput.Container>
          <TextInput.Label>
            {t(
              'bank-details.payment-account-requirement-type.label',
              'Choose option that best describes your bank account',
            )}
          </TextInput.Label>
          <Controller
            name="paymentAccountRequirementType"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="payment-account-requirement-type"
                variant="default"
                dropdownValues={Array.from(
                  paymentAccountRequirementsMap.keys(),
                ).map((data) => ({
                  value: data,
                  title: accountRequirementTypeLabel({
                    targetCurrency: currency,
                    requirementType: data,
                    t,
                  }),
                  label: data,
                }))}
                placeholder={t(
                  'bank-details.payment-account-requirement-type.placeholder',
                  'Bank Account Type',
                )}
                value={value as string}
                onChange={onChange}
              />
            )}
          />
        </TextInput.Container>
      )}
      {dynamicFieldsToRender?.map((field, index) => (
        <RenderFormItem
          key={field?.key}
          field={field}
          index={index}
          bankDetailObject={bankDetailObject}
          isEditing
          contractType={contractType}
          source="details-collect-page"
        />
      ))}
    </>
  );
};

export default DynamicDetailsForm;
