import { gql } from '@apollo/client';

export default gql`
  mutation CheckHash($username: String, $hash: String) {
    checkHash(username: $username, hash: $hash)
      @rest(
        type: "HashValidity"
        method: "GET"
        path: "{args.username}/{args.hash}/validity"
        endpoint: "user"
      ) {
      validity
    }
  }
`;
