/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Row,
  TableInstance,
  useExpanded,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useMedia } from 'react-use';

import {
  Card,
  Checkbox,
  Table as CommonTable,
  Icon,
  ThemeContext,
} from '@multiplier/common';
import tw, { css, theme } from 'twin.macro';

import { useSearchParams, useUpdateSearchParams } from 'app/hooks';
import { classified } from 'app/security';

import Loader from '../loader';
import MobileTable from './components/mobile-table';
import Pagination, { PaginationVariant } from './components/pagination';

export type TableDataObjectType = {
  [key: string]:
    | string
    | number
    | React.ReactElement
    | React.ReactNode
    | undefined;
};

export enum TableVariantType {
  MODAL = 'MODAL',
}

interface TableBodyProps {
  data: TableDataObjectType[] | [];
  mobileData?: TableDataObjectType[] | [];
  columns: {
    Header: string;
    accessor: string;
    disableSortBy?: boolean;
    width?: string | number;
  }[];
  disableSortBy?: boolean;
  defaultRows?: number;
  handleSort?: (sortBy: unknown) => void;
  renderMobileItem?: (i: TableDataObjectType) => React.ReactElement;
  handleRowClick?: (i: TableDataObjectType) => void;
  widget?: React.ReactElement;
  handleRowSelect?: (ids: string[]) => void;
  selectedIds?: string[];
  tableVariant?: TableVariantType;
  horizontalScroll?: boolean;
}

interface BaseTableProps
  extends Omit<
    TableBodyProps,
    'disableSortBy' | 'renderMobileItem' | 'mobileData'
  > {
  disableSortBy: boolean;
}

interface TableHeadProps {
  tabs: { name: string; key: string; count?: number }[];
  value: string;
  onChange: (val: string) => void;
  defaultTab?: string;
}

const Table: React.FC<React.PropsWithChildren<{ loading?: boolean }>> & {
  Body: React.FC<TableBodyProps>;
  Head: React.FC<TableHeadProps>;
} = ({ loading = false, children, ...props }) => (
  <div data-testid="table" tw="w-full" {...props}>
    {loading ? <Loader.Table /> : children}
  </div>
);

/**
 * NOTE: Right now we still need to use this table for Team view due to it special grouping behavior
 * Will need to re-check and ensure TableV2 can handle that case; then do refactor later
 */
const BaseTable = ({
  data,
  columns,
  disableSortBy,
  handleSort,
  handleRowClick,
  defaultRows = 5,
  widget,
  handleRowSelect,
  selectedIds,
  tableVariant,
  horizontalScroll,
  ...props
}: BaseTableProps) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const [rows, currentPage, sort, sortDir] = useSearchParams(
    'rows',
    'page',
    'sortBy',
    'sortDir',
  );
  const updateSearchParams = useUpdateSearchParams();
  const selectedIdsRef = useRef<string[]>([]);

  useEffect(() => {
    if (selectedIds) {
      selectedIdsRef.current = selectedIds;
    }
  }, [selectedIds]);

  const tableInstance: TableInstance<TableDataObjectType> = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      disableSortBy,
      initialState: {
        pageSize: Number(defaultRows ?? rows),
        pageIndex:
          tableVariant === TableVariantType.MODAL
            ? 0
            : Number(currentPage ?? 1) - 1,
        sortBy: sort
          ? [{ id: sort, desc: sortDir === 'desc' }]
          : [{ id: 'onboardingBucketNumber', desc: true }],
        groupBy: ['onboardingBucket'],
        hiddenColumns: ['onboardingBucket', 'onboardingBucketNumber'],
      },
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (handleRowSelect) {
        hooks.visibleColumns.push((visibleColumns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <Checkbox
                  {...getToggleAllRowsSelectedProps()}
                  onChange={(e) => {
                    tableInstance.toggleAllRowsSelected(e.target.checked);
                    if (e.target.checked) {
                      handleRowSelect(
                        tableInstance.rows
                          .filter((row) => row.original.id)
                          .map((row) => String(row.original.id)),
                      );
                    } else {
                      handleRowSelect([]);
                    }
                  }}
                />
              </div>
            ),
            Cell: ({ row }: { row: Row }) => (
              <div>
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    tableInstance.toggleRowSelected(row.id, e.target.checked);
                    if (
                      e.target.checked &&
                      (row.original as { id: string }).id
                    ) {
                      handleRowSelect([
                        ...(selectedIdsRef.current ?? []),
                        String((row.original as { id: string }).id),
                      ]);
                    } else {
                      handleRowSelect(
                        selectedIdsRef.current?.filter(
                          (id) => id !== (row.original as { id: string }).id,
                        ),
                      );
                    }
                  }}
                />
              </div>
            ),
          },
          ...visibleColumns,
        ]);
      }
    },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleRowSelected,
    // setGroupBy,
    state: { sortBy },
    toggleAllRowsExpanded,
    isAllRowsExpanded,
  } = tableInstance;

  React.useEffect(() => toggleAllRowsExpanded(true), [data, isAllRowsExpanded]);

  useEffect(() => {
    if (handleSort) {
      handleSort(sortBy);
      if (sortBy.length > 0) {
        updateSearchParams([
          {
            key: 'sortBy',
            value: sortBy[0].id,
          },
          {
            key: 'sortDir',
            value: sortBy[0].desc ? 'desc' : 'asc',
          },
        ]);
      }
    }
  }, [sortBy]);

  useEffect(() => {
    data.forEach((item, idx) => {
      if (selectedIds?.includes((item as { id: string })?.id)) {
        toggleRowSelected(String(idx), true);
      }
    });
  }, [data, selectedIds]);

  return (
    <Card css={[horizontalScroll && tw`overflow-x-auto`]} {...props}>
      {widget}
      <table {...getTableProps()} tw="w-full">
        {headerGroups.map((headerGroup) => (
          <colgroup {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <col
                {...column.getHeaderProps()}
                css={[
                  tw`w-auto`,
                  css`
                    width: ${column.width};
                  `,
                ]}
              />
            ))}
          </colgroup>
        ))}
        <thead css={[tableVariant === TableVariantType.MODAL && tw`hidden`]}>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              tw="text-ps leading-normal"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  css={[
                    tw`pr-large py-large font-normal text-left text-text-tertiary first-of-type:pl-large last-of-type:pr-large`,
                    !isNewThemeApplied && tw`text-grey02`,
                  ]}
                  data-testid={`columnheader-${column.id}`}
                >
                  <div
                    css={[
                      tw`flex flex-row items-center`,
                      column.id === 'actions' && tw`justify-end`,
                    ]}
                  >
                    <span>{column.render('Header')}</span>
                    {column.canSort && !column.isSorted && (
                      <div tw="flex flex-col opacity-30">
                        <Icon name="caret-up" tw="inline-block ml-tiny" />
                        <Icon name="caret-down" tw="inline-block ml-tiny" />
                      </div>
                    )}
                    {column.isSorted && (
                      <div tw="inline opacity-50">
                        {column.isSortedDesc ? (
                          <Icon
                            name="caret-down"
                            tw="inline-block mb-tiny ml-tiny"
                          />
                        ) : (
                          <Icon
                            name="caret-up"
                            tw="inline-block mb-tiny ml-tiny"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          data-testid="table-body"
          css={[
            tw`text-p text-text-primary mobile:border-none border-t border-border-primary`,
            !isNewThemeApplied && tw`border-grey04`,
          ]}
          {...classified}
        >
          {page.map((row) => {
            prepareRow(row);
            return row?.isGrouped ? (
              <tr
                css={[
                  tw`h-[52px] bg-background-primary border border-x-0 border-border-primary text-pxs text-text-secondary font-bold`,
                  !isNewThemeApplied &&
                    tw`bg-slateGrey100 border-slateGrey200 text-slateGrey500`,
                ]}
                key={`row-${row.id}`}
              >
                <td tw="pl-base" colSpan={row.cells.length}>
                  {row.values.onboardingBucket}
                </td>
              </tr>
            ) : (
              <tr
                {...row.getRowProps()}
                key={`row-${row.id}`}
                data-testid={`row-${row.id}`}
                css={[
                  tw`text-left even-of-type:(bg-background-primary)`,
                  !isNewThemeApplied &&
                    tw`text-left even-of-type:(bg-grey04 bg-opacity-20)`,
                  handleRowClick &&
                    tw`hover:(cursor-pointer bg-background-secondary)`,
                  !isNewThemeApplied &&
                    handleRowClick &&
                    tw`hover:(cursor-pointer bg-grey04 bg-opacity-50)`,
                ]}
                onClick={() => handleRowClick && handleRowClick(row?.original)}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    tw="relative py-base pr-large text-ps text-left whitespace-nowrap first-of-type:pl-large last-of-type:pr-large"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length > 5 && tableVariant !== TableVariantType.MODAL && (
        <Pagination tableInstance={tableInstance} />
      )}
      {data.length > 2 && tableVariant === TableVariantType.MODAL && (
        <Pagination
          tableInstance={tableInstance}
          variant={PaginationVariant.MODAL}
        />
      )}
    </Card>
  );
};

Table.Body = ({
  data,
  mobileData,
  columns,
  tableVariant,
  disableSortBy = true,
  handleSort,
  renderMobileItem,
  handleRowClick,
  defaultRows,
  widget,
  handleRowSelect,
  selectedIds,
  horizontalScroll,
}) => {
  const isMobile = useMedia(`(max-width: ${theme`screens.mobile.max`})`);

  // This is a temporary solution to force a rerender which can be removed once pagination is handled in the BE
  const [mobileTableKey, setMobileTableKey] = useState(0);
  useEffect(() => {
    setMobileTableKey((prev) => prev + 1);
  }, [data]);

  return isMobile && renderMobileItem ? (
    <MobileTable
      data={mobileData ?? data}
      renderItem={renderMobileItem}
      key={mobileTableKey}
    />
  ) : (
    <BaseTable
      widget={widget}
      data={data}
      columns={columns}
      tableVariant={tableVariant}
      disableSortBy={disableSortBy}
      handleSort={handleSort}
      handleRowClick={handleRowClick}
      defaultRows={defaultRows}
      handleRowSelect={handleRowSelect}
      selectedIds={selectedIds}
      horizontalScroll={horizontalScroll}
    />
  );
};

Table.Head = CommonTable.Head;

export default Table;
