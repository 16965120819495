import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';
import { handlers } from '@multiplier/notifications';

import { useSendReminderForContractSignatureMutation } from '../../../../__generated__/graphql';
import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const { errorNotification, successNotification } = handlers;

const SignContractTask: React.FC<TaskEntryProps> = ({
  task,
  contractId,
  actionable,
}) => {
  const { t } = useTranslation('contract-onboarding');

  const [onResend, { loading }] = useSendReminderForContractSignatureMutation({
    onError() {
      errorNotification(
        '',
        t('resend-contract.error.message', 'Failed to re-send contract'),
        false,
      );
    },
    onCompleted() {
      successNotification(
        t('resend-contract.notification.title', 'Success'),
        t('resend-contract.notification.subtitle', {
          defaultValue: 'Re-sent contract',
        }),
        false,
      );
    },
  });

  const resendContract = async () => {
    if (!contractId) {
      return;
    }

    await onResend({
      variables: {
        id: contractId,
      },
    });
  };

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.sign-contract.title',
        'Sign contract',
      )}
      completed={task.completed}
      action={
        <Button
          size="small"
          tw="text-ps"
          variant="secondary"
          loading={loading}
          onClick={resendContract}
          disabled={!actionable}
        >
          {t(
            'onboarding-checklist.task-list.resend-contract.action',
            'Re-send contract',
          )}
        </Button>
      }
    />
  );
};

export default SignContractTask;
