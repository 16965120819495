/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useTranslation } from 'react-i18next';

import { CardSeparator } from '@multiplier/common';

import DocumentViewer from 'common/components/document-viewer';

import { Contract } from '__generated__/graphql';

import { ContractPageType } from '../progress-widget-content/view-send-contract-action';
import { RequestedChangesContentBlock } from './view-requested-changes';

interface RequestChangesModalProps {
  open: boolean;
  onClose: () => void;
  requestedChangeData?: string;
  loading: boolean;
  contractId: Contract['id'];
}

const RequestChangesModal: React.FC<RequestChangesModalProps> = ({
  open,
  onClose,
  requestedChangeData,
  loading,
  contractId,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <DocumentViewer
      open={open}
      onClose={onClose}
      header={t('request-changes-viewer.header', 'Requested Changes')}
      contractModalPageType={ContractPageType.MAIN_PAGE}
      loading={loading}
    >
      <div tw="flex flex-col px-72">
        <RequestedChangesContentBlock contractId={contractId} />
      </div>
      <CardSeparator />
      <div tw="flex px-72 text-p text-primaryBlue800 whitespace-pre-line">
        {requestedChangeData}
      </div>
    </DocumentViewer>
  );
};

export default RequestChangesModal;
