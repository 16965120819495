import { useGetLatestValidBirthdate } from '@multiplier/contract-onboarding';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';

import { Experience } from 'app/models/module-config';
import { getLegalDataSchema } from 'contract-onboarding/services/legal-data';

import { DataFieldDefinition, Gender } from '__generated__/graphql';

import { fileSchema } from '../../app/utils/file-schema-utils';

const useGetBasicDetailsSectionSchema = (
  experience: Experience,
  requireDocument: boolean,
  t: TFunction,
  legalData: {
    [p: string]: DataFieldDefinition;
  },
): yup.ObjectSchema<{
  firstName: yup.StringSchema;
  lastName: yup.StringSchema;
  gender: yup.StringSchema;
  email?: yup.StringSchema;
  fullLegalName?: yup.StringSchema;
  dateOfBirth?: yup.DateSchema;
  nationality?: yup.StringSchema;
  legalData?: yup.ArraySchema<
    yup.ObjectSchema<{
      key: yup.StringSchema;
      value: Lazy<
        yup.StringSchema<string | null | undefined> | yup.NumberSchema
      >;
    }>
  >;
}> => {
  const latestValidBirthdate = useGetLatestValidBirthdate();

  return experience === Experience.MEMBER
    ? yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string(),
        gender: yup.string().oneOf(Object.keys(Gender)).required(),
        fullLegalName: yup.string().required(),
        dateOfBirth: yup.date().when('$shouldShowDOB', {
          is: true,
          then: (schema) =>
            schema
              .required(
                t(
                  'member.basic-details.date-of-birth.required',
                  'Date Of Birth is required',
                ),
              )
              .test(
                'is_less_than_max_date',
                t(
                  'basic-details.validation.date.max',
                  'Date is after allowed range',
                ),
                (val) => (val ? val <= latestValidBirthdate : false),
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        nationality: yup
          .string()
          .required(
            t(
              'member.basic-details.nationality.required',
              'Nationality is required',
            ),
          ),
        legalData: getLegalDataSchema(legalData, t).fields?.legalData,
        documentsProof: yup.lazy(() => {
          if (requireDocument) {
            return yup
              .array()
              .of(fileSchema)
              .min(
                1,
                t(
                  'member.basic-details.documents.required',
                  'Identity proof is required',
                ),
              );
          }
          return yup.array().of(fileSchema).nullable();
        }),
      })
    : yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string(),
        gender: yup.string().oneOf(Object.keys(Gender)).required(),
        email: yup.string().email().required(),
      });
};

export default useGetBasicDetailsSectionSchema;
