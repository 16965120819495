import { useNavigate } from 'react-router-dom';

import { Experience } from 'app/models/module-config';
import { getNextStep } from 'contract-onboarding/utils/routing';

import {
  ContractOnboarding,
  ContractOnboardingStatus,
  ContractOnboardingStep,
  Maybe,
} from '__generated__/graphql';

import stepConfig from '../pages/onboarding/step-config';
import routes from '../routes';

const useStartMemberOnboarding = (
  id?: Maybe<string>,
  onboarding?: Maybe<ContractOnboarding>,
): (() => void) => {
  const navigate = useNavigate();

  return () => {
    if (
      onboarding?.status !== ContractOnboardingStatus.MEMBER_STARTED &&
      onboarding?.status !== ContractOnboardingStatus.VISA_DOCUMENTS_STARTED
    ) {
      navigate(`/${Experience.MEMBER}/${routes.root}/${routes.verification}`);
    } else if (id) {
      const next =
        getNextStep(
          onboarding,
          onboarding.current ?? ContractOnboardingStep.MEMBER_WELCOME,
        ) ?? ContractOnboardingStep.MEMBER_BASIC_DETAILS;
      if (next && stepConfig[next]) {
        navigate(stepConfig[next].directLink());
      }
    }
  };
};

export default useStartMemberOnboarding;
