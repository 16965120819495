import React, { useContext, useMemo } from 'react';

import { isAfter, isBefore } from 'date-fns';
import tw from 'twin.macro';

import { ThemeContext } from '../../../theme-provider';
import ToolTip from '../../../tooltip';
import { HighlightType } from '../../../types';
import { checkIfSameDay } from '../../utils/calendar-utils';

interface CalendarDate {
  date?: Date;
  selectedDate?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  highlight?: HighlightType;
  disableDate?: (d: Date) => boolean;
  onClick?: (d: Date) => void;
}

const currentDate = new Date();

const CalendarDateComponent: React.FC<CalendarDate> = ({
  selectedDate,
  highlight,
  date,
  minDate,
  maxDate,
  disableDate,
  onClick,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const isDateNonActive = useMemo(() => {
    if (!date) return true;
    const isBeforeMinDate = minDate ? isBefore(date, minDate) : false;
    const isAfterMaxDate = maxDate ? isAfter(date, maxDate) : false;
    const isConstraintBroken = disableDate ? disableDate(date) : false;
    const highLightDisabled = Boolean(highlight?.disabled);
    return (
      isBeforeMinDate ||
      isAfterMaxDate ||
      isConstraintBroken ||
      highLightDisabled
    );
  }, [date, highlight, minDate, maxDate, disableDate]);

  const isToday = selectedDate && checkIfSameDay(date, currentDate);
  const isSelectedDay =
    checkIfSameDay(date, selectedDate) ||
    (!selectedDate && checkIfSameDay(date, currentDate));

  const getDateColor = () => {
    if (isSelectedDay) {
      return tw`text-text-inverted-primary`;
    }

    if (isNewThemeApplied && isToday && !isSelectedDay) {
      return tw`text-text-brand-primary`;
    }

    if (highlight?.type === 'bold') {
      return isNewThemeApplied ? tw`text-text-brand-primary` : tw`text-primary`;
    }

    if (isDateNonActive) {
      return isNewThemeApplied ? tw`text-text-tertiary` : tw`text-grey03`;
    }

    return tw`text-text-primary`;
  };

  const getDateButton = () => (
    <div
      css={[
        tw`flex justify-center items-center w-large h-large`,
        isToday && tw`rounded-full bg-background-brand-faded`,
        !isNewThemeApplied && isToday && tw`bg-grey05`,
        isSelectedDay && tw`rounded-full bg-background-brand`,
        !isNewThemeApplied && isSelectedDay && tw`bg-primary`,
      ]}
    >
      <button
        data-testid={date ? `date-${date.getDate()}` : ''}
        type="button"
        disabled={isDateNonActive ?? false}
        onClick={() => {
          if (onClick && date) onClick(date);
        }}
        css={[
          tw`border-none bg-transparent focus:outline-none`,
          !isNewThemeApplied && tw`pt-2`,
          isDateNonActive && tw`cursor-default`,
        ]}
      >
        <span
          css={[
            highlight?.type === 'bold' ? tw`font-medium` : '',
            highlight?.type === 'strike-through' ? tw`line-through` : '',
            getDateColor(),
          ]}
        >
          {date ? `${date.getDate()}` : ''}
        </span>
      </button>
    </div>
  );

  if (highlight?.hover) {
    return (
      <ToolTip content="" {...highlight?.hover}>
        {getDateButton()}
      </ToolTip>
    );
  }

  return getDateButton();
};

export default CalendarDateComponent;
