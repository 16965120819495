import React from 'react';

import { useFeature } from '@growthbook/growthbook-react';
import { usePermission } from '@multiplier/user';

import AppFeature from 'app/features';

import {
  Contract,
  ContractOffboardingStatus,
  ContractStatus,
} from '__generated__/graphql';

import LegacyOffboardingActions from './legacy-offboarding-actions';
import OffboardingActions from './offboarding-actions';
import OnboardingActions from './onboarding-actions';

const ActionCell: React.FC<{
  contractId: Contract['id'];
  contractStatus: Contract['status'];
  contractStarted: Contract['contractStarted'];
  contractBlocked: Contract['contractBlocked'];
  contractOffboarding: Contract['offboarding'];
  startOn: Contract['startOn'];
  endedOn: Contract['endedOn'];
  country: Contract['country'];
  handleDeleteContractClick?: () => void;
  handleCancelOffboardingClick?: () => void;
  handleLegacyCancelOffboardingClick?: () => void;
  handleLegacyOffboardingReschedule?: () => void;
}> = ({
  contractId,
  contractStatus,
  contractStarted,
  contractBlocked,
  contractOffboarding,
  startOn,
  endedOn,
  handleDeleteContractClick,
  handleCancelOffboardingClick,
  handleLegacyCancelOffboardingClick,
  handleLegacyOffboardingReschedule,
}) => {
  const isNewOffboardingEnabled = useFeature(
    AppFeature.CONTRACT_OFFBOARDING_WORKFLOW,
  ).on;

  const [
    canRescheduleCompanyContractOffboarding,
    canCancelCompanyContractOffboarding,
  ] = usePermission(
    'reschedule.company.contract.offboarding',
    'cancel.company.contract.offboarding',
  );

  if (
    (contractStatus === ContractStatus.ONBOARDING ||
      contractStatus === ContractStatus.ACTIVE) &&
    !contractOffboarding?.status &&
    handleDeleteContractClick
  ) {
    return (
      <OnboardingActions
        contractStarted={contractStarted}
        contractBlocked={contractBlocked}
        handleDeleteClick={handleDeleteContractClick}
      />
    );
  }

  if (
    contractStatus === ContractStatus.OFFBOARDING &&
    contractOffboarding == null &&
    handleLegacyOffboardingReschedule &&
    handleLegacyCancelOffboardingClick
  ) {
    return (
      <LegacyOffboardingActions
        handleEditClick={handleLegacyOffboardingReschedule}
        handleCancelClick={handleLegacyCancelOffboardingClick}
        startOn={startOn}
        endedOn={endedOn}
      />
    );
  }

  if (
    (canCancelCompanyContractOffboarding ||
      canRescheduleCompanyContractOffboarding) &&
    isNewOffboardingEnabled &&
    (contractOffboarding || contractStatus === ContractStatus.OFFBOARDING) &&
    contractOffboarding?.status !== ContractOffboardingStatus.COMPLETED &&
    handleCancelOffboardingClick
  ) {
    return (
      <OffboardingActions
        contractId={contractId}
        contractOffboarding={contractOffboarding}
        handleCancelClick={handleCancelOffboardingClick}
      />
    );
  }

  return null;
};

export default ActionCell;
