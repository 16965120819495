/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, ToolTip } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import * as format from 'app/utils/format';

import {
  CalculationEntry,
  CountryCode,
  CurrencyCode,
  Maybe,
  RateFrequency,
} from '__generated__/graphql';

import { salaryCalculationBreakdownLabels } from '../index';

const NestedEntry: React.FC<{
  name?: string | null;
  currency?: CurrencyCode | null;
  amount?: number | null;
  note?: string | null;
}> = ({ name, currency, amount, note }) => {
  const { i18n } = useTranslation('salary-calculator');
  return (
    <div data-testid="nested-entry" tw="flex flex-row justify-between mt-small">
      <div tw="w-3/4 flex flex-row items-center">
        <span>{name}</span>
        {note && (
          <ToolTip content={note}>
            <Icon
              name="info"
              tw="ml-8 w-small h-small"
              fill={theme`colors.icon-tertiary`}
            />
          </ToolTip>
        )}
      </div>
      <div>{`${currency} ${format.numberAsCurrency(
        i18n.language,
        amount,
      )}`}</div>
    </div>
  );
};
const BreakdownEntry: React.FC<
  React.PropsWithChildren<{
    name?: string | null;
    currency?: CurrencyCode | null;
    amount?: number | null;
    nestedEntries?: Maybe<
      Maybe<
        { __typename?: 'CalculationEntry' | undefined } & Pick<
          CalculationEntry,
          'name' | 'currency' | 'amount' | 'note'
        >
      >[]
    >;
    note?: string | null;
    frequency?: RateFrequency;
    country: CountryCode;
    minimal?: boolean;
    type: 'employee' | 'company';
  }>
> = ({
  name,
  currency,
  amount,
  nestedEntries,
  country,
  note,
  frequency,
  children,
  minimal,
  type,
}) => {
  const { t, i18n } = useTranslation('salary-calculator');
  const [showNested, setShowNested] = useState(false);

  const hasNestedEntries = nestedEntries && nestedEntries.length > 0;

  return (
    <div css={[tw`mb-large`, minimal && tw`mb-base`]}>
      <div tw="flex flex-row justify-between text-text-primary">
        <div tw="flex flex-row items-center">
          <span css={[tw`self-start`, minimal && tw`text-ps`]}>{name}</span>
          {note && (
            <ToolTip content={note}>
              <Icon
                name="info"
                tw="ml-8 w-small h-small"
                fill={theme`colors.icon-tertiary`}
              />
            </ToolTip>
          )}
          {hasNestedEntries && (
            <button
              data-testid="expand"
              css={[
                tw`ml-base rounded-full bg-background-primary w-large h-large`,
                tw`focus:(ring-transparent outline-none)`,
              ]}
              type="button"
              onClick={() => setShowNested((prev) => !prev)}
            >
              <Icon tw="m-auto" name={showNested ? 'caret-up' : 'caret-down'} />
            </button>
          )}
        </div>
        <div css={[tw`flex flex-col items-end`, minimal && tw`text-ps`]}>
          <span>{`${currency} ${format.numberAsCurrency(
            i18n.language,
            amount,
          )}`}</span>
          {children}
        </div>
      </div>
      {showNested && hasNestedEntries && (
        <div tw="text-ps text-text-primary border-border-primary border-l pl-base">
          {nestedEntries?.map((entry) => (
            <NestedEntry
              key={entry?.name}
              name={
                entry?.name && salaryCalculationBreakdownLabels[entry.name]
                  ? salaryCalculationBreakdownLabels[entry.name](t)
                  : t(`breakdown.${country?.toLowerCase()}.${entry?.name}`)
              }
              currency={entry?.currency}
              amount={entry?.amount}
              note={
                entry?.note
                  ? t(
                      `breakdown.${country?.toLowerCase()}.notes.${frequency?.toLowerCase()}.${type}.${
                        entry?.name
                      }`,
                      entry.note,
                    )
                  : undefined
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BreakdownEntry;
