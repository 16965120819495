/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ComboBox,
  DatePicker,
  DropdownTextContainer,
  DropdownTextInput,
  DropdownValue,
  TextInput,
} from '@multiplier/common';
import { omit, unescape } from 'lodash';
import tw from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import useGetCountryAllowancesConfig from 'contract-onboarding/company/hooks/get-country-allowances-config';
import getMatchedHrAllowanceConfig from 'contract-onboarding/company/services/get-matched-hr-allowance-config';
import { useIsNewAllowancesConfigEnabled } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';
import timezoneRemove from 'timesheets/services/timezone-remove';

import {
  Contract,
  ContractType,
  FixedPayComponentInput,
  Maybe,
  PayAmountType,
  PayFrequency,
  RateFrequency,
} from '__generated__/graphql';

import {
  AdditionalPayFormValues,
  PaymentType,
  frequencyOptions,
} from '../../index';
import AmountType from '../amount-type';

const AllowancesFields: React.FC<{
  contractType: Contract['type'];
  countryCode: Contract['country'];
  currencyOptions: Maybe<DropdownValue[]>;
  maxDate?: Date | string;
  minDate?: Date | string;
  basePayPaymentFrequency?: FixedPayComponentInput['paymentFrequency'];
}> = ({
  contractType,
  countryCode,
  currencyOptions,
  maxDate,
  minDate,
  basePayPaymentFrequency,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const {
    control,
    register,
    setValue,
    watch,
  } = useFormContext<AdditionalPayFormValues>();

  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: contractType,
  });
  const { countryAllowancesConfig } = useGetCountryAllowancesConfig({
    country: countryCode,
    isNewAllowancesConfigEnabled,
  });

  const payLabelOptions = useMemo(
    () =>
      countryAllowancesConfig?.map(({ name, label }) => ({
        title: t(
          `additional-pay.label.hr-allowances.${name}-label`,
          unescape(label ?? name),
        ),
        value: name,
      })) ?? [],
    [countryAllowancesConfig, t],
  );

  const [payTypeValue, nameValue, labelValue] = useWatch({
    control,
    name: ['payType', 'name', 'label'],
  });

  useEffect(() => {
    if (!isNewAllowancesConfigEnabled) return;
    if (!basePayPaymentFrequency) setValue('frequency', RateFrequency.MONTHLY);
    if (basePayPaymentFrequency === PayFrequency.BIWEEKLY)
      setValue('frequency', RateFrequency.BI_WEEKLY);
    if (basePayPaymentFrequency === PayFrequency.SEMIMONTHLY)
      setValue('frequency', RateFrequency.SEMIMONTHLY);
    if (basePayPaymentFrequency === PayFrequency.MONTHLY)
      setValue('frequency', RateFrequency.MONTHLY);
  }, [basePayPaymentFrequency]);

  const matchedHrAllowanceConfig = getMatchedHrAllowanceConfig({
    isNewAllowancesConfigEnabled,
    countryAllowancesConfig,
    payName: nameValue,
    payLabel: labelValue,
  });

  const frequencyOptionsWithoutOnce = frequencyOptions.filter(
    ({ value }) => value !== RateFrequency.ONCE,
  );

  return (
    <>
      {isNotFreelancer ? (
        payTypeValue === PaymentType.ALLOWANCES &&
        (matchedHrAllowanceConfig ? (
          <TextInput.Container tw="col-span-3">
            <TextInput.Label>
              {t('additional-pay.label.label.allowances', 'Allowance Type')}
            </TextInput.Label>
            <Controller
              data-testid="pay-label"
              {...omit(register('label'), 'ref')}
              control={control}
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  data-testid="select-payLabel"
                  variant="default"
                  dropdownValues={payLabelOptions}
                  showArrow
                  value={value || ''}
                  placeholder={t(
                    'additional-pay.label.placeholder.allowances',
                    'Select Allowance Type',
                  )}
                  onChange={(selectedPayLabel) => {
                    const selectedPayTitle = payLabelOptions.find(
                      ({ value: payLabel }) => payLabel === selectedPayLabel,
                    )?.title;

                    setValue('defaultDisplayLabel', selectedPayTitle);

                    onChange(selectedPayLabel);
                  }}
                />
              )}
            />
          </TextInput.Container>
        ) : (
          <div tw="grid grid-cols-1">
            <TextInput.Container tw="col-span-3">
              <TextInput.Label>
                {t('additional-pay.name.label.allowances', 'Allowance Type')}
              </TextInput.Label>
              <TextInput data-testid="pay-name" {...register('name')} />
            </TextInput.Container>
          </div>
        ))
      ) : (
        <div tw="grid grid-cols-1">
          <TextInput.Container tw="col-span-3">
            <TextInput.Label>
              {t('additional-pay.name.label.allowances', 'Allowance Type')}
            </TextInput.Label>
            <TextInput data-testid="pay-name" {...register('name')} />
          </TextInput.Container>
        </div>
      )}
      {isNotFreelancer && !isNewAllowancesConfigEnabled && (
        <div tw="grid grid-cols-2 mobile:grid-cols-1">
          <TextInput.Container tw="col-span-3">
            <TextInput.Label>
              {t('additional-pay.name.label.amount-type', 'Amount Type')}
            </TextInput.Label>
            <div tw="flex mobile:flex-wrap py-extra-small gap-small">
              <AmountType
                variant={PayAmountType.FIXED_AMOUNT}
                description={
                  contractType === ContractType.CONTRACTOR
                    ? t(
                        'additional-pay.name.label.amount-type-fixed-contractor',
                        'Amount is fixed and will be automatically paid to contractor',
                      )
                    : t(
                        'additional-pay.name.label.amount-type-fixed',
                        'Amount is fixed and will be automatically paid to employee',
                      )
                }
                isRecommended
                dataTestId={`amount-type-${PayAmountType.FIXED_AMOUNT}`}
              />
              <AmountType
                variant={PayAmountType.VARIABLE_AMOUNT}
                description={t(
                  'additional-pay.name.label.amount-type-variable',
                  'Amount is subject to expense approvals before they are paid out',
                )}
                dataTestId={`amount-type-${PayAmountType.VARIABLE_AMOUNT}`}
              />
            </div>
          </TextInput.Container>
        </div>
      )}
      <div
        css={[
          tw`grid grid-cols-3 gap-base mobile:grid-cols-1`,
          isNewAllowancesConfigEnabled && tw`grid-cols-2`,
        ]}
      >
        <TextInput.Container>
          {isNotFreelancer ? (
            <TextInput.Label>
              {t('additional-pay.frequency.label-feature-on', 'Frequency')}
            </TextInput.Label>
          ) : (
            <TextInput.Label>
              {t('additional-pay.frequency.label', 'Frequency (Every-)')}
            </TextInput.Label>
          )}
          {isNotFreelancer ? (
            <Controller
              name="frequency"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  variant="default"
                  data-testid="allowance-pay-frequency-selector"
                  disabled={
                    frequencyOptionsWithoutOnce?.length === 1 ||
                    isNewAllowancesConfigEnabled
                  }
                  dropdownValues={frequencyOptionsWithoutOnce ?? []}
                  value={(value as string) ?? ''}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  placeholder={t('pay-frequency.placeholder', 'Select')}
                />
              )}
            />
          ) : (
            <TextInput
              data-testid="pay-frequency"
              type="number"
              placeholder="0"
              units={t('additional-pay.frequency.units', 'Months')}
              {...register('frequency')}
            />
          )}
        </TextInput.Container>
        <TextInput.Container>
          <TextInput.Label>
            {t('additional-pay.amount.label.default', 'Amount')}
          </TextInput.Label>
          <DropdownTextContainer tw="bg-background-white">
            <Controller
              name="currency"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  variant="inline"
                  data-testid="allowance-currency-selector"
                  disabled={currencyOptions?.length === 1}
                  dropdownValues={currencyOptions ?? []}
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder={t(
                    'additional-pay.amount.currency-placeholder',
                    'Select Currency',
                  )}
                />
              )}
            />
            <DropdownTextInput
              currency
              type="number"
              step="0.01"
              placeholder="0"
              tw="appearance-none"
              data-testid="pay-amount"
              divStyles={tw`flex-grow`}
              {...register('amount')}
            />
          </DropdownTextContainer>
          <CurrencyHelper
            tw="mt-6"
            referenceCurrency={watch('currency')}
            amount={watch('amount')}
          />
        </TextInput.Container>
        {!isNewAllowancesConfigEnabled && (
          <TextInput.Container>
            <TextInput.Label>
              {t(
                'additional-pay.payout-month.effective-from',
                'Effective from',
              )}
            </TextInput.Label>
            <DatePicker
              monthSelector
              value={
                watch('payoutMonth.month') + 1 && watch('payoutMonth.year')
                  ? timezoneRemove(
                      `${watch('payoutMonth.year')}-${
                        watch('payoutMonth.month') + 1
                      }-1`,
                      true,
                    )
                  : null
              }
              min={minDate}
              max={maxDate}
              onChange={(v) => {
                setValue(
                  'payoutMonth.month',
                  timezoneRemove(v, true).getMonth(),
                  {
                    shouldValidate: true,
                  },
                );
                setValue(
                  'payoutMonth.year',
                  timezoneRemove(v, true).getFullYear(),
                  {
                    shouldValidate: true,
                  },
                );
              }}
              placeholder={t(
                'additional-pay.payout-month.placeholder',
                'Month',
              )}
              rightAlign
            />
          </TextInput.Container>
        )}
      </div>
    </>
  );
};

export default AllowancesFields;
