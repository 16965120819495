/** @jsxImportSource @emotion/react */

import { WetInkOnboardingStatusHeaderDescription } from 'contract-onboarding/company/components/wet-ink-signature';
import { PendingOn } from 'contract-onboarding/components/pending-on-pill';
import OnboardingAction from 'contract-onboarding/models/onboarding-action';

import { ContractType } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isWetInkSignatureEnabled,
} from '../status-config';

const created: ContractOnboardingStatusConfig = {
  title: (t, name, contractType) =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.created-contractor.title',
          'Review and Send Order Form',
        )
      : isWetInkSignatureEnabled()
      ? t(
          'onboarding-phase.created-wetink.title',
          'Send contract for wet ink signatures',
        )
      : t('onboarding-phase.created.title', 'Send contract for signatures'),
  description: (t, firstName, gender, country, contractType) =>
    contractType === ContractType.CONTRACTOR ? (
      t('onboarding-phase.created-contractor.description-v2', {
        defaultValue:
          "Please review {{firstName}}'s agreement details and confirm them. Once you click Send Order Form, Multiplier will send the Order Form to your Signatory and after it's signed by the Signatory, the Independent Contractor Agreement will be sent to {{firstName}}.\n" +
          'For clarifications, please reach out to us via the chat bubble below.',
        replace: {
          firstName,
        },
        context: gender,
      })
    ) : isWetInkSignatureEnabled() ? (
      <WetInkOnboardingStatusHeaderDescription {...{ firstName, country }} />
    ) : (
      t('onboarding-phase.created.description', {
        defaultValue:
          'Invite {{firstName}} to join Multiplier and to collect details required for the completion of the contract. Once {{firstName}} provides his details we will verify and send you the completed contract between 1-2 working days.',
        replace: {
          firstName,
        },
        context: gender,
      })
    ),

  statusLabel: (t, contractType) =>
    contractType === ContractType.CONTRACTOR
      ? t('onboarding-phase.created-contractor.label', 'Send Order Form')
      : t('onboarding-phase.created.label', ''),
  pendingOn: () => PendingOn.YOU,
  pendingLabel: (t): string =>
    t('onboarding-phase.created.status', 'Pending on You'),
  primaryAction: OnboardingAction.REVIEW_CONTRACT,
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default created;
