import React from 'react';

import { OnboardingTask } from '../../../../__generated__/graphql';
import { TaskEntryProps, TaskType } from '../types';
import CompleteContractCreationTask from './complete-contract-creation';
import EmployeeIdAndWorkEmailTask from './employee-id-and-work-email';
import KycDetailsTask from './kyc-details';
import PayDepositTask from './pay-deposit';
import PayrollFormsTask from './payroll-forms';
import PlatformOnboardingTask from './platform-onboarding';
import SendContractTask from './send-contract';
import SignContractTask from './sign-contract';
import SignMsaTask from './sign-msa';

const taskMap: Record<TaskType, React.FC<TaskEntryProps>> = {
  [TaskType.SIGN_MSA]: ({
    task,
    contractId,
    actionable,
    handleCloseSidePanel,
  }) => (
    <SignMsaTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
  [TaskType.COMPANY_COMPLETE_ONBOARDING]: ({
    task,
    contractId,
    actionable,
    handleCloseSidePanel,
  }) => (
    <CompleteContractCreationTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
  [TaskType.PAY_DEPOSIT]: ({
    task,
    contractId,
    actionable,
    handleCloseSidePanel,
  }) => (
    <PayDepositTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
  [TaskType.SEND_CONTRACT]: ({
    task,
    contractId,
    actionable,
    handleCloseSidePanel,
  }) => (
    <SendContractTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
  [TaskType.SIGN_CONTRACT]: ({ task, actionable, contractId }) => (
    <SignContractTask
      task={task}
      actionable={actionable}
      contractId={contractId}
    />
  ),
  [TaskType.PLATFORM_ONBOARDING]: ({
    task,
    contractId,
    actionable,
    isResendInviteVisible,
    handleCloseSidePanel,
  }) => (
    <PlatformOnboardingTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      isResendInviteVisible={isResendInviteVisible}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
  [TaskType.KYC_DETAILS]: ({
    task,
    contractId,
    actionable,
    handleCloseSidePanel,
  }) => (
    <KycDetailsTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
  [TaskType.PAYROLL_FORMS]: ({
    task,
    contractId,
    actionable,
    handleCloseSidePanel,
  }) => (
    <PayrollFormsTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
  [TaskType.EMPLOYEE_ID_AND_WORK_EMAIL]: ({
    task,
    contractId,
    actionable,
    handleCloseSidePanel,
  }) => (
    <EmployeeIdAndWorkEmailTask
      task={task}
      contractId={contractId}
      actionable={actionable}
      handleCloseSidePanel={handleCloseSidePanel}
    />
  ),
};

export const isOptionalTask = (task: OnboardingTask): boolean =>
  task?.name
    ? [TaskType.SIGN_MSA, TaskType.PAY_DEPOSIT].includes(task.name as TaskType)
    : false;

export const TaskEntryWrapper: React.FC<TaskEntryProps> = ({
  task,
  contractId,
  actionable,
  handleCloseSidePanel,
  isResendInviteVisible,
}) => {
  if (!taskMap[task.name as TaskType]) return null;

  return taskMap[task.name as TaskType]({
    task,
    contractId,
    actionable,
    isResendInviteVisible,
    handleCloseSidePanel,
  });
};

export default taskMap;
