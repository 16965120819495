import i18n from 'i18n';

export enum ErrorCode {
  MPL0001 = 'MPL0001',
  MPL0002 = 'MPL0002',
  MPL0003 = 'MPL0003',
  MPL0004 = 'MPL0004',
  MPL0005 = 'MPL0005',
  MPL0006 = 'MPL0006',
  MPL0007 = 'MPL0007',
  MPL0008 = 'MPL0008',
  MPL0009 = 'MPL0009',
  MPL0010 = 'MPL0010',
  MPL0011 = 'MPL0011',
  MPL0012 = 'MPL0012',
  MPL0013 = 'MPL0013',
  MPL0027 = 'MPL0027',
  MPL0029 = 'MPL0029',
  MPL1001 = 'MPL1001',
  MPL0500 = 'MPL0500',
  MPL_COMPANY_0001 = 'MPL_COMPANY_0001', // illegal email domain
  MPL0030 = 'MPL0030',
  MPL0032 = 'MPL0032',
  MPL0033 = 'MPL0033',
  MPL1008_INVALID_ACCESS_FOR_CONTRACT = 'MPL1008_INVALID_ACCESS_FOR_CONTRACT',
  MPL1001_PAYROLL_DATA_NOT_AVAILABLE = 'MPL1001_PAYROLL_DATA_NOT_AVAILABLE',
}

type errorParamType = {
  [key: string]: string | number | string[] | Record<string, string | number>;
};
const errorMessageMap: {
  [key: string]: (
    errorParams: errorParamType,
  ) => {
    title: string;
    description: string;
  };
} = {
  [ErrorCode.MPL0001]: (errorParams: errorParamType) => {
    const { email = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0001.title',
        'Email {{ email }} is already in use.',
        {
          replace: {
            email,
          },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0001.description',
        'Please use a different email. (Error code: #MPL0001)',
      ),
    };
  },
  [ErrorCode.MPL0002]: (errorParams: errorParamType) => {
    const { memberId = '' } = errorParams;
    return {
      title: i18n.t('common:errors.mpl0002.title', 'Member ID already exists'),
      description: i18n.t(
        'common:errors.mpl0002.description',
        'Member with id {{ id }} already exists. (Error code: #MPL0002)',
        {
          replace: {
            id: memberId,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0003]: (errorParams: errorParamType) => {
    const { memberId = '' } = errorParams;
    return {
      title: i18n.t('common:errors.mpl0003.title', 'Member Not Found'),
      description: i18n.t(
        'common:errors.mpl0003.description',
        'Member not found for id {{ id }}. (Error code: #MPL0003)',
        {
          replace: {
            id: memberId,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0004]: (errorParams: errorParamType) => {
    const { userId = '' } = errorParams;
    return {
      title: i18n.t('common:errors.mpl0004.title', 'Member Not Found'),
      description: i18n.t(
        'common:errors.mpl0004.description',
        'Member not found for user id {{ id }}. (Error code: #MPL0004)',
        {
          replace: {
            id: userId,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0005]: () => ({
    title: i18n.t('common:errors.mpl0005.title', 'Date of Birth Not Found'),
    description: i18n.t(
      'common:errors.mpl0005.description',
      'Member date of birth not found. (Error code: #MPL0005)',
    ),
  }),
  [ErrorCode.MPL0006]: (errorParams: errorParamType) => {
    const { memberId = '' } = errorParams;
    return {
      title: i18n.t('common:errors.mpl0006.title', 'Primary Email Not Found'),
      description: i18n.t(
        'common:errors.mpl0006.description',
        'Primary email not exist for member id {{ id }}. (Error code: #MPL0006)',
        {
          replace: {
            id: memberId,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0007]: (errorParams: errorParamType) => {
    const { expenseId = '', expenseStatus = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0007.title',
        "Can't Approve/Reject Expense in {{status}} Status",
        {
          replace: {
            status: expenseStatus,
          },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0007.description',
        'Can only approve/reject expenses in APPROVAL IN PROGRESS status, while this expense ({{ id }}) is in {{ status }} status. (Error code: #MPL0007)',
        {
          replace: {
            id: expenseId,
            status: expenseStatus,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0008]: (errorParams: errorParamType) => {
    const { timeOffId = '', timeOffStatus = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0008.title',
        "Can't Approve/Reject time off in {{ status }} Status",
        {
          replace: { status: timeOffStatus },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0008.description',
        'Can only approve/reject time off in APPROVAL IN PROGRESS status, while this time off ({{ id }}) is in {{ status }} status. (Error code: #MPL0008)',
        {
          replace: {
            id: timeOffId,
            status: timeOffStatus,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0009]: (errorParams: errorParamType) => {
    const { memberPayableId = '', memberPayableStatus = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0009.title',
        "Can't Approve/Reject Member Payable in {{ status }} Status",
        {
          replace: { status: memberPayableStatus },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0009.description',
        'Can only approve/reject member payable in APPROVAL IN PROGRESS status, while this member payable ({{ id }}) is in {{ status }} status. (Error code: #MPL0009)',
        {
          replace: {
            id: memberPayableId,
            status: memberPayableStatus,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0010]: (errorParams: errorParamType) => {
    const { legalDocId = '', legalDocStatus = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0010.title',
        "Can't Approve/Reject legal documents in {{ status }} Status",
        { replace: { status: legalDocStatus } },
      ),
      description: i18n.t(
        'common:errors.mpl0010.description',
        'Can only approve/reject legal documents in SUBMITTED status, while this legal document ({{ id }}) is in {{ status }} status. (Error code: #MPL0010)',
        {
          replace: {
            id: legalDocId,
            status: legalDocStatus,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0011]: (errorParams: errorParamType) => {
    const {
      companyUserId = '',
      contractId = '',
      approveCategory = '',
      otherApproverCUIds = [],
    } = errorParams;

    const approverIds = ((otherApproverCUIds ?? []) as string[]).join(', ');

    return {
      title: i18n.t(
        'common:errors.mpl0011.title',
        "User can't be a {{ approveCategory }} Approver for this contract",
        {
          replace: {
            approveCategory,
          },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0011.description',
        'The Manager ({{companyUserId}}) cannot be a {{ approveCategory }} approver of contract ({{contractId}}) because it has already been assigned to following manager(s): [{{approverIds}}]. (Error code: #MPL0011)',
        {
          replace: {
            companyUserId,
            contractId,
            approveCategory,
            approverIds,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0012]: (errorParams: errorParamType) => {
    const {
      companyUserId = '',
      contractId = '',
      approveCategory = '',
    } = errorParams;

    return {
      title: i18n.t(
        'common:errors.mpl0012.title',
        "Manager can't be their own approver",
      ),
      description: i18n.t(
        'common:errors.mpl0012.description',
        'The Manager ({{companyUserId}}) cannot be a {{ approveCategory }} approver of contract ({{ contractId }}) because they are the same user. (Error code: #MPL0012)',
        {
          replace: {
            companyUserId,
            contractId,
            approveCategory,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0013]: (errorParams: errorParamType) => {
    const defaultInput = {
      key: '',
      value: '',
      unit: '',
    };
    const {
      max = '',
      min = '',
      contractId = '',
      input = defaultInput,
    } = errorParams;

    return {
      title: i18n.t(
        'common:errors.mpl0013.title',
        'Entitlement value violates min max constraint',
      ),
      description: i18n.t(
        'common:errors.mpl0013.description',
        'Entitlement value must be in [min, max]=[{{min}}, {{max}}] for Contract({{ contractId }}). min max constraint violated for input key {{ key }}. (Error code: #MPL0013)',
        {
          replace: {
            min,
            max,
            contractId,
            key: (input as { key: string }).key,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0027]: (errorParams: errorParamType) => {
    const { email = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0027.title',
        'Email {{ email }} is already in use.',
        {
          replace: {
            email,
          },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0027.description',
        'Please use a different email. (Error code: #MPL0027)',
      ),
    };
  },
  [ErrorCode.MPL0029]: (errorParams: errorParamType) => {
    const { email = '', contractId = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0029.title',
        'Email {{ email }} is invalid.',
        {
          replace: {
            email,
          },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0029.description',
        'Work email {{ email }} for Contract {{ contractId }} cannot be a personal email. (Error code: #MPL0029)',
        {
          replace: {
            contractId,
            email,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL1001]: (errorParams: errorParamType) => {
    const { companyId = '' } = errorParams;
    return {
      title: i18n.t('common:errors.mpl1001.title', 'No data available.'),
      description: i18n.t(
        'common:errors.mpl1001.description',
        'Payroll reports are not available for the applied filters (company id: {{companyId}}). (Error code: #MPL1001)',
        {
          replace: {
            companyId,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0500]: (errorParams: errorParamType) => {
    const { companyId = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0500.title',
        'Insufficient leave balance.',
      ),
      description: i18n.t(
        'common:errors.mpl0500.description',
        'Oops, you do not have sufficient leave balance to apply time-off for the selected dates. One possible reason could be that your carried-over leaves have expired. Please try again in sometime, and if it persists, please reach out to us through our in-app chat. (Error code: #MPL0500)',
        {
          replace: {
            companyId,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL_COMPANY_0001]: (errorParams: errorParamType) => {
    const { email = '', allowedDomain = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl_company_0001.title',
        'Email {{ email }} is not allowed.',
        {
          replace: {
            email,
          },
        },
      ),
      description: i18n.t(
        'common:errors.mpl_company_0001.description',
        'Email {{ email }} can not be used as it is outside of {{ allowedDomain }} domain. (Error code: #MPLCompany0001)',
        {
          replace: {
            email,
            allowedDomain,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL0032]: (errorParams: errorParamType) => {
    const { email = '' } = errorParams;
    return {
      title: i18n.t(
        'common:errors.mpl0032.title',
        'Email {{ email }} is already in use.',
        {
          replace: {
            email,
          },
        },
      ),
      description: i18n.t(
        'common:errors.mpl0032.description',
        'Please contact your CSM or email support@usemultiplier.com to enable onboarding for this employee.',
      ),
    };
  },
  [ErrorCode.MPL0033]: (errorParams: errorParamType) => {
    const { firstName = '', email = '' } = errorParams;

    return {
      title: i18n.t(
        'common:errors.mpl0033.title',
        'Member already has non ended contract.',
      ),
      description: i18n.t(
        'common:errors.mpl0033.description',
        '{{ firstName }} with {{ email }} has an ongoing contract, please modify email to proceed further.',
        {
          replace: {
            firstName,
            email,
          },
        },
      ),
    };
  },
  [ErrorCode.MPL1008_INVALID_ACCESS_FOR_CONTRACT]: () => ({
    title: i18n.t(
      'common:errors.mpl1008-invalid-access-for-contract.title',
      '',
    ),
    description: i18n.t(
      'common:errors.mpl1008-invalid-access-for-contract.description',
      'Invalid access for contract, Please proceed with valid contract. (Error code: #MPL1008)',
    ),
  }),
  [ErrorCode.MPL1001_PAYROLL_DATA_NOT_AVAILABLE]: () => ({
    title: i18n.t(
      'common:errors.mpl1001-payroll-data-not-available.title',
      'No data available for the applied filters',
    ),
    description: i18n.t(
      'common:errors.mpl1001-payroll-data-not-available.description',
      'Please modify your filters and try again!',
    ),
  }),
  [ErrorCode.MPL0030]: () => ({
    title: i18n.t('common:errors.mpl_0030.title', 'Unable to complete action.'),
    description: i18n.t(
      'common:errors.mpl_0030.description',
      'The action can not be completed. Please contact customer support for more details.',
    ),
  }),
};
export default errorMessageMap;

export const isChunkError = (error: Error): boolean =>
  error.name === 'ChunkLoadError' ||
  error.message?.startsWith?.(
    'Failed to fetch', // Vite
  ) ||
  error.message?.startsWith?.('Importing a module script failed'); // Vite
