import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import {
  showModuleBasedOnCompanyStatus,
  showModuleBasedOnCsm,
} from 'app/utils/module-checks';

import { CompanyStatus } from '__generated__/graphql';

import routes from './routes';

const config: ModuleConfig = {
  id: 'contact-us',
  permission: 'contact-us',
  experience: Experience.COMPANY,
  folder: 'contact-us/company',
  nav: {
    defaultName: 'Contact us',
    route: routes.root,
    sort: 10,
    home: false,
    group: NavGroups.FOOTER,
    icon: {
      name: 'help',
    },
    activeIcon: {
      name: 'help-filled',
    },
    hide: false,
  },
  enabled: async () => {
    const shouldShow = await Promise.all([
      showModuleBasedOnCompanyStatus([CompanyStatus.ACTIVE]),
      showModuleBasedOnCsm(),
    ]);

    return shouldShow.every((show) => show);
  },
};

export default config;
