import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const memberVerificationInProgress: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.member-verification-in-progress.title', {
      defaultValue: 'We are verifying the details',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.member-verification-in-progress.description', {
      defaultValue:
        'We are verifying the parameters of the employment and all the detail provided by {{firstName}}}. If we require any clarification we will reach out. Everything is looking good for now and we should be done soon.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.member-verification-progress.label',
      'Verification in progress',
    ),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.member-verification-in-progress.status',
      'Pending on Multiplier',
    ),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
};

export default memberVerificationInProgress;
