import { useNavigate } from 'react-router-dom';

import { omit } from 'lodash';

import { useSubmitCompensation } from 'contract-onboarding/hooks';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Contract,
  ContractOnboardingStep,
  CurrencyCode,
  FixedPayComponentInput,
  Maybe,
  MonthYearInput,
  PayFrequency,
  PayFrequencyDateInput,
  RateFrequency,
  RateType,
  ScheduleTimeInput,
} from '__generated__/graphql';

import stepConfig from '../pages/definition-phase/step-config';

export interface FixedPayFormValues {
  amount?: Maybe<number>;
  condition?: Maybe<string>;
  currency?: Maybe<CurrencyCode>;
  frequency?: Maybe<RateFrequency>;
  label?: Maybe<string>;
  name?: Maybe<string>;
  payOn?: Maybe<MonthYearInput>;
  paySchedule?: Maybe<ScheduleTimeInput>;
  paymentFrequency?: Maybe<PayFrequency>;
  paymentFrequencyDate?: Maybe<Array<Maybe<PayFrequencyDateInput>>>;
  rateType?: Maybe<RateType>;
  firstPayoutDate?: Maybe<string>;
  secondPayoutDate?: Maybe<string>;
}

const useSubmitDefinitionCompensation = (
  contractIdFromPath: Contract['id'],
  isPostProbationToggleOn?: boolean,
  isFreelancerPayment?: boolean,
  contractType?: Contract['type'],
): {
  loading: boolean;
  onSubmit: (values: CompensationFormValues) => Promise<void>;
} => {
  const navigate = useNavigate();
  const {
    onSubmit: submitCompensation,
    loading: loadingSubmitCompensation,
  } = useSubmitCompensation(
    contractIdFromPath,
    isPostProbationToggleOn,
    isFreelancerPayment,
    contractType,
  );

  const formatFixedPayFormValues = (
    basePay: FixedPayFormValues,
  ): FixedPayComponentInput => {
    if (basePay.firstPayoutDate || basePay.secondPayoutDate)
      return omit(basePay, ['firstPayoutDate', 'secondPayoutDate']);
    return basePay;
  };

  const onSubmit = async (values: CompensationFormValues) => {
    if (!contractIdFromPath || !values.basePay) return;

    const formattedBasePay = formatFixedPayFormValues(values.basePay);

    const compensationUpdate = await submitCompensation({
      ...values,
      basePay: formattedBasePay,
      probationBasePay: values?.probationBasePay,
    });

    if (compensationUpdate?.onboarding?.current)
      navigate(
        stepConfig[
          compensationUpdate.onboarding.current as ContractOnboardingStep
        ].directLink(compensationUpdate.id),
      );

    if (compensationUpdate?.onboarding?.steps) {
      const current = compensationUpdate?.onboarding.steps.find(
        (x) => x === ContractOnboardingStep.DEFINITION_COMPENSATION_DETAILS,
      );
      const currentIndex =
        current && compensationUpdate?.onboarding.steps?.indexOf(current);
      const next =
        currentIndex && compensationUpdate.onboarding.steps[currentIndex + 1];
      navigate(
        next
          ? stepConfig[next].directLink(contractIdFromPath)
          : stepConfig[
              compensationUpdate.onboarding.current as ContractOnboardingStep
            ].directLink(contractIdFromPath),
      );
    }
  };

  return {
    loading: loadingSubmitCompensation,
    onSubmit,
  };
};

export default useSubmitDefinitionCompensation;
