import { TFunction } from 'i18next';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import { MixedSchema } from 'yup/lib/mixed';

import { notEmpty } from 'app/utils/array';
import {
  fileSchema,
  typeTest,
  unsupportedFormatMessage,
} from 'app/utils/file-schema-utils';

import { LegalDocumentRequirement, Maybe } from '__generated__/graphql';

export const groupLegalDocumentDefinitionByKey = (
  data?: Maybe<Array<Maybe<LegalDocumentRequirement>>>,
): { [key: string]: LegalDocumentRequirement } =>
  data
    ?.filter(notEmpty)
    .reduce(
      (
        prev: { [key: string]: LegalDocumentRequirement },
        curr: LegalDocumentRequirement,
      ) => {
        if (curr?.key) prev[curr.key] = curr;
        return prev;
      },
      {},
    ) ?? {};

export const getLegalDocumentSchema = (
  legalDocuments: {
    [p: string]: LegalDocumentRequirement;
  },
  t: TFunction,
): yup.ObjectSchema<{
  legalDocuments: yup.ArraySchema<
    yup.ObjectSchema<{
      key: yup.StringSchema;
      value: Lazy<MixedSchema | yup.ArraySchema<MixedSchema>>;
    }>
  >;
}> =>
  yup.object().shape({
    legalDocuments: yup.array().of(
      yup.object().shape({
        key: yup.string().required(),
        value: yup.lazy((_value, ctx) => {
          if (
            legalDocuments?.[ctx.parent.key]?.acceptMultiple &&
            legalDocuments?.[ctx.parent.key]?.required
          ) {
            return yup
              .array()
              .of(fileSchema)
              .min(
                1,
                t(
                  'document.minimum-one-required-error',
                  'Minimum one file required',
                ),
              );
          }
          if (legalDocuments?.[ctx.parent.key]?.acceptMultiple) {
            return yup.array().of(fileSchema).nullable();
          }
          if (legalDocuments?.[ctx.parent.key]?.required) {
            return yup
              .mixed()
              .required(t('document.required', 'Required'))
              .test('type', unsupportedFormatMessage, typeTest);
          }
          return yup
            .mixed()
            .notRequired()
            .nullable(true)
            .test('type', unsupportedFormatMessage, typeTest);
        }),
      }),
    ),
  });
