import { gql } from '@apollo/client';

export default gql`
  query GetMemberSummary {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        type
        position
        country
        status
        endedOn
        onboarding {
          status
        }
        currency
        paymentAccountId
        benefits {
          benefit {
            type
            packageName
          }
        }
      }
    }
  }
`;
