import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@multiplier/common';

import { Experience } from '../../../../types';
import routes from '../../../routes';
import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const PayDepositTask: React.FC<TaskEntryProps> = ({
  task,
  handleCloseSidePanel,
  actionable,
}) => {
  const { t } = useTranslation('contract-onboarding');

  const navigate = useNavigate();

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.pay-deposit.title',
        'Pay deposit',
      )}
      action={
        <Button
          size="small"
          tw="text-ps"
          variant="secondary"
          onClick={() => {
            if (handleCloseSidePanel) handleCloseSidePanel();
            navigate(`/${Experience.COMPANY}/${routes.payments}`);
          }}
          disabled={!actionable}
        >
          {t('onboarding-checklist.task-list.pay-deposit.action', 'Pay now')}
        </Button>
      }
      completed={task.completed}
    />
  );
};

export default PayDepositTask;
