/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';

import { TwStyle } from 'twin.macro';

import { Document, FileLink, Maybe } from '../../../__generated__/graphql';

export type TFunctionResult =
  | string
  | object
  | Array<string | object>
  | undefined
  | null;

export enum DetailRowType {
  BASIC_MODE,
  COMPARE_MODE,
}

export interface BaseDetailRowProps {
  id: string;
  hideLabel?: boolean;
  labelStyles?: TwStyle[] | TwStyle;
  valueStyles?: TwStyle[] | TwStyle;
  type?: DetailRowType;
}

export interface BaseEditableDetailRowProps extends BaseDetailRowProps {
  isEditing?: boolean;
  hide?: boolean | Record<string, boolean>;
}

export interface ComparableSingleDetailRowProps extends BaseDetailRowProps {
  label?: string | TFunctionResult;
  value?: DetailRowValueType;
  renderValue?: React.ReactElement;
  newValue?: DetailRowValueType;
  renderNewValue?: React.ReactElement;
}

export interface ComparableCompoundDetailRowProps extends BaseDetailRowProps {
  label?: Record<string, string> | Record<string, TFunctionResult>;
  value?: DetailRowCompoundValueType;
  renderValue?: Record<string, React.ReactElement>;
  newValue?: DetailRowCompoundValueType;
  renderNewValue?: Record<string, React.ReactElement>;
  ignoreKeys?: string[];
}

export interface EditableSingleDetailRowProps
  extends BaseEditableDetailRowProps {
  label?: string | TFunctionResult;
  value?: DetailRowValueType;
  renderValue?: React.ReactElement;
}

export interface EditableCompoundDetailRowProps
  extends BaseEditableDetailRowProps {
  groupName?: string;
  label?: Record<string, string> | Record<string, TFunctionResult>;
  value?: DetailRowCompoundValueType;
  renderValue?: Record<string, React.ReactElement>;
  ignoreKeys?: string[];
}

export type EditableDetailRowProps =
  | EditableCompoundDetailRowProps
  | EditableSingleDetailRowProps;

export type ComparableDetailRowProps =
  | ComparableSingleDetailRowProps
  | ComparableCompoundDetailRowProps;

export type DetailRowProps = (
  | EditableDetailRowProps
  | ComparableDetailRowProps
) & {
  emptyValue?: string;
};

export type DetailRowValueType = Maybe<
  string | number | Maybe<FileLink | Document>[]
>;

export type DetailRowCompoundValueType = Maybe<
  Record<string, DetailRowValueType>
>;

export const isDetailRowValueType = (
  value: unknown,
): value is DetailRowValueType => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'undefined' ||
    value === null
  )
    return true;

  return (
    typeof value === 'object' &&
    Array.isArray(value) &&
    value.every((item) => item === null || item.__typename === 'FileLink')
  );
};

export const isCompoundDetailRowValueType = (
  value: unknown,
): value is DetailRowCompoundValueType => {
  if (value === null || value === undefined) return true;
  if (value && typeof value === 'object') {
    return Object.values(value).every(isDetailRowValueType);
  }
  return false;
};

export const isEmptyValue = (value: unknown): value is undefined | null =>
  value === null || value === undefined || value === '';

export const combineKeys = (
  ...objects: Maybe<Record<string, unknown>>[]
): string[] => {
  const keySet = new Set<string>();

  objects?.forEach((value) => {
    if (!value) return;
    Object.keys(value).forEach((key) => keySet.add(key));
  });

  return Array.from(keySet);
};
