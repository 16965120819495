import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const signatureEmployerSent: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.signature-employer-sent.title', {
      defaultValue: "Waiting for employer's signature",
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.signature-employer-sent.description', {
      defaultValue:
        "Contract is sent to {{firstName}} and we're waiting for signatures. Once signed, {{firstName}} will be asked to create a Multiplier account to provide additional details for payroll.",
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.signature-employer-sent.label',
      'Contract emailed to signatory',
    ),
  pendingOn: () => PendingOn.SIGNATORY,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.signature-employer-sent.status',
      'Pending on Signatory',
    ),
  allowRevoke: () => true,
  allowDelete: () => true,
};

export default signatureEmployerSent;
