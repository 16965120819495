import { SortingState } from '@tanstack/react-table';

import { Order } from '../../types';

export default (sorting: SortingState) => {
  if (sorting?.[0]?.id) {
    return [
      {
        order: sorting?.[0]?.desc ? Order.DESC : Order.ASC,
        orderBy: sorting?.[0]?.id,
      },
    ];
  }
  return undefined;
};
