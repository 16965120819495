import { gql } from '@apollo/client';

export default gql`
  query GetMemberContractType {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        type
        term
        startOn
        endOn
        benefits {
          id
          status
          startOn
          benefit {
            id
          }
        }
      }
    }
  }
`;
