import React, { useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { PillTag, PillVariant } from '@multiplier/common';

import {
  Contract,
  ContractOffboarding,
  ContractOffboardingStatus,
  ContractStatus,
} from '../../../__generated__/graphql';

const TeamViewOffboardingStatusCell: React.FC<{
  status: Contract['status'];
  offboardingStatus: ContractOffboarding['status'];
}> = ({ status, offboardingStatus }) => {
  const { t } = useTranslation('contract-offboarding');

  const statusDetails = useMemo(() => {
    if (
      status === ContractStatus.ACTIVE &&
      offboardingStatus === ContractOffboardingStatus.INITIATED
    ) {
      return {
        label: t(
          'team-view.offboarding-status.offboarding-initiated',
          'Offboarding Initiated',
        ),
        variant: 'PREPROCESSING',
      };
    }

    if (
      status === ContractStatus.OFFBOARDING &&
      offboardingStatus === ContractOffboardingStatus.FNF_PENDING
    ) {
      return {
        label: t(
          'team-view.offboarding-status.offboarding-in-progress',
          'Offboarding in progress',
        ),
        variant: 'WARNING',
      };
    }

    if (
      status === ContractStatus.OFFBOARDING &&
      offboardingStatus === ContractOffboardingStatus.COMPLETED
    ) {
      return {
        label: t(
          'team-view.offboarding-status.offboarding-completed',
          'Awaiting last working day',
        ),
        variant: 'PREPROCESSING',
      };
    }

    if (status === ContractStatus.OFFBOARDING && offboardingStatus == null) {
      return {
        label: t(
          'team-view.offboarding-status.legacy-offboarding',
          'Offboarding in progress',
        ),
        variant: 'WARNING',
      };
    }

    return null;
  }, [status, offboardingStatus]);

  if (!statusDetails || !statusDetails.label || !statusDetails.variant)
    return null;

  return (
    <PillTag
      label={statusDetails.label}
      variant={statusDetails.variant as PillVariant}
    />
  );
};

export default TeamViewOffboardingStatusCell;
