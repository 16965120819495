/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { OperationVariables } from '@apollo/client';
import { Card, Icon, ToolTip } from '@multiplier/common';
import { TFunction } from 'i18next';
import tw from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import { useHomeCurrency } from 'app/hooks';

import {
  CalculationBreakdownFragment,
  CalculationPayableType,
  CountryCode,
  RateFrequency,
} from '__generated__/graphql';

import ContactSplash, { ContactSplashProps } from '../contact-splash';
import BreakdownEntry from './entry';
import BreakdownFooter from './footer';
import BreakdownHeader from './header';
import TalentWiki from './talentwiki';

export const salaryCalculationBreakdownLabels: {
  [key: string]: (t: TFunction<'salary-calculator'>) => string;
} = {
  grossAnnualSalary: (t) =>
    t('breakdown.gross-annual-salary', 'Gross Annual Salary'),
  grossMonthlySalary: (t) =>
    t('breakdown.gross-monthly-salary', 'Gross Monthly Salary'),
  TotalYearlyCost: (t) => t('breakdown.total-yearly-cost', 'Total Annual Cost'),
  TotalMonthlyCost: (t) =>
    t('breakdown.total-monthly-cost', 'Total Monthly Cost'),
  incomeTax: (t) => t('breakdown.income-tax', 'Income Tax'),
  netAnnuallySalary: (t) =>
    t('breakdown.net-annually-salary', 'Net Annual Salary'),
  netMonthlySalary: (t) =>
    t('breakdown.net-monthly-salary', 'Net Monthly Salary'),
  totalGrossMonthlySalary: (t) =>
    t('breakdown.total-gross-monthly-salary', 'Total Gross Monthly Salary'),
};

const BreakdownColumn: React.FC<{
  type: 'employee' | 'company';
  columnData: CalculationBreakdownFragment;
  country: CountryCode;
  frequency: RateFrequency;
}> = ({ type, columnData, country, frequency, ...props }) => {
  const { t } = useTranslation('salary-calculator');

  const { homeCurrency } = useHomeCurrency();

  return (
    <div {...props}>
      <h6 tw="text-h6 font-semibold text-text-primary mb-extra-large">
        {type === 'company'
          ? t('breakdown.company-payable', 'Amount you pay')
          : t('breakdown.employee-payable', 'Amount employee gets')}
      </h6>
      {columnData?.entries?.map((entry) => (
        <BreakdownEntry
          key={entry?.name}
          name={
            entry?.name && salaryCalculationBreakdownLabels[entry.name]
              ? salaryCalculationBreakdownLabels[entry.name](t)
              : t(`breakdown.${country?.toLowerCase()}.${entry?.name}`)
          }
          currency={entry?.currency}
          amount={entry?.amount}
          nestedEntries={entry?.entries}
          country={country}
          frequency={frequency}
          note={
            entry.note
              ? t(
                  `breakdown.${country?.toLowerCase()}.notes.${frequency?.toLowerCase()}.${type}.${
                    entry.name
                  }`,
                  entry.note,
                )
              : undefined
          }
          type={type}
        />
      ))}
      <BreakdownEntry
        name={
          columnData?.total?.name &&
          salaryCalculationBreakdownLabels[columnData.total.name]
            ? salaryCalculationBreakdownLabels[columnData.total.name](t)
            : t(
                `breakdown.${country?.toLowerCase()}.${
                  columnData?.total?.name
                }`,
              )
        }
        currency={columnData?.total?.currency}
        amount={columnData?.total?.amount}
        country={country}
        frequency={frequency}
        note={
          columnData?.total?.note
            ? t(
                `breakdown.${country?.toLowerCase()}.notes.${frequency?.toLowerCase()}.${type}.${
                  columnData?.total?.name
                }`,
                columnData.total.note,
              )
            : undefined
        }
        type={type}
      >
        {type === 'company' && homeCurrency !== columnData.total.currency && (
          <div tw="flex flex-row items-center mt-extra-small">
            <span tw="text-ps text-text-tertiary">
              {t('breakdown.company-payable-approx', 'Approx.')}
            </span>
            <CurrencyHelper
              tw="mx-tiny"
              referenceCurrency={columnData?.total?.currency}
              amount={columnData?.total?.amount}
            />
            <ToolTip
              variant="top"
              content={t(
                'breakdown.company-payable-fx-info',
                'The FX rate used here is mid-market rate and is for indicative purposes only',
              )}
            >
              <Icon name="info" height="16" width="16" />
            </ToolTip>
          </div>
        )}
      </BreakdownEntry>
    </div>
  );
};

const Separator = tw.div`border-t border-grey04 mx-24`;
interface SalaryCalculatorBreakdownProps {
  request: OperationVariables;
  frequency: RateFrequency;
  availableFrequencies: RateFrequency[];
  data: {
    companyPayable?: CalculationBreakdownFragment;
    employeePayable?: CalculationBreakdownFragment;
  };
  handleFrequencyChange: (frequency: RateFrequency) => void;
}

const RequestBreakdownCard: React.FC<{
  contactSplashProps: Omit<ContactSplashProps, 'variant'>;
}> = ({ contactSplashProps, ...props }) => {
  const { t } = useTranslation('salary-calculator');
  return (
    <div {...props}>
      <h6 tw="text-h6 font-semibold text-background mb-12">
        {t('breakdown.employee-payable', 'Amount employee gets')}
      </h6>
      <ContactSplash variant="breakdown" {...contactSplashProps} />
    </div>
  );
};

const SalaryCalculatorBreakdown: React.FC<SalaryCalculatorBreakdownProps> = ({
  data,
  request,
  frequency,
  availableFrequencies,
  handleFrequencyChange,
}) => (
  <Card tw="flex flex-col" data-testid="breakdown">
    <BreakdownHeader
      tw="p-large"
      frequency={frequency}
      availableFrequencies={availableFrequencies}
      currency={request.currency}
      amount={request.amount}
      handleFrequencyChange={handleFrequencyChange}
    />
    <Separator />
    <div tw="flex flex-row my-base">
      {data.companyPayable && (
        <BreakdownColumn
          type="company"
          tw="flex-1 mx-large"
          columnData={data.companyPayable}
          country={request.country}
          frequency={frequency}
        />
      )}
      {data.employeePayable ? (
        <BreakdownColumn
          type="employee"
          tw="flex-1 mx-large"
          columnData={data.employeePayable}
          country={request.country}
          frequency={frequency}
        />
      ) : (
        <RequestBreakdownCard
          tw="flex-1 mx-large"
          contactSplashProps={{
            country: request.country,
            amount: request.amount,
            currency: request.currency,
            frequency: request.frequency,
            payableType: [CalculationPayableType.EMPLOYEE],
          }}
        />
      )}
    </div>
    <Separator />
    <BreakdownFooter
      country={request.country}
      state={request.state}
      amount={request.amount}
      currency={request.currency}
      rateType={request.rateType}
      frequency={request.frequency}
      payableType={request.payableType}
    />
    <TalentWiki country={request.country} />
  </Card>
);

export default SalaryCalculatorBreakdown;
