import AppFeature from 'app/features';
import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
  WidgetPosition,
} from 'app/models/module-config';
import routes from 'team/member/routes';

const config: ModuleConfig = {
  id: 'onboarding-specialist',
  permission: 'team',
  folder: 'team/member/onboarding-specialist',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Onboarding Specialist',
    route: `${routes.root}`,
    sort: 1,
    home: false,
    group: MemberNavGroups.EMPLOYMENT,
    hide: true,
  },
  widget: {
    sort: 1,
    list: [
      {
        show: true,
        file: 'onboarding-specialist-widget',
        position: WidgetPosition.RIGHT,
      },
    ],
  },
  featureFlag: AppFeature.SHOW_ONBOARDING_SPECIALIST,
  featureFlagDefaultValue: false,
};

export default config;
