import React, { useMemo } from 'react';

import { useFeature } from '@growthbook/growthbook-react';

import AppFeature from 'app/features';

import {
  PayrollCycleEntityType,
  useGetCompanyPayrollEntityTypesQuery,
} from '__generated__/graphql';

import {
  AppSidebarNavItemName,
  SidebarEntryProps,
} from '../../app/components/sidebar';
import Sidebar from '../../common/components/sidebar';

const PayrollSidebarEntry: React.FC<SidebarEntryProps> = ({
  id,
  nav,
  handleClick,
}) => {
  const demoDataEnabled = useFeature(AppFeature.COMPANY_PAYROLL_DEMO).on;
  const { data } = useGetCompanyPayrollEntityTypesQuery();

  const isGlobalPayrollEnabled = useMemo(() => {
    if (demoDataEnabled) return true;

    if (!data) {
      return false;
    }
    return (
      data.companyPayrollEntityTypes?.includes(PayrollCycleEntityType.PEO) ||
      data.companyPayrollEntityTypes?.includes(
        PayrollCycleEntityType.GLOBAL_PAYROLL,
      )
    );
  }, [data, demoDataEnabled]);

  return (
    <Sidebar.SectionEntry
      route={nav.route}
      id={id}
      icon={nav.icon}
      activeIcon={nav.activeIcon}
      handleClick={() => {
        if (handleClick) handleClick();
      }}
      label={isGlobalPayrollEnabled ? undefined : 'New'}
    >
      <AppSidebarNavItemName name={nav.defaultName} configId={id} />
    </Sidebar.SectionEntry>
  );
};

export default PayrollSidebarEntry;
