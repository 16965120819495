export default {
  root: 'team',
  details: {
    root: 'details',
    compensation: 'compensation',
    reviseSalary: 'revise-salary',
    payslips: 'payslips',
    timeOffs: 'leave-entitlement',
    benefits: 'insurance',
    approvers: 'approvers',
    equipment: 'equipment',
    onboarding_awareness: 'onboarding-awareness',
    employment: 'employment',
  } as const,
};
