import { gql } from '@apollo/client';

export default gql`
  query GetContractAgreement($id: ID!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      status
      compliance {
        contractAgreement {
          id
          name
          extension
          contentType
          blob
          htmlPreview
        }
        ... on ComplianceContractor {
          orderForm {
            id
            name
            extension
            contentType
            blob
            htmlPreview
          }
        }
      }
      compensation {
        grant(contractId: $id) {
          id
          document {
            id
            name
            extension
            contentType
            ... on DocumentReadable {
              blob
              htmlPreview
            }
          }
        }
      }
    }
  }
`;
