import { gql } from '@apollo/client';

export default gql`
  query GetOnboardingHandler($contractId: ID!) {
    contract(id: $contractId) {
      onboarding {
        handler {
          firstName
          lastName
          displayPicture {
            link
          }
        }
      }
    }
  }
`;
