import React, { useState } from 'react';
import 'twin.macro';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useModal } from '@multiplier/common';
import { handlers } from '@multiplier/notifications';

import {
  Contract,
  Member,
  useDeleteMemberMutation,
} from '../../__generated__/graphql';
import { Experience } from '../../types';
import ImplicationInfo, {
  ImplicationInfoType,
} from '../components/implication-info';
import { TeamViewTab } from '../components/onboarding-tasks/types';
import RevokeDeleteDialog from '../components/revoke-delete-dialog';
import routes from '../routes';

const { errorNotification, successNotification } = handlers;

interface DeleteDialogReturnTypes {
  Dialog: React.FC;
  deleteDialogOpen: boolean;
  deleteInProgress: boolean;
  handleDeleteClick: (contract: ContractToDelete) => void;
  handleDeleteClose: () => void;
  handleDeleteConfirm: () => void;
  contractToDelete: ContractToDelete | undefined;
}

interface ContractToDelete {
  memberId: Member['id'];
  memberName: string;
  contractId: Contract['id'];
}

export default (
  handlePostDeleteConfirmation?: () => void,
  setSecondaryDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>,
): DeleteDialogReturnTypes => {
  const { t } = useTranslation('team.company');
  const navigate = useNavigate();
  const [contractToDelete, setContractToDelete] = useState<ContractToDelete>();
  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal();
  const [onDelete, { loading }] = useDeleteMemberMutation({
    onError() {
      errorNotification(
        '',
        t('delete.error.message', 'Failed to delete employee'),
        false,
      );
    },
    onCompleted(res) {
      if (res.memberDelete?.id) {
        navigate(
          `/${Experience.COMPANY}/${routes.team}?tab=${TeamViewTab.ONBOARDING}`,
        );
        successNotification(
          t('delete.notification.title', 'Success'),
          t('delete.notification.subtitle', {
            defaultValue: '{{name}} removed from Company',
            name: contractToDelete?.memberName ?? 'Employee',
          }),
          false,
        );
      }
    },
  });

  const handleDeleteConfirm = async () => {
    if (contractToDelete?.memberId) {
      await onDelete({
        variables: {
          id: String(contractToDelete.memberId),
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: contractToDelete.contractId,
            __typename: 'Contract',
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    }
    setContractToDelete(undefined);
    if (handlePostDeleteConfirmation) handlePostDeleteConfirmation();
    handleCloseDialog();
  };

  const handleDeleteClick = (input: ContractToDelete) => {
    if (input) {
      setContractToDelete(input);
      handleOpenDialog();
    }
  };

  const handleDeleteClose = () => {
    setContractToDelete(undefined);
    handleCloseDialog();
    if (setSecondaryDialogOpen) setSecondaryDialogOpen(false);
  };

  const Dialog = () => (
    <RevokeDeleteDialog
      open={showDialog}
      onClose={handleDeleteClose}
      onConfirm={handleDeleteConfirm}
      title={t(
        'main.delete-new.title',
        'Are you sure you want to delete their contract?',
      )}
      buttonText={t('main.delete-new.button', 'Delete Contract & Record')}
      loading={loading}
      buttonVariant="danger"
    >
      <div tw="flex flex-col items-start gap-y-base">
        <p tw="uppercase text-pxs text-text-secondary tracking-widest">
          {t(
            'main.delete-new.implications.header',
            'Implications of deleting contract',
          )}
        </p>
        <ImplicationInfo type={ImplicationInfoType.DELETE} />
      </div>
    </RevokeDeleteDialog>
  );

  return {
    Dialog,
    deleteDialogOpen: showDialog,
    deleteInProgress: loading,
    handleDeleteConfirm,
    handleDeleteClick,
    handleDeleteClose,
    contractToDelete,
  };
};
