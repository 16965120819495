/** @jsxImportSource @emotion/react */
import React, { useContext, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useClickAway, useKey } from 'react-use';

import { Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import ConfirmationDialog from '../../../../../common/components/confirmation-dialog';
import IconButton from '../../../../../common/components/icon-button';

const RequestMadeDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { t } = useTranslation('team.common');
  const dialogRef = useRef(null);
  const { isNewThemeApplied } = useContext(ThemeContext);

  useClickAway(dialogRef, () => {
    onClose();
  });
  useKey('Escape', () => {
    onClose();
  });

  if (!open) return null;

  return (
    <ConfirmationDialog.Lightbox>
      <ConfirmationDialog.Container
        ref={dialogRef}
        tw="flex flex-col items-center p-56"
      >
        <div tw="absolute right-none top-none">
          <IconButton
            size="medium"
            name="cross"
            fill={theme`colors.icon-tertiary`}
            onClick={onClose}
          />
        </div>
        <Icon
          name="documents-processing"
          css={[isNewThemeApplied && tw`h-[100px] w-[100px]`]}
        />

        <p tw="text-text-primary font-semibold text-h5">
          {t('request-made', 'Your request has been made')}
        </p>
        <p tw="text-text-tertiary font-normal text-p mt-small">
          {t(
            'please-wait',
            'Please wait for your information to be updated after a verification process',
          )}
        </p>
      </ConfirmationDialog.Container>
    </ConfirmationDialog.Lightbox>
  );
};

export default RequestMadeDialog;
