/** @jsxImportSource @emotion/react */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionProps,
  CardSeparator,
  useModal,
} from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import { addMonths, format, isValid, parseISO } from 'date-fns';
import { isNumber } from 'lodash';
import tw from 'twin.macro';
import * as yup from 'yup';

import CurrencyHelper from 'app/components/currency-helper';
import AppFeature from 'app/features';
import { Experience } from 'app/models/module-config';
import { successNotification } from 'app/services/notification-services';
import { notEmpty } from 'app/utils/array';
import { numberAsCurrency } from 'app/utils/format';
import { userVar } from 'app/vars';
import DatePicker from 'common/components/date-picker';
import { Religion, payFrequencyLabel } from 'common/constants/default-labels';
import {
  Occurrence,
  PaymentType,
} from 'contract-onboarding/company/components/additional-pay-form';
import AdditionalPaysTable from 'contract-onboarding/company/components/additional-pays-table';
import Esop from 'contract-onboarding/company/components/esop';
import { extendGrantValueType } from 'contract-onboarding/company/components/esop-input';
import PayrollFrequency from 'contract-onboarding/company/components/payroll-frequency';
import { PayrollFrequencySelector } from 'contract-onboarding/company/components/payroll-frequency-selector';
import PrePostProbationDisclaimer from 'contract-onboarding/company/components/pre-post-probation-disclaimer';
import PostProbationToggleBar from 'contract-onboarding/company/components/probation-salary/components/post-probation-toggle-bar';
import { isContractToPayrollConfigLinkingEnabled } from 'contract-onboarding/company/hooks/get-payroll-config';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import PayrollCutoffDateHint from 'contract-onboarding/company/pages/definition-phase/pages/basic-details/components/new-payroll-cutoff-date-hint';
import { getEsopFromGrant } from 'contract-onboarding/company/pages/definition-phase/pages/compensation';
import {
  convertBasePayToMonthlyPay,
  getCurrencyOptions,
  getFixedPayValidation,
  getProbationPayValidation,
  isMonthEnabledForAdditionalPay,
} from 'contract-onboarding/company/services/compensation';
import { useSubmitCompensation } from 'contract-onboarding/hooks';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';
import getPaymentFrequenciesForRateFrequency from 'contract-onboarding/services/get-payment-frequencies-for-rate-frequency';
import {
  useAdditionalCompensationsMapper,
  useIsNewAllowancesConfigEnabled,
} from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';
import CurrentCompensation from 'team/company/components/sections/compensation-section/current-compensation';
import { Sections } from 'team/components/employee-details-component';

import {
  Company,
  ComplianceFreelance,
  ComplianceMultiplierEor,
  ComplianceParamPeriodLimit,
  CompliancePartnerEor,
  CompliancePeo,
  Contract,
  ContractTerm,
  ContractType,
  CountryCode,
  CurrencyCode,
  Maybe,
  PayFrequency,
  PayType,
  PerformanceReview,
  RateFrequency,
  useGetCountryLocalCurrencyLazyQuery,
  useGetSupportedCurrenciesQuery,
} from '__generated__/graphql';

import FixedPayInput, {
  getFixedPayInputLabelText,
} from '../../company/components/fixed-pay-input';
import {
  useGetCountryLabourStandards,
  useGetPayrollConfig,
} from '../../company/hooks';
import ContractReviewSectionModal, {
  FooterSection,
} from '../contract-review-section-modal';
import DetailRow from '../detail-row';
import { DetailForm, DetailGrid, DetailValue } from '../layout';
import SectionActionBar from '../section-action-bar';
import PerformanceReviewDetails from './performance-review-details';

const CompensationSection: React.FC<
  {
    id: Contract['id'];
    type: Contract['type'];
    term: Contract['term'];
    country: Contract['country'];
    currency: Contract['currency'];
    compensation: Contract['compensation'];
    alreadyHired: Contract['alreadyHired'];
    companyName: Company['displayName'];
    companyId: Company['id'];
    religion?: Maybe<Religion>;
    edit: boolean;
    uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
    startOn?: string;
    additionalCompensationSubText?: boolean;
    compliance?:
      | ComplianceMultiplierEor
      | CompliancePartnerEor
      | CompliancePeo
      | ComplianceFreelance
      | null;
    countryStateCode: Contract['countryStateCode'];
    legalEntityId: Contract['legalEntityId'];
    toggle?: (key: string) => void;
    open?: boolean;
    contract?: Maybe<Contract>;
    performanceReview?: Maybe<PerformanceReview>;
  } & Partial<AccordionProps>
> = ({
  id,
  type,
  term,
  country,
  compensation,
  alreadyHired,
  companyName,
  companyId,
  religion,
  edit,
  uncommittedChangesCallback,
  startOn,
  additionalCompensationSubText,
  compliance,
  countryStateCode,
  legalEntityId,
  toggle,
  open,
  contract,
  performanceReview,
  ...props
}) => {
  const { i18n, t } = useTranslation('contract-onboarding.common');
  const isNotFreelancer = type !== ContractType.FREELANCER;
  const [showEdit, toggleEdit] = useToggle(false);
  const isContractOnboardingHourlyPayEnabled = useFeature(
    'contract-onboarding-hourly-pay',
  )?.on;

  const isPostProbationFeatureEnabled = useFeature(
    AppFeature.PROBATION_SALARY_FEATURE,
  )?.on;
  const isNewEmployeeFieldsEnabled = useFeature(
    AppFeature.HRIS_NEW_EMPLOYEE_FIELDS,
  ).on;

  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type,
  });

  const [showNested, setShowNested] = useState(false);
  const [
    isProbationMoreThanPostProbation,
    setIsProbationMoreThanPostProbation,
  ] = useState(false);

  const sorOnboardingContext = useSorOnboardingContext({
    type,
    companyId,
    legalEntityId,
  });

  const [
    showEditFormModal,
    handleCloseEditFormModal,
    handleOpenEditFormModal,
  ] = useModal(false);

  const modalCloseHandler = () => {
    toggleEdit(false);
    methods.reset();
    handleCloseEditFormModal();
    setUncommittedSubForms(false);
  };

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const { data: labourStandards } = useGetCountryLabourStandards({
    country,
    countryStateCode,
  });

  const averageWorkingHoursPerMonth = useMemo(
    () =>
      isContractOnboardingHourlyPayEnabled &&
      isNumber(
        labourStandards?.country?.labourStandards?.workShift
          ?.averageWorkingHoursPerMonth,
      )
        ? labourStandards?.country?.labourStandards?.workShift
            ?.averageWorkingHoursPerMonth
        : undefined,
    [isContractOnboardingHourlyPayEnabled, type, labourStandards],
  );

  const { data: supportedCurrenciesData } = useGetSupportedCurrenciesQuery({
    variables: {
      country: country as CountryCode,
      contractType: type as ContractType,
    },
    skip: country === undefined,
  });

  const compliantCurrencies =
    supportedCurrenciesData?.country?.supportedCurrencies || [];

  const methods = useForm<CompensationFormValues>({
    resolver: yupResolver(
      yup.object().shape({
        basePay: getFixedPayValidation({
          contractType: type,
          compliantCurrencies,
          contractTerm: term,
          isContractOnboardingHourlyPayEnabled,
          countryRateFrequencyList:
            labourStandards?.country?.labourStandards?.compensation
              ?.rateFrequency?.list,
          countryPaymentFrequencyList:
            labourStandards?.country?.labourStandards?.compensation
              ?.paymentFrequency?.list,
        }),
        probationBasePay: getProbationPayValidation({
          contractType: type,
          compliantCurrencies,
          contractTerm: term,
          country,
          isContractOnboardingHourlyPayEnabled,
          countryRateFrequencyList:
            labourStandards?.country?.labourStandards?.compensation
              ?.rateFrequency?.list,
          countryPaymentFrequencyList:
            labourStandards?.country?.labourStandards?.compensation
              ?.paymentFrequency?.list,
          isPostProbationToggleOn: showNested,
        }),
        payrollStart: yup.date().nullable(),
        payType: yup
          .string()
          .test('pay-type-test', (value?: string) =>
            type === ContractType.FREELANCER
              ? yup
                  .string()
                  .oneOf(Object.values(PayType))
                  .required()
                  .isValid(value)
              : true,
          )
          .nullable(),
        additionalPays: yup.lazy((val, ctx) => {
          const baseMonthlyPay = convertBasePayToMonthlyPay(
            ctx.parent?.basePay?.frequency ?? RateFrequency.MONTHLY,
            ctx.parent?.basePay?.amount ?? 0,
            averageWorkingHoursPerMonth,
          );
          return yup.array().of(
            yup.object().shape({
              payType: yup
                .string()
                .required()
                .oneOf(Object.values(PaymentType)),
              amount: yup.number().when('payInInstallments', {
                is: true,
                then: yup
                  .number()
                  .nullable()
                  .transform((_, value) =>
                    value === '' ? null : Number(value),
                  )
                  .notRequired(),
                otherwise: yup
                  .number()
                  .nullable()
                  .transform((_, value) =>
                    value === '' ? null : Number(value),
                  )
                  .notRequired()
                  .when('payType', {
                    is: (payType: PaymentType) =>
                      payType === PaymentType.THR_BONUS ||
                      payType === PaymentType.THIRTEENTH_MONTH_BONUS,
                    then: yup
                      .number()
                      .min(
                        baseMonthlyPay,
                        t(
                          'definition-phase.compensation.additional-pay.should-be-greater-than-base-pay',
                          'should be greater than min pay',
                        ),
                      ),
                  })
                  .when('payType', {
                    is: (payType: PaymentType) =>
                      payType === PaymentType.JOINING_BONUS ||
                      payType === PaymentType.ALLOWANCES,
                    then: yup.number().moreThan(0),
                  }),
              }),
              currency: yup
                .string()
                .notRequired()
                .nullable()
                .when('payType', {
                  is: (payType: PaymentType) =>
                    [
                      PaymentType.JOINING_BONUS,
                      PaymentType.ALLOWANCES,
                      PaymentType.THR_BONUS,
                      PaymentType.THIRTEENTH_MONTH_BONUS,
                    ].includes(payType),
                  then: yup
                    .string()
                    .oneOf(
                      currencyOptions
                        ? currencyOptions.map((v) => v.value)
                        : [],
                    ),
                }),
              frequency: isNotFreelancer
                ? yup
                    .string()
                    .nullable()
                    .notRequired()
                    .oneOf(Object.values(RateFrequency))
                : yup
                    .number()
                    .nullable()
                    .notRequired()
                    .when(['payType', 'occurrence'], {
                      is: (payType: PaymentType, occurrence: Occurrence) => {
                        if (
                          payType === PaymentType.VARIABLE_PERFORMANCE_BONUS ||
                          payType === PaymentType.ALLOWANCES
                        )
                          return true;
                        if (
                          payType === PaymentType.OTHER &&
                          occurrence === Occurrence.RECURRING
                        )
                          return true;
                        return false;
                      },
                      then: yup.number().integer().moreThan(0),
                    }),
              conditions: yup.string().notRequired().nullable(),
              payoutMonth: yup.object().when('payInInstallments', {
                is: true,
                then: yup.object().nullable().notRequired(),
                otherwise: yup
                  .object()
                  .nullable()
                  .notRequired()
                  .when('payType', {
                    is: (payType: PaymentType) =>
                      [
                        PaymentType.ALLOWANCES,
                        PaymentType.JOINING_BONUS,
                        PaymentType.VARIABLE_PERFORMANCE_BONUS,
                        PaymentType.OTHER,
                      ].includes(payType) && !isNewAllowancesConfigEnabled,
                    then: yup.object().shape({
                      month: yup.number().required(),
                      year: yup.number().required(),
                    }),
                  }),
              }),
              payrollConfigId: isContractToPayrollConfigLinkingEnabled(
                type,
                sorOnboardingContext,
              )
                ? yup
                    .string()
                    .required(
                      t(
                        'definition-phase.compensation.payroll-config-id.required',
                        'There is no payroll config matched with the selected payroll frequency',
                      ),
                    )
                : yup.string().nullable().optional(),
            }),
          );
        }),
      }),
    ),
  });

  const {
    formState: { isValid: isFormValid, dirtyFields, isDirty },
  } = methods;

  const [rateType, amount, probationAmount, payFrequency] = useWatch({
    control: methods.control,
    name: [
      `basePay.rateType`,
      `basePay.amount`,
      `probationBasePay.amount`,
      'basePay.paymentFrequency',
    ],
  });

  const basePayFrequency = methods.watch('basePay.frequency') as
    | RateFrequency
    | undefined;

  const probationPolicyCompliance = useMemo(
    () =>
      compliance?.complianceParams &&
      compliance.complianceParams
        ?.filter(notEmpty)
        .filter((param) => param.key === 'probationPolicy'),
    [compliance],
  );

  const supportedPaymentFrequencies = useMemo(
    () =>
      getPaymentFrequenciesForRateFrequency(
        labourStandards?.country?.labourStandards?.compensation
          ?.paymentFrequency?.data,
        basePayFrequency,
      ),
    [labourStandards, basePayFrequency],
  );

  const currencyOptions = useMemo(
    () => getCurrencyOptions(compliantCurrencies),
    [compliantCurrencies],
  );

  useEffect(() => {
    const currentPaymentFrequency = methods.watch(
      'basePay.paymentFrequency',
    ) as PayFrequency;
    const probationPayPaymentFrequency = methods.watch(
      'probationBasePay.paymentFrequency',
    ) as PayFrequency;
    if (
      isContractOnboardingHourlyPayEnabled &&
      supportedPaymentFrequencies?.payFrequencies?.length &&
      !supportedPaymentFrequencies?.payFrequencies?.includes(
        currentPaymentFrequency,
      )
    ) {
      methods.setValue(
        'basePay.paymentFrequency',
        supportedPaymentFrequencies?.payFrequencies?.[0],
      );
    }
    if (
      isContractOnboardingHourlyPayEnabled &&
      supportedPaymentFrequencies?.payFrequencies?.length &&
      !supportedPaymentFrequencies?.payFrequencies?.includes(
        probationPayPaymentFrequency,
      )
    ) {
      methods.setValue(
        'probationBasePay.paymentFrequency',
        supportedPaymentFrequencies?.payFrequencies?.[0],
      );
    }
  }, [
    isContractOnboardingHourlyPayEnabled,
    supportedPaymentFrequencies,
    basePayFrequency,
  ]);

  const baseCompensation = useMemo(() => {
    if (current === Experience.COMPANY)
      return compensation?.postProbationBasePay;

    return compensation?.basePay;
  }, [compensation, current]);

  const mapperParams = useMemo(
    () => ({
      contract: {
        country,
        countryStateCode,
        type,
      },
    }),
    [country, countryStateCode, type],
  );

  const { mapToAdditionalPayFormValues } = useAdditionalCompensationsMapper(
    mapperParams,
  );

  const handleReset = () => {
    if (compensation)
      methods.reset({
        payType:
          compensation.payType ??
          (type === ContractType.FREELANCER ? PayType.FIXED : null),
        basePay: {
          name: baseCompensation?.name,
          frequency: baseCompensation?.frequency,
          rateType: baseCompensation?.rateType,
          currency: baseCompensation?.currency,
          amount: baseCompensation?.amount,
          paymentFrequency: baseCompensation?.paymentFrequency,
        },
        probationBasePay: {
          name: compensation?.probationBasePay?.name,
          frequency: compensation?.probationBasePay?.frequency,
          rateType: compensation?.probationBasePay?.rateType,
          currency: compensation?.probationBasePay?.currency,
          amount: compensation?.probationBasePay?.amount,
          paymentFrequency: compensation?.probationBasePay?.paymentFrequency,
        },
        additional: compensation?.additional,
        esop: compensation?.grant?.length
          ? getEsopFromGrant(compensation?.grant?.[0])
          : undefined,
        payrollStart: compensation?.payrollStart,
        additionalPays: mapToAdditionalPayFormValues(
          compensation?.additionalPays,
        ),
      });
  };

  const [uncommittedSubForms, setUncommittedSubForms] = useState(false);

  const enableCurrentMonth = useMemo<boolean>(
    () => isMonthEnabledForAdditionalPay(startOn ?? ''),
    [compensation, startOn],
  );

  useEffect(() => {
    handleReset();
  }, [compensation, mapToAdditionalPayFormValues]);

  useEffect(() => {
    setUncommittedSubForms(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (uncommittedChangesCallback) {
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.COMPENSATION_DETAILS,
        value: uncommittedSubForms,
      });
    }
  }, [uncommittedSubForms]);

  const { onSubmit, loading } = useSubmitCompensation(
    id,
    showNested,
    type === ContractType.HR_MEMBER,
    type,
    () => {
      modalCloseHandler();
      setUncommittedSubForms(false);
      successNotification(
        t(
          'onboarding-phase.compensation-section.success-notification',
          'Compensation details have been updated in the contract.',
        ),
        '',
        false,
      );
    },
  );

  const shouldShowPayrollFrequencyDropdown =
    isContractOnboardingHourlyPayEnabled ||
    (term === ContractTerm.PERMANENT &&
      type !== ContractType.FREELANCER &&
      [CountryCode.PHL, CountryCode.USA, CountryCode.GBR].includes(
        country as CountryCode,
      ));

  const fixedPayInputLabel = useMemo(
    () =>
      getFixedPayInputLabelText({
        t,
        contractTerm: term,
        rateType,
        frequency: basePayFrequency,
        label: baseCompensation?.label,
        isProbationToggleOn: showNested,
      }),
    [rateType, basePayFrequency, showNested],
  );

  const getLabelForInput = (salaryType: string) => {
    if (showNested) return `${salaryType} - ${fixedPayInputLabel}`;

    return fixedPayInputLabel;
  };

  const showDetailRowLabel = useMemo(() => {
    if (isContractOnboardingHourlyPayEnabled) {
      return true;
    }

    return !showEdit;
  }, [showEdit, isContractOnboardingHourlyPayEnabled]);

  useEffect(() => {
    if (id && compensation && compensation.probationBasePay) {
      if (compensation.probationBasePay.amount) setShowNested(true);
      else setShowNested(false);
    }
  }, [compensation]);

  useEffect(() => {
    if (showNested && amount && probationAmount) {
      if (
        parseInt(probationAmount.toString(), 10) >
        parseInt(amount.toString(), 10)
      ) {
        setIsProbationMoreThanPostProbation(true);
      } else setIsProbationMoreThanPostProbation(false);
    } else setIsProbationMoreThanPostProbation(false);
  }, [amount, probationAmount, showNested]);

  useEffect(() => {
    const probationBasePay = methods.getValues('probationBasePay.amount');
    const probationFrequency = methods.getValues('probationBasePay.frequency');
    const basePayPaymentFrequency = methods.getValues(
      'basePay.paymentFrequency',
    );

    if (showNested && !probationAmount) {
      methods.setValue('probationBasePay.amount', amount);
      methods.setValue('probationBasePay.frequency', basePayFrequency);
      methods.setValue(
        'probationBasePay.paymentFrequency',
        basePayPaymentFrequency,
      );

      methods.setValue('basePay.amount', undefined);
    }

    if (!showNested && probationBasePay && probationFrequency) {
      methods.setValue('basePay.amount', probationBasePay);
      methods.setValue('basePay.frequency', probationFrequency);

      methods.setValue('probationBasePay.amount', undefined, {
        shouldDirty: true,
      });
    }
    methods.trigger();
  }, [showNested]);

  const [
    getLocalCurrency,
    { data: countryLocalCurrency },
  ] = useGetCountryLocalCurrencyLazyQuery();

  useEffect(() => {
    if (country) {
      getLocalCurrency({ variables: { country } });
    }
  }, [country]);

  const payrollConfig = useGetPayrollConfig({
    contractId: id ?? contract?.id,
    payFrequency,
    sorOnboardingContext,
  });

  useLayoutEffect(() => {
    methods.setValue('payrollConfigId', payrollConfig?.id);
    methods.trigger('payrollConfigId');
  }, [payrollConfig]);

  const FormData = useCallback(
    ({ isFormEditing = false }: { isFormEditing?: boolean }) => (
      <DetailGrid tw="items-center">
        {!showNested && (
          <DetailRow
            id="base-pay-entry"
            label={
              showDetailRowLabel
                ? getLabelForInput('Post Probation Salary')
                : null
            }
            labelStyles={tw`mr-base`}
            value={baseCompensation?.amount}
            isEditing={isFormEditing}
            renderValue={
              <div tw="col-span-3">
                <DetailValue data-testid="base-pay-entry">
                  {`${baseCompensation?.currency} ${numberAsCurrency(
                    i18n.language,
                    baseCompensation?.amount,
                    2,
                  )}`}
                </DetailValue>
                <CurrencyHelper
                  tw="mt-6"
                  referenceCurrency={baseCompensation?.currency}
                  amount={baseCompensation?.amount}
                />
              </div>
            }
            hideLabel={isFormEditing}
          >
            {baseCompensation && (
              <FixedPayInput
                css={
                  isContractOnboardingHourlyPayEnabled
                    ? tw`col-span-3`
                    : tw`col-span-4`
                }
                currencyOptions={currencyOptions}
                localCurrency={countryLocalCurrency?.country?.mainCurrency}
                contractType={type}
                contractTerm={term}
                fieldPath="basePay"
                payComponent={baseCompensation}
                country={country}
                inline
                hideLabel={isContractOnboardingHourlyPayEnabled}
                countryRateFrequencies={
                  labourStandards?.country?.labourStandards?.compensation
                    ?.rateFrequency?.list
                }
                countryHourlyRates={
                  labourStandards?.country?.labourStandards?.compensation
                    ?.hourlyRates
                }
                isRateFrequencyInputDisabled={showNested}
              />
            )}
          </DetailRow>
        )}
        {showNested && (
          <DetailRow
            id="probation-pay-entry"
            label={
              showDetailRowLabel ? getLabelForInput('Probation Salary') : null
            }
            labelStyles={tw`mr-[20px]`}
            value={compensation?.probationBasePay?.amount}
            isEditing={isFormEditing}
            renderValue={
              <div tw="col-span-3">
                <DetailValue data-testid="probation-pay-entry">
                  {`${
                    compensation?.probationBasePay?.currency
                  } ${numberAsCurrency(
                    i18n.language,
                    compensation?.probationBasePay?.amount,
                    2,
                  )}`}
                </DetailValue>
                <CurrencyHelper
                  tw="mt-6"
                  referenceCurrency={compensation?.probationBasePay?.currency}
                  amount={compensation?.probationBasePay?.amount}
                />
              </div>
            }
          >
            {compensation?.probationBasePay && (
              <FixedPayInput
                css={
                  isContractOnboardingHourlyPayEnabled
                    ? tw`col-span-3`
                    : tw`col-span-4`
                }
                currencyOptions={currencyOptions}
                localCurrency={
                  countryLocalCurrency?.country?.mainCurrency as CurrencyCode
                }
                contractType={type}
                contractTerm={term}
                fieldPath="probationBasePay"
                payComponent={compensation.probationBasePay}
                country={country}
                inline
                hideLabel={isContractOnboardingHourlyPayEnabled}
                countryRateFrequencies={
                  labourStandards?.country?.labourStandards?.compensation
                    ?.rateFrequency?.list
                }
                countryHourlyRates={
                  labourStandards?.country?.labourStandards?.compensation
                    ?.hourlyRates
                }
              />
            )}
          </DetailRow>
        )}
        {type === ContractType.EMPLOYEE &&
          showEdit &&
          isPostProbationFeatureEnabled &&
          (probationPolicyCompliance?.length ?? 0) > 0 && (
            <div tw="col-span-4">
              <PostProbationToggleBar
                showNested={showNested}
                setShowNested={setShowNested}
                disabled={
                  (probationPolicyCompliance?.[0] as ComplianceParamPeriodLimit)
                    ?.value === -1
                }
              />
            </div>
          )}
        {showNested && (
          <DetailRow
            id="base-pay-entry"
            label={
              showDetailRowLabel
                ? getLabelForInput('Post Probation Salary')
                : null
            }
            labelStyles={tw`mr-[20px]`}
            value={baseCompensation?.amount}
            isEditing={isFormEditing}
            renderValue={
              <div tw="col-span-3">
                <DetailValue data-testid="base-pay-entry">
                  {`${baseCompensation?.currency} ${numberAsCurrency(
                    i18n.language,
                    baseCompensation?.amount,
                    2,
                  )}`}
                </DetailValue>
                <CurrencyHelper
                  tw="mt-6"
                  referenceCurrency={baseCompensation?.currency}
                  amount={baseCompensation?.amount}
                />
              </div>
            }
          >
            {baseCompensation && (
              <FixedPayInput
                css={
                  isContractOnboardingHourlyPayEnabled
                    ? tw`col-span-3`
                    : tw`col-span-4`
                }
                currencyOptions={currencyOptions}
                localCurrency={countryLocalCurrency?.country?.mainCurrency}
                contractType={type}
                contractTerm={term}
                fieldPath="basePay"
                payComponent={baseCompensation}
                country={country}
                inline
                hideLabel={isContractOnboardingHourlyPayEnabled}
                countryRateFrequencies={
                  labourStandards?.country?.labourStandards?.compensation
                    ?.rateFrequency?.list
                }
                countryHourlyRates={
                  labourStandards?.country?.labourStandards?.compensation
                    ?.hourlyRates
                }
                isRateFrequencyInputDisabled={showNested}
              />
            )}
          </DetailRow>
        )}

        {isFormEditing && showNested && (
          <div tw="col-span-4">
            <PrePostProbationDisclaimer
              probationSalary={methods.watch(`probationBasePay.amount`)}
              postProbationSalary={methods.watch(`basePay.amount`)}
              currency={methods.watch(`basePay.currency`)}
              frequency={methods.watch(`basePay.frequency`)}
              probationFrequency={methods.watch(`probationBasePay.frequency`)}
              isToggle={showNested}
            />
          </div>
        )}

        {shouldShowPayrollFrequencyDropdown && compensation?.basePay && (
          <DetailRow
            id="payroll-frequency-select"
            label={t(
              `compensation.payroll-frequency.label`,
              'Payroll frequency',
            )}
            value={
              compensation?.basePay?.paymentFrequency &&
              payFrequencyLabel[compensation?.basePay?.paymentFrequency]
            }
            isEditing={isFormEditing}
            labelStyles={isFormEditing ? tw`self-center` : tw`mt-none`}
            hideLabel={isFormEditing}
          >
            <div tw="col-span-3">
              {isContractOnboardingHourlyPayEnabled ? (
                <>
                  {supportedPaymentFrequencies?.payFrequencies?.length !== 1 ? (
                    <PayrollFrequencySelector
                      simpleSelector
                      paymentFrequency={methods.getValues(
                        'basePay.paymentFrequency',
                      )}
                      setPaymentFrequency={(val) => {
                        methods.setValue(
                          'probationBasePay.paymentFrequency',
                          val,
                          { shouldDirty: true, shouldValidate: true },
                        );
                        methods.setValue('basePay.paymentFrequency', val, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      baseFrequency={methods.getValues('basePay.frequency')}
                      countryCompensationStandards={
                        labourStandards?.country?.labourStandards?.compensation
                      }
                      tw="w-1/2"
                    />
                  ) : (
                    payFrequencyLabel[
                      methods.watch('basePay.paymentFrequency') as PayFrequency
                    ]
                  )}
                </>
              ) : (
                <PayrollFrequency
                  fieldPath="basePay"
                  payComponent={compensation?.basePay}
                  contractType={type}
                  countryPaymentFrequency={
                    labourStandards?.country?.labourStandards?.compensation
                      ?.paymentFrequency
                  }
                  inline
                />
              )}
            </div>
          </DetailRow>
        )}

        <div tw="col-span-4">
          <PayrollCutoffDateHint
            startDate={startOn}
            contractType={type}
            sorOnboardingContext={sorOnboardingContext}
            payrollConfig={payrollConfig}
          />
        </div>

        {(compensation?.additionalPays?.length || showEdit) && (
          <div tw="col-span-4">
            <CardSeparator />
            <AdditionalPaysTable
              basePay={convertBasePayToMonthlyPay(
                basePayFrequency ?? RateFrequency.MONTHLY,
                methods.watch('basePay.amount') ?? 0,
                averageWorkingHoursPerMonth,
              )}
              basePayFrequency={basePayFrequency}
              currency={
                methods.watch('basePay.currency') ??
                compensation?.basePay?.currency
              }
              contractType={type}
              currencyOptions={currencyOptions}
              companyName={companyName}
              edit={isFormEditing}
              religion={religion}
              uncommittedSubFormChangesCallback={setUncommittedSubForms}
              countryCode={country}
              minDate={
                enableCurrentMonth
                  ? new Date(new Date(startOn ?? ''))
                  : addMonths(new Date(startOn ?? ''), 1)
              }
              additionalCompensationSubText={additionalCompensationSubText}
              countryStateCode={countryStateCode}
              containerStyle={tw`p-none pt-extra-large border-none shadow-none`}
              sorOnboardingContext={sorOnboardingContext}
            />
          </div>
        )}

        {(compensation?.grant?.[0]?.id || showEdit) &&
          type === ContractType.EMPLOYEE &&
          term === ContractTerm.PERMANENT && (
            <div tw="col-span-4">
              <CardSeparator />
              <Esop
                defaultCurrency={
                  compensation?.grant?.[0]?.values?.find(
                    (value) => value.type === extendGrantValueType.CASH,
                  )?.currency ?? CurrencyCode.USD
                }
                showEdit={isFormEditing}
                uncommittedSubFormChangesCallback={setUncommittedSubForms}
                containerStyle={tw`px-none border-none shadow-none`}
              />
            </div>
          )}

        {type === ContractType.HR_MEMBER && alreadyHired && (
          <DetailRow
            id="payroll-start"
            label={t('compensation.payroll-start.label', 'Payroll Start Date')}
            value={
              compensation?.payrollStart &&
              isValid(new Date(compensation.payrollStart)) &&
              format(parseISO(compensation.payrollStart), 'dd MMMM yyyy')
            }
            isEditing={isFormEditing}
          >
            <Controller
              control={methods.control}
              name="payrollStart"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  tw="col-span-2"
                  data-testid="payroll-start"
                  id="payroll-start"
                  value={value}
                  onChange={onChange}
                  error={!!methods.formState.errors.payrollStart}
                  helperText={methods.formState.errors.payrollStart?.message}
                  loading={false}
                  placeholder={t(
                    'definition-phase.compensation.payroll-start.placeholder',
                    'Month',
                  )}
                />
              )}
            />
          </DetailRow>
        )}
      </DetailGrid>
    ),
    [
      showNested,
      showEdit,
      labourStandards,
      supportedPaymentFrequencies,
      payrollConfig,
    ],
  );

  return (
    <Accordion
      name={t('onboarding-phase.compensation.header', 'Compensation')}
      description={t(
        'onboarding-phase.compensation.description-new',
        'Gross monthly salary and additional pay information',
      )}
      edit={edit}
      icon="dollar-sign-v2"
      iconStyle={tw`w-large h-large`}
      onEditClick={() => {
        handleOpenEditFormModal();
        toggleEdit(true);
        if (toggle) toggle(Sections.COMPENSATION);
      }}
      warning={uncommittedChangesCallback && uncommittedSubForms}
      onClick={() => {
        if (toggle) toggle(Sections.COMPENSATION);
      }}
      open={open}
      {...props}
    >
      <FormProvider {...methods}>
        {performanceReview && contract ? (
          <PerformanceReviewDetails
            performanceReview={performanceReview}
            contract={contract}
          />
        ) : (
          <>
            {isNewEmployeeFieldsEnabled && contract ? (
              <CurrentCompensation contract={contract} />
            ) : (
              <DetailForm onSubmit={methods.handleSubmit(onSubmit)}>
                <FormData />
              </DetailForm>
            )}
          </>
        )}
      </FormProvider>
      {showEditFormModal && (
        <ContractReviewSectionModal
          modalTitle="Edit Compensation Details"
          onClose={modalCloseHandler}
        >
          <FormProvider {...methods}>
            <DetailForm
              onSubmit={methods.handleSubmit(onSubmit)}
              tw="gap-y-none"
            >
              <div tw="flex flex-col p-extra-large py-none pt-base">
                <FormData isFormEditing={showEdit} />
              </div>
              <FooterSection>
                <SectionActionBar
                  disabled={
                    loading ||
                    !isFormValid ||
                    isProbationMoreThanPostProbation ||
                    Object.keys(dirtyFields).length === 0 ||
                    !isDirty
                  }
                  onCancelClick={modalCloseHandler}
                  submitLoading={loading}
                  tw="mx-auto"
                />
              </FooterSection>
            </DetailForm>
          </FormProvider>
        </ContractReviewSectionModal>
      )}
    </Accordion>
  );
};

export default CompensationSection;
