import { gql } from '@apollo/client';

// TODO - Needs to be changed once API contracts are confirmed
export default gql`
  mutation SignUpCompanyUser($input: string) {
    signUpCompanyUser(input: $input)
      @rest(
        type: "SignedUpCompany"
        method: "POST"
        path: "self-signup"
        endpoint: "user"
      ) {
      id
    }
  }
`;
