/* eslint-disable import/prefer-default-export */
import { isNil } from 'lodash';

import monthNames from 'common/constants/month-names';

const getMonthName = (monthNumber?: number | null): string => {
  if (isNil(monthNumber)) return '';
  if (monthNumber < 1 || monthNumber > 12) return '';
  return Object.values(monthNames)[monthNumber - 1];
};

export { getMonthName };
