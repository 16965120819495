import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { SorOnboardingContext } from '@multiplier/hris-member-management';

import { Experience } from 'app/models/module-config';
import { haveCompletedOnboardingForms } from 'contract-onboarding/services/legal-documents';
import useOnboardingFormRequirements from 'document-management/member/onboarding-forms/hooks/get-onboarding-form-requirements';
import onboardingRoutes from 'document-management/member/routes';

import { Maybe, Member } from '__generated__/graphql';

interface ShouldRedirectResponse {
  shouldRedirect: boolean;
  loading: boolean;
}

const useShouldRedirectOnboarding = (
  member?: Maybe<Member>,
  sorOnboardingContext?: SorOnboardingContext,
): ShouldRedirectResponse => {
  const [haveRedirected, setHaveRedirected] = useState(false);
  const { onboardingFormRequirements, loading } = useOnboardingFormRequirements(
    member,
  );
  const navigate = useNavigate();

  const shouldRedirect = useMemo(
    () =>
      !haveRedirected &&
      !(
        sorOnboardingContext?.isGlobalPayrollOnlyFlow ||
        sorOnboardingContext?.isHrisOnlyFlow
      ) &&
      !haveCompletedOnboardingForms({
        onboardingFormRequirements,
        legalDocuments: member?.legalDocuments,
      }),
    [haveRedirected, onboardingFormRequirements],
  );

  useEffect(() => {
    if (shouldRedirect) {
      navigate(
        `/${Experience.MEMBER}/${onboardingRoutes.root}/${onboardingRoutes.onboardingForms}`,
        { replace: true },
      );
      setHaveRedirected(true);
    }
  }, [shouldRedirect]);

  return {
    shouldRedirect,
    loading: loading || (shouldRedirect && !haveRedirected),
  };
};

export default useShouldRedirectOnboarding;
