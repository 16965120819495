/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';
import tw from 'twin.macro';

import ConfirmationDialog from 'common/components/confirmation-dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CancelRevisionFormDialog: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation('performance-reviews');
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      containerStyle={tw`w-[640px]`}
      title={t('form.cancel-dialog.title', 'Delete all changes?')}
      hideButtons
    >
      <div tw="text-center">
        {t(
          'form.cancel-dialog.description',
          'You will lose all changes made to salary. Do you want to go ahead?',
        )}
        <div tw="flex items-center justify-center gap-extra-small mt-large">
          <Button variant="secondary" onClick={onConfirm}>
            {t('form.cancel-dialog.delete-button', 'Yes, delete')}
          </Button>
          <Button onClick={onClose}>
            {t('form.cancel-dialog.continue-button', 'Continue editing')}
          </Button>
        </div>
      </div>
    </ConfirmationDialog>
  );
};

export default CancelRevisionFormDialog;
