import { gql } from '@apollo/client';

export default gql`
  mutation AddCompanyUser($user: AddCompanyUserInput) {
    companyUserAdd(user: $user) {
      id
      userId
      firstName
      lastName
      emails {
        email
      }
      title
      role
      isSignatory
      isBillingContact
      status
      company {
        id
      }
    }
  }
`;
