/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';

import { Button, ToolTip } from '@multiplier/common';
import * as FileSaver from 'file-saver';

import { errorNotification } from 'app/services/notification-services';
import DocumentViewer from 'common/components/document-viewer';
import IconButton from 'common/components/icon-button';
import importMetaEnv from 'import-meta-env';

import {
  Compliance,
  ComplianceContractor,
  Contract,
  ContractType,
  DocumentReadable,
  Grant,
} from '__generated__/graphql';

import workerContent from '../../../pdf.worker.min.json';
import { ContractPageType } from '../progress-widget-content/view-send-contract-action';

if (importMetaEnv.PROD) {
  const workerBlob = new Blob([workerContent], { type: 'text/javascript' });
  pdfjs.GlobalWorkerOptions.workerSrc = URL.createObjectURL(workerBlob);
}

enum Tab {
  CONTRACT_AGREEMENT = 'contractAgreement',
  ESOP_AGREEMENT = 'esopAgreement',
}

export enum AorDocumentType {
  ORDER_FORM = 'ORDER_FORM',
  AGREEMENT = 'AGREEMENT',
}

const ContractModal: React.FC<{
  open: boolean;
  onClose: () => void;
  handleCtaClick?: () => void;
  title?: string;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  contractType: Contract['type'];
  contractAgreement?: Compliance['contractAgreement'];
  orderForm?: ComplianceContractor['orderForm'];
  esopAgreement?: Grant['document'];
  loading: boolean;
  uncommittedChanges?: boolean;
  contractModalPageType?: ContractPageType;
  setContractModalPageType?: React.Dispatch<
    React.SetStateAction<ContractPageType>
  >;
  requestChangesPageContent?: React.ReactElement;
  contractBlocked?: boolean;
  contractBackdated?: boolean;
  aorDocumentType?: AorDocumentType;
}> = ({
  open,
  onClose,
  handleCtaClick,
  header,
  title,
  contractType,
  contractAgreement,
  orderForm,
  esopAgreement,
  loading,
  footer,
  uncommittedChanges,
  contractModalPageType = ContractPageType.MAIN_PAGE,
  setContractModalPageType,
  requestChangesPageContent,
  contractBlocked,
  contractBackdated,
  aorDocumentType,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [selectedTab, setSelectedTab] = useState(Tab.CONTRACT_AGREEMENT);

  const fileToRender = useMemo(() => {
    if (selectedTab === Tab.ESOP_AGREEMENT) {
      return {
        ...esopAgreement,
        name: esopAgreement?.name ?? 'ESOP.pdf',
      } as DocumentReadable;
    }
    if (contractType === ContractType.CONTRACTOR) {
      if (aorDocumentType === AorDocumentType.ORDER_FORM) {
        return {
          ...orderForm,
          name: orderForm?.name ?? 'OrderForm.pdf',
        } as DocumentReadable;
      }
      if (aorDocumentType === AorDocumentType.AGREEMENT) {
        return {
          ...contractAgreement,
          name: contractAgreement?.name ?? 'Agreement.pdf',
        } as DocumentReadable;
      }
    }
    return {
      ...contractAgreement,
      name: contractAgreement?.name ?? 'Contract.pdf',
    };
  }, [selectedTab, esopAgreement, contractAgreement, orderForm]);

  useEffect(() => {
    if (!open) {
      setSelectedTab(Tab.CONTRACT_AGREEMENT);
    }
  }, [open]);

  const handleDownloadClick = useCallback(() => {
    if (fileToRender?.blob) {
      FileSaver.saveAs(
        `data:application/pdf;base64,${fileToRender.blob}`,
        fileToRender?.name ?? '',
      );
    } else {
      errorNotification(
        t(
          'contract-viewer.download-contract.failed.title',
          'Failed to download contract',
        ),
        '',
        false,
      );
    }
  }, [fileToRender, selectedTab]);

  const tabs = useMemo(() => {
    if (esopAgreement)
      return [
        {
          key: Tab.CONTRACT_AGREEMENT,
          name: t(
            'contract-viewer.tab.contractAgreement',
            'Employment Contract',
          ),
        },
        {
          key: Tab.ESOP_AGREEMENT,
          name: t('contract-viewer.tab.esopAgreement', 'ESOP Agreement'),
        },
      ];
    return undefined;
  }, [esopAgreement]);

  return (
    <DocumentViewer
      open={open}
      onClose={onClose}
      header={
        title ??
        (contractType === ContractType.CONTRACTOR
          ? aorDocumentType === AorDocumentType.ORDER_FORM
            ? t('order-form-viewer.header', 'Order Form')
            : t('agreement-viewer.header', 'Agreement')
          : t('contract-viewer.header', 'Contract'))
      }
      primaryButton={
        handleCtaClick && (
          <ToolTip
            tw="flex-grow"
            variant="bottom"
            content={
              uncommittedChanges ? (
                <span>
                  {t(
                    'contract-viewer.send-contract-tooltip',
                    'You have uncommitted changes below.',
                  )}
                </span>
              ) : contractBackdated ? (
                <span>
                  {t(
                    'contract-viewer.send-contract-backdated-tooltip',
                    'Employment start date cannot be backdated.',
                  )}
                </span>
              ) : undefined
            }
          >
            <Button
              size="medium"
              variant="default"
              onClick={handleCtaClick}
              disabled={
                uncommittedChanges || contractBlocked || contractBackdated
              }
              warning={uncommittedChanges || contractBackdated || undefined}
            >
              {contractType === ContractType.CONTRACTOR
                ? t('contract-viewer.send-order-form', 'Send Order Form')
                : t('contract-viewer.send-contract', 'Send Contract')}
            </Button>
          </ToolTip>
        )
      }
      secondaryButton={
        <IconButton
          name="file-download"
          size="medium"
          variant="outline"
          disabled={!fileToRender?.blob}
          onClick={fileToRender?.blob ? handleDownloadClick : undefined}
        />
      }
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={(key) => setSelectedTab(key as Tab)}
      loading={loading}
      contractModalPageType={contractModalPageType}
      setContractModalPageType={setContractModalPageType}
      requestChangesPageContent={requestChangesPageContent}
    >
      {header}
      {fileToRender?.blob && (
        <Document
          file={`data:application/pdf;base64,${fileToRender.blob}`}
          onLoadSuccess={({ numPages }) => setPageCount(numPages)}
          renderMode="svg"
        >
          {Array.from(new Array(pageCount), (_, index) => (
            // TODO: Proper responsive pdf width
            // https://github.com/wojtekmaj/react-pdf/issues/129
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={809} // hardcoded for now
            />
          ))}
        </Document>
      )}
      {footer}
    </DocumentViewer>
  );
};

export default ContractModal;
