import i18n from 'i18n';

import { Contract, ContractType } from '__generated__/graphql';

export const contractTypeLabels = {
  [ContractType.EMPLOYEE]: 'Employee',
  [ContractType.HR_MEMBER]: 'Payroll & Benefits',
  [ContractType.FREELANCER]: 'Contractor',
  [ContractType.CONTRACTOR]: 'AOR-Contractor',
};

const fillContractTypeLabels = () => {
  contractTypeLabels[ContractType.EMPLOYEE] = i18n.t(
    'common:contract-type-labels.employee',
    'Employee',
  );
  contractTypeLabels[ContractType.HR_MEMBER] = i18n.t(
    'common:contract-type-labels.hr-member',
    'Direct employee',
    {
      interpolation: {
        escapeValue: false,
      },
    },
  );
  contractTypeLabels[ContractType.FREELANCER] = i18n.t(
    'common:contract-type-labels.freelancer',
    'Contractor',
  );
  contractTypeLabels[ContractType.CONTRACTOR] = i18n.t(
    'common:contract-type-labels.contractor',
    'AOR-Contractor',
  );
};

export const getContractDocumentLabel = (
  contractTypeInput?: Contract['type'],
): string => {
  if (contractTypeInput === ContractType.CONTRACTOR) {
    return i18n.t('common:contract-text-label.contractor', 'Agreement');
  }
  return i18n.t('common:contract-text-label.other', 'Contract');
};

i18n.on('languageChanged init', () => {
  fillContractTypeLabels();
});
