/** @jsxImportSource @emotion/react */
import React, { useEffect, useLayoutEffect, useMemo } from 'react';

import { Controller, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import 'twin.macro';

import { useReactiveVar } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Callout,
  CalloutVariant,
  Icon,
  TextInputLabelVariant,
} from '@multiplier/common';
import { setOnboardingAttributes } from '@multiplier/growthbook';
import { useIsHRISOfferingEnabled } from '@multiplier/hris-member-management';
import { userVar } from '@multiplier/user';
import * as yup from 'yup';

import AppFeature from 'app/features';
import { notEmpty } from 'app/utils/array';
import ComboBox from 'common/components/combo-box';
import Radio from 'common/components/radio';
import TextInput from 'common/components/text-input';
import countryLabels from 'common/constants/country-labels';
import { WetInkCallOutHint } from 'contract-onboarding/company/components/wet-ink-signature';
import { eligibilityStep, initialStep } from 'contract-onboarding/company/vars';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import { GET_CONTRACT } from 'contract-onboarding/queries';
import { useGetCompanyEntities } from 'organization-settings/hooks/get-company-entities';

import {
  Capability,
  ContractOnboardingStep,
  ContractType,
  CountryCode,
  CountryWorkStatus,
  LegalEntityStatus,
  Maybe,
  useChangeCountryMutation,
  useGetCountryStatesLazyQuery,
} from '__generated__/graphql';

import { useContract } from '../../../../hooks';
import {
  countryWithStateList,
  getCountryStateAbbreviation,
  getStateLabel,
  getStateTitle,
} from '../../../../services/eligibility-states';
import stepConfig from '../../step-config';

export interface EligibilityFormValues {
  country?: Maybe<CountryCode>;
  state?: Maybe<string>;
  countryWorkStatus?: Maybe<CountryWorkStatus>;
  contractType?: ContractType;
  legalEntityId?: Maybe<string>;
}

const EligibilityView: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { id } = useParams<{ id?: string }>();
  const {
    contract: { legalEntityId, country, countryStateCode, workStatus, type },
  } = useContract(id);
  const isHrisClient = useIsHRISOfferingEnabled();
  const navigate = useNavigate();
  const { contractType: initialContractType } = useReactiveVar(initialStep);
  const { selectedCompany } = useReactiveVar(userVar);
  const [getCountryStates, { data }] = useGetCountryStatesLazyQuery();

  const contractType = type ?? initialContractType;

  const {
    get,
    data: entitiesData,
    loading: entitiesLoading,
  } = useGetCompanyEntities({
    company: selectedCompany,
  });

  useEffect(() => {
    if (isHrisClient)
      get({
        companyEntityStatuses: [LegalEntityStatus.ACTIVE],
        companyEntityCapabilities: [],
      });
    else
      get({
        companyEntityStatuses: [LegalEntityStatus.ACTIVE],
        companyEntityCapabilities: [
          Capability.GROSS_TO_NET,
          Capability.SALARY_DISBURSEMENT,
        ],
      });
  }, [isHrisClient]);

  const isSorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;
  const singleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;
  const displayEntityDropdown =
    contractType === ContractType.HR_MEMBER &&
    (isSorOnboardingEnabled || singleOnboardingEnabled);

  const schema = useMemo(
    () =>
      yup.object().shape({
        country: yup.string().oneOf(Object.values(CountryCode)).required(),
        state: yup.string().when(['country'], {
          is: (nameOfCountry: CountryCode) =>
            !countryWithStateList.includes(nameOfCountry),
          then: yup.string().nullable().notRequired(),
          otherwise: yup.string().required(),
        }),
        countryWorkStatus: yup.string().when({
          is: () => displayEntityDropdown,
          then: yup.string().nullable().notRequired(),
          otherwise: yup
            .string()
            .oneOf(Object.values(CountryWorkStatus))
            .required(),
        }),
        legalEntityId: yup.string().when({
          is: () => displayEntityDropdown,
          then: yup.string().required(),
          otherwise: yup.string().nullable().notRequired(),
        }),
      }),
    [displayEntityDropdown],
  );

  const {
    control,
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<EligibilityFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const selectedCountry = useWatch({ control, name: 'country' });
  const selectedLegalEntityId = useWatch({ control, name: 'legalEntityId' });

  const includedCountriesForHrMember = useFeature(
    'contract-onboarding-hr-member-countries',
  ).value;

  const excludedEORCountries = useFeature(
    'contract-onboarding-countries-exclusion-list',
  )?.value?.EOR;

  const excludedVisaCountries = useFeature(
    'contract-onboarding-countries-exclusion-list',
  )?.value?.VISA;

  const dropdownValues = useMemo(
    () =>
      Object.values(CountryCode)
        .filter((c) =>
          contractType === ContractType.EMPLOYEE && excludedEORCountries
            ? !excludedEORCountries?.includes(c)
            : true,
        )
        .filter((c) =>
          contractType === ContractType.HR_MEMBER &&
          includedCountriesForHrMember
            ? includedCountriesForHrMember.includes(c)
            : true,
        )
        .map((c) => ({
          label: countryLabels[c],
          value: c,
          title: c,
        })),
    [contractType, includedCountriesForHrMember],
  );

  const entitiesDropdownValues = useMemo(
    () =>
      entitiesData?.filter(notEmpty).map((c) => ({
        label: c?.legalName ?? '',
        value: c?.id ?? '',
        title: c?.address?.country?.toString() ?? '',
        country: c?.address?.country,
        icon: <Icon.Flag name={c?.address?.country?.toLocaleLowerCase()} />,
      })) ?? [],
    [entitiesData],
  );

  useLayoutEffect(() => {
    const selectedEntity = entitiesDropdownValues.find(
      (entity) => entity.value === selectedLegalEntityId,
    );
    if (selectedEntity?.country != null) {
      reset({ ...getValues(), country: selectedEntity.country });
    }
  }, [selectedLegalEntityId]);

  useEffect(() => {
    if (country !== selectedCountry) {
      reset({ ...getValues(), countryWorkStatus: null });
    }
    if (selectedCountry) {
      getCountryStates({
        variables: { country: selectedCountry as CountryCode },
      });
    }
    if (selectedCountry !== CountryCode.USA) {
      reset({ ...getValues(), state: null });
    }
  }, [selectedCountry]);

  const stateDropdownValues = useMemo(
    () =>
      (data?.country?.countryStates &&
        data.country.countryStates.filter(notEmpty).map((c) => ({
          key: c?.code ?? '',
          label: getStateLabel(data?.country?.code, c),
          value: c?.code ?? '',
          title: getStateTitle(data?.country?.code, c),
        }))) ??
      [],
    [data],
  );

  useEffect(() => {
    reset(
      {
        legalEntityId,
        country,
        state: countryStateCode,
        countryWorkStatus: workStatus,
      },
      { keepDirty: false },
    );
  }, [country, countryStateCode, workStatus]);

  const [changeCountry, { loading }] = useChangeCountryMutation();

  useEffect(() => {
    setOnboardingAttributes({
      country: selectedCountry || null,
      contractType: contractType || null,
      companyId: selectedCompany?.id || null,
    });
  }, [selectedCountry, contractType]);

  const onSubmit = async (values: EligibilityFormValues) => {
    if (id) {
      const contract = await changeCountry({
        variables: {
          id,
          input: {
            country: values.country,
            countryStateCode: values.state,
            workStatus: values.countryWorkStatus,
            legalEntityId: values.legalEntityId,
          },
        },
        // Possibility of contract properties getting reset in the backend due to
        // such a fundamental change, better to re-fetch everything in the cache.
        refetchQueries: [{ query: GET_CONTRACT, variables: { id } }],
      });

      if (contract.data?.contractChangeCountry?.onboarding?.current) {
        navigate(
          stepConfig[
            contract.data?.contractChangeCountry?.onboarding?.current
          ].directLink(contract.data?.contractChangeCountry.id),
        );
      }
    } else {
      eligibilityStep(values);
      navigate(
        stepConfig[
          ContractOnboardingStep.DEFINITION_MEMBER_BASIC_DETAILS
        ].directLink(id),
      );
    }
  };

  return (
    <StepLayout data-testid="eligibility-view">
      <FormLayout onSubmit={handleSubmit(onSubmit)}>
        {!displayEntityDropdown && (
          <FormCard>
            <TextInput.Container>
              <TextInput.Label variant={TextInputLabelVariant.LARGE}>
                {t('definition-phase.eligibility.country.label', {
                  defaultValue: 'Where is your employee working from?',
                  context: contractType,
                })}
              </TextInput.Label>
              <Controller
                name="country"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    data-testid="select-country"
                    variant="autocomplete"
                    dropdownValues={dropdownValues}
                    placeholder={t(
                      'definition-phase.eligibility.country.placeholder',
                      'Select Country',
                    )}
                    value={value as string}
                    onChange={onChange}
                    showArrow
                  />
                )}
              />
            </TextInput.Container>
            {countryWithStateList.includes(selectedCountry as CountryCode) && (
              <TextInput.Container>
                <TextInput.Label variant={TextInputLabelVariant.LARGE}>
                  {t(
                    `definition-phase.eligibility.state.${selectedCountry}.label`,
                    {
                      defaultValue:
                        'From which {{-state}} are they working from?',
                      replace: {
                        state:
                          selectedCountry &&
                          getCountryStateAbbreviation(
                            selectedCountry,
                          )?.toLowerCase(),
                      },
                    },
                  )}
                </TextInput.Label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ComboBox
                      data-testid="select-state"
                      variant="autocomplete"
                      dropdownValues={stateDropdownValues}
                      placeholder={t(
                        `definition-phase.eligibility.state.${selectedCountry}.placeholder`,
                        {
                          defaultValue: 'Select {{-state}}',
                          replace: {
                            state:
                              selectedCountry &&
                              getCountryStateAbbreviation(selectedCountry),
                          },
                        },
                      )}
                      showTitleOnButton={selectedCountry === CountryCode.CHN}
                      value={value as string}
                      onChange={onChange}
                      showArrow
                    />
                  )}
                />
              </TextInput.Container>
            )}
            {selectedCountry && (
              <TextInput.Container data-testid="work-status-section">
                <TextInput.Label variant={TextInputLabelVariant.LARGE}>
                  {t('definition-phase.eligibility.work-status.label', {
                    defaultValue:
                      'Is this employee able to work in {{country}}?',
                    replace: {
                      country: countryLabels[selectedCountry as CountryCode],
                    },
                    context: contractType,
                    interpolation: { escapeValue: false },
                  })}
                </TextInput.Label>
                <div tw="flex flex-col gap-y-base">
                  <Radio
                    aria-label="resident"
                    value={CountryWorkStatus.RESIDENT}
                    {...register('countryWorkStatus')}
                  >
                    <Trans
                      t={t}
                      i18nKey="definition-phase.eligibility.country-work-status.resident"
                    >
                      <span tw="font-semibold">Yes, </span>they are a citizen,
                      permanent resident or holds a visa that allows them to
                      work
                    </Trans>
                  </Radio>
                  <Radio
                    aria-label="requires visa"
                    value={CountryWorkStatus.REQUIRES_VISA}
                    disabled={excludedVisaCountries?.includes(selectedCountry)}
                    info={
                      excludedVisaCountries?.includes(selectedCountry)
                        ? t(
                            'definition-phase.eligibility.requires-visa.tooltip',
                            'Visa assistance for this country is currently unavailable. Please reach out to us if you need more information.',
                          )
                        : undefined
                    }
                    {...register('countryWorkStatus')}
                  >
                    <Trans
                      t={t}
                      i18nKey="definition-phase.eligibility.country-work-status.requires-visa"
                    >
                      <span tw="font-semibold">No, </span>I would like
                      Multiplier to assist me with getting a work visa
                    </Trans>
                  </Radio>
                </div>
              </TextInput.Container>
            )}
          </FormCard>
        )}
        {displayEntityDropdown && (
          <FormCard>
            <TextInput.Container>
              <TextInput.Label variant={TextInputLabelVariant.LARGE}>
                {t('definition-phase.eligibility.entity.label', 'Entity')}
              </TextInput.Label>
              <Controller
                name="legalEntityId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    data-testid="select-entity"
                    variant="autocomplete"
                    dropdownValues={entitiesDropdownValues}
                    placeholder={t(
                      'definition-phase.eligibility.entity.placeholder',
                      'Select',
                    )}
                    value={value as string}
                    onChange={onChange}
                    showArrow
                  />
                )}
              />
              {entitiesDropdownValues.length === 0 && !entitiesLoading && (
                <Callout
                  variant={CalloutVariant.INFO}
                  tw="flex mt-large p-small items-center"
                >
                  <Callout.Content tw="text-text-primary font-normal text-ps">
                    {t(
                      'definition-phase.eligibility.entity.no-entity-callout',
                      'Please get in touch with your AE/CSM to manage entities on Multiplier',
                    )}
                  </Callout.Content>
                </Callout>
              )}
              {countryWithStateList.includes(
                selectedCountry as CountryCode,
              ) && (
                <div tw="mt-large">
                  <TextInput.Label variant={TextInputLabelVariant.LARGE}>
                    {t(
                      `definition-phase.eligibility.state.${selectedCountry}.label`,
                      {
                        defaultValue:
                          'From which {{-state}} are they working from?',
                        replace: {
                          state:
                            selectedCountry &&
                            getCountryStateAbbreviation(
                              selectedCountry,
                            )?.toLowerCase(),
                        },
                      },
                    )}
                  </TextInput.Label>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ComboBox
                        tw="mt-small"
                        data-testid="select-state"
                        variant="autocomplete"
                        dropdownValues={stateDropdownValues}
                        placeholder={t(
                          `definition-phase.eligibility.state.${selectedCountry}.placeholder`,
                          {
                            defaultValue: 'Select {{-state}}',
                            replace: {
                              state:
                                selectedCountry &&
                                getCountryStateAbbreviation(selectedCountry),
                            },
                          },
                        )}
                        showTitleOnButton={selectedCountry === CountryCode.CHN}
                        value={value as string}
                        onChange={onChange}
                        showArrow
                      />
                    )}
                  />
                </div>
              )}
            </TextInput.Container>
          </FormCard>
        )}

        {selectedCountry && <WetInkCallOutHint />}

        <StepNavigationFooter
          disabled={!isValid}
          currentStep={currentStep}
          onboardingSteps={onboardingSteps}
          contractId={id}
          stepConfig={stepConfig}
          submitLoading={loading}
        />
      </FormLayout>
    </StepLayout>
  );
};

export default EligibilityView;
