import { InMemoryCache } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import schema from '@multiplier/supergraph/supergraph-api.json';

// eslint-disable-next-line no-underscore-dangle
const possibleTypes = schema.__schema.types.reduce(
  (prev: { [key: string]: string[] }, curr) => {
    if (curr.possibleTypes) {
      prev[curr.name] = curr.possibleTypes.map((subtype) => subtype.name);
    }
    return prev;
  },
  {},
);

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Contract: {
      fields: {
        compliance: {
          merge: (existing, incoming, { mergeObjects }) =>
            mergeObjects(existing, incoming),
        },
        onboarding: {
          merge: (existing, incoming, { mergeObjects }) =>
            mergeObjects(existing, incoming),
        },
        expenses: {
          merge: (_existing, incoming) => incoming,
        },
        compensation: {
          merge: (existing, incoming, { mergeObjects }) =>
            mergeObjects(existing, incoming),
        },
      },
    },
    Company: {
      fields: {
        primaryEntity: {
          merge: (_existing, incoming) => incoming,
        },
        contracts: {
          merge: (_existing, incoming) => incoming,
        },
        expenses: {
          merge: (_existing, incoming) => incoming,
        },
      },
    },
    Member: {
      fields: {
        legalDocuments: {
          merge: (_existing, incoming) => incoming,
        },
      },
    },
    Country: {
      keyFields: ['code'],
      fields: {
        holidays: {
          keyArgs: false,
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
    Holiday: {
      keyFields: ['key', 'countryCode', 'year', 'month', 'date'],
    },
    CountryCompliance: {
      keyFields: ['countryCode'],
    },
    LegalDocument: {
      keyFields: ['key', 'financialYear', 'files'],
    },
    // DO NOT CACHE Legal Data because it breaks member details update
    TimeOffTypeInfo: {
      keyFields: ['typeId'],
    },
    CustomerIntegration: {
      keyFields: ['id'],
    },
    LatestSyncResult: {
      keyFields: ['syncId'],
    },
  },
});

export default cache;
