import { useEffect, useMemo } from 'react';

import { notEmpty } from 'app/utils/array';

import {
  ComplianceInsightDefinition,
  Contract,
  GetCompliancePageInsightsQuery,
  InsightCategory,
  InsightDefinition,
  TimeOffInsightDefinition,
  useGetCompliancePageInsightsLazyQuery,
} from '__generated__/graphql';

import { CompliancePageInsightViewModel } from '../pages/definition-phase/pages/compliance';

const isTimeOffInsightDefinition = (
  curr: InsightDefinition,
): curr is TimeOffInsightDefinition =>
  curr.category === InsightCategory.TIME_OFF;

const isComplianceInsightDefinition = (
  curr: InsightDefinition,
): curr is ComplianceInsightDefinition =>
  curr.category === InsightCategory.COMPLIANCE;

export const mapInsightsToViewModel = (
  data: GetCompliancePageInsightsQuery | undefined,
): CompliancePageInsightViewModel =>
  data?.country?.insights
    ?.filter(notEmpty)
    .reduce((prev: CompliancePageInsightViewModel, curr) => {
      if (!curr?.category) return prev;

      if (!prev[curr.category]) prev[curr.category] = {};

      if (
        isTimeOffInsightDefinition(curr) &&
        curr.timeOffTypeDefinition?.type
      ) {
        // Typescript unable to infer that prev[curr.category] will never be undefined
        (prev[curr.category] as {
          [key: string]: TimeOffInsightDefinition;
        })[curr.timeOffTypeDefinition.type] = curr;
      } else if (
        isComplianceInsightDefinition(curr) &&
        curr.complianceParam?.key
      ) {
        // Typescript unable to infer that prev[curr.category] will never be undefined
        (prev[curr.category] as {
          [key: string]: ComplianceInsightDefinition;
        })[curr.complianceParam.key] = curr;
      }

      return prev;
    }, {}) ?? {};

const useCompliancePageInsights = (
  country: Contract['country'],
  type: Contract['type'],
  term: Contract['term'],
): { insights: CompliancePageInsightViewModel; loading: boolean } => {
  const [
    getInsights,
    { data, loading },
  ] = useGetCompliancePageInsightsLazyQuery();

  useEffect(() => {
    if (country && type && term) {
      getInsights({
        variables: { country, contractType: type, contractTerm: term },
      });
    }
  }, [country]);

  const insights = useMemo(() => mapInsightsToViewModel(data), [data]);

  return { insights, loading };
};

export default useCompliancePageInsights;
