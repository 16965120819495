/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';
import { useClickAway } from 'react-use';

import { ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { useSearchParams, useUpdateSearchParams } from 'app/hooks';
import ComboBox from 'common/components/combo-box';
import IconButton from 'common/components/icon-button';
import TextInput from 'common/components/text-input';

export enum PaginationVariant {
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
  MODAL = 'MODAL',
}

const Pagination: React.FC<{
  tableInstance: TableInstance;
  variant?: PaginationVariant;
  disableURLBinding?: boolean;
}> = ({
  tableInstance,
  variant = PaginationVariant.DEFAULT,
  disableURLBinding = false,
}) => {
  const [selectedPage, setSelectedPage] = useState('1');
  const pageTextRef = useRef(null);
  const [currentPage] = useSearchParams('page');
  const { t } = useTranslation('common');
  const updateSearchParams = useUpdateSearchParams();
  const { isNewThemeApplied } = useContext(ThemeContext);

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: { pageSize, pageIndex },
  } = tableInstance;

  useEffect(() => {
    if (currentPage && currentPage !== selectedPage) {
      handlePageChange(currentPage);
    }
  }, [currentPage, selectedPage]);

  useEffect(() => {
    const newPage = Number(pageIndex) + 1;
    setSelectedPage(newPage.toString());
    if (!disableURLBinding) {
      updateSearchParams([
        {
          key: 'page',
          value: newPage.toString(),
        },
        {
          key: 'rows',
          value: pageSize.toString(),
        },
      ]);
    }
  }, [pageIndex, pageSize]);

  const handlePageChange = (newPage: string | number) => {
    if (newPage && !Number.isNaN(Number(newPage))) {
      gotoPage(Number(newPage) - 1);
    }
  };

  const handlePageSizeChange = (val: string) => {
    setPageSize(Number(val));
  };

  useClickAway(pageTextRef, () => {
    handlePageChange(selectedPage);
  });

  const dropdownValues = useMemo(
    () => (variant === PaginationVariant.SMALL ? [3, 5, 10] : [5, 10, 15, 20]),
    [variant],
  );

  return (
    <>
      <div
        data-testid="pagination"
        css={[
          tw`flex items-center border-t border-border-primary py-extra-small`,
          !isNewThemeApplied && tw`border-grey04`,
          variant === PaginationVariant.SMALL
            ? tw` justify-between`
            : tw` justify-end`,
          variant === PaginationVariant.MODAL && tw` hidden`,
        ]}
      >
        <div
          css={[
            tw`flex justify-center items-center w-1/3 mobile:w-full`,
            variant === PaginationVariant.SMALL
              ? tw`justify-start`
              : tw`justify-center`,
            variant !== PaginationVariant.MODAL && tw`w-1/3`,
          ]}
        >
          <IconButton
            size="small"
            name="caret-left"
            data-testid="pagination-previous"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          <TextInput
            css={[tw`h-extra-large w-48 ml-tiny mr-small text-center`]}
            value={selectedPage}
            onChange={(e) => {
              setSelectedPage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handlePageChange(selectedPage);
              }
            }}
            ref={pageTextRef}
          />
          <span tw="mr-tiny" data-testid="pagination-length">
            / {pageOptions.length}
          </span>
          <IconButton
            size="small"
            name="caret-right"
            data-testid="pagination-next"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />
        </div>
        <div
          css={[
            tw`w-1/3 mobile:w-full`,
            variant === PaginationVariant.MODAL && tw`hidden`,
          ]}
        >
          <div tw="flex justify-end items-center mr-base">
            <span tw="text-ps mr-small">{t('table.show', 'Show')}: </span>
            <ComboBox
              data-testid="dropdown-select"
              showArrow
              placeholder=""
              value={String(pageSize)}
              variant="default"
              dropdownValues={dropdownValues.map((x) => ({
                title: `${x} ${t('table.rows', 'rows')}`,
                value: String(x),
              }))}
              onChange={(val) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
      </div>
      {variant === PaginationVariant.MODAL && (
        <div tw="flex justify-around">
          <div tw="flex gap-small">
            {pageOptions.map((page) => (
              <IconButton
                size="small"
                name="circle-thick"
                data-testid="pagination-next"
                onClick={() => gotoPage(page)}
                tw="text-ps h-extra-large w-small"
                fill={
                  pageIndex === page
                    ? !isNewThemeApplied
                      ? '#2240CD'
                      : theme`colors.icon-primary`
                    : !isNewThemeApplied
                    ? '#ABB2D9'
                    : theme`colors.icon-secondary`
                }
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
