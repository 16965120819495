import { useEffect } from 'react';

import {
  hasGpCapabilities,
  useGetCompanyEntities,
} from 'organization-settings/hooks/get-company-entities';

const useIsGlobalPayrollEnabled = (entityId: string): boolean => {
  const { get, data: legalEntities } = useGetCompanyEntities({
    companyEntityId: entityId,
  });

  useEffect(() => {
    if (entityId) get();
  }, [entityId]);

  const entity = legalEntities?.[0] ?? {};

  return hasGpCapabilities(entity);
};

export default useIsGlobalPayrollEnabled;
