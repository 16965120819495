/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import {
  CurrencyCode,
  CurrencyConversionSource,
  useGetCurrencyConversionLazyQuery,
} from '../../../__generated__/graphql';
import { useHomeCurrency } from '../../hooks';
import * as format from '../../utils/format';

interface CurrencyHelperProps {
  referenceCurrency?: CurrencyCode | null;
  amount?: number | null;
  maxLength?: number;
}

const CurrencyHelper: React.FC<CurrencyHelperProps> = ({
  referenceCurrency,
  amount,
  maxLength,
  ...props
}) => {
  const { i18n } = useTranslation('common');

  const [
    convertCurrency,
    { data, loading: loadingCurrencyConversation },
  ] = useGetCurrencyConversionLazyQuery();

  const { loading: loadingHomeCurrency, homeCurrency } = useHomeCurrency();

  useDebounce(
    () => {
      if (
        homeCurrency &&
        homeCurrency !== referenceCurrency &&
        referenceCurrency &&
        amount &&
        amount !== 0
      )
        convertCurrency({
          variables: {
            code: referenceCurrency,
            to: homeCurrency,
            amount: Number(amount) ?? 0,
            source: CurrencyConversionSource.ON_DEMAND,
          },
        });
    },
    500,
    [homeCurrency, referenceCurrency, amount],
  );

  const convertedString = useMemo(() => {
    let val = `${homeCurrency} ${format.numberAsCurrency(
      i18n.language,
      !amount || Number(amount) === 0 ? 0 : data?.currency?.convert,
      2,
    )}`;
    if (maxLength) {
      val = val.length > maxLength ? `${val.substring(0, maxLength)}...` : val;
    }
    return val;
  }, [homeCurrency, amount, data, maxLength]);

  return homeCurrency &&
    referenceCurrency &&
    homeCurrency !== referenceCurrency &&
    !(loadingCurrencyConversation || loadingHomeCurrency) ? (
    <div
      data-testid="currency-helper"
      tw="text-ps text-text-tertiary"
      {...props}
    >
      {convertedString}
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

export default CurrencyHelper;
