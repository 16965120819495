import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import { showModuleBasedOnContractStatusAndContractStarted } from 'app/utils/module-checks';
import client from 'client';
import { GET_MEMBER_CONTRACT_TYPE } from 'login/queries';

import {
  ContractStatus,
  GetMemberContractTypeQuery,
} from '__generated__/graphql';

const showModuleBasedOnBenefits = async () => {
  const response = await client.query<GetMemberContractTypeQuery>({
    query: GET_MEMBER_CONTRACT_TYPE,
  });

  return !!response.data.member?.contracts?.[0]?.benefits?.[0]?.benefit;
};

const config: ModuleConfig = {
  id: 'insurance',
  permission: 'insurance',
  folder: 'insurance/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Insurance',
    route: 'insurance',
    sort: 5,
    home: false,
    icon: { name: 'insurance' },
    activeIcon: { name: 'insurance-filled' },
    group: NavGroups.MAIN,
  },
  enabled: async () => {
    const shouldShow = await Promise.all([
      showModuleBasedOnContractStatusAndContractStarted([
        ContractStatus.ACTIVE,
        ContractStatus.ONBOARDING,
        ContractStatus.OFFBOARDING,
      ]),
      showModuleBasedOnBenefits(),
    ]);

    return Boolean(shouldShow[0]);
  },
};

export default config;
