import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { formatMonthCountToDurationString } from '@multiplier/format';
import { add, subDays } from 'date-fns';

import countryLabels from 'common/constants/country-labels';

import {
  Contract,
  ContractType,
  CountryCode,
  GetFixedTermRestrictionQuery,
  useGetFixedTermRestrictionLazyQuery,
} from '__generated__/graphql';

type FixedTermRestrictionQueryModel = NonNullable<
  NonNullable<GetFixedTermRestrictionQuery['country']>['compliance']
>['fixedTermRestriction'];

export const useEndDateNotes = (
  selectedCountry: CountryCode | null | undefined,
  fixedTermRestriction: FixedTermRestrictionQueryModel,
  selectedContractType?: Contract['type'],
): string => {
  const { t } = useTranslation('contract-onboarding');

  const maxTenureInMonths = fixedTermRestriction?.maxTenureInMonths;

  const formattedTenureLimit = formatMonthCountToDurationString(
    maxTenureInMonths,
  );

  const countryLabel = selectedCountry
    ? countryLabels[selectedCountry]
    : selectedCountry;

  if (selectedContractType === ContractType.CONTRACTOR) {
    return t('end-date.fixed-term-notes.aor-maximum-tenure.limited', {
      defaultValue:
        'The initial term of a contractor in {{country}} cannot be more than {{maxTenureInMonths}}. The contract may be renewed on a case by case basis.',
      replace: {
        country: countryLabel || 'this country',
        maxTenureInMonths: formattedTenureLimit,
      },
    });
  }

  if (
    maxTenureInMonths !== -1 &&
    fixedTermRestriction?.maxRenewalDurationInMonths !== -1
  ) {
    if (
      fixedTermRestriction?.maxRenewalCount !== -1 &&
      fixedTermRestriction?.maxRenewalCount !== 0
    ) {
      return t(
        'end-date.fixed-term-notes.maximum-tenure-and-renewal-count.limited',
        {
          defaultValue:
            '{{country}} can have maximum term of {{maxRenewalDurationInMonths}}.' +
            ' The contract can be renewed {{maxRenewalCount}} time(s) upto a maximum tenure of {{maxTenureInMonths}}.',
          replace: {
            country: countryLabel || 'Country',
            maxTenureInMonths: formattedTenureLimit,
            maxRenewalCount: fixedTermRestriction?.maxRenewalCount,
            maxRenewalDurationInMonths: formatMonthCountToDurationString(
              fixedTermRestriction?.maxRenewalDurationInMonths,
            ),
          },
        },
      );
    }
    if (fixedTermRestriction?.maxRenewalCount === -1) {
      return t(
        'end-date.fixed-term-notes.maximum-tenure-and-renewal-count.unlimited',
        {
          defaultValue:
            '{{country}} can have maximum term of {{maxRenewalDurationInMonths}}.' +
            ' The contract can be renewed as many times as required up to maximum tenure of {{maxTenureInMonths}}.',
          replace: {
            country: countryLabel || 'Country',
            maxTenureInMonths: formattedTenureLimit,
            maxRenewalDurationInMonths: formatMonthCountToDurationString(
              fixedTermRestriction?.maxRenewalDurationInMonths,
            ),
          },
        },
      );
    }
  }

  const tenureLimit =
    maxTenureInMonths === -1
      ? t(
          'end-date.fixed-term-notes.tenure-limit.unlimited',
          "There is no total tenure limit stipulated by this country's labour law.",
        )
      : t('end-date.fixed-term-notes.tenure-limit.limited', {
          defaultValue:
            "Tenure limit is till {{ tenureLimit }} due to country's labour law requirements.",
          replace: {
            tenureLimit: formattedTenureLimit,
          },
        });

  const maxRenewalCount = fixedTermRestriction?.maxRenewalCount;

  const renewalCountLimit =
    maxRenewalCount === -1
      ? t(
          'end-date.fixed-term-notes.maximum-renewal-count.unlimited',
          'This contract can be renewed as many times as required.',
        )
      : maxRenewalCount === 0
      ? t(
          'end-date.fixed-term-notes.maximum-renewal-count.zero',
          'This contract cannot be renewed further within this tenure.',
        )
      : t('end-date.fixed-term-notes.maximum-renewal-count.limited', {
          defaultValue:
            'You can renew this contract {{ maxRenewalCount }} times within this tenure',
          replace: {
            maxRenewalCount: maxRenewalCount ?? 0,
          },
        });

  return `${tenureLimit} ${renewalCountLimit}`;
};

const useEndDateRestrictions = ({
  selectedContractType,
  selectedCountry,
  selectedCountryState,
}: {
  selectedContractType: Contract['type'];
  selectedCountry: Contract['country'];
  selectedCountryState: Contract['countryStateCode'];
}): {
  getMinimumEndDate: (employmentStartDate: Date) => Date;
  getMaximumEndDate: (employmentStartDate: Date) => Date;
  notes: string;
  loading: boolean;
  fixedTermRestriction: FixedTermRestrictionQueryModel;
} => {
  const [get, { data, loading }] = useGetFixedTermRestrictionLazyQuery();

  useEffect(() => {
    if (!selectedContractType || !selectedCountry) {
      return;
    }

    get({
      variables: {
        countryCode: selectedCountry,
        countryStateCode: selectedCountryState,
        contractType: selectedContractType,
        fixedTermRestrictionFilter: {
          contractType: selectedContractType,
        },
      },
    });
  }, [selectedContractType, selectedCountry, selectedCountryState]);

  const fixedTermRestriction = data?.country?.compliance?.fixedTermRestriction;

  const minRenewalDuration = fixedTermRestriction?.minRenewalDurationInMonths;
  const maxRenewalDuration = fixedTermRestriction?.maxRenewalDurationInMonths;

  const getMinimumEndDate = useCallback(
    (employmentStartDate: Date) => {
      if (!minRenewalDuration || minRenewalDuration === -1) {
        return employmentStartDate;
      }

      return subDays(
        add(new Date(employmentStartDate), {
          months: minRenewalDuration,
        }),
        1,
      );
    },
    [minRenewalDuration],
  );

  const getMaximumEndDate = useCallback(
    (employmentStartDate: Date) => {
      if (!maxRenewalDuration || maxRenewalDuration === -1) {
        return new Date(3000, 0, 1);
      }

      return subDays(
        add(new Date(employmentStartDate), {
          months: maxRenewalDuration,
        }),
        1,
      );
    },
    [maxRenewalDuration],
  );

  const notes = useEndDateNotes(
    selectedCountry,
    fixedTermRestriction,
    selectedContractType,
  );

  return {
    getMinimumEndDate,
    getMaximumEndDate,
    notes,
    loading,
    fixedTermRestriction,
  };
};

export default useEndDateRestrictions;
