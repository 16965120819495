import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'company-time-off',
  permission: 'time-off',
  folder: 'time-off/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Time off',
    route: routes.root,
    sort: 1,
    home: false,
    group: CompanyNavGroups.ADMINISTRATION,
  },
};

export default config;
