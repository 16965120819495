import { useEffect, useMemo } from 'react';

import { Experience } from '@multiplier/user';

import {
  CompanyOffering,
  OfferingCode,
  useGetCompanyBasicQuery,
  useGetCompanyOfferingsLazyQuery,
  useGetMemberCompanyOfferingsLazyQuery,
} from '../__generated__/graphql';

const hasHrisOffering = (
  offerings: (CompanyOffering | null)[] | null | undefined,
) => offerings?.some((offer) => offer?.offering === OfferingCode.HRIS) ?? false;

const useIsHRISOfferingEnabled = ({
  experience = Experience.COMPANY,
}: Partial<{
  experience: Experience;
}> = {}): boolean => {
  const { data: companyBasic } = useGetCompanyBasicQuery();

  const [
    getCompanyOfferings,
    { data: companyOfferingData },
  ] = useGetCompanyOfferingsLazyQuery();

  const [
    getMemberCompanyOfferings,
    { data: memberCompanyOfferingsData },
  ] = useGetMemberCompanyOfferingsLazyQuery();

  useEffect(() => {
    if (experience === Experience.MEMBER) {
      getMemberCompanyOfferings();
    } else {
      getCompanyOfferings();
    }
  }, []);

  const isHrisClient = useMemo(() => {
    if (experience === Experience.COMPANY) {
      return hasHrisOffering(companyOfferingData?.company?.offerings);
    }

    const memberCompanyOfferings = memberCompanyOfferingsData?.member?.contracts?.filter(
      (contract) => contract?.company?.id === companyBasic?.company?.id,
    )?.[0]?.company;

    return hasHrisOffering(memberCompanyOfferings?.offerings);
  }, [
    companyOfferingData,
    memberCompanyOfferingsData,
    experience,
    companyBasic,
  ]);

  return isHrisClient;
};

export default useIsHRISOfferingEnabled;
