import { useEffect, useRef, useState } from 'react';

interface UseScrollXControlProps {
  border?: number;
}
const useScrollXControl = ({ border }: UseScrollXControlProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const [scrollable, setScrollable] = useState({
    left: false,
    right: false,
  });

  const resizeObserver = useRef<ResizeObserver>();

  const borderWidth = border ?? 0;

  const onCheckScrollable = () => {
    if (parentRef.current && childRef.current) {
      const parentBounding = parentRef.current.getBoundingClientRect();
      const childBounding = childRef.current.getBoundingClientRect();
      setScrollable({
        left: parentBounding?.left - borderWidth > childBounding?.left,
        right: parentBounding.right + borderWidth < childBounding.right,
      });
    }
  };

  const handleScrollRight = (distance: number) => {
    if (parentRef.current) {
      const { scrollLeft } = parentRef.current;
      parentRef.current.scrollTo({
        left: scrollLeft + distance,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollLeft = (distance: number) => {
    if (parentRef.current) {
      const { scrollLeft } = parentRef.current;
      parentRef.current.scrollTo({
        left: scrollLeft - distance,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    onCheckScrollable();
    if (parentRef.current && childRef.current) {
      parentRef.current.addEventListener('scroll', onCheckScrollable);
      if (!resizeObserver.current) {
        resizeObserver.current = new ResizeObserver(onCheckScrollable);
      }
      resizeObserver.current.observe(parentRef.current);
      resizeObserver.current.observe(childRef.current);
    }
    return () => {
      if (parentRef.current) {
        parentRef.current.removeEventListener('scroll', onCheckScrollable);
        parentRef.current.removeEventListener('resize', onCheckScrollable);
        resizeObserver.current?.disconnect();
      }
    };
  }, [parentRef.current, childRef.current]);

  return {
    parentRef,
    childRef,
    scrollable,
    handleScrollRight,
    handleScrollLeft,
  };
};

export default useScrollXControl;
