import { useNavigate } from 'react-router-dom';

import {
  useCreateAndDeleteManager,
  useValidateAndAssignManager,
} from '@multiplier/hris-member-management';

import {
  Contract,
  ContractOnboardingStep,
  Manager,
  useDepartmentAssignEmployeesMutation,
  useUpdatePreFetchDetailsMutation,
  useUpdateWorkEmailMutation,
} from '__generated__/graphql';

import useSubmitBasicDetails from '../../hooks/submit-basic-details';
import useSubmitEmploymentDetails from '../../hooks/submit-employment-details';
import { BasicDetailsFormValues } from '../pages/definition-phase/pages/basic-details/basic-details';
import stepConfig from '../pages/definition-phase/step-config';

export const useSubmitExistingBasicDetails = (
  contractIdFromPath: string | undefined,
  contract: Contract,
  manager: Manager | null | undefined,
): {
  onSubmit: (values: BasicDetailsFormValues) => Promise<void>;
  loading: boolean;
} => {
  const navigate = useNavigate();

  const existingMember = contract.member;
  const currentReportsToManagerId =
    manager?.reportsToManager?.id ?? contract.reportsToManager?.id;

  const {
    onSubmit: updateEmployeeDetails,
    loading: loadingUpdateEmployeeDetails,
  } = useSubmitBasicDetails(existingMember);
  const {
    onSubmit: updateEmploymentDetails,
    loading: loadingUpdateEmploymentDetails,
  } = useSubmitEmploymentDetails(contractIdFromPath);

  const [
    updateLegalDetails,
    { loading: loadingLegalDetailsUpdate },
  ] = useUpdatePreFetchDetailsMutation();

  const {
    handleAssignManager,
    isValidating,
    assigningManager,
  } = useValidateAndAssignManager();

  const {
    creatingManager,
    createPeopleManager,
    deletePeopleManager,
  } = useCreateAndDeleteManager();

  const [
    assignEmployeesToDepartment,
    { loading: departmentAssignLoading },
  ] = useDepartmentAssignEmployeesMutation();

  const updateDepartment = async (
    department: string | undefined,
    managerId: string | undefined,
  ) => {
    if (
      contractIdFromPath &&
      department &&
      department !== contract.orgAttributes?.department?.id
    ) {
      await assignEmployeesToDepartment({
        variables: {
          input: {
            departmentId: department,
            employeeContractIds: managerId ? [] : [contractIdFromPath],
            employeeManagerIds: managerId ? [managerId] : [],
          },
        },
      });
    }
  };

  const [
    updateWorkEmail,
    { loading: updatingWorkEmail },
  ] = useUpdateWorkEmailMutation();

  const onSubmit = async (values: BasicDetailsFormValues) => {
    if (!contractIdFromPath) return;

    await updateEmployeeDetails(values);
    const onboarding = await updateEmploymentDetails({
      term: values.contractTerm,
      position: values.jobTitle,
      country: values.taxResidency,
      state: values.countryStateCode,
      startOn: values.startOn,
      endOn: values.endOn,
      scope: values.scope,
      workShift: values?.workShift,
      employeeId: values.employeeId,
      workEmail: values.workEmail,
    });

    if (
      values?.preDataRequirements &&
      values?.preDataRequirements?.length &&
      existingMember?.id
    )
      await updateLegalDetails({
        variables: {
          memberId: existingMember.id,
          dateFieldInputs: values?.preDataRequirements
            ?.filter((req) => req.type === 'date')
            .map((req) => ({
              key: req.key,
              value: req.value,
            })),
          memberDataInputs: values?.preDataRequirements
            ?.filter((req) => req.type === 'text')
            .map((req) => ({
              key: req.key,
              value: req.value as string,
            })),
        },
      });

    // Org management data section - start
    // TODO: unassign department & reporting manager if they are removed
    const currentManagerId = manager?.id;
    let managerId = currentManagerId;

    if (values.isPeopleManager && !currentManagerId) {
      await createPeopleManager(contractIdFromPath).then(({ data }) => {
        managerId = data?.managerCreate?.id;
      });
    } else if (values.isPeopleManager === false && currentManagerId) {
      await deletePeopleManager(currentManagerId).then(({ data }) => {
        if (data?.managerDelete?.id) {
          managerId = undefined;
        }
      });
    }

    await updateDepartment(values.department, managerId);

    if (
      values.reportingManager &&
      values.reportingManager !== currentReportsToManagerId
    ) {
      await handleAssignManager(
        values.reportingManager,
        contractIdFromPath,
        managerId,
      );
    }
    // Org management data section - end

    if (values.workEmail && values.workEmail !== contract.workEmail) {
      await updateWorkEmail({
        variables: {
          id: contractIdFromPath,
          email: {
            name: 'work',
            value: values.workEmail,
          },
        },
      });
    }

    if (onboarding?.steps) {
      const current = onboarding?.steps.find(
        (x) => x === ContractOnboardingStep.DEFINITION_MEMBER_BASIC_DETAILS,
      );
      const currentIndex = current && onboarding?.steps?.indexOf(current);
      const next =
        (currentIndex || currentIndex === 0) &&
        onboarding.steps[currentIndex + 1];
      navigate(
        next
          ? stepConfig[next].directLink(contractIdFromPath)
          : stepConfig[onboarding.current as ContractOnboardingStep].directLink(
              contractIdFromPath,
            ),
      );
    }
  };
  return {
    onSubmit,
    loading:
      loadingUpdateEmployeeDetails ||
      loadingUpdateEmploymentDetails ||
      loadingLegalDetailsUpdate ||
      assigningManager ||
      isValidating ||
      creatingManager ||
      departmentAssignLoading ||
      updatingWorkEmail,
  };
};

export default useSubmitExistingBasicDetails;
