/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import { isUndefined } from 'lodash';
import tw from 'twin.macro';

import { getDynamicFields } from 'contract-onboarding/member/services/bank-data';

import { PaymentAccountRequirement } from '__generated__/graphql';

export enum BankAccountType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export const useGetSwiftFeeField = (
  bankAccountType: BankAccountType,
  paymentAccountRequirementsMap: Map<string, PaymentAccountRequirement>,
  paymentAccountRequirementType?: string,
): string | undefined => {
  const swiftFeeField = useMemo(() => {
    if (
      bankAccountType === BankAccountType.DYNAMIC &&
      !isUndefined(paymentAccountRequirementType)
    ) {
      const swiftFieldIndex = Array.from(
        getDynamicFields(
          paymentAccountRequirementsMap.get(paymentAccountRequirementType)
            ?.requirementFields,
        )?.values() ?? [],
      )?.findIndex((field) => /swift|bic/i.test(field?.key ?? ''));

      if (swiftFieldIndex != null && swiftFieldIndex !== -1)
        return `bankData.${swiftFieldIndex}.value`;
    }
    if (bankAccountType === BankAccountType.STATIC) return 'swiftCode';
    return undefined;
  }, [
    bankAccountType,
    paymentAccountRequirementType,
    paymentAccountRequirementsMap,
  ]);

  return swiftFeeField;
};

const BoldText = tw.span`font-semibold`;

export const SwiftFeeInfo: React.FC = () => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('contract-onboarding.member');
  return (
    <div
      css={[
        tw`p-base rounded-base gap-x-base bg-background-warning-subtle flex`,
        !isNewThemeApplied && tw`bg-blue200`,
      ]}
      data-testid="swift-fee-info-modal"
    >
      <div tw="font-weight[400] text-text-primary text-ps ">
        <Trans t={t} i18nKey="swift-fee.info.extra-swift-fee-charges">
          A deduction equivalent to <BoldText>$35</BoldText> could be levied as
          your bank doesn&apos;t follow the SWIFT network guidelines. We suggest
          you to either add <BoldText>$35</BoldText> equivalent in all your
          invoices or change the bank account to receive the payment in full.
        </Trans>
      </div>
    </div>
  );
};
