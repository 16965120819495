/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import { Button } from '@multiplier/common';
import { Experience, userVar } from '@multiplier/user';
import FileSaver from 'file-saver';
import { TFunction } from 'i18next';
import type { Dictionary } from 'lodash';
import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import tw, { styled } from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import { accountRequirementTypeLabel } from 'contract-onboarding/member/pages/onboarding/pages/bank-details';
import {
  BankDynamicDetailField,
  getDynamicFields,
} from 'contract-onboarding/member/services/bank-data';
import { useCancelChangeRequest } from 'team/hooks';

import {
  BankAccount,
  BankAccountDetail,
  BankAccountParam,
  Contract,
  Maybe,
  MemberChangeCategory,
  MemberChangeRequest,
  PaymentAccountRequirement,
  RequirementField,
} from '__generated__/graphql';

export type BankAccountParamUpdated = BankAccountParam & {
  bankAccountValue?: Maybe<BankAccount>;
};

const CurrentTitle = styled.span(({ isLast }: { isLast?: boolean }) => [
  tw`py-tiny pl-base text-text-secondary`,
  isLast && tw`mb-base`,
]);
const CurrentValue = styled.span(({ isLast }: { isLast?: boolean }) => [
  tw`py-tiny pr-base text-text-secondary justify-self-end`,
  isLast && tw`mb-base`,
]);
const PendingTitle = styled.span(({ isLast }: { isLast?: boolean }) => [
  tw`py-tiny pl-base text-text-primary border-l border-border-primary`,
  isLast && tw`mb-base`,
]);
const PendingValue = styled.span(({ isLast }: { isLast?: boolean }) => [
  tw`py-tiny pr-base text-text-primary justify-self-end`,
  isLast && tw`mb-base`,
]);

const getValidFieldDefinition = (
  field: BankDynamicDetailField,
  bankAccountDetailsKeyMap: Dictionary<Maybe<BankAccountDetail>>,
) => {
  const bankAccountDetailsKeys = Object.keys(bankAccountDetailsKeyMap) ?? [];
  const validReq = field?.requirements?.subFields?.find(
    (f) =>
      bankAccountDetailsKeys.includes(field.key) &&
      f.parentField &&
      bankAccountDetailsKeyMap[f.parentField] === f.targetValue,
  );
  if (validReq) return validReq;
  if (field?.requirements?.default) return field?.requirements?.default;
  return undefined;
};

const getBankAccountKeyValue = (
  t: TFunction<'contract-onboarding.member'>,
  bankAccount?: Maybe<BankAccount>,
  requirementFields?: Maybe<Maybe<RequirementField>[]>,
  contractCurrency?: Contract['currency'],
) => {
  const isStaticAccount = bankAccount?.version !== 2;

  if (isStaticAccount)
    return Object.entries(
      pick(bankAccount, [
        'accountName',
        'accountNumber',
        'bankName',
        'branchName',
        'localBankCode',
        'swiftCode',
      ]),
    )
      ?.map((entry) => ({
        label: t(
          `onboarding-phase.bank-details.changes.items.${entry[0]}`,
          startCase(entry[0]),
        ),
        value: entry[1] as string,
      }))
      ?.filter((i) => !!i.value)
      ?.sort((a, b) => (a.label > b?.label ? 1 : -1));

  const bankAccountDetailsKeyMap = mapKeys(
    bankAccount?.accountDetails,
    (item) => item?.key,
  );

  const fields =
    Array.from(getDynamicFields(requirementFields)?.values() ?? [])
      ?.reduce((acc, field) => {
        const fieldDefinition = getValidFieldDefinition(
          field,
          bankAccountDetailsKeyMap,
        );
        if (fieldDefinition)
          acc.push({
            label: fieldDefinition?.label ?? '',
            value:
              fieldDefinition?.type === 'radio' ||
              fieldDefinition?.type === 'select'
                ? fieldDefinition?.allowedValues?.find(
                    (v) =>
                      v?.value ===
                      bankAccountDetailsKeyMap?.[field?.key]?.value,
                  )?.key ?? ''
                : bankAccountDetailsKeyMap?.[field?.key]?.value ?? '',
          });
        return acc;
      }, [] as { label: string; value: string }[])
      ?.filter((i) => !!i.value)
      ?.sort((a, b) => (a.label > b?.label ? 1 : -1)) ?? [];

  fields?.unshift({
    label: t(
      `bank-details.comparison.paymentAccountRequirementType.label`,
      'Account Requirement Type',
    ),
    value:
      accountRequirementTypeLabel({
        targetCurrency: contractCurrency,
        requirementType: bankAccount?.paymentAccountRequirementType ?? '',
        t,
      }) ?? '',
  });
  return fields;
};

const ComparisonView: React.FC<{
  member: Contract['member'];
  changeRequest?: Maybe<MemberChangeRequest>;
  lastSubmitOn?: Maybe<Date>;
  paymentAccountRequirementsMap: Map<string, PaymentAccountRequirement>;
  updateData?: () => void;
  onApproveRequest?: () => void;
  onRejectRequest?: () => void;
}> = ({
  member,
  changeRequest,
  lastSubmitOn,
  paymentAccountRequirementsMap,
  updateData,
  onApproveRequest,
  onRejectRequest,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const isMemberView = current === Experience.MEMBER;

  const {
    cancelChangeRequest,
    loading: cancelLoading,
  } = useCancelChangeRequest(MemberChangeCategory.BANK_DETAILS, updateData);

  const pendingAccountDetails = useMemo(
    () =>
      getBankAccountKeyValue(
        t,
        (changeRequest?.items?.[0] as BankAccountParamUpdated)
          ?.bankAccountValue,
        (changeRequest?.items?.[0] as BankAccountParamUpdated)?.bankAccountValue
          ?.paymentAccountRequirementType
          ? paymentAccountRequirementsMap.get(
              (changeRequest?.items?.[0] as BankAccountParamUpdated)
                ?.bankAccountValue?.paymentAccountRequirementType as string,
            )?.requirementFields
          : undefined,
      ) ?? [],
    [changeRequest?.items?.[0], paymentAccountRequirementsMap],
  );

  const currentAccountDetails = useMemo(
    () =>
      getBankAccountKeyValue(
        t,
        member?.bankAccounts?.[0],
        member?.bankAccounts?.[0]?.paymentAccountRequirementType
          ? paymentAccountRequirementsMap.get(
              member?.bankAccounts?.[0]?.paymentAccountRequirementType,
            )?.requirementFields
          : undefined,
      ) ?? [],
    [member?.bankAccounts?.[0], paymentAccountRequirementsMap],
  );

  const rowsToRender = useMemo(
    () =>
      Math.max(pendingAccountDetails?.length, currentAccountDetails?.length),
    [pendingAccountDetails, currentAccountDetails],
  );

  const checkIfLastRow = (index: number) =>
    index + 1 === rowsToRender && !changeRequest?.files?.length;

  return (
    <div tw="border border-border-primary p-large rounded-base">
      <div tw="grid grid-cols-4 border border-border-primary rounded-base">
        <CurrentTitle tw="text-text-primary font-semibold col-span-2 pt-base">
          {t(
            'onboarding-phase.bank-details.changes.current.title',
            'Current details (Active)',
          )}
        </CurrentTitle>
        <PendingTitle tw="text-text-primary font-semibold col-span-2 pt-base">
          {t(
            'onboarding-phase.bank-details.changes.pending.title',
            'New details (Submitted on {{ date }})',
            {
              replace: {
                date: lastSubmitOn && dateAsString(lastSubmitOn, 'd MMM'),
              },
            },
          )}
        </PendingTitle>
        {Array.from(Array(rowsToRender)?.keys()).map((index) => (
          <React.Fragment key={index}>
            <CurrentTitle isLast={checkIfLastRow(index)}>
              {currentAccountDetails?.[index]?.label}
            </CurrentTitle>
            <CurrentValue isLast={checkIfLastRow(index)}>
              {currentAccountDetails?.[index]?.value}
            </CurrentValue>
            <PendingTitle isLast={checkIfLastRow(index)}>
              {pendingAccountDetails?.[index]?.label}
            </PendingTitle>
            <PendingValue isLast={checkIfLastRow(index)}>
              {pendingAccountDetails?.[index]?.value}
            </PendingValue>
          </React.Fragment>
        ))}

        {changeRequest?.files?.length ? (
          <>
            <CurrentTitle />
            <CurrentValue />
            <PendingTitle tw="col-span-2">
              <Button
                tw="text-ps px-none pt-extra-small pb-base flex"
                variant="inline"
                onClick={() => {
                  changeRequest?.files?.forEach((file) => {
                    if (file?.downloadUrl)
                      FileSaver.saveAs(file?.downloadUrl, file?.name ?? '');
                  });
                }}
              >
                {t(
                  'onboarding-phase.bank-details.changes.pending.submitted-documents',
                  'Submitted document',
                )}
              </Button>
            </PendingTitle>
          </>
        ) : undefined}
      </div>
      {isMemberView ? (
        <Button
          tw="mt-base"
          variant="secondary"
          loading={cancelLoading}
          onClick={() => cancelChangeRequest()}
          size="small"
        >
          {t('contract-onboarding.common.cancel-request', 'Cancel Request')}
        </Button>
      ) : (
        <div tw="flex items-center justify-center gap-extra-small mt-base">
          <Button size="small" variant="secondary" onClick={onApproveRequest}>
            {t('contract-onboarding.common.approve-request', 'Approve request')}
          </Button>
          <Button
            size="small"
            variant="secondary"
            intent="negative"
            onClick={onRejectRequest}
          >
            {t('contract-onboarding.common.reject-request', 'Reject request')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ComparisonView;
