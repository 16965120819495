import React, { SVGProps, startTransition, useContext, useEffect } from 'react';

import { ThemeContext } from '../theme-provider';
import rebrandConfig from './rebrand-config';
import { IconStyle } from './types';

const importFlagIcon = async (name: string) => {
  let icon;
  const country = name.replace('flags/', '');

  try {
    if (country !== undefined && country !== '') {
      icon = await import(`./icons/flags/${country}.svg`);
    }
  } catch (e) {
    icon = null;
  }

  return icon;
};
const importMaterialSymbolIcon = async (name: string) => {
  let icon;

  try {
    icon = await import(`./material-symbols/${name}.svg`);
  } catch (e) {
    icon = null;
  }

  return icon;
};

const importIconFromExistingLibrary = async (name: string) => {
  let icon;
  try {
    icon = await import(`./icons/${name}.svg`);
  } catch (e) {
    icon = null;
  }
  return icon;
};

const Flags: {
  [key: string]: {
    ReactComponent: React.FC<SVGProps<SVGSVGElement>>;
  };
} = {};

const useAsyncIconLoader = ({
  name,
  forceCustom,
}: {
  name: string;
  materialStyle: IconStyle | undefined;
  forceCustom: boolean;
}): {
  icon: { ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> } | undefined;
  loading: boolean;
} => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  const [icon, setIcon] = React.useState<{
    ReactComponent: React.FC<SVGProps<SVGSVGElement>>;
  }>();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);

    const importIcon = async () => {
      let importedIcon;
      // @rollup/plugin-dynamic-import-vars only supports dynamically importing files in 1 level of depth
      // to import files in nested directories, we must specify the immediate parent of it
      // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#globs-only-go-one-level-deep
      if (name?.includes('flags/')) {
        importedIcon = await importFlagIcon(name);
        Flags[name] = importedIcon;
      }

      const isNewThemeToggledOnAndIconExistsInMappingConfig =
        !importedIcon &&
        isNewThemeApplied &&
        Object.keys(rebrandConfig).includes(name);

      if (isNewThemeToggledOnAndIconExistsInMappingConfig && !forceCustom) {
        importedIcon = await importMaterialSymbolIcon(rebrandConfig[name].name);
      }

      // if icon is not found in the rebrand config, try to import it from the existing library
      if (!importedIcon) {
        importedIcon = await importIconFromExistingLibrary(name);
      }

      // if icon is not found in the existing library, try again to import it from the rebrand config
      if (!importedIcon) {
        importedIcon = await importMaterialSymbolIcon(name);
      }

      if (importedIcon) {
        setIcon(importedIcon);
      }

      if (isSubscribed) {
        setLoading(false);
      }
    };

    startTransition(() => {
      // prevent loading indicator
      importIcon();
    });

    return () => {
      isSubscribed = false;
    };
  }, [name, isNewThemeApplied]);

  return {
    icon: name?.includes('flags/') ? Flags[name] : icon,
    loading,
  };
};

export default useAsyncIconLoader;
