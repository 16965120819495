import { gql } from '@apollo/client';

export default gql`
  query GetEquipmentsForCompany($companyId: ID!) {
    company(id: $companyId) {
      id
      equipments {
        id
        productId
        serialNumber
        purchasedOn
        type
        name
        description
        status
        documents {
          id
          type
          document {
            id
            name
            extension
            contentType
            link
          }
        }
        dynamicDetails {
          key
          value
        }
      }
    }
  }
`;
