/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import {
  Controller,
  FieldArrayWithId,
  FieldPath,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Button, ComboBox, DropdownValue, TextInput } from '@multiplier/common';
import { SorOnboardingContext } from '@multiplier/hris-member-management';
import { TFunction } from 'i18next';
import isUndefined from 'lodash/isUndefined';
import tw from 'twin.macro';

import { useModal } from 'app/hooks';
import { notEmpty } from 'app/utils/array';
import * as DropdownText from 'common/components/dropdown-text';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  Contract,
  ContractTerm,
  ContractType,
  CountryCode,
  CountryCompensationStandards,
  CountryRateFrequency,
  CurrencyCode,
  FixedPayComponent,
  Maybe,
  PayType,
  RateFrequency,
  RateType,
} from '__generated__/graphql';

import { rateFrequencyLabel } from '../../../../common/constants/default-labels';
import PayRateDialog from '../pay-rate-dialog';
import {
  AmountInput,
  CurrencyInput,
  PayInputCurrencyHelper,
} from './components/amount-input';
import BasicPayInput from './components/eor-basic-pay-input';
import PayInputHeaderTooltip from './components/pay-input-header-tool-tip';

export const defaultFixedPayComponentLabels: {
  [key: string]: {
    label: (
      t:
        | TFunction<'contract-onboarding.common'>
        | TFunction<'performance-reviews'>
        | TFunction<'team.company'>,
    ) => string;
    description: (t: TFunction<'contract-onboarding.common'>) => string;
  };
} = {
  [`${RateType.GROSS},${RateFrequency.MONTHLY},base`]: {
    label: (t) =>
      t('compensation.gross-monthly-salary.label', 'Gross Monthly Salary'),
    description: (t) =>
      t(
        'compensation.gross-monthly-salary.description',
        'Description of the gross monthly salary',
      ),
  },
  [`${RateType.GROSS},${RateFrequency.ANNUALLY},base`]: {
    label: (t) =>
      t('compensation.gross-annual-salary.label', 'Gross Annual Salary'),
    description: (t) =>
      t(
        'compensation.gross-annual-salary.description',
        'Description of the gross annual salary',
      ),
  },
  [`${RateType.GROSS},${RateFrequency.HOURLY},base`]: {
    label: (t) =>
      t('compensation.gross-hourly-salary.label', 'Gross Hourly Salary'),
    description: (t) =>
      t(
        'compensation.gross-hourly-salary.description',
        'Description of the gross hourly salary',
      ),
  },
  [`${RateType.CTC},${RateFrequency.MONTHLY},base`]: {
    label: (t) =>
      t(
        'compensation.ctc-monthly-salary.label',
        'Salary - Monthly cost to company (CTC)',
      ),
    description: (t) =>
      t(
        'compensation.ctc-monthly-salary.description',
        'Description of Salary - Monthly cost to company (CTC)',
      ),
  },
  [`${RateType.CTC},${RateFrequency.ANNUALLY},base`]: {
    label: (t) =>
      t(
        'compensation.ctc-annual-salary.label',
        'Salary - Annual cost to company (CTC)',
      ),
    description: (t) =>
      t(
        'compensation.ctc-annual-salary.description',
        'Description of Salary - Annual cost to company (CTC)',
      ),
  },
  [`${RateType.CTC},${RateFrequency.HOURLY},base`]: {
    label: (t) =>
      t(
        'compensation.ctc-hourly-salary.label',
        'Salary - Hourly cost to company (CTC)',
      ),
    description: (t) =>
      t(
        'compensation.ctc-hourly-salary.description',
        'Description of Salary - Hourly cost to company (CTC)',
      ),
  },
  [`${RateType.NET},${RateFrequency.MONTHLY},base`]: {
    label: (t) =>
      t(
        'compensation.net-monthly-salary.label',
        'Estimated Monthly Net Salary',
      ),
    description: (t) =>
      t(
        'compensation.net-monthly-salary.description',
        'Description of Estimated Monthly Net Salary',
      ),
  },
  [`${RateType.NET},${RateFrequency.ANNUALLY},base`]: {
    label: (t) =>
      t(
        'compensation.net-annual-salary.label',
        'Salary - Annual cost to company (CTC)',
      ),
    description: (t) =>
      t(
        'compensation.net-annual-salary.description',
        'Description of Estimated Annual Net Salary',
      ),
  },
  [`${RateType.NET},${RateFrequency.HOURLY},base`]: {
    label: (t) =>
      t(
        'compensation.net-hourly-salary.label',
        'Salary - Hourly cost to company (CTC)',
      ),
    description: (t) =>
      t(
        'compensation.net-hourly-salary.description',
        'Description of Estimated Hourly Net Salary',
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.HOURLY},base`]: {
    label: (t) => t('compensation.fixed-hourly-salary.label', 'Hourly Pay'),
    description: (t) =>
      t(
        'compensation.fixed-hourly-salary.description',
        'Description of hourly pay',
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.DAILY},base`]: {
    label: (t) => t('compensation.fixed-daily-salary.label', 'Daily Pay'),
    description: (t) =>
      t(
        'compensation.fixed-daily-salary.description',
        'Description of daily pay',
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.WEEKLY},base`]: {
    label: (t) => t('compensation.fixed-weekly-salary.label', 'Weekly Pay'),
    description: (t) =>
      t(
        'compensation.fixed-weekly-salary.description',
        'Description of weekly pay',
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.SEMIMONTHLY},base`]: {
    label: (t) =>
      t(
        'compensation.fixed-semimonthly-salary.label',
        'Semi-Monthly Pay Amount',
      ),
    description: (t) =>
      t(
        'compensation.fixed-semimonthly-salary.description',
        'The payment will be calculated based on the semi-monthly rate amount provided here. Payments are usually done at the end of every month.',
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.MONTHLY},base`]: {
    label: (t) => t('compensation.fixed-monthly-salary.label', 'Monthly Pay'),
    description: (t) =>
      t(
        'compensation.fixed-monthly-salary.description',
        'Description of monthly pay',
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.ANNUALLY},base`]: {
    label: (t) => t('compensation.fixed-annual-salary.label', 'Annual Pay'),
    description: (t) =>
      t(
        'compensation.fixed-annual-salary.description',
        'Description of annual pay',
      ),
  },
};

export const defaultFixedPayLabelUndefinedFrequency: {
  [key: string]: {
    label: (
      t:
        | TFunction<'contract-onboarding.common'>
        | TFunction<'performance-reviews'>
        | TFunction<'team.company'>,
    ) => string;
    description: (t: TFunction<'contract-onboarding.common'>) => string;
  };
} = {
  [`${RateType.GROSS},base`]: {
    label: (t) => t('compensation.gross-salary.label', 'Gross Salary'),
    description: (t) =>
      t(
        'compensation.gross-salary.description',
        'Enter the salary of the employee',
      ),
  },
  [`${RateType.CTC},base`]: {
    label: (t) =>
      t('compensation.ctc-salary.label', 'Salary - Cost to Company (CTC)'),
    description: (t) =>
      t(
        'compensation.ctc-salary.description',
        'Enter the salary of the employee',
      ),
  },
  [`${RateType.NET},base`]: {
    label: (t) => t('compensation.net-salary.label', 'Estimated Net Salary'),
    description: (t) =>
      t(
        'compensation.net-salary.description',
        'Enter the salary of the employee',
      ),
  },
  [`${ContractTerm.FIXED},base`]: {
    label: (t) => t('compensation.fixed-salary.label', 'Pay Amount'),
    description: (t) =>
      t(
        'compensation.fixed-salary.description',
        'Enter the salary of the employee',
      ),
  },
};

export const defaultFixedPayComponentLabelsProbationToggle: {
  [key: string]: {
    label: (
      t:
        | TFunction<'contract-onboarding.common'>
        | TFunction<'performance-reviews'>
        | TFunction<'team.company'>,
    ) => string;
    description: (
      t: TFunction<'contract-onboarding.common'>,
      salaryType?: string | undefined,
    ) => string;
  };
} = {
  [`${RateType.GROSS},${RateFrequency.MONTHLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.gross-monthly-salary.label-new',
        'Total Monthly salary',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.gross-monthly-salary.description',
        'Gross salary amount that will be paid to the employee for a month {{salaryType}}. This amount does not include contributions and deductions.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.GROSS},${RateFrequency.ANNUALLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.gross-annual-salary.label-new',
        'Total Annual salary',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.gross-annual-salary.description',
        'Gross salary amount that will be paid to the employee for a year {{salaryType}}. This amount does not include contributions and deductions.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.GROSS},${RateFrequency.HOURLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.gross-hourly-salary.label-new',
        'Total Hourly salary',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.gross-hourly-salary.description',
        'Gross salary amount that will be paid to the employee for an hour {{salaryType}}. This amount does not include contributions and deductions.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.CTC},${RateFrequency.MONTHLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.ctc-monthly-salary.label',
        'Monthly cost to company (CTC)',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.ctc-monthly-salary.description',
        'The salary amount that will be the total cost to the company for a month {{salaryType}}. This amount includes all contributions and costs to the company.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.CTC},${RateFrequency.ANNUALLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.ctc-annual-salary.label',
        'Annual cost to company (CTC)',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.ctc-annual-salary.description',
        'The salary amount that will be the total cost to the company for a year {{salaryType}}. This amount includes all contributions and costs to the company.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.CTC},${RateFrequency.HOURLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.ctc-hourly-salary.label',
        'Hourly cost to company (CTC)',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.ctc-hourly-salary.description',
        'The salary amount that will be the total cost to the company for an hour {{salaryType}}. This amount includes all contributions and costs to the company.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.NET},${RateFrequency.MONTHLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.net-monthly-salary.label',
        'Estimated Monthly Net Salary',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.net-monthly-salary.description',
        'The estimated Net salary amount that will be paid to the employee for a month {{salaryType}}. This will be the approximate amount after all deductions.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.NET},${RateFrequency.ANNUALLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.net-annual-salary.label',
        'Annual cost to company (CTC)',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.net-annual-salary.description',
        'The estimated Net salary amount that will be paid to the employee for a year {{salaryType}}. This will be the approximate amount after all deductions.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${RateType.NET},${RateFrequency.HOURLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.net-hourly-salary.label',
        'Salary - Hourly cost to company (CTC)',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.net-hourly-salary.description',
        'The estimated Net salary amount that will be paid to the employee for an hour {{salaryType}}. This will be the approximate amount after all deductions.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.HOURLY},base`]: {
    label: (t) =>
      t('compensation-toggleOn.fixed-hourly-salary.label', 'Hourly Pay'),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.fixed-hourly-salary.description',
        'The payment will be calculated based on the hourly amount provided here {{salaryType}}. Payments are usually done at the end of every month.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.DAILY},base`]: {
    label: (t) =>
      t('compensation-toggleOn.fixed-daily-salary.label', 'Daily Pay'),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.fixed-daily-salary.description',
        'The payment will be calculated based on the daily amount provided here {{salaryType}}. Payments are usually done at the end of every month.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.WEEKLY},base`]: {
    label: (t) =>
      t('compensation-toggleOn.fixed-weekly-salary.label', 'Weekly Pay'),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.fixed-weekly-salary.description',
        'The payment will be calculated based on the weekly rate amount provided here {{salaryType}}. Payments are usually done at the end of every month.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.SEMIMONTHLY},base`]: {
    label: (t) =>
      t(
        'compensation-toggleOn.fixed-semimonthly-salary.label',
        'Semi-Monthly Pay Amount',
      ),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.fixed-semimonthly-salary.description',
        'The payment will be calculated based on the semi-monthly rate amount provided here {{salaryType}}. Payments are usually done at the end of every month.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.MONTHLY},base`]: {
    label: (t) =>
      t('compensation-toggleOn.fixed-monthly-salary.label', 'Monthly Pay'),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.fixed-monthly-salary.description',
        'The payment will be calculated based on the monthly amount provided here {{salaryType}}. Payments are usually done at the end of every month.',
        {
          replace: { salaryType },
        },
      ),
  },
  [`${ContractTerm.FIXED},${RateFrequency.ANNUALLY},base`]: {
    label: (t) =>
      t('compensation-toggleOn.fixed-annual-salary.label', 'Annual Pay'),
    description: (t, salaryType) =>
      t(
        'compensation-toggleOn.fixed-annual-salary.description',
        'The payment will be calculated based on the year amount provided here {{salaryType}}. Payments are usually done at the end of every month.',
        {
          replace: { salaryType },
        },
      ),
  },
};

export const getFixedPayInputLabelText = ({
  contractTerm,
  rateType,
  frequency,
  label,
  t,
  isProbationToggleOn,
}: {
  contractTerm: Contract['term'];
  rateType: FixedPayComponent['rateType'];
  frequency: FixedPayComponent['frequency'];
  label: FixedPayComponent['label'];
  t:
    | TFunction<'contract-onboarding.common'>
    | TFunction<'performance-reviews'>
    | TFunction<'team.company'>;
  isProbationToggleOn?: boolean;
}): string => {
  if (isProbationToggleOn)
    return (
      defaultFixedPayComponentLabelsProbationToggle[
        `${
          contractTerm === ContractTerm.PERMANENT ? rateType : 'FIXED'
        },${frequency},${label}`
      ]?.label(t) ??
      t(
        `compensation.${
          contractTerm === ContractTerm.PERMANENT
            ? rateType?.toString().toLowerCase()
            : 'fixed'
        }-${frequency?.toString().toLowerCase()}-${label}.label`,
      )
    );

  if (isUndefined(frequency))
    return (
      defaultFixedPayLabelUndefinedFrequency[
        `${
          contractTerm === ContractTerm.PERMANENT ? rateType : 'FIXED'
        },${label}`
      ]?.label(t) ??
      t(
        `compensation.${
          contractTerm === ContractTerm.PERMANENT
            ? rateType?.toString().toLowerCase()
            : 'fixed'
        }-${label}.label`,
      )
    );

  return (
    defaultFixedPayComponentLabels[
      `${
        contractTerm === ContractTerm.PERMANENT ? rateType : 'FIXED'
      },${frequency},${label}`
    ]?.label(t) ??
    t(
      `compensation.${
        contractTerm === ContractTerm.PERMANENT
          ? rateType?.toString().toLowerCase()
          : 'fixed'
      }-${frequency?.toString().toLowerCase()}-${label}.label`,
    )
  );
};

export const FixedPayInputLabel: React.FC<{
  fieldPath: FixedPayInputProps['fieldPath'];
  fixedPayComponent: FixedPayInputProps['payComponent'];
  contractTerm: Contract['term'];
}> = ({ fieldPath, fixedPayComponent, contractTerm }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const { watch } = useFormContext();

  return (
    <TextInput.Label tw="font-semibold">
      {getFixedPayInputLabelText({
        contractTerm,
        rateType: watch(`${fieldPath}.rateType`),
        frequency: watch(`${fieldPath}.frequency`),
        label: fixedPayComponent.label,
        t,
      })}
    </TextInput.Label>
  );
};

export interface FixedPayInputProps {
  contractType?: Maybe<ContractType>;
  contractTerm?: Maybe<ContractTerm>;
  country?: Contract['country'];
  fieldPath: FieldPath<CompensationFormValues>;
  payComponent: FixedPayComponent;
  currencyOptions: Maybe<DropdownValue[]>;
  localCurrency: Maybe<CurrencyCode> | undefined;
  fieldArrayElement?: FieldArrayWithId<CompensationFormValues, 'other'>;
  countryRateFrequencies?: CountryRateFrequency['list'];
  countryHourlyRates?: CountryCompensationStandards['hourlyRates'];
  hideLabel?: boolean;
  inline?: boolean;
  isRateFrequencyInputDisabled?: boolean;
  sorOnboardingContext?: SorOnboardingContext;
}

const FixedPayInput: React.FC<FixedPayInputProps> = ({
  contractType,
  contractTerm,
  country,
  fieldPath,
  payComponent,
  currencyOptions,
  localCurrency,
  fieldArrayElement,
  inline = false,
  hideLabel = false,
  countryRateFrequencies,
  countryHourlyRates,
  isRateFrequencyInputDisabled = false,
  sorOnboardingContext,
  ...props
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const memberHourlyPayEnabled = useFeature('contract-onboarding-hourly-pay')
    ?.on;

  const isContractOnboardingHourlyPayEnabled = useMemo(
    () =>
      memberHourlyPayEnabled &&
      (fieldPath === 'basePay' || fieldPath === 'probationBasePay'),
    [memberHourlyPayEnabled, fieldPath],
  );

  const [
    showPayRateDialog,
    handleClosePayRateDialog,
    handleOpenPayRateDialog,
  ] = useModal();

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CompensationFormValues>();

  const shouldShowFrequencyDropdown = useMemo(() => {
    if (isContractOnboardingHourlyPayEnabled) return false;
    return (
      (contractTerm === ContractTerm.FIXED &&
        contractType !== ContractType.FREELANCER) ||
      (contractType === ContractType.FREELANCER &&
        watch('payType') === PayType.DYNAMIC)
    );
  }, [
    watch('payType'),
    contractType,
    contractTerm,
    isContractOnboardingHourlyPayEnabled,
  ]);

  const rateFrequencies = [
    RateFrequency.HOURLY,
    RateFrequency.DAILY,
    RateFrequency.WEEKLY,
    RateFrequency.SEMIMONTHLY,
    RateFrequency.MONTHLY,
  ];

  const filteredRateFrequencies = useMemo(
    () =>
      filterRateFrequencies(
        rateFrequencies,
        contractTerm,
        contractType,
        country,
      ).filter(notEmpty),
    [contractType, contractTerm, country],
  );

  if (isContractOnboardingHourlyPayEnabled) {
    return (
      <BasicPayInput
        inline={inline}
        hideLabel={hideLabel}
        contractType={contractType}
        contractTerm={contractTerm}
        fieldPath={fieldPath}
        payComponent={payComponent}
        currencyOptions={currencyOptions}
        localCurrency={localCurrency}
        fieldArrayElement={fieldArrayElement}
        countryRateFrequencies={countryRateFrequencies}
        country={country}
        countryHourlyRates={countryHourlyRates}
        isRateFrequencyInputDisabled={isRateFrequencyInputDisabled}
        sorOnboardingContext={sorOnboardingContext}
        {...props}
      />
    );
  }

  return (
    <div
      data-testid={`fixed-pay-input-${payComponent.label}`}
      tw="flex flex-col gap-y-large"
      {...props}
    >
      {shouldShowFrequencyDropdown && !fieldArrayElement && (
        <TextInput.Container
          data-testid="pay-rate-select"
          css={inline && tw`grid grid-cols-4 items-center`}
        >
          <TextInput.Label htmlFor="frequency-select" tw="font-semibold">
            {t(
              `definition-phase.compensation.${payComponent.label}.frequency.label`,
              'Pay Rate',
            )}
          </TextInput.Label>
          <Controller
            name={`${fieldPath}.frequency` as FieldPath<CompensationFormValues>}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="frequency-dropdown"
                id="frequency-select"
                css={[inline && tw`col-span-3 flex flex-row`]}
                variant="default"
                value={value as string}
                placeholder={t(
                  `compensation.${payComponent.label}.frequency.placeholder`,
                  'Select pay rate',
                )}
                dropdownValues={filteredRateFrequencies.map((frequency) => ({
                  title: rateFrequencyLabel[frequency],
                  value: frequency,
                }))}
                onChange={onChange}
              />
            )}
          />
          {errors.payrollConfigId && (
            <TextInput.Error>{errors.payrollConfigId.message}</TextInput.Error>
          )}
        </TextInput.Container>
      )}
      <TextInput.Container
        css={inline && tw`grid grid-cols-4 items-center gap-x-tiny`}
      >
        <div
          css={[tw`flex justify-between items-center`, !inline && tw`mb-small`]}
        >
          <div tw="flex flex-row gap-x-small">
            {!hideLabel && (
              <FixedPayInputLabel
                fieldPath={fieldPath}
                fixedPayComponent={payComponent}
                contractTerm={contractTerm}
              />
            )}
            {!inline && !sorOnboardingContext?.isHrisOnlyFlow && (
              <PayInputHeaderTooltip
                payComponent={payComponent}
                contractTerm={contractTerm}
                fieldPath={fieldPath}
              />
            )}
          </div>
          {!inline &&
            contractTerm === ContractTerm.PERMANENT &&
            !fieldArrayElement && (
              <Button
                variant="inline"
                tw="text-ps px-tiny"
                onClick={handleOpenPayRateDialog}
              >
                {t('compensation.change-pay-component-button', 'Change')}
              </Button>
            )}
        </div>
        <div css={inline && tw`col-span-3 flex flex-row`}>
          <DropdownText.Container
            tw="flex-grow"
            data-testid={`fixed-pay-currency-input-${payComponent.label}`}
          >
            <CurrencyInput
              fieldPath={fieldPath}
              currencyOptions={currencyOptions}
              fieldArrayElement={fieldArrayElement}
              disabled={
                contractType !== ContractType.FREELANCER &&
                currencyOptions?.length === 1
              }
            />
            <AmountInput
              fieldPath={fieldPath}
              fieldArrayElement={fieldArrayElement}
            />
          </DropdownText.Container>
          {inline &&
            !fieldArrayElement &&
            contractTerm === ContractTerm.PERMANENT && (
              <Button variant="inline" onClick={handleOpenPayRateDialog}>
                {t('compensation.change-pay-component-button', 'Change')}
              </Button>
            )}
        </div>
        {inline && <div data-testid="spacer" />}
        <PayInputCurrencyHelper fieldPath={fieldPath} />
      </TextInput.Container>
      <PayRateDialog
        open={showPayRateDialog}
        onClose={handleClosePayRateDialog}
        fieldPath={fieldPath}
        fieldArrayElement={fieldArrayElement}
        country={country}
      />
    </div>
  );
};

/**
 * To follow some requirements about which rate frequencies should/should not be in the dropdown based on type, term, country
 *
 * @param rateFrequencies original array. At least [MONTHLY] (but usually more) depending on country labor standards (otherwise seems corrupted data)
 * @param country the contract country
 * @param contractType
 * @param contractTerm
 * @return filtered rateFrequencies
 */
export const filterRateFrequencies = (
  rateFrequencies?: Maybe<RateFrequency>[],
  contractTerm?: Maybe<ContractTerm>,
  contractType?: Maybe<ContractType>,
  country?: Maybe<CountryCode>,
): Maybe<RateFrequency>[] => {
  if (rateFrequencies == null) {
    return [];
  }

  if (contractTerm !== ContractTerm.FIXED) {
    return rateFrequencies;
  }

  // remove ANNUALLY for FIXED term
  rateFrequencies = rateFrequencies.filter(
    (freq) => freq !== RateFrequency.ANNUALLY,
  );

  // restrict more for EOR FIXED term
  if (contractType === ContractType.EMPLOYEE) {
    rateFrequencies =
      country === CountryCode.PHL || country === CountryCode.NLD
        ? rateFrequencies.filter(
            (rateFreq) =>
              rateFreq === RateFrequency.MONTHLY ||
              rateFreq === RateFrequency.HOURLY,
          )
        : rateFrequencies.filter(
            (rateFreq) => rateFreq === RateFrequency.MONTHLY,
          );
  }
  return rateFrequencies;
};

export default FixedPayInput;
