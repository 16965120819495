/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';

import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useClickAway, useKey } from 'react-use';

import { Button, ButtonVariant, IconButton } from '@multiplier/common';
import tw, { TwStyle, theme } from 'twin.macro';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  asset?: React.ReactNode;
  icon?: string;
  title: string;
  description?: string;
  buttonText: string;
  loading?: boolean;
  disabled?: boolean;
  containerStyles?: TwStyle;
  buttonVariant?: ButtonVariant;
}

export const BaseConfirmationDialog: React.FC<
  React.PropsWithChildren<ConfirmationDialogProps>
> = ({
  onClose,
  onConfirm,
  title,
  buttonText,
  disabled = false,
  loading = false,
  containerStyles,
  buttonVariant = 'default',
  children,
}) => {
  const { t } = useTranslation('common');
  const dialogRef = useRef(null);
  useClickAway(dialogRef, () => {
    onClose();
  });

  useKey('Escape', () => {
    onClose();
  });

  return (
    <Lightbox>
      <Container
        data-testid="modal"
        ref={dialogRef}
        data-cy="modal"
        css={[containerStyles]}
      >
        <div tw="absolute right-extra-small top-small">
          <IconButton
            size="medium"
            name="cross"
            fill={theme`colors.icon-primary`}
            onClick={onClose}
          />
        </div>
        <div tw="pt-base px-extra-large flex flex-col gap-y-large justify-center items-center">
          <h5 tw="w-full text-center text-h5 font-semibold text-text-primary">
            {title}
          </h5>
          {children}
        </div>
        <div tw="w-full text-center my-large">
          <Button
            variant="outline"
            size="medium"
            tw="mr-small"
            onClick={onClose}
          >
            {t('confirmation-dialog.cancel', 'Cancel')}
          </Button>
          <Button
            data-cy="confirm-button"
            variant={buttonVariant}
            size="medium"
            onClick={onConfirm}
            disabled={loading || disabled}
            loading={loading}
          >
            {buttonText}
          </Button>
        </div>
      </Container>
    </Lightbox>
  );
};

const ConfirmationDialog: React.FC<
  React.PropsWithChildren<ConfirmationDialogProps>
> = (props) => {
  const { open } = props;

  if (open) {
    return ReactDOM.createPortal(
      <BaseConfirmationDialog {...props} />,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }
  return null;
};

const Lightbox = tw.div`h-screen w-screen fixed top-none left-none flex justify-center items-center bg-background-overlay bg-opacity-60 z-40`;
const Container = tw.div`bg-background-white h-auto width[640px] rounded-base relative overflow-hidden`;

export default Object.assign(ConfirmationDialog, { Lightbox, Container });
