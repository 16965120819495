/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Icon, IconButton, Popover, ThemeContext } from '@multiplier/common';
import { routes as offboardingRoutes } from '@multiplier/contract-offboarding';
import { usePermission } from '@multiplier/user';
import { theme } from 'twin.macro';

import { userVar } from 'app/vars';

import { Contract, ContractOffboardingType } from '__generated__/graphql';

const OffboardingButton: React.FC<{
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon: React.ReactElement;
  description: string;
}> = ({ onClick, icon, description }) => (
  <Popover.Button data-testid="reschedule-offboarding-button" onClick={onClick}>
    <div tw="flex flex-row items-center gap-x-small">
      {icon}
      {description}
    </div>
  </Popover.Button>
);

const OffboardingActions: React.FC<{
  contractId: Contract['id'];
  contractOffboarding: Contract['offboarding'];
  handleCancelClick: () => void;
}> = ({
  contractId,
  contractOffboarding,
  handleCancelClick,
}): React.ReactElement => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('team.company');
  const location = useLocation();
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const [
    canRescheduleCompanyContractOffboarding,
    canCancelCompanyContractOffboarding,
  ] = usePermission(
    'reschedule.company.contract.offboarding',
    'cancel.company.contract.offboarding',
  );

  return (
    <Popover
      key="offboarding.action-cell.popover"
      popOver={
        <div tw="flex flex-col">
          {canCancelCompanyContractOffboarding && (
            <OffboardingButton
              onClick={(e) => {
                e.stopPropagation();
                handleCancelClick();
              }}
              icon={
                <Icon
                  name="cancel"
                  fill="transparent"
                  stroke={theme`colors.icon-primary`}
                />
              }
              description={t(
                'offboarding.action-cell.cancel',
                'Cancel offboarding',
              )}
            />
          )}
          {contractOffboarding?.type !== ContractOffboardingType.DID_NOT_JOIN &&
            canRescheduleCompanyContractOffboarding && (
              <Link
                to={`${offboardingRoutes.root}/${offboardingRoutes.reschedule}`}
                state={{
                  background: location,
                  contractId,
                  experience: current,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <OffboardingButton
                  icon={
                    <Icon
                      name="redo"
                      fill={theme`colors.icon-primary`}
                      stroke={theme`colors.icon-primary`}
                    />
                  }
                  description={t(
                    'offboarding.action-cell.reschedule',
                    'Reschedule Offboarding',
                  )}
                />
              </Link>
            )}
        </div>
      }
    >
      <IconButton
        data-testid="offboarding.action-cell.trigger"
        variant="outline"
        size="medium"
        name="more-vertical"
        stroke={!isNewThemeApplied ? theme`colors.icon-primary` : undefined}
        fill={!isNewThemeApplied ? 'transparent' : undefined}
      />
    </Popover>
  );
};

export default OffboardingActions;
