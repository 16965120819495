import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';
import { handlers } from '@multiplier/notifications';

import { useResendMemberInviteForContractMutation } from '../../../../__generated__/graphql';
import TaskEntry from '../task-entry';
import { TaskEntryProps } from '../types';

const { errorNotification, successNotification } = handlers;

const PlatformOnboardingTask: React.FC<TaskEntryProps> = ({
  task,
  contractId,
  actionable,
  isResendInviteVisible,
}) => {
  const { t } = useTranslation('contract-onboarding');

  const [onInvite, { loading }] = useResendMemberInviteForContractMutation({
    onError() {
      errorNotification(
        '',
        t('resend-invite.error.message', 'Failed to re-send invite'),
        false,
      );
    },
    onCompleted() {
      successNotification(
        t('resend-invite.notification.title', 'Success'),
        t('resend-invite.notification.subtitle', {
          defaultValue: 'Invite re-sent',
        }),
        false,
      );
    },
  });

  const resendInvite = async () => {
    if (!contractId) {
      return;
    }

    await onInvite({
      variables: {
        id: contractId,
      },
    });
  };

  return (
    <TaskEntry
      name={t(
        'onboarding-checklist.task-list.resend-invite.title',
        'Platform onboarding',
      )}
      action={
        isResendInviteVisible ? (
          <Button
            size="small"
            tw="text-ps"
            variant="secondary"
            loading={loading}
            onClick={resendInvite}
            disabled={!actionable}
          >
            {t(
              'onboarding-checklist.task-list.resend-invite.action',
              'Re-send invite',
            )}
          </Button>
        ) : undefined
      }
      completed={task.completed}
    />
  );
};

export default PlatformOnboardingTask;
