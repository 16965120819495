import React, { useRef } from 'react';

import ReactDOM from 'react-dom';
import { useClickAway, useKey } from 'react-use';

import tw from 'twin.macro';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  closeOnClickAway?: boolean;
  children: React.ReactNode;
}

export const BaseModal: React.FC<ModalProps> = ({
  onClose,
  header,
  footer,
  closeOnClickAway = true,
  children,
  ...props
}) => {
  const dialogRef = useRef(null);
  useClickAway(dialogRef, () => {
    if (closeOnClickAway) {
      onClose();
    }
  });

  useKey('Escape', () => {
    onClose();
  });

  return (
    <LightBox>
      <Container data-testid="modal" ref={dialogRef} data-cy="modal" {...props}>
        {header}
        {children}
        {footer && <Footer>{footer}</Footer>}
      </Container>
    </LightBox>
  );
};

const Modal: React.FC<ModalProps> = (props) => {
  const { open } = props;

  if (open) {
    return ReactDOM.createPortal(
      <BaseModal {...props} />,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }
  return null;
};

const LightBox = tw.div`h-screen w-screen fixed top-0 left-0 flex justify-center items-center bg-background-overlay z-40`;
const Container = tw.div`bg-background-white h-auto min-width[360px] rounded-base relative p-large`;
const Footer = tw.div`absolute bottom-0 left-0 right-0`;

export default Object.assign(Modal, { LightBox, Container });
