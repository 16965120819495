import React, { useContext } from 'react';

import tw from 'twin.macro';

import Card from '../../../card';
import { ThemeContext } from '../../../theme-provider';

const List: React.FC = () => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const getRange = (n: number) => Array.from(Array(n).keys());

  return (
    <Card data-testid="loader-list">
      <div tw="flex flex-col mx-base divide-y divide-text-tertiary">
        {getRange(3).map((i) => (
          <div tw="w-full h-full flex py-base" key={i}>
            <div tw="w-1/6 flex mr-tiny mt-extra-small">
              <div tw="w-full py-base animate-pulse ml-base">
                <div
                  css={[
                    tw`rounded-tiny ml-none h-48 w-48 bg-background-primary`,
                    !isNewThemeApplied && tw`bg-grey04`,
                  ]}
                />
              </div>
            </div>
            <div tw="w-full flex">
              <div tw="w-full py-large animate-pulse">
                <div
                  css={[
                    tw`h-12 rounded-tiny w-1/3 ml-base bg-background-action-hover`,
                    !isNewThemeApplied && tw`bg-grey02`,
                  ]}
                />
                {i === 0 && (
                  <div
                    css={[
                      tw`h-12 rounded-tiny w-1/3 ml-base mt-base bg-background-primary`,
                      !isNewThemeApplied && tw`bg-grey04`,
                    ]}
                  />
                )}
                {i === 1 && (
                  <div
                    css={[
                      tw`h-12 rounded-tiny w-4/6 ml-base mt-base bg-background-primary`,
                      !isNewThemeApplied && tw`bg-grey04`,
                    ]}
                  />
                )}
                {i === 2 && (
                  <div
                    css={[
                      tw`h-12 rounded-tiny w-11/12 ml-base mt-base bg-background-primary`,
                      !isNewThemeApplied && tw`bg-grey04`,
                    ]}
                  />
                )}
              </div>
            </div>
            <div tw="w-1/2 flex">
              <div tw="w-1/3 py-large animate-pulse ml-46">
                <div
                  css={[
                    tw`h-12 rounded-tiny w-1/3 bg-background-action-hover ml-105`,
                    !isNewThemeApplied && tw`bg-grey02`,
                  ]}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default List;
