import type { Table } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { UseClientPaginatedTableOptions } from '../types';

const useClientPaginatedTableNoUrlState = <TData extends unknown>(
  options: UseClientPaginatedTableOptions<TData>,
): {
  tableInstance: Table<TData>;
} => {
  const {
    sorting,
    setSorting,
    expanded,
    setExpanded,
    rowSelection,
    setRowSelection,
  } = options.tableState;

  const tableInstance = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...options,
    state: {
      sorting,
      expanded,
      rowSelection,
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
  });

  return {
    tableInstance,
  };
};

export default useClientPaginatedTableNoUrlState;
