import {
  PerformanceReview,
  PerformanceReviewStatus,
} from '__generated__/graphql';

export enum Steps {
  UPDATE_DETAILS = 'UPDATE_DETAILS',
  SIGNATORY_SIGNS = 'SIGNATORY_SIGNS',
  EMPLOYEE_SIGNS = 'EMPLOYEE_SIGNS',
  CONFIRM_DETAILS = 'CONFIRM_DETAILS',
  COMPLETED = 'COMPLETED',
}

export const getCurrentStep = (
  status: PerformanceReviewStatus,
  payrollCycle: PerformanceReview['payrollCycle'],
  isPartnerEorOrHrMember: boolean,
): Steps => {
  if (isPartnerEorOrHrMember) {
    switch (status) {
      case PerformanceReviewStatus.DRAFT:
        return Steps.CONFIRM_DETAILS;
      case PerformanceReviewStatus.SENT_TO_OPS:
      case PerformanceReviewStatus.ACTIVATED:
        return Steps.COMPLETED;
      default:
        return Steps.UPDATE_DETAILS;
    }
  }

  switch (status) {
    case PerformanceReviewStatus.DRAFT:
    case PerformanceReviewStatus.SENT_FOR_APPROVAL:
    case PerformanceReviewStatus.SENT_TO_OPS:
      return Steps.SIGNATORY_SIGNS;
    case PerformanceReviewStatus.SENT_FOR_MEMBER_APPROVAL:
      return Steps.EMPLOYEE_SIGNS;
    case PerformanceReviewStatus.APPROVED:
      if (!payrollCycle) {
        return Steps.EMPLOYEE_SIGNS;
      }
      return Steps.COMPLETED;
    case PerformanceReviewStatus.ACTIVATED:
      return Steps.COMPLETED;
    default:
      return Steps.UPDATE_DETAILS;
  }
};
