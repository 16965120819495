/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Icon } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import { SorOnboardingContext } from '@multiplier/hris-member-management';
import { startOfDay } from 'date-fns';
import { theme } from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import { PayrollConfigWithCutoffDate } from 'contract-onboarding/company/hooks/get-payroll-config';

import { ContractType } from '__generated__/graphql';

const PayrollCutoffDateHint: React.FC<{
  startDate: Date | string | null | undefined;
  contractType: ContractType | null | undefined;
  sorOnboardingContext: SorOnboardingContext | null;
  payrollConfig: PayrollConfigWithCutoffDate | undefined;
}> = ({ contractType, startDate, sorOnboardingContext, payrollConfig }) => {
  const { t } = useTranslation('contract-onboarding.common');

  if (!useFeature(AppFeature.USE_PAYROLL_SERVICE_TO_GET_PAYROLL_CYCLES).on)
    return null;

  if (
    !startDate ||
    !payrollConfig ||
    sorOnboardingContext?.isHrisOnlyFlow ||
    contractType === ContractType.FREELANCER ||
    contractType === ContractType.CONTRACTOR
  )
    return null;

  const { cutoffDate } = payrollConfig;
  const isBackDated =
    cutoffDate && startOfDay(cutoffDate) < startOfDay(new Date());

  return (
    <div tw="flex -mt-extra-small" data-testid="payroll-cutoff-date-hint">
      <Icon
        name="circle-warning"
        fill={theme`colors.background`}
        tw="mr-extra-small w-48"
      />
      <div tw="text-text-primary text-ps">
        {isBackDated
          ? t(
              'definition-phase.basic-details.payroll-cutoff-date-passed-hint',
              {
                defaultValue:
                  'The cut off date has passed and hence your payroll will be processed in the next cycle',
              },
            )
          : t('definition-phase.basic-details.payroll-cutoff-date-hint', {
              defaultValue:
                'Please ensure your employee completed their full onboarding before {{ cutoffDate }}. If onboarding is not completed prior to {{ cutoffDate }} your payroll will be processed in the next payroll cycle',
              replace: {
                cutoffDate: dateAsString(cutoffDate, 'yyyy-MM-dd'),
              },
            })}
      </div>
    </div>
  );
};

export default PayrollCutoffDateHint;
