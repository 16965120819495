/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ToolTip } from '@multiplier/common';
import tw from 'twin.macro';

const UpdateButton = ({
  loading,
  disabled,
  primaryActionTooltip,
}: {
  loading: boolean;
  disabled: boolean;
  primaryActionTooltip?: string | ReactNode | null;
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  if (primaryActionTooltip) {
    return (
      <ToolTip tw="ml-auto" styles={tw`p-none`} content={primaryActionTooltip}>
        <Button
          loading={loading}
          type="submit"
          size="medium"
          disabled={disabled}
        >
          {t(
            'onboarding-phase.employee-detail-component.action-cell.update',
            'Update',
          )}
        </Button>
      </ToolTip>
    );
  }

  return (
    <Button loading={loading} type="submit" size="medium" disabled={disabled}>
      {t(
        'onboarding-phase.employee-detail-component.action-cell.update',
        'Update',
      )}
    </Button>
  );
};

const SectionActionBar: React.FC<{
  disabled?: boolean;
  onCancelClick?: () => void;
  submitLoading?: boolean;
  requireApproval?: boolean;
  primaryActionTooltip?: string | ReactNode | null;
}> = ({
  disabled = false,
  onCancelClick,
  submitLoading = false,
  requireApproval = false,
  primaryActionTooltip,
  ...props
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  return (
    <div tw="flex flex-row justify-center gap-x-small" {...props}>
      <Button onClick={onCancelClick} size="medium" variant="secondary">
        {t(
          'onboarding-phase.employee-detail-component.action-cell.cancel',
          'Cancel',
        )}
      </Button>
      {!requireApproval ? (
        <UpdateButton
          loading={submitLoading}
          disabled={disabled}
          primaryActionTooltip={primaryActionTooltip}
        />
      ) : (
        <Button
          loading={submitLoading}
          type="submit"
          size="medium"
          disabled={disabled}
        >
          {t(
            'onboarding-phase.employee-detail-component.action-cell.send-request',
            'Send a request',
          )}
        </Button>
      )}
    </div>
  );
};

export default SectionActionBar;
