import { gql } from '@apollo/client';

export default gql`
  query GetDepartments($filters: DepartmentFilters) {
    departments(filters: $filters) {
      id
      name
      description
      hod {
        manager {
          id
          companyUser {
            userId
            firstName
            lastName
          }
        }
        contract {
          id
          member {
            userId
            firstName
            lastName
          }
        }
      }
      employees {
        contracts {
          id
        }
        managers {
          id
        }
      }
    }
  }
`;
