import React from 'react';

// copy requisite icons from node_modules/@material-icons/svg-400
// Make sure to copy from the `sharp` library only unless explicitly stated otherwise
// i.e. cp ./node_modules/@material-symbols/svg-400/sharp/group-fill.svg ./packages/common/src/icon/material-symbols

const currentToRebrandMapping: {
  [key: string]: {
    name: string;
    size?: number;
    fill?: string;
    stroke?: string;
    icon?: { ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> } | string;
  };
} = {
  'alert-triangle-yellow': { name: 'warning-fill' },
  'analytics-filled': { name: 'analytics-fill' },
  avatar: { name: 'account_circle-fill' },
  'bar-chart': { name: 'bar_chart-fill' },
  bin: { name: 'delete-fill' },
  'deployed-code': { name: 'deployed_code' }, // -fill version looks bad
  trash: { name: 'delete-fill' },
  'box-filled': { name: 'box-fill' },
  box: { name: 'box-fill' },
  calendar: { name: 'calendar_today-fill' },
  'calendar-add-on-filled': { name: 'calendar_add_on-fill' },
  'calendar-today-filled': { name: 'calendar_today-fill' },
  'calendar-tooltip-tick': { name: 'event_available-fill' },
  'calendar-tooltip-revoke': { name: 'event_repeat-fill' },
  'calendar-tooltip-close': { name: 'event_busy-fill' },
  'calendar-tooltip-add': { name: 'calendar_add_on-fill' },
  camera: { name: 'videocam-fill' },
  'caret-down': { name: 'keyboard_arrow_down' },
  'caret-left': { name: 'keyboard_arrow_left-fill' },
  'caret-right': { name: 'keyboard_arrow_right-fill' },
  'caret-up': { name: 'keyboard_arrow_up' },
  check: { name: 'check-fill' },
  'check-sm': { name: 'check-fill' },
  'check-circled': { name: 'check_circle-fill' },
  'check-circle-new': { name: 'check_circle-fill' },
  'check-circle-offset': { name: 'task_alt-fill' },
  'circle-error': { name: 'error-fill' },
  'coin-arrow': { name: 'payments-fill' },
  copy: { name: 'content_copy-fill' },
  cross: { name: 'close-fill' },
  'cross-sm': { name: 'close-fill' },
  dollar: { name: 'attach_money-fill' },
  'dollar-sign-new': { name: 'attach_money-fill' },
  download: { name: 'download-fill' },
  'download-square': { name: 'download-fill' },
  'download-pdf': { name: 'download-fill' },
  'document-download-invoice': { name: 'download-fill' },
  error: { name: 'error-fill' },
  'circle-warning': { name: 'error-fill' },
  'error-reverse': { name: 'error' },
  eye: { name: 'visibility-fill' },
  'eye-light': { name: 'visibility-fill' },
  'eye-slash': { name: 'visibility_off-fill' },
  'file-empty': { name: 'draft-fill' },
  'file-plus': { name: 'note_stack_add-fill' },
  'file-review': { name: 'content_paste-fill' },
  'file-upload': { name: 'upload_file-fill' },
  'file-text-no-stroke': { name: 'description-fill' },
  'isr-download': { name: 'description-fill' },
  funnel: { name: 'filter_alt-fill' },
  'help-filled': { name: 'help-fill' },
  'home-utilities': { name: 'home-fill' },
  'house-filled': { name: 'home-fill' },
  'image-file': { name: 'image-fill' },
  'insurance-filled': { name: 'health_and_safety-fill' },
  loader: { name: 'clock_loader_40-fill' },
  'log-in': { name: 'exit_to_app-fill' },
  lozenge: { name: 'stat_0-fill' },
  mail: { name: 'mail' },
  meals: { name: 'restaurant-fill' },
  'monetization-filled': { name: 'paid-fill' },
  office: { name: 'business_center-fill' },
  'payroll-paper': { name: 'receipt_long-fill' },
  'pdf-file': { name: 'picture_as_pdf-fill' },
  pdf: { name: 'picture_as_pdf-fill' },
  people: { name: 'supervisor_account-fill' },
  'per-diem': { name: 'request_quote-fill' },
  'professional-services': { name: 'construction-fill' },
  receipt: { name: 'receipt_long-fill' },
  'receipt-filled': { name: 'receipt_long-fill' },
  revert: { name: 'undo-fill' },
  revoke: { name: 'undo-fill' },
  taxi: { name: 'local_taxi-fill' },
  search: { name: 'search-fill' },
  'schedule-filled': { name: 'schedule-fill' },
  user: { name: 'person-fill' },
  'user-group-filled': { name: 'group-fill' },
  'pie-chart': { name: 'pie_chart-fill' },
  users: { name: 'group-fill' },
  'warning-circle': { name: 'error-fill' },
  'person-removed': { name: 'person_remove-fill' },
  'user-plus': { name: 'person_add-fill' },
  'more-vertical': { name: 'more_vert-fill' },
  'more-horizontal': { name: 'more_horiz-fill' },
  contacts: { name: 'contacts-fill' },
  pencil: { name: 'border_color-fill' },
  'refresh-cw': { name: 'refresh-fill' },
  organization: { name: 'family_history-fill' },
  'company-structure': { name: 'network_node-fill' },
  'circle-up': { name: 'signature-fill' },
  'credit-card': { name: 'credit_card' }, // -fill version looks bad for some reason
  'person-check': { name: 'person_check-fill' },
  'person-with-clock': { name: 'manage_accounts-fill' },
  'upload-alt': { name: 'cloud_upload-fill' },
  'users-outline': { name: 'group-fill' },
  person: { name: 'guardian-fill' },
  admin: { name: 'shield-fill' },
  shield: { name: 'health_and_safety-fill' },
  super_admin: { name: 'local_police-fill' },
  info: { name: 'info-fill' },
  'info-sm': { name: 'info-fill' },
  'alert-circle': { name: 'info-fill' },
  'circular-loader': { name: 'progress_activity-fill' },
  spinner: { name: 'progress_activity-fill' },
  'life-buoy': { name: 'support-fill' },
  'home-v2': { name: 'home-fill' },
  'dollar-sign-v2': { name: 'attach_money-fill' },
  'book-open': { name: 'menu_book-fill' },
  'briefcase-2': { name: 'work-fill' },
  'user-v2': { name: 'person-fill' },
  'trash-empty': { name: 'delete-fill' },
  file: { name: 'description-fill' },
  event: { name: 'event-fill' },
  'calendar-crossed': { name: 'event_busy-fill' },
  'pencil-in-box': { name: 'edit_square-fill' },
  'alert-triangle-callout': { name: 'warning-fill' },
  'user-plus-white-new': { name: 'person_add-fill' },
  'user-plus-black': { name: 'person_add-fill' },
  'user-plus-new': { name: 'person_add-fill' },
  'dollar-circle-arrow-right': { name: 'paid-fill' },
  'equals-square': { name: 'equal-fill' },
  'open-in': { name: 'open_in_new-fill' },
  lock: { name: 'lock-fill' },
  'ellipsis-vertical': { name: 'more_vert-fill' },
  stack: { name: 'stacks-fill' },
  flag: { name: 'flag-fill' },
  'external-link': { name: 'open_in_new-fill' },
  'zap-bold': { name: 'electric_bolt-fill' },
  'star-v2': { name: 'star-fill' },
  list: { name: 'list-fill' },
  'documents-processing': { name: 'draft-fill' },
  bank: { name: 'account_balance-fill' },
  plus: { name: 'add-fill' },
  tasks: { name: 'checklist-fill' },
  'arrow-up-circle': { name: 'arrow_circle_up-fill' },
  'pie-two': { name: 'pie_chart-fill' },
  signature: { name: 'signature-fill' },
  'eye-outline': { name: 'visibility-fill' },
  'it-asset': { name: 'devices-fill' },
  certificate: { name: 'description-fill' },
  send: { name: 'send-fill' },
  'cross-circle': { name: 'cancel-fill' },
  'paper-plane': { name: 'forward_to_inbox' },
  'plus-circle-v2': { name: 'add_circle-fill' },
  'x-circle': { name: 'cancel-fill' },
  'shopping-cart': { name: 'shopping_cart-fill' },
  'dollar-arrow': { name: 'send_money-fill' },
  'book-no-stroke': { name: 'book_2-fill' },
  location: { name: 'pin_drop' },
  'document-select-country': { name: 'travel_explore-fill' },
  'start-hiring': { name: 'person_add-fill' },
  'document-with-globe-tick': { name: 'mark_email_read-fill' },
  'document-with-globe': { name: 'developer_guide-fill' },
  team: { name: 'group-fill' },
  employee: { name: 'person-fill' },
  'file-text': { name: 'description-fill' },
  'upload-square': { name: 'upload-fill' },
  'check-circle': { name: 'check_circle-fill' },
  globe: { name: 'language-fill' },
  'sso-cancel': { name: 'key_off-fill' },
  sso: { name: 'key-fill' },
  key: { name: 'key-fill' },
  'refresh-cw-cancel': { name: 'sync_disabled-fill' },
  'refresh-cw-error': { name: 'sync_problem-fill' },
  repeat: { name: 'repeat-fill' },
  book: { name: 'book-fill' },
  businessman: { name: 'account_circle-fill' },
  exit: { name: 'arrow_right_circle-fill' },
  'circle-thick': { name: 'trip_origin-fill' },
  'circle-thin': { name: 'circle' },
  'compare-new': { name: 'compare_arrows-fill' },
  dependent: { name: 'group-fill' },
  'shield-check': { name: 'verified_user-fill' },
  lightbulb: { name: 'award_star-fill' },
  timer: { name: 'timer-fill' },
  department: { name: 'business_center-fill' },
  'cog-v2': { name: 'settings-fill' },
  'cancel-sign': { name: 'close-fill' },
  'user-minus': { name: 'person_remove-fill' },
  'git-branch': { name: 'mediation-fill' },
  'arrow-right': { name: 'arrow_forward-fill' },
  'chevron-down': { name: 'keyboard_arrow_down-fill' },
  'backspace-fill': { name: 'backspace-fill' },
};

export default currentToRebrandMapping;
