/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Callout, CalloutVariant } from '@multiplier/common';
import tw from 'twin.macro';

import { CurrencyCode, InputMaybe, RateFrequency } from '__generated__/graphql';

const BoldText = tw.span`font-bold`;

interface PrePostProbationDisclaimerProps {
  probationSalary?: InputMaybe<number> | undefined;
  postProbationSalary?: InputMaybe<number> | undefined;
  currency?: InputMaybe<CurrencyCode> | undefined;
  frequency?: InputMaybe<RateFrequency> | undefined;
  probationFrequency?: InputMaybe<RateFrequency> | undefined;
  isToggle?: boolean;
}

const PrePostProbationDisclaimer: React.FC<PrePostProbationDisclaimerProps> = ({
  probationSalary,
  postProbationSalary,
  currency,
  frequency,
  probationFrequency,
  isToggle,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const rateFrequencyLabel: { [key: string]: string } = useMemo(
    () => ({
      [RateFrequency.HOURLY]: t('rate-frequency.hourly', 'hour'),
      [RateFrequency.SEMIMONTHLY]: t(
        'rate-frequency.semi-monthly',
        'semi-monthly',
      ),
      [RateFrequency.MONTHLY]: t('rate-frequency.monthly', 'month'),
      [RateFrequency.ANNUALLY]: t('rate-frequency.annually', 'annum'),
    }),
    [],
  );

  return (
    <div>
      {isToggle &&
      probationSalary &&
      postProbationSalary &&
      frequency &&
      probationFrequency &&
      parseInt(probationSalary.toString(), 10) ===
        parseInt(postProbationSalary.toString(), 10) ? (
        <Callout tw="my-24 bg-[#FEF3C7]" variant={CalloutVariant.WARNING}>
          <p tw="text-neutral800 font-medium text-ps">
            The Probation salary{' '}
            {`${currency} ${probationSalary} per ${rateFrequencyLabel[probationFrequency]}`}{' '}
            is <BoldText>equal to</BoldText> the Post-Probation salary of{' '}
            {`${currency} ${postProbationSalary} per ${rateFrequencyLabel[frequency]}`}
          </p>
        </Callout>
      ) : (
        ''
      )}

      {isToggle &&
      probationSalary &&
      postProbationSalary &&
      frequency &&
      probationFrequency &&
      parseInt(probationSalary.toString(), 10) >
        parseInt(postProbationSalary.toString(), 10) ? (
        <Callout tw="my-24 bg-[#FFE4E6]" variant={CalloutVariant.ERROR}>
          <p tw="text-neutral800 font-medium text-ps">
            The Probation salary{' '}
            {`${currency} ${probationSalary} per ${rateFrequencyLabel[probationFrequency]} `}
            <BoldText>cannot be greater</BoldText> than the Post-Probation
            salary of{' '}
            {`${currency} ${postProbationSalary} per ${rateFrequencyLabel[frequency]}`}
          </p>
        </Callout>
      ) : (
        ''
      )}
    </div>
  );
};

export default PrePostProbationDisclaimer;
