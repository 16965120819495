import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { SortingState, Table } from '@tanstack/react-table';

import { prependTableIdForUrlParams } from '../../utils';
import { UseClientPaginatedTableOptions } from '../types';

const useClientPaginatedTable = <TData extends unknown>(
  options: UseClientPaginatedTableOptions<TData>,
): {
  tableInstance: Table<TData>;
  sorting: SortingState;
} => {
  const { tableId } = options;
  const {
    sorting,
    setSorting,
    expanded,
    setExpanded,
    pagination,
    setPagination,
    rowSelection,
    setRowSelection,
  } = options.tableState;
  const [params, setParams] = useSearchParams();

  const rowsKey = prependTableIdForUrlParams('rows', tableId);
  const pageKey = prependTableIdForUrlParams('page', tableId);
  const sortByKey = prependTableIdForUrlParams('sortBy', tableId);
  const sortDirKey = prependTableIdForUrlParams('sortDir', tableId);

  const tableInstance = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...options,
    state: {
      sorting,
      expanded,
      rowSelection,
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    const pageNumber = (Number(params.get(pageKey)) || 1) - 1;
    const rowsPerPage = Number(params.get(rowsKey)) || pagination.pageSize;

    const sortKey = params.get(sortByKey);
    const sortDirection = params.get(sortDirKey);
    if (sortKey)
      setSorting([
        {
          desc: sortDirection === 'desc',
          id: sortKey,
        },
      ]);

    tableInstance.setPagination({
      pageIndex: pageNumber,
      pageSize: rowsPerPage,
    });
  }, []);

  const { pageIndex, pageSize } = tableInstance.getState().pagination;

  useEffect(() => {
    // pagination will be handled by tanstack, this is just to track the pagination details
    setPagination({ pageIndex, pageSize });
    params.set(rowsKey, pageSize.toString());
    params.set(pageKey, pageIndex.toString());
    params.set(sortByKey, sorting?.[0]?.id);
    params.set(sortDirKey, sorting?.[0]?.desc ? 'desc' : 'asc');
    setParams(params);
  }, [pageIndex, pageSize, sorting]);

  return {
    tableInstance,
    sorting,
  };
};

export default useClientPaginatedTable;
