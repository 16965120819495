import React, { useContext } from 'react';

import { TwStyle, theme } from 'twin.macro';

import Icon from '../../../icon';
import TextInput, { InputProps } from '../../../text-input';
import { ThemeContext } from '../../../theme-provider';
import { ComboBoxVariants } from '../../types';

interface QueryInputProps extends InputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
  placeholder: string;
  variant: ComboBoxVariants;
  divStyles?: TwStyle;
  prependStyles?: TwStyle;
}

const QueryInput = React.forwardRef<HTMLInputElement, QueryInputProps>(
  (
    {
      value,
      onChange,
      onKeyDown,
      placeholder,
      prependStyles,
      variant,
      ...props
    },
    ref,
  ) => {
    const { isNewThemeApplied } = useContext(ThemeContext);

    return (
      <TextInput
        prependStyles={prependStyles}
        prepend={
          variant !== 'inline-text' ? (
            <Icon
              name="search"
              fill={
                !isNewThemeApplied
                  ? theme`colors.grey01`
                  : theme`colors.icon-primary`
              }
              tw="h-base w-base"
            />
          ) : undefined
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={ref}
        aria-label="dropdown-input"
        data-testid="dropdown-input"
        name="input"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        autoComplete="off"
        {...props}
      />
    );
  },
);

export default QueryInput;
