import {
  MemberChangeCategory,
  useCancelChangeRequestMutation,
} from '__generated__/graphql';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCancelChangeRequest = (
  category: MemberChangeCategory,
  onCompleted?: () => void,
) => {
  const [cancelChangeRequest, { loading }] = useCancelChangeRequestMutation({
    variables: {
      category,
    },
    onCompleted: () => {
      if (onCompleted) onCompleted();
    },
  });

  return {
    cancelChangeRequest,
    loading,
  };
};

export default useCancelChangeRequest;
