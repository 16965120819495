import { useTranslation } from 'react-i18next';

import { isAfter, isEqual, isSaturday, isSunday, startOfDay } from 'date-fns';
import * as yup from 'yup';

import {
  buildSchemaForJobScope,
  buildValidationContextForJobScope,
} from 'contract-onboarding/company/pages/definition-phase/pages/basic-details/validation-schema/job-scope-validation-schema';
import useContractDetailRestrictions from 'contract-onboarding/hooks/contract-detail-restrictions';

import {
  Contract,
  ContractTerm,
  ContractType,
  CountryCode,
  WorkShiftInput,
} from '__generated__/graphql';

import countryLabels from '../../../common/constants/country-labels';
import { useGetContractStartDateLimit } from '../../company/hooks';
import useEndDateRestrictions from '../../company/hooks/end-date-restrictions';
import getWorkShiftSchema from '../../company/services/work-shift-schema';

const useEmploymentDetailsFormValidationSchema = ({
  type,
  term,
  country,
  countryStateCode,
  workStatus,
  onboardingFieldConfig,
  legalEntityId,
  hasSupportedJobPositions = false,
}: {
  type: Contract['type'];
  term: Contract['term'];
  country: Contract['country'];
  countryStateCode: Contract['countryStateCode'];
  workStatus?: Contract['workStatus'];
  legalEntityId?: Contract['legalEntityId'];
  onboardingFieldConfig: {
    [key: string]: { visible: boolean; editable: boolean } | undefined;
  } | null;
  hasSupportedJobPositions?: boolean;
}): yup.ObjectSchema<{
  position: yup.StringSchema;
  country: yup.StringSchema;
  state: yup.StringSchema<string | null | undefined>;
  term: yup.StringSchema;
  startOn: yup.DateSchema<Date | null | undefined>;
  endOn: yup.DateSchema<Date | null | undefined>;
  scope?: yup.StringSchema<string | null | undefined>;
  workShift?: yup.SchemaOf<WorkShiftInput>;
}> => {
  const { t } = useTranslation('contract-onboarding.common');

  const { earliestStartDate, limitedHolidays } = useGetContractStartDateLimit({
    country,
    countryStateCode,
    contractType: type,
    workStatus,
    legalEntityId,
  });

  const { getMaximumEndDate } = useEndDateRestrictions({
    selectedCountry: country,
    selectedCountryState: countryStateCode,
    selectedContractType: type,
  });

  const { validateJobTitle } = useContractDetailRestrictions(country);

  const minDate = earliestStartDate || new Date();

  return yup.object().shape({
    position: yup
      .string()
      .required(
        t(
          'onboarding-phase.employment-details.position.error',
          'Position is required field',
        ),
      )
      .test(
        'invalid-position',
        validateJobTitle({ hasSupportedJobPositions, contractType: type }),
      ),
    country: yup.string().oneOf(Object.values(CountryCode)).required(),
    state: yup.string().nullable(),
    term: yup.string().oneOf(Object.values(ContractTerm)).required(),
    startOn: yup
      .date()
      .nullable(true)
      .typeError(
        t(
          'onboarding-phase.employment-details.start-date-error.date-value',
          'Start date must be a valid date',
        ),
      )
      .required()
      .test(
        'later-date',
        t('onboarding-phase.employment-details.start-date-error.min-date', {
          defaultValue: 'Start date must be later than {{contract_min_date}}',
          replace: {
            contract_min_date: new Date(minDate).toDateString(),
          },
        }),
        (value) => {
          if (type === ContractType.EMPLOYEE) {
            return yup
              .date()
              .nullable(true)
              .min(startOfDay(new Date(minDate)))
              .required()
              .isValid(value);
          }

          return true;
        },
      )
      .test(
        'after-start-date',
        t(
          'onboarding-phase.employment-details.start-date-error.before-end-date',
          'Start date should be before End Date',
        ),
        (value, ctx) => {
          if (
            !onboardingFieldConfig?.endOn?.editable &&
            term === ContractTerm.FIXED &&
            ctx.parent.endOn
          ) {
            return ctx.parent.startOn
              ? yup.date().max(ctx.parent.endOn).isValid(value)
              : false;
          }
          return true;
        },
      )
      .test(
        'not-weekends',
        t(
          'onboarding-phase.employment-details.start-date-error.not-weekends',
          'The selected date falls on the weekend. Please select the closest working day.',
        ),
        (value) => {
          if (!value) {
            return true;
          }
          if (
            type === ContractType.CONTRACTOR ||
            type === ContractType.FREELANCER
          ) {
            return true;
          }
          return !isSaturday(value) && !isSunday(value);
        },
      )
      .test(
        'not-holidays',
        t('onboarding-phase.employment-details.start-date-error.not-holidays', {
          defaultValue:
            'The selected date is a Public Holiday in {{ country }}. Please select the closest working day.',
          replace: {
            country: country ? countryLabels[country] : country,
          },
        }),
        (value) => {
          if (!value || !limitedHolidays) return true;
          return limitedHolidays.every((day) => !isEqual(day.date, value));
        },
      ),
    endOn: yup
      .date()
      .nullable(true)
      .transform((value, origin) => (origin === '' ? null : value))
      .typeError(
        t(
          'onboarding-phase.employment-details.end-date-error.date-value',
          'End date must be a valid date',
        ),
      )
      .test(
        'after-start-date',
        t(
          'onboarding-phase.employment-details.end-date-error.after-start-date',
          'End date should be after Start Date',
        ),
        (value, ctx) => {
          if (term === ContractTerm.FIXED && value) {
            return ctx.parent.startOn
              ? yup.date().min(ctx.parent.startOn).isValid(value)
              : false;
          }
          return true;
        },
      )
      .test(
        'max-date',
        t(
          'onboarding-phase.employment-details.end-date.max-period.error',
          'End date is falling out of period',
        ),
        (value, ctx) => {
          if (!value) return true;

          const maxDate = getMaximumEndDate(ctx.parent.startOn);

          return !isAfter(value, maxDate);
        },
      ),
    scope: buildSchemaForJobScope(
      t,
      buildValidationContextForJobScope({ contract: { country, type } }),
    ),
    workShift: getWorkShiftSchema(),
  });
};

export default useEmploymentDetailsFormValidationSchema;
