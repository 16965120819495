import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { DropdownValue } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import { addDays, minTime, startOfMonth } from 'date-fns';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';

import { AdditionalPayFormValues } from 'contract-onboarding/company/components/additional-pay-form';
import { additionalPaySchema } from 'contract-onboarding/company/services/compensation-schema';
import useContractDetailRestrictions from 'contract-onboarding/hooks/contract-detail-restrictions';

import {
  Contract,
  GetContractPositionAndPayQuery,
  Maybe,
} from '__generated__/graphql';

export const getMinimumEffectiveDate = (
  enableSalaryRevisionNotes: boolean,
  selectedContractStartOn: Contract['startOn'],
  isEmployeeStartingOnThisMonth?: boolean,
  probationPeriodEndDate?: Date,
): Date => {
  if (probationPeriodEndDate)
    return addDays(new Date(probationPeriodEndDate), 1);

  if (!selectedContractStartOn) return new Date(minTime);

  if (enableSalaryRevisionNotes) {
    return new Date(selectedContractStartOn);
  }

  return isEmployeeStartingOnThisMonth
    ? new Date(selectedContractStartOn)
    : startOfMonth(new Date());
};

const usePerformanceReviewSchema = ({
  isEmployeeStartingAfterThisMonth,
  currencyOptions,
  selectedContract,
  isAllowanceFeature,
  isNewAllowancesConfigEnabled,
  hasSupportedJobPositions = false,
}: {
  isEmployeeStartingAfterThisMonth: boolean;
  currencyOptions: DropdownValue[];
  selectedContract?: GetContractPositionAndPayQuery['contract'];
  isAllowanceFeature?: boolean;
  isNewAllowancesConfigEnabled?: boolean;
  hasSupportedJobPositions?: boolean;
}): yup.ObjectSchema<{
  contractId: yup.StringSchema;
  promotedDesignation: yup.StringSchema<string | null | undefined>;
  currency: yup.StringSchema;
  revisedSalary: yup.NumberSchema;
  effectiveMonth: yup.DateSchema;
  additionalPays: Lazy<
    yup.ArraySchema<
      yup.SchemaOf<{
        payType: string;
        amount?: AdditionalPayFormValues['amount'];
        currency?: Maybe<string>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        frequency?: any;
        conditions?: Maybe<string>;
      }>
    >
  >;
}> => {
  const { t } = useTranslation('performance-reviews');
  const { t: tContractOnboarding } = useTranslation(
    'contract-onboarding.common',
  );
  const enableSalaryRevisionNotes = useFeature(AppFeature.SALARY_REVISION_NOTES)
    .on;

  const { validateJobTitle } = useContractDetailRestrictions(
    selectedContract?.country,
  );

  return yup.object().shape({
    contractId: yup
      .string()
      .required(t('form.validation.required', 'Required')),
    promotedDesignation: yup
      .string()
      .nullable()
      .test(
        'invalid-designation',
        validateJobTitle({
          hasSupportedJobPositions,
          contractType: selectedContract?.type,
        }),
      ),
    currency: yup.string().required(t('form.validation.required', 'Required')),
    revisedSalary: yup
      .number()
      .typeError(t('form.validation.invalid-number', 'Invalid Number'))
      .required(t('form.validation.required', 'Required')),
    effectiveMonth: yup
      .date()
      .typeError(t('form.validation.invalid-date', 'Invalid Date'))
      .min(
        getMinimumEffectiveDate(
          enableSalaryRevisionNotes,
          selectedContract?.startOn,
          isEmployeeStartingAfterThisMonth,
        ),
        t(
          'form.validation.min-date',
          'should be withing the current month and after the employee start date',
        ),
      )
      .required(t('form.validation.required', 'Required')),
    additionalPays: additionalPaySchema(
      tContractOnboarding,
      currencyOptions,
      undefined,
      isAllowanceFeature,
      isNewAllowancesConfigEnabled,
    ),
  });
};

export default usePerformanceReviewSchema;
