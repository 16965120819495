import React, { useContext } from 'react';

import tw, { TwStyle } from 'twin.macro';

import { ThemeContext } from '../theme-provider';

interface Props {
  items: { id: string; element: React.ReactNode }[];
  selectedId?: string;
  onChange?: (selected: string) => void;
  containerStyles?: TwStyle;
  buttonStyles?: (selected: boolean) => TwStyle[];
}

const ToggleButton: React.FC<Props> = ({
  items,
  selectedId,
  onChange,
  containerStyles,
  buttonStyles,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div
      css={[
        tw`flex gap-x-tiny p-tiny bg-background-primary rounded-tiny`,
        containerStyles,
      ]}
      {...props}
    >
      {items?.map((item) => (
        <button
          key={item.id}
          onClick={() => {
            if (onChange) {
              onChange(item.id);
            }
          }}
          data-testid={
            item.id === selectedId ? 'toggle-active' : 'toggle-inactive'
          }
          type="button"
          css={[
            tw`relative text-ps text-text-tertiary font-medium px-small rounded-tiny`,
            tw`focus:(outline-none ring-transparent)`,
            tw`focus-visible:(outline-primary)`,
            tw`min-h-28 h-auto flex-grow hover:bg-background-secondary flex-1`,
            !isNewThemeApplied && tw`hover:bg-grey04`,
            item.id === selectedId &&
              tw`bg-background-white text-text-primary hover:bg-background-white`,
            item.id === selectedId && !isNewThemeApplied && tw`text-foreground`,
            ...(buttonStyles ? buttonStyles(item.id === selectedId) : []),
          ]}
        >
          {item.element}
        </button>
      ))}
    </div>
  );
};

export default ToggleButton;
