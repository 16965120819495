/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import CircleIcon from '@multiplier/assets/files/app/circle.svg';
import { Avatar, ThemeContext } from '@multiplier/common';
import tw, { css, styled, theme } from 'twin.macro';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';

const ExtendedSidebar: React.FC = () => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { companyList, selectedCompany } = useReactiveVar(userVar);

  const navigateToCompanyHomePage = () => {
    navigate(`/${Experience.COMPANY}/dashboard`, { replace: true });
    window.location.reload();
  };

  const handleCompanyChange = async ({ id }: { id: string }) => {
    localStorage.setItem('selected_company', id);
    return navigateToCompanyHomePage();
  };

  return (
    <div tw="h-[calc(100vh-75px)] z-index[60] width[240px] mobile:width[100%] top[75px] bg-background-overlay position[absolute]">
      <div tw="bg-background-white">
        <div tw="border-b border-border-secondary" />
        <CompanyItemWrapper>
          {companyList?.map((company) => (
            <CompanyItem
              onClick={() => handleCompanyChange({ id: company.id ?? '' })}
              isNewThemeApplied={isNewThemeApplied}
              key={company.id}
            >
              {company.companyLogo?.link ? (
                <img
                  src={company.companyLogo.link}
                  alt="company logo"
                  tw="rounded-base height[auto] width[auto] shadow-high max-width[32px] max-height[32px]"
                />
              ) : (
                <Avatar
                  name={company.displayName ?? ''}
                  greyBackground
                  size="extraSmall"
                />
              )}
              <span
                css={[
                  tw`text-ps text-text-primary ml-small mt-auto mb-auto w-[120px] text-ellipsis overflow-hidden whitespace-nowrap`,
                  !isNewThemeApplied && tw`text-background`,
                ]}
              >
                {company.displayName}
              </span>
              <div tw="flex items-center ml-auto mr-none">
                <input
                  css={[
                    tw`width[18px] height[18px] flex items-center justify-center`,
                    tw`focus:(outline-none ring-transparent)`,
                    tw`focus-visible:outline-none`,
                    tw`hover:cursor-pointer`,
                    css`
                      :checked {
                        background-image: url('${CircleIcon}');
                        background-size: auto;
                        color: ${isNewThemeApplied
                          ? theme`colors.icon-primary`
                          : theme`colors.icon-brand`};
                      }
                    `,
                  ]}
                  type="radio"
                  value="one"
                  data-testid={company.id}
                  checked={company.id === selectedCompany?.id}
                />
              </div>
            </CompanyItem>
          ))}
        </CompanyItemWrapper>
      </div>
    </div>
  );
};

export default ExtendedSidebar;

const CompanyItemWrapper = styled.div`
  ${tw`items-center py-base max-h-[90vh] overflow-y-auto`}
`;

const CompanyItem = styled.div(
  ({ isNewThemeApplied }: { isNewThemeApplied: boolean }) => [
    tw`flex flex-row p-base cursor-pointer`,
    tw`hover:(cursor-pointer bg-background-primary)`,
    !isNewThemeApplied && tw`hover:bg-background-brand-faded`,
  ],
);
