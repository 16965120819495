/** @jsxImportSource @emotion/react */
import React from 'react';

import tw from 'twin.macro';

import Loader from 'common/components/loader';
import { FormCard } from 'contract-onboarding/components/layout';
import PendingOnPill, {
  PendingOn,
} from 'contract-onboarding/components/pending-on-pill';

import {
  BlockSource,
  Contract,
  ContractOnboarding,
  ContractOnboardingStatus,
  Member,
} from '__generated__/graphql';

import ContractDepositInformation from '../contract-deposit-information';
import ChangesRequestedStrip from '../contract-request-changes/changes-requested-strip';
import VisaDeposiInformation from '../visa-deposit-information';

const OnboardingPrimaryLoading = () => (
  <div>
    <Loader.Spinner variant={Loader.SpinnerType.MODULE} tw="w-full h-[256px]" />
  </div>
);

interface OnboardingPrimaryCardProps {
  header?: string | null;
  subtitle?: string | null;
  pendingOn?: PendingOn | null;
  loading?: boolean;
  contractId?: Contract['id'];
  contractType: Contract['type'];
  depositPayable?: Contract['depositPayable'];
  firstName?: Member['firstName'];
  lastName?: Member['lastName'];
  contractBlocked?: Contract['contractBlocked'];
  unresolvedBlockEvents?: Contract['unresolvedBlockEvents'];
  currentStatus?: ContractOnboarding['status'];
}

const OnboardingPrimaryCard: React.FC<
  React.PropsWithChildren<OnboardingPrimaryCardProps>
> & {
  Content: React.FC<React.PropsWithChildren>;
  ActionBar: React.FC<React.PropsWithChildren>;
} = ({
  header,
  subtitle,
  pendingOn,
  loading = false,
  contractId,
  contractType,
  depositPayable,
  firstName,
  lastName,
  contractBlocked,
  unresolvedBlockEvents,
  currentStatus,
  children,
}) => {
  const hasContractChangeRequest =
    unresolvedBlockEvents?.filter(
      (blockEvent) => blockEvent.source === BlockSource.CONTRACT_CHANGE_REQUEST,
    )?.[0]?.source === BlockSource.CONTRACT_CHANGE_REQUEST;

  return (
    <FormCard
      tw="text-text-primary p-none overflow-hidden"
      data-testid="onboarding-primary-card"
    >
      {loading ? (
        <OnboardingPrimaryLoading />
      ) : (
        <div>
          {currentStatus ===
            ContractOnboardingStatus.VISA_DOCUMENTS_VERIFIED && (
            <VisaDeposiInformation firstName={firstName} lastName={lastName} />
          )}
          <ContractDepositInformation
            contractId={contractId}
            contractType={contractType}
            depositPayables={depositPayable}
            firstName={firstName}
            lastName={lastName}
          />
          <div tw="flex flex-col gap-y-large py-large px-extra-large">
            {contractBlocked && hasContractChangeRequest && (
              <ChangesRequestedStrip />
            )}
            {!contractBlocked && (
              <div tw="flex flex-row justify-between items-start">
                <div tw="flex flex-col gap-y-extra-small">
                  {header && (
                    <h5 tw="text-h5 font-semibold text-text-primary">
                      {header}
                    </h5>
                  )}
                  {subtitle && (
                    <span tw="text-ps text-text-secondary">{subtitle}</span>
                  )}
                </div>
                <PendingOnPill tw="mt-extra-small" pendingOn={pendingOn} />
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </FormCard>
  );
};

OnboardingPrimaryCard.Content = tw.div`flex flex-col gap-y-base`;
OnboardingPrimaryCard.ActionBar = tw.div`flex flex-row gap-x-extra-small justify-end`;

export default OnboardingPrimaryCard;
