import { TFunction } from 'i18next';
import { capitalize, isEmpty, merge, uniqBy } from 'lodash';

import {
  ConditionKey,
  Contract,
  CountryCode,
  Gender,
  GetCompanyEntitiesQuery,
  Manager,
} from '../__generated__/graphql';
import { conditionKeyLabel, countryLabels, genderLabel } from '../constants';
import { OrgDirectoryFilterConfig } from '../hooks/get-org-directory';
import { OrgEmployee } from '../types';

export const getAvatarDisplay = (member: Contract['member']): string => {
  const firstName = member?.basicInfo?.firstName ?? member?.firstName ?? '';
  const lastName = member?.basicInfo?.lastName ?? member?.lastName ?? '';

  return `${firstName.slice(0, 1) ?? ''}${lastName.slice(0, 1) ?? ''}`
    .trim()
    .toUpperCase();
};

export const getMemberFullname = (member: Contract['member']): string => {
  const firstName = capitalize(
    member?.basicInfo?.firstName ?? member?.firstName ?? '',
  );
  const lastName = capitalize(
    member?.basicInfo?.lastName ?? member?.lastName ?? '',
  );
  return `${firstName} ${lastName}`.trim();
};

export const mapOptionsConfigToOrgDirectoryFilterConfig = (
  optionsConfig: Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>>,
) =>
  Object.keys(optionsConfig)
    .map<OrgDirectoryFilterConfig>((key) => {
      const config = optionsConfig[key as ConditionKey];
      return {
        key: key as ConditionKey,
        options: uniqBy(config.options, 'value').sort((a, b) =>
          // Support alphabetical sorting for options
          a?.label.localeCompare(b?.label),
        ),
        label: config.label,
      };
    })
    // TODO: Temporarily hide Department and Entity property till BE supports
    .filter((item) => ![ConditionKey.DEPARTMENT].includes(item.key));

export const getEmptyFilterConfig = (
  t: TFunction<'hris.common', undefined>,
): Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>> => ({
  [ConditionKey.COUNTRY]: {
    label: conditionKeyLabel(t)[ConditionKey.COUNTRY],
    options: [],
  },
  [ConditionKey.DEPARTMENT]: {
    label: conditionKeyLabel(t)[ConditionKey.DEPARTMENT],
    options: [],
  },
  [ConditionKey.GENDER]: {
    label: conditionKeyLabel(t)[ConditionKey.GENDER],
    options: [],
  },
  [ConditionKey.NAME]: {
    label: conditionKeyLabel(t)[ConditionKey.NAME],
    options: [],
  },
  [ConditionKey.ENTITY]: {
    label: conditionKeyLabel(t)[ConditionKey.ENTITY],
    options: [],
  },
});

const collectOptionsFromContract = ({
  t,
  contract,
  optionsConfig,
  entityList,
}: {
  t: TFunction<'hris.common', undefined>;
  contract: Contract;
  optionsConfig: Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>>;
  entityList: NonNullable<GetCompanyEntitiesQuery['company']>['otherEntities'];
}) => {
  const { country, member, orgAttributes, legalEntityId } = contract;
  const { firstName, lastName, gender } = member ?? {};
  const { department } = orgAttributes ?? {};

  const fullName = `${firstName ?? ''} ${lastName ?? ''}`.trim();

  if (fullName) {
    optionsConfig[ConditionKey.NAME].options.push({
      label: fullName,
      value: fullName,
    });
  }

  if (department) {
    optionsConfig[ConditionKey.DEPARTMENT].options.push({
      label: department?.name ?? '',
      value: department.id,
    });
  }

  if (gender) {
    optionsConfig[ConditionKey.GENDER].options.push({
      label: genderLabel(t)[gender],
      value: gender,
    });
  }

  if (country) {
    const countryLabel = countryLabels(t)[country] ?? country;
    optionsConfig[ConditionKey.COUNTRY].options.push({
      label: countryLabel,
      value: country,
    });
  }
  if (legalEntityId) {
    optionsConfig[ConditionKey.ENTITY].options.push({
      label:
        entityList?.find((entity) => entity?.id === legalEntityId)?.legalName ??
        '',
      value: legalEntityId,
    });
  }

  return optionsConfig;
};

export const collectOptionsFromContracts = ({
  t,
  employeeContracts,
  entityList,
}: {
  t: TFunction<'hris.common', undefined>;
  employeeContracts: Contract[];
  entityList: NonNullable<GetCompanyEntitiesQuery['company']>['otherEntities'];
}): Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>> =>
  employeeContracts.reduce((acc, contract) => {
    const newOptionsConfig = collectOptionsFromContract({
      t,
      contract,
      optionsConfig: { ...acc },
      entityList,
    });

    return { ...acc, ...newOptionsConfig };
  }, getEmptyFilterConfig(t));

export const collectCountryOptions = ({
  tCommon,
  optionsConfig,
}: {
  tCommon: TFunction<'common', undefined>;
  optionsConfig: Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>>;
}): Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>> => {
  const allLabels = countryLabels(tCommon);
  const allCountryCodes = Object.keys(allLabels);

  optionsConfig[ConditionKey.COUNTRY].options = allCountryCodes.reduce(
    (acc, countryCode) => {
      acc.push({
        label: allLabels[countryCode as CountryCode],
        value: countryCode as CountryCode,
      });

      return acc;
    },
    [] as { label: string; value: CountryCode }[],
  );

  return optionsConfig;
};

export const collectEntityOptions = ({
  optionsConfig,
  entityList,
}: {
  optionsConfig: Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>>;
  entityList: NonNullable<GetCompanyEntitiesQuery['company']>['otherEntities'];
}): Record<ConditionKey, Omit<OrgDirectoryFilterConfig, 'key'>> => {
  optionsConfig[ConditionKey.ENTITY].options = (entityList ?? [])?.map((e) => ({
    label: e?.legalName ?? '',
    value: e?.id ?? '',
  }));

  return optionsConfig;
};

export const rulesConfig = ({
  t,
  tCommon,
  employees,
  entityList,
  shouldIncludeAllCountriesFilter = true,
}: {
  t: TFunction<'hris.common', undefined>;
  tCommon: TFunction<'common', undefined>;
  employees: OrgEmployee[];
  entityList: NonNullable<GetCompanyEntitiesQuery['company']>['otherEntities'];
  shouldIncludeAllCountriesFilter?: boolean;
}): OrgDirectoryFilterConfig[] => {
  const emptyFilterConfig = getEmptyFilterConfig(t);

  const contractOptionsConfig = employees.reduce((acc, employee) => {
    if (!employee.contract) return acc;

    const newOptionsConfig = collectOptionsFromContract({
      t,
      contract: employee.contract,
      optionsConfig: acc,
      entityList,
    });

    return { ...acc, ...newOptionsConfig };
  }, emptyFilterConfig);

  const entityOptionsConfig = collectEntityOptions({
    optionsConfig: emptyFilterConfig,
    entityList,
  });

  return mapOptionsConfigToOrgDirectoryFilterConfig(
    merge(
      contractOptionsConfig,
      entityOptionsConfig,
      shouldIncludeAllCountriesFilter
        ? collectCountryOptions({
            tCommon,
            optionsConfig: emptyFilterConfig,
          })
        : {},
    ),
  );
};

/**
 * This is for the case manager without contract. In that case, we would use "companyUser" data instead
 * FIXME: This is just temp solution, will research and bring it to Apollo later
 */
export const formatManager = (manager: Manager): Manager => {
  const contract = manager?.contract;
  const companyUser = manager?.companyUser;

  if (!isEmpty(contract)) return manager;

  return {
    ...manager,
    contract: {
      member: {
        firstName: companyUser?.firstName,
        lastName: companyUser?.lastName,
        userId: companyUser?.userId,
        emails: companyUser?.emails,
        phoneNos: companyUser?.phoneNos,
        gender: Gender.UNSPECIFIED,
      },
      country: 'Unspecified' as CountryCode,
      position: companyUser?.title ?? '',
    },
  };
};

export const mapEmployees = (
  contracts: Contract[],
  managers: Manager[],
): OrgEmployee[] => {
  const output: Record<string, OrgEmployee> = {};

  contracts.forEach((contract) => {
    if (!contract?.member?.userId) return;

    output[contract?.member?.userId] = {
      userId: contract?.member?.userId ?? '',
      isManager: false,
      manager: null,
      contract,
    };
  });

  managers.forEach((manager) => {
    if (!manager?.companyUser?.userId) return;

    output[manager?.companyUser?.userId] = {
      userId: manager?.companyUser?.userId,
      isManager: true,
      manager,
      contract:
        output[manager?.companyUser?.userId]?.contract ??
        manager?.contract ??
        null,
    };
  });

  return Object.values(output);
};
