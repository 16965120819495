import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
} from 'app/models/module-config';
import {
  hideModuleBasedOnComplianceType,
  hideModuleBasedOnFixedTermEndDate,
  showModuleBasedOnContractStatusAndContractStarted,
} from 'app/utils/module-checks';
import routes from 'team/member/routes';

import { ContractStatus } from '__generated__/graphql';

const config: ModuleConfig = {
  id: 'member-employment-certificate',
  permission: 'team',
  folder: 'team/member/employment-certificate',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Employment certificate',
    route: `${routes.root}/${routes.employmentCertificate}`,
    hide: async () => {
      const shouldHide = await Promise.all([
        hideModuleBasedOnComplianceType(),
        hideModuleBasedOnFixedTermEndDate(),
      ]);

      return shouldHide.some((hide) => hide);
    },
    sort: 3,
    home: false,
    group: MemberNavGroups.EMPLOYMENT,
  },
  enabled: async () =>
    showModuleBasedOnContractStatusAndContractStarted([
      ContractStatus.ACTIVE,
      ContractStatus.ONBOARDING,
      ContractStatus.OFFBOARDING,
    ]),
};

export default config;
