import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

import { autoUpdate, computePosition, shift } from '@floating-ui/dom';
import {
  addMonths,
  addSeconds,
  endOfDay,
  endOfMonth,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import tw, { theme } from 'twin.macro';

import Icon from '../../../icon';
import { ThemeContext } from '../../../theme-provider';
import { HighlightType } from '../../../types';
import {
  getCalendarLayoutForMonth,
  isValidDate,
  months,
} from '../../utils/calendar-utils';
import CalendarDateComponent from '../calendar-date';
import YearSelector from '../year-month-selector';

interface CalendarWidgetProps {
  selectedDate: Date | string | null;
  max: Date | string | null;
  min: Date | string | null;
  setSelectedDate?: (date: Date, close?: boolean) => void;
  highlights?: HighlightType[];
  notes?: string | React.ReactNode;
  disableDate?: (date: Date) => boolean;
  loading?: boolean;
  monthSelector?: boolean;
  above?: boolean;
}

const weekOrder = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const CalendarWidget: React.FC<CalendarWidgetProps> = ({
  selectedDate,
  setSelectedDate,
  max,
  min,
  highlights,
  notes,
  disableDate,
  loading = false,
  monthSelector = false,
}) => {
  const [openYearSelector, setOpenYearSelector] = useState(false);
  const [suggestedDate, setSuggestedDate] = useState(new Date().getDate());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [maxDate, setMaxDate] = useState(endOfDay(new Date(2100, 0, 1)));
  const [minDate, setMinDate] = useState(startOfDay(new Date(1900, 0, 1)));

  const { isNewThemeApplied } = useContext(ThemeContext);

  const suggestedDateObj = useMemo(() => {
    if (
      suggestedDate !== null &&
      selectedMonth !== null &&
      selectedYear !== null
    ) {
      return endOfDay(new Date(selectedYear, selectedMonth, suggestedDate));
    }
    return endOfDay(new Date());
  }, [suggestedDate, selectedMonth, selectedYear]);

  useEffect(() => {
    const date =
      selectedDate && isValidDate(selectedDate)
        ? startOfDay(new Date(selectedDate))
        : startOfDay(new Date());
    setDateSkeletonFromDate(date);
  }, [selectedDate]);

  useEffect(() => {
    if (max && isValidDate(max)) {
      setMaxDate(endOfDay(new Date(max)));
    }
  }, [max]);

  useEffect(() => {
    if (min && isValidDate(min)) {
      setMinDate(startOfDay(new Date(min)));
    }
  }, [min]);

  const setDateSkeletonFromDate = (date: Date) => {
    setSuggestedDate(date.getDate());
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  };

  const handleDecrementMonth = () => {
    let newDate = new Date(selectedYear, selectedMonth, suggestedDate);
    newDate = addMonths(newDate, -1);
    setDateSkeletonFromDate(newDate);
  };

  const handleIncrementMonth = () => {
    let newDate = new Date(selectedYear, selectedMonth, suggestedDate);
    newDate = addMonths(newDate, 1);
    setDateSkeletonFromDate(newDate);
  };

  return (
    <div tw="flex flex-col flex-grow relative width[290px]">
      {loading && (
        <div
          data-testid="calender-widget-loading-icon"
          css={[
            tw`absolute z-30 left-0 top-0 bottom-0 left-0 right-0`,
            tw`flex justify-center items-center bg-white bg-opacity-60`,
            tw`border rounded-tiny border-border-primary shadow`,
          ]}
        >
          <div tw="mt-28 animate-spin">
            <Icon name="spinner" fill="none" />
          </div>
        </div>
      )}

      {!loading && (openYearSelector || monthSelector) && (
        <div data-testid="year-selector-wrapper" tw="z-10">
          <div tw="z-30 bg-background-white">
            <YearSelector
              year={selectedYear}
              min={minDate}
              max={maxDate}
              setYear={setSelectedYear}
              setMonth={(m: number) => {
                if (setSelectedDate && monthSelector)
                  setSelectedDate(
                    new Date(
                      selectedYear,
                      m,
                      monthSelector ? 1 : suggestedDate,
                    ),
                    monthSelector,
                  );
                setSelectedMonth(m);
              }}
              close={() => setOpenYearSelector(false)}
              month={selectedMonth}
              hideBackArrow={monthSelector}
            />
          </div>
        </div>
      )}

      {!openYearSelector && !monthSelector && (
        <div
          css={[
            tw`border rounded-tiny border-border-secondary shadow bg-background-white`,
            notes && isNewThemeApplied && tw`border-b-0`,
          ]}
        >
          <div tw="p-small grid grid-cols-7 gap-y-small justify-items-center">
            <button
              data-testid="prev-month-arrow"
              tw="focus:outline-none disabled:(opacity-50 cursor-not-allowed)"
              type="button"
              disabled={isBefore(
                addSeconds(startOfMonth(suggestedDateObj), -1),
                minDate,
              )}
              onClick={handleDecrementMonth}
            >
              <Icon
                name="caret-left"
                fill={
                  isNewThemeApplied
                    ? theme`colors.icon-primary`
                    : theme`colors.background`
                }
              />
            </button>

            <div tw="col-start-2 col-end-7">
              <button
                data-testid="open-year-selector"
                type="button"
                onClick={() => setOpenYearSelector(true)}
                css={[
                  tw`text-text-primary font-semibold bg-background-brand-faded rounded-tiny p-extra-small`,
                  !isNewThemeApplied && tw`text-primary bg-grey04`,
                ]}
              >
                {`${months[selectedMonth]} ${selectedYear}`}
              </button>
            </div>
            <button
              data-testid="next-month-arrow"
              tw="focus:outline-none disabled:(opacity-50 cursor-not-allowed)"
              type="button"
              disabled={isAfter(
                addSeconds(endOfMonth(suggestedDateObj), 1),
                maxDate,
              )}
              onClick={handleIncrementMonth}
            >
              <Icon
                name="caret-right"
                fill={
                  isNewThemeApplied
                    ? theme`colors.icon-primary`
                    : theme`colors.background`
                }
              />
            </button>

            {weekOrder.map((day) => (
              <div
                key={day}
                css={[
                  tw`text-text-primary font-semibold`,
                  !isNewThemeApplied && tw`text-background`,
                ]}
              >
                {day}
              </div>
            ))}

            {getCalendarLayoutForMonth(suggestedDateObj).map((d, ind) => (
              <CalendarDateComponent
                key={d ? d.toString() : ind}
                date={d}
                selectedDate={
                  selectedDate && isValidDate(selectedDate)
                    ? new Date(selectedDate)
                    : null
                }
                minDate={minDate}
                maxDate={maxDate}
                highlight={highlights?.find((item) =>
                  isSameDay(new Date(item.date), d),
                )}
                disableDate={disableDate}
                onClick={(clickedDate) => {
                  if (setSelectedDate) setSelectedDate(clickedDate);
                }}
              />
            ))}

            {/* Notes for the old theme */}
            {!isNewThemeApplied && notes && (
              <div
                data-testid="calendar-widget-notes"
                tw="flex flex-col items-center col-span-7 p-12 rounded-4 bg-slateGrey50 text-pxs w-full"
              >
                <p>{notes}</p>
              </div>
            )}
          </div>

          {/* Notes for the new theme */}
          {isNewThemeApplied && notes && (
            <div
              data-testid="calendar-widget-notes"
              tw="flex flex-col items-center p-small bg-background-primary text-pxs text-text-secondary border-b rounded-b-tiny border-border-secondary"
            >
              <p>{notes}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CalendarWidgetPortal: React.FC<
  React.PropsWithChildren<
    CalendarWidgetProps & {
      open: boolean;
      datePickerRef?: HTMLDivElement | null;
    }
  >
> = (props) => {
  const { above, open, children, datePickerRef } = props;
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!calendarRef.current) return;
    if (!datePickerRef) return;

    // eslint-disable-next-line consistent-return
    return autoUpdate(datePickerRef, calendarRef.current, () => {
      if (!calendarRef.current) return;
      computePosition(datePickerRef, calendarRef.current, {
        middleware: [shift()],
      }).then(({ y }) => {
        if (!calendarRef.current) return;
        const calendarHeight =
          calendarRef.current?.getBoundingClientRect().height ?? 0;
        const datePickerHeight =
          datePickerRef.getBoundingClientRect().height ?? 0;
        Object.assign(calendarRef.current.style, {
          top: above
            ? `${y + 4}px` // 4px is the margin between the input field and the calendar
            : `${y - (calendarHeight + datePickerHeight) - 4}px`,
          right: '5%',
        });
      });
    });
  }, [open, above]);

  if (open) {
    return ReactDOM.createPortal(
      <div
        data-testid="calendar-widget-wrapper"
        ref={calendarRef}
        css={[
          tw`absolute flex flex-grow rounded-tiny bg-background-white z-10`,
          tw`right-0`,
        ]}
      >
        <CalendarWidget {...props}>{children}</CalendarWidget>
      </div>,
      datePickerRef as HTMLElement,
    );
  }
  return null;
};
export { CalendarWidgetPortal };
export default CalendarWidget;
