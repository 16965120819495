import { useTranslation } from 'react-i18next';

import {
  CompanyUserCapability,
  useAddCompanyUserMutation,
  useGetCurrentCompanyIdQuery,
} from '../../../__generated__/graphql';
import {
  errorNotification,
  successNotification,
} from '../../../app/services/notification-services';
import { AddSignatoryFormValues } from '../../components/progress-widget-content/add-signatory-modal';

const useAddSignatory = (): {
  loading: boolean;
  handleAddSignatory: (values: AddSignatoryFormValues) => Promise<void>;
} => {
  const { t } = useTranslation('contract-onboarding.company');

  const {
    data: companyResponse,
    loading: loadingCurrentCompany,
  } = useGetCurrentCompanyIdQuery();

  const [
    addCompanyUser,
    { loading: loadingAddCompanyUser },
  ] = useAddCompanyUserMutation({
    onError: () => {
      errorNotification(
        '',
        t('onboarding-phase.add-signatory.failed', 'Failed to add a signatory'),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t(
          'onboarding-phase.add-signatory.success',
          'Successfully added a signatory',
        ),
        false,
      );
    },
  });

  const handleAddSignatory = async (values: AddSignatoryFormValues) => {
    if (!companyResponse?.company?.id) return;

    await addCompanyUser({
      variables: {
        user: {
          companyId: companyResponse.company.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.designation,
          capabilities: [CompanyUserCapability.SIGNATORY],
        },
      },
    });
  };
  return {
    loading: loadingCurrentCompany || loadingAddCompanyUser,
    handleAddSignatory,
  };
};

export default useAddSignatory;
