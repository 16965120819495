import { gql } from '@apollo/client';

export default gql`
  query GetStartDateRestrictions(
    $country: CountryCode!
    $countryStateCode: String
    $workStatus: CountryWorkStatus
  ) {
    country(country: $country) {
      code
      compliance(countryStateCode: $countryStateCode) {
        countryCode
        countryState {
          code
        }
        joiningDateRestriction(workStatus: $workStatus) {
          earliestJoiningDate
          checkpoints {
            earliestVisaCompletionDate
          }
          holidays {
            key
            label
            year
            month
            date
            name
            type
            countryCode
          }
        }
      }
    }
  }
`;
