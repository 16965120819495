import React, { useEffect, useMemo } from 'react';

import { useDebounce } from 'react-use';

import { differenceInCalendarDays } from 'date-fns';

import {
  CalculationBreakdownFragment,
  ContractTerm,
  ContractType,
  ContributionBy,
  CountryCode,
  CountryWorkShiftStandards,
  CurrencyCode,
  DeductionDefinition,
  DeductionUnit,
  Maybe,
  RateFrequency,
  RateType,
  SalaryCalculationParam,
  useGetSalaryCalculationLazyQuery,
  useGetSalaryCalculationParamsCountryLazyQuery,
} from '__generated__/graphql';

import { useGetAndGroupDeductionDefinitions } from '../../../hooks';
import { DeductionFormValues } from '../../services/deduction';
import SalaryCalculatorView from '../salary-calculator-view';

const getConvertedMonthlyAmount = (
  amount: number,
  frequency?: RateFrequency | null,
  averageWorkingHoursPerMonth?: CountryWorkShiftStandards['averageWorkingHoursPerMonth'],
) =>
  frequency === RateFrequency.HOURLY && averageWorkingHoursPerMonth
    ? amount * averageWorkingHoursPerMonth
    : amount;

const getConstantOverridesFromDeductionsInput = (
  deductions: DeductionFormValues | null | undefined,
  deductionDefinitionMap: Maybe<{ [p: string]: DeductionDefinition }>,
) => {
  if (!deductionDefinitionMap) return [];

  return deductions
    ? Object.entries(deductions).map(([key, deductionValue]) => ({
        name:
          deductionDefinitionMap[key].contributionBy === ContributionBy.EMPLOYER
            ? 'employerProvidentFundMonthlyFixedValue'
            : 'employeeProvidentFundMonthlyFixedValue',
        // Assume 'uncapped' if unit is percentage
        value: deductionValue?.unit === DeductionUnit.PERCENTAGE ? 0.0 : 1800.0,
      }))
    : [];
};

interface SalaryCalculatorProps {
  title?: string;
  amount: number;
  country?: CountryCode | null;
  state?: string | null;
  currency?: CurrencyCode | null;
  frequency?: RateFrequency | null;
  rateType?: RateType | null;
  contractType?: ContractType | null;
  contractTerm?: ContractTerm | null;
  averageWorkingHoursPerMonth?: number | null;
  deductions?: DeductionFormValues | null;
  startOn?: string;
  endOn?: string;
  setPostProbationBaseSalary?: (baseSalary: number) => void;
}

const SalaryCalculator: React.FC<SalaryCalculatorProps> = ({
  title,
  amount,
  country,
  state,
  currency,
  frequency,
  rateType,
  contractType,
  contractTerm,
  averageWorkingHoursPerMonth,
  deductions,
  startOn,
  endOn,
  setPostProbationBaseSalary,
}) => {
  const [
    getSalaryCalculationParamForCountry,
    { data: paramsResult, loading: loadingParamsForCountry },
  ] = useGetSalaryCalculationParamsCountryLazyQuery();

  const [
    getCalculation,
    { data: salaryCalculationResult, loading: loadingSalaryCalculator },
  ] = useGetSalaryCalculationLazyQuery();

  const loadingSalaryCalculatorInfo = useMemo(
    () => loadingSalaryCalculator || loadingParamsForCountry,
    [loadingSalaryCalculator, loadingParamsForCountry],
  );

  const salaryCalculation = useMemo((): {
    companyPayable?: CalculationBreakdownFragment;
    employeePayable?: CalculationBreakdownFragment;
  } => {
    const salaryCalculationLoaded = salaryCalculationResult?.salaryCalculation;
    // since BE does not send Hourly, we need to check for MONTHLY even if selected frequency is HOURLY
    if (frequency === RateFrequency.HOURLY) {
      return {
        companyPayable: salaryCalculationLoaded?.companyPayable?.find(
          (payable) => payable?.frequency === RateFrequency.MONTHLY,
        ),
        employeePayable: salaryCalculationLoaded?.employeePayable?.find(
          (payable) => payable?.frequency === RateFrequency.MONTHLY,
        ),
      };
    }

    return {
      companyPayable: salaryCalculationLoaded?.companyPayable?.find(
        (payable) => payable?.frequency === frequency,
      ),
      employeePayable: salaryCalculationLoaded?.employeePayable?.find(
        (payable) => payable?.frequency === frequency,
      ),
    };
  }, [frequency, salaryCalculationResult?.salaryCalculation]);

  useEffect(() => {
    if (country) {
      getSalaryCalculationParamForCountry({ variables: { country } });
    }
  }, [country]);

  useEffect(() => {
    const probationBaseSalary =
      salaryCalculationResult?.salaryCalculation?.employeePayable
        ?.find((employeePayable) => employeePayable?.name === 'MONTHLY')
        ?.entries?.find((entry) => entry.name === 'gross')
        ?.entries?.find((entry) => entry.name === 'basic')?.amount ?? 0;
    if (setPostProbationBaseSalary) {
      setPostProbationBaseSalary(probationBaseSalary);
    }
  }, [salaryCalculationResult?.salaryCalculation]);

  const payableType = useMemo(() => {
    const param = paramsResult?.salaryCalculationParams?.[0];
    return param &&
      param.country === country &&
      (!state || param.states?.some((it) => it === state)) &&
      param?.payableType?.length > 0
      ? param?.payableType
      : null;
  }, [paramsResult, country, state]);

  const {
    definitionMap: deductionDefinitionMap,
  } = useGetAndGroupDeductionDefinitions({
    country,
    state,
    contractType,
    contractTerm,
  });

  useDebounce(
    () => {
      if (country && currency && rateType && frequency && payableType) {
        const convertedAmount = getConvertedMonthlyAmount(
          amount ?? 0,
          frequency,
          averageWorkingHoursPerMonth,
        );
        const params: SalaryCalculationParam = {
          amount: convertedAmount,
          country,
          state,
          currency,
          frequency:
            frequency === RateFrequency.HOURLY
              ? RateFrequency.MONTHLY
              : frequency, // since BE calculates only based on MONTHLY and ANNUALLY, we convert hourly amount to MONTHLY and set frequency MONTHLY
          payableType,
          rateType,
          constantOverrides: getConstantOverridesFromDeductionsInput(
            deductions,
            deductionDefinitionMap,
          ),
        };
        getCalculation({ variables: { params } });
      }
    },
    1000,
    [
      amount,
      country,
      state,
      currency,
      frequency,
      payableType,
      rateType,
      averageWorkingHoursPerMonth,
      JSON.stringify(deductions),
      deductionDefinitionMap,
    ],
  );

  const daysBetweenStartAndEndDate = differenceInCalendarDays(
    new Date(endOn ?? ''),
    new Date(startOn ?? ''),
  );

  const shouldShowSalaryCalculator =
    salaryCalculation != null &&
    payableType != null &&
    contractType === ContractType.EMPLOYEE &&
    (contractTerm === ContractTerm.PERMANENT ||
      (contractTerm === ContractTerm.FIXED &&
        daysBetweenStartAndEndDate >= 365));

  return (
    <SalaryCalculatorView
      title={title}
      show={shouldShowSalaryCalculator}
      salaryCalculation={salaryCalculation}
      country={country}
      loading={loadingSalaryCalculatorInfo}
      amount={amount ?? 0}
      currency={currency}
      averageHours={averageWorkingHoursPerMonth}
      frequency={frequency}
    />
  );
};

export default SalaryCalculator;
