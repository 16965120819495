/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ToolTip } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import Toggle from 'common/components/toggle';
import { getFixedPayInputLabelText } from 'contract-onboarding/company/components/fixed-pay-input';
import PayInputHeaderTooltip from 'contract-onboarding/company/components/fixed-pay-input/components/pay-input-header-tool-tip';

import {
  ContractTerm,
  FixedPayComponent,
  InputMaybe,
  RateFrequency,
  RateType,
} from '__generated__/graphql';

const Heading = tw.div`
    font-normal text-background text-ps font-semibold leading-[26px]
`;

const SubHeading = tw.div`
    font-normal text-ps text-grey01
`;

interface PostProbationToggleBarProps {
  showNested: boolean;
  setShowNested: React.Dispatch<React.SetStateAction<boolean>>;
  contractTerm?: ContractTerm | null | undefined;
  frequency?: InputMaybe<RateFrequency> | undefined;
  rateType?: InputMaybe<RateType> | undefined;
  payComponent?: FixedPayComponent;
  disabled?: boolean;
}

const PostProbationToggleBar: React.FC<PostProbationToggleBarProps> = ({
  showNested,
  setShowNested,
  contractTerm,
  frequency,
  rateType,
  payComponent,
  disabled = false,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const fixedPayInputLabel = useMemo(
    () =>
      getFixedPayInputLabelText({
        t,
        contractTerm,
        rateType,
        frequency,
        label: 'base',
        isProbationToggleOn: showNested,
      }),
    [rateType, frequency, showNested],
  );

  const getLabelForInput = (salaryType: string) => {
    if (showNested && contractTerm && frequency && rateType)
      return (
        <>
          <span tw="text-primaryBlue500">{salaryType}</span> Salary -{' '}
          {fixedPayInputLabel}
        </>
      );

    return t('post-probation-toggle-bar.heading', 'Post Probation Salary');
  };

  return (
    <div tw="flex flex-col">
      <div tw="flex flex-row gap-x-[10px] items-center">
        <Heading>{getLabelForInput('Post Probation')}</Heading>
        {payComponent && showNested && (
          <PayInputHeaderTooltip
            payComponent={payComponent}
            contractTerm={contractTerm}
            fieldPath="probationBasePay"
            color={theme`colors.background`}
            isProbationToggleOn={showNested}
            salaryType={t(
              'post-probation-toggle-bar.salaryType',
              'after completing the probationary period',
            )}
          />
        )}
      </div>
      <div tw="flex flex-row items-center justify-between">
        <SubHeading>
          {t(
            'post-probation-toggle-bar.sub-heading',
            'If there is a probation period, does the probation salary differ from the gross salary?',
          )}
        </SubHeading>
        <ToolTip
          content={t(
            'post-probation-toggle-bar.disabled.tooltip',
            'To enable the toggle, enable Probation Period from contract tab.',
          )}
          styles={disabled ? tw`block` : tw`hidden`}
        >
          <Toggle
            id="toggle-check"
            defaultChecked={false}
            checked={showNested}
            disabled={disabled}
            onChange={(e) => {
              setShowNested(e.target.checked);
            }}
          />
        </ToolTip>
      </div>
    </div>
  );
};

export default PostProbationToggleBar;
