import {
  Experience,
  ModuleConfig,
  NavGroups,
  WidgetPosition,
  WidgetVariant,
} from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'payments',
  permission: 'payments',
  folder: 'payments',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Invoice Payments',
    route: routes.root,
    sort: 5,
    home: false,
    icon: { name: 'monetization' },
    activeIcon: { name: 'monetization-filled' },
    group: NavGroups.MAIN,
  },
  widget: {
    sort: 1,
    list: [
      {
        show: true,
        file: 'pending-bundle-widget',
        position: WidgetPosition.LEFT,
        variant: WidgetVariant.HALF_WIDTH,
      },
      {
        show: true,
        file: 'invoice-payments-widget',
        position: WidgetPosition.LEFT,
        variant: WidgetVariant.HALF_WIDTH,
      },
      {
        show: true,
        file: 'payment-not-received-widget',
        position: WidgetPosition.LEFT,
        variant: WidgetVariant.HALF_WIDTH,
      },
    ],
  },
};

export default config;
