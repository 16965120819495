import React, { useContext } from 'react';

import { css } from '@emotion/react';
import tw, { TwStyle, theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';
import ToolTip from '../tooltip';
import CircleIcon from './assets/circle.svg';

export enum RadioVariant {
  DEFAULT = 'DEFAULT',
  TOP_RIGHT = 'TOP_RIGHT',
}

interface RadioProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  labelStyles?: TwStyle | TwStyle[];
  info?: string;
  variant?: RadioVariant;
  borderlessButton?: boolean;
}

const styles = {
  [RadioVariant.DEFAULT]: {
    container: tw``,
    input: tw``,
  },
  [RadioVariant.TOP_RIGHT]: {
    container: tw`relative`,
    input: tw`absolute top-none right-none m-base`,
  },
};

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      labelStyles,
      disabled = false,
      info = undefined,
      borderlessButton = false,
      variant = RadioVariant.DEFAULT,
      ...props
    },
    ref,
  ) => {
    const { isNewThemeApplied } = useContext(ThemeContext);
    return (
      <label
        css={[
          tw`flex flex-row items-center gap-x-small`,
          !borderlessButton &&
            tw`border border-border-primary rounded-tiny p-base bg-background-white`,
          !isNewThemeApplied &&
            !borderlessButton &&
            tw`border-grey03 bg-transparent`,
          styles[variant].container,
          css`
            :has(:checked) {
              border-color: ${theme`colors.border-brand`};
              background-color: ${theme`colors.background-brand-faded`};
            }
          `,
          !isNewThemeApplied &&
            css`
              :has(:checked) {
                border-color: ${theme`colors.primary`};
                background-color: ${theme`colors.transparent`};
              }
            `,
          disabled && tw`cursor-not-allowed`,
          labelStyles,
        ]}
      >
        <input
          disabled={disabled}
          css={[
            tw`border border-border-primary w-[22px] h-[22px] flex items-center justify-center`,
            !disabled && tw`cursor-pointer`,
            !isNewThemeApplied && tw`border-grey03`,
            borderlessButton && tw`w-[20px] h-[20px] border-2`,
            !isNewThemeApplied && borderlessButton && tw`border-grey01`,
            tw`focus:(outline-none ring-transparent)`,
            tw`focus-visible:(outline-primary)`,
            tw`checked:focus:(border-none ring-offset-0)`,
            css`
              color: ${disabled
                ? theme`colors.background-tertiary`
                : !isNewThemeApplied
                ? theme`colors.background-action`
                : theme`colors.background-brand`};
              :checked {
                background-image: url('${CircleIcon}');
                background-size: auto;
              }
            `,
            styles[variant].input,
          ]}
          type="radio"
          ref={ref}
          {...props}
        />
        <div
          css={[
            tw`text-ps flex-grow`,
            !isNewThemeApplied && tw`text-background`,
            !isNewThemeApplied && borderlessButton && tw`text-slateGrey500`,
            disabled &&
              css`
                color: ${theme`colors.text-tertiary`};
              `,
            !isNewThemeApplied && disabled && tw`text-grey03`,
          ]}
        >
          {children}
        </div>
        {info && (
          <ToolTip variant="top" content={info}>
            <Icon
              name="info"
              height="16"
              width="16"
              fill={
                disabled
                  ? !isNewThemeApplied
                    ? theme`colors.grey03`
                    : theme`colors.text-tertiary`
                  : undefined
              }
            />
          </ToolTip>
        )}
      </label>
    );
  },
);

export default Radio;
