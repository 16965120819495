import React from 'react';

import tw, { TwStyle } from 'twin.macro';

import { colorStyles } from '../config';
import { StepperPosition, StepperType } from '../types';

const ProgressLine: React.FC<{
  position: StepperPosition;
  color: StepperType;
  style?: TwStyle;
}> = ({ position = StepperPosition.BOTTOM, color, style }) => (
  <div
    data-testid="progress-line"
    css={[
      tw`bg-background-primary absolute w-full left-0`,
      [StepperPosition.BOTTOM, StepperPosition.TOP].includes(position) &&
        tw`h-full w-2 pb-[22px] pt-[22px] left-small`,
      [StepperPosition.RIGHT, StepperPosition.LEFT].includes(position) &&
        tw`w-full h-2 top-auto bottom-auto`,
      colorStyles[color],
      style,
    ]}
  />
);

export default ProgressLine;
