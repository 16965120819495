/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton, ThemeContext, ToolTip } from '@multiplier/common';
import { theme } from 'twin.macro';

import { Contract } from '__generated__/graphql';

const OnboardingActions: React.FC<{
  contractStarted: Contract['contractStarted'];
  contractBlocked: Contract['contractBlocked'];
  handleDeleteClick: () => void;
}> = ({ contractStarted, contractBlocked, handleDeleteClick }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('team.company');

  const disableContractDeleteButton = !!contractStarted || !!contractBlocked;

  return (
    <div tw="flex justify-end">
      <ToolTip variant="bottom" content={t('action.remove.tooltip', 'Remove')}>
        <IconButton
          name="bin"
          fill={!isNewThemeApplied ? 'transparent' : undefined}
          stroke={!isNewThemeApplied ? theme`colors.icon-brand` : undefined}
          variant="outline"
          size="medium"
          data-testid="contract-delete-button"
          disabled={disableContractDeleteButton}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleDeleteClick();
          }}
        />
      </ToolTip>
    </div>
  );
};

export default OnboardingActions;
