import { gql } from '@apollo/client';

export default gql`
  mutation Authenticate($input: AuthenticateInput) {
    authenticate(input: $input)
      @rest(
        type: "AuthenticationResponse"
        method: "POST"
        path: "authenticate"
        endpoint: "user"
      ) {
      id_token
      mfaEnabled
      mfaRequired
    }
  }
`;
