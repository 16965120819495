import React from 'react';

import 'twin.macro';
import { Controller, useFormContext } from 'react-hook-form';

import {
  TableFilterConfigType,
  TableFilterFormDateValue,
  TableFilterFormMonthRangeValue,
  TableFilterFormParams,
  TableFilterFormRangeValue,
  TableFilterTypes,
} from '../../../../types';
import DateRangeFilterInput from '../date-range-filter-input';
import DropdownFilterInput from '../dropdown-filter-input';
import MonthFilterInput from '../month-filter-input';
import MonthRangeFilterInput from '../month-range-filter-input';
import MultiDropdownFilterInput from '../multi-dropdown-filter-input';
import MultiTextInput from '../multi-text-input';
import RangeFilterInput from '../range-filter-input';
import TextFilterInput from '../text-filter-input';

const FilterValueInput: React.FC<{
  filterKey: string;
  fieldName: string;
  filters: TableFilterConfigType;
}> = ({ filterKey, fieldName, filters }) => {
  const { control } = useFormContext<TableFilterFormParams>();

  const filterConfig = filters[filterKey];
  const filterType = filterConfig?.type;

  return (
    <div tw="flex-grow flex-1">
      <Controller
        name={fieldName as 'filters.0.value'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            {(filterType === TableFilterTypes.DROPDOWN ||
              filterType === TableFilterTypes.CUSTOM) &&
              filterKey !== '' && (
                <DropdownFilterInput
                  value={value as string}
                  onChange={onChange}
                  filterConfig={filterConfig}
                />
              )}
            {filterType === TableFilterTypes.MULTI_TEXT_INPUT &&
              filterKey !== '' && (
                <MultiTextInput
                  values={value as string[]}
                  onChange={onChange}
                  filterConfig={filterConfig}
                />
              )}
            {filterType === TableFilterTypes.MULTI_DROPDOWN &&
              filterKey !== '' && (
                <MultiDropdownFilterInput
                  values={value as string[]}
                  filterConfig={filterConfig}
                  onChange={onChange}
                />
              )}

            {(filterType === TableFilterTypes.TEXT ||
              filterType === TableFilterTypes.NUMBER ||
              filterKey === '') && (
              <TextFilterInput
                isNumber={
                  filterKey === 'contractId' ||
                  filterKey === 'year' ||
                  filterType === TableFilterTypes.NUMBER
                }
                value={value as string}
                onChange={onChange}
                filterConfig={filterConfig}
              />
            )}

            {filterType === TableFilterTypes.DATE_RANGE && filterKey !== '' && (
              <DateRangeFilterInput
                value={value as TableFilterFormDateValue}
                onChange={onChange}
              />
            )}

            {filterType === TableFilterTypes.MONTH && filterKey !== '' && (
              <MonthFilterInput
                value={value as string}
                onChange={onChange}
                filterConfig={filterConfig}
              />
            )}

            {filterType === TableFilterTypes.MONTH_RANGE &&
              filterKey !== '' && (
                <MonthRangeFilterInput
                  value={value as TableFilterFormMonthRangeValue}
                  onChange={onChange}
                />
              )}

            {filterType === TableFilterTypes.RANGE && (
              <RangeFilterInput
                value={value as TableFilterFormRangeValue}
                onChange={onChange}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

export default FilterValueInput;
