import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CardSeparator } from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import { addMonths } from 'date-fns';
import 'twin.macro';

import AppFeature from 'app/features';
import { notEmpty } from 'app/utils/array';
import DatePicker from 'common/components/date-picker';
import TextInput from 'common/components/text-input';
import { Religion } from 'common/constants/default-labels';
import AdditionalPaysTable from 'contract-onboarding/company/components/additional-pays-table';
import ContributionsSection from 'contract-onboarding/company/components/contributions-section';
import Esop from 'contract-onboarding/company/components/esop';
import FixedPayInput from 'contract-onboarding/company/components/fixed-pay-input';
import PayrollFrequency from 'contract-onboarding/company/components/payroll-frequency';
import PayrollFrequencySelector from 'contract-onboarding/company/components/payroll-frequency-selector';
import PlatformFee from 'contract-onboarding/company/components/platform-fee';
import PrePostProbationDisclaimer from 'contract-onboarding/company/components/pre-post-probation-disclaimer';
import ProbationSalary from 'contract-onboarding/company/components/probation-salary';
import PostProbationToggleBar from 'contract-onboarding/company/components/probation-salary/components/post-probation-toggle-bar';
import SalaryCalculator from 'contract-onboarding/company/components/salary-calculator';
import {
  useGetPayrollConfig,
  useSubmitDefinitionCompensation,
} from 'contract-onboarding/company/hooks';
import {
  convertBasePayToMonthlyPay,
  getAverageWorkingHoursPerMonth,
  getCurrencyOptions,
  isMonthEnabledForAdditionalPay,
} from 'contract-onboarding/company/services/compensation';
import compensationSchema from 'contract-onboarding/company/services/compensation-schema';
import {
  getDefaultDeductionValue,
  mapDeductionFromCompensation,
} from 'contract-onboarding/company/services/deduction';
import {
  FormCard,
  FormLayout,
  StepLayout,
} from 'contract-onboarding/components/layout';
import StepNavigationFooter, {
  OnboardingStepProps,
} from 'contract-onboarding/components/step-navigation-footer';
import { useGetAndGroupDeductionDefinitions } from 'contract-onboarding/hooks';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';
import getPaymentFrequenciesForRateFrequency from 'contract-onboarding/services/get-payment-frequencies-for-rate-frequency';
import {
  useAdditionalCompensationsMapper,
  useIsNewAllowancesConfigEnabled,
} from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Company,
  Compensation,
  Compliance,
  ComplianceParamPeriodLimit,
  Contract,
  ContractTerm,
  ContractType,
  Country,
  CountryCode,
  CountryWorkStatus,
  CurrencyCode,
  FixedPayComponent,
  Member,
  PayFrequency,
  RateFrequency,
  useGetCountryLocalCurrencyLazyQuery,
} from '__generated__/graphql';

import { getEsopFromGrant } from '..';
import stepConfig from '../../../step-config';
import PayrollCutoffDateHint from '../../basic-details/components/new-payroll-cutoff-date-hint';
import JobCodeCallout from '../job-code-callout';

const getDefaultPaymentFrequency = ({
  contractPaymentFrequency,
}: {
  contractPaymentFrequency?: FixedPayComponent['paymentFrequency'];
}) => {
  if (contractPaymentFrequency) return contractPaymentFrequency;
  return null;
};

const getBasePayFrequency = ({
  contractBasePayFrequency,
  amount,
  contractType,
}: {
  contractBasePayFrequency?: FixedPayComponent['frequency'];
  amount?: FixedPayComponent['amount'];
  contractType?: ContractType;
}) => {
  if (
    (contractType === ContractType.HR_MEMBER || amount) &&
    contractBasePayFrequency
  )
    return contractBasePayFrequency;
  return undefined;
};

const showPayrollFrequencyForPermanentContract = ({
  isContractOnboardingHourlyPayEnabled,
  contractTerm,
  contractType,
  country,
}: {
  isContractOnboardingHourlyPayEnabled: boolean;
  contractTerm?: ContractTerm | null;
  contractType?: ContractType | null;
  country?: CountryCode | null;
}): boolean =>
  !isContractOnboardingHourlyPayEnabled &&
  contractTerm === ContractTerm.PERMANENT &&
  contractType !== ContractType.FREELANCER &&
  [CountryCode.PHL, CountryCode.USA, CountryCode.GBR].includes(
    country as CountryCode,
  );

export const CompensationViewForm: React.FC<
  OnboardingStepProps & {
    compliantCurrencies: (CurrencyCode | null | undefined)[];
    contractTerm?: ContractTerm | null;
    contractType?: ContractType | null;
    contractWorkStatus?: CountryWorkStatus | null;
    country: Contract['country'];
    compensation?: Compensation | null;
    compliance?: Compliance | null;
    contractId?: string | null;
    company?: Company | null;
    alreadyHired?: boolean | null;
    member?: Member | null;
    showPricing: boolean;
    startOn: Contract['startOn'];
    endOn?: string;
    labourStandards?: Country['labourStandards'];
    countryStateCode: Contract['countryStateCode'];
    legalEntityId?: Contract['legalEntityId'];
  }
> = ({
  currentStep,
  onboardingSteps,
  compliantCurrencies,
  contractTerm,
  contractType,
  contractWorkStatus,
  country,
  compensation,
  compliance,
  contractId,
  company,
  alreadyHired,
  member,
  showPricing,
  startOn,
  endOn,
  labourStandards,
  countryStateCode,
  legalEntityId,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { t: tCommon } = useTranslation('contract-onboarding.common');
  const { id } = useParams<{ id?: string }>();
  const [showNested, setShowNested] = useState(false);
  const { definitions, definitionMap } = useGetAndGroupDeductionDefinitions({
    country,
    state: countryStateCode,
    contractType,
    contractTerm,
  });

  const [
    isProbationMoreThanPostProbation,
    setIsProbationMoreThanPostProbation,
  ] = useState(false);
  const { onSubmit, loading } = useSubmitDefinitionCompensation(
    contractId,
    showNested,
    contractType === ContractType.HR_MEMBER,
    contractType,
  );
  const isContractOnboardingHourlyPayEnabled = useFeature(
    'contract-onboarding-hourly-pay',
  )?.on;
  const isNotFreelancer = contractType !== ContractType.FREELANCER;
  const isPostProbationFeatureEnabled = useFeature(
    AppFeature.PROBATION_SALARY_FEATURE,
  )?.on;

  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: contractType,
  });

  const averageWorkingHoursPerMonth =
    labourStandards?.workShift?.averageWorkingHoursPerMonth;

  const currencyOptions = useMemo(
    () => getCurrencyOptions(compliantCurrencies),
    [compliantCurrencies],
  );

  const sorOnboardingContext = useSorOnboardingContext({
    type: contractType,
    companyId: company?.id,
    legalEntityId,
  });

  const schema = useMemo(
    () =>
      compensationSchema({
        t: tCommon,
        contractType,
        contractTerm,
        contractWorkStatus,
        country,
        compliantCurrencies,
        currencyOptions,
        isContractOnboardingHourlyPayEnabled,
        countryRateFrequencyList:
          labourStandards?.compensation?.rateFrequency?.list,
        countryPaymentFrequencyList:
          labourStandards?.compensation?.paymentFrequency?.list,
        averageWorkingHoursPerMonth:
          labourStandards?.workShift?.averageWorkingHoursPerMonth,
        isPostProbationToggleOn: showNested,
        isAllowanceFeature: isNotFreelancer,
        deductionDefinitions: definitions,
        isNewAllowancesConfigEnabled,
        sorOnboardingContext,
      }),
    [
      contractType,
      contractTerm,
      compliantCurrencies,
      currencyOptions,
      isContractOnboardingHourlyPayEnabled,
      labourStandards,
      showNested,
      definitions,
    ],
  );

  const methods = useForm<CompensationFormValues>({
    mode: 'onChange',
    defaultValues: {
      basePay: {
        amount: 0,
      },
    },
    resolver: yupResolver(schema),
  });

  const shouldShowPayrollFrequencyDropdown = useMemo(
    () =>
      showPayrollFrequencyForPermanentContract({
        isContractOnboardingHourlyPayEnabled,
        contractTerm,
        contractType,
        country,
      }) || contractType === ContractType.CONTRACTOR,
    [contractTerm, contractType, country, isContractOnboardingHourlyPayEnabled],
  );

  const setPaymentFrequency = useCallback(
    (payFrequency: PayFrequency) => {
      methods.setValue('basePay.paymentFrequency', payFrequency, {
        shouldValidate: true,
      });
      methods.setValue('probationBasePay.paymentFrequency', payFrequency, {
        shouldValidate: true,
      });
      methods.trigger();
    },
    [methods.setValue],
  );

  const enableCurrentMonth = useMemo<boolean>(
    () => isMonthEnabledForAdditionalPay(startOn ?? ''),
    [compensation, startOn],
  );

  const mapperParams = useMemo(
    () => ({
      contract: {
        country,
        countryStateCode,
        type: contractType,
      },
    }),
    [country, countryStateCode, contractType],
  );

  const { mapToAdditionalPayFormValues } = useAdditionalCompensationsMapper(
    mapperParams,
  );

  useEffect(() => {
    if (
      id &&
      compensation &&
      compensation.postProbationBasePay &&
      compensation.probationBasePay
    ) {
      methods.reset({
        payType: compensation.payType ?? null,
        basePay: {
          name: compensation.postProbationBasePay.name,
          frequency: getBasePayFrequency({
            contractBasePayFrequency:
              compensation.postProbationBasePay.frequency,
            amount: compensation.postProbationBasePay.amount,
            contractType: contractType ?? undefined,
          }),
          rateType: compensation.postProbationBasePay.rateType,
          currency: compensation.postProbationBasePay.currency,
          amount: compensation.postProbationBasePay.amount,
          paymentFrequency: getDefaultPaymentFrequency({
            contractPaymentFrequency:
              compensation.postProbationBasePay.paymentFrequency,
          }),
        },
        probationBasePay: {
          name: compensation.probationBasePay.name,
          frequency: getBasePayFrequency({
            contractBasePayFrequency: compensation.probationBasePay.frequency,
            amount: compensation.probationBasePay.amount,
            contractType: contractType ?? undefined,
          }),
          rateType: compensation.probationBasePay.rateType,
          currency: compensation.probationBasePay.currency,
          amount: compensation?.probationBasePay?.amount,
          paymentFrequency: getDefaultPaymentFrequency({
            contractPaymentFrequency:
              compensation.probationBasePay.paymentFrequency,
          }),
        },
        payrollStart: compensation.payrollStart,
        esop: compensation?.grant?.length
          ? getEsopFromGrant(compensation?.grant?.[0])
          : undefined,
        additionalPays: mapToAdditionalPayFormValues(
          compensation?.additionalPays,
        ),
        deductions:
          mapDeductionFromCompensation(compensation, definitionMap) ||
          getDefaultDeductionValue(definitions),
      });
    }
  }, [
    compensation,
    labourStandards,
    isContractOnboardingHourlyPayEnabled,
    contractType,
    definitions,
    definitionMap,
    mapToAdditionalPayFormValues,
  ]);

  useEffect(() => {
    const currentPaymentFrequency = methods.watch(
      'basePay.paymentFrequency',
    ) as PayFrequency;

    // if a payfrequency gets selected due to choosing any rate but user has not saved it then reset it
    if (currentPaymentFrequency && !compensation?.basePay?.paymentFrequency)
      methods.setValue('basePay.paymentFrequency', null);
  }, [methods.watch('basePay.frequency')]);

  const [
    frequency,
    payFrequency,
    amount,
    currency,
    rateType,
    probationAmount,
  ] = useWatch({
    control: methods.control,
    name: [
      `basePay.frequency`,
      `basePay.paymentFrequency`,
      `basePay.amount`,
      `basePay.currency`,
      `basePay.rateType`,
      `probationBasePay.amount`,
    ],
  });

  const {
    formState: { isValid, errors },
  } = methods;

  useEffect(() => {
    if (showNested && amount && probationAmount) {
      if (
        parseInt(probationAmount.toString(), 10) >
        parseInt(amount.toString(), 10)
      ) {
        setIsProbationMoreThanPostProbation(true);
      } else setIsProbationMoreThanPostProbation(false);
    } else setIsProbationMoreThanPostProbation(false);
  }, [amount, probationAmount, showNested]);

  useEffect(() => {
    const basePayFrequency = frequency as RateFrequency;
    const currentPaymentFrequency = payFrequency as PayFrequency;
    const supportedPaymentFrequency = getPaymentFrequenciesForRateFrequency(
      labourStandards?.compensation?.paymentFrequency?.data,
      basePayFrequency,
    );
    if (
      (isContractOnboardingHourlyPayEnabled &&
        supportedPaymentFrequency?.payFrequencies?.length &&
        !supportedPaymentFrequency?.payFrequencies?.includes(
          currentPaymentFrequency,
        )) ||
      (contractType === ContractType.HR_MEMBER && !currentPaymentFrequency)
    ) {
      const freq =
        labourStandards?.compensation?.paymentFrequency?.defaultValue &&
        supportedPaymentFrequency?.payFrequencies?.includes(
          labourStandards?.compensation?.paymentFrequency?.defaultValue,
        )
          ? labourStandards?.compensation?.paymentFrequency?.defaultValue
          : supportedPaymentFrequency?.payFrequencies?.[0];
      methods.setValue('probationBasePay.paymentFrequency', freq);
      methods.setValue('basePay.paymentFrequency', freq);
    }
    methods.trigger();
  }, [
    isContractOnboardingHourlyPayEnabled,
    frequency,
    payFrequency,
    labourStandards,
  ]);

  useEffect(() => {
    if (id && compensation && compensation.probationBasePay) {
      if (compensation.probationBasePay.amount) setShowNested(true);
    }
  }, [compensation]);

  const shouldShowPaymentFrequencySelector = useMemo(
    () => !(contractType === ContractType.EMPLOYEE && !frequency),
    [contractType, frequency],
  );

  useEffect(() => {
    const probationBasePay = methods.getValues('probationBasePay.amount');
    const probationFrequency = methods.getValues('probationBasePay.frequency');
    const basePayPaymentFrequency = methods.getValues(
      'basePay.paymentFrequency',
    );

    if (showNested && !probationAmount) {
      methods.setValue('probationBasePay.amount', amount);
      methods.setValue('probationBasePay.frequency', frequency);
      methods.setValue(
        'probationBasePay.paymentFrequency',
        basePayPaymentFrequency,
      );

      methods.setValue('basePay.amount', undefined);
    }

    if (!showNested) {
      methods.setValue('basePay.amount', probationBasePay);
      methods.setValue('basePay.frequency', probationFrequency);

      methods.setValue('probationBasePay.amount', undefined);
    }
    methods.trigger();
  }, [showNested]);

  const probationPolicyCompliance = useMemo(
    () =>
      compliance?.complianceParams &&
      compliance.complianceParams
        ?.filter(notEmpty)
        .filter((param) => param.key === 'probationPolicy'),
    [compliance],
  );

  const [postProbationBaseSalary, setPostProbationBaseSalary] = useState(0);

  const [
    getLocalCurrency,
    { data: countryLocalCurrency },
  ] = useGetCountryLocalCurrencyLazyQuery();

  useEffect(() => {
    if (country) {
      getLocalCurrency({ variables: { country } });
    }
  }, [country]);

  const payrollConfig = useGetPayrollConfig({
    contractId: id ?? contractId,
    payFrequency,
    sorOnboardingContext,
  });

  useLayoutEffect(() => {
    methods.setValue('payrollConfigId', payrollConfig?.id as string);
    methods.trigger('payrollConfigId');
  }, [payrollConfig, errors.payrollConfigId?.message]);

  return (
    <StepLayout data-testid="compensation-view">
      <FormProvider {...methods}>
        <FormLayout onSubmit={methods.handleSubmit(onSubmit)}>
          <FormCard>
            {shouldShowPayrollFrequencyDropdown &&
              compensation?.postProbationBasePay && (
                <PayrollFrequency
                  fieldPath="basePay"
                  payComponent={compensation?.postProbationBasePay}
                  contractType={contractType}
                  countryPaymentFrequency={
                    labourStandards?.compensation?.paymentFrequency
                  }
                />
              )}

            {compensation?.postProbationBasePay && !showNested && (
              <div>
                <FixedPayInput
                  currencyOptions={currencyOptions}
                  localCurrency={countryLocalCurrency?.country?.mainCurrency}
                  contractType={contractType}
                  contractTerm={contractTerm}
                  fieldPath="basePay"
                  payComponent={compensation?.postProbationBasePay}
                  country={country}
                  countryRateFrequencies={
                    labourStandards?.compensation?.rateFrequency?.list
                  }
                  countryHourlyRates={
                    labourStandards?.compensation?.hourlyRates
                  }
                  hideLabel={showNested}
                  sorOnboardingContext={sorOnboardingContext}
                />
                {errors.basePay?.amount?.type === 'min-visa-pay' && (
                  <TextInput.Error>
                    {errors.basePay.amount.message}
                  </TextInput.Error>
                )}
                <JobCodeCallout
                  countryCode={country}
                  contractType={contractType}
                  frequency={frequency}
                  probationBasePayAmount={probationAmount}
                  postProbationBasePayAmount={amount}
                  member={member}
                />
                <SalaryCalculator
                  amount={Number(amount ?? 0)}
                  country={country}
                  state={countryStateCode}
                  currency={currency}
                  frequency={frequency}
                  rateType={rateType}
                  contractType={contractType}
                  contractTerm={contractTerm}
                  averageWorkingHoursPerMonth={averageWorkingHoursPerMonth}
                  deductions={methods.watch('deductions')}
                  startOn={startOn}
                  endOn={endOn}
                  setPostProbationBaseSalary={setPostProbationBaseSalary}
                />
              </div>
            )}

            {compensation?.probationBasePay && showNested && (
              <>
                <ProbationSalary
                  currencyOptions={currencyOptions}
                  inline={false}
                  country={country}
                  countryRateFrequencies={
                    labourStandards?.compensation?.rateFrequency?.list
                  }
                  countryHourlyRates={
                    labourStandards?.compensation?.hourlyRates
                  }
                  contractType={contractType}
                  contractTerm={contractTerm}
                  payComponent={compensation?.probationBasePay}
                  frequency={frequency}
                  rateType={rateType}
                  showNested={showNested}
                />
                <JobCodeCallout
                  countryCode={country}
                  contractType={contractType}
                  frequency={frequency}
                  probationBasePayAmount={probationAmount}
                  postProbationBasePayAmount={amount}
                  member={member}
                />
                <SalaryCalculator
                  title={
                    showNested &&
                    t(
                      'compensation.probation-salary-breakdown-main-label',
                      'Probation Salary Breakdown',
                    )
                  }
                  amount={Number(probationAmount ?? 0)}
                  country={country}
                  state={countryStateCode}
                  currency={currency}
                  frequency={frequency}
                  rateType={rateType}
                  contractType={contractType}
                  contractTerm={contractTerm}
                  averageWorkingHoursPerMonth={averageWorkingHoursPerMonth}
                  deductions={methods.watch('deductions')}
                />
              </>
            )}

            {contractType === ContractType.EMPLOYEE &&
              compensation?.postProbationBasePay &&
              isPostProbationFeatureEnabled &&
              (probationPolicyCompliance?.length ?? 0) > 0 &&
              (probationPolicyCompliance?.[0] as ComplianceParamPeriodLimit)
                ?.value !== -1 && (
                <>
                  <CardSeparator />
                  <PostProbationToggleBar
                    showNested={showNested}
                    setShowNested={setShowNested}
                    payComponent={compensation?.postProbationBasePay}
                    contractTerm={contractTerm}
                    frequency={frequency}
                    rateType={rateType}
                  />
                </>
              )}

            {compensation?.postProbationBasePay && showNested && (
              <div>
                <FixedPayInput
                  currencyOptions={currencyOptions}
                  localCurrency={countryLocalCurrency?.country?.mainCurrency}
                  contractType={contractType}
                  contractTerm={contractTerm}
                  fieldPath="basePay"
                  payComponent={compensation?.postProbationBasePay}
                  country={country}
                  countryRateFrequencies={
                    labourStandards?.compensation?.rateFrequency?.list
                  }
                  countryHourlyRates={
                    labourStandards?.compensation?.hourlyRates
                  }
                  hideLabel={showNested}
                  isRateFrequencyInputDisabled={showNested}
                />
                {errors.basePay?.amount?.type === 'min-visa-pay' && (
                  <TextInput.Error>
                    {errors.basePay.amount.message}
                  </TextInput.Error>
                )}
                <PrePostProbationDisclaimer
                  probationSalary={methods.watch(`probationBasePay.amount`)}
                  postProbationSalary={methods.watch(`basePay.amount`)}
                  currency={methods.watch(`basePay.currency`)}
                  frequency={methods.watch(`basePay.frequency`)}
                  probationFrequency={methods.watch(
                    `probationBasePay.frequency`,
                  )}
                  isToggle={showNested}
                />
                <SalaryCalculator
                  title={
                    showNested &&
                    t(
                      'compensation.post-probation-salary-breakdown-main-label',
                      'Post Probation Salary Breakdown',
                    )
                  }
                  amount={Number(amount ?? 0)}
                  country={country}
                  state={countryStateCode}
                  currency={currency}
                  frequency={frequency}
                  rateType={rateType}
                  contractType={contractType}
                  contractTerm={contractTerm}
                  averageWorkingHoursPerMonth={averageWorkingHoursPerMonth}
                  deductions={methods.watch('deductions')}
                  setPostProbationBaseSalary={setPostProbationBaseSalary}
                />
              </div>
            )}

            {contractType === ContractType.HR_MEMBER && alreadyHired && (
              <TextInput.Container>
                <TextInput.Label htmlFor="payroll-start">
                  {t(
                    'definition-phase.compensation.payroll-start.label',
                    'Payroll Start Date',
                  )}
                </TextInput.Label>
                <TextInput.Helper>
                  {t(
                    'definition-phase.compensation.payroll-start.description',
                    'Select a month from which you would like to start calculating payroll for your employee.',
                  )}
                </TextInput.Helper>
                <Controller
                  control={methods.control}
                  name="payrollStart"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      tw="w-1/2"
                      data-testid="payroll-start"
                      id="payroll-start"
                      value={value}
                      onChange={onChange}
                      error={!!methods.formState.errors.payrollStart}
                      helperText={
                        methods.formState.errors.payrollStart?.message
                      }
                      loading={false}
                      placeholder={t(
                        'definition-phase.compensation.payroll-start.placeholder',
                        'Month',
                      )}
                    />
                  )}
                />
              </TextInput.Container>
            )}
          </FormCard>

          {shouldShowPaymentFrequencySelector && (
            <>
              <PayrollFrequencySelector
                paymentFrequency={methods.watch('basePay.paymentFrequency')}
                setPaymentFrequency={setPaymentFrequency}
                baseFrequency={methods.watch('basePay.frequency')}
                country={country}
                countryStateCode={countryStateCode}
              />
              {errors.payrollConfigId && (
                <div tw="mt-extra-small">
                  <TextInput.Error>
                    {errors.payrollConfigId.message}
                  </TextInput.Error>
                </div>
              )}
            </>
          )}

          <PayrollCutoffDateHint
            startDate={startOn}
            contractType={contractType}
            sorOnboardingContext={sorOnboardingContext}
            payrollConfig={payrollConfig}
          />

          <AdditionalPaysTable
            contractType={contractType}
            basePay={convertBasePayToMonthlyPay(
              methods.watch('basePay.frequency') ?? RateFrequency.MONTHLY,
              methods.watch('basePay.amount') ?? 0,
              getAverageWorkingHoursPerMonth(
                isContractOnboardingHourlyPayEnabled,
                labourStandards?.workShift?.averageWorkingHoursPerMonth,
              ),
            )}
            basePayFrequency={methods.watch('basePay.frequency')}
            currency={
              methods.watch('basePay.currency') ??
              compensation?.postProbationBasePay?.currency
            }
            currencyOptions={currencyOptions}
            companyName={company?.displayName}
            religion={
              member?.legalData?.find((d) => d?.key === 'religion')
                ?.value as Religion
            }
            edit
            countryCode={country}
            minDate={
              enableCurrentMonth
                ? new Date(new Date(startOn ?? ''))
                : addMonths(new Date(startOn ?? ''), 1)
            }
            countryStateCode={countryStateCode}
            sorOnboardingContext={sorOnboardingContext}
          />
          <ContributionsSection
            country={country}
            state={countryStateCode}
            contractType={contractType}
            contractTerm={contractTerm}
            currencyOptions={currencyOptions}
            postProbationBaseSalary={postProbationBaseSalary}
          />
          {contractType === ContractType.EMPLOYEE &&
            contractTerm === ContractTerm.PERMANENT && (
              <Esop defaultCurrency={CurrencyCode.USD} />
            )}
          {country && showPricing && <PlatformFee country={country} />}
          <StepNavigationFooter
            disabled={!isValid || isProbationMoreThanPostProbation}
            submitLoading={loading}
            currentStep={currentStep}
            onboardingSteps={onboardingSteps}
            contractId={id}
            stepConfig={stepConfig}
          />
        </FormLayout>
      </FormProvider>
    </StepLayout>
  );
};

export default CompensationViewForm;
