import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const visaApplicationApproved: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-workflow.visa-application-approved.title', {
      defaultValue: 'VISA had been approved',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-workflow.visa-application-approved.description', {
      defaultValue: 'Waiting for member to continue onboarding',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t('onboarding-workflow.visa-application-approved.label', 'Visa Approved'),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t(
      'onboarding-workflow.visa-application-approved.status',
      'Pending on VISA',
    ),
};

export default visaApplicationApproved;
