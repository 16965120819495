import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractOnboardingStepConfig } from '../step-config';

const visaEligibility: ContractOnboardingStepConfig = {
  title: (t): string =>
    t('onboarding-phase.visa_eligibility.title', 'VISA Eligibility'),
  status: (t): string =>
    t('onboarding-phase.visa_eligibility.status', 'Pending on Employee'),
  variant: ProgressWidgetContentVariant.STATUSES,
};

export default visaEligibility;
