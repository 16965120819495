import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Avatar,
  AvatarGroup,
  Button,
  Icon,
  ThemeContext,
  useModalStatic,
} from '@multiplier/common';
import { theme } from 'twin.macro';

import { ConditionInput, Contract } from '../../../__generated__/graphql';
import { OrgDirectoryFilterConfig } from '../../../hooks/get-org-directory';
import { getAvatarDisplay } from '../../../utils/common';
import { EmployeeListDialog } from '../../employee-list';
import RulesList from '../rules-list';

interface Props {
  rules: ConditionInput[];
  rulesConfig: OrgDirectoryFilterConfig[];
  employees: Contract[];
  onClick: () => void;
  disabled: boolean;
}

const AppliedRulesSection: React.FC<Props> = ({
  rules,
  rulesConfig,
  employees,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation('hris.common');
  const openModal = useModalStatic();
  const { isNewThemeApplied } = useContext(ThemeContext);

  const isEdit = Boolean(rules?.length);

  const viewEmployees = () => {
    openModal({
      renderAsStandalone: true,
      render({ onClose }) {
        return (
          <EmployeeListDialog
            show
            onClose={onClose}
            mode="view"
            data={employees}
          />
        );
      },
    });
  };

  return (
    <div tw="flex flex-col">
      <div tw="flex items-center gap-base">
        <Button
          tw="w-max"
          size="small"
          disabled={disabled}
          onClick={onClick}
          variant={isEdit ? 'secondary' : 'primary'}
        >
          <div tw="flex items-center gap-extra-small">
            <Icon
              name="user-plus"
              tw="w-base"
              fill={
                isEdit
                  ? isNewThemeApplied
                    ? undefined
                    : theme`colors.primary`
                  : theme`colors.icon-inverted-primary`
              }
            />
            {isEdit
              ? t('rules-section.edit-employee-btn', 'Edit')
              : t('rules-section.select-employee-btn', 'Select')}
          </div>
        </Button>

        {isEdit && (
          <div tw="flex items-center gap-base">
            <AvatarGroup>
              {employees.map((employee) => (
                <Avatar
                  key={employee.id}
                  displayChars={2}
                  circle
                  size="extraSmall"
                  greyBackground
                  name={getAvatarDisplay(employee.member)}
                />
              ))}
            </AvatarGroup>
            <p
              tw="font-semibold hover:underline cursor-pointer"
              aria-hidden="true"
              onClick={viewEmployees}
            >
              {t('rules-section.filtered-employees-single-plural', {
                defaultValue: '{{ count }} employee',
                defaultValue_plural: '{{ count }} employees',
                count: employees.length,
              })}
            </p>
          </div>
        )}
      </div>
      {isEdit && <RulesList rules={rules} rulesConfig={rulesConfig} />}
    </div>
  );
};

export default AppliedRulesSection;
