/** @jsxImportSource @emotion/react */

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Loader, SpinnerType } from '@multiplier/common';
import { useGetManager } from '@multiplier/hris-member-management';

import {
  useContract,
  useGetCountryLabourStandards,
  useSubmitBasicDetails,
  useSubmitExistingBasicDetails,
} from 'contract-onboarding/company/hooks';
import useGetSupportedJobTitles from 'contract-onboarding/company/hooks/get-supported-job-titles';
import { eligibilityStep, initialStep } from 'contract-onboarding/company/vars';
import { OnboardingStepProps } from 'contract-onboarding/components/step-navigation-footer';

import {
  ContractType,
  CountryCode,
  FetchStage,
  useGetCountryComplianceLimitationsLazyQuery,
  useGetSupportedCountriesLazyQuery,
} from '__generated__/graphql';

import { BasicDetailsFormValues, BasicDetailsView } from './basic-details';

const BasicDetailsContainer: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { id: contractIdFromPath } = useParams<{ id?: string }>();
  const [
    getSupportedCountries,
    { data: supportedCountries, loading: supportedCurrenciesLoading },
  ] = useGetSupportedCountriesLazyQuery();
  const { contractType } = useReactiveVar(initialStep);
  const { legalEntityId, country, countryWorkStatus, state } = useReactiveVar(
    eligibilityStep,
  );

  const {
    loading: companyIntegrationsLoading,
    supportedJobPositions,
  } = useGetSupportedJobTitles();

  const [
    getCountryComplianceLimitations,
    { data: countryCompliance, loading: countryComplianceLimitationsLoading },
  ] = useGetCountryComplianceLimitationsLazyQuery();

  const { contract, loading: contractLoading } = useContract(
    contractIdFromPath,
  );
  const { id: existingContractId } = useParams<{ id?: string }>();

  const { fetchManager, manager } = useGetManager();

  const {
    loading: labourStandardsLoading,
    data: labourStandards,
  } = useGetCountryLabourStandards({
    country: country ?? (contract?.country as CountryCode),
    countryStateCode: state ?? contract?.countryStateCode,
  });

  useEffect(() => {
    if (
      contractType === ContractType.FREELANCER ||
      contract?.type === ContractType.FREELANCER
    ) {
      getSupportedCountries({
        variables: {
          contractType: contract?.type ?? (contractType as ContractType),
        },
      });
    }
  }, [contractType, contract?.type]);

  useEffect(() => {
    if (contractIdFromPath) {
      fetchManager(contractIdFromPath);
    }
  }, [contractIdFromPath]);

  const {
    onSubmit: updateBasicDetails,
    loading: loadingUpdateBasicDetails,
  } = useSubmitBasicDetails();
  const {
    onSubmit: updateExistingBasicDetails,
    loading: loadingUpdateExistingBasicDetails,
  } = useSubmitExistingBasicDetails(existingContractId, contract, manager);

  const saveDetails = (values: BasicDetailsFormValues) => {
    if (existingContractId) updateExistingBasicDetails(values);
    else updateBasicDetails(values);
  };

  const selectedCountry = contract.country ?? country;
  const selectedState = contract.countryStateCode ?? state;
  const selectedWorkStatus = contract?.workStatus ?? countryWorkStatus;
  const selectedContractType = contract?.type ?? contractType;

  useEffect(() => {
    if (
      selectedCountry &&
      selectedContractType &&
      selectedContractType !== ContractType.FREELANCER
    ) {
      getCountryComplianceLimitations({
        variables: {
          country: selectedCountry,
          countryStateCode: selectedState,
          workStatus: selectedWorkStatus,
          contractType: selectedContractType,
          fetchStage: FetchStage.CONTRACT_GENERATION,
        },
      });
    }
  }, [
    selectedCountry,
    selectedState,
    selectedWorkStatus,
    selectedContractType,
  ]);

  if (
    countryComplianceLimitationsLoading ||
    contractLoading ||
    supportedCurrenciesLoading ||
    labourStandardsLoading ||
    companyIntegrationsLoading
  )
    return <Loader.Spinner variant={SpinnerType.CUSTOM_LAYOUT} tw="w-full" />;

  return (
    <BasicDetailsView
      currentStep={currentStep}
      onboardingSteps={onboardingSteps}
      contractType={selectedContractType}
      legalEntityId={legalEntityId}
      country={selectedCountry}
      state={selectedState ?? undefined}
      countryWorkStatus={selectedWorkStatus}
      countryCompliance={countryCompliance}
      contract={contract}
      existingContractId={existingContractId}
      loading={
        loadingUpdateBasicDetails ||
        loadingUpdateExistingBasicDetails ||
        labourStandardsLoading
      }
      loadCountryComplianceLimitations={(variables) =>
        getCountryComplianceLimitations({ variables })
      }
      saveDetails={saveDetails}
      countryLabourStandards={labourStandards?.country?.labourStandards}
      supportedCountries={supportedCountries}
      supportedJobPositions={supportedJobPositions}
      manager={manager}
    />
  );
};

export default BasicDetailsContainer;
