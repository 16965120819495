import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'integrations',
  permission: 'integrations',
  folder: 'integrations',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Integrations',
    route: routes.root,
    sort: 2,
    home: false,
    group: CompanyNavGroups.SETTINGS,
  },
  featureFlag: true,
  featureFlagDefaultValue: false,
};

export default config;
