export const numberAsCurrency = (
  language: string,
  amount?: number | null,
  decimalPlaces = 0,
  minimumDecimalPlaces?: number,
): string =>
  new Intl.NumberFormat(language, {
    style: 'decimal',
    minimumFractionDigits:
      minimumDecimalPlaces || minimumDecimalPlaces === 0
        ? minimumDecimalPlaces
        : decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(amount ?? 0);

export const numberToDecimalPlaces = (num: number, decimalPlaces = 2): number =>
  Math.round((num + Number.EPSILON) * 10 ** decimalPlaces) /
  10 ** decimalPlaces;

export const getNumberWithSuffix = (
  number: string | undefined | null,
): string => {
  if (number) {
    const i = parseInt(number, 10);
    const j = i % 10;
    if (j === 1 && i !== 11) {
      return `${i}st`;
    }
    if (j === 2 && i !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && i !== 13) {
      return `${i}rd`;
    }

    return `${i}th`;
  }
  return ``;
};

export const numberAsOrdinal = (n: number): string => {
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);
  const formatOrdinals = (p: number) => {
    const rule = pr.select(p);
    const suffix = suffixes.get(rule);
    return `${p}${suffix}`;
  };
  return formatOrdinals(n);
};

export const numberPadded = (n: number): string =>
  n < 10 && n !== 0 ? `0${n}` : `${n}`;
