import { ReactElement, useState } from 'react';

import { useModal } from '@multiplier/common';

import { Contract } from '../../__generated__/graphql';
import OnboardingChecklistSidebar from '../components/onboarding-checklist-sidebar';

export default (): {
  Dialog: () => ReactElement;
  showDialog: boolean;
  handleExpandProgress: (contractId: Contract['id']) => void;
  handleDialogClose: () => void;
  contractToShowTasks: Contract['id'] | undefined;
} => {
  const [contractToShowTasks, setContractToShowTasks] = useState<
    Contract['id']
  >();
  const [showDialog, handleDialogClose, handleDialogOpen] = useModal();

  const handleClose = () => {
    setContractToShowTasks(undefined);
    handleDialogClose();
  };

  const handleExpandProgress = (contractId: Contract['id']) => {
    setContractToShowTasks(contractId);
    handleDialogOpen();
  };

  const Dialog = () => (
    <OnboardingChecklistSidebar
      open={showDialog}
      onClose={handleClose}
      contractId={contractToShowTasks}
    />
  );

  return {
    Dialog,
    showDialog,
    handleExpandProgress,
    handleDialogClose: handleClose,
    contractToShowTasks,
  };
};
