import React, { SVGProps, startTransition, useEffect } from 'react';

const useAsyncAssetLoader = ({
  name,
  importFn,
}: {
  name: string;
  importFn: (
    name: string,
  ) => Promise<{
    ReactComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  } | null>;
}): {
  asset:
    | { ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> }
    | undefined;
  loading: boolean;
} => {
  const [asset, setAsset] = React.useState<{
    ReactComponent: React.FC<SVGProps<SVGSVGElement>>;
  }>();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);

    const importAsset = async () => {
      const importedAsset = await importFn(name);

      if (importedAsset) {
        setAsset(importedAsset);
      }

      if (isSubscribed) {
        setLoading(false);
      }
    };

    startTransition(() => {
      // prevent loading indicator
      importAsset();
    });

    return () => {
      isSubscribed = false;
    };
  }, [name]);

  return {
    asset,
    loading,
  };
};

export default useAsyncAssetLoader;
