import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import useSubmitDeductionValue from 'contract-onboarding/company/hooks/submit-deduction-value';
import { getNextStep } from 'contract-onboarding/utils/routing';

import {
  ContractOnboardingStep,
  Maybe,
  useUpdateLegalDetailsMutation,
} from '../../../__generated__/graphql';
import { LegalDetailsFormParams } from '../pages/onboarding/pages/legal-details';
import stepConfig from '../pages/onboarding/step-config';

const useUpdateLegalDetails = (
  contractId?: Maybe<string>,
  memberId?: Maybe<string>,
): {
  loading: boolean;
  onSubmit: (data: LegalDetailsFormParams) => void;
} => {
  const { t } = useTranslation('contract-onboarding.member');
  const navigate = useNavigate();

  const [updateLegalDetails, { loading }] = useUpdateLegalDetailsMutation({
    onCompleted() {
      successNotification(
        '',
        t('legal-details.updated-success-message', 'Legal details updated'),
        false,
      );
    },
    onError() {
      errorNotification(
        '',
        t(
          'legal-details.update-error-message',
          'Failed to update legal details',
        ),
        false,
      );
    },
  });

  const { onSubmit: submitDeductionForm } = useSubmitDeductionValue(contractId);

  const onSubmit = async (values: LegalDetailsFormParams) => {
    if (values?.deductions) submitDeductionForm(values.deductions);

    const member = await updateLegalDetails({
      variables: {
        id: String(memberId),
        data: values.legalData.map((val) => ({
          key: val.key,
          value: val.value,
          identifier: val.description,
        })),
      },
    });

    const onboarding =
      member?.data?.memberUpdateLegalData?.contracts?.[0]?.onboarding;

    const next = getNextStep(
      onboarding,
      ContractOnboardingStep.MEMBER_LEGAL_DETAILS,
    );
    if (next && stepConfig[next]) {
      navigate(stepConfig[next].directLink());
    }
  };

  return { loading, onSubmit };
};

export default useUpdateLegalDetails;
