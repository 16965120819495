/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { ThemeContext } from '@multiplier/common';
import {
  CoverageGroupDescription,
  RecommendInsuranceCoverageGroup,
} from '@multiplier/insurance';
import isNaN from 'lodash/isNaN';
import tw from 'twin.macro';

import i18n from 'i18n';
import InsuranceDetails from 'insurance/components/details';
import ERSplit from 'insurance/components/er-split';
import InsuranceMandatoryCallout from 'insurance/components/insurance-mandatory-callout';
import InsuranceNotes from 'insurance/components/insurance-notes';
import InsuranceTypePartnerName from 'insurance/components/insurance-type-partner-name';

import {
  Benefit,
  BenefitPartnerCountry,
  BenefitType,
  Contract,
  ContractBenefit,
  ContractType,
  CountryCode,
  Maybe,
} from '__generated__/graphql';

import DependentsSection from '../dependents-section';

const InsuranceFormDataV2: React.FC<{
  isFormEditing: boolean;
  benefitPartnerCountryData: BenefitPartnerCountry | undefined;
  setCoverageGroupId: (coverageGroupId: string) => void;
  coverageGroupId?: Maybe<string>;
  contractBenefit: ContractBenefit | null | undefined;
  selectedBenefit: Benefit | null | undefined;
  showDependents: boolean;
  benefits: Maybe<Maybe<ContractBenefit>[]> | undefined;
  country: Maybe<CountryCode> | undefined;
  contractType: Contract['type'];
  coverageGroups?: Maybe<Maybe<RecommendInsuranceCoverageGroup>[]>;
  availableBenefits?: Maybe<Maybe<Benefit>[]>;
}> = ({
  isFormEditing = false,
  benefitPartnerCountryData,
  setCoverageGroupId,
  contractBenefit,
  selectedBenefit,
  showDependents,
  benefits,
  country,
  contractType,
  coverageGroups,
  availableBenefits,
  coverageGroupId,
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const shouldShowERSplit =
    benefitPartnerCountryData?.erSplitApplicable && selectedBenefit?.id;

  const { isNewThemeApplied } = useContext(ThemeContext);

  useEffect(() => {
    setValue(
      'employerPayPercentage',
      String(contractBenefit?.employerPayPercentage ?? '100'),
    );
  }, [contractBenefit?.employeePayPercentage]);

  const showInsurancePartnerAndType =
    benefitPartnerCountryData?.benefitsDomain &&
    benefitPartnerCountryData.partnerName;

  const shouldShowMandatoryInsuranceCallout =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    contractType !== ContractType.CONTRACTOR;

  if (!benefitPartnerCountryData) return null;

  return (
    <>
      {isFormEditing ? (
        <>
          {shouldShowMandatoryInsuranceCallout ? (
            <InsuranceMandatoryCallout country={country} />
          ) : null}
          {showInsurancePartnerAndType ? (
            <div
              css={[
                tw`w-full bg-background-secondary rounded`,
                !isNewThemeApplied && tw`bg-slateGrey50`,
              ]}
            >
              <InsuranceTypePartnerName
                insuranceType={benefitPartnerCountryData?.benefitsDomain}
                insurancePartnerName={benefitPartnerCountryData?.partnerName}
              />
            </div>
          ) : null}
          <Controller
            control={control}
            name="benefitId"
            render={() => (
              <InsuranceDetails
                availableBenefits={availableBenefits}
                variant="tablet"
                country={country}
                selectable
                selectedValue={watch('benefitId')}
                onChange={(selectedValue) => {
                  setValue('benefitId', selectedValue, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                onTypeChange={setCoverageGroupId}
                benefitType={coverageGroupId}
                typesDropdown={
                  coverageGroups?.map?.((item) => ({
                    value: item?.id as string,
                    title: (
                      <div tw="font-semibold flex items-center gap-tiny flex-wrap">
                        <CoverageGroupDescription
                          coverageGroup={item}
                          t={i18n.t}
                        />
                      </div>
                    ),
                    isRecommended: item?.isRecommended,
                  })) ?? []
                }
                benefitsByType={
                  (benefitPartnerCountryData?.benefits as Benefit[]) ?? []
                }
              />
            )}
          />
          {showDependents && (
            <DependentsSection
              benefits={benefits}
              isFormEditing
              cost={selectedBenefit?.cost}
              currency={selectedBenefit?.currency}
              billingDuration={benefitPartnerCountryData?.billingDuration}
              frequency={selectedBenefit?.frequency}
            />
          )}
          <InsuranceNotes
            refundPolicy={benefitPartnerCountryData?.refundPolicy}
            platformFee={benefitPartnerCountryData?.platformFee}
            platformFeeApplicable={
              benefitPartnerCountryData?.platformFeeApplicable
            }
            billingDuration={benefitPartnerCountryData?.billingDuration}
            billingCurrency={benefitPartnerCountryData?.billingCurrency}
            contractType={contractType}
          />
          {shouldShowERSplit && (
            <>
              <div tw="h-[1px] bg-slateGrey200 w-full" />
              <Controller
                control={control}
                name="employerPayPercentage"
                render={({ field: { value: currEmployerPayPercentage } }) => {
                  const processedEmployerPayPercentage =
                    currEmployerPayPercentage &&
                    !isNaN(Number(currEmployerPayPercentage))
                      ? Number(currEmployerPayPercentage)
                      : 0;

                  const employeePercentage = Math.abs(
                    100 - processedEmployerPayPercentage,
                  );

                  return (
                    <ERSplit
                      onChange={(value: string) => {
                        if (value?.length > 2 && Number(value) > 100) return;
                        setValue(
                          'employerPayPercentage',
                          value === '' ? '0' : value,
                          {
                            shouldValidate: true,
                          },
                        );
                      }}
                      type={selectedBenefit?.type as BenefitType}
                      country={country}
                      billingCurrency={selectedBenefit?.currency}
                      frequency={selectedBenefit?.frequency}
                      cost={selectedBenefit?.cost}
                      employeePayPercentage={employeePercentage}
                      employerPayPercentage={processedEmployerPayPercentage}
                      error={errors?.employerPayPercentage?.message as string}
                      minimumErSplitPercentage={
                        benefitPartnerCountryData?.minimumErSplitPercentage
                      }
                      platformFee={benefitPartnerCountryData?.platformFee}
                      platformFeeApplicable={
                        benefitPartnerCountryData?.platformFeeApplicable
                      }
                    />
                  );
                }}
              />
            </>
          )}
        </>
      ) : (
        <>
          {shouldShowMandatoryInsuranceCallout ? (
            <InsuranceMandatoryCallout country={country} />
          ) : null}
          {showInsurancePartnerAndType ? (
            <div
              css={[
                tw`w-full bg-background-secondary rounded`,
                !isNewThemeApplied && tw`bg-slateGrey50`,
              ]}
            >
              <InsuranceTypePartnerName
                insuranceType={benefitPartnerCountryData?.benefitsDomain}
                insurancePartnerName={benefitPartnerCountryData?.partnerName}
              />
            </div>
          ) : null}
          <InsuranceDetails
            availableBenefits={availableBenefits}
            variant="tablet"
            country={country}
            showSelected
            selectedValue={selectedBenefit?.id}
            onTypeChange={(value) => {
              setCoverageGroupId(value);
            }}
            benefitType={coverageGroupId}
            benefitsByType={
              (benefitPartnerCountryData?.benefits as Benefit[]) ?? []
            }
            typesDropdown={
              coverageGroups?.map?.((item) => ({
                value: item?.id as string,
                title: (
                  <div tw="font-semibold flex items-center gap-tiny flex-wrap">
                    <CoverageGroupDescription coverageGroup={item} t={i18n.t} />
                  </div>
                ),
                isRecommended: item?.isRecommended,
              })) ?? []
            }
          />
          {showDependents && (
            <>
              <DependentsSection
                benefits={benefits}
                cost={selectedBenefit?.cost}
                currency={selectedBenefit?.currency}
                billingDuration={benefitPartnerCountryData?.billingDuration}
                frequency={selectedBenefit?.frequency}
              />
              <div tw="h-[1px] bg-slateGrey200 w-full" />
            </>
          )}
          <InsuranceNotes
            refundPolicy={benefitPartnerCountryData?.refundPolicy}
            platformFee={benefitPartnerCountryData?.platformFee}
            platformFeeApplicable={
              benefitPartnerCountryData?.platformFeeApplicable
            }
            billingDuration={benefitPartnerCountryData?.billingDuration}
            billingCurrency={benefitPartnerCountryData?.billingCurrency}
            contractType={contractType}
          />
          {shouldShowERSplit ? (
            <>
              <div tw="h-[1px] bg-slateGrey200 w-full" />
              <ERSplit
                type={selectedBenefit?.type as BenefitType}
                country={country}
                cost={selectedBenefit?.cost}
                billingCurrency={benefitPartnerCountryData?.billingCurrency}
                onChange={(value: string) => {
                  if (value?.length > 2 && Number(value) > 100) return;
                  setValue(
                    'employerPayPercentage',
                    value === '' ? '0' : value,
                    {
                      shouldValidate: true,
                    },
                  );
                }}
                employeePayPercentage={contractBenefit?.employeePayPercentage}
                frequency={selectedBenefit?.frequency}
                employerPayPercentage={contractBenefit?.employerPayPercentage}
                minimumErSplitPercentage={
                  benefitPartnerCountryData?.minimumErSplitPercentage
                }
                platformFee={benefitPartnerCountryData?.platformFee}
                platformFeeApplicable={
                  benefitPartnerCountryData?.platformFeeApplicable
                }
                readOnly
              />{' '}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default InsuranceFormDataV2;
