import { gql } from '@apollo/client';

export default gql`
  mutation UpdateMember($id: ID!, $input: MemberUpdateInput!) {
    memberUpdate(id: $id, memberChange: $input) {
      id
      firstName
      lastName
      gender
    }
  }
`;
