import { gql } from '@apollo/client';

export default gql`
  mutation SendSalaryCalculationEmail(
    $emails: [String]
    $params: SalaryCalculationParam
  ) {
    salaryCalculationEmail(emails: $emails, params: $params) {
      success
      message
    }
  }
`;
