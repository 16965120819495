import { faker } from '@faker-js/faker';

import {
  CompanyPayrollInputEntry,
  ContractPayrollLabel,
  ContractStatus,
  CountryCode,
  CurrencyCode,
  PayFrequency,
  RateFrequency,
} from '__generated__/graphql';

import { mockContract } from './contract';

export const mockCompanyPayrollInputEntry = ({
  country = CountryCode.SGP,
  contract = mockContract({ id: '1' }),
  contractPayrollLabels = [ContractPayrollLabel.INCLUDED],
  memberName = `${faker.name.firstName()} ${faker.name.lastName()}`,
  memberCurrency = CurrencyCode.SGD,
  baseSalary = faker.datatype.number(1000),
  rateFrequency = faker.helpers.arrayElement(Object.values(RateFrequency)),
}: {
  country?: CountryCode;
  contract?: CompanyPayrollInputEntry['contract'];
  contractPayrollLabels?: ContractPayrollLabel[];
  memberName?: CompanyPayrollInputEntry['memberName'];
  memberCurrency?: CompanyPayrollInputEntry['memberCurrency'];
  baseSalary?: CompanyPayrollInputEntry['baseSalary'];
  rateFrequency?: CompanyPayrollInputEntry['rateFrequency'];
}): CompanyPayrollInputEntry => ({
  id: '1',
  contractId: '1',
  contract,
  country,
  memberName,
  workDays: 0,
  timeoff: faker.datatype.number(5),
  memberCurrency,
  companyCurrency: CurrencyCode.SGD,
  memberToCompanyCurrencyExchangeRate: 1,
  baseSalary: baseSalary ?? faker.datatype.number({ min: 10000, max: 100000 }),
  expenses: faker.datatype.number(2000),
  paySuplements: faker.datatype.number(2000),
  allowance: faker.datatype.number(2000),
  contractStatus: ContractStatus.ACTIVE,
  payFrequency: PayFrequency.MONTHLY,
  contractPayrollLabels,
  rateFrequency,
  __typename: 'CompanyPayrollInputEntry',
});
export const globalPayrollDBtoCountryMap: {
  [key: string]: { country: CountryCode; currency: CurrencyCode };
} = {
  '1000000': {
    country: CountryCode.USA,
    currency: CurrencyCode.USD,
  },
  '1000001': {
    country: CountryCode.USA,
    currency: CurrencyCode.USD,
  },
  '1000002': {
    country: CountryCode.USA,
    currency: CurrencyCode.USD,
  },
  '1000003': {
    country: CountryCode.USA,
    currency: CurrencyCode.USD,
  },
  '1000004': {
    country: CountryCode.USA,
    currency: CurrencyCode.USD,
  },
  '1000005': {
    country: CountryCode.GBR,
    currency: CurrencyCode.GBP,
  },
  '1000006': {
    country: CountryCode.ARE,
    currency: CurrencyCode.AED,
  },
  '1000007': {
    country: CountryCode.IND,
    currency: CurrencyCode.INR,
  },
  '1000008': {
    country: CountryCode.LKA,
    currency: CurrencyCode.LKR,
  },
  '1000009': {
    country: CountryCode.SGP,
    currency: CurrencyCode.SGD,
  },
  '1000010': {
    country: CountryCode.CHN,
    currency: CurrencyCode.CNY,
  },
  '1000011': {
    country: CountryCode.SGP,
    currency: CurrencyCode.SGD,
  },
};

export const globalPayrollDBtoPrimaryEntityMap = (
  name: string | null | undefined = 'Space',
): {
  [key: string]: string;
} => ({
  '1000000': `${name} Corporation`,
  '1000001': `${name} Corporation`,
  '1000002': `${name} Corporation`,
  '1000003': `${name} Corporation`,
  '1000004': `${name} Corporation`,
  '1000005': `${name} Industries`,
  '1000006': `${name} Experience Limited`,
  '1000007': `${name} Gulf Services`,
  '1000008': `${name} Display Services`,
  '1000009': `${name} Electronics`,
  '1000010': `${name} Telecom Services`,
  '1000011': `${name} Tech Services`,
});
