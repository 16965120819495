/** @jsxImportSource @emotion/react */
import React from 'react';

// import { useTranslation } from 'react-i18next';

// import { Button } from '@multiplier/common';
import tw from 'twin.macro';

import { CountryCode } from '__generated__/graphql';

// import ContractStatusFilter from './components/contract-status-filter';
// import CountryFilter from './components/country-filter';
// import DateFilter from './components/date-filter';
// import EmploymentFilter from './components/employment-filter';
import SearchBar from './components/search-bar';
// import StatusFilter from './components/status-filter';

export enum Filters {
  COUNTRY_FILTER = 'COUNTRY_FILTER',
  DATE_FILTER = 'DATE_FILTER',
}

export interface TeamViewFiltersProps {
  countryList?: CountryCode[];
  selectedDate?: Record<string, string>;
  employmentList?: string[];
  statusList?: string[];
  contractStatusList?: string[];
  setSelectedCountries?: React.Dispatch<React.SetStateAction<CountryCode[]>>;
  setSelectedDate?: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  setEmploymentList?: React.Dispatch<React.SetStateAction<string[]>>;
  setStatusList?: React.Dispatch<React.SetStateAction<string[]>>;
  setContractStatusList?: React.Dispatch<React.SetStateAction<string[]>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedTab?: string;
}

const MainContainer = tw.div`
    flex flex-row justify-between mobile:flex-wrap ml-16
`;

const TeamViewFilters: React.FC<TeamViewFiltersProps> = ({
  // countryList,
  // selectedDate,
  // employmentList,
  // statusList,
  // contractStatusList,
  // setSelectedCountries,
  // setSelectedDate,
  // setEmploymentList,
  // setStatusList,
  // setContractStatusList,
  setSearchQuery,
  // selectedTab,
}) => (
  // const { t } = useTranslation('team-view');
  // const clearFilters = () => {
  //   setEmploymentList([]);
  //   setStatusList([]);
  //   setSelectedDate({});
  //   setSelectedCountries([]);
  //   setContractStatusList([]);
  // };

  // useEffect(() => {
  //   if (!(selectedTab === 'all' || selectedTab === 'pending'))
  //     setStatusList([]);
  // }, [selectedTab]);

  <MainContainer data-testid="team-filters">
    {/* <div tw="flex flex-row items-center  mobile:flex-wrap ">
        <CountryFilter
          selectedCountries={countryList}
          onChange={(codes) => setSelectedCountries(codes)}
        />
        <DateFilter
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <EmploymentFilter
          selectedEmployment={employmentList}
          setEmploymentList={setEmploymentList}
        />
        {selectedTab === 'pending' && (
          <StatusFilter
            selectedStatus={statusList}
            setStatusFilter={setStatusList}
          />
        )}
        {selectedTab === 'all' && (
          <ContractStatusFilter
            selectedContractStatus={contractStatusList}
            setContractStatusFilter={setContractStatusList}
          />
        )}
        {countryList?.length ||
        Object.keys(selectedDate).length === 2 ||
        employmentList?.length ||
        statusList?.length ||
        contractStatusList?.length ? (
          <Button
            variant="outline"
            css={tw`border-none p-0 text-ps font-normal text-[#011241] h-auto hover:bg-transparent underline`}
            onClick={clearFilters}
          >
            {t('filters-clear.button', 'Clear')}
          </Button>
        ) : (
          ''
        )}
      </div> */}
    <SearchBar setSearchQuery={setSearchQuery} />
  </MainContainer>
);
export default TeamViewFilters;
