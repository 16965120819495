import { useReactiveVar } from '@apollo/client';

import { userVar } from '../vars';

const wildcard = '*';
const recursiveWildcard = wildcard.repeat(2);

const arrayEquals = (array: string[], other: string[]) =>
  array.length === other.length && array.every((v, i) => v === other[i]);

// This only works for module + submodule (2 levels)
const isModuleMatch = (module: string[], other: string[]) =>
  module[0] === recursiveWildcard || // recursive wildcard matches all modules + submodules
  arrayEquals(module, other) || // exact match of modules
  (module[0] === wildcard && other.length === 1) || // match wildcard modules with no submodules
  (module[0] === other[0] &&
    (module[1] === wildcard || module[1] === recursiveWildcard) &&
    other.length === 2);

/**
 * Resolving permission according to the Multiplier Permission Language specification
 * @param input Permission to solve
 * @param permissions List of authorities from the JWT token/account endpoint to resolve against
 */
export const checkPermission = (
  input: string,
  permissions: string[],
): boolean => {
  if (!permissions) return false;

  if (permissions.length === 0) return false;

  return permissions.some((permission) => {
    // Immediately return on exact match
    if (permission === input) return true;

    const [operation, experience, ...branch] = permission.split('.');
    const [otherOperation, otherExperience, ...otherBranch] = input.split('.');

    return (
      (operation === wildcard && experience === recursiveWildcard) ||
      (operation === wildcard &&
        experience === otherExperience &&
        isModuleMatch(branch, otherBranch)) ||
      (operation === otherOperation &&
        experience === otherExperience &&
        isModuleMatch(branch, otherBranch)) ||
      (operation === wildcard &&
        experience === wildcard &&
        isModuleMatch(branch, otherBranch))
    );
  });
};
const usePermission = (...inputs: (string | null | undefined)[]): boolean[] => {
  const { permissions } = useReactiveVar(userVar);

  const result: boolean[] = [];

  inputs.forEach((input) => {
    if (!input) {
      result.push(true);
      return;
    }
    result.push(checkPermission(input, permissions ?? []));
  });

  return result;
};

export const hasSpecificPermission = (
  input: string,
  permissions: string[],
): boolean => permissions.some((permission) => input === permission);

export const useSpecificPermission = (input: string): boolean => {
  const { permissions } = useReactiveVar(userVar);

  return hasSpecificPermission(input, permissions ?? []);
};

export default usePermission;
