import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import {
  ContractOnboarding,
  ContractStatus,
} from '../../../__generated__/graphql';
import { TaskCounts, getTaskCounts } from '../../hooks/processed-tasks';

enum ProgressPillUrgency {
  LOW = 'LOW',
  NEUTRAL = 'NEUTRAL',
  HIGH = 'HIGH',
}

const getUrgency = (progress: number) =>
  progress <= 0.5
    ? ProgressPillUrgency.HIGH
    : progress >= 0.8
    ? ProgressPillUrgency.LOW
    : ProgressPillUrgency.NEUTRAL;

const style = {
  [ProgressPillUrgency.HIGH]: {
    border: tw`border-border-negative-subtle`,
    text: tw`text-text-primary`,
  },
  [ProgressPillUrgency.NEUTRAL]: {
    border: tw`border-border-primary`,
    text: tw`text-text-primary`,
  },
  [ProgressPillUrgency.LOW]: {
    border: tw`border-border-positive-subtle`,
    text: tw`text-text-primary`,
  },
};

const completedTasks: TaskCounts = {
  completed: 8,
  total: 8,
  pending: 0,
};

const OnboardingProgressPill: React.FC<{
  contractStatus?: ContractStatus | null;
  tasks: ContractOnboarding['tasks'];
  handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ tasks, contractStatus, handleClick }) => {
  const { t } = useTranslation('contract-onboarding');
  const isActive = contractStatus === ContractStatus.ACTIVE;
  const taskCounts = getTaskCounts(tasks ?? []);

  const allCompleted = isActive || taskCounts?.pending === 0;

  const { completed, total } = allCompleted ? completedTasks : taskCounts;

  const progress = completed / total;
  const urgency = getUrgency(progress);

  return (
    <button
      type="button"
      css={[
        tw`rounded-full bg-background-white border border-border-negative-subtle px-small py-tiny`,
        style[urgency].border,
      ]}
      onClick={(e) => !allCompleted && handleClick && handleClick(e)}
    >
      <div tw="flex flex-row items-center gap-x-extra-small">
        {!allCompleted && (
          <>
            <p css={[tw`font-semibold text-pxs`, style[urgency].text]}>
              {t('team-view.onboarding-progress-pill', {
                defaultValue: '{{ completed }} / {{ total }} tasks completed',
                replace: {
                  completed,
                  total,
                },
                count: total,
              })}
            </p>
            <Icon name="caret-right" fill={theme('colors.black')} />
          </>
        )}
        {allCompleted && (
          <p css={[tw`font-semibold text-pxs`, style[urgency].text]}>
            {t('team-view.onboarding-progress-pill-complete', {
              defaultValue: 'All tasks completed',
            })}
          </p>
        )}
      </div>
    </button>
  );
};

export default OnboardingProgressPill;
