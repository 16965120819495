import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const orderFormSentToEmployer: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.order-form-sent-to-employer.title', {
      defaultValue: "Waiting for employer's signature",
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.order-form-sent-to-employer.description', {
      defaultValue:
        'Order form is sent to the signatory for their signatures. Once signed, Agreement will be sent to {{ firstName }} for their acceptance and signatures',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.order-form-sent-to-employer.label',
      'Order form sent to Signatory',
    ),
  pendingOn: () => PendingOn.SIGNATORY,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.signature-employer-sent.status',
      'Pending on Signatory',
    ),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
};

export default orderFormSentToEmployer;
