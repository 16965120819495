import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { format, isValid } from 'date-fns';

import { Experience } from 'app/models/module-config';
import { errorNotification } from 'app/services/notification-services';
import { useAddSignatory } from 'contract-onboarding/company/hooks';
import {
  convertBasePayToMonthlyPay,
  mapAdditionalFormValueToCompensationPayComponent,
  notIncludesInCompensationList,
} from 'contract-onboarding/company/services/compensation';
import { AddSignatoryFormValues } from 'contract-onboarding/components/progress-widget-content/add-signatory-modal';
import { PerformanceReviewFormValues } from 'performance-reviews/components/performance-review-form';
import routes from 'performance-reviews/routes';

import {
  CompensationPayComponent,
  Contract,
  ContractType,
  CurrencyCode,
  FixedPayComponent,
  FixedPayComponentInput,
  Maybe,
  PerformanceReview,
  RateFrequency,
  useGetSignatoriesLazyQuery,
  useUpsertPerformanceReviewMutation,
} from '__generated__/graphql';

const sanitizeBasePay = ({
  basePay,
  salary,
  currency,
}: {
  basePay: FixedPayComponent;
  salary: number;
  currency?: Maybe<CurrencyCode>;
}): FixedPayComponentInput => {
  const copyOfBasePay = { ...basePay };
  delete copyOfBasePay.validFromInclusive;
  delete copyOfBasePay.validToExclusive;
  return {
    ...copyOfBasePay,
    amount: salary,
    currency,
    paymentFrequencyDate: basePay?.paymentFrequencyDate?.map(
      (payFrequencyDate) => {
        const copyOfPayFrequencyDate = { ...payFrequencyDate };
        if (payFrequencyDate?.__typename) {
          delete copyOfPayFrequencyDate?.__typename;
        }
        return copyOfPayFrequencyDate;
      },
    ),
  };
};

interface Props {
  id?: string;
  contract?: Maybe<Contract>;
  averageWorkingHoursPerMonth?: number;
  openSignatoryModal?: () => void;
  closeSignatoryModal?: () => void;
  redirect?: boolean;
  redirectUrl?: string;
}

const useUpsertPerformanceReview = ({
  id,
  contract,
  averageWorkingHoursPerMonth,
  openSignatoryModal,
  closeSignatoryModal,
  redirect = true,
  redirectUrl = '',
}: Props): {
  loading: boolean;
  handleAddSignatoryAndSubmit: (
    signatory: AddSignatoryFormValues,
    review: PerformanceReviewFormValues,
  ) => Promise<void>;
  handleSubmit: (
    values: PerformanceReviewFormValues,
    mandatoryAdditionalPays?: Maybe<CompensationPayComponent>[],
  ) => Promise<void>;
} => {
  const navigate = useNavigate();
  const { t } = useTranslation('performance-reviews');

  const isNotFreelancer = contract?.type !== ContractType.FREELANCER;
  const {
    loading: loadingAddSignatory,
    handleAddSignatory,
  } = useAddSignatory();

  const [
    getSignatories,
    { data: signatoriesResponse, loading: loadingSignatories },
  ] = useGetSignatoriesLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    getSignatories();
  }, []);

  const hasSignatories = useMemo(
    () =>
      (signatoriesResponse?.company?.signatories &&
        signatoriesResponse.company.signatories.length > 0) ??
      false,
    [signatoriesResponse],
  );

  const showErrorNotification = () =>
    errorNotification(
      '',
      id
        ? t(
            'update-performance-review.failed-notification',
            'Failed to update compensation update',
          )
        : t(
            'add-performance-review.failed-notification',
            'Failed to add compensation update',
          ),
      true,
    );

  const [
    upsertPerformance,
    { loading: loadingUpsertPerformanceReview },
  ] = useUpsertPerformanceReviewMutation({
    onCompleted: (data) => {
      if (data?.upsertPerformanceReview?.id) {
        if (redirect) {
          navigate(
            redirectUrl ||
              `/${Experience.COMPANY}/${routes.root}/${
                data?.upsertPerformanceReview?.id ?? ''
              }`,
          );
        }
      }
    },
    onError: () => {
      showErrorNotification();
    },
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify({
          id: data?.upsertPerformanceReview?.contract?.company?.id,
          __typename: 'Company',
        }),
        fields: {
          performanceReviews: (
            existingPerformanceReview,
            { readField, toReference },
          ) => {
            if (!data?.upsertPerformanceReview)
              return existingPerformanceReview;
            if (id) {
              return existingPerformanceReview?.map(
                (ref: PerformanceReview) => {
                  if (
                    data?.upsertPerformanceReview &&
                    readField('id', ref) === data?.upsertPerformanceReview?.id
                  ) {
                    return toReference(data.upsertPerformanceReview);
                  }
                  return ref;
                },
              );
            }
            return [
              ...existingPerformanceReview,
              toReference(data?.upsertPerformanceReview),
            ];
          },
        },
      });

      cache.modify({
        id: cache.identify({
          id: contract?.id,
          __typename: 'Contract',
        }),
        fields: {
          performanceReviews: (
            existingPerformanceReview,
            { readField, toReference },
          ) => {
            if (!data?.upsertPerformanceReview)
              return existingPerformanceReview;

            if (id) {
              return existingPerformanceReview?.map(
                (ref: PerformanceReview) => {
                  if (
                    data?.upsertPerformanceReview &&
                    readField('id', ref) === data?.upsertPerformanceReview?.id
                  ) {
                    return toReference(data.upsertPerformanceReview);
                  }
                  return ref;
                },
              );
            }
            return [
              ...existingPerformanceReview,
              toReference(data.upsertPerformanceReview),
            ];
          },
        },
      });
    },
  });

  const onSubmit = async (
    values: PerformanceReviewFormValues,
    mandatoryAdditionalPays?: Maybe<CompensationPayComponent>[],
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...basePay } = contract?.compensation?.basePay || {};
    const effectiveDate =
      values.effectiveMonth && isValid(new Date(values.effectiveMonth))
        ? `${format(new Date(values.effectiveMonth), 'yyyy-MM-dd')}T00:00:00`
        : undefined;

    const revisedSalaryNumber = values.revisedSalary
      ? Number(values.revisedSalary)
      : 0;

    const newMandatoryAdditionalPays = mandatoryAdditionalPays
      ? mandatoryAdditionalPays
          .filter((e) => e !== null)
          .map((additionalPay) => {
            const inputAdditionalPay = {
              name: additionalPay?.name,
              label: additionalPay?.label,
              amount: additionalPay?.amount,
              currency: additionalPay?.currency,
              frequency: additionalPay?.frequency,
            };

            if (
              additionalPay !== null &&
              (additionalPay.name === '13thMonth' ||
                additionalPay.name === 'thr')
            ) {
              inputAdditionalPay.amount = convertBasePayToMonthlyPay(
                contract?.compensation?.basePay?.frequency ??
                  RateFrequency.MONTHLY,
                revisedSalaryNumber,
                averageWorkingHoursPerMonth,
              );
            }
            return inputAdditionalPay;
          })
      : [];

    await upsertPerformance({
      variables: {
        input: {
          id,
          contractId: values.contractId,
          salaryReview: {
            id: values.salaryReviewId,
            contractId: values.contractId,
            effectiveDate,
            revisedCompensation: {
              basePay: sanitizeBasePay({
                basePay,
                salary: revisedSalaryNumber,
                currency: values.currency,
              }),
              other: newMandatoryAdditionalPays,
              otherFixedPayComponents: values?.additionalPays
                ?.filter(
                  notIncludesInCompensationList(newMandatoryAdditionalPays),
                )
                ?.map((payItem) =>
                  mapAdditionalFormValueToCompensationPayComponent({
                    payItem,
                    isNotFreelancer,
                  }),
                ),
            },
          },
          designation: {
            name: values.promotedDesignation,
            jobDescription: '',
          },
          effectiveDate,
        },
      },
    });
  };

  const handleSubmit = async (
    values: PerformanceReviewFormValues,
    mandatoryAdditionalPays?: Maybe<CompensationPayComponent>[],
  ) => {
    const needSignatory =
      !hasSignatories && contract?.type !== ContractType.HR_MEMBER;

    if (needSignatory && openSignatoryModal) {
      openSignatoryModal();
      return;
    }
    await onSubmit(values, mandatoryAdditionalPays);
  };

  const handleAddSignatoryAndSubmit = async (
    signatory: AddSignatoryFormValues,
    review: PerformanceReviewFormValues,
  ) => {
    await handleAddSignatory(signatory);
    await onSubmit(review);
    getSignatories();

    if (closeSignatoryModal) closeSignatoryModal();
  };

  return {
    handleSubmit,
    handleAddSignatoryAndSubmit,
    loading:
      loadingUpsertPerformanceReview ||
      loadingSignatories ||
      loadingAddSignatory,
  };
};

export default useUpsertPerformanceReview;
