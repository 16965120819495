import { useEffect, useState } from 'react';

import { useFeature } from '@growthbook/growthbook-react';
import growthBook from '@multiplier/growthbook';

import localStorageService from 'common/services/local-storage-service';
import importMetaEnv from 'import-meta-env';

// eslint-disable-next-line import/prefer-default-export
export const useGrowthBookLoader = (): {
  noGrowthBook: boolean;
  handleNoGrowthBook: () => void;
} => {
  const [noGrowthBook, setNoGrowthBook] = useState(false);
  const growthBookLoaded = useFeature('growthbook-initialized')?.on;

  useEffect(() => {
    const ignoreGrowthBook =
      importMetaEnv.PROD === true &&
      localStorageService.getItem('ignore-growth-book');
    if (!ignoreGrowthBook && !growthBookLoaded) {
      setNoGrowthBook(true);
    } else {
      setNoGrowthBook(false);
    }
  }, [growthBookLoaded]);

  useEffect(() => {
    growthBook?.setAttributes({
      url: window?.location?.href,
    });
  }, []);

  const handleNoGrowthBook = () => {
    localStorageService.setItem('ignore-growth-book', 'true');
    setNoGrowthBook(false);
  };

  return { noGrowthBook, handleNoGrowthBook };
};
