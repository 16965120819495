import { Manager, useGetManagerLazyQuery } from '__generated__/graphql';

const useGetManager = (): {
  fetchManager: (contractId: string) => void;
  manager: Manager | undefined | null;
  managerLoading: boolean;
} => {
  const [
    getManager,
    { data: managerData, loading: managerLoading },
  ] = useGetManagerLazyQuery();

  const fetchManager = (contractId: string) => {
    if (!contractId) return;
    getManager({
      variables: {
        filters: {
          contractId,
        },
      },
    });
  };

  return {
    fetchManager,
    manager: managerData?.manager,
    managerLoading,
  };
};

export default useGetManager;
