import { useEffect } from 'react';

import { Dictionary } from 'lodash';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';

import {
  Contract,
  ContractOnboarding,
  ContractType,
  CountryWorkStatus,
  LegalBindingType,
  Maybe,
  Member,
  OnboardingTask,
  useGetContractOnboardingTasksLazyQuery,
} from '../../__generated__/graphql';

export interface TaskCounts {
  completed: number;
  pending: number;
  total: number;
}

export const getTaskCounts = (tasks: OnboardingTask[]): TaskCounts => ({
  pending: tasks.filter((task) => !task.completed).length,
  completed: tasks.filter((task) => task.completed).length,
  total: tasks.length,
});

const useProcessTasks = (
  contractId: Contract['id'],
): {
  processedTasks: Dictionary<Dictionary<OnboardingTask[]>>;
  unprocessedTasks: ContractOnboarding['tasks'];
  loading: boolean;
  counts: TaskCounts;
  memberId: Member['id'];
  memberName: string;
  payrollCutoffDate: Contract['incomingPayrollCutoffDate'];
  complianceType?: Maybe<LegalBindingType>;
  workStatus?: Maybe<CountryWorkStatus>;
  contractType?: Maybe<ContractType>;
} => {
  const [
    getContractOnboardingTasks,
    { data, loading },
  ] = useGetContractOnboardingTasksLazyQuery();

  useEffect(() => {
    if (!contractId) return;

    getContractOnboardingTasks({ variables: { contractId } });
  }, [contractId]);

  const tasks = data?.contract?.onboarding?.tasks ?? [];

  return {
    processedTasks: flow(
      groupBy<OnboardingTask>((task) =>
        task.completed ? 'completed' : 'pending',
      ),
      mapValues<OnboardingTask, Dictionary<OnboardingTask[]>>((values) =>
        flow(groupBy<OnboardingTask>('pendingOn.id'))(values),
      ),
    )(tasks),
    unprocessedTasks: tasks,
    loading,
    counts: getTaskCounts(tasks),
    memberId: data?.contract?.member?.id,
    memberName: `${data?.contract?.member?.firstName} ${data?.contract?.member?.lastName}`,
    payrollCutoffDate: data?.contract?.incomingPayrollCutoffDate,
    complianceType: data?.contract?.compliance?.type,
    workStatus: data?.contract?.workStatus,
    contractType: data?.contract?.type,
  };
};

export default useProcessTasks;
