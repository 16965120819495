enum OnboardingAction {
  REVIEW_CONTRACT = 'REVIEW_CONTRACT',
  REVOKE_CONTRACT = 'REVOKE_CONTRACT',
  INVITE = 'INVITE',
  RESEND_INVITE = 'RESEND_INVITE',
  VIEW_DRAFT = 'VIEW_DRAFT',
  CONFIRM = 'CONFIRM',
  CONFIRM_CONTRACT = 'CONFIRM_CONTRACT',
}

export default OnboardingAction;
