/** @jsxImportSource @emotion/react */
import React, { useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';
import { useClickAway } from 'react-use';

import { css } from '@emotion/react';
import { Card, Icon, IconButton } from '@multiplier/common';
import tw from 'twin.macro';

import { useModal } from 'app/hooks';
import Loader from 'common/components/loader';
import TableCell from 'common/components/table-cell';
import TextInput from 'common/components/text-input';
import { contractType } from 'common/constants/default-labels';
import { useGetActiveMember, useGetActiveTeam } from 'team/company/hooks';

import {
  ApprovalCategory,
  CompanyContractsFilter,
  Contract,
  CountryCode,
  Maybe,
  Member,
} from '__generated__/graphql';

interface ContractDropdownProps {
  placeholder: string;
  onChange: (contract: ContractInput | null) => void;
  value?: Contract['id'];
  isEdit: boolean;
  onClear?: () => void;
  category?: ApprovalCategory;
  filterContracts?: (contract: Contract) => boolean;
  shouldIncludeOnboarding?: boolean;
  companyContractsFilter?: CompanyContractsFilter;
}

export interface ContractInput {
  id?: Contract['id'];
  memberId?: Member['id'];
  name?: string;
  country?: Contract['country'];
  position?: string;
  employmentType?: Contract['type'];
  currency?: Contract['currency'];
  term?: Contract['term'];
  countryStateCode?: Contract['countryStateCode'];
  contractStatus?: Contract['status'];
  companyId?: Maybe<string>;
}

const DropdownTable = React.forwardRef<
  HTMLDivElement,
  {
    team: Contract[];
    query: string;
    handleSelect: (member: ContractInput) => void;
    handleClose: () => void;
    category?: ApprovalCategory;
    loading?: boolean;
  }
>(({ team, query, handleSelect, handleClose, loading }, ref) => {
  const { t, ready: tReady } = useTranslation('team', {
    useSuspense: false,
  });

  const columns = useMemo(
    () => [
      {
        Header: t('member-dropdown.columns.country', 'Country'),
        accessor: 'country' as const,
        width: 'auto',
      },
      {
        Header: t('member-dropdown.columns.name', 'Name'),
        accessor: 'name' as const,
        width: 'auto',
      },
      {
        Header: t(
          'member-dropdown.columns.employement-type',
          'Employment Type',
        ),
        accessor: 'employmentType' as const,
        width: 'auto',
      },
    ],
    [],
  );

  const members = useMemo(() => {
    const getMemberName = (contract: Contract) =>
      `${contract.member?.firstName} ${contract.member?.lastName}`;

    return team
      .filter((contract) =>
        getMemberName(contract).toLowerCase().includes(query.toLowerCase()),
      )
      .map((contract) => ({
        country: contract?.country && (
          <TableCell.Country country={String(contract?.country)} />
        ),
        name: (
          <TableCell.Name
            name={getMemberName(contract)}
            position={contract.position ?? ''}
            maxNameLength={30}
            maxPositionLength={40}
          />
        ),
        employmentType: contract?.type && contractType[contract.type],
        memberInput: {
          id: contract.id,
          memberId: contract?.member?.id,
          name: getMemberName(contract),
          country: contract.country as CountryCode,
          position: contract.position ?? '',
          employmentType: contract.type,
          term: contract?.term,
          countryStateCode: contract?.countryStateCode,
          currency: contract.currency,
          contractStatus: contract?.status,
          companyId: contract?.company?.id,
          type: contract.type,
        },
      }));
  }, [query, team]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: members,
      manualSortBy: true,
      disableSortBy: false,
    },
    useSortBy,
  );

  return (
    <Card
      ref={ref}
      css={[
        tw`overflow-hidden max-height[400px] overflow-y-auto rounded-t-none`,
        tw`absolute left-0 right-0 w-auto top-40`,
        tw`z-10 flex flex-row`,
      ]}
    >
      {loading || !tReady ? (
        <Loader.Spinner
          variant={Loader.SpinnerType.CUSTOM_LAYOUT}
          tw="h-[200px]"
        />
      ) : (
        <table tw="flex-grow" {...getTableProps()}>
          {headerGroups.map((headerGroup) => (
            <colgroup {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <col
                  {...column.getHeaderProps()}
                  css={[
                    tw`w-auto`,
                    css`
                      width: ${column.width};
                    `,
                  ]}
                />
              ))}
            </colgroup>
          ))}
          <thead tw="sticky top-0 bg-background-white z-10">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                tw="border-b border-border-primary text-ps"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    tw="py-large px-extra-small first-of-type:pl-large text-left font-normal text-text-tertiary"
                    data-testid={`columnheader-${column.id}`}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted && (
                        <div tw="inline-flex opacity-50 ml-extra-small">
                          {column.isSortedDesc ? (
                            <Icon name="caret-down" />
                          ) : (
                            <Icon name="caret-up" />
                          )}
                        </div>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} tw="text-p text-text-primary">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  data-testid={`member-${row.original.memberInput.id}`}
                  css={[
                    tw`text-left even-of-type:bg-background-primary even-of-type:bg-opacity-20`,
                    tw`hover:(cursor-pointer bg-background-secondary)`,
                  ]}
                  onClick={() => {
                    handleSelect(row.original.memberInput);
                    handleClose();
                  }}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      tw="py-base px-extra-small text-ps text-left whitespace-nowrap first-of-type:pl-large last-of-type:pr-large"
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
});

export const ContractDropdownComponent: React.FC<
  ContractDropdownProps & { team: Contract[]; loading?: boolean }
> = ({
  team,
  placeholder,
  onChange,
  value,
  isEdit,
  onClear,
  category,
  loading,
  ...props
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, closeDropdown, openDropdown] = useModal();
  const [query, setQuery] = useState('');
  const [member, setMember] = useState<ContractInput>({});

  useGetActiveMember(value, setMember, onChange, team);

  useClickAway(dropdownRef, () => {
    closeDropdown();
  });

  return (
    <div tw="relative flex flex-col" {...props}>
      {member.id ? (
        <div tw="rounded-base p-base bg-background-primary flex flex-row items-center">
          <TableCell.Country
            data-testid="country-cell"
            tw="mr-base"
            country={member.country}
          />
          <TableCell.Name
            tw="flex-grow"
            name={member.name}
            position={member.position}
            maxNameLength={40}
            maxPositionLength={60}
          />
          {!isEdit && (
            <IconButton
              data-testid="edit-button"
              tw="bg-background-white"
              size="medium"
              name="cross"
              onClick={() => {
                setMember({});
                setQuery('');
                onChange(null);
                if (onClear) {
                  onClear();
                }
              }}
            />
          )}
        </div>
      ) : (
        <TextInput
          tw="z-20 hover:cursor-pointer"
          type="text"
          disabled={isEdit}
          placeholder={placeholder}
          onClick={openDropdown}
          onChange={(e) => {
            setMember({});
            setQuery(e.target.value);
          }}
        />
      )}
      {showDropdown && (
        <DropdownTable
          team={team}
          ref={dropdownRef}
          query={query}
          handleSelect={(input: ContractInput) => {
            setMember(input);
            onChange(input);
          }}
          handleClose={closeDropdown}
          category={category}
          loading={loading}
        />
      )}
    </div>
  );
};

const ContractDropdown: React.FC<
  React.PropsWithChildren<ContractDropdownProps>
> = ({
  category,
  filterContracts,
  shouldIncludeOnboarding = false,
  companyContractsFilter,
  ...props
}) => {
  const { team, loading } = useGetActiveTeam(
    undefined,
    category,
    filterContracts,
    shouldIncludeOnboarding,
    companyContractsFilter,
  );

  return (
    <ContractDropdownComponent
      category={category}
      team={team}
      loading={loading}
      {...props}
    />
  );
};

export default ContractDropdown;
