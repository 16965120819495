import React, { useContext, useEffect, useRef, useState } from 'react';

import { useClickAway, useKey } from 'react-use';

import tw, { TwStyle } from 'twin.macro';

import { useModal } from '../hooks';
import { ThemeContext } from '../theme-provider';
import DropdownButton from './components/dropdown-button';
import DropdownList from './components/dropdown-list';
import QueryInput from './components/query-input';
import {
  ComboBoxVariants,
  DropdownOnChange,
  DropdownValue,
  DropdownValueType,
  MultiValue,
  SingleValue,
} from './types';

export interface ComboBoxProps<ValueType, IsMulti extends boolean> {
  id?: string;
  variant: ComboBoxVariants;
  showArrow?: boolean;
  value: SingleValue<ValueType> | MultiValue<ValueType>;
  placeholder: string | React.ReactNode;
  dropdownValues: DropdownValue[];
  onChange: (value: DropdownOnChange<ValueType, IsMulti>) => void;
  queryPlaceholder?: string | React.ReactNode;
  prependStyles?: TwStyle;
  dropdownStyles?: TwStyle;
  disabled?: boolean;
  error?: boolean;
  buttonStyles?: TwStyle;
  arrowStyles?: TwStyle;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  labelStyles?: TwStyle;
  clearable?: boolean;
  onClear?: () => void;
  multiple?: IsMulti;
  showTitleOnButton?: boolean;
}

const ComboBox = <
  ValueType extends any = unknown,
  IsMulti extends boolean = false
>({
  id,
  variant,
  value,
  placeholder,
  queryPlaceholder,
  prependStyles,
  dropdownStyles,
  dropdownValues,
  onChange,
  showArrow = true,
  disabled = false,
  error = false,
  buttonStyles,
  arrowStyles,
  startAdornment,
  endAdornment,
  labelStyles,
  clearable = false,
  onClear,
  multiple,
  showTitleOnButton = false,
  ...props
}: ComboBoxProps<ValueType, IsMulti>) => {
  const [queryInput, setQueryInput] = useState('');
  const [values, setValues] = useState(dropdownValues);
  const [selectedValue, setSelectedValue] = useState<DropdownValueType>(null);
  const [showDropdown, handleCloseDropdown, handleOpenDropdown] = useModal(
    false,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);
  const selectedValueRef = useRef<HTMLTableRowElement>(null);
  const selectedOptionRef = useRef<HTMLTableRowElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { isNewThemeApplied } = useContext(ThemeContext);

  useEffect(() => {
    if (!multiple) {
      const targetValueIndex = dropdownValues.findIndex(
        (dropdownValue) => dropdownValue.value === value,
      );
      setSelectedIndex(targetValueIndex);
      setSelectedValue(
        targetValueIndex !== -1 ? dropdownValues[targetValueIndex] : null,
      );
    } else {
      const val = (value as MultiValue<ValueType>)
        ?.map((item) => dropdownValues.find((x) => x.value === item))
        .filter(Boolean);
      setSelectedValue(val as DropdownValue[]);
    }

    setValues(dropdownValues);
  }, [value, dropdownValues, multiple]);

  useEffect(() => {
    if (variant !== 'inline-text' && inputRef && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [showDropdown, selectedIndex, queryInput]);

  useEffect(() => {
    if (selectedOptionRef && selectedOptionRef.current && showDropdown) {
      selectedOptionRef.current.scrollIntoView({
        block: 'center',
        behavior: 'auto',
        inline: 'nearest',
      });
    }
  }, [showDropdown, selectedOptionRef, selectedIndex]);

  useEffect(() => {
    if (selectedValueRef && selectedValueRef.current && showDropdown) {
      selectedValueRef.current.scrollIntoView({
        block: 'center',
        behavior: 'auto',
        inline: 'nearest',
      });
    }
  }, [selectedValueRef, showDropdown]);

  useClickAway(dropdownListRef, handleCloseDropdown);

  const filterValues = (text: string) => {
    const newValues = dropdownValues.filter(
      (dropdownValue) =>
        (dropdownValue?.label &&
          dropdownValue?.label?.toLowerCase().indexOf(text.toLowerCase()) !==
            -1) ||
        dropdownValue?.value?.toLowerCase().indexOf(text.toLowerCase()) !==
          -1 ||
        (dropdownValue?.description &&
          dropdownValue?.description
            ?.toLowerCase()
            .indexOf(text.toLowerCase()) !== -1),
    );
    setValues(newValues);
  };

  const onChangeQueryInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!showDropdown) handleOpenDropdown();
    setQueryInput(e.target.value);
    filterValues(e.target.value);
  };

  const onValueSelected = (dropdownValue: DropdownValueType) => {
    handleCloseDropdown();
    const outputValue = dropdownValue
      ? Array.isArray(dropdownValue)
        ? (dropdownValue.map((x) => x.value) as MultiValue<ValueType>)
        : (dropdownValue.value as SingleValue<ValueType>)
      : null;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange(outputValue ?? value);
    setSelectedValue(dropdownValue);
    setQueryInput('');
    setValues(dropdownValues);

    if (!multiple) {
      setSelectedIndex(
        dropdownValues.findIndex((dv) => dv.value === outputValue),
      );
    }
  };

  useKey((e) => e.key === 'Escape', handleCloseDropdown);
  useKey(
    (e) => /[a-zA-Z1-9]/.test(e.key) && e?.key?.length === 1,
    (e) => {
      if (variant === 'default') {
        const targetValueIndex = dropdownValues.findIndex((dropdownValue) =>
          dropdownValue.value.toLowerCase().startsWith(e.key),
        );
        if (targetValueIndex) setSelectedIndex(targetValueIndex);
      }
    },
  );

  const selectDropdown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' && selectedIndex < dropdownValues.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }

    if (e.key === 'ArrowUp' && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }

    if (
      e.key === 'Enter' &&
      selectedIndex >= 0 &&
      selectedIndex < dropdownValues.length &&
      values[selectedIndex] !== undefined
    ) {
      onValueSelected(values[selectedIndex]);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
  };

  return (
    <div
      css={[
        tw`flex flex-col relative text-ps text-text-primary`,
        !isNewThemeApplied && tw`text-background`,
      ]}
      data-testid="combo-box"
      {...props}
    >
      <div
        css={
          variant === 'inline'
            ? tw`w-full h-full relative overflow-visible`
            : tw`w-full relative`
        }
      >
        {variant === 'inline-text' ? (
          <QueryInput
            ref={inputRef}
            value={queryInput}
            onChange={onChangeQueryInput}
            onKeyDown={selectDropdown}
            variant={variant}
            css={[
              tw`p-6 w-full`,
              tw`placeholder:text-text-tertiary`,
              !isNewThemeApplied && tw`placeholder:text-grey02`,
              tw`focus:(outline-none ring-transparent)`,
            ]}
            onFocus={handleOpenDropdown}
            placeholder={(queryPlaceholder || placeholder) as string}
            prependStyles={prependStyles}
          />
        ) : (
          <DropdownButton
            id={id}
            variant={variant}
            handleClick={handleOpenDropdown}
            selectDropdown={selectDropdown}
            selectedValue={selectedValue}
            placeholder={placeholder}
            showArrow={showArrow}
            showDropdown={showDropdown}
            disabled={disabled}
            error={error}
            buttonStyles={buttonStyles}
            arrowStyles={arrowStyles}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            labelStyles={labelStyles}
            clearable={clearable}
            handleClear={handleClear}
            showTitleOnButton={showTitleOnButton}
          />
        )}
        {showDropdown && (
          <DropdownList
            divRef={dropdownListRef}
            selectedValueRef={selectedValueRef}
            selectedOptionRef={selectedOptionRef}
            placeholder={placeholder as string}
            queryPlaceholder={queryPlaceholder}
            prependStyles={prependStyles}
            dropdownStyles={dropdownStyles}
            inputRef={inputRef}
            input={queryInput}
            onChangeInput={onChangeQueryInput}
            selectDropdown={selectDropdown}
            values={values}
            selectedIndex={selectedIndex}
            onValueSelected={onValueSelected}
            variant={variant}
            selectedValue={selectedValue}
            multiple={multiple}
            onClose={handleCloseDropdown}
          />
        )}
      </div>
    </div>
  );
};

export { default as DropdownList } from './components/dropdown-list';
export { default as QueryInput } from './components/query-input';
export { default as DropdownEntry } from './components/dropdown-entry';
export default ComboBox;
