/** @jsxImportSource @emotion/react */
import React from 'react';

import { CardSeparator } from '@multiplier/common';
import tw from 'twin.macro';

const Sidebar: React.FC<{ loadingFailed: boolean }> = ({ loadingFailed }) => (
  <div
    tw="w-[300px] h-screen max-h-screen overflow-hidden p-32 tablet:hidden mobile:hidden"
    css={[loadingFailed ? undefined : tw`animate-pulse`]}
  >
    <div tw="space-y-56">
      <div tw="flex space-x-6">
        <div tw="rounded-8 bg-grey03 bg-opacity-40 h-64 w-64" />
        <div tw="flex-1 space-y-6 py-2 mt-6 pl-12">
          <div tw="h-12 bg-grey03 bg-opacity-40 rounded-30" />
          <div tw="h-12 bg-grey03 bg-opacity-40 rounded-30" />
        </div>
      </div>
      <div tw="flex flex-col space-y-24">
        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-2/3 bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>
      </div>

      <div tw="flex flex-col space-y-24">
        <div tw="flex space-x-16 ml-16">
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-1/4 bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-1/3 bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-1/4 bg-grey03 bg-opacity-40 rounded-30" />
        </div>
      </div>

      <div tw="flex flex-col space-y-24">
        <div tw="flex space-x-16 ml-16">
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>
      </div>

      <div tw="flex flex-col space-y-24">
        <div tw="flex space-x-16 ml-16">
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>

        <div tw="flex space-x-16 ml-16">
          <div tw="rounded-full bg-grey03 bg-opacity-40 h-12 w-12" />
          <div tw="h-12 w-full bg-grey03 bg-opacity-40 rounded-30" />
        </div>
      </div>
    </div>
  </div>
);

const Header: React.FC<{ loadingFailed: boolean }> = ({ loadingFailed }) => (
  <div
    tw="w-[80%] tablet:hidden mobile:hidden"
    css={[loadingFailed ? undefined : tw`animate-pulse`]}
  >
    <div tw="h-28 w-2/3 bg-grey03 bg-opacity-40 rounded-30" />

    <div tw="mt-32 flex">
      <div tw="rounded-8 bg-grey03 bg-opacity-40 h-64 w-64" />
      <div tw="flex-1 space-y-12 py-2 mt-6 pl-12">
        <div tw="h-16 w-1/3 bg-grey03 bg-opacity-40 rounded-30" />
        <div tw="h-16 w-1/2 bg-grey03 bg-opacity-40 rounded-30" />
      </div>
    </div>
  </div>
);

const LoadingScreen: React.FC<
  React.PropsWithChildren<{ loadingFailed: boolean }>
> = ({ loadingFailed, children }) => (
  <div data-testid="loader" tw="flex bg-bg01 h-screen ">
    <Sidebar loadingFailed={loadingFailed} />
    <div tw="flex-1 mt-64 flex flex-col items-center">
      <Header loadingFailed={loadingFailed} />
      <CardSeparator tw="my-32 w-[80%]" />
      {children}
    </div>
  </div>
);

export default LoadingScreen;
