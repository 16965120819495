import { useEffect } from 'react';

import {
  ContractOnboarding,
  useGetOnboardingHandlerLazyQuery,
} from '../../__generated__/graphql';

const useOnboardingSpecialist = (
  contractId: string,
): {
  handler: ContractOnboarding['handler'];
  name: string | null;
  firstName: string;
} => {
  const [getOnboardingHandler, { data }] = useGetOnboardingHandlerLazyQuery({
    variables: {
      contractId,
    },
  });

  useEffect(() => {
    if (!contractId) {
      return;
    }

    getOnboardingHandler({ variables: { contractId } });
  }, [contractId]);

  const handler = data?.contract?.onboarding?.handler;

  // Handler names are expected to have at least a first name
  const fullName = handler
    ? `${handler?.firstName ?? ''} ${handler?.lastName ?? ''}`
    : null;

  return { handler, name: fullName, firstName: handler?.firstName ?? '' };
};

export default useOnboardingSpecialist;
