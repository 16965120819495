// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notEmpty = <TValue extends any>(
  value: TValue | null | undefined,
): value is TValue => {
  if (value === null || value === undefined) return false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testDummy: TValue = value; // dummy compiler check
  return true;
};

const mapAsync = <T, U>(
  array: T[],
  callbackFn: (value: T, index: number, array: T[]) => Promise<U>,
): Promise<U[]> => Promise.all(array.map(callbackFn));

export const filterAsync = async <T>(
  array: T[],
  callbackFn: (value: T, index: number, array: T[]) => Promise<boolean>,
): Promise<T[]> => {
  const filterMap = await mapAsync(array, callbackFn);
  return array.filter((value, index) => filterMap[index]);
};
