import { useFeature } from '@growthbook/growthbook-react';

import { assureNumber } from 'app/utils/number';
import { ComplianceFormValues } from 'contract-onboarding/components/contract-section';

import {
  ComplianceParamPeriodUnit,
  Contract,
  ContractAgreementType,
  useUpdateComplianceMutation,
  useUpdateLeaveEntitlementMutation,
} from '__generated__/graphql';

const useSubmitCompliance = (
  onCompleted?: () => void,
): {
  loading: boolean;
  onSubmit: (
    values: ComplianceFormValues,
    contractId?: string | null,
    contractAgreementType?: ContractAgreementType,
    skipUpdatingLeaveEntitlement?: boolean,
  ) => Promise<{
    id: Contract['id'];
    onboarding: Contract['onboarding'];
  } | null>;
} => {
  const disableNoticeAfterProbation = useFeature(
    'contract-onboarding-compliance-disable-notice-after-probation',
  ).on;

  // As of May 2022, we cannot make leave entitlement update and compliance
  // at the same time, because it will lead to the contract failed to generate
  // the latest time offs
  const [
    updateLeaveEntitlement,
    { loading: leaveEntitlementUploading },
  ] = useUpdateLeaveEntitlementMutation();

  const [
    updateCompliance,
    { loading: complianceUploading },
  ] = useUpdateComplianceMutation({
    onCompleted: () => {
      if (onCompleted) {
        onCompleted();
      }
    },
  });

  const onSubmit = async (
    values: ComplianceFormValues,
    contractId?: string | null,
    contractAgreementType?: ContractAgreementType,
    skipUpdatingLeaveEntitlement?: boolean,
  ): Promise<{
    id: Contract['id'];
    onboarding: Contract['onboarding'];
  } | null> => {
    if (!contractId) return null;

    if (!skipUpdatingLeaveEntitlement) {
      await updateLeaveEntitlement({
        variables: {
          id: contractId,
          input: [
            ...(values.mandatory?.map(({ key, value, unit, defaultValue }) => ({
              key,
              value: assureNumber(defaultValue + (value ?? 0)),
              unit,
            })) ?? []),
            ...(values.custom?.map(({ key, value, unit }) => ({
              key,
              value: assureNumber(value),
              unit,
            })) ?? []),
          ],
        },
      });
    }

    const contract = await updateCompliance({
      variables: {
        contractId,
        complianceInput: {
          complianceParams: values.complianceParams
            .filter(({ key }) => !!key)
            .map(({ key, enabled, value, unit }) => ({
              key,
              value: enabled
                ? disableNoticeAfterProbation && key === 'noticeAfterProbation'
                  ? 1
                  : value
                : -1,
              unit:
                disableNoticeAfterProbation && key === 'noticeAfterProbation'
                  ? ComplianceParamPeriodUnit.MONTHS
                  : unit,
            })),
          contractAgreementType,
          preferredContractAgreementTemplate:
            values.preferredContractAgreementTemplate !== ''
              ? values.preferredContractAgreementTemplate
              : null,
        },
      },
    });

    return {
      id: contract.data?.contractUpdateCompliance?.id,
      onboarding: contract.data?.contractUpdateCompliance?.onboarding,
    };
  };

  return {
    loading: leaveEntitlementUploading || complianceUploading,
    onSubmit,
  };
};

export default useSubmitCompliance;
