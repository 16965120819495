import { theme } from 'twin.macro';

import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'insurance',
  permission: 'insurance',
  folder: 'insurance',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Insurance',
    route: '/insurance',
    icon: {
      name: 'shield',
      fill: 'transparent',
      stroke: theme`colors.foreground`,
    },
    sort: 4,
    home: false,
    group: NavGroups.MAIN,
    hide: true,
  },
};

export default config;
