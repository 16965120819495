/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import { theme } from 'twin.macro';

import TextInput from 'common/components/text-input';

const SalaryChangesNote: React.FC<{
  salaryDifference: number;
}> = ({ salaryDifference }) => {
  const { t } = useTranslation('performance-reviews');

  const content = useMemo(() => {
    if (salaryDifference > 0) {
      return {
        icon: 'arrow-up-circle',
        iconColor: theme`colors.icon-positive`,
        note: t('form.revised-salary.helper.higher', {
          defaultValue: '{{difference}}% higher than the current compensation.',
          replace: {
            difference: salaryDifference,
          },
        }),
      };
    }

    return {
      icon: 'arrow-down-circle',
      iconColor: theme`colors.icon-negative`,
      note: t('form.revised-salary.helper.lower', {
        defaultValue: '{{difference}}% lower than the current compensation.',
        replace: {
          difference: salaryDifference,
        },
      }),
    };
  }, [salaryDifference]);

  return (
    <TextInput.Helper tw="mt-0 mb-0">
      <div tw="flex flex-row items-center mt-6">
        <Icon
          name={content.icon}
          stroke={content.iconColor}
          fill="transparent"
          tw="mr-6 w-base"
        />
        <span tw="text-text-tertiary">{content.note}</span>
      </div>
    </TextInput.Helper>
  );
};

export default SalaryChangesNote;
