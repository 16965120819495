import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
  WidgetPosition,
  WidgetVariant,
} from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'pay-supplements',
  permission: 'pay-supplements',
  folder: 'pay-supplements',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Pay Supplements',
    route: 'pay-supplements',
    sort: 4,
    home: false,
    group: CompanyNavGroups.ADMINISTRATION,
  },
  widget: {
    sort: 3,
    list: [
      {
        show: true,
        file: 'pay-supplements-widget',
        position: WidgetPosition.LEFT,
        variant: WidgetVariant.HALF_WIDTH,
      },
    ],
  },
};

export default config;
