import { gql } from '@apollo/client';

export default gql`
  query GetCompanyUsersAndContracts {
    company {
      id
      contracts {
        type
        status
      }
      managers {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
        status
      }
      signatories {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
        userId
      }
      billingContact {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
      }
    }
  }
`;
