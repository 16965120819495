import { gql } from '@apollo/client';

export default gql`
  fragment offboarding on ContractOffboarding {
    id
    contractId
    type
    status
  }
`;
