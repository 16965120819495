import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';
import tw, { styled } from 'twin.macro';

import { useModal } from 'app/hooks';

import { Product } from '__generated__/graphql';

import ProductDetailsModal from '../product-details-modal';

export enum ModalSource {
  LAPTOP_CARD = 'LAPTOP_CARD',
}
interface ProductDetailsButtonProps {
  product: Omit<Product, 'sku' | 'countryCode' | 'status'>;
  source?: ModalSource;
}

const MainContainer = tw.div`
  flex justify-end
`;

const OpenModalButton = styled(Button)(({ source }: { source?: string }) => [
  tw`text-ps font-normal text-text-action underline hidden h-auto`,
  source === ModalSource.LAPTOP_CARD &&
    tw`text-text-primary text-pxs font-medium h-none p-none block`,
]);

const ProductDetailsButton: React.FC<ProductDetailsButtonProps> = ({
  product,
  source,
}) => {
  const { t } = useTranslation('equipment.company');
  const [openPopup, handleCloseModal, handleOpenModal] = useModal();

  return (
    <MainContainer>
      <OpenModalButton
        className="show-more-button"
        data-testid="product-info-button"
        variant="inline"
        onClick={handleOpenModal}
        source={source}
      >
        {source === ModalSource.LAPTOP_CARD
          ? t('product-modal.laptop-card.button', 'More details')
          : t('product-modal.view-equipment.button', 'Show more info')}
      </OpenModalButton>
      <ProductDetailsModal
        openModal={openPopup}
        closeModal={handleCloseModal}
        product={product}
      />
    </MainContainer>
  );
};

export default ProductDetailsButton;
