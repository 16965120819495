import React, { MouseEventHandler } from 'react';

import tw, { theme } from 'twin.macro';

import Icon from '../../icon';

const typeStyles = {
  warning: {
    icon: 'bell',
    background: tw`bg-warning`,
    foreground: theme`colors.warning02`,
    text: tw`text-warning02`,
    stroke: undefined,
  },
  error: {
    icon: 'error-reverse',
    background: tw`bg-bgError`,
    foreground: theme`colors.error`,
    text: tw`text-error`,
    stroke: undefined,
  },
  success: {
    icon: 'check-circle',
    background: tw`bg-bgSuccess`,
    foreground: theme`colors.success`,
    text: tw`text-success`,
    stroke: undefined,
  },
  info: {
    icon: 'info',
    background: tw`bg-primary bg-opacity-5`,
    foreground: theme`colors.primary`,
    text: tw`text-warning02`,
    stroke: undefined,
  },
  uploadSuccess: {
    icon: 'upload-cloud',
    background: tw`bg-primary bg-opacity-5`,
    foreground: theme`colors.primaryBlue100`,
    text: tw`text-warning02`,
    stroke: undefined,
  },
  uploadError: {
    icon: 'upload-cloud',
    background: tw`bg-error bg-opacity-5`,
    foreground: theme`colors.red100`,
    text: tw`text-warning02`,
    stroke: undefined,
  },
};

type ToastType =
  | 'warning'
  | 'error'
  | 'success'
  | 'info'
  | 'uploadSuccess'
  | 'uploadError';

interface ToastProps {
  type: ToastType;
  inline?: boolean;
  header: string;
  close?: boolean;
  handleClose?: MouseEventHandler<HTMLButtonElement>;
}

export const ToastBadge: React.FC<{ type: ToastType }> = ({ type }) => (
  <div
    css={[
      typeStyles[type].background,
      tw`flex flex-row flex-shrink-0 justify-center items-center w-48 h-48 rounded-8 mr-16`,
    ]}
  >
    <Icon
      name={typeStyles[type].icon}
      fill={typeStyles[type].foreground}
      stroke={typeStyles[type].stroke}
    />
  </div>
);

// TODO: rebrand -- to be done

const MobileToast: React.FC<React.PropsWithChildren<ToastProps>> = ({
  type,
  inline = false,
  header,
  handleClose,
  close = false,
  children,
  ...props
}) => (
  <div css={[tw`flex flex-row p-base`, typeStyles[type].background]} {...props}>
    <div css={[tw`flex`, inline ? tw`flex-row` : tw`flex-col`]}>
      <div css={[inline && tw`mr-6`, tw`font-bold`, typeStyles[type].text]}>
        {header}
      </div>
      <div
        css={[
          typeStyles[type].text,
          tw`inline-block whitespace-pre-wrap text-left break-words`,
          tw`max-width[240px]`,
        ]}
      >
        {children}
      </div>
    </div>
    <div tw="flex-grow" />
    {close && (
      <div tw="p-base m-auto">
        <button type="button" onClick={handleClose}>
          <Icon name="cross" fill={typeStyles[type].foreground} />
        </button>
      </div>
    )}
  </div>
);

export default MobileToast;
