import isNil from 'lodash/isNil';

import { Contract, useUpdateBenefitsMutation } from '__generated__/graphql';

import { BenefitFormValues } from '../company/pages/definition-phase/pages/benefits/benefit-view-form';

const useSubmitBenefits = (
  contractId?: Contract['id'],
  onCompleted?: () => void,
): {
  onSubmit: (
    values: BenefitFormValues,
  ) => Promise<{
    id: Contract['id'];
    onboarding: Contract['onboarding'];
  } | null>;
  loading: boolean;
} => {
  const [updateBenefits, { loading }] = useUpdateBenefitsMutation({
    onCompleted: () => {
      if (onCompleted) {
        onCompleted();
      }
    },
  });

  const onSubmit = async (values: BenefitFormValues) => {
    if (!contractId) return null;

    const employerPayPercentageInput = isNil(values.employerPayPercentage)
      ? 100
      : Number(values.employerPayPercentage);

    const employeePayPercentageInput = Math.abs(
      100 - employerPayPercentageInput,
    );

    const contract = await updateBenefits({
      variables: {
        id: contractId,
        input: {
          benefitId: values.benefitId,
          dependentCount:
            values.includeDependents && values.dependentCount
              ? parseInt(values.dependentCount, 10)
              : 0,
          params: [
            {
              key: 'includeDependents',
              value: !isNil(values.includeDependents)
                ? String(values.includeDependents)
                : 'false',
            },
          ],
          employerPayPercentage: employerPayPercentageInput,
          employeePayPercentage: employeePayPercentageInput,
        },
      },
    });

    return {
      id: contract.data?.contractUpdateBenefits?.id,
      onboarding: contract.data?.contractUpdateBenefits?.onboarding,
    };
  };
  return { loading, onSubmit };
};

export default useSubmitBenefits;
