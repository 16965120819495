/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClickAway, useDebounce, useKey } from 'react-use';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Icon } from '@multiplier/common';
import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import { theme } from 'twin.macro';
import * as yup from 'yup';

import ConfirmationDialog from 'common/components/confirmation-dialog';
import IconButton from 'common/components/icon-button';
import TextInput from 'common/components/text-input';

import {
  GetCompanyUsersQuery,
  Member,
  useGetCompanyUsersQuery,
} from '__generated__/graphql';

export interface AddSignatoryFormValues {
  firstName: string;
  lastName: string;
  designation: string;
  email: string;
}

interface AddSignatoryModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: AddSignatoryFormValues) => void;
  loading: boolean;
  memberEmail?: NonNullable<NonNullable<Member['emails']>[number]>['email'];
}

export const AddSignatoryModal: React.FC<AddSignatoryModalProps> = ({
  onClose,
  onSubmit,
  loading,
  memberEmail,
}) => {
  const { data, loading: loadingCompanyUsers } = useGetCompanyUsersQuery();
  const [
    showCompanyUserValidationMessage,
    setShowCompanyUserValidationMessage,
  ] = useState(false);

  const companyUserEmails = useMemo(
    () =>
      flow(
        compact,
        map<
          NonNullable<
            NonNullable<GetCompanyUsersQuery['company']>['managers']
          >[number],
          string
        >((manager) => manager?.emails?.[0]?.email ?? ''),
        compact,
      )(data?.company?.managers),
    [data],
  );

  const { t } = useTranslation('contract-onboarding.company');
  const modalRef = useRef(null);
  useClickAway(modalRef, () => {
    onClose();
  });

  useKey('Escape', () => {
    onClose();
  });

  useEffect(() => {
    if (loading) onClose();
  }, [loading]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<AddSignatoryFormValues>({
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string(),
        designation: yup.string().required(),
        email: yup
          .string()
          .email()
          .test(
            'not-member',
            t(
              'add-signatory-modal.email.not-member',
              'Signatory email should not be the same as the onboarding member email',
            ),
            (value) => {
              if (companyUserEmails.includes(value)) {
                return true;
              }

              if (memberEmail) {
                return value !== memberEmail;
              }

              return true;
            },
          )
          .required(),
      }),
    ),
  });

  useDebounce(
    () => {
      if (companyUserEmails.includes(watch('email'))) {
        setShowCompanyUserValidationMessage(true);
      } else {
        setShowCompanyUserValidationMessage(false);
      }
    },
    500,
    [data, watch('email'), watch],
  );

  return (
    <ConfirmationDialog.Lightbox>
      <ConfirmationDialog.Container
        tw="w-[540px]"
        data-testid="modal"
        ref={modalRef}
      >
        <div tw="absolute right-0 top-0">
          <IconButton
            size="medium"
            name="cross"
            fill={theme`colors.grey03`}
            onClick={onClose}
          />
        </div>
        <div tw="pt-56 pb-extra-large px-extra-large flex flex-col items-center gap-y-large">
          <Icon name="signature" tw="w-[76px] h-[70px] mb-large" />
          <h5 tw="text-h4 text-background font-semibold">
            {t('add-signatory-modal.header', 'Add Signatory')}
          </h5>
          <span tw="text-ps text-text-tertiary">
            {t(
              'add-signatory-modal.description',
              'Signatory details will be added to the contract sent to your member.',
            )}
          </span>
          <form
            tw="w-full flex flex-col items-stretch gap-y-large"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div tw="flex flex-row items-center gap-x-base">
              <TextInput.Container tw="flex-grow">
                <TextInput.Label htmlFor="firstName">
                  {t('add-signatory-modal.first-name.label', 'First Name')}
                </TextInput.Label>
                <TextInput
                  id="firstName"
                  placeholder={t(
                    'add-signatory-modal.first-name.placeholder',
                    'John',
                  )}
                  error={!!errors.firstName}
                  {...register('firstName')}
                />
              </TextInput.Container>
              <TextInput.Container tw="flex-grow">
                <TextInput.Label htmlFor="lastName">
                  {t('add-signatory-modal.last-name.label', 'Last Name')}
                </TextInput.Label>
                <TextInput
                  id="lastName"
                  placeholder={t(
                    'add-signatory-modal.last-name.placeholder',
                    'Doe',
                  )}
                  error={!!errors.lastName}
                  {...register('lastName')}
                />
              </TextInput.Container>
            </div>
            <TextInput.Container>
              <TextInput.Label htmlFor="designation">
                {t('add-signatory-modal.designation.label', 'Designation')}
              </TextInput.Label>
              <TextInput
                id="designation"
                placeholder={t(
                  'add-signatory-modal.designation.placeholder',
                  'Ex. Marketing Lead',
                )}
                error={!!errors.designation}
                {...register('designation')}
              />
            </TextInput.Container>
            <TextInput.Container>
              <TextInput.Label htmlFor="email">
                {t('add-signatory-modal.email.label', 'Email Address')}
              </TextInput.Label>
              <TextInput
                id="email"
                placeholder={t(
                  'add-signatory-modal.email.placeholder',
                  'john@example.com',
                )}
                error={!!errors.email}
                {...register('email')}
              />
              {errors.email && (
                <TextInput.Error>{errors.email.message}</TextInput.Error>
              )}
              {showCompanyUserValidationMessage && (
                <TextInput.Error>
                  {t(
                    'add-signatory-modal.email.is-company-user',
                    'Note that this email is already an existing company user',
                  )}
                </TextInput.Error>
              )}
            </TextInput.Container>
            <div tw="text-center">
              <Button
                variant="default"
                size="medium"
                type="submit"
                loading={loading || loadingCompanyUsers}
                disabled={loading || loadingCompanyUsers}
              >
                {t('add-signatory-modal.submit', 'Add Signatory')}
              </Button>
            </div>
          </form>
        </div>
      </ConfirmationDialog.Container>
    </ConfirmationDialog.Lightbox>
  );
};

const AddSignatoryModalRoot: React.FC<
  React.PropsWithChildren<AddSignatoryModalProps>
> = (props) => {
  const { open, children } = props;

  if (open) {
    return ReactDOM.createPortal(
      <AddSignatoryModal {...props}>{children}</AddSignatoryModal>,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }
  return null;
};

export default AddSignatoryModalRoot;
