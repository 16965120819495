import { DropdownValue } from 'common/components/combo-box';
import i18n from 'i18n';

import {
  BenefitType,
  CompanyPayrollCycleStatus,
  ContractOffboardingType,
  ContractStatus,
  ContractType,
  CustomPayrollReportFrequency,
  DayOfWeek,
  DayType,
  Gender,
  MaritalStatus,
  PayFrequency,
  PaySupplementType,
  PayType,
  RateFrequency,
  RateType,
  Relationship,
  VestingFrequency,
} from '__generated__/graphql';

export const rateTypeLabel: { [key: string]: string } = {
  [RateType.CTC]: 'CTC',
  [RateType.NET]: 'Net',
  [RateType.GROSS]: 'Gross',
};

const fillRateType = () => {
  rateTypeLabel[RateType.CTC] = i18n.t('common:rate-type.ctc', 'CTC');
  rateTypeLabel[RateType.GROSS] = i18n.t('common:rate-type.gross', 'Gross');
  rateTypeLabel[RateType.NET] = i18n.t('common:rate-type.net', 'Net');
};

export const rateFrequencyLabelFreelancer: { [key: string]: string } = {
  [RateFrequency.HOURLY]: 'Hour',
  [RateFrequency.DAILY]: 'Day',
  [RateFrequency.WEEKLY]: 'Week',
  [RateFrequency.SEMIMONTHLY]: 'Semi-Month',
  [RateFrequency.MONTHLY]: 'Month',
};

const fillRateFrequencyFreelancer = () => {
  rateFrequencyLabelFreelancer[RateFrequency.HOURLY] = i18n.t(
    'common:rate-frequency.per-hour',
    'Hour',
  );
  rateFrequencyLabelFreelancer[RateFrequency.DAILY] = i18n.t(
    'common:rate-frequency.per-day',
    'Day',
  );
  rateFrequencyLabelFreelancer[RateFrequency.WEEKLY] = i18n.t(
    'common:rate-frequency.per-week',
    'Week',
  );
  rateFrequencyLabelFreelancer[RateFrequency.SEMIMONTHLY] = i18n.t(
    'common:rate-frequency.per-semi-month',
    'Semi-Month',
  );

  rateFrequencyLabelFreelancer[RateFrequency.MONTHLY] = i18n.t(
    'common:rate-frequency.per-month',
    'Month',
  );
};

export const payFrequencyLabel: { [key: string]: string } = {
  [PayFrequency.WEEKLY]: 'Weekly',
  [PayFrequency.SEMIMONTHLY]: 'Semi-Monthly',
  [PayFrequency.BIWEEKLY]: 'Bi-weekly',
  [PayFrequency.MONTHLY]: 'Monthly',
};

const fillPayFrequencyLabel = () => {
  payFrequencyLabel[PayFrequency.WEEKLY] = i18n.t(
    'common:pay-frequency.weekly',
    'Weekly',
  );
  payFrequencyLabel[PayFrequency.SEMIMONTHLY] = i18n.t(
    'common:pay-frequency.semi-monthly',
    'Semi-Monthly',
  );
  payFrequencyLabel[PayFrequency.BIWEEKLY] = i18n.t(
    'common:pay-frequency.bi-weekly',
    'Bi-weekly',
  );
  payFrequencyLabel[PayFrequency.MONTHLY] = i18n.t(
    'common:pay-frequency.monthly',
    'Monthly',
  );
};

export const rateFrequencyLabel: { [key: string]: string } = {
  [RateFrequency.HOURLY]: 'Hourly',
  [RateFrequency.DAILY]: 'Daily',
  [RateFrequency.WEEKLY]: 'Weekly',
  [RateFrequency.SEMIMONTHLY]: 'Semi-Monthly',
  [RateFrequency.MONTHLY]: 'Monthly',
  [RateFrequency.QUATERLY]: 'Quarterly',
  [RateFrequency.HALFYEARLY]: 'Half Yearly',
  [RateFrequency.ANNUALLY]: 'Annually',
};

const fillRateFrequency = () => {
  rateFrequencyLabel[RateFrequency.HOURLY] = i18n.t(
    'common:rate-frequency.hourly',
    'Hourly',
  );
  rateFrequencyLabel[RateFrequency.DAILY] = i18n.t(
    'common:rate-frequency.daily',
    'Daily',
  );
  rateFrequencyLabel[RateFrequency.WEEKLY] = i18n.t(
    'common:rate-frequency.weekly',
    'Weekly',
  );
  rateFrequencyLabel[RateFrequency.MONTHLY] = i18n.t(
    'common:rate-frequency.monthly',
    'Monthly',
  );
  rateFrequencyLabel[RateFrequency.ANNUALLY] = i18n.t(
    'common:rate-frequency.annually',
    'Annually',
  );
  rateFrequencyLabel[RateFrequency.QUATERLY] = i18n.t(
    'common:rate-frequency.quarterly',
    'Quarterly',
  );
  rateFrequencyLabel[RateFrequency.HALFYEARLY] = i18n.t(
    'common:rate-frequency.half-yearly',
    'Half Yearly',
  );
};

export const genderLabel = {
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
  [Gender.UNSPECIFIED]: 'Other',
};

const fillGender = () => {
  genderLabel[Gender.MALE] = i18n.t('common:gender.male', 'Male');
  genderLabel[Gender.FEMALE] = i18n.t('common:gender.female', 'Female');
  genderLabel[Gender.UNSPECIFIED] = i18n.t(
    'common:gender.unspecified',
    'Other',
  );
};

export const relationshipLabel = {
  [Relationship.FATHER]: 'Father',
  [Relationship.MOTHER]: 'Mother',
  [Relationship.SON]: 'Son',
  [Relationship.DAUGHTER]: 'Daughter',
  [Relationship.SPOUSE]: 'Spouse',
};

const fillRelationship = () => {
  relationshipLabel[Relationship.FATHER] = i18n.t(
    'common:relationship.father',
    'Father',
  );
  relationshipLabel[Relationship.MOTHER] = i18n.t(
    'common:relationship.mother',
    'Mother',
  );
  relationshipLabel[Relationship.SON] = i18n.t(
    'common:relationship.son',
    'Son',
  );
  relationshipLabel[Relationship.DAUGHTER] = i18n.t(
    'common:relationship.daughter',
    'Daughter',
  );
  relationshipLabel[Relationship.SPOUSE] = i18n.t(
    'common:relationship.spouse',
    'Spouse',
  );
};

export const maritalStatusLabel = {
  [MaritalStatus.SINGLE]: 'Single',
  [MaritalStatus.MARRIED]: 'Married',
  [MaritalStatus.DIVORCED]: 'Divorced',
  [MaritalStatus.UNSPECIFIED]: 'Unspecified',
};

const fillMaritalStatus = () => {
  maritalStatusLabel[MaritalStatus.SINGLE] = i18n.t(
    'common:marital-status.single',
    'Single',
  );
  maritalStatusLabel[MaritalStatus.MARRIED] = i18n.t(
    'common:marital-status.married',
    'Married',
  );
  maritalStatusLabel[MaritalStatus.DIVORCED] = i18n.t(
    'common:marital-status.divorced',
    'Divorced',
  );
  maritalStatusLabel[MaritalStatus.UNSPECIFIED] = i18n.t(
    'common:marital-status.unspecified',
    'Unspecified',
  );
};

export enum Religion {
  ISLAM = 'Islam',
  PROTESTANTISM = 'Protestantism',
  CATHOLICISM = 'Catholicism',
  BUDDHISM = 'Buddhism',
  CONFUCIANISM = 'Confucianism',
  HINDUISM = 'Hinduism',
}

export const religionLabel = {
  [Religion.ISLAM]: 'Islam',
  [Religion.PROTESTANTISM]: 'Protestantism',
  [Religion.CATHOLICISM]: 'Catholicism',
  [Religion.BUDDHISM]: 'Buddhism',
  [Religion.CONFUCIANISM]: 'Confucianism',
  [Religion.HINDUISM]: 'Hinduism',
};

const fillReligion = () => {
  religionLabel[Religion.ISLAM] = i18n.t(
    'common:indonesia-religion-islam',
    'Islam',
  );
  religionLabel[Religion.HINDUISM] = i18n.t(
    'common:indonesia-religion-hinduism',
    'Hinduism',
  );
  religionLabel[Religion.PROTESTANTISM] = i18n.t(
    'common:indonesia-religion-protestantism',
    'Protestantism',
  );
  religionLabel[Religion.CATHOLICISM] = i18n.t(
    'common:indonesia-religion-catholicism',
    'Catholicism',
  );
  religionLabel[Religion.BUDDHISM] = i18n.t(
    'common:indonesia-religion-buddhism',
    'Buddhism',
  );
  religionLabel[Religion.CONFUCIANISM] = i18n.t(
    'common:indonesia-religion-confucianism',
    'Confucianism',
  );
};

export const contractType = {
  [ContractType.HR_MEMBER]: 'Payroll & Benefits',
  [ContractType.EMPLOYEE]: 'Employee',
  [ContractType.FREELANCER]: 'Freelancer',
  [ContractType.CONTRACTOR]: 'Contractor',
};

const fillContractType = () => {
  contractType[ContractType.HR_MEMBER] = i18n.t(
    'common:employment-type.hr-member',
    'Payroll & Benefits',
  );
  contractType[ContractType.EMPLOYEE] = i18n.t(
    'common:employment-type.employee',
    'Employee',
  );
  contractType[ContractType.FREELANCER] = i18n.t(
    'common:employment-type.freelancer-v2',
    'Contractor',
  );
  contractType[ContractType.CONTRACTOR] = i18n.t(
    'common:employment-type.contractor',
    'AOR-Contractor',
  );
};

export const contractStatus: Record<ContractStatus, string> = {
  [ContractStatus.ACTIVE]: 'Active',
  [ContractStatus.DELETED]: 'Deleted',
  [ContractStatus.ENDED]: 'Ended',
  [ContractStatus.OFFBOARDING]: 'Offboarding',
  [ContractStatus.ONBOARDING]: 'Onboarding',
};

const fillContractStatus = () => {
  contractStatus[ContractStatus.ACTIVE] = i18n.t(
    'common:employment-status.active',
    'Active',
  );
  contractStatus[ContractStatus.DELETED] = i18n.t(
    'common:employment-status.deleted',
    'Deleted',
  );
  contractStatus[ContractStatus.ENDED] = i18n.t(
    'common:employment-status.ended',
    'Ended',
  );
  contractStatus[ContractStatus.OFFBOARDING] = i18n.t(
    'common:employment-status.offboarding',
    'Offboarding',
  );
  contractStatus[ContractStatus.ONBOARDING] = i18n.t(
    'common:employment-status.onboarding',
    'Onboarding',
  );
};

export const payTypeLabel = {
  [PayType.DYNAMIC]: 'Time Based',
  [PayType.FIXED]: 'Milestone Based',
};

const fillPayType = () => {
  payTypeLabel[PayType.DYNAMIC] = i18n.t(
    'common:compensation.pay-type.time-based',
    'Time Based',
  );
  payTypeLabel[PayType.FIXED] = i18n.t(
    'common:compensation.pay-type.milestone-based',
    'Milestone Based',
  );
};

export const vestingFrequency: { [key: string]: string } = {
  [VestingFrequency.YEARLY]: 'Annually',
  [VestingFrequency.SEMI_ANNUALLY]: 'Semi Annually',
  [VestingFrequency.QUARTERLY]: 'Quarterly',
  [VestingFrequency.MONTHLY]: 'Monthly',
  [VestingFrequency.DAILY]: 'Daily',
};

const fillVestingFrequency = () => {
  vestingFrequency[VestingFrequency.YEARLY] = i18n.t(
    'common:compensation.schedule-time-unit.annually',
    'Annually',
  );
  vestingFrequency[VestingFrequency.QUARTERLY] = i18n.t(
    'common:compensation.schedule-time-unit.quarterly',
    'Quarterly',
  );
  vestingFrequency[VestingFrequency.MONTHLY] = i18n.t(
    'common:compensation.schedule-time-unit.monthly',
    'Monthly',
  );
  vestingFrequency[VestingFrequency.DAILY] = i18n.t(
    'common:compensation.schedule-time-unit.daily',
    'Daily',
  );
};

export const weeklyPayoutLabelFreelancer: { [key: string]: string } = {
  [DayOfWeek.MONDAY]: 'Every Monday',
  [DayOfWeek.TUESDAY]: 'Every Tuesday',
  [DayOfWeek.WEDNESDAY]: 'Every Wednesday',
  [DayOfWeek.THURSDAY]: 'Every Thursday',
  [DayOfWeek.FRIDAY]: 'Every Friday',
  [DayOfWeek.SATURDAY]: 'Every Saturday',
  [DayOfWeek.SUNDAY]: 'Every Sunday',
};

const fillWeeklyPayoutLabelsFreelancer = () => {
  weeklyPayoutLabelFreelancer[DayOfWeek.MONDAY] = i18n.t(
    'common:weekly-payout-label.monday',
    'Every Monday',
  );
  weeklyPayoutLabelFreelancer[DayOfWeek.TUESDAY] = i18n.t(
    'common:weekly-payout-label.tuesday',
    'Every Tuesday',
  );
  weeklyPayoutLabelFreelancer[DayOfWeek.WEDNESDAY] = i18n.t(
    'common:weekly-payout-label.wednesday',
    'Every Wednesday',
  );
  weeklyPayoutLabelFreelancer[DayOfWeek.THURSDAY] = i18n.t(
    'common:weekly-payout-label.thursday',
    'Every Thursday',
  );

  weeklyPayoutLabelFreelancer[DayOfWeek.FRIDAY] = i18n.t(
    'common:weekly-payout-label.friday',
    'Every Friday',
  );
  weeklyPayoutLabelFreelancer[DayOfWeek.SATURDAY] = i18n.t(
    'common:weekly-payout-label.saturday',
    'Every Saturday',
  );
  weeklyPayoutLabelFreelancer[DayOfWeek.SUNDAY] = i18n.t(
    'common:weekly-payout-label.sunday',
    'Every Sunday',
  );
};

export const dayTypeLabels = {
  [DayType.REGULAR_DAY]: 'Working Day',
  [DayType.REGULAR_HOLIDAY]: 'Regular Holiday',
  [DayType.REST_DAY]: 'Rest Day',
  [DayType.REST_DAY_REGULAR_HOLIDAY]: 'Rest Day/ Regular Holiday',
  [DayType.REST_DAY_SPECIAL_HOLIDAY]: 'Rest Day/ Special Holiday',
  [DayType.SPECIAL_HOLIDAY]: 'Special Holiday',
  [DayType.NOT_APPLICABLE]: '',
};

const fillDayTypeLabels = () => {
  dayTypeLabels[DayType.REGULAR_DAY] = i18n.t(
    'common:time-sheet.day-type.regular-day',
    'Working Day',
  );
  dayTypeLabels[DayType.REGULAR_HOLIDAY] = i18n.t(
    'common:time-sheet.day-type.regular-holiday',
    'Regular Holiday',
  );
  dayTypeLabels[DayType.REST_DAY] = i18n.t(
    'common:time-sheet.day-type.rest-day',
    'Rest Day',
  );
  dayTypeLabels[DayType.REST_DAY_REGULAR_HOLIDAY] = i18n.t(
    'common:time-sheet.day-type.rest-day-regular-day',
    'Rest Day/ Regular Holiday',
  );
  dayTypeLabels[DayType.REST_DAY_SPECIAL_HOLIDAY] = i18n.t(
    'common:time-sheet.day-type.regular-day',
    'Rest Day/ Special Holiday',
  );
  dayTypeLabels[DayType.SPECIAL_HOLIDAY] = i18n.t(
    'common:time-sheet.day-type.regular-day',
    'Special Holiday',
  );
};

export const paySupplementTypeLabels = {
  [PaySupplementType.COMMISSION]: 'Sales Commission',
  [PaySupplementType.ALLOWANCE]: 'Allowance',
  [PaySupplementType.BONUS]: 'Bonus',
  [PaySupplementType.OTHER]: 'Other',
  [PaySupplementType.VARIABLE_PERFORMANCE_BONUS]: 'Variable Performance Bonus',
};

const fillPaySupplementTypeLabels = () => {
  paySupplementTypeLabels[PaySupplementType.COMMISSION] = i18n.t(
    'common:types.sales-commission',
    'Sales Commission',
  );
  paySupplementTypeLabels[PaySupplementType.ALLOWANCE] = i18n.t(
    'common:types.allowance',
    'Allowance',
  );
  paySupplementTypeLabels[PaySupplementType.BONUS] = i18n.t(
    'common:types.bonus',
    'Bonus',
  );
  paySupplementTypeLabels[PaySupplementType.OTHER] = i18n.t(
    'common:types.other',
    'Other',
  );
  paySupplementTypeLabels[
    PaySupplementType.VARIABLE_PERFORMANCE_BONUS
  ] = i18n.t(
    'common:types.variable-performance-bonus',
    'Variable Performance Bonus',
  );
};

export const benefitTypeLabels = {
  [BenefitType.INSURANCE]: 'Individual',
  [BenefitType.FAMILY_INSURANCE]: 'Family',
};

const fillBenefitTypeLabels = () => {
  benefitTypeLabels[BenefitType.INSURANCE] = i18n.t(
    'insurance:benefit.type.insurance',
    'Individual',
  );
  benefitTypeLabels[BenefitType.FAMILY_INSURANCE] = i18n.t(
    'insurance:benefit.type.family',
    'Family',
  );
};

export const companyPayrollCycleStatusLabels = {
  [CompanyPayrollCycleStatus.CREATED]: 'Created',
  [CompanyPayrollCycleStatus.PROCESSING]: 'Processing',
  [CompanyPayrollCycleStatus.REVIEW_INPUT]: 'Review Input',
  [CompanyPayrollCycleStatus.CONFIRM_REPORT]: 'Confirm Report',
  [CompanyPayrollCycleStatus.REPORT]: 'Report',
  [CompanyPayrollCycleStatus.COMPLETED]: 'Completed',
};

const fillCompanyPayrollCycleStatusLabels = () => {
  companyPayrollCycleStatusLabels[CompanyPayrollCycleStatus.CREATED] = i18n.t(
    'payroll.common:cycle-status.created',
    'Created',
  );
  companyPayrollCycleStatusLabels[
    CompanyPayrollCycleStatus.PROCESSING
  ] = i18n.t('payroll.common:cycle-status.processing', 'Processing');
  companyPayrollCycleStatusLabels[
    CompanyPayrollCycleStatus.REVIEW_INPUT
  ] = i18n.t('payroll.common:cycle-status.review-input', 'Review Input');
  companyPayrollCycleStatusLabels[
    CompanyPayrollCycleStatus.CONFIRM_REPORT
  ] = i18n.t('payroll.common:cycle-status.confirm-report', 'Confirm Report');
  companyPayrollCycleStatusLabels[CompanyPayrollCycleStatus.REPORT] = i18n.t(
    'payroll.common:cycle-status.report',
    'Report',
  );
  companyPayrollCycleStatusLabels[CompanyPayrollCycleStatus.COMPLETED] = i18n.t(
    'payroll.common:cycle-status.completed',
    'Completed',
  );
};

export const weeklyPayoutLabels: DropdownValue[] = [
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
  DayOfWeek.SUNDAY,
].map((frequency) => ({
  title: weeklyPayoutLabelFreelancer[frequency],
  value: frequency,
}));

export const offboardingTypeLabels: Record<ContractOffboardingType, string> = {
  [ContractOffboardingType.RESIGNATION]: 'Resignation',
  [ContractOffboardingType.DID_NOT_JOIN]: 'Did not join',
  [ContractOffboardingType.TERMINATION]: 'Termination',
};

export const customPayrollReportFrequencyLabel = {
  [CustomPayrollReportFrequency.MONTHLY]: 'Monthly',
  [CustomPayrollReportFrequency.QUARTERLY]: 'Quarterly',
  [CustomPayrollReportFrequency.ANNUALLY]: 'Annual',
  [CustomPayrollReportFrequency.YTD]: 'Year-to-Date (YTD)',
};

const fillCustomPayrollReportFrequencyLabel = () => {
  customPayrollReportFrequencyLabel[
    CustomPayrollReportFrequency.MONTHLY
  ] = i18n.t('common:custom-payroll-report-frequency.monthly', 'Monthly');
  customPayrollReportFrequencyLabel[
    CustomPayrollReportFrequency.QUARTERLY
  ] = i18n.t('common:custom-payroll-report-frequency.quarterly', 'Quarterly');
  customPayrollReportFrequencyLabel[
    CustomPayrollReportFrequency.ANNUALLY
  ] = i18n.t('common:custom-payroll-report-frequency.annual', 'Annual');
  customPayrollReportFrequencyLabel[CustomPayrollReportFrequency.YTD] = i18n.t(
    'common:custom-payroll-report-frequency.ytd',
    'Year-to-Date (YTD)',
  );
};

i18n.on('languageChanged init', () => {
  fillRateFrequency();
  fillRateFrequencyFreelancer();
  fillRateType();
  fillGender();
  fillRelationship();
  fillMaritalStatus();
  fillContractType();
  fillContractStatus();
  fillPayType();
  fillReligion();
  fillPayFrequencyLabel();
  fillVestingFrequency();
  fillWeeklyPayoutLabelsFreelancer();
  fillDayTypeLabels();
  fillPaySupplementTypeLabels();
  fillBenefitTypeLabels();
  fillCompanyPayrollCycleStatusLabels();
  fillCustomPayrollReportFrequencyLabel();
});
