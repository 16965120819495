/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import { ThemeContext, WorkFlowProgressNode } from '@multiplier/common';
import { setOnboardingAttributes } from '@multiplier/growthbook';
import tw from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import OnboardingHeader, {
  ContractTypeVariant,
} from 'contract-onboarding/components/onboarding-header';
import SignOutButton from 'contract-onboarding/member/components/sign-out-button';
import {
  isCurrentStep,
  isLastStep,
  isVisited,
  withStepMetadata,
} from 'contract-onboarding/utils/routing';

import {
  ContractOnboardingStep,
  useGetMemberCompanyQuery,
  useGetMemberDetailsForOnboardingQuery,
} from '__generated__/graphql';

import SwitchViewButton from '../../components/switch-view-button';
import routes from '../../routes';
import stepConfig, { generateDirectLink } from './step-config';

// slicing to remove the last `/`
const memberOnboardingUrl = generateDirectLink(
  routes.onboarding.toString(),
).slice(0, -1);

const MemberOnboarding: React.FC = () => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const location = useLocation();
  const {
    data: { member } = { member: {} },
  } = useGetMemberDetailsForOnboardingQuery();
  const {
    data: { member: memberCompany } = { member: {} },
  } = useGetMemberCompanyQuery();

  const contract = useMemo(() => member?.contracts?.[0], [member]);

  const onboardingSteps = useMemo(
    () =>
      contract?.onboarding?.steps
        ?.filter(notEmpty)
        .filter((step) => stepConfig[step] && stepConfig[step].label()) ?? [],
    [contract],
  );

  useEffect(() => {
    if (contract) {
      setOnboardingAttributes({
        country: contract.country,
        contractType: contract.type,
        companyId: memberCompany?.contracts?.[0]?.company?.id,
      });
    }
  }, [contract, memberCompany]);

  return (
    <div tw="flex justify-center min-h-screen h-full">
      <div tw="w-full">
        <div
          css={[
            tw`pt-56 pb-80 bg-background-inverted text-center`,
            !isNewThemeApplied && tw`bg-background`,
          ]}
        >
          <div
            tw="flex flex-col gap-y-extra-large items-stretch width[740px] ml-auto mr-auto"
            data-testid="onboarding-header"
          >
            <OnboardingHeader
              id={contract?.id}
              country={contract?.country}
              firstName={member?.firstName}
              lastName={member?.lastName}
              position={contract?.position}
              contractType={contract?.type}
              contractTypeVariant={ContractTypeVariant.CENTER}
              additionalContent={
                <>
                  <SwitchViewButton />
                  <SignOutButton />
                </>
              }
            />
            <div tw="flex flex-row w-full">
              {onboardingSteps &&
                onboardingSteps.map(
                  (step: ContractOnboardingStep, index) =>
                    stepConfig[step] &&
                    stepConfig[step].label && (
                      <WorkFlowProgressNode
                        key={step}
                        first={index === 0}
                        last={isLastStep(onboardingSteps, index)}
                        title={stepConfig[step].label()}
                        current={isCurrentStep(
                          step,
                          memberOnboardingUrl,
                          location,
                          stepConfig,
                        )}
                        done={isVisited(
                          index,
                          onboardingSteps,
                          contract?.onboarding?.current,
                        )}
                      />
                    ),
                )}
            </div>
          </div>
        </div>
        <div tw="width[740px] pt-large ml-auto mr-auto">
          <Routes>
            {onboardingSteps.map((step: ContractOnboardingStep) => {
              const Element = withStepMetadata(
                stepConfig[step].view,
                step,
                onboardingSteps,
              );
              return (
                stepConfig[step] && (
                  <Route
                    key={step}
                    path={stepConfig[step].route()}
                    element={<Element />}
                  />
                )
              );
            })}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default MemberOnboarding;
