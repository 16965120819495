/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import {
  ComboBox,
  DropdownValue,
  Icon,
  Radio,
  ThemeContext,
} from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import tw, { css, theme } from 'twin.macro';

import { getCoverDetails } from 'insurance/utils/utils';

import { BenefitType, CountryCode, Maybe } from '__generated__/graphql';

const InsuranceSelect: React.FC<{
  country?: Maybe<CountryCode>;
  types: Array<DropdownValue & { isRecommended?: boolean }>;
  checkedType: string;
  onChange?: (type: string) => void;
  selectable?: boolean;
}> = ({ types, onChange, checkedType, selectable = false, country }) => {
  const isInsuranceExpand = useFeature(AppFeature.INSURANCE_EXPAND)?.on;
  const { t } = useTranslation('insurance');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const disabled = !selectable;

  if (isInsuranceExpand)
    return (
      <ComboBox
        variant="default"
        placeholder={t(
          'insurance-type-select.placeholder',
          'Select coverage for employee & their family members',
        )}
        value={checkedType}
        data-testid="insurance-type-select"
        tw="w-full"
        buttonStyles={
          isNewThemeApplied
            ? tw`disabled:(border-none opacity-100 cursor-not-allowed)`
            : tw`disabled:(bg-slateGrey50 border-none opacity-100 cursor-not-allowed)`
        }
        onChange={(type) => {
          if (onChange) onChange(type as BenefitType);
        }}
        disabled={disabled}
        startAdornment={
          <Icon
            name="users"
            stroke={isNewThemeApplied ? undefined : theme`colors.slateGrey400`}
            fill={isNewThemeApplied ? undefined : 'transparent'}
            tw="mr-base"
          />
        }
        dropdownValues={types.map(({ value, title, isRecommended }) => ({
          value,
          title: (
            <div
              tw="flex justify-between items-center w-full"
              data-testid={`select-type-${value?.toLowerCase()}`}
            >
              <p tw="text-text-primary font-semibold text-p mr-extra-small">
                {title}
              </p>
              {isRecommended ? (
                <p tw="py-[1.5px] px-small flex justify-center items-center bg-background-positive-subtle text-ps text-text-positive font-semibold rounded-large">
                  {t('insurance-type-select.recommendation-tag', 'Recommended')}
                </p>
              ) : null}
            </div>
          ),
        }))}
      />
    );
  return (
    <div tw="flex w-full gap-x-base">
      {types.map(({ value, title, isRecommended }) => (
        <Radio
          labelStyles={
            value === checkedType
              ? [
                  tw`w-full rounded-base border-none bg-background-white cursor-pointer`,
                  !isNewThemeApplied &&
                    (css`
                      :has(:checked) {
                        border-color: ${theme`colors.primary`};
                        background-color: ${theme`colors.grey05`};
                      }
                    ` as any),
                ]
              : [
                  tw`w-full rounded-base border border-border-primary cursor-pointer`,
                  !isNewThemeApplied ? tw` border-grey04` : tw``,
                ]
          }
          disabled={disabled}
          css={
            disabled && [
              tw`bg-background-secondary border-border-primary cursor-not-allowed`,
              !isNewThemeApplied && tw`bg-slateGrey100 border-grey03`,
            ]
          }
          value={value}
          key={value}
          name="insurance-type"
          data-testid={`select-type-${value.toLowerCase()}`}
          checked={value === checkedType}
          onChange={() => {
            if (onChange) onChange(value);
          }}
        >
          <div tw="flex justify-between mb-tiny items-center">
            <p tw="text-text-primary font-semibold text-pl mr-extra-small">
              {title}
            </p>
            {isRecommended ? (
              <p tw="py-[1.5px] px-small flex justify-center items-center bg-background-positive-subtle text-ps text-text-positive font-semibold rounded-large">
                {t('insurance-type-select.recommendation-tag', 'Recommended')}
              </p>
            ) : null}
          </div>
          <p tw="text-text-primary text-ps">
            {getCoverDetails({
              t,
              insuranceType: value as BenefitType,
              country,
              maxDependentCount: 0,
            })}
          </p>
        </Radio>
      ))}
    </div>
  );
};

export default InsuranceSelect;
