/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  StepperPosition,
  StepperType,
  ToolTip,
  VerticalProgressNode,
} from '@multiplier/common';
import tw from 'twin.macro';

import useIsGlobalPayrollEnabled from 'performance-reviews/hooks/is-global-payroll-enabled';
import { checkPartnerEorOrHrMember } from 'performance-reviews/services/partner-eor-and-hr-members';
import { Steps, getCurrentStep } from 'performance-reviews/utils';
import timezoneFormat from 'timesheets/services/timezone-format';

import {
  CompanyUser,
  Contract,
  PerformanceReview,
  PerformanceReviewStatus,
  useGetCompanySignatoriesQuery,
} from '__generated__/graphql';

import { getStepsConfig } from './config';

const PerformanceReviewSteps: React.FC<{
  contract: Contract;
  effectiveMonth?: string;
  cutOffDate?: Date;
  performanceReviewStatus?: PerformanceReviewStatus;
  isEdit?: boolean;
  payrollCycle?: PerformanceReview['payrollCycle'];
  effectiveDate?: PerformanceReview['effectiveDate'];
}> = ({
  contract,
  effectiveMonth,
  performanceReviewStatus,
  cutOffDate,
  isEdit = false,
  payrollCycle,
  effectiveDate,
  ...props
}) => {
  const { t } = useTranslation('performance-reviews');

  const { data: signatoryData } = useGetCompanySignatoriesQuery();

  const signatory = signatoryData?.company?.signatories?.[0] as CompanyUser;

  const isParterEorOrHrMember = checkPartnerEorOrHrMember(contract);

  const isGlobalPayrollEnabled = useIsGlobalPayrollEnabled(
    contract?.legalEntityId ?? '',
  );

  const cutOffDateMessage = useMemo(() => {
    if (!cutOffDate && !effectiveMonth) return '';
    return cutOffDate
      ? timezoneFormat(cutOffDate, 'do MMM yy')
      : `${effectiveMonth} cut-off`;
  }, [cutOffDate, effectiveMonth]);

  const steps = useMemo(
    () =>
      getStepsConfig(t, {
        contract,
        signatory,
        cutOffDate: cutOffDateMessage,
        payrollCycle,
        status: performanceReviewStatus,
        effectiveDate,
        isGlobalPayrollEnabled,
      }),
    [
      contract,
      signatory,
      cutOffDateMessage,
      payrollCycle,
      performanceReviewStatus,
      effectiveDate,
      isGlobalPayrollEnabled,
    ],
  );

  const activeStep: Steps = useMemo(
    () =>
      isEdit
        ? Steps.UPDATE_DETAILS
        : getCurrentStep(
            performanceReviewStatus as PerformanceReviewStatus,
            payrollCycle,
            isParterEorOrHrMember,
          ),
    [performanceReviewStatus, isParterEorOrHrMember],
  );

  const activeStepIdx = useMemo(
    () => steps.findIndex((step) => step.key === activeStep),
    [steps, activeStep],
  );

  return (
    <div tw="flex w-full" {...props}>
      {steps.map((step, idx) => {
        const isFirstStep = idx === 0;
        const isLastStep = idx === steps.length - 1;

        const nodeType =
          (idx === activeStepIdx && step.markAsDone) || idx < activeStepIdx
            ? StepperType.DONE
            : StepperType.PROGRESS;
        const nodeColor =
          idx <= activeStepIdx ? StepperType.DONE : StepperType.DEFAULT;
        const lineStyles =
          idx <= activeStepIdx && activeStepIdx > 0
            ? idx < activeStepIdx || isLastStep
              ? tw`bg-background-action`
              : tw`bg-[linear-gradient(90deg, theme('colors.background-action') 0%, theme('colors.background-tertiary') 50%)]`
            : tw`bg-background-tertiary`;

        return (
          <VerticalProgressNode
            key={step.key}
            linePosition={StepperPosition.LEFT}
            childPosition={StepperPosition.BOTTOM}
            lineStyles={lineStyles}
            nodeColor={nodeColor}
            iconType={nodeType}
            css={[
              tw`text-center items-center`,
              !step.description && tw`w-full`,
              isLastStep && tw`text-right items-end`,
              isFirstStep && tw`text-left items-start`,
            ]}
          >
            <div tw="flex flex-col mr-tiny">
              <span tw="text-ps font-semibold text-text-primary">
                {step.title}
              </span>
              <ToolTip
                variant="bottom"
                css={[
                  tw`w-full leading-none`,
                  !isFirstStep && !isLastStep
                    ? steps.length === 4
                      ? tw`px-[20px]`
                      : tw`px-[100px]`
                    : '',
                ]}
                content={
                  step.tooltip ? (
                    <div tw="max-width[300px] [word-wrap: break-word] text-ps">
                      {step.tooltip}
                    </div>
                  ) : null
                }
              >
                <span tw="text-pxs font-medium text-text-tertiary">
                  {step.description}
                </span>
              </ToolTip>
            </div>
          </VerticalProgressNode>
        );
      })}
    </div>
  );
};

export default PerformanceReviewSteps;
