import React, { PropsWithChildren, useContext } from 'react';

import tw, { TwStyle } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';

export type AvatarSize = 'large' | 'small' | 'extraSmall' | 'atom';

interface BaseAvatarProps {
  greyBackground?: boolean;
  backgroundColor?: string;
  circle?: boolean;
  size?: AvatarSize;
  noShadow?: boolean;
}

interface LetterAvatarProps extends BaseAvatarProps {
  variant?: 'letter';
  name: string;
  nameStyles?: TwStyle;
  displayChars?: number;
}

interface ImageAvatarProps extends BaseAvatarProps {
  variant: 'image';
  imageUrl?: string;
  alt: string;
  fallbackIcon?: string;
  imageStyles?: TwStyle;
}

type AvatarProps = LetterAvatarProps | ImageAvatarProps;

const avatarSizes: Record<AvatarSize, number> = {
  large: 110,
  small: 60,
  extraSmall: 40,
  atom: 24,
};

const avatarTwSize: Record<AvatarSize, TwStyle> = {
  large: tw`width[110px] height[110px]`,
  small: tw`width[60px] height[60px]`,
  extraSmall: tw`width[40px] height[40px]`,
  atom: tw`width[24px] height[24px]`,
};

const avatarGroupBaseOffset: Record<AvatarSize, number> = {
  large: 55,
  small: 30,
  extraSmall: 20,
  atom: 12,
};

const AvatarWrapper: React.FC<
  React.PropsWithChildren<{
    size?: AvatarSize;
    circle?: boolean;
    greyBackground?: boolean;
    backgroundColor?: string;
    noShadow?: boolean;
  }>
> = ({
  size,
  circle,
  greyBackground,
  noShadow,
  backgroundColor,
  children,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div
      css={[
        backgroundColor && `background-color: ${backgroundColor}`,
        size && Object.keys(avatarTwSize).includes(size)
          ? avatarTwSize[size]
          : avatarTwSize.small,
        // there should be no shadow for this component for the new theme
        !noShadow && !isNewThemeApplied && tw`shadow-high`,
        tw`flex flex-row items-center justify-center border border-border-primary`,
        circle ? tw`rounded-full` : tw`rounded-base`,
        greyBackground &&
          tw`bg-background-secondary shadow-none border-2 border-border-primary`,
        !isNewThemeApplied && greyBackground && tw`border-slateGrey300`,
      ]}
      {...props}
    >
      {children}
    </div>
  );
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    variant,
    greyBackground,
    circle,
    size,
    noShadow,
    backgroundColor,
  } = props;

  if (variant === 'letter' || !variant) {
    const {
      name,
      displayChars = 1,
      nameStyles,
      ...otherProps
    } = props as PropsWithChildren<LetterAvatarProps>;

    return (
      <AvatarWrapper
        circle={circle}
        greyBackground={greyBackground}
        size={size}
        backgroundColor={backgroundColor}
        noShadow={noShadow}
        {...otherProps}
      >
        <span
          data-testid="text-icon"
          css={[
            tw`text-text-primary text-h6 font-semibold select-none`,
            greyBackground && tw`text-text-primary`,
            size === 'extraSmall' && tw`text-ps`,
            size === 'atom' && tw`text-pxs`,
            nameStyles,
          ]}
        >
          {name.slice(0, displayChars)?.toUpperCase()}
        </span>
      </AvatarWrapper>
    );
  }

  if (variant === 'image') {
    const {
      imageUrl,
      fallbackIcon,
      alt,
      imageStyles,
      ...otherProps
    } = props as ImageAvatarProps;

    return (
      <AvatarWrapper
        circle={circle}
        greyBackground={greyBackground}
        size={size}
        noShadow={noShadow}
        backgroundColor={backgroundColor}
        {...otherProps}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            data-testid="avatar-img"
            alt={alt}
            css={[
              circle && tw`rounded-full`,
              tw`w-full h-full object-fill`,
              imageStyles,
            ]}
          />
        ) : (
          <Icon
            name={fallbackIcon || 'avatar'}
            data-testid="avatar-icon"
            tw="w-1/2 h-1/2"
          />
        )}
      </AvatarWrapper>
    );
  }
  return null;
};

export const AvatarGroup: React.FC<{
  maxItems?: number;
  children: React.ReactNode;
}> = ({ maxItems = 4, children, ...props }) => {
  const childArray = React.Children.toArray(
    children,
  ) as React.ReactElement<AvatarProps>[];
  const displayAvatars = childArray.slice(0, maxItems);

  const width = displayAvatars.reduce((acc, item) => {
    acc +=
      avatarSizes[item.props.size || 'small'] /
      (displayAvatars.length > 1 ? 1.5 : 1);
    return acc;
  }, 0);

  return (
    <div
      css={[tw`flex items-center relative`, { width: `${width}px` }]}
      {...props}
    >
      {displayAvatars.map((avatar, idx) => {
        const offsetLeft =
          avatarGroupBaseOffset[(avatar.props.size as AvatarSize) ?? 'small'] *
          idx;
        return React.cloneElement(avatar, {
          ...avatar.props,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css: [tw`absolute`, idx > 0 && { left: `${offsetLeft}px` }],
        });
      })}
    </div>
  );
};

export default Avatar;
