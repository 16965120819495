import React from 'react';

import { DropdownValue } from '@multiplier/common';

import { Contract, DeductionDefinition } from '__generated__/graphql';

import EmployerMatchLimit from '../requirements/employer-match-limit';
import EmployerProvidentFund from '../requirements/employer-provident-fund';
import { DeductionConstraintFromQuery, DeductionLabel } from '../types';

type EmployerDeductionInputComponent = React.FC<{
  deductionDefinitionId: DeductionDefinition['id'];
  groupKey: DeductionDefinition['groupKey'];
  constraint?: DeductionConstraintFromQuery;
  currencyOptions: DropdownValue[];
  countryCode?: Contract['country'];
  postProbationBaseSalary?: number;
}>;

const EmployerDeductionInput: EmployerDeductionInputComponent = ({
  deductionDefinitionId,
  groupKey,
  constraint,
  currencyOptions,
  countryCode,
  postProbationBaseSalary,
}) => {
  if (!deductionDefinitionId || !constraint?.name) {
    return null;
  }

  switch (constraint.name) {
    case DeductionLabel.EMPLOYER_MATCH_LIMIT:
      return (
        <EmployerMatchLimit
          deductionDefinitionId={deductionDefinitionId}
          groupKey={groupKey}
          constraint={constraint}
        />
      );
    case DeductionLabel.EMPLOYER_PROVIDENT_FUND:
      return (
        <EmployerProvidentFund
          deductionDefinitionId={deductionDefinitionId}
          groupKey={groupKey}
          constraint={constraint}
          currencyOptions={currencyOptions}
          countryCode={countryCode}
          postProbationBaseSalary={postProbationBaseSalary}
        />
      );
    default:
      return null;
  }
};

export default EmployerDeductionInput;
