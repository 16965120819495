import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const contractPreparing: ContractOnboardingStatusConfig = {
  title: (t) =>
    t('onboarding-phase.contract-preparing.title', 'Preparing contract'),
  description: (t, firstName, gender) =>
    t('onboarding-phase.contract-preparing.description', {
      defaultValue:
        'You will receive an email from Multiplier when the contract is ready for reviewing and signing.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) => t('onboarding-phase.contract-preparing.label', ''),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t('onboarding-phase.contract-preparing.status', 'Pending on Multiplier'),
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default contractPreparing;
