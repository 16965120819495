/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import 'twin.macro';

import { Trans, useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import { Button } from '@multiplier/common';

import { useModal } from 'app/hooks';
import { Experience } from 'app/models/module-config';
import { errorNotification } from 'app/services/notification-services';
import { dateAsString } from 'app/utils/format';
import { userVar } from 'app/vars';

import {
  Contract,
  ContractType,
  ExternalSystemType,
  Member,
  useGetContractDepositExternalLinkLazyQuery,
  useGetContractDepositWithPdfLazyQuery,
} from '__generated__/graphql';

import {
  getUnpaidInvoice,
  hasUnpaidInvoices,
} from '../../services/contract-deposit-invoice';
import ContractModal from '../contract-modal';

const ContractDepositInformation: React.FC<{
  contractId: Contract['id'];
  contractType: Contract['type'];
  firstName?: Member['firstName'];
  lastName?: Member['lastName'];
  depositPayables?: Contract['depositPayable'];
}> = ({ contractId, contractType, depositPayables, firstName, lastName }) => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const { t } = useTranslation('contract-onboarding.company');

  const invoiceExternalSystem = depositPayables?.[0]?.invoice
    ?.externalSystem as ExternalSystemType;

  const [
    showInvoicePreview,
    handleCloseInvoicePreview,
    handleOpenInvoicePreview,
  ] = useModal();

  const shouldShowDepositInformation = useMemo(
    () => hasUnpaidInvoices(depositPayables),
    [depositPayables],
  );

  const [
    getContractDepositPdf,
    { data, loading: loadingContractDepositPdf },
  ] = useGetContractDepositWithPdfLazyQuery({ fetchPolicy: 'network-only' });

  const [
    getContractDepositExternalLink,
    { loading: loadingContractDepositExternalLink },
  ] = useGetContractDepositExternalLinkLazyQuery({
    fetchPolicy: 'network-only',
  });

  const handleViewInvoiceClick = async () => {
    if (!contractId || !invoiceExternalSystem) return;

    if (invoiceExternalSystem === ExternalSystemType.NETSUITE) {
      handleOpenInvoicePreview();
      getContractDepositPdf({ variables: { id: contractId } });
    } else {
      getContractDepositExternalLink({
        variables: { id: contractId },
        onCompleted(resp) {
          const link =
            resp?.contract?.depositPayable?.[0]?.invoice?.externalLink;
          if (link) {
            window.open(link, '_blank', 'noopener,noreferrer');
          } else {
            errorNotification(
              '',
              t('open-link.unavailable', 'Invoice link is not available'),
              false,
            );
          }
        },
      });
    }
  };

  const unpaidInvoice = useMemo(
    () => getUnpaidInvoice(data?.contract?.depositPayable || depositPayables),
    [data],
  );

  const loading =
    loadingContractDepositPdf || loadingContractDepositExternalLink;

  if (current === Experience.MEMBER) {
    return null;
  }

  if (!shouldShowDepositInformation) {
    return null;
  }

  const parsedDueDate = dateAsString(unpaidInvoice?.invoice?.dueDate);

  return (
    <div tw="flex flex-row items-center justify-between bg-background-warning-subtle py-base px-extra-large text-text-primary">
      <div tw="flex flex-col gap-y-extra-small">
        <p tw="font-semibold">
          {contractType === ContractType.CONTRACTOR
            ? t(
                'contract-deposit-information.header-contractor',
                'A deposit is required for Contractor Onboarding',
              )
            : t(
                'contract-deposit-information.header',
                'Deposit Required for Employee Onboarding',
              )}
        </p>
        <div tw="text-ps text-text-secondary whitespace-pre-line">
          {contractType === ContractType.CONTRACTOR ? (
            <Trans
              t={t}
              i18nKey="contract-deposit-information.description-contractor"
            >
              The deposit invoice for{' '}
              <p tw="inline font-medium text-text-primary">
                {{ firstName }} {{ lastName }}
              </p>{' '}
              is mailed to you.{'\n'}Please clear the invoice by{' '}
              <p tw="inline font-medium text-text-primary">
                {{ parsedStartOn: parsedDueDate }}
              </p>
              .{'\n'}
              Multiplier will sign the order form and the contractor agreement
              as soon as the deposit is received.
            </Trans>
          ) : (
            <Trans t={t} i18nKey="contract-deposit-information.description">
              The deposit invoice for{' '}
              <p tw="inline font-medium text-text-primary">
                {{ firstName }} {{ lastName }}
              </p>{' '}
              is mailed to you.{'\n'}Please clear the invoice by{' '}
              <p tw="inline font-medium text-text-primary">
                {{ parsedStartOn: parsedDueDate }}
              </p>
              .
            </Trans>
          )}
        </div>
      </div>
      <Button
        variant="content"
        onClick={handleViewInvoiceClick}
        loading={loading}
        tw="flex-shrink-0 underline [text-underline-offset: 6px] text-text-primary text-ps font-semibold"
      >
        {t('contract-deposit-information.view-invoice', 'View Invoice')}
      </Button>
      <ContractModal
        open={showInvoicePreview}
        onClose={handleCloseInvoicePreview}
        title={t(
          'contract-deposit-information.invoice-preview-header',
          'Deposit Invoice Preview',
        )}
        contractType={null}
        orderForm={null}
        contractAgreement={unpaidInvoice?.invoice?.document}
        loading={loading}
      />
    </div>
  );
};

export default ContractDepositInformation;
