import { DropdownValue } from 'common/components/combo-box';
import { weeklyPayoutLabels } from 'common/constants/default-labels';
import i18n from 'i18n';

import { PayFrequency, RateFrequency } from '__generated__/graphql';

import { getPayoutDateLabelValues } from './helpers';

const monthlyPayoutLabels = getPayoutDateLabelValues(1, 31);
const semiMonthlyPayoutLabels = getPayoutDateLabelValues(1, 16);

export const frequencyOptionsForBillingDurationsMap: {
  [key: string]: PayFrequency[];
} = {
  [RateFrequency.HOURLY]: [PayFrequency.SEMIMONTHLY, PayFrequency.MONTHLY],
  [RateFrequency.DAILY]: [PayFrequency.SEMIMONTHLY, PayFrequency.MONTHLY],
  [RateFrequency.WEEKLY]: [PayFrequency.WEEKLY],
  [RateFrequency.SEMIMONTHLY]: [PayFrequency.SEMIMONTHLY, PayFrequency.MONTHLY],
  [RateFrequency.MONTHLY]: [PayFrequency.SEMIMONTHLY, PayFrequency.MONTHLY],
};

export const firstPayoutDateOptionsForPayoutFrequencyMap: {
  [key: string]: DropdownValue[];
} = {
  [PayFrequency.WEEKLY]: weeklyPayoutLabels,
  [PayFrequency.SEMIMONTHLY]: semiMonthlyPayoutLabels,
  [PayFrequency.MONTHLY]: monthlyPayoutLabels,
};

export const payoutDateLabelsOnboarding: { [key: string]: string } = {
  [PayFrequency.WEEKLY]: 'Payout Day',
  [PayFrequency.SEMIMONTHLY]: 'Payout Dates',
  [PayFrequency.MONTHLY]: 'Payout Date',
};

const fillPayoutDateLabelsOnboarding = () => {
  payoutDateLabelsOnboarding[PayFrequency.WEEKLY] = i18n.t(
    'contract-onboarding.common:onboarding-phase.compensation-section.payout-day-label',
    'Payout Day',
  );
  payoutDateLabelsOnboarding[PayFrequency.SEMIMONTHLY] = i18n.t(
    'contract-onboarding.common:onboarding-phase.compensation.payout-dates-label',
    'Payout Dates',
  );
  payoutDateLabelsOnboarding[PayFrequency.MONTHLY] = i18n.t(
    'contract-onboarding.common:onboarding-phase.compensation.payout-date-label',
    'Payout Date',
  );
};

export const payoutDateLabelsDefinition: { [key: string]: string } = {
  [PayFrequency.WEEKLY]: 'Payout Day',
  [PayFrequency.SEMIMONTHLY]: 'First Payout Date',
  [PayFrequency.MONTHLY]: 'Payout Date',
};

const fillPayoutDateLabelsDefinition = () => {
  payoutDateLabelsOnboarding[PayFrequency.WEEKLY] = i18n.t(
    'contract-onboarding.common:definition-phase.compensation.payout-day-label',
    'Payout Day',
  );
  payoutDateLabelsOnboarding[PayFrequency.SEMIMONTHLY] = i18n.t(
    'contract-onboarding.common:definition-phase.compensation.first-payout-date-label',
    'First Payout Date',
  );
  payoutDateLabelsOnboarding[PayFrequency.MONTHLY] = i18n.t(
    'contract-onboarding.common:definition-phase.compensation.payout-date-label',
    'Payout Date',
  );
};

i18n.on('languageChanged init', () => {
  fillPayoutDateLabelsOnboarding();
  fillPayoutDateLabelsDefinition();
});
