import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { theme } from 'twin.macro';

import Button from '../button';
import Icon from '../icon';
import {
  DescriptionText,
  FileDropElementContainer,
  FileDropElementLayout,
  FileUploadInfoContainer,
  RejectionContainer,
  TitleText,
  UploadIcon,
} from '../stateless-file-upload';
import { UploadedFileFormat } from '../types';
import { fileUploadErrorMapping, getImageDimensions } from '../utils';
import FileUploadEntry from './components/file-upload-entry';

interface FileUploadProps {
  defaults?: UploadedFileFormat[];
  uploadedFiles?: File[];
  onDeleteDefaults?: (o: UploadedFileFormat) => void;
  onChange?: (f: File[]) => void;
  multiple?: boolean;
  minimal?: boolean;
  hideIcon?: boolean;
  title?: string;
  description?: string;
  maxSize?: number;
  accept?: string[];
  handleFileRejections?: (rejections: FileRejection[]) => void;
  maxTotalSize?: number;
  maxWidth?: number;
  maxHeight?: number;
  disabled?: boolean | undefined;
  resetFiles?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onChange,
  multiple,
  defaults,
  uploadedFiles,
  onDeleteDefaults,
  minimal,
  hideIcon = false,
  maxSize = 10 * 1024 * 1024,
  title,
  description,
  accept = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'],
  handleFileRejections,
  maxTotalSize = 10 * 1024 * 1024,
  maxWidth,
  maxHeight,
  disabled = false,
  resetFiles,
  ...props
}) => {
  const { t } = useTranslation('common');
  const [files, setFiles] = useState([] as File[]);
  const [customError, setCustomError] = useState({ code: '' });

  const onDropAccepted = useCallback(
    async (droppedFiles: Array<File>) => {
      if (multiple) setFiles((prev) => prev.concat(...droppedFiles));
      else if (maxWidth && maxHeight) {
        try {
          const dimensions = await getImageDimensions({
            file: droppedFiles[0],
          });
          if (dimensions.width <= maxWidth && dimensions.height <= maxHeight) {
            setFiles([droppedFiles[0]]);
          } else {
            setCustomError({
              code: 'max-dimension-exceeded',
            });
          }
        } catch (error) {
          // no need to handle , adding this because tslint throw errors when no catch block
        }
      } else {
        setFiles([droppedFiles[0]]);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (uploadedFiles) setFiles(uploadedFiles);
  }, [uploadedFiles]);

  const onChangeFiles = () => {
    if (onChange) {
      onChange(files as never);
    }
  };

  useEffect(() => {
    onChangeFiles();
  }, [files]);

  const {
    getInputProps,
    getRootProps,
    fileRejections,
    isDragActive,
  } = useDropzone({
    accept,
    onDropAccepted,
    multiple,
    maxSize,
    validator: ({ name, size }) => {
      setCustomError({ code: '' });
      if (files.map((f) => f.name).includes(name)) {
        return {
          code: 'already-accepted',
          message: 'Already accepted in file upload',
        };
      }

      if (
        files.reduce((prev, curr) => prev + curr.size, 0) + size >
        maxTotalSize
      ) {
        return {
          code: 'max-size-exceeded',
          message: 'Max size exceeded',
        };
      }

      return null;
    },
    disabled,
  });

  const fileUploadError = useMemo(() => fileRejections.length > 0, [
    fileRejections,
  ]);

  useEffect(() => {
    if (handleFileRejections) {
      handleFileRejections(fileRejections);
    }
  }, [fileRejections]);

  const noFiles = files.length + (defaults?.length ?? 0) === 0;

  const getFileDropElement = () => (
    <div>
      <FileDropElementContainer
        {...props}
        {...getRootProps()}
        hasError={fileUploadError || !!customError.code}
        isDragActive={isDragActive}
      >
        <input
          data-testid="file-upload"
          {...getInputProps({
            onChange() {
              onChangeFiles();
            },
          })}
        />
        <FileDropElementLayout minimal={!!minimal} noFiles={noFiles}>
          {!minimal && noFiles && !hideIcon && (
            <UploadIcon tw="mb-base" fileUploadError={fileUploadError} />
          )}
          {minimal && noFiles && !hideIcon && (
            <UploadIcon fileUploadError={fileUploadError} />
          )}
          <FileUploadInfoContainer noFiles={noFiles} hideIcon={hideIcon}>
            <TitleText hasError={fileUploadError}>
              {title ?? t('upload-files.drag-here', 'Drag and Drop files here')}
            </TitleText>
            <DescriptionText
              hasError={fileUploadError}
              minimal={!!minimal}
              noFiles={noFiles}
            >
              {description ??
                t(
                  'upload-files.supported-files',
                  'Files Supported: PDF, PNG, JPG (Max 30mb)',
                )}
            </DescriptionText>
          </FileUploadInfoContainer>
          <Button variant="secondary" size="small" disabled={disabled}>
            {t('upload-files.upload-button', 'Upload File')}
          </Button>
        </FileDropElementLayout>
      </FileDropElementContainer>
      {customError?.code && (
        <RejectionContainer>
          <Icon tw="mr-tiny" name="error" fill={theme`colors.icon-negative`} />
          <span>
            {fileUploadErrorMapping({
              t,
              key: customError.code,
              maxHeight,
              maxWidth,
              maxSize,
              maxTotalSize,
            })}
          </span>
        </RejectionContainer>
      )}
      {fileUploadError &&
        fileRejections.map((rejection) => {
          const errorCode = rejection.errors[0].code;
          return (
            <RejectionContainer key={rejection.file.name}>
              <Icon
                tw="mr-tiny"
                name="error"
                fill={theme`colors.icon-negative`}
              />
              <span>
                {fileUploadErrorMapping({
                  t,
                  key: errorCode,
                  maxHeight,
                  maxWidth,
                  maxSize,
                  maxTotalSize,
                })}
              </span>
            </RejectionContainer>
          );
        })}
    </div>
  );

  const ConditionalRenderFileDrop = () => {
    if (!minimal) return getFileDropElement();
    if (minimal && !multiple && !noFiles) return <div />;
    return getFileDropElement();
  };

  useEffect(() => {
    if (resetFiles) {
      setFiles([]);
    }
  }, [resetFiles]);

  return (
    <div>
      {defaults && defaults?.length
        ? defaults.map((file) => (
            <FileUploadEntry
              key={file.name}
              file={file}
              onDeleteClick={() => {
                if (onDeleteDefaults) {
                  onDeleteDefaults(file);
                }
              }}
            />
          ))
        : undefined}
      {files.map((file) => (
        <FileUploadEntry
          key={file.name}
          file={file}
          onDeleteClick={() => {
            setFiles(files.filter((f) => f.name !== file.name));
          }}
        />
      ))}
      {ConditionalRenderFileDrop()}
    </div>
  );
};

export default FileUpload;
