import React, { forwardRef, useContext, useRef } from 'react';

import { useToggle } from 'react-use';

import { Root, createRoot } from 'react-dom/client';
import tw, { TwStyle, theme } from 'twin.macro';

import Button from '../button';
import IconButton from '../icon-button';
import Modal from '../modal';
import ThemeProvider, { ThemeContext } from '../theme-provider';
import { ButtonProps, CustomModalProps } from '../types';

interface Props {
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  btnCancelLabel?: string;
  onCancel?: () => void;
  onCloseClick?: () => void;
  btnConfirmLabel?: string;
  btnConfirmProps?: ButtonProps;
  containerStyles?: TwStyle;
  footerContainerStyles?: TwStyle;
  contentContainerStyles?: TwStyle;
  onConfirm?: ({
    close,
    setLoading,
  }: {
    close: () => void;
    setLoading: (v: boolean) => void;
  }) => void;
  closeOnConfirm?: boolean;
  loading?: boolean;
  shouldDisableAllButtonsWhileLoading?: boolean;
  render?: ({
    handleCancel,
    onClose,
    handleConfirm,
    loading,
  }: CustomModalProps) => React.ReactElement;
  renderAsStandalone?: boolean;
  isClosingModalAllowed?: boolean;
}

interface ModalProps extends Props {
  onClose: () => void;
}

const ModalDOMContainer = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ModalProps>
>((props, ref) => (
  <ThemeProvider>
    <ModalDOM ref={ref} {...props} />
  </ThemeProvider>
));

const ModalDOM = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ModalProps>
>(
  (
    {
      title,
      content,
      btnCancelLabel = 'Cancel',
      btnConfirmLabel = 'Confirm',
      containerStyles,
      contentContainerStyles,
      footerContainerStyles,
      btnConfirmProps = {},
      onClose,
      onCancel,
      onConfirm,
      onCloseClick,
      render,
      renderAsStandalone = false,
      closeOnConfirm = true,
      shouldDisableAllButtonsWhileLoading = false,
      isClosingModalAllowed = true,
    },
    ref,
  ) => {
    const [loading, setLoading] = useToggle(false);
    const { isNewThemeApplied } = useContext(ThemeContext);

    const handleCloseClick = () => {
      onClose();
      if (onCloseClick) {
        onCloseClick();
      }
    };

    const handleCancel = () => {
      onClose();
      if (onCancel) {
        onCancel();
      }
    };

    const handleConfirm = () => {
      if (closeOnConfirm) {
        onClose();
      }
      if (onConfirm) {
        onConfirm({ close: onClose, setLoading });
      }
    };

    if (renderAsStandalone && render) {
      return (
        <div ref={ref}>
          {render({ onClose, handleCancel, handleConfirm, loading })}
        </div>
      );
    }

    return (
      <Modal.LightBox ref={ref}>
        <Modal.Container
          data-testid="modal"
          data-cy="modal"
          css={containerStyles}
        >
          {isClosingModalAllowed && (
            <IconButton
              size="small"
              name="cross"
              fill={
                isNewThemeApplied ? undefined : theme`colors.icon-secondary`
              }
              onClick={handleCloseClick}
              tw="absolute right-0 top-0"
              disabled={shouldDisableAllButtonsWhileLoading ? loading : false}
            />
          )}
          {render ? (
            render({ onClose, handleCancel, handleConfirm, loading })
          ) : (
            <div
              css={[
                tw`flex flex-col items-center mt-base text-center px-extra-large`,
                contentContainerStyles,
              ]}
            >
              <h4 tw="text-h5 text-text-primary font-semibold">{title}</h4>

              <div tw="mt-extra-small text-text-tertiary text-ps max-w-sm">
                {content}
              </div>

              <div
                css={[
                  tw`mt-large flex gap-small justify-center`,
                  footerContainerStyles,
                ]}
              >
                {isClosingModalAllowed && (
                  <Button
                    variant="outline"
                    onClick={handleCancel}
                    size={btnConfirmProps.size}
                    disabled={
                      shouldDisableAllButtonsWhileLoading ? loading : false
                    }
                  >
                    {btnCancelLabel}
                  </Button>
                )}
                <Button
                  onClick={handleConfirm}
                  loading={loading}
                  disabled={
                    shouldDisableAllButtonsWhileLoading ? loading : false
                  }
                  {...btnConfirmProps}
                >
                  {btnConfirmLabel}
                </Button>
              </div>
            </div>
          )}
        </Modal.Container>
      </Modal.LightBox>
    );
  },
);

const PARENT_MODAL_ID = 'static-modal-root';

// eslint-disable-next-line import/prefer-default-export
export const useModalStatic = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const root = useRef<Root>();

  const handleClose = () => {
    if (modalRef.current) {
      root.current?.unmount();
      root.current = undefined;
    }
  };

  const openModal = (props: Props) => {
    //   /**
    //    * Need to use "static-modal-root" root container for modal static as it using "render" & "unmount" API, which will have different behaviour with "createPortal"
    //    * to prevent unexpected issue when multiple components mounting at same time in "dialog-root" container
    //    */
    handleClose(); // close old modal before open new modal
    const div = document?.getElementById(PARENT_MODAL_ID);
    if (!div) return;
    if (!root.current) {
      root.current = createRoot(div);
    }

    root.current?.render(
      <ModalDOMContainer ref={modalRef} onClose={handleClose} {...props} />,
    );
  };

  return openModal;
};
