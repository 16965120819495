/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { PillTag } from '@multiplier/common';

export enum PendingOn {
  YOU = 'YOU',
  EMPLOYEE = 'EMPLOYEE',
  MULTIPLIER = 'MULTIPLIER',
  SIGNATORY = 'SIGNATORY',
  FREELANCER = 'FREELANCER',
  CONTRACTOR = 'CONTRACTOR',
}

const PendingOnPill: React.FC<{
  pendingOn?: PendingOn | null;
}> = ({ pendingOn, ...props }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const statusLabels = {
    [PendingOn.YOU]: t('onboarding-phase.pending-on.you', 'You'),
    [PendingOn.EMPLOYEE]: t('onboarding-phase.pending-on.employee', 'Employee'),
    [PendingOn.MULTIPLIER]: t(
      'onboarding-phase.pending-on.multiplier',
      'Multiplier',
    ),
    [PendingOn.SIGNATORY]: t(
      'onboarding-phase.pending-on.signatory',
      'Signatory',
    ),
    [PendingOn.FREELANCER]: t(
      'onboarding-phase.pending-on.freelancer',
      'Freelancer',
    ),
    [PendingOn.CONTRACTOR]: t(
      'onboarding-phase.pending-on.contractor',
      'Contractor',
    ),
  };

  return (
    <PillTag
      label={`${t('onboarding-phase.pending-on.prefix', 'Pending On')} ${
        pendingOn && statusLabels[pendingOn]
      }`}
      variant="PROCESSING"
      {...props}
    />
  );
};

export default PendingOnPill;
