/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import {
  Button,
  DropdownValue,
  Icon,
  ThemeContext,
  ToolTip,
  useScrollXControl,
} from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import uniqBy from 'lodash/uniqBy';
import tw, { TwStyle, css, theme } from 'twin.macro';

import { useModal } from 'app/hooks';
import MobileDialog from 'common/components/mobile-dialog';
import { benefitTypeLabels } from 'common/constants/default-labels';

import {
  Benefit,
  BenefitCostingType,
  BenefitFeatures,
  BenefitType,
  CountryCode,
  Maybe,
} from '__generated__/graphql';

import { notEmpty } from '../../../app/utils/array';
import {
  getCompareFileUrl,
  groupInsuranceByType,
  mapBenefitTypeWithOrder,
} from '../../services/insurance-service';
import InsuranceModal from '../insurance-modal';
import InsuranceSelect from '../insurance-select';
import InsuranceTableScrollableButton from './components/insurance-table-scrollable-button';
import { InsuranceFeature, featureLabels } from './types';

const InsuranceFeatureInformation: React.FC<{ feature: BenefitFeatures }> = ({
  feature,
}) => {
  const [showDialog, handleDialogClose, handleDialogOpen] = useModal();

  return (
    <>
      <button onClick={handleDialogOpen} type="button">
        <Icon name="info" />
      </button>
      <MobileDialog
        open={showDialog}
        onClose={handleDialogClose}
        title={featureLabels[feature.key as InsuranceFeature]?.label}
      >
        <p>{featureLabels[feature.key as InsuranceFeature]?.description}</p>
      </MobileDialog>
    </>
  );
};

const MIN_COL = 3;

const FeatureIndicator: React.FC<{
  variant?: InsuranceDetailsVariant;
  benefit: Benefit;
  feature: BenefitFeatures;
  css?: Array<TwStyle | false>;
}> = ({ variant, benefit, feature, ...props }) => {
  const featureFromBenefit = useMemo(
    () => benefit?.features?.find((f) => f?.key === feature.key),
    [benefit, feature],
  );

  return (
    <td
      data-testid="feature-indicator"
      css={[
        variant === 'mobile' && tw`border-l-2 border-bg01`,
        variant === 'tablet' && tw`min-w-[15%]`,
        ...(props?.css ?? []),
      ]}
      {...props}
    >
      <div
        css={[
          tw`flex place-content-center`,
          variant === 'mobile' && tw`place-content-start pl-16 py-16`,
        ]}
      >
        {Number(featureFromBenefit?.value)
          ? Array.from(
              Array(Number(featureFromBenefit?.value)).keys(),
            ).map((value) => (
              <Icon
                key={value}
                tw="w-24"
                name="star"
                data-testid="star-icon"
                fill={theme`colors.slateGrey700`}
              />
            ))
          : featureFromBenefit && (
              <div tw="flex justify-center place-items-center rounded-full w-32 h-32">
                <Icon
                  tw="w-[15px]"
                  name="check"
                  data-testid="check-icon"
                  fill={theme`colors.slateGrey900`}
                />
              </div>
            )}
      </div>
    </td>
  );
};

type InsuranceDetailsVariant = 'mobile' | 'tablet' | 'desktop';

const SCROLL_WIDTH = 186;

interface InsuranceDetailsProps {
  variant?: InsuranceDetailsVariant;
  showSelected?: boolean;
  selectable?: boolean;
  selectedValue?: string | null;
  availableBenefits?: Maybe<Array<Maybe<Benefit>>>; // change
  onChange?: (value: string | null | undefined) => void;
  country?: Maybe<CountryCode>;
  onTypeChange: (type: string) => void;
  benefitType?: Maybe<string>;
  shouldShowTierSelection?: boolean;
  shouldShowPlanChooseLabel?: boolean;
  typesDropdown?: Array<DropdownValue & { isRecommended?: boolean }>;
  benefitsByType: Benefit[];
  isTableExpand?: boolean; // for styling only
  minCols?: number;
}

const InsuranceDetails: React.FC<InsuranceDetailsProps> = ({
  availableBenefits,
  variant = 'desktop',
  showSelected = false,
  selectable = false,
  selectedValue,
  onChange,
  country,
  onTypeChange,
  benefitType,
  shouldShowTierSelection = true,
  typesDropdown,
  benefitsByType,
  isTableExpand,
  minCols = MIN_COL,
}) => {
  const {
    parentRef,
    childRef,
    scrollable,
    handleScrollRight,
    handleScrollLeft,
  } = useScrollXControl({ border: 1 });
  const { t } = useTranslation('insurance');
  const [benefitName, setBenefitName] = useState('');
  const [factSheetURL, setFactSheetURL] = useState('');
  const isInsuranceExpand = useFeature(AppFeature.INSURANCE_EXPAND)?.on;
  const { isNewThemeApplied } = useContext(ThemeContext);

  const selectedBenefit = availableBenefits?.find(
    (benefit) => benefit?.id === selectedValue,
  );

  const remainCols = Array(Math.max(minCols - benefitsByType.length, 0)).fill(
    0,
  );

  const features = useMemo(
    () =>
      uniqBy(
        benefitsByType.flatMap((benefit) => benefit?.features).filter(notEmpty),
        'key',
      ),
    [benefitsByType],
  );

  const [
    showInsuranceFactsheet,
    handleCloseInsuranceFactsheet,
    handleOpenInsuranceFactsheet,
  ] = useModal();

  const [
    showInsuranceCategoryFactsheet,
    handleCloseInsuranceCategoryFactsheet,
    handleOpenInsuranceCategoryFactsheet,
  ] = useModal();

  const handleOpenModal = (
    name: Maybe<string> | undefined,
    url: Maybe<string> | undefined,
  ) => {
    setBenefitName(name ?? '');
    setFactSheetURL(url ?? '');
    handleOpenInsuranceCategoryFactsheet();
  };

  const avalableTypesDropdown = useMemo(() => {
    if (typesDropdown) return typesDropdown;
    // will remove in future
    return Object.keys(groupInsuranceByType(availableBenefits ?? []))
      .map((benefit) => mapBenefitTypeWithOrder(benefit as BenefitType))
      .sort((first, second) => first.sort - second.sort)
      .map((benefitWithSort) => ({
        value: benefitWithSort.type as string,
        title: benefitTypeLabels[benefitWithSort.type],
        isRecommended: benefitWithSort.type === BenefitType.FAMILY_INSURANCE,
      }));
  }, [typesDropdown, availableBenefits]);

  const handleSelectBenefit = (benefit: Benefit) => {
    if (onChange) {
      if (selectedValue === benefit.id) {
        onChange(undefined);
      } else {
        onChange(benefit.id);
      }
    }
    // scroll to selected benefit after selecting
    const col = document.getElementById(
      `benefit-column-${selectable}-${benefit.id}`,
    );
    if (col) {
      col.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  const [
    hasAdditionalNotesForCountry,
    setHasAdditionalNotesForCountry,
  ] = useState(false);

  const partnersWithAdditionalNote = useFeature(
    AppFeature.INSURANCE_CUSTOM_NOTE_PARTNER_LIST,
  ).value;

  useEffect(() => {
    if (hasAdditionalNotesForCountry) setHasAdditionalNotesForCountry(false);
  }, [availableBenefits]);

  useEffect(() => {
    benefitsByType.forEach((benefit) => {
      if (
        !hasAdditionalNotesForCountry &&
        partnersWithAdditionalNote?.includes(benefit?.provider?.partner?.name)
      ) {
        setHasAdditionalNotesForCountry(true);
      }
    });
  }, [benefitsByType]);

  useEffect(() => {
    if (!benefitType && avalableTypesDropdown?.length > 0) {
      // set default value if no selected benefit
      onTypeChange(avalableTypesDropdown?.[0]?.value);
    }
  }, [avalableTypesDropdown, benefitType]);

  useEffect(() => {
    let selectedBenefitType; // getter from contract
    if (isInsuranceExpand) {
      // v2
      selectedBenefitType = selectedBenefit?.provider?.coverageGroup
        ?.id as string;
    } else {
      // @deprecated - v1
      selectedBenefitType = selectedBenefit?.type;
    }
    if (selectedBenefitType) {
      onTypeChange(selectedBenefitType);
    }
  }, [selectedBenefit, isInsuranceExpand]);

  const insuranceTypeSelectable =
    selectable && avalableTypesDropdown.length > 1;

  const coverageAndInsurancePlanLabel = insuranceTypeSelectable
    ? t('insurance-select.label.coverage-and-plan-v2', 'Select Coverage', {
        interpolation: {
          escapeValue: false,
        },
      })
    : t(
        'insurance-select.label.coverage-and-plan-already-selected-v2',
        'Below plans provide Insurance cover for:',
        {
          interpolation: {
            escapeValue: false,
          },
        },
      );

  const insurancePlanLabel =
    selectable && shouldShowTierSelection
      ? t('insurance-select.label.only-plan-v2', 'Select Insurance Plan')
      : t(
          'insurance-select.label.only-plan-already-selected-v2',
          'Insurance plan',
        );

  return (
    <div>
      <div css={[tw`px-large flex flex-col`, !isTableExpand && tw`px-none`]}>
        <div tw="text-text-secondary text-ps font-medium mb-extra-small">
          {coverageAndInsurancePlanLabel}
        </div>
        <InsuranceSelect
          types={avalableTypesDropdown}
          country={country}
          checkedType={benefitType as string}
          onChange={(type) => {
            if (onChange) {
              onChange(null);
            }
            if (onTypeChange) onTypeChange(type);
          }}
          selectable={insuranceTypeSelectable}
        />
        <div tw="text-text-secondary text-ps font-medium mb-extra-small mt-large">
          {insurancePlanLabel}
        </div>
      </div>

      {variant === 'mobile' ? (
        <InsuranceContainer data-testid="table">
          <thead>
            <tr>
              {benefitsByType.map((benefit: Benefit) => (
                <td
                  css={[
                    tw`text-left border-l-2 border-border-primary `,
                    !isNewThemeApplied && tw`border-bg01`,
                  ]}
                  key={benefit.id}
                >
                  <div tw="p-base">
                    <p tw="text-18 text-text-primary font-medium">
                      {benefit?.packageName ?? benefit?.packageTier?.tier ?? ''}
                    </p>
                    <p tw="text-ps text-text-primary font-semibold">
                      {`${benefit.cost} ${benefit.currency}`}
                      <span tw="font-normal">
                        {' / '}
                        {t('insurance-mobile.price.tag', 'mo')}
                      </span>
                    </p>
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature) => (
              <React.Fragment key={feature.key}>
                <tr data-testid="feature">
                  <td
                    css={[
                      tw`bg-background-primary py-small pl-base text-left w-1/2`,
                      !isNewThemeApplied && tw`bg-grey05`,
                    ]}
                    key={feature.key}
                    colSpan={3}
                  >
                    <div tw="flex items-center">
                      <p tw="text-p text-text-primary mr-extra-small">
                        {featureLabels[feature.key as InsuranceFeature]?.label}
                      </p>
                      <InsuranceFeatureInformation feature={feature} />
                    </div>
                  </td>
                </tr>
                <tr>
                  {benefitsByType.map((benefit) => (
                    <FeatureIndicator
                      key={benefit.id}
                      variant={variant}
                      benefit={benefit}
                      feature={feature}
                    />
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </InsuranceContainer>
      ) : (
        <div tw="relative">
          <InsuranceTableScrollableButton
            isVisible={scrollable.left}
            onClick={() => {
              handleScrollLeft(SCROLL_WIDTH);
            }}
            overrideCss={[tw`top-none left-[186px] border-r-0`]}
          />
          <InsuranceTableScrollableButton
            isVisible={scrollable.right}
            onClick={() => {
              handleScrollRight(SCROLL_WIDTH);
            }}
            overrideCss={[tw`right-none top-none border-l-none rounded-r-base`]}
            isReverse
          />
          <div
            css={[
              tw`overflow-x-auto relative border-y border-border-primary scrollbar-hide`,
              !isNewThemeApplied && tw`border-slateGrey200`,
              !isTableExpand && tw`border rounded-base`,
              css`
                ::-webkit-scrollbar {
                  display: none;
                }
              `,
            ]}
            ref={parentRef}
          >
            <InsuranceContainer
              css={['border-spacing: 0px !important;']}
              data-testid="table"
              ref={childRef as any}
            >
              <SelectedInsurance>
                <col />
                {benefitsByType.map((benefit) => (
                  <col
                    key={benefit.id}
                    data-testid="benefit-column"
                    id={`benefit-column-${selectable}-${benefit.id}`}
                  />
                ))}
              </SelectedInsurance>
              <thead>
                <tr>
                  <td
                    css={[
                      tw`flex flex-col pt-[60px] pb-extra-large z-[2] px-small min-w-[186px] max-w-[186px] sticky bg-background-primary left-none border-border-primary border-b border-r`,
                      !isNewThemeApplied && tw`border-slateGrey200`,
                    ]}
                  >
                    <div tw="w-full text-center text-text-primary font-semibold">
                      {t(
                        'insurance-screen.number-of-plans',
                        '{{count}} Plans',
                        {
                          count: benefitsByType?.length,
                        },
                      )}
                    </div>
                    <div tw="flex w-full gap-tiny">
                      <Icon tw="mt-tiny" name="compare-new" />
                      <button
                        tw="underline pl-tiny text-p text-text-action font-semibold focus:outline-none"
                        type="button"
                        onClick={handleOpenInsuranceFactsheet}
                      >
                        {t(
                          'insurance-screen.compare-plans-link',
                          'Compare Plans',
                        )}
                      </button>
                    </div>
                    <p tw="h-20" />

                    <InsuranceModal
                      open={showInsuranceFactsheet}
                      onClose={handleCloseInsuranceFactsheet}
                      handleCtaClick={() => null}
                      header={t(
                        'insurance-screen.compare-plans-link',
                        'Compare Plans',
                      )}
                      factsheetPath={
                        getCompareFileUrl(
                          availableBenefits ?? [],
                          benefitType as BenefitType,
                        ) ?? ''
                      }
                    />
                  </td>
                  {benefitsByType.map((benefit) => (
                    <td
                      key={benefit.id}
                      data-testid="benefit-description"
                      css={[
                        tw`py-none text-center overflow-hidden relative`,
                        tw`before:(content[''] block h-extra-small bg-white absolute top-none left-none w-full)`,
                        selectedValue === benefit.id &&
                          (isNewThemeApplied
                            ? tw`bg-background-secondary`
                            : tw`bg-grey05`),
                      ]}
                    >
                      <p
                        data-testid={`most-popular-${benefit.packageName}`}
                        css={[
                          tw`py-[1.5px] my-base flex justify-center items-center bg-background-positive-subtle text-ps text-background-positive font-semibold invisible rounded-30 overflow-hidden`,
                          benefit.isRecommended && tw`visible`,
                          tw`mx-20 px-small`,
                        ]}
                      >
                        {t('insurance-screen.recommended-tag', 'Recommended')}
                      </p>
                      <p
                        css={[
                          tw`text-text-primary text-p font-bold mb-extra-small`,
                        ]}
                      >
                        {benefit?.packageName ??
                          benefit?.packageTier?.tier ??
                          ''}
                      </p>
                      {benefit?.costingType === BenefitCostingType.AGE_BASED ? (
                        <ToolTip
                          variant="bottom"
                          tw="w-auto cursor-pointer"
                          content={
                            <span>
                              {t(
                                'insurance-desktop.safetywing.starting-from-tooltip',
                                'Actual Premium might vary depending on the age of the insured party.',
                              )}
                            </span>
                          }
                        >
                          <p tw="text-pxs text-text-tertiary underline">
                            {t(
                              'insurance-desktop.safetywing.starting-from',
                              'Starting From',
                            )}
                          </p>
                        </ToolTip>
                      ) : null}
                      <p tw="text-ps text-text-primary font-semibold">
                        {`${benefit.cost} ${benefit.currency}`}
                        <span tw="font-normal">
                          {' / '}
                          {t('insurance-desktop.price.tag', {
                            defaultValue: '{{frequency}}',
                            replace: {
                              frequency: benefit.frequency,
                            },
                          })}
                        </span>
                      </p>
                      <button
                        tw="text-ps text-text-action font-semibold focus:outline-none flex items-center gap-x-tiny mx-auto mt-small"
                        type="button"
                        data-testid={`${benefit.packageName}`}
                        onClick={() =>
                          handleOpenModal(
                            benefit.packageName,
                            benefit.factSheetUrl,
                          )
                        }
                      >
                        {t('insurance-screen.learn-more-link', 'Learn More')}
                        <Icon
                          name="caret-right"
                          height={12}
                          width={13}
                          fill={
                            isNewThemeApplied
                              ? theme`colors.text-action`
                              : theme`colors.primaryBlue600`
                          }
                        />
                      </button>
                      {benefitName === benefit.packageName && (
                        <InsuranceModal
                          open={showInsuranceCategoryFactsheet}
                          onClose={handleCloseInsuranceCategoryFactsheet}
                          handleCtaClick={() => null}
                          header={benefitName}
                          factsheetPath={factSheetURL}
                        />
                      )}
                    </td>
                  ))}
                  {remainCols.map(() => (
                    <td tw="w-[186px] h-[62px]" />
                  ))}
                </tr>
              </thead>
              <InsuranceFeatureDetail>
                {features.map((feature) => (
                  <tr key={feature.key} data-testid="feature">
                    <td tw="sticky left-none p-none" key={feature.key}>
                      <div
                        css={[
                          tw`w-full py-large text-left bg-background-primary px-small min-w-[186px] max-w-[186px] border-border-primary border-r text-ps`,
                          !isNewThemeApplied && tw`border-slateGrey200`,
                          variant === 'tablet' && tw`py-base`,
                        ]}
                      >
                        <p tw="text-text-primary font-semibold">
                          {
                            featureLabels[feature.key as InsuranceFeature]
                              ?.label
                          }
                        </p>
                        <p tw="text-ps text-text-tertiary">
                          {
                            featureLabels[feature.key as InsuranceFeature]
                              ?.description
                          }
                        </p>
                      </div>
                    </td>
                    {benefitsByType.map((benefit) => (
                      <FeatureIndicator
                        key={benefit.id}
                        variant={variant}
                        benefit={benefit}
                        feature={feature}
                        css={[
                          selectedValue === benefit.id &&
                            (isNewThemeApplied
                              ? tw`bg-background-secondary`
                              : tw`bg-grey05`),
                        ]}
                      />
                    ))}
                  </tr>
                ))}
                {(showSelected || selectable) && shouldShowTierSelection && (
                  <tr>
                    <td tw="sticky left-none p-none z-[2]">
                      <div
                        css={[
                          tw`w-full border-border-primary border-r bg-background-primary h-[62px] min-w-[186px] max-w-[186px]`,
                          !isNewThemeApplied && tw`border-slateGrey200`,
                        ]}
                      />
                    </td>
                    {benefitsByType.map((benefit) => {
                      const selected = selectedValue === benefit.id;
                      return (
                        <td
                          css={[
                            tw`overflow-hidden rounded-b-base relative`,
                            tw`after:(content[''] block h-extra-small bg-background-white absolute bottom-none left-none w-full)`,
                            selected &&
                              (isNewThemeApplied
                                ? tw`bg-background-secondary`
                                : tw`bg-grey05`),
                          ]}
                          key={benefit.id}
                        >
                          <div tw="flex place-content-center py-small">
                            <Button
                              css={[
                                tw`text-p static`,
                                !selectable && !selected && tw`invisible`,
                              ]}
                              size="small"
                              variant="outline"
                              selected={selected}
                              onClick={() => {
                                handleSelectBenefit(benefit);
                              }}
                              data-testid="select-button"
                            >
                              {selected ? (
                                <div tw="flex flex-row items-center">
                                  <span tw="mr-small">
                                    {t(
                                      'insurance-details.selected',
                                      'Selected',
                                    )}
                                  </span>
                                  <Icon
                                    tw="w-[15px] h-[11px]"
                                    name="check"
                                    fill={theme`colors.white`}
                                  />
                                </div>
                              ) : (
                                <span>
                                  {t('insurance-details.select', 'Select')}
                                </span>
                              )}
                            </Button>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                )}
              </InsuranceFeatureDetail>
            </InsuranceContainer>
          </div>
        </div>
      )}
    </div>
  );
};

const InsuranceContainer = tw.table`table-auto border-collapse w-full`;
const InsuranceFeatureDetail = tw.tbody``;
const SelectedInsurance = tw.colgroup``;

export default InsuranceDetails;
