import { range } from 'lodash';

import { getNumberWithSuffix } from 'app/utils/format';
import { DropdownValue } from 'common/components/combo-box';
import i18n from 'i18n';

import {
  Maybe,
  PayFrequency,
  PayFrequencyDate,
  PayFrequencyDateIdentifier,
  PayFrequencyDateInput,
} from '__generated__/graphql';

export const getFirstPayout = (
  paymentFrequency: Maybe<PayFrequency> | undefined,
  paymentFrequencyDate: Maybe<Maybe<PayFrequencyDate>[]> | undefined,
): string | undefined | null => {
  switch (paymentFrequency) {
    case PayFrequency.WEEKLY:
      return paymentFrequencyDate
        ?.find(
          (item) => item?.identifier === PayFrequencyDateIdentifier.PAYOUT_DAY,
        )
        ?.dayOfWeek?.toString();

    case PayFrequency.SEMIMONTHLY:
      return paymentFrequencyDate
        ?.find(
          (item) =>
            item?.identifier === PayFrequencyDateIdentifier.FIRST_PAYOUT_DATE,
        )
        ?.dateOfMonth?.toString();

    case PayFrequency.MONTHLY:
      return paymentFrequencyDate
        ?.find(
          (item) => item?.identifier === PayFrequencyDateIdentifier.PAYOUT_DATE,
        )
        ?.dateOfMonth?.toString();

    default:
      return paymentFrequencyDate?.[0]?.dateOfMonth?.toString();
  }
};

export const mapPayFrequencyDateToFormValues = (
  paymentFrequencyDate: Maybe<Maybe<PayFrequencyDate>[]> | undefined,
): PayFrequencyDateInput[] | undefined | null => {
  if (paymentFrequencyDate) {
    const abc = paymentFrequencyDate.map((dateItem) => ({
      identifier: dateItem?.identifier,
      dateOfMonth: dateItem?.dateOfMonth,
      dayOfWeek: dateItem?.dayOfWeek,
    }));
    return abc as PayFrequencyDateInput[];
  }
  return null;
};

export const getSecondPayout = (
  paymentFrequency: Maybe<PayFrequency> | undefined,
  paymentFrequencyDate: Maybe<Maybe<PayFrequencyDate>[]> | undefined,
): string | undefined | null => {
  if (paymentFrequency === PayFrequency.SEMIMONTHLY)
    return paymentFrequencyDate
      ?.find(
        (item) =>
          item?.identifier === PayFrequencyDateIdentifier.SECOND_PAYOUT_DATE,
      )
      ?.dateOfMonth?.toString();
  return null;
};

export const getPayoutDateLabelValues = (
  startDate: number,
  endDate: number,
): DropdownValue[] =>
  range(startDate, endDate).map((date) => ({
    title: i18n.t(
      'definition-phase.compensation.monthly-payout-date-labels-nth',
      '{{ ordinalNo }} of the month',
      {
        ns: 'contract-onboarding.common',
        replace: { ordinalNo: getNumberWithSuffix(date.toString()) },
      },
    ),
    value: date.toString(),
  }));
