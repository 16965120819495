/** @jsxImportSource @emotion/react */
import React from 'react';

import { UploadedDocumentViewer, fileIsDocument } from '@multiplier/common';
import tw, { styled } from 'twin.macro';

import { Document, FileLink, Maybe } from '__generated__/graphql';

interface UploadedBankStatementListProps {
  files: Maybe<Maybe<Document | FileLink>[]> | undefined;
  shouldShowMinimalView?: boolean;
}

const DocumentList = styled.div(
  ({ shouldShowMinimalView }: { shouldShowMinimalView: boolean }) => [
    tw`flex`,
    shouldShowMinimalView ? tw`flex-col` : tw`flex-row`,
  ],
);

const DocumentItem = styled.div(
  ({ shouldShowMinimalView }: { shouldShowMinimalView: boolean }) => [
    shouldShowMinimalView ? tw`mb-small` : tw`mr-2`,
  ],
);

const UploadedBankStatementList = ({
  files,
  shouldShowMinimalView = false,
}: UploadedBankStatementListProps): React.ReactElement => (
  <DocumentList shouldShowMinimalView={shouldShowMinimalView}>
    {files?.map((file) => (
      <DocumentItem
        key={`bank-statement-${file?.id}`}
        shouldShowMinimalView={shouldShowMinimalView}
      >
        <UploadedDocumentViewer
          minimal={shouldShowMinimalView}
          link={
            fileIsDocument(file) ? file.downloadUrl : (file as FileLink)?.link
          }
          label={file?.name}
        />
      </DocumentItem>
    ))}
  </DocumentList>
);

export default UploadedBankStatementList;
