import { isAfter } from 'date-fns';

import { Experience } from 'app/models/module-config';

import { Contract, ContractStatus, ContractType } from '__generated__/graphql';

import onboardingDataConfig, {
  OnboardingConfigStatus,
  statusToColumnMapping,
} from '.';

const checkVisibility = (
  contract: Contract,
  visibleConfig:
    | {
        contractType: string[] | ContractType[];
        contractStatus: string[] | ContractStatus[];
      }
    | undefined,
) =>
  !!(
    contract.status &&
    visibleConfig?.contractStatus.includes(contract.status) &&
    contract.type &&
    visibleConfig.contractType.includes(contract.type)
  );

const checkEditability = (
  contract: Contract,
  editConfig: { [key in OnboardingConfigStatus]?: boolean } | undefined,
) => {
  let editable = false;

  if (
    contract.status === ContractStatus.ACTIVE &&
    isAfter(new Date(), new Date(contract.startOn))
  ) {
    editable = !!editConfig?.[OnboardingConfigStatus.EMPLOYMENT_STARTED];
  } else {
    const status = contract.onboarding?.status ?? contract.status;
    const column = status && statusToColumnMapping[status];

    if (column) {
      editable = !!editConfig?.[column];
    }
  }

  return editable;
};

const resolveOnboardingField = (
  field: string,
  experience: Experience,
  contract: Contract,
  config = onboardingDataConfig,
): { visible: boolean; editable: boolean } => {
  const visibleConfig = config?.[field]?.[experience]?.visibility;
  const editConfig = config?.[field]?.[experience]?.editability;

  return {
    visible: checkVisibility(contract, visibleConfig),
    editable: checkEditability(contract, editConfig),
  };
};

export default resolveOnboardingField;
