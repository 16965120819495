/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from 'common/components/text-input';

import { BasicDetailsFormValues } from '../basic-details';

const EmailInput: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    register,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="email">
        {t('definition-phase.basic-details.email.label', 'Email Address')}
      </TextInput.Label>
      <TextInput
        id="email"
        placeholder={t(
          'definition-phase.basic-details.email.placeholder',
          'john@gmail.com',
        )}
        {...register('email')}
        error={!!errors.email}
        autoComplete="off"
      />
      {errors.email && (
        <TextInput.Error data-testid="email-error">
          {errors.email.message}
        </TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default EmailInput;
