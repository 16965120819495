import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import fileSize from 'filesize';
import tw, { theme } from 'twin.macro';

import Button from '../../../button';
import Icon from '../../../icon';
import IconButton from '../../../icon-button';
import { ThemeContext } from '../../../theme-provider';
import { Document, UploadedFileFormat } from '../../../types';
import {
  getFileFromUploadedFile,
  isDocumentType,
  isUploadedFile,
} from '../../../utils';

interface FileUploadEntryProps extends React.HTMLAttributes<HTMLDivElement> {
  file: UploadedFileFormat | File;
  icon?: ReactNode;
  hideIcon?: boolean;
  onDeleteClick?: () => void;
  onReuploadClick?: () => void;
  variant?: 'outline' | 'inline';
  renderActions?: ({
    onViewFile,
  }: {
    onViewFile: () => void;
  }) => React.ReactNode;
}

const FileUploadEntry: React.FC<FileUploadEntryProps> = ({
  file,
  icon,
  hideIcon,
  onDeleteClick,
  onReuploadClick,
  variant = 'outline',
  renderActions,
  ...props
}) => {
  const [fileEntry, setFileEntry] = useState<UploadedFileFormat | File>();
  const { t } = useTranslation('common');

  const { isNewThemeApplied } = useContext(ThemeContext);

  useEffect(() => {
    if (isUploadedFile(file) && file?.blob) {
      getFileFromUploadedFile(file).then(setFileEntry);
    } else {
      setFileEntry(file);
    }
  }, [file]);

  const variantStyleMap = (newTheme: boolean) => ({
    outline: [
      tw`bg-background-white border border-border-primary rounded-base shadow-tiny`,
      !newTheme && tw`shadow-low`,
    ],
    inline: [tw``],
  });

  const handleViewFile = () => {
    if (!fileEntry) return;

    if (isUploadedFile(fileEntry) && (fileEntry?.url || fileEntry?.link)) {
      window.open(
        (fileEntry?.url || fileEntry?.link) as string,
        '_blank',
        'noopener,noreferrer',
      );
    } else {
      window.open(
        URL.createObjectURL(fileEntry as File),
        '_blank',
        'noopener,noreferrer',
      );
    }
  };

  return (
    <div
      data-testid="file-entry"
      key={file.name}
      css={[
        tw`flex flex-row items-center py-small px-base text-ps text-text-primary gap-x-base overflow-x-hidden`,
        ...variantStyleMap(isNewThemeApplied)[variant || 'outline'],
      ]}
      {...props}
    >
      {fileEntry && (
        <>
          <FileIcon file={fileEntry} hideIcon={hideIcon} icon={icon} />
          <div tw="flex-grow flex flex-col h-full justify-between overflow-x-hidden">
            <span tw="text-ps truncate">{fileEntry?.name}</span>
            {fileEntry?.size && (
              <span tw="text-text-tertiary">{fileSize(fileEntry.size)}</span>
            )}
            {onReuploadClick && (
              <div tw="mt-6">
                <Button
                  data-testid="reupload"
                  size="small"
                  variant="outline"
                  onClick={onReuploadClick}
                >
                  <div
                    css={[
                      tw`flex flex-row items-center text-text-primary text-p gap-x-tiny`,
                      !isNewThemeApplied && tw`text-primary`,
                    ]}
                  >
                    <div>
                      <Icon
                        name="revert"
                        fill={
                          isNewThemeApplied
                            ? theme`colors.icon-primary`
                            : 'transparent'
                        }
                        stroke={
                          !isNewThemeApplied
                            ? theme`colors.icon-brand`
                            : 'undefined'
                        }
                      />
                    </div>
                    <p tw="mobile:hidden">
                      {t('file-upload-entry.re-submit', 'Re-submit')}
                    </p>
                  </div>
                </Button>
              </div>
            )}
          </div>
          <div tw="flex flex-row flex-nowrap">
            {renderActions ? (
              renderActions({ onViewFile: handleViewFile })
            ) : (
              <>
                <IconButton
                  data-testid="view-file-btn"
                  tw="border border-border-primary mr-extra-small"
                  size="medium"
                  name="eye"
                  fill={theme`colors.icon-primary`}
                  onClick={handleViewFile}
                />
                {onDeleteClick && (
                  <IconButton
                    tw="border border-border-primary"
                    data-testid="delete"
                    size="medium"
                    name="bin"
                    fill={
                      !isNewThemeApplied
                        ? 'transparent'
                        : theme`colors.icon-primary`
                    }
                    stroke={
                      !isNewThemeApplied
                        ? theme`colors.icon-primary`
                        : 'undefined'
                    }
                    onClick={onDeleteClick}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const FileIcon: React.FC<{
  file: UploadedFileFormat | File | Document;
  hideIcon?: boolean;
  icon?: ReactNode;
}> = ({ file, hideIcon, icon }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  if (hideIcon) return null;

  if (icon) return <>{icon}</>;

  if (!isUploadedFile(file) || !file.contentType?.includes('image/')) {
    return (
      <Icon
        css={isNewThemeApplied && tw`w-extra-large h-extra-large`}
        name="file-empty"
      />
    );
  }

  const imageSrc = useMemo(() => {
    if (file?.link) return file.link;
    if (file?.url) return file.url;
    if (isDocumentType(file)) return (file as Document).downloadUrl ?? '';

    return URL.createObjectURL(file as File);
  }, [file]);

  return (
    <img
      tw="rounded"
      data-testid="image-preview"
      src={imageSrc}
      alt={file.name ?? ''}
      width="48"
    />
  );
};

export default FileUploadEntry;
