/** @jsxImportSource @emotion/react */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Trans } from 'react-i18next';

import { TFunction } from 'i18next';
import tw from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import { checkPartnerEorOrHrMember } from 'performance-reviews/services/partner-eor-and-hr-members';
import { Steps } from 'performance-reviews/utils';

import {
  CompanyUser,
  Contract,
  ContractType,
  PerformanceReview,
  PerformanceReviewStatus,
} from '__generated__/graphql';

interface StepsData {
  contract: Contract;
  signatory?: CompanyUser;
  cutOffDate?: string;
  status?: PerformanceReview['status'];
  payrollCycle?: PerformanceReview['payrollCycle'];
  effectiveDate?: PerformanceReview['effectiveDate'];
  isGlobalPayrollEnabled?: boolean;
  isParterEorOrHrMember?: boolean;
}

interface StepConfig {
  key: Steps;
  title: string;
  description: string | React.ReactNode;
  tooltip?: string | React.ReactNode;
  markAsDone?: boolean;
}

const BoldText = tw.span`font-semibold`;

const updateDetailsStep = (
  data: StepsData,
  t: TFunction<'performance-reviews'>,
): StepConfig => {
  const { isGlobalPayrollEnabled, contract } = data;

  return {
    key: Steps.UPDATE_DETAILS,
    title: t('steps.update-details.title', 'Update details'),
    description:
      isGlobalPayrollEnabled || contract?.type === ContractType.EMPLOYEE
        ? t(
            'steps.update-details.description',
            'Basic salary, job title, or additional Pay',
          )
        : null,
  };
};

const signatorySignsStep = (
  data: StepsData,
  t: TFunction<'performance-reviews'>,
): StepConfig => {
  const { signatory, cutOffDate } = data;

  const signatoryEmail = signatory?.emails?.[0]?.email ?? '';

  const signatoryName = `${signatory?.firstName ?? ''} ${
    signatory?.lastName ?? ''
  }`.trim();

  return {
    key: Steps.SIGNATORY_SIGNS,
    title: t('steps.signatory-signs.title', 'Signatory signs'),
    description: t(
      'steps.signatory-signs.description',
      'Send addendum to company signatory {{ signatoryName }} for signature',
      {
        replace: {
          signatoryName,
        },
      },
    ),
    tooltip: cutOffDate && (
      <Trans
        t={t}
        values={{
          signatoryEmail,
          cutOffDate,
        }}
        i18nKey="steps.signatory-signs.tooltip"
      >
        Company signatory <BoldText>{{ signatoryEmail }}</BoldText> should sign
        addendum before <BoldText>{{ cutOffDate }}</BoldText> for the salary
        revision to be effective.
      </Trans>
    ),
  };
};

const employeeSignsStep = (
  data: StepsData,
  t: TFunction<'performance-reviews'>,
): StepConfig => {
  const { contract, cutOffDate, status, payrollCycle } = data;

  const employeeEmail = contract?.member?.emails?.[0]?.email ?? '';

  const employeeName = `${contract?.member?.firstName ?? ''} ${
    contract?.member?.lastName ?? ''
  }`.trim();

  const markAsDone =
    [
      PerformanceReviewStatus.APPROVED,
      PerformanceReviewStatus.ACTIVATED,
    ].includes(status as PerformanceReviewStatus) && !payrollCycle;

  return {
    key: Steps.EMPLOYEE_SIGNS,
    title: t('steps.employee-signs.title', 'Employee signs'),
    description: t(
      'steps.employee-signs.description',
      'Employee {{name}} accepts & signs addendum',
      {
        replace: {
          name: employeeName,
        },
      },
    ),
    tooltip: employeeEmail && cutOffDate && (
      <Trans
        t={t}
        values={{
          employeeEmail,
          cutOffDate,
        }}
        i18nKey="steps.employee-signs.tooltip"
      >
        Employee <BoldText>{{ employeeEmail }}</BoldText> should sign addendum
        before <BoldText>{{ cutOffDate }}</BoldText> for the salary revision to
        be effective.
      </Trans>
    ),
    markAsDone,
  };
};

const confirmsDetailsStep = (
  data: StepsData,
  t: TFunction<'performance-reviews'>,
): StepConfig => {
  const {
    effectiveDate,
    payrollCycle,
    status,
    isGlobalPayrollEnabled,
    contract,
  } = data;

  const markAsDone =
    [
      PerformanceReviewStatus.SENT_TO_OPS,
      PerformanceReviewStatus.ACTIVATED,
    ].includes(status as PerformanceReviewStatus) && !payrollCycle;

  return {
    key: Steps.CONFIRM_DETAILS,
    title: t('steps.confirm-detail.title', 'Confirm details'),
    markAsDone,
    description:
      effectiveDate &&
      (isGlobalPayrollEnabled || contract?.type === ContractType.EMPLOYEE)
        ? t(
            'steps.confirm-detail.description-new',
            'The revision will be effective from {{ effectiveDate }}',
            {
              replace: {
                effectiveDate: dateAsString(effectiveDate, 'do MMMM yyyy'),
              },
            },
          )
        : null,
  };
};

const completedStep = (
  data: StepsData,
  t: TFunction<'performance-reviews'>,
): StepConfig => {
  const { status, isGlobalPayrollEnabled, contract } = data;

  return {
    key: Steps.COMPLETED,
    title: t('steps.completed.title', 'Completed'),
    description:
      isGlobalPayrollEnabled || contract?.type === ContractType.EMPLOYEE
        ? t(
            'steps.completed.description',
            'New salary will reflected based on payroll cut-off',
          )
        : null,
    markAsDone: status === PerformanceReviewStatus.ACTIVATED,
  };
};

export const getStepsConfig = (
  t: TFunction<'performance-reviews'>,
  data: StepsData,
): StepConfig[] => {
  const { contract } = data;

  const isParterEorOrHrMember = checkPartnerEorOrHrMember(contract);

  if (isParterEorOrHrMember) {
    return [updateDetailsStep, confirmsDetailsStep, completedStep].map((step) =>
      step.apply(this, [data, t]),
    );
  }

  return [
    updateDetailsStep,
    signatorySignsStep,
    employeeSignsStep,
    completedStep,
  ].map((step) => step.apply(this, [data, t]));
};
