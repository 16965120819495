/** @jsxImportSource @emotion/react */
import React, { useContext, useRef } from 'react';

import { useKey } from 'react-use';

import { ConfirmationDialog, Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

const TopContainer = tw.div`
  flex bg-background-primary h-[60px] items-center text-pl text-text-primary font-semibold px-extra-large justify-between rounded-tr-base rounded-tl-base
`;

export const FooterSection = tw.div`
  flex bg-background-white h-[78px] w-full items-center border-t border-border-primary sticky bottom-none rounded-bl-base rounded-br-base mt-large
`;

interface ContractReviewSectionModalProps {
  modalTitle: string;
  onClose: () => void;
}

const ContractReviewSectionModal: React.FC<
  React.PropsWithChildren<ContractReviewSectionModalProps>
> = ({ modalTitle, onClose, children, ...props }) => {
  const dialogRef = useRef(null);
  const { isNewThemeApplied } = useContext(ThemeContext);

  useKey('Escape', () => {
    onClose();
  });

  return (
    <ConfirmationDialog.Lightbox>
      <ConfirmationDialog.Container
        data-testid="review-section-modal"
        ref={dialogRef}
        tw="w-[740px] max-h-[740px] overflow-y-auto"
        {...props}
      >
        <TopContainer
          css={[
            tw`bg-background-secondary`,
            !isNewThemeApplied && tw`bg-grey05`,
          ]}
        >
          {modalTitle}
          <button type="button" tw="ml-base" onClick={onClose}>
            <Icon name="cross" fill={theme`colors.icon-tertiary`} />
          </button>
        </TopContainer>
        {children}
      </ConfirmationDialog.Container>
    </ConfirmationDialog.Lightbox>
  );
};

export default ContractReviewSectionModal;
