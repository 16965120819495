/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { Icon } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import { SorOnboardingContext } from '@multiplier/hris-member-management';
import { startOfDay } from 'date-fns';
import { theme } from 'twin.macro';

import { parseDate } from 'app/utils/format';
import {
  CutoffInterval,
  getCutoffInterval,
} from 'contract-onboarding/services/payroll-onboarding';

import { ContractType } from '__generated__/graphql';

export const payrollCutoffHintLabels = {
  [CutoffInterval.StartDateBeforeCutoffDate]: {
    key:
      'definition-phase.basic-details.current-date-and-employment-start-date-before-payroll-cutoff-date',
    defaultValue:
      'Note: Please ensure your employee completes their full onboarding before {{ joiningMonth }} {{ joiningDate }}, {{ joiningYear }}. The payroll cut-off date is on {{ joiningMonth }} {{ joiningMonthCutoffDate }}, {{ joiningYear }} failing which, the payroll will be processed in the {{ subsequentMonth }}, {{ subsequentYear }} payroll.',
  },
  [CutoffInterval.StartDateAfterCutoffDateButBefore20th]: {
    key:
      'definition-phase.basic-details.current-date-before-cutoff-date-employment-date-before-20th',
    defaultValue:
      'Note: Please ensure your employee completes their full onboarding before {{ joiningMonth }} {{ joiningMonthCutoffDate }}, {{ joiningYear }} to be included in the {{ joiningMonth }}, {{ joiningYear }} payroll. Otherwise, the payroll will be processed in the {{ subsequentMonth }}, {{ subsequentYear }} payroll.',
  },
  [CutoffInterval.StartDateAfter20thOfMonth]: {
    key:
      'definition-phase.basic-details.current-date-before-cutoff-date-employment-date-after-20th',
    defaultValue:
      'Note: Please ensure your employee completes their full onboarding before {{ joiningMonth }} {{ joiningDate }}, {{ joiningYear }}. The payroll cut-off date is on {{ joiningMonth }} {{ joiningMonthCutoffDate }}, {{ joiningYear }}. Since the employment start date is later than the cut-off date, the payroll will be processed in the {{ subsequentMonth }}, {{ subsequentYear }} payroll.',
  },
  [CutoffInterval.TodayAfterCutoffDateOfJoiningMonth]: {
    key:
      'definition-phase.basic-details.current-date-after-payroll-cutoff-date',
    defaultValue:
      'Note: Please ensure your employee completes their full onboarding before {{ joiningMonth }} {{ joiningDate }}, {{ joiningYear }}. This month payroll cut-off date is on {{ joiningMonth }} {{ joiningMonthCutoffDate }}, {{ joiningYear }}. Since the cut-off date passed, the payroll will be processed in the {{ subsequentMonth }}, {{ subsequentYear }} payroll.',
  },
};

export const OldPayrollCutoffDateHint: React.FC<{
  startDate: Date | string | null | undefined;
  contractType?: ContractType;
  sorOnboardingContext?: SorOnboardingContext;
}> = ({ contractType, startDate, sorOnboardingContext }) => {
  const { t } = useTranslation('contract-onboarding.common');

  if (useFeature(AppFeature.USE_PAYROLL_SERVICE_TO_GET_PAYROLL_CYCLES).on)
    return null;

  if (!startDate || sorOnboardingContext?.isHrisOnlyFlow) return null;

  const parsedStartDate = parseDate(startDate);

  const isBackDated = startOfDay(parsedStartDate) < startOfDay(new Date());

  if (
    isBackDated ||
    contractType === ContractType.FREELANCER ||
    contractType === ContractType.CONTRACTOR
  ) {
    return null;
  }

  const { interval, params } = getCutoffInterval(parseDate(parsedStartDate));

  const i18n = payrollCutoffHintLabels[interval];

  return (
    <div
      tw="flex -mt-extra-small"
      data-testid="start-date-before-payroll-cutoff-date"
    >
      <Icon
        name="circle-warning"
        fill={theme`colors.icon-primary`}
        tw="mr-extra-small w-48"
      />
      <div tw="text-text-primary text-ps">
        {t(i18n.key, {
          defaultValue: i18n.defaultValue,
          replace: params,
        })}
      </div>
    </div>
  );
};
