import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractType } from '__generated__/graphql';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingMember: ContractOnboardingStepConfig = {
  title: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.onboarding-member.contractor.title',
          'Request Details',
        )
      : t('onboarding-phase.onboarding-member.title', 'Collecting Details'),
  status: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.onboarding-member.contractor.status',
          'Pending on Contractor',
        )
      : t('onboarding-phase.onboarding-member.status', 'Pending on Employee'),
  variant: ProgressWidgetContentVariant.STATUSES,
};

export default onboardingMember;
