import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'calendar',
  permission: 'calendar',
  folder: 'calendar/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Calendar',
    route: 'calendar',
    sort: 1,
    home: false,
    group: CompanyNavGroups.COMPANY,
    hide: true,
  },
};

export default config;
