import { FetchResult } from '@apollo/client';

import {
  CreateManagerMutation,
  DeleteManagerMutation,
  useCreateManagerMutation,
  useDeleteManagerMutation,
  useGetCompanyBasicQuery,
} from '__generated__/graphql';

const useCreateAndDeleteManager = (): {
  creatingManager: boolean;
  deletingManager: boolean;
  createPeopleManager: (
    contractId: string,
  ) => Promise<FetchResult<CreateManagerMutation>>;
  deletePeopleManager: (
    managerId: string,
  ) => Promise<FetchResult<DeleteManagerMutation>>;
} => {
  const { data: companyData } = useGetCompanyBasicQuery();

  const companyId = companyData?.company?.id as string;

  const [
    createManager,
    { loading: creatingManager },
  ] = useCreateManagerMutation();

  const [
    deleteManager,
    { loading: deletingManager },
  ] = useDeleteManagerMutation();

  const createPeopleManager = async (contractId: string) =>
    createManager({
      variables: {
        input: {
          companyId,
          contractId,
        },
      },
    });

  const deletePeopleManager = async (managerId: string) =>
    deleteManager({
      variables: {
        input: {
          id: managerId,
        },
      },
    });

  return {
    creatingManager,
    deletingManager,
    createPeopleManager,
    deletePeopleManager,
  };
};

export default useCreateAndDeleteManager;
