/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import tw from 'twin.macro';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';
import ComboBox, { DropdownValue } from 'common/components/combo-box';
import DatePicker from 'common/components/date-picker';
import Radio from 'common/components/radio';
import TextInput from 'common/components/text-input';

import {
  Contract,
  DataFieldDefinition,
  DateField,
  DomainType,
  DropDownField,
  DropDownTextField,
  LegalDocumentRequirement,
  Maybe,
  Member,
  MemberChangeRequest,
  MemberNationalogy,
} from '__generated__/graphql';

import { haveSubmittedChangeRequestItem } from '../../services/change-request';
import {
  getFormattedValue,
  memberLegalDataMap,
} from '../../services/legal-data';
import { BasicDetailsFormValues } from '../basic-details-section';
import { DetailRowType } from '../detail-row/types';

const DetailRow = React.lazy(() => import('../detail-row'));

const LegalDetailsSection: React.FC<{
  requirements: {
    memberLegalDataRequirements: { [p: string]: DataFieldDefinition };
    memberDocumentRequirements:
      | Maybe<Array<Maybe<LegalDocumentRequirement>>>
      | undefined;
  };
  member: Contract['member'];
  isEditing: boolean;
  changeRequest?: Maybe<MemberChangeRequest>;
  changeRequestItemsByKey?: {
    firstName: Member['firstName'];
    lastName: Member['lastName'];
    fullLegalName: Member['fullLegalName'];
    gender: Member['gender'];
    dateOfBirth: Member['dateOfBirth'];
    nationality: Maybe<MemberNationalogy> | undefined;
    legalData: Member['legalData'];
  };
}> = ({
  requirements: { memberLegalDataRequirements },
  member,
  isEditing,
  changeRequest,
  changeRequestItemsByKey,
}) => {
  const { t } = useTranslation('contract-onboarding.member');
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const {
    control,
    formState: { errors },
    register,
    trigger,
    watch,
  } = useFormContext<BasicDetailsFormValues>();

  return (
    <>
      {Object.values(memberLegalDataRequirements)
        .filter(
          (val: DataFieldDefinition) =>
            val.domainType === DomainType.LEGAL_DATA || !val.domainType,
        )
        .map((legalData, index) => {
          if (!legalData?.key) return null;

          const legalDataMap =
            member?.legalData && memberLegalDataMap(member.legalData);

          const formValue = legalData?.key
            ? legalDataMap?.[legalData.key]?.value
            : null;

          const formIdentifier = legalData?.key
            ? legalDataMap?.[legalData.key]?.identifier
            : null;

          const disabled = !(
            memberLegalDataRequirements[legalData.key]?.editable ?? true
          );

          // dependsOn can only be validated on CheckboxFields at the moment
          if (
            legalData.dependsOn?.some(
              (controllingField) =>
                controllingField?.key &&
                watch('legalData')?.find(
                  (fieldElement) => fieldElement?.key === controllingField?.key,
                )?.value !== 'true',
            )
          )
            return null;

          return (
            <React.Fragment key={legalData?.key}>
              {legalData?.key && legalData?.key !== 'dateOfBirth' && (
                <DetailRow
                  id={legalData?.key}
                  label={
                    formIdentifier
                      ? t(
                          `legal-details.${formIdentifier}.label`,
                          formIdentifier,
                        )
                      : t(
                          `legal-details.${legalData?.label}.label`,
                          legalData?.label ?? '',
                        )
                  }
                  isEditing={
                    (current === Experience.MEMBER ||
                      current === Experience.COMPANY) &&
                    isEditing
                  }
                  type={
                    haveSubmittedChangeRequestItem(changeRequest)
                      ? DetailRowType.COMPARE_MODE
                      : DetailRowType.BASIC_MODE
                  }
                  value={getFormattedValue(formValue, legalData, t)}
                  newValue={getFormattedValue(
                    changeRequestItemsByKey?.legalData?.find(
                      (field) => field?.key === legalData.key,
                    )?.value,
                    legalData,
                    t,
                  )}
                >
                  <TextInput.Container
                    key={legalData?.key ?? ''}
                    tw="col-span-3"
                  >
                    {legalData.dataType?.__typename === 'DateField' &&
                      legalData?.key !== 'dateOfBirth' && (
                        <Controller
                          control={control}
                          name={`legalData.${index}.value`}
                          defaultValue={formValue}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              tw="w-1/2"
                              data-testid={String(legalData?.key)}
                              id="date-input"
                              value={value}
                              onChange={(val) => {
                                if (val === '') onChange(undefined);
                                else onChange(val);
                              }}
                              min={
                                (legalData.dataType as DateField).minDate ??
                                undefined
                              }
                              max={
                                (legalData.dataType as DateField).maxDate ??
                                undefined
                              }
                              error={!!errors?.legalData?.[index]?.value}
                              helperText={
                                errors?.legalData?.[index]?.value?.message
                              }
                              loading={false}
                              disabled={disabled}
                            />
                          )}
                        />
                      )}
                    {legalData.dataType?.__typename === 'DropDownField' && (
                      <Controller
                        control={control}
                        name={`legalData.${index}.value`}
                        defaultValue={formValue}
                        render={({ field: { value, onChange } }) => (
                          <ComboBox
                            variant="default"
                            tw="w-1/2"
                            value={value ?? ''}
                            onChange={onChange}
                            data-testid={legalData.key}
                            placeholder={t(
                              `legal-details.${legalData.label}.placeholder`,
                              legalData.label ?? '',
                            )}
                            dropdownValues={
                              ((legalData.dataType as DropDownField).values?.map(
                                (dropdownValue) => ({
                                  title: t(
                                    `legal-details.${
                                      legalData.label
                                    }.option.${dropdownValue?.toLowerCase()}`,
                                    dropdownValue ?? '',
                                  ),
                                  value: dropdownValue,
                                }),
                              ) as DropdownValue[]) ?? []
                            }
                            disabled={disabled}
                          />
                        )}
                      />
                    )}
                    {legalData.dataType?.__typename === 'TextField' && (
                      <TextInput
                        id={legalData.key ?? ''}
                        data-testid={legalData.key ?? ''}
                        defaultValue={formValue ?? ''}
                        placeholder={t(
                          'legal-details.numeric.number-placeholder',
                          'Add Number',
                        )}
                        {...register(`legalData.${index}.value`)}
                        onChange={(e) => {
                          register(`legalData.${index}.value`).onChange(e);
                          trigger(`legalData.${index}`);
                        }}
                        error={!!errors?.legalData?.[index]?.value?.message}
                        disabled={disabled}
                      />
                    )}
                    {legalData.dataType?.__typename === 'DropDownTextField' && (
                      <div tw="flex flex-row gap-x-extra-small">
                        <Controller
                          control={control}
                          name={`legalData.${index}.description`}
                          render={({ field: { value, onChange } }) => (
                            <ComboBox
                              variant="default"
                              tw="h-6"
                              value={value ?? ''}
                              onChange={onChange}
                              data-testid={legalData.key}
                              placeholder={t(
                                `legal-details.${legalData.label}.placeholder`,
                                'Select from List',
                              )}
                              dropdownValues={
                                ((legalData.dataType as DropDownTextField).values?.map(
                                  (dropdownValue) => ({
                                    title: t(
                                      `legal-details.${
                                        legalData.label
                                      }.option.${dropdownValue?.toLowerCase()}`,
                                      dropdownValue ?? '',
                                    ),
                                    value: dropdownValue,
                                  }),
                                ) as DropdownValue[]) ?? []
                              }
                              disabled={disabled}
                            />
                          )}
                        />
                        <TextInput
                          divStyles={tw`flex-grow`}
                          id={legalData.key ?? ''}
                          data-testid={legalData.key ?? ''}
                          defaultValue={formIdentifier ?? formValue ?? ''}
                          placeholder={t(
                            `legal-details.${legalData.key}.placeholder`,
                            'Add Number',
                          )}
                          {...register(`legalData.${index}.value`)}
                          onChange={(e) => {
                            register(`legalData.${index}.value`).onChange(e);
                            trigger(`legalData.${index}`);
                          }}
                          error={!!errors?.legalData?.[index]?.value?.message}
                          disabled={disabled}
                        />
                      </div>
                    )}
                    {legalData.dataType?.__typename === 'CheckboxField' && (
                      <div tw="flex flex-row gap-x-base">
                        <Radio
                          id={legalData.key ?? ''}
                          labelStyles={tw`py-small flex-grow`}
                          value="true"
                          {...register(`legalData.${index}.value`)}
                          disabled={disabled}
                        >
                          {t('legal-details.checkbox.yes', 'Yes')}
                        </Radio>
                        <Radio
                          id={legalData.key ?? ''}
                          labelStyles={tw`py-small flex-grow`}
                          value="false"
                          {...register(`legalData.${index}.value`)}
                          disabled={disabled}
                        >
                          {t('legal-details.checkbox.no', 'No')}
                        </Radio>
                      </div>
                    )}
                    {errors.legalData?.[index] && (
                      <TextInput.Error tw="mb-base">
                        {errors.legalData[index]?.value?.message}
                      </TextInput.Error>
                    )}
                  </TextInput.Container>
                </DetailRow>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default LegalDetailsSection;
