import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { HighlightType } from '@multiplier/common';
import {
  addDays,
  addYears,
  eachDayOfInterval,
  eachWeekendOfInterval,
  isBefore,
  isSameDay,
  startOfMonth,
} from 'date-fns';
import flow from 'lodash/fp/flow';
import uniqWith from 'lodash/fp/uniqWith';

import { Contract, ContractType } from '__generated__/graphql';

import { ParsedHoliday } from '../../hooks/startdate-limit';

const useStartDateFieldHighlights = (
  limitedHolidays: ParsedHoliday[],
  minDate: Date | undefined,
  visaCheckpointDate: Date | undefined,
  contractType: Contract['type'],
): HighlightType[] => {
  const { t } = useTranslation('contract-onboarding.common');

  const getWeekendHighlights = (currentDate: Date) =>
    eachWeekendOfInterval({
      start: startOfMonth(currentDate),
      end: addYears(currentDate, 1),
    }).map((weekend) => ({
      date: weekend,
      hover: {
        content: t(
          'start-date.weekend-info',
          'This date cannot be selected as it is a weekend',
        ),
      },
    }));

  const getHolidayHighlights = () =>
    limitedHolidays.map((holiday) => ({
      date: holiday.date,
      hover: { content: holiday.holiday },
    }));

  const getBeforeCurrentDateHighlights = (currentDate: Date) => {
    if (contractType !== ContractType.EMPLOYEE) return [];

    if (isSameDay(startOfMonth(currentDate), currentDate)) return [];

    return eachDayOfInterval({
      start: startOfMonth(currentDate),
      end: addDays(currentDate, -1),
    }).map((beforeDates) => ({
      date: beforeDates,
      hover: {
        content: t('start-date.before-date', 'Backdating is unavailable'),
      },
    }));
  };

  const visaWorkflowEnabled = useFeature('contract.onboarding.visa-workflow')
    ?.on;
  const getVisaTatDateHighlights = (currentDate: Date) =>
    visaCheckpointDate &&
    visaWorkflowEnabled &&
    isBefore(currentDate, addDays(visaCheckpointDate, -1))
      ? eachDayOfInterval({
          start: currentDate,
          end: addDays(visaCheckpointDate, -1),
        }).map((visaTatDate) => ({
          date: visaTatDate,
          hover: {
            content: t(
              'start-date.visa-tat-date-info',
              'This date is not available due to VISA application timelines',
            ),
          },
        }))
      : [];

  const minDateIsVisaCheckPointDate =
    minDate && visaCheckpointDate && isSameDay(minDate, visaCheckpointDate);
  const getPreregistrationDateHighlights = (currentDate: Date) =>
    minDate &&
    !minDateIsVisaCheckPointDate &&
    isBefore(currentDate, addDays(minDate, -1))
      ? eachDayOfInterval({
          start: visaWorkflowEnabled
            ? visaCheckpointDate ?? currentDate
            : currentDate,
          end: addDays(minDate, -1),
        }).map((preregistrationDate) => ({
          date: preregistrationDate,
          hover: {
            content: t(
              'start-date.pre-registration-date-info',
              'This date is not available due to pre-registration timelines',
            ),
          },
        }))
      : [];

  const isContractor = contractType === ContractType.CONTRACTOR;
  const isFreelancer = contractType === ContractType.FREELANCER;

  const startDateFieldHighlights = useMemo(() => {
    if (isContractor || isFreelancer) return [];

    const currentDate = new Date();

    const holidays = getHolidayHighlights();

    const weekends = getWeekendHighlights(currentDate);

    const beforeCurrentDate = getBeforeCurrentDateHighlights(currentDate);

    const visaTatDates = getVisaTatDateHighlights(currentDate);

    const preregistrationDates = getPreregistrationDateHighlights(currentDate);

    return flow(
      uniqWith<HighlightType>((value, other) =>
        isSameDay(value.date as Date, other.date as Date),
      ),
    )([
      ...holidays,
      ...beforeCurrentDate,
      ...visaTatDates,
      ...preregistrationDates,
      ...weekends,
    ]);
  }, [minDate, limitedHolidays]);

  return startDateFieldHighlights;
};

export default useStartDateFieldHighlights;
