import { gql } from '@apollo/client';

export default gql`
  mutation UpdateMemberPrimaryEmail($id: ID!, $input: EmailAddressInput!) {
    memberChangePrimaryEmail(id: $id, email: $input) {
      id
      emails {
        type
        email
      }
    }
  }
`;
