import React, { useContext } from 'react';

import tw, { styled } from 'twin.macro';

import Card from '../card';
import Loader from '../loader';
import { ThemeContext } from '../theme-provider';

interface WidgetLayoutProps {
  header: string;
  headerCount?: number | string | null;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  actionItem?: React.ReactNode;
  loading?: boolean;
}

const WidgetLayout: React.FC<React.PropsWithChildren<WidgetLayoutProps>> = ({
  icon,
  header,
  description,
  actionItem,
  children,
  loading = false,
  headerCount,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const headerText = headerCount ? `${header} (${headerCount})` : header;

  if (loading) {
    return (
      <WidgetWrapper>
        <Loader.Widget />
      </WidgetWrapper>
    );
  }

  return (
    <WidgetWrapper>
      <Card
        css={[
          tw`px-large py-large divide-y divide-border-primary`,
          !isNewThemeApplied && tw`shadow-none`,
        ]}
      >
        <HeaderWrapper>
          <Header>
            {icon || null}

            <div>
              <HeaderText isNewThemeApplied={isNewThemeApplied}>
                {headerText}
              </HeaderText>
              {description && <DescriptionText>{description}</DescriptionText>}
            </div>
          </Header>
          {actionItem}
        </HeaderWrapper>
        <Content {...props}>{children}</Content>
      </Card>
    </WidgetWrapper>
  );
};

export default WidgetLayout;

const WidgetWrapper = tw.div`max-width[473px]`;
const HeaderWrapper = tw.div`flex justify-between items-center pb-large`;
const Header = tw.div`flex items-center gap-x-small`;
const HeaderText = styled.h6<{ isNewThemeApplied: boolean }>(
  ({ isNewThemeApplied }) => [
    tw`text-text-primary font-semibold`,
    isNewThemeApplied && tw`text-pl`,
  ],
);
const Content = tw.div`pt-base`;
const DescriptionText = tw.p`text-ps text-text-tertiary`;
