/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useFeature } from '@growthbook/growthbook-react';
import {
  Callout,
  CalloutVariant,
  CardSeparator,
  Icon,
  ThemeContext,
} from '@multiplier/common';
import { userVar } from '@multiplier/user';
import tw, { theme } from 'twin.macro';

import { Experience } from 'app/models/module-config';
import { assureNumber } from 'app/utils/number';
import OnboardingPrimaryCard from 'contract-onboarding/components/onboarding-primary-card';
import OnboardingAction from 'contract-onboarding/models/onboarding-action';
import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import {
  BlockSource,
  Contract,
  ContractAgreementType,
  ContractDocumentWorkflow,
  ContractOnboarding,
  ContractOnboardingStatus,
  ContractOnboardingStep,
  ContractType,
  CountryCompliance,
  CountryWorkStatus,
  Maybe,
  Member,
} from '__generated__/graphql';

import ViewRequestedChanges from '../contract-request-changes/view-requested-changes';
import PayrollExpect from '../payroll-expect';
import SorOnboardingActionBar from '../sor-onboarding-action-bar';
import ConfirmAndContinueAction from './confirm-and-continue';
import ConfirmContractPreparationAction from './confirm-contract-preparation';
import RequestDetailsAction from './request-details-action';
import ReviseContractAction from './revise-contract-action';
import ViewDraftAction from './view-draft-action';
import ViewSendContractAction from './view-send-contract-action';
import ViewStepsAction from './view-steps-action';

const OnboardingStatusTracker: React.FC<{
  currentStatus: ContractOnboardingStatus | null | undefined;
  statuses: { status: ContractOnboardingStatus; label: string }[];
  index: number;
}> = ({ currentStatus, statuses, index }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const styleVariants = {
    done: {
      textStyle: tw`text-text-primary`,
      icon: (
        <div
          css={[
            tw`flex flex-row items-center rounded-full bg-background-tertiary w-large h-large flex-shrink-0`,
            !isNewThemeApplied && tw`bg-primary bg-opacity-15`,
          ]}
        >
          <Icon
            css={[
              tw`h-[20px] w-[18px] mx-auto`,
              !isNewThemeApplied && tw`h-[9px] w-[13px] mx-auto`,
            ]}
            name="check"
            fill={theme`colors.icon-primary`}
          />
        </div>
      ),
    },
    next: {
      textStyle: tw`text-text-primary font-semibold`,
      icon: (
        <Icon
          tw="h-large w-large"
          name="circle-thick"
          fill={isNewThemeApplied ? theme`colors.icon-tertiary` : 'transparent'}
          stroke={
            isNewThemeApplied
              ? theme`colors.icon-tertiary`
              : theme`colors.grey03`
          }
        />
      ),
    },
    future: {
      textStyle: isNewThemeApplied ? tw`text-text-tertiary` : tw`text-grey03`,
      icon: (
        <Icon
          tw="h-large w-large"
          name="circle-thin"
          fill={isNewThemeApplied ? theme`colors.icon-tertiary` : 'transparent'}
          stroke={
            isNewThemeApplied
              ? theme`colors.icon-tertiary`
              : theme`colors.grey03`
          }
        />
      ),
    },
  };

  const currentStatusIndex = useMemo(
    () =>
      statuses
        .map(({ status }, idx) => ({ status, idx }))
        .find(({ status }) => status === currentStatus)?.idx ?? -1,
    [currentStatus, statuses],
  );

  const done = useMemo(
    () =>
      Array.from(Array(currentStatusIndex + 1).keys()).some(
        (idx) => statuses[index]?.status === statuses[idx]?.status,
      ),
    [currentStatus, statuses, currentStatusIndex],
  );

  const next = useMemo(() => currentStatus === statuses[index - 1]?.status, [
    currentStatus,
    statuses,
  ]);

  return (
    <div data-testid="onboarding-status-tracker" tw="flex flex-row gap-x-large">
      <div tw="w-large flex justify-center place-items-center">
        {styleVariants[done ? 'done' : next ? 'next' : 'future'].icon}
      </div>
      <span
        css={styleVariants[done ? 'done' : next ? 'next' : 'future'].textStyle}
      >
        {statuses[index].label}
      </span>
    </div>
  );
};

const ProgressWidgetContent: React.FC<{
  memberName: Member['firstName'];
  memberEmail?: NonNullable<NonNullable<Member['emails']>[number]>['email'];
  currentStatus: ContractOnboarding['status'];
  variant: ProgressWidgetContentVariant;
  description: string | EmotionJSX.Element;
  primaryAction?: OnboardingAction;
  optionalAction?: OnboardingAction;
  statuses?: { status: ContractOnboardingStatus; label: string }[];
  steps?: Maybe<Maybe<ContractOnboardingStep>[]> | undefined;
  contractId?: Maybe<string>;
  compliance?: Contract['compliance'];
  showAdditionalPaysWarning?: boolean;
  uncommittedChanges?: boolean;
  workflowUpdating?: boolean;
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
  requiredDocuments?: CountryCompliance['memberDocumentRequirements'];
  contractBackdated?: boolean;
  allowRevoke?: boolean;
  contractDocumentWorkflowStatus?: ContractDocumentWorkflow['currentStatus'];
  contractBlocked?: Contract['contractBlocked'];
  unresolvedBlockEvents?: Contract['unresolvedBlockEvents'];
  depositPayables?: Contract['depositPayable'];
  activationCutoff: ContractOnboarding['activationCutoff'];
  contractType?: Contract['type'];
  workStatus?: Contract['workStatus'];
  legalEntityId?: Contract['legalEntityId'];
  currentOnboardingStep?: ContractOnboardingStep;
}> = ({
  memberName,
  memberEmail,
  currentStatus,
  variant,
  description,
  primaryAction,
  optionalAction,
  statuses,
  contractId,
  compliance,
  showAdditionalPaysWarning,
  uncommittedChanges,
  workflowUpdating = false,
  toggleWorkflowUpdating,
  contractBackdated,
  allowRevoke,
  requiredDocuments,
  contractDocumentWorkflowStatus,
  contractBlocked,
  unresolvedBlockEvents,
  depositPayables,
  activationCutoff,
  contractType,
  workStatus,
  legalEntityId,
  currentOnboardingStep,
}) => {
  const doesContractRequiresVisa =
    useFeature('contract.onboarding.visa-workflow').on &&
    workStatus === CountryWorkStatus.REQUIRES_VISA;
  const isHRMemberWithSorOnboarding =
    contractType === ContractType.HR_MEMBER &&
    useFeature('contract.onboarding.sor-onboarding').on;
  const { t } = useTranslation('contract-onboarding.company');

  // This is done to hide action buttons based on special conditions
  // Ideally this should not happen but here in this scenario we are using the same onboarding steps again
  // The solution to this is BE should send in new steps for every major contract status change so that status-config can handle all actions
  const showContractRevokeButton =
    compliance?.contractAgreementType ===
      ContractAgreementType.MULTIPLIER_TEMPLATE &&
    contractType !== ContractType.CONTRACTOR;

  const primaryActions: { [key: string]: React.ReactElement } = {
    [OnboardingAction.REVIEW_CONTRACT]: (
      <ViewSendContractAction
        contractId={contractId}
        memberName={memberName}
        memberEmail={memberEmail}
        uncommittedChanges={uncommittedChanges}
        toggleWorkflowUpdating={toggleWorkflowUpdating}
        contractBackdated={contractBackdated}
        contractBlocked={contractBlocked ?? false}
        contractType={contractType}
      />
    ),
    [OnboardingAction.CONFIRM]: (
      <ConfirmAndContinueAction
        contractId={contractId}
        toggleWorkflowUpdating={toggleWorkflowUpdating}
      />
    ),
    [OnboardingAction.INVITE]: (
      <RequestDetailsAction
        contractId={contractId}
        contractType={contractType}
        legalEntityId={legalEntityId}
      />
    ),
    [OnboardingAction.CONFIRM_CONTRACT]: (
      <ConfirmContractPreparationAction
        contractId={contractId}
        contractType={contractType}
        contractBackdated={contractBackdated}
      />
    ),
  };

  const optionalActions: { [key: string]: React.ReactElement | null } = {
    [OnboardingAction.VIEW_DRAFT]: (
      <ViewDraftAction contractId={contractId} compliance={compliance} />
    ),
  };

  const hasContractChangeRequest =
    unresolvedBlockEvents?.filter(
      (blockEvent) => blockEvent.source === BlockSource.CONTRACT_CHANGE_REQUEST,
    )?.[0]?.source === BlockSource.CONTRACT_CHANGE_REQUEST;

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  return (
    <>
      <OnboardingPrimaryCard.Content>
        <div
          css={[
            tw`flex flex-row gap-x-large`,
            variant === ProgressWidgetContentVariant.ANIMATION &&
              tw`items-center`,
          ]}
        >
          {variant === ProgressWidgetContentVariant.STATUSES &&
            statuses &&
            !(
              isHRMemberWithSorOnboarding &&
              currentOnboardingStep === ContractOnboardingStep.ONBOARDING_MEMBER
            ) && (
              <div
                data-testid="status-tracker"
                tw="flex flex-col gap-y-base flex-shrink-0"
              >
                {statuses.map(({ status }, index) => (
                  <OnboardingStatusTracker
                    key={status}
                    currentStatus={currentStatus}
                    index={index}
                    statuses={statuses}
                  />
                ))}
              </div>
            )}
          {contractBlocked && hasContractChangeRequest && (
            <ViewRequestedChanges
              unresolvedBlockEvents={unresolvedBlockEvents}
              contractId={contractId}
            />
          )}
          {!contractBlocked && (
            <div tw="flex flex-col gap-y-base text-ps whitespace-pre-wrap">
              <span>{description}</span>
              {showAdditionalPaysWarning && (
                <Callout variant={CalloutVariant.WARNING}>
                  {t(
                    'onboarding-phase.progress-widget-content.additional-pays-warning',
                    'Please re-check the compensation section before sending the contract.',
                  )}
                </Callout>
              )}
            </div>
          )}
        </div>
        {variant === ProgressWidgetContentVariant.EXPECT &&
          assureNumber(requiredDocuments?.length) > 0 && (
            <>
              <CardSeparator />
              <PayrollExpect />
            </>
          )}
        {isHRMemberWithSorOnboarding &&
          currentOnboardingStep ===
            ContractOnboardingStep.ONBOARDING_MEMBER && (
            <div>
              <CardSeparator />
              <SorOnboardingActionBar currentStatus={currentStatus} />
            </div>
          )}
      </OnboardingPrimaryCard.Content>
      {((primaryAction && primaryActions[primaryAction]) ||
        (optionalAction && optionalActions[optionalAction])) && (
        <>
          <CardSeparator />
          <OnboardingPrimaryCard.ActionBar>
            {optionalAction && optionalActions[optionalAction]}
            {primaryAction && primaryActions[primaryAction]}
          </OnboardingPrimaryCard.ActionBar>
        </>
      )}
      {allowRevoke && showContractRevokeButton && (
        <>
          <CardSeparator />
          <ReviseContractAction
            contractId={contractId}
            contractType={contractType}
            workflowUpdating={workflowUpdating}
            toggleWorkflowUpdating={toggleWorkflowUpdating}
            currentOnboardingStatus={currentStatus}
            contractDocumentWorkflowStatus={contractDocumentWorkflowStatus}
            depositPayables={depositPayables}
          />
        </>
      )}
      {current === Experience.COMPANY &&
        contractType === ContractType.EMPLOYEE &&
        !doesContractRequiresVisa && (
          <>
            <CardSeparator />
            <ViewStepsAction
              contractId={contractId}
              workflowUpdating={workflowUpdating}
              activationCutoff={activationCutoff}
              workStatus={workStatus}
            />
          </>
        )}
    </>
  );
};

export default ProgressWidgetContent;
