/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React from 'react';

import { hasKey, includesIfNotEmpty } from '../../helper';
import {
  ComparableCompoundDetailRowProps,
  ComparableDetailRowProps,
  combineKeys,
  isCompoundDetailRowValueType,
  isDetailRowValueType,
  isEmptyValue,
} from '../../types';

const ComparableSingleRow = React.lazy(
  () => import('../comparable-single-row'),
);

const ComparableDetailRow: React.FC<ComparableDetailRowProps> = ({
  id,
  label,
  hideLabel = false,
  labelStyles,
  value,
  renderValue,
  newValue,
  renderNewValue,
  ...otherProps
}) => {
  if (isEmptyValue(value) && isEmptyValue(newValue)) return null;

  if (isDetailRowValueType(value) && isDetailRowValueType(newValue))
    return (
      <ComparableSingleRow
        id={id}
        label={label}
        value={value}
        newValue={newValue}
        hideLabel={hideLabel}
        labelStyles={labelStyles}
        renderValue={renderValue as React.ReactElement}
        renderNewValue={renderNewValue as React.ReactElement}
      />
    );

  if (
    !isCompoundDetailRowValueType(value) ||
    !isCompoundDetailRowValueType(newValue)
  )
    return null;

  const { ignoreKeys } = otherProps as ComparableCompoundDetailRowProps;

  return (
    <>
      {combineKeys(value, newValue)
        .filter((key) => !includesIfNotEmpty(ignoreKeys, key))
        .filter(
          (key) =>
            !isEmptyValue(value?.[key]) || !isEmptyValue(newValue?.[key]),
        )
        .map((childKey) => (
          <ComparableSingleRow
            id={`${id}.${childKey}`}
            key={`${id}.${childKey}`}
            label={hasKey(label, childKey) ? label[childKey] : undefined}
            value={value?.[childKey] || ''}
            renderValue={
              (renderValue as Record<string, React.ReactElement>)?.[childKey]
            }
            newValue={newValue?.[childKey]}
            renderNewValue={
              (renderNewValue as Record<string, React.ReactElement>)?.[childKey]
            }
            hideLabel={hideLabel}
            labelStyles={labelStyles}
          />
        ))}
    </>
  );
};

export default ComparableDetailRow;
