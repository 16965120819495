/** @jsxImportSource @emotion/react */
import { format, isBefore } from 'date-fns';
import { TFunction } from 'i18next';
import capitalize from 'lodash/capitalize';
import 'twin.macro';

import { InsuranceMemberDetails } from 'insurance/member/pages/active-insurance';

import {
  Benefit,
  BenefitDocumentType,
  BenefitPartnerBillingFrequency,
  BenefitPremiumFrequency,
  BenefitType,
  ContractBenefit,
  ContractBenefitStatus,
  CountryCode,
  InsuranceIndividualPremium,
  Maybe,
} from '__generated__/graphql';

export const BenefitTypeMap = {
  [ContractBenefitStatus.ACTIVE]: 'Active',
  [ContractBenefitStatus.NEW]: 'New',
  [ContractBenefitStatus.ONBOARDING]: 'Onboarding',
  [ContractBenefitStatus.OFFBOARDED]: 'Offboarded',
  [ContractBenefitStatus.DEACTIVATED]: 'Deactivated',
  [ContractBenefitStatus.OPTED_OUT]: 'Opted-Out',
};

export const BenefitDocumentTypeMap = {
  [BenefitDocumentType.FACT_SHEET]: 'Factsheet',
  [BenefitDocumentType.INSURANCE_DOCUMENT]: 'Insurance Document',
  [BenefitDocumentType.INSURANCE_COMPARISON_DOCUMENT]:
    'Insurance Comparison Document',
};

export const premiumFrequencyLabelShort: {
  [key: string]: (t: TFunction) => string;
} = {
  [BenefitPremiumFrequency.ANNUALLY]: (t) =>
    t('premium-frequency.annually-short', 'yr'),
  [BenefitPremiumFrequency.MONTHLY]: (t) => t('premium.monthly-short', 'mo'),
};

export const benefitDocumentTypeLabels: {
  [key: string]: {
    title: (t: TFunction) => string;
    description: (t: TFunction) => string;
  };
} = {
  [BenefitDocumentType.FACT_SHEET]: {
    title: (t) => t('benefit.document-factsheet-title', 'Factsheet'),
    description: (t) =>
      t(
        'benefit.document-factsheet-description',
        'Your benefits and coverage details are mentioned in the Factsheet',
      ),
  },
  [BenefitDocumentType.INSURANCE_DOCUMENT]: {
    title: (t) =>
      t('benefit.document-insurance.document-title', 'Step by step Guide'),
    description: (t) =>
      t(
        'benefit.document-insurance.document-description',
        'In order to claim your Insurance, please follow the steps in the Onboarding Kit',
      ),
  },
  [BenefitDocumentType.INSURANCE_COMPARISON_DOCUMENT]: {
    title: (t) =>
      t(
        'benefit.document-insurance.comparison.document-title',
        'Insurance Comparison Document',
      ),
    description: (t) =>
      t(
        'benefit.document-insurance.comparison.document-description',
        'Your benefits and coverage are mentioned in the Factsheet',
      ),
  },
};

export const premiumFrequencyLabel: {
  [key: string]: (t: TFunction) => string;
} = {
  [BenefitPartnerBillingFrequency.ANNUALLY]: (t) =>
    t('premium-frequency.annually', 'year'),
  [BenefitPartnerBillingFrequency.MONTHLY]: (t) =>
    t('premium.monthly', 'month'),
};

export const benefitPartnerBillingFrequencyLabel: {
  [key: string]: (t: TFunction) => string;
} = {
  [BenefitPartnerBillingFrequency.ANNUALLY]: (t) =>
    t('benefit-partner-billing-frequency.annually', 'annually'),
  [BenefitPartnerBillingFrequency.MONTHLY]: (t) =>
    t('benefit-partner-billing-frequency.month', 'monthly'),
};

export const getDownloadUrl = ({
  benefit,
  type,
}: {
  benefit?: Benefit | null;
  type: BenefitDocumentType;
}): string =>
  benefit?.benefitDocuments?.find((doc) => doc?.benefitDocumentType === type)
    ?.files?.[0]?.downloadUrl ?? '';

export const getInsuranceDuration = ({
  startOn,
  endOn,
}: {
  startOn?: string | null;
  endOn?: string | null;
}): string | null =>
  startOn && endOn
    ? `${format(new Date(startOn), 'dd MMM yyyy')} - ${format(
        new Date(endOn),
        'dd MMM yyyy',
      )}`
    : null;

export const getDependentsList = (
  contractBenefit: ContractBenefit | undefined | null,
): InsuranceMemberDetails[] =>
  contractBenefit?.dependents?.map((dependent) => ({
    name: `${capitalize(dependent?.firstName ?? '')} ${capitalize(
      dependent?.lastName ?? '',
    )}`,
    dob: dependent?.dateOfBirth,
    gender: dependent?.gender,
    relation: dependent?.relationshipToMember,
    healthId: dependent?.externalPolicyId,
    insuranceDuration: getInsuranceDuration({
      startOn: dependent?.startOn,
      endOn: dependent?.endOn,
    }),
    premium: dependent?.benefitPackageCost?.premium,
    premiumFrequency: dependent?.benefitPackageCost?.premiumFrequency,
    premiumCurrency: dependent?.benefitPackageCost?.premiumCurrency,
  })) ?? [];

export const getIsDependentStartDateDiffFromMember = (
  contractBenefit: ContractBenefit | undefined | null,
): boolean =>
  contractBenefit?.startOn &&
  contractBenefit?.dependents?.some(
    (d) => d?.startOn && isBefore(d?.startOn, contractBenefit?.startOn),
  );

export const getDependentSubTotal = (
  dependentInsurancePremiums:
    | Maybe<Maybe<InsuranceIndividualPremium>[]>
    | undefined,
): number =>
  dependentInsurancePremiums?.reduce(
    (prev, curr) => prev + (curr?.subTotalAmount ?? 0),
    0,
  ) ?? 0;

export const getContributionsTitle = ({
  billingDuration,
  t,
}: {
  billingDuration: BenefitPartnerBillingFrequency;
  t: TFunction<'insurance', undefined>;
}): string =>
  billingDuration === BenefitPartnerBillingFrequency.MONTHLY
    ? t(
        'active-insurance.main.premium-breakdown-main-monthly-label',
        'Monthly Cost of Insurance:',
      )
    : t(
        'active-insurance.main.premium-breakdown-main-label',
        'Annual Cost of Insurance:',
      );

export const getCoverDetails = ({
  insuranceType,
  maxDependentCount,
  country,
  t,
}: {
  insuranceType: BenefitType | null | undefined;
  maxDependentCount: number;
  country: CountryCode | null | undefined;
  t: TFunction;
}): string => {
  if (!insuranceType) return '--';

  if (insuranceType === BenefitType.INSURANCE) {
    if (maxDependentCount) {
      return t(`cover-detail-suffix.${country}-individual.label`, {
        defaultValue: ' + {{maxDependentCount}} Dependents',
        replace: {
          maxDependentCount,
        },
      });
    }
    return t(`cover-detail-suffix.${country}-no-dependent.label`, '');
  }

  return t(
    `insurance-type-select.${insuranceType}.${country}.description`,
    'Employee + Spouse + 2 children',
  );
};
