import { Experience } from 'app/models/module-config';

import { ContractOnboardingStep } from '../../../../__generated__/graphql';
import i18n from '../../../../i18n';
import { StepConfig } from '../../../components/step-navigation-footer';
import routes from '../../routes';
import MemberStart from '../start';
import MemberStartVisa from '../startVisa';
import MemberVerification from '../verification';
import BlockPage from './BlockPage';
import BankDetails from './pages/bank-details';
import BasicDetails from './pages/basic-details';
import InsuranceDetails from './pages/insurance-details';
import LegalDetails from './pages/legal-details';
import LegalDocuments from './pages/legal-documents';
import VisaDocuments from './pages/visa-eligibility-documents';

const generateRelativeLink = (phase?: string, step?: string): string =>
  `${phase ? `${phase}/` : ''}${step ?? ''}`;

export const generateDirectLink = (phase?: string, step?: string): string =>
  `/${Experience.MEMBER}/${routes.root}/${generateRelativeLink(phase, step)}`;

const stepConfig: { [key: string]: StepConfig } = {
  [ContractOnboardingStep.MEMBER_WELCOME]: {
    route: (): string => routes.root,
    label: () => '',
    view: MemberStart,
    directLink: () => generateDirectLink(),
    relativeLink: () => '',
  },
  [ContractOnboardingStep.MEMBER_WELCOME_VISA]: {
    route: (): string => routes.visa.root,
    label: () => '',
    view: MemberStartVisa,
    directLink: () => generateDirectLink(routes.visa.root),
    relativeLink: () => generateRelativeLink(routes.visa.root),
  },
  [ContractOnboardingStep.MEMBER_VISA_ELIGIBILITY]: {
    route: (): string => routes.visa.visaEligibility,
    label: () => '',
    view: VisaDocuments,
    directLink: () =>
      generateDirectLink(routes.visa.root, routes.visa.visaEligibility),
    relativeLink: () =>
      generateRelativeLink(routes.visa.root, routes.visa.visaEligibility),
  },
  [ContractOnboardingStep.MEMBER_VISA_APPLICATION]: {
    route: (): string => routes.visa.visaApplication,
    label: () => '',
    view: BlockPage,
    directLink: () =>
      generateDirectLink(routes.visa.blockPage, routes.visa.visaApplication),
    relativeLink: () =>
      generateRelativeLink(routes.visa.blockPage, routes.visa.visaApplication),
  },
  [ContractOnboardingStep.MEMBER_BASIC_DETAILS]: {
    route: (): string => routes.onboarding.basicDetails,
    label: () =>
      i18n.t(
        'contract-onboarding.member:basic-details.node-title',
        'Basic Details',
      ),
    view: BasicDetails,
    directLink: () =>
      generateDirectLink(
        routes.onboarding.toString(),
        routes.onboarding.basicDetails,
      ),
    relativeLink: () =>
      generateRelativeLink(
        routes.onboarding.toString(),
        routes.onboarding.basicDetails,
      ),
  },
  [ContractOnboardingStep.MEMBER_VISA_APPROVED]: {
    route: (): string => routes.visa.visaApproved,
    label: () => '',
    view: BlockPage,
    directLink: () =>
      generateDirectLink(routes.visa.blockPage, routes.visa.visaApproved),
    relativeLink: () =>
      generateRelativeLink(routes.visa.blockPage, routes.visa.visaApproved),
  },
  [ContractOnboardingStep.MEMBER_BANK_DETAILS]: {
    route: (): string => routes.onboarding.bankDetails,
    label: () =>
      i18n.t(
        'contract-onboarding.member:bank-details.node-title',
        'Bank Details',
      ),
    view: BankDetails,
    directLink: () =>
      generateDirectLink(
        routes.onboarding.toString(),
        routes.onboarding.bankDetails,
      ),
    relativeLink: () =>
      generateRelativeLink(
        routes.onboarding.toString(),
        routes.onboarding.bankDetails,
      ),
  },
  [ContractOnboardingStep.MEMBER_LEGAL_DETAILS]: {
    route: (): string => routes.onboarding.legalDetails,
    label: () =>
      i18n.t(
        'contract-onboarding.member:legal-details.node-title',
        'Legal Details',
      ),
    view: LegalDetails,
    directLink: () =>
      generateDirectLink(
        routes.onboarding.toString(),
        routes.onboarding.legalDetails,
      ),
    relativeLink: () =>
      generateRelativeLink(
        routes.onboarding.toString(),
        routes.onboarding.legalDetails,
      ),
  },
  [ContractOnboardingStep.MEMBER_LEGAL_DOCUMENTS]: {
    route: (): string => routes.onboarding.legalDocuments,
    label: () =>
      i18n.t(
        'contract-onboarding.member:legal-documents.node-title',
        'Legal Documents',
      ),
    view: LegalDocuments,
    directLink: () =>
      generateDirectLink(
        routes.onboarding.toString(),
        routes.onboarding.legalDocuments,
      ),
    relativeLink: () =>
      generateRelativeLink(
        routes.onboarding.toString(),
        routes.onboarding.legalDocuments,
      ),
  },
  [ContractOnboardingStep.MEMBER_BENEFIT_DETAILS]: {
    route: (): string => routes.onboarding.benefitDetails,
    label: () =>
      i18n.t(
        'contract-onboarding.member:insurance-details.node-title',
        'Insurance',
      ),
    view: InsuranceDetails,
    directLink: () =>
      generateDirectLink(
        routes.onboarding.toString(),
        routes.onboarding.benefitDetails,
      ),
    relativeLink: () =>
      generateRelativeLink(
        routes.onboarding.toString(),
        routes.onboarding.benefitDetails,
      ),
  },
  [ContractOnboardingStep.MEMBER_REVIEW]: {
    route: (): string => routes.verification.toString(),
    label: () => '',
    view: MemberVerification,
    directLink: () => generateDirectLink(routes.verification.toString(), ''),
    relativeLink: () =>
      generateRelativeLink(routes.verification.toString(), ''),
  },
  [ContractOnboardingStep.ONBOARDING_VERIFYING]: {
    route: (): string => routes.verification.toString(),
    label: () => '',
    view: MemberVerification,
    directLink: () => generateDirectLink(routes.verification.toString(), ''),
    relativeLink: () =>
      generateRelativeLink(routes.verification.toString(), ''),
  },
};

export default stepConfig;
