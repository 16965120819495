import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
} from 'app/models/module-config';
import {
  showModuleBasedOnContractStatusAndContractStarted,
  showModuleBasedOnContractType,
} from 'app/utils/module-checks';

import { ContractStatus, ContractType } from '__generated__/graphql';

import routes from './routes';

const config: ModuleConfig = {
  id: 'member-payables',
  permission: 'member-payables',
  folder: 'member-payables/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Invoices',
    route: routes.root,
    sort: 2,
    home: false,
    group: MemberNavGroups.PAYMENTS,
  },
  enabled: async () => {
    const shouldShow = await Promise.all([
      showModuleBasedOnContractType([
        ContractType.FREELANCER,
        ContractType.CONTRACTOR,
      ]),
      showModuleBasedOnContractStatusAndContractStarted([
        ContractStatus.ACTIVE,
        ContractStatus.OFFBOARDING,
        ContractStatus.ENDED,
        ContractStatus.DELETED,
      ]),
    ]);

    return shouldShow.every((show) => show);
  },
};

export default config;
