/** @jsxImportSource @emotion/react */
import React from 'react';

import { Icon } from '@multiplier/common';
import tw, { TwStyle } from 'twin.macro';

interface MobileDialogProps {
  open: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  children: React.ReactNode;
  containerStyles?: TwStyle;
  headerStyles?: TwStyle;
}

const MobileDialog: React.FC<MobileDialogProps> = ({
  open,
  onClose,
  title,
  children,
  containerStyles,
  headerStyles,
}) => (
  <>
    {open && (
      <div
        css={[
          tw`h-screen w-screen fixed top-0 left-0 flex flex-col`,
          containerStyles,
        ]}
        data-testid="mobile-dialog"
      >
        <div
          tw="bg-black opacity-60 flex-grow w-full"
          onClick={onClose}
          role="button"
          tabIndex={0}
          onKeyPress={onClose}
          aria-label="Click Outside"
        />
        <div tw="bg-white h-auto divide-y divide-grey05">
          <div css={[tw`flex items-center justify-between p-16`, headerStyles]}>
            <p tw="text-p text-background">{title}</p>
            <button type="button" onClick={onClose}>
              <Icon name="cross" />
            </button>
          </div>
          <div tw="p-16">{children}</div>
        </div>
      </div>
    )}
  </>
);
export default MobileDialog;
