import React from 'react';

import { Icon, Modal } from '@multiplier/common';

const Loader = (): React.ReactElement => (
  <Modal.LightBox>
    <Icon name="spinner" fill="none" tw="animate-spin" />
  </Modal.LightBox>
);

export default Loader;
