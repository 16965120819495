import axios from 'axios';

import importMetaEnv from 'import-meta-env';

import { getAuthHeader, getHeaders } from './header';

const api = axios.create({
  baseURL: importMetaEnv.VITE_BENEFIT_SERVICE_URL,
  withCredentials: false,
  headers: getHeaders(),
});

api.defaults.headers.common = getAuthHeader();

export default api;
