/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { FieldArrayWithId, FieldPath, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import { Button, Icon } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import Modal from 'common/components/confirmation-dialog';
import Radio from 'common/components/radio';
import TextInput from 'common/components/text-input';
import {
  rateFrequencyLabel,
  rateTypeLabel,
} from 'common/constants/default-labels';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import { Contract, RateFrequency, RateType } from '__generated__/graphql';

interface PayRateDialogProps {
  open?: boolean;
  onClose: () => void;
  fieldArrayElement?: FieldArrayWithId<CompensationFormValues, 'other'>;
  fieldPath?: FieldPath<CompensationFormValues>;
  country: Contract['country'];
}

export const BasePayRateDialog: React.FC<PayRateDialogProps> = ({
  onClose,
  fieldPath,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const dialogRef = useRef(null);
  const [frequency, setFrequency] = useState<RateFrequency | undefined>(
    undefined,
  );
  const [rateType, setRateType] = useState<RateType | undefined>(undefined);
  const { watch, setValue } = useFormContext();
  const watchedFreq = watch(`${fieldPath}.frequency`);
  const watchedRate = watch(`${fieldPath}.rateType`);

  useClickAway(dialogRef, () => {
    onClose();
  });

  useEffect(() => {
    setFrequency(watchedFreq);
    setRateType(watchedRate);
  }, [watchedFreq]);

  const handleDone = () => {
    setValue(`${fieldPath}.frequency`, frequency);
    setValue(`${fieldPath}.rateType`, rateType);
    onClose();
  };

  return (
    <Modal.Lightbox>
      <Modal.Container tw="width[494px]" ref={dialogRef}>
        <div tw="relative flex flex-col overflow-hidden p-24 gap-y-24">
          <button
            tw="absolute top-16 right-16 focus:(ring-transparent outline-none)"
            type="button"
            onClick={onClose}
          >
            <Icon name="cross" fill={theme`colors.grey02`} />
          </button>
          <TextInput.Container>
            <TextInput.Label>
              {t('pay-rate-dialog.pay-rate.label', 'Pay Rate')}
            </TextInput.Label>
            <div tw="grid grid-cols-2 gap-x-16">
              {[RateFrequency.MONTHLY, RateFrequency.ANNUALLY].map(
                (currFrequency) => (
                  <Radio
                    key={currFrequency}
                    checked={currFrequency === frequency}
                    aria-label={currFrequency.toLowerCase()}
                    onClick={() => {
                      setFrequency(currFrequency);
                    }}
                  >
                    <span tw="text-background font-semibold">
                      {rateFrequencyLabel[currFrequency]}
                    </span>
                  </Radio>
                ),
              )}
            </div>
          </TextInput.Container>
          {rateType && (
            <TextInput.Container>
              <TextInput.Label>
                {t('pay-rate-dialog.rate-type.label', 'Pay Type')}
              </TextInput.Label>
              <div tw="flex flex-col gap-y-16">
                <Radio
                  key={rateType}
                  checked
                  aria-label={rateType.toLowerCase()}
                  labelStyles={tw`items-start`}
                >
                  <div tw="flex flex-col">
                    <span css={[tw`text-background font-semibold`]}>
                      {rateTypeLabel[rateType]}
                    </span>
                    <span tw="text-grey01">
                      {t(
                        `pay-rate-dialog.rate-type.${rateType.toLowerCase()}.description`,
                        'Description about what each pay type entails.',
                      )}
                    </span>
                  </div>
                </Radio>
              </div>
            </TextInput.Container>
          )}
          <div tw="flex flex-row gap-x-6 items-center justify-center">
            <Button variant="outline" size="medium" onClick={onClose}>
              {t('pay-rate-dialog.cancel-button', 'Cancel')}
            </Button>
            <Button
              type="submit"
              variant="default"
              size="medium"
              onClick={handleDone}
            >
              {t('pay-rate-dialog.done-button', 'Done')}
            </Button>
          </div>
        </div>
      </Modal.Container>
    </Modal.Lightbox>
  );
};

const PayRateDialog: React.FC<PayRateDialogProps> = ({ open, ...props }) => {
  if (open) {
    return ReactDOM.createPortal(
      <BasePayRateDialog {...props} />,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }
  return null;
};
export default PayRateDialog;
