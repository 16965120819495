import { formatDuration, intervalToDuration } from 'date-fns';
import { isNil } from 'lodash';

export const getMeridianValueCorrespondingToHour = (hour: number): string => {
  if (hour === 0 || hour === 24) return 'am';
  if (hour === 12) return 'pm';
  if (hour > 12) return 'pm';
  return 'am';
};

export const get12HourFormatFrom24hour = (hour: number): number => {
  if (hour % 12 === 0) return 12;
  return hour % 12;
};

export const get24HourFormatFrom12Hour = (hour: number): number => hour + 12;

export const convert24HourTo12HourFormat = (
  time: string,
  { showMinutes = false } = {},
): string => {
  const [hour24format, minute = 0, second = 0] = time.split(':').map(Number);
  const meridian = getMeridianValueCorrespondingToHour(hour24format);
  const hour = get12HourFormatFrom24hour(hour24format);
  let timeString = `${hour}`;
  if (showMinutes) {
    timeString = `${timeString}:${String(minute).padStart(2, '0')}`;
  }
  if (second) {
    timeString = `${timeString}:${String(second).padStart(2, '0')}`;
  }

  return `${timeString} ${meridian}`;
};

export const secondsAsFormattedDuration = (seconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    delimiter: ' & ',
  });
};

const getDateTimeWithOffset = (date: string) => {
  const serverDate = new Date(date);
  const modifyDateToHaveConstantHours = new Date(
    serverDate.valueOf() + serverDate.getTimezoneOffset() * 60000,
  );
  return new Date(modifyDateToHaveConstantHours);
};

const getDateTimeWithoutOffset = () => {
  const modifyDateToHaveConstantHours = new Date(Date.now()).setHours(
    0,
    0,
    0,
    0,
  );
  return new Date(modifyDateToHaveConstantHours);
};

const setDateTimeWithoutOffset = (date: string) => {
  const modifyDateToHaveConstantHours = new Date(date).setHours(0, 0, 0, 0);
  return new Date(modifyDateToHaveConstantHours);
};

/* This is a duplication of setDateTimeWithoutOffset which has defined to fix a
cross browser issue in additional pay items. The date format yyyy-MM-dd is not
supported by Safari, so it should be converted the format yyyy/MM/dd.
Since the conversion could break some other flows, a separate method is defined.
ex: "2022/11/22T09:52:29.790769" returns an invalid date if this change was done
 */
const setDateTimeWithoutOffsetForAdditionalPayItem = (date: string) => {
  const modifyDateToHaveConstantHours = new Date(
    date.replace(/-/g, '/'),
  ).setHours(0, 0, 0, 0);
  return new Date(modifyDateToHaveConstantHours);
};

export const timezoneRemove = (
  date?: Date | string,
  isFromAdditionalPayItem = false,
): Date => {
  const serverDateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  if (isNil(date)) {
    return getDateTimeWithoutOffset();
  }
  if (date instanceof Date) {
    return date;
  }
  if (serverDateRegex.test(date)) {
    return getDateTimeWithOffset(date);
  }
  return isFromAdditionalPayItem
    ? setDateTimeWithoutOffsetForAdditionalPayItem(date)
    : setDateTimeWithoutOffset(date);
};
