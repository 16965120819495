import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const visaApplicationFiling: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-workflow.visa-application-filing.title', {
      defaultValue: 'Visa Application Filing',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-workflow.visa-application-filing.description', {
      defaultValue: 'Waiting for VISA to be approved',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-workflow.visa-application-filing.label',
      'Visa application filed',
    ),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t(
      'onboarding-workflow.visa-application-filing.status',
      'Pending on Multiplier',
    ),
};

export default visaApplicationFiling;
