/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Callout,
  CalloutVariant,
  Card,
  Icon,
  Loader,
  SpinnerType,
  ToolTip,
} from '@multiplier/common';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import tw, { theme } from 'twin.macro';

import AppFeature from 'app/features';
import { useModal } from 'app/hooks';
import { Experience } from 'app/models/module-config';
import TextInput from 'common/components/text-input';
import { Religion } from 'common/constants/default-labels';
import {
  AdditionalPayFormValues,
  PaymentType,
} from 'contract-onboarding/company/components/additional-pay-form';
import { EsopForm } from 'contract-onboarding/company/components/esop-input';
import { useGetCountryLabourStandards } from 'contract-onboarding/company/hooks';
import useGetSupportedJobTitles from 'contract-onboarding/company/hooks/get-supported-job-titles';
import { getCurrencyOptions } from 'contract-onboarding/company/services/compensation';
import AddSignatoryModal from 'contract-onboarding/components/progress-widget-content/add-signatory-modal';
import {
  useSalaryRevisionNotes,
  useUpsertPerformanceReview,
} from 'performance-reviews/hooks';
import usePerformanceReviewSchema from 'performance-reviews/hooks/performance-review-schema';
import ContractDropdown from 'team/company/components/contract-dropdown';
import teamRoutes from 'team/company/routes';

import {
  Company,
  Compensation,
  Contract,
  ContractStatus,
  ContractType,
  FixedPayComponent,
  GetContractPositionAndPayQuery,
  Maybe,
  PerformanceReview,
  PerformanceReviewStatus,
  SalaryReview,
  useGetCompanyNameQuery,
  useGetContractPerformanceReviewLazyQuery,
  useGetContractPositionAndPayLazyQuery,
} from '__generated__/graphql';

import { isHrMember } from '../../services/partner-eor-and-hr-members';
import PerformanceReviewSteps from '../performance-review-steps';
import AddtionalPaySection from './components/additional-pay-section';
import CancelRevisionFormDialog from './components/cancel-revision-form-dialog';
import CurrentCompensationSection from './components/current-compensation-section';
import ReviseCompensationSection from './components/revise-compensation-section';
import {
  useAdditionalCompensationsMapper,
  useIsNewAllowancesConfigEnabled,
} from './use-additional-compensation-mapper';

const NoteContainer = tw.div`
  flex flex-col p-16 bg-grey04 border border-grey02 rounded-8 text-p font-medium mb-24 text-[15px] mt-16
`;

export interface PerformanceReviewFormValues {
  contractId: string;
  salaryReviewId?: SalaryReview['id'];
  promotedDesignation: string;
  currency: Contract['currency'];
  revisedSalary?: Maybe<number>;
  effectiveMonth: Maybe<Date | string>;
  additionalPays?: AdditionalPayFormValues[];
  currentAdditionalPays?: AdditionalPayFormValues[];
  esop?: EsopForm;
}

interface ProbationDisclaimerNoteProps {
  validTillDate?: FixedPayComponent['validToExclusive'];
}

export const ProbationDisclaimerNote: React.FC<ProbationDisclaimerNoteProps> = ({
  validTillDate,
}) => {
  const { t } = useTranslation('performance-reviews');
  const probationEndDate = validTillDate
    ? format(new Date(validTillDate), 'dd-MM-yyyy')
    : '{dd-mm-yyyy}';

  return (
    <NoteContainer>
      <span tw="flex text-text-brand-primary">
        <Icon
          name="warning-alert-circle"
          fill="transparent"
          tw="mr-extra-small"
        />
        {t('form.current-salary.note.heading', 'Important Note')}
      </span>
      <span tw="text-text-primary ml-extra-large mt-extra-small">
        <Trans
          i18nKey="form.current-salary.note.content-data"
          t={t}
          values={{
            probationEndDate,
          }}
        >
          <p>
            This compensation revision will be effective ONLY after the
            employee’s probation period which ends on{' '}
            <strong>{{ probationEndDate }}</strong>. If you wish to make
            revision to the probation salary, please reach out to your customer
            success manager.
          </p>
        </Trans>
      </span>
    </NoteContainer>
  );
};

const PerformanceReviewForm: React.FC<{
  isEdit: boolean;
  performanceReview?: Maybe<PerformanceReview>;
  isNew?: boolean;
  contractId?: string;
  onCancel?: () => void;
  currentView?: 'team' | 'salary-revision';
}> = ({
  isEdit,
  performanceReview,
  isNew = false,
  contractId,
  onCancel,
  currentView = 'team',
}) => {
  const [selectedContract, setSelectedContract] = useState<
    GetContractPositionAndPayQuery['contract']
  >();
  const { t } = useTranslation('performance-reviews');

  const isNotFreelancer = selectedContract?.type !== ContractType.FREELANCER;
  const shouldUseNewTeamViewRoute = useFeature(
    AppFeature.HRIS_NEW_EMPLOYEE_FIELDS,
  ).on;
  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: selectedContract?.type,
  });

  const { data: companyName } = useGetCompanyNameQuery();

  const [
    showSignatoryModal,
    closeSignatoryModal,
    openSignatoryModal,
  ] = useModal();

  const [showCancelModal, closeCancelModal, openCancelModal] = useModal();

  const {
    getNote,
    cutOffDate,
    effectiveMonth: payrollMonth,
  } = useSalaryRevisionNotes();

  const [
    getContract,
    {
      data: contractPositionAndPayData,
      loading: contractPositionAndPayLoading,
    },
  ] = useGetContractPositionAndPayLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data?.contract) {
        setSelectedContract(data.contract);
      }
    },
  });

  const [
    getPerformanceReview,
    { data: performanceReviewData, loading: performanceReviewLoading },
  ] = useGetContractPerformanceReviewLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: companyIntegrationsLoading,
    supportedJobPositions,
  } = useGetSupportedJobTitles();

  const hasPendingRevisions = useMemo(
    () =>
      (
        performanceReviewData?.contract?.performanceReviews?.filter(
          (item) =>
            item?.status &&
            ![
              PerformanceReviewStatus.APPROVED,
              PerformanceReviewStatus.DECLINED,
              PerformanceReviewStatus.ACTIVATED,
            ].includes(item?.status),
        ) ?? []
      ).length > 0,
    [performanceReviewData],
  );

  const memberReligion = useMemo(
    () =>
      selectedContract?.member?.legalData?.find((d) => d?.key === 'religion')
        ?.value as Religion,
    [selectedContract],
  );

  const { data: labourStandards } = useGetCountryLabourStandards({
    country: selectedContract?.country,
    countryStateCode: selectedContract?.countryStateCode,
  });

  const averageWorkingHoursPerMonth =
    labourStandards?.country?.labourStandards?.workShift
      ?.averageWorkingHoursPerMonth ?? undefined;

  const {
    handleAddSignatoryAndSubmit,
    handleSubmit: onSubmit,
    loading: loadingUpsert,
  } = useUpsertPerformanceReview({
    id: performanceReview?.id,
    contract: contractPositionAndPayData?.contract,
    averageWorkingHoursPerMonth,
    openSignatoryModal,
    closeSignatoryModal,
    redirectUrl:
      currentView === 'team'
        ? `/${Experience.COMPANY}/${teamRoutes.root}/${selectedContract?.id}/${
            shouldUseNewTeamViewRoute
              ? teamRoutes.details.employment
              : teamRoutes.details.compensation
          }`
        : '',
  });

  const currencyOptions = useMemo(
    () => getCurrencyOptions([contractPositionAndPayData?.contract?.currency]),
    [contractPositionAndPayData],
  );

  const isEmployeeStartingOnThisMonth = useMemo(() => {
    const employeeStartMonth = new Date(selectedContract?.startOn).getMonth();
    const employeeStartYear = new Date(selectedContract?.startOn).getFullYear();
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    return (
      currentYear === employeeStartYear && currentMonth === employeeStartMonth
    );
  }, [selectedContract]);

  const { mapToAdditionalPayFormValues } = useAdditionalCompensationsMapper({
    contract: selectedContract as Contract,
  });

  const schema = usePerformanceReviewSchema({
    isEmployeeStartingAfterThisMonth: isEmployeeStartingOnThisMonth,
    currencyOptions,
    selectedContract,
    isAllowanceFeature: isNotFreelancer,
    isNewAllowancesConfigEnabled,
    hasSupportedJobPositions: Boolean(
      supportedJobPositions && supportedJobPositions.length > 0,
    ),
  });

  const methods = useForm<PerformanceReviewFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    reset,
    getValues,
    watch,
    trigger,
    formState: { touchedFields },
  } = methods;

  const [
    revisedSalary,
    promotedDesignation,
    additionalPays,
    currentAdditionalPays,
    effectiveMonth,
  ] = watch([
    'revisedSalary',
    'promotedDesignation',
    'additionalPays',
    'currentAdditionalPays',
    'effectiveMonth',
  ]);

  const hasSalaryOrTitleOrAdditonalPaysChanges = useMemo(() => {
    const currentSalary = selectedContract?.compensation?.basePay?.amount;

    if (!revisedSalary || !currentSalary) {
      return true;
    }

    const isAdditionalPaysChanged = !isEqual(
      additionalPays,
      currentAdditionalPays,
    );

    const isNewDesignationAvailable =
      promotedDesignation && promotedDesignation !== '';

    return (
      isAdditionalPaysChanged ||
      isNewDesignationAvailable ||
      Number(revisedSalary) !== Number(currentSalary)
    );
  }, [
    revisedSalary,
    selectedContract,
    currentAdditionalPays,
    additionalPays,
    promotedDesignation,
  ]);

  const showContinueTooltip =
    selectedContract && !hasSalaryOrTitleOrAdditonalPaysChanges;

  useEffect(() => {
    if (isNew && selectedContract && selectedContract?.id) {
      getPerformanceReview({
        variables: {
          contractId: selectedContract?.id,
        },
      });
    }
  }, [isNew, selectedContract]);

  useEffect(() => {
    if (selectedContract && effectiveMonth) {
      getNote(selectedContract?.id, effectiveMonth);
    }
  }, [selectedContract, effectiveMonth]);

  useEffect(() => {
    if (contractId) {
      getContract({
        variables: {
          id: contractId,
        },
      });
      setValue('contractId', contractId);
    }
  }, [contractId]);

  useEffect(() => {
    if (
      touchedFields?.revisedSalary ||
      touchedFields?.promotedDesignation ||
      touchedFields?.additionalPays
    ) {
      trigger('revisedSalary');
    }
  }, [promotedDesignation, additionalPays, revisedSalary]);

  useEffect(() => {
    if (
      performanceReview?.id &&
      performanceReview?.contract?.id &&
      performanceReview.contract.member?.id
    ) {
      reset({
        ...getValues(),
        contractId: performanceReview.contract.id,
        salaryReviewId: performanceReview.salaryReview?.id,
        promotedDesignation: performanceReview?.designation?.name ?? '',
        currency:
          performanceReview?.salaryReview?.revisedCompensation?.basePay
            ?.currency,
        revisedSalary:
          performanceReview?.salaryReview?.revisedCompensation?.basePay?.amount,
        effectiveMonth: new Date(performanceReview?.effectiveDate),
        additionalPays: mapToAdditionalPayFormValues(
          performanceReview?.salaryReview?.revisedCompensation?.additionalPays,
        ),
        currentAdditionalPays: mapToAdditionalPayFormValues(
          performanceReview?.salaryReview?.currentCompensation?.additionalPays,
        ),
      });
      trigger();
      getContract({
        variables: {
          id: performanceReview.contract.id,
        },
      });
    }
  }, [performanceReview, mapToAdditionalPayFormValues]);

  useEffect(() => {
    if (!performanceReview?.id && selectedContract) {
      const initAdditionalPays = isNew
        ? mapToAdditionalPayFormValues(
            selectedContract?.compensation
              ?.additionalPays as Compensation['additionalPays'],
          ).filter((item) =>
            [
              PaymentType.VARIABLE_PERFORMANCE_BONUS,
              PaymentType.OTHER,
              PaymentType.ALLOWANCES,
              PaymentType.DE_MINIMIS_ALLOWANCE,
              PaymentType.INTERNET_ALLOWANCE,
              PaymentType.MOBILE_AND_PHONE_ALLOWANCE,
              PaymentType.THIRTEENTH_MONTH_BONUS,
              PaymentType.FOURTEENTH_MONTH_BONUS,
              PaymentType.THR_BONUS,
            ].includes(item.payType),
          )
        : undefined;

      reset({
        ...getValues(),
        currency:
          selectedContract?.compensation?.postProbationBasePay?.currency,
        revisedSalary:
          selectedContract?.compensation?.postProbationBasePay?.amount,
        additionalPays: initAdditionalPays,
        currentAdditionalPays: initAdditionalPays,
      });
    }
  }, [
    performanceReview,
    selectedContract,
    isNew,
    mapToAdditionalPayFormValues,
  ]);

  const resetEmptyForm = () => {
    setSelectedContract(undefined);
    reset({
      currency: undefined,
      effectiveMonth: undefined,
      esop: undefined,
      promotedDesignation: undefined,
      revisedSalary: undefined,
      additionalPays: [],
      currentAdditionalPays: [],
    });
  };

  const handleFormSubmit = (values: PerformanceReviewFormValues) => {
    const mandatoryAdditionalPays = selectedContract?.compensation?.additionalPays?.filter(
      (additionalPay) => additionalPay != null && !additionalPay.isDeletable,
    );

    if (mandatoryAdditionalPays === null || isEmpty(mandatoryAdditionalPays)) {
      onSubmit(values);
    } else {
      onSubmit(values, mandatoryAdditionalPays || []);
    }
  };

  const handleCancel = () => {
    if (!onCancel) return;

    if (isEdit) {
      onCancel();
      return;
    }
    openCancelModal();
  };

  const hasProbationAmount = useMemo(() => {
    const probationAmount =
      selectedContract?.compensation?.probationBasePay?.amount;

    return probationAmount !== null && probationAmount !== undefined;
  }, [selectedContract]);

  const showRestrictionNote = selectedContract && hasPendingRevisions;

  const loading =
    contractPositionAndPayLoading ||
    performanceReviewLoading ||
    companyIntegrationsLoading;

  return (
    <FormProvider {...methods}>
      <form data-testid="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card tw="p-large grid grid-cols-1 mobile:px-base">
          <TextInput.Container>
            <TextInput.Label htmlFor="employee">
              {t('form.employee', 'Employee')}
            </TextInput.Label>
            <Controller
              name="contractId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ContractDropdown
                  tw="mb-large"
                  data-testid="member-dropdown"
                  placeholder={t(
                    'form.member-dropdown.placeholder',
                    'e.g. John Doe',
                  )}
                  onChange={(contract) => {
                    if (contract?.id) {
                      getContract({
                        variables: {
                          id: contract.id,
                        },
                      });
                      onChange(contract.id);
                    }
                  }}
                  value={value}
                  isEdit={isEdit || Boolean(contractId)}
                  onClear={() => {
                    resetEmptyForm();
                    clearErrors('revisedSalary');
                  }}
                  filterContracts={(contract) =>
                    (contract.type === ContractType.EMPLOYEE ||
                      isHrMember(contract.type)) &&
                    contract.status === ContractStatus.ACTIVE
                  }
                />
              )}
            />
          </TextInput.Container>
          {showRestrictionNote && (
            <Callout
              variant={CalloutVariant.INFO}
              iconProps={{
                fill: theme`colors.icon-secondary`,
              }}
            >
              <div tw="text-text-secondary text-ps">
                {t(
                  'form.warning.revision-existed',
                  'There is already a salary revision, that has been already created for {{ employeeName }}. Kindly cancel the existing salary revision before creating a new one',
                  {
                    replace: {
                      employeeName: `${
                        selectedContract?.member?.firstName ?? ''
                      } ${selectedContract?.member?.lastName ?? ''}`.trim(),
                    },
                  },
                )}
              </div>
            </Callout>
          )}
        </Card>
        {loading ? (
          <Loader.Spinner variant={SpinnerType.CUSTOM_LAYOUT} />
        ) : (
          <>
            {!showRestrictionNote && (
              <>
                {selectedContract && (
                  <Card tw="p-large mt-base grid grid-cols-1 mobile:px-base">
                    <PerformanceReviewSteps
                      isEdit={isEdit}
                      contract={selectedContract}
                      effectiveDate={effectiveMonth}
                      performanceReviewStatus={
                        performanceReview?.status as PerformanceReviewStatus
                      }
                      payrollCycle={performanceReview?.payrollCycle}
                      effectiveMonth={payrollMonth}
                      cutOffDate={cutOffDate}
                    />
                  </Card>
                )}
                {selectedContract && (
                  <CurrentCompensationSection
                    contract={selectedContract}
                    company={companyName?.company as Company}
                    hasProbationAmount={hasProbationAmount}
                    memberReligion={memberReligion}
                  />
                )}
                <ReviseCompensationSection
                  contract={selectedContract as Contract}
                  currencyOptions={currencyOptions}
                  hasProbationAmount={hasProbationAmount}
                  supportedJobPositions={supportedJobPositions}
                />
                {selectedContract && (
                  <Card tw="p-large mt-base grid grid-cols-1 mobile:px-base">
                    <AddtionalPaySection
                      contract={selectedContract as Contract}
                      company={companyName?.company as Company}
                      currencyOptions={currencyOptions}
                      memberReligion={memberReligion}
                      averageWorkingHoursPerMonth={averageWorkingHoursPerMonth}
                    />
                  </Card>
                )}
                <div tw="flex flex-row justify-center mt-large gap-x-12">
                  {onCancel && (
                    <Button
                      variant="secondary"
                      size="medium"
                      onClick={handleCancel}
                    >
                      {t('form.cancel', 'Cancel')}
                    </Button>
                  )}
                  <ToolTip
                    styles={tw`w-[350px] leading-6`}
                    content={
                      showContinueTooltip &&
                      t(
                        'form.validation.revised-salary.salary-or-title-or-additional-pays',
                        'Please change any of the components - Salary, Designation or Additional compensation items, to proceed ahead !',
                      )
                    }
                  >
                    <Button
                      tw="relative"
                      variant="default"
                      size="medium"
                      type="submit"
                      data-cy="submit"
                      disabled={
                        !selectedContract ||
                        loadingUpsert ||
                        !hasSalaryOrTitleOrAdditonalPaysChanges
                      }
                      loading={loadingUpsert}
                    >
                      {t('form.submit', 'Continue')}
                      {showContinueTooltip && (
                        <Icon
                          tw="absolute top-0 right-0 translate-x-2/4 translate-y-[-50%]"
                          name="warning-circle"
                        />
                      )}
                    </Button>
                  </ToolTip>
                </div>
              </>
            )}
          </>
        )}
      </form>
      <AddSignatoryModal
        open={showSignatoryModal}
        onClose={closeSignatoryModal}
        onSubmit={(values) =>
          handleAddSignatoryAndSubmit(values, methods.getValues())
        }
        loading={loadingUpsert}
      />
      <CancelRevisionFormDialog
        open={showCancelModal}
        onClose={closeCancelModal}
        onConfirm={() => {
          closeCancelModal();
          if (onCancel) {
            onCancel();
          }
        }}
      />
    </FormProvider>
  );
};

export default PerformanceReviewForm;
