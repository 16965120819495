/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import 'twin.macro';

import { Button } from '@multiplier/common';

import {
  ContractOnboardingStatus,
  useContractReviseMutation,
  useInviteMemberMutation,
  useResendInviteMemberMutation,
} from '__generated__/graphql';

const SorOnboardingActionBar: React.FC<{
  currentStatus: ContractOnboardingStatus | null | undefined;
}> = ({ currentStatus }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { id: contractId } = useParams<{ id?: string }>();

  const [
    inviteMember,
    { loading: inviteMemberLoading },
  ] = useInviteMemberMutation();
  const [
    inviteMemberResend,
    { loading: inviteMemberResendLoading },
  ] = useResendInviteMemberMutation();

  const [
    reviseContract,
    { loading: reviseContractLoading },
  ] = useContractReviseMutation();

  const handleReviseContractClick = async () => {
    if (contractId) {
      await reviseContract({ variables: { id: contractId } });
    }
  };

  const handleSubmit = async () => {
    if (contractId) {
      if (currentStatus === ContractOnboardingStatus.MEMBER_INVITE_PENDING) {
        await inviteMember({ variables: { id: contractId } });
      } else {
        await inviteMemberResend({ variables: { id: contractId } });
      }
    }
  };

  const getInviteButtonText = () => {
    switch (currentStatus) {
      case ContractOnboardingStatus.MEMBER_INVITE_PENDING:
        return t(
          'onboarding-phase.sor-onboarding-action-bar.action-cell.invite-employee',
          'Invite employee',
        );
      default:
        return t(
          'onboarding-phase.sor-onboarding-action-bar.action-cell.resend-invite-employee',
          'Resend invitation',
        );
    }
  };

  return (
    <div tw="flex flex-row justify-center gap-x-12 w-full mt-24">
      <Button
        size="medium"
        variant="outline"
        tw="w-[100%]"
        loading={reviseContractLoading}
        onClick={handleReviseContractClick}
      >
        {t(
          'onboarding-phase.sor-onboarding-action-bar.action-cell.revise-details',
          'Revise employee details',
        )}
      </Button>
      <Button
        variant={
          currentStatus === ContractOnboardingStatus.MEMBER_INVITE_PENDING
            ? 'default'
            : 'outline'
        }
        type="submit"
        size="medium"
        tw="w-[100%]"
        loading={inviteMemberLoading || inviteMemberResendLoading}
        onClick={handleSubmit}
      >
        {getInviteButtonText()}
      </Button>
    </div>
  );
};

export default SorOnboardingActionBar;
