/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ComboBox from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import countryLabels from 'common/constants/country-labels';

import { CountryCode, DataFieldDefinition } from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '../index';

interface NationalitySelectorProps {
  requirement?: DataFieldDefinition;
}

const NationalitySelector: React.FC<NationalitySelectorProps> = ({
  requirement,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const nationalityDropdownValues = useMemo(
    () =>
      Object.values(CountryCode).map((c) => ({
        label: countryLabels[c],
        value: c,
        title: c,
      })),
    [],
  );

  const {
    control,
    setValue,
    formState: { defaultValues },
  } = useFormContext<MemberBasicDetailsFormValues>();

  return (
    <TextInput.Container>
      <TextInput.Label>
        {t('member.basic-details.nationality.label', 'Nationality')}
      </TextInput.Label>
      <Controller
        name="nationality"
        control={control}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            data-testid="select-nationality"
            variant="autocomplete"
            dropdownValues={nationalityDropdownValues}
            placeholder={t(
              'member.basic-details.nationality.placeholder',
              'Select Nationality',
            )}
            value={value as string}
            onChange={(countryCode: string) => {
              setValue('countryCode', countryCode);
              onChange(countryCode);
            }}
            disabled={
              requirement?.editable === false && !!defaultValues?.nationality
            }
            showArrow
          />
        )}
      />
    </TextInput.Container>
  );
};

export default NationalitySelector;
