import React, { useContext, useRef } from 'react';

import ReactDOM from 'react-dom';
import { useClickAway, useKey } from 'react-use';

import { css, keyframes } from '@emotion/react';
import tw, { theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
`;

const slideInAnimation = css`
  animation: ${slideIn} 0.5s;
`;

const slideOutAnimation = css`
  animation: ${slideOut} 0.5s;
`;

const Lightbox = tw.div`h-screen w-screen fixed top-0 left-0 flex justify-end items-center bg-background-overlay z-40`;

interface SideModalProps {
  open: boolean;
  title?: string | React.ReactNode;
  isEscapeable?: boolean;
  onCloseClick: () => void;
  titleElement?: React.ReactElement;
}

type SideModalComponentProps = SideModalProps;

const SideModalComponent: React.FC<
  React.PropsWithChildren<SideModalComponentProps>
> = ({
  children,
  onCloseClick,
  title,
  isEscapeable = false,
  open,
  titleElement,
  ...props
}) => {
  const containerRef = useRef(null);
  const { isNewThemeApplied } = useContext(ThemeContext);

  useClickAway(containerRef, () => {
    if (isEscapeable) {
      onCloseClick();
    }
  });

  useKey('Escape', () => {
    if (isEscapeable) {
      onCloseClick();
    }
  });

  return (
    <Lightbox>
      <div
        css={open ? slideInAnimation : slideOutAnimation}
        ref={containerRef}
        tw="h-screen bg-background-white"
        {...props}
      >
        <div tw="flex justify-between items-center gap-x-base px-large pt-large">
          {titleElement || <div tw="text-h5 font-semibold">{title}</div>}
          <Icon
            tw="cursor-pointer ml-auto w-large h-large"
            css={!isNewThemeApplied && tw`w-base h-base`}
            fill={theme`colors.icon-secondary`}
            name="cross"
            onClick={onCloseClick}
          />
        </div>
        {children}
      </div>
    </Lightbox>
  );
};

const SideModal: React.FC<React.PropsWithChildren<SideModalProps>> = ({
  open,
  ...props
}) => {
  if (open) {
    return ReactDOM.createPortal(
      <SideModalComponent open={open} {...props} />,
      document.getElementById('side-modal-root') as HTMLElement,
    );
  }

  return null;
};

export default SideModal;
