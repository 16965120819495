/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  Controller,
  FieldArrayWithId,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import { Icon, ThemeContext } from '@multiplier/common';
import { TFunction } from 'i18next';
import tw, { theme } from 'twin.macro';

import IconButton from 'common/components/icon-button';
import TextInput from 'common/components/text-input';
import Toggle from 'common/components/toggle';
import countryLabels from 'common/constants/country-labels';
import i18n from 'i18n';

import {
  Compensation,
  ComplianceDefinitionScope,
  ComplianceParamDataFieldType,
  ComplianceParamDefinition,
  ComplianceParamPeriodLimitUnitValidation,
  ComplianceParamPeriodUnit,
  ContractTerm,
  ContractType,
  CountryCode,
  Maybe,
} from '__generated__/graphql';

import { CustomComplianceInsightDefinition } from '../../company/pages/definition-phase/pages/compliance';
import { ComplianceFormValues } from '../contract-section';
import { FormCard, FormContainer, FormTitle } from '../layout';
import ComplianceParamDescription from './components/compliance-param-description';
import NoticePeriodTooltip from './components/notice-period-tooltip';

const RecommendationPill = React.lazy(() => import('../recommendation-pill'));

export const periodLabels = {
  [ComplianceParamPeriodUnit.YEARS]: (count: number): string =>
    i18n.t('contract-onboarding.common:compliance.period.years', {
      defaultValue: 'years',
      count,
    }),
  [ComplianceParamPeriodUnit.MONTHS]: (count: number): string =>
    i18n.t('contract-onboarding.common:compliance.period.months', {
      defaultValue: 'months',
      count,
    }),
  [ComplianceParamPeriodUnit.DAYS]: (count: number): string =>
    i18n.t('contract-onboarding.common:compliance.period.days', {
      defaultValue: 'days',
      count,
    }),
  [ComplianceParamPeriodUnit.WEEKS]: (count: number): string =>
    i18n.t('contract-onboarding.common:compliance.period.weeks', {
      defaultValue: 'weeks',
      count,
    }),
  [ComplianceParamPeriodUnit.NONE]: (count: number): string =>
    i18n.t('contract-onboarding.common:compliance.period.none', {
      defaultValue: '',
      count,
    }),
};

export const defaultComplianceParamLabels: { [key: string]: string } = {
  noticeAfterProbation: i18n.t(
    'contract-onboarding.common:compliance.notice-after-probation',
    'Notice Period',
  ),
  noticePeriod: i18n.t(
    'contract-onboarding.common:compliance.notice-period',
    'Notice Period',
  ),
  noticeBeforeProbation: i18n.t(
    'contract-onboarding.common:compliance.notice-before-probation',
    'Notice Before Probation',
  ),
  noticeDuringProbation: i18n.t(
    'contract-onboarding.common:compliance.notice-during-probation',
    'Notice During Probation',
  ),
  leavePolicy: i18n.t(
    'contract-onboarding.common:compliance.leave-policy',
    'Leave Policy',
  ),
  nonSolicit: i18n.t(
    'contract-onboarding.common:compliance.non-solicit',
    'Non-solicitation',
  ),
  probationPolicy: i18n.t(
    'contract-onboarding.common:compliance.probation-policy',
    'Probation Policy',
  ),
  nonCompete: i18n.t(
    'contract-onboarding.common:compliance.non-compete',
    'Non-compete',
  ),
  usa401k: i18n.t(
    'contract-onboarding.common:compliance.usa-401k',
    '401K Retirement Plan',
  ),
  brazilPcmso: i18n.t(
    'contract-onboarding.common:compliance.brazil-pcmso',
    'Mandatory occupational health examination program (PCMSO)',
  ),
  registeredRetirementSavingsPlan: i18n.t(
    'contract-onboarding.common:compliance.registered-retirement-savings-plan',
    'Registered Retirement Savings Plan (RRSP)',
  ),
};

export const getValidationHelperText = (
  t: TFunction<'contract-onboarding.company'>,
  validation: ComplianceParamPeriodLimitUnitValidation,
  id?: string,
  country?: Maybe<CountryCode>,
): string => {
  if (id === 'leavePolicy') {
    return t('compliance.period.validation-helper.leaves', {
      defaultValue:
        '{{country}} Employment Laws allow you to offer a minimum of {{minimum}} leaves.',
      replace: {
        minimum: validation.minimum,
        country: country && countryLabels[country],
      },
      interpolation: { escapeValue: false },
    });
  }

  if (id === 'probationPolicy') {
    return t('compliance.period.validation-helper.probation', {
      defaultValue:
        '{{country}} Employment Laws allow you to offer between {{ minimum }} to {{ maximum }} {{ unit }}.',
      replace: {
        minimum: validation.minimum,
        maximum: validation.maximum,
        country: country && countryLabels[country],
        unit:
          validation?.unit &&
          periodLabels[validation.unit](validation.maximum ?? 2),
      },
      interpolation: { escapeValue: false },
    });
  }

  if (validation.minimum !== null && validation.maximum !== null) {
    return t('compliance.period.validation-helper.upper-lower-bounds', {
      defaultValue:
        'Value should be between {{ minimum }} and {{ maximum }} {{ unit }}.',
      replace: {
        minimum: validation.minimum,
        maximum: validation.maximum,
        unit:
          validation?.unit &&
          periodLabels[validation.unit](validation.maximum ?? 2),
      },
    });
  }

  if (validation.minimum !== null) {
    return t('compliance.period.validation-helper.lower-bound', {
      defaultValue: 'Value should be above {{ minimum }} {{ unit }}.',
      replace: {
        minimum: validation.minimum,
        unit:
          validation?.unit &&
          periodLabels[validation.unit](validation.minimum ?? 2),
      },
    });
  }

  return t('compliance.period.validation-helper.upper-bound', {
    defaultValue: 'Value should be below {{ maximum }} {{ unit }}.',
    replace: {
      maximum: validation.maximum,
      unit:
        validation?.unit &&
        periodLabels[validation.unit](validation.maximum ?? 2),
    },
  });
};

interface ComplianceEntryProps {
  id: string;
  complianceParamDefinition?: Maybe<ComplianceParamDefinition>;
  dependencies: number[]; // array of index of param dependencies
  field: FieldArrayWithId<
    ComplianceFormValues,
    'complianceParams' | 'mandatory' | 'custom',
    'id'
  >;
  index: number;
  inline?: boolean;
  edit?: boolean;
  hideDisabled?: boolean;
  scope?: Maybe<ComplianceDefinitionScope>;
  country?: Maybe<CountryCode>;
  insight?: CustomComplianceInsightDefinition;
  compensation?: Compensation | null;
  contractTerm?: Maybe<ContractTerm>;
  contractType?: Maybe<ContractType>;
}

/**
 * The logic governing the forms that use these compliance entry param components is
 * currently not according to the intended method exposed on the graphql api
 *
 * Current steps to populate compliance params
 * 1. Extract compliance params from contract.compliance
 *    - If any values are -1, initialise with enabled: false (toggled off)
 * 2. Validate inputs with country requirements query
 * 3. Form will be
 * 4. Send array of all inputs, extra processing to update params with enabled: false to value: -1
 *    to indicate those that have been toggled off
 * This logic is currently used in
 *  - onboarding-phase/contract-section
 *  - company/definition-phase/compliance
 *  - company/definition-phase/contract
 *
 * Intended steps to populate
 * 1. Extract compliance params from contract.compliance
 * 2. Query country.compliance.requirements for additional params not included in contract.compliance
 * 3. Validate inputs with country.compliance.requirements
 * 4. Send array of only toggled on inputs
 */
const ComplianceEntry: React.FC<ComplianceEntryProps> = ({
  id,
  complianceParamDefinition,
  dependencies,
  field,
  index,
  inline = false,
  edit = false,
  hideDisabled = false,
  scope,
  country,
  insight,
  compensation,
  contractTerm,
  contractType,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  if (!field.key) return null; // Ignore dummy compliance params

  const [showEditMode, setShowEditMode] = useState(edit);
  const { t } = useTranslation('contract-onboarding.company');

  useEffect(() => {
    setShowEditMode(edit);
  }, [edit]);

  const {
    control,
    formState: { errors },
    setValue,
    register,
  } = useFormContext();

  const paramValue = useWatch({
    control,
    name: `complianceParams.${index}.value`,
  });

  const dependenciesEnabled = useWatch({
    control,
    name: dependencies.map(
      (dependencyIndex) => `complianceParams.${dependencyIndex}.enabled`,
    ),
  });

  const paramEnabled =
    useWatch({
      control,
      name: `complianceParams.${index}.enabled`,
    }) && dependenciesEnabled.every(Boolean);

  useEffect(() => {
    if (!dependenciesEnabled.every(Boolean)) {
      setValue(`complianceParams.${index}.enabled`, false);
    }
  }, [dependenciesEnabled]);

  // FIXME: React hook form upgrade applies form types stricter, this should be fixed
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const error = errors.complianceParams?.[index];

  const paramValidation = useMemo(
    () =>
      complianceParamDefinition?.validation?.find(
        (definition) => field?.unit === definition?.unit,
      ),
    [complianceParamDefinition, field],
  );

  const divRef = useRef<HTMLDivElement>(null);

  useClickAway(divRef, () => {
    if (showEditMode && !inline) {
      setValue(
        `complianceParams.${index}.value`,
        paramValidation?.defaultValue,
      );
      setShowEditMode(false);
    }
  });

  const shouldShowEditInput = useMemo(
    () =>
      showEditMode &&
      complianceParamDefinition?.dataFieldType ===
        ComplianceParamDataFieldType.TEXT,
    [showEditMode, complianceParamDefinition],
  );

  const isNoticeAfterProbation =
    complianceParamDefinition?.param?.key === 'noticeAfterProbation';

  const isProbationPolicy =
    complianceParamDefinition?.param?.key === 'probationPolicy';

  const isClauseEditable = useMemo(
    () =>
      (complianceParamDefinition?.editable &&
        complianceParamDefinition?.dataFieldType ===
          ComplianceParamDataFieldType.TEXT &&
        !inline) ??
      false,
    [complianceParamDefinition, inline, isNoticeAfterProbation],
  );

  const clauseDefaultValue = paramEnabled
    ? paramValue
    : paramValidation?.defaultValue;

  const isMaltaFixedTermContract =
    country === CountryCode.MLT && contractTerm === ContractTerm.FIXED;

  const isGermanyContract = country === CountryCode.DEU;

  return !paramEnabled && inline && hideDisabled ? null : (
    <FormCard
      ref={divRef}
      css={[tw`gap-y-small`, inline && tw`p-none border-none shadow-none`]}
      data-testid={`compliance-entry-${id}`}
    >
      <FormContainer>
        <div tw="flex flex-row gap-base items-center">
          <FormTitle
            css={[
              inline && tw`text-ps`,
              !paramEnabled && tw`text-text-secondary`,
            ]}
          >
            {isNoticeAfterProbation && country === CountryCode.USA
              ? defaultComplianceParamLabels.noticePeriod
              : field.key && defaultComplianceParamLabels[field.key]}
          </FormTitle>
          {insight && (
            <RecommendationPill
              insight={insight}
              value={paramEnabled ? paramValue : paramValidation?.defaultValue}
              unit={field?.unit}
            />
          )}
        </div>
        <div tw="flex flex-row gap-x-small">
          {complianceParamDefinition &&
            !complianceParamDefinition.required &&
            // this is to support a future possible scenario where we'd have to show a compliance clause
            // but not let users edit it.
            complianceParamDefinition.editable &&
            ((edit && inline) || (!edit && !inline)) && (
              <Controller
                control={control}
                name={`complianceParams.${index}.enabled`}
                defaultValue={paramEnabled}
                render={({ field: { value, onChange } }) => (
                  <Toggle
                    id={id}
                    checked={dependenciesEnabled.every(Boolean) && value}
                    disabled={
                      isProbationPolicy &&
                      compensation?.probationBasePay?.amount !== null
                    }
                    onChange={(e) => {
                      if (
                        !dependenciesEnabled.every(Boolean) &&
                        e.target.checked
                      )
                        return;

                      onChange(e.target.checked);
                    }}
                  />
                )}
              />
            )}
        </div>
      </FormContainer>
      {shouldShowEditInput ? (
        <TextInput.Container>
          <TextInput.Label
            htmlFor={`${
              complianceParamDefinition?.param &&
              complianceParamDefinition.param.key
            }-input`}
          >
            {t('compliance.period.label', {
              defaultValue: 'Enter duration in {{ unit }}',
              replace: {
                unit: (field?.unit && periodLabels[field.unit](2)) ?? '', // force plural
              },
            })}
          </TextInput.Label>
          {(paramValidation?.minimum || paramValidation?.maximum) &&
            scope === ComplianceDefinitionScope.COUNTRY_SPECIFIC && (
              <TextInput.Helper data-testid="validation-helper">
                {getValidationHelperText(t, paramValidation, id, country)}
              </TextInput.Helper>
            )}
          <div tw="flex flex-row gap-x-small">
            <TextInput
              id={`${
                complianceParamDefinition?.param &&
                complianceParamDefinition.param.key
              }-input`}
              type="number"
              divStyles={inline ? tw`flex-grow` : tw`w-1/2`}
              units={(field?.unit && periodLabels[field.unit](2)) ?? ''} // force plural
              disabled={!paramEnabled || !complianceParamDefinition?.editable}
              error={!!error?.value}
              data-testid={`${
                complianceParamDefinition?.param &&
                complianceParamDefinition.param.key
              }-input`}
              step=".1"
              defaultValue={clauseDefaultValue}
              {...register(`complianceParams.${index}.value`, {
                setValueAs: (value) =>
                  Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
                validate: {
                  whole: (v) =>
                    Number.isInteger(Number(v)) ||
                    (t(
                      'compliance.value.whole',
                      'Entry should be a whole number',
                    ) as string),
                  min: (v) =>
                    Number(v) >= (paramValidation?.minimum ?? 0) ||
                    (t('compliance.value.min-error', {
                      defaultValue: 'Entry should be above {{value}}',
                      replace: {
                        value: paramValidation?.minimum ?? 0,
                      },
                    }) as string),
                  max: (v) =>
                    Number(v) <=
                      (paramValidation?.maximum ?? Number.MAX_VALUE) ||
                    (t('compliance.value.max-error', {
                      defaultValue: 'Entry should be below {{value}}',
                      replace: {
                        value: paramValidation?.maximum ?? Number.MAX_VALUE,
                      },
                    }) as string),
                },
              })}
            />
            {!inline && (
              <div tw="flex flex-row gap-x-extra-small">
                <IconButton
                  type="submit"
                  aria-label="confirm"
                  data-testid="check-button"
                  variant="outline"
                  size="medium"
                  name="check"
                  fill={
                    isNewThemeApplied
                      ? theme`colors.icon-primary`
                      : theme`colors.success`
                  }
                  css={[!isNewThemeApplied && tw`bg-bgSuccess border-0`]}
                  onClick={() => {
                    if (!error?.value) setShowEditMode(false);
                  }}
                />
                <Controller
                  name={`complianceParams.${index}.value`}
                  control={control}
                  render={({ field: { onChange } }) => (
                    <IconButton
                      aria-label="cancel"
                      data-testid="cancel-button"
                      variant="outline"
                      size="medium"
                      name="cross"
                      fill={theme`colors.icon-primary`}
                      onClick={() => {
                        onChange(paramValidation?.defaultValue);
                        setShowEditMode(false);
                      }}
                    />
                  )}
                />
              </div>
            )}
          </div>
          {error?.value && (
            <TextInput.Error>{error.value.message}</TextInput.Error>
          )}
        </TextInput.Container>
      ) : (
        <ComplianceParamDescription
          isNoticeAfterProbation={isNoticeAfterProbation}
          isMaltaFixedTermContract={isMaltaFixedTermContract}
          paramEnabled={paramEnabled}
          isClauseEditable={isClauseEditable}
          clauseDefaultValue={clauseDefaultValue}
          field={field}
          complianceParamDefinition={complianceParamDefinition}
          setShowEditMode={setShowEditMode}
          contractType={contractType}
          isGermanyContract={isGermanyContract}
        />
      )}
      {isNoticeAfterProbation && (
        <NoticePeriodTooltip
          contractType={contractType}
          paramValue={paramValue}
          unit={field.unit}
          isMaltaFixedTermContract={isMaltaFixedTermContract}
          isGermanyContract={isGermanyContract}
        />
      )}
      {isProbationPolicy && compensation?.probationBasePay?.amount !== null && (
        <div
          css={[
            tw`rounded-base flex flex-row p-small text-ps text-text-primary gap-x-small bg-background-primary`,
            !isNewThemeApplied && tw`bg-primary bg-opacity-5`,
          ]}
        >
          <Icon name="info" tw="h-extra-large w-extra-large" />
          <span>
            <Trans t={t} i18nKey="compliance.probation-policy.info">
              To turn off / on the Probation period, please go to the
              compensation section, turn off the post-probation salary toggle,
              and revise the Gross Salary.
            </Trans>
          </span>
        </div>
      )}
    </FormCard>
  );
};

export default ComplianceEntry;
