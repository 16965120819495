import { gql } from '@apollo/client';

import importMetaEnv from 'import-meta-env';

export default gql`
  mutation SubmitHubspotForm($input: SubmitHubspotFormInput) {
    submitHubspotForm(input: $input)
      @rest(
          type: "HubspotFormSubmissionResponse"
          method: "POST"
          path: "submissions/v3/integration/submit/${importMetaEnv.VITE_HUBSPOT_PORTAL_ID}/${importMetaEnv.VITE_HUBSPOT_FORM_GUID}"
          endpoint: "hubspot"
      ) {
        inlineMessage
    }
  }
`;
