/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button, CardSeparator } from '@multiplier/common';
import tw from 'twin.macro';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import TextInput from 'common/components/text-input';
import Textarea from 'common/components/textarea';
import { GET_CONTRACT } from 'contract-onboarding/queries';

import {
  Contract,
  useGetConfigurationQuery,
  useRequestContractChangesMutation,
} from '__generated__/graphql';

import { ContractPageType } from '../progress-widget-content/view-send-contract-action';

const NoteContainer = tw.div`
  flex flex-col
`;

const Notes = tw.div`
   flex flex-col p-24 gap-y-8 bg-grey05 rounded-8 text-ps text-slateGrey900 font-medium
`;

const NoteItem = tw.p``;

const RequestChangeInputContainer = tw.div`
  flex flex-col pt-32 h-full
`;

const Heading = tw.span`
  flex font-semibold text-h6 text-background
`;

const BottomContainer = tw.div`
   flex flex-col justify-center
`;

const BoldText = tw.b``;

const RequestChangesForm: React.FC<{
  contractId: Contract['id'];
  setContractModalPageType: React.Dispatch<
    React.SetStateAction<ContractPageType>
  >;
  requestChangeContent: string;
  setRequestChangeContent: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
}> = ({
  contractId,
  setContractModalPageType,
  requestChangeContent,
  setRequestChangeContent,
  onClose,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { data } = useGetConfigurationQuery({
    variables: {
      filters: {
        contractId,
      },
    },
  });

  const getTatTimeInDays = useMemo(() => {
    const tatInHours =
      data?.configuration?.contractChangeRequestConfig?.turnAroundTimeInHours;
    if (tatInHours) return tatInHours / 24;

    return 3;
  }, [data]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ reason: string }>();

  const [requestChanges, { loading }] = useRequestContractChangesMutation({
    onError: () => {
      errorNotification(
        '',
        t(
          'onboarding-phase.request-changes.failed',
          'Failed to Requested for Changes',
        ),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t(
          'onboarding-phase.request-changes.success',
          'Successfully Requested for Changes',
        ),
        false,
      );
      onClose();
    },
    refetchQueries: [{ query: GET_CONTRACT, variables: { id: contractId } }],
  });

  const handleRequestChanges = async (values: { reason: string }) => {
    if (contractId) {
      await requestChanges({
        variables: {
          id: contractId,
          reason: values.reason,
        },
      });
    }
  };

  return (
    <div tw="flex flex-col mx-72 h-full">
      <NoteContainer>
        <span tw="flex mb-8 text-ps font-semibold text-slateGrey900">
          Please Note:
        </span>
        <Trans t={t} i18nKey="request-changes.note">
          <Notes>
            <NoteItem>
              1. Any changes requested in the contract will require{' '}
              <BoldText>minimum {{ getTatTimeInDays }} working days</BoldText>{' '}
              to implement.
            </NoteItem>
            <NoteItem>
              2. Any changes requested in the contract will{' '}
              <BoldText>disable</BoldText> the ability to edit, delete, and send
              contract.
            </NoteItem>
            <NoteItem>
              3. You can view the contract under the Blocked Tab in the Teams
              view.
            </NoteItem>
            <NoteItem>
              4. The contract will be <BoldText>re-enabled</BoldText> to edit,
              delete, and send post the requested changes are implemented.
            </NoteItem>
          </Notes>
        </Trans>
      </NoteContainer>

      <RequestChangeInputContainer>
        <Heading>
          {t(
            'request-change-input.heading',
            'Add only legal, compliance, and template changes to the contract below:',
          )}
        </Heading>
        <form
          tw="pt-12 pb-24 flex flex-col justify-between h-full"
          onSubmit={handleSubmit(handleRequestChanges)}
        >
          <TextInput.Container>
            <Textarea
              tw="border-none p-0 border-transparent focus:border-transparent focus:ring-0 focus:shadow-none"
              placeholder={t(
                'onboarding-phase.contract-viewer.request-change-input.placeholder',
                'Type or paste here...',
              )}
              rows={15}
              {...register('reason')}
              value={requestChangeContent}
              onChange={(e) => setRequestChangeContent(e.target.value)}
            />
            {errors.reason && (
              <TextInput.Error>{errors.reason.message}</TextInput.Error>
            )}
          </TextInput.Container>

          <BottomContainer>
            <CardSeparator tw="mb-24" />
            <Heading tw="justify-center">
              {t(
                'footer.request-changes.heading',
                'Are you sure you want to request these changes?',
              )}
            </Heading>
            <div tw="flex flex-row justify-center gap-x-12 mt-24">
              <Button
                variant="outline"
                size="medium"
                onClick={() =>
                  setContractModalPageType(ContractPageType.MAIN_PAGE)
                }
              >
                {t(
                  'onboarding-phase.contract-viewer.request-changes.cancel',
                  'Cancel',
                )}
              </Button>
              <Button
                type="submit"
                variant="default"
                size="medium"
                loading={loading}
                disabled={requestChangeContent.length === 0 || loading}
              >
                {t(
                  'onboarding-phase.contract-viewer.request-changes.submit',
                  'Confirm changes',
                )}
              </Button>
            </div>
          </BottomContainer>
        </form>
      </RequestChangeInputContainer>
    </div>
  );
};

export default RequestChangesForm;
