import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingContractWetInkPreparing: ContractOnboardingStepConfig = {
  title: (t): string =>
    t(
      'onboarding-phase.onboarding-contract-wetink-preparing.title',
      'Preparing Contract',
    ),
  status: (t): string =>
    t(
      'onboarding-phase.onboarding-contract-wetink-preparing.status',
      'Pending on Multiplier',
    ),
  variant: ProgressWidgetContentVariant.DESCRIPTION,
};

export default onboardingContractWetInkPreparing;
