import { OnboardingTask } from '../../../__generated__/graphql';

export enum TaskType {
  SIGN_MSA = 'SIGN_MSA',
  PAY_DEPOSIT = 'PAY_DEPOSIT',
  COMPANY_COMPLETE_ONBOARDING = 'COMPANY_COMPLETE_ONBOARDING',
  SEND_CONTRACT = 'SEND_CONTRACT',
  SIGN_CONTRACT = 'SIGN_CONTRACT',
  PLATFORM_ONBOARDING = 'PLATFORM_ONBOARDING',
  KYC_DETAILS = 'KYC_DETAILS_AND_DOCUMENTS',
  PAYROLL_FORMS = 'PAYROLL_AND_COMPLIANCE_FORMS',
  EMPLOYEE_ID_AND_WORK_EMAIL = 'EMPLOYEE_ID_AND_WORK_EMAIL',
}

export interface TaskEntryProps {
  task: OnboardingTask;
  contractId?: string;
  actionable?: boolean;
  handleCloseSidePanel?: () => void;
  isResendInviteVisible?: boolean;
}

// TODO: Extract Team View into separate package
export enum TeamViewTab {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
  INACTIVE = 'INACTIVE',
  OFFBOARDING = 'OFFBOARDING',
  ENDED = 'ENDED',
  ALL = 'ALL',
  BLOCKED = 'BLOCKED',
}
