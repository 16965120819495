import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { timezoneRemove } from '@multiplier/format';
import { format } from 'date-fns';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import { notEmpty } from 'app/utils/array';
import routes from 'contract-onboarding/member/routes';
import { getNextStep } from 'contract-onboarding/utils/routing';

import {
  ContractBenefitDependentDocumentType,
  ContractOnboardingStep,
  useContractMemberBenefitCompletedMutation as useBenefitCompleted,
  // ContractOnboardingStep,
  useUpdateContractBenefitDependentBasicDetailsMutation as useDependentUpdate,
  useUpdateContractBenefitDependentDocumentsMutation as useDocumentsUpdate,
} from '../../../__generated__/graphql';
import { InsuranceDetailsPrams } from '../pages/onboarding/pages/insurance-details';
import stepConfig from '../pages/onboarding/step-config';

const datePostFix = 'T00:00:00';

const useUpdateInsuranceDetails = (): {
  loading: boolean;
  onSubmit: (
    insuranceInputData: InsuranceDetailsPrams,
    benefitId?: string | null,
  ) => void;
} => {
  const { t } = useTranslation('contract-onboarding.member');
  const navigate = useNavigate();
  const location = useLocation();

  const [updateDocuments, { loading: documentsLoading }] = useDocumentsUpdate();

  const [updateDependant, { loading }] = useDependentUpdate({
    onCompleted() {
      successNotification(
        '',
        t(
          'insurance-details.updated-success-message',
          'Insurance details updated',
        ),
        false,
      );
    },
    onError() {
      errorNotification(
        '',
        t(
          'insurance-details.update-error-message',
          'Failed to update insurance details',
        ),
        false,
      );
    },
  });

  const [benefitCompleted] = useBenefitCompleted();

  const onSubmit = async (
    insuranceInputData: InsuranceDetailsPrams,
    benefitId?: string | null,
  ) => {
    if (!benefitId) return;
    const updatedData = await updateDependant({
      variables: {
        contractBenefitId: benefitId,
        input: insuranceInputData.insuranceDetails.map((item) => ({
          contractBenefitDependentId: item?.id,
          firstName: item.firstName,
          lastName: item.lastName,
          gender: item.gender,
          dateOfBirth: item.dateOfBirth
            ? format(timezoneRemove(item.dateOfBirth), 'yyyy-MM-dd') +
              datePostFix
            : null,
          relationshipToMember: item.relationship,
          legalData: [
            {
              key: 'nationalIdNumber',
              value: item.nationalIdNumber,
            },
          ],
        })),
      },
    });

    const { benefits, id } =
      updatedData.data?.contractUpdateBenefitDependentBasicDetails ?? {};

    const benefitDependantDocuments = benefits
      ?.filter(notEmpty)
      .map((item) =>
        item?.dependents?.filter(notEmpty).map((dependant) => {
          const { firstName, lastName, gender } = dependant;
          // finding browser side data for the dependant to extract the correct document
          const browserDependantDetails = insuranceInputData.insuranceDetails.find(
            (i) =>
              i.firstName === firstName &&
              i.lastName === lastName &&
              i.gender === gender,
          );
          return {
            contractBenefitDependentId: dependant?.id,
            documents: browserDependantDetails?.identity,
            key: ContractBenefitDependentDocumentType.PROOF_OF_IDENTITY,
          };
        }),
      )
      .flat();

    if (benefitDependantDocuments) {
      await Promise.all(
        benefitDependantDocuments.filter(notEmpty).map(async (e) => {
          if (e.documents)
            await updateDocuments({
              variables: {
                contractBenefitDependentId: e.contractBenefitDependentId ?? '',
                key: e.key,
                documents: e.documents,
              },
            });
        }),
      );
    }

    if (location.pathname.includes(routes.onboarding.benefitDetails)) {
      const benefitCompletedResult = await benefitCompleted({
        variables: {
          contractId: id ?? '',
        },
      });

      const onboarding =
        benefitCompletedResult?.data?.contractMemberBenefitCompleted
          ?.onboarding;

      const next = getNextStep(
        onboarding,
        ContractOnboardingStep.MEMBER_BENEFIT_DETAILS,
      );
      if (next && stepConfig[next]) {
        navigate(stepConfig[next].directLink());
      }
    }
  };

  return { loading: loading || documentsLoading, onSubmit };
};

export default useUpdateInsuranceDetails;
