import {
  useAssignManagerMutation,
  useValidateReportingManagerMutation,
} from '../__generated__/graphql';

const useValidateAndAssignManager = (): {
  isValidating: boolean;
  assigningManager: boolean;
  handleAssignManager: (
    managerToAssign: string,
    contractId: string,
    managerId: string | undefined,
  ) => Promise<void>;
} => {
  const [
    triggerValidateManager,
    { loading: isValidating },
  ] = useValidateReportingManagerMutation();

  const [
    assignManager,
    { loading: assigningManager },
  ] = useAssignManagerMutation();

  const validateManager = async (
    reportsToManagerId: string,
    contractId: string,
    managerId: string | undefined,
  ) => {
    const resp = await triggerValidateManager({
      variables: {
        input: {
          reportsToManagerId,
          contractId,
          managerId,
        },
      },
    });
    return {
      isValid: Boolean(resp.data?.orgDirectoryValidateReportsTo?.success),
      error: resp.data?.orgDirectoryValidateReportsTo?.message,
    };
  };

  const handleAssignManager = async (
    managerToAssign: string,
    contractId: string,
    managerId: string | undefined,
  ) => {
    if (!managerToAssign) return;

    const { isValid } = await validateManager(
      managerToAssign,
      contractId,
      managerId,
    );

    if (isValid) {
      assignManager({
        variables: {
          input: {
            reportsToManagerId: managerToAssign,
            contractId,
            managerId,
          },
        },
      });
    }
  };

  return {
    isValidating,
    assigningManager,
    handleAssignManager,
  };
};

export default useValidateAndAssignManager;
