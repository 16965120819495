/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck FIXME: Need to upgrade "react-pdf" to more newer version to resolve component types issue
import React, { useCallback, useMemo, useState } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';

import { DocumentReadable } from '../__generated__/graphql';
import Button from '../button';
import DocumentViewer from '../document-viewer';
import workerContent from '../pdf.worker.min.json';
import { Maybe } from '../types';
import { documentIsPDF, downloadBlobAsPdf } from '../utils';

const workerBlob = new Blob([workerContent], {
  type: 'text/javascript',
});
pdfjs.GlobalWorkerOptions.workerSrc = URL.createObjectURL(workerBlob);

const DocumentPreviewModal: React.FC<{
  open: boolean;
  onClose: () => void;
  title?: string;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  document?: Maybe<DocumentReadable>;
  loading?: boolean;
}> = ({ open, onClose, header, title, document, loading = false, footer }) => {
  const { t } = useTranslation('insurance.member');
  const [pageCount, setPageCount] = useState<number | null>(null);

  const fileToRender = useMemo(
    () => ({
      ...document,
      name: document?.name ?? title,
    }),
    [document],
  );

  const handleDownloadClick = useCallback(() => {
    if (!fileToRender) return;
    const { link, blob } = fileToRender;
    if (link) {
      window.open(link, '_blank');
    } else if (blob) {
      downloadBlobAsPdf(blob, fileToRender.name ?? '');
    }
  }, [fileToRender]);

  return (
    <DocumentViewer
      open={open}
      onClose={onClose}
      header={title ?? t('document-viewer.header', 'Document')}
      secondaryButton={
        <Button size="medium" variant="outline" onClick={handleDownloadClick}>
          {t('document-viewer.download', 'Download')}
        </Button>
      }
      loading={loading}
    >
      {header}
      {(fileToRender?.blob || fileToRender?.link) && (
        <>
          {documentIsPDF(fileToRender) ? (
            <Document
              file={
                fileToRender?.link
                  ? { url: fileToRender?.link }
                  : `data:application/pdf;base64,${fileToRender.blob}`
              }
              onLoadSuccess={({ numPages }) => setPageCount(numPages)}
              renderMode="svg"
            >
              {Array.from(new Array(pageCount), (_, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={809}
                />
              ))}
            </Document>
          ) : (
            <img
              alt="Document preview"
              src={
                fileToRender?.link
                  ? fileToRender?.link
                  : `data:image/png;base64,${fileToRender.blob}`
              }
            />
          )}
        </>
      )}
      {footer}
    </DocumentViewer>
  );
};

export default DocumentPreviewModal;
