import { gql } from '@apollo/client';

export default gql`
  query GetCompanyUsersRole {
    company {
      id
      companyUsers {
        userId
        roles
      }
    }
  }
`;
