/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from 'common/components/text-input';

import { BasicDetailsFormValues } from '../basic-details';

const FirstNameInput: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    register,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="first-name">
        {t('definition-phase.basic-details.first-name.label', 'First Name')}
      </TextInput.Label>
      <TextInput
        id="first-name"
        placeholder={t(
          'definition-phase.basic-details.first-name.placeholder',
          'John',
        )}
        {...register('firstName')}
        error={!!errors.firstName}
        autoComplete="off"
      />
      {errors.firstName && (
        <TextInput.Error>{errors.firstName.message}</TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default FirstNameInput;
