import i18n from 'i18n';

export enum InsuranceFeature {
  INPATIENT = 'Inpatient',
  OUTPATIENT = 'Outpatient',
  DENTAL = 'Dental',
  DEPENDENTS = 'dependents',
  VISION = 'Vision',
  INPATIENT_OUTPATIENT = 'Inpatient & Outpatient',
  TERM_LIFE_GPA = 'Term Life + GPA',
  DENTAL_VISION = 'Dental + Vision',
  EMERGENCY_ASSISTANCE_ABROAD = 'Emergency assistance abroad',
  MEDICAL_REIMBURSEMENT = 'Medical Reimbursement Cover',
  PHARMACY_REIMBURSEMENT = 'Pharmacy Reimbursement Cover',
  GLOBAL_COVER = 'Global Cover',
  INTERNATIONAL_EMERGENCY_MEDICAL_ASSISTANCE = 'International Emergency Medical Assistance',
  DAILY_HOSPITAL_CASH_ALLOWANCE = 'Daily Hospital Cash Allowance',
  LIFE_INSURANCE = 'Life Insurance',
  DISABILITY_INCOME_INSURANCE = 'Disability Income Insurance',
  TRAVEL = 'Travel',
  MENTAL_WELLNESS = 'Mental Wellness',
  MATERNITY_AND_NEWBORN_COVER = 'Maternity and Newborn Cover',
}

export const featureLabels = {
  [InsuranceFeature.INPATIENT]: {
    label: i18n.t(
      'insurance:insurance-feature.inpatient.label',
      'Inpatient Treatments',
    ),
    description: i18n.t(
      'insurance:insurance-feature.inpatient.description',
      'Cover for treatment that requires hospitalisation',
    ),
  },
  [InsuranceFeature.OUTPATIENT]: {
    label: i18n.t(
      'insurance:insurance-feature.outpatient.label',
      'Outpatient Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.outpatient.description',
      'Cover for visit to GP (General Physician).',
    ),
  },
  [InsuranceFeature.DENTAL]: {
    label: i18n.t(
      'insurance:insurance-feature.dental.label',
      'Dental Coverage',
    ),
    description: i18n.t(
      'insurance:insurance-feature.dental.description',
      'Cover for dental procedures and treatments.',
    ),
  },
  [InsuranceFeature.VISION]: {
    label: i18n.t('insurance:insurance-feature.vision.label', 'Vision Cover'),
    description: i18n.t(
      'insurance:insurance-feature.vision.description',
      'Provides reimbursement for prescription glasses.',
    ),
  },
  [InsuranceFeature.INPATIENT_OUTPATIENT]: {
    label: i18n.t(
      'insurance:insurance-feature.inpatient-outpatient.label',
      'Inpatient & Outpatient Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.inpatient-outpatient.description',
      'Covers inpatient and outpatient hospitalisation expenses.',
    ),
  },
  [InsuranceFeature.TERM_LIFE_GPA]: {
    label: i18n.t(
      'insurance:insurance-feature.term-life-gpa.label',
      'Term Life & GPA Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.term-life-gpa.description',
      'Provides death coverage and accidental dismemberment benefits.',
    ),
  },
  [InsuranceFeature.DENTAL_VISION]: {
    label: i18n.t(
      'insurance:insurance-feature.dental-vision.label',
      'Dental & Vision Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.dental-vision.description',
      'Covers for dental expenses and provides reimbursement for prescription glasses.',
    ),
  },
  [InsuranceFeature.DEPENDENTS]: {
    label: i18n.t('insurance:insurance-feature.dependents.label', 'Dependents'),
    description: i18n.t(
      'insurance:insurance-feature.dependents.description',
      'Includes cover for dependents',
    ),
  },
  [InsuranceFeature.EMERGENCY_ASSISTANCE_ABROAD]: {
    label: i18n.t(
      'insurance:insurance-feature.emergency-assistance-abroad.label',
      'Emergency Assistance Abroad Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.emergency-assistance-abroad.description',
      'Complete coverage abroad up to €15,000',
    ),
  },
  [InsuranceFeature.MEDICAL_REIMBURSEMENT]: {
    label: i18n.t(
      'insurance:insurance-feature.medical-reimbursement-cover.label',
      'Medical Reimbursement Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.medical-reimbursement-cover.description',
      'In any hospital outside our network.',
    ),
  },
  [InsuranceFeature.PHARMACY_REIMBURSEMENT]: {
    label: i18n.t(
      'insurance:insurance-feature.pharmacy-reimbursement-cover.label',
      'Pharmacy Reimbursement Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.pharmacy-reimbursement-cover.description',
      'Cost of the medicine up to €200.',
    ),
  },
  [InsuranceFeature.GLOBAL_COVER]: {
    label: i18n.t(
      'insurance:insurance-feature.global-cover.label',
      'Global Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.global-cover.description',
      'Excluding USA and Canada.',
    ),
  },
  [InsuranceFeature.INTERNATIONAL_EMERGENCY_MEDICAL_ASSISTANCE]: {
    label: i18n.t(
      'insurance:insurance-feature.international-emergency-medical-assistance.label',
      'International Emergency Medical Assistance',
    ),
    description: i18n.t(
      'insurance:insurance-feature.international-emergency-medical-assistance.description',
      'Evacuation & Repatriation, and Mortal Remains.',
    ),
  },
  [InsuranceFeature.DAILY_HOSPITAL_CASH_ALLOWANCE]: {
    label: i18n.t(
      'insurance:insurance-feature.daily-hospital-cash-allowance.label',
      'Daily Hospital Cash Allowance',
    ),
    description: i18n.t(
      'insurance:insurance-feature.daily-hospital-cash-allowance.description',
      'Maximum for 20 days.',
    ),
  },
  [InsuranceFeature.LIFE_INSURANCE]: {
    label: i18n.t(
      'insurance:insurance-feature.life-insurance.label',
      'Life Insurance',
    ),
    description: i18n.t(
      'insurance:insurance-feature.life-insurance.description',
      'Cover 5x of Basic Annual Salary.',
    ),
  },
  [InsuranceFeature.DISABILITY_INCOME_INSURANCE]: {
    label: i18n.t(
      'insurance:insurance-feature.disability-income-insurance.label',
      'Disability Income Insurance',
    ),
    description: i18n.t(
      'insurance:insurance-feature.disability-income-insurance.description',
      'Cover 70% of Basic Annual Salary.',
    ),
  },
  [InsuranceFeature.TRAVEL]: {
    label: i18n.t(
      'insurance:insurance-feature.travel.label',
      'International Travel Assistance',
    ),
    description: i18n.t(
      'insurance:insurance-feature.travel.description',
      'Special Coverage for International Travel.',
    ),
  },
  [InsuranceFeature.MENTAL_WELLNESS]: {
    label: i18n.t(
      'insurance:insurance-feature.mental-wellness.label',
      'Mental Wellness',
    ),
    description: i18n.t(
      'insurance:insurance-feature.mental-wellness.description',
      '1-on-1 mental health counselling by licensed coaches',
    ),
  },
  [InsuranceFeature.MATERNITY_AND_NEWBORN_COVER]: {
    label: i18n.t(
      'insurance:insurance-feature.maternity-and-newborn-cover.label',
      'Maternity and Newborn Cover',
    ),
    description: i18n.t(
      'insurance:insurance-feature.maternity-and-newborn-cover.description',
      'Cover for maternity with pre-post natal expenses.',
    ),
  },
};
