import { useState } from 'react';

import { LazyQueryResultTuple, QueryResult } from '@apollo/client';

const mockRequestLazy = <TData = any, TVariables = any>({
  data,
  delay = 1000,
}: {
  data?: TData;
  delay?: number;
}): LazyQueryResultTuple<TData, TVariables> => {
  const [_data, setData] = useState<TData>();
  const [loading, setLoading] = useState(false);

  const executeRequest = ({
    onCompleted = () => null,
  }: { onCompleted?: (data: TData | undefined) => void } = {}) =>
    new Promise<QueryResult<TData, TVariables>>((resolve) => {
      setLoading(true);

      setTimeout(() => {
        setData(data);
        setLoading(false);
        resolve({ data, loading: false } as QueryResult<TData, TVariables>);
        onCompleted(data);
      }, delay);
    });

  return [
    executeRequest,
    {
      data: _data,
      loading,
    },
  ] as LazyQueryResultTuple<TData, TVariables>;
};

export default mockRequestLazy;
