/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { Button, ThemeContext } from '@multiplier/common';
import regexifyString from 'regexify-string';
import tw from 'twin.macro';

import { assureNumber } from 'app/utils/number';
import countryLabels from 'common/constants/country-labels';
import internalLeaveTypes from 'contract-onboarding/constants/internal-leave-types';

import {
  ContractStatus,
  ContractTimeOffEntitlement,
  CountryCode,
  InsightCategory,
  Maybe,
  TimeOffTypeDefinition,
  TimeOffUnit,
} from '__generated__/graphql';

import { CompliancePageInsightViewModel } from '../../company/pages/definition-phase/pages/compliance';
import {
  hasLeaveEntitlementsBesidesUnpaidType,
  hasMandatoryFieldsBesidesUnpaidType,
} from '../../services/leave-entitlement';
import { ComplianceFormValues } from '../contract-section';
import { FormCard, FormContainer, FormTitle } from '../layout';

const CustomLeaveEntry = React.lazy(() => import('./custom-leave-entry'));
const InitialCustomLeaveEntry = React.lazy(
  () => import('./initial-custom-leave-entry'),
);
const LeaveEntitlementViewMode = React.lazy(
  () => import('./leave-entitlement-view-mode'),
);
const MandatoryLeaveEntry = React.lazy(() => import('./mandatory-leave-entry'));

export interface LeaveEntitlementFormEntryValue {
  key: string;
  value: number | null;
  unit: TimeOffUnit;
  defaultValue: number;
  maximum: number | null | undefined;
  allowedContractStatuses?: Maybe<Maybe<ContractStatus>[]> | undefined;
}

export interface LeaveEntitlementFormValues {
  mandatory: LeaveEntitlementFormEntryValue[];
  custom: LeaveEntitlementFormEntryValue[];
}

const LeaveEntitlement: React.FC<{
  insights?: CompliancePageInsightViewModel[InsightCategory.TIME_OFF];
  country?: Maybe<CountryCode>;
  assignedTimeOffEntitlements:
    | Maybe<Maybe<ContractTimeOffEntitlement>[]>
    | undefined;
  timeOffTypeToDefinitionMap: Record<string, TimeOffTypeDefinition>;
  availableCustomEntitlementOptions: {
    title: string;
    value: string;
  }[];
  clause: Maybe<string>;
}> = ({
  insights,
  country,
  clause,
  availableCustomEntitlementOptions,
  assignedTimeOffEntitlements,
  timeOffTypeToDefinitionMap,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const [edit, toggleEdit] = useToggle(false);

  const { reset, getValues, setValue, resetField, watch } = useFormContext();

  const { fields: mandatoryFields } = useFieldArray<
    Pick<ComplianceFormValues, 'mandatory'>
  >({
    name: 'mandatory',
  });

  const { fields: customFields, append, remove } = useFieldArray<
    Pick<ComplianceFormValues, 'custom'>
  >({
    name: 'custom',
  });

  const timeOffsMap = useMemo<Record<string, number>>(
    () =>
      assignedTimeOffEntitlements?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr?.timeOffType?.type || '_']: curr?.value || 0,
        }),
        { _: 0 },
      ) || {},
    [assignedTimeOffEntitlements],
  );

  const resetLeaveEntitlements = () => {
    mandatoryFields.forEach((field, index) =>
      setValue(
        `mandatory.${index}.value`,
        timeOffsMap[field.key] - field.defaultValue || null,
      ),
    );

    resetField('custom');

    customFields.forEach((field, index) => {
      if (timeOffsMap[field.key])
        setValue(`custom.${index}.value`, timeOffsMap[field.key]);
    });

    const toRemoveCustomFields = customFields
      .map((field, index) => ({ ...field, index }))
      .reduce((prev, field) => {
        if (timeOffsMap[field.key]) return prev;
        return [...prev, field.index];
      }, [] as number[]);
    remove(toRemoveCustomFields);
  };

  const shouldBeVisible = !!clause;

  if (!shouldBeVisible) return null;

  const shouldShowWhenOnboarding = (
    field: FieldArrayWithId<LeaveEntitlementFormValues, 'mandatory'>,
  ) => !internalLeaveTypes.includes(field.key);

  return (
    <FormCard tw="gap-y-base" data-testid="leave-entitlement">
      <FormContainer>
        <FormTitle>
          {t('compliance.leave-entitlement.header', 'Leave Entitlement')}
        </FormTitle>
        {!edit && (
          <Button
            aria-label="edit"
            size="medium"
            variant="outline"
            onClick={toggleEdit}
          >
            {t('compliance.leave-entitlement.edit-leaves', '+ Add/Edit Leaves')}
          </Button>
        )}
      </FormContainer>
      {hasLeaveEntitlementsBesidesUnpaidType(assignedTimeOffEntitlements) && (
        <span
          css={[
            tw`text-ps text-text-secondary`,
            !isNewThemeApplied && tw`text-grey01`,
          ]}
        >
          {t('compliance.leave-entitlement.description', {
            defaultValue:
              'According to Employment laws in {{country}}, we need to offer these below leaves:',
            country: countryLabels[country as string],
            interpolation: { escapeValue: false },
          })}
        </span>
      )}
      {edit ? (
        <div data-testid="edit-mode" tw="flex flex-col gap-y-base">
          <div
            css={[
              tw`rounded-base bg-background-warning-subtle bg-opacity-10 px-extra-large py-large text-ps text-text-primary`,
              !isNewThemeApplied && tw`bg-highlight`,
            ]}
          >
            {regexifyString({
              pattern: /\\n/g,
              decorator: (_, index) => <br key={index} />,
              input: clause ?? '',
            })}
          </div>
          {hasMandatoryFieldsBesidesUnpaidType(mandatoryFields) && (
            <div
              css={[
                tw`grid grid-template-columns[auto repeat(3,max-content)] items-center gap-y-small gap-x-extra-large text-ps text-text-secondary`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              <span>
                {t(
                  'compliance.leave-entitlement.mandatory.leave-type',
                  'Leave Type',
                )}
              </span>
              <span>
                {t(
                  'compliance.leave-entitlement.mandatory.allocated',
                  'Allocated',
                )}
              </span>
              <span>
                {t(
                  'compliance.leave-entitlement.mandatory.additional',
                  'Additional',
                )}
              </span>
              <span>
                {t('compliance.leave-entitlement.mandatory.total', 'Total')}
              </span>
              {mandatoryFields?.map(
                (field, index) =>
                  field?.key &&
                  shouldShowWhenOnboarding(field) && (
                    <MandatoryLeaveEntry
                      key={field.id}
                      field={field}
                      index={index}
                      insight={insights?.[field?.key]}
                      timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
                    />
                  ),
              )}
            </div>
          )}
          {availableCustomEntitlementOptions &&
            availableCustomEntitlementOptions.length > 0 && (
              <div tw="flex flex-col gap-y-small text-ps">
                <span tw="text-text-primary">
                  {`${t(
                    'compliance.leave-entitlement.custom.header',
                    'Custom Leaves',
                  )}:`}
                </span>
                <div tw="grid grid-template-columns[auto auto min-content] items-center gap-y-small gap-x-small text-text-primary font-medium">
                  <span>
                    {t(
                      'compliance.leave-entitlement.custom.leave-type',
                      'Leave Type',
                    )}
                  </span>
                  <span>
                    {t('compliance.leave-entitlement.custom.days', 'Days')}
                  </span>
                  <span />
                  {customFields.map((field, index) => (
                    <CustomLeaveEntry
                      key={field.id}
                      index={index}
                      field={field}
                      remove={remove}
                      availableCustomEntitlementOptions={
                        availableCustomEntitlementOptions
                      }
                      timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
                    />
                  ))}
                  <InitialCustomLeaveEntry
                    append={append}
                    availableCustomEntitlementOptions={
                      availableCustomEntitlementOptions
                    }
                    timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
                  />
                </div>
              </div>
            )}
          <div tw="flex flex-row justify-center gap-x-small">
            <Button
              size="medium"
              aria-label="cancel-leave-entitlement"
              variant="outline"
              onClick={() => {
                resetLeaveEntitlements();
                // Save form state after reset to server state
                reset(getValues());
                toggleEdit();
              }}
            >
              {t('compliance.leave-entitlement.cancel', 'Cancel')}
            </Button>
            <Button
              type="button"
              aria-label="submit-leave-entitlement"
              size="medium"
              variant="default"
              onClick={() => {
                reset({
                  ...getValues(),
                  custom: watch('custom').map(
                    (field: LeaveEntitlementFormEntryValue) => ({
                      ...field,
                      value: assureNumber(field.value),
                    }),
                  ),
                });
                toggleEdit();
              }}
            >
              {t('compliance.leave-entitlement.update', 'Update Leaves')}
            </Button>
          </div>
        </div>
      ) : (
        <LeaveEntitlementViewMode
          assignedTimeOffEntitlements={assignedTimeOffEntitlements}
          insights={insights}
          timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
        />
      )}
    </FormCard>
  );
};

export default LeaveEntitlement;
