import { gql } from '@apollo/client';

export default gql`
  query GetMemberComplianceType {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        compliance {
          type
        }
      }
    }
  }
`;
