import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import routes from 'payroll/company/routes';

import PayrollSidebarEntry from './sidebar-entry';

const config: ModuleConfig = {
  id: 'payroll',
  permission: 'payroll',
  folder: 'payroll/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Payroll',
    route: routes.root,
    sort: 4,
    home: false,
    icon: { name: 'calendar-today' },
    activeIcon: { name: 'calendar-today-filled' },
    group: NavGroups.MAIN,
    custom: PayrollSidebarEntry,
  },
  featureFlag: 'company-payroll-module',
  featureFlagDefaultValue: false,
};

export default config;
