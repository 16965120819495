/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { add, startOfMonth } from 'date-fns';
import tw from 'twin.macro';
import * as yup from 'yup';

import Checkbox from 'common/components/checkbox';
import DatePicker from 'common/components/date-picker';
import Dialog from 'common/components/dialog';

import {
  Contract,
  ContractOffboardMutationVariables,
  ContractTerm,
} from '__generated__/graphql';

interface EditOffboardingDateDialogProps {
  contract: Contract | undefined;
  show: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: (
    endDate: ContractOffboardMutationVariables['lastWorkingDay'],
  ) => void;
}

const getMinMaxDate = (
  endedOn: Contract['endedOn'],
  endOn: Contract['endOn'],
  term: Contract['term'],
) => {
  const today = new Date();

  if (term === ContractTerm.FIXED) {
    const end = endOn ?? endedOn;
    return { minDate: startOfMonth(today), maxDate: end };
  }

  return { minDate: startOfMonth(today), maxDate: add(today, { years: 1 }) };
};

const getFormSchema = (
  today: Date,
  endedOn: Contract['endedOn'] | null,
  endOn: Contract['endOn'] | null,
  term: Contract['term'],
) => {
  const { minDate, maxDate } = getMinMaxDate(endedOn, endOn, term);

  if (endedOn && term === ContractTerm.FIXED) {
    return yup.object().shape({
      endDate: yup
        .date()
        .min(minDate)
        .max(maxDate)
        .required()
        .typeError('Please provide a valid end date'),
    });
  }

  return yup.object().shape({
    endDate: yup
      .date()
      .min(minDate)
      .max(maxDate)
      .required()
      .typeError('Please provide a valid end date'),
  });
};

const EditOffboardingDateDialog = ({
  contract,
  show,
  loading,
  onClose,
  onConfirm,
}: EditOffboardingDateDialogProps): React.ReactElement | null => {
  const { t } = useTranslation('team.company');
  const [consent, setConsent] = useState(false);

  const today = new Date();

  const defaultEndDate = useMemo(() => {
    const endedOn = contract?.endedOn ? contract?.endedOn.split('T')[0] : null;
    return endedOn ? new Date(endedOn) : new Date();
  }, [contract?.endedOn]);

  const schema = getFormSchema(
    today,
    defaultEndDate,
    contract?.endOn,
    contract?.term,
  );

  const { minDate, maxDate } = getMinMaxDate(
    defaultEndDate,
    contract?.endOn,
    contract?.term,
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{
    endDate: Date;
  }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleConfirmClick = () => {
    handleSubmit(({ endDate }) => {
      onConfirm(endDate);
    })();
  };

  return (
    <Dialog
      open={show}
      loading={loading}
      disabled={!(isValid && consent)}
      onClose={onClose}
      onConfirm={handleConfirmClick}
      title={t('edit-offboarding-date-dialog.title', 'Edit last working day?')}
      buttonText={t('edit-offboarding-date-dialog.confirm', 'Save Changes')}
      containerStyle={tw`overflow-visible`}
    >
      <form>
        <div tw="text-background text-ps mb-12 mt-28">
          {t(
            'edit-offboarding-date-dialog.last-working-day-label',
            "Select Employee's last working day",
          )}
        </div>
        <Controller
          control={control}
          defaultValue={defaultEndDate}
          name="endDate"
          render={({ field: { value, onChange } }) => (
            <DatePicker
              data-testid="endDate"
              tw="mb-40 z-50"
              value={value}
              onChange={onChange}
              min={minDate}
              max={maxDate}
              error={!!errors.endDate}
              helperText={errors.endDate?.message}
            />
          )}
        />
      </form>
      <div tw="flex mb-48">
        <Checkbox
          data-testid="consent"
          checkboxSize="large"
          tw="mr-12"
          defaultChecked={consent}
          onClick={() => setConsent(!consent)}
        />
        <div tw="text-ps text-background">
          {t(
            'edit-offboarding-date-dialog.consent',
            'I agree that my employee consents their last working day on the above mentioned date',
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default EditOffboardingDateDialog;
