import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'account-settings',
  permission: 'account-settings',
  folder: 'account-settings/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Account Settings',
    route: `/${routes.root}`,
    icon: { name: 'home' },
    sort: 6,
    home: false,
    group: NavGroups.FOOTER,
    hide: true,
  },
};

export default config;
