import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import tw from 'twin.macro';

import ComboBox from '../../../../../combo-box';
import { TableFilterConfigType, TableFilterTypes } from '../../../../types';
import Label from '../label';

const FilterKeyInput: React.FC<{
  filterKey: string;
  keyFieldName: string;
  valueFieldName: string;
  filters: TableFilterConfigType;
  isPrimary?: boolean;
}> = ({ filterKey, keyFieldName, valueFieldName, filters, isPrimary }) => {
  const { t } = useTranslation('common.table');

  const { control, setValue } = useFormContext();

  const options = Object.keys(filters)
    .filter((filter) => {
      if (isPrimary) {
        return filters[filter]?.primary;
      }
      return !filters[filter]?.primary;
    })
    .map((filter) => ({
      title: filters[filter].title,
      value: filter,
    }));
  return (
    <div tw="flex-grow flex-1">
      <Label tw="pb-0">
        {t('filters.filter-by', 'Filter By:')}{' '}
        {isPrimary && <span tw="text-text-negative">*</span>}
      </Label>
      <Controller
        name={keyFieldName}
        control={control}
        defaultValue={filterKey}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            buttonStyles={tw`text-text-primary mt-extra-small`}
            data-testid="select-filter"
            disabled={
              filters[filterKey]?.required ||
              (isPrimary && options?.length <= 1)
            }
            showArrow
            placeholder={`E.g. ${options?.[0]?.title ?? 'Name'} `}
            value={value}
            variant="default"
            dropdownValues={options}
            onChange={(val: string) => {
              onChange(val);
              if (filters[val]?.type === TableFilterTypes.DATE_RANGE) {
                setValue(
                  valueFieldName,
                  {
                    startDate: undefined,
                    endDate: undefined,
                  },
                  { shouldValidate: true },
                );
              } else if (filters[val]?.type === TableFilterTypes.TEXT) {
                setValue(valueFieldName, '', {
                  shouldValidate: true,
                });
              } else {
                setValue(valueFieldName, null, {
                  shouldValidate: true,
                });
              }
            }}
          />
        )}
      />
    </div>
  );
};

export default FilterKeyInput;
