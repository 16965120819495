/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  Button,
  Callout,
  CalloutVariant,
  Icon,
  UploadedDocumentViewer,
} from '@multiplier/common';
import tw from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import ComboBox from 'common/components/combo-box';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';
import countryIDD from 'common/constants/country-idd';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import { useSubmitContactDetails } from 'contract-onboarding/hooks';
import { useGetCountryLegalRequirements } from 'contract-onboarding/member/hooks';

import {
  AddressInput,
  Contract,
  ContractType,
  CountryCode,
  FetchStage,
  Maybe,
  MemberChangeCategory,
  MemberChangeRequest,
  MemberChangeRequestStatus,
  useGetCountryStatesLazyQuery,
} from '__generated__/graphql';

import { useModal } from '../../../app/hooks';
import { Experience } from '../../../app/models/module-config';
import { userVar } from '../../../app/vars';
import countryLabels from '../../../common/constants/country-labels';
import {
  useCancelChangeRequest,
  useSendChangeRequest,
} from '../../../team/hooks';
import RequestMadeDialog from '../../../team/member/personal-details/components/request-made-dialog';
import {
  getChangeRequestItemByKey,
  getCorrespondingPillTag,
  haveSubmittedChangeRequestItem,
  transformToChangeRequests,
} from '../../services/change-request';
import { getFirstCountryCodeBasedOnPhoneCode } from '../../services/contact-details';
import getContactDetailsSectionSchema from '../../services/contact-details-section-schema';
import { Paths } from '../../utils/types';
import AddressInputField from '../address-input-field';
import DetailRow from '../detail-row';
import { DetailRowType } from '../detail-row/types';
import {
  DetailForm,
  DetailGrid,
  DetailValue,
  DetailValueTwoColumn,
} from '../layout';

const DetailGridLabel = React.lazy(() => import('../detail-grid-label'));
const DocumentsProofFooter = React.lazy(
  () => import('../documents-proof-footer'),
);
const SectionActionBar = React.lazy(() => import('../section-action-bar'));

export interface MemberContactDetailsFormValues {
  email: Maybe<string>;
  phone: {
    countryCode: Maybe<string>;
    number: Maybe<string>;
  };
  address: AddressInput;
  documentsProof?: File[];
}

const ContactDetailsSection: React.FC<{
  member: Contract['member'];
  editable?: boolean;
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  changeRequest?: Maybe<MemberChangeRequest>;
  lastSubmitOn?: Maybe<Date>;
  editDisabledFields?: Paths<MemberContactDetailsFormValues>[];
  requireApproval?: boolean;
  updateData?: () => void;
  workStatus?: Contract['workStatus'];
  type?: Contract['type'];
  country?: Contract['country'];
}> = ({
  member,
  editable = false,
  uncommittedChangesCallback,
  changeRequest,
  lastSubmitOn,
  requireApproval = false,
  editDisabledFields,
  updateData,
  workStatus,
  type,
  country,
}) => {
  const { t } = useTranslation([
    'contract-onboarding.common',
    'contract-onboarding.member',
  ]);
  const { loading, onSubmit } = useSubmitContactDetails(member?.id, () => {
    setIsEditing(false);
  });

  const [getCountryStates, { data }] = useGetCountryStatesLazyQuery();

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const {
    sendChangeRequest,
    loading: sendRequestLoading,
  } = useSendChangeRequest(MemberChangeCategory.CONTACT_DETAILS, () => {
    if (updateData) updateData();
    setIsEditing(false);
    handleOpenDialog();
  });

  const {
    cancelChangeRequest,
    loading: cancelLoading,
  } = useCancelChangeRequest(MemberChangeCategory.CONTACT_DETAILS, updateData);

  const { getRequirements, requirements } = useGetCountryLegalRequirements(
    workStatus,
    type,
    current === Experience.COMPANY
      ? FetchStage.CONTRACT_GENERATION
      : FetchStage.MEMBER_LEGAL_DATA_CAPTURE,
  );

  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal(false);

  const changeRequestItemsByKey = {
    address: getChangeRequestItemByKey('address', changeRequest)?.addressValue,
    emailAddress: getChangeRequestItemByKey('email', changeRequest)?.emailValue,
    phoneNo: getChangeRequestItemByKey('phone', changeRequest)?.phoneValue,
  };

  const changeRequestPhoneAreaCode = changeRequestItemsByKey.phoneNo?.phoneNo?.split(
    ' ',
  )[0];
  const phoneAreaCode = member?.phoneNos?.[0]?.phoneNo?.split(' ')[0];
  const [isEditing, setIsEditing] = useToggle(false);

  const submitForm = (values: MemberContactDetailsFormValues) => {
    if (requireApproval) {
      sendChangeRequest(
        transformToChangeRequests(
          member,
          values,
          MemberChangeCategory.CONTACT_DETAILS,
        ),
      );
      return;
    }
    onSubmit(values);
  };

  const methods = useForm<MemberContactDetailsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      getContactDetailsSectionSchema(requireApproval, t, member),
    ),
  });

  const { control, reset, getValues } = methods;

  const selectedCountry = useWatch({ control, name: 'address.country' });

  const memberLegalDataRequirements = requirements?.memberLegalDataRequirements;

  useEffect(() => {
    if (country) {
      getRequirements(country);
    }
  }, [country]);

  useEffect(() => {
    if (selectedCountry) {
      getCountryStates({
        variables: { country: selectedCountry as CountryCode },
      });
    }
    if (selectedCountry !== CountryCode.USA) {
      reset({
        ...getValues(),
        address: { ...getValues().address, state: null },
      });
    }
  }, [selectedCountry]);

  const stateDropdownValues = useMemo(
    () =>
      (data?.country?.countryStates &&
        data.country.countryStates.filter(notEmpty).map((c) => ({
          label: c?.name ?? '',
          value: c?.code ?? '',
          title: c?.code ?? '',
        }))) ??
      [],
    [data],
  );

  useEffect(() => {
    if (member) {
      const parsedPhoneCode = member.phoneNos?.[0]?.phoneNo
        ?.match(/^\+\d{1,4}/)
        ?.pop();

      methods.reset({
        email: member?.emails?.[0]?.email,
        phone: {
          countryCode: getFirstCountryCodeBasedOnPhoneCode(parsedPhoneCode),
          number: member.phoneNos?.[0]?.phoneNo
            ?.replace(parsedPhoneCode ?? '', '')
            .trim(),
        },
        address: {
          city: member?.addresses?.[0]?.city,
          country: member?.addresses?.[0]?.country,
          state: member?.addresses?.[0]?.state,
          province: member?.addresses?.[0]?.province,
          zipcode: member?.addresses?.[0]?.zipcode,
          postalCode: member?.addresses?.[0]?.postalCode,
          line1: member?.addresses?.[0]?.line1,
          line2: member?.addresses?.[0]?.line2,
          street: member?.addresses?.[0]?.street,
        },
      });
    }
  }, [member]);

  const phoneCodeDropdownValues = useMemo(
    () =>
      Object.entries(countryIDD).map((c) => ({
        key: `${c[1]}-${c[0]}`,
        icon: <Icon.Flag name={c[0].toLowerCase()} />,
        title: c[1],
        value: c[0],
        label: countryLabels[c[0]],
      })),
    [],
  );

  const phoneAreaCodeFlag = useMemo(
    () =>
      Object.entries(countryIDD)
        .find((idd) => idd[1] === phoneAreaCode)?.[0]
        ?.toLowerCase(),
    [phoneAreaCode],
  );

  const changeRequestPhoneAreaCodeFlag = useMemo(
    () =>
      Object.entries(countryIDD)
        .find((idd) => idd[1] === changeRequestPhoneAreaCode)?.[0]
        ?.toLowerCase() ?? '',
    [changeRequestPhoneAreaCode],
  );

  const isContractor = member?.contracts?.[0]?.type === ContractType.CONTRACTOR;

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.CONTRACT_DETAILS,
        value: methods.formState.isDirty,
      });
  }, [methods.formState.isDirty]);

  return (
    <>
      <Accordion
        name={t('onboarding-phase.contact-details.header', 'Contact Details')}
        description={
          type === ContractType.CONTRACTOR
            ? t(
                'onboarding-phase.contact-details.contractor-description',
                'Address and other contact details of the contractor',
              )
            : t(
                'onboarding-phase.contact-details.description',
                'Category description to better explain what this section is about.',
              )
        }
        icon="contacts"
        renderAction={getCorrespondingPillTag(changeRequest)}
        edit={editable && !haveSubmittedChangeRequestItem(changeRequest)}
        onEditClick={setIsEditing}
        warning={uncommittedChangesCallback && methods.formState.isDirty}
      >
        {changeRequest?.message &&
          changeRequest.status === MemberChangeRequestStatus.REJECTED && (
            <Callout tw="mb-large" variant={CalloutVariant.ERROR}>
              {changeRequest.message}
            </Callout>
          )}
        <FormProvider {...methods}>
          <DetailForm onSubmit={methods.handleSubmit(submitForm)}>
            <DetailGrid>
              {current === Experience.MEMBER &&
                haveSubmittedChangeRequestItem(changeRequest) &&
                lastSubmitOn && <DetailGridLabel submitOn={lastSubmitOn} />}
              <DetailRow
                id="email-address"
                label={t(
                  'onboarding-phase.contact-details.email-label',
                  'Email Address',
                )}
                value={member?.emails?.[0]?.email}
                newValue={changeRequestItemsByKey.emailAddress?.email}
                isEditing={isEditing}
                type={
                  haveSubmittedChangeRequestItem(changeRequest)
                    ? DetailRowType.COMPARE_MODE
                    : DetailRowType.BASIC_MODE
                }
                hide={editDisabledFields?.includes('email')}
              >
                <TextInput
                  id="email"
                  placeholder={t(
                    'onboarding-phase.contact-details.email-placeholder',
                    'john@example.com',
                  )}
                  divStyles={tw`col-span-3`}
                  {...methods.register('email')}
                  error={!!methods.formState.errors.email}
                />
              </DetailRow>
              <DetailRow
                isEditing={isEditing}
                id="phoneNumber"
                label={t(
                  'onboarding-phase.contact-details.phone-number',
                  'Phone Number',
                )}
                renderValue={
                  <div
                    css={[
                      tw`col-span-3 flex items-center text-ps text-text-tertiary`,
                      changeRequest?.status ===
                        MemberChangeRequestStatus.SUBMITTED &&
                        tw`col-span-1 text-text-secondary`,
                    ]}
                  >
                    {phoneAreaCode ? (
                      <Icon.Flag
                        width="24"
                        height="24"
                        name={phoneAreaCodeFlag as string}
                        data-testid="phone-area-code"
                      />
                    ) : (
                      ''
                    )}
                    <span
                      css={
                        (member?.phoneNos?.[0]?.phoneNo?.split(' ')?.length ??
                          0) > 1
                          ? tw`ml-small`
                          : tw`ml-none`
                      }
                    >
                      {(member?.phoneNos?.[0]?.phoneNo?.split(' ')?.length ??
                        0) > 1
                        ? member?.phoneNos?.[0]?.phoneNo?.split(' ')[1]
                        : member?.phoneNos?.[0]?.phoneNo}
                    </span>
                  </div>
                }
                value={member?.phoneNos?.[0]?.phoneNo}
                newValue={changeRequestItemsByKey.phoneNo?.phoneNo}
                renderNewValue={
                  <div tw="col-span-2 flex items-center text-ps text-text-tertiary">
                    {changeRequestItemsByKey.phoneNo?.phoneNo ? (
                      <Icon.Flag
                        width="24"
                        height="24"
                        name={changeRequestPhoneAreaCodeFlag}
                        data-testid="phone-area-code"
                      />
                    ) : (
                      ''
                    )}
                    <span tw="ml-small">
                      {changeRequestItemsByKey.phoneNo?.phoneNo?.split(' ')[1]}
                    </span>
                  </div>
                }
                type={
                  haveSubmittedChangeRequestItem(changeRequest)
                    ? DetailRowType.COMPARE_MODE
                    : DetailRowType.BASIC_MODE
                }
                hide={editDisabledFields?.includes('phone')}
              >
                <DropdownText.Container tw="col-span-3">
                  <Controller
                    name="phone.countryCode"
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                      <ComboBox
                        data-testid="select-country-code"
                        variant="inline"
                        showArrow
                        value={value as string}
                        placeholder="+XXX"
                        dropdownValues={phoneCodeDropdownValues}
                        onChange={onChange}
                        disabled={isContractor}
                      />
                    )}
                  />
                  <DropdownText.Input
                    tw="w-full"
                    id="phone"
                    placeholder={t(
                      'basic-details.phone-number-placeholder',
                      '000000000',
                    )}
                    error={!!methods.formState.errors.phone}
                    disabled={
                      memberLegalDataRequirements?.phoneNumber?.editable ===
                        false || isContractor
                    }
                    {...methods.register('phone.number')}
                  />
                </DropdownText.Container>
                {(methods.formState.errors.phone?.countryCode ||
                  methods.formState.errors?.phone?.number) && (
                  <TextInput.Error>
                    {methods.formState.errors.phone?.countryCode?.message ||
                      methods.formState.errors.phone?.number?.message}
                  </TextInput.Error>
                )}
              </DetailRow>

              <DetailRow
                id="address"
                labelStyles={tw`self-start`}
                label={{
                  line1: t(
                    'basic-details.address-line1-label',
                    'Address Line 1',
                  ),
                  line2: t(
                    'basic-details.address-line2-label',
                    'Address Line 2',
                  ),
                  street: t(
                    'basic-details.address-street-label',
                    'Address Street',
                  ),
                  city: t('basic-details.address-city-label', 'Address City'),
                  country: t('basic-details.address-country-label', 'Country'),
                  state: t('basic-details.address-state-label', 'State'),
                  province: t(
                    'basic-details.address-province-label',
                    'Province',
                  ),
                  postalCode: t(
                    'basic-details.address-postal-code-label',
                    'Postal Code/ Zip Code',
                  ),
                }}
                ignoreKeys={['__typename', 'key', 'zipcode']}
                value={member?.addresses?.[0]}
                newValue={changeRequestItemsByKey.address}
                renderValue={{
                  country: (
                    <DetailValue
                      css={[
                        changeRequest?.status ===
                          MemberChangeRequestStatus.SUBMITTED &&
                          tw`col-span-1 text-text-tertiary`,
                      ]}
                    >
                      {countryLabels[member?.addresses?.[0]?.country || '']}
                    </DetailValue>
                  ),
                }}
                renderNewValue={{
                  country: (
                    <DetailValueTwoColumn>
                      {
                        countryLabels[
                          changeRequestItemsByKey.address?.country || ''
                        ]
                      }
                    </DetailValueTwoColumn>
                  ),
                }}
                groupName={t('basic-details.address-label', 'Address')}
                type={
                  haveSubmittedChangeRequestItem(changeRequest)
                    ? DetailRowType.COMPARE_MODE
                    : DetailRowType.BASIC_MODE
                }
                hide={editDisabledFields?.includes('address')}
                isEditing={isEditing}
              >
                <Controller
                  name="address"
                  control={methods.control}
                  render={({ field: { value, onChange } }) => (
                    <AddressInputField
                      value={value}
                      onChange={onChange}
                      editExcludes={
                        editDisabledFields
                          ?.filter((path) => path.startsWith('address'))
                          ?.map((path) =>
                            path.substring(8),
                          ) as Paths<AddressInput>[]
                      }
                      selectedCountry={selectedCountry}
                      stateDropdownValues={stateDropdownValues}
                      memberLegalDataRequirements={memberLegalDataRequirements}
                      isContractor={isContractor}
                    />
                  )}
                />
              </DetailRow>

              {(!!changeRequest?.files?.length || isEditing) && (
                <DetailRow
                  id="documents-proof"
                  isEditing={isEditing}
                  value={changeRequest?.files?.length}
                  hideLabel={!!changeRequest?.files && !isEditing}
                  renderValue={
                    <div tw="mt-large">
                      <p tw="text-ps font-normal text-text-primary my-extra-small">
                        {t(
                          'contact-details.attach-proof',
                          'Attached Address Proof',
                        )}
                      </p>
                      <div tw="flex flex-row">
                        {changeRequest?.files?.map((doc) => (
                          <UploadedDocumentViewer
                            key={`contact-documents-${doc?.id}`}
                            link={doc?.downloadUrl}
                            label={doc?.name}
                            date={changeRequest.createdOn}
                          />
                        ))}
                      </div>
                    </div>
                  }
                  type={DetailRowType.BASIC_MODE}
                  hide={editDisabledFields?.includes('documentsProof')}
                >
                  <DocumentsProofFooter
                    label={t('edit.contact-details.proof', 'Address Proof')}
                    onChange={(files) => {
                      methods.setValue('documentsProof', files);
                      methods.trigger('documentsProof');
                    }}
                    experience={current}
                    requireApproval={requireApproval}
                  />
                </DetailRow>
              )}
            </DetailGrid>

            {isEditing && (
              <SectionActionBar
                disabled={!methods.formState.isValid}
                submitLoading={loading || sendRequestLoading}
                onCancelClick={() => {
                  setIsEditing(false);
                  methods.reset();
                }}
                requireApproval={requireApproval}
              />
            )}
            {changeRequest?.status &&
              changeRequest.status === MemberChangeRequestStatus.SUBMITTED && (
                <div tw="flex justify-center">
                  <Button
                    size="large"
                    variant="secondary"
                    loading={cancelLoading}
                    onClick={() => cancelChangeRequest()}
                  >
                    {t(
                      'contract-onboarding.common.cancel-request',
                      'Cancel Request',
                    )}
                  </Button>
                </div>
              )}
          </DetailForm>
        </FormProvider>
      </Accordion>
      <RequestMadeDialog open={showDialog} onClose={handleCloseDialog} />
    </>
  );
};

export default ContactDetailsSection;
