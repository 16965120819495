/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Icon, ToolTip } from '@multiplier/common';
import {
  Duration,
  add,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import { TFunction } from 'i18next';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import mapKeys from 'lodash/mapKeys';
import tw from 'twin.macro';
import * as yup from 'yup';

import Checkbox from 'common/components/checkbox';
import DatePicker from 'common/components/date-picker';
import Dialog from 'common/components/dialog';
import Textarea from 'common/components/textarea';
import Loader from 'team/company/components/dialog-loader';
import { useContractOffboard } from 'team/company/hooks';

import {
  ComplianceParamPeriodLimit,
  ComplianceParamPeriodUnit,
  Contract,
  ContractType,
  Maybe,
  useGetContractLazyQuery,
} from '__generated__/graphql';

import NoticeInfo from './notice-info';

export const getAddDuration = (
  probationPolicy: ComplianceParamPeriodLimit | null,
): Duration => {
  if (!probationPolicy) return { days: 0 };

  switch (probationPolicy.unit) {
    case ComplianceParamPeriodUnit.DAYS:
      return { days: probationPolicy.value ?? 0 };
    case ComplianceParamPeriodUnit.WEEKS:
      return { weeks: probationPolicy.value ?? 0 };
    case ComplianceParamPeriodUnit.MONTHS:
      return { months: probationPolicy.value ?? 0 };
    case ComplianceParamPeriodUnit.YEARS:
      return { years: probationPolicy.value ?? 0 };
    default:
      return { days: 0 };
  }
};

const getDefaultEndDate = (
  startOn: Contract['startOn'],
  probationPolicy: ComplianceParamPeriodLimit | null,
  noticeAfterProbation: ComplianceParamPeriodLimit | null,
  noticeDuringProbation: ComplianceParamPeriodLimit | null,
  contractType: Contract['type'],
): Date => {
  const today = new Date();

  if (
    contractType === ContractType.HR_MEMBER ||
    contractType === ContractType.FREELANCER
  )
    return today;

  if (!isPresent(probationPolicy) && isPresent(noticeAfterProbation)) {
    return add(today, getAddDuration(noticeAfterProbation));
  }

  if (isPresent(probationPolicy)) {
    const probationEndDate = add(startOn, getAddDuration(probationPolicy));

    if (
      (isBefore(today, probationEndDate) ||
        isSameDay(today, probationEndDate)) &&
      isPresent(noticeDuringProbation)
    )
      return add(today, getAddDuration(noticeDuringProbation));

    if (isAfter(today, probationEndDate) && isPresent(noticeAfterProbation))
      return add(today, getAddDuration(noticeAfterProbation));
  }

  return add(today, { weeks: 4 });
};

export const isPresent = <T extends unknown>(value: T): boolean =>
  !isUndefined(value) && !isNull(value);

export const getOffboardText = (
  t: TFunction<'team.company'>,
  type: 'query' | 'text' | 'main',
  contractType: ContractType,
): string => {
  const suffix = type === 'query' ? '?' : '';
  switch (contractType) {
    case ContractType.FREELANCER:
      return t(`offboard-freelancer.${type}`, `Offboard Freelancer${suffix}`);
    case ContractType.HR_MEMBER:
      return t(`offboard-hrmember.${type}`, `Offboard HR Member${suffix}`);
    default:
      return type === 'main'
        ? t(`offboard-member.confirm-send`, `Confirm & send notice`)
        : t(`offboard-member.${type}`, `Offboard Member${suffix}`);
  }
};

export interface OffboardMemberDialogProps {
  contractId?: Maybe<string>;
  show: boolean;
  onClose: () => void;
}

const OffboardMemberDialog: React.FC<OffboardMemberDialogProps> = ({
  contractId,
  show,
  onClose,
}) => {
  const { t } = useTranslation('team.company');
  const [consent, setConsent] = useState(false);
  const [minEndDate, setMinEndDate] = useState(startOfMonth(new Date()));
  const [
    getContract,
    { data, loading: contractLoading },
  ] = useGetContractLazyQuery();
  const { contractOffboardLoading, onSubmit } = useContractOffboard();

  const filteredParams = filter(
    data?.contract?.compliance?.complianceParams,
    (param) => Boolean(param && param.value !== -1),
  );

  const complianceParams = mapKeys(filteredParams, 'key');

  const contractType = data?.contract?.type;

  const startOn = new Date(data?.contract?.startOn);
  const today = new Date();

  const schema = yup.object().shape({
    endDate: yup
      .date()
      .min(
        subDays(minEndDate, 1),
        t('offboard-member-dialog.form.end-date.min-error', {
          defaultValue: 'Last working day must be later than {{ minDate }}',
          replace: {
            minDate: format(subDays(minEndDate, 1), 'dd-MMM-yyyy'),
          },
        }),
      )
      .max(add(today, { years: 1 }))
      .required()
      .typeError('Please provide a valid end date'),
    reason: yup.string().required(),
  });

  useEffect(() => {
    if (!contractId || contractId === '') return;
    getContract({
      variables: {
        id: contractId,
      },
    });
  }, [contractId, getContract]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<{
    endDate: Date;
    reason: string;
  }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleConfirmClick = () => {
    if (!contractId || contractId === '') return;
    handleSubmit(({ endDate, reason }) => {
      onSubmit({
        id: contractId,
        lastWorkingDay: format(endDate, 'yyyy-MM-dd'),
        offboardingNote: reason,
      });
      onClose();
    })();
  };

  const probationPolicy = complianceParams?.probationPolicy ?? null;
  const noticeAfterProbation = complianceParams?.noticeAfterProbation ?? null;
  const noticeDuringProbation = complianceParams?.noticeDuringProbation ?? null;

  useEffect(() => {
    const defaultEndDate = getDefaultEndDate(
      startOn,
      probationPolicy,
      noticeAfterProbation,
      noticeDuringProbation,
      contractType,
    );
    const fourWeeksFromToday = add(today, { weeks: 4 });
    const nextBestDate = isBefore(fourWeeksFromToday, defaultEndDate)
      ? defaultEndDate
      : fourWeeksFromToday;
    setMinEndDate(
      contractType === ContractType.EMPLOYEE ? nextBestDate : today,
    );
    reset({
      endDate: contractType === ContractType.EMPLOYEE ? nextBestDate : today,
    });
  }, [data]);

  const tooltipInfoComp = useMemo(() => {
    const { endDate } = getValues();
    const fourWeeksFromToday = add(today, { weeks: 4 });
    if (contractType !== ContractType.EMPLOYEE) {
      return t(
        'offboard-member-dialog.form.tooltip',
        'Last working day can only be after the notice period. For earlier dates, contact us.',
      );
    }
    return isBefore(fourWeeksFromToday, endDate)
      ? t(
          'offboard-member-dialog.form.tooltip',
          'Last working day can only be after the notice period. For earlier dates, contact us.',
        )
      : t(
          'offboard-member-dialog.form.tooltip-before-min-period',
          'The minimum lead time for Multiplier to process an employee offboarding request is 4 weeks.',
        );
  }, [getValues().endDate]);

  if (contractLoading) return <Loader />;

  return (
    <Dialog
      open={show}
      disabled={!(isValid && consent)}
      onClose={onClose}
      onConfirm={handleConfirmClick}
      loading={contractOffboardLoading}
      title={getOffboardText(t, 'query', contractType || ContractType.EMPLOYEE)}
      buttonText={getOffboardText(
        t,
        ContractType.EMPLOYEE ? 'main' : 'text',
        contractType || ContractType.EMPLOYEE,
      )}
      containerStyle={tw`overflow-visible m-24`}
    >
      {contractType !== ContractType.HR_MEMBER ? (
        <NoticeInfo
          startOn={startOn}
          probationPolicy={probationPolicy}
          noticeAfterProbation={noticeAfterProbation}
          noticeDuringProbation={noticeDuringProbation}
        />
      ) : null}

      <form>
        <div tw="flex text-background items-center mb-12">
          <p tw="text-ps font-medium mr-6">
            {t(
              'offboard-member-dialog.form.last-working-day-label',
              "Member's last working day",
            )}
          </p>
          <ToolTip
            styles={tw`max-width[320px] rounded-4 text-ps`}
            variant="top"
            content={tooltipInfoComp}
          >
            <Icon name="info" height="16" width="16" />
          </ToolTip>
        </div>
        <Controller
          control={control}
          name="endDate"
          render={({ field: { value, onChange } }) => (
            <DatePicker
              data-testid="endDate"
              tw="mb-16 z-50"
              value={value}
              onChange={onChange}
              min={minEndDate}
              max={add(today, { years: 1 })}
              error={!!errors.endDate}
              helperText={errors.endDate?.message}
            />
          )}
        />
        <div tw="text-background text-ps mb-12">
          {t('offboard-member-dialog.form.reason', 'Reason for Offboarding')}
        </div>
        <Textarea
          data-testid="reason"
          rows={4}
          tw="flex w-full mb-24 resize-none"
          {...register('reason')}
        />
      </form>
      <div tw="flex mb-16">
        <Checkbox
          data-testid="consent"
          checkboxSize="large"
          tw="mr-12"
          defaultChecked={consent}
          onClick={() => setConsent(!consent)}
        />
        <div tw="text-ps text-background">
          {t(
            'offboard-member-dialog.form.consent-label',
            'I agree that my employee consents their last working day on the above mentioned date',
          )}
        </div>
      </div>
      <div css={[tw`rounded-8 p-16 flex flex-row`, tw`bg-warningCallout`]}>
        <Icon name="checkbox-mail" fill="#FFF" tw="min-w-[32px] h-[32px]" />
        <p tw="text-ps text-background font-medium" css={[tw`flex ml-16`]}>
          {t(
            'offboard-member-dialog.form.note',
            'Confirming will send an email notification to member with their last working date mentioned.',
          )}
        </p>
      </div>
    </Dialog>
  );
};

export default OffboardMemberDialog;
