import { gql } from '@apollo/client';

export default gql`
  query GetMemberHomeCurrency {
    member {
      id
      contracts {
        id
        currency
      }
    }
  }
`;
