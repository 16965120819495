/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { useModal } from 'app/hooks';
import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import {
  isWetInkFlowEnabled,
  isWetInkSignatureEnabled,
} from 'contract-onboarding/company/pages/onboarding-phase/status-config';

import {
  Contract,
  ContractDocumentWorkflow,
  ContractOnboarding,
  ContractType,
  useContractReviseMutation,
} from '__generated__/graphql';

import ImplicationInfo, {
  ImplicationInfoType,
} from '../../../company/components/implication-info';
import RevokeDeleteDialog from '../../../company/components/revoke-delete-dialog';
import { hasPaidInvoice } from '../../../services/contract-deposit-invoice';

export const ReviseContractDialogContent: React.FC<{
  currentOnboardingStatus: ContractOnboarding['status'];
  contractDocumentWorkflowStatus: ContractDocumentWorkflow['currentStatus'];
  depositPayables: Contract['depositPayable'];
}> = ({
  contractDocumentWorkflowStatus,
  currentOnboardingStatus,
  depositPayables,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const shouldShowCreditNoteAction = useMemo(
    () => hasPaidInvoice(depositPayables),
    [depositPayables],
  );

  return (
    <div tw="flex flex-col gap-y-base">
      <p
        css={[
          tw`uppercase text-pxs text-text-secondary tracking-widest`,
          !isNewThemeApplied && tw`text-grey01`,
        ]}
      >
        {t('revise-dialog.implications.header', 'What to do next?')}
      </p>
      <div tw="flex flex-col items-start gap-y-base">
        <ImplicationInfo
          type={ImplicationInfoType.RESEND}
          currentOnboardingStatus={currentOnboardingStatus}
          contractDocumentWorkflowStatus={contractDocumentWorkflowStatus}
        />
        <ImplicationInfo
          type={ImplicationInfoType.RESIGNATURE}
          currentOnboardingStatus={currentOnboardingStatus}
          contractDocumentWorkflowStatus={contractDocumentWorkflowStatus}
        />
        <ImplicationInfo
          type={ImplicationInfoType.DEPOSIT_INVOICE}
          currentOnboardingStatus={currentOnboardingStatus}
          contractDocumentWorkflowStatus={contractDocumentWorkflowStatus}
        />
        {shouldShowCreditNoteAction && (
          <ImplicationInfo
            type={ImplicationInfoType.CREDIT_NOTE}
            currentOnboardingStatus={currentOnboardingStatus}
            contractDocumentWorkflowStatus={contractDocumentWorkflowStatus}
          />
        )}
      </div>
    </div>
  );
};

const ReviseContractAction: React.FC<{
  contractId: Contract['id'];
  contractType: Contract['type'];
  workflowUpdating: boolean;
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
  currentOnboardingStatus: ContractOnboarding['status'];
  contractDocumentWorkflowStatus: ContractDocumentWorkflow['currentStatus'];
  depositPayables: Contract['depositPayable'];
}> = ({
  contractId,
  contractType,
  workflowUpdating,
  toggleWorkflowUpdating,
  currentOnboardingStatus,
  contractDocumentWorkflowStatus,
  depositPayables,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const [
    showReviseContract,
    closeReviseContractModal,
    openReviseContractModal,
  ] = useModal();

  const [reviseContract, { loading }] = useContractReviseMutation({
    onError: () => {
      errorNotification(
        '',
        t('revise-contract.failed', 'Failed to Revise Contract'),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t('revise-contract.success', 'Revise Contract initiated'),
        false,
      );
    },
  });

  const handleReviseContractClick = async () => {
    if (contractId) {
      await reviseContract({ variables: { id: contractId } });
    }
  };

  const shouldHideReviseContractAction =
    isWetInkFlowEnabled() && isWetInkSignatureEnabled();

  useEffect(() => {
    toggleWorkflowUpdating(loading);
    if (loading) closeReviseContractModal();
  }, [loading]);

  if (shouldHideReviseContractAction) {
    return null;
  }

  return (
    <div>
      <div tw="flex flex-row items-center flex-grow gap-x-extra-large">
        <p
          css={[
            tw`text-ps font-medium text-text-secondary whitespace-pre-line`,
            !isNewThemeApplied && tw`text-grey01`,
          ]}
        >
          {t(
            'onboarding-phase.progress-widget-content.revise-action.description',
            "You can revise the details (e.g. change their joining date, employment details) until the member's joining date.\nPost revision, the contract can be accessed from the Onboarding tab.",
          )}
        </p>
        <Button
          tw="flex-shrink-0 w-[200px]"
          size="medium"
          variant="outline"
          onClick={openReviseContractModal}
          loading={loading}
          disabled={workflowUpdating || loading}
        >
          {contractType === ContractType.CONTRACTOR
            ? t(
                'onboarding-phase.progress-widget-content.revise-action.contractor.button',
                'Revise Order Form',
              )
            : t(
                'onboarding-phase.progress-widget-content.revise-action.button',
                'Revise Contract',
              )}
        </Button>
      </div>
      <RevokeDeleteDialog
        open={showReviseContract}
        onClose={closeReviseContractModal}
        onConfirm={handleReviseContractClick}
        title={t(
          'revise-dialog.title',
          'Are you sure you want to revise their contract?',
        )}
        buttonText={
          contractType === ContractType.CONTRACTOR
            ? t('revise-dialog.contractor.button', 'Revise Order Form')
            : t('revise-dialog.button', 'Revise Contract')
        }
        loading={loading}
        buttonVariant="default"
      >
        <ReviseContractDialogContent
          currentOnboardingStatus={currentOnboardingStatus}
          contractDocumentWorkflowStatus={contractDocumentWorkflowStatus}
          depositPayables={depositPayables}
        />
      </RevokeDeleteDialog>
    </div>
  );
};

export default ReviseContractAction;
