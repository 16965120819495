import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'marketplace',
  permission: 'marketplace',
  folder: 'marketplace',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Marketplace',
    route: '/marketplace',
    sort: 1,
    home: false,
    group: CompanyNavGroups.COMPANY,
    hide: true,
  },
};

export default config;
