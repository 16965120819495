import growthBook from '@multiplier/growthbook';

import AppFeature from 'app/features';
import { Experience } from 'app/models/module-config';

import { ContractOnboardingStep, ContractType } from '__generated__/graphql';

import i18n from '../../../../i18n';
import { StepConfig } from '../../../components/step-navigation-footer';
import routes from '../../routes';
import OnboardingPhase from '../onboarding-phase';
import BankDetailsContainer from './pages/bank-details';
import BasicDetailsView from './pages/basic-details';
import InsuranceView from './pages/benefits';
import CompensationView from './pages/compensation';
import ComplianceView from './pages/compliance';
import ContractView from './pages/contract';
import EligibilityView from './pages/eligibility';

const generateRelativeLink = (
  phase: string,
  step: string,
  contractId?: string | null,
) => `${contractId ? `${contractId}/` : ''}${phase}/${step}`;

const isSingleOnboardingEnabled = () =>
  growthBook.isOn(AppFeature.SINGLE_ONBOARDING);
const isSorOnboardingEnabled = () => growthBook.isOn(AppFeature.SOR_ONBOARDING);

const generateDirectLink = (
  phase: string,
  step: string,
  contractId?: string | null,
): string =>
  `/${Experience.COMPANY}/${routes.root}/${generateRelativeLink(
    phase,
    step,
    contractId,
  )}`;

const stepConfig: { [key: string]: StepConfig } = {
  [ContractOnboardingStep.DEFINITION_MEMBER_ELIGIBILITY]: {
    route: (): string => routes.definitionPhase.eligibility,
    relativeLink: (contractId) =>
      generateRelativeLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.eligibility,
        contractId,
      ),
    directLink: (contractId) =>
      generateDirectLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.eligibility,
        contractId,
      ),
    label: (contractType) =>
      contractType === ContractType.HR_MEMBER &&
      (isSorOnboardingEnabled() || isSingleOnboardingEnabled())
        ? i18n.t(
            'contract-onboarding.company:definition-phase.progress-bar.entity.',
            'Entity',
          )
        : i18n.t(
            'contract-onboarding.company:definition-phase.progress-bar.eligibility.',
            'Eligibility',
          ),
    view: EligibilityView,
  },
  [ContractOnboardingStep.DEFINITION_MEMBER_BASIC_DETAILS]: {
    route: (): string => routes.definitionPhase.basicDetails,
    directLink: (contractId) =>
      generateDirectLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.basicDetails,
        contractId,
      ),
    relativeLink: (contractId) =>
      generateRelativeLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.basicDetails,
        contractId,
      ),
    label: () =>
      i18n.t(
        'contract-onboarding.company:definition-phase.progress-bar.basic-details',
        'Basic Details',
      ),
    view: BasicDetailsView,
  },
  [ContractOnboardingStep.DEFINITION_COMPENSATION_DETAILS]: {
    route: (): string => routes.definitionPhase.compensation,
    directLink: (contractId) =>
      generateDirectLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.compensation,
        contractId,
      ),
    relativeLink: (contractId) =>
      generateRelativeLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.compensation,
        contractId,
      ),
    label: () =>
      i18n.t(
        'contract-onboarding.company:definition-phase.progress-bar.compensation',
        'Compensation',
      ),
    view: CompensationView,
  },
  [ContractOnboardingStep.DEFINITION_BANK_DETAILS]: {
    route: (): string => routes.definitionPhase.bankDetails,
    directLink: (contractId) =>
      generateDirectLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.bankDetails,
        contractId,
      ),
    relativeLink: (contractId) =>
      generateRelativeLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.bankDetails,
        contractId,
      ),
    label: () =>
      i18n.t(
        'contract-onboarding.company:definition-phase.progress-bar.bank-details',
        'Bank Details',
      ),
    view: BankDetailsContainer,
  },
  [ContractOnboardingStep.DEFINITION_BENEFITS_DETAILS]: {
    route: (): string => routes.definitionPhase.insurance,
    directLink: (contractId) =>
      generateDirectLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.insurance,
        contractId,
      ),
    relativeLink: (contractId) =>
      generateRelativeLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.insurance,
        contractId,
      ),
    label: () =>
      i18n.t(
        'contract-onboarding.company:definition-phase.progress-bar.insurance',
        'Insurance',
      ),
    view: InsuranceView,
  },
  [ContractOnboardingStep.DEFINITION_COMPLIANCE_DETAILS]: {
    route: (): string => routes.definitionPhase.compliance,
    directLink: (contractId) =>
      generateDirectLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.compliance,
        contractId,
      ),
    relativeLink: (contractId) =>
      generateRelativeLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.compliance,
        contractId,
      ),
    label: () =>
      i18n.t(
        'contract-onboarding.company:definition-phase.progress-bar.compliance',
        'Compliance',
      ),
    view: ComplianceView,
  },
  [ContractOnboardingStep.DEFINITION_CONTRACT]: {
    route: (): string => routes.definitionPhase.contract,
    directLink: (contractId) =>
      generateDirectLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.contract,
        contractId,
      ),
    relativeLink: (contractId) =>
      generateRelativeLink(
        routes.definitionPhase.toString(),
        routes.definitionPhase.contract,
        contractId,
      ),
    label: (contractType) =>
      contractType === ContractType.HR_MEMBER &&
      (isSorOnboardingEnabled() || isSingleOnboardingEnabled())
        ? i18n.t(
            'contract-onboarding.company:definition-phase.progress-bar.policy',
            'Compliance',
          )
        : i18n.t(
            'contract-onboarding.company:definition-phase.progress-bar.contract',
            'Contract',
          ),
    view: ContractView,
  },
  [ContractOnboardingStep.ONBOARDING_REVIEW]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_CONTRACT_PREPARING_CONFIRMATION]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_CONTRACT_WET_INK_PREPARING_CONFIRMATION]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.VISA_ELIGIBILITY]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_CONTRACT]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.VISA_APPLICATION]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_CONTRACT_UPDATING]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_MULTIPLIER_SIGNING]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_MEMBER]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_VERIFYING]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_SIGNING]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_MSA_PENDING]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDING_ACTIVATION]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
  [ContractOnboardingStep.ONBOARDED]: {
    route: (): string => `${routes.onboardingPhase}`,
    directLink: (contractId) =>
      generateDirectLink(routes.onboardingPhase.toString(), '', contractId),
    relativeLink: (contractId) =>
      generateRelativeLink(routes.onboardingPhase.toString(), '', contractId),
    label: () => '',
    view: OnboardingPhase,
  },
};

export default stepConfig;
