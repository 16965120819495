/** @jsxImportSource @emotion/react */
import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PillTag } from '@multiplier/common';
import tw from 'twin.macro';

import { PayAmountType } from '__generated__/graphql';

import { AdditionalPayFormValues } from '../..';

interface AmountTypeProps {
  variant: PayAmountType;
  isRecommended?: boolean;
  description: string;
  dataTestId?: string;
}

const AmountTypeWrapper = tw.div`flex flex-col p-12 border-solid border border-grey03 rounded-6`;

const AmountType: React.FC<AmountTypeProps> = ({
  variant,
  isRecommended,
  description,
  dataTestId = 'amount-type',
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const AmountTitle = {
    [PayAmountType.FIXED_AMOUNT]: t(
      'additional-pay.amount-type.name.label.fixed',
      'Fixed',
    ),
    [PayAmountType.VARIABLE_AMOUNT]: t(
      'additional-pay.amount-type.name.label.variable',
      'Variable',
    ),
    [PayAmountType.FIXED_PERCENTAGE]: t(
      'additional-pay.amount-type.name.label.fixed-percentage',
      'Fixed (%)',
    ),
    [PayAmountType.VARIABLE_PERCENTAGE]: t(
      'additional-pay.amount-type.name.label.variable-percentage',
      'Variable (%)',
    ),
  };
  const { setValue, watch } = useFormContext<AdditionalPayFormValues>();
  return (
    <AmountTypeWrapper
      data-testid={dataTestId}
      onClick={() => {
        setValue('amountType', variant);
        if (variant === PayAmountType.FIXED_AMOUNT) setValue('conditions', '');
      }}
    >
      <div tw="flex flex-row">
        <div tw="flex justify-center items-center ">
          <input checked={watch('amountType') === variant} type="radio" />
          <span tw="ml-small text-ps"> {AmountTitle[variant]}</span>
        </div>
        <div tw="ml-auto">
          {isRecommended && <PillTag variant="SUCCESS" label="Recommended" />}
        </div>
      </div>
      <div tw="flex flex-row mt-base text-ps text-text-secondary">
        {description}
      </div>
    </AmountTypeWrapper>
  );
};
export default AmountType;
