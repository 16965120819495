import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
  WidgetVariant,
} from 'app/models/module-config';

import routes from './routes';

const config: ModuleConfig = {
  id: 'company-settings',
  permission: 'organization-settings',
  folder: 'organization-settings',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Company settings',
    route: routes.root,
    sort: 1,
    home: false,
    group: CompanyNavGroups.SETTINGS,
  },
  widget: {
    sort: 0,
    list: [
      {
        show: true,
        file: 'organization-settings-widget',
        variant: WidgetVariant.FULL_WIDTH,
      },
      {
        show: true,
        file: 'company-entities-widget',
        variant: WidgetVariant.HALF_WIDTH,
      },
    ],
  },
  preloadNs: ['organization-setting', 'common.select-filter'],
};

export default config;
