/* eslint-disable spaced-comment */
/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { useParams } from 'react-router-dom';

import {
  EsopForm,
  extendGrantValueType,
} from 'contract-onboarding/company/components/esop-input';
import {
  useContract,
  useGetCountryLabourStandards,
} from 'contract-onboarding/company/hooks';
import { OnboardingStepProps } from 'contract-onboarding/components/step-navigation-footer';

import {
  ContractType,
  CountryCode,
  CurrencyCode,
  Grant,
  Maybe,
  MsaWorkflowStatus,
  VestingFrequency,
  useGetCompanyDuplicateDomainQuery,
  useGetCompanyMsaStatusQuery,
  useGetSupportedCurrenciesQuery,
} from '__generated__/graphql';

import CompensationViewForm from './components/compensation-view-form';
import FreelancerCompensationView from './freelancer';

const getEsopAgreementType = (grant?: Maybe<Grant>): string => {
  if (grant?.values?.length === 1) {
    return grant?.values?.[0].type;
  }
  if (grant?.values?.length === 2) {
    return extendGrantValueType.BOTH;
  }
  return extendGrantValueType.UNIT;
};

export const getEsopFromGrant = (grant?: Maybe<Grant>): EsopForm => ({
  id: grant?.id,
  currency:
    grant?.values?.find((value) => value.type === extendGrantValueType.CASH)
      ?.currency ?? CurrencyCode.USD,
  amount:
    grant?.values?.find((value) => value.type === extendGrantValueType.CASH)
      ?.value ?? undefined,
  noOfEsops:
    grant?.values?.find((value) => value.type === extendGrantValueType.UNIT)
      ?.value ?? undefined,
  vestedOver: grant?.schedule?.vestingPeriod?.value ?? 0,
  vestingSchedule:
    grant?.schedule?.vestingSchedule?.map((value) => ({
      percentage: value?.vestingValue?.value ?? 0,
      value: value?.time?.value ?? 0,
    })) ?? [],
  cliffPeriod: grant?.schedule?.cliffPeriod?.value ?? 0,
  vestingFrequency:
    grant?.schedule?.vestingSchedule?.[0]?.frequency ?? VestingFrequency.YEARLY,
  condition: grant?.condition ?? '',
  esopAgreementOptions: getEsopAgreementType(grant),
});

const useShowPricing = (
  contractType: ContractType | null | undefined,
): boolean => {
  const {
    data: companyMsaData,
    loading: loadingCompanyMsaData,
  } = useGetCompanyMsaStatusQuery();

  const {
    data: companyDuplicateData,
    loading: loadingCompanyDuplicateData,
  } = useGetCompanyDuplicateDomainQuery();

  return (
    contractType === ContractType.EMPLOYEE &&
    !loadingCompanyMsaData &&
    companyMsaData?.company?.onboarding?.msaWorkflow?.currentStatus ===
      MsaWorkflowStatus.MSA_GENERATION_PENDING &&
    !loadingCompanyDuplicateData &&
    companyDuplicateData?.companyHasDuplicatedDomain === false
  );
};

const CompensationView: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { id } = useParams<{ id?: string }>();
  const {
    contract: {
      id: contractId,
      company,
      term: contractTerm,
      type: contractType,
      workStatus: contractWorkStatus,
      compensation,
      compliance,
      alreadyHired,
      country,
      member,
      countryStateCode,
      startOn,
      endOn,
      legalEntityId,
    },
  } = useContract(id);

  const { data: labourStandards } = useGetCountryLabourStandards({
    country,
    countryStateCode,
  });

  const { data: supportedCurrenciesData } = useGetSupportedCurrenciesQuery({
    variables: {
      country: country as CountryCode,
      contractType:
        contractType === ContractType.CONTRACTOR
          ? ContractType.FREELANCER
          : (contractType as ContractType),
    },
    skip: country === undefined,
  });

  const compliantCurrencies =
    supportedCurrenciesData?.country?.supportedCurrencies ?? [];

  const showPricing = useShowPricing(contractType);

  return contractType !== ContractType.FREELANCER &&
    contractType !== ContractType.CONTRACTOR ? (
    <CompensationViewForm
      currentStep={currentStep}
      onboardingSteps={onboardingSteps}
      compliantCurrencies={compliantCurrencies}
      contractTerm={contractTerm}
      contractType={contractType}
      contractWorkStatus={contractWorkStatus}
      country={country}
      compensation={compensation}
      compliance={compliance}
      contractId={contractId}
      company={company}
      alreadyHired={alreadyHired}
      member={member}
      showPricing={showPricing}
      startOn={startOn}
      endOn={endOn}
      labourStandards={labourStandards?.country?.labourStandards}
      countryStateCode={countryStateCode}
      legalEntityId={legalEntityId}
    />
  ) : (
    <FreelancerCompensationView
      currentStep={currentStep}
      onboardingSteps={onboardingSteps}
      compliantCurrencies={compliantCurrencies}
      contractTerm={contractTerm}
      contractType={contractType}
      country={country}
      compensation={compensation}
      contractId={contractId}
      company={company}
      member={member}
      showPricing={showPricing}
      startOn={startOn}
      countryStateCode={countryStateCode}
      legalEntityId={legalEntityId}
    />
  );
};

export default CompensationView;
