import React, { useRef } from 'react';

import { useClickAway, useToggle } from 'react-use';

import tw, { TwStyle } from 'twin.macro';

const DropdownMenu: React.FC<{
  button: React.ReactElement;
  open?: boolean;
  toggleOpen?: () => void;
  children?: React.ReactNode;
  menuContainerStyles?: TwStyle | TwStyle[];
}> = ({ button, open, toggleOpen, children, menuContainerStyles }) => {
  const [showDropdown, toggleDropdown] = useToggle(false);

  const handleToggle = toggleOpen ?? toggleDropdown;

  const dropdownRef = useRef(null);
  useClickAway(dropdownRef, handleToggle);

  return (
    <div tw="relative w-max">
      <div
        onClick={handleToggle}
        onKeyUp={handleToggle}
        role="button"
        tabIndex={0}
      >
        {button}
      </div>
      {(typeof open !== 'undefined' ? open : showDropdown) && (
        <div
          css={[
            tw`absolute top-[calc(100% + 6px)] right-0 border border-grey03 rounded-6 bg-white w-max z-50`,
            menuContainerStyles,
          ]}
          ref={dropdownRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
