import { TFunction } from 'i18next';

import { CountryCode, Maybe } from '__generated__/graphql';

export default (
  t: TFunction<'contract-onboarding.member'>,
  countryCode?: Maybe<CountryCode>,
): string => {
  const map: Record<string, string> = {
    [CountryCode.ARE]: t(
      `bank-details.account-number.label-${CountryCode.ARE}`,
      'International Bank Account Number (IBAN)',
    ),
  };
  if (countryCode && countryCode in map) {
    return map[countryCode];
  }
  return t('bank-details.account-number.label', 'Account Number');
};
