/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Icon, Popover } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { premiumFrequencyLabel } from 'insurance/utils/utils';

import {
  BenefitPartnerBillingFrequency,
  BenefitType,
  CurrencyCode,
  InsurancePremium,
  Maybe,
} from '__generated__/graphql';

const PopoverContent = tw.div`text-ps text-center text-white bg-primaryBlue800 px-12 py-8 rounded-8`;

type Props = {
  insurancePremium: Maybe<InsurancePremium> | undefined;
  ageBandApplicable: Maybe<boolean> | undefined;
  billingCurrency: Maybe<CurrencyCode> | undefined;
  platformFee: Maybe<number> | undefined;
  platformFeeApplicable: Maybe<boolean> | undefined;
  type: Maybe<BenefitType> | undefined;
  dependentCount: Maybe<number> | undefined;
  isReview: boolean;
};

const Contributions: React.FC<Props> = ({
  insurancePremium,
  ageBandApplicable,
  billingCurrency,
  platformFee,
  platformFeeApplicable,
  type,
  dependentCount,
  isReview = false,
}) => {
  const { t } = useTranslation('contract-onboarding.member');
  const {
    self,
    billingDuration,
    employeePayAmount,
    employerPayAmount,
    employeePayPercentage,
    employerPayPercentage,
  } = insurancePremium ?? {};
  const { premiumCurrency } = self?.benefitPackageCost ?? {};

  const hasPlatformFee = (platformFee ?? 0) > 0 && !!platformFeeApplicable;

  const maxDependantCount = dependentCount ?? 0;

  if (!employeePayAmount || employeePayAmount <= 0) return null;

  const contributionsTooltipReview = () => {
    if (maxDependantCount === 0)
      return (
        <Trans
          t={t}
          i18nKey="onboarding-phase.insurance.health-plan.insurance-premium-title-no-dependent-review"
        >
          This is the actual premium based on your details.
        </Trans>
      );

    return (
      <Trans
        t={t}
        i18nKey="onboarding-phase.insurance.health-plan.insurance-premium-title-has-dependent-review"
      >
        This is the actual premium based on you and your dependents details.
      </Trans>
    );
  };

  const contributionsTooltip = () => {
    if (maxDependantCount <= 0)
      return (
        <Trans
          t={t}
          i18nKey="onboarding-phase.insurance.health-plan.insurance-premium-title-no-dependent-onboarding"
        >
          This is the actual premium based on your details.
        </Trans>
      );

    if (ageBandApplicable)
      return hasPlatformFee ? (
        <Trans
          t={t}
          i18nKey="onboarding-phase.insurance.health-plan.insurance-premium-title-tooltip-age-band-with-platform"
        >
          Actual premium might vary depending on the age and no of insured
          person(s). Platform fee of ({{ billingCurrency }} {{ platformFee }} is
          charged per insured person per month.
        </Trans>
      ) : (
        <Trans
          t={t}
          i18nKey="onboarding-phase.insurance.health-plan.insurance-premium-title-tooltip-age-band-without-platform"
        >
          Actual premium might vary depending on the age and no of insured
          person(s).
        </Trans>
      );

    return hasPlatformFee ? (
      <Trans
        t={t}
        i18nKey="onboarding-phase.insurance.health-plan.insurance-premium-title-tooltip-no-age-band"
      >
        Actual premium might vary depending on platform fees charged at{' '}
        {{ billingCurrency }} {{ platformFee }} per insured person per month
      </Trans>
    ) : null;
  };

  const showContributionsTooltip = type === BenefitType.INSURANCE;

  const contributionContent = isReview
    ? contributionsTooltipReview()
    : contributionsTooltip();

  return (
    <div tw="flex flex-col gap-y-12">
      <div tw="text-slateGrey900 font-semibold flex items-center gap-x-4">
        {t(
          'onboarding-phase.insurance.health-plan.insurance-premium-title',
          'Insurance premium contributions',
        )}
        {showContributionsTooltip && !!contributionContent ? (
          <Popover
            containerStyles={tw`border-none self-stretch`}
            position="bottom"
            popOver={<PopoverContent>{contributionContent}</PopoverContent>}
          >
            <Icon
              name="info"
              width={16}
              height={16}
              fill={theme`colors.slateGrey700`}
            />
          </Popover>
        ) : null}
      </div>

      <div tw="flex flex-1 gap-x-16">
        <div tw="border border-slateGrey200 p-16 rounded-8 flex-1 bg-slateGrey50">
          <div tw="text-ps text-slateGrey500 mb-4">
            <Trans
              i18nKey="onboarding-phase.insurance.health-plan.er-split-employer-label"
              t={t}
            >
              Employer Pays{' '}
              <span tw="font-semibold">{{ employerPayPercentage }}</span>%
            </Trans>
          </div>
          <div tw="font-semibold text-slateGrey900">
            {premiumCurrency} {employerPayAmount?.toFixed(2)} per{' '}
            <span>
              {premiumFrequencyLabel[
                billingDuration ?? BenefitPartnerBillingFrequency.ANNUALLY
              ]?.(t)}
            </span>
          </div>
        </div>
        <div tw="border border-slateGrey200 p-16 rounded-8 flex-1 bg-slateGrey50">
          <div tw="flex items-center gap-x-4 mb-4">
            <div tw="flex text-ps text-slateGrey500">
              <Trans
                i18nKey="onboarding-phase.insurance.health-plan.er-split-employee-label"
                t={t}
              >
                You Pay&nbsp;
                <span tw="font-semibold">{{ employeePayPercentage }}</span>%
              </Trans>
            </div>
            <Popover
              containerStyles={tw`border-none self-stretch`}
              position="top"
              popOver={
                <PopoverContent>
                  {' '}
                  {t(
                    'onboarding-phase.insurance.health-plan.employee-premium-title-tooltip',
                    'Your insurance contributions will be deducted from your payroll',
                  )}
                </PopoverContent>
              }
            >
              <Icon
                name="info"
                width={16}
                height={16}
                fill={theme`colors.slateGrey700`}
              />
            </Popover>
          </div>
          <div tw="font-semibold text-slateGrey900">
            {premiumCurrency} {employeePayAmount?.toFixed(2)} per{' '}
            <span>
              {premiumFrequencyLabel[
                billingDuration ?? BenefitPartnerBillingFrequency.ANNUALLY
              ]?.(t)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contributions;
