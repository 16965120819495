import React, { useEffect, useMemo, useState } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox, Icon, TextInput, notEmpty } from '@multiplier/common';
import tw from 'twin.macro';

import {
  BulkOnboardingContext,
  Capability,
  Company,
  LegalEntity,
} from '__generated__/graphql';

import { BulkUploadFormValues } from '..';
import { useGetCompanyEntities } from '../../../hooks/get-company-entities';
import BulkUploadSection from './bulk-upload-section';
import DownloadTemplateSection from './download-template-section';

export const TopSection = tw.div`flex flex-col mb-large`;
export const SectionHeader = tw.span`flex font-semibold text-p text-text-primary`;
export const SectionDescription = tw.span`flex text-text-tertiary text-ps`;

const useGetCompanyLegalEntities = ({
  company,
}: {
  company: Company | undefined;
}) => {
  const { get, data } = useGetCompanyEntities({
    company,
  });

  useEffect(() => {
    get();
  }, []);

  return data;
};

export interface UploadDataSectionProps {
  company?: Company;
  companyEntity?: LegalEntity;
  loadingValidate?: boolean;
  handleValidate: (file: File, companyEntity?: LegalEntity) => void;
  onCompanyEntityChange?: (companyEntity: LegalEntity) => void;
}

const UploadDataSection: React.FC<UploadDataSectionProps> = ({
  company,
  loadingValidate,
  handleValidate,
  onCompanyEntityChange,
}) => {
  const { t } = useTranslation('contract-onboarding');

  const legalEntities = useGetCompanyLegalEntities({ company });

  const { control } = useFormContext<BulkUploadFormValues>();

  const [bulkOnboardingContext, legalEntityId] = useWatch({
    name: ['bulkOnboardingUploadDataType', 'legalEntityId'],
    control,
  });

  const [companyEntity, setCompanyEntity] = useState<LegalEntity>();

  useEffect(() => {
    const selectedLegalEntity = legalEntities?.find(
      (entity) => entity?.id === legalEntityId,
    );
    if (selectedLegalEntity) {
      setCompanyEntity(selectedLegalEntity);
      onCompanyEntityChange?.(selectedLegalEntity);
    }
  }, [legalEntityId, legalEntities]);

  const entitiesDropdownValues = useMemo(
    () =>
      (legalEntities ?? [])
        .filter(notEmpty)
        .filter((entity) =>
          entity?.capabilities?.some(
            (capability) =>
              capability &&
              [
                Capability.GROSS_TO_NET,
                Capability.SALARY_DISBURSEMENT,
              ].includes(capability),
          ),
        )
        .map((entity) => ({
          label: entity?.legalName ?? '',
          value: entity?.id ?? '',
          title: entity?.address?.country?.toString() ?? '',
          country: entity?.address?.country,
          icon: (
            <Icon.Flag name={entity?.address?.country?.toLocaleLowerCase()} />
          ),
        })),
    [legalEntities],
  );

  const shouldDisplayEntityDropdown =
    bulkOnboardingContext === BulkOnboardingContext.GLOBAL_PAYROLL;
  const shouldDisplayTemplateSection = !(
    bulkOnboardingContext === BulkOnboardingContext.GLOBAL_PAYROLL &&
    companyEntity == null
  );

  return (
    <div tw="flex flex-col">
      {shouldDisplayEntityDropdown && (
        <TextInput.Container tw="mb-base text-left">
          <TextInput.Label>
            {t(
              'company-entity-bulk-onboarding-flow.bulk-upload-section.upload-data.entity.label',
              'Select entity',
            )}
          </TextInput.Label>
          <Controller
            name="legalEntityId"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <>
                <ComboBox
                  data-testid="select-entity"
                  variant="autocomplete"
                  dropdownValues={entitiesDropdownValues}
                  placeholder={t(
                    'company-entity-bulk-onboarding-flow.bulk-upload-section.upload-data.entity.placeholder',
                    'Select',
                  )}
                  value={value as string}
                  onChange={onChange}
                  showArrow
                />
                {fieldState.error?.message && (
                  <TextInput.Error>{fieldState.error?.message}</TextInput.Error>
                )}
              </>
            )}
          />
        </TextInput.Container>
      )}
      <TopSection>
        <SectionHeader>
          {t(
            'company-entity-bulk-onboarding-flow.bulk-upload-section.upload-data.header',
            'Step 2: Upload data',
          )}
        </SectionHeader>
        <SectionDescription>
          {t(
            'company-entity-bulk-onboarding-flow.bulk-upload-section.upload-data.description',
            'Download the template and submit the filled template.',
          )}
        </SectionDescription>
      </TopSection>
      {shouldDisplayTemplateSection && (
        <>
          <DownloadTemplateSection
            company={company}
            companyEntity={companyEntity}
          />
          <BulkUploadSection
            loading={loadingValidate}
            handleValidate={(file) => handleValidate(file, companyEntity)}
          />
        </>
      )}
    </div>
  );
};

export default UploadDataSection;
