import { Experience } from 'app/models/module-config';

export const getPriorityExperience = (experiences: Experience[]): Experience =>
  experiences?.includes(Experience.COMPANY)
    ? Experience.COMPANY
    : experiences?.[0];

export const getCurrentExperience = (
  experiences: Experience[],
  pathname?: string,
): Experience =>
  pathname?.split('/')[1] === ''
    ? getPriorityExperience(experiences)
    : pathname?.split('/')[1] === 'login' && !!experiences?.length
    ? experiences[0]
    : (pathname?.split('/')[1] as Experience);
