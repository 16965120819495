/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { ApolloClient, ApolloLink, gql, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { getAuthHeaders } from '@multiplier/user';
import * as Sentry from '@sentry/react';
import { RestLink } from 'apollo-link-rest';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import merge from 'lodash/fp/merge';
import 'twin.macro';

import apm from 'apm';
import { Experience } from 'app/models/module-config';
import { errorNotification } from 'app/services/notification-services';
import localStorageService from 'common/services/local-storage-service';
import sessionStorageService from 'common/services/session-storage-service';
import errorMessageMap from 'error-code-mapping';
import i18n from 'i18n';
import importMetaEnv from 'import-meta-env';
import { forceLogout, getOtpToken } from 'login/services/jwt';
import { refreshAccessToken } from 'login/services/refresh';
import { publicKeyNames, publicOperationNames } from 'public-operations-config';

import cache from './app/cache';

const otpVerificationOperations = ['VerifyOtp', 'ResendOtp'];

const traceLink = new ApolloLink((operation, forward) => {
  const transaction = apm?.startTransaction(
    `GraphQL: ${operation.operationName}`,
    'graphql',
    { managed: true },
  );
  if (transaction) {
    const { traceId } = (transaction as unknown) as { traceId: string };
    operation.setContext({ traceId });
  }
  return forward(operation);
});

const sentryLink = new SentryLink({
  uri: importMetaEnv.VITE_CORE_SERVICE_URL,
  setTransaction: false,
  setFingerprint: true,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: false, // sensitive information
    includeFetchResult: false, // sensitive information
    includeError: true,
    includeCache: false,
    includeContext: false,
  },
});

const logoutLink = onError(({ networkError, operation }: ErrorResponse) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    forceLogout(
      operation.operationName !== 'Authenticate' &&
        operation.operationName !== 'SsoSignUp',
    );
  }
});

export const errorLink = onError(
  ({ response, graphQLErrors, networkError, operation }: ErrorResponse) => {
    const {
      traceId: traceIdFromApmTransaction,
      shouldPropagateError = false,
      response: responseFromContext,
      shouldHideGeneralError = false,
    } = operation.getContext();

    const throwGeneralError = () => {
      if (shouldHideGeneralError) return;

      errorNotification(
        i18n.t(
          'common:general-error.title',
          'Something went wrong on our end.',
        ),
        <div>
          {networkError ? (
            <p>
              {JSON.stringify(
                networkError,
                Object.getOwnPropertyNames(networkError),
              )}
            </p>
          ) : (
            <p>
              {i18n.t(
                'common:general-error.description',
                'We ran into an issue and we are looking into it right away. Please continue and if this repeats, do reach out to us through our in-app chat.',
              )}
            </p>
          )}
          {traceId && <p tw="text-ps text-text-tertiary mt-tiny">{traceId}</p>}
        </div>,
        false,
      );
    };

    const headers = responseFromContext?.headers;

    const traceIdFromHeaders = headers
      ? headers.get('traceparent')?.split('-')[1]
      : null;

    const traceId = traceIdFromApmTransaction || traceIdFromHeaders;

    if (importMetaEnv.PROD) {
      // Build mode
      // eslint-disable-next-line no-console
      console.error(`Transaction Trace Id ${traceId}`);
    }

    // Ignoring error notifications for specific operations with 403 return code
    if (
      ['ResendOtp'].includes(operation.operationName) &&
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 403
    ) {
      return;
    }

    // Ignoring error notifications for SSO REST operations
    if (['SSORedirect', 'SSOInitiate'].includes(operation.operationName)) {
      return;
    }

    if (
      networkError &&
      'statusCode' in networkError &&
      ![400, 401, 429].includes(networkError.statusCode) &&
      !shouldPropagateError
    ) {
      Sentry.withScope((scope) => {
        scope.setExtra('variables', operation.variables);
        scope.setExtra('response', response);
        Sentry.captureException(networkError);
      });

      throwGeneralError();
    }

    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 400
    ) {
      try {
        networkError.response
          .clone()
          .json()
          .then((res) => {
            if (
              res?.title &&
              res.title.includes('passwords should not be the same')
            ) {
              errorNotification(
                i18n.t('common:password-error.title', 'Invalid Password'),
                i18n.t(
                  'common:password-error.same-password',
                  'Old and new passwords should not be the same',
                ),
                false,
              );
            }
          });
      } catch (e) {
        // ignore
      }
    }

    if (graphQLErrors) {
      const error = response?.errors?.[0] ?? graphQLErrors[0];
      const extensions = error?.extensions as any;
      const errorCode =
        extensions?.debugInfo?.errorCode ?? extensions?.errorCode ?? '';

      if (Object.keys(errorMessageMap).includes(errorCode)) {
        const errorParams = extensions.debugInfo?.errorParams ?? extensions;
        const errorMessage = errorMessageMap[errorCode](errorParams);
        errorNotification(errorMessage.title, errorMessage.description, false);
      } else if (
        response?.errors &&
        response.errors.length > 0 &&
        response?.errors?.some(
          (err) => err?.extensions?.errorType === 'PERMISSION_DENIED',
        )
      ) {
        // ignore permission denied queries silently
      } else if (
        operation.operationName === 'AddCompanyUser' &&
        response?.errors?.length &&
        response.errors[0].message.includes('UserAlreadyExistsException')
      ) {
        errorNotification(
          i18n.t(
            'organization-settings:existing-member.title',
            'Existing Member',
          ),
          i18n.t(
            'organization-settings:existing-member.description',
            'Email is already in use. Please use a different email',
          ),
          false,
        );
      } else if (
        operation.operationName === 'CreateMember' &&
        response?.errors?.length &&
        response.errors[0].message.includes('MemberAlreadyExistsException')
      ) {
        errorNotification(
          i18n.t(
            'contract-onboarding.company:update.existing-member.title',
            'Existing Member',
          ),
          i18n.t(
            'contract-onboarding.company:update.existing-member.description',
            'Email is already in use. Please use a different email',
          ),
          false,
        );
      } else if (
        operation.operationName === 'UpdateCompensation' &&
        response?.errors?.length &&
        response.errors[0].message.includes('ADDITIONAL_PAY_VALIDATION_FAILED')
      ) {
        const errors = response.errors[0].message.split(
          'ADDITIONAL_PAY_VALIDATION_FAILED-',
        );
        errorNotification(
          i18n.t(
            'common:compensation-error.title',
            'Failed to submit compensation',
          ),
          errors[1],
          false,
        );
      } else if (
        operation.operationName === 'UpsertPerformanceReview' &&
        response?.errors?.length &&
        (response.errors[0].message.includes('PERFORMANCE_REVIEW_EXISTS') ||
          response.errors[0].message.includes('SALARY_REVIEW_EXISTS'))
      ) {
        if (response.errors[0].message.includes('PERFORMANCE_REVIEW_EXISTS')) {
          errorNotification(
            i18n.t(
              'performance-reviews:add-performance-review.existing-performance-review.title',
              'Request Failed',
            ),
            i18n.t(
              'performance-reviews:add-performance-review.existing-performance-review.description',
              'Performance Review has already been added for the member.',
            ),
            false,
          );
        } else if (
          response.errors[0].message.includes('SALARY_REVIEW_EXISTS')
        ) {
          errorNotification(
            i18n.t(
              'performance-reviews:add-performance-review.existing-salary-review.title',
              'Request Failed',
            ),
            i18n.t(
              'performance-reviews:add-performance-review.existing-performance-salary.description',
              'Compensation update has already been added for the member.',
            ),
            false,
          );
        }
      } else if (
        operation.operationName === 'GetPayrollReport' &&
        (graphQLErrors[0].message.includes('The company payroll not found') ||
          graphQLErrors[0].message.includes('No report data is available'))
      ) {
        errorNotification(
          i18n.t(
            'payroll-report:report-download.error.not-found.message',
            'Report data not available',
          ),
          i18n.t(
            'payroll-report:report-download.error.not-found.description',
            'There are no matching entries for your filter',
          ),
          false,
        );
      } else if (
        graphQLErrors.some(
          (e) => (e?.extensions as any)?.debugInfo?.errorCode === 'MPL0025',
        )
      ) {
        //  handled by component as per https://app.clickup.com/t/2y8q0p1
      } else if (
        operation.operationName === 'memberPayableCompanyInvoiceCreatePayIn' &&
        graphQLErrors[0].message.includes('amount_too_large')
      ) {
        errorNotification(
          i18n.t(
            'member-payable:create-pay-in.error.amount-too-large.title',
            'Invoice Limit Exceeded',
          ),
          i18n.t(
            'member-payable:create-pay-in.error.amount-too-large.description',
            'Invoice value has exceeded the maximum payment via Credit card. Kindly reach out to finance team or write us support@usemultiplier.com for the CC link.',
          ),
          false,
        );
      } else if (
        operation.operationName === 'memberPayableCompanyInvoiceCreatePayIn' &&
        graphQLErrors[0].message.includes('amount_too_small')
      ) {
        errorNotification(
          i18n.t(
            'member-payable:create-pay-in.error.amount-too-small.title',
            'Invoice Value Too Low',
          ),
          i18n.t(
            'member-payable:create-pay-in.error.amount-too-small.description',
            'Invoice value is too low. Kindly reach out to finance team or write us support@usemultiplier.com for the CC link.',
          ),
          false,
        );
      } else if (
        operation.operationName === 'GetContractDepositWithPdf' &&
        graphQLErrors[0].message.includes('NetsuiteApiException')
      ) {
        // ignore NetSuite errors temporarily
      } else if (
        operation.operationName === 'DisputeCreate' &&
        graphQLErrors[0].message.includes('Dispute already exists')
      ) {
        errorNotification(
          '',
          i18n.t(
            'raise-dispute.notification.already-exists',
            'Dispute already exists.',
          ),
          false,
        );
      } else if (
        operation.operationName === 'DisputeCreate' &&
        !graphQLErrors[0].message.includes('Dispute already exists')
      ) {
        errorNotification(
          '',
          i18n.t(
            'raise-dispute.notification.error',
            'Could not raise dispute.',
          ),
          false,
        );
      } else if (operation.operationName === 'AssociateUserToCompany') {
        // ignore error notification for AssociateUserToCompany because we're showing a static error
      } else if (
        operation.operationName === 'GetSepaIbanDetails' &&
        graphQLErrors.some((e) =>
          ['MPL_COMPANY_0005', 'MPL_COMPANY_0006'].includes(
            (e?.extensions as any)?.debugInfo?.errorCode,
          ),
        )
      ) {
        // handled by component organization-settings/pages/setup-sepa-direct-debit/components/sepa-direct-debit-form/components/iban-form-field
      } else {
        throwGeneralError();
      }
    }

    if (response?.errors && !shouldPropagateError) {
      response.errors = undefined;
    }
  },
);

export const publicErrorLink = onError(
  ({ networkError, response, operation }: ErrorResponse) => {
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode !== 401 &&
      networkError.statusCode !== 400 &&
      networkError.statusCode !== 500
    ) {
      Sentry.withScope((scope) => {
        scope.setExtra('variables', operation.variables);
        scope.setExtra('response', response);
        Sentry.captureException(networkError);
      });
      errorNotification(
        i18n.t(
          'common:general-error.title',
          'Something went wrong on our end.',
        ),
        JSON.stringify(networkError, Object.getOwnPropertyNames(networkError)),
        false,
      );
    }
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 500
    ) {
      networkError.response
        .clone()
        .json()
        .then((res) => {
          if (
            res?.detail &&
            res.detail.includes('Auth code expired or invalid')
          ) {
            errorNotification(
              i18n.t(
                'common:expired-key.title',
                'Auth code expired or invalid',
              ),
              '',
              false,
            );
          } else
            errorNotification(
              i18n.t(
                'common:general-error.title',
                'Something went wrong on our end.',
              ),
              '',
              false,
            );
        });
    }
  },
);

export const authLink = new ApolloLink((operation, forward) => {
  const jwtToken = localStorageService.getRawItem('jwt_token');
  const otpJwtToken = getOtpToken();

  const localCompanyId = localStorage.getItem('selected_company');

  let headers: { [key: string]: string } = {
    Platform: 'COMPANY_EXPERIENCE',
  };

  const context = operation.getContext();

  if (context?.headers) {
    headers = {
      ...headers,
      ...context.headers,
    };
  }

  if (otpVerificationOperations.includes(operation.operationName)) {
    headers = { ...headers, ...getAuthHeaders(otpJwtToken) };
  } else if (jwtToken) {
    headers = { ...getAuthHeaders(jwtToken), ...headers };
  }

  if (localCompanyId) {
    headers['user-selection-current-company-id'] = localCompanyId;
  }

  operation.setContext({
    headers,
  });

  return forward(operation);
});

const publicAuthLink = new ApolloLink((operation, forward) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userKey = urlParams.get(publicKeyNames[operation.operationName]);
  const rjtToken = sessionStorageService.getItem(`rjt_${userKey}`);
  if (rjtToken) {
    operation.setContext({
      headers: {
        ...getAuthHeaders(rjtToken),
      },
    });
  }
  return forward(operation);
});

export const authRefreshLink = setContext(async (request, prevContext) => {
  if (
    request.operationName &&
    otpVerificationOperations.includes(request.operationName)
  )
    return prevContext;
  const token = await refreshAccessToken();

  if (token) {
    return {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        ...getAuthHeaders(token),
      },
    };
  }

  return prevContext;
});

const experienceLink = setContext((request, prevContext) => {
  const { pathname } = window.location;

  // On first load, we would not be able to find the current experience without
  // querying to server, so we will not send anything
  const currentExperience =
    pathname.split('/')[1] === ''
      ? null
      : (pathname.split('/')[1] as Experience);

  if (
    currentExperience &&
    Object.values(Experience).includes(currentExperience)
  ) {
    return merge(
      {
        headers: {
          'Current-Experience': currentExperience,
        },
      },
      prevContext,
    );
  }
  return prevContext;
});

const responseTransformer = async (response: Response) => {
  const json = await response.json();
  return json.data;
};

const restLink = new RestLink({
  uri: importMetaEnv.VITE_EMPLOYER_SERVICE_URL,
  endpoints: {
    alpha: importMetaEnv.VITE_EMPLOYER_SERVICE_URL as string,
    contractor: {
      uri: importMetaEnv.VITE_CONTRACTOR_SERVICE_URL as string,
      responseTransformer,
    },
    benefit: {
      uri: importMetaEnv.VITE_BENEFIT_SERVICE_URL as string,
      responseTransformer,
    },
    user: importMetaEnv.VITE_USER_SERVICE_URL as string,
    hubspot: importMetaEnv.VITE_HUBSPOT_FORM_URL as string,
  },
  bodySerializers: {
    text: (data: string, headers: Headers) => {
      headers.set('Content-Type', 'text/plain');
      return { body: data, headers };
    },
    multipart: (data: string, headers: Headers) => {
      const formData = new FormData();
      formData.append('reason_for_decline', data);
      return { body: formData, headers };
    },
  },
  credentials: 'include',
});

const httpLink = createUploadLink({
  uri: importMetaEnv.VITE_CORE_SERVICE_URL,
});

// AuthenticationResponse type should be removed after related flags are moved back to jwt.

const typeDefs = gql`
  input ResetPasswordInput {
    key: String
    password: String
  }

  input VerifyOtpInput {
    code: String
    rememberMe: Boolean
  }

  type IntercomHashInput {
    userId: String
  }

  type IntercomHashResponse {
    hash: String
  }

  type JwtResponse {
    id_token: String
  }

  type AuthenticationResponse {
    id_token: String
    mfaEnabled: Boolean
    mfaRequired: Boolean
  }

  type HubspotFormFieldInput {
    objectTypeId: String
    name: String
    value: String
  }

  type HubspotFormContextInput {
    pageUri: String
    pageName: String
    hutk: String
  }

  type SubmitHubspotFormInput {
    submittedAt: String
    fields: [HubspotFormFieldInput]
    context: HubspotFormContextInput
  }

  type HubspotFormSubmissionResponse {
    inlineMessage: String
  }
`;

const getAuthenticatedUserLinks = () => {
  const links = [
    traceLink,
    sentryLink,
    logoutLink,
    errorLink,
    authLink,
    experienceLink,
    restLink,
    httpLink,
  ];

  if (importMetaEnv.PROD) {
    links.splice(4, 0, authRefreshLink);
  }

  return links;
};

const authenticatedUserLinks = ApolloLink.from(
  getAuthenticatedUserLinks() as ApolloLink[],
);

const publicLinks = ApolloLink.from([
  publicErrorLink,
  publicAuthLink,
  restLink,
  httpLink,
] as ApolloLink[]);

const directionalLink = split(
  (operation) => !!publicOperationNames[operation.operationName],
  publicLinks,
  authenticatedUserLinks,
);

const client = new ApolloClient({
  cache,
  link: directionalLink,
  typeDefs,
  ...(importMetaEnv.VITE_GRAPHQL_MOCK === 'true' &&
  importMetaEnv.VITE_ENV === 'local'
    ? {
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
          },
          query: {
            fetchPolicy: 'no-cache',
          },
        },
      }
    : {}),
});
export default client;
