/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { RateFrequency } from '__generated__/graphql';

interface DateNoteSemimonthlyProps {
  paymentFrequency: RateFrequency;
  firstPayoutDate?: string;
  firstPayoutDateText: string;
  secondPayoutDateText: string;
}

const DateNoteSemimonthly: React.FC<DateNoteSemimonthlyProps> = ({
  paymentFrequency,
  firstPayoutDate,
  firstPayoutDateText,
  secondPayoutDateText,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  if (paymentFrequency !== RateFrequency.SEMIMONTHLY || !firstPayoutDate) {
    return null;
  }
  return (
    <Trans
      t={t}
      i18nKey="definition-phase.compensation.payout-date-range-label-semimonthly-contractor"
    >
      <span
        css={[
          tw`text-ps font-normal text-text-secondary mt-small mb-extra-small whitespace-pre-wrap`,
          !isNewThemeApplied && tw`text-grey01`,
        ]}
      >
        Payout to contractor will be initiated on{' '}
        <span
          data-testid="info-date"
          tw="text-text-primary font-medium text-ps"
        >
          {{ firstPayoutDateText }}
        </span>{' '}
        and{' '}
        <span
          data-testid="info-date"
          tw="text-text-primary font-medium text-ps"
        >
          {{ secondPayoutDateText }}
        </span>{' '}
        of every month.
      </span>
    </Trans>
  );
};

export default DateNoteSemimonthly;
