import { useTranslation } from 'react-i18next';

import { ApolloError, useReactiveVar } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { AppFeature } from '@multiplier/growthbook';
import { handlers } from '@multiplier/notifications';
import { Experience, userVar } from '@multiplier/user';

import {
  BulkOnboardingContext,
  BulkOnboardingJobStatus,
  Company,
  ContractType,
  GetTeamDocument,
  LegalEntity,
  UploadCompanyEntityBulkOnboardingMutation,
  useUploadCompanyEntityBulkOnboardingMutation,
} from '__generated__/graphql';

import { BulkUploadFormValues } from '../components/bulk-upload-form';
import mockRequestLazy from './mock-request-lazy';

const { errorNotification, infoNotification, successNotification } = handlers;

const MOCK_DATA = {
  bulkOnboardingTrigger: {
    status: BulkOnboardingJobStatus.SUCCESS,
    onboardingResult: { onboardedEmployeeCount: 23 },
  },
} as UploadCompanyEntityBulkOnboardingMutation;

export interface UseSubmitCompanyEntityBulkOnboardingParams {
  company: Company;
  context: BulkOnboardingContext;
  companyEntity?: LegalEntity;
  contractType?: ContractType;
  customContract?: File | null;
  onSubmitCompletion?: () => void;
}

interface UseSubmitCompanyEntityBulkOnboardingResult {
  loading: boolean;
  error?: ApolloError;
  data: UploadCompanyEntityBulkOnboardingMutation | null | undefined;
  onSubmit: (values: BulkUploadFormValues) => void;
}

const useOnError = () => {
  const { t } = useTranslation('contract-onboarding');

  return (error: ApolloError) => {
    errorNotification(
      t(
        'company-entity-bulk-onboarding-flow.error-notification.message',
        'Failed to upload onboardings',
      ),
      error.message,
      false,
    );
  };
};

const useOnCompleted = ({
  onSubmitCompletion,
}: {
  onSubmitCompletion?: () => void;
}) => {
  const { t } = useTranslation('contract-onboarding');

  return (data: UploadCompanyEntityBulkOnboardingMutation) => {
    const status = data?.bulkOnboardingTrigger?.status;

    if (status === BulkOnboardingJobStatus.FAILED) {
      errorNotification(
        '',
        t(
          'company-entity-bulk-onboarding-flow.error-notification.message',
          'Failed to upload onboardings',
        ),
        false,
      );
    } else if (status === BulkOnboardingJobStatus.IN_PROGRESS) {
      infoNotification(
        '',
        t(
          'company-entity-bulk-onboarding-flow.info-notification.message',
          'Upload is in progress, please go to teams',
        ),
        false,
      );
    } else if (status === BulkOnboardingJobStatus.SUCCESS) {
      successNotification(
        '',
        t(
          'company-entity-bulk-onboarding-flow.success-notification.message',
          'Successfully added {{count}} employees',
          {
            count:
              data?.bulkOnboardingTrigger?.onboardingResult
                ?.onboardedEmployeeCount,
          },
        ),
        false,
      );

      if (onSubmitCompletion) onSubmitCompletion();
    }
  };
};

const useWithoutMock = (
  params?: UseSubmitCompanyEntityBulkOnboardingParams,
): UseSubmitCompanyEntityBulkOnboardingResult => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const [upload, result] = useUploadCompanyEntityBulkOnboardingMutation({
    onError: useOnError(),
    refetchQueries:
      current === Experience.COMPANY ? [{ query: GetTeamDocument }] : undefined,
    onCompleted: useOnCompleted({
      onSubmitCompletion: params?.onSubmitCompletion,
    }),
  });

  const onSubmit = (values: BulkUploadFormValues) => {
    if (!params?.company?.id) {
      return;
    }

    if (params.context === BulkOnboardingContext.GLOBAL_PAYROLL) {
      if (!params.companyEntity?.id || !params.companyEntity.address?.country) {
        return;
      }

      if (!params.contractType) {
        return;
      }
    }

    upload({
      variables: {
        options: {
          companyId: params.company.id,
          entityId: params?.companyEntity?.id,
          countryCode: params?.companyEntity?.address?.country,
          contractType: params.contractType,
          context: params.context,
        },
        file: values?.files?.[0],
      },
    });
  };

  return {
    onSubmit,
    ...result,
    data: result.data,
  };
};

const useWithMock = (
  params?: UseSubmitCompanyEntityBulkOnboardingParams,
): UseSubmitCompanyEntityBulkOnboardingResult => {
  const [executeRequest, rest] = mockRequestLazy({
    data: MOCK_DATA,
  });

  const onCompleted = useOnCompleted({
    onSubmitCompletion: params?.onSubmitCompletion,
  });

  const onSubmit = () => {
    executeRequest({ onCompleted });
  };

  return {
    ...rest,
    onSubmit,
  };
};

const useSubmitCompanyEntityBulkOnboarding = (
  params?: UseSubmitCompanyEntityBulkOnboardingParams,
): UseSubmitCompanyEntityBulkOnboardingResult => {
  const mockResult = useWithMock(params);
  const liveResult = useWithoutMock(params);

  return !useFeature(AppFeature.COMPANY_PAYROLL_DEMO)?.on
    ? liveResult
    : mockResult;
};

export default useSubmitCompanyEntityBulkOnboarding;
