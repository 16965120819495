import { useCallback, useEffect, useState } from 'react';

const useModal = (
  shouldFreezeScreen = true,
): [boolean, () => void, () => void] => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    document.getElementById('experience')?.setAttribute('style', '');
  }, []);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
    if (shouldFreezeScreen)
      document
        .getElementById('experience')
        ?.setAttribute('style', `overflow: hidden;`);
  }, [shouldFreezeScreen]);

  useEffect(() => () => handleCloseModal(), []);

  return [showModal, handleCloseModal, handleOpenModal];
};

export default useModal;
