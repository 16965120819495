import React from 'react';

import { Cell, Column, Row, SortingRule } from 'react-table';

import { TwStyle } from 'twin.macro';

import { DropdownValue } from '../combo-box/types';
import { Maybe } from '../types';

export type TableDataObjectType = {
  subRows?: Maybe<Maybe<TableDataObjectType>[]>;
  disableRowSelection?: boolean;
  /**
   *
   * @param data Row data
   * @returns Element that to be append before the row. This is for case when we want to insert custom element between rows
   */
  insert?: (data: TableDataObjectType) => React.ReactNode;
} & {
  [key: string]:
    | string
    | number
    | React.ReactElement
    | React.ReactNode
    | undefined;
};

export type TableColumnType = {
  Header: string | Column<TableDataObjectType>['Header'];
  id?: string;
  accessor: string;
  disableSortBy?: boolean;
  width?: string | number;
  Cell?: string | ((cell: Cell) => React.ReactNode | string);
  canExpand?: boolean;
  hide?: boolean;
};

export interface TableBodyProps {
  tableId?: string;
  data: TableDataObjectType[] | [];
  mobileData?: TableDataObjectType[] | [];
  columns: TableColumnType[];
  disableSortBy?: boolean;
  defaultRows?: number;
  handleSort?: (sortBy: SortingRule<TableDataObjectType>[]) => void;
  renderMobileItem?: (i: TableDataObjectType) => React.ReactElement;
  handleRowClick?: (row: TableDataObjectType) => void;
  shouldHighlightRow?: (row: TableDataObjectType) => boolean;
  handleSelectedRows?: (rows: string[]) => void;
  disableSelection?: boolean;
  selectedIds?: string[];
  widget?: React.ReactElement;
  paginationType?: 'server-side' | 'client-side';
  bePageCount?: number | null;
  resetRows?: boolean;
  renderExpandedRow?: (
    row: Row<TableDataObjectType>,
    prepareRow: (row: Row<TableDataObjectType>) => void,
  ) => React.ReactElement; // this can be used to render a single sub row
  renderSubRows?: (row: Row<TableDataObjectType>) => React.ReactElement; // this can be used to render multiple custom subRows
  tableCellStyle?: TwStyle;
  tableHeadCellStyle?: TwStyle;
  tableHeaderRowStyle?: TwStyle;
  autoResetSelectedRows?: boolean;
  disablePagination?: boolean;
  expandIconPosition?: 'right' | 'left';
  totalText?: string;
  defaultSelectedIds?: string[];
  shouldRenderIndeterminateState?: boolean;
  onCheckboxClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  boldColumnHeader?: boolean;
  bgRowsType?: 'odd' | 'even' | 'custom'; // set bgRowsType=custom to and use row styles for custom styling of row based on some row properties
  rowStyles?: TwStyle | ((row: Row<TableDataObjectType>) => TwStyle); // example use case: only rows in timesheets where dayType = REST_DAY should have dark bg
  onPageChange?: ({
    pageSize,
    pageIndex,
  }: {
    pageSize: number;
    pageIndex: number;
  }) => void;
  checkboxStyle?: TwStyle;
  disableUrlBinding?: boolean;
  isAllRowsSelected?: boolean;
  handleAllRowSelectClick?: (checked: boolean) => void;
  ignoreDisabledRowsForSelectAllAction?: boolean;
  getDisabledTooltipMessage?: (rowId: string | number) => string;
  horizontalScroll?: boolean;
  additionalPageSizes?: number[];
  expandSubRows?: boolean;
  autoResetExpanded?: boolean;
  groupBy?: string;
  groupByLabel?: (row: Row<TableDataObjectType>) => React.ReactNode;
  defaultSort?: SortingRule<TableDataObjectType>[];
}

export enum TableFilterTypes {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DROPDOWN = 'DROPDOWN',
  MULTI_DROPDOWN = 'MULTI_DROPDOWN',
  MULTI_TEXT_INPUT = 'MULTI_TEXT_INPUT',
  MONTH = 'MONTH',
  MONTH_RANGE = 'MONTH_RANGE',
  DATE_RANGE = 'DATE_RANGE',
  CUSTOM = 'CUSTOM',
  RANGE = 'RANGE',
}

interface TableFilterConfigOptions extends DropdownValue {
  filterText?: string;
}

export type TableFilterConfigValue = {
  title: string;
  placeholder?: string;
  prefix?: string;
  filterDefault?: string;
  type: TableFilterTypes;
  options?: [] | TableFilterConfigOptions[];
  leadingFilterText?: boolean;
  required?: boolean;
  primary?: boolean;
  disabled?: boolean;
};

export type TableFilterConfigType = {
  [key: string]: TableFilterConfigValue;
};

export interface TableFiltersProps {
  associatedTableIds?: string[];
  filters: TableFilterConfigType;
  handleFilterChange?: (filters?: {
    [key: string]: TableFilterFormValue;
  }) => void;
  tabs?: {
    title: string;
    key: string;
  }[];
  selectedTab?: string | null;
  handleTabChange?: (
    key: string,
    filters?: {
      [key: string]: TableFilterFormValue;
    },
  ) => void;
  loading?: boolean;
  leadingText?: string;
  showAddNewFilter?: boolean;
  defaultFilters?: TableFilter[];
  variant?: 'default' | 'minimal';
  minimalFilterWrapperStyles?: TwStyle;
  minimalFilterButtonStyles?: TwStyle;
  submitBtnText?: string;
  validation?: {
    min?: number;
    disable?: boolean;
  };
  disable?: {
    form?: boolean;
    initialSubmission?: boolean;
    urlBinding?: boolean;
    keyDuplication?: boolean;
  };
}

export type TableFilterFormDateValue = {
  startDate?: Date | string | null;
  endDate?: Date | string | null;
};

export type TableFilterFormRangeValue = {
  minimum?: string | null;
  maximum?: string | null;
};

export type TableFilterFormMonthRangeValue = {
  startMonth?: string | null;
  endMonth?: string | null;
};

export type TableFilterFormValue =
  | string
  | string[]
  | TableFilterFormDateValue
  | TableFilterFormMonthRangeValue
  | TableFilterFormRangeValue
  | null;

export type TableFilter = {
  key: string;
  value: TableFilterFormValue;
};

export type TableFilterFormParams = {
  filters: TableFilter[];
  primaryFilter?: TableFilter | null;
};

export const isTableFilterFormDateValue = (
  value: TableFilterFormValue,
): value is TableFilterFormDateValue =>
  !!(value as TableFilterFormDateValue)?.startDate;

export type TableFiltersHandlers = {
  resetSubmittedState: () => void;
  resetSelectedFilters: () => void;
  clearAllFilters: () => void;
};
