import { useEffect, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';
import { OnboardingDataConfigType } from 'team/services/onboarding-data-config';
import resolveOnboardingField from 'team/services/onboarding-data-config/onboarding-data-config-resolver';

import {
  Contract,
  Maybe,
  useGetContractLazyQuery,
  useGetMemberLazyQuery,
} from '__generated__/graphql';

const useGetOnboardingFieldConfig = (
  fields: string[],
  id?: Maybe<string>,
  config?: OnboardingDataConfigType,
): {
  [key: string]: {
    visible: boolean;
    editable: boolean;
  };
} | null => {
  const [getContract, { data: companyContract }] = useGetContractLazyQuery({
    errorPolicy: 'all',
  });
  const [getMember, { data: memberContract }] = useGetMemberLazyQuery();
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  useEffect(() => {
    if (id && current === Experience.COMPANY) {
      getContract({ variables: { id } });
    } else if (current === Experience.MEMBER) {
      getMember();
    }
  }, [id, current]);

  const contract = useMemo(
    () => companyContract?.contract ?? memberContract?.member?.contracts?.[0],
    [companyContract, memberContract],
  );

  return useMemo(() => {
    if (contract) {
      return fields.reduce(
        (
          prev: { [key: string]: { visible: boolean; editable: boolean } },
          curr,
        ) => {
          prev[curr] = resolveOnboardingField(
            curr,
            current,
            contract as Contract,
            config,
          );
          return prev;
        },
        {},
      );
    }
    return null;
  }, [fields, id, contract]);
};

export default useGetOnboardingFieldConfig;
