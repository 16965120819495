/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ComboBox from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import { maritalStatusLabel } from 'common/constants/default-labels';

import { DataFieldDefinition, MaritalStatus } from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '../index';

interface MaritalStatusSelectorProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
  requirement?: DataFieldDefinition;
}

const MaritalStatusSelector: React.FC<MaritalStatusSelectorProps> = ({
  methods,
  requirement,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const maritalStatusDropdownValues = useMemo(
    () =>
      Object.values(MaritalStatus).map((s) => ({
        value: s,
        title: maritalStatusLabel[s],
      })),
    [],
  );

  const {
    control,
    formState: { defaultValues },
  } = methods;

  return (
    <TextInput.Container>
      <TextInput.Label>
        {t('member.basic-details.marital-status.label', 'Marital Status')}
      </TextInput.Label>
      <Controller
        name="maritalStatus"
        control={control}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            data-testid="select-marital-status"
            variant="default"
            dropdownValues={maritalStatusDropdownValues}
            placeholder={t(
              'member.basic-details.marital-status.placeholder',
              'Select Marital Status',
            )}
            value={value as string}
            onChange={onChange}
            disabled={
              requirement?.editable === false && !!defaultValues?.maritalStatus
            }
            showArrow
          />
        )}
      />
    </TextInput.Container>
  );
};

export default MaritalStatusSelector;
