import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const memberInvitePending: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.member_invite_pending.title', {
      defaultValue: 'Send invitation to employee',
      replace: {
        name,
      },
    }),
  description: () => '',
  subtitle: (t, firstName) =>
    t('onboarding-phase.member_invite_pending.description', {
      defaultValue:
        'Invite {{firstName}} to Multiplier platform. {{firstName}} needs to submit details to complete onboarding',
      replace: {
        firstName,
      },
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.member_invite_pending.label',
      'Employee invitation sent',
    ),
  pendingOn: () => PendingOn.YOU,
  pendingLabel: (t): string =>
    t('onboarding-phase.member_invite_pending.status', 'Pending on You'),
};

export default memberInvitePending;
