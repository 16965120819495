import { gql } from '@apollo/client';

export default gql`
  mutation ChangeCountry($id: ID!, $input: ContractUpdateCountryInput!) {
    contractChangeCountry(id: $id, input: $input) {
      id
      country
      workStatus
      countryStateCode
      alreadyHired
      onboarding {
        status
        steps
        current
      }
    }
  }
`;
