import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingContract: ContractOnboardingStepConfig = {
  title: (t): string =>
    t('onboarding-phase.onboarding-contract.title', 'Contract'),
  status: (t): string =>
    t('onboarding-phase.onboarding-contract.status', 'Pending on Multiplier'),
  variant: ProgressWidgetContentVariant.DESCRIPTION,
};

export default onboardingContract;
