import React from 'react';

import tw, { TwStyle } from 'twin.macro';

import Icon from '../../icon';
import { colorStyles, iconStyleProps } from '../config';
import { StepperType } from '../types';

const ProgressNodeIcon: React.FC<{
  iconType: StepperType;
  color: StepperType;
  active: boolean;
  onClick?: () => void;
  style?: TwStyle;
  renderIcon?: (type: StepperType) => React.ReactNode;
}> = ({ iconType, color, onClick, style, renderIcon }) => (
  <button
    data-testid="progress-node-icon"
    type="button"
    onClick={onClick}
    css={[
      tw`w-large h-large z-[2] right-0 rounded-full flex-shrink-0 flex items-center`,
      colorStyles[color],
      style,
    ]}
  >
    {renderIcon ? (
      renderIcon(iconType)
    ) : (
      <Icon
        tw="m-auto"
        name={iconStyleProps[iconType].iconName}
        fill={iconStyleProps[iconType].iconFill}
        stroke={iconStyleProps[iconType].iconStroke}
        css={[iconStyleProps[iconType].iconStyle]}
      />
    )}
  </button>
);

export default ProgressNodeIcon;
