import { gql } from '@apollo/client';

export default gql`
  mutation InviteMember($id: ID!) {
    contractInviteMember(id: $id) {
      id
      status
      onboarding {
        status
        statuses
        steps
        current
      }
    }
  }
`;
