import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractType, CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const memberDataAdded: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.member-data-added.title', {
      defaultValue: '{{name}} is almost done',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.member-data-added.description', {
      defaultValue:
        'We are collecting basic, bank, legal details from {{firstName}} to setup payroll.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t, contractType) => {
    switch (contractType) {
      case ContractType.FREELANCER:
        return t(
          'onboarding-phase.member-data-added-freelancer.label',
          'Details submitted by Freelancer',
        );
      case ContractType.CONTRACTOR:
        return t(
          'onboarding-phase.member-data-added-contractor.label',
          'Details submitted by Contractor',
        );
      default:
        return t(
          'onboarding-phase.member-data-added.label',
          'Collecting details',
        );
    }
  },
  pendingOn: (contractType) =>
    contractType === ContractType.CONTRACTOR
      ? PendingOn.CONTRACTOR
      : PendingOn.EMPLOYEE,
  pendingLabel: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.member-data-added-contractor.status',
          'Pending on Contractor',
        )
      : t('onboarding-phase.member-data-added.status', 'Pending on Employee'),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
};

export default memberDataAdded;
