/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, ThemeContext, useUpdateSearchParams } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { SUB_TAB, TeamViewAllSubTab } from 'team/company/pages/team-view';

interface TeamViewAllSubFiltersProps {
  pillList: { tab: TeamViewAllSubTab; count: number }[];
  count?: number;
  selectedSubTab?: TeamViewAllSubTab;
  setSelectedSubTab: Dispatch<SetStateAction<TeamViewAllSubTab>>;
}

interface PillButtonProps {
  name: TeamViewAllSubTab;
  count?: number;
  selectedSubTab?: TeamViewAllSubTab;
  handleButtonClick: (t: TeamViewAllSubTab) => void;
}

const PillButton: React.FC<PillButtonProps> = ({
  handleButtonClick,
  selectedSubTab,
  count,
  name,
}) => {
  const { t } = useTranslation('team-view');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const pillText = {
    [TeamViewAllSubTab.INACTIVE]: t('sub-tab.inactive', 'Inactive'),
    [TeamViewAllSubTab.ENDED]: t('sub-tab.ended', 'Ended'),
    [TeamViewAllSubTab.BLOCKED]: t('sub-tab.blocked', 'Blocked'),
    [TeamViewAllSubTab.ALL]: t('sub-tab.all', 'All'),
  };
  const isSelected = selectedSubTab === name;
  return (
    <PillButtonC
      onClick={() => handleButtonClick(name)}
      css={[
        isSelected && tw`bg-background-action text-white border-border-action`,
        isSelected &&
          isNewThemeApplied &&
          tw`bg-background-brand text-white border-none`,
        count === 0 &&
          tw`text-text-tertiary border-border-secondary bg-transparent`,
      ]}
      disabled={count === 0}
      data-testid={`pill-button-${name}`}
    >
      {`${pillText[name as TeamViewAllSubTab]} (${count})`}
      {selectedSubTab === name && (
        <Icon
          name="cross"
          fill={theme`colors.white`}
          tw="ml-small w-[10px] h-[10px]"
        />
      )}
    </PillButtonC>
  );
};

const TeamViewAllSubFilters: React.FC<TeamViewAllSubFiltersProps> = ({
  pillList,
  selectedSubTab,
  setSelectedSubTab,
}) => {
  const updateSearchParams = useUpdateSearchParams();

  const handleButtonClick = (subTab: TeamViewAllSubTab) => {
    if (subTab === selectedSubTab) {
      setSelectedSubTab(TeamViewAllSubTab.ALL);
      updateSearchParams([
        {
          key: SUB_TAB,
          value: TeamViewAllSubTab.ALL,
        },
        {
          key: 'page',
          value: '1',
        },
      ]);
      return;
    }
    setSelectedSubTab(subTab);
    updateSearchParams([
      {
        key: SUB_TAB,
        value: subTab,
      },
      {
        key: 'page',
        value: '1',
      },
    ]);
  };

  return (
    <MainContainer>
      {pillList.map((pill) => (
        <PillButton
          key={pill.tab}
          name={pill.tab}
          handleButtonClick={handleButtonClick}
          count={pill.count}
          selectedSubTab={selectedSubTab}
        />
      ))}
    </MainContainer>
  );
};

export default TeamViewAllSubFilters;

const MainContainer = tw.div`
    flex flex-row mb-base gap-extra-small
`;

const PillButtonC = tw.button`
    flex justify-center items-center py-extra-small px-base text-ps font-medium text-text-primary bg-transparent border border-border-primary rounded-[32px]
`;
