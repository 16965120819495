import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractOnboardingStatusConfig } from '../status-config';

const visaDocumentsSubmitted: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.visa-documents-submitted.title', {
      defaultValue: '{{name}} has added VISA Eligibility Documents',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.visa-documents-submitted.description', {
      defaultValue:
        'We are reviewing VISA eligibility documents from {{firstName}} to setup VISA.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.visa-documents-submitted.label',
      'Member uploaded eligibility documents',
    ),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t('onboarding-phase.visa-documents-submitted.status', 'Pending on member'),
};

export default visaDocumentsSubmitted;
