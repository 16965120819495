import React, { useContext } from 'react';

import { useMedia } from 'react-use';

import tw, { theme } from 'twin.macro';

import Card from '../../../card';
import { ThemeContext } from '../../../theme-provider';

const Table: React.FC<{ rows?: number }> = ({ rows = 2 }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const getRange = (n: number) => Array.from(Array(n).keys());
  const isMobile = useMedia(`(max-width: ${theme`screens.mobile.max`})`);

  return (
    <Card>
      {!isMobile && (
        <div tw="flex flex-col divide-y divide-text-tertiary">
          {getRange(rows).map((i) => (
            <div tw="w-full h-full flex" key={i}>
              <div tw="w-1/2 flex mr-96">
                {getRange(2).map((e) => (
                  <div tw="w-1/3 py-large animate-pulse" key={e}>
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/3 ml-base bg-background-action-hover`,
                        !isNewThemeApplied && tw`bg-grey02`,
                      ]}
                    />
                    {i > 0 && e > 0 && (
                      <div
                        css={[
                          tw`h-12 rounded-tiny w-11/12 ml-base mt-base bg-background-primary`,
                          !isNewThemeApplied && tw`bg-grey04`,
                        ]}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div tw="w-1/2 flex ml-48">
                {getRange(3).map((e) => (
                  <div tw="w-2/3 py-large animate-pulse" key={e}>
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-1/3 ml-base bg-background-action-hover`,
                        !isNewThemeApplied && tw`bg-grey02`,
                      ]}
                    />
                    {i === 1 && e === 0 && (
                      <div
                        css={[
                          tw`h-12 rounded-tiny w-5/6 ml-base mt-base bg-background-primary`,
                          !isNewThemeApplied && tw`bg-grey04`,
                        ]}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {isMobile && (
        <div tw="flex flex-col divide-y divide-text-tertiary">
          {getRange(2).map((i) => (
            <div tw="w-full h-full flex" key={i}>
              <div tw="w-1/2 flex">
                <div tw="w-full py-large animate-pulse">
                  <div
                    css={[
                      tw`h-12 rounded-tiny w-1/3 ml-base bg-background-action-hover`,
                      !isNewThemeApplied && tw`bg-grey02`,
                    ]}
                  />
                  {i === 1 && (
                    <div
                      css={[
                        tw`h-12 rounded-tiny w-5/6 ml-base mt-base bg-background-primary`,
                        !isNewThemeApplied && tw`bg-grey04`,
                      ]}
                    />
                  )}
                </div>
              </div>
              <div tw="w-1/2 flex">
                <div tw="w-1/3 py-large animate-pulse ml-96">
                  <div
                    css={[
                      tw`h-small rounded-tiny w-1/3 ml-base mt-base bg-background-primary`,
                      !isNewThemeApplied && tw`bg-grey04`,
                    ]}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default Table;
