import { CurrencyCode } from '__generated__/graphql';

import i18n from '../../i18n';

const currencyLabels: { [key: string]: string } = {};

// https://github.com/i18next/react-i18next/issues/909
const fill = () => {
  currencyLabels[CurrencyCode.AED] = i18n.t(
    'common:currency.aed',
    'UAE Dirham',
  );
  currencyLabels[CurrencyCode.AFN] = i18n.t('common:currency.afn', 'Afghani');
  currencyLabels[CurrencyCode.ALL] = i18n.t('common:currency.all', 'Lek');
  currencyLabels[CurrencyCode.AMD] = i18n.t(
    'common:currency.amd',
    'Armenian Dram',
  );
  currencyLabels[CurrencyCode.ANG] = i18n.t(
    'common:currency.ang',
    'Netherlands Antillean Guilder',
  );
  currencyLabels[CurrencyCode.AOA] = i18n.t('common:currency.aoa', 'Kwanza');
  currencyLabels[CurrencyCode.ARS] = i18n.t(
    'common:currency.ars',
    'Argentine Peso',
  );
  currencyLabels[CurrencyCode.AUD] = i18n.t(
    'common:currency.aud',
    'Australian Dollar',
  );
  currencyLabels[CurrencyCode.AWG] = i18n.t(
    'common:currency.awg',
    'Aruban Florin',
  );
  currencyLabels[CurrencyCode.AZN] = i18n.t(
    'common:currency.azn',
    'Azerbaijanian Manat',
  );
  currencyLabels[CurrencyCode.BAM] = i18n.t(
    'common:currency.bam',
    'Convertible Mark',
  );
  currencyLabels[CurrencyCode.BBD] = i18n.t(
    'common:currency.bbd',
    'Barbados Dollar',
  );
  currencyLabels[CurrencyCode.BDT] = i18n.t('common:currency.bdt', 'Taka');
  currencyLabels[CurrencyCode.BGN] = i18n.t(
    'common:currency.bgn',
    'Bulgarian Lev',
  );
  currencyLabels[CurrencyCode.BHD] = i18n.t(
    'common:currency.bhd',
    'Bahraini Dinar',
  );
  currencyLabels[CurrencyCode.BIF] = i18n.t(
    'common:currency.bif',
    'Burundi Franc',
  );
  currencyLabels[CurrencyCode.BMD] = i18n.t(
    'common:currency.bmd',
    'Bermudian Dollar',
  );
  currencyLabels[CurrencyCode.BND] = i18n.t(
    'common:currency.bnd',
    'Brunei Dollar',
  );
  currencyLabels[CurrencyCode.BOB] = i18n.t('common:currency.bob', 'Boliviano');
  currencyLabels[CurrencyCode.BOV] = i18n.t('common:currency.bov', 'Mvdol');
  currencyLabels[CurrencyCode.BRL] = i18n.t(
    'common:currency.brl',
    'Brazilian Real',
  );
  currencyLabels[CurrencyCode.BSD] = i18n.t(
    'common:currency.bsd',
    'Bahamian Dollar',
  );
  currencyLabels[CurrencyCode.BTN] = i18n.t('common:currency.btn', 'Ngultrum');
  currencyLabels[CurrencyCode.BWP] = i18n.t('common:currency.bwp', 'Pula');
  currencyLabels[CurrencyCode.BYN] = i18n.t(
    'common:currency.byn',
    'Belarussian Ruble',
  );
  currencyLabels[CurrencyCode.BZD] = i18n.t(
    'common:currency.bzd',
    'Belize Dollar',
  );
  currencyLabels[CurrencyCode.CAD] = i18n.t(
    'common:currency.cad',
    'Canadian Dollar',
  );
  currencyLabels[CurrencyCode.CDF] = i18n.t(
    'common:currency.cdf',
    'Congolese Franc',
  );
  currencyLabels[CurrencyCode.CHE] = i18n.t('common:currency.che', 'WIR Euro');
  currencyLabels[CurrencyCode.CHF] = i18n.t(
    'common:currency.chf',
    'Swiss Franc',
  );
  currencyLabels[CurrencyCode.CNH] = i18n.t(
    'common:currency.cnh',
    'Yuan Renminbi',
  );
  currencyLabels[CurrencyCode.CHW] = i18n.t('common:currency.chw', 'WIR Franc');
  currencyLabels[CurrencyCode.CLF] = i18n.t(
    'common:currency.clf',
    'Unidad de Fomento',
  );
  currencyLabels[CurrencyCode.CLP] = i18n.t(
    'common:currency.clp',
    'Chilean Peso',
  );
  currencyLabels[CurrencyCode.CNY] = i18n.t(
    'common:currency.cny',
    'Yuan Renminbi',
  );
  currencyLabels[CurrencyCode.COP] = i18n.t(
    'common:currency.cop',
    'Colombian Peso',
  );
  currencyLabels[CurrencyCode.COU] = i18n.t(
    'common:currency.cou',
    'Unidad de Valor Real',
  );
  currencyLabels[CurrencyCode.CRC] = i18n.t(
    'common:currency.crc',
    'Costa Rican Colon',
  );
  currencyLabels[CurrencyCode.CUC] = i18n.t(
    'common:currency.cuc',
    'Peso Convertible',
  );
  currencyLabels[CurrencyCode.CUP] = i18n.t(
    'common:currency.cup',
    'Cuban Peso',
  );
  currencyLabels[CurrencyCode.CVE] = i18n.t(
    'common:currency.cve',
    'Cabo Verde Escudo',
  );
  currencyLabels[CurrencyCode.CZK] = i18n.t(
    'common:currency.czk',
    'Czech Koruna',
  );
  currencyLabels[CurrencyCode.DJF] = i18n.t(
    'common:currency.djf',
    'Djibouti Franc',
  );
  currencyLabels[CurrencyCode.DKK] = i18n.t(
    'common:currency.dkk',
    'Danish Krone',
  );
  currencyLabels[CurrencyCode.DOP] = i18n.t(
    'common:currency.dop',
    'Dominican Peso',
  );
  currencyLabels[CurrencyCode.DZD] = i18n.t(
    'common:currency.dzd',
    'Algerian Dinar',
  );
  currencyLabels[CurrencyCode.EGP] = i18n.t(
    'common:currency.egp',
    'Egyptian Pound',
  );
  currencyLabels[CurrencyCode.ERN] = i18n.t('common:currency.ern', 'Nakfa');
  currencyLabels[CurrencyCode.ETB] = i18n.t(
    'common:currency.etb',
    'Ethiopian Birr',
  );
  currencyLabels[CurrencyCode.EUR] = i18n.t('common:currency.eur', 'Euro');
  currencyLabels[CurrencyCode.FJD] = i18n.t(
    'common:currency.fjd',
    'Fiji Dollar',
  );
  currencyLabels[CurrencyCode.FKP] = i18n.t(
    'common:currency.fkp',
    'Falkland Islands Pound',
  );
  currencyLabels[CurrencyCode.GBP] = i18n.t(
    'common:currency.gbp',
    'Pound Sterling',
  );
  currencyLabels[CurrencyCode.GEL] = i18n.t('common:currency.gel', 'Lari');
  currencyLabels[CurrencyCode.GHS] = i18n.t(
    'common:currency.ghs',
    'Ghana Cedi',
  );
  currencyLabels[CurrencyCode.GIP] = i18n.t(
    'common:currency.gip',
    'Gibraltar Pound',
  );
  currencyLabels[CurrencyCode.GMD] = i18n.t('common:currency.gmd', 'Dalasi');
  currencyLabels[CurrencyCode.GNF] = i18n.t(
    'common:currency.gnf',
    'Guinea Franc',
  );
  currencyLabels[CurrencyCode.GTQ] = i18n.t('common:currency.gtq', 'Quetzal');
  currencyLabels[CurrencyCode.GYD] = i18n.t(
    'common:currency.gyd',
    'Guyana Dollar',
  );
  currencyLabels[CurrencyCode.HKD] = i18n.t(
    'common:currency.hkd',
    'Hong Kong Dollar',
  );
  currencyLabels[CurrencyCode.HNL] = i18n.t('common:currency.hnl', 'Lempira');
  currencyLabels[CurrencyCode.HRK] = i18n.t('common:currency.hrk', 'Kuna');
  currencyLabels[CurrencyCode.HTG] = i18n.t('common:currency.htg', 'Gourde');
  currencyLabels[CurrencyCode.HUF] = i18n.t('common:currency.huf', 'Forint');
  currencyLabels[CurrencyCode.IDR] = i18n.t('common:currency.idr', 'Rupiah');
  currencyLabels[CurrencyCode.ILS] = i18n.t(
    'common:currency.ils',
    'New Israeli Sheqel',
  );
  currencyLabels[CurrencyCode.IMP] = i18n.t(
    'common:currency.imp',
    'Isle of Man Pound',
  );
  currencyLabels[CurrencyCode.INR] = i18n.t(
    'common:currency.inr',
    'Indian Rupee',
  );
  currencyLabels[CurrencyCode.IQD] = i18n.t(
    'common:currency.iqd',
    'Iraqi Dinar',
  );
  currencyLabels[CurrencyCode.IRR] = i18n.t(
    'common:currency.irr',
    'Iranian Rial',
  );
  currencyLabels[CurrencyCode.ISK] = i18n.t(
    'common:currency.isk',
    'Iceland Krona',
  );
  currencyLabels[CurrencyCode.JMD] = i18n.t(
    'common:currency.jmd',
    'Jamaican Dollar',
  );
  currencyLabels[CurrencyCode.JOD] = i18n.t(
    'common:currency.jod',
    'Jordanian Dinar',
  );
  currencyLabels[CurrencyCode.JPY] = i18n.t('common:currency.jpy', 'Yen');
  currencyLabels[CurrencyCode.KES] = i18n.t(
    'common:currency.kes',
    'Kenyan Shilling',
  );
  currencyLabels[CurrencyCode.KGS] = i18n.t('common:currency.kgs', 'Som');
  currencyLabels[CurrencyCode.KHR] = i18n.t('common:currency.khr', 'Riel');
  currencyLabels[CurrencyCode.KMF] = i18n.t(
    'common:currency.kmf',
    'Comoro Franc',
  );
  currencyLabels[CurrencyCode.KPW] = i18n.t(
    'common:currency.kpw',
    'North Korean Won',
  );
  currencyLabels[CurrencyCode.KRW] = i18n.t('common:currency.krw', 'Won');
  currencyLabels[CurrencyCode.KWD] = i18n.t(
    'common:currency.kwd',
    'Kuwaiti Dinar',
  );
  currencyLabels[CurrencyCode.KYD] = i18n.t(
    'common:currency.kyd',
    'Cayman Islands Dollar',
  );
  currencyLabels[CurrencyCode.KZT] = i18n.t('common:currency.kzt', 'Tenge');
  currencyLabels[CurrencyCode.LAK] = i18n.t('common:currency.lak', 'Kip');
  currencyLabels[CurrencyCode.LBP] = i18n.t(
    'common:currency.lbp',
    'Lebanese Pound',
  );
  currencyLabels[CurrencyCode.LKR] = i18n.t(
    'common:currency.lkr',
    'Sri Lanka Rupee',
  );
  currencyLabels[CurrencyCode.LRD] = i18n.t(
    'common:currency.lrd',
    'Liberian Dollar',
  );
  currencyLabels[CurrencyCode.LSL] = i18n.t('common:currency.lsl', 'Loti');
  currencyLabels[CurrencyCode.LYD] = i18n.t(
    'common:currency.lyd',
    'Libyan Dinar',
  );
  currencyLabels[CurrencyCode.MAD] = i18n.t(
    'common:currency.mad',
    'Moroccan Dirham',
  );
  currencyLabels[CurrencyCode.MDL] = i18n.t(
    'common:currency.mdl',
    'Moldovan Leu',
  );
  currencyLabels[CurrencyCode.MGA] = i18n.t(
    'common:currency.mga',
    'Malagasy Ariary',
  );
  currencyLabels[CurrencyCode.MKD] = i18n.t('common:currency.mkd', 'Denar');
  currencyLabels[CurrencyCode.MMK] = i18n.t('common:currency.mmk', 'Kyat');
  currencyLabels[CurrencyCode.MNT] = i18n.t('common:currency.mnt', 'Tugrik');
  currencyLabels[CurrencyCode.MOP] = i18n.t('common:currency.mop', 'Pataca');
  currencyLabels[CurrencyCode.MRU] = i18n.t('common:currency.mru', 'Ouguiya');
  currencyLabels[CurrencyCode.MUR] = i18n.t(
    'common:currency.mur',
    'Mauritius Rupee',
  );
  currencyLabels[CurrencyCode.MVR] = i18n.t('common:currency.mvr', 'Rufiyaa');
  currencyLabels[CurrencyCode.MWK] = i18n.t('common:currency.mwk', 'Kwacha');
  currencyLabels[CurrencyCode.MXN] = i18n.t(
    'common:currency.mxn',
    'Mexican Peso',
  );
  currencyLabels[CurrencyCode.MXV] = i18n.t(
    'common:currency.mxv',
    'Mexican Unidad de Inversion (UDI)',
  );
  currencyLabels[CurrencyCode.MYR] = i18n.t(
    'common:currency.myr',
    'Malaysian Ringgit',
  );
  currencyLabels[CurrencyCode.MZN] = i18n.t(
    'common:currency.mzn',
    'Mozambique Metical',
  );
  currencyLabels[CurrencyCode.NAD] = i18n.t(
    'common:currency.nad',
    'Namibia Dollar',
  );
  currencyLabels[CurrencyCode.NGN] = i18n.t('common:currency.ngn', 'Naira');
  currencyLabels[CurrencyCode.NIO] = i18n.t(
    'common:currency.nio',
    'Cordoba Oro',
  );
  currencyLabels[CurrencyCode.NOK] = i18n.t(
    'common:currency.nok',
    'Norwegian Krone',
  );
  currencyLabels[CurrencyCode.NPR] = i18n.t(
    'common:currency.npr',
    'Nepalese Rupee',
  );
  currencyLabels[CurrencyCode.NZD] = i18n.t(
    'common:currency.nzd',
    'New Zealand Dollar',
  );
  currencyLabels[CurrencyCode.OMR] = i18n.t(
    'common:currency.omr',
    'Rial Omani',
  );
  currencyLabels[CurrencyCode.PAB] = i18n.t('common:currency.pab', 'Balboa');
  currencyLabels[CurrencyCode.PEN] = i18n.t('common:currency.pen', 'Nuevo Sol');
  currencyLabels[CurrencyCode.PGK] = i18n.t('common:currency.pgk', 'Kina');
  currencyLabels[CurrencyCode.PHP] = i18n.t(
    'common:currency.php',
    'Philippine Peso',
  );
  currencyLabels[CurrencyCode.PKR] = i18n.t(
    'common:currency.pkr',
    'Pakistan Rupee',
  );
  currencyLabels[CurrencyCode.PLN] = i18n.t('common:currency.pln', 'Zloty');
  currencyLabels[CurrencyCode.PYG] = i18n.t('common:currency.pyg', 'Guarani');
  currencyLabels[CurrencyCode.QAR] = i18n.t(
    'common:currency.qar',
    'Qatari Rial',
  );
  currencyLabels[CurrencyCode.RON] = i18n.t(
    'common:currency.ron',
    'Romanian Leu',
  );
  currencyLabels[CurrencyCode.RSD] = i18n.t(
    'common:currency.rsd',
    'Serbian Dinar',
  );
  currencyLabels[CurrencyCode.RUB] = i18n.t(
    'common:currency.rub',
    'Russian Ruble',
  );
  currencyLabels[CurrencyCode.RWF] = i18n.t(
    'common:currency.rwf',
    'Rwanda Franc',
  );
  currencyLabels[CurrencyCode.SAR] = i18n.t(
    'common:currency.sar',
    'Saudi Riyal',
  );
  currencyLabels[CurrencyCode.SBD] = i18n.t(
    'common:currency.sbd',
    'Solomon Islands Dollar',
  );
  currencyLabels[CurrencyCode.SCR] = i18n.t(
    'common:currency.scr',
    'Seychelles Rupee',
  );
  currencyLabels[CurrencyCode.SDG] = i18n.t(
    'common:currency.sdg',
    'Sudanese Pound',
  );
  currencyLabels[CurrencyCode.SEK] = i18n.t(
    'common:currency.sek',
    'Swedish Krona',
  );
  currencyLabels[CurrencyCode.SGD] = i18n.t(
    'common:currency.sgd',
    'Singapore Dollar',
  );
  currencyLabels[CurrencyCode.SHP] = i18n.t(
    'common:currency.shp',
    'Saint Helena Pound',
  );
  currencyLabels[CurrencyCode.SLL] = i18n.t('common:currency.sll', 'Leone');
  currencyLabels[CurrencyCode.SOS] = i18n.t(
    'common:currency.sos',
    'Somali Shilling',
  );
  currencyLabels[CurrencyCode.SRD] = i18n.t(
    'common:currency.srd',
    'Surinam Dollar',
  );
  currencyLabels[CurrencyCode.SSP] = i18n.t(
    'common:currency.ssp',
    'South Sudanese Pound',
  );
  currencyLabels[CurrencyCode.STN] = i18n.t('common:currency.stn', 'Dobra');
  currencyLabels[CurrencyCode.SVC] = i18n.t(
    'common:currency.svc',
    'El Salvador Colon',
  );
  currencyLabels[CurrencyCode.SYP] = i18n.t(
    'common:currency.syp',
    'Syrian Pound',
  );
  currencyLabels[CurrencyCode.SZL] = i18n.t('common:currency.szl', 'Lilangeni');
  currencyLabels[CurrencyCode.THB] = i18n.t('common:currency.thb', 'Baht');
  currencyLabels[CurrencyCode.TJS] = i18n.t('common:currency.tjs', 'Somoni');
  currencyLabels[CurrencyCode.TMT] = i18n.t(
    'common:currency.tmt',
    'Turkmenistan New Manat',
  );
  currencyLabels[CurrencyCode.TND] = i18n.t(
    'common:currency.tnd',
    'Tunisian Dinar',
  );
  currencyLabels[CurrencyCode.TOP] = i18n.t('common:currency.top', 'Pa’anga');
  currencyLabels[CurrencyCode.TRY] = i18n.t(
    'common:currency.try',
    'Turkish Lira',
  );
  currencyLabels[CurrencyCode.TTD] = i18n.t(
    'common:currency.ttd',
    'Trinidad and Tobago Dollar',
  );
  currencyLabels[CurrencyCode.TWD] = i18n.t(
    'common:currency.twd',
    'New Taiwan Dollar',
  );
  currencyLabels[CurrencyCode.TZS] = i18n.t(
    'common:currency.tzs',
    'Tanzanian Shilling',
  );
  currencyLabels[CurrencyCode.UAH] = i18n.t('common:currency.uah', 'Hryvnia');
  currencyLabels[CurrencyCode.UGX] = i18n.t(
    'common:currency.ugx',
    'Uganda Shilling',
  );
  currencyLabels[CurrencyCode.USD] = i18n.t('common:currency.usd', 'US Dollar');
  currencyLabels[CurrencyCode.USN] = i18n.t(
    'common:currency.usn',
    'US Dollar (Next day)',
  );
  currencyLabels[CurrencyCode.UYI] = i18n.t(
    'common:currency.uyi',
    'Uruguay Peso en Unidades Indexadas (URUIURUI)',
  );
  currencyLabels[CurrencyCode.UYU] = i18n.t(
    'common:currency.uyu',
    'Peso Uruguayo',
  );
  currencyLabels[CurrencyCode.UYW] = i18n.t(
    'common:currency.uyw',
    'Unidad previsional',
  );
  currencyLabels[CurrencyCode.UZS] = i18n.t(
    'common:currency.uzs',
    'Uzbekistan Sum',
  );
  currencyLabels[CurrencyCode.VES] = i18n.t(
    'common:currency.ves',
    'Venezuelan bolívar soberano',
  );
  currencyLabels[CurrencyCode.VND] = i18n.t('common:currency.vnd', 'Dong');
  currencyLabels[CurrencyCode.VUV] = i18n.t('common:currency.vuv', 'Vatu');
  currencyLabels[CurrencyCode.WST] = i18n.t('common:currency.wst', 'Tala');
  currencyLabels[CurrencyCode.XAF] = i18n.t(
    'common:currency.xaf',
    'CFA Franc BEAC',
  );
  currencyLabels[CurrencyCode.XAG] = i18n.t('common:currency.xag', 'Silver');
  currencyLabels[CurrencyCode.XAU] = i18n.t('common:currency.xau', 'Gold');
  currencyLabels[CurrencyCode.XBA] = i18n.t(
    'common:currency.xba',
    'European Composite Unit (EURCO)',
  );
  currencyLabels[CurrencyCode.XBB] = i18n.t(
    'common:currency.xbb',
    'European Monetary Unit (E.M.U.-6)',
  );
  currencyLabels[CurrencyCode.XBC] = i18n.t(
    'common:currency.xbc',
    'European Unit of Account 9 (E.U.A.-9)',
  );
  currencyLabels[CurrencyCode.XBD] = i18n.t(
    'common:currency.xbd',
    'European Unit of Account 17 (E.U.A.-17)',
  );
  currencyLabels[CurrencyCode.XCD] = i18n.t(
    'common:currency.xcd',
    'East Caribbean Dollar',
  );
  currencyLabels[CurrencyCode.XDR] = i18n.t(
    'common:currency.xdr',
    'SDR (Special Drawing Right)',
  );
  currencyLabels[CurrencyCode.XOF] = i18n.t(
    'common:currency.xof',
    'CFA Franc BCEAO',
  );
  currencyLabels[CurrencyCode.XPD] = i18n.t('common:currency.xpd', 'Palladium');
  currencyLabels[CurrencyCode.XPF] = i18n.t('common:currency.xpf', 'CFP Franc');
  currencyLabels[CurrencyCode.XPT] = i18n.t('common:currency.xpt', 'Platinum');
  currencyLabels[CurrencyCode.XSU] = i18n.t('common:currency.xsu', 'Sucre');
  currencyLabels[CurrencyCode.XTS] = i18n.t(
    'common:currency.xts',
    'Code reserved for testing',
  );
  currencyLabels[CurrencyCode.XUA] = i18n.t(
    'common:currency.xua',
    'ADB Unit of Account',
  );
  currencyLabels[CurrencyCode.YER] = i18n.t(
    'common:currency.yer',
    'Yemeni Rial',
  );
  currencyLabels[CurrencyCode.ZAR] = i18n.t('common:currency.zar', 'Rand');
  currencyLabels[CurrencyCode.ZMW] = i18n.t(
    'common:currency.zmw',
    'Zambian Kwacha',
  );
  currencyLabels[CurrencyCode.ZWL] = i18n.t(
    'common:currency.zwl',
    'Zimbabwe Dollar',
  );
};

i18n.on('languageChanged init', () => {
  fill();
});

export default currencyLabels;
