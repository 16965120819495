/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Card } from '@multiplier/common';
import tw from 'twin.macro';

import SelectLaptopContainer from 'team/company/pages/equipment/components/select-laptop-container';
import RequestSubmittedContainer from 'team/company/pages/equipment/request-submitted-container';

import {
  Contract,
  EquipmentProcurement,
  Maybe,
  useGetProcurementsForContractLazyQuery,
} from '__generated__/graphql';

const MainContainer = tw(Card)`p-extra-large`;

const Header = tw.div`
  flex flex-col mb-[20px]
`;

const Heading = tw.div`
  text-text-primary font-normal text-18
`;

const isLaptopProcurementRequested = (
  procurements?: Maybe<Array<EquipmentProcurement>>,
): boolean => {
  if ((procurements?.length ?? 0) > 0) {
    return true;
  }
  return false;
};

interface SelectLaptopSectionProps {
  contract: Contract;
}

const SelectLaptopSection: React.FC<SelectLaptopSectionProps> = ({
  contract,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const [
    getProcurements,
    { data, loading },
  ] = useGetProcurementsForContractLazyQuery();

  useEffect(() => {
    if (contract?.id) {
      getProcurements({
        variables: {
          contractId: contract.id,
        },
      });
    }
  }, []);

  return (
    <MainContainer>
      {!loading &&
      isLaptopProcurementRequested(data?.contract?.equipmentProcurements) ? (
        <>
          <Header>
            <Heading>
              {t(
                'laptop-request.heading',
                'Issue laptops & IT accessories to your employees',
              )}
            </Heading>
          </Header>
          <RequestSubmittedContainer
            procurementData={data?.contract?.equipmentProcurements}
            contract={contract}
          />
        </>
      ) : (
        <SelectLaptopContainer contract={contract} numberOfLaptopCards={2} />
      )}
    </MainContainer>
  );
};

export default SelectLaptopSection;
