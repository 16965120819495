import { gql } from '@apollo/client';

export default gql`
  query GetCompanyHomeCurrency {
    company {
      id
      primaryEntity {
        id
        currency
      }
    }
  }
`;
