import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { usePermission } from 'app/hooks';
import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  CompanyUserRole,
  Contract,
  ContractType,
  GetSignatoriesQuery,
  useGetCompanyUsersQuery,
  useGetContractAgreementLazyQuery,
  useGetSignatoriesQuery,
  useSendForSignatureMutation,
  useSendOrderFormForSignatureMutation,
} from '__generated__/graphql';

import { useAddSignatory } from '../company/hooks';
import { AddSignatoryFormValues } from '../components/progress-widget-content/add-signatory-modal';

const useSendForSignature = ({
  contractId,
  contractType,
  openSignatoryModal,
  closeSignatoryModal,
  closeContractModal,
}: {
  contractId: string | null | undefined;
  contractType: Contract['type'];
  openSignatoryModal?: () => void;
  closeSignatoryModal?: () => void;
  closeContractModal?: () => void;
}): {
  loading: boolean;
  handleAddSignatory: (values: AddSignatoryFormValues) => Promise<void>;
  handleSendForContractClick: () => Promise<void>;
  hasSignatories: boolean;
  signatories?: GetSignatoriesQuery;
} => {
  const { t } = useTranslation('contract-onboarding.company');

  const [canCreateCompanyUser] = usePermission('create.company.users');

  const {
    data: companyUsersResponse,
    loading: loadingCompanyUsers,
    refetch: refetchSignatories,
  } = useGetCompanyUsersQuery();

  const {
    data: signatoriesResponse,
    loading: loadingSignatories,
  } = useGetSignatoriesQuery();

  const {
    loading: loadingAddSignatory,
    handleAddSignatory: addSignatory,
  } = useAddSignatory();

  const signatories = signatoriesResponse?.company?.signatories ?? [];
  const hasSignatories = signatories.length > 0;

  const firstSuperAdmin = useMemo(
    () =>
      companyUsersResponse?.company?.managers?.filter(
        (companyUser) => companyUser?.role === CompanyUserRole.PRIMARY_ADMIN,
      )?.[0],
    [companyUsersResponse],
  );

  const [
    sendForSignature,
    { loading: loadingSendForSignature },
  ] = useSendForSignatureMutation({
    onError: () => {
      errorNotification(
        '',
        t(
          'onboarding-phase.send-for-signature.failed',
          'Failed to send emails for signature',
        ),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t(
          'onboarding-phase.send-for-signature.success',
          'Successfully sent emails for signature',
        ),
        false,
      );
      if (closeSignatoryModal) closeSignatoryModal();
    },
  });

  const [
    sendOrderFormForSignature,
    { loading: loadingSendOrderFormForSignature },
  ] = useSendOrderFormForSignatureMutation({
    onError: () => {
      errorNotification(
        '',
        t(
          'onboarding-phase.send-for-signature.failed',
          'Failed to send emails for signature',
        ),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t(
          'onboarding-phase.send-for-signature.success',
          'Successfully sent emails for signature',
        ),
        false,
      );
      if (closeSignatoryModal) closeSignatoryModal();
    },
  });

  const [
    getContractAgreement,
    { loading: contractLoading },
  ] = useGetContractAgreementLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (closeSignatoryModal) closeSignatoryModal();
      refetchSignatories();
    },
  });

  const handleAddSignatory = async (values: AddSignatoryFormValues) => {
    if (!contractId) return;

    await addSignatory(values);
    getContractAgreement({ variables: { id: contractId } });
  };

  const handleSendForContractClick = async () => {
    if (!contractId) return;

    if (closeContractModal) {
      closeContractModal();
    }

    if (!hasSignatories && canCreateCompanyUser) {
      if (openSignatoryModal) openSignatoryModal();
    } else if (!hasSignatories && !canCreateCompanyUser) {
      errorNotification(
        t(
          'onboarding-phase.send-for-signature.add-signatory-permission-error.header',
          'Signature not added!',
        ),
        t(
          'onboarding-phase.send-for-signature.add-signatory-permission-error.description',
          {
            defaultValue: 'Please contact {{ name }}, {{ email }}',
            replace: {
              name: `${firstSuperAdmin?.firstName} ${firstSuperAdmin?.lastName}`,
              email: firstSuperAdmin?.emails?.[0]?.email,
            },
          },
        ),
        false,
      );
    } else if (contractType === ContractType.CONTRACTOR) {
      await sendOrderFormForSignature({ variables: { id: contractId } });
    } else {
      await sendForSignature({ variables: { id: contractId } });
    }
  };

  return {
    loading:
      loadingSignatories ||
      loadingAddSignatory ||
      loadingCompanyUsers ||
      loadingSendForSignature ||
      loadingSendOrderFormForSignature ||
      contractLoading,
    handleAddSignatory,
    handleSendForContractClick,
    signatories: signatoriesResponse,
    hasSignatories,
  };
};

export default useSendForSignature;
