import { gql } from '@apollo/client';

export default gql`
  query ResendOtp {
    resendOtp
      @rest(
        type: "Void"
        method: "GET"
        path: "resend/otp"
        endpoint: "user"
        bodySerializer: "text"
      ) {
      void
    }
  }
`;
