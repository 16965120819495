import React, { useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import {
  ComboBox,
  DatePicker,
  TextInput,
  UploadedDocumentViewer,
} from '@multiplier/common';
import { useGetLatestValidBirthdate } from '@multiplier/contract-onboarding';
import { Experience, userVar } from '@multiplier/user';
import { Dictionary } from 'lodash';
import tw from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import countryLabels from 'common/constants/country-labels';
import { genderLabel } from 'common/constants/default-labels';
import { MemberCountryLegalRequirements } from 'contract-onboarding/member/hooks/get-country-legal-requirements-for-member';
import {
  getChangeRequestItemByKey,
  haveSubmittedChangeRequestItem,
} from 'contract-onboarding/services/change-request';

import {
  Contract,
  ContractType,
  CountryCode,
  DataFieldDefinition,
  Gender,
  Maybe,
  Member,
  MemberChangeRequest,
} from '__generated__/graphql';

import { BasicDetailsFormValues } from '.';
import { Paths } from '../../utils/types';
import DetailGridLabel from '../detail-grid-label';
import DetailRow from '../detail-row';
import { DetailRowType } from '../detail-row/types';
import DocumentsProofFooter from '../documents-proof-footer';
import { DetailGrid } from '../layout';
import LegalDetailsSection from '../legal-details-section';
import PreDataRequirementsSection from '../pre-data-requirements-section';

interface FormDataProps {
  changeRequest: Maybe<MemberChangeRequest> | undefined;
  lastSubmitOn: Maybe<Date> | undefined;
  member: Maybe<Member> | undefined;
  country: Contract['country'];
  isFormEditing: boolean;
  isEditing: boolean;
  shouldShowDOB: boolean;
  editDisabledFields?: Paths<BasicDetailsFormValues>[];
  memberLegalDataRequirements: Dictionary<DataFieldDefinition>;
  type: Contract['type'];
  hideLegalDetailsSection: boolean;
  requirements: MemberCountryLegalRequirements;
  requireApproval: boolean;
}

const FormData: React.FC<FormDataProps> = ({
  changeRequest,
  lastSubmitOn,
  member,
  country,
  isFormEditing,
  isEditing,
  editDisabledFields,
  shouldShowDOB,
  memberLegalDataRequirements,
  type,
  hideLegalDetailsSection,
  requirements,
  requireApproval,
}) => {
  const { t } = useTranslation([
    'contract-onboarding.common',
    'contract-onboarding.member',
  ]);
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const {
    register,
    control,
    trigger,
    formState: { errors },
    setValue,
  } = useFormContext();

  const latestValidBirthdate = useGetLatestValidBirthdate();

  const changeRequestItemsByKey = {
    firstName: getChangeRequestItemByKey('firstName', changeRequest)?.strValue,
    lastName: getChangeRequestItemByKey('lastName', changeRequest)?.strValue,
    fullLegalName: getChangeRequestItemByKey('fullLegalName', changeRequest)
      ?.strValue,
    gender: getChangeRequestItemByKey('gender', changeRequest)?.genderValue,
    dateOfBirth: getChangeRequestItemByKey('dateOfBirth', changeRequest)
      ?.dateValue,
    nationality: getChangeRequestItemByKey('nationality', changeRequest)
      ?.nationalogyValue,
    legalData: getChangeRequestItemByKey('legalData', changeRequest)?.legalData,
  };

  const changeRequestItemsByKeyCompanyExp = {
    legalData: getChangeRequestItemByKey('legalData', changeRequest)?.legalData,
  };

  const genderValues = React.useMemo(() => {
    if (country === CountryCode.ITA) {
      return [
        { title: genderLabel[Gender.MALE], value: Gender.MALE },
        {
          title: genderLabel[Gender.FEMALE],
          value: Gender.FEMALE,
        },
      ];
    }

    return [
      { title: genderLabel[Gender.MALE], value: Gender.MALE },
      {
        title: genderLabel[Gender.FEMALE],
        value: Gender.FEMALE,
      },
      {
        title: genderLabel[Gender.UNSPECIFIED],
        value: Gender.UNSPECIFIED,
      },
    ];
  }, [country]);

  const nationalityDropdownValues = useMemo(
    () =>
      Object.values(CountryCode).map((c) => ({
        label: countryLabels[c],
        value: c,
        title: c,
      })),
    [],
  );

  return (
    <DetailGrid>
      {current === Experience.MEMBER &&
        haveSubmittedChangeRequestItem(changeRequest) &&
        lastSubmitOn && <DetailGridLabel submitOn={lastSubmitOn} />}
      <DetailRow
        id="first-name"
        label={t('onboarding-phase.basic-details.first-name', 'First Name')}
        value={member?.firstName}
        newValue={changeRequestItemsByKey.firstName}
        isEditing={isFormEditing}
        type={
          haveSubmittedChangeRequestItem(changeRequest)
            ? DetailRowType.COMPARE_MODE
            : DetailRowType.BASIC_MODE
        }
        hide={editDisabledFields?.includes('firstName')}
      >
        <TextInput
          id="first-name"
          placeholder={t(
            'definition-phase.basic-details.first-name.placeholder',
            'John',
          )}
          divStyles={tw`col-span-3`}
          {...register('firstName')}
          error={!!errors?.firstName}
        />
      </DetailRow>
      <DetailRow
        id="last-name"
        label={t('onboarding-phase.basic-details.last-name', 'Last Name')}
        value={member?.lastName}
        newValue={changeRequestItemsByKey.lastName}
        isEditing={isFormEditing}
        type={
          haveSubmittedChangeRequestItem(changeRequest)
            ? DetailRowType.COMPARE_MODE
            : DetailRowType.BASIC_MODE
        }
        hide={editDisabledFields?.includes('lastName')}
      >
        <TextInput
          id="last-name"
          divStyles={tw`col-span-3`}
          placeholder={t(
            'definition-phase.basic-details.last-name.placeholder',
            'Doe',
          )}
          error={!!errors?.lastName}
          {...register('lastName')}
        />
      </DetailRow>
      {current === Experience.COMPANY && (
        <DetailRow
          id="email-address"
          label={t(
            'onboarding-phase.basic-details.email-address',
            'Email Address',
          )}
          value={member?.emails?.[0]?.email}
          isEditing={isFormEditing}
          hide={editDisabledFields?.includes('email')}
        >
          <TextInput
            id="email-address"
            divStyles={tw`col-span-3`}
            {...register('email')}
            onChange={(e) => {
              register('email').onChange(e);
              trigger('email');
            }}
            error={!!errors.email}
          />
        </DetailRow>
      )}
      <DetailRow
        id="gender"
        label={t(
          'onboarding-phase.basic-details-section.gender.label',
          'Gender',
        )}
        value={member?.gender && genderLabel[member.gender]}
        newValue={
          changeRequestItemsByKey.gender &&
          genderLabel[changeRequestItemsByKey.gender]
        }
        isEditing={isFormEditing}
        type={
          haveSubmittedChangeRequestItem(changeRequest)
            ? DetailRowType.COMPARE_MODE
            : DetailRowType.BASIC_MODE
        }
        hide={editDisabledFields?.includes('gender')}
      >
        <Controller
          control={control}
          name="gender"
          render={({ field: { value, onChange } }) => (
            <ComboBox
              id="gender"
              tw="col-span-3"
              data-testid="select-gender"
              variant="default"
              dropdownValues={genderValues}
              showArrow
              value={value || ''}
              placeholder={t(
                'onboarding-phase.basic-details-section.gender.placeholder',
                'Select',
              )}
              onChange={onChange}
              error={!!errors?.gender}
            />
          )}
        />
      </DetailRow>
      <DetailRow
        id="legalName"
        label={t('onboarding-phase.basic-details.legal-name', 'Legal Name')}
        value={member?.fullLegalName}
        newValue={changeRequestItemsByKey.fullLegalName}
        isEditing={current === Experience.MEMBER && isEditing}
        type={
          haveSubmittedChangeRequestItem(changeRequest)
            ? DetailRowType.COMPARE_MODE
            : DetailRowType.BASIC_MODE
        }
        hide={editDisabledFields?.includes('fullLegalName')}
      >
        <TextInput
          divStyles={tw`col-span-3`}
          id="legalName"
          placeholder={t(
            'member.basic-details.name.placeholder',
            'John David Markus',
          )}
          error={!!errors?.fullLegalName}
          {...register('fullLegalName')}
        />
      </DetailRow>
      {/* {type === ContractType.EMPLOYEE && current === Experience.MEMBER && (
          <DetailRow
            id="deputedAt"
            label={t('onboarding-phase.basic-details.deputed-at', 'Deputed at')}
            value={data?.company?.primaryEntity?.legalName}
          />
        )} */}
      {shouldShowDOB && (
        <DetailRow
          id="dateOfBirth"
          label={t(
            'onboarding-phase.basic-details.date-of-birth',
            'Date of Birth',
          )}
          value={member?.dateOfBirth && dateAsString(member?.dateOfBirth)}
          newValue={
            changeRequestItemsByKey.dateOfBirth &&
            dateAsString(changeRequestItemsByKey.dateOfBirth)
          }
          isEditing={
            (current === Experience.MEMBER || current === Experience.COMPANY) &&
            isEditing
          }
          type={
            haveSubmittedChangeRequestItem(changeRequest)
              ? DetailRowType.COMPARE_MODE
              : DetailRowType.BASIC_MODE
          }
          hide={editDisabledFields?.includes('dateOfBirth')}
        >
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                data-testid="date-of-birth"
                error={!!errors?.dateOfBirth}
                value={value as never}
                onChange={onChange}
                disabledInput
                disabled={
                  memberLegalDataRequirements?.dateOfBirth?.editable === false
                }
                max={latestValidBirthdate}
                tw="col-span-3"
              />
            )}
          />
        </DetailRow>
      )}
      {current === Experience.MEMBER && (
        <DetailRow
          id="nationality"
          label={t('onboarding-phase.basic-details.nationality', 'Nationality')}
          value={
            member?.nationalities?.[0]?.country &&
            countryLabels[member?.nationalities?.[0]?.country]
          }
          newValue={
            changeRequestItemsByKey.nationality?.country &&
            countryLabels[changeRequestItemsByKey.nationality.country]
          }
          isEditing={current === Experience.MEMBER && isEditing}
          type={
            haveSubmittedChangeRequestItem(changeRequest)
              ? DetailRowType.COMPARE_MODE
              : DetailRowType.BASIC_MODE
          }
          hide={editDisabledFields?.includes('nationality')}
        >
          <Controller
            name="nationality"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div tw="col-span-3">
                <ComboBox
                  data-testid="select-nationality"
                  variant="autocomplete"
                  dropdownValues={nationalityDropdownValues}
                  placeholder={t(
                    'member.basic-details.nationality.placeholder',
                    'Select Nationality',
                  )}
                  value={value as string}
                  onChange={onChange}
                  showArrow
                  error={!!errors?.nationality}
                />
              </div>
            )}
          />
        </DetailRow>
      )}
      {type !== ContractType.FREELANCER &&
        !hideLegalDetailsSection &&
        (current === Experience.COMPANY ? (
          <PreDataRequirementsSection
            requirements={requirements}
            member={member}
            isEditing={isEditing}
            changeRequest={changeRequest}
            changeRequestItemsByKey={changeRequestItemsByKeyCompanyExp}
          />
        ) : (
          <LegalDetailsSection
            requirements={requirements}
            member={member}
            isEditing={isEditing}
            changeRequest={changeRequest}
            changeRequestItemsByKey={changeRequestItemsByKey}
          />
        ))}
      {(!!changeRequest?.files?.length || isEditing) && (
        <DetailRow
          id="documents-proof"
          isEditing={isFormEditing}
          value={changeRequest?.files?.length}
          hideLabel={!!changeRequest?.files && !isEditing}
          renderValue={
            <div tw="mt-large" data-testid="documents">
              <p tw="text-ps font-normal text-text-primary my-tiny">
                {t('basic-details.attach-proof', 'Attached Identity Proof')}
              </p>
              <div tw="flex flex-row">
                {changeRequest?.files?.map((doc) => (
                  <UploadedDocumentViewer
                    key={`basic-document-${doc?.id}`}
                    link={doc?.downloadUrl}
                    label={doc?.name}
                    date={changeRequest.createdOn}
                  />
                ))}
              </div>
            </div>
          }
          type={DetailRowType.BASIC_MODE}
          hide={editDisabledFields?.includes('documentsProof')}
        >
          <DocumentsProofFooter
            label={t('edit.basic-details.proof', 'Attach Identity Proof')}
            onChange={(files) => {
              setValue('documentsProof', files);
              trigger('documentsProof');
            }}
            experience={current}
            requireApproval={requireApproval}
          />
        </DetailRow>
      )}
    </DetailGrid>
  );
};

export default FormData;
