import { gql } from '@apollo/client';

export default gql`
  mutation ContractOffboard(
    $id: ID!
    $lastWorkingDay: Date!
    $offboardingNote: String!
  ) {
    contractOffboard(
      id: $id
      lastWorkingDay: $lastWorkingDay
      offboardingNote: $offboardingNote
    ) {
      id
      status
      offboardingNote
      lastWorkingDay
      endedOn
      endOn
      type
    }
  }
`;
