/** @jsxImportSource @emotion/react */
import React from 'react';

import { Icon } from '@multiplier/common';
import { theme } from 'twin.macro';

const InfoNote: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => (
  <div tw="flex" {...props}>
    <Icon name="info" fill={theme`colors.icon-secondary`} tw="mr-6 shrink-0" />
    <div tw="text-text-secondary text-ps">{children}</div>
  </div>
);

export default InfoNote;
