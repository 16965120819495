import { useEffect, useRef } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useBeforeUnload = (callback: () => void): void => {
  const cb = useRef(callback);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [cb]);
};
