import { add, subDays, yearsToMonths } from 'date-fns';
import { round } from 'lodash';

import {
  Contract,
  ContractTerm,
  ContractType,
  CountryCode,
  DataFieldDefinition,
  Maybe,
} from '__generated__/graphql';

import { ComplianceLimitation } from './basic-details';

export type GroupedComplianceLimitationDefinition = {
  [key: string]: ComplianceLimitation;
};

export const isFieldDisabled = (
  limitations: GroupedComplianceLimitationDefinition,
  field: string,
  value: string,
): boolean => {
  const limitation = limitations[field];

  return !!(
    limitation &&
    limitation.value &&
    limitation.value === value &&
    limitation.enabled === false
  );
};

export const isFieldDisabledWithPreconditions = (
  limitations: GroupedComplianceLimitationDefinition,
  field: string,
  value: string,
  preconditionsValues?: {
    contractType?: ContractType | null;
    contractTerm?: ContractTerm | null;
  },
): boolean => {
  const limitation = limitations[field];
  const meetPreconditions =
    !limitation?.preconditions ||
    limitation?.preconditions.length === 0 ||
    limitation?.preconditions.some(
      (precondition) =>
        (!precondition.contractTerm ||
          precondition.contractTerm === preconditionsValues?.contractTerm) &&
        (!precondition.contractType ||
          precondition.contractType === preconditionsValues?.contractType),
    );
  return !!(
    limitation &&
    limitation.value &&
    limitation.value === value &&
    limitation.enabled === false &&
    meetPreconditions
  );
};

export const getDisabledFieldDescription = (
  disabledFields: GroupedComplianceLimitationDefinition,
  field: string,
): string | null => {
  const description = disabledFields?.[field]?.description;

  // Only allow non-empty description
  return !!description && description.length > 0 ? description : null;
};

export const getContractTerm = (
  condition: boolean,
  disabledFields: GroupedComplianceLimitationDefinition,
): ContractTerm => {
  if (condition) {
    return isFieldDisabled(disabledFields, 'contractTerm', ContractTerm.FIXED)
      ? ContractTerm.PERMANENT
      : ContractTerm.FIXED;
  }
  return isFieldDisabled(disabledFields, 'contractTerm', ContractTerm.PERMANENT)
    ? ContractTerm.FIXED
    : ContractTerm.PERMANENT;
};

export const getMemberDataRequirementValues = (
  f: DataFieldDefinition,
  member: Maybe<Contract['member']> | undefined,
): string | null | undefined => {
  switch (f.key) {
    case 'address.line1':
      return member?.addresses?.[0]?.line1;
    case 'address.line2':
      return member?.addresses?.[0]?.line2 ?? '';
    case 'address.country':
      return member?.addresses?.[0]?.country;
    case 'address.state':
      return member?.addresses?.[0]?.state;
    case 'address.city':
      return member?.addresses?.[0]?.city;
    case 'address.postalCode':
      return member?.addresses?.[0]?.postalCode;
    case 'phoneNumber':
      return member?.phoneNos?.[0]?.phoneNo;
    case 'nationality':
      return member?.nationalities?.[0]?.country;
    case 'maritalStatus':
      return member?.maritalStatus;
    default:
      return member?.legalData?.find((data) => data?.key === f.key)?.value;
  }
};

export const mapDefaultLegalDataRequirementFields = (
  memberDataRequirementFields: DataFieldDefinition[],
  country: CountryCode | null | undefined,
): { key: string; value: string; type: 'date' | 'text' }[] =>
  memberDataRequirementFields
    .filter((definition) => !!definition.key)
    .map((definition) => {
      const key = definition.key as string;

      if (country && definition.key === 'address.country') {
        return { key, value: country, type: 'text' };
      }

      return {
        key,
        value: '',
        type:
          definition?.dataType?.__typename === 'DateField' ? 'date' : 'text',
      };
    });

export const getMaximumEndDate = (
  employmentStartDate: Date,
  disabledFields: GroupedComplianceLimitationDefinition,
  contractType: ContractType | undefined | null,
): Date => {
  if (
    contractType === ContractType.EMPLOYEE &&
    disabledFields &&
    disabledFields?.contractTermPeriodMax
  ) {
    const contractTermPeriod = parseFloat(
      disabledFields?.contractTermPeriodMax?.value,
    );

    const months = round(yearsToMonths(contractTermPeriod));

    return subDays(add(new Date(employmentStartDate), { months }), 1);
  }

  return new Date(5000, 1, 1);
};
