import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import {
  showModuleBasedOnContractStatusAndContractStarted,
  showModuleBasedOnContractType,
} from 'app/utils/module-checks';

import { ContractStatus, ContractType } from '__generated__/graphql';

const config: ModuleConfig = {
  id: 'member-time-off',
  permission: 'time-off',
  folder: 'time-off/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Time off',
    route: 'time-off',
    sort: 2,
    home: false,
    icon: { name: 'schedule' },
    activeIcon: { name: 'schedule-filled' },
    group: NavGroups.MAIN,
  },
  enabled: async () => {
    const shouldShow = await Promise.all([
      showModuleBasedOnContractType([
        ContractType.HR_MEMBER,
        ContractType.EMPLOYEE,
      ]),
      showModuleBasedOnContractStatusAndContractStarted([
        ContractStatus.ACTIVE,
        ContractStatus.ONBOARDING,
        ContractStatus.OFFBOARDING,
      ]),
    ]);

    return shouldShow.every((show) => show);
  },
};

export default config;
