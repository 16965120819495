import React from 'react';

import { SidebarEntryProps } from '../components/sidebar';

export enum Experience {
  COMPANY = 'company',
  MEMBER = 'member',
  PARTNER = 'partner',
  OPERATIONS = 'operations',
  DEVELOPER = 'developer',
}

export enum WidgetVariant {
  FULL_WIDTH = 'FULL_WIDTH',
  HALF_WIDTH = 'HALF_WIDTH',
}

export enum WidgetPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface IconConfig {
  name: string;
  fill?: string;
  stroke?: string;
}

export enum NavGroups {
  MAIN = 'MAIN',
  FOOTER = 'FOOTER',
}
export enum CompanyNavGroups {
  ADMINISTRATION = 'ADMINISTRATION',
  COMPANY = 'COMPANY',
  SETTINGS = 'SETTINGS',
}

export enum MemberNavGroups {
  EMPLOYMENT = 'EMPLOYMENT',
  PAYMENTS = 'PAYMENTS',
  FORMS_SUBMISSION = 'FORMS_SUBMISSION',
  COMPANY = 'MEMBER_COMPANY',
}

type NavGroupUnion = NavGroups | CompanyNavGroups | MemberNavGroups;
type NavGroup = NavGroupUnion | (() => NavGroupUnion);

export type WidgetItemConfig = {
  file: string;
  show?: boolean | (() => Promise<boolean> | boolean);
  position?: WidgetPosition;
  variant?: WidgetVariant;
};

export type NavIconType = IconConfig | (() => IconConfig);

export type NavItemName = string | (() => string);

export interface SideNavGroupConfig {
  id: string;
  items: ModuleConfig[];
  nav: Partial<ModuleConfig['nav']>;
}

export interface ModuleConfig {
  id: string;
  /**
   * Permission that need to be allowed for using module. Format: `use.${experience}.${permission}`.
   */
  permission: string;
  /**
   * Folder where module located within project. Being used for importing view
   */
  folder: string;
  /**
   * Which experience this module belong to. E.g: COMPANY; MEMBER; etc..
   */
  experience: Experience;
  /**
   * Configuration for sidebar navigation item
   */
  nav: {
    /**
     * Default display name
     */
    defaultName: NavItemName;
    /**
     * Navigation route
     */
    route: string;
    /**
     * Default icon display
     */
    icon?: NavIconType;
    /**
     * Icon display when navigation item is active
     */
    activeIcon?: NavIconType;
    /**
     * Order for sorting between navigation items within same group
     */
    sort: number;
    /**
     * Group where this navigation item belongs to
     * - If using "NavGroup.MAIN", it would be renders on root list
     * - If using a specific group, it would be nested as child item
     */
    group: NavGroup;
    /**
     * Should show as default view after login. Only one module should be configured as home
     */
    home: boolean;
    /**
     * Either a boolean value or a resolve function to indicate visibility for navigation item
     */
    hide?: boolean | (() => Promise<boolean> | boolean);
    /**
     * Custom render for navigation item
     */
    custom?: React.FC<SidebarEntryProps>;
  };
  widget?: {
    sort?: number;
    list?: WidgetItemConfig[];
  };
  banner?: {
    show?: boolean;
    component?: React.ReactNode;
    sort?: number;
  };
  /**
   * Determines if this config can be hidden using a feature flag
   */
  featureFlag?: boolean | string;
  /**
   * Determines if GrowthBook fails to initialize what should happen
   */
  featureFlagDefaultValue?: boolean;
  /**
   * Re-use interface to add imported view into config
   */
  view?: React.ReactNode;
  /**
   * Return false to disable a module based on condition
   */
  enabled?: boolean | (() => Promise<boolean> | boolean);
  preloadNs?: string[];
}
