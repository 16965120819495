/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon, ToolTip } from '@multiplier/common';

import ComboBox, { DropdownValue } from 'common/components/combo-box';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';

import {
  ContractType,
  CountryCode,
  DataFieldDefinition,
  DropDownTextField,
} from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '..';

interface LegalDataRequirementsProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
  legalDataRequirements: DataFieldDefinition[] | null;
  memberLegalDataDefinitionKeysToShow?: string[];
  contractCountry?: CountryCode | null;
  contractType?: ContractType | null;
}

const ItalyTooltip: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.member');

  return (
    <ToolTip
      content={
        <div tw="w-[380px]">
          {t(
            'member.basic-details.legal-data.italy.national-id.tooltip',
            'The fiscal code officially known as Codice fiscale, is the tax code in Italy, It is an alphanumeric code of 16 characters.',
          )}
        </div>
      }
    >
      <Icon tw="pl-tiny" name="info" />
    </ToolTip>
  );
};

const LegalDataRequirements: React.FC<LegalDataRequirementsProps> = ({
  methods,
  legalDataRequirements,
  memberLegalDataDefinitionKeysToShow,
  contractCountry,
  contractType,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    register,
    formState: { errors, defaultValues },
  } = methods;

  return (
    <>
      {legalDataRequirements?.map((legalData, index) => {
        const isItalyNationalId =
          contractCountry === CountryCode.ITA &&
          legalData?.key === 'nationalId';
        const isPolandNationalId =
          contractCountry === CountryCode.POL &&
          legalData?.key === 'nationalId';
        const isChinaNationalId =
          contractCountry === CountryCode.CHN &&
          legalData?.key === 'nationalId';

        const isHelperShown =
          !isItalyNationalId && !isPolandNationalId && legalData.description;
        const isNotFreelancerField =
          legalData.dataType?.__typename === 'TextField' &&
          contractType !== ContractType.FREELANCER;
        const isFreelancerOrContractorField =
          legalData.dataType?.__typename === 'DropDownTextField' &&
          (contractType === ContractType.FREELANCER ||
            contractType === ContractType.CONTRACTOR);

        const showDefinition = memberLegalDataDefinitionKeysToShow?.includes(
          legalData?.key || '',
        );

        const disabled =
          !!defaultValues?.legalData?.[index]?.value && !legalData?.editable;

        return !showDefinition ? null : (
          <div key={legalData.key}>
            {(isNotFreelancerField || isChinaNationalId) && (
              <TextInput.Container>
                <div tw="flex flex-row">
                  <TextInput.Label htmlFor="legal-data">
                    {t(
                      `legal-details.${legalData.label}.label`,
                      legalData.label ?? '',
                    )}
                  </TextInput.Label>
                  {isItalyNationalId && <ItalyTooltip />}
                </div>
                {isHelperShown && (
                  <TextInput.Helper>
                    {t(
                      `legal-details.${legalData.label}.description`,
                      legalData.description ?? '',
                    )}
                  </TextInput.Helper>
                )}
                <TextInput
                  data-testid="basic-details.legal-data"
                  id="legalData"
                  placeholder={t(
                    'member.basic-details.legal-data.placeholder',
                    'Add number',
                  )}
                  {...register(`legalData.${index}.value`)}
                  error={!!errors.legalData?.[index]?.value?.message}
                  disabled={disabled}
                />
                {errors.legalData && (
                  <TextInput.Error>
                    {errors.legalData[index]?.value?.message}
                  </TextInput.Error>
                )}
              </TextInput.Container>
            )}
            {isFreelancerOrContractorField && (
              <TextInput.Container>
                <TextInput.Label htmlFor={`legal-data-${legalData.key}`}>
                  {t(`member.basic-details.${legalData.label}.label`)}
                </TextInput.Label>
                <DropdownText.Container>
                  <Controller
                    name={`legalData.${index}.identifier`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ComboBox
                        data-testid={`select-${legalData.label}`}
                        variant="default"
                        tw="-ml-tiny -mt-tiny whitespace-nowrap w-[9rem]"
                        value={value as string}
                        placeholder={t(
                          `member.basic-details.${legalData.label}.dropdown`,
                          'Select',
                        )}
                        dropdownValues={
                          (legalData.dataType as DropDownTextField)?.values?.map(
                            (dropdownValue) => ({
                              title: t(
                                `${
                                  legalData.key
                                }-dropdown.option.${dropdownValue?.toLowerCase()}`,
                                dropdownValue ?? '',
                              ),
                              value: dropdownValue,
                            }),
                          ) as DropdownValue[]
                        }
                        onChange={onChange}
                        disabled={disabled}
                      />
                    )}
                  />
                  <DropdownText.Input
                    tw="w-full"
                    id={`${legalData.key}-value`}
                    placeholder={t(
                      `basic-details.${legalData.key}.placeholder`,
                    )}
                    error={!!errors?.legalData?.[index]?.value?.message}
                    {...register(`legalData.${index}.value`)}
                    disabled={disabled}
                  />
                </DropdownText.Container>
                {errors.legalData?.[index] && (
                  <TextInput.Error>
                    {errors.legalData?.[index]?.value?.message}
                  </TextInput.Error>
                )}
              </TextInput.Container>
            )}
          </div>
        );
      })}
    </>
  );
};

export default LegalDataRequirements;
