/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { useFeature } from '@growthbook/growthbook-react';
import { Accordion, useModal } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';
import { useGetTimeOffDataLazy } from '@multiplier/time-off';
import { isNil } from 'lodash';
import tw from 'twin.macro';

import { successNotification } from 'app/services/notification-services';
import { notEmpty } from 'app/utils/array';
import { useComplianceParamsDefinition } from 'contract-onboarding/company/hooks';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import ContractTemplateInput from 'contract-onboarding/company/pages/definition-phase/pages/compliance/components/contract-template-input';
import internalLeaveTypes from 'contract-onboarding/constants/internal-leave-types';
import { useSubmitCompliance } from 'contract-onboarding/hooks';
import { Sections } from 'team/components/employee-details-component';

import {
  ComplianceContractor,
  ComplianceFreelance,
  ComplianceMultiplierEor,
  ComplianceParamDefinition,
  ComplianceParamPeriodLimit,
  ComplianceParamPeriodUnit,
  CompliancePartnerEor,
  CompliancePeo,
  Contract,
  ContractAgreementType,
  ContractOnboarding,
  ContractOnboardingStatus,
  ContractType,
  Maybe,
} from '__generated__/graphql';

import { getComplianceParamDependencies } from '../../services/compliance';
import {
  hasLeaveEntitlementsBesidesUnpaidType,
  hasMandatoryFieldsBesidesUnpaidType,
} from '../../services/leave-entitlement';
import ComplianceEntry from '../compliance-entry';
import ContractReviewSectionModal, {
  FooterSection,
} from '../contract-review-section-modal';
import ContractTemplateViewer from '../contract-template';
import ContractViewer from '../contract-viewer';
import { DetailForm } from '../layout';
import { LeaveEntitlementFormEntryValue } from '../leave-entitlement';
import CustomLeaveEntry from '../leave-entitlement/custom-leave-entry';
import InitialCustomLeaveEntry from '../leave-entitlement/initial-custom-leave-entry';
import LeaveEntitlementViewMode from '../leave-entitlement/leave-entitlement-view-mode';
import MandatoryLeaveEntry from '../leave-entitlement/mandatory-leave-entry';
import SectionActionBar from '../section-action-bar';

export interface ComplianceFormValues {
  complianceParams: {
    key: string | null;
    value: number | null;
    unit: ComplianceParamPeriodUnit | null;
    enabled: boolean;
  }[];
  mandatory?: LeaveEntitlementFormEntryValue[];
  custom?: LeaveEntitlementFormEntryValue[];
  preferredContractAgreementTemplate?: string | null;
}

export const showComplianceParams = (
  compliance:
    | ComplianceMultiplierEor
    | CompliancePartnerEor
    | CompliancePeo
    | ComplianceFreelance
    | ComplianceContractor
    | null
    | undefined,
): boolean => {
  if (
    compliance?.__typename &&
    [
      'ComplianceMultiplierEOR',
      'CompliancePartnerEOR',
      'ComplianceContractor',
    ].includes(compliance.__typename)
  ) {
    return true;
  }

  if (
    compliance?.__typename &&
    ['CompliancePEO'].includes(compliance.__typename)
  )
    return (
      (compliance as CompliancePeo).contractAgreementType ===
      ContractAgreementType.MULTIPLIER_TEMPLATE
    );

  return false;
};

const ContractSection: React.FC<{
  id: Contract['id'];
  type: Contract['type'];
  term: Contract['term'];
  country: Contract['country'];
  countryStateCode: Contract['countryStateCode'];
  start: Contract['startOn'];
  end: Contract['endOn'];
  companyId: string;
  compliance?: Contract['compliance'];
  edit: boolean;
  status?: Contract['status'];
  onboardingStatus?: ContractOnboarding['status'];
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  compensation?: Contract['compensation'];
  toggle?: (key: string) => void;
  open?: boolean;
}> = ({
  id,
  type,
  term,
  country,
  countryStateCode,
  start,
  end,
  compliance,
  edit,
  status,
  onboardingStatus,
  companyId,
  uncommittedChangesCallback,
  compensation,
  toggle,
  open,
}) => {
  const isCompanyMultipleContractTemplatesEnabled = useFeature(
    AppFeature.COMPANY_MULTIPLE_CONTRACT_TEMPLATES_ONBOARDING,
  )?.on;
  const { t } = useTranslation('contract-onboarding.company');
  const [showEdit, toggleEdit] = useToggle(false);
  const [
    showEditFormModal,
    handleCloseEditFormModal,
    handleOpenEditFormModal,
  ] = useModal(false);

  const modalCloseHandler = () => {
    toggleEdit(false);
    resetComplianceParamValues();
    handleCloseEditFormModal();
  };

  const {
    groupedComplianceParamDefinitions: {
      complianceParamDefinitions,
      usedScope,
    },
  } = useComplianceParamsDefinition(
    type,
    country,
    countryStateCode,
    term,
    start,
    end,
  );

  const [
    getTimeOffData,
    {
      mandatoryFields: mandatory,
      customFields: custom,
      assignedTimeOffEntitlements,
      timeOffTypeToDefinitionMap,
      availableCustomEntitlementOptions,
      refetch,
    },
  ] = useGetTimeOffDataLazy();

  const shouldHideLeaveEntitlementForHrMember = type === ContractType.HR_MEMBER;

  const methods = useForm<ComplianceFormValues>({});

  const {
    formState: { isDirty, dirtyFields },
  } = methods;

  const { fields } = useFieldArray<ComplianceFormValues>({
    control: methods.control,
    name: 'complianceParams',
  });

  const { fields: mandatoryFields } = useFieldArray({
    name: 'mandatory',
    control: methods.control,
  });

  const { fields: customFields, append, remove } = useFieldArray({
    name: 'custom',
    control: methods.control,
  });

  const complianceParamsVisible = useMemo(
    () => showComplianceParams(compliance),
    [compliance],
  );

  const resetComplianceParamValues = () => {
    methods.reset({
      preferredContractAgreementTemplate: (compliance as ComplianceMultiplierEor)
        ?.preferredContractAgreementTemplate?.template?.id,
      complianceParams:
        (compliance?.complianceParams &&
          compliance.complianceParams
            ?.filter(notEmpty)
            .filter((param) => param.key !== 'leavePolicy') // filter out leave policy temporarily
            .map((param: ComplianceParamPeriodLimit) => {
              const defaultValue = param.key
                ? complianceParamDefinitions[param.key]?.validation?.find(
                    (definition) => param?.unit === definition?.unit,
                  )?.defaultValue
                : undefined;

              return {
                key: param.key,
                value: param.value === -1 ? defaultValue : param.value,
                unit: param.unit,
                enabled: param.value !== -1,
              };
            })) ??
        undefined,
      ...(!isNil(assignedTimeOffEntitlements)
        ? {
            mandatory,
            custom,
          }
        : {}),
    });
  };

  useEffect(() => {
    if (id && companyId && country) {
      getTimeOffData({
        contractId: id,
        contractType: type,
      });
    }
  }, [companyId, id, country, type, term, status]);

  useEffect(() => {
    resetComplianceParamValues();
  }, [compliance, assignedTimeOffEntitlements, complianceParamDefinitions]);

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.CONTRACT_DETAILS,
        value: isDirty,
      });
  }, [isDirty]);

  const { loading, onSubmit } = useSubmitCompliance(() => {
    refetch?.();
    modalCloseHandler();
    successNotification(
      t(
        'onboarding-phase.contract-section.success-notification',
        'Contract details have been updated in the contract.',
      ),
      '',
      false,
    );
  });

  const aorOrderFormNotSigned =
    type === ContractType.CONTRACTOR &&
    (onboardingStatus === ContractOnboardingStatus.CREATED ||
      onboardingStatus ===
        ContractOnboardingStatus.ORDER_FORM_SENT_TO_EMPLOYER);

  const FormData = useCallback(
    ({ isFormEditing = false }: { isFormEditing?: boolean }) => (
      <>
        {compliance?.contractAgreement?.id && !aorOrderFormNotSigned && (
          <ContractViewer
            contractId={id}
            filename={compliance.contractAgreement?.name}
            status={status}
            type={type}
          />
        )}
        {isCompanyMultipleContractTemplatesEnabled &&
          type === ContractType.EMPLOYEE && (
            <>
              {isFormEditing ? (
                <ContractTemplateInput
                  companyId={companyId}
                  contractType={type}
                  contractTerm={term}
                  contractCountry={country}
                />
              ) : (
                <ContractTemplateViewer
                  compliance={compliance as ComplianceMultiplierEor}
                />
              )}
            </>
          )}
        {!shouldHideLeaveEntitlementForHrMember &&
          hasLeaveEntitlementsBesidesUnpaidType(
            assignedTimeOffEntitlements,
          ) && (
            <span tw="text-ps text-text-primary font-semibold -mb-base">
              {t(
                'onboarding-phase.contract.leave-entitlement.header',
                'Leave Entitlement',
              )}
            </span>
          )}
        {!shouldHideLeaveEntitlementForHrMember &&
          (isFormEditing ? (
            <>
              {hasMandatoryFieldsBesidesUnpaidType(mandatoryFields) && (
                <div tw="grid grid-template-columns[auto repeat(3,max-content)] items-center gap-y-small gap-x-large text-ps text-text-primary">
                  <span>
                    {t(
                      'compliance.leave-entitlement.mandatory.leave-type',
                      'Leave Type',
                    )}
                  </span>
                  <span>
                    {t(
                      'compliance.leave-entitlement.mandatory.allocated',
                      'Allocated',
                    )}
                  </span>
                  <span>
                    {t(
                      'compliance.leave-entitlement.mandatory.additional',
                      'Additional',
                    )}
                  </span>
                  <span>
                    {t('compliance.leave-entitlement.mandatory.total', 'Total')}
                  </span>
                  {mandatoryFields.map(
                    (field, index) =>
                      !internalLeaveTypes.includes(field.key) && (
                        <MandatoryLeaveEntry
                          key={field.id}
                          field={field}
                          index={index}
                          timeOffTypeToDefinitionMap={
                            timeOffTypeToDefinitionMap
                          }
                        />
                      ),
                  )}
                </div>
              )}
              {availableCustomEntitlementOptions &&
                availableCustomEntitlementOptions.length > 0 && (
                  <div tw="flex flex-col gap-y-12 text-ps">
                    <span tw="text-grey01">
                      {`${t(
                        'compliance.leave-entitlement.custom.header',
                        'Custom Leaves',
                      )}:`}
                    </span>
                    <div tw="grid grid-template-columns[auto auto min-content] items-center gap-y-12 gap-x-12 text-background font-medium">
                      <span>
                        {t(
                          'compliance.leave-entitlement.custom.leave-type',
                          'Leave Type',
                        )}
                      </span>
                      <span>
                        {t('compliance.leave-entitlement.custom.days', 'Days')}
                      </span>
                      <span />
                      {customFields.map((field, index) => (
                        <CustomLeaveEntry
                          key={field.id}
                          index={index}
                          field={field}
                          remove={remove}
                          availableCustomEntitlementOptions={
                            availableCustomEntitlementOptions
                          }
                          timeOffTypeToDefinitionMap={
                            timeOffTypeToDefinitionMap
                          }
                        />
                      ))}
                      <InitialCustomLeaveEntry
                        append={append}
                        availableCustomEntitlementOptions={
                          availableCustomEntitlementOptions
                        }
                        timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
                      />
                    </div>
                  </div>
                )}
            </>
          ) : (
            <LeaveEntitlementViewMode
              assignedTimeOffEntitlements={assignedTimeOffEntitlements}
              timeOffTypeToDefinitionMap={timeOffTypeToDefinitionMap}
            />
          ))}
        {!shouldHideLeaveEntitlementForHrMember &&
          complianceParamsVisible &&
          fields.map((field, index) => (
            <ComplianceEntry
              id={field.key ?? String(index)}
              key={field.id}
              field={field}
              index={index}
              complianceParamDefinition={
                (field.key && complianceParamDefinitions[field.key]) as
                  | Maybe<ComplianceParamDefinition>
                  | undefined
              }
              dependencies={getComplianceParamDependencies(
                field.key,
                complianceParamDefinitions,
                fields.map((element, elementIndex) => ({
                  key: element.key,
                  index: elementIndex,
                })),
              )}
              scope={usedScope}
              inline
              edit={isFormEditing}
              hideDisabled={!isFormEditing && !edit}
              country={country}
              compensation={compensation}
              contractTerm={term}
              contractType={type}
            />
          ))}
      </>
    ),
    [
      compliance,
      complianceParamDefinitions,
      availableCustomEntitlementOptions,
      compensation,
      customFields,
    ],
  );

  return (
    <Accordion
      name={
        type === ContractType.CONTRACTOR
          ? t('onboarding-phase.contract.contractor.header', 'Agreement')
          : t('onboarding-phase.contract.header', 'Contract')
      }
      description={
        type === ContractType.CONTRACTOR
          ? t(
              'onboarding-phase.contract.contractor.description',
              'Agreement document and the associated compliance details',
            )
          : t(
              'onboarding-phase.contract.description-new',
              'Contract document and the associated compliance details',
            )
      }
      edit={edit}
      icon="signature"
      iconStyle={tw`w-large h-large`}
      onEditClick={() => {
        toggleEdit();
        handleOpenEditFormModal();
        if (toggle) toggle(Sections.CONTRACT);
      }}
      warning={uncommittedChangesCallback && isDirty}
      onClick={() => {
        if (toggle) toggle(Sections.CONTRACT);
      }}
      open={open}
    >
      <FormProvider {...methods}>
        <DetailForm
          data-testid="contract-section-expanded"
          onSubmit={methods.handleSubmit((values) =>
            onSubmit(values, id, undefined, type === ContractType.CONTRACTOR),
          )}
        >
          <FormData />
        </DetailForm>
      </FormProvider>
      {showEditFormModal && (
        <ContractReviewSectionModal
          modalTitle={
            type === ContractType.CONTRACTOR
              ? t(
                  'onboarding-phase.contract.contract-review-modal.title-contractor',
                  'Edit Agreement Details',
                )
              : t(
                  'onboarding-phase.contract.contract-review-modal.title',
                  'Edit Contract Details',
                )
          }
          onClose={modalCloseHandler}
        >
          <FormProvider {...methods}>
            <DetailForm
              data-testid="contract-section-modal"
              onSubmit={methods.handleSubmit((values) => onSubmit(values, id))}
            >
              <div tw="flex flex-col p-extra-large py-none pt-base gap-y-extra-large">
                <FormData isFormEditing={showEdit} />
              </div>
              <FooterSection>
                <SectionActionBar
                  disabled={loading || Object.keys(dirtyFields).length === 0}
                  onCancelClick={modalCloseHandler}
                  submitLoading={loading}
                  tw="mx-auto"
                />
              </FooterSection>
            </DetailForm>
          </FormProvider>
        </ContractReviewSectionModal>
      )}
    </Accordion>
  );
};

export default ContractSection;
