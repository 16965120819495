/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { dateAsString } from '../../../app/utils/format';

const DetailGridLabel: React.FC<{ submitOn: Date }> = ({ submitOn }) => {
  const { t } = useTranslation('contract-onboarding.common');

  return (
    <>
      <div tw="col-start-2 col-span-1 text-text-tertiary font-semibold text-ps">
        {t('current-active', 'Currently Active')}
      </div>
      <div tw="col-span-2 text-text-tertiary text-ps font-normal">
        <b tw="font-semibold">{t('submitted', 'Submitted')}:</b>{' '}
        {dateAsString(submitOn)}
      </div>
    </>
  );
};

export default DetailGridLabel;
