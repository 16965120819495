import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Object: any;
  Time: any;
  UUID: any;
  Upload: any;
  _FieldSet: any;
  join__FieldSet: any;
};

export type AchCompanyPayinMethod = CompanyPayInMethod &
  PayInMethod & {
    __typename?: 'ACHCompanyPayinMethod';
    bankDetails?: Maybe<Array<Maybe<CompanyPayInMethodBankDetails>>>;
    chargeBackPeriod?: Maybe<Scalars['Int']>;
    company?: Maybe<Company>;
    context?: Maybe<PayInMethodContext>;
    currentStatus?: Maybe<CompanyPayInMethodWorkflowStatus>;
    currentStep?: Maybe<PayInMethodDefinitionStep>;
    data?: Maybe<Array<Maybe<KeyValue>>>;
    documents?: Maybe<Array<Maybe<DocumentData>>>;
    id: Scalars['ID'];
    isActive?: Maybe<Scalars['Boolean']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    payInMethodType?: Maybe<PayInMethodType>;
    workflowSteps: Array<Maybe<CompanyPayInMethodWorkflowStep>>;
  };

export type AccessibilityResponse = {
  __typename?: 'AccessibilityResponse';
  editable?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<FieldAccessibilityRule>>;
};

export type AccountDetails = {
  __typename?: 'AccountDetails';
  data?: Maybe<Array<Maybe<KeyValue>>>;
  documents?: Maybe<Array<Maybe<DocumentData>>>;
  payInAccountRequirementType?: Maybe<Scalars['String']>;
};

export type AccountRequirementContext = {
  __typename?: 'AccountRequirementContext';
  accountType?: Maybe<PaymentAccountType>;
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  sourceCurrency?: Maybe<CurrencyCode>;
  targetCountry?: Maybe<CountryCode>;
  targetCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export type AccountRequirementInput = {
  data?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  documents?: InputMaybe<Array<InputMaybe<DocumentKeyValueInput>>>;
  payInAccountRequirementType?: InputMaybe<Scalars['String']>;
};

export enum AccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export enum Action {
  ADMIN_TERMINATE = 'ADMIN_TERMINATE',
  CUSTOMER_APPROVE_INPUT = 'CUSTOMER_APPROVE_INPUT',
  CUSTOMER_APPROVE_OUTPUT = 'CUSTOMER_APPROVE_OUTPUT',
  CUSTOMER_RECONCILE_OUTPUT = 'CUSTOMER_RECONCILE_OUTPUT',
  OPS_APPROVE_INPUT = 'OPS_APPROVE_INPUT',
  OPS_APPROVE_OUTPUT = 'OPS_APPROVE_OUTPUT',
  OPS_APPROVE_PAYSLIPS = 'OPS_APPROVE_PAYSLIPS',
  OPS_CLOSE_WORKFLOW = 'OPS_CLOSE_WORKFLOW',
  OPS_PROCESS_INPUT = 'OPS_PROCESS_INPUT',
  OPS_RECONCILE_OUTPUT = 'OPS_RECONCILE_OUTPUT',
  OPS_UPLOAD_PAYSLIPS = 'OPS_UPLOAD_PAYSLIPS',
  PARTNER_SUBMIT_OUTPUT = 'PARTNER_SUBMIT_OUTPUT',
  PARTNER_UPLOAD_OUTPUT = 'PARTNER_UPLOAD_OUTPUT',
  PLATFORM_INIT = 'PLATFORM_INIT',
  PLATFORM_LOCK_INPUT = 'PLATFORM_LOCK_INPUT',
  PLATFORM_PROCESS_INPUT = 'PLATFORM_PROCESS_INPUT',
  PLATFORM_RECONCILE_OUTPUT = 'PLATFORM_RECONCILE_OUTPUT',
  PLATFORM_VALIDATE_INPUT = 'PLATFORM_VALIDATE_INPUT',
  PLATFORM_VALIDATE_OUTPUT = 'PLATFORM_VALIDATE_OUTPUT',
  PLATFORM_VALIDATE_PAYSLIPS = 'PLATFORM_VALIDATE_PAYSLIPS',
}

export type ActivationCutoffData = {
  __typename?: 'ActivationCutoffData';
  date?: Maybe<Scalars['Date']>;
  urgency?: Maybe<Urgency>;
};

export type ActivityDocument = {
  __typename?: 'ActivityDocument';
  document?: Maybe<Document>;
  errorMessages?: Maybe<Scalars['Object']>;
  /** @deprecated Use `document` instead */
  file?: Maybe<FileLink>;
  opsUserEmail?: Maybe<Scalars['String']>;
  partnerCountryId?: Maybe<Scalars['ID']>;
  status?: Maybe<ActivityDocumentStatus>;
  type?: Maybe<ActivityType>;
};

export type ActivityDocumentFilter = {
  partnerCountryId?: InputMaybe<Scalars['String']>;
};

export enum ActivityDocumentStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum ActivityType {
  PARTNER_COUNTRY_ADD = 'PARTNER_COUNTRY_ADD',
  PARTNER_COUNTRY_UPDATE = 'PARTNER_COUNTRY_UPDATE',
}

export type AddApproverInfoInput = {
  approverInfo?: InputMaybe<Array<InputMaybe<ApproverInfoInput>>>;
  companyId: Scalars['ID'];
  companyUserId: Scalars['ID'];
};

export type AddCompanyUserInput = {
  capabilities?: InputMaybe<Array<InputMaybe<CompanyUserCapability>>>;
  companyId: Scalars['ID'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  /** Deprecated. use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isBillingContact?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isSignatory?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CompanyUserRole>;
  title?: InputMaybe<Scalars['String']>;
};

export type AdditionalPayComponent = {
  __typename?: 'AdditionalPayComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PayComponentType>;
  value?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  key?: Maybe<Scalars['String']>;
  landmark?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  landmark?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AddressParam = {
  __typename?: 'AddressParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Address>;
};

export type Allocation = {
  __typename?: 'Allocation';
  count?: Maybe<Scalars['Int']>;
  user?: Maybe<OperationsUser>;
};

export enum AllocationBasis {
  ANNUALLY = 'ANNUALLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
  QUARTERLY = 'QUARTERLY',
  WEEKLY = 'WEEKLY',
}

export type AllocationConfig = {
  __typename?: 'AllocationConfig';
  basis?: Maybe<AllocationBasis>;
  prorated?: Maybe<Scalars['Boolean']>;
};

export type Allowance = {
  __typename?: 'Allowance';
  country: CountryCode;
  label: Scalars['String'];
  mandatory: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AllowedValue = {
  __typename?: 'AllowedValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Amount = {
  __typename?: 'Amount';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export type AmountWithCurrency = {
  __typename?: 'AmountWithCurrency';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export enum ApprovalCategory {
  EXPENSE = 'EXPENSE',
  LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
  MEMBER_DATA = 'MEMBER_DATA',
  /** @deprecated unused since mid 2022, when we stopped using the `approval framework` for memberPayable */
  MEMBER_PAYABLE = 'MEMBER_PAYABLE',
  TIME_OFF = 'TIME_OFF',
  TIME_SHEET = 'TIME_SHEET',
}

export type ApprovalItem = {
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<ApprovalItemStatus>;
};

export enum ApprovalItemStatus {
  ACTION_TAKEN = 'ACTION_TAKEN',
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type Approver = {
  __typename?: 'Approver';
  approverUser?: Maybe<Person>;
  categoryContracts?: Maybe<Array<Maybe<ApproverCategoryContract>>>;
  id?: Maybe<Scalars['ID']>;
};

export type ApproverCategoryContract = {
  __typename?: 'ApproverCategoryContract';
  category?: Maybe<ApprovalCategory>;
  managedContracts?: Maybe<Array<Maybe<Contract>>>;
};

export type ApproverInfoInput = {
  category?: InputMaybe<ApprovalCategory>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum AssessmentRiskScore {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}

export enum AssessmentType {
  COMPLIANCE = 'COMPLIANCE',
  FINANCIAL = 'FINANCIAL',
}

export type AttributeInput = {
  legalEntityId?: InputMaybe<Scalars['ID']>;
};

export type Audit = {
  __typename?: 'Audit';
  completedOn?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
};

export type AuditUser = {
  __typename?: 'AuditUser';
  experience?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type AutoCompleteField = {
  __typename?: 'AutoCompleteField';
  optionListType?: Maybe<Scalars['String']>;
};

export type AutoDebitInfo = {
  __typename?: 'AutoDebitInfo';
  id: Scalars['ID'];
  scheduledDebitDate?: Maybe<Scalars['Date']>;
};

export type AutoDebitInput = {
  bundleId: Scalars['ID'];
  executionDate?: InputMaybe<Scalars['Date']>;
};

export type AvailableSalaryCalculationParams = {
  __typename?: 'AvailableSalaryCalculationParams';
  country: CountryCode;
  currency: Array<CurrencyCode>;
  frequency: Array<RateFrequency>;
  payableType: Array<CalculationPayableType>;
  rateType: Array<RateType>;
  states: Array<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountDetails?: Maybe<Array<Maybe<BankAccountDetail>>>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountRequirementContext?: Maybe<AccountRequirementContext>;
  bankIdentifier?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankStatements?: Maybe<Array<Maybe<FileLink>>>;
  branchName?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  country?: Maybe<CountryCode>;
  /** @deprecated No longer supported */
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  localBankCode?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type BankAccountDetail = {
  __typename?: 'BankAccountDetail';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BankAccountFields = {
  __typename?: 'BankAccountFields';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankAccountInput = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  /**
   * null, [], [null], [null, null]...are considered "empty"<br>
   * The mutation will consider these "existing documents" and simply save them into DB along with "document ids" returned from uploading `bankStatements: [Upload]`<br>
   * If both `bankStatements` and `bankStatementDocIds` are empty, the mutation will not touch existing `bankStatementDocIds` in the DB
   */
  bankStatementDocIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /**
   * null, [], [null], [null, null]...are considered "empty"<br>
   * The mutation will upload these files and save into DB as "document ids" along with `bankStatementDocIds: [ID]`<br>
   * If both `bankStatements` and `bankStatementDocIds` are empty, the mutation will not touch existing `bankStatementDocIds` in the DB
   */
  bankStatements?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  branchName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  currency?: InputMaybe<CurrencyCode>;
  dynamicDetail?: InputMaybe<BankDetailDynamicInput>;
  localBankCode?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type BankAccountParam = {
  __typename?: 'BankAccountParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<BankAccount>;
};

export enum BankCode {
  HSBC = 'HSBC',
}

export type BankDetailDynamicInput = {
  accountType?: InputMaybe<PaymentAccountType>;
  fieldValues?: InputMaybe<Array<InputMaybe<RequirementFieldValue>>>;
  paymentAccountRequirementType?: InputMaybe<Scalars['String']>;
  paymentPartner?: InputMaybe<PaymentPartnerCode>;
  sourceCurrency?: InputMaybe<CurrencyCode>;
  targetCurrency?: InputMaybe<CurrencyCode>;
  transferType?: InputMaybe<TransferType>;
};

export type Benefit = {
  __typename?: 'Benefit';
  benefitDocuments?: Maybe<Array<Maybe<BenefitDocument>>>;
  benefitStatus?: Maybe<BenefitStatus>;
  billFrequency?: Maybe<RateFrequency>;
  compareBenefits?: Maybe<BenefitComparison>;
  contractType?: Maybe<ContractType>;
  cost?: Maybe<Scalars['Float']>;
  costingType?: Maybe<BenefitCostingType>;
  country?: Maybe<CountryCode>;
  countryStateCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  dependentMaxCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  factSheetUrl?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<BenefitFeatures>>>;
  frequency?: Maybe<RateFrequency>;
  id: Scalars['String'];
  isRecommended?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  memberOnboardingKit?: Maybe<BenefitMemberOnboardingKit>;
  packageName?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  packageTier?: Maybe<BenefitPackageTier>;
  provider?: Maybe<BenefitPartnerCountry>;
  showOpsOnly?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tier?: Maybe<InsuranceTier>;
  type?: Maybe<BenefitType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type Benefit_CompareBenefitsArgs = {
  benefitId?: InputMaybe<Scalars['String']>;
};

export type Benefit_MemberOnboardingKitArgs = {
  benefitId?: InputMaybe<Scalars['String']>;
};

export type BenefitBrokerData = {
  __typename?: 'BenefitBrokerData';
  /** @deprecated Use companyLogoUrl instead. */
  companyLogo?: Maybe<DocumentReadable>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  entity?: Maybe<LegalEntity>;
  name?: Maybe<Scalars['String']>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  scope?: Maybe<BenefitScope>;
};

export type BenefitComparison = {
  __typename?: 'BenefitComparison';
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  comparisonFile?: Maybe<BenefitDocument>;
  /** @deprecated use benefits.benefitDocuments instead */
  comparisonFileUrl?: Maybe<Scalars['String']>;
};

export enum BenefitCostingType {
  AGE_BASED = 'AGE_BASED',
  FIXED = 'FIXED',
}

export type BenefitDocument = {
  __typename?: 'BenefitDocument';
  benefitDocumentType?: Maybe<BenefitDocumentType>;
  /** @deprecated Use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  identifier?: Maybe<Scalars['String']>;
};

export type BenefitDocumentInput = {
  benefitDocumentType?: InputMaybe<BenefitDocumentType>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  identifier?: InputMaybe<Scalars['String']>;
};

export enum BenefitDocumentType {
  FACT_SHEET = 'FACT_SHEET',
  INSURANCE_COMPARISON_DOCUMENT = 'INSURANCE_COMPARISON_DOCUMENT',
  INSURANCE_DOCUMENT = 'INSURANCE_DOCUMENT',
}

export type BenefitFeatures = {
  __typename?: 'BenefitFeatures';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BenefitFeaturesInput = {
  key?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type BenefitFilters = {
  benefitId?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  contractType?: InputMaybe<ContractType>;
  /** @deprecated No longer supported */
  country?: InputMaybe<CountryCode>;
  /** @deprecated No longer supported */
  countryStateCode?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated No longer supported */
  packageName?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['ID']>;
  showOpsOnly?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<BenefitStatus>;
  /** @deprecated No longer supported */
  tier?: InputMaybe<BenefitTier>;
  /** @deprecated No longer supported */
  type?: InputMaybe<BenefitType>;
};

export type BenefitInput = {
  benefitStatus?: InputMaybe<BenefitStatus>;
  contractType?: InputMaybe<ContractType>;
  cost?: InputMaybe<Scalars['Float']>;
  costingType?: InputMaybe<BenefitCostingType>;
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  factSheetUrl?: InputMaybe<Scalars['String']>;
  features?: InputMaybe<Array<InputMaybe<BenefitFeaturesInput>>>;
  frequency?: InputMaybe<RateFrequency>;
  location?: InputMaybe<Scalars['String']>;
  packageName?: InputMaybe<Scalars['String']>;
  packageTier?: InputMaybe<BenefitTierInput>;
  providerId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<BenefitType>;
};

export type BenefitInsightDefinition = InsightDefinition & {
  __typename?: 'BenefitInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  benefitType?: Maybe<Scalars['String']>;
  category?: Maybe<InsightCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum BenefitInsuranceType {
  FAMILY_INSURANCE = 'FAMILY_INSURANCE',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type BenefitMemberOnboardingKit = {
  __typename?: 'BenefitMemberOnboardingKit';
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  memberOnboardingKitFile?: Maybe<BenefitDocument>;
};

export type BenefitMetaData = {
  __typename?: 'BenefitMetaData';
  brokerData?: Maybe<BenefitBrokerData>;
  companyLogo?: Maybe<DocumentReadable>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  pocName?: Maybe<Scalars['String']>;
};

export type BenefitPackageCost = {
  __typename?: 'BenefitPackageCost';
  ageHigherRange?: Maybe<Scalars['Int']>;
  ageLowerRange?: Maybe<Scalars['Int']>;
  benefitPackageId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  premium?: Maybe<Scalars['Float']>;
  premiumCurrency?: Maybe<CurrencyCode>;
  premiumFrequency?: Maybe<BenefitPremiumFrequency>;
  status?: Maybe<BenefitPackageCostStatus>;
};

export type BenefitPackageCostFilters = {
  benefitPackageCostId?: InputMaybe<Scalars['String']>;
  benefitPackageId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BenefitPackageCostStatus>;
};

export enum BenefitPackageCostStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type BenefitPackageTier = {
  __typename?: 'BenefitPackageTier';
  isDefault?: Maybe<Scalars['Boolean']>;
  tier?: Maybe<BenefitTier>;
};

export type BenefitParamInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum BenefitPartnerBillingFrequency {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  REAL_TIME = 'REAL_TIME',
}

export type BenefitPartnerCountriesResult = {
  __typename?: 'BenefitPartnerCountriesResult';
  data?: Maybe<Array<BenefitPartnerCountry>>;
  pageResult?: Maybe<PageResult>;
};

export type BenefitPartnerCountry = PartnerCountry & {
  __typename?: 'BenefitPartnerCountry';
  ageBandApplicable?: Maybe<Scalars['Boolean']>;
  backDatingDays?: Maybe<Scalars['Int']>;
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  benefitsDomain?: Maybe<BenefitsDomain>;
  billingCurrency?: Maybe<CurrencyCode>;
  billingDuration?: Maybe<BenefitPartnerBillingFrequency>;
  brokerData?: Maybe<BenefitBrokerData>;
  canMarkAsExpired?: Maybe<Scalars['Boolean']>;
  capability?: Maybe<PartnerCapability>;
  country?: Maybe<CountryCode>;
  coverageGroup?: Maybe<InsuranceCoverageGroup>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  dependentsAllowed?: Maybe<Scalars['Boolean']>;
  dependentsCutoffDays?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<Maybe<BenefitDocument>>>;
  emergencyPointOfContact?: Maybe<Person>;
  enrollmentProcess?: Maybe<BenefitPartnerEnrollmentProcess>;
  entity?: Maybe<LegalEntity>;
  erSplitApplicable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  insuranceType?: Maybe<BenefitInsuranceType>;
  isInsuranceMandatory?: Maybe<Scalars['Boolean']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  masterPolicyEndDate?: Maybe<Scalars['DateTime']>;
  masterPolicyStartDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use memberOnboardingKitUrl instead. */
  memberOnboardingKit?: Maybe<FileLink>;
  /** @deprecated No longer supported */
  memberOnboardingKitUrl?: Maybe<Scalars['String']>;
  memberType?: Maybe<ContractType>;
  metaData?: Maybe<BenefitMetaData>;
  minimumErSplitPercentage?: Maybe<Scalars['Float']>;
  operator?: Maybe<Array<Maybe<PartnerUser>>>;
  partner?: Maybe<Partner>;
  partnerDetails?: Maybe<BenefitPartnerData>;
  partnerName?: Maybe<Scalars['String']>;
  partnerPortalWeblink?: Maybe<Scalars['String']>;
  platformFee?: Maybe<Scalars['Int']>;
  platformFeeApplicable?: Maybe<Scalars['Boolean']>;
  policyRules?: Maybe<BenefitPolicyRules>;
  refundPolicy?: Maybe<Scalars['Boolean']>;
  showOpsOnly?: Maybe<Scalars['Boolean']>;
  status?: Maybe<BenefitPartnerStatus>;
  terminationType?: Maybe<BenefitTerminationType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type BenefitPartnerCountryFilters = {
  ageBandApplicable?: InputMaybe<Scalars['Boolean']>;
  backDatingDaysLower?: InputMaybe<Scalars['Int']>;
  backDatingDaysUpper?: InputMaybe<Scalars['Int']>;
  billingDuration?: InputMaybe<
    Array<InputMaybe<BenefitPartnerBillingFrequency>>
  >;
  brokerName?: InputMaybe<Scalars['String']>;
  countries?: InputMaybe<Array<InputMaybe<CountryCode>>>;
  coverageGroupId?: InputMaybe<Scalars['ID']>;
  dependentsAllowed?: InputMaybe<Scalars['Boolean']>;
  dependentsCutoffDaysLower?: InputMaybe<Scalars['Int']>;
  dependentsCutoffDaysUpper?: InputMaybe<Scalars['Int']>;
  erSplitApplicable?: InputMaybe<Scalars['Boolean']>;
  insuranceType?: InputMaybe<BenefitInsuranceType>;
  masterPolicyEndDateFrom?: InputMaybe<Scalars['DateTime']>;
  masterPolicyEndDateTo?: InputMaybe<Scalars['DateTime']>;
  masterPolicyStartDateFrom?: InputMaybe<Scalars['DateTime']>;
  masterPolicyStartDateTo?: InputMaybe<Scalars['DateTime']>;
  memberType?: InputMaybe<Array<InputMaybe<ContractType>>>;
  name?: InputMaybe<Scalars['String']>;
  platformFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<InputMaybe<BenefitPartnerStatus>>>;
};

export type BenefitPartnerCountryResponse = {
  __typename?: 'BenefitPartnerCountryResponse';
  benefitPartnerCountry?: Maybe<BenefitPartnerCountry>;
  response?: Maybe<TaskResponse>;
};

export type BenefitPartnerData = {
  __typename?: 'BenefitPartnerData';
  address?: Maybe<Address>;
  bankAccount?: Maybe<BankAccount>;
  companyLogo?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  scope?: Maybe<BenefitScope>;
};

export type BenefitPartnerDataDownloadInput = {
  benefitPartnerCountryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum BenefitPartnerEnrollmentProcess {
  MANUAL = 'MANUAL',
  ONLINE = 'ONLINE',
}

export enum BenefitPartnerStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type BenefitPolicyRules = {
  __typename?: 'BenefitPolicyRules';
  married?: Maybe<MarriedPolicyRules>;
  unmarried?: Maybe<UnmarriedPolicyRules>;
};

export enum BenefitPremiumFrequency {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
}

export enum BenefitScope {
  GLOBAL = 'GLOBAL',
  LOCAL = 'LOCAL',
}

export enum BenefitStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export enum BenefitTerminationType {
  IMMEDIATE = 'IMMEDIATE',
  NON_IMMEDIATE = 'NON_IMMEDIATE',
}

export enum BenefitTier {
  BRONZE = 'BRONZE',
  GOLD = 'GOLD',
  GOLD_PLUS = 'GOLD_PLUS',
  SILVER = 'SILVER',
}

export type BenefitTierInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  tier?: InputMaybe<BenefitTier>;
};

export enum BenefitType {
  FAMILY_INSURANCE = 'FAMILY_INSURANCE',
  INSURANCE = 'INSURANCE',
}

export enum BenefitsDomain {
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
}

export enum BillingRate {
  ANNUALLY = 'ANNUALLY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type BlockActor = {
  __typename?: 'BlockActor';
  domainUserId?: Maybe<Scalars['ID']>;
  type: BlockActorType;
  userId: Scalars['ID'];
};

export enum BlockActorType {
  COMPANY_USER = 'COMPANY_USER',
  MEMBER = 'MEMBER',
  OPS_USER = 'OPS_USER',
}

export type BlockData = ChangeRequestBlockData;

export type BlockEvent = {
  __typename?: 'BlockEvent';
  blockData?: Maybe<BlockData>;
  blockReason: Scalars['String'];
  blocker: BlockActor;
  contractId: Scalars['ID'];
  contractStatus: ContractStatus;
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  source: BlockSource;
  status: BlockStatus;
  turnAroundTimeInHours?: Maybe<Scalars['Int']>;
  unblockReason?: Maybe<Scalars['String']>;
  unblocker?: Maybe<BlockActor>;
  unblockerCandidates?: Maybe<Array<BlockActor>>;
};

export enum BlockSource {
  CONTRACT_CHANGE_REQUEST = 'CONTRACT_CHANGE_REQUEST',
}

export enum BlockStatus {
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED',
}

export type BooleanConfigValue = {
  __typename?: 'BooleanConfigValue';
  value: Scalars['Boolean'];
};

export enum BulkOnboardingContext {
  AOR = 'AOR',
  EOR = 'EOR',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL = 'GLOBAL_PAYROLL',
  HRIS_PROFILE_DATA = 'HRIS_PROFILE_DATA',
}

export type BulkOnboardingJob = {
  __typename?: 'BulkOnboardingJob';
  id: Scalars['String'];
  onboardingResult?: Maybe<BulkOnboardingResult>;
  startTime: Scalars['DateTime'];
  status: BulkOnboardingJobStatus;
  validationResult: BulkOnboardingValidationResult;
};

export enum BulkOnboardingJobStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export type BulkOnboardingMessage = {
  __typename?: 'BulkOnboardingMessage';
  columnName?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  employeeName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  rowNumber?: Maybe<Scalars['Int']>;
};

export type BulkOnboardingOptions = {
  companyId?: InputMaybe<Scalars['ID']>;
  context?: BulkOnboardingContext;
  contractType?: InputMaybe<ContractType>;
  countryCode?: InputMaybe<CountryCode>;
  entityId?: InputMaybe<Scalars['ID']>;
};

export type BulkOnboardingResult = {
  __typename?: 'BulkOnboardingResult';
  errors?: Maybe<Array<BulkOnboardingMessage>>;
  failedEmployeeCount: Scalars['Int'];
  onboardedEmployeeCount: Scalars['Int'];
  warnings?: Maybe<Array<BulkOnboardingMessage>>;
};

export type BulkOnboardingValidationResult = {
  __typename?: 'BulkOnboardingValidationResult';
  errorEmployeeCount: Scalars['Int'];
  errors?: Maybe<Array<BulkOnboardingMessage>>;
  totalEmployeeCount: Scalars['Int'];
  validEmployeeCount: Scalars['Int'];
  validationErrors?: Maybe<Array<BulkOnboardingMessage>>;
  validationResultFile?: Maybe<DocumentReadable>;
  warnings?: Maybe<Array<BulkOnboardingMessage>>;
};

export type BulkPayrollConfigUpdateInput = {
  calendarUpdateInput?: InputMaybe<CalendarUpdateInput>;
  configIds: Array<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BulkPayrollCycleConfigInput = {
  calendar: PayrollCalendarInput;
  frequency: PayFrequency;
  isDefault: Scalars['Boolean'];
  payrollConfigEntity: Array<PayrollConfigEntityInput>;
  type: PayrollCycleEntityType;
};

export enum BulkUploadItemType {
  BANK_DETAIL = 'BANK_DETAIL',
  DEDUCTION = 'DEDUCTION',
  EXPENSE = 'EXPENSE',
  HOLD_SALARY = 'HOLD_SALARY',
  OFF_BOARDING = 'OFF_BOARDING',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY_REVISION = 'SALARY_REVISION',
  TIME_OFF = 'TIME_OFF',
  TIME_SHEET = 'TIME_SHEET',
}

export type BulkUploadResponse = {
  __typename?: 'BulkUploadResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type BulkUploadValidationResponse = {
  __typename?: 'BulkUploadValidationResponse';
  bulkUploadFile?: Maybe<PayrollFile>;
  bulkUploadSummary?: Maybe<PayrollBulkUploadSummary>;
  errorReport?: Maybe<PayrollFileDownloadResponse>;
  validationResponse?: Maybe<ValidationResponse>;
};

export type CalculationBreakdown = {
  __typename?: 'CalculationBreakdown';
  entries: Array<CalculationEntryGroup>;
  frequency?: Maybe<RateFrequency>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  total: CalculationEntry;
};

export type CalculationEntry = {
  __typename?: 'CalculationEntry';
  amount: Scalars['Float'];
  currency?: Maybe<CurrencyCode>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type CalculationEntryGroup = {
  __typename?: 'CalculationEntryGroup';
  amount: Scalars['Float'];
  currency?: Maybe<CurrencyCode>;
  entries: Array<CalculationEntry>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export enum CalculationPayableType {
  COMPANY = 'COMPANY',
  EMPLOYEE = 'EMPLOYEE',
}

export type CalendarDateInput = {
  date: Scalars['Int'];
  relativeMonth: Scalars['Int'];
};

export type CalendarUpdateInput = {
  approvePayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  approvePayrollReportDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  approvePayrollReportDeadlineRelativeToPayDate?: InputMaybe<Scalars['Int']>;
  compensationDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  contractEndDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  contractStartDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  cutOffRelativeToPayDate?: InputMaybe<Scalars['Int']>;
  deductionDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  expenseDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  memberChangesDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  paySupplementDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payrollCompleteDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payrollProcessingDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  snapCutOffDates?: InputMaybe<Scalars['Boolean']>;
  snapDeadlineDates?: InputMaybe<Scalars['Boolean']>;
  snapPayDate?: InputMaybe<Scalars['Boolean']>;
  snapToDates?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  submitPayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  timeOffDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  timeSheetDateRelativeToEndDate?: InputMaybe<Scalars['Int']>;
};

export enum Capability {
  AOR = 'AOR',
  EOR = 'EOR',
  EXPENSES = 'EXPENSES',
  FREELANCER = 'FREELANCER',
  GROSS_TO_NET = 'GROSS_TO_NET',
  ORG_MANAGEMENT = 'ORG_MANAGEMENT',
  SALARY_DISBURSEMENT = 'SALARY_DISBURSEMENT',
  TIME_OFF = 'TIME_OFF',
  TIME_SHEET = 'TIME_SHEET',
}

export type CarryForwardConfig = {
  __typename?: 'CarryForwardConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  expiry?: Maybe<TimeOffDuration>;
  maxLimit?: Maybe<CarryForwardLimit>;
  minForEligibility?: Maybe<CarryForwardLimit>;
};

export type CarryForwardConfigInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  expiryConfig?: InputMaybe<CarryForwardExpiryInput>;
  maxLimit?: InputMaybe<TimeOffDurationInput>;
};

export type CarryForwardExpiryInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  expiry?: InputMaybe<TimeOffDurationInput>;
};

export type CarryForwardLimit = {
  __typename?: 'CarryForwardLimit';
  type?: Maybe<LimitValueType>;
  unit?: Maybe<TimeOffUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type CategoryApproverInfo = {
  __typename?: 'CategoryApproverInfo';
  approverUsers?: Maybe<Array<Maybe<Person>>>;
  category?: Maybe<ApprovalCategory>;
};

export type ChangeRequestBlockData = {
  __typename?: 'ChangeRequestBlockData';
  changeRequestId: Scalars['ID'];
};

export type ChangeRequestConfigFiltersInput = {
  contractId?: InputMaybe<Scalars['ID']>;
};

export enum ChangeRequestStatus {
  CREATED = 'CREATED',
  RESOLVED = 'RESOLVED',
  UPDATED = 'UPDATED',
  VIEWED = 'VIEWED',
}

export type CheckboxField = {
  __typename?: 'CheckboxField';
  defaultValue: Scalars['Boolean'];
};

export type ClearGpSyncFailedEventsInput = {
  companyId: Scalars['ID'];
  from?: InputMaybe<Scalars['DateTime']>;
  platformID: Scalars['ID'];
  to?: InputMaybe<Scalars['DateTime']>;
};

export type ClientDetail = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CompaniesCountRequest = {
  active?: InputMaybe<Scalars['Boolean']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  pending?: InputMaybe<Scalars['Boolean']>;
};

export type CompaniesCountResult = {
  __typename?: 'CompaniesCountResult';
  active?: Maybe<Scalars['Int']>;
  inactive?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
};

export type CompaniesResult = {
  __typename?: 'CompaniesResult';
  countResult?: Maybe<CompaniesCountResult>;
  data?: Maybe<Array<Maybe<Company>>>;
  pageResult?: Maybe<PageResult>;
};

export type Company = {
  __typename?: 'Company';
  accountManagerUser?: Maybe<OperationsUser>;
  approvers?: Maybe<Array<Maybe<Approver>>>;
  billingContact?: Maybe<CompanyUser>;
  channel?: Maybe<CompanyChannel>;
  companyLogo?: Maybe<DocumentReadable>;
  companyLogoId?: Maybe<Scalars['ID']>;
  /** @deprecated Use companyPayrollCycles instead */
  companyPayrollCycle?: Maybe<Array<CompanyPayrollCycle>>;
  companyPayrollCycles?: Maybe<Array<CompanyPayrollCycle>>;
  companyUsers?: Maybe<Array<CompanyUser>>;
  /**
   * # If includeAnyStatus = true => result will contain contracts with any statuses (ONBOARDING, ACTIVE, OFFBOARDING, ENDED, or DELETED)
   * # If includeAnyStatus = false => result will filter-out/skip any "ENDED" or "DELETED" contracts.
   */
  contracts?: Maybe<Array<Maybe<Contract>>>;
  /**
   * Similar to `contracts`, but will be dedicated for Team View page (as part of HRIS).<br>
   * The main difference is around Reporting Managers now being able to see Team View page (only their direct/indirect subordinates).<br>
   * Super Admin (or similar role) keeps seeing all contracts. Abac logic and most stuff are also copied over.<br>
   * Details: https://app.clickup.com/t/86cvd78qw | https://multiplier-group.slack.com/archives/C02B2S7A83T/p1715925057440889?thread_ts=1715854492.186609&cid=C02B2S7A83T<br>
   */
  contractsForTeamView?: Maybe<Array<Maybe<Contract>>>;
  createdOn?: Maybe<Scalars['DateTime']>;
  csmUser?: Maybe<OperationsUser>;
  currentMonthPayrollCutoffDate?: Maybe<Scalars['Date']>;
  customerIntegrations?: Maybe<Array<Maybe<CustomerIntegration>>>;
  displayName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  equipments?: Maybe<Array<Equipment>>;
  expenseReport?: Maybe<DocumentReadable>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  financialYear?: Maybe<Scalars['Int']>;
  fxSensitivity?: Maybe<FxSensitivity>;
  id?: Maybe<Scalars['ID']>;
  inactiveReason?: Maybe<CompanyInactiveReason>;
  isTest?: Maybe<Scalars['Boolean']>;
  managementFees?: Maybe<Array<Maybe<ManagementFee>>>;
  managers?: Maybe<Array<Maybe<CompanyUser>>>;
  memberPayableCompanyInvoices?: Maybe<
    Array<Maybe<MemberPayableCompanyInvoice>>
  >;
  memberPayables?: Maybe<Array<Maybe<MemberPayable>>>;
  msa?: Maybe<DocumentReadable>;
  /** @deprecated Should use msaAddendums */
  msaAddendum?: Maybe<MsaAddendum>;
  msaAddendums?: Maybe<Array<Maybe<MsaAddendum>>>;
  name?: Maybe<Scalars['String']>;
  nextPayrollCutoffDate?: Maybe<Scalars['Date']>;
  offerings?: Maybe<Array<Maybe<CompanyOffering>>>;
  onboarding?: Maybe<CompanyOnboarding>;
  otherEntities?: Maybe<Array<Maybe<LegalEntity>>>;
  payInSettings?: Maybe<CompanyPayInSettings>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payables?: Maybe<Array<Maybe<CompanyPayable>>>;
  paymentBundles?: Maybe<Array<Maybe<PaymentBundle>>>;
  payroll?: Maybe<Array<Maybe<CompanyPayroll>>>;
  /** @deprecated Should use currentMonthPayrollCutoffDate or nextPayrollCutoffDate */
  payrollCutOffDate?: Maybe<Scalars['Int']>;
  performanceReviews?: Maybe<Array<Maybe<PerformanceReview>>>;
  pricing?: Maybe<Pricing>;
  primaryEntity?: Maybe<LegalEntity>;
  requestsCreated?: Maybe<Array<Maybe<Request>>>;
  salaryReviews?: Maybe<Array<Maybe<SalaryReview>>>;
  salesUser?: Maybe<OperationsUser>;
  sfdcAccountNo?: Maybe<Scalars['String']>;
  signatories?: Maybe<Array<Maybe<CompanyUser>>>;
  ssoPreference?: Maybe<Array<Maybe<SsoPreference>>>;
  status?: Maybe<CompanyStatus>;
  statutoryReports?: Maybe<Array<CustomPayrollReportDetails>>;
  templates?: Maybe<Array<Maybe<Template>>>;
  timeOffBalanceReport?: Maybe<DocumentReadable>;
  timeOffPolicies?: Maybe<Array<Maybe<TimeOffTypeDefinition>>>;
  timeOffReport?: Maybe<DocumentReadable>;
  timeOffRequirements?: Maybe<TimeOffRequirements>;
  timeOffTypeInfos?: Maybe<Array<Maybe<TimeOffTypeInfo>>>;
  timeOffs?: Maybe<Array<Maybe<TimeOff>>>;
  timesheetSummaries?: Maybe<Array<ContractTimesheetSummary>>;
  timesheets?: Maybe<Array<Maybe<Timesheet>>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type Company_ApproversArgs = {
  category?: InputMaybe<ApprovalCategory>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Company_CompanyPayrollCycleArgs = {
  filter: CompanyPayrollCycleFilter;
};

export type Company_CompanyPayrollCyclesArgs = {
  filter: CompanyPayrollCycleFilter;
};

export type Company_CompanyUsersArgs = {
  statuses?: InputMaybe<Array<CompanyUserStatus>>;
};

export type Company_ContractsArgs = {
  category?: InputMaybe<ApprovalCategory>;
  companyContractsFilter?: InputMaybe<CompanyContractsFilter>;
  contractId?: InputMaybe<Scalars['ID']>;
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ContractStatus>;
};

export type Company_ContractsForTeamViewArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ContractStatus>;
};

export type Company_ExpenseReportArgs = {
  input: ExpenseReportInput;
};

export type Company_ExpensesArgs = {
  expenseId?: InputMaybe<Scalars['ID']>;
};

export type Company_ManagementFeesArgs = {
  contractFilters?: InputMaybe<ContractFilters>;
  monthYear?: InputMaybe<MonthYearInput>;
};

export type Company_ManagersArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type Company_MemberPayableCompanyInvoicesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  reference?: InputMaybe<Scalars['String']>;
};

export type Company_MemberPayablesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<MemberPayableStatus>>;
  types?: InputMaybe<Array<MemberPayableType>>;
};

export type Company_MsaAddendumsArgs = {
  filters: MsaAddendumFilters;
};

export type Company_OtherEntitiesArgs = {
  legalEntityId?: InputMaybe<Scalars['ID']>;
};

export type Company_PaySupplementsArgs = {
  paySupplementId?: InputMaybe<Scalars['ID']>;
};

export type Company_PayablesArgs = {
  filters?: InputMaybe<CompanyPayableFilters>;
  month?: InputMaybe<MonthYearInput>;
  payableId?: InputMaybe<Scalars['ID']>;
  payableQueryMode?: InputMaybe<PayableQueryMode>;
};

export type Company_PaymentBundlesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  statuses: Array<PaymentBundleStatus>;
};

export type Company_PayrollArgs = {
  payrollMonth?: InputMaybe<MonthYearInput>;
};

export type Company_PerformanceReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PerformanceReviewStatus>;
};

export type Company_RequestsCreatedArgs = {
  status?: InputMaybe<RequestStatus>;
};

export type Company_SalaryReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<SalaryReviewStatus>;
};

export type Company_StatutoryReportsArgs = {
  filter: CustomPayrollReportFilter;
};

export type Company_TemplatesArgs = {
  filter: TemplateFilter;
};

export type Company_TimeOffPoliciesArgs = {
  filter?: InputMaybe<CompanyTimeOffPolicyFilter>;
};

export type Company_TimeOffReportArgs = {
  input: TimeOffReportInput;
};

export type Company_TimeOffRequirementsArgs = {
  filters?: InputMaybe<TimeOffRequirementFilters>;
};

export type Company_TimeOffTypeInfosArgs = {
  filter?: InputMaybe<TimeOffTypeFilter>;
};

export type Company_TimeOffsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Company_TimesheetSummariesArgs = {
  filters?: InputMaybe<TimesheetSummaryFilter>;
};

export type Company_TimesheetsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  timesheetFilters?: InputMaybe<TimesheetFilters>;
};

export enum CompanyAssignTypeFilter {
  ALL = 'ALL',
  ME = 'ME',
  UNASSIGNED = 'UNASSIGNED',
}

export type CompanyBankAccount = {
  __typename?: 'CompanyBankAccount';
  id: Scalars['ID'];
};

export type CompanyBankOverrideInfo = {
  accountNumber: Scalars['String'];
  accountType?: InputMaybe<AccountType>;
  bank: Scalars['String'];
  companyName: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type CompanyBinding = {
  __typename?: 'CompanyBinding';
  company?: Maybe<Company>;
  /** @deprecated use customerId instead, when Netsuite is fully implemented this will be removed. */
  contactId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<ExternalSystemType>;
  id: Scalars['ID'];
};

export type CompanyBindingInput = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export enum CompanyChannel {
  ORGANIC = 'ORGANIC',
  SALES = 'SALES',
}

export type CompanyChargebackResult = {
  __typename?: 'CompanyChargebackResult';
  chargebackDays: Scalars['Int'];
  companyId: Scalars['ID'];
};

export type CompanyContractsFilter = {
  isApprovalRelated?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyCountryInvoiceSourceReport = {
  __typename?: 'CompanyCountryInvoiceSourceReport';
  companyCountryBillingTotal?: Maybe<Scalars['Float']>;
  country?: Maybe<CountryCode>;
  items?: Maybe<Array<Maybe<CompanyInvoiceSourceItem>>>;
};

export type CompanyEmployeePricingInput = {
  country?: InputMaybe<CountryCode>;
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
  region?: InputMaybe<Region>;
};

export type CompanyFilters = {
  assignType?: InputMaybe<CompanyAssignTypeFilter>;
  associatedEmailDomain?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyStatuses?: InputMaybe<Array<InputMaybe<CompanyStatus>>>;
  country?: InputMaybe<CountryCode>;
  createdDateRange?: InputMaybe<DateRange>;
  csmUserId?: InputMaybe<Scalars['ID']>;
  salesUserId?: InputMaybe<Scalars['ID']>;
};

export type CompanyGlCode = {
  __typename?: 'CompanyGLCode';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyGlobalPricingInput = {
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
};

export type CompanyGroup = {
  __typename?: 'CompanyGroup';
  children?: Maybe<Array<Maybe<Company>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type CompanyGroupAddCompanyInput = {
  children?: InputMaybe<Array<CompanyGroupNodeInput>>;
  id?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<CompanyGroupAddUserInput>>;
};

export type CompanyGroupAddRemoveCompanyInput = {
  children?: InputMaybe<Array<CompanyGroupNodeInput>>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanyGroupAddRemoveUserInput = {
  id?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<CompanyGroupUserInput>>;
};

export type CompanyGroupAddUserInput = {
  roles?: InputMaybe<Array<InputMaybe<CompanyGroupRole>>>;
  userId: Scalars['ID'];
};

export type CompanyGroupCreateInput = {
  children?: InputMaybe<Array<CompanyGroupNodeInput>>;
  name: Scalars['String'];
  userList?: InputMaybe<Array<CompanyGroupUserInput>>;
  users?: InputMaybe<Array<CompanyGroupAddUserInput>>;
};

export type CompanyGroupNodeInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum CompanyGroupRole {
  COMPANY_GROUP_PRIMARY_ADMIN = 'COMPANY_GROUP_PRIMARY_ADMIN',
}

export type CompanyGroupUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CompanyGroupUserInput = {
  userId: Scalars['ID'];
};

export type CompanyIdToForcedContractIdsInput = {
  companyId: Scalars['ID'];
  forcedContractIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum CompanyInactiveReason {
  CHURN = 'CHURN',
  DISQUALIFIED = 'DISQUALIFIED',
  DUPLICATE = 'DUPLICATE',
  INVALID = 'INVALID',
  LOST = 'LOST',
  NON_RESPONSIVE = 'NON_RESPONSIVE',
  PASSIVE = 'PASSIVE',
}

export type CompanyIntegration = {
  __typename?: 'CompanyIntegration';
  companyId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lastSyncCreatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncId?: Maybe<Scalars['ID']>;
  platformID?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['ID']>;
  syncInProgress?: Maybe<Scalars['Boolean']>;
};

export type CompanyIntegrationInfo = {
  __typename?: 'CompanyIntegrationInfo';
  companyId?: Maybe<Scalars['ID']>;
  incomingImportInProgress?: Maybe<Scalars['Boolean']>;
  incomingSyncEnabled?: Maybe<Scalars['Boolean']>;
  incomingSyncInProgress?: Maybe<Scalars['Boolean']>;
  lastIncomingSyncId?: Maybe<Scalars['String']>;
  lastIncomingSyncStartTime?: Maybe<Scalars['DateTime']>;
  lastOutgoingSyncId?: Maybe<Scalars['String']>;
  lastOutgoingSyncStartTime?: Maybe<Scalars['DateTime']>;
  outgoingSyncEnabled?: Maybe<Scalars['Boolean']>;
  outgoingSyncInProgress?: Maybe<Scalars['Boolean']>;
  platformID?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['ID']>;
};

export type CompanyInvoiceSourceItem = {
  __typename?: 'CompanyInvoiceSourceItem';
  additional?: Maybe<Array<Maybe<CompanyPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<CompanyPayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<CompanyPayComponent>>>;
  firstInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  firstInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  secondInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  secondInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBillingAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type CompanyInvoiceSourceRecord = {
  __typename?: 'CompanyInvoiceSourceRecord';
  additional?: Maybe<Array<Maybe<CompanyPayableComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<CompanyPayableComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<CompanyPayableComponent>>>;
  firstInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  firstInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  secondInvoiceGrossSalary?: Maybe<Scalars['Float']>;
  secondInvoiceUnitPrice?: Maybe<Scalars['Float']>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBillingAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type CompanyInvoiceSourceReportInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /**
   * which invoice the data should be sourced from
   * 1 = First Invoice
   * 2 = Second Invoice
   */
  invoiceType?: InputMaybe<Scalars['Int']>;
  monthYearCycle?: InputMaybe<MonthYearCycleInput>;
};

export type CompanyInvoiceSourceReportResult = {
  __typename?: 'CompanyInvoiceSourceReportResult';
  company?: Maybe<Company>;
  companyTotalBillingAmount?: Maybe<Scalars['Float']>;
  invoiceSourceReport?: Maybe<Array<Maybe<CompanyCountryInvoiceSourceReport>>>;
};

export type CompanyMemberPay = MemberPay & {
  __typename?: 'CompanyMemberPay';
  additional?: Maybe<Array<Maybe<AdditionalPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountGrossCompanyCurrency?: Maybe<Scalars['Float']>;
  amountNet?: Maybe<Scalars['Float']>;
  amountNetCompanyCurrency?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  amountTotalCostCompanyCurrency?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  clientDeductions?: Maybe<Array<Maybe<PayComponent>>>;
  companyPayroll?: Maybe<CompanyPayroll>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<PayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<PayComponent>>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payrollCycle?: Maybe<PayrollCycle>;
  payslip?: Maybe<Payslip>;
  status?: Maybe<MemberPayStatus>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type CompanyMemberPayResult = {
  __typename?: 'CompanyMemberPayResult';
  companyCount?: Maybe<Scalars['Int']>;
  /** countryCount is the total number of payroll country in the whole result */
  countryCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<CompanyMemberPay>>>;
  employeeCount?: Maybe<Scalars['Int']>;
  pageResult?: Maybe<PageResult>;
};

export type CompanyOffering = {
  __typename?: 'CompanyOffering';
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  companyId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  offering?: Maybe<OfferingCode>;
  pricing?: Maybe<CompanyOfferingPricing>;
  status?: Maybe<OfferingStatus>;
};

export type CompanyOfferingFeeComponent = {
  __typename?: 'CompanyOfferingFeeComponent';
  companyOfferingFeeComponentTier?: Maybe<CompanyOfferingFeeComponentTier>;
  countryCode?: Maybe<CountryCode>;
  currencyCode?: Maybe<CurrencyCode>;
  discount?: Maybe<Discount>;
  fee: Scalars['Float'];
  feeComponentDefinitionId?: Maybe<Scalars['ID']>;
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id: Scalars['ID'];
  key: Scalars['String'];
  offeringCode: OfferingCode;
  variableBaseUnit?: Maybe<VariableBaseUnit>;
};

export type CompanyOfferingFeeComponentInput = {
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<CurrencyCode>;
  discount?: InputMaybe<DiscountInput>;
  fee: Scalars['Float'];
  feeComponentDefinitionId?: InputMaybe<Scalars['ID']>;
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  offeringCode: OfferingCode;
  variableBaseUnit?: InputMaybe<VariableBaseUnit>;
};

export type CompanyOfferingFeeComponentTier = {
  __typename?: 'CompanyOfferingFeeComponentTier';
  feeComponentTierDefinitionId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  offeringCode: OfferingCode;
  tierLowerBound?: Maybe<Scalars['Int']>;
  tierUpperBound?: Maybe<Scalars['Int']>;
};

export type CompanyOfferingFeeComponentTierInput = {
  companyOfferingFeeComponents: Array<CompanyOfferingFeeComponentInput>;
  feeComponentTierDefinitionId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  offeringCode: OfferingCode;
  tierLowerBound?: InputMaybe<Scalars['Int']>;
  tierUpperBound?: InputMaybe<Scalars['Int']>;
};

export type CompanyOfferingInput = {
  capabilities?: InputMaybe<Array<InputMaybe<Capability>>>;
  offering?: InputMaybe<OfferingCode>;
};

export type CompanyOfferingPricing = {
  __typename?: 'CompanyOfferingPricing';
  companyId: Scalars['ID'];
  companyOfferingFeeComponentTiers: Array<CompanyOfferingFeeComponentTier>;
  companyOfferingFeeComponents: Array<CompanyOfferingFeeComponent>;
  companyOfferingId: Scalars['ID'];
  discount?: Maybe<Discount>;
  id: Scalars['ID'];
  offeringCode: OfferingCode;
  pricingPlans: Array<PricingPlan>;
  status?: Maybe<CompanyOfferingPricingStatus>;
};

export type CompanyOfferingPricingFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  offeringCode?: InputMaybe<Array<OfferingCode>>;
};

export type CompanyOfferingPricingInput = {
  companyOfferingFeeComponentTiers: Array<CompanyOfferingFeeComponentTierInput>;
  companyOfferingId: Scalars['ID'];
  discount?: InputMaybe<DiscountInput>;
};

export enum CompanyOfferingPricingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CompanyOfferingsAddInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<Array<InputMaybe<CompanyOfferingInput>>>;
};

export type CompanyOfferingsUpdateInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<Array<InputMaybe<CompanyOfferingInput>>>;
};

export type CompanyOnboarding = {
  __typename?: 'CompanyOnboarding';
  hasDataChanged?: Maybe<Scalars['Boolean']>;
  msaGenerationRequirement?: Maybe<Array<Maybe<Scalars['String']>>>;
  msaWorkflow?: Maybe<MsaWorkflow>;
};

export type CompanyPayComponent = {
  __typename?: 'CompanyPayComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<CompanyPayComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum CompanyPayComponentType {
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  DEDUCTIONS = 'DEDUCTIONS',
  OTHER = 'OTHER',
}

export type CompanyPayInMethod = {
  bankDetails?: Maybe<Array<Maybe<CompanyPayInMethodBankDetails>>>;
  company?: Maybe<Company>;
  context?: Maybe<PayInMethodContext>;
  currentStatus?: Maybe<CompanyPayInMethodWorkflowStatus>;
  currentStep?: Maybe<PayInMethodDefinitionStep>;
  data?: Maybe<Array<Maybe<KeyValue>>>;
  documents?: Maybe<Array<Maybe<DocumentData>>>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  payInMethodType?: Maybe<PayInMethodType>;
  workflowSteps: Array<Maybe<CompanyPayInMethodWorkflowStep>>;
};

export type CompanyPayInMethodBankDetails = {
  __typename?: 'CompanyPayInMethodBankDetails';
  data?: Maybe<Array<Maybe<KeyValue>>>;
  documents?: Maybe<Array<Maybe<DocumentData>>>;
  payInAccountRequirementType?: Maybe<Scalars['String']>;
};

export type CompanyPayInMethodInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  payInMethodType?: InputMaybe<PayInMethodType>;
  setUpData?: InputMaybe<CompanyPayInMethodSetUpData>;
};

export type CompanyPayInMethodSetUpData = {
  accountRequirements?: InputMaybe<Array<InputMaybe<AccountRequirementInput>>>;
  step?: InputMaybe<PayInMethodDefinitionStep>;
};

export enum CompanyPayInMethodWorkflowStatus {
  /** @deprecated will use ACTIVATION PENDING to represent all the statuses till its activated */
  ACTIVATION_INITIATED = 'ACTIVATION_INITIATED',
  ACTIVATION_IN_PROGRESS = 'ACTIVATION_IN_PROGRESS',
  ACTIVATION_PENDING = 'ACTIVATION_PENDING',
  AGREEMENT_SENT_TO_SIGNATORY = 'AGREEMENT_SENT_TO_SIGNATORY',
  AGREEMENT_SIGNED = 'AGREEMENT_SIGNED',
  AGREEMENT_SIGNING_IN_PROGRESS = 'AGREEMENT_SIGNING_IN_PROGRESS',
  BANK_DETAILS_COLLECTED = 'BANK_DETAILS_COLLECTED',
  DELETED = 'DELETED',
  ENABLED = 'ENABLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  MANDATE_REGISTERED = 'MANDATE_REGISTERED',
  MANDATE_SUBMISSION_IN_PROGRESS = 'MANDATE_SUBMISSION_IN_PROGRESS',
  SETUP_INITIATED = 'SETUP_INITIATED',
}

export type CompanyPayInMethodWorkflowStep = PayInMethodWorkflowStep & {
  __typename?: 'CompanyPayInMethodWorkflowStep';
  /** @deprecated will not be populated as it is unnecessary */
  data: Array<Maybe<AccountDetails>>;
  status?: Maybe<CompanyPayInMethodWorkflowStatus>;
  step?: Maybe<PayInMethodDefinitionStep>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CompanyPayInPreference = {
  company: Company;
  id: Scalars['ID'];
  isPreferenceActive: Scalars['Boolean'];
  preferenceType: CompanyPayInPreferenceType;
};

export type CompanyPayInPreferenceAutoDebit = CompanyPayInPreference & {
  __typename?: 'CompanyPayInPreferenceAutoDebit';
  company: Company;
  defaultAutoDebitPayInMethod?: Maybe<CompanyPayInMethod>;
  id: Scalars['ID'];
  isAutoDebitEnabled: Scalars['Boolean'];
  isPreferenceActive: Scalars['Boolean'];
  preferenceType: CompanyPayInPreferenceType;
};

export enum CompanyPayInPreferenceType {
  AUTO_DEBIT = 'AUTO_DEBIT',
}

export type CompanyPayInSettings = {
  __typename?: 'CompanyPayInSettings';
  company?: Maybe<Company>;
  payInMethodRequirements?: Maybe<Array<Maybe<PayInMethodRequirement>>>;
  payInMethods: Array<Maybe<CompanyPayInMethod>>;
  payInPreferences?: Maybe<Array<Maybe<CompanyPayInPreference>>>;
};

export type CompanyPayInSettings_PayInMethodsArgs = {
  filters?: InputMaybe<PayInMethodFilters>;
};

export type CompanyPayable = {
  __typename?: 'CompanyPayable';
  company?: Maybe<Company>;
  createdOn?: Maybe<Scalars['DateTime']>;
  creditNote?: Maybe<CreditNote>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  items?: Maybe<Array<Maybe<PayableItem>>>;
  memberPayableCompanyInvoice?: Maybe<MemberPayableCompanyInvoice>;
  month?: Maybe<Scalars['Int']>;
  report?: Maybe<CompanyPayableReport>;
  status?: Maybe<PayableStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<CompanyPayableType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type CompanyPayableComponent = {
  __typename?: 'CompanyPayableComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<CompanyPayableComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum CompanyPayableComponentType {
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  DEDUCTIONS = 'DEDUCTIONS',
  OTHER = 'OTHER',
}

export type CompanyPayableFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdDateRange?: InputMaybe<DateRange>;
  invoiceNo?: InputMaybe<Scalars['String']>;
  monthYear?: InputMaybe<PayableMonthInput>;
  payableIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  payableType?: InputMaybe<CompanyPayableType>;
  queryMode?: InputMaybe<PayableQueryMode>;
  reference?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<PayableStatus>>>;
};

export type CompanyPayableInput = {
  autoSubmit?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Use companyIds instead */
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  forcedContractIds?: InputMaybe<Array<Scalars['ID']>>;
  invoiceDate?: InputMaybe<Scalars['DateTime']>;
  invoiceDueDate?: InputMaybe<Scalars['DateTime']>;
  invoiceMonth?: InputMaybe<PayableMonthInput>;
  invoiceReference?: InputMaybe<Scalars['String']>;
  invoiceType?: InputMaybe<Scalars['Int']>;
};

export type CompanyPayableInvoiceSourceReportExportInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /**
   * which invoice the data should be sourced from
   * 1 = First Invoice
   * 2 = Second Invoice
   */
  invoiceType?: InputMaybe<Scalars['Int']>;
  payableMonth?: InputMaybe<PayableMonthInput>;
  storeFile?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyPayableReport = {
  __typename?: 'CompanyPayableReport';
  companyId: Scalars['ID'];
  month: Scalars['Int'];
  type?: Maybe<CompanyPayableReportType>;
  year: Scalars['Int'];
};

export enum CompanyPayableReportType {
  GROSS_INVOICE_SOURCE_REPORT = 'GROSS_INVOICE_SOURCE_REPORT',
  SALARY_INVOICE_SOURCE_REPORT = 'SALARY_INVOICE_SOURCE_REPORT',
}

export type CompanyPayableSyncInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum CompanyPayableType {
  ANNUAL_PLAN = 'ANNUAL_PLAN',
  DEPOSIT = 'DEPOSIT',
  FIRST_INVOICE = 'FIRST_INVOICE',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL_FUNDING = 'GLOBAL_PAYROLL_FUNDING',
  INSURANCE = 'INSURANCE',
  SECOND_INVOICE = 'SECOND_INVOICE',
  UNKNOWN = 'UNKNOWN',
}

export type CompanyPayableWithErrors = {
  __typename?: 'CompanyPayableWithErrors';
  companyPayables?: Maybe<Array<Maybe<CompanyPayable>>>;
  errorResponses?: Maybe<Array<Maybe<TaskResponse>>>;
};

export type CompanyPayablesResult = {
  __typename?: 'CompanyPayablesResult';
  data?: Maybe<Array<Maybe<CompanyPayable>>>;
  pageResult?: Maybe<PageResult>;
};

export type CompanyPayroll = Payroll & {
  __typename?: 'CompanyPayroll';
  amountTotalCost?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  id?: Maybe<Scalars['ID']>;
  memberPays?: Maybe<Array<Maybe<CompanyMemberPay>>>;
  month?: Maybe<PayrollMonth>;
  status?: Maybe<CompanyPayrollStatus>;
};

export type CompanyPayrollConsolidatedReportDownloadRequest = {
  financialYears?: InputMaybe<Array<Scalars['Int']>>;
  isForced?: InputMaybe<Scalars['Boolean']>;
  payrollCycleEntityId?: InputMaybe<Scalars['ID']>;
  payrollType?: InputMaybe<PayrollCycleEntityType>;
  type: PayrollFileDownloadType;
};

export type CompanyPayrollCycle = {
  __typename?: 'CompanyPayrollCycle';
  company: Company;
  /** @deprecated No longer supported */
  companyId: Scalars['ID'];
  companyPayrollInput?: Maybe<CompanyPayrollInputResponse>;
  companyPayrollInputV2?: Maybe<CompanyPayrollInputContractResponse>;
  confirmByDate?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  cycleIds: Array<Scalars['ID']>;
  endDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<LegalEntity>;
  entityType: PayrollCycleEntityType;
  frequency?: Maybe<PayFrequency>;
  id: Scalars['ID'];
  payDate?: Maybe<Scalars['Date']>;
  payrollCutOffDate?: Maybe<Scalars['Date']>;
  payrollCycles?: Maybe<Array<PayrollCycle>>;
  payrollMonth?: Maybe<MonthYear>;
  startDate?: Maybe<Scalars['Date']>;
  status: CompanyPayrollCycleStatus;
  summary?: Maybe<CompanyPayrollCycleSummary>;
};

export type CompanyPayrollCycle_CompanyPayrollInputArgs = {
  filter?: InputMaybe<CompanyPayrollInputFilter>;
  pageRequest: PageRequest;
};

export type CompanyPayrollCycle_CompanyPayrollInputV2Args = {
  filter?: InputMaybe<CompanyPayrollInputContractFilter>;
  pageRequest: PageRequest;
};

export type CompanyPayrollCycleFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  endDate?: InputMaybe<Scalars['Date']>;
  frequencies?: InputMaybe<Array<PayFrequency>>;
  /** @deprecated Use query companyPayrollCycle with id instead */
  id?: InputMaybe<Scalars['ID']>;
  legalEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  payDate?: InputMaybe<DateRange>;
  /** @deprecated Use payrollCycleEntityTypes instead */
  payrollCycleEntityType?: InputMaybe<PayrollCycleEntityType>;
  payrollCycleEntityTypes?: InputMaybe<Array<PayrollCycleEntityType>>;
  payrollCycleTypes?: InputMaybe<Array<PayrollCycleType>>;
  payrollMonth?: InputMaybe<MonthYearInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<Array<CompanyPayrollCycleStatus>>;
};

export type CompanyPayrollCycleReportFilter = {
  companyPayrollCycleId: Scalars['ID'];
  reportType: CompanyPayrollReport;
};

export enum CompanyPayrollCycleStatus {
  COMPLETED = 'COMPLETED',
  CONFIRM_REPORT = 'CONFIRM_REPORT',
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  REPORT = 'REPORT',
  REVIEW_INPUT = 'REVIEW_INPUT',
}

export type CompanyPayrollCycleSummary = {
  __typename?: 'CompanyPayrollCycleSummary';
  countryTotalCounts?: Maybe<Array<Maybe<CountryCount>>>;
  memberTotalCount?: Maybe<Scalars['Int']>;
};

export type CompanyPayrollCyclesResult = {
  __typename?: 'CompanyPayrollCyclesResult';
  data?: Maybe<Array<CompanyPayrollCycle>>;
  pageResult: PageResult;
};

export type CompanyPayrollFileDownloadRequest = {
  companyPayrollCycleId: Scalars['ID'];
  isForced?: InputMaybe<Scalars['Boolean']>;
  type: PayrollFileDownloadType;
};

export type CompanyPayrollFilter = {
  companyId: Scalars['ID'];
  companyPayrollCycleId: Scalars['ID'];
  country?: InputMaybe<CountryCode>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanyPayrollInputContractFilter = {
  anyBankDetailsChange?: InputMaybe<Scalars['Boolean']>;
  anyCompensationChange?: InputMaybe<Scalars['Boolean']>;
  anyVariance?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<CountryCode>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
};

export type CompanyPayrollInputContractResponse = {
  __typename?: 'CompanyPayrollInputContractResponse';
  data?: Maybe<Array<PayrollInputContract>>;
  pageResult: PageResult;
};

export type CompanyPayrollInputEntry = {
  __typename?: 'CompanyPayrollInputEntry';
  allowance: Scalars['Float'];
  baseSalary: Scalars['Float'];
  companyCurrency: CurrencyCode;
  contract: Contract;
  /** @deprecated No longer supported */
  contractId: Scalars['ID'];
  contractPayrollLabels: Array<ContractPayrollLabel>;
  contractStatus: ContractStatus;
  country: CountryCode;
  expenses: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  memberCurrency: CurrencyCode;
  memberName: Scalars['String'];
  memberToCompanyCurrencyExchangeRate: Scalars['Float'];
  payFrequency: PayFrequency;
  paySuplements: Scalars['Float'];
  payrollContractTypes?: Maybe<Array<PayrollContractType>>;
  rateFrequency: RateFrequency;
  timeoff: Scalars['Float'];
  workDays: Scalars['Float'];
};

export type CompanyPayrollInputFilter = {
  anyBankDetailsChange?: InputMaybe<Scalars['Boolean']>;
  anyCompensationChange?: InputMaybe<Scalars['Boolean']>;
  anyVariance?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<CountryCode>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
};

export type CompanyPayrollInputReportFilter = {
  companyId: Scalars['ID'];
  companyPayrollCycleId: Scalars['ID'];
  companyPayrollInputFilter?: InputMaybe<CompanyPayrollInputFilter>;
};

export type CompanyPayrollInputResponse = {
  __typename?: 'CompanyPayrollInputResponse';
  data: Array<CompanyPayrollInputEntry>;
  pageResult: PageResult;
};

export enum CompanyPayrollReport {
  ALL_PAYROLL_REPORTS = 'ALL_PAYROLL_REPORTS',
  ALL_PAYSLIPS = 'ALL_PAYSLIPS',
  BANK_SUMMARY = 'BANK_SUMMARY',
  EMPLOYEE_SUMMARY = 'EMPLOYEE_SUMMARY',
  EMPLOYER_SUMMARY = 'EMPLOYER_SUMMARY',
  GENERAL_LEDGER = 'GENERAL_LEDGER',
  GROSS_TO_NET = 'GROSS_TO_NET',
  PAYMENT_INSTRUCTION_FILE = 'PAYMENT_INSTRUCTION_FILE',
  PAYROLL_INPUT = 'PAYROLL_INPUT',
  TOTAL_PAYROLL_COST = 'TOTAL_PAYROLL_COST',
  VARIANCE_INPUT = 'VARIANCE_INPUT',
  VARIANCE_OUTPUT = 'VARIANCE_OUTPUT',
}

export type CompanyPayrollReportExportInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  compare?: InputMaybe<ReportComparisonInput>;
  contractId?: InputMaybe<Scalars['ID']>;
  countryCode?: InputMaybe<CountryCode>;
  frequency?: InputMaybe<PayFrequency>;
  memberNameSearchTerm?: InputMaybe<Scalars['String']>;
  payDateRange?: InputMaybe<DateRange>;
  payPeriodDateRange?: InputMaybe<DateRange>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  payrollMonth?: InputMaybe<PayrollMonthInput>;
  payrollMonths?: InputMaybe<PayrollMonthRangeInput>;
  reportType?: InputMaybe<ExportPayrollReportType>;
};

export type CompanyPayrollReportInput = {
  filters: CompanyPayrollFilter;
  reportType: ExportPayrollReportType;
};

export enum CompanyPayrollStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type CompanyPreFillData = {
  __typename?: 'CompanyPreFillData';
  domain?: Maybe<Scalars['String']>;
  logo?: Maybe<DocumentReadable>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyPreFillDataInput = {
  domain: Scalars['String'];
};

export type CompanyPricingPlanMigrateInput = {
  companyId: Scalars['ID'];
  paymentTerm: PaymentTermInput;
};

export type CompanyPricingPlanMigrateResponse = {
  __typename?: 'CompanyPricingPlanMigrateResponse';
  companyId: Scalars['ID'];
  successful: Scalars['Boolean'];
};

export type CompanyQuotation = {
  __typename?: 'CompanyQuotation';
  billingCurrency?: Maybe<CurrencyCode>;
  companyCountry?: Maybe<CountryCode>;
  companyName?: Maybe<Scalars['String']>;
  costOverview?: Maybe<QuotationCostOverview>;
  countryWiseBreakdowns?: Maybe<
    Array<Maybe<QuotationCountryWiseCostBreakdown>>
  >;
  countryWiseSummaries?: Maybe<Array<Maybe<QuotationCountryWiseCostSummary>>>;
  createdBy?: Maybe<OperationsUser>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  includeDepositAmount?: Maybe<Scalars['Boolean']>;
  includeManagementFee?: Maybe<Scalars['Boolean']>;
  memberInputs?: Maybe<Array<Maybe<QuotationMemberInputData>>>;
  memberWiseSummaries?: Maybe<Array<Maybe<QuotationMemberWiseSummary>>>;
};

export type CompanyQuotationFilter = {
  companyName?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  createdOn?: InputMaybe<DateRange>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanyQuotationGenerationInput = {
  billingCurrency: CurrencyCode;
  companyCountry?: InputMaybe<CountryCode>;
  companyName: Scalars['String'];
  includeDepositAmount: Scalars['Boolean'];
  includeManagementFee: Scalars['Boolean'];
  memberInputs: Array<QuotationMemberInput>;
};

export type CompanyQuotationsResponse = {
  __typename?: 'CompanyQuotationsResponse';
  data?: Maybe<Array<Maybe<CompanyQuotation>>>;
  pageResult?: Maybe<PageResult>;
};

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
  INVITE_ACCEPTED = 'INVITE_ACCEPTED',
  UNVERIFIED = 'UNVERIFIED',
}

export type CompanySync = {
  __typename?: 'CompanySync';
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<SyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanySyncStatus = {
  __typename?: 'CompanySyncStatus';
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<SyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyTimeOffPolicyFilter = {
  entityIds?: InputMaybe<Array<Scalars['ID']>>;
  timeOffPolicyIds?: InputMaybe<Array<Scalars['ID']>>;
  timeOffTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanyUpdateBasicDetailsInput = {
  companyId: Scalars['ID'];
  currency: CurrencyCode;
  displayName: Scalars['String'];
  legalName: Scalars['String'];
  registrationNo: Scalars['String'];
  sfdcAccountNo?: InputMaybe<Scalars['String']>;
};

export type CompanyUpdateContactDetailsInput = {
  address: AddressInput;
  companyId: Scalars['ID'];
  phoneNo: Scalars['String'];
};

export type CompanyUpdateInput = {
  address?: InputMaybe<AddressInput>;
  companyId?: InputMaybe<Scalars['ID']>;
  displayName?: InputMaybe<Scalars['String']>;
  financialYear?: InputMaybe<Scalars['Int']>;
  fxSensitivity?: InputMaybe<FxSensitivity>;
  phoneNo?: InputMaybe<Scalars['String']>;
  sfdcAccountNo?: InputMaybe<Scalars['String']>;
};

export type CompanyUser = Person & {
  __typename?: 'CompanyUser';
  approver?: Maybe<Approver>;
  capabilities?: Maybe<Array<Maybe<CompanyUserCapability>>>;
  company?: Maybe<Company>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  hasLogin?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isBillingContact?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use 'capabilities' instead. Will be removed by 1st of December 2021 */
  isSignatory?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  /** @deprecated Use 'roles' instead. Now compnay user can have multiple roles: Manager, Admin, etc. */
  role?: Maybe<CompanyUserRole>;
  roles?: Maybe<Array<Maybe<CompanyUserRole>>>;
  rolesWithAttributes?: Maybe<Array<Maybe<CompanyUserRoleWithAttributes>>>;
  status?: Maybe<CompanyUserStatus>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CompanyUserAssignRoleAndAttributesInput = {
  companyUserId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  legalEntityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  role: CompanyUserRole;
};

export type CompanyUserAssignRoleInput = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeInput>>>;
  companyUserIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  /** @deprecated use attributes instead */
  legalEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  role: CompanyUserRole;
};

export enum CompanyUserCapability {
  BILLING_CONTACT = 'BILLING_CONTACT',
  SIGNATORY = 'SIGNATORY',
}

export type CompanyUserEditRoleAndAttributesInput = {
  companyUserId?: InputMaybe<Scalars['ID']>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  legalEntityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  role?: InputMaybe<CompanyUserRole>;
};

export enum CompanyUserRole {
  ADMIN = 'ADMIN',
  BILLING_ADMIN = 'BILLING_ADMIN',
  HR_ADMIN = 'HR_ADMIN',
  MANAGER = 'MANAGER',
  PAYROLL_ADMIN = 'PAYROLL_ADMIN',
  PRIMARY_ADMIN = 'PRIMARY_ADMIN',
}

export type CompanyUserRoleWithAttributes = {
  __typename?: 'CompanyUserRoleWithAttributes';
  departmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  legalEntityIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  role?: Maybe<CompanyUserRole>;
};

export enum CompanyUserStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  INVITED = 'INVITED',
}

export type CompanyVisaGlobalPricingInput = {
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
};

export type CompanyVisaPricingInput = {
  country?: InputMaybe<CountryCode>;
  employeeType: ContractType;
  fixedRate: Scalars['Float'];
  validUntil?: InputMaybe<Scalars['Date']>;
};

export type Compensation = {
  __typename?: 'Compensation';
  additional?: Maybe<Scalars['String']>;
  additionalPays?: Maybe<Array<Maybe<CompensationPayComponent>>>;
  basePay?: Maybe<FixedPayComponent>;
  deductions?: Maybe<Array<Maybe<Deduction>>>;
  grant?: Maybe<Array<Maybe<Grant>>>;
  /** @deprecated No longer supported */
  other?: Maybe<Array<Maybe<FixedPayComponent>>>;
  payType?: Maybe<PayType>;
  payrollStart?: Maybe<Scalars['DateTime']>;
  postProbationBasePay?: Maybe<FixedPayComponent>;
  probationBasePay?: Maybe<FixedPayComponent>;
};

export type Compensation_GrantArgs = {
  contractId: Scalars['ID'];
};

export type CompensationComponent = {
  __typename?: 'CompensationComponent';
  category: Scalars['String'];
  country: CountryCode;
  entityId: Scalars['ID'];
  id: Scalars['UUID'];
  isCash: Scalars['Boolean'];
  isFixed: Scalars['Boolean'];
  isProrated: Scalars['Boolean'];
  name: Scalars['String'];
  taxable: Scalars['Boolean'];
  validForTaxExemption: Scalars['Boolean'];
};

export type CompensationInsightDefinition = InsightDefinition & {
  __typename?: 'CompensationInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  compensationStructureDefinition?: Maybe<CompensationPayComponentDefinition>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type CompensationPayComponent = {
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<PayAmountType>;
  condition?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  frequency?: Maybe<RateFrequency>;
  id?: Maybe<Scalars['ID']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  paymentFrequency?: Maybe<PayFrequency>;
  paymentFrequencyDate?: Maybe<Array<Maybe<PayFrequencyDate>>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CompensationPayComponentDefinition = {
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<RateFrequency>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  rateType?: Maybe<RateType>;
};

/**
 * Except `id`, all are optional.
 * E.g. 1: to update label and amount: {id: 123, label: "New Label", amount: 5000}
 * E.g. 2: to update paymentFrequency and isActive: {id: 123, paymentFrequency: "SEMIMONTHLY", isActive: true}
 * E.g. 3: a useless request that does not update anything: {id: 123}
 */
export type CompensationRecordUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  amountType?: InputMaybe<PayAmountType>;
  condition?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  frequency?: InputMaybe<RateFrequency>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<PayFrequencyDateInput>>;
  rateType?: InputMaybe<RateType>;
  status?: InputMaybe<CompensationStatus>;
  validFromInclusive?: InputMaybe<Scalars['Date']>;
  validToExclusive?: InputMaybe<Scalars['Date']>;
};

export type CompensationSchema = {
  __typename?: 'CompensationSchema';
  components: Array<CompensationComponent>;
};

export type CompensationSnapshot = {
  __typename?: 'CompensationSnapshot';
  amount?: Maybe<Scalars['Float']>;
  compensationId: Scalars['ID'];
  currency?: Maybe<CurrencyCode>;
  rateType?: Maybe<RateType>;
  type?: Maybe<Scalars['String']>;
};

export enum CompensationStatus {
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  UNCHANGED = 'UNCHANGED',
}

export type CompensationStructureDefinition = {
  __typename?: 'CompensationStructureDefinition';
  additionalPays?: Maybe<Array<Maybe<CompensationPayComponentDefinition>>>;
  allowCustomFixedComponents?: Maybe<Scalars['Boolean']>;
  basePay?: Maybe<FixedPayComponentDefinition>;
  countryCode?: Maybe<CountryCode>;
  countryState?: Maybe<State>;
  deductions?: Maybe<Array<Maybe<DeductionDefinition>>>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  other?: Maybe<Array<Maybe<FixedPayComponentDefinition>>>;
  showAdditionalRequirementInput?: Maybe<Scalars['Boolean']>;
};

export type Compliance = {
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type Compliance_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceAssessmentDataInput = {
  data?: InputMaybe<Array<InputMaybe<ComplianceDataRequirementInput>>>;
  id: Scalars['ID'];
};

export type ComplianceAssessmentDocumentsInput = {
  documents?: InputMaybe<Array<InputMaybe<ComplianceDocumentRequirementInput>>>;
  id: Scalars['ID'];
  reports?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ComplianceContractor = Compliance & {
  __typename?: 'ComplianceContractor';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  orderForm?: Maybe<DocumentReadable>;
  orderFormTemplate?: Maybe<DocumentReadable>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type ComplianceContractor_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceDataInput = {
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
  value?: InputMaybe<Scalars['String']>;
};

export type ComplianceDataRequirementInput = {
  data: Array<InputMaybe<ComplianceDataInput>>;
  kybRequirementType: Scalars['String'];
};

export enum ComplianceDefinitionScope {
  COUNTRY_SPECIFIC = 'COUNTRY_SPECIFIC',
  DEFAULT = 'DEFAULT',
}

export type ComplianceDocumentInput = {
  documentList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
};

export type ComplianceDocumentRequirementInput = {
  data: Array<InputMaybe<ComplianceDocumentInput>>;
  kybRequirementType: Scalars['String'];
};

export type ComplianceFreelance = Compliance & {
  __typename?: 'ComplianceFreelance';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  freelancerType?: Maybe<FreelancerType>;
  legalEntity?: Maybe<LegalEntity>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type ComplianceFreelance_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceInsightDefinition = InsightDefinition & {
  __typename?: 'ComplianceInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  complianceParam?: Maybe<ComplianceParam>;
  complianceParamPeriodUnit?: Maybe<ComplianceParamPeriodUnit>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  recommendedValue?: Maybe<Scalars['Float']>;
};

export type ComplianceJoiningCheckpoints = {
  __typename?: 'ComplianceJoiningCheckpoints';
  earliestVisaCompletionDate?: Maybe<Scalars['Date']>;
};

export type ComplianceJoiningDateRestriction = {
  __typename?: 'ComplianceJoiningDateRestriction';
  checkpoints?: Maybe<ComplianceJoiningCheckpoints>;
  earliestJoiningDate: Scalars['Date'];
  holidays: Array<Holiday>;
};

export type ComplianceLimitationDefinition = {
  __typename?: 'ComplianceLimitationDefinition';
  countryState?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  preconditions?: Maybe<Array<ComplianceLimitationPrecondition>>;
  value: Scalars['String'];
};

export type ComplianceLimitationPrecondition = {
  __typename?: 'ComplianceLimitationPrecondition';
  contractTerm?: Maybe<ContractTerm>;
  contractType?: Maybe<ContractType>;
};

export type ComplianceMultiplierEor = Compliance & {
  __typename?: 'ComplianceMultiplierEOR';
  certificateOfEmployment?: Maybe<DocumentReadable>;
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  offerLetter?: Maybe<DocumentReadable>;
  preferredContractAgreementTemplate?: Maybe<PreferredContractAgreementTemplateResponse>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type ComplianceMultiplierEor_CertificateOfEmploymentArgs = {
  date: Scalars['Date'];
};

export type ComplianceMultiplierEor_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type CompliancePeo = Compliance & {
  __typename?: 'CompliancePEO';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type CompliancePeo_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceParam = {
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export enum ComplianceParamDataFieldType {
  DROPDOWN = 'DROPDOWN',
  TEXT = 'TEXT',
  TOGGLE = 'TOGGLE',
}

export type ComplianceParamDefinition = {
  __typename?: 'ComplianceParamDefinition';
  dataFieldType?: Maybe<ComplianceParamDataFieldType>;
  dependencies?: Maybe<Array<Maybe<ComplianceParam>>>;
  editable?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  param?: Maybe<ComplianceParam>;
  required?: Maybe<Scalars['Boolean']>;
  validation?: Maybe<Array<Maybe<ComplianceParamValidation>>>;
};

export type ComplianceParamPeriodLimit = ComplianceParam & {
  __typename?: 'ComplianceParamPeriodLimit';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  unit?: Maybe<ComplianceParamPeriodUnit>;
  value?: Maybe<Scalars['Int']>;
};

export type ComplianceParamPeriodLimitInput = {
  key?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<ComplianceParamPeriodUnit>;
  value?: InputMaybe<Scalars['Int']>;
};

export type ComplianceParamPeriodLimitUnitValidation = {
  __typename?: 'ComplianceParamPeriodLimitUnitValidation';
  defaultValue?: Maybe<Scalars['Int']>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  unit?: Maybe<ComplianceParamPeriodUnit>;
};

export enum ComplianceParamPeriodUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  NONE = 'NONE',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export type ComplianceParamType = {
  __typename?: 'ComplianceParamType';
  dataFieldType?: Maybe<ComplianceParamDataFieldType>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
};

export type ComplianceParamTypeInput = {
  dataFieldType?: InputMaybe<ComplianceParamDataFieldType>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceParamValidation = ComplianceParamPeriodLimitUnitValidation;

export type CompliancePartnerEor = Compliance & {
  __typename?: 'CompliancePartnerEOR';
  complianceParams?: Maybe<Array<Maybe<ComplianceParam>>>;
  contract?: Maybe<Contract>;
  contractAgreement?: Maybe<DocumentReadable>;
  contractAgreementTemplate?: Maybe<DocumentReadable>;
  contractAgreementType?: Maybe<ContractAgreementType>;
  legalEntity?: Maybe<LegalEntity>;
  offerLetter?: Maybe<DocumentReadable>;
  partner?: Maybe<EorPartnerCountry>;
  signature?: Maybe<Signature>;
  timeOffEntitlement?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  type?: Maybe<LegalBindingType>;
};

export type CompliancePartnerEor_ComplianceParamsArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export type ComplianceRequirementDefinition = {
  __typename?: 'ComplianceRequirementDefinition';
  paramDefinitions?: Maybe<Array<Maybe<ComplianceParamDefinition>>>;
  usedScope?: Maybe<ComplianceDefinitionScope>;
};

export type ComplianceRiskAssessment = KybRiskAssessment & {
  __typename?: 'ComplianceRiskAssessment';
  collectedRiskData?: Maybe<KybCollectedRiskData>;
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  riskScore?: Maybe<AssessmentRiskScore>;
  schema?: Maybe<KybSchema>;
  status?: Maybe<RiskAssessmentStatus>;
  type: AssessmentType;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type ComponentAccessibilityResponse = {
  __typename?: 'ComponentAccessibilityResponse';
  page: MemberExperiencePage;
  sections?: Maybe<Array<Maybe<PageSection>>>;
};

export type ComponentCategory = {
  __typename?: 'ComponentCategory';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export enum ComponentType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
  VARIABLE_AMOUNT = 'VARIABLE_AMOUNT',
  VARIABLE_PERCENTAGE = 'VARIABLE_PERCENTAGE',
}

export type Condition = {
  __typename?: 'Condition';
  key: ConditionKey;
  operator: ConditionOperator;
  values?: Maybe<Array<Scalars['String']>>;
};

export type ConditionInput = {
  key: ConditionKey;
  operator: ConditionOperator;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export enum ConditionKey {
  COUNTRY = 'COUNTRY',
  DEPARTMENT = 'DEPARTMENT',
  ENTITY = 'ENTITY',
  GENDER = 'GENDER',
  NAME = 'NAME',
}

export enum ConditionOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
}

export type Configuration = {
  __typename?: 'Configuration';
  bankTransferFee?: Maybe<Scalars['Float']>;
  contractChangeRequestConfig?: Maybe<ContractChangeRequestConfig>;
  id?: Maybe<Scalars['ID']>;
};

export type Configuration_BankTransferFeeArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type Configuration_ContractChangeRequestConfigArgs = {
  filters?: InputMaybe<ChangeRequestConfigFiltersInput>;
};

export type ConfirmPayslipsResponse = {
  __typename?: 'ConfirmPayslipsResponse';
  confirmPayslipsSummary?: Maybe<ConfirmedPayslipsSummary>;
  confirmedPayslips?: Maybe<Array<Maybe<Payslip>>>;
};

export type ConfirmedPayslipsSummary = {
  __typename?: 'ConfirmedPayslipsSummary';
  confirmedPayslipsCount?: Maybe<Scalars['Int']>;
  nonConfirmedPayslipsCount?: Maybe<Scalars['Int']>;
  totalMemberCount?: Maybe<Scalars['Int']>;
};

export type ConstantOverride = {
  name: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
};

export type ContactEmailInput = {
  companyPayableID?: InputMaybe<Scalars['ID']>;
  contractID?: InputMaybe<Scalars['ID']>;
};

export type Contract = {
  __typename?: 'Contract';
  activatedOn?: Maybe<Scalars['DateTime']>;
  addendums?: Maybe<Array<Maybe<ContractAddendum>>>;
  alreadyHired?: Maybe<Scalars['Boolean']>;
  benefits?: Maybe<Array<Maybe<ContractBenefit>>>;
  /**
   * To know expense/timeoff approvers of the contract.<br>
   * Useful for the company admin when assigning members to managers, or for the member when submitting a timeoff/expense
   */
  categoryApproverInfos?: Maybe<Array<Maybe<CategoryApproverInfo>>>;
  changeRequests?: Maybe<Array<ContractChangeRequest>>;
  company?: Maybe<Company>;
  compensation?: Maybe<Compensation>;
  compliance?: Maybe<Compliance>;
  contractBlocked?: Maybe<Scalars['Boolean']>;
  contractDataChanges?: Maybe<Array<Maybe<DataChange>>>;
  contractLatestDataChange?: Maybe<Array<Maybe<DataChange>>>;
  contractPaymentAccount?: Maybe<ContractPaymentAccount>;
  contractStarted?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryCode>;
  countryStateCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  depositAmount?: Maybe<Scalars['Float']>;
  depositPayable?: Maybe<Array<Maybe<CompanyPayable>>>;
  employeeId?: Maybe<Scalars['String']>;
  endOn?: Maybe<Scalars['DateTime']>;
  endedOn?: Maybe<Scalars['DateTime']>;
  eorPartner?: Maybe<ContractEorPartner>;
  equipmentProcurements?: Maybe<Array<EquipmentProcurement>>;
  equipments?: Maybe<Array<Equipment>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fieldAccessibilityRules?: Maybe<Array<Maybe<FieldAccessibilityRule>>>;
  id?: Maybe<Scalars['ID']>;
  incomingPayrollCutoffDate?: Maybe<Scalars['Date']>;
  isOffboardingV2Supported?: Maybe<OffboardingCanInitializeValidation>;
  isPayrollActive?: Maybe<Scalars['Boolean']>;
  isPickedUpForPayroll?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isTestContract?: Maybe<Scalars['Boolean']>;
  isValidLastWorkingDay?: Maybe<LastWorkingDayValidation>;
  lastWorkingDay?: Maybe<Scalars['Date']>;
  legalEntityId?: Maybe<Scalars['ID']>;
  member?: Maybe<Member>;
  /**
   * MemberPayable list can filter by:
   * 1. id: memberPayable id
   * 2. status: memberPayable status
   * 3. fromDate: checks if payableFromDate greater than or equal to given date.
   * 4. toDate: checks if payableToDate less than or equal to given date.
   * 5. referenceId: company invoice referenceId, checks for referenceId starting with given value
   * 6. createdDateRange: checked against memberPayable createdOn date
   */
  memberPayables?: Maybe<Array<Maybe<MemberPayable>>>;
  multiplierLegalEntityId?: Maybe<Scalars['ID']>;
  offboarding?: Maybe<ContractOffboarding>;
  offboardingAudit?: Maybe<OffboardingAudit>;
  offboardingNote?: Maybe<Scalars['String']>;
  offboardingV2?: Maybe<Offboarding>;
  onboarding?: Maybe<ContractOnboarding>;
  orgAttributes?: Maybe<OrgAttributes>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  paymentAccount?: Maybe<PaymentAccount>;
  /**
   * Ideally it should be https://app.clickup.com/t/3rch1m9, but we need a quick solution now, so doing this.<br>
   * Of course can't define another `paymentAccount: PaymentAccount` because supergraph will fail to build due to "already defined in payment-account.graphql
   * @deprecated No longer supported
   */
  paymentAccountId?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  paymentAccountRequirements?: Maybe<Array<Maybe<PaymentAccountRequirement>>>;
  payrollNote?: Maybe<Scalars['String']>;
  payrollPartner?: Maybe<ContractPayrollPartner>;
  payrolls?: Maybe<Array<Maybe<MemberPay>>>;
  payslips?: Maybe<Array<Maybe<Payslip>>>;
  performanceReviews?: Maybe<Array<Maybe<PerformanceReview>>>;
  position?: Maybe<Scalars['String']>;
  renewal?: Maybe<ContractRenewalInfo>;
  reportsToManager?: Maybe<Manager>;
  salaryReviews?: Maybe<Array<Maybe<SalaryReview>>>;
  scope?: Maybe<Scalars['String']>;
  startOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ContractStatus>;
  term?: Maybe<ContractTerm>;
  timeOff?: Maybe<ContractTimeOff>;
  timeOffs?: Maybe<Array<Maybe<TimeOff>>>;
  timesheets?: Maybe<Array<Maybe<Timesheet>>>;
  type?: Maybe<ContractType>;
  unresolvedBlockEvents?: Maybe<Array<BlockEvent>>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  workEmail?: Maybe<Scalars['String']>;
  /** Contract's standard working days and hours. (E.g: from Monday to Friday, from 8:30 to 17:30) */
  workShift?: Maybe<WorkShift>;
  workStatus?: Maybe<CountryWorkStatus>;
};

export type Contract_AddendumsArgs = {
  addendumIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Contract_BenefitsArgs = {
  contractBenefitFilters?: InputMaybe<ContractBenefitFilters>;
};

export type Contract_CategoryApproverInfosArgs = {
  activeManagersOnly?: InputMaybe<Scalars['Boolean']>;
  returnAdminsIfNoManagers?: InputMaybe<Scalars['Boolean']>;
};

export type Contract_ChangeRequestsArgs = {
  changeRequestIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Contract_ContractDataChangesArgs = {
  status?: InputMaybe<DataChangeStatus>;
};

export type Contract_ExpensesArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<ExpenseStatus>;
  statuses?: InputMaybe<Array<ExpenseStatus>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Contract_IsOffboardingV2SupportedArgs = {
  offboardingType: OffboardingType;
};

export type Contract_IsPickedUpForPayrollArgs = {
  payslipMonth: MonthYearInput;
};

export type Contract_IsValidLastWorkingDayArgs = {
  dateToCheck: Scalars['Date'];
  offboardingType: ContractOffboardingType;
};

export type Contract_MemberPayablesArgs = {
  createdDateRange?: InputMaybe<DateRange>;
  externalId?: InputMaybe<Scalars['ID']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  paymentMethod?: InputMaybe<TransferType>;
  payoutDateRange?: InputMaybe<DateRange>;
  referenceId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MemberPayableStatus>;
  toDate?: InputMaybe<Scalars['Date']>;
  type?: InputMaybe<MemberPayableType>;
};

export type Contract_PaySupplementsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PaySupplementStatus>;
  statuses?: InputMaybe<Array<PaySupplementStatus>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Contract_PaymentAccountArgs = {
  id: Scalars['ID'];
};

export type Contract_PayrollsArgs = {
  memberPayId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<MonthYearInput>;
};

export type Contract_PayslipsArgs = {
  filters?: InputMaybe<PaySlipFilters>;
  payrollMonth?: InputMaybe<MonthYearInput>;
};

export type Contract_PerformanceReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PerformanceReviewStatus>;
};

export type Contract_SalaryReviewsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<SalaryReviewStatus>;
};

export type Contract_TimeOffArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Contract_TimeOffsArgs = {
  filters?: InputMaybe<TimeOffFilter>;
};

export type Contract_TimesheetsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ContractAddendum = {
  __typename?: 'ContractAddendum';
  addendumOnboarding?: Maybe<ContractAddendumOnboarding>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<DocumentReadable>;
  id?: Maybe<Scalars['ID']>;
};

export type ContractAddendumOnboarding = {
  __typename?: 'ContractAddendumOnboarding';
  id?: Maybe<Scalars['ID']>;
  workflows?: Maybe<Array<Maybe<ContractAddendumWorkflow>>>;
};

export enum ContractAddendumStep {
  GENERATE = 'GENERATE',
  SENT = 'SENT',
  SIGNING = 'SIGNING',
}

export type ContractAddendumWorkflow = {
  __typename?: 'ContractAddendumWorkflow';
  currentStatus?: Maybe<ContractAddendumWorkflowStatus>;
  experience?: Maybe<Persona>;
};

export enum ContractAddendumWorkflowStatus {
  ADDENDUM_GENERATED = 'ADDENDUM_GENERATED',
  ADDENDUM_GENERATION_PENDING = 'ADDENDUM_GENERATION_PENDING',
  ADDENDUM_SENT_FOR_SIGNING = 'ADDENDUM_SENT_FOR_SIGNING',
  ADDENDUM_SIGNED_COMPANY = 'ADDENDUM_SIGNED_COMPANY',
  ADDENDUM_SIGNED_MEMBER = 'ADDENDUM_SIGNED_MEMBER',
  ADDENDUM_SIGNED_MULTIPLIER = 'ADDENDUM_SIGNED_MULTIPLIER',
}

export type ContractAddendums = {
  __typename?: 'ContractAddendums';
  addendums?: Maybe<Array<Maybe<ContractAddendum>>>;
};

export enum ContractAgreementType {
  CUSTOM_TEMPLATE = 'CUSTOM_TEMPLATE',
  MULTIPLIER_TEMPLATE = 'MULTIPLIER_TEMPLATE',
  UPLOADED_FINAL = 'UPLOADED_FINAL',
}

export type ContractBenefit = {
  __typename?: 'ContractBenefit';
  benefit?: Maybe<Benefit>;
  /** @deprecated No longer supported */
  benefitEndDate?: Maybe<Scalars['Date']>;
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  billAmount?: Maybe<Scalars['Float']>;
  changeRequests?: Maybe<Array<Maybe<ContractBenefitChangeRequestResponse>>>;
  contract?: Maybe<Contract>;
  dependentCount?: Maybe<Scalars['Int']>;
  dependents?: Maybe<Array<Maybe<ContractBenefitDependent>>>;
  discountTermPercentage?: Maybe<Scalars['Float']>;
  employeePayPercentage?: Maybe<Scalars['Float']>;
  employerPayPercentage?: Maybe<Scalars['Float']>;
  endOn?: Maybe<Scalars['DateTime']>;
  externalPolicyFileUrl?: Maybe<Scalars['String']>;
  externalPolicyId?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<ContractBenefitDocument>>>;
  id?: Maybe<Scalars['ID']>;
  insurancePremium?: Maybe<InsurancePremium>;
  params?: Maybe<Array<Maybe<ContractBenefitParam>>>;
  pendingChangeRequestsCount?: Maybe<Scalars['Int']>;
  startOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ContractBenefitStatus>;
};

export type ContractBenefit_ChangeRequestsArgs = {
  statuses?: InputMaybe<Array<ContractTypeChangeRequestStatus>>;
};

export type ContractBenefit_DependentsArgs = {
  dependentPlan?: InputMaybe<BenefitTier>;
};

export type ContractBenefitAddingDependentWorkflow = {
  __typename?: 'ContractBenefitAddingDependentWorkflow';
  currentStep?: Maybe<ContractBenefitAddingDependentWorkflowStep>;
  steps?: Maybe<Array<Maybe<ContractBenefitAddingDependentWorkflowStep>>>;
};

export enum ContractBenefitAddingDependentWorkflowStep {
  CAPTURE_BASIC_DETAILS = 'CAPTURE_BASIC_DETAILS',
  CAPTURE_PLAN_DETAILS = 'CAPTURE_PLAN_DETAILS',
  CONFIRM = 'CONFIRM',
  UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
}

export type ContractBenefitBanner = {
  __typename?: 'ContractBenefitBanner';
  contractId: Scalars['ID'];
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: ContractBenefitBannerStatus;
  type?: Maybe<ContractBenefitBannerType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ContractBenefitBannerFilters = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  statuses?: InputMaybe<Array<ContractBenefitBannerStatus>>;
  types?: InputMaybe<Array<ContractBenefitBannerType>>;
};

export type ContractBenefitBannerInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<ContractBenefitBannerType>;
  visibleToAllActiveContractsWithDependents?: InputMaybe<Scalars['Boolean']>;
};

export enum ContractBenefitBannerStatus {
  ACTIVE = 'ACTIVE',
  APPROVED_BY_MEMBER = 'APPROVED_BY_MEMBER',
  APPROVED_BY_OPS = 'APPROVED_BY_OPS',
  NO_ACTION_FROM_MEMBER = 'NO_ACTION_FROM_MEMBER',
  PARTIAL_REVIEW_BY_OPS = 'PARTIAL_REVIEW_BY_OPS',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED_BY_OPS = 'REJECTED_BY_OPS',
  SCHEDULED = 'SCHEDULED',
}

export enum ContractBenefitBannerType {
  DEPENDENT_DOB_CHANGE = 'DEPENDENT_DOB_CHANGE',
}

export type ContractBenefitChangeRequestChange = {
  __typename?: 'ContractBenefitChangeRequestChange';
  dependentChanges?: Maybe<
    Array<Maybe<ContractBenefitChangeRequestDependentDetailsChange>>
  >;
  /** @deprecated No longer supported */
  dependentDetailsChanges?: Maybe<ContractBenefitChangeRequestDependentDetailsChange>;
};

export type ContractBenefitChangeRequestChangeInput = {
  dependentDetailsChanges?: InputMaybe<
    Array<ContractBenefitChangeRequestDependentDetailsChangeInput>
  >;
};

export type ContractBenefitChangeRequestDependentDetails = {
  __typename?: 'ContractBenefitChangeRequestDependentDetails';
  field: Scalars['String'];
  newValue: Scalars['String'];
  oldValue: Scalars['String'];
};

export type ContractBenefitChangeRequestDependentDetailsChange = {
  __typename?: 'ContractBenefitChangeRequestDependentDetailsChange';
  changes?: Maybe<Array<Maybe<ContractBenefitChangeRequestDependentDetails>>>;
  contractBenefitDependentId: Scalars['ID'];
};

export type ContractBenefitChangeRequestDependentDetailsChangeInput = {
  changes: Array<ContractBenefitChangeRequestDependentDetailsInput>;
  contractBenefitDependentId: Scalars['ID'];
};

export type ContractBenefitChangeRequestDependentDetailsInput = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export type ContractBenefitChangeRequestFilters = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  dependentIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<ContractTypeChangeRequestStatus>;
};

export type ContractBenefitChangeRequestInput = {
  benefitContractBannerId?: InputMaybe<Scalars['ID']>;
  changes?: InputMaybe<ContractBenefitChangeRequestChangeInput>;
  contractId: Scalars['ID'];
  type: ContractBenefitChangeRequestType;
};

export type ContractBenefitChangeRequestResponse = {
  __typename?: 'ContractBenefitChangeRequestResponse';
  changes: ContractBenefitChangeRequestChange;
  contractId: Scalars['ID'];
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  status: ContractTypeChangeRequestStatus;
  type: ContractBenefitChangeRequestType;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum ContractBenefitChangeRequestType {
  CHANGE_DEPENDENT_DETAILS = 'CHANGE_DEPENDENT_DETAILS',
}

export type ContractBenefitChangeRequestUpdateInput = {
  id: Scalars['ID'];
  status: ContractTypeChangeRequestStatus;
};

export type ContractBenefitDependent = {
  __typename?: 'ContractBenefitDependent';
  addingWorkflow?: Maybe<ContractBenefitAddingDependentWorkflow>;
  benefit?: Maybe<Benefit>;
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  changeRequests?: Maybe<Array<Maybe<ContractBenefitChangeRequestResponse>>>;
  contractBenefit?: Maybe<ContractBenefit>;
  country?: Maybe<CountryCode>;
  countryStateCode?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<Maybe<ContractBenefitDependentDocument>>>;
  endOn?: Maybe<Scalars['DateTime']>;
  externalPolicyFileUrl?: Maybe<Scalars['String']>;
  externalPolicyId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  legalData?: Maybe<Array<Maybe<ContractBenefitDependentLegalData>>>;
  relationshipToMember?: Maybe<Relationship>;
  startOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ContractBenefitDependentStatus>;
};

export type ContractBenefitDependent_ChangeRequestsArgs = {
  statuses?: InputMaybe<Array<ContractTypeChangeRequestStatus>>;
};

export type ContractBenefitDependentDocument = {
  __typename?: 'ContractBenefitDependentDocument';
  /** @deprecated Use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  key?: Maybe<ContractBenefitDependentDocumentType>;
};

export enum ContractBenefitDependentDocumentType {
  HEALTH_CARD = 'HEALTH_CARD',
  OTHER = 'OTHER',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PROOF_OF_IDENTITY = 'PROOF_OF_IDENTITY',
  PROOF_OF_RELATIONSHIP = 'PROOF_OF_RELATIONSHIP',
}

export type ContractBenefitDependentLegalData = {
  __typename?: 'ContractBenefitDependentLegalData';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ContractBenefitDependentLegalDataInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum ContractBenefitDependentStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type ContractBenefitDocument = {
  __typename?: 'ContractBenefitDocument';
  /** @deprecated Use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  key?: Maybe<ContractBenefitDocumentType>;
};

export enum ContractBenefitDocumentType {
  HEALTH_CARD = 'HEALTH_CARD',
  OTHER = 'OTHER',
}

export type ContractBenefitFilters = {
  benefitType?: InputMaybe<BenefitType>;
  companyId?: InputMaybe<Scalars['ID']>;
  contractBenefitPlan?: InputMaybe<BenefitTier>;
  /** @deprecated. Use contractBenefitStatuses instead */
  contractBenefitStatus?: InputMaybe<ContractBenefitStatus>;
  contractBenefitStatuses?: InputMaybe<
    Array<InputMaybe<ContractBenefitStatus>>
  >;
  contractId?: InputMaybe<Scalars['ID']>;
  contractStartDateRange?: InputMaybe<DateRange>;
  contractStatuses?: InputMaybe<Array<InputMaybe<ContractStatus>>>;
  country?: InputMaybe<CountryCode>;
  coverageStartDateRange?: InputMaybe<DateRange>;
  dependentsPlan?: InputMaybe<BenefitTier>;
  hasDependents?: InputMaybe<Scalars['Boolean']>;
  memberNameContains?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<ContractOnboardingStatus>;
};

export type ContractBenefitParam = {
  __typename?: 'ContractBenefitParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ContractBenefitStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  NEW = 'NEW',
  OFFBOARDED = 'OFFBOARDED',
  ONBOARDING = 'ONBOARDING',
  OPTED_OUT = 'OPTED_OUT',
}

export type ContractBenefitWithPaginationFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractBenefitStatuses?: InputMaybe<Array<ContractBenefitStatus>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStartDateRange?: InputMaybe<DateRange>;
  contractStatus?: InputMaybe<Array<ContractStatus>>;
  countries?: InputMaybe<Array<CountryCode>>;
  coverageStartDateRange?: InputMaybe<DateRange>;
  hasPendingChangeRequests?: InputMaybe<Scalars['Boolean']>;
  onboardingStatuses?: InputMaybe<Array<ContractOnboardingStatus>>;
};

export type ContractBenefitsCountResult = {
  __typename?: 'ContractBenefitsCountResult';
  countriesCount?: Maybe<Scalars['Int']>;
  employeesCount?: Maybe<Scalars['Int']>;
};

export type ContractBenefitsResult = {
  __typename?: 'ContractBenefitsResult';
  countResult?: Maybe<ContractBenefitsCountResult>;
  data?: Maybe<Array<ContractBenefit>>;
  pageResult?: Maybe<PageResult>;
};

export type ContractBulkUpdateWorkShiftInput = {
  ids: Array<Scalars['ID']>;
  workShift?: InputMaybe<WorkShiftInput>;
};

export type ContractChangeRequest = {
  __typename?: 'ContractChangeRequest';
  contractId: Scalars['ID'];
  createdOn?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  status?: Maybe<ChangeRequestStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ContractChangeRequestConfig = {
  __typename?: 'ContractChangeRequestConfig';
  earliestJoiningDateWithTurnAround?: Maybe<Scalars['Date']>;
  turnAroundTimeInHours?: Maybe<Scalars['Int']>;
};

export type ContractChangeRequestUpdateInput = {
  changeRequestId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type ContractCreateInput = {
  alreadyHired?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['ID']>;
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  memberId: Scalars['ID'];
  position?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  term?: InputMaybe<ContractTerm>;
  type: ContractType;
  workEmail?: InputMaybe<Scalars['String']>;
  workShift?: InputMaybe<WorkShiftInput>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

/**
 * DB: country.contract_detail_restriction.
 * Case sensitive: No.
 */
export type ContractDetailRestriction = {
  __typename?: 'ContractDetailRestriction';
  alternatives?: Maybe<Array<Scalars['String']>>;
  countryCode?: Maybe<CountryCode>;
  field?: Maybe<ContractDetailRestrictionField>;
  id?: Maybe<Scalars['ID']>;
  operator?: Maybe<ContractDetailRestrictionOperator>;
  searchTerm?: Maybe<Scalars['String']>;
};

export enum ContractDetailRestrictionField {
  JOB_TITLE = 'JOB_TITLE',
}

export enum ContractDetailRestrictionOperator {
  CONTAIN = 'CONTAIN',
  EQUAL = 'EQUAL',
  START_WITH = 'START_WITH',
}

export enum ContractDocumentStatus {
  CONTRACT_NOT_SIGNED = 'CONTRACT_NOT_SIGNED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CUSTOM_CONTRACT = 'CUSTOM_CONTRACT',
  DRAFT_CONTRACT = 'DRAFT_CONTRACT',
  EMPLOYEE_SIGNED = 'EMPLOYEE_SIGNED',
  EMPLOYER_SIGNED = 'EMPLOYER_SIGNED',
  PREPARING_CONTRACT = 'PREPARING_CONTRACT',
  SIGNING_IN_PROGRESS = 'SIGNING_IN_PROGRESS',
}

export type ContractDocumentWorkflow = {
  __typename?: 'ContractDocumentWorkflow';
  currentStatus?: Maybe<ContractDocumentStatus>;
  statuses?: Maybe<Array<Maybe<ContractDocumentStatus>>>;
};

export type ContractDropInput = {
  contractId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
};

export type ContractEorPartner = {
  __typename?: 'ContractEORPartner';
  contract?: Maybe<Contract>;
  eorPartnerCountry?: Maybe<EorPartnerCountry>;
};

export type ContractFilters = {
  activationDateRange?: InputMaybe<DateRange>;
  assignedToOpsUser?: InputMaybe<Scalars['Boolean']>;
  benefitPackageName?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyNameContains?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['ID']>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  contractType?: InputMaybe<ContractType>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  country?: InputMaybe<CountryCode>;
  createdDateRange?: InputMaybe<DateRange>;
  csmUserId?: InputMaybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  depositInvoiceFullyPaidOnDateRange?: InputMaybe<DateRange>;
  /** @deprecated No longer supported */
  depositInvoiceId?: InputMaybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  depositInvoiceStatus?: InputMaybe<InvoiceStatus>;
  depositPayableFilters?: InputMaybe<DepositPayableFilters>;
  endedDateRange?: InputMaybe<DateRange>;
  eorPartnerId?: InputMaybe<Scalars['ID']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedStatuses?: InputMaybe<Array<ContractStatus>>;
  excludedTypes?: InputMaybe<Array<ContractType>>;
  isLegacyOffboarding?: InputMaybe<Scalars['Boolean']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  isPayrollActive?: InputMaybe<Scalars['Boolean']>;
  isTestContract?: InputMaybe<Scalars['Boolean']>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  memberEmailContains?: InputMaybe<Scalars['String']>;
  memberNameContains?: InputMaybe<Scalars['String']>;
  offboardingStatuses?: InputMaybe<Array<ContractOffboardingStatus>>;
  onboardingOperationsUserId?: InputMaybe<Scalars['ID']>;
  onboardingStatus?: InputMaybe<ContractOnboardingStatus>;
  onboardingStatuses?: InputMaybe<Array<ContractOnboardingStatus>>;
  payrollFormsUploadDateRange?: InputMaybe<DateRange>;
  payrollFormsUploadStatus?: InputMaybe<PayrollFormsUploadStatus>;
  payrollStartDateRange?: InputMaybe<DateRange>;
  requiresPayrollForms?: InputMaybe<Scalars['Boolean']>;
  startDateRange?: InputMaybe<DateRange>;
  toBeAssignedEorPartner?: InputMaybe<Scalars['Boolean']>;
  validTillDateRange?: InputMaybe<DateRange>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type ContractIdMappingUpload = {
  __typename?: 'ContractIdMappingUpload';
  contract?: Maybe<Contract>;
  inputJoiningDate?: Maybe<Scalars['DateTime']>;
  inputName?: Maybe<Scalars['String']>;
};

export type ContractOffboarding = {
  __typename?: 'ContractOffboarding';
  contractId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  didNotJoinReason?: Maybe<ContractOffboardingDidNotJoinReason>;
  documents?: Maybe<Array<Maybe<ContractOffboardingDocument>>>;
  id?: Maybe<Scalars['ID']>;
  initiatedBy?: Maybe<ContractOffboardingInitiator>;
  initiatedOn?: Maybe<Scalars['DateTime']>;
  lastWorkingDay?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
  severancePay?: Maybe<SeverancePayOption>;
  source?: Maybe<ContractOffboardingSource>;
  status?: Maybe<ContractOffboardingStatus>;
  terminationClauses?: Maybe<Array<Maybe<Scalars['String']>>>;
  terminationReason?: Maybe<Scalars['String']>;
  type?: Maybe<ContractOffboardingType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  verificationChecklist?: Maybe<ContractOffboardingVerificationChecklist>;
};

export type ContractOffboarding_DocumentsArgs = {
  type?: InputMaybe<ContractOffboardingDocumentType>;
};

export type ContractOffboardingCancelInput = {
  contractOffboardingId: Scalars['ID'];
};

export type ContractOffboardingCompleteInput = {
  contractOffboardingId: Scalars['ID'];
  finalSettlementProcessed?: InputMaybe<Scalars['String']>;
  insurance?: InputMaybe<Scalars['String']>;
  pendingExpenses?: InputMaybe<Scalars['String']>;
  pendingPaySupplements?: InputMaybe<Scalars['String']>;
  verificationChecklistDocuments?: InputMaybe<
    Array<InputMaybe<Scalars['Upload']>>
  >;
};

export type ContractOffboardingDidNotJoinInitialiseInput = {
  contractId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<ContractOffboardingDidNotJoinReason>;
};

export enum ContractOffboardingDidNotJoinReason {
  JOINED_ANOTHER_COMPANY = 'JOINED_ANOTHER_COMPANY',
  NO_INFORMATION = 'NO_INFORMATION',
  OTHERS = 'OTHERS',
}

export type ContractOffboardingDocument = {
  __typename?: 'ContractOffboardingDocument';
  contractId?: Maybe<Scalars['ID']>;
  contractOffboardingId?: Maybe<Scalars['ID']>;
  /** @deprecated Use `file` instead */
  document?: Maybe<DocumentReadable>;
  file?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ContractOffboardingDocumentType>;
};

export enum ContractOffboardingDocumentType {
  CHECKLIST = 'CHECKLIST',
  SUPPORTING = 'SUPPORTING',
}

export enum ContractOffboardingInitiator {
  EMPLOYER = 'EMPLOYER',
  OPERATIONS = 'OPERATIONS',
}

export type ContractOffboardingRescheduleInput = {
  contractOffboardingId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  revisedDate?: InputMaybe<Scalars['Date']>;
};

export type ContractOffboardingResignationInitialiseInput = {
  contractId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  lastWorkingDay?: InputMaybe<Scalars['Date']>;
  notes?: InputMaybe<Scalars['String']>;
};

export enum ContractOffboardingSource {
  EXTERNAL_SERVICE = 'EXTERNAL_SERVICE',
  MULTIPLIER_PLATFORM = 'MULTIPLIER_PLATFORM',
}

export enum ContractOffboardingStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FNF_PENDING = 'FNF_PENDING',
  INITIATED = 'INITIATED',
}

export type ContractOffboardingTerminationInitialiseInput = {
  clauses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contractId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  lastWorkingDay?: InputMaybe<Scalars['Date']>;
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  severancePay?: InputMaybe<SeverancePayOption>;
};

export enum ContractOffboardingType {
  DID_NOT_JOIN = 'DID_NOT_JOIN',
  RESIGNATION = 'RESIGNATION',
  TERMINATION = 'TERMINATION',
}

export type ContractOffboardingVerificationChecklist = {
  __typename?: 'ContractOffboardingVerificationChecklist';
  checklistDocuments?: Maybe<Array<Maybe<ContractOffboardingDocument>>>;
  contractId?: Maybe<Scalars['ID']>;
  contractOffboardingId?: Maybe<Scalars['ID']>;
  finalSettlementProcessed?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  insurance?: Maybe<Scalars['String']>;
  pendingExpenses?: Maybe<Scalars['String']>;
  pendingPaySupplements?: Maybe<Scalars['String']>;
};

export type ContractOffboardingVerifyInput = {
  contractOffboardingId: Scalars['ID'];
};

export type ContractOnboarding = {
  __typename?: 'ContractOnboarding';
  activationCutoff?: Maybe<Scalars['Date']>;
  activationCutoffData?: Maybe<ActivationCutoffData>;
  contract?: Maybe<Contract>;
  contractDocumentWorkflow?: Maybe<ContractDocumentWorkflow>;
  current?: Maybe<ContractOnboardingStep>;
  handler?: Maybe<OperationsUser>;
  id?: Maybe<Scalars['ID']>;
  payload?: Maybe<Array<Maybe<ContractOnboardingParam>>>;
  status?: Maybe<ContractOnboardingStatus>;
  statuses?: Maybe<Array<Maybe<ContractOnboardingStatus>>>;
  steps?: Maybe<Array<Maybe<ContractOnboardingStep>>>;
  tasks?: Maybe<Array<OnboardingTask>>;
};

export type ContractOnboardingInput = {
  completed?: InputMaybe<ContractOnboardingStep>;
  payload?: InputMaybe<ContractOnboardingParamInput>;
};

export type ContractOnboardingParam = {
  __typename?: 'ContractOnboardingParam';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ContractOnboardingParamInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum ContractOnboardingStatus {
  ACTIVE = 'ACTIVE',
  CONTRACT_PREPARING = 'CONTRACT_PREPARING',
  CONTRACT_PREPARING_CONFIRMATION = 'CONTRACT_PREPARING_CONFIRMATION',
  CONTRACT_UPDATING = 'CONTRACT_UPDATING',
  CONTRACT_WET_INK_PREPARING = 'CONTRACT_WET_INK_PREPARING',
  CREATED = 'CREATED',
  CREATED_CUSTOM = 'CREATED_CUSTOM',
  DRAFT = 'DRAFT',
  DROPPED = 'DROPPED',
  /** @deprecated No longer supported */
  ICA_SENT_TO_MULTIPLIER = 'ICA_SENT_TO_MULTIPLIER',
  /** @deprecated No longer supported */
  ICA_SIGNED_BY_MULTIPLIER = 'ICA_SIGNED_BY_MULTIPLIER',
  /** @deprecated No longer supported */
  INVITED = 'INVITED',
  MEMBER_COMPLETED = 'MEMBER_COMPLETED',
  MEMBER_DATA_ADDED = 'MEMBER_DATA_ADDED',
  MEMBER_DATA_REVIEWED = 'MEMBER_DATA_REVIEWED',
  MEMBER_INVITED = 'MEMBER_INVITED',
  MEMBER_INVITE_PENDING = 'MEMBER_INVITE_PENDING',
  MEMBER_STARTED = 'MEMBER_STARTED',
  MEMBER_VERIFICATION_COMPLETED = 'MEMBER_VERIFICATION_COMPLETED',
  MEMBER_VERIFICATION_IN_PROGRESS = 'MEMBER_VERIFICATION_IN_PROGRESS',
  MSA_SIGNING_IN_PROGRESS = 'MSA_SIGNING_IN_PROGRESS',
  MSA_SIGNING_PENDING = 'MSA_SIGNING_PENDING',
  NULL_STATE = 'NULL_STATE',
  ORDER_FORM_SENT_TO_EMPLOYER = 'ORDER_FORM_SENT_TO_EMPLOYER',
  ORDER_FORM_SENT_TO_MULTIPLIER = 'ORDER_FORM_SENT_TO_MULTIPLIER',
  ORDER_FORM_SIGNED_BY_EMPLOYER = 'ORDER_FORM_SIGNED_BY_EMPLOYER',
  ORDER_FORM_SIGNED_BY_MULTIPLIER = 'ORDER_FORM_SIGNED_BY_MULTIPLIER',
  /** @deprecated No longer supported */
  PREPARING_CONTRACT = 'PREPARING_CONTRACT',
  /** @deprecated No longer supported */
  RESOLUTION_IN_PROGRESS = 'RESOLUTION_IN_PROGRESS',
  REVOKED = 'REVOKED',
  SIGNATURE_EMPLOYEE_SENT = 'SIGNATURE_EMPLOYEE_SENT',
  SIGNATURE_EMPLOYEE_SIGNED = 'SIGNATURE_EMPLOYEE_SIGNED',
  SIGNATURE_EMPLOYER_SENT = 'SIGNATURE_EMPLOYER_SENT',
  SIGNATURE_EMPLOYER_SIGNED = 'SIGNATURE_EMPLOYER_SIGNED',
  SIGNATURE_MULTIPLIER_SENT = 'SIGNATURE_MULTIPLIER_SENT',
  SIGNATURE_MULTIPLIER_SIGNED = 'SIGNATURE_MULTIPLIER_SIGNED',
  /** @deprecated No longer supported */
  SIGNING_IN_PROGRESS = 'SIGNING_IN_PROGRESS',
  /** @deprecated No longer supported */
  VERIFICATION_DONE = 'VERIFICATION_DONE',
  /** @deprecated No longer supported */
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS',
  VISA_APPLICATION_APPROVED = 'VISA_APPLICATION_APPROVED',
  VISA_APPLICATION_FILING = 'VISA_APPLICATION_FILING',
  VISA_APPLICATION_PREPARING = 'VISA_APPLICATION_PREPARING',
  VISA_DOCUMENTS_STARTED = 'VISA_DOCUMENTS_STARTED',
  VISA_DOCUMENTS_SUBMITTED = 'VISA_DOCUMENTS_SUBMITTED',
  VISA_DOCUMENTS_VERIFIED = 'VISA_DOCUMENTS_VERIFIED',
  VISA_INVOICE_PAID = 'VISA_INVOICE_PAID',
}

export enum ContractOnboardingStep {
  DEFINITION_BANK_DETAILS = 'DEFINITION_BANK_DETAILS',
  DEFINITION_BENEFITS_DETAILS = 'DEFINITION_BENEFITS_DETAILS',
  DEFINITION_COMPENSATION_DETAILS = 'DEFINITION_COMPENSATION_DETAILS',
  DEFINITION_COMPLIANCE_DETAILS = 'DEFINITION_COMPLIANCE_DETAILS',
  DEFINITION_CONTRACT = 'DEFINITION_CONTRACT',
  DEFINITION_COUNTRY_SPECIFIC_DETAILS = 'DEFINITION_COUNTRY_SPECIFIC_DETAILS',
  DEFINITION_MEMBER_BASIC_DETAILS = 'DEFINITION_MEMBER_BASIC_DETAILS',
  DEFINITION_MEMBER_ELIGIBILITY = 'DEFINITION_MEMBER_ELIGIBILITY',
  MEMBER_BANK_DETAILS = 'MEMBER_BANK_DETAILS',
  MEMBER_BASIC_DETAILS = 'MEMBER_BASIC_DETAILS',
  MEMBER_BENEFIT_DETAILS = 'MEMBER_BENEFIT_DETAILS',
  MEMBER_LEGAL_DETAILS = 'MEMBER_LEGAL_DETAILS',
  MEMBER_LEGAL_DOCUMENTS = 'MEMBER_LEGAL_DOCUMENTS',
  MEMBER_REVIEW = 'MEMBER_REVIEW',
  MEMBER_VISA_APPLICATION = 'MEMBER_VISA_APPLICATION',
  MEMBER_VISA_APPROVED = 'MEMBER_VISA_APPROVED',
  MEMBER_VISA_ELIGIBILITY = 'MEMBER_VISA_ELIGIBILITY',
  MEMBER_WELCOME = 'MEMBER_WELCOME',
  MEMBER_WELCOME_VISA = 'MEMBER_WELCOME_VISA',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_ACTIVATION = 'ONBOARDING_ACTIVATION',
  ONBOARDING_CONTRACT = 'ONBOARDING_CONTRACT',
  ONBOARDING_CONTRACT_PREPARING_CONFIRMATION = 'ONBOARDING_CONTRACT_PREPARING_CONFIRMATION',
  ONBOARDING_CONTRACT_UPDATING = 'ONBOARDING_CONTRACT_UPDATING',
  ONBOARDING_CONTRACT_WET_INK_PREPARING = 'ONBOARDING_CONTRACT_WET_INK_PREPARING',
  ONBOARDING_CONTRACT_WET_INK_PREPARING_CONFIRMATION = 'ONBOARDING_CONTRACT_WET_INK_PREPARING_CONFIRMATION',
  ONBOARDING_MEMBER = 'ONBOARDING_MEMBER',
  ONBOARDING_MSA_PENDING = 'ONBOARDING_MSA_PENDING',
  ONBOARDING_MULTIPLIER_SIGNING = 'ONBOARDING_MULTIPLIER_SIGNING',
  ONBOARDING_REVIEW = 'ONBOARDING_REVIEW',
  ONBOARDING_SIGNING = 'ONBOARDING_SIGNING',
  ONBOARDING_VERIFYING = 'ONBOARDING_VERIFYING',
  START = 'START',
  VISA_APPLICATION = 'VISA_APPLICATION',
  VISA_ELIGIBILITY = 'VISA_ELIGIBILITY',
}

export type ContractPaymentAccount = {
  __typename?: 'ContractPaymentAccount';
  configurations?: Maybe<ContractPaymentAccountConfig>;
  id?: Maybe<Scalars['ID']>;
  paymentAccountId?: Maybe<Scalars['ID']>;
  supportedPaymentOptions?: Maybe<Array<Maybe<PaymentOption>>>;
};

export type ContractPaymentAccountConfig = {
  __typename?: 'ContractPaymentAccountConfig';
  preferredPayOutType?: Maybe<TransferType>;
};

export enum ContractPayrollLabel {
  ACTIVE = 'ACTIVE',
  EXCLUDED = 'EXCLUDED',
  INCLUDED = 'INCLUDED',
  NEW_HIRE = 'NEW_HIRE',
  OFFBOARDING = 'OFFBOARDING',
  ONBOARDING = 'ONBOARDING',
  TERMINATION = 'TERMINATION',
}

export type ContractPayrollPartner = {
  __typename?: 'ContractPayrollPartner';
  contract?: Maybe<Contract>;
  id?: Maybe<Scalars['ID']>;
  payrollPartnerCountry?: Maybe<PayrollPartnerCountry>;
  status?: Maybe<ContractPayrollPartnerStatus>;
};

export enum ContractPayrollPartnerStatus {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  OFFBOARDED = 'OFFBOARDED',
  REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS',
}

export type ContractRenewalInfo = {
  __typename?: 'ContractRenewalInfo';
  definition?: Maybe<FixedTermRestrictionDefinition>;
  remainingRenewalCount?: Maybe<Scalars['Int']>;
  remainingTenureInMonths?: Maybe<Scalars['Int']>;
};

export type ContractReviseInput = {
  isReviseByOpsAfterSend?: InputMaybe<Scalars['Boolean']>;
};

export type ContractSnapshot = {
  __typename?: 'ContractSnapshot';
  companyName?: Maybe<Scalars['String']>;
  contractActivatedDate?: Maybe<Scalars['Date']>;
  contractId?: Maybe<Scalars['ID']>;
  contractStartDate?: Maybe<Scalars['Date']>;
  contractStatus?: Maybe<ContractStatus>;
  exceptions?: Maybe<Array<Maybe<PayrollContractException>>>;
  expenses?: Maybe<Array<Maybe<ExpenseSnapshot>>>;
  id?: Maybe<Scalars['ID']>;
  lastWorkingDate?: Maybe<Scalars['Date']>;
  memberName?: Maybe<Scalars['String']>;
  notIncludedExpenses: Array<Expense>;
  notIncludedPaySupplements: Array<PaySupplement>;
  paySupplements?: Maybe<Array<Maybe<PaySupplementSnapshot>>>;
  payrollContractType?: Maybe<Array<Maybe<PayrollContractType>>>;
  /** Related payrollCycle of the contractSnapshot. Needs to be a child fetcher with data loader to avoid N+1 */
  payrollCycle?: Maybe<PayrollCycle>;
  payrollFormUploadStatus?: Maybe<PayrollFormsUploadStatus>;
  relatedCompanyPayables?: Maybe<Array<CompanyPayable>>;
  submittedDate?: Maybe<Scalars['Date']>;
};

export type ContractSnapshotFilter = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedPayrollCycleStatuses?: InputMaybe<Array<PayrollCycleStatus>>;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
};

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ENDED = 'ENDED',
  OFFBOARDING = 'OFFBOARDING',
  ONBOARDING = 'ONBOARDING',
}

export enum ContractTerm {
  FIXED = 'FIXED',
  PERMANENT = 'PERMANENT',
}

export type ContractTimeOff = {
  __typename?: 'ContractTimeOff';
  summary?: Maybe<Array<Maybe<TimeOffType>>>;
  timeOffs?: Maybe<Array<Maybe<TimeOff>>>;
};

export type ContractTimeOff_SummaryArgs = {
  period?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ContractTimeOffEntitlement = {
  __typename?: 'ContractTimeOffEntitlement';
  definition?: Maybe<TimeOffTypeDefinition>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  timeOffType?: Maybe<TimeOffTypeInfo>;
  /**
   * deprecated, to be removed once HRIS is live -> use timeoffType instead
   * @deprecated use timeOffType instead
   */
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<TimeOffUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type ContractTimesheetSummary = {
  __typename?: 'ContractTimesheetSummary';
  contract?: Maybe<Contract>;
  cutOffDate?: Maybe<Scalars['Date']>;
  totalDaysToApprove?: Maybe<Scalars['Int']>;
  totalSubmittedHoursToApprove?: Maybe<Scalars['Float']>;
};

export type ContractTriggerPayrollFormsReminderEmailInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  todayDateAs?: InputMaybe<Scalars['Date']>;
};

export enum ContractType {
  CONTRACTOR = 'CONTRACTOR',
  EMPLOYEE = 'EMPLOYEE',
  FREELANCER = 'FREELANCER',
  HR_MEMBER = 'HR_MEMBER',
}

export enum ContractTypeChangeRequestStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type ContractUnblockDataChangeInput = {
  category: Scalars['String'];
  changes?: InputMaybe<Array<Scalars['String']>>;
};

export type ContractUnblockInput = {
  blockEventId: Scalars['ID'];
  dataChanges?: InputMaybe<Array<ContractUnblockDataChangeInput>>;
  reason: Scalars['String'];
};

export type ContractUpdateBenefitDependentInput = {
  benefitId?: InputMaybe<Scalars['String']>;
  contractBenefitDependentId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  healthCard?: InputMaybe<HealthCardFile>;
  healthId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  legalData?: InputMaybe<Array<ContractBenefitDependentLegalDataInput>>;
  plan?: InputMaybe<Array<InputMaybe<BenefitParamInput>>>;
  relationshipToMember?: InputMaybe<Relationship>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ContractBenefitDependentStatus>;
};

export type ContractUpdateBenefitsInput = {
  benefitId?: InputMaybe<Scalars['String']>;
  dependentCount?: InputMaybe<Scalars['Int']>;
  discountTermPercentage?: InputMaybe<Scalars['Float']>;
  employeePayPercentage?: InputMaybe<Scalars['Float']>;
  employerPayPercentage?: InputMaybe<Scalars['Float']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  params?: InputMaybe<Array<InputMaybe<BenefitParamInput>>>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ContractBenefitStatus>;
};

export type ContractUpdateCompensationInput = {
  additional?: InputMaybe<Scalars['String']>;
  basePay: FixedPayComponentInput;
  deductions?: InputMaybe<Array<InputMaybe<DeductionInput>>>;
  grant?: InputMaybe<Array<InputMaybe<GrantInput>>>;
  other?: InputMaybe<Array<InputMaybe<FixedPayComponentInput>>>;
  otherFixedPayComponents?: InputMaybe<
    Array<InputMaybe<FixedPayComponentInput>>
  >;
  otherPercentPayComponents?: InputMaybe<
    Array<InputMaybe<PercentPayComponentInput>>
  >;
  payType?: InputMaybe<PayType>;
  payrollConfigId?: InputMaybe<Scalars['ID']>;
  payrollStart?: InputMaybe<Scalars['DateTime']>;
  probationBasePay?: InputMaybe<FixedPayComponentInput>;
};

export type ContractUpdateComplianceInput = {
  complianceParams?: InputMaybe<
    Array<InputMaybe<ComplianceParamPeriodLimitInput>>
  >;
  contractAgreementType?: InputMaybe<ContractAgreementType>;
  preferredContractAgreementTemplate?: InputMaybe<Scalars['ID']>;
};

export type ContractUpdateCountryInput = {
  alreadyHired?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type ContractUpdateEmploymentInput = {
  country?: InputMaybe<CountryCode>;
  employeeId?: InputMaybe<Scalars['String']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  position?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  startOn?: InputMaybe<Scalars['DateTime']>;
  term?: InputMaybe<ContractTerm>;
  workEmail?: InputMaybe<Scalars['String']>;
  workShift?: InputMaybe<WorkShiftInput>;
};

export type ContractUpdatePaymentAccountInput = {
  preferredPayOutType?: InputMaybe<TransferType>;
};

export type ContractUpdateTimeOffEntitlementsInput = {
  key?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<TimeOffUnit>;
  value?: InputMaybe<Scalars['Float']>;
};

export type ContractsCountRequest = {
  all?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<Scalars['Boolean']>;
  testUser?: InputMaybe<Scalars['Boolean']>;
  verification?: InputMaybe<Scalars['Boolean']>;
};

export type ContractsCountResult = {
  __typename?: 'ContractsCountResult';
  all?: Maybe<Scalars['Int']>;
  onboarding?: Maybe<Scalars['Int']>;
  testUser?: Maybe<Scalars['Int']>;
  verification?: Maybe<Scalars['Int']>;
};

export type ContractsResult = {
  __typename?: 'ContractsResult';
  countResult?: Maybe<ContractsCountResult>;
  data?: Maybe<Array<Maybe<Contract>>>;
  pageResult?: Maybe<PageResult>;
  payrollActiveCount?: Maybe<Scalars['Int']>;
};

export enum ContributionBy {
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYER = 'EMPLOYER',
}

export type ControllingField = {
  __typename?: 'ControllingField';
  key: Scalars['String'];
};

export type ControllingFieldInput = {
  key: Scalars['String'];
};

export type CostCenter = {
  __typename?: 'CostCenter';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  bankAccountFields?: Maybe<BankAccountFields>;
  code?: Maybe<CountryCode>;
  /** @deprecated No longer supported */
  compensationStructureDefinition?: Maybe<CompensationStructureDefinition>;
  compliance?: Maybe<CountryCompliance>;
  /** @deprecated No longer supported */
  complianceRequirements?: Maybe<Array<Maybe<ComplianceParamDefinition>>>;
  /** @deprecated No longer supported */
  compliantCurrencies?: Maybe<Array<Maybe<CurrencyCode>>>;
  configs?: Maybe<Array<CountryConfig>>;
  countryStates?: Maybe<Array<Maybe<State>>>;
  currency?: Maybe<Currency>;
  documents?: Maybe<Array<Maybe<CountryDocument>>>;
  fiscalYears?: Maybe<Array<Maybe<FiscalYear>>>;
  holidays?: Maybe<Array<Maybe<Holiday>>>;
  insights?: Maybe<Array<Maybe<InsightDefinition>>>;
  isMultiplierVisaAssistance?: Maybe<Scalars['Boolean']>;
  labourStandards?: Maybe<CountryLabourStandards>;
  legalEntityPayrollDataRequirements?: Maybe<LegalEntityPayrollDataRequirements>;
  mainCurrency?: Maybe<CurrencyCode>;
  /** @deprecated No longer supported */
  memberDocumentRequirements?: Maybe<Array<Maybe<LegalDocumentRequirement>>>;
  /** @deprecated No longer supported */
  memberLegalDataRequirements?: Maybe<Array<Maybe<LegalDataRequirement>>>;
  name?: Maybe<Scalars['String']>;
  payrollInputNotes?: Maybe<Array<PayrollInputNote>>;
  productFilterSpec?: Maybe<ProductFilterSpec>;
  productList?: Maybe<ProductList>;
  region?: Maybe<Scalars['String']>;
  supportedCurrencies?: Maybe<Array<Maybe<CurrencyCode>>>;
};

export type Country_CompensationStructureDefinitionArgs = {
  term?: InputMaybe<ContractTerm>;
  type?: InputMaybe<ContractType>;
};

export type Country_ComplianceArgs = {
  contractType?: InputMaybe<ContractType>;
  countryStateCode?: InputMaybe<Scalars['String']>;
};

export type Country_ComplianceRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  term?: InputMaybe<ContractTerm>;
};

export type Country_ConfigsArgs = {
  key?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type Country_DocumentsArgs = {
  filter?: InputMaybe<CountryDocumentFilter>;
};

export type Country_FiscalYearsArgs = {
  years?: InputMaybe<Array<Scalars['Int']>>;
};

export type Country_HolidaysArgs = {
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type Country_InsightsArgs = {
  filter?: InputMaybe<InsightDefinitionFilter>;
};

export type Country_LabourStandardsArgs = {
  filter?: InputMaybe<CountryLabourStandardsFilter>;
};

export type Country_LegalEntityPayrollDataRequirementsArgs = {
  country?: InputMaybe<CountryCode>;
};

export type Country_MemberDocumentRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type Country_MemberLegalDataRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type Country_ProductFilterSpecArgs = {
  status?: InputMaybe<ProductStatus>;
};

export type Country_ProductListArgs = {
  filter: ProductFilter;
};

export type Country_SupportedCurrenciesArgs = {
  contractType?: InputMaybe<ContractType>;
};

export type Country13th14thMonthPay = {
  __typename?: 'Country13th14thMonthPay';
  description13thMonthPay?: Maybe<Scalars['String']>;
  description14thMonthPay?: Maybe<Scalars['String']>;
  is13thMonthPayApplicable?: Maybe<Scalars['Boolean']>;
  is14thMonthPayApplicable?: Maybe<Scalars['Boolean']>;
  isIncludedInGross?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  payFrequencyType?: Maybe<Country13th14thMonthPayFrequencyType>;
  schedules?: Maybe<Array<Maybe<Country13th14thMonthPaySchedule>>>;
};

export enum Country13th14thMonthPayFrequencyType {
  INSTALLMENT = 'INSTALLMENT',
  PAYROLL = 'PAYROLL',
}

export type Country13th14thMonthPaySchedule = {
  __typename?: 'Country13th14thMonthPaySchedule';
  country13th14thMonthPayType?: Maybe<Country13th14thMonthPayType>;
  monthlySalaryMultiple?: Maybe<Scalars['Float']>;
  payoutDay?: Maybe<Scalars['Int']>;
  scheduleTimeUnit?: Maybe<ScheduleTimeUnit>;
};

export enum Country13th14thMonthPayType {
  FOURTEENTH_MONTH_PAY = 'FOURTEENTH_MONTH_PAY',
  THIRTEENTH_MONTH_PAY = 'THIRTEENTH_MONTH_PAY',
}

export type CountryCallingCodeDefinition = {
  __typename?: 'CountryCallingCodeDefinition';
  callingCode?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
};

export type CountryCallingCodeFilters = {
  countries?: InputMaybe<Array<InputMaybe<CountryCode>>>;
};

export enum CountryCode {
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALA = 'ALA',
  ALB = 'ALB',
  AND = 'AND',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATF = 'ATF',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  CZE = 'CZE',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESH = 'ESH',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUF = 'GUF',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HMD = 'HMD',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  PYF = 'PYF',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SGS = 'SGS',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  STP = 'STP',
  SUR = 'SUR',
  SVK = 'SVK',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TWN = 'TWN',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  UMI = 'UMI',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE',
}

export type CountryCompensationComponentInput = {
  amount?: InputMaybe<Scalars['Float']>;
  amountType?: InputMaybe<PayAmountType>;
  basedOn?: InputMaybe<Scalars['String']>;
  componentType: ComponentType;
  condition?: InputMaybe<Scalars['String']>;
  currency: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<RateFrequency>;
  isBase: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  key: Scalars['String'];
  label: Scalars['String'];
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  type?: InputMaybe<RateType>;
};

export type CountryCompensationDefinitionInput = {
  components?: InputMaybe<Array<CountryCompensationComponentInput>>;
  contractType: ContractType;
  country: CountryCode;
  id?: InputMaybe<Scalars['ID']>;
  term: ContractTerm;
};

export type CountryCompensationStandards = {
  __typename?: 'CountryCompensationStandards';
  hourlyRates?: Maybe<CountryHourlyRates>;
  paymentFrequency?: Maybe<CountryPaymentFrequency>;
  rateFrequency?: Maybe<CountryRateFrequency>;
};

export type CountryCompliance = {
  __typename?: 'CountryCompliance';
  availableDocuments?: Maybe<Array<Maybe<DocumentTemplate>>>;
  compensationStructureDefinition?: Maybe<CompensationStructureDefinition>;
  /** Priority: country-specific restrictions > global restrictions */
  contractDetailRestrictions?: Maybe<Array<ContractDetailRestriction>>;
  country13th14thMonthPay?: Maybe<Country13th14thMonthPay>;
  countryCode?: Maybe<CountryCode>;
  countryState?: Maybe<State>;
  currencies?: Maybe<Array<Maybe<CurrencyCode>>>;
  financialYearStartMonth?: Maybe<Scalars['Int']>;
  fixedTermRestriction?: Maybe<FixedTermRestrictionDefinition>;
  joiningDateRestriction?: Maybe<ComplianceJoiningDateRestriction>;
  legalEntity?: Maybe<CountryLegalEntity>;
  limitations?: Maybe<Array<Maybe<ComplianceLimitationDefinition>>>;
  /** @deprecated No longer supported */
  memberDataPreFetch?: Maybe<Array<Maybe<DataFieldDefinition>>>;
  memberDataRequirements?: Maybe<Array<Maybe<DataFieldDefinition>>>;
  /** @deprecated No longer supported */
  memberDocumentRequirements?: Maybe<Array<Maybe<LegalDocumentRequirement>>>;
  onboardingFlowEnabled?: Maybe<Scalars['Boolean']>;
  requirements?: Maybe<ComplianceRequirementDefinition>;
  timeOffRequirements?: Maybe<TimeOffRequirements>;
};

export type CountryCompliance_AvailableDocumentsArgs = {
  contractType?: InputMaybe<ContractType>;
  term?: InputMaybe<ContractTerm>;
};

export type CountryCompliance_CompensationStructureDefinitionArgs = {
  term?: InputMaybe<ContractTerm>;
  type?: InputMaybe<ContractType>;
};

export type CountryCompliance_FixedTermRestrictionArgs = {
  filter?: InputMaybe<FixedTermRestrictionFilter>;
};

export type CountryCompliance_JoiningDateRestrictionArgs = {
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type CountryCompliance_LegalEntityArgs = {
  type?: InputMaybe<LegalBindingType>;
};

export type CountryCompliance_MemberDataPreFetchArgs = {
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type CountryCompliance_MemberDataRequirementsArgs = {
  filter?: InputMaybe<MemberDataRequirementsFilter>;
};

export type CountryCompliance_MemberDocumentRequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  filters: RequirementFiltersInput;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type CountryCompliance_RequirementsArgs = {
  contractType?: InputMaybe<ContractType>;
  inputParams?: InputMaybe<RequirementsInputs>;
  term?: InputMaybe<ContractTerm>;
};

export type CountryCompliance_TimeOffRequirementsArgs = {
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  filters?: InputMaybe<TimeOffRequirementFilters>;
  term?: InputMaybe<ContractTerm>;
};

export type CountryComplianceParameterInput = {
  complianceParamTypeId?: InputMaybe<Scalars['ID']>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  countryCode?: InputMaybe<CountryCode>;
  countryState?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['Int']>;
  dependencies?: InputMaybe<Array<ControllingFieldInput>>;
  editable?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  maximum?: InputMaybe<Scalars['Int']>;
  minimum?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<ComplianceParamPeriodUnit>;
};

export type CountryConfig = {
  __typename?: 'CountryConfig';
  country: CountryCode;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  key: Scalars['String'];
  preconditions?: Maybe<Array<CountryConfigPrecondition>>;
  stateCode?: Maybe<Scalars['String']>;
  value: CountryConfigValue;
};

export type CountryConfigPrecondition = {
  __typename?: 'CountryConfigPrecondition';
  contractTerm?: Maybe<ContractTerm>;
  contractType?: Maybe<ContractType>;
};

export type CountryConfigValue =
  | BooleanConfigValue
  | FloatConfigValue
  | IntConfigValue
  | StringConfigValue;

export type CountryContractClauseUpsertInput = {
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  countryCode: CountryCode;
  english: Scalars['String'];
  key: Scalars['String'];
  local?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type CountryCount = {
  __typename?: 'CountryCount';
  count?: Maybe<Scalars['Int']>;
  country?: Maybe<CountryCode>;
};

export type CountryDocument = {
  __typename?: 'CountryDocument';
  file?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<CountryDocumentType>;
};

export type CountryDocumentFilter = {
  stateCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CountryDocumentType>;
};

export enum CountryDocumentType {
  OFFBOARDING_GUIDE = 'OFFBOARDING_GUIDE',
  ONBOARDING_GUIDE = 'ONBOARDING_GUIDE',
  VISA_GUIDE = 'VISA_GUIDE',
}

export type CountryDocumentUpdateInput = {
  country: CountryCode;
  documentId?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CountryDocumentType>;
};

export type CountryEmployeePricing = EmployeePricing & {
  __typename?: 'CountryEmployeePricing';
  country?: Maybe<CountryCode>;
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type CountryFilter = {
  code?: InputMaybe<CountryCode>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type CountryHourlyRateValue = {
  __typename?: 'CountryHourlyRateValue';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CountryHourlyRates = {
  __typename?: 'CountryHourlyRates';
  helpUrl?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<CountryHourlyRateValue>>>;
};

export type CountryLabourStandards = {
  __typename?: 'CountryLabourStandards';
  compensation?: Maybe<CountryCompensationStandards>;
  countryCode?: Maybe<CountryCode>;
  countryStateCode?: Maybe<State>;
  workShift?: Maybe<CountryWorkShiftStandards>;
};

export type CountryLabourStandardsFilter = {
  stateCode?: InputMaybe<Scalars['String']>;
};

export type CountryLegalDataDefinitionUpsertInput = {
  applyTo: LegalDataRequirementApplicability;
  autoCompleteField?: InputMaybe<DataAutoCompleteFieldInput>;
  checkboxField?: InputMaybe<DataCheckboxFieldInput>;
  contractType: ContractType;
  countryCode?: InputMaybe<CountryCode>;
  dateField?: InputMaybe<DataDateFieldInput>;
  dependsOn?: InputMaybe<Array<InputMaybe<ControllingFieldInput>>>;
  description?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  domainType: DomainType;
  dropDownField?: InputMaybe<DataDropDownFieldInput>;
  dropDownTextField?: InputMaybe<DataDropDownTextFieldInput>;
  fetchStage: FetchStage;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isEditable?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label: Scalars['String'];
  textField?: InputMaybe<DataTextFieldInput>;
  type: LegalDataType;
};

export type CountryLegalDataInput = {
  applyTo: LegalDataRequirementApplicability;
  autoCompleteField?: InputMaybe<DataAutoCompleteFieldInput>;
  checkboxField?: InputMaybe<DataCheckboxFieldInput>;
  contractType?: InputMaybe<ContractType>;
  country: CountryCode;
  dateField?: InputMaybe<DataDateFieldInput>;
  dependsOn?: InputMaybe<Array<InputMaybe<ControllingFieldInput>>>;
  description?: InputMaybe<Scalars['String']>;
  domainType?: InputMaybe<DomainType>;
  dropDownField?: InputMaybe<DataDropDownFieldInput>;
  dropDownTextField?: InputMaybe<DataDropDownTextFieldInput>;
  fetchStage: FetchStage;
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  label: Scalars['String'];
  required: Scalars['Boolean'];
  textField?: InputMaybe<DataTextFieldInput>;
  type: LegalDataType;
};

export type CountryLegalEntity = {
  __typename?: 'CountryLegalEntity';
  legalEntity?: Maybe<LegalEntity>;
  type?: Maybe<LegalBindingType>;
};

export type CountryPaymentFrequency = {
  __typename?: 'CountryPaymentFrequency';
  data?: Maybe<Array<Maybe<CountryPaymentFrequencyData>>>;
  defaultValue?: Maybe<PayFrequency>;
  list?: Maybe<Array<Maybe<PayFrequency>>>;
  payDates?: Maybe<Array<Maybe<PayFrequencyDate>>>;
};

export type CountryPaymentFrequencyData = {
  __typename?: 'CountryPaymentFrequencyData';
  payFrequencies?: Maybe<Array<Maybe<PayFrequency>>>;
  rateFrequency?: Maybe<RateFrequency>;
};

export type CountryRateFrequency = {
  __typename?: 'CountryRateFrequency';
  defaultValue?: Maybe<RateFrequency>;
  list?: Maybe<Array<Maybe<RateFrequency>>>;
};

export type CountryWorkShiftStandards = {
  __typename?: 'CountryWorkShiftStandards';
  averageWorkingHoursPerMonth?: Maybe<Scalars['Int']>;
  defaultWorkingHours?: Maybe<WorkingHours>;
  endDay?: Maybe<DayOfWeek>;
  startDay?: Maybe<DayOfWeek>;
  workingHoursInfoText?: Maybe<Scalars['String']>;
};

export enum CountryWorkStatus {
  HAS_VISA = 'HAS_VISA',
  OBTAINED_VISA = 'OBTAINED_VISA',
  REQUIRES_VISA = 'REQUIRES_VISA',
  RESIDENT = 'RESIDENT',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type CreateCompanyInput = {
  address?: InputMaybe<AddressInput>;
  countryCode?: InputMaybe<CountryCode>;
  csmId?: InputMaybe<Scalars['ID']>;
  currency: CurrencyCode;
  displayName: Scalars['String'];
  fxSensitivity?: InputMaybe<FxSensitivity>;
  legalName: Scalars['String'];
  phoneNo?: InputMaybe<Scalars['String']>;
  pricing?: InputMaybe<PricingInput>;
  registrationNo?: InputMaybe<Scalars['String']>;
  salesUserId?: InputMaybe<Scalars['ID']>;
  sfdcAccountNo?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyLegalEntityInput = {
  address: AddressInput;
  capabilities?: InputMaybe<Array<Capability>>;
  companyId: Scalars['ID'];
  legalName: Scalars['String'];
  offeringCodes?: InputMaybe<Array<OfferingCode>>;
  payrollData?: InputMaybe<Array<DataFieldInput>>;
};

export type CreateDemoCyclesInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  untilDate: Scalars['Date'];
};

export type CreateDisputeInput = {
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  originId: Scalars['ID'];
  originType: DisputeOriginType;
  reasonCategory: DisputeReasonCategory;
  reasonSubCategory?: InputMaybe<DisputeReasonSubCategory>;
};

export type CreatePartnerInput = {
  name: Scalars['String'];
  partnerCountries: Array<PartnerCountryInput>;
  partnerUsers?: InputMaybe<Array<CreatePartnerUserInput>>;
};

export type CreatePartnerPayrollInput = {
  country?: InputMaybe<CountryCode>;
  month?: InputMaybe<PayrollMonthInput>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type CreatePartnerPayrollMemberPayInput = {
  amountGross?: InputMaybe<Scalars['Float']>;
  amountNet?: InputMaybe<Scalars['Float']>;
  amountTotalCost?: InputMaybe<Scalars['Float']>;
  contractId?: InputMaybe<Scalars['ID']>;
  contributions?: InputMaybe<
    Array<InputMaybe<CreatePartnerPayrollPayComponentInput>>
  >;
  currency?: InputMaybe<CurrencyCode>;
  deductions?: InputMaybe<
    Array<InputMaybe<CreatePartnerPayrollPayComponentInput>>
  >;
};

export type CreatePartnerPayrollPayComponentInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreatePartnerUserInput = {
  country: CountryCode;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  role: PartnerRole;
  type: PartnerUserType;
};

export type CreatePayrollPartnerInput = {
  name: Scalars['String'];
  partnerCountries: Array<PayrollPartnerCountryInput>;
  partnerUsers?: InputMaybe<Array<CreatePayrollPartnerUserInput>>;
};

export type CreatePayrollPartnerUserInput = {
  country: CountryCode;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  role: PartnerRole;
  type: PartnerUserType;
};

export type CreatePayrollWorkflowRequest = {
  configIds: Array<Scalars['ID']>;
  untilDate: Scalars['Date'];
};

export type CreditNote = {
  __typename?: 'CreditNote';
  amountApplied?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  amountUnapplied?: Maybe<Scalars['Float']>;
  appliedInvoices?: Maybe<Array<Invoice>>;
  company: Company;
  companyPayableId?: Maybe<Scalars['ID']>;
  createdDate?: Maybe<Scalars['Date']>;
  creditNoteNo?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  document?: Maybe<DocumentReadable>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Array<CreditNoteLineItem>>;
  month?: Maybe<Scalars['Int']>;
  reason?: Maybe<CreditNoteReason>;
  reference?: Maybe<Scalars['String']>;
  report?: Maybe<CompanyPayableReport>;
  status?: Maybe<CreditNoteStatus>;
  year?: Maybe<Scalars['Int']>;
};

export type CreditNoteCountByStatus = {
  __typename?: 'CreditNoteCountByStatus';
  count: Scalars['Int'];
  status: CreditNoteStatus;
};

export type CreditNoteFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  createdDateRange?: InputMaybe<DateRange>;
  creditNoteNo?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>;
  reason?: InputMaybe<CreditNoteReason>;
  reference?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CreditNoteStatus>;
};

export type CreditNoteLineItem = {
  __typename?: 'CreditNoteLineItem';
  billingCost?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  type?: Maybe<PayableItemType>;
};

export type CreditNoteQueryInput = {
  filters?: InputMaybe<CreditNoteFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export enum CreditNoteReason {
  DEPOSIT_REFUND = 'DEPOSIT_REFUND',
  DISCOUNTS = 'DISCOUNTS',
  DOUBLE_PAYMENT = 'DOUBLE_PAYMENT',
  EXPENSES_REFUNDS = 'EXPENSES_REFUNDS',
  FREELANCER = 'FREELANCER',
  INSURANCE_CANCELLATION = 'INSURANCE_CANCELLATION',
  INVOICE_CORRECTION = 'INVOICE_CORRECTION',
  INVOICE_VOIDED = 'INVOICE_VOIDED',
  SECOND_INVOICE = 'SECOND_INVOICE',
  WRITE_OFF = 'WRITE_OFF',
}

export enum CreditNoteStatus {
  DELETED = 'DELETED',
  /** @deprecated We don't have draft status in credit notes. */
  DRAFT = 'DRAFT',
  FULLY_APPLIED = 'FULLY_APPLIED',
  OPEN = 'OPEN',
}

export type CreditNoteTransaction = FinancialTransaction & {
  __typename?: 'CreditNoteTransaction';
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type CreditNotesResult = {
  __typename?: 'CreditNotesResult';
  data?: Maybe<Array<CreditNote>>;
  pageResult?: Maybe<PageResult>;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<CurrencyCode>;
  convert?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type Currency_ConvertArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  forDate?: InputMaybe<Scalars['Date']>;
  source?: InputMaybe<CurrencyConversionSource>;
  to?: InputMaybe<CurrencyCode>;
};

export enum CurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNH = 'CNH',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SPL = 'SPL',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TVD = 'TVD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  UYI = 'UYI',
  UYU = 'UYU',
  UYW = 'UYW',
  UZS = 'UZS',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWD = 'ZWD',
  ZWL = 'ZWL',
}

export type CurrencyConversionParams = {
  amount: Scalars['Float'];
  from: CurrencyCode;
  to: CurrencyCode;
};

export enum CurrencyConversionSource {
  FALLBACK = 'FALLBACK',
  INTERNALLY_FIXED = 'INTERNALLY_FIXED',
  ON_DEMAND = 'ON_DEMAND',
}

export enum CustomPayrollReportCategory {
  STATUTORY = 'STATUTORY',
}

export type CustomPayrollReportConfig = {
  __typename?: 'CustomPayrollReportConfig';
  country: CountryCode;
  id: Scalars['ID'];
  reportCategory: CustomPayrollReportCategory;
  reportCode: Scalars['String'];
  reportFormat?: Maybe<CustomPayrollReportFormat>;
  reportFrequency: CustomPayrollReportFrequency;
  reportName: Scalars['String'];
  status: CustomPayrollReportConfigStatus;
};

export type CustomPayrollReportConfigCreateInput = {
  country: CountryCode;
  reportCategory: CustomPayrollReportCategory;
  reportCode: Scalars['String'];
  reportFormat?: InputMaybe<CustomPayrollReportFormat>;
  reportFrequency: CustomPayrollReportFrequency;
  reportName: Scalars['String'];
};

export type CustomPayrollReportConfigFilter = {
  country?: InputMaybe<CountryCode>;
  reportCategory?: InputMaybe<CustomPayrollReportCategory>;
  reportFrequency?: InputMaybe<CustomPayrollReportFrequency>;
};

export enum CustomPayrollReportConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CustomPayrollReportConfigUpdateInput = {
  id: Scalars['ID'];
  status: CustomPayrollReportConfigStatus;
};

export type CustomPayrollReportCreateInput = {
  companyId: Scalars['ID'];
  employingLegalEntityId: Scalars['ID'];
  files: Array<CustomPayrollReportFileInput>;
  financialYearFrom: Scalars['Date'];
  financialYearTo: Scalars['Date'];
  month?: InputMaybe<MonthYearInput>;
  quarter?: InputMaybe<Scalars['Int']>;
  reportCategory: CustomPayrollReportCategory;
  reportFrequency: CustomPayrollReportFrequency;
};

export type CustomPayrollReportDetails = {
  company?: Maybe<Company>;
  country?: Maybe<CountryCode>;
  employingLegalEntity?: Maybe<LegalEntity>;
  files: Array<CustomPayrollReportFile>;
  financialYearFrom?: Maybe<Scalars['Date']>;
  financialYearTo?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  month?: Maybe<MonthYear>;
  quarter?: Maybe<Scalars['Int']>;
  reportCategory: CustomPayrollReportCategory;
  reportFrequency?: Maybe<CustomPayrollReportFrequency>;
};

export type CustomPayrollReportFile = {
  __typename?: 'CustomPayrollReportFile';
  config: CustomPayrollReportConfig;
  file: PayrollFile;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type CustomPayrollReportFileInput = {
  configId: Scalars['ID'];
  fileName: Scalars['String'];
};

export type CustomPayrollReportFileUpdateInput = {
  files: Array<CustomPayrollReportFileInput>;
  reportId: Scalars['ID'];
};

export type CustomPayrollReportFilter = {
  companyIds: Array<Scalars['ID']>;
  countries: Array<CountryCode>;
  employingLegalEntityIds: Array<Scalars['ID']>;
  financialYearFrom?: InputMaybe<DateRange>;
  financialYearTo?: InputMaybe<DateRange>;
};

export enum CustomPayrollReportFormat {
  CSV = 'CSV',
  PDF = 'PDF',
  TXT = 'TXT',
  XLS = 'XLS',
}

export enum CustomPayrollReportFrequency {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YTD = 'YTD',
}

export type CustomPayrollReportsResponse = {
  __typename?: 'CustomPayrollReportsResponse';
  page: PageResult;
  reports: Array<CustomPayrollReportDetails>;
};

export type CustomerIntegration = {
  __typename?: 'CustomerIntegration';
  category?: Maybe<PlatformCategory>;
  connected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isPositionDropdownListEnabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomerIntegrationDefinition = {
  __typename?: 'CustomerIntegrationDefinition';
  category?: Maybe<PlatformCategory>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logoLink?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  squareLogoLink?: Maybe<Scalars['String']>;
};

export type CustomerIntegrationFilter = {
  category?: InputMaybe<PlatformCategory>;
};

export type CustomerPayrollWorkflowRequest = {
  action: Action;
  companyPayrollCycleIds: Array<Scalars['ID']>;
};

export type CustomerPayrollWorkflowResponse = {
  __typename?: 'CustomerPayrollWorkflowResponse';
  companyPayrollCycles: Array<CompanyPayrollCycle>;
};

export type CycleContractSnapshotsResponse = {
  __typename?: 'CycleContractSnapshotsResponse';
  contracts?: Maybe<Array<ContractSnapshot>>;
  pageResult?: Maybe<PageResult>;
};

export type CycleContractsFilter = {
  activationDateRange?: InputMaybe<DateRange>;
  companyNames?: InputMaybe<Array<Scalars['String']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  endDateRange?: InputMaybe<DateRange>;
  excludedPayrollContractExceptionTypes?: InputMaybe<
    Array<PayrollContractExceptionType>
  >;
  excludedPayrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
  hasExceptions?: InputMaybe<Scalars['Boolean']>;
  memberNames?: InputMaybe<Array<Scalars['String']>>;
  payrollContractExceptionTypes?: InputMaybe<
    Array<PayrollContractExceptionType>
  >;
  payrollContractTypes?: InputMaybe<Array<PayrollContractType>>;
  payrollFormStatuses?: InputMaybe<Array<PayrollFormsUploadStatus>>;
  startDateRange?: InputMaybe<DateRange>;
};

export type DataAutoCompleteFieldInput = {
  optionListType: Scalars['String'];
};

export type DataChange = {
  __typename?: 'DataChange';
  content?: Maybe<DataChangeContent>;
  createdBy?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  fieldCategory?: Maybe<Scalars['String']>;
  fieldCategoryObject?: Maybe<FieldCategoryObject>;
  id?: Maybe<Scalars['ID']>;
  metaData?: Maybe<DataChangeMetaData>;
  status?: Maybe<DataChangeStatus>;
};

export type DataChangeCompensationContent = {
  __typename?: 'DataChangeCompensationContent';
  newValue?: Maybe<FixedPayComponent>;
  oldValue?: Maybe<FixedPayComponent>;
};

export type DataChangeContent =
  | DataChangeCompensationContent
  | DataChangeDateContent
  | DataChangeLegalContent
  | DataChangeStringContent;

export type DataChangeDateContent = {
  __typename?: 'DataChangeDateContent';
  newValue?: Maybe<Scalars['DateTime']>;
  oldValue?: Maybe<Scalars['DateTime']>;
};

export type DataChangeFilter = {
  company?: InputMaybe<Scalars['String']>;
  contractID?: InputMaybe<Scalars['ID']>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  country?: InputMaybe<CountryCode>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eorPartnerId?: InputMaybe<Scalars['ID']>;
  field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fieldCategory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isMultiplierEntity?: InputMaybe<Scalars['Boolean']>;
  memberName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DataChangeLegalContent = {
  __typename?: 'DataChangeLegalContent';
  newValue?: Maybe<LegalData>;
  oldValue?: Maybe<LegalData>;
};

export type DataChangeMetaData = {
  __typename?: 'DataChangeMetaData';
  country?: Maybe<CountryCode>;
};

export type DataChangePageResults = {
  __typename?: 'DataChangePageResults';
  data?: Maybe<Array<Maybe<DataChange>>>;
  pageResult?: Maybe<PageResult>;
};

export enum DataChangeStatus {
  NOT_VIEWED = 'NOT_VIEWED',
  VIEWED = 'VIEWED',
}

export type DataChangeStringContent = {
  __typename?: 'DataChangeStringContent';
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export type DataCheckboxFieldInput = {
  defaultValue: Scalars['Boolean'];
};

export type DataDateFieldInput = {
  defaultValue?: InputMaybe<Scalars['Date']>;
  maxDate?: InputMaybe<Scalars['Date']>;
  minDate?: InputMaybe<Scalars['Date']>;
};

export type DataDropDownFieldInput = {
  values: Array<Scalars['String']>;
};

export type DataDropDownTextFieldInput = {
  defaultText?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<LegalDataType>;
  values: Array<Scalars['String']>;
};

/**
 * Defines a data field definition so the UI can render accordingly.
 * The intention is to replace all the dynamic UI generation definitions by this
 * i.e. LegalDataRequirement, LegalDocument etc...
 */
export type DataFieldDefinition = {
  __typename?: 'DataFieldDefinition';
  dataType?: Maybe<DataFieldType>;
  dependsOn?: Maybe<Array<ControllingField>>;
  description?: Maybe<Scalars['String']>;
  domainType?: Maybe<DomainType>;
  editable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type DataFieldInput = {
  documents?: InputMaybe<Array<Scalars['Upload']>>;
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type DataFieldType =
  | AutoCompleteField
  | CheckboxField
  | DateField
  | DropDownField
  | DropDownTextField
  | FileField
  | TextField;

export type DataFieldValue = {
  __typename?: 'DataFieldValue';
  documents?: Maybe<Array<Maybe<File>>>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DataTextFieldInput = {
  defaultValue?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['String']>;
  minLength?: InputMaybe<Scalars['String']>;
  pattern?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LegalDataType>;
};

export type DateField = {
  __typename?: 'DateField';
  defaultValue?: Maybe<Scalars['Date']>;
  maxDate?: Maybe<Scalars['Date']>;
  minDate?: Maybe<Scalars['Date']>;
};

export type DateFieldInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Date']>;
};

export type DateParam = {
  __typename?: 'DateParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['DateTime']>;
};

export type DateRange = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DateRangeOutput = {
  __typename?: 'DateRangeOutput';
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export enum DayType {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  REGULAR_DAY = 'REGULAR_DAY',
  REGULAR_HOLIDAY = 'REGULAR_HOLIDAY',
  REST_DAY = 'REST_DAY',
  REST_DAY_REGULAR_HOLIDAY = 'REST_DAY_REGULAR_HOLIDAY',
  REST_DAY_SPECIAL_HOLIDAY = 'REST_DAY_SPECIAL_HOLIDAY',
  SPECIAL_HOLIDAY = 'SPECIAL_HOLIDAY',
}

export type Deduction = {
  __typename?: 'Deduction';
  deductionDefinitionId: Scalars['ID'];
  unit?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type DeductionDefinition = {
  __typename?: 'DeductionDefinition';
  contributionBy?: Maybe<ContributionBy>;
  deductionConstraint?: Maybe<DeductionRequirement>;
  /** @deprecated Use deductionConstraint instead. */
  deductionRequirement?: Maybe<DeductionRequirement>;
  /** @deprecated It is no longer needed, as we handle description in Locize directly. */
  description?: Maybe<Scalars['String']>;
  groupKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
};

export type DeductionInput = {
  deductionDefinitionId: Scalars['ID'];
  unit?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type DeductionRequirement = {
  name?: Maybe<Scalars['String']>;
  /** @deprecated use 'name' property instead. */
  type?: Maybe<DeductionRequirementType>;
};

/** Deprecated. Use string instead. */
export enum DeductionRequirementType {
  /** @deprecated No longer supported */
  EMPLOYEE_MATCH_LIMIT = 'EMPLOYEE_MATCH_LIMIT',
  /** @deprecated Whole enum is deprecated. Instead of using the enum value the string is going to be used. */
  EMPLOYER_MATCH_LIMIT = 'EMPLOYER_MATCH_LIMIT',
  /** @deprecated Whole enum is deprecated. Instead of using the enum value the string is going to be used. */
  NOT_USED = 'NOT_USED',
}

export enum DeductionUnit {
  FIXED_AMOUNT_PER_MONTH = 'FIXED_AMOUNT_PER_MONTH',
  PERCENTAGE = 'PERCENTAGE',
}

export type DeductionValue = {
  __typename?: 'DeductionValue';
  unit: DeductionUnit;
  value: Scalars['Float'];
};

export type DefaultFinancialTransaction = FinancialTransaction & {
  __typename?: 'DefaultFinancialTransaction';
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type Department = {
  __typename?: 'Department';
  description?: Maybe<Scalars['String']>;
  employees?: Maybe<OrgDirectory>;
  hod?: Maybe<HeadOfDepartment>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<DepartmentStatus>;
};

export type Department_EmployeesArgs = {
  filters?: InputMaybe<DepartmentEmployeeFilters>;
};

export type DepartmentAssignEmployeesInput = {
  departmentId: Scalars['ID'];
  employeeContractIds: Array<Scalars['ID']>;
  employeeManagerIds: Array<Scalars['ID']>;
};

export type DepartmentCreateInput = {
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  hod?: InputMaybe<HeadOfDepartmentInput>;
  name: Scalars['String'];
};

export type DepartmentDeleteInput = {
  id: Scalars['ID'];
};

export type DepartmentEmployeeFilters = {
  contractTypes?: InputMaybe<Array<ContractType>>;
  countries?: InputMaybe<Array<CountryCode>>;
  designations?: InputMaybe<Array<Scalars['String']>>;
};

export type DepartmentFilters = {
  id?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<DepartmentStatus>>;
};

export enum DepartmentStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
}

export type DepartmentUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  hod?: InputMaybe<HeadOfDepartmentInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DepositPayableFilters = {
  invoiceStatuses?: InputMaybe<Array<InputMaybe<InvoiceStatus>>>;
};

export type DepositTerm = {
  __typename?: 'DepositTerm';
  maxDeposit?: Maybe<Scalars['Float']>;
  termInMonths?: Maybe<Scalars['Int']>;
};

export type DepositTermInput = {
  maxDeposit?: InputMaybe<Scalars['Float']>;
  termInMonths?: InputMaybe<Scalars['Int']>;
};

export type Designation = {
  __typename?: 'Designation';
  jobDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DesignationInput = {
  jobDescription: Scalars['String'];
  name: Scalars['String'];
};

export type DirectReport = {
  __typename?: 'DirectReport';
  contract?: Maybe<Contract>;
  manager?: Maybe<Manager>;
};

export type DirectReportValidationResult = {
  __typename?: 'DirectReportValidationResult';
  failedEntries?: Maybe<OrgDirectory>;
  successEntries?: Maybe<OrgDirectory>;
};

export type Discount = {
  __typename?: 'Discount';
  discountPolicy: DiscountPolicy;
  discountTerms: Array<DiscountTermV2>;
  id: Scalars['ID'];
};

export type DiscountInput = {
  discountPolicy: DiscountPolicy;
  discountTerms: Array<DiscountTermInputV2>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum DiscountPolicy {
  ADDITIVE = 'ADDITIVE',
  APPLY_FIRST = 'APPLY_FIRST',
  APPLY_LARGEST = 'APPLY_LARGEST',
  APPLY_LAST = 'APPLY_LAST',
}

export type DiscountRule =
  | DiscountRuleCountry
  | DiscountRuleDeadlineBased
  | DiscountRuleMemberBased
  | DiscountRuleRegion
  | DiscountRuleSalaryBased
  | DiscountRuleTimeBound;

export type DiscountRuleCountry = {
  __typename?: 'DiscountRuleCountry';
  anyCountry?: Maybe<Scalars['Boolean']>;
  countries: Array<Maybe<CountryCode>>;
  excludedCountries?: Maybe<Array<CountryCode>>;
  id: Scalars['ID'];
};

export type DiscountRuleCountryInput = {
  anyCountry: Scalars['Boolean'];
  countries: Array<InputMaybe<CountryCode>>;
  excludedCountries?: InputMaybe<Array<CountryCode>>;
};

export type DiscountRuleDeadlineBased = {
  __typename?: 'DiscountRuleDeadlineBased';
  deadline?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  type?: Maybe<DiscountRuleDeadlineType>;
};

export type DiscountRuleDeadlineBasedInput = {
  deadline: Scalars['Date'];
  deadlineType: DiscountRuleDeadlineType;
};

export enum DiscountRuleDeadlineType {
  MEMBER_ONBOARDED = 'MEMBER_ONBOARDED',
  MSA_SIGNED = 'MSA_SIGNED',
}

export type DiscountRuleInput = {
  countryBasedDiscount?: InputMaybe<DiscountRuleCountryInput>;
  deadlineBasedDiscount?: InputMaybe<DiscountRuleDeadlineBasedInput>;
  globalDiscount?: InputMaybe<Scalars['Boolean']>;
  memberBasedDiscount?: InputMaybe<DiscountRuleMemberBasedInput>;
  regionBasedDiscount?: InputMaybe<DiscountRuleRegionInput>;
  salaryBasedDiscount?: InputMaybe<DiscountRuleSalaryBasedInput>;
  timeBasedDiscount?: InputMaybe<DiscountRuleTimeBoundInput>;
};

export type DiscountRuleMemberBased = {
  __typename?: 'DiscountRuleMemberBased';
  id: Scalars['ID'];
  memberLowerBound?: Maybe<Scalars['Int']>;
  memberUpperBound?: Maybe<Scalars['Int']>;
};

export type DiscountRuleMemberBasedInput = {
  memberLowerBound?: InputMaybe<Scalars['Int']>;
  memberUpperBound?: InputMaybe<Scalars['Int']>;
};

export type DiscountRuleRegion = {
  __typename?: 'DiscountRuleRegion';
  id: Scalars['ID'];
  region?: Maybe<Region>;
};

export type DiscountRuleRegionInput = {
  region?: InputMaybe<Region>;
};

export type DiscountRuleSalaryBased = {
  __typename?: 'DiscountRuleSalaryBased';
  id: Scalars['ID'];
  salaryLowerBound?: Maybe<Scalars['Float']>;
  salaryUpperBound?: Maybe<Scalars['Float']>;
};

export type DiscountRuleSalaryBasedInput = {
  salaryLowerBound?: InputMaybe<Scalars['Float']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']>;
};

export type DiscountRuleTimeBound = {
  __typename?: 'DiscountRuleTimeBound';
  fromMonth?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  toMonth?: Maybe<Scalars['Int']>;
};

export type DiscountRuleTimeBoundInput = {
  fromMonth?: InputMaybe<Scalars['Int']>;
  toMonth?: InputMaybe<Scalars['Int']>;
};

export type DiscountTerm = {
  __typename?: 'DiscountTerm';
  discount: Scalars['Float'];
  discountRules?: Maybe<Array<Maybe<DiscountRule>>>;
  discountType: DiscountType;
  id?: Maybe<Scalars['ID']>;
};

export type DiscountTermCondition = {
  __typename?: 'DiscountTermCondition';
  condition?: Maybe<DiscountTermConditionValue>;
  id: Scalars['ID'];
  type?: Maybe<DiscountTermConditionType>;
};

export type DiscountTermConditionInput = {
  condition?: InputMaybe<DiscountTermConditionValueInput>;
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<DiscountTermConditionType>;
};

export enum DiscountTermConditionType {
  OFFERING_BUNDLE = 'OFFERING_BUNDLE',
  PROMOTIONAL_PERIOD = 'PROMOTIONAL_PERIOD',
}

export type DiscountTermConditionValue =
  | OfferingBundleDiscountTermCondition
  | PromotionalPeriodDiscountTermCondition;

export type DiscountTermConditionValueInput = {
  offeringBundle?: InputMaybe<OfferingBundleDiscountTermConditionInput>;
  promotionalPeriod?: InputMaybe<PromotionalPeriodDiscountTermConditionInput>;
};

export type DiscountTermInput = {
  discount: Scalars['Float'];
  discountRules?: InputMaybe<Array<InputMaybe<DiscountRuleInput>>>;
  discountType: DiscountType;
};

export type DiscountTermInputV2 = {
  conditions: Array<DiscountTermConditionInput>;
  customDiscount?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  type: DiscountTermType;
  value?: InputMaybe<Scalars['Float']>;
};

export enum DiscountTermType {
  CUSTOM = 'CUSTOM',
  FIXED_PRICE = 'FIXED_PRICE',
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
}

export type DiscountTermV2 = {
  __typename?: 'DiscountTermV2';
  conditions: Array<DiscountTermCondition>;
  customDiscount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: DiscountTermType;
  value?: Maybe<Scalars['Float']>;
};

export enum DiscountType {
  /** The 'discount' field contains the absolute discount value. i.e. fee = 500, discount 50, discounted value = 450 [500 - 50] */
  ABSOLUTE = 'ABSOLUTE',
  /** The 'discount' field contains the flat price value. i.e. fee = 500, discount 50, discounted value = 50 [regardless the fee] */
  FLAT_PRICE = 'FLAT_PRICE',
  /** The 'discount' field contains the percentage value. i.e. fee = 500, discount 50, discounted value = 250 [500 - (500 * 50%)] */
  PERCENT = 'PERCENT',
}

export type Dispute = {
  __typename?: 'Dispute';
  description?: Maybe<Scalars['String']>;
  origin?: Maybe<DisputeOrigin>;
  originId: Scalars['ID'];
  originType: DisputeOriginType;
  reasonCategory?: Maybe<DisputeReasonCategory>;
  reasonSubCategory?: Maybe<DisputeReasonSubCategory>;
  status: DisputeStatus;
};

export type DisputeFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  originType: DisputeOriginType;
  statuses?: InputMaybe<Array<DisputeStatus>>;
};

export type DisputeOrigin = CompanyPayable;

export enum DisputeOriginType {
  COMPANY_PAYABLE = 'COMPANY_PAYABLE',
}

export enum DisputeReasonCategory {
  INVOICE_INCORRECT_AMOUNT = 'INVOICE_INCORRECT_AMOUNT',
  INVOICE_INCORRECT_FORMAT = 'INVOICE_INCORRECT_FORMAT',
  INVOICE_QUERY = 'INVOICE_QUERY',
}

export enum DisputeReasonSubCategory {
  INVOICE_BILLING_CURRENCY_INCORRECT = 'INVOICE_BILLING_CURRENCY_INCORRECT',
  INVOICE_MANAGEMENT_FEE_INCORRECT = 'INVOICE_MANAGEMENT_FEE_INCORRECT',
  INVOICE_OTHER_FEES = 'INVOICE_OTHER_FEES',
  INVOICE_SERVICE_AMOUNT_INCORRECT = 'INVOICE_SERVICE_AMOUNT_INCORRECT',
}

export type DisputeResponse = {
  __typename?: 'DisputeResponse';
  data?: Maybe<Array<Dispute>>;
  page?: Maybe<PageResult>;
};

export enum DisputeStatus {
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  RESOLVED = 'RESOLVED',
}

export type Document = {
  __typename?: 'Document';
  contentType?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  uploadUrl?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Document>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  viewUrl?: Maybe<Scalars['String']>;
};

export type DocumentData = {
  __typename?: 'DocumentData';
  document?: Maybe<DocumentReadable>;
  key?: Maybe<Scalars['String']>;
};

export type DocumentKeyValueInput = {
  documentId?: InputMaybe<Scalars['ID']>;
  file?: InputMaybe<Scalars['Upload']>;
  key: Scalars['String'];
};

export type DocumentReadable = File & {
  __typename?: 'DocumentReadable';
  blob?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  htmlPreview?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  key?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  usedScope?: Maybe<ComplianceDefinitionScope>;
};

export enum DomainType {
  LEGAL_DATA = 'LEGAL_DATA',
  LEGAL_ENTITY_PAYROLL_BANK = 'LEGAL_ENTITY_PAYROLL_BANK',
  LEGAL_ENTITY_PAYROLL_BASIC_INFO = 'LEGAL_ENTITY_PAYROLL_BASIC_INFO',
  LEGAL_ENTITY_PAYROLL_CONTACT = 'LEGAL_ENTITY_PAYROLL_CONTACT',
  LEGAL_ENTITY_PAYROLL_DOCUMENT = 'LEGAL_ENTITY_PAYROLL_DOCUMENT',
  LEGAL_ENTITY_PAYROLL_INSURANCE = 'LEGAL_ENTITY_PAYROLL_INSURANCE',
  LEGAL_ENTITY_PAYROLL_TAX = 'LEGAL_ENTITY_PAYROLL_TAX',
  MEMBER_DATA = 'MEMBER_DATA',
}

export type DownloadCompanyPayableReportInput = {
  companyId: Scalars['ID'];
  payableMonth: PayableMonthInput;
  type: CompanyPayableReportType;
};

export type DropDownField = {
  __typename?: 'DropDownField';
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DropDownTextField = {
  __typename?: 'DropDownTextField';
  defaultText?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Duration = {
  __typename?: 'Duration';
  length?: Maybe<Scalars['Float']>;
  unit?: Maybe<TimeUnit>;
};

export type EorEmployeesToSyncResult = {
  __typename?: 'EOREmployeesToSyncResult';
  numberOfEmployees?: Maybe<Scalars['Int']>;
};

export type EorPartnerCountry = PartnerCountry & {
  __typename?: 'EORPartnerCountry';
  capability?: Maybe<PartnerCapability>;
  country?: Maybe<CountryCode>;
  entity?: Maybe<LegalEntity>;
  id?: Maybe<Scalars['ID']>;
  isMultiplierEntity?: Maybe<Scalars['Boolean']>;
  memberContracts?: Maybe<Array<Maybe<Contract>>>;
  operator?: Maybe<Array<Maybe<PartnerUser>>>;
  partner?: Maybe<Partner>;
};

export type EsopGrant = Grant & {
  __typename?: 'ESOPGrant';
  condition?: Maybe<Scalars['String']>;
  document?: Maybe<File>;
  grantDate: Scalars['Date'];
  id: Scalars['ID'];
  schedule: GrantSchedule;
  /** @deprecated No longer supported */
  value?: Maybe<GrantValue>;
  values?: Maybe<Array<GrantValue>>;
};

export type Education = {
  __typename?: 'Education';
  degree?: Maybe<Scalars['String']>;
  gpa?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['String']>;
  lastSchoolAttended?: Maybe<Scalars['String']>;
  yearOfPassing?: Maybe<Scalars['Date']>;
};

export type EducationInput = {
  degree: Scalars['String'];
  gpa?: InputMaybe<Scalars['Float']>;
  grade?: InputMaybe<Scalars['String']>;
  lastSchoolAttended: Scalars['String'];
  yearOfPassing: Scalars['Date'];
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EmailAddressInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type EmailAddressParam = {
  __typename?: 'EmailAddressParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<EmailAddress>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  defaultSubject?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  /** @deprecated Use the document */
  file?: Maybe<DocumentReadable>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  templateType?: Maybe<Scalars['String']>;
};

export type EmailTemplateUpsertInput = {
  defaultSubject: Scalars['String'];
  file: Scalars['Upload'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  templateType: Scalars['String'];
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumber>;
  relationship?: Maybe<Relationship>;
};

export type EmergencyContactInput = {
  mobileNo: PhoneNumberInput;
  name: Scalars['String'];
  relationship?: InputMaybe<Relationship>;
};

export type EmergencyPointOfContact = Person & {
  __typename?: 'EmergencyPointOfContact';
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  userId?: Maybe<Scalars['String']>;
};

export type EmergencyPointOfContactInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerUserId?: InputMaybe<Scalars['ID']>;
};

export type Employee = {
  __typename?: 'Employee';
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type EmployeeDataChanges = {
  __typename?: 'EmployeeDataChanges';
  bankDetails?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['Int']>;
};

export type EmployeeDataChangesV2 = {
  __typename?: 'EmployeeDataChangesV2';
  bankDetails?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['Int']>;
};

export type EmployeePricing = {
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type EmployeePricingDefault = {
  __typename?: 'EmployeePricingDefault';
  employeePricings?: Maybe<Array<Maybe<EmployeePricing>>>;
  globalPricing?: Maybe<Array<Maybe<GlobalPricing>>>;
};

export type Equipment = {
  __typename?: 'Equipment';
  companyId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<EquipmentProcurementDocument>>;
  dynamicDetails?: Maybe<Array<Maybe<EquipmentDetail>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  purchasedOn?: Maybe<Scalars['Date']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<EquipmentStatus>;
  type?: Maybe<Scalars['String']>;
};

export type EquipmentDetail = {
  __typename?: 'EquipmentDetail';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type EquipmentDownloadResponse = {
  __typename?: 'EquipmentDownloadResponse';
  file?: Maybe<DocumentReadable>;
  result: TaskResponse;
};

export type EquipmentFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  equipmentIds?: InputMaybe<Array<Scalars['ID']>>;
  procurementIds?: InputMaybe<Array<Scalars['ID']>>;
  statuses?: InputMaybe<Array<EquipmentStatus>>;
};

export type EquipmentProcurement = {
  __typename?: 'EquipmentProcurement';
  companyId?: Maybe<Scalars['ID']>;
  completedOn?: Maybe<Scalars['Date']>;
  contractId?: Maybe<Scalars['ID']>;
  currencyCode?: Maybe<CurrencyCode>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Array<EquipmentProcurementItem>>;
  requestedOn: Scalars['Date'];
  status?: Maybe<EquipmentProcurementStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type EquipmentProcurementDocument = {
  __typename?: 'EquipmentProcurementDocument';
  document: DocumentReadable;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type EquipmentProcurementFilter = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  procurementIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EquipmentProcurementInput = {
  clients?: InputMaybe<Array<ClientDetail>>;
  detail?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EquipmentProcurementItem = {
  __typename?: 'EquipmentProcurementItem';
  amount?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export enum EquipmentProcurementStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  REQUESTED = 'REQUESTED',
}

export type EquipmentQuotationInput = {
  brand?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  detail?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum EquipmentStatus {
  ASSIGNED = 'ASSIGNED',
  EXPIRED = 'EXPIRED',
  FREE = 'FREE',
}

export type Expense = {
  __typename?: 'Expense';
  approverInfos?: Maybe<Array<ItemApproverInfo>>;
  /** @deprecated Legacy field. No implementations or usages found in the code */
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  changes?: Maybe<Array<Maybe<ExpenseChange>>>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<ExpenseItem>>>;
  payrollCutOffDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ExpenseStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  /** @deprecated Hardcoded with USD. No usages. Seems a legacy one. BE still calculates and returns this but will be removed */
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type Expense_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ExpenseStatus>;
};

export type ExpenseAmountInfo = {
  __typename?: 'ExpenseAmountInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export type ExpenseAmountInfoInput = {
  amount: Scalars['Float'];
  currency: CurrencyCode;
};

export type ExpenseAmountLimit = {
  __typename?: 'ExpenseAmountLimit';
  basis?: Maybe<ExpenseAmountLimitBasis>;
  currency?: Maybe<CurrencyCode>;
  limit?: Maybe<Scalars['Float']>;
  remaining?: Maybe<Scalars['Float']>;
};

export enum ExpenseAmountLimitBasis {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type ExpenseApprovalItem = ApprovalItem & {
  __typename?: 'ExpenseApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  expenseId?: Maybe<Scalars['ID']>;
  expenseItem?: Maybe<Expense>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<ApprovalItemStatus>;
};

export type ExpenseCategory = {
  __typename?: 'ExpenseCategory';
  glCode?: Maybe<CompanyGlCode>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ExpenseCategoryFilter = {
  contractId?: InputMaybe<Scalars['ID']>;
};

export type ExpenseChange = {
  __typename?: 'ExpenseChange';
  actionedBy?: Maybe<Person>;
  actionedOn?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<ExpenseStatus>;
};

export type ExpenseCreateInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  expenseId?: InputMaybe<Scalars['ID']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  items: Array<InputMaybe<ExpenseItemInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseDateRange = {
  __typename?: 'ExpenseDateRange';
  end?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  threshold?: Maybe<Scalars['Int']>;
  unit?: Maybe<ExpenseDateThresholdUnit>;
};

export enum ExpenseDateThresholdUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type ExpenseFilters = {
  companyIds: Array<Scalars['ID']>;
  contractIds: Array<Scalars['ID']>;
  contractStatus?: InputMaybe<ContractStatus>;
  /** @deprecated contractType is deprecated. Use contractTypes instead. */
  contractType?: InputMaybe<ContractType>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  country: Array<CountryCode>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  expenseIds: Array<Scalars['ID']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** @deprecated status is deprecated. Use statuses instead. */
  status?: InputMaybe<ExpenseStatus>;
  statuses?: InputMaybe<Array<ExpenseStatus>>;
};

export type ExpenseItem = {
  __typename?: 'ExpenseItem';
  amount?: Maybe<Scalars['Float']>;
  amountInForeignCurrency?: Maybe<ExpenseAmountInfo>;
  /** @deprecated Hardcoded with USD. No usages. Seems a legacy one. BE still calculates and returns this but will be removed */
  amountInFunctionalCurrency?: Maybe<Scalars['Float']>;
  /** @deprecated As part of 86cvptuja/86cvptv6d. Use `categoryV2` instead */
  category?: Maybe<Scalars['String']>;
  categoryV2?: Maybe<ExpenseCategory>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Found no usages. All existing records have it null. */
  merchant?: Maybe<Scalars['String']>;
  receipts?: Maybe<Array<Maybe<DocumentReadable>>>;
  receiptsInForeignCurrency?: Maybe<Array<DocumentReadable>>;
  title?: Maybe<Scalars['String']>;
};

export type ExpenseItemInput = {
  amount: Scalars['Float'];
  amountInForeignCurrency?: InputMaybe<ExpenseAmountInfoInput>;
  /** @deprecated As part of 86cvptuja/86cvptv6d. Use `categoryId` instead */
  category?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  /** @deprecated Found no usages. All existing records have it null. */
  merchant?: InputMaybe<Scalars['String']>;
  receipts?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  receiptsInForeignCurrency?: InputMaybe<Array<Scalars['Upload']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseMemberPayable = MemberPayable & {
  __typename?: 'ExpenseMemberPayable';
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationFrequency?: Maybe<RateFrequency>;
  durationValue?: Maybe<Scalars['Float']>;
  expense?: Maybe<Expense>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ExpenseMemberPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type ExpenseMemberPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type ExpenseMemberPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export type ExpenseReportInput = {
  category: Array<InputMaybe<Scalars['String']>>;
  country: Array<InputMaybe<CountryCode>>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  status?: InputMaybe<ExpenseStatus>;
};

export type ExpenseSaveAsDraftInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  items: Array<InputMaybe<ExpenseItemInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseSnapshot = {
  __typename?: 'ExpenseSnapshot';
  currency?: Maybe<CurrencyCode>;
  expenseId: Scalars['ID'];
  expenseTitle?: Maybe<Scalars['String']>;
  status?: Maybe<ExpenseStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export enum ExpenseSource {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export enum ExpenseStatus {
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  TO_BE_PAID = 'TO_BE_PAID',
}

export type ExpenseSubmitInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  expenseId?: InputMaybe<Scalars['ID']>;
  items: Array<InputMaybe<ExpenseItemInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseSummary = {
  __typename?: 'ExpenseSummary';
  paid?: Maybe<Array<Scalars['ID']>>;
  processing?: Maybe<Array<Scalars['ID']>>;
  revoked?: Maybe<Array<Scalars['ID']>>;
  toBePaid?: Maybe<Array<Scalars['ID']>>;
};

export type ExpenseUpdateBulkResponse = {
  __typename?: 'ExpenseUpdateBulkResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export type ExpenseUpdateInput = {
  expenseId: Scalars['ID'];
  items?: InputMaybe<Array<InputMaybe<ExpenseItemInput>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExpenseValidationInfo = {
  __typename?: 'ExpenseValidationInfo';
  amountLimit?: Maybe<ExpenseAmountLimit>;
  dateLimit?: Maybe<ExpenseDateRange>;
};

export type ExpenseValidationInfoInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  expenseDate?: InputMaybe<Scalars['Date']>;
};

export type ExpensesResponse = {
  __typename?: 'ExpensesResponse';
  expenses?: Maybe<Array<Maybe<Expense>>>;
  page?: Maybe<PageResult>;
};

export enum ExportPayrollReportType {
  COUNTRY_DETAILED = 'COUNTRY_DETAILED',
  COUNTRY_SUMMARIZED = 'COUNTRY_SUMMARIZED',
  DETAILED = 'DETAILED',
  SUMMARIZED = 'SUMMARIZED',
}

export type ExternalLeaveType = {
  __typename?: 'ExternalLeaveType';
  id?: Maybe<Scalars['String']>;
  leaveType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ExternalSubField = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum ExternalSystemType {
  NETSUITE = 'NETSUITE',
  XERO = 'XERO',
}

export enum FeatureCategory {
  CANDIDATE_TYPE = 'CANDIDATE_TYPE',
  COUNTRY = 'COUNTRY',
  EXPERIENCE_LEVEL = 'EXPERIENCE_LEVEL',
  HIRING_DIVERSITY = 'HIRING_DIVERSITY',
  JOB_ROLE = 'JOB_ROLE',
}

export type FeeComponentDefinition = {
  __typename?: 'FeeComponentDefinition';
  countryCode?: Maybe<CountryCode>;
  currencyCode?: Maybe<CurrencyCode>;
  discount?: Maybe<Discount>;
  fee: Scalars['Float'];
  feeComponentTierDefinition?: Maybe<FeeComponentTierDefinition>;
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id: Scalars['ID'];
  key: Scalars['String'];
  offeringCode: OfferingCode;
  status: FeeComponentDefinitionStatus;
  variableBaseUnit?: Maybe<VariableBaseUnit>;
};

export type FeeComponentDefinitionFilter = {
  offeringCode?: InputMaybe<Array<OfferingCode>>;
  status?: InputMaybe<Array<FeeComponentDefinitionStatus>>;
};

export type FeeComponentDefinitionInput = {
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<CurrencyCode>;
  discount?: InputMaybe<DiscountInput>;
  fee: Scalars['Float'];
  feeComponentTierDefinitionId: Scalars['ID'];
  feeComponentType: FeeComponentType;
  feeUnit: FeeUnit;
  frequencyUnit: FrequencyUnit;
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  offeringCode: OfferingCode;
  variableBaseUnit?: InputMaybe<VariableBaseUnit>;
};

export enum FeeComponentDefinitionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type FeeComponentTierDefinition = {
  __typename?: 'FeeComponentTierDefinition';
  feeComponentDefinitions?: Maybe<Array<FeeComponentDefinition>>;
  id: Scalars['ID'];
  offeringCode: OfferingCode;
  tierLowerBound?: Maybe<Scalars['Int']>;
  tierUpperBound?: Maybe<Scalars['Int']>;
};

export type FeeComponentTierDefinitionInput = {
  id?: InputMaybe<Scalars['ID']>;
  offeringCode: OfferingCode;
  tierLowerBound?: InputMaybe<Scalars['Int']>;
  tierUpperBound?: InputMaybe<Scalars['Int']>;
};

export enum FeeComponentType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export type FeeType = {
  __typename?: 'FeeType';
  amount?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Array<Maybe<FeeTypeMetadata>>>;
  name?: Maybe<Scalars['String']>;
};

export type FeeTypeMetadata = {
  __typename?: 'FeeTypeMetadata';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum FeeUnit {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
}

export type FetchEmployeesResult = {
  __typename?: 'FetchEmployeesResult';
  employees?: Maybe<Array<Maybe<Employee>>>;
  syncId?: Maybe<Scalars['String']>;
};

export enum FetchStage {
  CONTRACT_GENERATION = 'CONTRACT_GENERATION',
  MEMBER_LEGAL_DATA_CAPTURE = 'MEMBER_LEGAL_DATA_CAPTURE',
}

export type FiatTransaction = Transaction & {
  __typename?: 'FiatTransaction';
  creditor?: Maybe<LedgerSide>;
  debitor?: Maybe<LedgerSide>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTerminalTransaction?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Payment>;
  paymentPartner?: Maybe<PaymentPartner>;
  status?: Maybe<TransactionStatus>;
  transferValue?: Maybe<TransferValue>;
};

export type FieldAccessibilityRule = {
  __typename?: 'FieldAccessibilityRule';
  editable?: Maybe<Scalars['Boolean']>;
  field?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type FieldCategoryObject = Contract | Member;

export type FieldMapping = {
  __typename?: 'FieldMapping';
  children?: Maybe<Array<Maybe<FieldMapping>>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isCalculated?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  mappedField?: Maybe<Scalars['String']>;
  mappedFieldLabel?: Maybe<Scalars['String']>;
  originField?: Maybe<Scalars['String']>;
  originFieldLabel?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FieldMappingInput = {
  children?: InputMaybe<Array<InputMaybe<FieldMappingInput>>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isMappedByThirdParty?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  mappedField?: InputMaybe<Scalars['String']>;
  mappedFieldFromApp?: InputMaybe<Scalars['String']>;
  mappedFieldId?: InputMaybe<Scalars['String']>;
  mappedFieldLabel?: InputMaybe<Scalars['String']>;
  mappedSubFields?: InputMaybe<Array<InputMaybe<ExternalSubField>>>;
  originField?: InputMaybe<Scalars['String']>;
  originFieldLabel?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type File = {
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
};

export type FileDownload = {
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated use `payrollFile` instead */
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type FileDownloadHistoryFilter = {
  statuses?: InputMaybe<Array<PayrollFileDownloadStatus>>;
};

export type FileDownloadHistoryResponse = {
  __typename?: 'FileDownloadHistoryResponse';
  fileDownloads?: Maybe<Array<FileDownload>>;
};

export type FileField = {
  __typename?: 'FileField';
  acceptMultiple?: Maybe<Scalars['Boolean']>;
};

export type FileGenerationErrors = {
  __typename?: 'FileGenerationErrors';
  errorMessage?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  level?: Maybe<PaymentInstructionErrorLevel>;
  type?: Maybe<PaymentInstructionErrorType>;
};

export type FileGenerationRequestData = {
  bankCode: BankCode;
  countryCode: CountryCode;
  fileType: PaymentInstructionFileType;
  iso20022DirectDebitInstructionFileGenerateData?: InputMaybe<Iso20022DirectDebitInstructionFileGenerateData>;
  nachaDebitInstructionFileGenerateData?: InputMaybe<NachaDebitInstructionFileGenerateData>;
  nachaPrenoteInstructionFileGenerateData?: InputMaybe<NachaPrenoteInstructionFileGenerateData>;
  paymentFileSubType: Scalars['String'];
  paymentInstructionFileGenerateData?: InputMaybe<PaymentInstructionFileGenerateData>;
};

export type FileLink = File & {
  __typename?: 'FileLink';
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type FileWithVersion = File & {
  __typename?: 'FileWithVersion';
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type Filter = {
  __typename?: 'Filter';
  isMandatory?: Maybe<Scalars['Boolean']>;
  parameterName?: Maybe<Scalars['String']>;
  parameterTitle?: Maybe<Scalars['String']>;
  possibleValues: Array<Maybe<Scalars['String']>>;
  type?: Maybe<FilterTypes>;
};

export enum FilterTypes {
  DATE_RANGE = 'DATE_RANGE',
  DROPDOWN = 'DROPDOWN',
  MONTH = 'MONTH',
  MONTH_RANGE = 'MONTH_RANGE',
  MULTI_DROPDOWN = 'MULTI_DROPDOWN',
  MULTI_TEXT_INPUT = 'MULTI_TEXT_INPUT',
  NUMBER = 'NUMBER',
  RANGE = 'RANGE',
  TEXT = 'TEXT',
}

export type FinancialAssessmentDataInput = {
  data?: InputMaybe<Array<InputMaybe<FinancialDataRequirementInput>>>;
  id: Scalars['ID'];
};

export type FinancialAssessmentDocumentsInput = {
  documents?: InputMaybe<Array<InputMaybe<FinancialDocumentRequirementInput>>>;
  id: Scalars['ID'];
  reports?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type FinancialDataInput = {
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
  value?: InputMaybe<Scalars['String']>;
};

export type FinancialDataRequirementInput = {
  data: Array<InputMaybe<FinancialDataInput>>;
  kybRequirementType: Scalars['String'];
};

export type FinancialDocumentInput = {
  documentList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  key?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Array<InputMaybe<KybMetaDataInput>>>;
};

export type FinancialDocumentRequirementInput = {
  data: Array<InputMaybe<FinancialDocumentInput>>;
  kybRequirementType: Scalars['String'];
};

export type FinancialRiskAssessment = KybRiskAssessment & {
  __typename?: 'FinancialRiskAssessment';
  collectedRiskData?: Maybe<KybCollectedRiskData>;
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  riskScore?: Maybe<AssessmentRiskScore>;
  schema?: Maybe<KybSchema>;
  status?: Maybe<RiskAssessmentStatus>;
  type: AssessmentType;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransaction = {
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type FinancialTransactionInput = {
  autoSubmit?: InputMaybe<Scalars['Boolean']>;
  companyIdToForcedContractIds?: InputMaybe<
    Array<CompanyIdToForcedContractIdsInput>
  >;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateRange?: InputMaybe<DateRange>;
  financialTransactionType?: InputMaybe<FinancialTransactionType>;
  frequency?: InputMaybe<Scalars['String']>;
  transactionDate?: InputMaybe<Scalars['DateTime']>;
};

export type FinancialTransactionInspectorResponse = {
  __typename?: 'FinancialTransactionInspectorResponse';
  financialTransactions?: Maybe<Array<Maybe<FinancialTransaction>>>;
  transactionCommands?: Maybe<Array<Maybe<TransactionCommand>>>;
};

export enum FinancialTransactionType {
  ANNUAL_PLAN_INVOICE = 'ANNUAL_PLAN_INVOICE',
  DEPOSIT_INVOICE = 'DEPOSIT_INVOICE',
  FIRST_INVOICE = 'FIRST_INVOICE',
  GLOBAL_PAYROLL_FUNDING_INVOICE = 'GLOBAL_PAYROLL_FUNDING_INVOICE',
  INSURANCE_INVOICE = 'INSURANCE_INVOICE',
  SECOND_INVOICE = 'SECOND_INVOICE',
}

export type FiscalYear = {
  __typename?: 'FiscalYear';
  endDate?: Maybe<Scalars['Date']>;
  fiscalYear?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FixTimeoffAllocationInput = {
  dryRun?: InputMaybe<Scalars['Boolean']>;
  timeoffSummaryIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type FixedAllowance = {
  __typename?: 'FixedAllowance';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  rateType?: Maybe<RateType>;
  type?: Maybe<Scalars['String']>;
};

export type FixedDeductionRequirement = DeductionRequirement & {
  __typename?: 'FixedDeductionRequirement';
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<DeductionValue>>>;
  /** @deprecated Use FixedDeductionRequirement.name instead. */
  type?: Maybe<DeductionRequirementType>;
};

export type FixedPayComponent = CompensationPayComponent & {
  __typename?: 'FixedPayComponent';
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<PayAmountType>;
  condition?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  frequency?: Maybe<RateFrequency>;
  id?: Maybe<Scalars['ID']>;
  instalments?: Maybe<Array<Instalment>>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  paymentFrequency?: Maybe<PayFrequency>;
  paymentFrequencyDate?: Maybe<Array<Maybe<PayFrequencyDate>>>;
  rateType?: Maybe<RateType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  validFromInclusive?: Maybe<Scalars['Date']>;
  validToExclusive?: Maybe<Scalars['Date']>;
};

export type FixedPayComponentDefinition = CompensationPayComponentDefinition & {
  __typename?: 'FixedPayComponentDefinition';
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<RateFrequency>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  rateType?: Maybe<RateType>;
};

export type FixedPayComponentInput = {
  amount?: InputMaybe<Scalars['Float']>;
  amountType?: InputMaybe<PayAmountType>;
  condition?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  frequency?: InputMaybe<RateFrequency>;
  instalments?: InputMaybe<Array<InstalmentInput>>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<InputMaybe<PayFrequencyDateInput>>>;
  rateType?: InputMaybe<RateType>;
};

/** Deprecated. Use `FixedDeductionRequirement` type instead. */
export type FixedPercentageDeductionRequirement = DeductionRequirement & {
  __typename?: 'FixedPercentageDeductionRequirement';
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use FixedDeductionRequirement.type instead. */
  type?: Maybe<DeductionRequirementType>;
  /** @deprecated Use FixedDeductionRequirement.options instead. */
  values?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FixedTermRestrictionDefinition = {
  __typename?: 'FixedTermRestrictionDefinition';
  maxRenewalCount?: Maybe<Scalars['Int']>;
  maxRenewalDurationInMonths?: Maybe<Scalars['Int']>;
  maxTenureInMonths?: Maybe<Scalars['Int']>;
  minRenewalDurationInMonths?: Maybe<Scalars['Int']>;
};

export type FixedTermRestrictionFilter = {
  contractType?: InputMaybe<ContractType>;
};

export type FloatConfigValue = NumberConfigValue & {
  __typename?: 'FloatConfigValue';
  unit?: Maybe<NumberConfigUnit>;
  value: Scalars['Float'];
};

export type FreelancerPayable = MemberPayable & {
  __typename?: 'FreelancerPayable';
  /** Expense has its own approval flow, and PaySupplement doesn't even have one. So, adding approvedAt to here only and not the interface */
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationFrequency?: Maybe<RateFrequency>;
  durationValue?: Maybe<Scalars['Float']>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type FreelancerPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type FreelancerPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type FreelancerPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export enum FreelancerType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum FrequencyUnit {
  AD_HOC = 'AD_HOC',
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
}

export enum FxSensitivity {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type GenderParam = {
  __typename?: 'GenderParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Gender>;
};

export type GenericPayableCost = {
  __typename?: 'GenericPayableCost';
  billingCost?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['Float']>;
  type?: Maybe<PayableItemType>;
};

export type GetPartnerPayrollInput = {
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type GlobalPricing = {
  __typename?: 'GlobalPricing';
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
};

export type Grant = {
  condition?: Maybe<Scalars['String']>;
  document?: Maybe<File>;
  grantDate: Scalars['Date'];
  id: Scalars['ID'];
  schedule: GrantSchedule;
  /** @deprecated No longer supported */
  value?: Maybe<GrantValue>;
  values?: Maybe<Array<GrantValue>>;
};

export type GrantInput = {
  condition?: InputMaybe<Scalars['String']>;
  grantDate: Scalars['Date'];
  id?: InputMaybe<Scalars['ID']>;
  schedule: GrantScheduleInput;
  value?: InputMaybe<GrantValueInput>;
  values?: InputMaybe<Array<GrantValueInput>>;
};

export type GrantSchedule = {
  __typename?: 'GrantSchedule';
  cliffPeriod?: Maybe<ScheduleTime>;
  vestingPeriod?: Maybe<ScheduleTime>;
  vestingSchedule?: Maybe<Array<Maybe<VestingSchedule>>>;
};

export type GrantScheduleInput = {
  cliffPeriod?: InputMaybe<ScheduleTimeInput>;
  vestingPeriod?: InputMaybe<ScheduleTimeInput>;
  vestingSchedule?: InputMaybe<Array<InputMaybe<VestingScheduleInput>>>;
};

export enum GrantType {
  ESOP = 'ESOP',
  RSU = 'RSU',
  STOCK = 'STOCK',
}

export type GrantValue = {
  __typename?: 'GrantValue';
  currency?: Maybe<CurrencyCode>;
  type: GrantValueType;
  value: Scalars['Float'];
};

export type GrantValueInput = {
  currency?: InputMaybe<CurrencyCode>;
  type: GrantValueType;
  value: Scalars['Float'];
};

export enum GrantValueType {
  CASH = 'CASH',
  UNIT = 'UNIT',
}

export type HeadOfDepartment = {
  __typename?: 'HeadOfDepartment';
  contract?: Maybe<Contract>;
  manager?: Maybe<Manager>;
};

export type HeadOfDepartmentInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  managerId?: InputMaybe<Scalars['ID']>;
};

export type HealthCardFile = {
  file?: InputMaybe<Scalars['Upload']>;
  fileId?: InputMaybe<Scalars['Int']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  countryCode?: Maybe<CountryCode>;
  date?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['ID']>;
  month?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** for a single holiday */
export type HolidayAddInput = {
  date: Scalars['Date'];
  name: Scalars['String'];
  type?: InputMaybe<HolidayType>;
};

export enum HolidayType {
  BANK = 'BANK',
  NATIONAL = 'NATIONAL',
  SPECIAL = 'SPECIAL',
}

/**
 * - countryCode + year must be provided (to avoid disaster)
 * - the other fields: nullable
 */
export type HolidaysDeleteFilter = {
  countryCode: CountryCode;
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<HolidayType>;
  year: Scalars['Int'];
};

export enum HoursType {
  NIGHT_SHIFT_HOURS = 'NIGHT_SHIFT_HOURS',
  OVERTIME_AT_NIGHT_HOURS = 'OVERTIME_AT_NIGHT_HOURS',
  OVERTIME_HOURS = 'OVERTIME_HOURS',
  REGULAR_HOURS = 'REGULAR_HOURS',
}

export enum IdentityProvider {
  TRINET = 'TRINET',
}

export type Image = File & {
  __typename?: 'Image';
  blob?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  versionFiles?: Maybe<Array<Maybe<File>>>;
  versionId?: Maybe<Scalars['ID']>;
  versionedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated No longer supported */
  versions?: Maybe<Array<Maybe<FileWithVersion>>>;
};

export type InputFileDownload = FileDownload & {
  __typename?: 'InputFileDownload';
  createdOn?: Maybe<Scalars['DateTime']>;
  cycles?: Maybe<Array<PayrollCycle>>;
  description?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type InputHeadcountSummary = {
  __typename?: 'InputHeadcountSummary';
  leaver?: Maybe<Scalars['Int']>;
  newJoiner?: Maybe<Scalars['Int']>;
  previousCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type InputHeadcountSummaryV2 = {
  __typename?: 'InputHeadcountSummaryV2';
  leaver?: Maybe<Scalars['Int']>;
  newJoiner?: Maybe<Scalars['Int']>;
  previousCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type InsightAction = {
  __typename?: 'InsightAction';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum InsightCategory {
  BENEFIT = 'BENEFIT',
  COMPENSATION = 'COMPENSATION',
  COMPLIANCE = 'COMPLIANCE',
  TIME_OFF = 'TIME_OFF',
}

export type InsightDefinition = {
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type InsightDefinitionFilter = {
  categories?: InputMaybe<Array<InputMaybe<InsightCategory>>>;
  contractEndDate?: InputMaybe<Scalars['Date']>;
  contractStartDate?: InputMaybe<Scalars['Date']>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type Instalment = {
  __typename?: 'Instalment';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
};

export type InstalmentInput = {
  amount?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<CurrencyCode>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
};

export type InsuranceCoverage = {
  __typename?: 'InsuranceCoverage';
  maxCount: Scalars['Int'];
  relation?: Maybe<InsuranceRelation>;
};

export type InsuranceCoverageGroup = {
  __typename?: 'InsuranceCoverageGroup';
  group?: Maybe<Array<Maybe<InsuranceCoverage>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InsuranceCoverageInput = {
  maxCount: Scalars['Int'];
  relationId?: InputMaybe<Scalars['ID']>;
};

export type InsuranceIndividualPremium = {
  __typename?: 'InsuranceIndividualPremium';
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  billingPeriodInMonths?: Maybe<Scalars['Int']>;
  endOn?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  platformFee?: Maybe<Scalars['Float']>;
  startOn?: Maybe<Scalars['Date']>;
  subTotalAmount?: Maybe<Scalars['Float']>;
  subTotalPlatformFee?: Maybe<Scalars['Float']>;
};

export type InsurancePremium = {
  __typename?: 'InsurancePremium';
  benefitType?: Maybe<BenefitType>;
  billingDuration?: Maybe<BenefitPartnerBillingFrequency>;
  dependents?: Maybe<Array<Maybe<InsuranceIndividualPremium>>>;
  employeePayAmount?: Maybe<Scalars['Float']>;
  employeePayPercentage?: Maybe<Scalars['Float']>;
  employerPayAmount?: Maybe<Scalars['Float']>;
  employerPayPercentage?: Maybe<Scalars['Float']>;
  self?: Maybe<InsuranceIndividualPremium>;
  totalPlatformFee?: Maybe<Scalars['Float']>;
  totalPremium?: Maybe<Scalars['Float']>;
};

export type InsuranceRelation = {
  __typename?: 'InsuranceRelation';
  familyMember?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type InsuranceRelationInput = {
  familyMember: Scalars['String'];
};

export type InsuranceStatusResponse = {
  __typename?: 'InsuranceStatusResponse';
  contractBenefitId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  response?: Maybe<TaskResponse>;
  status: ContractBenefitStatus;
};

export type InsuranceStatuses = {
  currentStatus?: InputMaybe<ContractBenefitStatus>;
  nextStatus: ContractBenefitStatus;
};

export type InsuranceTier = {
  __typename?: 'InsuranceTier';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InsuranceTierInput = {
  name: Scalars['String'];
};

export type InsuranceUpdateInput = {
  benefitId?: InputMaybe<Scalars['String']>;
  contractBenefitId?: InputMaybe<Scalars['ID']>;
  discountTermPercentage?: InputMaybe<Scalars['Float']>;
  employeePayPercentage?: InputMaybe<Scalars['Float']>;
  employerPayPercentage?: InputMaybe<Scalars['Float']>;
  endOn?: InputMaybe<Scalars['DateTime']>;
  healthCard?: InputMaybe<HealthCardFile>;
  healthId?: InputMaybe<Scalars['String']>;
  includeDependents?: InputMaybe<Scalars['Boolean']>;
  insuranceTier?: InputMaybe<BenefitTier>;
  maxDependentCount?: InputMaybe<Scalars['Int']>;
  startOn?: InputMaybe<Scalars['DateTime']>;
};

export type IntConfigValue = NumberConfigValue & {
  __typename?: 'IntConfigValue';
  unit?: Maybe<NumberConfigUnit>;
  value: Scalars['Int'];
};

export type IntParam = {
  __typename?: 'IntParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type IntegrationEntityMappingStatusOutput = {
  __typename?: 'IntegrationEntityMappingStatusOutput';
  company?: Maybe<Company>;
  /** @deprecated No longer supported */
  companyId?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  entityId?: Maybe<Scalars['ID']>;
  entityMappingId?: Maybe<Scalars['ID']>;
  entityMappingStatus?: Maybe<LegalMappingStatus>;
  /** @deprecated No longer supported */
  entityName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['ID']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  legalEntity?: Maybe<LegalEntity>;
  /** @deprecated No longer supported */
  status?: Maybe<Scalars['String']>;
};

export type IntegrationFieldsMappingOutput = {
  __typename?: 'IntegrationFieldsMappingOutput';
  company?: Maybe<Company>;
  /** @deprecated No longer supported */
  companyId?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  entityId?: Maybe<Scalars['ID']>;
  entityMappingStatus?: Maybe<LegalMappingStatus>;
  fieldsMapping?: Maybe<Array<Maybe<FieldMapping>>>;
  integrationId?: Maybe<Scalars['ID']>;
  legalEntity?: Maybe<LegalEntity>;
  thirdPartyFields?: Maybe<Array<Maybe<UnmappedField>>>;
  /** @deprecated No longer supported */
  unmappedFields?: Maybe<Array<Maybe<UnmappedField>>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  companyPayableId?: Maybe<Scalars['ID']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  creditNotes?: Maybe<Array<CreditNote>>;
  document?: Maybe<DocumentReadable>;
  dueDate?: Maybe<Scalars['DateTime']>;
  externalLink?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<ExternalSystemType>;
  fullyPaidOnDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoiceId?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  reason?: Maybe<InvoiceReason>;
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<InvoiceType>;
};

export type InvoiceOverrideInfo = {
  dueDate: Scalars['Date'];
  id: Scalars['ID'];
  reference: Scalars['String'];
  value: Scalars['String'];
};

export enum InvoiceReason {
  CORRECTION_OF_INVOICE = 'CORRECTION_OF_INVOICE',
  DEPOSITS = 'DEPOSITS',
  FIRST_INVOICE = 'FIRST_INVOICE',
  FIRST_INVOICE_SUPPLEMENTARY = 'FIRST_INVOICE_SUPPLEMENTARY',
  INSURANCE = 'INSURANCE',
  INVOICE_VOIDED = 'INVOICE_VOIDED',
  OTHERS = 'OTHERS',
  SECOND_INVOICE = 'SECOND_INVOICE',
  VISA = 'VISA',
}

export type InvoiceSourceReportByHashInput = {
  hash: Scalars['String'];
};

export type InvoiceSourceReportByInvoiceIdInput = {
  invoiceId: Scalars['ID'];
};

export type InvoiceSourceReportFromLinkInput = {
  authCode: Scalars['String'];
  companyId: Scalars['ID'];
  invoiceMonth: Scalars['Int'];
  invoiceYear: Scalars['Int'];
};

export type InvoiceSourceReportHashInput = {
  hash: Scalars['String'];
};

export type InvoiceSourceReportUploadInput = {
  file?: InputMaybe<Scalars['Upload']>;
};

export enum InvoiceStatus {
  AUTHORIZED = 'AUTHORIZED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  VOIDED = 'VOIDED',
}

export type InvoiceStepData = {
  __typename?: 'InvoiceStepData';
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type InvoiceTransaction = FinancialTransaction & {
  __typename?: 'InvoiceTransaction';
  company?: Maybe<Company>;
  currency?: Maybe<CurrencyCode>;
  date?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Maybe<TransactionPayableItem>>>;
  month?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export enum InvoiceType {
  ANNUAL_PLAN = 'ANNUAL_PLAN',
  DEPOSIT = 'DEPOSIT',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL_FUNDING = 'GLOBAL_PAYROLL_FUNDING',
  GROSS = 'GROSS',
  INSURANCE = 'INSURANCE',
  SALARY = 'SALARY',
}

export type InvoiceWorkFlow = {
  __typename?: 'InvoiceWorkFlow';
  allSteps?: Maybe<Array<Maybe<InvoiceWorkflowStepDefinition>>>;
  currentStep?: Maybe<InvoiceWorkflowStepDefinition>;
};

export enum InvoiceWorkflowStep {
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_PAID = 'INVOICE_PAID',
  INVOICE_PAYMENT_INITIATED = 'INVOICE_PAYMENT_INITIATED',
  INVOICE_PAYMENT_RECEIVED = 'INVOICE_PAYMENT_RECEIVED',
  INVOICE_PAYOUT_INITIATED = 'INVOICE_PAYOUT_INITIATED',
}

export type InvoiceWorkflowStepDefinition = {
  __typename?: 'InvoiceWorkflowStepDefinition';
  currentStatus?: Maybe<MemberPayableStatus>;
  data?: Maybe<Array<Maybe<InvoiceStepData>>>;
  statuses?: Maybe<Array<Maybe<MemberPayableStatus>>>;
  step?: Maybe<InvoiceWorkflowStep>;
};

export type Iso20022DirectDebitInstructionFileGenerateData = {
  companyBankOverrideInfo?: InputMaybe<CompanyBankOverrideInfo>;
  companyId: Scalars['ID'];
  expectedPayoutDate: Scalars['Date'];
  invoiceIds: Array<InputMaybe<Scalars['ID']>>;
  invoiceOverrideInfo?: InputMaybe<Array<InputMaybe<InvoiceOverrideInfo>>>;
};

/**
 * This reflects a record of coredb.platform.item_approver table.<br>
 * The table lets us know who are the approvers of an item (and their decisions).<br>
 * Currently used in TimeOff & Expense (extends.graphql)
 */
export type ItemApproverInfo = {
  __typename?: 'ItemApproverInfo';
  /** currently this is just CompanyUser */
  approverUser?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ApprovalItemStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type JobCodeFilters = {
  contractType: ContractType;
  countryCode: CountryCode;
  postProbationBasePay?: InputMaybe<FixedPayComponentInput>;
  probationBasePay?: InputMaybe<FixedPayComponentInput>;
};

export type JobMetaData = {
  __typename?: 'JobMetaData';
  action: Scalars['String'];
  description: Scalars['String'];
  inputTypes?: Maybe<Array<Maybe<KeyValue>>>;
  ownerBy: Scalars['String'];
};

export type JobMetaDataPageResult = {
  __typename?: 'JobMetaDataPageResult';
  data?: Maybe<Array<Maybe<JobMetaData>>>;
  page?: Maybe<PageResult>;
};

export type Kyb = {
  __typename?: 'KYB';
  entity?: Maybe<KybEntity>;
  id: Scalars['ID'];
  kybAssessments?: Maybe<Array<Maybe<KybAssessment>>>;
};

export enum KybAdditionalRequirement {
  COMMENT = 'COMMENT',
  EMAIL = 'EMAIL',
}

export type KybAllowedValue = {
  __typename?: 'KYBAllowedValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KybAssessment = {
  __typename?: 'KYBAssessment';
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  reportExists?: Maybe<Scalars['Boolean']>;
  riskAssessment?: Maybe<Array<Maybe<KybRiskAssessment>>>;
  status?: Maybe<KybAssessmentStatus>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export enum KybAssessmentStatus {
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type KybCollectedData = {
  __typename?: 'KYBCollectedData';
  data?: Maybe<Array<Maybe<KybRiskData>>>;
  documents?: Maybe<Array<Maybe<KybRiskDocument>>>;
  kybRequirementType: Scalars['String'];
};

export type KybCollectedRiskData = {
  __typename?: 'KYBCollectedRiskData';
  fields?: Maybe<Array<Maybe<KybCollectedData>>>;
  report?: Maybe<KybPartnerReport>;
};

export type KybEmailRequirementInput = {
  kybRequirementType: Scalars['String'];
  requiredDocuments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type KybEntity = {
  __typename?: 'KYBEntity';
  company?: Maybe<Company>;
  legalEntity?: Maybe<LegalEntity>;
};

export type KybMetaData = {
  __typename?: 'KYBMetaData';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KybMetaDataInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type KybPartnerReport = {
  __typename?: 'KYBPartnerReport';
  reports?: Maybe<Array<Maybe<DocumentReadable>>>;
};

export type KybRequirement = {
  __typename?: 'KYBRequirement';
  fields?: Maybe<Array<Maybe<KybRequirementField>>>;
  kybRequirementType?: Maybe<Scalars['String']>;
};

export type KybRequirementField = {
  __typename?: 'KYBRequirementField';
  additionalRequirements?: Maybe<Array<Maybe<KybAdditionalRequirement>>>;
  allowedValues?: Maybe<Array<Maybe<KybAllowedValue>>>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type KybRequirementsInput = {
  kybAssessmentId: Scalars['ID'];
  requiredComplianceDocuments?: InputMaybe<
    Array<InputMaybe<KybEmailRequirementInput>>
  >;
  requiredFinancialDocuments?: InputMaybe<
    Array<InputMaybe<KybEmailRequirementInput>>
  >;
};

export type KybRiskAssessment = {
  collectedRiskData?: Maybe<KybCollectedRiskData>;
  completedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  riskScore?: Maybe<AssessmentRiskScore>;
  schema?: Maybe<KybSchema>;
  status?: Maybe<RiskAssessmentStatus>;
  type: AssessmentType;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type KybRiskData = {
  __typename?: 'KYBRiskData';
  key?: Maybe<Scalars['String']>;
  metaData?: Maybe<Array<Maybe<KybMetaData>>>;
  value?: Maybe<Scalars['String']>;
};

export type KybRiskDocument = {
  __typename?: 'KYBRiskDocument';
  documents?: Maybe<Array<Maybe<DocumentReadable>>>;
  key?: Maybe<Scalars['String']>;
  metaData?: Maybe<Array<Maybe<KybMetaData>>>;
};

export type KybSchema = {
  __typename?: 'KYBSchema';
  requirements?: Maybe<Array<Maybe<KybRequirement>>>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type KnitAuthResponse = {
  __typename?: 'KnitAuthResponse';
  error?: Maybe<KnitAuthTokenError>;
  msg?: Maybe<KnitAuthToken>;
  success: Scalars['Boolean'];
};

export type KnitAuthToken = {
  __typename?: 'KnitAuthToken';
  token?: Maybe<Scalars['String']>;
};

export type KnitAuthTokenError = {
  __typename?: 'KnitAuthTokenError';
  msg?: Maybe<Scalars['String']>;
};

export enum Language {
  EN = 'EN',
}

export type LastWorkingDayRangeResponse = {
  __typename?: 'LastWorkingDayRangeResponse';
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type LastWorkingDayResponse = {
  __typename?: 'LastWorkingDayResponse';
  date?: Maybe<Scalars['Date']>;
};

export type LastWorkingDayValidation = {
  __typename?: 'LastWorkingDayValidation';
  isValid?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  minimumLastWorkingDay?: Maybe<Scalars['Date']>;
};

export type LatestSyncResult = {
  __typename?: 'LatestSyncResult';
  addedContracts?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  failedContracts?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['ID']>;
  status?: Maybe<SyncStatus>;
  syncId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedContracts?: Maybe<Scalars['Int']>;
};

export type LeaveTypeMappingDefinition = {
  __typename?: 'LeaveTypeMappingDefinition';
  externalTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  integrationId?: Maybe<Scalars['ID']>;
  internalTypeId?: Maybe<Scalars['String']>;
};

export type LeaveTypeMappingDefinitionInput = {
  externalTypeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  integrationId?: InputMaybe<Scalars['ID']>;
  internalTypeId?: InputMaybe<Scalars['String']>;
};

export type LedgerSide = {
  __typename?: 'LedgerSide';
  account?: Maybe<PaymentPartnerAccount>;
  transferValue?: Maybe<TransferValue>;
};

export enum LegalBindingType {
  CLIENT = 'CLIENT',
  MULTIPLIER = 'MULTIPLIER',
  PARTNER = 'PARTNER',
}

export type LegalData = {
  __typename?: 'LegalData';
  /** @deprecated No longer supported */
  country?: Maybe<CountryCode>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  identifier?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type LegalDataInput = {
  identifier?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type LegalDataParam = {
  __typename?: 'LegalDataParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<LegalData>;
};

export type LegalDataRequirement = {
  __typename?: 'LegalDataRequirement';
  applyTo?: Maybe<LegalDataRequirementApplicability>;
  dependsOn?: Maybe<Array<Maybe<ControllingField>>>;
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  maxChars?: Maybe<Scalars['Int']>;
  minChars?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export enum LegalDataRequirementApplicability {
  ALL = 'ALL',
  EXPAT_ONLY = 'EXPAT_ONLY',
  LOCAL_ONLY = 'LOCAL_ONLY',
}

export enum LegalDataType {
  ALPHA_NUMERIC = 'ALPHA_NUMERIC',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_TEXT = 'DROPDOWN_TEXT',
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT',
  WHOLE_NUMBER = 'WHOLE_NUMBER',
}

export type LegalDocument = {
  __typename?: 'LegalDocument';
  approver?: Maybe<Array<Maybe<OperationsUser>>>;
  category?: Maybe<LegalDocumentCategory>;
  comment?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  country?: Maybe<CountryCode>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use `files` instead */
  documents?: Maybe<Array<Maybe<File>>>;
  files?: Maybe<Array<Document>>;
  financialYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  status?: Maybe<LegalDocumentStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum LegalDocumentCategory {
  LEGAL = 'LEGAL',
  OTHER = 'OTHER',
  PAYROLL = 'PAYROLL',
  PROOF = 'PROOF',
  VISA_PRIMARY = 'VISA_PRIMARY',
  VISA_SECONDARY = 'VISA_SECONDARY',
}

export type LegalDocumentInput = {
  category?: InputMaybe<LegalDocumentCategory>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  financialYear?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
};

export type LegalDocumentParam = {
  __typename?: 'LegalDocumentParam';
  /** @deprecated Please use `value.key` and `value.files` instead */
  documents?: Maybe<Array<Maybe<DocumentReadable>>>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<LegalDocument>;
};

export type LegalDocumentRequirement = {
  __typename?: 'LegalDocumentRequirement';
  acceptMultiple?: Maybe<Scalars['Boolean']>;
  applyTo?: Maybe<LegalDataRequirementApplicability>;
  category?: Maybe<LegalDocumentCategory>;
  deadline?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  template?: Maybe<Array<Maybe<DocumentReadable>>>;
};

export type LegalDocumentRequirementInput = {
  acceptMultiple?: InputMaybe<Scalars['Boolean']>;
  applyTo?: InputMaybe<LegalDataRequirementApplicability>;
  category?: InputMaybe<LegalDocumentCategory>;
  countryCode?: InputMaybe<CountryCode>;
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  endDate?: InputMaybe<Scalars['Date']>;
  key?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum LegalDocumentStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type LegalDocumentsApprovalItem = ApprovalItem & {
  __typename?: 'LegalDocumentsApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  legalDocument?: Maybe<LegalDocument>;
  status?: Maybe<ApprovalItemStatus>;
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  address?: Maybe<Address>;
  bankAccount?: Maybe<BankAccount>;
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  holidays?: Maybe<Array<Holiday>>;
  id?: Maybe<Scalars['ID']>;
  kybId?: Maybe<Scalars['ID']>;
  legalName?: Maybe<Scalars['String']>;
  offeringCodes?: Maybe<Array<Maybe<OfferingCode>>>;
  payrollData?: Maybe<Array<Maybe<DataFieldValue>>>;
  phone?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  status?: Maybe<LegalEntityStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type LegalEntity_HolidaysArgs = {
  date?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<LegalEntityHolidayInput>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type LegalEntityHolidayInput = {
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Array<Scalars['Int']>>;
};

export type LegalEntityPayrollDataRequirements = {
  __typename?: 'LegalEntityPayrollDataRequirements';
  country?: Maybe<CountryCode>;
  requirements?: Maybe<Array<Maybe<DataFieldDefinition>>>;
};

export enum LegalEntityStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export enum LegalMappingStatus {
  FULLY_MAPPED = 'FULLY_MAPPED',
  PARTIALLY_MAPPED = 'PARTIALLY_MAPPED',
  UNMAPPED = 'UNMAPPED',
}

export enum LimitValueType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export type MsaWorkflow = {
  __typename?: 'MSAWorkflow';
  currentStatus?: Maybe<MsaWorkflowStatus>;
  currentStep?: Maybe<MsaWorkflowStep>;
  steps?: Maybe<Array<Maybe<MsaWorkflowStep>>>;
};

export enum MsaWorkflowStatus {
  MSA_GENERATED = 'MSA_GENERATED',
  MSA_GENERATION_PENDING = 'MSA_GENERATION_PENDING',
  MSA_SENT_FOR_SIGNING = 'MSA_SENT_FOR_SIGNING',
  MSA_SIGNED = 'MSA_SIGNED',
}

export enum MsaWorkflowStep {
  GENERATE_MSA = 'GENERATE_MSA',
  SEND_MSA = 'SEND_MSA',
  SIGNING_MSA = 'SIGNING_MSA',
}

export type ManagementFee = {
  __typename?: 'ManagementFee';
  appliedDiscount?: Maybe<Scalars['String']>;
  calculatedDateTime?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<Contract>;
  discountedFee?: Maybe<Scalars['Float']>;
  originalFee?: Maybe<Scalars['Float']>;
};

export type Manager = {
  __typename?: 'Manager';
  company?: Maybe<Company>;
  companyUser?: Maybe<CompanyUser>;
  contract?: Maybe<Contract>;
  directReports?: Maybe<Array<DirectReport>>;
  id: Scalars['ID'];
  orgAttributes?: Maybe<OrgAttributes>;
  reportsToManager?: Maybe<Manager>;
  totalSubordinates?: Maybe<Scalars['Int']>;
};

export type ManagerAssignDirectReportsInput = {
  directReportContractIds?: InputMaybe<Array<Scalars['ID']>>;
  directReportManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  managerId: Scalars['ID'];
  ruleInput?: InputMaybe<RuleInput>;
};

export type ManagerCreateInput = {
  companyId: Scalars['ID'];
  companyUserId?: InputMaybe<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['ID']>;
  orgAttributes?: InputMaybe<OrgAttributesInput>;
  reportsToManagerId?: InputMaybe<Scalars['ID']>;
};

export type ManagerDeleteInput = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type ManagerFilters = {
  contractId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ManagerUpdateInput = {
  id: Scalars['ID'];
  orgAttributes?: InputMaybe<OrgAttributesInput>;
};

export type ManagerValidateDirectReportsInput = {
  directReportContractIds?: InputMaybe<Array<Scalars['ID']>>;
  directReportManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  managerId: Scalars['ID'];
};

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type MarriedPolicyRules = {
  __typename?: 'MarriedPolicyRules';
  maxAllowedTotal?: Maybe<Scalars['Int']>;
  maxChildrenAllowed?: Maybe<Scalars['Int']>;
  maxParentsAllowed?: Maybe<Scalars['Int']>;
  spouse?: Maybe<Scalars['Boolean']>;
};

export enum MartialStatus {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type Member = Person & {
  __typename?: 'Member';
  /** @deprecated Moved to Contract. */
  activatedOn?: Maybe<Scalars['DateTime']>;
  addressDetails?: Maybe<MemberAddress>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  age?: Maybe<Scalars['Float']>;
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  basicInfo?: Maybe<MemberBasicInfo>;
  changeRequests?: Maybe<Array<Maybe<MemberChangeRequest>>>;
  contactInfo?: Maybe<MemberContactInfo>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated not required */
  customFields?: Maybe<Array<Maybe<MemberCustomField>>>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  education?: Maybe<Array<Maybe<Education>>>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  emergencyContact?: Maybe<EmergencyContact>;
  firstName?: Maybe<Scalars['String']>;
  fullLegalName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']>;
  identificationDetails?: Maybe<MemberIdentificationDetails>;
  lastName?: Maybe<Scalars['String']>;
  legalData?: Maybe<Array<Maybe<LegalData>>>;
  legalDocuments?: Maybe<Array<Maybe<LegalDocument>>>;
  maritalStatus?: Maybe<MaritalStatus>;
  /** @deprecated Typo in the field. New field `maritalStatus` introduced */
  martialStatus?: Maybe<MartialStatus>;
  middleName?: Maybe<Scalars['String']>;
  nationalities?: Maybe<Array<Maybe<MemberNationalogy>>>;
  payrollFormsUploadDate?: Maybe<Scalars['DateTime']>;
  payrollFormsUploadStatus?: Maybe<PayrollFormsUploadStatus>;
  persona?: Maybe<Persona>;
  personalInfo?: Maybe<MemberPersonalInfo>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  previousEmployers?: Maybe<Array<Maybe<PreviousEmployer>>>;
  profilePicture?: Maybe<Image>;
  status?: Maybe<MemberStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type Member_ContractsArgs = {
  includeAnyMemberStatus?: InputMaybe<Scalars['Boolean']>;
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ContractStatus>;
};

export type Member_LegalDocumentsArgs = {
  category?: InputMaybe<LegalDocumentCategory>;
};

export type MemberAddress = {
  __typename?: 'MemberAddress';
  currentAddress?: Maybe<Address>;
  permanentAddress?: Maybe<Address>;
};

export type MemberAddressInput = {
  currentAddress?: InputMaybe<AddressInput>;
  permanentAddress?: InputMaybe<AddressInput>;
};

export type MemberBasicInfo = {
  __typename?: 'MemberBasicInfo';
  firstName?: Maybe<Scalars['String']>;
  fullLegalName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type MemberBasicInfoInput = {
  firstName?: InputMaybe<Scalars['String']>;
  fullLegalName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export enum MemberChangeCategory {
  BANK_DETAILS = 'BANK_DETAILS',
  BASIC_DETAILS = 'BASIC_DETAILS',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  IDENTIFICATION_DETAILS = 'IDENTIFICATION_DETAILS',
}

export type MemberChangeItem =
  | AddressParam
  | BankAccountParam
  | DateParam
  | EmailAddressParam
  | GenderParam
  | IntParam
  | LegalDataParam
  | LegalDocumentParam
  | MemberNationalogyParam
  | PhoneNumberParam
  | StringParam;

export type MemberChangeRequest = {
  __typename?: 'MemberChangeRequest';
  category?: Maybe<MemberChangeCategory>;
  createdOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use `files` instead */
  documents?: Maybe<Array<Maybe<FileLink>>>;
  files?: Maybe<Array<Document>>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<MemberChangeItem>>>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<MemberChangeRequestStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MemberChangeRequestInput = {
  address?: InputMaybe<AddressInput>;
  bankAccount?: InputMaybe<BankAccountInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** proof document for this particullar request. Same behavior as BankAccountInput.bankStatements/bankStatementDocIds */
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  email?: InputMaybe<EmailAddressInput>;
  firstName?: InputMaybe<Scalars['String']>;
  fullLegalName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastname?: InputMaybe<Scalars['String']>;
  legalData?: InputMaybe<Array<LegalDataInput>>;
  legalDocuments?: InputMaybe<Array<LegalDocumentInput>>;
  middleName?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<MemberNationalogyInput>;
  passportNo?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<PhoneNumberInput>;
  workEmail?: InputMaybe<EmailAddressInput>;
};

export enum MemberChangeRequestStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type MemberContactInfo = {
  __typename?: 'MemberContactInfo';
  email?: Maybe<EmailAddress>;
  mobile?: Maybe<PhoneNumber>;
  phone?: Maybe<PhoneNumber>;
};

export type MemberContactInfoInput = {
  email?: InputMaybe<EmailAddressInput>;
  mobile?: InputMaybe<PhoneNumberInput>;
  phone?: InputMaybe<PhoneNumberInput>;
};

export type MemberCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export type MemberCustomField = {
  __typename?: 'MemberCustomField';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MemberCustomFieldInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type MemberDataApprovalItem = ApprovalItem & {
  __typename?: 'MemberDataApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  memberDataChangeRequest?: Maybe<MemberChangeRequest>;
  status?: Maybe<ApprovalItemStatus>;
};

export type MemberDataRequirementsFilter = {
  contractType?: InputMaybe<ContractType>;
  fetchStage?: InputMaybe<FetchStage>;
  onlyDynamicRequirements?: InputMaybe<Scalars['Boolean']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export enum MemberExperiencePage {
  EMPLOYMENT_DETAILS = 'EMPLOYMENT_DETAILS',
}

export type MemberFilters = {
  activationDateRanges?: InputMaybe<Array<DateRange>>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractFilters?: InputMaybe<ContractFilters>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  countries?: InputMaybe<Array<CountryCode>>;
  createdDateRanges?: InputMaybe<Array<DateRange>>;
  endedDateRanges?: InputMaybe<Array<DateRange>>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  hasChangeRequested?: InputMaybe<Scalars['Boolean']>;
  isLegacyOffboardingFilter?: InputMaybe<Scalars['Boolean']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  memberEmails?: InputMaybe<Array<Scalars['String']>>;
  memberNameContains?: InputMaybe<Array<Scalars['String']>>;
  offboardingStatuses?: InputMaybe<Array<ContractOffboardingStatus>>;
  onboardingStatuses?: InputMaybe<Array<ContractOnboardingStatus>>;
  payrollFormsUploadDateRanges?: InputMaybe<Array<DateRange>>;
  payrollFormsUploadStatuses?: InputMaybe<Array<PayrollFormsUploadStatus>>;
  startDateRanges?: InputMaybe<Array<DateRange>>;
  statuses?: InputMaybe<Array<MemberStatus>>;
  uploadedPayrollForms?: InputMaybe<Scalars['Boolean']>;
  validTillDateRanges?: InputMaybe<Array<DateRange>>;
};

export type MemberIdentificationDetails = {
  __typename?: 'MemberIdentificationDetails';
  legalData?: Maybe<Array<Maybe<LegalData>>>;
  legalDocuments?: Maybe<Array<Maybe<LegalDocument>>>;
};

export type MemberIdentificationDetailsInput = {
  legalData?: InputMaybe<Array<InputMaybe<LegalDataInput>>>;
  legalDocuments?: InputMaybe<Array<InputMaybe<LegalDocumentInput>>>;
};

export type MemberNationalogy = {
  __typename?: 'MemberNationalogy';
  country?: Maybe<CountryCode>;
  type?: Maybe<Nationalogy>;
};

export type MemberNationalogyInput = {
  country?: InputMaybe<CountryCode>;
  type?: InputMaybe<Nationalogy>;
};

export type MemberNationalogyParam = {
  __typename?: 'MemberNationalogyParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<MemberNationalogy>;
};

export type MemberPay = {
  additional?: Maybe<Array<Maybe<AdditionalPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountNet?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  clientDeductions?: Maybe<Array<Maybe<PayComponent>>>;
  companyPayroll?: Maybe<CompanyPayroll>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<PayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<PayComponent>>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payrollCycle?: Maybe<PayrollCycle>;
  payslip?: Maybe<Payslip>;
  status?: Maybe<MemberPayStatus>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export enum MemberPayStatus {
  NEW = 'NEW',
  PAID = 'PAID',
  PAYSLIP_GENERATION_FAILED = 'PAYSLIP_GENERATION_FAILED',
  PAYSLIP_GENERATION_IN_PROGRESS = 'PAYSLIP_GENERATION_IN_PROGRESS',
  PAYSLIP_GENERATION_SUCCESS = 'PAYSLIP_GENERATION_SUCCESS',
  PENDING = 'PENDING',
}

export type MemberPayable = {
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MemberPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type MemberPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type MemberPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export type MemberPayableAmount = {
  __typename?: 'MemberPayableAmount';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
};

export type MemberPayableApprovalItem = ApprovalItem & {
  __typename?: 'MemberPayableApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  memberPayableId?: Maybe<Scalars['ID']>;
  memberPayableItem?: Maybe<MemberPayable>;
  status?: Maybe<ApprovalItemStatus>;
};

export enum MemberPayableAutoGenerationState {
  OFF = 'OFF',
  ON = 'ON',
}

export type MemberPayableChange = {
  __typename?: 'MemberPayableChange';
  actionedBy?: Maybe<Person>;
  actionedOn?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<MemberPayableStatus>;
};

/** @deprecated: No usage found in backend codebase */
export enum MemberPayableCompanyExperienceStatus {
  PAID = 'PAID',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  UNPAID = 'UNPAID',
}

export type MemberPayableCompanyInvoice = {
  __typename?: 'MemberPayableCompanyInvoice';
  bankAccount: BankAccount;
  bankTransferFee?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  company?: Maybe<Company>;
  companyPayable?: Maybe<CompanyPayable>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<DocumentReadable>;
  /**
   * @deprecated: No usage found in backend codebase
   * @deprecated No longer supported
   */
  experienceStatus?: Maybe<MemberPayableCompanyExperienceStatus>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<MemberPayableCompanyInvoiceStatus>;
  isReadyForPayouts?: Maybe<Scalars['Boolean']>;
  memberPayableInvoiceItems?: Maybe<
    Array<Maybe<MemberPayableCompanyInvoiceItem>>
  >;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  paidOn?: Maybe<Scalars['DateTime']>;
  payInInfo?: Maybe<PayInInfo>;
  paymentMethod?: Maybe<PaymentMethodInfo>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutPaymentMethodInfo?: Maybe<Array<Maybe<PaymentMethodInfo>>>;
  referenceId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MemberPayableCompanyInvoice_DocumentArgs = {
  format?: InputMaybe<MemberPayableCompanyInvoiceFormat>;
};

export type MemberPayableCompanyInvoiceFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  generatedDateRange?: InputMaybe<DateRange>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceStatus?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
  payInMethod?: InputMaybe<PaymentMethod>;
  referenceId?: InputMaybe<Scalars['String']>;
};

export enum MemberPayableCompanyInvoiceFormat {
  CSV = 'CSV',
  PDF = 'PDF',
}

export type MemberPayableCompanyInvoiceItem = {
  __typename?: 'MemberPayableCompanyInvoiceItem';
  billingAmount?: Maybe<Scalars['Float']>;
  managementFee?: Maybe<Scalars['Float']>;
  memberPayable?: Maybe<MemberPayable>;
};

export type MemberPayableCompanyInvoiceResult = {
  __typename?: 'MemberPayableCompanyInvoiceResult';
  data?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
};

export enum MemberPayableCompanyInvoiceStatus {
  /** when the payin is received without any mismatch */
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  /** when the payin is received but the amount doesn't match. Similar to MemberPayableStatus.PARTIAL_PAY_IN_RECEIVED */
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PROCESSING = 'PROCESSING',
  VOID = 'VOID',
}

export type MemberPayableCompanyInvoiceSubmitInput = {
  memberPayableIds: Array<InputMaybe<Scalars['ID']>>;
  paymentMethod?: InputMaybe<PaymentMethod>;
};

/** Deprecated. This was created for REMITTANCE_ADVICE feature but remittance advice feature is not in use */
export enum MemberPayableDocumentType {
  /** @deprecated No longer supported */
  INVOICE = 'INVOICE',
  /** @deprecated No longer supported */
  REMITTANCE_ADVICE = 'REMITTANCE_ADVICE',
}

export enum MemberPayableExtraFeeType {
  CRYPTO_FEE = 'CRYPTO_FEE',
  FLAT_FEE = 'FLAT_FEE',
  SWIFT_FEE = 'SWIFT_FEE',
  VARIABLE_FEE = 'VARIABLE_FEE',
}

export type MemberPayableInvoiceSourceReportExportInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  payableMonth?: InputMaybe<PayableMonthInput>;
};

export type MemberPayableItem = {
  __typename?: 'MemberPayableItem';
  amount?: Maybe<Scalars['Float']>;
  amountInFunctionalCurrency?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Not in use since when the `custom invoice upload` feature was dropped in Feb 2022 */
  supportingDocs?: Maybe<Array<Maybe<DocumentReadable>>>;
};

export type MemberPayableItemInput = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  /** @deprecated Not in use since when the `custom invoice upload` feature was dropped in Feb 2022 */
  supportingDocs?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
};

export type MemberPayablePayOutInfo = {
  __typename?: 'MemberPayablePayOutInfo';
  paidOn?: Maybe<Scalars['DateTime']>;
  paymentPartner?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type MemberPayableRejectInput = {
  additionalComment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type MemberPayableReportFilter = {
  companyIds: Array<Scalars['ID']>;
  companyInvoiceReferenceId?: InputMaybe<Scalars['String']>;
  contractIds: Array<Scalars['ID']>;
  countryCodes: Array<CountryCode>;
  createdDateRange?: InputMaybe<DateRange>;
  externalId?: InputMaybe<Scalars['ID']>;
  memberName?: InputMaybe<Scalars['String']>;
  memberPayableIds: Array<Scalars['ID']>;
  payablePeriod?: InputMaybe<PeriodInput>;
  paymentMethod?: InputMaybe<TransferType>;
  paymentStatuses: Array<MemberPayableStatus>;
  payoutDateRange?: InputMaybe<DateRange>;
  type?: InputMaybe<MemberPayableType>;
};

export enum MemberPayableStatus {
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  PARTIAL_PAY_IN_RECEIVED = 'PARTIAL_PAY_IN_RECEIVED',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYOUT_COMPLETED = 'PAYOUT_COMPLETED',
  PAYOUT_FAILED = 'PAYOUT_FAILED',
  PAYOUT_INITIATED = 'PAYOUT_INITIATED',
  PAY_IN_PROCESSING = 'PAY_IN_PROCESSING',
  PAY_IN_RECEIVED = 'PAY_IN_RECEIVED',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  UNPAID = 'UNPAID',
}

export type MemberPayableSubmitInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  dueDate?: InputMaybe<Scalars['Date']>;
  duration?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  items: Array<InputMaybe<MemberPayableItemInput>>;
  payablePeriod: PeriodInput;
  title?: InputMaybe<Scalars['String']>;
};

export type MemberPayableSummary = {
  __typename?: 'MemberPayableSummary';
  failedPayOutTotalCount?: Maybe<Scalars['Int']>;
  payInReceivedTotalCount?: Maybe<Scalars['Int']>;
  payOutInitiatedCount?: Maybe<Scalars['Int']>;
  payOutInitiatedTotalCount?: Maybe<Scalars['Int']>;
};

export type MemberPayableSummary_PayOutInitiatedCountArgs = {
  pendingDays?: InputMaybe<Scalars['Int']>;
};

export enum MemberPayableType {
  EXPENSE = 'EXPENSE',
  /** @deprecated No longer supported */
  FREELANCER = 'FREELANCER',
  INVOICE = 'INVOICE',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
}

export type MemberPayableUpdateBulkResponse = {
  __typename?: 'MemberPayableUpdateBulkResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export type MemberPayableUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  /** @deprecated No longer supported */
  dueDate?: InputMaybe<Scalars['Date']>;
  duration?: InputMaybe<Scalars['Float']>;
  items: Array<InputMaybe<MemberPayableItemInput>>;
  payablePeriod: PeriodInput;
  title?: InputMaybe<Scalars['String']>;
};

export type MemberPayrollCost = {
  __typename?: 'MemberPayrollCost';
  billingCost?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  memberPay?: Maybe<MemberPay>;
  totalCost?: Maybe<Scalars['Float']>;
  type?: Maybe<PayableItemType>;
};

export type MemberPayrollCycle = {
  __typename?: 'MemberPayrollCycle';
  memberPay?: Maybe<MemberPay>;
  payrollCycle?: Maybe<PayrollCycle>;
};

export type MemberPayrollCycleDetail = {
  __typename?: 'MemberPayrollCycleDetail';
  compensations?: Maybe<Array<CompensationSnapshot>>;
  expenses?: Maybe<Array<ExpenseSnapshot>>;
  fixedAllowances?: Maybe<Array<FixedAllowance>>;
  memberPay?: Maybe<MemberPay>;
  paySupplements?: Maybe<Array<PaySupplementSnapshot>>;
  payrollCycle?: Maybe<PayrollCycle>;
  summary?: Maybe<MemberPayrollCycleSummary>;
  unpaidTimeOffs?: Maybe<Array<TimeOffSnapshot>>;
};

export type MemberPayrollCycleDetailFilter = {
  payrollCycleId: Scalars['ID'];
};

export type MemberPayrollCycleFilter = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MemberPayrollCycleSummary = {
  __typename?: 'MemberPayrollCycleSummary';
  compensation?: Maybe<MemberPayrollCycleSummaryItem>;
  deductions?: Maybe<MemberPayrollCycleSummaryItem>;
  expense?: Maybe<MemberPayrollCycleSummaryItem>;
  fixedAllowance?: Maybe<MemberPayrollCycleSummaryItem>;
  paySupplement?: Maybe<MemberPayrollCycleSummaryItem>;
  unpaidTimeOff?: Maybe<MemberPayrollCycleSummaryItem>;
};

export type MemberPayrollCycleSummaryItem = {
  __typename?: 'MemberPayrollCycleSummaryItem';
  count?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type MemberPayrollCyclesResponse = {
  __typename?: 'MemberPayrollCyclesResponse';
  payrollCycleDetails?: Maybe<Array<MemberPayrollCycleDetail>>;
  /** @deprecated Use payrollCycleDetails instead */
  payrollCycles?: Maybe<Array<MemberPayrollCycle>>;
};

export enum MemberPersonalDetailsSection {
  ADDRESS = 'ADDRESS',
  BASIC_INFO = 'BASIC_INFO',
  CHANGE_REQUESTS = 'CHANGE_REQUESTS',
  EDUCATION = 'EDUCATION',
  EMERGENCY = 'EMERGENCY',
  FINANCIAL = 'FINANCIAL',
  IDENTIFICATION_DETAILS = 'IDENTIFICATION_DETAILS',
  PERSONAL_CONTACT_DETAILS = 'PERSONAL_CONTACT_DETAILS',
  PERSONAL_INFO = 'PERSONAL_INFO',
  PREVIOUS_EMPLOYMENT = 'PREVIOUS_EMPLOYMENT',
}

export type MemberPersonalInfo = {
  __typename?: 'MemberPersonalInfo';
  age?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  maritalStatus?: Maybe<MaritalStatus>;
  nationalities?: Maybe<Array<Maybe<MemberNationalogy>>>;
  religion?: Maybe<Scalars['String']>;
};

export type MemberPersonalInfoInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  gender?: InputMaybe<Gender>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  nationalities?: InputMaybe<Array<MemberNationalogyInput>>;
  religion?: InputMaybe<Scalars['String']>;
};

export enum MemberStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  INVITED = 'INVITED',
  SUSPENDED = 'SUSPENDED',
}

export type MemberUpdateBasicDetailsInput = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullLegalName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  /** @deprecated Typo in the field. New field `maritalStatus` introduced */
  martialStatus?: InputMaybe<MartialStatus>;
  middleName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<MemberNationalogyInput>;
  phone?: InputMaybe<PhoneNumberInput>;
  status?: InputMaybe<MemberStatus>;
};

export type MemberUpdateInput = {
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
};

export type MembersInput = {
  /** filters contains the list of filter and merge it by AND operation */
  filters?: InputMaybe<MemberFilters>;
};

export type MembersResult = {
  __typename?: 'MembersResult';
  activeCount?: Maybe<Scalars['Int']>;
  allCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Member>>>;
  endedCount?: Maybe<Scalars['Int']>;
  offboardingCount?: Maybe<Scalars['Int']>;
  onboardingCount?: Maybe<Scalars['Int']>;
  pageResult?: Maybe<PageResult>;
};

export type MonthYear = {
  __typename?: 'MonthYear';
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type MonthYearCycleInput = {
  cycle?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MonthYearInput = {
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MsaAddendum = {
  __typename?: 'MsaAddendum';
  addendumDocId?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  document?: Maybe<DocumentReadable>;
  id?: Maybe<Scalars['ID']>;
  msaAddendumChange?: Maybe<MsaAddendumChange>;
  msaAddendumChanges?: Maybe<Array<Maybe<MsaAddendumChange>>>;
  operationType?: Maybe<MsaAddendumOperationType>;
  status?: Maybe<MsaAddendumStatus>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type MsaAddendumChange = {
  __typename?: 'MsaAddendumChange';
  customDiscountTerm?: Maybe<MsaAddendumChangeCustomDiscountTerm>;
  discountTerms?: Maybe<MsaAddendumChangeDiscountTerms>;
  employeePricing?: Maybe<MsaAddendumChangeEmployeePricing>;
  fieldName?: Maybe<MsaDataChangeFieldName>;
  globalFreelancerPricing?: Maybe<MsaAddendumChangeGlobalFreelancerPricing>;
  paymentTermInDays?: Maybe<MsaAddendumChangePaymentTermInDays>;
  pricingPlan?: Maybe<MsaAddendumChangePricingPlan>;
};

export type MsaAddendumChangeCustomDiscountTerm = {
  __typename?: 'MsaAddendumChangeCustomDiscountTerm';
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export type MsaAddendumChangeDiscountTerms = {
  __typename?: 'MsaAddendumChangeDiscountTerms';
  newValue?: Maybe<Array<Maybe<DiscountTerm>>>;
  oldValue?: Maybe<Array<Maybe<DiscountTerm>>>;
};

export type MsaAddendumChangeEmployeePricing = {
  __typename?: 'MsaAddendumChangeEmployeePricing';
  newValue?: Maybe<Array<Maybe<EmployeePricing>>>;
  oldValue?: Maybe<Array<Maybe<EmployeePricing>>>;
};

export type MsaAddendumChangeGlobalFreelancerPricing = {
  __typename?: 'MsaAddendumChangeGlobalFreelancerPricing';
  newValue?: Maybe<Scalars['Float']>;
  oldValue?: Maybe<Scalars['Float']>;
};

export type MsaAddendumChangePaymentTermInDays = {
  __typename?: 'MsaAddendumChangePaymentTermInDays';
  newValue?: Maybe<Scalars['Int']>;
  oldValue?: Maybe<Scalars['Int']>;
};

export type MsaAddendumChangePricingPlan = {
  __typename?: 'MsaAddendumChangePricingPlan';
  newId?: Maybe<Scalars['Int']>;
  oldId?: Maybe<Scalars['Int']>;
};

export type MsaAddendumFilters = {
  statuses: Array<MsaAddendumStatus>;
};

export type MsaAddendumInput = {
  companyId: Scalars['ID'];
  pricingInput?: InputMaybe<PricingInput>;
};

export enum MsaAddendumOperationType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}

export enum MsaAddendumStatus {
  ADDENDUM_COMPLETED = 'ADDENDUM_COMPLETED',
  ADDENDUM_DELETED = 'ADDENDUM_DELETED',
  ADDENDUM_DRAFT = 'ADDENDUM_DRAFT',
  ADDENDUM_GENERATED = 'ADDENDUM_GENERATED',
  ADDENDUM_SENT_FOR_SIGNING = 'ADDENDUM_SENT_FOR_SIGNING',
  ADDENDUM_SIGNED_BY_CUSTOMER = 'ADDENDUM_SIGNED_BY_CUSTOMER',
  ADDENDUM_SIGNED_BY_MULTIPLIER = 'ADDENDUM_SIGNED_BY_MULTIPLIER',
}

export enum MsaDataChangeFieldName {
  CUSTOM_DISCOUNT_TERM = 'CUSTOM_DISCOUNT_TERM',
  DISCOUNT_TERMS = 'DISCOUNT_TERMS',
  EMPLOYEE_PRICING = 'EMPLOYEE_PRICING',
  GLOBAL_FREELANCER_PRICING = 'GLOBAL_FREELANCER_PRICING',
  PAYMENT_TERM_IN_DAYS = 'PAYMENT_TERM_IN_DAYS',
  PRICING_PLAN = 'PRICING_PLAN',
}

export type Mutation = {
  __typename?: 'Mutation';
  PaymentInstructionFileGenerate?: Maybe<TaskResponse>;
  PaymentInstructionFileGenerateV2?: Maybe<TaskResponse>;
  PennyTestInitiate?: Maybe<TaskResponse>;
  activateReviews?: Maybe<TaskResponse>;
  addOrAssociatePartnerCompanyUser?: Maybe<TaskResponse>;
  approveItem?: Maybe<ApprovalItem>;
  assignOperationUsers?: Maybe<Company>;
  assignPayrollSnapshotDomain?: Maybe<TaskResponse>;
  /** @deprecated no longer support, use payrollInputFileDownloadAsync instead */
  asyncPayrollInputFileDownload?: Maybe<TaskResponse>;
  /** @deprecated no longer support, use payrollInputFilePartnerBulkDownloadAsync instead */
  asyncPayrollInputFilePartnerBulkDownload?: Maybe<TaskResponse>;
  autoDebitManualTrigger?: Maybe<TaskResponse>;
  backFillKybForCompanies?: Maybe<TaskResponse>;
  backfillEntitlementDefinitionIds?: Maybe<TaskResponse>;
  backfillHrisManagersFromCompanyUsers?: Maybe<TaskResponse>;
  backfillRulesForExistingCompanyDefinitions?: Maybe<TaskResponse>;
  backfillTimeoffAllocationRecords?: Maybe<TaskResponse>;
  backfillTimeoffApprovedOn?: Maybe<TaskResponse>;
  backfillTimeoffCarryForwardRecords?: Maybe<TaskResponse>;
  benefitActivityAddOperation?: Maybe<TaskResponse>;
  benefitActivityAddOperationWrapper?: Maybe<BenefitPartnerCountryResponse>;
  /** @deprecated No longer supported */
  benefitActivityUpdateOperation?: Maybe<TaskResponse>;
  benefitActivityUpdateOperationWithId?: Maybe<TaskResponse>;
  benefitActivityUpdateOperations?: Maybe<TaskResponse>;
  benefitCreate?: Maybe<Array<Maybe<Benefit>>>;
  benefitDelete?: Maybe<Benefit>;
  benefitPackageMappingCSV?: Maybe<TaskResponse>;
  benefitPartnerCountryDelete?: Maybe<BenefitPartnerCountry>;
  benefitPartnerMappingBackfillCSV?: Maybe<TaskResponse>;
  benefitPartnerUpdateDocuments?: Maybe<Array<Maybe<BenefitDocument>>>;
  benefitSetTierPackage?: Maybe<Benefit>;
  benefitUpdate?: Maybe<Benefit>;
  benefitUpdateDocument?: Maybe<Benefit>;
  bulkCreatePayrollCycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  bulkOnboardingTrigger?: Maybe<BulkOnboardingJob>;
  bulkOnboardingValidate?: Maybe<BulkOnboardingValidationResult>;
  bulkPayrollCycleConfigUpdate?: Maybe<PayrollCycleConfigsResponse>;
  bulkUpdateCompanyInvoiceStatus?: Maybe<TaskResponse>;
  bulkUpdatePayrollCycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  bulkUploadMemberPayableExtraFees?: Maybe<TaskResponse>;
  bulkUploadMemberPayableFromExternalEntity?: Maybe<TaskResponse>;
  categoryBulkUpload: BulkUploadResponse;
  changeSyncState?: Maybe<TaskResponse>;
  /**
   * Typical use case: to move a timeoff to weekend/holiday as some contracts still work on those days. Doesn't change noOfDays, sessions or anything else.
   * To prevent complicated bugs related to timeoff summary/cycle, there are following rules you should ask the user to follow first:
   * - timeoff must be first submitted by the user (status = APPROVAL_IN_PROGRESS/APPROVED/TAKEN)
   * - can only change to a date range within the months of the old date range. E.g.:
   *     - if old date range is Jul N1 - Jul N2: new date range must be Jul N3 - Jul N4
   *     - if old date range is Jul N1 - Aug N2: new date range must be Jul N3 - Jul 4, or Jul N3 - Aug N4, or Aug N3 - Aug N4
   * If you're sure above validations are not relevant in your case (summary cycle is in another month etc...), pass ignoresValidations=true to "sudo" it (be careful!).
   * If you're not sure, please @Thang @Max for more details
   */
  changeTimeOffDate?: Maybe<TaskResponse>;
  clearGPSyncFailedEvents?: Maybe<TaskResponse>;
  clearGPSyncFailedEventsV2?: Maybe<TaskResponse>;
  companyAcceptInvite?: Maybe<Company>;
  companyBackfillEmployeePricing?: Maybe<TaskResponse>;
  companyBackfillPricing?: Maybe<TaskResponse>;
  companyBindingCreate?: Maybe<CompanyBinding>;
  companyCreate?: Maybe<Company>;
  companyGenerateMSA?: Maybe<Company>;
  /** @deprecated No longer supported */
  companyGroupAddCompany?: Maybe<CompanyGroup>;
  /** @deprecated No longer supported */
  companyGroupAddUsers?: Maybe<CompanyGroup>;
  companyGroupCompanyAdd?: Maybe<CompanyGroup>;
  companyGroupCompanyRemove?: Maybe<CompanyGroup>;
  companyGroupCreate?: Maybe<CompanyGroup>;
  companyGroupUpdate?: Maybe<CompanyGroup>;
  companyGroupUserAdd?: Maybe<CompanyGroup>;
  companyGroupUserRemove?: Maybe<CompanyGroup>;
  companyInactivate?: Maybe<Company>;
  companyInvite?: Maybe<Company>;
  companyLegalEntityActivate?: Maybe<LegalEntity>;
  companyLegalEntityCopyCountryHolidays?: Maybe<TaskResponse>;
  companyLegalEntityCreate?: Maybe<LegalEntity>;
  companyLegalEntityUpdate?: Maybe<LegalEntity>;
  companyMSARequest?: Maybe<Request>;
  companyMSASendForSignature?: Maybe<FileLink>;
  companyMsaUpload?: Maybe<Company>;
  companyOfferingFeeComponentDiscountUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingFeeComponentTierCreate?: Maybe<CompanyOfferingPricing>;
  companyOfferingFeeComponentTierUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingFeeComponentUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingPricingActivate?: Maybe<Array<Maybe<CompanyOfferingPricing>>>;
  companyOfferingPricingCreate?: Maybe<CompanyOfferingPricing>;
  companyOfferingPricingDeactivate?: Maybe<
    Array<Maybe<CompanyOfferingPricing>>
  >;
  companyOfferingPricingDiscountUpdate?: Maybe<CompanyOfferingPricing>;
  companyOfferingsAdd?: Maybe<Array<Maybe<CompanyOffering>>>;
  companyOfferingsUpdate?: Maybe<Array<Maybe<CompanyOffering>>>;
  companyPayableGenerate?: Maybe<CompanyPayableWithErrors>;
  /** @deprecated Use the generateDepositForContract instead */
  companyPayableGenerateForContract?: Maybe<CompanyPayable>;
  companyPayableSyncFromExternalSystem?: Maybe<TaskResponse>;
  companyPayableUploadInvoiceSourceReport?: Maybe<TaskResponse>;
  companyPayrollConsolidatedReportDownloadAsync?: Maybe<PayrollConsolidatedReportDownloadResponse>;
  companyPayrollCycleRefresh?: Maybe<Array<Maybe<CompanyPayrollCycle>>>;
  companyPayrollFileDownloadAsync?: Maybe<PayrollFileDownloadResponse>;
  companyPayrollInputConfirm?: Maybe<CompanyPayrollCycle>;
  companyPayrollOutputConfirm?: Maybe<CompanyPayrollCycle>;
  companyPayrollPayslipPublish?: Maybe<PublishPayslipsResponse>;
  companyPayrollReportsRegenerate?: Maybe<TaskResponse>;
  /** This removes only the SIGNATORY role. Details: https://app.clickup.com/t/865cghnvm */
  companyRemoveSignatory?: Maybe<CompanyUser>;
  companyRemoveUser?: Maybe<CompanyUser>;
  companyRemoveUsers?: Maybe<Array<Maybe<CompanyUser>>>;
  companySlabFXRateUpload?: Maybe<TaskResponse>;
  companyUpdate?: Maybe<Company>;
  companyUpdateBasicDetails?: Maybe<Company>;
  companyUpdateContactDetails?: Maybe<Company>;
  companyUpdateUser?: Maybe<CompanyUser>;
  companyUpdateUsers?: Maybe<Array<Maybe<CompanyUser>>>;
  companyUserAcceptInvite?: Maybe<CompanyUser>;
  companyUserAdd?: Maybe<CompanyUser>;
  companyUserAddApproverInfo?: Maybe<CompanyUser>;
  companyUserAssignCapabilities?: Maybe<CompanyUser>;
  companyUserAssignRole?: Maybe<Array<Maybe<CompanyUser>>>;
  companyUserAssignRoleAndAttributes?: Maybe<CompanyUser>;
  companyUserEditRoleAndAttributes?: Maybe<CompanyUser>;
  companyUserResendApproverInvite?: Maybe<CompanyUser>;
  companyUserRevokeApproverRole?: Maybe<CompanyUser>;
  companyUserRevokeCapability?: Maybe<CompanyUser>;
  companyUserRevokeRole?: Maybe<CompanyUser>;
  companyUserUpdateApproverInfo?: Maybe<CompanyUser>;
  companyUsersAdd?: Maybe<Array<Maybe<CompanyUser>>>;
  /**
   * This directly updates the compensation record, setting columns based on the fields specified in the input (null/omitted = ignored).
   * This means, to really set a column to `null`, you have to raise db-update PRs for now (rare demand).
   * No other side effect except `compensationUpdateKafkaPublisher.publishUpdate(contractId);`.
   * More details on each field: check comments in CompensationRecordUpdateInput.
   *
   * Intended for Product Ops only (alternative of database-updater PRs). Once we have a better way to do this, we can deprecate this
   */
  compensationRecordUpdate?: Maybe<TaskResponse>;
  completeComplianceAssessment?: Maybe<ComplianceRiskAssessment>;
  completeFinancialAssessment?: Maybe<FinancialRiskAssessment>;
  confirmPayrollInput?: Maybe<PayrollCycle>;
  confirmPayslips?: Maybe<ConfirmPayslipsResponse>;
  contractActivate?: Maybe<Contract>;
  contractAddendumGenerate: ContractAddendum;
  contractAddendumSendForSignature?: Maybe<ContractAddendum>;
  contractAgreementCustomUpload?: Maybe<Contract>;
  contractAgreementRegenerate?: Maybe<TaskResponse>;
  contractAgreementReplace?: Maybe<Contract>;
  contractAgreementReplaceWetInk?: Maybe<Contract>;
  contractAssignOperationsUser?: Maybe<Contract>;
  contractChangeCountry?: Maybe<Contract>;
  contractChangeRequestStatusUpdate?: Maybe<Contract>;
  contractChangeRequestUpdate?: Maybe<Contract>;
  contractCompleteEmployeeSignature?: Maybe<Contract>;
  contractCompleteEmployerSignature?: Maybe<Contract>;
  contractCompleteVerification?: Maybe<Contract>;
  contractCompleteVisaEligibilityReview?: Maybe<Contract>;
  contractConfirmContractPrepare?: Maybe<Contract>;
  contractConfirmMemberDetails?: Maybe<Contract>;
  contractConfirmVisaApplicationFiling?: Maybe<Contract>;
  contractConfirmVisaApproved?: Maybe<Contract>;
  contractConfirmVisaInvoicePaid?: Maybe<Contract>;
  contractConfirmWetInkContractPrepare?: Maybe<Contract>;
  contractCreate?: Maybe<Contract>;
  contractCreateBenefitChangeRequests?: Maybe<ContractBenefitChangeRequestResponse>;
  contractCreateBulk?: Maybe<TaskResponse>;
  contractCreateBulkPaymentAccount?: Maybe<TaskResponse>;
  contractDelete?: Maybe<Contract>;
  contractDeleteBenefitDependentDocuments?: Maybe<Contract>;
  contractDeleteCompensationGrant?: Maybe<Array<Maybe<Grant>>>;
  contractDeleteProbationBasePay?: Maybe<Contract>;
  contractDocumentRequestChanges?: Maybe<Contract>;
  contractDrop?: Maybe<Contract>;
  contractEnd?: Maybe<Contract>;
  contractInviteMember?: Maybe<Contract>;
  contractInviteMemberBulk?: Maybe<TaskResponse>;
  contractInviteMemberResend?: Maybe<Contract>;
  contractLinkTemplate?: Maybe<TaskResponse>;
  contractMemberBenefitCompleted?: Maybe<Contract>;
  contractMemberCompleted?: Maybe<Contract>;
  contractMemberStarted?: Maybe<Contract>;
  contractOffboard?: Maybe<Contract>;
  contractOffboardingCancel?: Maybe<Contract>;
  contractOffboardingCancelV2?: Maybe<ContractOffboarding>;
  contractOffboardingComplete?: Maybe<ContractOffboarding>;
  contractOffboardingDidNotJoinInitialise?: Maybe<ContractOffboarding>;
  contractOffboardingReschedule?: Maybe<ContractOffboarding>;
  contractOffboardingResignationInitialise?: Maybe<ContractOffboarding>;
  contractOffboardingTerminationInitialise?: Maybe<ContractOffboarding>;
  contractOffboardingTriggerEndContractScheduler?: Maybe<TaskResponse>;
  contractOffboardingVerify?: Maybe<ContractOffboarding>;
  contractOfflineSignatureProcess?: Maybe<Contract>;
  /** @deprecated No longer supported */
  contractOnboardingUpdate?: Maybe<ContractOnboarding>;
  contractOrderFormSendForSignature?: Maybe<Contract>;
  contractRequestChangesFromMember?: Maybe<Contract>;
  contractRevise?: Maybe<Contract>;
  contractRevoke?: Maybe<Contract>;
  contractSendAgreementToMemberForSignature?: Maybe<Contract>;
  contractSendForSignature?: Maybe<Contract>;
  contractSendOfferLetter?: Maybe<TaskResponse>;
  contractSendReminderForSignature?: Maybe<Contract>;
  contractSetTest?: Maybe<Contract>;
  contractStartVerification?: Maybe<Contract>;
  contractTriggerCompleteOnboardingReminderEmail?: Maybe<TaskResponse>;
  contractTriggerFixedTermEndingEmail?: Maybe<TaskResponse>;
  contractTriggerJoingDayComplianceEmail?: Maybe<TaskResponse>;
  contractTriggerJoingDayEmail?: Maybe<TaskResponse>;
  contractTriggerPayrollFormsReminderEmail?: Maybe<TaskResponse>;
  contractUnassignOperationsUser?: Maybe<Contract>;
  contractUnblock?: Maybe<Contract>;
  contractUpdateBenefitDependentBasicDetails?: Maybe<Contract>;
  contractUpdateBenefitDependentDocuments?: Maybe<Contract>;
  contractUpdateBenefitDependentPlan?: Maybe<Contract>;
  contractUpdateBenefits?: Maybe<Contract>;
  contractUpdateCompensation?: Maybe<Contract>;
  contractUpdateCompliance?: Maybe<Contract>;
  contractUpdateDeductions?: Maybe<Array<Maybe<Deduction>>>;
  contractUpdateEmployment?: Maybe<Contract>;
  contractUpdateLegalEntity?: Maybe<Contract>;
  contractUpdatePayrollStartDateBulk?: Maybe<Array<Maybe<Contract>>>;
  contractUpdateTimeOffEntitlements?: Maybe<Contract>;
  contractWorkEmailUpdate?: Maybe<Contract>;
  contractWorkShiftBulkUpdate?: Maybe<TaskResponse>;
  countryCompensationDefinitionDelete?: Maybe<TaskResponse>;
  countryCompensationDefinitionUpsert?: Maybe<CompensationStructureDefinition>;
  countryComplianceParamTypeUpdate?: Maybe<ComplianceParamType>;
  countryComplianceParameterUpdate?: Maybe<ComplianceParamDefinition>;
  countryLegalDataDelete?: Maybe<TaskResponse>;
  countryLegalDataUpsert?: Maybe<DataFieldDefinition>;
  countryUpdateLegalDocumentRequirement?: Maybe<LegalDocumentRequirement>;
  countryUpdateMemberDataDate?: Maybe<Member>;
  countryUpdateMemberDataText?: Maybe<Member>;
  countryUpdateOnboardingFlowEnablement?: Maybe<CountryCompliance>;
  createDemoCycles: Array<PayrollCycle>;
  createInsuranceCoverageGroup?: Maybe<InsuranceCoverageGroup>;
  createInsuranceRelation?: Maybe<InsuranceRelation>;
  createInsuranceTier?: Maybe<InsuranceTier>;
  createPayrollContractException?: Maybe<PayrollContractException>;
  createPayrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
  createPayrollWorkflow: PayrollWorkflowResponse;
  currencyRefreshFXRates?: Maybe<TaskResponse>;
  currencyUploadExchangeRates?: Maybe<TaskResponse>;
  customPayrollReportConfigsCreate: Array<CustomPayrollReportConfig>;
  customPayrollReportConfigsUpdate: Array<CustomPayrollReportConfig>;
  customPayrollReportCreate: Array<CustomPayrollReportDetails>;
  customPayrollReportFileUpdate: CustomPayrollReportDetails;
  customerIntegrationDisconnect?: Maybe<CustomerIntegration>;
  customerUpdatePayrollWorkflow: CustomerPayrollWorkflowResponse;
  deleteAssessmentDocument?: Maybe<TaskResponse>;
  deleteCompanyPayInMethod?: Maybe<TaskResponse>;
  deletePayrollContractException?: Maybe<TaskResponse>;
  deletePayrollPaymentBatch?: Maybe<TaskResponse>;
  deletePerformanceReview?: Maybe<Array<Maybe<PerformanceReview>>>;
  deleteRecruiter?: Maybe<TaskResponse>;
  deleteSalaryReview?: Maybe<Array<Maybe<SalaryReview>>>;
  departmentAssignEmployees?: Maybe<Department>;
  departmentCreate?: Maybe<Department>;
  departmentDelete?: Maybe<Department>;
  departmentUpdate?: Maybe<Department>;
  dismissSyncResult?: Maybe<TaskResponse>;
  disputeCreate: Dispute;
  disputeResolve: Dispute;
  /** @deprecated Use saveAndDownloadCompanyQuotation instead */
  downloadCompanyQuotation?: Maybe<FileLink>;
  dummy?: Maybe<Scalars['Boolean']>;
  dummyAccessibilityMutation?: Maybe<Scalars['Boolean']>;
  emailTemplateUpsert?: Maybe<TaskResponse>;
  enableCompanyPayInMethod?: Maybe<CompanyPayInMethod>;
  equipmentProcurementCreate?: Maybe<EquipmentProcurement>;
  equipmentProductsUpdate?: Maybe<TaskResponse>;
  equipmentQuotationCreate?: Maybe<TaskResponse>;
  equipmentsUpdate?: Maybe<TaskResponse>;
  executeBatchPayment?: Maybe<Array<Maybe<PayrollPaymentBatch>>>;
  /** @deprecated No longer supported */
  expenseApprove?: Maybe<Expense>;
  expenseBulkRevoke?: Maybe<ExpenseUpdateBulkResponse>;
  /** @deprecated No longer supported */
  expenseCreate?: Maybe<Expense>;
  expenseDelete?: Maybe<Expense>;
  expenseItemMigrateToCategoryId?: Maybe<TaskResponse>;
  /** @deprecated No longer supported */
  expenseReject?: Maybe<Expense>;
  expenseRevoke?: Maybe<Expense>;
  expenseSaveAsDraft?: Maybe<Expense>;
  expenseSubmit?: Maybe<Expense>;
  expenseUpdate?: Maybe<Expense>;
  expenseUpdateBulkAsPaid?: Maybe<ExpenseUpdateBulkResponse>;
  expenseUpdateBulkAsToBePaid?: Maybe<ExpenseUpdateBulkResponse>;
  feeComponentDefinitionActivate?: Maybe<Array<Maybe<FeeComponentDefinition>>>;
  feeComponentDefinitionCreate?: Maybe<FeeComponentDefinition>;
  feeComponentDefinitionDeactivate?: Maybe<
    Array<Maybe<FeeComponentDefinition>>
  >;
  feeComponentDefinitionTierCreate?: Maybe<FeeComponentTierDefinition>;
  feeComponentDefinitionTierUpdate?: Maybe<FeeComponentTierDefinition>;
  feeComponentDefinitionUpdate?: Maybe<FeeComponentDefinition>;
  fetchLatestEmployeesFromPlatform?: Maybe<FetchEmployeesResult>;
  financialTransactionCollect?: Maybe<Scalars['Boolean']>;
  financialTransactionGenerate: Array<TransactionCommand>;
  fixEntitlementChangeRecords?: Maybe<TaskResponse>;
  /**  temporary mutation to fix non-prorated timeoff allocation  */
  fixTimeoffAllocations?: Maybe<TaskResponse>;
  forceUploadPayslip?: Maybe<Payslip>;
  forwardItem?: Maybe<ApprovalItem>;
  generateCompanyQuotation?: Maybe<CompanyQuotation>;
  generateCompensationGrantDocument?: Maybe<Grant>;
  generateDepositForContract?: Maybe<CompanyPayable>;
  /** @deprecated Use `verifyTriNetCredential` instead */
  getTrinetCredential?: Maybe<TrinetCredentialResult>;
  /** Throws exception if there's already a holiday on the given day/country. If you want to edit it, first use holidaysDelete() */
  holidaysAdd?: Maybe<TaskResponse>;
  /** WARNING: There's no holiday_aud table for you to easily recover the records. It's recommended to take an export first */
  holidaysDelete?: Maybe<TaskResponse>;
  importEmployees?: Maybe<TaskResponse>;
  initiateAutoDebit?: Maybe<TaskResponse>;
  linkContractsToPayrollCycleConfig?: Maybe<TaskResponse>;
  managerAssignDirectReports?: Maybe<Manager>;
  managerCreate?: Maybe<Manager>;
  managerDelete?: Maybe<Manager>;
  managerUpdate?: Maybe<Manager>;
  managerValidateDirectReports?: Maybe<DirectReportValidationResult>;
  markFailCompanyPayInMethod?: Maybe<CompanyPayInMethod>;
  memberBackFillBankIdentifier?: Maybe<TaskResponse>;
  memberCancelUpdateRequest?: Maybe<Member>;
  memberChangePrimaryEmail?: Maybe<Member>;
  memberCreate?: Maybe<Member>;
  /** @deprecated No longer supported */
  memberDelete?: Maybe<Member>;
  memberPayBackfillBillingCurrency?: Maybe<TaskResponse>;
  /**
   * For Finance Ops to initiate the payouts after the payins are confirmed received.<br>
   * Ideally FE should send ids of valid member-payables (i.e. the status must be PAY_IN_RECEIVED, the contract must have paymentAccountId etc...)<br>
   * BE will validate again and exclude invalid member-payables from the process.<br>
   *
   * ids: the member payable ids to be initiated
   */
  memberPayableBulkInitiatePayout?: Maybe<TaskResponse>;
  memberPayableCompanyInvoiceConfirmPayment?: Maybe<
    Array<Maybe<MemberPayable>>
  >;
  memberPayableCompanyInvoiceCreatePayIn?: Maybe<PayInInfo>;
  memberPayableCompanyInvoiceMarkAsReadyForPayouts?: Maybe<MemberPayableCompanyInvoice>;
  /**
   * To generate the invoices, triggered by customer
   * If an invoice is already created, it is skipped
   * The invoice must be raised for the same member payable invoice items
   * Case:
   * Company user may not complete the payment for the invoice and stop midway. Hence the invoice is created and kept in draft until revisited
   */
  memberPayableCompanyInvoiceSubmit?: Maybe<MemberPayableCompanyInvoice>;
  memberPayableDelete?: Maybe<MemberPayable>;
  memberPayableGeneration?: Maybe<TaskResponse>;
  memberPayableSubmit?: Maybe<MemberPayable>;
  memberPayableToggleAutoGeneration?: Maybe<TaskResponse>;
  memberPayableUpdate?: Maybe<MemberPayable>;
  memberPayableUpdateBulkAsApproved?: Maybe<Array<Maybe<MemberPayable>>>;
  memberPayableUpdateBulkAsPaid?: Maybe<MemberPayableUpdateBulkResponse>;
  memberPayableUpdateBulkAsRejected?: Maybe<Array<Maybe<MemberPayable>>>;
  memberRemoveProfilePicture?: Maybe<Member>;
  memberSendUpdateRequest?: Maybe<Member>;
  memberUpdate?: Maybe<Member>;
  memberUpdateBankDetails?: Maybe<Member>;
  memberUpdateBasicDetails?: Maybe<Member>;
  memberUpdateDocument?: Maybe<Member>;
  memberUpdateLegalData?: Maybe<Member>;
  memberUpdateVisaDocument?: Maybe<Member>;
  memberUpsertAddress?: Maybe<Member>;
  memberUpsertBasicInfo?: Maybe<Member>;
  memberUpsertContactInfo?: Maybe<Member>;
  /** @deprecated not required */
  memberUpsertCustomFields?: Maybe<Member>;
  memberUpsertEducation?: Maybe<Member>;
  memberUpsertEmergencyContact?: Maybe<Member>;
  memberUpsertIdentificationDetails?: Maybe<Member>;
  memberUpsertPersonalInfo?: Maybe<Member>;
  memberUpsertPreviousEmployer?: Maybe<Member>;
  memberUpsertProfilePicture?: Maybe<Member>;
  /** to update end_date/end_session based on back_to_work_date/back_to_work_session */
  migrateFromBackToWorkDateToEndDate?: Maybe<TaskResponse>;
  migrateOldPayslips?: Maybe<TaskResponse>;
  migrateSeatId?: Maybe<TaskResponse>;
  moveToNextPayslipUploadWorkflowStep?: Maybe<PayrollCycle>;
  moveToNextStatus?: Maybe<PayrollCycle>;
  msaAddendumConfirm?: Maybe<MsaAddendum>;
  msaAddendumDocumentGenerate?: Maybe<MsaAddendum>;
  msaAddendumDocumentRevoke?: Maybe<MsaAddendum>;
  msaAddendumDocumentSendForSignature?: Maybe<MsaAddendum>;
  msaAddendumDraftDelete?: Maybe<Scalars['ID']>;
  msaAddendumDraftEdit?: Maybe<MsaAddendum>;
  msaAddendumUploadCustom?: Maybe<MsaAddendum>;
  offboardingClientApproveLwd?: Maybe<Offboarding>;
  offboardingConfirmAssetsRecovery?: Maybe<Offboarding>;
  offboardingExperienceInitiatialise: OffboardingExperienceState;
  offboardingInitialize?: Maybe<Offboarding>;
  offboardingInputLwd?: Maybe<Offboarding>;
  offboardingNextState?: Maybe<Offboarding>;
  offboardingOpsApproveLwd?: Maybe<Offboarding>;
  ok?: Maybe<Scalars['Boolean']>;
  operationsUserAcceptInvite?: Maybe<OperationsUser>;
  operationsUserCreate?: Maybe<OperationsUser>;
  operationsUserDelete?: Maybe<TaskResponse>;
  operationsUserUpdate?: Maybe<OperationsUser>;
  operationsUserUpdateRoles?: Maybe<OperationsUser>;
  opsUpdatePayrollWorkflow: PayrollWorkflowResponse;
  optOutInsurance?: Maybe<ContractBenefit>;
  orderFormRegenerate?: Maybe<TaskResponse>;
  orgDirectoryAssignReportsTo?: Maybe<OrgDirectory>;
  orgDirectoryValidateReportsTo?: Maybe<ReportsToValidationResult>;
  /** Assign users to a role */
  orgRoleAssignManagers?: Maybe<OrgRole>;
  orgRoleCreate?: Maybe<OrgRole>;
  orgRoleDelete?: Maybe<OrgRole>;
  orgRoleUpdate?: Maybe<OrgRole>;
  partnerAcceptInvite?: Maybe<Partner>;
  partnerAddCountryCapability?: Maybe<Partner>;
  partnerComponentBulkUpload: BulkUploadResponse;
  partnerCreate?: Maybe<Partner>;
  partnerEORAssignContract?: Maybe<ContractEorPartner>;
  partnerInvite?: Maybe<Partner>;
  partnerMappingBackfillCSV?: Maybe<TaskResponse>;
  partnerPayrollAssignContract?: Maybe<ContractPayrollPartner>;
  /** @deprecated No longer supported */
  partnerPayrollCreate?: Maybe<PartnerPayroll>;
  partnerPayrollPayslipGenerateForPayrollCycle?: Maybe<TaskResponse>;
  partnerPayrollRegistrationCompleted?: Maybe<ContractPayrollPartner>;
  partnerPayrollSendForReview?: Maybe<PartnerPayroll>;
  partnerPayrollStartRegistration?: Maybe<ContractPayrollPartner>;
  /** @deprecated No longer supported */
  partnerPayrollUploadPayslip?: Maybe<TaskResponse>;
  partnerUpdatePayrollWorkflow: PayrollWorkflowResponse;
  partnerUserAcceptInvite?: Maybe<PartnerUser>;
  partnerUserChangeRole?: Maybe<PartnerUser>;
  partnerUserCreate?: Maybe<PartnerUser>;
  partnerUserDelete?: Maybe<TaskResponse>;
  partnerUserUpdateOperator?: Maybe<PartnerUser>;
  payInProcess?: Maybe<Scalars['Boolean']>;
  paySupplementBulkUpload?: Maybe<TaskResponse>;
  paySupplementCreate?: Maybe<PaySupplement>;
  paySupplementPaid?: Maybe<PaySupplement>;
  paySupplementReject?: Maybe<PaySupplement>;
  paySupplementRevoke?: Maybe<PaySupplement>;
  paySupplementUpdate?: Maybe<PaySupplement>;
  paySupplementUpdateBulkAsPaid?: Maybe<PaySupplementUpdateBulkResponse>;
  paySupplementUpdateBulkAsRevoked?: Maybe<PaySupplementUpdateBulkResponse>;
  paySupplementUpdateBulkAsToBePaid?: Maybe<PaySupplementUpdateBulkResponse>;
  paymentBundleCreate?: Maybe<PaymentBundle>;
  paymentBundleDelete?: Maybe<PaymentBundle>;
  paymentBundleMarkAsPaid?: Maybe<PaymentBundle>;
  paymentBundleMarkAsProcessing?: Maybe<PaymentBundle>;
  paymentBundleUpdate?: Maybe<PaymentBundle>;
  payrollBulkUploadConfirm?: Maybe<TaskResponse>;
  payrollBulkUploadCreate?: Maybe<UploadUrlResponse>;
  payrollBulkUploadTemplatesRefresh?: Maybe<TaskResponse>;
  payrollBulkUploadValidate?: Maybe<BulkUploadValidationResponse>;
  payrollCycleConfigBulkCreate?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleConfigCreate?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleConfigUpdate?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleCreate?: Maybe<PayrollCycle>;
  payrollCycleSendForReview?: Maybe<PayrollCycle>;
  payrollCycleStatusBulkUpdate?: Maybe<Array<Maybe<PayrollCycle>>>;
  payrollCycleUpdateBulkAsClosed?: Maybe<Array<Maybe<PayrollCycle>>>;
  payrollCycleUpdateBulkAsSuspended?: Maybe<Array<Maybe<PayrollCycle>>>;
  payrollInputAuditTrailsRemove?: Maybe<TaskResponse>;
  payrollInputFileDownloadAsync?: Maybe<InputFileDownload>;
  payrollInputFilePartnerBulkDownloadAsync?: Maybe<InputFileDownload>;
  payrollNoteForContract?: Maybe<Contract>;
  payrollOffCycleCreate?: Maybe<PayrollCycle>;
  /** 86cuedugx - For Ops to sync offline-signed salary revisions to the system. Note: only EMPLOYEE (EOR) & HR_MEMBER (PEO) are supported */
  performanceReviewBulkCreateAsApproved?: Maybe<TaskResponse>;
  placeholder?: Maybe<Scalars['Boolean']>;
  platformBambooHRAccountTokenUpdate?: Maybe<TaskResponse>;
  platformBatchedUpdate: Array<PlatformUpdateTaskResponse>;
  platformCompanyTokenUpdate?: Maybe<TaskResponse>;
  platformUpdate: PlatformUpdateTaskResponse;
  pricingPlanActivate: PricingPlan;
  pricingPlanAutofill?: Maybe<PricingPlan>;
  pricingPlanCloseSeats?: Maybe<PricingPlan>;
  pricingPlanCreate?: Maybe<PricingPlan>;
  pricingPlanDelete?: Maybe<PricingPlan>;
  pricingPlanExpand?: Maybe<PricingPlan>;
  pricingPlanMigrate: PricingPlanMigrateResponse;
  pricingPlanUpdate?: Maybe<PricingPlan>;
  providerKnitAuthToken?: Maybe<KnitAuthResponse>;
  publishPayslips?: Maybe<PublishPayslipsResponse>;
  /** Use for manually trigger only */
  reGeneratePayrollCycleInputFile?: Maybe<TaskResponse>;
  reInitiateActivation?: Maybe<TaskResponse>;
  recreatePayrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
  refreshActivePayrollSnapshots?: Maybe<TaskResponse>;
  refreshContractReferencesForPayrollCycle?: Maybe<TaskResponse>;
  refreshPaymentAccountRequirements?: Maybe<Scalars['Boolean']>;
  refreshPayrollSnapshot?: Maybe<TaskResponse>;
  refreshPayrollSnapshotByWatermark?: Maybe<TaskResponse>;
  /** @deprecated use updateDomainItems instead */
  refreshPayrollSnapshotDomainForce?: Maybe<TaskResponse>;
  refreshPayrollSnapshots?: Maybe<TaskResponse>;
  /** @deprecated Use contractAgreementRegenerate instead */
  regenerateAgreement?: Maybe<TaskResponse>;
  regeneratePayslipZip?: Maybe<TaskResponse>;
  registerDirectDebitMandate?: Maybe<CompanyPayInMethod>;
  rejectItem?: Maybe<ApprovalItem>;
  relinkContractToAnotherPayrollCycleConfig?: Maybe<TaskResponse>;
  salaryCalculationEmail?: Maybe<TaskResponse>;
  salaryCalculatorRequestEmail?: Maybe<TaskResponse>;
  saveAndDownloadCompanyQuotation?: Maybe<CompanyQuotation>;
  saveIntegrationEntityMappingStatus?: Maybe<TaskResponse>;
  saveIntegrationFieldsMapping?: Maybe<TaskResponse>;
  seatAssign?: Maybe<PricingPlan>;
  seatRelease?: Maybe<PricingPlan>;
  sendEmailToOnboardingSpecialist?: Maybe<SendEmailToOnboardingSpecialistResponse>;
  sendImportedMemberInvites?: Maybe<TaskResponse>;
  sendInvoiceReminderNotificationToCustomer?: Maybe<TaskResponse>;
  sendInvoiceReminderNotificationToMember?: Maybe<TaskResponse>;
  sendInvoiceToContactEmail?: Maybe<TaskResponse>;
  sendKYBRequirementsEmail?: Maybe<TaskResponse>;
  sendSyncedMemberInvites?: Maybe<TaskResponse>;
  sendToSignatoryPerformanceReview?: Maybe<PerformanceReview>;
  sendToSignatorySalaryReview?: Maybe<SalaryReview>;
  setDisabledPayrollCycleConfigToContract?: Maybe<TaskResponse>;
  startIncomingSync?: Maybe<TaskResponse>;
  startNewKYBAssessment?: Maybe<KybAssessment>;
  syncEORManually?: Maybe<SyncEorManuallyResult>;
  syncEmployees?: Maybe<TaskResponse>;
  syncPayrollCycle?: Maybe<TaskResponse>;
  syncTurnOff?: Maybe<TaskResponse>;
  /** @deprecated The name is misleading and parameters aren't according to best practices */
  syncXeroInvoicesToCore?: Maybe<TaskResponse>;
  templateDelete?: Maybe<Template>;
  templateUpsert?: Maybe<Template>;
  terminatePayrollWorkflow: PayrollWorkflowResponse;
  test?: Maybe<Scalars['Boolean']>;
  timeOffBulkRevoke?: Maybe<TaskResponse>;
  /**
   * # i.e. submit (send for approval). The timeoff can be a new one or existing DRAFT one, based on `input.id`. Refer to input TimeOffCreateInput.
   * # Note: FE is sending `contractId: ID` even in "member" exp (personal view) which is a security issue: MemberA can raise a timeoff for memberB by mistake or intention!.
   * # TODO: 1. FE: not to pass `contractId` if in member exp. 2. BE: will ignore `contractId` if in member exp, and query the contract from `currentUser` instead.
   * # In company exp (team view): `contractId` is still used as usual, to indicate whom the companyUser is raising on behalf of.
   */
  timeOffCreate?: Maybe<TimeOff>;
  timeOffDelete?: Maybe<TaskResponse>;
  timeOffPolicyAssignUsers?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyCreate?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyDelete?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyUpdate?: Maybe<TimeOffTypeDefinition>;
  timeOffPolicyValidateUsers?: Maybe<TimeOffPolicyUsersValidationResult>;
  /** to set the status back to DRAFT. */
  timeOffRevoke?: Maybe<TimeOff>;
  /** to create a (new) timeoff with status=DRAFT. If you want to update an existing DRAFT timeoff, use `timeOffUpdate`, not this */
  timeOffSaveAsDraft?: Maybe<TimeOff>;
  timeOffTypeCreate?: Maybe<TimeOffTypeInfo>;
  timeOffTypeDelete?: Maybe<TimeOffTypeInfo>;
  timeOffTypeUpdate?: Maybe<TimeOffTypeInfo>;
  /** Update. Owner who created it. Does not change status */
  timeOffUpdate?: Maybe<TimeOff>;
  timeOffUpdateCompanyDefinitions?: Maybe<TaskResponse>;
  timeOffUpdateCountryDefinitions?: Maybe<TaskResponse>;
  timesheetChangeStatus?: Maybe<Timesheet>;
  /** @deprecated Use timesheetUploadBulk instead */
  timesheetCreateBulk?: Maybe<TaskResponse>;
  timesheetCreateOrUpdate?: Maybe<Timesheet>;
  timesheetEntriesApprove?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesCreateOrUpdate?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesDelete?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesReject?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesRevoke?: Maybe<Array<Maybe<TimesheetEntry>>>;
  timesheetEntriesSubmit?: Maybe<Array<Maybe<TimesheetEntry>>>;
  /** @deprecated Use timesheetEntriesCreateOrUpdate instead */
  timesheetEntryCreateOrUpdate?: Maybe<TimesheetEntry>;
  timesheetGenerateIfMissing?: Maybe<TaskResponse>;
  timesheetUploadBulk?: Maybe<TimesheetUploadResult>;
  toggleAutoDebit?: Maybe<CompanyPayInPreferenceAutoDebit>;
  triggerCarryForwardExpiration?: Maybe<TaskResponse>;
  triggerChangeRequestDelayedEmailProcess?: Maybe<TaskResponse>;
  triggerCompleteWorkEmailAndEmployeeIdReminderEmailProcess?: Maybe<TaskResponse>;
  triggerContractBenefitSetEndedStatus?: Maybe<TaskResponse>;
  triggerContractWorkEmailReminderOnStartDayEmailProcess?: Maybe<TaskResponse>;
  triggerEmployeeTimesheetEntryAutoApproval?: Maybe<TaskResponse>;
  triggerEmployeeTimesheetsAutoSubmission?: Maybe<TaskResponse>;
  triggerEmployeeTimesheetsGeneration?: Maybe<TaskResponse>;
  triggerEndContractForFreelancerAfterLWD?: Maybe<TaskResponse>;
  triggerExpenseReminderEmailProcess?: Maybe<TaskResponse>;
  triggerLegacyTimeoffReallocation?: Maybe<TaskResponse>;
  triggerMemberExpenseSubmissionReminders?: Maybe<TaskResponse>;
  triggerMemberTimeCardDailyReminders?: Maybe<TaskResponse>;
  triggerMemberTimesheetApprovedHoursReminders?: Maybe<TaskResponse>;
  triggerOffboardingReminderEmailToOps?: Maybe<TaskResponse>;
  triggerOnboardingReminderEmailProcess?: Maybe<TaskResponse>;
  triggerPaySupplementAutoCreateProcess?: Maybe<TaskResponse>;
  triggerPaySupplementMonthlyReminderEmailProcess?: Maybe<TaskResponse>;
  triggerPaySupplementReminderEmailProcess?: Maybe<TaskResponse>;
  triggerProbationEndReminderEmailProcess?: Maybe<TaskResponse>;
  triggerReminderEmailForSalaryRevisionSignature?: Maybe<TaskResponse>;
  triggerReviewPayrollInputReminderEmail?: Maybe<TaskResponse>;
  triggerReviewPayrollReportReminderEmail?: Maybe<TaskResponse>;
  triggerTimeoffAllocation?: Maybe<TaskResponse>;
  triggerTimeoffCarryForward?: Maybe<TaskResponse>;
  triggerTimeoffUpdateProcess?: Maybe<TaskResponse>;
  triggerTimesheetApproveSubmittedHoursReminderEmailProcess?: Maybe<TaskResponse>;
  triggerTimesheetApproveSubmittedHoursReminderEmailsToAdmins?: Maybe<TaskResponse>;
  triggerTimesheetsAutoSubmissionEmailProcess?: Maybe<TaskResponse>;
  triggerUnpaidPaymentBundleReminderEmailProcess?: Maybe<TaskResponse>;
  triggerUpcomingMonthlyInvoiceEmailProcess?: Maybe<TaskResponse>;
  triggerUpdateExpiredBanners?: Maybe<TaskResponse>;
  triggerUpdateScheduledBanners?: Maybe<TaskResponse>;
  unlinkContractFromPayrollCycleConfig?: Maybe<TaskResponse>;
  updateAndFixTimeOffs?: Maybe<TaskResponse>;
  updateCompanyChargebackPeriod?: Maybe<CompanyChargebackResult>;
  updateCompanyPayInMethodChargeBack?: Maybe<CompanyPayInMethod>;
  updateCompanyPricing?: Maybe<Pricing>;
  updateCompensationPaymentInfo?: Maybe<TaskResponse>;
  updateComplianceAssessmentData?: Maybe<ComplianceRiskAssessment>;
  updateComplianceAssessmentDocuments?: Maybe<ComplianceRiskAssessment>;
  updateContractBenefitChangeRequest?: Maybe<
    Array<Maybe<ContractBenefitChangeRequestResponse>>
  >;
  updateContractPaymentDetails?: Maybe<ContractPaymentAccount>;
  updateDemoCycles: Array<PayrollCycle>;
  updateDomainItems?: Maybe<TaskResponse>;
  updateFinancialAssessmentData?: Maybe<FinancialRiskAssessment>;
  updateFinancialAssessmentDocuments?: Maybe<FinancialRiskAssessment>;
  updateInsurance?: Maybe<ContractBenefit>;
  updateInsuranceStatus?: Maybe<InsuranceStatusResponse>;
  updateInvoiceAndBundleOnExternalInvoicePaid?: Maybe<TaskResponse>;
  updateOrCreateCompanyBinding?: Maybe<CompanyBinding>;
  updatePayrollParserConfig?: Maybe<PayrollParserConfig>;
  updatePayrollPartnerMemberIdToContractId?: Maybe<TaskResponse>;
  updatePayrollPaymentBatchStatus?: Maybe<Array<Maybe<PayrollPaymentBatch>>>;
  updatePayrollPaymentStatus?: Maybe<Array<Maybe<PayrollPayment>>>;
  updatePayrollReconciliationResultStatus: Scalars['ID'];
  updatePayrollReportGeneratorConfig?: Maybe<ReportGeneratorConfig>;
  updatePayrollWorkflow: PayrollWorkflowResponse;
  /** @deprecated No longer supported */
  updateTimeoffDefinition?: Maybe<TaskResponse>;
  uploadAssessmentDocument?: Maybe<Scalars['ID']>;
  uploadContractIdMappingFile?: Maybe<Array<Maybe<ContractIdMappingUpload>>>;
  /** @deprecated No longer supported */
  uploadPayrollFile?: Maybe<PayrollReportUpload>;
  uploadPayrollOutputReport?: Maybe<PayrollReportUpload>;
  uploadSignedAgreement?: Maybe<CompanyPayInMethod>;
  upsertCompanyPayInMethodBankDetails?: Maybe<CompanyPayInMethod>;
  upsertContractBenefitBanners?: Maybe<Array<Maybe<ContractBenefitBanner>>>;
  upsertCountryContractClauses?: Maybe<TaskResponse>;
  upsertCountryDocument?: Maybe<FileLink>;
  upsertCountryLegalDataDefinitions?: Maybe<TaskResponse>;
  upsertPerformanceReview?: Maybe<PerformanceReview>;
  upsertRecruiter?: Maybe<Recruiter>;
  upsertSSOPreferenceForCompany?: Maybe<SsoPreference>;
  upsertSalaryReview?: Maybe<SalaryReview>;
  validatePayslips?: Maybe<TaskResponse>;
  verifyTriNetCredential?: Maybe<TrinetCredentialResult>;
};

export type Mutation_PaymentInstructionFileGenerateArgs = {
  data: PaymentInstructionFileGenerateInput;
};

export type Mutation_PaymentInstructionFileGenerateV2Args = {
  data: FileGenerationRequestData;
};

export type Mutation_PennyTestInitiateArgs = {
  data: PennyTestInitiateRequestData;
};

export type Mutation_ActivateReviewsArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
};

export type Mutation_ApproveItemArgs = {
  category: ApprovalCategory;
  comment?: InputMaybe<Scalars['String']>;
  itemId: Scalars['ID'];
};

export type Mutation_AssignOperationUsersArgs = {
  accountManagerUserId?: InputMaybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  csmUserId?: InputMaybe<Scalars['ID']>;
  salesUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_AssignPayrollSnapshotDomainArgs = {
  value: PayrollSnapshotAssignDomainInput;
};

export type Mutation_AsyncPayrollInputFileDownloadArgs = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_AsyncPayrollInputFilePartnerBulkDownloadArgs = {
  filter?: InputMaybe<PayrollCycleFilter>;
};

export type Mutation_AutoDebitManualTriggerArgs = {
  invoiceDueDate: Scalars['Date'];
};

export type Mutation_BackFillKybForCompaniesArgs = {
  companyIds: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_BackfillEntitlementDefinitionIdsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_BackfillHrisManagersFromCompanyUsersArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_BackfillRulesForExistingCompanyDefinitionsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  forAll?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_BackfillTimeoffAllocationRecordsArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_BackfillTimeoffApprovedOnArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_BackfillTimeoffCarryForwardRecordsArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_BenefitActivityAddOperationArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitActivityAddOperationWrapperArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitActivityUpdateOperationArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitActivityUpdateOperationWithIdArgs = {
  file: Scalars['Upload'];
  id: Scalars['String'];
};

export type Mutation_BenefitActivityUpdateOperationsArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
};

export type Mutation_BenefitCreateArgs = {
  input: Array<BenefitInput>;
};

export type Mutation_BenefitDeleteArgs = {
  id: Scalars['String'];
};

export type Mutation_BenefitPackageMappingCsvArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitPartnerCountryDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_BenefitPartnerMappingBackfillCsvArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BenefitPartnerUpdateDocumentsArgs = {
  data: Array<BenefitDocumentInput>;
  id: Scalars['ID'];
};

export type Mutation_BenefitSetTierPackageArgs = {
  id: Scalars['String'];
  tier: BenefitTierInput;
};

export type Mutation_BenefitUpdateArgs = {
  id: Scalars['String'];
  input: BenefitInput;
};

export type Mutation_BenefitUpdateDocumentArgs = {
  documents: Array<Scalars['Upload']>;
  id: Scalars['String'];
  key: BenefitDocumentType;
};

export type Mutation_BulkCreatePayrollCyclesArgs = {
  value: PayrollCycleBulkCreateInput;
};

export type Mutation_BulkOnboardingTriggerArgs = {
  employeeDataFile: Scalars['Upload'];
  options: BulkOnboardingOptions;
};

export type Mutation_BulkOnboardingValidateArgs = {
  employeeDataFile: Scalars['Upload'];
  options: BulkOnboardingOptions;
};

export type Mutation_BulkPayrollCycleConfigUpdateArgs = {
  input: BulkPayrollConfigUpdateInput;
};

export type Mutation_BulkUpdateCompanyInvoiceStatusArgs = {
  companyInvoiceIds: Array<InputMaybe<Scalars['ID']>>;
  status: MemberPayableCompanyInvoiceStatus;
};

export type Mutation_BulkUpdatePayrollCyclesArgs = {
  value: PayrollCycleUpdateInput;
};

export type Mutation_BulkUploadMemberPayableExtraFeesArgs = {
  file: Scalars['Upload'];
};

export type Mutation_BulkUploadMemberPayableFromExternalEntityArgs = {
  file: Scalars['Upload'];
};

export type Mutation_CategoryBulkUploadArgs = {
  input: Scalars['Upload'];
};

export type Mutation_ChangeSyncStateArgs = {
  integrationId: Scalars['ID'];
  on: Scalars['Boolean'];
  syncType: SyncType;
};

export type Mutation_ChangeTimeOffDateArgs = {
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  ignoresValidations?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
};

export type Mutation_ClearGpSyncFailedEventsArgs = {
  input: ClearGpSyncFailedEventsInput;
};

export type Mutation_ClearGpSyncFailedEventsV2Args = {
  companyId: Scalars['ID'];
  platformID: Scalars['ID'];
};

export type Mutation_CompanyAcceptInviteArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_CompanyBackfillEmployeePricingArgs = {
  employeePricing: Scalars['Upload'];
};

export type Mutation_CompanyBackfillPricingArgs = {
  employeePricing: Scalars['Upload'];
  pricing: Scalars['Upload'];
};

export type Mutation_CompanyBindingCreateArgs = {
  input: CompanyBindingInput;
};

export type Mutation_CompanyCreateArgs = {
  company?: InputMaybe<CreateCompanyInput>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_CompanyGenerateMsaArgs = {
  companyId: Scalars['ID'];
};

export type Mutation_CompanyGroupAddCompanyArgs = {
  input?: InputMaybe<CompanyGroupAddCompanyInput>;
};

export type Mutation_CompanyGroupAddUsersArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Array<CompanyGroupAddUserInput>>;
};

export type Mutation_CompanyGroupCompanyAddArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveCompanyInput>;
};

export type Mutation_CompanyGroupCompanyRemoveArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveCompanyInput>;
};

export type Mutation_CompanyGroupCreateArgs = {
  input: CompanyGroupCreateInput;
};

export type Mutation_CompanyGroupUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: CompanyGroupUpdateInput;
};

export type Mutation_CompanyGroupUserAddArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveUserInput>;
};

export type Mutation_CompanyGroupUserRemoveArgs = {
  input?: InputMaybe<CompanyGroupAddRemoveUserInput>;
};

export type Mutation_CompanyInactivateArgs = {
  companyId: Scalars['ID'];
  inactivateReason?: InputMaybe<CompanyInactiveReason>;
};

export type Mutation_CompanyInviteArgs = {
  companyId: Scalars['ID'];
  companyUserIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Mutation_CompanyLegalEntityActivateArgs = {
  id: Scalars['ID'];
};

export type Mutation_CompanyLegalEntityCopyCountryHolidaysArgs = {
  id: Scalars['ID'];
  year: Scalars['Int'];
};

export type Mutation_CompanyLegalEntityCreateArgs = {
  input: CreateCompanyLegalEntityInput;
};

export type Mutation_CompanyLegalEntityUpdateArgs = {
  input: UpdateCompanyLegalEntityInput;
};

export type Mutation_CompanyMsaSendForSignatureArgs = {
  companyId: Scalars['ID'];
};

export type Mutation_CompanyMsaUploadArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type Mutation_CompanyOfferingFeeComponentDiscountUpdateArgs = {
  companyOfferingFeeComponentId: Scalars['ID'];
  input: DiscountInput;
};

export type Mutation_CompanyOfferingFeeComponentTierCreateArgs = {
  companyOfferingPricingId: Scalars['ID'];
  input: CompanyOfferingFeeComponentTierInput;
};

export type Mutation_CompanyOfferingFeeComponentTierUpdateArgs = {
  companyOfferingPricingId: Scalars['ID'];
  input: CompanyOfferingFeeComponentTierInput;
};

export type Mutation_CompanyOfferingFeeComponentUpdateArgs = {
  companyOfferingFeeComponentId: Scalars['ID'];
  input: CompanyOfferingFeeComponentInput;
};

export type Mutation_CompanyOfferingPricingActivateArgs = {
  companyOfferingPricingIds: Array<Scalars['ID']>;
};

export type Mutation_CompanyOfferingPricingCreateArgs = {
  input: CompanyOfferingPricingInput;
};

export type Mutation_CompanyOfferingPricingDeactivateArgs = {
  companyOfferingPricingIds: Array<Scalars['ID']>;
};

export type Mutation_CompanyOfferingPricingDiscountUpdateArgs = {
  companyOfferingPricingId: Scalars['ID'];
  input: DiscountInput;
};

export type Mutation_CompanyOfferingsAddArgs = {
  input?: InputMaybe<CompanyOfferingsAddInput>;
};

export type Mutation_CompanyOfferingsUpdateArgs = {
  input?: InputMaybe<CompanyOfferingsUpdateInput>;
};

export type Mutation_CompanyPayableGenerateArgs = {
  input: CompanyPayableInput;
};

export type Mutation_CompanyPayableGenerateForContractArgs = {
  contractID: Scalars['ID'];
  types: Array<PayableItemType>;
};

export type Mutation_CompanyPayableSyncFromExternalSystemArgs = {
  input: CompanyPayableSyncInput;
};

export type Mutation_CompanyPayableUploadInvoiceSourceReportArgs = {
  input: InvoiceSourceReportUploadInput;
};

export type Mutation_CompanyPayrollConsolidatedReportDownloadAsyncArgs = {
  request: CompanyPayrollConsolidatedReportDownloadRequest;
};

export type Mutation_CompanyPayrollCycleRefreshArgs = {
  value?: InputMaybe<RefreshCompanyPayrollCycleInput>;
};

export type Mutation_CompanyPayrollFileDownloadAsyncArgs = {
  request: CompanyPayrollFileDownloadRequest;
};

export type Mutation_CompanyPayrollInputConfirmArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Mutation_CompanyPayrollOutputConfirmArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Mutation_CompanyPayrollPayslipPublishArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Mutation_CompanyPayrollReportsRegenerateArgs = {
  payrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_CompanyRemoveSignatoryArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyRemoveUserArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyRemoveUsersArgs = {
  companyUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_CompanySlabFxRateUploadArgs = {
  companySlabFXRates: Scalars['Upload'];
};

export type Mutation_CompanyUpdateArgs = {
  company?: InputMaybe<CompanyUpdateInput>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_CompanyUpdateBasicDetailsArgs = {
  companyLogo?: InputMaybe<Scalars['Upload']>;
  input: CompanyUpdateBasicDetailsInput;
};

export type Mutation_CompanyUpdateContactDetailsArgs = {
  input: CompanyUpdateContactDetailsInput;
};

export type Mutation_CompanyUpdateUserArgs = {
  user?: InputMaybe<UpdateUserInput>;
};

export type Mutation_CompanyUpdateUsersArgs = {
  users?: InputMaybe<Array<InputMaybe<UpdateUserInput>>>;
};

export type Mutation_CompanyUserAcceptInviteArgs = {
  companyUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_CompanyUserAddArgs = {
  user?: InputMaybe<AddCompanyUserInput>;
};

export type Mutation_CompanyUserAddApproverInfoArgs = {
  input: AddApproverInfoInput;
};

export type Mutation_CompanyUserAssignCapabilitiesArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CompanyUserCapability>>>;
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserAssignRoleArgs = {
  input?: InputMaybe<CompanyUserAssignRoleInput>;
};

export type Mutation_CompanyUserAssignRoleAndAttributesArgs = {
  input?: InputMaybe<CompanyUserAssignRoleAndAttributesInput>;
};

export type Mutation_CompanyUserEditRoleAndAttributesArgs = {
  input?: InputMaybe<CompanyUserEditRoleAndAttributesInput>;
};

export type Mutation_CompanyUserResendApproverInviteArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserRevokeApproverRoleArgs = {
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserRevokeCapabilityArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CompanyUserCapability>>>;
  companyUserId: Scalars['ID'];
};

export type Mutation_CompanyUserRevokeRoleArgs = {
  companyUserId: Scalars['ID'];
  role?: InputMaybe<CompanyUserRole>;
};

export type Mutation_CompanyUserUpdateApproverInfoArgs = {
  input: UpdateApproverInfoInput;
};

export type Mutation_CompanyUsersAddArgs = {
  users?: InputMaybe<Array<InputMaybe<AddCompanyUserInput>>>;
};

export type Mutation_CompensationRecordUpdateArgs = {
  input: CompensationRecordUpdateInput;
};

export type Mutation_CompleteComplianceAssessmentArgs = {
  id: Scalars['ID'];
  riskScore: AssessmentRiskScore;
};

export type Mutation_CompleteFinancialAssessmentArgs = {
  id: Scalars['ID'];
  riskScore: AssessmentRiskScore;
};

export type Mutation_ConfirmPayrollInputArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_ConfirmPayslipsArgs = {
  value: PayslipConfirmInput;
};

export type Mutation_ContractActivateArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractAddendumGenerateArgs = {
  addendumId: Scalars['ID'];
};

export type Mutation_ContractAddendumSendForSignatureArgs = {
  addendumId: Scalars['ID'];
};

export type Mutation_ContractAgreementCustomUploadArgs = {
  contractId: Scalars['ID'];
  file?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_ContractAgreementRegenerateArgs = {
  input?: InputMaybe<RegenerateAgreementInput>;
};

export type Mutation_ContractAgreementReplaceArgs = {
  contract: Scalars['Upload'];
  contractId: Scalars['ID'];
};

export type Mutation_ContractAgreementReplaceWetInkArgs = {
  contract: Scalars['Upload'];
  contractId: Scalars['ID'];
};

export type Mutation_ContractAssignOperationsUserArgs = {
  contractId: Scalars['ID'];
  operationsUserId: Scalars['ID'];
};

export type Mutation_ContractChangeCountryArgs = {
  id: Scalars['ID'];
  input: ContractUpdateCountryInput;
};

export type Mutation_ContractChangeRequestStatusUpdateArgs = {
  changeRequestId: Scalars['ID'];
  status: ChangeRequestStatus;
};

export type Mutation_ContractChangeRequestUpdateArgs = {
  input?: InputMaybe<ContractChangeRequestUpdateInput>;
};

export type Mutation_ContractCompleteEmployeeSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCompleteEmployerSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCompleteVerificationArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCompleteVisaEligibilityReviewArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmContractPrepareArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmMemberDetailsArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmVisaApplicationFilingArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmVisaApprovedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmVisaInvoicePaidArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractConfirmWetInkContractPrepareArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractCreateArgs = {
  input: ContractCreateInput;
};

export type Mutation_ContractCreateBenefitChangeRequestsArgs = {
  input: ContractBenefitChangeRequestInput;
};

export type Mutation_ContractCreateBulkArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  contractType?: InputMaybe<ContractType>;
  file: Scalars['Upload'];
  legalEntityId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_ContractCreateBulkPaymentAccountArgs = {
  file: Scalars['Upload'];
};

export type Mutation_ContractDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractDeleteBenefitDependentDocumentsArgs = {
  contractBenefitDependentId: Scalars['ID'];
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  key?: InputMaybe<ContractBenefitDependentDocumentType>;
};

export type Mutation_ContractDeleteCompensationGrantArgs = {
  contractId: Scalars['ID'];
  grantId: Scalars['ID'];
};

export type Mutation_ContractDeleteProbationBasePayArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_ContractDocumentRequestChangesArgs = {
  id: Scalars['ID'];
  request?: InputMaybe<Scalars['String']>;
};

export type Mutation_ContractDropArgs = {
  input: ContractDropInput;
};

export type Mutation_ContractEndArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['ID'];
  lastWorkingDay?: InputMaybe<Scalars['Date']>;
};

export type Mutation_ContractInviteMemberArgs = {
  id: Scalars['ID'];
  offerLetter?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractInviteMemberBulkArgs = {
  contractIds: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_ContractInviteMemberResendArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractLinkTemplateArgs = {
  contractId: Scalars['ID'];
  templateReference: Scalars['String'];
};

export type Mutation_ContractMemberBenefitCompletedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractMemberCompletedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractMemberStartedArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractOffboardArgs = {
  id: Scalars['ID'];
  lastWorkingDay: Scalars['Date'];
  offboardingNote?: InputMaybe<Scalars['String']>;
};

export type Mutation_ContractOffboardingCancelArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractOffboardingCancelV2Args = {
  input: ContractOffboardingCancelInput;
};

export type Mutation_ContractOffboardingCompleteArgs = {
  input: ContractOffboardingCompleteInput;
};

export type Mutation_ContractOffboardingDidNotJoinInitialiseArgs = {
  input: ContractOffboardingDidNotJoinInitialiseInput;
};

export type Mutation_ContractOffboardingRescheduleArgs = {
  input: ContractOffboardingRescheduleInput;
};

export type Mutation_ContractOffboardingResignationInitialiseArgs = {
  input: ContractOffboardingResignationInitialiseInput;
};

export type Mutation_ContractOffboardingTerminationInitialiseArgs = {
  input: ContractOffboardingTerminationInitialiseInput;
};

export type Mutation_ContractOffboardingVerifyArgs = {
  input: ContractOffboardingVerifyInput;
};

export type Mutation_ContractOfflineSignatureProcessArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractOnboardingUpdateArgs = {
  contractId: Scalars['ID'];
  input: ContractOnboardingInput;
};

export type Mutation_ContractOrderFormSendForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractRequestChangesFromMemberArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Mutation_ContractReviseArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<ContractReviseInput>;
};

export type Mutation_ContractRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendAgreementToMemberForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendOfferLetterArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSendReminderForSignatureArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractSetTestArgs = {
  id: Scalars['ID'];
  isClientTest?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractStartVerificationArgs = {
  id: Scalars['ID'];
};

export type Mutation_ContractTriggerCompleteOnboardingReminderEmailArgs = {
  bypassStartOn?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type Mutation_ContractTriggerJoingDayComplianceEmailArgs = {
  id: Scalars['ID'];
  overrideStartOn?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractTriggerJoingDayEmailArgs = {
  id: Scalars['ID'];
  isBatch?: InputMaybe<Scalars['Boolean']>;
  overrideStartOn?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_ContractTriggerPayrollFormsReminderEmailArgs = {
  input?: InputMaybe<ContractTriggerPayrollFormsReminderEmailInput>;
};

export type Mutation_ContractUnassignOperationsUserArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_ContractUnblockArgs = {
  inputs: Array<ContractUnblockInput>;
};

export type Mutation_ContractUpdateBenefitDependentBasicDetailsArgs = {
  contractBenefitId: Scalars['ID'];
  input: Array<InputMaybe<ContractUpdateBenefitDependentInput>>;
};

export type Mutation_ContractUpdateBenefitDependentDocumentsArgs = {
  contractBenefitDependentId: Scalars['ID'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  key?: InputMaybe<ContractBenefitDependentDocumentType>;
};

export type Mutation_ContractUpdateBenefitDependentPlanArgs = {
  contractBenefitId: Scalars['ID'];
  input: Array<InputMaybe<ContractUpdateBenefitDependentInput>>;
};

export type Mutation_ContractUpdateBenefitsArgs = {
  id: Scalars['ID'];
  input: ContractUpdateBenefitsInput;
};

export type Mutation_ContractUpdateCompensationArgs = {
  id: Scalars['ID'];
  input: ContractUpdateCompensationInput;
};

export type Mutation_ContractUpdateComplianceArgs = {
  contractId: Scalars['ID'];
  input: ContractUpdateComplianceInput;
};

export type Mutation_ContractUpdateDeductionsArgs = {
  id: Scalars['ID'];
  input: Array<DeductionInput>;
};

export type Mutation_ContractUpdateEmploymentArgs = {
  id: Scalars['ID'];
  input: ContractUpdateEmploymentInput;
};

export type Mutation_ContractUpdateLegalEntityArgs = {
  id: Scalars['ID'];
  legalEntityId: Scalars['ID'];
};

export type Mutation_ContractUpdatePayrollStartDateBulkArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  startDate: Scalars['Date'];
};

export type Mutation_ContractUpdateTimeOffEntitlementsArgs = {
  id: Scalars['ID'];
  input: Array<InputMaybe<ContractUpdateTimeOffEntitlementsInput>>;
};

export type Mutation_ContractWorkEmailUpdateArgs = {
  email: EmailAddressInput;
  id: Scalars['ID'];
};

export type Mutation_ContractWorkShiftBulkUpdateArgs = {
  inputs: Array<ContractBulkUpdateWorkShiftInput>;
};

export type Mutation_CountryCompensationDefinitionDeleteArgs = {
  compensationId: Scalars['ID'];
};

export type Mutation_CountryCompensationDefinitionUpsertArgs = {
  input: CountryCompensationDefinitionInput;
};

export type Mutation_CountryComplianceParamTypeUpdateArgs = {
  input?: InputMaybe<ComplianceParamTypeInput>;
};

export type Mutation_CountryComplianceParameterUpdateArgs = {
  input?: InputMaybe<CountryComplianceParameterInput>;
};

export type Mutation_CountryLegalDataDeleteArgs = {
  legalDataId: Scalars['ID'];
};

export type Mutation_CountryLegalDataUpsertArgs = {
  input: CountryLegalDataInput;
};

export type Mutation_CountryUpdateLegalDocumentRequirementArgs = {
  countryCode?: InputMaybe<CountryCode>;
  requirementInput?: InputMaybe<LegalDocumentRequirementInput>;
};

export type Mutation_CountryUpdateMemberDataDateArgs = {
  dateFieldInputs: Array<InputMaybe<DateFieldInput>>;
  memberId: Scalars['ID'];
};

export type Mutation_CountryUpdateMemberDataTextArgs = {
  memberId: Scalars['ID'];
  textFieldInputs: Array<InputMaybe<TextFieldInput>>;
};

export type Mutation_CountryUpdateOnboardingFlowEnablementArgs = {
  input: UpdateCountryOnboardingEnablementInput;
};

export type Mutation_CreateDemoCyclesArgs = {
  value: CreateDemoCyclesInput;
};

export type Mutation_CreateInsuranceCoverageGroupArgs = {
  input: Array<InputMaybe<InsuranceCoverageInput>>;
};

export type Mutation_CreateInsuranceRelationArgs = {
  input: InsuranceRelationInput;
};

export type Mutation_CreateInsuranceTierArgs = {
  input: InsuranceTierInput;
};

export type Mutation_CreatePayrollContractExceptionArgs = {
  value: PayrollContractExceptionInput;
};

export type Mutation_CreatePayrollPaymentBatchArgs = {
  payrollPaymentBatch: PayrollPaymentBatchInput;
};

export type Mutation_CreatePayrollWorkflowArgs = {
  value: CreatePayrollWorkflowRequest;
};

export type Mutation_CurrencyUploadExchangeRatesArgs = {
  effectiveDate?: InputMaybe<Scalars['Date']>;
  file: Scalars['Upload'];
};

export type Mutation_CustomPayrollReportConfigsCreateArgs = {
  values: Array<CustomPayrollReportConfigCreateInput>;
};

export type Mutation_CustomPayrollReportConfigsUpdateArgs = {
  values: Array<CustomPayrollReportConfigUpdateInput>;
};

export type Mutation_CustomPayrollReportCreateArgs = {
  values: Array<CustomPayrollReportCreateInput>;
};

export type Mutation_CustomPayrollReportFileUpdateArgs = {
  input: CustomPayrollReportFileUpdateInput;
};

export type Mutation_CustomerIntegrationDisconnectArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Mutation_CustomerUpdatePayrollWorkflowArgs = {
  value: CustomerPayrollWorkflowRequest;
};

export type Mutation_DeleteAssessmentDocumentArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeleteCompanyPayInMethodArgs = {
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_DeletePayrollContractExceptionArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeletePayrollPaymentBatchArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeletePerformanceReviewArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeleteRecruiterArgs = {
  id: Scalars['ID'];
};

export type Mutation_DeleteSalaryReviewArgs = {
  id: Scalars['ID'];
};

export type Mutation_DepartmentAssignEmployeesArgs = {
  input: DepartmentAssignEmployeesInput;
};

export type Mutation_DepartmentCreateArgs = {
  input: DepartmentCreateInput;
};

export type Mutation_DepartmentDeleteArgs = {
  input: DepartmentDeleteInput;
};

export type Mutation_DepartmentUpdateArgs = {
  input: DepartmentUpdateInput;
};

export type Mutation_DismissSyncResultArgs = {
  syncId: Scalars['String'];
};

export type Mutation_DisputeCreateArgs = {
  input: CreateDisputeInput;
};

export type Mutation_DisputeResolveArgs = {
  input: ResolveDisputeInput;
};

export type Mutation_DownloadCompanyQuotationArgs = {
  quotationId: Scalars['ID'];
};

export type Mutation_EmailTemplateUpsertArgs = {
  data: EmailTemplateUpsertInput;
};

export type Mutation_EnableCompanyPayInMethodArgs = {
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_EquipmentProcurementCreateArgs = {
  input: EquipmentProcurementInput;
};

export type Mutation_EquipmentProductsUpdateArgs = {
  file: Scalars['Upload'];
};

export type Mutation_EquipmentQuotationCreateArgs = {
  input: EquipmentQuotationInput;
};

export type Mutation_EquipmentsUpdateArgs = {
  file?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_ExecuteBatchPaymentArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_ExpenseApproveArgs = {
  id: Scalars['ID'];
};

export type Mutation_ExpenseBulkRevokeArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_ExpenseCreateArgs = {
  input: ExpenseCreateInput;
};

export type Mutation_ExpenseDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_ExpenseRejectArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_ExpenseRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_ExpenseSaveAsDraftArgs = {
  input: ExpenseSaveAsDraftInput;
};

export type Mutation_ExpenseSubmitArgs = {
  input: ExpenseSubmitInput;
};

export type Mutation_ExpenseUpdateArgs = {
  input: ExpenseUpdateInput;
};

export type Mutation_ExpenseUpdateBulkAsPaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_ExpenseUpdateBulkAsToBePaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_FeeComponentDefinitionActivateArgs = {
  feeComponentDefinitionIds: Array<Scalars['ID']>;
};

export type Mutation_FeeComponentDefinitionCreateArgs = {
  input: FeeComponentDefinitionInput;
  offeringCode: OfferingCode;
};

export type Mutation_FeeComponentDefinitionDeactivateArgs = {
  feeComponentDefinitionIds: Array<Scalars['ID']>;
};

export type Mutation_FeeComponentDefinitionTierCreateArgs = {
  input: FeeComponentTierDefinitionInput;
  offeringCode: OfferingCode;
};

export type Mutation_FeeComponentDefinitionTierUpdateArgs = {
  feeComponentTierDefinitionId: Scalars['ID'];
  input: FeeComponentTierDefinitionInput;
};

export type Mutation_FeeComponentDefinitionUpdateArgs = {
  feeComponentDefinitionId: Scalars['ID'];
  input: FeeComponentDefinitionInput;
};

export type Mutation_FetchLatestEmployeesFromPlatformArgs = {
  integrationId: Scalars['ID'];
};

export type Mutation_FinancialTransactionCollectArgs = {
  input: FinancialTransactionInput;
};

export type Mutation_FinancialTransactionGenerateArgs = {
  input: FinancialTransactionInput;
};

export type Mutation_FixEntitlementChangeRecordsArgs = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_FixTimeoffAllocationsArgs = {
  input?: InputMaybe<FixTimeoffAllocationInput>;
};

export type Mutation_ForceUploadPayslipArgs = {
  file: Scalars['Upload'];
  value: PayslipForceUploadInput;
};

export type Mutation_ForwardItemArgs = {
  category: ApprovalCategory;
  comment?: InputMaybe<Scalars['String']>;
  forwardUserId: Scalars['ID'];
  itemId: Scalars['ID'];
};

export type Mutation_GenerateCompanyQuotationArgs = {
  input: CompanyQuotationGenerationInput;
};

export type Mutation_GenerateCompensationGrantDocumentArgs = {
  grantId: Scalars['ID'];
  grantType: GrantType;
};

export type Mutation_GenerateDepositForContractArgs = {
  contractID: Scalars['ID'];
};

export type Mutation_GetTrinetCredentialArgs = {
  input: TrinetCredentialInput;
};

export type Mutation_HolidaysAddArgs = {
  countryCode: Scalars['String'];
  inputs?: InputMaybe<Array<HolidayAddInput>>;
};

export type Mutation_HolidaysDeleteArgs = {
  filter?: InputMaybe<HolidaysDeleteFilter>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_ImportEmployeesArgs = {
  employees: Array<InputMaybe<Scalars['String']>>;
  syncId: Scalars['String'];
};

export type Mutation_InitiateAutoDebitArgs = {
  input: AutoDebitInput;
};

export type Mutation_LinkContractsToPayrollCycleConfigArgs = {
  contractIds: Array<Scalars['ID']>;
  payrollCycleConfigId?: InputMaybe<Scalars['ID']>;
  validFrom: Scalars['Date'];
};

export type Mutation_ManagerAssignDirectReportsArgs = {
  input: ManagerAssignDirectReportsInput;
};

export type Mutation_ManagerCreateArgs = {
  input: ManagerCreateInput;
};

export type Mutation_ManagerDeleteArgs = {
  input: ManagerDeleteInput;
};

export type Mutation_ManagerUpdateArgs = {
  input: ManagerUpdateInput;
};

export type Mutation_ManagerValidateDirectReportsArgs = {
  input: ManagerValidateDirectReportsInput;
};

export type Mutation_MarkFailCompanyPayInMethodArgs = {
  companyPayInMethodId: Scalars['ID'];
  reason: Scalars['String'];
};

export type Mutation_MemberBackFillBankIdentifierArgs = {
  country: CountryCode;
  memberIds: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_MemberCancelUpdateRequestArgs = {
  category: MemberChangeCategory;
};

export type Mutation_MemberChangePrimaryEmailArgs = {
  email?: InputMaybe<EmailAddressInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberCreateArgs = {
  value: MemberCreateInput;
};

export type Mutation_MemberDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayBackfillBillingCurrencyArgs = {
  memberPayIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_MemberPayableBulkInitiatePayoutArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_MemberPayableCompanyInvoiceConfirmPaymentArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableCompanyInvoiceCreatePayInArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableCompanyInvoiceMarkAsReadyForPayoutsArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableCompanyInvoiceSubmitArgs = {
  input: MemberPayableCompanyInvoiceSubmitInput;
};

export type Mutation_MemberPayableDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberPayableGenerationArgs = {
  generateSecondHalfOnly?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_MemberPayableSubmitArgs = {
  input: MemberPayableSubmitInput;
};

export type Mutation_MemberPayableToggleAutoGenerationArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  state: MemberPayableAutoGenerationState;
};

export type Mutation_MemberPayableUpdateArgs = {
  id: Scalars['ID'];
  input: MemberPayableUpdateInput;
};

export type Mutation_MemberPayableUpdateBulkAsApprovedArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_MemberPayableUpdateBulkAsPaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_MemberPayableUpdateBulkAsRejectedArgs = {
  input: Array<InputMaybe<MemberPayableRejectInput>>;
};

export type Mutation_MemberRemoveProfilePictureArgs = {
  id: Scalars['ID'];
};

export type Mutation_MemberSendUpdateRequestArgs = {
  category: MemberChangeCategory;
  requestInput: MemberChangeRequestInput;
};

export type Mutation_MemberUpdateArgs = {
  id: Scalars['ID'];
  memberChange?: InputMaybe<MemberUpdateInput>;
};

export type Mutation_MemberUpdateBankDetailsArgs = {
  data: BankAccountInput;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateBasicDetailsArgs = {
  data: MemberUpdateBasicDetailsInput;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateDocumentArgs = {
  data: Array<LegalDocumentInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateLegalDataArgs = {
  data: Array<LegalDataInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberUpdateVisaDocumentArgs = {
  data: Array<LegalDocumentInput>;
  id: Scalars['ID'];
};

export type Mutation_MemberUpsertAddressArgs = {
  id: Scalars['ID'];
  input: MemberAddressInput;
};

export type Mutation_MemberUpsertBasicInfoArgs = {
  id: Scalars['ID'];
  input: MemberBasicInfoInput;
};

export type Mutation_MemberUpsertContactInfoArgs = {
  id: Scalars['ID'];
  input: MemberContactInfoInput;
};

export type Mutation_MemberUpsertCustomFieldsArgs = {
  id: Scalars['ID'];
  input: MemberCustomFieldInput;
};

export type Mutation_MemberUpsertEducationArgs = {
  id: Scalars['ID'];
  input: EducationInput;
};

export type Mutation_MemberUpsertEmergencyContactArgs = {
  id: Scalars['ID'];
  input: EmergencyContactInput;
};

export type Mutation_MemberUpsertIdentificationDetailsArgs = {
  id: Scalars['ID'];
  input: MemberIdentificationDetailsInput;
};

export type Mutation_MemberUpsertPersonalInfoArgs = {
  id: Scalars['ID'];
  input: MemberPersonalInfoInput;
};

export type Mutation_MemberUpsertPreviousEmployerArgs = {
  id: Scalars['ID'];
  input: PreviousEmployerInput;
};

export type Mutation_MemberUpsertProfilePictureArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
  key?: InputMaybe<Scalars['String']>;
};

export type Mutation_MigrateSeatIdArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation_MoveToNextPayslipUploadWorkflowStepArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_MoveToNextStatusArgs = {
  action?: InputMaybe<PayrollWorkflowAction>;
  payrollCycleId: Scalars['ID'];
};

export type Mutation_MsaAddendumConfirmArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDocumentGenerateArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDocumentRevokeArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDocumentSendForSignatureArgs = {
  msaAddendumId: Scalars['ID'];
};

export type Mutation_MsaAddendumDraftDeleteArgs = {
  companyId: Scalars['ID'];
};

export type Mutation_MsaAddendumDraftEditArgs = {
  msaAddendumInput: MsaAddendumInput;
};

export type Mutation_MsaAddendumUploadCustomArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type Mutation_OffboardingClientApproveLwdArgs = {
  input: OffboardingClientApproveLwdInput;
};

export type Mutation_OffboardingConfirmAssetsRecoveryArgs = {
  input: OffboardingConfirmAssetsRecoveryInput;
};

export type Mutation_OffboardingExperienceInitiatialiseArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_OffboardingInitializeArgs = {
  input: OffboardingInitializeInput;
};

export type Mutation_OffboardingInputLwdArgs = {
  input: OffboardingInputLwdInput;
};

export type Mutation_OffboardingNextStateArgs = {
  offboardingId: Scalars['ID'];
};

export type Mutation_OffboardingOpsApproveLwdArgs = {
  input: OffboardingOpsApproveLwdInput;
};

export type Mutation_OperationsUserCreateArgs = {
  operationsUser: OperationsUserCreateInput;
};

export type Mutation_OperationsUserDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_OperationsUserUpdateArgs = {
  id: Scalars['ID'];
  operationsUserChange: OperationsUserUpdateInput;
};

export type Mutation_OperationsUserUpdateRolesArgs = {
  id: Scalars['ID'];
  roles: Array<OperationsUserRole>;
};

export type Mutation_OpsUpdatePayrollWorkflowArgs = {
  value: UpdatePayrollWorkflowRequest;
};

export type Mutation_OptOutInsuranceArgs = {
  contractBenefitId: Scalars['ID'];
  reasons: Array<Scalars['String']>;
};

export type Mutation_OrderFormRegenerateArgs = {
  input?: InputMaybe<RegenerateOrderFormInput>;
};

export type Mutation_OrgDirectoryAssignReportsToArgs = {
  input: OrgDirectoryAssignReportsToInput;
};

export type Mutation_OrgDirectoryValidateReportsToArgs = {
  input: OrgDirectoryValidateReportsToInput;
};

export type Mutation_OrgRoleAssignManagersArgs = {
  input: OrgRoleAssignManagersInput;
};

export type Mutation_OrgRoleCreateArgs = {
  role: OrgRoleCreateInput;
};

export type Mutation_OrgRoleDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_OrgRoleUpdateArgs = {
  role: OrgRoleUpdateInput;
};

export type Mutation_PartnerAcceptInviteArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerAddCountryCapabilityArgs = {
  partnerCountry?: InputMaybe<PartnerCountryInput>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerComponentBulkUploadArgs = {
  input: Scalars['Upload'];
};

export type Mutation_PartnerCreateArgs = {
  partner?: InputMaybe<CreatePartnerInput>;
};

export type Mutation_PartnerEorAssignContractArgs = {
  contractId: Scalars['ID'];
  partnerId: Scalars['ID'];
};

export type Mutation_PartnerInviteArgs = {
  partnerId: Scalars['ID'];
};

export type Mutation_PartnerMappingBackfillCsvArgs = {
  file: Scalars['Upload'];
};

export type Mutation_PartnerPayrollAssignContractArgs = {
  contractId: Scalars['ID'];
  partnerId: Scalars['ID'];
  partnerMemberId?: InputMaybe<Scalars['String']>;
};

export type Mutation_PartnerPayrollCreateArgs = {
  payrollData: CreatePartnerPayrollInput;
};

export type Mutation_PartnerPayrollPayslipGenerateForPayrollCycleArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PartnerPayrollRegistrationCompletedArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerPayrollSendForReviewArgs = {
  input: SendReportForReviewInput;
};

export type Mutation_PartnerPayrollStartRegistrationArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerPayrollUploadPayslipArgs = {
  file: Scalars['Upload'];
  value: PartnerPayrollUploadPayslipInput;
};

export type Mutation_PartnerUpdatePayrollWorkflowArgs = {
  value: UpdatePayrollWorkflowRequest;
};

export type Mutation_PartnerUserChangeRoleArgs = {
  newRole?: InputMaybe<Scalars['String']>;
  partnerUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerUserCreateArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
  partnerUser?: InputMaybe<CreatePartnerUserInput>;
};

export type Mutation_PartnerUserDeleteArgs = {
  partnerUserId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_PartnerUserUpdateOperatorArgs = {
  countryCode: CountryCode;
  partnerUserId?: InputMaybe<Scalars['ID']>;
  userType: PartnerUserType;
};

export type Mutation_PayInProcessArgs = {
  currency?: InputMaybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  paymentPartnerCode: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type Mutation_PaySupplementBulkUploadArgs = {
  file: Scalars['Upload'];
};

export type Mutation_PaySupplementCreateArgs = {
  paySupplementData?: InputMaybe<PaySupplementCreateInput>;
};

export type Mutation_PaySupplementPaidArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaySupplementRejectArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_PaySupplementRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaySupplementUpdateArgs = {
  id: Scalars['ID'];
  paySupplementChange?: InputMaybe<PaySupplementUpdateInput>;
};

export type Mutation_PaySupplementUpdateBulkAsPaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PaySupplementUpdateBulkAsRevokedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PaySupplementUpdateBulkAsToBePaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PaymentBundleCreateArgs = {
  input: PaymentBundleCreateInput;
};

export type Mutation_PaymentBundleDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaymentBundleMarkAsPaidArgs = {
  id: Scalars['ID'];
};

export type Mutation_PaymentBundleMarkAsProcessingArgs = {
  id: Scalars['ID'];
  paymentAdvices?: InputMaybe<Array<Scalars['Upload']>>;
};

export type Mutation_PaymentBundleUpdateArgs = {
  input?: InputMaybe<PaymentBundleUpdateInput>;
};

export type Mutation_PayrollBulkUploadConfirmArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollBulkUploadCreateArgs = {
  fileName: Scalars['String'];
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollBulkUploadTemplatesRefreshArgs = {
  countries?: InputMaybe<Array<CountryCode>>;
};

export type Mutation_PayrollBulkUploadValidateArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollCycleConfigBulkCreateArgs = {
  input?: InputMaybe<BulkPayrollCycleConfigInput>;
};

export type Mutation_PayrollCycleConfigCreateArgs = {
  input?: InputMaybe<PayrollCycleConfigInput>;
};

export type Mutation_PayrollCycleConfigUpdateArgs = {
  input?: InputMaybe<PayrollCycleConfigUpdateInput>;
};

export type Mutation_PayrollCycleCreateArgs = {
  value: PayrollCycleInput;
};

export type Mutation_PayrollCycleSendForReviewArgs = {
  input: SendPayrollCycleForReviewInput;
};

export type Mutation_PayrollCycleStatusBulkUpdateArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  publishEvent?: InputMaybe<Scalars['Boolean']>;
  status: PayrollCycleStatus;
};

export type Mutation_PayrollCycleUpdateBulkAsClosedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PayrollCycleUpdateBulkAsSuspendedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_PayrollInputAuditTrailsRemoveArgs = {
  input: PayrollInputAuditTrailsRemoveInput;
};

export type Mutation_PayrollInputFileDownloadAsyncArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_PayrollInputFilePartnerBulkDownloadAsyncArgs = {
  filter: PayrollCycleFilter;
};

export type Mutation_PayrollNoteForContractArgs = {
  contractId: Scalars['ID'];
  payrollNote?: InputMaybe<Scalars['String']>;
};

export type Mutation_PayrollOffCycleCreateArgs = {
  value: PayrollOffCycleCreateInput;
};

export type Mutation_PerformanceReviewBulkCreateAsApprovedArgs = {
  inputs: Array<PerformanceReviewBulkInput>;
};

export type Mutation_PlatformBambooHrAccountTokenUpdateArgs = {
  companyId: Scalars['ID'];
  token: Scalars['String'];
};

export type Mutation_PlatformBatchedUpdateArgs = {
  input: PlatformBatchedUpdateInput;
};

export type Mutation_PlatformCompanyTokenUpdateArgs = {
  companyId: Scalars['ID'];
  platformId: Scalars['ID'];
  token: Scalars['String'];
};

export type Mutation_PlatformUpdateArgs = {
  input: PlatformUpdateInput;
};

export type Mutation_PricingPlanActivateArgs = {
  id: Scalars['ID'];
};

export type Mutation_PricingPlanAutofillArgs = {
  input: PricingPlanAutofillInput;
};

export type Mutation_PricingPlanCloseSeatsArgs = {
  input: PricingPlanCloseSeatsInput;
};

export type Mutation_PricingPlanCreateArgs = {
  input: PricingPlanCreateInput;
};

export type Mutation_PricingPlanDeleteArgs = {
  input: PricingPlanDeleteInput;
};

export type Mutation_PricingPlanExpandArgs = {
  input: PricingPlanExpandInput;
};

export type Mutation_PricingPlanMigrateArgs = {
  input: PricingPlanMigrateInput;
};

export type Mutation_PricingPlanUpdateArgs = {
  input: PricingPlanUpdateInput;
};

export type Mutation_ProviderKnitAuthTokenArgs = {
  clearErrors?: InputMaybe<Scalars['Boolean']>;
  originOrgId: Scalars['String'];
  originOrgName: Scalars['String'];
  originUserEmail: Scalars['String'];
  originUserName: Scalars['String'];
  platformId: Scalars['String'];
};

export type Mutation_PublishPayslipsArgs = {
  value: PayslipPublishInput;
};

export type Mutation_ReGeneratePayrollCycleInputFileArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_ReInitiateActivationArgs = {
  companyPayInMethodId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_RecreatePayrollPaymentBatchArgs = {
  id: Scalars['ID'];
};

export type Mutation_RefreshContractReferencesForPayrollCycleArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_RefreshPayrollSnapshotArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_RefreshPayrollSnapshotByWatermarkArgs = {
  value: PayrollSnapshotWatermarkRefreshInput;
};

export type Mutation_RefreshPayrollSnapshotDomainForceArgs = {
  value: PayrollSnapshotRefreshDomainByIdInput;
};

export type Mutation_RefreshPayrollSnapshotsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_RegenerateAgreementArgs = {
  input?: InputMaybe<RegenerateAgreementInput>;
};

export type Mutation_RegeneratePayslipZipArgs = {
  payrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_RegisterDirectDebitMandateArgs = {
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_RejectItemArgs = {
  category: ApprovalCategory;
  itemId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type Mutation_RelinkContractToAnotherPayrollCycleConfigArgs = {
  contractId: Scalars['ID'];
  disableConfig?: InputMaybe<Scalars['Boolean']>;
  payrollCycleConfigId: Scalars['ID'];
  validFrom: Scalars['Date'];
};

export type Mutation_SalaryCalculationEmailArgs = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  params?: InputMaybe<SalaryCalculationParam>;
};

export type Mutation_SalaryCalculatorRequestEmailArgs = {
  params?: InputMaybe<SalaryCalculationRequestEmailParam>;
};

export type Mutation_SaveAndDownloadCompanyQuotationArgs = {
  input: CompanyQuotationGenerationInput;
};

export type Mutation_SaveIntegrationEntityMappingStatusArgs = {
  enableDataSync?: InputMaybe<Scalars['Boolean']>;
  entityMappingId: Scalars['ID'];
};

export type Mutation_SaveIntegrationFieldsMappingArgs = {
  input?: InputMaybe<SaveIntegrationFieldsMappingInput>;
};

export type Mutation_SeatAssignArgs = {
  input: SeatAssignInput;
};

export type Mutation_SeatReleaseArgs = {
  input: SeatReleaseInput;
};

export type Mutation_SendEmailToOnboardingSpecialistArgs = {
  contractId: Scalars['ID'];
  message: Scalars['String'];
};

export type Mutation_SendImportedMemberInvitesArgs = {
  employees: Array<InputMaybe<Scalars['String']>>;
  syncId?: InputMaybe<Scalars['String']>;
};

export type Mutation_SendInvoiceToContactEmailArgs = {
  input: ContactEmailInput;
};

export type Mutation_SendKybRequirementsEmailArgs = {
  input: KybRequirementsInput;
};

export type Mutation_SendSyncedMemberInvitesArgs = {
  employees: Array<InputMaybe<Scalars['ID']>>;
  syncId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_SendToSignatoryPerformanceReviewArgs = {
  reviewId: Scalars['ID'];
};

export type Mutation_SendToSignatorySalaryReviewArgs = {
  reviewId: Scalars['ID'];
};

export type Mutation_SetDisabledPayrollCycleConfigToContractArgs = {
  configToContractLinkId: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
};

export type Mutation_StartIncomingSyncArgs = {
  integrationId: Scalars['ID'];
};

export type Mutation_StartNewKybAssessmentArgs = {
  kybId: Scalars['ID'];
  reportExists: Scalars['Boolean'];
};

export type Mutation_SyncEorManuallyArgs = {
  integrationId: Scalars['ID'];
};

export type Mutation_SyncEmployeesArgs = {
  employees: Array<InputMaybe<Scalars['ID']>>;
  syncId: Scalars['ID'];
};

export type Mutation_SyncPayrollCycleArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Mutation_SyncTurnOffArgs = {
  syncId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_SyncXeroInvoicesToCoreArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Mutation_TemplateDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_TemplateUpsertArgs = {
  input: TemplateInput;
};

export type Mutation_TerminatePayrollWorkflowArgs = {
  value: TerminatePayrollWorkflowRequest;
};

export type Mutation_TimeOffBulkRevokeArgs = {
  ids: Array<Scalars['ID']>;
};

export type Mutation_TimeOffCreateArgs = {
  contractId?: InputMaybe<Scalars['ID']>;
  input: TimeOffCreateInput;
};

export type Mutation_TimeOffDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_TimeOffPolicyAssignUsersArgs = {
  input: TimeOffPolicyAssignUsersInput;
};

export type Mutation_TimeOffPolicyCreateArgs = {
  input: TimeOffPolicyCreateInput;
};

export type Mutation_TimeOffPolicyDeleteArgs = {
  policyId: Scalars['ID'];
};

export type Mutation_TimeOffPolicyUpdateArgs = {
  input: TimeOffPolicyUpdateInput;
};

export type Mutation_TimeOffPolicyValidateUsersArgs = {
  input: TimeOffPolicyValidateUsersInput;
};

export type Mutation_TimeOffRevokeArgs = {
  id: Scalars['ID'];
};

export type Mutation_TimeOffSaveAsDraftArgs = {
  input: TimeOffSaveAsDraftInput;
};

export type Mutation_TimeOffTypeCreateArgs = {
  input: TimeOffTypeCreateInput;
};

export type Mutation_TimeOffTypeDeleteArgs = {
  id: Scalars['ID'];
};

export type Mutation_TimeOffTypeUpdateArgs = {
  input: TimeOffTypeUpdateInput;
};

export type Mutation_TimeOffUpdateArgs = {
  id: Scalars['ID'];
  input: TimeOffUpdateInput;
};

export type Mutation_TimeOffUpdateCompanyDefinitionsArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type Mutation_TimeOffUpdateCountryDefinitionsArgs = {
  file: Scalars['Upload'];
};

export type Mutation_TimesheetChangeStatusArgs = {
  comment?: InputMaybe<Scalars['String']>;
  status: TimesheetStatus;
  timesheetId: Scalars['ID'];
};

export type Mutation_TimesheetCreateBulkArgs = {
  endDate: Scalars['Date'];
  file: Scalars['Upload'];
  startDate: Scalars['Date'];
};

export type Mutation_TimesheetCreateOrUpdateArgs = {
  input?: InputMaybe<TimesheetCreateUpdateInput>;
  timesheetId?: InputMaybe<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesApproveArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesCreateOrUpdateArgs = {
  input: Array<TimesheetEntryInput>;
  timesheetId: Scalars['ID'];
};

export type Mutation_TimesheetEntriesDeleteArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesRejectArgs = {
  comment?: InputMaybe<Scalars['String']>;
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesRevokeArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntriesSubmitArgs = {
  timesheetEntryIds: Array<Scalars['ID']>;
};

export type Mutation_TimesheetEntryCreateOrUpdateArgs = {
  input: TimesheetEntryInput;
  timesheetId: Scalars['ID'];
};

export type Mutation_TimesheetGenerateIfMissingArgs = {
  contractId: Scalars['ID'];
};

export type Mutation_TimesheetUploadBulkArgs = {
  file: Scalars['Upload'];
  input: TimesheetUploadBulkInput;
};

export type Mutation_ToggleAutoDebitArgs = {
  companyId: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type Mutation_TriggerCarryForwardExpirationArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_TriggerCompleteWorkEmailAndEmployeeIdReminderEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  simulatedSendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerContractBenefitSetEndedStatusArgs = {
  endOn?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerContractWorkEmailReminderOnStartDayEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  simulatedSendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerEmployeeTimesheetEntryAutoApprovalArgs = {
  currentDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerEmployeeTimesheetsAutoSubmissionArgs = {
  currentDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerMemberExpenseSubmissionRemindersArgs = {
  simulatedExecutionDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerOnboardingReminderEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  simulatedSendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerPaySupplementAutoCreateProcessArgs = {
  filters?: InputMaybe<PaySupplementAutoCreationFilters>;
};

export type Mutation_TriggerProbationEndReminderEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  sendingDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerReminderEmailForSalaryRevisionSignatureArgs = {
  simulatedExecutionDate?: InputMaybe<Scalars['Date']>;
};

export type Mutation_TriggerReviewPayrollInputReminderEmailArgs = {
  companyPayrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_TriggerReviewPayrollReportReminderEmailArgs = {
  companyPayrollCycleIds: Array<Scalars['ID']>;
};

export type Mutation_TriggerTimeoffAllocationArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_TriggerTimeoffCarryForwardArgs = {
  input?: InputMaybe<TimeOffAllocationInput>;
};

export type Mutation_TriggerTimesheetApproveSubmittedHoursReminderEmailsToAdminsArgs = {
  currentDate: Scalars['Date'];
};

export type Mutation_TriggerUpcomingMonthlyInvoiceEmailProcessArgs = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  invoiceMonth?: InputMaybe<MonthYearInput>;
};

export type Mutation_UnlinkContractFromPayrollCycleConfigArgs = {
  contractId: Scalars['ID'];
  validTo: Scalars['Date'];
};

export type Mutation_UpdateAndFixTimeOffsArgs = {
  input?: InputMaybe<UpdateAndFixTimeOffsInput>;
};

export type Mutation_UpdateCompanyChargebackPeriodArgs = {
  request: UpdateCompanyChargebackRequest;
};

export type Mutation_UpdateCompanyPayInMethodChargeBackArgs = {
  request: UpdateCompanyPayInMethodChargeBackRequest;
};

export type Mutation_UpdateCompanyPricingArgs = {
  companyId: Scalars['ID'];
  pricing: PricingInput;
  skipCreatingMsaAddendum?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_UpdateCompensationPaymentInfoArgs = {
  input: UpdateCompensationPayInfoInput;
};

export type Mutation_UpdateComplianceAssessmentDataArgs = {
  input: ComplianceAssessmentDataInput;
};

export type Mutation_UpdateComplianceAssessmentDocumentsArgs = {
  input: ComplianceAssessmentDocumentsInput;
};

export type Mutation_UpdateContractBenefitChangeRequestArgs = {
  input: Array<ContractBenefitChangeRequestUpdateInput>;
};

export type Mutation_UpdateContractPaymentDetailsArgs = {
  id: Scalars['ID'];
  input: ContractUpdatePaymentAccountInput;
};

export type Mutation_UpdateDemoCyclesArgs = {
  value: UpdateDemoCyclesInput;
};

export type Mutation_UpdateDomainItemsArgs = {
  domain: PayrollInputDomainType;
  domainIds: Array<Scalars['ID']>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation_UpdateFinancialAssessmentDataArgs = {
  input: FinancialAssessmentDataInput;
};

export type Mutation_UpdateFinancialAssessmentDocumentsArgs = {
  input: FinancialAssessmentDocumentsInput;
};

export type Mutation_UpdateInsuranceArgs = {
  benefitUpdate: InsuranceUpdateInput;
  contractId: Scalars['ID'];
  dependentsUpdate?: InputMaybe<Array<ContractUpdateBenefitDependentInput>>;
};

export type Mutation_UpdateInsuranceStatusArgs = {
  contractId: Scalars['ID'];
  statuses: InsuranceStatuses;
};

export type Mutation_UpdateInvoiceAndBundleOnExternalInvoicePaidArgs = {
  externalInvoiceId: Scalars['String'];
};

export type Mutation_UpdateOrCreateCompanyBindingArgs = {
  input: UpdateOrCreateCompanyBindingInput;
};

export type Mutation_UpdatePayrollParserConfigArgs = {
  input: PayrollParserConfigInput;
};

export type Mutation_UpdatePayrollPartnerMemberIdToContractIdArgs = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  country?: InputMaybe<CountryCode>;
};

export type Mutation_UpdatePayrollPaymentBatchStatusArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  payrollPaymentBatchStatus: PayrollPaymentBatchStatus;
};

export type Mutation_UpdatePayrollPaymentStatusArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  status: PayrollPaymentStatus;
};

export type Mutation_UpdatePayrollReconciliationResultStatusArgs = {
  resultId: Scalars['ID'];
  resultStatus: ReconciliationResultStatus;
};

export type Mutation_UpdatePayrollReportGeneratorConfigArgs = {
  input: PayrollReportGeneratorConfigInput;
};

export type Mutation_UpdatePayrollWorkflowArgs = {
  value: UpdatePayrollWorkflowRequest;
};

export type Mutation_UpdateTimeoffDefinitionArgs = {
  file: Scalars['Upload'];
};

export type Mutation_UploadAssessmentDocumentArgs = {
  document: Scalars['Upload'];
};

export type Mutation_UploadContractIdMappingFileArgs = {
  input: Scalars['Upload'];
};

export type Mutation_UploadPayrollFileArgs = {
  country: CountryCode;
  input: Scalars['Upload'];
  partnerId: Scalars['ID'];
  payrollMonth: PayrollMonthInput;
};

export type Mutation_UploadPayrollOutputReportArgs = {
  input: Scalars['Upload'];
  partnerId: Scalars['ID'];
  payrollCycle: Scalars['ID'];
};

export type Mutation_UploadSignedAgreementArgs = {
  agreement?: InputMaybe<Scalars['Upload']>;
  companyPayInMethodId: Scalars['ID'];
};

export type Mutation_UpsertCompanyPayInMethodBankDetailsArgs = {
  input?: InputMaybe<CompanyPayInMethodInput>;
};

export type Mutation_UpsertContractBenefitBannersArgs = {
  input: ContractBenefitBannerInput;
};

export type Mutation_UpsertCountryContractClausesArgs = {
  inputs: Array<CountryContractClauseUpsertInput>;
};

export type Mutation_UpsertCountryDocumentArgs = {
  file: Scalars['Upload'];
  input?: InputMaybe<CountryDocumentUpdateInput>;
};

export type Mutation_UpsertCountryLegalDataDefinitionsArgs = {
  inputs: Array<CountryLegalDataDefinitionUpsertInput>;
};

export type Mutation_UpsertPerformanceReviewArgs = {
  review: PerformanceReviewInput;
};

export type Mutation_UpsertRecruiterArgs = {
  input?: InputMaybe<RecruiterInput>;
  logo?: InputMaybe<Scalars['Upload']>;
};

export type Mutation_UpsertSsoPreferenceForCompanyArgs = {
  companyId: Scalars['ID'];
  input: UpsertSsoPreferenceInput;
};

export type Mutation_UpsertSalaryReviewArgs = {
  review: SalaryReviewInput;
};

export type Mutation_ValidatePayslipsArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Mutation_VerifyTriNetCredentialArgs = {
  input: TrinetCredentialInputV2;
};

export type NachaDebitInstructionFileGenerateData = {
  companyBankOverrideInfo?: InputMaybe<CompanyBankOverrideInfo>;
  companyId: Scalars['ID'];
  expectedPayoutDate: Scalars['Date'];
  fileDataOverrideInfo?: InputMaybe<NachaFileDataOverride>;
  invoiceIds: Array<InputMaybe<Scalars['ID']>>;
  invoiceOverrideInfo?: InputMaybe<Array<InputMaybe<InvoiceOverrideInfo>>>;
};

export type NachaFileDataOverride = {
  fileIdModifier?: InputMaybe<Scalars['String']>;
};

export type NachaPrenoteInstructionFileGenerateData = {
  companyBankOverrideInfo?: InputMaybe<CompanyBankOverrideInfo>;
  companyId: Scalars['ID'];
  fileDataOverrideInfo?: InputMaybe<NachaFileDataOverride>;
};

export enum Nationalogy {
  CITIZEN = 'CITIZEN',
  PERMANENT_RESIDENT = 'PERMANENT_RESIDENT',
  TEMPORARY_RESIDENT = 'TEMPORARY_RESIDENT',
}

export enum NotificationType {
  ACHDIRECTDEBITOPSEMAIL = 'ACHDirectDebitOpsEmail',
  ACHDIRECTDEBITPRENOTEEMAIL = 'ACHDirectDebitPreNoteEmail',
  ACHDIRECTDEBITSETUPOPSEMAIL = 'ACHDirectDebitSetupOpsEmail',
  ADDCOMPANYMEMBER = 'AddCompanyMember',
  ADMINCONTRACTDELETED = 'AdminContractDeleted',
  ADMINCONTRACTREVOKED = 'AdminContractRevoked',
  APPROVEPEOPAYROLLREPORTREMINDER = 'ApprovePeoPayrollReportReminder',
  APPROVERUPDATIONEMAILTOOLDAPPROVER = 'ApproverUpdationEmailToOldApprover',
  AUTODEBITDISPUTERECREATIONNOTIFICATION = 'AutoDebitDisputeRecreationNotification',
  AUTODEBITMANUALCREATIONNOTIFICATION = 'AutoDebitManualCreationNotification',
  AUTODEBITPRENOTIFICATION = 'AutoDebitPreNotification',
  BENEFITCHANGEREQUESTRECEIVEDEMAILTOMEMBER = 'BenefitChangeRequestReceivedEmailToMember',
  BENEFITCHANGEREQUESTUPDATEDEMAILTOMEMBER = 'BenefitChangeRequestUpdatedEmailToMember',
  BULKONBOARDINGFAILEDTOSLACK = 'BulkOnboardingFailedToSlack',
  BULKONBOARDINGSUCCESSTOOPS = 'BulkOnboardingSuccessToOps',
  BUNDLEPAYMENTRECEIVEDEMAILTOOPS = 'BundlePaymentReceivedEmailToOps',
  CHANGEPASSWORD = 'ChangePassword',
  COMPANYADMINACTIVATEDTOOPS = 'CompanyAdminActivatedToOps',
  COMPANYBILLINGCONTACTUSERINVITATION = 'CompanyBillingContactUserInvitation',
  COMPANYCREATIONOPSEMAILORGANIC = 'CompanyCreationOpsEmailOrganic',
  COMPANYINVITATION = 'CompanyInvitation',
  COMPANYUSERINVITATIONADMIN = 'CompanyUserInvitationAdmin',
  COMPANYUSERINVITATIONMANAGER = 'CompanyUserInvitationManager',
  /** @deprecated No longer supported */
  CONTRACTACTIVATEDTOEMPLOYER = 'ContractActivatedToEmployer',
  CONTRACTACTIVATEDTOMEMBER = 'ContractActivatedToMember',
  CONTRACTCHANGEREQUESTBLOCKEDEMAILTOCOMPANYUSER = 'ContractChangeRequestBlockedEmailToCompanyUser',
  CONTRACTCHANGEREQUESTDELAYEDEMAILTOCOMPANYUSER = 'ContractChangeRequestDelayedEmailToCompanyUser',
  CONTRACTCHANGEREQUESTUNBLOCKEDEMAILTOCOMPANYUSER = 'ContractChangeRequestUnblockedEmailToCompanyUser',
  CONTRACTEMPLOYEEIDANDWORKEMAILREMINDERTOCOMPANY = 'ContractEmployeeIdAndWorkEmailReminderToCompany',
  CONTRACTFIXEDTERMNONRENEWABLEEXPIRINGREMINDER = 'ContractFixedTermNonRenewableExpiringReminder',
  CONTRACTFIXEDTERMRENEWABLEEXPIRINGREMINDER = 'ContractFixedTermRenewableExpiringReminder',
  CONTRACTORDERFORMTOCOMPANY = 'ContractOrderFormToCompany',
  CONTRACTORDERFORMTOMULTIPLIER = 'ContractOrderFormToMultiplier',
  CONTRACTQUESTIONNAIREEMAILTOCOMPANY = 'ContractQuestionnaireEmailToCompany',
  CONTRACTQUESTIONNAIREEMAILTOOPS = 'ContractQuestionnaireEmailToOps',
  CONTRACTWORKEMAILREMINDERONSTARTDAYTOCOMPANY = 'ContractWorkEmailReminderOnStartDayToCompany',
  DAILYTIMECARDREMINDERTOMEMBER = 'DailyTimecardReminderToMember',
  DATACHANGENOTIFYTOOPS = 'DataChangeNotifyToOps',
  DEPARTMENTCREATEDEMAILTOADMIN = 'DepartmentCreatedEmailToAdmin',
  DEPARTMENTDELETEDEMAILTOADMIN = 'DepartmentDeletedEmailToAdmin',
  DEPOSITINVOICEPAIDSLACKEMAIL = 'DepositInvoicePaidSlackEmail',
  DIRECTDEBITFAILEDSTATUSEMAILTOFINANCE = 'DirectDebitFailedStatusEmailToFinance',
  DIRECTDEBITPROCESSINGFAILEDEMAILTOFINANCE = 'DirectDebitProcessingFailedEmailToFinance',
  DISPUTEINVOICEEMAILTOUSER = 'DisputeInvoiceEmailToUser',
  EMPLOYEECONTRACTREVISED = 'EmployeeContractRevised',
  EORINVOICERAISEDTOCOMPANYADMIN = 'EorInvoiceRaisedToCompanyAdmin',
  EQUIPMENTPROCUREMENTCREATEDEMAILTOOPS = 'EquipmentProcurementCreatedEmailToOps',
  EQUIPMENTQUOTATIONCREATEDEMAILTOOPS = 'EquipmentQuotationCreatedEmailToOps',
  ESOPEMAILTOMEMBER = 'EsopEmailToMember',
  ESOPEMAILTOSIGNATORY = 'EsopEmailToSignatory',
  EXPENSEAPPROVEDTOMEMBER = 'ExpenseApprovedToMember',
  EXPENSEAPPROVEDTOOPS = 'ExpenseApprovedToOps',
  EXPENSECREATEDTOCOMPANYUSERS = 'ExpenseCreatedToCompanyUsers',
  EXPENSEDELETETOEMPLOYER = 'ExpenseDeleteToEmployer',
  /** @deprecated No longer supported */
  EXPENSEDELETETOOPS = 'ExpenseDeleteToOps',
  EXPENSEREJECTEDTOMEMBER = 'ExpenseRejectedToMember',
  EXPENSEREMINDERTOEMPLOYER = 'ExpenseReminderToEmployer',
  EXPENSESUBMISSIONREMINDERTOMEMBER = 'ExpenseSubmissionReminderToMember',
  EXPENSEUPDATETOEMPLOYER = 'ExpenseUpdateToEmployer',
  /** @deprecated No longer supported */
  EXPENSEUPDATETOOPS = 'ExpenseUpdateToOps',
  FIRSTCONTRACTONBOARDINGREMINDERTOCOMPANY = 'FirstContractOnboardingReminderToCompany',
  FIRSTCONTRACTONBOARDINGREMINDERTOMEMBER = 'FirstContractOnboardingReminderToMember',
  FREELANCERINVOICEPAIDCONFIRMEDTOOPS = 'FreeLancerInvoicePaidConfirmedToOps',
  FREELANCERINVOICEPAYINCONFIRMEDTOOPS = 'FreeLancerInvoicePayInConfirmedToOps',
  FREELANCERINVOICEAPPROVEDTOMEMBER = 'FreelancerInvoiceApprovedToMember',
  FREELANCERINVOICEAPPROVEDTOOPS = 'FreelancerInvoiceApprovedToOps',
  FREELANCERINVOICEFIRSTREMINDERTOMEMBER = 'FreelancerInvoiceFirstReminderToMember',
  FREELANCERINVOICEPAIDTOCUSTOMER = 'FreelancerInvoicePaidToCustomer',
  FREELANCERINVOICEPAIDTOMEMBER = 'FreelancerInvoicePaidToMember',
  FREELANCERINVOICEPARTIALPAYINRECEIVEDTOCOMPANY = 'FreelancerInvoicePartialPayInReceivedToCompany',
  FREELANCERINVOICEPARTIALPAYINRECEIVEDTOOPS = 'FreelancerInvoicePartialPayInReceivedToOps',
  FREELANCERINVOICEPAYMENTRECEIVEDTOCUSTOMER = 'FreelancerInvoicePaymentReceivedToCustomer',
  FREELANCERINVOICERAISEDTOCUSTOMER = 'FreelancerInvoiceRaisedToCustomer',
  FREELANCERINVOICEREJECTEDTOMEMBER = 'FreelancerInvoiceRejectedToMember',
  FREELANCERINVOICEREMINDERTOCUSTOMER = 'FreelancerInvoiceReminderToCustomer',
  FREELANCERINVOICEREVISEDSUCCESSFULLYTOMEMBER = 'FreelancerInvoiceRevisedSuccessfullyToMember',
  FREELANCERINVOICESECONDREMINDERTOMEMBER = 'FreelancerInvoiceSecondReminderToMember',
  /** @deprecated Use FreelancerInvoiceRaisedToCustomer instead */
  FREELANCERINVOICESUBMITTEDTOCOMPANY = 'FreelancerInvoiceSubmittedToCompany',
  FREELANCERINVOICESUBMITTEDTOMEMBER = 'FreelancerInvoiceSubmittedToMember',
  FREELANCERINVOICESUBMITTEDTOOPS = 'FreelancerInvoiceSubmittedToOps',
  FREELANCERINVOICETHIRDREMINDERTOMEMBER = 'FreelancerInvoiceThirdReminderToMember',
  FREELANCERINVOICEUPDATEDTOCUSTOMER = 'FreelancerInvoiceUpdatedToCustomer',
  FREELANCERPAYOUTFAILCLIENT = 'FreelancerPayoutFailClient',
  FREELANCERPAYOUTFAILFREELANCER = 'FreelancerPayoutFailFreelancer',
  FUNDCLEAREDWITHCHARGEBACKPERIOD = 'FundClearedWithChargebackPeriod',
  FUNDCLEAREDWITHOUTCHARGEBACKPERIOD = 'FundClearedWithoutChargebackPeriod',
  GPANDHRISMEMBERINVITE = 'GPAndHRISMemberInvite',
  HRISONLYMEMBERINVITE = 'HRISOnlyMemberInvite',
  HRISROLECHANGEEMAILTOEMPLOYEE = 'HRISRoleChangeEmailToEmployee',
  INPUTFILEDOWNLOADFAILEDTOOPS = 'InputFileDownloadFailedToOps',
  INPUTFILEDOWNLOADSUCCESSTOOPS = 'InputFileDownloadSuccessToOps',
  INSURANCECLIENTEMAILSFORAGEBASEDCOST = 'InsuranceClientEmailsForAgeBasedCost',
  INTEGRATIONAUTOSYNCFAILEDEMAILTOADMIN = 'IntegrationAutoSyncFailedEmailToAdmin',
  INTEGRATIONCONNECTEDEMAILTOADMIN = 'IntegrationConnectedEmailToAdmin',
  INTEGRATIONCREDENTIALSEXPIREDEMAILTOADMIN = 'IntegrationCredentialsExpiredEmailToAdmin',
  INTEGRATIONDISCONNECTEDEMAILTOADMIN = 'IntegrationDisconnectedEmailToAdmin',
  INTEGRATIONEMPLOYEEIMPORTCOMPLETEDEMAILTOADMIN = 'IntegrationEmployeeImportCompletedEmailToAdmin',
  INTEGRATIONEMPLOYEESYNCTURNEDONEMAILTOADMIN = 'IntegrationEmployeeSyncTurnedOnEmailToAdmin',
  INTEGRATIONSYNCRESULTSEMAILTOADMIN = 'IntegrationSyncResultsEmailToAdmin',
  INVOICEPAIDEMAILTOCOMPANY = 'InvoicePaidEmailToCompany',
  LEGALDOCUMENTAPPROVEDEMAILTOMEMBER = 'LegalDocumentApprovedEmailToMember',
  LEGALDOCUMENTCREATEDEMAILTOOPS = 'LegalDocumentCreatedEmailToOps',
  LEGALDOCUMENTREJECTEDEMAILTOMEMBER = 'LegalDocumentRejectedEmailToMember',
  LEGALENTITYACTIVATEDTOADMIN = 'LegalEntityActivatedToAdmin',
  MEMBERCHANGEREQUESTSTATUS = 'MemberChangeRequestStatus',
  MEMBERCHANGEREQUESTSTATUSAPPROVED = 'MemberChangeRequestStatusApproved',
  MEMBERCHANGEREQUESTSTATUSREJECTED = 'MemberChangeRequestStatusRejected',
  MEMBERCHANGEREQUESTTOCOMPANYADMIN = 'MemberChangeRequestToCompanyAdmin',
  MEMBERCOMPLETEONBOARDINGREMINDER = 'MemberCompleteOnboardingReminder',
  MEMBERCOMPLETEDONBOARDINGTOMEMBERANDADMINS = 'MemberCompletedOnboardingToMemberAndAdmins',
  MEMBERINVITE = 'MemberInvite',
  MEMBERJOININGDAY = 'MemberJoiningDay',
  MEMBERJOININGDAYBATCH = 'MemberJoiningDayBatch',
  MEMBERJOININGDAYPAYROLLFORMS = 'MemberJoiningDayPayrollForms',
  MEMBEROFFBOARDINGCANCELEMAILTOMEMBER = 'MemberOffBoardingCancelEmailToMember',
  MEMBEROFFBOARDINGCANCELEMAILTOOPS = 'MemberOffBoardingCancelEmailToOps',
  MEMBEROFFBOARDINGEMAILTOMEMBER = 'MemberOffBoardingEmailToMember',
  MEMBEROFFBOARDINGEMAILTOOPS = 'MemberOffBoardingEmailToOps',
  MEMBEROFFBOARDINGFUTUREDATECONTRACTENDEMAILTOMEMBER = 'MemberOffBoardingFutureDateContractEndEmailToMember',
  MEMBERPENDINGPAYROLLFORMSSUBMISSIONREMINDERTOMEMBER = 'MemberPendingPayrollFormsSubmissionReminderToMember',
  MEMBERVERIFICATIONCOMPLETE = 'MemberVerificationComplete',
  MSAEMAILTOCOMPANY = 'MsaEmailToCompany',
  MSASIGNEDEMAILTOCXLEADERS = 'MsaSignedEmailToCXLeaders',
  OFFERLETTEREMAIL = 'OfferLetterEmail',
  ONBOARDINGSPECIALISTEMAIL = 'OnboardingSpecialistEmail',
  OPSASSIGNCUSTOMERTOCSM = 'OpsAssignCustomerToCsm',
  OPSCOMPANYISFXSENSITIVE = 'OpsCompanyIsFxSensitive',
  OPSCONTRACTASSIGNEDTOONBOARDINGSPECIALIST = 'OpsContractAssignedToOnboardingSpecialist',
  OPSCONTRACTDELETED = 'OpsContractDeleted',
  OPSCONTRACTREQUESTCHANGESFORMEMBER = 'OpsContractRequestChangesForMember',
  OPSCONTRACTREVOKED = 'OpsContractRevoked',
  OPSEMPLOYERONBOARDEDMEMBER = 'OpsEmployerOnboardedMember',
  OPSMEMBERCHANGEREQUEST = 'OpsMemberChangeRequest',
  OPSMEMBERCOMPLETEDONBOARDING = 'OpsMemberCompletedOnboarding',
  OPSMEMBERONBOARDINGFORACOUNTRYWITHWETINKREQUIREMENT = 'OpsMemberOnboardingForACountryWithWetInkRequirement',
  OPSMEMBERONBOARDINGFORACOUNTRYWITHOUTCONTRACT = 'OpsMemberOnboardingForACountryWithoutContract',
  OPSMEMBERRETIREMENTPLANDETAILS = 'OpsMemberRetirementPlanDetails',
  OPSOFFBOARDINGCONTRACTSREMINDER = 'OpsOffboardingContractsReminder',
  OPSPAYSUPPLEMENTADDED = 'OpsPaySupplementAdded',
  OPSPAYSUPPLEMENTREVOKED = 'OpsPaySupplementRevoked',
  OPSPAYSUPPLEMENTUPDATED = 'OpsPaySupplementUpdated',
  OPSREPORTSENTFORREVIEW = 'OpsReportSentForReview',
  OPSSTARTDATECHANGED = 'OpsStartDateChanged',
  PARTNERPAYROLLINITIATIONWITHUPDATES = 'PartnerPayrollInitiationWithUpdates',
  PARTNERPAYROLLINITIATIONWITHOUTUPDATES = 'PartnerPayrollInitiationWithoutUpdates',
  PAYSUPPLEMENTREMINDERTOCOMPANYADMINS = 'PaySupplementReminderToCompanyAdmins',
  PAYSUPPLEMENTREMINDERTOCOMPANYADMINSMONTHLY = 'PaySupplementReminderToCompanyAdminsMonthly',
  PAYSUPPLEMENTREMINDERTOCOMPANYADMINSMONTHLYCOUNTRYSPECIFICCUTOFF = 'PaySupplementReminderToCompanyAdminsMonthlyCountrySpecificCutOff',
  PAYMENTBUNDLEMARKASPAIDEMAILTOOPS = 'PaymentBundleMarkAsPaidEmailToOps',
  PAYMENTBUNDLEUNPAIDREMINDEREMAILTOCOMPANY = 'PaymentBundleUnpaidReminderEmailToCompany',
  PAYROLLCYCLEOUTPUTAPPROVEDEMAILTOOPS = 'PayrollCycleOutputApprovedEmailToOps',
  PAYROLLMEMBERINVITE = 'PayrollMemberInvite',
  PAYROLLPARTNERUSERINVITE = 'PayrollPartnerUserInvite',
  PAYSLIPFILEVALIDATIONRESULTEMAIL = 'PayslipFileValidationResultEmail',
  PAYSLIPMEMBEREMAIL = 'PayslipMemberEmail',
  PENDINGAPPROVALEMAILTONEWAPPROVER = 'PendingApprovalEmailToNewApprover',
  PERFORMANCEREVIEWREMINDEREMAILTOCOMPANYSIGNATORY = 'PerformanceReviewReminderEmailToCompanySignatory',
  PERFORMANCEREVIEWREMINDEREMAILTOEMPLOYEE = 'PerformanceReviewReminderEmailToEmployee',
  PERFORMANCEREVIEWSIGNATORYEMAILTOEMPLOYEE = 'PerformanceReviewSignatoryEmailToEmployee',
  PREREGISTRATIONCONTRACTACTIVATIONEMAILTOOPS = 'PreRegistrationContractActivationEmailToOps',
  PROBATIONENDREMINDERTOCOMPANY = 'ProbationEndReminderToCompany',
  RESETPASSWORD = 'ResetPassword',
  REVIEWEORPAYROLLREMINDER = 'ReviewEorPayrollReminder',
  REVIEWEORPAYROLLREPORTREMINDER = 'ReviewEorPayrollReportReminder',
  REVIEWPEOPAYROLLREMINDER = 'ReviewPeoPayrollReminder',
  SALARYCALCULATORREQUESTEMAIL = 'SalaryCalculatorRequestEmail',
  SECONDCONTRACTONBOARDINGREMINDERTOCOMPANY = 'SecondContractOnboardingReminderToCompany',
  SECONDCONTRACTONBOARDINGREMINDERTOMEMBER = 'SecondContractOnboardingReminderToMember',
  SENDCONTRACTADDENDUMFORSIGNINGTOCOMPANY = 'SendContractAddendumForSigningToCompany',
  SENDCONTRACTADDENDUMFORSIGNINGTOMEMBER = 'SendContractAddendumForSigningToMember',
  SENDCONTRACTADDENDUMFORSIGNINGTOMULTIPLIER = 'SendContractAddendumForSigningToMultiplier',
  SENDTOADMINAFTERMEMBERSIGNCONTRACT = 'SendToAdminAfterMemberSignContract',
  SENDTOCOMPANYFORSIGNING = 'SendToCompanyForSigning',
  SENDTOCOMPANYFORWETINKCONTRACT = 'SendToCompanyForWetInkContract',
  SENDTOMEMBERFORSIGNING = 'SendToMemberForSigning',
  SENDTOMEMBERFORWELCOME = 'SendToMemberForWelcome',
  SENDTOMEMBERFORWELCOMECONTRACTOR = 'SendToMemberForWelcomeContractor',
  SENDTOMEMBERFORWETINKCONTRACT = 'SendToMemberForWetInkContract',
  SENDTOMULTIPLIERFORSIGNING = 'SendToMultiplierForSigning',
  TRSEMAILTOEMPLOYEE = 'TRSEmailToEmployee',
  TRSEMAILTOEMPLOYER = 'TRSEmailToEmployer',
  TRSEMAILTOMULTIPLIERFORSIGNATURE = 'TRSEmailToMultiplierForSignature',
  TRSEMAILTOOPS = 'TRSEmailToOps',
  TRSHRMEMBEREMAILTOOPS = 'TRSHrMemberEmailToOps',
  TRSPARTNEREOREMAILTOEMPLOYEE = 'TRSPartnerEOREmailToEmployee',
  TRSPARTNEREOREMAILTOOPS = 'TRSPartnerEOREmailToOps',
  TRSREMINDEREMAILTOEMPLOYER = 'TRSReminderEmailToEmployer',
  THIRDCONTRACTONBOARDINGREMINDERTOCOMPANY = 'ThirdContractOnboardingReminderToCompany',
  THIRDCONTRACTONBOARDINGREMINDERTOMEMBER = 'ThirdContractOnboardingReminderToMember',
  TIMEOFFAPPROVEEMAILTOMEMBER = 'TimeoffApproveEmailToMember',
  TIMEOFFCREATEDEMAILTOAPPROVER = 'TimeoffCreatedEmailToApprover',
  TIMEOFFPOLICYASSIGNMENTEMAILTOADMIN = 'TimeoffPolicyAssignmentEmailToAdmin',
  TIMEOFFPOLICYCREATEDEMAILTOADMIN = 'TimeoffPolicyCreatedEmailToAdmin',
  TIMEOFFPOLICYDELETEDEMAILTOADMIN = 'TimeoffPolicyDeletedEmailToAdmin',
  TIMEOFFPOLICYUPDATEDEMAILTOADMIN = 'TimeoffPolicyUpdatedEmailToAdmin',
  TIMEOFFREJECTEMAILTOMEMBER = 'TimeoffRejectEmailToMember',
  TIMEOFFTYPECREATEDEMAILTOADMIN = 'TimeoffTypeCreatedEmailToAdmin',
  TIMEOFFTYPEDELETEDEMAILTOADMIN = 'TimeoffTypeDeletedEmailToAdmin',
  TIMEOFFTYPEUPDATEDEMAILTOADMIN = 'TimeoffTypeUpdatedEmailToAdmin',
  TIMESHEETAPPROVESUBMITTEDHOURSEMAILONENDCYCLETOADMIN = 'TimesheetApproveSubmittedHoursEmailOnEndCycleToAdmin',
  TIMESHEETAPPROVESUBMITTEDHOURSEMAILONMIDCYCLETOADMIN = 'TimesheetApproveSubmittedHoursEmailOnMidCycleToAdmin',
  TIMESHEETAPPROVEDEMAILTOMEMBER = 'TimesheetApprovedEmailToMember',
  TIMESHEETAPPROVEDEMAILTOOPS = 'TimesheetApprovedEmailToOps',
  TIMESHEETAPPROVEDHOURSEMAILTOHOURLYMEMBER = 'TimesheetApprovedHoursEmailToHourlyMember',
  TIMESHEETAPPROVEDHOURSEMAILTONONHOURLYMEMBER = 'TimesheetApprovedHoursEmailToNonHourlyMember',
  TIMESHEETAUTOSUBMITEMAILTOHOURLYRATEMEMBERS = 'TimesheetAutoSubmitEmailToHourlyRateMembers',
  TIMESHEETAUTOSUBMITEMAILTONONHOURLYRATEMEMBERS = 'TimesheetAutoSubmitEmailToNonHourlyRateMembers',
  TIMESHEETREJECTEDEMAILTOMEMBER = 'TimesheetRejectedEmailToMember',
  TIMESHEETREJECTEDHOURSEMAILTOMEMBER = 'TimesheetRejectedHoursEmailToMember',
  TIMESHEETSUBMITTEDEMAILTOCOMPANYUSER = 'TimesheetSubmittedEmailToCompanyUser',
  UPCOMINGMONTHLYINVOICEEMAILTOCOMPANY = 'UpcomingMonthlyInvoiceEmailToCompany',
  UPDATEBANKDETAILSONMULTIPLIEREMAILTOMEMBER = 'UpdateBankDetailsOnMultiplierEmailToMember',
  UPDATEBASICDETAILSONMULTIPLIEREMAILTOMEMBER = 'UpdateBasicDetailsOnMultiplierEmailToMember',
  VISAAPPROVEDTOMEMBER = 'VisaApprovedToMember',
  VISADOCUMENTSSUBMITTEDTOMEMBER = 'VisaDocumentsSubmittedToMember',
  VISADOCUMENTSSUBMITTEDTOOPS = 'VisaDocumentsSubmittedToOps',
  VISADOCUMENTSVERIFIEDTOMEMBER = 'VisaDocumentsVerifiedToMember',
  VISAINVITEFORELIGIBILITYTOMEMBER = 'VisaInviteForEligibilityToMember',
  VISAMEMBERADDEDTOOPS = 'VisaMemberAddedToOps',
  VISASENDCONTRACTREMINDERTOCOMPANY = 'VisaSendContractReminderToCompany',
}

export enum NumberConfigUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export type NumberConfigValue = {
  unit?: Maybe<NumberConfigUnit>;
};

export enum OffBoardingExperienceStep {
  COMPLETED = 'COMPLETED',
  EMPLOYEE_RESIGNATION_LETTER_SIGNED = 'EMPLOYEE_RESIGNATION_LETTER_SIGNED',
  INITIATED_OFFBOARDING = 'INITIATED_OFFBOARDING',
  NOT_STARTED = 'NOT_STARTED',
  RESIGNATION_LETTER_SENT = 'RESIGNATION_LETTER_SENT',
}

export type Offboarding = {
  __typename?: 'Offboarding';
  connectingTransitions: Array<OffboardingTransition>;
  contractId?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  lastWorkingDay?: Maybe<Scalars['Date']>;
  state?: Maybe<OffboardingState>;
  type?: Maybe<OffboardingType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum OffboardingAction {
  CLIENT_APPROVE_LWD = 'CLIENT_APPROVE_LWD',
  CLIENT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT = 'CLIENT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT',
  CONFIRM_ASSETS_RECOVERED = 'CONFIRM_ASSETS_RECOVERED',
  INPUT_LWD = 'INPUT_LWD',
  LWD_PASSED = 'LWD_PASSED',
  MEMBER_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT = 'MEMBER_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT',
  MEMBER_SIGNS_RESIGNATION_LETTER = 'MEMBER_SIGNS_RESIGNATION_LETTER',
  MT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT = 'MT_SIGNS_MUTUAL_UNDERSTANDING_AGREEMENT',
  OPS_APPROVE_LWD = 'OPS_APPROVE_LWD',
}

export type OffboardingActionStatus = {
  __typename?: 'OffboardingActionStatus';
  currentStatus?: Maybe<OffboardingTriggerStatus>;
  currentTriggerResult?: Maybe<OffboardingTriggerResult>;
  isDone?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  requiredAction?: Maybe<OffboardingAction>;
  requiredTriggerResult?: Maybe<OffboardingTriggerResult>;
};

export type OffboardingAudit = {
  __typename?: 'OffboardingAudit';
  initiatedBy?: Maybe<Person>;
  initiatedOn?: Maybe<Scalars['DateTime']>;
};

export type OffboardingCanInitializeValidation = {
  __typename?: 'OffboardingCanInitializeValidation';
  canInitialize?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export type OffboardingClientApproveLwdInput = {
  offboardingId: Scalars['ID'];
};

export type OffboardingConfirmAssetsRecoveryInput = {
  offboardingId: Scalars['ID'];
};

export type OffboardingExperienceDetails = {
  __typename?: 'OffboardingExperienceDetails';
  additionalComments?: Maybe<Scalars['String']>;
  contractId: Scalars['ID'];
  desiredLastWorkingDay?: Maybe<Scalars['Date']>;
  documentIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  nextStepsSummmaries?: Maybe<Array<Scalars['String']>>;
  noticePeriod?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  submittedOn?: Maybe<Scalars['DateTime']>;
  tentativeLastWorkingDay?: Maybe<Scalars['Date']>;
};

export type OffboardingExperienceState = {
  __typename?: 'OffboardingExperienceState';
  completedSteps?: Maybe<Array<OffBoardingExperienceStep>>;
  contractId: Scalars['ID'];
  currentStep: OffBoardingExperienceStep;
  errorMessage?: Maybe<Scalars['String']>;
  offboardingDetails: OffboardingExperienceDetails;
  offboardingStatus: OffboardingExperienceStatus;
  remainingSteps?: Maybe<Array<OffBoardingExperienceStep>>;
};

export enum OffboardingExperienceStatus {
  APPROVED = 'APPROVED',
  AWAITING_COMPANY_CONFIRMATION = 'AWAITING_COMPANY_CONFIRMATION',
  AWAITING_EMPLOYEE_SIGNATURE = 'AWAITING_EMPLOYEE_SIGNATURE',
  CANCELLED = 'CANCELLED',
  CANNOT_START = 'CANNOT_START',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  REJECTED = 'REJECTED',
}

export enum OffboardingFeeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum OffboardingFeeType {
  COMPLEX = 'COMPLEX',
  STANDARD = 'STANDARD',
}

export type OffboardingGlobalPricing = {
  __typename?: 'OffboardingGlobalPricing';
  employeeType: ContractType;
  offboardingFeeType: OffboardingFeeType;
  status: OffboardingFeeStatus;
};

export type OffboardingGlobalPricingInput = {
  employeeType: ContractType;
  offboardingFeeType: OffboardingFeeType;
  status: OffboardingFeeStatus;
};

export type OffboardingInitializeInput = {
  contractId: Scalars['ID'];
  type: OffboardingType;
};

export type OffboardingInputLwdInput = {
  lastWorkingDay: Scalars['Date'];
  offboardingId: Scalars['ID'];
};

export type OffboardingOpsApproveLwdInput = {
  offboardingId: Scalars['ID'];
};

export enum OffboardingState {
  ASSETS_RECOVERING = 'ASSETS_RECOVERING',
  DRAFT = 'DRAFT',
  LWD_PENDING = 'LWD_PENDING',
  MUTUAL_UNDERSTANDING_AGREEMENT_PENDING = 'MUTUAL_UNDERSTANDING_AGREEMENT_PENDING',
  RESIGNATION_LETTER_PENDING = 'RESIGNATION_LETTER_PENDING',
}

export type OffboardingTransition = {
  __typename?: 'OffboardingTransition';
  actionStatuses: Array<OffboardingActionStatus>;
  from?: Maybe<OffboardingState>;
  to?: Maybe<OffboardingState>;
};

export enum OffboardingTriggerResult {
  ASSETS_RECOVERED = 'ASSETS_RECOVERED',
  CLIENT_APPROVED_LWD = 'CLIENT_APPROVED_LWD',
  CLIENT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT = 'CLIENT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT',
  LWD_EQ_COMPLIANCE_NOTICE_PERIOD = 'LWD_EQ_COMPLIANCE_NOTICE_PERIOD',
  LWD_GT_COMPLIANCE_NOTICE_PERIOD = 'LWD_GT_COMPLIANCE_NOTICE_PERIOD',
  LWD_LT_COMPLIANCE_NOTICE_PERIOD = 'LWD_LT_COMPLIANCE_NOTICE_PERIOD',
  LWD_PASSED = 'LWD_PASSED',
  MEMBER_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT = 'MEMBER_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT',
  MEMBER_SIGNED_RESIGNATION_LETTER = 'MEMBER_SIGNED_RESIGNATION_LETTER',
  MT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT = 'MT_SIGNED_MUTUAL_UNDERSTANDING_AGREEMENT',
  OPS_APPROVED_LWD = 'OPS_APPROVED_LWD',
}

export enum OffboardingTriggerStatus {
  GUARD_FAILED = 'GUARD_FAILED',
  SUCCESS = 'SUCCESS',
}

export enum OffboardingType {
  RESIGNATION = 'RESIGNATION',
}

export type Offering = {
  __typename?: 'Offering';
  capabilities?: Maybe<Array<Maybe<Capability>>>;
  code?: Maybe<OfferingCode>;
  name?: Maybe<Scalars['String']>;
};

export type OfferingBundleDiscountTermCondition = {
  __typename?: 'OfferingBundleDiscountTermCondition';
  excludes?: Maybe<Array<Maybe<OfferingCode>>>;
  includes?: Maybe<Array<Maybe<OfferingCode>>>;
};

export type OfferingBundleDiscountTermConditionInput = {
  excludes?: InputMaybe<Array<InputMaybe<OfferingCode>>>;
  includes?: InputMaybe<Array<InputMaybe<OfferingCode>>>;
};

export enum OfferingCode {
  AOR = 'AOR',
  EOR = 'EOR',
  FREELANCER = 'FREELANCER',
  GLOBAL_PAYROLL = 'GLOBAL_PAYROLL',
  HRIS = 'HRIS',
}

export enum OfferingStatus {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
}

export type OnboardingAuditFilter = {
  contractId: Scalars['ID'];
};

export type OnboardingTask = {
  __typename?: 'OnboardingTask';
  completed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pendingOn?: Maybe<OnboardingTaskUser>;
};

export type OnboardingTaskUser = {
  __typename?: 'OnboardingTaskUser';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  userId?: Maybe<Scalars['String']>;
};

export type OperationsDashboard = {
  __typename?: 'OperationsDashboard';
  aeAllocation?: Maybe<Array<Maybe<Allocation>>>;
  aeUnassigned?: Maybe<Scalars['Int']>;
  createdStateContractCount?: Maybe<Scalars['Int']>;
  csmAllocation?: Maybe<Array<Maybe<Allocation>>>;
  csmUnassigned?: Maybe<Scalars['Int']>;
  inviteStateContractCount?: Maybe<Scalars['Int']>;
  offboardingInitiatedContractCount?: Maybe<Scalars['Int']>;
  sentContractCount?: Maybe<Scalars['Int']>;
  signedMSACount?: Maybe<Scalars['Int']>;
  willEndContractCount?: Maybe<Scalars['Int']>;
};

export type OperationsUser = Person & {
  __typename?: 'OperationsUser';
  calendlyLink?: Maybe<Scalars['String']>;
  /** @deprecated Use `profilePicture` instead */
  displayPicture?: Maybe<DocumentReadable>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  onboardingContracts?: Maybe<Array<Contract>>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  profilePicture?: Maybe<Document>;
  roles?: Maybe<Array<Maybe<OperationsUserRole>>>;
  userId?: Maybe<Scalars['String']>;
};

export type OperationsUser_OnboardingContractsArgs = {
  status?: InputMaybe<ContractStatus>;
};

export type OperationsUserCreateInput = {
  calendlyLink?: InputMaybe<Scalars['String']>;
  displayPicture?: InputMaybe<Scalars['Upload']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<OperationsUserRole>>>;
};

export enum OperationsUserRole {
  ACCOUNT_EXECUTIVE = 'ACCOUNT_EXECUTIVE',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  ADMIN = 'ADMIN',
  CONTRACT_MANAGER = 'CONTRACT_MANAGER',
  CUSTOMER_SUCCESS_LEAD = 'CUSTOMER_SUCCESS_LEAD',
  CUSTOMER_SUCCESS_SPECIALIST = 'CUSTOMER_SUCCESS_SPECIALIST',
  DEVELOPER_ADMIN = 'DEVELOPER_ADMIN',
  FINANCE_OPS_SPECIALIST = 'FINANCE_OPS_SPECIALIST',
  OFFBOARDING_SPECIALIST = 'OFFBOARDING_SPECIALIST',
  ONBOARDING_LEAD = 'ONBOARDING_LEAD',
  ONBOARDING_SPECIALIST = 'ONBOARDING_SPECIALIST',
  PAYROLL_LEAD = 'PAYROLL_LEAD',
  PAYROLL_SPECIALIST = 'PAYROLL_SPECIALIST',
  SALES_OPS = 'SALES_OPS',
}

export type OperationsUserUpdateInput = {
  calendlyLink?: InputMaybe<Scalars['String']>;
  displayPicture?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
};

export type OpsPayslipUploadWorkflow = {
  __typename?: 'OpsPayslipUploadWorkflow';
  allSteps?: Maybe<Array<Maybe<OpsPayslipUploadWorkflowStep>>>;
  currentStep?: Maybe<OpsPayslipUploadWorkflowStep>;
};

export enum OpsPayslipUploadWorkflowStep {
  PAYSLIPS_CONFIRMED = 'PAYSLIPS_CONFIRMED',
  PAYSLIPS_VALIDATION_IN_PROGRESS = 'PAYSLIPS_VALIDATION_IN_PROGRESS',
  PAYSLIPS_VALIDATION_REPORT = 'PAYSLIPS_VALIDATION_REPORT',
  PAYSLIPS_VALIDATION_REPORT_DOWNLOADED = 'PAYSLIPS_VALIDATION_REPORT_DOWNLOADED',
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OrgAttributes = {
  __typename?: 'OrgAttributes';
  costCenter?: Maybe<CostCenter>;
  department?: Maybe<Department>;
};

export type OrgAttributesInput = {
  costCenterId?: InputMaybe<Scalars['ID']>;
  departmentId?: InputMaybe<Scalars['ID']>;
};

export type OrgDirectory = {
  __typename?: 'OrgDirectory';
  contracts?: Maybe<Array<Contract>>;
  managers?: Maybe<Array<Manager>>;
};

export type OrgDirectoryAssignReportsToInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  managerId?: InputMaybe<Scalars['ID']>;
  reportsToManagerId: Scalars['ID'];
};

export type OrgDirectoryFilters = {
  companyId: Scalars['ID'];
  contractStatuses?: InputMaybe<Array<ContractStatus>>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  costCenterIds?: InputMaybe<Array<Scalars['ID']>>;
  departmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** @deprecated Now BE decide this and will only include test contracts for test company and non-test contracts for non-test company. */
  excludeTestContracts?: InputMaybe<Scalars['Boolean']>;
  includeNotStartedContracts?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<RuleInput>;
};

export type OrgDirectoryValidateReportsToInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  managerId?: InputMaybe<Scalars['ID']>;
  reportsToManagerId: Scalars['ID'];
};

export type OrgRole = {
  __typename?: 'OrgRole';
  companyId: Scalars['ID'];
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  managers?: Maybe<Array<Manager>>;
  name: Scalars['String'];
  permissions?: Maybe<Array<Permission>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type OrgRoleAssignManagersInput = {
  managerIds: Array<Scalars['ID']>;
  roleId: Scalars['ID'];
};

export type OrgRoleCreateInput = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Scalars['ID']>;
};

export type OrgRoleFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type OrgRoleUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Scalars['ID']>;
};

export type OutputHeadcountSummary = {
  __typename?: 'OutputHeadcountSummary';
  active?: Maybe<Scalars['Int']>;
  leaver?: Maybe<Scalars['Int']>;
  starter?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Override = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type OverrideInfo = {
  payrollOverride?: InputMaybe<Array<InputMaybe<Override>>>;
};

export type PageRequest = {
  /** pageNumber is the zero-based page number */
  pageNumber?: InputMaybe<Scalars['Int']>;
  /** pageSize is the size of page */
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type PageResult = {
  __typename?: 'PageResult';
  count?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PageSection = {
  __typename?: 'PageSection';
  editable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Section>;
  reason?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<FieldAccessibilityRule>>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type ParsedMemberPay = {
  __typename?: 'ParsedMemberPay';
  contract?: Maybe<Contract>;
  currency?: Maybe<CurrencyCode>;
  designation?: Maybe<Scalars['String']>;
  employeeDeductions?: Maybe<Scalars['Float']>;
  employerContributions?: Maybe<Scalars['Float']>;
  grossSalary?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  netSalary?: Maybe<Scalars['Float']>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
  rowId?: Maybe<Scalars['ID']>;
  totalPayrollCost?: Maybe<Scalars['Float']>;
};

export type Partner = {
  __typename?: 'Partner';
  countries?: Maybe<Array<Maybe<PartnerCountry>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Partner_CountriesArgs = {
  capability?: InputMaybe<PartnerCapability>;
  country?: InputMaybe<CountryCode>;
};

export type PartnerAccountRequirement = {
  __typename?: 'PartnerAccountRequirement';
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  requirementFields?: Maybe<Array<Maybe<RequirementField>>>;
};

export enum PartnerCapability {
  BENEFIT = 'BENEFIT',
  EOR = 'EOR',
  PAYROLL = 'PAYROLL',
}

export type PartnerCountry = {
  capability?: Maybe<PartnerCapability>;
  country?: Maybe<CountryCode>;
  id?: Maybe<Scalars['ID']>;
  partner?: Maybe<Partner>;
};

export type PartnerCountryInput = {
  capability: PartnerCapability;
  countryCode: CountryCode;
  currency?: InputMaybe<CurrencyCode>;
  emergencyPointOfContact?: InputMaybe<EmergencyPointOfContactInput>;
};

export type PartnerMemberPay = MemberPay & {
  __typename?: 'PartnerMemberPay';
  additional?: Maybe<Array<Maybe<AdditionalPayComponent>>>;
  aggregatedGross?: Maybe<Scalars['Float']>;
  amountGross?: Maybe<Scalars['Float']>;
  amountNet?: Maybe<Scalars['Float']>;
  amountTotalCost?: Maybe<Scalars['Float']>;
  billingCurrency?: Maybe<CurrencyCode>;
  clientDeductions?: Maybe<Array<Maybe<PayComponent>>>;
  companyPayroll?: Maybe<CompanyPayroll>;
  contract?: Maybe<Contract>;
  contributions?: Maybe<Array<Maybe<PayComponent>>>;
  currency?: Maybe<CurrencyCode>;
  deductions?: Maybe<Array<Maybe<PayComponent>>>;
  expenses?: Maybe<Array<Maybe<Expense>>>;
  fxRateApplied?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
  payrollCycle?: Maybe<PayrollCycle>;
  payslip?: Maybe<Payslip>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
  status?: Maybe<MemberPayStatus>;
  totalAllowanceAmount?: Maybe<Scalars['Float']>;
  totalBonusAmount?: Maybe<Scalars['Float']>;
  totalCommissionAmount?: Maybe<Scalars['Float']>;
  totalContributionAmount?: Maybe<Scalars['Float']>;
  totalDeductionsAmount?: Maybe<Scalars['Float']>;
  totalExpenseAmount?: Maybe<Scalars['Float']>;
  totalSupplementAmount?: Maybe<Scalars['Float']>;
};

export type PartnerPayroll = Payroll & {
  __typename?: 'PartnerPayroll';
  amountTotalCost?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  memberPays?: Maybe<Array<Maybe<PartnerMemberPay>>>;
  month?: Maybe<PayrollMonth>;
  partner?: Maybe<PayrollPartnerCountry>;
  payrollProcess?: Maybe<PayrollProcess>;
  status?: Maybe<PartnerPayrollStatus>;
};

export type PartnerPayrollComponent = {
  __typename?: 'PartnerPayrollComponent';
  category: Scalars['String'];
  country: CountryCode;
  displayName: Scalars['String'];
  id: Scalars['UUID'];
  mandatory?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partnerId: Scalars['ID'];
};

export type PartnerPayrollGeneratePayslipsInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  country?: InputMaybe<CountryCode>;
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth: PayrollMonthInput;
};

export type PartnerPayrollSchema = {
  __typename?: 'PartnerPayrollSchema';
  components: Array<PartnerPayrollComponent>;
};

export type PartnerPayrollSchemaInput = {
  country: CountryCode;
  partnerId: Scalars['ID'];
};

export type PartnerPayrollSendPayslipsInput = {
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  country?: InputMaybe<CountryCode>;
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth: PayrollMonthInput;
};

export enum PartnerPayrollStatus {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
}

export type PartnerPayrollUploadPayslipInput = {
  contractId: Scalars['ID'];
  currencyCode: CurrencyCode;
  netAmount: Scalars['Float'];
  payrollCycle?: InputMaybe<Scalars['Int']>;
  payrollMonth: PayrollMonthInput;
};

export type PartnerPayrollVersion = {
  __typename?: 'PartnerPayrollVersion';
  memberPays?: Maybe<Array<Maybe<PartnerMemberPay>>>;
  partnerPayroll?: Maybe<PartnerPayroll>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
  status?: Maybe<PartnerPayrollVersionStatus>;
  version?: Maybe<Scalars['Int']>;
};

export enum PartnerPayrollVersionStatus {
  CLOSED = 'CLOSED',
  GENERATED_PAYSLIPS = 'GENERATED_PAYSLIPS',
  MEMBER_PAYMENT_DONE = 'MEMBER_PAYMENT_DONE',
  MEMBER_PAYMENT_IN_PROGRESS = 'MEMBER_PAYMENT_IN_PROGRESS',
  NULL_STATUS = 'NULL_STATUS',
  PARTNER_PAYMENT_DONE = 'PARTNER_PAYMENT_DONE',
  PARTNER_PAYMENT_IN_PROGRESS = 'PARTNER_PAYMENT_IN_PROGRESS',
  PAYSLIPS_APPROVED = 'PAYSLIPS_APPROVED',
  PAYSLIPS_CHANGES_REQUESTED = 'PAYSLIPS_CHANGES_REQUESTED',
  PAYSLIPS_REVIEW_IN_PROGRESS = 'PAYSLIPS_REVIEW_IN_PROGRESS',
  REPORT_APPROVED = 'REPORT_APPROVED',
  REPORT_CHANGES_REQUESTED = 'REPORT_CHANGES_REQUESTED',
  REPORT_REVIEW_IN_PROGRESS = 'REPORT_REVIEW_IN_PROGRESS',
  REPORT_SENT_FOR_REVIEW = 'REPORT_SENT_FOR_REVIEW',
  UPLOADED_PAYSLIPS = 'UPLOADED_PAYSLIPS',
}

export enum PartnerPayrollVersionStep {
  PAYMENT = 'PAYMENT',
  PAYROLL_HISTORY = 'PAYROLL_HISTORY',
  PAYROLL_REPORT = 'PAYROLL_REPORT',
  PAYROLL_UPDATE = 'PAYROLL_UPDATE',
  REPORT_REVIEW = 'REPORT_REVIEW',
  UPLOAD_PAYSLIP = 'UPLOAD_PAYSLIP',
}

export enum PartnerRole {
  ADMIN = 'ADMIN',
  PRIMARY_ADMIN = 'PRIMARY_ADMIN',
}

export type PartnerSupportInfo = {
  __typename?: 'PartnerSupportInfo';
  accountType?: Maybe<PaymentAccountType>;
  paymentAccountRequirements?: Maybe<PaymentAccountPartnerRequirement>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  paymentProcessingMethods?: Maybe<Array<Maybe<PaymentProcessingMethod>>>;
  sourceCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export type PartnerUser = {
  __typename?: 'PartnerUser';
  countryCode?: Maybe<CountryCode>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  partner?: Maybe<Partner>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  role?: Maybe<Scalars['String']>;
  type?: Maybe<PartnerUserType>;
  userId?: Maybe<Scalars['ID']>;
};

export enum PartnerUserStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  INVITED = 'INVITED',
}

export enum PartnerUserType {
  PAYMENTS = 'PAYMENTS',
  PAYROLL_PRIMARY = 'PAYROLL_PRIMARY',
  PAYROLL_SECONDARY = 'PAYROLL_SECONDARY',
}

export enum PayAmountType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  FIXED_PERCENTAGE = 'FIXED_PERCENTAGE',
  VARIABLE_AMOUNT = 'VARIABLE_AMOUNT',
  VARIABLE_PERCENTAGE = 'VARIABLE_PERCENTAGE',
}

export type PayComponent = {
  __typename?: 'PayComponent';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PayComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum PayComponentType {
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  DEDUCTIONS = 'DEDUCTIONS',
  OTHER = 'OTHER',
}

export type PayDetail = {
  __typename?: 'PayDetail';
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<BillingRate>;
  type?: Maybe<PayComponentType>;
  value?: Maybe<Scalars['Float']>;
};

export enum PayFrequency {
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  WEEKLY = 'WEEKLY',
}

export type PayFrequencyDate = {
  __typename?: 'PayFrequencyDate';
  dateOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  identifier?: Maybe<PayFrequencyDateIdentifier>;
};

export enum PayFrequencyDateIdentifier {
  FIRST_PAYOUT_DATE = 'FIRST_PAYOUT_DATE',
  PAYOUT_DATE = 'PAYOUT_DATE',
  PAYOUT_DAY = 'PAYOUT_DAY',
  SECOND_PAYOUT_DATE = 'SECOND_PAYOUT_DATE',
}

export type PayFrequencyDateInput = {
  dateOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  identifier?: InputMaybe<PayFrequencyDateIdentifier>;
};

export type PayInInfo = {
  __typename?: 'PayInInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Array<Maybe<PayInMetadata>>>;
};

export type PayInMetadata = {
  __typename?: 'PayInMetadata';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PayInMethod = {
  context?: Maybe<PayInMethodContext>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  payInMethodType?: Maybe<PayInMethodType>;
  workflowSteps: Array<Maybe<PayInMethodWorkflowStep>>;
};

export type PayInMethodAccountRequirement = {
  __typename?: 'PayInMethodAccountRequirement';
  fields?: Maybe<Array<Maybe<PaymentRequirementField>>>;
  payInAccountRequirementType?: Maybe<Scalars['String']>;
};

export type PayInMethodContext = {
  __typename?: 'PayInMethodContext';
  country?: Maybe<CountryCode>;
  sourceCurrency?: Maybe<CurrencyCode>;
  targetCurrency?: Maybe<CurrencyCode>;
};

export type PayInMethodDefinition = {
  __typename?: 'PayInMethodDefinition';
  requirement?: Maybe<Array<Maybe<PayInMethodAccountRequirement>>>;
  step?: Maybe<PayInMethodDefinitionStep>;
};

export enum PayInMethodDefinitionStep {
  ACTIVATION = 'ACTIVATION',
  COLLECTING_BANK_DETAILS = 'COLLECTING_BANK_DETAILS',
  DELETED = 'DELETED',
  DOCUMENT_SIGNING = 'DOCUMENT_SIGNING',
  MANDATE_SUBMISSION = 'MANDATE_SUBMISSION',
}

export type PayInMethodFilters = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  payInMethodTypes?: InputMaybe<Array<InputMaybe<PayInMethodType>>>;
};

export type PayInMethodRequirement = {
  __typename?: 'PayInMethodRequirement';
  context?: Maybe<PayInMethodContext>;
  definition?: Maybe<Array<Maybe<PayInMethodDefinition>>>;
  id: Scalars['ID'];
  partner?: Maybe<Scalars['String']>;
  payInMethodType?: Maybe<PayInMethodType>;
};

export enum PayInMethodType {
  ACH_DIRECT_DEBIT = 'ACH_DIRECT_DEBIT',
  SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
}

export type PayInMethodWorkflowStep = {
  data: Array<Maybe<AccountDetails>>;
  step?: Maybe<PayInMethodDefinitionStep>;
};

export enum PayInStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type PaySlipFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  csmUserId?: InputMaybe<Scalars['ID']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  memberNameContains?: InputMaybe<Scalars['String']>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  payrollMonth?: InputMaybe<MonthYearInput>;
  payslipId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PayslipStatus>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type PaySummary = {
  __typename?: 'PaySummary';
  absolute?: Maybe<Scalars['Float']>;
  hourly?: Maybe<Scalars['Boolean']>;
};

export type PaySupplement = {
  __typename?: 'PaySupplement';
  amount?: Maybe<Scalars['Float']>;
  amountInFunctionalCurrency?: Maybe<Scalars['Float']>;
  changes?: Maybe<Array<Maybe<PaySupplementChange>>>;
  contract?: Maybe<Contract>;
  createdDate?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  status?: Maybe<PaySupplementStatus>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<PaySupplementType>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type PaySupplement_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
};

export type PaySupplementAutoCreationFilters = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PaySupplementChange = {
  __typename?: 'PaySupplementChange';
  actionedBy?: Maybe<Person>;
  actionedOn?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<PaySupplementStatus>;
};

export type PaySupplementCreateInput = {
  amount: Scalars['Float'];
  compensationId?: InputMaybe<Scalars['ID']>;
  contractId: Scalars['ID'];
  currency?: InputMaybe<CurrencyCode>;
  description?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  title?: InputMaybe<Scalars['String']>;
  type: PaySupplementType;
};

export type PaySupplementFilters = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatus?: InputMaybe<ContractStatus>;
  /** @deprecated Use contractTypes instead */
  contractType?: InputMaybe<ContractType>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  country?: InputMaybe<Array<CountryCode>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  isMultiplierEorPartner?: InputMaybe<Scalars['Boolean']>;
  paySupplementIds?: InputMaybe<Array<Scalars['ID']>>;
  payrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** @deprecated Use statuses instead */
  status?: InputMaybe<PaySupplementStatus>;
  statuses?: InputMaybe<Array<PaySupplementStatus>>;
};

export type PaySupplementMemberPayable = MemberPayable & {
  __typename?: 'PaySupplementMemberPayable';
  approvers?: Maybe<Array<Maybe<CompanyUser>>>;
  changeReason?: Maybe<Scalars['String']>;
  /** @deprecated use payoutInitiatedAt, payoutCompletedAt, rejectedAt etc...instead */
  changes?: Maybe<Array<Maybe<MemberPayableChange>>>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationFrequency?: Maybe<RateFrequency>;
  durationValue?: Maybe<Scalars['Float']>;
  file?: Maybe<FileLink>;
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MemberPayableItem>>>;
  memberPayableInvoiceWorkflow?: Maybe<InvoiceWorkFlow>;
  memberPayableInvoices?: Maybe<Array<Maybe<MemberPayableCompanyInvoice>>>;
  payInReceivedOn?: Maybe<Scalars['DateTime']>;
  payOutInfo?: Maybe<Array<Maybe<MemberPayablePayOutInfo>>>;
  payOutType?: Maybe<TransferType>;
  paySupplement?: Maybe<PaySupplement>;
  payableFromDate?: Maybe<Scalars['Date']>;
  payableToDate?: Maybe<Scalars['Date']>;
  paymentReceipt?: Maybe<DocumentReadable>;
  payoutCompletedAt?: Maybe<Scalars['DateTime']>;
  payoutInitiatedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MemberPayableStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalInFunctionalCurrency?: Maybe<Scalars['Float']>;
  type?: Maybe<MemberPayableType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PaySupplementMemberPayable_ChangesArgs = {
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<MemberPayableStatus>;
};

export type PaySupplementMemberPayable_FileArgs = {
  documentType?: InputMaybe<MemberPayableDocumentType>;
};

export type PaySupplementMemberPayable_MemberPayableInvoicesArgs = {
  status?: InputMaybe<MemberPayableCompanyInvoiceStatus>;
};

export type PaySupplementSnapshot = {
  __typename?: 'PaySupplementSnapshot';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paySupplementId: Scalars['ID'];
  status?: Maybe<PaySupplementStatus>;
  type?: Maybe<PaySupplementType>;
};

export enum PaySupplementStatus {
  CREATED = 'CREATED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
  TO_BE_PAID = 'TO_BE_PAID',
}

export type PaySupplementSummary = {
  __typename?: 'PaySupplementSummary';
  paid?: Maybe<Array<Scalars['ID']>>;
  processing?: Maybe<Array<Scalars['ID']>>;
  revoked?: Maybe<Array<Scalars['ID']>>;
  toBePaid?: Maybe<Array<Scalars['ID']>>;
};

export enum PaySupplementType {
  ALLOWANCE = 'ALLOWANCE',
  BONUS = 'BONUS',
  COMMISSION = 'COMMISSION',
  OTHER = 'OTHER',
  VARIABLE_PERFORMANCE_BONUS = 'VARIABLE_PERFORMANCE_BONUS',
}

export type PaySupplementUpdateBulkResponse = {
  __typename?: 'PaySupplementUpdateBulkResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export type PaySupplementUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<CurrencyCode>;
  description?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaySupplementType>;
};

export type PaySupplementsResponse = {
  __typename?: 'PaySupplementsResponse';
  page?: Maybe<PageResult>;
  paySupplements?: Maybe<Array<Maybe<PaySupplement>>>;
};

export enum PayType {
  DYNAMIC = 'DYNAMIC',
  FIXED = 'FIXED',
}

export type PayableItem = GenericPayableCost | MemberPayrollCost;

export enum PayableItemType {
  ANNUAL_MEMBER_MANAGEMENT_FEE = 'ANNUAL_MEMBER_MANAGEMENT_FEE',
  MEMBER_DEPOSIT = 'MEMBER_DEPOSIT',
  MEMBER_GLOBAL_PAYROLL_FUNDING_COST = 'MEMBER_GLOBAL_PAYROLL_FUNDING_COST',
  MEMBER_GROSS_SALARY = 'MEMBER_GROSS_SALARY',
  MEMBER_GROSS_SALARY_REVERSAL = 'MEMBER_GROSS_SALARY_REVERSAL',
  MEMBER_INSURANCE_COST = 'MEMBER_INSURANCE_COST',
  MEMBER_MANAGEMENT_FEE = 'MEMBER_MANAGEMENT_FEE',
  MEMBER_MANAGEMENT_FEE_FOR_FREELANCER = 'MEMBER_MANAGEMENT_FEE_FOR_FREELANCER',
  MEMBER_MANAGEMENT_FEE_REVERSAL = 'MEMBER_MANAGEMENT_FEE_REVERSAL',
  MEMBER_PAYROLL_COST = 'MEMBER_PAYROLL_COST',
  MEMBER_PAYROLL_COST_FOR_FREELANCER = 'MEMBER_PAYROLL_COST_FOR_FREELANCER',
  /** @deprecated use MEMBER_GLOBAL_PAYROLL_FUNDING_COST to sync with business terms */
  MEMBER_PEO_PAYROLL_COST = 'MEMBER_PEO_PAYROLL_COST',
  MEMBER_PROCESSING_FEE_FOR_EOR = 'MEMBER_PROCESSING_FEE_FOR_EOR',
  MEMBER_PROCESSING_FEE_FOR_FREELANCER = 'MEMBER_PROCESSING_FEE_FOR_FREELANCER',
  MEMBER_PRORATED_PAYROLL_COST = 'MEMBER_PRORATED_PAYROLL_COST',
  MEMBER_TOTAL_EXPENSE_COST = 'MEMBER_TOTAL_EXPENSE_COST',
  MEMBER_TOTAL_PAYROLL_LESS_EXPENSE_COST = 'MEMBER_TOTAL_PAYROLL_LESS_EXPENSE_COST',
}

export type PayableMonthInput = {
  cycle?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export enum PayableQueryMode {
  DEFAULT = 'DEFAULT',
  DRYRUN_DETAILED = 'DRYRUN_DETAILED',
  DRYRUN_SUMMARIZED = 'DRYRUN_SUMMARIZED',
}

export enum PayableStatus {
  AUTHORIZED = 'AUTHORIZED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  VOIDED = 'VOIDED',
}

export type Payment = {
  __typename?: 'Payment';
  audit?: Maybe<Audit>;
  id: Scalars['ID'];
  status?: Maybe<PaymentStatus>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  transferValue?: Maybe<TransferValue>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  id: Scalars['ID'];
  paymentPartnerAccounts?: Maybe<Array<Maybe<PaymentPartnerAccount>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  sourceSystem?: Maybe<Scalars['String']>;
};

export type PaymentAccountPartnerRequirement = {
  __typename?: 'PaymentAccountPartnerRequirement';
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  requirementFields?: Maybe<Array<Maybe<PaymentRequirementField>>>;
};

export type PaymentAccountRequirement = {
  __typename?: 'PaymentAccountRequirement';
  accountType?: Maybe<PaymentAccountType>;
  paymentAccountRequirementType?: Maybe<Scalars['String']>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  requirementFields?: Maybe<Array<Maybe<RequirementField>>>;
  sourceCurrency?: Maybe<CurrencyCode>;
  targetCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export enum PaymentAccountType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

export type PaymentBundle = {
  __typename?: 'PaymentBundle';
  bankAccount?: Maybe<BankAccount>;
  company?: Maybe<Company>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  id: Scalars['ID'];
  instructionFile?: Maybe<DocumentReadable>;
  items: Array<PaymentBundleItem>;
  payInInfo?: Maybe<PayInInfo>;
  paymentAdvices?: Maybe<Array<DocumentReadable>>;
  paymentMethodInfo?: Maybe<PaymentMethodInfo>;
  referenceId?: Maybe<Scalars['String']>;
  sourceType?: Maybe<PaymentBundleSourceType>;
  status?: Maybe<PaymentBundleStatus>;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PaymentBundleCreateInput = {
  bundleItems?: InputMaybe<Array<PaymentBundleItemInput>>;
  companyId?: InputMaybe<Scalars['ID']>;
  payInMethodId?: InputMaybe<Scalars['ID']>;
  paymentMethod: PaymentMethod;
  source?: InputMaybe<PaymentBundleSourceType>;
};

export type PaymentBundleFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  createdDateRange?: InputMaybe<DateRange>;
  /** Whether bundles of test companies should be returned. By default only non-test are respected */
  includesTestCompanies?: InputMaybe<Scalars['Boolean']>;
  paymentBundleId?: InputMaybe<Scalars['ID']>;
  referenceId?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<PaymentBundleSourceType>;
};

export type PaymentBundleItem = {
  __typename?: 'PaymentBundleItem';
  companyPayable?: Maybe<CompanyPayable>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<PaymentBundleItemType>;
};

export type PaymentBundleItemInput = {
  id: Scalars['ID'];
  type: PaymentBundleItemType;
};

export enum PaymentBundleItemType {
  COMPANY_PAYABLE = 'COMPANY_PAYABLE',
  FREELANCER_PAYABLE = 'FREELANCER_PAYABLE',
}

export type PaymentBundlePreview = {
  __typename?: 'PaymentBundlePreview';
  currency?: Maybe<CurrencyCode>;
  items: Array<PaymentBundlePreviewItem>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type PaymentBundlePreviewItem = {
  __typename?: 'PaymentBundlePreviewItem';
  billingAmount?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['ID']>;
  item?: Maybe<PaymentBundlePreviewItemPayable>;
  managementFee?: Maybe<Scalars['Float']>;
  payoutProcessingFee?: Maybe<Scalars['Float']>;
  type?: Maybe<PaymentBundleItemType>;
};

export type PaymentBundlePreviewItemInput = {
  id: Scalars['ID'];
  type: PaymentBundleItemType;
};

export type PaymentBundlePreviewItemPayable =
  | CompanyPayable
  | ExpenseMemberPayable
  | FreelancerPayable
  | PaySupplementMemberPayable;

export enum PaymentBundleSourceType {
  AUTO_DEBIT_SERVICE = 'AUTO_DEBIT_SERVICE',
  COMPANY_EXPERIENCE = 'COMPANY_EXPERIENCE',
}

export enum PaymentBundleStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
}

export type PaymentBundleUpdateInput = {
  id: Scalars['ID'];
  payInMethodId?: InputMaybe<Scalars['ID']>;
  paymentMethod: PaymentMethod;
};

export type PaymentExecutionInfo = {
  __typename?: 'PaymentExecutionInfo';
  executionType?: Maybe<Scalars['String']>;
};

export enum PaymentInstructionErrorLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum PaymentInstructionErrorType {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export type PaymentInstructionFileGenerateData = {
  contractIDs: Array<InputMaybe<Scalars['ID']>>;
  overrideInfo?: InputMaybe<OverrideInfo>;
  paymentExecutionDate?: InputMaybe<Scalars['Date']>;
  paymentFileSubType?: InputMaybe<Scalars['String']>;
  payrollDate?: InputMaybe<Scalars['Date']>;
};

export type PaymentInstructionFileGenerateInput = {
  bank: BankCode;
  contractIDs: Array<InputMaybe<Scalars['ID']>>;
  countryCode: CountryCode;
  overrideInfo?: InputMaybe<OverrideInfo>;
  paymentExecutionDate?: InputMaybe<Scalars['Date']>;
  payrollDate?: InputMaybe<Scalars['Date']>;
};

export type PaymentInstructionFileGenerationReport = {
  __typename?: 'PaymentInstructionFileGenerationReport';
  errors?: Maybe<Array<Maybe<FileGenerationErrors>>>;
  file?: Maybe<Document>;
  pifId: Scalars['ID'];
  status: PaymentInstructionStatus;
};

export enum PaymentInstructionFileType {
  ISO20022XML = 'ISO20022XML',
  ISO20022XML_DIRECT_DEBIT = 'ISO20022XML_DIRECT_DEBIT',
  NACHA_DEBIT = 'NACHA_DEBIT',
  NACHA_PRENOTE = 'NACHA_PRENOTE',
}

export enum PaymentInstructionStatus {
  FILE_GENERATED = 'FILE_GENERATED',
  FILE_GENERATION_FAILED = 'FILE_GENERATION_FAILED',
  FILE_GENERATION_IN_PROGRESS = 'FILE_GENERATION_IN_PROGRESS',
}

export enum PaymentMethod {
  ACH_DIRECT_DEBIT = 'ACH_DIRECT_DEBIT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CARD = 'CARD',
  CHEQUE = 'CHEQUE',
  CRYPTO = 'CRYPTO',
  SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
}

export type PaymentMethodInfo = {
  __typename?: 'PaymentMethodInfo';
  autoDebitInfo?: Maybe<AutoDebitInfo>;
  bundleId: Scalars['ID'];
  /** @deprecated No longer supported */
  fee?: Maybe<Scalars['Float']>;
  payInMethodId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<PaymentMethod>;
  processingFee?: Maybe<ProcessingFee>;
  processingFeePercentage?: Maybe<Scalars['Float']>;
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  accountType?: Maybe<PaymentAccountType>;
  partnerAccountRequirements?: Maybe<PartnerAccountRequirement>;
  payOutCountry?: Maybe<CountryCode>;
  payOutCurrency?: Maybe<CurrencyCode>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
  sourceCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
};

export type PaymentOptionFilters = {
  contractType?: InputMaybe<ContractType>;
  paymentPartners?: InputMaybe<Array<InputMaybe<PaymentPartnerCode>>>;
  taxResidency: CountryCode;
};

export type PaymentOptionInfo = {
  __typename?: 'PaymentOptionInfo';
  partnerSupport?: Maybe<PartnerSupportInfo>;
  paymentCountry?: Maybe<CountryCode>;
  paymentCurrency?: Maybe<CurrencyCode>;
};

export type PaymentOptions = {
  __typename?: 'PaymentOptions';
  contractType?: Maybe<ContractType>;
  options?: Maybe<Array<Maybe<PaymentOptionInfo>>>;
  taxResidency?: Maybe<CountryCode>;
};

export type PaymentPartner = {
  __typename?: 'PaymentPartner';
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paymentPartnerType?: Maybe<PaymentPartnerType>;
};

export type PaymentPartnerAccount = {
  __typename?: 'PaymentPartnerAccount';
  accountType?: Maybe<PaymentAccountType>;
  beneficaryID?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentAccount?: Maybe<PaymentAccount>;
  paymentPartner?: Maybe<PaymentPartner>;
  transferType?: Maybe<TransferType>;
};

export enum PaymentPartnerCode {
  HSBC = 'HSBC',
  WISE = 'WISE',
}

export enum PaymentPartnerType {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
}

export enum PaymentPriorityType {
  NON_URGENT_PAYMENT = 'NON_URGENT_PAYMENT',
  URGENT_PAYMENT = 'URGENT_PAYMENT',
}

export type PaymentProcessingMethod = {
  __typename?: 'PaymentProcessingMethod';
  automated?: Maybe<Scalars['Boolean']>;
  executionInfo?: Maybe<PaymentExecutionInfo>;
  paymentSLA?: Maybe<PaymentSla>;
};

export type PaymentRequirementAllowedValue = {
  __typename?: 'PaymentRequirementAllowedValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PaymentRequirementField = {
  __typename?: 'PaymentRequirementField';
  allowedValues?: Maybe<Array<Maybe<PaymentRequirementAllowedValue>>>;
  errorMessage?: Maybe<Scalars['String']>;
  hasSubFields?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  regex?: Maybe<Scalars['String']>;
  subFields?: Maybe<Array<Maybe<PaymentRequirementSubField>>>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentRequirementSubField = {
  __typename?: 'PaymentRequirementSubField';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<PaymentRequirementField>>>;
};

export type PaymentSla = {
  __typename?: 'PaymentSLA';
  cost?: Maybe<Amount>;
  estimatedDeliveryTime?: Maybe<Duration>;
};

export enum PaymentStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  NOT_WHITELISTED = 'NOT_WHITELISTED',
  PROCESSING = 'PROCESSING',
}

export type PaymentTerm = {
  __typename?: 'PaymentTerm';
  interval: Scalars['Int'];
  planId: Scalars['ID'];
  timeUnit: PaymentTermTimeUnit;
};

export type PaymentTermInput = {
  interval: Scalars['Int'];
  timeUnit: PaymentTermTimeUnit;
};

export enum PaymentTermTimeUnit {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type Payroll = {
  amountTotalCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  memberPays?: Maybe<Array<Maybe<MemberPay>>>;
  month?: Maybe<PayrollMonth>;
};

export type PayrollBulkUpload = {
  __typename?: 'PayrollBulkUpload';
  bulkUploadSummary?: Maybe<PayrollBulkUploadSummary>;
  errors?: Maybe<Array<PayrollBulkUploadError>>;
  inputFile: PayrollFile;
  paySupplements?: Maybe<Array<PaySupplement>>;
};

export enum PayrollBulkUploadConfirmStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type PayrollBulkUploadError = {
  __typename?: 'PayrollBulkUploadError';
  contractId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
};

export type PayrollBulkUploadItemCount = {
  __typename?: 'PayrollBulkUploadItemCount';
  employee: Scalars['Int'];
  error: Scalars['Int'];
  itemType: BulkUploadItemType;
  success: Scalars['Int'];
  warn: Scalars['Int'];
};

export type PayrollBulkUploadSummary = {
  __typename?: 'PayrollBulkUploadSummary';
  counts?: Maybe<Array<PayrollBulkUploadItemCount>>;
  /** @deprecated already exists inside counts field */
  totalExpenses?: Maybe<Scalars['Int']>;
  /** @deprecated already exists inside counts field */
  totalPaySupplements?: Maybe<Scalars['Int']>;
  /** @deprecated already exists inside counts field */
  totalTimeOff?: Maybe<Scalars['Int']>;
};

export type PayrollCalendar = {
  __typename?: 'PayrollCalendar';
  approvePayrollInputDeadlineRelativeToCutOff?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  approvePayrollReportDeadlineRelativeToEndDate?: Maybe<Scalars['Int']>;
  approvePayrollReportDeadlineRelativeToPayDate?: Maybe<Scalars['Int']>;
  compensationDateRelativeToEndDate: Scalars['Int'];
  contractEndDateRelativeToEndDate: Scalars['Int'];
  contractStartDateRelativeToEndDate: Scalars['Int'];
  cutOffRelativeToPayDate: Scalars['Int'];
  deductionDateRelativeToEndDate: Scalars['Int'];
  expenseDateRelativeToEndDate: Scalars['Int'];
  memberChangesDateRelativeToEndDate: Scalars['Int'];
  payDateRelativeToEndDate: Scalars['Int'];
  paySupplementDateRelativeToEndDate: Scalars['Int'];
  payrollCompleteDeadlineRelativeToEndDate?: Maybe<Scalars['Int']>;
  payrollProcessingDeadlineRelativeToEndDate?: Maybe<Scalars['Int']>;
  snapCutOffDates: Scalars['Boolean'];
  snapDeadlineDates?: Maybe<Scalars['Boolean']>;
  snapPayDate: Scalars['Boolean'];
  snapToDates: Scalars['Boolean'];
  startDate: Scalars['Date'];
  submitPayrollInputDeadlineRelativeToCutOff?: Maybe<Scalars['Int']>;
  timeOffDateRelativeToEndDate: Scalars['Int'];
  timeSheetDateRelativeToEndDate: Scalars['Int'];
};

export type PayrollCalendarFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyPayrollCycleId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  payFrequency: PayFrequency;
  payrollCycleEntityTypes?: InputMaybe<Array<PayrollCycleEntityType>>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  payrollPartnerId?: InputMaybe<Scalars['ID']>;
};

export type PayrollCalendarInput = {
  approvePayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  approvePayrollReportDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  approvePayrollReportDeadlineRelativeToPayDate?: InputMaybe<Scalars['Int']>;
  compensationDateRelativeToEndDate: Scalars['Int'];
  contractEndDateRelativeToEndDate: Scalars['Int'];
  contractStartDateRelativeToEndDate: Scalars['Int'];
  cutOffRelativeToPayDate: Scalars['Int'];
  deductionDateRelativeToEndDate: Scalars['Int'];
  expenseDateRelativeToEndDate: Scalars['Int'];
  memberChangesDateRelativeToEndDate: Scalars['Int'];
  payDateRelativeToEndDate: Scalars['Int'];
  paySupplementDateRelativeToEndDate: Scalars['Int'];
  payrollCompleteDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  payrollProcessingDeadlineRelativeToEndDate?: InputMaybe<Scalars['Int']>;
  snapCutOffDates?: InputMaybe<Scalars['Boolean']>;
  snapDeadlineDates?: InputMaybe<Scalars['Boolean']>;
  snapPayDate?: InputMaybe<Scalars['Boolean']>;
  snapToDates?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
  submitPayrollInputDeadlineRelativeToCutOff?: InputMaybe<Scalars['Int']>;
  timeOffDateRelativeToEndDate: Scalars['Int'];
  timeSheetDateRelativeToEndDate: Scalars['Int'];
};

export type PayrollConfigEntityInput = {
  country: CountryCode;
  payrollCycleEntityId?: InputMaybe<Scalars['ID']>;
  payrollPartnerCountryId: Scalars['ID'];
  title: Scalars['String'];
};

export type PayrollConsolidatedReportDownloadResponse = FileDownload & {
  __typename?: 'PayrollConsolidatedReportDownloadResponse';
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated use `payrollFile` instead */
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PayrollContract = {
  __typename?: 'PayrollContract';
  employeeId?: Maybe<Scalars['String']>;
  input?: Maybe<PayrollInputContract>;
  name: Scalars['String'];
  position: Scalars['String'];
  status: ContractStatus;
};

export type PayrollContractException = {
  __typename?: 'PayrollContractException';
  contractId?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['DateTime']>;
  exceptionType?: Maybe<PayrollContractExceptionType>;
  id?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  reasonCategory?: Maybe<PayrollContractExceptionReasonCategory>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollContractExceptionInput = {
  contractId: Scalars['ID'];
  exceptionType?: InputMaybe<PayrollContractExceptionType>;
  payrollCycleId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  reasonCategory?: InputMaybe<PayrollContractExceptionReasonCategory>;
};

export enum PayrollContractExceptionReasonCategory {
  DID_NOT_JOIN = 'DID_NOT_JOIN',
  ELIGIBLE_BEFORE_ACTIVE = 'ELIGIBLE_BEFORE_ACTIVE',
  END_DATE_CHANGED = 'END_DATE_CHANGED',
  MISSED_CUTOFF = 'MISSED_CUTOFF',
  OFFBOARDING_CANCELLED = 'OFFBOARDING_CANCELLED',
  OTHER = 'OTHER',
  PRE_REGISTRATION = 'PRE_REGISTRATION',
  START_DATE_CHANGED = 'START_DATE_CHANGED',
}

export type PayrollContractExceptionReasonCategoryRequest = {
  contractSnapshotId: Scalars['ID'];
  type: PayrollContractType;
};

export enum PayrollContractExceptionType {
  ACTIVE_EXCLUDE = 'ACTIVE_EXCLUDE',
  ACTIVE_INCLUDE = 'ACTIVE_INCLUDE',
  NEW_HIRE_EXCLUDE = 'NEW_HIRE_EXCLUDE',
  NEW_HIRE_INCLUDE = 'NEW_HIRE_INCLUDE',
  TERMINATION_EXCLUDE = 'TERMINATION_EXCLUDE',
  TERMINATION_INCLUDE = 'TERMINATION_INCLUDE',
}

export type PayrollContractFilter = {
  contractId: Scalars['ID'];
  payrollCycleId: Scalars['ID'];
};

export enum PayrollContractType {
  ACTIVE = 'ACTIVE',
  NEW_HIRE = 'NEW_HIRE',
  TERMINATION = 'TERMINATION',
}

export type PayrollCountryEntityType = {
  __typename?: 'PayrollCountryEntityType';
  countryCode: CountryCode;
  entityType: PayrollCycleEntityType;
};

export type PayrollCycle = {
  __typename?: 'PayrollCycle';
  approvePayrollInputDeadline?: Maybe<Scalars['Date']>;
  approvePayrollReportDeadline?: Maybe<Scalars['Date']>;
  bulkUpload?: Maybe<PayrollBulkUpload>;
  compensationDateFrom?: Maybe<Scalars['Date']>;
  compensationDateTo?: Maybe<Scalars['Date']>;
  configId?: Maybe<Scalars['ID']>;
  contractEndDateFrom?: Maybe<Scalars['Date']>;
  contractEndDateTo?: Maybe<Scalars['Date']>;
  contractStartDateFrom?: Maybe<Scalars['Date']>;
  contractStartDateTo?: Maybe<Scalars['Date']>;
  contracts?: Maybe<Array<PayrollContract>>;
  country?: Maybe<CountryCode>;
  createdBy?: Maybe<Person>;
  createdOn?: Maybe<Scalars['DateTime']>;
  cutOffFrom?: Maybe<Scalars['Date']>;
  cutOffTo?: Maybe<Scalars['Date']>;
  cycleType?: Maybe<PayrollCycleType>;
  deductionFrom?: Maybe<Scalars['Date']>;
  deductionTo?: Maybe<Scalars['Date']>;
  defaultApprovePayrollReportDeadline?: Maybe<Scalars['Date']>;
  defaultCutOffTo?: Maybe<Scalars['Date']>;
  defaultEndDate?: Maybe<Scalars['Date']>;
  defaultPayDate?: Maybe<Scalars['Date']>;
  defaultStartDate?: Maybe<Scalars['Date']>;
  employeeDataChanges?: Maybe<EmployeeDataChanges>;
  employeeDataChangesV2?: Maybe<EmployeeDataChangesV2>;
  endDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<PayrollCycleEntity>;
  expenseFrom?: Maybe<Scalars['Date']>;
  expenseSummary?: Maybe<ExpenseSummary>;
  expenseTo?: Maybe<Scalars['Date']>;
  frequency?: Maybe<PayFrequency>;
  id: Scalars['ID'];
  inputHeadCountSummaryV2?: Maybe<InputHeadcountSummaryV2>;
  inputHeadcountSummary?: Maybe<InputHeadcountSummary>;
  memberChangesFrom?: Maybe<Scalars['Date']>;
  memberChangesTo?: Maybe<Scalars['Date']>;
  offCycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  /** @deprecated No longer supported */
  oneTimePaymentDateFrom?: Maybe<Scalars['Date']>;
  /** @deprecated No longer supported */
  oneTimePaymentDateTo?: Maybe<Scalars['Date']>;
  opsPayslipUploadWorkflow?: Maybe<OpsPayslipUploadWorkflow>;
  outputHeadcountSummary?: Maybe<OutputHeadcountSummary>;
  outputVariance?: Maybe<PayrollOutputDataVariance>;
  payDate?: Maybe<Scalars['Date']>;
  paySupplementFrom?: Maybe<Scalars['Date']>;
  paySupplementSummary?: Maybe<PaySupplementSummary>;
  paySupplementTo?: Maybe<Scalars['Date']>;
  payrollCompleteDeadline?: Maybe<Scalars['Date']>;
  payrollInput?: Maybe<FileDownload>;
  payrollMonth?: Maybe<MonthYear>;
  payrollOutputFile?: Maybe<PayrollReportUpload>;
  payrollOutputSummary?: Maybe<PayrollOutputSummary>;
  payrollProcessingDeadline?: Maybe<Scalars['Date']>;
  payslipSummary?: Maybe<PayslipSummary>;
  payslipUploadDetails?: Maybe<PayslipUploadDetails>;
  payslips?: Maybe<Array<Payslip>>;
  previousCycle?: Maybe<PayrollCycle>;
  primaryCycle?: Maybe<PayrollCycle>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<PayrollCycleStatus>;
  submitPayrollInputDeadline?: Maybe<Scalars['Date']>;
  timeOffFrom?: Maybe<Scalars['Date']>;
  timeOffTo?: Maybe<Scalars['Date']>;
  timeSheetDateFrom?: Maybe<Scalars['Date']>;
  timeSheetDateTo?: Maybe<Scalars['Date']>;
  workflow?: Maybe<PayrollCycleWorkflow>;
};

export type PayrollCycleBulkCreateInput = {
  configIds: Array<Scalars['ID']>;
  untilDate: Scalars['Date'];
};

export type PayrollCycleConfig = {
  __typename?: 'PayrollCycleConfig';
  calendar?: Maybe<PayrollCalendar>;
  country?: Maybe<CountryCode>;
  frequency?: Maybe<PayFrequency>;
  id?: Maybe<Scalars['ID']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  payrollCycleByReferenceDate?: Maybe<PayrollCycle>;
  payrollCycleEntity?: Maybe<PayrollCycleEntity>;
  payrollCycles: Array<PayrollCycle>;
  payrollPartnerCountry?: Maybe<PayrollPartnerCountry>;
  title: Scalars['String'];
  type?: Maybe<PayrollCycleEntityType>;
};

export type PayrollCycleConfig_PayrollCycleByReferenceDateArgs = {
  domain: PayrollInputDomainType;
  referenceDate: Scalars['Date'];
  returnClosestToReferenceDateUpcomingCutOff: Scalars['Boolean'];
};

export type PayrollCycleConfig_PayrollCyclesArgs = {
  pageNumber: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type PayrollCycleConfigInput = {
  calendar: PayrollCalendarInput;
  country: CountryCode;
  frequency: PayFrequency;
  isDefault: Scalars['Boolean'];
  payrollCycleEntityId?: InputMaybe<Scalars['ID']>;
  payrollPartnerCountryId: Scalars['ID'];
  title: Scalars['String'];
  type: PayrollCycleEntityType;
};

export enum PayrollCycleConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type PayrollCycleConfigUpdateInput = {
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PayrollCycleConfigStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type PayrollCycleConfigsFilter = {
  countries?: InputMaybe<Array<CountryCode>>;
  payFrequency?: InputMaybe<PayFrequency>;
  payrollCycleEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<PayrollCycleEntityType>>;
};

export type PayrollCycleConfigsResponse = {
  __typename?: 'PayrollCycleConfigsResponse';
  configs?: Maybe<Array<PayrollCycleConfig>>;
};

export type PayrollCycleEntity = {
  __typename?: 'PayrollCycleEntity';
  company?: Maybe<Company>;
  entityType?: Maybe<PayrollCycleEntityType>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum PayrollCycleEntityType {
  EOR = 'EOR',
  EOR_PARTNER = 'EOR_PARTNER',
  GLOBAL_PAYROLL = 'GLOBAL_PAYROLL',
  PEO = 'PEO',
}

export type PayrollCycleFilter = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countries?: InputMaybe<Array<CountryCode>>;
  createdBy?: InputMaybe<Scalars['ID']>;
  dateRange?: InputMaybe<DateRange>;
  entityId?: InputMaybe<Scalars['ID']>;
  eorPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedStatuses?: InputMaybe<Array<PayrollCycleStatus>>;
  frequencies?: InputMaybe<Array<PayFrequency>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** @deprecated No longer supported */
  partnerName?: InputMaybe<Scalars['String']>;
  payDate?: InputMaybe<DateRange>;
  /** @deprecated Use payrollCycleEntityTypes instead */
  payrollCycleEntityType?: InputMaybe<PayrollCycleEntityType>;
  payrollCycleEntityTypes?: InputMaybe<Array<PayrollCycleEntityType>>;
  payrollCycleTypes?: InputMaybe<Array<PayrollCycleType>>;
  payrollMonth?: InputMaybe<MonthYearInput>;
  primaryCycleIds?: InputMaybe<Array<Scalars['ID']>>;
  statuses?: InputMaybe<Array<PayrollCycleStatus>>;
};

export type PayrollCycleInput = {
  configId: Scalars['ID'];
  entityId?: InputMaybe<Scalars['ID']>;
  payrollMonth: MonthYearInput;
};

export type PayrollCyclePayment = {
  __typename?: 'PayrollCyclePayment';
  id: Scalars['ID'];
  payrollCycle?: Maybe<PayrollCycle>;
  payrollPaymentsPaginated?: Maybe<PayrollPaymentResult>;
  status?: Maybe<PayrollCyclePaymentStatus>;
  type?: Maybe<PayrollCyclePaymentType>;
};

export type PayrollCyclePayment_PayrollPaymentsPaginatedArgs = {
  filters?: InputMaybe<PayrollPaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type PayrollCyclePaymentFilters = {
  country?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payrollCycleId?: InputMaybe<Scalars['String']>;
  payrollMonth?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Array<InputMaybe<PayrollCyclePaymentStatus>>>;
  types?: InputMaybe<Array<InputMaybe<PayrollCyclePaymentType>>>;
};

export type PayrollCyclePaymentResult = {
  __typename?: 'PayrollCyclePaymentResult';
  data?: Maybe<Array<Maybe<PayrollCyclePayment>>>;
  pageResult?: Maybe<PageResult>;
};

export enum PayrollCyclePaymentStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  VOID = 'VOID',
}

export enum PayrollCyclePaymentType {
  EOR = 'EOR',
  PEO = 'PEO',
}

export enum PayrollCycleStatus {
  CLOSED = 'CLOSED',
  CLOSE_PAYROLL = 'CLOSE_PAYROLL',
  CREATED = 'CREATED',
  CUSTOMER_REVIEW = 'CUSTOMER_REVIEW',
  INPUT_IN_CONFIRMATION = 'INPUT_IN_CONFIRMATION',
  INPUT_IN_PROGRESS = 'INPUT_IN_PROGRESS',
  OPS_RECONCILIATION = 'OPS_RECONCILIATION',
  OUTPUT_APPROVED = 'OUTPUT_APPROVED',
  PARTNER_RECONCILIATION = 'PARTNER_RECONCILIATION',
  PAYSLIPS_CONFIRMED = 'PAYSLIPS_CONFIRMED',
  PAYSLIPS_UPLOAD = 'PAYSLIPS_UPLOAD',
  SENT_FOR_REVIEW = 'SENT_FOR_REVIEW',
  SENT_TO_PARTNER = 'SENT_TO_PARTNER',
  SUSPENDED = 'SUSPENDED',
}

export enum PayrollCycleStep {
  CLOSURE = 'CLOSURE',
  INITIATION = 'INITIATION',
  INPUT_GENERATION = 'INPUT_GENERATION',
  PAYROLL_PROCESSING = 'PAYROLL_PROCESSING',
  /** @deprecated It's merged with CLOSURE */
  PAY_SLIPS = 'PAY_SLIPS',
}

export type PayrollCycleToDates = {
  approvePayrollInputDeadline?: InputMaybe<Scalars['Date']>;
  approvePayrollReportDeadline?: InputMaybe<Scalars['Date']>;
  compensationDateTo?: InputMaybe<Scalars['Date']>;
  contractEndDateTo?: InputMaybe<Scalars['Date']>;
  contractStartDateTo?: InputMaybe<Scalars['Date']>;
  cutOffTo?: InputMaybe<Scalars['Date']>;
  deductionTo?: InputMaybe<Scalars['Date']>;
  expenseTo?: InputMaybe<Scalars['Date']>;
  memberChangesTo?: InputMaybe<Scalars['Date']>;
  payDate?: InputMaybe<Scalars['Date']>;
  paySupplementTo?: InputMaybe<Scalars['Date']>;
  payrollCompleteDeadline?: InputMaybe<Scalars['Date']>;
  payrollProcessingDeadline?: InputMaybe<Scalars['Date']>;
  submitPayrollInputDeadline?: InputMaybe<Scalars['Date']>;
  timeOffTo?: InputMaybe<Scalars['Date']>;
  timeSheetDateTo?: InputMaybe<Scalars['Date']>;
};

export type PayrollCycleToDatesInput = {
  cycleId: Scalars['ID'];
  payrollCycleToDates: PayrollCycleToDates;
};

export enum PayrollCycleType {
  OFF_CYCLE = 'OFF_CYCLE',
  PRIMARY_CYCLE = 'PRIMARY_CYCLE',
}

export type PayrollCycleUpdateInput = {
  payrollCycleToDatesInput: Array<PayrollCycleToDatesInput>;
};

export type PayrollCycleWorkflow = {
  __typename?: 'PayrollCycleWorkflow';
  allSteps?: Maybe<Array<Maybe<PayrollCycleStep>>>;
  currentStep?: Maybe<PayrollCycleStep>;
};

export type PayrollCyclesResponse = {
  __typename?: 'PayrollCyclesResponse';
  cycles?: Maybe<Array<Maybe<PayrollCycle>>>;
  page?: Maybe<PageResult>;
};

export type PayrollDocument = {
  __typename?: 'PayrollDocument';
  downloadUrl: Scalars['String'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type PayrollDocumentsResponse = {
  __typename?: 'PayrollDocumentsResponse';
  documents?: Maybe<Array<PayrollDocument>>;
};

export type PayrollFile = {
  __typename?: 'PayrollFile';
  contentType?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  downloadUrl?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type PayrollFileDownloadResponse = FileDownload & {
  __typename?: 'PayrollFileDownloadResponse';
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated use `payrollFile` instead */
  document?: Maybe<Document>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  payrollFile?: Maybe<PayrollFile>;
  status?: Maybe<PayrollFileDownloadStatus>;
  type?: Maybe<PayrollFileDownloadType>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum PayrollFileDownloadStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  RECEIVED = 'RECEIVED',
  TIMED_OUT = 'TIMED_OUT',
}

export enum PayrollFileDownloadType {
  BULK_UPLOAD_ERROR_REPORT = 'BULK_UPLOAD_ERROR_REPORT',
  COMPANY_PAYROLL_INPUT = 'COMPANY_PAYROLL_INPUT',
  COMPANY_PAYROLL_INPUT_VARIANCE = 'COMPANY_PAYROLL_INPUT_VARIANCE',
  INPUT_FILE = 'INPUT_FILE',
  YEAR_TO_DATE_REPORT = 'YEAR_TO_DATE_REPORT',
}

export type PayrollFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  /** Wildcard search */
  companyName?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  frequency?: InputMaybe<PayFrequency>;
  /** Wildcard search */
  memberName?: InputMaybe<Scalars['String']>;
  payDateRange?: InputMaybe<DateRange>;
  payPeriodDateRange?: InputMaybe<DateRange>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
  /** this filter allow client to filter by month and year */
  payrollMonth?: InputMaybe<PayrollMonthInput>;
  payrollMonths?: InputMaybe<PayrollMonthRangeInput>;
};

export enum PayrollFormsUploadStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
}

export enum PayrollInputAuditTrailEntityType {
  EXPENSE = 'EXPENSE',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
}

export type PayrollInputAuditTrailsRemoveInput = {
  entityIds: Array<Scalars['ID']>;
  entityType: PayrollInputAuditTrailEntityType;
};

export type PayrollInputContract = {
  __typename?: 'PayrollInputContract';
  additionalAllowances?: Maybe<Array<PayDetail>>;
  baseSalaryBreakdowns?: Maybe<Array<PayDetail>>;
  currency: CurrencyCode;
  deductions?: Maybe<Array<PayDetail>>;
  employeeId?: Maybe<Scalars['String']>;
  expenses?: Maybe<Array<PayDetail>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  paySupplements?: Maybe<Array<PayDetail>>;
  status: ContractStatus;
  totalAdditionalAllowances?: Maybe<PaySummary>;
  totalBaseSalary?: Maybe<Scalars['Float']>;
  totalDeductions?: Maybe<Scalars['Float']>;
  totalExpenses?: Maybe<Scalars['Float']>;
  totalPaySupplements?: Maybe<Scalars['Float']>;
  totalTimeOffs?: Maybe<Scalars['Float']>;
};

export enum PayrollInputDomainType {
  COMPANY_UPLOADED_DEDUCTION = 'COMPANY_UPLOADED_DEDUCTION',
  COMPENSATION = 'COMPENSATION',
  CONTRACT = 'CONTRACT',
  CONTRACT_ONBOARDING = 'CONTRACT_ONBOARDING',
  DATA_CHANGE = 'DATA_CHANGE',
  DEDUCTION = 'DEDUCTION',
  EXPENSE = 'EXPENSE',
  MEMBER = 'MEMBER',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY_REVISION = 'SALARY_REVISION',
  TIMESHEET = 'TIMESHEET',
  TIME_OFF = 'TIME_OFF',
}

/** In DB, `notes` are different for every combination of (countryCode, domain, experience)  */
export type PayrollInputNote = {
  __typename?: 'PayrollInputNote';
  countryCode: CountryCode;
  domain: PayrollInputNoteDomain;
  experience: Persona;
  id?: Maybe<Scalars['ID']>;
  notes: Array<Scalars['String']>;
};

/** For now only applies to expense, but later can be in freelancer invoice, time off etc... */
export enum PayrollInputNoteDomain {
  EXPENSE = 'EXPENSE',
}

export type PayrollMonth = {
  __typename?: 'PayrollMonth';
  cycle?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type PayrollMonthInput = {
  cycle?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type PayrollMonthRangeInput = {
  from: PayrollMonthInput;
  to: PayrollMonthInput;
};

export type PayrollOffCycleCreateInput = {
  cutOffToDate: Scalars['Date'];
  payDate: Scalars['Date'];
  primaryPayrollCycleId: Scalars['ID'];
};

export type PayrollOutputDataVariance = {
  __typename?: 'PayrollOutputDataVariance';
  employeeDeductionsVariance?: Maybe<Scalars['Float']>;
  employerContributionsVariance?: Maybe<Scalars['Float']>;
  expensesVariance?: Maybe<Scalars['Float']>;
  grossSalaryVariance?: Maybe<Scalars['Float']>;
  netPayVariance?: Maybe<Scalars['Float']>;
  totalPayrollCostVariance?: Maybe<Scalars['Float']>;
};

export type PayrollOutputSummary = {
  __typename?: 'PayrollOutputSummary';
  employeeDeductions: Scalars['Float'];
  employerContributions: Scalars['Float'];
  expenses: Scalars['Float'];
  grossSalary: Scalars['Float'];
  netPay: Scalars['Float'];
  totalPayrollCost: Scalars['Float'];
};

export type PayrollParserConfig = {
  __typename?: 'PayrollParserConfig';
  configString?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PayrollParserConfigFilter = {
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollParserConfigInput = {
  configString?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollPartnerCountry = PartnerCountry & {
  __typename?: 'PayrollPartnerCountry';
  capability?: Maybe<PartnerCapability>;
  contracts?: Maybe<Array<Maybe<ContractPayrollPartner>>>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['ID']>;
  operator?: Maybe<Array<Maybe<PartnerUser>>>;
  partner?: Maybe<Partner>;
  payroll?: Maybe<Array<Maybe<PartnerPayroll>>>;
};

export type PayrollPartnerCountry_ContractsArgs = {
  status?: InputMaybe<ContractPayrollPartnerStatus>;
};

export type PayrollPartnerCountry_PayrollArgs = {
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type PayrollPartnerCountryInput = {
  capability: PartnerCapability;
  countryCode: CountryCode;
  currency?: InputMaybe<CurrencyCode>;
};

export type PayrollPayment = {
  __typename?: 'PayrollPayment';
  contract?: Maybe<Contract>;
  firstInvoiceStatus?: Maybe<InvoiceStatus>;
  id: Scalars['ID'];
  paidOn?: Maybe<Scalars['DateTime']>;
  payrollCyclePayment?: Maybe<PayrollCyclePayment>;
  payrollPaymentInfo?: Maybe<PayrollPaymentInfo>;
  payrollPaymentStatus?: Maybe<PayrollPaymentStatus>;
  payrollPaymentType?: Maybe<PayrollPaymentType>;
};

export type PayrollPaymentBatch = {
  createdOn?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<Array<Maybe<PayrollPaymentBatchError>>>;
  id: Scalars['ID'];
  payrollPaymentBatchName?: Maybe<Scalars['String']>;
  payrollPaymentBatchPaymentPriorityType?: Maybe<PaymentPriorityType>;
  payrollPaymentBatchPayoutDate?: Maybe<Scalars['DateTime']>;
  payrollPaymentBatchStatus?: Maybe<PayrollPaymentBatchStatus>;
  payrollPaymentBatchType?: Maybe<PayrollPaymentBatchType>;
  payrollPaymentsPaginated?: Maybe<PayrollPaymentResult>;
};

export type PayrollPaymentBatch_PayrollPaymentsPaginatedArgs = {
  filters?: InputMaybe<PayrollPaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type PayrollPaymentBatchError = {
  __typename?: 'PayrollPaymentBatchError';
  errorType?: Maybe<PayrollPaymentBatchErrorType>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  payrollPayment?: Maybe<PayrollPayment>;
};

export enum PayrollPaymentBatchErrorType {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export type PayrollPaymentBatchFilters = {
  batchName?: InputMaybe<Scalars['String']>;
  batchStatuses?: InputMaybe<Array<InputMaybe<PayrollPaymentBatchStatus>>>;
  batchStepStatusFilters?: InputMaybe<
    Array<InputMaybe<PayrollPaymentBatchStepStatusFilter>>
  >;
  payrollCyclePaymentId?: InputMaybe<Scalars['ID']>;
};

export type PayrollPaymentBatchInput = {
  payoutDate: Scalars['Date'];
  payrollPayments: Array<InputMaybe<Scalars['ID']>>;
  priority?: InputMaybe<PaymentPriorityType>;
};

export type PayrollPaymentBatchResult = {
  __typename?: 'PayrollPaymentBatchResult';
  data?: Maybe<Array<Maybe<PayrollPaymentBatch>>>;
  pageResult?: Maybe<PageResult>;
};

export enum PayrollPaymentBatchStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
}

export enum PayrollPaymentBatchStep {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  EXECUTE = 'EXECUTE',
}

export type PayrollPaymentBatchStepStatusFilter = {
  batchStatuses: Array<InputMaybe<PayrollPaymentBatchStatus>>;
  batchStep: PayrollPaymentBatchStep;
};

export enum PayrollPaymentBatchType {
  FILE = 'FILE',
}

export type PayrollPaymentFileBatch = PayrollPaymentBatch & {
  __typename?: 'PayrollPaymentFileBatch';
  batchFile?: Maybe<DocumentReadable>;
  createdOn?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<Array<Maybe<PayrollPaymentBatchError>>>;
  id: Scalars['ID'];
  payrollPaymentBatchName?: Maybe<Scalars['String']>;
  payrollPaymentBatchPaymentPriorityType?: Maybe<PaymentPriorityType>;
  payrollPaymentBatchPayoutDate?: Maybe<Scalars['DateTime']>;
  payrollPaymentBatchStatus?: Maybe<PayrollPaymentBatchStatus>;
  payrollPaymentBatchType?: Maybe<PayrollPaymentBatchType>;
  payrollPaymentsPaginated?: Maybe<PayrollPaymentResult>;
};

export type PayrollPaymentFileBatch_PayrollPaymentsPaginatedArgs = {
  filters?: InputMaybe<PayrollPaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type PayrollPaymentFilters = {
  companyId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['ID']>;
  firstInvoiceStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  memberName?: InputMaybe<Scalars['String']>;
  payrollPaymentStatus?: InputMaybe<Array<InputMaybe<PayrollPaymentStatus>>>;
};

export type PayrollPaymentInfo = {
  __typename?: 'PayrollPaymentInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  id: Scalars['ID'];
  payrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
};

export type PayrollPaymentResult = {
  __typename?: 'PayrollPaymentResult';
  data?: Maybe<Array<Maybe<PayrollPayment>>>;
  pageResult?: Maybe<PageResult>;
};

export enum PayrollPaymentStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  VOID = 'VOID',
}

export enum PayrollPaymentType {
  EXPENSES_PAYMENT = 'EXPENSES_PAYMENT',
  PAY_SUPPLEMENT_PAYMENT = 'PAY_SUPPLEMENT_PAYMENT',
  SALARY_PAYMENT = 'SALARY_PAYMENT',
}

export type PayrollProcess = {
  __typename?: 'PayrollProcess';
  currentStep?: Maybe<PartnerPayrollVersionStep>;
  payrollReportUpload?: Maybe<PayrollReportUpload>;
  payrollUpdates?: Maybe<Array<PayrollUpdateBatch>>;
  versions?: Maybe<Array<Maybe<PartnerPayrollVersion>>>;
  workflow?: Maybe<Array<Maybe<PayrollVersionWorkFlowNode>>>;
};

export type PayrollReconciliationData = {
  __typename?: 'PayrollReconciliationData';
  payrollCycle: PayrollCycle;
  payrollDataId?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<PayrollReconciliationResult>>>;
};

export type PayrollReconciliationResult = {
  __typename?: 'PayrollReconciliationResult';
  category: ReconciliationCategory;
  contractIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  group: ReconciliationGroup;
  id: Scalars['ID'];
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  severityLevel?: Maybe<ReconciliationResultSeverity>;
  status: ReconciliationResultStatus;
};

export type PayrollReportGeneratorConfigFilter = {
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollReportGeneratorConfigInput = {
  configString?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
};

export type PayrollReportUpload = {
  __typename?: 'PayrollReportUpload';
  country?: Maybe<CountryCode>;
  document?: Maybe<Document>;
  /** @deprecated please use document instead of file */
  file?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  month?: Maybe<PayrollMonth>;
  parsedMemberPays?: Maybe<Array<Maybe<ParsedMemberPay>>>;
  partnerId?: Maybe<Scalars['ID']>;
  payrollCycle?: Maybe<PayrollCycle>;
  reconciliationResults?: Maybe<Array<Maybe<ReconciliationResult>>>;
};

export type PayrollSnapshotAssignDomainInput = {
  domain: PayrollInputDomainType;
  domainIds: Array<Scalars['ID']>;
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type PayrollSnapshotRefreshDomainByIdInput = {
  domain: PayrollInputDomainType;
  domainIds: Array<Scalars['ID']>;
};

export type PayrollSnapshotWatermarkRefreshInput = {
  domain: PayrollInputDomainType;
  until?: InputMaybe<Scalars['DateTime']>;
};

export type PayrollTimeOffFilter = {
  contractIds: Array<InputMaybe<Scalars['ID']>>;
  timeOffReportInput: TimeOffReportInput;
  timeOffStatus: Array<InputMaybe<TimeOffStatus>>;
};

export type PayrollUpdateBatch = {
  __typename?: 'PayrollUpdateBatch';
  createdOn: Scalars['DateTime'];
  updateItems?: Maybe<Array<PayrollUpdateItem>>;
};

export type PayrollUpdateBatchCreateFilter = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  expenseIds?: InputMaybe<Array<Scalars['ID']>>;
  paySupplementIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PayrollUpdateItem = {
  __typename?: 'PayrollUpdateItem';
  data?: Maybe<PayrollUpdateItemData>;
  type?: Maybe<PayrollUpdateType>;
};

export type PayrollUpdateItemData = Contract | Expense | PaySupplement;

export type PayrollUpdateTaskResponse = {
  __typename?: 'PayrollUpdateTaskResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updateItemCount?: Maybe<Scalars['Int']>;
};

export enum PayrollUpdateType {
  EXPENSE = 'EXPENSE',
  MEMBER_OFFBOARDED = 'MEMBER_OFFBOARDED',
  NEW_MEMBER = 'NEW_MEMBER',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY_CHANGE = 'SALARY_CHANGE',
  START_DATE = 'START_DATE',
}

export type PayrollVersionWorkFlowNode = {
  __typename?: 'PayrollVersionWorkFlowNode';
  status?: Maybe<PartnerPayrollVersionStatus>;
  statuses?: Maybe<Array<Maybe<PartnerPayrollVersionStatus>>>;
  step?: Maybe<PartnerPayrollVersionStep>;
};

export enum PayrollWorkflowAction {
  CONFIRMINPUT = 'ConfirmInput',
  CONFIRMOUTPUTREPORT = 'ConfirmOutputReport',
  CONFIRMPAYSLIPS = 'ConfirmPayslips',
  INITIATEPAYROLLCLOSURE = 'InitiatePayrollClosure',
  REVIEWOUTPUTREPORT = 'ReviewOutputReport',
  SENDFORREVIEW = 'SendForReview',
  SENDTOCUSTOMER = 'SendToCustomer',
  UPLOADOUTPUTREPORT = 'UploadOutputReport',
}

export type PayrollWorkflowResponse = {
  __typename?: 'PayrollWorkflowResponse';
  payrollCycles: Array<PayrollCycle>;
};

export type Payslip = {
  __typename?: 'Payslip';
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  /** @deprecated Use payslipDocument instead. Keeping this field for backward compatibility */
  document?: Maybe<Document>;
  /** @deprecated Use payslipDocument instead. Keeping this field for backward compatibility */
  file?: Maybe<FileLink>;
  id?: Maybe<Scalars['ID']>;
  month?: Maybe<PayrollMonth>;
  netAmount?: Maybe<Scalars['Float']>;
  payCycle?: Maybe<PayrollCycle>;
  /** @deprecated Use payCycle instead. Keeping this field for backward compatibility */
  payrollCycle?: Maybe<Scalars['Int']>;
  payslipDocument?: Maybe<PayslipDocument>;
  status?: Maybe<PayslipStatus>;
};

export type PayslipConfirmInput = {
  payrollCycleId: Scalars['ID'];
};

export type PayslipDocument = {
  __typename?: 'PayslipDocument';
  downloadUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PayslipForceUploadInput = {
  contractId: Scalars['ID'];
  payrollCycleId: Scalars['ID'];
};

export type PayslipPublishInput = {
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  payrollCycleId: Scalars['ID'];
};

export enum PayslipStatus {
  GENERATION_FAILED = 'GENERATION_FAILED',
  GENERATION_IN_PROGRESS = 'GENERATION_IN_PROGRESS',
  GENERATION_SUCCESSFUL = 'GENERATION_SUCCESSFUL',
  PUBLISHED = 'PUBLISHED',
  READY_TO_PUBLISH = 'READY_TO_PUBLISH',
  UPLOADED = 'UPLOADED',
  VALIDATED = 'VALIDATED',
  VERIFIED = 'VERIFIED',
}

export type PayslipSummary = {
  __typename?: 'PayslipSummary';
  published?: Maybe<Scalars['Int']>;
  readyToPublish?: Maybe<Scalars['Int']>;
  totalMembers?: Maybe<Scalars['Int']>;
};

export type PayslipUploadDetails = {
  __typename?: 'PayslipUploadDetails';
  confirmed?: Maybe<ConfirmedPayslipsSummary>;
  uploadUrl?: Maybe<Scalars['String']>;
  validationRequest?: Maybe<PayslipValidationRequest>;
};

export enum PayslipValidationErrorType {
  CONTRACT_ID_MISMATCH = 'CONTRACT_ID_MISMATCH',
  CONTRACT_ID_SKIPPED = 'CONTRACT_ID_SKIPPED',
  FILE_READ_ERROR = 'FILE_READ_ERROR',
  MEMBER_FIRST_NAME_MISMATCH = 'MEMBER_FIRST_NAME_MISMATCH',
  MEMBER_LAST_NAME_MISMATCH = 'MEMBER_LAST_NAME_MISMATCH',
  NO_MATCHING_FILE = 'NO_MATCHING_FILE',
}

export type PayslipValidationRequest = {
  __typename?: 'PayslipValidationRequest';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<PayslipValidationRequestStatus>;
  validationReport?: Maybe<PayrollFile>;
  validationResults?: Maybe<Array<Maybe<PayslipValidationResult>>>;
};

export enum PayslipValidationRequestStatus {
  ENDED = 'ENDED',
  INITIATED = 'INITIATED',
}

export type PayslipValidationResult = {
  __typename?: 'PayslipValidationResult';
  contractId?: Maybe<Scalars['ID']>;
  errorTypes?: Maybe<Array<Maybe<PayslipValidationErrorType>>>;
  fileName?: Maybe<Scalars['String']>;
};

export type PayslipsResponse = {
  __typename?: 'PayslipsResponse';
  page?: Maybe<PageResult>;
  payslips?: Maybe<Array<Maybe<Payslip>>>;
};

export type PennyTestInitiateRequestData = {
  amount: TransferValueAmount;
  batchPaymentFileId: Scalars['String'];
};

export type PercentPayComponent = CompensationPayComponent & {
  __typename?: 'PercentPayComponent';
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<PayAmountType>;
  basedOn?: Maybe<FixedPayComponent>;
  condition?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CurrencyCode>;
  frequency?: Maybe<RateFrequency>;
  id?: Maybe<Scalars['ID']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  paySchedule?: Maybe<ScheduleTime>;
  paymentFrequency?: Maybe<PayFrequency>;
  paymentFrequencyDate?: Maybe<Array<Maybe<PayFrequencyDate>>>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PercentPayComponentDefinition = CompensationPayComponentDefinition & {
  __typename?: 'PercentPayComponentDefinition';
  basedOn?: Maybe<FixedPayComponentDefinition>;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<RateFrequency>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payOn?: Maybe<MonthYear>;
  rateType?: Maybe<RateType>;
};

export type PercentPayComponentInput = {
  amountType?: InputMaybe<PayAmountType>;
  basedOn?: InputMaybe<FixedPayComponentInput>;
  condition?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyCode>;
  frequency?: InputMaybe<RateFrequency>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payOn?: InputMaybe<MonthYearInput>;
  paySchedule?: InputMaybe<ScheduleTimeInput>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<InputMaybe<PayFrequencyDateInput>>>;
  ratio?: InputMaybe<Scalars['Float']>;
};

export type PerformanceReview = {
  __typename?: 'PerformanceReview';
  comment?: Maybe<Scalars['String']>;
  companySignedOn?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<Designation>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memberSignedOn?: Maybe<Scalars['DateTime']>;
  payrollCycle?: Maybe<PayrollCycle>;
  previousDesignation?: Maybe<Designation>;
  reviewDocument?: Maybe<DocumentReadable>;
  salaryReview?: Maybe<SalaryReview>;
  status?: Maybe<PerformanceReviewStatus>;
};

export type PerformanceReviewBulkInput = {
  addendumDocument?: InputMaybe<Scalars['Upload']>;
  companySignedOn?: InputMaybe<Scalars['DateTime']>;
  memberSignedOn?: InputMaybe<Scalars['DateTime']>;
  performanceReview: PerformanceReviewInput;
};

export type PerformanceReviewInput = {
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  designation?: InputMaybe<DesignationInput>;
  effectiveDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  salaryReview?: InputMaybe<SalaryReviewInput>;
};

export type PerformanceReviewNote = {
  __typename?: 'PerformanceReviewNote';
  cutOffDate?: Maybe<Scalars['Date']>;
  /** @deprecated FE should build the notes. BE will be returning null to avoid any confusion */
  message?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  payrollCyclePayDate?: Maybe<Scalars['Date']>;
  semimonthlySegment?: Maybe<SemimonthlySegment>;
  type?: Maybe<PerformanceReviewNoteType>;
  year?: Maybe<Scalars['Int']>;
};

export type PerformanceReviewNoteInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  currentDate?: InputMaybe<Scalars['Date']>;
  effectiveDate: Scalars['Date'];
};

export enum PerformanceReviewNoteType {
  EFFECTIVE = 'EFFECTIVE',
  REFLECTIVE = 'REFLECTIVE',
}

export enum PerformanceReviewStatus {
  ACTIVATED = 'ACTIVATED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DRAFT = 'DRAFT',
  SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
  SENT_FOR_MEMBER_APPROVAL = 'SENT_FOR_MEMBER_APPROVAL',
  SENT_TO_OPS = 'SENT_TO_OPS',
}

export type PeriodInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

/** Platform permission (pre-defined) */
export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Person = {
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  persona?: Maybe<Persona>;
  phoneNos?: Maybe<Array<Maybe<PhoneNumber>>>;
  userId?: Maybe<Scalars['String']>;
};

export enum Persona {
  COMPANY = 'COMPANY',
  MEMBER = 'MEMBER',
  OPERATIONS = 'OPERATIONS',
  PARTNER = 'PARTNER',
}

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  phoneNo?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PhoneNumberParam = {
  __typename?: 'PhoneNumberParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<PhoneNumber>;
};

export type PlatformBatchedUpdateInput = {
  action: Scalars['String'];
  payload: Array<Scalars['String']>;
  ticketId: Scalars['String'];
};

export enum PlatformCategory {
  ACCOUNTING = 'ACCOUNTING',
  ATS = 'ATS',
  CRM = 'CRM',
  EXPENSES = 'EXPENSES',
  HRIS = 'HRIS',
  TICKETING = 'TICKETING',
}

export type PlatformJobMetaDataFilter = {
  action?: InputMaybe<Array<Scalars['String']>>;
  ownerBy?: InputMaybe<Scalars['String']>;
};

export type PlatformUpdateInput = {
  action: Scalars['String'];
  payload: Scalars['String'];
  ticketId: Scalars['String'];
};

export type PlatformUpdateTaskResponse = {
  __typename?: 'PlatformUpdateTaskResponse';
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  request?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  ticketId?: Maybe<Scalars['String']>;
};

export type Position = {
  __typename?: 'Position';
  department?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
};

export type PreferredContractAgreementTemplateResponse = {
  __typename?: 'PreferredContractAgreementTemplateResponse';
  documentId?: Maybe<Scalars['ID']>;
  template?: Maybe<Template>;
};

export type PreviousEmployer = {
  __typename?: 'PreviousEmployer';
  designation?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  jobDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type PreviousEmployerInput = {
  designation: Scalars['String'];
  endDate: Scalars['Date'];
  jobDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['Date'];
};

export type Pricing = {
  __typename?: 'Pricing';
  /** removing the entire section from the front-end */
  billingCurrencyCode?: Maybe<CurrencyCode>;
  company?: Maybe<Company>;
  customDiscountTerm?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  deposit?: Maybe<Scalars['Float']>;
  depositTerm?: Maybe<DepositTerm>;
  discountTerms?: Maybe<Array<Maybe<DiscountTerm>>>;
  employeePricing?: Maybe<Array<Maybe<EmployeePricing>>>;
  globalPricing?: Maybe<Array<Maybe<GlobalPricing>>>;
  id?: Maybe<Scalars['ID']>;
  offboardingGlobalPricing?: Maybe<Array<Maybe<OffboardingGlobalPricing>>>;
  paymentTermInDays?: Maybe<Scalars['Int']>;
  /**
   * deposit is no longer a fixed value. Use depositTerm instead
   * @deprecated No longer supported
   */
  resourceClaim?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  serviceFee?: Maybe<Scalars['Float']>;
  services?: Maybe<Scalars['String']>;
  setupFee?: Maybe<Scalars['Float']>;
  visaGlobalPricing?: Maybe<Array<Maybe<VisaGlobalPricing>>>;
  visaPricing?: Maybe<Array<Maybe<EmployeePricing>>>;
};

export type PricingCountryPlan = {
  __typename?: 'PricingCountryPlan';
  country: CountryCode;
  id: Scalars['ID'];
  seats: Array<PricingPlanSeat>;
  workStatus?: Maybe<CountryWorkStatus>;
};

export type PricingInput = {
  billingCurrencyCode?: InputMaybe<CurrencyCode>;
  customDiscountTerm?: InputMaybe<Scalars['String']>;
  deposit?: InputMaybe<Scalars['Float']>;
  depositTerm?: InputMaybe<DepositTermInput>;
  discountTerms: Array<InputMaybe<DiscountTermInput>>;
  employeePricing: Array<InputMaybe<CompanyEmployeePricingInput>>;
  globalPricing?: InputMaybe<Array<InputMaybe<CompanyGlobalPricingInput>>>;
  offboardingGlobalPricing?: InputMaybe<Array<OffboardingGlobalPricingInput>>;
  paymentTermInDays?: InputMaybe<Scalars['Int']>;
  resourceClaim?: InputMaybe<Scalars['String']>;
  serviceFee?: InputMaybe<Scalars['Float']>;
  services: Scalars['String'];
  setupFee: Scalars['Float'];
  visaGlobalPricing?: InputMaybe<
    Array<InputMaybe<CompanyVisaGlobalPricingInput>>
  >;
  visaPricing?: InputMaybe<Array<InputMaybe<CompanyVisaPricingInput>>>;
};

export type PricingPlan = {
  __typename?: 'PricingPlan';
  autoSeatExpansion: Scalars['Boolean'];
  companyId: Scalars['ID'];
  companyOfferingId: Scalars['ID'];
  companyOfferingPricingId: Scalars['ID'];
  contractIds: Array<Scalars['ID']>;
  discount?: Maybe<Discount>;
  end?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  paymentTerm?: Maybe<PaymentTerm>;
  pricingCountryPlans: Array<PricingCountryPlan>;
  start?: Maybe<Scalars['Date']>;
  status?: Maybe<PricingPlanStatus>;
};

export type PricingPlanAutofillInput = {
  expandIfNecessary?: InputMaybe<Scalars['Boolean']>;
  pricingPlanId: Scalars['ID'];
};

export type PricingPlanCloseSeatsInput = {
  country: CountryCode;
  numberOfSeatsToClose: Scalars['Int'];
  pricingPlanId: Scalars['ID'];
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type PricingPlanCreateInput = {
  autoSeatExpansion?: InputMaybe<Scalars['Boolean']>;
  companyOfferingId: Scalars['ID'];
  discount?: InputMaybe<DiscountInput>;
  end: Scalars['Date'];
  generateAndAutofillSeats?: InputMaybe<Scalars['Boolean']>;
  initialContractIds?: InputMaybe<Array<Scalars['ID']>>;
  paymentTerm?: InputMaybe<PaymentTermInput>;
  seatConfigurations?: InputMaybe<Array<SeatConfigurationInput>>;
  start: Scalars['Date'];
};

export type PricingPlanDeleteInput = {
  forceDeleteIfActive: Scalars['Boolean'];
  id: Scalars['ID'];
  vacateAndCloseAllSeats: Scalars['Boolean'];
};

export type PricingPlanExpandInput = {
  /** @deprecated Use seatConfigurations[i].count instead */
  count?: InputMaybe<Scalars['Int']>;
  country: CountryCode;
  pricingPlanId: Scalars['ID'];
  seatConfigurations?: InputMaybe<Array<SeatConfigurationInput>>;
  /** @deprecated Use seatConfigurations[i].workStatus instead */
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type PricingPlanMigrateInput = {
  pricingPlans: Array<CompanyPricingPlanMigrateInput>;
};

export type PricingPlanMigrateResponse = {
  __typename?: 'PricingPlanMigrateResponse';
  pricingPlans: Array<CompanyPricingPlanMigrateResponse>;
};

export type PricingPlanSeat = {
  __typename?: 'PricingPlanSeat';
  contractId?: Maybe<Scalars['ID']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  events: Array<PricingPlanSeatEvent>;
  id: Scalars['ID'];
  pricingCountryPlanId: Scalars['ID'];
  status?: Maybe<SeatStatus>;
};

export type PricingPlanSeatEvent = {
  __typename?: 'PricingPlanSeatEvent';
  contractId?: Maybe<Scalars['ID']>;
  eventTime?: Maybe<Scalars['DateTime']>;
  eventType?: Maybe<SeatEventType>;
  id: Scalars['ID'];
  seatId: Scalars['ID'];
};

export enum PricingPlanStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

export type PricingPlanUpdateInput = {
  autoSeatExpansion?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<DiscountInput>;
  end?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  initialContractIds?: InputMaybe<Array<Scalars['ID']>>;
  paymentTerm?: InputMaybe<PaymentTermInput>;
  seatConfigurations?: InputMaybe<Array<SeatConfigurationInput>>;
  start?: InputMaybe<Scalars['Date']>;
};

export type ProcessingFee = {
  __typename?: 'ProcessingFee';
  feeTotal?: Maybe<Scalars['Float']>;
  feeTypes?: Maybe<Array<Maybe<FeeType>>>;
};

export type Product = {
  __typename?: 'Product';
  brand: Scalars['String'];
  color?: Maybe<ProductColor>;
  countryCode: CountryCode;
  currencyCode: CurrencyCode;
  description?: Maybe<Scalars['String']>;
  dynamicDetails?: Maybe<Array<ProductDetailCategory>>;
  id: Scalars['ID'];
  images?: Maybe<Array<ProductImage>>;
  modelNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sku: Scalars['String'];
  status: ProductStatus;
  type: Scalars['String'];
};

export type ProductColor = {
  __typename?: 'ProductColor';
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ProductDetail = {
  __typename?: 'ProductDetail';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProductDetailCategory = {
  __typename?: 'ProductDetailCategory';
  details?: Maybe<Array<ProductDetail>>;
  key: Scalars['String'];
};

export type ProductFilter = {
  brand?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  productId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<ProductStatus>;
  type?: InputMaybe<Scalars['String']>;
};

export type ProductFilterSpec = {
  __typename?: 'ProductFilterSpec';
  brands?: Maybe<Array<Scalars['String']>>;
  countries?: Maybe<Array<CountryCode>>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductList = {
  __typename?: 'ProductList';
  filterSpec?: Maybe<ProductFilterSpec>;
  products?: Maybe<Array<Product>>;
};

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type PromotionalPeriodDiscountTermCondition = {
  __typename?: 'PromotionalPeriodDiscountTermCondition';
  event?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  relativeDurationFromEvent?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Date']>;
};

export type PromotionalPeriodDiscountTermConditionInput = {
  event?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  relativeDurationFromEvent?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type PublishPayslipsResponse = {
  __typename?: 'PublishPayslipsResponse';
  publishPayslipsSummary?: Maybe<PublishPayslipsSummary>;
  publishedPayslips?: Maybe<Array<Maybe<Payslip>>>;
};

export type PublishPayslipsSummary = {
  __typename?: 'PublishPayslipsSummary';
  nonPublishedPayslipsCount?: Maybe<Scalars['Int']>;
  publishedPayslipsCount?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  GetPaymentInstructionDocument?: Maybe<Document>;
  GetPaymentInstructionDocumentV2?: Maybe<PaymentInstructionFileGenerationReport>;
  /** @deprecated Use PaymentInstructionFile */
  GetPaymentInstructionFile?: Maybe<DocumentReadable>;
  benefit?: Maybe<Benefit>;
  benefitActivityLogs?: Maybe<Array<Maybe<ActivityDocument>>>;
  benefitPackageCost?: Maybe<BenefitPackageCost>;
  benefitPackageCosts?: Maybe<Array<Maybe<BenefitPackageCost>>>;
  benefitPartnerAddOperationActivityTemplate: DocumentReadable;
  /** @deprecated No longer supported */
  benefitPartnerAddOperationTemplate: DocumentReadable;
  benefitPartnerCountries?: Maybe<Array<Maybe<BenefitPartnerCountry>>>;
  benefitPartnerCountriesWithPagination?: Maybe<BenefitPartnerCountriesResult>;
  benefitPartnerCountry?: Maybe<BenefitPartnerCountry>;
  benefitPartnerCountryCheckExpirationStatus?: Maybe<Scalars['Boolean']>;
  benefitPartnerDataDownload: DocumentReadable;
  benefitPartnerEditOperationSheetDownload: DocumentReadable;
  benefits?: Maybe<Array<Maybe<Benefit>>>;
  bulkOnboardingTemplateDownload?: Maybe<DocumentReadable>;
  companies?: Maybe<Array<Maybe<Company>>>;
  companiesWithPagination?: Maybe<CompaniesResult>;
  company?: Maybe<Company>;
  companyChargebackPeriod?: Maybe<CompanyChargebackResult>;
  companyGroup?: Maybe<Array<Maybe<CompanyGroup>>>;
  companyHasDuplicatedDomain?: Maybe<Scalars['Boolean']>;
  companyHasIntegration?: Maybe<Scalars['Boolean']>;
  companyIntegration?: Maybe<CompanyIntegration>;
  companyIntegrationInfo?: Maybe<CompanyIntegrationInfo>;
  companyOfferingPricing?: Maybe<Array<Maybe<CompanyOfferingPricing>>>;
  companyPayableDownloadInvoiceSourceReport: DocumentReadable;
  /** @deprecated Use the companyPayableDownloadInvoiceSourceReportByInvoiceId instead */
  companyPayableDownloadInvoiceSourceReportByHash: DocumentReadable;
  /** @deprecated Use the companyPayableDownloadInvoiceSourceReportFromLink to get ISR for both Invoice and CN */
  companyPayableDownloadInvoiceSourceReportByInvoiceId: DocumentReadable;
  companyPayableDownloadInvoiceSourceReportFromHash: DocumentReadable;
  companyPayableDownloadInvoiceSourceReportFromLink: DocumentReadable;
  companyPayableDownloadInvoiceSourceReports: DocumentReadable;
  companyPayableInvoiceSourceDetailedReport?: Maybe<
    Array<Maybe<CompanyInvoiceSourceItem>>
  >;
  companyPayableInvoiceSourceDetailedReportFetch?: Maybe<
    Array<Maybe<CompanyInvoiceSourceRecord>>
  >;
  companyPayableInvoiceSourceReport?: Maybe<
    Array<Maybe<CompanyInvoiceSourceReportResult>>
  >;
  companyPayablesWithPagination?: Maybe<CompanyPayablesResult>;
  /** @deprecated No longer supported */
  companyPayroll?: Maybe<CompanyPayroll>;
  companyPayrollCycle?: Maybe<CompanyPayrollCycle>;
  companyPayrollCycleReportDownload?: Maybe<PayrollFile>;
  companyPayrollCycleReports?: Maybe<Array<CompanyPayrollReport>>;
  companyPayrollCycles: CompanyPayrollCyclesResult;
  companyPayrollEntityTypes?: Maybe<Array<Maybe<PayrollCycleEntityType>>>;
  companyPayrollInputConfirmRules?: Maybe<AccessibilityResponse>;
  companyPayrollInputReportDownload?: Maybe<DocumentReadable>;
  companyPayrollMemberPays?: Maybe<CompanyMemberPayResult>;
  companyPayrollOutputConfirmRules?: Maybe<AccessibilityResponse>;
  companyPayrollPayslipPublishRules?: Maybe<AccessibilityResponse>;
  companyPayrollReportDownload?: Maybe<DocumentReadable>;
  companyPrePopulatedPaySupplementReport?: Maybe<DocumentReadable>;
  companyQuotation?: Maybe<CompanyQuotation>;
  companyQuotations?: Maybe<CompanyQuotationsResponse>;
  companySync?: Maybe<CompanySync>;
  compensationComponents: CompensationSchema;
  /** @deprecated No longer supported */
  compensationStructureDefinition?: Maybe<CompensationStructureDefinition>;
  /** @deprecated No longer supported */
  complianceParamsDefinition?: Maybe<Array<Maybe<ComplianceParamDefinition>>>;
  componentCategories: Array<ComponentCategory>;
  configuration?: Maybe<Configuration>;
  contract?: Maybe<Contract>;
  contractBenefitBanners?: Maybe<Array<Maybe<ContractBenefitBanner>>>;
  contractBenefitChangeRequests?: Maybe<
    Array<Maybe<ContractBenefitChangeRequestResponse>>
  >;
  /** @deprecated No longer supported */
  contractBenefitDocument?: Maybe<DocumentReadable>;
  contractBenefitReport?: Maybe<DocumentReadable>;
  contractBenefitsReport?: Maybe<DocumentReadable>;
  contractBenefitsWithPagination?: Maybe<ContractBenefitsResult>;
  contractDetailsReport?: Maybe<DocumentReadable>;
  contractJobCode?: Maybe<Scalars['String']>;
  contractPayrollDetailsReport?: Maybe<DocumentReadable>;
  /** Mainly for "Support 360" feature */
  contractSnapshots?: Maybe<CycleContractSnapshotsResponse>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  contractsWithPagination?: Maybe<ContractsResult>;
  /** @deprecated No longer supported */
  convertCurrency?: Maybe<Scalars['Float']>;
  countries?: Maybe<Array<Maybe<Country>>>;
  country?: Maybe<Country>;
  countryAllowancesConfig: Array<Allowance>;
  countryCallingCodes?: Maybe<Array<Maybe<CountryCallingCodeDefinition>>>;
  countryLegalData?: Maybe<Array<Maybe<LegalData>>>;
  creditNoteCountByStatus?: Maybe<Array<CreditNoteCountByStatus>>;
  creditNotesWithPagination?: Maybe<CreditNotesResult>;
  currency?: Maybe<Currency>;
  customPayrollReport: CustomPayrollReportDetails;
  customPayrollReportConfigs: Array<CustomPayrollReportConfig>;
  customPayrollReports: CustomPayrollReportsResponse;
  customerIntegrationDefinitions?: Maybe<
    Array<Maybe<CustomerIntegrationDefinition>>
  >;
  cycleContracts?: Maybe<CycleContractSnapshotsResponse>;
  dataChangeReport?: Maybe<DocumentReadable>;
  departments: Array<Department>;
  disputes?: Maybe<DisputeResponse>;
  downloadCompanyPayableReport: DocumentReadable;
  downloadCustomPayrollReport: DocumentReadable;
  downloadPayrollReconciliationData?: Maybe<DocumentReadable>;
  downloadSalaryInvoiceSourceReport?: Maybe<Array<DocumentReadable>>;
  dummy?: Maybe<Scalars['Boolean']>;
  eligibleCountriesForEor: Array<PayrollCountryEntityType>;
  eligibleEntitiesForPeo: Array<PayrollCycleEntity>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplateByType?: Maybe<EmailTemplate>;
  emailTemplates: Array<Maybe<EmailTemplate>>;
  employeePricingDefaultCountries?: Maybe<EmployeePricingDefault>;
  employeePricingForCountry?: Maybe<Array<Maybe<EmployeePricing>>>;
  employeePricingForRegion?: Maybe<Array<Maybe<EmployeePricing>>>;
  equipmentIncompleteProcurementsDownload?: Maybe<EquipmentDownloadResponse>;
  equipmentsDownload?: Maybe<EquipmentDownloadResponse>;
  expense?: Maybe<Expense>;
  expenseCategories?: Maybe<Array<ExpenseCategory>>;
  expenseValidationInfo: ExpenseValidationInfo;
  expenses?: Maybe<ExpensesResponse>;
  expensesReport?: Maybe<DocumentReadable>;
  feeComponentDefinition?: Maybe<Array<Maybe<FeeComponentDefinition>>>;
  feeComponentTierDefinition?: Maybe<Array<Maybe<FeeComponentTierDefinition>>>;
  fileDownloadHistory?: Maybe<FileDownloadHistoryResponse>;
  filteredDataChange?: Maybe<DataChangePageResults>;
  financialTransactionInspect: FinancialTransactionInspectorResponse;
  generateSalaryInvoiceSourceReports?: Maybe<TaskResponse>;
  getAllApprovers?: Maybe<Array<Maybe<Approver>>>;
  getApplicableExceptionCategories: Array<
    Maybe<PayrollContractExceptionReasonCategory>
  >;
  getAvailableReports: Array<Maybe<ReportConfig>>;
  getCompanyPayInMethod?: Maybe<CompanyPayInMethod>;
  getCompanyPrefillData?: Maybe<CompanyPreFillData>;
  getComponentAccessibilityRules?: Maybe<ComponentAccessibilityResponse>;
  getEmployeesToSendInviteTo?: Maybe<Array<Maybe<Employee>>>;
  getEmployeesToSync?: Maybe<SyncEmployees>;
  getEmployeesToSyncEOR?: Maybe<EorEmployeesToSyncResult>;
  getExternalLeaveTypes?: Maybe<Array<Maybe<ExternalLeaveType>>>;
  getIntegrationEntityMappingStatus?: Maybe<
    Array<Maybe<IntegrationEntityMappingStatusOutput>>
  >;
  getIntegrationFieldsMapping?: Maybe<IntegrationFieldsMappingOutput>;
  getInternalLeaveTypes?: Maybe<Array<Maybe<ExternalLeaveType>>>;
  getLastWorkingDayRange?: Maybe<LastWorkingDayRangeResponse>;
  getLatestEmployeesForImport?: Maybe<FetchEmployeesResult>;
  getLatestSyncResultForIntegration?: Maybe<LatestSyncResult>;
  getLeaveTypeMappingDefinition?: Maybe<
    Array<Maybe<LeaveTypeMappingDefinition>>
  >;
  getMemberPersonalDetailsAccessibilityRules?: Maybe<
    Array<Maybe<SectionAccessibilityResponse>>
  >;
  getPaymentOptions?: Maybe<Array<Maybe<PaymentOptions>>>;
  getPayrollReconciliationData?: Maybe<PayrollReconciliationData>;
  getPendingEmployeesToSync?: Maybe<Array<Maybe<Employee>>>;
  getPositionsForIntegrations?: Maybe<Array<Maybe<Position>>>;
  getReport: Scalars['String'];
  getSEPAIBANDetailsFieldInformation?: Maybe<SepaibanDetailsFieldInformationResponse>;
  getTentativeLastWorkingDay?: Maybe<LastWorkingDayResponse>;
  getTimeOffsForPayroll?: Maybe<Array<Maybe<TimeOff>>>;
  holidays?: Maybe<Array<Maybe<Holiday>>>;
  insuranceCoverageGroups?: Maybe<Array<Maybe<InsuranceCoverageGroup>>>;
  insuranceRelation?: Maybe<Array<Maybe<InsuranceRelation>>>;
  insuranceTier?: Maybe<Array<Maybe<InsuranceTier>>>;
  /** @deprecated Not implemented. Do not use this. */
  isNonHrisManager: Scalars['Boolean'];
  kyb?: Maybe<Kyb>;
  kybList?: Maybe<Array<Maybe<Kyb>>>;
  latestMemberPayrollCycles?: Maybe<MemberPayrollCyclesResponse>;
  manager?: Maybe<Manager>;
  managerDirectReportsRule?: Maybe<Rule>;
  member?: Maybe<Member>;
  memberPayableCompanyInvoices?: Maybe<MemberPayableCompanyInvoiceResult>;
  /** @deprecated No longer supported */
  memberPayableDownloadInvoiceSourceReport: DocumentReadable;
  memberPayableReport?: Maybe<DocumentReadable>;
  memberPayableSummary?: Maybe<MemberPayableSummary>;
  memberPayrollCycleDetail?: Maybe<MemberPayrollCycleDetail>;
  memberPayrollCycles?: Maybe<MemberPayrollCyclesResponse>;
  /** membersWithPagination will return the list of member by filter and pagination */
  membersWithPagination?: Maybe<MembersResult>;
  msaAddendumDraftSample?: Maybe<MsaAddendum>;
  nothing?: Maybe<Scalars['String']>;
  offboardingExperienceState: OffboardingExperienceState;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  ok?: Maybe<Scalars['Boolean']>;
  onboarding?: Maybe<ContractOnboarding>;
  onboardingAudit?: Maybe<Array<Maybe<Scalars['Object']>>>;
  operationsDashboard?: Maybe<OperationsDashboard>;
  operationsUser?: Maybe<OperationsUser>;
  operationsUsers?: Maybe<Array<Maybe<OperationsUser>>>;
  orgDirectory: OrgDirectory;
  /** @deprecated Not implemented. Use 'orgDirectory' query instead. */
  orgHierarchy: Manager;
  /** Get all users that can be assigned to a role (TO-BE discussed!!!) */
  orgRoleAvailableManagers?: Maybe<Array<Manager>>;
  orgRoles?: Maybe<Array<OrgRole>>;
  partner?: Maybe<Partner>;
  /** @deprecated No longer supported */
  partnerPayroll?: Maybe<PartnerPayroll>;
  partnerPayrollSchema: PartnerPayrollSchema;
  partnerPayrolls?: Maybe<Array<Maybe<PartnerPayroll>>>;
  partners?: Maybe<Array<Maybe<Partner>>>;
  paySupplement?: Maybe<PaySupplement>;
  paySupplements?: Maybe<PaySupplementsResponse>;
  paySupplementsReport?: Maybe<DocumentReadable>;
  paymentAccount?: Maybe<PaymentAccount>;
  paymentBundlePreview?: Maybe<PaymentBundlePreview>;
  paymentBundles?: Maybe<Array<Maybe<PaymentBundle>>>;
  paymentOk?: Maybe<Scalars['Boolean']>;
  payrollBulkUploadConfirmRules?: Maybe<AccessibilityResponse>;
  payrollBulkUploadConfirmStatus?: Maybe<PayrollBulkUploadConfirmStatus>;
  payrollBulkUploadCreateRules?: Maybe<AccessibilityResponse>;
  payrollBulkUploadTemplate?: Maybe<DocumentReadable>;
  payrollBulkUploadValidateRules?: Maybe<AccessibilityResponse>;
  payrollConfigsWithNextPayrollCycles: Array<PayrollCycleConfig>;
  payrollContract?: Maybe<PayrollContract>;
  payrollCycle?: Maybe<PayrollCycle>;
  payrollCycleConfigs?: Maybe<PayrollCycleConfigsResponse>;
  payrollCycleForDomain?: Maybe<PayrollCycle>;
  payrollCyclePayment?: Maybe<PayrollCyclePayment>;
  payrollCyclePayments?: Maybe<PayrollCyclePaymentResult>;
  payrollCycles?: Maybe<PayrollCyclesResponse>;
  payrollCyclesForCalendar: Array<PayrollCycle>;
  payrollDocuments?: Maybe<PayrollDocumentsResponse>;
  payrollFileDownload?: Maybe<PayrollFileDownloadResponse>;
  payrollInputFile?: Maybe<DocumentReadable>;
  payrollInputFileDownload?: Maybe<DocumentReadable>;
  payrollInputFilePartnerBulkDownload?: Maybe<DocumentReadable>;
  payrollParserConfig?: Maybe<PayrollParserConfig>;
  payrollPaymentBatch?: Maybe<PayrollPaymentBatch>;
  payrollPaymentBatches?: Maybe<PayrollPaymentBatchResult>;
  payrollReportDownload?: Maybe<DocumentReadable>;
  payrollReportDownloadForCustomer?: Maybe<DocumentReadable>;
  payrollReportGeneratorConfig?: Maybe<ReportGeneratorConfig>;
  /** payrollReports use to get the list of Payroll Report */
  payrollReports?: Maybe<CompanyMemberPayResult>;
  payslips?: Maybe<PayslipsResponse>;
  performanceReviewNotes?: Maybe<PerformanceReviewNote>;
  permissions?: Maybe<Array<Permission>>;
  platformMergeDevLinkToken?: Maybe<Scalars['String']>;
  platformUpdaterJobs: JobMetaDataPageResult;
  recruiters?: Maybe<Array<Maybe<Recruiter>>>;
  salaryCalculation?: Maybe<SalaryCalculation>;
  salaryCalculationMinimumWage?: Maybe<SalaryCalculationMinimumWage>;
  salaryCalculationParams?: Maybe<Array<AvailableSalaryCalculationParams>>;
  salaryCalculationPdf?: Maybe<FileLink>;
  supportedCountries?: Maybe<Array<Maybe<Country>>>;
  syncStatus?: Maybe<CompanySyncStatus>;
  syncSummaryResultDownload?: Maybe<SyncSummaryResultDownloadOutput>;
  templates?: Maybe<Array<Maybe<Template>>>;
  timeOffContractRequirements?: Maybe<TimeOffContractRequirements>;
  timeOffPolicyAssignmentDetails?: Maybe<TimeOffPolicyAssignmentDetailsResult>;
  timeOffSummaries?: Maybe<TimeOffSummariesResponse>;
  timeOffs?: Maybe<TimeOffsResponse>;
  timesheetConfigs?: Maybe<TimesheetConfigs>;
  timesheetReport?: Maybe<DocumentReadable>;
  timesheetWithPagination?: Maybe<TimesheetResult>;
  validateIntegrationCredentials?: Maybe<TaskResponse>;
  visaDocumentRequirements: Array<LegalDocumentRequirement>;
};

export type Query_GetPaymentInstructionDocumentArgs = {
  paymentInstructionId: Scalars['ID'];
};

export type Query_GetPaymentInstructionDocumentV2Args = {
  paymentInstructionId: Scalars['ID'];
};

export type Query_GetPaymentInstructionFileArgs = {
  paymentInstructionId: Scalars['ID'];
};

export type Query_BenefitArgs = {
  id: Scalars['String'];
};

export type Query_BenefitActivityLogsArgs = {
  filters: ActivityDocumentFilter;
};

export type Query_BenefitPackageCostArgs = {
  id: Scalars['String'];
};

export type Query_BenefitPackageCostsArgs = {
  filters?: InputMaybe<BenefitPackageCostFilters>;
};

export type Query_BenefitPartnerAddOperationTemplateArgs = {
  input: BenefitPartnerDataDownloadInput;
};

export type Query_BenefitPartnerCountriesArgs = {
  input?: InputMaybe<BenefitPartnerCountryFilters>;
};

export type Query_BenefitPartnerCountriesWithPaginationArgs = {
  filters?: InputMaybe<BenefitPartnerCountryFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_BenefitPartnerCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_BenefitPartnerCountryCheckExpirationStatusArgs = {
  id: Scalars['ID'];
};

export type Query_BenefitPartnerDataDownloadArgs = {
  input: BenefitPartnerDataDownloadInput;
};

export type Query_BenefitPartnerEditOperationSheetDownloadArgs = {
  input: BenefitPartnerDataDownloadInput;
};

export type Query_BenefitsArgs = {
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<BenefitFilters>;
  type?: InputMaybe<BenefitType>;
};

export type Query_BulkOnboardingTemplateDownloadArgs = {
  options: BulkOnboardingOptions;
};

export type Query_CompaniesArgs = {
  filters?: InputMaybe<CompanyFilters>;
};

export type Query_CompaniesWithPaginationArgs = {
  countRequest?: InputMaybe<CompaniesCountRequest>;
  filters?: InputMaybe<CompanyFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyChargebackPeriodArgs = {
  companyId: Scalars['ID'];
};

export type Query_CompanyGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyHasDuplicatedDomainArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyHasIntegrationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyIntegrationArgs = {
  companyId: Scalars['ID'];
};

export type Query_CompanyIntegrationInfoArgs = {
  integrationId: Scalars['ID'];
};

export type Query_CompanyOfferingPricingArgs = {
  filter: CompanyOfferingPricingFilter;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportArgs = {
  input: CompanyPayableInvoiceSourceReportExportInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportByHashArgs = {
  input: InvoiceSourceReportByHashInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportByInvoiceIdArgs = {
  input: InvoiceSourceReportByInvoiceIdInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportFromHashArgs = {
  input: InvoiceSourceReportHashInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportFromLinkArgs = {
  input: InvoiceSourceReportFromLinkInput;
};

export type Query_CompanyPayableDownloadInvoiceSourceReportsArgs = {
  input: CompanyInvoiceSourceReportInput;
};

export type Query_CompanyPayableInvoiceSourceDetailedReportArgs = {
  input: CompanyInvoiceSourceReportInput;
};

export type Query_CompanyPayableInvoiceSourceDetailedReportFetchArgs = {
  input: CompanyPayableInvoiceSourceReportExportInput;
};

export type Query_CompanyPayableInvoiceSourceReportArgs = {
  input: CompanyInvoiceSourceReportInput;
};

export type Query_CompanyPayablesWithPaginationArgs = {
  filters?: InputMaybe<CompanyPayableFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanyPayrollArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type Query_CompanyPayrollCycleArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyPayrollCycleReportDownloadArgs = {
  filter: CompanyPayrollCycleReportFilter;
};

export type Query_CompanyPayrollCycleReportsArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollCyclesArgs = {
  filter: CompanyPayrollCycleFilter;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanyPayrollInputConfirmRulesArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollInputReportDownloadArgs = {
  filter: CompanyPayrollInputReportFilter;
};

export type Query_CompanyPayrollMemberPaysArgs = {
  pageRequest?: InputMaybe<PageRequest>;
  payrollFilter: CompanyPayrollFilter;
};

export type Query_CompanyPayrollOutputConfirmRulesArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollPayslipPublishRulesArgs = {
  companyPayrollCycleId: Scalars['ID'];
};

export type Query_CompanyPayrollReportDownloadArgs = {
  filter: CompanyPayrollReportInput;
};

export type Query_CompanyPrePopulatedPaySupplementReportArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_CompanyQuotationArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyQuotationsArgs = {
  filters?: InputMaybe<CompanyQuotationFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CompanySyncArgs = {
  id: Scalars['ID'];
};

export type Query_CompensationComponentsArgs = {
  entityId: Scalars['ID'];
};

export type Query_CompensationStructureDefinitionArgs = {
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<ContractTerm>;
  type?: InputMaybe<ContractType>;
};

export type Query_ComplianceParamsDefinitionArgs = {
  contractType: ContractType;
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<ContractTerm>;
};

export type Query_ContractArgs = {
  id: Scalars['ID'];
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
  isApprovalRelated?: InputMaybe<Scalars['Boolean']>;
};

export type Query_ContractBenefitBannersArgs = {
  filters?: InputMaybe<ContractBenefitBannerFilters>;
};

export type Query_ContractBenefitChangeRequestsArgs = {
  filters?: InputMaybe<ContractBenefitChangeRequestFilters>;
};

export type Query_ContractBenefitDocumentArgs = {
  id: Scalars['ID'];
};

export type Query_ContractBenefitReportArgs = {
  id: Scalars['ID'];
};

export type Query_ContractBenefitsReportArgs = {
  filters: ContractBenefitFilters;
};

export type Query_ContractBenefitsWithPaginationArgs = {
  filters?: InputMaybe<ContractBenefitWithPaginationFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ContractDetailsReportArgs = {
  filters?: InputMaybe<ContractFilters>;
};

export type Query_ContractJobCodeArgs = {
  filter: JobCodeFilters;
};

export type Query_ContractPayrollDetailsReportArgs = {
  filters?: InputMaybe<ContractFilters>;
};

export type Query_ContractSnapshotsArgs = {
  filter?: InputMaybe<ContractSnapshotFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ContractsArgs = {
  filters?: InputMaybe<ContractFilters>;
  onboardingOperationsUserId?: InputMaybe<Scalars['ID']>;
  onboardingStatus?: InputMaybe<ContractOnboardingStatus>;
};

export type Query_ContractsWithPaginationArgs = {
  countRequest?: InputMaybe<ContractsCountRequest>;
  filters?: InputMaybe<ContractFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ConvertCurrencyArgs = {
  params: CurrencyConversionParams;
};

export type Query_CountriesArgs = {
  countries?: InputMaybe<Array<InputMaybe<CountryCode>>>;
  country?: InputMaybe<CountryCode>;
  mainCurrency?: InputMaybe<CurrencyCode>;
};

export type Query_CountryArgs = {
  country: CountryCode;
};

export type Query_CountryAllowancesConfigArgs = {
  country: CountryCode;
};

export type Query_CountryCallingCodesArgs = {
  filters?: InputMaybe<CountryCallingCodeFilters>;
};

export type Query_CountryLegalDataArgs = {
  country: CountryCode;
};

export type Query_CreditNotesWithPaginationArgs = {
  input?: InputMaybe<CreditNoteQueryInput>;
};

export type Query_CurrencyArgs = {
  code?: InputMaybe<CurrencyCode>;
};

export type Query_CustomPayrollReportArgs = {
  id: Scalars['ID'];
};

export type Query_CustomPayrollReportConfigsArgs = {
  filter: CustomPayrollReportConfigFilter;
};

export type Query_CustomPayrollReportsArgs = {
  filter: CustomPayrollReportFilter;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_CustomerIntegrationDefinitionsArgs = {
  filters?: InputMaybe<CustomerIntegrationFilter>;
};

export type Query_CycleContractsArgs = {
  cycleId: Scalars['ID'];
  filter?: InputMaybe<CycleContractsFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_DataChangeReportArgs = {
  filter?: InputMaybe<DataChangeFilter>;
};

export type Query_DepartmentsArgs = {
  filters?: InputMaybe<DepartmentFilters>;
};

export type Query_DisputesArgs = {
  filter?: InputMaybe<DisputeFilter>;
  page?: InputMaybe<PageRequest>;
};

export type Query_DownloadCompanyPayableReportArgs = {
  input: DownloadCompanyPayableReportInput;
};

export type Query_DownloadCustomPayrollReportArgs = {
  id: Scalars['ID'];
};

export type Query_DownloadPayrollReconciliationDataArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_DownloadSalaryInvoiceSourceReportArgs = {
  input: SalaryInvoiceSourceReportDownloadInput;
};

export type Query_EligibleCountriesForEorArgs = {
  payrollCycleEntityTypes: Array<PayrollCycleEntityType>;
};

export type Query_EligibleEntitiesForPeoArgs = {
  payrollCycleEntityType: PayrollCycleEntityType;
};

export type Query_EmailTemplateArgs = {
  id: Scalars['ID'];
};

export type Query_EmailTemplateByTypeArgs = {
  type: Scalars['String'];
};

export type Query_EmployeePricingForCountryArgs = {
  country?: InputMaybe<CountryCode>;
};

export type Query_EmployeePricingForRegionArgs = {
  region?: InputMaybe<Region>;
};

export type Query_EquipmentIncompleteProcurementsDownloadArgs = {
  filter?: InputMaybe<EquipmentProcurementFilter>;
};

export type Query_EquipmentsDownloadArgs = {
  filter?: InputMaybe<EquipmentFilter>;
};

export type Query_ExpenseArgs = {
  id: Scalars['ID'];
};

export type Query_ExpenseCategoriesArgs = {
  filter?: InputMaybe<ExpenseCategoryFilter>;
};

export type Query_ExpenseValidationInfoArgs = {
  input: ExpenseValidationInfoInput;
};

export type Query_ExpensesArgs = {
  filters?: InputMaybe<ExpenseFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_ExpensesReportArgs = {
  filters: ExpenseFilters;
};

export type Query_FeeComponentDefinitionArgs = {
  filter: FeeComponentDefinitionFilter;
};

export type Query_FeeComponentTierDefinitionArgs = {
  offeringCode?: InputMaybe<OfferingCode>;
};

export type Query_FileDownloadHistoryArgs = {
  filter: FileDownloadHistoryFilter;
};

export type Query_FilteredDataChangeArgs = {
  filter?: InputMaybe<DataChangeFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_FinancialTransactionInspectArgs = {
  transactionId: Scalars['String'];
};

export type Query_GenerateSalaryInvoiceSourceReportsArgs = {
  input: SalaryInvoiceSourceReportGenerationInput;
};

export type Query_GetAllApproversArgs = {
  category: ApprovalCategory;
  itemId: Scalars['ID'];
};

export type Query_GetApplicableExceptionCategoriesArgs = {
  request?: InputMaybe<PayrollContractExceptionReasonCategoryRequest>;
};

export type Query_GetAvailableReportsArgs = {
  input?: InputMaybe<ReportId>;
};

export type Query_GetCompanyPayInMethodArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_GetCompanyPrefillDataArgs = {
  input?: InputMaybe<CompanyPreFillDataInput>;
};

export type Query_GetComponentAccessibilityRulesArgs = {
  contractID?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<MemberExperiencePage>;
};

export type Query_GetEmployeesToSendInviteToArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetEmployeesToSyncArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetEmployeesToSyncEorArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetExternalLeaveTypesArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetIntegrationEntityMappingStatusArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetIntegrationFieldsMappingArgs = {
  entityId: Scalars['ID'];
  integrationId: Scalars['ID'];
};

export type Query_GetInternalLeaveTypesArgs = {
  companyId: Scalars['ID'];
};

export type Query_GetLastWorkingDayRangeArgs = {
  contractId: Scalars['ID'];
};

export type Query_GetLatestEmployeesForImportArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetLatestSyncResultForIntegrationArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetLeaveTypeMappingDefinitionArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetMemberPersonalDetailsAccessibilityRulesArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
};

export type Query_GetPaymentOptionsArgs = {
  filters: PaymentOptionFilters;
};

export type Query_GetPayrollReconciliationDataArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_GetPendingEmployeesToSyncArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetPositionsForIntegrationsArgs = {
  integrationId: Scalars['ID'];
};

export type Query_GetReportArgs = {
  input?: InputMaybe<Scalars['String']>;
};

export type Query_GetSepaibanDetailsFieldInformationArgs = {
  iban?: InputMaybe<Scalars['String']>;
};

export type Query_GetTentativeLastWorkingDayArgs = {
  contractId: Scalars['ID'];
};

export type Query_GetTimeOffsForPayrollArgs = {
  input: PayrollTimeOffFilter;
};

export type Query_HolidaysArgs = {
  countries: Array<InputMaybe<CountryCode>>;
  date?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type Query_InsuranceCoverageGroupsArgs = {
  input: BenefitPartnerCountryFilters;
};

export type Query_IsNonHrisManagerArgs = {
  companyUserId: Scalars['ID'];
};

export type Query_KybArgs = {
  id: Scalars['ID'];
};

export type Query_KybListArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Query_ManagerArgs = {
  filters: ManagerFilters;
};

export type Query_ManagerDirectReportsRuleArgs = {
  managerId: Scalars['ID'];
};

export type Query_MemberArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_MemberPayableCompanyInvoicesArgs = {
  filters?: InputMaybe<MemberPayableCompanyInvoiceFilters>;
};

export type Query_MemberPayableDownloadInvoiceSourceReportArgs = {
  input: MemberPayableInvoiceSourceReportExportInput;
};

export type Query_MemberPayableReportArgs = {
  filters?: InputMaybe<MemberPayableReportFilter>;
};

export type Query_MemberPayrollCycleDetailArgs = {
  filter: MemberPayrollCycleDetailFilter;
};

export type Query_MemberPayrollCyclesArgs = {
  filter: MemberPayrollCycleFilter;
};

export type Query_MembersWithPaginationArgs = {
  input?: InputMaybe<MembersInput>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_MsaAddendumDraftSampleArgs = {
  msaAddendumInput: MsaAddendumInput;
};

export type Query_OffboardingExperienceStateArgs = {
  contractId: Scalars['ID'];
};

export type Query_OnboardingArgs = {
  legalEntityId?: InputMaybe<Scalars['ID']>;
  type: ContractType;
};

export type Query_OnboardingAuditArgs = {
  filter: OnboardingAuditFilter;
};

export type Query_OperationsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_OperationsUsersArgs = {
  roles?: InputMaybe<Array<OperationsUserRole>>;
};

export type Query_OrgDirectoryArgs = {
  filters: OrgDirectoryFilters;
};

export type Query_OrgHierarchyArgs = {
  companyId: Scalars['ID'];
};

export type Query_OrgRoleAvailableManagersArgs = {
  companyId: Scalars['ID'];
};

export type Query_OrgRolesArgs = {
  filter: OrgRoleFilters;
};

export type Query_PartnerArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Query_PartnerPayrollArgs = {
  countryCode?: InputMaybe<CountryCode>;
  partnerId?: InputMaybe<Scalars['ID']>;
  payrollMonth?: InputMaybe<PayrollMonthInput>;
};

export type Query_PartnerPayrollSchemaArgs = {
  input: PartnerPayrollSchemaInput;
};

export type Query_PartnerPayrollsArgs = {
  input?: InputMaybe<GetPartnerPayrollInput>;
};

export type Query_PartnersArgs = {
  capability?: InputMaybe<PartnerCapability>;
  country?: InputMaybe<CountryCode>;
};

export type Query_PaySupplementArgs = {
  id: Scalars['ID'];
};

export type Query_PaySupplementsArgs = {
  filters?: InputMaybe<PaySupplementFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PaySupplementsReportArgs = {
  filters: PaySupplementFilters;
};

export type Query_PaymentAccountArgs = {
  id: Scalars['ID'];
};

export type Query_PaymentBundlePreviewArgs = {
  items?: InputMaybe<Array<InputMaybe<PaymentBundlePreviewItemInput>>>;
};

export type Query_PaymentBundlesArgs = {
  filters?: InputMaybe<PaymentBundleFilters>;
};

export type Query_PayrollBulkUploadConfirmRulesArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollBulkUploadConfirmStatusArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollBulkUploadCreateRulesArgs = {
  fileName?: InputMaybe<Scalars['String']>;
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollBulkUploadTemplateArgs = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type Query_PayrollBulkUploadValidateRulesArgs = {
  payrollCycleId: Scalars['ID'];
};

export type Query_PayrollConfigsWithNextPayrollCyclesArgs = {
  companyLegalEntityId: Scalars['ID'];
  multiplierLegalEntityId?: InputMaybe<Scalars['ID']>;
  payFrequency?: InputMaybe<PayFrequency>;
};

export type Query_PayrollContractArgs = {
  filter: PayrollContractFilter;
};

export type Query_PayrollCycleArgs = {
  id: Scalars['ID'];
};

export type Query_PayrollCycleConfigsArgs = {
  filter?: InputMaybe<PayrollCycleConfigsFilter>;
};

export type Query_PayrollCycleForDomainArgs = {
  contractId: Scalars['ID'];
  domain: PayrollInputDomainType;
  domainId?: InputMaybe<Scalars['ID']>;
  referenceDate?: InputMaybe<Scalars['Date']>;
  returnClosestToReferenceDateUpcomingCutOff: Scalars['Boolean'];
};

export type Query_PayrollCyclePaymentArgs = {
  id: Scalars['ID'];
};

export type Query_PayrollCyclePaymentsArgs = {
  filters?: InputMaybe<PayrollCyclePaymentFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PayrollCyclesArgs = {
  filter?: InputMaybe<PayrollCycleFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PayrollCyclesForCalendarArgs = {
  filter: PayrollCalendarFilters;
};

export type Query_PayrollDocumentsArgs = {
  cycleId: Scalars['ID'];
  filter?: InputMaybe<CycleContractsFilter>;
};

export type Query_PayrollFileDownloadArgs = {
  fileDownloadId: Scalars['ID'];
};

export type Query_PayrollInputFileArgs = {
  filters?: InputMaybe<ContractFilters>;
};

export type Query_PayrollInputFileDownloadArgs = {
  payrollCycleId?: InputMaybe<Scalars['ID']>;
};

export type Query_PayrollInputFilePartnerBulkDownloadArgs = {
  filter?: InputMaybe<PayrollCycleFilter>;
};

export type Query_PayrollParserConfigArgs = {
  filter?: InputMaybe<PayrollParserConfigFilter>;
};

export type Query_PayrollPaymentBatchArgs = {
  id: Scalars['ID'];
};

export type Query_PayrollPaymentBatchesArgs = {
  filters?: InputMaybe<PayrollPaymentBatchFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PayrollReportDownloadArgs = {
  filter: CompanyPayrollReportExportInput;
};

export type Query_PayrollReportDownloadForCustomerArgs = {
  input: Scalars['String'];
};

export type Query_PayrollReportGeneratorConfigArgs = {
  filter?: InputMaybe<PayrollReportGeneratorConfigFilter>;
};

export type Query_PayrollReportsArgs = {
  pageRequest?: InputMaybe<PageRequest>;
  payrollFilter?: InputMaybe<PayrollFilter>;
};

export type Query_PayslipsArgs = {
  filters?: InputMaybe<PaySlipFilters>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_PerformanceReviewNotesArgs = {
  input?: InputMaybe<PerformanceReviewNoteInput>;
};

export type Query_PlatformMergeDevLinkTokenArgs = {
  companyId: Scalars['ID'];
  platformId: Scalars['ID'];
};

export type Query_PlatformUpdaterJobsArgs = {
  input: PlatformJobMetaDataFilter;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_RecruitersArgs = {
  filters?: InputMaybe<RecruiterFilters>;
};

export type Query_SalaryCalculationArgs = {
  params?: InputMaybe<SalaryCalculationParam>;
};

export type Query_SalaryCalculationMinimumWageArgs = {
  params: SalaryCalculationMinimumWageParam;
};

export type Query_SalaryCalculationParamsArgs = {
  country?: InputMaybe<CountryCode>;
  payableType?: InputMaybe<Array<CalculationPayableType>>;
};

export type Query_SalaryCalculationPdfArgs = {
  params?: InputMaybe<SalaryCalculationParam>;
};

export type Query_SupportedCountriesArgs = {
  contractType: ContractType;
};

export type Query_SyncStatusArgs = {
  integrationId: Scalars['ID'];
  syncType?: InputMaybe<SyncType>;
};

export type Query_SyncSummaryResultDownloadArgs = {
  input: SyncSummaryResultDownloadInput;
};

export type Query_TemplatesArgs = {
  filter: TemplateFilter;
};

export type Query_TimeOffContractRequirementsArgs = {
  contractId: Scalars['ID'];
};

export type Query_TimeOffPolicyAssignmentDetailsArgs = {
  policyId: Scalars['ID'];
};

export type Query_TimeOffSummariesArgs = {
  filter?: InputMaybe<TimeOffSummaryFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_TimeOffsArgs = {
  filter?: InputMaybe<TimeOffFilter>;
  pageRequest?: InputMaybe<PageRequest>;
};

export type Query_TimesheetConfigsArgs = {
  filters?: InputMaybe<TimesheetConfigFilters>;
};

export type Query_TimesheetReportArgs = {
  filters: TimesheetFilters;
};

export type Query_TimesheetWithPaginationArgs = {
  filters: TimesheetFilters;
  pageRequest: PageRequest;
};

export type Query_ValidateIntegrationCredentialsArgs = {
  integrationId: Scalars['ID'];
};

export type Query_VisaDocumentRequirementsArgs = {
  categories: Array<LegalDocumentCategory>;
  destinationCountry: CountryCode;
  originCountry: CountryCode;
};

export type QuotationCostCalculation = {
  __typename?: 'QuotationCostCalculation';
  entryGroups?: Maybe<Array<Maybe<QuotationCostEntryGroup>>>;
  total?: Maybe<QuotationCostEntry>;
};

export type QuotationCostEntry = {
  __typename?: 'QuotationCostEntry';
  amountInBillingCurrency?: Maybe<AmountWithCurrency>;
  amountInLocalCurrency?: Maybe<AmountWithCurrency>;
  name?: Maybe<Scalars['String']>;
};

export type QuotationCostEntryGroup = {
  __typename?: 'QuotationCostEntryGroup';
  entries?: Maybe<Array<Maybe<QuotationCostEntry>>>;
  total?: Maybe<QuotationCostEntry>;
};

export type QuotationCostOverview = {
  __typename?: 'QuotationCostOverview';
  currency?: Maybe<CurrencyCode>;
  totalAnnualCost?: Maybe<Scalars['Float']>;
  totalMemberCount?: Maybe<Scalars['Int']>;
  totalMemberDeposit?: Maybe<Scalars['Float']>;
  totalMonthlyCost?: Maybe<Scalars['Float']>;
};

export type QuotationCountryWiseCostBreakdown = {
  __typename?: 'QuotationCountryWiseCostBreakdown';
  annually?: Maybe<QuotationCostCalculation>;
  country?: Maybe<CountryCode>;
  memberDeposit?: Maybe<AmountWithCurrency>;
  monthly?: Maybe<QuotationCostCalculation>;
};

export type QuotationCountryWiseCostSummary = {
  __typename?: 'QuotationCountryWiseCostSummary';
  annually?: Maybe<Scalars['Float']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<CurrencyCode>;
  memberCount?: Maybe<Scalars['Int']>;
  memberDeposit?: Maybe<Scalars['Float']>;
  monthly?: Maybe<Scalars['Float']>;
};

export enum QuotationDiscountPlanType {
  ANNUAL_PAYMENT = 'ANNUAL_PAYMENT',
}

export enum QuotationDiscountType {
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
}

export type QuotationMemberDiscountInput = {
  amount: Scalars['Float'];
  currency: CurrencyCode;
  discountType: QuotationDiscountType;
  planType: QuotationDiscountPlanType;
};

export type QuotationMemberDiscountInputData = {
  __typename?: 'QuotationMemberDiscountInputData';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCode>;
  discountType?: Maybe<QuotationDiscountType>;
  planType?: Maybe<QuotationDiscountPlanType>;
};

export type QuotationMemberInput = {
  benefitPackageId?: InputMaybe<Scalars['String']>;
  depositAmount?: InputMaybe<Scalars['Float']>;
  depositAmountCurrency?: InputMaybe<CurrencyCode>;
  discounts?: InputMaybe<Array<InputMaybe<QuotationMemberDiscountInput>>>;
  /** @deprecated Use grossSalary and grossSalaryFrequency */
  grossMonthly?: InputMaybe<Scalars['Float']>;
  grossSalary?: InputMaybe<Scalars['Float']>;
  grossSalaryFrequency?: InputMaybe<RateFrequency>;
  managementFee?: InputMaybe<Scalars['Float']>;
  managementFeeCurrency?: InputMaybe<CurrencyCode>;
  memberCountry: CountryCode;
  memberCountryState?: InputMaybe<Scalars['String']>;
  memberCurrency: CurrencyCode;
};

export type QuotationMemberInputData = {
  __typename?: 'QuotationMemberInputData';
  benefitPackageId?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['Float']>;
  depositAmountCurrency?: Maybe<CurrencyCode>;
  discounts?: Maybe<Array<Maybe<QuotationMemberDiscountInputData>>>;
  /** @deprecated Use grossSalary and grossSalaryFrequency */
  grossMonthly?: Maybe<Scalars['Float']>;
  grossSalary?: Maybe<Scalars['Float']>;
  grossSalaryFrequency?: Maybe<RateFrequency>;
  managementFee?: Maybe<Scalars['Float']>;
  managementFeeCurrency?: Maybe<CurrencyCode>;
  memberCountry?: Maybe<CountryCode>;
  memberCountryState?: Maybe<Scalars['String']>;
  memberCurrency?: Maybe<CurrencyCode>;
};

export type QuotationMemberWiseSummary = {
  __typename?: 'QuotationMemberWiseSummary';
  benefitsCost?: Maybe<Scalars['Float']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<CurrencyCode>;
  managementFee?: Maybe<Scalars['Float']>;
  monthlyPayrollCost?: Maybe<Scalars['Float']>;
  totalAnnualCost?: Maybe<Scalars['Float']>;
  totalMonthlyCost?: Maybe<Scalars['Float']>;
};

export enum RateFrequency {
  ANNUALLY = 'ANNUALLY',
  BI_MONTHLY = 'BI_MONTHLY',
  BI_WEEKLY = 'BI_WEEKLY',
  CUSTOM = 'CUSTOM',
  DAILY = 'DAILY',
  HALFYEARLY = 'HALFYEARLY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
  QUATERLY = 'QUATERLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  TRI_ANNUALLY = 'TRI_ANNUALLY',
  WEEKLY = 'WEEKLY',
}

export enum RateType {
  CTC = 'CTC',
  GROSS = 'GROSS',
  NET = 'NET',
}

export enum ReconciliationCategory {
  ACTIVE_MEMBER_INVALID = 'ACTIVE_MEMBER_INVALID',
  ACTIVE_MEMBER_MISSING = 'ACTIVE_MEMBER_MISSING',
  EXPENSE_INVALID = 'EXPENSE_INVALID',
  EXPENSE_MISMATCH = 'EXPENSE_MISMATCH',
  EXPENSE_MISSING = 'EXPENSE_MISSING',
  MEMBER_INVALID = 'MEMBER_INVALID',
  MEMBER_MISSING = 'MEMBER_MISSING',
  NEW_HIRE_MEMBER_INVALID = 'NEW_HIRE_MEMBER_INVALID',
  NEW_HIRE_MEMBER_MISSING = 'NEW_HIRE_MEMBER_MISSING',
  SALARY_INVALID = 'SALARY_INVALID',
  SALARY_MISMATCH = 'SALARY_MISMATCH',
  SALARY_MISSING = 'SALARY_MISSING',
  TERMINATING_MEMBER_INVALID = 'TERMINATING_MEMBER_INVALID',
  TERMINATING_MEMBER_MISSING = 'TERMINATING_MEMBER_MISSING',
}

export enum ReconciliationGroup {
  EXPENSE = 'EXPENSE',
  MEMBER = 'MEMBER',
  PAY_SUPPLEMENT = 'PAY_SUPPLEMENT',
  SALARY = 'SALARY',
}

export type ReconciliationResult = {
  __typename?: 'ReconciliationResult';
  details?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  messageKey?: Maybe<Scalars['String']>;
  type?: Maybe<ReconciliationResultType>;
};

export enum ReconciliationResultSeverity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum ReconciliationResultStatus {
  DISCARDED = 'DISCARDED',
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
}

export enum ReconciliationResultType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

export type Recruiter = {
  __typename?: 'Recruiter';
  description?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailAddress>>>;
  features?: Maybe<Array<Maybe<RecruiterFeature>>>;
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<DocumentReadable>;
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<PhoneNumber>>>;
};

export type RecruiterFeature = {
  __typename?: 'RecruiterFeature';
  category?: Maybe<FeatureCategory>;
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecruiterFeatureInput = {
  category: FeatureCategory;
  values: Array<InputMaybe<Scalars['String']>>;
};

export type RecruiterFilters = {
  features?: InputMaybe<Array<InputMaybe<RecruiterFeatureInput>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RecruiterInput = {
  description?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<InputMaybe<EmailAddressInput>>>;
  features?: InputMaybe<Array<InputMaybe<RecruiterFeatureInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<InputMaybe<PhoneNumberInput>>>;
};

export type RefreshCompanyPayrollCycleInput = {
  companyPayrollCycleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RegenerateAgreementInput = {
  contractId: Scalars['ID'];
  createNewDocument?: InputMaybe<Scalars['Boolean']>;
  ignoreStatusCheck?: InputMaybe<Scalars['Boolean']>;
  preferredContractAgreementTemplate?: InputMaybe<Scalars['ID']>;
};

export type RegenerateOrderFormInput = {
  contractId: Scalars['ID'];
};

export enum Region {
  AFRICA = 'AFRICA',
  ASIA = 'ASIA',
}

export type RegionEmployeePricing = EmployeePricing & {
  __typename?: 'RegionEmployeePricing';
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  region?: Maybe<Region>;
};

export enum Relationship {
  DAUGHTER = 'DAUGHTER',
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  SON = 'SON',
  SPOUSE = 'SPOUSE',
}

export type ReportComparisonInput = {
  payrollMonth: PayrollMonthInput;
};

export type ReportConfig = {
  __typename?: 'ReportConfig';
  description?: Maybe<Scalars['String']>;
  filters: Array<Maybe<Filter>>;
  id?: Maybe<Scalars['String']>;
  reportSource?: Maybe<ReportSource>;
  title?: Maybe<Scalars['String']>;
};

export type ReportGeneratorConfig = {
  __typename?: 'ReportGeneratorConfig';
  configString?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ReportId = {
  id?: InputMaybe<Scalars['String']>;
};

export enum ReportSource {
  DOMAIN_SPECIFIC = 'DOMAIN_SPECIFIC',
  HYBRID = 'HYBRID',
  WAREHOUSE = 'WAREHOUSE',
}

export type ReportsToValidationResult = {
  __typename?: 'ReportsToValidationResult';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Request = {
  __typename?: 'Request';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  recipient?: Maybe<RequestUser>;
  requester?: Maybe<RequestUser>;
  status?: Maybe<RequestStatus>;
  type?: Maybe<RequestType>;
};

export enum RequestStatus {
  REQUESTED = 'REQUESTED',
  RESOLVED = 'RESOLVED',
}

export enum RequestType {
  GENERATE_MSA = 'GENERATE_MSA',
}

export type RequestUser = CompanyUser | OperationsUser;

export type RequirementField = {
  __typename?: 'RequirementField';
  allowedValues?: Maybe<Array<Maybe<AllowedValue>>>;
  errorMessage?: Maybe<Scalars['String']>;
  hasSubFields?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  regex?: Maybe<Scalars['String']>;
  subFields?: Maybe<Array<Maybe<RequirementSubField>>>;
  type?: Maybe<Scalars['String']>;
};

export type RequirementFieldValue = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type RequirementFiltersInput = {
  applyTo?: InputMaybe<LegalDataRequirementApplicability>;
  category?: InputMaybe<LegalDocumentCategory>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
  financialYear?: InputMaybe<Scalars['Int']>;
  joinDate?: InputMaybe<Scalars['Date']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
};

export type RequirementSubField = {
  __typename?: 'RequirementSubField';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<RequirementField>>>;
};

export type RequirementsInputs = {
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
};

export type ResolveDisputeInput = {
  disputeId: Scalars['ID'];
};

export enum RiskAssessmentStatus {
  COMPLETED = 'COMPLETED',
  INITIATED = 'INITIATED',
  OUTDATED = 'OUTDATED',
}

export type Rule = {
  __typename?: 'Rule';
  conditions?: Maybe<Array<Condition>>;
  type?: Maybe<RuleType>;
};

export type RuleInput = {
  conditions?: InputMaybe<Array<ConditionInput>>;
  type?: InputMaybe<RuleType>;
};

export enum RuleType {
  ALL = 'ALL',
  BY_CONDITION = 'BY_CONDITION',
}

export type SepaCompanyPayinMethod = CompanyPayInMethod &
  PayInMethod & {
    __typename?: 'SEPACompanyPayinMethod';
    bankDetails?: Maybe<Array<Maybe<CompanyPayInMethodBankDetails>>>;
    chargeBackPeriod?: Maybe<Scalars['Int']>;
    company?: Maybe<Company>;
    context?: Maybe<PayInMethodContext>;
    currentStatus?: Maybe<CompanyPayInMethodWorkflowStatus>;
    currentStep?: Maybe<PayInMethodDefinitionStep>;
    data?: Maybe<Array<Maybe<KeyValue>>>;
    documents?: Maybe<Array<Maybe<DocumentData>>>;
    id: Scalars['ID'];
    isActive?: Maybe<Scalars['Boolean']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    payInMethodType?: Maybe<PayInMethodType>;
    workflowSteps: Array<Maybe<CompanyPayInMethodWorkflowStep>>;
  };

export type SepaibanDetailsFieldInformationResponse = {
  __typename?: 'SEPAIBANDetailsFieldInformationResponse';
  data?: Maybe<Array<Maybe<KeyValue>>>;
};

export type SsoPreference = {
  __typename?: 'SSOPreference';
  company?: Maybe<Company>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identityProvider?: Maybe<IdentityProvider>;
};

export type SalaryCalculation = {
  __typename?: 'SalaryCalculation';
  companyPayable: Array<CalculationBreakdown>;
  employeePayable: Array<CalculationBreakdown>;
};

export type SalaryCalculationMinimumWage = {
  __typename?: 'SalaryCalculationMinimumWage';
  amount: Scalars['Float'];
  currency: CurrencyCode;
  frequency: RateFrequency;
};

export type SalaryCalculationMinimumWageParam = {
  country: CountryCode;
  currency?: InputMaybe<CurrencyCode>;
  frequency: RateFrequency;
  state?: InputMaybe<Scalars['String']>;
};

export type SalaryCalculationParam = {
  amount: Scalars['Float'];
  constantOverrides?: InputMaybe<Array<ConstantOverride>>;
  country: CountryCode;
  currency: CurrencyCode;
  frequency: RateFrequency;
  payableType: Array<CalculationPayableType>;
  rateType: RateType;
  state?: InputMaybe<Scalars['String']>;
};

export type SalaryCalculationRequestEmailParam = {
  amount: Scalars['Float'];
  country: CountryCode;
  currency: CurrencyCode;
  frequency: RateFrequency;
  payableType?: InputMaybe<Array<CalculationPayableType>>;
};

export type SalaryInvoiceSourceReportDownloadInput = {
  companyId: Scalars['ID'];
  companyPayableId?: InputMaybe<Scalars['ID']>;
  payableMonth: PayableMonthInput;
};

export type SalaryInvoiceSourceReportGenerationInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  payableMonth: PayableMonthInput;
};

export type SalaryReview = {
  __typename?: 'SalaryReview';
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  currentCompensation?: Maybe<Compensation>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  reviewDocument?: Maybe<DocumentReadable>;
  revisedCompensation?: Maybe<Compensation>;
  state?: Maybe<SalaryReviewStatus>;
};

export type SalaryReviewInput = {
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  effectiveDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  revisedCompensation: ContractUpdateCompensationInput;
};

export enum SalaryReviewStatus {
  ACTIVATED = 'ACTIVATED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DRAFT = 'DRAFT',
  SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
  SENT_FOR_MEMBER_APPROVAL = 'SENT_FOR_MEMBER_APPROVAL',
  SENT_TO_OPS = 'SENT_TO_OPS',
}

export type SaveIntegrationFieldsMappingInput = {
  entityId: Scalars['ID'];
  integrationId: Scalars['ID'];
  mapping?: InputMaybe<Array<InputMaybe<FieldMappingInput>>>;
};

export type SaveLeaveTypesMappingInput = {
  integrationId: Scalars['ID'];
  mapping?: InputMaybe<Array<InputMaybe<LeaveTypeMappingDefinitionInput>>>;
};

export type ScheduleTime = {
  __typename?: 'ScheduleTime';
  unit: ScheduleTimeUnit;
  value: Scalars['Int'];
};

export type ScheduleTimeInput = {
  unit: ScheduleTimeUnit;
  value: Scalars['Int'];
};

export enum ScheduleTimeUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export type SeatAssignInput = {
  contractId: Scalars['ID'];
  effectiveDate: Scalars['Date'];
  pricingPlanId: Scalars['ID'];
  seatId?: InputMaybe<Scalars['ID']>;
};

export type SeatConfigurationInput = {
  count: Scalars['Int'];
  country: CountryCode;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  workStatus: CountryWorkStatus;
};

export enum SeatEventType {
  CLOSE = 'CLOSE',
  FILL = 'FILL',
  OPEN = 'OPEN',
  RELEASE = 'RELEASE',
}

export type SeatReleaseInput = {
  contractId: Scalars['ID'];
  pricingPlanId: Scalars['ID'];
};

export enum SeatStatus {
  CLOSED = 'CLOSED',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
}

export enum Section {
  ALLOCATED_IT_ASSETS = 'ALLOCATED_IT_ASSETS',
  COMPENSATION = 'COMPENSATION',
  CONTRACT = 'CONTRACT',
  CONTRIBUTION = 'CONTRIBUTION',
  DOCUMENTS = 'DOCUMENTS',
  PERFORMANCE_REVIEW = 'PERFORMANCE_REVIEW',
  WORK_INFO = 'WORK_INFO',
}

export type SectionAccessibilityResponse = {
  __typename?: 'SectionAccessibilityResponse';
  accessibility?: Maybe<AccessibilityResponse>;
  section: MemberPersonalDetailsSection;
  visible?: Maybe<Scalars['Boolean']>;
};

export enum SemimonthlySegment {
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
}

export type SendEmailToOnboardingSpecialistResponse = {
  __typename?: 'SendEmailToOnboardingSpecialistResponse';
  message?: Maybe<Scalars['String']>;
  requesterEmail?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SendPayrollCycleForReviewInput = {
  payrollCycleId: Scalars['ID'];
};

export type SendReportForReviewInput = {
  country?: InputMaybe<CountryCode>;
  month?: InputMaybe<PayrollMonthInput>;
  partnerId?: InputMaybe<Scalars['ID']>;
};

export enum SeverancePayOption {
  FOUR_WEEKS = 'FOUR_WEEKS',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NO_SEVERANCE = 'NO_SEVERANCE',
  ONE_MONTH = 'ONE_MONTH',
  ONE_WEEK = 'ONE_WEEK',
  THREE_MONTHS = 'THREE_MONTHS',
  THREE_WEEKS = 'THREE_WEEKS',
  TWO_MONTHS = 'TWO_MONTHS',
  TWO_WEEKS = 'TWO_WEEKS',
}

export type Signature = {
  __typename?: 'Signature';
  employerSignatory?: Maybe<Scalars['String']>;
  employerSignedDate?: Maybe<Scalars['DateTime']>;
  memberSignedDate?: Maybe<Scalars['DateTime']>;
};

export type Sort = {
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum StateCode {
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  CALIFORNIA = 'CALIFORNIA',
  COLORADO = 'COLORADO',
  CONNECTICUT = 'CONNECTICUT',
  DELAWARE = 'DELAWARE',
  FLORIDA = 'FLORIDA',
  GEORGIA = 'GEORGIA',
  HAWAII = 'HAWAII',
  IDAHO = 'IDAHO',
  ILLINOIS = 'ILLINOIS',
  INDIANA = 'INDIANA',
  IOWA = 'IOWA',
  KANSAS = 'KANSAS',
  KENTUCKY = 'KENTUCKY',
  LOUISIANA = 'LOUISIANA',
  MAINE = 'MAINE',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MONTANA = 'MONTANA',
  NEBRASKA = 'NEBRASKA',
  NEVADA = 'NEVADA',
  NEWYORK = 'NEWYORK',
  NEW_HAMPSHIRE = 'NEW_HAMPSHIRE',
  NEW_JERSEY = 'NEW_JERSEY',
  NEW_MEXICO = 'NEW_MEXICO',
  NORTH_CAROLINA = 'NORTH_CAROLINA',
  NORTH_DAKOTA = 'NORTH_DAKOTA',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  OREGON = 'OREGON',
  PENNSYLVANIA = 'PENNSYLVANIA',
  RHODE_ISLAND = 'RHODE_ISLAND',
  SOUTH_CAROLINA = 'SOUTH_CAROLINA',
  SOUTH_DAKOTA = 'SOUTH_DAKOTA',
  TENNESSEE = 'TENNESSEE',
  TEXAS = 'TEXAS',
  UTAH = 'UTAH',
  VERMONT = 'VERMONT',
  VIRGINIA = 'VIRGINIA',
  WASHINGTON = 'WASHINGTON',
  WASHINGTON_DC = 'WASHINGTON_DC',
  WEST_VIRGINIA = 'WEST_VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING',
}

export type StatutoryReportDetails = CustomPayrollReportDetails & {
  __typename?: 'StatutoryReportDetails';
  company?: Maybe<Company>;
  country?: Maybe<CountryCode>;
  employingLegalEntity?: Maybe<LegalEntity>;
  files: Array<CustomPayrollReportFile>;
  financialYearFrom?: Maybe<Scalars['Date']>;
  financialYearTo?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  month?: Maybe<MonthYear>;
  quarter?: Maybe<Scalars['Int']>;
  reportCategory: CustomPayrollReportCategory;
  reportFrequency?: Maybe<CustomPayrollReportFrequency>;
  uploadedCount?: Maybe<UploadedFileCount>;
};

export enum Strategy {
  PANDA_DOC = 'PANDA_DOC',
  PDF_MONKEY = 'PDF_MONKEY',
  STATIC = 'STATIC',
}

export type StringConfigValue = {
  __typename?: 'StringConfigValue';
  value: Scalars['String'];
};

export type StringParam = {
  __typename?: 'StringParam';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SyncEorManuallyResult = {
  __typename?: 'SyncEORManuallyResult';
  integrationId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  syncId?: Maybe<Scalars['String']>;
};

export type SyncEmployees = {
  __typename?: 'SyncEmployees';
  employees?: Maybe<Array<Maybe<Employee>>>;
  extSyncId?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  syncId?: Maybe<Scalars['ID']>;
};

export enum SyncStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export type SyncSummaryResultDownloadInput = {
  syncId: Scalars['String'];
};

export type SyncSummaryResultDownloadOutput = {
  __typename?: 'SyncSummaryResultDownloadOutput';
  isDownloaded?: Maybe<Scalars['Boolean']>;
  reportFile?: Maybe<DocumentReadable>;
};

export enum SyncType {
  INCOMING = 'INCOMING',
  MANUAL_OUTGOING = 'MANUAL_OUTGOING',
  OUTGOING = 'OUTGOING',
}

export type TaskResponse = {
  __typename?: 'TaskResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Template = {
  __typename?: 'Template';
  contractId?: Maybe<Scalars['ID']>;
  country?: Maybe<CountryCode>;
  countryState?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  link?: Maybe<Scalars['String']>;
  metadata?: Maybe<TemplateMetadata>;
  strategy: Strategy;
  type: Scalars['String'];
  version: Scalars['Int'];
  workspace?: Maybe<Workspace>;
};

export type TemplateFilter = {
  contractId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  entityId?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Language>;
  stateCode?: InputMaybe<Scalars['String']>;
  strategy?: InputMaybe<Strategy>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type TemplateInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<CountryCode>;
  countryState?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['ID']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Language>;
  metadata?: InputMaybe<TemplateMetadataInput>;
  strategy: Strategy;
  type: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
  workspace?: InputMaybe<Workspace>;
};

export type TemplateMetadata = {
  __typename?: 'TemplateMetadata';
  languagesCount?: Maybe<Scalars['Int']>;
};

export type TemplateMetadataInput = {
  languagesCount?: InputMaybe<Scalars['Int']>;
};

export type TerminatePayrollWorkflowRequest = {
  payrollCycleIds: Array<Scalars['ID']>;
};

export type TextField = {
  __typename?: 'TextField';
  defaultValue?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TextFieldInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TimeOff = {
  __typename?: 'TimeOff';
  approverInfos?: Maybe<Array<ItemApproverInfo>>;
  approvers?: Maybe<Array<Maybe<Person>>>;
  /** @deprecated use endDate instead. https://app.clickup.com/t/2kchkk4 */
  backToWorkDate?: Maybe<TimeOffDate>;
  changeReason?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<TimeOffDate>;
  id: Scalars['ID'];
  isActionable?: Maybe<Scalars['Boolean']>;
  noOfDays?: Maybe<Scalars['Float']>;
  startDate?: Maybe<TimeOffDate>;
  status?: Maybe<TimeOffStatus>;
  type?: Maybe<TimeOffType>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type TimeOffAllocationInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  excludedContractIds?: InputMaybe<Array<Scalars['ID']>>;
  expiryDate?: InputMaybe<Scalars['Date']>;
};

export type TimeOffApprovalItem = ApprovalItem & {
  __typename?: 'TimeOffApprovalItem';
  approverUser?: Maybe<Array<Maybe<Person>>>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<ApprovalItemStatus>;
  timeOffId?: Maybe<Scalars['ID']>;
  timeOffItem?: Maybe<TimeOff>;
};

export type TimeOffContractRequirements = {
  __typename?: 'TimeOffContractRequirements';
  assignedEntitlements?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  availableEntitlements?: Maybe<Array<Maybe<ContractTimeOffEntitlement>>>;
  clause?: Maybe<Scalars['String']>;
};

export type TimeOffCreateInput = {
  /** deprecated, to be removed once FE completely migrates to `endDate` */
  backToWorkDate?: InputMaybe<TimeOffDateInput>;
  description?: InputMaybe<Scalars['String']>;
  /** to replace `backToWorkDate` - https://app.clickup.com/t/2kchkk4 */
  endDate?: InputMaybe<TimeOffDateInput>;
  /** id is optional. a) id=null -> submitting a new time off. b) id=123 -> submitting an existing DRAFT time off with id=123 */
  id?: InputMaybe<Scalars['ID']>;
  /** deprecated, don't use isDraft! The mutation will ignore isDraft and always submit for approval */
  isDraft?: InputMaybe<Scalars['Boolean']>;
  noOfDays: Scalars['Float'];
  startDate: TimeOffDateInput;
  type: Scalars['String'];
};

export type TimeOffDate = {
  __typename?: 'TimeOffDate';
  /** @deprecated All time values are always 00:00:00. Use `dateOnly` instead */
  date?: Maybe<Scalars['DateTime']>;
  dateOnly?: Maybe<Scalars['Date']>;
  session?: Maybe<TimeOffSession>;
};

export type TimeOffDateInput = {
  /** deprecated. All time values are always 00:00:00. Use `dateOnly` instead */
  date?: InputMaybe<Scalars['DateTime']>;
  dateOnly?: InputMaybe<Scalars['Date']>;
  session?: InputMaybe<TimeOffSession>;
};

export type TimeOffDuration = {
  __typename?: 'TimeOffDuration';
  unit?: Maybe<TimeOffUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type TimeOffDurationInput = {
  unit?: InputMaybe<TimeOffUnit>;
  value?: InputMaybe<Scalars['Float']>;
};

export type TimeOffFilter = {
  contractCountry?: InputMaybe<CountryCode>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatus?: InputMaybe<ContractStatus>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  startDateRange?: InputMaybe<DateRange>;
  timeOffStatus?: InputMaybe<Array<TimeOffStatus>>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type TimeOffFixedValidation = {
  __typename?: 'TimeOffFixedValidation';
  allowedContractStatuses?: Maybe<Array<Maybe<ContractStatus>>>;
  defaultValue?: Maybe<Scalars['Float']>;
  isUnlimitedLeavesAllowed?: Maybe<Scalars['Boolean']>;
  maximum?: Maybe<Scalars['Float']>;
  minimum?: Maybe<Scalars['Float']>;
  unit?: Maybe<TimeOffUnit>;
};

export type TimeOffInsightDefinition = InsightDefinition & {
  __typename?: 'TimeOffInsightDefinition';
  actions?: Maybe<Array<Maybe<InsightAction>>>;
  category?: Maybe<InsightCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  recommendedValue?: Maybe<Scalars['Float']>;
  timeOffTypeDefinition?: Maybe<TimeOffTypeDefinition>;
  timeOffUnit?: Maybe<TimeOffUnit>;
};

export type TimeOffPolicyAssignUsersInput = {
  contractIds: Array<Scalars['ID']>;
  timeOffPolicyId: Scalars['ID'];
  timeOffPolicyRule?: InputMaybe<RuleInput>;
  timeOffTypeId: Scalars['ID'];
};

export type TimeOffPolicyAssignmentDetailsResult = {
  __typename?: 'TimeOffPolicyAssignmentDetailsResult';
  assignedContracts?: Maybe<Array<Contract>>;
  rule?: Maybe<Rule>;
};

export type TimeOffPolicyCreateInput = {
  allocatedCount: TimeOffDurationInput;
  carryForwardConfig: CarryForwardConfigInput;
  isUnlimitedLeavesAllowed?: InputMaybe<Scalars['Boolean']>;
  policyName: Scalars['String'];
  timeOffTypeId: Scalars['ID'];
};

export type TimeOffPolicyUpdateInput = {
  allocatedCount: TimeOffDurationInput;
  carryForwardConfig?: InputMaybe<CarryForwardConfigInput>;
  id: Scalars['ID'];
  isUnlimitedLeavesAllowed?: InputMaybe<Scalars['Boolean']>;
  policyName: Scalars['String'];
};

export type TimeOffPolicyUsersValidationResult = {
  __typename?: 'TimeOffPolicyUsersValidationResult';
  invalidContractsToAssign: Array<Contract>;
  invalidContractsToUnassigned: Array<Contract>;
  validContractsToAssign: Array<Contract>;
};

export type TimeOffPolicyValidateUsersInput = {
  contractIds: Array<Scalars['ID']>;
  timeOffPolicyId: Scalars['ID'];
  timeOffTypeId: Scalars['ID'];
};

export type TimeOffReportInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type TimeOffRequirementFilters = {
  companyId?: InputMaybe<Scalars['ID']>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  country?: InputMaybe<CountryFilter>;
  term?: InputMaybe<ContractTerm>;
};

export type TimeOffRequirements = {
  __typename?: 'TimeOffRequirements';
  clause?: Maybe<Scalars['String']>;
  definitions?: Maybe<Array<Maybe<TimeOffTypeDefinition>>>;
};

export type TimeOffSaveAsDraftInput = {
  /** deprecated, to be removed once FE completely migrates to `endDate` */
  backToWorkDate?: InputMaybe<TimeOffDateInput>;
  /** contractId is optional. a) null -> this is a member. Always leave it null in member exp!; b) 123 -> this is a companyUser saving as draft a timeoff on behalf of contractId=123 */
  contractId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  /** to replace `backToWorkDate` - https://app.clickup.com/t/2kchkk4 */
  endDate?: InputMaybe<TimeOffDateInput>;
  noOfDays: Scalars['Float'];
  startDate: TimeOffDateInput;
  type: Scalars['String'];
};

export enum TimeOffSession {
  AFTERNOON = 'AFTERNOON',
  MORNING = 'MORNING',
}

export type TimeOffSnapshot = {
  __typename?: 'TimeOffSnapshot';
  description?: Maybe<Scalars['String']>;
  noOfDays?: Maybe<Scalars['Int']>;
  status?: Maybe<TimeOffStatus>;
  timeOffId: Scalars['ID'];
};

export enum TimeOffStatus {
  APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  /** @deprecated Time off was created. Don't use SUBMITTED. Use APPROVAL_IN_PROGRESS instead */
  SUBMITTED = 'SUBMITTED',
  TAKEN = 'TAKEN',
}

export type TimeOffSummariesResponse = {
  __typename?: 'TimeOffSummariesResponse';
  page: PageResult;
  timeOffSummaries?: Maybe<Array<TimeOffType>>;
};

export type TimeOffSummaryFilter = {
  contractCountry?: InputMaybe<CountryCode>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
};

export type TimeOffType = {
  __typename?: 'TimeOffType';
  allocated?: Maybe<Scalars['Float']>;
  carried?: Maybe<Scalars['Float']>;
  contract?: Maybe<Contract>;
  definition?: Maybe<TimeOffTypeDefinition>;
  entitled?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['Float']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  remaining?: Maybe<Scalars['Float']>;
  taken?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['ID']>;
};

export type TimeOffTypeCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  isPaidLeave: Scalars['Boolean'];
  name: Scalars['String'];
};

export type TimeOffTypeDefinition = {
  __typename?: 'TimeOffTypeDefinition';
  assignedEmployeeCount?: Maybe<Scalars['Int']>;
  assignedEntityNames?: Maybe<Array<Scalars['String']>>;
  basis?: Maybe<Scalars['String']>;
  configuration?: Maybe<TimeoffConfiguration>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  policyName?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  status?: Maybe<TimeOffTypeDefinitionStatus>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  validation?: Maybe<Array<Maybe<TimeOffValidation>>>;
};

export enum TimeOffTypeDefinitionStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TimeOffTypeFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isPaidLeave?: InputMaybe<Scalars['Boolean']>;
};

export type TimeOffTypeInfo = {
  __typename?: 'TimeOffTypeInfo';
  companyId?: Maybe<Scalars['ID']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  isPaidLeave?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  status?: Maybe<TimeOffTypeStatus>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  usage?: Maybe<Scalars['Int']>;
};

export enum TimeOffTypeStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TimeOffTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  isPaidLeave: Scalars['Boolean'];
  name: Scalars['String'];
  typeId: Scalars['ID'];
};

export enum TimeOffUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export type TimeOffUpdateInput = {
  /** deprecated, to be removed once FE completely migrates to `endDate` */
  backToWorkDate?: InputMaybe<TimeOffDateInput>;
  description?: InputMaybe<Scalars['String']>;
  /** to replace `backToWorkDate` - https://app.clickup.com/t/2kchkk4 */
  endDate?: InputMaybe<TimeOffDateInput>;
  /** deprecated, don't use isDraft! BE doesn't change the timeoff status in `timeOffUpdate` */
  isDraft?: InputMaybe<Scalars['Boolean']>;
  noOfDays: Scalars['Float'];
  startDate: TimeOffDateInput;
  type: Scalars['String'];
};

export type TimeOffValidation = TimeOffFixedValidation;

export type TimeOffsResponse = {
  __typename?: 'TimeOffsResponse';
  allCount: Scalars['Int'];
  approvedCount: Scalars['Int'];
  page: PageResult;
  pendingCount: Scalars['Int'];
  timeOffs?: Maybe<Array<TimeOff>>;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  endTime?: Maybe<Scalars['Time']>;
  startTime?: Maybe<Scalars['Time']>;
};

export type TimeRangeInput = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export enum TimeUnit {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export enum TimeValueInputMethod {
  START_TIME_END_TIME = 'START_TIME_END_TIME',
  TOTAL_HOURS = 'TOTAL_HOURS',
}

export type TimeoffConfiguration = {
  __typename?: 'TimeoffConfiguration';
  allocation?: Maybe<AllocationConfig>;
  carryForward?: Maybe<CarryForwardConfig>;
};

export type Timesheet = {
  __typename?: 'Timesheet';
  approvedOn?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  createdOn?: Maybe<Scalars['DateTime']>;
  cutOffDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<TimesheetStatus>;
  submittedOn?: Maybe<Scalars['DateTime']>;
  timeValueInputMethod?: Maybe<TimeValueInputMethod>;
  timesheetEntries?: Maybe<Array<Maybe<TimesheetEntry>>>;
  totalApprovedDays?: Maybe<Scalars['Int']>;
  /** @deprecated Now calculated on FE. The column in DB shouldn't be used anywhere. Details: 865cqtgah */
  totalClockedInDays?: Maybe<Scalars['Int']>;
  totalDays?: Maybe<Scalars['Int']>;
  /** @deprecated Use TimesheetTotalTimeSummary.totalTimeInMinutes instead */
  totalHours?: Maybe<Scalars['Float']>;
  totalRejectedDays?: Maybe<Scalars['Int']>;
  totalSubmittedDays?: Maybe<Scalars['Int']>;
  totalTimeSummary?: Maybe<TimesheetTotalTimeSummary>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type TimesheetConfigFilters = {
  countryCode?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<StateCode>;
};

export type TimesheetConfigs = {
  __typename?: 'TimesheetConfigs';
  countryConfigs?: Maybe<TimesheetCountryConfigs>;
};

export type TimesheetCountryConfigs = {
  __typename?: 'TimesheetCountryConfigs';
  countryCode?: Maybe<CountryCode>;
  countryStateCode?: Maybe<StateCode>;
  dayTypes?: Maybe<Array<Maybe<DayType>>>;
  hoursTypes?: Maybe<Array<Maybe<HoursType>>>;
};

export type TimesheetCreateUpdateInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type TimesheetEntry = {
  __typename?: 'TimesheetEntry';
  addedByInfo?: Maybe<AuditUser>;
  approvedByInfo?: Maybe<AuditUser>;
  approvedOn?: Maybe<Scalars['DateTime']>;
  breakTimes?: Maybe<Array<Maybe<TimeRange>>>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  dayType?: Maybe<DayType>;
  /** @deprecated Use TimesheetEntry.regularTimeInMinutes instead */
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isPaidLeave?: Maybe<Scalars['Boolean']>;
  lateTimeInMinutes?: Maybe<Scalars['Int']>;
  nightShiftTimeInMinutes?: Maybe<Scalars['Int']>;
  overtimeAtNightInMinutes?: Maybe<Scalars['Int']>;
  overtimeTotalInMinutes?: Maybe<Scalars['Int']>;
  regularTimeInMinutes?: Maybe<Scalars['Int']>;
  rejectedByInfo?: Maybe<AuditUser>;
  rejectedOn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TimesheetEntryStatus>;
  submittedOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  workTimes?: Maybe<Array<Maybe<TimeRange>>>;
};

export type TimesheetEntryInput = {
  breakTimes?: InputMaybe<Array<InputMaybe<TimeRangeInput>>>;
  date?: InputMaybe<Scalars['Date']>;
  dayType?: InputMaybe<DayType>;
  hours?: InputMaybe<Scalars['Float']>;
  isPaidLeave?: InputMaybe<Scalars['Boolean']>;
  lateTimeInMinutes?: InputMaybe<Scalars['Int']>;
  nightShiftTimeInMinutes?: InputMaybe<Scalars['Int']>;
  overtimeAtNightInMinutes?: InputMaybe<Scalars['Int']>;
  overtimeTotalInMinutes?: InputMaybe<Scalars['Int']>;
  regularTimeInMinutes?: InputMaybe<Scalars['Int']>;
  workTimes?: InputMaybe<Array<InputMaybe<TimeRangeInput>>>;
};

export enum TimesheetEntryStatus {
  APPROVED = 'APPROVED',
  PAID = 'PAID',
  PARTIALLY_CREATED = 'PARTIALLY_CREATED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type TimesheetFilters = {
  approvedDateRange?: InputMaybe<DateRange>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractType?: InputMaybe<ContractType>;
  country?: InputMaybe<CountryCode>;
  hoursType?: InputMaybe<HoursType>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  memberName?: InputMaybe<Scalars['String']>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  startDateRange?: InputMaybe<DateRange>;
  timesheetIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  timesheetStatus?: InputMaybe<TimesheetStatus>;
  updateDateRange?: InputMaybe<DateRange>;
};

export type TimesheetResult = {
  __typename?: 'TimesheetResult';
  data?: Maybe<Array<Maybe<Timesheet>>>;
  pageResult?: Maybe<PageResult>;
};

export enum TimesheetStatus {
  APPROVED = 'APPROVED',
  OPEN = 'OPEN',
  /** @deprecated No longer supported */
  PARTIALLY_SUBMITTED = 'PARTIALLY_SUBMITTED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
}

export type TimesheetSummaryFilter = {
  contractTypes?: InputMaybe<Array<ContractType>>;
};

export type TimesheetTotalTimeSummary = {
  __typename?: 'TimesheetTotalTimeSummary';
  totalLateTimeInMinutes?: Maybe<Scalars['Int']>;
  totalTimeInMinutes?: Maybe<Scalars['Int']>;
  totalTimeSummaryItems?: Maybe<Array<Maybe<TimesheetTotalTimeSummaryItem>>>;
};

export type TimesheetTotalTimeSummaryItem = {
  __typename?: 'TimesheetTotalTimeSummaryItem';
  dayType?: Maybe<DayType>;
  hoursType?: Maybe<HoursType>;
  totalTimeInMinutes?: Maybe<Scalars['Int']>;
};

export type TimesheetUploadBulkInput = {
  companyId: Scalars['ID'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type TimesheetUploadError = {
  __typename?: 'TimesheetUploadError';
  contractId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type TimesheetUploadResult = {
  __typename?: 'TimesheetUploadResult';
  errors?: Maybe<Array<Maybe<TimesheetUploadError>>>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type TracingContextEntry = {
  __typename?: 'TracingContextEntry';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Transaction = {
  creditor?: Maybe<LedgerSide>;
  debitor?: Maybe<LedgerSide>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTerminalTransaction?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Payment>;
  paymentPartner?: Maybe<PaymentPartner>;
  status?: Maybe<TransactionStatus>;
  transferValue?: Maybe<TransferValue>;
};

export type TransactionCommand = {
  __typename?: 'TransactionCommand';
  companyId?: Maybe<Scalars['ID']>;
  cycle?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRangeOutput>;
  tracingContext?: Maybe<Array<Maybe<TracingContextEntry>>>;
  transactionAction?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
  transactionType?: Maybe<FinancialTransactionType>;
};

export type TransactionPayableItem = {
  __typename?: 'TransactionPayableItem';
  amountInBaseCurrency?: Maybe<Scalars['Float']>;
  baseCurrency?: Maybe<Scalars['String']>;
  billableCost?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  cycle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemCount?: Maybe<Scalars['Int']>;
  lineItemType?: Maybe<Scalars['String']>;
  periodEndDate?: Maybe<Scalars['String']>;
  periodStartDate?: Maybe<Scalars['String']>;
  seatId?: Maybe<Scalars['ID']>;
  taxType?: Maybe<Scalars['String']>;
};

export enum TransactionStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PENDING_ON_MULTIPLIER = 'PENDING_ON_MULTIPLIER',
  PENDING_ON_PARTNER = 'PENDING_ON_PARTNER',
  PROCESSING = 'PROCESSING',
  REFUNDED = 'REFUNDED',
  REFUND_INITIATED = 'REFUND_INITIATED',
  REFUND_PROCESSING = 'REFUND_PROCESSING',
}

export enum TransferType {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
}

export type TransferValue = {
  __typename?: 'TransferValue';
  amount?: Maybe<Scalars['Float']>;
  destinationCurrency?: Maybe<Scalars['String']>;
  type?: Maybe<TransferType>;
};

export type TransferValueAmount = {
  amount?: InputMaybe<Scalars['Float']>;
  destinationCurrency?: InputMaybe<Scalars['String']>;
};

export type TransferValueBreakdown = {
  __typename?: 'TransferValueBreakdown';
  component?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type TrinetCredentialInput = {
  companyId: Scalars['ID'];
  companyUserName: Scalars['String'];
  companyUserPassword: Scalars['String'];
  externalCompanyId?: InputMaybe<Scalars['String']>;
};

export type TrinetCredentialInputV2 = {
  companyUserName: Scalars['String'];
  companyUserPassword: Scalars['String'];
  externalCompanyId: Scalars['String'];
};

export type TrinetCredentialResult = {
  __typename?: 'TrinetCredentialResult';
  message: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export type UnmappedField = {
  __typename?: 'UnmappedField';
  children?: Maybe<Array<Maybe<UnmappedField>>>;
  fieldFromApp?: Maybe<Scalars['String']>;
  fieldId?: Maybe<Scalars['String']>;
  isCustomField?: Maybe<Scalars['Boolean']>;
  isMappedByThirdParty?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  subFields?: Maybe<Array<Maybe<UnmappedField>>>;
  type?: Maybe<Scalars['String']>;
};

export type UnmarriedPolicyRules = {
  __typename?: 'UnmarriedPolicyRules';
  maxAllowedTotal?: Maybe<Scalars['Int']>;
  maxParentsAllowed?: Maybe<Scalars['Int']>;
};

export type UpdateAndFixTimeOffsInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  isTestContracts: Scalars['Boolean'];
  shouldCreateNonRequiredEntitlementIfMissing?: InputMaybe<Scalars['Boolean']>;
  shouldCreateRequiredEntitlementIfMissing?: InputMaybe<Scalars['Boolean']>;
  shouldCreateUpdateAllocationSummary?: InputMaybe<Scalars['Boolean']>;
  shouldSetComplianceLeavePolicyToCreatedDefaultEntitlement?: InputMaybe<
    Scalars['Boolean']
  >;
  shouldSetComplianceLeavePolicyToUpdatedDefaultEntitlement?: InputMaybe<
    Scalars['Boolean']
  >;
  statuses?: InputMaybe<Array<ContractStatus>>;
};

export type UpdateApproverInfoInput = {
  approverInfo?: InputMaybe<Array<InputMaybe<ApproverInfoInput>>>;
  companyUserId: Scalars['ID'];
};

export type UpdateCompanyChargebackRequest = {
  chargebackDays: Scalars['Int'];
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyLegalEntityInput = {
  address: AddressInput;
  capabilities?: InputMaybe<Array<Capability>>;
  id: Scalars['ID'];
  legalName: Scalars['String'];
  offeringCodes?: InputMaybe<Array<OfferingCode>>;
  payrollData?: InputMaybe<Array<DataFieldInput>>;
};

export type UpdateCompanyPayInMethodChargeBackRequest = {
  chargebackPeriodDays: Scalars['Int'];
  companyPayInMethodId: Scalars['ID'];
  description: Scalars['String'];
};

export type UpdateCompensationPayInfoInput = {
  contractId: Array<Scalars['ID']>;
  paymentFrequency?: InputMaybe<PayFrequency>;
  paymentFrequencyDate?: InputMaybe<Array<PayFrequencyDateInput>>;
};

export type UpdateCountryOnboardingEnablementInput = {
  country: CountryCode;
  enableStatus: Scalars['Boolean'];
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateDemoCyclesInput = {
  payrollCycleIds: Array<Scalars['ID']>;
  status: PayrollCycleStatus;
};

export type UpdateOffboardingInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  completedStep: OffBoardingExperienceStep;
  contractId: Scalars['ID'];
  documentIds?: InputMaybe<Array<Scalars['ID']>>;
  reason?: InputMaybe<Scalars['String']>;
};

export type UpdateOrCreateCompanyBindingInput = {
  companyId: Scalars['ID'];
  externalId: Scalars['String'];
};

export type UpdatePayrollWorkflowRequest = {
  action: Action;
  payrollCycleIds: Array<Scalars['ID']>;
};

export type UpdateUserInput = {
  companyUserId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  /** Deprecated. use 'companyUser[Assign|Revoke]Capabilities' instead. Will be removed by 1st of December 2021 */
  isBillingContact?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. use 'companyUser[Assign|Revoke]Capabilities' instead. Will be removed by 1st of December 2021 */
  isSignatory?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CompanyUserRole>;
  title?: InputMaybe<Scalars['String']>;
};

export type UploadUrlResponse = {
  __typename?: 'UploadUrlResponse';
  url: Scalars['String'];
};

export type UploadedFileCount = {
  __typename?: 'UploadedFileCount';
  total: Scalars['Int'];
  uploaded: Scalars['Int'];
};

export type UpsertSsoPreferenceInput = {
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  identityProvider: IdentityProvider;
};

export enum Urgency {
  DELAYED = 'DELAYED',
  OTHER = 'OTHER',
  URGENT = 'URGENT',
}

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  results?: Maybe<Array<ValidationResult>>;
  succeeded: Scalars['Boolean'];
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  message: Scalars['String'];
  type: ValidationResultType;
};

export enum ValidationResultType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

export enum VariableBaseUnit {
  ACTIVE_MEMBER = 'ACTIVE_MEMBER',
  COMPANY_ACTIVATION = 'COMPANY_ACTIVATION',
  HOUR = 'HOUR',
  MEMBER_ACTIVATION = 'MEMBER_ACTIVATION',
  MEMBER_OFFBOARD = 'MEMBER_OFFBOARD',
  MEMBER_OFFCYCLE_RUN = 'MEMBER_OFFCYCLE_RUN',
  MEMBER_PAYROLL_CYCLE = 'MEMBER_PAYROLL_CYCLE',
}

export enum VestingFrequency {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  YEARLY = 'YEARLY',
}

export type VestingSchedule = {
  __typename?: 'VestingSchedule';
  frequency?: Maybe<VestingFrequency>;
  time?: Maybe<ScheduleTime>;
  vestingValue?: Maybe<VestingValue>;
};

export type VestingScheduleInput = {
  frequency: VestingFrequency;
  time: ScheduleTimeInput;
  vestingValue: VestingValueInput;
};

export type VestingValue = {
  __typename?: 'VestingValue';
  type: VestingValueType;
  value: Scalars['Float'];
};

export type VestingValueInput = {
  type: VestingValueType;
  value: Scalars['Float'];
};

export enum VestingValueType {
  CASH = 'CASH',
  PERCENTAGE = 'PERCENTAGE',
  UNIT = 'UNIT',
}

export type VisaEmployeePricing = EmployeePricing & {
  __typename?: 'VisaEmployeePricing';
  country?: Maybe<CountryCode>;
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  validUntil?: Maybe<Scalars['Date']>;
};

export type VisaGlobalPricing = {
  __typename?: 'VisaGlobalPricing';
  employeeType?: Maybe<ContractType>;
  fixedRate?: Maybe<Scalars['Float']>;
};

export type WorkShift = {
  __typename?: 'WorkShift';
  breakHours?: Maybe<WorkingHours>;
  endDay?: Maybe<DayOfWeek>;
  startDay?: Maybe<DayOfWeek>;
  workingHours?: Maybe<WorkingHours>;
};

export type WorkShiftInput = {
  breakHours?: InputMaybe<WorkingHoursInput>;
  endDay: DayOfWeek;
  startDay: DayOfWeek;
  workingHours: WorkingHoursInput;
};

export type WorkingHours = {
  __typename?: 'WorkingHours';
  endTime?: Maybe<Scalars['Time']>;
  startTime?: Maybe<Scalars['Time']>;
};

export type WorkingHoursInput = {
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export enum Workspace {
  DEFAULT = 'DEFAULT',
  PANDA_DOC = 'PANDA_DOC',
  PANDA_DOC_MSA = 'PANDA_DOC_MSA',
}

export enum Core__Purpose {
  /** `EXECUTION` features provide metadata necessary to for operation execution. */
  EXECUTION = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  SECURITY = 'SECURITY',
}

export enum Join__Graph {
  ANALYTICS = 'ANALYTICS',
  COMPANY = 'COMPANY',
  COMPENSATION = 'COMPENSATION',
  CONTRACT = 'CONTRACT',
  CONTRACT_OFFBOARDING = 'CONTRACT_OFFBOARDING',
  CONTRACT_ONBOARDING = 'CONTRACT_ONBOARDING',
  CORE = 'CORE',
  COUNTRY = 'COUNTRY',
  CUSTOMER_INTEGRATION = 'CUSTOMER_INTEGRATION',
  DOCGEN = 'DOCGEN',
  EQUIPMENT = 'EQUIPMENT',
  EXPENSE = 'EXPENSE',
  FREELANCER = 'FREELANCER',
  MEMBER = 'MEMBER',
  MEMBER_EXPERIENCE = 'MEMBER_EXPERIENCE',
  OFFBOARDING_EXPERIENCE = 'OFFBOARDING_EXPERIENCE',
  ORG_MANAGEMENT = 'ORG_MANAGEMENT',
  PAYABLE = 'PAYABLE',
  PAYMENT = 'PAYMENT',
  PAYMENT_INSTRUCTION = 'PAYMENT_INSTRUCTION',
  PAYROLL = 'PAYROLL',
  PAYROLL_INPUT = 'PAYROLL_INPUT',
  PAYROLL_ORCHESTRATOR = 'PAYROLL_ORCHESTRATOR',
  PAYROLL_PAYMENT = 'PAYROLL_PAYMENT',
  PAYROLL_SCHEMA_SERVICE = 'PAYROLL_SCHEMA_SERVICE',
  PIGEON = 'PIGEON',
  PLATFORM_UTILITY = 'PLATFORM_UTILITY',
  PRICING = 'PRICING',
  RECRUITMENT = 'RECRUITMENT',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  SALARY_CALCULATOR = 'SALARY_CALCULATOR',
  TIMEOFF = 'TIMEOFF',
  TIMESHEET = 'TIMESHEET',
}

export type GetMemberDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberDetailsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    fullLegalName?: string | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      email?: string | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      position?: string | null;
      startOn?: any | null;
    } | null> | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      label?: string | null;
    } | null> | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      country?: CountryCode | null;
      type?: Nationalogy | null;
    } | null> | null;
  } | null;
};

export type GetConvertedCurrencyQueryVariables = Exact<{
  params: CurrencyConversionParams;
}>;

export type GetConvertedCurrencyQuery = {
  __typename?: 'Query';
  convertCurrency?: number | null;
};

export type GetCompanyHomeCurrencyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyHomeCurrencyQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      currency?: CurrencyCode | null;
    } | null;
  } | null;
};

export type GetCompanyNameQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyNameQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
    companyLogo?: {
      __typename?: 'DocumentReadable';
      link?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
  } | null;
};

export type GetCompanyStatusAndCsmQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyStatusAndCsmQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    status?: CompanyStatus | null;
    csmUser?: { __typename?: 'OperationsUser'; id?: string | null } | null;
  } | null;
};

export type GetMemberContractStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberContractStatusQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      status?: ContractStatus | null;
      contractStarted?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetCurrencyConversionQueryVariables = Exact<{
  code?: InputMaybe<CurrencyCode>;
  to?: InputMaybe<CurrencyCode>;
  amount?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<CurrencyConversionSource>;
  forDate?: InputMaybe<Scalars['Date']>;
}>;

export type GetCurrencyConversionQuery = {
  __typename?: 'Query';
  currency?: { __typename?: 'Currency'; convert?: number | null } | null;
};

export type GetMemberComplianceTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberComplianceTypeQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
        | {
            __typename?: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
          }
        | null;
    } | null> | null;
  } | null;
};

export type GetMemberHomeCurrencyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberHomeCurrencyQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      currency?: CurrencyCode | null;
    } | null> | null;
  } | null;
};

export type GetCompanyCalendarCountriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyCalendarCountriesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      legalEntityId?: string | null;
      member?: {
        __typename?: 'Member';
        userId?: string | null;
        fullLegalName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetMemberCalendarCountriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberCalendarCountriesQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      legalEntityId?: string | null;
      type?: ContractType | null;
      company?: {
        __typename?: 'Company';
        id?: string | null;
        primaryEntity?: {
          __typename?: 'LegalEntity';
          id?: string | null;
          address?: {
            __typename?: 'Address';
            country?: CountryCode | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCountryHolidaysQueryVariables = Exact<{
  countries: Array<InputMaybe<CountryCode>> | InputMaybe<CountryCode>;
  year: Scalars['Int'];
  month?: InputMaybe<Scalars['Int']>;
}>;

export type GetCountryHolidaysQuery = {
  __typename?: 'Query';
  holidays?: Array<{
    __typename?: 'Holiday';
    key?: string | null;
    label?: string | null;
    year?: number | null;
    month?: number | null;
    date?: number | null;
    name?: string | null;
    countryCode?: CountryCode | null;
  } | null> | null;
};

export type GetEntitiesHolidaysQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  legalEntityId?: InputMaybe<Scalars['ID']>;
  year?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<LegalEntityHolidayInput>;
}>;

export type GetEntitiesHolidaysQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    otherEntities?: Array<{
      __typename?: 'LegalEntity';
      holidays?: Array<{
        __typename?: 'Holiday';
        label?: string | null;
        legalEntityId?: string | null;
        key?: string | null;
        year?: number | null;
        month?: number | null;
        date?: number | null;
        name?: string | null;
        countryCode?: CountryCode | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetMemberOnboardingContractQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberOnboardingContractQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    status?: MemberStatus | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      status?: ContractStatus | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        id?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyCsmQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyCsmQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    csmUser?: {
      __typename?: 'OperationsUser';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      roles?: Array<OperationsUserRole | null> | null;
      calendlyLink?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        type?: string | null;
        email?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        type?: string | null;
        phoneNo?: string | null;
      } | null> | null;
      profilePicture?: {
        __typename?: 'Document';
        downloadUrl?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCountryWithLegalEntityQueryVariables = Exact<{
  country: CountryCode;
}>;

export type GetCountryWithLegalEntityQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      legalEntity?: {
        __typename?: 'CountryLegalEntity';
        legalEntity?: {
          __typename?: 'LegalEntity';
          legalName?: string | null;
          address?: {
            __typename?: 'Address';
            line1?: string | null;
            line2?: string | null;
            country?: CountryCode | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetMemberWithLegalEntityQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberWithLegalEntityQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            type?: LegalBindingType | null;
            legalEntity?: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
              address?: {
                __typename?: 'Address';
                line1?: string | null;
                line2?: string | null;
                country?: CountryCode | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'ComplianceFreelance';
            type?: LegalBindingType | null;
            legalEntity?: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
              address?: {
                __typename?: 'Address';
                line1?: string | null;
                line2?: string | null;
                country?: CountryCode | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
            legalEntity?: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
              address?: {
                __typename?: 'Address';
                line1?: string | null;
                line2?: string | null;
                country?: CountryCode | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'CompliancePEO';
            type?: LegalBindingType | null;
            legalEntity?: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
              address?: {
                __typename?: 'Address';
                line1?: string | null;
                line2?: string | null;
                country?: CountryCode | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
            legalEntity?: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
              address?: {
                __typename?: 'Address';
                line1?: string | null;
                line2?: string | null;
                country?: CountryCode | null;
              } | null;
            } | null;
          }
        | null;
    } | null> | null;
  } | null;
};

export type ChangeCountryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ContractUpdateCountryInput;
}>;

export type ChangeCountryMutation = {
  __typename?: 'Mutation';
  contractChangeCountry?: {
    __typename?: 'Contract';
    id?: string | null;
    country?: CountryCode | null;
    workStatus?: CountryWorkStatus | null;
    countryStateCode?: string | null;
    alreadyHired?: boolean | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type CompanyMsaRequestMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyMsaRequestMutation = {
  __typename?: 'Mutation';
  companyMSARequest?: {
    __typename?: 'Request';
    id?: string | null;
    type?: RequestType | null;
    status?: RequestStatus | null;
    comment?: string | null;
    requester?:
      | { __typename?: 'CompanyUser'; id?: string | null }
      | { __typename?: 'OperationsUser' }
      | null;
  } | null;
};

export type ContractPreparingConfirmationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ContractPreparingConfirmationMutation = {
  __typename?: 'Mutation';
  contractConfirmContractPrepare?: {
    __typename?: 'Contract';
    id?: string | null;
    type?: ContractType | null;
    status?: ContractStatus | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      statuses?: Array<ContractOnboardingStatus | null> | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        status?: InvoiceStatus | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetContractTemplatesQueryVariables = Exact<{
  id: Scalars['ID'];
  filter: TemplateFilter;
}>;

export type GetContractTemplatesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    templates?: Array<{
      __typename?: 'Template';
      id: string;
      entityId?: string | null;
      externalId?: string | null;
      displayName?: string | null;
      contractId?: string | null;
      country?: CountryCode | null;
      countryState?: string | null;
      language: Language;
      type: string;
      link?: string | null;
      metadata?: {
        __typename?: 'TemplateMetadata';
        languagesCount?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreateContractMutationVariables = Exact<{
  input: ContractCreateInput;
}>;

export type CreateContractMutation = {
  __typename?: 'Mutation';
  contractCreate?: {
    __typename?: 'Contract';
    id?: string | null;
    type?: ContractType | null;
    country?: CountryCode | null;
    workStatus?: CountryWorkStatus | null;
    countryStateCode?: string | null;
    alreadyHired?: boolean | null;
    term?: ContractTerm | null;
    position?: string | null;
    startOn?: any | null;
    endOn?: any | null;
    endedOn?: any | null;
    offboardingNote?: string | null;
    scope?: string | null;
    status?: ContractStatus | null;
    employeeId?: string | null;
    createdOn?: any | null;
    contractStarted?: boolean | null;
    contractBlocked?: boolean | null;
    lastWorkingDay?: any | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      status?: MemberStatus | null;
      maritalStatus?: MaritalStatus | null;
    } | null;
    compensation?: {
      __typename?: 'Compensation';
      basePay?: {
        __typename?: 'FixedPayComponent';
        amount?: number | null;
        frequency?: RateFrequency | null;
        currency?: CurrencyCode | null;
      } | null;
    } | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      id?: string | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
      activationCutoff?: any | null;
      tasks?: Array<{
        __typename?: 'OnboardingTask';
        name?: string | null;
        order?: number | null;
        completed?: boolean | null;
        pendingOn?: {
          __typename?: 'OnboardingTaskUser';
          id?: string | null;
          persona?: Persona | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }> | null;
    } | null;
    workShift?: {
      __typename?: 'WorkShift';
      startDay?: DayOfWeek | null;
      endDay?: DayOfWeek | null;
      workingHours?: {
        __typename?: 'WorkingHours';
        startTime?: any | null;
        endTime?: any | null;
      } | null;
    } | null;
    offboarding?: {
      __typename?: 'ContractOffboarding';
      id?: string | null;
      type?: ContractOffboardingType | null;
      lastWorkingDay?: any | null;
      status?: ContractOffboardingStatus | null;
    } | null;
  } | null;
};

export type CreateMemberMutationVariables = Exact<{
  input: MemberCreateInput;
}>;

export type CreateMemberMutation = {
  __typename?: 'Mutation';
  memberCreate?: { __typename?: 'Member'; id?: string | null } | null;
};

export type WorkShiftFragment = {
  __typename?: 'WorkShift';
  startDay?: DayOfWeek | null;
  endDay?: DayOfWeek | null;
  workingHours?: {
    __typename?: 'WorkingHours';
    startTime?: any | null;
    endTime?: any | null;
  } | null;
};

export type GetCompanyDuplicateDomainQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyDuplicateDomainQuery = {
  __typename?: 'Query';
  companyHasDuplicatedDomain?: boolean | null;
};

export type GetCompanyMsaRequirementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyMsaRequirementsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      registrationNo?: string | null;
      legalName?: string | null;
      phone?: string | null;
      address?: { __typename?: 'Address'; key?: string | null } | null;
    } | null;
  } | null;
};

export type GetCompanyMsaStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyMsaStatusQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    onboarding?: {
      __typename?: 'CompanyOnboarding';
      msaWorkflow?: {
        __typename?: 'MSAWorkflow';
        currentStatus?: MsaWorkflowStatus | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyTimeOffRequirementsQueryVariables = Exact<{
  contractType: ContractType;
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractStatus?: InputMaybe<ContractStatus>;
  companyId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyTimeOffRequirementsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    timeOffRequirements?: {
      __typename?: 'TimeOffRequirements';
      clause?: string | null;
      definitions?: Array<{
        __typename?: 'TimeOffTypeDefinition';
        type?: string | null;
        required?: boolean | null;
        label?: string | null;
        description?: string | null;
        assignedEntityNames?: Array<string> | null;
        validation?: Array<{
          __typename?: 'TimeOffFixedValidation';
          minimum?: number | null;
          maximum?: number | null;
          defaultValue?: number | null;
          unit?: TimeOffUnit | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCompensationStructureDefinitionQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ContractType>;
  term?: InputMaybe<ContractTerm>;
}>;

export type GetCompensationStructureDefinitionQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: {
        __typename?: 'State';
        code?: string | null;
        name?: string | null;
      } | null;
      compensationStructureDefinition?: {
        __typename?: 'CompensationStructureDefinition';
        basePay?: {
          __typename?: 'FixedPayComponentDefinition';
          name?: string | null;
          label?: string | null;
          description?: string | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          isDeletable?: boolean | null;
          payOn?: {
            __typename?: 'MonthYear';
            year?: number | null;
            month?: number | null;
          } | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponentDefinition';
              name?: string | null;
              label?: string | null;
              description?: string | null;
              frequency?: RateFrequency | null;
              rateType?: RateType | null;
              isDeletable?: boolean | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponentDefinition';
              name?: string | null;
              label?: string | null;
              description?: string | null;
              frequency?: RateFrequency | null;
              rateType?: RateType | null;
              isDeletable?: boolean | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

type CompensationPayComponentDefinition_FixedPayComponentDefinition_Fragment = {
  __typename?: 'FixedPayComponentDefinition';
  name?: string | null;
  label?: string | null;
  description?: string | null;
  frequency?: RateFrequency | null;
  rateType?: RateType | null;
  isDeletable?: boolean | null;
  payOn?: {
    __typename?: 'MonthYear';
    year?: number | null;
    month?: number | null;
  } | null;
};

type CompensationPayComponentDefinition_PercentPayComponentDefinition_Fragment = {
  __typename?: 'PercentPayComponentDefinition';
  name?: string | null;
  label?: string | null;
  description?: string | null;
  frequency?: RateFrequency | null;
  rateType?: RateType | null;
  isDeletable?: boolean | null;
  payOn?: {
    __typename?: 'MonthYear';
    year?: number | null;
    month?: number | null;
  } | null;
};

export type CompensationPayComponentDefinitionFragment =
  | CompensationPayComponentDefinition_FixedPayComponentDefinition_Fragment
  | CompensationPayComponentDefinition_PercentPayComponentDefinition_Fragment;

export type GetCompliancePageInsightsQueryVariables = Exact<{
  country: CountryCode;
  contractTerm?: InputMaybe<ContractTerm>;
  contractType?: InputMaybe<ContractType>;
}>;

export type GetCompliancePageInsightsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    insights?: Array<
      | {
          __typename?: 'BenefitInsightDefinition';
          id: string;
          category?: InsightCategory | null;
          description?: string | null;
        }
      | {
          __typename?: 'CompensationInsightDefinition';
          id: string;
          category?: InsightCategory | null;
          description?: string | null;
        }
      | {
          __typename?: 'ComplianceInsightDefinition';
          complianceParamPeriodUnit?: ComplianceParamPeriodUnit | null;
          id: string;
          category?: InsightCategory | null;
          description?: string | null;
          complianceRecommendedValue?: number | null;
          complianceParam?: {
            __typename?: 'ComplianceParamPeriodLimit';
            key?: string | null;
            label?: string | null;
          } | null;
        }
      | {
          __typename?: 'TimeOffInsightDefinition';
          timeOffUnit?: TimeOffUnit | null;
          id: string;
          category?: InsightCategory | null;
          description?: string | null;
          timeOffRecommendedValue?: number | null;
          timeOffTypeDefinition?: {
            __typename?: 'TimeOffTypeDefinition';
            type?: string | null;
            label?: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type GetCompliantCurrenciesQueryVariables = Exact<{
  country?: InputMaybe<CountryCode>;
}>;

export type GetCompliantCurrenciesQuery = {
  __typename?: 'Query';
  countries?: Array<{
    __typename?: 'Country';
    code?: CountryCode | null;
    compliantCurrencies?: Array<CurrencyCode | null> | null;
  } | null> | null;
};

export type GetConfigurationQueryVariables = Exact<{
  filters?: InputMaybe<ChangeRequestConfigFiltersInput>;
}>;

export type GetConfigurationQuery = {
  __typename?: 'Query';
  configuration?: {
    __typename?: 'Configuration';
    contractChangeRequestConfig?: {
      __typename?: 'ContractChangeRequestConfig';
      turnAroundTimeInHours?: number | null;
      earliestJoiningDateWithTurnAround?: any | null;
    } | null;
  } | null;
};

export type GetContractAgreementQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractAgreementQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    compliance?:
      | {
          __typename?: 'ComplianceContractor';
          orderForm?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
        }
      | {
          __typename?: 'ComplianceFreelance';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
        }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
        }
      | {
          __typename?: 'CompliancePEO';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
        }
      | {
          __typename?: 'CompliancePartnerEOR';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
        }
      | null;
    compensation?: {
      __typename?: 'Compensation';
      grant?: Array<{
        __typename?: 'ESOPGrant';
        id: string;
        document?:
          | {
              __typename?: 'DocumentReadable';
              blob?: string | null;
              htmlPreview?: string | null;
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
            }
          | {
              __typename?: 'FileLink';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
            }
          | {
              __typename?: 'FileWithVersion';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
            }
          | {
              __typename?: 'Image';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
            }
          | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetContractChangeRequestQueryVariables = Exact<{
  contractId: Scalars['ID'];
  changeRequestIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetContractChangeRequestQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    changeRequests?: Array<{
      __typename?: 'ContractChangeRequest';
      id: string;
      contractId: string;
      description: string;
    }> | null;
  } | null;
};

export type GetContractEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractEntityQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    type?: ContractType | null;
    startOn?: any | null;
    legalEntityId?: string | null;
    multiplierLegalEntityId?: string | null;
  } | null;
};

export type GetContractJobCodeQueryVariables = Exact<{
  filter: JobCodeFilters;
}>;

export type GetContractJobCodeQuery = {
  __typename?: 'Query';
  contractJobCode?: string | null;
};

export type GetCountry13th14thMonthPayQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
}>;

export type GetCountry13th14thMonthPayQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: {
        __typename?: 'State';
        name?: string | null;
        code?: string | null;
      } | null;
      country13th14thMonthPay?: {
        __typename?: 'Country13th14thMonthPay';
        is13thMonthPayApplicable?: boolean | null;
        is14thMonthPayApplicable?: boolean | null;
        isIncludedInGross?: boolean | null;
        description13thMonthPay?: string | null;
        description14thMonthPay?: string | null;
        note?: string | null;
        payFrequencyType?: Country13th14thMonthPayFrequencyType | null;
        schedules?: Array<{
          __typename?: 'Country13th14thMonthPaySchedule';
          country13th14thMonthPayType?: Country13th14thMonthPayType | null;
          monthlySalaryMultiple?: number | null;
          scheduleTimeUnit?: ScheduleTimeUnit | null;
          payoutDay?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetCountryAllowancesConfigQueryVariables = Exact<{
  country: CountryCode;
}>;

export type GetCountryAllowancesConfigQuery = {
  __typename?: 'Query';
  countryAllowancesConfig: Array<{
    __typename?: 'Allowance';
    country: CountryCode;
    name: string;
    label: string;
    mandatory: boolean;
  }>;
};

export type GetCountryComplianceLimitationsQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
  workStatus?: InputMaybe<CountryWorkStatus>;
  fetchStage?: InputMaybe<FetchStage>;
}>;

export type GetCountryComplianceLimitationsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: {
        __typename?: 'State';
        name?: string | null;
        code?: string | null;
      } | null;
      limitations?: Array<{
        __typename?: 'ComplianceLimitationDefinition';
        key: string;
        label?: string | null;
        value: string;
        enabled?: boolean | null;
        description?: string | null;
        countryState?: string | null;
        preconditions?: Array<{
          __typename?: 'ComplianceLimitationPrecondition';
          contractType?: ContractType | null;
          contractTerm?: ContractTerm | null;
        }> | null;
      } | null> | null;
      memberDataRequirements?: Array<{
        __typename?: 'DataFieldDefinition';
        key?: string | null;
        label?: string | null;
        description?: string | null;
        required?: boolean | null;
        dependsOn?: Array<{
          __typename?: 'ControllingField';
          key: string;
        }> | null;
        dataType?:
          | { __typename?: 'AutoCompleteField'; optionListType?: string | null }
          | { __typename?: 'CheckboxField'; defaultValue: boolean }
          | {
              __typename?: 'DateField';
              minDate?: any | null;
              maxDate?: any | null;
            }
          | {
              __typename?: 'DropDownField';
              values?: Array<string | null> | null;
            }
          | {
              __typename?: 'DropDownTextField';
              minLength?: number | null;
              maxLength?: number | null;
              type?: string | null;
              values?: Array<string | null> | null;
            }
          | { __typename?: 'FileField' }
          | {
              __typename?: 'TextField';
              minLength?: number | null;
              maxLength?: number | null;
              pattern?: string | null;
            }
          | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCountryComplianceRequirementsQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  inputParams?: InputMaybe<RequirementsInputs>;
  contractType?: InputMaybe<ContractType>;
}>;

export type GetCountryComplianceRequirementsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: {
        __typename?: 'State';
        name?: string | null;
        code?: string | null;
      } | null;
      requirements?: {
        __typename?: 'ComplianceRequirementDefinition';
        usedScope?: ComplianceDefinitionScope | null;
        paramDefinitions?: Array<{
          __typename?: 'ComplianceParamDefinition';
          dataFieldType?: ComplianceParamDataFieldType | null;
          required?: boolean | null;
          editable?: boolean | null;
          enabled?: boolean | null;
          param?: {
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            key?: string | null;
            label?: string | null;
          } | null;
          validation?: Array<{
            __typename?: 'ComplianceParamPeriodLimitUnitValidation';
            minimum?: number | null;
            maximum?: number | null;
            defaultValue?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
          } | null> | null;
          dependencies?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            key?: string | null;
            label?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetCountryComplianceLimitationsOnlyQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
}>;

export type GetCountryComplianceLimitationsOnlyQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      limitations?: Array<{
        __typename?: 'ComplianceLimitationDefinition';
        key: string;
        label?: string | null;
        value: string;
        enabled?: boolean | null;
        description?: string | null;
        countryState?: string | null;
        preconditions?: Array<{
          __typename?: 'ComplianceLimitationPrecondition';
          contractType?: ContractType | null;
          contractTerm?: ContractTerm | null;
        }> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetEmployeeCountryPricingQueryVariables = Exact<{
  country?: InputMaybe<CountryCode>;
}>;

export type GetEmployeeCountryPricingQuery = {
  __typename?: 'Query';
  employeePricingForCountry?: Array<
    | {
        __typename?: 'CountryEmployeePricing';
        id: string;
        employeeType?: ContractType | null;
        fixedRate?: number | null;
      }
    | {
        __typename?: 'RegionEmployeePricing';
        id: string;
        employeeType?: ContractType | null;
        fixedRate?: number | null;
      }
    | {
        __typename?: 'VisaEmployeePricing';
        id: string;
        employeeType?: ContractType | null;
        fixedRate?: number | null;
      }
    | null
  > | null;
};

export type GetCountryLabourStandardsQueryVariables = Exact<{
  country: CountryCode;
  stateCode?: InputMaybe<Scalars['String']>;
}>;

export type GetCountryLabourStandardsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    labourStandards?: {
      __typename?: 'CountryLabourStandards';
      countryCode?: CountryCode | null;
      workShift?: {
        __typename?: 'CountryWorkShiftStandards';
        startDay?: DayOfWeek | null;
        endDay?: DayOfWeek | null;
        workingHoursInfoText?: string | null;
        averageWorkingHoursPerMonth?: number | null;
        defaultWorkingHours?: {
          __typename?: 'WorkingHours';
          startTime?: any | null;
          endTime?: any | null;
        } | null;
      } | null;
      compensation?: {
        __typename?: 'CountryCompensationStandards';
        rateFrequency?: {
          __typename?: 'CountryRateFrequency';
          list?: Array<RateFrequency | null> | null;
          defaultValue?: RateFrequency | null;
        } | null;
        paymentFrequency?: {
          __typename?: 'CountryPaymentFrequency';
          list?: Array<PayFrequency | null> | null;
          defaultValue?: PayFrequency | null;
          payDates?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
          data?: Array<{
            __typename?: 'CountryPaymentFrequencyData';
            rateFrequency?: RateFrequency | null;
            payFrequencies?: Array<PayFrequency | null> | null;
          } | null> | null;
        } | null;
        hourlyRates?: {
          __typename?: 'CountryHourlyRates';
          helpUrl?: string | null;
          values?: Array<{
            __typename?: 'CountryHourlyRateValue';
            key?: string | null;
            label?: string | null;
            description?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetCountryLocalCurrencyQueryVariables = Exact<{
  country: CountryCode;
}>;

export type GetCountryLocalCurrencyQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    mainCurrency?: CurrencyCode | null;
  } | null;
};

export type GetCountryStatesQueryVariables = Exact<{
  country: CountryCode;
}>;

export type GetCountryStatesQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    name?: string | null;
    code?: CountryCode | null;
    isMultiplierVisaAssistance?: boolean | null;
    countryStates?: Array<{
      __typename?: 'State';
      name?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type GetCurrentCompanyIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentCompanyIdQuery = {
  __typename?: 'Query';
  company?: { __typename?: 'Company'; id?: string | null } | null;
};

export type GetFixedTermRestrictionQueryVariables = Exact<{
  countryCode: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
  fixedTermRestrictionFilter?: InputMaybe<FixedTermRestrictionFilter>;
}>;

export type GetFixedTermRestrictionQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: { __typename?: 'State'; code?: string | null } | null;
      fixedTermRestriction?: {
        __typename?: 'FixedTermRestrictionDefinition';
        maxRenewalCount?: number | null;
        maxRenewalDurationInMonths?: number | null;
        maxTenureInMonths?: number | null;
        minRenewalDurationInMonths?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetInitialOnboardingStepsQueryVariables = Exact<{
  type: ContractType;
  legalEntityId?: InputMaybe<Scalars['ID']>;
}>;

export type GetInitialOnboardingStepsQuery = {
  __typename?: 'Query';
  onboarding?: {
    __typename?: 'ContractOnboarding';
    steps?: Array<ContractOnboardingStep | null> | null;
  } | null;
};

export type GetLegalEntityHolidaysQueryVariables = Exact<{
  legalEntityId: Scalars['ID'];
  year?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<LegalEntityHolidayInput>;
}>;

export type GetLegalEntityHolidaysQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    otherEntities?: Array<{
      __typename?: 'LegalEntity';
      id?: string | null;
      holidays?: Array<{
        __typename?: 'Holiday';
        name?: string | null;
        date?: number | null;
        month?: number | null;
        year?: number | null;
        key?: string | null;
        label?: string | null;
        countryCode?: CountryCode | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetOfferLetterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOfferLetterQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    compliance?:
      | { __typename?: 'ComplianceContractor' }
      | { __typename?: 'ComplianceFreelance' }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
        }
      | { __typename?: 'CompliancePEO' }
      | {
          __typename?: 'CompliancePartnerEOR';
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
        }
      | null;
  } | null;
};

export type GetOnboardingProgressQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetOnboardingProgressQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    type?: ContractType | null;
    contractStarted?: boolean | null;
    startOn?: any | null;
    legalEntityId?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      current?: ContractOnboardingStep | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
    } | null;
  } | null;
};

export type GetPayrollConfigsForCompanyLegalEntityQueryVariables = Exact<{
  companyLegalEntityId: Scalars['ID'];
  multiplierLegalEntityId?: InputMaybe<Scalars['ID']>;
  payFrequency?: InputMaybe<PayFrequency>;
  domain: PayrollInputDomainType;
  referenceDate: Scalars['Date'];
}>;

export type GetPayrollConfigsForCompanyLegalEntityQuery = {
  __typename?: 'Query';
  payrollConfigsWithNextPayrollCycles: Array<{
    __typename?: 'PayrollCycleConfig';
    id?: string | null;
    title: string;
    frequency?: PayFrequency | null;
    referencePayrollCycle?: {
      __typename?: 'PayrollCycle';
      id: string;
      cutOffTo?: any | null;
    } | null;
  }>;
};

export type GetPositionsForIntegrationsQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type GetPositionsForIntegrationsQuery = {
  __typename?: 'Query';
  getPositionsForIntegrations?: Array<{
    __typename?: 'Position';
    department?: string | null;
    designation?: string | null;
    positionId?: string | null;
  } | null> | null;
};

export type GetSignatoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSignatoriesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    status?: CompanyStatus | null;
    signatories?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetStartDateRestrictionsQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
}>;

export type GetStartDateRestrictionsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: { __typename?: 'State'; code?: string | null } | null;
      joiningDateRestriction?: {
        __typename?: 'ComplianceJoiningDateRestriction';
        earliestJoiningDate: any;
        checkpoints?: {
          __typename?: 'ComplianceJoiningCheckpoints';
          earliestVisaCompletionDate?: any | null;
        } | null;
        holidays: Array<{
          __typename?: 'Holiday';
          key?: string | null;
          label?: string | null;
          year?: number | null;
          month?: number | null;
          date?: number | null;
          name?: string | null;
          type?: string | null;
          countryCode?: CountryCode | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type GetSupportedCountriesQueryVariables = Exact<{
  contractType: ContractType;
}>;

export type GetSupportedCountriesQuery = {
  __typename?: 'Query';
  supportedCountries?: Array<{
    __typename?: 'Country';
    code?: CountryCode | null;
    name?: string | null;
  } | null> | null;
};

export type GetSupportedCurrenciesQueryVariables = Exact<{
  country: CountryCode;
  contractType?: InputMaybe<ContractType>;
}>;

export type GetSupportedCurrenciesQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    supportedCurrencies?: Array<CurrencyCode | null> | null;
  } | null;
};

export type GetTimeOffContractRequirementsQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetTimeOffContractRequirementsQuery = {
  __typename?: 'Query';
  timeOffContractRequirements?: {
    __typename?: 'TimeOffContractRequirements';
    clause?: string | null;
    assignedEntitlements?: Array<{
      __typename?: 'ContractTimeOffEntitlement';
      isMandatory?: boolean | null;
      value?: number | null;
      unit?: TimeOffUnit | null;
      timeOffType?: {
        __typename?: 'TimeOffTypeInfo';
        typeId?: string | null;
        type?: string | null;
      } | null;
      definition?: {
        __typename?: 'TimeOffTypeDefinition';
        label?: string | null;
        type?: string | null;
        assignedEntityNames?: Array<string> | null;
        validation?: Array<{
          __typename?: 'TimeOffFixedValidation';
          allowedContractStatuses?: Array<ContractStatus | null> | null;
          defaultValue?: number | null;
          isUnlimitedLeavesAllowed?: boolean | null;
          maximum?: number | null;
          minimum?: number | null;
          unit?: TimeOffUnit | null;
        } | null> | null;
        configuration?: {
          __typename?: 'TimeoffConfiguration';
          allocation?: {
            __typename?: 'AllocationConfig';
            basis?: AllocationBasis | null;
            prorated?: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    availableEntitlements?: Array<{
      __typename?: 'ContractTimeOffEntitlement';
      isMandatory?: boolean | null;
      value?: number | null;
      unit?: TimeOffUnit | null;
      timeOffType?: {
        __typename?: 'TimeOffTypeInfo';
        typeId?: string | null;
        type?: string | null;
      } | null;
      definition?: {
        __typename?: 'TimeOffTypeDefinition';
        label?: string | null;
        type?: string | null;
        assignedEntityNames?: Array<string> | null;
        configuration?: {
          __typename?: 'TimeoffConfiguration';
          allocation?: {
            __typename?: 'AllocationConfig';
            basis?: AllocationBasis | null;
            prorated?: boolean | null;
          } | null;
        } | null;
        validation?: Array<{
          __typename?: 'TimeOffFixedValidation';
          allowedContractStatuses?: Array<ContractStatus | null> | null;
          defaultValue?: number | null;
          isUnlimitedLeavesAllowed?: boolean | null;
          maximum?: number | null;
          minimum?: number | null;
          unit?: TimeOffUnit | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type InviteMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InviteMemberMutation = {
  __typename?: 'Mutation';
  contractInviteMember?: {
    __typename?: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      statuses?: Array<ContractOnboardingStatus | null> | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type RequestContractChangesMutationVariables = Exact<{
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}>;

export type RequestContractChangesMutation = {
  __typename?: 'Mutation';
  contractRequestChangesFromMember?: {
    __typename?: 'Contract';
    id?: string | null;
  } | null;
};

export type ResendInviteMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResendInviteMemberMutation = {
  __typename?: 'Mutation';
  contractInviteMemberResend?: {
    __typename?: 'Contract';
    id?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type ContractReviseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ContractReviseMutation = {
  __typename?: 'Mutation';
  contractRevise?: {
    __typename?: 'Contract';
    id?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      current?: ContractOnboardingStep | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
    } | null;
  } | null;
};

export type ContractRevokeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ContractRevokeMutation = {
  __typename?: 'Mutation';
  contractRevoke?: {
    __typename?: 'Contract';
    id?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      current?: ContractOnboardingStep | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
    } | null;
  } | null;
};

export type SendForSignatureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SendForSignatureMutation = {
  __typename?: 'Mutation';
  contractSendForSignature?: {
    __typename?: 'Contract';
    id?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      statuses?: Array<ContractOnboardingStatus | null> | null;
      current?: ContractOnboardingStep | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
    } | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        status?: InvoiceStatus | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ContractSendOfferLetterMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ContractSendOfferLetterMutation = {
  __typename?: 'Mutation';
  contractSendOfferLetter?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type SendOrderFormForSignatureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SendOrderFormForSignatureMutation = {
  __typename?: 'Mutation';
  contractOrderFormSendForSignature?: {
    __typename?: 'Contract';
    id?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      statuses?: Array<ContractOnboardingStatus | null> | null;
      current?: ContractOnboardingStep | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
    } | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        status?: InvoiceStatus | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdatePreFetchDetailsMutationVariables = Exact<{
  memberId: Scalars['ID'];
  memberDataInputs:
    | Array<InputMaybe<TextFieldInput>>
    | InputMaybe<TextFieldInput>;
  dateFieldInputs:
    | Array<InputMaybe<DateFieldInput>>
    | InputMaybe<DateFieldInput>;
}>;

export type UpdatePreFetchDetailsMutation = {
  __typename?: 'Mutation';
  countryUpdateMemberDataText?: {
    __typename?: 'Member';
    id?: string | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      label?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
  countryUpdateMemberDataDate?: {
    __typename?: 'Member';
    id?: string | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      label?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateBankDetailsCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  bankAccount: BankAccountInput;
}>;

export type UpdateBankDetailsCompanyMutation = {
  __typename?: 'Mutation';
  memberUpdateBankDetails?: {
    __typename?: 'Member';
    id?: string | null;
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id?: string | null;
      accountName?: string | null;
      accountNumber?: string | null;
      bankName?: string | null;
      branchName?: string | null;
      swiftCode?: string | null;
      localBankCode?: string | null;
      bankStatements?: Array<{
        __typename?: 'FileLink';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        link?: string | null;
        contentType?: string | null;
        size?: number | null;
      } | null> | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        id?: string | null;
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberDataForCompanyMutationVariables = Exact<{
  memberId: Scalars['ID'];
  generalDataChange: MemberUpdateInput;
  email: EmailAddressInput;
  memberDataInputs:
    | Array<InputMaybe<TextFieldInput>>
    | InputMaybe<TextFieldInput>;
  dateFieldInputs:
    | Array<InputMaybe<DateFieldInput>>
    | InputMaybe<DateFieldInput>;
}>;

export type UpdateMemberDataForCompanyMutation = {
  __typename?: 'Mutation';
  memberUpdate?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
  } | null;
  memberChangePrimaryEmail?: {
    __typename?: 'Member';
    id?: string | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
  countryUpdateMemberDataText?: {
    __typename?: 'Member';
    id?: string | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      label?: string | null;
      value?: string | null;
      identifier?: string | null;
    } | null> | null;
  } | null;
  countryUpdateMemberDataDate?: {
    __typename?: 'Member';
    id?: string | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      label?: string | null;
      value?: string | null;
      identifier?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberDataWithoutLegalDataForCompanyMutationVariables = Exact<{
  memberId: Scalars['ID'];
  generalDataChange: MemberUpdateInput;
  email: EmailAddressInput;
}>;

export type UpdateMemberDataWithoutLegalDataForCompanyMutation = {
  __typename?: 'Mutation';
  memberUpdate?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
  } | null;
  memberChangePrimaryEmail?: {
    __typename?: 'Member';
    id?: string | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateBenefitsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ContractUpdateBenefitsInput;
}>;

export type UpdateBenefitsMutation = {
  __typename?: 'Mutation';
  contractUpdateBenefits?: {
    __typename?: 'Contract';
    id?: string | null;
    benefits?: Array<{
      __typename?: 'ContractBenefit';
      id?: string | null;
      employeePayPercentage?: number | null;
      employerPayPercentage?: number | null;
      dependentCount?: number | null;
      benefit?: {
        __typename?: 'Benefit';
        id: string;
        type?: BenefitType | null;
      } | null;
      params?: Array<{
        __typename?: 'ContractBenefitParam';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type UpdateCompensationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ContractUpdateCompensationInput;
}>;

export type UpdateCompensationMutation = {
  __typename?: 'Mutation';
  contractUpdateCompensation?: {
    __typename?: 'Contract';
    id?: string | null;
    compensation?: {
      __typename?: 'Compensation';
      additional?: string | null;
      payrollStart?: any | null;
      payType?: PayType | null;
      basePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      postProbationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      probationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      additionalPays?: Array<
        | {
            __typename?: 'FixedPayComponent';
            rateType?: RateType | null;
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            instalments?: Array<{
              __typename?: 'Instalment';
              amount?: number | null;
              currency?: CurrencyCode | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }> | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | {
            __typename?: 'PercentPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | null
      > | null;
      grant?: Array<{
        __typename?: 'ESOPGrant';
        id: string;
        condition?: string | null;
        grantDate: any;
        values?: Array<{
          __typename?: 'GrantValue';
          value: number;
          type: GrantValueType;
          currency?: CurrencyCode | null;
        }> | null;
        schedule: {
          __typename?: 'GrantSchedule';
          cliffPeriod?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
          vestingPeriod?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
          vestingSchedule?: Array<{
            __typename?: 'VestingSchedule';
            frequency?: VestingFrequency | null;
            time?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
            vestingValue?: {
              __typename?: 'VestingValue';
              value: number;
              type: VestingValueType;
            } | null;
          } | null> | null;
        };
      } | null> | null;
      deductions?: Array<{
        __typename?: 'Deduction';
        deductionDefinitionId: string;
        value: number;
        unit?: string | null;
      } | null> | null;
    } | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
      activationCutoff?: any | null;
    } | null;
  } | null;
};

export type UpdateComplianceMutationVariables = Exact<{
  contractId: Scalars['ID'];
  complianceInput: ContractUpdateComplianceInput;
}>;

export type UpdateComplianceMutation = {
  __typename?: 'Mutation';
  contractUpdateCompliance?: {
    __typename?: 'Contract';
    id?: string | null;
    compliance?:
      | {
          __typename: 'ComplianceContractor';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          orderForm?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceFreelance';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          preferredContractAgreementTemplate?: {
            __typename?: 'PreferredContractAgreementTemplateResponse';
            documentId?: string | null;
            template?: {
              __typename?: 'Template';
              id: string;
              displayName?: string | null;
              externalId?: string | null;
            } | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePEO';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePartnerEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type UpdateDeductionMutationVariables = Exact<{
  contractID: Scalars['ID'];
  input: Array<DeductionInput> | DeductionInput;
}>;

export type UpdateDeductionMutation = {
  __typename?: 'Mutation';
  contractUpdateDeductions?: Array<{
    __typename?: 'Deduction';
    deductionDefinitionId: string;
    value: number;
  } | null> | null;
};

export type UpdateContractEmploymentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ContractUpdateEmploymentInput;
}>;

export type UpdateContractEmploymentMutation = {
  __typename?: 'Mutation';
  contractUpdateEmployment?: {
    __typename?: 'Contract';
    id?: string | null;
    term?: ContractTerm | null;
    startOn?: any | null;
    endOn?: any | null;
    position?: string | null;
    scope?: string | null;
    employeeId?: string | null;
    workEmail?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type UpdateLeaveEntitlementMutationVariables = Exact<{
  id: Scalars['ID'];
  input:
    | Array<InputMaybe<ContractUpdateTimeOffEntitlementsInput>>
    | InputMaybe<ContractUpdateTimeOffEntitlementsInput>;
}>;

export type UpdateLeaveEntitlementMutation = {
  __typename?: 'Mutation';
  contractUpdateTimeOffEntitlements?: {
    __typename?: 'Contract';
    id?: string | null;
    compliance?:
      | {
          __typename: 'ComplianceContractor';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          orderForm?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceFreelance';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          preferredContractAgreementTemplate?: {
            __typename?: 'PreferredContractAgreementTemplateResponse';
            documentId?: string | null;
            template?: {
              __typename?: 'Template';
              id: string;
              displayName?: string | null;
              externalId?: string | null;
            } | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePEO';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePartnerEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | null;
  } | null;
};

export type UpdateMemberPrimaryEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EmailAddressInput;
}>;

export type UpdateMemberPrimaryEmailMutation = {
  __typename?: 'Mutation';
  memberChangePrimaryEmail?: {
    __typename?: 'Member';
    id?: string | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberUpdateInput;
}>;

export type UpdateMemberMutation = {
  __typename?: 'Mutation';
  memberUpdate?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
  } | null;
};

export type UploadCustomContractMutationVariables = Exact<{
  id: Scalars['ID'];
  file?: InputMaybe<Scalars['Upload']>;
}>;

export type UploadCustomContractMutation = {
  __typename?: 'Mutation';
  contractAgreementCustomUpload?: {
    __typename?: 'Contract';
    id?: string | null;
    compliance?:
      | {
          __typename: 'ComplianceContractor';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          orderForm?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceFreelance';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          preferredContractAgreementTemplate?: {
            __typename?: 'PreferredContractAgreementTemplateResponse';
            documentId?: string | null;
            template?: {
              __typename?: 'Template';
              id: string;
              displayName?: string | null;
              externalId?: string | null;
            } | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePEO';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePartnerEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type ContractConfirmWetInkContractPrepareMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ContractConfirmWetInkContractPrepareMutation = {
  __typename?: 'Mutation';
  contractConfirmWetInkContractPrepare?: {
    __typename?: 'Contract';
    id?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      statuses?: Array<ContractOnboardingStatus | null> | null;
      current?: ContractOnboardingStep | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
    } | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        status?: InvoiceStatus | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateContractBenefitDependentBasicDetailsMutationVariables = Exact<{
  contractBenefitId: Scalars['ID'];
  input:
    | Array<InputMaybe<ContractUpdateBenefitDependentInput>>
    | InputMaybe<ContractUpdateBenefitDependentInput>;
}>;

export type UpdateContractBenefitDependentBasicDetailsMutation = {
  __typename?: 'Mutation';
  contractUpdateBenefitDependentBasicDetails?: {
    __typename?: 'Contract';
    id?: string | null;
    benefits?: Array<{
      __typename?: 'ContractBenefit';
      dependents?: Array<{
        __typename?: 'ContractBenefitDependent';
        country?: CountryCode | null;
        dateOfBirth?: any | null;
        firstName?: string | null;
        gender?: Gender | null;
        id?: string | null;
        lastName?: string | null;
        relationshipToMember?: Relationship | null;
        legalData?: Array<{
          __typename?: 'ContractBenefitDependentLegalData';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CompleteContractMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompleteContractMutation = {
  __typename?: 'Mutation';
  contractMemberCompleted?: {
    __typename?: 'Contract';
    id?: string | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
      statuses?: Array<ContractOnboardingStatus | null> | null;
    } | null;
  } | null;
};

export type ContractMemberBenefitCompletedMutationVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type ContractMemberBenefitCompletedMutation = {
  __typename?: 'Mutation';
  contractMemberBenefitCompleted?: {
    __typename: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    type?: ContractType | null;
    onboarding?: {
      __typename: 'ContractOnboarding';
      current?: ContractOnboardingStep | null;
      status?: ContractOnboardingStatus | null;
      steps?: Array<ContractOnboardingStep | null> | null;
    } | null;
  } | null;
};

export type GetMemberCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberCompanyQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      company?: {
        __typename?: 'Company';
        id?: string | null;
        displayName?: string | null;
        status?: CompanyStatus | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetMemberCountryRequirementsQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
  workStatus?: InputMaybe<CountryWorkStatus>;
  fetchStage?: InputMaybe<FetchStage>;
}>;

export type GetMemberCountryRequirementsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    name?: string | null;
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: {
        __typename?: 'State';
        name?: string | null;
        code?: string | null;
      } | null;
      memberDataRequirements?: Array<{
        __typename?: 'DataFieldDefinition';
        key?: string | null;
        label?: string | null;
        description?: string | null;
        required?: boolean | null;
        editable?: boolean | null;
        domainType?: DomainType | null;
        dependsOn?: Array<{
          __typename?: 'ControllingField';
          key: string;
        }> | null;
        dataType?:
          | { __typename?: 'AutoCompleteField'; optionListType?: string | null }
          | { __typename?: 'CheckboxField'; defaultValue: boolean }
          | {
              __typename?: 'DateField';
              minDate?: any | null;
              maxDate?: any | null;
            }
          | {
              __typename?: 'DropDownField';
              values?: Array<string | null> | null;
            }
          | {
              __typename?: 'DropDownTextField';
              minLength?: number | null;
              maxLength?: number | null;
              type?: string | null;
              values?: Array<string | null> | null;
            }
          | { __typename?: 'FileField' }
          | {
              __typename?: 'TextField';
              type?: string | null;
              minLength?: number | null;
              maxLength?: number | null;
            }
          | null;
      } | null> | null;
    } | null;
    memberDocumentRequirements?: Array<{
      __typename?: 'LegalDocumentRequirement';
      key?: string | null;
      label?: string | null;
      description?: string | null;
      required?: boolean | null;
      acceptMultiple?: boolean | null;
      applyTo?: LegalDataRequirementApplicability | null;
      category?: LegalDocumentCategory | null;
    } | null> | null;
  } | null;
};

export type GetMemberDetailsForOnboardingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberDetailsForOnboardingQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    legalDocuments?: Array<{
      __typename?: 'LegalDocument';
      id: string;
      key?: string | null;
      label?: string | null;
      financialYear?: number | null;
      status?: LegalDocumentStatus | null;
      comment?: string | null;
      category?: LegalDocumentCategory | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        downloadUrl?: string | null;
      }> | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      position?: string | null;
      type?: ContractType | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
        statuses?: Array<ContractOnboardingStatus | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetMemberIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberIdQuery = {
  __typename?: 'Query';
  member?: { __typename?: 'Member'; id?: string | null } | null;
};

export type GetMemberVisaDocumentRequirementsQueryVariables = Exact<{
  destinationCountry: CountryCode;
  originCountry: CountryCode;
  categories: Array<LegalDocumentCategory> | LegalDocumentCategory;
}>;

export type GetMemberVisaDocumentRequirementsQuery = {
  __typename?: 'Query';
  visaDocumentRequirements: Array<{
    __typename?: 'LegalDocumentRequirement';
    key?: string | null;
    label?: string | null;
    description?: string | null;
    required?: boolean | null;
    acceptMultiple?: boolean | null;
    category?: LegalDocumentCategory | null;
  }>;
};

export type GetMemberWithComplianceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberWithComplianceQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullLegalName?: string | null;
    gender?: Gender | null;
    maritalStatus?: MaritalStatus | null;
    dateOfBirth?: any | null;
    age?: number | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
    addresses?: Array<{
      __typename?: 'Address';
      key?: string | null;
      street?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      province?: string | null;
      country?: CountryCode | null;
      zipcode?: string | null;
      postalCode?: string | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      employeeId?: string | null;
      term?: ContractTerm | null;
      type?: ContractType | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      status?: ContractStatus | null;
      workEmail?: string | null;
      currency?: CurrencyCode | null;
      workStatus?: CountryWorkStatus | null;
      legalEntityId?: string | null;
      position?: string | null;
      alreadyHired?: boolean | null;
      startOn?: any | null;
      endOn?: any | null;
      endedOn?: any | null;
      scope?: string | null;
      company?: {
        __typename?: 'Company';
        id?: string | null;
        displayName?: string | null;
        status?: CompanyStatus | null;
        primaryEntity?: {
          __typename?: 'LegalEntity';
          legalName?: string | null;
          id?: string | null;
        } | null;
        otherEntities?: Array<{
          __typename?: 'LegalEntity';
          id?: string | null;
          legalName?: string | null;
        } | null> | null;
      } | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
      compensation?: {
        __typename?: 'Compensation';
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        postProbationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        probationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
        deductions?: Array<{
          __typename?: 'Deduction';
          deductionDefinitionId: string;
          value: number;
          unit?: string | null;
        } | null> | null;
      } | null;
      performanceReviews?: Array<{
        __typename?: 'PerformanceReview';
        id: string;
        status?: PerformanceReviewStatus | null;
        effectiveDate?: any | null;
        createdOn?: any | null;
        designation?: {
          __typename?: 'Designation';
          name?: string | null;
          jobDescription?: string | null;
        } | null;
      } | null> | null;
      compliance?:
        | {
            __typename: 'ComplianceContractor';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            orderForm?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              blob?: string | null;
              htmlPreview?: string | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'ComplianceFreelance';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            offerLetter?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            preferredContractAgreementTemplate?: {
              __typename?: 'PreferredContractAgreementTemplateResponse';
              documentId?: string | null;
              template?: {
                __typename?: 'Template';
                id: string;
                displayName?: string | null;
                externalId?: string | null;
              } | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'CompliancePEO';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            offerLetter?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | null;
      benefits?: Array<{
        __typename?: 'ContractBenefit';
        id?: string | null;
        status?: ContractBenefitStatus | null;
        startOn?: any | null;
        endOn?: any | null;
        benefit?: {
          __typename?: 'Benefit';
          id: string;
          type?: BenefitType | null;
          packageName?: string | null;
          description?: string | null;
          benefitDocuments?: Array<{
            __typename?: 'BenefitDocument';
            benefitDocumentType?: BenefitDocumentType | null;
            files?: Array<{
              __typename?: 'Document';
              id?: string | null;
              name?: string | null;
              downloadUrl?: string | null;
            }> | null;
          } | null> | null;
          provider?: {
            __typename?: 'BenefitPartnerCountry';
            id?: string | null;
            emergencyPointOfContact?:
              | {
                  __typename?: 'CompanyUser';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'EmergencyPointOfContact';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'Member';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'OperationsUser';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | null;
          } | null;
        } | null;
      } | null> | null;
      offboarding?: {
        __typename?: 'ContractOffboarding';
        id?: string | null;
        contractId?: string | null;
        status?: ContractOffboardingStatus | null;
        type?: ContractOffboardingType | null;
        terminationReason?: string | null;
        lastWorkingDay?: any | null;
      } | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        department?: {
          __typename?: 'Department';
          id: string;
          description?: string | null;
          name: string;
        } | null;
      } | null;
    } | null> | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      label?: string | null;
      country?: CountryCode | null;
    } | null> | null;
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id?: string | null;
      accountName?: string | null;
      accountNumber?: string | null;
      bankName?: string | null;
      branchName?: string | null;
      currency?: CurrencyCode | null;
      country?: CountryCode | null;
      swiftCode?: string | null;
      localBankCode?: string | null;
    } | null> | null;
    legalDocuments?: Array<{
      __typename?: 'LegalDocument';
      id: string;
      key?: string | null;
      label?: string | null;
      financialYear?: number | null;
      status?: LegalDocumentStatus | null;
      comment?: string | null;
      category?: LegalDocumentCategory | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        downloadUrl?: string | null;
        size?: number | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type BenefitFragment = {
  __typename?: 'Benefit';
  id: string;
  type?: BenefitType | null;
  packageName?: string | null;
  description?: string | null;
  benefitDocuments?: Array<{
    __typename?: 'BenefitDocument';
    benefitDocumentType?: BenefitDocumentType | null;
    files?: Array<{
      __typename?: 'Document';
      id?: string | null;
      name?: string | null;
      downloadUrl?: string | null;
    }> | null;
  } | null> | null;
  provider?: {
    __typename?: 'BenefitPartnerCountry';
    id?: string | null;
    emergencyPointOfContact?:
      | {
          __typename?: 'CompanyUser';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'EmergencyPointOfContact';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'OperationsUser';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
        }
      | null;
  } | null;
};

export type GetMemberWithContractStartOnQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberWithContractStartOnQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      status?: ContractStatus | null;
      contractStarted?: boolean | null;
      startOn?: any | null;
      incomingPayrollCutoffDate?: any | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        activationCutoff?: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetMemberQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullLegalName?: string | null;
    gender?: Gender | null;
    maritalStatus?: MaritalStatus | null;
    dateOfBirth?: any | null;
    age?: number | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
    addresses?: Array<{
      __typename?: 'Address';
      key?: string | null;
      street?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      province?: string | null;
      country?: CountryCode | null;
      zipcode?: string | null;
      postalCode?: string | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      term?: ContractTerm | null;
      type?: ContractType | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      status?: ContractStatus | null;
      currency?: CurrencyCode | null;
      workStatus?: CountryWorkStatus | null;
      position?: string | null;
      alreadyHired?: boolean | null;
      startOn?: any | null;
      createdOn?: any | null;
      endOn?: any | null;
      contractStarted?: boolean | null;
      legalEntityId?: string | null;
      company?: {
        __typename?: 'Company';
        id?: string | null;
        displayName?: string | null;
        status?: CompanyStatus | null;
      } | null;
      benefits?: Array<{
        __typename?: 'ContractBenefit';
        id?: string | null;
        billAmount?: number | null;
        discountTermPercentage?: number | null;
        employeePayPercentage?: number | null;
        employerPayPercentage?: number | null;
        externalPolicyFileUrl?: string | null;
        externalPolicyId?: string | null;
        startOn?: any | null;
        endOn?: any | null;
        dependentCount?: number | null;
        status?: ContractBenefitStatus | null;
        dependents?: Array<{
          __typename?: 'ContractBenefitDependent';
          id?: string | null;
          dateOfBirth?: any | null;
          startOn?: any | null;
          endOn?: any | null;
          externalPolicyFileUrl?: string | null;
          externalPolicyId?: string | null;
          firstName?: string | null;
          gender?: Gender | null;
          lastName?: string | null;
          relationshipToMember?: Relationship | null;
          documents?: Array<{
            __typename?: 'ContractBenefitDependentDocument';
            key?: ContractBenefitDependentDocumentType | null;
            files?: Array<{
              __typename?: 'Document';
              id?: string | null;
              downloadUrl?: string | null;
              name?: string | null;
              size?: number | null;
            }> | null;
          } | null> | null;
          legalData?: Array<{
            __typename?: 'ContractBenefitDependentLegalData';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
        benefit?: {
          __typename?: 'Benefit';
          id: string;
          cost?: number | null;
          costingType?: BenefitCostingType | null;
          packageName?: string | null;
          type?: BenefitType | null;
          benefitDocuments?: Array<{
            __typename?: 'BenefitDocument';
            benefitDocumentType?: BenefitDocumentType | null;
            files?: Array<{
              __typename?: 'Document';
              id?: string | null;
              downloadUrl?: string | null;
              name?: string | null;
            }> | null;
          } | null> | null;
          memberOnboardingKit?: {
            __typename?: 'BenefitMemberOnboardingKit';
            memberOnboardingKitFile?: {
              __typename?: 'BenefitDocument';
              benefitDocumentType?: BenefitDocumentType | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                downloadUrl?: string | null;
                name?: string | null;
              }> | null;
            } | null;
          } | null;
          features?: Array<{
            __typename?: 'BenefitFeatures';
            key?: string | null;
            label?: string | null;
            value?: string | null;
          } | null> | null;
          packageTier?: {
            __typename?: 'BenefitPackageTier';
            tier?: BenefitTier | null;
          } | null;
          provider?: {
            __typename?: 'BenefitPartnerCountry';
            id?: string | null;
            partnerName?: string | null;
            status?: BenefitPartnerStatus | null;
            isInsuranceMandatory?: boolean | null;
            emergencyPointOfContact?:
              | {
                  __typename?: 'CompanyUser';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'EmergencyPointOfContact';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'Member';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'OperationsUser';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | null;
            metaData?: {
              __typename?: 'BenefitMetaData';
              pocName?: string | null;
              emails?: Array<{
                __typename?: 'EmailAddress';
                type?: string | null;
                email?: string | null;
              } | null> | null;
              phoneNos?: Array<{
                __typename?: 'PhoneNumber';
                phoneNo?: string | null;
                type?: string | null;
              } | null> | null;
            } | null;
            brokerData?: {
              __typename?: 'BenefitBrokerData';
              name?: string | null;
            } | null;
            entity?: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
            } | null;
          } | null;
        } | null;
        params?: Array<{
          __typename?: 'ContractBenefitParam';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'ComplianceFreelance';
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'CompliancePEO';
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'CompliancePartnerEOR';
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | null;
      compensation?: {
        __typename?: 'Compensation';
        deductions?: Array<{
          __typename?: 'Deduction';
          deductionDefinitionId: string;
          value: number;
          unit?: string | null;
        } | null> | null;
      } | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
        statuses?: Array<ContractOnboardingStatus | null> | null;
        activationCutoff?: any | null;
      } | null;
      paymentAccountRequirements?: Array<{
        __typename?: 'PaymentAccountRequirement';
        accountType?: PaymentAccountType | null;
        transferType?: TransferType | null;
        sourceCurrency?: CurrencyCode | null;
        targetCurrency?: CurrencyCode | null;
        paymentPartner?: PaymentPartnerCode | null;
        paymentAccountRequirementType?: string | null;
        requirementFields?: Array<{
          __typename?: 'RequirementField';
          key?: string | null;
          label?: string | null;
          isMandatory?: boolean | null;
          type?: string | null;
          regex?: string | null;
          errorMessage?: string | null;
          hasSubFields?: boolean | null;
          allowedValues?: Array<{
            __typename?: 'AllowedValue';
            key?: string | null;
            value?: string | null;
          } | null> | null;
          subFields?: Array<{
            __typename?: 'RequirementSubField';
            key?: string | null;
            value?: Array<{
              __typename?: 'RequirementField';
              key?: string | null;
              label?: string | null;
              isMandatory?: boolean | null;
              type?: string | null;
              regex?: string | null;
              errorMessage?: string | null;
              hasSubFields?: boolean | null;
              allowedValues?: Array<{
                __typename?: 'AllowedValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
              subFields?: Array<{
                __typename?: 'RequirementSubField';
                key?: string | null;
                value?: Array<{
                  __typename?: 'RequirementField';
                  key?: string | null;
                  label?: string | null;
                  isMandatory?: boolean | null;
                  type?: string | null;
                  regex?: string | null;
                  errorMessage?: string | null;
                  allowedValues?: Array<{
                    __typename?: 'AllowedValue';
                    key?: string | null;
                    value?: string | null;
                  } | null> | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
      payslips?: Array<{
        __typename?: 'Payslip';
        id?: string | null;
      } | null> | null;
      categoryApproverInfos?: Array<{
        __typename?: 'CategoryApproverInfo';
        category?: ApprovalCategory | null;
        approverUsers?: Array<
          | {
              __typename?: 'CompanyUser';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            }
          | {
              __typename?: 'EmergencyPointOfContact';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            }
          | {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            }
          | {
              __typename?: 'OperationsUser';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            }
          | null
        > | null;
      } | null> | null;
    } | null> | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      label?: string | null;
      country?: CountryCode | null;
      identifier?: string | null;
    } | null> | null;
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id?: string | null;
      version?: number | null;
      accountName?: string | null;
      accountNumber?: string | null;
      bankName?: string | null;
      branchName?: string | null;
      currency?: CurrencyCode | null;
      country?: CountryCode | null;
      swiftCode?: string | null;
      localBankCode?: string | null;
      paymentAccountRequirementType?: string | null;
      accountDetails?: Array<{
        __typename?: 'BankAccountDetail';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      bankStatements?: Array<{
        __typename?: 'FileLink';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        link?: string | null;
        contentType?: string | null;
        size?: number | null;
      } | null> | null;
    } | null> | null;
    legalDocuments?: Array<{
      __typename?: 'LegalDocument';
      id: string;
      key?: string | null;
      label?: string | null;
      financialYear?: number | null;
      status?: LegalDocumentStatus | null;
      comment?: string | null;
      category?: LegalDocumentCategory | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        downloadUrl?: string | null;
        size?: number | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type MemberUpdateBasicDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberUpdateBasicDetailsInput;
}>;

export type MemberUpdateBasicDetailsMutation = {
  __typename?: 'Mutation';
  memberUpdateBasicDetails?: {
    __typename?: 'Member';
    id?: string | null;
    fullLegalName?: string | null;
    dateOfBirth?: any | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    addresses?: Array<{
      __typename?: 'Address';
      key?: string | null;
      street?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      province?: string | null;
      country?: CountryCode | null;
      zipcode?: string | null;
      postalCode?: string | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      onboarding?: {
        __typename?: 'ContractOnboarding';
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type MemberUpdateLegalDataMutationVariables = Exact<{
  id: Scalars['ID'];
  input: Array<LegalDataInput> | LegalDataInput;
}>;

export type MemberUpdateLegalDataMutation = {
  __typename?: 'Mutation';
  memberUpdateLegalData?: {
    __typename?: 'Member';
    id?: string | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      label?: string | null;
      country?: CountryCode | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type OptOutInsuranceMutationVariables = Exact<{
  contractBenefitId: Scalars['ID'];
  reasons: Array<Scalars['String']> | Scalars['String'];
}>;

export type OptOutInsuranceMutation = {
  __typename?: 'Mutation';
  optOutInsurance?: {
    __typename?: 'ContractBenefit';
    id?: string | null;
    status?: ContractBenefitStatus | null;
    benefit?: {
      __typename?: 'Benefit';
      id: string;
      type?: BenefitType | null;
    } | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      status?: ContractStatus | null;
      type?: ContractType | null;
      onboarding?: {
        __typename: 'ContractOnboarding';
        current?: ContractOnboardingStep | null;
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateBankDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  bankAccount: BankAccountInput;
}>;

export type UpdateBankDetailsMutation = {
  __typename?: 'Mutation';
  memberUpdateBankDetails?: {
    __typename?: 'Member';
    id?: string | null;
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id?: string | null;
      accountName?: string | null;
      accountNumber?: string | null;
      bankName?: string | null;
      branchName?: string | null;
      swiftCode?: string | null;
      localBankCode?: string | null;
      bankStatements?: Array<{
        __typename?: 'FileLink';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        link?: string | null;
        contentType?: string | null;
        size?: number | null;
      } | null> | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberDataForOnboardingMemberMutationVariables = Exact<{
  memberId: Scalars['ID'];
  generalDataChange: MemberUpdateInput;
  basicDetailsChange: MemberUpdateBasicDetailsInput;
  legalDataChange: Array<LegalDataInput> | LegalDataInput;
}>;

export type UpdateMemberDataForOnboardingMemberMutation = {
  __typename?: 'Mutation';
  memberUpdate?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
  } | null;
  memberUpdateBasicDetails?: {
    __typename?: 'Member';
    id?: string | null;
    fullLegalName?: string | null;
    dateOfBirth?: any | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    addresses?: Array<{
      __typename?: 'Address';
      key?: string | null;
      street?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      province?: string | null;
      country?: CountryCode | null;
      zipcode?: string | null;
      postalCode?: string | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      onboarding?: {
        __typename?: 'ContractOnboarding';
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
  memberUpdateLegalData?: {
    __typename?: 'Member';
    id?: string | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      label?: string | null;
      country?: CountryCode | null;
      identifier?: string | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationVariables = Exact<{
  memberId: Scalars['ID'];
  generalDataChange: MemberUpdateInput;
  basicDetailsChange: MemberUpdateBasicDetailsInput;
}>;

export type UpdateMemberDataForOnboardingMemberWithoutLegalDataMutation = {
  __typename?: 'Mutation';
  memberUpdate?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
  } | null;
  memberUpdateBasicDetails?: {
    __typename?: 'Member';
    id?: string | null;
    fullLegalName?: string | null;
    dateOfBirth?: any | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    addresses?: Array<{
      __typename?: 'Address';
      key?: string | null;
      street?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      province?: string | null;
      country?: CountryCode | null;
      zipcode?: string | null;
      postalCode?: string | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      onboarding?: {
        __typename?: 'ContractOnboarding';
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateContractBenefitDependentDocumentsMutationVariables = Exact<{
  contractBenefitDependentId: Scalars['ID'];
  key?: InputMaybe<ContractBenefitDependentDocumentType>;
  documents?: InputMaybe<
    Array<InputMaybe<Scalars['Upload']>> | InputMaybe<Scalars['Upload']>
  >;
}>;

export type UpdateContractBenefitDependentDocumentsMutation = {
  __typename?: 'Mutation';
  contractUpdateBenefitDependentDocuments?: {
    __typename?: 'Contract';
    id?: string | null;
    benefits?: Array<{
      __typename?: 'ContractBenefit';
      dependents?: Array<{
        __typename?: 'ContractBenefitDependent';
        id?: string | null;
        firstName?: string | null;
        gender?: Gender | null;
        lastName?: string | null;
        relationshipToMember?: Relationship | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateLegalDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  data: Array<LegalDataInput> | LegalDataInput;
}>;

export type UpdateLegalDetailsMutation = {
  __typename?: 'Mutation';
  memberUpdateLegalData?: {
    __typename?: 'Member';
    id?: string | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      label?: string | null;
      country?: CountryCode | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateLegalDocumentsMutationVariables = Exact<{
  id: Scalars['ID'];
  data: Array<LegalDocumentInput> | LegalDocumentInput;
}>;

export type UpdateLegalDocumentsMutation = {
  __typename?: 'Mutation';
  memberUpdateDocument?: {
    __typename?: 'Member';
    id?: string | null;
    legalDocuments?: Array<{
      __typename?: 'LegalDocument';
      id: string;
      key?: string | null;
      label?: string | null;
      financialYear?: number | null;
      status?: LegalDocumentStatus | null;
      comment?: string | null;
      category?: LegalDocumentCategory | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        downloadUrl?: string | null;
        size?: number | null;
      }> | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateVisaDocumentsMutationVariables = Exact<{
  id: Scalars['ID'];
  data: Array<LegalDocumentInput> | LegalDocumentInput;
}>;

export type UpdateVisaDocumentsMutation = {
  __typename?: 'Mutation';
  memberUpdateVisaDocument?: {
    __typename?: 'Member';
    id?: string | null;
    legalDocuments?: Array<{
      __typename?: 'LegalDocument';
      id: string;
      key?: string | null;
      label?: string | null;
      financialYear?: number | null;
      status?: LegalDocumentStatus | null;
      comment?: string | null;
      category?: LegalDocumentCategory | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        downloadUrl?: string | null;
        size?: number | null;
      }> | null;
    } | null> | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
    } | null> | null;
  } | null;
};

export type DeleteCompensationGrantMutationVariables = Exact<{
  contractId: Scalars['ID'];
  grantId: Scalars['ID'];
}>;

export type DeleteCompensationGrantMutation = {
  __typename?: 'Mutation';
  contractDeleteCompensationGrant?: Array<{
    __typename?: 'ESOPGrant';
    id: string;
  } | null> | null;
};

export type GetCompanyUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyUsersQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    managers?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      status?: CompanyUserStatus | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
    signatories?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
    billingContact?: {
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetContractDepositExternalLinkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractDepositExternalLinkQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        dueDate?: any | null;
        externalSystem?: ExternalSystemType | null;
        externalLink?: string | null;
        status?: InvoiceStatus | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetContractDepositWithPdfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractDepositWithPdfQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        dueDate?: any | null;
        externalSystem?: ExternalSystemType | null;
        status?: InvoiceStatus | null;
        document?: {
          __typename?: 'DocumentReadable';
          name?: string | null;
          extension?: string | null;
          contentType?: string | null;
          blob?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetContractDetailRestrictionsByCountryQueryVariables = Exact<{
  country: CountryCode;
}>;

export type GetContractDetailRestrictionsByCountryQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      contractDetailRestrictions?: Array<{
        __typename?: 'ContractDetailRestriction';
        id?: string | null;
        alternatives?: Array<string> | null;
        countryCode?: CountryCode | null;
        field?: ContractDetailRestrictionField | null;
        operator?: ContractDetailRestrictionOperator | null;
        searchTerm?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type GetContractPerformanceReviewNoSalaryReviewsQueryVariables = Exact<{
  contractId: Scalars['ID'];
  includeAnyStatus?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetContractPerformanceReviewNoSalaryReviewsQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    performanceReviews?: Array<{
      __typename?: 'PerformanceReview';
      id: string;
      status?: PerformanceReviewStatus | null;
      effectiveDate?: any | null;
      createdOn?: any | null;
      comment?: string | null;
      designation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
      previousDesignation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
      reviewDocument?: {
        __typename?: 'DocumentReadable';
        id?: string | null;
        name?: string | null;
        link?: string | null;
        blob?: string | null;
        extension?: string | null;
        contentType?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetContractV2QueryVariables = Exact<{
  id: Scalars['ID'];
  skipDepositPayable?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetContractV2Query = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    term?: ContractTerm | null;
    type?: ContractType | null;
    currency?: CurrencyCode | null;
    country?: CountryCode | null;
    countryStateCode?: string | null;
    legalEntityId?: string | null;
    employeeId?: string | null;
    workStatus?: CountryWorkStatus | null;
    status?: ContractStatus | null;
    workEmail?: string | null;
    position?: string | null;
    alreadyHired?: boolean | null;
    startOn?: any | null;
    createdOn?: any | null;
    endOn?: any | null;
    endedOn?: any | null;
    offboardingNote?: string | null;
    scope?: string | null;
    contractStarted?: boolean | null;
    contractBlocked?: boolean | null;
    company?: {
      __typename?: 'Company';
      id?: string | null;
      displayName?: string | null;
      primaryEntity?: {
        __typename?: 'LegalEntity';
        legalName?: string | null;
        id?: string | null;
      } | null;
      otherEntities?: Array<{
        __typename?: 'LegalEntity';
        id?: string | null;
        legalName?: string | null;
      } | null> | null;
    } | null;
    workShift?: {
      __typename?: 'WorkShift';
      startDay?: DayOfWeek | null;
      endDay?: DayOfWeek | null;
      workingHours?: {
        __typename?: 'WorkingHours';
        startTime?: any | null;
        endTime?: any | null;
      } | null;
    } | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      fullLegalName?: string | null;
      status?: MemberStatus | null;
      gender?: Gender | null;
      maritalStatus?: MaritalStatus | null;
      dateOfBirth?: any | null;
      age?: number | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        type?: string | null;
        email?: string | null;
      } | null> | null;
      addresses?: Array<{
        __typename?: 'Address';
        key?: string | null;
        street?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        province?: string | null;
        country?: CountryCode | null;
        zipcode?: string | null;
        postalCode?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        type?: string | null;
        phoneNo?: string | null;
      } | null> | null;
      nationalities?: Array<{
        __typename?: 'MemberNationalogy';
        type?: Nationalogy | null;
        country?: CountryCode | null;
      } | null> | null;
      legalData?: Array<{
        __typename?: 'LegalData';
        key?: string | null;
        value?: string | null;
        identifier?: string | null;
        label?: string | null;
        country?: CountryCode | null;
      } | null> | null;
      bankAccounts?: Array<{
        __typename?: 'BankAccount';
        id?: string | null;
        version?: number | null;
        accountName?: string | null;
        accountNumber?: string | null;
        bankName?: string | null;
        branchName?: string | null;
        currency?: CurrencyCode | null;
        country?: CountryCode | null;
        swiftCode?: string | null;
        localBankCode?: string | null;
        paymentAccountRequirementType?: string | null;
        accountDetails?: Array<{
          __typename?: 'BankAccountDetail';
          key?: string | null;
          label?: string | null;
          value?: string | null;
        } | null> | null;
        accountRequirementContext?: {
          __typename?: 'AccountRequirementContext';
          paymentAccountRequirementType?: string | null;
        } | null;
        bankStatements?: Array<{
          __typename?: 'FileLink';
          id?: string | null;
          name?: string | null;
          extension?: string | null;
          link?: string | null;
          contentType?: string | null;
          size?: number | null;
        } | null> | null;
      } | null> | null;
      legalDocuments?: Array<{
        __typename?: 'LegalDocument';
        id: string;
        key?: string | null;
        label?: string | null;
        financialYear?: number | null;
        status?: LegalDocumentStatus | null;
        comment?: string | null;
        category?: LegalDocumentCategory | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        files?: Array<{
          __typename?: 'Document';
          id?: string | null;
          name?: string | null;
          extension?: string | null;
          contentType?: string | null;
          downloadUrl?: string | null;
          size?: number | null;
        }> | null;
      } | null> | null;
      emergencyContact?: {
        __typename?: 'EmergencyContact';
        name?: string | null;
        relationship?: Relationship | null;
        phoneNumber?: {
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
          type?: string | null;
        } | null;
      } | null;
      education?: Array<{
        __typename?: 'Education';
        degree?: string | null;
        gpa?: number | null;
        grade?: string | null;
        lastSchoolAttended?: string | null;
        yearOfPassing?: any | null;
      } | null> | null;
      previousEmployers?: Array<{
        __typename?: 'PreviousEmployer';
        designation?: string | null;
        endDate?: any | null;
        name?: string | null;
        startDate?: any | null;
      } | null> | null;
      basicInfo?: {
        __typename?: 'MemberBasicInfo';
        firstName?: string | null;
        fullLegalName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      } | null;
      personalInfo?: {
        __typename?: 'MemberPersonalInfo';
        age?: number | null;
        gender?: Gender | null;
        dateOfBirth?: any | null;
        maritalStatus?: MaritalStatus | null;
        religion?: string | null;
        nationalities?: Array<{
          __typename?: 'MemberNationalogy';
          type?: Nationalogy | null;
          country?: CountryCode | null;
        } | null> | null;
      } | null;
      contactInfo?: {
        __typename?: 'MemberContactInfo';
        email?: { __typename?: 'EmailAddress'; email?: string | null } | null;
        mobile?: { __typename?: 'PhoneNumber'; phoneNo?: string | null } | null;
        phone?: { __typename?: 'PhoneNumber'; phoneNo?: string | null } | null;
      } | null;
      addressDetails?: {
        __typename?: 'MemberAddress';
        currentAddress?: {
          __typename?: 'Address';
          city?: string | null;
          country?: CountryCode | null;
          key?: string | null;
          landmark?: string | null;
          line1?: string | null;
          line2?: string | null;
          postalCode?: string | null;
          province?: string | null;
          state?: string | null;
          street?: string | null;
          zipcode?: string | null;
        } | null;
        permanentAddress?: {
          __typename?: 'Address';
          city?: string | null;
          country?: CountryCode | null;
          key?: string | null;
          landmark?: string | null;
          line1?: string | null;
          line2?: string | null;
          postalCode?: string | null;
          province?: string | null;
          state?: string | null;
          street?: string | null;
          zipcode?: string | null;
        } | null;
      } | null;
      changeRequests?: Array<{
        __typename?: 'MemberChangeRequest';
        id: string;
        status?: MemberChangeRequestStatus | null;
        category?: MemberChangeCategory | null;
        message?: string | null;
        createdOn?: any | null;
        files?: Array<{
          __typename?: 'Document';
          id?: string | null;
          contentType?: string | null;
          extension?: string | null;
          downloadUrl?: string | null;
          name?: string | null;
        }> | null;
        items?: Array<
          | {
              __typename?: 'AddressParam';
              key?: string | null;
              addressValue?: {
                __typename?: 'Address';
                street?: string | null;
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                state?: string | null;
                country?: CountryCode | null;
                province?: string | null;
                zipcode?: string | null;
                postalCode?: string | null;
              } | null;
            }
          | {
              __typename?: 'BankAccountParam';
              key?: string | null;
              bankAccountValue?: {
                __typename?: 'BankAccount';
                bankName?: string | null;
                accountName?: string | null;
                accountNumber?: string | null;
                branchName?: string | null;
                localBankCode?: string | null;
                swiftCode?: string | null;
                paymentAccountRequirementType?: string | null;
                version?: number | null;
                accountDetails?: Array<{
                  __typename?: 'BankAccountDetail';
                  key?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null;
            }
          | {
              __typename?: 'DateParam';
              key?: string | null;
              dateValue?: any | null;
            }
          | {
              __typename?: 'EmailAddressParam';
              key?: string | null;
              emailValue?: {
                __typename?: 'EmailAddress';
                email?: string | null;
                type?: string | null;
              } | null;
            }
          | {
              __typename?: 'GenderParam';
              key?: string | null;
              genderValue?: Gender | null;
            }
          | {
              __typename?: 'IntParam';
              key?: string | null;
              intValue?: number | null;
            }
          | {
              __typename?: 'LegalDataParam';
              key?: string | null;
              legalValue?: {
                __typename?: 'LegalData';
                key?: string | null;
                value?: string | null;
                identifier?: string | null;
              } | null;
            }
          | {
              __typename?: 'LegalDocumentParam';
              key?: string | null;
              legalDocumentValue?: {
                __typename?: 'LegalDocument';
                key?: string | null;
                label?: string | null;
                category?: LegalDocumentCategory | null;
                financialYear?: number | null;
                files?: Array<{
                  __typename?: 'Document';
                  id?: string | null;
                  name?: string | null;
                  extension?: string | null;
                  contentType?: string | null;
                  downloadUrl?: string | null;
                  size?: number | null;
                }> | null;
              } | null;
            }
          | {
              __typename?: 'MemberNationalogyParam';
              key?: string | null;
              nationalogyValue?: {
                __typename?: 'MemberNationalogy';
                type?: Nationalogy | null;
                country?: CountryCode | null;
              } | null;
            }
          | {
              __typename?: 'PhoneNumberParam';
              key?: string | null;
              phoneValue?: {
                __typename?: 'PhoneNumber';
                phoneNo?: string | null;
                type?: string | null;
              } | null;
            }
          | {
              __typename?: 'StringParam';
              key?: string | null;
              strValue?: string | null;
            }
          | null
        > | null;
      } | null> | null;
    } | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      statuses?: Array<ContractOnboardingStatus | null> | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
      activationCutoff?: any | null;
      contractDocumentWorkflow?: {
        __typename?: 'ContractDocumentWorkflow';
        currentStatus?: ContractDocumentStatus | null;
      } | null;
    } | null;
    compensation?: {
      __typename?: 'Compensation';
      additional?: string | null;
      payrollStart?: any | null;
      payType?: PayType | null;
      basePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      postProbationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      probationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      additionalPays?: Array<
        | {
            __typename?: 'FixedPayComponent';
            rateType?: RateType | null;
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            instalments?: Array<{
              __typename?: 'Instalment';
              amount?: number | null;
              currency?: CurrencyCode | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }> | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | {
            __typename?: 'PercentPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | null
      > | null;
      grant?: Array<{
        __typename?: 'ESOPGrant';
        id: string;
        condition?: string | null;
        grantDate: any;
        values?: Array<{
          __typename?: 'GrantValue';
          value: number;
          type: GrantValueType;
          currency?: CurrencyCode | null;
        }> | null;
        schedule: {
          __typename?: 'GrantSchedule';
          cliffPeriod?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
          vestingPeriod?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
          vestingSchedule?: Array<{
            __typename?: 'VestingSchedule';
            frequency?: VestingFrequency | null;
            time?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
            vestingValue?: {
              __typename?: 'VestingValue';
              value: number;
              type: VestingValueType;
            } | null;
          } | null> | null;
        };
      } | null> | null;
      deductions?: Array<{
        __typename?: 'Deduction';
        deductionDefinitionId: string;
        value: number;
        unit?: string | null;
      } | null> | null;
    } | null;
    benefits?: Array<{
      __typename?: 'ContractBenefit';
      id?: string | null;
      status?: ContractBenefitStatus | null;
      startOn?: any | null;
      endOn?: any | null;
      dependentCount?: number | null;
      employeePayPercentage?: number | null;
      employerPayPercentage?: number | null;
      externalPolicyId?: string | null;
      billAmount?: number | null;
      benefitPackageCost?: {
        __typename?: 'BenefitPackageCost';
        id: string;
        premium?: number | null;
        premiumCurrency?: CurrencyCode | null;
        premiumFrequency?: BenefitPremiumFrequency | null;
      } | null;
      dependents?: Array<{
        __typename?: 'ContractBenefitDependent';
        firstName?: string | null;
        lastName?: string | null;
        gender?: Gender | null;
        dateOfBirth?: any | null;
        relationshipToMember?: Relationship | null;
        externalPolicyId?: string | null;
        startOn?: any | null;
        endOn?: any | null;
        benefitPackageCost?: {
          __typename?: 'BenefitPackageCost';
          id: string;
          premium?: number | null;
          premiumCurrency?: CurrencyCode | null;
          premiumFrequency?: BenefitPremiumFrequency | null;
        } | null;
      } | null> | null;
      benefit?: {
        __typename?: 'Benefit';
        id: string;
        type?: BenefitType | null;
        packageName?: string | null;
        description?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        currency?: CurrencyCode | null;
        cost?: number | null;
        costingType?: BenefitCostingType | null;
        frequency?: RateFrequency | null;
        packageTier?: {
          __typename?: 'BenefitPackageTier';
          tier?: BenefitTier | null;
        } | null;
        memberOnboardingKit?: {
          __typename?: 'BenefitMemberOnboardingKit';
          memberOnboardingKitFile?: {
            __typename?: 'BenefitDocument';
            files?: Array<{
              __typename?: 'Document';
              id?: string | null;
              downloadUrl?: string | null;
            }> | null;
          } | null;
        } | null;
        benefitDocuments?: Array<{
          __typename?: 'BenefitDocument';
          benefitDocumentType?: BenefitDocumentType | null;
          files?: Array<{
            __typename?: 'Document';
            id?: string | null;
            name?: string | null;
            downloadUrl?: string | null;
          }> | null;
        } | null> | null;
        provider?: {
          __typename?: 'BenefitPartnerCountry';
          id?: string | null;
          partnerName?: string | null;
          brokerData?: {
            __typename?: 'BenefitBrokerData';
            name?: string | null;
          } | null;
          coverageGroup?: {
            __typename?: 'InsuranceCoverageGroup';
            id?: string | null;
            name?: string | null;
            group?: Array<{
              __typename?: 'InsuranceCoverage';
              maxCount: number;
              relation?: {
                __typename?: 'InsuranceRelation';
                familyMember?: string | null;
                id?: string | null;
              } | null;
            } | null> | null;
          } | null;
          emergencyPointOfContact?:
            | {
                __typename?: 'CompanyUser';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | {
                __typename?: 'EmergencyPointOfContact';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | {
                __typename?: 'Member';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | {
                __typename?: 'OperationsUser';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | null;
          metaData?: {
            __typename?: 'BenefitMetaData';
            pocName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              type?: string | null;
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
          partner?: {
            __typename?: 'Partner';
            id?: string | null;
            name?: string | null;
          } | null;
        } | null;
        features?: Array<{
          __typename?: 'BenefitFeatures';
          key?: string | null;
          label?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
      params?: Array<{
        __typename?: 'ContractBenefitParam';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    compliance?:
      | {
          __typename: 'ComplianceContractor';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          orderForm?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceFreelance';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          preferredContractAgreementTemplate?: {
            __typename?: 'PreferredContractAgreementTemplateResponse';
            documentId?: string | null;
            template?: {
              __typename?: 'Template';
              id: string;
              displayName?: string | null;
              externalId?: string | null;
            } | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePEO';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePartnerEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | null;
    payslips?: Array<{
      __typename?: 'Payslip';
      id?: string | null;
      createdOn?: any | null;
      month?: {
        __typename?: 'PayrollMonth';
        month?: number | null;
        year?: number | null;
      } | null;
      payslipDocument?: {
        __typename?: 'PayslipDocument';
        downloadUrl?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    categoryApproverInfos?: Array<{
      __typename?: 'CategoryApproverInfo';
      category?: ApprovalCategory | null;
      approverUsers?: Array<
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            title?: string | null;
            role?: CompanyUserRole | null;
            roles?: Array<CompanyUserRole | null> | null;
            isSignatory?: boolean | null;
            isBillingContact?: boolean | null;
            status?: CompanyUserStatus | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | { __typename?: 'EmergencyPointOfContact' }
        | { __typename?: 'Member' }
        | { __typename?: 'OperationsUser' }
        | null
      > | null;
    } | null> | null;
    timeOff?: {
      __typename?: 'ContractTimeOff';
      summary?: Array<{
        __typename?: 'TimeOffType';
        key?: string | null;
        entitled?: number | null;
        taken?: number | null;
        pending?: number | null;
        remaining?: number | null;
        periodEnd?: any | null;
        definition?: {
          __typename?: 'TimeOffTypeDefinition';
          type?: string | null;
          label?: string | null;
        } | null;
      } | null> | null;
    } | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        status?: InvoiceStatus | null;
        dueDate?: any | null;
        externalSystem?: ExternalSystemType | null;
      } | null;
    } | null> | null;
    unresolvedBlockEvents?: Array<{
      __typename?: 'BlockEvent';
      id: string;
      source: BlockSource;
      status: BlockStatus;
      blockReason: string;
      blockData?: {
        __typename: 'ChangeRequestBlockData';
        changeRequestId: string;
      } | null;
    }> | null;
    offboarding?: {
      __typename?: 'ContractOffboarding';
      id?: string | null;
      contractId?: string | null;
      status?: ContractOffboardingStatus | null;
      type?: ContractOffboardingType | null;
      terminationReason?: string | null;
      lastWorkingDay?: any | null;
    } | null;
    paymentAccountRequirements?: Array<{
      __typename?: 'PaymentAccountRequirement';
      accountType?: PaymentAccountType | null;
      transferType?: TransferType | null;
      sourceCurrency?: CurrencyCode | null;
      targetCurrency?: CurrencyCode | null;
      paymentPartner?: PaymentPartnerCode | null;
      paymentAccountRequirementType?: string | null;
      requirementFields?: Array<{
        __typename?: 'RequirementField';
        key?: string | null;
        label?: string | null;
        isMandatory?: boolean | null;
        type?: string | null;
        regex?: string | null;
        errorMessage?: string | null;
        hasSubFields?: boolean | null;
        allowedValues?: Array<{
          __typename?: 'AllowedValue';
          key?: string | null;
          value?: string | null;
        } | null> | null;
        subFields?: Array<{
          __typename?: 'RequirementSubField';
          key?: string | null;
          value?: Array<{
            __typename?: 'RequirementField';
            key?: string | null;
            label?: string | null;
            isMandatory?: boolean | null;
            type?: string | null;
            regex?: string | null;
            errorMessage?: string | null;
            hasSubFields?: boolean | null;
            allowedValues?: Array<{
              __typename?: 'AllowedValue';
              key?: string | null;
              value?: string | null;
            } | null> | null;
            subFields?: Array<{
              __typename?: 'RequirementSubField';
              key?: string | null;
              value?: Array<{
                __typename?: 'RequirementField';
                key?: string | null;
                label?: string | null;
                isMandatory?: boolean | null;
                type?: string | null;
                regex?: string | null;
                errorMessage?: string | null;
                allowedValues?: Array<{
                  __typename?: 'AllowedValue';
                  key?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    reportsToManager?: {
      __typename?: 'Manager';
      id: string;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        position?: string | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null;
    orgAttributes?: {
      __typename?: 'OrgAttributes';
      costCenter?: {
        __typename?: 'CostCenter';
        id?: string | null;
        description?: string | null;
        name?: string | null;
      } | null;
      department?: {
        __typename?: 'Department';
        id: string;
        description?: string | null;
        name: string;
      } | null;
    } | null;
    performanceReviews?: Array<{
      __typename?: 'PerformanceReview';
      id: string;
      status?: PerformanceReviewStatus | null;
      effectiveDate?: any | null;
      createdOn?: any | null;
      designation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

type Compliance_ComplianceContractor_Fragment = {
  __typename: 'ComplianceContractor';
  type?: LegalBindingType | null;
  contractAgreementType?: ContractAgreementType | null;
  orderForm?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
    extension?: string | null;
    contentType?: string | null;
    blob?: string | null;
    htmlPreview?: string | null;
  } | null;
  contractAgreement?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
  } | null;
  complianceParams?: Array<{
    __typename?: 'ComplianceParamPeriodLimit';
    description?: string | null;
    value?: number | null;
    unit?: ComplianceParamPeriodUnit | null;
    key?: string | null;
    label?: string | null;
  } | null> | null;
  timeOffEntitlement?: Array<{
    __typename?: 'ContractTimeOffEntitlement';
    value?: number | null;
    unit?: TimeOffUnit | null;
    timeOffType?: {
      __typename?: 'TimeOffTypeInfo';
      type?: string | null;
      typeId?: string | null;
    } | null;
    definition?: {
      __typename?: 'TimeOffTypeDefinition';
      type?: string | null;
      required?: boolean | null;
      label?: string | null;
      description?: string | null;
      assignedEntityNames?: Array<string> | null;
      validation?: Array<{
        __typename?: 'TimeOffFixedValidation';
        minimum?: number | null;
        maximum?: number | null;
        defaultValue?: number | null;
        unit?: TimeOffUnit | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

type Compliance_ComplianceFreelance_Fragment = {
  __typename: 'ComplianceFreelance';
  type?: LegalBindingType | null;
  contractAgreementType?: ContractAgreementType | null;
  contractAgreement?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
  } | null;
  complianceParams?: Array<{
    __typename?: 'ComplianceParamPeriodLimit';
    description?: string | null;
    value?: number | null;
    unit?: ComplianceParamPeriodUnit | null;
    key?: string | null;
    label?: string | null;
  } | null> | null;
  timeOffEntitlement?: Array<{
    __typename?: 'ContractTimeOffEntitlement';
    value?: number | null;
    unit?: TimeOffUnit | null;
    timeOffType?: {
      __typename?: 'TimeOffTypeInfo';
      type?: string | null;
      typeId?: string | null;
    } | null;
    definition?: {
      __typename?: 'TimeOffTypeDefinition';
      type?: string | null;
      required?: boolean | null;
      label?: string | null;
      description?: string | null;
      assignedEntityNames?: Array<string> | null;
      validation?: Array<{
        __typename?: 'TimeOffFixedValidation';
        minimum?: number | null;
        maximum?: number | null;
        defaultValue?: number | null;
        unit?: TimeOffUnit | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

type Compliance_ComplianceMultiplierEor_Fragment = {
  __typename: 'ComplianceMultiplierEOR';
  type?: LegalBindingType | null;
  contractAgreementType?: ContractAgreementType | null;
  offerLetter?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
  } | null;
  preferredContractAgreementTemplate?: {
    __typename?: 'PreferredContractAgreementTemplateResponse';
    documentId?: string | null;
    template?: {
      __typename?: 'Template';
      id: string;
      displayName?: string | null;
      externalId?: string | null;
    } | null;
  } | null;
  contractAgreement?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
  } | null;
  complianceParams?: Array<{
    __typename?: 'ComplianceParamPeriodLimit';
    description?: string | null;
    value?: number | null;
    unit?: ComplianceParamPeriodUnit | null;
    key?: string | null;
    label?: string | null;
  } | null> | null;
  timeOffEntitlement?: Array<{
    __typename?: 'ContractTimeOffEntitlement';
    value?: number | null;
    unit?: TimeOffUnit | null;
    timeOffType?: {
      __typename?: 'TimeOffTypeInfo';
      type?: string | null;
      typeId?: string | null;
    } | null;
    definition?: {
      __typename?: 'TimeOffTypeDefinition';
      type?: string | null;
      required?: boolean | null;
      label?: string | null;
      description?: string | null;
      assignedEntityNames?: Array<string> | null;
      validation?: Array<{
        __typename?: 'TimeOffFixedValidation';
        minimum?: number | null;
        maximum?: number | null;
        defaultValue?: number | null;
        unit?: TimeOffUnit | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

type Compliance_CompliancePeo_Fragment = {
  __typename: 'CompliancePEO';
  type?: LegalBindingType | null;
  contractAgreementType?: ContractAgreementType | null;
  contractAgreement?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
  } | null;
  complianceParams?: Array<{
    __typename?: 'ComplianceParamPeriodLimit';
    description?: string | null;
    value?: number | null;
    unit?: ComplianceParamPeriodUnit | null;
    key?: string | null;
    label?: string | null;
  } | null> | null;
  timeOffEntitlement?: Array<{
    __typename?: 'ContractTimeOffEntitlement';
    value?: number | null;
    unit?: TimeOffUnit | null;
    timeOffType?: {
      __typename?: 'TimeOffTypeInfo';
      type?: string | null;
      typeId?: string | null;
    } | null;
    definition?: {
      __typename?: 'TimeOffTypeDefinition';
      type?: string | null;
      required?: boolean | null;
      label?: string | null;
      description?: string | null;
      assignedEntityNames?: Array<string> | null;
      validation?: Array<{
        __typename?: 'TimeOffFixedValidation';
        minimum?: number | null;
        maximum?: number | null;
        defaultValue?: number | null;
        unit?: TimeOffUnit | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

type Compliance_CompliancePartnerEor_Fragment = {
  __typename: 'CompliancePartnerEOR';
  type?: LegalBindingType | null;
  contractAgreementType?: ContractAgreementType | null;
  offerLetter?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
  } | null;
  contractAgreement?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
  } | null;
  complianceParams?: Array<{
    __typename?: 'ComplianceParamPeriodLimit';
    description?: string | null;
    value?: number | null;
    unit?: ComplianceParamPeriodUnit | null;
    key?: string | null;
    label?: string | null;
  } | null> | null;
  timeOffEntitlement?: Array<{
    __typename?: 'ContractTimeOffEntitlement';
    value?: number | null;
    unit?: TimeOffUnit | null;
    timeOffType?: {
      __typename?: 'TimeOffTypeInfo';
      type?: string | null;
      typeId?: string | null;
    } | null;
    definition?: {
      __typename?: 'TimeOffTypeDefinition';
      type?: string | null;
      required?: boolean | null;
      label?: string | null;
      description?: string | null;
      assignedEntityNames?: Array<string> | null;
      validation?: Array<{
        __typename?: 'TimeOffFixedValidation';
        minimum?: number | null;
        maximum?: number | null;
        defaultValue?: number | null;
        unit?: TimeOffUnit | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type ComplianceFragment =
  | Compliance_ComplianceContractor_Fragment
  | Compliance_ComplianceFreelance_Fragment
  | Compliance_ComplianceMultiplierEor_Fragment
  | Compliance_CompliancePeo_Fragment
  | Compliance_CompliancePartnerEor_Fragment;

type CompensationPayComponent_FixedPayComponent_Fragment = {
  __typename?: 'FixedPayComponent';
  rateType?: RateType | null;
  name?: string | null;
  label?: string | null;
  amount?: number | null;
  amountType?: PayAmountType | null;
  currency?: CurrencyCode | null;
  frequency?: RateFrequency | null;
  isDeletable?: boolean | null;
  condition?: string | null;
  instalments?: Array<{
    __typename?: 'Instalment';
    amount?: number | null;
    currency?: CurrencyCode | null;
    payOn?: {
      __typename?: 'MonthYear';
      year?: number | null;
      month?: number | null;
    } | null;
  }> | null;
  payOn?: {
    __typename?: 'MonthYear';
    year?: number | null;
    month?: number | null;
  } | null;
  paySchedule?: {
    __typename?: 'ScheduleTime';
    value: number;
    unit: ScheduleTimeUnit;
  } | null;
};

type CompensationPayComponent_PercentPayComponent_Fragment = {
  __typename?: 'PercentPayComponent';
  name?: string | null;
  label?: string | null;
  amount?: number | null;
  amountType?: PayAmountType | null;
  currency?: CurrencyCode | null;
  frequency?: RateFrequency | null;
  isDeletable?: boolean | null;
  condition?: string | null;
  payOn?: {
    __typename?: 'MonthYear';
    year?: number | null;
    month?: number | null;
  } | null;
  paySchedule?: {
    __typename?: 'ScheduleTime';
    value: number;
    unit: ScheduleTimeUnit;
  } | null;
};

export type CompensationPayComponentFragment =
  | CompensationPayComponent_FixedPayComponent_Fragment
  | CompensationPayComponent_PercentPayComponent_Fragment;

export type FixedPayComponentFragment = {
  __typename?: 'FixedPayComponent';
  name?: string | null;
  label?: string | null;
  amount?: number | null;
  currency?: CurrencyCode | null;
  frequency?: RateFrequency | null;
  rateType?: RateType | null;
  paymentFrequency?: PayFrequency | null;
  validFromInclusive?: any | null;
  validToExclusive?: any | null;
  paymentFrequencyDate?: Array<{
    __typename?: 'PayFrequencyDate';
    identifier?: PayFrequencyDateIdentifier | null;
    dateOfMonth?: number | null;
    dayOfWeek?: DayOfWeek | null;
  } | null> | null;
};

export type GrantComponentFragment = {
  __typename?: 'ESOPGrant';
  id: string;
  condition?: string | null;
  grantDate: any;
  values?: Array<{
    __typename?: 'GrantValue';
    value: number;
    type: GrantValueType;
    currency?: CurrencyCode | null;
  }> | null;
  schedule: {
    __typename?: 'GrantSchedule';
    cliffPeriod?: {
      __typename?: 'ScheduleTime';
      value: number;
      unit: ScheduleTimeUnit;
    } | null;
    vestingPeriod?: {
      __typename?: 'ScheduleTime';
      value: number;
      unit: ScheduleTimeUnit;
    } | null;
    vestingSchedule?: Array<{
      __typename?: 'VestingSchedule';
      frequency?: VestingFrequency | null;
      time?: {
        __typename?: 'ScheduleTime';
        value: number;
        unit: ScheduleTimeUnit;
      } | null;
      vestingValue?: {
        __typename?: 'VestingValue';
        value: number;
        type: VestingValueType;
      } | null;
    } | null> | null;
  };
};

export type LegalDocumentFragment = {
  __typename?: 'LegalDocument';
  id: string;
  key?: string | null;
  label?: string | null;
  financialYear?: number | null;
  status?: LegalDocumentStatus | null;
  comment?: string | null;
  category?: LegalDocumentCategory | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  files?: Array<{
    __typename?: 'Document';
    id?: string | null;
    name?: string | null;
    extension?: string | null;
    contentType?: string | null;
    downloadUrl?: string | null;
    size?: number | null;
  }> | null;
};

export type CompanyBenefitFragment = {
  __typename?: 'Benefit';
  id: string;
  type?: BenefitType | null;
  packageName?: string | null;
  description?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  currency?: CurrencyCode | null;
  cost?: number | null;
  costingType?: BenefitCostingType | null;
  frequency?: RateFrequency | null;
  packageTier?: {
    __typename?: 'BenefitPackageTier';
    tier?: BenefitTier | null;
  } | null;
  memberOnboardingKit?: {
    __typename?: 'BenefitMemberOnboardingKit';
    memberOnboardingKitFile?: {
      __typename?: 'BenefitDocument';
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        downloadUrl?: string | null;
      }> | null;
    } | null;
  } | null;
  benefitDocuments?: Array<{
    __typename?: 'BenefitDocument';
    benefitDocumentType?: BenefitDocumentType | null;
    files?: Array<{
      __typename?: 'Document';
      id?: string | null;
      name?: string | null;
      downloadUrl?: string | null;
    }> | null;
  } | null> | null;
  provider?: {
    __typename?: 'BenefitPartnerCountry';
    id?: string | null;
    partnerName?: string | null;
    brokerData?: {
      __typename?: 'BenefitBrokerData';
      name?: string | null;
    } | null;
    coverageGroup?: {
      __typename?: 'InsuranceCoverageGroup';
      id?: string | null;
      name?: string | null;
      group?: Array<{
        __typename?: 'InsuranceCoverage';
        maxCount: number;
        relation?: {
          __typename?: 'InsuranceRelation';
          familyMember?: string | null;
          id?: string | null;
        } | null;
      } | null> | null;
    } | null;
    emergencyPointOfContact?:
      | {
          __typename?: 'CompanyUser';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            type?: string | null;
            phoneNo?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'EmergencyPointOfContact';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            type?: string | null;
            phoneNo?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            type?: string | null;
            phoneNo?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'OperationsUser';
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            type?: string | null;
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            type?: string | null;
            phoneNo?: string | null;
          } | null> | null;
        }
      | null;
    metaData?: {
      __typename?: 'BenefitMetaData';
      pocName?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        type?: string | null;
        email?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
        type?: string | null;
      } | null> | null;
    } | null;
    partner?: {
      __typename?: 'Partner';
      id?: string | null;
      name?: string | null;
    } | null;
  } | null;
  features?: Array<{
    __typename?: 'BenefitFeatures';
    key?: string | null;
    label?: string | null;
    value?: string | null;
  } | null> | null;
};

export type CompanyUserFragment = {
  __typename?: 'CompanyUser';
  id?: string | null;
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  role?: CompanyUserRole | null;
  roles?: Array<CompanyUserRole | null> | null;
  isSignatory?: boolean | null;
  isBillingContact?: boolean | null;
  status?: CompanyUserStatus | null;
  emails?: Array<{
    __typename?: 'EmailAddress';
    email?: string | null;
  } | null> | null;
};

export type ChangeRequestBlockDataFragment = {
  __typename: 'ChangeRequestBlockData';
  changeRequestId: string;
};

export type GetContractQueryVariables = Exact<{
  id: Scalars['ID'];
  skipDepositPayable?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetContractQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    term?: ContractTerm | null;
    type?: ContractType | null;
    currency?: CurrencyCode | null;
    country?: CountryCode | null;
    countryStateCode?: string | null;
    legalEntityId?: string | null;
    employeeId?: string | null;
    workStatus?: CountryWorkStatus | null;
    status?: ContractStatus | null;
    workEmail?: string | null;
    position?: string | null;
    alreadyHired?: boolean | null;
    startOn?: any | null;
    createdOn?: any | null;
    endOn?: any | null;
    endedOn?: any | null;
    offboardingNote?: string | null;
    scope?: string | null;
    contractStarted?: boolean | null;
    contractBlocked?: boolean | null;
    company?: {
      __typename?: 'Company';
      id?: string | null;
      displayName?: string | null;
    } | null;
    workShift?: {
      __typename?: 'WorkShift';
      startDay?: DayOfWeek | null;
      endDay?: DayOfWeek | null;
      workingHours?: {
        __typename?: 'WorkingHours';
        startTime?: any | null;
        endTime?: any | null;
      } | null;
    } | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullLegalName?: string | null;
      status?: MemberStatus | null;
      gender?: Gender | null;
      maritalStatus?: MaritalStatus | null;
      dateOfBirth?: any | null;
      age?: number | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        type?: string | null;
        email?: string | null;
      } | null> | null;
      addresses?: Array<{
        __typename?: 'Address';
        key?: string | null;
        street?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        province?: string | null;
        country?: CountryCode | null;
        zipcode?: string | null;
        postalCode?: string | null;
      } | null> | null;
      phoneNos?: Array<{
        __typename?: 'PhoneNumber';
        type?: string | null;
        phoneNo?: string | null;
      } | null> | null;
      nationalities?: Array<{
        __typename?: 'MemberNationalogy';
        type?: Nationalogy | null;
        country?: CountryCode | null;
      } | null> | null;
      basicInfo?: {
        __typename?: 'MemberBasicInfo';
        firstName?: string | null;
        fullLegalName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      } | null;
      legalData?: Array<{
        __typename?: 'LegalData';
        key?: string | null;
        value?: string | null;
        label?: string | null;
        country?: CountryCode | null;
      } | null> | null;
      bankAccounts?: Array<{
        __typename?: 'BankAccount';
        id?: string | null;
        version?: number | null;
        accountName?: string | null;
        accountNumber?: string | null;
        bankName?: string | null;
        branchName?: string | null;
        currency?: CurrencyCode | null;
        country?: CountryCode | null;
        swiftCode?: string | null;
        localBankCode?: string | null;
        paymentAccountRequirementType?: string | null;
        accountDetails?: Array<{
          __typename?: 'BankAccountDetail';
          key?: string | null;
          value?: string | null;
        } | null> | null;
        bankStatements?: Array<{
          __typename?: 'FileLink';
          id?: string | null;
          name?: string | null;
          extension?: string | null;
          link?: string | null;
          contentType?: string | null;
          size?: number | null;
        } | null> | null;
      } | null> | null;
      legalDocuments?: Array<{
        __typename?: 'LegalDocument';
        id: string;
        key?: string | null;
        label?: string | null;
        financialYear?: number | null;
        status?: LegalDocumentStatus | null;
        comment?: string | null;
        category?: LegalDocumentCategory | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        files?: Array<{
          __typename?: 'Document';
          id?: string | null;
          name?: string | null;
          extension?: string | null;
          contentType?: string | null;
          downloadUrl?: string | null;
          size?: number | null;
        }> | null;
      } | null> | null;
    } | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      status?: ContractOnboardingStatus | null;
      statuses?: Array<ContractOnboardingStatus | null> | null;
      steps?: Array<ContractOnboardingStep | null> | null;
      current?: ContractOnboardingStep | null;
      activationCutoff?: any | null;
      contractDocumentWorkflow?: {
        __typename?: 'ContractDocumentWorkflow';
        currentStatus?: ContractDocumentStatus | null;
      } | null;
    } | null;
    compensation?: {
      __typename?: 'Compensation';
      additional?: string | null;
      payrollStart?: any | null;
      payType?: PayType | null;
      basePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      postProbationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      probationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      additionalPays?: Array<
        | {
            __typename?: 'FixedPayComponent';
            rateType?: RateType | null;
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            instalments?: Array<{
              __typename?: 'Instalment';
              amount?: number | null;
              currency?: CurrencyCode | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }> | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | {
            __typename?: 'PercentPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | null
      > | null;
      grant?: Array<{
        __typename?: 'ESOPGrant';
        id: string;
        condition?: string | null;
        grantDate: any;
        values?: Array<{
          __typename?: 'GrantValue';
          value: number;
          type: GrantValueType;
          currency?: CurrencyCode | null;
        }> | null;
        schedule: {
          __typename?: 'GrantSchedule';
          cliffPeriod?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
          vestingPeriod?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
          vestingSchedule?: Array<{
            __typename?: 'VestingSchedule';
            frequency?: VestingFrequency | null;
            time?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
            vestingValue?: {
              __typename?: 'VestingValue';
              value: number;
              type: VestingValueType;
            } | null;
          } | null> | null;
        };
      } | null> | null;
      deductions?: Array<{
        __typename?: 'Deduction';
        deductionDefinitionId: string;
        value: number;
        unit?: string | null;
      } | null> | null;
    } | null;
    benefits?: Array<{
      __typename?: 'ContractBenefit';
      id?: string | null;
      status?: ContractBenefitStatus | null;
      startOn?: any | null;
      endOn?: any | null;
      dependentCount?: number | null;
      employeePayPercentage?: number | null;
      employerPayPercentage?: number | null;
      externalPolicyId?: string | null;
      billAmount?: number | null;
      benefitPackageCost?: {
        __typename?: 'BenefitPackageCost';
        id: string;
        premium?: number | null;
        premiumCurrency?: CurrencyCode | null;
        premiumFrequency?: BenefitPremiumFrequency | null;
      } | null;
      dependents?: Array<{
        __typename?: 'ContractBenefitDependent';
        firstName?: string | null;
        lastName?: string | null;
        gender?: Gender | null;
        dateOfBirth?: any | null;
        relationshipToMember?: Relationship | null;
        externalPolicyId?: string | null;
        startOn?: any | null;
        endOn?: any | null;
        benefitPackageCost?: {
          __typename?: 'BenefitPackageCost';
          id: string;
          premium?: number | null;
          premiumCurrency?: CurrencyCode | null;
          premiumFrequency?: BenefitPremiumFrequency | null;
        } | null;
      } | null> | null;
      benefit?: {
        __typename?: 'Benefit';
        id: string;
        type?: BenefitType | null;
        packageName?: string | null;
        description?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        currency?: CurrencyCode | null;
        cost?: number | null;
        costingType?: BenefitCostingType | null;
        frequency?: RateFrequency | null;
        packageTier?: {
          __typename?: 'BenefitPackageTier';
          tier?: BenefitTier | null;
        } | null;
        memberOnboardingKit?: {
          __typename?: 'BenefitMemberOnboardingKit';
          memberOnboardingKitFile?: {
            __typename?: 'BenefitDocument';
            files?: Array<{
              __typename?: 'Document';
              id?: string | null;
              downloadUrl?: string | null;
            }> | null;
          } | null;
        } | null;
        benefitDocuments?: Array<{
          __typename?: 'BenefitDocument';
          benefitDocumentType?: BenefitDocumentType | null;
          files?: Array<{
            __typename?: 'Document';
            id?: string | null;
            name?: string | null;
            downloadUrl?: string | null;
          }> | null;
        } | null> | null;
        provider?: {
          __typename?: 'BenefitPartnerCountry';
          id?: string | null;
          partnerName?: string | null;
          brokerData?: {
            __typename?: 'BenefitBrokerData';
            name?: string | null;
          } | null;
          coverageGroup?: {
            __typename?: 'InsuranceCoverageGroup';
            id?: string | null;
            name?: string | null;
            group?: Array<{
              __typename?: 'InsuranceCoverage';
              maxCount: number;
              relation?: {
                __typename?: 'InsuranceRelation';
                familyMember?: string | null;
                id?: string | null;
              } | null;
            } | null> | null;
          } | null;
          emergencyPointOfContact?:
            | {
                __typename?: 'CompanyUser';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | {
                __typename?: 'EmergencyPointOfContact';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | {
                __typename?: 'Member';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | {
                __typename?: 'OperationsUser';
                firstName?: string | null;
                lastName?: string | null;
                emails?: Array<{
                  __typename?: 'EmailAddress';
                  type?: string | null;
                  email?: string | null;
                } | null> | null;
                phoneNos?: Array<{
                  __typename?: 'PhoneNumber';
                  type?: string | null;
                  phoneNo?: string | null;
                } | null> | null;
              }
            | null;
          metaData?: {
            __typename?: 'BenefitMetaData';
            pocName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              type?: string | null;
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
          partner?: {
            __typename?: 'Partner';
            id?: string | null;
            name?: string | null;
          } | null;
        } | null;
        features?: Array<{
          __typename?: 'BenefitFeatures';
          key?: string | null;
          label?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
      params?: Array<{
        __typename?: 'ContractBenefitParam';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    compliance?:
      | {
          __typename: 'ComplianceContractor';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          orderForm?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            blob?: string | null;
            htmlPreview?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceFreelance';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          preferredContractAgreementTemplate?: {
            __typename?: 'PreferredContractAgreementTemplateResponse';
            documentId?: string | null;
            template?: {
              __typename?: 'Template';
              id: string;
              displayName?: string | null;
              externalId?: string | null;
            } | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePEO';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: 'CompliancePartnerEOR';
          type?: LegalBindingType | null;
          contractAgreementType?: ContractAgreementType | null;
          offerLetter?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              required?: boolean | null;
              label?: string | null;
              description?: string | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | null;
    payslips?: Array<{
      __typename?: 'Payslip';
      id?: string | null;
      createdOn?: any | null;
      month?: {
        __typename?: 'PayrollMonth';
        month?: number | null;
        year?: number | null;
      } | null;
      payslipDocument?: {
        __typename?: 'PayslipDocument';
        downloadUrl?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    categoryApproverInfos?: Array<{
      __typename?: 'CategoryApproverInfo';
      category?: ApprovalCategory | null;
      approverUsers?: Array<
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            title?: string | null;
            role?: CompanyUserRole | null;
            roles?: Array<CompanyUserRole | null> | null;
            isSignatory?: boolean | null;
            isBillingContact?: boolean | null;
            status?: CompanyUserStatus | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | { __typename?: 'EmergencyPointOfContact' }
        | { __typename?: 'Member' }
        | { __typename?: 'OperationsUser' }
        | null
      > | null;
    } | null> | null;
    timeOff?: {
      __typename?: 'ContractTimeOff';
      summary?: Array<{
        __typename?: 'TimeOffType';
        key?: string | null;
        entitled?: number | null;
        taken?: number | null;
        pending?: number | null;
        remaining?: number | null;
        periodEnd?: any | null;
        definition?: {
          __typename?: 'TimeOffTypeDefinition';
          type?: string | null;
          label?: string | null;
        } | null;
      } | null> | null;
    } | null;
    depositPayable?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        status?: InvoiceStatus | null;
        dueDate?: any | null;
        externalSystem?: ExternalSystemType | null;
      } | null;
    } | null> | null;
    unresolvedBlockEvents?: Array<{
      __typename?: 'BlockEvent';
      id: string;
      source: BlockSource;
      status: BlockStatus;
      blockReason: string;
      blockData?: {
        __typename: 'ChangeRequestBlockData';
        changeRequestId: string;
      } | null;
    }> | null;
    offboarding?: {
      __typename?: 'ContractOffboarding';
      id?: string | null;
      contractId?: string | null;
      status?: ContractOffboardingStatus | null;
      type?: ContractOffboardingType | null;
      terminationReason?: string | null;
      lastWorkingDay?: any | null;
    } | null;
    paymentAccountRequirements?: Array<{
      __typename?: 'PaymentAccountRequirement';
      accountType?: PaymentAccountType | null;
      transferType?: TransferType | null;
      sourceCurrency?: CurrencyCode | null;
      targetCurrency?: CurrencyCode | null;
      paymentPartner?: PaymentPartnerCode | null;
      paymentAccountRequirementType?: string | null;
      requirementFields?: Array<{
        __typename?: 'RequirementField';
        key?: string | null;
        label?: string | null;
        isMandatory?: boolean | null;
        type?: string | null;
        regex?: string | null;
        errorMessage?: string | null;
        hasSubFields?: boolean | null;
        allowedValues?: Array<{
          __typename?: 'AllowedValue';
          key?: string | null;
          value?: string | null;
        } | null> | null;
        subFields?: Array<{
          __typename?: 'RequirementSubField';
          key?: string | null;
          value?: Array<{
            __typename?: 'RequirementField';
            key?: string | null;
            label?: string | null;
            isMandatory?: boolean | null;
            type?: string | null;
            regex?: string | null;
            errorMessage?: string | null;
            hasSubFields?: boolean | null;
            allowedValues?: Array<{
              __typename?: 'AllowedValue';
              key?: string | null;
              value?: string | null;
            } | null> | null;
            subFields?: Array<{
              __typename?: 'RequirementSubField';
              key?: string | null;
              value?: Array<{
                __typename?: 'RequirementField';
                key?: string | null;
                label?: string | null;
                isMandatory?: boolean | null;
                type?: string | null;
                regex?: string | null;
                errorMessage?: string | null;
                allowedValues?: Array<{
                  __typename?: 'AllowedValue';
                  key?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    reportsToManager?: {
      __typename?: 'Manager';
      id: string;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        position?: string | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null;
    orgAttributes?: {
      __typename?: 'OrgAttributes';
      costCenter?: {
        __typename?: 'CostCenter';
        id?: string | null;
        description?: string | null;
        name?: string | null;
      } | null;
      department?: {
        __typename?: 'Department';
        id: string;
        description?: string | null;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type GetDeductionDefinitionQueryVariables = Exact<{
  country: CountryCode;
  state?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
  contractTerm?: InputMaybe<ContractTerm>;
}>;

export type GetDeductionDefinitionQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      compensationStructureDefinition?: {
        __typename?: 'CompensationStructureDefinition';
        deductions?: Array<{
          __typename?: 'DeductionDefinition';
          id?: string | null;
          groupKey?: string | null;
          description?: string | null;
          contributionBy?: ContributionBy | null;
          isMandatory?: boolean | null;
          deductionRequirement?:
            | {
                __typename: 'FixedDeductionRequirement';
                type?: DeductionRequirementType | null;
              }
            | {
                __typename: 'FixedPercentageDeductionRequirement';
                values?: Array<number | null> | null;
                type?: DeductionRequirementType | null;
              }
            | null;
          deductionConstraint?:
            | {
                __typename?: 'FixedDeductionRequirement';
                name?: string | null;
                options?: Array<{
                  __typename?: 'DeductionValue';
                  value: number;
                  unit: DeductionUnit;
                } | null> | null;
              }
            | {
                __typename?: 'FixedPercentageDeductionRequirement';
                name?: string | null;
              }
            | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

type DeductionRequirement_FixedDeductionRequirement_Fragment = {
  __typename?: 'FixedDeductionRequirement';
  type?: DeductionRequirementType | null;
};

type DeductionRequirement_FixedPercentageDeductionRequirement_Fragment = {
  __typename?: 'FixedPercentageDeductionRequirement';
  values?: Array<number | null> | null;
  type?: DeductionRequirementType | null;
};

export type DeductionRequirementFragment =
  | DeductionRequirement_FixedDeductionRequirement_Fragment
  | DeductionRequirement_FixedPercentageDeductionRequirement_Fragment;

type DeductionConstraint_FixedDeductionRequirement_Fragment = {
  __typename?: 'FixedDeductionRequirement';
  name?: string | null;
  options?: Array<{
    __typename?: 'DeductionValue';
    value: number;
    unit: DeductionUnit;
  } | null> | null;
};

type DeductionConstraint_FixedPercentageDeductionRequirement_Fragment = {
  __typename?: 'FixedPercentageDeductionRequirement';
  name?: string | null;
};

export type DeductionConstraintFragment =
  | DeductionConstraint_FixedDeductionRequirement_Fragment
  | DeductionConstraint_FixedPercentageDeductionRequirement_Fragment;

export type GetOnboardingHandlerQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetOnboardingHandlerQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    onboarding?: {
      __typename?: 'ContractOnboarding';
      handler?: {
        __typename?: 'OperationsUser';
        firstName?: string | null;
        lastName?: string | null;
        displayPicture?: {
          __typename?: 'DocumentReadable';
          link?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SendEmailToOnboardingSpecialistMutationVariables = Exact<{
  contractId: Scalars['ID'];
  message: Scalars['String'];
}>;

export type SendEmailToOnboardingSpecialistMutation = {
  __typename?: 'Mutation';
  sendEmailToOnboardingSpecialist?: {
    __typename?: 'SendEmailToOnboardingSpecialistResponse';
    success: boolean;
    message?: string | null;
    requesterEmail?: string | null;
  } | null;
};

export type GetCountryDocumentsQueryVariables = Exact<{
  country: CountryCode;
  filter?: InputMaybe<CountryDocumentFilter>;
}>;

export type GetCountryDocumentsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    documents?: Array<{
      __typename?: 'CountryDocument';
      id?: string | null;
      type?: CountryDocumentType | null;
      file?: {
        __typename?: 'FileLink';
        id?: string | null;
        link?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        versionId?: string | null;
        versionedOn?: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyPayInSettingDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyPayInSettingDetailsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payInSettings?: {
      __typename?: 'CompanyPayInSettings';
      payInMethodRequirements?: Array<{
        __typename?: 'PayInMethodRequirement';
        id: string;
        payInMethodType?: PayInMethodType | null;
      } | null> | null;
      payInMethods: Array<
        | {
            __typename?: 'ACHCompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
          }
        | {
            __typename?: 'SEPACompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
          }
        | null
      >;
    } | null;
  } | null;
};

export type GetMsaStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetMsaStatusQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    status?: CompanyStatus | null;
    channel?: CompanyChannel | null;
    onboarding?: {
      __typename?: 'CompanyOnboarding';
      msaWorkflow?: {
        __typename?: 'MSAWorkflow';
        currentStatus?: MsaWorkflowStatus | null;
      } | null;
    } | null;
  } | null;
};

export type GetActivationCutoffDatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActivationCutoffDatesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      status?: ContractStatus | null;
      contractStarted?: boolean | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        activationCutoff?: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetOnboardingFormRequirementsQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  workStatus?: InputMaybe<CountryWorkStatus>;
  contractType?: InputMaybe<ContractType>;
  contractTerm?: InputMaybe<ContractTerm>;
  contractStatus?: InputMaybe<ContractStatus>;
  joinDate?: InputMaybe<Scalars['Date']>;
  financialYear?: InputMaybe<Scalars['Int']>;
}>;

export type GetOnboardingFormRequirementsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    compliance?: {
      __typename?: 'CountryCompliance';
      countryCode?: CountryCode | null;
      countryState?: {
        __typename?: 'State';
        code?: string | null;
        name?: string | null;
      } | null;
      memberDocumentRequirements?: Array<{
        __typename?: 'LegalDocumentRequirement';
        key?: string | null;
        label?: string | null;
        description?: string | null;
        acceptMultiple?: boolean | null;
        deadline?: any | null;
        required?: boolean | null;
        category?: LegalDocumentCategory | null;
        startDate?: any | null;
        endDate?: any | null;
        template?: Array<{
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          contentType?: string | null;
          link?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateEquipmentProcurementMutationVariables = Exact<{
  input: EquipmentProcurementInput;
}>;

export type CreateEquipmentProcurementMutation = {
  __typename?: 'Mutation';
  equipmentProcurementCreate?: {
    __typename?: 'EquipmentProcurement';
    id: string;
    contractId?: string | null;
    companyId?: string | null;
    requestedOn: any;
    detail?: string | null;
    status?: EquipmentProcurementStatus | null;
    totalAmount?: number | null;
    currencyCode?: CurrencyCode | null;
    items?: Array<{
      __typename?: 'EquipmentProcurementItem';
      id: string;
      productId: string;
      name?: string | null;
      type?: string | null;
      description?: string | null;
      amount?: number | null;
      currencyCode?: CurrencyCode | null;
    }> | null;
  } | null;
};

export type CreateQuotationMutationVariables = Exact<{
  input: EquipmentQuotationInput;
}>;

export type CreateQuotationMutation = {
  __typename?: 'Mutation';
  equipmentQuotationCreate?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type GetEquipmentsForCompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetEquipmentsForCompanyQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    equipments?: Array<{
      __typename?: 'Equipment';
      id: string;
      productId: string;
      serialNumber?: string | null;
      purchasedOn?: any | null;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      status?: EquipmentStatus | null;
      documents?: Array<{
        __typename?: 'EquipmentProcurementDocument';
        id: string;
        type: string;
        document: {
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          extension?: string | null;
          contentType?: string | null;
          link?: string | null;
        };
      }> | null;
      dynamicDetails?: Array<{
        __typename?: 'EquipmentDetail';
        key: string;
        value: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type GetEquipmentsForContractQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetEquipmentsForContractQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    equipments?: Array<{
      __typename?: 'Equipment';
      id: string;
      productId: string;
      serialNumber?: string | null;
      purchasedOn?: any | null;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      status?: EquipmentStatus | null;
      documents?: Array<{
        __typename?: 'EquipmentProcurementDocument';
        id: string;
        type: string;
        document: {
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          extension?: string | null;
          contentType?: string | null;
          link?: string | null;
        };
      }> | null;
      dynamicDetails?: Array<{
        __typename?: 'EquipmentDetail';
        key: string;
        value: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type GetProcurementsForContractQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetProcurementsForContractQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    equipmentProcurements?: Array<{
      __typename?: 'EquipmentProcurement';
      id: string;
      companyId?: string | null;
      contractId?: string | null;
      totalAmount?: number | null;
      currencyCode?: CurrencyCode | null;
      requestedOn: any;
      completedOn?: any | null;
      status?: EquipmentProcurementStatus | null;
      detail?: string | null;
      items?: Array<{
        __typename?: 'EquipmentProcurementItem';
        id: string;
        productId: string;
        name?: string | null;
        type?: string | null;
        description?: string | null;
        amount?: number | null;
        currencyCode?: CurrencyCode | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetProductFiltersQueryVariables = Exact<{
  code: CountryCode;
  status: ProductStatus;
}>;

export type GetProductFiltersQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    name?: string | null;
    productFilterSpec?: {
      __typename?: 'ProductFilterSpec';
      types?: Array<string> | null;
      brands?: Array<string> | null;
    } | null;
  } | null;
};

export type GetProductsQueryVariables = Exact<{
  code: CountryCode;
  filter: ProductFilter;
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    name?: string | null;
    productList?: {
      __typename?: 'ProductList';
      products?: Array<{
        __typename?: 'Product';
        id: string;
        name: string;
        type: string;
        brand: string;
        price: number;
        currencyCode: CurrencyCode;
        description?: string | null;
        color?: {
          __typename?: 'ProductColor';
          code?: string | null;
          name: string;
        } | null;
        dynamicDetails?: Array<{
          __typename?: 'ProductDetailCategory';
          key: string;
          details?: Array<{
            __typename?: 'ProductDetail';
            key: string;
            value: string;
          }> | null;
        }> | null;
        images?: Array<{
          __typename?: 'ProductImage';
          url?: string | null;
        }> | null;
      }> | null;
      filterSpec?: {
        __typename?: 'ProductFilterSpec';
        types?: Array<string> | null;
        brands?: Array<string> | null;
      } | null;
    } | null;
  } | null;
};

export type ApproveExpenseMutationVariables = Exact<{
  itemId: Scalars['ID'];
}>;

export type ApproveExpenseMutation = {
  __typename?: 'Mutation';
  approveItem?:
    | {
        __typename?: 'ExpenseApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
        expenseId?: string | null;
        expenseItem?: {
          __typename?: 'Expense';
          id?: string | null;
          status?: ExpenseStatus | null;
          title?: string | null;
          currency?: CurrencyCode | null;
          updatedOn?: any | null;
          updatedBy?: string | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
            company?: { __typename?: 'Company'; id?: string | null } | null;
          } | null;
          items?: Array<{
            __typename?: 'ExpenseItem';
            id?: string | null;
            date?: any | null;
            category?: string | null;
            amount?: number | null;
            amountInFunctionalCurrency?: number | null;
          } | null> | null;
        } | null;
      }
    | { __typename?: 'LegalDocumentsApprovalItem' }
    | { __typename?: 'MemberDataApprovalItem' }
    | { __typename?: 'MemberPayableApprovalItem' }
    | { __typename?: 'TimeOffApprovalItem' }
    | null;
};

export type ForwardExpenseMutationVariables = Exact<{
  itemId: Scalars['ID'];
  forwardUserId: Scalars['ID'];
}>;

export type ForwardExpenseMutation = {
  __typename?: 'Mutation';
  forwardItem?:
    | {
        __typename?: 'ExpenseApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
        expenseId?: string | null;
        expenseItem?: {
          __typename?: 'Expense';
          id?: string | null;
          status?: ExpenseStatus | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          updatedBy?: string | null;
          title?: string | null;
          currency?: CurrencyCode | null;
          isApprovable?: boolean | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
            company?: { __typename?: 'Company'; id?: string | null } | null;
          } | null;
          approverInfos?: Array<{
            __typename?: 'ItemApproverInfo';
            status?: ApprovalItemStatus | null;
            updatedOn?: any | null;
            approverUser?:
              | {
                  __typename?: 'CompanyUser';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | {
                  __typename?: 'EmergencyPointOfContact';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | {
                  __typename?: 'Member';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | {
                  __typename?: 'OperationsUser';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | null;
          }> | null;
          items?: Array<{
            __typename?: 'ExpenseItem';
            id?: string | null;
            date?: any | null;
            category?: string | null;
            amount?: number | null;
            amountInFunctionalCurrency?: number | null;
          } | null> | null;
          changes?: Array<{
            __typename?: 'ExpenseChange';
            status?: ExpenseStatus | null;
            actionedBy?:
              | { __typename?: 'CompanyUser'; persona?: Persona | null }
              | {
                  __typename?: 'EmergencyPointOfContact';
                  persona?: Persona | null;
                }
              | { __typename?: 'Member'; persona?: Persona | null }
              | { __typename?: 'OperationsUser'; persona?: Persona | null }
              | null;
          } | null> | null;
        } | null;
      }
    | { __typename?: 'LegalDocumentsApprovalItem' }
    | { __typename?: 'MemberDataApprovalItem' }
    | { __typename?: 'MemberPayableApprovalItem' }
    | { __typename?: 'TimeOffApprovalItem' }
    | null;
};

export type GetCompanyExpenseDetailsQueryVariables = Exact<{
  expenseId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyExpenseDetailsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    expenses?: Array<{
      __typename?: 'Expense';
      id?: string | null;
      status?: ExpenseStatus | null;
      submittedAt?: any | null;
      createdOn?: any | null;
      createdBy?: string | null;
      updatedBy?: string | null;
      updatedOn?: any | null;
      title?: string | null;
      currency?: CurrencyCode | null;
      totalAmount?: number | null;
      totalInFunctionalCurrency?: number | null;
      isApprovable?: boolean | null;
      changeReason?: string | null;
      payrollCutOffDate?: any | null;
      items?: Array<{
        __typename?: 'ExpenseItem';
        id?: string | null;
        date?: any | null;
        title?: string | null;
        description?: string | null;
        category?: string | null;
        amount?: number | null;
        amountInFunctionalCurrency?: number | null;
        categoryV2?: {
          __typename?: 'ExpenseCategory';
          id?: string | null;
          name?: string | null;
          glCode?: {
            __typename?: 'CompanyGLCode';
            code?: string | null;
            id?: string | null;
            name?: string | null;
          } | null;
        } | null;
        amountInForeignCurrency?: {
          __typename?: 'ExpenseAmountInfo';
          amount?: number | null;
          currency?: CurrencyCode | null;
        } | null;
        receipts?: Array<{
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          contentType?: string | null;
          link?: string | null;
          size?: number | null;
        } | null> | null;
        receiptsInForeignCurrency?: Array<{
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          contentType?: string | null;
          link?: string | null;
          size?: number | null;
        }> | null;
      } | null> | null;
      changes?: Array<{
        __typename?: 'ExpenseChange';
        status?: ExpenseStatus | null;
        actionedBy?:
          | { __typename?: 'CompanyUser'; persona?: Persona | null }
          | { __typename?: 'EmergencyPointOfContact'; persona?: Persona | null }
          | { __typename?: 'Member'; persona?: Persona | null }
          | { __typename?: 'OperationsUser'; persona?: Persona | null }
          | null;
      } | null> | null;
      approverInfos?: Array<{
        __typename?: 'ItemApproverInfo';
        status?: ApprovalItemStatus | null;
        updatedOn?: any | null;
        approverUser?:
          | {
              __typename?: 'CompanyUser';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | {
              __typename?: 'EmergencyPointOfContact';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | {
              __typename?: 'Member';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | {
              __typename?: 'OperationsUser';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | null;
      }> | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        company?: { __typename?: 'Company'; id?: string | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetExpenseApproversQueryVariables = Exact<{
  itemId: Scalars['ID'];
}>;

export type GetExpenseApproversQuery = {
  __typename?: 'Query';
  getAllApprovers?: Array<{
    __typename?: 'Approver';
    id?: string | null;
    approverUser?:
      | {
          __typename?: 'CompanyUser';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          status?: CompanyUserStatus | null;
        }
      | {
          __typename?: 'EmergencyPointOfContact';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }
      | {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }
      | {
          __typename?: 'OperationsUser';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }
      | null;
  } | null> | null;
};

export type GetExpenseSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetExpenseSummaryQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    expenses?: Array<{
      __typename?: 'Expense';
      id?: string | null;
      status?: ExpenseStatus | null;
      isApprovable?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetExpensesQueryVariables = Exact<{ [key: string]: never }>;

export type GetExpensesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    expenses?: Array<{
      __typename?: 'Expense';
      id?: string | null;
      status?: ExpenseStatus | null;
      submittedAt?: any | null;
      createdOn?: any | null;
      createdBy?: string | null;
      updatedBy?: string | null;
      updatedOn?: any | null;
      title?: string | null;
      currency?: CurrencyCode | null;
      totalAmount?: number | null;
      totalInFunctionalCurrency?: number | null;
      isApprovable?: boolean | null;
      changeReason?: string | null;
      payrollCutOffDate?: any | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        company?: { __typename?: 'Company'; id?: string | null } | null;
      } | null;
      items?: Array<{
        __typename?: 'ExpenseItem';
        id?: string | null;
        title?: string | null;
        date?: any | null;
        category?: string | null;
        amount?: number | null;
        amountInFunctionalCurrency?: number | null;
        categoryV2?: {
          __typename?: 'ExpenseCategory';
          id?: string | null;
          name?: string | null;
          glCode?: {
            __typename?: 'CompanyGLCode';
            code?: string | null;
            id?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null> | null;
      approverInfos?: Array<{
        __typename?: 'ItemApproverInfo';
        status?: ApprovalItemStatus | null;
        updatedOn?: any | null;
        approverUser?:
          | {
              __typename?: 'CompanyUser';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | {
              __typename?: 'EmergencyPointOfContact';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | {
              __typename?: 'Member';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | {
              __typename?: 'OperationsUser';
              id?: string | null;
              userId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              persona?: Persona | null;
            }
          | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type ExpenseFragment = {
  __typename?: 'Expense';
  id?: string | null;
  status?: ExpenseStatus | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  updatedOn?: any | null;
  title?: string | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  isApprovable?: boolean | null;
  changeReason?: string | null;
  payrollCutOffDate?: any | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    company?: { __typename?: 'Company'; id?: string | null } | null;
  } | null;
  items?: Array<{
    __typename?: 'ExpenseItem';
    id?: string | null;
    title?: string | null;
    date?: any | null;
    category?: string | null;
    amount?: number | null;
    amountInFunctionalCurrency?: number | null;
    categoryV2?: {
      __typename?: 'ExpenseCategory';
      id?: string | null;
      name?: string | null;
      glCode?: {
        __typename?: 'CompanyGLCode';
        code?: string | null;
        id?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null> | null;
  approverInfos?: Array<{
    __typename?: 'ItemApproverInfo';
    status?: ApprovalItemStatus | null;
    updatedOn?: any | null;
    approverUser?:
      | {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | {
          __typename?: 'EmergencyPointOfContact';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | {
          __typename?: 'OperationsUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | null;
  }> | null;
};

export type RejectExpenseMutationVariables = Exact<{
  itemId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}>;

export type RejectExpenseMutation = {
  __typename?: 'Mutation';
  rejectItem?:
    | {
        __typename?: 'ExpenseApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
        expenseId?: string | null;
        expenseItem?: {
          __typename?: 'Expense';
          id?: string | null;
          status?: ExpenseStatus | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          createdBy?: string | null;
          updatedBy?: string | null;
          updatedOn?: any | null;
          title?: string | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          isApprovable?: boolean | null;
          changeReason?: string | null;
          payrollCutOffDate?: any | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
            company?: { __typename?: 'Company'; id?: string | null } | null;
          } | null;
          items?: Array<{
            __typename?: 'ExpenseItem';
            id?: string | null;
            title?: string | null;
            date?: any | null;
            category?: string | null;
            amount?: number | null;
            amountInFunctionalCurrency?: number | null;
            categoryV2?: {
              __typename?: 'ExpenseCategory';
              id?: string | null;
              name?: string | null;
              glCode?: {
                __typename?: 'CompanyGLCode';
                code?: string | null;
                id?: string | null;
                name?: string | null;
              } | null;
            } | null;
          } | null> | null;
          approverInfos?: Array<{
            __typename?: 'ItemApproverInfo';
            status?: ApprovalItemStatus | null;
            updatedOn?: any | null;
            approverUser?:
              | {
                  __typename?: 'CompanyUser';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | {
                  __typename?: 'EmergencyPointOfContact';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | {
                  __typename?: 'Member';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | {
                  __typename?: 'OperationsUser';
                  id?: string | null;
                  userId?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  persona?: Persona | null;
                }
              | null;
          }> | null;
        } | null;
      }
    | { __typename?: 'LegalDocumentsApprovalItem' }
    | { __typename?: 'MemberDataApprovalItem' }
    | { __typename?: 'MemberPayableApprovalItem' }
    | { __typename?: 'TimeOffApprovalItem' }
    | null;
};

export type MemberExpenseFragment = {
  __typename?: 'Expense';
  id?: string | null;
  status?: ExpenseStatus | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  updatedOn?: any | null;
  title?: string | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  isApprovable?: boolean | null;
  changeReason?: string | null;
  payrollCutOffDate?: any | null;
  items?: Array<{
    __typename?: 'ExpenseItem';
    id?: string | null;
    title?: string | null;
    date?: any | null;
    category?: string | null;
    amount?: number | null;
    amountInFunctionalCurrency?: number | null;
    categoryV2?: {
      __typename?: 'ExpenseCategory';
      id?: string | null;
      name?: string | null;
      glCode?: {
        __typename?: 'CompanyGLCode';
        code?: string | null;
        id?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null> | null;
  approverInfos?: Array<{
    __typename?: 'ItemApproverInfo';
    status?: ApprovalItemStatus | null;
    updatedOn?: any | null;
    approverUser?:
      | {
          __typename?: 'CompanyUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | {
          __typename?: 'EmergencyPointOfContact';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | {
          __typename?: 'OperationsUser';
          id?: string | null;
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          persona?: Persona | null;
        }
      | null;
  }> | null;
};

export type GetMemberExpenseDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetMemberExpenseDetailsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      expenses?: Array<{
        __typename?: 'Expense';
        id?: string | null;
        status?: ExpenseStatus | null;
        submittedAt?: any | null;
        createdOn?: any | null;
        createdBy?: string | null;
        updatedBy?: string | null;
        updatedOn?: any | null;
        title?: string | null;
        currency?: CurrencyCode | null;
        totalAmount?: number | null;
        totalInFunctionalCurrency?: number | null;
        isApprovable?: boolean | null;
        changeReason?: string | null;
        payrollCutOffDate?: any | null;
        items?: Array<{
          __typename?: 'ExpenseItem';
          id?: string | null;
          date?: any | null;
          description?: string | null;
          category?: string | null;
          amount?: number | null;
          amountInFunctionalCurrency?: number | null;
          title?: string | null;
          categoryV2?: {
            __typename?: 'ExpenseCategory';
            id?: string | null;
            name?: string | null;
            glCode?: {
              __typename?: 'CompanyGLCode';
              code?: string | null;
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          amountInForeignCurrency?: {
            __typename?: 'ExpenseAmountInfo';
            amount?: number | null;
            currency?: CurrencyCode | null;
          } | null;
          receipts?: Array<{
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            contentType?: string | null;
            link?: string | null;
            size?: number | null;
          } | null> | null;
          receiptsInForeignCurrency?: Array<{
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            contentType?: string | null;
            link?: string | null;
            size?: number | null;
          }> | null;
        } | null> | null;
        changes?: Array<{
          __typename?: 'ExpenseChange';
          status?: ExpenseStatus | null;
          actionedOn?: any | null;
        } | null> | null;
        approverInfos?: Array<{
          __typename?: 'ItemApproverInfo';
          status?: ApprovalItemStatus | null;
          updatedOn?: any | null;
          approverUser?:
            | {
                __typename?: 'CompanyUser';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | {
                __typename?: 'EmergencyPointOfContact';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | {
                __typename?: 'Member';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | {
                __typename?: 'OperationsUser';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | null;
        }> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetMemberExpensesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberExpensesQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      status?: ContractStatus | null;
      expenses?: Array<{
        __typename?: 'Expense';
        id?: string | null;
        status?: ExpenseStatus | null;
        submittedAt?: any | null;
        createdOn?: any | null;
        createdBy?: string | null;
        updatedBy?: string | null;
        updatedOn?: any | null;
        title?: string | null;
        currency?: CurrencyCode | null;
        totalAmount?: number | null;
        totalInFunctionalCurrency?: number | null;
        isApprovable?: boolean | null;
        changeReason?: string | null;
        payrollCutOffDate?: any | null;
        items?: Array<{
          __typename?: 'ExpenseItem';
          id?: string | null;
          title?: string | null;
          date?: any | null;
          category?: string | null;
          amount?: number | null;
          amountInFunctionalCurrency?: number | null;
          categoryV2?: {
            __typename?: 'ExpenseCategory';
            id?: string | null;
            name?: string | null;
            glCode?: {
              __typename?: 'CompanyGLCode';
              code?: string | null;
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
        } | null> | null;
        approverInfos?: Array<{
          __typename?: 'ItemApproverInfo';
          status?: ApprovalItemStatus | null;
          updatedOn?: any | null;
          approverUser?:
            | {
                __typename?: 'CompanyUser';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | {
                __typename?: 'EmergencyPointOfContact';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | {
                __typename?: 'Member';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | {
                __typename?: 'OperationsUser';
                id?: string | null;
                userId?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                persona?: Persona | null;
              }
            | null;
        }> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DeleteExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteExpenseMutation = {
  __typename?: 'Mutation';
  expenseDelete?: {
    __typename?: 'Expense';
    id?: string | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
  } | null;
};

export type ExpenseCategoryFragment = {
  __typename?: 'ExpenseCategory';
  id?: string | null;
  name?: string | null;
  glCode?: {
    __typename?: 'CompanyGLCode';
    code?: string | null;
    id?: string | null;
    name?: string | null;
  } | null;
};

export type GetCountryPayrollInputNotesQueryVariables = Exact<{
  country: CountryCode;
}>;

export type GetCountryPayrollInputNotesQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    payrollInputNotes?: Array<{
      __typename?: 'PayrollInputNote';
      countryCode: CountryCode;
      domain: PayrollInputNoteDomain;
      experience: Persona;
      id?: string | null;
      notes: Array<string>;
    }> | null;
  } | null;
};

export type GetExpenseCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<ExpenseCategoryFilter>;
}>;

export type GetExpenseCategoriesQuery = {
  __typename?: 'Query';
  expenseCategories?: Array<{
    __typename?: 'ExpenseCategory';
    id?: string | null;
    name?: string | null;
    glCode?: {
      __typename?: 'CompanyGLCode';
      code?: string | null;
      id?: string | null;
      name?: string | null;
    } | null;
  }> | null;
};

export type RevokeExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RevokeExpenseMutation = {
  __typename?: 'Mutation';
  expenseRevoke?: {
    __typename?: 'Expense';
    id?: string | null;
    status?: ExpenseStatus | null;
    submittedAt?: any | null;
    createdOn?: any | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    updatedOn?: any | null;
    title?: string | null;
    currency?: CurrencyCode | null;
    totalAmount?: number | null;
    totalInFunctionalCurrency?: number | null;
    isApprovable?: boolean | null;
    changeReason?: string | null;
    payrollCutOffDate?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
    items?: Array<{
      __typename?: 'ExpenseItem';
      id?: string | null;
      title?: string | null;
      date?: any | null;
      category?: string | null;
      amount?: number | null;
      amountInFunctionalCurrency?: number | null;
      categoryV2?: {
        __typename?: 'ExpenseCategory';
        id?: string | null;
        name?: string | null;
        glCode?: {
          __typename?: 'CompanyGLCode';
          code?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null> | null;
    approverInfos?: Array<{
      __typename?: 'ItemApproverInfo';
      status?: ApprovalItemStatus | null;
      updatedOn?: any | null;
      approverUser?:
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'EmergencyPointOfContact';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'OperationsUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | null;
    }> | null;
  } | null;
};

export type SaveAsDraftExpenseMutationVariables = Exact<{
  input: ExpenseSaveAsDraftInput;
}>;

export type SaveAsDraftExpenseMutation = {
  __typename?: 'Mutation';
  expenseSaveAsDraft?: {
    __typename?: 'Expense';
    id?: string | null;
    status?: ExpenseStatus | null;
    submittedAt?: any | null;
    createdOn?: any | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    updatedOn?: any | null;
    title?: string | null;
    currency?: CurrencyCode | null;
    totalAmount?: number | null;
    totalInFunctionalCurrency?: number | null;
    isApprovable?: boolean | null;
    changeReason?: string | null;
    payrollCutOffDate?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
    items?: Array<{
      __typename?: 'ExpenseItem';
      id?: string | null;
      title?: string | null;
      date?: any | null;
      category?: string | null;
      amount?: number | null;
      amountInFunctionalCurrency?: number | null;
      categoryV2?: {
        __typename?: 'ExpenseCategory';
        id?: string | null;
        name?: string | null;
        glCode?: {
          __typename?: 'CompanyGLCode';
          code?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null> | null;
    approverInfos?: Array<{
      __typename?: 'ItemApproverInfo';
      status?: ApprovalItemStatus | null;
      updatedOn?: any | null;
      approverUser?:
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'EmergencyPointOfContact';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'OperationsUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | null;
    }> | null;
  } | null;
};

export type SubmitExpenseMutationVariables = Exact<{
  input: ExpenseSubmitInput;
}>;

export type SubmitExpenseMutation = {
  __typename?: 'Mutation';
  expenseSubmit?: {
    __typename?: 'Expense';
    id?: string | null;
    status?: ExpenseStatus | null;
    submittedAt?: any | null;
    createdOn?: any | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    updatedOn?: any | null;
    title?: string | null;
    currency?: CurrencyCode | null;
    totalAmount?: number | null;
    totalInFunctionalCurrency?: number | null;
    isApprovable?: boolean | null;
    changeReason?: string | null;
    payrollCutOffDate?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
    items?: Array<{
      __typename?: 'ExpenseItem';
      id?: string | null;
      title?: string | null;
      date?: any | null;
      category?: string | null;
      amount?: number | null;
      amountInFunctionalCurrency?: number | null;
      categoryV2?: {
        __typename?: 'ExpenseCategory';
        id?: string | null;
        name?: string | null;
        glCode?: {
          __typename?: 'CompanyGLCode';
          code?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null> | null;
    approverInfos?: Array<{
      __typename?: 'ItemApproverInfo';
      status?: ApprovalItemStatus | null;
      updatedOn?: any | null;
      approverUser?:
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'EmergencyPointOfContact';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'OperationsUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | null;
    }> | null;
  } | null;
};

export type UpdateExpenseMutationVariables = Exact<{
  input: ExpenseUpdateInput;
}>;

export type UpdateExpenseMutation = {
  __typename?: 'Mutation';
  expenseUpdate?: {
    __typename?: 'Expense';
    id?: string | null;
    status?: ExpenseStatus | null;
    submittedAt?: any | null;
    createdOn?: any | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    updatedOn?: any | null;
    title?: string | null;
    currency?: CurrencyCode | null;
    totalAmount?: number | null;
    totalInFunctionalCurrency?: number | null;
    isApprovable?: boolean | null;
    changeReason?: string | null;
    payrollCutOffDate?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      type?: ContractType | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
    items?: Array<{
      __typename?: 'ExpenseItem';
      id?: string | null;
      title?: string | null;
      date?: any | null;
      category?: string | null;
      amount?: number | null;
      amountInFunctionalCurrency?: number | null;
      categoryV2?: {
        __typename?: 'ExpenseCategory';
        id?: string | null;
        name?: string | null;
        glCode?: {
          __typename?: 'CompanyGLCode';
          code?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null> | null;
    approverInfos?: Array<{
      __typename?: 'ItemApproverInfo';
      status?: ApprovalItemStatus | null;
      updatedOn?: any | null;
      approverUser?:
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'EmergencyPointOfContact';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | {
            __typename?: 'OperationsUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            persona?: Persona | null;
          }
        | null;
    }> | null;
  } | null;
};

export type ValidateExpenseInfoQueryVariables = Exact<{
  input: ExpenseValidationInfoInput;
}>;

export type ValidateExpenseInfoQuery = {
  __typename?: 'Query';
  expenseValidationInfo: {
    __typename?: 'ExpenseValidationInfo';
    amountLimit?: {
      __typename?: 'ExpenseAmountLimit';
      basis?: ExpenseAmountLimitBasis | null;
      currency?: CurrencyCode | null;
      limit?: number | null;
      remaining?: number | null;
    } | null;
    dateLimit?: {
      __typename?: 'ExpenseDateRange';
      end?: any | null;
      start?: any | null;
      threshold?: number | null;
      unit?: ExpenseDateThresholdUnit | null;
    } | null;
  };
};

export type ContractCreateBenefitChangeRequestsMutationVariables = Exact<{
  input: ContractBenefitChangeRequestInput;
}>;

export type ContractCreateBenefitChangeRequestsMutation = {
  __typename?: 'Mutation';
  contractCreateBenefitChangeRequests?: {
    __typename?: 'ContractBenefitChangeRequestResponse';
    id: string;
    status: ContractTypeChangeRequestStatus;
  } | null;
};

export type ContractBenefitBannersQueryVariables = Exact<{
  filters?: InputMaybe<ContractBenefitBannerFilters>;
}>;

export type ContractBenefitBannersQuery = {
  __typename?: 'Query';
  contractBenefitBanners?: Array<{
    __typename?: 'ContractBenefitBanner';
    id: string;
    contractId: string;
    status: ContractBenefitBannerStatus;
    startDate?: any | null;
    endDate?: any | null;
    type?: ContractBenefitBannerType | null;
  } | null> | null;
};

export type GetBenefitDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBenefitDetailsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullLegalName?: string | null;
    gender?: Gender | null;
    maritalStatus?: MaritalStatus | null;
    dateOfBirth?: any | null;
    age?: number | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      term?: ContractTerm | null;
      type?: ContractType | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      status?: ContractStatus | null;
      startOn?: any | null;
      endOn?: any | null;
      contractStarted?: boolean | null;
      benefits?: Array<{
        __typename?: 'ContractBenefit';
        id?: string | null;
        billAmount?: number | null;
        discountTermPercentage?: number | null;
        employeePayPercentage?: number | null;
        employerPayPercentage?: number | null;
        externalPolicyFileUrl?: string | null;
        externalPolicyId?: string | null;
        startOn?: any | null;
        endOn?: any | null;
        dependentCount?: number | null;
        status?: ContractBenefitStatus | null;
        insurancePremium?: {
          __typename?: 'InsurancePremium';
          benefitType?: BenefitType | null;
          billingDuration?: BenefitPartnerBillingFrequency | null;
          employeePayAmount?: number | null;
          employeePayPercentage?: number | null;
          employerPayAmount?: number | null;
          employerPayPercentage?: number | null;
          totalPlatformFee?: number | null;
          totalPremium?: number | null;
          dependents?: Array<{
            __typename?: 'InsuranceIndividualPremium';
            id?: string | null;
            billingPeriodInMonths?: number | null;
            endOn?: any | null;
            firstName?: string | null;
            lastName?: string | null;
            platformFee?: number | null;
            startOn?: any | null;
            subTotalAmount?: number | null;
            subTotalPlatformFee?: number | null;
            benefitPackageCost?: {
              __typename?: 'BenefitPackageCost';
              id: string;
              premium?: number | null;
              premiumCurrency?: CurrencyCode | null;
              premiumFrequency?: BenefitPremiumFrequency | null;
            } | null;
          } | null> | null;
          self?: {
            __typename?: 'InsuranceIndividualPremium';
            subTotalAmount?: number | null;
            subTotalPlatformFee?: number | null;
            platformFee?: number | null;
            id?: string | null;
            billingPeriodInMonths?: number | null;
            benefitPackageCost?: {
              __typename?: 'BenefitPackageCost';
              premium?: number | null;
              id: string;
              premiumCurrency?: CurrencyCode | null;
              premiumFrequency?: BenefitPremiumFrequency | null;
            } | null;
          } | null;
        } | null;
        dependents?: Array<{
          __typename?: 'ContractBenefitDependent';
          id?: string | null;
          dateOfBirth?: any | null;
          startOn?: any | null;
          endOn?: any | null;
          externalPolicyFileUrl?: string | null;
          externalPolicyId?: string | null;
          firstName?: string | null;
          gender?: Gender | null;
          lastName?: string | null;
          relationshipToMember?: Relationship | null;
          documents?: Array<{
            __typename?: 'ContractBenefitDependentDocument';
            key?: ContractBenefitDependentDocumentType | null;
            files?: Array<{
              __typename?: 'Document';
              id?: string | null;
              downloadUrl?: string | null;
              name?: string | null;
              size?: number | null;
            }> | null;
          } | null> | null;
          benefit?: {
            __typename?: 'Benefit';
            id: string;
            provider?: {
              __typename?: 'BenefitPartnerCountry';
              id?: string | null;
              platformFee?: number | null;
            } | null;
          } | null;
          legalData?: Array<{
            __typename?: 'ContractBenefitDependentLegalData';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
        benefit?: {
          __typename?: 'Benefit';
          id: string;
          cost?: number | null;
          costingType?: BenefitCostingType | null;
          packageName?: string | null;
          type?: BenefitType | null;
          benefitDocuments?: Array<{
            __typename?: 'BenefitDocument';
            benefitDocumentType?: BenefitDocumentType | null;
            files?: Array<{
              __typename?: 'Document';
              id?: string | null;
              downloadUrl?: string | null;
              name?: string | null;
            }> | null;
          } | null> | null;
          memberOnboardingKit?: {
            __typename?: 'BenefitMemberOnboardingKit';
            memberOnboardingKitFile?: {
              __typename?: 'BenefitDocument';
              benefitDocumentType?: BenefitDocumentType | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                downloadUrl?: string | null;
              }> | null;
            } | null;
          } | null;
          features?: Array<{
            __typename?: 'BenefitFeatures';
            key?: string | null;
            label?: string | null;
            value?: string | null;
          } | null> | null;
          packageTier?: {
            __typename?: 'BenefitPackageTier';
            tier?: BenefitTier | null;
          } | null;
          provider?: {
            __typename?: 'BenefitPartnerCountry';
            id?: string | null;
            partnerName?: string | null;
            status?: BenefitPartnerStatus | null;
            platformFee?: number | null;
            ageBandApplicable?: boolean | null;
            platformFeeApplicable?: boolean | null;
            erSplitApplicable?: boolean | null;
            billingCurrency?: CurrencyCode | null;
            isInsuranceMandatory?: boolean | null;
            emergencyPointOfContact?:
              | {
                  __typename?: 'CompanyUser';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'EmergencyPointOfContact';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'Member';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'OperationsUser';
                  firstName?: string | null;
                  lastName?: string | null;
                  emails?: Array<{
                    __typename?: 'EmailAddress';
                    type?: string | null;
                    email?: string | null;
                  } | null> | null;
                }
              | null;
            coverageGroup?: {
              __typename?: 'InsuranceCoverageGroup';
              id?: string | null;
              name?: string | null;
              group?: Array<{
                __typename?: 'InsuranceCoverage';
                maxCount: number;
                relation?: {
                  __typename?: 'InsuranceRelation';
                  familyMember?: string | null;
                  id?: string | null;
                } | null;
              } | null> | null;
            } | null;
            metaData?: {
              __typename?: 'BenefitMetaData';
              pocName?: string | null;
              emails?: Array<{
                __typename?: 'EmailAddress';
                type?: string | null;
                email?: string | null;
              } | null> | null;
              phoneNos?: Array<{
                __typename?: 'PhoneNumber';
                phoneNo?: string | null;
                type?: string | null;
              } | null> | null;
            } | null;
            brokerData?: {
              __typename?: 'BenefitBrokerData';
              name?: string | null;
            } | null;
            entity?: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
            } | null;
          } | null;
        } | null;
        params?: Array<{
          __typename?: 'ContractBenefitParam';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetBenefitPartnerCountryDetailsQueryVariables = Exact<{
  filters?: InputMaybe<BenefitPartnerCountryFilters>;
  pageRequest?: InputMaybe<PageRequest>;
}>;

export type GetBenefitPartnerCountryDetailsQuery = {
  __typename?: 'Query';
  benefitPartnerCountriesWithPagination?: {
    __typename?: 'BenefitPartnerCountriesResult';
    data?: Array<{
      __typename?: 'BenefitPartnerCountry';
      id?: string | null;
      country?: CountryCode | null;
      partnerName?: string | null;
      platformFee?: number | null;
      insuranceType?: BenefitInsuranceType | null;
      billingDuration?: BenefitPartnerBillingFrequency | null;
      erSplitApplicable?: boolean | null;
      ageBandApplicable?: boolean | null;
      minimumErSplitPercentage?: number | null;
      benefitsDomain?: BenefitsDomain | null;
      refundPolicy?: boolean | null;
      billingCurrency?: CurrencyCode | null;
      platformFeeApplicable?: boolean | null;
      isInsuranceMandatory?: boolean | null;
      brokerData?: {
        __typename?: 'BenefitBrokerData';
        name?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type ContractBenefitChangeRequestsQueryVariables = Exact<{
  filters?: InputMaybe<ContractBenefitChangeRequestFilters>;
}>;

export type ContractBenefitChangeRequestsQuery = {
  __typename?: 'Query';
  contractBenefitChangeRequests?: Array<{
    __typename?: 'ContractBenefitChangeRequestResponse';
    contractId: string;
    id: string;
    status: ContractTypeChangeRequestStatus;
    type: ContractBenefitChangeRequestType;
    changes: {
      __typename?: 'ContractBenefitChangeRequestChange';
      dependentChanges?: Array<{
        __typename?: 'ContractBenefitChangeRequestDependentDetailsChange';
        contractBenefitDependentId: string;
        changes?: Array<{
          __typename?: 'ContractBenefitChangeRequestDependentDetails';
          field: string;
          newValue: string;
          oldValue: string;
        } | null> | null;
      } | null> | null;
    };
  } | null> | null;
};

export type GetContractBenefitDocumentQueryVariables = Exact<{
  contractBenefitDocumentId: Scalars['ID'];
}>;

export type GetContractBenefitDocumentQuery = {
  __typename?: 'Query';
  contractBenefitDocument?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
    extension?: string | null;
    contentType?: string | null;
    blob?: string | null;
    htmlPreview?: string | null;
  } | null;
};

export type GetCountryInsuranceQueryVariables = Exact<{
  country?: InputMaybe<CountryCode>;
  countryStateCode?: InputMaybe<Scalars['String']>;
  filters: BenefitFilters;
}>;

export type GetCountryInsuranceQuery = {
  __typename?: 'Query';
  benefits?: Array<{
    __typename?: 'Benefit';
    id: string;
    type?: BenefitType | null;
    dependentMaxCount?: number | null;
    packageName?: string | null;
    description?: string | null;
    factSheetUrl?: string | null;
    country?: CountryCode | null;
    location?: string | null;
    currency?: CurrencyCode | null;
    cost?: number | null;
    costingType?: BenefitCostingType | null;
    frequency?: RateFrequency | null;
    isRecommended?: boolean | null;
    compareBenefits?: {
      __typename?: 'BenefitComparison';
      comparisonFileUrl?: string | null;
    } | null;
    features?: Array<{
      __typename?: 'BenefitFeatures';
      key?: string | null;
      label?: string | null;
      value?: string | null;
    } | null> | null;
    provider?: {
      __typename?: 'BenefitPartnerCountry';
      id?: string | null;
      entity?: { __typename?: 'LegalEntity'; legalName?: string | null } | null;
      partner?: {
        __typename?: 'Partner';
        id?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type GetDependentBannerDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDependentBannerDetailsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      benefits?: Array<{
        __typename?: 'ContractBenefit';
        id?: string | null;
        dependents?: Array<{
          __typename?: 'ContractBenefitDependent';
          id?: string | null;
          dateOfBirth?: any | null;
          firstName?: string | null;
          lastName?: string | null;
          gender?: Gender | null;
          relationshipToMember?: Relationship | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetSingleBenefitQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSingleBenefitQuery = {
  __typename?: 'Query';
  benefit?: {
    __typename?: 'Benefit';
    id: string;
    type?: BenefitType | null;
    dependentMaxCount?: number | null;
    packageName?: string | null;
    description?: string | null;
    factSheetUrl?: string | null;
    country?: CountryCode | null;
    location?: string | null;
    currency?: CurrencyCode | null;
    cost?: number | null;
    costingType?: BenefitCostingType | null;
    frequency?: RateFrequency | null;
    isRecommended?: boolean | null;
    compareBenefits?: {
      __typename?: 'BenefitComparison';
      comparisonFileUrl?: string | null;
    } | null;
    features?: Array<{
      __typename?: 'BenefitFeatures';
      key?: string | null;
      label?: string | null;
      value?: string | null;
    } | null> | null;
    provider?: {
      __typename?: 'BenefitPartnerCountry';
      id?: string | null;
      partner?: {
        __typename?: 'Partner';
        id?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ChangeSyncStateMutationVariables = Exact<{
  integrationId: Scalars['ID'];
  syncType: SyncType;
  on: Scalars['Boolean'];
}>;

export type ChangeSyncStateMutation = {
  __typename?: 'Mutation';
  changeSyncState?: {
    __typename?: 'TaskResponse';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type CompanyIntegrationInfoQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type CompanyIntegrationInfoQuery = {
  __typename?: 'Query';
  companyIntegrationInfo?: {
    __typename?: 'CompanyIntegrationInfo';
    companyId?: string | null;
    incomingSyncEnabled?: boolean | null;
    lastIncomingSyncId?: string | null;
    incomingSyncInProgress?: boolean | null;
    lastIncomingSyncStartTime?: any | null;
    lastOutgoingSyncId?: string | null;
    lastOutgoingSyncStartTime?: any | null;
    outgoingSyncEnabled?: boolean | null;
    outgoingSyncInProgress?: boolean | null;
    platformID?: string | null;
    providerId?: string | null;
    incomingImportInProgress?: boolean | null;
  } | null;
};

export type DisconnectCustomerIntegrationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type DisconnectCustomerIntegrationMutation = {
  __typename?: 'Mutation';
  customerIntegrationDisconnect?: {
    __typename?: 'CustomerIntegration';
    id?: string | null;
  } | null;
};

export type DismissSyncResultMutationVariables = Exact<{
  syncId: Scalars['String'];
}>;

export type DismissSyncResultMutation = {
  __typename?: 'Mutation';
  dismissSyncResult?: {
    __typename?: 'TaskResponse';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type GetCompanyCustomerIntegrationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyCustomerIntegrationsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    customerIntegrations?: Array<{
      __typename?: 'CustomerIntegration';
      id?: string | null;
      name?: string | null;
      category?: PlatformCategory | null;
      connected?: boolean | null;
      isPositionDropdownListEnabled?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetCompanySsoPreferenceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanySsoPreferenceQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    ssoPreference?: Array<{
      __typename?: 'SSOPreference';
      id: string;
      identityProvider?: IdentityProvider | null;
      enabled?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetEmployeesToSendInviteToQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type GetEmployeesToSendInviteToQuery = {
  __typename?: 'Query';
  getEmployeesToSendInviteTo?: Array<{
    __typename?: 'Employee';
    externalId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null> | null;
};

export type GetIntegrationEntityFieldMappingQueryVariables = Exact<{
  integrationId: Scalars['ID'];
  entityId: Scalars['ID'];
}>;

export type GetIntegrationEntityFieldMappingQuery = {
  __typename?: 'Query';
  getIntegrationFieldsMapping?: {
    __typename?: 'IntegrationFieldsMappingOutput';
    integrationId?: string | null;
    entityMappingStatus?: LegalMappingStatus | null;
    legalEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
    fieldsMapping?: Array<{
      __typename?: 'FieldMapping';
      originField?: string | null;
      originFieldLabel?: string | null;
      mappedField?: string | null;
      mappedFieldLabel?: string | null;
      isRequired?: boolean | null;
      isActive?: boolean | null;
      isCalculated?: boolean | null;
      type?: string | null;
      children?: Array<{
        __typename?: 'FieldMapping';
        originField?: string | null;
        originFieldLabel?: string | null;
        mappedField?: string | null;
        mappedFieldLabel?: string | null;
        isRequired?: boolean | null;
        isActive?: boolean | null;
      } | null> | null;
    } | null> | null;
    thirdPartyFields?: Array<{
      __typename?: 'UnmappedField';
      key?: string | null;
      label?: string | null;
      type?: string | null;
      isMappedByThirdParty?: boolean | null;
      fieldId?: string | null;
      fieldFromApp?: string | null;
      children?: Array<{
        __typename?: 'UnmappedField';
        key?: string | null;
        label?: string | null;
        type?: string | null;
        isMappedByThirdParty?: boolean | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetIntegrationEntityMappingQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type GetIntegrationEntityMappingQuery = {
  __typename?: 'Query';
  getIntegrationEntityMappingStatus?: Array<{
    __typename?: 'IntegrationEntityMappingStatusOutput';
    entityMappingId?: string | null;
    integrationId?: string | null;
    entityMappingStatus?: LegalMappingStatus | null;
    isEnabled?: boolean | null;
    legalEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      status?: LegalEntityStatus | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
  } | null> | null;
};

export type GetLatestEmployeesForImportQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type GetLatestEmployeesForImportQuery = {
  __typename?: 'Query';
  getLatestEmployeesForImport?: {
    __typename?: 'FetchEmployeesResult';
    syncId?: string | null;
    employees?: Array<{
      __typename?: 'Employee';
      externalId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
  } | null;
};

export type GetLatestSyncResultForIntegrationQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type GetLatestSyncResultForIntegrationQuery = {
  __typename?: 'Query';
  getLatestSyncResultForIntegration?: {
    __typename?: 'LatestSyncResult';
    addedContracts?: number | null;
    completedAt?: any | null;
    createdAt?: any | null;
    failedContracts?: number | null;
    integrationId?: string | null;
    status?: SyncStatus | null;
    syncId?: string | null;
    updatedAt?: any | null;
    updatedContracts?: number | null;
    dismissedAt?: any | null;
  } | null;
};

export type ImportEmployeesMutationVariables = Exact<{
  employees:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
  syncId: Scalars['String'];
}>;

export type ImportEmployeesMutation = {
  __typename?: 'Mutation';
  importEmployees?: {
    __typename?: 'TaskResponse';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type ListPlatformsQueryVariables = Exact<{ [key: string]: never }>;

export type ListPlatformsQuery = {
  __typename?: 'Query';
  customerIntegrationDefinitions?: Array<{
    __typename?: 'CustomerIntegrationDefinition';
    id?: string | null;
    name?: string | null;
    color?: string | null;
    category?: PlatformCategory | null;
    logoLink?: string | null;
    squareLogoLink?: string | null;
  } | null> | null;
};

export type PlatformCompanyTokenUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  platformId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type PlatformCompanyTokenUpdateMutation = {
  __typename?: 'Mutation';
  platformCompanyTokenUpdate?: {
    __typename?: 'TaskResponse';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type ProviderKnitAuthTokenMutationVariables = Exact<{
  originOrgId: Scalars['String'];
  originOrgName: Scalars['String'];
  originUserEmail: Scalars['String'];
  originUserName: Scalars['String'];
  platformId: Scalars['String'];
}>;

export type ProviderKnitAuthTokenMutation = {
  __typename?: 'Mutation';
  providerKnitAuthToken?: {
    __typename?: 'KnitAuthResponse';
    success: boolean;
    error?: { __typename?: 'KnitAuthTokenError'; msg?: string | null } | null;
    msg?: { __typename?: 'KnitAuthToken'; token?: string | null } | null;
  } | null;
};

export type SaveIntegrationEntityMappingStatusMutationVariables = Exact<{
  entityMappingId: Scalars['ID'];
  enableDataSync?: InputMaybe<Scalars['Boolean']>;
}>;

export type SaveIntegrationEntityMappingStatusMutation = {
  __typename?: 'Mutation';
  saveIntegrationEntityMappingStatus?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type SaveIntegrationFieldMappingMutationVariables = Exact<{
  input?: InputMaybe<SaveIntegrationFieldsMappingInput>;
}>;

export type SaveIntegrationFieldMappingMutation = {
  __typename?: 'Mutation';
  saveIntegrationFieldsMapping?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type SendImportedMemberInvitesMutationVariables = Exact<{
  employees:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
  syncId?: InputMaybe<Scalars['String']>;
}>;

export type SendImportedMemberInvitesMutation = {
  __typename?: 'Mutation';
  sendImportedMemberInvites?: {
    __typename?: 'TaskResponse';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type StartIncomingSyncMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type StartIncomingSyncMutation = {
  __typename?: 'Mutation';
  startIncomingSync?: {
    __typename?: 'TaskResponse';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type SyncEorManuallyMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type SyncEorManuallyMutation = {
  __typename?: 'Mutation';
  syncEORManually?: {
    __typename?: 'SyncEORManuallyResult';
    integrationId?: string | null;
    success?: boolean | null;
    message?: string | null;
    syncId?: string | null;
  } | null;
};

export type SyncStatusQueryVariables = Exact<{
  integrationId: Scalars['ID'];
  syncType?: InputMaybe<SyncType>;
}>;

export type SyncStatusQuery = {
  __typename?: 'Query';
  syncStatus?: {
    __typename?: 'CompanySyncStatus';
    companyId?: string | null;
    createdAt?: any | null;
    status?: SyncStatus | null;
    updatedAt?: any | null;
  } | null;
};

export type SyncSummaryResultDownloadQueryVariables = Exact<{
  input: SyncSummaryResultDownloadInput;
}>;

export type SyncSummaryResultDownloadQuery = {
  __typename?: 'Query';
  syncSummaryResultDownload?: {
    __typename?: 'SyncSummaryResultDownloadOutput';
    isDownloaded?: boolean | null;
    reportFile?: {
      __typename?: 'DocumentReadable';
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
      blob?: string | null;
      htmlPreview?: string | null;
    } | null;
  } | null;
};

export type UpsertSsoPreferenceForCompanyMutationVariables = Exact<{
  companyId: Scalars['ID'];
  input: UpsertSsoPreferenceInput;
}>;

export type UpsertSsoPreferenceForCompanyMutation = {
  __typename?: 'Mutation';
  upsertSSOPreferenceForCompany?: {
    __typename?: 'SSOPreference';
    id: string;
    enabled?: boolean | null;
    identityProvider?: IdentityProvider | null;
  } | null;
};

export type ValidateIntegrationCredentialsQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type ValidateIntegrationCredentialsQuery = {
  __typename?: 'Query';
  validateIntegrationCredentials?: {
    __typename?: 'TaskResponse';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type VerifyTriNetCredentialMutationVariables = Exact<{
  input: TrinetCredentialInputV2;
}>;

export type VerifyTriNetCredentialMutation = {
  __typename?: 'Mutation';
  verifyTriNetCredential?: {
    __typename?: 'TrinetCredentialResult';
    message: string;
    success?: boolean | null;
  } | null;
};

export type AssociateUserToCompanyMutationVariables = Exact<{
  [key: string]: never;
}>;

export type AssociateUserToCompanyMutation = {
  __typename?: 'Mutation';
  addOrAssociatePartnerCompanyUser?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
  } | null;
};

export type CompanyAcceptInviteMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyAcceptInviteMutation = {
  __typename?: 'Mutation';
  companyAcceptInvite?: { __typename?: 'Company'; id?: string | null } | null;
};

export type CompanyUserAcceptInviteMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyUserAcceptInviteMutation = {
  __typename?: 'Mutation';
  companyUserAcceptInvite?: {
    __typename?: 'CompanyUser';
    id?: string | null;
  } | null;
};

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  companyCreate?: { __typename?: 'Company'; id?: string | null } | null;
};

export type GetCompanyDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyDetailsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
    status?: CompanyStatus | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      registrationNo?: string | null;
      phone?: string | null;
      address?: {
        __typename?: 'Address';
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        zipcode?: string | null;
        country?: CountryCode | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyPrefillDataQueryVariables = Exact<{
  input?: InputMaybe<CompanyPreFillDataInput>;
}>;

export type GetCompanyPrefillDataQuery = {
  __typename?: 'Query';
  getCompanyPrefillData?: {
    __typename?: 'CompanyPreFillData';
    name?: string | null;
    domain?: string | null;
    logo?: {
      __typename?: 'DocumentReadable';
      link?: string | null;
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
  } | null;
};

export type GetCompanyStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyStatusQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    status?: CompanyStatus | null;
  } | null;
};

export type GetCompanyUsersAndContractsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyUsersAndContractsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      type?: ContractType | null;
      status?: ContractStatus | null;
    } | null> | null;
    managers?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      status?: CompanyUserStatus | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
    signatories?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
    billingContact?: {
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetMemberContractTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberContractTypeQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      type?: ContractType | null;
      term?: ContractTerm | null;
      startOn?: any | null;
      endOn?: any | null;
      benefits?: Array<{
        __typename?: 'ContractBenefit';
        id?: string | null;
        status?: ContractBenefitStatus | null;
        startOn?: any | null;
        benefit?: { __typename?: 'Benefit'; id: string } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetMemberSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberSummaryQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      type?: ContractType | null;
      position?: string | null;
      country?: CountryCode | null;
      status?: ContractStatus | null;
      endedOn?: any | null;
      currency?: CurrencyCode | null;
      paymentAccountId?: string | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
      } | null;
      benefits?: Array<{
        __typename?: 'ContractBenefit';
        benefit?: {
          __typename?: 'Benefit';
          type?: BenefitType | null;
          packageName?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetMultipleCompaniesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  userID?: InputMaybe<Scalars['ID']>;
}>;

export type GetMultipleCompaniesQuery = {
  __typename?: 'Query';
  companyGroup?: Array<{
    __typename?: 'CompanyGroup';
    id: string;
    name?: string | null;
    children?: Array<{
      __typename?: 'Company';
      id?: string | null;
      displayName?: string | null;
      companyLogo?: {
        __typename?: 'DocumentReadable';
        link?: string | null;
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetMemberContractIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberContractIdQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
    } | null> | null;
  } | null;
};

export type MemberStartedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MemberStartedMutation = {
  __typename?: 'Mutation';
  contractMemberStarted?: {
    __typename?: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    onboarding?: {
      __typename?: 'ContractOnboarding';
      current?: ContractOnboardingStep | null;
    } | null;
  } | null;
};

export type GetAllRecruitersQueryVariables = Exact<{
  filters?: InputMaybe<RecruiterFilters>;
}>;

export type GetAllRecruitersQuery = {
  __typename?: 'Query';
  recruiters?: Array<{
    __typename: 'Recruiter';
    id?: string | null;
    name?: string | null;
    description?: string | null;
    logo?: {
      __typename: 'DocumentReadable';
      id?: string | null;
      link?: string | null;
    } | null;
    features?: Array<{
      __typename: 'RecruiterFeature';
      name?: Array<string | null> | null;
      category?: FeatureCategory | null;
    } | null> | null;
  } | null> | null;
};

export type GetRecruiterQueryVariables = Exact<{
  filters?: InputMaybe<RecruiterFilters>;
}>;

export type GetRecruiterQuery = {
  __typename?: 'Query';
  recruiters?: Array<{
    __typename: 'Recruiter';
    id?: string | null;
    name?: string | null;
    description?: string | null;
    logo?: {
      __typename: 'DocumentReadable';
      id?: string | null;
      extension?: string | null;
      name?: string | null;
      link?: string | null;
      contentType?: string | null;
      versionedOn?: any | null;
    } | null;
    phones?: Array<{
      __typename: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    emails?: Array<{
      __typename: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
    features?: Array<{
      __typename: 'RecruiterFeature';
      name?: Array<string | null> | null;
      category?: FeatureCategory | null;
    } | null> | null;
  } | null> | null;
};

export type ApproveInvoiceMutationVariables = Exact<{
  itemId: Scalars['ID'];
}>;

export type ApproveInvoiceMutation = {
  __typename?: 'Mutation';
  approveItem?:
    | { __typename?: 'ExpenseApprovalItem' }
    | { __typename?: 'LegalDocumentsApprovalItem' }
    | { __typename?: 'MemberDataApprovalItem' }
    | {
        __typename?: 'MemberPayableApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
        memberPayableItem?:
          | {
              __typename?: 'ExpenseMemberPayable';
              id: string;
              createdOn?: any | null;
              currency?: CurrencyCode | null;
              totalAmount?: number | null;
              totalInFunctionalCurrency?: number | null;
              dueDate?: any | null;
              status?: MemberPayableStatus | null;
              changes?: Array<{
                __typename?: 'MemberPayableChange';
                status?: MemberPayableStatus | null;
                actionedOn?: any | null;
              } | null> | null;
              contract?: {
                __typename?: 'Contract';
                id?: string | null;
                country?: CountryCode | null;
                member?: {
                  __typename?: 'Member';
                  id?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'FreelancerPayable';
              id: string;
              createdOn?: any | null;
              currency?: CurrencyCode | null;
              totalAmount?: number | null;
              totalInFunctionalCurrency?: number | null;
              dueDate?: any | null;
              status?: MemberPayableStatus | null;
              changes?: Array<{
                __typename?: 'MemberPayableChange';
                status?: MemberPayableStatus | null;
                actionedOn?: any | null;
              } | null> | null;
              contract?: {
                __typename?: 'Contract';
                id?: string | null;
                country?: CountryCode | null;
                member?: {
                  __typename?: 'Member';
                  id?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'PaySupplementMemberPayable';
              id: string;
              createdOn?: any | null;
              currency?: CurrencyCode | null;
              totalAmount?: number | null;
              totalInFunctionalCurrency?: number | null;
              dueDate?: any | null;
              status?: MemberPayableStatus | null;
              changes?: Array<{
                __typename?: 'MemberPayableChange';
                status?: MemberPayableStatus | null;
                actionedOn?: any | null;
              } | null> | null;
              contract?: {
                __typename?: 'Contract';
                id?: string | null;
                country?: CountryCode | null;
                member?: {
                  __typename?: 'Member';
                  id?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              } | null;
            }
          | null;
      }
    | { __typename?: 'TimeOffApprovalItem' }
    | null;
};

export type ApproveInvoicesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ApproveInvoicesMutation = {
  __typename?: 'Mutation';
  memberPayableUpdateBulkAsApproved?: Array<
    | { __typename?: 'ExpenseMemberPayable' }
    | {
        __typename?: 'FreelancerPayable';
        id: string;
        type?: MemberPayableType | null;
        payableFromDate?: any | null;
        payableToDate?: any | null;
        dueDate?: any | null;
        title?: string | null;
        description?: string | null;
        submittedAt?: any | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        currency?: CurrencyCode | null;
        totalAmount?: number | null;
        totalInFunctionalCurrency?: number | null;
        status?: MemberPayableStatus | null;
        durationValue?: number | null;
        changeReason?: string | null;
        comment?: string | null;
        rejectedAt?: any | null;
        approvedAt?: any | null;
        payOutInfo?: Array<{
          __typename?: 'MemberPayablePayOutInfo';
          paidOn?: any | null;
          paymentPartner?: string | null;
        } | null> | null;
        memberPayableInvoiceWorkflow?: {
          __typename?: 'InvoiceWorkFlow';
          allSteps?: Array<{
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null> | null;
          currentStep?: {
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          position?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        } | null;
      }
    | { __typename?: 'PaySupplementMemberPayable' }
    | null
  > | null;
};

export type ExpenseMemberPayableFragment = {
  __typename?: 'ExpenseMemberPayable';
  id: string;
  type?: MemberPayableType | null;
  payableFromDate?: any | null;
  payableToDate?: any | null;
  dueDate?: any | null;
  title?: string | null;
  description?: string | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  status?: MemberPayableStatus | null;
  rejectedAt?: any | null;
  payOutInfo?: Array<{
    __typename?: 'MemberPayablePayOutInfo';
    paidOn?: any | null;
    paymentPartner?: string | null;
  } | null> | null;
  memberPayableInvoiceWorkflow?: {
    __typename?: 'InvoiceWorkFlow';
    allSteps?: Array<{
      __typename?: 'InvoiceWorkflowStepDefinition';
      step?: InvoiceWorkflowStep | null;
      statuses?: Array<MemberPayableStatus | null> | null;
      currentStatus?: MemberPayableStatus | null;
      data?: Array<{
        __typename?: 'InvoiceStepData';
        key?: string | null;
        value?: string | null;
        type?: string | null;
      } | null> | null;
    } | null> | null;
    currentStep?: {
      __typename?: 'InvoiceWorkflowStepDefinition';
      step?: InvoiceWorkflowStep | null;
      statuses?: Array<MemberPayableStatus | null> | null;
      currentStatus?: MemberPayableStatus | null;
      data?: Array<{
        __typename?: 'InvoiceStepData';
        key?: string | null;
        value?: string | null;
        type?: string | null;
      } | null> | null;
    } | null;
  } | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    position?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
  expense?: { __typename?: 'Expense'; id?: string | null } | null;
};

export type FreelancerPayableFragment = {
  __typename?: 'FreelancerPayable';
  id: string;
  type?: MemberPayableType | null;
  payableFromDate?: any | null;
  payableToDate?: any | null;
  dueDate?: any | null;
  title?: string | null;
  description?: string | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  status?: MemberPayableStatus | null;
  durationValue?: number | null;
  changeReason?: string | null;
  comment?: string | null;
  rejectedAt?: any | null;
  approvedAt?: any | null;
  payOutInfo?: Array<{
    __typename?: 'MemberPayablePayOutInfo';
    paidOn?: any | null;
    paymentPartner?: string | null;
  } | null> | null;
  memberPayableInvoiceWorkflow?: {
    __typename?: 'InvoiceWorkFlow';
    allSteps?: Array<{
      __typename?: 'InvoiceWorkflowStepDefinition';
      step?: InvoiceWorkflowStep | null;
      statuses?: Array<MemberPayableStatus | null> | null;
      currentStatus?: MemberPayableStatus | null;
      data?: Array<{
        __typename?: 'InvoiceStepData';
        key?: string | null;
        value?: string | null;
        type?: string | null;
      } | null> | null;
    } | null> | null;
    currentStep?: {
      __typename?: 'InvoiceWorkflowStepDefinition';
      step?: InvoiceWorkflowStep | null;
      statuses?: Array<MemberPayableStatus | null> | null;
      currentStatus?: MemberPayableStatus | null;
      data?: Array<{
        __typename?: 'InvoiceStepData';
        key?: string | null;
        value?: string | null;
        type?: string | null;
      } | null> | null;
    } | null;
  } | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    position?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type PaySupplementMemberPayableFragment = {
  __typename?: 'PaySupplementMemberPayable';
  id: string;
  type?: MemberPayableType | null;
  payableFromDate?: any | null;
  payableToDate?: any | null;
  dueDate?: any | null;
  title?: string | null;
  description?: string | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  status?: MemberPayableStatus | null;
  rejectedAt?: any | null;
  payOutInfo?: Array<{
    __typename?: 'MemberPayablePayOutInfo';
    paidOn?: any | null;
    paymentPartner?: string | null;
  } | null> | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    position?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
  paySupplement?: { __typename?: 'PaySupplement'; id?: string | null } | null;
};

export type GetCompanyMemberPayableDetailsQueryVariables = Exact<{
  payableId: Scalars['ID'];
}>;

export type GetCompanyMemberPayableDetailsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    memberPayables?: Array<
      | {
          __typename?: 'ExpenseMemberPayable';
          payoutCompletedAt?: any | null;
          payoutInitiatedAt?: any | null;
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          status?: MemberPayableStatus | null;
          rejectedAt?: any | null;
          payOutInfo?: Array<{
            __typename?: 'MemberPayablePayOutInfo';
            paidOn?: any | null;
            paymentPartner?: string | null;
          } | null> | null;
          memberPayableInvoiceWorkflow?: {
            __typename?: 'InvoiceWorkFlow';
            allSteps?: Array<{
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null> | null;
            currentStep?: {
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null;
          } | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
          expense?: { __typename?: 'Expense'; id?: string | null } | null;
        }
      | {
          __typename?: 'FreelancerPayable';
          payoutCompletedAt?: any | null;
          payoutInitiatedAt?: any | null;
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          status?: MemberPayableStatus | null;
          durationValue?: number | null;
          changeReason?: string | null;
          comment?: string | null;
          rejectedAt?: any | null;
          approvedAt?: any | null;
          payOutInfo?: Array<{
            __typename?: 'MemberPayablePayOutInfo';
            paidOn?: any | null;
            paymentPartner?: string | null;
          } | null> | null;
          memberPayableInvoiceWorkflow?: {
            __typename?: 'InvoiceWorkFlow';
            allSteps?: Array<{
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null> | null;
            currentStep?: {
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null;
          } | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: 'PaySupplementMemberPayable';
          payoutCompletedAt?: any | null;
          payoutInitiatedAt?: any | null;
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          status?: MemberPayableStatus | null;
          rejectedAt?: any | null;
          payOutInfo?: Array<{
            __typename?: 'MemberPayablePayOutInfo';
            paidOn?: any | null;
            paymentPartner?: string | null;
          } | null> | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
          paySupplement?: {
            __typename?: 'PaySupplement';
            id?: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type GetCompanyUserMemberPayableDownloadAsPdfQueryVariables = Exact<{
  memberPayableId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyUserMemberPayableDownloadAsPdfQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    memberPayables?: Array<
      | {
          __typename?: 'ExpenseMemberPayable';
          file?: {
            __typename?: 'FileLink';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            link?: string | null;
          } | null;
        }
      | {
          __typename?: 'FreelancerPayable';
          file?: {
            __typename?: 'FileLink';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            link?: string | null;
          } | null;
        }
      | {
          __typename?: 'PaySupplementMemberPayable';
          file?: {
            __typename?: 'FileLink';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            link?: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type GetCompanyUserMemberPaymentProofDownloadAsPdfQueryVariables = Exact<{
  memberPayableId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyUserMemberPaymentProofDownloadAsPdfQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    memberPayables?: Array<
      | {
          __typename?: 'ExpenseMemberPayable';
          paymentReceipt?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            blob?: string | null;
          } | null;
        }
      | {
          __typename?: 'FreelancerPayable';
          paymentReceipt?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            blob?: string | null;
          } | null;
        }
      | {
          __typename?: 'PaySupplementMemberPayable';
          paymentReceipt?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
            blob?: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type GetCompanyMemberPayablesQueryVariables = Exact<{
  types?: InputMaybe<Array<MemberPayableType> | MemberPayableType>;
}>;

export type GetCompanyMemberPayablesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    memberPayables?: Array<
      | {
          __typename?: 'ExpenseMemberPayable';
          payoutCompletedAt?: any | null;
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          status?: MemberPayableStatus | null;
          rejectedAt?: any | null;
          payOutInfo?: Array<{
            __typename?: 'MemberPayablePayOutInfo';
            paidOn?: any | null;
            paymentPartner?: string | null;
          } | null> | null;
          memberPayableInvoiceWorkflow?: {
            __typename?: 'InvoiceWorkFlow';
            allSteps?: Array<{
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null> | null;
            currentStep?: {
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null;
          } | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
          expense?: { __typename?: 'Expense'; id?: string | null } | null;
        }
      | {
          __typename?: 'FreelancerPayable';
          payoutCompletedAt?: any | null;
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          status?: MemberPayableStatus | null;
          durationValue?: number | null;
          changeReason?: string | null;
          comment?: string | null;
          rejectedAt?: any | null;
          approvedAt?: any | null;
          payOutInfo?: Array<{
            __typename?: 'MemberPayablePayOutInfo';
            paidOn?: any | null;
            paymentPartner?: string | null;
          } | null> | null;
          memberPayableInvoiceWorkflow?: {
            __typename?: 'InvoiceWorkFlow';
            allSteps?: Array<{
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null> | null;
            currentStep?: {
              __typename?: 'InvoiceWorkflowStepDefinition';
              step?: InvoiceWorkflowStep | null;
              statuses?: Array<MemberPayableStatus | null> | null;
              currentStatus?: MemberPayableStatus | null;
              data?: Array<{
                __typename?: 'InvoiceStepData';
                key?: string | null;
                value?: string | null;
                type?: string | null;
              } | null> | null;
            } | null;
          } | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: 'PaySupplementMemberPayable';
          payoutCompletedAt?: any | null;
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          status?: MemberPayableStatus | null;
          rejectedAt?: any | null;
          payOutInfo?: Array<{
            __typename?: 'MemberPayablePayOutInfo';
            paidOn?: any | null;
            paymentPartner?: string | null;
          } | null> | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
          paySupplement?: {
            __typename?: 'PaySupplement';
            id?: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type MemberPayableCompanyInvoiceConfirmPaymentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MemberPayableCompanyInvoiceConfirmPaymentMutation = {
  __typename?: 'Mutation';
  memberPayableCompanyInvoiceConfirmPayment?: Array<
    | { __typename?: 'ExpenseMemberPayable' }
    | {
        __typename?: 'FreelancerPayable';
        id: string;
        type?: MemberPayableType | null;
        payableFromDate?: any | null;
        payableToDate?: any | null;
        dueDate?: any | null;
        title?: string | null;
        description?: string | null;
        submittedAt?: any | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        currency?: CurrencyCode | null;
        totalAmount?: number | null;
        totalInFunctionalCurrency?: number | null;
        status?: MemberPayableStatus | null;
        durationValue?: number | null;
        changeReason?: string | null;
        comment?: string | null;
        rejectedAt?: any | null;
        approvedAt?: any | null;
        payOutInfo?: Array<{
          __typename?: 'MemberPayablePayOutInfo';
          paidOn?: any | null;
          paymentPartner?: string | null;
        } | null> | null;
        memberPayableInvoiceWorkflow?: {
          __typename?: 'InvoiceWorkFlow';
          allSteps?: Array<{
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null> | null;
          currentStep?: {
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          position?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        } | null;
      }
    | { __typename?: 'PaySupplementMemberPayable' }
    | null
  > | null;
};

export type MemberPayableCompanyInvoiceCreatePayInMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MemberPayableCompanyInvoiceCreatePayInMutation = {
  __typename?: 'Mutation';
  memberPayableCompanyInvoiceCreatePayIn?: {
    __typename?: 'PayInInfo';
    id: string;
    metadata?: Array<{
      __typename?: 'PayInMetadata';
      key?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type MemberPayableCompanyInvoiceSubmitMutationVariables = Exact<{
  invoice: MemberPayableCompanyInvoiceSubmitInput;
}>;

export type MemberPayableCompanyInvoiceSubmitMutation = {
  __typename?: 'Mutation';
  memberPayableCompanyInvoiceSubmit?: {
    __typename?: 'MemberPayableCompanyInvoice';
    id: string;
    billingCurrency?: CurrencyCode | null;
    totalAmount?: number | null;
    referenceId?: string | null;
    bankTransferFee?: number | null;
    bankAccount: {
      __typename?: 'BankAccount';
      accountDetails?: Array<{
        __typename?: 'BankAccountDetail';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    };
    paymentMethod?: {
      __typename?: 'PaymentMethodInfo';
      bundleId: string;
      paymentMethod?: PaymentMethod | null;
      processingFee?: {
        __typename?: 'ProcessingFee';
        feeTotal?: number | null;
        feeTypes?: Array<{
          __typename?: 'FeeType';
          amount?: number | null;
          name?: string | null;
        } | null> | null;
      } | null;
    } | null;
    payoutPaymentMethodInfo?: Array<{
      __typename?: 'PaymentMethodInfo';
      bundleId: string;
      paymentMethod?: PaymentMethod | null;
      processingFee?: {
        __typename?: 'ProcessingFee';
        feeTotal?: number | null;
        feeTypes?: Array<{
          __typename?: 'FeeType';
          amount?: number | null;
          name?: string | null;
          metadata?: Array<{
            __typename?: 'FeeTypeMetadata';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null> | null;
    memberPayableInvoiceItems?: Array<{
      __typename?: 'MemberPayableCompanyInvoiceItem';
      billingAmount?: number | null;
      managementFee?: number | null;
      memberPayable?:
        | {
            __typename?: 'ExpenseMemberPayable';
            id: string;
            createdOn?: any | null;
            type?: MemberPayableType | null;
            payableFromDate?: any | null;
            payableToDate?: any | null;
            title?: string | null;
            description?: string | null;
            payOutType?: TransferType | null;
            expense?: { __typename?: 'Expense'; id?: string | null } | null;
            contract?: {
              __typename?: 'Contract';
              id?: string | null;
              country?: CountryCode | null;
              member?: {
                __typename?: 'Member';
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'FreelancerPayable';
            id: string;
            createdOn?: any | null;
            type?: MemberPayableType | null;
            payableFromDate?: any | null;
            payableToDate?: any | null;
            title?: string | null;
            description?: string | null;
            payOutType?: TransferType | null;
            contract?: {
              __typename?: 'Contract';
              id?: string | null;
              country?: CountryCode | null;
              member?: {
                __typename?: 'Member';
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'PaySupplementMemberPayable';
            id: string;
            createdOn?: any | null;
            type?: MemberPayableType | null;
            payableFromDate?: any | null;
            payableToDate?: any | null;
            title?: string | null;
            description?: string | null;
            payOutType?: TransferType | null;
            paySupplement?: {
              __typename?: 'PaySupplement';
              id?: string | null;
            } | null;
            contract?: {
              __typename?: 'Contract';
              id?: string | null;
              country?: CountryCode | null;
              member?: {
                __typename?: 'Member';
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          }
        | null;
    } | null> | null;
    document?: {
      __typename?: 'DocumentReadable';
      id?: string | null;
      link?: string | null;
    } | null;
  } | null;
};

export type RejectInvoicesMutationVariables = Exact<{
  input:
    | Array<InputMaybe<MemberPayableRejectInput>>
    | InputMaybe<MemberPayableRejectInput>;
}>;

export type RejectInvoicesMutation = {
  __typename?: 'Mutation';
  memberPayableUpdateBulkAsRejected?: Array<
    | { __typename?: 'ExpenseMemberPayable' }
    | {
        __typename?: 'FreelancerPayable';
        id: string;
        type?: MemberPayableType | null;
        payableFromDate?: any | null;
        payableToDate?: any | null;
        dueDate?: any | null;
        title?: string | null;
        description?: string | null;
        submittedAt?: any | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        currency?: CurrencyCode | null;
        totalAmount?: number | null;
        totalInFunctionalCurrency?: number | null;
        status?: MemberPayableStatus | null;
        durationValue?: number | null;
        changeReason?: string | null;
        comment?: string | null;
        rejectedAt?: any | null;
        approvedAt?: any | null;
        payOutInfo?: Array<{
          __typename?: 'MemberPayablePayOutInfo';
          paidOn?: any | null;
          paymentPartner?: string | null;
        } | null> | null;
        memberPayableInvoiceWorkflow?: {
          __typename?: 'InvoiceWorkFlow';
          allSteps?: Array<{
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null> | null;
          currentStep?: {
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          position?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        } | null;
      }
    | { __typename?: 'PaySupplementMemberPayable' }
    | null
  > | null;
};

export type DeleteMemberPayableMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteMemberPayableMutation = {
  __typename?: 'Mutation';
  memberPayableDelete?:
    | {
        __typename?: 'ExpenseMemberPayable';
        id: string;
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      }
    | {
        __typename?: 'FreelancerPayable';
        id: string;
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      }
    | {
        __typename?: 'PaySupplementMemberPayable';
        id: string;
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      }
    | null;
};

export type FreelancerPayableMemberFragment = {
  __typename?: 'FreelancerPayable';
  id: string;
  type?: MemberPayableType | null;
  title?: string | null;
  description?: string | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  status?: MemberPayableStatus | null;
  dueDate?: any | null;
  payableFromDate?: any | null;
  changeReason?: string | null;
  payableToDate?: any | null;
  payOutInfo?: Array<{
    __typename?: 'MemberPayablePayOutInfo';
    paidOn?: any | null;
    paymentPartner?: string | null;
  } | null> | null;
  memberPayableInvoiceWorkflow?: {
    __typename?: 'InvoiceWorkFlow';
    allSteps?: Array<{
      __typename?: 'InvoiceWorkflowStepDefinition';
      step?: InvoiceWorkflowStep | null;
      statuses?: Array<MemberPayableStatus | null> | null;
      currentStatus?: MemberPayableStatus | null;
      data?: Array<{
        __typename?: 'InvoiceStepData';
        key?: string | null;
        value?: string | null;
        type?: string | null;
      } | null> | null;
    } | null> | null;
    currentStep?: {
      __typename?: 'InvoiceWorkflowStepDefinition';
      step?: InvoiceWorkflowStep | null;
      statuses?: Array<MemberPayableStatus | null> | null;
      currentStatus?: MemberPayableStatus | null;
      data?: Array<{
        __typename?: 'InvoiceStepData';
        key?: string | null;
        value?: string | null;
        type?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetMemberPayableByCompanyQueryVariables = Exact<{
  memberPayableId?: InputMaybe<Scalars['ID']>;
}>;

export type GetMemberPayableByCompanyQuery = {
  __typename?: 'Query';
  member?: {
    __typename: 'Member';
    contracts?: Array<{
      __typename: 'Contract';
      memberPayables?: Array<
        | { __typename?: 'ExpenseMemberPayable' }
        | {
            __typename?: 'FreelancerPayable';
            id: string;
            status?: MemberPayableStatus | null;
            title?: string | null;
            dueDate?: any | null;
            createdOn?: any | null;
            totalAmount?: number | null;
            description?: string | null;
            payableFromDate?: any | null;
            payableToDate?: any | null;
            changeReason?: string | null;
            durationValue?: number | null;
            comment?: string | null;
            rejectedAt?: any | null;
            submittedAt?: any | null;
            currency?: CurrencyCode | null;
            memberPayableInvoiceWorkflow?: {
              __typename?: 'InvoiceWorkFlow';
              allSteps?: Array<{
                __typename?: 'InvoiceWorkflowStepDefinition';
                step?: InvoiceWorkflowStep | null;
                statuses?: Array<MemberPayableStatus | null> | null;
                currentStatus?: MemberPayableStatus | null;
                data?: Array<{
                  __typename?: 'InvoiceStepData';
                  key?: string | null;
                  value?: string | null;
                  type?: string | null;
                } | null> | null;
              } | null> | null;
              currentStep?: {
                __typename?: 'InvoiceWorkflowStepDefinition';
                step?: InvoiceWorkflowStep | null;
                statuses?: Array<MemberPayableStatus | null> | null;
                currentStatus?: MemberPayableStatus | null;
                data?: Array<{
                  __typename?: 'InvoiceStepData';
                  key?: string | null;
                  value?: string | null;
                  type?: string | null;
                } | null> | null;
              } | null;
            } | null;
            changes?: Array<{
              __typename?: 'MemberPayableChange';
              status?: MemberPayableStatus | null;
              actionedOn?: any | null;
              reason?: string | null;
            } | null> | null;
            contract?: {
              __typename?: 'Contract';
              id?: string | null;
              country?: CountryCode | null;
            } | null;
          }
        | { __typename?: 'PaySupplementMemberPayable' }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type GetMemberPayableDownloadAsPdfQueryVariables = Exact<{
  memberPayableId?: InputMaybe<Scalars['ID']>;
}>;

export type GetMemberPayableDownloadAsPdfQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    contracts?: Array<{
      __typename?: 'Contract';
      memberPayables?: Array<
        | {
            __typename?: 'ExpenseMemberPayable';
            file?: {
              __typename?: 'FileLink';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              link?: string | null;
            } | null;
          }
        | {
            __typename?: 'FreelancerPayable';
            file?: {
              __typename?: 'FileLink';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              link?: string | null;
            } | null;
          }
        | {
            __typename?: 'PaySupplementMemberPayable';
            file?: {
              __typename?: 'FileLink';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              link?: string | null;
            } | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type GetRemittanceAdviceDownloadAsPdfQueryVariables = Exact<{
  memberPayableId?: InputMaybe<Scalars['ID']>;
}>;

export type GetRemittanceAdviceDownloadAsPdfQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    contracts?: Array<{
      __typename?: 'Contract';
      memberPayables?: Array<
        | {
            __typename?: 'ExpenseMemberPayable';
            file?: {
              __typename?: 'FileLink';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              link?: string | null;
            } | null;
          }
        | {
            __typename?: 'FreelancerPayable';
            file?: {
              __typename?: 'FileLink';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              link?: string | null;
            } | null;
          }
        | {
            __typename?: 'PaySupplementMemberPayable';
            file?: {
              __typename?: 'FileLink';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              link?: string | null;
            } | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type GetMemberPayablesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberPayablesQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      currency?: CurrencyCode | null;
      memberPayables?: Array<
        | { __typename?: 'ExpenseMemberPayable' }
        | {
            __typename?: 'FreelancerPayable';
            id: string;
            type?: MemberPayableType | null;
            title?: string | null;
            description?: string | null;
            currency?: CurrencyCode | null;
            totalAmount?: number | null;
            createdOn?: any | null;
            updatedOn?: any | null;
            status?: MemberPayableStatus | null;
            dueDate?: any | null;
            payableFromDate?: any | null;
            changeReason?: string | null;
            payableToDate?: any | null;
            payOutInfo?: Array<{
              __typename?: 'MemberPayablePayOutInfo';
              paidOn?: any | null;
              paymentPartner?: string | null;
            } | null> | null;
            memberPayableInvoiceWorkflow?: {
              __typename?: 'InvoiceWorkFlow';
              allSteps?: Array<{
                __typename?: 'InvoiceWorkflowStepDefinition';
                step?: InvoiceWorkflowStep | null;
                statuses?: Array<MemberPayableStatus | null> | null;
                currentStatus?: MemberPayableStatus | null;
                data?: Array<{
                  __typename?: 'InvoiceStepData';
                  key?: string | null;
                  value?: string | null;
                  type?: string | null;
                } | null> | null;
              } | null> | null;
              currentStep?: {
                __typename?: 'InvoiceWorkflowStepDefinition';
                step?: InvoiceWorkflowStep | null;
                statuses?: Array<MemberPayableStatus | null> | null;
                currentStatus?: MemberPayableStatus | null;
                data?: Array<{
                  __typename?: 'InvoiceStepData';
                  key?: string | null;
                  value?: string | null;
                  type?: string | null;
                } | null> | null;
              } | null;
            } | null;
          }
        | { __typename?: 'PaySupplementMemberPayable' }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type GetMemberPaymentProofDownloadAsPdfQueryVariables = Exact<{
  memberPayableId?: InputMaybe<Scalars['ID']>;
}>;

export type GetMemberPaymentProofDownloadAsPdfQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    contracts?: Array<{
      __typename?: 'Contract';
      memberPayables?: Array<
        | {
            __typename?: 'ExpenseMemberPayable';
            paymentReceipt?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
              blob?: string | null;
            } | null;
          }
        | {
            __typename?: 'FreelancerPayable';
            paymentReceipt?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
              blob?: string | null;
            } | null;
          }
        | {
            __typename?: 'PaySupplementMemberPayable';
            paymentReceipt?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
              blob?: string | null;
            } | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type GetMemberPayableContractQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberPayableContractQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      startOn?: any | null;
      endOn?: any | null;
      currency?: CurrencyCode | null;
      compensation?: {
        __typename?: 'Compensation';
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
      } | null;
      company?: {
        __typename?: 'Company';
        id?: string | null;
        displayName?: string | null;
        primaryEntity?: {
          __typename?: 'LegalEntity';
          id?: string | null;
          registrationNo?: string | null;
          address?: {
            __typename?: 'Address';
            key?: string | null;
            country?: CountryCode | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type MemberSubmitMemberPayableMutationVariables = Exact<{
  input: MemberPayableSubmitInput;
}>;

export type MemberSubmitMemberPayableMutation = {
  __typename?: 'Mutation';
  memberPayableSubmit?:
    | {
        __typename?: 'ExpenseMemberPayable';
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      }
    | {
        __typename?: 'FreelancerPayable';
        id: string;
        type?: MemberPayableType | null;
        title?: string | null;
        description?: string | null;
        currency?: CurrencyCode | null;
        totalAmount?: number | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        status?: MemberPayableStatus | null;
        dueDate?: any | null;
        payableFromDate?: any | null;
        changeReason?: string | null;
        payableToDate?: any | null;
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
        payOutInfo?: Array<{
          __typename?: 'MemberPayablePayOutInfo';
          paidOn?: any | null;
          paymentPartner?: string | null;
        } | null> | null;
        memberPayableInvoiceWorkflow?: {
          __typename?: 'InvoiceWorkFlow';
          allSteps?: Array<{
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null> | null;
          currentStep?: {
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
      }
    | {
        __typename?: 'PaySupplementMemberPayable';
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      }
    | null;
};

export type MemberUpdateMemberPayableMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberPayableUpdateInput;
}>;

export type MemberUpdateMemberPayableMutation = {
  __typename?: 'Mutation';
  memberPayableUpdate?:
    | {
        __typename?: 'ExpenseMemberPayable';
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      }
    | {
        __typename?: 'FreelancerPayable';
        id: string;
        type?: MemberPayableType | null;
        title?: string | null;
        description?: string | null;
        currency?: CurrencyCode | null;
        totalAmount?: number | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        status?: MemberPayableStatus | null;
        dueDate?: any | null;
        payableFromDate?: any | null;
        changeReason?: string | null;
        payableToDate?: any | null;
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
        payOutInfo?: Array<{
          __typename?: 'MemberPayablePayOutInfo';
          paidOn?: any | null;
          paymentPartner?: string | null;
        } | null> | null;
        memberPayableInvoiceWorkflow?: {
          __typename?: 'InvoiceWorkFlow';
          allSteps?: Array<{
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null> | null;
          currentStep?: {
            __typename?: 'InvoiceWorkflowStepDefinition';
            step?: InvoiceWorkflowStep | null;
            statuses?: Array<MemberPayableStatus | null> | null;
            currentStatus?: MemberPayableStatus | null;
            data?: Array<{
              __typename?: 'InvoiceStepData';
              key?: string | null;
              value?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
      }
    | {
        __typename?: 'PaySupplementMemberPayable';
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      }
    | null;
};

export type InvoiceWorkflowStepDefinitionFragment = {
  __typename?: 'InvoiceWorkflowStepDefinition';
  step?: InvoiceWorkflowStep | null;
  statuses?: Array<MemberPayableStatus | null> | null;
  currentStatus?: MemberPayableStatus | null;
  data?: Array<{
    __typename?: 'InvoiceStepData';
    key?: string | null;
    value?: string | null;
    type?: string | null;
  } | null> | null;
};

export type InvoiceWorkFlowFragment = {
  __typename?: 'InvoiceWorkFlow';
  allSteps?: Array<{
    __typename?: 'InvoiceWorkflowStepDefinition';
    step?: InvoiceWorkflowStep | null;
    statuses?: Array<MemberPayableStatus | null> | null;
    currentStatus?: MemberPayableStatus | null;
    data?: Array<{
      __typename?: 'InvoiceStepData';
      key?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
  } | null> | null;
  currentStep?: {
    __typename?: 'InvoiceWorkflowStepDefinition';
    step?: InvoiceWorkflowStep | null;
    statuses?: Array<MemberPayableStatus | null> | null;
    currentStatus?: MemberPayableStatus | null;
    data?: Array<{
      __typename?: 'InvoiceStepData';
      key?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
  } | null;
};

export type AddApproverMutationVariables = Exact<{
  input: AddApproverInfoInput;
}>;

export type AddApproverMutation = {
  __typename?: 'Mutation';
  companyUserAddApproverInfo?: {
    __typename?: 'CompanyUser';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    role?: CompanyUserRole | null;
    isSignatory?: boolean | null;
    isBillingContact?: boolean | null;
    userId?: string | null;
    status?: CompanyUserStatus | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      email?: string | null;
    } | null> | null;
    approver?: {
      __typename?: 'Approver';
      id?: string | null;
      categoryContracts?: Array<{
        __typename?: 'ApproverCategoryContract';
        category?: ApprovalCategory | null;
        managedContracts?: Array<{
          __typename?: 'Contract';
          id?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type AddCompanyUserMutationVariables = Exact<{
  user?: InputMaybe<AddCompanyUserInput>;
}>;

export type AddCompanyUserMutation = {
  __typename?: 'Mutation';
  companyUserAdd?: {
    __typename?: 'CompanyUser';
    id?: string | null;
    userId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    role?: CompanyUserRole | null;
    isSignatory?: boolean | null;
    isBillingContact?: boolean | null;
    status?: CompanyUserStatus | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      email?: string | null;
    } | null> | null;
    company?: { __typename?: 'Company'; id?: string | null } | null;
  } | null;
};

export type DepartmentAssignEmployeesMutationVariables = Exact<{
  input: DepartmentAssignEmployeesInput;
}>;

export type DepartmentAssignEmployeesMutation = {
  __typename?: 'Mutation';
  departmentAssignEmployees?: {
    __typename?: 'Department';
    id: string;
    name: string;
    description?: string | null;
    hod?: {
      __typename?: 'HeadOfDepartment';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null;
    employees?: {
      __typename?: 'OrgDirectory';
      contracts?: Array<{
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        reportsToManager?: { __typename?: 'Manager'; id: string } | null;
      }> | null;
      managers?: Array<{
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        reportsToManager?: { __typename?: 'Manager'; id: string } | null;
      }> | null;
    } | null;
  } | null;
};

export type CompanyUserAssignRoleMutationVariables = Exact<{
  input?: InputMaybe<CompanyUserAssignRoleInput>;
}>;

export type CompanyUserAssignRoleMutation = {
  __typename?: 'Mutation';
  companyUserAssignRole?: Array<{
    __typename?: 'CompanyUser';
    id?: string | null;
  } | null> | null;
};

export type TimeOffPolicyAssignUsersMutationVariables = Exact<{
  input: TimeOffPolicyAssignUsersInput;
}>;

export type TimeOffPolicyAssignUsersMutation = {
  __typename?: 'Mutation';
  timeOffPolicyAssignUsers?: {
    __typename?: 'TimeOffTypeDefinition';
    id?: string | null;
    policyName?: string | null;
    type?: string | null;
    typeId?: string | null;
    label?: string | null;
    assignedEntityNames?: Array<string> | null;
    assignedEmployeeCount?: number | null;
    status?: TimeOffTypeDefinitionStatus | null;
    validation?: Array<{
      __typename?: 'TimeOffFixedValidation';
      defaultValue?: number | null;
      unit?: TimeOffUnit | null;
      isUnlimitedLeavesAllowed?: boolean | null;
    } | null> | null;
    configuration?: {
      __typename?: 'TimeoffConfiguration';
      carryForward?: {
        __typename?: 'CarryForwardConfig';
        enabled?: boolean | null;
        maxLimit?: {
          __typename?: 'CarryForwardLimit';
          unit?: TimeOffUnit | null;
          value?: number | null;
        } | null;
        expiry?: {
          __typename?: 'TimeOffDuration';
          unit?: TimeOffUnit | null;
          value?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyUserAssignRoleAndAttributesMutationVariables = Exact<{
  input?: InputMaybe<CompanyUserAssignRoleAndAttributesInput>;
}>;

export type CompanyUserAssignRoleAndAttributesMutation = {
  __typename?: 'Mutation';
  companyUserAssignRoleAndAttributes?: {
    __typename?: 'CompanyUser';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type CompanyUserEditRoleAndAttributesMutationVariables = Exact<{
  input?: InputMaybe<CompanyUserEditRoleAndAttributesInput>;
}>;

export type CompanyUserEditRoleAndAttributesMutation = {
  __typename?: 'Mutation';
  companyUserEditRoleAndAttributes?: {
    __typename?: 'CompanyUser';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type CompanyUserRevokeRoleMutationVariables = Exact<{
  companyUserId: Scalars['ID'];
  role?: InputMaybe<CompanyUserRole>;
}>;

export type CompanyUserRevokeRoleMutation = {
  __typename?: 'Mutation';
  companyUserRevokeRole?: {
    __typename?: 'CompanyUser';
    id?: string | null;
  } | null;
};

export type DepartmentCreateMutationVariables = Exact<{
  input: DepartmentCreateInput;
}>;

export type DepartmentCreateMutation = {
  __typename?: 'Mutation';
  departmentCreate?: {
    __typename?: 'Department';
    id: string;
    name: string;
    description?: string | null;
    hod?: {
      __typename?: 'HeadOfDepartment';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null;
    employees?: {
      __typename?: 'OrgDirectory';
      contracts?: Array<{ __typename?: 'Contract'; id?: string | null }> | null;
      managers?: Array<{ __typename?: 'Manager'; id: string }> | null;
    } | null;
  } | null;
};

export type TimeOffPolicyCreateMutationVariables = Exact<{
  input: TimeOffPolicyCreateInput;
}>;

export type TimeOffPolicyCreateMutation = {
  __typename?: 'Mutation';
  timeOffPolicyCreate?: {
    __typename?: 'TimeOffTypeDefinition';
    id?: string | null;
    policyName?: string | null;
    type?: string | null;
    typeId?: string | null;
    assignedEntityNames?: Array<string> | null;
    assignedEmployeeCount?: number | null;
    status?: TimeOffTypeDefinitionStatus | null;
    validation?: Array<{
      __typename?: 'TimeOffFixedValidation';
      defaultValue?: number | null;
      unit?: TimeOffUnit | null;
      isUnlimitedLeavesAllowed?: boolean | null;
    } | null> | null;
    configuration?: {
      __typename?: 'TimeoffConfiguration';
      carryForward?: {
        __typename?: 'CarryForwardConfig';
        enabled?: boolean | null;
        maxLimit?: {
          __typename?: 'CarryForwardLimit';
          unit?: TimeOffUnit | null;
          value?: number | null;
        } | null;
        expiry?: {
          __typename?: 'TimeOffDuration';
          unit?: TimeOffUnit | null;
          value?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type TimeOffTypeCreateMutationVariables = Exact<{
  input: TimeOffTypeCreateInput;
}>;

export type TimeOffTypeCreateMutation = {
  __typename?: 'Mutation';
  timeOffTypeCreate?: {
    __typename?: 'TimeOffTypeInfo';
    description?: string | null;
    isPaidLeave?: boolean | null;
    label?: string | null;
    status?: TimeOffTypeStatus | null;
    type?: string | null;
    typeId?: string | null;
    usage?: number | null;
  } | null;
};

export type DeleteCompanyPayInMethodMutationVariables = Exact<{
  companyPayInMethodId: Scalars['ID'];
}>;

export type DeleteCompanyPayInMethodMutation = {
  __typename?: 'Mutation';
  deleteCompanyPayInMethod?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type DepartmentDeleteMutationVariables = Exact<{
  input: DepartmentDeleteInput;
}>;

export type DepartmentDeleteMutation = {
  __typename?: 'Mutation';
  departmentDelete?: {
    __typename?: 'Department';
    id: string;
    name: string;
    description?: string | null;
    status?: DepartmentStatus | null;
    hod?: {
      __typename?: 'HeadOfDepartment';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null;
    employees?: {
      __typename?: 'OrgDirectory';
      contracts?: Array<{ __typename?: 'Contract'; id?: string | null }> | null;
      managers?: Array<{ __typename?: 'Manager'; id: string }> | null;
    } | null;
  } | null;
};

export type TimeOffPolicyDeleteMutationVariables = Exact<{
  policyId: Scalars['ID'];
}>;

export type TimeOffPolicyDeleteMutation = {
  __typename?: 'Mutation';
  timeOffPolicyDelete?: {
    __typename?: 'TimeOffTypeDefinition';
    id?: string | null;
    policyName?: string | null;
    type?: string | null;
    typeId?: string | null;
    assignedEntityNames?: Array<string> | null;
    assignedEmployeeCount?: number | null;
    status?: TimeOffTypeDefinitionStatus | null;
    validation?: Array<{
      __typename?: 'TimeOffFixedValidation';
      defaultValue?: number | null;
      unit?: TimeOffUnit | null;
      isUnlimitedLeavesAllowed?: boolean | null;
    } | null> | null;
    configuration?: {
      __typename?: 'TimeoffConfiguration';
      carryForward?: {
        __typename?: 'CarryForwardConfig';
        enabled?: boolean | null;
        maxLimit?: {
          __typename?: 'CarryForwardLimit';
          unit?: TimeOffUnit | null;
          value?: number | null;
        } | null;
        expiry?: {
          __typename?: 'TimeOffDuration';
          unit?: TimeOffUnit | null;
          value?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type TimeOffTypeDeleteMutationVariables = Exact<{
  timeOffTypeDeleteId: Scalars['ID'];
}>;

export type TimeOffTypeDeleteMutation = {
  __typename?: 'Mutation';
  timeOffTypeDelete?: {
    __typename?: 'TimeOffTypeInfo';
    description?: string | null;
    label?: string | null;
    status?: TimeOffTypeStatus | null;
    type?: string | null;
    typeId?: string | null;
    usage?: number | null;
  } | null;
};

export type GetApproverQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetApproverQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    approvers?: Array<{
      __typename?: 'Approver';
      id?: string | null;
      categoryContracts?: Array<{
        __typename?: 'ApproverCategoryContract';
        category?: ApprovalCategory | null;
        managedContracts?: Array<{
          __typename?: 'Contract';
          id?: string | null;
          position?: string | null;
          country?: CountryCode | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null> | null;
      approverUser?:
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            title?: string | null;
            role?: CompanyUserRole | null;
            roles?: Array<CompanyUserRole | null> | null;
            isSignatory?: boolean | null;
            isBillingContact?: boolean | null;
            status?: CompanyUserStatus | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'EmergencyPointOfContact';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'OperationsUser';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | null;
    } | null> | null;
  } | null;
};

export type GetCompanyActiveContractsForApprovalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyActiveContractsForApprovalQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      currency?: CurrencyCode | null;
      type?: ContractType | null;
      term?: ContractTerm | null;
      position?: string | null;
      status?: ContractStatus | null;
      startOn?: any | null;
      categoryApproverInfos?: Array<{
        __typename?: 'CategoryApproverInfo';
        category?: ApprovalCategory | null;
        approverUsers?: Array<
          | {
              __typename?: 'CompanyUser';
              id?: string | null;
              userId?: string | null;
              lastName?: string | null;
              firstName?: string | null;
              emails?: Array<{
                __typename?: 'EmailAddress';
                email?: string | null;
              } | null> | null;
            }
          | { __typename?: 'EmergencyPointOfContact' }
          | { __typename?: 'Member' }
          | { __typename?: 'OperationsUser' }
          | null
        > | null;
      } | null> | null;
      orgAttributes?: {
        __typename?: 'OrgAttributes';
        department?: { __typename?: 'Department'; name: string } | null;
      } | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userId?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyAutoDebitPreferenceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyAutoDebitPreferenceQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payInSettings?: {
      __typename?: 'CompanyPayInSettings';
      payInPreferences?: Array<{
        __typename?: 'CompanyPayInPreferenceAutoDebit';
        isAutoDebitEnabled: boolean;
        id: string;
        isPreferenceActive: boolean;
        preferenceType: CompanyPayInPreferenceType;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCompanyCountryQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyCountryQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
  } | null;
};

export type LegalEntityFragment = {
  __typename?: 'LegalEntity';
  id?: string | null;
  legalName?: string | null;
  status?: LegalEntityStatus | null;
  capabilities?: Array<Capability | null> | null;
  address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
};

export type GetCompanyEntitiesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyEntitiesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      status?: LegalEntityStatus | null;
      capabilities?: Array<Capability | null> | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
    otherEntities?: Array<{
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      status?: LegalEntityStatus | null;
      capabilities?: Array<Capability | null> | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyMsaQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyMsaQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    msa?: {
      __typename?: 'DocumentReadable';
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
      link?: string | null;
      blob?: string | null;
    } | null;
  } | null;
};

export type GetCompanyOfferingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyOfferingsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    offerings?: Array<{
      __typename?: 'CompanyOffering';
      offering?: OfferingCode | null;
      status?: OfferingStatus | null;
    } | null> | null;
  } | null;
};

export type GetCompanyPayInPreferencesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyPayInPreferencesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payInSettings?: {
      __typename?: 'CompanyPayInSettings';
      payInPreferences?: Array<{
        __typename?: 'CompanyPayInPreferenceAutoDebit';
        id: string;
        isPreferenceActive: boolean;
        preferenceType: CompanyPayInPreferenceType;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCompanyPayInSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyPayInSettingsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payInSettings?: {
      __typename?: 'CompanyPayInSettings';
      payInMethodRequirements?: Array<{
        __typename?: 'PayInMethodRequirement';
        id: string;
        partner?: string | null;
        payInMethodType?: PayInMethodType | null;
        definition?: Array<{
          __typename?: 'PayInMethodDefinition';
          step?: PayInMethodDefinitionStep | null;
          requirement?: Array<{
            __typename?: 'PayInMethodAccountRequirement';
            payInAccountRequirementType?: string | null;
            fields?: Array<{
              __typename?: 'PaymentRequirementField';
              key?: string | null;
              label?: string | null;
              isMandatory?: boolean | null;
              type?: string | null;
              regex?: string | null;
              hasSubFields?: boolean | null;
              errorMessage?: string | null;
              allowedValues?: Array<{
                __typename?: 'PaymentRequirementAllowedValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
      payInMethods: Array<
        | {
            __typename?: 'ACHCompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
            isActive?: boolean | null;
            isEnabled?: boolean | null;
            currentStep?: PayInMethodDefinitionStep | null;
            currentStatus?: CompanyPayInMethodWorkflowStatus | null;
            workflowSteps: Array<{
              __typename?: 'CompanyPayInMethodWorkflowStep';
              step?: PayInMethodDefinitionStep | null;
              status?: CompanyPayInMethodWorkflowStatus | null;
              updatedOn?: any | null;
            } | null>;
            bankDetails?: Array<{
              __typename?: 'CompanyPayInMethodBankDetails';
              payInAccountRequirementType?: string | null;
              data?: Array<{
                __typename?: 'KeyValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null> | null;
            data?: Array<{
              __typename?: 'KeyValue';
              key?: string | null;
              value?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'SEPACompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
            isActive?: boolean | null;
            isEnabled?: boolean | null;
            currentStep?: PayInMethodDefinitionStep | null;
            currentStatus?: CompanyPayInMethodWorkflowStatus | null;
            workflowSteps: Array<{
              __typename?: 'CompanyPayInMethodWorkflowStep';
              step?: PayInMethodDefinitionStep | null;
              status?: CompanyPayInMethodWorkflowStatus | null;
              updatedOn?: any | null;
            } | null>;
            bankDetails?: Array<{
              __typename?: 'CompanyPayInMethodBankDetails';
              payInAccountRequirementType?: string | null;
              data?: Array<{
                __typename?: 'KeyValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null> | null;
            data?: Array<{
              __typename?: 'KeyValue';
              key?: string | null;
              value?: string | null;
            } | null> | null;
          }
        | null
      >;
    } | null;
  } | null;
};

export type GetCompanyPricingInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyPricingInfoQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    pricing?: {
      __typename?: 'Pricing';
      id?: string | null;
      billingCurrencyCode?: CurrencyCode | null;
    } | null;
  } | null;
};

export type GetCompanyTimeOffPoliciesQueryVariables = Exact<{
  filter?: InputMaybe<CompanyTimeOffPolicyFilter>;
}>;

export type GetCompanyTimeOffPoliciesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timeOffPolicies?: Array<{
      __typename?: 'TimeOffTypeDefinition';
      id?: string | null;
      policyName?: string | null;
      type?: string | null;
      typeId?: string | null;
      assignedEntityNames?: Array<string> | null;
      assignedEmployeeCount?: number | null;
      status?: TimeOffTypeDefinitionStatus | null;
      label?: string | null;
      validation?: Array<{
        __typename?: 'TimeOffFixedValidation';
        defaultValue?: number | null;
        unit?: TimeOffUnit | null;
        isUnlimitedLeavesAllowed?: boolean | null;
      } | null> | null;
      configuration?: {
        __typename?: 'TimeoffConfiguration';
        carryForward?: {
          __typename?: 'CarryForwardConfig';
          enabled?: boolean | null;
          maxLimit?: {
            __typename?: 'CarryForwardLimit';
            unit?: TimeOffUnit | null;
            value?: number | null;
          } | null;
          expiry?: {
            __typename?: 'TimeOffDuration';
            unit?: TimeOffUnit | null;
            value?: number | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyTimeOffTypesQueryVariables = Exact<{
  filter?: InputMaybe<TimeOffTypeFilter>;
}>;

export type GetCompanyTimeOffTypesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timeOffTypeInfos?: Array<{
      __typename?: 'TimeOffTypeInfo';
      description?: string | null;
      label?: string | null;
      status?: TimeOffTypeStatus | null;
      type?: string | null;
      typeId?: string | null;
      isPaidLeave?: boolean | null;
      usage?: number | null;
      companyId?: string | null;
      createdOn?: any | null;
    } | null> | null;
  } | null;
};

export type GetCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
    status?: CompanyStatus | null;
    financialYear?: number | null;
    companyLogo?: {
      __typename?: 'DocumentReadable';
      link?: string | null;
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      registrationNo?: string | null;
      phone?: string | null;
      address?: {
        __typename?: 'Address';
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        zipcode?: string | null;
        country?: CountryCode | null;
      } | null;
    } | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
    } | null> | null;
    managers?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      roles?: Array<CompanyUserRole | null> | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      status?: CompanyUserStatus | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
    signatories?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      roles?: Array<CompanyUserRole | null> | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      status?: CompanyUserStatus | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
    billingContact?: {
      __typename?: 'CompanyUser';
      id?: string | null;
      userId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      role?: CompanyUserRole | null;
      roles?: Array<CompanyUserRole | null> | null;
      isSignatory?: boolean | null;
      isBillingContact?: boolean | null;
      status?: CompanyUserStatus | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null;
    approvers?: Array<{
      __typename?: 'Approver';
      id?: string | null;
      categoryContracts?: Array<{
        __typename?: 'ApproverCategoryContract';
        category?: ApprovalCategory | null;
        managedContracts?: Array<{
          __typename?: 'Contract';
          id?: string | null;
        } | null> | null;
      } | null> | null;
      approverUser?:
        | {
            __typename?: 'CompanyUser';
            id?: string | null;
            userId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            title?: string | null;
            role?: CompanyUserRole | null;
            roles?: Array<CompanyUserRole | null> | null;
            isSignatory?: boolean | null;
            isBillingContact?: boolean | null;
            status?: CompanyUserStatus | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'EmergencyPointOfContact';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'OperationsUser';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
          }
        | null;
    } | null> | null;
    offerings?: Array<{
      __typename?: 'CompanyOffering';
      offering?: OfferingCode | null;
      status?: OfferingStatus | null;
    } | null> | null;
  } | null;
};

export type GetDepartmentDetailsQueryVariables = Exact<{
  filters: DepartmentFilters;
}>;

export type GetDepartmentDetailsQuery = {
  __typename?: 'Query';
  departments: Array<{
    __typename?: 'Department';
    id: string;
    name: string;
    description?: string | null;
    hod?: {
      __typename?: 'HeadOfDepartment';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          firstName?: string | null;
          lastName?: string | null;
          userId?: string | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
          userId?: string | null;
        } | null;
      } | null;
    } | null;
    employees?: {
      __typename?: 'OrgDirectory';
      contracts?: Array<{
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        type?: ContractType | null;
        position?: string | null;
        workEmail?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          userId?: string | null;
          gender?: Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        reportsToManager?: {
          __typename?: 'Manager';
          id: string;
          companyUser?: {
            __typename?: 'CompanyUser';
            userId?: string | null;
          } | null;
          contract?: {
            __typename?: 'Contract';
            member?: { __typename?: 'Member'; userId?: string | null } | null;
          } | null;
        } | null;
      }> | null;
      managers?: Array<{
        __typename?: 'Manager';
        id: string;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          type?: ContractType | null;
          position?: string | null;
          workEmail?: string | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            userId?: string | null;
            gender?: Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'EmailAddress';
              email?: string | null;
            } | null> | null;
            phoneNos?: Array<{
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
            } | null> | null;
          } | null;
        } | null;
        companyUser?: {
          __typename?: 'CompanyUser';
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          title?: string | null;
          emails?: Array<{
            __typename?: 'EmailAddress';
            email?: string | null;
          } | null> | null;
          phoneNos?: Array<{
            __typename?: 'PhoneNumber';
            phoneNo?: string | null;
          } | null> | null;
        } | null;
        reportsToManager?: {
          __typename?: 'Manager';
          id: string;
          companyUser?: {
            __typename?: 'CompanyUser';
            userId?: string | null;
          } | null;
          contract?: {
            __typename?: 'Contract';
            member?: { __typename?: 'Member'; userId?: string | null } | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  }>;
};

export type GetDepartmentsQueryVariables = Exact<{
  filters?: InputMaybe<DepartmentFilters>;
}>;

export type GetDepartmentsQuery = {
  __typename?: 'Query';
  departments: Array<{
    __typename?: 'Department';
    id: string;
    name: string;
    description?: string | null;
    hod?: {
      __typename?: 'HeadOfDepartment';
      manager?: {
        __typename?: 'Manager';
        id: string;
        companyUser?: {
          __typename?: 'CompanyUser';
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        member?: {
          __typename?: 'Member';
          userId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null;
    employees?: {
      __typename?: 'OrgDirectory';
      contracts?: Array<{ __typename?: 'Contract'; id?: string | null }> | null;
      managers?: Array<{ __typename?: 'Manager'; id: string }> | null;
    } | null;
  }>;
};

export type GetSepaIbanDetailsQueryVariables = Exact<{
  iban?: InputMaybe<Scalars['String']>;
}>;

export type GetSepaIbanDetailsQuery = {
  __typename?: 'Query';
  getSEPAIBANDetailsFieldInformation?: {
    __typename?: 'SEPAIBANDetailsFieldInformationResponse';
    data?: Array<{
      __typename?: 'KeyValue';
      key?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type GetTimeOffPolicyAssignmentDetailsQueryVariables = Exact<{
  policyId: Scalars['ID'];
}>;

export type GetTimeOffPolicyAssignmentDetailsQuery = {
  __typename?: 'Query';
  timeOffPolicyAssignmentDetails?: {
    __typename?: 'TimeOffPolicyAssignmentDetailsResult';
    assignedContracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      position?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }> | null;
    rule?: {
      __typename?: 'Rule';
      type?: RuleType | null;
      conditions?: Array<{
        __typename?: 'Condition';
        key: ConditionKey;
        operator: ConditionOperator;
        values?: Array<string> | null;
      }> | null;
    } | null;
  } | null;
};

export type RemoveCompanySignatoryMutationVariables = Exact<{
  companyUserId: Scalars['ID'];
}>;

export type RemoveCompanySignatoryMutation = {
  __typename?: 'Mutation';
  companyRemoveSignatory?: {
    __typename?: 'CompanyUser';
    id?: string | null;
  } | null;
};

export type RemoveCompanyUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type RemoveCompanyUserMutation = {
  __typename?: 'Mutation';
  companyRemoveUser?: { __typename?: 'CompanyUser'; id?: string | null } | null;
};

export type ResendApproverInviteMutationVariables = Exact<{
  companyUserId: Scalars['ID'];
}>;

export type ResendApproverInviteMutation = {
  __typename?: 'Mutation';
  companyUserResendApproverInvite?: {
    __typename?: 'CompanyUser';
    id?: string | null;
  } | null;
};

export type RevokeApproverRoleMutationVariables = Exact<{
  companyUserId: Scalars['ID'];
}>;

export type RevokeApproverRoleMutation = {
  __typename?: 'Mutation';
  companyUserRevokeApproverRole?: {
    __typename?: 'CompanyUser';
    id?: string | null;
  } | null;
};

export type SetupCompanyPayInMethodMutationVariables = Exact<{
  input: CompanyPayInMethodInput;
}>;

export type SetupCompanyPayInMethodMutation = {
  __typename?: 'Mutation';
  upsertCompanyPayInMethodBankDetails?:
    | {
        __typename?: 'ACHCompanyPayinMethod';
        id: string;
        payInMethodType?: PayInMethodType | null;
        isActive?: boolean | null;
        isEnabled?: boolean | null;
        currentStep?: PayInMethodDefinitionStep | null;
        currentStatus?: CompanyPayInMethodWorkflowStatus | null;
        workflowSteps: Array<{
          __typename?: 'CompanyPayInMethodWorkflowStep';
          step?: PayInMethodDefinitionStep | null;
          status?: CompanyPayInMethodWorkflowStatus | null;
          updatedOn?: any | null;
        } | null>;
        bankDetails?: Array<{
          __typename?: 'CompanyPayInMethodBankDetails';
          payInAccountRequirementType?: string | null;
          data?: Array<{
            __typename?: 'KeyValue';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
        data?: Array<{
          __typename?: 'KeyValue';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'SEPACompanyPayinMethod';
        id: string;
        payInMethodType?: PayInMethodType | null;
        isActive?: boolean | null;
        isEnabled?: boolean | null;
        currentStep?: PayInMethodDefinitionStep | null;
        currentStatus?: CompanyPayInMethodWorkflowStatus | null;
        workflowSteps: Array<{
          __typename?: 'CompanyPayInMethodWorkflowStep';
          step?: PayInMethodDefinitionStep | null;
          status?: CompanyPayInMethodWorkflowStatus | null;
          updatedOn?: any | null;
        } | null>;
        bankDetails?: Array<{
          __typename?: 'CompanyPayInMethodBankDetails';
          payInAccountRequirementType?: string | null;
          data?: Array<{
            __typename?: 'KeyValue';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
        data?: Array<{
          __typename?: 'KeyValue';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | null;
};

export type ToggleAutoDebitMutationVariables = Exact<{
  companyId: Scalars['ID'];
  status: Scalars['Boolean'];
}>;

export type ToggleAutoDebitMutation = {
  __typename?: 'Mutation';
  toggleAutoDebit?: {
    __typename?: 'CompanyPayInPreferenceAutoDebit';
    id: string;
    isAutoDebitEnabled: boolean;
  } | null;
};

export type UpdateApproverMutationVariables = Exact<{
  input: UpdateApproverInfoInput;
}>;

export type UpdateApproverMutation = {
  __typename?: 'Mutation';
  companyUserUpdateApproverInfo?: {
    __typename?: 'CompanyUser';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    role?: CompanyUserRole | null;
    isSignatory?: boolean | null;
    isBillingContact?: boolean | null;
    userId?: string | null;
    status?: CompanyUserStatus | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      email?: string | null;
    } | null> | null;
    approver?: {
      __typename?: 'Approver';
      id?: string | null;
      categoryContracts?: Array<{
        __typename?: 'ApproverCategoryContract';
        category?: ApprovalCategory | null;
        managedContracts?: Array<{
          __typename?: 'Contract';
          id?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateCompanyUserMutationVariables = Exact<{
  user?: InputMaybe<UpdateUserInput>;
}>;

export type UpdateCompanyUserMutation = {
  __typename?: 'Mutation';
  companyUpdateUser?: {
    __typename?: 'CompanyUser';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    role?: CompanyUserRole | null;
    isSignatory?: boolean | null;
    isBillingContact?: boolean | null;
    userId?: string | null;
    status?: CompanyUserStatus | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      email?: string | null;
    } | null> | null;
  } | null;
};

export type CompanyUpdateMutationVariables = Exact<{
  company: CompanyUpdateInput;
  companyLogo?: InputMaybe<Scalars['Upload']>;
}>;

export type CompanyUpdateMutation = {
  __typename?: 'Mutation';
  companyUpdate?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
    status?: CompanyStatus | null;
    financialYear?: number | null;
    companyLogo?: {
      __typename?: 'DocumentReadable';
      link?: string | null;
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      registrationNo?: string | null;
      phone?: string | null;
      address?: {
        __typename?: 'Address';
        line1?: string | null;
        line2?: string | null;
        zipcode?: string | null;
        country?: CountryCode | null;
      } | null;
    } | null;
  } | null;
};

export type DepartmentUpdateMutationVariables = Exact<{
  input: DepartmentUpdateInput;
}>;

export type DepartmentUpdateMutation = {
  __typename?: 'Mutation';
  departmentUpdate?: {
    __typename?: 'Department';
    id: string;
    name: string;
    description?: string | null;
    hod?: {
      __typename?: 'HeadOfDepartment';
      manager?: { __typename?: 'Manager'; id: string } | null;
      contract?: { __typename?: 'Contract'; id?: string | null } | null;
    } | null;
  } | null;
};

export type TimeOffPolicyUpdateMutationVariables = Exact<{
  input: TimeOffPolicyUpdateInput;
}>;

export type TimeOffPolicyUpdateMutation = {
  __typename?: 'Mutation';
  timeOffPolicyUpdate?: {
    __typename?: 'TimeOffTypeDefinition';
    id?: string | null;
    policyName?: string | null;
    validation?: Array<{
      __typename?: 'TimeOffFixedValidation';
      defaultValue?: number | null;
      unit?: TimeOffUnit | null;
      isUnlimitedLeavesAllowed?: boolean | null;
    } | null> | null;
  } | null;
};

export type TimeOffTypeUpdateMutationVariables = Exact<{
  input: TimeOffTypeUpdateInput;
}>;

export type TimeOffTypeUpdateMutation = {
  __typename?: 'Mutation';
  timeOffTypeUpdate?: {
    __typename?: 'TimeOffTypeInfo';
    description?: string | null;
    label?: string | null;
    isPaidLeave?: boolean | null;
    status?: TimeOffTypeStatus | null;
    type?: string | null;
    typeId?: string | null;
    usage?: number | null;
  } | null;
};

export type TimeOffPolicyValidateUsersMutationVariables = Exact<{
  input: TimeOffPolicyValidateUsersInput;
}>;

export type TimeOffPolicyValidateUsersMutation = {
  __typename?: 'Mutation';
  timeOffPolicyValidateUsers?: {
    __typename?: 'TimeOffPolicyUsersValidationResult';
    invalidContractsToAssign: Array<{
      __typename?: 'Contract';
      id?: string | null;
      position?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    invalidContractsToUnassigned: Array<{
      __typename?: 'Contract';
      id?: string | null;
      position?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    validContractsToAssign: Array<{
      __typename?: 'Contract';
      id?: string | null;
      position?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
  } | null;
};

export type PaySupplementBulkUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type PaySupplementBulkUploadMutation = {
  __typename?: 'Mutation';
  paySupplementBulkUpload?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type CreatePaySupplementMutationVariables = Exact<{
  input?: InputMaybe<PaySupplementCreateInput>;
}>;

export type CreatePaySupplementMutation = {
  __typename?: 'Mutation';
  paySupplementCreate?: {
    __typename?: 'PaySupplement';
    id?: string | null;
    type?: PaySupplementType | null;
    status?: PaySupplementStatus | null;
    currency?: CurrencyCode | null;
    amount?: number | null;
    amountInFunctionalCurrency?: number | null;
    notes?: string | null;
    description?: string | null;
    createdDate?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
  } | null;
};

export type DeletePaySupplementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePaySupplementMutation = {
  __typename?: 'Mutation';
  paySupplementRevoke?: {
    __typename?: 'PaySupplement';
    id?: string | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
  } | null;
};

export type GetPrePopulatedPaySupplementReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPrePopulatedPaySupplementReportQuery = {
  __typename?: 'Query';
  companyPrePopulatedPaySupplementReport?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
    link?: string | null;
    blob?: string | null;
    extension?: string | null;
    contentType?: string | null;
  } | null;
};

export type GetCompanyActiveContractsQueryVariables = Exact<{
  category?: InputMaybe<ApprovalCategory>;
  companyContractsFilter?: InputMaybe<CompanyContractsFilter>;
}>;

export type GetCompanyActiveContractsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      currency?: CurrencyCode | null;
      type?: ContractType | null;
      term?: ContractTerm | null;
      position?: string | null;
      status?: ContractStatus | null;
      startOn?: any | null;
      contractStarted?: boolean | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        emails?: Array<{
          __typename?: 'EmailAddress';
          email?: string | null;
        } | null> | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetPaySupplementQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetPaySupplementQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    paySupplements?: Array<{
      __typename?: 'PaySupplement';
      id?: string | null;
      type?: PaySupplementType | null;
      status?: PaySupplementStatus | null;
      currency?: CurrencyCode | null;
      amount?: number | null;
      amountInFunctionalCurrency?: number | null;
      notes?: string | null;
      description?: string | null;
      createdDate?: any | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        position?: string | null;
        country?: CountryCode | null;
        currency?: CurrencyCode | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        company?: { __typename?: 'Company'; id?: string | null } | null;
        compensation?: {
          __typename?: 'Compensation';
          additionalPays?: Array<
            | {
                __typename?: 'FixedPayComponent';
                rateType?: RateType | null;
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                instalments?: Array<{
                  __typename?: 'Instalment';
                  amount?: number | null;
                  currency?: CurrencyCode | null;
                  payOn?: {
                    __typename?: 'MonthYear';
                    year?: number | null;
                    month?: number | null;
                  } | null;
                }> | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | {
                __typename?: 'PercentPayComponent';
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | null
          > | null;
        } | null;
      } | null;
      payOn?: {
        __typename?: 'MonthYear';
        month?: number | null;
        year?: number | null;
      } | null;
      paySchedule?: {
        __typename?: 'ScheduleTime';
        unit: ScheduleTimeUnit;
        value: number;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPaySupplementsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaySupplementsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    paySupplements?: Array<{
      __typename?: 'PaySupplement';
      id?: string | null;
      type?: PaySupplementType | null;
      status?: PaySupplementStatus | null;
      currency?: CurrencyCode | null;
      amount?: number | null;
      amountInFunctionalCurrency?: number | null;
      notes?: string | null;
      description?: string | null;
      createdDate?: any | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        company?: { __typename?: 'Company'; id?: string | null } | null;
      } | null;
      payOn?: {
        __typename?: 'MonthYear';
        month?: number | null;
        year?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPayrollCycleForDomainQueryVariables = Exact<{
  domainId?: InputMaybe<Scalars['ID']>;
  contractId: Scalars['ID'];
  referenceDate: Scalars['Date'];
  domain: PayrollInputDomainType;
  returnClosestToReferenceDateUpcomingCutOff: Scalars['Boolean'];
}>;

export type GetPayrollCycleForDomainQuery = {
  __typename?: 'Query';
  payrollCycleForDomain?: {
    __typename?: 'PayrollCycle';
    id: string;
    frequency?: PayFrequency | null;
    startDate?: any | null;
    endDate?: any | null;
    payDate?: any | null;
    cutOffTo?: any | null;
  } | null;
};

export type UpdatePaySupplementMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<PaySupplementUpdateInput>;
}>;

export type UpdatePaySupplementMutation = {
  __typename?: 'Mutation';
  paySupplementUpdate?: {
    __typename?: 'PaySupplement';
    id?: string | null;
    type?: PaySupplementType | null;
    status?: PaySupplementStatus | null;
    currency?: CurrencyCode | null;
    amount?: number | null;
    amountInFunctionalCurrency?: number | null;
    notes?: string | null;
    description?: string | null;
    createdDate?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
  } | null;
};

export type DisputeCreateMutationVariables = Exact<{
  input: CreateDisputeInput;
}>;

export type DisputeCreateMutation = {
  __typename?: 'Mutation';
  disputeCreate: { __typename?: 'Dispute'; status: DisputeStatus };
};

export type CompanyPayableFragmentFragment = {
  __typename?: 'CompanyPayable';
  id: string;
  totalAmount?: number | null;
  currency?: CurrencyCode | null;
  status?: PayableStatus | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  companyPayableType?: CompanyPayableType | null;
  invoice?: {
    __typename?: 'Invoice';
    id: string;
    createdDate?: any | null;
    fullyPaidOnDate?: any | null;
    dueDate?: any | null;
    reference?: string | null;
    invoiceNo?: string | null;
    invoiceId?: string | null;
    amountPaid?: number | null;
    amountDue?: number | null;
    externalSystem?: ExternalSystemType | null;
  } | null;
  report?: {
    __typename?: 'CompanyPayableReport';
    companyId: string;
    month: number;
    year: number;
    type?: CompanyPayableReportType | null;
  } | null;
};

export type ExpenseMemberPayableFragmentFragment = {
  __typename?: 'ExpenseMemberPayable';
  id: string;
  type?: MemberPayableType | null;
  payableFromDate?: any | null;
  payableToDate?: any | null;
  dueDate?: any | null;
  title?: string | null;
  description?: string | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  rejectedAt?: any | null;
  memberPayableStatus?: MemberPayableStatus | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    position?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
  expense?: { __typename?: 'Expense'; id?: string | null } | null;
};

export type FreelancerPayableFragmentFragment = {
  __typename?: 'FreelancerPayable';
  id: string;
  type?: MemberPayableType | null;
  payableFromDate?: any | null;
  payableToDate?: any | null;
  dueDate?: any | null;
  title?: string | null;
  description?: string | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  durationValue?: number | null;
  changeReason?: string | null;
  comment?: string | null;
  rejectedAt?: any | null;
  memberPayableStatus?: MemberPayableStatus | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    position?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type PaySupplementMemberPayableFragmentFragment = {
  __typename?: 'PaySupplementMemberPayable';
  id: string;
  type?: MemberPayableType | null;
  payableFromDate?: any | null;
  payableToDate?: any | null;
  dueDate?: any | null;
  title?: string | null;
  description?: string | null;
  submittedAt?: any | null;
  createdOn?: any | null;
  updatedOn?: any | null;
  currency?: CurrencyCode | null;
  totalAmount?: number | null;
  totalInFunctionalCurrency?: number | null;
  rejectedAt?: any | null;
  memberPayableStatus?: MemberPayableStatus | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    position?: string | null;
    country?: CountryCode | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
  paySupplement?: { __typename?: 'PaySupplement'; id?: string | null } | null;
};

export type GetBankProcessingFeeQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetBankProcessingFeeQuery = {
  __typename?: 'Query';
  configuration?: {
    __typename?: 'Configuration';
    bankTransferFee?: number | null;
  } | null;
};

export type GetCompanyAutoDebitPayInSettingsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyAutoDebitPayInSettingsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payInSettings?: {
      __typename?: 'CompanyPayInSettings';
      payInPreferences?: Array<{
        __typename?: 'CompanyPayInPreferenceAutoDebit';
        isAutoDebitEnabled: boolean;
        isPreferenceActive: boolean;
        id: string;
        preferenceType: CompanyPayInPreferenceType;
        defaultAutoDebitPayInMethod?:
          | { __typename?: 'ACHCompanyPayinMethod'; id: string }
          | { __typename?: 'SEPACompanyPayinMethod'; id: string }
          | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCompanyBillingCurrencyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyBillingCurrencyQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    pricing?: {
      __typename?: 'Pricing';
      billingCurrencyCode?: CurrencyCode | null;
    } | null;
  } | null;
};

export type GetCompanyCreatedBundlesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyCreatedBundlesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    paymentBundles?: Array<{
      __typename?: 'PaymentBundle';
      currency?: CurrencyCode | null;
      id: string;
      referenceId?: string | null;
      status?: PaymentBundleStatus | null;
      totalAmount?: number | null;
      sourceType?: PaymentBundleSourceType | null;
      items: Array<{
        __typename?: 'PaymentBundleItem';
        id?: string | null;
        type?: PaymentBundleItemType | null;
        companyPayable?: {
          __typename?: 'CompanyPayable';
          id: string;
          totalAmount?: number | null;
          currency?: CurrencyCode | null;
          status?: PayableStatus | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          companyPayableType?: CompanyPayableType | null;
          invoice?: {
            __typename?: 'Invoice';
            id: string;
            createdDate?: any | null;
            fullyPaidOnDate?: any | null;
            dueDate?: any | null;
            reference?: string | null;
            invoiceNo?: string | null;
            invoiceId?: string | null;
            amountPaid?: number | null;
            amountDue?: number | null;
            externalSystem?: ExternalSystemType | null;
          } | null;
          report?: {
            __typename?: 'CompanyPayableReport';
            companyId: string;
            month: number;
            year: number;
            type?: CompanyPayableReportType | null;
          } | null;
        } | null;
      }>;
    } | null> | null;
  } | null;
};

export type GetCompanyDetailsForPaymentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyDetailsForPaymentsQuery = {
  __typename?: 'Query';
  company?: { __typename?: 'Company'; id?: string | null } | null;
};

export type GetCompanyPayInSettingsCondensedQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyPayInSettingsCondensedQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payInSettings?: {
      __typename?: 'CompanyPayInSettings';
      payInMethods: Array<
        | {
            __typename?: 'ACHCompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
            isActive?: boolean | null;
            isEnabled?: boolean | null;
            data?: Array<{
              __typename?: 'KeyValue';
              key?: string | null;
              value?: string | null;
            } | null> | null;
          }
        | {
            __typename?: 'SEPACompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
            isActive?: boolean | null;
            isEnabled?: boolean | null;
            data?: Array<{
              __typename?: 'KeyValue';
              key?: string | null;
              value?: string | null;
            } | null> | null;
          }
        | null
      >;
    } | null;
  } | null;
};

export type GetCompanyPayablesAndBundlesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyPayablesAndBundlesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      currency?: CurrencyCode | null;
    } | null;
    payables?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      totalAmount?: number | null;
      currency?: CurrencyCode | null;
      status?: PayableStatus | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      companyPayableType?: CompanyPayableType | null;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        createdDate?: any | null;
        fullyPaidOnDate?: any | null;
        dueDate?: any | null;
        reference?: string | null;
        invoiceNo?: string | null;
        invoiceId?: string | null;
        amountPaid?: number | null;
        amountDue?: number | null;
        externalSystem?: ExternalSystemType | null;
      } | null;
      report?: {
        __typename?: 'CompanyPayableReport';
        companyId: string;
        month: number;
        year: number;
        type?: CompanyPayableReportType | null;
      } | null;
    } | null> | null;
    paymentBundles?: Array<{
      __typename?: 'PaymentBundle';
      currency?: CurrencyCode | null;
      id: string;
      referenceId?: string | null;
      status?: PaymentBundleStatus | null;
      totalAmount?: number | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      sourceType?: PaymentBundleSourceType | null;
      items: Array<{
        __typename?: 'PaymentBundleItem';
        id?: string | null;
        type?: PaymentBundleItemType | null;
        companyPayable?: {
          __typename?: 'CompanyPayable';
          id: string;
          totalAmount?: number | null;
          currency?: CurrencyCode | null;
          status?: PayableStatus | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          companyPayableType?: CompanyPayableType | null;
          invoice?: {
            __typename?: 'Invoice';
            id: string;
            createdDate?: any | null;
            fullyPaidOnDate?: any | null;
            dueDate?: any | null;
            reference?: string | null;
            invoiceNo?: string | null;
            invoiceId?: string | null;
            amountPaid?: number | null;
            amountDue?: number | null;
            externalSystem?: ExternalSystemType | null;
          } | null;
          report?: {
            __typename?: 'CompanyPayableReport';
            companyId: string;
            month: number;
            year: number;
            type?: CompanyPayableReportType | null;
          } | null;
        } | null;
      }>;
      paymentMethodInfo?: {
        __typename?: 'PaymentMethodInfo';
        bundleId: string;
        autoDebitInfo?: {
          __typename?: 'AutoDebitInfo';
          id: string;
          scheduledDebitDate?: any | null;
        } | null;
      } | null;
    } | null> | null;
    memberPayables?: Array<
      | {
          __typename?: 'ExpenseMemberPayable';
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          rejectedAt?: any | null;
          memberPayableStatus?: MemberPayableStatus | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
          expense?: { __typename?: 'Expense'; id?: string | null } | null;
        }
      | {
          __typename?: 'FreelancerPayable';
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          durationValue?: number | null;
          changeReason?: string | null;
          comment?: string | null;
          rejectedAt?: any | null;
          memberPayableStatus?: MemberPayableStatus | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: 'PaySupplementMemberPayable';
          id: string;
          type?: MemberPayableType | null;
          payableFromDate?: any | null;
          payableToDate?: any | null;
          dueDate?: any | null;
          title?: string | null;
          description?: string | null;
          submittedAt?: any | null;
          createdOn?: any | null;
          updatedOn?: any | null;
          currency?: CurrencyCode | null;
          totalAmount?: number | null;
          totalInFunctionalCurrency?: number | null;
          rejectedAt?: any | null;
          memberPayableStatus?: MemberPayableStatus | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            country?: CountryCode | null;
            type?: ContractType | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          } | null;
          paySupplement?: {
            __typename?: 'PaySupplement';
            id?: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type GetCompanyPaymentBundleQueryVariables = Exact<{
  bundleId: Scalars['ID'];
}>;

export type GetCompanyPaymentBundleQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    paymentBundles?: Array<{
      __typename?: 'PaymentBundle';
      id: string;
      status?: PaymentBundleStatus | null;
      totalAmount?: number | null;
      currency?: CurrencyCode | null;
      referenceId?: string | null;
      items: Array<{
        __typename?: 'PaymentBundleItem';
        id?: string | null;
        type?: PaymentBundleItemType | null;
        companyPayable?: {
          __typename?: 'CompanyPayable';
          id: string;
          status?: PayableStatus | null;
          totalAmount?: number | null;
          currency?: CurrencyCode | null;
          invoice?: {
            __typename?: 'Invoice';
            invoiceNo?: string | null;
            invoiceId?: string | null;
            reference?: string | null;
            externalSystem?: ExternalSystemType | null;
            amountDue?: number | null;
          } | null;
        } | null;
      }>;
      paymentMethodInfo?: {
        __typename?: 'PaymentMethodInfo';
        paymentMethod?: PaymentMethod | null;
        processingFeePercentage?: number | null;
        processingFee?: {
          __typename?: 'ProcessingFee';
          feeTotal?: number | null;
        } | null;
      } | null;
      payInInfo?: {
        __typename?: 'PayInInfo';
        id: string;
        amount?: number | null;
        currency?: string | null;
        metadata?: Array<{
          __typename?: 'PayInMetadata';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
      bankAccount?: {
        __typename?: 'BankAccount';
        accountDetails?: Array<{
          __typename?: 'BankAccountDetail';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
    payInSettings?: {
      __typename?: 'CompanyPayInSettings';
      payInMethods: Array<
        | {
            __typename?: 'ACHCompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
            bankDetails?: Array<{
              __typename?: 'CompanyPayInMethodBankDetails';
              data?: Array<{
                __typename?: 'KeyValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null> | null;
          }
        | {
            __typename?: 'SEPACompanyPayinMethod';
            id: string;
            payInMethodType?: PayInMethodType | null;
            bankDetails?: Array<{
              __typename?: 'CompanyPayInMethodBankDetails';
              data?: Array<{
                __typename?: 'KeyValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null> | null;
          }
        | null
      >;
    } | null;
  } | null;
};

export type GetCreditNoteDocumentQueryVariables = Exact<{
  input?: InputMaybe<CreditNoteQueryInput>;
}>;

export type GetCreditNoteDocumentQuery = {
  __typename?: 'Query';
  creditNotesWithPagination?: {
    __typename?: 'CreditNotesResult';
    data?: Array<{
      __typename?: 'CreditNote';
      document?: {
        __typename?: 'DocumentReadable';
        id?: string | null;
        name?: string | null;
        link?: string | null;
        blob?: string | null;
        extension?: string | null;
        contentType?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type GetCreditNotesWithPaginationQueryVariables = Exact<{
  input?: InputMaybe<CreditNoteQueryInput>;
}>;

export type GetCreditNotesWithPaginationQuery = {
  __typename?: 'Query';
  creditNotesWithPagination?: {
    __typename?: 'CreditNotesResult';
    data?: Array<{
      __typename?: 'CreditNote';
      id: string;
      amountApplied?: number | null;
      amountUnapplied?: number | null;
      amountTotal?: number | null;
      creditNoteNo?: string | null;
      reference?: string | null;
      externalId?: string | null;
      currencyCode?: CurrencyCode | null;
      createdDate?: any | null;
      appliedInvoices?: Array<{
        __typename?: 'Invoice';
        reference?: string | null;
        invoiceNo?: string | null;
        amountPaid?: number | null;
        amountDue?: number | null;
        totalAmount?: number | null;
      }> | null;
      report?: {
        __typename?: 'CompanyPayableReport';
        companyId: string;
        month: number;
        year: number;
        type?: CompanyPayableReportType | null;
      } | null;
    }> | null;
    pageResult?: {
      __typename?: 'PageResult';
      pageNumber?: number | null;
      pageSize?: number | null;
      count?: number | null;
    } | null;
  } | null;
};

export type GetDisputesQueryVariables = Exact<{
  filter?: InputMaybe<DisputeFilter>;
  page?: InputMaybe<PageRequest>;
}>;

export type GetDisputesQuery = {
  __typename?: 'Query';
  disputes?: {
    __typename?: 'DisputeResponse';
    data?: Array<{
      __typename?: 'Dispute';
      status: DisputeStatus;
      description?: string | null;
      reasonCategory?: DisputeReasonCategory | null;
      reasonSubCategory?: DisputeReasonSubCategory | null;
      originType: DisputeOriginType;
      originId: string;
      origin?: {
        __typename?: 'CompanyPayable';
        id: string;
        type?: CompanyPayableType | null;
        totalAmount?: number | null;
        currency?: CurrencyCode | null;
        invoice?: {
          __typename?: 'Invoice';
          reference?: string | null;
          invoiceNo?: string | null;
          totalAmount?: number | null;
        } | null;
        creditNote?: {
          __typename?: 'CreditNote';
          reference?: string | null;
          creditNoteNo?: string | null;
        } | null;
        report?: {
          __typename?: 'CompanyPayableReport';
          companyId: string;
          month: number;
          year: number;
          type?: CompanyPayableReportType | null;
        } | null;
      } | null;
    }> | null;
    page?: {
      __typename?: 'PageResult';
      pageNumber?: number | null;
      pageSize?: number | null;
      count?: number | null;
    } | null;
  } | null;
};

export type GetInvoiceDocumentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetInvoiceDocumentQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payables?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        externalSystem?: ExternalSystemType | null;
        document?: {
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          link?: string | null;
          blob?: string | null;
          extension?: string | null;
          contentType?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetInvoiceExternalLinkQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetInvoiceExternalLinkQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    payables?: Array<{
      __typename?: 'CompanyPayable';
      id: string;
      invoice?: {
        __typename?: 'Invoice';
        id: string;
        externalSystem?: ExternalSystemType | null;
        externalLink?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyPayableReportQueryVariables = Exact<{
  input: DownloadCompanyPayableReportInput;
}>;

export type GetCompanyPayableReportQuery = {
  __typename?: 'Query';
  downloadCompanyPayableReport: {
    __typename?: 'DocumentReadable';
    blob?: string | null;
    extension?: string | null;
    name?: string | null;
    contentType?: string | null;
  };
};

export type GetPaymentBundleReviewItemsQueryVariables = Exact<{
  items?: InputMaybe<
    | Array<InputMaybe<PaymentBundlePreviewItemInput>>
    | InputMaybe<PaymentBundlePreviewItemInput>
  >;
}>;

export type GetPaymentBundleReviewItemsQuery = {
  __typename?: 'Query';
  paymentBundlePreview?: {
    __typename?: 'PaymentBundlePreview';
    currency?: CurrencyCode | null;
    totalAmount?: number | null;
    items: Array<{
      __typename?: 'PaymentBundlePreviewItem';
      externalId?: string | null;
      billingAmount?: number | null;
      managementFee?: number | null;
      type?: PaymentBundleItemType | null;
      payoutProcessingFee?: number | null;
      item?:
        | {
            __typename?: 'CompanyPayable';
            id: string;
            totalAmount?: number | null;
            currency?: CurrencyCode | null;
            status?: PayableStatus | null;
            createdOn?: any | null;
            updatedOn?: any | null;
            companyPayableType?: CompanyPayableType | null;
            invoice?: {
              __typename?: 'Invoice';
              id: string;
              createdDate?: any | null;
              fullyPaidOnDate?: any | null;
              dueDate?: any | null;
              reference?: string | null;
              invoiceNo?: string | null;
              invoiceId?: string | null;
              amountPaid?: number | null;
              amountDue?: number | null;
              externalSystem?: ExternalSystemType | null;
            } | null;
            report?: {
              __typename?: 'CompanyPayableReport';
              companyId: string;
              month: number;
              year: number;
              type?: CompanyPayableReportType | null;
            } | null;
          }
        | {
            __typename?: 'ExpenseMemberPayable';
            id: string;
            type?: MemberPayableType | null;
            payableFromDate?: any | null;
            payableToDate?: any | null;
            dueDate?: any | null;
            title?: string | null;
            description?: string | null;
            submittedAt?: any | null;
            createdOn?: any | null;
            updatedOn?: any | null;
            currency?: CurrencyCode | null;
            totalAmount?: number | null;
            totalInFunctionalCurrency?: number | null;
            rejectedAt?: any | null;
            memberPayableStatus?: MemberPayableStatus | null;
            contract?: {
              __typename?: 'Contract';
              id?: string | null;
              position?: string | null;
              country?: CountryCode | null;
              type?: ContractType | null;
              member?: {
                __typename?: 'Member';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
            expense?: { __typename?: 'Expense'; id?: string | null } | null;
          }
        | {
            __typename?: 'FreelancerPayable';
            id: string;
            type?: MemberPayableType | null;
            payableFromDate?: any | null;
            payableToDate?: any | null;
            dueDate?: any | null;
            title?: string | null;
            description?: string | null;
            submittedAt?: any | null;
            createdOn?: any | null;
            updatedOn?: any | null;
            currency?: CurrencyCode | null;
            totalAmount?: number | null;
            totalInFunctionalCurrency?: number | null;
            durationValue?: number | null;
            changeReason?: string | null;
            comment?: string | null;
            rejectedAt?: any | null;
            memberPayableStatus?: MemberPayableStatus | null;
            contract?: {
              __typename?: 'Contract';
              id?: string | null;
              position?: string | null;
              country?: CountryCode | null;
              type?: ContractType | null;
              member?: {
                __typename?: 'Member';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'PaySupplementMemberPayable';
            id: string;
            type?: MemberPayableType | null;
            payableFromDate?: any | null;
            payableToDate?: any | null;
            dueDate?: any | null;
            title?: string | null;
            description?: string | null;
            submittedAt?: any | null;
            createdOn?: any | null;
            updatedOn?: any | null;
            currency?: CurrencyCode | null;
            totalAmount?: number | null;
            totalInFunctionalCurrency?: number | null;
            rejectedAt?: any | null;
            memberPayableStatus?: MemberPayableStatus | null;
            contract?: {
              __typename?: 'Contract';
              id?: string | null;
              position?: string | null;
              country?: CountryCode | null;
              type?: ContractType | null;
              member?: {
                __typename?: 'Member';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
            paySupplement?: {
              __typename?: 'PaySupplement';
              id?: string | null;
            } | null;
          }
        | null;
    }>;
  } | null;
};

export type GetPaymentInstructionsFileQueryVariables = Exact<{
  bundleId: Scalars['ID'];
}>;

export type GetPaymentInstructionsFileQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    paymentBundles?: Array<{
      __typename?: 'PaymentBundle';
      id: string;
      instructionFile?: {
        __typename?: 'DocumentReadable';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        link?: string | null;
        blob?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type MarkBundleAsProcessingMutationVariables = Exact<{
  id: Scalars['ID'];
  paymentAdvices?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;

export type MarkBundleAsProcessingMutation = {
  __typename?: 'Mutation';
  paymentBundleMarkAsProcessing?: {
    __typename?: 'PaymentBundle';
    id: string;
    status?: PaymentBundleStatus | null;
  } | null;
};

export type CreatePaymentBundleMutationVariables = Exact<{
  input: PaymentBundleCreateInput;
}>;

export type CreatePaymentBundleMutation = {
  __typename?: 'Mutation';
  paymentBundleCreate?: {
    __typename?: 'PaymentBundle';
    currency?: CurrencyCode | null;
    id: string;
    referenceId?: string | null;
    status?: PaymentBundleStatus | null;
    totalAmount?: number | null;
    items: Array<{
      __typename?: 'PaymentBundleItem';
      id?: string | null;
      type?: PaymentBundleItemType | null;
      companyPayable?: {
        __typename?: 'CompanyPayable';
        id: string;
        totalAmount?: number | null;
        currency?: CurrencyCode | null;
        status?: PayableStatus | null;
        createdOn?: any | null;
        updatedOn?: any | null;
        companyPayableType?: CompanyPayableType | null;
        invoice?: {
          __typename?: 'Invoice';
          id: string;
          createdDate?: any | null;
          fullyPaidOnDate?: any | null;
          dueDate?: any | null;
          reference?: string | null;
          invoiceNo?: string | null;
          invoiceId?: string | null;
          amountPaid?: number | null;
          amountDue?: number | null;
          externalSystem?: ExternalSystemType | null;
        } | null;
        report?: {
          __typename?: 'CompanyPayableReport';
          companyId: string;
          month: number;
          year: number;
          type?: CompanyPayableReportType | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type PaymentBundleDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PaymentBundleDeleteMutation = {
  __typename?: 'Mutation';
  paymentBundleDelete?: {
    __typename?: 'PaymentBundle';
    id: string;
    status?: PaymentBundleStatus | null;
  } | null;
};

export type UpdatePaymentBundleMutationVariables = Exact<{
  input?: InputMaybe<PaymentBundleUpdateInput>;
}>;

export type UpdatePaymentBundleMutation = {
  __typename?: 'Mutation';
  paymentBundleUpdate?: {
    __typename?: 'PaymentBundle';
    id: string;
    status?: PaymentBundleStatus | null;
    totalAmount?: number | null;
    currency?: CurrencyCode | null;
    referenceId?: string | null;
    items: Array<{
      __typename?: 'PaymentBundleItem';
      id?: string | null;
      type?: PaymentBundleItemType | null;
      companyPayable?: {
        __typename?: 'CompanyPayable';
        id: string;
        status?: PayableStatus | null;
        totalAmount?: number | null;
        currency?: CurrencyCode | null;
        invoice?: {
          __typename?: 'Invoice';
          invoiceNo?: string | null;
          invoiceId?: string | null;
        } | null;
      } | null;
    }>;
    paymentMethodInfo?: {
      __typename?: 'PaymentMethodInfo';
      paymentMethod?: PaymentMethod | null;
      processingFee?: {
        __typename?: 'ProcessingFee';
        feeTotal?: number | null;
      } | null;
    } | null;
    bankAccount?: {
      __typename?: 'BankAccount';
      accountDetails?: Array<{
        __typename?: 'BankAccountDetail';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    instructionFile?: {
      __typename?: 'DocumentReadable';
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
      link?: string | null;
    } | null;
  } | null;
};

export type CompanyPayrollFileDownloadAsyncMutationVariables = Exact<{
  request: CompanyPayrollFileDownloadRequest;
}>;

export type CompanyPayrollFileDownloadAsyncMutation = {
  __typename?: 'Mutation';
  companyPayrollFileDownloadAsync?: {
    __typename?: 'PayrollFileDownloadResponse';
    id?: string | null;
    status?: PayrollFileDownloadStatus | null;
    payrollFile?: {
      __typename?: 'PayrollFile';
      id?: string | null;
      downloadUrl?: string | null;
      name?: string | null;
      extension?: string | null;
      createdOn?: any | null;
    } | null;
  } | null;
};

export type PayrollBulkUploadCreateMutationVariables = Exact<{
  payrollCycleId: Scalars['ID'];
  fileName: Scalars['String'];
}>;

export type PayrollBulkUploadCreateMutation = {
  __typename?: 'Mutation';
  payrollBulkUploadCreate?: {
    __typename?: 'UploadUrlResponse';
    url: string;
  } | null;
};

export type CompanyPayrollConfirmInputMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompanyPayrollConfirmInputMutation = {
  __typename?: 'Mutation';
  companyPayrollInputConfirm?: {
    __typename?: 'CompanyPayrollCycle';
    id: string;
    status: CompanyPayrollCycleStatus;
    payrollCutOffDate?: any | null;
    payDate?: any | null;
    frequency?: PayFrequency | null;
    startDate?: any | null;
    endDate?: any | null;
    entityType: PayrollCycleEntityType;
    summary?: {
      __typename?: 'CompanyPayrollCycleSummary';
      memberTotalCount?: number | null;
    } | null;
  } | null;
};

export type CompanyPayrollConfirmOutputMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompanyPayrollConfirmOutputMutation = {
  __typename?: 'Mutation';
  companyPayrollOutputConfirm?: {
    __typename?: 'CompanyPayrollCycle';
    id: string;
    status: CompanyPayrollCycleStatus;
    payrollCutOffDate?: any | null;
    payDate?: any | null;
    frequency?: PayFrequency | null;
    startDate?: any | null;
    endDate?: any | null;
    entityType: PayrollCycleEntityType;
    summary?: {
      __typename?: 'CompanyPayrollCycleSummary';
      memberTotalCount?: number | null;
    } | null;
  } | null;
};

export type CustomerUpdatePayrollWorkflowMutationVariables = Exact<{
  value: CustomerPayrollWorkflowRequest;
}>;

export type CustomerUpdatePayrollWorkflowMutation = {
  __typename?: 'Mutation';
  customerUpdatePayrollWorkflow: {
    __typename?: 'CustomerPayrollWorkflowResponse';
    companyPayrollCycles: Array<{
      __typename?: 'CompanyPayrollCycle';
      id: string;
      status: CompanyPayrollCycleStatus;
      payrollCutOffDate?: any | null;
      payDate?: any | null;
      frequency?: PayFrequency | null;
      startDate?: any | null;
      endDate?: any | null;
      entityType: PayrollCycleEntityType;
      summary?: {
        __typename?: 'CompanyPayrollCycleSummary';
        memberTotalCount?: number | null;
      } | null;
    }>;
  };
};

export type DownloadCompanyPayrollCycleReportQueryVariables = Exact<{
  filter: CompanyPayrollCycleReportFilter;
}>;

export type DownloadCompanyPayrollCycleReportQuery = {
  __typename?: 'Query';
  companyPayrollCycleReportDownload?: {
    __typename?: 'PayrollFile';
    id?: string | null;
    downloadUrl?: string | null;
    name?: string | null;
  } | null;
};

export type DownloadCompanyPayrollInputReportQueryVariables = Exact<{
  filter: CompanyPayrollInputReportFilter;
}>;

export type DownloadCompanyPayrollInputReportQuery = {
  __typename?: 'Query';
  companyPayrollInputReportDownload?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
    extension?: string | null;
    contentType?: string | null;
    link?: string | null;
    blob?: string | null;
  } | null;
};

export type DownloadCompanyPayrollReportQueryVariables = Exact<{
  filter: CompanyPayrollReportInput;
}>;

export type DownloadCompanyPayrollReportQuery = {
  __typename?: 'Query';
  companyPayrollReportDownload?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
    extension?: string | null;
    contentType?: string | null;
    link?: string | null;
    blob?: string | null;
  } | null;
};

export type DownloadPayrollBulkUploadTemplateQueryVariables = Exact<{
  payrollCycleId?: InputMaybe<Scalars['ID']>;
}>;

export type DownloadPayrollBulkUploadTemplateQuery = {
  __typename?: 'Query';
  payrollBulkUploadTemplate?: {
    __typename?: 'DocumentReadable';
    name?: string | null;
    extension?: string | null;
    link?: string | null;
  } | null;
};

export type DownloadPayrollFileQueryVariables = Exact<{
  fileDownloadId: Scalars['ID'];
}>;

export type DownloadPayrollFileQuery = {
  __typename?: 'Query';
  payrollFileDownload?: {
    __typename?: 'PayrollFileDownloadResponse';
    id?: string | null;
    status?: PayrollFileDownloadStatus | null;
    payrollFile?: {
      __typename?: 'PayrollFile';
      id?: string | null;
      downloadUrl?: string | null;
      name?: string | null;
      extension?: string | null;
      createdOn?: any | null;
    } | null;
  } | null;
};

export type GetCompanyAndEntityQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyAndEntityQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      registrationNo?: string | null;
      phone?: string | null;
      address?: {
        __typename?: 'Address';
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        zipcode?: string | null;
        country?: CountryCode | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyComplianceEorTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyComplianceEorTypeQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
        | {
            __typename?: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
          }
        | null;
    } | null> | null;
  } | null;
};

export type GetCompanyPayrollCycleAvailableReportsQueryVariables = Exact<{
  companyPayrollCycleId: Scalars['ID'];
}>;

export type GetCompanyPayrollCycleAvailableReportsQuery = {
  __typename?: 'Query';
  companyPayrollCycleReports?: Array<CompanyPayrollReport> | null;
};

export type GetCompanyPayrollCycleOutputHeadcountSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyPayrollCycleOutputHeadcountSummaryQuery = {
  __typename?: 'Query';
  companyPayrollCycle?: {
    __typename?: 'CompanyPayrollCycle';
    id: string;
    payrollCycles?: Array<{
      __typename?: 'PayrollCycle';
      id: string;
      outputHeadcountSummary?: {
        __typename?: 'OutputHeadcountSummary';
        starter?: number | null;
        leaver?: number | null;
        active?: number | null;
        total?: number | null;
      } | null;
    }> | null;
  } | null;
};

export type GetCompanyPayrollCycleOutputSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyPayrollCycleOutputSummaryQuery = {
  __typename?: 'Query';
  companyPayrollCycle?: {
    __typename?: 'CompanyPayrollCycle';
    id: string;
    payrollCycles?: Array<{
      __typename?: 'PayrollCycle';
      id: string;
      payrollOutputSummary?: {
        __typename?: 'PayrollOutputSummary';
        grossSalary: number;
        expenses: number;
        employeeDeductions: number;
        netPay: number;
        employerContributions: number;
        totalPayrollCost: number;
      } | null;
      previousCycle?: {
        __typename?: 'PayrollCycle';
        payrollOutputSummary?: {
          __typename?: 'PayrollOutputSummary';
          grossSalary: number;
          expenses: number;
          employeeDeductions: number;
          netPay: number;
          employerContributions: number;
          totalPayrollCost: number;
        } | null;
      } | null;
      outputVariance?: {
        __typename?: 'PayrollOutputDataVariance';
        grossSalaryVariance?: number | null;
        expensesVariance?: number | null;
        employeeDeductionsVariance?: number | null;
        netPayVariance?: number | null;
        employerContributionsVariance?: number | null;
        totalPayrollCostVariance?: number | null;
      } | null;
    }> | null;
  } | null;
};

export type GetCompanyPayrollCyclePayslipSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyPayrollCyclePayslipSummaryQuery = {
  __typename?: 'Query';
  companyPayrollCycle?: {
    __typename?: 'CompanyPayrollCycle';
    id: string;
    payrollCycles?: Array<{
      __typename?: 'PayrollCycle';
      id: string;
      payslipSummary?: {
        __typename?: 'PayslipSummary';
        published?: number | null;
        readyToPublish?: number | null;
        totalMembers?: number | null;
      } | null;
    }> | null;
  } | null;
};

export type GetCompanyPayrollCycleSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyPayrollCycleSummaryQuery = {
  __typename?: 'Query';
  companyPayrollCycle?: {
    __typename?: 'CompanyPayrollCycle';
    id: string;
    status: CompanyPayrollCycleStatus;
    payrollCutOffDate?: any | null;
    payDate?: any | null;
    frequency?: PayFrequency | null;
    startDate?: any | null;
    endDate?: any | null;
    entityType: PayrollCycleEntityType;
    company: {
      __typename?: 'Company';
      id?: string | null;
      displayName?: string | null;
      primaryEntity?: {
        __typename?: 'LegalEntity';
        id?: string | null;
        legalName?: string | null;
        address?: {
          __typename?: 'Address';
          country?: CountryCode | null;
        } | null;
      } | null;
    };
    entity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      currency?: CurrencyCode | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
    payrollCycles?: Array<{
      __typename?: 'PayrollCycle';
      id: string;
      status?: PayrollCycleStatus | null;
      inputHeadcountSummary?: {
        __typename?: 'InputHeadcountSummary';
        previousCount?: number | null;
        newJoiner?: number | null;
        leaver?: number | null;
        totalCount?: number | null;
      } | null;
      employeeDataChanges?: {
        __typename?: 'EmployeeDataChanges';
        bankDetails?: number | null;
        compensation?: number | null;
      } | null;
      previousCycle?: { __typename?: 'PayrollCycle'; id: string } | null;
    }> | null;
    summary?: {
      __typename?: 'CompanyPayrollCycleSummary';
      memberTotalCount?: number | null;
    } | null;
  } | null;
};

export type GetCompanyPayrollCycleQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: InputMaybe<CompanyPayrollInputFilter>;
  pageRequest: PageRequest;
}>;

export type GetCompanyPayrollCycleQuery = {
  __typename?: 'Query';
  companyPayrollCycle?: {
    __typename?: 'CompanyPayrollCycle';
    id: string;
    status: CompanyPayrollCycleStatus;
    payrollCutOffDate?: any | null;
    payDate?: any | null;
    frequency?: PayFrequency | null;
    startDate?: any | null;
    endDate?: any | null;
    confirmByDate?: any | null;
    createdOn?: any | null;
    entityType: PayrollCycleEntityType;
    payrollMonth?: {
      __typename?: 'MonthYear';
      month?: number | null;
      year?: number | null;
    } | null;
    company: {
      __typename?: 'Company';
      id?: string | null;
      displayName?: string | null;
      primaryEntity?: {
        __typename?: 'LegalEntity';
        id?: string | null;
        currency?: CurrencyCode | null;
      } | null;
    };
    companyPayrollInput?: {
      __typename?: 'CompanyPayrollInputResponse';
      pageResult: {
        __typename?: 'PageResult';
        count?: number | null;
        pageSize?: number | null;
        pageNumber?: number | null;
      };
      data: Array<{
        __typename?: 'CompanyPayrollInputEntry';
        id?: string | null;
        contractId: string;
        country: CountryCode;
        memberName: string;
        baseSalary: number;
        memberCurrency: CurrencyCode;
        companyCurrency: CurrencyCode;
        memberToCompanyCurrencyExchangeRate: number;
        contractStatus: ContractStatus;
        payFrequency: PayFrequency;
        rateFrequency: RateFrequency;
        contractPayrollLabels: Array<ContractPayrollLabel>;
        expenses: number;
        allowance: number;
        paySuplements: number;
        timeoff: number;
        payrollContractTypes?: Array<PayrollContractType> | null;
        contract: {
          __typename?: 'Contract';
          id?: string | null;
          position?: string | null;
          employeeId?: string | null;
        };
      }>;
    } | null;
  } | null;
};

export type GetCompanyPayrollCyclesQueryVariables = Exact<{
  filter: CompanyPayrollCycleFilter;
}>;

export type GetCompanyPayrollCyclesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
    companyPayrollCycles?: Array<{
      __typename?: 'CompanyPayrollCycle';
      id: string;
      status: CompanyPayrollCycleStatus;
      entityType: PayrollCycleEntityType;
      payrollCutOffDate?: any | null;
      payDate?: any | null;
      frequency?: PayFrequency | null;
      startDate?: any | null;
      endDate?: any | null;
      confirmByDate?: any | null;
      createdOn?: any | null;
      payrollMonth?: {
        __typename?: 'MonthYear';
        month?: number | null;
        year?: number | null;
      } | null;
      entity?: {
        __typename?: 'LegalEntity';
        id?: string | null;
        legalName?: string | null;
        address?: {
          __typename?: 'Address';
          country?: CountryCode | null;
        } | null;
      } | null;
      payrollCycles?: Array<{
        __typename?: 'PayrollCycle';
        id: string;
        approvePayrollInputDeadline?: any | null;
        approvePayrollReportDeadline?: any | null;
      }> | null;
      summary?: {
        __typename?: 'CompanyPayrollCycleSummary';
        memberTotalCount?: number | null;
        countryTotalCounts?: Array<{
          __typename?: 'CountryCount';
          country?: CountryCode | null;
          count?: number | null;
        } | null> | null;
      } | null;
    }> | null;
  } | null;
};

export type GetCompanyPayrollEntityTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyPayrollEntityTypesQuery = {
  __typename?: 'Query';
  companyPayrollEntityTypes?: Array<PayrollCycleEntityType | null> | null;
};

export type GetCompanyPayrollMemberPayCountsQueryVariables = Exact<{
  payrollFilter: CompanyPayrollFilter;
  pageRequest?: InputMaybe<PageRequest>;
}>;

export type GetCompanyPayrollMemberPayCountsQuery = {
  __typename?: 'Query';
  companyPayrollMemberPays?: {
    __typename?: 'CompanyMemberPayResult';
    employeeCount?: number | null;
    countryCount?: number | null;
  } | null;
};

export type GetCompanyPayrollMemberPaysQueryVariables = Exact<{
  payrollFilter: CompanyPayrollFilter;
  pageRequest?: InputMaybe<PageRequest>;
}>;

export type GetCompanyPayrollMemberPaysQuery = {
  __typename?: 'Query';
  companyPayrollMemberPays?: {
    __typename?: 'CompanyMemberPayResult';
    employeeCount?: number | null;
    countryCount?: number | null;
    data?: Array<{
      __typename?: 'CompanyMemberPay';
      id?: string | null;
      currency?: CurrencyCode | null;
      amountGross?: number | null;
      totalContributionAmount?: number | null;
      totalDeductionsAmount?: number | null;
      totalExpenseAmount?: number | null;
      amountNet?: number | null;
      amountTotalCost?: number | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        employeeId?: string | null;
        status?: ContractStatus | null;
        country?: CountryCode | null;
        position?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null> | null;
    pageResult?: {
      __typename?: 'PageResult';
      count?: number | null;
      pageNumber?: number | null;
      pageSize?: number | null;
    } | null;
  } | null;
};

export type GetEligibleCountriesForEorQueryVariables = Exact<{
  payrollCycleEntityTypes:
    | Array<PayrollCycleEntityType>
    | PayrollCycleEntityType;
}>;

export type GetEligibleCountriesForEorQuery = {
  __typename?: 'Query';
  eligibleCountriesForEor: Array<{
    __typename?: 'PayrollCountryEntityType';
    countryCode: CountryCode;
    entityType: PayrollCycleEntityType;
  }>;
};

export type GetEligibleEntitiesForPeoQueryVariables = Exact<{
  payrollCycleEntityType: PayrollCycleEntityType;
}>;

export type GetEligibleEntitiesForPeoQuery = {
  __typename?: 'Query';
  eligibleEntitiesForPeo: Array<{
    __typename?: 'PayrollCycleEntity';
    id?: string | null;
    name?: string | null;
  }>;
};

export type GetPayrollBulkUploadConfirmStatusQueryVariables = Exact<{
  payrollCycleId: Scalars['ID'];
}>;

export type GetPayrollBulkUploadConfirmStatusQuery = {
  __typename?: 'Query';
  payrollBulkUploadConfirmStatus?: PayrollBulkUploadConfirmStatus | null;
};

export type GetPayrollCyclesForCalendarQueryVariables = Exact<{
  filter: PayrollCalendarFilters;
}>;

export type GetPayrollCyclesForCalendarQuery = {
  __typename?: 'Query';
  payrollCyclesForCalendar: Array<{
    __typename?: 'PayrollCycle';
    id: string;
    configId?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    defaultStartDate?: any | null;
    payDate?: any | null;
    defaultPayDate?: any | null;
    cutOffTo?: any | null;
    defaultCutOffTo?: any | null;
    approvePayrollReportDeadline?: any | null;
    country?: CountryCode | null;
    entity?: {
      __typename?: 'PayrollCycleEntity';
      id?: string | null;
      entityType?: PayrollCycleEntityType | null;
      name?: string | null;
      company?: {
        __typename?: 'Company';
        primaryEntity?: {
          __typename?: 'LegalEntity';
          address?: {
            __typename?: 'Address';
            country?: CountryCode | null;
          } | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type PayrollBulkUploadConfirmMutationVariables = Exact<{
  payrollCycleId: Scalars['ID'];
}>;

export type PayrollBulkUploadConfirmMutation = {
  __typename?: 'Mutation';
  payrollBulkUploadConfirm?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type PayrollBulkUploadValidateMutationVariables = Exact<{
  payrollCycleId: Scalars['ID'];
}>;

export type PayrollBulkUploadValidateMutation = {
  __typename?: 'Mutation';
  payrollBulkUploadValidate?: {
    __typename?: 'BulkUploadValidationResponse';
    bulkUploadSummary?: {
      __typename?: 'PayrollBulkUploadSummary';
      totalExpenses?: number | null;
      totalPaySupplements?: number | null;
      totalTimeOff?: number | null;
    } | null;
    validationResponse?: {
      __typename?: 'ValidationResponse';
      succeeded: boolean;
      results?: Array<{
        __typename?: 'ValidationResult';
        type: ValidationResultType;
        message: string;
      }> | null;
    } | null;
    bulkUploadFile?: {
      __typename?: 'PayrollFile';
      name?: string | null;
      extension?: string | null;
      downloadUrl?: string | null;
    } | null;
    errorReport?: {
      __typename?: 'PayrollFileDownloadResponse';
      id?: string | null;
      status?: PayrollFileDownloadStatus | null;
      payrollFile?: {
        __typename?: 'PayrollFile';
        id?: string | null;
        downloadUrl?: string | null;
        name?: string | null;
        extension?: string | null;
      } | null;
    } | null;
  } | null;
};

export type PublishPayslipsMutationVariables = Exact<{
  companyPayrollCycleId: Scalars['ID'];
}>;

export type PublishPayslipsMutation = {
  __typename?: 'Mutation';
  companyPayrollPayslipPublish?: {
    __typename?: 'PublishPayslipsResponse';
    publishPayslipsSummary?: {
      __typename?: 'PublishPayslipsSummary';
      publishedPayslipsCount?: number | null;
    } | null;
  } | null;
};

export type UpdateDemoCyclesMutationVariables = Exact<{
  value: UpdateDemoCyclesInput;
}>;

export type UpdateDemoCyclesMutation = {
  __typename?: 'Mutation';
  updateDemoCycles: Array<{
    __typename?: 'PayrollCycle';
    id: string;
    status?: PayrollCycleStatus | null;
  }>;
};

export type GetLatestMemberPayrollCyclesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestMemberPayrollCyclesQuery = {
  __typename?: 'Query';
  latestMemberPayrollCycles?: {
    __typename?: 'MemberPayrollCyclesResponse';
    payrollCycleDetails?: Array<{
      __typename?: 'MemberPayrollCycleDetail';
      payrollCycle?: {
        __typename?: 'PayrollCycle';
        id: string;
        configId?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        frequency?: PayFrequency | null;
        cutOffTo?: any | null;
      } | null;
      memberPay?:
        | {
            __typename?: 'CompanyMemberPay';
            amountGross?: number | null;
            currency?: CurrencyCode | null;
            amountNet?: number | null;
          }
        | {
            __typename?: 'PartnerMemberPay';
            amountGross?: number | null;
            currency?: CurrencyCode | null;
            amountNet?: number | null;
          }
        | null;
    }> | null;
  } | null;
};

export type GetLatestMemberPayrollSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestMemberPayrollSummaryQuery = {
  __typename?: 'Query';
  latestMemberPayrollCycles?: {
    __typename?: 'MemberPayrollCyclesResponse';
    payrollCycleDetails?: Array<{
      __typename?: 'MemberPayrollCycleDetail';
      payrollCycle?: {
        __typename?: 'PayrollCycle';
        id: string;
        startDate?: any | null;
        endDate?: any | null;
      } | null;
      memberPay?:
        | {
            __typename?: 'CompanyMemberPay';
            aggregatedGross?: number | null;
            amountGross?: number | null;
            amountNet?: number | null;
            currency?: CurrencyCode | null;
          }
        | {
            __typename?: 'PartnerMemberPay';
            aggregatedGross?: number | null;
            amountGross?: number | null;
            amountNet?: number | null;
            currency?: CurrencyCode | null;
          }
        | null;
      summary?: {
        __typename?: 'MemberPayrollCycleSummary';
        paySupplement?: {
          __typename?: 'MemberPayrollCycleSummaryItem';
          totalAmount?: number | null;
          count?: number | null;
        } | null;
        expense?: {
          __typename?: 'MemberPayrollCycleSummaryItem';
          totalAmount?: number | null;
          count?: number | null;
        } | null;
        fixedAllowance?: {
          __typename?: 'MemberPayrollCycleSummaryItem';
          totalAmount?: number | null;
          count?: number | null;
        } | null;
        unpaidTimeOff?: {
          __typename?: 'MemberPayrollCycleSummaryItem';
          totalAmount?: number | null;
          count?: number | null;
        } | null;
        deductions?: {
          __typename?: 'MemberPayrollCycleSummaryItem';
          totalAmount?: number | null;
          count?: number | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type MemberPayrollCycleSummaryItemFragment = {
  __typename?: 'MemberPayrollCycleSummaryItem';
  totalAmount?: number | null;
  count?: number | null;
};

export type GetMemberPayrollCycleDetailsQueryVariables = Exact<{
  filter: MemberPayrollCycleDetailFilter;
}>;

export type GetMemberPayrollCycleDetailsQuery = {
  __typename?: 'Query';
  memberPayrollCycleDetail?: {
    __typename?: 'MemberPayrollCycleDetail';
    payrollCycle?: {
      __typename?: 'PayrollCycle';
      id: string;
      configId?: string | null;
      startDate?: any | null;
      endDate?: any | null;
      frequency?: PayFrequency | null;
      status?: PayrollCycleStatus | null;
      cutOffTo?: any | null;
    } | null;
    memberPay?:
      | {
          __typename?: 'CompanyMemberPay';
          amountGross?: number | null;
          currency?: CurrencyCode | null;
          amountNet?: number | null;
          totalDeductionsAmount?: number | null;
          aggregatedGross?: number | null;
          deductions?: Array<{
            __typename?: 'PayComponent';
            name?: string | null;
            type?: PayComponentType | null;
            value?: number | null;
          } | null> | null;
        }
      | {
          __typename?: 'PartnerMemberPay';
          amountGross?: number | null;
          currency?: CurrencyCode | null;
          amountNet?: number | null;
          totalDeductionsAmount?: number | null;
          aggregatedGross?: number | null;
          deductions?: Array<{
            __typename?: 'PayComponent';
            name?: string | null;
            type?: PayComponentType | null;
            value?: number | null;
          } | null> | null;
        }
      | null;
    paySupplements?: Array<{
      __typename?: 'PaySupplementSnapshot';
      paySupplementId: string;
      amount?: number | null;
      currency?: CurrencyCode | null;
      type?: PaySupplementType | null;
    }> | null;
    expenses?: Array<{
      __typename?: 'ExpenseSnapshot';
      expenseId: string;
      totalAmount?: number | null;
      currency?: CurrencyCode | null;
      expenseTitle?: string | null;
    }> | null;
    fixedAllowances?: Array<{
      __typename?: 'FixedAllowance';
      id?: string | null;
      amount?: number | null;
      currency?: CurrencyCode | null;
      label?: string | null;
    }> | null;
    unpaidTimeOffs?: Array<{
      __typename?: 'TimeOffSnapshot';
      timeOffId: string;
      noOfDays?: number | null;
    }> | null;
  } | null;
};

export type GetMemberPayrollCyclesQueryVariables = Exact<{
  filter: MemberPayrollCycleFilter;
}>;

export type GetMemberPayrollCyclesQuery = {
  __typename?: 'Query';
  memberPayrollCycles?: {
    __typename?: 'MemberPayrollCyclesResponse';
    payrollCycleDetails?: Array<{
      __typename?: 'MemberPayrollCycleDetail';
      payrollCycle?: {
        __typename?: 'PayrollCycle';
        id: string;
        configId?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        frequency?: PayFrequency | null;
      } | null;
      memberPay?:
        | {
            __typename?: 'CompanyMemberPay';
            amountGross?: number | null;
            currency?: CurrencyCode | null;
            amountNet?: number | null;
            totalDeductionsAmount?: number | null;
          }
        | {
            __typename?: 'PartnerMemberPay';
            amountGross?: number | null;
            currency?: CurrencyCode | null;
            amountNet?: number | null;
            totalDeductionsAmount?: number | null;
          }
        | null;
    }> | null;
  } | null;
};

export type GetPayrollDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
  payslipMonth: MonthYearInput;
}>;

export type GetPayrollDetailsQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    isPickedUpForPayroll?: boolean | null;
  } | null;
};

export type GetPayslipsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPayslipsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      payslips?: Array<{
        __typename?: 'Payslip';
        id?: string | null;
        payrollCycle?: number | null;
        netAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        createdOn?: any | null;
        month?: {
          __typename?: 'PayrollMonth';
          month?: number | null;
          year?: number | null;
        } | null;
        payslipDocument?: {
          __typename?: 'PayslipDocument';
          downloadUrl?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DeletePerformanceReviewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePerformanceReviewMutation = {
  __typename?: 'Mutation';
  deletePerformanceReview?: Array<{
    __typename?: 'PerformanceReview';
    id: string;
    status?: PerformanceReviewStatus | null;
    effectiveDate?: any | null;
    createdOn?: any | null;
    comment?: string | null;
    companySignedOn?: any | null;
    memberSignedOn?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      position?: string | null;
      type?: ContractType | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        legalData?: Array<{
          __typename?: 'LegalData';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
        | {
            __typename?: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
          }
        | null;
    } | null;
    salaryReview?: {
      __typename?: 'SalaryReview';
      id: string;
      state?: SalaryReviewStatus | null;
      comment?: string | null;
      createdOn?: any | null;
      effectiveDate?: any | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        position?: string | null;
        type?: ContractType | null;
        term?: ContractTerm | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      revisedCompensation?: {
        __typename?: 'Compensation';
        payType?: PayType | null;
        additional?: string | null;
        payrollStart?: any | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        probationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        postProbationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
      currentCompensation?: {
        __typename?: 'Compensation';
        payType?: PayType | null;
        additional?: string | null;
        payrollStart?: any | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
    } | null;
    designation?: {
      __typename?: 'Designation';
      name?: string | null;
      jobDescription?: string | null;
    } | null;
    previousDesignation?: {
      __typename?: 'Designation';
      name?: string | null;
      jobDescription?: string | null;
    } | null;
  } | null> | null;
};

export type GetCompanySignatoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanySignatoriesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    signatories?: Array<{
      __typename?: 'CompanyUser';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      title?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetContractPositionAndPayQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractPositionAndPayQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    position?: string | null;
    scope?: string | null;
    term?: ContractTerm | null;
    type?: ContractType | null;
    currency?: CurrencyCode | null;
    startOn?: any | null;
    country?: CountryCode | null;
    countryStateCode?: string | null;
    legalEntityId?: string | null;
    compensation?: {
      __typename?: 'Compensation';
      basePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      postProbationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      probationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      additionalPays?: Array<
        | {
            __typename?: 'FixedPayComponent';
            rateType?: RateType | null;
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            instalments?: Array<{
              __typename?: 'Instalment';
              amount?: number | null;
              currency?: CurrencyCode | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }> | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | {
            __typename?: 'PercentPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | null
      > | null;
    } | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      emails?: Array<{
        __typename?: 'EmailAddress';
        email?: string | null;
      } | null> | null;
      legalData?: Array<{
        __typename?: 'LegalData';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    compliance?:
      | { __typename?: 'ComplianceContractor'; type?: LegalBindingType | null }
      | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
        }
      | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
      | { __typename?: 'CompliancePartnerEOR'; type?: LegalBindingType | null }
      | null;
  } | null;
};

export type GetPerformanceReviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPerformanceReviewQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    performanceReviews?: Array<{
      __typename?: 'PerformanceReview';
      id: string;
      status?: PerformanceReviewStatus | null;
      effectiveDate?: any | null;
      createdOn?: any | null;
      comment?: string | null;
      companySignedOn?: any | null;
      memberSignedOn?: any | null;
      payrollCycle?: {
        __typename?: 'PayrollCycle';
        id: string;
        configId?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        status?: PayrollCycleStatus | null;
      } | null;
      reviewDocument?: {
        __typename?: 'DocumentReadable';
        link?: string | null;
        blob?: string | null;
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        position?: string | null;
        type?: ContractType | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          legalData?: Array<{
            __typename?: 'LegalData';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
        compliance?:
          | {
              __typename?: 'ComplianceContractor';
              type?: LegalBindingType | null;
            }
          | {
              __typename?: 'ComplianceFreelance';
              type?: LegalBindingType | null;
            }
          | {
              __typename?: 'ComplianceMultiplierEOR';
              type?: LegalBindingType | null;
            }
          | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
          | {
              __typename?: 'CompliancePartnerEOR';
              type?: LegalBindingType | null;
            }
          | null;
      } | null;
      salaryReview?: {
        __typename?: 'SalaryReview';
        id: string;
        state?: SalaryReviewStatus | null;
        comment?: string | null;
        createdOn?: any | null;
        effectiveDate?: any | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          position?: string | null;
          type?: ContractType | null;
          term?: ContractTerm | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        } | null;
        revisedCompensation?: {
          __typename?: 'Compensation';
          payType?: PayType | null;
          additional?: string | null;
          payrollStart?: any | null;
          basePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          probationBasePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          postProbationBasePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          additionalPays?: Array<
            | {
                __typename?: 'FixedPayComponent';
                rateType?: RateType | null;
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                instalments?: Array<{
                  __typename?: 'Instalment';
                  amount?: number | null;
                  currency?: CurrencyCode | null;
                  payOn?: {
                    __typename?: 'MonthYear';
                    year?: number | null;
                    month?: number | null;
                  } | null;
                }> | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | {
                __typename?: 'PercentPayComponent';
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | null
          > | null;
        } | null;
        currentCompensation?: {
          __typename?: 'Compensation';
          payType?: PayType | null;
          additional?: string | null;
          payrollStart?: any | null;
          basePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          additionalPays?: Array<
            | {
                __typename?: 'FixedPayComponent';
                rateType?: RateType | null;
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                instalments?: Array<{
                  __typename?: 'Instalment';
                  amount?: number | null;
                  currency?: CurrencyCode | null;
                  payOn?: {
                    __typename?: 'MonthYear';
                    year?: number | null;
                    month?: number | null;
                  } | null;
                }> | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | {
                __typename?: 'PercentPayComponent';
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | null
          > | null;
        } | null;
      } | null;
      designation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
      previousDesignation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PerformanceReviewFragment = {
  __typename?: 'PerformanceReview';
  id: string;
  status?: PerformanceReviewStatus | null;
  effectiveDate?: any | null;
  createdOn?: any | null;
  comment?: string | null;
  companySignedOn?: any | null;
  memberSignedOn?: any | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    country?: CountryCode | null;
    position?: string | null;
    type?: ContractType | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      legalData?: Array<{
        __typename?: 'LegalData';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    compliance?:
      | { __typename?: 'ComplianceContractor'; type?: LegalBindingType | null }
      | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
        }
      | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
      | { __typename?: 'CompliancePartnerEOR'; type?: LegalBindingType | null }
      | null;
  } | null;
  salaryReview?: {
    __typename?: 'SalaryReview';
    id: string;
    state?: SalaryReviewStatus | null;
    comment?: string | null;
    createdOn?: any | null;
    effectiveDate?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      position?: string | null;
      type?: ContractType | null;
      term?: ContractTerm | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null;
    revisedCompensation?: {
      __typename?: 'Compensation';
      payType?: PayType | null;
      additional?: string | null;
      payrollStart?: any | null;
      basePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      probationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      postProbationBasePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      additionalPays?: Array<
        | {
            __typename?: 'FixedPayComponent';
            rateType?: RateType | null;
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            instalments?: Array<{
              __typename?: 'Instalment';
              amount?: number | null;
              currency?: CurrencyCode | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }> | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | {
            __typename?: 'PercentPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | null
      > | null;
    } | null;
    currentCompensation?: {
      __typename?: 'Compensation';
      payType?: PayType | null;
      additional?: string | null;
      payrollStart?: any | null;
      basePay?: {
        __typename?: 'FixedPayComponent';
        name?: string | null;
        label?: string | null;
        amount?: number | null;
        currency?: CurrencyCode | null;
        frequency?: RateFrequency | null;
        rateType?: RateType | null;
        paymentFrequency?: PayFrequency | null;
        validFromInclusive?: any | null;
        validToExclusive?: any | null;
        paymentFrequencyDate?: Array<{
          __typename?: 'PayFrequencyDate';
          identifier?: PayFrequencyDateIdentifier | null;
          dateOfMonth?: number | null;
          dayOfWeek?: DayOfWeek | null;
        } | null> | null;
      } | null;
      additionalPays?: Array<
        | {
            __typename?: 'FixedPayComponent';
            rateType?: RateType | null;
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            instalments?: Array<{
              __typename?: 'Instalment';
              amount?: number | null;
              currency?: CurrencyCode | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
            }> | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | {
            __typename?: 'PercentPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            amountType?: PayAmountType | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            isDeletable?: boolean | null;
            condition?: string | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
            paySchedule?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
          }
        | null
      > | null;
    } | null;
  } | null;
  designation?: {
    __typename?: 'Designation';
    name?: string | null;
    jobDescription?: string | null;
  } | null;
  previousDesignation?: {
    __typename?: 'Designation';
    name?: string | null;
    jobDescription?: string | null;
  } | null;
};

export type SalaryReviewFragment = {
  __typename?: 'SalaryReview';
  id: string;
  state?: SalaryReviewStatus | null;
  comment?: string | null;
  createdOn?: any | null;
  effectiveDate?: any | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    country?: CountryCode | null;
    position?: string | null;
    type?: ContractType | null;
    term?: ContractTerm | null;
    member?: {
      __typename?: 'Member';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
  revisedCompensation?: {
    __typename?: 'Compensation';
    payType?: PayType | null;
    additional?: string | null;
    payrollStart?: any | null;
    basePay?: {
      __typename?: 'FixedPayComponent';
      name?: string | null;
      label?: string | null;
      amount?: number | null;
      currency?: CurrencyCode | null;
      frequency?: RateFrequency | null;
      rateType?: RateType | null;
      paymentFrequency?: PayFrequency | null;
      validFromInclusive?: any | null;
      validToExclusive?: any | null;
      paymentFrequencyDate?: Array<{
        __typename?: 'PayFrequencyDate';
        identifier?: PayFrequencyDateIdentifier | null;
        dateOfMonth?: number | null;
        dayOfWeek?: DayOfWeek | null;
      } | null> | null;
    } | null;
    probationBasePay?: {
      __typename?: 'FixedPayComponent';
      name?: string | null;
      label?: string | null;
      amount?: number | null;
      currency?: CurrencyCode | null;
      frequency?: RateFrequency | null;
      rateType?: RateType | null;
      paymentFrequency?: PayFrequency | null;
      validFromInclusive?: any | null;
      validToExclusive?: any | null;
      paymentFrequencyDate?: Array<{
        __typename?: 'PayFrequencyDate';
        identifier?: PayFrequencyDateIdentifier | null;
        dateOfMonth?: number | null;
        dayOfWeek?: DayOfWeek | null;
      } | null> | null;
    } | null;
    postProbationBasePay?: {
      __typename?: 'FixedPayComponent';
      name?: string | null;
      label?: string | null;
      amount?: number | null;
      currency?: CurrencyCode | null;
      frequency?: RateFrequency | null;
      rateType?: RateType | null;
      paymentFrequency?: PayFrequency | null;
      validFromInclusive?: any | null;
      validToExclusive?: any | null;
      paymentFrequencyDate?: Array<{
        __typename?: 'PayFrequencyDate';
        identifier?: PayFrequencyDateIdentifier | null;
        dateOfMonth?: number | null;
        dayOfWeek?: DayOfWeek | null;
      } | null> | null;
    } | null;
    additionalPays?: Array<
      | {
          __typename?: 'FixedPayComponent';
          rateType?: RateType | null;
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          amountType?: PayAmountType | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          isDeletable?: boolean | null;
          condition?: string | null;
          instalments?: Array<{
            __typename?: 'Instalment';
            amount?: number | null;
            currency?: CurrencyCode | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
          }> | null;
          payOn?: {
            __typename?: 'MonthYear';
            year?: number | null;
            month?: number | null;
          } | null;
          paySchedule?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
        }
      | {
          __typename?: 'PercentPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          amountType?: PayAmountType | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          isDeletable?: boolean | null;
          condition?: string | null;
          payOn?: {
            __typename?: 'MonthYear';
            year?: number | null;
            month?: number | null;
          } | null;
          paySchedule?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
        }
      | null
    > | null;
  } | null;
  currentCompensation?: {
    __typename?: 'Compensation';
    payType?: PayType | null;
    additional?: string | null;
    payrollStart?: any | null;
    basePay?: {
      __typename?: 'FixedPayComponent';
      name?: string | null;
      label?: string | null;
      amount?: number | null;
      currency?: CurrencyCode | null;
      frequency?: RateFrequency | null;
      rateType?: RateType | null;
      paymentFrequency?: PayFrequency | null;
      validFromInclusive?: any | null;
      validToExclusive?: any | null;
      paymentFrequencyDate?: Array<{
        __typename?: 'PayFrequencyDate';
        identifier?: PayFrequencyDateIdentifier | null;
        dateOfMonth?: number | null;
        dayOfWeek?: DayOfWeek | null;
      } | null> | null;
    } | null;
    additionalPays?: Array<
      | {
          __typename?: 'FixedPayComponent';
          rateType?: RateType | null;
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          amountType?: PayAmountType | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          isDeletable?: boolean | null;
          condition?: string | null;
          instalments?: Array<{
            __typename?: 'Instalment';
            amount?: number | null;
            currency?: CurrencyCode | null;
            payOn?: {
              __typename?: 'MonthYear';
              year?: number | null;
              month?: number | null;
            } | null;
          }> | null;
          payOn?: {
            __typename?: 'MonthYear';
            year?: number | null;
            month?: number | null;
          } | null;
          paySchedule?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
        }
      | {
          __typename?: 'PercentPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          amountType?: PayAmountType | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          isDeletable?: boolean | null;
          condition?: string | null;
          payOn?: {
            __typename?: 'MonthYear';
            year?: number | null;
            month?: number | null;
          } | null;
          paySchedule?: {
            __typename?: 'ScheduleTime';
            value: number;
            unit: ScheduleTimeUnit;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type GetPerformanceReviewsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPerformanceReviewsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    performanceReviews?: Array<{
      __typename?: 'PerformanceReview';
      id: string;
      status?: PerformanceReviewStatus | null;
      effectiveDate?: any | null;
      createdOn?: any | null;
      comment?: string | null;
      companySignedOn?: any | null;
      memberSignedOn?: any | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        position?: string | null;
        type?: ContractType | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          legalData?: Array<{
            __typename?: 'LegalData';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
        compliance?:
          | {
              __typename?: 'ComplianceContractor';
              type?: LegalBindingType | null;
            }
          | {
              __typename?: 'ComplianceFreelance';
              type?: LegalBindingType | null;
            }
          | {
              __typename?: 'ComplianceMultiplierEOR';
              type?: LegalBindingType | null;
            }
          | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
          | {
              __typename?: 'CompliancePartnerEOR';
              type?: LegalBindingType | null;
            }
          | null;
      } | null;
      salaryReview?: {
        __typename?: 'SalaryReview';
        id: string;
        state?: SalaryReviewStatus | null;
        comment?: string | null;
        createdOn?: any | null;
        effectiveDate?: any | null;
        contract?: {
          __typename?: 'Contract';
          id?: string | null;
          country?: CountryCode | null;
          position?: string | null;
          type?: ContractType | null;
          term?: ContractTerm | null;
          member?: {
            __typename?: 'Member';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        } | null;
        revisedCompensation?: {
          __typename?: 'Compensation';
          payType?: PayType | null;
          additional?: string | null;
          payrollStart?: any | null;
          basePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          probationBasePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          postProbationBasePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          additionalPays?: Array<
            | {
                __typename?: 'FixedPayComponent';
                rateType?: RateType | null;
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                instalments?: Array<{
                  __typename?: 'Instalment';
                  amount?: number | null;
                  currency?: CurrencyCode | null;
                  payOn?: {
                    __typename?: 'MonthYear';
                    year?: number | null;
                    month?: number | null;
                  } | null;
                }> | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | {
                __typename?: 'PercentPayComponent';
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | null
          > | null;
        } | null;
        currentCompensation?: {
          __typename?: 'Compensation';
          payType?: PayType | null;
          additional?: string | null;
          payrollStart?: any | null;
          basePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          additionalPays?: Array<
            | {
                __typename?: 'FixedPayComponent';
                rateType?: RateType | null;
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                instalments?: Array<{
                  __typename?: 'Instalment';
                  amount?: number | null;
                  currency?: CurrencyCode | null;
                  payOn?: {
                    __typename?: 'MonthYear';
                    year?: number | null;
                    month?: number | null;
                  } | null;
                }> | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | {
                __typename?: 'PercentPayComponent';
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | null
          > | null;
        } | null;
      } | null;
      designation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
      previousDesignation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetTeamCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeamCountQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
    } | null> | null;
  } | null;
};

export type SendPerformanceReviewToSignatoryMutationVariables = Exact<{
  reviewId: Scalars['ID'];
}>;

export type SendPerformanceReviewToSignatoryMutation = {
  __typename?: 'Mutation';
  sendToSignatoryPerformanceReview?: {
    __typename?: 'PerformanceReview';
    id: string;
    status?: PerformanceReviewStatus | null;
    effectiveDate?: any | null;
    createdOn?: any | null;
    comment?: string | null;
    companySignedOn?: any | null;
    memberSignedOn?: any | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      position?: string | null;
      type?: ContractType | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        legalData?: Array<{
          __typename?: 'LegalData';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
        | {
            __typename?: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
          }
        | null;
    } | null;
    reviewDocument?: {
      __typename?: 'DocumentReadable';
      link?: string | null;
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
    salaryReview?: {
      __typename?: 'SalaryReview';
      id: string;
      state?: SalaryReviewStatus | null;
      comment?: string | null;
      createdOn?: any | null;
      effectiveDate?: any | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        position?: string | null;
        type?: ContractType | null;
        term?: ContractTerm | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      revisedCompensation?: {
        __typename?: 'Compensation';
        payType?: PayType | null;
        additional?: string | null;
        payrollStart?: any | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        probationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        postProbationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
      currentCompensation?: {
        __typename?: 'Compensation';
        payType?: PayType | null;
        additional?: string | null;
        payrollStart?: any | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
    } | null;
    designation?: {
      __typename?: 'Designation';
      name?: string | null;
      jobDescription?: string | null;
    } | null;
    previousDesignation?: {
      __typename?: 'Designation';
      name?: string | null;
      jobDescription?: string | null;
    } | null;
  } | null;
};

export type UpsertPerformanceReviewMutationVariables = Exact<{
  input: PerformanceReviewInput;
}>;

export type UpsertPerformanceReviewMutation = {
  __typename?: 'Mutation';
  upsertPerformanceReview?: {
    __typename?: 'PerformanceReview';
    id: string;
    status?: PerformanceReviewStatus | null;
    effectiveDate?: any | null;
    createdOn?: any | null;
    comment?: string | null;
    companySignedOn?: any | null;
    memberSignedOn?: any | null;
    reviewDocument?: {
      __typename?: 'DocumentReadable';
      link?: string | null;
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      position?: string | null;
      type?: ContractType | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        legalData?: Array<{
          __typename?: 'LegalData';
          key?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
          }
        | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
        | {
            __typename?: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
          }
        | null;
    } | null;
    salaryReview?: {
      __typename?: 'SalaryReview';
      id: string;
      state?: SalaryReviewStatus | null;
      comment?: string | null;
      createdOn?: any | null;
      effectiveDate?: any | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        country?: CountryCode | null;
        position?: string | null;
        type?: ContractType | null;
        term?: ContractTerm | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      revisedCompensation?: {
        __typename?: 'Compensation';
        payType?: PayType | null;
        additional?: string | null;
        payrollStart?: any | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        probationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        postProbationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
      currentCompensation?: {
        __typename?: 'Compensation';
        payType?: PayType | null;
        additional?: string | null;
        payrollStart?: any | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
    } | null;
    designation?: {
      __typename?: 'Designation';
      name?: string | null;
      jobDescription?: string | null;
    } | null;
    previousDesignation?: {
      __typename?: 'Designation';
      name?: string | null;
      jobDescription?: string | null;
    } | null;
  } | null;
};

export type GetPublicExpenseQueryVariables = Exact<{
  expenseId: Scalars['ID'];
}>;

export type GetPublicExpenseQuery = {
  __typename?: 'Query';
  expense?: {
    __typename?: 'Expense';
    id?: string | null;
    title?: string | null;
    currency?: CurrencyCode | null;
    totalAmount?: number | null;
    items?: Array<{
      __typename?: 'ExpenseItem';
      receipts?: Array<{
        __typename?: 'DocumentReadable';
        id?: string | null;
        name?: string | null;
        contentType?: string | null;
        link?: string | null;
        size?: number | null;
      } | null> | null;
      receiptsInForeignCurrency?: Array<{
        __typename?: 'DocumentReadable';
        id?: string | null;
        name?: string | null;
        contentType?: string | null;
        link?: string | null;
        size?: number | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetPublicIsrByLinkQueryVariables = Exact<{
  input: InvoiceSourceReportFromLinkInput;
}>;

export type GetPublicIsrByLinkQuery = {
  __typename?: 'Query';
  companyPayableDownloadInvoiceSourceReportFromLink: {
    __typename?: 'DocumentReadable';
    blob?: string | null;
    extension?: string | null;
    name?: string | null;
    contentType?: string | null;
  };
};

export type PlaceholderQueryVariables = Exact<{ [key: string]: never }>;

export type PlaceholderQuery = { __typename: 'Query' };

export type DownloadCustomPayrollReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadCustomPayrollReportQuery = {
  __typename?: 'Query';
  downloadCustomPayrollReport: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    blob?: string | null;
    extension?: string | null;
    name?: string | null;
  };
};

export type GetAvailableReportsQueryVariables = Exact<{
  input?: InputMaybe<ReportId>;
}>;

export type GetAvailableReportsQuery = {
  __typename?: 'Query';
  getAvailableReports: Array<{
    __typename?: 'ReportConfig';
    title?: string | null;
    description?: string | null;
    id?: string | null;
    reportSource?: ReportSource | null;
  } | null>;
};

export type GetCompanyEntitiesForReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompanyEntitiesForReportQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    primaryEntity?: {
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null;
    otherEntities?: Array<{
      __typename?: 'LegalEntity';
      id?: string | null;
      legalName?: string | null;
      address?: { __typename?: 'Address'; country?: CountryCode | null } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyStatutoryReportsQueryVariables = Exact<{
  filter: CustomPayrollReportFilter;
}>;

export type GetCompanyStatutoryReportsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    statutoryReports?: Array<{
      __typename?: 'StatutoryReportDetails';
      id: string;
      reportFrequency?: CustomPayrollReportFrequency | null;
      quarter?: number | null;
      month?: {
        __typename?: 'MonthYear';
        month?: number | null;
        year?: number | null;
      } | null;
      files: Array<{
        __typename?: 'CustomPayrollReportFile';
        config: {
          __typename?: 'CustomPayrollReportConfig';
          id: string;
          reportCode: string;
          reportName: string;
        };
        file: {
          __typename?: 'PayrollFile';
          id?: string | null;
          downloadUrl?: string | null;
        };
      }>;
    }> | null;
  } | null;
};

export type GetCountryFiscalYearsQueryVariables = Exact<{
  country: CountryCode;
  years?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetCountryFiscalYearsQuery = {
  __typename?: 'Query';
  country?: {
    __typename?: 'Country';
    code?: CountryCode | null;
    fiscalYears?: Array<{
      __typename?: 'FiscalYear';
      fiscalYear?: number | null;
      startDate?: any | null;
      endDate?: any | null;
    } | null> | null;
  } | null;
};

export type GetExpenseReportQueryVariables = Exact<{
  input: ExpenseReportInput;
}>;

export type GetExpenseReportQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    expenseReport?: {
      __typename?: 'DocumentReadable';
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
  } | null;
};

export type GetPayrollCyclesForReportQueryVariables = Exact<{
  filter: CompanyPayrollCycleFilter;
}>;

export type GetPayrollCyclesForReportQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    companyPayrollCycles?: Array<{
      __typename?: 'CompanyPayrollCycle';
      id: string;
      startDate?: any | null;
      endDate?: any | null;
      payrollCycles?: Array<{ __typename?: 'PayrollCycle'; id: string }> | null;
    }> | null;
  } | null;
};

export type GetPayrollReportQueryVariables = Exact<{
  input: CompanyPayrollReportExportInput;
}>;

export type GetPayrollReportQuery = {
  __typename?: 'Query';
  payrollReportDownload?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    name?: string | null;
    extension?: string | null;
    contentType?: string | null;
    blob?: string | null;
  } | null;
};

export type GetReportConfigurationQueryVariables = Exact<{
  input?: InputMaybe<ReportId>;
}>;

export type GetReportConfigurationQuery = {
  __typename?: 'Query';
  getAvailableReports: Array<{
    __typename?: 'ReportConfig';
    title?: string | null;
    description?: string | null;
    id?: string | null;
    reportSource?: ReportSource | null;
    filters: Array<{
      __typename?: 'Filter';
      parameterName?: string | null;
      parameterTitle?: string | null;
      isMandatory?: boolean | null;
      possibleValues: Array<string | null>;
      type?: FilterTypes | null;
    } | null>;
  } | null>;
};

export type GetReportQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;

export type GetReportQuery = { __typename?: 'Query'; getReport: string };

export type GetTimeOffBalanceReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTimeOffBalanceReportQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timeOffBalanceReport?: {
      __typename?: 'DocumentReadable';
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
  } | null;
};

export type GetTimeOffReportQueryVariables = Exact<{
  input: TimeOffReportInput;
}>;

export type GetTimeOffReportQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timeOffReport?: {
      __typename?: 'DocumentReadable';
      blob?: string | null;
      id?: string | null;
      name?: string | null;
      extension?: string | null;
      contentType?: string | null;
    } | null;
  } | null;
};

export type PayrollReportDownloadForCustomerQueryVariables = Exact<{
  input: Scalars['String'];
}>;

export type PayrollReportDownloadForCustomerQuery = {
  __typename?: 'Query';
  payrollReportDownloadForCustomer?: {
    __typename?: 'DocumentReadable';
    id?: string | null;
    blob?: string | null;
    extension?: string | null;
    name?: string | null;
  } | null;
};

export type GetCountriesQueryVariables = Exact<{
  country?: InputMaybe<CountryCode>;
  mainCurrency?: InputMaybe<CurrencyCode>;
}>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries?: Array<{
    __typename?: 'Country';
    name?: string | null;
    code?: CountryCode | null;
    region?: string | null;
    mainCurrency?: CurrencyCode | null;
    supportedCurrencies?: Array<CurrencyCode | null> | null;
    compliantCurrencies?: Array<CurrencyCode | null> | null;
  } | null> | null;
};

export type GetSalaryCalculationMinimumWageQueryVariables = Exact<{
  params: SalaryCalculationMinimumWageParam;
}>;

export type GetSalaryCalculationMinimumWageQuery = {
  __typename?: 'Query';
  salaryCalculationMinimumWage?: {
    __typename?: 'SalaryCalculationMinimumWage';
    currency: CurrencyCode;
    amount: number;
    frequency: RateFrequency;
  } | null;
};

export type GetSalaryCalculationParamsCountryQueryVariables = Exact<{
  country: CountryCode;
}>;

export type GetSalaryCalculationParamsCountryQuery = {
  __typename?: 'Query';
  salaryCalculationParams?: Array<{
    __typename?: 'AvailableSalaryCalculationParams';
    country: CountryCode;
    states: Array<string>;
    rateType: Array<RateType>;
    currency: Array<CurrencyCode>;
    frequency: Array<RateFrequency>;
    payableType: Array<CalculationPayableType>;
  }> | null;
};

export type GetSalaryCalculationParamsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSalaryCalculationParamsQuery = {
  __typename?: 'Query';
  salaryCalculationParams?: Array<{
    __typename?: 'AvailableSalaryCalculationParams';
    country: CountryCode;
    states: Array<string>;
    rateType: Array<RateType>;
    currency: Array<CurrencyCode>;
    frequency: Array<RateFrequency>;
    payableType: Array<CalculationPayableType>;
  }> | null;
};

export type GetSalaryCalculationPdfQueryVariables = Exact<{
  params?: InputMaybe<SalaryCalculationParam>;
}>;

export type GetSalaryCalculationPdfQuery = {
  __typename?: 'Query';
  salaryCalculationPdf?: {
    __typename?: 'FileLink';
    link?: string | null;
    contentType?: string | null;
    name?: string | null;
  } | null;
};

export type GetSalaryCalculationQueryVariables = Exact<{
  params?: InputMaybe<SalaryCalculationParam>;
}>;

export type GetSalaryCalculationQuery = {
  __typename?: 'Query';
  salaryCalculation?: {
    __typename?: 'SalaryCalculation';
    companyPayable: Array<{
      __typename?: 'CalculationBreakdown';
      name: string;
      frequency?: RateFrequency | null;
      entries: Array<{
        __typename?: 'CalculationEntryGroup';
        name: string;
        currency?: CurrencyCode | null;
        amount: number;
        note?: string | null;
        entries: Array<{
          __typename?: 'CalculationEntry';
          name: string;
          currency?: CurrencyCode | null;
          amount: number;
          note?: string | null;
        }>;
      }>;
      total: {
        __typename?: 'CalculationEntry';
        name: string;
        currency?: CurrencyCode | null;
        amount: number;
        note?: string | null;
      };
    }>;
    employeePayable: Array<{
      __typename?: 'CalculationBreakdown';
      name: string;
      frequency?: RateFrequency | null;
      entries: Array<{
        __typename?: 'CalculationEntryGroup';
        name: string;
        currency?: CurrencyCode | null;
        amount: number;
        note?: string | null;
        entries: Array<{
          __typename?: 'CalculationEntry';
          name: string;
          currency?: CurrencyCode | null;
          amount: number;
          note?: string | null;
        }>;
      }>;
      total: {
        __typename?: 'CalculationEntry';
        name: string;
        currency?: CurrencyCode | null;
        amount: number;
        note?: string | null;
      };
    }>;
  } | null;
};

export type CalculationBreakdownFragment = {
  __typename?: 'CalculationBreakdown';
  name: string;
  frequency?: RateFrequency | null;
  entries: Array<{
    __typename?: 'CalculationEntryGroup';
    name: string;
    currency?: CurrencyCode | null;
    amount: number;
    note?: string | null;
    entries: Array<{
      __typename?: 'CalculationEntry';
      name: string;
      currency?: CurrencyCode | null;
      amount: number;
      note?: string | null;
    }>;
  }>;
  total: {
    __typename?: 'CalculationEntry';
    name: string;
    currency?: CurrencyCode | null;
    amount: number;
    note?: string | null;
  };
};

export type SendSalaryCalculationEmailMutationVariables = Exact<{
  emails?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  params?: InputMaybe<SalaryCalculationParam>;
}>;

export type SendSalaryCalculationEmailMutation = {
  __typename?: 'Mutation';
  salaryCalculationEmail?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type SalaryCalculatorRequestEmailMutationVariables = Exact<{
  params?: InputMaybe<SalaryCalculationRequestEmailParam>;
}>;

export type SalaryCalculatorRequestEmailMutation = {
  __typename?: 'Mutation';
  salaryCalculatorRequestEmail?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type ApproveChangeRequestMutationVariables = Exact<{
  itemId: Scalars['ID'];
  category: ApprovalCategory;
  comment?: InputMaybe<Scalars['String']>;
}>;

export type ApproveChangeRequestMutation = {
  __typename?: 'Mutation';
  approveItem?:
    | {
        __typename?: 'ExpenseApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'LegalDocumentsApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'MemberDataApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'MemberPayableApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'TimeOffApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | null;
};

export type ContractOffboardingCancelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ContractOffboardingCancelMutation = {
  __typename?: 'Mutation';
  contractOffboardingCancel?: {
    __typename?: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    offboardingNote?: string | null;
    lastWorkingDay?: any | null;
    endedOn?: any | null;
    endOn?: any | null;
    type?: ContractType | null;
  } | null;
};

export type ContractAgreementReplaceMutationVariables = Exact<{
  contractId: Scalars['ID'];
  contract: Scalars['Upload'];
}>;

export type ContractAgreementReplaceMutation = {
  __typename?: 'Mutation';
  contractAgreementReplace?: {
    __typename?: 'Contract';
    id?: string | null;
    compliance?:
      | {
          __typename?: 'ComplianceContractor';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
        }
      | {
          __typename?: 'ComplianceFreelance';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
        }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
        }
      | {
          __typename?: 'CompliancePEO';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
        }
      | {
          __typename?: 'CompliancePartnerEOR';
          contractAgreement?: {
            __typename?: 'DocumentReadable';
            id?: string | null;
            name?: string | null;
          } | null;
        }
      | null;
  } | null;
};

export type ContractOffboardMutationVariables = Exact<{
  id: Scalars['ID'];
  lastWorkingDay: Scalars['Date'];
  offboardingNote: Scalars['String'];
}>;

export type ContractOffboardMutation = {
  __typename?: 'Mutation';
  contractOffboard?: {
    __typename?: 'Contract';
    id?: string | null;
    status?: ContractStatus | null;
    offboardingNote?: string | null;
    lastWorkingDay?: any | null;
    endedOn?: any | null;
    endOn?: any | null;
    type?: ContractType | null;
  } | null;
};

export type ContractUpdateComplianceMutationVariables = Exact<{
  contractId: Scalars['ID'];
  input: ContractUpdateComplianceInput;
}>;

export type ContractUpdateComplianceMutation = {
  __typename?: 'Mutation';
  contractUpdateCompliance?: {
    __typename?: 'Contract';
    id?: string | null;
    compliance?:
      | {
          __typename?: 'ComplianceContractor';
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'ComplianceFreelance';
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'CompliancePEO';
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'CompliancePartnerEOR';
          complianceParams?: Array<{
            __typename?: 'ComplianceParamPeriodLimit';
            description?: string | null;
            value?: number | null;
            unit?: ComplianceParamPeriodUnit | null;
            key?: string | null;
            label?: string | null;
          } | null> | null;
        }
      | null;
  } | null;
};

export type ContractUpdateEmploymentMutationVariables = Exact<{
  contractId: Scalars['ID'];
  input: ContractUpdateEmploymentInput;
}>;

export type ContractUpdateEmploymentMutation = {
  __typename?: 'Mutation';
  contractUpdateEmployment?: {
    __typename?: 'Contract';
    id?: string | null;
    startOn?: any | null;
    endOn?: any | null;
    employeeId?: string | null;
  } | null;
};

export type DeleteMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteMemberMutation = {
  __typename?: 'Mutation';
  memberDelete?: { __typename?: 'Member'; id?: string | null } | null;
};

export type GetCompanyUsersRoleQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyUsersRoleQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    companyUsers?: Array<{
      __typename?: 'CompanyUser';
      userId?: string | null;
      roles?: Array<CompanyUserRole | null> | null;
    }> | null;
  } | null;
};

export type GetComponentAccessibilityRulesQueryVariables = Exact<{
  contractId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<MemberExperiencePage>;
}>;

export type GetComponentAccessibilityRulesQuery = {
  __typename?: 'Query';
  getComponentAccessibilityRules?: {
    __typename?: 'ComponentAccessibilityResponse';
    page: MemberExperiencePage;
    sections?: Array<{
      __typename?: 'PageSection';
      editable?: boolean | null;
      name?: Section | null;
      reason?: string | null;
      rules?: Array<{
        __typename?: 'FieldAccessibilityRule';
        visible?: boolean | null;
        field?: string | null;
        editable?: boolean | null;
        reason?: string | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetContractBenefitInsurancePremiumQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractBenefitInsurancePremiumQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    benefits?: Array<{
      __typename?: 'ContractBenefit';
      id?: string | null;
      insurancePremium?: {
        __typename?: 'InsurancePremium';
        benefitType?: BenefitType | null;
        billingDuration?: BenefitPartnerBillingFrequency | null;
        employeePayAmount?: number | null;
        employeePayPercentage?: number | null;
        employerPayAmount?: number | null;
        employerPayPercentage?: number | null;
        totalPlatformFee?: number | null;
        totalPremium?: number | null;
        dependents?: Array<{
          __typename?: 'InsuranceIndividualPremium';
          billingPeriodInMonths?: number | null;
          endOn?: any | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          platformFee?: number | null;
          startOn?: any | null;
          subTotalAmount?: number | null;
          subTotalPlatformFee?: number | null;
          benefitPackageCost?: {
            __typename?: 'BenefitPackageCost';
            id: string;
            premium?: number | null;
            premiumCurrency?: CurrencyCode | null;
            premiumFrequency?: BenefitPremiumFrequency | null;
          } | null;
        } | null> | null;
        self?: {
          __typename?: 'InsuranceIndividualPremium';
          subTotalAmount?: number | null;
          subTotalPlatformFee?: number | null;
          platformFee?: number | null;
          id?: string | null;
          billingPeriodInMonths?: number | null;
          benefitPackageCost?: {
            __typename?: 'BenefitPackageCost';
            premium?: number | null;
            id: string;
            premiumCurrency?: CurrencyCode | null;
            premiumFrequency?: BenefitPremiumFrequency | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetContractPerformanceReviewQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetContractPerformanceReviewQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    type?: ContractType | null;
    performanceReviews?: Array<{
      __typename?: 'PerformanceReview';
      id: string;
      status?: PerformanceReviewStatus | null;
      companySignedOn?: any | null;
      memberSignedOn?: any | null;
      effectiveDate?: any | null;
      createdOn?: any | null;
      comment?: string | null;
      designation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
      salaryReview?: {
        __typename?: 'SalaryReview';
        id: string;
        revisedCompensation?: {
          __typename?: 'Compensation';
          payType?: PayType | null;
          basePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          probationBasePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          postProbationBasePay?: {
            __typename?: 'FixedPayComponent';
            name?: string | null;
            label?: string | null;
            amount?: number | null;
            currency?: CurrencyCode | null;
            frequency?: RateFrequency | null;
            rateType?: RateType | null;
            paymentFrequency?: PayFrequency | null;
            validFromInclusive?: any | null;
            validToExclusive?: any | null;
            paymentFrequencyDate?: Array<{
              __typename?: 'PayFrequencyDate';
              identifier?: PayFrequencyDateIdentifier | null;
              dateOfMonth?: number | null;
              dayOfWeek?: DayOfWeek | null;
            } | null> | null;
          } | null;
          additionalPays?: Array<
            | {
                __typename?: 'FixedPayComponent';
                rateType?: RateType | null;
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                instalments?: Array<{
                  __typename?: 'Instalment';
                  amount?: number | null;
                  currency?: CurrencyCode | null;
                  payOn?: {
                    __typename?: 'MonthYear';
                    year?: number | null;
                    month?: number | null;
                  } | null;
                }> | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | {
                __typename?: 'PercentPayComponent';
                name?: string | null;
                label?: string | null;
                amount?: number | null;
                amountType?: PayAmountType | null;
                currency?: CurrencyCode | null;
                frequency?: RateFrequency | null;
                isDeletable?: boolean | null;
                condition?: string | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
                paySchedule?: {
                  __typename?: 'ScheduleTime';
                  value: number;
                  unit: ScheduleTimeUnit;
                } | null;
              }
            | null
          > | null;
        } | null;
      } | null;
      previousDesignation?: {
        __typename?: 'Designation';
        name?: string | null;
        jobDescription?: string | null;
      } | null;
      reviewDocument?: {
        __typename?: 'DocumentReadable';
        id?: string | null;
        name?: string | null;
        link?: string | null;
        blob?: string | null;
        extension?: string | null;
        contentType?: string | null;
      } | null;
      payrollCycle?: {
        __typename?: 'PayrollCycle';
        id: string;
        configId?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        status?: PayrollCycleStatus | null;
      } | null;
    } | null> | null;
    compliance?:
      | { __typename?: 'ComplianceContractor'; type?: LegalBindingType | null }
      | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
        }
      | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
      | { __typename?: 'CompliancePartnerEOR'; type?: LegalBindingType | null }
      | null;
  } | null;
};

export type GetMemberCompensationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberCompensationQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      compensation?: {
        __typename?: 'Compensation';
        additional?: string | null;
        payrollStart?: any | null;
        payType?: PayType | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        postProbationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        probationBasePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
        grant?: Array<{
          __typename?: 'ESOPGrant';
          id: string;
          condition?: string | null;
          grantDate: any;
          values?: Array<{
            __typename?: 'GrantValue';
            value: number;
            type: GrantValueType;
            currency?: CurrencyCode | null;
          }> | null;
          schedule: {
            __typename?: 'GrantSchedule';
            cliffPeriod?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
            vestingPeriod?: {
              __typename?: 'ScheduleTime';
              value: number;
              unit: ScheduleTimeUnit;
            } | null;
            vestingSchedule?: Array<{
              __typename?: 'VestingSchedule';
              frequency?: VestingFrequency | null;
              time?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
              vestingValue?: {
                __typename?: 'VestingValue';
                value: number;
                type: VestingValueType;
              } | null;
            } | null> | null;
          };
        } | null> | null;
        deductions?: Array<{
          __typename?: 'Deduction';
          deductionDefinitionId: string;
          value: number;
          unit?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetMemberPersonalDetailsAccessibilityRulesQueryVariables = Exact<{
  memberId?: InputMaybe<Scalars['ID']>;
}>;

export type GetMemberPersonalDetailsAccessibilityRulesQuery = {
  __typename?: 'Query';
  getMemberPersonalDetailsAccessibilityRules?: Array<{
    __typename?: 'SectionAccessibilityResponse';
    section: MemberPersonalDetailsSection;
    visible?: boolean | null;
    accessibility?: {
      __typename?: 'AccessibilityResponse';
      editable?: boolean | null;
      reason?: string | null;
      rules?: Array<{
        __typename?: 'FieldAccessibilityRule';
        editable?: boolean | null;
        field?: string | null;
        reason?: string | null;
        visible?: boolean | null;
      }> | null;
    } | null;
  } | null> | null;
};

export type GetPayslipsFileQueryVariables = Exact<{
  contractId: Scalars['ID'];
  payslipId: Scalars['ID'];
}>;

export type GetPayslipsFileQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    payslips?: Array<{
      __typename?: 'Payslip';
      id?: string | null;
      payslipDocument?: {
        __typename?: 'PayslipDocument';
        downloadUrl?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSalaryRevisionNotesQueryVariables = Exact<{
  input?: InputMaybe<PerformanceReviewNoteInput>;
  contractId: Scalars['ID'];
}>;

export type GetSalaryRevisionNotesQuery = {
  __typename?: 'Query';
  performanceReviewNotes?: {
    __typename?: 'PerformanceReviewNote';
    cutOffDate?: any | null;
    month?: number | null;
    year?: number | null;
    type?: PerformanceReviewNoteType | null;
    semimonthlySegment?: SemimonthlySegment | null;
    payrollCyclePayDate?: any | null;
  } | null;
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    type?: ContractType | null;
    legalEntityId?: string | null;
    compliance?:
      | { __typename?: 'ComplianceContractor'; type?: LegalBindingType | null }
      | { __typename?: 'ComplianceFreelance'; type?: LegalBindingType | null }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          type?: LegalBindingType | null;
        }
      | { __typename?: 'CompliancePEO'; type?: LegalBindingType | null }
      | { __typename?: 'CompliancePartnerEOR'; type?: LegalBindingType | null }
      | null;
    compensation?: {
      __typename?: 'Compensation';
      basePay?: {
        __typename?: 'FixedPayComponent';
        paymentFrequency?: PayFrequency | null;
      } | null;
    } | null;
  } | null;
};

export type GetTeamQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeamQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      endOn?: any | null;
      endedOn?: any | null;
      offboardingNote?: string | null;
      type?: ContractType | null;
      term?: ContractTerm | null;
      position?: string | null;
      status?: ContractStatus | null;
      startOn?: any | null;
      country?: CountryCode | null;
      createdOn?: any | null;
      contractStarted?: boolean | null;
      contractBlocked?: boolean | null;
      lastWorkingDay?: any | null;
      member?: {
        __typename?: 'Member';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        status?: MemberStatus | null;
      } | null;
      compensation?: {
        __typename?: 'Compensation';
        basePay?: {
          __typename?: 'FixedPayComponent';
          amount?: number | null;
          frequency?: RateFrequency | null;
          currency?: CurrencyCode | null;
        } | null;
      } | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        id?: string | null;
        status?: ContractOnboardingStatus | null;
        activationCutoff?: any | null;
        tasks?: Array<{
          __typename?: 'OnboardingTask';
          name?: string | null;
          order?: number | null;
          completed?: boolean | null;
          pendingOn?: {
            __typename?: 'OnboardingTaskUser';
            id?: string | null;
            persona?: Persona | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      } | null;
      offboarding?: {
        __typename?: 'ContractOffboarding';
        id?: string | null;
        type?: ContractOffboardingType | null;
        lastWorkingDay?: any | null;
        status?: ContractOffboardingStatus | null;
      } | null;
    } | null> | null;
  } | null;
};

export type RejectChangeRequestMutationVariables = Exact<{
  category: ApprovalCategory;
  itemId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}>;

export type RejectChangeRequestMutation = {
  __typename?: 'Mutation';
  rejectItem?:
    | {
        __typename?: 'ExpenseApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'LegalDocumentsApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'MemberDataApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'MemberPayableApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | {
        __typename?: 'TimeOffApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
      }
    | null;
};

export type UpdateWorkEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  email: EmailAddressInput;
}>;

export type UpdateWorkEmailMutation = {
  __typename?: 'Mutation';
  contractWorkEmailUpdate?: {
    __typename?: 'Contract';
    id?: string | null;
    workEmail?: string | null;
  } | null;
};

export type MemberUpsertAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberAddressInput;
}>;

export type MemberUpsertAddressMutation = {
  __typename?: 'Mutation';
  memberUpsertAddress?: {
    __typename?: 'Member';
    id?: string | null;
    addressDetails?: {
      __typename?: 'MemberAddress';
      currentAddress?: {
        __typename?: 'Address';
        city?: string | null;
        country?: CountryCode | null;
        key?: string | null;
        landmark?: string | null;
        line1?: string | null;
        line2?: string | null;
        postalCode?: string | null;
        province?: string | null;
        state?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
      permanentAddress?: {
        __typename?: 'Address';
        city?: string | null;
        country?: CountryCode | null;
        key?: string | null;
        landmark?: string | null;
        line1?: string | null;
        line2?: string | null;
        postalCode?: string | null;
        province?: string | null;
        state?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
    } | null;
  } | null;
};

export type MemberUpsertBasicInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberBasicInfoInput;
}>;

export type MemberUpsertBasicInfoMutation = {
  __typename?: 'Mutation';
  memberUpsertBasicInfo?: {
    __typename?: 'Member';
    id?: string | null;
    basicInfo?: {
      __typename?: 'MemberBasicInfo';
      firstName?: string | null;
      fullLegalName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
    } | null;
  } | null;
};

export type MemberUpsertContactInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberContactInfoInput;
}>;

export type MemberUpsertContactInfoMutation = {
  __typename?: 'Mutation';
  memberUpsertContactInfo?: {
    __typename?: 'Member';
    id?: string | null;
    contactInfo?: {
      __typename?: 'MemberContactInfo';
      email?: { __typename?: 'EmailAddress'; email?: string | null } | null;
      mobile?: { __typename?: 'PhoneNumber'; phoneNo?: string | null } | null;
      phone?: { __typename?: 'PhoneNumber'; phoneNo?: string | null } | null;
    } | null;
  } | null;
};

export type MemberUpsertEducationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EducationInput;
}>;

export type MemberUpsertEducationMutation = {
  __typename?: 'Mutation';
  memberUpsertEducation?: {
    __typename?: 'Member';
    id?: string | null;
    education?: Array<{
      __typename?: 'Education';
      degree?: string | null;
      gpa?: number | null;
      grade?: string | null;
      lastSchoolAttended?: string | null;
      yearOfPassing?: any | null;
    } | null> | null;
  } | null;
};

export type MemberUpsertEmergencyContactMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EmergencyContactInput;
}>;

export type MemberUpsertEmergencyContactMutation = {
  __typename?: 'Mutation';
  memberUpsertEmergencyContact?: {
    __typename?: 'Member';
    id?: string | null;
    emergencyContact?: {
      __typename?: 'EmergencyContact';
      name?: string | null;
      relationship?: Relationship | null;
      phoneNumber?: {
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
        type?: string | null;
      } | null;
    } | null;
  } | null;
};

export type MemberUpsertIdentificationDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberIdentificationDetailsInput;
}>;

export type MemberUpsertIdentificationDetailsMutation = {
  __typename?: 'Mutation';
  memberUpsertIdentificationDetails?: {
    __typename?: 'Member';
    id?: string | null;
    identificationDetails?: {
      __typename?: 'MemberIdentificationDetails';
      legalData?: Array<{
        __typename?: 'LegalData';
        key?: string | null;
        value?: string | null;
        label?: string | null;
        country?: CountryCode | null;
      } | null> | null;
    } | null;
  } | null;
};

export type MemberUpsertPersonalInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberPersonalInfoInput;
}>;

export type MemberUpsertPersonalInfoMutation = {
  __typename?: 'Mutation';
  memberUpsertPersonalInfo?: {
    __typename?: 'Member';
    id?: string | null;
    personalInfo?: {
      __typename?: 'MemberPersonalInfo';
      age?: number | null;
      dateOfBirth?: any | null;
      maritalStatus?: MaritalStatus | null;
      religion?: string | null;
      nationalities?: Array<{
        __typename?: 'MemberNationalogy';
        country?: CountryCode | null;
      } | null> | null;
    } | null;
  } | null;
};

export type MemberUpsertPreviousEmployerMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PreviousEmployerInput;
}>;

export type MemberUpsertPreviousEmployerMutation = {
  __typename?: 'Mutation';
  memberUpsertPreviousEmployer?: {
    __typename?: 'Member';
    id?: string | null;
    previousEmployers?: Array<{
      __typename?: 'PreviousEmployer';
      designation?: string | null;
      endDate?: any | null;
      name?: string | null;
      startDate?: any | null;
    } | null> | null;
  } | null;
};

export type GetEmploymentCertificateQueryVariables = Exact<{
  date: Scalars['Date'];
}>;

export type GetEmploymentCertificateQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      compliance?:
        | {
            __typename?: 'ComplianceContractor';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'ComplianceFreelance';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            certificateOfEmployment?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              contentType?: string | null;
              extension?: string | null;
              blob?: string | null;
              link?: string | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'CompliancePEO';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | {
            __typename?: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
            } | null;
          }
        | null;
    } | null> | null;
  } | null;
};

export type CancelChangeRequestMutationVariables = Exact<{
  category: MemberChangeCategory;
}>;

export type CancelChangeRequestMutation = {
  __typename?: 'Mutation';
  memberCancelUpdateRequest?: {
    __typename?: 'Member';
    id?: string | null;
    changeRequests?: Array<{
      __typename?: 'MemberChangeRequest';
      id: string;
      status?: MemberChangeRequestStatus | null;
      category?: MemberChangeCategory | null;
      message?: string | null;
      createdOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        contentType?: string | null;
        extension?: string | null;
        downloadUrl?: string | null;
        name?: string | null;
      }> | null;
      items?: Array<
        | {
            __typename?: 'AddressParam';
            key?: string | null;
            addressValue?: {
              __typename?: 'Address';
              street?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: CountryCode | null;
              province?: string | null;
              zipcode?: string | null;
              postalCode?: string | null;
            } | null;
          }
        | {
            __typename?: 'BankAccountParam';
            key?: string | null;
            bankAccountValue?: {
              __typename?: 'BankAccount';
              bankName?: string | null;
              accountName?: string | null;
              accountNumber?: string | null;
              branchName?: string | null;
              localBankCode?: string | null;
              swiftCode?: string | null;
              paymentAccountRequirementType?: string | null;
              version?: number | null;
              accountDetails?: Array<{
                __typename?: 'BankAccountDetail';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          }
        | {
            __typename?: 'DateParam';
            key?: string | null;
            dateValue?: any | null;
          }
        | {
            __typename?: 'EmailAddressParam';
            key?: string | null;
            emailValue?: {
              __typename?: 'EmailAddress';
              email?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'GenderParam';
            key?: string | null;
            genderValue?: Gender | null;
          }
        | {
            __typename?: 'IntParam';
            key?: string | null;
            intValue?: number | null;
          }
        | {
            __typename?: 'LegalDataParam';
            key?: string | null;
            legalValue?: {
              __typename?: 'LegalData';
              key?: string | null;
              value?: string | null;
              identifier?: string | null;
            } | null;
          }
        | {
            __typename?: 'LegalDocumentParam';
            key?: string | null;
            legalDocumentValue?: {
              __typename?: 'LegalDocument';
              key?: string | null;
              label?: string | null;
              category?: LegalDocumentCategory | null;
              financialYear?: number | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                name?: string | null;
                extension?: string | null;
                contentType?: string | null;
                downloadUrl?: string | null;
                size?: number | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'MemberNationalogyParam';
            key?: string | null;
            nationalogyValue?: {
              __typename?: 'MemberNationalogy';
              type?: Nationalogy | null;
              country?: CountryCode | null;
            } | null;
          }
        | {
            __typename?: 'PhoneNumberParam';
            key?: string | null;
            phoneValue?: {
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'StringParam';
            key?: string | null;
            strValue?: string | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type AddressFieldsFragment = {
  __typename?: 'Address';
  city?: string | null;
  country?: CountryCode | null;
  key?: string | null;
  landmark?: string | null;
  line1?: string | null;
  line2?: string | null;
  postalCode?: string | null;
  province?: string | null;
  state?: string | null;
  street?: string | null;
  zipcode?: string | null;
};

export type ChangeRequestItemsFragmentFragment = {
  __typename?: 'MemberChangeRequest';
  items?: Array<
    | {
        __typename?: 'AddressParam';
        key?: string | null;
        addressValue?: {
          __typename?: 'Address';
          street?: string | null;
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: CountryCode | null;
          province?: string | null;
          zipcode?: string | null;
          postalCode?: string | null;
        } | null;
      }
    | {
        __typename?: 'BankAccountParam';
        key?: string | null;
        bankAccountValue?: {
          __typename?: 'BankAccount';
          bankName?: string | null;
          accountName?: string | null;
          accountNumber?: string | null;
          branchName?: string | null;
          localBankCode?: string | null;
          swiftCode?: string | null;
          paymentAccountRequirementType?: string | null;
          version?: number | null;
          accountDetails?: Array<{
            __typename?: 'BankAccountDetail';
            key?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
      }
    | { __typename?: 'DateParam'; key?: string | null; dateValue?: any | null }
    | {
        __typename?: 'EmailAddressParam';
        key?: string | null;
        emailValue?: {
          __typename?: 'EmailAddress';
          email?: string | null;
          type?: string | null;
        } | null;
      }
    | {
        __typename?: 'GenderParam';
        key?: string | null;
        genderValue?: Gender | null;
      }
    | { __typename?: 'IntParam'; key?: string | null; intValue?: number | null }
    | {
        __typename?: 'LegalDataParam';
        key?: string | null;
        legalValue?: {
          __typename?: 'LegalData';
          key?: string | null;
          value?: string | null;
          identifier?: string | null;
        } | null;
      }
    | {
        __typename?: 'LegalDocumentParam';
        key?: string | null;
        legalDocumentValue?: {
          __typename?: 'LegalDocument';
          key?: string | null;
          label?: string | null;
          category?: LegalDocumentCategory | null;
          financialYear?: number | null;
          files?: Array<{
            __typename?: 'Document';
            id?: string | null;
            name?: string | null;
            extension?: string | null;
            contentType?: string | null;
            downloadUrl?: string | null;
            size?: number | null;
          }> | null;
        } | null;
      }
    | {
        __typename?: 'MemberNationalogyParam';
        key?: string | null;
        nationalogyValue?: {
          __typename?: 'MemberNationalogy';
          type?: Nationalogy | null;
          country?: CountryCode | null;
        } | null;
      }
    | {
        __typename?: 'PhoneNumberParam';
        key?: string | null;
        phoneValue?: {
          __typename?: 'PhoneNumber';
          phoneNo?: string | null;
          type?: string | null;
        } | null;
      }
    | {
        __typename?: 'StringParam';
        key?: string | null;
        strValue?: string | null;
      }
    | null
  > | null;
};

export type PaymentAccountRequirementFragmentFragment = {
  __typename?: 'PaymentAccountRequirement';
  accountType?: PaymentAccountType | null;
  transferType?: TransferType | null;
  sourceCurrency?: CurrencyCode | null;
  targetCurrency?: CurrencyCode | null;
  paymentPartner?: PaymentPartnerCode | null;
  paymentAccountRequirementType?: string | null;
  requirementFields?: Array<{
    __typename?: 'RequirementField';
    key?: string | null;
    label?: string | null;
    isMandatory?: boolean | null;
    type?: string | null;
    regex?: string | null;
    errorMessage?: string | null;
    hasSubFields?: boolean | null;
    allowedValues?: Array<{
      __typename?: 'AllowedValue';
      key?: string | null;
      value?: string | null;
    } | null> | null;
    subFields?: Array<{
      __typename?: 'RequirementSubField';
      key?: string | null;
      value?: Array<{
        __typename?: 'RequirementField';
        key?: string | null;
        label?: string | null;
        isMandatory?: boolean | null;
        type?: string | null;
        regex?: string | null;
        errorMessage?: string | null;
        hasSubFields?: boolean | null;
        allowedValues?: Array<{
          __typename?: 'AllowedValue';
          key?: string | null;
          value?: string | null;
        } | null> | null;
        subFields?: Array<{
          __typename?: 'RequirementSubField';
          key?: string | null;
          value?: Array<{
            __typename?: 'RequirementField';
            key?: string | null;
            label?: string | null;
            isMandatory?: boolean | null;
            type?: string | null;
            regex?: string | null;
            errorMessage?: string | null;
            allowedValues?: Array<{
              __typename?: 'AllowedValue';
              key?: string | null;
              value?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetMemberBankDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberBankDetailsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      type?: ContractType | null;
      paymentAccountRequirements?: Array<{
        __typename?: 'PaymentAccountRequirement';
        accountType?: PaymentAccountType | null;
        transferType?: TransferType | null;
        sourceCurrency?: CurrencyCode | null;
        targetCurrency?: CurrencyCode | null;
        paymentPartner?: PaymentPartnerCode | null;
        paymentAccountRequirementType?: string | null;
        requirementFields?: Array<{
          __typename?: 'RequirementField';
          key?: string | null;
          label?: string | null;
          isMandatory?: boolean | null;
          type?: string | null;
          regex?: string | null;
          errorMessage?: string | null;
          hasSubFields?: boolean | null;
          allowedValues?: Array<{
            __typename?: 'AllowedValue';
            key?: string | null;
            value?: string | null;
          } | null> | null;
          subFields?: Array<{
            __typename?: 'RequirementSubField';
            key?: string | null;
            value?: Array<{
              __typename?: 'RequirementField';
              key?: string | null;
              label?: string | null;
              isMandatory?: boolean | null;
              type?: string | null;
              regex?: string | null;
              errorMessage?: string | null;
              hasSubFields?: boolean | null;
              allowedValues?: Array<{
                __typename?: 'AllowedValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
              subFields?: Array<{
                __typename?: 'RequirementSubField';
                key?: string | null;
                value?: Array<{
                  __typename?: 'RequirementField';
                  key?: string | null;
                  label?: string | null;
                  isMandatory?: boolean | null;
                  type?: string | null;
                  regex?: string | null;
                  errorMessage?: string | null;
                  allowedValues?: Array<{
                    __typename?: 'AllowedValue';
                    key?: string | null;
                    value?: string | null;
                  } | null> | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id?: string | null;
      version?: number | null;
      paymentAccountRequirementType?: string | null;
    } | null> | null;
    changeRequests?: Array<{
      __typename?: 'MemberChangeRequest';
      id: string;
      status?: MemberChangeRequestStatus | null;
      category?: MemberChangeCategory | null;
      message?: string | null;
      createdOn?: any | null;
      items?: Array<
        | {
            __typename?: 'AddressParam';
            key?: string | null;
            addressValue?: {
              __typename?: 'Address';
              street?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: CountryCode | null;
              province?: string | null;
              zipcode?: string | null;
              postalCode?: string | null;
            } | null;
          }
        | {
            __typename?: 'BankAccountParam';
            key?: string | null;
            bankAccountValue?: {
              __typename?: 'BankAccount';
              bankName?: string | null;
              accountName?: string | null;
              accountNumber?: string | null;
              branchName?: string | null;
              localBankCode?: string | null;
              swiftCode?: string | null;
              paymentAccountRequirementType?: string | null;
              version?: number | null;
              accountDetails?: Array<{
                __typename?: 'BankAccountDetail';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          }
        | {
            __typename?: 'DateParam';
            key?: string | null;
            dateValue?: any | null;
          }
        | {
            __typename?: 'EmailAddressParam';
            key?: string | null;
            emailValue?: {
              __typename?: 'EmailAddress';
              email?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'GenderParam';
            key?: string | null;
            genderValue?: Gender | null;
          }
        | {
            __typename?: 'IntParam';
            key?: string | null;
            intValue?: number | null;
          }
        | {
            __typename?: 'LegalDataParam';
            key?: string | null;
            legalValue?: {
              __typename?: 'LegalData';
              key?: string | null;
              value?: string | null;
              identifier?: string | null;
            } | null;
          }
        | {
            __typename?: 'LegalDocumentParam';
            key?: string | null;
            legalDocumentValue?: {
              __typename?: 'LegalDocument';
              key?: string | null;
              label?: string | null;
              category?: LegalDocumentCategory | null;
              financialYear?: number | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                name?: string | null;
                extension?: string | null;
                contentType?: string | null;
                downloadUrl?: string | null;
                size?: number | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'MemberNationalogyParam';
            key?: string | null;
            nationalogyValue?: {
              __typename?: 'MemberNationalogy';
              type?: Nationalogy | null;
              country?: CountryCode | null;
            } | null;
          }
        | {
            __typename?: 'PhoneNumberParam';
            key?: string | null;
            phoneValue?: {
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'StringParam';
            key?: string | null;
            strValue?: string | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type GetMemberWithUpdateRequestsV2QueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberWithUpdateRequestsV2Query = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    fullLegalName?: string | null;
    gender?: Gender | null;
    maritalStatus?: MaritalStatus | null;
    dateOfBirth?: any | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      employeeId?: string | null;
      term?: ContractTerm | null;
      type?: ContractType | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      status?: ContractStatus | null;
      currency?: CurrencyCode | null;
      workStatus?: CountryWorkStatus | null;
      position?: string | null;
      alreadyHired?: boolean | null;
      startOn?: any | null;
      endOn?: any | null;
      scope?: string | null;
      company?: {
        __typename?: 'Company';
        id?: string | null;
        displayName?: string | null;
        status?: CompanyStatus | null;
      } | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
      compensation?: {
        __typename?: 'Compensation';
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
      compliance?:
        | {
            __typename: 'ComplianceContractor';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            orderForm?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              blob?: string | null;
              htmlPreview?: string | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'ComplianceFreelance';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            offerLetter?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            preferredContractAgreementTemplate?: {
              __typename?: 'PreferredContractAgreementTemplateResponse';
              documentId?: string | null;
              template?: {
                __typename?: 'Template';
                id: string;
                displayName?: string | null;
                externalId?: string | null;
              } | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'CompliancePEO';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            offerLetter?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | null;
      paymentAccountRequirements?: Array<{
        __typename?: 'PaymentAccountRequirement';
        accountType?: PaymentAccountType | null;
        transferType?: TransferType | null;
        sourceCurrency?: CurrencyCode | null;
        targetCurrency?: CurrencyCode | null;
        paymentPartner?: PaymentPartnerCode | null;
        paymentAccountRequirementType?: string | null;
        requirementFields?: Array<{
          __typename?: 'RequirementField';
          key?: string | null;
          label?: string | null;
          isMandatory?: boolean | null;
          type?: string | null;
          regex?: string | null;
          errorMessage?: string | null;
          hasSubFields?: boolean | null;
          allowedValues?: Array<{
            __typename?: 'AllowedValue';
            key?: string | null;
            value?: string | null;
          } | null> | null;
          subFields?: Array<{
            __typename?: 'RequirementSubField';
            key?: string | null;
            value?: Array<{
              __typename?: 'RequirementField';
              key?: string | null;
              label?: string | null;
              isMandatory?: boolean | null;
              type?: string | null;
              regex?: string | null;
              errorMessage?: string | null;
              hasSubFields?: boolean | null;
              allowedValues?: Array<{
                __typename?: 'AllowedValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
              subFields?: Array<{
                __typename?: 'RequirementSubField';
                key?: string | null;
                value?: Array<{
                  __typename?: 'RequirementField';
                  key?: string | null;
                  label?: string | null;
                  isMandatory?: boolean | null;
                  type?: string | null;
                  regex?: string | null;
                  errorMessage?: string | null;
                  allowedValues?: Array<{
                    __typename?: 'AllowedValue';
                    key?: string | null;
                    value?: string | null;
                  } | null> | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
    addresses?: Array<{
      __typename?: 'Address';
      key?: string | null;
      street?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      province?: string | null;
      country?: CountryCode | null;
      zipcode?: string | null;
      postalCode?: string | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      identifier?: string | null;
      label?: string | null;
      country?: CountryCode | null;
    } | null> | null;
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id?: string | null;
      version?: number | null;
      accountName?: string | null;
      accountNumber?: string | null;
      bankName?: string | null;
      branchName?: string | null;
      currency?: CurrencyCode | null;
      country?: CountryCode | null;
      swiftCode?: string | null;
      localBankCode?: string | null;
      paymentAccountRequirementType?: string | null;
      accountDetails?: Array<{
        __typename?: 'BankAccountDetail';
        key?: string | null;
        label?: string | null;
        value?: string | null;
      } | null> | null;
      accountRequirementContext?: {
        __typename?: 'AccountRequirementContext';
        paymentAccountRequirementType?: string | null;
      } | null;
      bankStatements?: Array<{
        __typename?: 'FileLink';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        link?: string | null;
        contentType?: string | null;
        size?: number | null;
      } | null> | null;
    } | null> | null;
    legalDocuments?: Array<{
      __typename?: 'LegalDocument';
      id: string;
      key?: string | null;
      label?: string | null;
      financialYear?: number | null;
      status?: LegalDocumentStatus | null;
      comment?: string | null;
      category?: LegalDocumentCategory | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        downloadUrl?: string | null;
        size?: number | null;
      }> | null;
    } | null> | null;
    changeRequests?: Array<{
      __typename?: 'MemberChangeRequest';
      id: string;
      status?: MemberChangeRequestStatus | null;
      category?: MemberChangeCategory | null;
      message?: string | null;
      createdOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        contentType?: string | null;
        extension?: string | null;
        downloadUrl?: string | null;
        name?: string | null;
      }> | null;
      items?: Array<
        | {
            __typename?: 'AddressParam';
            key?: string | null;
            addressValue?: {
              __typename?: 'Address';
              street?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: CountryCode | null;
              province?: string | null;
              zipcode?: string | null;
              postalCode?: string | null;
            } | null;
          }
        | {
            __typename?: 'BankAccountParam';
            key?: string | null;
            bankAccountValue?: {
              __typename?: 'BankAccount';
              bankName?: string | null;
              accountName?: string | null;
              accountNumber?: string | null;
              branchName?: string | null;
              localBankCode?: string | null;
              swiftCode?: string | null;
              paymentAccountRequirementType?: string | null;
              version?: number | null;
              accountDetails?: Array<{
                __typename?: 'BankAccountDetail';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          }
        | {
            __typename?: 'DateParam';
            key?: string | null;
            dateValue?: any | null;
          }
        | {
            __typename?: 'EmailAddressParam';
            key?: string | null;
            emailValue?: {
              __typename?: 'EmailAddress';
              email?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'GenderParam';
            key?: string | null;
            genderValue?: Gender | null;
          }
        | {
            __typename?: 'IntParam';
            key?: string | null;
            intValue?: number | null;
          }
        | {
            __typename?: 'LegalDataParam';
            key?: string | null;
            legalValue?: {
              __typename?: 'LegalData';
              key?: string | null;
              value?: string | null;
              identifier?: string | null;
            } | null;
          }
        | {
            __typename?: 'LegalDocumentParam';
            key?: string | null;
            legalDocumentValue?: {
              __typename?: 'LegalDocument';
              key?: string | null;
              label?: string | null;
              category?: LegalDocumentCategory | null;
              financialYear?: number | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                name?: string | null;
                extension?: string | null;
                contentType?: string | null;
                downloadUrl?: string | null;
                size?: number | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'MemberNationalogyParam';
            key?: string | null;
            nationalogyValue?: {
              __typename?: 'MemberNationalogy';
              type?: Nationalogy | null;
              country?: CountryCode | null;
            } | null;
          }
        | {
            __typename?: 'PhoneNumberParam';
            key?: string | null;
            phoneValue?: {
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'StringParam';
            key?: string | null;
            strValue?: string | null;
          }
        | null
      > | null;
    } | null> | null;
    emergencyContact?: {
      __typename?: 'EmergencyContact';
      name?: string | null;
      relationship?: Relationship | null;
      phoneNumber?: {
        __typename?: 'PhoneNumber';
        phoneNo?: string | null;
        type?: string | null;
      } | null;
    } | null;
    education?: Array<{
      __typename?: 'Education';
      degree?: string | null;
      gpa?: number | null;
      grade?: string | null;
      lastSchoolAttended?: string | null;
      yearOfPassing?: any | null;
    } | null> | null;
    previousEmployers?: Array<{
      __typename?: 'PreviousEmployer';
      designation?: string | null;
      endDate?: any | null;
      name?: string | null;
      startDate?: any | null;
    } | null> | null;
    basicInfo?: {
      __typename?: 'MemberBasicInfo';
      firstName?: string | null;
      fullLegalName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
    } | null;
    personalInfo?: {
      __typename?: 'MemberPersonalInfo';
      gender?: Gender | null;
      age?: number | null;
      dateOfBirth?: any | null;
      maritalStatus?: MaritalStatus | null;
      religion?: string | null;
      nationalities?: Array<{
        __typename?: 'MemberNationalogy';
        country?: CountryCode | null;
        type?: Nationalogy | null;
      } | null> | null;
    } | null;
    contactInfo?: {
      __typename?: 'MemberContactInfo';
      email?: { __typename?: 'EmailAddress'; email?: string | null } | null;
      mobile?: { __typename?: 'PhoneNumber'; phoneNo?: string | null } | null;
      phone?: { __typename?: 'PhoneNumber'; phoneNo?: string | null } | null;
    } | null;
    addressDetails?: {
      __typename?: 'MemberAddress';
      currentAddress?: {
        __typename?: 'Address';
        city?: string | null;
        country?: CountryCode | null;
        key?: string | null;
        landmark?: string | null;
        line1?: string | null;
        line2?: string | null;
        postalCode?: string | null;
        province?: string | null;
        state?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
      permanentAddress?: {
        __typename?: 'Address';
        city?: string | null;
        country?: CountryCode | null;
        key?: string | null;
        landmark?: string | null;
        line1?: string | null;
        line2?: string | null;
        postalCode?: string | null;
        province?: string | null;
        state?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetMemberWithUpdateRequestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberWithUpdateRequestsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullLegalName?: string | null;
    gender?: Gender | null;
    maritalStatus?: MaritalStatus | null;
    dateOfBirth?: any | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      term?: ContractTerm | null;
      type?: ContractType | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      status?: ContractStatus | null;
      currency?: CurrencyCode | null;
      workStatus?: CountryWorkStatus | null;
      employeeId?: string | null;
      legalEntityId?: string | null;
      position?: string | null;
      alreadyHired?: boolean | null;
      startOn?: any | null;
      endOn?: any | null;
      scope?: string | null;
      company?: {
        __typename?: 'Company';
        id?: string | null;
        displayName?: string | null;
        status?: CompanyStatus | null;
      } | null;
      onboarding?: {
        __typename?: 'ContractOnboarding';
        status?: ContractOnboardingStatus | null;
        steps?: Array<ContractOnboardingStep | null> | null;
        current?: ContractOnboardingStep | null;
      } | null;
      compensation?: {
        __typename?: 'Compensation';
        basePay?: {
          __typename?: 'FixedPayComponent';
          name?: string | null;
          label?: string | null;
          amount?: number | null;
          currency?: CurrencyCode | null;
          frequency?: RateFrequency | null;
          rateType?: RateType | null;
          paymentFrequency?: PayFrequency | null;
          validFromInclusive?: any | null;
          validToExclusive?: any | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            identifier?: PayFrequencyDateIdentifier | null;
            dateOfMonth?: number | null;
            dayOfWeek?: DayOfWeek | null;
          } | null> | null;
        } | null;
        additionalPays?: Array<
          | {
              __typename?: 'FixedPayComponent';
              rateType?: RateType | null;
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              instalments?: Array<{
                __typename?: 'Instalment';
                amount?: number | null;
                currency?: CurrencyCode | null;
                payOn?: {
                  __typename?: 'MonthYear';
                  year?: number | null;
                  month?: number | null;
                } | null;
              }> | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | {
              __typename?: 'PercentPayComponent';
              name?: string | null;
              label?: string | null;
              amount?: number | null;
              amountType?: PayAmountType | null;
              currency?: CurrencyCode | null;
              frequency?: RateFrequency | null;
              isDeletable?: boolean | null;
              condition?: string | null;
              payOn?: {
                __typename?: 'MonthYear';
                year?: number | null;
                month?: number | null;
              } | null;
              paySchedule?: {
                __typename?: 'ScheduleTime';
                value: number;
                unit: ScheduleTimeUnit;
              } | null;
            }
          | null
        > | null;
      } | null;
      compliance?:
        | {
            __typename: 'ComplianceContractor';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            orderForm?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
              extension?: string | null;
              contentType?: string | null;
              blob?: string | null;
              htmlPreview?: string | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'ComplianceFreelance';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'ComplianceMultiplierEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            offerLetter?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            preferredContractAgreementTemplate?: {
              __typename?: 'PreferredContractAgreementTemplateResponse';
              documentId?: string | null;
              template?: {
                __typename?: 'Template';
                id: string;
                displayName?: string | null;
                externalId?: string | null;
              } | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'CompliancePEO';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename: 'CompliancePartnerEOR';
            type?: LegalBindingType | null;
            contractAgreementType?: ContractAgreementType | null;
            offerLetter?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            contractAgreement?: {
              __typename?: 'DocumentReadable';
              id?: string | null;
              name?: string | null;
            } | null;
            complianceParams?: Array<{
              __typename?: 'ComplianceParamPeriodLimit';
              description?: string | null;
              value?: number | null;
              unit?: ComplianceParamPeriodUnit | null;
              key?: string | null;
              label?: string | null;
            } | null> | null;
            timeOffEntitlement?: Array<{
              __typename?: 'ContractTimeOffEntitlement';
              value?: number | null;
              unit?: TimeOffUnit | null;
              timeOffType?: {
                __typename?: 'TimeOffTypeInfo';
                type?: string | null;
                typeId?: string | null;
              } | null;
              definition?: {
                __typename?: 'TimeOffTypeDefinition';
                type?: string | null;
                required?: boolean | null;
                label?: string | null;
                description?: string | null;
                assignedEntityNames?: Array<string> | null;
                validation?: Array<{
                  __typename?: 'TimeOffFixedValidation';
                  minimum?: number | null;
                  maximum?: number | null;
                  defaultValue?: number | null;
                  unit?: TimeOffUnit | null;
                } | null> | null;
              } | null;
            } | null> | null;
          }
        | null;
      paymentAccountRequirements?: Array<{
        __typename?: 'PaymentAccountRequirement';
        accountType?: PaymentAccountType | null;
        transferType?: TransferType | null;
        sourceCurrency?: CurrencyCode | null;
        targetCurrency?: CurrencyCode | null;
        paymentPartner?: PaymentPartnerCode | null;
        paymentAccountRequirementType?: string | null;
        requirementFields?: Array<{
          __typename?: 'RequirementField';
          key?: string | null;
          label?: string | null;
          isMandatory?: boolean | null;
          type?: string | null;
          regex?: string | null;
          errorMessage?: string | null;
          hasSubFields?: boolean | null;
          allowedValues?: Array<{
            __typename?: 'AllowedValue';
            key?: string | null;
            value?: string | null;
          } | null> | null;
          subFields?: Array<{
            __typename?: 'RequirementSubField';
            key?: string | null;
            value?: Array<{
              __typename?: 'RequirementField';
              key?: string | null;
              label?: string | null;
              isMandatory?: boolean | null;
              type?: string | null;
              regex?: string | null;
              errorMessage?: string | null;
              hasSubFields?: boolean | null;
              allowedValues?: Array<{
                __typename?: 'AllowedValue';
                key?: string | null;
                value?: string | null;
              } | null> | null;
              subFields?: Array<{
                __typename?: 'RequirementSubField';
                key?: string | null;
                value?: Array<{
                  __typename?: 'RequirementField';
                  key?: string | null;
                  label?: string | null;
                  isMandatory?: boolean | null;
                  type?: string | null;
                  regex?: string | null;
                  errorMessage?: string | null;
                  allowedValues?: Array<{
                    __typename?: 'AllowedValue';
                    key?: string | null;
                    value?: string | null;
                  } | null> | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    emails?: Array<{
      __typename?: 'EmailAddress';
      type?: string | null;
      email?: string | null;
    } | null> | null;
    addresses?: Array<{
      __typename?: 'Address';
      key?: string | null;
      street?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      province?: string | null;
      country?: CountryCode | null;
      zipcode?: string | null;
      postalCode?: string | null;
    } | null> | null;
    phoneNos?: Array<{
      __typename?: 'PhoneNumber';
      type?: string | null;
      phoneNo?: string | null;
    } | null> | null;
    nationalities?: Array<{
      __typename?: 'MemberNationalogy';
      type?: Nationalogy | null;
      country?: CountryCode | null;
    } | null> | null;
    legalData?: Array<{
      __typename?: 'LegalData';
      key?: string | null;
      value?: string | null;
      identifier?: string | null;
      label?: string | null;
      country?: CountryCode | null;
    } | null> | null;
    bankAccounts?: Array<{
      __typename?: 'BankAccount';
      id?: string | null;
      version?: number | null;
      accountName?: string | null;
      accountNumber?: string | null;
      bankName?: string | null;
      branchName?: string | null;
      currency?: CurrencyCode | null;
      country?: CountryCode | null;
      swiftCode?: string | null;
      localBankCode?: string | null;
      paymentAccountRequirementType?: string | null;
      accountDetails?: Array<{
        __typename?: 'BankAccountDetail';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      bankStatements?: Array<{
        __typename?: 'FileLink';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        link?: string | null;
        contentType?: string | null;
        size?: number | null;
      } | null> | null;
    } | null> | null;
    legalDocuments?: Array<{
      __typename?: 'LegalDocument';
      id: string;
      key?: string | null;
      label?: string | null;
      financialYear?: number | null;
      status?: LegalDocumentStatus | null;
      comment?: string | null;
      category?: LegalDocumentCategory | null;
      createdOn?: any | null;
      updatedOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        name?: string | null;
        extension?: string | null;
        contentType?: string | null;
        downloadUrl?: string | null;
        size?: number | null;
      }> | null;
    } | null> | null;
    changeRequests?: Array<{
      __typename?: 'MemberChangeRequest';
      id: string;
      status?: MemberChangeRequestStatus | null;
      category?: MemberChangeCategory | null;
      message?: string | null;
      createdOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        contentType?: string | null;
        extension?: string | null;
        downloadUrl?: string | null;
        name?: string | null;
      }> | null;
      items?: Array<
        | {
            __typename?: 'AddressParam';
            key?: string | null;
            addressValue?: {
              __typename?: 'Address';
              street?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: CountryCode | null;
              province?: string | null;
              zipcode?: string | null;
              postalCode?: string | null;
            } | null;
          }
        | {
            __typename?: 'BankAccountParam';
            key?: string | null;
            bankAccountValue?: {
              __typename?: 'BankAccount';
              bankName?: string | null;
              accountName?: string | null;
              accountNumber?: string | null;
              branchName?: string | null;
              localBankCode?: string | null;
              swiftCode?: string | null;
              paymentAccountRequirementType?: string | null;
              version?: number | null;
              accountDetails?: Array<{
                __typename?: 'BankAccountDetail';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          }
        | {
            __typename?: 'DateParam';
            key?: string | null;
            dateValue?: any | null;
          }
        | {
            __typename?: 'EmailAddressParam';
            key?: string | null;
            emailValue?: {
              __typename?: 'EmailAddress';
              email?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'GenderParam';
            key?: string | null;
            genderValue?: Gender | null;
          }
        | {
            __typename?: 'IntParam';
            key?: string | null;
            intValue?: number | null;
          }
        | {
            __typename?: 'LegalDataParam';
            key?: string | null;
            legalValue?: {
              __typename?: 'LegalData';
              key?: string | null;
              value?: string | null;
              identifier?: string | null;
            } | null;
          }
        | {
            __typename?: 'LegalDocumentParam';
            key?: string | null;
            legalDocumentValue?: {
              __typename?: 'LegalDocument';
              key?: string | null;
              label?: string | null;
              category?: LegalDocumentCategory | null;
              financialYear?: number | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                name?: string | null;
                extension?: string | null;
                contentType?: string | null;
                downloadUrl?: string | null;
                size?: number | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'MemberNationalogyParam';
            key?: string | null;
            nationalogyValue?: {
              __typename?: 'MemberNationalogy';
              type?: Nationalogy | null;
              country?: CountryCode | null;
            } | null;
          }
        | {
            __typename?: 'PhoneNumberParam';
            key?: string | null;
            phoneValue?: {
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'StringParam';
            key?: string | null;
            strValue?: string | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberBankDetailsMutationVariables = Exact<{
  detail: MemberChangeRequestInput;
}>;

export type UpdateMemberBankDetailsMutation = {
  __typename?: 'Mutation';
  memberSendUpdateRequest?: {
    __typename?: 'Member';
    id?: string | null;
    changeRequests?: Array<{
      __typename?: 'MemberChangeRequest';
      id: string;
      status?: MemberChangeRequestStatus | null;
      category?: MemberChangeCategory | null;
      message?: string | null;
      createdOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        contentType?: string | null;
        extension?: string | null;
        downloadUrl?: string | null;
        name?: string | null;
      }> | null;
      items?: Array<
        | {
            __typename?: 'AddressParam';
            key?: string | null;
            addressValue?: {
              __typename?: 'Address';
              street?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: CountryCode | null;
              province?: string | null;
              zipcode?: string | null;
              postalCode?: string | null;
            } | null;
          }
        | {
            __typename?: 'BankAccountParam';
            key?: string | null;
            bankAccountValue?: {
              __typename?: 'BankAccount';
              bankName?: string | null;
              accountName?: string | null;
              accountNumber?: string | null;
              branchName?: string | null;
              localBankCode?: string | null;
              swiftCode?: string | null;
              paymentAccountRequirementType?: string | null;
              version?: number | null;
              accountDetails?: Array<{
                __typename?: 'BankAccountDetail';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          }
        | {
            __typename?: 'DateParam';
            key?: string | null;
            dateValue?: any | null;
          }
        | {
            __typename?: 'EmailAddressParam';
            key?: string | null;
            emailValue?: {
              __typename?: 'EmailAddress';
              email?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'GenderParam';
            key?: string | null;
            genderValue?: Gender | null;
          }
        | {
            __typename?: 'IntParam';
            key?: string | null;
            intValue?: number | null;
          }
        | {
            __typename?: 'LegalDataParam';
            key?: string | null;
            legalValue?: {
              __typename?: 'LegalData';
              key?: string | null;
              value?: string | null;
              identifier?: string | null;
            } | null;
          }
        | {
            __typename?: 'LegalDocumentParam';
            key?: string | null;
            legalDocumentValue?: {
              __typename?: 'LegalDocument';
              key?: string | null;
              label?: string | null;
              category?: LegalDocumentCategory | null;
              financialYear?: number | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                name?: string | null;
                extension?: string | null;
                contentType?: string | null;
                downloadUrl?: string | null;
                size?: number | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'MemberNationalogyParam';
            key?: string | null;
            nationalogyValue?: {
              __typename?: 'MemberNationalogy';
              type?: Nationalogy | null;
              country?: CountryCode | null;
            } | null;
          }
        | {
            __typename?: 'PhoneNumberParam';
            key?: string | null;
            phoneValue?: {
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'StringParam';
            key?: string | null;
            strValue?: string | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberBasicDetailsMutationVariables = Exact<{
  detail: MemberChangeRequestInput;
}>;

export type UpdateMemberBasicDetailsMutation = {
  __typename?: 'Mutation';
  memberSendUpdateRequest?: {
    __typename?: 'Member';
    id?: string | null;
    changeRequests?: Array<{
      __typename?: 'MemberChangeRequest';
      id: string;
      status?: MemberChangeRequestStatus | null;
      category?: MemberChangeCategory | null;
      message?: string | null;
      createdOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        contentType?: string | null;
        extension?: string | null;
        downloadUrl?: string | null;
        name?: string | null;
      }> | null;
      items?: Array<
        | {
            __typename?: 'AddressParam';
            key?: string | null;
            addressValue?: {
              __typename?: 'Address';
              street?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: CountryCode | null;
              province?: string | null;
              zipcode?: string | null;
              postalCode?: string | null;
            } | null;
          }
        | {
            __typename?: 'BankAccountParam';
            key?: string | null;
            bankAccountValue?: {
              __typename?: 'BankAccount';
              bankName?: string | null;
              accountName?: string | null;
              accountNumber?: string | null;
              branchName?: string | null;
              localBankCode?: string | null;
              swiftCode?: string | null;
              paymentAccountRequirementType?: string | null;
              version?: number | null;
              accountDetails?: Array<{
                __typename?: 'BankAccountDetail';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          }
        | {
            __typename?: 'DateParam';
            key?: string | null;
            dateValue?: any | null;
          }
        | {
            __typename?: 'EmailAddressParam';
            key?: string | null;
            emailValue?: {
              __typename?: 'EmailAddress';
              email?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'GenderParam';
            key?: string | null;
            genderValue?: Gender | null;
          }
        | {
            __typename?: 'IntParam';
            key?: string | null;
            intValue?: number | null;
          }
        | {
            __typename?: 'LegalDataParam';
            key?: string | null;
            legalValue?: {
              __typename?: 'LegalData';
              key?: string | null;
              value?: string | null;
              identifier?: string | null;
            } | null;
          }
        | {
            __typename?: 'LegalDocumentParam';
            key?: string | null;
            legalDocumentValue?: {
              __typename?: 'LegalDocument';
              key?: string | null;
              label?: string | null;
              category?: LegalDocumentCategory | null;
              financialYear?: number | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                name?: string | null;
                extension?: string | null;
                contentType?: string | null;
                downloadUrl?: string | null;
                size?: number | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'MemberNationalogyParam';
            key?: string | null;
            nationalogyValue?: {
              __typename?: 'MemberNationalogy';
              type?: Nationalogy | null;
              country?: CountryCode | null;
            } | null;
          }
        | {
            __typename?: 'PhoneNumberParam';
            key?: string | null;
            phoneValue?: {
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'StringParam';
            key?: string | null;
            strValue?: string | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberContactDetailsMutationVariables = Exact<{
  detail: MemberChangeRequestInput;
}>;

export type UpdateMemberContactDetailsMutation = {
  __typename?: 'Mutation';
  memberSendUpdateRequest?: {
    __typename?: 'Member';
    id?: string | null;
  } | null;
};

export type UpdateMemberIdentificationDetailsMutationVariables = Exact<{
  detail: MemberChangeRequestInput;
}>;

export type UpdateMemberIdentificationDetailsMutation = {
  __typename?: 'Mutation';
  memberSendUpdateRequest?: {
    __typename?: 'Member';
    id?: string | null;
    changeRequests?: Array<{
      __typename?: 'MemberChangeRequest';
      id: string;
      status?: MemberChangeRequestStatus | null;
      category?: MemberChangeCategory | null;
      message?: string | null;
      createdOn?: any | null;
      files?: Array<{
        __typename?: 'Document';
        id?: string | null;
        contentType?: string | null;
        extension?: string | null;
        downloadUrl?: string | null;
        name?: string | null;
      }> | null;
      items?: Array<
        | {
            __typename?: 'AddressParam';
            key?: string | null;
            addressValue?: {
              __typename?: 'Address';
              street?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: CountryCode | null;
              province?: string | null;
              zipcode?: string | null;
              postalCode?: string | null;
            } | null;
          }
        | {
            __typename?: 'BankAccountParam';
            key?: string | null;
            bankAccountValue?: {
              __typename?: 'BankAccount';
              bankName?: string | null;
              accountName?: string | null;
              accountNumber?: string | null;
              branchName?: string | null;
              localBankCode?: string | null;
              swiftCode?: string | null;
              paymentAccountRequirementType?: string | null;
              version?: number | null;
              accountDetails?: Array<{
                __typename?: 'BankAccountDetail';
                key?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          }
        | {
            __typename?: 'DateParam';
            key?: string | null;
            dateValue?: any | null;
          }
        | {
            __typename?: 'EmailAddressParam';
            key?: string | null;
            emailValue?: {
              __typename?: 'EmailAddress';
              email?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'GenderParam';
            key?: string | null;
            genderValue?: Gender | null;
          }
        | {
            __typename?: 'IntParam';
            key?: string | null;
            intValue?: number | null;
          }
        | {
            __typename?: 'LegalDataParam';
            key?: string | null;
            legalValue?: {
              __typename?: 'LegalData';
              key?: string | null;
              value?: string | null;
              identifier?: string | null;
            } | null;
          }
        | {
            __typename?: 'LegalDocumentParam';
            key?: string | null;
            legalDocumentValue?: {
              __typename?: 'LegalDocument';
              key?: string | null;
              label?: string | null;
              category?: LegalDocumentCategory | null;
              financialYear?: number | null;
              files?: Array<{
                __typename?: 'Document';
                id?: string | null;
                name?: string | null;
                extension?: string | null;
                contentType?: string | null;
                downloadUrl?: string | null;
                size?: number | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'MemberNationalogyParam';
            key?: string | null;
            nationalogyValue?: {
              __typename?: 'MemberNationalogy';
              type?: Nationalogy | null;
              country?: CountryCode | null;
            } | null;
          }
        | {
            __typename?: 'PhoneNumberParam';
            key?: string | null;
            phoneValue?: {
              __typename?: 'PhoneNumber';
              phoneNo?: string | null;
              type?: string | null;
            } | null;
          }
        | {
            __typename?: 'StringParam';
            key?: string | null;
            strValue?: string | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type GetMemberRewardsStatementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberRewardsStatementsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      performanceReviews?: Array<{
        __typename?: 'PerformanceReview';
        id: string;
        effectiveDate?: any | null;
        status?: PerformanceReviewStatus | null;
        reviewDocument?: {
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          link?: string | null;
          blob?: string | null;
          extension?: string | null;
          contentType?: string | null;
        } | null;
      } | null> | null;
      salaryReviews?: Array<{
        __typename?: 'SalaryReview';
        id: string;
        effectiveDate?: any | null;
        state?: SalaryReviewStatus | null;
        reviewDocument?: {
          __typename?: 'DocumentReadable';
          id?: string | null;
          name?: string | null;
          link?: string | null;
          blob?: string | null;
          extension?: string | null;
          contentType?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DocumentReadableFragment = {
  __typename?: 'DocumentReadable';
  id?: string | null;
  name?: string | null;
  link?: string | null;
  blob?: string | null;
  extension?: string | null;
  contentType?: string | null;
};

export type ApproveTimeOffMutationVariables = Exact<{
  itemId: Scalars['ID'];
}>;

export type ApproveTimeOffMutation = {
  __typename?: 'Mutation';
  approveItem?:
    | { __typename?: 'ExpenseApprovalItem' }
    | { __typename?: 'LegalDocumentsApprovalItem' }
    | { __typename?: 'MemberDataApprovalItem' }
    | { __typename?: 'MemberPayableApprovalItem' }
    | {
        __typename?: 'TimeOffApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
        timeOffId?: string | null;
        timeOffItem?: {
          __typename?: 'TimeOff';
          id: string;
          status?: TimeOffStatus | null;
          noOfDays?: number | null;
          description?: string | null;
          isActionable?: boolean | null;
          changeReason?: string | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
            company?: { __typename?: 'Company'; id?: string | null } | null;
          } | null;
          startDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
          endDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
        } | null;
      }
    | null;
};

export type ForwardTimeOffMutationVariables = Exact<{
  itemId: Scalars['ID'];
  forwardUserId: Scalars['ID'];
}>;

export type ForwardTimeOffMutation = {
  __typename?: 'Mutation';
  forwardItem?:
    | { __typename?: 'ExpenseApprovalItem' }
    | { __typename?: 'LegalDocumentsApprovalItem' }
    | { __typename?: 'MemberDataApprovalItem' }
    | { __typename?: 'MemberPayableApprovalItem' }
    | {
        __typename?: 'TimeOffApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
        timeOffId?: string | null;
        timeOffItem?: {
          __typename?: 'TimeOff';
          id: string;
          status?: TimeOffStatus | null;
          noOfDays?: number | null;
          description?: string | null;
          isActionable?: boolean | null;
          changeReason?: string | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
            company?: { __typename?: 'Company'; id?: string | null } | null;
          } | null;
          startDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
          endDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
        } | null;
      }
    | null;
};

export type GetCompanyTimeOffDetailsQueryVariables = Exact<{
  country: CountryCode;
  countryStateCode?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
  term?: InputMaybe<ContractTerm>;
  contractStatus?: InputMaybe<ContractStatus>;
  companyId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCompanyTimeOffDetailsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    timeOffRequirements?: {
      __typename?: 'TimeOffRequirements';
      definitions?: Array<{
        __typename?: 'TimeOffTypeDefinition';
        type?: string | null;
        required?: boolean | null;
        label?: string | null;
        description?: string | null;
        assignedEntityNames?: Array<string> | null;
        validation?: Array<{
          __typename?: 'TimeOffFixedValidation';
          minimum?: number | null;
          maximum?: number | null;
          defaultValue?: number | null;
          unit?: TimeOffUnit | null;
        } | null> | null;
        configuration?: {
          __typename?: 'TimeoffConfiguration';
          allocation?: {
            __typename?: 'AllocationConfig';
            basis?: AllocationBasis | null;
            prorated?: boolean | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCompanyTimeOffQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyTimeOffQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timeOffs?: Array<{
      __typename?: 'TimeOff';
      id: string;
      createdOn?: any | null;
      createdBy?: string | null;
      noOfDays?: number | null;
      description?: string | null;
      startDate?: {
        __typename?: 'TimeOffDate';
        dateOnly?: any | null;
        session?: TimeOffSession | null;
      } | null;
      endDate?: {
        __typename?: 'TimeOffDate';
        dateOnly?: any | null;
        session?: TimeOffSession | null;
      } | null;
      type?: {
        __typename?: 'TimeOffType';
        key?: string | null;
        definition?: {
          __typename?: 'TimeOffTypeDefinition';
          type?: string | null;
          label?: string | null;
          assignedEntityNames?: Array<string> | null;
        } | null;
      } | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        startOn?: any | null;
        country?: CountryCode | null;
        position?: string | null;
        legalEntityId?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        timeOff?: {
          __typename?: 'ContractTimeOff';
          summary?: Array<{
            __typename?: 'TimeOffType';
            key?: string | null;
            periodStart?: any | null;
            periodEnd?: any | null;
            taken?: number | null;
            remaining?: number | null;
            entitled?: number | null;
            pending?: number | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              label?: string | null;
              assignedEntityNames?: Array<string> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CompanyTimeOffsQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyTimeOffsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timeOffs?: Array<{
      __typename?: 'TimeOff';
      id: string;
      createdBy?: string | null;
      createdOn?: any | null;
      status?: TimeOffStatus | null;
      noOfDays?: number | null;
      description?: string | null;
      isActionable?: boolean | null;
      changeReason?: string | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        position?: string | null;
        member?: {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        company?: { __typename?: 'Company'; id?: string | null } | null;
      } | null;
      type?: {
        __typename?: 'TimeOffType';
        key?: string | null;
        definition?: {
          __typename?: 'TimeOffTypeDefinition';
          type?: string | null;
          label?: string | null;
        } | null;
      } | null;
      startDate?: {
        __typename?: 'TimeOffDate';
        dateOnly?: any | null;
        session?: TimeOffSession | null;
      } | null;
      endDate?: {
        __typename?: 'TimeOffDate';
        dateOnly?: any | null;
        session?: TimeOffSession | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetContractTimeOffQueryVariables = Exact<{
  id: Scalars['ID'];
  timeOffId: Scalars['ID'];
}>;

export type GetContractTimeOffQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    startOn?: any | null;
    country?: CountryCode | null;
    position?: string | null;
    member?: {
      __typename?: 'Member';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    timeOff?: {
      __typename?: 'ContractTimeOff';
      timeOffs?: Array<{
        __typename?: 'TimeOff';
        id: string;
        createdOn?: any | null;
        createdBy?: string | null;
        noOfDays?: number | null;
        description?: string | null;
        startDate?: {
          __typename?: 'TimeOffDate';
          dateOnly?: any | null;
          session?: TimeOffSession | null;
        } | null;
        endDate?: {
          __typename?: 'TimeOffDate';
          dateOnly?: any | null;
          session?: TimeOffSession | null;
        } | null;
        type?: {
          __typename?: 'TimeOffType';
          key?: string | null;
          definition?: {
            __typename?: 'TimeOffTypeDefinition';
            type?: string | null;
            label?: string | null;
          } | null;
        } | null;
      } | null> | null;
      summary?: Array<{
        __typename?: 'TimeOffType';
        key?: string | null;
        periodStart?: any | null;
        periodEnd?: any | null;
        entitled?: number | null;
        taken?: number | null;
        pending?: number | null;
        remaining?: number | null;
        definition?: {
          __typename?: 'TimeOffTypeDefinition';
          type?: string | null;
          label?: string | null;
        } | null;
        contract?: { __typename?: 'Contract'; id?: string | null } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetContractTimeOffsComplianceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractTimeOffsComplianceQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    compliance?:
      | {
          __typename?: 'ComplianceContractor';
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              label?: string | null;
              required?: boolean | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
              configuration?: {
                __typename?: 'TimeoffConfiguration';
                allocation?: {
                  __typename?: 'AllocationConfig';
                  basis?: AllocationBasis | null;
                  prorated?: boolean | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename?: 'ComplianceFreelance';
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              label?: string | null;
              required?: boolean | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
              configuration?: {
                __typename?: 'TimeoffConfiguration';
                allocation?: {
                  __typename?: 'AllocationConfig';
                  basis?: AllocationBasis | null;
                  prorated?: boolean | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename?: 'ComplianceMultiplierEOR';
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              label?: string | null;
              required?: boolean | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
              configuration?: {
                __typename?: 'TimeoffConfiguration';
                allocation?: {
                  __typename?: 'AllocationConfig';
                  basis?: AllocationBasis | null;
                  prorated?: boolean | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename?: 'CompliancePEO';
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              label?: string | null;
              required?: boolean | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
              configuration?: {
                __typename?: 'TimeoffConfiguration';
                allocation?: {
                  __typename?: 'AllocationConfig';
                  basis?: AllocationBasis | null;
                  prorated?: boolean | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename?: 'CompliancePartnerEOR';
          timeOffEntitlement?: Array<{
            __typename?: 'ContractTimeOffEntitlement';
            value?: number | null;
            unit?: TimeOffUnit | null;
            timeOffType?: {
              __typename?: 'TimeOffTypeInfo';
              type?: string | null;
              typeId?: string | null;
            } | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              label?: string | null;
              required?: boolean | null;
              assignedEntityNames?: Array<string> | null;
              validation?: Array<{
                __typename?: 'TimeOffFixedValidation';
                minimum?: number | null;
                maximum?: number | null;
                defaultValue?: number | null;
                unit?: TimeOffUnit | null;
              } | null> | null;
              configuration?: {
                __typename?: 'TimeoffConfiguration';
                allocation?: {
                  __typename?: 'AllocationConfig';
                  basis?: AllocationBasis | null;
                  prorated?: boolean | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        }
      | null;
  } | null;
};

export type GetContractTimeOffsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractTimeOffsQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    type?: ContractType | null;
    startOn?: any | null;
    country?: CountryCode | null;
    legalEntityId?: string | null;
    timeOff?: {
      __typename?: 'ContractTimeOff';
      timeOffs?: Array<{
        __typename?: 'TimeOff';
        id: string;
        status?: TimeOffStatus | null;
        createdOn?: any | null;
        createdBy?: string | null;
        noOfDays?: number | null;
        description?: string | null;
        startDate?: {
          __typename?: 'TimeOffDate';
          dateOnly?: any | null;
          session?: TimeOffSession | null;
        } | null;
        endDate?: {
          __typename?: 'TimeOffDate';
          dateOnly?: any | null;
          session?: TimeOffSession | null;
        } | null;
        type?: {
          __typename?: 'TimeOffType';
          key?: string | null;
          definition?: {
            __typename?: 'TimeOffTypeDefinition';
            type?: string | null;
            label?: string | null;
          } | null;
        } | null;
      } | null> | null;
      summary?: Array<{
        __typename?: 'TimeOffType';
        key?: string | null;
        periodStart?: any | null;
        periodEnd?: any | null;
        entitled?: number | null;
        taken?: number | null;
        pending?: number | null;
        remaining?: number | null;
        definition?: {
          __typename?: 'TimeOffTypeDefinition';
          type?: string | null;
          label?: string | null;
          assignedEntityNames?: Array<string> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetTimeOffApproversQueryVariables = Exact<{
  itemId: Scalars['ID'];
}>;

export type GetTimeOffApproversQuery = {
  __typename?: 'Query';
  getAllApprovers?: Array<{
    __typename?: 'Approver';
    id?: string | null;
    approverUser?:
      | {
          __typename?: 'CompanyUser';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          status?: CompanyUserStatus | null;
        }
      | {
          __typename?: 'EmergencyPointOfContact';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }
      | {
          __typename?: 'Member';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }
      | {
          __typename?: 'OperationsUser';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        }
      | null;
  } | null> | null;
};

export type RejectTimeOffMutationVariables = Exact<{
  itemId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}>;

export type RejectTimeOffMutation = {
  __typename?: 'Mutation';
  rejectItem?:
    | { __typename?: 'ExpenseApprovalItem' }
    | { __typename?: 'LegalDocumentsApprovalItem' }
    | { __typename?: 'MemberDataApprovalItem' }
    | { __typename?: 'MemberPayableApprovalItem' }
    | {
        __typename?: 'TimeOffApprovalItem';
        id?: string | null;
        status?: ApprovalItemStatus | null;
        timeOffId?: string | null;
        timeOffItem?: {
          __typename?: 'TimeOff';
          id: string;
          status?: TimeOffStatus | null;
          noOfDays?: number | null;
          description?: string | null;
          isActionable?: boolean | null;
          changeReason?: string | null;
          contract?: {
            __typename?: 'Contract';
            id?: string | null;
            position?: string | null;
            member?: {
              __typename?: 'Member';
              id?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            } | null;
            company?: { __typename?: 'Company'; id?: string | null } | null;
          } | null;
          startDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
          endDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
        } | null;
      }
    | null;
};

export type GetMemberTimeOffQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetMemberTimeOffQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      term?: ContractTerm | null;
      type?: ContractType | null;
      country?: CountryCode | null;
      status?: ContractStatus | null;
      timeOff?: {
        __typename?: 'ContractTimeOff';
        timeOffs?: Array<{
          __typename?: 'TimeOff';
          id: string;
          status?: TimeOffStatus | null;
          noOfDays?: number | null;
          description?: string | null;
          changeReason?: string | null;
          type?: { __typename?: 'TimeOffType'; key?: string | null } | null;
          startDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
          endDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
        } | null> | null;
        summary?: Array<{
          __typename?: 'TimeOffType';
          key?: string | null;
          periodStart?: any | null;
          periodEnd?: any | null;
          entitled?: number | null;
          taken?: number | null;
          pending?: number | null;
          remaining?: number | null;
          definition?: {
            __typename?: 'TimeOffTypeDefinition';
            type?: string | null;
            label?: string | null;
            assignedEntityNames?: Array<string> | null;
          } | null;
          contract?: { __typename?: 'Contract'; id?: string | null } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetMemberTimeOffsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMemberTimeOffsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      type?: ContractType | null;
      country?: CountryCode | null;
      status?: ContractStatus | null;
      legalEntityId?: string | null;
      timeOff?: {
        __typename?: 'ContractTimeOff';
        timeOffs?: Array<{
          __typename?: 'TimeOff';
          id: string;
          createdOn?: any | null;
          createdBy?: string | null;
          status?: TimeOffStatus | null;
          noOfDays?: number | null;
          description?: string | null;
          changeReason?: string | null;
          type?: {
            __typename?: 'TimeOffType';
            key?: string | null;
            definition?: {
              __typename?: 'TimeOffTypeDefinition';
              type?: string | null;
              label?: string | null;
            } | null;
          } | null;
          startDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
          endDate?: {
            __typename?: 'TimeOffDate';
            dateOnly?: any | null;
            session?: TimeOffSession | null;
          } | null;
        } | null> | null;
        summary?: Array<{
          __typename?: 'TimeOffType';
          key?: string | null;
          periodStart?: any | null;
          periodEnd?: any | null;
          entitled?: number | null;
          taken?: number | null;
          pending?: number | null;
          remaining?: number | null;
          definition?: {
            __typename?: 'TimeOffTypeDefinition';
            type?: string | null;
            label?: string | null;
            assignedEntityNames?: Array<string> | null;
          } | null;
          contract?: { __typename?: 'Contract'; id?: string | null } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreateTimeoffAsDraftMutationVariables = Exact<{
  input: TimeOffSaveAsDraftInput;
}>;

export type CreateTimeoffAsDraftMutation = {
  __typename?: 'Mutation';
  timeOffSaveAsDraft?: {
    __typename?: 'TimeOff';
    id: string;
    status?: TimeOffStatus | null;
    noOfDays?: number | null;
    description?: string | null;
    changeReason?: string | null;
    type?: { __typename?: 'TimeOffType'; key?: string | null } | null;
    startDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
    endDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
  } | null;
};

export type CreateTimeoffMutationVariables = Exact<{
  contractId?: InputMaybe<Scalars['ID']>;
  input: TimeOffCreateInput;
}>;

export type CreateTimeoffMutation = {
  __typename?: 'Mutation';
  timeOffCreate?: {
    __typename?: 'TimeOff';
    id: string;
    status?: TimeOffStatus | null;
    noOfDays?: number | null;
    description?: string | null;
    changeReason?: string | null;
    type?: { __typename?: 'TimeOffType'; key?: string | null } | null;
    startDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
    endDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
  } | null;
};

export type DeleteTimeoffMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTimeoffMutation = {
  __typename?: 'Mutation';
  timeOffDelete?: {
    __typename?: 'TaskResponse';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type UpdateTimeoffMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TimeOffUpdateInput;
}>;

export type UpdateTimeoffMutation = {
  __typename?: 'Mutation';
  timeOffUpdate?: {
    __typename?: 'TimeOff';
    id: string;
    status?: TimeOffStatus | null;
    noOfDays?: number | null;
    description?: string | null;
    changeReason?: string | null;
    type?: { __typename?: 'TimeOffType'; key?: string | null } | null;
    startDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
    endDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
  } | null;
};

export type RevokeTimeOffMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RevokeTimeOffMutation = {
  __typename?: 'Mutation';
  timeOffRevoke?: {
    __typename?: 'TimeOff';
    id: string;
    status?: TimeOffStatus | null;
    noOfDays?: number | null;
    description?: string | null;
    changeReason?: string | null;
    type?: { __typename?: 'TimeOffType'; key?: string | null } | null;
    startDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
    endDate?: {
      __typename?: 'TimeOffDate';
      dateOnly?: any | null;
      session?: TimeOffSession | null;
    } | null;
  } | null;
};

export type TimesheetEntriesApproveMutationVariables = Exact<{
  timesheetEntryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type TimesheetEntriesApproveMutation = {
  __typename?: 'Mutation';
  timesheetEntriesApprove?: Array<{
    __typename?: 'TimesheetEntry';
    id?: string | null;
    date?: any | null;
    hours?: number | null;
    dayType?: DayType | null;
    regularTimeInMinutes?: number | null;
    overtimeTotalInMinutes?: number | null;
    overtimeAtNightInMinutes?: number | null;
    nightShiftTimeInMinutes?: number | null;
    lateTimeInMinutes?: number | null;
    status?: TimesheetEntryStatus | null;
    updatedOn?: any | null;
    isPaidLeave?: boolean | null;
    comment?: string | null;
    submittedOn?: any | null;
    approvedOn?: any | null;
    rejectedOn?: any | null;
    workTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    breakTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    addedByInfo?: {
      __typename?: 'AuditUser';
      userId?: string | null;
      experience?: string | null;
    } | null;
  } | null> | null;
};

export type GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  contractId?: InputMaybe<Scalars['ID']>;
}>;

export type GetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    displayName?: string | null;
    timesheets?: Array<{
      __typename?: 'Timesheet';
      id?: string | null;
      startDate?: any | null;
      endDate?: any | null;
    } | null> | null;
  } | null;
};

export type GetEmployerTimesheetSummariesQueryVariables = Exact<{
  filters?: InputMaybe<TimesheetSummaryFilter>;
}>;

export type GetEmployerTimesheetSummariesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timesheetSummaries?: Array<{
      __typename?: 'ContractTimesheetSummary';
      cutOffDate?: any | null;
      totalDaysToApprove?: number | null;
      totalSubmittedHoursToApprove?: number | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        type?: ContractType | null;
        country?: CountryCode | null;
        startOn?: any | null;
        endOn?: any | null;
        endedOn?: any | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetEmployerTimesheetQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  contractId: Scalars['ID'];
}>;

export type GetEmployerTimesheetQuery = {
  __typename?: 'Query';
  contract?: {
    __typename?: 'Contract';
    id?: string | null;
    country?: CountryCode | null;
    countryStateCode?: string | null;
    startOn?: any | null;
    endOn?: any | null;
    endedOn?: any | null;
    type?: ContractType | null;
    company?: { __typename?: 'Company'; displayName?: string | null } | null;
    member?: {
      __typename?: 'Member';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    compensation?: {
      __typename?: 'Compensation';
      basePay?: {
        __typename?: 'FixedPayComponent';
        paymentFrequency?: PayFrequency | null;
        frequency?: RateFrequency | null;
      } | null;
    } | null;
    timesheets?: Array<{
      __typename?: 'Timesheet';
      id?: string | null;
      startDate?: any | null;
      endDate?: any | null;
      totalDays?: number | null;
      totalHours?: number | null;
      status?: TimesheetStatus | null;
      comment?: string | null;
      submittedOn?: any | null;
      approvedOn?: any | null;
      cutOffDate?: any | null;
      timeValueInputMethod?: TimeValueInputMethod | null;
      totalSubmittedDays?: number | null;
      totalRejectedDays?: number | null;
      totalApprovedDays?: number | null;
      totalClockedInDays?: number | null;
      timesheetEntries?: Array<{
        __typename?: 'TimesheetEntry';
        id?: string | null;
        date?: any | null;
        hours?: number | null;
        dayType?: DayType | null;
        regularTimeInMinutes?: number | null;
        overtimeTotalInMinutes?: number | null;
        overtimeAtNightInMinutes?: number | null;
        nightShiftTimeInMinutes?: number | null;
        lateTimeInMinutes?: number | null;
        status?: TimesheetEntryStatus | null;
        updatedOn?: any | null;
        isPaidLeave?: boolean | null;
        comment?: string | null;
        submittedOn?: any | null;
        approvedOn?: any | null;
        rejectedOn?: any | null;
        workTimes?: Array<{
          __typename?: 'TimeRange';
          startTime?: any | null;
          endTime?: any | null;
        } | null> | null;
        breakTimes?: Array<{
          __typename?: 'TimeRange';
          startTime?: any | null;
          endTime?: any | null;
        } | null> | null;
        addedByInfo?: {
          __typename?: 'AuditUser';
          userId?: string | null;
          experience?: string | null;
        } | null;
      } | null> | null;
      totalTimeSummary?: {
        __typename?: 'TimesheetTotalTimeSummary';
        totalTimeInMinutes?: number | null;
        totalLateTimeInMinutes?: number | null;
        totalTimeSummaryItems?: Array<{
          __typename?: 'TimesheetTotalTimeSummaryItem';
          dayType?: DayType | null;
          hoursType?: HoursType | null;
          totalTimeInMinutes?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetEmployerTimesheetsQueryVariables = Exact<{
  timesheetFilters?: InputMaybe<TimesheetFilters>;
}>;

export type GetEmployerTimesheetsQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: string | null;
    timesheets?: Array<{
      __typename?: 'Timesheet';
      id?: string | null;
      cutOffDate?: any | null;
      startDate?: any | null;
      endDate?: any | null;
      totalDays?: number | null;
      totalHours?: number | null;
      status?: TimesheetStatus | null;
      comment?: string | null;
      submittedOn?: any | null;
      approvedOn?: any | null;
      totalSubmittedDays?: number | null;
      totalRejectedDays?: number | null;
      totalApprovedDays?: number | null;
      totalClockedInDays?: number | null;
      contract?: {
        __typename?: 'Contract';
        id?: string | null;
        type?: ContractType | null;
        country?: CountryCode | null;
        startOn?: any | null;
        endOn?: any | null;
        endedOn?: any | null;
        member?: {
          __typename?: 'Member';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      timesheetEntries?: Array<{
        __typename?: 'TimesheetEntry';
        id?: string | null;
        date?: any | null;
        hours?: number | null;
        status?: TimesheetEntryStatus | null;
        regularTimeInMinutes?: number | null;
        overtimeTotalInMinutes?: number | null;
        overtimeAtNightInMinutes?: number | null;
        nightShiftTimeInMinutes?: number | null;
        lateTimeInMinutes?: number | null;
        addedByInfo?: {
          __typename?: 'AuditUser';
          userId?: string | null;
          experience?: string | null;
        } | null;
      } | null> | null;
      totalTimeSummary?: {
        __typename?: 'TimesheetTotalTimeSummary';
        totalTimeInMinutes?: number | null;
        totalLateTimeInMinutes?: number | null;
        totalTimeSummaryItems?: Array<{
          __typename?: 'TimesheetTotalTimeSummaryItem';
          dayType?: DayType | null;
          hoursType?: HoursType | null;
          totalTimeInMinutes?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TimesheetEntriesRejectMutationVariables = Exact<{
  timesheetEntryIds: Array<Scalars['ID']> | Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
}>;

export type TimesheetEntriesRejectMutation = {
  __typename?: 'Mutation';
  timesheetEntriesReject?: Array<{
    __typename?: 'TimesheetEntry';
    id?: string | null;
    date?: any | null;
    hours?: number | null;
    dayType?: DayType | null;
    regularTimeInMinutes?: number | null;
    overtimeTotalInMinutes?: number | null;
    overtimeAtNightInMinutes?: number | null;
    nightShiftTimeInMinutes?: number | null;
    lateTimeInMinutes?: number | null;
    status?: TimesheetEntryStatus | null;
    updatedOn?: any | null;
    isPaidLeave?: boolean | null;
    comment?: string | null;
    submittedOn?: any | null;
    approvedOn?: any | null;
    rejectedOn?: any | null;
    workTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    breakTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    addedByInfo?: {
      __typename?: 'AuditUser';
      userId?: string | null;
      experience?: string | null;
    } | null;
  } | null> | null;
};

export type GetEmployeeTimesheetQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetEmployeeTimesheetQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      country?: CountryCode | null;
      compensation?: {
        __typename?: 'Compensation';
        basePay?: {
          __typename?: 'FixedPayComponent';
          paymentFrequency?: PayFrequency | null;
          frequency?: RateFrequency | null;
        } | null;
      } | null;
      workShift?: {
        __typename?: 'WorkShift';
        workingHours?: {
          __typename?: 'WorkingHours';
          startTime?: any | null;
          endTime?: any | null;
        } | null;
      } | null;
      timesheets?: Array<{
        __typename?: 'Timesheet';
        id?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        totalDays?: number | null;
        totalHours?: number | null;
        status?: TimesheetStatus | null;
        comment?: string | null;
        submittedOn?: any | null;
        approvedOn?: any | null;
        cutOffDate?: any | null;
        timeValueInputMethod?: TimeValueInputMethod | null;
        timesheetEntries?: Array<{
          __typename?: 'TimesheetEntry';
          id?: string | null;
          date?: any | null;
          hours?: number | null;
          dayType?: DayType | null;
          regularTimeInMinutes?: number | null;
          overtimeTotalInMinutes?: number | null;
          overtimeAtNightInMinutes?: number | null;
          nightShiftTimeInMinutes?: number | null;
          lateTimeInMinutes?: number | null;
          status?: TimesheetEntryStatus | null;
          updatedOn?: any | null;
          isPaidLeave?: boolean | null;
          comment?: string | null;
          submittedOn?: any | null;
          approvedOn?: any | null;
          rejectedOn?: any | null;
          workTimes?: Array<{
            __typename?: 'TimeRange';
            startTime?: any | null;
            endTime?: any | null;
          } | null> | null;
          breakTimes?: Array<{
            __typename?: 'TimeRange';
            startTime?: any | null;
            endTime?: any | null;
          } | null> | null;
          addedByInfo?: {
            __typename?: 'AuditUser';
            userId?: string | null;
            experience?: string | null;
          } | null;
        } | null> | null;
        totalTimeSummary?: {
          __typename?: 'TimesheetTotalTimeSummary';
          totalTimeInMinutes?: number | null;
          totalLateTimeInMinutes?: number | null;
          totalTimeSummaryItems?: Array<{
            __typename?: 'TimesheetTotalTimeSummaryItem';
            dayType?: DayType | null;
            hoursType?: HoursType | null;
            totalTimeInMinutes?: number | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetEmployeeTimesheetsIdsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEmployeeTimesheetsIdsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      timesheets?: Array<{
        __typename?: 'Timesheet';
        id?: string | null;
        startDate?: any | null;
        endDate?: any | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetEmployeeTimesheetsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEmployeeTimesheetsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      compensation?: {
        __typename?: 'Compensation';
        basePay?: {
          __typename?: 'FixedPayComponent';
          paymentFrequency?: PayFrequency | null;
        } | null;
      } | null;
      workShift?: {
        __typename?: 'WorkShift';
        workingHours?: {
          __typename?: 'WorkingHours';
          startTime?: any | null;
          endTime?: any | null;
        } | null;
      } | null;
      timesheets?: Array<{
        __typename?: 'Timesheet';
        id?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        totalDays?: number | null;
        totalApprovedDays?: number | null;
        totalRejectedDays?: number | null;
        totalSubmittedDays?: number | null;
        totalClockedInDays?: number | null;
        totalHours?: number | null;
        status?: TimesheetStatus | null;
        comment?: string | null;
        submittedOn?: any | null;
        approvedOn?: any | null;
        cutOffDate?: any | null;
        timesheetEntries?: Array<{
          __typename?: 'TimesheetEntry';
          id?: string | null;
          date?: any | null;
          hours?: number | null;
          dayType?: DayType | null;
          status?: TimesheetEntryStatus | null;
          regularTimeInMinutes?: number | null;
          overtimeTotalInMinutes?: number | null;
          overtimeAtNightInMinutes?: number | null;
          nightShiftTimeInMinutes?: number | null;
          lateTimeInMinutes?: number | null;
        } | null> | null;
        totalTimeSummary?: {
          __typename?: 'TimesheetTotalTimeSummary';
          totalTimeInMinutes?: number | null;
          totalLateTimeInMinutes?: number | null;
          totalTimeSummaryItems?: Array<{
            __typename?: 'TimesheetTotalTimeSummaryItem';
            dayType?: DayType | null;
            hoursType?: HoursType | null;
            totalTimeInMinutes?: number | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetMemberContractDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberContractDetailsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    contracts?: Array<{
      __typename?: 'Contract';
      id?: string | null;
      term?: ContractTerm | null;
      type?: ContractType | null;
      status?: ContractStatus | null;
      startOn?: any | null;
      endOn?: any | null;
      endedOn?: any | null;
      country?: CountryCode | null;
      countryStateCode?: string | null;
      company?: { __typename?: 'Company'; displayName?: string | null } | null;
      workShift?: {
        __typename?: 'WorkShift';
        workingHours?: {
          __typename?: 'WorkingHours';
          startTime?: any | null;
          endTime?: any | null;
        } | null;
      } | null;
      compensation?: {
        __typename?: 'Compensation';
        payType?: PayType | null;
        basePay?: {
          __typename?: 'FixedPayComponent';
          frequency?: RateFrequency | null;
          paymentFrequency?: PayFrequency | null;
          paymentFrequencyDate?: Array<{
            __typename?: 'PayFrequencyDate';
            dateOfMonth?: number | null;
            identifier?: PayFrequencyDateIdentifier | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCountryTimesheetConfigsQueryVariables = Exact<{
  value?: InputMaybe<TimesheetConfigFilters>;
}>;

export type GetCountryTimesheetConfigsQuery = {
  __typename?: 'Query';
  timesheetConfigs?: {
    __typename?: 'TimesheetConfigs';
    countryConfigs?: {
      __typename?: 'TimesheetCountryConfigs';
      countryCode?: CountryCode | null;
      countryStateCode?: StateCode | null;
      dayTypes?: Array<DayType | null> | null;
      hoursTypes?: Array<HoursType | null> | null;
    } | null;
  } | null;
};

export type TimesheetEntriesSubmitMutationVariables = Exact<{
  timesheetEntryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type TimesheetEntriesSubmitMutation = {
  __typename?: 'Mutation';
  timesheetEntriesSubmit?: Array<{
    __typename?: 'TimesheetEntry';
    id?: string | null;
    date?: any | null;
    hours?: number | null;
    dayType?: DayType | null;
    regularTimeInMinutes?: number | null;
    overtimeTotalInMinutes?: number | null;
    overtimeAtNightInMinutes?: number | null;
    nightShiftTimeInMinutes?: number | null;
    lateTimeInMinutes?: number | null;
    status?: TimesheetEntryStatus | null;
    updatedOn?: any | null;
    isPaidLeave?: boolean | null;
    comment?: string | null;
    submittedOn?: any | null;
    approvedOn?: any | null;
    rejectedOn?: any | null;
    workTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    breakTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    addedByInfo?: {
      __typename?: 'AuditUser';
      userId?: string | null;
      experience?: string | null;
    } | null;
  } | null> | null;
};

export type TimesheetEntriesUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: Array<TimesheetEntryInput> | TimesheetEntryInput;
}>;

export type TimesheetEntriesUpdateMutation = {
  __typename?: 'Mutation';
  timesheetEntriesCreateOrUpdate?: Array<{
    __typename?: 'TimesheetEntry';
    id?: string | null;
    date?: any | null;
    hours?: number | null;
    dayType?: DayType | null;
    regularTimeInMinutes?: number | null;
    overtimeTotalInMinutes?: number | null;
    overtimeAtNightInMinutes?: number | null;
    nightShiftTimeInMinutes?: number | null;
    lateTimeInMinutes?: number | null;
    status?: TimesheetEntryStatus | null;
    updatedOn?: any | null;
    isPaidLeave?: boolean | null;
    comment?: string | null;
    submittedOn?: any | null;
    approvedOn?: any | null;
    rejectedOn?: any | null;
    workTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    breakTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    addedByInfo?: {
      __typename?: 'AuditUser';
      userId?: string | null;
      experience?: string | null;
    } | null;
  } | null> | null;
};

export type TimesheetCreateOrUpdateMutationVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  contractId?: InputMaybe<Scalars['ID']>;
}>;

export type TimesheetCreateOrUpdateMutation = {
  __typename?: 'Mutation';
  timesheetCreateOrUpdate?: {
    __typename?: 'Timesheet';
    id?: string | null;
  } | null;
};

export type TimesheetEntriesDeleteMutationVariables = Exact<{
  timesheetEntryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type TimesheetEntriesDeleteMutation = {
  __typename?: 'Mutation';
  timesheetEntriesDelete?: Array<{
    __typename?: 'TimesheetEntry';
    id?: string | null;
    date?: any | null;
    hours?: number | null;
    dayType?: DayType | null;
    regularTimeInMinutes?: number | null;
    overtimeTotalInMinutes?: number | null;
    overtimeAtNightInMinutes?: number | null;
    nightShiftTimeInMinutes?: number | null;
    lateTimeInMinutes?: number | null;
    status?: TimesheetEntryStatus | null;
    updatedOn?: any | null;
    isPaidLeave?: boolean | null;
    comment?: string | null;
    submittedOn?: any | null;
    approvedOn?: any | null;
    rejectedOn?: any | null;
    workTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    breakTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    addedByInfo?: {
      __typename?: 'AuditUser';
      userId?: string | null;
      experience?: string | null;
    } | null;
  } | null> | null;
};

export type TimesheetEntryFragment = {
  __typename?: 'TimesheetEntry';
  id?: string | null;
  date?: any | null;
  hours?: number | null;
  dayType?: DayType | null;
  regularTimeInMinutes?: number | null;
  overtimeTotalInMinutes?: number | null;
  overtimeAtNightInMinutes?: number | null;
  nightShiftTimeInMinutes?: number | null;
  lateTimeInMinutes?: number | null;
  status?: TimesheetEntryStatus | null;
  updatedOn?: any | null;
  isPaidLeave?: boolean | null;
  comment?: string | null;
  submittedOn?: any | null;
  approvedOn?: any | null;
  rejectedOn?: any | null;
  workTimes?: Array<{
    __typename?: 'TimeRange';
    startTime?: any | null;
    endTime?: any | null;
  } | null> | null;
  breakTimes?: Array<{
    __typename?: 'TimeRange';
    startTime?: any | null;
    endTime?: any | null;
  } | null> | null;
  addedByInfo?: {
    __typename?: 'AuditUser';
    userId?: string | null;
    experience?: string | null;
  } | null;
};

export type TimesheetEntriesRevokeMutationVariables = Exact<{
  timesheetEntryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type TimesheetEntriesRevokeMutation = {
  __typename?: 'Mutation';
  timesheetEntriesRevoke?: Array<{
    __typename?: 'TimesheetEntry';
    id?: string | null;
    date?: any | null;
    hours?: number | null;
    dayType?: DayType | null;
    regularTimeInMinutes?: number | null;
    overtimeTotalInMinutes?: number | null;
    overtimeAtNightInMinutes?: number | null;
    nightShiftTimeInMinutes?: number | null;
    lateTimeInMinutes?: number | null;
    status?: TimesheetEntryStatus | null;
    updatedOn?: any | null;
    isPaidLeave?: boolean | null;
    comment?: string | null;
    submittedOn?: any | null;
    approvedOn?: any | null;
    rejectedOn?: any | null;
    workTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    breakTimes?: Array<{
      __typename?: 'TimeRange';
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
    addedByInfo?: {
      __typename?: 'AuditUser';
      userId?: string | null;
      experience?: string | null;
    } | null;
  } | null> | null;
};

export type TimesheetStatusUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  status: TimesheetStatus;
  comment?: InputMaybe<Scalars['String']>;
}>;

export type TimesheetStatusUpdateMutation = {
  __typename?: 'Mutation';
  timesheetChangeStatus?: {
    __typename?: 'Timesheet';
    id?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    totalDays?: number | null;
    totalHours?: number | null;
    status?: TimesheetStatus | null;
    comment?: string | null;
    submittedOn?: any | null;
    approvedOn?: any | null;
    timesheetEntries?: Array<{
      __typename?: 'TimesheetEntry';
      id?: string | null;
      date?: any | null;
      hours?: number | null;
    } | null> | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      company?: { __typename?: 'Company'; id?: string | null } | null;
    } | null;
  } | null;
};

export const WorkShiftFragmentDoc = gql`
  fragment workShift on WorkShift {
    startDay
    endDay
    workingHours {
      startTime
      endTime
    }
  }
`;
export const CompensationPayComponentDefinitionFragmentDoc = gql`
  fragment compensationPayComponentDefinition on CompensationPayComponentDefinition {
    name
    label
    description
    frequency
    rateType
    payOn {
      year
      month
    }
    isDeletable
  }
`;
export const BenefitFragmentDoc = gql`
  fragment benefit on Benefit {
    id
    type
    packageName
    description
    benefitDocuments {
      benefitDocumentType
      files {
        id
        name
        downloadUrl
      }
    }
    provider {
      id
      emergencyPointOfContact {
        firstName
        lastName
        emails {
          type
          email
        }
      }
    }
  }
`;
export const ComplianceFragmentDoc = gql`
  fragment compliance on Compliance {
    type
    contractAgreement {
      id
      name
    }
    contractAgreementType
    complianceParams {
      key
      label
      ... on ComplianceParamPeriodLimit {
        description
        value
        unit
      }
    }
    timeOffEntitlement {
      timeOffType {
        type
        typeId
      }
      definition {
        type
        required
        label
        description
        validation {
          ... on TimeOffFixedValidation {
            minimum
            maximum
            defaultValue
            unit
          }
        }
        assignedEntityNames
      }
      value
      unit
    }
    ... on ComplianceMultiplierEOR {
      offerLetter {
        id
        name
      }
      preferredContractAgreementTemplate {
        documentId
        template {
          id
          displayName
          externalId
        }
      }
    }
    ... on CompliancePartnerEOR {
      offerLetter {
        id
        name
      }
    }
    ... on ComplianceContractor {
      orderForm {
        id
        name
        extension
        contentType
        blob
        htmlPreview
      }
    }
    __typename
  }
`;
export const GrantComponentFragmentDoc = gql`
  fragment grantComponent on Grant {
    id
    values {
      value
      type
      currency
    }
    condition
    schedule {
      cliffPeriod {
        value
        unit
      }
      vestingPeriod {
        value
        unit
      }
      vestingSchedule {
        time {
          value
          unit
        }
        vestingValue {
          value
          type
        }
        frequency
      }
    }
    grantDate
  }
`;
export const LegalDocumentFragmentDoc = gql`
  fragment legalDocument on LegalDocument {
    id
    key
    files {
      id
      name
      extension
      contentType
      downloadUrl
      size
    }
    label
    financialYear
    status
    comment
    category
    createdOn
    updatedOn
  }
`;
export const CompanyBenefitFragmentDoc = gql`
  fragment companyBenefit on Benefit {
    id
    type
    packageName
    description
    startDate
    endDate
    currency
    cost
    costingType
    frequency
    packageTier {
      tier
    }
    memberOnboardingKit {
      memberOnboardingKitFile {
        files {
          id
          downloadUrl
        }
      }
    }
    benefitDocuments {
      benefitDocumentType
      files {
        id
        name
        downloadUrl
      }
    }
    provider {
      id
      brokerData {
        name
      }
      coverageGroup {
        id
        name
        group {
          maxCount
          relation {
            familyMember
            id
          }
        }
      }
      emergencyPointOfContact {
        firstName
        lastName
        emails {
          type
          email
        }
        phoneNos {
          type
          phoneNo
        }
      }
      metaData {
        pocName
        emails {
          type
          email
        }
        phoneNos {
          phoneNo
          type
        }
      }
      partnerName
      partner {
        id
        name
      }
    }
    features {
      key
      label
      value
    }
  }
`;
export const CompanyUserFragmentDoc = gql`
  fragment companyUser on CompanyUser {
    id
    userId
    firstName
    lastName
    emails {
      email
    }
    title
    role
    roles
    isSignatory
    isBillingContact
    status
  }
`;
export const ChangeRequestBlockDataFragmentDoc = gql`
  fragment changeRequestBlockData on ChangeRequestBlockData {
    changeRequestId
    __typename
  }
`;
export const DeductionRequirementFragmentDoc = gql`
  fragment deductionRequirement on DeductionRequirement {
    type
    ... on FixedPercentageDeductionRequirement {
      values
    }
  }
`;
export const DeductionConstraintFragmentDoc = gql`
  fragment deductionConstraint on DeductionRequirement {
    name
    ... on FixedDeductionRequirement {
      options {
        value
        unit
      }
    }
  }
`;
export const ExpenseCategoryFragmentDoc = gql`
  fragment expenseCategory on ExpenseCategory {
    id
    name
    glCode {
      code
      id
      name
    }
  }
`;
export const ExpenseFragmentDoc = gql`
  fragment expense on Expense {
    id
    contract {
      id
      country
      type
      member {
        id
        firstName
        lastName
      }
      company {
        id
      }
    }
    items {
      id
      title
      date
      category
      categoryV2 {
        ...expenseCategory
      }
      amount
      amountInFunctionalCurrency
    }
    status
    submittedAt
    createdOn
    createdBy
    updatedBy
    updatedOn
    title
    currency
    totalAmount
    totalInFunctionalCurrency
    isApprovable
    changeReason
    payrollCutOffDate
    approverInfos {
      approverUser {
        id
        userId
        firstName
        lastName
        persona
      }
      status
      updatedOn
    }
  }
  ${ExpenseCategoryFragmentDoc}
`;
export const MemberExpenseFragmentDoc = gql`
  fragment memberExpense on Expense {
    id
    items {
      id
      title
      date
      category
      categoryV2 {
        ...expenseCategory
      }
      amount
      amountInFunctionalCurrency
    }
    status
    submittedAt
    createdOn
    createdBy
    updatedBy
    updatedOn
    title
    currency
    totalAmount
    totalInFunctionalCurrency
    isApprovable
    changeReason
    payrollCutOffDate
    approverInfos {
      approverUser {
        id
        userId
        firstName
        lastName
        persona
      }
      status
      updatedOn
    }
  }
  ${ExpenseCategoryFragmentDoc}
`;
export const InvoiceWorkflowStepDefinitionFragmentDoc = gql`
  fragment invoiceWorkflowStepDefinition on InvoiceWorkflowStepDefinition {
    step
    statuses
    currentStatus
    data {
      key
      value
      type
    }
  }
`;
export const InvoiceWorkFlowFragmentDoc = gql`
  fragment invoiceWorkFlow on InvoiceWorkFlow {
    allSteps {
      ...invoiceWorkflowStepDefinition
    }
    currentStep {
      ...invoiceWorkflowStepDefinition
    }
  }
  ${InvoiceWorkflowStepDefinitionFragmentDoc}
`;
export const ExpenseMemberPayableFragmentDoc = gql`
  fragment expenseMemberPayable on ExpenseMemberPayable {
    ... on ExpenseMemberPayable {
      id
      type
      payableFromDate
      payableToDate
      dueDate
      title
      description
      submittedAt
      createdOn
      updatedOn
      payOutInfo {
        paidOn
        paymentPartner
      }
      memberPayableInvoiceWorkflow {
        ...invoiceWorkFlow
      }
      currency
      totalAmount
      totalInFunctionalCurrency
      status
      rejectedAt
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        position
        country
        type
      }
      expense {
        id
      }
    }
  }
  ${InvoiceWorkFlowFragmentDoc}
`;
export const FreelancerPayableFragmentDoc = gql`
  fragment freelancerPayable on FreelancerPayable {
    ... on FreelancerPayable {
      id
      type
      payableFromDate
      payableToDate
      dueDate
      title
      description
      submittedAt
      createdOn
      updatedOn
      payOutInfo {
        paidOn
        paymentPartner
      }
      memberPayableInvoiceWorkflow {
        ...invoiceWorkFlow
      }
      currency
      totalAmount
      totalInFunctionalCurrency
      status
      durationValue
      changeReason
      comment
      rejectedAt
      approvedAt
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        position
        country
        type
      }
    }
  }
  ${InvoiceWorkFlowFragmentDoc}
`;
export const PaySupplementMemberPayableFragmentDoc = gql`
  fragment paySupplementMemberPayable on PaySupplementMemberPayable {
    ... on PaySupplementMemberPayable {
      id
      type
      payableFromDate
      payableToDate
      dueDate
      title
      description
      submittedAt
      createdOn
      updatedOn
      payOutInfo {
        paidOn
        paymentPartner
      }
      currency
      totalAmount
      totalInFunctionalCurrency
      status
      rejectedAt
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        position
        country
        type
      }
      paySupplement {
        id
      }
    }
  }
`;
export const FreelancerPayableMemberFragmentDoc = gql`
  fragment freelancerPayableMember on FreelancerPayable {
    ... on FreelancerPayable {
      id
      type
      title
      description
      currency
      totalAmount
      createdOn
      updatedOn
      status
      dueDate
      payOutInfo {
        paidOn
        paymentPartner
      }
      payableFromDate
      changeReason
      payableToDate
      memberPayableInvoiceWorkflow {
        ...invoiceWorkFlow
      }
    }
  }
  ${InvoiceWorkFlowFragmentDoc}
`;
export const LegalEntityFragmentDoc = gql`
  fragment legalEntity on LegalEntity {
    id
    legalName
    address {
      country
    }
    status
    capabilities
  }
`;
export const CompanyPayableFragmentFragmentDoc = gql`
  fragment companyPayableFragment on CompanyPayable {
    ... on CompanyPayable {
      id
      totalAmount
      currency
      status
      createdOn
      updatedOn
      companyPayableType: type
      invoice {
        id
        createdDate
        fullyPaidOnDate
        dueDate
        reference
        invoiceNo
        invoiceId
        amountPaid
        amountDue
        externalSystem
      }
      report {
        companyId
        month
        year
        type
      }
    }
  }
`;
export const ExpenseMemberPayableFragmentFragmentDoc = gql`
  fragment expenseMemberPayableFragment on ExpenseMemberPayable {
    ... on ExpenseMemberPayable {
      id
      type
      payableFromDate
      payableToDate
      dueDate
      title
      description
      submittedAt
      createdOn
      updatedOn
      currency
      totalAmount
      totalInFunctionalCurrency
      memberPayableStatus: status
      rejectedAt
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        position
        country
        type
      }
      expense {
        id
      }
    }
  }
`;
export const FreelancerPayableFragmentFragmentDoc = gql`
  fragment freelancerPayableFragment on FreelancerPayable {
    ... on FreelancerPayable {
      id
      type
      payableFromDate
      payableToDate
      dueDate
      title
      description
      submittedAt
      createdOn
      updatedOn
      currency
      totalAmount
      totalInFunctionalCurrency
      memberPayableStatus: status
      durationValue
      changeReason
      comment
      rejectedAt
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        position
        country
        type
      }
    }
  }
`;
export const PaySupplementMemberPayableFragmentFragmentDoc = gql`
  fragment paySupplementMemberPayableFragment on PaySupplementMemberPayable {
    ... on PaySupplementMemberPayable {
      id
      type
      payableFromDate
      payableToDate
      dueDate
      title
      description
      submittedAt
      createdOn
      updatedOn
      currency
      totalAmount
      totalInFunctionalCurrency
      memberPayableStatus: status
      rejectedAt
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        position
        country
        type
      }
      paySupplement {
        id
      }
    }
  }
`;
export const MemberPayrollCycleSummaryItemFragmentDoc = gql`
  fragment memberPayrollCycleSummaryItem on MemberPayrollCycleSummaryItem {
    totalAmount
    count
  }
`;
export const FixedPayComponentFragmentDoc = gql`
  fragment fixedPayComponent on FixedPayComponent {
    name
    label
    amount
    currency
    frequency
    rateType
    paymentFrequency
    paymentFrequencyDate {
      identifier
      dateOfMonth
      dayOfWeek
    }
    validFromInclusive
    validToExclusive
  }
`;
export const CompensationPayComponentFragmentDoc = gql`
  fragment compensationPayComponent on CompensationPayComponent {
    name
    label
    amount
    amountType
    currency
    frequency
    payOn {
      year
      month
    }
    paySchedule {
      value
      unit
    }
    ... on FixedPayComponent {
      rateType
      instalments {
        amount
        payOn {
          year
          month
        }
        currency
      }
    }
    isDeletable
    condition
  }
`;
export const SalaryReviewFragmentDoc = gql`
  fragment salaryReview on SalaryReview {
    id
    contract {
      id
      country
      member {
        id
        firstName
        lastName
      }
      position
      type
      term
    }
    revisedCompensation {
      payType
      basePay {
        ...fixedPayComponent
      }
      probationBasePay {
        ...fixedPayComponent
      }
      postProbationBasePay {
        ...fixedPayComponent
      }
      additionalPays {
        ...compensationPayComponent
      }
      additional
      payrollStart
    }
    currentCompensation {
      payType
      basePay {
        ...fixedPayComponent
      }
      additionalPays {
        ...compensationPayComponent
      }
      additional
      payrollStart
    }
    state
    comment
    createdOn
    effectiveDate
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
`;
export const PerformanceReviewFragmentDoc = gql`
  fragment performanceReview on PerformanceReview {
    id
    contract {
      id
      country
      member {
        id
        firstName
        lastName
        legalData {
          key
          value
        }
      }
      position
      type
      compliance {
        type
      }
    }
    salaryReview {
      ...salaryReview
    }
    status
    designation {
      name
      jobDescription
    }
    previousDesignation {
      name
      jobDescription
    }
    effectiveDate
    createdOn
    comment
    companySignedOn
    memberSignedOn
  }
  ${SalaryReviewFragmentDoc}
`;
export const CalculationBreakdownFragmentDoc = gql`
  fragment calculationBreakdown on CalculationBreakdown {
    name
    entries {
      name
      currency
      amount
      note
      entries {
        name
        currency
        amount
        note
      }
    }
    total {
      name
      currency
      amount
      note
    }
    frequency
  }
`;
export const AddressFieldsFragmentDoc = gql`
  fragment addressFields on Address {
    city
    country
    key
    landmark
    line1
    line2
    postalCode
    province
    state
    street
    zipcode
  }
`;
export const ChangeRequestItemsFragmentFragmentDoc = gql`
  fragment changeRequestItemsFragment on MemberChangeRequest {
    items {
      ... on StringParam {
        key
        strValue: value
      }
      ... on IntParam {
        key
        intValue: value
      }
      ... on DateParam {
        key
        dateValue: value
      }
      ... on GenderParam {
        key
        genderValue: value
      }
      ... on MemberNationalogyParam {
        key
        nationalogyValue: value {
          type
          country
        }
      }
      ... on PhoneNumberParam {
        key
        phoneValue: value {
          phoneNo
          type
        }
      }
      ... on AddressParam {
        key
        addressValue: value {
          street
          line1
          line2
          city
          state
          country
          province
          zipcode
          postalCode
        }
      }
      ... on EmailAddressParam {
        key
        emailValue: value {
          email
          type
        }
      }
      ... on BankAccountParam {
        key
        bankAccountValue: value {
          bankName
          accountName
          accountNumber
          branchName
          localBankCode
          swiftCode
          paymentAccountRequirementType
          version
          accountDetails {
            key
            value
          }
        }
      }
      ... on LegalDataParam {
        key
        legalValue: value {
          key
          value
          identifier
        }
      }
      ... on LegalDocumentParam {
        key
        legalDocumentValue: value {
          key
          label
          category
          financialYear
          files {
            id
            name
            extension
            contentType
            downloadUrl
            size
          }
        }
      }
    }
  }
`;
export const PaymentAccountRequirementFragmentFragmentDoc = gql`
  fragment paymentAccountRequirementFragment on PaymentAccountRequirement {
    accountType
    transferType
    sourceCurrency
    targetCurrency
    paymentPartner
    paymentAccountRequirementType
    requirementFields {
      key
      label
      isMandatory
      type
      regex
      allowedValues {
        key
        value
      }
      errorMessage
      hasSubFields
      subFields {
        key
        value {
          key
          label
          isMandatory
          type
          regex
          allowedValues {
            key
            value
          }
          errorMessage
          hasSubFields
          subFields {
            key
            value {
              key
              label
              isMandatory
              type
              regex
              errorMessage
              allowedValues {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;
export const DocumentReadableFragmentDoc = gql`
  fragment documentReadable on DocumentReadable {
    id
    name
    link
    blob
    extension
    contentType
  }
`;
export const TimesheetEntryFragmentDoc = gql`
  fragment timesheetEntry on TimesheetEntry {
    id
    date
    hours
    dayType
    regularTimeInMinutes
    overtimeTotalInMinutes
    overtimeAtNightInMinutes
    nightShiftTimeInMinutes
    lateTimeInMinutes
    status
    updatedOn
    workTimes {
      startTime
      endTime
    }
    breakTimes {
      startTime
      endTime
    }
    isPaidLeave
    comment
    submittedOn
    approvedOn
    rejectedOn
    addedByInfo {
      userId
      experience
    }
  }
`;
export const GetMemberDetailsDocument = gql`
  query GetMemberDetails {
    member {
      id
      fullLegalName
      emails {
        email
      }
      contracts(includeAnyStatus: true) {
        position
        startOn
      }
      legalData {
        key
        value
        label
      }
      nationalities {
        country
        type
      }
    }
  }
`;

/**
 * __useGetMemberDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberDetailsQuery,
    GetMemberDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberDetailsQuery, GetMemberDetailsQueryVariables>(
    GetMemberDetailsDocument,
    options,
  );
}
export function useGetMemberDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberDetailsQuery,
    GetMemberDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberDetailsQuery,
    GetMemberDetailsQueryVariables
  >(GetMemberDetailsDocument, options);
}
export type GetMemberDetailsQueryHookResult = ReturnType<
  typeof useGetMemberDetailsQuery
>;
export type GetMemberDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberDetailsLazyQuery
>;
export type GetMemberDetailsQueryResult = Apollo.QueryResult<
  GetMemberDetailsQuery,
  GetMemberDetailsQueryVariables
>;
export const GetConvertedCurrencyDocument = gql`
  query GetConvertedCurrency($params: CurrencyConversionParams!) {
    convertCurrency(params: $params)
  }
`;

/**
 * __useGetConvertedCurrencyQuery__
 *
 * To run a query within a React component, call `useGetConvertedCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConvertedCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConvertedCurrencyQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetConvertedCurrencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConvertedCurrencyQuery,
    GetConvertedCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConvertedCurrencyQuery,
    GetConvertedCurrencyQueryVariables
  >(GetConvertedCurrencyDocument, options);
}
export function useGetConvertedCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConvertedCurrencyQuery,
    GetConvertedCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConvertedCurrencyQuery,
    GetConvertedCurrencyQueryVariables
  >(GetConvertedCurrencyDocument, options);
}
export type GetConvertedCurrencyQueryHookResult = ReturnType<
  typeof useGetConvertedCurrencyQuery
>;
export type GetConvertedCurrencyLazyQueryHookResult = ReturnType<
  typeof useGetConvertedCurrencyLazyQuery
>;
export type GetConvertedCurrencyQueryResult = Apollo.QueryResult<
  GetConvertedCurrencyQuery,
  GetConvertedCurrencyQueryVariables
>;
export const GetCompanyHomeCurrencyDocument = gql`
  query GetCompanyHomeCurrency {
    company {
      id
      primaryEntity {
        id
        currency
      }
    }
  }
`;

/**
 * __useGetCompanyHomeCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCompanyHomeCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyHomeCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyHomeCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyHomeCurrencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyHomeCurrencyQuery,
    GetCompanyHomeCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyHomeCurrencyQuery,
    GetCompanyHomeCurrencyQueryVariables
  >(GetCompanyHomeCurrencyDocument, options);
}
export function useGetCompanyHomeCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyHomeCurrencyQuery,
    GetCompanyHomeCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyHomeCurrencyQuery,
    GetCompanyHomeCurrencyQueryVariables
  >(GetCompanyHomeCurrencyDocument, options);
}
export type GetCompanyHomeCurrencyQueryHookResult = ReturnType<
  typeof useGetCompanyHomeCurrencyQuery
>;
export type GetCompanyHomeCurrencyLazyQueryHookResult = ReturnType<
  typeof useGetCompanyHomeCurrencyLazyQuery
>;
export type GetCompanyHomeCurrencyQueryResult = Apollo.QueryResult<
  GetCompanyHomeCurrencyQuery,
  GetCompanyHomeCurrencyQueryVariables
>;
export const GetCompanyNameDocument = gql`
  query GetCompanyName {
    company {
      id
      displayName
      companyLogo {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
        }
      }
      primaryEntity {
        id
        legalName
        address {
          country
        }
      }
    }
  }
`;

/**
 * __useGetCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyNameQuery,
    GetCompanyNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(
    GetCompanyNameDocument,
    options,
  );
}
export function useGetCompanyNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyNameQuery,
    GetCompanyNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(
    GetCompanyNameDocument,
    options,
  );
}
export type GetCompanyNameQueryHookResult = ReturnType<
  typeof useGetCompanyNameQuery
>;
export type GetCompanyNameLazyQueryHookResult = ReturnType<
  typeof useGetCompanyNameLazyQuery
>;
export type GetCompanyNameQueryResult = Apollo.QueryResult<
  GetCompanyNameQuery,
  GetCompanyNameQueryVariables
>;
export const GetCompanyStatusAndCsmDocument = gql`
  query GetCompanyStatusAndCSM {
    company {
      id
      status
      csmUser {
        id
      }
    }
  }
`;

/**
 * __useGetCompanyStatusAndCsmQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatusAndCsmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatusAndCsmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatusAndCsmQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyStatusAndCsmQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyStatusAndCsmQuery,
    GetCompanyStatusAndCsmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyStatusAndCsmQuery,
    GetCompanyStatusAndCsmQueryVariables
  >(GetCompanyStatusAndCsmDocument, options);
}
export function useGetCompanyStatusAndCsmLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyStatusAndCsmQuery,
    GetCompanyStatusAndCsmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyStatusAndCsmQuery,
    GetCompanyStatusAndCsmQueryVariables
  >(GetCompanyStatusAndCsmDocument, options);
}
export type GetCompanyStatusAndCsmQueryHookResult = ReturnType<
  typeof useGetCompanyStatusAndCsmQuery
>;
export type GetCompanyStatusAndCsmLazyQueryHookResult = ReturnType<
  typeof useGetCompanyStatusAndCsmLazyQuery
>;
export type GetCompanyStatusAndCsmQueryResult = Apollo.QueryResult<
  GetCompanyStatusAndCsmQuery,
  GetCompanyStatusAndCsmQueryVariables
>;
export const GetMemberContractStatusDocument = gql`
  query GetMemberContractStatus {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        status
        contractStarted
      }
    }
  }
`;

/**
 * __useGetMemberContractStatusQuery__
 *
 * To run a query within a React component, call `useGetMemberContractStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberContractStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberContractStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberContractStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberContractStatusQuery,
    GetMemberContractStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberContractStatusQuery,
    GetMemberContractStatusQueryVariables
  >(GetMemberContractStatusDocument, options);
}
export function useGetMemberContractStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberContractStatusQuery,
    GetMemberContractStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberContractStatusQuery,
    GetMemberContractStatusQueryVariables
  >(GetMemberContractStatusDocument, options);
}
export type GetMemberContractStatusQueryHookResult = ReturnType<
  typeof useGetMemberContractStatusQuery
>;
export type GetMemberContractStatusLazyQueryHookResult = ReturnType<
  typeof useGetMemberContractStatusLazyQuery
>;
export type GetMemberContractStatusQueryResult = Apollo.QueryResult<
  GetMemberContractStatusQuery,
  GetMemberContractStatusQueryVariables
>;
export const GetCurrencyConversionDocument = gql`
  query GetCurrencyConversion(
    $code: CurrencyCode
    $to: CurrencyCode
    $amount: Float
    $source: CurrencyConversionSource
    $forDate: Date
  ) {
    currency(code: $code) {
      convert(to: $to, amount: $amount, source: $source, forDate: $forDate)
    }
  }
`;

/**
 * __useGetCurrencyConversionQuery__
 *
 * To run a query within a React component, call `useGetCurrencyConversionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyConversionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyConversionQuery({
 *   variables: {
 *      code: // value for 'code'
 *      to: // value for 'to'
 *      amount: // value for 'amount'
 *      source: // value for 'source'
 *      forDate: // value for 'forDate'
 *   },
 * });
 */
export function useGetCurrencyConversionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrencyConversionQuery,
    GetCurrencyConversionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrencyConversionQuery,
    GetCurrencyConversionQueryVariables
  >(GetCurrencyConversionDocument, options);
}
export function useGetCurrencyConversionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrencyConversionQuery,
    GetCurrencyConversionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrencyConversionQuery,
    GetCurrencyConversionQueryVariables
  >(GetCurrencyConversionDocument, options);
}
export type GetCurrencyConversionQueryHookResult = ReturnType<
  typeof useGetCurrencyConversionQuery
>;
export type GetCurrencyConversionLazyQueryHookResult = ReturnType<
  typeof useGetCurrencyConversionLazyQuery
>;
export type GetCurrencyConversionQueryResult = Apollo.QueryResult<
  GetCurrencyConversionQuery,
  GetCurrencyConversionQueryVariables
>;
export const GetMemberComplianceTypeDocument = gql`
  query GetMemberComplianceType {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        compliance {
          type
        }
      }
    }
  }
`;

/**
 * __useGetMemberComplianceTypeQuery__
 *
 * To run a query within a React component, call `useGetMemberComplianceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberComplianceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberComplianceTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberComplianceTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberComplianceTypeQuery,
    GetMemberComplianceTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberComplianceTypeQuery,
    GetMemberComplianceTypeQueryVariables
  >(GetMemberComplianceTypeDocument, options);
}
export function useGetMemberComplianceTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberComplianceTypeQuery,
    GetMemberComplianceTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberComplianceTypeQuery,
    GetMemberComplianceTypeQueryVariables
  >(GetMemberComplianceTypeDocument, options);
}
export type GetMemberComplianceTypeQueryHookResult = ReturnType<
  typeof useGetMemberComplianceTypeQuery
>;
export type GetMemberComplianceTypeLazyQueryHookResult = ReturnType<
  typeof useGetMemberComplianceTypeLazyQuery
>;
export type GetMemberComplianceTypeQueryResult = Apollo.QueryResult<
  GetMemberComplianceTypeQuery,
  GetMemberComplianceTypeQueryVariables
>;
export const GetMemberHomeCurrencyDocument = gql`
  query GetMemberHomeCurrency {
    member {
      id
      contracts {
        id
        currency
      }
    }
  }
`;

/**
 * __useGetMemberHomeCurrencyQuery__
 *
 * To run a query within a React component, call `useGetMemberHomeCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberHomeCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberHomeCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberHomeCurrencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberHomeCurrencyQuery,
    GetMemberHomeCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberHomeCurrencyQuery,
    GetMemberHomeCurrencyQueryVariables
  >(GetMemberHomeCurrencyDocument, options);
}
export function useGetMemberHomeCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberHomeCurrencyQuery,
    GetMemberHomeCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberHomeCurrencyQuery,
    GetMemberHomeCurrencyQueryVariables
  >(GetMemberHomeCurrencyDocument, options);
}
export type GetMemberHomeCurrencyQueryHookResult = ReturnType<
  typeof useGetMemberHomeCurrencyQuery
>;
export type GetMemberHomeCurrencyLazyQueryHookResult = ReturnType<
  typeof useGetMemberHomeCurrencyLazyQuery
>;
export type GetMemberHomeCurrencyQueryResult = Apollo.QueryResult<
  GetMemberHomeCurrencyQuery,
  GetMemberHomeCurrencyQueryVariables
>;
export const GetCompanyCalendarCountriesDocument = gql`
  query GetCompanyCalendarCountries {
    company {
      primaryEntity {
        id
        address {
          country
        }
      }
      contracts {
        id
        country
        type
        member {
          userId
          fullLegalName
          firstName
          lastName
        }
        legalEntityId
      }
    }
  }
`;

/**
 * __useGetCompanyCalendarCountriesQuery__
 *
 * To run a query within a React component, call `useGetCompanyCalendarCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCalendarCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCalendarCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCalendarCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyCalendarCountriesQuery,
    GetCompanyCalendarCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyCalendarCountriesQuery,
    GetCompanyCalendarCountriesQueryVariables
  >(GetCompanyCalendarCountriesDocument, options);
}
export function useGetCompanyCalendarCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCalendarCountriesQuery,
    GetCompanyCalendarCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyCalendarCountriesQuery,
    GetCompanyCalendarCountriesQueryVariables
  >(GetCompanyCalendarCountriesDocument, options);
}
export type GetCompanyCalendarCountriesQueryHookResult = ReturnType<
  typeof useGetCompanyCalendarCountriesQuery
>;
export type GetCompanyCalendarCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCalendarCountriesLazyQuery
>;
export type GetCompanyCalendarCountriesQueryResult = Apollo.QueryResult<
  GetCompanyCalendarCountriesQuery,
  GetCompanyCalendarCountriesQueryVariables
>;
export const GetMemberCalendarCountriesDocument = gql`
  query GetMemberCalendarCountries {
    member {
      id
      contracts {
        id
        country
        legalEntityId
        type
        company {
          id
          primaryEntity {
            id
            address {
              country
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberCalendarCountriesQuery__
 *
 * To run a query within a React component, call `useGetMemberCalendarCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberCalendarCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberCalendarCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberCalendarCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberCalendarCountriesQuery,
    GetMemberCalendarCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberCalendarCountriesQuery,
    GetMemberCalendarCountriesQueryVariables
  >(GetMemberCalendarCountriesDocument, options);
}
export function useGetMemberCalendarCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberCalendarCountriesQuery,
    GetMemberCalendarCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberCalendarCountriesQuery,
    GetMemberCalendarCountriesQueryVariables
  >(GetMemberCalendarCountriesDocument, options);
}
export type GetMemberCalendarCountriesQueryHookResult = ReturnType<
  typeof useGetMemberCalendarCountriesQuery
>;
export type GetMemberCalendarCountriesLazyQueryHookResult = ReturnType<
  typeof useGetMemberCalendarCountriesLazyQuery
>;
export type GetMemberCalendarCountriesQueryResult = Apollo.QueryResult<
  GetMemberCalendarCountriesQuery,
  GetMemberCalendarCountriesQueryVariables
>;
export const GetCountryHolidaysDocument = gql`
  query GetCountryHolidays(
    $countries: [CountryCode]!
    $year: Int!
    $month: Int
  ) {
    holidays(countries: $countries, year: $year, month: $month) {
      key
      label
      year
      month
      date
      name
      countryCode
    }
  }
`;

/**
 * __useGetCountryHolidaysQuery__
 *
 * To run a query within a React component, call `useGetCountryHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryHolidaysQuery({
 *   variables: {
 *      countries: // value for 'countries'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetCountryHolidaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryHolidaysQuery,
    GetCountryHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryHolidaysQuery,
    GetCountryHolidaysQueryVariables
  >(GetCountryHolidaysDocument, options);
}
export function useGetCountryHolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryHolidaysQuery,
    GetCountryHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryHolidaysQuery,
    GetCountryHolidaysQueryVariables
  >(GetCountryHolidaysDocument, options);
}
export type GetCountryHolidaysQueryHookResult = ReturnType<
  typeof useGetCountryHolidaysQuery
>;
export type GetCountryHolidaysLazyQueryHookResult = ReturnType<
  typeof useGetCountryHolidaysLazyQuery
>;
export type GetCountryHolidaysQueryResult = Apollo.QueryResult<
  GetCountryHolidaysQuery,
  GetCountryHolidaysQueryVariables
>;
export const GetEntitiesHolidaysDocument = gql`
  query GetEntitiesHolidays(
    $companyId: ID
    $legalEntityId: ID
    $year: Int
    $input: LegalEntityHolidayInput
  ) {
    company(id: $companyId) {
      otherEntities(legalEntityId: $legalEntityId) {
        holidays(year: $year, input: $input) {
          label
          legalEntityId
          key
          year
          month
          date
          name
          countryCode
        }
      }
    }
  }
`;

/**
 * __useGetEntitiesHolidaysQuery__
 *
 * To run a query within a React component, call `useGetEntitiesHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitiesHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitiesHolidaysQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      legalEntityId: // value for 'legalEntityId'
 *      year: // value for 'year'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEntitiesHolidaysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEntitiesHolidaysQuery,
    GetEntitiesHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEntitiesHolidaysQuery,
    GetEntitiesHolidaysQueryVariables
  >(GetEntitiesHolidaysDocument, options);
}
export function useGetEntitiesHolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntitiesHolidaysQuery,
    GetEntitiesHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEntitiesHolidaysQuery,
    GetEntitiesHolidaysQueryVariables
  >(GetEntitiesHolidaysDocument, options);
}
export type GetEntitiesHolidaysQueryHookResult = ReturnType<
  typeof useGetEntitiesHolidaysQuery
>;
export type GetEntitiesHolidaysLazyQueryHookResult = ReturnType<
  typeof useGetEntitiesHolidaysLazyQuery
>;
export type GetEntitiesHolidaysQueryResult = Apollo.QueryResult<
  GetEntitiesHolidaysQuery,
  GetEntitiesHolidaysQueryVariables
>;
export const GetMemberOnboardingContractDocument = gql`
  query GetMemberOnboardingContract {
    member {
      id
      status
      contracts(status: ONBOARDING) {
        id
        status
        onboarding {
          id
        }
      }
    }
  }
`;

/**
 * __useGetMemberOnboardingContractQuery__
 *
 * To run a query within a React component, call `useGetMemberOnboardingContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberOnboardingContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberOnboardingContractQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberOnboardingContractQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberOnboardingContractQuery,
    GetMemberOnboardingContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberOnboardingContractQuery,
    GetMemberOnboardingContractQueryVariables
  >(GetMemberOnboardingContractDocument, options);
}
export function useGetMemberOnboardingContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberOnboardingContractQuery,
    GetMemberOnboardingContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberOnboardingContractQuery,
    GetMemberOnboardingContractQueryVariables
  >(GetMemberOnboardingContractDocument, options);
}
export type GetMemberOnboardingContractQueryHookResult = ReturnType<
  typeof useGetMemberOnboardingContractQuery
>;
export type GetMemberOnboardingContractLazyQueryHookResult = ReturnType<
  typeof useGetMemberOnboardingContractLazyQuery
>;
export type GetMemberOnboardingContractQueryResult = Apollo.QueryResult<
  GetMemberOnboardingContractQuery,
  GetMemberOnboardingContractQueryVariables
>;
export const GetCompanyCsmDocument = gql`
  query GetCompanyCSM {
    company {
      id
      csmUser {
        id
        firstName
        lastName
        emails {
          type
          email
        }
        phoneNos {
          type
          phoneNo
        }
        roles
        calendlyLink
        profilePicture {
          downloadUrl
        }
      }
    }
  }
`;

/**
 * __useGetCompanyCsmQuery__
 *
 * To run a query within a React component, call `useGetCompanyCsmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCsmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCsmQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCsmQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyCsmQuery,
    GetCompanyCsmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyCsmQuery, GetCompanyCsmQueryVariables>(
    GetCompanyCsmDocument,
    options,
  );
}
export function useGetCompanyCsmLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCsmQuery,
    GetCompanyCsmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyCsmQuery, GetCompanyCsmQueryVariables>(
    GetCompanyCsmDocument,
    options,
  );
}
export type GetCompanyCsmQueryHookResult = ReturnType<
  typeof useGetCompanyCsmQuery
>;
export type GetCompanyCsmLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCsmLazyQuery
>;
export type GetCompanyCsmQueryResult = Apollo.QueryResult<
  GetCompanyCsmQuery,
  GetCompanyCsmQueryVariables
>;
export const GetCountryWithLegalEntityDocument = gql`
  query GetCountryWithLegalEntity($country: CountryCode!) {
    country(country: $country) {
      code
      compliance {
        countryCode
        legalEntity(type: MULTIPLIER) {
          legalEntity {
            legalName
            address {
              line1
              line2
              country
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCountryWithLegalEntityQuery__
 *
 * To run a query within a React component, call `useGetCountryWithLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryWithLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryWithLegalEntityQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCountryWithLegalEntityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryWithLegalEntityQuery,
    GetCountryWithLegalEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryWithLegalEntityQuery,
    GetCountryWithLegalEntityQueryVariables
  >(GetCountryWithLegalEntityDocument, options);
}
export function useGetCountryWithLegalEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryWithLegalEntityQuery,
    GetCountryWithLegalEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryWithLegalEntityQuery,
    GetCountryWithLegalEntityQueryVariables
  >(GetCountryWithLegalEntityDocument, options);
}
export type GetCountryWithLegalEntityQueryHookResult = ReturnType<
  typeof useGetCountryWithLegalEntityQuery
>;
export type GetCountryWithLegalEntityLazyQueryHookResult = ReturnType<
  typeof useGetCountryWithLegalEntityLazyQuery
>;
export type GetCountryWithLegalEntityQueryResult = Apollo.QueryResult<
  GetCountryWithLegalEntityQuery,
  GetCountryWithLegalEntityQueryVariables
>;
export const GetMemberWithLegalEntityDocument = gql`
  query GetMemberWithLegalEntity {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        country
        compliance {
          type
          legalEntity {
            legalName
            address {
              line1
              line2
              country
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberWithLegalEntityQuery__
 *
 * To run a query within a React component, call `useGetMemberWithLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberWithLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberWithLegalEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberWithLegalEntityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberWithLegalEntityQuery,
    GetMemberWithLegalEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberWithLegalEntityQuery,
    GetMemberWithLegalEntityQueryVariables
  >(GetMemberWithLegalEntityDocument, options);
}
export function useGetMemberWithLegalEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberWithLegalEntityQuery,
    GetMemberWithLegalEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberWithLegalEntityQuery,
    GetMemberWithLegalEntityQueryVariables
  >(GetMemberWithLegalEntityDocument, options);
}
export type GetMemberWithLegalEntityQueryHookResult = ReturnType<
  typeof useGetMemberWithLegalEntityQuery
>;
export type GetMemberWithLegalEntityLazyQueryHookResult = ReturnType<
  typeof useGetMemberWithLegalEntityLazyQuery
>;
export type GetMemberWithLegalEntityQueryResult = Apollo.QueryResult<
  GetMemberWithLegalEntityQuery,
  GetMemberWithLegalEntityQueryVariables
>;
export const ChangeCountryDocument = gql`
  mutation ChangeCountry($id: ID!, $input: ContractUpdateCountryInput!) {
    contractChangeCountry(id: $id, input: $input) {
      id
      country
      workStatus
      countryStateCode
      alreadyHired
      onboarding {
        status
        steps
        current
      }
    }
  }
`;
export type ChangeCountryMutationFn = Apollo.MutationFunction<
  ChangeCountryMutation,
  ChangeCountryMutationVariables
>;

/**
 * __useChangeCountryMutation__
 *
 * To run a mutation, you first call `useChangeCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCountryMutation, { data, loading, error }] = useChangeCountryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeCountryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeCountryMutation,
    ChangeCountryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeCountryMutation,
    ChangeCountryMutationVariables
  >(ChangeCountryDocument, options);
}
export type ChangeCountryMutationHookResult = ReturnType<
  typeof useChangeCountryMutation
>;
export type ChangeCountryMutationResult = Apollo.MutationResult<ChangeCountryMutation>;
export type ChangeCountryMutationOptions = Apollo.BaseMutationOptions<
  ChangeCountryMutation,
  ChangeCountryMutationVariables
>;
export const CompanyMsaRequestDocument = gql`
  mutation CompanyMsaRequest {
    companyMSARequest {
      id
      type
      requester {
        ... on CompanyUser {
          id
        }
      }
      status
      comment
    }
  }
`;
export type CompanyMsaRequestMutationFn = Apollo.MutationFunction<
  CompanyMsaRequestMutation,
  CompanyMsaRequestMutationVariables
>;

/**
 * __useCompanyMsaRequestMutation__
 *
 * To run a mutation, you first call `useCompanyMsaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyMsaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyMsaRequestMutation, { data, loading, error }] = useCompanyMsaRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompanyMsaRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyMsaRequestMutation,
    CompanyMsaRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyMsaRequestMutation,
    CompanyMsaRequestMutationVariables
  >(CompanyMsaRequestDocument, options);
}
export type CompanyMsaRequestMutationHookResult = ReturnType<
  typeof useCompanyMsaRequestMutation
>;
export type CompanyMsaRequestMutationResult = Apollo.MutationResult<CompanyMsaRequestMutation>;
export type CompanyMsaRequestMutationOptions = Apollo.BaseMutationOptions<
  CompanyMsaRequestMutation,
  CompanyMsaRequestMutationVariables
>;
export const ContractPreparingConfirmationDocument = gql`
  mutation ContractPreparingConfirmation($id: ID!) {
    contractConfirmContractPrepare(id: $id) {
      id
      type
      status
      onboarding {
        status
        statuses
        steps
        current
      }
      depositPayable {
        id
        invoice {
          id
          status
        }
      }
    }
  }
`;
export type ContractPreparingConfirmationMutationFn = Apollo.MutationFunction<
  ContractPreparingConfirmationMutation,
  ContractPreparingConfirmationMutationVariables
>;

/**
 * __useContractPreparingConfirmationMutation__
 *
 * To run a mutation, you first call `useContractPreparingConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractPreparingConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractPreparingConfirmationMutation, { data, loading, error }] = useContractPreparingConfirmationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractPreparingConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractPreparingConfirmationMutation,
    ContractPreparingConfirmationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractPreparingConfirmationMutation,
    ContractPreparingConfirmationMutationVariables
  >(ContractPreparingConfirmationDocument, options);
}
export type ContractPreparingConfirmationMutationHookResult = ReturnType<
  typeof useContractPreparingConfirmationMutation
>;
export type ContractPreparingConfirmationMutationResult = Apollo.MutationResult<ContractPreparingConfirmationMutation>;
export type ContractPreparingConfirmationMutationOptions = Apollo.BaseMutationOptions<
  ContractPreparingConfirmationMutation,
  ContractPreparingConfirmationMutationVariables
>;
export const GetContractTemplatesDocument = gql`
  query GetContractTemplates($id: ID!, $filter: TemplateFilter!) {
    company(id: $id) {
      id
      templates(filter: $filter) {
        id
        entityId
        externalId
        displayName
        contractId
        country
        countryState
        language
        type
        link
        metadata {
          languagesCount
        }
      }
    }
  }
`;

/**
 * __useGetContractTemplatesQuery__
 *
 * To run a query within a React component, call `useGetContractTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTemplatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetContractTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractTemplatesQuery,
    GetContractTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractTemplatesQuery,
    GetContractTemplatesQueryVariables
  >(GetContractTemplatesDocument, options);
}
export function useGetContractTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractTemplatesQuery,
    GetContractTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractTemplatesQuery,
    GetContractTemplatesQueryVariables
  >(GetContractTemplatesDocument, options);
}
export type GetContractTemplatesQueryHookResult = ReturnType<
  typeof useGetContractTemplatesQuery
>;
export type GetContractTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetContractTemplatesLazyQuery
>;
export type GetContractTemplatesQueryResult = Apollo.QueryResult<
  GetContractTemplatesQuery,
  GetContractTemplatesQueryVariables
>;
export const CreateContractDocument = gql`
  mutation CreateContract($input: ContractCreateInput!) {
    contractCreate(input: $input) {
      id
      member {
        id
        firstName
        lastName
        status
        maritalStatus
      }
      compensation {
        basePay {
          amount
          frequency
          currency
        }
      }
      type
      country
      workStatus
      countryStateCode
      alreadyHired
      term
      position
      startOn
      endOn
      endedOn
      offboardingNote
      scope
      status
      employeeId
      onboarding {
        id
        status
        steps
        current
        activationCutoff
        tasks {
          name
          order
          completed
          pendingOn {
            id
            persona
            firstName
            lastName
          }
        }
      }
      workShift {
        ...workShift
      }
      createdOn
      contractStarted
      contractBlocked
      lastWorkingDay
      offboarding {
        id
        type
        lastWorkingDay
        status
      }
    }
  }
  ${WorkShiftFragmentDoc}
`;
export type CreateContractMutationFn = Apollo.MutationFunction<
  CreateContractMutation,
  CreateContractMutationVariables
>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractMutation,
    CreateContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContractMutation,
    CreateContractMutationVariables
  >(CreateContractDocument, options);
}
export type CreateContractMutationHookResult = ReturnType<
  typeof useCreateContractMutation
>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  CreateContractMutation,
  CreateContractMutationVariables
>;
export const CreateMemberDocument = gql`
  mutation CreateMember($input: MemberCreateInput!) {
    memberCreate(value: $input) {
      id
    }
  }
`;
export type CreateMemberMutationFn = Apollo.MutationFunction<
  CreateMemberMutation,
  CreateMemberMutationVariables
>;

/**
 * __useCreateMemberMutation__
 *
 * To run a mutation, you first call `useCreateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberMutation, { data, loading, error }] = useCreateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberMutation,
    CreateMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberMutation,
    CreateMemberMutationVariables
  >(CreateMemberDocument, options);
}
export type CreateMemberMutationHookResult = ReturnType<
  typeof useCreateMemberMutation
>;
export type CreateMemberMutationResult = Apollo.MutationResult<CreateMemberMutation>;
export type CreateMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberMutation,
  CreateMemberMutationVariables
>;
export const GetCompanyDuplicateDomainDocument = gql`
  query GetCompanyDuplicateDomain {
    companyHasDuplicatedDomain
  }
`;

/**
 * __useGetCompanyDuplicateDomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyDuplicateDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDuplicateDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDuplicateDomainQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyDuplicateDomainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyDuplicateDomainQuery,
    GetCompanyDuplicateDomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyDuplicateDomainQuery,
    GetCompanyDuplicateDomainQueryVariables
  >(GetCompanyDuplicateDomainDocument, options);
}
export function useGetCompanyDuplicateDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDuplicateDomainQuery,
    GetCompanyDuplicateDomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyDuplicateDomainQuery,
    GetCompanyDuplicateDomainQueryVariables
  >(GetCompanyDuplicateDomainDocument, options);
}
export type GetCompanyDuplicateDomainQueryHookResult = ReturnType<
  typeof useGetCompanyDuplicateDomainQuery
>;
export type GetCompanyDuplicateDomainLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDuplicateDomainLazyQuery
>;
export type GetCompanyDuplicateDomainQueryResult = Apollo.QueryResult<
  GetCompanyDuplicateDomainQuery,
  GetCompanyDuplicateDomainQueryVariables
>;
export const GetCompanyMsaRequirementsDocument = gql`
  query GetCompanyMsaRequirements {
    company {
      id
      primaryEntity {
        id
        registrationNo
        legalName
        address {
          key
        }
        phone
      }
    }
  }
`;

/**
 * __useGetCompanyMsaRequirementsQuery__
 *
 * To run a query within a React component, call `useGetCompanyMsaRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMsaRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMsaRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyMsaRequirementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyMsaRequirementsQuery,
    GetCompanyMsaRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyMsaRequirementsQuery,
    GetCompanyMsaRequirementsQueryVariables
  >(GetCompanyMsaRequirementsDocument, options);
}
export function useGetCompanyMsaRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMsaRequirementsQuery,
    GetCompanyMsaRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMsaRequirementsQuery,
    GetCompanyMsaRequirementsQueryVariables
  >(GetCompanyMsaRequirementsDocument, options);
}
export type GetCompanyMsaRequirementsQueryHookResult = ReturnType<
  typeof useGetCompanyMsaRequirementsQuery
>;
export type GetCompanyMsaRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMsaRequirementsLazyQuery
>;
export type GetCompanyMsaRequirementsQueryResult = Apollo.QueryResult<
  GetCompanyMsaRequirementsQuery,
  GetCompanyMsaRequirementsQueryVariables
>;
export const GetCompanyMsaStatusDocument = gql`
  query GetCompanyMsaStatus {
    company {
      id
      onboarding {
        msaWorkflow {
          currentStatus
        }
      }
    }
  }
`;

/**
 * __useGetCompanyMsaStatusQuery__
 *
 * To run a query within a React component, call `useGetCompanyMsaStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMsaStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMsaStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyMsaStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyMsaStatusQuery,
    GetCompanyMsaStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyMsaStatusQuery,
    GetCompanyMsaStatusQueryVariables
  >(GetCompanyMsaStatusDocument, options);
}
export function useGetCompanyMsaStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMsaStatusQuery,
    GetCompanyMsaStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMsaStatusQuery,
    GetCompanyMsaStatusQueryVariables
  >(GetCompanyMsaStatusDocument, options);
}
export type GetCompanyMsaStatusQueryHookResult = ReturnType<
  typeof useGetCompanyMsaStatusQuery
>;
export type GetCompanyMsaStatusLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMsaStatusLazyQuery
>;
export type GetCompanyMsaStatusQueryResult = Apollo.QueryResult<
  GetCompanyMsaStatusQuery,
  GetCompanyMsaStatusQueryVariables
>;
export const GetCompanyTimeOffRequirementsDocument = gql`
  query GetCompanyTimeOffRequirements(
    $contractType: ContractType!
    $country: CountryCode!
    $countryStateCode: String
    $contractTerm: ContractTerm
    $contractStatus: ContractStatus
    $companyId: ID
  ) {
    company {
      timeOffRequirements(
        filters: {
          contractType: $contractType
          term: $contractTerm
          contractStatus: $contractStatus
          country: { code: $country, stateCode: $countryStateCode }
          companyId: $companyId
        }
      ) {
        clause
        definitions {
          type
          required
          label
          description
          validation {
            ... on TimeOffFixedValidation {
              minimum
              maximum
              defaultValue
              unit
            }
          }
          assignedEntityNames
        }
      }
    }
  }
`;

/**
 * __useGetCompanyTimeOffRequirementsQuery__
 *
 * To run a query within a React component, call `useGetCompanyTimeOffRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTimeOffRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTimeOffRequirementsQuery({
 *   variables: {
 *      contractType: // value for 'contractType'
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      contractTerm: // value for 'contractTerm'
 *      contractStatus: // value for 'contractStatus'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyTimeOffRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyTimeOffRequirementsQuery,
    GetCompanyTimeOffRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyTimeOffRequirementsQuery,
    GetCompanyTimeOffRequirementsQueryVariables
  >(GetCompanyTimeOffRequirementsDocument, options);
}
export function useGetCompanyTimeOffRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyTimeOffRequirementsQuery,
    GetCompanyTimeOffRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyTimeOffRequirementsQuery,
    GetCompanyTimeOffRequirementsQueryVariables
  >(GetCompanyTimeOffRequirementsDocument, options);
}
export type GetCompanyTimeOffRequirementsQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffRequirementsQuery
>;
export type GetCompanyTimeOffRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffRequirementsLazyQuery
>;
export type GetCompanyTimeOffRequirementsQueryResult = Apollo.QueryResult<
  GetCompanyTimeOffRequirementsQuery,
  GetCompanyTimeOffRequirementsQueryVariables
>;
export const GetCompensationStructureDefinitionDocument = gql`
  query GetCompensationStructureDefinition(
    $country: CountryCode!
    $countryStateCode: String
    $type: ContractType
    $term: ContractTerm
  ) {
    country(country: $country) {
      code
      compliance(countryStateCode: $countryStateCode) {
        countryCode
        countryState {
          code
          name
        }
        compensationStructureDefinition(type: $type, term: $term) {
          basePay {
            ...compensationPayComponentDefinition
          }
          additionalPays {
            ...compensationPayComponentDefinition
          }
        }
      }
    }
  }
  ${CompensationPayComponentDefinitionFragmentDoc}
`;

/**
 * __useGetCompensationStructureDefinitionQuery__
 *
 * To run a query within a React component, call `useGetCompensationStructureDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompensationStructureDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompensationStructureDefinitionQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      type: // value for 'type'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useGetCompensationStructureDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompensationStructureDefinitionQuery,
    GetCompensationStructureDefinitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompensationStructureDefinitionQuery,
    GetCompensationStructureDefinitionQueryVariables
  >(GetCompensationStructureDefinitionDocument, options);
}
export function useGetCompensationStructureDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompensationStructureDefinitionQuery,
    GetCompensationStructureDefinitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompensationStructureDefinitionQuery,
    GetCompensationStructureDefinitionQueryVariables
  >(GetCompensationStructureDefinitionDocument, options);
}
export type GetCompensationStructureDefinitionQueryHookResult = ReturnType<
  typeof useGetCompensationStructureDefinitionQuery
>;
export type GetCompensationStructureDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetCompensationStructureDefinitionLazyQuery
>;
export type GetCompensationStructureDefinitionQueryResult = Apollo.QueryResult<
  GetCompensationStructureDefinitionQuery,
  GetCompensationStructureDefinitionQueryVariables
>;
export const GetCompliancePageInsightsDocument = gql`
  query GetCompliancePageInsights(
    $country: CountryCode!
    $contractTerm: ContractTerm
    $contractType: ContractType
  ) {
    country(country: $country) {
      code
      insights(
        filter: {
          contractTerm: $contractTerm
          contractType: $contractType
          categories: [COMPLIANCE, TIME_OFF]
        }
      ) {
        id
        category
        description
        ... on ComplianceInsightDefinition {
          complianceRecommendedValue: recommendedValue
          complianceParamPeriodUnit
          complianceParam {
            key
            label
          }
        }
        ... on TimeOffInsightDefinition {
          timeOffRecommendedValue: recommendedValue
          timeOffUnit
          timeOffTypeDefinition {
            type
            label
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompliancePageInsightsQuery__
 *
 * To run a query within a React component, call `useGetCompliancePageInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompliancePageInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompliancePageInsightsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      contractTerm: // value for 'contractTerm'
 *      contractType: // value for 'contractType'
 *   },
 * });
 */
export function useGetCompliancePageInsightsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompliancePageInsightsQuery,
    GetCompliancePageInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompliancePageInsightsQuery,
    GetCompliancePageInsightsQueryVariables
  >(GetCompliancePageInsightsDocument, options);
}
export function useGetCompliancePageInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompliancePageInsightsQuery,
    GetCompliancePageInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompliancePageInsightsQuery,
    GetCompliancePageInsightsQueryVariables
  >(GetCompliancePageInsightsDocument, options);
}
export type GetCompliancePageInsightsQueryHookResult = ReturnType<
  typeof useGetCompliancePageInsightsQuery
>;
export type GetCompliancePageInsightsLazyQueryHookResult = ReturnType<
  typeof useGetCompliancePageInsightsLazyQuery
>;
export type GetCompliancePageInsightsQueryResult = Apollo.QueryResult<
  GetCompliancePageInsightsQuery,
  GetCompliancePageInsightsQueryVariables
>;
export const GetCompliantCurrenciesDocument = gql`
  query GetCompliantCurrencies($country: CountryCode) {
    countries(country: $country) {
      code
      compliantCurrencies
    }
  }
`;

/**
 * __useGetCompliantCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCompliantCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompliantCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompliantCurrenciesQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCompliantCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompliantCurrenciesQuery,
    GetCompliantCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompliantCurrenciesQuery,
    GetCompliantCurrenciesQueryVariables
  >(GetCompliantCurrenciesDocument, options);
}
export function useGetCompliantCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompliantCurrenciesQuery,
    GetCompliantCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompliantCurrenciesQuery,
    GetCompliantCurrenciesQueryVariables
  >(GetCompliantCurrenciesDocument, options);
}
export type GetCompliantCurrenciesQueryHookResult = ReturnType<
  typeof useGetCompliantCurrenciesQuery
>;
export type GetCompliantCurrenciesLazyQueryHookResult = ReturnType<
  typeof useGetCompliantCurrenciesLazyQuery
>;
export type GetCompliantCurrenciesQueryResult = Apollo.QueryResult<
  GetCompliantCurrenciesQuery,
  GetCompliantCurrenciesQueryVariables
>;
export const GetConfigurationDocument = gql`
  query GetConfiguration($filters: ChangeRequestConfigFiltersInput) {
    configuration {
      contractChangeRequestConfig(filters: $filters) {
        turnAroundTimeInHours
        earliestJoiningDateWithTurnAround
      }
    }
  }
`;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConfigurationQuery,
    GetConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(
    GetConfigurationDocument,
    options,
  );
}
export function useGetConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConfigurationQuery,
    GetConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConfigurationQuery,
    GetConfigurationQueryVariables
  >(GetConfigurationDocument, options);
}
export type GetConfigurationQueryHookResult = ReturnType<
  typeof useGetConfigurationQuery
>;
export type GetConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetConfigurationLazyQuery
>;
export type GetConfigurationQueryResult = Apollo.QueryResult<
  GetConfigurationQuery,
  GetConfigurationQueryVariables
>;
export const GetContractAgreementDocument = gql`
  query GetContractAgreement($id: ID!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      status
      compliance {
        contractAgreement {
          id
          name
          extension
          contentType
          blob
          htmlPreview
        }
        ... on ComplianceContractor {
          orderForm {
            id
            name
            extension
            contentType
            blob
            htmlPreview
          }
        }
      }
      compensation {
        grant(contractId: $id) {
          id
          document {
            id
            name
            extension
            contentType
            ... on DocumentReadable {
              blob
              htmlPreview
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractAgreementQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractAgreementQuery,
    GetContractAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractAgreementQuery,
    GetContractAgreementQueryVariables
  >(GetContractAgreementDocument, options);
}
export function useGetContractAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractAgreementQuery,
    GetContractAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractAgreementQuery,
    GetContractAgreementQueryVariables
  >(GetContractAgreementDocument, options);
}
export type GetContractAgreementQueryHookResult = ReturnType<
  typeof useGetContractAgreementQuery
>;
export type GetContractAgreementLazyQueryHookResult = ReturnType<
  typeof useGetContractAgreementLazyQuery
>;
export type GetContractAgreementQueryResult = Apollo.QueryResult<
  GetContractAgreementQuery,
  GetContractAgreementQueryVariables
>;
export const GetContractChangeRequestDocument = gql`
  query GetContractChangeRequest($contractId: ID!, $changeRequestIds: [ID!]) {
    contract(id: $contractId) {
      id
      changeRequests(changeRequestIds: $changeRequestIds) {
        id
        contractId
        description
      }
    }
  }
`;

/**
 * __useGetContractChangeRequestQuery__
 *
 * To run a query within a React component, call `useGetContractChangeRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractChangeRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractChangeRequestQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      changeRequestIds: // value for 'changeRequestIds'
 *   },
 * });
 */
export function useGetContractChangeRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractChangeRequestQuery,
    GetContractChangeRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractChangeRequestQuery,
    GetContractChangeRequestQueryVariables
  >(GetContractChangeRequestDocument, options);
}
export function useGetContractChangeRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractChangeRequestQuery,
    GetContractChangeRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractChangeRequestQuery,
    GetContractChangeRequestQueryVariables
  >(GetContractChangeRequestDocument, options);
}
export type GetContractChangeRequestQueryHookResult = ReturnType<
  typeof useGetContractChangeRequestQuery
>;
export type GetContractChangeRequestLazyQueryHookResult = ReturnType<
  typeof useGetContractChangeRequestLazyQuery
>;
export type GetContractChangeRequestQueryResult = Apollo.QueryResult<
  GetContractChangeRequestQuery,
  GetContractChangeRequestQueryVariables
>;
export const GetContractEntityDocument = gql`
  query GetContractEntity($id: ID!) {
    contract(id: $id) {
      id
      type
      startOn
      legalEntityId
      multiplierLegalEntityId
    }
  }
`;

/**
 * __useGetContractEntityQuery__
 *
 * To run a query within a React component, call `useGetContractEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractEntityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractEntityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractEntityQuery,
    GetContractEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractEntityQuery,
    GetContractEntityQueryVariables
  >(GetContractEntityDocument, options);
}
export function useGetContractEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractEntityQuery,
    GetContractEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractEntityQuery,
    GetContractEntityQueryVariables
  >(GetContractEntityDocument, options);
}
export type GetContractEntityQueryHookResult = ReturnType<
  typeof useGetContractEntityQuery
>;
export type GetContractEntityLazyQueryHookResult = ReturnType<
  typeof useGetContractEntityLazyQuery
>;
export type GetContractEntityQueryResult = Apollo.QueryResult<
  GetContractEntityQuery,
  GetContractEntityQueryVariables
>;
export const GetContractJobCodeDocument = gql`
  query GetContractJobCode($filter: JobCodeFilters!) {
    contractJobCode(filter: $filter)
  }
`;

/**
 * __useGetContractJobCodeQuery__
 *
 * To run a query within a React component, call `useGetContractJobCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractJobCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractJobCodeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetContractJobCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractJobCodeQuery,
    GetContractJobCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractJobCodeQuery,
    GetContractJobCodeQueryVariables
  >(GetContractJobCodeDocument, options);
}
export function useGetContractJobCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractJobCodeQuery,
    GetContractJobCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractJobCodeQuery,
    GetContractJobCodeQueryVariables
  >(GetContractJobCodeDocument, options);
}
export type GetContractJobCodeQueryHookResult = ReturnType<
  typeof useGetContractJobCodeQuery
>;
export type GetContractJobCodeLazyQueryHookResult = ReturnType<
  typeof useGetContractJobCodeLazyQuery
>;
export type GetContractJobCodeQueryResult = Apollo.QueryResult<
  GetContractJobCodeQuery,
  GetContractJobCodeQueryVariables
>;
export const GetCountry13th14thMonthPayDocument = gql`
  query GetCountry13th14thMonthPay(
    $country: CountryCode!
    $countryStateCode: String
    $contractType: ContractType
  ) {
    country(country: $country) {
      code
      compliance(
        contractType: $contractType
        countryStateCode: $countryStateCode
      ) {
        countryCode
        countryState {
          name
          code
        }
        country13th14thMonthPay {
          is13thMonthPayApplicable
          is14thMonthPayApplicable
          isIncludedInGross
          description13thMonthPay
          description14thMonthPay
          note
          payFrequencyType
          schedules {
            country13th14thMonthPayType
            monthlySalaryMultiple
            scheduleTimeUnit
            payoutDay
          }
        }
      }
    }
  }
`;

/**
 * __useGetCountry13th14thMonthPayQuery__
 *
 * To run a query within a React component, call `useGetCountry13th14thMonthPayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountry13th14thMonthPayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountry13th14thMonthPayQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      contractType: // value for 'contractType'
 *   },
 * });
 */
export function useGetCountry13th14thMonthPayQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountry13th14thMonthPayQuery,
    GetCountry13th14thMonthPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountry13th14thMonthPayQuery,
    GetCountry13th14thMonthPayQueryVariables
  >(GetCountry13th14thMonthPayDocument, options);
}
export function useGetCountry13th14thMonthPayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountry13th14thMonthPayQuery,
    GetCountry13th14thMonthPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountry13th14thMonthPayQuery,
    GetCountry13th14thMonthPayQueryVariables
  >(GetCountry13th14thMonthPayDocument, options);
}
export type GetCountry13th14thMonthPayQueryHookResult = ReturnType<
  typeof useGetCountry13th14thMonthPayQuery
>;
export type GetCountry13th14thMonthPayLazyQueryHookResult = ReturnType<
  typeof useGetCountry13th14thMonthPayLazyQuery
>;
export type GetCountry13th14thMonthPayQueryResult = Apollo.QueryResult<
  GetCountry13th14thMonthPayQuery,
  GetCountry13th14thMonthPayQueryVariables
>;
export const GetCountryAllowancesConfigDocument = gql`
  query GetCountryAllowancesConfig($country: CountryCode!) {
    countryAllowancesConfig(country: $country) {
      country
      name
      label
      mandatory
    }
  }
`;

/**
 * __useGetCountryAllowancesConfigQuery__
 *
 * To run a query within a React component, call `useGetCountryAllowancesConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryAllowancesConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryAllowancesConfigQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCountryAllowancesConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryAllowancesConfigQuery,
    GetCountryAllowancesConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryAllowancesConfigQuery,
    GetCountryAllowancesConfigQueryVariables
  >(GetCountryAllowancesConfigDocument, options);
}
export function useGetCountryAllowancesConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryAllowancesConfigQuery,
    GetCountryAllowancesConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryAllowancesConfigQuery,
    GetCountryAllowancesConfigQueryVariables
  >(GetCountryAllowancesConfigDocument, options);
}
export type GetCountryAllowancesConfigQueryHookResult = ReturnType<
  typeof useGetCountryAllowancesConfigQuery
>;
export type GetCountryAllowancesConfigLazyQueryHookResult = ReturnType<
  typeof useGetCountryAllowancesConfigLazyQuery
>;
export type GetCountryAllowancesConfigQueryResult = Apollo.QueryResult<
  GetCountryAllowancesConfigQuery,
  GetCountryAllowancesConfigQueryVariables
>;
export const GetCountryComplianceLimitationsDocument = gql`
  query GetCountryComplianceLimitations(
    $country: CountryCode!
    $countryStateCode: String
    $contractType: ContractType
    $workStatus: CountryWorkStatus
    $fetchStage: FetchStage
  ) {
    country(country: $country) {
      code
      compliance(countryStateCode: $countryStateCode) {
        countryCode
        countryState {
          name
          code
        }
        limitations {
          key
          label
          value
          enabled
          description
          countryState
          preconditions {
            contractType
            contractTerm
          }
        }
        memberDataRequirements(
          filter: {
            workStatus: $workStatus
            contractType: $contractType
            fetchStage: $fetchStage
          }
        ) {
          key
          label
          description
          required
          dependsOn {
            key
          }
          dataType {
            ... on TextField {
              minLength
              maxLength
              pattern
            }
            ... on DateField {
              minDate
              maxDate
            }
            ... on DropDownField {
              values
            }
            ... on DropDownTextField {
              minLength
              maxLength
              type
              values
            }
            ... on CheckboxField {
              defaultValue
            }
            ... on AutoCompleteField {
              optionListType
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCountryComplianceLimitationsQuery__
 *
 * To run a query within a React component, call `useGetCountryComplianceLimitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryComplianceLimitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryComplianceLimitationsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      contractType: // value for 'contractType'
 *      workStatus: // value for 'workStatus'
 *      fetchStage: // value for 'fetchStage'
 *   },
 * });
 */
export function useGetCountryComplianceLimitationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryComplianceLimitationsQuery,
    GetCountryComplianceLimitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryComplianceLimitationsQuery,
    GetCountryComplianceLimitationsQueryVariables
  >(GetCountryComplianceLimitationsDocument, options);
}
export function useGetCountryComplianceLimitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryComplianceLimitationsQuery,
    GetCountryComplianceLimitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryComplianceLimitationsQuery,
    GetCountryComplianceLimitationsQueryVariables
  >(GetCountryComplianceLimitationsDocument, options);
}
export type GetCountryComplianceLimitationsQueryHookResult = ReturnType<
  typeof useGetCountryComplianceLimitationsQuery
>;
export type GetCountryComplianceLimitationsLazyQueryHookResult = ReturnType<
  typeof useGetCountryComplianceLimitationsLazyQuery
>;
export type GetCountryComplianceLimitationsQueryResult = Apollo.QueryResult<
  GetCountryComplianceLimitationsQuery,
  GetCountryComplianceLimitationsQueryVariables
>;
export const GetCountryComplianceRequirementsDocument = gql`
  query GetCountryComplianceRequirements(
    $country: CountryCode!
    $countryStateCode: String
    $inputParams: RequirementsInputs
    $contractType: ContractType
  ) {
    country(country: $country) {
      code
      compliance(
        contractType: $contractType
        countryStateCode: $countryStateCode
      ) {
        countryCode
        countryState {
          name
          code
        }
        requirements(inputParams: $inputParams) {
          usedScope
          paramDefinitions {
            param {
              key
              label
              ... on ComplianceParamPeriodLimit {
                description
              }
            }
            dataFieldType
            required
            editable
            enabled
            validation {
              ... on ComplianceParamPeriodLimitUnitValidation {
                minimum
                maximum
                defaultValue
                unit
              }
            }
            dependencies {
              key
              label
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCountryComplianceRequirementsQuery__
 *
 * To run a query within a React component, call `useGetCountryComplianceRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryComplianceRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryComplianceRequirementsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      inputParams: // value for 'inputParams'
 *      contractType: // value for 'contractType'
 *   },
 * });
 */
export function useGetCountryComplianceRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryComplianceRequirementsQuery,
    GetCountryComplianceRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryComplianceRequirementsQuery,
    GetCountryComplianceRequirementsQueryVariables
  >(GetCountryComplianceRequirementsDocument, options);
}
export function useGetCountryComplianceRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryComplianceRequirementsQuery,
    GetCountryComplianceRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryComplianceRequirementsQuery,
    GetCountryComplianceRequirementsQueryVariables
  >(GetCountryComplianceRequirementsDocument, options);
}
export type GetCountryComplianceRequirementsQueryHookResult = ReturnType<
  typeof useGetCountryComplianceRequirementsQuery
>;
export type GetCountryComplianceRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetCountryComplianceRequirementsLazyQuery
>;
export type GetCountryComplianceRequirementsQueryResult = Apollo.QueryResult<
  GetCountryComplianceRequirementsQuery,
  GetCountryComplianceRequirementsQueryVariables
>;
export const GetCountryComplianceLimitationsOnlyDocument = gql`
  query GetCountryComplianceLimitationsOnly(
    $country: CountryCode!
    $countryStateCode: String
  ) {
    country(country: $country) {
      code
      compliance(countryStateCode: $countryStateCode) {
        countryCode
        limitations {
          key
          label
          value
          enabled
          description
          countryState
          preconditions {
            contractType
            contractTerm
          }
        }
      }
    }
  }
`;

/**
 * __useGetCountryComplianceLimitationsOnlyQuery__
 *
 * To run a query within a React component, call `useGetCountryComplianceLimitationsOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryComplianceLimitationsOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryComplianceLimitationsOnlyQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *   },
 * });
 */
export function useGetCountryComplianceLimitationsOnlyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryComplianceLimitationsOnlyQuery,
    GetCountryComplianceLimitationsOnlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryComplianceLimitationsOnlyQuery,
    GetCountryComplianceLimitationsOnlyQueryVariables
  >(GetCountryComplianceLimitationsOnlyDocument, options);
}
export function useGetCountryComplianceLimitationsOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryComplianceLimitationsOnlyQuery,
    GetCountryComplianceLimitationsOnlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryComplianceLimitationsOnlyQuery,
    GetCountryComplianceLimitationsOnlyQueryVariables
  >(GetCountryComplianceLimitationsOnlyDocument, options);
}
export type GetCountryComplianceLimitationsOnlyQueryHookResult = ReturnType<
  typeof useGetCountryComplianceLimitationsOnlyQuery
>;
export type GetCountryComplianceLimitationsOnlyLazyQueryHookResult = ReturnType<
  typeof useGetCountryComplianceLimitationsOnlyLazyQuery
>;
export type GetCountryComplianceLimitationsOnlyQueryResult = Apollo.QueryResult<
  GetCountryComplianceLimitationsOnlyQuery,
  GetCountryComplianceLimitationsOnlyQueryVariables
>;
export const GetEmployeeCountryPricingDocument = gql`
  query GetEmployeeCountryPricing($country: CountryCode) {
    employeePricingForCountry(country: $country) {
      id
      employeeType
      fixedRate
    }
  }
`;

/**
 * __useGetEmployeeCountryPricingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeCountryPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeCountryPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeCountryPricingQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetEmployeeCountryPricingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeeCountryPricingQuery,
    GetEmployeeCountryPricingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeCountryPricingQuery,
    GetEmployeeCountryPricingQueryVariables
  >(GetEmployeeCountryPricingDocument, options);
}
export function useGetEmployeeCountryPricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeCountryPricingQuery,
    GetEmployeeCountryPricingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeCountryPricingQuery,
    GetEmployeeCountryPricingQueryVariables
  >(GetEmployeeCountryPricingDocument, options);
}
export type GetEmployeeCountryPricingQueryHookResult = ReturnType<
  typeof useGetEmployeeCountryPricingQuery
>;
export type GetEmployeeCountryPricingLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeCountryPricingLazyQuery
>;
export type GetEmployeeCountryPricingQueryResult = Apollo.QueryResult<
  GetEmployeeCountryPricingQuery,
  GetEmployeeCountryPricingQueryVariables
>;
export const GetCountryLabourStandardsDocument = gql`
  query GetCountryLabourStandards($country: CountryCode!, $stateCode: String) {
    country(country: $country) {
      code
      labourStandards(filter: { stateCode: $stateCode }) {
        countryCode
        workShift {
          startDay
          endDay
          defaultWorkingHours {
            startTime
            endTime
          }
          workingHoursInfoText
          averageWorkingHoursPerMonth
        }
        compensation {
          rateFrequency {
            list
            defaultValue
          }
          paymentFrequency {
            list
            defaultValue
            payDates {
              identifier
              dateOfMonth
              dayOfWeek
            }
            data {
              rateFrequency
              payFrequencies
            }
          }
          hourlyRates {
            helpUrl
            values {
              key
              label
              description
              value
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCountryLabourStandardsQuery__
 *
 * To run a query within a React component, call `useGetCountryLabourStandardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryLabourStandardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryLabourStandardsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      stateCode: // value for 'stateCode'
 *   },
 * });
 */
export function useGetCountryLabourStandardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryLabourStandardsQuery,
    GetCountryLabourStandardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryLabourStandardsQuery,
    GetCountryLabourStandardsQueryVariables
  >(GetCountryLabourStandardsDocument, options);
}
export function useGetCountryLabourStandardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryLabourStandardsQuery,
    GetCountryLabourStandardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryLabourStandardsQuery,
    GetCountryLabourStandardsQueryVariables
  >(GetCountryLabourStandardsDocument, options);
}
export type GetCountryLabourStandardsQueryHookResult = ReturnType<
  typeof useGetCountryLabourStandardsQuery
>;
export type GetCountryLabourStandardsLazyQueryHookResult = ReturnType<
  typeof useGetCountryLabourStandardsLazyQuery
>;
export type GetCountryLabourStandardsQueryResult = Apollo.QueryResult<
  GetCountryLabourStandardsQuery,
  GetCountryLabourStandardsQueryVariables
>;
export const GetCountryLocalCurrencyDocument = gql`
  query GetCountryLocalCurrency($country: CountryCode!) {
    country(country: $country) {
      code
      mainCurrency
    }
  }
`;

/**
 * __useGetCountryLocalCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCountryLocalCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryLocalCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryLocalCurrencyQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCountryLocalCurrencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryLocalCurrencyQuery,
    GetCountryLocalCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryLocalCurrencyQuery,
    GetCountryLocalCurrencyQueryVariables
  >(GetCountryLocalCurrencyDocument, options);
}
export function useGetCountryLocalCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryLocalCurrencyQuery,
    GetCountryLocalCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryLocalCurrencyQuery,
    GetCountryLocalCurrencyQueryVariables
  >(GetCountryLocalCurrencyDocument, options);
}
export type GetCountryLocalCurrencyQueryHookResult = ReturnType<
  typeof useGetCountryLocalCurrencyQuery
>;
export type GetCountryLocalCurrencyLazyQueryHookResult = ReturnType<
  typeof useGetCountryLocalCurrencyLazyQuery
>;
export type GetCountryLocalCurrencyQueryResult = Apollo.QueryResult<
  GetCountryLocalCurrencyQuery,
  GetCountryLocalCurrencyQueryVariables
>;
export const GetCountryStatesDocument = gql`
  query GetCountryStates($country: CountryCode!) {
    country(country: $country) {
      name
      code
      countryStates {
        name
        code
      }
      isMultiplierVisaAssistance
    }
  }
`;

/**
 * __useGetCountryStatesQuery__
 *
 * To run a query within a React component, call `useGetCountryStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryStatesQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCountryStatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryStatesQuery,
    GetCountryStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountryStatesQuery, GetCountryStatesQueryVariables>(
    GetCountryStatesDocument,
    options,
  );
}
export function useGetCountryStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryStatesQuery,
    GetCountryStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryStatesQuery,
    GetCountryStatesQueryVariables
  >(GetCountryStatesDocument, options);
}
export type GetCountryStatesQueryHookResult = ReturnType<
  typeof useGetCountryStatesQuery
>;
export type GetCountryStatesLazyQueryHookResult = ReturnType<
  typeof useGetCountryStatesLazyQuery
>;
export type GetCountryStatesQueryResult = Apollo.QueryResult<
  GetCountryStatesQuery,
  GetCountryStatesQueryVariables
>;
export const GetCurrentCompanyIdDocument = gql`
  query GetCurrentCompanyId {
    company {
      id
    }
  }
`;

/**
 * __useGetCurrentCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyIdQuery,
    GetCurrentCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyIdQuery,
    GetCurrentCompanyIdQueryVariables
  >(GetCurrentCompanyIdDocument, options);
}
export function useGetCurrentCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyIdQuery,
    GetCurrentCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyIdQuery,
    GetCurrentCompanyIdQueryVariables
  >(GetCurrentCompanyIdDocument, options);
}
export type GetCurrentCompanyIdQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyIdQuery
>;
export type GetCurrentCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyIdLazyQuery
>;
export type GetCurrentCompanyIdQueryResult = Apollo.QueryResult<
  GetCurrentCompanyIdQuery,
  GetCurrentCompanyIdQueryVariables
>;
export const GetFixedTermRestrictionDocument = gql`
  query GetFixedTermRestriction(
    $countryCode: CountryCode!
    $countryStateCode: String
    $contractType: ContractType
    $fixedTermRestrictionFilter: FixedTermRestrictionFilter
  ) {
    country(country: $countryCode) {
      code
      compliance(
        contractType: $contractType
        countryStateCode: $countryStateCode
      ) {
        countryCode
        countryState {
          code
        }
        fixedTermRestriction(filter: $fixedTermRestrictionFilter) {
          maxRenewalCount
          maxRenewalDurationInMonths
          maxTenureInMonths
          minRenewalDurationInMonths
        }
      }
    }
  }
`;

/**
 * __useGetFixedTermRestrictionQuery__
 *
 * To run a query within a React component, call `useGetFixedTermRestrictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFixedTermRestrictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFixedTermRestrictionQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      countryStateCode: // value for 'countryStateCode'
 *      contractType: // value for 'contractType'
 *      fixedTermRestrictionFilter: // value for 'fixedTermRestrictionFilter'
 *   },
 * });
 */
export function useGetFixedTermRestrictionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFixedTermRestrictionQuery,
    GetFixedTermRestrictionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFixedTermRestrictionQuery,
    GetFixedTermRestrictionQueryVariables
  >(GetFixedTermRestrictionDocument, options);
}
export function useGetFixedTermRestrictionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFixedTermRestrictionQuery,
    GetFixedTermRestrictionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFixedTermRestrictionQuery,
    GetFixedTermRestrictionQueryVariables
  >(GetFixedTermRestrictionDocument, options);
}
export type GetFixedTermRestrictionQueryHookResult = ReturnType<
  typeof useGetFixedTermRestrictionQuery
>;
export type GetFixedTermRestrictionLazyQueryHookResult = ReturnType<
  typeof useGetFixedTermRestrictionLazyQuery
>;
export type GetFixedTermRestrictionQueryResult = Apollo.QueryResult<
  GetFixedTermRestrictionQuery,
  GetFixedTermRestrictionQueryVariables
>;
export const GetInitialOnboardingStepsDocument = gql`
  query GetInitialOnboardingSteps($type: ContractType!, $legalEntityId: ID) {
    onboarding(type: $type, legalEntityId: $legalEntityId) {
      steps
    }
  }
`;

/**
 * __useGetInitialOnboardingStepsQuery__
 *
 * To run a query within a React component, call `useGetInitialOnboardingStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialOnboardingStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialOnboardingStepsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetInitialOnboardingStepsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInitialOnboardingStepsQuery,
    GetInitialOnboardingStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInitialOnboardingStepsQuery,
    GetInitialOnboardingStepsQueryVariables
  >(GetInitialOnboardingStepsDocument, options);
}
export function useGetInitialOnboardingStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInitialOnboardingStepsQuery,
    GetInitialOnboardingStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInitialOnboardingStepsQuery,
    GetInitialOnboardingStepsQueryVariables
  >(GetInitialOnboardingStepsDocument, options);
}
export type GetInitialOnboardingStepsQueryHookResult = ReturnType<
  typeof useGetInitialOnboardingStepsQuery
>;
export type GetInitialOnboardingStepsLazyQueryHookResult = ReturnType<
  typeof useGetInitialOnboardingStepsLazyQuery
>;
export type GetInitialOnboardingStepsQueryResult = Apollo.QueryResult<
  GetInitialOnboardingStepsQuery,
  GetInitialOnboardingStepsQueryVariables
>;
export const GetLegalEntityHolidaysDocument = gql`
  query GetLegalEntityHolidays(
    $legalEntityId: ID!
    $year: Int
    $input: LegalEntityHolidayInput
  ) {
    company {
      id
      otherEntities(legalEntityId: $legalEntityId) {
        id
        holidays(year: $year, input: $input) {
          name
          date
          month
          year
          key
          label
          countryCode
        }
      }
    }
  }
`;

/**
 * __useGetLegalEntityHolidaysQuery__
 *
 * To run a query within a React component, call `useGetLegalEntityHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalEntityHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalEntityHolidaysQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      year: // value for 'year'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLegalEntityHolidaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLegalEntityHolidaysQuery,
    GetLegalEntityHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLegalEntityHolidaysQuery,
    GetLegalEntityHolidaysQueryVariables
  >(GetLegalEntityHolidaysDocument, options);
}
export function useGetLegalEntityHolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLegalEntityHolidaysQuery,
    GetLegalEntityHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLegalEntityHolidaysQuery,
    GetLegalEntityHolidaysQueryVariables
  >(GetLegalEntityHolidaysDocument, options);
}
export type GetLegalEntityHolidaysQueryHookResult = ReturnType<
  typeof useGetLegalEntityHolidaysQuery
>;
export type GetLegalEntityHolidaysLazyQueryHookResult = ReturnType<
  typeof useGetLegalEntityHolidaysLazyQuery
>;
export type GetLegalEntityHolidaysQueryResult = Apollo.QueryResult<
  GetLegalEntityHolidaysQuery,
  GetLegalEntityHolidaysQueryVariables
>;
export const GetOfferLetterDocument = gql`
  query GetOfferLetter($id: ID!) {
    contract(id: $id) {
      id
      status
      compliance {
        ... on ComplianceMultiplierEOR {
          offerLetter {
            id
            name
            extension
            contentType
            blob
            htmlPreview
          }
        }
        ... on CompliancePartnerEOR {
          offerLetter {
            id
            name
            extension
            contentType
            blob
            htmlPreview
          }
        }
      }
    }
  }
`;

/**
 * __useGetOfferLetterQuery__
 *
 * To run a query within a React component, call `useGetOfferLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferLetterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferLetterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfferLetterQuery,
    GetOfferLetterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfferLetterQuery, GetOfferLetterQueryVariables>(
    GetOfferLetterDocument,
    options,
  );
}
export function useGetOfferLetterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfferLetterQuery,
    GetOfferLetterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfferLetterQuery, GetOfferLetterQueryVariables>(
    GetOfferLetterDocument,
    options,
  );
}
export type GetOfferLetterQueryHookResult = ReturnType<
  typeof useGetOfferLetterQuery
>;
export type GetOfferLetterLazyQueryHookResult = ReturnType<
  typeof useGetOfferLetterLazyQuery
>;
export type GetOfferLetterQueryResult = Apollo.QueryResult<
  GetOfferLetterQuery,
  GetOfferLetterQueryVariables
>;
export const GetOnboardingProgressDocument = gql`
  query GetOnboardingProgress($contractId: ID!) {
    contract(id: $contractId) {
      id
      status
      type
      contractStarted
      startOn
      legalEntityId
      onboarding {
        current
        status
        steps
      }
    }
  }
`;

/**
 * __useGetOnboardingProgressQuery__
 *
 * To run a query within a React component, call `useGetOnboardingProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingProgressQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetOnboardingProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnboardingProgressQuery,
    GetOnboardingProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnboardingProgressQuery,
    GetOnboardingProgressQueryVariables
  >(GetOnboardingProgressDocument, options);
}
export function useGetOnboardingProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingProgressQuery,
    GetOnboardingProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnboardingProgressQuery,
    GetOnboardingProgressQueryVariables
  >(GetOnboardingProgressDocument, options);
}
export type GetOnboardingProgressQueryHookResult = ReturnType<
  typeof useGetOnboardingProgressQuery
>;
export type GetOnboardingProgressLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingProgressLazyQuery
>;
export type GetOnboardingProgressQueryResult = Apollo.QueryResult<
  GetOnboardingProgressQuery,
  GetOnboardingProgressQueryVariables
>;
export const GetPayrollConfigsForCompanyLegalEntityDocument = gql`
  query GetPayrollConfigsForCompanyLegalEntity(
    $companyLegalEntityId: ID!
    $multiplierLegalEntityId: ID
    $payFrequency: PayFrequency
    $domain: PayrollInputDomainType!
    $referenceDate: Date!
  ) {
    payrollConfigsWithNextPayrollCycles(
      companyLegalEntityId: $companyLegalEntityId
      multiplierLegalEntityId: $multiplierLegalEntityId
      payFrequency: $payFrequency
    ) {
      id
      title
      frequency
      referencePayrollCycle: payrollCycleByReferenceDate(
        domain: $domain
        referenceDate: $referenceDate
        returnClosestToReferenceDateUpcomingCutOff: true
      ) {
        id
        cutOffTo
      }
    }
  }
`;

/**
 * __useGetPayrollConfigsForCompanyLegalEntityQuery__
 *
 * To run a query within a React component, call `useGetPayrollConfigsForCompanyLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollConfigsForCompanyLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollConfigsForCompanyLegalEntityQuery({
 *   variables: {
 *      companyLegalEntityId: // value for 'companyLegalEntityId'
 *      multiplierLegalEntityId: // value for 'multiplierLegalEntityId'
 *      payFrequency: // value for 'payFrequency'
 *      domain: // value for 'domain'
 *      referenceDate: // value for 'referenceDate'
 *   },
 * });
 */
export function useGetPayrollConfigsForCompanyLegalEntityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollConfigsForCompanyLegalEntityQuery,
    GetPayrollConfigsForCompanyLegalEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayrollConfigsForCompanyLegalEntityQuery,
    GetPayrollConfigsForCompanyLegalEntityQueryVariables
  >(GetPayrollConfigsForCompanyLegalEntityDocument, options);
}
export function useGetPayrollConfigsForCompanyLegalEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollConfigsForCompanyLegalEntityQuery,
    GetPayrollConfigsForCompanyLegalEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollConfigsForCompanyLegalEntityQuery,
    GetPayrollConfigsForCompanyLegalEntityQueryVariables
  >(GetPayrollConfigsForCompanyLegalEntityDocument, options);
}
export type GetPayrollConfigsForCompanyLegalEntityQueryHookResult = ReturnType<
  typeof useGetPayrollConfigsForCompanyLegalEntityQuery
>;
export type GetPayrollConfigsForCompanyLegalEntityLazyQueryHookResult = ReturnType<
  typeof useGetPayrollConfigsForCompanyLegalEntityLazyQuery
>;
export type GetPayrollConfigsForCompanyLegalEntityQueryResult = Apollo.QueryResult<
  GetPayrollConfigsForCompanyLegalEntityQuery,
  GetPayrollConfigsForCompanyLegalEntityQueryVariables
>;
export const GetPositionsForIntegrationsDocument = gql`
  query GetPositionsForIntegrations($integrationId: ID!) {
    getPositionsForIntegrations(integrationId: $integrationId) {
      department
      designation
      positionId
    }
  }
`;

/**
 * __useGetPositionsForIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetPositionsForIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionsForIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionsForIntegrationsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetPositionsForIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPositionsForIntegrationsQuery,
    GetPositionsForIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPositionsForIntegrationsQuery,
    GetPositionsForIntegrationsQueryVariables
  >(GetPositionsForIntegrationsDocument, options);
}
export function useGetPositionsForIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionsForIntegrationsQuery,
    GetPositionsForIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPositionsForIntegrationsQuery,
    GetPositionsForIntegrationsQueryVariables
  >(GetPositionsForIntegrationsDocument, options);
}
export type GetPositionsForIntegrationsQueryHookResult = ReturnType<
  typeof useGetPositionsForIntegrationsQuery
>;
export type GetPositionsForIntegrationsLazyQueryHookResult = ReturnType<
  typeof useGetPositionsForIntegrationsLazyQuery
>;
export type GetPositionsForIntegrationsQueryResult = Apollo.QueryResult<
  GetPositionsForIntegrationsQuery,
  GetPositionsForIntegrationsQueryVariables
>;
export const GetSignatoriesDocument = gql`
  query GetSignatories {
    company {
      id
      status
      signatories {
        id
        firstName
        lastName
        title
        emails {
          email
        }
      }
    }
  }
`;

/**
 * __useGetSignatoriesQuery__
 *
 * To run a query within a React component, call `useGetSignatoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignatoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignatoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignatoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSignatoriesQuery,
    GetSignatoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSignatoriesQuery, GetSignatoriesQueryVariables>(
    GetSignatoriesDocument,
    options,
  );
}
export function useGetSignatoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSignatoriesQuery,
    GetSignatoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSignatoriesQuery, GetSignatoriesQueryVariables>(
    GetSignatoriesDocument,
    options,
  );
}
export type GetSignatoriesQueryHookResult = ReturnType<
  typeof useGetSignatoriesQuery
>;
export type GetSignatoriesLazyQueryHookResult = ReturnType<
  typeof useGetSignatoriesLazyQuery
>;
export type GetSignatoriesQueryResult = Apollo.QueryResult<
  GetSignatoriesQuery,
  GetSignatoriesQueryVariables
>;
export const GetStartDateRestrictionsDocument = gql`
  query GetStartDateRestrictions(
    $country: CountryCode!
    $countryStateCode: String
    $workStatus: CountryWorkStatus
  ) {
    country(country: $country) {
      code
      compliance(countryStateCode: $countryStateCode) {
        countryCode
        countryState {
          code
        }
        joiningDateRestriction(workStatus: $workStatus) {
          earliestJoiningDate
          checkpoints {
            earliestVisaCompletionDate
          }
          holidays {
            key
            label
            year
            month
            date
            name
            type
            countryCode
          }
        }
      }
    }
  }
`;

/**
 * __useGetStartDateRestrictionsQuery__
 *
 * To run a query within a React component, call `useGetStartDateRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStartDateRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStartDateRestrictionsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      workStatus: // value for 'workStatus'
 *   },
 * });
 */
export function useGetStartDateRestrictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStartDateRestrictionsQuery,
    GetStartDateRestrictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStartDateRestrictionsQuery,
    GetStartDateRestrictionsQueryVariables
  >(GetStartDateRestrictionsDocument, options);
}
export function useGetStartDateRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStartDateRestrictionsQuery,
    GetStartDateRestrictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStartDateRestrictionsQuery,
    GetStartDateRestrictionsQueryVariables
  >(GetStartDateRestrictionsDocument, options);
}
export type GetStartDateRestrictionsQueryHookResult = ReturnType<
  typeof useGetStartDateRestrictionsQuery
>;
export type GetStartDateRestrictionsLazyQueryHookResult = ReturnType<
  typeof useGetStartDateRestrictionsLazyQuery
>;
export type GetStartDateRestrictionsQueryResult = Apollo.QueryResult<
  GetStartDateRestrictionsQuery,
  GetStartDateRestrictionsQueryVariables
>;
export const GetSupportedCountriesDocument = gql`
  query GetSupportedCountries($contractType: ContractType!) {
    supportedCountries(contractType: $contractType) {
      code
      name
    }
  }
`;

/**
 * __useGetSupportedCountriesQuery__
 *
 * To run a query within a React component, call `useGetSupportedCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedCountriesQuery({
 *   variables: {
 *      contractType: // value for 'contractType'
 *   },
 * });
 */
export function useGetSupportedCountriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupportedCountriesQuery,
    GetSupportedCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupportedCountriesQuery,
    GetSupportedCountriesQueryVariables
  >(GetSupportedCountriesDocument, options);
}
export function useGetSupportedCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportedCountriesQuery,
    GetSupportedCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupportedCountriesQuery,
    GetSupportedCountriesQueryVariables
  >(GetSupportedCountriesDocument, options);
}
export type GetSupportedCountriesQueryHookResult = ReturnType<
  typeof useGetSupportedCountriesQuery
>;
export type GetSupportedCountriesLazyQueryHookResult = ReturnType<
  typeof useGetSupportedCountriesLazyQuery
>;
export type GetSupportedCountriesQueryResult = Apollo.QueryResult<
  GetSupportedCountriesQuery,
  GetSupportedCountriesQueryVariables
>;
export const GetSupportedCurrenciesDocument = gql`
  query GetSupportedCurrencies(
    $country: CountryCode!
    $contractType: ContractType
  ) {
    country(country: $country) {
      code
      supportedCurrencies(contractType: $contractType)
    }
  }
`;

/**
 * __useGetSupportedCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetSupportedCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedCurrenciesQuery({
 *   variables: {
 *      country: // value for 'country'
 *      contractType: // value for 'contractType'
 *   },
 * });
 */
export function useGetSupportedCurrenciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupportedCurrenciesQuery,
    GetSupportedCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupportedCurrenciesQuery,
    GetSupportedCurrenciesQueryVariables
  >(GetSupportedCurrenciesDocument, options);
}
export function useGetSupportedCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportedCurrenciesQuery,
    GetSupportedCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupportedCurrenciesQuery,
    GetSupportedCurrenciesQueryVariables
  >(GetSupportedCurrenciesDocument, options);
}
export type GetSupportedCurrenciesQueryHookResult = ReturnType<
  typeof useGetSupportedCurrenciesQuery
>;
export type GetSupportedCurrenciesLazyQueryHookResult = ReturnType<
  typeof useGetSupportedCurrenciesLazyQuery
>;
export type GetSupportedCurrenciesQueryResult = Apollo.QueryResult<
  GetSupportedCurrenciesQuery,
  GetSupportedCurrenciesQueryVariables
>;
export const GetTimeOffContractRequirementsDocument = gql`
  query GetTimeOffContractRequirements($contractId: ID!) {
    timeOffContractRequirements(contractId: $contractId) {
      assignedEntitlements {
        isMandatory
        timeOffType {
          typeId
          type
        }
        definition {
          label
          type
          validation {
            ... on TimeOffFixedValidation {
              allowedContractStatuses
              defaultValue
              isUnlimitedLeavesAllowed
              maximum
              minimum
              unit
            }
          }
          configuration {
            allocation {
              basis
              prorated
            }
          }
          assignedEntityNames
        }
        value
        unit
      }
      availableEntitlements {
        isMandatory
        timeOffType {
          typeId
          type
        }
        definition {
          label
          type
          configuration {
            allocation {
              basis
              prorated
            }
          }
          validation {
            ... on TimeOffFixedValidation {
              allowedContractStatuses
              defaultValue
              isUnlimitedLeavesAllowed
              maximum
              minimum
              unit
            }
          }
          assignedEntityNames
        }
        value
        unit
      }
      clause
    }
  }
`;

/**
 * __useGetTimeOffContractRequirementsQuery__
 *
 * To run a query within a React component, call `useGetTimeOffContractRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffContractRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffContractRequirementsQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetTimeOffContractRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTimeOffContractRequirementsQuery,
    GetTimeOffContractRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTimeOffContractRequirementsQuery,
    GetTimeOffContractRequirementsQueryVariables
  >(GetTimeOffContractRequirementsDocument, options);
}
export function useGetTimeOffContractRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeOffContractRequirementsQuery,
    GetTimeOffContractRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTimeOffContractRequirementsQuery,
    GetTimeOffContractRequirementsQueryVariables
  >(GetTimeOffContractRequirementsDocument, options);
}
export type GetTimeOffContractRequirementsQueryHookResult = ReturnType<
  typeof useGetTimeOffContractRequirementsQuery
>;
export type GetTimeOffContractRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetTimeOffContractRequirementsLazyQuery
>;
export type GetTimeOffContractRequirementsQueryResult = Apollo.QueryResult<
  GetTimeOffContractRequirementsQuery,
  GetTimeOffContractRequirementsQueryVariables
>;
export const InviteMemberDocument = gql`
  mutation InviteMember($id: ID!) {
    contractInviteMember(id: $id) {
      id
      status
      onboarding {
        status
        statuses
        steps
        current
      }
    }
  }
`;
export type InviteMemberMutationFn = Apollo.MutationFunction<
  InviteMemberMutation,
  InviteMemberMutationVariables
>;

/**
 * __useInviteMemberMutation__
 *
 * To run a mutation, you first call `useInviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberMutation, { data, loading, error }] = useInviteMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteMemberMutation,
    InviteMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteMemberMutation,
    InviteMemberMutationVariables
  >(InviteMemberDocument, options);
}
export type InviteMemberMutationHookResult = ReturnType<
  typeof useInviteMemberMutation
>;
export type InviteMemberMutationResult = Apollo.MutationResult<InviteMemberMutation>;
export type InviteMemberMutationOptions = Apollo.BaseMutationOptions<
  InviteMemberMutation,
  InviteMemberMutationVariables
>;
export const RequestContractChangesDocument = gql`
  mutation RequestContractChanges($id: ID!, $reason: String) {
    contractRequestChangesFromMember(id: $id, description: $reason) {
      id
    }
  }
`;
export type RequestContractChangesMutationFn = Apollo.MutationFunction<
  RequestContractChangesMutation,
  RequestContractChangesMutationVariables
>;

/**
 * __useRequestContractChangesMutation__
 *
 * To run a mutation, you first call `useRequestContractChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestContractChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestContractChangesMutation, { data, loading, error }] = useRequestContractChangesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRequestContractChangesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestContractChangesMutation,
    RequestContractChangesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestContractChangesMutation,
    RequestContractChangesMutationVariables
  >(RequestContractChangesDocument, options);
}
export type RequestContractChangesMutationHookResult = ReturnType<
  typeof useRequestContractChangesMutation
>;
export type RequestContractChangesMutationResult = Apollo.MutationResult<RequestContractChangesMutation>;
export type RequestContractChangesMutationOptions = Apollo.BaseMutationOptions<
  RequestContractChangesMutation,
  RequestContractChangesMutationVariables
>;
export const ResendInviteMemberDocument = gql`
  mutation ResendInviteMember($id: ID!) {
    contractInviteMemberResend(id: $id) {
      id
      onboarding {
        status
        steps
        current
      }
    }
  }
`;
export type ResendInviteMemberMutationFn = Apollo.MutationFunction<
  ResendInviteMemberMutation,
  ResendInviteMemberMutationVariables
>;

/**
 * __useResendInviteMemberMutation__
 *
 * To run a mutation, you first call `useResendInviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteMemberMutation, { data, loading, error }] = useResendInviteMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendInviteMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInviteMemberMutation,
    ResendInviteMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInviteMemberMutation,
    ResendInviteMemberMutationVariables
  >(ResendInviteMemberDocument, options);
}
export type ResendInviteMemberMutationHookResult = ReturnType<
  typeof useResendInviteMemberMutation
>;
export type ResendInviteMemberMutationResult = Apollo.MutationResult<ResendInviteMemberMutation>;
export type ResendInviteMemberMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteMemberMutation,
  ResendInviteMemberMutationVariables
>;
export const ContractReviseDocument = gql`
  mutation ContractRevise($id: ID!) {
    contractRevise(id: $id) {
      id
      onboarding {
        current
        status
        steps
      }
    }
  }
`;
export type ContractReviseMutationFn = Apollo.MutationFunction<
  ContractReviseMutation,
  ContractReviseMutationVariables
>;

/**
 * __useContractReviseMutation__
 *
 * To run a mutation, you first call `useContractReviseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractReviseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractReviseMutation, { data, loading, error }] = useContractReviseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractReviseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractReviseMutation,
    ContractReviseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractReviseMutation,
    ContractReviseMutationVariables
  >(ContractReviseDocument, options);
}
export type ContractReviseMutationHookResult = ReturnType<
  typeof useContractReviseMutation
>;
export type ContractReviseMutationResult = Apollo.MutationResult<ContractReviseMutation>;
export type ContractReviseMutationOptions = Apollo.BaseMutationOptions<
  ContractReviseMutation,
  ContractReviseMutationVariables
>;
export const ContractRevokeDocument = gql`
  mutation ContractRevoke($id: ID!) {
    contractRevoke(id: $id) {
      id
      onboarding {
        current
        status
        steps
      }
    }
  }
`;
export type ContractRevokeMutationFn = Apollo.MutationFunction<
  ContractRevokeMutation,
  ContractRevokeMutationVariables
>;

/**
 * __useContractRevokeMutation__
 *
 * To run a mutation, you first call `useContractRevokeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractRevokeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractRevokeMutation, { data, loading, error }] = useContractRevokeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractRevokeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractRevokeMutation,
    ContractRevokeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractRevokeMutation,
    ContractRevokeMutationVariables
  >(ContractRevokeDocument, options);
}
export type ContractRevokeMutationHookResult = ReturnType<
  typeof useContractRevokeMutation
>;
export type ContractRevokeMutationResult = Apollo.MutationResult<ContractRevokeMutation>;
export type ContractRevokeMutationOptions = Apollo.BaseMutationOptions<
  ContractRevokeMutation,
  ContractRevokeMutationVariables
>;
export const SendForSignatureDocument = gql`
  mutation SendForSignature($id: ID!) {
    contractSendForSignature(id: $id) {
      id
      onboarding {
        statuses
        current
        status
        steps
      }
      depositPayable {
        id
        invoice {
          id
          status
        }
      }
    }
  }
`;
export type SendForSignatureMutationFn = Apollo.MutationFunction<
  SendForSignatureMutation,
  SendForSignatureMutationVariables
>;

/**
 * __useSendForSignatureMutation__
 *
 * To run a mutation, you first call `useSendForSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendForSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendForSignatureMutation, { data, loading, error }] = useSendForSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendForSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendForSignatureMutation,
    SendForSignatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendForSignatureMutation,
    SendForSignatureMutationVariables
  >(SendForSignatureDocument, options);
}
export type SendForSignatureMutationHookResult = ReturnType<
  typeof useSendForSignatureMutation
>;
export type SendForSignatureMutationResult = Apollo.MutationResult<SendForSignatureMutation>;
export type SendForSignatureMutationOptions = Apollo.BaseMutationOptions<
  SendForSignatureMutation,
  SendForSignatureMutationVariables
>;
export const ContractSendOfferLetterDocument = gql`
  mutation ContractSendOfferLetter($id: ID!) {
    contractSendOfferLetter(id: $id) {
      success
      message
    }
  }
`;
export type ContractSendOfferLetterMutationFn = Apollo.MutationFunction<
  ContractSendOfferLetterMutation,
  ContractSendOfferLetterMutationVariables
>;

/**
 * __useContractSendOfferLetterMutation__
 *
 * To run a mutation, you first call `useContractSendOfferLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractSendOfferLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractSendOfferLetterMutation, { data, loading, error }] = useContractSendOfferLetterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractSendOfferLetterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractSendOfferLetterMutation,
    ContractSendOfferLetterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractSendOfferLetterMutation,
    ContractSendOfferLetterMutationVariables
  >(ContractSendOfferLetterDocument, options);
}
export type ContractSendOfferLetterMutationHookResult = ReturnType<
  typeof useContractSendOfferLetterMutation
>;
export type ContractSendOfferLetterMutationResult = Apollo.MutationResult<ContractSendOfferLetterMutation>;
export type ContractSendOfferLetterMutationOptions = Apollo.BaseMutationOptions<
  ContractSendOfferLetterMutation,
  ContractSendOfferLetterMutationVariables
>;
export const SendOrderFormForSignatureDocument = gql`
  mutation SendOrderFormForSignature($id: ID!) {
    contractOrderFormSendForSignature(id: $id) {
      id
      onboarding {
        statuses
        current
        status
        steps
      }
      depositPayable {
        id
        invoice {
          id
          status
        }
      }
    }
  }
`;
export type SendOrderFormForSignatureMutationFn = Apollo.MutationFunction<
  SendOrderFormForSignatureMutation,
  SendOrderFormForSignatureMutationVariables
>;

/**
 * __useSendOrderFormForSignatureMutation__
 *
 * To run a mutation, you first call `useSendOrderFormForSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderFormForSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderFormForSignatureMutation, { data, loading, error }] = useSendOrderFormForSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendOrderFormForSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendOrderFormForSignatureMutation,
    SendOrderFormForSignatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendOrderFormForSignatureMutation,
    SendOrderFormForSignatureMutationVariables
  >(SendOrderFormForSignatureDocument, options);
}
export type SendOrderFormForSignatureMutationHookResult = ReturnType<
  typeof useSendOrderFormForSignatureMutation
>;
export type SendOrderFormForSignatureMutationResult = Apollo.MutationResult<SendOrderFormForSignatureMutation>;
export type SendOrderFormForSignatureMutationOptions = Apollo.BaseMutationOptions<
  SendOrderFormForSignatureMutation,
  SendOrderFormForSignatureMutationVariables
>;
export const UpdatePreFetchDetailsDocument = gql`
  mutation UpdatePreFetchDetails(
    $memberId: ID!
    $memberDataInputs: [TextFieldInput]!
    $dateFieldInputs: [DateFieldInput]!
  ) {
    countryUpdateMemberDataText(
      memberId: $memberId
      textFieldInputs: $memberDataInputs
    ) {
      id
      legalData {
        key
        label
        value
      }
    }
    countryUpdateMemberDataDate(
      memberId: $memberId
      dateFieldInputs: $dateFieldInputs
    ) {
      id
      legalData {
        key
        label
        value
      }
    }
  }
`;
export type UpdatePreFetchDetailsMutationFn = Apollo.MutationFunction<
  UpdatePreFetchDetailsMutation,
  UpdatePreFetchDetailsMutationVariables
>;

/**
 * __useUpdatePreFetchDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePreFetchDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreFetchDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreFetchDetailsMutation, { data, loading, error }] = useUpdatePreFetchDetailsMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      memberDataInputs: // value for 'memberDataInputs'
 *      dateFieldInputs: // value for 'dateFieldInputs'
 *   },
 * });
 */
export function useUpdatePreFetchDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreFetchDetailsMutation,
    UpdatePreFetchDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePreFetchDetailsMutation,
    UpdatePreFetchDetailsMutationVariables
  >(UpdatePreFetchDetailsDocument, options);
}
export type UpdatePreFetchDetailsMutationHookResult = ReturnType<
  typeof useUpdatePreFetchDetailsMutation
>;
export type UpdatePreFetchDetailsMutationResult = Apollo.MutationResult<UpdatePreFetchDetailsMutation>;
export type UpdatePreFetchDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePreFetchDetailsMutation,
  UpdatePreFetchDetailsMutationVariables
>;
export const UpdateBankDetailsCompanyDocument = gql`
  mutation updateBankDetailsCompany($id: ID!, $bankAccount: BankAccountInput!) {
    memberUpdateBankDetails(id: $id, data: $bankAccount) {
      id
      bankAccounts {
        id
        accountName
        accountNumber
        bankName
        branchName
        swiftCode
        localBankCode
        bankStatements {
          id
          name
          extension
          link
          contentType
          size
        }
      }
      contracts(status: ONBOARDING) {
        id
        onboarding {
          id
          status
          steps
          current
        }
      }
    }
  }
`;
export type UpdateBankDetailsCompanyMutationFn = Apollo.MutationFunction<
  UpdateBankDetailsCompanyMutation,
  UpdateBankDetailsCompanyMutationVariables
>;

/**
 * __useUpdateBankDetailsCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateBankDetailsCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankDetailsCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankDetailsCompanyMutation, { data, loading, error }] = useUpdateBankDetailsCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      bankAccount: // value for 'bankAccount'
 *   },
 * });
 */
export function useUpdateBankDetailsCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBankDetailsCompanyMutation,
    UpdateBankDetailsCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBankDetailsCompanyMutation,
    UpdateBankDetailsCompanyMutationVariables
  >(UpdateBankDetailsCompanyDocument, options);
}
export type UpdateBankDetailsCompanyMutationHookResult = ReturnType<
  typeof useUpdateBankDetailsCompanyMutation
>;
export type UpdateBankDetailsCompanyMutationResult = Apollo.MutationResult<UpdateBankDetailsCompanyMutation>;
export type UpdateBankDetailsCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateBankDetailsCompanyMutation,
  UpdateBankDetailsCompanyMutationVariables
>;
export const UpdateMemberDataForCompanyDocument = gql`
  mutation UpdateMemberDataForCompany(
    $memberId: ID!
    $generalDataChange: MemberUpdateInput!
    $email: EmailAddressInput!
    $memberDataInputs: [TextFieldInput]!
    $dateFieldInputs: [DateFieldInput]!
  ) {
    memberUpdate(id: $memberId, memberChange: $generalDataChange) {
      id
      firstName
      lastName
      gender
    }
    memberChangePrimaryEmail(id: $memberId, email: $email) {
      id
      emails {
        type
        email
      }
    }
    countryUpdateMemberDataText(
      memberId: $memberId
      textFieldInputs: $memberDataInputs
    ) {
      id
      legalData {
        key
        label
        value
        identifier
      }
    }
    countryUpdateMemberDataDate(
      memberId: $memberId
      dateFieldInputs: $dateFieldInputs
    ) {
      id
      legalData {
        key
        label
        value
        identifier
      }
    }
  }
`;
export type UpdateMemberDataForCompanyMutationFn = Apollo.MutationFunction<
  UpdateMemberDataForCompanyMutation,
  UpdateMemberDataForCompanyMutationVariables
>;

/**
 * __useUpdateMemberDataForCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateMemberDataForCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberDataForCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberDataForCompanyMutation, { data, loading, error }] = useUpdateMemberDataForCompanyMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      generalDataChange: // value for 'generalDataChange'
 *      email: // value for 'email'
 *      memberDataInputs: // value for 'memberDataInputs'
 *      dateFieldInputs: // value for 'dateFieldInputs'
 *   },
 * });
 */
export function useUpdateMemberDataForCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberDataForCompanyMutation,
    UpdateMemberDataForCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberDataForCompanyMutation,
    UpdateMemberDataForCompanyMutationVariables
  >(UpdateMemberDataForCompanyDocument, options);
}
export type UpdateMemberDataForCompanyMutationHookResult = ReturnType<
  typeof useUpdateMemberDataForCompanyMutation
>;
export type UpdateMemberDataForCompanyMutationResult = Apollo.MutationResult<UpdateMemberDataForCompanyMutation>;
export type UpdateMemberDataForCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberDataForCompanyMutation,
  UpdateMemberDataForCompanyMutationVariables
>;
export const UpdateMemberDataWithoutLegalDataForCompanyDocument = gql`
  mutation UpdateMemberDataWithoutLegalDataForCompany(
    $memberId: ID!
    $generalDataChange: MemberUpdateInput!
    $email: EmailAddressInput!
  ) {
    memberUpdate(id: $memberId, memberChange: $generalDataChange) {
      id
      firstName
      lastName
      gender
    }
    memberChangePrimaryEmail(id: $memberId, email: $email) {
      id
      emails {
        type
        email
      }
    }
  }
`;
export type UpdateMemberDataWithoutLegalDataForCompanyMutationFn = Apollo.MutationFunction<
  UpdateMemberDataWithoutLegalDataForCompanyMutation,
  UpdateMemberDataWithoutLegalDataForCompanyMutationVariables
>;

/**
 * __useUpdateMemberDataWithoutLegalDataForCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateMemberDataWithoutLegalDataForCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberDataWithoutLegalDataForCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberDataWithoutLegalDataForCompanyMutation, { data, loading, error }] = useUpdateMemberDataWithoutLegalDataForCompanyMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      generalDataChange: // value for 'generalDataChange'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateMemberDataWithoutLegalDataForCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberDataWithoutLegalDataForCompanyMutation,
    UpdateMemberDataWithoutLegalDataForCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberDataWithoutLegalDataForCompanyMutation,
    UpdateMemberDataWithoutLegalDataForCompanyMutationVariables
  >(UpdateMemberDataWithoutLegalDataForCompanyDocument, options);
}
export type UpdateMemberDataWithoutLegalDataForCompanyMutationHookResult = ReturnType<
  typeof useUpdateMemberDataWithoutLegalDataForCompanyMutation
>;
export type UpdateMemberDataWithoutLegalDataForCompanyMutationResult = Apollo.MutationResult<UpdateMemberDataWithoutLegalDataForCompanyMutation>;
export type UpdateMemberDataWithoutLegalDataForCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberDataWithoutLegalDataForCompanyMutation,
  UpdateMemberDataWithoutLegalDataForCompanyMutationVariables
>;
export const UpdateBenefitsDocument = gql`
  mutation UpdateBenefits($id: ID!, $input: ContractUpdateBenefitsInput!) {
    contractUpdateBenefits(id: $id, input: $input) {
      id
      benefits {
        id
        employeePayPercentage
        employerPayPercentage
        benefit {
          id
          type
        }
        params {
          key
          value
        }
        dependentCount
      }
      onboarding {
        status
        steps
        current
      }
    }
  }
`;
export type UpdateBenefitsMutationFn = Apollo.MutationFunction<
  UpdateBenefitsMutation,
  UpdateBenefitsMutationVariables
>;

/**
 * __useUpdateBenefitsMutation__
 *
 * To run a mutation, you first call `useUpdateBenefitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefitsMutation, { data, loading, error }] = useUpdateBenefitsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBenefitsMutation,
    UpdateBenefitsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBenefitsMutation,
    UpdateBenefitsMutationVariables
  >(UpdateBenefitsDocument, options);
}
export type UpdateBenefitsMutationHookResult = ReturnType<
  typeof useUpdateBenefitsMutation
>;
export type UpdateBenefitsMutationResult = Apollo.MutationResult<UpdateBenefitsMutation>;
export type UpdateBenefitsMutationOptions = Apollo.BaseMutationOptions<
  UpdateBenefitsMutation,
  UpdateBenefitsMutationVariables
>;
export const UpdateCompensationDocument = gql`
  mutation UpdateCompensation(
    $id: ID!
    $input: ContractUpdateCompensationInput!
  ) {
    contractUpdateCompensation(id: $id, input: $input) {
      id
      compensation {
        basePay {
          ...fixedPayComponent
        }
        postProbationBasePay {
          ...fixedPayComponent
        }
        probationBasePay {
          ...fixedPayComponent
        }
        additionalPays {
          ...compensationPayComponent
        }
        additional
        payrollStart
        grant(contractId: $id) {
          ...grantComponent
        }
        payType
        deductions {
          deductionDefinitionId
          value
          unit
        }
      }
      onboarding {
        status
        steps
        current
        activationCutoff
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${GrantComponentFragmentDoc}
`;
export type UpdateCompensationMutationFn = Apollo.MutationFunction<
  UpdateCompensationMutation,
  UpdateCompensationMutationVariables
>;

/**
 * __useUpdateCompensationMutation__
 *
 * To run a mutation, you first call `useUpdateCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompensationMutation, { data, loading, error }] = useUpdateCompensationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompensationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompensationMutation,
    UpdateCompensationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompensationMutation,
    UpdateCompensationMutationVariables
  >(UpdateCompensationDocument, options);
}
export type UpdateCompensationMutationHookResult = ReturnType<
  typeof useUpdateCompensationMutation
>;
export type UpdateCompensationMutationResult = Apollo.MutationResult<UpdateCompensationMutation>;
export type UpdateCompensationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompensationMutation,
  UpdateCompensationMutationVariables
>;
export const UpdateComplianceDocument = gql`
  mutation UpdateCompliance(
    $contractId: ID!
    $complianceInput: ContractUpdateComplianceInput!
  ) {
    contractUpdateCompliance(contractId: $contractId, input: $complianceInput) {
      id
      compliance {
        ...compliance
      }
      onboarding {
        status
        steps
        current
      }
    }
  }
  ${ComplianceFragmentDoc}
`;
export type UpdateComplianceMutationFn = Apollo.MutationFunction<
  UpdateComplianceMutation,
  UpdateComplianceMutationVariables
>;

/**
 * __useUpdateComplianceMutation__
 *
 * To run a mutation, you first call `useUpdateComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComplianceMutation, { data, loading, error }] = useUpdateComplianceMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      complianceInput: // value for 'complianceInput'
 *   },
 * });
 */
export function useUpdateComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateComplianceMutation,
    UpdateComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateComplianceMutation,
    UpdateComplianceMutationVariables
  >(UpdateComplianceDocument, options);
}
export type UpdateComplianceMutationHookResult = ReturnType<
  typeof useUpdateComplianceMutation
>;
export type UpdateComplianceMutationResult = Apollo.MutationResult<UpdateComplianceMutation>;
export type UpdateComplianceMutationOptions = Apollo.BaseMutationOptions<
  UpdateComplianceMutation,
  UpdateComplianceMutationVariables
>;
export const UpdateDeductionDocument = gql`
  mutation UpdateDeduction($contractID: ID!, $input: [DeductionInput!]!) {
    contractUpdateDeductions(id: $contractID, input: $input) {
      deductionDefinitionId
      value
    }
  }
`;
export type UpdateDeductionMutationFn = Apollo.MutationFunction<
  UpdateDeductionMutation,
  UpdateDeductionMutationVariables
>;

/**
 * __useUpdateDeductionMutation__
 *
 * To run a mutation, you first call `useUpdateDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeductionMutation, { data, loading, error }] = useUpdateDeductionMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeductionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDeductionMutation,
    UpdateDeductionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDeductionMutation,
    UpdateDeductionMutationVariables
  >(UpdateDeductionDocument, options);
}
export type UpdateDeductionMutationHookResult = ReturnType<
  typeof useUpdateDeductionMutation
>;
export type UpdateDeductionMutationResult = Apollo.MutationResult<UpdateDeductionMutation>;
export type UpdateDeductionMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeductionMutation,
  UpdateDeductionMutationVariables
>;
export const UpdateContractEmploymentDocument = gql`
  mutation UpdateContractEmployment(
    $id: ID!
    $input: ContractUpdateEmploymentInput!
  ) {
    contractUpdateEmployment(id: $id, input: $input) {
      id
      term
      startOn
      endOn
      position
      scope
      employeeId
      workEmail
      onboarding {
        status
        steps
        current
      }
    }
  }
`;
export type UpdateContractEmploymentMutationFn = Apollo.MutationFunction<
  UpdateContractEmploymentMutation,
  UpdateContractEmploymentMutationVariables
>;

/**
 * __useUpdateContractEmploymentMutation__
 *
 * To run a mutation, you first call `useUpdateContractEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractEmploymentMutation, { data, loading, error }] = useUpdateContractEmploymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractEmploymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractEmploymentMutation,
    UpdateContractEmploymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractEmploymentMutation,
    UpdateContractEmploymentMutationVariables
  >(UpdateContractEmploymentDocument, options);
}
export type UpdateContractEmploymentMutationHookResult = ReturnType<
  typeof useUpdateContractEmploymentMutation
>;
export type UpdateContractEmploymentMutationResult = Apollo.MutationResult<UpdateContractEmploymentMutation>;
export type UpdateContractEmploymentMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractEmploymentMutation,
  UpdateContractEmploymentMutationVariables
>;
export const UpdateLeaveEntitlementDocument = gql`
  mutation UpdateLeaveEntitlement(
    $id: ID!
    $input: [ContractUpdateTimeOffEntitlementsInput]!
  ) {
    contractUpdateTimeOffEntitlements(id: $id, input: $input) {
      id
      compliance {
        ...compliance
      }
    }
  }
  ${ComplianceFragmentDoc}
`;
export type UpdateLeaveEntitlementMutationFn = Apollo.MutationFunction<
  UpdateLeaveEntitlementMutation,
  UpdateLeaveEntitlementMutationVariables
>;

/**
 * __useUpdateLeaveEntitlementMutation__
 *
 * To run a mutation, you first call `useUpdateLeaveEntitlementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaveEntitlementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaveEntitlementMutation, { data, loading, error }] = useUpdateLeaveEntitlementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLeaveEntitlementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLeaveEntitlementMutation,
    UpdateLeaveEntitlementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLeaveEntitlementMutation,
    UpdateLeaveEntitlementMutationVariables
  >(UpdateLeaveEntitlementDocument, options);
}
export type UpdateLeaveEntitlementMutationHookResult = ReturnType<
  typeof useUpdateLeaveEntitlementMutation
>;
export type UpdateLeaveEntitlementMutationResult = Apollo.MutationResult<UpdateLeaveEntitlementMutation>;
export type UpdateLeaveEntitlementMutationOptions = Apollo.BaseMutationOptions<
  UpdateLeaveEntitlementMutation,
  UpdateLeaveEntitlementMutationVariables
>;
export const UpdateMemberPrimaryEmailDocument = gql`
  mutation UpdateMemberPrimaryEmail($id: ID!, $input: EmailAddressInput!) {
    memberChangePrimaryEmail(id: $id, email: $input) {
      id
      emails {
        type
        email
      }
    }
  }
`;
export type UpdateMemberPrimaryEmailMutationFn = Apollo.MutationFunction<
  UpdateMemberPrimaryEmailMutation,
  UpdateMemberPrimaryEmailMutationVariables
>;

/**
 * __useUpdateMemberPrimaryEmailMutation__
 *
 * To run a mutation, you first call `useUpdateMemberPrimaryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberPrimaryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberPrimaryEmailMutation, { data, loading, error }] = useUpdateMemberPrimaryEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberPrimaryEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberPrimaryEmailMutation,
    UpdateMemberPrimaryEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberPrimaryEmailMutation,
    UpdateMemberPrimaryEmailMutationVariables
  >(UpdateMemberPrimaryEmailDocument, options);
}
export type UpdateMemberPrimaryEmailMutationHookResult = ReturnType<
  typeof useUpdateMemberPrimaryEmailMutation
>;
export type UpdateMemberPrimaryEmailMutationResult = Apollo.MutationResult<UpdateMemberPrimaryEmailMutation>;
export type UpdateMemberPrimaryEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberPrimaryEmailMutation,
  UpdateMemberPrimaryEmailMutationVariables
>;
export const UpdateMemberDocument = gql`
  mutation UpdateMember($id: ID!, $input: MemberUpdateInput!) {
    memberUpdate(id: $id, memberChange: $input) {
      id
      firstName
      lastName
      gender
    }
  }
`;
export type UpdateMemberMutationFn = Apollo.MutationFunction<
  UpdateMemberMutation,
  UpdateMemberMutationVariables
>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberMutation,
    UpdateMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberMutation,
    UpdateMemberMutationVariables
  >(UpdateMemberDocument, options);
}
export type UpdateMemberMutationHookResult = ReturnType<
  typeof useUpdateMemberMutation
>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberMutation,
  UpdateMemberMutationVariables
>;
export const UploadCustomContractDocument = gql`
  mutation UploadCustomContract($id: ID!, $file: Upload) {
    contractAgreementCustomUpload(contractId: $id, file: $file) {
      id
      compliance {
        ...compliance
      }
      onboarding {
        status
        steps
        current
      }
    }
  }
  ${ComplianceFragmentDoc}
`;
export type UploadCustomContractMutationFn = Apollo.MutationFunction<
  UploadCustomContractMutation,
  UploadCustomContractMutationVariables
>;

/**
 * __useUploadCustomContractMutation__
 *
 * To run a mutation, you first call `useUploadCustomContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCustomContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCustomContractMutation, { data, loading, error }] = useUploadCustomContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCustomContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCustomContractMutation,
    UploadCustomContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadCustomContractMutation,
    UploadCustomContractMutationVariables
  >(UploadCustomContractDocument, options);
}
export type UploadCustomContractMutationHookResult = ReturnType<
  typeof useUploadCustomContractMutation
>;
export type UploadCustomContractMutationResult = Apollo.MutationResult<UploadCustomContractMutation>;
export type UploadCustomContractMutationOptions = Apollo.BaseMutationOptions<
  UploadCustomContractMutation,
  UploadCustomContractMutationVariables
>;
export const ContractConfirmWetInkContractPrepareDocument = gql`
  mutation ContractConfirmWetInkContractPrepare($id: ID!) {
    contractConfirmWetInkContractPrepare(id: $id) {
      id
      onboarding {
        statuses
        current
        status
        steps
      }
      depositPayable {
        id
        invoice {
          id
          status
        }
      }
    }
  }
`;
export type ContractConfirmWetInkContractPrepareMutationFn = Apollo.MutationFunction<
  ContractConfirmWetInkContractPrepareMutation,
  ContractConfirmWetInkContractPrepareMutationVariables
>;

/**
 * __useContractConfirmWetInkContractPrepareMutation__
 *
 * To run a mutation, you first call `useContractConfirmWetInkContractPrepareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractConfirmWetInkContractPrepareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractConfirmWetInkContractPrepareMutation, { data, loading, error }] = useContractConfirmWetInkContractPrepareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractConfirmWetInkContractPrepareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractConfirmWetInkContractPrepareMutation,
    ContractConfirmWetInkContractPrepareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractConfirmWetInkContractPrepareMutation,
    ContractConfirmWetInkContractPrepareMutationVariables
  >(ContractConfirmWetInkContractPrepareDocument, options);
}
export type ContractConfirmWetInkContractPrepareMutationHookResult = ReturnType<
  typeof useContractConfirmWetInkContractPrepareMutation
>;
export type ContractConfirmWetInkContractPrepareMutationResult = Apollo.MutationResult<ContractConfirmWetInkContractPrepareMutation>;
export type ContractConfirmWetInkContractPrepareMutationOptions = Apollo.BaseMutationOptions<
  ContractConfirmWetInkContractPrepareMutation,
  ContractConfirmWetInkContractPrepareMutationVariables
>;
export const UpdateContractBenefitDependentBasicDetailsDocument = gql`
  mutation updateContractBenefitDependentBasicDetails(
    $contractBenefitId: ID!
    $input: [ContractUpdateBenefitDependentInput]!
  ) {
    contractUpdateBenefitDependentBasicDetails(
      contractBenefitId: $contractBenefitId
      input: $input
    ) {
      id
      benefits {
        dependents {
          country
          dateOfBirth
          firstName
          gender
          id
          lastName
          relationshipToMember
          legalData {
            key
            value
          }
        }
      }
    }
  }
`;
export type UpdateContractBenefitDependentBasicDetailsMutationFn = Apollo.MutationFunction<
  UpdateContractBenefitDependentBasicDetailsMutation,
  UpdateContractBenefitDependentBasicDetailsMutationVariables
>;

/**
 * __useUpdateContractBenefitDependentBasicDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateContractBenefitDependentBasicDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractBenefitDependentBasicDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractBenefitDependentBasicDetailsMutation, { data, loading, error }] = useUpdateContractBenefitDependentBasicDetailsMutation({
 *   variables: {
 *      contractBenefitId: // value for 'contractBenefitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractBenefitDependentBasicDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractBenefitDependentBasicDetailsMutation,
    UpdateContractBenefitDependentBasicDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractBenefitDependentBasicDetailsMutation,
    UpdateContractBenefitDependentBasicDetailsMutationVariables
  >(UpdateContractBenefitDependentBasicDetailsDocument, options);
}
export type UpdateContractBenefitDependentBasicDetailsMutationHookResult = ReturnType<
  typeof useUpdateContractBenefitDependentBasicDetailsMutation
>;
export type UpdateContractBenefitDependentBasicDetailsMutationResult = Apollo.MutationResult<UpdateContractBenefitDependentBasicDetailsMutation>;
export type UpdateContractBenefitDependentBasicDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractBenefitDependentBasicDetailsMutation,
  UpdateContractBenefitDependentBasicDetailsMutationVariables
>;
export const CompleteContractDocument = gql`
  mutation CompleteContract($id: ID!) {
    contractMemberCompleted(id: $id) {
      id
      onboarding {
        status
        steps
        current
        statuses
      }
    }
  }
`;
export type CompleteContractMutationFn = Apollo.MutationFunction<
  CompleteContractMutation,
  CompleteContractMutationVariables
>;

/**
 * __useCompleteContractMutation__
 *
 * To run a mutation, you first call `useCompleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeContractMutation, { data, loading, error }] = useCompleteContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteContractMutation,
    CompleteContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteContractMutation,
    CompleteContractMutationVariables
  >(CompleteContractDocument, options);
}
export type CompleteContractMutationHookResult = ReturnType<
  typeof useCompleteContractMutation
>;
export type CompleteContractMutationResult = Apollo.MutationResult<CompleteContractMutation>;
export type CompleteContractMutationOptions = Apollo.BaseMutationOptions<
  CompleteContractMutation,
  CompleteContractMutationVariables
>;
export const ContractMemberBenefitCompletedDocument = gql`
  mutation ContractMemberBenefitCompleted($contractId: ID!) {
    contractMemberBenefitCompleted(id: $contractId) {
      id
      status
      type
      onboarding {
        current
        status
        steps
        __typename
      }
      __typename
    }
  }
`;
export type ContractMemberBenefitCompletedMutationFn = Apollo.MutationFunction<
  ContractMemberBenefitCompletedMutation,
  ContractMemberBenefitCompletedMutationVariables
>;

/**
 * __useContractMemberBenefitCompletedMutation__
 *
 * To run a mutation, you first call `useContractMemberBenefitCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractMemberBenefitCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractMemberBenefitCompletedMutation, { data, loading, error }] = useContractMemberBenefitCompletedMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useContractMemberBenefitCompletedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractMemberBenefitCompletedMutation,
    ContractMemberBenefitCompletedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractMemberBenefitCompletedMutation,
    ContractMemberBenefitCompletedMutationVariables
  >(ContractMemberBenefitCompletedDocument, options);
}
export type ContractMemberBenefitCompletedMutationHookResult = ReturnType<
  typeof useContractMemberBenefitCompletedMutation
>;
export type ContractMemberBenefitCompletedMutationResult = Apollo.MutationResult<ContractMemberBenefitCompletedMutation>;
export type ContractMemberBenefitCompletedMutationOptions = Apollo.BaseMutationOptions<
  ContractMemberBenefitCompletedMutation,
  ContractMemberBenefitCompletedMutationVariables
>;
export const GetMemberCompanyDocument = gql`
  query GetMemberCompany {
    member {
      id
      contracts(includeAnyStatus: true) {
        company {
          id
          displayName
          status
        }
      }
    }
  }
`;

/**
 * __useGetMemberCompanyQuery__
 *
 * To run a query within a React component, call `useGetMemberCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberCompanyQuery,
    GetMemberCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberCompanyQuery, GetMemberCompanyQueryVariables>(
    GetMemberCompanyDocument,
    options,
  );
}
export function useGetMemberCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberCompanyQuery,
    GetMemberCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberCompanyQuery,
    GetMemberCompanyQueryVariables
  >(GetMemberCompanyDocument, options);
}
export type GetMemberCompanyQueryHookResult = ReturnType<
  typeof useGetMemberCompanyQuery
>;
export type GetMemberCompanyLazyQueryHookResult = ReturnType<
  typeof useGetMemberCompanyLazyQuery
>;
export type GetMemberCompanyQueryResult = Apollo.QueryResult<
  GetMemberCompanyQuery,
  GetMemberCompanyQueryVariables
>;
export const GetMemberCountryRequirementsDocument = gql`
  query GetMemberCountryRequirements(
    $country: CountryCode!
    $countryStateCode: String
    $contractType: ContractType
    $workStatus: CountryWorkStatus
    $fetchStage: FetchStage
  ) {
    country(country: $country) {
      name
      code
      compliance(countryStateCode: $countryStateCode) {
        countryCode
        countryState {
          name
          code
        }
        memberDataRequirements(
          filter: {
            workStatus: $workStatus
            contractType: $contractType
            fetchStage: $fetchStage
          }
        ) {
          key
          label
          description
          required
          dependsOn {
            key
          }
          dataType {
            ... on TextField {
              type
              minLength
              maxLength
            }
            ... on DateField {
              minDate
              maxDate
            }
            ... on DropDownField {
              values
            }
            ... on DropDownTextField {
              minLength
              maxLength
              type
              values
            }
            ... on CheckboxField {
              defaultValue
            }
            ... on AutoCompleteField {
              optionListType
            }
          }
          dependsOn {
            key
          }
          editable
          domainType
        }
      }
      memberDocumentRequirements(contractType: $contractType) {
        key
        label
        description
        required
        acceptMultiple
        applyTo
        category
      }
    }
  }
`;

/**
 * __useGetMemberCountryRequirementsQuery__
 *
 * To run a query within a React component, call `useGetMemberCountryRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberCountryRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberCountryRequirementsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      contractType: // value for 'contractType'
 *      workStatus: // value for 'workStatus'
 *      fetchStage: // value for 'fetchStage'
 *   },
 * });
 */
export function useGetMemberCountryRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberCountryRequirementsQuery,
    GetMemberCountryRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberCountryRequirementsQuery,
    GetMemberCountryRequirementsQueryVariables
  >(GetMemberCountryRequirementsDocument, options);
}
export function useGetMemberCountryRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberCountryRequirementsQuery,
    GetMemberCountryRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberCountryRequirementsQuery,
    GetMemberCountryRequirementsQueryVariables
  >(GetMemberCountryRequirementsDocument, options);
}
export type GetMemberCountryRequirementsQueryHookResult = ReturnType<
  typeof useGetMemberCountryRequirementsQuery
>;
export type GetMemberCountryRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetMemberCountryRequirementsLazyQuery
>;
export type GetMemberCountryRequirementsQueryResult = Apollo.QueryResult<
  GetMemberCountryRequirementsQuery,
  GetMemberCountryRequirementsQueryVariables
>;
export const GetMemberDetailsForOnboardingDocument = gql`
  query GetMemberDetailsForOnboarding {
    member {
      id
      firstName
      lastName
      gender
      nationalities {
        type
        country
      }
      legalDocuments {
        id
        key
        files {
          id
          name
          extension
          contentType
          downloadUrl
        }
        label
        financialYear
        status
        comment
        category
        createdOn
        updatedOn
      }
      contracts(includeAnyStatus: true) {
        id
        country
        position
        type
        onboarding {
          status
          steps
          current
          statuses
        }
      }
    }
  }
`;

/**
 * __useGetMemberDetailsForOnboardingQuery__
 *
 * To run a query within a React component, call `useGetMemberDetailsForOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberDetailsForOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberDetailsForOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberDetailsForOnboardingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberDetailsForOnboardingQuery,
    GetMemberDetailsForOnboardingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberDetailsForOnboardingQuery,
    GetMemberDetailsForOnboardingQueryVariables
  >(GetMemberDetailsForOnboardingDocument, options);
}
export function useGetMemberDetailsForOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberDetailsForOnboardingQuery,
    GetMemberDetailsForOnboardingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberDetailsForOnboardingQuery,
    GetMemberDetailsForOnboardingQueryVariables
  >(GetMemberDetailsForOnboardingDocument, options);
}
export type GetMemberDetailsForOnboardingQueryHookResult = ReturnType<
  typeof useGetMemberDetailsForOnboardingQuery
>;
export type GetMemberDetailsForOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetMemberDetailsForOnboardingLazyQuery
>;
export type GetMemberDetailsForOnboardingQueryResult = Apollo.QueryResult<
  GetMemberDetailsForOnboardingQuery,
  GetMemberDetailsForOnboardingQueryVariables
>;
export const GetMemberIdDocument = gql`
  query GetMemberId {
    member {
      id
    }
  }
`;

/**
 * __useGetMemberIdQuery__
 *
 * To run a query within a React component, call `useGetMemberIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberIdQuery,
    GetMemberIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberIdQuery, GetMemberIdQueryVariables>(
    GetMemberIdDocument,
    options,
  );
}
export function useGetMemberIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberIdQuery,
    GetMemberIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberIdQuery, GetMemberIdQueryVariables>(
    GetMemberIdDocument,
    options,
  );
}
export type GetMemberIdQueryHookResult = ReturnType<typeof useGetMemberIdQuery>;
export type GetMemberIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberIdLazyQuery
>;
export type GetMemberIdQueryResult = Apollo.QueryResult<
  GetMemberIdQuery,
  GetMemberIdQueryVariables
>;
export const GetMemberVisaDocumentRequirementsDocument = gql`
  query GetMemberVisaDocumentRequirements(
    $destinationCountry: CountryCode!
    $originCountry: CountryCode!
    $categories: [LegalDocumentCategory!]!
  ) {
    visaDocumentRequirements(
      destinationCountry: $destinationCountry
      originCountry: $originCountry
      categories: $categories
    ) {
      key
      label
      description
      required
      acceptMultiple
      category
    }
  }
`;

/**
 * __useGetMemberVisaDocumentRequirementsQuery__
 *
 * To run a query within a React component, call `useGetMemberVisaDocumentRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberVisaDocumentRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberVisaDocumentRequirementsQuery({
 *   variables: {
 *      destinationCountry: // value for 'destinationCountry'
 *      originCountry: // value for 'originCountry'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useGetMemberVisaDocumentRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberVisaDocumentRequirementsQuery,
    GetMemberVisaDocumentRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberVisaDocumentRequirementsQuery,
    GetMemberVisaDocumentRequirementsQueryVariables
  >(GetMemberVisaDocumentRequirementsDocument, options);
}
export function useGetMemberVisaDocumentRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberVisaDocumentRequirementsQuery,
    GetMemberVisaDocumentRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberVisaDocumentRequirementsQuery,
    GetMemberVisaDocumentRequirementsQueryVariables
  >(GetMemberVisaDocumentRequirementsDocument, options);
}
export type GetMemberVisaDocumentRequirementsQueryHookResult = ReturnType<
  typeof useGetMemberVisaDocumentRequirementsQuery
>;
export type GetMemberVisaDocumentRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetMemberVisaDocumentRequirementsLazyQuery
>;
export type GetMemberVisaDocumentRequirementsQueryResult = Apollo.QueryResult<
  GetMemberVisaDocumentRequirementsQuery,
  GetMemberVisaDocumentRequirementsQueryVariables
>;
export const GetMemberWithComplianceDocument = gql`
  query GetMemberWithCompliance {
    member {
      id
      firstName
      lastName
      fullLegalName
      emails {
        type
        email
      }
      addresses {
        key
        street
        line1
        line2
        city
        state
        province
        country
        zipcode
        postalCode
      }
      phoneNos {
        type
        phoneNo
      }
      gender
      maritalStatus
      dateOfBirth
      age
      nationalities {
        type
        country
      }
      contracts(includeAnyStatus: true) {
        id
        employeeId
        term
        type
        country
        countryStateCode
        status
        workEmail
        company {
          id
          displayName
          status
          primaryEntity {
            legalName
            id
          }
          otherEntities {
            id
            legalName
          }
        }
        currency
        workStatus
        employeeId
        workEmail
        legalEntityId
        position
        onboarding {
          status
          steps
          current
        }
        compensation {
          basePay {
            ...fixedPayComponent
          }
          postProbationBasePay {
            ...fixedPayComponent
          }
          probationBasePay {
            ...fixedPayComponent
          }
          additionalPays {
            ...compensationPayComponent
          }
          deductions {
            deductionDefinitionId
            value
            unit
          }
        }
        performanceReviews {
          id
          status
          designation {
            name
            jobDescription
          }
          effectiveDate
          createdOn
        }
        compliance {
          ...compliance
        }
        benefits {
          id
          status
          startOn
          endOn
          benefit {
            ...benefit
          }
        }
        offboarding {
          id
          contractId
          status
          type
          terminationReason
          lastWorkingDay
        }
        alreadyHired
        startOn
        endOn
        endedOn
        scope
        orgAttributes {
          department {
            id
            description
            name
          }
        }
      }
      legalData {
        key
        value
        label
        country
      }
      bankAccounts {
        id
        accountName
        accountNumber
        bankName
        branchName
        currency
        country
        swiftCode
        localBankCode
      }
      legalDocuments {
        ...legalDocument
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${ComplianceFragmentDoc}
  ${BenefitFragmentDoc}
  ${LegalDocumentFragmentDoc}
`;

/**
 * __useGetMemberWithComplianceQuery__
 *
 * To run a query within a React component, call `useGetMemberWithComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberWithComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberWithComplianceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberWithComplianceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberWithComplianceQuery,
    GetMemberWithComplianceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberWithComplianceQuery,
    GetMemberWithComplianceQueryVariables
  >(GetMemberWithComplianceDocument, options);
}
export function useGetMemberWithComplianceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberWithComplianceQuery,
    GetMemberWithComplianceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberWithComplianceQuery,
    GetMemberWithComplianceQueryVariables
  >(GetMemberWithComplianceDocument, options);
}
export type GetMemberWithComplianceQueryHookResult = ReturnType<
  typeof useGetMemberWithComplianceQuery
>;
export type GetMemberWithComplianceLazyQueryHookResult = ReturnType<
  typeof useGetMemberWithComplianceLazyQuery
>;
export type GetMemberWithComplianceQueryResult = Apollo.QueryResult<
  GetMemberWithComplianceQuery,
  GetMemberWithComplianceQueryVariables
>;
export const GetMemberWithContractStartOnDocument = gql`
  query GetMemberWithContractStartOn {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        status
        contractStarted
        startOn
        incomingPayrollCutoffDate
        onboarding {
          activationCutoff
        }
      }
    }
  }
`;

/**
 * __useGetMemberWithContractStartOnQuery__
 *
 * To run a query within a React component, call `useGetMemberWithContractStartOnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberWithContractStartOnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberWithContractStartOnQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberWithContractStartOnQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberWithContractStartOnQuery,
    GetMemberWithContractStartOnQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberWithContractStartOnQuery,
    GetMemberWithContractStartOnQueryVariables
  >(GetMemberWithContractStartOnDocument, options);
}
export function useGetMemberWithContractStartOnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberWithContractStartOnQuery,
    GetMemberWithContractStartOnQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberWithContractStartOnQuery,
    GetMemberWithContractStartOnQueryVariables
  >(GetMemberWithContractStartOnDocument, options);
}
export type GetMemberWithContractStartOnQueryHookResult = ReturnType<
  typeof useGetMemberWithContractStartOnQuery
>;
export type GetMemberWithContractStartOnLazyQueryHookResult = ReturnType<
  typeof useGetMemberWithContractStartOnLazyQuery
>;
export type GetMemberWithContractStartOnQueryResult = Apollo.QueryResult<
  GetMemberWithContractStartOnQuery,
  GetMemberWithContractStartOnQueryVariables
>;
export const GetMemberDocument = gql`
  query GetMember {
    member {
      id
      firstName
      lastName
      fullLegalName
      emails {
        type
        email
      }
      addresses {
        key
        street
        line1
        line2
        city
        state
        province
        country
        zipcode
        postalCode
      }
      phoneNos {
        type
        phoneNo
      }
      gender
      maritalStatus
      dateOfBirth
      age
      nationalities {
        type
        country
      }
      contracts(includeAnyStatus: true) {
        id
        term
        type
        country
        countryStateCode
        status
        company {
          id
          displayName
          status
        }
        benefits {
          id
          billAmount
          discountTermPercentage
          employeePayPercentage
          employerPayPercentage
          externalPolicyFileUrl
          externalPolicyId
          startOn
          endOn
          dependents {
            id
            dateOfBirth
            startOn
            endOn
            documents {
              key
              files {
                id
                downloadUrl
                name
                size
              }
            }
            externalPolicyFileUrl
            externalPolicyId
            firstName
            gender
            lastName
            legalData {
              key
              value
            }
            relationshipToMember
          }
          dependentCount
          status
          benefit {
            id
            cost
            costingType
            benefitDocuments {
              benefitDocumentType
              files {
                id
                downloadUrl
                name
              }
            }
            memberOnboardingKit {
              memberOnboardingKitFile {
                files {
                  id
                  downloadUrl
                  name
                }
                benefitDocumentType
              }
            }
            features {
              key
              label
              value
            }
            packageName
            packageTier {
              tier
            }
            provider {
              id
              partnerName
              status
              isInsuranceMandatory
              emergencyPointOfContact {
                firstName
                lastName
                emails {
                  type
                  email
                }
              }
              metaData {
                pocName
                emails {
                  type
                  email
                }
                phoneNos {
                  phoneNo
                  type
                }
              }
              brokerData {
                name
              }
              entity {
                legalName
              }
            }
            type
          }
          params {
            key
            value
          }
        }
        compliance {
          contractAgreement {
            id
          }
        }
        compensation {
          deductions {
            deductionDefinitionId
            value
            unit
          }
        }
        currency
        workStatus
        position
        onboarding {
          status
          steps
          current
          statuses
          activationCutoff
        }
        alreadyHired
        startOn
        createdOn
        endOn
        paymentAccountRequirements {
          ...paymentAccountRequirementFragment
        }
        contractStarted
        payslips {
          id
        }
        categoryApproverInfos(
          returnAdminsIfNoManagers: true
          activeManagersOnly: true
        ) {
          category
          approverUsers {
            id
            firstName
            lastName
          }
        }
        legalEntityId
      }
      legalData {
        key
        value
        label
        country
        identifier
      }
      bankAccounts {
        id
        version
        accountName
        accountNumber
        bankName
        branchName
        currency
        country
        swiftCode
        localBankCode
        paymentAccountRequirementType
        accountDetails {
          key
          value
        }
        bankStatements {
          id
          name
          extension
          link
          contentType
          size
        }
      }
      legalDocuments {
        ...legalDocument
      }
    }
  }
  ${PaymentAccountRequirementFragmentFragmentDoc}
  ${LegalDocumentFragmentDoc}
`;

/**
 * __useGetMemberQuery__
 *
 * To run a query within a React component, call `useGetMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberQuery,
    GetMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberQuery, GetMemberQueryVariables>(
    GetMemberDocument,
    options,
  );
}
export function useGetMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberQuery,
    GetMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberQuery, GetMemberQueryVariables>(
    GetMemberDocument,
    options,
  );
}
export type GetMemberQueryHookResult = ReturnType<typeof useGetMemberQuery>;
export type GetMemberLazyQueryHookResult = ReturnType<
  typeof useGetMemberLazyQuery
>;
export type GetMemberQueryResult = Apollo.QueryResult<
  GetMemberQuery,
  GetMemberQueryVariables
>;
export const MemberUpdateBasicDetailsDocument = gql`
  mutation MemberUpdateBasicDetails(
    $id: ID!
    $input: MemberUpdateBasicDetailsInput!
  ) {
    memberUpdateBasicDetails(id: $id, data: $input) {
      id
      fullLegalName
      nationalities {
        type
        country
      }
      phoneNos {
        type
        phoneNo
      }
      dateOfBirth
      addresses {
        key
        street
        line1
        line2
        city
        state
        province
        country
        zipcode
        postalCode
      }
      contracts(status: null) {
        onboarding {
          steps
          current
        }
      }
    }
  }
`;
export type MemberUpdateBasicDetailsMutationFn = Apollo.MutationFunction<
  MemberUpdateBasicDetailsMutation,
  MemberUpdateBasicDetailsMutationVariables
>;

/**
 * __useMemberUpdateBasicDetailsMutation__
 *
 * To run a mutation, you first call `useMemberUpdateBasicDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpdateBasicDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpdateBasicDetailsMutation, { data, loading, error }] = useMemberUpdateBasicDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpdateBasicDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpdateBasicDetailsMutation,
    MemberUpdateBasicDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpdateBasicDetailsMutation,
    MemberUpdateBasicDetailsMutationVariables
  >(MemberUpdateBasicDetailsDocument, options);
}
export type MemberUpdateBasicDetailsMutationHookResult = ReturnType<
  typeof useMemberUpdateBasicDetailsMutation
>;
export type MemberUpdateBasicDetailsMutationResult = Apollo.MutationResult<MemberUpdateBasicDetailsMutation>;
export type MemberUpdateBasicDetailsMutationOptions = Apollo.BaseMutationOptions<
  MemberUpdateBasicDetailsMutation,
  MemberUpdateBasicDetailsMutationVariables
>;
export const MemberUpdateLegalDataDocument = gql`
  mutation MemberUpdateLegalData($id: ID!, $input: [LegalDataInput!]!) {
    memberUpdateLegalData(id: $id, data: $input) {
      id
      legalData {
        key
        value
        label
        country
      }
      contracts {
        onboarding {
          status
          steps
          current
        }
      }
    }
  }
`;
export type MemberUpdateLegalDataMutationFn = Apollo.MutationFunction<
  MemberUpdateLegalDataMutation,
  MemberUpdateLegalDataMutationVariables
>;

/**
 * __useMemberUpdateLegalDataMutation__
 *
 * To run a mutation, you first call `useMemberUpdateLegalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpdateLegalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpdateLegalDataMutation, { data, loading, error }] = useMemberUpdateLegalDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpdateLegalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpdateLegalDataMutation,
    MemberUpdateLegalDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpdateLegalDataMutation,
    MemberUpdateLegalDataMutationVariables
  >(MemberUpdateLegalDataDocument, options);
}
export type MemberUpdateLegalDataMutationHookResult = ReturnType<
  typeof useMemberUpdateLegalDataMutation
>;
export type MemberUpdateLegalDataMutationResult = Apollo.MutationResult<MemberUpdateLegalDataMutation>;
export type MemberUpdateLegalDataMutationOptions = Apollo.BaseMutationOptions<
  MemberUpdateLegalDataMutation,
  MemberUpdateLegalDataMutationVariables
>;
export const OptOutInsuranceDocument = gql`
  mutation OptOutInsurance($contractBenefitId: ID!, $reasons: [String!]!) {
    optOutInsurance(contractBenefitId: $contractBenefitId, reasons: $reasons) {
      id
      status
      benefit {
        id
        type
      }
      contract {
        id
        status
        type
        onboarding {
          current
          status
          steps
          __typename
        }
      }
    }
  }
`;
export type OptOutInsuranceMutationFn = Apollo.MutationFunction<
  OptOutInsuranceMutation,
  OptOutInsuranceMutationVariables
>;

/**
 * __useOptOutInsuranceMutation__
 *
 * To run a mutation, you first call `useOptOutInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptOutInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optOutInsuranceMutation, { data, loading, error }] = useOptOutInsuranceMutation({
 *   variables: {
 *      contractBenefitId: // value for 'contractBenefitId'
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useOptOutInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OptOutInsuranceMutation,
    OptOutInsuranceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OptOutInsuranceMutation,
    OptOutInsuranceMutationVariables
  >(OptOutInsuranceDocument, options);
}
export type OptOutInsuranceMutationHookResult = ReturnType<
  typeof useOptOutInsuranceMutation
>;
export type OptOutInsuranceMutationResult = Apollo.MutationResult<OptOutInsuranceMutation>;
export type OptOutInsuranceMutationOptions = Apollo.BaseMutationOptions<
  OptOutInsuranceMutation,
  OptOutInsuranceMutationVariables
>;
export const UpdateBankDetailsDocument = gql`
  mutation updateBankDetails($id: ID!, $bankAccount: BankAccountInput!) {
    memberUpdateBankDetails(id: $id, data: $bankAccount) {
      id
      bankAccounts {
        id
        accountName
        accountNumber
        bankName
        branchName
        swiftCode
        localBankCode
        bankStatements {
          id
          name
          extension
          link
          contentType
          size
        }
      }
      contracts(status: null) {
        onboarding {
          status
          steps
          current
        }
      }
    }
  }
`;
export type UpdateBankDetailsMutationFn = Apollo.MutationFunction<
  UpdateBankDetailsMutation,
  UpdateBankDetailsMutationVariables
>;

/**
 * __useUpdateBankDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateBankDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankDetailsMutation, { data, loading, error }] = useUpdateBankDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      bankAccount: // value for 'bankAccount'
 *   },
 * });
 */
export function useUpdateBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBankDetailsMutation,
    UpdateBankDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBankDetailsMutation,
    UpdateBankDetailsMutationVariables
  >(UpdateBankDetailsDocument, options);
}
export type UpdateBankDetailsMutationHookResult = ReturnType<
  typeof useUpdateBankDetailsMutation
>;
export type UpdateBankDetailsMutationResult = Apollo.MutationResult<UpdateBankDetailsMutation>;
export type UpdateBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateBankDetailsMutation,
  UpdateBankDetailsMutationVariables
>;
export const UpdateMemberDataForOnboardingMemberDocument = gql`
  mutation UpdateMemberDataForOnboardingMember(
    $memberId: ID!
    $generalDataChange: MemberUpdateInput!
    $basicDetailsChange: MemberUpdateBasicDetailsInput!
    $legalDataChange: [LegalDataInput!]!
  ) {
    memberUpdate(id: $memberId, memberChange: $generalDataChange) {
      id
      firstName
      lastName
      gender
    }
    memberUpdateBasicDetails(id: $memberId, data: $basicDetailsChange) {
      id
      fullLegalName
      nationalities {
        type
        country
      }
      phoneNos {
        type
        phoneNo
      }
      dateOfBirth
      addresses {
        key
        street
        line1
        line2
        city
        state
        province
        country
        zipcode
        postalCode
      }
      contracts(status: null) {
        onboarding {
          steps
          current
        }
      }
    }
    memberUpdateLegalData(id: $memberId, data: $legalDataChange) {
      id
      legalData {
        key
        value
        label
        country
        identifier
      }
      contracts(status: null) {
        onboarding {
          status
          steps
          current
        }
      }
    }
  }
`;
export type UpdateMemberDataForOnboardingMemberMutationFn = Apollo.MutationFunction<
  UpdateMemberDataForOnboardingMemberMutation,
  UpdateMemberDataForOnboardingMemberMutationVariables
>;

/**
 * __useUpdateMemberDataForOnboardingMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberDataForOnboardingMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberDataForOnboardingMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberDataForOnboardingMemberMutation, { data, loading, error }] = useUpdateMemberDataForOnboardingMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      generalDataChange: // value for 'generalDataChange'
 *      basicDetailsChange: // value for 'basicDetailsChange'
 *      legalDataChange: // value for 'legalDataChange'
 *   },
 * });
 */
export function useUpdateMemberDataForOnboardingMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberDataForOnboardingMemberMutation,
    UpdateMemberDataForOnboardingMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberDataForOnboardingMemberMutation,
    UpdateMemberDataForOnboardingMemberMutationVariables
  >(UpdateMemberDataForOnboardingMemberDocument, options);
}
export type UpdateMemberDataForOnboardingMemberMutationHookResult = ReturnType<
  typeof useUpdateMemberDataForOnboardingMemberMutation
>;
export type UpdateMemberDataForOnboardingMemberMutationResult = Apollo.MutationResult<UpdateMemberDataForOnboardingMemberMutation>;
export type UpdateMemberDataForOnboardingMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberDataForOnboardingMemberMutation,
  UpdateMemberDataForOnboardingMemberMutationVariables
>;
export const UpdateMemberDataForOnboardingMemberWithoutLegalDataDocument = gql`
  mutation UpdateMemberDataForOnboardingMemberWithoutLegalData(
    $memberId: ID!
    $generalDataChange: MemberUpdateInput!
    $basicDetailsChange: MemberUpdateBasicDetailsInput!
  ) {
    memberUpdate(id: $memberId, memberChange: $generalDataChange) {
      id
      firstName
      lastName
      gender
    }
    memberUpdateBasicDetails(id: $memberId, data: $basicDetailsChange) {
      id
      fullLegalName
      nationalities {
        type
        country
      }
      phoneNos {
        type
        phoneNo
      }
      dateOfBirth
      addresses {
        key
        street
        line1
        line2
        city
        state
        province
        country
        zipcode
        postalCode
      }
      contracts(status: null) {
        onboarding {
          steps
          current
        }
      }
    }
  }
`;
export type UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationFn = Apollo.MutationFunction<
  UpdateMemberDataForOnboardingMemberWithoutLegalDataMutation,
  UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationVariables
>;

/**
 * __useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation__
 *
 * To run a mutation, you first call `useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberDataForOnboardingMemberWithoutLegalDataMutation, { data, loading, error }] = useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      generalDataChange: // value for 'generalDataChange'
 *      basicDetailsChange: // value for 'basicDetailsChange'
 *   },
 * });
 */
export function useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberDataForOnboardingMemberWithoutLegalDataMutation,
    UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberDataForOnboardingMemberWithoutLegalDataMutation,
    UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationVariables
  >(UpdateMemberDataForOnboardingMemberWithoutLegalDataDocument, options);
}
export type UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationHookResult = ReturnType<
  typeof useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation
>;
export type UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationResult = Apollo.MutationResult<UpdateMemberDataForOnboardingMemberWithoutLegalDataMutation>;
export type UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberDataForOnboardingMemberWithoutLegalDataMutation,
  UpdateMemberDataForOnboardingMemberWithoutLegalDataMutationVariables
>;
export const UpdateContractBenefitDependentDocumentsDocument = gql`
  mutation updateContractBenefitDependentDocuments(
    $contractBenefitDependentId: ID!
    $key: ContractBenefitDependentDocumentType
    $documents: [Upload]
  ) {
    contractUpdateBenefitDependentDocuments(
      contractBenefitDependentId: $contractBenefitDependentId
      key: $key
      documents: $documents
    ) {
      id
      benefits {
        dependents {
          id
          firstName
          gender
          lastName
          relationshipToMember
        }
      }
    }
  }
`;
export type UpdateContractBenefitDependentDocumentsMutationFn = Apollo.MutationFunction<
  UpdateContractBenefitDependentDocumentsMutation,
  UpdateContractBenefitDependentDocumentsMutationVariables
>;

/**
 * __useUpdateContractBenefitDependentDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateContractBenefitDependentDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractBenefitDependentDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractBenefitDependentDocumentsMutation, { data, loading, error }] = useUpdateContractBenefitDependentDocumentsMutation({
 *   variables: {
 *      contractBenefitDependentId: // value for 'contractBenefitDependentId'
 *      key: // value for 'key'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useUpdateContractBenefitDependentDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractBenefitDependentDocumentsMutation,
    UpdateContractBenefitDependentDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractBenefitDependentDocumentsMutation,
    UpdateContractBenefitDependentDocumentsMutationVariables
  >(UpdateContractBenefitDependentDocumentsDocument, options);
}
export type UpdateContractBenefitDependentDocumentsMutationHookResult = ReturnType<
  typeof useUpdateContractBenefitDependentDocumentsMutation
>;
export type UpdateContractBenefitDependentDocumentsMutationResult = Apollo.MutationResult<UpdateContractBenefitDependentDocumentsMutation>;
export type UpdateContractBenefitDependentDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractBenefitDependentDocumentsMutation,
  UpdateContractBenefitDependentDocumentsMutationVariables
>;
export const UpdateLegalDetailsDocument = gql`
  mutation UpdateLegalDetails($id: ID!, $data: [LegalDataInput!]!) {
    memberUpdateLegalData(id: $id, data: $data) {
      id
      legalData {
        key
        value
        label
        country
      }
      contracts(status: null) {
        onboarding {
          status
          steps
          current
        }
      }
    }
  }
`;
export type UpdateLegalDetailsMutationFn = Apollo.MutationFunction<
  UpdateLegalDetailsMutation,
  UpdateLegalDetailsMutationVariables
>;

/**
 * __useUpdateLegalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateLegalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalDetailsMutation, { data, loading, error }] = useUpdateLegalDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLegalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLegalDetailsMutation,
    UpdateLegalDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLegalDetailsMutation,
    UpdateLegalDetailsMutationVariables
  >(UpdateLegalDetailsDocument, options);
}
export type UpdateLegalDetailsMutationHookResult = ReturnType<
  typeof useUpdateLegalDetailsMutation
>;
export type UpdateLegalDetailsMutationResult = Apollo.MutationResult<UpdateLegalDetailsMutation>;
export type UpdateLegalDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLegalDetailsMutation,
  UpdateLegalDetailsMutationVariables
>;
export const UpdateLegalDocumentsDocument = gql`
  mutation UpdateLegalDocuments($id: ID!, $data: [LegalDocumentInput!]!) {
    memberUpdateDocument(id: $id, data: $data) {
      id
      legalDocuments {
        ...legalDocument
      }
      contracts(status: null) {
        id
        onboarding {
          status
          steps
          current
        }
      }
    }
  }
  ${LegalDocumentFragmentDoc}
`;
export type UpdateLegalDocumentsMutationFn = Apollo.MutationFunction<
  UpdateLegalDocumentsMutation,
  UpdateLegalDocumentsMutationVariables
>;

/**
 * __useUpdateLegalDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateLegalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalDocumentsMutation, { data, loading, error }] = useUpdateLegalDocumentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLegalDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLegalDocumentsMutation,
    UpdateLegalDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLegalDocumentsMutation,
    UpdateLegalDocumentsMutationVariables
  >(UpdateLegalDocumentsDocument, options);
}
export type UpdateLegalDocumentsMutationHookResult = ReturnType<
  typeof useUpdateLegalDocumentsMutation
>;
export type UpdateLegalDocumentsMutationResult = Apollo.MutationResult<UpdateLegalDocumentsMutation>;
export type UpdateLegalDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLegalDocumentsMutation,
  UpdateLegalDocumentsMutationVariables
>;
export const UpdateVisaDocumentsDocument = gql`
  mutation UpdateVisaDocuments($id: ID!, $data: [LegalDocumentInput!]!) {
    memberUpdateVisaDocument(id: $id, data: $data) {
      id
      legalDocuments {
        ...legalDocument
      }
      contracts(status: null) {
        id
        onboarding {
          status
          steps
          current
        }
      }
    }
  }
  ${LegalDocumentFragmentDoc}
`;
export type UpdateVisaDocumentsMutationFn = Apollo.MutationFunction<
  UpdateVisaDocumentsMutation,
  UpdateVisaDocumentsMutationVariables
>;

/**
 * __useUpdateVisaDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateVisaDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisaDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisaDocumentsMutation, { data, loading, error }] = useUpdateVisaDocumentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVisaDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVisaDocumentsMutation,
    UpdateVisaDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVisaDocumentsMutation,
    UpdateVisaDocumentsMutationVariables
  >(UpdateVisaDocumentsDocument, options);
}
export type UpdateVisaDocumentsMutationHookResult = ReturnType<
  typeof useUpdateVisaDocumentsMutation
>;
export type UpdateVisaDocumentsMutationResult = Apollo.MutationResult<UpdateVisaDocumentsMutation>;
export type UpdateVisaDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisaDocumentsMutation,
  UpdateVisaDocumentsMutationVariables
>;
export const DeleteCompensationGrantDocument = gql`
  mutation DeleteCompensationGrant($contractId: ID!, $grantId: ID!) {
    contractDeleteCompensationGrant(
      contractId: $contractId
      grantId: $grantId
    ) {
      id
    }
  }
`;
export type DeleteCompensationGrantMutationFn = Apollo.MutationFunction<
  DeleteCompensationGrantMutation,
  DeleteCompensationGrantMutationVariables
>;

/**
 * __useDeleteCompensationGrantMutation__
 *
 * To run a mutation, you first call `useDeleteCompensationGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompensationGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompensationGrantMutation, { data, loading, error }] = useDeleteCompensationGrantMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      grantId: // value for 'grantId'
 *   },
 * });
 */
export function useDeleteCompensationGrantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompensationGrantMutation,
    DeleteCompensationGrantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCompensationGrantMutation,
    DeleteCompensationGrantMutationVariables
  >(DeleteCompensationGrantDocument, options);
}
export type DeleteCompensationGrantMutationHookResult = ReturnType<
  typeof useDeleteCompensationGrantMutation
>;
export type DeleteCompensationGrantMutationResult = Apollo.MutationResult<DeleteCompensationGrantMutation>;
export type DeleteCompensationGrantMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompensationGrantMutation,
  DeleteCompensationGrantMutationVariables
>;
export const GetCompanyUsersDocument = gql`
  query GetCompanyUsers {
    company {
      id
      managers {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
        status
      }
      signatories {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
        userId
      }
      billingContact {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
      }
    }
  }
`;

/**
 * __useGetCompanyUsersQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyUsersQuery,
    GetCompanyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(
    GetCompanyUsersDocument,
    options,
  );
}
export function useGetCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyUsersQuery,
    GetCompanyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyUsersQuery,
    GetCompanyUsersQueryVariables
  >(GetCompanyUsersDocument, options);
}
export type GetCompanyUsersQueryHookResult = ReturnType<
  typeof useGetCompanyUsersQuery
>;
export type GetCompanyUsersLazyQueryHookResult = ReturnType<
  typeof useGetCompanyUsersLazyQuery
>;
export type GetCompanyUsersQueryResult = Apollo.QueryResult<
  GetCompanyUsersQuery,
  GetCompanyUsersQueryVariables
>;
export const GetContractDepositExternalLinkDocument = gql`
  query GetContractDepositExternalLink($id: ID!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      depositPayable {
        id
        invoice {
          id
          dueDate
          externalSystem
          externalLink
          status
        }
      }
    }
  }
`;

/**
 * __useGetContractDepositExternalLinkQuery__
 *
 * To run a query within a React component, call `useGetContractDepositExternalLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDepositExternalLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDepositExternalLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractDepositExternalLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractDepositExternalLinkQuery,
    GetContractDepositExternalLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractDepositExternalLinkQuery,
    GetContractDepositExternalLinkQueryVariables
  >(GetContractDepositExternalLinkDocument, options);
}
export function useGetContractDepositExternalLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractDepositExternalLinkQuery,
    GetContractDepositExternalLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractDepositExternalLinkQuery,
    GetContractDepositExternalLinkQueryVariables
  >(GetContractDepositExternalLinkDocument, options);
}
export type GetContractDepositExternalLinkQueryHookResult = ReturnType<
  typeof useGetContractDepositExternalLinkQuery
>;
export type GetContractDepositExternalLinkLazyQueryHookResult = ReturnType<
  typeof useGetContractDepositExternalLinkLazyQuery
>;
export type GetContractDepositExternalLinkQueryResult = Apollo.QueryResult<
  GetContractDepositExternalLinkQuery,
  GetContractDepositExternalLinkQueryVariables
>;
export const GetContractDepositWithPdfDocument = gql`
  query GetContractDepositWithPdf($id: ID!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      depositPayable {
        id
        invoice {
          id
          dueDate
          externalSystem
          document {
            name
            extension
            contentType
            blob
          }
          status
        }
      }
    }
  }
`;

/**
 * __useGetContractDepositWithPdfQuery__
 *
 * To run a query within a React component, call `useGetContractDepositWithPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDepositWithPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDepositWithPdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractDepositWithPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractDepositWithPdfQuery,
    GetContractDepositWithPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractDepositWithPdfQuery,
    GetContractDepositWithPdfQueryVariables
  >(GetContractDepositWithPdfDocument, options);
}
export function useGetContractDepositWithPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractDepositWithPdfQuery,
    GetContractDepositWithPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractDepositWithPdfQuery,
    GetContractDepositWithPdfQueryVariables
  >(GetContractDepositWithPdfDocument, options);
}
export type GetContractDepositWithPdfQueryHookResult = ReturnType<
  typeof useGetContractDepositWithPdfQuery
>;
export type GetContractDepositWithPdfLazyQueryHookResult = ReturnType<
  typeof useGetContractDepositWithPdfLazyQuery
>;
export type GetContractDepositWithPdfQueryResult = Apollo.QueryResult<
  GetContractDepositWithPdfQuery,
  GetContractDepositWithPdfQueryVariables
>;
export const GetContractDetailRestrictionsByCountryDocument = gql`
  query GetContractDetailRestrictionsByCountry($country: CountryCode!) {
    country(country: $country) {
      code
      compliance {
        countryCode
        contractDetailRestrictions {
          id
          alternatives
          countryCode
          field
          operator
          searchTerm
        }
      }
    }
  }
`;

/**
 * __useGetContractDetailRestrictionsByCountryQuery__
 *
 * To run a query within a React component, call `useGetContractDetailRestrictionsByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDetailRestrictionsByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDetailRestrictionsByCountryQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetContractDetailRestrictionsByCountryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractDetailRestrictionsByCountryQuery,
    GetContractDetailRestrictionsByCountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractDetailRestrictionsByCountryQuery,
    GetContractDetailRestrictionsByCountryQueryVariables
  >(GetContractDetailRestrictionsByCountryDocument, options);
}
export function useGetContractDetailRestrictionsByCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractDetailRestrictionsByCountryQuery,
    GetContractDetailRestrictionsByCountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractDetailRestrictionsByCountryQuery,
    GetContractDetailRestrictionsByCountryQueryVariables
  >(GetContractDetailRestrictionsByCountryDocument, options);
}
export type GetContractDetailRestrictionsByCountryQueryHookResult = ReturnType<
  typeof useGetContractDetailRestrictionsByCountryQuery
>;
export type GetContractDetailRestrictionsByCountryLazyQueryHookResult = ReturnType<
  typeof useGetContractDetailRestrictionsByCountryLazyQuery
>;
export type GetContractDetailRestrictionsByCountryQueryResult = Apollo.QueryResult<
  GetContractDetailRestrictionsByCountryQuery,
  GetContractDetailRestrictionsByCountryQueryVariables
>;
export const GetContractPerformanceReviewNoSalaryReviewsDocument = gql`
  query GetContractPerformanceReviewNoSalaryReviews(
    $contractId: ID!
    $includeAnyStatus: Boolean = true
  ) {
    contract(id: $contractId, includeAnyStatus: $includeAnyStatus) {
      id
      performanceReviews {
        id
        status
        designation {
          name
          jobDescription
        }
        previousDesignation {
          name
          jobDescription
        }
        reviewDocument {
          ...documentReadable
        }
        effectiveDate
        createdOn
        comment
      }
    }
  }
  ${DocumentReadableFragmentDoc}
`;

/**
 * __useGetContractPerformanceReviewNoSalaryReviewsQuery__
 *
 * To run a query within a React component, call `useGetContractPerformanceReviewNoSalaryReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractPerformanceReviewNoSalaryReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractPerformanceReviewNoSalaryReviewsQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      includeAnyStatus: // value for 'includeAnyStatus'
 *   },
 * });
 */
export function useGetContractPerformanceReviewNoSalaryReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractPerformanceReviewNoSalaryReviewsQuery,
    GetContractPerformanceReviewNoSalaryReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractPerformanceReviewNoSalaryReviewsQuery,
    GetContractPerformanceReviewNoSalaryReviewsQueryVariables
  >(GetContractPerformanceReviewNoSalaryReviewsDocument, options);
}
export function useGetContractPerformanceReviewNoSalaryReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractPerformanceReviewNoSalaryReviewsQuery,
    GetContractPerformanceReviewNoSalaryReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractPerformanceReviewNoSalaryReviewsQuery,
    GetContractPerformanceReviewNoSalaryReviewsQueryVariables
  >(GetContractPerformanceReviewNoSalaryReviewsDocument, options);
}
export type GetContractPerformanceReviewNoSalaryReviewsQueryHookResult = ReturnType<
  typeof useGetContractPerformanceReviewNoSalaryReviewsQuery
>;
export type GetContractPerformanceReviewNoSalaryReviewsLazyQueryHookResult = ReturnType<
  typeof useGetContractPerformanceReviewNoSalaryReviewsLazyQuery
>;
export type GetContractPerformanceReviewNoSalaryReviewsQueryResult = Apollo.QueryResult<
  GetContractPerformanceReviewNoSalaryReviewsQuery,
  GetContractPerformanceReviewNoSalaryReviewsQueryVariables
>;
export const GetContractV2Document = gql`
  query GetContractV2($id: ID!, $skipDepositPayable: Boolean = false) {
    contract(id: $id, includeAnyStatus: true) {
      id
      term
      type
      currency
      company {
        id
        displayName
        primaryEntity {
          legalName
          id
        }
        otherEntities {
          id
          legalName
        }
      }
      country
      countryStateCode
      legalEntityId
      employeeId
      workStatus
      workShift {
        ...workShift
      }
      status
      workEmail
      member {
        id
        firstName
        middleName
        lastName
        fullLegalName
        status
        emails {
          type
          email
        }
        addresses {
          key
          street
          line1
          line2
          city
          state
          province
          country
          zipcode
          postalCode
        }
        phoneNos {
          type
          phoneNo
        }
        gender
        maritalStatus
        dateOfBirth
        age
        nationalities {
          type
          country
        }
        legalData {
          key
          value
          identifier
          label
          country
        }
        bankAccounts {
          id
          version
          accountName
          accountNumber
          bankName
          branchName
          currency
          country
          swiftCode
          localBankCode
          paymentAccountRequirementType
          accountDetails {
            key
            label
            value
          }
          accountRequirementContext {
            paymentAccountRequirementType
          }
          bankStatements {
            id
            name
            extension
            link
            contentType
            size
          }
        }
        legalDocuments {
          ...legalDocument
        }
        emergencyContact {
          name
          phoneNumber {
            phoneNo
            type
          }
          relationship
        }
        education {
          degree
          gpa
          grade
          lastSchoolAttended
          yearOfPassing
        }
        previousEmployers {
          designation
          endDate
          name
          startDate
        }
        basicInfo {
          firstName
          fullLegalName
          lastName
          middleName
        }
        personalInfo {
          age
          gender
          dateOfBirth
          maritalStatus
          nationalities {
            type
            country
          }
          religion
        }
        contactInfo {
          email {
            email
          }
          mobile {
            phoneNo
          }
          phone {
            phoneNo
          }
        }
        addressDetails {
          currentAddress {
            ...addressFields
          }
          permanentAddress {
            ...addressFields
          }
        }
        changeRequests {
          id
          status
          category
          message
          createdOn
          files {
            id
            contentType
            extension
            downloadUrl
            name
          }
          ...changeRequestItemsFragment
        }
      }
      position
      onboarding {
        status
        statuses
        steps
        current
        contractDocumentWorkflow {
          currentStatus
        }
        activationCutoff
      }
      alreadyHired
      startOn
      createdOn
      endOn
      endedOn
      offboardingNote
      scope
      compensation {
        basePay {
          ...fixedPayComponent
        }
        postProbationBasePay {
          ...fixedPayComponent
        }
        probationBasePay {
          ...fixedPayComponent
        }
        additionalPays {
          ...compensationPayComponent
        }
        additional
        payrollStart
        grant(contractId: $id) {
          ...grantComponent
        }
        payType
        deductions {
          deductionDefinitionId
          value
          unit
        }
      }
      benefits {
        id
        status
        startOn
        endOn
        dependentCount
        employeePayPercentage
        employerPayPercentage
        externalPolicyId
        billAmount
        benefitPackageCost {
          id
          premium
          premiumCurrency
          premiumFrequency
        }
        dependents {
          firstName
          lastName
          gender
          dateOfBirth
          relationshipToMember
          externalPolicyId
          startOn
          endOn
          benefitPackageCost {
            id
            premium
            premiumCurrency
            premiumFrequency
          }
        }
        benefit {
          ...companyBenefit
        }
        params {
          key
          value
        }
      }
      compliance {
        ...compliance
      }
      payslips {
        id
        month {
          month
          year
        }
        payslipDocument {
          downloadUrl
          name
        }
        createdOn
      }
      categoryApproverInfos {
        category
        approverUsers {
          ...companyUser
        }
      }
      timeOff {
        summary {
          key
          definition {
            type
            label
          }
          entitled
          taken
          pending
          remaining
          periodEnd
        }
      }
      contractStarted
      depositPayable @skip(if: $skipDepositPayable) {
        id
        invoice {
          id
          status
          dueDate
          externalSystem
        }
      }
      contractBlocked
      unresolvedBlockEvents {
        id
        source
        status
        blockReason
        blockData {
          ...changeRequestBlockData
        }
      }
      offboarding {
        id
        contractId
        status
        type
        terminationReason
        lastWorkingDay
      }
      paymentAccountRequirements {
        ...paymentAccountRequirementFragment
      }
      reportsToManager {
        id
        contract {
          id
          position
          member {
            firstName
            lastName
          }
        }
      }
      orgAttributes {
        costCenter {
          id
          description
          name
        }
        department {
          id
          description
          name
        }
      }
      performanceReviews {
        id
        status
        designation {
          name
          jobDescription
        }
        effectiveDate
        createdOn
      }
    }
  }
  ${WorkShiftFragmentDoc}
  ${LegalDocumentFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${ChangeRequestItemsFragmentFragmentDoc}
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${GrantComponentFragmentDoc}
  ${CompanyBenefitFragmentDoc}
  ${ComplianceFragmentDoc}
  ${CompanyUserFragmentDoc}
  ${ChangeRequestBlockDataFragmentDoc}
  ${PaymentAccountRequirementFragmentFragmentDoc}
`;

/**
 * __useGetContractV2Query__
 *
 * To run a query within a React component, call `useGetContractV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetContractV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractV2Query({
 *   variables: {
 *      id: // value for 'id'
 *      skipDepositPayable: // value for 'skipDepositPayable'
 *   },
 * });
 */
export function useGetContractV2Query(
  baseOptions: Apollo.QueryHookOptions<
    GetContractV2Query,
    GetContractV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractV2Query, GetContractV2QueryVariables>(
    GetContractV2Document,
    options,
  );
}
export function useGetContractV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractV2Query,
    GetContractV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractV2Query, GetContractV2QueryVariables>(
    GetContractV2Document,
    options,
  );
}
export type GetContractV2QueryHookResult = ReturnType<
  typeof useGetContractV2Query
>;
export type GetContractV2LazyQueryHookResult = ReturnType<
  typeof useGetContractV2LazyQuery
>;
export type GetContractV2QueryResult = Apollo.QueryResult<
  GetContractV2Query,
  GetContractV2QueryVariables
>;
export const GetContractDocument = gql`
  query GetContract($id: ID!, $skipDepositPayable: Boolean = false) {
    contract(id: $id, includeAnyStatus: true) {
      id
      term
      type
      currency
      company {
        id
        displayName
      }
      country
      countryStateCode
      legalEntityId
      employeeId
      workStatus
      workShift {
        ...workShift
      }
      status
      workEmail
      member {
        id
        firstName
        lastName
        fullLegalName
        status
        emails {
          type
          email
        }
        addresses {
          key
          street
          line1
          line2
          city
          state
          province
          country
          zipcode
          postalCode
        }
        phoneNos {
          type
          phoneNo
        }
        gender
        maritalStatus
        dateOfBirth
        age
        nationalities {
          type
          country
        }
        basicInfo {
          firstName
          fullLegalName
          lastName
          middleName
        }
        legalData {
          key
          value
          label
          country
        }
        bankAccounts {
          id
          version
          accountName
          accountNumber
          bankName
          branchName
          currency
          country
          swiftCode
          localBankCode
          paymentAccountRequirementType
          accountDetails {
            key
            value
          }
          bankStatements {
            id
            name
            extension
            link
            contentType
            size
          }
        }
        legalDocuments {
          ...legalDocument
        }
      }
      position
      onboarding {
        status
        statuses
        steps
        current
        contractDocumentWorkflow {
          currentStatus
        }
        activationCutoff
      }
      alreadyHired
      startOn
      createdOn
      endOn
      endedOn
      offboardingNote
      scope
      compensation {
        basePay {
          ...fixedPayComponent
        }
        postProbationBasePay {
          ...fixedPayComponent
        }
        probationBasePay {
          ...fixedPayComponent
        }
        additionalPays {
          ...compensationPayComponent
        }
        additional
        payrollStart
        grant(contractId: $id) {
          ...grantComponent
        }
        payType
        deductions {
          deductionDefinitionId
          value
          unit
        }
      }
      benefits {
        id
        status
        startOn
        endOn
        dependentCount
        employeePayPercentage
        employerPayPercentage
        externalPolicyId
        billAmount
        benefitPackageCost {
          id
          premium
          premiumCurrency
          premiumFrequency
        }
        dependents {
          firstName
          lastName
          gender
          dateOfBirth
          relationshipToMember
          externalPolicyId
          startOn
          endOn
          benefitPackageCost {
            id
            premium
            premiumCurrency
            premiumFrequency
          }
        }
        benefit {
          ...companyBenefit
        }
        params {
          key
          value
        }
      }
      compliance {
        ...compliance
      }
      payslips {
        id
        month {
          month
          year
        }
        payslipDocument {
          downloadUrl
          name
        }
        createdOn
      }
      categoryApproverInfos {
        category
        approverUsers {
          ...companyUser
        }
      }
      timeOff {
        summary {
          key
          definition {
            type
            label
          }
          entitled
          taken
          pending
          remaining
          periodEnd
        }
      }
      contractStarted
      depositPayable @skip(if: $skipDepositPayable) {
        id
        invoice {
          id
          status
          dueDate
          externalSystem
        }
      }
      contractBlocked
      unresolvedBlockEvents {
        id
        source
        status
        blockReason
        blockData {
          ...changeRequestBlockData
        }
      }
      offboarding {
        id
        contractId
        status
        type
        terminationReason
        lastWorkingDay
      }
      paymentAccountRequirements {
        ...paymentAccountRequirementFragment
      }
      reportsToManager {
        id
        contract {
          id
          position
          member {
            firstName
            lastName
          }
        }
      }
      orgAttributes {
        costCenter {
          id
          description
          name
        }
        department {
          id
          description
          name
        }
      }
    }
  }
  ${WorkShiftFragmentDoc}
  ${LegalDocumentFragmentDoc}
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${GrantComponentFragmentDoc}
  ${CompanyBenefitFragmentDoc}
  ${ComplianceFragmentDoc}
  ${CompanyUserFragmentDoc}
  ${ChangeRequestBlockDataFragmentDoc}
  ${PaymentAccountRequirementFragmentFragmentDoc}
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skipDepositPayable: // value for 'skipDepositPayable'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  );
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  );
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<
  typeof useGetContractLazyQuery
>;
export type GetContractQueryResult = Apollo.QueryResult<
  GetContractQuery,
  GetContractQueryVariables
>;
export const GetDeductionDefinitionDocument = gql`
  query GetDeductionDefinition(
    $country: CountryCode!
    $state: String
    $contractType: ContractType
    $contractTerm: ContractTerm
  ) {
    country(country: $country) {
      code
      compliance(countryStateCode: $state) {
        countryCode
        compensationStructureDefinition(
          type: $contractType
          term: $contractTerm
        ) {
          deductions {
            id
            groupKey
            description
            contributionBy
            isMandatory
            deductionRequirement {
              ...deductionRequirement
              __typename
            }
            deductionConstraint {
              ...deductionConstraint
            }
          }
        }
      }
    }
  }
  ${DeductionRequirementFragmentDoc}
  ${DeductionConstraintFragmentDoc}
`;

/**
 * __useGetDeductionDefinitionQuery__
 *
 * To run a query within a React component, call `useGetDeductionDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeductionDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeductionDefinitionQuery({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      contractType: // value for 'contractType'
 *      contractTerm: // value for 'contractTerm'
 *   },
 * });
 */
export function useGetDeductionDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDeductionDefinitionQuery,
    GetDeductionDefinitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeductionDefinitionQuery,
    GetDeductionDefinitionQueryVariables
  >(GetDeductionDefinitionDocument, options);
}
export function useGetDeductionDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeductionDefinitionQuery,
    GetDeductionDefinitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeductionDefinitionQuery,
    GetDeductionDefinitionQueryVariables
  >(GetDeductionDefinitionDocument, options);
}
export type GetDeductionDefinitionQueryHookResult = ReturnType<
  typeof useGetDeductionDefinitionQuery
>;
export type GetDeductionDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetDeductionDefinitionLazyQuery
>;
export type GetDeductionDefinitionQueryResult = Apollo.QueryResult<
  GetDeductionDefinitionQuery,
  GetDeductionDefinitionQueryVariables
>;
export const GetOnboardingHandlerDocument = gql`
  query GetOnboardingHandler($contractId: ID!) {
    contract(id: $contractId) {
      onboarding {
        handler {
          firstName
          lastName
          displayPicture {
            link
          }
        }
      }
    }
  }
`;

/**
 * __useGetOnboardingHandlerQuery__
 *
 * To run a query within a React component, call `useGetOnboardingHandlerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingHandlerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingHandlerQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetOnboardingHandlerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnboardingHandlerQuery,
    GetOnboardingHandlerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnboardingHandlerQuery,
    GetOnboardingHandlerQueryVariables
  >(GetOnboardingHandlerDocument, options);
}
export function useGetOnboardingHandlerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingHandlerQuery,
    GetOnboardingHandlerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnboardingHandlerQuery,
    GetOnboardingHandlerQueryVariables
  >(GetOnboardingHandlerDocument, options);
}
export type GetOnboardingHandlerQueryHookResult = ReturnType<
  typeof useGetOnboardingHandlerQuery
>;
export type GetOnboardingHandlerLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingHandlerLazyQuery
>;
export type GetOnboardingHandlerQueryResult = Apollo.QueryResult<
  GetOnboardingHandlerQuery,
  GetOnboardingHandlerQueryVariables
>;
export const SendEmailToOnboardingSpecialistDocument = gql`
  mutation SendEmailToOnboardingSpecialist(
    $contractId: ID!
    $message: String!
  ) {
    sendEmailToOnboardingSpecialist(
      contractId: $contractId
      message: $message
    ) {
      success
      message
      requesterEmail
    }
  }
`;
export type SendEmailToOnboardingSpecialistMutationFn = Apollo.MutationFunction<
  SendEmailToOnboardingSpecialistMutation,
  SendEmailToOnboardingSpecialistMutationVariables
>;

/**
 * __useSendEmailToOnboardingSpecialistMutation__
 *
 * To run a mutation, you first call `useSendEmailToOnboardingSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToOnboardingSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToOnboardingSpecialistMutation, { data, loading, error }] = useSendEmailToOnboardingSpecialistMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendEmailToOnboardingSpecialistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailToOnboardingSpecialistMutation,
    SendEmailToOnboardingSpecialistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendEmailToOnboardingSpecialistMutation,
    SendEmailToOnboardingSpecialistMutationVariables
  >(SendEmailToOnboardingSpecialistDocument, options);
}
export type SendEmailToOnboardingSpecialistMutationHookResult = ReturnType<
  typeof useSendEmailToOnboardingSpecialistMutation
>;
export type SendEmailToOnboardingSpecialistMutationResult = Apollo.MutationResult<SendEmailToOnboardingSpecialistMutation>;
export type SendEmailToOnboardingSpecialistMutationOptions = Apollo.BaseMutationOptions<
  SendEmailToOnboardingSpecialistMutation,
  SendEmailToOnboardingSpecialistMutationVariables
>;
export const GetCountryDocumentsDocument = gql`
  query GetCountryDocuments(
    $country: CountryCode!
    $filter: CountryDocumentFilter
  ) {
    country(country: $country) {
      code
      documents(filter: $filter) {
        id
        type
        file {
          id
          link
          name
          extension
          contentType
          versionId
          versionedOn
        }
      }
    }
  }
`;

/**
 * __useGetCountryDocumentsQuery__
 *
 * To run a query within a React component, call `useGetCountryDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryDocumentsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCountryDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryDocumentsQuery,
    GetCountryDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryDocumentsQuery,
    GetCountryDocumentsQueryVariables
  >(GetCountryDocumentsDocument, options);
}
export function useGetCountryDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryDocumentsQuery,
    GetCountryDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryDocumentsQuery,
    GetCountryDocumentsQueryVariables
  >(GetCountryDocumentsDocument, options);
}
export type GetCountryDocumentsQueryHookResult = ReturnType<
  typeof useGetCountryDocumentsQuery
>;
export type GetCountryDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetCountryDocumentsLazyQuery
>;
export type GetCountryDocumentsQueryResult = Apollo.QueryResult<
  GetCountryDocumentsQuery,
  GetCountryDocumentsQueryVariables
>;
export const GetCompanyPayInSettingDetailsDocument = gql`
  query GetCompanyPayInSettingDetails($id: ID) {
    company(id: $id) {
      id
      payInSettings {
        payInMethodRequirements {
          id
          payInMethodType
        }
        payInMethods(filters: {}) {
          id
          payInMethodType
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayInSettingDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayInSettingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayInSettingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayInSettingDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPayInSettingDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPayInSettingDetailsQuery,
    GetCompanyPayInSettingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayInSettingDetailsQuery,
    GetCompanyPayInSettingDetailsQueryVariables
  >(GetCompanyPayInSettingDetailsDocument, options);
}
export function useGetCompanyPayInSettingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayInSettingDetailsQuery,
    GetCompanyPayInSettingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayInSettingDetailsQuery,
    GetCompanyPayInSettingDetailsQueryVariables
  >(GetCompanyPayInSettingDetailsDocument, options);
}
export type GetCompanyPayInSettingDetailsQueryHookResult = ReturnType<
  typeof useGetCompanyPayInSettingDetailsQuery
>;
export type GetCompanyPayInSettingDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayInSettingDetailsLazyQuery
>;
export type GetCompanyPayInSettingDetailsQueryResult = Apollo.QueryResult<
  GetCompanyPayInSettingDetailsQuery,
  GetCompanyPayInSettingDetailsQueryVariables
>;
export const GetMsaStatusDocument = gql`
  query GetMSAStatus {
    company {
      id
      status
      channel
      onboarding {
        msaWorkflow {
          currentStatus
        }
      }
    }
  }
`;

/**
 * __useGetMsaStatusQuery__
 *
 * To run a query within a React component, call `useGetMsaStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsaStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsaStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMsaStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMsaStatusQuery,
    GetMsaStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMsaStatusQuery, GetMsaStatusQueryVariables>(
    GetMsaStatusDocument,
    options,
  );
}
export function useGetMsaStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMsaStatusQuery,
    GetMsaStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMsaStatusQuery, GetMsaStatusQueryVariables>(
    GetMsaStatusDocument,
    options,
  );
}
export type GetMsaStatusQueryHookResult = ReturnType<
  typeof useGetMsaStatusQuery
>;
export type GetMsaStatusLazyQueryHookResult = ReturnType<
  typeof useGetMsaStatusLazyQuery
>;
export type GetMsaStatusQueryResult = Apollo.QueryResult<
  GetMsaStatusQuery,
  GetMsaStatusQueryVariables
>;
export const GetActivationCutoffDatesDocument = gql`
  query GetActivationCutoffDates {
    company {
      id
      contracts {
        status
        contractStarted
        onboarding {
          status
          activationCutoff
        }
      }
    }
  }
`;

/**
 * __useGetActivationCutoffDatesQuery__
 *
 * To run a query within a React component, call `useGetActivationCutoffDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivationCutoffDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivationCutoffDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivationCutoffDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivationCutoffDatesQuery,
    GetActivationCutoffDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivationCutoffDatesQuery,
    GetActivationCutoffDatesQueryVariables
  >(GetActivationCutoffDatesDocument, options);
}
export function useGetActivationCutoffDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivationCutoffDatesQuery,
    GetActivationCutoffDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivationCutoffDatesQuery,
    GetActivationCutoffDatesQueryVariables
  >(GetActivationCutoffDatesDocument, options);
}
export type GetActivationCutoffDatesQueryHookResult = ReturnType<
  typeof useGetActivationCutoffDatesQuery
>;
export type GetActivationCutoffDatesLazyQueryHookResult = ReturnType<
  typeof useGetActivationCutoffDatesLazyQuery
>;
export type GetActivationCutoffDatesQueryResult = Apollo.QueryResult<
  GetActivationCutoffDatesQuery,
  GetActivationCutoffDatesQueryVariables
>;
export const GetOnboardingFormRequirementsDocument = gql`
  query GetOnboardingFormRequirements(
    $country: CountryCode!
    $countryStateCode: String
    $workStatus: CountryWorkStatus
    $contractType: ContractType
    $contractTerm: ContractTerm
    $contractStatus: ContractStatus
    $joinDate: Date
    $financialYear: Int
  ) {
    country(country: $country) {
      code
      compliance(countryStateCode: $countryStateCode) {
        countryCode
        countryState {
          code
          name
        }
        memberDocumentRequirements(
          filters: {
            workStatus: $workStatus
            contractType: $contractType
            contractTerm: $contractTerm
            contractStatus: $contractStatus
            joinDate: $joinDate
            financialYear: $financialYear
            category: PAYROLL
          }
        ) {
          key
          label
          description
          acceptMultiple
          template {
            id
            name
            contentType
            link
          }
          deadline
          required
          category
          startDate
          endDate
        }
      }
    }
  }
`;

/**
 * __useGetOnboardingFormRequirementsQuery__
 *
 * To run a query within a React component, call `useGetOnboardingFormRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingFormRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingFormRequirementsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      workStatus: // value for 'workStatus'
 *      contractType: // value for 'contractType'
 *      contractTerm: // value for 'contractTerm'
 *      contractStatus: // value for 'contractStatus'
 *      joinDate: // value for 'joinDate'
 *      financialYear: // value for 'financialYear'
 *   },
 * });
 */
export function useGetOnboardingFormRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnboardingFormRequirementsQuery,
    GetOnboardingFormRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnboardingFormRequirementsQuery,
    GetOnboardingFormRequirementsQueryVariables
  >(GetOnboardingFormRequirementsDocument, options);
}
export function useGetOnboardingFormRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingFormRequirementsQuery,
    GetOnboardingFormRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnboardingFormRequirementsQuery,
    GetOnboardingFormRequirementsQueryVariables
  >(GetOnboardingFormRequirementsDocument, options);
}
export type GetOnboardingFormRequirementsQueryHookResult = ReturnType<
  typeof useGetOnboardingFormRequirementsQuery
>;
export type GetOnboardingFormRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingFormRequirementsLazyQuery
>;
export type GetOnboardingFormRequirementsQueryResult = Apollo.QueryResult<
  GetOnboardingFormRequirementsQuery,
  GetOnboardingFormRequirementsQueryVariables
>;
export const CreateEquipmentProcurementDocument = gql`
  mutation CreateEquipmentProcurement($input: EquipmentProcurementInput!) {
    equipmentProcurementCreate(input: $input) {
      id
      contractId
      companyId
      requestedOn
      detail
      status
      totalAmount
      currencyCode
      items {
        id
        productId
        name
        type
        description
        amount
        currencyCode
      }
    }
  }
`;
export type CreateEquipmentProcurementMutationFn = Apollo.MutationFunction<
  CreateEquipmentProcurementMutation,
  CreateEquipmentProcurementMutationVariables
>;

/**
 * __useCreateEquipmentProcurementMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentProcurementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentProcurementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentProcurementMutation, { data, loading, error }] = useCreateEquipmentProcurementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEquipmentProcurementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEquipmentProcurementMutation,
    CreateEquipmentProcurementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEquipmentProcurementMutation,
    CreateEquipmentProcurementMutationVariables
  >(CreateEquipmentProcurementDocument, options);
}
export type CreateEquipmentProcurementMutationHookResult = ReturnType<
  typeof useCreateEquipmentProcurementMutation
>;
export type CreateEquipmentProcurementMutationResult = Apollo.MutationResult<CreateEquipmentProcurementMutation>;
export type CreateEquipmentProcurementMutationOptions = Apollo.BaseMutationOptions<
  CreateEquipmentProcurementMutation,
  CreateEquipmentProcurementMutationVariables
>;
export const CreateQuotationDocument = gql`
  mutation CreateQuotation($input: EquipmentQuotationInput!) {
    equipmentQuotationCreate(input: $input) {
      success
      message
    }
  }
`;
export type CreateQuotationMutationFn = Apollo.MutationFunction<
  CreateQuotationMutation,
  CreateQuotationMutationVariables
>;

/**
 * __useCreateQuotationMutation__
 *
 * To run a mutation, you first call `useCreateQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuotationMutation, { data, loading, error }] = useCreateQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuotationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuotationMutation,
    CreateQuotationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQuotationMutation,
    CreateQuotationMutationVariables
  >(CreateQuotationDocument, options);
}
export type CreateQuotationMutationHookResult = ReturnType<
  typeof useCreateQuotationMutation
>;
export type CreateQuotationMutationResult = Apollo.MutationResult<CreateQuotationMutation>;
export type CreateQuotationMutationOptions = Apollo.BaseMutationOptions<
  CreateQuotationMutation,
  CreateQuotationMutationVariables
>;
export const GetEquipmentsForCompanyDocument = gql`
  query GetEquipmentsForCompany($companyId: ID!) {
    company(id: $companyId) {
      id
      equipments {
        id
        productId
        serialNumber
        purchasedOn
        type
        name
        description
        status
        documents {
          id
          type
          document {
            id
            name
            extension
            contentType
            link
          }
        }
        dynamicDetails {
          key
          value
        }
      }
    }
  }
`;

/**
 * __useGetEquipmentsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetEquipmentsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentsForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetEquipmentsForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEquipmentsForCompanyQuery,
    GetEquipmentsForCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEquipmentsForCompanyQuery,
    GetEquipmentsForCompanyQueryVariables
  >(GetEquipmentsForCompanyDocument, options);
}
export function useGetEquipmentsForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEquipmentsForCompanyQuery,
    GetEquipmentsForCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEquipmentsForCompanyQuery,
    GetEquipmentsForCompanyQueryVariables
  >(GetEquipmentsForCompanyDocument, options);
}
export type GetEquipmentsForCompanyQueryHookResult = ReturnType<
  typeof useGetEquipmentsForCompanyQuery
>;
export type GetEquipmentsForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetEquipmentsForCompanyLazyQuery
>;
export type GetEquipmentsForCompanyQueryResult = Apollo.QueryResult<
  GetEquipmentsForCompanyQuery,
  GetEquipmentsForCompanyQueryVariables
>;
export const GetEquipmentsForContractDocument = gql`
  query GetEquipmentsForContract($contractId: ID!) {
    contract(id: $contractId) {
      id
      equipments {
        id
        productId
        serialNumber
        purchasedOn
        type
        name
        description
        status
        documents {
          id
          type
          document {
            id
            name
            extension
            contentType
            link
          }
        }
        dynamicDetails {
          key
          value
        }
      }
    }
  }
`;

/**
 * __useGetEquipmentsForContractQuery__
 *
 * To run a query within a React component, call `useGetEquipmentsForContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentsForContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentsForContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetEquipmentsForContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEquipmentsForContractQuery,
    GetEquipmentsForContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEquipmentsForContractQuery,
    GetEquipmentsForContractQueryVariables
  >(GetEquipmentsForContractDocument, options);
}
export function useGetEquipmentsForContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEquipmentsForContractQuery,
    GetEquipmentsForContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEquipmentsForContractQuery,
    GetEquipmentsForContractQueryVariables
  >(GetEquipmentsForContractDocument, options);
}
export type GetEquipmentsForContractQueryHookResult = ReturnType<
  typeof useGetEquipmentsForContractQuery
>;
export type GetEquipmentsForContractLazyQueryHookResult = ReturnType<
  typeof useGetEquipmentsForContractLazyQuery
>;
export type GetEquipmentsForContractQueryResult = Apollo.QueryResult<
  GetEquipmentsForContractQuery,
  GetEquipmentsForContractQueryVariables
>;
export const GetProcurementsForContractDocument = gql`
  query GetProcurementsForContract($contractId: ID!) {
    contract(id: $contractId) {
      id
      equipmentProcurements {
        id
        companyId
        contractId
        totalAmount
        currencyCode
        requestedOn
        completedOn
        status
        detail
        items {
          id
          productId
          name
          type
          description
          amount
          currencyCode
        }
      }
    }
  }
`;

/**
 * __useGetProcurementsForContractQuery__
 *
 * To run a query within a React component, call `useGetProcurementsForContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcurementsForContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcurementsForContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetProcurementsForContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProcurementsForContractQuery,
    GetProcurementsForContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProcurementsForContractQuery,
    GetProcurementsForContractQueryVariables
  >(GetProcurementsForContractDocument, options);
}
export function useGetProcurementsForContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProcurementsForContractQuery,
    GetProcurementsForContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProcurementsForContractQuery,
    GetProcurementsForContractQueryVariables
  >(GetProcurementsForContractDocument, options);
}
export type GetProcurementsForContractQueryHookResult = ReturnType<
  typeof useGetProcurementsForContractQuery
>;
export type GetProcurementsForContractLazyQueryHookResult = ReturnType<
  typeof useGetProcurementsForContractLazyQuery
>;
export type GetProcurementsForContractQueryResult = Apollo.QueryResult<
  GetProcurementsForContractQuery,
  GetProcurementsForContractQueryVariables
>;
export const GetProductFiltersDocument = gql`
  query GetProductFilters($code: CountryCode!, $status: ProductStatus!) {
    country(country: $code) {
      code
      name
      productFilterSpec(status: $status) {
        types
        brands
      }
    }
  }
`;

/**
 * __useGetProductFiltersQuery__
 *
 * To run a query within a React component, call `useGetProductFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductFiltersQuery({
 *   variables: {
 *      code: // value for 'code'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetProductFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductFiltersQuery,
    GetProductFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductFiltersQuery,
    GetProductFiltersQueryVariables
  >(GetProductFiltersDocument, options);
}
export function useGetProductFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductFiltersQuery,
    GetProductFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductFiltersQuery,
    GetProductFiltersQueryVariables
  >(GetProductFiltersDocument, options);
}
export type GetProductFiltersQueryHookResult = ReturnType<
  typeof useGetProductFiltersQuery
>;
export type GetProductFiltersLazyQueryHookResult = ReturnType<
  typeof useGetProductFiltersLazyQuery
>;
export type GetProductFiltersQueryResult = Apollo.QueryResult<
  GetProductFiltersQuery,
  GetProductFiltersQueryVariables
>;
export const GetProductsDocument = gql`
  query GetProducts($code: CountryCode!, $filter: ProductFilter!) {
    country(country: $code) {
      code
      name
      productList(filter: $filter) {
        products {
          id
          name
          type
          brand
          price
          currencyCode
          description
          color {
            code
            name
          }
          dynamicDetails {
            key
            details {
              key
              value
            }
          }
          images {
            url
          }
        }
        filterSpec {
          types
          brands
        }
      }
    }
  }
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      code: // value for 'code'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<
  typeof useGetProductsLazyQuery
>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const ApproveExpenseDocument = gql`
  mutation ApproveExpense($itemId: ID!) {
    approveItem(itemId: $itemId, category: EXPENSE) {
      ... on ExpenseApprovalItem {
        id
        status
        expenseId
        expenseItem {
          id
          contract {
            id
            member {
              id
              firstName
              lastName
            }
            company {
              id
            }
          }
          items {
            id
            date
            category
            amount
            amountInFunctionalCurrency
          }
          status
          title
          currency
          updatedOn
          updatedBy
        }
      }
    }
  }
`;
export type ApproveExpenseMutationFn = Apollo.MutationFunction<
  ApproveExpenseMutation,
  ApproveExpenseMutationVariables
>;

/**
 * __useApproveExpenseMutation__
 *
 * To run a mutation, you first call `useApproveExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveExpenseMutation, { data, loading, error }] = useApproveExpenseMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useApproveExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveExpenseMutation,
    ApproveExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveExpenseMutation,
    ApproveExpenseMutationVariables
  >(ApproveExpenseDocument, options);
}
export type ApproveExpenseMutationHookResult = ReturnType<
  typeof useApproveExpenseMutation
>;
export type ApproveExpenseMutationResult = Apollo.MutationResult<ApproveExpenseMutation>;
export type ApproveExpenseMutationOptions = Apollo.BaseMutationOptions<
  ApproveExpenseMutation,
  ApproveExpenseMutationVariables
>;
export const ForwardExpenseDocument = gql`
  mutation ForwardExpense($itemId: ID!, $forwardUserId: ID!) {
    forwardItem(
      itemId: $itemId
      forwardUserId: $forwardUserId
      category: EXPENSE
    ) {
      ... on ExpenseApprovalItem {
        id
        status
        expenseId
        expenseItem {
          id
          contract {
            id
            member {
              id
              firstName
              lastName
            }
            company {
              id
            }
          }
          approverInfos {
            approverUser {
              id
              userId
              firstName
              lastName
              persona
            }
            status
            updatedOn
          }
          items {
            id
            date
            category
            amount
            amountInFunctionalCurrency
          }
          changes(status: APPROVAL_IN_PROGRESS) {
            status
            actionedBy {
              persona
            }
          }
          status
          submittedAt
          createdOn
          updatedOn
          updatedBy
          title
          currency
          isApprovable
        }
      }
    }
  }
`;
export type ForwardExpenseMutationFn = Apollo.MutationFunction<
  ForwardExpenseMutation,
  ForwardExpenseMutationVariables
>;

/**
 * __useForwardExpenseMutation__
 *
 * To run a mutation, you first call `useForwardExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForwardExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forwardExpenseMutation, { data, loading, error }] = useForwardExpenseMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      forwardUserId: // value for 'forwardUserId'
 *   },
 * });
 */
export function useForwardExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForwardExpenseMutation,
    ForwardExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForwardExpenseMutation,
    ForwardExpenseMutationVariables
  >(ForwardExpenseDocument, options);
}
export type ForwardExpenseMutationHookResult = ReturnType<
  typeof useForwardExpenseMutation
>;
export type ForwardExpenseMutationResult = Apollo.MutationResult<ForwardExpenseMutation>;
export type ForwardExpenseMutationOptions = Apollo.BaseMutationOptions<
  ForwardExpenseMutation,
  ForwardExpenseMutationVariables
>;
export const GetCompanyExpenseDetailsDocument = gql`
  query GetCompanyExpenseDetails($expenseId: ID) {
    company {
      id
      expenses(expenseId: $expenseId) {
        ...expense
        items {
          id
          date
          title
          description
          category
          categoryV2 {
            ...expenseCategory
          }
          amount
          amountInFunctionalCurrency
          amountInForeignCurrency {
            amount
            currency
          }
          receipts {
            id
            name
            contentType
            link
            size
          }
          receiptsInForeignCurrency {
            id
            name
            contentType
            link
            size
          }
        }
        changes(status: APPROVAL_IN_PROGRESS) {
          status
          actionedBy {
            persona
          }
        }
        approverInfos {
          approverUser {
            id
            userId
            firstName
            lastName
            persona
          }
          status
          updatedOn
        }
      }
    }
  }
  ${ExpenseFragmentDoc}
  ${ExpenseCategoryFragmentDoc}
`;

/**
 * __useGetCompanyExpenseDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyExpenseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyExpenseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyExpenseDetailsQuery({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useGetCompanyExpenseDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyExpenseDetailsQuery,
    GetCompanyExpenseDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyExpenseDetailsQuery,
    GetCompanyExpenseDetailsQueryVariables
  >(GetCompanyExpenseDetailsDocument, options);
}
export function useGetCompanyExpenseDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyExpenseDetailsQuery,
    GetCompanyExpenseDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyExpenseDetailsQuery,
    GetCompanyExpenseDetailsQueryVariables
  >(GetCompanyExpenseDetailsDocument, options);
}
export type GetCompanyExpenseDetailsQueryHookResult = ReturnType<
  typeof useGetCompanyExpenseDetailsQuery
>;
export type GetCompanyExpenseDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyExpenseDetailsLazyQuery
>;
export type GetCompanyExpenseDetailsQueryResult = Apollo.QueryResult<
  GetCompanyExpenseDetailsQuery,
  GetCompanyExpenseDetailsQueryVariables
>;
export const GetExpenseApproversDocument = gql`
  query GetExpenseApprovers($itemId: ID!) {
    getAllApprovers(itemId: $itemId, category: EXPENSE) {
      id
      approverUser {
        ... on CompanyUser {
          id
          firstName
          lastName
          status
        }
        ... on Member {
          id
          firstName
          lastName
        }
        ... on OperationsUser {
          id
          firstName
          lastName
        }
        ... on EmergencyPointOfContact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetExpenseApproversQuery__
 *
 * To run a query within a React component, call `useGetExpenseApproversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseApproversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseApproversQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetExpenseApproversQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExpenseApproversQuery,
    GetExpenseApproversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExpenseApproversQuery,
    GetExpenseApproversQueryVariables
  >(GetExpenseApproversDocument, options);
}
export function useGetExpenseApproversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpenseApproversQuery,
    GetExpenseApproversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExpenseApproversQuery,
    GetExpenseApproversQueryVariables
  >(GetExpenseApproversDocument, options);
}
export type GetExpenseApproversQueryHookResult = ReturnType<
  typeof useGetExpenseApproversQuery
>;
export type GetExpenseApproversLazyQueryHookResult = ReturnType<
  typeof useGetExpenseApproversLazyQuery
>;
export type GetExpenseApproversQueryResult = Apollo.QueryResult<
  GetExpenseApproversQuery,
  GetExpenseApproversQueryVariables
>;
export const GetExpenseSummaryDocument = gql`
  query GetExpenseSummary {
    company {
      id
      expenses {
        id
        status
        isApprovable
      }
    }
  }
`;

/**
 * __useGetExpenseSummaryQuery__
 *
 * To run a query within a React component, call `useGetExpenseSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExpenseSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExpenseSummaryQuery,
    GetExpenseSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExpenseSummaryQuery,
    GetExpenseSummaryQueryVariables
  >(GetExpenseSummaryDocument, options);
}
export function useGetExpenseSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpenseSummaryQuery,
    GetExpenseSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExpenseSummaryQuery,
    GetExpenseSummaryQueryVariables
  >(GetExpenseSummaryDocument, options);
}
export type GetExpenseSummaryQueryHookResult = ReturnType<
  typeof useGetExpenseSummaryQuery
>;
export type GetExpenseSummaryLazyQueryHookResult = ReturnType<
  typeof useGetExpenseSummaryLazyQuery
>;
export type GetExpenseSummaryQueryResult = Apollo.QueryResult<
  GetExpenseSummaryQuery,
  GetExpenseSummaryQueryVariables
>;
export const GetExpensesDocument = gql`
  query GetExpenses {
    company {
      id
      expenses {
        ...expense
      }
    }
  }
  ${ExpenseFragmentDoc}
`;

/**
 * __useGetExpensesQuery__
 *
 * To run a query within a React component, call `useGetExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExpensesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExpensesQuery,
    GetExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExpensesQuery, GetExpensesQueryVariables>(
    GetExpensesDocument,
    options,
  );
}
export function useGetExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpensesQuery,
    GetExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExpensesQuery, GetExpensesQueryVariables>(
    GetExpensesDocument,
    options,
  );
}
export type GetExpensesQueryHookResult = ReturnType<typeof useGetExpensesQuery>;
export type GetExpensesLazyQueryHookResult = ReturnType<
  typeof useGetExpensesLazyQuery
>;
export type GetExpensesQueryResult = Apollo.QueryResult<
  GetExpensesQuery,
  GetExpensesQueryVariables
>;
export const RejectExpenseDocument = gql`
  mutation RejectExpense($itemId: ID!, $reason: String) {
    rejectItem(itemId: $itemId, reason: $reason, category: EXPENSE) {
      ... on ExpenseApprovalItem {
        id
        status
        expenseId
        expenseItem {
          ...expense
        }
      }
    }
  }
  ${ExpenseFragmentDoc}
`;
export type RejectExpenseMutationFn = Apollo.MutationFunction<
  RejectExpenseMutation,
  RejectExpenseMutationVariables
>;

/**
 * __useRejectExpenseMutation__
 *
 * To run a mutation, you first call `useRejectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectExpenseMutation, { data, loading, error }] = useRejectExpenseMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectExpenseMutation,
    RejectExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectExpenseMutation,
    RejectExpenseMutationVariables
  >(RejectExpenseDocument, options);
}
export type RejectExpenseMutationHookResult = ReturnType<
  typeof useRejectExpenseMutation
>;
export type RejectExpenseMutationResult = Apollo.MutationResult<RejectExpenseMutation>;
export type RejectExpenseMutationOptions = Apollo.BaseMutationOptions<
  RejectExpenseMutation,
  RejectExpenseMutationVariables
>;
export const GetMemberExpenseDetailsDocument = gql`
  query GetMemberExpenseDetails($id: ID) {
    member {
      id
      contracts {
        id
        expenses(id: $id) {
          ...memberExpense
          items {
            id
            date
            description
            category
            categoryV2 {
              ...expenseCategory
            }
            amount
            amountInFunctionalCurrency
            amountInForeignCurrency {
              amount
              currency
            }
            receipts {
              id
              name
              contentType
              link
              size
            }
            receiptsInForeignCurrency {
              id
              name
              contentType
              link
              size
            }
          }
          changes(latest: true, status: REJECTED) {
            status
            actionedOn
          }
        }
      }
    }
  }
  ${MemberExpenseFragmentDoc}
  ${ExpenseCategoryFragmentDoc}
`;

/**
 * __useGetMemberExpenseDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberExpenseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberExpenseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberExpenseDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberExpenseDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberExpenseDetailsQuery,
    GetMemberExpenseDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberExpenseDetailsQuery,
    GetMemberExpenseDetailsQueryVariables
  >(GetMemberExpenseDetailsDocument, options);
}
export function useGetMemberExpenseDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberExpenseDetailsQuery,
    GetMemberExpenseDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberExpenseDetailsQuery,
    GetMemberExpenseDetailsQueryVariables
  >(GetMemberExpenseDetailsDocument, options);
}
export type GetMemberExpenseDetailsQueryHookResult = ReturnType<
  typeof useGetMemberExpenseDetailsQuery
>;
export type GetMemberExpenseDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberExpenseDetailsLazyQuery
>;
export type GetMemberExpenseDetailsQueryResult = Apollo.QueryResult<
  GetMemberExpenseDetailsQuery,
  GetMemberExpenseDetailsQueryVariables
>;
export const GetMemberExpensesDocument = gql`
  query GetMemberExpenses {
    member {
      id
      contracts {
        id
        status
        expenses {
          ...memberExpense
        }
      }
    }
  }
  ${MemberExpenseFragmentDoc}
`;

/**
 * __useGetMemberExpensesQuery__
 *
 * To run a query within a React component, call `useGetMemberExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberExpensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberExpensesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberExpensesQuery,
    GetMemberExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberExpensesQuery,
    GetMemberExpensesQueryVariables
  >(GetMemberExpensesDocument, options);
}
export function useGetMemberExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberExpensesQuery,
    GetMemberExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberExpensesQuery,
    GetMemberExpensesQueryVariables
  >(GetMemberExpensesDocument, options);
}
export type GetMemberExpensesQueryHookResult = ReturnType<
  typeof useGetMemberExpensesQuery
>;
export type GetMemberExpensesLazyQueryHookResult = ReturnType<
  typeof useGetMemberExpensesLazyQuery
>;
export type GetMemberExpensesQueryResult = Apollo.QueryResult<
  GetMemberExpensesQuery,
  GetMemberExpensesQueryVariables
>;
export const DeleteExpenseDocument = gql`
  mutation DeleteExpense($id: ID!) {
    expenseDelete(id: $id) {
      id
      contract {
        id
        company {
          id
        }
      }
    }
  }
`;
export type DeleteExpenseMutationFn = Apollo.MutationFunction<
  DeleteExpenseMutation,
  DeleteExpenseMutationVariables
>;

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExpenseMutation,
    DeleteExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExpenseMutation,
    DeleteExpenseMutationVariables
  >(DeleteExpenseDocument, options);
}
export type DeleteExpenseMutationHookResult = ReturnType<
  typeof useDeleteExpenseMutation
>;
export type DeleteExpenseMutationResult = Apollo.MutationResult<DeleteExpenseMutation>;
export type DeleteExpenseMutationOptions = Apollo.BaseMutationOptions<
  DeleteExpenseMutation,
  DeleteExpenseMutationVariables
>;
export const GetCountryPayrollInputNotesDocument = gql`
  query GetCountryPayrollInputNotes($country: CountryCode!) {
    country(country: $country) {
      code
      payrollInputNotes {
        countryCode
        domain
        experience
        id
        notes
      }
    }
  }
`;

/**
 * __useGetCountryPayrollInputNotesQuery__
 *
 * To run a query within a React component, call `useGetCountryPayrollInputNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryPayrollInputNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryPayrollInputNotesQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCountryPayrollInputNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryPayrollInputNotesQuery,
    GetCountryPayrollInputNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryPayrollInputNotesQuery,
    GetCountryPayrollInputNotesQueryVariables
  >(GetCountryPayrollInputNotesDocument, options);
}
export function useGetCountryPayrollInputNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryPayrollInputNotesQuery,
    GetCountryPayrollInputNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryPayrollInputNotesQuery,
    GetCountryPayrollInputNotesQueryVariables
  >(GetCountryPayrollInputNotesDocument, options);
}
export type GetCountryPayrollInputNotesQueryHookResult = ReturnType<
  typeof useGetCountryPayrollInputNotesQuery
>;
export type GetCountryPayrollInputNotesLazyQueryHookResult = ReturnType<
  typeof useGetCountryPayrollInputNotesLazyQuery
>;
export type GetCountryPayrollInputNotesQueryResult = Apollo.QueryResult<
  GetCountryPayrollInputNotesQuery,
  GetCountryPayrollInputNotesQueryVariables
>;
export const GetExpenseCategoriesDocument = gql`
  query GetExpenseCategories($filter: ExpenseCategoryFilter) {
    expenseCategories(filter: $filter) {
      ...expenseCategory
    }
  }
  ${ExpenseCategoryFragmentDoc}
`;

/**
 * __useGetExpenseCategoriesQuery__
 *
 * To run a query within a React component, call `useGetExpenseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetExpenseCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExpenseCategoriesQuery,
    GetExpenseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExpenseCategoriesQuery,
    GetExpenseCategoriesQueryVariables
  >(GetExpenseCategoriesDocument, options);
}
export function useGetExpenseCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpenseCategoriesQuery,
    GetExpenseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExpenseCategoriesQuery,
    GetExpenseCategoriesQueryVariables
  >(GetExpenseCategoriesDocument, options);
}
export type GetExpenseCategoriesQueryHookResult = ReturnType<
  typeof useGetExpenseCategoriesQuery
>;
export type GetExpenseCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetExpenseCategoriesLazyQuery
>;
export type GetExpenseCategoriesQueryResult = Apollo.QueryResult<
  GetExpenseCategoriesQuery,
  GetExpenseCategoriesQueryVariables
>;
export const RevokeExpenseDocument = gql`
  mutation RevokeExpense($id: ID!) {
    expenseRevoke(id: $id) {
      ...expense
    }
  }
  ${ExpenseFragmentDoc}
`;
export type RevokeExpenseMutationFn = Apollo.MutationFunction<
  RevokeExpenseMutation,
  RevokeExpenseMutationVariables
>;

/**
 * __useRevokeExpenseMutation__
 *
 * To run a mutation, you first call `useRevokeExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeExpenseMutation, { data, loading, error }] = useRevokeExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeExpenseMutation,
    RevokeExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeExpenseMutation,
    RevokeExpenseMutationVariables
  >(RevokeExpenseDocument, options);
}
export type RevokeExpenseMutationHookResult = ReturnType<
  typeof useRevokeExpenseMutation
>;
export type RevokeExpenseMutationResult = Apollo.MutationResult<RevokeExpenseMutation>;
export type RevokeExpenseMutationOptions = Apollo.BaseMutationOptions<
  RevokeExpenseMutation,
  RevokeExpenseMutationVariables
>;
export const SaveAsDraftExpenseDocument = gql`
  mutation SaveAsDraftExpense($input: ExpenseSaveAsDraftInput!) {
    expenseSaveAsDraft(input: $input) {
      ...expense
    }
  }
  ${ExpenseFragmentDoc}
`;
export type SaveAsDraftExpenseMutationFn = Apollo.MutationFunction<
  SaveAsDraftExpenseMutation,
  SaveAsDraftExpenseMutationVariables
>;

/**
 * __useSaveAsDraftExpenseMutation__
 *
 * To run a mutation, you first call `useSaveAsDraftExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAsDraftExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAsDraftExpenseMutation, { data, loading, error }] = useSaveAsDraftExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAsDraftExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAsDraftExpenseMutation,
    SaveAsDraftExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAsDraftExpenseMutation,
    SaveAsDraftExpenseMutationVariables
  >(SaveAsDraftExpenseDocument, options);
}
export type SaveAsDraftExpenseMutationHookResult = ReturnType<
  typeof useSaveAsDraftExpenseMutation
>;
export type SaveAsDraftExpenseMutationResult = Apollo.MutationResult<SaveAsDraftExpenseMutation>;
export type SaveAsDraftExpenseMutationOptions = Apollo.BaseMutationOptions<
  SaveAsDraftExpenseMutation,
  SaveAsDraftExpenseMutationVariables
>;
export const SubmitExpenseDocument = gql`
  mutation SubmitExpense($input: ExpenseSubmitInput!) {
    expenseSubmit(input: $input) {
      ...expense
    }
  }
  ${ExpenseFragmentDoc}
`;
export type SubmitExpenseMutationFn = Apollo.MutationFunction<
  SubmitExpenseMutation,
  SubmitExpenseMutationVariables
>;

/**
 * __useSubmitExpenseMutation__
 *
 * To run a mutation, you first call `useSubmitExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitExpenseMutation, { data, loading, error }] = useSubmitExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitExpenseMutation,
    SubmitExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitExpenseMutation,
    SubmitExpenseMutationVariables
  >(SubmitExpenseDocument, options);
}
export type SubmitExpenseMutationHookResult = ReturnType<
  typeof useSubmitExpenseMutation
>;
export type SubmitExpenseMutationResult = Apollo.MutationResult<SubmitExpenseMutation>;
export type SubmitExpenseMutationOptions = Apollo.BaseMutationOptions<
  SubmitExpenseMutation,
  SubmitExpenseMutationVariables
>;
export const UpdateExpenseDocument = gql`
  mutation UpdateExpense($input: ExpenseUpdateInput!) {
    expenseUpdate(input: $input) {
      ...expense
    }
  }
  ${ExpenseFragmentDoc}
`;
export type UpdateExpenseMutationFn = Apollo.MutationFunction<
  UpdateExpenseMutation,
  UpdateExpenseMutationVariables
>;

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExpenseMutation,
    UpdateExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExpenseMutation,
    UpdateExpenseMutationVariables
  >(UpdateExpenseDocument, options);
}
export type UpdateExpenseMutationHookResult = ReturnType<
  typeof useUpdateExpenseMutation
>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<
  UpdateExpenseMutation,
  UpdateExpenseMutationVariables
>;
export const ValidateExpenseInfoDocument = gql`
  query ValidateExpenseInfo($input: ExpenseValidationInfoInput!) {
    expenseValidationInfo(input: $input) {
      amountLimit {
        basis
        currency
        limit
        remaining
      }
      dateLimit {
        end
        start
        threshold
        unit
      }
    }
  }
`;

/**
 * __useValidateExpenseInfoQuery__
 *
 * To run a query within a React component, call `useValidateExpenseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateExpenseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateExpenseInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateExpenseInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateExpenseInfoQuery,
    ValidateExpenseInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateExpenseInfoQuery,
    ValidateExpenseInfoQueryVariables
  >(ValidateExpenseInfoDocument, options);
}
export function useValidateExpenseInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateExpenseInfoQuery,
    ValidateExpenseInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateExpenseInfoQuery,
    ValidateExpenseInfoQueryVariables
  >(ValidateExpenseInfoDocument, options);
}
export type ValidateExpenseInfoQueryHookResult = ReturnType<
  typeof useValidateExpenseInfoQuery
>;
export type ValidateExpenseInfoLazyQueryHookResult = ReturnType<
  typeof useValidateExpenseInfoLazyQuery
>;
export type ValidateExpenseInfoQueryResult = Apollo.QueryResult<
  ValidateExpenseInfoQuery,
  ValidateExpenseInfoQueryVariables
>;
export const ContractCreateBenefitChangeRequestsDocument = gql`
  mutation ContractCreateBenefitChangeRequests(
    $input: ContractBenefitChangeRequestInput!
  ) {
    contractCreateBenefitChangeRequests(input: $input) {
      id
      status
    }
  }
`;
export type ContractCreateBenefitChangeRequestsMutationFn = Apollo.MutationFunction<
  ContractCreateBenefitChangeRequestsMutation,
  ContractCreateBenefitChangeRequestsMutationVariables
>;

/**
 * __useContractCreateBenefitChangeRequestsMutation__
 *
 * To run a mutation, you first call `useContractCreateBenefitChangeRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractCreateBenefitChangeRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractCreateBenefitChangeRequestsMutation, { data, loading, error }] = useContractCreateBenefitChangeRequestsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractCreateBenefitChangeRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractCreateBenefitChangeRequestsMutation,
    ContractCreateBenefitChangeRequestsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractCreateBenefitChangeRequestsMutation,
    ContractCreateBenefitChangeRequestsMutationVariables
  >(ContractCreateBenefitChangeRequestsDocument, options);
}
export type ContractCreateBenefitChangeRequestsMutationHookResult = ReturnType<
  typeof useContractCreateBenefitChangeRequestsMutation
>;
export type ContractCreateBenefitChangeRequestsMutationResult = Apollo.MutationResult<ContractCreateBenefitChangeRequestsMutation>;
export type ContractCreateBenefitChangeRequestsMutationOptions = Apollo.BaseMutationOptions<
  ContractCreateBenefitChangeRequestsMutation,
  ContractCreateBenefitChangeRequestsMutationVariables
>;
export const ContractBenefitBannersDocument = gql`
  query ContractBenefitBanners($filters: ContractBenefitBannerFilters) {
    contractBenefitBanners(filters: $filters) {
      id
      contractId
      status
      startDate
      endDate
      type
    }
  }
`;

/**
 * __useContractBenefitBannersQuery__
 *
 * To run a query within a React component, call `useContractBenefitBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractBenefitBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractBenefitBannersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContractBenefitBannersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContractBenefitBannersQuery,
    ContractBenefitBannersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContractBenefitBannersQuery,
    ContractBenefitBannersQueryVariables
  >(ContractBenefitBannersDocument, options);
}
export function useContractBenefitBannersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractBenefitBannersQuery,
    ContractBenefitBannersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContractBenefitBannersQuery,
    ContractBenefitBannersQueryVariables
  >(ContractBenefitBannersDocument, options);
}
export type ContractBenefitBannersQueryHookResult = ReturnType<
  typeof useContractBenefitBannersQuery
>;
export type ContractBenefitBannersLazyQueryHookResult = ReturnType<
  typeof useContractBenefitBannersLazyQuery
>;
export type ContractBenefitBannersQueryResult = Apollo.QueryResult<
  ContractBenefitBannersQuery,
  ContractBenefitBannersQueryVariables
>;
export const GetBenefitDetailsDocument = gql`
  query GetBenefitDetails {
    member {
      id
      firstName
      lastName
      fullLegalName
      gender
      maritalStatus
      dateOfBirth
      age
      contracts(includeAnyStatus: true) {
        id
        term
        type
        country
        countryStateCode
        status
        benefits {
          id
          billAmount
          discountTermPercentage
          employeePayPercentage
          employerPayPercentage
          externalPolicyFileUrl
          externalPolicyId
          startOn
          endOn
          insurancePremium {
            benefitType
            billingDuration
            dependents {
              id
              benefitPackageCost {
                id
                premium
                premiumCurrency
                premiumFrequency
              }
              billingPeriodInMonths
              endOn
              firstName
              id
              lastName
              platformFee
              startOn
              subTotalAmount
              subTotalPlatformFee
            }
            employeePayAmount
            employeePayPercentage
            employerPayAmount
            employerPayPercentage
            self {
              subTotalAmount
              subTotalPlatformFee
              platformFee
              id
              billingPeriodInMonths
              benefitPackageCost {
                premium
                id
                premiumCurrency
                premiumFrequency
              }
            }
            totalPlatformFee
            totalPremium
          }
          dependents {
            id
            dateOfBirth
            startOn
            endOn
            documents {
              key
              files {
                id
                downloadUrl
                name
                size
              }
            }
            benefit {
              id
              provider {
                id
                platformFee
              }
            }
            externalPolicyFileUrl
            externalPolicyId
            firstName
            gender
            lastName
            legalData {
              key
              value
            }
            relationshipToMember
          }
          dependentCount
          status
          benefit {
            id
            cost
            costingType
            benefitDocuments {
              benefitDocumentType
              files {
                id
                downloadUrl
                name
              }
            }
            memberOnboardingKit {
              memberOnboardingKitFile {
                files {
                  id
                  downloadUrl
                }
                benefitDocumentType
              }
            }
            features {
              key
              label
              value
            }
            packageName
            packageTier {
              tier
            }
            provider {
              id
              partnerName
              status
              platformFee
              ageBandApplicable
              platformFeeApplicable
              erSplitApplicable
              billingCurrency
              isInsuranceMandatory
              emergencyPointOfContact {
                firstName
                lastName
                emails {
                  type
                  email
                }
              }
              coverageGroup {
                id
                name
                group {
                  maxCount
                  relation {
                    familyMember
                    id
                  }
                }
              }
              metaData {
                pocName
                emails {
                  type
                  email
                }
                phoneNos {
                  phoneNo
                  type
                }
              }
              brokerData {
                name
              }
              entity {
                legalName
              }
            }
            type
          }
          params {
            key
            value
          }
        }
        startOn
        endOn
        contractStarted
      }
    }
  }
`;

/**
 * __useGetBenefitDetailsQuery__
 *
 * To run a query within a React component, call `useGetBenefitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBenefitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBenefitDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBenefitDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBenefitDetailsQuery,
    GetBenefitDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBenefitDetailsQuery,
    GetBenefitDetailsQueryVariables
  >(GetBenefitDetailsDocument, options);
}
export function useGetBenefitDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBenefitDetailsQuery,
    GetBenefitDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBenefitDetailsQuery,
    GetBenefitDetailsQueryVariables
  >(GetBenefitDetailsDocument, options);
}
export type GetBenefitDetailsQueryHookResult = ReturnType<
  typeof useGetBenefitDetailsQuery
>;
export type GetBenefitDetailsLazyQueryHookResult = ReturnType<
  typeof useGetBenefitDetailsLazyQuery
>;
export type GetBenefitDetailsQueryResult = Apollo.QueryResult<
  GetBenefitDetailsQuery,
  GetBenefitDetailsQueryVariables
>;
export const GetBenefitPartnerCountryDetailsDocument = gql`
  query GetBenefitPartnerCountryDetails(
    $filters: BenefitPartnerCountryFilters
    $pageRequest: PageRequest
  ) {
    benefitPartnerCountriesWithPagination(
      filters: $filters
      pageRequest: $pageRequest
    ) {
      data {
        id
        country
        partnerName
        brokerData {
          name
        }
        platformFee
        insuranceType
        billingDuration
        erSplitApplicable
        ageBandApplicable
        minimumErSplitPercentage
        benefitsDomain
        refundPolicy
        billingCurrency
        platformFeeApplicable
        isInsuranceMandatory
      }
    }
  }
`;

/**
 * __useGetBenefitPartnerCountryDetailsQuery__
 *
 * To run a query within a React component, call `useGetBenefitPartnerCountryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBenefitPartnerCountryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBenefitPartnerCountryDetailsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pageRequest: // value for 'pageRequest'
 *   },
 * });
 */
export function useGetBenefitPartnerCountryDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBenefitPartnerCountryDetailsQuery,
    GetBenefitPartnerCountryDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBenefitPartnerCountryDetailsQuery,
    GetBenefitPartnerCountryDetailsQueryVariables
  >(GetBenefitPartnerCountryDetailsDocument, options);
}
export function useGetBenefitPartnerCountryDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBenefitPartnerCountryDetailsQuery,
    GetBenefitPartnerCountryDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBenefitPartnerCountryDetailsQuery,
    GetBenefitPartnerCountryDetailsQueryVariables
  >(GetBenefitPartnerCountryDetailsDocument, options);
}
export type GetBenefitPartnerCountryDetailsQueryHookResult = ReturnType<
  typeof useGetBenefitPartnerCountryDetailsQuery
>;
export type GetBenefitPartnerCountryDetailsLazyQueryHookResult = ReturnType<
  typeof useGetBenefitPartnerCountryDetailsLazyQuery
>;
export type GetBenefitPartnerCountryDetailsQueryResult = Apollo.QueryResult<
  GetBenefitPartnerCountryDetailsQuery,
  GetBenefitPartnerCountryDetailsQueryVariables
>;
export const ContractBenefitChangeRequestsDocument = gql`
  query ContractBenefitChangeRequests(
    $filters: ContractBenefitChangeRequestFilters
  ) {
    contractBenefitChangeRequests(filters: $filters) {
      changes {
        dependentChanges {
          changes {
            field
            newValue
            oldValue
          }
          contractBenefitDependentId
        }
      }
      contractId
      id
      status
      type
    }
  }
`;

/**
 * __useContractBenefitChangeRequestsQuery__
 *
 * To run a query within a React component, call `useContractBenefitChangeRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractBenefitChangeRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractBenefitChangeRequestsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContractBenefitChangeRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContractBenefitChangeRequestsQuery,
    ContractBenefitChangeRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContractBenefitChangeRequestsQuery,
    ContractBenefitChangeRequestsQueryVariables
  >(ContractBenefitChangeRequestsDocument, options);
}
export function useContractBenefitChangeRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractBenefitChangeRequestsQuery,
    ContractBenefitChangeRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContractBenefitChangeRequestsQuery,
    ContractBenefitChangeRequestsQueryVariables
  >(ContractBenefitChangeRequestsDocument, options);
}
export type ContractBenefitChangeRequestsQueryHookResult = ReturnType<
  typeof useContractBenefitChangeRequestsQuery
>;
export type ContractBenefitChangeRequestsLazyQueryHookResult = ReturnType<
  typeof useContractBenefitChangeRequestsLazyQuery
>;
export type ContractBenefitChangeRequestsQueryResult = Apollo.QueryResult<
  ContractBenefitChangeRequestsQuery,
  ContractBenefitChangeRequestsQueryVariables
>;
export const GetContractBenefitDocumentDocument = gql`
  query GetContractBenefitDocument($contractBenefitDocumentId: ID!) {
    contractBenefitDocument(id: $contractBenefitDocumentId) {
      id
      name
      extension
      contentType
      blob
      extension
      htmlPreview
    }
  }
`;

/**
 * __useGetContractBenefitDocumentQuery__
 *
 * To run a query within a React component, call `useGetContractBenefitDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractBenefitDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractBenefitDocumentQuery({
 *   variables: {
 *      contractBenefitDocumentId: // value for 'contractBenefitDocumentId'
 *   },
 * });
 */
export function useGetContractBenefitDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractBenefitDocumentQuery,
    GetContractBenefitDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractBenefitDocumentQuery,
    GetContractBenefitDocumentQueryVariables
  >(GetContractBenefitDocumentDocument, options);
}
export function useGetContractBenefitDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractBenefitDocumentQuery,
    GetContractBenefitDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractBenefitDocumentQuery,
    GetContractBenefitDocumentQueryVariables
  >(GetContractBenefitDocumentDocument, options);
}
export type GetContractBenefitDocumentQueryHookResult = ReturnType<
  typeof useGetContractBenefitDocumentQuery
>;
export type GetContractBenefitDocumentLazyQueryHookResult = ReturnType<
  typeof useGetContractBenefitDocumentLazyQuery
>;
export type GetContractBenefitDocumentQueryResult = Apollo.QueryResult<
  GetContractBenefitDocumentQuery,
  GetContractBenefitDocumentQueryVariables
>;
export const GetCountryInsuranceDocument = gql`
  query GetCountryInsurance(
    $country: CountryCode
    $countryStateCode: String
    $filters: BenefitFilters!
  ) {
    benefits(
      country: $country
      countryStateCode: $countryStateCode
      filters: $filters
    ) {
      id
      type
      dependentMaxCount
      packageName
      description
      factSheetUrl
      country
      location
      currency
      cost
      costingType
      frequency
      isRecommended
      compareBenefits {
        comparisonFileUrl
      }
      features {
        key
        label
        value
      }
      provider {
        id
        entity {
          legalName
        }
        partner {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetCountryInsuranceQuery__
 *
 * To run a query within a React component, call `useGetCountryInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryInsuranceQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCountryInsuranceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryInsuranceQuery,
    GetCountryInsuranceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryInsuranceQuery,
    GetCountryInsuranceQueryVariables
  >(GetCountryInsuranceDocument, options);
}
export function useGetCountryInsuranceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryInsuranceQuery,
    GetCountryInsuranceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryInsuranceQuery,
    GetCountryInsuranceQueryVariables
  >(GetCountryInsuranceDocument, options);
}
export type GetCountryInsuranceQueryHookResult = ReturnType<
  typeof useGetCountryInsuranceQuery
>;
export type GetCountryInsuranceLazyQueryHookResult = ReturnType<
  typeof useGetCountryInsuranceLazyQuery
>;
export type GetCountryInsuranceQueryResult = Apollo.QueryResult<
  GetCountryInsuranceQuery,
  GetCountryInsuranceQueryVariables
>;
export const GetDependentBannerDetailsDocument = gql`
  query GetDependentBannerDetails {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        benefits {
          id
          dependents {
            id
            dateOfBirth
            firstName
            lastName
            gender
            relationshipToMember
          }
        }
      }
    }
  }
`;

/**
 * __useGetDependentBannerDetailsQuery__
 *
 * To run a query within a React component, call `useGetDependentBannerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependentBannerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependentBannerDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDependentBannerDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDependentBannerDetailsQuery,
    GetDependentBannerDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDependentBannerDetailsQuery,
    GetDependentBannerDetailsQueryVariables
  >(GetDependentBannerDetailsDocument, options);
}
export function useGetDependentBannerDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDependentBannerDetailsQuery,
    GetDependentBannerDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDependentBannerDetailsQuery,
    GetDependentBannerDetailsQueryVariables
  >(GetDependentBannerDetailsDocument, options);
}
export type GetDependentBannerDetailsQueryHookResult = ReturnType<
  typeof useGetDependentBannerDetailsQuery
>;
export type GetDependentBannerDetailsLazyQueryHookResult = ReturnType<
  typeof useGetDependentBannerDetailsLazyQuery
>;
export type GetDependentBannerDetailsQueryResult = Apollo.QueryResult<
  GetDependentBannerDetailsQuery,
  GetDependentBannerDetailsQueryVariables
>;
export const GetSingleBenefitDocument = gql`
  query GetSingleBenefit($id: String!) {
    benefit(id: $id) {
      id
      type
      dependentMaxCount
      packageName
      description
      factSheetUrl
      country
      location
      currency
      cost
      costingType
      frequency
      isRecommended
      compareBenefits {
        comparisonFileUrl
      }
      features {
        key
        label
        value
      }
      provider {
        id
        partner {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetSingleBenefitQuery__
 *
 * To run a query within a React component, call `useGetSingleBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBenefitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleBenefitQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleBenefitQuery,
    GetSingleBenefitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSingleBenefitQuery, GetSingleBenefitQueryVariables>(
    GetSingleBenefitDocument,
    options,
  );
}
export function useGetSingleBenefitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleBenefitQuery,
    GetSingleBenefitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSingleBenefitQuery,
    GetSingleBenefitQueryVariables
  >(GetSingleBenefitDocument, options);
}
export type GetSingleBenefitQueryHookResult = ReturnType<
  typeof useGetSingleBenefitQuery
>;
export type GetSingleBenefitLazyQueryHookResult = ReturnType<
  typeof useGetSingleBenefitLazyQuery
>;
export type GetSingleBenefitQueryResult = Apollo.QueryResult<
  GetSingleBenefitQuery,
  GetSingleBenefitQueryVariables
>;
export const ChangeSyncStateDocument = gql`
  mutation ChangeSyncState(
    $integrationId: ID!
    $syncType: SyncType!
    $on: Boolean!
  ) {
    changeSyncState(
      integrationId: $integrationId
      syncType: $syncType
      on: $on
    ) {
      message
      success
    }
  }
`;
export type ChangeSyncStateMutationFn = Apollo.MutationFunction<
  ChangeSyncStateMutation,
  ChangeSyncStateMutationVariables
>;

/**
 * __useChangeSyncStateMutation__
 *
 * To run a mutation, you first call `useChangeSyncStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSyncStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSyncStateMutation, { data, loading, error }] = useChangeSyncStateMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      syncType: // value for 'syncType'
 *      on: // value for 'on'
 *   },
 * });
 */
export function useChangeSyncStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeSyncStateMutation,
    ChangeSyncStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeSyncStateMutation,
    ChangeSyncStateMutationVariables
  >(ChangeSyncStateDocument, options);
}
export type ChangeSyncStateMutationHookResult = ReturnType<
  typeof useChangeSyncStateMutation
>;
export type ChangeSyncStateMutationResult = Apollo.MutationResult<ChangeSyncStateMutation>;
export type ChangeSyncStateMutationOptions = Apollo.BaseMutationOptions<
  ChangeSyncStateMutation,
  ChangeSyncStateMutationVariables
>;
export const CompanyIntegrationInfoDocument = gql`
  query CompanyIntegrationInfo($integrationId: ID!) {
    companyIntegrationInfo(integrationId: $integrationId) {
      companyId
      incomingSyncEnabled
      lastIncomingSyncId
      incomingSyncInProgress
      lastIncomingSyncStartTime
      lastOutgoingSyncId
      lastOutgoingSyncStartTime
      outgoingSyncEnabled
      outgoingSyncInProgress
      platformID
      providerId
      incomingImportInProgress
    }
  }
`;

/**
 * __useCompanyIntegrationInfoQuery__
 *
 * To run a query within a React component, call `useCompanyIntegrationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyIntegrationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyIntegrationInfoQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useCompanyIntegrationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyIntegrationInfoQuery,
    CompanyIntegrationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyIntegrationInfoQuery,
    CompanyIntegrationInfoQueryVariables
  >(CompanyIntegrationInfoDocument, options);
}
export function useCompanyIntegrationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyIntegrationInfoQuery,
    CompanyIntegrationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyIntegrationInfoQuery,
    CompanyIntegrationInfoQueryVariables
  >(CompanyIntegrationInfoDocument, options);
}
export type CompanyIntegrationInfoQueryHookResult = ReturnType<
  typeof useCompanyIntegrationInfoQuery
>;
export type CompanyIntegrationInfoLazyQueryHookResult = ReturnType<
  typeof useCompanyIntegrationInfoLazyQuery
>;
export type CompanyIntegrationInfoQueryResult = Apollo.QueryResult<
  CompanyIntegrationInfoQuery,
  CompanyIntegrationInfoQueryVariables
>;
export const DisconnectCustomerIntegrationDocument = gql`
  mutation DisconnectCustomerIntegration($id: ID) {
    customerIntegrationDisconnect(id: $id) {
      id
    }
  }
`;
export type DisconnectCustomerIntegrationMutationFn = Apollo.MutationFunction<
  DisconnectCustomerIntegrationMutation,
  DisconnectCustomerIntegrationMutationVariables
>;

/**
 * __useDisconnectCustomerIntegrationMutation__
 *
 * To run a mutation, you first call `useDisconnectCustomerIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectCustomerIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectCustomerIntegrationMutation, { data, loading, error }] = useDisconnectCustomerIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisconnectCustomerIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectCustomerIntegrationMutation,
    DisconnectCustomerIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectCustomerIntegrationMutation,
    DisconnectCustomerIntegrationMutationVariables
  >(DisconnectCustomerIntegrationDocument, options);
}
export type DisconnectCustomerIntegrationMutationHookResult = ReturnType<
  typeof useDisconnectCustomerIntegrationMutation
>;
export type DisconnectCustomerIntegrationMutationResult = Apollo.MutationResult<DisconnectCustomerIntegrationMutation>;
export type DisconnectCustomerIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DisconnectCustomerIntegrationMutation,
  DisconnectCustomerIntegrationMutationVariables
>;
export const DismissSyncResultDocument = gql`
  mutation DismissSyncResult($syncId: String!) {
    dismissSyncResult(syncId: $syncId) {
      message
      success
    }
  }
`;
export type DismissSyncResultMutationFn = Apollo.MutationFunction<
  DismissSyncResultMutation,
  DismissSyncResultMutationVariables
>;

/**
 * __useDismissSyncResultMutation__
 *
 * To run a mutation, you first call `useDismissSyncResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissSyncResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissSyncResultMutation, { data, loading, error }] = useDismissSyncResultMutation({
 *   variables: {
 *      syncId: // value for 'syncId'
 *   },
 * });
 */
export function useDismissSyncResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissSyncResultMutation,
    DismissSyncResultMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissSyncResultMutation,
    DismissSyncResultMutationVariables
  >(DismissSyncResultDocument, options);
}
export type DismissSyncResultMutationHookResult = ReturnType<
  typeof useDismissSyncResultMutation
>;
export type DismissSyncResultMutationResult = Apollo.MutationResult<DismissSyncResultMutation>;
export type DismissSyncResultMutationOptions = Apollo.BaseMutationOptions<
  DismissSyncResultMutation,
  DismissSyncResultMutationVariables
>;
export const GetCompanyCustomerIntegrationsDocument = gql`
  query GetCompanyCustomerIntegrations {
    company {
      id
      customerIntegrations {
        id
        name
        category
        connected
        isPositionDropdownListEnabled
      }
    }
  }
`;

/**
 * __useGetCompanyCustomerIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetCompanyCustomerIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCustomerIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCustomerIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCustomerIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyCustomerIntegrationsQuery,
    GetCompanyCustomerIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyCustomerIntegrationsQuery,
    GetCompanyCustomerIntegrationsQueryVariables
  >(GetCompanyCustomerIntegrationsDocument, options);
}
export function useGetCompanyCustomerIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCustomerIntegrationsQuery,
    GetCompanyCustomerIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyCustomerIntegrationsQuery,
    GetCompanyCustomerIntegrationsQueryVariables
  >(GetCompanyCustomerIntegrationsDocument, options);
}
export type GetCompanyCustomerIntegrationsQueryHookResult = ReturnType<
  typeof useGetCompanyCustomerIntegrationsQuery
>;
export type GetCompanyCustomerIntegrationsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCustomerIntegrationsLazyQuery
>;
export type GetCompanyCustomerIntegrationsQueryResult = Apollo.QueryResult<
  GetCompanyCustomerIntegrationsQuery,
  GetCompanyCustomerIntegrationsQueryVariables
>;
export const GetCompanySsoPreferenceDocument = gql`
  query GetCompanySSOPreference {
    company {
      id
      ssoPreference {
        id
        identityProvider
        enabled
      }
    }
  }
`;

/**
 * __useGetCompanySsoPreferenceQuery__
 *
 * To run a query within a React component, call `useGetCompanySsoPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySsoPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySsoPreferenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanySsoPreferenceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanySsoPreferenceQuery,
    GetCompanySsoPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanySsoPreferenceQuery,
    GetCompanySsoPreferenceQueryVariables
  >(GetCompanySsoPreferenceDocument, options);
}
export function useGetCompanySsoPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanySsoPreferenceQuery,
    GetCompanySsoPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanySsoPreferenceQuery,
    GetCompanySsoPreferenceQueryVariables
  >(GetCompanySsoPreferenceDocument, options);
}
export type GetCompanySsoPreferenceQueryHookResult = ReturnType<
  typeof useGetCompanySsoPreferenceQuery
>;
export type GetCompanySsoPreferenceLazyQueryHookResult = ReturnType<
  typeof useGetCompanySsoPreferenceLazyQuery
>;
export type GetCompanySsoPreferenceQueryResult = Apollo.QueryResult<
  GetCompanySsoPreferenceQuery,
  GetCompanySsoPreferenceQueryVariables
>;
export const GetEmployeesToSendInviteToDocument = gql`
  query GetEmployeesToSendInviteTo($integrationId: ID!) {
    getEmployeesToSendInviteTo(integrationId: $integrationId) {
      externalId
      firstName
      lastName
    }
  }
`;

/**
 * __useGetEmployeesToSendInviteToQuery__
 *
 * To run a query within a React component, call `useGetEmployeesToSendInviteToQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesToSendInviteToQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesToSendInviteToQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetEmployeesToSendInviteToQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeesToSendInviteToQuery,
    GetEmployeesToSendInviteToQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeesToSendInviteToQuery,
    GetEmployeesToSendInviteToQueryVariables
  >(GetEmployeesToSendInviteToDocument, options);
}
export function useGetEmployeesToSendInviteToLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeesToSendInviteToQuery,
    GetEmployeesToSendInviteToQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeesToSendInviteToQuery,
    GetEmployeesToSendInviteToQueryVariables
  >(GetEmployeesToSendInviteToDocument, options);
}
export type GetEmployeesToSendInviteToQueryHookResult = ReturnType<
  typeof useGetEmployeesToSendInviteToQuery
>;
export type GetEmployeesToSendInviteToLazyQueryHookResult = ReturnType<
  typeof useGetEmployeesToSendInviteToLazyQuery
>;
export type GetEmployeesToSendInviteToQueryResult = Apollo.QueryResult<
  GetEmployeesToSendInviteToQuery,
  GetEmployeesToSendInviteToQueryVariables
>;
export const GetIntegrationEntityFieldMappingDocument = gql`
  query GetIntegrationEntityFieldMapping($integrationId: ID!, $entityId: ID!) {
    getIntegrationFieldsMapping(
      integrationId: $integrationId
      entityId: $entityId
    ) {
      integrationId
      entityMappingStatus
      legalEntity {
        id
        legalName
        address {
          country
        }
      }
      fieldsMapping {
        originField
        originFieldLabel
        mappedField
        mappedFieldLabel
        isRequired
        isActive
        isCalculated
        type
        children {
          originField
          originFieldLabel
          mappedField
          mappedFieldLabel
          isRequired
          isActive
        }
      }
      thirdPartyFields {
        key
        label
        type
        isMappedByThirdParty
        fieldId
        fieldFromApp
        children {
          key
          label
          type
          isMappedByThirdParty
        }
      }
    }
  }
`;

/**
 * __useGetIntegrationEntityFieldMappingQuery__
 *
 * To run a query within a React component, call `useGetIntegrationEntityFieldMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationEntityFieldMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationEntityFieldMappingQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetIntegrationEntityFieldMappingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationEntityFieldMappingQuery,
    GetIntegrationEntityFieldMappingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIntegrationEntityFieldMappingQuery,
    GetIntegrationEntityFieldMappingQueryVariables
  >(GetIntegrationEntityFieldMappingDocument, options);
}
export function useGetIntegrationEntityFieldMappingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationEntityFieldMappingQuery,
    GetIntegrationEntityFieldMappingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIntegrationEntityFieldMappingQuery,
    GetIntegrationEntityFieldMappingQueryVariables
  >(GetIntegrationEntityFieldMappingDocument, options);
}
export type GetIntegrationEntityFieldMappingQueryHookResult = ReturnType<
  typeof useGetIntegrationEntityFieldMappingQuery
>;
export type GetIntegrationEntityFieldMappingLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationEntityFieldMappingLazyQuery
>;
export type GetIntegrationEntityFieldMappingQueryResult = Apollo.QueryResult<
  GetIntegrationEntityFieldMappingQuery,
  GetIntegrationEntityFieldMappingQueryVariables
>;
export const GetIntegrationEntityMappingDocument = gql`
  query GetIntegrationEntityMapping($integrationId: ID!) {
    getIntegrationEntityMappingStatus(integrationId: $integrationId) {
      entityMappingId
      legalEntity {
        id
        legalName
        status
        address {
          country
        }
      }
      integrationId
      entityMappingStatus
      isEnabled
    }
  }
`;

/**
 * __useGetIntegrationEntityMappingQuery__
 *
 * To run a query within a React component, call `useGetIntegrationEntityMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationEntityMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationEntityMappingQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetIntegrationEntityMappingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationEntityMappingQuery,
    GetIntegrationEntityMappingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIntegrationEntityMappingQuery,
    GetIntegrationEntityMappingQueryVariables
  >(GetIntegrationEntityMappingDocument, options);
}
export function useGetIntegrationEntityMappingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationEntityMappingQuery,
    GetIntegrationEntityMappingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIntegrationEntityMappingQuery,
    GetIntegrationEntityMappingQueryVariables
  >(GetIntegrationEntityMappingDocument, options);
}
export type GetIntegrationEntityMappingQueryHookResult = ReturnType<
  typeof useGetIntegrationEntityMappingQuery
>;
export type GetIntegrationEntityMappingLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationEntityMappingLazyQuery
>;
export type GetIntegrationEntityMappingQueryResult = Apollo.QueryResult<
  GetIntegrationEntityMappingQuery,
  GetIntegrationEntityMappingQueryVariables
>;
export const GetLatestEmployeesForImportDocument = gql`
  query GetLatestEmployeesForImport($integrationId: ID!) {
    getLatestEmployeesForImport(integrationId: $integrationId) {
      employees {
        externalId
        firstName
        lastName
      }
      syncId
    }
  }
`;

/**
 * __useGetLatestEmployeesForImportQuery__
 *
 * To run a query within a React component, call `useGetLatestEmployeesForImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestEmployeesForImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestEmployeesForImportQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetLatestEmployeesForImportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestEmployeesForImportQuery,
    GetLatestEmployeesForImportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestEmployeesForImportQuery,
    GetLatestEmployeesForImportQueryVariables
  >(GetLatestEmployeesForImportDocument, options);
}
export function useGetLatestEmployeesForImportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestEmployeesForImportQuery,
    GetLatestEmployeesForImportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestEmployeesForImportQuery,
    GetLatestEmployeesForImportQueryVariables
  >(GetLatestEmployeesForImportDocument, options);
}
export type GetLatestEmployeesForImportQueryHookResult = ReturnType<
  typeof useGetLatestEmployeesForImportQuery
>;
export type GetLatestEmployeesForImportLazyQueryHookResult = ReturnType<
  typeof useGetLatestEmployeesForImportLazyQuery
>;
export type GetLatestEmployeesForImportQueryResult = Apollo.QueryResult<
  GetLatestEmployeesForImportQuery,
  GetLatestEmployeesForImportQueryVariables
>;
export const GetLatestSyncResultForIntegrationDocument = gql`
  query GetLatestSyncResultForIntegration($integrationId: ID!) {
    getLatestSyncResultForIntegration(integrationId: $integrationId) {
      addedContracts
      completedAt
      createdAt
      failedContracts
      integrationId
      status
      syncId
      updatedAt
      updatedContracts
      dismissedAt
    }
  }
`;

/**
 * __useGetLatestSyncResultForIntegrationQuery__
 *
 * To run a query within a React component, call `useGetLatestSyncResultForIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestSyncResultForIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestSyncResultForIntegrationQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetLatestSyncResultForIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestSyncResultForIntegrationQuery,
    GetLatestSyncResultForIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestSyncResultForIntegrationQuery,
    GetLatestSyncResultForIntegrationQueryVariables
  >(GetLatestSyncResultForIntegrationDocument, options);
}
export function useGetLatestSyncResultForIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestSyncResultForIntegrationQuery,
    GetLatestSyncResultForIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestSyncResultForIntegrationQuery,
    GetLatestSyncResultForIntegrationQueryVariables
  >(GetLatestSyncResultForIntegrationDocument, options);
}
export type GetLatestSyncResultForIntegrationQueryHookResult = ReturnType<
  typeof useGetLatestSyncResultForIntegrationQuery
>;
export type GetLatestSyncResultForIntegrationLazyQueryHookResult = ReturnType<
  typeof useGetLatestSyncResultForIntegrationLazyQuery
>;
export type GetLatestSyncResultForIntegrationQueryResult = Apollo.QueryResult<
  GetLatestSyncResultForIntegrationQuery,
  GetLatestSyncResultForIntegrationQueryVariables
>;
export const ImportEmployeesDocument = gql`
  mutation ImportEmployees($employees: [String]!, $syncId: String!) {
    importEmployees(employees: $employees, syncId: $syncId) {
      message
      success
    }
  }
`;
export type ImportEmployeesMutationFn = Apollo.MutationFunction<
  ImportEmployeesMutation,
  ImportEmployeesMutationVariables
>;

/**
 * __useImportEmployeesMutation__
 *
 * To run a mutation, you first call `useImportEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEmployeesMutation, { data, loading, error }] = useImportEmployeesMutation({
 *   variables: {
 *      employees: // value for 'employees'
 *      syncId: // value for 'syncId'
 *   },
 * });
 */
export function useImportEmployeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportEmployeesMutation,
    ImportEmployeesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportEmployeesMutation,
    ImportEmployeesMutationVariables
  >(ImportEmployeesDocument, options);
}
export type ImportEmployeesMutationHookResult = ReturnType<
  typeof useImportEmployeesMutation
>;
export type ImportEmployeesMutationResult = Apollo.MutationResult<ImportEmployeesMutation>;
export type ImportEmployeesMutationOptions = Apollo.BaseMutationOptions<
  ImportEmployeesMutation,
  ImportEmployeesMutationVariables
>;
export const ListPlatformsDocument = gql`
  query ListPlatforms {
    customerIntegrationDefinitions {
      id
      name
      color
      category
      logoLink
      squareLogoLink
    }
  }
`;

/**
 * __useListPlatformsQuery__
 *
 * To run a query within a React component, call `useListPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlatformsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPlatformsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListPlatformsQuery,
    ListPlatformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlatformsQuery, ListPlatformsQueryVariables>(
    ListPlatformsDocument,
    options,
  );
}
export function useListPlatformsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPlatformsQuery,
    ListPlatformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlatformsQuery, ListPlatformsQueryVariables>(
    ListPlatformsDocument,
    options,
  );
}
export type ListPlatformsQueryHookResult = ReturnType<
  typeof useListPlatformsQuery
>;
export type ListPlatformsLazyQueryHookResult = ReturnType<
  typeof useListPlatformsLazyQuery
>;
export type ListPlatformsQueryResult = Apollo.QueryResult<
  ListPlatformsQuery,
  ListPlatformsQueryVariables
>;
export const PlatformCompanyTokenUpdateDocument = gql`
  mutation PlatformCompanyTokenUpdate(
    $companyId: ID!
    $platformId: ID!
    $token: String!
  ) {
    platformCompanyTokenUpdate(
      companyId: $companyId
      platformId: $platformId
      token: $token
    ) {
      message
      success
    }
  }
`;
export type PlatformCompanyTokenUpdateMutationFn = Apollo.MutationFunction<
  PlatformCompanyTokenUpdateMutation,
  PlatformCompanyTokenUpdateMutationVariables
>;

/**
 * __usePlatformCompanyTokenUpdateMutation__
 *
 * To run a mutation, you first call `usePlatformCompanyTokenUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlatformCompanyTokenUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [platformCompanyTokenUpdateMutation, { data, loading, error }] = usePlatformCompanyTokenUpdateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      platformId: // value for 'platformId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePlatformCompanyTokenUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlatformCompanyTokenUpdateMutation,
    PlatformCompanyTokenUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlatformCompanyTokenUpdateMutation,
    PlatformCompanyTokenUpdateMutationVariables
  >(PlatformCompanyTokenUpdateDocument, options);
}
export type PlatformCompanyTokenUpdateMutationHookResult = ReturnType<
  typeof usePlatformCompanyTokenUpdateMutation
>;
export type PlatformCompanyTokenUpdateMutationResult = Apollo.MutationResult<PlatformCompanyTokenUpdateMutation>;
export type PlatformCompanyTokenUpdateMutationOptions = Apollo.BaseMutationOptions<
  PlatformCompanyTokenUpdateMutation,
  PlatformCompanyTokenUpdateMutationVariables
>;
export const ProviderKnitAuthTokenDocument = gql`
  mutation ProviderKnitAuthToken(
    $originOrgId: String!
    $originOrgName: String!
    $originUserEmail: String!
    $originUserName: String!
    $platformId: String!
  ) {
    providerKnitAuthToken(
      originOrgId: $originOrgId
      originOrgName: $originOrgName
      originUserEmail: $originUserEmail
      originUserName: $originUserName
      platformId: $platformId
    ) {
      error {
        msg
      }
      msg {
        token
      }
      success
    }
  }
`;
export type ProviderKnitAuthTokenMutationFn = Apollo.MutationFunction<
  ProviderKnitAuthTokenMutation,
  ProviderKnitAuthTokenMutationVariables
>;

/**
 * __useProviderKnitAuthTokenMutation__
 *
 * To run a mutation, you first call `useProviderKnitAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderKnitAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerKnitAuthTokenMutation, { data, loading, error }] = useProviderKnitAuthTokenMutation({
 *   variables: {
 *      originOrgId: // value for 'originOrgId'
 *      originOrgName: // value for 'originOrgName'
 *      originUserEmail: // value for 'originUserEmail'
 *      originUserName: // value for 'originUserName'
 *      platformId: // value for 'platformId'
 *   },
 * });
 */
export function useProviderKnitAuthTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProviderKnitAuthTokenMutation,
    ProviderKnitAuthTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProviderKnitAuthTokenMutation,
    ProviderKnitAuthTokenMutationVariables
  >(ProviderKnitAuthTokenDocument, options);
}
export type ProviderKnitAuthTokenMutationHookResult = ReturnType<
  typeof useProviderKnitAuthTokenMutation
>;
export type ProviderKnitAuthTokenMutationResult = Apollo.MutationResult<ProviderKnitAuthTokenMutation>;
export type ProviderKnitAuthTokenMutationOptions = Apollo.BaseMutationOptions<
  ProviderKnitAuthTokenMutation,
  ProviderKnitAuthTokenMutationVariables
>;
export const SaveIntegrationEntityMappingStatusDocument = gql`
  mutation SaveIntegrationEntityMappingStatus(
    $entityMappingId: ID!
    $enableDataSync: Boolean
  ) {
    saveIntegrationEntityMappingStatus(
      entityMappingId: $entityMappingId
      enableDataSync: $enableDataSync
    ) {
      success
      message
    }
  }
`;
export type SaveIntegrationEntityMappingStatusMutationFn = Apollo.MutationFunction<
  SaveIntegrationEntityMappingStatusMutation,
  SaveIntegrationEntityMappingStatusMutationVariables
>;

/**
 * __useSaveIntegrationEntityMappingStatusMutation__
 *
 * To run a mutation, you first call `useSaveIntegrationEntityMappingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveIntegrationEntityMappingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveIntegrationEntityMappingStatusMutation, { data, loading, error }] = useSaveIntegrationEntityMappingStatusMutation({
 *   variables: {
 *      entityMappingId: // value for 'entityMappingId'
 *      enableDataSync: // value for 'enableDataSync'
 *   },
 * });
 */
export function useSaveIntegrationEntityMappingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveIntegrationEntityMappingStatusMutation,
    SaveIntegrationEntityMappingStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveIntegrationEntityMappingStatusMutation,
    SaveIntegrationEntityMappingStatusMutationVariables
  >(SaveIntegrationEntityMappingStatusDocument, options);
}
export type SaveIntegrationEntityMappingStatusMutationHookResult = ReturnType<
  typeof useSaveIntegrationEntityMappingStatusMutation
>;
export type SaveIntegrationEntityMappingStatusMutationResult = Apollo.MutationResult<SaveIntegrationEntityMappingStatusMutation>;
export type SaveIntegrationEntityMappingStatusMutationOptions = Apollo.BaseMutationOptions<
  SaveIntegrationEntityMappingStatusMutation,
  SaveIntegrationEntityMappingStatusMutationVariables
>;
export const SaveIntegrationFieldMappingDocument = gql`
  mutation SaveIntegrationFieldMapping(
    $input: SaveIntegrationFieldsMappingInput
  ) {
    saveIntegrationFieldsMapping(input: $input) {
      success
      message
    }
  }
`;
export type SaveIntegrationFieldMappingMutationFn = Apollo.MutationFunction<
  SaveIntegrationFieldMappingMutation,
  SaveIntegrationFieldMappingMutationVariables
>;

/**
 * __useSaveIntegrationFieldMappingMutation__
 *
 * To run a mutation, you first call `useSaveIntegrationFieldMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveIntegrationFieldMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveIntegrationFieldMappingMutation, { data, loading, error }] = useSaveIntegrationFieldMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveIntegrationFieldMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveIntegrationFieldMappingMutation,
    SaveIntegrationFieldMappingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveIntegrationFieldMappingMutation,
    SaveIntegrationFieldMappingMutationVariables
  >(SaveIntegrationFieldMappingDocument, options);
}
export type SaveIntegrationFieldMappingMutationHookResult = ReturnType<
  typeof useSaveIntegrationFieldMappingMutation
>;
export type SaveIntegrationFieldMappingMutationResult = Apollo.MutationResult<SaveIntegrationFieldMappingMutation>;
export type SaveIntegrationFieldMappingMutationOptions = Apollo.BaseMutationOptions<
  SaveIntegrationFieldMappingMutation,
  SaveIntegrationFieldMappingMutationVariables
>;
export const SendImportedMemberInvitesDocument = gql`
  mutation SendImportedMemberInvites($employees: [String]!, $syncId: String) {
    sendImportedMemberInvites(employees: $employees, syncId: $syncId) {
      message
      success
    }
  }
`;
export type SendImportedMemberInvitesMutationFn = Apollo.MutationFunction<
  SendImportedMemberInvitesMutation,
  SendImportedMemberInvitesMutationVariables
>;

/**
 * __useSendImportedMemberInvitesMutation__
 *
 * To run a mutation, you first call `useSendImportedMemberInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendImportedMemberInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendImportedMemberInvitesMutation, { data, loading, error }] = useSendImportedMemberInvitesMutation({
 *   variables: {
 *      employees: // value for 'employees'
 *      syncId: // value for 'syncId'
 *   },
 * });
 */
export function useSendImportedMemberInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendImportedMemberInvitesMutation,
    SendImportedMemberInvitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendImportedMemberInvitesMutation,
    SendImportedMemberInvitesMutationVariables
  >(SendImportedMemberInvitesDocument, options);
}
export type SendImportedMemberInvitesMutationHookResult = ReturnType<
  typeof useSendImportedMemberInvitesMutation
>;
export type SendImportedMemberInvitesMutationResult = Apollo.MutationResult<SendImportedMemberInvitesMutation>;
export type SendImportedMemberInvitesMutationOptions = Apollo.BaseMutationOptions<
  SendImportedMemberInvitesMutation,
  SendImportedMemberInvitesMutationVariables
>;
export const StartIncomingSyncDocument = gql`
  mutation StartIncomingSync($integrationId: ID!) {
    startIncomingSync(integrationId: $integrationId) {
      message
      success
    }
  }
`;
export type StartIncomingSyncMutationFn = Apollo.MutationFunction<
  StartIncomingSyncMutation,
  StartIncomingSyncMutationVariables
>;

/**
 * __useStartIncomingSyncMutation__
 *
 * To run a mutation, you first call `useStartIncomingSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartIncomingSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startIncomingSyncMutation, { data, loading, error }] = useStartIncomingSyncMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useStartIncomingSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartIncomingSyncMutation,
    StartIncomingSyncMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartIncomingSyncMutation,
    StartIncomingSyncMutationVariables
  >(StartIncomingSyncDocument, options);
}
export type StartIncomingSyncMutationHookResult = ReturnType<
  typeof useStartIncomingSyncMutation
>;
export type StartIncomingSyncMutationResult = Apollo.MutationResult<StartIncomingSyncMutation>;
export type StartIncomingSyncMutationOptions = Apollo.BaseMutationOptions<
  StartIncomingSyncMutation,
  StartIncomingSyncMutationVariables
>;
export const SyncEorManuallyDocument = gql`
  mutation SyncEORManually($integrationId: ID!) {
    syncEORManually(integrationId: $integrationId) {
      integrationId
      success
      message
      syncId
    }
  }
`;
export type SyncEorManuallyMutationFn = Apollo.MutationFunction<
  SyncEorManuallyMutation,
  SyncEorManuallyMutationVariables
>;

/**
 * __useSyncEorManuallyMutation__
 *
 * To run a mutation, you first call `useSyncEorManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncEorManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncEorManuallyMutation, { data, loading, error }] = useSyncEorManuallyMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useSyncEorManuallyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncEorManuallyMutation,
    SyncEorManuallyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncEorManuallyMutation,
    SyncEorManuallyMutationVariables
  >(SyncEorManuallyDocument, options);
}
export type SyncEorManuallyMutationHookResult = ReturnType<
  typeof useSyncEorManuallyMutation
>;
export type SyncEorManuallyMutationResult = Apollo.MutationResult<SyncEorManuallyMutation>;
export type SyncEorManuallyMutationOptions = Apollo.BaseMutationOptions<
  SyncEorManuallyMutation,
  SyncEorManuallyMutationVariables
>;
export const SyncStatusDocument = gql`
  query SyncStatus($integrationId: ID!, $syncType: SyncType) {
    syncStatus(integrationId: $integrationId, syncType: $syncType) {
      companyId
      createdAt
      status
      updatedAt
    }
  }
`;

/**
 * __useSyncStatusQuery__
 *
 * To run a query within a React component, call `useSyncStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncStatusQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      syncType: // value for 'syncType'
 *   },
 * });
 */
export function useSyncStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyncStatusQuery,
    SyncStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SyncStatusQuery, SyncStatusQueryVariables>(
    SyncStatusDocument,
    options,
  );
}
export function useSyncStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyncStatusQuery,
    SyncStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SyncStatusQuery, SyncStatusQueryVariables>(
    SyncStatusDocument,
    options,
  );
}
export type SyncStatusQueryHookResult = ReturnType<typeof useSyncStatusQuery>;
export type SyncStatusLazyQueryHookResult = ReturnType<
  typeof useSyncStatusLazyQuery
>;
export type SyncStatusQueryResult = Apollo.QueryResult<
  SyncStatusQuery,
  SyncStatusQueryVariables
>;
export const SyncSummaryResultDownloadDocument = gql`
  query SyncSummaryResultDownload($input: SyncSummaryResultDownloadInput!) {
    syncSummaryResultDownload(input: $input) {
      isDownloaded
      reportFile {
        id
        name
        extension
        contentType
        blob
        htmlPreview
      }
    }
  }
`;

/**
 * __useSyncSummaryResultDownloadQuery__
 *
 * To run a query within a React component, call `useSyncSummaryResultDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncSummaryResultDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncSummaryResultDownloadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncSummaryResultDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyncSummaryResultDownloadQuery,
    SyncSummaryResultDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyncSummaryResultDownloadQuery,
    SyncSummaryResultDownloadQueryVariables
  >(SyncSummaryResultDownloadDocument, options);
}
export function useSyncSummaryResultDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyncSummaryResultDownloadQuery,
    SyncSummaryResultDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyncSummaryResultDownloadQuery,
    SyncSummaryResultDownloadQueryVariables
  >(SyncSummaryResultDownloadDocument, options);
}
export type SyncSummaryResultDownloadQueryHookResult = ReturnType<
  typeof useSyncSummaryResultDownloadQuery
>;
export type SyncSummaryResultDownloadLazyQueryHookResult = ReturnType<
  typeof useSyncSummaryResultDownloadLazyQuery
>;
export type SyncSummaryResultDownloadQueryResult = Apollo.QueryResult<
  SyncSummaryResultDownloadQuery,
  SyncSummaryResultDownloadQueryVariables
>;
export const UpsertSsoPreferenceForCompanyDocument = gql`
  mutation UpsertSSOPreferenceForCompany(
    $companyId: ID!
    $input: UpsertSSOPreferenceInput!
  ) {
    upsertSSOPreferenceForCompany(companyId: $companyId, input: $input) {
      id
      enabled
      identityProvider
    }
  }
`;
export type UpsertSsoPreferenceForCompanyMutationFn = Apollo.MutationFunction<
  UpsertSsoPreferenceForCompanyMutation,
  UpsertSsoPreferenceForCompanyMutationVariables
>;

/**
 * __useUpsertSsoPreferenceForCompanyMutation__
 *
 * To run a mutation, you first call `useUpsertSsoPreferenceForCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSsoPreferenceForCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSsoPreferenceForCompanyMutation, { data, loading, error }] = useUpsertSsoPreferenceForCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSsoPreferenceForCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertSsoPreferenceForCompanyMutation,
    UpsertSsoPreferenceForCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertSsoPreferenceForCompanyMutation,
    UpsertSsoPreferenceForCompanyMutationVariables
  >(UpsertSsoPreferenceForCompanyDocument, options);
}
export type UpsertSsoPreferenceForCompanyMutationHookResult = ReturnType<
  typeof useUpsertSsoPreferenceForCompanyMutation
>;
export type UpsertSsoPreferenceForCompanyMutationResult = Apollo.MutationResult<UpsertSsoPreferenceForCompanyMutation>;
export type UpsertSsoPreferenceForCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpsertSsoPreferenceForCompanyMutation,
  UpsertSsoPreferenceForCompanyMutationVariables
>;
export const ValidateIntegrationCredentialsDocument = gql`
  query ValidateIntegrationCredentials($integrationId: ID!) {
    validateIntegrationCredentials(integrationId: $integrationId) {
      message
      success
    }
  }
`;

/**
 * __useValidateIntegrationCredentialsQuery__
 *
 * To run a query within a React component, call `useValidateIntegrationCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateIntegrationCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateIntegrationCredentialsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useValidateIntegrationCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateIntegrationCredentialsQuery,
    ValidateIntegrationCredentialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateIntegrationCredentialsQuery,
    ValidateIntegrationCredentialsQueryVariables
  >(ValidateIntegrationCredentialsDocument, options);
}
export function useValidateIntegrationCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateIntegrationCredentialsQuery,
    ValidateIntegrationCredentialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateIntegrationCredentialsQuery,
    ValidateIntegrationCredentialsQueryVariables
  >(ValidateIntegrationCredentialsDocument, options);
}
export type ValidateIntegrationCredentialsQueryHookResult = ReturnType<
  typeof useValidateIntegrationCredentialsQuery
>;
export type ValidateIntegrationCredentialsLazyQueryHookResult = ReturnType<
  typeof useValidateIntegrationCredentialsLazyQuery
>;
export type ValidateIntegrationCredentialsQueryResult = Apollo.QueryResult<
  ValidateIntegrationCredentialsQuery,
  ValidateIntegrationCredentialsQueryVariables
>;
export const VerifyTriNetCredentialDocument = gql`
  mutation VerifyTriNetCredential($input: TrinetCredentialInputV2!) {
    verifyTriNetCredential(input: $input) {
      message
      success
    }
  }
`;
export type VerifyTriNetCredentialMutationFn = Apollo.MutationFunction<
  VerifyTriNetCredentialMutation,
  VerifyTriNetCredentialMutationVariables
>;

/**
 * __useVerifyTriNetCredentialMutation__
 *
 * To run a mutation, you first call `useVerifyTriNetCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTriNetCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTriNetCredentialMutation, { data, loading, error }] = useVerifyTriNetCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyTriNetCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyTriNetCredentialMutation,
    VerifyTriNetCredentialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyTriNetCredentialMutation,
    VerifyTriNetCredentialMutationVariables
  >(VerifyTriNetCredentialDocument, options);
}
export type VerifyTriNetCredentialMutationHookResult = ReturnType<
  typeof useVerifyTriNetCredentialMutation
>;
export type VerifyTriNetCredentialMutationResult = Apollo.MutationResult<VerifyTriNetCredentialMutation>;
export type VerifyTriNetCredentialMutationOptions = Apollo.BaseMutationOptions<
  VerifyTriNetCredentialMutation,
  VerifyTriNetCredentialMutationVariables
>;
export const AssociateUserToCompanyDocument = gql`
  mutation AssociateUserToCompany {
    addOrAssociatePartnerCompanyUser {
      success
    }
  }
`;
export type AssociateUserToCompanyMutationFn = Apollo.MutationFunction<
  AssociateUserToCompanyMutation,
  AssociateUserToCompanyMutationVariables
>;

/**
 * __useAssociateUserToCompanyMutation__
 *
 * To run a mutation, you first call `useAssociateUserToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateUserToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateUserToCompanyMutation, { data, loading, error }] = useAssociateUserToCompanyMutation({
 *   variables: {
 *   },
 * });
 */
export function useAssociateUserToCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateUserToCompanyMutation,
    AssociateUserToCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateUserToCompanyMutation,
    AssociateUserToCompanyMutationVariables
  >(AssociateUserToCompanyDocument, options);
}
export type AssociateUserToCompanyMutationHookResult = ReturnType<
  typeof useAssociateUserToCompanyMutation
>;
export type AssociateUserToCompanyMutationResult = Apollo.MutationResult<AssociateUserToCompanyMutation>;
export type AssociateUserToCompanyMutationOptions = Apollo.BaseMutationOptions<
  AssociateUserToCompanyMutation,
  AssociateUserToCompanyMutationVariables
>;
export const CompanyAcceptInviteDocument = gql`
  mutation CompanyAcceptInvite {
    companyAcceptInvite {
      id
    }
  }
`;
export type CompanyAcceptInviteMutationFn = Apollo.MutationFunction<
  CompanyAcceptInviteMutation,
  CompanyAcceptInviteMutationVariables
>;

/**
 * __useCompanyAcceptInviteMutation__
 *
 * To run a mutation, you first call `useCompanyAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAcceptInviteMutation, { data, loading, error }] = useCompanyAcceptInviteMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompanyAcceptInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAcceptInviteMutation,
    CompanyAcceptInviteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyAcceptInviteMutation,
    CompanyAcceptInviteMutationVariables
  >(CompanyAcceptInviteDocument, options);
}
export type CompanyAcceptInviteMutationHookResult = ReturnType<
  typeof useCompanyAcceptInviteMutation
>;
export type CompanyAcceptInviteMutationResult = Apollo.MutationResult<CompanyAcceptInviteMutation>;
export type CompanyAcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  CompanyAcceptInviteMutation,
  CompanyAcceptInviteMutationVariables
>;
export const CompanyUserAcceptInviteDocument = gql`
  mutation CompanyUserAcceptInvite {
    companyUserAcceptInvite {
      id
    }
  }
`;
export type CompanyUserAcceptInviteMutationFn = Apollo.MutationFunction<
  CompanyUserAcceptInviteMutation,
  CompanyUserAcceptInviteMutationVariables
>;

/**
 * __useCompanyUserAcceptInviteMutation__
 *
 * To run a mutation, you first call `useCompanyUserAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserAcceptInviteMutation, { data, loading, error }] = useCompanyUserAcceptInviteMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompanyUserAcceptInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserAcceptInviteMutation,
    CompanyUserAcceptInviteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserAcceptInviteMutation,
    CompanyUserAcceptInviteMutationVariables
  >(CompanyUserAcceptInviteDocument, options);
}
export type CompanyUserAcceptInviteMutationHookResult = ReturnType<
  typeof useCompanyUserAcceptInviteMutation
>;
export type CompanyUserAcceptInviteMutationResult = Apollo.MutationResult<CompanyUserAcceptInviteMutation>;
export type CompanyUserAcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  CompanyUserAcceptInviteMutation,
  CompanyUserAcceptInviteMutationVariables
>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $companyLogo: Upload
    $isTest: Boolean
  ) {
    companyCreate(company: $input, companyLogo: $companyLogo, isTest: $isTest) {
      id
    }
  }
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      companyLogo: // value for 'companyLogo'
 *      isTest: // value for 'isTest'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const GetCompanyDetailsDocument = gql`
  query GetCompanyDetails {
    company {
      id
      displayName
      status
      primaryEntity {
        id
        legalName
        registrationNo
        phone
        address {
          line1
          line2
          city
          zipcode
          country
        }
      }
    }
  }
`;

/**
 * __useGetCompanyDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >(GetCompanyDetailsDocument, options);
}
export function useGetCompanyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyDetailsQuery,
    GetCompanyDetailsQueryVariables
  >(GetCompanyDetailsDocument, options);
}
export type GetCompanyDetailsQueryHookResult = ReturnType<
  typeof useGetCompanyDetailsQuery
>;
export type GetCompanyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDetailsLazyQuery
>;
export type GetCompanyDetailsQueryResult = Apollo.QueryResult<
  GetCompanyDetailsQuery,
  GetCompanyDetailsQueryVariables
>;
export const GetCompanyPrefillDataDocument = gql`
  query GetCompanyPrefillData($input: CompanyPreFillDataInput) {
    getCompanyPrefillData(input: $input) {
      name
      domain
      logo {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
          blob
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPrefillDataQuery__
 *
 * To run a query within a React component, call `useGetCompanyPrefillDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPrefillDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPrefillDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompanyPrefillDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPrefillDataQuery,
    GetCompanyPrefillDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPrefillDataQuery,
    GetCompanyPrefillDataQueryVariables
  >(GetCompanyPrefillDataDocument, options);
}
export function useGetCompanyPrefillDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPrefillDataQuery,
    GetCompanyPrefillDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPrefillDataQuery,
    GetCompanyPrefillDataQueryVariables
  >(GetCompanyPrefillDataDocument, options);
}
export type GetCompanyPrefillDataQueryHookResult = ReturnType<
  typeof useGetCompanyPrefillDataQuery
>;
export type GetCompanyPrefillDataLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPrefillDataLazyQuery
>;
export type GetCompanyPrefillDataQueryResult = Apollo.QueryResult<
  GetCompanyPrefillDataQuery,
  GetCompanyPrefillDataQueryVariables
>;
export const GetCompanyStatusDocument = gql`
  query GetCompanyStatus {
    company {
      id
      status
    }
  }
`;

/**
 * __useGetCompanyStatusQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyStatusQuery,
    GetCompanyStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyStatusQuery, GetCompanyStatusQueryVariables>(
    GetCompanyStatusDocument,
    options,
  );
}
export function useGetCompanyStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyStatusQuery,
    GetCompanyStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyStatusQuery,
    GetCompanyStatusQueryVariables
  >(GetCompanyStatusDocument, options);
}
export type GetCompanyStatusQueryHookResult = ReturnType<
  typeof useGetCompanyStatusQuery
>;
export type GetCompanyStatusLazyQueryHookResult = ReturnType<
  typeof useGetCompanyStatusLazyQuery
>;
export type GetCompanyStatusQueryResult = Apollo.QueryResult<
  GetCompanyStatusQuery,
  GetCompanyStatusQueryVariables
>;
export const GetCompanyUsersAndContractsDocument = gql`
  query GetCompanyUsersAndContracts {
    company {
      id
      contracts {
        type
        status
      }
      managers {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
        status
      }
      signatories {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
        userId
      }
      billingContact {
        id
        userId
        firstName
        lastName
        emails {
          email
        }
        title
        role
        isSignatory
        isBillingContact
      }
    }
  }
`;

/**
 * __useGetCompanyUsersAndContractsQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersAndContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersAndContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersAndContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyUsersAndContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyUsersAndContractsQuery,
    GetCompanyUsersAndContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyUsersAndContractsQuery,
    GetCompanyUsersAndContractsQueryVariables
  >(GetCompanyUsersAndContractsDocument, options);
}
export function useGetCompanyUsersAndContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyUsersAndContractsQuery,
    GetCompanyUsersAndContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyUsersAndContractsQuery,
    GetCompanyUsersAndContractsQueryVariables
  >(GetCompanyUsersAndContractsDocument, options);
}
export type GetCompanyUsersAndContractsQueryHookResult = ReturnType<
  typeof useGetCompanyUsersAndContractsQuery
>;
export type GetCompanyUsersAndContractsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyUsersAndContractsLazyQuery
>;
export type GetCompanyUsersAndContractsQueryResult = Apollo.QueryResult<
  GetCompanyUsersAndContractsQuery,
  GetCompanyUsersAndContractsQueryVariables
>;
export const GetMemberContractTypeDocument = gql`
  query GetMemberContractType {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        type
        term
        startOn
        endOn
        benefits {
          id
          status
          startOn
          benefit {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberContractTypeQuery__
 *
 * To run a query within a React component, call `useGetMemberContractTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberContractTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberContractTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberContractTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberContractTypeQuery,
    GetMemberContractTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberContractTypeQuery,
    GetMemberContractTypeQueryVariables
  >(GetMemberContractTypeDocument, options);
}
export function useGetMemberContractTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberContractTypeQuery,
    GetMemberContractTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberContractTypeQuery,
    GetMemberContractTypeQueryVariables
  >(GetMemberContractTypeDocument, options);
}
export type GetMemberContractTypeQueryHookResult = ReturnType<
  typeof useGetMemberContractTypeQuery
>;
export type GetMemberContractTypeLazyQueryHookResult = ReturnType<
  typeof useGetMemberContractTypeLazyQuery
>;
export type GetMemberContractTypeQueryResult = Apollo.QueryResult<
  GetMemberContractTypeQuery,
  GetMemberContractTypeQueryVariables
>;
export const GetMemberSummaryDocument = gql`
  query GetMemberSummary {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        type
        position
        country
        status
        endedOn
        onboarding {
          status
        }
        currency
        paymentAccountId
        benefits {
          benefit {
            type
            packageName
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberSummaryQuery__
 *
 * To run a query within a React component, call `useGetMemberSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberSummaryQuery,
    GetMemberSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberSummaryQuery, GetMemberSummaryQueryVariables>(
    GetMemberSummaryDocument,
    options,
  );
}
export function useGetMemberSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberSummaryQuery,
    GetMemberSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberSummaryQuery,
    GetMemberSummaryQueryVariables
  >(GetMemberSummaryDocument, options);
}
export type GetMemberSummaryQueryHookResult = ReturnType<
  typeof useGetMemberSummaryQuery
>;
export type GetMemberSummaryLazyQueryHookResult = ReturnType<
  typeof useGetMemberSummaryLazyQuery
>;
export type GetMemberSummaryQueryResult = Apollo.QueryResult<
  GetMemberSummaryQuery,
  GetMemberSummaryQueryVariables
>;
export const GetMultipleCompaniesDocument = gql`
  query GetMultipleCompanies($id: ID, $userID: ID) {
    companyGroup(id: $id, userId: $userID) {
      id
      name
      children {
        id
        displayName
        companyLogo {
          id
          name
          extension
          contentType
          ... on DocumentReadable {
            link
          }
        }
      }
    }
  }
`;

/**
 * __useGetMultipleCompaniesQuery__
 *
 * To run a query within a React component, call `useGetMultipleCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleCompaniesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetMultipleCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMultipleCompaniesQuery,
    GetMultipleCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMultipleCompaniesQuery,
    GetMultipleCompaniesQueryVariables
  >(GetMultipleCompaniesDocument, options);
}
export function useGetMultipleCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMultipleCompaniesQuery,
    GetMultipleCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMultipleCompaniesQuery,
    GetMultipleCompaniesQueryVariables
  >(GetMultipleCompaniesDocument, options);
}
export type GetMultipleCompaniesQueryHookResult = ReturnType<
  typeof useGetMultipleCompaniesQuery
>;
export type GetMultipleCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetMultipleCompaniesLazyQuery
>;
export type GetMultipleCompaniesQueryResult = Apollo.QueryResult<
  GetMultipleCompaniesQuery,
  GetMultipleCompaniesQueryVariables
>;
export const GetMemberContractIdDocument = gql`
  query GetMemberContractId {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
      }
    }
  }
`;

/**
 * __useGetMemberContractIdQuery__
 *
 * To run a query within a React component, call `useGetMemberContractIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberContractIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberContractIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberContractIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberContractIdQuery,
    GetMemberContractIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberContractIdQuery,
    GetMemberContractIdQueryVariables
  >(GetMemberContractIdDocument, options);
}
export function useGetMemberContractIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberContractIdQuery,
    GetMemberContractIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberContractIdQuery,
    GetMemberContractIdQueryVariables
  >(GetMemberContractIdDocument, options);
}
export type GetMemberContractIdQueryHookResult = ReturnType<
  typeof useGetMemberContractIdQuery
>;
export type GetMemberContractIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberContractIdLazyQuery
>;
export type GetMemberContractIdQueryResult = Apollo.QueryResult<
  GetMemberContractIdQuery,
  GetMemberContractIdQueryVariables
>;
export const MemberStartedDocument = gql`
  mutation MemberStarted($id: ID!) {
    contractMemberStarted(id: $id) {
      id
      status
      onboarding {
        current
      }
    }
  }
`;
export type MemberStartedMutationFn = Apollo.MutationFunction<
  MemberStartedMutation,
  MemberStartedMutationVariables
>;

/**
 * __useMemberStartedMutation__
 *
 * To run a mutation, you first call `useMemberStartedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberStartedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberStartedMutation, { data, loading, error }] = useMemberStartedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberStartedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberStartedMutation,
    MemberStartedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberStartedMutation,
    MemberStartedMutationVariables
  >(MemberStartedDocument, options);
}
export type MemberStartedMutationHookResult = ReturnType<
  typeof useMemberStartedMutation
>;
export type MemberStartedMutationResult = Apollo.MutationResult<MemberStartedMutation>;
export type MemberStartedMutationOptions = Apollo.BaseMutationOptions<
  MemberStartedMutation,
  MemberStartedMutationVariables
>;
export const GetAllRecruitersDocument = gql`
  query GetAllRecruiters($filters: RecruiterFilters) {
    recruiters(filters: $filters) {
      id
      name
      description
      logo {
        id
        link
        __typename
      }
      features {
        name
        category
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetAllRecruitersQuery__
 *
 * To run a query within a React component, call `useGetAllRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecruitersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllRecruitersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRecruitersQuery,
    GetAllRecruitersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRecruitersQuery, GetAllRecruitersQueryVariables>(
    GetAllRecruitersDocument,
    options,
  );
}
export function useGetAllRecruitersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRecruitersQuery,
    GetAllRecruitersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllRecruitersQuery,
    GetAllRecruitersQueryVariables
  >(GetAllRecruitersDocument, options);
}
export type GetAllRecruitersQueryHookResult = ReturnType<
  typeof useGetAllRecruitersQuery
>;
export type GetAllRecruitersLazyQueryHookResult = ReturnType<
  typeof useGetAllRecruitersLazyQuery
>;
export type GetAllRecruitersQueryResult = Apollo.QueryResult<
  GetAllRecruitersQuery,
  GetAllRecruitersQueryVariables
>;
export const GetRecruiterDocument = gql`
  query GetRecruiter($filters: RecruiterFilters) {
    recruiters(filters: $filters) {
      id
      name
      description
      logo {
        id
        extension
        name
        link
        contentType
        versionedOn
        __typename
      }
      phones {
        type
        phoneNo
        __typename
      }
      emails {
        type
        email
        __typename
      }
      features {
        name
        category
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetRecruiterQuery__
 *
 * To run a query within a React component, call `useGetRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecruiterQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRecruiterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecruiterQuery,
    GetRecruiterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecruiterQuery, GetRecruiterQueryVariables>(
    GetRecruiterDocument,
    options,
  );
}
export function useGetRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecruiterQuery,
    GetRecruiterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecruiterQuery, GetRecruiterQueryVariables>(
    GetRecruiterDocument,
    options,
  );
}
export type GetRecruiterQueryHookResult = ReturnType<
  typeof useGetRecruiterQuery
>;
export type GetRecruiterLazyQueryHookResult = ReturnType<
  typeof useGetRecruiterLazyQuery
>;
export type GetRecruiterQueryResult = Apollo.QueryResult<
  GetRecruiterQuery,
  GetRecruiterQueryVariables
>;
export const ApproveInvoiceDocument = gql`
  mutation ApproveInvoice($itemId: ID!) {
    approveItem(itemId: $itemId, category: MEMBER_PAYABLE) {
      ... on MemberPayableApprovalItem {
        id
        status
        memberPayableItem {
          id
          createdOn
          currency
          totalAmount
          totalInFunctionalCurrency
          dueDate
          status
          changes(status: PAID) {
            status
            actionedOn
          }
          contract {
            id
            member {
              id
              firstName
              lastName
            }
            country
          }
        }
      }
    }
  }
`;
export type ApproveInvoiceMutationFn = Apollo.MutationFunction<
  ApproveInvoiceMutation,
  ApproveInvoiceMutationVariables
>;

/**
 * __useApproveInvoiceMutation__
 *
 * To run a mutation, you first call `useApproveInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInvoiceMutation, { data, loading, error }] = useApproveInvoiceMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useApproveInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveInvoiceMutation,
    ApproveInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveInvoiceMutation,
    ApproveInvoiceMutationVariables
  >(ApproveInvoiceDocument, options);
}
export type ApproveInvoiceMutationHookResult = ReturnType<
  typeof useApproveInvoiceMutation
>;
export type ApproveInvoiceMutationResult = Apollo.MutationResult<ApproveInvoiceMutation>;
export type ApproveInvoiceMutationOptions = Apollo.BaseMutationOptions<
  ApproveInvoiceMutation,
  ApproveInvoiceMutationVariables
>;
export const ApproveInvoicesDocument = gql`
  mutation ApproveInvoices($ids: [ID!]!) {
    memberPayableUpdateBulkAsApproved(ids: $ids) {
      ...freelancerPayable
    }
  }
  ${FreelancerPayableFragmentDoc}
`;
export type ApproveInvoicesMutationFn = Apollo.MutationFunction<
  ApproveInvoicesMutation,
  ApproveInvoicesMutationVariables
>;

/**
 * __useApproveInvoicesMutation__
 *
 * To run a mutation, you first call `useApproveInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInvoicesMutation, { data, loading, error }] = useApproveInvoicesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useApproveInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveInvoicesMutation,
    ApproveInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveInvoicesMutation,
    ApproveInvoicesMutationVariables
  >(ApproveInvoicesDocument, options);
}
export type ApproveInvoicesMutationHookResult = ReturnType<
  typeof useApproveInvoicesMutation
>;
export type ApproveInvoicesMutationResult = Apollo.MutationResult<ApproveInvoicesMutation>;
export type ApproveInvoicesMutationOptions = Apollo.BaseMutationOptions<
  ApproveInvoicesMutation,
  ApproveInvoicesMutationVariables
>;
export const GetCompanyMemberPayableDetailsDocument = gql`
  query GetCompanyMemberPayableDetails($payableId: ID!) {
    company {
      id
      memberPayables(id: $payableId) {
        payoutCompletedAt
        payoutInitiatedAt
        ...expenseMemberPayable
        ...freelancerPayable
        ...paySupplementMemberPayable
      }
    }
  }
  ${ExpenseMemberPayableFragmentDoc}
  ${FreelancerPayableFragmentDoc}
  ${PaySupplementMemberPayableFragmentDoc}
`;

/**
 * __useGetCompanyMemberPayableDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberPayableDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberPayableDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberPayableDetailsQuery({
 *   variables: {
 *      payableId: // value for 'payableId'
 *   },
 * });
 */
export function useGetCompanyMemberPayableDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyMemberPayableDetailsQuery,
    GetCompanyMemberPayableDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyMemberPayableDetailsQuery,
    GetCompanyMemberPayableDetailsQueryVariables
  >(GetCompanyMemberPayableDetailsDocument, options);
}
export function useGetCompanyMemberPayableDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberPayableDetailsQuery,
    GetCompanyMemberPayableDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMemberPayableDetailsQuery,
    GetCompanyMemberPayableDetailsQueryVariables
  >(GetCompanyMemberPayableDetailsDocument, options);
}
export type GetCompanyMemberPayableDetailsQueryHookResult = ReturnType<
  typeof useGetCompanyMemberPayableDetailsQuery
>;
export type GetCompanyMemberPayableDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberPayableDetailsLazyQuery
>;
export type GetCompanyMemberPayableDetailsQueryResult = Apollo.QueryResult<
  GetCompanyMemberPayableDetailsQuery,
  GetCompanyMemberPayableDetailsQueryVariables
>;
export const GetCompanyUserMemberPayableDownloadAsPdfDocument = gql`
  query GetCompanyUserMemberPayableDownloadAsPDF($memberPayableId: ID) {
    company {
      memberPayables(id: $memberPayableId) {
        file {
          id
          name
          extension
          contentType
          link
        }
      }
    }
  }
`;

/**
 * __useGetCompanyUserMemberPayableDownloadAsPdfQuery__
 *
 * To run a query within a React component, call `useGetCompanyUserMemberPayableDownloadAsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUserMemberPayableDownloadAsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUserMemberPayableDownloadAsPdfQuery({
 *   variables: {
 *      memberPayableId: // value for 'memberPayableId'
 *   },
 * });
 */
export function useGetCompanyUserMemberPayableDownloadAsPdfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyUserMemberPayableDownloadAsPdfQuery,
    GetCompanyUserMemberPayableDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyUserMemberPayableDownloadAsPdfQuery,
    GetCompanyUserMemberPayableDownloadAsPdfQueryVariables
  >(GetCompanyUserMemberPayableDownloadAsPdfDocument, options);
}
export function useGetCompanyUserMemberPayableDownloadAsPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyUserMemberPayableDownloadAsPdfQuery,
    GetCompanyUserMemberPayableDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyUserMemberPayableDownloadAsPdfQuery,
    GetCompanyUserMemberPayableDownloadAsPdfQueryVariables
  >(GetCompanyUserMemberPayableDownloadAsPdfDocument, options);
}
export type GetCompanyUserMemberPayableDownloadAsPdfQueryHookResult = ReturnType<
  typeof useGetCompanyUserMemberPayableDownloadAsPdfQuery
>;
export type GetCompanyUserMemberPayableDownloadAsPdfLazyQueryHookResult = ReturnType<
  typeof useGetCompanyUserMemberPayableDownloadAsPdfLazyQuery
>;
export type GetCompanyUserMemberPayableDownloadAsPdfQueryResult = Apollo.QueryResult<
  GetCompanyUserMemberPayableDownloadAsPdfQuery,
  GetCompanyUserMemberPayableDownloadAsPdfQueryVariables
>;
export const GetCompanyUserMemberPaymentProofDownloadAsPdfDocument = gql`
  query GetCompanyUserMemberPaymentProofDownloadAsPDF($memberPayableId: ID) {
    company {
      memberPayables(id: $memberPayableId) {
        paymentReceipt {
          id
          name
          blob
        }
      }
    }
  }
`;

/**
 * __useGetCompanyUserMemberPaymentProofDownloadAsPdfQuery__
 *
 * To run a query within a React component, call `useGetCompanyUserMemberPaymentProofDownloadAsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUserMemberPaymentProofDownloadAsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUserMemberPaymentProofDownloadAsPdfQuery({
 *   variables: {
 *      memberPayableId: // value for 'memberPayableId'
 *   },
 * });
 */
export function useGetCompanyUserMemberPaymentProofDownloadAsPdfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyUserMemberPaymentProofDownloadAsPdfQuery,
    GetCompanyUserMemberPaymentProofDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyUserMemberPaymentProofDownloadAsPdfQuery,
    GetCompanyUserMemberPaymentProofDownloadAsPdfQueryVariables
  >(GetCompanyUserMemberPaymentProofDownloadAsPdfDocument, options);
}
export function useGetCompanyUserMemberPaymentProofDownloadAsPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyUserMemberPaymentProofDownloadAsPdfQuery,
    GetCompanyUserMemberPaymentProofDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyUserMemberPaymentProofDownloadAsPdfQuery,
    GetCompanyUserMemberPaymentProofDownloadAsPdfQueryVariables
  >(GetCompanyUserMemberPaymentProofDownloadAsPdfDocument, options);
}
export type GetCompanyUserMemberPaymentProofDownloadAsPdfQueryHookResult = ReturnType<
  typeof useGetCompanyUserMemberPaymentProofDownloadAsPdfQuery
>;
export type GetCompanyUserMemberPaymentProofDownloadAsPdfLazyQueryHookResult = ReturnType<
  typeof useGetCompanyUserMemberPaymentProofDownloadAsPdfLazyQuery
>;
export type GetCompanyUserMemberPaymentProofDownloadAsPdfQueryResult = Apollo.QueryResult<
  GetCompanyUserMemberPaymentProofDownloadAsPdfQuery,
  GetCompanyUserMemberPaymentProofDownloadAsPdfQueryVariables
>;
export const GetCompanyMemberPayablesDocument = gql`
  query GetCompanyMemberPayables($types: [MemberPayableType!]) {
    company {
      id
      memberPayables(types: $types) {
        payoutCompletedAt
        ...expenseMemberPayable
        ...freelancerPayable
        ...paySupplementMemberPayable
      }
    }
  }
  ${ExpenseMemberPayableFragmentDoc}
  ${FreelancerPayableFragmentDoc}
  ${PaySupplementMemberPayableFragmentDoc}
`;

/**
 * __useGetCompanyMemberPayablesQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberPayablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberPayablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberPayablesQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetCompanyMemberPayablesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyMemberPayablesQuery,
    GetCompanyMemberPayablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyMemberPayablesQuery,
    GetCompanyMemberPayablesQueryVariables
  >(GetCompanyMemberPayablesDocument, options);
}
export function useGetCompanyMemberPayablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberPayablesQuery,
    GetCompanyMemberPayablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMemberPayablesQuery,
    GetCompanyMemberPayablesQueryVariables
  >(GetCompanyMemberPayablesDocument, options);
}
export type GetCompanyMemberPayablesQueryHookResult = ReturnType<
  typeof useGetCompanyMemberPayablesQuery
>;
export type GetCompanyMemberPayablesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberPayablesLazyQuery
>;
export type GetCompanyMemberPayablesQueryResult = Apollo.QueryResult<
  GetCompanyMemberPayablesQuery,
  GetCompanyMemberPayablesQueryVariables
>;
export const MemberPayableCompanyInvoiceConfirmPaymentDocument = gql`
  mutation memberPayableCompanyInvoiceConfirmPayment($id: ID!) {
    memberPayableCompanyInvoiceConfirmPayment(id: $id) {
      ...freelancerPayable
    }
  }
  ${FreelancerPayableFragmentDoc}
`;
export type MemberPayableCompanyInvoiceConfirmPaymentMutationFn = Apollo.MutationFunction<
  MemberPayableCompanyInvoiceConfirmPaymentMutation,
  MemberPayableCompanyInvoiceConfirmPaymentMutationVariables
>;

/**
 * __useMemberPayableCompanyInvoiceConfirmPaymentMutation__
 *
 * To run a mutation, you first call `useMemberPayableCompanyInvoiceConfirmPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberPayableCompanyInvoiceConfirmPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberPayableCompanyInvoiceConfirmPaymentMutation, { data, loading, error }] = useMemberPayableCompanyInvoiceConfirmPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberPayableCompanyInvoiceConfirmPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberPayableCompanyInvoiceConfirmPaymentMutation,
    MemberPayableCompanyInvoiceConfirmPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberPayableCompanyInvoiceConfirmPaymentMutation,
    MemberPayableCompanyInvoiceConfirmPaymentMutationVariables
  >(MemberPayableCompanyInvoiceConfirmPaymentDocument, options);
}
export type MemberPayableCompanyInvoiceConfirmPaymentMutationHookResult = ReturnType<
  typeof useMemberPayableCompanyInvoiceConfirmPaymentMutation
>;
export type MemberPayableCompanyInvoiceConfirmPaymentMutationResult = Apollo.MutationResult<MemberPayableCompanyInvoiceConfirmPaymentMutation>;
export type MemberPayableCompanyInvoiceConfirmPaymentMutationOptions = Apollo.BaseMutationOptions<
  MemberPayableCompanyInvoiceConfirmPaymentMutation,
  MemberPayableCompanyInvoiceConfirmPaymentMutationVariables
>;
export const MemberPayableCompanyInvoiceCreatePayInDocument = gql`
  mutation memberPayableCompanyInvoiceCreatePayIn($id: ID!) {
    memberPayableCompanyInvoiceCreatePayIn(id: $id) {
      id
      metadata {
        key
        value
      }
    }
  }
`;
export type MemberPayableCompanyInvoiceCreatePayInMutationFn = Apollo.MutationFunction<
  MemberPayableCompanyInvoiceCreatePayInMutation,
  MemberPayableCompanyInvoiceCreatePayInMutationVariables
>;

/**
 * __useMemberPayableCompanyInvoiceCreatePayInMutation__
 *
 * To run a mutation, you first call `useMemberPayableCompanyInvoiceCreatePayInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberPayableCompanyInvoiceCreatePayInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberPayableCompanyInvoiceCreatePayInMutation, { data, loading, error }] = useMemberPayableCompanyInvoiceCreatePayInMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberPayableCompanyInvoiceCreatePayInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberPayableCompanyInvoiceCreatePayInMutation,
    MemberPayableCompanyInvoiceCreatePayInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberPayableCompanyInvoiceCreatePayInMutation,
    MemberPayableCompanyInvoiceCreatePayInMutationVariables
  >(MemberPayableCompanyInvoiceCreatePayInDocument, options);
}
export type MemberPayableCompanyInvoiceCreatePayInMutationHookResult = ReturnType<
  typeof useMemberPayableCompanyInvoiceCreatePayInMutation
>;
export type MemberPayableCompanyInvoiceCreatePayInMutationResult = Apollo.MutationResult<MemberPayableCompanyInvoiceCreatePayInMutation>;
export type MemberPayableCompanyInvoiceCreatePayInMutationOptions = Apollo.BaseMutationOptions<
  MemberPayableCompanyInvoiceCreatePayInMutation,
  MemberPayableCompanyInvoiceCreatePayInMutationVariables
>;
export const MemberPayableCompanyInvoiceSubmitDocument = gql`
  mutation memberPayableCompanyInvoiceSubmit(
    $invoice: MemberPayableCompanyInvoiceSubmitInput!
  ) {
    memberPayableCompanyInvoiceSubmit(input: $invoice) {
      id
      billingCurrency
      totalAmount
      referenceId
      bankTransferFee
      bankAccount {
        accountDetails {
          key
          value
        }
      }
      paymentMethod {
        bundleId
        paymentMethod
        processingFee {
          feeTotal
          feeTypes {
            amount
            name
          }
        }
      }
      payoutPaymentMethodInfo {
        bundleId
        paymentMethod
        processingFee {
          feeTotal
          feeTypes {
            amount
            name
            metadata {
              key
              value
            }
          }
        }
      }
      memberPayableInvoiceItems {
        billingAmount
        managementFee
        memberPayable {
          id
          createdOn
          type
          payableFromDate
          payableToDate
          title
          description
          contract {
            id
            member {
              firstName
              lastName
            }
            country
          }
          payOutType
          ... on PaySupplementMemberPayable {
            paySupplement {
              id
            }
          }
          ... on ExpenseMemberPayable {
            expense {
              id
            }
          }
        }
      }
      document {
        id
        link
      }
    }
  }
`;
export type MemberPayableCompanyInvoiceSubmitMutationFn = Apollo.MutationFunction<
  MemberPayableCompanyInvoiceSubmitMutation,
  MemberPayableCompanyInvoiceSubmitMutationVariables
>;

/**
 * __useMemberPayableCompanyInvoiceSubmitMutation__
 *
 * To run a mutation, you first call `useMemberPayableCompanyInvoiceSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberPayableCompanyInvoiceSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberPayableCompanyInvoiceSubmitMutation, { data, loading, error }] = useMemberPayableCompanyInvoiceSubmitMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useMemberPayableCompanyInvoiceSubmitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberPayableCompanyInvoiceSubmitMutation,
    MemberPayableCompanyInvoiceSubmitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberPayableCompanyInvoiceSubmitMutation,
    MemberPayableCompanyInvoiceSubmitMutationVariables
  >(MemberPayableCompanyInvoiceSubmitDocument, options);
}
export type MemberPayableCompanyInvoiceSubmitMutationHookResult = ReturnType<
  typeof useMemberPayableCompanyInvoiceSubmitMutation
>;
export type MemberPayableCompanyInvoiceSubmitMutationResult = Apollo.MutationResult<MemberPayableCompanyInvoiceSubmitMutation>;
export type MemberPayableCompanyInvoiceSubmitMutationOptions = Apollo.BaseMutationOptions<
  MemberPayableCompanyInvoiceSubmitMutation,
  MemberPayableCompanyInvoiceSubmitMutationVariables
>;
export const RejectInvoicesDocument = gql`
  mutation RejectInvoices($input: [MemberPayableRejectInput]!) {
    memberPayableUpdateBulkAsRejected(input: $input) {
      ...freelancerPayable
    }
  }
  ${FreelancerPayableFragmentDoc}
`;
export type RejectInvoicesMutationFn = Apollo.MutationFunction<
  RejectInvoicesMutation,
  RejectInvoicesMutationVariables
>;

/**
 * __useRejectInvoicesMutation__
 *
 * To run a mutation, you first call `useRejectInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectInvoicesMutation, { data, loading, error }] = useRejectInvoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectInvoicesMutation,
    RejectInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectInvoicesMutation,
    RejectInvoicesMutationVariables
  >(RejectInvoicesDocument, options);
}
export type RejectInvoicesMutationHookResult = ReturnType<
  typeof useRejectInvoicesMutation
>;
export type RejectInvoicesMutationResult = Apollo.MutationResult<RejectInvoicesMutation>;
export type RejectInvoicesMutationOptions = Apollo.BaseMutationOptions<
  RejectInvoicesMutation,
  RejectInvoicesMutationVariables
>;
export const DeleteMemberPayableDocument = gql`
  mutation DeleteMemberPayable($id: ID!) {
    memberPayableDelete(id: $id) {
      id
      contract {
        id
      }
    }
  }
`;
export type DeleteMemberPayableMutationFn = Apollo.MutationFunction<
  DeleteMemberPayableMutation,
  DeleteMemberPayableMutationVariables
>;

/**
 * __useDeleteMemberPayableMutation__
 *
 * To run a mutation, you first call `useDeleteMemberPayableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberPayableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberPayableMutation, { data, loading, error }] = useDeleteMemberPayableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberPayableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberPayableMutation,
    DeleteMemberPayableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberPayableMutation,
    DeleteMemberPayableMutationVariables
  >(DeleteMemberPayableDocument, options);
}
export type DeleteMemberPayableMutationHookResult = ReturnType<
  typeof useDeleteMemberPayableMutation
>;
export type DeleteMemberPayableMutationResult = Apollo.MutationResult<DeleteMemberPayableMutation>;
export type DeleteMemberPayableMutationOptions = Apollo.BaseMutationOptions<
  DeleteMemberPayableMutation,
  DeleteMemberPayableMutationVariables
>;
export const GetMemberPayableByCompanyDocument = gql`
  query getMemberPayableByCompany($memberPayableId: ID) {
    member {
      contracts(includeAnyStatus: true) {
        memberPayables(id: $memberPayableId) {
          ... on FreelancerPayable {
            id
            status
            title
            dueDate
            createdOn
            totalAmount
            description
            payableFromDate
            payableToDate
            changeReason
            durationValue
            comment
            rejectedAt
            submittedAt
            memberPayableInvoiceWorkflow {
              ...invoiceWorkFlow
            }
            changes {
              status
              actionedOn
              reason
            }
            currency
            contract {
              id
              country
            }
          }
        }
        __typename
      }
      __typename
    }
  }
  ${InvoiceWorkFlowFragmentDoc}
`;

/**
 * __useGetMemberPayableByCompanyQuery__
 *
 * To run a query within a React component, call `useGetMemberPayableByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPayableByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPayableByCompanyQuery({
 *   variables: {
 *      memberPayableId: // value for 'memberPayableId'
 *   },
 * });
 */
export function useGetMemberPayableByCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberPayableByCompanyQuery,
    GetMemberPayableByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPayableByCompanyQuery,
    GetMemberPayableByCompanyQueryVariables
  >(GetMemberPayableByCompanyDocument, options);
}
export function useGetMemberPayableByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPayableByCompanyQuery,
    GetMemberPayableByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPayableByCompanyQuery,
    GetMemberPayableByCompanyQueryVariables
  >(GetMemberPayableByCompanyDocument, options);
}
export type GetMemberPayableByCompanyQueryHookResult = ReturnType<
  typeof useGetMemberPayableByCompanyQuery
>;
export type GetMemberPayableByCompanyLazyQueryHookResult = ReturnType<
  typeof useGetMemberPayableByCompanyLazyQuery
>;
export type GetMemberPayableByCompanyQueryResult = Apollo.QueryResult<
  GetMemberPayableByCompanyQuery,
  GetMemberPayableByCompanyQueryVariables
>;
export const GetMemberPayableDownloadAsPdfDocument = gql`
  query GetMemberPayableDownloadAsPDF($memberPayableId: ID) {
    member {
      contracts(includeAnyStatus: true) {
        memberPayables(id: $memberPayableId) {
          file {
            id
            name
            extension
            contentType
            link
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberPayableDownloadAsPdfQuery__
 *
 * To run a query within a React component, call `useGetMemberPayableDownloadAsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPayableDownloadAsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPayableDownloadAsPdfQuery({
 *   variables: {
 *      memberPayableId: // value for 'memberPayableId'
 *   },
 * });
 */
export function useGetMemberPayableDownloadAsPdfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberPayableDownloadAsPdfQuery,
    GetMemberPayableDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPayableDownloadAsPdfQuery,
    GetMemberPayableDownloadAsPdfQueryVariables
  >(GetMemberPayableDownloadAsPdfDocument, options);
}
export function useGetMemberPayableDownloadAsPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPayableDownloadAsPdfQuery,
    GetMemberPayableDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPayableDownloadAsPdfQuery,
    GetMemberPayableDownloadAsPdfQueryVariables
  >(GetMemberPayableDownloadAsPdfDocument, options);
}
export type GetMemberPayableDownloadAsPdfQueryHookResult = ReturnType<
  typeof useGetMemberPayableDownloadAsPdfQuery
>;
export type GetMemberPayableDownloadAsPdfLazyQueryHookResult = ReturnType<
  typeof useGetMemberPayableDownloadAsPdfLazyQuery
>;
export type GetMemberPayableDownloadAsPdfQueryResult = Apollo.QueryResult<
  GetMemberPayableDownloadAsPdfQuery,
  GetMemberPayableDownloadAsPdfQueryVariables
>;
export const GetRemittanceAdviceDownloadAsPdfDocument = gql`
  query GetRemittanceAdviceDownloadAsPDF($memberPayableId: ID) {
    member {
      contracts(includeAnyStatus: true) {
        memberPayables(id: $memberPayableId) {
          file {
            id
            name
            extension
            contentType
            link
          }
        }
      }
    }
  }
`;

/**
 * __useGetRemittanceAdviceDownloadAsPdfQuery__
 *
 * To run a query within a React component, call `useGetRemittanceAdviceDownloadAsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemittanceAdviceDownloadAsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemittanceAdviceDownloadAsPdfQuery({
 *   variables: {
 *      memberPayableId: // value for 'memberPayableId'
 *   },
 * });
 */
export function useGetRemittanceAdviceDownloadAsPdfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRemittanceAdviceDownloadAsPdfQuery,
    GetRemittanceAdviceDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRemittanceAdviceDownloadAsPdfQuery,
    GetRemittanceAdviceDownloadAsPdfQueryVariables
  >(GetRemittanceAdviceDownloadAsPdfDocument, options);
}
export function useGetRemittanceAdviceDownloadAsPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRemittanceAdviceDownloadAsPdfQuery,
    GetRemittanceAdviceDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRemittanceAdviceDownloadAsPdfQuery,
    GetRemittanceAdviceDownloadAsPdfQueryVariables
  >(GetRemittanceAdviceDownloadAsPdfDocument, options);
}
export type GetRemittanceAdviceDownloadAsPdfQueryHookResult = ReturnType<
  typeof useGetRemittanceAdviceDownloadAsPdfQuery
>;
export type GetRemittanceAdviceDownloadAsPdfLazyQueryHookResult = ReturnType<
  typeof useGetRemittanceAdviceDownloadAsPdfLazyQuery
>;
export type GetRemittanceAdviceDownloadAsPdfQueryResult = Apollo.QueryResult<
  GetRemittanceAdviceDownloadAsPdfQuery,
  GetRemittanceAdviceDownloadAsPdfQueryVariables
>;
export const GetMemberPayablesDocument = gql`
  query GetMemberPayables {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        currency
        memberPayables {
          ...freelancerPayableMember
        }
      }
    }
  }
  ${FreelancerPayableMemberFragmentDoc}
`;

/**
 * __useGetMemberPayablesQuery__
 *
 * To run a query within a React component, call `useGetMemberPayablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPayablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPayablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberPayablesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberPayablesQuery,
    GetMemberPayablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPayablesQuery,
    GetMemberPayablesQueryVariables
  >(GetMemberPayablesDocument, options);
}
export function useGetMemberPayablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPayablesQuery,
    GetMemberPayablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPayablesQuery,
    GetMemberPayablesQueryVariables
  >(GetMemberPayablesDocument, options);
}
export type GetMemberPayablesQueryHookResult = ReturnType<
  typeof useGetMemberPayablesQuery
>;
export type GetMemberPayablesLazyQueryHookResult = ReturnType<
  typeof useGetMemberPayablesLazyQuery
>;
export type GetMemberPayablesQueryResult = Apollo.QueryResult<
  GetMemberPayablesQuery,
  GetMemberPayablesQueryVariables
>;
export const GetMemberPaymentProofDownloadAsPdfDocument = gql`
  query GetMemberPaymentProofDownloadAsPDF($memberPayableId: ID) {
    member {
      contracts(includeAnyStatus: true) {
        memberPayables(id: $memberPayableId) {
          paymentReceipt {
            id
            name
            blob
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberPaymentProofDownloadAsPdfQuery__
 *
 * To run a query within a React component, call `useGetMemberPaymentProofDownloadAsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPaymentProofDownloadAsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPaymentProofDownloadAsPdfQuery({
 *   variables: {
 *      memberPayableId: // value for 'memberPayableId'
 *   },
 * });
 */
export function useGetMemberPaymentProofDownloadAsPdfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberPaymentProofDownloadAsPdfQuery,
    GetMemberPaymentProofDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPaymentProofDownloadAsPdfQuery,
    GetMemberPaymentProofDownloadAsPdfQueryVariables
  >(GetMemberPaymentProofDownloadAsPdfDocument, options);
}
export function useGetMemberPaymentProofDownloadAsPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPaymentProofDownloadAsPdfQuery,
    GetMemberPaymentProofDownloadAsPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPaymentProofDownloadAsPdfQuery,
    GetMemberPaymentProofDownloadAsPdfQueryVariables
  >(GetMemberPaymentProofDownloadAsPdfDocument, options);
}
export type GetMemberPaymentProofDownloadAsPdfQueryHookResult = ReturnType<
  typeof useGetMemberPaymentProofDownloadAsPdfQuery
>;
export type GetMemberPaymentProofDownloadAsPdfLazyQueryHookResult = ReturnType<
  typeof useGetMemberPaymentProofDownloadAsPdfLazyQuery
>;
export type GetMemberPaymentProofDownloadAsPdfQueryResult = Apollo.QueryResult<
  GetMemberPaymentProofDownloadAsPdfQuery,
  GetMemberPaymentProofDownloadAsPdfQueryVariables
>;
export const GetMemberPayableContractDocument = gql`
  query GetMemberPayableContract {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        startOn
        endOn
        currency
        compensation {
          basePay {
            ...fixedPayComponent
          }
        }
        company {
          id
          displayName
          primaryEntity {
            id
            registrationNo
            address {
              key
              country
            }
          }
        }
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
`;

/**
 * __useGetMemberPayableContractQuery__
 *
 * To run a query within a React component, call `useGetMemberPayableContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPayableContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPayableContractQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberPayableContractQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberPayableContractQuery,
    GetMemberPayableContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPayableContractQuery,
    GetMemberPayableContractQueryVariables
  >(GetMemberPayableContractDocument, options);
}
export function useGetMemberPayableContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPayableContractQuery,
    GetMemberPayableContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPayableContractQuery,
    GetMemberPayableContractQueryVariables
  >(GetMemberPayableContractDocument, options);
}
export type GetMemberPayableContractQueryHookResult = ReturnType<
  typeof useGetMemberPayableContractQuery
>;
export type GetMemberPayableContractLazyQueryHookResult = ReturnType<
  typeof useGetMemberPayableContractLazyQuery
>;
export type GetMemberPayableContractQueryResult = Apollo.QueryResult<
  GetMemberPayableContractQuery,
  GetMemberPayableContractQueryVariables
>;
export const MemberSubmitMemberPayableDocument = gql`
  mutation MemberSubmitMemberPayable($input: MemberPayableSubmitInput!) {
    memberPayableSubmit(input: $input) {
      ...freelancerPayableMember
      contract {
        id
      }
    }
  }
  ${FreelancerPayableMemberFragmentDoc}
`;
export type MemberSubmitMemberPayableMutationFn = Apollo.MutationFunction<
  MemberSubmitMemberPayableMutation,
  MemberSubmitMemberPayableMutationVariables
>;

/**
 * __useMemberSubmitMemberPayableMutation__
 *
 * To run a mutation, you first call `useMemberSubmitMemberPayableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberSubmitMemberPayableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberSubmitMemberPayableMutation, { data, loading, error }] = useMemberSubmitMemberPayableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberSubmitMemberPayableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberSubmitMemberPayableMutation,
    MemberSubmitMemberPayableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberSubmitMemberPayableMutation,
    MemberSubmitMemberPayableMutationVariables
  >(MemberSubmitMemberPayableDocument, options);
}
export type MemberSubmitMemberPayableMutationHookResult = ReturnType<
  typeof useMemberSubmitMemberPayableMutation
>;
export type MemberSubmitMemberPayableMutationResult = Apollo.MutationResult<MemberSubmitMemberPayableMutation>;
export type MemberSubmitMemberPayableMutationOptions = Apollo.BaseMutationOptions<
  MemberSubmitMemberPayableMutation,
  MemberSubmitMemberPayableMutationVariables
>;
export const MemberUpdateMemberPayableDocument = gql`
  mutation MemberUpdateMemberPayable(
    $id: ID!
    $input: MemberPayableUpdateInput!
  ) {
    memberPayableUpdate(id: $id, input: $input) {
      ...freelancerPayableMember
      contract {
        id
      }
    }
  }
  ${FreelancerPayableMemberFragmentDoc}
`;
export type MemberUpdateMemberPayableMutationFn = Apollo.MutationFunction<
  MemberUpdateMemberPayableMutation,
  MemberUpdateMemberPayableMutationVariables
>;

/**
 * __useMemberUpdateMemberPayableMutation__
 *
 * To run a mutation, you first call `useMemberUpdateMemberPayableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpdateMemberPayableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpdateMemberPayableMutation, { data, loading, error }] = useMemberUpdateMemberPayableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpdateMemberPayableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpdateMemberPayableMutation,
    MemberUpdateMemberPayableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpdateMemberPayableMutation,
    MemberUpdateMemberPayableMutationVariables
  >(MemberUpdateMemberPayableDocument, options);
}
export type MemberUpdateMemberPayableMutationHookResult = ReturnType<
  typeof useMemberUpdateMemberPayableMutation
>;
export type MemberUpdateMemberPayableMutationResult = Apollo.MutationResult<MemberUpdateMemberPayableMutation>;
export type MemberUpdateMemberPayableMutationOptions = Apollo.BaseMutationOptions<
  MemberUpdateMemberPayableMutation,
  MemberUpdateMemberPayableMutationVariables
>;
export const AddApproverDocument = gql`
  mutation AddApprover($input: AddApproverInfoInput!) {
    companyUserAddApproverInfo(input: $input) {
      id
      firstName
      lastName
      emails {
        email
      }
      title
      role
      isSignatory
      isBillingContact
      userId
      status
      approver {
        id
        categoryContracts {
          category
          managedContracts {
            id
          }
        }
      }
    }
  }
`;
export type AddApproverMutationFn = Apollo.MutationFunction<
  AddApproverMutation,
  AddApproverMutationVariables
>;

/**
 * __useAddApproverMutation__
 *
 * To run a mutation, you first call `useAddApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApproverMutation, { data, loading, error }] = useAddApproverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddApproverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddApproverMutation,
    AddApproverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddApproverMutation, AddApproverMutationVariables>(
    AddApproverDocument,
    options,
  );
}
export type AddApproverMutationHookResult = ReturnType<
  typeof useAddApproverMutation
>;
export type AddApproverMutationResult = Apollo.MutationResult<AddApproverMutation>;
export type AddApproverMutationOptions = Apollo.BaseMutationOptions<
  AddApproverMutation,
  AddApproverMutationVariables
>;
export const AddCompanyUserDocument = gql`
  mutation AddCompanyUser($user: AddCompanyUserInput) {
    companyUserAdd(user: $user) {
      id
      userId
      firstName
      lastName
      emails {
        email
      }
      title
      role
      isSignatory
      isBillingContact
      status
      company {
        id
      }
    }
  }
`;
export type AddCompanyUserMutationFn = Apollo.MutationFunction<
  AddCompanyUserMutation,
  AddCompanyUserMutationVariables
>;

/**
 * __useAddCompanyUserMutation__
 *
 * To run a mutation, you first call `useAddCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyUserMutation, { data, loading, error }] = useAddCompanyUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyUserMutation,
    AddCompanyUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCompanyUserMutation,
    AddCompanyUserMutationVariables
  >(AddCompanyUserDocument, options);
}
export type AddCompanyUserMutationHookResult = ReturnType<
  typeof useAddCompanyUserMutation
>;
export type AddCompanyUserMutationResult = Apollo.MutationResult<AddCompanyUserMutation>;
export type AddCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyUserMutation,
  AddCompanyUserMutationVariables
>;
export const DepartmentAssignEmployeesDocument = gql`
  mutation DepartmentAssignEmployees($input: DepartmentAssignEmployeesInput!) {
    departmentAssignEmployees(input: $input) {
      id
      name
      description
      hod {
        manager {
          id
          companyUser {
            firstName
            lastName
          }
        }
        contract {
          id
          member {
            firstName
            lastName
          }
        }
      }
      employees {
        contracts {
          id
          country
          member {
            id
            userId
            gender
            firstName
            lastName
            emails {
              email
            }
            phoneNos {
              phoneNo
            }
          }
          position
          workEmail
          reportsToManager {
            id
          }
        }
        managers {
          id
          companyUser {
            userId
            firstName
            lastName
            title
            emails {
              email
            }
            phoneNos {
              phoneNo
            }
          }
          reportsToManager {
            id
          }
        }
      }
    }
  }
`;
export type DepartmentAssignEmployeesMutationFn = Apollo.MutationFunction<
  DepartmentAssignEmployeesMutation,
  DepartmentAssignEmployeesMutationVariables
>;

/**
 * __useDepartmentAssignEmployeesMutation__
 *
 * To run a mutation, you first call `useDepartmentAssignEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentAssignEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentAssignEmployeesMutation, { data, loading, error }] = useDepartmentAssignEmployeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepartmentAssignEmployeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DepartmentAssignEmployeesMutation,
    DepartmentAssignEmployeesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DepartmentAssignEmployeesMutation,
    DepartmentAssignEmployeesMutationVariables
  >(DepartmentAssignEmployeesDocument, options);
}
export type DepartmentAssignEmployeesMutationHookResult = ReturnType<
  typeof useDepartmentAssignEmployeesMutation
>;
export type DepartmentAssignEmployeesMutationResult = Apollo.MutationResult<DepartmentAssignEmployeesMutation>;
export type DepartmentAssignEmployeesMutationOptions = Apollo.BaseMutationOptions<
  DepartmentAssignEmployeesMutation,
  DepartmentAssignEmployeesMutationVariables
>;
export const CompanyUserAssignRoleDocument = gql`
  mutation CompanyUserAssignRole($input: CompanyUserAssignRoleInput) {
    companyUserAssignRole(input: $input) {
      id
    }
  }
`;
export type CompanyUserAssignRoleMutationFn = Apollo.MutationFunction<
  CompanyUserAssignRoleMutation,
  CompanyUserAssignRoleMutationVariables
>;

/**
 * __useCompanyUserAssignRoleMutation__
 *
 * To run a mutation, you first call `useCompanyUserAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserAssignRoleMutation, { data, loading, error }] = useCompanyUserAssignRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUserAssignRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserAssignRoleMutation,
    CompanyUserAssignRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserAssignRoleMutation,
    CompanyUserAssignRoleMutationVariables
  >(CompanyUserAssignRoleDocument, options);
}
export type CompanyUserAssignRoleMutationHookResult = ReturnType<
  typeof useCompanyUserAssignRoleMutation
>;
export type CompanyUserAssignRoleMutationResult = Apollo.MutationResult<CompanyUserAssignRoleMutation>;
export type CompanyUserAssignRoleMutationOptions = Apollo.BaseMutationOptions<
  CompanyUserAssignRoleMutation,
  CompanyUserAssignRoleMutationVariables
>;
export const TimeOffPolicyAssignUsersDocument = gql`
  mutation TimeOffPolicyAssignUsers($input: TimeOffPolicyAssignUsersInput!) {
    timeOffPolicyAssignUsers(input: $input) {
      id
      policyName
      type
      typeId
      label
      assignedEntityNames
      assignedEmployeeCount
      status
      validation {
        ... on TimeOffFixedValidation {
          defaultValue
          unit
          isUnlimitedLeavesAllowed
        }
      }
      configuration {
        carryForward {
          enabled
          maxLimit {
            unit
            value
          }
          expiry {
            unit
            value
          }
        }
      }
    }
  }
`;
export type TimeOffPolicyAssignUsersMutationFn = Apollo.MutationFunction<
  TimeOffPolicyAssignUsersMutation,
  TimeOffPolicyAssignUsersMutationVariables
>;

/**
 * __useTimeOffPolicyAssignUsersMutation__
 *
 * To run a mutation, you first call `useTimeOffPolicyAssignUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyAssignUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffPolicyAssignUsersMutation, { data, loading, error }] = useTimeOffPolicyAssignUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffPolicyAssignUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffPolicyAssignUsersMutation,
    TimeOffPolicyAssignUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffPolicyAssignUsersMutation,
    TimeOffPolicyAssignUsersMutationVariables
  >(TimeOffPolicyAssignUsersDocument, options);
}
export type TimeOffPolicyAssignUsersMutationHookResult = ReturnType<
  typeof useTimeOffPolicyAssignUsersMutation
>;
export type TimeOffPolicyAssignUsersMutationResult = Apollo.MutationResult<TimeOffPolicyAssignUsersMutation>;
export type TimeOffPolicyAssignUsersMutationOptions = Apollo.BaseMutationOptions<
  TimeOffPolicyAssignUsersMutation,
  TimeOffPolicyAssignUsersMutationVariables
>;
export const CompanyUserAssignRoleAndAttributesDocument = gql`
  mutation CompanyUserAssignRoleAndAttributes(
    $input: CompanyUserAssignRoleAndAttributesInput
  ) {
    companyUserAssignRoleAndAttributes(input: $input) {
      id
      firstName
      lastName
    }
  }
`;
export type CompanyUserAssignRoleAndAttributesMutationFn = Apollo.MutationFunction<
  CompanyUserAssignRoleAndAttributesMutation,
  CompanyUserAssignRoleAndAttributesMutationVariables
>;

/**
 * __useCompanyUserAssignRoleAndAttributesMutation__
 *
 * To run a mutation, you first call `useCompanyUserAssignRoleAndAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserAssignRoleAndAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserAssignRoleAndAttributesMutation, { data, loading, error }] = useCompanyUserAssignRoleAndAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUserAssignRoleAndAttributesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserAssignRoleAndAttributesMutation,
    CompanyUserAssignRoleAndAttributesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserAssignRoleAndAttributesMutation,
    CompanyUserAssignRoleAndAttributesMutationVariables
  >(CompanyUserAssignRoleAndAttributesDocument, options);
}
export type CompanyUserAssignRoleAndAttributesMutationHookResult = ReturnType<
  typeof useCompanyUserAssignRoleAndAttributesMutation
>;
export type CompanyUserAssignRoleAndAttributesMutationResult = Apollo.MutationResult<CompanyUserAssignRoleAndAttributesMutation>;
export type CompanyUserAssignRoleAndAttributesMutationOptions = Apollo.BaseMutationOptions<
  CompanyUserAssignRoleAndAttributesMutation,
  CompanyUserAssignRoleAndAttributesMutationVariables
>;
export const CompanyUserEditRoleAndAttributesDocument = gql`
  mutation CompanyUserEditRoleAndAttributes(
    $input: CompanyUserEditRoleAndAttributesInput
  ) {
    companyUserEditRoleAndAttributes(input: $input) {
      id
      firstName
      lastName
    }
  }
`;
export type CompanyUserEditRoleAndAttributesMutationFn = Apollo.MutationFunction<
  CompanyUserEditRoleAndAttributesMutation,
  CompanyUserEditRoleAndAttributesMutationVariables
>;

/**
 * __useCompanyUserEditRoleAndAttributesMutation__
 *
 * To run a mutation, you first call `useCompanyUserEditRoleAndAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserEditRoleAndAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserEditRoleAndAttributesMutation, { data, loading, error }] = useCompanyUserEditRoleAndAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUserEditRoleAndAttributesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserEditRoleAndAttributesMutation,
    CompanyUserEditRoleAndAttributesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserEditRoleAndAttributesMutation,
    CompanyUserEditRoleAndAttributesMutationVariables
  >(CompanyUserEditRoleAndAttributesDocument, options);
}
export type CompanyUserEditRoleAndAttributesMutationHookResult = ReturnType<
  typeof useCompanyUserEditRoleAndAttributesMutation
>;
export type CompanyUserEditRoleAndAttributesMutationResult = Apollo.MutationResult<CompanyUserEditRoleAndAttributesMutation>;
export type CompanyUserEditRoleAndAttributesMutationOptions = Apollo.BaseMutationOptions<
  CompanyUserEditRoleAndAttributesMutation,
  CompanyUserEditRoleAndAttributesMutationVariables
>;
export const CompanyUserRevokeRoleDocument = gql`
  mutation CompanyUserRevokeRole($companyUserId: ID!, $role: CompanyUserRole) {
    companyUserRevokeRole(companyUserId: $companyUserId, role: $role) {
      id
    }
  }
`;
export type CompanyUserRevokeRoleMutationFn = Apollo.MutationFunction<
  CompanyUserRevokeRoleMutation,
  CompanyUserRevokeRoleMutationVariables
>;

/**
 * __useCompanyUserRevokeRoleMutation__
 *
 * To run a mutation, you first call `useCompanyUserRevokeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserRevokeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserRevokeRoleMutation, { data, loading, error }] = useCompanyUserRevokeRoleMutation({
 *   variables: {
 *      companyUserId: // value for 'companyUserId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCompanyUserRevokeRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserRevokeRoleMutation,
    CompanyUserRevokeRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserRevokeRoleMutation,
    CompanyUserRevokeRoleMutationVariables
  >(CompanyUserRevokeRoleDocument, options);
}
export type CompanyUserRevokeRoleMutationHookResult = ReturnType<
  typeof useCompanyUserRevokeRoleMutation
>;
export type CompanyUserRevokeRoleMutationResult = Apollo.MutationResult<CompanyUserRevokeRoleMutation>;
export type CompanyUserRevokeRoleMutationOptions = Apollo.BaseMutationOptions<
  CompanyUserRevokeRoleMutation,
  CompanyUserRevokeRoleMutationVariables
>;
export const DepartmentCreateDocument = gql`
  mutation DepartmentCreate($input: DepartmentCreateInput!) {
    departmentCreate(input: $input) {
      id
      name
      description
      hod {
        manager {
          id
          companyUser {
            firstName
            lastName
          }
        }
        contract {
          id
          member {
            firstName
            lastName
          }
        }
      }
      employees {
        contracts {
          id
        }
        managers {
          id
        }
      }
    }
  }
`;
export type DepartmentCreateMutationFn = Apollo.MutationFunction<
  DepartmentCreateMutation,
  DepartmentCreateMutationVariables
>;

/**
 * __useDepartmentCreateMutation__
 *
 * To run a mutation, you first call `useDepartmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentCreateMutation, { data, loading, error }] = useDepartmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepartmentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DepartmentCreateMutation,
    DepartmentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DepartmentCreateMutation,
    DepartmentCreateMutationVariables
  >(DepartmentCreateDocument, options);
}
export type DepartmentCreateMutationHookResult = ReturnType<
  typeof useDepartmentCreateMutation
>;
export type DepartmentCreateMutationResult = Apollo.MutationResult<DepartmentCreateMutation>;
export type DepartmentCreateMutationOptions = Apollo.BaseMutationOptions<
  DepartmentCreateMutation,
  DepartmentCreateMutationVariables
>;
export const TimeOffPolicyCreateDocument = gql`
  mutation TimeOffPolicyCreate($input: TimeOffPolicyCreateInput!) {
    timeOffPolicyCreate(input: $input) {
      id
      policyName
      type
      typeId
      assignedEntityNames
      assignedEmployeeCount
      status
      validation {
        ... on TimeOffFixedValidation {
          defaultValue
          unit
          isUnlimitedLeavesAllowed
        }
      }
      configuration {
        carryForward {
          enabled
          maxLimit {
            unit
            value
          }
          expiry {
            unit
            value
          }
        }
      }
    }
  }
`;
export type TimeOffPolicyCreateMutationFn = Apollo.MutationFunction<
  TimeOffPolicyCreateMutation,
  TimeOffPolicyCreateMutationVariables
>;

/**
 * __useTimeOffPolicyCreateMutation__
 *
 * To run a mutation, you first call `useTimeOffPolicyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffPolicyCreateMutation, { data, loading, error }] = useTimeOffPolicyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffPolicyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffPolicyCreateMutation,
    TimeOffPolicyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffPolicyCreateMutation,
    TimeOffPolicyCreateMutationVariables
  >(TimeOffPolicyCreateDocument, options);
}
export type TimeOffPolicyCreateMutationHookResult = ReturnType<
  typeof useTimeOffPolicyCreateMutation
>;
export type TimeOffPolicyCreateMutationResult = Apollo.MutationResult<TimeOffPolicyCreateMutation>;
export type TimeOffPolicyCreateMutationOptions = Apollo.BaseMutationOptions<
  TimeOffPolicyCreateMutation,
  TimeOffPolicyCreateMutationVariables
>;
export const TimeOffTypeCreateDocument = gql`
  mutation TimeOffTypeCreate($input: TimeOffTypeCreateInput!) {
    timeOffTypeCreate(input: $input) {
      description
      isPaidLeave
      label
      status
      type
      typeId
      usage
    }
  }
`;
export type TimeOffTypeCreateMutationFn = Apollo.MutationFunction<
  TimeOffTypeCreateMutation,
  TimeOffTypeCreateMutationVariables
>;

/**
 * __useTimeOffTypeCreateMutation__
 *
 * To run a mutation, you first call `useTimeOffTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffTypeCreateMutation, { data, loading, error }] = useTimeOffTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffTypeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffTypeCreateMutation,
    TimeOffTypeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffTypeCreateMutation,
    TimeOffTypeCreateMutationVariables
  >(TimeOffTypeCreateDocument, options);
}
export type TimeOffTypeCreateMutationHookResult = ReturnType<
  typeof useTimeOffTypeCreateMutation
>;
export type TimeOffTypeCreateMutationResult = Apollo.MutationResult<TimeOffTypeCreateMutation>;
export type TimeOffTypeCreateMutationOptions = Apollo.BaseMutationOptions<
  TimeOffTypeCreateMutation,
  TimeOffTypeCreateMutationVariables
>;
export const DeleteCompanyPayInMethodDocument = gql`
  mutation DeleteCompanyPayInMethod($companyPayInMethodId: ID!) {
    deleteCompanyPayInMethod(companyPayInMethodId: $companyPayInMethodId) {
      success
      message
    }
  }
`;
export type DeleteCompanyPayInMethodMutationFn = Apollo.MutationFunction<
  DeleteCompanyPayInMethodMutation,
  DeleteCompanyPayInMethodMutationVariables
>;

/**
 * __useDeleteCompanyPayInMethodMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyPayInMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyPayInMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyPayInMethodMutation, { data, loading, error }] = useDeleteCompanyPayInMethodMutation({
 *   variables: {
 *      companyPayInMethodId: // value for 'companyPayInMethodId'
 *   },
 * });
 */
export function useDeleteCompanyPayInMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyPayInMethodMutation,
    DeleteCompanyPayInMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCompanyPayInMethodMutation,
    DeleteCompanyPayInMethodMutationVariables
  >(DeleteCompanyPayInMethodDocument, options);
}
export type DeleteCompanyPayInMethodMutationHookResult = ReturnType<
  typeof useDeleteCompanyPayInMethodMutation
>;
export type DeleteCompanyPayInMethodMutationResult = Apollo.MutationResult<DeleteCompanyPayInMethodMutation>;
export type DeleteCompanyPayInMethodMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyPayInMethodMutation,
  DeleteCompanyPayInMethodMutationVariables
>;
export const DepartmentDeleteDocument = gql`
  mutation DepartmentDelete($input: DepartmentDeleteInput!) {
    departmentDelete(input: $input) {
      id
      name
      description
      status
      hod {
        manager {
          id
          companyUser {
            firstName
            lastName
          }
        }
        contract {
          id
          member {
            firstName
            lastName
          }
        }
      }
      employees {
        contracts {
          id
        }
        managers {
          id
        }
      }
    }
  }
`;
export type DepartmentDeleteMutationFn = Apollo.MutationFunction<
  DepartmentDeleteMutation,
  DepartmentDeleteMutationVariables
>;

/**
 * __useDepartmentDeleteMutation__
 *
 * To run a mutation, you first call `useDepartmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentDeleteMutation, { data, loading, error }] = useDepartmentDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepartmentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DepartmentDeleteMutation,
    DepartmentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DepartmentDeleteMutation,
    DepartmentDeleteMutationVariables
  >(DepartmentDeleteDocument, options);
}
export type DepartmentDeleteMutationHookResult = ReturnType<
  typeof useDepartmentDeleteMutation
>;
export type DepartmentDeleteMutationResult = Apollo.MutationResult<DepartmentDeleteMutation>;
export type DepartmentDeleteMutationOptions = Apollo.BaseMutationOptions<
  DepartmentDeleteMutation,
  DepartmentDeleteMutationVariables
>;
export const TimeOffPolicyDeleteDocument = gql`
  mutation TimeOffPolicyDelete($policyId: ID!) {
    timeOffPolicyDelete(policyId: $policyId) {
      id
      policyName
      type
      typeId
      assignedEntityNames
      assignedEmployeeCount
      status
      validation {
        ... on TimeOffFixedValidation {
          defaultValue
          unit
          isUnlimitedLeavesAllowed
        }
      }
      configuration {
        carryForward {
          enabled
          maxLimit {
            unit
            value
          }
          expiry {
            unit
            value
          }
        }
      }
    }
  }
`;
export type TimeOffPolicyDeleteMutationFn = Apollo.MutationFunction<
  TimeOffPolicyDeleteMutation,
  TimeOffPolicyDeleteMutationVariables
>;

/**
 * __useTimeOffPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useTimeOffPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffPolicyDeleteMutation, { data, loading, error }] = useTimeOffPolicyDeleteMutation({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useTimeOffPolicyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffPolicyDeleteMutation,
    TimeOffPolicyDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffPolicyDeleteMutation,
    TimeOffPolicyDeleteMutationVariables
  >(TimeOffPolicyDeleteDocument, options);
}
export type TimeOffPolicyDeleteMutationHookResult = ReturnType<
  typeof useTimeOffPolicyDeleteMutation
>;
export type TimeOffPolicyDeleteMutationResult = Apollo.MutationResult<TimeOffPolicyDeleteMutation>;
export type TimeOffPolicyDeleteMutationOptions = Apollo.BaseMutationOptions<
  TimeOffPolicyDeleteMutation,
  TimeOffPolicyDeleteMutationVariables
>;
export const TimeOffTypeDeleteDocument = gql`
  mutation TimeOffTypeDelete($timeOffTypeDeleteId: ID!) {
    timeOffTypeDelete(id: $timeOffTypeDeleteId) {
      description
      label
      status
      type
      typeId
      usage
    }
  }
`;
export type TimeOffTypeDeleteMutationFn = Apollo.MutationFunction<
  TimeOffTypeDeleteMutation,
  TimeOffTypeDeleteMutationVariables
>;

/**
 * __useTimeOffTypeDeleteMutation__
 *
 * To run a mutation, you first call `useTimeOffTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffTypeDeleteMutation, { data, loading, error }] = useTimeOffTypeDeleteMutation({
 *   variables: {
 *      timeOffTypeDeleteId: // value for 'timeOffTypeDeleteId'
 *   },
 * });
 */
export function useTimeOffTypeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffTypeDeleteMutation,
    TimeOffTypeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffTypeDeleteMutation,
    TimeOffTypeDeleteMutationVariables
  >(TimeOffTypeDeleteDocument, options);
}
export type TimeOffTypeDeleteMutationHookResult = ReturnType<
  typeof useTimeOffTypeDeleteMutation
>;
export type TimeOffTypeDeleteMutationResult = Apollo.MutationResult<TimeOffTypeDeleteMutation>;
export type TimeOffTypeDeleteMutationOptions = Apollo.BaseMutationOptions<
  TimeOffTypeDeleteMutation,
  TimeOffTypeDeleteMutationVariables
>;
export const GetApproverDocument = gql`
  query GetApprover($id: ID) {
    company {
      id
      approvers(userId: $id) {
        id
        categoryContracts {
          category
          managedContracts {
            id
            position
            country
            member {
              id
              firstName
              lastName
              emails {
                email
              }
            }
          }
        }
        approverUser {
          ...companyUser
          ... on Member {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on OperationsUser {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on EmergencyPointOfContact {
            id
            firstName
            lastName
            emails {
              email
            }
          }
        }
      }
    }
  }
  ${CompanyUserFragmentDoc}
`;

/**
 * __useGetApproverQuery__
 *
 * To run a query within a React component, call `useGetApproverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApproverQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApproverQuery,
    GetApproverQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApproverQuery, GetApproverQueryVariables>(
    GetApproverDocument,
    options,
  );
}
export function useGetApproverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApproverQuery,
    GetApproverQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApproverQuery, GetApproverQueryVariables>(
    GetApproverDocument,
    options,
  );
}
export type GetApproverQueryHookResult = ReturnType<typeof useGetApproverQuery>;
export type GetApproverLazyQueryHookResult = ReturnType<
  typeof useGetApproverLazyQuery
>;
export type GetApproverQueryResult = Apollo.QueryResult<
  GetApproverQuery,
  GetApproverQueryVariables
>;
export const GetCompanyActiveContractsForApprovalDocument = gql`
  query GetCompanyActiveContractsForApproval {
    company {
      id
      contracts {
        id
        country
        countryStateCode
        currency
        categoryApproverInfos {
          category
          approverUsers {
            ... on CompanyUser {
              id
              userId
              emails {
                email
              }
              lastName
              firstName
            }
          }
        }
        orgAttributes {
          department {
            name
          }
        }
        member {
          id
          firstName
          lastName
          emails {
            email
          }
          userId
        }
        type
        term
        position
        status
        startOn
      }
    }
  }
`;

/**
 * __useGetCompanyActiveContractsForApprovalQuery__
 *
 * To run a query within a React component, call `useGetCompanyActiveContractsForApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyActiveContractsForApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyActiveContractsForApprovalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyActiveContractsForApprovalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyActiveContractsForApprovalQuery,
    GetCompanyActiveContractsForApprovalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyActiveContractsForApprovalQuery,
    GetCompanyActiveContractsForApprovalQueryVariables
  >(GetCompanyActiveContractsForApprovalDocument, options);
}
export function useGetCompanyActiveContractsForApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyActiveContractsForApprovalQuery,
    GetCompanyActiveContractsForApprovalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyActiveContractsForApprovalQuery,
    GetCompanyActiveContractsForApprovalQueryVariables
  >(GetCompanyActiveContractsForApprovalDocument, options);
}
export type GetCompanyActiveContractsForApprovalQueryHookResult = ReturnType<
  typeof useGetCompanyActiveContractsForApprovalQuery
>;
export type GetCompanyActiveContractsForApprovalLazyQueryHookResult = ReturnType<
  typeof useGetCompanyActiveContractsForApprovalLazyQuery
>;
export type GetCompanyActiveContractsForApprovalQueryResult = Apollo.QueryResult<
  GetCompanyActiveContractsForApprovalQuery,
  GetCompanyActiveContractsForApprovalQueryVariables
>;
export const GetCompanyAutoDebitPreferenceDocument = gql`
  query GetCompanyAutoDebitPreference {
    company {
      id
      payInSettings {
        payInPreferences {
          id
          isPreferenceActive
          preferenceType
          ... on CompanyPayInPreferenceAutoDebit {
            isAutoDebitEnabled
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyAutoDebitPreferenceQuery__
 *
 * To run a query within a React component, call `useGetCompanyAutoDebitPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAutoDebitPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAutoDebitPreferenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyAutoDebitPreferenceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyAutoDebitPreferenceQuery,
    GetCompanyAutoDebitPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyAutoDebitPreferenceQuery,
    GetCompanyAutoDebitPreferenceQueryVariables
  >(GetCompanyAutoDebitPreferenceDocument, options);
}
export function useGetCompanyAutoDebitPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyAutoDebitPreferenceQuery,
    GetCompanyAutoDebitPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyAutoDebitPreferenceQuery,
    GetCompanyAutoDebitPreferenceQueryVariables
  >(GetCompanyAutoDebitPreferenceDocument, options);
}
export type GetCompanyAutoDebitPreferenceQueryHookResult = ReturnType<
  typeof useGetCompanyAutoDebitPreferenceQuery
>;
export type GetCompanyAutoDebitPreferenceLazyQueryHookResult = ReturnType<
  typeof useGetCompanyAutoDebitPreferenceLazyQuery
>;
export type GetCompanyAutoDebitPreferenceQueryResult = Apollo.QueryResult<
  GetCompanyAutoDebitPreferenceQuery,
  GetCompanyAutoDebitPreferenceQueryVariables
>;
export const GetCompanyCountryDocument = gql`
  query GetCompanyCountry {
    company {
      id
      primaryEntity {
        id
        address {
          country
        }
      }
    }
  }
`;

/**
 * __useGetCompanyCountryQuery__
 *
 * To run a query within a React component, call `useGetCompanyCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCountryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCountryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyCountryQuery,
    GetCompanyCountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyCountryQuery,
    GetCompanyCountryQueryVariables
  >(GetCompanyCountryDocument, options);
}
export function useGetCompanyCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCountryQuery,
    GetCompanyCountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyCountryQuery,
    GetCompanyCountryQueryVariables
  >(GetCompanyCountryDocument, options);
}
export type GetCompanyCountryQueryHookResult = ReturnType<
  typeof useGetCompanyCountryQuery
>;
export type GetCompanyCountryLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCountryLazyQuery
>;
export type GetCompanyCountryQueryResult = Apollo.QueryResult<
  GetCompanyCountryQuery,
  GetCompanyCountryQueryVariables
>;
export const GetCompanyEntitiesDocument = gql`
  query GetCompanyEntities($id: ID) {
    company(id: $id) {
      id
      primaryEntity {
        ...legalEntity
      }
      otherEntities {
        ...legalEntity
      }
    }
  }
  ${LegalEntityFragmentDoc}
`;

/**
 * __useGetCompanyEntitiesQuery__
 *
 * To run a query within a React component, call `useGetCompanyEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyEntitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyEntitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >(GetCompanyEntitiesDocument, options);
}
export function useGetCompanyEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyEntitiesQuery,
    GetCompanyEntitiesQueryVariables
  >(GetCompanyEntitiesDocument, options);
}
export type GetCompanyEntitiesQueryHookResult = ReturnType<
  typeof useGetCompanyEntitiesQuery
>;
export type GetCompanyEntitiesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyEntitiesLazyQuery
>;
export type GetCompanyEntitiesQueryResult = Apollo.QueryResult<
  GetCompanyEntitiesQuery,
  GetCompanyEntitiesQueryVariables
>;
export const GetCompanyMsaDocument = gql`
  query GetCompanyMsa {
    company {
      id
      msa {
        id
        name
        extension
        contentType
        link
        blob
      }
    }
  }
`;

/**
 * __useGetCompanyMsaQuery__
 *
 * To run a query within a React component, call `useGetCompanyMsaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMsaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMsaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyMsaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyMsaQuery,
    GetCompanyMsaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyMsaQuery, GetCompanyMsaQueryVariables>(
    GetCompanyMsaDocument,
    options,
  );
}
export function useGetCompanyMsaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMsaQuery,
    GetCompanyMsaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyMsaQuery, GetCompanyMsaQueryVariables>(
    GetCompanyMsaDocument,
    options,
  );
}
export type GetCompanyMsaQueryHookResult = ReturnType<
  typeof useGetCompanyMsaQuery
>;
export type GetCompanyMsaLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMsaLazyQuery
>;
export type GetCompanyMsaQueryResult = Apollo.QueryResult<
  GetCompanyMsaQuery,
  GetCompanyMsaQueryVariables
>;
export const GetCompanyOfferingsDocument = gql`
  query GetCompanyOfferings {
    company {
      id
      offerings {
        offering
        status
      }
    }
  }
`;

/**
 * __useGetCompanyOfferingsQuery__
 *
 * To run a query within a React component, call `useGetCompanyOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOfferingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyOfferingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >(GetCompanyOfferingsDocument, options);
}
export function useGetCompanyOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyOfferingsQuery,
    GetCompanyOfferingsQueryVariables
  >(GetCompanyOfferingsDocument, options);
}
export type GetCompanyOfferingsQueryHookResult = ReturnType<
  typeof useGetCompanyOfferingsQuery
>;
export type GetCompanyOfferingsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyOfferingsLazyQuery
>;
export type GetCompanyOfferingsQueryResult = Apollo.QueryResult<
  GetCompanyOfferingsQuery,
  GetCompanyOfferingsQueryVariables
>;
export const GetCompanyPayInPreferencesDocument = gql`
  query GetCompanyPayInPreferences {
    company {
      id
      payInSettings {
        payInPreferences {
          id
          isPreferenceActive
          preferenceType
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayInPreferencesQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayInPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayInPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayInPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPayInPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPayInPreferencesQuery,
    GetCompanyPayInPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayInPreferencesQuery,
    GetCompanyPayInPreferencesQueryVariables
  >(GetCompanyPayInPreferencesDocument, options);
}
export function useGetCompanyPayInPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayInPreferencesQuery,
    GetCompanyPayInPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayInPreferencesQuery,
    GetCompanyPayInPreferencesQueryVariables
  >(GetCompanyPayInPreferencesDocument, options);
}
export type GetCompanyPayInPreferencesQueryHookResult = ReturnType<
  typeof useGetCompanyPayInPreferencesQuery
>;
export type GetCompanyPayInPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayInPreferencesLazyQuery
>;
export type GetCompanyPayInPreferencesQueryResult = Apollo.QueryResult<
  GetCompanyPayInPreferencesQuery,
  GetCompanyPayInPreferencesQueryVariables
>;
export const GetCompanyPayInSettingsDocument = gql`
  query GetCompanyPayInSettings {
    company {
      id
      payInSettings {
        payInMethodRequirements {
          id
          partner
          payInMethodType
          definition {
            step
            requirement {
              payInAccountRequirementType
              fields {
                key
                label
                isMandatory
                type
                regex
                allowedValues {
                  key
                  value
                }
                hasSubFields
                errorMessage
              }
            }
          }
        }
        payInMethods(filters: {}) {
          id
          payInMethodType
          isActive
          isEnabled
          currentStep
          currentStatus
          workflowSteps {
            step
            status
            updatedOn
          }
          bankDetails {
            payInAccountRequirementType
            data {
              key
              value
            }
          }
          data {
            key
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayInSettingsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayInSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayInSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayInSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPayInSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPayInSettingsQuery,
    GetCompanyPayInSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayInSettingsQuery,
    GetCompanyPayInSettingsQueryVariables
  >(GetCompanyPayInSettingsDocument, options);
}
export function useGetCompanyPayInSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayInSettingsQuery,
    GetCompanyPayInSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayInSettingsQuery,
    GetCompanyPayInSettingsQueryVariables
  >(GetCompanyPayInSettingsDocument, options);
}
export type GetCompanyPayInSettingsQueryHookResult = ReturnType<
  typeof useGetCompanyPayInSettingsQuery
>;
export type GetCompanyPayInSettingsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayInSettingsLazyQuery
>;
export type GetCompanyPayInSettingsQueryResult = Apollo.QueryResult<
  GetCompanyPayInSettingsQuery,
  GetCompanyPayInSettingsQueryVariables
>;
export const GetCompanyPricingInfoDocument = gql`
  query GetCompanyPricingInfo {
    company {
      id
      pricing {
        id
        billingCurrencyCode
      }
    }
  }
`;

/**
 * __useGetCompanyPricingInfoQuery__
 *
 * To run a query within a React component, call `useGetCompanyPricingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPricingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPricingInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPricingInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPricingInfoQuery,
    GetCompanyPricingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPricingInfoQuery,
    GetCompanyPricingInfoQueryVariables
  >(GetCompanyPricingInfoDocument, options);
}
export function useGetCompanyPricingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPricingInfoQuery,
    GetCompanyPricingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPricingInfoQuery,
    GetCompanyPricingInfoQueryVariables
  >(GetCompanyPricingInfoDocument, options);
}
export type GetCompanyPricingInfoQueryHookResult = ReturnType<
  typeof useGetCompanyPricingInfoQuery
>;
export type GetCompanyPricingInfoLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPricingInfoLazyQuery
>;
export type GetCompanyPricingInfoQueryResult = Apollo.QueryResult<
  GetCompanyPricingInfoQuery,
  GetCompanyPricingInfoQueryVariables
>;
export const GetCompanyTimeOffPoliciesDocument = gql`
  query GetCompanyTimeOffPolicies($filter: CompanyTimeOffPolicyFilter) {
    company {
      id
      timeOffPolicies(filter: $filter) {
        id
        policyName
        type
        typeId
        assignedEntityNames
        assignedEmployeeCount
        status
        label
        validation {
          ... on TimeOffFixedValidation {
            defaultValue
            unit
            isUnlimitedLeavesAllowed
          }
        }
        configuration {
          carryForward {
            enabled
            maxLimit {
              unit
              value
            }
            expiry {
              unit
              value
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyTimeOffPoliciesQuery__
 *
 * To run a query within a React component, call `useGetCompanyTimeOffPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTimeOffPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTimeOffPoliciesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompanyTimeOffPoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyTimeOffPoliciesQuery,
    GetCompanyTimeOffPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyTimeOffPoliciesQuery,
    GetCompanyTimeOffPoliciesQueryVariables
  >(GetCompanyTimeOffPoliciesDocument, options);
}
export function useGetCompanyTimeOffPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyTimeOffPoliciesQuery,
    GetCompanyTimeOffPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyTimeOffPoliciesQuery,
    GetCompanyTimeOffPoliciesQueryVariables
  >(GetCompanyTimeOffPoliciesDocument, options);
}
export type GetCompanyTimeOffPoliciesQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffPoliciesQuery
>;
export type GetCompanyTimeOffPoliciesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffPoliciesLazyQuery
>;
export type GetCompanyTimeOffPoliciesQueryResult = Apollo.QueryResult<
  GetCompanyTimeOffPoliciesQuery,
  GetCompanyTimeOffPoliciesQueryVariables
>;
export const GetCompanyTimeOffTypesDocument = gql`
  query GetCompanyTimeOffTypes($filter: TimeOffTypeFilter) {
    company {
      id
      timeOffTypeInfos(filter: $filter) {
        description
        label
        status
        type
        typeId
        isPaidLeave
        usage
        companyId
        createdOn
      }
    }
  }
`;

/**
 * __useGetCompanyTimeOffTypesQuery__
 *
 * To run a query within a React component, call `useGetCompanyTimeOffTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTimeOffTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTimeOffTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompanyTimeOffTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyTimeOffTypesQuery,
    GetCompanyTimeOffTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyTimeOffTypesQuery,
    GetCompanyTimeOffTypesQueryVariables
  >(GetCompanyTimeOffTypesDocument, options);
}
export function useGetCompanyTimeOffTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyTimeOffTypesQuery,
    GetCompanyTimeOffTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyTimeOffTypesQuery,
    GetCompanyTimeOffTypesQueryVariables
  >(GetCompanyTimeOffTypesDocument, options);
}
export type GetCompanyTimeOffTypesQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffTypesQuery
>;
export type GetCompanyTimeOffTypesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffTypesLazyQuery
>;
export type GetCompanyTimeOffTypesQueryResult = Apollo.QueryResult<
  GetCompanyTimeOffTypesQuery,
  GetCompanyTimeOffTypesQueryVariables
>;
export const GetCompanyDocument = gql`
  query GetCompany {
    company {
      id
      displayName
      companyLogo {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
          blob
        }
      }
      status
      financialYear
      primaryEntity {
        id
        legalName
        registrationNo
        address {
          line1
          line2
          city
          zipcode
          country
        }
        phone
      }
      contracts(status: null) {
        id
      }
      managers {
        ...companyUser
      }
      signatories {
        ...companyUser
      }
      billingContact {
        ...companyUser
      }
      approvers {
        id
        categoryContracts {
          category
          managedContracts {
            id
          }
        }
        approverUser {
          ...companyUser
          ... on Member {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on OperationsUser {
            id
            firstName
            lastName
            emails {
              email
            }
          }
          ... on EmergencyPointOfContact {
            id
            firstName
            lastName
            emails {
              email
            }
          }
        }
      }
      offerings {
        offering
        status
      }
    }
  }
  ${CompanyUserFragmentDoc}
`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyQuery,
    GetCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options,
  );
}
export function useGetCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyQuery,
    GetCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options,
  );
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<
  typeof useGetCompanyLazyQuery
>;
export type GetCompanyQueryResult = Apollo.QueryResult<
  GetCompanyQuery,
  GetCompanyQueryVariables
>;
export const GetDepartmentDetailsDocument = gql`
  query GetDepartmentDetails($filters: DepartmentFilters!) {
    departments(filters: $filters) {
      id
      name
      description
      hod {
        manager {
          id
          companyUser {
            firstName
            lastName
            userId
          }
        }
        contract {
          id
          member {
            firstName
            lastName
            userId
          }
        }
      }
      employees {
        contracts {
          id
          country
          type
          member {
            id
            userId
            gender
            firstName
            lastName
            emails {
              email
            }
            phoneNos {
              phoneNo
            }
          }
          position
          workEmail
          reportsToManager {
            id
            companyUser {
              userId
            }
            contract {
              member {
                userId
              }
            }
          }
        }
        managers {
          id
          contract {
            id
            country
            type
            member {
              id
              userId
              gender
              firstName
              lastName
              emails {
                email
              }
              phoneNos {
                phoneNo
              }
            }
            position
            workEmail
          }
          companyUser {
            userId
            firstName
            lastName
            title
            emails {
              email
            }
            phoneNos {
              phoneNo
            }
          }
          reportsToManager {
            id
            companyUser {
              userId
            }
            contract {
              member {
                userId
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetDepartmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentDetailsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDepartmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDepartmentDetailsQuery,
    GetDepartmentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDepartmentDetailsQuery,
    GetDepartmentDetailsQueryVariables
  >(GetDepartmentDetailsDocument, options);
}
export function useGetDepartmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepartmentDetailsQuery,
    GetDepartmentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDepartmentDetailsQuery,
    GetDepartmentDetailsQueryVariables
  >(GetDepartmentDetailsDocument, options);
}
export type GetDepartmentDetailsQueryHookResult = ReturnType<
  typeof useGetDepartmentDetailsQuery
>;
export type GetDepartmentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentDetailsLazyQuery
>;
export type GetDepartmentDetailsQueryResult = Apollo.QueryResult<
  GetDepartmentDetailsQuery,
  GetDepartmentDetailsQueryVariables
>;
export const GetDepartmentsDocument = gql`
  query GetDepartments($filters: DepartmentFilters) {
    departments(filters: $filters) {
      id
      name
      description
      hod {
        manager {
          id
          companyUser {
            userId
            firstName
            lastName
          }
        }
        contract {
          id
          member {
            userId
            firstName
            lastName
          }
        }
      }
      employees {
        contracts {
          id
        }
        managers {
          id
        }
      }
    }
  }
`;

/**
 * __useGetDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDepartmentsQuery, GetDepartmentsQueryVariables>(
    GetDepartmentsDocument,
    options,
  );
}
export function useGetDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDepartmentsQuery, GetDepartmentsQueryVariables>(
    GetDepartmentsDocument,
    options,
  );
}
export type GetDepartmentsQueryHookResult = ReturnType<
  typeof useGetDepartmentsQuery
>;
export type GetDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentsLazyQuery
>;
export type GetDepartmentsQueryResult = Apollo.QueryResult<
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables
>;
export const GetSepaIbanDetailsDocument = gql`
  query GetSepaIbanDetails($iban: String) {
    getSEPAIBANDetailsFieldInformation(iban: $iban) {
      data {
        key
        value
      }
    }
  }
`;

/**
 * __useGetSepaIbanDetailsQuery__
 *
 * To run a query within a React component, call `useGetSepaIbanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSepaIbanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSepaIbanDetailsQuery({
 *   variables: {
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useGetSepaIbanDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSepaIbanDetailsQuery,
    GetSepaIbanDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSepaIbanDetailsQuery,
    GetSepaIbanDetailsQueryVariables
  >(GetSepaIbanDetailsDocument, options);
}
export function useGetSepaIbanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSepaIbanDetailsQuery,
    GetSepaIbanDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSepaIbanDetailsQuery,
    GetSepaIbanDetailsQueryVariables
  >(GetSepaIbanDetailsDocument, options);
}
export type GetSepaIbanDetailsQueryHookResult = ReturnType<
  typeof useGetSepaIbanDetailsQuery
>;
export type GetSepaIbanDetailsLazyQueryHookResult = ReturnType<
  typeof useGetSepaIbanDetailsLazyQuery
>;
export type GetSepaIbanDetailsQueryResult = Apollo.QueryResult<
  GetSepaIbanDetailsQuery,
  GetSepaIbanDetailsQueryVariables
>;
export const GetTimeOffPolicyAssignmentDetailsDocument = gql`
  query GetTimeOffPolicyAssignmentDetails($policyId: ID!) {
    timeOffPolicyAssignmentDetails(policyId: $policyId) {
      assignedContracts {
        id
        member {
          id
          firstName
          lastName
        }
        position
      }
      rule {
        conditions {
          key
          operator
          values
        }
        type
      }
    }
  }
`;

/**
 * __useGetTimeOffPolicyAssignmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetTimeOffPolicyAssignmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffPolicyAssignmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffPolicyAssignmentDetailsQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetTimeOffPolicyAssignmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTimeOffPolicyAssignmentDetailsQuery,
    GetTimeOffPolicyAssignmentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTimeOffPolicyAssignmentDetailsQuery,
    GetTimeOffPolicyAssignmentDetailsQueryVariables
  >(GetTimeOffPolicyAssignmentDetailsDocument, options);
}
export function useGetTimeOffPolicyAssignmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeOffPolicyAssignmentDetailsQuery,
    GetTimeOffPolicyAssignmentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTimeOffPolicyAssignmentDetailsQuery,
    GetTimeOffPolicyAssignmentDetailsQueryVariables
  >(GetTimeOffPolicyAssignmentDetailsDocument, options);
}
export type GetTimeOffPolicyAssignmentDetailsQueryHookResult = ReturnType<
  typeof useGetTimeOffPolicyAssignmentDetailsQuery
>;
export type GetTimeOffPolicyAssignmentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetTimeOffPolicyAssignmentDetailsLazyQuery
>;
export type GetTimeOffPolicyAssignmentDetailsQueryResult = Apollo.QueryResult<
  GetTimeOffPolicyAssignmentDetailsQuery,
  GetTimeOffPolicyAssignmentDetailsQueryVariables
>;
export const RemoveCompanySignatoryDocument = gql`
  mutation RemoveCompanySignatory($companyUserId: ID!) {
    companyRemoveSignatory(companyUserId: $companyUserId) {
      id
    }
  }
`;
export type RemoveCompanySignatoryMutationFn = Apollo.MutationFunction<
  RemoveCompanySignatoryMutation,
  RemoveCompanySignatoryMutationVariables
>;

/**
 * __useRemoveCompanySignatoryMutation__
 *
 * To run a mutation, you first call `useRemoveCompanySignatoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanySignatoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanySignatoryMutation, { data, loading, error }] = useRemoveCompanySignatoryMutation({
 *   variables: {
 *      companyUserId: // value for 'companyUserId'
 *   },
 * });
 */
export function useRemoveCompanySignatoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCompanySignatoryMutation,
    RemoveCompanySignatoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCompanySignatoryMutation,
    RemoveCompanySignatoryMutationVariables
  >(RemoveCompanySignatoryDocument, options);
}
export type RemoveCompanySignatoryMutationHookResult = ReturnType<
  typeof useRemoveCompanySignatoryMutation
>;
export type RemoveCompanySignatoryMutationResult = Apollo.MutationResult<RemoveCompanySignatoryMutation>;
export type RemoveCompanySignatoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveCompanySignatoryMutation,
  RemoveCompanySignatoryMutationVariables
>;
export const RemoveCompanyUserDocument = gql`
  mutation removeCompanyUser($userId: ID!) {
    companyRemoveUser(companyUserId: $userId) {
      id
    }
  }
`;
export type RemoveCompanyUserMutationFn = Apollo.MutationFunction<
  RemoveCompanyUserMutation,
  RemoveCompanyUserMutationVariables
>;

/**
 * __useRemoveCompanyUserMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyUserMutation, { data, loading, error }] = useRemoveCompanyUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCompanyUserMutation,
    RemoveCompanyUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCompanyUserMutation,
    RemoveCompanyUserMutationVariables
  >(RemoveCompanyUserDocument, options);
}
export type RemoveCompanyUserMutationHookResult = ReturnType<
  typeof useRemoveCompanyUserMutation
>;
export type RemoveCompanyUserMutationResult = Apollo.MutationResult<RemoveCompanyUserMutation>;
export type RemoveCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveCompanyUserMutation,
  RemoveCompanyUserMutationVariables
>;
export const ResendApproverInviteDocument = gql`
  mutation ResendApproverInvite($companyUserId: ID!) {
    companyUserResendApproverInvite(companyUserId: $companyUserId) {
      id
    }
  }
`;
export type ResendApproverInviteMutationFn = Apollo.MutationFunction<
  ResendApproverInviteMutation,
  ResendApproverInviteMutationVariables
>;

/**
 * __useResendApproverInviteMutation__
 *
 * To run a mutation, you first call `useResendApproverInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendApproverInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendApproverInviteMutation, { data, loading, error }] = useResendApproverInviteMutation({
 *   variables: {
 *      companyUserId: // value for 'companyUserId'
 *   },
 * });
 */
export function useResendApproverInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendApproverInviteMutation,
    ResendApproverInviteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendApproverInviteMutation,
    ResendApproverInviteMutationVariables
  >(ResendApproverInviteDocument, options);
}
export type ResendApproverInviteMutationHookResult = ReturnType<
  typeof useResendApproverInviteMutation
>;
export type ResendApproverInviteMutationResult = Apollo.MutationResult<ResendApproverInviteMutation>;
export type ResendApproverInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendApproverInviteMutation,
  ResendApproverInviteMutationVariables
>;
export const RevokeApproverRoleDocument = gql`
  mutation RevokeApproverRole($companyUserId: ID!) {
    companyUserRevokeApproverRole(companyUserId: $companyUserId) {
      id
    }
  }
`;
export type RevokeApproverRoleMutationFn = Apollo.MutationFunction<
  RevokeApproverRoleMutation,
  RevokeApproverRoleMutationVariables
>;

/**
 * __useRevokeApproverRoleMutation__
 *
 * To run a mutation, you first call `useRevokeApproverRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeApproverRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeApproverRoleMutation, { data, loading, error }] = useRevokeApproverRoleMutation({
 *   variables: {
 *      companyUserId: // value for 'companyUserId'
 *   },
 * });
 */
export function useRevokeApproverRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeApproverRoleMutation,
    RevokeApproverRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeApproverRoleMutation,
    RevokeApproverRoleMutationVariables
  >(RevokeApproverRoleDocument, options);
}
export type RevokeApproverRoleMutationHookResult = ReturnType<
  typeof useRevokeApproverRoleMutation
>;
export type RevokeApproverRoleMutationResult = Apollo.MutationResult<RevokeApproverRoleMutation>;
export type RevokeApproverRoleMutationOptions = Apollo.BaseMutationOptions<
  RevokeApproverRoleMutation,
  RevokeApproverRoleMutationVariables
>;
export const SetupCompanyPayInMethodDocument = gql`
  mutation SetupCompanyPayInMethod($input: CompanyPayInMethodInput!) {
    upsertCompanyPayInMethodBankDetails(input: $input) {
      id
      payInMethodType
      isActive
      isEnabled
      currentStep
      currentStatus
      workflowSteps {
        step
        status
        updatedOn
      }
      bankDetails {
        payInAccountRequirementType
        data {
          key
          value
        }
      }
      data {
        key
        value
      }
    }
  }
`;
export type SetupCompanyPayInMethodMutationFn = Apollo.MutationFunction<
  SetupCompanyPayInMethodMutation,
  SetupCompanyPayInMethodMutationVariables
>;

/**
 * __useSetupCompanyPayInMethodMutation__
 *
 * To run a mutation, you first call `useSetupCompanyPayInMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupCompanyPayInMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupCompanyPayInMethodMutation, { data, loading, error }] = useSetupCompanyPayInMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupCompanyPayInMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupCompanyPayInMethodMutation,
    SetupCompanyPayInMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetupCompanyPayInMethodMutation,
    SetupCompanyPayInMethodMutationVariables
  >(SetupCompanyPayInMethodDocument, options);
}
export type SetupCompanyPayInMethodMutationHookResult = ReturnType<
  typeof useSetupCompanyPayInMethodMutation
>;
export type SetupCompanyPayInMethodMutationResult = Apollo.MutationResult<SetupCompanyPayInMethodMutation>;
export type SetupCompanyPayInMethodMutationOptions = Apollo.BaseMutationOptions<
  SetupCompanyPayInMethodMutation,
  SetupCompanyPayInMethodMutationVariables
>;
export const ToggleAutoDebitDocument = gql`
  mutation ToggleAutoDebit($companyId: ID!, $status: Boolean!) {
    toggleAutoDebit(companyId: $companyId, status: $status) {
      id
      isAutoDebitEnabled
    }
  }
`;
export type ToggleAutoDebitMutationFn = Apollo.MutationFunction<
  ToggleAutoDebitMutation,
  ToggleAutoDebitMutationVariables
>;

/**
 * __useToggleAutoDebitMutation__
 *
 * To run a mutation, you first call `useToggleAutoDebitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAutoDebitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAutoDebitMutation, { data, loading, error }] = useToggleAutoDebitMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleAutoDebitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleAutoDebitMutation,
    ToggleAutoDebitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleAutoDebitMutation,
    ToggleAutoDebitMutationVariables
  >(ToggleAutoDebitDocument, options);
}
export type ToggleAutoDebitMutationHookResult = ReturnType<
  typeof useToggleAutoDebitMutation
>;
export type ToggleAutoDebitMutationResult = Apollo.MutationResult<ToggleAutoDebitMutation>;
export type ToggleAutoDebitMutationOptions = Apollo.BaseMutationOptions<
  ToggleAutoDebitMutation,
  ToggleAutoDebitMutationVariables
>;
export const UpdateApproverDocument = gql`
  mutation UpdateApprover($input: UpdateApproverInfoInput!) {
    companyUserUpdateApproverInfo(input: $input) {
      id
      firstName
      lastName
      emails {
        email
      }
      title
      role
      isSignatory
      isBillingContact
      userId
      status
      approver {
        id
        categoryContracts {
          category
          managedContracts {
            id
          }
        }
      }
    }
  }
`;
export type UpdateApproverMutationFn = Apollo.MutationFunction<
  UpdateApproverMutation,
  UpdateApproverMutationVariables
>;

/**
 * __useUpdateApproverMutation__
 *
 * To run a mutation, you first call `useUpdateApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApproverMutation, { data, loading, error }] = useUpdateApproverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApproverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApproverMutation,
    UpdateApproverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApproverMutation,
    UpdateApproverMutationVariables
  >(UpdateApproverDocument, options);
}
export type UpdateApproverMutationHookResult = ReturnType<
  typeof useUpdateApproverMutation
>;
export type UpdateApproverMutationResult = Apollo.MutationResult<UpdateApproverMutation>;
export type UpdateApproverMutationOptions = Apollo.BaseMutationOptions<
  UpdateApproverMutation,
  UpdateApproverMutationVariables
>;
export const UpdateCompanyUserDocument = gql`
  mutation updateCompanyUser($user: UpdateUserInput) {
    companyUpdateUser(user: $user) {
      id
      firstName
      lastName
      emails {
        email
      }
      title
      role
      isSignatory
      isBillingContact
      userId
      status
    }
  }
`;
export type UpdateCompanyUserMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserMutation,
  UpdateCompanyUserMutationVariables
>;

/**
 * __useUpdateCompanyUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserMutation, { data, loading, error }] = useUpdateCompanyUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyUserMutation,
    UpdateCompanyUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyUserMutation,
    UpdateCompanyUserMutationVariables
  >(UpdateCompanyUserDocument, options);
}
export type UpdateCompanyUserMutationHookResult = ReturnType<
  typeof useUpdateCompanyUserMutation
>;
export type UpdateCompanyUserMutationResult = Apollo.MutationResult<UpdateCompanyUserMutation>;
export type UpdateCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserMutation,
  UpdateCompanyUserMutationVariables
>;
export const CompanyUpdateDocument = gql`
  mutation companyUpdate($company: CompanyUpdateInput!, $companyLogo: Upload) {
    companyUpdate(company: $company, companyLogo: $companyLogo) {
      id
      displayName
      companyLogo {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
          blob
        }
      }
      status
      financialYear
      primaryEntity {
        id
        legalName
        registrationNo
        address {
          line1
          line2
          zipcode
          country
        }
        phone
      }
    }
  }
`;
export type CompanyUpdateMutationFn = Apollo.MutationFunction<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      company: // value for 'company'
 *      companyLogo: // value for 'companyLogo'
 *   },
 * });
 */
export function useCompanyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >(CompanyUpdateDocument, options);
}
export type CompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyUpdateMutation
>;
export type CompanyUpdateMutationResult = Apollo.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;
export const DepartmentUpdateDocument = gql`
  mutation DepartmentUpdate($input: DepartmentUpdateInput!) {
    departmentUpdate(input: $input) {
      id
      name
      description
      hod {
        manager {
          id
        }
        contract {
          id
        }
      }
    }
  }
`;
export type DepartmentUpdateMutationFn = Apollo.MutationFunction<
  DepartmentUpdateMutation,
  DepartmentUpdateMutationVariables
>;

/**
 * __useDepartmentUpdateMutation__
 *
 * To run a mutation, you first call `useDepartmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentUpdateMutation, { data, loading, error }] = useDepartmentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepartmentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DepartmentUpdateMutation,
    DepartmentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DepartmentUpdateMutation,
    DepartmentUpdateMutationVariables
  >(DepartmentUpdateDocument, options);
}
export type DepartmentUpdateMutationHookResult = ReturnType<
  typeof useDepartmentUpdateMutation
>;
export type DepartmentUpdateMutationResult = Apollo.MutationResult<DepartmentUpdateMutation>;
export type DepartmentUpdateMutationOptions = Apollo.BaseMutationOptions<
  DepartmentUpdateMutation,
  DepartmentUpdateMutationVariables
>;
export const TimeOffPolicyUpdateDocument = gql`
  mutation TimeOffPolicyUpdate($input: TimeOffPolicyUpdateInput!) {
    timeOffPolicyUpdate(input: $input) {
      id
      policyName
      validation {
        ... on TimeOffFixedValidation {
          defaultValue
          unit
          isUnlimitedLeavesAllowed
        }
      }
    }
  }
`;
export type TimeOffPolicyUpdateMutationFn = Apollo.MutationFunction<
  TimeOffPolicyUpdateMutation,
  TimeOffPolicyUpdateMutationVariables
>;

/**
 * __useTimeOffPolicyUpdateMutation__
 *
 * To run a mutation, you first call `useTimeOffPolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffPolicyUpdateMutation, { data, loading, error }] = useTimeOffPolicyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffPolicyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffPolicyUpdateMutation,
    TimeOffPolicyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffPolicyUpdateMutation,
    TimeOffPolicyUpdateMutationVariables
  >(TimeOffPolicyUpdateDocument, options);
}
export type TimeOffPolicyUpdateMutationHookResult = ReturnType<
  typeof useTimeOffPolicyUpdateMutation
>;
export type TimeOffPolicyUpdateMutationResult = Apollo.MutationResult<TimeOffPolicyUpdateMutation>;
export type TimeOffPolicyUpdateMutationOptions = Apollo.BaseMutationOptions<
  TimeOffPolicyUpdateMutation,
  TimeOffPolicyUpdateMutationVariables
>;
export const TimeOffTypeUpdateDocument = gql`
  mutation TimeOffTypeUpdate($input: TimeOffTypeUpdateInput!) {
    timeOffTypeUpdate(input: $input) {
      description
      label
      isPaidLeave
      status
      type
      typeId
      usage
    }
  }
`;
export type TimeOffTypeUpdateMutationFn = Apollo.MutationFunction<
  TimeOffTypeUpdateMutation,
  TimeOffTypeUpdateMutationVariables
>;

/**
 * __useTimeOffTypeUpdateMutation__
 *
 * To run a mutation, you first call `useTimeOffTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffTypeUpdateMutation, { data, loading, error }] = useTimeOffTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffTypeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffTypeUpdateMutation,
    TimeOffTypeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffTypeUpdateMutation,
    TimeOffTypeUpdateMutationVariables
  >(TimeOffTypeUpdateDocument, options);
}
export type TimeOffTypeUpdateMutationHookResult = ReturnType<
  typeof useTimeOffTypeUpdateMutation
>;
export type TimeOffTypeUpdateMutationResult = Apollo.MutationResult<TimeOffTypeUpdateMutation>;
export type TimeOffTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
  TimeOffTypeUpdateMutation,
  TimeOffTypeUpdateMutationVariables
>;
export const TimeOffPolicyValidateUsersDocument = gql`
  mutation TimeOffPolicyValidateUsers(
    $input: TimeOffPolicyValidateUsersInput!
  ) {
    timeOffPolicyValidateUsers(input: $input) {
      invalidContractsToAssign {
        id
        member {
          id
          firstName
          lastName
        }
        position
      }
      invalidContractsToUnassigned {
        id
        member {
          id
          firstName
          lastName
        }
        position
      }
      validContractsToAssign {
        id
        member {
          id
          firstName
          lastName
        }
        position
      }
    }
  }
`;
export type TimeOffPolicyValidateUsersMutationFn = Apollo.MutationFunction<
  TimeOffPolicyValidateUsersMutation,
  TimeOffPolicyValidateUsersMutationVariables
>;

/**
 * __useTimeOffPolicyValidateUsersMutation__
 *
 * To run a mutation, you first call `useTimeOffPolicyValidateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyValidateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffPolicyValidateUsersMutation, { data, loading, error }] = useTimeOffPolicyValidateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffPolicyValidateUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimeOffPolicyValidateUsersMutation,
    TimeOffPolicyValidateUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimeOffPolicyValidateUsersMutation,
    TimeOffPolicyValidateUsersMutationVariables
  >(TimeOffPolicyValidateUsersDocument, options);
}
export type TimeOffPolicyValidateUsersMutationHookResult = ReturnType<
  typeof useTimeOffPolicyValidateUsersMutation
>;
export type TimeOffPolicyValidateUsersMutationResult = Apollo.MutationResult<TimeOffPolicyValidateUsersMutation>;
export type TimeOffPolicyValidateUsersMutationOptions = Apollo.BaseMutationOptions<
  TimeOffPolicyValidateUsersMutation,
  TimeOffPolicyValidateUsersMutationVariables
>;
export const PaySupplementBulkUploadDocument = gql`
  mutation PaySupplementBulkUpload($file: Upload!) {
    paySupplementBulkUpload(file: $file) {
      success
      message
    }
  }
`;
export type PaySupplementBulkUploadMutationFn = Apollo.MutationFunction<
  PaySupplementBulkUploadMutation,
  PaySupplementBulkUploadMutationVariables
>;

/**
 * __usePaySupplementBulkUploadMutation__
 *
 * To run a mutation, you first call `usePaySupplementBulkUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaySupplementBulkUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paySupplementBulkUploadMutation, { data, loading, error }] = usePaySupplementBulkUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function usePaySupplementBulkUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaySupplementBulkUploadMutation,
    PaySupplementBulkUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaySupplementBulkUploadMutation,
    PaySupplementBulkUploadMutationVariables
  >(PaySupplementBulkUploadDocument, options);
}
export type PaySupplementBulkUploadMutationHookResult = ReturnType<
  typeof usePaySupplementBulkUploadMutation
>;
export type PaySupplementBulkUploadMutationResult = Apollo.MutationResult<PaySupplementBulkUploadMutation>;
export type PaySupplementBulkUploadMutationOptions = Apollo.BaseMutationOptions<
  PaySupplementBulkUploadMutation,
  PaySupplementBulkUploadMutationVariables
>;
export const CreatePaySupplementDocument = gql`
  mutation CreatePaySupplement($input: PaySupplementCreateInput) {
    paySupplementCreate(paySupplementData: $input) {
      id
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        company {
          id
        }
      }
      type
      status
      currency
      amount
      amountInFunctionalCurrency
      notes
      description
      createdDate
    }
  }
`;
export type CreatePaySupplementMutationFn = Apollo.MutationFunction<
  CreatePaySupplementMutation,
  CreatePaySupplementMutationVariables
>;

/**
 * __useCreatePaySupplementMutation__
 *
 * To run a mutation, you first call `useCreatePaySupplementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaySupplementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaySupplementMutation, { data, loading, error }] = useCreatePaySupplementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaySupplementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaySupplementMutation,
    CreatePaySupplementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaySupplementMutation,
    CreatePaySupplementMutationVariables
  >(CreatePaySupplementDocument, options);
}
export type CreatePaySupplementMutationHookResult = ReturnType<
  typeof useCreatePaySupplementMutation
>;
export type CreatePaySupplementMutationResult = Apollo.MutationResult<CreatePaySupplementMutation>;
export type CreatePaySupplementMutationOptions = Apollo.BaseMutationOptions<
  CreatePaySupplementMutation,
  CreatePaySupplementMutationVariables
>;
export const DeletePaySupplementDocument = gql`
  mutation DeletePaySupplement($id: ID!) {
    paySupplementRevoke(id: $id) {
      id
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        company {
          id
        }
      }
    }
  }
`;
export type DeletePaySupplementMutationFn = Apollo.MutationFunction<
  DeletePaySupplementMutation,
  DeletePaySupplementMutationVariables
>;

/**
 * __useDeletePaySupplementMutation__
 *
 * To run a mutation, you first call `useDeletePaySupplementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaySupplementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaySupplementMutation, { data, loading, error }] = useDeletePaySupplementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaySupplementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaySupplementMutation,
    DeletePaySupplementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePaySupplementMutation,
    DeletePaySupplementMutationVariables
  >(DeletePaySupplementDocument, options);
}
export type DeletePaySupplementMutationHookResult = ReturnType<
  typeof useDeletePaySupplementMutation
>;
export type DeletePaySupplementMutationResult = Apollo.MutationResult<DeletePaySupplementMutation>;
export type DeletePaySupplementMutationOptions = Apollo.BaseMutationOptions<
  DeletePaySupplementMutation,
  DeletePaySupplementMutationVariables
>;
export const GetPrePopulatedPaySupplementReportDocument = gql`
  query GetPrePopulatedPaySupplementReport($id: ID!) {
    companyPrePopulatedPaySupplementReport(id: $id) {
      id
      name
      link
      blob
      extension
      contentType
    }
  }
`;

/**
 * __useGetPrePopulatedPaySupplementReportQuery__
 *
 * To run a query within a React component, call `useGetPrePopulatedPaySupplementReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrePopulatedPaySupplementReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrePopulatedPaySupplementReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrePopulatedPaySupplementReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrePopulatedPaySupplementReportQuery,
    GetPrePopulatedPaySupplementReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPrePopulatedPaySupplementReportQuery,
    GetPrePopulatedPaySupplementReportQueryVariables
  >(GetPrePopulatedPaySupplementReportDocument, options);
}
export function useGetPrePopulatedPaySupplementReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrePopulatedPaySupplementReportQuery,
    GetPrePopulatedPaySupplementReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPrePopulatedPaySupplementReportQuery,
    GetPrePopulatedPaySupplementReportQueryVariables
  >(GetPrePopulatedPaySupplementReportDocument, options);
}
export type GetPrePopulatedPaySupplementReportQueryHookResult = ReturnType<
  typeof useGetPrePopulatedPaySupplementReportQuery
>;
export type GetPrePopulatedPaySupplementReportLazyQueryHookResult = ReturnType<
  typeof useGetPrePopulatedPaySupplementReportLazyQuery
>;
export type GetPrePopulatedPaySupplementReportQueryResult = Apollo.QueryResult<
  GetPrePopulatedPaySupplementReportQuery,
  GetPrePopulatedPaySupplementReportQueryVariables
>;
export const GetCompanyActiveContractsDocument = gql`
  query GetCompanyActiveContracts(
    $category: ApprovalCategory
    $companyContractsFilter: CompanyContractsFilter
  ) {
    company {
      id
      contracts(
        category: $category
        companyContractsFilter: $companyContractsFilter
      ) {
        id
        country
        countryStateCode
        currency
        member {
          id
          firstName
          lastName
          emails {
            email
          }
        }
        type
        term
        position
        status
        startOn
        company {
          id
        }
        contractStarted
      }
    }
  }
`;

/**
 * __useGetCompanyActiveContractsQuery__
 *
 * To run a query within a React component, call `useGetCompanyActiveContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyActiveContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyActiveContractsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      companyContractsFilter: // value for 'companyContractsFilter'
 *   },
 * });
 */
export function useGetCompanyActiveContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyActiveContractsQuery,
    GetCompanyActiveContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyActiveContractsQuery,
    GetCompanyActiveContractsQueryVariables
  >(GetCompanyActiveContractsDocument, options);
}
export function useGetCompanyActiveContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyActiveContractsQuery,
    GetCompanyActiveContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyActiveContractsQuery,
    GetCompanyActiveContractsQueryVariables
  >(GetCompanyActiveContractsDocument, options);
}
export type GetCompanyActiveContractsQueryHookResult = ReturnType<
  typeof useGetCompanyActiveContractsQuery
>;
export type GetCompanyActiveContractsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyActiveContractsLazyQuery
>;
export type GetCompanyActiveContractsQueryResult = Apollo.QueryResult<
  GetCompanyActiveContractsQuery,
  GetCompanyActiveContractsQueryVariables
>;
export const GetPaySupplementDocument = gql`
  query GetPaySupplement($id: ID) {
    company {
      id
      paySupplements(paySupplementId: $id) {
        id
        contract {
          id
          position
          country
          currency
          position
          member {
            id
            firstName
            lastName
          }
          company {
            id
          }
          compensation {
            additionalPays {
              ...compensationPayComponent
            }
          }
        }
        type
        status
        currency
        amount
        amountInFunctionalCurrency
        notes
        description
        createdDate
        payOn {
          month
          year
        }
        paySchedule {
          unit
          value
        }
      }
    }
  }
  ${CompensationPayComponentFragmentDoc}
`;

/**
 * __useGetPaySupplementQuery__
 *
 * To run a query within a React component, call `useGetPaySupplementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaySupplementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaySupplementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaySupplementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaySupplementQuery,
    GetPaySupplementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaySupplementQuery, GetPaySupplementQueryVariables>(
    GetPaySupplementDocument,
    options,
  );
}
export function useGetPaySupplementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaySupplementQuery,
    GetPaySupplementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaySupplementQuery,
    GetPaySupplementQueryVariables
  >(GetPaySupplementDocument, options);
}
export type GetPaySupplementQueryHookResult = ReturnType<
  typeof useGetPaySupplementQuery
>;
export type GetPaySupplementLazyQueryHookResult = ReturnType<
  typeof useGetPaySupplementLazyQuery
>;
export type GetPaySupplementQueryResult = Apollo.QueryResult<
  GetPaySupplementQuery,
  GetPaySupplementQueryVariables
>;
export const GetPaySupplementsDocument = gql`
  query GetPaySupplements {
    company {
      id
      paySupplements {
        id
        contract {
          id
          member {
            id
            firstName
            lastName
          }
          company {
            id
          }
        }
        type
        status
        currency
        amount
        amountInFunctionalCurrency
        notes
        description
        createdDate
        payOn {
          month
          year
        }
      }
    }
  }
`;

/**
 * __useGetPaySupplementsQuery__
 *
 * To run a query within a React component, call `useGetPaySupplementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaySupplementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaySupplementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaySupplementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaySupplementsQuery,
    GetPaySupplementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaySupplementsQuery,
    GetPaySupplementsQueryVariables
  >(GetPaySupplementsDocument, options);
}
export function useGetPaySupplementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaySupplementsQuery,
    GetPaySupplementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaySupplementsQuery,
    GetPaySupplementsQueryVariables
  >(GetPaySupplementsDocument, options);
}
export type GetPaySupplementsQueryHookResult = ReturnType<
  typeof useGetPaySupplementsQuery
>;
export type GetPaySupplementsLazyQueryHookResult = ReturnType<
  typeof useGetPaySupplementsLazyQuery
>;
export type GetPaySupplementsQueryResult = Apollo.QueryResult<
  GetPaySupplementsQuery,
  GetPaySupplementsQueryVariables
>;
export const GetPayrollCycleForDomainDocument = gql`
  query GetPayrollCycleForDomain(
    $domainId: ID
    $contractId: ID!
    $referenceDate: Date!
    $domain: PayrollInputDomainType!
    $returnClosestToReferenceDateUpcomingCutOff: Boolean!
  ) {
    payrollCycleForDomain(
      domainId: $domainId
      contractId: $contractId
      referenceDate: $referenceDate
      domain: $domain
      returnClosestToReferenceDateUpcomingCutOff: $returnClosestToReferenceDateUpcomingCutOff
    ) {
      id
      frequency
      startDate
      endDate
      payDate
      cutOffTo
    }
  }
`;

/**
 * __useGetPayrollCycleForDomainQuery__
 *
 * To run a query within a React component, call `useGetPayrollCycleForDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollCycleForDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollCycleForDomainQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      contractId: // value for 'contractId'
 *      referenceDate: // value for 'referenceDate'
 *      domain: // value for 'domain'
 *      returnClosestToReferenceDateUpcomingCutOff: // value for 'returnClosestToReferenceDateUpcomingCutOff'
 *   },
 * });
 */
export function useGetPayrollCycleForDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollCycleForDomainQuery,
    GetPayrollCycleForDomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayrollCycleForDomainQuery,
    GetPayrollCycleForDomainQueryVariables
  >(GetPayrollCycleForDomainDocument, options);
}
export function useGetPayrollCycleForDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollCycleForDomainQuery,
    GetPayrollCycleForDomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollCycleForDomainQuery,
    GetPayrollCycleForDomainQueryVariables
  >(GetPayrollCycleForDomainDocument, options);
}
export type GetPayrollCycleForDomainQueryHookResult = ReturnType<
  typeof useGetPayrollCycleForDomainQuery
>;
export type GetPayrollCycleForDomainLazyQueryHookResult = ReturnType<
  typeof useGetPayrollCycleForDomainLazyQuery
>;
export type GetPayrollCycleForDomainQueryResult = Apollo.QueryResult<
  GetPayrollCycleForDomainQuery,
  GetPayrollCycleForDomainQueryVariables
>;
export const UpdatePaySupplementDocument = gql`
  mutation UpdatePaySupplement($id: ID!, $input: PaySupplementUpdateInput) {
    paySupplementUpdate(id: $id, paySupplementChange: $input) {
      id
      contract {
        id
        member {
          id
          firstName
          lastName
        }
        company {
          id
        }
      }
      type
      status
      currency
      amount
      amountInFunctionalCurrency
      notes
      description
      createdDate
    }
  }
`;
export type UpdatePaySupplementMutationFn = Apollo.MutationFunction<
  UpdatePaySupplementMutation,
  UpdatePaySupplementMutationVariables
>;

/**
 * __useUpdatePaySupplementMutation__
 *
 * To run a mutation, you first call `useUpdatePaySupplementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaySupplementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaySupplementMutation, { data, loading, error }] = useUpdatePaySupplementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaySupplementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaySupplementMutation,
    UpdatePaySupplementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaySupplementMutation,
    UpdatePaySupplementMutationVariables
  >(UpdatePaySupplementDocument, options);
}
export type UpdatePaySupplementMutationHookResult = ReturnType<
  typeof useUpdatePaySupplementMutation
>;
export type UpdatePaySupplementMutationResult = Apollo.MutationResult<UpdatePaySupplementMutation>;
export type UpdatePaySupplementMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaySupplementMutation,
  UpdatePaySupplementMutationVariables
>;
export const DisputeCreateDocument = gql`
  mutation DisputeCreate($input: CreateDisputeInput!) {
    disputeCreate(input: $input) {
      status
    }
  }
`;
export type DisputeCreateMutationFn = Apollo.MutationFunction<
  DisputeCreateMutation,
  DisputeCreateMutationVariables
>;

/**
 * __useDisputeCreateMutation__
 *
 * To run a mutation, you first call `useDisputeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisputeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disputeCreateMutation, { data, loading, error }] = useDisputeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisputeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisputeCreateMutation,
    DisputeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisputeCreateMutation,
    DisputeCreateMutationVariables
  >(DisputeCreateDocument, options);
}
export type DisputeCreateMutationHookResult = ReturnType<
  typeof useDisputeCreateMutation
>;
export type DisputeCreateMutationResult = Apollo.MutationResult<DisputeCreateMutation>;
export type DisputeCreateMutationOptions = Apollo.BaseMutationOptions<
  DisputeCreateMutation,
  DisputeCreateMutationVariables
>;
export const GetBankProcessingFeeDocument = gql`
  query GetBankProcessingFee($id: ID) {
    configuration {
      bankTransferFee(companyId: $id)
    }
  }
`;

/**
 * __useGetBankProcessingFeeQuery__
 *
 * To run a query within a React component, call `useGetBankProcessingFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankProcessingFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankProcessingFeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBankProcessingFeeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBankProcessingFeeQuery,
    GetBankProcessingFeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBankProcessingFeeQuery,
    GetBankProcessingFeeQueryVariables
  >(GetBankProcessingFeeDocument, options);
}
export function useGetBankProcessingFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBankProcessingFeeQuery,
    GetBankProcessingFeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBankProcessingFeeQuery,
    GetBankProcessingFeeQueryVariables
  >(GetBankProcessingFeeDocument, options);
}
export type GetBankProcessingFeeQueryHookResult = ReturnType<
  typeof useGetBankProcessingFeeQuery
>;
export type GetBankProcessingFeeLazyQueryHookResult = ReturnType<
  typeof useGetBankProcessingFeeLazyQuery
>;
export type GetBankProcessingFeeQueryResult = Apollo.QueryResult<
  GetBankProcessingFeeQuery,
  GetBankProcessingFeeQueryVariables
>;
export const GetCompanyAutoDebitPayInSettingsDocument = gql`
  query GetCompanyAutoDebitPayInSettings($id: ID) {
    company(id: $id) {
      id
      payInSettings {
        payInPreferences {
          id
          preferenceType
          ... on CompanyPayInPreferenceAutoDebit {
            isAutoDebitEnabled
            isPreferenceActive
            defaultAutoDebitPayInMethod {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyAutoDebitPayInSettingsQuery__
 *
 * To run a query within a React component, call `useGetCompanyAutoDebitPayInSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAutoDebitPayInSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAutoDebitPayInSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyAutoDebitPayInSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyAutoDebitPayInSettingsQuery,
    GetCompanyAutoDebitPayInSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyAutoDebitPayInSettingsQuery,
    GetCompanyAutoDebitPayInSettingsQueryVariables
  >(GetCompanyAutoDebitPayInSettingsDocument, options);
}
export function useGetCompanyAutoDebitPayInSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyAutoDebitPayInSettingsQuery,
    GetCompanyAutoDebitPayInSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyAutoDebitPayInSettingsQuery,
    GetCompanyAutoDebitPayInSettingsQueryVariables
  >(GetCompanyAutoDebitPayInSettingsDocument, options);
}
export type GetCompanyAutoDebitPayInSettingsQueryHookResult = ReturnType<
  typeof useGetCompanyAutoDebitPayInSettingsQuery
>;
export type GetCompanyAutoDebitPayInSettingsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyAutoDebitPayInSettingsLazyQuery
>;
export type GetCompanyAutoDebitPayInSettingsQueryResult = Apollo.QueryResult<
  GetCompanyAutoDebitPayInSettingsQuery,
  GetCompanyAutoDebitPayInSettingsQueryVariables
>;
export const GetCompanyBillingCurrencyDocument = gql`
  query getCompanyBillingCurrency {
    company {
      pricing {
        billingCurrencyCode
      }
    }
  }
`;

/**
 * __useGetCompanyBillingCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCompanyBillingCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBillingCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBillingCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyBillingCurrencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyBillingCurrencyQuery,
    GetCompanyBillingCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyBillingCurrencyQuery,
    GetCompanyBillingCurrencyQueryVariables
  >(GetCompanyBillingCurrencyDocument, options);
}
export function useGetCompanyBillingCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyBillingCurrencyQuery,
    GetCompanyBillingCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyBillingCurrencyQuery,
    GetCompanyBillingCurrencyQueryVariables
  >(GetCompanyBillingCurrencyDocument, options);
}
export type GetCompanyBillingCurrencyQueryHookResult = ReturnType<
  typeof useGetCompanyBillingCurrencyQuery
>;
export type GetCompanyBillingCurrencyLazyQueryHookResult = ReturnType<
  typeof useGetCompanyBillingCurrencyLazyQuery
>;
export type GetCompanyBillingCurrencyQueryResult = Apollo.QueryResult<
  GetCompanyBillingCurrencyQuery,
  GetCompanyBillingCurrencyQueryVariables
>;
export const GetCompanyCreatedBundlesDocument = gql`
  query GetCompanyCreatedBundles {
    company {
      id
      paymentBundles(statuses: [CREATED]) {
        currency
        id
        items {
          companyPayable {
            ...companyPayableFragment
          }
          id
          type
        }
        referenceId
        status
        totalAmount
        sourceType
      }
    }
  }
  ${CompanyPayableFragmentFragmentDoc}
`;

/**
 * __useGetCompanyCreatedBundlesQuery__
 *
 * To run a query within a React component, call `useGetCompanyCreatedBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCreatedBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCreatedBundlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCreatedBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyCreatedBundlesQuery,
    GetCompanyCreatedBundlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyCreatedBundlesQuery,
    GetCompanyCreatedBundlesQueryVariables
  >(GetCompanyCreatedBundlesDocument, options);
}
export function useGetCompanyCreatedBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCreatedBundlesQuery,
    GetCompanyCreatedBundlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyCreatedBundlesQuery,
    GetCompanyCreatedBundlesQueryVariables
  >(GetCompanyCreatedBundlesDocument, options);
}
export type GetCompanyCreatedBundlesQueryHookResult = ReturnType<
  typeof useGetCompanyCreatedBundlesQuery
>;
export type GetCompanyCreatedBundlesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCreatedBundlesLazyQuery
>;
export type GetCompanyCreatedBundlesQueryResult = Apollo.QueryResult<
  GetCompanyCreatedBundlesQuery,
  GetCompanyCreatedBundlesQueryVariables
>;
export const GetCompanyDetailsForPaymentsDocument = gql`
  query GetCompanyDetailsForPayments {
    company {
      id
    }
  }
`;

/**
 * __useGetCompanyDetailsForPaymentsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDetailsForPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDetailsForPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDetailsForPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyDetailsForPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyDetailsForPaymentsQuery,
    GetCompanyDetailsForPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyDetailsForPaymentsQuery,
    GetCompanyDetailsForPaymentsQueryVariables
  >(GetCompanyDetailsForPaymentsDocument, options);
}
export function useGetCompanyDetailsForPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDetailsForPaymentsQuery,
    GetCompanyDetailsForPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyDetailsForPaymentsQuery,
    GetCompanyDetailsForPaymentsQueryVariables
  >(GetCompanyDetailsForPaymentsDocument, options);
}
export type GetCompanyDetailsForPaymentsQueryHookResult = ReturnType<
  typeof useGetCompanyDetailsForPaymentsQuery
>;
export type GetCompanyDetailsForPaymentsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDetailsForPaymentsLazyQuery
>;
export type GetCompanyDetailsForPaymentsQueryResult = Apollo.QueryResult<
  GetCompanyDetailsForPaymentsQuery,
  GetCompanyDetailsForPaymentsQueryVariables
>;
export const GetCompanyPayInSettingsCondensedDocument = gql`
  query GetCompanyPayInSettingsCondensed($id: ID) {
    company(id: $id) {
      id
      payInSettings {
        payInMethods(filters: {}) {
          id
          payInMethodType
          isActive
          isEnabled
          data {
            key
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayInSettingsCondensedQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayInSettingsCondensedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayInSettingsCondensedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayInSettingsCondensedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPayInSettingsCondensedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPayInSettingsCondensedQuery,
    GetCompanyPayInSettingsCondensedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayInSettingsCondensedQuery,
    GetCompanyPayInSettingsCondensedQueryVariables
  >(GetCompanyPayInSettingsCondensedDocument, options);
}
export function useGetCompanyPayInSettingsCondensedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayInSettingsCondensedQuery,
    GetCompanyPayInSettingsCondensedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayInSettingsCondensedQuery,
    GetCompanyPayInSettingsCondensedQueryVariables
  >(GetCompanyPayInSettingsCondensedDocument, options);
}
export type GetCompanyPayInSettingsCondensedQueryHookResult = ReturnType<
  typeof useGetCompanyPayInSettingsCondensedQuery
>;
export type GetCompanyPayInSettingsCondensedLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayInSettingsCondensedLazyQuery
>;
export type GetCompanyPayInSettingsCondensedQueryResult = Apollo.QueryResult<
  GetCompanyPayInSettingsCondensedQuery,
  GetCompanyPayInSettingsCondensedQueryVariables
>;
export const GetCompanyPayablesAndBundlesDocument = gql`
  query GetCompanyPayablesAndBundles {
    company {
      id
      primaryEntity {
        id
        currency
      }
      payables {
        ...companyPayableFragment
      }
      paymentBundles(statuses: [CREATED, PROCESSING, PAID]) {
        currency
        id
        items {
          companyPayable {
            ...companyPayableFragment
          }
          id
          type
        }
        referenceId
        status
        totalAmount
        createdOn
        updatedOn
        sourceType
        paymentMethodInfo {
          bundleId
          autoDebitInfo {
            id
            scheduledDebitDate
          }
        }
      }
      memberPayables(statuses: [UNPAID, APPROVED, CREATED]) {
        ...expenseMemberPayableFragment
        ...freelancerPayableFragment
        ...paySupplementMemberPayableFragment
      }
    }
  }
  ${CompanyPayableFragmentFragmentDoc}
  ${ExpenseMemberPayableFragmentFragmentDoc}
  ${FreelancerPayableFragmentFragmentDoc}
  ${PaySupplementMemberPayableFragmentFragmentDoc}
`;

/**
 * __useGetCompanyPayablesAndBundlesQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayablesAndBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayablesAndBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayablesAndBundlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPayablesAndBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPayablesAndBundlesQuery,
    GetCompanyPayablesAndBundlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayablesAndBundlesQuery,
    GetCompanyPayablesAndBundlesQueryVariables
  >(GetCompanyPayablesAndBundlesDocument, options);
}
export function useGetCompanyPayablesAndBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayablesAndBundlesQuery,
    GetCompanyPayablesAndBundlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayablesAndBundlesQuery,
    GetCompanyPayablesAndBundlesQueryVariables
  >(GetCompanyPayablesAndBundlesDocument, options);
}
export type GetCompanyPayablesAndBundlesQueryHookResult = ReturnType<
  typeof useGetCompanyPayablesAndBundlesQuery
>;
export type GetCompanyPayablesAndBundlesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayablesAndBundlesLazyQuery
>;
export type GetCompanyPayablesAndBundlesQueryResult = Apollo.QueryResult<
  GetCompanyPayablesAndBundlesQuery,
  GetCompanyPayablesAndBundlesQueryVariables
>;
export const GetCompanyPaymentBundleDocument = gql`
  query GetCompanyPaymentBundle($bundleId: ID!) {
    company {
      id
      paymentBundles(id: $bundleId, statuses: []) {
        id
        items {
          id
          companyPayable {
            id
            status
            invoice {
              invoiceNo
              invoiceId
              reference
              externalSystem
              amountDue
            }
            totalAmount
            currency
          }
          type
        }
        status
        paymentMethodInfo {
          paymentMethod
          processingFeePercentage
          processingFee {
            feeTotal
          }
        }
        payInInfo {
          id
          amount
          currency
          metadata {
            key
            value
          }
        }
        totalAmount
        currency
        referenceId
        bankAccount {
          accountDetails {
            key
            value
          }
        }
      }
      payInSettings {
        payInMethods(filters: {}) {
          id
          payInMethodType
          bankDetails {
            data {
              key
              value
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPaymentBundleQuery__
 *
 * To run a query within a React component, call `useGetCompanyPaymentBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPaymentBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPaymentBundleQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetCompanyPaymentBundleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPaymentBundleQuery,
    GetCompanyPaymentBundleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPaymentBundleQuery,
    GetCompanyPaymentBundleQueryVariables
  >(GetCompanyPaymentBundleDocument, options);
}
export function useGetCompanyPaymentBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPaymentBundleQuery,
    GetCompanyPaymentBundleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPaymentBundleQuery,
    GetCompanyPaymentBundleQueryVariables
  >(GetCompanyPaymentBundleDocument, options);
}
export type GetCompanyPaymentBundleQueryHookResult = ReturnType<
  typeof useGetCompanyPaymentBundleQuery
>;
export type GetCompanyPaymentBundleLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPaymentBundleLazyQuery
>;
export type GetCompanyPaymentBundleQueryResult = Apollo.QueryResult<
  GetCompanyPaymentBundleQuery,
  GetCompanyPaymentBundleQueryVariables
>;
export const GetCreditNoteDocumentDocument = gql`
  query GetCreditNoteDocument($input: CreditNoteQueryInput) {
    creditNotesWithPagination(input: $input) {
      data {
        document {
          ...documentReadable
        }
      }
    }
  }
  ${DocumentReadableFragmentDoc}
`;

/**
 * __useGetCreditNoteDocumentQuery__
 *
 * To run a query within a React component, call `useGetCreditNoteDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditNoteDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditNoteDocumentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCreditNoteDocumentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCreditNoteDocumentQuery,
    GetCreditNoteDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCreditNoteDocumentQuery,
    GetCreditNoteDocumentQueryVariables
  >(GetCreditNoteDocumentDocument, options);
}
export function useGetCreditNoteDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreditNoteDocumentQuery,
    GetCreditNoteDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreditNoteDocumentQuery,
    GetCreditNoteDocumentQueryVariables
  >(GetCreditNoteDocumentDocument, options);
}
export type GetCreditNoteDocumentQueryHookResult = ReturnType<
  typeof useGetCreditNoteDocumentQuery
>;
export type GetCreditNoteDocumentLazyQueryHookResult = ReturnType<
  typeof useGetCreditNoteDocumentLazyQuery
>;
export type GetCreditNoteDocumentQueryResult = Apollo.QueryResult<
  GetCreditNoteDocumentQuery,
  GetCreditNoteDocumentQueryVariables
>;
export const GetCreditNotesWithPaginationDocument = gql`
  query GetCreditNotesWithPagination($input: CreditNoteQueryInput) {
    creditNotesWithPagination(input: $input) {
      data {
        id
        amountApplied
        amountUnapplied
        amountTotal
        creditNoteNo
        reference
        externalId
        appliedInvoices {
          reference
          invoiceNo
          amountPaid
          amountDue
          totalAmount
        }
        currencyCode
        createdDate
        report {
          companyId
          month
          year
          type
        }
      }
      pageResult {
        pageNumber
        pageSize
        count
      }
    }
  }
`;

/**
 * __useGetCreditNotesWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetCreditNotesWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditNotesWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditNotesWithPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCreditNotesWithPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCreditNotesWithPaginationQuery,
    GetCreditNotesWithPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCreditNotesWithPaginationQuery,
    GetCreditNotesWithPaginationQueryVariables
  >(GetCreditNotesWithPaginationDocument, options);
}
export function useGetCreditNotesWithPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreditNotesWithPaginationQuery,
    GetCreditNotesWithPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreditNotesWithPaginationQuery,
    GetCreditNotesWithPaginationQueryVariables
  >(GetCreditNotesWithPaginationDocument, options);
}
export type GetCreditNotesWithPaginationQueryHookResult = ReturnType<
  typeof useGetCreditNotesWithPaginationQuery
>;
export type GetCreditNotesWithPaginationLazyQueryHookResult = ReturnType<
  typeof useGetCreditNotesWithPaginationLazyQuery
>;
export type GetCreditNotesWithPaginationQueryResult = Apollo.QueryResult<
  GetCreditNotesWithPaginationQuery,
  GetCreditNotesWithPaginationQueryVariables
>;
export const GetDisputesDocument = gql`
  query GetDisputes($filter: DisputeFilter, $page: PageRequest) {
    disputes(filter: $filter, page: $page) {
      data {
        status
        description
        reasonCategory
        reasonSubCategory
        originType
        originId
        origin {
          ... on CompanyPayable {
            id
            type
            totalAmount
            currency
            invoice {
              reference
              invoiceNo
              totalAmount
            }
            creditNote {
              reference
              creditNoteNo
            }
            report {
              companyId
              month
              year
              type
            }
          }
        }
      }
      page {
        pageNumber
        pageSize
        count
      }
    }
  }
`;

/**
 * __useGetDisputesQuery__
 *
 * To run a query within a React component, call `useGetDisputesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisputesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisputesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetDisputesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDisputesQuery,
    GetDisputesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDisputesQuery, GetDisputesQueryVariables>(
    GetDisputesDocument,
    options,
  );
}
export function useGetDisputesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDisputesQuery,
    GetDisputesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDisputesQuery, GetDisputesQueryVariables>(
    GetDisputesDocument,
    options,
  );
}
export type GetDisputesQueryHookResult = ReturnType<typeof useGetDisputesQuery>;
export type GetDisputesLazyQueryHookResult = ReturnType<
  typeof useGetDisputesLazyQuery
>;
export type GetDisputesQueryResult = Apollo.QueryResult<
  GetDisputesQuery,
  GetDisputesQueryVariables
>;
export const GetInvoiceDocumentDocument = gql`
  query GetInvoiceDocument($id: ID) {
    company {
      id
      payables(filters: { payableIds: [$id] }) {
        id
        invoice {
          id
          externalSystem
          document {
            ...documentReadable
          }
        }
      }
    }
  }
  ${DocumentReadableFragmentDoc}
`;

/**
 * __useGetInvoiceDocumentQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceDocumentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvoiceDocumentQuery,
    GetInvoiceDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoiceDocumentQuery,
    GetInvoiceDocumentQueryVariables
  >(GetInvoiceDocumentDocument, options);
}
export function useGetInvoiceDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceDocumentQuery,
    GetInvoiceDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoiceDocumentQuery,
    GetInvoiceDocumentQueryVariables
  >(GetInvoiceDocumentDocument, options);
}
export type GetInvoiceDocumentQueryHookResult = ReturnType<
  typeof useGetInvoiceDocumentQuery
>;
export type GetInvoiceDocumentLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceDocumentLazyQuery
>;
export type GetInvoiceDocumentQueryResult = Apollo.QueryResult<
  GetInvoiceDocumentQuery,
  GetInvoiceDocumentQueryVariables
>;
export const GetInvoiceExternalLinkDocument = gql`
  query GetInvoiceExternalLink($id: ID) {
    company {
      id
      payables(filters: { payableIds: [$id] }) {
        id
        invoice {
          id
          externalSystem
          externalLink
        }
      }
    }
  }
`;

/**
 * __useGetInvoiceExternalLinkQuery__
 *
 * To run a query within a React component, call `useGetInvoiceExternalLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceExternalLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceExternalLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceExternalLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvoiceExternalLinkQuery,
    GetInvoiceExternalLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoiceExternalLinkQuery,
    GetInvoiceExternalLinkQueryVariables
  >(GetInvoiceExternalLinkDocument, options);
}
export function useGetInvoiceExternalLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceExternalLinkQuery,
    GetInvoiceExternalLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoiceExternalLinkQuery,
    GetInvoiceExternalLinkQueryVariables
  >(GetInvoiceExternalLinkDocument, options);
}
export type GetInvoiceExternalLinkQueryHookResult = ReturnType<
  typeof useGetInvoiceExternalLinkQuery
>;
export type GetInvoiceExternalLinkLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceExternalLinkLazyQuery
>;
export type GetInvoiceExternalLinkQueryResult = Apollo.QueryResult<
  GetInvoiceExternalLinkQuery,
  GetInvoiceExternalLinkQueryVariables
>;
export const GetCompanyPayableReportDocument = gql`
  query GetCompanyPayableReport($input: DownloadCompanyPayableReportInput!) {
    downloadCompanyPayableReport(input: $input) {
      blob
      extension
      name
      contentType
    }
  }
`;

/**
 * __useGetCompanyPayableReportQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayableReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayableReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayableReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompanyPayableReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayableReportQuery,
    GetCompanyPayableReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayableReportQuery,
    GetCompanyPayableReportQueryVariables
  >(GetCompanyPayableReportDocument, options);
}
export function useGetCompanyPayableReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayableReportQuery,
    GetCompanyPayableReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayableReportQuery,
    GetCompanyPayableReportQueryVariables
  >(GetCompanyPayableReportDocument, options);
}
export type GetCompanyPayableReportQueryHookResult = ReturnType<
  typeof useGetCompanyPayableReportQuery
>;
export type GetCompanyPayableReportLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayableReportLazyQuery
>;
export type GetCompanyPayableReportQueryResult = Apollo.QueryResult<
  GetCompanyPayableReportQuery,
  GetCompanyPayableReportQueryVariables
>;
export const GetPaymentBundleReviewItemsDocument = gql`
  query GetPaymentBundleReviewItems($items: [PaymentBundlePreviewItemInput]) {
    paymentBundlePreview(items: $items) {
      currency
      totalAmount
      items {
        externalId
        item {
          ...companyPayableFragment
          ...expenseMemberPayableFragment
          ...freelancerPayableFragment
          ...paySupplementMemberPayableFragment
        }
        billingAmount
        managementFee
        type
        payoutProcessingFee
      }
    }
  }
  ${CompanyPayableFragmentFragmentDoc}
  ${ExpenseMemberPayableFragmentFragmentDoc}
  ${FreelancerPayableFragmentFragmentDoc}
  ${PaySupplementMemberPayableFragmentFragmentDoc}
`;

/**
 * __useGetPaymentBundleReviewItemsQuery__
 *
 * To run a query within a React component, call `useGetPaymentBundleReviewItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentBundleReviewItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentBundleReviewItemsQuery({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useGetPaymentBundleReviewItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentBundleReviewItemsQuery,
    GetPaymentBundleReviewItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentBundleReviewItemsQuery,
    GetPaymentBundleReviewItemsQueryVariables
  >(GetPaymentBundleReviewItemsDocument, options);
}
export function useGetPaymentBundleReviewItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentBundleReviewItemsQuery,
    GetPaymentBundleReviewItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentBundleReviewItemsQuery,
    GetPaymentBundleReviewItemsQueryVariables
  >(GetPaymentBundleReviewItemsDocument, options);
}
export type GetPaymentBundleReviewItemsQueryHookResult = ReturnType<
  typeof useGetPaymentBundleReviewItemsQuery
>;
export type GetPaymentBundleReviewItemsLazyQueryHookResult = ReturnType<
  typeof useGetPaymentBundleReviewItemsLazyQuery
>;
export type GetPaymentBundleReviewItemsQueryResult = Apollo.QueryResult<
  GetPaymentBundleReviewItemsQuery,
  GetPaymentBundleReviewItemsQueryVariables
>;
export const GetPaymentInstructionsFileDocument = gql`
  query GetPaymentInstructionsFile($bundleId: ID!) {
    company {
      id
      paymentBundles(id: $bundleId, statuses: [CREATED]) {
        id
        instructionFile {
          id
          name
          extension
          contentType
          link
          blob
        }
      }
    }
  }
`;

/**
 * __useGetPaymentInstructionsFileQuery__
 *
 * To run a query within a React component, call `useGetPaymentInstructionsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentInstructionsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentInstructionsFileQuery({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *   },
 * });
 */
export function useGetPaymentInstructionsFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentInstructionsFileQuery,
    GetPaymentInstructionsFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentInstructionsFileQuery,
    GetPaymentInstructionsFileQueryVariables
  >(GetPaymentInstructionsFileDocument, options);
}
export function useGetPaymentInstructionsFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentInstructionsFileQuery,
    GetPaymentInstructionsFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentInstructionsFileQuery,
    GetPaymentInstructionsFileQueryVariables
  >(GetPaymentInstructionsFileDocument, options);
}
export type GetPaymentInstructionsFileQueryHookResult = ReturnType<
  typeof useGetPaymentInstructionsFileQuery
>;
export type GetPaymentInstructionsFileLazyQueryHookResult = ReturnType<
  typeof useGetPaymentInstructionsFileLazyQuery
>;
export type GetPaymentInstructionsFileQueryResult = Apollo.QueryResult<
  GetPaymentInstructionsFileQuery,
  GetPaymentInstructionsFileQueryVariables
>;
export const MarkBundleAsProcessingDocument = gql`
  mutation MarkBundleAsProcessing($id: ID!, $paymentAdvices: [Upload!]) {
    paymentBundleMarkAsProcessing(id: $id, paymentAdvices: $paymentAdvices) {
      id
      status
    }
  }
`;
export type MarkBundleAsProcessingMutationFn = Apollo.MutationFunction<
  MarkBundleAsProcessingMutation,
  MarkBundleAsProcessingMutationVariables
>;

/**
 * __useMarkBundleAsProcessingMutation__
 *
 * To run a mutation, you first call `useMarkBundleAsProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkBundleAsProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markBundleAsProcessingMutation, { data, loading, error }] = useMarkBundleAsProcessingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paymentAdvices: // value for 'paymentAdvices'
 *   },
 * });
 */
export function useMarkBundleAsProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkBundleAsProcessingMutation,
    MarkBundleAsProcessingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkBundleAsProcessingMutation,
    MarkBundleAsProcessingMutationVariables
  >(MarkBundleAsProcessingDocument, options);
}
export type MarkBundleAsProcessingMutationHookResult = ReturnType<
  typeof useMarkBundleAsProcessingMutation
>;
export type MarkBundleAsProcessingMutationResult = Apollo.MutationResult<MarkBundleAsProcessingMutation>;
export type MarkBundleAsProcessingMutationOptions = Apollo.BaseMutationOptions<
  MarkBundleAsProcessingMutation,
  MarkBundleAsProcessingMutationVariables
>;
export const CreatePaymentBundleDocument = gql`
  mutation CreatePaymentBundle($input: PaymentBundleCreateInput!) {
    paymentBundleCreate(input: $input) {
      currency
      id
      items {
        companyPayable {
          ...companyPayableFragment
        }
        id
        type
      }
      referenceId
      status
      totalAmount
    }
  }
  ${CompanyPayableFragmentFragmentDoc}
`;
export type CreatePaymentBundleMutationFn = Apollo.MutationFunction<
  CreatePaymentBundleMutation,
  CreatePaymentBundleMutationVariables
>;

/**
 * __useCreatePaymentBundleMutation__
 *
 * To run a mutation, you first call `useCreatePaymentBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentBundleMutation, { data, loading, error }] = useCreatePaymentBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentBundleMutation,
    CreatePaymentBundleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentBundleMutation,
    CreatePaymentBundleMutationVariables
  >(CreatePaymentBundleDocument, options);
}
export type CreatePaymentBundleMutationHookResult = ReturnType<
  typeof useCreatePaymentBundleMutation
>;
export type CreatePaymentBundleMutationResult = Apollo.MutationResult<CreatePaymentBundleMutation>;
export type CreatePaymentBundleMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentBundleMutation,
  CreatePaymentBundleMutationVariables
>;
export const PaymentBundleDeleteDocument = gql`
  mutation PaymentBundleDelete($id: ID!) {
    paymentBundleDelete(id: $id) {
      id
      status
    }
  }
`;
export type PaymentBundleDeleteMutationFn = Apollo.MutationFunction<
  PaymentBundleDeleteMutation,
  PaymentBundleDeleteMutationVariables
>;

/**
 * __usePaymentBundleDeleteMutation__
 *
 * To run a mutation, you first call `usePaymentBundleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentBundleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentBundleDeleteMutation, { data, loading, error }] = usePaymentBundleDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentBundleDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaymentBundleDeleteMutation,
    PaymentBundleDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaymentBundleDeleteMutation,
    PaymentBundleDeleteMutationVariables
  >(PaymentBundleDeleteDocument, options);
}
export type PaymentBundleDeleteMutationHookResult = ReturnType<
  typeof usePaymentBundleDeleteMutation
>;
export type PaymentBundleDeleteMutationResult = Apollo.MutationResult<PaymentBundleDeleteMutation>;
export type PaymentBundleDeleteMutationOptions = Apollo.BaseMutationOptions<
  PaymentBundleDeleteMutation,
  PaymentBundleDeleteMutationVariables
>;
export const UpdatePaymentBundleDocument = gql`
  mutation UpdatePaymentBundle($input: PaymentBundleUpdateInput) {
    paymentBundleUpdate(input: $input) {
      id
      items {
        id
        companyPayable {
          id
          status
          invoice {
            invoiceNo
            invoiceId
          }
          totalAmount
          currency
        }
        type
      }
      status
      paymentMethodInfo {
        paymentMethod
        processingFee {
          feeTotal
        }
      }
      totalAmount
      currency
      referenceId
      bankAccount {
        accountDetails {
          key
          value
        }
      }
      instructionFile {
        id
        name
        extension
        contentType
        link
      }
    }
  }
`;
export type UpdatePaymentBundleMutationFn = Apollo.MutationFunction<
  UpdatePaymentBundleMutation,
  UpdatePaymentBundleMutationVariables
>;

/**
 * __useUpdatePaymentBundleMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentBundleMutation, { data, loading, error }] = useUpdatePaymentBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentBundleMutation,
    UpdatePaymentBundleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaymentBundleMutation,
    UpdatePaymentBundleMutationVariables
  >(UpdatePaymentBundleDocument, options);
}
export type UpdatePaymentBundleMutationHookResult = ReturnType<
  typeof useUpdatePaymentBundleMutation
>;
export type UpdatePaymentBundleMutationResult = Apollo.MutationResult<UpdatePaymentBundleMutation>;
export type UpdatePaymentBundleMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentBundleMutation,
  UpdatePaymentBundleMutationVariables
>;
export const CompanyPayrollFileDownloadAsyncDocument = gql`
  mutation CompanyPayrollFileDownloadAsync(
    $request: CompanyPayrollFileDownloadRequest!
  ) {
    companyPayrollFileDownloadAsync(request: $request) {
      id
      status
      payrollFile {
        id
        downloadUrl
        name
        extension
        createdOn
      }
    }
  }
`;
export type CompanyPayrollFileDownloadAsyncMutationFn = Apollo.MutationFunction<
  CompanyPayrollFileDownloadAsyncMutation,
  CompanyPayrollFileDownloadAsyncMutationVariables
>;

/**
 * __useCompanyPayrollFileDownloadAsyncMutation__
 *
 * To run a mutation, you first call `useCompanyPayrollFileDownloadAsyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyPayrollFileDownloadAsyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyPayrollFileDownloadAsyncMutation, { data, loading, error }] = useCompanyPayrollFileDownloadAsyncMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCompanyPayrollFileDownloadAsyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyPayrollFileDownloadAsyncMutation,
    CompanyPayrollFileDownloadAsyncMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyPayrollFileDownloadAsyncMutation,
    CompanyPayrollFileDownloadAsyncMutationVariables
  >(CompanyPayrollFileDownloadAsyncDocument, options);
}
export type CompanyPayrollFileDownloadAsyncMutationHookResult = ReturnType<
  typeof useCompanyPayrollFileDownloadAsyncMutation
>;
export type CompanyPayrollFileDownloadAsyncMutationResult = Apollo.MutationResult<CompanyPayrollFileDownloadAsyncMutation>;
export type CompanyPayrollFileDownloadAsyncMutationOptions = Apollo.BaseMutationOptions<
  CompanyPayrollFileDownloadAsyncMutation,
  CompanyPayrollFileDownloadAsyncMutationVariables
>;
export const PayrollBulkUploadCreateDocument = gql`
  mutation PayrollBulkUploadCreate($payrollCycleId: ID!, $fileName: String!) {
    payrollBulkUploadCreate(
      payrollCycleId: $payrollCycleId
      fileName: $fileName
    ) {
      url
    }
  }
`;
export type PayrollBulkUploadCreateMutationFn = Apollo.MutationFunction<
  PayrollBulkUploadCreateMutation,
  PayrollBulkUploadCreateMutationVariables
>;

/**
 * __usePayrollBulkUploadCreateMutation__
 *
 * To run a mutation, you first call `usePayrollBulkUploadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollBulkUploadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollBulkUploadCreateMutation, { data, loading, error }] = usePayrollBulkUploadCreateMutation({
 *   variables: {
 *      payrollCycleId: // value for 'payrollCycleId'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function usePayrollBulkUploadCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollBulkUploadCreateMutation,
    PayrollBulkUploadCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollBulkUploadCreateMutation,
    PayrollBulkUploadCreateMutationVariables
  >(PayrollBulkUploadCreateDocument, options);
}
export type PayrollBulkUploadCreateMutationHookResult = ReturnType<
  typeof usePayrollBulkUploadCreateMutation
>;
export type PayrollBulkUploadCreateMutationResult = Apollo.MutationResult<PayrollBulkUploadCreateMutation>;
export type PayrollBulkUploadCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollBulkUploadCreateMutation,
  PayrollBulkUploadCreateMutationVariables
>;
export const CompanyPayrollConfirmInputDocument = gql`
  mutation CompanyPayrollConfirmInput($id: ID!) {
    companyPayrollInputConfirm(companyPayrollCycleId: $id) {
      id
      status
      payrollCutOffDate
      payDate
      frequency
      startDate
      endDate
      entityType
      summary {
        memberTotalCount
      }
    }
  }
`;
export type CompanyPayrollConfirmInputMutationFn = Apollo.MutationFunction<
  CompanyPayrollConfirmInputMutation,
  CompanyPayrollConfirmInputMutationVariables
>;

/**
 * __useCompanyPayrollConfirmInputMutation__
 *
 * To run a mutation, you first call `useCompanyPayrollConfirmInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyPayrollConfirmInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyPayrollConfirmInputMutation, { data, loading, error }] = useCompanyPayrollConfirmInputMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPayrollConfirmInputMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyPayrollConfirmInputMutation,
    CompanyPayrollConfirmInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyPayrollConfirmInputMutation,
    CompanyPayrollConfirmInputMutationVariables
  >(CompanyPayrollConfirmInputDocument, options);
}
export type CompanyPayrollConfirmInputMutationHookResult = ReturnType<
  typeof useCompanyPayrollConfirmInputMutation
>;
export type CompanyPayrollConfirmInputMutationResult = Apollo.MutationResult<CompanyPayrollConfirmInputMutation>;
export type CompanyPayrollConfirmInputMutationOptions = Apollo.BaseMutationOptions<
  CompanyPayrollConfirmInputMutation,
  CompanyPayrollConfirmInputMutationVariables
>;
export const CompanyPayrollConfirmOutputDocument = gql`
  mutation CompanyPayrollConfirmOutput($id: ID!) {
    companyPayrollOutputConfirm(companyPayrollCycleId: $id) {
      id
      status
      payrollCutOffDate
      payDate
      frequency
      startDate
      endDate
      entityType
      summary {
        memberTotalCount
      }
    }
  }
`;
export type CompanyPayrollConfirmOutputMutationFn = Apollo.MutationFunction<
  CompanyPayrollConfirmOutputMutation,
  CompanyPayrollConfirmOutputMutationVariables
>;

/**
 * __useCompanyPayrollConfirmOutputMutation__
 *
 * To run a mutation, you first call `useCompanyPayrollConfirmOutputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyPayrollConfirmOutputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyPayrollConfirmOutputMutation, { data, loading, error }] = useCompanyPayrollConfirmOutputMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyPayrollConfirmOutputMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyPayrollConfirmOutputMutation,
    CompanyPayrollConfirmOutputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyPayrollConfirmOutputMutation,
    CompanyPayrollConfirmOutputMutationVariables
  >(CompanyPayrollConfirmOutputDocument, options);
}
export type CompanyPayrollConfirmOutputMutationHookResult = ReturnType<
  typeof useCompanyPayrollConfirmOutputMutation
>;
export type CompanyPayrollConfirmOutputMutationResult = Apollo.MutationResult<CompanyPayrollConfirmOutputMutation>;
export type CompanyPayrollConfirmOutputMutationOptions = Apollo.BaseMutationOptions<
  CompanyPayrollConfirmOutputMutation,
  CompanyPayrollConfirmOutputMutationVariables
>;
export const CustomerUpdatePayrollWorkflowDocument = gql`
  mutation CustomerUpdatePayrollWorkflow(
    $value: CustomerPayrollWorkflowRequest!
  ) {
    customerUpdatePayrollWorkflow(value: $value) {
      companyPayrollCycles {
        id
        status
        payrollCutOffDate
        payDate
        frequency
        startDate
        endDate
        entityType
        summary {
          memberTotalCount
        }
      }
    }
  }
`;
export type CustomerUpdatePayrollWorkflowMutationFn = Apollo.MutationFunction<
  CustomerUpdatePayrollWorkflowMutation,
  CustomerUpdatePayrollWorkflowMutationVariables
>;

/**
 * __useCustomerUpdatePayrollWorkflowMutation__
 *
 * To run a mutation, you first call `useCustomerUpdatePayrollWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerUpdatePayrollWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerUpdatePayrollWorkflowMutation, { data, loading, error }] = useCustomerUpdatePayrollWorkflowMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCustomerUpdatePayrollWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerUpdatePayrollWorkflowMutation,
    CustomerUpdatePayrollWorkflowMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CustomerUpdatePayrollWorkflowMutation,
    CustomerUpdatePayrollWorkflowMutationVariables
  >(CustomerUpdatePayrollWorkflowDocument, options);
}
export type CustomerUpdatePayrollWorkflowMutationHookResult = ReturnType<
  typeof useCustomerUpdatePayrollWorkflowMutation
>;
export type CustomerUpdatePayrollWorkflowMutationResult = Apollo.MutationResult<CustomerUpdatePayrollWorkflowMutation>;
export type CustomerUpdatePayrollWorkflowMutationOptions = Apollo.BaseMutationOptions<
  CustomerUpdatePayrollWorkflowMutation,
  CustomerUpdatePayrollWorkflowMutationVariables
>;
export const DownloadCompanyPayrollCycleReportDocument = gql`
  query DownloadCompanyPayrollCycleReport(
    $filter: CompanyPayrollCycleReportFilter!
  ) {
    companyPayrollCycleReportDownload(filter: $filter) {
      id
      downloadUrl
      name
    }
  }
`;

/**
 * __useDownloadCompanyPayrollCycleReportQuery__
 *
 * To run a query within a React component, call `useDownloadCompanyPayrollCycleReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCompanyPayrollCycleReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCompanyPayrollCycleReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDownloadCompanyPayrollCycleReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadCompanyPayrollCycleReportQuery,
    DownloadCompanyPayrollCycleReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadCompanyPayrollCycleReportQuery,
    DownloadCompanyPayrollCycleReportQueryVariables
  >(DownloadCompanyPayrollCycleReportDocument, options);
}
export function useDownloadCompanyPayrollCycleReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadCompanyPayrollCycleReportQuery,
    DownloadCompanyPayrollCycleReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadCompanyPayrollCycleReportQuery,
    DownloadCompanyPayrollCycleReportQueryVariables
  >(DownloadCompanyPayrollCycleReportDocument, options);
}
export type DownloadCompanyPayrollCycleReportQueryHookResult = ReturnType<
  typeof useDownloadCompanyPayrollCycleReportQuery
>;
export type DownloadCompanyPayrollCycleReportLazyQueryHookResult = ReturnType<
  typeof useDownloadCompanyPayrollCycleReportLazyQuery
>;
export type DownloadCompanyPayrollCycleReportQueryResult = Apollo.QueryResult<
  DownloadCompanyPayrollCycleReportQuery,
  DownloadCompanyPayrollCycleReportQueryVariables
>;
export const DownloadCompanyPayrollInputReportDocument = gql`
  query DownloadCompanyPayrollInputReport(
    $filter: CompanyPayrollInputReportFilter!
  ) {
    companyPayrollInputReportDownload(filter: $filter) {
      id
      name
      extension
      contentType
      link
      blob
    }
  }
`;

/**
 * __useDownloadCompanyPayrollInputReportQuery__
 *
 * To run a query within a React component, call `useDownloadCompanyPayrollInputReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCompanyPayrollInputReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCompanyPayrollInputReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDownloadCompanyPayrollInputReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadCompanyPayrollInputReportQuery,
    DownloadCompanyPayrollInputReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadCompanyPayrollInputReportQuery,
    DownloadCompanyPayrollInputReportQueryVariables
  >(DownloadCompanyPayrollInputReportDocument, options);
}
export function useDownloadCompanyPayrollInputReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadCompanyPayrollInputReportQuery,
    DownloadCompanyPayrollInputReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadCompanyPayrollInputReportQuery,
    DownloadCompanyPayrollInputReportQueryVariables
  >(DownloadCompanyPayrollInputReportDocument, options);
}
export type DownloadCompanyPayrollInputReportQueryHookResult = ReturnType<
  typeof useDownloadCompanyPayrollInputReportQuery
>;
export type DownloadCompanyPayrollInputReportLazyQueryHookResult = ReturnType<
  typeof useDownloadCompanyPayrollInputReportLazyQuery
>;
export type DownloadCompanyPayrollInputReportQueryResult = Apollo.QueryResult<
  DownloadCompanyPayrollInputReportQuery,
  DownloadCompanyPayrollInputReportQueryVariables
>;
export const DownloadCompanyPayrollReportDocument = gql`
  query DownloadCompanyPayrollReport($filter: CompanyPayrollReportInput!) {
    companyPayrollReportDownload(filter: $filter) {
      id
      name
      extension
      contentType
      link
      blob
    }
  }
`;

/**
 * __useDownloadCompanyPayrollReportQuery__
 *
 * To run a query within a React component, call `useDownloadCompanyPayrollReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCompanyPayrollReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCompanyPayrollReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDownloadCompanyPayrollReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadCompanyPayrollReportQuery,
    DownloadCompanyPayrollReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadCompanyPayrollReportQuery,
    DownloadCompanyPayrollReportQueryVariables
  >(DownloadCompanyPayrollReportDocument, options);
}
export function useDownloadCompanyPayrollReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadCompanyPayrollReportQuery,
    DownloadCompanyPayrollReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadCompanyPayrollReportQuery,
    DownloadCompanyPayrollReportQueryVariables
  >(DownloadCompanyPayrollReportDocument, options);
}
export type DownloadCompanyPayrollReportQueryHookResult = ReturnType<
  typeof useDownloadCompanyPayrollReportQuery
>;
export type DownloadCompanyPayrollReportLazyQueryHookResult = ReturnType<
  typeof useDownloadCompanyPayrollReportLazyQuery
>;
export type DownloadCompanyPayrollReportQueryResult = Apollo.QueryResult<
  DownloadCompanyPayrollReportQuery,
  DownloadCompanyPayrollReportQueryVariables
>;
export const DownloadPayrollBulkUploadTemplateDocument = gql`
  query DownloadPayrollBulkUploadTemplate($payrollCycleId: ID) {
    payrollBulkUploadTemplate(payrollCycleId: $payrollCycleId) {
      name
      extension
      link
    }
  }
`;

/**
 * __useDownloadPayrollBulkUploadTemplateQuery__
 *
 * To run a query within a React component, call `useDownloadPayrollBulkUploadTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadPayrollBulkUploadTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadPayrollBulkUploadTemplateQuery({
 *   variables: {
 *      payrollCycleId: // value for 'payrollCycleId'
 *   },
 * });
 */
export function useDownloadPayrollBulkUploadTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DownloadPayrollBulkUploadTemplateQuery,
    DownloadPayrollBulkUploadTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadPayrollBulkUploadTemplateQuery,
    DownloadPayrollBulkUploadTemplateQueryVariables
  >(DownloadPayrollBulkUploadTemplateDocument, options);
}
export function useDownloadPayrollBulkUploadTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadPayrollBulkUploadTemplateQuery,
    DownloadPayrollBulkUploadTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadPayrollBulkUploadTemplateQuery,
    DownloadPayrollBulkUploadTemplateQueryVariables
  >(DownloadPayrollBulkUploadTemplateDocument, options);
}
export type DownloadPayrollBulkUploadTemplateQueryHookResult = ReturnType<
  typeof useDownloadPayrollBulkUploadTemplateQuery
>;
export type DownloadPayrollBulkUploadTemplateLazyQueryHookResult = ReturnType<
  typeof useDownloadPayrollBulkUploadTemplateLazyQuery
>;
export type DownloadPayrollBulkUploadTemplateQueryResult = Apollo.QueryResult<
  DownloadPayrollBulkUploadTemplateQuery,
  DownloadPayrollBulkUploadTemplateQueryVariables
>;
export const DownloadPayrollFileDocument = gql`
  query DownloadPayrollFile($fileDownloadId: ID!) {
    payrollFileDownload(fileDownloadId: $fileDownloadId) {
      id
      status
      payrollFile {
        id
        downloadUrl
        name
        extension
        createdOn
      }
    }
  }
`;

/**
 * __useDownloadPayrollFileQuery__
 *
 * To run a query within a React component, call `useDownloadPayrollFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadPayrollFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadPayrollFileQuery({
 *   variables: {
 *      fileDownloadId: // value for 'fileDownloadId'
 *   },
 * });
 */
export function useDownloadPayrollFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadPayrollFileQuery,
    DownloadPayrollFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadPayrollFileQuery,
    DownloadPayrollFileQueryVariables
  >(DownloadPayrollFileDocument, options);
}
export function useDownloadPayrollFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadPayrollFileQuery,
    DownloadPayrollFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadPayrollFileQuery,
    DownloadPayrollFileQueryVariables
  >(DownloadPayrollFileDocument, options);
}
export type DownloadPayrollFileQueryHookResult = ReturnType<
  typeof useDownloadPayrollFileQuery
>;
export type DownloadPayrollFileLazyQueryHookResult = ReturnType<
  typeof useDownloadPayrollFileLazyQuery
>;
export type DownloadPayrollFileQueryResult = Apollo.QueryResult<
  DownloadPayrollFileQuery,
  DownloadPayrollFileQueryVariables
>;
export const GetCompanyAndEntityDocument = gql`
  query GetCompanyAndEntity {
    company {
      id
      displayName
      primaryEntity {
        id
        legalName
        registrationNo
        address {
          line1
          line2
          city
          zipcode
          country
        }
        phone
      }
    }
  }
`;

/**
 * __useGetCompanyAndEntityQuery__
 *
 * To run a query within a React component, call `useGetCompanyAndEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAndEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAndEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyAndEntityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyAndEntityQuery,
    GetCompanyAndEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyAndEntityQuery,
    GetCompanyAndEntityQueryVariables
  >(GetCompanyAndEntityDocument, options);
}
export function useGetCompanyAndEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyAndEntityQuery,
    GetCompanyAndEntityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyAndEntityQuery,
    GetCompanyAndEntityQueryVariables
  >(GetCompanyAndEntityDocument, options);
}
export type GetCompanyAndEntityQueryHookResult = ReturnType<
  typeof useGetCompanyAndEntityQuery
>;
export type GetCompanyAndEntityLazyQueryHookResult = ReturnType<
  typeof useGetCompanyAndEntityLazyQuery
>;
export type GetCompanyAndEntityQueryResult = Apollo.QueryResult<
  GetCompanyAndEntityQuery,
  GetCompanyAndEntityQueryVariables
>;
export const GetCompanyComplianceEorTypeDocument = gql`
  query GetCompanyComplianceEorType {
    company {
      id
      contracts(includeAnyStatus: true) {
        id
        country
        compliance {
          type
        }
      }
    }
  }
`;

/**
 * __useGetCompanyComplianceEorTypeQuery__
 *
 * To run a query within a React component, call `useGetCompanyComplianceEorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyComplianceEorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyComplianceEorTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyComplianceEorTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyComplianceEorTypeQuery,
    GetCompanyComplianceEorTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyComplianceEorTypeQuery,
    GetCompanyComplianceEorTypeQueryVariables
  >(GetCompanyComplianceEorTypeDocument, options);
}
export function useGetCompanyComplianceEorTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyComplianceEorTypeQuery,
    GetCompanyComplianceEorTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyComplianceEorTypeQuery,
    GetCompanyComplianceEorTypeQueryVariables
  >(GetCompanyComplianceEorTypeDocument, options);
}
export type GetCompanyComplianceEorTypeQueryHookResult = ReturnType<
  typeof useGetCompanyComplianceEorTypeQuery
>;
export type GetCompanyComplianceEorTypeLazyQueryHookResult = ReturnType<
  typeof useGetCompanyComplianceEorTypeLazyQuery
>;
export type GetCompanyComplianceEorTypeQueryResult = Apollo.QueryResult<
  GetCompanyComplianceEorTypeQuery,
  GetCompanyComplianceEorTypeQueryVariables
>;
export const GetCompanyPayrollCycleAvailableReportsDocument = gql`
  query GetCompanyPayrollCycleAvailableReports($companyPayrollCycleId: ID!) {
    companyPayrollCycleReports(companyPayrollCycleId: $companyPayrollCycleId)
  }
`;

/**
 * __useGetCompanyPayrollCycleAvailableReportsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollCycleAvailableReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollCycleAvailableReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollCycleAvailableReportsQuery({
 *   variables: {
 *      companyPayrollCycleId: // value for 'companyPayrollCycleId'
 *   },
 * });
 */
export function useGetCompanyPayrollCycleAvailableReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollCycleAvailableReportsQuery,
    GetCompanyPayrollCycleAvailableReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollCycleAvailableReportsQuery,
    GetCompanyPayrollCycleAvailableReportsQueryVariables
  >(GetCompanyPayrollCycleAvailableReportsDocument, options);
}
export function useGetCompanyPayrollCycleAvailableReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollCycleAvailableReportsQuery,
    GetCompanyPayrollCycleAvailableReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollCycleAvailableReportsQuery,
    GetCompanyPayrollCycleAvailableReportsQueryVariables
  >(GetCompanyPayrollCycleAvailableReportsDocument, options);
}
export type GetCompanyPayrollCycleAvailableReportsQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleAvailableReportsQuery
>;
export type GetCompanyPayrollCycleAvailableReportsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleAvailableReportsLazyQuery
>;
export type GetCompanyPayrollCycleAvailableReportsQueryResult = Apollo.QueryResult<
  GetCompanyPayrollCycleAvailableReportsQuery,
  GetCompanyPayrollCycleAvailableReportsQueryVariables
>;
export const GetCompanyPayrollCycleOutputHeadcountSummaryDocument = gql`
  query GetCompanyPayrollCycleOutputHeadcountSummary($id: ID!) {
    companyPayrollCycle(id: $id) {
      id
      payrollCycles {
        id
        outputHeadcountSummary {
          starter
          leaver
          active
          total
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayrollCycleOutputHeadcountSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollCycleOutputHeadcountSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollCycleOutputHeadcountSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollCycleOutputHeadcountSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPayrollCycleOutputHeadcountSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollCycleOutputHeadcountSummaryQuery,
    GetCompanyPayrollCycleOutputHeadcountSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollCycleOutputHeadcountSummaryQuery,
    GetCompanyPayrollCycleOutputHeadcountSummaryQueryVariables
  >(GetCompanyPayrollCycleOutputHeadcountSummaryDocument, options);
}
export function useGetCompanyPayrollCycleOutputHeadcountSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollCycleOutputHeadcountSummaryQuery,
    GetCompanyPayrollCycleOutputHeadcountSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollCycleOutputHeadcountSummaryQuery,
    GetCompanyPayrollCycleOutputHeadcountSummaryQueryVariables
  >(GetCompanyPayrollCycleOutputHeadcountSummaryDocument, options);
}
export type GetCompanyPayrollCycleOutputHeadcountSummaryQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleOutputHeadcountSummaryQuery
>;
export type GetCompanyPayrollCycleOutputHeadcountSummaryLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleOutputHeadcountSummaryLazyQuery
>;
export type GetCompanyPayrollCycleOutputHeadcountSummaryQueryResult = Apollo.QueryResult<
  GetCompanyPayrollCycleOutputHeadcountSummaryQuery,
  GetCompanyPayrollCycleOutputHeadcountSummaryQueryVariables
>;
export const GetCompanyPayrollCycleOutputSummaryDocument = gql`
  query GetCompanyPayrollCycleOutputSummary($id: ID!) {
    companyPayrollCycle(id: $id) {
      id
      payrollCycles {
        id
        payrollOutputSummary {
          grossSalary
          expenses
          employeeDeductions
          netPay
          employerContributions
          totalPayrollCost
        }
        previousCycle {
          payrollOutputSummary {
            grossSalary
            expenses
            employeeDeductions
            netPay
            employerContributions
            totalPayrollCost
          }
        }
        outputVariance {
          grossSalaryVariance
          expensesVariance
          employeeDeductionsVariance
          netPayVariance
          employerContributionsVariance
          totalPayrollCostVariance
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayrollCycleOutputSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollCycleOutputSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollCycleOutputSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollCycleOutputSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPayrollCycleOutputSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollCycleOutputSummaryQuery,
    GetCompanyPayrollCycleOutputSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollCycleOutputSummaryQuery,
    GetCompanyPayrollCycleOutputSummaryQueryVariables
  >(GetCompanyPayrollCycleOutputSummaryDocument, options);
}
export function useGetCompanyPayrollCycleOutputSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollCycleOutputSummaryQuery,
    GetCompanyPayrollCycleOutputSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollCycleOutputSummaryQuery,
    GetCompanyPayrollCycleOutputSummaryQueryVariables
  >(GetCompanyPayrollCycleOutputSummaryDocument, options);
}
export type GetCompanyPayrollCycleOutputSummaryQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleOutputSummaryQuery
>;
export type GetCompanyPayrollCycleOutputSummaryLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleOutputSummaryLazyQuery
>;
export type GetCompanyPayrollCycleOutputSummaryQueryResult = Apollo.QueryResult<
  GetCompanyPayrollCycleOutputSummaryQuery,
  GetCompanyPayrollCycleOutputSummaryQueryVariables
>;
export const GetCompanyPayrollCyclePayslipSummaryDocument = gql`
  query GetCompanyPayrollCyclePayslipSummary($id: ID!) {
    companyPayrollCycle(id: $id) {
      id
      payrollCycles {
        id
        payslipSummary {
          published
          readyToPublish
          totalMembers
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayrollCyclePayslipSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollCyclePayslipSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollCyclePayslipSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollCyclePayslipSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPayrollCyclePayslipSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollCyclePayslipSummaryQuery,
    GetCompanyPayrollCyclePayslipSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollCyclePayslipSummaryQuery,
    GetCompanyPayrollCyclePayslipSummaryQueryVariables
  >(GetCompanyPayrollCyclePayslipSummaryDocument, options);
}
export function useGetCompanyPayrollCyclePayslipSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollCyclePayslipSummaryQuery,
    GetCompanyPayrollCyclePayslipSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollCyclePayslipSummaryQuery,
    GetCompanyPayrollCyclePayslipSummaryQueryVariables
  >(GetCompanyPayrollCyclePayslipSummaryDocument, options);
}
export type GetCompanyPayrollCyclePayslipSummaryQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCyclePayslipSummaryQuery
>;
export type GetCompanyPayrollCyclePayslipSummaryLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCyclePayslipSummaryLazyQuery
>;
export type GetCompanyPayrollCyclePayslipSummaryQueryResult = Apollo.QueryResult<
  GetCompanyPayrollCyclePayslipSummaryQuery,
  GetCompanyPayrollCyclePayslipSummaryQueryVariables
>;
export const GetCompanyPayrollCycleSummaryDocument = gql`
  query GetCompanyPayrollCycleSummary($id: ID!) {
    companyPayrollCycle(id: $id) {
      id
      status
      payrollCutOffDate
      payDate
      frequency
      startDate
      endDate
      company {
        id
        displayName
        primaryEntity {
          id
          legalName
          address {
            country
          }
        }
      }
      entity {
        id
        legalName
        currency
        address {
          country
        }
      }
      entityType
      payrollCycles {
        id
        inputHeadcountSummary {
          previousCount
          newJoiner
          leaver
          totalCount
        }
        employeeDataChanges {
          bankDetails
          compensation
        }
        previousCycle {
          id
        }
        status
      }
      summary {
        memberTotalCount
      }
    }
  }
`;

/**
 * __useGetCompanyPayrollCycleSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollCycleSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollCycleSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollCycleSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPayrollCycleSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollCycleSummaryQuery,
    GetCompanyPayrollCycleSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollCycleSummaryQuery,
    GetCompanyPayrollCycleSummaryQueryVariables
  >(GetCompanyPayrollCycleSummaryDocument, options);
}
export function useGetCompanyPayrollCycleSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollCycleSummaryQuery,
    GetCompanyPayrollCycleSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollCycleSummaryQuery,
    GetCompanyPayrollCycleSummaryQueryVariables
  >(GetCompanyPayrollCycleSummaryDocument, options);
}
export type GetCompanyPayrollCycleSummaryQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleSummaryQuery
>;
export type GetCompanyPayrollCycleSummaryLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleSummaryLazyQuery
>;
export type GetCompanyPayrollCycleSummaryQueryResult = Apollo.QueryResult<
  GetCompanyPayrollCycleSummaryQuery,
  GetCompanyPayrollCycleSummaryQueryVariables
>;
export const GetCompanyPayrollCycleDocument = gql`
  query GetCompanyPayrollCycle(
    $id: ID!
    $filter: CompanyPayrollInputFilter
    $pageRequest: PageRequest!
  ) {
    companyPayrollCycle(id: $id) {
      id
      status
      payrollMonth {
        month
        year
      }
      company {
        id
        displayName
        primaryEntity {
          id
          currency
        }
      }
      payrollCutOffDate
      payDate
      frequency
      startDate
      endDate
      confirmByDate
      createdOn
      entityType
      companyPayrollInput(filter: $filter, pageRequest: $pageRequest) {
        pageResult {
          count
          pageSize
          pageNumber
        }
        data {
          id
          contract {
            id
            position
            employeeId
          }
          contractId
          country
          memberName
          baseSalary
          memberCurrency
          companyCurrency
          memberToCompanyCurrencyExchangeRate
          contractStatus
          payFrequency
          rateFrequency
          contractPayrollLabels
          expenses
          allowance
          paySuplements
          expenses
          timeoff
          payrollContractTypes
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayrollCycleQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollCycleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      pageRequest: // value for 'pageRequest'
 *   },
 * });
 */
export function useGetCompanyPayrollCycleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollCycleQuery,
    GetCompanyPayrollCycleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollCycleQuery,
    GetCompanyPayrollCycleQueryVariables
  >(GetCompanyPayrollCycleDocument, options);
}
export function useGetCompanyPayrollCycleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollCycleQuery,
    GetCompanyPayrollCycleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollCycleQuery,
    GetCompanyPayrollCycleQueryVariables
  >(GetCompanyPayrollCycleDocument, options);
}
export type GetCompanyPayrollCycleQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleQuery
>;
export type GetCompanyPayrollCycleLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCycleLazyQuery
>;
export type GetCompanyPayrollCycleQueryResult = Apollo.QueryResult<
  GetCompanyPayrollCycleQuery,
  GetCompanyPayrollCycleQueryVariables
>;
export const GetCompanyPayrollCyclesDocument = gql`
  query GetCompanyPayrollCycles($filter: CompanyPayrollCycleFilter!) {
    company {
      id
      displayName
      primaryEntity {
        id
        legalName
        address {
          country
        }
      }
      companyPayrollCycles(filter: $filter) {
        id
        status
        payrollMonth {
          month
          year
        }
        entity {
          id
          legalName
          address {
            country
          }
        }
        entityType
        payrollCutOffDate
        payDate
        frequency
        startDate
        endDate
        confirmByDate
        createdOn
        payrollCycles {
          id
          approvePayrollInputDeadline
          approvePayrollReportDeadline
        }
        summary {
          countryTotalCounts {
            country
            count
          }
          memberTotalCount
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPayrollCyclesQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollCyclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollCyclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollCyclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompanyPayrollCyclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollCyclesQuery,
    GetCompanyPayrollCyclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollCyclesQuery,
    GetCompanyPayrollCyclesQueryVariables
  >(GetCompanyPayrollCyclesDocument, options);
}
export function useGetCompanyPayrollCyclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollCyclesQuery,
    GetCompanyPayrollCyclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollCyclesQuery,
    GetCompanyPayrollCyclesQueryVariables
  >(GetCompanyPayrollCyclesDocument, options);
}
export type GetCompanyPayrollCyclesQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCyclesQuery
>;
export type GetCompanyPayrollCyclesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollCyclesLazyQuery
>;
export type GetCompanyPayrollCyclesQueryResult = Apollo.QueryResult<
  GetCompanyPayrollCyclesQuery,
  GetCompanyPayrollCyclesQueryVariables
>;
export const GetCompanyPayrollEntityTypesDocument = gql`
  query GetCompanyPayrollEntityTypes {
    companyPayrollEntityTypes
  }
`;

/**
 * __useGetCompanyPayrollEntityTypesQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollEntityTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollEntityTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollEntityTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPayrollEntityTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyPayrollEntityTypesQuery,
    GetCompanyPayrollEntityTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollEntityTypesQuery,
    GetCompanyPayrollEntityTypesQueryVariables
  >(GetCompanyPayrollEntityTypesDocument, options);
}
export function useGetCompanyPayrollEntityTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollEntityTypesQuery,
    GetCompanyPayrollEntityTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollEntityTypesQuery,
    GetCompanyPayrollEntityTypesQueryVariables
  >(GetCompanyPayrollEntityTypesDocument, options);
}
export type GetCompanyPayrollEntityTypesQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollEntityTypesQuery
>;
export type GetCompanyPayrollEntityTypesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollEntityTypesLazyQuery
>;
export type GetCompanyPayrollEntityTypesQueryResult = Apollo.QueryResult<
  GetCompanyPayrollEntityTypesQuery,
  GetCompanyPayrollEntityTypesQueryVariables
>;
export const GetCompanyPayrollMemberPayCountsDocument = gql`
  query GetCompanyPayrollMemberPayCounts(
    $payrollFilter: CompanyPayrollFilter!
    $pageRequest: PageRequest
  ) {
    companyPayrollMemberPays(
      payrollFilter: $payrollFilter
      pageRequest: $pageRequest
    ) {
      employeeCount
      countryCount
    }
  }
`;

/**
 * __useGetCompanyPayrollMemberPayCountsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollMemberPayCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollMemberPayCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollMemberPayCountsQuery({
 *   variables: {
 *      payrollFilter: // value for 'payrollFilter'
 *      pageRequest: // value for 'pageRequest'
 *   },
 * });
 */
export function useGetCompanyPayrollMemberPayCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollMemberPayCountsQuery,
    GetCompanyPayrollMemberPayCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollMemberPayCountsQuery,
    GetCompanyPayrollMemberPayCountsQueryVariables
  >(GetCompanyPayrollMemberPayCountsDocument, options);
}
export function useGetCompanyPayrollMemberPayCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollMemberPayCountsQuery,
    GetCompanyPayrollMemberPayCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollMemberPayCountsQuery,
    GetCompanyPayrollMemberPayCountsQueryVariables
  >(GetCompanyPayrollMemberPayCountsDocument, options);
}
export type GetCompanyPayrollMemberPayCountsQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollMemberPayCountsQuery
>;
export type GetCompanyPayrollMemberPayCountsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollMemberPayCountsLazyQuery
>;
export type GetCompanyPayrollMemberPayCountsQueryResult = Apollo.QueryResult<
  GetCompanyPayrollMemberPayCountsQuery,
  GetCompanyPayrollMemberPayCountsQueryVariables
>;
export const GetCompanyPayrollMemberPaysDocument = gql`
  query GetCompanyPayrollMemberPays(
    $payrollFilter: CompanyPayrollFilter!
    $pageRequest: PageRequest
  ) {
    companyPayrollMemberPays(
      payrollFilter: $payrollFilter
      pageRequest: $pageRequest
    ) {
      data {
        id
        contract {
          id
          employeeId
          status
          country
          position
          member {
            id
            firstName
            lastName
          }
        }
        currency
        amountGross
        totalContributionAmount
        totalDeductionsAmount
        totalExpenseAmount
        amountNet
        amountTotalCost
      }
      employeeCount
      countryCount
      pageResult {
        count
        pageNumber
        pageSize
      }
    }
  }
`;

/**
 * __useGetCompanyPayrollMemberPaysQuery__
 *
 * To run a query within a React component, call `useGetCompanyPayrollMemberPaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPayrollMemberPaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPayrollMemberPaysQuery({
 *   variables: {
 *      payrollFilter: // value for 'payrollFilter'
 *      pageRequest: // value for 'pageRequest'
 *   },
 * });
 */
export function useGetCompanyPayrollMemberPaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPayrollMemberPaysQuery,
    GetCompanyPayrollMemberPaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPayrollMemberPaysQuery,
    GetCompanyPayrollMemberPaysQueryVariables
  >(GetCompanyPayrollMemberPaysDocument, options);
}
export function useGetCompanyPayrollMemberPaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPayrollMemberPaysQuery,
    GetCompanyPayrollMemberPaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPayrollMemberPaysQuery,
    GetCompanyPayrollMemberPaysQueryVariables
  >(GetCompanyPayrollMemberPaysDocument, options);
}
export type GetCompanyPayrollMemberPaysQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollMemberPaysQuery
>;
export type GetCompanyPayrollMemberPaysLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPayrollMemberPaysLazyQuery
>;
export type GetCompanyPayrollMemberPaysQueryResult = Apollo.QueryResult<
  GetCompanyPayrollMemberPaysQuery,
  GetCompanyPayrollMemberPaysQueryVariables
>;
export const GetEligibleCountriesForEorDocument = gql`
  query GetEligibleCountriesForEor(
    $payrollCycleEntityTypes: [PayrollCycleEntityType!]!
  ) {
    eligibleCountriesForEor(payrollCycleEntityTypes: $payrollCycleEntityTypes) {
      countryCode
      entityType
    }
  }
`;

/**
 * __useGetEligibleCountriesForEorQuery__
 *
 * To run a query within a React component, call `useGetEligibleCountriesForEorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibleCountriesForEorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibleCountriesForEorQuery({
 *   variables: {
 *      payrollCycleEntityTypes: // value for 'payrollCycleEntityTypes'
 *   },
 * });
 */
export function useGetEligibleCountriesForEorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEligibleCountriesForEorQuery,
    GetEligibleCountriesForEorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEligibleCountriesForEorQuery,
    GetEligibleCountriesForEorQueryVariables
  >(GetEligibleCountriesForEorDocument, options);
}
export function useGetEligibleCountriesForEorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEligibleCountriesForEorQuery,
    GetEligibleCountriesForEorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEligibleCountriesForEorQuery,
    GetEligibleCountriesForEorQueryVariables
  >(GetEligibleCountriesForEorDocument, options);
}
export type GetEligibleCountriesForEorQueryHookResult = ReturnType<
  typeof useGetEligibleCountriesForEorQuery
>;
export type GetEligibleCountriesForEorLazyQueryHookResult = ReturnType<
  typeof useGetEligibleCountriesForEorLazyQuery
>;
export type GetEligibleCountriesForEorQueryResult = Apollo.QueryResult<
  GetEligibleCountriesForEorQuery,
  GetEligibleCountriesForEorQueryVariables
>;
export const GetEligibleEntitiesForPeoDocument = gql`
  query GetEligibleEntitiesForPeo(
    $payrollCycleEntityType: PayrollCycleEntityType!
  ) {
    eligibleEntitiesForPeo(payrollCycleEntityType: $payrollCycleEntityType) {
      id
      name
    }
  }
`;

/**
 * __useGetEligibleEntitiesForPeoQuery__
 *
 * To run a query within a React component, call `useGetEligibleEntitiesForPeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibleEntitiesForPeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibleEntitiesForPeoQuery({
 *   variables: {
 *      payrollCycleEntityType: // value for 'payrollCycleEntityType'
 *   },
 * });
 */
export function useGetEligibleEntitiesForPeoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEligibleEntitiesForPeoQuery,
    GetEligibleEntitiesForPeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEligibleEntitiesForPeoQuery,
    GetEligibleEntitiesForPeoQueryVariables
  >(GetEligibleEntitiesForPeoDocument, options);
}
export function useGetEligibleEntitiesForPeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEligibleEntitiesForPeoQuery,
    GetEligibleEntitiesForPeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEligibleEntitiesForPeoQuery,
    GetEligibleEntitiesForPeoQueryVariables
  >(GetEligibleEntitiesForPeoDocument, options);
}
export type GetEligibleEntitiesForPeoQueryHookResult = ReturnType<
  typeof useGetEligibleEntitiesForPeoQuery
>;
export type GetEligibleEntitiesForPeoLazyQueryHookResult = ReturnType<
  typeof useGetEligibleEntitiesForPeoLazyQuery
>;
export type GetEligibleEntitiesForPeoQueryResult = Apollo.QueryResult<
  GetEligibleEntitiesForPeoQuery,
  GetEligibleEntitiesForPeoQueryVariables
>;
export const GetPayrollBulkUploadConfirmStatusDocument = gql`
  query GetPayrollBulkUploadConfirmStatus($payrollCycleId: ID!) {
    payrollBulkUploadConfirmStatus(payrollCycleId: $payrollCycleId)
  }
`;

/**
 * __useGetPayrollBulkUploadConfirmStatusQuery__
 *
 * To run a query within a React component, call `useGetPayrollBulkUploadConfirmStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollBulkUploadConfirmStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollBulkUploadConfirmStatusQuery({
 *   variables: {
 *      payrollCycleId: // value for 'payrollCycleId'
 *   },
 * });
 */
export function useGetPayrollBulkUploadConfirmStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollBulkUploadConfirmStatusQuery,
    GetPayrollBulkUploadConfirmStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayrollBulkUploadConfirmStatusQuery,
    GetPayrollBulkUploadConfirmStatusQueryVariables
  >(GetPayrollBulkUploadConfirmStatusDocument, options);
}
export function useGetPayrollBulkUploadConfirmStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollBulkUploadConfirmStatusQuery,
    GetPayrollBulkUploadConfirmStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollBulkUploadConfirmStatusQuery,
    GetPayrollBulkUploadConfirmStatusQueryVariables
  >(GetPayrollBulkUploadConfirmStatusDocument, options);
}
export type GetPayrollBulkUploadConfirmStatusQueryHookResult = ReturnType<
  typeof useGetPayrollBulkUploadConfirmStatusQuery
>;
export type GetPayrollBulkUploadConfirmStatusLazyQueryHookResult = ReturnType<
  typeof useGetPayrollBulkUploadConfirmStatusLazyQuery
>;
export type GetPayrollBulkUploadConfirmStatusQueryResult = Apollo.QueryResult<
  GetPayrollBulkUploadConfirmStatusQuery,
  GetPayrollBulkUploadConfirmStatusQueryVariables
>;
export const GetPayrollCyclesForCalendarDocument = gql`
  query GetPayrollCyclesForCalendar($filter: PayrollCalendarFilters!) {
    payrollCyclesForCalendar(filter: $filter) {
      id
      configId
      startDate
      endDate
      defaultStartDate
      payDate
      defaultPayDate
      cutOffTo
      defaultCutOffTo
      approvePayrollReportDeadline
      country
      entity {
        id
        company {
          primaryEntity {
            address {
              country
            }
          }
        }
        entityType
        name
      }
    }
  }
`;

/**
 * __useGetPayrollCyclesForCalendarQuery__
 *
 * To run a query within a React component, call `useGetPayrollCyclesForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollCyclesForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollCyclesForCalendarQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPayrollCyclesForCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollCyclesForCalendarQuery,
    GetPayrollCyclesForCalendarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayrollCyclesForCalendarQuery,
    GetPayrollCyclesForCalendarQueryVariables
  >(GetPayrollCyclesForCalendarDocument, options);
}
export function useGetPayrollCyclesForCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollCyclesForCalendarQuery,
    GetPayrollCyclesForCalendarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollCyclesForCalendarQuery,
    GetPayrollCyclesForCalendarQueryVariables
  >(GetPayrollCyclesForCalendarDocument, options);
}
export type GetPayrollCyclesForCalendarQueryHookResult = ReturnType<
  typeof useGetPayrollCyclesForCalendarQuery
>;
export type GetPayrollCyclesForCalendarLazyQueryHookResult = ReturnType<
  typeof useGetPayrollCyclesForCalendarLazyQuery
>;
export type GetPayrollCyclesForCalendarQueryResult = Apollo.QueryResult<
  GetPayrollCyclesForCalendarQuery,
  GetPayrollCyclesForCalendarQueryVariables
>;
export const PayrollBulkUploadConfirmDocument = gql`
  mutation PayrollBulkUploadConfirm($payrollCycleId: ID!) {
    payrollBulkUploadConfirm(payrollCycleId: $payrollCycleId) {
      success
      message
    }
  }
`;
export type PayrollBulkUploadConfirmMutationFn = Apollo.MutationFunction<
  PayrollBulkUploadConfirmMutation,
  PayrollBulkUploadConfirmMutationVariables
>;

/**
 * __usePayrollBulkUploadConfirmMutation__
 *
 * To run a mutation, you first call `usePayrollBulkUploadConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollBulkUploadConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollBulkUploadConfirmMutation, { data, loading, error }] = usePayrollBulkUploadConfirmMutation({
 *   variables: {
 *      payrollCycleId: // value for 'payrollCycleId'
 *   },
 * });
 */
export function usePayrollBulkUploadConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollBulkUploadConfirmMutation,
    PayrollBulkUploadConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollBulkUploadConfirmMutation,
    PayrollBulkUploadConfirmMutationVariables
  >(PayrollBulkUploadConfirmDocument, options);
}
export type PayrollBulkUploadConfirmMutationHookResult = ReturnType<
  typeof usePayrollBulkUploadConfirmMutation
>;
export type PayrollBulkUploadConfirmMutationResult = Apollo.MutationResult<PayrollBulkUploadConfirmMutation>;
export type PayrollBulkUploadConfirmMutationOptions = Apollo.BaseMutationOptions<
  PayrollBulkUploadConfirmMutation,
  PayrollBulkUploadConfirmMutationVariables
>;
export const PayrollBulkUploadValidateDocument = gql`
  mutation PayrollBulkUploadValidate($payrollCycleId: ID!) {
    payrollBulkUploadValidate(payrollCycleId: $payrollCycleId) {
      bulkUploadSummary {
        totalExpenses
        totalPaySupplements
        totalTimeOff
      }
      validationResponse {
        results {
          type
          message
        }
        succeeded
      }
      bulkUploadFile {
        name
        extension
        downloadUrl
      }
      errorReport {
        id
        status
        payrollFile {
          id
          downloadUrl
          name
          extension
        }
      }
    }
  }
`;
export type PayrollBulkUploadValidateMutationFn = Apollo.MutationFunction<
  PayrollBulkUploadValidateMutation,
  PayrollBulkUploadValidateMutationVariables
>;

/**
 * __usePayrollBulkUploadValidateMutation__
 *
 * To run a mutation, you first call `usePayrollBulkUploadValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollBulkUploadValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollBulkUploadValidateMutation, { data, loading, error }] = usePayrollBulkUploadValidateMutation({
 *   variables: {
 *      payrollCycleId: // value for 'payrollCycleId'
 *   },
 * });
 */
export function usePayrollBulkUploadValidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollBulkUploadValidateMutation,
    PayrollBulkUploadValidateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollBulkUploadValidateMutation,
    PayrollBulkUploadValidateMutationVariables
  >(PayrollBulkUploadValidateDocument, options);
}
export type PayrollBulkUploadValidateMutationHookResult = ReturnType<
  typeof usePayrollBulkUploadValidateMutation
>;
export type PayrollBulkUploadValidateMutationResult = Apollo.MutationResult<PayrollBulkUploadValidateMutation>;
export type PayrollBulkUploadValidateMutationOptions = Apollo.BaseMutationOptions<
  PayrollBulkUploadValidateMutation,
  PayrollBulkUploadValidateMutationVariables
>;
export const PublishPayslipsDocument = gql`
  mutation PublishPayslips($companyPayrollCycleId: ID!) {
    companyPayrollPayslipPublish(
      companyPayrollCycleId: $companyPayrollCycleId
    ) {
      publishPayslipsSummary {
        publishedPayslipsCount
      }
    }
  }
`;
export type PublishPayslipsMutationFn = Apollo.MutationFunction<
  PublishPayslipsMutation,
  PublishPayslipsMutationVariables
>;

/**
 * __usePublishPayslipsMutation__
 *
 * To run a mutation, you first call `usePublishPayslipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPayslipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPayslipsMutation, { data, loading, error }] = usePublishPayslipsMutation({
 *   variables: {
 *      companyPayrollCycleId: // value for 'companyPayrollCycleId'
 *   },
 * });
 */
export function usePublishPayslipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishPayslipsMutation,
    PublishPayslipsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishPayslipsMutation,
    PublishPayslipsMutationVariables
  >(PublishPayslipsDocument, options);
}
export type PublishPayslipsMutationHookResult = ReturnType<
  typeof usePublishPayslipsMutation
>;
export type PublishPayslipsMutationResult = Apollo.MutationResult<PublishPayslipsMutation>;
export type PublishPayslipsMutationOptions = Apollo.BaseMutationOptions<
  PublishPayslipsMutation,
  PublishPayslipsMutationVariables
>;
export const UpdateDemoCyclesDocument = gql`
  mutation UpdateDemoCycles($value: UpdateDemoCyclesInput!) {
    updateDemoCycles(value: $value) {
      id
      status
    }
  }
`;
export type UpdateDemoCyclesMutationFn = Apollo.MutationFunction<
  UpdateDemoCyclesMutation,
  UpdateDemoCyclesMutationVariables
>;

/**
 * __useUpdateDemoCyclesMutation__
 *
 * To run a mutation, you first call `useUpdateDemoCyclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDemoCyclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDemoCyclesMutation, { data, loading, error }] = useUpdateDemoCyclesMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateDemoCyclesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDemoCyclesMutation,
    UpdateDemoCyclesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDemoCyclesMutation,
    UpdateDemoCyclesMutationVariables
  >(UpdateDemoCyclesDocument, options);
}
export type UpdateDemoCyclesMutationHookResult = ReturnType<
  typeof useUpdateDemoCyclesMutation
>;
export type UpdateDemoCyclesMutationResult = Apollo.MutationResult<UpdateDemoCyclesMutation>;
export type UpdateDemoCyclesMutationOptions = Apollo.BaseMutationOptions<
  UpdateDemoCyclesMutation,
  UpdateDemoCyclesMutationVariables
>;
export const GetLatestMemberPayrollCyclesDocument = gql`
  query GetLatestMemberPayrollCycles {
    latestMemberPayrollCycles {
      payrollCycleDetails {
        payrollCycle {
          id
          configId
          startDate
          endDate
          frequency
          cutOffTo
        }
        memberPay {
          amountGross
          currency
          amountNet
        }
      }
    }
  }
`;

/**
 * __useGetLatestMemberPayrollCyclesQuery__
 *
 * To run a query within a React component, call `useGetLatestMemberPayrollCyclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestMemberPayrollCyclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestMemberPayrollCyclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestMemberPayrollCyclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestMemberPayrollCyclesQuery,
    GetLatestMemberPayrollCyclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestMemberPayrollCyclesQuery,
    GetLatestMemberPayrollCyclesQueryVariables
  >(GetLatestMemberPayrollCyclesDocument, options);
}
export function useGetLatestMemberPayrollCyclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestMemberPayrollCyclesQuery,
    GetLatestMemberPayrollCyclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestMemberPayrollCyclesQuery,
    GetLatestMemberPayrollCyclesQueryVariables
  >(GetLatestMemberPayrollCyclesDocument, options);
}
export type GetLatestMemberPayrollCyclesQueryHookResult = ReturnType<
  typeof useGetLatestMemberPayrollCyclesQuery
>;
export type GetLatestMemberPayrollCyclesLazyQueryHookResult = ReturnType<
  typeof useGetLatestMemberPayrollCyclesLazyQuery
>;
export type GetLatestMemberPayrollCyclesQueryResult = Apollo.QueryResult<
  GetLatestMemberPayrollCyclesQuery,
  GetLatestMemberPayrollCyclesQueryVariables
>;
export const GetLatestMemberPayrollSummaryDocument = gql`
  query GetLatestMemberPayrollSummary {
    latestMemberPayrollCycles {
      payrollCycleDetails {
        payrollCycle {
          id
          startDate
          endDate
        }
        memberPay {
          aggregatedGross
          amountGross
          amountNet
          currency
        }
        summary {
          paySupplement {
            ...memberPayrollCycleSummaryItem
          }
          expense {
            ...memberPayrollCycleSummaryItem
          }
          fixedAllowance {
            ...memberPayrollCycleSummaryItem
          }
          unpaidTimeOff {
            ...memberPayrollCycleSummaryItem
          }
          deductions {
            ...memberPayrollCycleSummaryItem
          }
        }
      }
    }
  }
  ${MemberPayrollCycleSummaryItemFragmentDoc}
`;

/**
 * __useGetLatestMemberPayrollSummaryQuery__
 *
 * To run a query within a React component, call `useGetLatestMemberPayrollSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestMemberPayrollSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestMemberPayrollSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestMemberPayrollSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestMemberPayrollSummaryQuery,
    GetLatestMemberPayrollSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestMemberPayrollSummaryQuery,
    GetLatestMemberPayrollSummaryQueryVariables
  >(GetLatestMemberPayrollSummaryDocument, options);
}
export function useGetLatestMemberPayrollSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestMemberPayrollSummaryQuery,
    GetLatestMemberPayrollSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestMemberPayrollSummaryQuery,
    GetLatestMemberPayrollSummaryQueryVariables
  >(GetLatestMemberPayrollSummaryDocument, options);
}
export type GetLatestMemberPayrollSummaryQueryHookResult = ReturnType<
  typeof useGetLatestMemberPayrollSummaryQuery
>;
export type GetLatestMemberPayrollSummaryLazyQueryHookResult = ReturnType<
  typeof useGetLatestMemberPayrollSummaryLazyQuery
>;
export type GetLatestMemberPayrollSummaryQueryResult = Apollo.QueryResult<
  GetLatestMemberPayrollSummaryQuery,
  GetLatestMemberPayrollSummaryQueryVariables
>;
export const GetMemberPayrollCycleDetailsDocument = gql`
  query GetMemberPayrollCycleDetails($filter: MemberPayrollCycleDetailFilter!) {
    memberPayrollCycleDetail(filter: $filter) {
      payrollCycle {
        id
        configId
        startDate
        endDate
        frequency
        status
        cutOffTo
      }
      memberPay {
        amountGross
        currency
        amountNet
        totalDeductionsAmount
        aggregatedGross
        deductions {
          name
          type
          value
        }
      }
      paySupplements {
        paySupplementId
        amount
        currency
        type
      }
      expenses {
        expenseId
        totalAmount
        currency
        expenseTitle
      }
      fixedAllowances {
        id
        amount
        currency
        label
      }
      unpaidTimeOffs {
        timeOffId
        noOfDays
      }
    }
  }
`;

/**
 * __useGetMemberPayrollCycleDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberPayrollCycleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPayrollCycleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPayrollCycleDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMemberPayrollCycleDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberPayrollCycleDetailsQuery,
    GetMemberPayrollCycleDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPayrollCycleDetailsQuery,
    GetMemberPayrollCycleDetailsQueryVariables
  >(GetMemberPayrollCycleDetailsDocument, options);
}
export function useGetMemberPayrollCycleDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPayrollCycleDetailsQuery,
    GetMemberPayrollCycleDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPayrollCycleDetailsQuery,
    GetMemberPayrollCycleDetailsQueryVariables
  >(GetMemberPayrollCycleDetailsDocument, options);
}
export type GetMemberPayrollCycleDetailsQueryHookResult = ReturnType<
  typeof useGetMemberPayrollCycleDetailsQuery
>;
export type GetMemberPayrollCycleDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberPayrollCycleDetailsLazyQuery
>;
export type GetMemberPayrollCycleDetailsQueryResult = Apollo.QueryResult<
  GetMemberPayrollCycleDetailsQuery,
  GetMemberPayrollCycleDetailsQueryVariables
>;
export const GetMemberPayrollCyclesDocument = gql`
  query GetMemberPayrollCycles($filter: MemberPayrollCycleFilter!) {
    memberPayrollCycles(filter: $filter) {
      payrollCycleDetails {
        payrollCycle {
          id
          configId
          startDate
          endDate
          frequency
        }
        memberPay {
          amountGross
          currency
          amountNet
          totalDeductionsAmount
        }
      }
    }
  }
`;

/**
 * __useGetMemberPayrollCyclesQuery__
 *
 * To run a query within a React component, call `useGetMemberPayrollCyclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPayrollCyclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPayrollCyclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMemberPayrollCyclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberPayrollCyclesQuery,
    GetMemberPayrollCyclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPayrollCyclesQuery,
    GetMemberPayrollCyclesQueryVariables
  >(GetMemberPayrollCyclesDocument, options);
}
export function useGetMemberPayrollCyclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPayrollCyclesQuery,
    GetMemberPayrollCyclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPayrollCyclesQuery,
    GetMemberPayrollCyclesQueryVariables
  >(GetMemberPayrollCyclesDocument, options);
}
export type GetMemberPayrollCyclesQueryHookResult = ReturnType<
  typeof useGetMemberPayrollCyclesQuery
>;
export type GetMemberPayrollCyclesLazyQueryHookResult = ReturnType<
  typeof useGetMemberPayrollCyclesLazyQuery
>;
export type GetMemberPayrollCyclesQueryResult = Apollo.QueryResult<
  GetMemberPayrollCyclesQuery,
  GetMemberPayrollCyclesQueryVariables
>;
export const GetPayrollDetailsDocument = gql`
  query GetPayrollDetails($id: ID!, $payslipMonth: MonthYearInput!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      isPickedUpForPayroll(payslipMonth: $payslipMonth)
    }
  }
`;

/**
 * __useGetPayrollDetailsQuery__
 *
 * To run a query within a React component, call `useGetPayrollDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      payslipMonth: // value for 'payslipMonth'
 *   },
 * });
 */
export function useGetPayrollDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollDetailsQuery,
    GetPayrollDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayrollDetailsQuery,
    GetPayrollDetailsQueryVariables
  >(GetPayrollDetailsDocument, options);
}
export function useGetPayrollDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollDetailsQuery,
    GetPayrollDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollDetailsQuery,
    GetPayrollDetailsQueryVariables
  >(GetPayrollDetailsDocument, options);
}
export type GetPayrollDetailsQueryHookResult = ReturnType<
  typeof useGetPayrollDetailsQuery
>;
export type GetPayrollDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPayrollDetailsLazyQuery
>;
export type GetPayrollDetailsQueryResult = Apollo.QueryResult<
  GetPayrollDetailsQuery,
  GetPayrollDetailsQueryVariables
>;
export const GetPayslipsDocument = gql`
  query GetPayslips {
    member {
      id
      contracts {
        id
        payslips {
          id
          payrollCycle
          month {
            month
            year
          }
          netAmount
          currencyCode
          createdOn
          payslipDocument {
            downloadUrl
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetPayslipsQuery__
 *
 * To run a query within a React component, call `useGetPayslipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayslipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayslipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayslipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPayslipsQuery,
    GetPayslipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayslipsQuery, GetPayslipsQueryVariables>(
    GetPayslipsDocument,
    options,
  );
}
export function useGetPayslipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayslipsQuery,
    GetPayslipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPayslipsQuery, GetPayslipsQueryVariables>(
    GetPayslipsDocument,
    options,
  );
}
export type GetPayslipsQueryHookResult = ReturnType<typeof useGetPayslipsQuery>;
export type GetPayslipsLazyQueryHookResult = ReturnType<
  typeof useGetPayslipsLazyQuery
>;
export type GetPayslipsQueryResult = Apollo.QueryResult<
  GetPayslipsQuery,
  GetPayslipsQueryVariables
>;
export const DeletePerformanceReviewDocument = gql`
  mutation DeletePerformanceReview($id: ID!) {
    deletePerformanceReview(id: $id) {
      ...performanceReview
      contract {
        id
        country
        member {
          id
          firstName
          lastName
        }
        position
        company {
          id
        }
      }
    }
  }
  ${PerformanceReviewFragmentDoc}
`;
export type DeletePerformanceReviewMutationFn = Apollo.MutationFunction<
  DeletePerformanceReviewMutation,
  DeletePerformanceReviewMutationVariables
>;

/**
 * __useDeletePerformanceReviewMutation__
 *
 * To run a mutation, you first call `useDeletePerformanceReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePerformanceReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePerformanceReviewMutation, { data, loading, error }] = useDeletePerformanceReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePerformanceReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePerformanceReviewMutation,
    DeletePerformanceReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePerformanceReviewMutation,
    DeletePerformanceReviewMutationVariables
  >(DeletePerformanceReviewDocument, options);
}
export type DeletePerformanceReviewMutationHookResult = ReturnType<
  typeof useDeletePerformanceReviewMutation
>;
export type DeletePerformanceReviewMutationResult = Apollo.MutationResult<DeletePerformanceReviewMutation>;
export type DeletePerformanceReviewMutationOptions = Apollo.BaseMutationOptions<
  DeletePerformanceReviewMutation,
  DeletePerformanceReviewMutationVariables
>;
export const GetCompanySignatoriesDocument = gql`
  query GetCompanySignatories {
    company {
      id
      signatories {
        id
        firstName
        lastName
        title
        emails {
          email
        }
      }
    }
  }
`;

/**
 * __useGetCompanySignatoriesQuery__
 *
 * To run a query within a React component, call `useGetCompanySignatoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySignatoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySignatoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanySignatoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanySignatoriesQuery,
    GetCompanySignatoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanySignatoriesQuery,
    GetCompanySignatoriesQueryVariables
  >(GetCompanySignatoriesDocument, options);
}
export function useGetCompanySignatoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanySignatoriesQuery,
    GetCompanySignatoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanySignatoriesQuery,
    GetCompanySignatoriesQueryVariables
  >(GetCompanySignatoriesDocument, options);
}
export type GetCompanySignatoriesQueryHookResult = ReturnType<
  typeof useGetCompanySignatoriesQuery
>;
export type GetCompanySignatoriesLazyQueryHookResult = ReturnType<
  typeof useGetCompanySignatoriesLazyQuery
>;
export type GetCompanySignatoriesQueryResult = Apollo.QueryResult<
  GetCompanySignatoriesQuery,
  GetCompanySignatoriesQueryVariables
>;
export const GetContractPositionAndPayDocument = gql`
  query GetContractPositionAndPay($id: ID!) {
    contract(id: $id) {
      id
      compensation {
        basePay {
          ...fixedPayComponent
        }
        postProbationBasePay {
          ...fixedPayComponent
        }
        probationBasePay {
          ...fixedPayComponent
        }
        additionalPays {
          ...compensationPayComponent
        }
      }
      member {
        id
        firstName
        lastName
        emails {
          email
        }
        legalData {
          key
          value
        }
      }
      position
      scope
      term
      type
      currency
      startOn
      country
      countryStateCode
      legalEntityId
      compliance {
        type
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
`;

/**
 * __useGetContractPositionAndPayQuery__
 *
 * To run a query within a React component, call `useGetContractPositionAndPayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractPositionAndPayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractPositionAndPayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractPositionAndPayQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractPositionAndPayQuery,
    GetContractPositionAndPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractPositionAndPayQuery,
    GetContractPositionAndPayQueryVariables
  >(GetContractPositionAndPayDocument, options);
}
export function useGetContractPositionAndPayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractPositionAndPayQuery,
    GetContractPositionAndPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractPositionAndPayQuery,
    GetContractPositionAndPayQueryVariables
  >(GetContractPositionAndPayDocument, options);
}
export type GetContractPositionAndPayQueryHookResult = ReturnType<
  typeof useGetContractPositionAndPayQuery
>;
export type GetContractPositionAndPayLazyQueryHookResult = ReturnType<
  typeof useGetContractPositionAndPayLazyQuery
>;
export type GetContractPositionAndPayQueryResult = Apollo.QueryResult<
  GetContractPositionAndPayQuery,
  GetContractPositionAndPayQueryVariables
>;
export const GetPerformanceReviewDocument = gql`
  query GetPerformanceReview($id: ID!) {
    company {
      id
      performanceReviews(id: $id) {
        ...performanceReview
        payrollCycle {
          id
          configId
          startDate
          endDate
          status
        }
        reviewDocument {
          id
          name
          extension
          contentType
          ... on DocumentReadable {
            link
            blob
          }
        }
      }
    }
  }
  ${PerformanceReviewFragmentDoc}
`;

/**
 * __useGetPerformanceReviewQuery__
 *
 * To run a query within a React component, call `useGetPerformanceReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformanceReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformanceReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPerformanceReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPerformanceReviewQuery,
    GetPerformanceReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPerformanceReviewQuery,
    GetPerformanceReviewQueryVariables
  >(GetPerformanceReviewDocument, options);
}
export function useGetPerformanceReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPerformanceReviewQuery,
    GetPerformanceReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPerformanceReviewQuery,
    GetPerformanceReviewQueryVariables
  >(GetPerformanceReviewDocument, options);
}
export type GetPerformanceReviewQueryHookResult = ReturnType<
  typeof useGetPerformanceReviewQuery
>;
export type GetPerformanceReviewLazyQueryHookResult = ReturnType<
  typeof useGetPerformanceReviewLazyQuery
>;
export type GetPerformanceReviewQueryResult = Apollo.QueryResult<
  GetPerformanceReviewQuery,
  GetPerformanceReviewQueryVariables
>;
export const GetPerformanceReviewsDocument = gql`
  query GetPerformanceReviews {
    company {
      id
      performanceReviews {
        ...performanceReview
      }
    }
  }
  ${PerformanceReviewFragmentDoc}
`;

/**
 * __useGetPerformanceReviewsQuery__
 *
 * To run a query within a React component, call `useGetPerformanceReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformanceReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformanceReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPerformanceReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPerformanceReviewsQuery,
    GetPerformanceReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPerformanceReviewsQuery,
    GetPerformanceReviewsQueryVariables
  >(GetPerformanceReviewsDocument, options);
}
export function useGetPerformanceReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPerformanceReviewsQuery,
    GetPerformanceReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPerformanceReviewsQuery,
    GetPerformanceReviewsQueryVariables
  >(GetPerformanceReviewsDocument, options);
}
export type GetPerformanceReviewsQueryHookResult = ReturnType<
  typeof useGetPerformanceReviewsQuery
>;
export type GetPerformanceReviewsLazyQueryHookResult = ReturnType<
  typeof useGetPerformanceReviewsLazyQuery
>;
export type GetPerformanceReviewsQueryResult = Apollo.QueryResult<
  GetPerformanceReviewsQuery,
  GetPerformanceReviewsQueryVariables
>;
export const GetTeamCountDocument = gql`
  query GetTeamCount {
    company {
      id
      contracts(status: null) {
        id
      }
    }
  }
`;

/**
 * __useGetTeamCountQuery__
 *
 * To run a query within a React component, call `useGetTeamCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTeamCountQuery,
    GetTeamCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamCountQuery, GetTeamCountQueryVariables>(
    GetTeamCountDocument,
    options,
  );
}
export function useGetTeamCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamCountQuery,
    GetTeamCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamCountQuery, GetTeamCountQueryVariables>(
    GetTeamCountDocument,
    options,
  );
}
export type GetTeamCountQueryHookResult = ReturnType<
  typeof useGetTeamCountQuery
>;
export type GetTeamCountLazyQueryHookResult = ReturnType<
  typeof useGetTeamCountLazyQuery
>;
export type GetTeamCountQueryResult = Apollo.QueryResult<
  GetTeamCountQuery,
  GetTeamCountQueryVariables
>;
export const SendPerformanceReviewToSignatoryDocument = gql`
  mutation SendPerformanceReviewToSignatory($reviewId: ID!) {
    sendToSignatoryPerformanceReview(reviewId: $reviewId) {
      ...performanceReview
      contract {
        id
        country
        member {
          id
          firstName
          lastName
        }
        position
        company {
          id
        }
      }
      reviewDocument {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
          blob
        }
      }
    }
  }
  ${PerformanceReviewFragmentDoc}
`;
export type SendPerformanceReviewToSignatoryMutationFn = Apollo.MutationFunction<
  SendPerformanceReviewToSignatoryMutation,
  SendPerformanceReviewToSignatoryMutationVariables
>;

/**
 * __useSendPerformanceReviewToSignatoryMutation__
 *
 * To run a mutation, you first call `useSendPerformanceReviewToSignatoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPerformanceReviewToSignatoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPerformanceReviewToSignatoryMutation, { data, loading, error }] = useSendPerformanceReviewToSignatoryMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useSendPerformanceReviewToSignatoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPerformanceReviewToSignatoryMutation,
    SendPerformanceReviewToSignatoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPerformanceReviewToSignatoryMutation,
    SendPerformanceReviewToSignatoryMutationVariables
  >(SendPerformanceReviewToSignatoryDocument, options);
}
export type SendPerformanceReviewToSignatoryMutationHookResult = ReturnType<
  typeof useSendPerformanceReviewToSignatoryMutation
>;
export type SendPerformanceReviewToSignatoryMutationResult = Apollo.MutationResult<SendPerformanceReviewToSignatoryMutation>;
export type SendPerformanceReviewToSignatoryMutationOptions = Apollo.BaseMutationOptions<
  SendPerformanceReviewToSignatoryMutation,
  SendPerformanceReviewToSignatoryMutationVariables
>;
export const UpsertPerformanceReviewDocument = gql`
  mutation UpsertPerformanceReview($input: PerformanceReviewInput!) {
    upsertPerformanceReview(review: $input) {
      ...performanceReview
      reviewDocument {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          link
          blob
        }
      }
      contract {
        id
        country
        member {
          id
          firstName
          lastName
        }
        position
        company {
          id
        }
      }
    }
  }
  ${PerformanceReviewFragmentDoc}
`;
export type UpsertPerformanceReviewMutationFn = Apollo.MutationFunction<
  UpsertPerformanceReviewMutation,
  UpsertPerformanceReviewMutationVariables
>;

/**
 * __useUpsertPerformanceReviewMutation__
 *
 * To run a mutation, you first call `useUpsertPerformanceReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPerformanceReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPerformanceReviewMutation, { data, loading, error }] = useUpsertPerformanceReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPerformanceReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPerformanceReviewMutation,
    UpsertPerformanceReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertPerformanceReviewMutation,
    UpsertPerformanceReviewMutationVariables
  >(UpsertPerformanceReviewDocument, options);
}
export type UpsertPerformanceReviewMutationHookResult = ReturnType<
  typeof useUpsertPerformanceReviewMutation
>;
export type UpsertPerformanceReviewMutationResult = Apollo.MutationResult<UpsertPerformanceReviewMutation>;
export type UpsertPerformanceReviewMutationOptions = Apollo.BaseMutationOptions<
  UpsertPerformanceReviewMutation,
  UpsertPerformanceReviewMutationVariables
>;
export const GetPublicExpenseDocument = gql`
  query GetPublicExpense($expenseId: ID!) {
    expense(id: $expenseId) {
      id
      title
      items {
        receipts {
          id
          name
          contentType
          link
          size
        }
        receiptsInForeignCurrency {
          id
          name
          contentType
          link
          size
        }
      }
      currency
      totalAmount
    }
  }
`;

/**
 * __useGetPublicExpenseQuery__
 *
 * To run a query within a React component, call `useGetPublicExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicExpenseQuery({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useGetPublicExpenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicExpenseQuery,
    GetPublicExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicExpenseQuery, GetPublicExpenseQueryVariables>(
    GetPublicExpenseDocument,
    options,
  );
}
export function useGetPublicExpenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicExpenseQuery,
    GetPublicExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicExpenseQuery,
    GetPublicExpenseQueryVariables
  >(GetPublicExpenseDocument, options);
}
export type GetPublicExpenseQueryHookResult = ReturnType<
  typeof useGetPublicExpenseQuery
>;
export type GetPublicExpenseLazyQueryHookResult = ReturnType<
  typeof useGetPublicExpenseLazyQuery
>;
export type GetPublicExpenseQueryResult = Apollo.QueryResult<
  GetPublicExpenseQuery,
  GetPublicExpenseQueryVariables
>;
export const GetPublicIsrByLinkDocument = gql`
  query GetPublicISRByLink($input: InvoiceSourceReportFromLinkInput!) {
    companyPayableDownloadInvoiceSourceReportFromLink(input: $input) {
      blob
      extension
      name
      contentType
    }
  }
`;

/**
 * __useGetPublicIsrByLinkQuery__
 *
 * To run a query within a React component, call `useGetPublicIsrByLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicIsrByLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicIsrByLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPublicIsrByLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicIsrByLinkQuery,
    GetPublicIsrByLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicIsrByLinkQuery,
    GetPublicIsrByLinkQueryVariables
  >(GetPublicIsrByLinkDocument, options);
}
export function useGetPublicIsrByLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicIsrByLinkQuery,
    GetPublicIsrByLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicIsrByLinkQuery,
    GetPublicIsrByLinkQueryVariables
  >(GetPublicIsrByLinkDocument, options);
}
export type GetPublicIsrByLinkQueryHookResult = ReturnType<
  typeof useGetPublicIsrByLinkQuery
>;
export type GetPublicIsrByLinkLazyQueryHookResult = ReturnType<
  typeof useGetPublicIsrByLinkLazyQuery
>;
export type GetPublicIsrByLinkQueryResult = Apollo.QueryResult<
  GetPublicIsrByLinkQuery,
  GetPublicIsrByLinkQueryVariables
>;
export const PlaceholderDocument = gql`
  query Placeholder {
    __typename
  }
`;

/**
 * __usePlaceholderQuery__
 *
 * To run a query within a React component, call `usePlaceholderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceholderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceholderQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlaceholderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlaceholderQuery,
    PlaceholderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaceholderQuery, PlaceholderQueryVariables>(
    PlaceholderDocument,
    options,
  );
}
export function usePlaceholderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaceholderQuery,
    PlaceholderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaceholderQuery, PlaceholderQueryVariables>(
    PlaceholderDocument,
    options,
  );
}
export type PlaceholderQueryHookResult = ReturnType<typeof usePlaceholderQuery>;
export type PlaceholderLazyQueryHookResult = ReturnType<
  typeof usePlaceholderLazyQuery
>;
export type PlaceholderQueryResult = Apollo.QueryResult<
  PlaceholderQuery,
  PlaceholderQueryVariables
>;
export const DownloadCustomPayrollReportDocument = gql`
  query DownloadCustomPayrollReport($id: ID!) {
    downloadCustomPayrollReport(id: $id) {
      id
      blob
      extension
      name
    }
  }
`;

/**
 * __useDownloadCustomPayrollReportQuery__
 *
 * To run a query within a React component, call `useDownloadCustomPayrollReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCustomPayrollReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCustomPayrollReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadCustomPayrollReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadCustomPayrollReportQuery,
    DownloadCustomPayrollReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadCustomPayrollReportQuery,
    DownloadCustomPayrollReportQueryVariables
  >(DownloadCustomPayrollReportDocument, options);
}
export function useDownloadCustomPayrollReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadCustomPayrollReportQuery,
    DownloadCustomPayrollReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadCustomPayrollReportQuery,
    DownloadCustomPayrollReportQueryVariables
  >(DownloadCustomPayrollReportDocument, options);
}
export type DownloadCustomPayrollReportQueryHookResult = ReturnType<
  typeof useDownloadCustomPayrollReportQuery
>;
export type DownloadCustomPayrollReportLazyQueryHookResult = ReturnType<
  typeof useDownloadCustomPayrollReportLazyQuery
>;
export type DownloadCustomPayrollReportQueryResult = Apollo.QueryResult<
  DownloadCustomPayrollReportQuery,
  DownloadCustomPayrollReportQueryVariables
>;
export const GetAvailableReportsDocument = gql`
  query GetAvailableReports($input: ReportID) {
    getAvailableReports(input: $input) {
      title
      description
      id
      reportSource
    }
  }
`;

/**
 * __useGetAvailableReportsQuery__
 *
 * To run a query within a React component, call `useGetAvailableReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAvailableReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableReportsQuery,
    GetAvailableReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableReportsQuery,
    GetAvailableReportsQueryVariables
  >(GetAvailableReportsDocument, options);
}
export function useGetAvailableReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableReportsQuery,
    GetAvailableReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableReportsQuery,
    GetAvailableReportsQueryVariables
  >(GetAvailableReportsDocument, options);
}
export type GetAvailableReportsQueryHookResult = ReturnType<
  typeof useGetAvailableReportsQuery
>;
export type GetAvailableReportsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableReportsLazyQuery
>;
export type GetAvailableReportsQueryResult = Apollo.QueryResult<
  GetAvailableReportsQuery,
  GetAvailableReportsQueryVariables
>;
export const GetCompanyEntitiesForReportDocument = gql`
  query GetCompanyEntitiesForReport {
    company {
      id
      primaryEntity {
        id
        legalName
        address {
          country
        }
      }
      otherEntities {
        id
        legalName
        address {
          country
        }
      }
    }
  }
`;

/**
 * __useGetCompanyEntitiesForReportQuery__
 *
 * To run a query within a React component, call `useGetCompanyEntitiesForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyEntitiesForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyEntitiesForReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyEntitiesForReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyEntitiesForReportQuery,
    GetCompanyEntitiesForReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyEntitiesForReportQuery,
    GetCompanyEntitiesForReportQueryVariables
  >(GetCompanyEntitiesForReportDocument, options);
}
export function useGetCompanyEntitiesForReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyEntitiesForReportQuery,
    GetCompanyEntitiesForReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyEntitiesForReportQuery,
    GetCompanyEntitiesForReportQueryVariables
  >(GetCompanyEntitiesForReportDocument, options);
}
export type GetCompanyEntitiesForReportQueryHookResult = ReturnType<
  typeof useGetCompanyEntitiesForReportQuery
>;
export type GetCompanyEntitiesForReportLazyQueryHookResult = ReturnType<
  typeof useGetCompanyEntitiesForReportLazyQuery
>;
export type GetCompanyEntitiesForReportQueryResult = Apollo.QueryResult<
  GetCompanyEntitiesForReportQuery,
  GetCompanyEntitiesForReportQueryVariables
>;
export const GetCompanyStatutoryReportsDocument = gql`
  query GetCompanyStatutoryReports($filter: CustomPayrollReportFilter!) {
    company {
      id
      statutoryReports(filter: $filter) {
        id
        reportFrequency
        month {
          month
          year
        }
        quarter
        files {
          config {
            id
            reportCode
            reportName
          }
          file {
            id
            downloadUrl
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyStatutoryReportsQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatutoryReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatutoryReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatutoryReportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompanyStatutoryReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyStatutoryReportsQuery,
    GetCompanyStatutoryReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyStatutoryReportsQuery,
    GetCompanyStatutoryReportsQueryVariables
  >(GetCompanyStatutoryReportsDocument, options);
}
export function useGetCompanyStatutoryReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyStatutoryReportsQuery,
    GetCompanyStatutoryReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyStatutoryReportsQuery,
    GetCompanyStatutoryReportsQueryVariables
  >(GetCompanyStatutoryReportsDocument, options);
}
export type GetCompanyStatutoryReportsQueryHookResult = ReturnType<
  typeof useGetCompanyStatutoryReportsQuery
>;
export type GetCompanyStatutoryReportsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyStatutoryReportsLazyQuery
>;
export type GetCompanyStatutoryReportsQueryResult = Apollo.QueryResult<
  GetCompanyStatutoryReportsQuery,
  GetCompanyStatutoryReportsQueryVariables
>;
export const GetCountryFiscalYearsDocument = gql`
  query GetCountryFiscalYears($country: CountryCode!, $years: [Int!]) {
    country(country: $country) {
      code
      fiscalYears(years: $years) {
        fiscalYear
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useGetCountryFiscalYearsQuery__
 *
 * To run a query within a React component, call `useGetCountryFiscalYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryFiscalYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryFiscalYearsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      years: // value for 'years'
 *   },
 * });
 */
export function useGetCountryFiscalYearsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryFiscalYearsQuery,
    GetCountryFiscalYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryFiscalYearsQuery,
    GetCountryFiscalYearsQueryVariables
  >(GetCountryFiscalYearsDocument, options);
}
export function useGetCountryFiscalYearsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryFiscalYearsQuery,
    GetCountryFiscalYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryFiscalYearsQuery,
    GetCountryFiscalYearsQueryVariables
  >(GetCountryFiscalYearsDocument, options);
}
export type GetCountryFiscalYearsQueryHookResult = ReturnType<
  typeof useGetCountryFiscalYearsQuery
>;
export type GetCountryFiscalYearsLazyQueryHookResult = ReturnType<
  typeof useGetCountryFiscalYearsLazyQuery
>;
export type GetCountryFiscalYearsQueryResult = Apollo.QueryResult<
  GetCountryFiscalYearsQuery,
  GetCountryFiscalYearsQueryVariables
>;
export const GetExpenseReportDocument = gql`
  query GetExpenseReport($input: ExpenseReportInput!) {
    company {
      id
      expenseReport(input: $input) {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          blob
        }
      }
    }
  }
`;

/**
 * __useGetExpenseReportQuery__
 *
 * To run a query within a React component, call `useGetExpenseReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExpenseReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExpenseReportQuery,
    GetExpenseReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExpenseReportQuery, GetExpenseReportQueryVariables>(
    GetExpenseReportDocument,
    options,
  );
}
export function useGetExpenseReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpenseReportQuery,
    GetExpenseReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExpenseReportQuery,
    GetExpenseReportQueryVariables
  >(GetExpenseReportDocument, options);
}
export type GetExpenseReportQueryHookResult = ReturnType<
  typeof useGetExpenseReportQuery
>;
export type GetExpenseReportLazyQueryHookResult = ReturnType<
  typeof useGetExpenseReportLazyQuery
>;
export type GetExpenseReportQueryResult = Apollo.QueryResult<
  GetExpenseReportQuery,
  GetExpenseReportQueryVariables
>;
export const GetPayrollCyclesForReportDocument = gql`
  query GetPayrollCyclesForReport($filter: CompanyPayrollCycleFilter!) {
    company {
      companyPayrollCycles(filter: $filter) {
        id
        startDate
        endDate
        payrollCycles {
          id
        }
      }
    }
  }
`;

/**
 * __useGetPayrollCyclesForReportQuery__
 *
 * To run a query within a React component, call `useGetPayrollCyclesForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollCyclesForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollCyclesForReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPayrollCyclesForReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollCyclesForReportQuery,
    GetPayrollCyclesForReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayrollCyclesForReportQuery,
    GetPayrollCyclesForReportQueryVariables
  >(GetPayrollCyclesForReportDocument, options);
}
export function useGetPayrollCyclesForReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollCyclesForReportQuery,
    GetPayrollCyclesForReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollCyclesForReportQuery,
    GetPayrollCyclesForReportQueryVariables
  >(GetPayrollCyclesForReportDocument, options);
}
export type GetPayrollCyclesForReportQueryHookResult = ReturnType<
  typeof useGetPayrollCyclesForReportQuery
>;
export type GetPayrollCyclesForReportLazyQueryHookResult = ReturnType<
  typeof useGetPayrollCyclesForReportLazyQuery
>;
export type GetPayrollCyclesForReportQueryResult = Apollo.QueryResult<
  GetPayrollCyclesForReportQuery,
  GetPayrollCyclesForReportQueryVariables
>;
export const GetPayrollReportDocument = gql`
  query GetPayrollReport($input: CompanyPayrollReportExportInput!) {
    payrollReportDownload(filter: $input) {
      id
      name
      extension
      contentType
      blob
    }
  }
`;

/**
 * __useGetPayrollReportQuery__
 *
 * To run a query within a React component, call `useGetPayrollReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPayrollReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayrollReportQuery,
    GetPayrollReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayrollReportQuery, GetPayrollReportQueryVariables>(
    GetPayrollReportDocument,
    options,
  );
}
export function useGetPayrollReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayrollReportQuery,
    GetPayrollReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayrollReportQuery,
    GetPayrollReportQueryVariables
  >(GetPayrollReportDocument, options);
}
export type GetPayrollReportQueryHookResult = ReturnType<
  typeof useGetPayrollReportQuery
>;
export type GetPayrollReportLazyQueryHookResult = ReturnType<
  typeof useGetPayrollReportLazyQuery
>;
export type GetPayrollReportQueryResult = Apollo.QueryResult<
  GetPayrollReportQuery,
  GetPayrollReportQueryVariables
>;
export const GetReportConfigurationDocument = gql`
  query GetReportConfiguration($input: ReportID) {
    getAvailableReports(input: $input) {
      title
      description
      id
      reportSource
      filters {
        parameterName
        parameterTitle
        isMandatory
        possibleValues
        type
      }
    }
  }
`;

/**
 * __useGetReportConfigurationQuery__
 *
 * To run a query within a React component, call `useGetReportConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportConfigurationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReportConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReportConfigurationQuery,
    GetReportConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReportConfigurationQuery,
    GetReportConfigurationQueryVariables
  >(GetReportConfigurationDocument, options);
}
export function useGetReportConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportConfigurationQuery,
    GetReportConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReportConfigurationQuery,
    GetReportConfigurationQueryVariables
  >(GetReportConfigurationDocument, options);
}
export type GetReportConfigurationQueryHookResult = ReturnType<
  typeof useGetReportConfigurationQuery
>;
export type GetReportConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetReportConfigurationLazyQuery
>;
export type GetReportConfigurationQueryResult = Apollo.QueryResult<
  GetReportConfigurationQuery,
  GetReportConfigurationQueryVariables
>;
export const GetReportDocument = gql`
  query GetReport($input: String) {
    getReport(input: $input)
  }
`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReportQuery,
    GetReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options,
  );
}
export function useGetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportQuery,
    GetReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options,
  );
}
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<
  typeof useGetReportLazyQuery
>;
export type GetReportQueryResult = Apollo.QueryResult<
  GetReportQuery,
  GetReportQueryVariables
>;
export const GetTimeOffBalanceReportDocument = gql`
  query GetTimeOffBalanceReport {
    company {
      id
      timeOffBalanceReport {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          blob
        }
      }
    }
  }
`;

/**
 * __useGetTimeOffBalanceReportQuery__
 *
 * To run a query within a React component, call `useGetTimeOffBalanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffBalanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffBalanceReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeOffBalanceReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTimeOffBalanceReportQuery,
    GetTimeOffBalanceReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTimeOffBalanceReportQuery,
    GetTimeOffBalanceReportQueryVariables
  >(GetTimeOffBalanceReportDocument, options);
}
export function useGetTimeOffBalanceReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeOffBalanceReportQuery,
    GetTimeOffBalanceReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTimeOffBalanceReportQuery,
    GetTimeOffBalanceReportQueryVariables
  >(GetTimeOffBalanceReportDocument, options);
}
export type GetTimeOffBalanceReportQueryHookResult = ReturnType<
  typeof useGetTimeOffBalanceReportQuery
>;
export type GetTimeOffBalanceReportLazyQueryHookResult = ReturnType<
  typeof useGetTimeOffBalanceReportLazyQuery
>;
export type GetTimeOffBalanceReportQueryResult = Apollo.QueryResult<
  GetTimeOffBalanceReportQuery,
  GetTimeOffBalanceReportQueryVariables
>;
export const GetTimeOffReportDocument = gql`
  query GetTimeOffReport($input: TimeOffReportInput!) {
    company {
      id
      timeOffReport(input: $input) {
        id
        name
        extension
        contentType
        ... on DocumentReadable {
          blob
        }
      }
    }
  }
`;

/**
 * __useGetTimeOffReportQuery__
 *
 * To run a query within a React component, call `useGetTimeOffReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTimeOffReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTimeOffReportQuery,
    GetTimeOffReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeOffReportQuery, GetTimeOffReportQueryVariables>(
    GetTimeOffReportDocument,
    options,
  );
}
export function useGetTimeOffReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeOffReportQuery,
    GetTimeOffReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTimeOffReportQuery,
    GetTimeOffReportQueryVariables
  >(GetTimeOffReportDocument, options);
}
export type GetTimeOffReportQueryHookResult = ReturnType<
  typeof useGetTimeOffReportQuery
>;
export type GetTimeOffReportLazyQueryHookResult = ReturnType<
  typeof useGetTimeOffReportLazyQuery
>;
export type GetTimeOffReportQueryResult = Apollo.QueryResult<
  GetTimeOffReportQuery,
  GetTimeOffReportQueryVariables
>;
export const PayrollReportDownloadForCustomerDocument = gql`
  query PayrollReportDownloadForCustomer($input: String!) {
    payrollReportDownloadForCustomer(input: $input) {
      id
      blob
      extension
      name
    }
  }
`;

/**
 * __usePayrollReportDownloadForCustomerQuery__
 *
 * To run a query within a React component, call `usePayrollReportDownloadForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollReportDownloadForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollReportDownloadForCustomerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayrollReportDownloadForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollReportDownloadForCustomerQuery,
    PayrollReportDownloadForCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollReportDownloadForCustomerQuery,
    PayrollReportDownloadForCustomerQueryVariables
  >(PayrollReportDownloadForCustomerDocument, options);
}
export function usePayrollReportDownloadForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollReportDownloadForCustomerQuery,
    PayrollReportDownloadForCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollReportDownloadForCustomerQuery,
    PayrollReportDownloadForCustomerQueryVariables
  >(PayrollReportDownloadForCustomerDocument, options);
}
export type PayrollReportDownloadForCustomerQueryHookResult = ReturnType<
  typeof usePayrollReportDownloadForCustomerQuery
>;
export type PayrollReportDownloadForCustomerLazyQueryHookResult = ReturnType<
  typeof usePayrollReportDownloadForCustomerLazyQuery
>;
export type PayrollReportDownloadForCustomerQueryResult = Apollo.QueryResult<
  PayrollReportDownloadForCustomerQuery,
  PayrollReportDownloadForCustomerQueryVariables
>;
export const GetCountriesDocument = gql`
  query GetCountries($country: CountryCode, $mainCurrency: CurrencyCode) {
    countries(country: $country, mainCurrency: $mainCurrency) {
      name
      code
      region
      mainCurrency
      supportedCurrencies
      compliantCurrencies
    }
  }
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *      country: // value for 'country'
 *      mainCurrency: // value for 'mainCurrency'
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export type GetCountriesQueryHookResult = ReturnType<
  typeof useGetCountriesQuery
>;
export type GetCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCountriesLazyQuery
>;
export type GetCountriesQueryResult = Apollo.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const GetSalaryCalculationMinimumWageDocument = gql`
  query GetSalaryCalculationMinimumWage(
    $params: SalaryCalculationMinimumWageParam!
  ) {
    salaryCalculationMinimumWage(params: $params) {
      currency
      amount
      frequency
    }
  }
`;

/**
 * __useGetSalaryCalculationMinimumWageQuery__
 *
 * To run a query within a React component, call `useGetSalaryCalculationMinimumWageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryCalculationMinimumWageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryCalculationMinimumWageQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSalaryCalculationMinimumWageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalaryCalculationMinimumWageQuery,
    GetSalaryCalculationMinimumWageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalaryCalculationMinimumWageQuery,
    GetSalaryCalculationMinimumWageQueryVariables
  >(GetSalaryCalculationMinimumWageDocument, options);
}
export function useGetSalaryCalculationMinimumWageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryCalculationMinimumWageQuery,
    GetSalaryCalculationMinimumWageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryCalculationMinimumWageQuery,
    GetSalaryCalculationMinimumWageQueryVariables
  >(GetSalaryCalculationMinimumWageDocument, options);
}
export type GetSalaryCalculationMinimumWageQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationMinimumWageQuery
>;
export type GetSalaryCalculationMinimumWageLazyQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationMinimumWageLazyQuery
>;
export type GetSalaryCalculationMinimumWageQueryResult = Apollo.QueryResult<
  GetSalaryCalculationMinimumWageQuery,
  GetSalaryCalculationMinimumWageQueryVariables
>;
export const GetSalaryCalculationParamsCountryDocument = gql`
  query GetSalaryCalculationParamsCountry($country: CountryCode!) {
    salaryCalculationParams(country: $country) {
      country
      states
      rateType
      currency
      frequency
      payableType
    }
  }
`;

/**
 * __useGetSalaryCalculationParamsCountryQuery__
 *
 * To run a query within a React component, call `useGetSalaryCalculationParamsCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryCalculationParamsCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryCalculationParamsCountryQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetSalaryCalculationParamsCountryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalaryCalculationParamsCountryQuery,
    GetSalaryCalculationParamsCountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalaryCalculationParamsCountryQuery,
    GetSalaryCalculationParamsCountryQueryVariables
  >(GetSalaryCalculationParamsCountryDocument, options);
}
export function useGetSalaryCalculationParamsCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryCalculationParamsCountryQuery,
    GetSalaryCalculationParamsCountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryCalculationParamsCountryQuery,
    GetSalaryCalculationParamsCountryQueryVariables
  >(GetSalaryCalculationParamsCountryDocument, options);
}
export type GetSalaryCalculationParamsCountryQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationParamsCountryQuery
>;
export type GetSalaryCalculationParamsCountryLazyQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationParamsCountryLazyQuery
>;
export type GetSalaryCalculationParamsCountryQueryResult = Apollo.QueryResult<
  GetSalaryCalculationParamsCountryQuery,
  GetSalaryCalculationParamsCountryQueryVariables
>;
export const GetSalaryCalculationParamsDocument = gql`
  query GetSalaryCalculationParams {
    salaryCalculationParams {
      country
      states
      rateType
      currency
      frequency
      payableType
    }
  }
`;

/**
 * __useGetSalaryCalculationParamsQuery__
 *
 * To run a query within a React component, call `useGetSalaryCalculationParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryCalculationParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryCalculationParamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalaryCalculationParamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSalaryCalculationParamsQuery,
    GetSalaryCalculationParamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalaryCalculationParamsQuery,
    GetSalaryCalculationParamsQueryVariables
  >(GetSalaryCalculationParamsDocument, options);
}
export function useGetSalaryCalculationParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryCalculationParamsQuery,
    GetSalaryCalculationParamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryCalculationParamsQuery,
    GetSalaryCalculationParamsQueryVariables
  >(GetSalaryCalculationParamsDocument, options);
}
export type GetSalaryCalculationParamsQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationParamsQuery
>;
export type GetSalaryCalculationParamsLazyQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationParamsLazyQuery
>;
export type GetSalaryCalculationParamsQueryResult = Apollo.QueryResult<
  GetSalaryCalculationParamsQuery,
  GetSalaryCalculationParamsQueryVariables
>;
export const GetSalaryCalculationPdfDocument = gql`
  query GetSalaryCalculationPdf($params: SalaryCalculationParam) {
    salaryCalculationPdf(params: $params) {
      link
      contentType
      name
    }
  }
`;

/**
 * __useGetSalaryCalculationPdfQuery__
 *
 * To run a query within a React component, call `useGetSalaryCalculationPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryCalculationPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryCalculationPdfQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSalaryCalculationPdfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSalaryCalculationPdfQuery,
    GetSalaryCalculationPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalaryCalculationPdfQuery,
    GetSalaryCalculationPdfQueryVariables
  >(GetSalaryCalculationPdfDocument, options);
}
export function useGetSalaryCalculationPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryCalculationPdfQuery,
    GetSalaryCalculationPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryCalculationPdfQuery,
    GetSalaryCalculationPdfQueryVariables
  >(GetSalaryCalculationPdfDocument, options);
}
export type GetSalaryCalculationPdfQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationPdfQuery
>;
export type GetSalaryCalculationPdfLazyQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationPdfLazyQuery
>;
export type GetSalaryCalculationPdfQueryResult = Apollo.QueryResult<
  GetSalaryCalculationPdfQuery,
  GetSalaryCalculationPdfQueryVariables
>;
export const GetSalaryCalculationDocument = gql`
  query GetSalaryCalculation($params: SalaryCalculationParam) {
    salaryCalculation(params: $params) {
      companyPayable {
        ...calculationBreakdown
      }
      employeePayable {
        ...calculationBreakdown
      }
    }
  }
  ${CalculationBreakdownFragmentDoc}
`;

/**
 * __useGetSalaryCalculationQuery__
 *
 * To run a query within a React component, call `useGetSalaryCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryCalculationQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSalaryCalculationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSalaryCalculationQuery,
    GetSalaryCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalaryCalculationQuery,
    GetSalaryCalculationQueryVariables
  >(GetSalaryCalculationDocument, options);
}
export function useGetSalaryCalculationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryCalculationQuery,
    GetSalaryCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryCalculationQuery,
    GetSalaryCalculationQueryVariables
  >(GetSalaryCalculationDocument, options);
}
export type GetSalaryCalculationQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationQuery
>;
export type GetSalaryCalculationLazyQueryHookResult = ReturnType<
  typeof useGetSalaryCalculationLazyQuery
>;
export type GetSalaryCalculationQueryResult = Apollo.QueryResult<
  GetSalaryCalculationQuery,
  GetSalaryCalculationQueryVariables
>;
export const SendSalaryCalculationEmailDocument = gql`
  mutation SendSalaryCalculationEmail(
    $emails: [String]
    $params: SalaryCalculationParam
  ) {
    salaryCalculationEmail(emails: $emails, params: $params) {
      success
      message
    }
  }
`;
export type SendSalaryCalculationEmailMutationFn = Apollo.MutationFunction<
  SendSalaryCalculationEmailMutation,
  SendSalaryCalculationEmailMutationVariables
>;

/**
 * __useSendSalaryCalculationEmailMutation__
 *
 * To run a mutation, you first call `useSendSalaryCalculationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSalaryCalculationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSalaryCalculationEmailMutation, { data, loading, error }] = useSendSalaryCalculationEmailMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSendSalaryCalculationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSalaryCalculationEmailMutation,
    SendSalaryCalculationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSalaryCalculationEmailMutation,
    SendSalaryCalculationEmailMutationVariables
  >(SendSalaryCalculationEmailDocument, options);
}
export type SendSalaryCalculationEmailMutationHookResult = ReturnType<
  typeof useSendSalaryCalculationEmailMutation
>;
export type SendSalaryCalculationEmailMutationResult = Apollo.MutationResult<SendSalaryCalculationEmailMutation>;
export type SendSalaryCalculationEmailMutationOptions = Apollo.BaseMutationOptions<
  SendSalaryCalculationEmailMutation,
  SendSalaryCalculationEmailMutationVariables
>;
export const SalaryCalculatorRequestEmailDocument = gql`
  mutation SalaryCalculatorRequestEmail(
    $params: SalaryCalculationRequestEmailParam
  ) {
    salaryCalculatorRequestEmail(params: $params) {
      success
      message
    }
  }
`;
export type SalaryCalculatorRequestEmailMutationFn = Apollo.MutationFunction<
  SalaryCalculatorRequestEmailMutation,
  SalaryCalculatorRequestEmailMutationVariables
>;

/**
 * __useSalaryCalculatorRequestEmailMutation__
 *
 * To run a mutation, you first call `useSalaryCalculatorRequestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalaryCalculatorRequestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salaryCalculatorRequestEmailMutation, { data, loading, error }] = useSalaryCalculatorRequestEmailMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSalaryCalculatorRequestEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalaryCalculatorRequestEmailMutation,
    SalaryCalculatorRequestEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalaryCalculatorRequestEmailMutation,
    SalaryCalculatorRequestEmailMutationVariables
  >(SalaryCalculatorRequestEmailDocument, options);
}
export type SalaryCalculatorRequestEmailMutationHookResult = ReturnType<
  typeof useSalaryCalculatorRequestEmailMutation
>;
export type SalaryCalculatorRequestEmailMutationResult = Apollo.MutationResult<SalaryCalculatorRequestEmailMutation>;
export type SalaryCalculatorRequestEmailMutationOptions = Apollo.BaseMutationOptions<
  SalaryCalculatorRequestEmailMutation,
  SalaryCalculatorRequestEmailMutationVariables
>;
export const ApproveChangeRequestDocument = gql`
  mutation ApproveChangeRequest(
    $itemId: ID!
    $category: ApprovalCategory!
    $comment: String
  ) {
    approveItem(itemId: $itemId, category: $category, comment: $comment) {
      id
      status
    }
  }
`;
export type ApproveChangeRequestMutationFn = Apollo.MutationFunction<
  ApproveChangeRequestMutation,
  ApproveChangeRequestMutationVariables
>;

/**
 * __useApproveChangeRequestMutation__
 *
 * To run a mutation, you first call `useApproveChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveChangeRequestMutation, { data, loading, error }] = useApproveChangeRequestMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      category: // value for 'category'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useApproveChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveChangeRequestMutation,
    ApproveChangeRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveChangeRequestMutation,
    ApproveChangeRequestMutationVariables
  >(ApproveChangeRequestDocument, options);
}
export type ApproveChangeRequestMutationHookResult = ReturnType<
  typeof useApproveChangeRequestMutation
>;
export type ApproveChangeRequestMutationResult = Apollo.MutationResult<ApproveChangeRequestMutation>;
export type ApproveChangeRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveChangeRequestMutation,
  ApproveChangeRequestMutationVariables
>;
export const ContractOffboardingCancelDocument = gql`
  mutation ContractOffboardingCancel($id: ID!) {
    contractOffboardingCancel(id: $id) {
      id
      status
      offboardingNote
      lastWorkingDay
      endedOn
      endOn
      type
    }
  }
`;
export type ContractOffboardingCancelMutationFn = Apollo.MutationFunction<
  ContractOffboardingCancelMutation,
  ContractOffboardingCancelMutationVariables
>;

/**
 * __useContractOffboardingCancelMutation__
 *
 * To run a mutation, you first call `useContractOffboardingCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractOffboardingCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractOffboardingCancelMutation, { data, loading, error }] = useContractOffboardingCancelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractOffboardingCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractOffboardingCancelMutation,
    ContractOffboardingCancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractOffboardingCancelMutation,
    ContractOffboardingCancelMutationVariables
  >(ContractOffboardingCancelDocument, options);
}
export type ContractOffboardingCancelMutationHookResult = ReturnType<
  typeof useContractOffboardingCancelMutation
>;
export type ContractOffboardingCancelMutationResult = Apollo.MutationResult<ContractOffboardingCancelMutation>;
export type ContractOffboardingCancelMutationOptions = Apollo.BaseMutationOptions<
  ContractOffboardingCancelMutation,
  ContractOffboardingCancelMutationVariables
>;
export const ContractAgreementReplaceDocument = gql`
  mutation ContractAgreementReplace($contractId: ID!, $contract: Upload!) {
    contractAgreementReplace(contractId: $contractId, contract: $contract) {
      id
      compliance {
        contractAgreement {
          id
          name
        }
      }
    }
  }
`;
export type ContractAgreementReplaceMutationFn = Apollo.MutationFunction<
  ContractAgreementReplaceMutation,
  ContractAgreementReplaceMutationVariables
>;

/**
 * __useContractAgreementReplaceMutation__
 *
 * To run a mutation, you first call `useContractAgreementReplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractAgreementReplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractAgreementReplaceMutation, { data, loading, error }] = useContractAgreementReplaceMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      contract: // value for 'contract'
 *   },
 * });
 */
export function useContractAgreementReplaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractAgreementReplaceMutation,
    ContractAgreementReplaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractAgreementReplaceMutation,
    ContractAgreementReplaceMutationVariables
  >(ContractAgreementReplaceDocument, options);
}
export type ContractAgreementReplaceMutationHookResult = ReturnType<
  typeof useContractAgreementReplaceMutation
>;
export type ContractAgreementReplaceMutationResult = Apollo.MutationResult<ContractAgreementReplaceMutation>;
export type ContractAgreementReplaceMutationOptions = Apollo.BaseMutationOptions<
  ContractAgreementReplaceMutation,
  ContractAgreementReplaceMutationVariables
>;
export const ContractOffboardDocument = gql`
  mutation ContractOffboard(
    $id: ID!
    $lastWorkingDay: Date!
    $offboardingNote: String!
  ) {
    contractOffboard(
      id: $id
      lastWorkingDay: $lastWorkingDay
      offboardingNote: $offboardingNote
    ) {
      id
      status
      offboardingNote
      lastWorkingDay
      endedOn
      endOn
      type
    }
  }
`;
export type ContractOffboardMutationFn = Apollo.MutationFunction<
  ContractOffboardMutation,
  ContractOffboardMutationVariables
>;

/**
 * __useContractOffboardMutation__
 *
 * To run a mutation, you first call `useContractOffboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractOffboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractOffboardMutation, { data, loading, error }] = useContractOffboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      lastWorkingDay: // value for 'lastWorkingDay'
 *      offboardingNote: // value for 'offboardingNote'
 *   },
 * });
 */
export function useContractOffboardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractOffboardMutation,
    ContractOffboardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractOffboardMutation,
    ContractOffboardMutationVariables
  >(ContractOffboardDocument, options);
}
export type ContractOffboardMutationHookResult = ReturnType<
  typeof useContractOffboardMutation
>;
export type ContractOffboardMutationResult = Apollo.MutationResult<ContractOffboardMutation>;
export type ContractOffboardMutationOptions = Apollo.BaseMutationOptions<
  ContractOffboardMutation,
  ContractOffboardMutationVariables
>;
export const ContractUpdateComplianceDocument = gql`
  mutation ContractUpdateCompliance(
    $contractId: ID!
    $input: ContractUpdateComplianceInput!
  ) {
    contractUpdateCompliance(contractId: $contractId, input: $input) {
      id
      compliance {
        complianceParams {
          key
          label
          ... on ComplianceParamPeriodLimit {
            description
            value
            unit
          }
        }
      }
    }
  }
`;
export type ContractUpdateComplianceMutationFn = Apollo.MutationFunction<
  ContractUpdateComplianceMutation,
  ContractUpdateComplianceMutationVariables
>;

/**
 * __useContractUpdateComplianceMutation__
 *
 * To run a mutation, you first call `useContractUpdateComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractUpdateComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractUpdateComplianceMutation, { data, loading, error }] = useContractUpdateComplianceMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractUpdateComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractUpdateComplianceMutation,
    ContractUpdateComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractUpdateComplianceMutation,
    ContractUpdateComplianceMutationVariables
  >(ContractUpdateComplianceDocument, options);
}
export type ContractUpdateComplianceMutationHookResult = ReturnType<
  typeof useContractUpdateComplianceMutation
>;
export type ContractUpdateComplianceMutationResult = Apollo.MutationResult<ContractUpdateComplianceMutation>;
export type ContractUpdateComplianceMutationOptions = Apollo.BaseMutationOptions<
  ContractUpdateComplianceMutation,
  ContractUpdateComplianceMutationVariables
>;
export const ContractUpdateEmploymentDocument = gql`
  mutation ContractUpdateEmployment(
    $contractId: ID!
    $input: ContractUpdateEmploymentInput!
  ) {
    contractUpdateEmployment(id: $contractId, input: $input) {
      id
      startOn
      endOn
      employeeId
    }
  }
`;
export type ContractUpdateEmploymentMutationFn = Apollo.MutationFunction<
  ContractUpdateEmploymentMutation,
  ContractUpdateEmploymentMutationVariables
>;

/**
 * __useContractUpdateEmploymentMutation__
 *
 * To run a mutation, you first call `useContractUpdateEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractUpdateEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractUpdateEmploymentMutation, { data, loading, error }] = useContractUpdateEmploymentMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractUpdateEmploymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractUpdateEmploymentMutation,
    ContractUpdateEmploymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractUpdateEmploymentMutation,
    ContractUpdateEmploymentMutationVariables
  >(ContractUpdateEmploymentDocument, options);
}
export type ContractUpdateEmploymentMutationHookResult = ReturnType<
  typeof useContractUpdateEmploymentMutation
>;
export type ContractUpdateEmploymentMutationResult = Apollo.MutationResult<ContractUpdateEmploymentMutation>;
export type ContractUpdateEmploymentMutationOptions = Apollo.BaseMutationOptions<
  ContractUpdateEmploymentMutation,
  ContractUpdateEmploymentMutationVariables
>;
export const DeleteMemberDocument = gql`
  mutation DeleteMember($id: ID!) {
    memberDelete(id: $id) {
      id
    }
  }
`;
export type DeleteMemberMutationFn = Apollo.MutationFunction<
  DeleteMemberMutation,
  DeleteMemberMutationVariables
>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberMutation,
    DeleteMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberMutation,
    DeleteMemberMutationVariables
  >(DeleteMemberDocument, options);
}
export type DeleteMemberMutationHookResult = ReturnType<
  typeof useDeleteMemberMutation
>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteMemberMutation,
  DeleteMemberMutationVariables
>;
export const GetCompanyUsersRoleDocument = gql`
  query GetCompanyUsersRole {
    company {
      id
      companyUsers {
        userId
        roles
      }
    }
  }
`;

/**
 * __useGetCompanyUsersRoleQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyUsersRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyUsersRoleQuery,
    GetCompanyUsersRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyUsersRoleQuery,
    GetCompanyUsersRoleQueryVariables
  >(GetCompanyUsersRoleDocument, options);
}
export function useGetCompanyUsersRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyUsersRoleQuery,
    GetCompanyUsersRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyUsersRoleQuery,
    GetCompanyUsersRoleQueryVariables
  >(GetCompanyUsersRoleDocument, options);
}
export type GetCompanyUsersRoleQueryHookResult = ReturnType<
  typeof useGetCompanyUsersRoleQuery
>;
export type GetCompanyUsersRoleLazyQueryHookResult = ReturnType<
  typeof useGetCompanyUsersRoleLazyQuery
>;
export type GetCompanyUsersRoleQueryResult = Apollo.QueryResult<
  GetCompanyUsersRoleQuery,
  GetCompanyUsersRoleQueryVariables
>;
export const GetComponentAccessibilityRulesDocument = gql`
  query GetComponentAccessibilityRules(
    $contractId: ID
    $page: MemberExperiencePage
  ) {
    getComponentAccessibilityRules(contractID: $contractId, page: $page) {
      page
      sections {
        editable
        name
        reason
        rules {
          visible
          field
          editable
          reason
        }
      }
    }
  }
`;

/**
 * __useGetComponentAccessibilityRulesQuery__
 *
 * To run a query within a React component, call `useGetComponentAccessibilityRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentAccessibilityRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentAccessibilityRulesQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetComponentAccessibilityRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetComponentAccessibilityRulesQuery,
    GetComponentAccessibilityRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComponentAccessibilityRulesQuery,
    GetComponentAccessibilityRulesQueryVariables
  >(GetComponentAccessibilityRulesDocument, options);
}
export function useGetComponentAccessibilityRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComponentAccessibilityRulesQuery,
    GetComponentAccessibilityRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComponentAccessibilityRulesQuery,
    GetComponentAccessibilityRulesQueryVariables
  >(GetComponentAccessibilityRulesDocument, options);
}
export type GetComponentAccessibilityRulesQueryHookResult = ReturnType<
  typeof useGetComponentAccessibilityRulesQuery
>;
export type GetComponentAccessibilityRulesLazyQueryHookResult = ReturnType<
  typeof useGetComponentAccessibilityRulesLazyQuery
>;
export type GetComponentAccessibilityRulesQueryResult = Apollo.QueryResult<
  GetComponentAccessibilityRulesQuery,
  GetComponentAccessibilityRulesQueryVariables
>;
export const GetContractBenefitInsurancePremiumDocument = gql`
  query GetContractBenefitInsurancePremium($id: ID!) {
    contract(id: $id, includeAnyStatus: true) {
      id
      benefits {
        id
        insurancePremium {
          benefitType
          billingDuration
          dependents {
            benefitPackageCost {
              id
              premium
              premiumCurrency
              premiumFrequency
            }
            billingPeriodInMonths
            endOn
            firstName
            id
            lastName
            platformFee
            startOn
            subTotalAmount
            subTotalPlatformFee
          }
          employeePayAmount
          employeePayPercentage
          employerPayAmount
          employerPayPercentage
          self {
            subTotalAmount
            subTotalPlatformFee
            platformFee
            id
            billingPeriodInMonths
            benefitPackageCost {
              premium
              id
              premiumCurrency
              premiumFrequency
            }
          }
          totalPlatformFee
          totalPremium
        }
      }
    }
  }
`;

/**
 * __useGetContractBenefitInsurancePremiumQuery__
 *
 * To run a query within a React component, call `useGetContractBenefitInsurancePremiumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractBenefitInsurancePremiumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractBenefitInsurancePremiumQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractBenefitInsurancePremiumQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractBenefitInsurancePremiumQuery,
    GetContractBenefitInsurancePremiumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractBenefitInsurancePremiumQuery,
    GetContractBenefitInsurancePremiumQueryVariables
  >(GetContractBenefitInsurancePremiumDocument, options);
}
export function useGetContractBenefitInsurancePremiumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractBenefitInsurancePremiumQuery,
    GetContractBenefitInsurancePremiumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractBenefitInsurancePremiumQuery,
    GetContractBenefitInsurancePremiumQueryVariables
  >(GetContractBenefitInsurancePremiumDocument, options);
}
export type GetContractBenefitInsurancePremiumQueryHookResult = ReturnType<
  typeof useGetContractBenefitInsurancePremiumQuery
>;
export type GetContractBenefitInsurancePremiumLazyQueryHookResult = ReturnType<
  typeof useGetContractBenefitInsurancePremiumLazyQuery
>;
export type GetContractBenefitInsurancePremiumQueryResult = Apollo.QueryResult<
  GetContractBenefitInsurancePremiumQuery,
  GetContractBenefitInsurancePremiumQueryVariables
>;
export const GetContractPerformanceReviewDocument = gql`
  query GetContractPerformanceReview($contractId: ID!) {
    contract(id: $contractId) {
      id
      performanceReviews {
        id
        status
        companySignedOn
        memberSignedOn
        designation {
          name
          jobDescription
        }
        salaryReview {
          id
          revisedCompensation {
            payType
            basePay {
              ...fixedPayComponent
            }
            probationBasePay {
              ...fixedPayComponent
            }
            postProbationBasePay {
              ...fixedPayComponent
            }
            additionalPays {
              ...compensationPayComponent
            }
          }
        }
        previousDesignation {
          name
          jobDescription
        }
        reviewDocument {
          ...documentReadable
        }
        effectiveDate
        createdOn
        comment
        payrollCycle {
          id
          configId
          startDate
          endDate
          status
        }
      }
      type
      compliance {
        type
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${DocumentReadableFragmentDoc}
`;

/**
 * __useGetContractPerformanceReviewQuery__
 *
 * To run a query within a React component, call `useGetContractPerformanceReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractPerformanceReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractPerformanceReviewQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractPerformanceReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractPerformanceReviewQuery,
    GetContractPerformanceReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractPerformanceReviewQuery,
    GetContractPerformanceReviewQueryVariables
  >(GetContractPerformanceReviewDocument, options);
}
export function useGetContractPerformanceReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractPerformanceReviewQuery,
    GetContractPerformanceReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractPerformanceReviewQuery,
    GetContractPerformanceReviewQueryVariables
  >(GetContractPerformanceReviewDocument, options);
}
export type GetContractPerformanceReviewQueryHookResult = ReturnType<
  typeof useGetContractPerformanceReviewQuery
>;
export type GetContractPerformanceReviewLazyQueryHookResult = ReturnType<
  typeof useGetContractPerformanceReviewLazyQuery
>;
export type GetContractPerformanceReviewQueryResult = Apollo.QueryResult<
  GetContractPerformanceReviewQuery,
  GetContractPerformanceReviewQueryVariables
>;
export const GetMemberCompensationDocument = gql`
  query GetMemberCompensation($id: ID!) {
    member {
      contracts(includeAnyStatus: true) {
        id
        compensation {
          basePay {
            ...fixedPayComponent
          }
          postProbationBasePay {
            ...fixedPayComponent
          }
          probationBasePay {
            ...fixedPayComponent
          }
          additionalPays {
            ...compensationPayComponent
          }
          additional
          payrollStart
          grant(contractId: $id) {
            ...grantComponent
          }
          payType
          deductions {
            deductionDefinitionId
            value
            unit
          }
        }
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${GrantComponentFragmentDoc}
`;

/**
 * __useGetMemberCompensationQuery__
 *
 * To run a query within a React component, call `useGetMemberCompensationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberCompensationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberCompensationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberCompensationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberCompensationQuery,
    GetMemberCompensationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberCompensationQuery,
    GetMemberCompensationQueryVariables
  >(GetMemberCompensationDocument, options);
}
export function useGetMemberCompensationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberCompensationQuery,
    GetMemberCompensationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberCompensationQuery,
    GetMemberCompensationQueryVariables
  >(GetMemberCompensationDocument, options);
}
export type GetMemberCompensationQueryHookResult = ReturnType<
  typeof useGetMemberCompensationQuery
>;
export type GetMemberCompensationLazyQueryHookResult = ReturnType<
  typeof useGetMemberCompensationLazyQuery
>;
export type GetMemberCompensationQueryResult = Apollo.QueryResult<
  GetMemberCompensationQuery,
  GetMemberCompensationQueryVariables
>;
export const GetMemberPersonalDetailsAccessibilityRulesDocument = gql`
  query GetMemberPersonalDetailsAccessibilityRules($memberId: ID) {
    getMemberPersonalDetailsAccessibilityRules(memberId: $memberId) {
      accessibility {
        editable
        reason
        rules {
          editable
          field
          reason
          visible
        }
      }
      section
      visible
    }
  }
`;

/**
 * __useGetMemberPersonalDetailsAccessibilityRulesQuery__
 *
 * To run a query within a React component, call `useGetMemberPersonalDetailsAccessibilityRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPersonalDetailsAccessibilityRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPersonalDetailsAccessibilityRulesQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetMemberPersonalDetailsAccessibilityRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberPersonalDetailsAccessibilityRulesQuery,
    GetMemberPersonalDetailsAccessibilityRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPersonalDetailsAccessibilityRulesQuery,
    GetMemberPersonalDetailsAccessibilityRulesQueryVariables
  >(GetMemberPersonalDetailsAccessibilityRulesDocument, options);
}
export function useGetMemberPersonalDetailsAccessibilityRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPersonalDetailsAccessibilityRulesQuery,
    GetMemberPersonalDetailsAccessibilityRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPersonalDetailsAccessibilityRulesQuery,
    GetMemberPersonalDetailsAccessibilityRulesQueryVariables
  >(GetMemberPersonalDetailsAccessibilityRulesDocument, options);
}
export type GetMemberPersonalDetailsAccessibilityRulesQueryHookResult = ReturnType<
  typeof useGetMemberPersonalDetailsAccessibilityRulesQuery
>;
export type GetMemberPersonalDetailsAccessibilityRulesLazyQueryHookResult = ReturnType<
  typeof useGetMemberPersonalDetailsAccessibilityRulesLazyQuery
>;
export type GetMemberPersonalDetailsAccessibilityRulesQueryResult = Apollo.QueryResult<
  GetMemberPersonalDetailsAccessibilityRulesQuery,
  GetMemberPersonalDetailsAccessibilityRulesQueryVariables
>;
export const GetPayslipsFileDocument = gql`
  query GetPayslipsFile($contractId: ID!, $payslipId: ID!) {
    contract(id: $contractId) {
      payslips(filters: { payslipId: $payslipId }) {
        id
        payslipDocument {
          downloadUrl
          name
        }
      }
    }
  }
`;

/**
 * __useGetPayslipsFileQuery__
 *
 * To run a query within a React component, call `useGetPayslipsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayslipsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayslipsFileQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      payslipId: // value for 'payslipId'
 *   },
 * });
 */
export function useGetPayslipsFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayslipsFileQuery,
    GetPayslipsFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayslipsFileQuery, GetPayslipsFileQueryVariables>(
    GetPayslipsFileDocument,
    options,
  );
}
export function useGetPayslipsFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayslipsFileQuery,
    GetPayslipsFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayslipsFileQuery,
    GetPayslipsFileQueryVariables
  >(GetPayslipsFileDocument, options);
}
export type GetPayslipsFileQueryHookResult = ReturnType<
  typeof useGetPayslipsFileQuery
>;
export type GetPayslipsFileLazyQueryHookResult = ReturnType<
  typeof useGetPayslipsFileLazyQuery
>;
export type GetPayslipsFileQueryResult = Apollo.QueryResult<
  GetPayslipsFileQuery,
  GetPayslipsFileQueryVariables
>;
export const GetSalaryRevisionNotesDocument = gql`
  query GetSalaryRevisionNotes(
    $input: PerformanceReviewNoteInput
    $contractId: ID!
  ) {
    performanceReviewNotes(input: $input) {
      cutOffDate
      month
      year
      type
      semimonthlySegment
      payrollCyclePayDate
    }
    contract(id: $contractId) {
      id
      type
      compliance {
        type
      }
      legalEntityId
      compensation {
        basePay {
          paymentFrequency
        }
      }
    }
  }
`;

/**
 * __useGetSalaryRevisionNotesQuery__
 *
 * To run a query within a React component, call `useGetSalaryRevisionNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryRevisionNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryRevisionNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetSalaryRevisionNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalaryRevisionNotesQuery,
    GetSalaryRevisionNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalaryRevisionNotesQuery,
    GetSalaryRevisionNotesQueryVariables
  >(GetSalaryRevisionNotesDocument, options);
}
export function useGetSalaryRevisionNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryRevisionNotesQuery,
    GetSalaryRevisionNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryRevisionNotesQuery,
    GetSalaryRevisionNotesQueryVariables
  >(GetSalaryRevisionNotesDocument, options);
}
export type GetSalaryRevisionNotesQueryHookResult = ReturnType<
  typeof useGetSalaryRevisionNotesQuery
>;
export type GetSalaryRevisionNotesLazyQueryHookResult = ReturnType<
  typeof useGetSalaryRevisionNotesLazyQuery
>;
export type GetSalaryRevisionNotesQueryResult = Apollo.QueryResult<
  GetSalaryRevisionNotesQuery,
  GetSalaryRevisionNotesQueryVariables
>;
export const GetTeamDocument = gql`
  query GetTeam {
    company {
      id
      contracts(includeAnyStatus: true) {
        id
        endOn
        endedOn
        offboardingNote
        member {
          id
          firstName
          lastName
          status
        }
        compensation {
          basePay {
            amount
            frequency
            currency
          }
        }
        onboarding {
          id
          status
          activationCutoff
          tasks {
            name
            order
            completed
            pendingOn {
              id
              persona
              firstName
              lastName
            }
          }
        }
        type
        term
        position
        status
        startOn
        country
        createdOn
        contractStarted
        contractBlocked
        lastWorkingDay
        offboarding {
          id
          type
          lastWorkingDay
          status
        }
      }
    }
  }
`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(
    GetTeamDocument,
    options,
  );
}
export function useGetTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamQuery,
    GetTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(
    GetTeamDocument,
    options,
  );
}
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<
  GetTeamQuery,
  GetTeamQueryVariables
>;
export const RejectChangeRequestDocument = gql`
  mutation RejectChangeRequest(
    $category: ApprovalCategory!
    $itemId: ID!
    $reason: String
  ) {
    rejectItem(category: $category, itemId: $itemId, reason: $reason) {
      id
      status
    }
  }
`;
export type RejectChangeRequestMutationFn = Apollo.MutationFunction<
  RejectChangeRequestMutation,
  RejectChangeRequestMutationVariables
>;

/**
 * __useRejectChangeRequestMutation__
 *
 * To run a mutation, you first call `useRejectChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectChangeRequestMutation, { data, loading, error }] = useRejectChangeRequestMutation({
 *   variables: {
 *      category: // value for 'category'
 *      itemId: // value for 'itemId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectChangeRequestMutation,
    RejectChangeRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectChangeRequestMutation,
    RejectChangeRequestMutationVariables
  >(RejectChangeRequestDocument, options);
}
export type RejectChangeRequestMutationHookResult = ReturnType<
  typeof useRejectChangeRequestMutation
>;
export type RejectChangeRequestMutationResult = Apollo.MutationResult<RejectChangeRequestMutation>;
export type RejectChangeRequestMutationOptions = Apollo.BaseMutationOptions<
  RejectChangeRequestMutation,
  RejectChangeRequestMutationVariables
>;
export const UpdateWorkEmailDocument = gql`
  mutation UpdateWorkEmail($id: ID!, $email: EmailAddressInput!) {
    contractWorkEmailUpdate(id: $id, email: $email) {
      id
      workEmail
    }
  }
`;
export type UpdateWorkEmailMutationFn = Apollo.MutationFunction<
  UpdateWorkEmailMutation,
  UpdateWorkEmailMutationVariables
>;

/**
 * __useUpdateWorkEmailMutation__
 *
 * To run a mutation, you first call `useUpdateWorkEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkEmailMutation, { data, loading, error }] = useUpdateWorkEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateWorkEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkEmailMutation,
    UpdateWorkEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkEmailMutation,
    UpdateWorkEmailMutationVariables
  >(UpdateWorkEmailDocument, options);
}
export type UpdateWorkEmailMutationHookResult = ReturnType<
  typeof useUpdateWorkEmailMutation
>;
export type UpdateWorkEmailMutationResult = Apollo.MutationResult<UpdateWorkEmailMutation>;
export type UpdateWorkEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkEmailMutation,
  UpdateWorkEmailMutationVariables
>;
export const MemberUpsertAddressDocument = gql`
  mutation MemberUpsertAddress($id: ID!, $input: MemberAddressInput!) {
    memberUpsertAddress(id: $id, input: $input) {
      id
      addressDetails {
        currentAddress {
          ...addressFields
        }
        permanentAddress {
          ...addressFields
        }
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type MemberUpsertAddressMutationFn = Apollo.MutationFunction<
  MemberUpsertAddressMutation,
  MemberUpsertAddressMutationVariables
>;

/**
 * __useMemberUpsertAddressMutation__
 *
 * To run a mutation, you first call `useMemberUpsertAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertAddressMutation, { data, loading, error }] = useMemberUpsertAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertAddressMutation,
    MemberUpsertAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertAddressMutation,
    MemberUpsertAddressMutationVariables
  >(MemberUpsertAddressDocument, options);
}
export type MemberUpsertAddressMutationHookResult = ReturnType<
  typeof useMemberUpsertAddressMutation
>;
export type MemberUpsertAddressMutationResult = Apollo.MutationResult<MemberUpsertAddressMutation>;
export type MemberUpsertAddressMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertAddressMutation,
  MemberUpsertAddressMutationVariables
>;
export const MemberUpsertBasicInfoDocument = gql`
  mutation MemberUpsertBasicInfo($id: ID!, $input: MemberBasicInfoInput!) {
    memberUpsertBasicInfo(id: $id, input: $input) {
      id
      basicInfo {
        firstName
        fullLegalName
        lastName
        middleName
      }
    }
  }
`;
export type MemberUpsertBasicInfoMutationFn = Apollo.MutationFunction<
  MemberUpsertBasicInfoMutation,
  MemberUpsertBasicInfoMutationVariables
>;

/**
 * __useMemberUpsertBasicInfoMutation__
 *
 * To run a mutation, you first call `useMemberUpsertBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertBasicInfoMutation, { data, loading, error }] = useMemberUpsertBasicInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertBasicInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertBasicInfoMutation,
    MemberUpsertBasicInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertBasicInfoMutation,
    MemberUpsertBasicInfoMutationVariables
  >(MemberUpsertBasicInfoDocument, options);
}
export type MemberUpsertBasicInfoMutationHookResult = ReturnType<
  typeof useMemberUpsertBasicInfoMutation
>;
export type MemberUpsertBasicInfoMutationResult = Apollo.MutationResult<MemberUpsertBasicInfoMutation>;
export type MemberUpsertBasicInfoMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertBasicInfoMutation,
  MemberUpsertBasicInfoMutationVariables
>;
export const MemberUpsertContactInfoDocument = gql`
  mutation MemberUpsertContactInfo($id: ID!, $input: MemberContactInfoInput!) {
    memberUpsertContactInfo(id: $id, input: $input) {
      id
      contactInfo {
        email {
          email
        }
        mobile {
          phoneNo
        }
        phone {
          phoneNo
        }
      }
    }
  }
`;
export type MemberUpsertContactInfoMutationFn = Apollo.MutationFunction<
  MemberUpsertContactInfoMutation,
  MemberUpsertContactInfoMutationVariables
>;

/**
 * __useMemberUpsertContactInfoMutation__
 *
 * To run a mutation, you first call `useMemberUpsertContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertContactInfoMutation, { data, loading, error }] = useMemberUpsertContactInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertContactInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertContactInfoMutation,
    MemberUpsertContactInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertContactInfoMutation,
    MemberUpsertContactInfoMutationVariables
  >(MemberUpsertContactInfoDocument, options);
}
export type MemberUpsertContactInfoMutationHookResult = ReturnType<
  typeof useMemberUpsertContactInfoMutation
>;
export type MemberUpsertContactInfoMutationResult = Apollo.MutationResult<MemberUpsertContactInfoMutation>;
export type MemberUpsertContactInfoMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertContactInfoMutation,
  MemberUpsertContactInfoMutationVariables
>;
export const MemberUpsertEducationDocument = gql`
  mutation MemberUpsertEducation($id: ID!, $input: EducationInput!) {
    memberUpsertEducation(id: $id, input: $input) {
      id
      education {
        degree
        gpa
        grade
        lastSchoolAttended
        yearOfPassing
      }
    }
  }
`;
export type MemberUpsertEducationMutationFn = Apollo.MutationFunction<
  MemberUpsertEducationMutation,
  MemberUpsertEducationMutationVariables
>;

/**
 * __useMemberUpsertEducationMutation__
 *
 * To run a mutation, you first call `useMemberUpsertEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertEducationMutation, { data, loading, error }] = useMemberUpsertEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertEducationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertEducationMutation,
    MemberUpsertEducationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertEducationMutation,
    MemberUpsertEducationMutationVariables
  >(MemberUpsertEducationDocument, options);
}
export type MemberUpsertEducationMutationHookResult = ReturnType<
  typeof useMemberUpsertEducationMutation
>;
export type MemberUpsertEducationMutationResult = Apollo.MutationResult<MemberUpsertEducationMutation>;
export type MemberUpsertEducationMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertEducationMutation,
  MemberUpsertEducationMutationVariables
>;
export const MemberUpsertEmergencyContactDocument = gql`
  mutation MemberUpsertEmergencyContact(
    $id: ID!
    $input: EmergencyContactInput!
  ) {
    memberUpsertEmergencyContact(id: $id, input: $input) {
      id
      emergencyContact {
        name
        phoneNumber {
          phoneNo
          type
        }
        relationship
      }
    }
  }
`;
export type MemberUpsertEmergencyContactMutationFn = Apollo.MutationFunction<
  MemberUpsertEmergencyContactMutation,
  MemberUpsertEmergencyContactMutationVariables
>;

/**
 * __useMemberUpsertEmergencyContactMutation__
 *
 * To run a mutation, you first call `useMemberUpsertEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertEmergencyContactMutation, { data, loading, error }] = useMemberUpsertEmergencyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertEmergencyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertEmergencyContactMutation,
    MemberUpsertEmergencyContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertEmergencyContactMutation,
    MemberUpsertEmergencyContactMutationVariables
  >(MemberUpsertEmergencyContactDocument, options);
}
export type MemberUpsertEmergencyContactMutationHookResult = ReturnType<
  typeof useMemberUpsertEmergencyContactMutation
>;
export type MemberUpsertEmergencyContactMutationResult = Apollo.MutationResult<MemberUpsertEmergencyContactMutation>;
export type MemberUpsertEmergencyContactMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertEmergencyContactMutation,
  MemberUpsertEmergencyContactMutationVariables
>;
export const MemberUpsertIdentificationDetailsDocument = gql`
  mutation MemberUpsertIdentificationDetails(
    $id: ID!
    $input: MemberIdentificationDetailsInput!
  ) {
    memberUpsertIdentificationDetails(id: $id, input: $input) {
      id
      identificationDetails {
        legalData {
          key
          value
          label
          country
        }
      }
    }
  }
`;
export type MemberUpsertIdentificationDetailsMutationFn = Apollo.MutationFunction<
  MemberUpsertIdentificationDetailsMutation,
  MemberUpsertIdentificationDetailsMutationVariables
>;

/**
 * __useMemberUpsertIdentificationDetailsMutation__
 *
 * To run a mutation, you first call `useMemberUpsertIdentificationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertIdentificationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertIdentificationDetailsMutation, { data, loading, error }] = useMemberUpsertIdentificationDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertIdentificationDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertIdentificationDetailsMutation,
    MemberUpsertIdentificationDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertIdentificationDetailsMutation,
    MemberUpsertIdentificationDetailsMutationVariables
  >(MemberUpsertIdentificationDetailsDocument, options);
}
export type MemberUpsertIdentificationDetailsMutationHookResult = ReturnType<
  typeof useMemberUpsertIdentificationDetailsMutation
>;
export type MemberUpsertIdentificationDetailsMutationResult = Apollo.MutationResult<MemberUpsertIdentificationDetailsMutation>;
export type MemberUpsertIdentificationDetailsMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertIdentificationDetailsMutation,
  MemberUpsertIdentificationDetailsMutationVariables
>;
export const MemberUpsertPersonalInfoDocument = gql`
  mutation MemberUpsertPersonalInfo(
    $id: ID!
    $input: MemberPersonalInfoInput!
  ) {
    memberUpsertPersonalInfo(id: $id, input: $input) {
      id
      personalInfo {
        age
        dateOfBirth
        maritalStatus
        nationalities {
          country
        }
        religion
      }
    }
  }
`;
export type MemberUpsertPersonalInfoMutationFn = Apollo.MutationFunction<
  MemberUpsertPersonalInfoMutation,
  MemberUpsertPersonalInfoMutationVariables
>;

/**
 * __useMemberUpsertPersonalInfoMutation__
 *
 * To run a mutation, you first call `useMemberUpsertPersonalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertPersonalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertPersonalInfoMutation, { data, loading, error }] = useMemberUpsertPersonalInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertPersonalInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertPersonalInfoMutation,
    MemberUpsertPersonalInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertPersonalInfoMutation,
    MemberUpsertPersonalInfoMutationVariables
  >(MemberUpsertPersonalInfoDocument, options);
}
export type MemberUpsertPersonalInfoMutationHookResult = ReturnType<
  typeof useMemberUpsertPersonalInfoMutation
>;
export type MemberUpsertPersonalInfoMutationResult = Apollo.MutationResult<MemberUpsertPersonalInfoMutation>;
export type MemberUpsertPersonalInfoMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertPersonalInfoMutation,
  MemberUpsertPersonalInfoMutationVariables
>;
export const MemberUpsertPreviousEmployerDocument = gql`
  mutation MemberUpsertPreviousEmployer(
    $id: ID!
    $input: PreviousEmployerInput!
  ) {
    memberUpsertPreviousEmployer(id: $id, input: $input) {
      id
      previousEmployers {
        designation
        endDate
        name
        startDate
      }
    }
  }
`;
export type MemberUpsertPreviousEmployerMutationFn = Apollo.MutationFunction<
  MemberUpsertPreviousEmployerMutation,
  MemberUpsertPreviousEmployerMutationVariables
>;

/**
 * __useMemberUpsertPreviousEmployerMutation__
 *
 * To run a mutation, you first call `useMemberUpsertPreviousEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberUpsertPreviousEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberUpsertPreviousEmployerMutation, { data, loading, error }] = useMemberUpsertPreviousEmployerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberUpsertPreviousEmployerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MemberUpsertPreviousEmployerMutation,
    MemberUpsertPreviousEmployerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MemberUpsertPreviousEmployerMutation,
    MemberUpsertPreviousEmployerMutationVariables
  >(MemberUpsertPreviousEmployerDocument, options);
}
export type MemberUpsertPreviousEmployerMutationHookResult = ReturnType<
  typeof useMemberUpsertPreviousEmployerMutation
>;
export type MemberUpsertPreviousEmployerMutationResult = Apollo.MutationResult<MemberUpsertPreviousEmployerMutation>;
export type MemberUpsertPreviousEmployerMutationOptions = Apollo.BaseMutationOptions<
  MemberUpsertPreviousEmployerMutation,
  MemberUpsertPreviousEmployerMutationVariables
>;
export const GetEmploymentCertificateDocument = gql`
  query GetEmploymentCertificate($date: Date!) {
    member {
      id
      contracts {
        id
        compliance {
          type
          contractAgreement {
            id
          }
          contractAgreementType
          ... on ComplianceMultiplierEOR {
            certificateOfEmployment(date: $date) {
              id
              contentType
              extension
              blob
              link
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetEmploymentCertificateQuery__
 *
 * To run a query within a React component, call `useGetEmploymentCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmploymentCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmploymentCertificateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetEmploymentCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmploymentCertificateQuery,
    GetEmploymentCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmploymentCertificateQuery,
    GetEmploymentCertificateQueryVariables
  >(GetEmploymentCertificateDocument, options);
}
export function useGetEmploymentCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmploymentCertificateQuery,
    GetEmploymentCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmploymentCertificateQuery,
    GetEmploymentCertificateQueryVariables
  >(GetEmploymentCertificateDocument, options);
}
export type GetEmploymentCertificateQueryHookResult = ReturnType<
  typeof useGetEmploymentCertificateQuery
>;
export type GetEmploymentCertificateLazyQueryHookResult = ReturnType<
  typeof useGetEmploymentCertificateLazyQuery
>;
export type GetEmploymentCertificateQueryResult = Apollo.QueryResult<
  GetEmploymentCertificateQuery,
  GetEmploymentCertificateQueryVariables
>;
export const CancelChangeRequestDocument = gql`
  mutation CancelChangeRequest($category: MemberChangeCategory!) {
    memberCancelUpdateRequest(category: $category) {
      id
      changeRequests {
        id
        status
        category
        message
        createdOn
        files {
          id
          contentType
          extension
          downloadUrl
          name
        }
        ...changeRequestItemsFragment
      }
    }
  }
  ${ChangeRequestItemsFragmentFragmentDoc}
`;
export type CancelChangeRequestMutationFn = Apollo.MutationFunction<
  CancelChangeRequestMutation,
  CancelChangeRequestMutationVariables
>;

/**
 * __useCancelChangeRequestMutation__
 *
 * To run a mutation, you first call `useCancelChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelChangeRequestMutation, { data, loading, error }] = useCancelChangeRequestMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCancelChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelChangeRequestMutation,
    CancelChangeRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelChangeRequestMutation,
    CancelChangeRequestMutationVariables
  >(CancelChangeRequestDocument, options);
}
export type CancelChangeRequestMutationHookResult = ReturnType<
  typeof useCancelChangeRequestMutation
>;
export type CancelChangeRequestMutationResult = Apollo.MutationResult<CancelChangeRequestMutation>;
export type CancelChangeRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelChangeRequestMutation,
  CancelChangeRequestMutationVariables
>;
export const GetMemberBankDetailsDocument = gql`
  query GetMemberBankDetails {
    member {
      id
      contracts(includeAnyStatus: true) {
        id
        type
        paymentAccountRequirements {
          ...paymentAccountRequirementFragment
        }
      }
      bankAccounts {
        id
        version
        paymentAccountRequirementType
      }
      changeRequests {
        id
        status
        category
        message
        createdOn
        ...changeRequestItemsFragment
      }
    }
  }
  ${PaymentAccountRequirementFragmentFragmentDoc}
  ${ChangeRequestItemsFragmentFragmentDoc}
`;

/**
 * __useGetMemberBankDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberBankDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBankDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBankDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberBankDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >(GetMemberBankDetailsDocument, options);
}
export function useGetMemberBankDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >(GetMemberBankDetailsDocument, options);
}
export type GetMemberBankDetailsQueryHookResult = ReturnType<
  typeof useGetMemberBankDetailsQuery
>;
export type GetMemberBankDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberBankDetailsLazyQuery
>;
export type GetMemberBankDetailsQueryResult = Apollo.QueryResult<
  GetMemberBankDetailsQuery,
  GetMemberBankDetailsQueryVariables
>;
export const GetMemberWithUpdateRequestsV2Document = gql`
  query GetMemberWithUpdateRequestsV2 {
    member {
      id
      firstName
      middleName
      lastName
      fullLegalName
      contracts(includeAnyStatus: true) {
        id
        employeeId
        term
        type
        country
        countryStateCode
        status
        company {
          id
          displayName
          status
        }
        currency
        workStatus
        position
        onboarding {
          status
          steps
          current
        }
        compensation {
          basePay {
            ...fixedPayComponent
          }
          additionalPays {
            ...compensationPayComponent
          }
        }
        compliance {
          ...compliance
        }
        alreadyHired
        startOn
        endOn
        scope
        paymentAccountRequirements {
          ...paymentAccountRequirementFragment
        }
      }
      emails {
        type
        email
      }
      addresses {
        key
        street
        line1
        line2
        city
        state
        province
        country
        zipcode
        postalCode
      }
      phoneNos {
        type
        phoneNo
      }
      gender
      maritalStatus
      dateOfBirth
      nationalities {
        type
        country
      }
      legalData {
        key
        value
        identifier
        label
        country
      }
      bankAccounts {
        id
        version
        accountName
        accountNumber
        bankName
        branchName
        currency
        country
        swiftCode
        localBankCode
        paymentAccountRequirementType
        accountDetails {
          key
          label
          value
        }
        accountRequirementContext {
          paymentAccountRequirementType
        }
        bankStatements {
          id
          name
          extension
          link
          contentType
          size
        }
      }
      legalDocuments {
        ...legalDocument
      }
      changeRequests {
        id
        status
        category
        message
        createdOn
        files {
          id
          contentType
          extension
          downloadUrl
          name
        }
        ...changeRequestItemsFragment
      }
      emergencyContact {
        name
        phoneNumber {
          phoneNo
          type
        }
        relationship
      }
      education {
        degree
        gpa
        grade
        lastSchoolAttended
        yearOfPassing
      }
      previousEmployers {
        designation
        endDate
        name
        startDate
      }
      basicInfo {
        firstName
        fullLegalName
        lastName
        middleName
      }
      personalInfo {
        gender
        age
        dateOfBirth
        maritalStatus
        nationalities {
          country
          type
        }
        religion
      }
      contactInfo {
        email {
          email
        }
        mobile {
          phoneNo
        }
        phone {
          phoneNo
        }
      }
      addressDetails {
        currentAddress {
          ...addressFields
        }
        permanentAddress {
          ...addressFields
        }
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${ComplianceFragmentDoc}
  ${PaymentAccountRequirementFragmentFragmentDoc}
  ${LegalDocumentFragmentDoc}
  ${ChangeRequestItemsFragmentFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useGetMemberWithUpdateRequestsV2Query__
 *
 * To run a query within a React component, call `useGetMemberWithUpdateRequestsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberWithUpdateRequestsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberWithUpdateRequestsV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberWithUpdateRequestsV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberWithUpdateRequestsV2Query,
    GetMemberWithUpdateRequestsV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberWithUpdateRequestsV2Query,
    GetMemberWithUpdateRequestsV2QueryVariables
  >(GetMemberWithUpdateRequestsV2Document, options);
}
export function useGetMemberWithUpdateRequestsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberWithUpdateRequestsV2Query,
    GetMemberWithUpdateRequestsV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberWithUpdateRequestsV2Query,
    GetMemberWithUpdateRequestsV2QueryVariables
  >(GetMemberWithUpdateRequestsV2Document, options);
}
export type GetMemberWithUpdateRequestsV2QueryHookResult = ReturnType<
  typeof useGetMemberWithUpdateRequestsV2Query
>;
export type GetMemberWithUpdateRequestsV2LazyQueryHookResult = ReturnType<
  typeof useGetMemberWithUpdateRequestsV2LazyQuery
>;
export type GetMemberWithUpdateRequestsV2QueryResult = Apollo.QueryResult<
  GetMemberWithUpdateRequestsV2Query,
  GetMemberWithUpdateRequestsV2QueryVariables
>;
export const GetMemberWithUpdateRequestsDocument = gql`
  query GetMemberWithUpdateRequests {
    member {
      id
      firstName
      lastName
      fullLegalName
      contracts(includeAnyStatus: true) {
        id
        term
        type
        country
        countryStateCode
        status
        company {
          id
          displayName
          status
        }
        currency
        workStatus
        employeeId
        legalEntityId
        position
        onboarding {
          status
          steps
          current
        }
        compensation {
          basePay {
            ...fixedPayComponent
          }
          additionalPays {
            ...compensationPayComponent
          }
        }
        compliance {
          ...compliance
        }
        alreadyHired
        startOn
        endOn
        scope
        paymentAccountRequirements {
          ...paymentAccountRequirementFragment
        }
      }
      emails {
        type
        email
      }
      addresses {
        key
        street
        line1
        line2
        city
        state
        province
        country
        zipcode
        postalCode
      }
      phoneNos {
        type
        phoneNo
      }
      gender
      maritalStatus
      dateOfBirth
      nationalities {
        type
        country
      }
      legalData {
        key
        value
        identifier
        label
        country
      }
      bankAccounts {
        id
        version
        accountName
        accountNumber
        bankName
        branchName
        currency
        country
        swiftCode
        localBankCode
        paymentAccountRequirementType
        accountDetails {
          key
          value
        }
        bankStatements {
          id
          name
          extension
          link
          contentType
          size
        }
      }
      legalDocuments {
        ...legalDocument
      }
      changeRequests {
        id
        status
        category
        message
        createdOn
        files {
          id
          contentType
          extension
          downloadUrl
          name
        }
        ...changeRequestItemsFragment
      }
    }
  }
  ${FixedPayComponentFragmentDoc}
  ${CompensationPayComponentFragmentDoc}
  ${ComplianceFragmentDoc}
  ${PaymentAccountRequirementFragmentFragmentDoc}
  ${LegalDocumentFragmentDoc}
  ${ChangeRequestItemsFragmentFragmentDoc}
`;

/**
 * __useGetMemberWithUpdateRequestsQuery__
 *
 * To run a query within a React component, call `useGetMemberWithUpdateRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberWithUpdateRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberWithUpdateRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberWithUpdateRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberWithUpdateRequestsQuery,
    GetMemberWithUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberWithUpdateRequestsQuery,
    GetMemberWithUpdateRequestsQueryVariables
  >(GetMemberWithUpdateRequestsDocument, options);
}
export function useGetMemberWithUpdateRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberWithUpdateRequestsQuery,
    GetMemberWithUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberWithUpdateRequestsQuery,
    GetMemberWithUpdateRequestsQueryVariables
  >(GetMemberWithUpdateRequestsDocument, options);
}
export type GetMemberWithUpdateRequestsQueryHookResult = ReturnType<
  typeof useGetMemberWithUpdateRequestsQuery
>;
export type GetMemberWithUpdateRequestsLazyQueryHookResult = ReturnType<
  typeof useGetMemberWithUpdateRequestsLazyQuery
>;
export type GetMemberWithUpdateRequestsQueryResult = Apollo.QueryResult<
  GetMemberWithUpdateRequestsQuery,
  GetMemberWithUpdateRequestsQueryVariables
>;
export const UpdateMemberBankDetailsDocument = gql`
  mutation UpdateMemberBankDetails($detail: MemberChangeRequestInput!) {
    memberSendUpdateRequest(category: BANK_DETAILS, requestInput: $detail) {
      id
      changeRequests {
        id
        status
        category
        message
        createdOn
        files {
          id
          contentType
          extension
          downloadUrl
          name
        }
        ...changeRequestItemsFragment
      }
    }
  }
  ${ChangeRequestItemsFragmentFragmentDoc}
`;
export type UpdateMemberBankDetailsMutationFn = Apollo.MutationFunction<
  UpdateMemberBankDetailsMutation,
  UpdateMemberBankDetailsMutationVariables
>;

/**
 * __useUpdateMemberBankDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMemberBankDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberBankDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberBankDetailsMutation, { data, loading, error }] = useUpdateMemberBankDetailsMutation({
 *   variables: {
 *      detail: // value for 'detail'
 *   },
 * });
 */
export function useUpdateMemberBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberBankDetailsMutation,
    UpdateMemberBankDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberBankDetailsMutation,
    UpdateMemberBankDetailsMutationVariables
  >(UpdateMemberBankDetailsDocument, options);
}
export type UpdateMemberBankDetailsMutationHookResult = ReturnType<
  typeof useUpdateMemberBankDetailsMutation
>;
export type UpdateMemberBankDetailsMutationResult = Apollo.MutationResult<UpdateMemberBankDetailsMutation>;
export type UpdateMemberBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberBankDetailsMutation,
  UpdateMemberBankDetailsMutationVariables
>;
export const UpdateMemberBasicDetailsDocument = gql`
  mutation UpdateMemberBasicDetails($detail: MemberChangeRequestInput!) {
    memberSendUpdateRequest(category: BASIC_DETAILS, requestInput: $detail) {
      id
      changeRequests {
        id
        status
        category
        message
        createdOn
        files {
          id
          contentType
          extension
          downloadUrl
          name
        }
        ...changeRequestItemsFragment
      }
    }
  }
  ${ChangeRequestItemsFragmentFragmentDoc}
`;
export type UpdateMemberBasicDetailsMutationFn = Apollo.MutationFunction<
  UpdateMemberBasicDetailsMutation,
  UpdateMemberBasicDetailsMutationVariables
>;

/**
 * __useUpdateMemberBasicDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMemberBasicDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberBasicDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberBasicDetailsMutation, { data, loading, error }] = useUpdateMemberBasicDetailsMutation({
 *   variables: {
 *      detail: // value for 'detail'
 *   },
 * });
 */
export function useUpdateMemberBasicDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberBasicDetailsMutation,
    UpdateMemberBasicDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberBasicDetailsMutation,
    UpdateMemberBasicDetailsMutationVariables
  >(UpdateMemberBasicDetailsDocument, options);
}
export type UpdateMemberBasicDetailsMutationHookResult = ReturnType<
  typeof useUpdateMemberBasicDetailsMutation
>;
export type UpdateMemberBasicDetailsMutationResult = Apollo.MutationResult<UpdateMemberBasicDetailsMutation>;
export type UpdateMemberBasicDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberBasicDetailsMutation,
  UpdateMemberBasicDetailsMutationVariables
>;
export const UpdateMemberContactDetailsDocument = gql`
  mutation UpdateMemberContactDetails($detail: MemberChangeRequestInput!) {
    memberSendUpdateRequest(category: CONTACT_DETAILS, requestInput: $detail) {
      id
    }
  }
`;
export type UpdateMemberContactDetailsMutationFn = Apollo.MutationFunction<
  UpdateMemberContactDetailsMutation,
  UpdateMemberContactDetailsMutationVariables
>;

/**
 * __useUpdateMemberContactDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMemberContactDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberContactDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberContactDetailsMutation, { data, loading, error }] = useUpdateMemberContactDetailsMutation({
 *   variables: {
 *      detail: // value for 'detail'
 *   },
 * });
 */
export function useUpdateMemberContactDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberContactDetailsMutation,
    UpdateMemberContactDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberContactDetailsMutation,
    UpdateMemberContactDetailsMutationVariables
  >(UpdateMemberContactDetailsDocument, options);
}
export type UpdateMemberContactDetailsMutationHookResult = ReturnType<
  typeof useUpdateMemberContactDetailsMutation
>;
export type UpdateMemberContactDetailsMutationResult = Apollo.MutationResult<UpdateMemberContactDetailsMutation>;
export type UpdateMemberContactDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberContactDetailsMutation,
  UpdateMemberContactDetailsMutationVariables
>;
export const UpdateMemberIdentificationDetailsDocument = gql`
  mutation UpdateMemberIdentificationDetails(
    $detail: MemberChangeRequestInput!
  ) {
    memberSendUpdateRequest(
      category: IDENTIFICATION_DETAILS
      requestInput: $detail
    ) {
      id
      changeRequests {
        id
        status
        category
        message
        createdOn
        files {
          id
          contentType
          extension
          downloadUrl
          name
        }
        ...changeRequestItemsFragment
      }
    }
  }
  ${ChangeRequestItemsFragmentFragmentDoc}
`;
export type UpdateMemberIdentificationDetailsMutationFn = Apollo.MutationFunction<
  UpdateMemberIdentificationDetailsMutation,
  UpdateMemberIdentificationDetailsMutationVariables
>;

/**
 * __useUpdateMemberIdentificationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMemberIdentificationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberIdentificationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberIdentificationDetailsMutation, { data, loading, error }] = useUpdateMemberIdentificationDetailsMutation({
 *   variables: {
 *      detail: // value for 'detail'
 *   },
 * });
 */
export function useUpdateMemberIdentificationDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberIdentificationDetailsMutation,
    UpdateMemberIdentificationDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberIdentificationDetailsMutation,
    UpdateMemberIdentificationDetailsMutationVariables
  >(UpdateMemberIdentificationDetailsDocument, options);
}
export type UpdateMemberIdentificationDetailsMutationHookResult = ReturnType<
  typeof useUpdateMemberIdentificationDetailsMutation
>;
export type UpdateMemberIdentificationDetailsMutationResult = Apollo.MutationResult<UpdateMemberIdentificationDetailsMutation>;
export type UpdateMemberIdentificationDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberIdentificationDetailsMutation,
  UpdateMemberIdentificationDetailsMutationVariables
>;
export const GetMemberRewardsStatementsDocument = gql`
  query GetMemberRewardsStatements {
    member {
      id
      contracts {
        id
        performanceReviews {
          id
          effectiveDate
          reviewDocument {
            ...documentReadable
          }
          status
        }
        salaryReviews {
          id
          effectiveDate
          reviewDocument {
            ...documentReadable
          }
          state
        }
      }
    }
  }
  ${DocumentReadableFragmentDoc}
`;

/**
 * __useGetMemberRewardsStatementsQuery__
 *
 * To run a query within a React component, call `useGetMemberRewardsStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberRewardsStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberRewardsStatementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberRewardsStatementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberRewardsStatementsQuery,
    GetMemberRewardsStatementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberRewardsStatementsQuery,
    GetMemberRewardsStatementsQueryVariables
  >(GetMemberRewardsStatementsDocument, options);
}
export function useGetMemberRewardsStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberRewardsStatementsQuery,
    GetMemberRewardsStatementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberRewardsStatementsQuery,
    GetMemberRewardsStatementsQueryVariables
  >(GetMemberRewardsStatementsDocument, options);
}
export type GetMemberRewardsStatementsQueryHookResult = ReturnType<
  typeof useGetMemberRewardsStatementsQuery
>;
export type GetMemberRewardsStatementsLazyQueryHookResult = ReturnType<
  typeof useGetMemberRewardsStatementsLazyQuery
>;
export type GetMemberRewardsStatementsQueryResult = Apollo.QueryResult<
  GetMemberRewardsStatementsQuery,
  GetMemberRewardsStatementsQueryVariables
>;
export const ApproveTimeOffDocument = gql`
  mutation ApproveTimeOff($itemId: ID!) {
    approveItem(itemId: $itemId, category: TIME_OFF) {
      ... on TimeOffApprovalItem {
        id
        status
        timeOffId
        timeOffItem {
          id
          contract {
            id
            member {
              id
              firstName
              lastName
            }
            company {
              id
            }
            position
          }
          status
          noOfDays
          description
          startDate {
            dateOnly
            session
          }
          endDate {
            dateOnly
            session
          }
          isActionable
          changeReason
        }
      }
    }
  }
`;
export type ApproveTimeOffMutationFn = Apollo.MutationFunction<
  ApproveTimeOffMutation,
  ApproveTimeOffMutationVariables
>;

/**
 * __useApproveTimeOffMutation__
 *
 * To run a mutation, you first call `useApproveTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTimeOffMutation, { data, loading, error }] = useApproveTimeOffMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useApproveTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveTimeOffMutation,
    ApproveTimeOffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveTimeOffMutation,
    ApproveTimeOffMutationVariables
  >(ApproveTimeOffDocument, options);
}
export type ApproveTimeOffMutationHookResult = ReturnType<
  typeof useApproveTimeOffMutation
>;
export type ApproveTimeOffMutationResult = Apollo.MutationResult<ApproveTimeOffMutation>;
export type ApproveTimeOffMutationOptions = Apollo.BaseMutationOptions<
  ApproveTimeOffMutation,
  ApproveTimeOffMutationVariables
>;
export const ForwardTimeOffDocument = gql`
  mutation ForwardTimeOff($itemId: ID!, $forwardUserId: ID!) {
    forwardItem(
      itemId: $itemId
      forwardUserId: $forwardUserId
      category: TIME_OFF
    ) {
      ... on TimeOffApprovalItem {
        id
        status
        timeOffId
        timeOffItem {
          id
          contract {
            id
            member {
              id
              firstName
              lastName
            }
            company {
              id
            }
            position
          }
          status
          noOfDays
          description
          startDate {
            dateOnly
            session
          }
          endDate {
            dateOnly
            session
          }
          isActionable
          changeReason
        }
      }
    }
  }
`;
export type ForwardTimeOffMutationFn = Apollo.MutationFunction<
  ForwardTimeOffMutation,
  ForwardTimeOffMutationVariables
>;

/**
 * __useForwardTimeOffMutation__
 *
 * To run a mutation, you first call `useForwardTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForwardTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forwardTimeOffMutation, { data, loading, error }] = useForwardTimeOffMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      forwardUserId: // value for 'forwardUserId'
 *   },
 * });
 */
export function useForwardTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForwardTimeOffMutation,
    ForwardTimeOffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForwardTimeOffMutation,
    ForwardTimeOffMutationVariables
  >(ForwardTimeOffDocument, options);
}
export type ForwardTimeOffMutationHookResult = ReturnType<
  typeof useForwardTimeOffMutation
>;
export type ForwardTimeOffMutationResult = Apollo.MutationResult<ForwardTimeOffMutation>;
export type ForwardTimeOffMutationOptions = Apollo.BaseMutationOptions<
  ForwardTimeOffMutation,
  ForwardTimeOffMutationVariables
>;
export const GetCompanyTimeOffDetailsDocument = gql`
  query GetCompanyTimeOffDetails(
    $country: CountryCode!
    $countryStateCode: String
    $contractType: ContractType
    $term: ContractTerm
    $contractStatus: ContractStatus
    $companyId: ID
  ) {
    company {
      timeOffRequirements(
        filters: {
          contractType: $contractType
          term: $term
          contractStatus: $contractStatus
          country: { code: $country, stateCode: $countryStateCode }
          companyId: $companyId
        }
      ) {
        definitions {
          type
          required
          label
          description
          validation {
            ... on TimeOffFixedValidation {
              minimum
              maximum
              defaultValue
              unit
            }
          }
          assignedEntityNames
          configuration {
            allocation {
              basis
              prorated
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyTimeOffDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyTimeOffDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTimeOffDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTimeOffDetailsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      countryStateCode: // value for 'countryStateCode'
 *      contractType: // value for 'contractType'
 *      term: // value for 'term'
 *      contractStatus: // value for 'contractStatus'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyTimeOffDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyTimeOffDetailsQuery,
    GetCompanyTimeOffDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyTimeOffDetailsQuery,
    GetCompanyTimeOffDetailsQueryVariables
  >(GetCompanyTimeOffDetailsDocument, options);
}
export function useGetCompanyTimeOffDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyTimeOffDetailsQuery,
    GetCompanyTimeOffDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyTimeOffDetailsQuery,
    GetCompanyTimeOffDetailsQueryVariables
  >(GetCompanyTimeOffDetailsDocument, options);
}
export type GetCompanyTimeOffDetailsQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffDetailsQuery
>;
export type GetCompanyTimeOffDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffDetailsLazyQuery
>;
export type GetCompanyTimeOffDetailsQueryResult = Apollo.QueryResult<
  GetCompanyTimeOffDetailsQuery,
  GetCompanyTimeOffDetailsQueryVariables
>;
export const GetCompanyTimeOffDocument = gql`
  query GetCompanyTimeOff($id: ID!) {
    company {
      id
      timeOffs(id: $id) {
        id
        createdOn
        createdBy
        noOfDays
        description
        startDate {
          dateOnly
          session
        }
        endDate {
          dateOnly
          session
        }
        type {
          key
          definition {
            type
            label
            assignedEntityNames
          }
        }
        contract {
          id
          startOn
          country
          position
          legalEntityId
          member {
            id
            firstName
            lastName
          }
          timeOff {
            summary {
              key
              definition {
                type
                label
                assignedEntityNames
              }
              periodStart
              periodEnd
              taken
              remaining
              entitled
              pending
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyTimeOffQuery__
 *
 * To run a query within a React component, call `useGetCompanyTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTimeOffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyTimeOffQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyTimeOffQuery,
    GetCompanyTimeOffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyTimeOffQuery,
    GetCompanyTimeOffQueryVariables
  >(GetCompanyTimeOffDocument, options);
}
export function useGetCompanyTimeOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyTimeOffQuery,
    GetCompanyTimeOffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyTimeOffQuery,
    GetCompanyTimeOffQueryVariables
  >(GetCompanyTimeOffDocument, options);
}
export type GetCompanyTimeOffQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffQuery
>;
export type GetCompanyTimeOffLazyQueryHookResult = ReturnType<
  typeof useGetCompanyTimeOffLazyQuery
>;
export type GetCompanyTimeOffQueryResult = Apollo.QueryResult<
  GetCompanyTimeOffQuery,
  GetCompanyTimeOffQueryVariables
>;
export const CompanyTimeOffsDocument = gql`
  query CompanyTimeOffs {
    company {
      id
      timeOffs {
        id
        contract {
          id
          member {
            id
            firstName
            lastName
          }
          company {
            id
          }
          position
        }
        createdBy
        createdOn
        status
        type {
          key
          definition {
            type
            label
          }
        }
        noOfDays
        description
        startDate {
          dateOnly
          session
        }
        endDate {
          dateOnly
          session
        }
        isActionable
        changeReason
      }
    }
  }
`;

/**
 * __useCompanyTimeOffsQuery__
 *
 * To run a query within a React component, call `useCompanyTimeOffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTimeOffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTimeOffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyTimeOffsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyTimeOffsQuery,
    CompanyTimeOffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyTimeOffsQuery, CompanyTimeOffsQueryVariables>(
    CompanyTimeOffsDocument,
    options,
  );
}
export function useCompanyTimeOffsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyTimeOffsQuery,
    CompanyTimeOffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyTimeOffsQuery,
    CompanyTimeOffsQueryVariables
  >(CompanyTimeOffsDocument, options);
}
export type CompanyTimeOffsQueryHookResult = ReturnType<
  typeof useCompanyTimeOffsQuery
>;
export type CompanyTimeOffsLazyQueryHookResult = ReturnType<
  typeof useCompanyTimeOffsLazyQuery
>;
export type CompanyTimeOffsQueryResult = Apollo.QueryResult<
  CompanyTimeOffsQuery,
  CompanyTimeOffsQueryVariables
>;
export const GetContractTimeOffDocument = gql`
  query GetContractTimeOff($id: ID!, $timeOffId: ID!) {
    contract(id: $id) {
      id
      startOn
      member {
        lastName
        firstName
      }
      country
      position
      timeOff(id: $timeOffId) {
        timeOffs {
          id
          createdOn
          createdBy
          noOfDays
          description
          startDate {
            dateOnly
            session
          }
          endDate {
            dateOnly
            session
          }
          type {
            key
            definition {
              type
              label
            }
          }
        }
        summary {
          key
          definition {
            type
            label
          }
          contract {
            id
          }
          periodStart
          periodEnd
          entitled
          taken
          pending
          remaining
        }
      }
    }
  }
`;

/**
 * __useGetContractTimeOffQuery__
 *
 * To run a query within a React component, call `useGetContractTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTimeOffQuery({
 *   variables: {
 *      id: // value for 'id'
 *      timeOffId: // value for 'timeOffId'
 *   },
 * });
 */
export function useGetContractTimeOffQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractTimeOffQuery,
    GetContractTimeOffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractTimeOffQuery,
    GetContractTimeOffQueryVariables
  >(GetContractTimeOffDocument, options);
}
export function useGetContractTimeOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractTimeOffQuery,
    GetContractTimeOffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractTimeOffQuery,
    GetContractTimeOffQueryVariables
  >(GetContractTimeOffDocument, options);
}
export type GetContractTimeOffQueryHookResult = ReturnType<
  typeof useGetContractTimeOffQuery
>;
export type GetContractTimeOffLazyQueryHookResult = ReturnType<
  typeof useGetContractTimeOffLazyQuery
>;
export type GetContractTimeOffQueryResult = Apollo.QueryResult<
  GetContractTimeOffQuery,
  GetContractTimeOffQueryVariables
>;
export const GetContractTimeOffsComplianceDocument = gql`
  query GetContractTimeOffsCompliance($id: ID!) {
    contract(id: $id) {
      id
      compliance {
        timeOffEntitlement {
          timeOffType {
            type
            typeId
          }
          definition {
            type
            label
            required
            validation {
              ... on TimeOffFixedValidation {
                minimum
                maximum
                defaultValue
                unit
              }
            }
            assignedEntityNames
            configuration {
              allocation {
                basis
                prorated
              }
            }
          }
          value
          unit
        }
      }
    }
  }
`;

/**
 * __useGetContractTimeOffsComplianceQuery__
 *
 * To run a query within a React component, call `useGetContractTimeOffsComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTimeOffsComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTimeOffsComplianceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractTimeOffsComplianceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractTimeOffsComplianceQuery,
    GetContractTimeOffsComplianceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractTimeOffsComplianceQuery,
    GetContractTimeOffsComplianceQueryVariables
  >(GetContractTimeOffsComplianceDocument, options);
}
export function useGetContractTimeOffsComplianceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractTimeOffsComplianceQuery,
    GetContractTimeOffsComplianceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractTimeOffsComplianceQuery,
    GetContractTimeOffsComplianceQueryVariables
  >(GetContractTimeOffsComplianceDocument, options);
}
export type GetContractTimeOffsComplianceQueryHookResult = ReturnType<
  typeof useGetContractTimeOffsComplianceQuery
>;
export type GetContractTimeOffsComplianceLazyQueryHookResult = ReturnType<
  typeof useGetContractTimeOffsComplianceLazyQuery
>;
export type GetContractTimeOffsComplianceQueryResult = Apollo.QueryResult<
  GetContractTimeOffsComplianceQuery,
  GetContractTimeOffsComplianceQueryVariables
>;
export const GetContractTimeOffsDocument = gql`
  query GetContractTimeOffs($id: ID!) {
    contract(id: $id, isApprovalRelated: true) {
      id
      type
      startOn
      country
      legalEntityId
      timeOff {
        timeOffs {
          id
          status
          createdOn
          createdBy
          noOfDays
          description
          startDate {
            dateOnly
            session
          }
          endDate {
            dateOnly
            session
          }
          type {
            key
            definition {
              type
              label
            }
          }
        }
        summary {
          key
          definition {
            type
            label
            assignedEntityNames
          }
          periodStart
          periodEnd
          entitled
          taken
          pending
          remaining
        }
      }
    }
  }
`;

/**
 * __useGetContractTimeOffsQuery__
 *
 * To run a query within a React component, call `useGetContractTimeOffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTimeOffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTimeOffsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractTimeOffsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractTimeOffsQuery,
    GetContractTimeOffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractTimeOffsQuery,
    GetContractTimeOffsQueryVariables
  >(GetContractTimeOffsDocument, options);
}
export function useGetContractTimeOffsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractTimeOffsQuery,
    GetContractTimeOffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractTimeOffsQuery,
    GetContractTimeOffsQueryVariables
  >(GetContractTimeOffsDocument, options);
}
export type GetContractTimeOffsQueryHookResult = ReturnType<
  typeof useGetContractTimeOffsQuery
>;
export type GetContractTimeOffsLazyQueryHookResult = ReturnType<
  typeof useGetContractTimeOffsLazyQuery
>;
export type GetContractTimeOffsQueryResult = Apollo.QueryResult<
  GetContractTimeOffsQuery,
  GetContractTimeOffsQueryVariables
>;
export const GetTimeOffApproversDocument = gql`
  query GetTimeOffApprovers($itemId: ID!) {
    getAllApprovers(itemId: $itemId, category: TIME_OFF) {
      id
      approverUser {
        ... on CompanyUser {
          id
          firstName
          lastName
          status
        }
        ... on Member {
          id
          firstName
          lastName
        }
        ... on OperationsUser {
          id
          firstName
          lastName
        }
        ... on EmergencyPointOfContact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetTimeOffApproversQuery__
 *
 * To run a query within a React component, call `useGetTimeOffApproversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffApproversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffApproversQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetTimeOffApproversQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTimeOffApproversQuery,
    GetTimeOffApproversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTimeOffApproversQuery,
    GetTimeOffApproversQueryVariables
  >(GetTimeOffApproversDocument, options);
}
export function useGetTimeOffApproversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeOffApproversQuery,
    GetTimeOffApproversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTimeOffApproversQuery,
    GetTimeOffApproversQueryVariables
  >(GetTimeOffApproversDocument, options);
}
export type GetTimeOffApproversQueryHookResult = ReturnType<
  typeof useGetTimeOffApproversQuery
>;
export type GetTimeOffApproversLazyQueryHookResult = ReturnType<
  typeof useGetTimeOffApproversLazyQuery
>;
export type GetTimeOffApproversQueryResult = Apollo.QueryResult<
  GetTimeOffApproversQuery,
  GetTimeOffApproversQueryVariables
>;
export const RejectTimeOffDocument = gql`
  mutation RejectTimeOff($itemId: ID!, $reason: String) {
    rejectItem(itemId: $itemId, reason: $reason, category: TIME_OFF) {
      ... on TimeOffApprovalItem {
        id
        status
        timeOffId
        timeOffItem {
          id
          contract {
            id
            member {
              id
              firstName
              lastName
            }
            company {
              id
            }
            position
          }
          status
          noOfDays
          description
          startDate {
            dateOnly
            session
          }
          endDate {
            dateOnly
            session
          }
          isActionable
          changeReason
        }
      }
    }
  }
`;
export type RejectTimeOffMutationFn = Apollo.MutationFunction<
  RejectTimeOffMutation,
  RejectTimeOffMutationVariables
>;

/**
 * __useRejectTimeOffMutation__
 *
 * To run a mutation, you first call `useRejectTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTimeOffMutation, { data, loading, error }] = useRejectTimeOffMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectTimeOffMutation,
    RejectTimeOffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectTimeOffMutation,
    RejectTimeOffMutationVariables
  >(RejectTimeOffDocument, options);
}
export type RejectTimeOffMutationHookResult = ReturnType<
  typeof useRejectTimeOffMutation
>;
export type RejectTimeOffMutationResult = Apollo.MutationResult<RejectTimeOffMutation>;
export type RejectTimeOffMutationOptions = Apollo.BaseMutationOptions<
  RejectTimeOffMutation,
  RejectTimeOffMutationVariables
>;
export const GetMemberTimeOffDocument = gql`
  query GetMemberTimeOff($id: ID) {
    member {
      id
      contracts {
        id
        term
        type
        country
        status
        timeOff(id: $id) {
          timeOffs {
            id
            type {
              key
            }
            status
            noOfDays
            description
            changeReason
            startDate {
              dateOnly
              session
            }
            endDate {
              dateOnly
              session
            }
          }
          summary {
            key
            definition {
              type
              label
              assignedEntityNames
            }
            contract {
              id
            }
            periodStart
            periodEnd
            entitled
            taken
            pending
            remaining
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberTimeOffQuery__
 *
 * To run a query within a React component, call `useGetMemberTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberTimeOffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberTimeOffQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberTimeOffQuery,
    GetMemberTimeOffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberTimeOffQuery, GetMemberTimeOffQueryVariables>(
    GetMemberTimeOffDocument,
    options,
  );
}
export function useGetMemberTimeOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberTimeOffQuery,
    GetMemberTimeOffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberTimeOffQuery,
    GetMemberTimeOffQueryVariables
  >(GetMemberTimeOffDocument, options);
}
export type GetMemberTimeOffQueryHookResult = ReturnType<
  typeof useGetMemberTimeOffQuery
>;
export type GetMemberTimeOffLazyQueryHookResult = ReturnType<
  typeof useGetMemberTimeOffLazyQuery
>;
export type GetMemberTimeOffQueryResult = Apollo.QueryResult<
  GetMemberTimeOffQuery,
  GetMemberTimeOffQueryVariables
>;
export const GetMemberTimeOffsDocument = gql`
  query GetMemberTimeOffs {
    member {
      id
      contracts {
        id
        type
        country
        status
        legalEntityId
        timeOff {
          timeOffs {
            id
            type {
              key
              definition {
                type
                label
              }
            }
            createdOn
            createdBy
            status
            noOfDays
            description
            changeReason
            startDate {
              dateOnly
              session
            }
            endDate {
              dateOnly
              session
            }
          }
          summary {
            key
            definition {
              type
              label
              assignedEntityNames
            }
            contract {
              id
            }
            periodStart
            periodEnd
            entitled
            taken
            pending
            remaining
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberTimeOffsQuery__
 *
 * To run a query within a React component, call `useGetMemberTimeOffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberTimeOffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberTimeOffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberTimeOffsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberTimeOffsQuery,
    GetMemberTimeOffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberTimeOffsQuery,
    GetMemberTimeOffsQueryVariables
  >(GetMemberTimeOffsDocument, options);
}
export function useGetMemberTimeOffsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberTimeOffsQuery,
    GetMemberTimeOffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberTimeOffsQuery,
    GetMemberTimeOffsQueryVariables
  >(GetMemberTimeOffsDocument, options);
}
export type GetMemberTimeOffsQueryHookResult = ReturnType<
  typeof useGetMemberTimeOffsQuery
>;
export type GetMemberTimeOffsLazyQueryHookResult = ReturnType<
  typeof useGetMemberTimeOffsLazyQuery
>;
export type GetMemberTimeOffsQueryResult = Apollo.QueryResult<
  GetMemberTimeOffsQuery,
  GetMemberTimeOffsQueryVariables
>;
export const CreateTimeoffAsDraftDocument = gql`
  mutation CreateTimeoffAsDraft($input: TimeOffSaveAsDraftInput!) {
    timeOffSaveAsDraft(input: $input) {
      id
      type {
        key
      }
      status
      noOfDays
      description
      changeReason
      startDate {
        dateOnly
        session
      }
      endDate {
        dateOnly
        session
      }
    }
  }
`;
export type CreateTimeoffAsDraftMutationFn = Apollo.MutationFunction<
  CreateTimeoffAsDraftMutation,
  CreateTimeoffAsDraftMutationVariables
>;

/**
 * __useCreateTimeoffAsDraftMutation__
 *
 * To run a mutation, you first call `useCreateTimeoffAsDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeoffAsDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeoffAsDraftMutation, { data, loading, error }] = useCreateTimeoffAsDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeoffAsDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTimeoffAsDraftMutation,
    CreateTimeoffAsDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTimeoffAsDraftMutation,
    CreateTimeoffAsDraftMutationVariables
  >(CreateTimeoffAsDraftDocument, options);
}
export type CreateTimeoffAsDraftMutationHookResult = ReturnType<
  typeof useCreateTimeoffAsDraftMutation
>;
export type CreateTimeoffAsDraftMutationResult = Apollo.MutationResult<CreateTimeoffAsDraftMutation>;
export type CreateTimeoffAsDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeoffAsDraftMutation,
  CreateTimeoffAsDraftMutationVariables
>;
export const CreateTimeoffDocument = gql`
  mutation CreateTimeoff($contractId: ID, $input: TimeOffCreateInput!) {
    timeOffCreate(contractId: $contractId, input: $input) {
      id
      type {
        key
      }
      status
      noOfDays
      description
      changeReason
      startDate {
        dateOnly
        session
      }
      endDate {
        dateOnly
        session
      }
    }
  }
`;
export type CreateTimeoffMutationFn = Apollo.MutationFunction<
  CreateTimeoffMutation,
  CreateTimeoffMutationVariables
>;

/**
 * __useCreateTimeoffMutation__
 *
 * To run a mutation, you first call `useCreateTimeoffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeoffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeoffMutation, { data, loading, error }] = useCreateTimeoffMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeoffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTimeoffMutation,
    CreateTimeoffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTimeoffMutation,
    CreateTimeoffMutationVariables
  >(CreateTimeoffDocument, options);
}
export type CreateTimeoffMutationHookResult = ReturnType<
  typeof useCreateTimeoffMutation
>;
export type CreateTimeoffMutationResult = Apollo.MutationResult<CreateTimeoffMutation>;
export type CreateTimeoffMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeoffMutation,
  CreateTimeoffMutationVariables
>;
export const DeleteTimeoffDocument = gql`
  mutation DeleteTimeoff($id: ID!) {
    timeOffDelete(id: $id) {
      success
      message
    }
  }
`;
export type DeleteTimeoffMutationFn = Apollo.MutationFunction<
  DeleteTimeoffMutation,
  DeleteTimeoffMutationVariables
>;

/**
 * __useDeleteTimeoffMutation__
 *
 * To run a mutation, you first call `useDeleteTimeoffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeoffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeoffMutation, { data, loading, error }] = useDeleteTimeoffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeoffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTimeoffMutation,
    DeleteTimeoffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTimeoffMutation,
    DeleteTimeoffMutationVariables
  >(DeleteTimeoffDocument, options);
}
export type DeleteTimeoffMutationHookResult = ReturnType<
  typeof useDeleteTimeoffMutation
>;
export type DeleteTimeoffMutationResult = Apollo.MutationResult<DeleteTimeoffMutation>;
export type DeleteTimeoffMutationOptions = Apollo.BaseMutationOptions<
  DeleteTimeoffMutation,
  DeleteTimeoffMutationVariables
>;
export const UpdateTimeoffDocument = gql`
  mutation UpdateTimeoff($id: ID!, $input: TimeOffUpdateInput!) {
    timeOffUpdate(id: $id, input: $input) {
      id
      type {
        key
      }
      status
      noOfDays
      description
      changeReason
      startDate {
        dateOnly
        session
      }
      endDate {
        dateOnly
        session
      }
    }
  }
`;
export type UpdateTimeoffMutationFn = Apollo.MutationFunction<
  UpdateTimeoffMutation,
  UpdateTimeoffMutationVariables
>;

/**
 * __useUpdateTimeoffMutation__
 *
 * To run a mutation, you first call `useUpdateTimeoffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeoffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeoffMutation, { data, loading, error }] = useUpdateTimeoffMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeoffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTimeoffMutation,
    UpdateTimeoffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTimeoffMutation,
    UpdateTimeoffMutationVariables
  >(UpdateTimeoffDocument, options);
}
export type UpdateTimeoffMutationHookResult = ReturnType<
  typeof useUpdateTimeoffMutation
>;
export type UpdateTimeoffMutationResult = Apollo.MutationResult<UpdateTimeoffMutation>;
export type UpdateTimeoffMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeoffMutation,
  UpdateTimeoffMutationVariables
>;
export const RevokeTimeOffDocument = gql`
  mutation RevokeTimeOff($id: ID!) {
    timeOffRevoke(id: $id) {
      id
      type {
        key
      }
      status
      noOfDays
      description
      changeReason
      startDate {
        dateOnly
        session
      }
      endDate {
        dateOnly
        session
      }
    }
  }
`;
export type RevokeTimeOffMutationFn = Apollo.MutationFunction<
  RevokeTimeOffMutation,
  RevokeTimeOffMutationVariables
>;

/**
 * __useRevokeTimeOffMutation__
 *
 * To run a mutation, you first call `useRevokeTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTimeOffMutation, { data, loading, error }] = useRevokeTimeOffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeTimeOffMutation,
    RevokeTimeOffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeTimeOffMutation,
    RevokeTimeOffMutationVariables
  >(RevokeTimeOffDocument, options);
}
export type RevokeTimeOffMutationHookResult = ReturnType<
  typeof useRevokeTimeOffMutation
>;
export type RevokeTimeOffMutationResult = Apollo.MutationResult<RevokeTimeOffMutation>;
export type RevokeTimeOffMutationOptions = Apollo.BaseMutationOptions<
  RevokeTimeOffMutation,
  RevokeTimeOffMutationVariables
>;
export const TimesheetEntriesApproveDocument = gql`
  mutation TimesheetEntriesApprove($timesheetEntryIds: [ID!]!) {
    timesheetEntriesApprove(timesheetEntryIds: $timesheetEntryIds) {
      ...timesheetEntry
    }
  }
  ${TimesheetEntryFragmentDoc}
`;
export type TimesheetEntriesApproveMutationFn = Apollo.MutationFunction<
  TimesheetEntriesApproveMutation,
  TimesheetEntriesApproveMutationVariables
>;

/**
 * __useTimesheetEntriesApproveMutation__
 *
 * To run a mutation, you first call `useTimesheetEntriesApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntriesApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetEntriesApproveMutation, { data, loading, error }] = useTimesheetEntriesApproveMutation({
 *   variables: {
 *      timesheetEntryIds: // value for 'timesheetEntryIds'
 *   },
 * });
 */
export function useTimesheetEntriesApproveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetEntriesApproveMutation,
    TimesheetEntriesApproveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetEntriesApproveMutation,
    TimesheetEntriesApproveMutationVariables
  >(TimesheetEntriesApproveDocument, options);
}
export type TimesheetEntriesApproveMutationHookResult = ReturnType<
  typeof useTimesheetEntriesApproveMutation
>;
export type TimesheetEntriesApproveMutationResult = Apollo.MutationResult<TimesheetEntriesApproveMutation>;
export type TimesheetEntriesApproveMutationOptions = Apollo.BaseMutationOptions<
  TimesheetEntriesApproveMutation,
  TimesheetEntriesApproveMutationVariables
>;
export const GetEmployerTimesheetNextAndPreviousTimesheetsIdsDocument = gql`
  query GetEmployerTimesheetNextAndPreviousTimesheetsIds(
    $id: ID
    $startDate: DateTime
    $endDate: DateTime
    $contractId: ID
  ) {
    company {
      id
      displayName
      timesheets(
        id: $id
        timesheetFilters: {
          startDateRange: { startDate: $startDate, endDate: $endDate }
          contractIds: [$contractId]
        }
      ) {
        id
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useGetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery__
 *
 * To run a query within a React component, call `useGetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery,
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery,
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryVariables
  >(GetEmployerTimesheetNextAndPreviousTimesheetsIdsDocument, options);
}
export function useGetEmployerTimesheetNextAndPreviousTimesheetsIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery,
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery,
    GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryVariables
  >(GetEmployerTimesheetNextAndPreviousTimesheetsIdsDocument, options);
}
export type GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery
>;
export type GetEmployerTimesheetNextAndPreviousTimesheetsIdsLazyQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetNextAndPreviousTimesheetsIdsLazyQuery
>;
export type GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryResult = Apollo.QueryResult<
  GetEmployerTimesheetNextAndPreviousTimesheetsIdsQuery,
  GetEmployerTimesheetNextAndPreviousTimesheetsIdsQueryVariables
>;
export const GetEmployerTimesheetSummariesDocument = gql`
  query GetEmployerTimesheetSummaries($filters: TimesheetSummaryFilter) {
    company {
      id
      timesheetSummaries(filters: $filters) {
        contract {
          id
          type
          country
          startOn
          endOn
          endedOn
          member {
            firstName
            lastName
          }
        }
        cutOffDate
        totalDaysToApprove
        totalSubmittedHoursToApprove
      }
    }
  }
`;

/**
 * __useGetEmployerTimesheetSummariesQuery__
 *
 * To run a query within a React component, call `useGetEmployerTimesheetSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerTimesheetSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerTimesheetSummariesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetEmployerTimesheetSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployerTimesheetSummariesQuery,
    GetEmployerTimesheetSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployerTimesheetSummariesQuery,
    GetEmployerTimesheetSummariesQueryVariables
  >(GetEmployerTimesheetSummariesDocument, options);
}
export function useGetEmployerTimesheetSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployerTimesheetSummariesQuery,
    GetEmployerTimesheetSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployerTimesheetSummariesQuery,
    GetEmployerTimesheetSummariesQueryVariables
  >(GetEmployerTimesheetSummariesDocument, options);
}
export type GetEmployerTimesheetSummariesQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetSummariesQuery
>;
export type GetEmployerTimesheetSummariesLazyQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetSummariesLazyQuery
>;
export type GetEmployerTimesheetSummariesQueryResult = Apollo.QueryResult<
  GetEmployerTimesheetSummariesQuery,
  GetEmployerTimesheetSummariesQueryVariables
>;
export const GetEmployerTimesheetDocument = gql`
  query GetEmployerTimesheet($id: ID, $contractId: ID!) {
    contract(id: $contractId, includeAnyStatus: true, isApprovalRelated: true) {
      id
      company {
        displayName
      }
      country
      countryStateCode
      startOn
      endOn
      endedOn
      member {
        firstName
        lastName
      }
      type
      compensation {
        basePay {
          paymentFrequency
          frequency
        }
      }
      timesheets(id: $id) {
        id
        startDate
        endDate
        totalDays
        totalHours
        status
        comment
        submittedOn
        approvedOn
        cutOffDate
        timeValueInputMethod
        totalSubmittedDays
        totalRejectedDays
        totalApprovedDays
        totalClockedInDays
        timesheetEntries {
          ...timesheetEntry
        }
        totalTimeSummary {
          totalTimeInMinutes
          totalLateTimeInMinutes
          totalTimeSummaryItems {
            dayType
            hoursType
            totalTimeInMinutes
          }
        }
      }
    }
  }
  ${TimesheetEntryFragmentDoc}
`;

/**
 * __useGetEmployerTimesheetQuery__
 *
 * To run a query within a React component, call `useGetEmployerTimesheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerTimesheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerTimesheetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetEmployerTimesheetQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployerTimesheetQuery,
    GetEmployerTimesheetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployerTimesheetQuery,
    GetEmployerTimesheetQueryVariables
  >(GetEmployerTimesheetDocument, options);
}
export function useGetEmployerTimesheetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployerTimesheetQuery,
    GetEmployerTimesheetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployerTimesheetQuery,
    GetEmployerTimesheetQueryVariables
  >(GetEmployerTimesheetDocument, options);
}
export type GetEmployerTimesheetQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetQuery
>;
export type GetEmployerTimesheetLazyQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetLazyQuery
>;
export type GetEmployerTimesheetQueryResult = Apollo.QueryResult<
  GetEmployerTimesheetQuery,
  GetEmployerTimesheetQueryVariables
>;
export const GetEmployerTimesheetsDocument = gql`
  query GetEmployerTimesheets($timesheetFilters: TimesheetFilters) {
    company {
      id
      timesheets(timesheetFilters: $timesheetFilters) {
        id
        contract {
          id
          type
          country
          startOn
          endOn
          endedOn
          member {
            firstName
            lastName
          }
        }
        cutOffDate
        startDate
        endDate
        totalDays
        totalHours
        status
        comment
        submittedOn
        approvedOn
        timesheetEntries {
          id
          date
          hours
          status
          regularTimeInMinutes
          overtimeTotalInMinutes
          overtimeAtNightInMinutes
          nightShiftTimeInMinutes
          lateTimeInMinutes
          addedByInfo {
            userId
            experience
          }
        }
        totalSubmittedDays
        totalRejectedDays
        totalApprovedDays
        totalClockedInDays
        totalTimeSummary {
          totalTimeInMinutes
          totalLateTimeInMinutes
          totalTimeSummaryItems {
            dayType
            hoursType
            totalTimeInMinutes
          }
        }
      }
    }
  }
`;

/**
 * __useGetEmployerTimesheetsQuery__
 *
 * To run a query within a React component, call `useGetEmployerTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerTimesheetsQuery({
 *   variables: {
 *      timesheetFilters: // value for 'timesheetFilters'
 *   },
 * });
 */
export function useGetEmployerTimesheetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployerTimesheetsQuery,
    GetEmployerTimesheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployerTimesheetsQuery,
    GetEmployerTimesheetsQueryVariables
  >(GetEmployerTimesheetsDocument, options);
}
export function useGetEmployerTimesheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployerTimesheetsQuery,
    GetEmployerTimesheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployerTimesheetsQuery,
    GetEmployerTimesheetsQueryVariables
  >(GetEmployerTimesheetsDocument, options);
}
export type GetEmployerTimesheetsQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetsQuery
>;
export type GetEmployerTimesheetsLazyQueryHookResult = ReturnType<
  typeof useGetEmployerTimesheetsLazyQuery
>;
export type GetEmployerTimesheetsQueryResult = Apollo.QueryResult<
  GetEmployerTimesheetsQuery,
  GetEmployerTimesheetsQueryVariables
>;
export const TimesheetEntriesRejectDocument = gql`
  mutation TimesheetEntriesReject(
    $timesheetEntryIds: [ID!]!
    $comment: String
  ) {
    timesheetEntriesReject(
      timesheetEntryIds: $timesheetEntryIds
      comment: $comment
    ) {
      ...timesheetEntry
    }
  }
  ${TimesheetEntryFragmentDoc}
`;
export type TimesheetEntriesRejectMutationFn = Apollo.MutationFunction<
  TimesheetEntriesRejectMutation,
  TimesheetEntriesRejectMutationVariables
>;

/**
 * __useTimesheetEntriesRejectMutation__
 *
 * To run a mutation, you first call `useTimesheetEntriesRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntriesRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetEntriesRejectMutation, { data, loading, error }] = useTimesheetEntriesRejectMutation({
 *   variables: {
 *      timesheetEntryIds: // value for 'timesheetEntryIds'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useTimesheetEntriesRejectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetEntriesRejectMutation,
    TimesheetEntriesRejectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetEntriesRejectMutation,
    TimesheetEntriesRejectMutationVariables
  >(TimesheetEntriesRejectDocument, options);
}
export type TimesheetEntriesRejectMutationHookResult = ReturnType<
  typeof useTimesheetEntriesRejectMutation
>;
export type TimesheetEntriesRejectMutationResult = Apollo.MutationResult<TimesheetEntriesRejectMutation>;
export type TimesheetEntriesRejectMutationOptions = Apollo.BaseMutationOptions<
  TimesheetEntriesRejectMutation,
  TimesheetEntriesRejectMutationVariables
>;
export const GetEmployeeTimesheetDocument = gql`
  query GetEmployeeTimesheet($id: ID) {
    member {
      id
      firstName
      lastName
      contracts {
        id
        country
        compensation {
          basePay {
            paymentFrequency
            frequency
          }
        }
        workShift {
          workingHours {
            startTime
            endTime
          }
        }
        timesheets(id: $id) {
          id
          startDate
          endDate
          totalDays
          totalHours
          status
          comment
          submittedOn
          approvedOn
          cutOffDate
          timeValueInputMethod
          timesheetEntries {
            ...timesheetEntry
          }
          totalTimeSummary {
            totalTimeInMinutes
            totalLateTimeInMinutes
            totalTimeSummaryItems {
              dayType
              hoursType
              totalTimeInMinutes
            }
          }
        }
      }
    }
  }
  ${TimesheetEntryFragmentDoc}
`;

/**
 * __useGetEmployeeTimesheetQuery__
 *
 * To run a query within a React component, call `useGetEmployeeTimesheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeTimesheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeTimesheetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeTimesheetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeeTimesheetQuery,
    GetEmployeeTimesheetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeTimesheetQuery,
    GetEmployeeTimesheetQueryVariables
  >(GetEmployeeTimesheetDocument, options);
}
export function useGetEmployeeTimesheetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeTimesheetQuery,
    GetEmployeeTimesheetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeTimesheetQuery,
    GetEmployeeTimesheetQueryVariables
  >(GetEmployeeTimesheetDocument, options);
}
export type GetEmployeeTimesheetQueryHookResult = ReturnType<
  typeof useGetEmployeeTimesheetQuery
>;
export type GetEmployeeTimesheetLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeTimesheetLazyQuery
>;
export type GetEmployeeTimesheetQueryResult = Apollo.QueryResult<
  GetEmployeeTimesheetQuery,
  GetEmployeeTimesheetQueryVariables
>;
export const GetEmployeeTimesheetsIdsDocument = gql`
  query GetEmployeeTimesheetsIds {
    member {
      id
      firstName
      lastName
      contracts {
        id
        timesheets {
          id
          startDate
          endDate
        }
      }
    }
  }
`;

/**
 * __useGetEmployeeTimesheetsIdsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeTimesheetsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeTimesheetsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeTimesheetsIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeTimesheetsIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeeTimesheetsIdsQuery,
    GetEmployeeTimesheetsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeTimesheetsIdsQuery,
    GetEmployeeTimesheetsIdsQueryVariables
  >(GetEmployeeTimesheetsIdsDocument, options);
}
export function useGetEmployeeTimesheetsIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeTimesheetsIdsQuery,
    GetEmployeeTimesheetsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeTimesheetsIdsQuery,
    GetEmployeeTimesheetsIdsQueryVariables
  >(GetEmployeeTimesheetsIdsDocument, options);
}
export type GetEmployeeTimesheetsIdsQueryHookResult = ReturnType<
  typeof useGetEmployeeTimesheetsIdsQuery
>;
export type GetEmployeeTimesheetsIdsLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeTimesheetsIdsLazyQuery
>;
export type GetEmployeeTimesheetsIdsQueryResult = Apollo.QueryResult<
  GetEmployeeTimesheetsIdsQuery,
  GetEmployeeTimesheetsIdsQueryVariables
>;
export const GetEmployeeTimesheetsDocument = gql`
  query GetEmployeeTimesheets {
    member {
      id
      firstName
      lastName
      contracts {
        id
        compensation {
          basePay {
            paymentFrequency
          }
        }
        workShift {
          workingHours {
            startTime
            endTime
          }
        }
        timesheets {
          id
          startDate
          endDate
          totalDays
          totalApprovedDays
          totalRejectedDays
          totalSubmittedDays
          totalClockedInDays
          totalHours
          status
          comment
          submittedOn
          approvedOn
          cutOffDate
          timesheetEntries {
            id
            date
            hours
            dayType
            status
            regularTimeInMinutes
            overtimeTotalInMinutes
            overtimeAtNightInMinutes
            nightShiftTimeInMinutes
            lateTimeInMinutes
          }
          totalTimeSummary {
            totalTimeInMinutes
            totalLateTimeInMinutes
            totalTimeSummaryItems {
              dayType
              hoursType
              totalTimeInMinutes
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetEmployeeTimesheetsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeTimesheetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeTimesheetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeeTimesheetsQuery,
    GetEmployeeTimesheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmployeeTimesheetsQuery,
    GetEmployeeTimesheetsQueryVariables
  >(GetEmployeeTimesheetsDocument, options);
}
export function useGetEmployeeTimesheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeTimesheetsQuery,
    GetEmployeeTimesheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmployeeTimesheetsQuery,
    GetEmployeeTimesheetsQueryVariables
  >(GetEmployeeTimesheetsDocument, options);
}
export type GetEmployeeTimesheetsQueryHookResult = ReturnType<
  typeof useGetEmployeeTimesheetsQuery
>;
export type GetEmployeeTimesheetsLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeTimesheetsLazyQuery
>;
export type GetEmployeeTimesheetsQueryResult = Apollo.QueryResult<
  GetEmployeeTimesheetsQuery,
  GetEmployeeTimesheetsQueryVariables
>;
export const GetMemberContractDetailsDocument = gql`
  query GetMemberContractDetails {
    member {
      contracts {
        id
        term
        type
        status
        startOn
        endOn
        endedOn
        country
        countryStateCode
        company {
          displayName
        }
        workShift {
          workingHours {
            startTime
            endTime
          }
        }
        compensation {
          basePay {
            frequency
            paymentFrequency
            paymentFrequencyDate {
              dateOfMonth
              identifier
            }
          }
          payType
        }
      }
    }
  }
`;

/**
 * __useGetMemberContractDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberContractDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberContractDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberContractDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberContractDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberContractDetailsQuery,
    GetMemberContractDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberContractDetailsQuery,
    GetMemberContractDetailsQueryVariables
  >(GetMemberContractDetailsDocument, options);
}
export function useGetMemberContractDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberContractDetailsQuery,
    GetMemberContractDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberContractDetailsQuery,
    GetMemberContractDetailsQueryVariables
  >(GetMemberContractDetailsDocument, options);
}
export type GetMemberContractDetailsQueryHookResult = ReturnType<
  typeof useGetMemberContractDetailsQuery
>;
export type GetMemberContractDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberContractDetailsLazyQuery
>;
export type GetMemberContractDetailsQueryResult = Apollo.QueryResult<
  GetMemberContractDetailsQuery,
  GetMemberContractDetailsQueryVariables
>;
export const GetCountryTimesheetConfigsDocument = gql`
  query GetCountryTimesheetConfigs($value: TimesheetConfigFilters) {
    timesheetConfigs(filters: $value) {
      countryConfigs {
        countryCode
        countryStateCode
        dayTypes
        hoursTypes
      }
    }
  }
`;

/**
 * __useGetCountryTimesheetConfigsQuery__
 *
 * To run a query within a React component, call `useGetCountryTimesheetConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryTimesheetConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryTimesheetConfigsQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetCountryTimesheetConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountryTimesheetConfigsQuery,
    GetCountryTimesheetConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryTimesheetConfigsQuery,
    GetCountryTimesheetConfigsQueryVariables
  >(GetCountryTimesheetConfigsDocument, options);
}
export function useGetCountryTimesheetConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryTimesheetConfigsQuery,
    GetCountryTimesheetConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryTimesheetConfigsQuery,
    GetCountryTimesheetConfigsQueryVariables
  >(GetCountryTimesheetConfigsDocument, options);
}
export type GetCountryTimesheetConfigsQueryHookResult = ReturnType<
  typeof useGetCountryTimesheetConfigsQuery
>;
export type GetCountryTimesheetConfigsLazyQueryHookResult = ReturnType<
  typeof useGetCountryTimesheetConfigsLazyQuery
>;
export type GetCountryTimesheetConfigsQueryResult = Apollo.QueryResult<
  GetCountryTimesheetConfigsQuery,
  GetCountryTimesheetConfigsQueryVariables
>;
export const TimesheetEntriesSubmitDocument = gql`
  mutation TimesheetEntriesSubmit($timesheetEntryIds: [ID!]!) {
    timesheetEntriesSubmit(timesheetEntryIds: $timesheetEntryIds) {
      ...timesheetEntry
    }
  }
  ${TimesheetEntryFragmentDoc}
`;
export type TimesheetEntriesSubmitMutationFn = Apollo.MutationFunction<
  TimesheetEntriesSubmitMutation,
  TimesheetEntriesSubmitMutationVariables
>;

/**
 * __useTimesheetEntriesSubmitMutation__
 *
 * To run a mutation, you first call `useTimesheetEntriesSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntriesSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetEntriesSubmitMutation, { data, loading, error }] = useTimesheetEntriesSubmitMutation({
 *   variables: {
 *      timesheetEntryIds: // value for 'timesheetEntryIds'
 *   },
 * });
 */
export function useTimesheetEntriesSubmitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetEntriesSubmitMutation,
    TimesheetEntriesSubmitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetEntriesSubmitMutation,
    TimesheetEntriesSubmitMutationVariables
  >(TimesheetEntriesSubmitDocument, options);
}
export type TimesheetEntriesSubmitMutationHookResult = ReturnType<
  typeof useTimesheetEntriesSubmitMutation
>;
export type TimesheetEntriesSubmitMutationResult = Apollo.MutationResult<TimesheetEntriesSubmitMutation>;
export type TimesheetEntriesSubmitMutationOptions = Apollo.BaseMutationOptions<
  TimesheetEntriesSubmitMutation,
  TimesheetEntriesSubmitMutationVariables
>;
export const TimesheetEntriesUpdateDocument = gql`
  mutation TimesheetEntriesUpdate($id: ID!, $input: [TimesheetEntryInput!]!) {
    timesheetEntriesCreateOrUpdate(timesheetId: $id, input: $input) {
      ...timesheetEntry
    }
  }
  ${TimesheetEntryFragmentDoc}
`;
export type TimesheetEntriesUpdateMutationFn = Apollo.MutationFunction<
  TimesheetEntriesUpdateMutation,
  TimesheetEntriesUpdateMutationVariables
>;

/**
 * __useTimesheetEntriesUpdateMutation__
 *
 * To run a mutation, you first call `useTimesheetEntriesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntriesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetEntriesUpdateMutation, { data, loading, error }] = useTimesheetEntriesUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimesheetEntriesUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetEntriesUpdateMutation,
    TimesheetEntriesUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetEntriesUpdateMutation,
    TimesheetEntriesUpdateMutationVariables
  >(TimesheetEntriesUpdateDocument, options);
}
export type TimesheetEntriesUpdateMutationHookResult = ReturnType<
  typeof useTimesheetEntriesUpdateMutation
>;
export type TimesheetEntriesUpdateMutationResult = Apollo.MutationResult<TimesheetEntriesUpdateMutation>;
export type TimesheetEntriesUpdateMutationOptions = Apollo.BaseMutationOptions<
  TimesheetEntriesUpdateMutation,
  TimesheetEntriesUpdateMutationVariables
>;
export const TimesheetCreateOrUpdateDocument = gql`
  mutation TimesheetCreateOrUpdate(
    $startDate: Date!
    $endDate: Date!
    $contractId: ID
  ) {
    timesheetCreateOrUpdate(
      input: {
        startDate: $startDate
        endDate: $endDate
        contractId: $contractId
      }
    ) {
      id
    }
  }
`;
export type TimesheetCreateOrUpdateMutationFn = Apollo.MutationFunction<
  TimesheetCreateOrUpdateMutation,
  TimesheetCreateOrUpdateMutationVariables
>;

/**
 * __useTimesheetCreateOrUpdateMutation__
 *
 * To run a mutation, you first call `useTimesheetCreateOrUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetCreateOrUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetCreateOrUpdateMutation, { data, loading, error }] = useTimesheetCreateOrUpdateMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useTimesheetCreateOrUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetCreateOrUpdateMutation,
    TimesheetCreateOrUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetCreateOrUpdateMutation,
    TimesheetCreateOrUpdateMutationVariables
  >(TimesheetCreateOrUpdateDocument, options);
}
export type TimesheetCreateOrUpdateMutationHookResult = ReturnType<
  typeof useTimesheetCreateOrUpdateMutation
>;
export type TimesheetCreateOrUpdateMutationResult = Apollo.MutationResult<TimesheetCreateOrUpdateMutation>;
export type TimesheetCreateOrUpdateMutationOptions = Apollo.BaseMutationOptions<
  TimesheetCreateOrUpdateMutation,
  TimesheetCreateOrUpdateMutationVariables
>;
export const TimesheetEntriesDeleteDocument = gql`
  mutation TimesheetEntriesDelete($timesheetEntryIds: [ID!]!) {
    timesheetEntriesDelete(timesheetEntryIds: $timesheetEntryIds) {
      ...timesheetEntry
    }
  }
  ${TimesheetEntryFragmentDoc}
`;
export type TimesheetEntriesDeleteMutationFn = Apollo.MutationFunction<
  TimesheetEntriesDeleteMutation,
  TimesheetEntriesDeleteMutationVariables
>;

/**
 * __useTimesheetEntriesDeleteMutation__
 *
 * To run a mutation, you first call `useTimesheetEntriesDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntriesDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetEntriesDeleteMutation, { data, loading, error }] = useTimesheetEntriesDeleteMutation({
 *   variables: {
 *      timesheetEntryIds: // value for 'timesheetEntryIds'
 *   },
 * });
 */
export function useTimesheetEntriesDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetEntriesDeleteMutation,
    TimesheetEntriesDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetEntriesDeleteMutation,
    TimesheetEntriesDeleteMutationVariables
  >(TimesheetEntriesDeleteDocument, options);
}
export type TimesheetEntriesDeleteMutationHookResult = ReturnType<
  typeof useTimesheetEntriesDeleteMutation
>;
export type TimesheetEntriesDeleteMutationResult = Apollo.MutationResult<TimesheetEntriesDeleteMutation>;
export type TimesheetEntriesDeleteMutationOptions = Apollo.BaseMutationOptions<
  TimesheetEntriesDeleteMutation,
  TimesheetEntriesDeleteMutationVariables
>;
export const TimesheetEntriesRevokeDocument = gql`
  mutation TimesheetEntriesRevoke($timesheetEntryIds: [ID!]!) {
    timesheetEntriesRevoke(timesheetEntryIds: $timesheetEntryIds) {
      ...timesheetEntry
    }
  }
  ${TimesheetEntryFragmentDoc}
`;
export type TimesheetEntriesRevokeMutationFn = Apollo.MutationFunction<
  TimesheetEntriesRevokeMutation,
  TimesheetEntriesRevokeMutationVariables
>;

/**
 * __useTimesheetEntriesRevokeMutation__
 *
 * To run a mutation, you first call `useTimesheetEntriesRevokeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntriesRevokeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetEntriesRevokeMutation, { data, loading, error }] = useTimesheetEntriesRevokeMutation({
 *   variables: {
 *      timesheetEntryIds: // value for 'timesheetEntryIds'
 *   },
 * });
 */
export function useTimesheetEntriesRevokeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetEntriesRevokeMutation,
    TimesheetEntriesRevokeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetEntriesRevokeMutation,
    TimesheetEntriesRevokeMutationVariables
  >(TimesheetEntriesRevokeDocument, options);
}
export type TimesheetEntriesRevokeMutationHookResult = ReturnType<
  typeof useTimesheetEntriesRevokeMutation
>;
export type TimesheetEntriesRevokeMutationResult = Apollo.MutationResult<TimesheetEntriesRevokeMutation>;
export type TimesheetEntriesRevokeMutationOptions = Apollo.BaseMutationOptions<
  TimesheetEntriesRevokeMutation,
  TimesheetEntriesRevokeMutationVariables
>;
export const TimesheetStatusUpdateDocument = gql`
  mutation TimesheetStatusUpdate(
    $id: ID!
    $status: TimesheetStatus!
    $comment: String
  ) {
    timesheetChangeStatus(
      timesheetId: $id
      status: $status
      comment: $comment
    ) {
      id
      startDate
      endDate
      totalDays
      totalHours
      status
      comment
      submittedOn
      approvedOn
      timesheetEntries {
        id
        date
        hours
      }
      contract {
        id
        company {
          id
        }
      }
    }
  }
`;
export type TimesheetStatusUpdateMutationFn = Apollo.MutationFunction<
  TimesheetStatusUpdateMutation,
  TimesheetStatusUpdateMutationVariables
>;

/**
 * __useTimesheetStatusUpdateMutation__
 *
 * To run a mutation, you first call `useTimesheetStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimesheetStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timesheetStatusUpdateMutation, { data, loading, error }] = useTimesheetStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useTimesheetStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimesheetStatusUpdateMutation,
    TimesheetStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimesheetStatusUpdateMutation,
    TimesheetStatusUpdateMutationVariables
  >(TimesheetStatusUpdateDocument, options);
}
export type TimesheetStatusUpdateMutationHookResult = ReturnType<
  typeof useTimesheetStatusUpdateMutation
>;
export type TimesheetStatusUpdateMutationResult = Apollo.MutationResult<TimesheetStatusUpdateMutation>;
export type TimesheetStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  TimesheetStatusUpdateMutation,
  TimesheetStatusUpdateMutationVariables
>;
