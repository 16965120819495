import { Experience } from 'app/models/module-config';

import {
  ContractOnboardingStatus,
  ContractStatus,
  ContractType,
} from '__generated__/graphql';

export enum OnboardingConfigStatus {
  REVIEW_INVITE = 'REVIEW_INVITE',
  INVITED = 'INVITED',
  MEMBER_ONBOARDING = 'MEMBER_ONBOARDING',
  VERIFICATION = 'VERIFICATION',
  ACTIVE = 'ACTIVE',
  EMPLOYMENT_STARTED = 'EMPLOYMENT_STARTED',
}

export const statusToColumnMapping: {
  [key in ContractOnboardingStatus | ContractStatus]?: OnboardingConfigStatus;
} = {
  [ContractOnboardingStatus.DRAFT]: OnboardingConfigStatus.REVIEW_INVITE,
  [ContractOnboardingStatus.CREATED]: OnboardingConfigStatus.REVIEW_INVITE,
  // TODO: Need to revamp this whole thing to update against the new self-serve workflows
  // This shouldn't be in the FE anyway, so just adding CONTRACT_PREPARING here.
  [ContractOnboardingStatus.CONTRACT_PREPARING]:
    OnboardingConfigStatus.REVIEW_INVITE,
  [ContractOnboardingStatus.CONTRACT_PREPARING_CONFIRMATION]:
    OnboardingConfigStatus.REVIEW_INVITE,
  [ContractOnboardingStatus.CREATED_CUSTOM]:
    OnboardingConfigStatus.REVIEW_INVITE,
  [ContractOnboardingStatus.REVOKED]: OnboardingConfigStatus.REVIEW_INVITE,
  [ContractOnboardingStatus.INVITED]: OnboardingConfigStatus.INVITED,
  [ContractOnboardingStatus.MEMBER_STARTED]:
    OnboardingConfigStatus.MEMBER_ONBOARDING,
  [ContractOnboardingStatus.MEMBER_DATA_ADDED]:
    OnboardingConfigStatus.MEMBER_ONBOARDING,
  [ContractOnboardingStatus.MEMBER_COMPLETED]:
    OnboardingConfigStatus.MEMBER_ONBOARDING,
  [ContractOnboardingStatus.MEMBER_COMPLETED]:
    OnboardingConfigStatus.MEMBER_ONBOARDING,
  [ContractOnboardingStatus.VERIFICATION_IN_PROGRESS]:
    OnboardingConfigStatus.VERIFICATION,
  [ContractOnboardingStatus.VERIFICATION_DONE]:
    OnboardingConfigStatus.VERIFICATION,
  [ContractOnboardingStatus.ACTIVE]: OnboardingConfigStatus.ACTIVE,
};

export type OnboardingDataConfigType = {
  [key: string]: {
    [experience in Experience]?: {
      visibility: {
        contractType: string[] | ContractType[];
        contractStatus: string[] | ContractStatus[];
      };
      editability: {
        [column in OnboardingConfigStatus]?: boolean;
      };
    };
  };
};

// This configuration is based on https://docs.google.com/spreadsheets/d/1wca_UMFmxVm7p2xZq8hApx-tkCGxszsVHlGLTdNbIns/edit#gid=0

const onboardingDataConfig: OnboardingDataConfigType = {
  // Member Basic Data
  name: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  fullLegalName: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  gender: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  nationality: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  dateOfBirth: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Contact Data
  email: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
  },
  phone: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
  },
  address: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
  },
  // Employment Data
  country: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  workStatus: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  jobTitle: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  term: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  startOn: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: true,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  endOn: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  scope: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          // any
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          // any
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  existingEmployee: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  leaveEntitlement: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Compensation Data
  basePay: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  basePayPayRate: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  basePayPayType: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  ratePerFrequency: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  annualBonus: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  additionalRequest: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  payrollStartDate: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Compliance Data
  countrySpecificComplianceData: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [ContractStatus.ACTIVE],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  defaultComplianceData: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [ContractStatus.ACTIVE],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Insurance
  insurance: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Contract
  contractDocument: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: true,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [ContractStatus.ACTIVE],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  ownVsMultiplier: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [ContractType.FREELANCER],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  uploadedContract: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [
          ContractStatus.ONBOARDING,
          ContractStatus.ACTIVE,
          ContractStatus.OFFBOARDING,
          ContractStatus.ENDED,
          ContractStatus.DELETED,
        ],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: true,
        [OnboardingConfigStatus.INVITED]: true,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Member Legal Data
  defaulLegaltData: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [ContractStatus.ACTIVE],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  countrySpecificLegalData: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [ContractStatus.ACTIVE],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Member Legal Documents
  memberLegalDocuments: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [ContractStatus.ACTIVE],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
  },
  // Member Bank Details
  memberBankDetails: {
    [Experience.COMPANY]: {
      visibility: {
        contractType: [],
        contractStatus: [],
      },
      editability: {
        [OnboardingConfigStatus.REVIEW_INVITE]: false,
        [OnboardingConfigStatus.INVITED]: false,
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: false,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: false,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: false,
      },
    },
    [Experience.MEMBER]: {
      visibility: {
        contractType: [
          ContractType.EMPLOYEE,
          ContractType.FREELANCER,
          ContractType.HR_MEMBER,
        ],
        contractStatus: [ContractStatus.ACTIVE],
      },
      editability: {
        [OnboardingConfigStatus.MEMBER_ONBOARDING]: true,
        [OnboardingConfigStatus.VERIFICATION]: false,
        [OnboardingConfigStatus.ACTIVE]: true,
        [OnboardingConfigStatus.EMPLOYMENT_STARTED]: true,
      },
    },
  },
};

export default onboardingDataConfig;
