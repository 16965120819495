/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';

import { Icon, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { TableDataObjectType } from '../../index';
import { useFakePagination } from './hooks';

const MobileTable: React.FC<{
  data: TableDataObjectType[] | [];
  renderItem: (i: TableDataObjectType) => React.ReactElement;
}> = ({ data, renderItem }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { loading, items, hasNextPage, error, loadMore } = useFakePagination(
    data,
  );

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div>
      <ul
        css={[
          tw`divide-background-secondary bg-background-white mt-large`,
          !isNewThemeApplied && tw`divide-grey04 bg-white`,
        ]}
      >
        {items.map((member) => renderItem(member))}
      </ul>
      {(loading || hasNextPage) && (
        <div tw="flex justify-center mt-tiny" ref={sentryRef}>
          <div
            css={[
              tw`p-small`,
              tw`bg-background-primary rounded-full animate-spin`,
            ]}
          >
            <Icon name="circular-loader" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTable;
