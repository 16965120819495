import { theme } from 'twin.macro';

import {
  Experience,
  ModuleConfig,
  NavGroups,
  WidgetPosition,
  WidgetVariant,
} from 'app/models/module-config';
import { shouldShowModuleForHrisManagers } from 'app/utils/module-checks';

import TeamSidebarEntry from './sidebar-entry';

const config: ModuleConfig = {
  id: 'team',
  permission: 'team',
  folder: 'team/company',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Team',
    route: 'team',
    icon: {
      name: 'user-group',
      fill: theme`colors.black`,
    },
    activeIcon: {
      name: 'user-group-filled',
      fill: theme`colors.icon-primary`,
    },
    sort: 2,
    home: false,
    group: NavGroups.MAIN,
    custom: TeamSidebarEntry,
  },
  widget: {
    sort: 4,
    list: [
      {
        show: true,
        file: 'team-widget',
        position: WidgetPosition.RIGHT,
        variant: WidgetVariant.HALF_WIDTH,
      },
    ],
  },
  enabled: async () => shouldShowModuleForHrisManagers(),
};

export default config;
