import React from 'react';

import { Icon, ToolTip } from '@multiplier/common';
import tw from 'twin.macro';

export const FormInputContainer = tw.div`flex flex-col gap-y-small`;

export const FormInputLabel: React.FC<
  React.PropsWithChildren<{
    tooltip?: string | React.ReactElement | null;
  }>
> = ({ tooltip, children }) => (
  <div tw="text-text-primary text-ps font-medium">
    <div tw="flex flex-row items-center gap-x-extra-small">
      <span>{children}</span>
      <ToolTip content={tooltip}>{tooltip && <Icon name="info" />}</ToolTip>
    </div>
  </div>
);

export const FormContainer = tw.form`p-extra-large flex flex-col justify-between gap-y-40 overflow-y-auto max-h-[calc(100vh - 70px)]`;
