/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';

import { notEmpty } from 'app/utils/array';

import { Member, useGetSignatoriesQuery } from '__generated__/graphql';

export const SendContractDisclaimer: React.FC<{
  employeeName: Member['firstName'];
  isSignatoryPresent: boolean;
}> = ({ employeeName, isSignatoryPresent = false }) => {
  const { t } = useTranslation('contract-onboarding.company');

  return (
    <div tw="flex flex-col gap-y-16 mx-64">
      <div tw="rounded-8 bg-primary bg-opacity-5 text-ps text-background leading-[26px]">
        <div tw="flex flex-row items-start gap-x-24 py-16 px-24">
          <Icon tw="flex-shrink-0" name="error" />
          <div tw="font-medium">
            {isSignatoryPresent ? (
              <Trans
                t={t}
                i18nKey="onboarding-phase.contract-viewer.send-contract-disclaimer.info-signatory-present"
              >
                <p data-testid="disclaimer-for-company-with-signatory">
                  Please review and send the contract, once you confirm all the
                  details. Multiplier will send the Employment contract to your
                  Signatory and once signed by the Signatory, it will be sent to{' '}
                  {{ employeeName }}. For clarifications reach out to us
                  at&nbsp;
                  <span tw="text-primary font-semibold">
                    support@usemultiplier.com
                  </span>
                </p>
              </Trans>
            ) : (
              <Trans
                t={t}
                i18nKey="onboarding-phase.contract-viewer.send-contract-disclaimer.info"
              >
                <p data-testid="disclaimer-for-company-without-signatory">
                  Please review the contract and add Signatory if not done
                  already. As a default signature, we add Admin&apos;s signature
                  on the contract which changes automatically once the Signatory
                  gets added. For any clarifications reach out to us at&nbsp;
                  <span tw="text-primary font-semibold">
                    support@usemultiplier.com
                  </span>
                </p>
              </Trans>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SendContractDisclaimerWrapper: React.FC<{
  employeeName: Member['firstName'];
}> = ({ employeeName }) => {
  const { data } = useGetSignatoriesQuery({ fetchPolicy: 'cache-and-network' });
  const isSignatoryPresent = useMemo(
    () => (data?.company?.signatories?.filter(notEmpty) ?? [])?.length > 0,
    [data],
  );
  return (
    <SendContractDisclaimer
      isSignatoryPresent={isSignatoryPresent}
      employeeName={employeeName}
    />
  );
};

export default SendContractDisclaimerWrapper;
