import { gql } from '@apollo/client';

import WORK_SHIFT_FRAGMENT from 'contract-onboarding/company/queries/fragments/work-shift';
import PaymentAccountRequirementsFragment from 'team/member/personal-details/queries/fragments/payment-account-requirement';

export default gql`
  ${WORK_SHIFT_FRAGMENT}
  ${PaymentAccountRequirementsFragment}
  query GetContract($id: ID!, $skipDepositPayable: Boolean = false) {
    contract(id: $id, includeAnyStatus: true) {
      id
      term
      type
      currency
      company {
        id
        displayName
      }
      country
      countryStateCode
      legalEntityId
      employeeId
      workStatus
      workShift {
        ...workShift
      }
      status
      workEmail
      member {
        id
        firstName
        lastName
        fullLegalName
        status
        emails {
          type
          email
        }
        addresses {
          key
          street
          line1
          line2
          city
          state
          province
          country
          zipcode
          postalCode
        }
        phoneNos {
          type
          phoneNo
        }
        gender
        maritalStatus
        dateOfBirth
        age
        nationalities {
          type
          country
        }
        basicInfo {
          firstName
          fullLegalName
          lastName
          middleName
        }
        legalData {
          key
          value
          label
          country
        }
        bankAccounts {
          id
          version
          accountName
          accountNumber
          bankName
          branchName
          currency
          country
          swiftCode
          localBankCode
          paymentAccountRequirementType
          accountDetails {
            key
            value
          }
          bankStatements {
            id
            name
            extension
            link
            contentType
            size
          }
        }
        legalDocuments {
          ...legalDocument
        }
      }
      position
      onboarding {
        status
        statuses
        steps
        current
        contractDocumentWorkflow {
          currentStatus
        }
        activationCutoff
      }
      alreadyHired
      startOn
      createdOn
      endOn
      endedOn
      offboardingNote
      scope
      compensation {
        basePay {
          ...fixedPayComponent
        }
        postProbationBasePay {
          ...fixedPayComponent
        }
        probationBasePay {
          ...fixedPayComponent
        }
        additionalPays {
          ...compensationPayComponent
        }
        additional
        payrollStart
        grant(contractId: $id) {
          ...grantComponent
        }
        payType
        deductions {
          deductionDefinitionId
          value
          unit
        }
      }
      benefits {
        id
        status
        startOn
        endOn
        dependentCount
        employeePayPercentage
        employerPayPercentage
        externalPolicyId
        billAmount
        benefitPackageCost {
          id
          premium
          premiumCurrency
          premiumFrequency
        }
        dependents {
          firstName
          lastName
          gender
          dateOfBirth
          relationshipToMember
          externalPolicyId
          startOn
          endOn
          benefitPackageCost {
            id
            premium
            premiumCurrency
            premiumFrequency
          }
        }
        benefit {
          ...companyBenefit
        }
        params {
          key
          value
        }
      }
      compliance {
        ...compliance
      }
      payslips {
        id
        month {
          month
          year
        }
        payslipDocument {
          downloadUrl
          name
        }
        createdOn
      }
      categoryApproverInfos {
        category
        approverUsers {
          ...companyUser
        }
      }
      timeOff {
        summary {
          key
          definition {
            type
            label
          }
          entitled
          taken
          pending
          remaining
          periodEnd
        }
      }
      contractStarted
      depositPayable @skip(if: $skipDepositPayable) {
        id
        invoice {
          id
          status
          dueDate
          externalSystem
        }
      }
      contractBlocked
      unresolvedBlockEvents {
        id
        source
        status
        blockReason
        blockData {
          ...changeRequestBlockData
        }
      }
      offboarding {
        id
        contractId
        status
        type
        terminationReason
        lastWorkingDay
      }
      paymentAccountRequirements {
        ...paymentAccountRequirementFragment
      }
      reportsToManager {
        id
        contract {
          id
          position
          member {
            firstName
            lastName
          }
        }
      }
      orgAttributes {
        costCenter {
          id
          description
          name
        }
        department {
          id
          description
          name
        }
      }
    }
  }

  fragment compliance on Compliance {
    type
    contractAgreement {
      id
      name
    }
    contractAgreementType
    complianceParams {
      key
      label
      ... on ComplianceParamPeriodLimit {
        description
        value
        unit
      }
    }
    timeOffEntitlement {
      timeOffType {
        type
        typeId
      }
      definition {
        type
        required
        label
        description
        validation {
          ... on TimeOffFixedValidation {
            minimum
            maximum
            defaultValue
            unit
          }
        }
        assignedEntityNames
      }
      value
      unit
    }
    ... on ComplianceMultiplierEOR {
      offerLetter {
        id
        name
      }
      preferredContractAgreementTemplate {
        documentId
        template {
          id
          displayName
          externalId
        }
      }
    }
    ... on CompliancePartnerEOR {
      offerLetter {
        id
        name
      }
    }
    ... on ComplianceContractor {
      orderForm {
        id
        name
        extension
        contentType
        blob
        htmlPreview
      }
    }
    __typename
  }

  fragment compensationPayComponent on CompensationPayComponent {
    name
    label
    amount
    amountType
    currency
    frequency
    payOn {
      year
      month
    }
    paySchedule {
      value
      unit
    }
    ... on FixedPayComponent {
      rateType
      instalments {
        amount
        payOn {
          year
          month
        }
        currency
      }
    }
    isDeletable
    condition
  }

  fragment fixedPayComponent on FixedPayComponent {
    name
    label
    amount
    currency
    frequency
    rateType
    paymentFrequency
    paymentFrequencyDate {
      identifier
      dateOfMonth
      dayOfWeek
    }
    validFromInclusive
    validToExclusive
  }

  fragment grantComponent on Grant {
    id
    values {
      value
      type
      currency
    }
    condition
    schedule {
      cliffPeriod {
        value
        unit
      }
      vestingPeriod {
        value
        unit
      }
      vestingSchedule {
        time {
          value
          unit
        }
        vestingValue {
          value
          type
        }
        frequency
      }
    }
    grantDate
  }

  fragment legalDocument on LegalDocument {
    id
    key
    files {
      id
      name
      extension
      contentType
      downloadUrl
      size
    }
    label
    financialYear
    status
    comment
    category
    createdOn
    updatedOn
  }

  fragment companyBenefit on Benefit {
    id
    type
    packageName
    description
    startDate
    endDate
    currency
    cost
    costingType
    frequency
    packageTier {
      tier
    }
    memberOnboardingKit {
      memberOnboardingKitFile {
        files {
          id
          downloadUrl
        }
      }
    }
    benefitDocuments {
      benefitDocumentType
      files {
        id
        name
        downloadUrl
      }
    }
    provider {
      id
      brokerData {
        name
      }
      coverageGroup {
        id
        name
        group {
          maxCount
          relation {
            familyMember
            id
          }
        }
      }
      emergencyPointOfContact {
        firstName
        lastName
        emails {
          type
          email
        }
        phoneNos {
          type
          phoneNo
        }
      }
      metaData {
        pocName
        emails {
          type
          email
        }
        phoneNos {
          phoneNo
          type
        }
      }
      partnerName
      partner {
        id
        name
      }
    }
    features {
      key
      label
      value
    }
  }

  fragment companyUser on CompanyUser {
    id
    userId
    firstName
    lastName
    emails {
      email
    }
    title
    role
    roles
    isSignatory
    isBillingContact
    status
  }

  fragment changeRequestBlockData on ChangeRequestBlockData {
    changeRequestId
    __typename
  }
`;
