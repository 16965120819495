import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  LegalDocumentInput,
  useGetMemberIdQuery,
  useUpdateVisaDocumentsMutation,
} from '__generated__/graphql';

import { DocumentFormParams } from '../pages/onboarding/pages/visa-eligibility-documents';
import stepConfig from '../pages/onboarding/step-config';

const useUpdateVisaDocuments = (): {
  onSubmit: (documents: DocumentFormParams) => void;
  loading: boolean;
} => {
  const { data: memberResponse } = useGetMemberIdQuery();
  const { t } = useTranslation('contract-onboarding.member');
  const navigate = useNavigate();
  const [
    updateVisaDocumentsMutation,
    { loading },
  ] = useUpdateVisaDocumentsMutation({
    onCompleted() {
      successNotification(
        '',
        t('legal-document.updated-success-message', 'Documents updated'),
        false,
      );
    },
    onError() {
      errorNotification(
        '',
        t('legal-document.update-error-message', 'Failed to update documents'),
        false,
      );
    },
  });

  const onSubmit = useCallback(
    async (documents: DocumentFormParams) => {
      const documentMap = documents.legalDocuments.map((i) => ({
        key: i.key,
        documents: i.acceptMultiple ? i.value : [i.value],
        category: i.category,
      }));

      const member = await updateVisaDocumentsMutation({
        variables: {
          id: String(memberResponse?.member?.id),
          data: documentMap as LegalDocumentInput | LegalDocumentInput[],
        },
      });

      const current =
        member?.data?.memberUpdateVisaDocument?.contracts?.[0]?.onboarding
          ?.current;

      if (current && stepConfig[current]) {
        navigate(stepConfig[current].directLink());
      }
    },
    [updateVisaDocumentsMutation],
  );

  return {
    onSubmit,
    loading,
  };
};

export default useUpdateVisaDocuments;
