/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';

import { useReactiveVar } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { ReactComponent as ErrorIcon } from '@multiplier/assets/files/common/error.svg';
import {
  Button,
  Pictogram,
  SystemDowntimeWarning,
  ThemeContext,
} from '@multiplier/common';
import { userVar } from '@multiplier/user';

import AppFeature from 'app/features';

export enum ErrorVariant {
  UPDATED = 'UPDATED',
  DEFAULT = 'DEFAULT',
  NOT_FOUND = 'NOT_FOUND',
}

const Error: React.FC<{ variant?: ErrorVariant }> = ({
  variant = ErrorVariant.DEFAULT,
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const { isNewThemeApplied } = useContext(ThemeContext);

  const isSystemDown = useFeature(AppFeature.SYSTEM_DOWNTIME_WARNING).value;

  const errorLabels = {
    [ErrorVariant.UPDATED]: {
      header: t('error-page.updated.header', 'Multiplier has updated'),
      description: t(
        'error-page.updated.description',
        'Multiplier has updated in the background to improve your experience.',
      ),
    },
    [ErrorVariant.DEFAULT]: {
      header: t('error-page.default.header', 'Something went wrong'),
      description: t(
        'error-page.default.description',
        'Something went wrong went trying to display the page you were trying to reach.',
      ),
    },
    [ErrorVariant.NOT_FOUND]: {
      header: t('error-page.not-found.header', 'Page doesn’t exist'),
      description: t(
        'error-page.not-found.description',
        'This link may have been deleted by your administrator. Please contact your administrator if you have any questions.',
      ),
    },
  };

  return (
    <div>
      {isSystemDown && (
        <SystemDowntimeWarning>{isSystemDown}</SystemDowntimeWarning>
      )}
      <div
        tw="h-screen flex flex-row items-center justify-center bg-background-primary"
        data-testid="application-updated"
      >
        <div tw="flex flex-col items-center gap-y-large">
          {isNewThemeApplied ? (
            <Pictogram name="tackle-employment-risks" size="large" />
          ) : (
            <ErrorIcon />
          )}
          <div tw="flex flex-col items-center gap-y-base text-text-primary">
            <div tw="text-h3 font-semibold">{errorLabels[variant].header}</div>
            <div tw="text-ps text-text-tertiary max-w-[333px] text-center">
              {errorLabels[variant].description}
            </div>
            {variant === ErrorVariant.UPDATED && (
              <Button
                size="medium"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t('error-page.updated.cta', 'Click here to reload')}
              </Button>
            )}
            {variant === ErrorVariant.NOT_FOUND && (
              <Button
                size="medium"
                onClick={() => {
                  navigate(`/${current}`); // go to experience root
                }}
              >
                {t('error-page.not-found.cta', 'Return to Dashboard')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
