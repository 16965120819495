/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconButton,
  ThemeContext,
  downloadBlobAsPdf,
} from '@multiplier/common';
import tw from 'twin.macro';

import { useModal } from 'app/hooks';
import { errorNotification } from 'app/services/notification-services';

import {
  Contract,
  ContractType,
  DocumentReadable,
  useGetContractAgreementLazyQuery,
} from '__generated__/graphql';

import ContractModal, { AorDocumentType } from '../contract-modal';

const ContractViewer: React.FC<{
  contractId: Contract['id'];
  filename: DocumentReadable['name'];
  status?: Contract['status'];
  type?: Contract['type'];
}> = ({ contractId, filename, type }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const [
    showContractPreview,
    handleCloseContractPreview,
    handleOpenContractPreview,
  ] = useModal();

  const [
    getContractAgreement,
    { data, loading },
  ] = useGetContractAgreementLazyQuery({
    fetchPolicy: 'no-cache',
    onError: () => {
      errorNotification(
        '',
        t(
          'contract-viewer.fetching-error',
          'Failed to fetch contract agreement',
        ),
        false,
      );
    },
  });

  const handleView = () => {
    if (contractId) {
      getContractAgreement({ variables: { id: contractId } });
      handleOpenContractPreview();
    }
  };

  const handleDownload = async () => {
    if (contractId) {
      const response = await getContractAgreement({
        variables: { id: contractId },
      });
      const agreement = response?.data?.contract?.compliance?.contractAgreement;
      if (agreement && agreement.blob) {
        downloadBlobAsPdf(agreement.blob, agreement.name ?? 'Contract.pdf');
      }
    }
  };

  return (
    <>
      <div
        tw="w-full rounded p-large flex items-center gap-small"
        css={[
          tw`bg-background-primary`,
          !isNewThemeApplied && tw`bg-slateGrey50`,
        ]}
      >
        <Icon css={tw`h-48 w-48`} name="pdf-file" />
        <div tw="flex flex-col gap-tiny">
          <p
            tw="text-p font-semibold text-background"
            css={[
              tw`text-text-primary`,
              !isNewThemeApplied && tw`text-background`,
            ]}
          >
            {type === ContractType.CONTRACTOR
              ? t('contract-viewer.agreement', 'Agreement')
              : t('contract-viewer.contract', 'Contract')}
          </p>
          <p
            tw="text-pxs"
            css={[
              tw`text-text-secondary`,
              !isNewThemeApplied && tw`text-grey01`,
            ]}
          >
            {filename}
          </p>
        </div>
        <div tw="ml-auto flex items-center gap-small">
          <IconButton
            data-testid="view-btn"
            name="eye-outline"
            size="small"
            variant="filled"
            loading={loading}
            onClick={handleView}
          />
          <IconButton
            loading={loading}
            data-testid="download-btn"
            name="download"
            size="small"
            variant="filled"
            onClick={handleDownload}
          />
        </div>
      </div>
      {showContractPreview && (
        <ContractModal
          open={showContractPreview}
          onClose={handleCloseContractPreview}
          title={
            type === ContractType.CONTRACTOR
              ? t('contract-viewer.agreement-header', 'Agreement')
              : t('contract-viewer.contract-header', 'Contract')
          }
          contractType={type}
          contractAgreement={data?.contract?.compliance?.contractAgreement}
          esopAgreement={data?.contract?.compensation?.grant?.[0]?.document}
          loading={loading}
          aorDocumentType={AorDocumentType.AGREEMENT}
        />
      )}
    </>
  );
};

export default ContractViewer;
