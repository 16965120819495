import React from 'react';

import * as FullStory from '@fullstory/browser';
import { createRoot } from 'react-dom/client';
import { GlobalStyles } from 'twin.macro';
import 'regenerator-runtime/runtime';
import './index.css';

import importMetaEnv from 'import-meta-env';

import App from './app';
import './i18n';
import './apm';
import reportWebVitals from './report-web-vitals';
import initializeSentry from './sentry';

FullStory.init({
  orgId: importMetaEnv.VITE_CUSTOMER_FULLSTORY_ORG_ID as string,
  devMode: importMetaEnv.VITE_ENV !== 'prod',
  namespace: 'customer',
});

initializeSentry();

// FIXME - Workaround for delaying application load until msw is loaded
// https://github.com/mswjs/msw/issues/73

const root = createRoot(document.getElementById('root')!);

const bootstrap = () => {
  root.render(
    <React.StrictMode>
      <GlobalStyles />
      <App />
    </React.StrictMode>,
  );
};

if (importMetaEnv.VITE_GRAPHQL_MOCK === 'true') {
  import('./mocks/browser').then(({ worker }) => {
    worker
      .start({
        onUnhandledRequest: 'bypass',
      })
      .then(() => {
        bootstrap();
      });
  });
} else {
  bootstrap();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
