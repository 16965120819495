/**
 * TODO: Rebranding -> This component looks similar to `DocumentViewer` from common package with some additional codes.
 * See if we can use the common component instead of this.
 */
/** @jsxImportSource @emotion/react */
import React, { useContext, useRef } from 'react';

import ReactDOM from 'react-dom';
import { useKey } from 'react-use';

import {
  BackButton,
  CardSeparator,
  Icon,
  SpinnerType,
  ThemeContext,
} from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import Loader from 'common/components/loader';
import { ContractPageType } from 'contract-onboarding/components/progress-widget-content/view-send-contract-action';

import ConfirmationDialog from '../confirmation-dialog';

interface ContractPreviewProps {
  open: boolean;
  onClose: () => void;
  header: string;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
  primaryButtonText?: string;
  primaryButtonClick?: () => void;
  loading: boolean;
  tabs?: { key: string; name: string }[];
  selectedTab?: string;
  setSelectedTab?: (key: string) => void;
  contractModalPageType?: ContractPageType;
  setContractModalPageType?: React.Dispatch<
    React.SetStateAction<ContractPageType>
  >;
  requestChangesPageContent?: React.ReactElement;
}

interface DocumentViewerMainPageProps {
  header: string;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
  primaryButtonText?: string;
  primaryButtonClick?: () => void;
  loading: boolean;
  tabs?: { key: string; name: string }[];
  selectedTab?: string;
  setSelectedTab?: (key: string) => void;
}

interface DocumentViewerRequestChangesProps {
  setContractModalPageType?: React.Dispatch<
    React.SetStateAction<ContractPageType>
  >;
  requestChangesPageContent?: React.ReactElement;
}

const TabButton: React.FC<{
  isSelected: boolean;
  onClick: () => void;
  name: string;
}> = ({ isSelected = false, name, onClick }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <button
      type="button"
      css={[
        tw`flex-grow text-ps bg-background-primary rounded-t-tiny py-small text-center`,
        !isNewThemeApplied && tw`bg-grey05`,
        isSelected
          ? isNewThemeApplied
            ? tw`text-text-primary font-semibold`
            : tw`text-primary`
          : isNewThemeApplied
          ? tw`text-text-secondary`
          : tw`text-grey01`,
        isSelected && [
          tw`border-b-2`,
          isNewThemeApplied
            ? tw`border-border-high-contrast`
            : tw`border-primary`,
        ],
      ]}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

const DocumentViewerMainPage: React.FC<
  React.PropsWithChildren<DocumentViewerMainPageProps>
> = ({
  header,
  secondaryButton,
  primaryButton,
  loading,
  tabs,
  selectedTab,
  setSelectedTab,
  children,
}) => (
  <>
    <div tw="flex flex-row items-center justify-between px-extra-large py-large">
      <span tw="text-h5 font-semibold text-text-primary">{header}</span>
      <div tw="flex flex-row items-center gap-x-small">
        {secondaryButton}
        {primaryButton}
      </div>
    </div>
    {tabs?.length ? (
      <div
        data-testid="document-viewer-tabs"
        tw="px-extra-large flex flex-row gap-x-base"
      >
        {tabs?.map((tab) => (
          <TabButton
            isSelected={tab.key === selectedTab}
            name={tab.name}
            key={tab.key}
            onClick={() => {
              if (setSelectedTab) setSelectedTab(tab.key);
            }}
          />
        ))}
      </div>
    ) : null}
    <CardSeparator />
    {loading ? (
      <Loader.Spinner variant={SpinnerType.DOCUMENT_VIEWER} />
    ) : (
      <div tw="py-24 flex flex-col gap-y-24 overflow-y-auto h-[calc(100vh - 160px)] overflow-x-hidden">
        {children}
      </div>
    )}
  </>
);

const DocumentViewerRequestChanges: React.FC<DocumentViewerRequestChangesProps> = ({
  setContractModalPageType,
  requestChangesPageContent,
}) => (
  <>
    <div tw="flex flex-col justify-between px-extra-large py-large">
      <BackButton
        tw="w-fit"
        onClick={() =>
          setContractModalPageType &&
          setContractModalPageType(ContractPageType.MAIN_PAGE)
        }
      >
        Back to Draft Contract
      </BackButton>
      <span tw="text-h5 font-semibold text-text-primary">Request Changes</span>
    </div>
    <CardSeparator />
    <div tw="py-large flex flex-col gap-y-large overflow-y-auto h-[calc(100vh - 160px)] overflow-x-hidden">
      {requestChangesPageContent}
    </div>
  </>
);

export const BaseDocumentViewer: React.FC<
  React.PropsWithChildren<ContractPreviewProps>
> = ({
  onClose,
  header,
  secondaryButton,
  primaryButton,
  loading,
  tabs,
  selectedTab,
  setSelectedTab,
  contractModalPageType = ContractPageType.MAIN_PAGE,
  setContractModalPageType,
  requestChangesPageContent,
  children,
}) => {
  const dialogRef = useRef(null);
  const { isNewThemeApplied } = useContext(ThemeContext);

  useKey('Escape', () => {
    onClose();
  });

  return (
    <ConfirmationDialog.Lightbox>
      <div tw="relative self-start flex flex-row items-start mt-56">
        <div
          tw="bg-background-white width[824px] rounded-t-base mb-extra-small"
          data-testid="document-viewer"
          ref={dialogRef}
        >
          {contractModalPageType === ContractPageType.MAIN_PAGE ? (
            <DocumentViewerMainPage
              header={header}
              secondaryButton={secondaryButton}
              primaryButton={primaryButton}
              loading={loading}
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            >
              {children}
            </DocumentViewerMainPage>
          ) : (
            <DocumentViewerRequestChanges
              setContractModalPageType={setContractModalPageType}
              requestChangesPageContent={requestChangesPageContent}
            />
          )}
        </div>
        <button
          type="button"
          tw="ml-base rounded-full bg-background-primary w-extra-large h-extra-large flex justify-center place-items-center"
          onClick={onClose}
        >
          <Icon
            name="cross"
            fill={isNewThemeApplied ? undefined : theme`colors.background`}
          />
        </button>
      </div>
    </ConfirmationDialog.Lightbox>
  );
};
const DocumentViewer: React.FC<
  React.PropsWithChildren<ContractPreviewProps>
> = (props) => {
  const { open, children } = props;

  if (open) {
    return ReactDOM.createPortal(
      <BaseDocumentViewer {...props}>{children}</BaseDocumentViewer>,
      document.getElementById('dialog-root') as HTMLElement,
    );
  }
  return null;
};

export default DocumentViewer;
