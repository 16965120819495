import { useReactiveVar } from '@apollo/client';
import { format, isValid } from 'date-fns';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';

import {
  Member,
  Nationalogy,
  useUpdateMemberDataForCompanyMutation,
  useUpdateMemberDataForOnboardingMemberMutation,
  useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation,
  useUpdateMemberDataWithoutLegalDataForCompanyMutation,
} from '__generated__/graphql';

import { BasicDetailsFormValues } from '../components/basic-details-section';

const useSubmitBasicDetails = (
  member?: Member | null,
): {
  loading: boolean;
  onSubmit: (values: BasicDetailsFormValues) => Promise<void>;
} => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const [
    updateBasicDetailsForOnboardingMember,
    { loading: loadingUpdateForMember },
  ] = useUpdateMemberDataForOnboardingMemberMutation();

  const [
    updateBasicDetailsForOnboardingMemberWithoutLegalData,
    { loading: loadingUpdateForMemberWithoutLegalData },
  ] = useUpdateMemberDataForOnboardingMemberWithoutLegalDataMutation();

  const [
    updateBasicDetailsForCompany,
    { loading: loadingForCompany },
  ] = useUpdateMemberDataForCompanyMutation();

  const [
    updateBasicDetailsWithoutLegalDataForCompany,
    { loading: loadingForCompanyWithoutLegalData },
  ] = useUpdateMemberDataWithoutLegalDataForCompanyMutation();

  const onSubmit = async (values: BasicDetailsFormValues) => {
    if (!(member && member.id)) return;

    if (current === Experience.COMPANY && values.legalData) {
      const dateFields =
        values?.legalData
          ?.filter((req) => req.type === 'DateField' && req.value)
          .map((req) => ({
            key: req.key,
            value: req.value,
          })) || [];

      if (values.dateOfBirth) {
        const { dateOfBirth } = values;
        dateFields.push({
          key: 'dateOfBirth',
          value: `${format(new Date(dateOfBirth), 'yyyy-MM-dd')}`,
        });
      }

      await updateBasicDetailsForCompany({
        variables: {
          memberId: String(member.id),
          generalDataChange: {
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
          },
          email: {
            name: 'primary',
            value: values.email,
          },
          dateFieldInputs: dateFields,
          memberDataInputs:
            values?.legalData
              ?.filter(
                (req) =>
                  req.type === 'TextField' ||
                  req.type === 'DropDownField' ||
                  req.type === 'AutoCompleteField',
              )
              .map((req) => ({
                key: req.key,
                value: String(req.value),
              })) || [],
        },
      });
      return;
    }

    if (current === Experience.COMPANY) {
      await updateBasicDetailsWithoutLegalDataForCompany({
        variables: {
          memberId: String(member.id),
          generalDataChange: {
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
          },
          email: {
            name: 'primary',
            value: values.email,
          },
        },
      });
      return;
    }

    if (values.legalData) {
      await updateBasicDetailsForOnboardingMember({
        variables: {
          memberId: String(member.id),
          generalDataChange: {
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
          },
          basicDetailsChange: {
            fullLegalName: values.fullLegalName,
            nationality: {
              type: Nationalogy.CITIZEN,
              country: values.nationality,
            },
            dateOfBirth:
              values.dateOfBirth &&
              isValid(values.dateOfBirth) &&
              `${format(values.dateOfBirth, 'yyyy-MM-dd')}T00:00:00`,
          },
          legalDataChange: values.legalData.map((val) => ({
            key: val.key,
            value: val.value,
            identifier: val.description,
          })),
        },
      });
      return;
    }

    await updateBasicDetailsForOnboardingMemberWithoutLegalData({
      variables: {
        memberId: String(member.id),
        generalDataChange: {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
        },
        basicDetailsChange: {
          fullLegalName: values.fullLegalName,
          nationality: {
            type: Nationalogy.CITIZEN,
            country: values.nationality,
          },
          dateOfBirth:
            values.dateOfBirth &&
            isValid(values.dateOfBirth) &&
            `${format(values.dateOfBirth, 'yyyy-MM-dd')}T00:00:00`,
        },
      },
    });
  };

  return {
    loading:
      loadingForCompany ||
      loadingUpdateForMember ||
      loadingForCompanyWithoutLegalData ||
      loadingUpdateForMemberWithoutLegalData,
    onSubmit,
  };
};

export default useSubmitBasicDetails;
