import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import { showModuleBasedOnContractStatusAndContractStarted } from 'app/utils/module-checks';

import { ContractStatus } from '__generated__/graphql';

const config: ModuleConfig = {
  id: 'expenses',
  permission: 'expenses',
  folder: 'expenses/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Expenses',
    route: 'expenses',
    sort: 1,
    home: false,
    icon: { name: 'receipt' },
    activeIcon: { name: 'receipt-filled' },
    group: NavGroups.MAIN,
  },
  featureFlag: true,
  featureFlagDefaultValue: true,
  enabled: async () =>
    showModuleBasedOnContractStatusAndContractStarted([
      ContractStatus.ACTIVE,
      ContractStatus.ONBOARDING,
      ContractStatus.OFFBOARDING,
    ]),
};

export default config;
