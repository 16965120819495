import { updateIntercomUserData } from 'login/services/intercom';

import { Maybe, useCompleteContractMutation } from '__generated__/graphql';

const useCompleteContract = (
  id?: Maybe<string>,
): {
  completeContract: () => void;
  loading: boolean;
} => {
  const [onSubmit, { loading }] = useCompleteContractMutation({
    onCompleted() {
      updateIntercomUserData({
        'Onboarding done date': new Date().toDateString(),
      });
    },
  });
  const completeContract = () => {
    if (id) {
      return onSubmit({
        variables: {
          id,
        },
      });
    }
    return Promise.reject();
  };
  return { completeContract, loading };
};

export default useCompleteContract;
