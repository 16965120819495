import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractType, CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const memberStarted: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.member-started.title', {
      defaultValue: 'Collecting details from {{name}}',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.member-started.description', {
      defaultValue: '{{firstName}} has accessed Multiplier for the first time.',
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t, contractType) =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.member-started.contractor.label',
          'Contractor has accessed Multiplier',
        )
      : t(
          'onboarding-phase.member-started.label',
          'Employee has accessed Multiplier',
        ),
  pendingOn: (contractType) =>
    contractType === ContractType.CONTRACTOR
      ? PendingOn.CONTRACTOR
      : PendingOn.EMPLOYEE,
  pendingLabel: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.member-started.contractor.status',
          'Pending on Contractor',
        )
      : t('onboarding-phase.member-started.status', 'Pending on Employee'),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
};

export default memberStarted;
