/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import tw from 'twin.macro';

import ComboBox from 'common/components/combo-box';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';
import countryIDD from 'common/constants/country-idd';
import countryLabels from 'common/constants/country-labels';

import { ContractType, DataFieldDefinition } from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '..';

interface PhoneNumberInputProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
  requirement?: DataFieldDefinition;
  contractType?: ContractType | null;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  methods,
  requirement,
  contractType,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    register,
    formState: { errors, defaultValues },
  } = methods;

  const phoneCodeDropdownValues = useMemo(
    () =>
      Object.entries(countryIDD).map((c) => ({
        key: `${c[1]}-${c[0]}`,
        icon: <Icon.Flag name={c[0].toLowerCase()} />,
        title: c[1],
        value: c[0],
        label: countryLabels[c[0]],
      })),
    [],
  );

  const disabled =
    (requirement?.editable === false && !!defaultValues?.phone) ||
    contractType === ContractType.CONTRACTOR;

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="phone">
        {t('member.basic-details.phone.label', 'Phone Number')}
      </TextInput.Label>
      <DropdownText.Container css={[disabled && tw`bg-background-secondary`]}>
        <Controller
          name="countryCode"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ComboBox
              data-testid="select-country-code"
              variant="inline"
              showArrow
              value={value as string}
              placeholder="+XXX"
              dropdownValues={phoneCodeDropdownValues}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
        <DropdownText.Input
          tw="w-full"
          id="phone"
          placeholder={t('basic-details.phone-number-placeholder', '000000000')}
          error={!!errors.phone}
          {...register('phone')}
          disabled={disabled}
        />
      </DropdownText.Container>
      {errors.phone && (
        <TextInput.Error>{errors.phone.message}</TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default PhoneNumberInput;
