import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Toggle } from '@multiplier/common';

import { FormCard } from 'contract-onboarding/components/layout';

import 'twin.macro';

import { BasicDetailsFormValues } from '../basic-details';

const PeopleManagerSection: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  const { control, watch } = useFormContext<BasicDetailsFormValues>();

  return (
    <FormCard tw="flex flex-row">
      <div tw="flex flex-col">
        <span tw="text-p font-semibold">
          {t(
            'definition-phase.basic-details.people-manager.title',
            'This employee is a people manager',
          )}
        </span>
        <span tw="text-ps text-slate-400">
          {t(
            'definition-phase.basic-details.people-manager.description',
            'This permission allows this employee to view their team’s basic, compensation details and manage approvals of their team.',
          )}
        </span>
      </div>
      <Controller
        name="isPeopleManager"
        control={control}
        render={({ field: { onChange } }) => (
          <Toggle
            id="manager-toggle"
            checked={watch('isPeopleManager')}
            onChange={onChange}
          />
        )}
      />
    </FormCard>
  );
};

export default PeopleManagerSection;
