import React, { SetStateAction, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { TwStyle } from 'twin.macro';

import { shortenText } from '../utils';

interface ReadMoreComponentProps {
  content: string;
  charLimit?: number;
  textStyle?: TwStyle;
}

interface ReadMoreProps {
  content: string;
  charLimit: number;
  showMore: React.Dispatch<SetStateAction<boolean>>;
}

const ReadMore: React.FC<ReadMoreProps> = ({
  content,
  charLimit,
  showMore,
}) => {
  const { t } = useTranslation('common');
  return charLimit >= content.length ? null : (
    <span
      tw="underline text-ps text-text-primary font-semibold focus:outline-none"
      role="presentation"
      onClick={() => {
        showMore(true);
      }}
    >
      {t('read-more-component.read-more-label', 'Read more')}
    </span>
  );
};

const ReadLess: React.FC<ReadMoreProps> = ({
  content,
  charLimit,
  showMore,
}) => {
  const { t } = useTranslation('common');
  return charLimit >= content.length ? null : (
    <span
      tw="underline text-ps text-text-primary font-semibold focus:outline-none"
      role="presentation"
      onClick={() => {
        showMore(false);
      }}
    >
      {t('read-more-component.read-less-label', 'Read less')}
    </span>
  );
};
const ReadMoreText: React.FC<ReadMoreComponentProps> = ({
  content,
  charLimit = 165,
  textStyle,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <p data-testid="read-more-text-content" css={textStyle}>
      {showMore ? content : shortenText({ content, charLimit })}{' '}
      {showMore ? (
        <ReadLess
          content={content}
          charLimit={charLimit}
          showMore={setShowMore}
        />
      ) : (
        <ReadMore
          content={content}
          charLimit={charLimit}
          showMore={setShowMore}
        />
      )}
    </p>
  );
};

export default ReadMoreText;
