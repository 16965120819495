import React from 'react';

import { useTranslation } from 'react-i18next';

import { CardSeparator, UploadedDocumentViewer } from '@multiplier/common';
import { dateAsString } from '@multiplier/format';
import tw from 'twin.macro';

import {
  ContractBenefitFromQuery,
  ContractOffboardingOpsFromQuery,
} from 'types';

import { Compliance, Contract } from '__generated__/graphql';

import { isFreelancerOrEmployeeMultiplierEor } from '../../services/contract';

const DetailLabel = tw.p`font-medium text-ps text-primaryBlue800 whitespace-nowrap`;
const DetailValue = tw.p`col-span-3 text-ps text-neutral500`;

const DetailRow: React.FC<
  React.PropsWithChildren<{
    label: string;
  }>
> = ({ label, children }) => {
  if (!children) return null;

  return (
    <>
      <DetailLabel>{label}</DetailLabel>
      <DetailValue>{children}</DetailValue>
    </>
  );
};

const InsuranceSection: React.FC<{
  insurance: ContractBenefitFromQuery | null | undefined;
}> = ({ insurance }) => {
  const { t } = useTranslation('contract-offboarding');

  if (!insurance) return null;

  return (
    <>
      <h2 tw="font-semibold text-h6 text-neutral800">
        {t('offboarding-details.insurance-details', 'Insurance')}
      </h2>
      <div tw="grid grid-template-columns[repeat(4, auto)] gap-x-24 gap-y-16">
        <DetailRow
          label={t(
            'offboarding-details.insurance.benefit-status.label',
            'Status',
          )}
        >
          {insurance.benefitStatus}
        </DetailRow>
        <DetailRow
          label={t('offboarding-details.insurance.package-name.label', 'Plan')}
        >
          {insurance.packageName}
        </DetailRow>
      </div>
    </>
  );
};

const OffboardingDetailsSection: React.FC<{
  offboarding: ContractOffboardingOpsFromQuery;
  insurance: ContractBenefitFromQuery | null | undefined;
  contractType: Contract['type'];
  complianceType: Compliance['type'];
}> = ({ offboarding, insurance, contractType, complianceType }) => {
  const { t } = useTranslation('contract-offboarding');

  const shouldHaveEmailInfo = isFreelancerOrEmployeeMultiplierEor(
    contractType,
    complianceType,
  );

  return (
    <div tw="flex flex-col gap-y-24">
      <div tw="flex flex-col gap-y-2">
        <h2 tw="font-semibold text-h6 text-neutral800">
          {t('offboarding-details.header', 'Offboarding Details')}
        </h2>
        <p tw="text-ps text-slateGrey700">
          <span>
            {t(
              'offboarding-details.reminder',
              'Please review the last working day and supporting documents. ',
            )}
          </span>
          {shouldHaveEmailInfo && (
            <span>
              {t(
                'offboarding-details.reminder-email-info',
                'Once you click send email, an email will be sent to the member, customer and support team confirming the members last working day.',
              )}
            </span>
          )}
        </p>
      </div>
      <div tw="grid grid-template-columns[repeat(4, auto)] gap-x-24 gap-y-16">
        <DetailRow
          label={t(
            'offboarding-details.reason-for-offboarding.label',
            'Reason for Offboarding',
          )}
        >
          {offboarding.type}
        </DetailRow>
        <DetailRow
          label={t(
            'offboarding-details.last-working-day.label',
            'Last Working Date',
          )}
        >
          {dateAsString(offboarding.lastWorkingDay)}
        </DetailRow>
        <DetailRow
          label={t('offboarding-details.initiated-by.label', 'Initiated by')}
        >
          {offboarding.initiatedBy}
        </DetailRow>
        <DetailRow
          label={t('offboarding-details.initiated-on.label', 'Initiated on')}
        >
          {dateAsString(offboarding.initiatedOn)}
        </DetailRow>
        <DetailRow label={t('offboarding-details.notes.label', 'Notes')}>
          {offboarding.notes}
        </DetailRow>
        <DetailRow
          label={t(
            'offboarding-details.termination-clause.label',
            'Termination Clause',
          )}
        >
          {offboarding.terminationClauses?.join(', ')}
        </DetailRow>
        <DetailRow
          label={t(
            'offboarding-details.termination-reason.label',
            'Termination Reason',
          )}
        >
          {offboarding.terminationReason}
        </DetailRow>
        <DetailRow
          label={t('offboarding-details.severance-pay.label', 'Severance Pay')}
        >
          {offboarding.severancePay}
        </DetailRow>
        <DetailRow
          label={t(
            'offboarding-details.pending-pay-supplements.label',
            'Pending Pay Supplements',
          )}
        >
          {offboarding.verificationChecklist?.pendingPaySupplements}
        </DetailRow>
        <DetailRow
          label={t(
            'offboarding-details.pending-expenses.label',
            'Pending expenses',
          )}
        >
          {offboarding.verificationChecklist?.pendingExpenses}
        </DetailRow>
        <DetailRow
          label={t('offboarding-details.insurance.label', 'Insurance')}
        >
          {offboarding.verificationChecklist?.insurance}
        </DetailRow>
        <DetailRow
          label={t(
            'offboarding-details.final-settlement.label',
            'Full and Final Settlement',
          )}
        >
          {offboarding.verificationChecklist?.finalSettlementProcessed}
        </DetailRow>
      </div>
      <InsuranceSection insurance={insurance} />
      {offboarding.documents && (
        <div tw="col-span-4 grid grid-cols-4 gap-16">
          {offboarding.documents.map((contractOffboardingDocument) => (
            <UploadedDocumentViewer
              key={contractOffboardingDocument?.id}
              label={contractOffboardingDocument?.name}
              link={contractOffboardingDocument?.file?.downloadUrl}
            />
          ))}
        </div>
      )}
      <CardSeparator />
    </div>
  );
};

export default OffboardingDetailsSection;
