/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, CardSeparator, Loader } from '@multiplier/common';
import tw from 'twin.macro';

import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import { getIncludeDependents } from 'contract-onboarding/company/services/benefits';
import { FooterSection } from 'contract-onboarding/components/contract-review-section-modal';
import SectionActionBar from 'contract-onboarding/components/section-action-bar';
import useGetInsuranceDetails from 'contract-onboarding/member/hooks/get-insurance-details';
import useUpdateInsuranceDetails from 'contract-onboarding/member/hooks/update-dependant-details';
import insuranceSchema from 'contract-onboarding/member/services/insurance-details-schema';
import { Sections } from 'team/components/employee-details-component';

import { Contract, ContractType, CountryCode } from '__generated__/graphql';

import InsuranceDependent from '../../../components/insurance-section/components/dependant-details';
import DependantForm from '../../../components/insurance-section/components/dependant-form';
import DependantHeader from '../../../components/insurance-section/components/dependant-header';
import InsurancePlanDetails from '../../../components/insurance-section/components/plan-details';
import { InsuranceDetailsPrams } from '../../onboarding/pages/insurance-details';
import { mapDependantToInsuranceFormValues } from '../../utils/dependant-map';

const DetailsSection = tw.div`flex flex-col gap-y-32`;
const DependantFormWrapper = tw.form`grid gap-y-32`;

const InsuranceMemberSection: React.FC<{
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  toggle?: (key: string) => void;
  open?: boolean;
  contractId: Contract['id'];
  contractType: Contract['type'];
}> = ({
  contractId,
  contractType,
  uncommittedChangesCallback,
  toggle,
  open,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const [showEdit, setShowEdit] = useToggle(false);

  const {
    contractBenefit,
    country,
    loading: benefitLoading,
  } = useGetInsuranceDetails();

  const {
    onSubmit: handleUpdateDependant,
    loading: dependantUpdateLoading,
  } = useUpdateInsuranceDetails();

  const isUSA = country === CountryCode.USA;

  const schema = insuranceSchema(isUSA, t);

  const methods = useForm<InsuranceDetailsPrams>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.INSURANCE_DETAILS,
        value: methods.formState.isDirty,
      });
  }, [methods.formState.isDirty]);

  const benefit = contractBenefit?.benefit;

  const loading =
    benefitLoading || dependantUpdateLoading || methods.formState.isSubmitting;

  const dependantList = useMemo(
    () => mapDependantToInsuranceFormValues(contractBenefit?.dependents),
    [contractBenefit],
  );

  const modalCloseHandler = () => {
    setShowEdit(false);
  };

  const hasDependents = contractBenefit
    ? getIncludeDependents([contractBenefit])
    : false;

  const showDependentDetails =
    hasDependents && (contractBenefit?.dependents?.length ?? 0) > 0;

  if (loading) return <Loader.Form />;

  if (!contractBenefit) {
    return null;
  }

  return (
    <Accordion
      name={t('onboarding-phase.insurance.header', 'Insurance')}
      description={
        contractType === ContractType.CONTRACTOR
          ? t(
              'onboarding-phase.insurance.contractor-description',
              'Insurance plan and Dependent details of the contractor',
            )
          : t(
              'onboarding-phase.insurance.description',
              'Category description to better explain what this section is about.',
            )
      }
      edit={hasDependents}
      icon="shield-check"
      onEditClick={() => {
        setShowEdit(!showEdit);
        if (toggle) toggle(Sections.INSURANCE);
      }}
      warning={uncommittedChangesCallback && methods.formState.isDirty}
      onClick={() => {
        if (toggle) toggle(Sections.INSURANCE);
      }}
      open={open}
    >
      <DetailsSection>
        <InsurancePlanDetails
          contractBenefit={contractBenefit}
          providerName={benefit?.provider?.partnerName}
          country={country}
          contractId={contractId}
          isReview
        />
        {showEdit && hasDependents && (
          <>
            <CardSeparator />
            <FormProvider {...methods}>
              <DependantFormWrapper
                onSubmit={methods.handleSubmit((formaData) => {
                  setShowEdit(false);
                  handleUpdateDependant(formaData, contractBenefit?.id);
                })}
              >
                <DependantForm
                  dependantList={dependantList}
                  dependantCount={contractBenefit?.dependentCount ?? 0}
                  edit
                  country={country}
                />
                <FooterSection>
                  <SectionActionBar
                    onCancelClick={modalCloseHandler}
                    submitLoading={loading}
                    disabled={!methods.formState.isValid}
                    tw="mx-auto"
                  />
                </FooterSection>
              </DependantFormWrapper>
            </FormProvider>
          </>
        )}
        {!showEdit && showDependentDetails && (
          <div>
            <CardSeparator />
            <DependantHeader.DependantContainer
              data-testid="dependant-header"
              tw="my-large"
            >
              <DependantHeader.DependantTitle>
                {t(
                  'onboarding-phase.insurance.dependant-details.review-title',
                  'Dependent Details',
                )}
              </DependantHeader.DependantTitle>
              <DependantHeader.DependantDescription>
                {t(
                  'onboarding-phase.insurance.dependant-details.review-edit-sub-title',
                  'These details about your dependents (spouse / children / parents) will be verified post your joining date.',
                )}
              </DependantHeader.DependantDescription>
            </DependantHeader.DependantContainer>
            {contractBenefit.dependents?.map((dependant, index) => (
              <InsuranceDependent
                dependant={dependant}
                dependantIndex={index}
                country={country}
              />
            ))}
          </div>
        )}
      </DetailsSection>
    </Accordion>
  );
};

export default InsuranceMemberSection;
