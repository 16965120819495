/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { FieldPath, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon, ToolTip } from '@multiplier/common';
import isUndefined from 'lodash/isUndefined';
import { theme } from 'twin.macro';

import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import { ContractTerm, FixedPayComponent, Maybe } from '__generated__/graphql';

import {
  defaultFixedPayComponentLabels,
  defaultFixedPayComponentLabelsProbationToggle,
} from '../../index';

const PayInputHeaderTooltip: React.FC<{
  payComponent: FixedPayComponent;
  contractTerm?: Maybe<ContractTerm>;
  fieldPath: FieldPath<CompensationFormValues>;
  color?: string;
  isProbationToggleOn?: boolean;
  salaryType?: string;
}> = ({
  payComponent,
  contractTerm,
  fieldPath,
  color,
  isProbationToggleOn,
  salaryType,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { watch } = useFormContext();

  const rateType = watch(`${fieldPath}.rateType`);
  const frequency = watch(`${fieldPath}.frequency`);

  const content = useMemo(() => {
    if (payComponent?.name && isProbationToggleOn) {
      const rate = contractTerm === ContractTerm.PERMANENT ? rateType : 'FIXED';
      return defaultFixedPayComponentLabelsProbationToggle[
        `${rate},${frequency},${payComponent.label}`
      ]?.description(t, salaryType);
    }

    if (payComponent?.name) {
      const rate = contractTerm === ContractTerm.PERMANENT ? rateType : 'FIXED';
      return defaultFixedPayComponentLabels[
        `${rate},${frequency},${payComponent.label}`
      ]?.description(t);
    }
    const rate =
      contractTerm === ContractTerm.PERMANENT
        ? rateType?.toString().toLowerCase()
        : 'fixed';
    return t(
      `compensation.${rate}-${frequency?.toString().toLowerCase()}-${
        payComponent.label
      }.description`,
    );
  }, [payComponent, contractTerm, rateType, frequency]);

  if (isUndefined(frequency)) return null;

  return (
    <ToolTip variant="bottom" content={content}>
      <Icon name="info" fill={color ?? theme`colors.icon-primary`} />
    </ToolTip>
  );
};

export default PayInputHeaderTooltip;
