/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import {
  Button,
  Icon,
  IconBadge,
  Pictogram,
  ReadMoreText,
  ThemeContext,
} from '@multiplier/common';
import tw, { TwStyle, theme } from 'twin.macro';

import { useModal } from 'app/hooks';
import { numberAsCurrency } from 'app/utils/format';
import Modal from 'common/components/confirmation-dialog';
import IconButton from 'common/components/icon-button';
import EsopInput, {
  EsopForm,
  getRatioString,
} from 'contract-onboarding/company/components/esop-input';
import {
  DetailLabel,
  FormCard,
  FormContainer,
} from 'contract-onboarding/components/layout';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';

import {
  CurrencyCode,
  Maybe,
  useGetCompanyNameQuery,
} from '__generated__/graphql';

import EsopsOptionText from '../esop-option-text';

const Esop: React.FC<{
  inverseColor?: boolean;
  defaultCurrency?: Maybe<CurrencyCode>;
  showEdit?: boolean;
  uncommittedSubFormChangesCallback?: (values: boolean) => void;
  containerStyle?: TwStyle;
  titleStyle?: TwStyle;
  hideDescription?: boolean;
}> = ({
  inverseColor = false,
  defaultCurrency,
  showEdit = true,
  uncommittedSubFormChangesCallback,
  containerStyle,
  titleStyle,
  hideDescription = false,
}) => {
  const { t, i18n } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { data: company } = useGetCompanyNameQuery();
  const [showForm, setShowForm] = useToggle(false);

  const [
    openDeleteConfirmation,
    handleCloseDeleteConfirmation,
    handleOpenDeleteConfirmation,
  ] = useModal();

  const { setValue, control } = useFormContext<CompensationFormValues>();

  const esop = useWatch({
    control,
    name: 'esop',
  });

  const handleEsopAdd = (values: EsopForm) => {
    setValue('esop', values, { shouldDirty: true });
    setShowForm(false);
  };

  const amountString = useMemo(
    () =>
      esop
        ? `${esop.currency} ${numberAsCurrency(i18n.language, esop.amount)}`
        : '',
    [esop],
  );

  const ratioString = esop ? getRatioString(esop.vestingSchedule) : '';

  const esopAmount = esop?.noOfEsops ?? 0;

  return (
    <div data-testid="esop">
      <FormCard
        css={[
          tw`gap-y-small`,
          inverseColor && tw`bg-background-tertiary`,
          containerStyle,
        ]}
        data-testid="esop-container"
      >
        <FormContainer>
          <div
            css={[
              tw`flex flex-row gap-[20px] w-full justify-between`,
              esop && tw`flex-col`,
            ]}
          >
            <div>
              <DetailLabel css={[tw`text-text-primary`, titleStyle]}>
                {t(
                  'definition-phase.compensation.esop.header',
                  'Employee Stock Option Plan (ESOP) (Optional)',
                )}
              </DetailLabel>
              {!hideDescription && (
                <div
                  css={[
                    tw`text-ps text-text-tertiary w-[400px]`,
                    !isNewThemeApplied && tw`text-grey02`,
                  ]}
                >
                  {t('definition-phase.compensation.esop.description', {
                    defaultValue:
                      'The ESOP agreement is solely between "{{ company }}" and employee. It must be agreed upon between both the parties.',
                    replace: {
                      company: company?.company?.displayName ?? '',
                    },
                    interpolation: { escapeValue: false },
                  })}
                </div>
              )}
            </div>
            <div>
              {showForm ? (
                <Modal.Lightbox>
                  <Modal.Container tw="w-[650px] max-h-full overflow-y-scroll">
                    <div tw="relative flex flex-col overflow-hidden gap-y-large">
                      <EsopInput
                        defaultCurrency={defaultCurrency}
                        companyName={company?.company?.displayName}
                        onEsopAdd={handleEsopAdd}
                        handleClose={() => setShowForm(false)}
                        uncommittedSubFormChangesCallback={
                          uncommittedSubFormChangesCallback
                        }
                      />
                    </div>
                  </Modal.Container>
                </Modal.Lightbox>
              ) : (
                <>
                  {esop ? (
                    <div tw="w-full">
                      <div
                        css={[
                          !esop?.condition && tw`rounded-10`,
                          tw`border-border-primary`,
                          !isNewThemeApplied && tw`border-grey04`,
                        ]}
                        tw="flex items-center border rounded-t-base px-base pt-base pb-small gap-x-small"
                      >
                        <IconBadge
                          icon="stock"
                          tw="bg-primary bg-opacity-5"
                          css={[
                            tw`bg-background-brand-faded `,
                            !isNewThemeApplied && tw`bg-primary bg-opacity-5`,
                          ]}
                          stroke={
                            isNewThemeApplied
                              ? theme`colors.icon-primary`
                              : theme`colors.foreground`
                          }
                          color="transparent"
                        />
                        <div tw="items-start">
                          <p tw="text-ps flex-grow" data-testid="esop-option">
                            <EsopsOptionText
                              amountString={amountString}
                              esopAgreementOptions={esop?.esopAgreementOptions}
                              t={t}
                              ratioString={ratioString}
                              esopAmount={esopAmount}
                              vestedOver={esop?.vestedOver}
                            />
                          </p>
                        </div>
                        {showEdit && (
                          <div tw="w-max flex gap-x-12">
                            <IconButton
                              aria-label="edit"
                              size="medium"
                              name="pencil"
                              fill={
                                isNewThemeApplied ? undefined : 'transparent'
                              }
                              stroke={theme`colors.icon-primary`}
                              variant="outline"
                              onClick={setShowForm}
                            />
                            <IconButton
                              aria-label="delete"
                              size="medium"
                              name="bin"
                              stroke={theme`colors.icon-primary`}
                              fill={
                                isNewThemeApplied ? undefined : 'transparent'
                              }
                              variant="outline"
                              onClick={handleOpenDeleteConfirmation}
                            />
                          </div>
                        )}
                      </div>
                      {esop?.condition && (
                        <div tw="flex border border-border-primary border-t-0 rounded-b-base p-base gap-x-small">
                          <span tw="font-semibold text-ps whitespace-nowrap">
                            Note :{' '}
                          </span>
                          <ReadMoreText
                            content={esop?.condition}
                            charLimit={140}
                            textStyle={
                              isNewThemeApplied
                                ? tw`text-ps text-text-tertiary overflow-ellipsis`
                                : tw`text-ps text-grey02 overflow-ellipsis`
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) : showEdit ? (
                    <div tw="mt-base flex whitespace-nowrap">
                      <Button
                        variant="outline"
                        size="small"
                        onClick={setShowForm}
                      >
                        <div tw="flex items-center gap-tiny">
                          <Icon
                            name="plus"
                            css={[
                              isNewThemeApplied && tw`h-base w-base`,
                              !isNewThemeApplied && tw`h-[10px] w-[10px]`,
                            ]}
                            fill={
                              isNewThemeApplied
                                ? undefined
                                : theme`colors.primary`
                            }
                          />
                          {t(
                            'definition-phase.compensation.esop.add',
                            'ESOP for employee',
                          )}
                        </div>
                      </Button>
                    </div>
                  ) : null}
                </>
              )}
              {(esop || showForm) && (
                <p
                  tw="text-ps text-text-secondary pt-small mr-extra-large"
                  data-testid="esop-note"
                  css={[!isNewThemeApplied && tw`text-grey01`]}
                >
                  <Trans
                    t={t}
                    i18nKey="definition-phase.compensation.esop.note"
                  >
                    Note:
                    <em>
                      {' '}
                      ESOP agreement shall be generated and sent for signatures
                      in a separate e-mail, post the receipt of Employment
                      contract by the employee.
                    </em>
                  </Trans>
                </p>
              )}
            </div>
          </div>
        </FormContainer>
      </FormCard>
      <Modal
        open={openDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
        onConfirm={() => {
          if (esop?.id) {
            setValue('grantToDelete', esop.id, { shouldDirty: true });
            if (uncommittedSubFormChangesCallback)
              uncommittedSubFormChangesCallback(true);
          }
          setValue('esop', undefined, { shouldDirty: true });
          handleCloseDeleteConfirmation();
        }}
        icon={isNewThemeApplied ? undefined : 'info'}
        title={t(
          'definition-phase.compensation.esop.delete-confirmation.header',
          'Delete ESOP',
        )}
        description={t(
          'definition-phase.compensation.esop.delete-confirmation.description',
          'Are you sure you want to delete this ESOP?',
        )}
        buttonText={t(
          'definition-phase.compensation.esop.delete-confirmation.continue',
          'Delete',
        )}
        loading={false}
        asset={
          isNewThemeApplied ? (
            <Pictogram name="benefits" size="large" tw="mb-40" />
          ) : undefined
        }
      />
    </div>
  );
};

export default Esop;
