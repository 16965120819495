import { useLocation, useNavigate } from 'react-router-dom';

type UpdateSearchParamsReturn = (
  newParams: { key: string; value: string }[],
) => void;

type DeleteSearchParamsReturn = (newParams: string[]) => void;

// react-use only allows single retrieval
export const useSearchParams = (...params: string[]): (string | null)[] => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const results: (string | null)[] = [];

  params.forEach((param) => {
    results.push(searchParams.get(param));
  });

  return results;
};

export const useUpdateSearchParams = (): UpdateSearchParamsReturn => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  return (newParams: { key: string; value: string }[]) => {
    const params = new URLSearchParams(search);
    const oldParamsString = params.toString();
    newParams.forEach((newValue) => {
      params.set(newValue.key, newValue.value.toString());
    });
    const newParamsString = params.toString();
    if (newParamsString !== oldParamsString) {
      navigate(`${pathname}?${newParamsString}`);
    }
  };
};

export const useRemoveSearchParams = (): DeleteSearchParamsReturn => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  return (paramsToRemove: string[]) => {
    const params = new URLSearchParams(search);
    const oldParamsString = params.toString();
    paramsToRemove.forEach((key) => {
      params.delete(key);
    });
    const newParamsString = params.toString();
    if (newParamsString !== oldParamsString) {
      navigate(`${pathname}?${newParamsString}`);
    }
  };
};

export const useNavigateWithParams = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (url: string) => navigate(`${url}${search}`);
};
