import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import {
  showModuleBasedOnContractStatusAndContractStarted,
  showModuleBasedOnContractType,
} from 'app/utils/module-checks';

import { ContractStatus, ContractType } from '__generated__/graphql';

const config: ModuleConfig = {
  id: 'calendar',
  permission: 'calendar',
  folder: 'calendar/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Calendar',
    route: 'calendar',
    sort: 6,
    home: false,
    icon: { name: 'calendar-today' },
    activeIcon: { name: 'calendar-today-filled' },
    group: NavGroups.MAIN,
  },
  enabled: async () => {
    const shouldShow = await Promise.all([
      showModuleBasedOnContractType([
        ContractType.HR_MEMBER,
        ContractType.EMPLOYEE,
      ]),
      showModuleBasedOnContractStatusAndContractStarted([
        ContractStatus.ACTIVE,
        ContractStatus.ONBOARDING,
        ContractStatus.OFFBOARDING,
      ]),
    ]);

    return shouldShow.every((show) => show);
  },
};

export default config;
