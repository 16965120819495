import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker } from '@multiplier/common';

import { Contract } from '__generated__/graphql';

import useEndDateRestrictions from '../../hooks/end-date-restrictions';
import { BasicDetailsFormValues } from '../../pages/definition-phase/pages/basic-details/basic-details';
import useEndDateHighlights from './calendar-highlights';

const EndDateField: React.FC<{
  selectedCountry: Contract['country'];
  selectedCountryState: Contract['countryStateCode'];
  selectedContractType: Contract['type'];
}> = ({ selectedContractType, selectedCountry, selectedCountryState }) => {
  const {
    control,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext<BasicDetailsFormValues>();

  const employmentStartDate = watch('startOn');

  const {
    getMinimumEndDate,
    getMaximumEndDate,
    notes,
  } = useEndDateRestrictions({
    selectedContractType,
    selectedCountry,
    selectedCountryState,
  });

  const highlights = useEndDateHighlights({
    minimum: getMinimumEndDate(employmentStartDate),
    maximum: getMaximumEndDate(employmentStartDate),
  });

  return (
    <Controller
      control={control}
      name="endOn"
      render={({ field: { value, onChange } }) => (
        <DatePicker
          data-testid="end-on"
          id="end-on"
          value={value}
          min={getMinimumEndDate(employmentStartDate)}
          max={getMaximumEndDate(employmentStartDate)}
          onChange={(val) => {
            if (val === '') onChange(undefined);
            else onChange(val);
            trigger(['startOn', 'endOn']);
          }}
          error={!!errors.endOn}
          helperText={errors.endOn?.message}
          loading={false}
          notes={notes}
          highlights={highlights}
        />
      )}
    />
  );
};

export default EndDateField;
