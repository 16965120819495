import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
} from 'app/models/module-config';
import routes from 'team/member/routes';

const config: ModuleConfig = {
  id: 'employment-details',
  permission: 'team',
  folder: 'team/member/employment-contract',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Employment details',
    route: `${routes.root}/${routes.employmentContract}`,
    sort: 2,
    home: false,
    group: MemberNavGroups.EMPLOYMENT,
  },
};

export default config;
