/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React from 'react';

import { FileUpload } from '@multiplier/common';

import { Experience } from '../../../app/models/module-config';

const DocumentsProofFooter: React.FC<{
  label: string;
  onChange?: (files: File[]) => void;
  experience?: Experience;
  requireApproval?: boolean;
}> = ({ label, onChange, experience, requireApproval }) => {
  if (experience !== Experience.MEMBER || !requireApproval) return null;

  return (
    <div
      tw="col-span-3"
      data-testid={`${label.toLowerCase().trim()}-documents`}
    >
      <p tw="text-ps text-text-primary mb-small">{label}</p>
      <FileUpload minimal multiple onChange={onChange} />
    </div>
  );
};

export default DocumentsProofFooter;
