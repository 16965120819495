import React, { useContext } from 'react';

import tw, { styled } from 'twin.macro';

import { IconProps } from '../icon';
import { ThemeContext } from '../theme-provider';
import { CalloutVariant } from '../types';
import CalloutIcon from './callout-icon';
import { getVariantStyles } from './config';

export { default as CalloutIcon } from './callout-icon';

interface CalloutProps {
  variant: CalloutVariant;
  action?: boolean;
  icon?: React.ReactNode;
  iconProps?: IconProps;
  shouldShowInfoIcon?: boolean;
}

type RootProps = { variant: CalloutVariant; isNewThemeApplied?: boolean };
type ContentProps = {
  action?: boolean;
  variant?: CalloutVariant;
  isNewThemeApplied?: boolean;
};

const Callout: React.FC<React.PropsWithChildren<CalloutProps>> = ({
  variant,
  action,
  children,
  icon,
  iconProps,
  shouldShowInfoIcon = true,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <Root
      data-testid="callout"
      variant={variant}
      isNewThemeApplied={isNewThemeApplied}
      {...props}
    >
      {icon}
      {!icon && shouldShowInfoIcon && (
        <CalloutIcon.Info
          variant={variant}
          iconProps={iconProps}
          iconStyle={tw`mr-extra-small mt-2`}
          isNewThemeApplied={isNewThemeApplied}
        />
      )}
      <Content
        action={action}
        variant={variant}
        isNewThemeApplied={isNewThemeApplied}
      >
        {children}
      </Content>
    </Root>
  );
};

const Root = styled.div<RootProps>(({ variant, isNewThemeApplied = false }) => [
  tw`rounded-base p-base flex flex-row`,
  getVariantStyles(variant, isNewThemeApplied).background,
  getVariantStyles(variant, isNewThemeApplied).border,
]);

const Content = styled.div<ContentProps>(
  ({ action, variant, isNewThemeApplied = false }) => [
    tw`flex items-center`,
    variant && getVariantStyles(variant, isNewThemeApplied).foreground,
    action
      ? tw`flex grow`
      : isNewThemeApplied
      ? tw`font-normal`
      : tw`font-semibold`,
  ],
);

export default Object.assign(Callout, {
  Root,
  Content,
}) as React.FC<React.PropsWithChildren<CalloutProps>> & {
  Root: React.FC<React.PropsWithChildren<RootProps>>;
  Content: React.FC<React.PropsWithChildren<ContentProps>>;
};
