import { useEffect, useState } from 'react';

import { notEmpty } from 'app/utils/array';

import {
  ApprovalCategory,
  CompanyContractsFilter,
  Contract,
  ContractStatus,
  useGetCompanyActiveContractsQuery,
} from '__generated__/graphql';

export const contractStatusFilter = (
  contract: Contract,
  shouldIncludeOnboarding: boolean,
): boolean =>
  !!contract?.status &&
  !!contract.contractStarted &&
  (shouldIncludeOnboarding
    ? true
    : contract.status !== ContractStatus.ONBOARDING);

export const filterContracts = (
  contracts: Contract[],
  query?: string,
  contractFilter?: (contract: Contract) => boolean,
): Contract[] => {
  if (contractFilter) {
    contracts = contracts.filter(contractFilter);
  }

  if (query) {
    const queryLowerCase = query.toLowerCase();

    return contracts.filter(
      (contract) =>
        contract?.member?.firstName?.toLowerCase().includes(queryLowerCase) ||
        contract?.member?.lastName?.toLowerCase().includes(queryLowerCase) ||
        contract?.member?.emails?.[0]?.email
          ?.toLowerCase()
          .includes(queryLowerCase) ||
        contract?.position?.toLowerCase().includes(queryLowerCase),
    );
  }

  return contracts;
};

export default (
  query?: string,
  category?: ApprovalCategory,
  contractFilter?: (contract: Contract) => boolean,
  shouldIncludeOnboarding = false,
  companyContractsFilter?: CompanyContractsFilter,
): {
  team: Contract[];
  loading: boolean;
} => {
  const [team, setTeam] = useState<Contract[]>([]);
  const { data, loading } = useGetCompanyActiveContractsQuery({
    variables: {
      category,
      companyContractsFilter,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.company?.contracts) {
      const contracts = filterContracts(
        data.company.contracts
          .filter(notEmpty)
          .filter((contract) =>
            contractStatusFilter(contract, shouldIncludeOnboarding),
          ),
        query,
        contractFilter,
      );
      setTeam(contracts);
    }
  }, [data, query]);

  return { team, loading };
};
