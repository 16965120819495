/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { Contract, ContractBenefit } from '__generated__/graphql';

import InsuranceBanner from '../insurance-banner';
import PlanInfo from './plan-info';
import SkipInsurance from './skip-insurance';

const InsurancePlanDetails: React.FC<{
  providerName?: string | null;
  isReview?: boolean;
  country: Contract['country'];
  contractBenefit: ContractBenefit;
  contractId: Contract['id'];
}> = ({
  providerName,
  isReview = false,
  contractId,
  contractBenefit,
  ...props
}) => {
  const shouldShowSkipInsuranceFooter =
    contractBenefit?.benefit?.provider?.isInsuranceMandatory;

  return (
    <div data-testid="plan-details">
      {!isReview && <InsuranceBanner providerName={providerName} />}
      <PlanInfo
        contractBenefit={contractBenefit}
        isReview={isReview}
        {...props}
      />
      {shouldShowSkipInsuranceFooter ? (
        <SkipInsurance
          contractId={contractId}
          contractBenefit={contractBenefit}
        />
      ) : null}
    </div>
  );
};

export default InsurancePlanDetails;
