import { addWeeks, isBefore, isSameDay, parseISO } from 'date-fns';

import { Contract } from '__generated__/graphql';

const isNoJoiner = (
  startOn: Contract['startOn'],
  endedOn: Contract['endedOn'],
): boolean => {
  const startOnAdd = addWeeks(parseISO(startOn), 1);
  return (
    isBefore(parseISO(endedOn), startOnAdd) ||
    isSameDay(parseISO(endedOn), startOnAdd)
  );
};

export default isNoJoiner;
