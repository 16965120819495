import { ReactElement, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useModal } from 'app/hooks';
import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';
import ConfirmationDialog from 'common/components/confirmation-dialog';

import {
  Contract,
  ContractType,
  Maybe,
  useContractOffboardingCancelMutation,
} from '__generated__/graphql';

interface CancelDialogReturnType {
  Dialog: () => ReactElement;
  cancelDialogOpen: boolean;
  cancelInProgress: boolean;
  handleCancelClick: (contract?: Maybe<Contract>) => void;
  handleCancelClose: () => void;
  handleCancelConfirm: () => void;
  contractToCancel?: Contract;
}

export default (): CancelDialogReturnType => {
  const { t } = useTranslation('team.company');
  const [contractToCancel, setContractToCancel] = useState<Contract>();
  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal();

  const [onCancel, { loading }] = useContractOffboardingCancelMutation({
    onCompleted: (data) => {
      if (data.contractOffboardingCancel?.type === ContractType.FREELANCER) {
        successNotification(
          '',
          t(
            'cancel-offboarding-freelancer.notification.success',
            'Offboarding Cancelled Successfully, Freelancer moved to active.',
          ),
          false,
        );
      } else {
        successNotification(
          '',
          t(
            'cancel-offboarding-member.notification.success',
            'Offboarding Cancelled Successfully, Employee moved to active.',
          ),
          false,
        );
      }
    },
    onError: () => {
      errorNotification(
        '',
        t(
          'cancel-offboarding.notification.error',
          'Could not cancel offboarding for the member',
        ),
        false,
      );
    },
  });

  const handleCancelConfirm = async () => {
    if (contractToCancel?.id) {
      await onCancel({
        variables: {
          id: contractToCancel.id,
        },
      });
    }
    setContractToCancel(undefined);
    handleCloseDialog();
  };

  const handleCancelClick = (contract?: Maybe<Contract>) => {
    if (contract) {
      setContractToCancel(contract);
      handleOpenDialog();
    }
  };

  const handleCancelClose = () => {
    setContractToCancel(undefined);
    handleCloseDialog();
  };

  const Dialog = () => (
    <ConfirmationDialog
      open={showDialog}
      onClose={handleCancelClose}
      onConfirm={handleCancelConfirm}
      icon="member-cross"
      title={t('main.cancel-offboarding.title', 'Cancel Offboarding?')}
      description={t('main.cancel-offboarding.description', {
        defaultValue:
          'Do you want to cancel the offboarding for {{name}}? Member will be moved to active state.',
        name: `${contractToCancel?.member?.firstName} ${contractToCancel?.member?.lastName}`,
      })}
      buttonText={t('main.cancel-offboarding.continue', 'Continue')}
      loading={loading}
    />
  );

  return {
    Dialog,
    cancelDialogOpen: showDialog,
    cancelInProgress: loading,
    handleCancelConfirm,
    handleCancelClick,
    handleCancelClose,
    contractToCancel,
  };
};
