import { gql } from '@apollo/client';

export default gql`
  query GetCompanyTimeOffPolicies($filter: CompanyTimeOffPolicyFilter) {
    company {
      id
      timeOffPolicies(filter: $filter) {
        id
        policyName
        type
        typeId
        assignedEntityNames
        assignedEmployeeCount
        status
        label
        validation {
          ... on TimeOffFixedValidation {
            defaultValue
            unit
            isUnlimitedLeavesAllowed
          }
        }
        configuration {
          carryForward {
            enabled
            maxLimit {
              unit
              value
            }
            expiry {
              unit
              value
            }
          }
        }
      }
    }
  }
`;
