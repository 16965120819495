import React from 'react';

import { ToastType } from '@multiplier/common';

import { notificationVar } from '../vars';

const setNotificationVar = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
  severity: ToastType,
  persistent: boolean,
) => {
  notificationVar([
    ...notificationVar(),
    {
      id: Date.now() + Math.random(),
      shown: true,
      severity,
      title,
      message,
      inline,
      persistent,
    },
  ]);
};

export const successNotification = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
): void => {
  setNotificationVar(title, message, inline, 'success', false);
};

export const errorNotification = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
): void => {
  setNotificationVar(title, message, inline, 'error', false);
};

export const warningNotification = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
): void => {
  setNotificationVar(title, message, inline, 'warning', false);
};

export const infoNotification = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
): void => {
  setNotificationVar(title, message, inline, 'info', false);
};

export const uploadSuccessNotification = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
): void => {
  setNotificationVar(title, message, inline, 'uploadSuccess', false);
};

export const uploadErrorNotification = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
): void => {
  setNotificationVar(title, message, inline, 'uploadError', false);
};

export const uploadSuccessPersistentNotification = (
  title: string,
  message: React.ReactNode,
  inline: boolean,
): void => {
  setNotificationVar(title, message, inline, 'uploadSuccess', true);
};
