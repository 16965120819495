/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import {
  FieldArray,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce, useToggle } from 'react-use';

import {
  Button,
  CardSeparator,
  Icon,
  IconBadge,
  IconButton,
  ThemeContext,
} from '@multiplier/common';
import { SorOnboardingContext } from '@multiplier/hris-member-management';
import omit from 'lodash/omit';
import tw, { TwStyle, theme } from 'twin.macro';

import { DropdownValue } from 'common/components/combo-box';
import { Religion } from 'common/constants/default-labels';
import {
  DetailLabel,
  FormCard,
  FormContainer,
} from 'contract-onboarding/components/layout';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';
import { useIsNewAllowancesConfigEnabled } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Company,
  Contract,
  ContractType,
  CurrencyCode,
  FixedPayComponent,
  Maybe,
  PayAmountType,
  PayFrequency,
  RateFrequency,
} from '__generated__/graphql';

import { AdditionalPayDisclaimerFull } from '../additional-pay-disclaimer';
import AdditionalPayForm, {
  AdditionalPayDropdownType,
  AdditionalPayFormValues,
  PaymentType,
} from '../additional-pay-form';
import AdditionalPayTitle from './components/additional-pay-title';
import AdditionalPayItem from './components/addtional-pay-item';

export const MandatoryPill: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');
  return (
    <div tw="bg-background-primary text-ps rounded-large text-text-primary py-tiny px-small">
      {t(
        'definition-phase.compensation.additional-pays.common.mandatory',
        'Mandatory',
      )}
    </div>
  );
};

export const AdditionalPayComponentOnSalaryRevision: React.FC<{
  payItem: AdditionalPayFormValues;
  deletePayItem: () => void;
  toggleEdit: (value: boolean) => void;
  companyName: Company['displayName'];
  showEdit?: boolean;
  religion?: Religion;
  contractType: Contract['type'];
  countryCode: Contract['country'];
}> = ({
  payItem,
  deletePayItem,
  toggleEdit,
  companyName,
  showEdit,
  religion,
  contractType,
  countryCode,
}) => (
  <div tw="flex items-center gap-x-small border border-border-primary rounded-large mb-small">
    <div
      data-testid="additional-pay-item"
      css={[
        tw`p-base items-center flex-grow`,
        tw`grid grid-cols-3 grid-template-columns[min-content 1fr min-content]`,
      ]}
    >
      <div tw="mr-small">
        <IconBadge icon="dollar" tw="bg-background-primary" />
      </div>
      <div tw="text-text-primary">
        <AdditionalPayTitle
          payItem={payItem}
          companyName={companyName}
          religion={religion}
          contractType={contractType}
          countryCode={countryCode}
        />
      </div>
      <div>
        {showEdit && payItem?.isDeletable && (
          <div tw="flex gap-x-small">
            <IconButton
              name="pencil"
              data-testid="edit-pay-item"
              variant="outline"
              size="medium"
              onClick={() => {
                toggleEdit(true);
              }}
            />
            <IconButton
              name="bin"
              stroke={theme`colors.icon-primary`}
              fill="transparent"
              data-testid="delete-pay-item"
              variant="outline"
              size="medium"
              onClick={deletePayItem}
            />
          </div>
        )}
        {!payItem?.isDeletable && <MandatoryPill />}
      </div>
    </div>
  </div>
);

const AdditionalPaysTable: React.FC<{
  contractId?: Contract['id'];
  contractType?: Contract['type'];
  basePay?: number;
  basePayFrequency?: FixedPayComponent['frequency'];
  currency?: Maybe<CurrencyCode>;
  currencyOptions: Maybe<DropdownValue[]>;
  edit?: boolean;
  companyName: Company['displayName'];
  religion?: Maybe<Religion>;
  additionalPaysSupported?: AdditionalPayDropdownType[];
  uncommittedSubFormChangesCallback?: (values: boolean) => void;
  countryCode: Contract['country'];
  title?: string;
  titleStyle?: TwStyle;
  subtitleStyle?: TwStyle;
  containerStyle?: TwStyle;
  inverseColor?: boolean;
  hideListTitle?: boolean;
  description?: string;
  maxDate?: Date | string;
  minDate?: Date | string;
  additionalCompensationSubText?: boolean;
  countryStateCode: Contract['countryStateCode'];
  defaultEffectiveDate?: Date;
  showRevisionNoteForPayTypes?: PaymentType[];
  excludeUndeletable?: boolean;
  sorOnboardingContext?: SorOnboardingContext;
}> = ({
  contractId,
  contractType,
  basePay,
  basePayFrequency,
  currency,
  currencyOptions,
  edit = false,
  inverseColor = false,
  hideListTitle = false,
  companyName,
  religion,
  uncommittedSubFormChangesCallback,
  countryCode,
  title,
  additionalPaysSupported,
  titleStyle,
  containerStyle,
  subtitleStyle,
  minDate,
  maxDate,
  additionalCompensationSubText,
  countryStateCode,
  defaultEffectiveDate,
  showRevisionNoteForPayTypes = [],
  excludeUndeletable,
  sorOnboardingContext,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: contractType,
  });

  const isNotFreelancer = contractType !== ContractType.FREELANCER;
  const { control, trigger, watch } = useFormContext<CompensationFormValues>();
  const [showForm, toggleShowForm] = useToggle(false);
  const { fields, append, remove, update } = useFieldArray({
    name: 'additionalPays',
    control,
  });

  useEffect(() => {
    const basePayPaymentFrequency = watch('basePay.paymentFrequency');
    if (!isNewAllowancesConfigEnabled) return;

    fields.forEach((additionalPayItem, index) => {
      if (
        additionalPayItem?.payType === PaymentType.ALLOWANCES ||
        additionalPayItem?.payType === PaymentType.INTERNET_ALLOWANCE ||
        additionalPayItem?.payType === PaymentType.DE_MINIMIS_ALLOWANCE ||
        additionalPayItem?.payType === PaymentType.MOBILE_AND_PHONE_ALLOWANCE
      ) {
        const omittedId = omit(additionalPayItem, 'id'); // Hook-form marks as dirty even if id not changed. Hence exculding it from properties when updating.
        if (basePayPaymentFrequency === PayFrequency.SEMIMONTHLY)
          update(index, {
            ...omittedId,
            frequency: RateFrequency.SEMIMONTHLY,
          });
        if (basePayPaymentFrequency === PayFrequency.MONTHLY)
          update(index, {
            ...omittedId,
            frequency: RateFrequency.MONTHLY,
          });
        trigger('additionalPays');
      }
    });
  }, [watch('basePay.paymentFrequency')]);

  useDebounce(
    () => {
      if (edit && fields?.length)
        fields.forEach((additionalPayItem, index) => {
          if (
            additionalPayItem?.payType === PaymentType.THR_BONUS ||
            additionalPayItem.payType === PaymentType.THIRTEENTH_MONTH_BONUS
          ) {
            const omittedId = omit(additionalPayItem, 'id'); // Hook-form marks as dirty even if id not changed. Hence exculding it from properties when updating.
            update(index, {
              ...omittedId,
              currency,
              amount: basePay ?? 0,
            });
            trigger('additionalPays');
          }
          if (
            contractType === ContractType.FREELANCER &&
            (additionalPayItem.payType === PaymentType.ALLOWANCES ||
              additionalPayItem.payType === PaymentType.JOINING_BONUS)
          ) {
            const omittedId = omit(additionalPayItem, 'id'); // Hook-form marks as dirty even if id not changed. Hence exculding it from properties when updating.
            update(index, {
              ...omittedId,
              currency,
            });
            trigger('additionalPays');
          }
        });
    },
    1000,
    [basePay, edit, currency],
  );

  const existingPays = useMemo(
    () =>
      (fields as FieldArray<CompensationFormValues, 'additionalPays'>[])?.map(
        (f) => ({
          payType: f.payType,
          name: f.name,
          amountType: f.amountType,
          label: f.label,
        }),
      ),
    [fields],
  );

  const hasAutomaticPayments =
    (fields as FieldArrayWithId<
      CompensationFormValues,
      'additionalPays'
    >[]).filter((item) => item.amountType === PayAmountType.FIXED_AMOUNT)
      .length > 0;

  const hasManualPayments =
    (fields as FieldArrayWithId<
      CompensationFormValues,
      'additionalPays'
    >[]).filter((item) => item.amountType === PayAmountType.VARIABLE_AMOUNT)
      .length > 0;

  if (!edit && fields?.length === 0) return null;

  return (
    <FormCard
      css={[
        tw`gap-y-small`,
        inverseColor && tw`bg-background-primary`,
        containerStyle,
      ]}
      data-testid="additional-pay-container"
    >
      <FormContainer>
        <div tw="flex flex-col w-full">
          <div tw="flex flex-row justify-between w-full">
            <div>
              <DetailLabel
                css={[tw`text-p font-semibold text-text-primary`, titleStyle]}
              >
                {title ??
                  t(
                    'definition-phase.compensation.additional-pays.common.header',
                    'Additional Compensation',
                  )}
              </DetailLabel>
              {!additionalCompensationSubText && (
                <div
                  css={[
                    tw`text-ps text-text-tertiary w-[80%]`,
                    subtitleStyle,
                    !isNewThemeApplied && tw`text-grey02`,
                  ]}
                >
                  {t(
                    'definition-phase.compensation.additional-pays.common.description',
                    'Add any country-specific mandatory payments, bonuses, commissions or other benefits here',
                  )}
                </div>
              )}
            </div>
            <div>
              {edit && (
                <div tw="mt-base items-end">
                  {showForm ? (
                    <AdditionalPayForm
                      contractType={contractType}
                      countryCode={countryCode}
                      basePay={basePay}
                      currency={currency}
                      onSubmit={(v) => {
                        append(v);
                        toggleShowForm(false);
                        trigger('additionalPays');
                      }}
                      onCancel={() => {
                        toggleShowForm(false);
                      }}
                      currencyOptions={currencyOptions}
                      existingPays={existingPays}
                      uncommittedSubFormChangesCallback={
                        uncommittedSubFormChangesCallback
                      }
                      additionalPaysSupported={additionalPaysSupported}
                      minDate={minDate}
                      maxDate={maxDate}
                      defaultEffectiveDate={defaultEffectiveDate}
                      basePayPaymentFrequency={watch(
                        'basePay.paymentFrequency',
                      )}
                      sorOnboardingContext={sorOnboardingContext}
                    />
                  ) : (
                    <div tw="flex items-center gap-x-small whitespace-nowrap">
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          toggleShowForm(true);
                        }}
                      >
                        <div tw="flex items-center gap-tiny">
                          <Icon
                            name="plus"
                            css={[
                              isNewThemeApplied && tw`h-base w-base`,
                              !isNewThemeApplied && tw`h-[10px] w-[10px]`,
                            ]}
                            fill={
                              isNewThemeApplied
                                ? undefined
                                : theme`colors.primary`
                            }
                          />
                          {isNewAllowancesConfigEnabled
                            ? t(
                                'definition-phase.compensation.additional-pays.add-allowance-button',
                                'Add allowance',
                              )
                            : t(
                                'definition-phase.compensation.additional-pays.add-button-new',
                                'Add additional pay',
                              )}
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div data-testid="additional-pay-component">
            {fields.length > 0 && !hideListTitle && (
              <DetailLabel
                css={[tw`text-ps font-semibold mb-base text-text-primary`]}
              >
                {t(
                  'definition-phase.compensation.additional-pays.common.items-label',
                  'Pay Items',
                )}
              </DetailLabel>
            )}
            {isNotFreelancer ? (
              <>
                {hasAutomaticPayments && (
                  <div tw="mt-base mb-large">
                    <p tw="text-ps text-text-secondary capitalize">
                      {t(
                        'definition-phase.compensation.additional-pays.common.automatic-payments',
                        'AUTOMATIC PAYMENTS',
                      )}
                    </p>
                    {(fields as FieldArrayWithId<
                      CompensationFormValues,
                      'additionalPays'
                    >[])
                      .filter(
                        (item) =>
                          item.amountType === PayAmountType.FIXED_AMOUNT &&
                          (excludeUndeletable ? item.isDeletable : true),
                      )
                      .map((additionalPay) => (
                        <AdditionalPayItem
                          key={additionalPay.id}
                          basePay={basePay}
                          basePayFrequency={basePayFrequency}
                          currency={currency}
                          currencyOptions={currencyOptions}
                          contractType={contractType}
                          payItem={additionalPay}
                          deletePayItem={() => {
                            const deleteIndex = fields.indexOf(additionalPay);
                            remove(deleteIndex);
                          }}
                          onUpdate={(payItem: AdditionalPayFormValues) => {
                            const updateIndex = fields.indexOf(additionalPay);
                            update(updateIndex, payItem);
                            trigger('additionalPays');
                          }}
                          showEdit={edit}
                          existingPays={existingPays}
                          companyName={companyName ?? ''}
                          religion={religion}
                          uncommittedSubFormChangesCallback={
                            uncommittedSubFormChangesCallback
                          }
                          countryCode={countryCode}
                          countryStateCode={countryStateCode}
                          contractId={contractId}
                          showRevisionNoteForPayTypes={
                            showRevisionNoteForPayTypes
                          }
                          basePayPaymentFrequency={watch(
                            'basePay.paymentFrequency',
                          )}
                        />
                      ))}
                    <div tw="mt-large">
                      {!sorOnboardingContext?.isHrisOnlyFlow && (
                        <AdditionalPayDisclaimerFull
                          existingPays={existingPays}
                          amountType={PayAmountType.FIXED_AMOUNT}
                          contractType={contractType}
                        />
                      )}
                    </div>
                  </div>
                )}
                {hasAutomaticPayments && hasManualPayments && <CardSeparator />}
                {hasManualPayments && (
                  <div>
                    <p tw="text-ps text-text-secondary mt-large capitalize">
                      {t(
                        'definition-phase.compensation.additional-pays.common.manual-payments',
                        'MANUAL PAYMENTS',
                      )}
                    </p>
                    {(fields as FieldArrayWithId<
                      CompensationFormValues,
                      'additionalPays'
                    >[])
                      .filter(
                        (item) =>
                          item.amountType === PayAmountType.VARIABLE_AMOUNT,
                      )
                      .map((additionalPay) => (
                        <AdditionalPayItem
                          key={additionalPay.id}
                          basePay={basePay}
                          basePayFrequency={basePayFrequency}
                          currency={currency}
                          currencyOptions={currencyOptions}
                          contractType={contractType}
                          payItem={additionalPay}
                          deletePayItem={() => {
                            const deleteIndex = fields.indexOf(additionalPay);
                            remove(deleteIndex);
                          }}
                          onUpdate={(payItem: AdditionalPayFormValues) => {
                            const updateIndex = fields.indexOf(additionalPay);
                            update(updateIndex, payItem);
                            trigger('additionalPays');
                          }}
                          showEdit={edit}
                          existingPays={existingPays}
                          companyName={companyName ?? ''}
                          religion={religion}
                          uncommittedSubFormChangesCallback={
                            uncommittedSubFormChangesCallback
                          }
                          countryCode={countryCode}
                          countryStateCode={countryStateCode}
                          minDate={minDate}
                          maxDate={maxDate}
                          contractId={contractId}
                          showRevisionNoteForPayTypes={
                            showRevisionNoteForPayTypes
                          }
                        />
                      ))}
                    <div tw="mt-large">
                      <AdditionalPayDisclaimerFull
                        existingPays={existingPays}
                        amountType={PayAmountType.VARIABLE_AMOUNT}
                        contractType={contractType}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div>
                {(fields as FieldArrayWithId<
                  CompensationFormValues,
                  'additionalPays'
                >[]).map((additionalPay) => (
                  <AdditionalPayItem
                    key={additionalPay.id}
                    basePay={basePay}
                    basePayFrequency={basePayFrequency}
                    currency={currency}
                    currencyOptions={currencyOptions}
                    contractType={contractType}
                    payItem={additionalPay}
                    deletePayItem={() => {
                      const deleteIndex = fields.indexOf(additionalPay);
                      remove(deleteIndex);
                    }}
                    onUpdate={(payItem: AdditionalPayFormValues) => {
                      const updateIndex = fields.indexOf(additionalPay);
                      update(updateIndex, payItem);
                      trigger('additionalPays');
                    }}
                    showEdit={edit}
                    existingPays={existingPays}
                    companyName={companyName ?? ''}
                    religion={religion}
                    uncommittedSubFormChangesCallback={
                      uncommittedSubFormChangesCallback
                    }
                    countryCode={countryCode}
                    countryStateCode={countryStateCode}
                    contractId={contractId}
                    showRevisionNoteForPayTypes={showRevisionNoteForPayTypes}
                  />
                ))}
              </div>
            )}

            {!isNotFreelancer && !sorOnboardingContext?.isHrisOnlyFlow && (
              <div tw="mt-small">
                <AdditionalPayDisclaimerFull
                  contractType={contractType}
                  existingPays={existingPays}
                />
              </div>
            )}
          </div>
        </div>
      </FormContainer>
    </FormCard>
  );
};

export default AdditionalPaysTable;
