/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { setOnboardingAttributes } from '@multiplier/growthbook';

import { notEmpty } from 'app/utils/array';
import OnboardingHeader, {
  ContractTypeVariant,
} from 'contract-onboarding/components/onboarding-header';
import OnboardingPrimaryCard from 'contract-onboarding/components/onboarding-primary-card';
import ProgressWidgetContent from 'contract-onboarding/components/progress-widget-content';
import SignOutButton from 'contract-onboarding/member/components/sign-out-button';
import useOnboardingFormRequirements from 'document-management/member/onboarding-forms/hooks/get-onboarding-form-requirements';
import EmployeeDetailsCard from 'team/components/employee-details-component';

import {
  ContractOnboardingStatus,
  useGetMemberQuery,
} from '__generated__/graphql';

import { OnboardingLayout } from '../../../components/layout';
import SwitchViewButton from '../../components/switch-view-button';
import statusConfig from './status-config';
import stepConfig from './step-config';

const MemberVerification: React.FC = () => {
  const { data: { member } = { member: {} }, loading } = useGetMemberQuery();
  const contract = useMemo(() => member?.contracts?.[0], [member]);
  const { onboardingFormRequirements } = useOnboardingFormRequirements(member);
  const { t } = useTranslation('contract-onboarding.member');

  const statusList = useMemo(
    () =>
      (contract?.onboarding?.statuses ?? [ContractOnboardingStatus.DRAFT])
        .filter(notEmpty)
        .map((status) => ({
          status,
          label: statusConfig[status]?.statusLabel(t),
        })),
    [contract],
  );

  useEffect(() => {
    if (contract) {
      setOnboardingAttributes({
        country: contract.country,
        contractType: contract.type,
        companyId: contract.company?.id,
      });
    }
  }, [contract]);

  const [workflowUpdating, toggleWorkflowUpdating] = useToggle(false);

  return (
    <OnboardingLayout data-testid="verification-phase">
      <div tw="pt-56 pb-[167px] bg-background-inverted text-center">
        <div
          tw="flex flex-col gap-y-36 items-stretch w-[740px] ml-auto mr-auto"
          data-testid="verification-phase-header"
        >
          <OnboardingHeader
            id={contract?.id}
            country={contract?.country}
            firstName={member?.firstName}
            lastName={member?.lastName}
            position={contract?.position}
            contractType={contract?.type}
            contractTypeVariant={ContractTypeVariant.CENTER}
            additionalContent={
              <>
                <SwitchViewButton />
                <SignOutButton />
              </>
            }
          />
        </div>
      </div>
      <div tw="width[740px] ml-auto mr-auto margin-top[-76px] flex flex-col gap-y-base">
        <OnboardingPrimaryCard
          header={
            contract?.onboarding?.status &&
            statusConfig[contract.onboarding.status] &&
            statusConfig[contract.onboarding.status].title(
              t,
              `${member?.firstName} ${member?.lastName}`,
            )
          }
          subtitle={
            contract?.onboarding?.status &&
            statusConfig[contract.onboarding.status] &&
            statusConfig[contract.onboarding.status].subtitle &&
            statusConfig[contract.onboarding.status].subtitle?.(t)
          }
          pendingOn={
            contract?.onboarding?.status &&
            statusConfig[contract.onboarding.status]?.pendingOn
          }
          loading={loading}
          contractType={contract?.type}
        >
          {contract?.onboarding?.status &&
            contract.onboarding.current &&
            statusConfig[contract.onboarding.status] && (
              <ProgressWidgetContent
                memberName={member?.firstName}
                currentStatus={contract.onboarding.status}
                variant={stepConfig[contract.onboarding.current]?.variant}
                requiredDocuments={onboardingFormRequirements}
                description={statusConfig[
                  contract.onboarding.status
                ].description(
                  t,
                  `${member?.firstName}`,
                  member?.gender,
                  contract.type,
                )}
                primaryAction={
                  statusConfig[contract.onboarding.status].primaryAction
                }
                optionalAction={
                  statusConfig[contract.onboarding.status].optionalAction
                }
                statuses={statusList}
                contractId={contract.id}
                workflowUpdating={workflowUpdating}
                toggleWorkflowUpdating={toggleWorkflowUpdating}
                activationCutoff={contract.onboarding.activationCutoff}
              />
            )}
        </OnboardingPrimaryCard>
        {contract && member && (
          <EmployeeDetailsCard
            contract={contract}
            member={member}
            workflowUpdating={workflowUpdating}
          />
        )}
      </div>
    </OnboardingLayout>
  );
};

export default MemberVerification;
