/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import countryLabels from 'common/constants/country-labels';
import importMetaEnv from 'import-meta-env';

import { CountryCode } from '__generated__/graphql';

const talentWikiUrls: { [key: string]: string } = {
  [CountryCode.ARG]: 'argentina',
  [CountryCode.AUS]: 'australia',
  [CountryCode.AUT]: 'austria',
  [CountryCode.BGD]: 'bangladesh',
  [CountryCode.BEL]: 'belgium',
  [CountryCode.BRA]: 'brazil',
  [CountryCode.BRN]: 'brunei',
  [CountryCode.KHM]: 'cambodia',
  [CountryCode.CAN]: 'canada',
  [CountryCode.CHN]: 'china',
  [CountryCode.DNK]: 'denmark',
  [CountryCode.EGY]: 'egypt',
  [CountryCode.EST]: 'estonia',
  [CountryCode.FJI]: 'fiji',
  [CountryCode.FIN]: 'finland',
  [CountryCode.FRA]: 'france',
  [CountryCode.DEU]: 'germany',
  [CountryCode.GHA]: 'ghana',
  [CountryCode.GRC]: 'greece',
  [CountryCode.HKG]: 'hong-kong',
  [CountryCode.IND]: 'india',
  [CountryCode.IDN]: 'indonesia',
  [CountryCode.IRL]: 'ireland',
  [CountryCode.ISR]: 'israel',
  [CountryCode.ITA]: 'italy',
  [CountryCode.JPN]: 'japan',
  [CountryCode.LAO]: 'laos',
  [CountryCode.MYS]: 'malaysia',
  [CountryCode.MDV]: 'maldives',
  [CountryCode.MEX]: 'mexico',
  [CountryCode.MNG]: 'mongolia',
  [CountryCode.MMR]: 'myanmar',
  [CountryCode.NPL]: 'nepal',
  [CountryCode.NLD]: 'netherlands',
  [CountryCode.NZL]: 'new-zealand',
  [CountryCode.PAK]: 'pakistan',
  [CountryCode.PHL]: 'philippines',
  [CountryCode.PRT]: 'portugal',
  [CountryCode.RUS]: 'russia',
  [CountryCode.SGP]: 'singapore',
  [CountryCode.SWE]: 'sweden',
  [CountryCode.KOR]: 'south-korea',
  [CountryCode.LKA]: 'sri-lanka',
  [CountryCode.TWN]: 'taiwan',
  [CountryCode.THA]: 'thailand',
  [CountryCode.TUR]: 'turkey',
  [CountryCode.UKR]: 'ukraine',
  [CountryCode.ARE]: 'united-arab-emirates',
  [CountryCode.GBR]: 'united-kingdom',
  [CountryCode.USA]: 'united-states',
  [CountryCode.VNM]: 'vietnam',
  [CountryCode.CHE]: 'switzerland',
};

const TalentWiki: React.FC<{ country: CountryCode }> = ({ country }) => {
  const { t } = useTranslation(['salary-calculator', 'common']);

  const { isNewThemeApplied } = useContext(ThemeContext);

  return talentWikiUrls[country] ? (
    <div
      css={[
        tw`flex flex-row items-center justify-between bg-background-secondary px-large py-extra-large rounded-b-base`,
        !isNewThemeApplied && tw`bg-grey05`,
      ]}
    >
      <div tw="flex flex-row items-center">
        {country && (
          <Icon.Flag tw="mr-base w-40" name={country.toLowerCase()} />
        )}
        <span>
          {t('breakdown.talentwiki.learn-more', {
            defaultValue: 'Learn more about hiring talent in {{-country}}.',
            replace: {
              country: countryLabels[country],
            },
          })}
        </span>
      </div>
      <div>
        <a
          tw="underline"
          href={`${importMetaEnv.VITE_TALENTWIKI_URL}${talentWikiUrls[country]}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('breakdown.talentwiki.link', 'Go to Talentwiki')}
        </a>
      </div>
    </div>
  ) : null;
};

export default TalentWiki;
