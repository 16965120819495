import { gql } from '@apollo/client';

export default gql`
  query GetSalaryCalculation($params: SalaryCalculationParam) {
    salaryCalculation(params: $params) {
      companyPayable {
        ...calculationBreakdown
      }
      employeePayable {
        ...calculationBreakdown
      }
    }
  }

  fragment calculationBreakdown on CalculationBreakdown {
    name
    entries {
      name
      currency
      amount
      note
      entries {
        name
        currency
        amount
        note
      }
    }
    total {
      name
      currency
      amount
      note
    }
    frequency
  }
`;
