import { TFunction } from 'i18next';

import {
  ConditionKey,
  ConditionOperator,
  Gender,
} from '../__generated__/graphql';

export const genderLabel = (
  t: TFunction<'hris.common'>,
): Record<Gender, string> => ({
  [Gender.MALE]: t('label.gender.male', 'Male'),
  [Gender.FEMALE]: t('label.gender.female', 'Female'),
  [Gender.UNSPECIFIED]: t('label.gender.unspecified', 'Unspecified'),
});

export const conditionKeyLabel = (
  t: TFunction<'hris.common'>,
): Record<ConditionKey, string> => ({
  [ConditionKey.COUNTRY]: t('condition-key.country', 'Country'),
  [ConditionKey.DEPARTMENT]: t('condition-key.department', 'Department'),
  [ConditionKey.ENTITY]: t('condition-key.entity', 'Entity'),
  [ConditionKey.GENDER]: t('condition-key.gender', 'Gender'),
  [ConditionKey.NAME]: t('condition-key.name', 'Name'),
});

export const operatorLabel = (
  t: TFunction<'hris.common'>,
): Record<ConditionOperator, string> => ({
  [ConditionOperator.EQUALS]: t('condition-operator.equals', 'equals to'),
  [ConditionOperator.NOT_EQUALS]: t(
    'condition-operator.not-equals',
    'does not equal to',
  ),
});

export { default as countryLabels } from './country-labels';
