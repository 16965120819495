/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import {
  Controller,
  RegisterOptions,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect, usePrevious } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { ComboBox, TextInput } from '@multiplier/common';
import type { TFunction } from 'i18next';
import 'twin.macro';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';
import DynamicInput from 'contract-onboarding/member/components/dynamic-input';
import {
  BankDetailsFormParams,
  accountRequirementTypeLabel,
} from 'contract-onboarding/member/pages/onboarding/pages/bank-details';
import {
  BankDynamicDetailField,
  getDynamicFields,
} from 'contract-onboarding/member/services/bank-data';
import getErrorMessageFromRegex from 'contract-onboarding/services/get-error-message-from-regex';

import {
  Contract,
  CurrencyCode,
  Maybe,
  PaymentAccountRequirement,
} from '__generated__/graphql';

import { BankDetailsSectionVariant } from '../../index';
import FormItem from '../bank-account-details-form-item';
import SwiftBICValueComponent, {
  checkIfSwiftKeyField,
} from '../swift-bic-value-component';

const addressLabel = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  key: string,
  label?: Maybe<string>,
): string => {
  const map: Record<string, string> = {
    'address.country': t(
      `bank-address-fields.address.country.label`,
      'Bank Country',
    ),
    'address.state': t(`bank-address-fields.address.state.label`, 'Bank State'),
    'address.city': t(`bank-address-fields.address.city.label`, 'Bank City'),
    'address.postCode': t(
      `bank-address-fields.address.postCode.label`,
      'Bank Postal Code',
    ),
  };
  if (key in map) return map[key];
  return t(`bank-address-fields.${key}.label`, label ?? '');
};

const getLabelName = (
  t: TFunction<'contract-onboarding.member'> | TFunction<string[]>,
  fieldKey: string,
  fieldLabel?: Maybe<string>,
  isMandatory?: Maybe<boolean>,
): string => {
  const optionalLabel = !isMandatory
    ? ` (${t('bank-details.optional-label', 'Optional')})`
    : '';
  if (fieldKey.startsWith('address.')) {
    return `${addressLabel(t, fieldKey, fieldLabel)}${optionalLabel}`;
  }
  return `${fieldLabel}${optionalLabel}`;
};

export const RenderFormItem: React.FC<{
  field: BankDynamicDetailField;
  index: number;
  bankDetailObject: Record<string, string>;
  isEditing?: boolean;
  variant?: BankDetailsSectionVariant;
  contractType: Contract['type'];
  source: 'details-collect-page' | 'update-page';
}> = ({
  field,
  index,
  bankDetailObject,
  isEditing,
  variant,
  contractType,
  source = false,
}) => {
  const { t } = useTranslation('contract-onboarding.member');
  const methods = useFormContext<BankDetailsFormParams>();

  const {
    register,
    formState: { errors },
    control,
    setValue,
    trigger,
  } = methods;

  const dependentFields = useMemo(
    () => new Set(field?.requirements?.subFields?.map((f) => f.parentField)),
    [field],
  );

  const bankData = useWatch({ control, name: 'bankData' });
  const dependentData = useMemo(
    () =>
      Array.from(dependentFields)?.map(
        (f) => bankData?.find((d) => d?.key === f)?.value,
      ),
    [bankData],
  );

  useDeepCompareEffect(() => {
    if (dependentData?.length) {
      trigger(`bankData.${index}.value`);
    }
  }, [dependentData]);

  const fieldDefinition = useMemo(() => {
    const validReq = field?.requirements?.subFields?.find(
      (f) =>
        bankData?.find((d) => d?.key === f.parentField)?.value ===
        f.targetValue,
    );
    if (validReq) return validReq;
    if (field?.requirements?.default) return field?.requirements?.default;
    return undefined;
  }, [field, dependentData]);

  const prevFieldDefinition = usePrevious(fieldDefinition);

  useEffect(() => {
    if (!fieldDefinition && prevFieldDefinition) {
      setValue(`bankData.${index}.value`, undefined);
    }
  }, [prevFieldDefinition, fieldDefinition]);

  const registerOptions = useMemo<RegisterOptions>(
    () => ({
      required: fieldDefinition?.isMandatory
        ? t(
            'bank-account-schema.select.error-message.required',
            'Input is required',
          )
        : false,
      pattern: fieldDefinition?.regex
        ? {
            value: new RegExp(fieldDefinition?.regex),
            message:
              fieldDefinition?.errorMessage ??
              getErrorMessageFromRegex(t, fieldDefinition?.regex),
          }
        : undefined,
      validate: (val) => {
        if (!fieldDefinition?.isMandatory && !val) return true;
        if (fieldDefinition?.isMandatory && !val)
          return t(
            'bank-account-schema.select.error-message.wrong-value',
            'Select a valid dropdown value',
          );
        if (
          val &&
          fieldDefinition?.allowedValues?.length &&
          !fieldDefinition?.allowedValues?.map((v) => v?.value)?.includes(val)
        )
          return t(
            'bank-account-schema.select.error-message.wrong-value',
            'Select a valid dropdown value',
          );
        return true;
      },
    }),
    [fieldDefinition],
  );

  const fieldLabel = useMemo(
    () =>
      getLabelName(
        t,
        field?.key,
        fieldDefinition?.label,
        fieldDefinition?.isMandatory,
      ),
    [fieldDefinition],
  );

  if (fieldDefinition) {
    if (source === 'details-collect-page') {
      return (
        <TextInput.Container key={field?.key}>
          <TextInput.Label htmlFor={field?.key ?? ''}>
            {fieldLabel}
          </TextInput.Label>
          <DynamicInput
            t={t}
            index={index}
            field={fieldDefinition}
            register={register}
            errors={errors}
            control={control}
            registerOptions={registerOptions}
          />
        </TextInput.Container>
      );
    }
    return (
      <FormItem
        id={field?.key || ''}
        key={field?.key || ''}
        label={fieldLabel}
        value={
          fieldDefinition?.type === 'radio' ||
          fieldDefinition?.type === 'select'
            ? fieldDefinition?.allowedValues?.find(
                (v) => v?.value === bankDetailObject[field?.key || ''],
              )?.key
            : bankDetailObject[field?.key || '']
        }
        valueElement={
          checkIfSwiftKeyField(field?.key ?? '') ? (
            <SwiftBICValueComponent
              contractType={contractType}
              value={bankDetailObject[field?.key || '']}
            />
          ) : undefined
        }
        isEditing={isEditing}
        variant={variant}
      >
        <div>
          <DynamicInput
            t={t}
            index={index}
            field={fieldDefinition}
            register={register}
            errors={errors}
            control={control}
            registerOptions={registerOptions}
          />
        </div>
      </FormItem>
    );
  }
  return null;
};

const DynamicBankDetailsSubForm: React.FC<{
  contractType: Contract['type'];
  paymentAccountRequirementsMap: Map<string, PaymentAccountRequirement>;
  currentPaymentAccountRequirementType?: Maybe<string>;
  isEditing?: boolean;
  bankDetailObject: Record<string, string>;
  selectedAccountRequirementType?: Maybe<string>;
  contractCurrency: Maybe<CurrencyCode> | undefined;
  variant: BankDetailsSectionVariant;
}> = ({
  contractType,
  paymentAccountRequirementsMap,
  currentPaymentAccountRequirementType,
  isEditing,
  bankDetailObject,
  selectedAccountRequirementType,
  contractCurrency,
  variant,
}) => {
  const { t } = useTranslation([
    'contract-onboarding.common',
    'contract-onboarding.member',
  ]);
  const methods = useFormContext();

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  const { control, unregister } = methods;

  useEffect(
    () => () => {
      unregister('bankData');
    },
    [],
  );

  const fieldsToRender = useMemo(
    () =>
      Array.from(
        getDynamicFields(
          paymentAccountRequirementsMap.get(
            isEditing
              ? selectedAccountRequirementType ?? ''
              : currentPaymentAccountRequirementType ?? '',
          )?.requirementFields,
        )?.values() ?? [],
      ),
    [
      paymentAccountRequirementsMap,
      isEditing,
      selectedAccountRequirementType,
      currentPaymentAccountRequirementType,
    ],
  );

  return (
    <>
      {current === Experience.MEMBER &&
        (paymentAccountRequirementsMap?.size ?? 0) > 1 && (
          <FormItem
            id="account-requirement-type"
            label={t(
              'onboarding-phase.bank-details.account-requirement-type',
              'Account Requirement Type',
            )}
            value={currentPaymentAccountRequirementType}
            isEditing={isEditing}
            variant={variant}
          >
            <Controller
              name="paymentAccountRequirementType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  data-testid="payment-account-requirement-type"
                  variant="default"
                  dropdownValues={Array.from(
                    paymentAccountRequirementsMap.keys(),
                  ).map((data) => ({
                    value: data,
                    title: accountRequirementTypeLabel({
                      targetCurrency: contractCurrency,
                      requirementType: data,
                      t,
                    }),
                    label: data,
                  }))}
                  placeholder={t(
                    'bank-details.payment-account-requirement-type.placeholder',
                    'Bank Account Type',
                  )}
                  value={value as string}
                  onChange={onChange}
                />
              )}
            />
          </FormItem>
        )}
      {fieldsToRender?.map((f, index) => (
        <RenderFormItem
          key={f?.key}
          field={f}
          index={index}
          bankDetailObject={bankDetailObject}
          isEditing={isEditing}
          variant={variant}
          contractType={contractType}
          source="update-page"
        />
      ))}
    </>
  );
};

export default DynamicBankDetailsSubForm;
