import {
  CompanyNavGroups,
  Experience,
  ModuleConfig,
} from 'app/models/module-config';

const config: ModuleConfig = {
  id: 'performance-reviews',
  folder: 'performance-reviews',
  permission: 'performance-reviews',
  experience: Experience.COMPANY,
  nav: {
    defaultName: 'Compensation & Job Title Revision',
    route: 'promotions',
    sort: 4,
    home: false,
    group: CompanyNavGroups.ADMINISTRATION,
  },
};

export default config;
