const routes = {
  root: 'organization-settings',
  paymentSettings: 'payment-settings',
  setupAchDirectDebit: 'setup-ach-direct-debit',
  setupSepaDirectDebit: 'setup-sepa-direct-debit',
  bulkOnboarding: {
    toString: (): string => 'bulk-onboarding',
    entities: 'entities',
  },
  companyMembers: {
    toString: (): string => 'company-members',
    addManager: 'add-manager',
    editManager: 'edit-manager',
    details: 'details',
  },
  companyStructure: {
    toString: (): string => 'company-structure',
    departments: 'departments',
  },
  companyPolicies: {
    toString: (): string => 'company-policies',
    timeOff: 'time-off',
  },
  departments: {
    toString: (): string => 'departments',
    create: 'create',
    edit: 'edit',
  },
  timeOffType: {
    toString: (): string => 'time-off-type',
    create: 'create',
    edit: 'edit',
  },
  timeOffPolicy: {
    toString: (): string => 'time-off-policy',
    create: 'create',
    edit: 'edit',
  },
  companyRolesAndPermissions: {
    toString: (): string => 'company-roles-and-permissions',
    editRole: 'edit-role',
    assignRole: 'assign-role',
    roleDetails: 'details',
  } as const,
};
export default routes;

export enum mobilePaths {
  SETTINGS = 'company-settings',
  MEMBERS = 'company-members',
  ADMINISTRATORS = 'administrators',
  CONTRACTS = 'contracts',
  CONTACT = 'contact',
  PAYMENT_SETTINGS = 'payment-settings',
  SETUP_ACH_DIRECT_DEBIT = 'setup-ach-direct-debit',
  SETUP_SEPA_DIRECT_DEBIT = 'setup-sepa-direct-debit',
  ROLE_AND_PERMISSION = 'company-roles-and-permissions',
}
