import {
  Contract,
  ContractBenefit,
  useGetBenefitDetailsQuery,
} from '../../../__generated__/graphql';

const useGetInsuranceDetails = (): {
  loading: boolean;
  contractBenefit: ContractBenefit | undefined | null;
  contractId: Contract['id'];
  country: Contract['country'];
} => {
  const { data, loading: memberLoading } = useGetBenefitDetailsQuery();

  const contractBenefit = data?.member?.contracts?.[0]?.benefits?.[0];

  const loading = memberLoading;
  return {
    loading,
    contractBenefit,
    contractId: data?.member?.contracts?.[0]?.id,
    country: data?.member?.contracts?.[0]?.country,
  };
};

export default useGetInsuranceDetails;
