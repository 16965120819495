import React from 'react';

import { makeVar } from '@apollo/client';

import { BannerId } from 'app/services/banner-services';

export const bannerVar = makeVar<
  {
    id: BannerId;
    banner: React.ReactNode;
  }[]
>([]);

export { userVar } from '@multiplier/user';

export const hideOrganizationSettingsWidget = makeVar<boolean>(false);
