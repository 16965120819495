import { useMemo } from 'react';

import { Dictionary } from 'lodash';
import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';

import {
  Contract,
  ContractType,
  CountryCode,
  CountryWorkStatus,
  DataFieldDefinition,
  DomainType,
  FetchStage,
  GetMemberCountryRequirementsQueryVariables,
  LegalDataRequirementApplicability,
  LegalDocumentCategory,
  LegalDocumentRequirement,
  Maybe,
  useGetMemberCountryRequirementsLazyQuery,
} from '__generated__/graphql';

export const filterByWorkStatus = (
  applyTo?: Maybe<LegalDataRequirementApplicability>,
  workStatus?: Maybe<CountryWorkStatus>,
): boolean => {
  // Return docs with empty "applyTo" so can use for category filtering later
  if (!applyTo) return true;

  if (!workStatus)
    return (
      applyTo === LegalDataRequirementApplicability.LOCAL_ONLY ||
      applyTo === LegalDataRequirementApplicability.ALL
    );

  if (applyTo === LegalDataRequirementApplicability.ALL) return true;

  if (workStatus === CountryWorkStatus.RESIDENT) {
    return applyTo === LegalDataRequirementApplicability.LOCAL_ONLY;
  }

  return applyTo === LegalDataRequirementApplicability.EXPAT_ONLY;
};

const isDefinitionMatchingDomainTypes = (
  source: Maybe<DataFieldDefinition>,
  domainTypes: DataFieldDefinition['domainType'][],
) => !source || !domainTypes.length || domainTypes.includes(source.domainType);

function getFilterByDomainType(
  domainTypes: DataFieldDefinition['domainType'][],
) {
  return (source: Maybe<DataFieldDefinition>) =>
    isDefinitionMatchingDomainTypes(source, domainTypes);
}

export interface UseGetCountryLegalRequirementsForMemberProps {
  workStatus?: Contract['workStatus'];
  contractType?: Contract['type'];
  fetchStage?: GetMemberCountryRequirementsQueryVariables['fetchStage'];
  legalDataDomainTypes?: DataFieldDefinition['domainType'][];
  shouldIncludePayrollDocs?: boolean;
}

export interface MemberCountryLegalRequirements {
  memberLegalDataRequirements: Dictionary<DataFieldDefinition>;
  memberLegalDataDefinitionKeysToShow: Array<string>;
  memberDocumentRequirements: Maybe<Array<LegalDocumentRequirement>>;
}

export interface UseGetCountryLegalRequirementsForMemberResult {
  getRequirements: (
    country: CountryCode,
    contractType?: Contract['type'],
  ) => void;
  loading: boolean;
  requirements: MemberCountryLegalRequirements;
}

const useGetCountryLegalRequirementsForMember = (
  props: UseGetCountryLegalRequirementsForMemberProps,
): UseGetCountryLegalRequirementsForMemberResult => {
  const {
    workStatus,
    fetchStage,
    legalDataDomainTypes = [],
    shouldIncludePayrollDocs = false,
  } = {
    legalDataDomainTypes: [DomainType.LEGAL_DATA],
    fetchStage: FetchStage.MEMBER_LEGAL_DATA_CAPTURE,
    ...props,
  };

  const [
    queryRequirements,
    { data: { country } = { country: {} }, loading },
  ] = useGetMemberCountryRequirementsLazyQuery();

  const getRequirements = (
    selectedCountry: CountryCode,
    selectedContractType?: ContractType | null,
  ) => {
    queryRequirements({
      variables: {
        country: selectedCountry,
        contractType: selectedContractType,
        workStatus,
        fetchStage,
      },
    });
  };

  const memberDataRequirements = ((country?.compliance
    ?.memberDataRequirements || []) as DataFieldDefinition[]).filter(
    getFilterByDomainType(legalDataDomainTypes),
  );

  const memberLegalDataRequirements = flow(
    compact,
    keyBy<DataFieldDefinition>('key'),
  )(memberDataRequirements);

  const memberLegalDataDefinitionKeysToShow = compact(
    memberDataRequirements
      .filter(getFilterByDomainType(legalDataDomainTypes))
      .map((m) => m.key),
  );

  const memberDocumentRequirements = ((country?.memberDocumentRequirements ||
    []) as LegalDocumentRequirement[])
    .filter((field) => filterByWorkStatus(field?.applyTo, workStatus))
    .filter((field) => {
      if (!field.applyTo) {
        return shouldIncludePayrollDocs
          ? field.category === LegalDocumentCategory.PAYROLL
          : false;
      }
      return true;
    });

  const requirements = useMemo(
    () => ({
      memberLegalDataRequirements,
      memberLegalDataDefinitionKeysToShow,
      memberDocumentRequirements,
    }),
    [country],
  );

  return {
    loading,
    requirements,
    getRequirements,
  };
};

export default useGetCountryLegalRequirementsForMember;
