import React from 'react';
import 'twin.macro';

const TableCell: React.FC<React.HTMLProps<HTMLTableCellElement>> = ({
  ...props
}) => (
  <td
    tw="relative pr-large text-ps text-left whitespace-nowrap first-of-type:pl-large last-of-type:pr-large"
    {...props}
  />
);

export default TableCell;
