import { TFunction } from 'i18next';

import { ContractOnboardingStep } from '__generated__/graphql';

import ProgressWidgetContentVariant from '../../../models/progress-widget-content-variant';

const stepConfig: {
  [key: string]: {
    title: (t: TFunction<'contract-onboarding.member'>) => string;
    status: (t: TFunction<'contract-onboarding.member'>) => string;
    variant: ProgressWidgetContentVariant;
  };
} = {
  [ContractOnboardingStep.MEMBER_REVIEW]: {
    title: (t): string =>
      t('verification-phase.member-review.title', 'Review & Confirm'),
    status: (t): string =>
      t('verification-phase.member-review.status', 'Pending on You'),
    variant: ProgressWidgetContentVariant.EXPECT,
  },
  [ContractOnboardingStep.ONBOARDING_VERIFYING]: {
    title: (t): string =>
      t('verification-phase.onboarding-verifying.title', 'Verifying'),
    status: (t): string =>
      t(
        'verification-phase.onboarding-verifying.status',
        'Pending on Multiplier',
      ),
    variant: ProgressWidgetContentVariant.DESCRIPTION,
  },
  [ContractOnboardingStep.ONBOARDING_ACTIVATION]: {
    title: (t): string =>
      t('verification-phase.onboarding-activation.title', 'Activating'),
    status: (t): string =>
      t(
        'verification-phase.onboarding-activation.status',
        'Pending on Multiplier',
      ),
    variant: ProgressWidgetContentVariant.DESCRIPTION,
  },
};

export default stepConfig;
