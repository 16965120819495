import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from 'react';

import { StyledComponent } from '@emotion/styled';
import tw, { TwStyle, theme } from 'twin.macro';

import Card from '../card';
import CardSeparator from '../card-separator';
import {
  useRemoveSearchParams,
  useSearchParams,
  useUpdateSearchParams,
} from '../hooks/url';
import Icon from '../icon';
import IconBadge from '../icon-badge';
import IconButton, { IconButtonProps } from '../icon-button';
import { ThemeContext } from '../theme-provider';
import ToolTip from '../tooltip';

export interface AccordionProps {
  name: string;
  nameHelper?: React.ReactElement;
  description?: string | React.ReactElement;
  edit?: boolean;
  renderAction?: React.ReactElement | null;
  warning?: boolean;
  error?: boolean;
  editIcon?: string;
  editIconProps?: Partial<IconButtonProps>;
  editIconDisabled?: boolean;
  editIconTooltip?: string | null;
  icon?: string | React.ReactElement;
  iconFill?: string;
  iconStroke?: string;
  cta?: React.ReactElement | null;
  ctaOnExpand?: React.ReactElement | null;
  onEditClick?: () => void;
  onClick?: () => void;
  urlBinding?: boolean;
  enableExpand?: boolean;
  isExpanded?: boolean;
  swapHeaders?: boolean;
  nestedStyles?: TwStyle;
  open?: boolean;
  showSeparator?: boolean;
  iconStyle?: TwStyle;
  nameStyles?: TwStyle;
  descriptionStyles?: TwStyle;
  tooltipStyle?: TwStyle;
  isControlled?: boolean;
  id?: string;
}

export const AccordionComponent: React.FC<
  React.PropsWithChildren<AccordionProps>
> = ({
  name,
  nameHelper,
  description,
  renderAction,
  nestedStyles,
  edit = false,
  editIconDisabled = false,
  editIconTooltip,
  editIcon = 'pencil',
  editIconProps,
  icon = 'info',
  iconFill,
  iconStroke,
  warning = false,
  error = false,
  cta,
  ctaOnExpand,
  onClick,
  onEditClick,
  enableExpand = true,
  urlBinding = false,
  isExpanded = false,
  swapHeaders = false,
  showSeparator = true,
  open,
  children,
  iconStyle,
  nameStyles,
  descriptionStyles,
  tooltipStyle,
  isControlled = false,
  ...props
}) => {
  const updateSearchParams = useUpdateSearchParams();
  const removeSearchParams = useRemoveSearchParams();
  const [param] = useSearchParams(name.toLowerCase());
  const [showNested, setShowNested] = useState(isExpanded);

  const { isNewThemeApplied } = useContext(ThemeContext);

  const toggleExpand = () => {
    if (isControlled) return;
    if (enableExpand && !showNested) {
      setShowNested(true);
      if (urlBinding) {
        updateSearchParams([{ key: name.toLowerCase(), value: 'true' }]);
      }
    } else {
      setShowNested(false);
      if (urlBinding) {
        removeSearchParams([name.toLowerCase()]);
      }
    }
  };

  useEffect(() => {
    if (urlBinding && param === 'true') {
      setShowNested(true);
    }
  }, [urlBinding, param]);

  useEffect(() => {
    if (isControlled) {
      setShowNested(open ?? false);
      return;
    }
    if (open === false) setShowNested(false);
  }, [open]);

  const onClickHandler = () => {
    if (!isControlled) toggleExpand();
    if (onClick) onClick();
  };

  const handleEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!isControlled) setShowNested(true);
    if (onEditClick) onEditClick();
  };

  return (
    <div
      tw="flex flex-col gap-y-extra-large"
      data-testid="accordion"
      {...props}
    >
      <div
        aria-label={name}
        role="menuitem"
        tabIndex={0}
        onClick={() => (onClick ? onClickHandler() : toggleExpand())}
        onKeyPress={() => (onClick ? onClick() : toggleExpand())}
        tw="flex flex-row items-center cursor-pointer"
      >
        {icon && typeof icon === 'string' && (
          <IconBadge
            iconContainerStyle={[
              error
                ? tw`mr-base bg-background-negative-faded`
                : tw`mr-base bg-background-brand-faded`,
            ]}
            iconStyle={iconStyle}
            icon={icon}
            color={
              error
                ? theme`colors.icon-negative`
                : iconFill ??
                  (isNewThemeApplied ? undefined : theme`colors.foreground`)
            }
            stroke={iconStroke}
            badgeContent={
              warning ? (
                <Icon
                  name="error"
                  data-testid="info-icon-warning"
                  fill={
                    error
                      ? theme`colors.icon-negative`
                      : theme`colors.icon-warning`
                  }
                />
              ) : undefined
            }
            badgeStyles={warning ? tw`bg-background-white` : undefined}
          />
        )}

        {icon && typeof icon === 'object' && <div tw="mr-base">{icon}</div>}

        <div
          css={[
            tw`flex grow`,
            swapHeaders ? tw`flex-col-reverse` : tw`flex-col`,
          ]}
        >
          <h6
            css={[
              tw`flex items-center gap-x-extra-small text-text-primary text-h6 font-semibold`,
              nameStyles,
            ]}
          >
            {name}
            {nameHelper}
          </h6>
          {description && (
            <span css={[tw`text-ps text-text-tertiary`, descriptionStyles]}>
              {description}
            </span>
          )}
        </div>

        {!!renderAction && <div tw="mr-base">{renderAction}</div>}

        {edit && (
          <div tw="mr-base">
            <ToolTip content={editIconTooltip} styles={tooltipStyle}>
              <IconButton
                aria-label="edit"
                variant="outline"
                onClick={handleEditButtonClick}
                size="medium"
                name={editIcon}
                fill={isNewThemeApplied ? undefined : 'transparent'}
                stroke={
                  isNewThemeApplied ? undefined : theme`colors.icon-brand`
                }
                disabled={editIconDisabled}
                {...editIconProps}
              />
            </ToolTip>
          </div>
        )}

        {showNested ? ctaOnExpand : cta}

        {enableExpand && (
          <Icon
            tw="min-w-base"
            name={showNested ? 'caret-down' : 'caret-right'}
          />
        )}
      </div>

      {showNested && (
        <div data-testid="nested" css={nestedStyles}>
          {showSeparator && <CardSeparator tw="mb-large" />}

          <div tw="w-full">{children}</div>
        </div>
      )}
    </div>
  );
};

const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> & {
  Container: StyledComponent<
    any,
    DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  >;
} = (props) => <AccordionComponent {...props} />;

Accordion.Container = tw(
  Card,
)`flex flex-col p-extra-large gap-y-extra-large mb-base`;

export default Accordion;
