/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@multiplier/common';
import tw from 'twin.macro';

import { ComplianceMultiplierEor } from '__generated__/graphql';

interface ContractTemplateViewerProps {
  compliance?: ComplianceMultiplierEor | null;
}

export const ContractTemplateViewer: React.FC<ContractTemplateViewerProps> = ({
  compliance,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  if (!compliance?.preferredContractAgreementTemplate?.template) {
    return null;
  }

  const templateName =
    compliance.preferredContractAgreementTemplate.template?.displayName;

  return (
    <div tw="flex flex-col gap-y-small">
      <div tw="text-ps text-text-primary font-semibold">
        {t(
          'onboarding-phase.contract.contract-template.header',
          'Contract Template',
        )}
      </div>
      <div tw="flex flex-row gap-x-small p-large bg-background-primary rounded-tiny">
        <div tw="flex gap-small text-text-primary text-ps items-center">
          <Icon css={tw`h-48 w-48`} name="pdf-file" />
          <div> {templateName}</div>
        </div>
      </div>
    </div>
  );
};

export default ContractTemplateViewer;
