/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useSorOnboardingContext } from '@multiplier/hris-member-management';

import { Experience } from 'app/models/module-config';
import Loader from 'common/components/loader';
import ContractMemberOnboardingView from 'contract-onboarding/member/view';

import {
  ContractOnboardingStep,
  ContractStatus,
  useGetMemberQuery,
} from '__generated__/graphql';

import { useShouldRedirectOnboarding } from '../../hooks';

export const onboardingWrapper = (
  WrappedComponent: React.FC<{ experience: Experience }>,
  experience: Experience,
): React.FC<{ experience: Experience }> => () => (
  <OnboardingWrapper>
    <WrappedComponent experience={experience} />
  </OnboardingWrapper>
);

const OnboardingWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: { member } = { member: {} }, loading } = useGetMemberQuery();
  const contract = useMemo(() => member?.contracts?.[0], [member]);
  const sorOnboardingContext = useSorOnboardingContext({
    type: contract?.type,
    companyId: contract?.company?.id,
    legalEntityId: contract?.legalEntityId,
  });
  useShouldRedirectOnboarding(member, sorOnboardingContext);

  const showOnboarding =
    contract?.status === ContractStatus.ONBOARDING &&
    contract?.onboarding?.current &&
    ![
      ContractOnboardingStep.ONBOARDING_ACTIVATION,
      ContractOnboardingStep.ONBOARDING_VERIFYING,
    ].includes(contract.onboarding.current);

  if (loading)
    return <Loader.Spinner variant={Loader.SpinnerType.EXPERIENCE} />;

  if (showOnboarding) {
    return (
      <div data-testid="member-onboarding">
        <Routes>
          <Route
            path="member-onboard/*"
            element={<ContractMemberOnboardingView />}
          />
          <Route path="*" element={<Navigate to="member-onboard" replace />} />
        </Routes>
      </div>
    );
  }

  return <>{children}</>;
};

export default OnboardingWrapper;
