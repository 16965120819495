/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';

import { useFeature } from '@growthbook/growthbook-react';
import { AppFeature } from '@multiplier/growthbook';
import { CompensationPayComponent } from '@multiplier/user/dist/types/__generated__/graphql';

import { notEmpty } from 'app/utils/array';
import { AdditionalPayFormValues } from 'contract-onboarding/company/components/additional-pay-form';
import useGetCountryAllowancesConfig from 'contract-onboarding/company/hooks/get-country-allowances-config';
import {
  mapCompensationPayComponentToAdditionalFormValue,
  mapCountry13th14thMonthPayToAdditionalFormValue,
} from 'contract-onboarding/company/services/compensation';

import {
  Contract,
  ContractType,
  CountryCode,
  CountryCompliance,
  FixedPayComponent,
  GetCountryAllowancesConfigQuery,
  Maybe,
  Scalars,
  useGetCountry13th14thMonthPayLazyQuery,
} from '__generated__/graphql';

export interface AdditionalCompensationMapOptions {
  overrideAmount?: Maybe<Scalars['Float']>;
  isNotFreelancer?: boolean;
  isNewAllowancesConfigEnabled: boolean;
  countryAllowancesConfig:
    | GetCountryAllowancesConfigQuery['countryAllowancesConfig']
    | undefined;
}

export interface AdditionalCompensationContractOptions {
  country?: Contract['country'];
  countryStateCode?: Contract['countryStateCode'];
  type?: Contract['type'];
}

export interface UseGetAdditionalCompensationsMapperProps {
  contract?: AdditionalCompensationContractOptions;
  mapOptions?: AdditionalCompensationMapOptions;
}

export type AdditionalCompensationsMapperSource =
  | CompensationPayComponent
  | FixedPayComponent;

export interface UseGetAdditionalCompensationsMapperResult {
  mapToAdditionalPayFormValues: (
    values?: Maybe<Maybe<AdditionalCompensationsMapperSource>[]>,
    options?: AdditionalCompensationMapOptions,
  ) => AdditionalPayFormValues[];
  fetchingCountry13th14thMonthPay?: boolean;
  fetchingCountryAllowancesConfig?: boolean;
}

const filterNonHoliday = (value?: Maybe<AdditionalCompensationsMapperSource>) =>
  notEmpty(value) &&
  value.name &&
  !['13thMonth', '14thMonth', 'thr'].includes(value.name);

const mapNonHoliday = (
  value: AdditionalCompensationsMapperSource,
  options: AdditionalCompensationMapOptions,
) =>
  mapCompensationPayComponentToAdditionalFormValue({
    payItem: value,
    overrideAmount: options?.overrideAmount || undefined,
    isNotFreelancer: options?.isNotFreelancer || undefined,
    isNewAllowancesConfigEnabled:
      options?.isNewAllowancesConfigEnabled || false,
    countryAllowancesConfig: options?.countryAllowancesConfig,
  });

const mapHoliday = (config: CountryCompliance['country13th14thMonthPay']) =>
  mapCountry13th14thMonthPayToAdditionalFormValue(config);

const getRequestVariables = (contract?: Contract) => ({
  country: contract?.country as CountryCode,
  countryStateCode: contract?.countryStateCode,
  contractType:
    contract?.type === ContractType.CONTRACTOR
      ? ContractType.FREELANCER
      : (contract?.type as ContractType),
});

export const useIsNewAllowancesConfigEnabled = ({
  type,
}: {
  type?: Contract['type'];
}): boolean => {
  const singleOnboardingEnabled = useFeature(AppFeature.SINGLE_ONBOARDING).on;
  const sorOnboardingEnabled = useFeature(AppFeature.SOR_ONBOARDING).on;

  return (
    type === ContractType.HR_MEMBER &&
    (sorOnboardingEnabled || singleOnboardingEnabled)
  );
};

export const useAdditionalCompensationsMapper = ({
  contract,
  mapOptions,
}: UseGetAdditionalCompensationsMapperProps): UseGetAdditionalCompensationsMapperResult => {
  const isNotFreelancer = contract?.type !== ContractType.FREELANCER;
  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: contract?.type,
  });

  const {
    countryAllowancesConfig,
    loading: fetchingCountryAllowancesConfig,
  } = useGetCountryAllowancesConfig({
    country: contract?.country,
    isNewAllowancesConfigEnabled,
  });

  const [
    get,
    { data, loading: fetchingCountry13th14thMonthPay },
  ] = useGetCountry13th14thMonthPayLazyQuery();

  useEffect(() => {
    if (
      !contract?.country ||
      contract?.type === ContractType.FREELANCER ||
      contract?.type === ContractType.CONTRACTOR
    ) {
      return;
    }

    get({ variables: getRequestVariables(contract) });
  }, [contract]);

  const mapToAdditionalPayFormValues = useCallback(
    (
      values?: Maybe<Maybe<AdditionalCompensationsMapperSource>[]>,
      options?: AdditionalCompensationMapOptions,
    ) => [
      ...(values?.filter(filterNonHoliday)?.map((m) =>
        mapNonHoliday(m as AdditionalCompensationsMapperSource, {
          ...{
            isNotFreelancer,
            isNewAllowancesConfigEnabled,
            countryAllowancesConfig,
          },
          ...(mapOptions || {}),
          ...(options || {}),
        }),
      ) || []),
      ...(mapHoliday(data?.country?.compliance?.country13th14thMonthPay) || []),
    ],
    [
      data,
      isNotFreelancer,
      isNewAllowancesConfigEnabled,
      countryAllowancesConfig,
    ],
  );

  return {
    mapToAdditionalPayFormValues,
    fetchingCountry13th14thMonthPay,
    fetchingCountryAllowancesConfig,
  };
};
