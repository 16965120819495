import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { CountryWorkStatus } from '__generated__/graphql';

import {
  ContractOnboardingStatusConfig,
  isVisaWOrkflowEnabled,
} from '../status-config';

const memberVerificationCompleted: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.member-verification-completed.title', {
      defaultValue: 'We are verifying the details',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.member-verification-completed.description', {
      defaultValue:
        "We have verified {{ firstName }}'s details, awaiting ACTIVE",
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t) =>
    t(
      'onboarding-phase.member-verification-completed.label',
      'Verification complete',
    ),
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.member-verification-completed.status',
      'Pending on Multiplier',
    ),
  allowRevoke: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
  allowDelete: (contractWorkStatus) =>
    !(
      contractWorkStatus === CountryWorkStatus.REQUIRES_VISA &&
      isVisaWOrkflowEnabled()
    ),
};

export default memberVerificationCompleted;
