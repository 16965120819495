/** @jsxImportSource @emotion/react */
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import growthBook, { AppFeature } from '@multiplier/growthbook';
import { SorOnboardingContext } from '@multiplier/hris-member-management';
import { TFunction } from 'i18next';
import 'twin.macro';

import OnboardingAction from 'contract-onboarding/models/onboarding-action';

import {
  Contract,
  ContractOnboardingStatus,
  ContractType,
  CountryWorkStatus,
  Maybe,
  Member,
} from '__generated__/graphql';

import { PendingOn } from '../../../components/pending-on-pill';
import contractPreparing from './onboarding-status-config/contract-preparing';
import contractPreparingConfirmation from './onboarding-status-config/contract-preparing-confirmation';
import contractUpdating from './onboarding-status-config/contract-updating';
import contractWetInkPreparing from './onboarding-status-config/contract-wet-ink-preparing';
import created from './onboarding-status-config/created';
import createdCustom from './onboarding-status-config/created-custom';
import memberCompleted from './onboarding-status-config/member-completed';
import memberDataAdded from './onboarding-status-config/member-data-added';
import memberDataReviewed from './onboarding-status-config/member-data-reviewed';
import memberInvitePending from './onboarding-status-config/member-invite-pending';
import memberInvited from './onboarding-status-config/member-invited';
import memberStarted from './onboarding-status-config/member-started';
import memberVerificationCompleted from './onboarding-status-config/member-verification-completed';
import memberVerificationInProgress from './onboarding-status-config/member-verification-in-progress';
import orderFormSentToEmployer from './onboarding-status-config/order-form-sent-to-employer';
import orderFormSignedByEmployer from './onboarding-status-config/order-form-signed-by-employer';
import preparingContract from './onboarding-status-config/preparing-contract';
import revoked from './onboarding-status-config/revoked';
import signatureEmployeeSent from './onboarding-status-config/signature-employee-sent';
import signatureEmployeeSigned from './onboarding-status-config/signature-employee-signed';
import signatureEmployerSent from './onboarding-status-config/signature-employer-sent';
import signatureEmployerSigned from './onboarding-status-config/signature-employer-signed';
import visaApplicationApproved from './onboarding-status-config/visa-application-approved';
import visaApplicationFiling from './onboarding-status-config/visa-application-filing';
import visaApplicationPreparing from './onboarding-status-config/visa-application-preparing';
import visaDocumentsStarted from './onboarding-status-config/visa-documents-started';
import visaDocumentsSubmitted from './onboarding-status-config/visa-documents-submitted';
import visaDocumentsVerified from './onboarding-status-config/visa-documents-verified';
import visaInvoicePaid from './onboarding-status-config/visa-invoice-paid';

export type ContractOnboardingStatusConfig = {
  title: (
    t: TFunction<'contract-onboarding.company'>,
    name?: string,
    contractType?: Contract['type'],
    contractWorkStatus?: Maybe<CountryWorkStatus>,
  ) => string;
  subtitle?: (
    t: TFunction<'contract-onboarding.company'>,
    name?: string,
  ) => string;
  description: (
    t: TFunction<'contract-onboarding.company'>,
    name?: string,
    gender?: Member['gender'],
    country?: string,
    contractType?: Maybe<ContractType>,
    contractStatus?: Maybe<CountryWorkStatus>,
    sorOnboardingContext?: Maybe<SorOnboardingContext>,
  ) => string | EmotionJSX.Element;
  statusLabel: (
    t: TFunction<'contract-onboarding.company'>,
    contractType?: Maybe<ContractType>,
    name?: string,
  ) => string;
  pendingOn: (contractType?: Maybe<ContractType>) => PendingOn;
  pendingLabel: (
    t: TFunction<'contract-onboarding.company'>,
    contractType?: Contract['type'],
  ) => string;
  primaryAction?: OnboardingAction;
  optionalAction?: OnboardingAction;
  allowRevoke?: (contractWorkStatus?: Maybe<CountryWorkStatus>) => boolean;
  allowDelete?: (contractWorkStatus?: Maybe<CountryWorkStatus>) => boolean;
};

export const isVisaWOrkflowEnabled = (): boolean =>
  growthBook.isOn('contract.onboarding.visa-workflow');

export const isWetInkFlowEnabled = (): boolean =>
  growthBook.isOn(AppFeature.CONTRACT_ONBOARDING_WET_INK_ENABLED);

export const isWetInkSignatureEnabled = (): boolean =>
  growthBook.isOn(AppFeature.CONTRACT_ONBOARDING_WITH_WET_INK_ENABLED);

export const isNewGlobalPayrollFlowEnabled = (): boolean =>
  growthBook.isOn(AppFeature.SINGLE_ONBOARDING);

const statusConfig: {
  [key: string]: ContractOnboardingStatusConfig;
} = {
  [ContractOnboardingStatus.CREATED]: created,
  [ContractOnboardingStatus.CREATED_CUSTOM]: createdCustom,
  [ContractOnboardingStatus.REVOKED]: revoked,
  [ContractOnboardingStatus.CONTRACT_PREPARING]: contractPreparing,
  [ContractOnboardingStatus.CONTRACT_PREPARING_CONFIRMATION]: contractPreparingConfirmation,
  [ContractOnboardingStatus.CONTRACT_WET_INK_PREPARING]: contractWetInkPreparing,
  [ContractOnboardingStatus.CONTRACT_UPDATING]: contractUpdating,
  [ContractOnboardingStatus.SIGNATURE_EMPLOYER_SENT]: signatureEmployerSent,
  [ContractOnboardingStatus.SIGNATURE_EMPLOYER_SIGNED]: signatureEmployerSigned,
  [ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SENT]: signatureEmployeeSent,
  [ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SIGNED]: signatureEmployeeSigned,
  [ContractOnboardingStatus.MEMBER_INVITED]: memberInvited,
  [ContractOnboardingStatus.VISA_DOCUMENTS_STARTED]: visaDocumentsStarted,
  [ContractOnboardingStatus.VISA_DOCUMENTS_SUBMITTED]: visaDocumentsSubmitted,
  [ContractOnboardingStatus.VISA_DOCUMENTS_VERIFIED]: visaDocumentsVerified,
  [ContractOnboardingStatus.VISA_INVOICE_PAID]: visaInvoicePaid,
  [ContractOnboardingStatus.VISA_APPLICATION_PREPARING]: visaApplicationPreparing,
  [ContractOnboardingStatus.VISA_APPLICATION_FILING]: visaApplicationFiling,
  [ContractOnboardingStatus.VISA_APPLICATION_APPROVED]: visaApplicationApproved,
  [ContractOnboardingStatus.MEMBER_STARTED]: memberStarted,
  [ContractOnboardingStatus.MEMBER_DATA_ADDED]: memberDataAdded,
  [ContractOnboardingStatus.MEMBER_DATA_REVIEWED]: memberDataReviewed,
  [ContractOnboardingStatus.MEMBER_COMPLETED]: memberCompleted,
  [ContractOnboardingStatus.MEMBER_VERIFICATION_IN_PROGRESS]: memberVerificationInProgress,
  [ContractOnboardingStatus.MEMBER_VERIFICATION_COMPLETED]: memberVerificationCompleted,
  [ContractOnboardingStatus.PREPARING_CONTRACT]: preparingContract,
  [ContractOnboardingStatus.ORDER_FORM_SENT_TO_EMPLOYER]: orderFormSentToEmployer,
  [ContractOnboardingStatus.ORDER_FORM_SIGNED_BY_EMPLOYER]: orderFormSignedByEmployer,
  MEMBER_INVITE_PENDING: memberInvitePending,
};

export default statusConfig;
