export enum StepperType {
  PROGRESS = 'PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
  DEFAULT = 'DEFAULT',
}

export enum StepperPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
