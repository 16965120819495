import { gql } from '@apollo/client';

export default gql`
  mutation SsoSignUp($input: SSOInput) {
    ssoSignUp(input: $input)
      @rest(
        type: "JwtResponse"
        method: "POST"
        path: "sso-signup"
        endpoint: "user"
      ) {
      id_token
    }
  }
`;
