/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import 'twin.macro';

import { unescape } from 'lodash';

import * as format from 'app/utils/format';
import { numberAsCurrency } from 'app/utils/format';
import { assureNumber } from 'app/utils/number';
import { Religion } from 'common/constants/default-labels';
import monthNames from 'common/constants/month-names';
import useGetCountryAllowancesConfig from 'contract-onboarding/company/hooks/get-country-allowances-config';
import { mapRateFrequencyToNumberOfMonths } from 'contract-onboarding/company/services/compensation';
import getMatchedHrAllowanceConfig from 'contract-onboarding/company/services/get-matched-hr-allowance-config';
import { useIsNewAllowancesConfigEnabled } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Company,
  Contract,
  ContractType,
  Maybe,
  RateFrequency,
} from '__generated__/graphql';

import {
  AdditionalPayFormValues,
  Occurrence,
  PaymentType,
  mapFrequency,
} from '../../../additional-pay-form';

const AdditionalPayTitle: React.FC<{
  payItem: AdditionalPayFormValues;
  companyName: Company['displayName'];
  religion?: Maybe<Religion>;
  contractType: Contract['type'];
  countryCode: Contract['country'];
}> = ({ payItem, companyName, religion, contractType, countryCode }) => {
  const { t, i18n } = useTranslation('contract-onboarding.company');
  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: contractType,
  });

  const { countryAllowancesConfig } = useGetCountryAllowancesConfig({
    country: countryCode,
    isNewAllowancesConfigEnabled,
  });

  const matchedHrAllowanceConfig = getMatchedHrAllowanceConfig({
    isNewAllowancesConfigEnabled,
    countryAllowancesConfig,
    payName: payItem.name,
    payLabel: payItem.label,
  });

  const {
    payType,
    name,
    label,
    defaultDisplayLabel,
    occurrence,
    frequency: frequencyList,
    payoutMonth: { month, year } = { month: undefined, year: undefined },
    amount,
    currency,
    payInInstallments,
    instalments,
    title,
  } = payItem;

  const totalJoiningBonusFromInstallments = useMemo(() => {
    if (instalments && instalments?.length > 0) {
      const totalBonus = instalments.reduce(
        (bonus, instalment) =>
          instalment?.amount ? bonus + instalment?.amount : bonus,
        0,
      );
      return numberAsCurrency(i18n.language, totalBonus, 2, 0);
    }

    return 0;
  }, [JSON.stringify(instalments)]);

  const totalInstalments = instalments && instalments?.length;

  const instalmentsPeriod = useMemo(() => {
    if (instalments && instalments?.length > 0) {
      const allPeriods = instalments.reduce(
        (period, instalment, index) =>
          instalment?.amount &&
          instalment?.payOn?.month !== null &&
          instalment?.payOn?.month !== undefined &&
          instalment?.payOn?.year !== null
            ? `${period}${totalInstalments === index + 1 ? ` and ` : ``}${
                index !== 0 && index + 1 !== totalInstalments ? `, ` : ``
              }${Object.values(monthNames)[instalment?.payOn?.month]} ${
                instalment?.payOn?.year
              }`
            : period,
        '',
      );
      return allPeriods;
    }

    return '';
  }, [JSON.stringify(instalments)]);

  const frequency:
    | number
    | Maybe<RateFrequency>
    | string
    | undefined = isNotFreelancer
    ? mapFrequency({
        frequency: mapRateFrequencyToNumberOfMonths(
          frequencyList as RateFrequency,
        ),
      })
    : frequencyList;

  const freqCount = assureNumber(frequencyList);

  const monthName = useMemo(
    () =>
      month != null && Number.isInteger(month)
        ? Object.values(monthNames)[month]
        : '',
    [month],
  );

  const formattedAmount = useMemo(
    () => (amount ? format.numberAsCurrency(i18n.language, amount, 2, 0) : 0),
    [amount],
  );

  if (matchedHrAllowanceConfig) {
    const payName = t(
      `additional-pay.label.hr-allowances.${label}-label`,
      unescape(defaultDisplayLabel ?? name),
    );

    return (
      <Trans
        i18nKey="additional-compensation.table-label.allowances-for-hr-member"
        t={t}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        count={freqCount}
        values={{
          payName,
          currency,
          formattedAmount,
          frequency,
          monthName,
          year,
        }}
      >
        <p data-testid="allowances">
          <strong>{{ payName }}</strong> of&nbsp;
          <strong>
            {{ currency }} {{ formattedAmount }}
          </strong>{' '}
          paid out on
          <strong> {{ frequency }}</strong>
        </p>
      </Trans>
    );
  }

  if (isNewAllowancesConfigEnabled) {
    if (payType === PaymentType.ALLOWANCES) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.allowances-hr-member"
          t={t}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          count={freqCount}
          values={{
            name: unescape(name),
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="allowances">
            <strong>{{ name }} Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on
            <strong> {{ frequency }}</strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.DE_MINIMIS_ALLOWANCE) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.de-minimis-allowances-hr-member"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="de-minimis-allowances">
            <strong>De Minimis Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on
            <strong> {{ frequency }}</strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.INTERNET_ALLOWANCE) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.internet-allowances-hr-member"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="internet-allowances">
            <strong>Internet Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on
            <strong> {{ frequency }}</strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.MOBILE_AND_PHONE_ALLOWANCE) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.mobile-and-phone-allowances-hr-member"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="mobile-and-phone-allowances">
            <strong>Mobile And Phone Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on
            <strong> {{ frequency }}</strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.OTHER && occurrence === Occurrence.RECURRING) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.other-recurring-hr-member"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            name: unescape(name),
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="other-recurring">
            <strong>{{ name }}</strong> paid out on
            <strong> {{ frequency }} </strong>
          </p>
        </Trans>
      );
    }
  }

  if (isNotFreelancer && !isNewAllowancesConfigEnabled) {
    if (payType === PaymentType.VARIABLE_PERFORMANCE_BONUS) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.variable-performance-bonus-feature-on"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="variable-performance-bonus">
            <strong>Variable Performance Bonus</strong>
            paid out on&nbsp;
            <strong> {{ frequency }}</strong> starting from&nbsp;
            <strong>
              {{ monthName }} {{ year }}
            </strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.ALLOWANCES) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.allowances-feature-on"
          t={t}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          count={freqCount}
          values={{
            name: unescape(name),
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="allowances">
            <strong>{{ name }} Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on&nbsp;
            <strong> {{ frequency }}</strong>{' '}
            {monthName && year ? `starting from` : ``}&nbsp;
            <strong>
              {{ monthName }} {{ year }}
            </strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.DE_MINIMIS_ALLOWANCE) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.de-minimis-allowances-feature-on"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="de-minimis-allowances">
            <strong>De Minimis Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on&nbsp;
            <strong> {{ frequency }}</strong>{' '}
            {monthName && year ? `starting from` : ``}&nbsp;
            <strong>
              {{ monthName }} {{ year }}
            </strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.INTERNET_ALLOWANCE) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.internet-allowances-feature-on"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="internet-allowances">
            <strong>Internet Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on&nbsp;
            <strong> {{ frequency }}</strong>{' '}
            {monthName && year ? `starting from` : ``}&nbsp;
            <strong>
              {{ monthName }} {{ year }}
            </strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.MOBILE_AND_PHONE_ALLOWANCE) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.mobile-and-phone-allowances-feature-on"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            currency,
            formattedAmount,
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="mobile-and-phone-allowances">
            <strong>Mobile And Phone Allowances</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid out on&nbsp;
            <strong> {{ frequency }}</strong>{' '}
            {monthName && year ? `starting from` : ``}&nbsp;
            <strong>
              {{ monthName }} {{ year }}
            </strong>
          </p>
        </Trans>
      );
    }

    if (payType === PaymentType.OTHER && occurrence === Occurrence.RECURRING) {
      return (
        <Trans
          i18nKey="additional-compensation.table-label.other-recurring-feature-on"
          t={t}
          count={freqCount}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
          values={{
            name: unescape(name),
            frequency,
            monthName,
            year,
          }}
        >
          <p data-testid="other-recurring">
            <strong>{{ name }}</strong> paid out on&nbsp;
            <strong> {{ frequency }} </strong>{' '}
            {monthName && year ? `starting from` : ``}&nbsp;
            <strong>
              {{ monthName }} {{ year }}
            </strong>
          </p>
        </Trans>
      );
    }
  }

  if (payType === PaymentType.JOINING_BONUS) {
    return (
      <>
        {payInInstallments ? (
          <Trans
            i18nKey="additional-compensation.table-label.joining-bonus-payInInstallments"
            t={t}
          >
            <p data-testid="joining-bonus">
              <strong>Joining Bonus</strong> of&nbsp;
              <strong>
                {{ currency }} {{ totalJoiningBonusFromInstallments }}
              </strong>{' '}
              split in <strong>{{ totalInstalments }} instalments </strong>
              paid on <strong>{{ instalmentsPeriod }}</strong>
            </p>
          </Trans>
        ) : (
          <Trans
            i18nKey="additional-compensation.table-label.joining-bonus"
            t={t}
          >
            <p data-testid="joining-bonus">
              <strong>Joining Bonus</strong> of&nbsp;
              <strong>
                {{ currency }} {{ formattedAmount }}
              </strong>{' '}
              paid out on&nbsp;
              <strong>
                {{ monthName }} {{ year }}
              </strong>
            </p>
          </Trans>
        )}
      </>
    );
  }

  if (payType === PaymentType.VARIABLE_PERFORMANCE_BONUS) {
    return (
      <Trans
        i18nKey="additional-compensation.table-label.variable-performance-bonus"
        t={t}
        count={freqCount}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        values={{
          frequency,
          monthName,
          year,
        }}
      >
        <p data-testid="variable-performance-bonus">
          <strong>Variable Performance Bonus</strong>
          paid out on&nbsp;
          <strong>every {{ frequency }} month </strong> starting from&nbsp;
          <strong>
            {{ monthName }} {{ year }}
          </strong>
        </p>
      </Trans>
    );
  }

  if (payType === PaymentType.ALLOWANCES) {
    return (
      <Trans
        i18nKey="additional-compensation.table-label.allowances"
        t={t}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        count={freqCount}
        values={{
          name: unescape(name),
          currency,
          formattedAmount,
          frequency,
          monthName,
          year,
        }}
      >
        <p data-testid="allowances">
          <strong>{{ name }} Allowances</strong> of&nbsp;
          <strong>
            {{ currency }} {{ formattedAmount }}
          </strong>{' '}
          paid out on&nbsp;
          <strong>every {{ frequency }} month </strong>{' '}
          {monthName && year ? `starting from` : ``}&nbsp;
          <strong>
            {{ monthName }} {{ year }}
          </strong>
        </p>
      </Trans>
    );
  }

  if (payType === PaymentType.DE_MINIMIS_ALLOWANCE) {
    return (
      <Trans
        i18nKey="additional-compensation.table-label.de-minimis-allowances"
        t={t}
        count={freqCount}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        values={{
          currency,
          formattedAmount,
          frequency,
          monthName,
          year,
        }}
      >
        <p data-testid="de-minimis-allowances">
          <strong>De Minimis Allowances</strong> of&nbsp;
          <strong>
            {{ currency }} {{ formattedAmount }}
          </strong>{' '}
          paid out on&nbsp;
          <strong>every {{ frequency }} month </strong>{' '}
          {monthName && year ? `starting from` : ``}&nbsp;
          <strong>
            {{ monthName }} {{ year }}
          </strong>
        </p>
      </Trans>
    );
  }

  if (payType === PaymentType.INTERNET_ALLOWANCE) {
    return (
      <Trans
        i18nKey="additional-compensation.table-label.internet-allowances"
        t={t}
        count={freqCount}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        values={{
          currency,
          formattedAmount,
          frequency,
          monthName,
          year,
        }}
      >
        <p data-testid="internet-allowances">
          <strong>Internet Allowances</strong> of&nbsp;
          <strong>
            {{ currency }} {{ formattedAmount }}
          </strong>{' '}
          paid out on&nbsp;
          <strong>every {{ frequency }} month </strong>{' '}
          {monthName && year ? `starting from` : ``}&nbsp;
          <strong>
            {{ monthName }} {{ year }}
          </strong>
        </p>
      </Trans>
    );
  }

  if (payType === PaymentType.MOBILE_AND_PHONE_ALLOWANCE) {
    return (
      <Trans
        i18nKey="additional-compensation.table-label.mobile-and-phone-allowances"
        t={t}
        count={freqCount}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        values={{
          currency,
          formattedAmount,
          frequency,
          monthName,
          year,
        }}
      >
        <p data-testid="mobile-and-phone-allowances">
          <strong>Mobile And Phone Allowances</strong> of&nbsp;
          <strong>
            {{ currency }} {{ formattedAmount }}
          </strong>{' '}
          paid out on&nbsp;
          <strong>every {{ frequency }} month </strong>{' '}
          {monthName && year ? `starting from` : ``}&nbsp;
          <strong>
            {{ monthName }} {{ year }}
          </strong>
        </p>
      </Trans>
    );
  }

  if (payType === PaymentType.OTHER && occurrence === Occurrence.ONCE) {
    return (
      <Trans
        i18nKey="additional-compensation.table-label.other-once"
        t={t}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        values={{
          name: unescape(name),
          monthName,
          year,
        }}
      >
        <p data-testid="other-once">
          <strong>{{ name }}</strong> paid out on&nbsp;
          <strong>
            {{ monthName }} {{ year }}
          </strong>
        </p>
      </Trans>
    );
  }

  if (payType === PaymentType.OTHER && occurrence === Occurrence.RECURRING) {
    return (
      <Trans
        i18nKey="additional-compensation.table-label.other-recurring"
        t={t}
        count={freqCount}
        tOptions={{
          interpolation: { escapeValue: false },
        }}
        values={{
          name: unescape(name),
          frequency,
          monthName,
          year,
        }}
      >
        <p data-testid="other-recurring">
          <strong>{{ name }}</strong>
          paid out on&nbsp;
          <strong>every {{ frequency }} month </strong>{' '}
          {monthName && year ? `starting from` : ``}&nbsp;
          <strong>
            {{ monthName }} {{ year }}
          </strong>
        </p>
      </Trans>
    );
  }

  if (payType === PaymentType.THR_BONUS) {
    return (
      <div>
        <Trans i18nKey="additional-compensation.table-label.thr-bonus" t={t}>
          <p data-testid="thr-bonus">
            <strong>THR Bonus</strong> of&nbsp;
            <strong>
              {{ currency }} {{ formattedAmount }}
            </strong>{' '}
            paid during{' '}
            {{
              payOn:
                religion === Religion.ISLAM
                  ? t(
                      'additional-compensation.pay-on-month.thr.islam',
                      'Ramadan Month',
                    )
                  : t(
                      'additional-compensation.pay-on-month.thr.other',
                      'Christmas Month',
                    ),
            }}
          </p>
        </Trans>

        <p tw="text-ps text-grey01">
          {t(
            'additional-compensation.table-description.thr-bonus',
            'Religious bonus is paid prorated and is mandated by the country’s labor laws. The payout month shall be decided by {{ companyName }}',
            { replace: { companyName }, interpolation: { escapeValue: false } },
          )}
        </p>
      </div>
    );
  }

  if (
    (payType === PaymentType.THIRTEENTH_MONTH_BONUS ||
      payType === PaymentType.FOURTEENTH_MONTH_BONUS) &&
    title
  ) {
    return <>{title}</>;
  }

  return null;
};

export default AdditionalPayTitle;
