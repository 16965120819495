/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, PillTag, useModal } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import Loader from 'common/components/loader';

import {
  BlockSource,
  Contract,
  useGetConfigurationQuery,
  useGetContractChangeRequestLazyQuery,
} from '__generated__/graphql';

import NewStartDateMessage from './new-start-date-message';
import RequestChangesModal from './request-changes-modal';

interface ViewRequestedChangesProps {
  unresolvedBlockEvents?: Contract['unresolvedBlockEvents'];
  contractId: Contract['id'];
}

interface RequestedChangesContentBlockProps {
  showRequestedChangesButton?: boolean;
  handleViewRequestedChangesClick?: () => void;
  contractId: Contract['id'];
}

const MainContainer = tw.div`
    flex flex-col w-full
`;

const Header = tw.div`
    flex flex-row justify-between w-full
`;

const Heading = tw.span`
    flex text-p text-background font-semibold
`;

const ContentBody = tw.div`
    flex justify-between
`;

const Content = tw.p`
  flex flex-col justify-between gap-12 text-ps font-medium text-grey01 max-w-[420px] ml-[40px] mt-4
`;

export const RequestedChangesContentBlock: React.FC<RequestedChangesContentBlockProps> = ({
  showRequestedChangesButton = false,
  handleViewRequestedChangesClick,
  contractId,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const { data } = useGetConfigurationQuery({
    variables: {
      filters: {
        contractId,
      },
    },
  });

  const tatInHours =
    data?.configuration?.contractChangeRequestConfig?.turnAroundTimeInHours;
  const tatInDays = tatInHours && tatInHours / 24;

  return (
    <>
      <Header>
        <span tw="flex">
          <Icon
            name="check-colored"
            fill={theme`colors.background`}
            tw="h-24 w-24 mr-16"
          />
          <Heading>
            {t(
              'view-requested-changes.heading',
              'Thank you for submitting a request for changes.',
            )}
          </Heading>
        </span>
        <PillTag
          tw="bg-warning text-warning02"
          label={t('view-requested-changes.under-review', 'Under Review')}
          variant="PREPROCESSING"
        />
      </Header>
      <ContentBody>
        {tatInDays == null ? (
          <Loader.Spinner
            variant={Loader.SpinnerType.CUSTOM_LAYOUT}
            tw="h-[100%]"
          />
        ) : (
          <Content>
            <div>
              {t('view-requested-changes.contentMessage', {
                defaultValue:
                  'Please allow us a minimum of {{tatInDays}} days for contract review and updates as per labor laws. Our rep may contact you for clarifications; respond promptly.',
                replace: {
                  tatInDays,
                },
              })}
            </div>
            <NewStartDateMessage contractId={contractId} />
          </Content>
        )}
        {showRequestedChangesButton && (
          <button
            type="button"
            tw="flex flex-col-reverse text-ps font-semibold text-primary underline"
            onClick={handleViewRequestedChangesClick}
          >
            {t('view-requested-changes.button', 'View requested changes')}
          </button>
        )}
      </ContentBody>
    </>
  );
};

const ViewRequestedChanges: React.FC<ViewRequestedChangesProps> = ({
  unresolvedBlockEvents,
  contractId,
}) => {
  const [
    showRequestChanges,
    closeRequestChangesModal,
    openRequestChangesModal,
  ] = useModal();

  const [
    getContractChangeRequest,
    { data, loading },
  ] = useGetContractChangeRequestLazyQuery({ fetchPolicy: 'network-only' });

  const contractChangeRequest = unresolvedBlockEvents?.filter(
    (blockEvent) => blockEvent.source === BlockSource.CONTRACT_CHANGE_REQUEST,
  )?.[0];

  const handleViewRequestedChangesClick = () => {
    if (contractId && contractChangeRequest) {
      getContractChangeRequest({
        variables: {
          contractId,
          changeRequestIds: [
            contractChangeRequest?.blockData?.changeRequestId ?? '',
          ],
        },
      });
      openRequestChangesModal();
    }
  };

  return (
    <MainContainer>
      <RequestedChangesContentBlock
        showRequestedChangesButton
        handleViewRequestedChangesClick={handleViewRequestedChangesClick}
        contractId={contractId}
      />
      <RequestChangesModal
        open={showRequestChanges}
        onClose={closeRequestChangesModal}
        contractId={contractId}
        requestedChangeData={data?.contract?.changeRequests?.[0].description}
        loading={loading}
      />
    </MainContainer>
  );
};

export default ViewRequestedChanges;
