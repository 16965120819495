import React from 'react';

import { Trans } from 'react-i18next';

import { TFunction } from 'i18next';

import { extendGrantValueType } from '../esop-input';

interface EsopsOptionTextProps {
  esopAgreementOptions: string;
  amountString: string;
  vestedOver: number;
  ratioString: string;
  esopAmount: number;
  t: TFunction<'contract-onboarding.common'>;
}

const EsopsOptionText: React.FC<EsopsOptionTextProps> = ({
  esopAgreementOptions,
  amountString,
  vestedOver,
  ratioString,
  esopAmount,
  t,
}) => {
  if (esopAgreementOptions === extendGrantValueType.CASH) {
    return (
      <Trans i18nKey="definition-phase.compensation.esop.amount" t={t}>
        Stock option shares equivalent of{' '}
        <strong data-testid="amount-string">{{ amountString }}</strong>, vested
        over{' '}
        <strong data-testid="vested-over-string">{{ vestedOver }} Years</strong>{' '}
        in the ratio of{' '}
        <strong data-testid="ratio-string">{{ ratioString }}</strong> from the
        date of grant.
      </Trans>
    );
  }

  if (esopAgreementOptions === extendGrantValueType.UNIT) {
    return (
      <Trans i18nKey="definition-phase.compensation.esop.esops" t={t}>
        <strong data-testid="esops-string">{{ esopAmount }}</strong> stock
        option shares, vested over{' '}
        <strong data-testid="vested-over-string">{{ vestedOver }} Years</strong>{' '}
        in the ratio of{' '}
        <strong data-testid="ratio-string">{{ ratioString }}</strong> from the
        date of grant.
      </Trans>
    );
  }

  return (
    <Trans i18nKey="definition-phase.compensation.esop.both" t={t}>
      <strong data-testid="esops-string">{{ esopAmount }}</strong> Stock option
      shares equivalent of{' '}
      <strong data-testid="amount-string">{{ amountString }}</strong>, vested
      over{' '}
      <strong data-testid="vested-over-string">{{ vestedOver }} Years</strong>{' '}
      in the ratio of{' '}
      <strong data-testid="ratio-string">{{ ratioString }}</strong> from the
      date of grant.
    </Trans>
  );
};

export default EsopsOptionText;
