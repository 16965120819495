import { SorOnboardingContext } from 'types';

const useShouldDisplaySorFields = ({
  sorOnboardingContext,
}: {
  sorOnboardingContext: SorOnboardingContext;
}): {
  shouldDisplayHrisFields: boolean;
  shouldDisplayEmployeeIdAndWorkEmail: boolean;
  hideLegalDetailsSection: boolean;
  shouldDisplayReportingManager: boolean;
} => ({
  shouldDisplayHrisFields: sorOnboardingContext.isHrisEnabled,
  shouldDisplayEmployeeIdAndWorkEmail:
    sorOnboardingContext.isHrisEnabled ||
    sorOnboardingContext.isGlobalPayrollOnlyFlow,
  hideLegalDetailsSection:
    sorOnboardingContext.isGlobalPayrollAndHrisFlow ||
    sorOnboardingContext.isHrisOnlyFlow,
  shouldDisplayReportingManager:
    sorOnboardingContext.isSorOnboardingEnabled &&
    sorOnboardingContext.isSupportedTypesForHris,
});

export default useShouldDisplaySorFields;
