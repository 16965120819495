import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import tw, { TwStyle, styled, theme } from 'twin.macro';

import Button from '../button';
import FileUploadEntry from '../file-upload/components/file-upload-entry';
import Icon from '../icon';
import { ThemeContext } from '../theme-provider';
import { Maybe } from '../types';
import { fileUploadErrorMapping } from '../utils';
import { ReactComponent as Upload } from './assets/upload.svg';

export interface UploadedFileFormat {
  name?: Maybe<string>;
  url?: Maybe<string>;
}

interface FileUploadProps {
  files?: File[];
  onRemoveFile?: (f: File) => void;
  onFileDrop?: (f: File[]) => void;
  multiple?: boolean;
  minimal?: boolean;
  hideIcon?: boolean;
  description?: string;
  maxSize?: number;
  accept?: string[];
  handleFileRejections?: (rejections: FileRejection[]) => void;
  maxTotalSize?: number;
  disabled?: boolean | undefined;
  buttonStyle?: TwStyle;
  buttonText?: string;
  title?: string;
  dragContentStyles?: TwStyle;
}

export const FileDropElementContainer = styled.div(
  ({
    hasError,
    isDragActive,
  }: {
    hasError: boolean;
    isDragActive: boolean;
  }) => [
    tw`rounded-base border-2 border-dashed border-border-primary bg-background-primary bg-opacity-15`,
    hasError && tw`border-border-negative bg-background-negative-faded`,
    isDragActive && tw`border-border-brand`,
  ],
);

export const FileDropElementLayout = styled.div(
  ({ minimal, noFiles }: { minimal: boolean; noFiles: boolean }) => [
    tw`flex items-center`,
    minimal && tw`justify-between`,
    minimal || !noFiles ? tw`flex-row p-base` : tw`flex-col py-extra-large`,
  ],
);

export const FileUploadInfoContainer = styled.div(
  ({
    noFiles,
    hideIcon,
    dragContentStyles,
  }: {
    noFiles: boolean;
    hideIcon: boolean;
    dragContentStyles?: TwStyle | undefined;
  }) => [
    tw`flex flex-col`,
    !noFiles ? tw`flex-grow` : hideIcon ? tw`items-start` : tw`items-center`,
    dragContentStyles,
  ],
);

export const TitleText = styled.div(({ hasError }: { hasError: boolean }) => [
  tw`font-semibold text-ps mb-tiny`,
  hasError && tw`text-text-negative`,
]);

export const DescriptionText = styled.div(
  ({
    noFiles,
    minimal,
    hasError,
  }: {
    noFiles: boolean;
    minimal: boolean;
    hasError: boolean;
  }) => [
    tw`text-ps text-text-tertiary`,
    !minimal && noFiles && tw`mb-base`,
    hasError && tw`text-text-negative`,
  ],
);

export const RejectionContainer = styled.div`
  ${tw`text-ps text-text-negative mb-base mt-small flex`}
`;

export const UploadIcon = ({
  fileUploadError,
  ...props
}: {
  fileUploadError?: boolean;
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  if (isNewThemeApplied)
    return (
      <Icon
        tw="flex-shrink-0 h-48 w-48"
        name="file-upload"
        fill={
          fileUploadError
            ? theme`colors.icon-negative`
            : theme`colors.icon-primary`
        }
        {...props}
      />
    );

  return (
    <Upload
      tw="flex-shrink-0"
      fill={fileUploadError ? theme`colors.error` : theme`colors.primary`}
      {...props}
    />
  );
};

const FileUpload: React.FC<FileUploadProps> = ({
  files,
  onFileDrop,
  onRemoveFile,
  multiple,
  minimal,
  hideIcon = false,
  maxSize = 10 * 1024 * 1024,
  description,
  accept = ['application/pdf', 'image/*'],
  handleFileRejections,
  maxTotalSize = 10 * 1024 * 1024,
  disabled = false,
  buttonStyle,
  buttonText,
  title,
  dragContentStyles,
  ...props
}) => {
  const { t } = useTranslation('common');

  const onDropAccepted = useCallback(
    (droppedFiles: Array<File>) => {
      if (onFileDrop) {
        onFileDrop(droppedFiles);
      }
    },
    [onFileDrop],
  );

  const {
    getInputProps,
    getRootProps,
    fileRejections,
    isDragActive,
  } = useDropzone({
    accept,
    onDropAccepted,
    multiple,
    maxSize,
    validator: ({ name, size }) => {
      if (files?.map((f) => f.name).includes(name)) {
        return {
          code: 'already-accepted',
          message: 'Already accepted in file upload',
        };
      }

      if (
        (files?.reduce((prev, curr) => prev + curr.size, 0) ?? 0) + size >
        maxTotalSize
      ) {
        return {
          code: 'max-size-exceeded',
          message: 'Max size exceeded',
        };
      }

      return null;
    },
    disabled,
  });

  useEffect(() => {
    if (handleFileRejections) {
      handleFileRejections(fileRejections);
    }
  }, [fileRejections]);

  const noFiles = (files ?? [])?.length === 0;

  const fileUploadError = useMemo(() => fileRejections.length > 0, [
    fileRejections,
  ]);

  const getFileDropElement = () => (
    <FileDropElementContainer
      {...props}
      {...getRootProps()}
      hasError={fileUploadError}
      isDragActive={isDragActive}
    >
      <input data-testid="file-upload" {...getInputProps()} />
      <FileDropElementLayout minimal={!!minimal} noFiles={noFiles}>
        {!minimal && noFiles && !hideIcon && (
          <UploadIcon tw="mb-base" fileUploadError={fileUploadError} />
        )}
        {minimal && noFiles && !hideIcon && (
          <UploadIcon fileUploadError={fileUploadError} />
        )}
        <FileUploadInfoContainer
          noFiles={noFiles}
          hideIcon={hideIcon}
          dragContentStyles={dragContentStyles}
        >
          <TitleText hasError={fileUploadError}>
            {title ?? t('upload-files.drag-here', 'Drag and Drop files here')}
          </TitleText>
          <DescriptionText
            hasError={fileUploadError}
            minimal={!!minimal}
            noFiles={noFiles}
          >
            {description ??
              t(
                'upload-files.supported-files',
                'Files Supported: PDF, PNG, JPG (Max 30mb)',
              )}
          </DescriptionText>
          {fileRejections.map((rejection) => {
            const errorCode = rejection.errors[0].code;
            return (
              <RejectionContainer key={rejection.file.name}>
                <Icon
                  tw="mr-tiny"
                  name="error"
                  fill={theme`colors.icon-negative`}
                />
                <span>
                  {fileUploadErrorMapping({
                    t,
                    key: errorCode,
                    maxSize,
                    maxTotalSize,
                  })}
                </span>
              </RejectionContainer>
            );
          })}
        </FileUploadInfoContainer>
        <Button
          variant="secondary"
          size="small"
          disabled={disabled}
          css={[buttonStyle]}
        >
          {buttonText ?? t('upload-files.upload-button', 'Upload File')}
        </Button>
      </FileDropElementLayout>
    </FileDropElementContainer>
  );

  const ConditionalRenderFileDrop = () => {
    if (!minimal) return getFileDropElement();
    if (minimal && !multiple && !noFiles) return null;
    return getFileDropElement();
  };

  return (
    <div tw="flex flex-col gap-y-base">
      {ConditionalRenderFileDrop()}
      {(files ?? [])?.map((file) => (
        <FileUploadEntry
          key={file.name}
          file={file}
          onDeleteClick={
            disabled
              ? undefined
              : () => {
                  if (onRemoveFile) onRemoveFile(file);
                }
          }
        />
      ))}
    </div>
  );
};

export default FileUpload;
