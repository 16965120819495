import { Maybe } from '@multiplier/common';
import growthBook, { AppFeature } from '@multiplier/growthbook';
import { isPast } from 'date-fns';
import { TFunction } from 'i18next';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import merge from 'lodash/fp/merge';

import {
  ComplianceParamPeriodLimit,
  Contract,
  ContractOffboardingStatus,
  ContractOnboardingStatus,
  ContractStatus,
  FixedPayComponent,
  RateFrequency,
} from '__generated__/graphql';

import { TeamViewTab } from '../pages/team-view';
import { ContractFromTeamQuery } from '../types';

export interface FilteredContracts {
  ACTIVE: Contract[];
  ONBOARDING: Contract[];
  INACTIVE: Contract[];
  OFFBOARDING: Contract[];
  ENDED: Contract[];
  ALL: Contract[];
  BLOCKED: Contract[];
}

const isSorOnboardingEnabled = () => growthBook.isOn(AppFeature.SOR_ONBOARDING);

export const groupTeamView = (
  contracts: ContractFromTeamQuery[],
): FilteredContracts =>
  flow(
    groupBy<ContractFromTeamQuery>((contract) => {
      if (contract.contractBlocked) return TeamViewTab.BLOCKED;

      if (
        contract.status &&
        [ContractStatus.ACTIVE].includes(contract.status) &&
        !contract.offboarding &&
        (contract.startOn ? isPast(new Date(contract.startOn)) : true) // Only check if the contract has started if startOn field exists
      )
        return TeamViewTab.ACTIVE;

      if (
        contract.status &&
        [ContractStatus.ONBOARDING, ContractStatus.ACTIVE].includes(
          contract.status,
        ) &&
        (isSorOnboardingEnabled() || !contract.contractStarted) &&
        contract.onboarding?.status !== ContractOnboardingStatus.REVOKED
      )
        return TeamViewTab.ONBOARDING;

      if (contract.onboarding?.status === ContractOnboardingStatus.REVOKED)
        return TeamViewTab.INACTIVE;

      if (
        (contract.status === ContractStatus.ACTIVE &&
          contract.offboarding?.status ===
            ContractOffboardingStatus.INITIATED) ||
        contract.status === ContractStatus.OFFBOARDING
      )
        return TeamViewTab.OFFBOARDING;

      if (contract.status === ContractStatus.ENDED) return TeamViewTab.ENDED;

      return TeamViewTab.ALL;
    }),
    merge({
      [TeamViewTab.ACTIVE]: [],
      [TeamViewTab.ONBOARDING]: [],
      [TeamViewTab.BLOCKED]: [],
      [TeamViewTab.INACTIVE]: [],
      [TeamViewTab.OFFBOARDING]: [],
      [TeamViewTab.ENDED]: [],
      [TeamViewTab.ALL]: contracts,
    }),
  )(contracts);

export const showPaymentFrequency = (
  t: TFunction<'team.company'>,
  employmentType?: Contract['type'],
  frequency?: FixedPayComponent['frequency'],
): string => {
  const frequencyLabels: Record<string, string> = {
    [RateFrequency.ONCE]: t('view.payment-frequency.once', 'once'),
    [RateFrequency.HOURLY]: t('view.payment-frequency.hourly', 'hour'),
    [RateFrequency.DAILY]: t('view.payment-frequency.daily', 'day'),
    [RateFrequency.WEEKLY]: t('view.payment-frequency.weekly', 'week'),
    [RateFrequency.MONTHLY]: t('view.payment-frequency.monthly', 'month'),
    [RateFrequency.QUATERLY]: t(
      'view.payment-frequency.quarterly',
      'quarterly',
    ),
    [RateFrequency.HALFYEARLY]: t(
      'view.payment-frequency.half-yearly',
      'half yearly',
    ),
    [RateFrequency.ANNUALLY]: t('view.payment-frequency.annually', 'year'),
    [RateFrequency.CUSTOM]: t('view.payment-frequency.custom', 'custom'),
    [RateFrequency.SEMIMONTHLY]: t(
      'view.payment-frequency.semi-monthly',
      'semi-monthly',
    ),
    [RateFrequency.BI_MONTHLY]: t(
      'view.payment-frequency.bi-monthly',
      'bi monthly',
    ),
    [RateFrequency.TRI_ANNUALLY]: t(
      'view.payment-frequency.tri-annually',
      'tri-annually',
    ),
  };

  return frequency ? `/ ${frequencyLabels[frequency]}` : '';
};

export const getNoticePeriod = (
  contract?: Maybe<Pick<Contract, 'compliance'>>,
): Maybe<Pick<ComplianceParamPeriodLimit, 'value' | 'unit'>> => {
  const compliance = contract?.compliance?.complianceParams;
  if (!compliance) return null;

  const noticePeriod = compliance.find(
    (comp) => comp?.key === 'noticeAfterProbation',
  ) as ComplianceParamPeriodLimit;
  if (!noticePeriod?.value) return null;

  return { value: noticePeriod.value, unit: noticePeriod.unit };
};
