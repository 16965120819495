/** @jsxImportSource @emotion/react */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import {
  Controller,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, useModal } from '@multiplier/common';
import { useSorOnboardingContext } from '@multiplier/hris-member-management';
import { addMonths } from 'date-fns';
import tw from 'twin.macro';
import * as yup from 'yup';

import { successNotification } from 'app/services/notification-services';
import { numberAsCurrency } from 'app/utils/format';
import ComboBox from 'common/components/combo-box';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';
import {
  Religion,
  payFrequencyLabel,
  payTypeLabel,
  rateFrequencyLabelFreelancer,
} from 'common/constants/default-labels';
import {
  Occurrence,
  PaymentType,
} from 'contract-onboarding/company/components/additional-pay-form';
import AdditionalPaysTable from 'contract-onboarding/company/components/additional-pays-table';
import { useGetPayrollConfig } from 'contract-onboarding/company/hooks';
import { isContractToPayrollConfigLinkingEnabled } from 'contract-onboarding/company/hooks/get-payroll-config';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import PayrollCutoffDateHint from 'contract-onboarding/company/pages/definition-phase/pages/basic-details/components/new-payroll-cutoff-date-hint';
import { getEsopFromGrant } from 'contract-onboarding/company/pages/definition-phase/pages/compensation';
import {
  firstPayoutDateOptionsForPayoutFrequencyMap,
  frequencyOptionsForBillingDurationsMap,
  payoutDateLabelsOnboarding,
} from 'contract-onboarding/company/pages/definition-phase/pages/compensation/freelancer/common/constants';
import {
  getFirstPayout,
  getSecondPayout,
  mapPayFrequencyDateToFormValues,
} from 'contract-onboarding/company/pages/definition-phase/pages/compensation/freelancer/common/helpers';
import {
  convertBasePayToMonthlyPay,
  getCurrencyOptions,
  getFixedPayValidation,
  isMonthEnabledForAdditionalPay,
} from 'contract-onboarding/company/services/compensation';
import DetailRow from 'contract-onboarding/components/detail-row';
import {
  DetailForm,
  DetailGrid,
  DetailValue,
} from 'contract-onboarding/components/layout';
import SectionActionBar from 'contract-onboarding/components/section-action-bar';
import { useSubmitCompensation } from 'contract-onboarding/hooks';
import { CompensationFormValues } from 'contract-onboarding/hooks/submit-compensation';
import { useAdditionalCompensationsMapper } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';
import { Sections } from 'team/components/employee-details-component';

import {
  Company,
  Contract,
  ContractType,
  CountryCode,
  DayOfWeek,
  Maybe,
  PayFrequency,
  PayFrequencyDateIdentifier,
  PayType,
  RateFrequency,
  useGetSupportedCurrenciesQuery,
} from '__generated__/graphql';

import ContractReviewSectionModal, {
  FooterSection,
} from '../contract-review-section-modal';

const CompensationSectionFreelancer: React.FC<{
  id: Contract['id'];
  type: Contract['type'];
  term: Contract['term'];
  country: Contract['country'];
  currency: Contract['currency'];
  compensation: Contract['compensation'];
  alreadyHired: Contract['alreadyHired'];
  companyName: Company['displayName'];
  companyId: Company['id'];
  religion?: Maybe<Religion>;
  edit: boolean;
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  startOn?: string;
  countryStateCode: Contract['countryStateCode'];
  legalEntityId: Contract['legalEntityId'];
  toggle?: (key: string) => void;
  open?: boolean;
}> = ({
  id,
  type,
  term,
  country,
  compensation,
  companyName,
  companyId,
  religion,
  edit,
  uncommittedChangesCallback,
  startOn,
  countryStateCode,
  legalEntityId,
  toggle,
  open,
}) => {
  const { i18n, t } = useTranslation('contract-onboarding.common');

  const mapperParams = useMemo(
    () => ({
      contract: {
        country,
        countryStateCode,
        type,
      } as Contract,
    }),
    [country, countryStateCode, type],
  );

  const { mapToAdditionalPayFormValues } = useAdditionalCompensationsMapper(
    mapperParams,
  );

  const isNotFreelancer = type !== ContractType.FREELANCER;

  const sorOnboardingContext = useSorOnboardingContext({
    type,
    companyId,
    legalEntityId,
  });

  const [showEdit, toggleEdit] = useToggle(false);
  const [
    showEditFormModal,
    handleCloseEditFormModal,
    handleOpenEditFormModal,
  ] = useModal(false);

  const modalCloseHandler = () => {
    toggleEdit(false);
    methods.reset();
    handleCloseEditFormModal();
    setUncommittedSubForms(false);
  };

  const { data: supportedCurrenciesData } = useGetSupportedCurrenciesQuery({
    variables: {
      country: country as CountryCode,
      contractType: type,
    },
    skip: country === undefined,
  });

  const compliantCurrencies =
    supportedCurrenciesData?.country?.supportedCurrencies || [];

  const methods = useForm<CompensationFormValues>({
    resolver: yupResolver(
      yup.object().shape({
        basePay: getFixedPayValidation({
          contractType: type,
          compliantCurrencies,
          contractTerm: term,
        }),
        payrollStart: yup.date().nullable(),
        payType: yup
          .string()
          .test('pay-type-test', (value?: string) =>
            type === ContractType.FREELANCER
              ? yup
                  .string()
                  .oneOf(Object.values(PayType))
                  .required()
                  .isValid(value)
              : true,
          )
          .nullable(),
        additionalPays: yup.lazy((val, ctx) => {
          const baseMonthlyPay = convertBasePayToMonthlyPay(
            ctx.parent?.basePay?.frequency ?? RateFrequency.MONTHLY,
            ctx.parent?.basePay?.amount ?? 0,
          );
          return yup.array().of(
            yup.object().shape({
              payType: yup
                .string()
                .required()
                .oneOf(Object.values(PaymentType)),
              amount: yup
                .number()
                .nullable()
                .notRequired()
                .when('payType', {
                  is: (payType: PaymentType) =>
                    payType === PaymentType.THR_BONUS ||
                    payType === PaymentType.THIRTEENTH_MONTH_BONUS,
                  then: yup
                    .number()
                    .min(
                      baseMonthlyPay,
                      t(
                        'definition-phase.compensation-section.additional-pay.should-be-greater-than-base-pay',
                        'should be greater than min pay',
                      ),
                    ),
                })
                .when('payType', {
                  is: (payType: PaymentType) =>
                    payType === PaymentType.JOINING_BONUS ||
                    payType === PaymentType.ALLOWANCES,
                  then: yup.number().moreThan(0),
                }),
              currency: yup
                .string()
                .notRequired()
                .nullable()
                .when('payType', {
                  is: (payType: PaymentType) =>
                    [
                      PaymentType.JOINING_BONUS,
                      PaymentType.ALLOWANCES,
                      PaymentType.THR_BONUS,
                      PaymentType.THIRTEENTH_MONTH_BONUS,
                    ].includes(payType),
                  then: yup
                    .string()
                    .oneOf(
                      currencyOptions
                        ? currencyOptions.map((v) => v.value)
                        : [],
                    ),
                }),
              frequency: isNotFreelancer
                ? yup.string().required().oneOf(Object.values(RateFrequency))
                : yup
                    .number()
                    .nullable()
                    .notRequired()
                    .when(['payType', 'occurrence'], {
                      is: (payType: PaymentType, occurrence: Occurrence) => {
                        if (
                          payType === PaymentType.VARIABLE_PERFORMANCE_BONUS ||
                          payType === PaymentType.ALLOWANCES
                        )
                          return true;
                        return (
                          payType === PaymentType.OTHER &&
                          occurrence === Occurrence.RECURRING
                        );
                      },
                      then: yup.number().integer().moreThan(0),
                    }),
              conditions: yup.string().notRequired().nullable(),
              payoutMonth: yup
                .object()
                .nullable()
                .notRequired()
                .when('payType', {
                  is: (payType: PaymentType) =>
                    [
                      PaymentType.ALLOWANCES,
                      PaymentType.JOINING_BONUS,
                      PaymentType.VARIABLE_PERFORMANCE_BONUS,
                      PaymentType.OTHER,
                    ].includes(payType),
                  then: yup.object().shape({
                    month: yup.number().required(),
                    year: yup.number().required(),
                  }),
                }),
            }),
          );
        }),
        payrollConfigId: isContractToPayrollConfigLinkingEnabled(
          type,
          sorOnboardingContext,
        )
          ? yup
              .string()
              .required(
                t(
                  'definition-phase.compensation.payroll-config-id.required',
                  'There is no payroll config matched with the selected payroll frequency',
                ),
              )
          : yup.string().nullable().optional(),
      }),
    ),
    defaultValues: {
      payType:
        compensation?.payType ??
        (type === ContractType.FREELANCER ? PayType.FIXED : null),
      basePay: {
        name: compensation?.basePay?.name,
        frequency: compensation?.basePay?.frequency,
        rateType: compensation?.basePay?.rateType,
        currency: compensation?.basePay?.currency,
        amount: compensation?.basePay?.amount,
        paymentFrequency: compensation?.basePay?.paymentFrequency,
        paymentFrequencyDate:
          mapPayFrequencyDateToFormValues(
            compensation?.basePay?.paymentFrequencyDate,
          ) ?? undefined,
        firstPayoutDate: getFirstPayout(
          compensation?.basePay?.paymentFrequency,
          compensation?.basePay?.paymentFrequencyDate,
        ),
        secondPayoutDate: getSecondPayout(
          compensation?.basePay?.paymentFrequency,
          compensation?.basePay?.paymentFrequencyDate,
        ),
      },
      additional: compensation?.additional,
      esop: compensation?.grant?.length
        ? getEsopFromGrant(compensation?.grant?.[0])
        : undefined,
      payrollStart: compensation?.payrollStart,
      additionalPays: mapToAdditionalPayFormValues(
        compensation?.additionalPays,
      ),
    },
  });

  const {
    control,
    reset,
    setValue,
    formState: { isDirty, isValid, errors, dirtyFields },
    register,
    watch,
    handleSubmit,
    trigger,
  } = methods;

  const { replace: replacePaymentFrequencyDateFieldArray } = useFieldArray({
    control,
    name: 'basePay.paymentFrequencyDate',
  });

  const [uncommittedSubForms, setUncommittedSubForms] = useState(false);

  const handlePaymentDateOne = (dateValue: string) => {
    switch (paymentFrequency) {
      case PayFrequency.WEEKLY:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.PAYOUT_DAY,
            dateOfMonth: null,
            dayOfWeek: dateValue as DayOfWeek,
          },
        ]);

        break;
      case PayFrequency.SEMIMONTHLY:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.FIRST_PAYOUT_DATE,
            dateOfMonth: parseInt(dateValue, 10),
            dayOfWeek: null,
          },
          {
            identifier: PayFrequencyDateIdentifier.SECOND_PAYOUT_DATE,
            dateOfMonth: parseInt(dateValue, 10) + 15,
            dayOfWeek: null,
          },
        ]);
        setValue(
          'basePay.secondPayoutDate',
          calculateSecondPayoutDate(dateValue),
        );
        break;
      case PayFrequency.MONTHLY:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.PAYOUT_DATE,
            dateOfMonth: parseInt(dateValue, 10),
            dayOfWeek: null,
          },
        ]);
        break;
      default:
        replacePaymentFrequencyDateFieldArray([
          {
            identifier: PayFrequencyDateIdentifier.PAYOUT_DAY,
            dateOfMonth: null,
            dayOfWeek: DayOfWeek.MONDAY,
          },
        ]);
    }
  };

  const calculateSecondPayoutDate = (
    number: string | undefined,
  ): string | undefined => {
    if (firstPayoutDate && number) {
      const i = parseInt(number, 10);
      return (i + 15).toString();
    }
    return undefined;
  };

  const firstPayoutDate = useWatch({
    name: 'basePay.firstPayoutDate',
    control,
  });

  const paymentFrequency = useWatch({
    name: 'basePay.paymentFrequency',
    control,
  });

  const rateFrequency = useWatch({
    name: 'basePay.frequency',
    control,
  });

  const baseCurrency = useWatch({
    name: 'basePay.currency',
    control,
  });

  useEffect(() => {
    if (firstPayoutDate) handlePaymentDateOne(firstPayoutDate);
  }, [firstPayoutDate]);

  const currencyOptions = useMemo(
    () => getCurrencyOptions(compliantCurrencies),
    [compliantCurrencies],
  );

  const currencyOptionsForAdditionalPays = useMemo(
    () =>
      currencyOptions.filter(
        (selectedCurrency) => selectedCurrency.value === baseCurrency,
      ),
    [currencyOptions, baseCurrency],
  );

  const handleReset = () => {
    if (compensation && compensation.basePay)
      reset({
        payType:
          compensation.payType ??
          (type === ContractType.FREELANCER ? PayType.FIXED : null),
        basePay: {
          name: compensation.basePay.name,
          frequency: compensation.basePay.frequency,
          rateType: compensation.basePay.rateType,
          currency: compensation.basePay.currency,
          amount: compensation.basePay.amount,
          paymentFrequency: compensation.basePay.paymentFrequency,
          paymentFrequencyDate:
            mapPayFrequencyDateToFormValues(
              compensation?.basePay?.paymentFrequencyDate,
            ) ?? undefined,
          firstPayoutDate: getFirstPayout(
            compensation.basePay.paymentFrequency,
            compensation.basePay.paymentFrequencyDate,
          ),
          secondPayoutDate: getSecondPayout(
            compensation.basePay.paymentFrequency,
            compensation.basePay.paymentFrequencyDate,
          ),
        },
        additional: compensation?.additional,
        esop: compensation?.grant?.length
          ? getEsopFromGrant(compensation?.grant?.[0])
          : undefined,
        payrollStart: compensation?.payrollStart,
        additionalPays: mapToAdditionalPayFormValues(
          compensation.additionalPays,
        ),
      });
  };

  const { onSubmit, loading } = useSubmitCompensation(
    id,
    false,
    true,
    type,
    () => {
      modalCloseHandler();
      setUncommittedSubForms(false);
      successNotification(
        t(
          'onboarding-phase.compensation-section.success-notification',
          'Compensation details have been updated in the contract.',
        ),
        '',
        false,
      );
    },
  );

  const rateFrequencyDropdownOptions = useMemo(
    () =>
      Object.values([
        RateFrequency.HOURLY,
        RateFrequency.DAILY,
        RateFrequency.SEMIMONTHLY,
        RateFrequency.MONTHLY,
      ]).map((frequency) => ({
        title: rateFrequencyLabelFreelancer[frequency],
        value: frequency,
      })),
    [],
  );

  const paymentFrequencyDropdownOptions = useMemo(
    () =>
      (
        rateFrequency && frequencyOptionsForBillingDurationsMap[rateFrequency]
      )?.map((frequency) => ({
        title: payFrequencyLabel[frequency],
        value: frequency,
      })) ?? [],
    [rateFrequency],
  );

  const isPaymentFrequencySemiMonthly = useMemo(
    () => paymentFrequency === PayFrequency.SEMIMONTHLY,
    [paymentFrequency],
  );

  const firstPayoutDateValueText = useMemo(() => {
    const compensationPayDate = compensation?.basePay?.paymentFrequencyDate;
    const compensationPayFrequency = compensation?.basePay?.paymentFrequency;

    if (compensationPayFrequency && compensationPayDate) {
      const firstPayoutDateValue = getFirstPayout(
        compensationPayFrequency,
        compensationPayDate,
      );
      return firstPayoutDateOptionsForPayoutFrequencyMap[
        compensationPayFrequency
      ].find((item) => item.value === firstPayoutDateValue)?.title as string;
    }
    return '';
  }, [compensation]);

  const secondPayoutDateText = useMemo(() => {
    const compensationPayDate = compensation?.basePay?.paymentFrequencyDate;
    const compensationPayFrequency = compensation?.basePay?.paymentFrequency;

    if (
      compensationPayFrequency &&
      compensationPayFrequency === PayFrequency.SEMIMONTHLY &&
      compensationPayDate
    ) {
      const secondPayoutDateValue = getSecondPayout(
        compensationPayFrequency,
        compensationPayDate,
      );
      return firstPayoutDateOptionsForPayoutFrequencyMap[
        PayFrequency.MONTHLY
      ].find((item) => item.value === secondPayoutDateValue)?.title as string;
    }
    return '';
  }, [compensation]);

  const enableCurrentMonth = useMemo<boolean>(
    () => isMonthEnabledForAdditionalPay(startOn ?? ''),
    [compensation, startOn],
  );

  const resetPayoutDates = () => {
    setValue('basePay.firstPayoutDate', null);
    setValue('basePay.secondPayoutDate', undefined);
    replacePaymentFrequencyDateFieldArray([]);
  };

  useEffect(() => {
    handleReset();
  }, [compensation, mapToAdditionalPayFormValues]);

  useEffect(() => {
    setUncommittedSubForms(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (uncommittedChangesCallback) {
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.COMPENSATION_DETAILS,
        value: uncommittedSubForms,
      });
    }
  }, [uncommittedSubForms]);

  const payrollConfig = useGetPayrollConfig({
    contractId: id,
    payFrequency: paymentFrequency,
    sorOnboardingContext,
  });

  useLayoutEffect(() => {
    methods.setValue('payrollConfigId', payrollConfig?.id as string);
    methods.trigger('payrollConfigId');
  }, [payrollConfig]);

  const FormData = useCallback(
    ({ isFormEditing = false }: { isFormEditing?: boolean }) => (
      <DetailGrid>
        <DetailRow
          id="pay-type"
          label={t(
            'onboarding-phase.compensation-section.compensation-type.label',
            'Compensation Type',
          )}
          value={compensation?.payType && payTypeLabel[compensation?.payType]}
          isEditing={isFormEditing}
        >
          <Controller
            control={control}
            name="payType"
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="pay-type-dropdown"
                id="pay-type"
                tw="col-span-3 w-1/2"
                variant="default"
                value={value ?? ''}
                placeholder={t(
                  'onboarding-phase.compensation-section.pay-type.placeholder',
                  'Select',
                )}
                dropdownValues={[
                  {
                    title: payTypeLabel[PayType.DYNAMIC],
                    value: PayType.DYNAMIC,
                  },
                ]}
                onChange={onChange}
              />
            )}
          />
        </DetailRow>

        {compensation?.basePay && (
          <>
            <DetailRow
              id="base-pay-entry"
              label={t(
                'onboarding-phase.compensation-section.billing-rate.label',
                'Billing Rate',
              )}
              value={compensation?.basePay?.amount}
              isEditing={isFormEditing}
              renderValue={
                <div tw="col-span-3">
                  <DetailValue data-testid="base-pay-entry">
                    {`${compensation?.basePay?.currency} ${numberAsCurrency(
                      i18n.language,
                      compensation?.basePay?.amount,
                    )} / ${
                      compensation?.basePay?.frequency &&
                      rateFrequencyLabelFreelancer[
                        compensation?.basePay?.frequency
                      ]
                    }`}
                  </DetailValue>
                </div>
              }
            >
              <div css={tw`flex flex-row col-span-3`}>
                <div tw="w-1/2">
                  <DropdownText.Container
                    tw="flex-grow"
                    data-testid="fixed-pay-currency-input-base"
                  >
                    <Controller
                      name={
                        `basePay.currency` as FieldPath<CompensationFormValues>
                      }
                      control={control}
                      defaultValue={compensation?.basePay?.currency}
                      render={({ field: { value, onChange } }) => (
                        <ComboBox
                          data-testid="currency-select"
                          variant="inline"
                          showArrow
                          value={value as string}
                          dropdownValues={currencyOptions || []}
                          placeholder={t(
                            'compensation.currency.placeholder',
                            'Type a currency/country',
                          )}
                          onChange={onChange}
                          disabled={
                            type !== ContractType.FREELANCER &&
                            currencyOptions?.length === 1
                          }
                        />
                      )}
                    />
                    <DropdownText.Input
                      currency
                      type="number"
                      step="0.01"
                      placeholder="0"
                      tw="appearance-none"
                      data-testid="currency-input"
                      divStyles={tw`flex-grow`}
                      {...register(`basePay.amount`)}
                      onChange={(e) => {
                        register(`basePay.amount`).onChange(e);
                        trigger('additionalPays');
                      }}
                      // defaultValue={fieldArrayElement?.amount || ''}
                    />
                  </DropdownText.Container>
                </div>

                <div tw="w-1/2">
                  <TextInput.Container
                    data-testid="pay-rate-select"
                    css={tw`flex flex-row items-center`}
                  >
                    <span tw="mx-small font-normal text-ps">
                      {t(
                        `definition-phase.compensation-section.billing-frequency-label`,
                        'Per',
                      )}
                    </span>
                    <Controller
                      name={
                        `basePay.frequency` as FieldPath<CompensationFormValues>
                      }
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ComboBox
                          data-testid="frequency-dropdown"
                          id="frequency-select"
                          tw="w-full"
                          variant="default"
                          value={value as string}
                          placeholder={t(
                            `compensation.base.frequency.placeholder`,
                            'Select pay rate',
                          )}
                          dropdownValues={rateFrequencyDropdownOptions}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </TextInput.Container>
                </div>
              </div>
            </DetailRow>

            <DetailRow
              id="payment-frequency"
              label={t(
                'onboarding-phase.compensation-section.payment-frequency.label',
                'Payment Frequency',
              )}
              value={
                compensation?.basePay?.paymentFrequency &&
                payFrequencyLabel[compensation?.basePay?.paymentFrequency]
              }
              isEditing={isFormEditing}
            >
              <Controller
                control={control}
                name="basePay.paymentFrequency"
                render={({ field: { value, onChange } }) => (
                  <ComboBox
                    data-testid="payment-frequency-dropdown"
                    id="payment-frequency-select"
                    tw="col-span-3 w-1/2"
                    variant="default"
                    value={value ?? ''}
                    placeholder={t(
                      'onboarding-phase.compensation-section.payment-frequency.placeholder',
                      'Select',
                    )}
                    dropdownValues={paymentFrequencyDropdownOptions ?? []}
                    onChange={(e) => {
                      onChange(e);
                      setUncommittedSubForms(true);
                      resetPayoutDates();
                    }}
                  />
                )}
              />

              <div tw="col-span-3 w-full">
                <PayrollCutoffDateHint
                  startDate={startOn}
                  contractType={type}
                  sorOnboardingContext={sorOnboardingContext}
                  payrollConfig={payrollConfig}
                />
              </div>
            </DetailRow>

            <DetailRow
              id="payout-date"
              label={
                paymentFrequency
                  ? payoutDateLabelsOnboarding[paymentFrequency]
                  : 'Payout Date'
              }
              value={firstPayoutDateValueText}
              isEditing={isFormEditing}
            >
              <div css={tw`flex flex-row col-span-3`}>
                <div
                  css={isPaymentFrequencySemiMonthly ? tw`w-full` : tw`w-1/2`}
                >
                  <Controller
                    name="basePay.firstPayoutDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ComboBox
                        data-testid="first-payment-date-dropdown"
                        variant={paymentFrequency ? 'default' : 'disabled'}
                        disabled={!paymentFrequency}
                        dropdownValues={
                          (paymentFrequency &&
                            firstPayoutDateOptionsForPayoutFrequencyMap[
                              paymentFrequency
                            ]) ?? [
                            {
                              title: t(
                                'onboarding-phase.compensation-section.first-payout-date-default',
                                'Select',
                              ),
                              value: '',
                            },
                          ]
                        }
                        showArrow
                        value={value || ''}
                        placeholder={t(
                          'onboarding-phase.compensation-section.first-payout-date-placeholder',
                          'Select',
                        )}
                        onChange={(e) => {
                          onChange(e);
                          handlePaymentDateOne(e);
                          setUncommittedSubForms(true);
                        }}
                        error={!!errors.basePay?.firstPayoutDate}
                      />
                    )}
                  />
                </div>

                {isPaymentFrequencySemiMonthly && (
                  <div tw="w-full">
                    <Controller
                      name="basePay.secondPayoutDate"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ComboBox
                          data-testid="second-payment-date-dropdown"
                          variant="disabled"
                          disabled
                          tw="ml-small"
                          dropdownValues={
                            (paymentFrequency &&
                              firstPayoutDateOptionsForPayoutFrequencyMap[
                                PayFrequency.MONTHLY
                              ]) ?? [
                              {
                                title: t(
                                  'onboarding-phase.compensation-section.second-payout-date-default',
                                  'Select',
                                ),
                                value: '',
                              },
                            ]
                          }
                          showArrow
                          value={value || ''}
                          placeholder={t(
                            'onboarding-phase.compensation-section.second-payout-date-placeholder',
                            'Select',
                          )}
                          onChange={(e) => {
                            onChange(e);
                            handlePaymentDateOne(e);
                            setUncommittedSubForms(true);
                          }}
                          error={!!errors.basePay?.secondPayoutDate}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            </DetailRow>

            {compensation.basePay?.paymentFrequency ===
              PayFrequency.SEMIMONTHLY && (
              <DetailRow
                id="second-payout-date"
                label="Second Payout Date"
                value={secondPayoutDateText}
                hideLabel={isFormEditing}
                isEditing={isFormEditing}
              />
            )}
          </>
        )}
        {(compensation?.additionalPays?.length || showEdit) && (
          <div tw="col-span-4 my-base">
            <AdditionalPaysTable
              basePay={convertBasePayToMonthlyPay(
                watch('basePay.frequency') ?? RateFrequency.MONTHLY,
                watch('basePay.amount') ?? 0,
              )}
              currency={
                watch('basePay.currency') ?? compensation?.basePay?.currency
              }
              contractType={type}
              currencyOptions={currencyOptionsForAdditionalPays}
              companyName={companyName}
              edit={isFormEditing}
              religion={religion}
              uncommittedSubFormChangesCallback={setUncommittedSubForms}
              countryCode={country}
              minDate={
                enableCurrentMonth
                  ? new Date(new Date(startOn ?? ''))
                  : addMonths(new Date(startOn ?? ''), 1)
              }
              countryStateCode={countryStateCode}
            />
          </div>
        )}
      </DetailGrid>
    ),
    [showEdit, sorOnboardingContext, payrollConfig],
  );

  return (
    <Accordion
      name={t('onboarding-phase.compensation-section.header', 'Compensation')}
      description={t(
        'onboarding-phase.compensation-section.description',
        'Category description to better explain what this section is about.',
      )}
      edit={edit}
      icon="dollar"
      onEditClick={() => {
        toggleEdit(true);
        handleOpenEditFormModal();
        if (toggle) toggle(Sections.COMPENSATION_FREELANCER);
      }}
      warning={uncommittedChangesCallback && uncommittedSubForms}
      onClick={() => {
        if (toggle) toggle(Sections.COMPENSATION_FREELANCER);
      }}
      open={open}
    >
      <FormProvider {...methods}>
        <DetailForm
          onSubmit={handleSubmit(onSubmit)}
          data-testid="compensation-section-view"
        >
          <FormData />
        </DetailForm>
      </FormProvider>
      {showEditFormModal && (
        <ContractReviewSectionModal
          modalTitle="Edit Compensation Details"
          onClose={modalCloseHandler}
        >
          <FormProvider {...methods}>
            <DetailForm
              onSubmit={methods.handleSubmit(onSubmit)}
              tw="gap-y-none"
            >
              <div tw="flex flex-col p-extra-large py-none pt-base">
                <FormData isFormEditing={showEdit} />
              </div>
              <FooterSection>
                <SectionActionBar
                  disabled={
                    loading || !isValid || Object.keys(dirtyFields).length === 0
                  }
                  onCancelClick={modalCloseHandler}
                  submitLoading={loading}
                  tw="mx-auto"
                />
              </FooterSection>
            </DetailForm>
          </FormProvider>
        </ContractReviewSectionModal>
      )}
    </Accordion>
  );
};

export default CompensationSectionFreelancer;
