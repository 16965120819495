/** @jsxImportSource @emotion/react */
import React, { useMemo, useRef, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useClickAway, useMedia } from 'react-use';

import { Icon, IconButton } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import countryLabels from 'common/constants/country-labels';

import {
  Contract,
  CountryCode,
  CountryCompensationStandards,
  CountryHourlyRateValue,
  CountryHourlyRates,
} from '__generated__/graphql';

const ConversionContainer = tw.div`flex mt-8 flex-col`;
const OvertimePayContainer = tw.div`flex flex-col mt-16 bg-[#2240CD66] p-16 rounded-8`;

const WarningBox: React.FC<{
  helpUrl: CountryHourlyRates['helpUrl'];
}> = ({ helpUrl }) => {
  const { t } = useTranslation('contract-onboarding.common');

  return (
    <div tw="my-12 bg-primary rounded-8 p-16 gap-x-16 grid grid-template-columns[min-content 1fr]">
      <div tw="mt-[4px]">
        <Icon name="info-white" />
      </div>
      <div>
        <p tw="text-[#D2DDFD]">
          {t(
            'hourly-rate-variations.pop-up.warning',
            'The following rate differentials are mandated by the local labor laws.',
          )}
          &nbsp;
          {helpUrl && (
            <a
              href={helpUrl}
              target="_blank"
              rel="noreferrer"
              tw="text-white border-b border-white"
            >
              {t('hourly-rate-variations.pop-up.warning-link', 'Learn more.')}
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

const Bold = tw.span`font-semibold`;

const TimeDifferentialItem: React.FC<{
  country: CountryCode;
  hourlyRateValue: CountryHourlyRateValue;
}> = ({ country, hourlyRateValue }) => {
  const { t } = useTranslation('contract-onboarding.common');
  return (
    <div tw="my-6 grid grid-cols-2 grid-template-columns[1fr auto] gap-x-6 items-center">
      <span>
        {t(
          `hourly-rate-variations.${country}.${hourlyRateValue.key}`,
          hourlyRateValue?.label ?? '',
        )}
      </span>
      <Bold>
        {t(
          'hourly-rate-variations.pop-up.differential-value',
          '{{ value }} more than X',
          { replace: { value: hourlyRateValue?.value } },
        )}
      </Bold>
    </div>
  );
};

const OvertimeDifferential: React.FC<{
  country: CountryCode;
  overTimeHourlyRateValue: CountryHourlyRateValue[];
}> = ({ country, overTimeHourlyRateValue }) => {
  const { t } = useTranslation('contract-onboarding.common');
  if (!overTimeHourlyRateValue?.length) return null;
  return (
    <div>
      <p tw="mb-12">
        {t(
          'hourly-rate-variations.pop-up.over-time-differential.header',
          'Overtime on',
        )}
        :
      </p>
      <ul tw="ml-32 list-disc">
        {overTimeHourlyRateValue?.map((hourlyRateValue) => (
          <li key={hourlyRateValue?.key}>
            <TimeDifferentialItem
              hourlyRateValue={hourlyRateValue}
              country={country}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const OtherDifferentials: React.FC<{
  country: CountryCode;
  otherHourlyRateValue: CountryHourlyRateValue[];
}> = ({ country, otherHourlyRateValue }) => {
  if (!otherHourlyRateValue?.length) return null;
  return (
    <div tw="mt-24">
      {otherHourlyRateValue?.map((hourlyRateValue) => (
        <React.Fragment key={hourlyRateValue?.key}>
          <TimeDifferentialItem
            hourlyRateValue={hourlyRateValue}
            country={country}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

type HourlyRatePopUpProps = {
  countryHourlyRates: CountryCompensationStandards['hourlyRates'];
  country: CountryCode;
  hidePopUp: () => void;
  currency: Contract['currency'];
  amount: number;
};

const HourlyRateConversion: React.FC<{
  conversionType: string;
  conversionAmount: string;
}> = ({ conversionType, conversionAmount }) => (
  <div tw="flex justify-between w-full">
    <span>
      &#x2022;&nbsp;&nbsp;
      {conversionType}
    </span>
    <span>{conversionAmount}</span>
  </div>
);

export const HourlyRatePopUp: React.FC<HourlyRatePopUpProps> = ({
  countryHourlyRates,
  country,
  hidePopUp,
  currency,
  amount,
  ...props
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const isMobile = useMedia(`(max-width: ${theme`screens.mobile.max`})`);

  const differentialsMap = useMemo(
    () =>
      countryHourlyRates?.values?.filter(notEmpty).reduce(
        (map, hourlyRateValue) => {
          if (hourlyRateValue?.key?.includes('overtime')) {
            map.overTime.push(hourlyRateValue);
          } else {
            map.others.push(hourlyRateValue);
          }
          return map;
        },
        {
          overTime: [] as CountryHourlyRateValue[],
          others: [] as CountryHourlyRateValue[],
        },
      ),
    [countryHourlyRates],
  );

  return (
    <div
      tw="z-50 absolute bg-background  text-white min-w-[430px] left-[-125px] top-28 pt-12 px-[20px] pb-[20px] rounded-10"
      {...props}
    >
      <div tw="relative">
        <div tw="flex justify-between items-center">
          <h3 tw="text-white font-semibold text-pl">
            {t(
              'hourly-rate-variations.pop-up.header',
              'Hourly Rates in {{ countryName }}',
              { replace: { countryName: countryLabels[country] } },
            )}
          </h3>
          {!isMobile && (
            <IconButton
              data-testid="hide-pop-up"
              name="cross"
              size="small"
              fill={theme`colors.grey01`}
              onClick={hidePopUp}
            />
          )}
        </div>
        {country === CountryCode.NLD ? (
          <div tw="text-ps">
            <div>
              {t(
                'hourly-rate-variation-ndl.content',
                'In Netherlands, the statutory gross minimum wage for employees aged 21 and older who are full-time employees is',
              )}
            </div>
            <ConversionContainer>
              <HourlyRateConversion
                conversionType={t(
                  'hourly-rate-variation-ndl.monthly',
                  'Monthly',
                )}
                conversionAmount={`${currency} ${amount * 160} (160 hrs)`}
              />
              <HourlyRateConversion
                conversionType={t('hourly-rate-variation-ndl.weekly', 'Weekly')}
                conversionAmount={`${currency} ${amount * 40} (40 hrs)`}
              />
              <HourlyRateConversion
                conversionType={t('hourly-rate-variation-ndl.daily', 'Daily')}
                conversionAmount={`${currency} ${amount * 8} (8 hrs)`}
              />
            </ConversionContainer>
            <OvertimePayContainer>
              <div tw="flex items-center">
                <Icon name="info-white" fill="white" tw="mr-8" />{' '}
                {t(
                  'hourly-rate-variation-ndl.overtime-pay.header',
                  'Overtime pay',
                )}
              </div>
              <span tw="mt-12">
                <Trans i18nKey="hourly-rate-variation-ndl.overtime-pay.content">
                  Opt for <span tw="font-semibold">time-for-time</span> or
                  <span tw="font-semibold">
                    provide additional pay for transparent onboarding.
                  </span>{' '}
                  Explore
                  <span tw="font-semibold">
                    overtime pay with surcharge,
                  </span>{' '}
                  which includes{' '}
                  <span tw="font-semibold">tax implications.</span>
                </Trans>
              </span>
              <div tw="flex items-center">
                <a
                  tw="text-white font-semibold underline"
                  href="https://prod.wordpress.usemultiplier.cloud/talentwiki/netherlands"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(
                    'hourly-rate-variation-ndl.overtime-pay.learn-more',
                    'Learn more',
                  )}
                </a>
              </div>
            </OvertimePayContainer>
          </div>
        ) : (
          <div>
            <WarningBox helpUrl={countryHourlyRates?.helpUrl} />
            <div tw="my-24">
              <Trans i18nKey="hourly-rate-variations.pop-up.predicate" t={t}>
                Considering Base Pay Rate as <Bold>X</Bold>
              </Trans>
            </div>
            <OvertimeDifferential
              country={country}
              overTimeHourlyRateValue={differentialsMap?.overTime ?? []}
            />
            <OtherDifferentials
              country={country}
              otherHourlyRateValue={differentialsMap?.others ?? []}
            />
            <div tw="absolute left-1/2 margin-left[-6px] border-width[6px] border-solid border-transparent border-b-background -top-24" />
          </div>
        )}
      </div>
    </div>
  );
};

const HourlyRateVariations: React.FC<{
  countryHourlyRates: CountryCompensationStandards['hourlyRates'];
  country: CountryCode;
  currency: Contract['currency'];
  amount: number;
}> = ({ countryHourlyRates, country, currency, amount }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setShowTooltip(false));

  return (
    <div tw="relative" ref={ref}>
      <button
        type="button"
        tw="flex items-center"
        onClick={() => setShowTooltip(true)}
      >
        <Icon name="clock" />
        <span tw="ml-6 font-semibold text-primary text-ps border-b border-primary">
          {t('compensation.pay-input.show-hourly-rates', 'View Hourly Rates')}
        </span>
      </button>
      {showTooltip && (
        <HourlyRatePopUp
          data-testid="pop-up-window"
          countryHourlyRates={countryHourlyRates}
          country={country}
          hidePopUp={() => setShowTooltip(false)}
          currency={currency}
          amount={amount}
        />
      )}
    </div>
  );
};

export default HourlyRateVariations;
