import React from 'react';

import 'twin.macro';

import { TFunction } from 'i18next';

import {
  InsuranceCoverage,
  InsuranceCoverageGroup,
  Maybe,
} from '../../__generated__/graphql';

interface CoverageGroupDescriptionProps {
  coverageGroup: Maybe<InsuranceCoverageGroup>;
  t: TFunction;
}

const CoverageGroupDescription: React.FC<CoverageGroupDescriptionProps> = ({
  coverageGroup,
  t,
}) => {
  const coverageGroupMemberMap: Record<string, string> = {
    Self: t('insurance:coverage-group.employee', 'Employee'),
  };
  if (coverageGroup?.group?.length === 1)
    return <>{coverageGroup?.name ?? ''}</>;

  const getMaxCountText = (maxCount: number) => (
    <span tw="font-normal">
      {t('insurance:coverage-group.upto-v2', ' (up to {{maxCount}})', {
        maxCount,
      })}
    </span>
  );

  const getFamilyMemberText = (group?: InsuranceCoverage | null) => {
    const maxCount = group?.maxCount ?? 0;

    if (group?.relation?.familyMember === 'Children') {
      return maxCount > 1 && maxCount !== -1 ? getMaxCountText(maxCount) : null;
    }

    if (group?.relation?.familyMember === 'Parents') {
      return maxCount === 2 ? null : getMaxCountText(maxCount);
    }

    return null;
  };

  return (
    <>
      {coverageGroup?.group?.map((group, index) => (
        <React.Fragment key={group?.relation?.id}>
          {index > 0 && <span>+</span>}
          {coverageGroupMemberMap?.[group?.relation?.familyMember as string] ??
            group?.relation?.familyMember}
          {getFamilyMemberText(group)}
        </React.Fragment>
      ))}
    </>
  );
};

export default CoverageGroupDescription;
