/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';

import { useCompleteContract } from 'contract-onboarding/member/hooks';

import { Contract } from '__generated__/graphql';

const ConfirmAndContinueAction: React.FC<{
  contractId: Contract['id'];
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
}> = ({ contractId, toggleWorkflowUpdating }) => {
  const { t } = useTranslation('contract-onboarding.member');
  const {
    completeContract,
    loading: loadingCompleteContract,
  } = useCompleteContract(contractId);

  useEffect(() => {
    toggleWorkflowUpdating(loadingCompleteContract);
  }, [loadingCompleteContract]);

  return (
    <Button
      size="medium"
      disabled={loadingCompleteContract}
      loading={loadingCompleteContract}
      onClick={completeContract}
    >
      {t('verification-phase.primary-action.confirm', 'Confirm & Continue')}
    </Button>
  );
};

export default ConfirmAndContinueAction;
