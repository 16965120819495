import React, { useContext } from 'react';

import tw from 'twin.macro';

import Card from '../../../card';
import CardSeparator from '../../../card-separator';
import { ThemeContext } from '../../../theme-provider';

const Widget: React.FC = ({ ...props }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <Card
      tw="px-extra-large py-large min-h-[290px]"
      data-testid="widget-spinner"
      {...props}
    >
      <div tw="animate-pulse flex gap-x-base mb-large">
        <div
          css={[
            tw`w-48 h-48 bg-background-primary rounded-base`,
            !isNewThemeApplied && tw`bg-grey04`,
          ]}
        />
        <div>
          <div
            css={[
              tw`w-[170px] h-[10px] bg-background-action-hover rounded-tiny mt-tiny`,
              !isNewThemeApplied && tw`bg-grey03`,
            ]}
          />
          <div
            css={[
              tw`w-[264px] h-[8px] bg-background-primary rounded-tiny mt-base`,
              !isNewThemeApplied && tw`bg-grey04`,
            ]}
          />
        </div>
      </div>
      <CardSeparator />
    </Card>
  );
};

export default Widget;
