import { TFunction } from 'i18next';

import { CountryCode } from '../__generated__/graphql';

/**
 * This was copied from "customer" app
 * Consider to refactor and move it to "format" package later and re-use in all other projects
 */
const countryLabels = (
  t: TFunction<'common'>,
): Record<CountryCode, string> => ({
  [CountryCode.ABW]: t('country.abw', 'Aruba'),
  [CountryCode.AFG]: t('country.afg', 'Afghanistan'),
  [CountryCode.AGO]: t('country.ago', 'Angola'),
  [CountryCode.AIA]: t('country.aia', 'Anguilla'),
  [CountryCode.ALA]: t('country.ala', 'Åland Islands'),
  [CountryCode.ALB]: t('country.alb', 'Albania'),
  [CountryCode.AND]: t('country.and', 'Andorra'),
  [CountryCode.ARE]: t('country.are', 'United Arab Emirates'),
  [CountryCode.ARG]: t('country.arg', 'Argentina'),
  [CountryCode.ARM]: t('country.arm', 'Armenia'),
  [CountryCode.ASM]: t('country.asm', 'American Samoa'),
  [CountryCode.ATA]: t('country.ata', 'Antarctica'),
  [CountryCode.ATF]: t('country.atf', 'French Southern Territories'),
  [CountryCode.ATG]: t('country.atg', 'Antigua and Barbuda'),
  [CountryCode.AUS]: t('country.aus', 'Australia'),
  [CountryCode.AUT]: t('country.aut', 'Austria'),
  [CountryCode.AZE]: t('country.aze', 'Azerbaijan'),
  [CountryCode.BDI]: t('country.bdi', 'Burundi'),
  [CountryCode.BEL]: t('country.bel', 'Belgium'),
  [CountryCode.BEN]: t('country.ben', 'Benin'),
  [CountryCode.BES]: t('country.bes', 'Bonaire, Sint Eustatius and Saba'),
  [CountryCode.BFA]: t('country.bfa', 'Burkina Faso'),
  [CountryCode.BGD]: t('country.bgd', 'Bangladesh'),
  [CountryCode.BGR]: t('country.bgr', 'Bulgaria'),
  [CountryCode.BHR]: t('country.bhr', 'Bahrain'),
  [CountryCode.BHS]: t('country.bhs', 'Bahamas'),
  [CountryCode.BIH]: t('country.bih', 'Bosnia and Herzegovina'),
  [CountryCode.BLM]: t('country.blm', 'Saint Barthélemy'),
  [CountryCode.BLR]: t('country.blr', 'Belarus'),
  [CountryCode.BLZ]: t('country.blz', 'Belize'),
  [CountryCode.BMU]: t('country.bmu', 'Bermuda'),
  [CountryCode.BOL]: t('country.bol', 'Bolivia (Plurinational State of)'),
  [CountryCode.BRA]: t('country.bra', 'Brazil'),
  [CountryCode.BRB]: t('country.brb', 'Barbados'),
  [CountryCode.BRN]: t('country.brn', 'Brunei Darussalam'),
  [CountryCode.BTN]: t('country.btn', 'Bhutan'),
  [CountryCode.BVT]: t('country.bvt', 'Bouvet Island'),
  [CountryCode.BWA]: t('country.bwa', 'Botswana'),
  [CountryCode.CAF]: t('country.caf', 'Central African Republic'),
  [CountryCode.CAN]: t('country.can', 'Canada'),
  [CountryCode.CCK]: t('country.cck', 'Cocos (Keeling) Islands'),
  [CountryCode.CHE]: t('country.che', 'Switzerland'),
  [CountryCode.CHL]: t('country.chl', 'Chile'),
  [CountryCode.CHN]: t('country.chn', 'China'),
  [CountryCode.CIV]: t('country.civ', "Côte d'Ivoire"),
  [CountryCode.CMR]: t('country.cmr', 'Cameroon'),
  [CountryCode.COD]: t('country.cod', 'Congo (the Democratic Republic of the)'),
  [CountryCode.COG]: t('country.cog', 'Congo'),
  [CountryCode.COK]: t('country.cok', 'Cook Islands'),
  [CountryCode.COL]: t('country.col', 'Colombia'),
  [CountryCode.COM]: t('country.com', 'Comoros'),
  [CountryCode.CPV]: t('country.cpv', 'Cabo Verde'),
  [CountryCode.CRI]: t('country.cri', 'Costa Rica'),
  [CountryCode.CUB]: t('country.cub', 'Cuba'),
  [CountryCode.CUW]: t('country.cuw', 'Curaçao'),
  [CountryCode.CXR]: t('country.cxr', 'Christmas Island'),
  [CountryCode.CYM]: t('country.cym', 'Cayman Islands'),
  [CountryCode.CYP]: t('country.cyp', 'Cyprus'),
  [CountryCode.CZE]: t('country.cze', 'Czechia'),
  [CountryCode.DEU]: t('country.deu', 'Germany'),
  [CountryCode.DJI]: t('country.dji', 'Djibouti'),
  [CountryCode.DMA]: t('country.dma', 'Dominica'),
  [CountryCode.DNK]: t('country.dnk', 'Denmark'),
  [CountryCode.DOM]: t('country.dom', 'Dominican Republic'),
  [CountryCode.DZA]: t('country.dza', 'Algeria'),
  [CountryCode.ECU]: t('country.ecu', 'Ecuador'),
  [CountryCode.EGY]: t('country.egy', 'Egypt'),
  [CountryCode.ERI]: t('country.eri', 'Eritrea'),
  [CountryCode.ESH]: t('country.esh', 'Western Sahara'),
  [CountryCode.ESP]: t('country.esp', 'Spain'),
  [CountryCode.EST]: t('country.est', 'Estonia'),
  [CountryCode.ETH]: t('country.eth', 'Ethiopia'),
  [CountryCode.FIN]: t('country.fin', 'Finland'),
  [CountryCode.FJI]: t('country.fji', 'Fiji'),
  [CountryCode.FLK]: t('country.flk', 'Falkland Islands [Malvinas]'),
  [CountryCode.FRA]: t('country.fra', 'France'),
  [CountryCode.FRO]: t('country.fro', 'Faroe Islands'),
  [CountryCode.FSM]: t('country.fsm', 'Micronesia (Federated States of)'),
  [CountryCode.GAB]: t('country.gab', 'Gabon'),
  [CountryCode.GBR]: t(
    'country.gbr',
    'United Kingdom of Great Britain and Northern Ireland',
  ),
  [CountryCode.GEO]: t('country.geo', 'Georgia'),
  [CountryCode.GGY]: t('country.ggy', 'Guernsey'),
  [CountryCode.GHA]: t('country.gha', 'Ghana'),
  [CountryCode.GIB]: t('country.gib', 'Gibraltar'),
  [CountryCode.GIN]: t('country.gin', 'Guinea'),
  [CountryCode.GLP]: t('country.glp', 'Guadeloupe'),
  [CountryCode.GMB]: t('country.gmb', 'Gambia'),
  [CountryCode.GNB]: t('country.gnb', 'Guinea-Bissau'),
  [CountryCode.GNQ]: t('country.gnq', 'Equatorial Guinea'),
  [CountryCode.GRC]: t('country.grc', 'Greece'),
  [CountryCode.GRD]: t('country.grd', 'Grenada'),
  [CountryCode.GRL]: t('country.grl', 'Greenland'),
  [CountryCode.GTM]: t('country.gtm', 'Guatemala'),
  [CountryCode.GUF]: t('country.guf', 'French Guiana'),
  [CountryCode.GUM]: t('country.gum', 'Guam'),
  [CountryCode.GUY]: t('country.guy', 'Guyana'),
  [CountryCode.HKG]: t('country.hkg', 'Hong Kong'),
  [CountryCode.HMD]: t('country.hmd', 'Heard Island and McDonald Islands'),
  [CountryCode.HND]: t('country.hnd', 'Honduras'),
  [CountryCode.HRV]: t('country.hrv', 'Croatia'),
  [CountryCode.HTI]: t('country.hti', 'Haiti'),
  [CountryCode.HUN]: t('country.hun', 'Hungary'),
  [CountryCode.IDN]: t('country.idn', 'Indonesia'),
  [CountryCode.IMN]: t('country.imn', 'Isle of Man'),
  [CountryCode.IND]: t('country.ind', 'India'),
  [CountryCode.IOT]: t('country.iot', 'British Indian Ocean Territory'),
  [CountryCode.IRL]: t('country.irl', 'Ireland'),
  [CountryCode.IRN]: t('country.irn', 'Iran (Islamic Republic of)'),
  [CountryCode.IRQ]: t('country.irq', 'Iraq'),
  [CountryCode.ISL]: t('country.isl', 'Iceland'),
  [CountryCode.ISR]: t('country.isr', 'Israel'),
  [CountryCode.ITA]: t('country.ita', 'Italy'),
  [CountryCode.JAM]: t('country.jam', 'Jamaica'),
  [CountryCode.JEY]: t('country.jey', 'Jersey'),
  [CountryCode.JOR]: t('country.jor', 'Jordan'),
  [CountryCode.JPN]: t('country.jpn', 'Japan'),
  [CountryCode.KAZ]: t('country.kaz', 'Kazakhstan'),
  [CountryCode.KEN]: t('country.ken', 'Kenya'),
  [CountryCode.KGZ]: t('country.kgz', 'Kyrgyzstan'),
  [CountryCode.KHM]: t('country.khm', 'Cambodia'),
  [CountryCode.KIR]: t('country.kir', 'Kiribati'),
  [CountryCode.KNA]: t('country.kna', 'Saint Kitts and Nevis'),
  [CountryCode.KOR]: t('country.kor', 'Korea (the Republic of)'),
  [CountryCode.KWT]: t('country.kwt', 'Kuwait'),
  [CountryCode.LAO]: t('country.lao-alt', {
    defaultValue: 'Lao People\u2019s Democratic Republic',
    interpolation: { escapeValue: false },
  }),
  [CountryCode.LBN]: t('country.lbn', 'Lebanon'),
  [CountryCode.LBR]: t('country.lbr', 'Liberia'),
  [CountryCode.LBY]: t('country.lby', 'Libya'),
  [CountryCode.LCA]: t('country.lca', 'Saint Lucia'),
  [CountryCode.LIE]: t('country.lie', 'Liechtenstein'),
  [CountryCode.LKA]: t('country.lka', 'Sri Lanka'),
  [CountryCode.LSO]: t('country.lso', 'Lesotho'),
  [CountryCode.LTU]: t('country.ltu', 'Lithuania'),
  [CountryCode.LUX]: t('country.lux', 'Luxembourg'),
  [CountryCode.LVA]: t('country.lva', 'Latvia'),
  [CountryCode.MAC]: t('country.mac', 'Macao'),
  [CountryCode.MAF]: t('country.maf', 'Saint Martin (French part)'),
  [CountryCode.MAR]: t('country.mar', 'Morocco'),
  [CountryCode.MCO]: t('country.mco', 'Monaco'),
  [CountryCode.MDA]: t('country.mda', 'Moldova (the Republic of)'),
  [CountryCode.MDG]: t('country.mdg', 'Madagascar'),
  [CountryCode.MDV]: t('country.mdv', 'Maldives'),
  [CountryCode.MEX]: t('country.mex', 'Mexico'),
  [CountryCode.MHL]: t('country.mhl', 'Marshall Islands'),
  [CountryCode.MKD]: t('country.mkd', 'Republic of North Macedonia'),
  [CountryCode.MLI]: t('country.mli', 'Mali'),
  [CountryCode.MLT]: t('country.mlt', 'Malta'),
  [CountryCode.MMR]: t('country.mmr', 'Myanmar'),
  [CountryCode.MNE]: t('country.mne', 'Montenegro'),
  [CountryCode.MNG]: t('country.mng', 'Mongolia'),
  [CountryCode.MNP]: t('country.mnp', 'Northern Mariana Islands'),
  [CountryCode.MOZ]: t('country.moz', 'Mozambique'),
  [CountryCode.MRT]: t('country.mrt', 'Mauritania'),
  [CountryCode.MSR]: t('country.msr', 'Montserrat'),
  [CountryCode.MTQ]: t('country.mtq', 'Martinique'),
  [CountryCode.MUS]: t('country.mus', 'Mauritius'),
  [CountryCode.MWI]: t('country.mwi', 'Malawi'),
  [CountryCode.MYS]: t('country.mys', 'Malaysia'),
  [CountryCode.MYT]: t('country.myt', 'Mayotte'),
  [CountryCode.NAM]: t('country.nam', 'Namibia'),
  [CountryCode.NCL]: t('country.ncl', 'New Caledonia'),
  [CountryCode.NER]: t('country.ner', 'Niger'),
  [CountryCode.NFK]: t('country.nfk', 'Norfolk Island'),
  [CountryCode.NGA]: t('country.nga', 'Nigeria'),
  [CountryCode.NIC]: t('country.nic', 'Nicaragua'),
  [CountryCode.NIU]: t('country.niu', 'Niue'),
  [CountryCode.NLD]: t('country.nld', 'Netherlands'),
  [CountryCode.NOR]: t('country.nor', 'Norway'),
  [CountryCode.NPL]: t('country.npl', 'Nepal'),
  [CountryCode.NRU]: t('country.nru', 'Nauru'),
  [CountryCode.NZL]: t('country.nzl', 'New Zealand'),
  [CountryCode.OMN]: t('country.omn', 'Oman'),
  [CountryCode.PAK]: t('country.pak', 'Pakistan'),
  [CountryCode.PAN]: t('country.pan', 'Panama'),
  [CountryCode.PCN]: t('country.pcn', 'Pitcairn'),
  [CountryCode.PER]: t('country.per', 'Peru'),
  [CountryCode.PHL]: t('country.phl', 'Philippines'),
  [CountryCode.PLW]: t('country.plw', 'Palau'),
  [CountryCode.PNG]: t('country.png', 'Papua New Guinea'),
  [CountryCode.POL]: t('country.pol', 'Poland'),
  [CountryCode.PRI]: t('country.pri', 'Puerto Rico'),
  [CountryCode.PRK]: t(
    'country.prk',
    "Korea (the Democratic People's Republic of)",
  ),
  [CountryCode.PRT]: t('country.prt', 'Portugal'),
  [CountryCode.PRY]: t('country.pry', 'Paraguay'),
  [CountryCode.PSE]: t('country.pse', 'Palestine, State of'),
  [CountryCode.PYF]: t('country.pyf', 'French Polynesia'),
  [CountryCode.QAT]: t('country.qat', 'Qatar'),
  [CountryCode.REU]: t('country.reu', 'Réunion'),
  [CountryCode.ROU]: t('country.rou', 'Romania'),
  [CountryCode.RUS]: t('country.rus', 'Russian Federation'),
  [CountryCode.RWA]: t('country.rwa', 'Rwanda'),
  [CountryCode.SAU]: t('country.sau', 'Saudi Arabia'),
  [CountryCode.SDN]: t('country.sdn', 'Sudan'),
  [CountryCode.SEN]: t('country.sen', 'Senegal'),
  [CountryCode.SGP]: t('country.sgp', 'Singapore'),
  [CountryCode.SGS]: t(
    'country.sgs',
    'South Georgia and the South Sandwich Islands',
  ),
  [CountryCode.SHN]: t(
    'country.shn',
    'Saint Helena, Ascension and Tristan da Cunha',
  ),
  [CountryCode.SJM]: t('country.sjm', 'Svalbard and Jan Mayen'),
  [CountryCode.SLB]: t('country.slb', 'Solomon Islands'),
  [CountryCode.SLE]: t('country.sle', 'Sierra Leone'),
  [CountryCode.SLV]: t('country.slv', 'El Salvador'),
  [CountryCode.SMR]: t('country.smr', 'San Marino'),
  [CountryCode.SOM]: t('country.som', 'Somalia'),
  [CountryCode.SPM]: t('country.spm', 'Saint Pierre and Miquelon'),
  [CountryCode.SRB]: t('country.srb', 'Serbia'),
  [CountryCode.SSD]: t('country.ssd', 'South Sudan'),
  [CountryCode.STP]: t('country.stp', 'Sao Tome and Principe'),
  [CountryCode.SUR]: t('country.sur', 'Suriname'),
  [CountryCode.SVK]: t('country.svk', 'Slovakia'),
  [CountryCode.SVN]: t('country.svn', 'Slovenia'),
  [CountryCode.SWE]: t('country.swe', 'Sweden'),
  [CountryCode.SWZ]: t('country.swz', 'Eswatini'),
  [CountryCode.SXM]: t('country.sxm', 'Sint Maarten (Dutch part)'),
  [CountryCode.SYC]: t('country.syc', 'Seychelles'),
  [CountryCode.SYR]: t('country.syr', 'Syrian Arab Republic'),
  [CountryCode.TCA]: t('country.tca', 'Turks and Caicos Islands'),
  [CountryCode.TCD]: t('country.tcd', 'Chad'),
  [CountryCode.TGO]: t('country.tgo', 'Togo'),
  [CountryCode.THA]: t('country.tha', 'Thailand'),
  [CountryCode.TJK]: t('country.tjk', 'Tajikistan'),
  [CountryCode.TKL]: t('country.tkl', 'Tokelau'),
  [CountryCode.TKM]: t('country.tkm', 'Turkmenistan'),
  [CountryCode.TLS]: t('country.tls', 'Timor-Leste'),
  [CountryCode.TON]: t('country.ton', 'Tonga'),
  [CountryCode.TTO]: t('country.tto', 'Trinidad and Tobago'),
  [CountryCode.TUN]: t('country.tun', 'Tunisia'),
  [CountryCode.TUR]: t('country.tur', 'Turkey'),
  [CountryCode.TUV]: t('country.tuv', 'Tuvalu'),
  [CountryCode.TWN]: t('country.twn', 'Taiwan (Province of China)'),
  [CountryCode.TZA]: t('country.tza', 'Tanzania, United Republic of'),
  [CountryCode.UGA]: t('country.uga', 'Uganda'),
  [CountryCode.UKR]: t('country.ukr', 'Ukraine'),
  [CountryCode.UMI]: t('country.umi', 'United States Minor Outlying Islands'),
  [CountryCode.URY]: t('country.ury', 'Uruguay'),
  [CountryCode.USA]: t('country.usa', 'United States of America'),
  [CountryCode.UZB]: t('country.uzb', 'Uzbekistan'),
  [CountryCode.VAT]: t('country.vat', 'Holy See'),
  [CountryCode.VCT]: t('country.vct', 'Saint Vincent and the Grenadines'),
  [CountryCode.VEN]: t('country.ven', 'Venezuela (Bolivarian Republic of)'),
  [CountryCode.VGB]: t('country.vgb', 'Virgin Islands (British)'),
  [CountryCode.VIR]: t('country.vir', 'Virgin Islands (U.S.)'),
  [CountryCode.VNM]: t('country.vnm', 'Viet Nam'),
  [CountryCode.VUT]: t('country.vut', 'Vanuatu'),
  [CountryCode.WLF]: t('country.wlf', 'Wallis and Futuna'),
  [CountryCode.WSM]: t('country.wsm', 'Samoa'),
  [CountryCode.YEM]: t('country.yem', 'Yemen'),
  [CountryCode.ZAF]: t('country.zaf', 'South Africa'),
  [CountryCode.ZMB]: t('country.zmb', 'Zambia'),
  [CountryCode.ZWE]: t('country.zwe', 'Zimbabwe'),
});

export default countryLabels;
