/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import TextInput from 'common/components/text-input';
import EndDateField from 'contract-onboarding/company/components/end-date-field';

import { Contract, ContractType } from '__generated__/graphql';

const useGetEndDateLabel = (contractType: Contract['type']) => {
  const { t } = useTranslation('contract-onboarding.common');

  switch (contractType) {
    case ContractType.FREELANCER:
      return t(
        'definition-phase.basic-details.freelancer-end-date.label',
        'Contract End Date',
      );
    case ContractType.CONTRACTOR:
      return t(
        'definition-phase.basic-details.contractor-end-date.label',
        'Agreement End Date',
      );
    default:
      return t(
        'definition-phase.basic-details.employment-end-date.label',
        'Employment End Date',
      );
  }
};

const EndDateInfo: React.FC<{
  contractType: Contract['type'];
}> = ({ contractType }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const endDateInfo = {
    [ContractType.FREELANCER]: t(
      'definition-phase.basic-details.employment-end-date.info',
      'In unspecified, freelancer contract will be valid for a year and will be renewed annually.',
    ),
    [ContractType.CONTRACTOR]: t(
      'definition-phase.basic-details.employment-end-date.contractor.info',
      'In unspecified, contractor contract will be valid for a year and will be renewed annually.',
    ),
    [ContractType.EMPLOYEE]: '',
    [ContractType.HR_MEMBER]: '',
  };

  const showInfo = contractType === ContractType.FREELANCER;

  if (!showInfo) {
    return null;
  }

  return (
    <div tw="flex flex-row mt-extra-small items-start">
      <Icon
        name="info"
        tw="mr-tiny w-large"
        fill={
          isNewThemeApplied ? theme`colors.icon-tertiary` : theme`colors.grey01`
        }
      />
      <span
        css={[
          tw`text-ps font-normal text-text-tertiary`,
          !isNewThemeApplied && tw`text-grey01`,
        ]}
      >
        {endDateInfo[contractType]}
      </span>
    </div>
  );
};

const EndDateSelector: React.FC<{
  selectedCountry: Contract['country'];
  selectedCountryState: Contract['countryStateCode'];
  selectedContractType: Contract['type'];
}> = ({ selectedCountry, selectedCountryState, selectedContractType }) => (
  <TextInput.Container>
    <TextInput.Label htmlFor="end-on">
      {useGetEndDateLabel(selectedContractType)}
    </TextInput.Label>
    <EndDateField
      selectedCountry={selectedCountry}
      selectedCountryState={selectedCountryState}
      selectedContractType={selectedContractType}
    />
    <EndDateInfo contractType={selectedContractType} />
  </TextInput.Container>
);

export default EndDateSelector;
