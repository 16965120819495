/** @jsxImportSource @emotion/react */
import React from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import tw from 'twin.macro';
import * as yup from 'yup';

import Dialog from 'common/components/dialog';
import Textarea from 'common/components/textarea';

import {
  Contract,
  ContractOffboardMutationVariables,
  ContractType,
} from '__generated__/graphql';

import { getOffboardText } from '../../pages/member-view/components/offboard-member-dialog';

interface OffboardNotStartedMemberDialogProps {
  contract: Contract | undefined;
  show: boolean;
  loading: boolean;
  onConfirm: (values: ContractOffboardMutationVariables) => void;
  onClose: () => void;
}

const LegacyOffboardingForNotStartedMemberDialog = ({
  contract,
  show,
  loading,
  onClose,
  onConfirm,
}: OffboardNotStartedMemberDialogProps): React.ReactElement | null => {
  const { t } = useTranslation('team.company');

  const today = new Date();

  const schema = yup.object().shape({
    reason: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleConfirmClick = () => {
    handleSubmit(({ reason }) => {
      if (contract?.id) {
        onConfirm({
          id: contract.id,
          lastWorkingDay: format(today, 'yyyy-MM-dd'),
          offboardingNote: reason,
        });
      }
    })();
  };

  return (
    <Dialog
      open={show}
      disabled={!isValid}
      loading={loading}
      onClose={onClose}
      onConfirm={handleConfirmClick}
      title={getOffboardText(
        t,
        'text',
        contract?.type || ContractType.EMPLOYEE,
      )}
      buttonText={t('offboard-not-started-member-dialog.continue', 'Continue')}
      containerStyle={tw`overflow-visible`}
    >
      <div tw="text-18 text-background mb-6">
        {t(
          'offboard-not-started-member-dialog.header',
          'Member not started yet?',
        )}
      </div>
      <div tw="text-ps text-grey01 mb-24">
        {t(
          'offboard-not-started-member-dialog.description',
          'If the member has not started their employment term with the organization, please proceed with the offboarding process.',
        )}
      </div>
      <form>
        <div tw="text-background text-ps mb-12 font-medium">
          {t(
            'offboard-member-not-started-dialog.form.reason',
            'Reason for Offboarding',
          )}
        </div>
        <Textarea
          data-testid="reason"
          rows={4}
          tw="flex w-full mb-40 resize-none"
          {...register('reason')}
        />
      </form>
    </Dialog>
  );
};

export default LegacyOffboardingForNotStartedMemberDialog;
