import { useEffect, useMemo } from 'react';

import keyBy from 'lodash/keyBy';

import { notEmpty } from 'app/utils/array';

import {
  ContractTerm,
  ContractType,
  CountryCode,
  DeductionDefinition,
  GetDeductionDefinitionQuery,
  Maybe,
  useGetDeductionDefinitionLazyQuery,
} from '__generated__/graphql';

interface GetAndGroupDeductionDefinitionsParam {
  country?: Maybe<CountryCode>;
  state?: Maybe<string>;
  contractType?: Maybe<ContractType>;
  contractTerm?: Maybe<ContractTerm>;
}

interface GetAndGroupDeductionDefinitionsResult {
  definitions: Maybe<DeductionDefinition[]>;
  definitionMap: Maybe<{
    [deductionDefinitionId: string]: DeductionDefinition;
  }>;
  loading: boolean;
}

const getDeductionsDefinitions = (
  response?: Maybe<GetDeductionDefinitionQuery>,
): Maybe<DeductionDefinition[]> => {
  const deductions =
    response?.country?.compliance?.compensationStructureDefinition?.deductions;
  if (!deductions) {
    return null;
  }

  return deductions.filter(notEmpty);
};

const groupDeductionDefinitionsById = (
  response?: Maybe<GetDeductionDefinitionQuery>,
): Maybe<{ [deductionDefinitionId: string]: DeductionDefinition }> => {
  const deductions = getDeductionsDefinitions(response);

  if (!deductions) return null;

  return keyBy(deductions, 'id');
};

const useGetAndGroupDeductionDefinitions = ({
  country,
  state,
  contractType,
  contractTerm,
}: GetAndGroupDeductionDefinitionsParam): GetAndGroupDeductionDefinitionsResult => {
  const [
    getDeductions,
    { data, loading },
  ] = useGetDeductionDefinitionLazyQuery();

  useEffect(() => {
    if (!country || !contractType || !contractTerm) {
      return;
    }

    getDeductions({
      variables: {
        country,
        state,
        contractType,
        contractTerm,
      },
    });
  }, [country, state, contractType, contractTerm]);

  return {
    definitionMap: useMemo(() => groupDeductionDefinitionsById(data), [data]),
    definitions: useMemo(() => getDeductionsDefinitions(data), [data]),
    loading,
  };
};

export default useGetAndGroupDeductionDefinitions;
