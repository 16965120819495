import React, { useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ComboBox, Icon, TextInput, ToolTip } from '@multiplier/common';
import { theme } from 'twin.macro';

import { useGetDepartmentsQuery } from '__generated__/graphql';

import { BasicDetailsFormValues } from '../basic-details';

const DepartmentSelector: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');
  const { data: departmentData } = useGetDepartmentsQuery();

  const { control } = useFormContext<BasicDetailsFormValues>();

  const allDepartments = useMemo(
    () =>
      (departmentData?.departments ?? [])?.map((department) => ({
        label: department?.name,
        value: department?.id,
        title: department?.name,
      })),
    [departmentData],
  );

  const isNoDepartments = !allDepartments.length;

  return (
    <div>
      <TextInput.Container>
        <TextInput.Label htmlFor="department">
          {t(
            'definition-phase.basic-details.department.label',
            'Department (optional)',
          )}
        </TextInput.Label>
        <Controller
          name="department"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ComboBox
              value={value}
              onChange={onChange}
              id="department"
              placeholder={
                isNoDepartments
                  ? t(
                      'definition-phase.basic-details.department.no-data-placeholder',
                      'No department',
                    )
                  : t(
                      'definition-phase.basic-details.department.placeholder',
                      'Select department',
                    )
              }
              variant="default"
              dropdownValues={allDepartments}
              disabled={isNoDepartments}
              endAdornment={
                isNoDepartments ? (
                  <ToolTip
                    content={t(
                      'definition-phase.basic-details.department.no-data-tooltip',
                      'No departments created yet. You can create new department from Settings',
                    )}
                  >
                    <Icon
                      name="info"
                      height={16}
                      width={16}
                      tw="ml-4"
                      fill={theme`colors.background`}
                    />
                  </ToolTip>
                ) : null
              }
            />
          )}
        />
        {isNoDepartments && (
          <Link
            tw="underline text-primary font-semibold mt-8"
            to="/company/organization-settings/company-structure/departments/create"
          >
            {t(
              'definition-phase.basic-details.department.create-first-department',
              'Create first department',
            )}
          </Link>
        )}
      </TextInput.Container>
    </div>
  );
};
export default DepartmentSelector;
