/** @jsxImportSource @emotion/react */
import React from 'react';

import tw, { TwStyle } from 'twin.macro';

interface RadioProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  labelStyles?: TwStyle;
  divStyle?: TwStyle;
}

const ButtonSwitch = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<RadioProps>
>(({ children, labelStyles, divStyle, disabled = false, ...props }, ref) => (
  <div
    css={[
      tw`flex cursor-pointer mx-12 w-full rounded-4 p-2 h-36 justify-center items-center`,
      divStyle,
      disabled && tw`opacity-40`,
    ]}
  >
    <label
      css={[
        tw`flex mx-12 w-full cursor-pointer justify-center items-center text-p font-medium`,
        labelStyles,
      ]}
    >
      <input
        type="radio"
        disabled={disabled}
        css={[tw`hidden`]}
        ref={ref}
        {...props}
      />
      {children}
    </label>
  </div>
));

export default ButtonSwitch;
