import { useEffect, useMemo } from 'react';

import {
  CompensationPayComponent,
  CompensationPayComponentDefinition,
  Contract,
  ContractOnboarding,
  ContractOnboardingStatus,
  Maybe,
  useGetCompensationStructureDefinitionLazyQuery,
} from '__generated__/graphql';

import { notEmpty } from '../../../app/utils/array';

export const requiredAdditionalPaysExists = (
  additionalPaysDefinition:
    | Maybe<CompensationPayComponentDefinition>[]
    | null
    | undefined,
  additionalPays: Maybe<CompensationPayComponent>[] | null | undefined,
): boolean =>
  additionalPaysDefinition
    ?.filter(notEmpty)
    .every((additionalPayDefinition) => {
      if (additionalPayDefinition?.isDeletable === false) {
        return (
          additionalPays?.find(
            (existingAdditionalPay) =>
              existingAdditionalPay?.name === additionalPayDefinition?.name,
          ) != null
        );
      }

      return true;
    }) ?? false;

const useShowAdditionalPaysWarning = (
  country: Contract['country'],
  countryStateCode: Contract['countryStateCode'],
  type: Contract['type'],
  term: Contract['term'],
  compensation: Contract['compensation'],
  currentStatus: ContractOnboarding['status'],
): { shouldShowAdditionalPaysWarning: boolean; loading: boolean } => {
  const [
    getCompensationStructureDefinition,
    { data, loading },
  ] = useGetCompensationStructureDefinitionLazyQuery();

  useEffect(() => {
    if (country && type && term) {
      getCompensationStructureDefinition({
        variables: { country, countryStateCode, type, term },
      });
    }
  }, [country, countryStateCode, type, term]);

  const shouldShowAdditionalPaysWarning = useMemo(
    () =>
      currentStatus === ContractOnboardingStatus.CREATED &&
      !requiredAdditionalPaysExists(
        data?.country?.compliance?.compensationStructureDefinition
          ?.additionalPays,
        compensation?.additionalPays,
      ),
    [
      data?.country?.compliance?.compensationStructureDefinition
        ?.additionalPays,
      compensation?.additionalPays,
    ],
  );

  return { shouldShowAdditionalPaysWarning, loading };
};

export default useShowAdditionalPaysWarning;
