import { TFunction } from 'i18next';
import * as yup from 'yup';

import { fileSchema } from 'app/utils/file-schema-utils';

import { Member } from '__generated__/graphql';

import { MemberContactDetailsFormValues } from '../components/contact-details-section';

const getContactDetailsSectionSchema = (
  requireDocument: boolean,
  t: TFunction,
  member?: Member | null,
): yup.ObjectSchema<{
  email?: yup.StringSchema;
  phone?: yup.ObjectSchema<{
    countryCode: yup.StringSchema;
    number: yup.StringSchema;
  }>;
  address?: yup.ObjectSchema<{
    street: yup.StringSchema<string | null | undefined>;
    line1: yup.StringSchema;
    line2: yup.StringSchema<string | null | undefined>;
    postalCode: yup.StringSchema;
  }>;
}> =>
  yup.object().shape({
    email: yup
      .string()
      .email()
      .required(t('member.basic-details.email.required', 'Email is required')),
    phone: yup.object().shape({
      countryCode: yup
        .string()
        .required(
          t(
            'member.basic-details.country-code.required',
            'Country Code is required',
          ),
        ),
      number: yup
        .string()
        .required(
          t('member.basic-details.phone.required', 'Phone Number is required'),
        )
        .max(
          25,
          t('member.basic-details.phone.max', 'Phone Number is too long'),
        ),
    }),
    address: yup.object().shape({
      street: yup.string().nullable(),
      line1: yup
        .string()
        .required(
          t('member.basic-details.address.required', 'Address is required'),
        ),
      line2: yup.string().nullable(),
      city: yup
        .string()
        .required(
          t('member.basic-details.address-city.required', 'City is required'),
        ),
      postalCode: yup
        .string()
        .required(
          t(
            'member.basic-details.postal-code.required',
            'Postal Code is required',
          ),
        ),
    }),
    documentsProof: yup.lazy((_value, ctx) => {
      if (requireDocument && !isAddressEqual(ctx.parent, member)) {
        return yup
          .array()
          .of(fileSchema)
          .min(
            1,
            t(
              'member.basic-details.documents.required',
              'Identity proof is required',
            ),
          );
      }
      return yup.array().of(fileSchema).nullable();
    }),
  });

const isAddressEqual = (
  formValue: MemberContactDetailsFormValues,
  member?: Member | null,
) => {
  const address = member?.addresses?.[0];

  return (
    address?.city === formValue?.address?.city &&
    address?.line1 === formValue?.address?.line1 &&
    address?.line2 === formValue?.address?.line2 &&
    address?.street === formValue?.address?.street &&
    address?.street === formValue?.address?.street &&
    address?.state === formValue?.address?.state &&
    address?.postalCode === formValue?.address?.postalCode
  );
};

export default getContactDetailsSectionSchema;
