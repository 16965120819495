import React, { useContext } from 'react';

import tw, { theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';
import {
  contentStyles,
  dangerOutlineStyles,
  dangerStyles,
  getDefaultOrPrimaryStyles,
  getSecondaryOrOutlineStyles,
  inlineStyles,
  tertiaryStyles,
} from './button-variant-styles';
import { ButtonProps, ButtonSize, ButtonVariant } from './types';

// TODO: rebrand -- remove old variants when theme Multiplier 2.2 is fully implemented
const getVariantStyles = (variant: ButtonVariant, isNewTheme: boolean) => {
  switch (variant) {
    // varinats to keep
    case 'primary':
      return getDefaultOrPrimaryStyles(isNewTheme);
    case 'secondary':
      return getSecondaryOrOutlineStyles(isNewTheme);
    case 'tertiary':
      return tertiaryStyles;
    case 'inline':
      return inlineStyles;
    case 'content':
      return contentStyles;
    // varinats to remove
    case 'default':
      return getDefaultOrPrimaryStyles(isNewTheme);
    case 'outline':
      return getSecondaryOrOutlineStyles(isNewTheme);
    case 'danger':
      return dangerStyles;
    case 'dangerOutline':
      return dangerOutlineStyles;
    default:
      return getDefaultOrPrimaryStyles(isNewTheme);
  }
};

const getPositiveIntentStyles = (variant: ButtonVariant) => {
  if (variant === 'primary') {
    return tw`bg-background-positive hover:bg-background-positive-hover`;
  }

  if (variant === 'secondary') {
    return tw`border-border-positive text-text-positive hover:bg-background-primary`;
  }

  if (variant === 'tertiary') {
    return tw`bg-background-positive-subtle text-text-positive hover:bg-background-positive-faded`;
  }

  return undefined;
};

const getNegativeIntentStyles = (variant: ButtonVariant) => {
  if (variant === 'primary') {
    return tw`bg-background-negative hover:bg-background-negative-hover`;
  }

  if (variant === 'secondary') {
    return tw`border-border-negative text-text-negative hover:bg-background-primary`;
  }

  if (variant === 'tertiary') {
    return tw`bg-background-negative-subtle text-text-negative hover:bg-background-negative-faded`;
  }

  return undefined;
};

const sizeStyles = {
  small: tw`h-36 text-ps rounded-[5px]`,
  medium: tw`h-46 text-18 rounded-base`,
  large: tw`h-64 text-pl rounded-base`,
};

const sizeStylesNewTheme = {
  small: tw`h-36 text-ps`,
  medium: tw`h-40 text-p`,
  large: tw`h-48 text-p`,
};

const getButtonSizeStyles = (size: ButtonSize, isNewTheme: boolean) => {
  if (isNewTheme) {
    return sizeStylesNewTheme[size];
  }

  return sizeStyles[size];
};

const Button: React.FC<ButtonProps> = ({
  size = 'medium',
  variant = 'primary',
  intent = 'neutral',
  children,
  loading = false,
  selected = false,
  warning = false,
  styles = tw``,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  const getSpinnerFillColor = () => {
    if (variant === 'primary' || variant === 'default') {
      return theme`colors.icon-inverted-primary`;
    }

    return undefined;
  };

  return (
    <button
      type="button"
      css={[
        tw`relative font-medium px-24`,
        tw`focus:(outline-none ring-transparent)`,
        tw`focus-visible:(outline-primary)`,
        tw`disabled:cursor-not-allowed`,
        isNewThemeApplied && tw`rounded-full font-semibold`,
        getVariantStyles(variant, isNewThemeApplied),
        size && getButtonSizeStyles(size, isNewThemeApplied),
        intent && intent === 'positive' && getPositiveIntentStyles(variant),
        intent && intent === 'negative' && getNegativeIntentStyles(variant),
        selected &&
          isNewThemeApplied &&
          tw`bg-background-action text-white hover:bg-background-action`,
        selected &&
          !isNewThemeApplied &&
          tw`bg-foreground text-white hover:bg-foreground`,
        styles,
      ]}
      {...props}
    >
      <div css={loading ? tw`invisible` : tw``}>{children}</div>

      {loading && (
        <Icon
          data-testid="spinner"
          name="spinner"
          tw="absolute animate-spin m-auto left-0 right-0 top-0 bottom-0"
          fill={isNewThemeApplied ? getSpinnerFillColor() : 'transparent'}
        />
      )}

      {warning ? (
        <div
          css={[
            tw`absolute text-[12px] w-[20px] h-[20px] right-[4px] text-white top-0 rounded-full`,
            tw`flex justify-center items-center transform[translate(50%, -50%)]`,
          ]}
        >
          <Icon name="error" fill={theme`colors.text-negative`} />
        </div>
      ) : null}
    </button>
  );
};

export default Button;
