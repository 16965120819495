import {
  Company,
  Maybe,
  useGetCompanyBasicLazyQuery,
} from '../__generated__/graphql';

const useGetCompanyBasicDetails = (): {
  fetchCompanyDetails: () => void;
  companyDetails: Maybe<Company> | undefined;
  loading: boolean;
} => {
  const [trigger, { data, loading }] = useGetCompanyBasicLazyQuery();

  const fetchCompanyDetails = () => {
    trigger();
  };

  return {
    fetchCompanyDetails,
    loading,
    companyDetails: data?.company,
  };
};

export default useGetCompanyBasicDetails;
