import ProgressWidgetContentVariant from 'contract-onboarding/models/progress-widget-content-variant';

import { ContractOnboardingStepConfig } from '../step-config';

const onboardingVerifying: ContractOnboardingStepConfig = {
  title: (t): string =>
    t('onboarding-phase.onboarding-verifying.title', 'Verifying Details'),
  status: (t): string =>
    t('onboarding-phase.onboarding-verifying.status', 'Pending on Multiplier'),
  variant: ProgressWidgetContentVariant.STATUSES,
};

export default onboardingVerifying;
