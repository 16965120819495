/** @jsxImportSource @emotion/react */
import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon, TextInput, ToolTip } from '@multiplier/common';
import { theme } from 'twin.macro';

import { BasicDetailsFormValues } from '../basic-details';

const EmployeeIdInput: React.FC<{
  optional: boolean;
}> = ({ optional }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    register,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  return (
    <div tw="grid grid-cols-2 gap-x-base gap-y-large">
      <TextInput.Container>
        <TextInput.Label htmlFor="employee-id" tw="flex items-center">
          {t('definition-phase.basic-details.employee-id.label', 'Employee ID')}
          {optional && t('label.optional', ' (optional)')}
          {optional && (
            <ToolTip
              content={t(
                'definition-phase.basic-details.employee-id.tooltip',
                'Employee id',
              )}
            >
              <Icon
                name="info"
                height={16}
                width={16}
                tw="ml-tiny"
                fill={theme`colors.icon-primary`}
              />
            </ToolTip>
          )}
        </TextInput.Label>
        <TextInput
          id="employee-id"
          placeholder={t(
            'definition-phase.basic-details.employee-id.placeholder',
            'MULT0001',
          )}
          {...register('employeeId')}
          error={!!errors.employeeId}
          autoComplete="off"
        />
        {errors.employeeId && (
          <TextInput.Error>{errors.employeeId.message}</TextInput.Error>
        )}
      </TextInput.Container>
    </div>
  );
};

export default EmployeeIdInput;
