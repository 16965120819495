import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { usePermission } from '@multiplier/user';
import { isValid, isWithinInterval } from 'date-fns';

import { Contract, ContractStatus, CountryCode } from '__generated__/graphql';

import { needsAttention } from '../../../dashboard/hooks/pending-onboardings';
import {
  TeamViewAllSubTab,
  TeamViewSubTab,
  TeamViewTab,
} from '../pages/team-view';
import { FilteredContracts, groupTeamView } from '../services/contracts';
import { SortParams, handleSortTeam } from './get-team';

export const ONBOARDING_LEAD_TIME_THAT_NEEDS_ATTENTION_IN_DAYS = 10;

interface FilterAndSortContractsHook {
  setSelectedStatus: Dispatch<SetStateAction<string[]>>;
  filteredContracts: FilteredContracts;
  handleTeamSort: (sortBy: unknown) => void;
  setSelectedCountries: Dispatch<SetStateAction<CountryCode[]>>;
  selectedCountries: CountryCode[];
  setSelectedDate: Dispatch<SetStateAction<Record<string, string>>>;
  selectedStatus: string[];
  selectedDate: Record<string, string>;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  setSelectedContractStatus: Dispatch<SetStateAction<string[]>>;
  selectedContractStatus: string[];
  setSelectedEmployment: Dispatch<SetStateAction<string[]>>;
  selectedEmployment: string[];
  setSelectedTab: Dispatch<SetStateAction<TeamViewTab>>;
  selectedTab: TeamViewTab;
  setSelectedSubTab: Dispatch<SetStateAction<TeamViewSubTab | undefined>>;
  selectedSubTab: TeamViewSubTab | undefined;
  setSelectedSubTabAll: Dispatch<SetStateAction<TeamViewAllSubTab>>;
  selectedSubTabAll: TeamViewAllSubTab;
}

const useFilterAndSortContracts = (
  team: Contract[],
): FilterAndSortContractsHook => {
  const [allowViewOnboardingContracts] = usePermission(
    'use.company.contract-onboarding',
  );
  const [selectedTab, setSelectedTab] = useState<TeamViewTab>(
    allowViewOnboardingContracts ? TeamViewTab.ONBOARDING : TeamViewTab.ALL,
  );
  const [selectedCountries, setSelectedCountries] = useState<CountryCode[]>([]);
  const [selectedEmployment, setSelectedEmployment] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedContractStatus, setSelectedContractStatus] = useState<
    string[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Record<string, string>>({});
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedSubTab, setSelectedSubTab] = useState<
    TeamViewSubTab | undefined
  >(undefined);
  const [selectedSubTabAll, setSelectedSubTabAll] = useState<TeamViewAllSubTab>(
    TeamViewAllSubTab.ALL,
  );

  const [sort, setSort] = useState<SortParams>();

  const filteredContracts = useMemo(() => {
    const filteredTeamData = team.filter((data) => {
      let hasCountry = true;
      let hasEmployment = true;
      let hasStatus = true;
      let hasDate = true;
      let hasSearch = true;
      let hasContractStatus = true;
      let hasSelectedSubTab = true;

      if (selectedCountries.length && data.country)
        hasCountry = selectedCountries.includes(data.country);

      if (selectedEmployment.length) {
        const employmentType: string = data.type ?? '';
        hasEmployment = selectedEmployment.includes(employmentType);
      }

      if (selectedStatus.length) {
        const employmentStatus: string = data?.onboarding?.status ?? '';
        hasStatus = selectedStatus.includes(employmentStatus);
      }

      if (selectedContractStatus.length) {
        const contractStatus: string = data?.status ?? '';
        hasContractStatus = selectedContractStatus.includes(contractStatus);
      }

      if (selectedSubTab) {
        hasSelectedSubTab = needsAttention(data.onboarding);
      }

      if (
        Object.keys(selectedDate).length === 2 &&
        isValid(new Date(selectedDate.startDate)) &&
        isValid(new Date(selectedDate.endDate))
      ) {
        const employmentStartDate = data?.startOn;
        try {
          hasDate = isWithinInterval(new Date(employmentStartDate), {
            start: new Date(selectedDate.startDate),
            end: new Date(selectedDate.endDate),
          });
        } catch (e) {
          hasDate = true;
        }
      }

      if (searchQuery.length) {
        hasSearch = `${data.member?.firstName} ${data.member?.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      }

      return (
        hasCountry &&
        hasEmployment &&
        hasStatus &&
        hasDate &&
        hasSearch &&
        hasContractStatus &&
        hasSelectedSubTab &&
        (allowViewOnboardingContracts ||
          data.status !== ContractStatus.ONBOARDING)
      );
    });

    if (sort) {
      const sortedTeamData = handleSortTeam(filteredTeamData, {
        sortBy: sort.sortBy,
        sortDesc: sort.sortDesc,
      });
      return groupTeamView(sortedTeamData);
    }

    return groupTeamView(filteredTeamData);
  }, [
    sort,
    team,
    selectedCountries,
    selectedEmployment,
    selectedStatus,
    selectedContractStatus,
    selectedDate,
    searchQuery,
    selectedSubTab,
    allowViewOnboardingContracts,
  ]);

  const handleTeamSort = useCallback(
    (sortBy: any) => {
      if (sortBy.length > 0) {
        setSort(({
          sortBy: sortBy[0].id,
          sortDesc: sortBy[0].desc,
        } as unknown) as SortParams);
      }
    },
    [setSort],
  );
  return {
    selectedSubTabAll,
    setSelectedSubTabAll,
    selectedTab,
    setSelectedTab,
    selectedCountries,
    setSelectedCountries,
    selectedEmployment,
    setSelectedEmployment,
    selectedStatus,
    setSelectedStatus,
    selectedContractStatus,
    setSelectedContractStatus,
    selectedDate,
    setSelectedDate,
    setSearchQuery,
    selectedSubTab,
    setSelectedSubTab,
    filteredContracts,
    handleTeamSort,
  };
};

export default useFilterAndSortContracts;
