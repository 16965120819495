import { gql } from '@apollo/client';

export default gql`
  query GetCompanyActiveContractsForApproval {
    company {
      id
      contracts {
        id
        country
        countryStateCode
        currency
        categoryApproverInfos {
          category
          approverUsers {
            ... on CompanyUser {
              id
              userId
              emails {
                email
              }
              lastName
              firstName
            }
          }
        }
        orgAttributes {
          department {
            name
          }
        }
        member {
          id
          firstName
          lastName
          emails {
            email
          }
          userId
        }
        type
        term
        position
        status
        startOn
      }
    }
  }
`;
