/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon, ThemeContext } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import { DropdownValue } from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import {
  payFrequencyLabel,
  payTypeLabel,
} from 'common/constants/default-labels';
import {
  defaultFixedPayComponentLabels,
  getFixedPayInputLabelText,
} from 'contract-onboarding/company/components/fixed-pay-input';
import { payoutDateLabelsOnboarding } from 'contract-onboarding/company/pages/definition-phase/pages/compensation/freelancer/common/constants';
import ContractOnboardingDetailRow from 'contract-onboarding/components/detail-row';
import { DetailRowProps } from 'contract-onboarding/components/detail-row/types';
import { ProbationDisclaimerNote } from 'performance-reviews/components/performance-review-form';

import {
  Compensation,
  Contract,
  ContractTerm,
  ContractType,
  PayFrequency,
} from '__generated__/graphql';

import MonthlyPayInput from '../monthly-pay-input';

interface CompensationCtcProps {
  isEditing?: boolean;
  position?: Contract['position'];
  contractTerm?: Contract['term'];
  contractType?: Contract['type'];
  basePay?: Compensation['basePay'];
  probationBasePay?: Compensation['probationBasePay'];
  postProbationBasePay?: Compensation['postProbationBasePay'];
  payType?: Compensation['payType'];
  firstPayoutDate?: string;
  secondPayoutDate?: string;
  currencyOptions?: DropdownValue[];
  employeeStartAfterThisMonth?: boolean;
  startOn?: Contract['startOn'];
  comparing: boolean;
  effectiveDate?: Date;
  isRevisionCard?: boolean;
  salaryDifference?: number;
}

export const DetailRow: React.FC<React.PropsWithChildren<DetailRowProps>> = ({
  children,
  ...props
}) => (
  <ContractOnboardingDetailRow
    labelStyles={tw`font-normal text-text-tertiary`}
    {...props}
  >
    {children}
  </ContractOnboardingDetailRow>
);

const CompensationCtc: React.FC<CompensationCtcProps> = ({
  isEditing,
  position,
  basePay,
  probationBasePay,
  postProbationBasePay,
  payType,
  contractTerm = ContractTerm.PERMANENT,
  contractType = ContractType.EMPLOYEE,
  firstPayoutDate,
  secondPayoutDate,
  currencyOptions,
  employeeStartAfterThisMonth,
  startOn,
  comparing,
  effectiveDate,
  isRevisionCard,
  salaryDifference = 0,
}) => {
  const { t } = useTranslation('team.company');

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { isNewThemeApplied } = useContext(ThemeContext);
  // TODO: For old logics, it's using "postProbationBasePay" but seems its empty most of the time
  // So causing issue cannot see amount, not sure on the previous context. But keep the "basePay" here as fallback
  const payComponent = postProbationBasePay ?? basePay;

  const fixedPayInputLabel = useMemo(
    () =>
      getFixedPayInputLabelText({
        t,
        contractTerm,
        rateType: basePay?.rateType,
        frequency: basePay?.frequency,
        label: basePay?.label,
        isProbationToggleOn: false,
      }),
    [basePay?.rateType, basePay?.frequency],
  );

  const getLabelForInput = (salaryType: string) => {
    if (contractTerm && basePay?.rateType && basePay?.frequency)
      return `${salaryType} ${fixedPayInputLabel}`;

    return t('probation-salary.heading', 'Probation Salary');
  };

  const hasProbationAmount = probationBasePay?.amount !== null;

  return (
    <div tw="mt-extra-large mb-base">
      <p tw="text-text-primary text-p font-semibold">
        {defaultFixedPayComponentLabels[
          `${basePay?.rateType},${basePay?.frequency},base`
        ]?.label(t) ||
          t(
            'compensation-tab.cost-to-company',
            'Salary - Cost to company (CTC)',
          )}
      </p>
      {hasProbationAmount && isEditing && (
        <ProbationDisclaimerNote
          validTillDate={probationBasePay?.validToExclusive}
        />
      )}
      <div
        css={[
          tw`grid grid-cols-4 mt-large gap-y-small grid-cols-[250px_auto_auto_auto]`,
          comparing && tw`grid-cols-2`,
        ]}
      >
        <DetailRow
          id="position"
          label={t('compensation-tab.ctc.designation.label', 'Designation')}
          labelStyles={tw`self-start text-ps font-normal text-text-tertiary`}
          valueStyles={comparing ? tw`col-span-1` : tw`text-text-primary`}
          value={position}
          isEditing={isEditing}
        >
          <TextInput
            divStyles={comparing ? undefined : tw`col-span-3`}
            id="designation"
            placeholder={t(
              'form.promoted-designation.placeholder',
              'Add new job title',
            )}
            error={!!errors.promotedDesignation}
            {...register('promotedDesignation')}
          />
          {errors.promotedDesignation && (
            <TextInput.Error>
              {errors.promotedDesignation?.message as string}
            </TextInput.Error>
          )}
        </DetailRow>
        {hasProbationAmount && (
          <DetailRow
            id="probation-amount"
            labelStyles={tw`self-start text-ps font-normal text-text-tertiary`}
            valueStyles={comparing ? tw`col-span-1` : undefined}
            label={getLabelForInput('Probation')}
            value={probationBasePay?.amount}
            renderValue={
              <MonthlyPayInput
                basePay={probationBasePay}
                comparing={comparing}
              />
            }
            isEditing={false}
          >
            <MonthlyPayInput
              isEditing
              currencyOptions={currencyOptions}
              employeeStartAfterThisMonth={employeeStartAfterThisMonth}
              startOn={startOn}
              comparing={comparing}
            />
          </DetailRow>
        )}
        <DetailRow
          id="amount"
          labelStyles={
            isEditing
              ? tw`mt-base self-start text-ps font-normal text-text-tertiary`
              : tw`self-start text-ps font-normal text-text-tertiary`
          }
          label={
            hasProbationAmount
              ? getLabelForInput('Post-Probation')
              : getLabelForInput('')
          }
          value={payComponent?.amount}
          renderValue={
            <div
              css={[
                tw`flex items-start gap-tiny`,
                comparing ? tw`col-span-1` : tw`col-span-3`,
              ]}
            >
              <MonthlyPayInput basePay={payComponent} comparing={comparing} />
              {isRevisionCard && salaryDifference !== 0 && (
                <Icon
                  name={
                    salaryDifference > 0
                      ? 'arrow-up-circle'
                      : 'arrow-down-circle'
                  }
                  tw="w-base"
                  stroke={
                    isNewThemeApplied
                      ? theme`colors.icon-secondary`
                      : theme`colors.slateGrey800`
                  }
                  fill="transparent"
                />
              )}
            </div>
          }
          isEditing={isEditing}
        >
          <MonthlyPayInput
            isEditing
            basePay={payComponent}
            currencyOptions={currencyOptions}
            employeeStartAfterThisMonth={employeeStartAfterThisMonth}
            startOn={startOn}
            hasProbationAmount={hasProbationAmount}
            comparing={comparing}
          />
        </DetailRow>
        {contractType !== ContractType.EMPLOYEE && (
          <>
            <DetailRow
              id="payment-frequency"
              labelStyles={tw`self-start text-ps font-normal text-text-tertiary`}
              label={t(
                'onboarding-phase.compensation-section.payment-frequency.label',
                'Payment Frequency',
              )}
              valueStyles={comparing ? tw`col-span-1` : undefined}
              value={
                basePay?.paymentFrequency &&
                payFrequencyLabel[basePay?.paymentFrequency]
              }
            />
            <DetailRow
              id="pay-type"
              label={t(
                'compensation-tab.ctc.compensation-type.label',
                'Compensation Type',
              )}
              value={payType && payTypeLabel[payType]}
            />
            <DetailRow
              id="payout-date"
              labelStyles={tw`self-start text-ps font-normal text-text-tertiary`}
              label={
                payoutDateLabelsOnboarding[
                  basePay?.paymentFrequency || PayFrequency.MONTHLY
                ]
              }
              value={firstPayoutDate}
            />
            <DetailRow
              id="second-payout-date"
              label="Second Payout Date"
              value={secondPayoutDate}
            />
          </>
        )}
        {effectiveDate && !isEditing && (
          <DetailRow
            id="effectiveDate"
            label={t(
              'compensation-tab.ctc.effective-date.label',
              'Effective Date',
            )}
            labelStyles={tw`self-start text-ps font-normal text-text-tertiary`}
            valueStyles={comparing ? tw`col-span-1` : tw`text-text-primary`}
            value={dateAsString(effectiveDate, 'do MMMM yyyy')}
          />
        )}
      </div>
    </div>
  );
};

export default CompensationCtc;
