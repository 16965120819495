/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import {
  Controller,
  UseFieldArrayAppend,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@multiplier/common';

import ComboBox, { DropdownValue } from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import { ComplianceFormValues } from 'contract-onboarding/components/contract-section';

import { TimeOffTypeDefinition, TimeOffUnit } from '__generated__/graphql';

import { periodLabels } from '../../compliance-entry';
import { LeaveEntitlementFormEntryValue } from '../index';

interface InitialCustomLeaveEntryFormValues {
  type: string;
  value: number | null;
}

const InitialCustomLeaveEntry: React.FC<{
  append:
    | UseFieldArrayAppend<ComplianceFormValues, 'custom'>
    | UseFieldArrayAppend<Pick<ComplianceFormValues, 'custom'>, 'custom'>;
  availableCustomEntitlementOptions: DropdownValue[];
  timeOffTypeToDefinitionMap: { [key: string]: TimeOffTypeDefinition };
}> = ({
  append,
  availableCustomEntitlementOptions,
  timeOffTypeToDefinitionMap,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<InitialCustomLeaveEntryFormValues>({
    mode: 'onChange',
  });

  const { watch: parentWatch } = useFormContext();

  const onSubmit = (values: InitialCustomLeaveEntryFormValues) => {
    append({
      key: values.type,
      value: values.value,
      unit: TimeOffUnit.DAYS,
      defaultValue:
        timeOffTypeToDefinitionMap[values.type]?.validation?.find(
          (v) => v?.unit === TimeOffUnit.DAYS,
        )?.defaultValue ?? 0,
      maximum: timeOffTypeToDefinitionMap[values.type]?.validation?.find(
        (v) => v?.unit === TimeOffUnit.DAYS,
      )?.maximum,
    });
    reset({ type: '', value: null });
  };

  const maximum = useMemo(
    () =>
      timeOffTypeToDefinitionMap[watch('type')]?.validation?.find(
        (v) => v?.unit === TimeOffUnit.DAYS,
      )?.maximum ?? Number.MAX_SAFE_INTEGER,
    [timeOffTypeToDefinitionMap, watch('type')],
  );

  return (
    <>
      <Controller
        name="type"
        defaultValue=""
        control={control}
        rules={{
          required: true,
          validate: {
            unique: (value) =>
              parentWatch('custom')?.filter(
                (customField: LeaveEntitlementFormEntryValue) =>
                  customField.key === value,
              ).length === 0 ||
              (t(
                'compliance.leave-entitlement.custom-unique',
                'Custom leave types must be unique',
              ) as string),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <ComboBox
            variant="default"
            value={value}
            placeholder={t(
              'compliance.leave-entitlement.leave-type-placeholder',
              'Select Leave Type',
            )}
            dropdownValues={availableCustomEntitlementOptions}
            onChange={(type) => onChange(type)}
            error={!!errors?.type}
          />
        )}
      />
      <TextInput
        data-testid="leave-value-input"
        placeholder="0"
        units={periodLabels[TimeOffUnit.DAYS](2)} // force plural
        {...register('value', {
          setValueAs: (value) =>
            Number.isNaN(parseFloat(value)) ? null : parseFloat(value),
          required: {
            value: true,
            message: t(
              'compliance.leave-entitlement.value.required-error',
              'Input is required',
            ),
          },
          max: {
            value: maximum,
            message: t('compliance.leave-entitlement.value.max-error', {
              defaultValue: 'Total value should be below {{value}}',
              replace: {
                value: maximum,
              },
            }),
          },
          min: {
            value: 0,
            message: t(
              'compliance.leave-entitlement.custom.min-error',
              'Cannot have negative custom leave entitlements',
            ),
          },
          validate: {
            rounded: (value) =>
              (value ?? 0) % 0.5 === 0 ||
              (t(
                'compliance.leave-entitlement.custom.rounded',
                'Only half (0.5) values accepted',
              ) as string),
          },
        })}
        error={!!errors?.value}
        type="number"
        step="0.1"
      />
      <Button
        type="submit"
        variant="primary"
        size="medium"
        disabled={!isValid}
        onClick={handleSubmit(onSubmit)}
      >
        {t('compliance.leave-entitlement.custom.add', 'Add')}
      </Button>
      {errors?.type && (
        <TextInput.Error tw="col-span-3 mt-none">
          {errors.type.message}
        </TextInput.Error>
      )}
      {errors?.value && (
        <TextInput.Error tw="col-span-3 mt-none">
          {errors.value.message}
        </TextInput.Error>
      )}
    </>
  );
};

export default InitialCustomLeaveEntry;
