import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ApolloError } from '@apollo/client';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  ContractOnboardingStep,
  CurrencyCode,
  Maybe,
  PaymentAccountType,
  PaymentPartnerCode,
  TransferType,
  useGetMemberIdQuery,
  useUpdateBankDetailsMutation,
} from '../../../__generated__/graphql';
import { getNextStep } from '../../utils/routing';
import { UpdateBankDetailsParams } from '../pages/onboarding/pages/bank-details';
import stepConfig from '../pages/onboarding/step-config';

export interface OptionalBankDetailsParams {
  accountType?: Maybe<PaymentAccountType>;
  sourceCurrency?: Maybe<CurrencyCode>;
  targetCurrency?: Maybe<CurrencyCode>;
  transferType?: Maybe<TransferType>;
  paymentAccountRequirementType?: Maybe<string>;
  paymentPartner?: Maybe<PaymentPartnerCode>;
}

const useUpdateBankDetails = (
  onComplete?: () => void,
  onError?: (e: ApolloError) => void,
  shouldPropagateError?: boolean,
): {
  updateDetails: (
    isDynamicDetailData: boolean,
    values: UpdateBankDetailsParams,
    optionalBankDetails: OptionalBankDetailsParams,
  ) => void;
  loading: boolean;
} => {
  const navigate = useNavigate();
  const { data: memberResponse } = useGetMemberIdQuery();
  const { t } = useTranslation('contract-onboarding.member');
  const [updateBankDetailsMutation, { loading }] = useUpdateBankDetailsMutation(
    {
      onCompleted() {
        successNotification(
          '',
          t('bank-details.updated-success-message', 'Bank details updated'),
          false,
        );
        if (onComplete) onComplete();
      },
      onError(e) {
        if (onError) {
          onError(e);
        } else {
          errorNotification(
            '',
            t(
              'bank-details.update-error-message',
              'Bank details updation failed',
            ),
            false,
          );
        }
      },
      context: {
        shouldPropagateError,
      },
    },
  );
  const updateDetails = async (
    isDynamicDetailData: boolean,
    values: UpdateBankDetailsParams,
    optionalBankDetails: OptionalBankDetailsParams,
  ) => {
    let member;
    if (!isDynamicDetailData) {
      member = await updateBankDetailsMutation({
        variables: {
          id: String(memberResponse?.member?.id),
          bankAccount: {
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            bankName: values.bankName,
            branchName: values.branchName,
            swiftCode: values.swiftCode,
            localBankCode: values.localBankCode,
            bankStatements: values.bankStatements,
            bankStatementDocIds: values.bankStatementDocIds,
            dynamicDetail: null,
          },
        },
      });
    } else if (isDynamicDetailData) {
      member = await updateBankDetailsMutation({
        variables: {
          id: String(memberResponse?.member?.id),
          bankAccount: {
            dynamicDetail: {
              accountType: optionalBankDetails.accountType,
              paymentPartner: optionalBankDetails.paymentPartner,
              paymentAccountRequirementType:
                optionalBankDetails.paymentAccountRequirementType,
              sourceCurrency: optionalBankDetails.sourceCurrency,
              targetCurrency: optionalBankDetails.targetCurrency,
              transferType: optionalBankDetails.transferType,
              fieldValues: values?.bankData,
            },
            bankStatements: values.bankStatements,
            bankStatementDocIds: values.bankStatementDocIds,
          },
        },
      });
    }

    const onboarding =
      member?.data?.memberUpdateBankDetails?.contracts?.[0]?.onboarding;

    const next = getNextStep(
      onboarding,
      ContractOnboardingStep.MEMBER_BANK_DETAILS,
    );
    if (next && stepConfig[next]) {
      navigate(stepConfig[next].directLink());
    }
  };

  return { updateDetails, loading };
};

export default useUpdateBankDetails;
