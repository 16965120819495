import React from 'react';

import { makeVar } from '@apollo/client';
import { ToastType } from '@multiplier/common';

export const initialNotification = {
  id: 1519211809934,
  shown: false,
  severity: 'success' as ToastType,
  title: '',
  message: '',
  inline: false,
  persistent: false,
};

export const notificationVar = makeVar<
  {
    id: number;
    shown: boolean;
    severity: ToastType;
    title: string;
    message: React.ReactNode;
    inline: boolean;
    persistent: boolean;
  }[]
>([]);
