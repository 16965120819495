/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import 'twin.macro';

import { useFeature } from '@growthbook/growthbook-react';
import { Icon } from '@multiplier/common';
import { AppFeature } from '@multiplier/growthbook';

import WetInkKnowMoreLink from './wet-ink-know-more-link';

export const WetInkCallOutHint: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  if (!useFeature(AppFeature.CONTRACT_ONBOARDING_WITH_WET_INK_ENABLED).on)
    return null;

  return (
    <div tw="flex gap-x-8 items-center px-16 py-12 border-none rounded-8 bg-primaryBlue100">
      <Icon name="circle-tooltip" tw="w-16 h-16" />
      <div tw="text-foreground text-ps">
        <Trans
          t={t}
          i18nKey="definition-phase.eligibility.wet-ink-signature.note"
          defaults="This country mandates wet ink signatures, affecting adjustments to start dates for processing. <a>know more</a>"
          components={{
            a: <WetInkKnowMoreLink />,
          }}
        />
      </div>
    </div>
  );
};

export { WetInkCallOutHint as default };
