import React from 'react';

import { FormCard } from 'contract-onboarding/components/layout';

import {
  Contract,
  DataFieldDefinition,
  GetCountryComplianceLimitationsQuery,
} from '__generated__/graphql';

import MemberLegalDetailsList from './member-legal-details-list';

const LegalDetailSection: React.FC<{
  contractType: Contract['type'];
  contractWorkStatus: Contract['workStatus'];
  contractCountry: Contract['country'];
  countryCompliance: GetCountryComplianceLimitationsQuery | undefined;
  memberDataRequirementFields: DataFieldDefinition[];
}> = ({
  contractType,
  contractWorkStatus,
  contractCountry,
  countryCompliance,
  memberDataRequirementFields,
}) => {
  const shouldShowSection = memberDataRequirementFields.length > 0;

  if (!shouldShowSection) return null;

  return (
    <FormCard data-testid="legal-data-section">
      <MemberLegalDetailsList
        contractType={contractType}
        contractWorkStatus={contractWorkStatus}
        contractCountry={contractCountry}
        countryCompliance={countryCompliance}
        memberDataRequirementFields={memberDataRequirementFields}
      />
    </FormCard>
  );
};

export default LegalDetailSection;
