export const publicOperationNames: { [key: string]: string } = {
  GenerateRjt: 'GenerateRjt',
  GetPublicExpense: 'GetPublicExpense',
  GetPublicISRByLink: 'GetPublicISRByLink',
};

export const publicKeyNames: { [key: string]: string } = {
  GetPublicExpense: 'authCode',
  GetPublicISRByLink: 'authCode',
};

export const publicPagesList: Array<string> = [
  '/login',
  '/documents',
  '/public',
];
