import { ApolloError, FetchResult } from '@apollo/client';

import {
  MemberChangeCategory,
  MemberChangeRequestInput,
  UpdateMemberBankDetailsMutation,
  UpdateMemberBasicDetailsMutation,
  UpdateMemberContactDetailsMutation,
  UpdateMemberIdentificationDetailsMutation,
  useUpdateMemberBankDetailsMutation,
  useUpdateMemberBasicDetailsMutation,
  useUpdateMemberContactDetailsMutation,
  useUpdateMemberIdentificationDetailsMutation,
} from '__generated__/graphql';

interface UseSendChangeRequest {
  sendChangeRequest: (
    values: MemberChangeRequestInput,
  ) => Promise<
    FetchResult<
      | UpdateMemberBankDetailsMutation
      | UpdateMemberBasicDetailsMutation
      | UpdateMemberContactDetailsMutation
      | UpdateMemberIdentificationDetailsMutation
    >
  >;
  loading: boolean;
}

const useSendChangeRequest = (
  category: MemberChangeCategory,
  onComplete?: () => void,
  onError?: (e: ApolloError) => void,
  shouldPropagateError?: boolean,
): UseSendChangeRequest => {
  const [
    updateBasicDetails,
    { loading: loadBasicDetails },
  ] = useUpdateMemberBasicDetailsMutation({
    onCompleted: () => {
      if (onComplete) onComplete();
    },
  });
  const [
    updateContactDetails,
    { loading: loadContactDetails },
  ] = useUpdateMemberContactDetailsMutation({
    onCompleted: () => {
      if (onComplete) onComplete();
    },
  });
  const [
    updateBankDetails,
    { loading: loadBankDetails },
  ] = useUpdateMemberBankDetailsMutation({
    onCompleted: () => {
      if (onComplete) onComplete();
    },
    onError: (e) => {
      if (onError) {
        onError(e);
      }
    },
    context: {
      shouldPropagateError,
    },
  });
  const [
    updateIdentificationDetails,
    { loading: loadIdentificationDetails },
  ] = useUpdateMemberIdentificationDetailsMutation({
    onCompleted: () => {
      onComplete?.();
    },
  });

  const sendChangeRequest = async (
    values: MemberChangeRequestInput,
  ): Promise<
    FetchResult<
      | UpdateMemberBankDetailsMutation
      | UpdateMemberBasicDetailsMutation
      | UpdateMemberContactDetailsMutation
      | UpdateMemberIdentificationDetailsMutation
    >
  > => {
    switch (category) {
      case MemberChangeCategory.BANK_DETAILS:
        return updateBankDetails({
          variables: {
            detail: values,
          },
        });
      case MemberChangeCategory.CONTACT_DETAILS:
        return updateContactDetails({
          variables: {
            detail: values,
          },
        });
      case MemberChangeCategory.IDENTIFICATION_DETAILS:
        return updateIdentificationDetails({
          variables: {
            detail: values,
          },
        });
      default:
        return updateBasicDetails({
          variables: {
            detail: values,
          },
        });
    }
  };

  return {
    sendChangeRequest,
    loading:
      loadBasicDetails ||
      loadBankDetails ||
      loadContactDetails ||
      loadIdentificationDetails,
  };
};

export default useSendChangeRequest;
