import React from 'react';

import tw, { TwStyle } from 'twin.macro';

interface ToggleProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id: string;
  checked?: boolean;
  blockStyle?: TwStyle;
  knobStyle?: TwStyle;
  labelContainerStyle?: TwStyle;
}
const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      id,
      disabled,
      blockStyle,
      knobStyle,
      labelContainerStyle,
      checked,
      ...props
    },
    ref,
  ) => (
    <label
      htmlFor={`${id}-toggle`}
      css={[
        tw`flex items-center cursor-pointer`,
        disabled && tw`cursor-not-allowed`,
        labelContainerStyle,
      ]}
    >
      <div tw="relative">
        <input
          css={[
            tw`sr-only checked:sibling:last:transform[translateX(100%)]`,
            disabled
              ? tw`sibling:not-last:bg-background-tertiary`
              : tw`checked:sibling:not-last:bg-background-brand`,
          ]}
          type="checkbox"
          id={`${id}-toggle`}
          data-testid={`${id}-toggle`}
          ref={ref}
          disabled={disabled}
          checked={checked}
          {...props}
        />
        <div
          css={[
            tw`block w-[52px] h-[28px] rounded-full`,
            !disabled
              ? tw`bg-background-secondary`
              : tw`bg-background-tertiary`,
            !checked && !disabled && tw`border border-border-primary`,
            blockStyle,
          ]}
        />
        <div
          css={[
            tw`absolute left-2 top-2 bg-background-white w-large h-large rounded-full transition`,
            disabled && tw`bg-background-secondary`,
            knobStyle,
          ]}
        />
      </div>
    </label>
  ),
);

export default Toggle;
