/** @jsxImportSource @emotion/react */
import React from 'react';
import 'twin.macro';

import { useReactiveVar } from '@apollo/client';
import { CardSeparator } from '@multiplier/common';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';

import { CompanyUser, Maybe } from '__generated__/graphql';

import SignatoryContent from '../signatory-content';

const AddSignatoryCallOut = React.lazy(
  () => import('../add-signatory-callout'),
);

interface ShowSignatoryContentProps {
  loadingSendForSignature: boolean;
  hasSignatory: boolean;
  signatory?: Maybe<CompanyUser>;
  openSignatoryModal: () => void;
}

const ShowSignatoryContent: React.FC<
  React.PropsWithChildren<ShowSignatoryContentProps>
> = ({
  signatory,
  loadingSendForSignature,
  hasSignatory,
  openSignatoryModal,
  children,
}) => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  if (current !== Experience.COMPANY) {
    return null;
  }

  if (!hasSignatory) {
    return (
      <>
        <AddSignatoryCallOut
          loadingSendForSignature={loadingSendForSignature}
          openSignatoryModal={openSignatoryModal}
        />
        <CardSeparator />
      </>
    );
  }
  return (
    <>
      <SignatoryContent signatory={signatory}>{children}</SignatoryContent>
      <CardSeparator />
    </>
  );
};

export default ShowSignatoryContent;
