/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ThemeContext } from '@multiplier/common';
import { companyHooks } from '@multiplier/contract-onboarding';
import { dateAsString, parseDate } from '@multiplier/format';
import { startOfDay } from 'date-fns';
import tw from 'twin.macro';

import {
  Contract,
  ContractOnboarding,
  CountryWorkStatus,
} from '__generated__/graphql';

const ViewStepsAction: React.FC<{
  contractId: Contract['id'];
  activationCutoff: ContractOnboarding['activationCutoff'];
  workflowUpdating: boolean;
  workStatus: Contract['workStatus'];
}> = ({ contractId, activationCutoff, workflowUpdating, workStatus }) => {
  const { t } = useTranslation('contract-onboarding');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const {
    handleExpandProgress,
    Dialog,
  } = companyHooks.useOnboardingChecklistSidebar();

  const activationCutoffDate = activationCutoff && parseDate(activationCutoff);

  const hasCutoffDatePassed =
    startOfDay(new Date()) > startOfDay(activationCutoffDate);

  return (
    <div>
      <div
        css={[
          tw`flex flex-row items-center flex-grow gap-x-large`,
          workStatus === CountryWorkStatus.REQUIRES_VISA && tw`float-right`,
        ]}
      >
        {workStatus !== CountryWorkStatus.REQUIRES_VISA && (
          <div tw="flex flex-col gap-y-extra-small">
            <p tw="font-semibold text-text-primary">
              {!activationCutoff || hasCutoffDatePassed
                ? t(
                    'onboarding-phase.progress-widget-content.view-steps-action.cutoff-date-passed.header',
                    'Complete all the steps to complete onboarding.',
                  )
                : t(
                    'onboarding-phase.progress-widget-content.view-steps-action.header',
                    {
                      defaultValue: 'Complete all steps before {{ cutoff }}',
                      replace: {
                        cutoff: dateAsString(activationCutoffDate, 'do MMMM'),
                      },
                    },
                  )}
            </p>
            <p
              css={[
                tw`text-ps font-medium text-text-secondary whitespace-pre-line`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {hasCutoffDatePassed
                ? t(
                    'onboarding-phase.progress-widget-content.view-steps-action.cutoff-date-passed.description',
                    "The member will receive their salary on the following cycle's payout date",
                  )
                : t(
                    'onboarding-phase.progress-widget-content.view-steps-action.description',
                    "Missing the cut-off means this member will receive their salary on the following cycle's payout date",
                  )}
            </p>
          </div>
        )}
        <Button
          tw="flex-shrink-0 w-[185px]"
          size="medium"
          variant="outline"
          onClick={() => handleExpandProgress(contractId)}
          disabled={workflowUpdating}
        >
          {t(
            'onboarding-phase.progress-widget-content.view-steps-action.button',
            'See all steps',
          )}
        </Button>
      </div>
      <Dialog />
    </div>
  );
};

export default ViewStepsAction;
