/** @jsxImportSource @emotion/react */
import React from 'react';

import ComparableDetailRow from './components/comparable-detail-row';
import EditableDetailRow from './components/editable-detail-row';
import { DetailRowProps, DetailRowType } from './types';

const DetailRow: React.FC<React.PropsWithChildren<DetailRowProps>> = ({
  type,
  ...otherProps
}): React.ReactElement | null => {
  if (type === DetailRowType.COMPARE_MODE)
    return (
      <ComparableDetailRow type={DetailRowType.COMPARE_MODE} {...otherProps} />
    );

  return <EditableDetailRow type={DetailRowType.BASIC_MODE} {...otherProps} />;
};

export default DetailRow;
