/* eslint-disable import/prefer-default-export */
import { useTranslation } from 'react-i18next';

import { useGetLatestValidBirthdate } from '@multiplier/contract-onboarding';
import * as yup from 'yup';

import { countryWithStateList } from 'contract-onboarding/company/services/eligibility-states';
import { getLegalFieldSchema } from 'contract-onboarding/services/legal-data';

import {
  Contract,
  ContractType,
  CountryCode,
  DataFieldDefinition,
} from '__generated__/graphql';

export const useGetMemberBasicDetailsSchema = (
  shouldValidateLegalData: boolean,
  requirementMap?: { [key: string]: DataFieldDefinition },
  memberLegalDataDefinitionKeysToShow?: string[],
  contract?: Contract,
): any => {
  const { t } = useTranslation('contract-onboarding.member');

  const latestValidBirthdate = useGetLatestValidBirthdate();

  const schema = yup.object().shape({
    legalName: yup
      .string()
      .required(
        t('member.basic-details.legalName.required', 'Legal Name is required'),
      ),
    nationality: yup
      .string()
      .required(
        t(
          'member.basic-details.nationality.required',
          'Nationality is required',
        ),
      ),
    addressLine2: yup.string().nullable(),
    currentAddress: yup
      .string()
      .required(
        t(
          'member.basic-details.currentAddress.required',
          'Address is required',
        ),
      ),
    postalCode: yup
      .string()
      .required(
        t(
          'member.basic-details.postal-code.required',
          'Postal Code is required',
        ),
      ),
    city: yup
      .string()
      .required(t('member.basic-details.city.required', 'City is required')),
    phone: yup
      .string()
      .required(
        t('member.basic-details.phone.required', 'Phone Number is required'),
      )
      .max(25, t('member.basic-details.phone.max', 'Phone Number is too long')),
    dateOfBirth: yup
      .date()
      .required(
        t(
          'member.basic-details.date-of-birth.required',
          'Date Of Birth is required',
        ),
      )
      .test(
        'is_less_than_max_date',
        t('basic-details.validation.date.max', 'Date is after allowed range'),
        (val) =>
          contract?.type === ContractType.FREELANCER ||
          (val ? val <= latestValidBirthdate : false),
      ),
    countryCode: yup
      .string()
      .required(
        t(
          'member.basic-details.country-code.required',
          'Country Code is required',
        ),
      ),
    legalData: !shouldValidateLegalData
      ? yup.array()
      : useGetLegalDataSchema(
          requirementMap,
          memberLegalDataDefinitionKeysToShow,
        ),
    country: yup.string().oneOf(Object.values(CountryCode)).required(),
    state: yup
      .string()
      .nullable()
      .when('country', {
        is: (nameOfCountry: CountryCode) =>
          countryWithStateList.includes(nameOfCountry),
        then: yup.string().required(),
      }),
  });
  return schema;
};

const useGetLegalDataSchema = (
  requirementMap?: { [key: string]: DataFieldDefinition },
  memberLegalDataDefinitionKeysToShow?: string[],
) => {
  const { t } = useTranslation('contract-onboarding.member');

  return yup.array().of(
    yup.object().shape({
      key: yup.string().required(),
      value: yup.lazy((_value, ctx) =>
        !memberLegalDataDefinitionKeysToShow?.includes(ctx?.parent?.key)
          ? yup.string().nullable()
          : getLegalFieldSchema(
              t,
              requirementMap?.[ctx?.parent?.key],
              ctx.context,
            ),
      ),
      identifier: yup.lazy((_value, ctx) =>
        !memberLegalDataDefinitionKeysToShow?.includes(ctx?.parent?.key) ||
        requirementMap?.[ctx?.parent?.key]?.dataType?.__typename !==
          'DropDownTextField'
          ? yup.string().nullable()
          : yup.string().nullable().required(),
      ),
    }),
  );
};
