import { useNavigate } from 'react-router-dom';

import { useSubmitBenefits } from 'contract-onboarding/hooks';

import { ContractOnboardingStep, Maybe } from '__generated__/graphql';

import { BenefitFormValues } from '../pages/definition-phase/pages/benefits/benefit-view-form';
import stepConfig from '../pages/definition-phase/step-config';

const useSubmitDefinitionBenefits = (
  contractIdFromPath?: Maybe<string>,
): {
  loading: boolean;
  onSubmit: (values: BenefitFormValues) => Promise<void>;
} => {
  const navigate = useNavigate();
  const { onSubmit: updateBenefits, loading } = useSubmitBenefits(
    contractIdFromPath,
  );

  const onSubmit = async (values: BenefitFormValues) => {
    if (!contractIdFromPath) return;

    const updatedBenefits = await updateBenefits(values);

    if (updatedBenefits?.onboarding?.current) {
      navigate(
        stepConfig[updatedBenefits.onboarding.current].directLink(
          updatedBenefits.id,
        ),
      );
    }

    if (updatedBenefits?.onboarding?.steps) {
      const current = updatedBenefits?.onboarding.steps.find(
        (x) => x === ContractOnboardingStep.DEFINITION_BENEFITS_DETAILS,
      );
      const currentIndex =
        current && updatedBenefits?.onboarding.steps?.indexOf(current);
      const next =
        currentIndex && updatedBenefits.onboarding.steps[currentIndex + 1];
      navigate(
        next
          ? stepConfig[next].directLink(contractIdFromPath)
          : stepConfig[
              updatedBenefits.onboarding.current as ContractOnboardingStep
            ].directLink(contractIdFromPath),
      );
    }
  };
  return {
    loading,
    onSubmit,
  };
};

export default useSubmitDefinitionBenefits;
