import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
} from 'app/models/module-config';
import {
  showModuleBasedOnContractStatusAndContractStarted,
  showModuleBasedOnContractType,
} from 'app/utils/module-checks';

import { ContractStatus, ContractType } from '__generated__/graphql';

import routes from '../routes';

const config: ModuleConfig = {
  id: 'onboarding-forms',
  experience: Experience.MEMBER,
  folder: 'document-management/member/onboarding-forms',
  nav: {
    defaultName: 'Onboarding Forms',
    group: MemberNavGroups.FORMS_SUBMISSION,
    home: false,
    route: `${routes.root}/${routes.onboardingForms}`,
    sort: 1,
    hide: async () =>
      !(await showModuleBasedOnContractType([
        ContractType.EMPLOYEE,
        ContractType.HR_MEMBER,
      ])),
  },
  permission: '',
  enabled: async () => {
    const shouldHide = await Promise.all([
      showModuleBasedOnContractStatusAndContractStarted([ContractStatus.ENDED]),
      showModuleBasedOnContractType([ContractType.FREELANCER]),
    ]);

    return !shouldHide.some((hide) => hide);
  },
};

export default config;
