/* eslint-disable import/prefer-default-export */
import {
  CompanyNavGroups,
  MemberNavGroups,
  NavGroups,
  NavIconType,
} from 'app/models/module-config';

export const sidebarSections: {
  [key: string]: { name?: string; icon?: NavIconType; sort: number };
} = {
  [NavGroups.MAIN]: { sort: 0 },
  [CompanyNavGroups.ADMINISTRATION]: {
    name: 'Administration',
    sort: 4,
    icon: { name: 'assignment' },
  },
  [CompanyNavGroups.COMPANY]: {
    name: 'Company',
    sort: 8,
    icon: { name: 'business-center' },
  },
  [CompanyNavGroups.SETTINGS]: {
    name: 'Settings',
    sort: 9,
    icon: { name: 'settings' },
  },
  [MemberNavGroups.COMPANY]: {
    name: 'Company',
    sort: 6,
    icon: { name: 'business-center' },
  },
  [MemberNavGroups.EMPLOYMENT]: {
    name: 'Employment details',
    sort: 7,
    icon: { name: 'badge' },
  },
  [MemberNavGroups.PAYMENTS]: {
    name: 'Payroll',
    sort: 8,
    icon: {
      name: 'calendar-today',
    },
  },
  [MemberNavGroups.FORMS_SUBMISSION]: {
    name: 'Form submission',
    sort: 9,
    icon: { name: 'calendar-today' },
  },
  [NavGroups.FOOTER]: { sort: 6 },
};
