/** @jsxImportSource @emotion/react */
import React from 'react';
import 'twin.macro';

const LoaderItem: React.FC = () => (
  <div tw="flex justify-between mt-36">
    <div tw="w-[60%] h-[8px] bg-background-secondary rounded-tiny" />
    <div tw="w-[10%] h-[8px] bg-background-secondary rounded-tiny" />
  </div>
);

const LoaderItemSet: React.FC<{ count: number }> = ({ count }) => (
  <>
    {Array.from(Array(count).keys()).map((value) => (
      <LoaderItem key={value} />
    ))}
  </>
);

const Loader: React.FC = () => (
  <div tw="tablet:rounded-base desktop:rounded-base bg-background-white py-large min-h-[290px]">
    <div tw="animate-pulse flex mb-large">
      <div tw="w-1/2 pr-large">
        <div tw="w-[60%] h-extra-small bg-background-tertiary rounded-tiny" />
        <LoaderItemSet count={3} />
      </div>
      <div tw="w-1/2 pl-large">
        <div tw="w-[60%] h-extra-small bg-background-tertiary rounded-tiny" />
        <LoaderItemSet count={3} />
      </div>
    </div>
  </div>
);

export default Loader;
