import { GetCountryAllowancesConfigQuery } from '__generated__/graphql';

const NEW_HR_ALLOWANCES_NAME = 'allowances';

const getMatchedHrAllowanceConfig = ({
  isNewAllowancesConfigEnabled,
  countryAllowancesConfig,
  payName,
  payLabel,
}: {
  isNewAllowancesConfigEnabled: boolean;
  countryAllowancesConfig:
    | GetCountryAllowancesConfigQuery['countryAllowancesConfig']
    | undefined;
  payName: string;
  payLabel: string | undefined;
}): { name: string; label: string } | undefined => {
  if (!isNewAllowancesConfigEnabled || !countryAllowancesConfig)
    return undefined;

  if (payName === NEW_HR_ALLOWANCES_NAME && !payLabel)
    // when for adding new hr allowance
    return {
      name: '',
      label: '',
    };

  const matchedConfig = countryAllowancesConfig.find(
    ({ name, label }) =>
      name === payName || name === payLabel || label === payLabel,
  );

  if (!matchedConfig) return undefined;

  return {
    name: matchedConfig.name,
    label: matchedConfig.label,
  };
};

export default getMatchedHrAllowanceConfig;
