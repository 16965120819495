import React, { useContext } from 'react';

import { theme } from 'twin.macro';

import Icon from '../icon';
import { ThemeContext } from '../theme-provider';

const SystemDowntimeWarning: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div tw="fixed top-0 w-screen flex gap-x-extra-small justify-center bg-background-white z-50 py-extra-small font-medium text-text-primary">
      <Icon
        name="alert-triangle-yellow"
        fill={!isNewThemeApplied ? 'transparent' : theme`colors.icon-warning`}
        tw="w-large h-large"
      />
      <span>{children}</span>
    </div>
  );
};
export default SystemDowntimeWarning;
