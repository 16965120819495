/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { IconButton, ThemeContext } from '@multiplier/common';
import tw, { TwStyle, theme } from 'twin.macro';

interface InsuranceTableScrollableButtonProps {
  isVisible: boolean;
  onClick: () => void;
  isReverse?: boolean;
  overrideCss?: Array<TwStyle>;
}
const InsuranceTableScrollableButton: React.FC<InsuranceTableScrollableButtonProps> = ({
  isVisible,
  onClick,
  isReverse,
  overrideCss,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  if (!isVisible) return null;
  return (
    <div
      style={{
        background: isReverse
          ? 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.79) 14%, white 60%) padding-box'
          : 'linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0.79) 60%, rgba(255, 255, 255, 0) 100%) padding-box',
      }}
      css={[
        tw`h-full absolute z-[2] px-tiny border-transparent border-[1px] justify-center items-center gap-extra-small inline-flex`,
        ...(overrideCss ?? []),
      ]}
    >
      <IconButton
        name={isReverse ? 'caret-right' : 'caret-left'}
        fill={theme`colors.icon-primary`}
        tw="rounded-full bg-background-white border-border-secondary border shadow-md hover:bg-white"
        size="medium"
        onClick={onClick}
        iconProps={
          isNewThemeApplied
            ? {
                width: 32,
                height: 32,
                css: [tw`h-[32px] w-[32px]`],
              }
            : {
                width: 8,
                height: 12,
              }
        }
      />
    </div>
  );
};

export default InsuranceTableScrollableButton;
