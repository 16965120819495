/** @jsxImportSource @emotion/react */
import React, { Suspense } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { Intercom, LiveChatLoaderProvider } from 'react-live-chat-loader';
import {
  unstable_HistoryRouter as HistoryRouter,
  MemoryRouter,
  MemoryRouterProps,
} from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { MockedProvider, MockedResponse } from '@apollo/client/testing';
import { Loader, SpinnerType, ThemeProvider } from '@multiplier/common';
import { History, Location } from 'history';

import 'twin.macro';
import ExperienceLoader from 'app/components/experience-loader';
import importMetaEnv from 'import-meta-env';
import { useGrowthBookLoader } from 'login/hooks/growth-book-loader';

import client from './client';

interface ProviderWrapperProps {
  history?: History;
  initialEntries?: Location[] | MemoryRouterProps['initialEntries'];
  mocks?: MockedResponse[];
}

export const Apollo: React.FC<
  React.PropsWithChildren<{ mocks?: MockedResponse[] }>
> = ({ mocks, children }) =>
  mocks && mocks.length > 0 ? (
    <MockedProvider
      mocks={mocks}
      addTypename={false}
      defaultOptions={{ watchQuery: { fetchPolicy: 'no-cache' } }}
    >
      {children}
    </MockedProvider>
  ) : (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  );

const IntercomProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const INTERCOM_WIDGET_CLASS = 'intercom-widget-placeholder';
  if (importMetaEnv.VITE_ENV !== 'prod') {
    return <>{children}</>;
  }

  return (
    <LiveChatLoaderProvider
      provider="intercom"
      providerKey={importMetaEnv.VITE_INTERCOM_APP_ID ?? ''}
      idlePeriod={500}
      /**
       * This is workaround for Safari issue as for some reason it was unable to remove the widget placeholder
       * So adding this callback to manually hide it once the Intercom widget ready
       * Note: Updated to hide it instead of removing from DOM due to infinite loop issue. Reference: https://app.clickup.com/t/865bqebt9
       */
      onReady={() =>
        document
          .querySelector(`.${INTERCOM_WIDGET_CLASS}`)
          ?.setAttribute('style', 'display: none')
      }
    >
      <>
        {children}
        <Intercom containerClass={INTERCOM_WIDGET_CLASS} color="#f76918" />
      </>
    </LiveChatLoaderProvider>
  );
};

const GrowthBookLoader: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { noGrowthBook, handleNoGrowthBook } = useGrowthBookLoader();

  if (noGrowthBook && importMetaEnv.PROD) {
    return <ExperienceLoader onProceed={handleNoGrowthBook} />;
  }

  return <>{children}</>;
};

const ProviderWrapper: React.FC<
  React.PropsWithChildren<ProviderWrapperProps>
> = ({ children, history, initialEntries, mocks }) => (
  <Apollo mocks={mocks}>
    <Suspense fallback={<Loader.Spinner variant={SpinnerType.EXPERIENCE} />}>
      <GrowthBookLoader>
        <IntercomProvider>
          <HelmetProvider>
            <ThemeProvider>
              {history ? (
                <HistoryRouter history={history}>{children}</HistoryRouter>
              ) : (
                <MemoryRouter initialEntries={initialEntries}>
                  {children}
                </MemoryRouter>
              )}
            </ThemeProvider>
          </HelmetProvider>
        </IntercomProvider>
      </GrowthBookLoader>
    </Suspense>
  </Apollo>
);

export default ProviderWrapper;
