/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CardSeparator,
  ComboBox,
  ConfirmationDialog,
  Icon,
  TextInput,
  ThemeContext,
} from '@multiplier/common';
import { SorOnboardingContext } from '@multiplier/hris-member-management';
import tw, { theme } from 'twin.macro';

import { DropdownValue } from 'common/components/combo-box';
import Textarea from 'common/components/textarea';
import { getContractDocumentLabel } from 'common/constants/contract-labels';
import useGetCountryAllowancesConfig from 'contract-onboarding/company/hooks/get-country-allowances-config';
import {
  getAdditionalPaySchema,
  getAdditionalPaySchemaForFreelancer,
  mapAdditionalPayTypeToName,
} from 'contract-onboarding/company/services/compensation';
import getMatchedHrAllowanceConfig from 'contract-onboarding/company/services/get-matched-hr-allowance-config';
import i18n from 'i18n';
import { useIsNewAllowancesConfigEnabled } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Contract,
  ContractType,
  CurrencyCode,
  FixedPayComponent,
  FixedPayComponentInput,
  Maybe,
  PayAmountType,
  RateFrequency,
} from '__generated__/graphql';

import { AdditionalPayDisclaimerSingle } from '../additional-pay-disclaimer';
import AllowancesFields from './components/allowance-form-fields';
import JoiningBonusFields from './components/joining-bonus-form-fields';
import OtherPayFields from './components/other-pay-form-fields';
import PayoutContractStatement from './components/payout-contract-statement';
import ThrBonusFields from './components/thr-bonus-form-fields';
import VariablePerformanceBonusFields from './components/variable-performance-bonus-form-fields';

export enum PaymentType {
  JOINING_BONUS = 'JOINING_BONUS',
  VARIABLE_PERFORMANCE_BONUS = 'VARIABLE_PERFORMANCE_BONUS',
  ALLOWANCES = 'ALLOWANCES',
  OTHER = 'OTHER',
  THR_BONUS = 'THR_BONUS',
  THIRTEENTH_MONTH_BONUS = 'THIRTEENTH_MONTH_BONUS',
  MOBILE_AND_PHONE_ALLOWANCE = 'MOBILE_AND_PHONE_ALLOWANCE',
  INTERNET_ALLOWANCE = 'INTERNET_ALLOWANCE',
  DE_MINIMIS_ALLOWANCE = 'DE_MINIMIS_ALLOWANCE',
  FOURTEENTH_MONTH_BONUS = 'FOURTEENTH_MONTH_BONUS',
}

const preferredFrequencyDropDown = [
  RateFrequency.ONCE,
  RateFrequency.BI_WEEKLY,
  RateFrequency.SEMIMONTHLY,
  RateFrequency.MONTHLY,
  RateFrequency.BI_MONTHLY,
  RateFrequency.QUATERLY,
  RateFrequency.TRI_ANNUALLY,
  RateFrequency.HALFYEARLY,
  RateFrequency.ANNUALLY,
];

export const FrequencyTypeLabels = {
  [RateFrequency.ANNUALLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.annually',
    'Annually',
  ),
  [RateFrequency.CUSTOM]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.custom',
    'Custom',
  ),
  [RateFrequency.DAILY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.daily',
    'Daily',
  ),
  [RateFrequency.HALFYEARLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.half-yearly',
    'Semi Annually',
  ),
  [RateFrequency.HOURLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.hourly',
    'Hourly',
  ),
  [RateFrequency.MONTHLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.monthly',
    'Monthly',
  ),
  [RateFrequency.ONCE]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.once',
    'Once',
  ),
  [RateFrequency.QUATERLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.quarterly',
    'Quarterly',
  ),
  [RateFrequency.SEMIMONTHLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.semi-monthly',
    'Semi Monthly',
  ),
  [RateFrequency.WEEKLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.weekly',
    'Weekly',
  ),
  [RateFrequency.BI_WEEKLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.bi-weekly',
    'Bi Weekly',
  ),
  [RateFrequency.BI_MONTHLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.bi-monthly',
    'Bi Monthly',
  ),
  [RateFrequency.TRI_ANNUALLY]: i18n.t(
    'contract-onboarding.common:additional-pay.rate-frequency.labels.tri-annually',
    'Tri Annually',
  ),
};

export const frequencyOptions = Object.values(RateFrequency)
  .filter((freq) => preferredFrequencyDropDown.includes(freq))
  .sort(
    (a, b) =>
      preferredFrequencyDropDown.indexOf(a) -
      preferredFrequencyDropDown.indexOf(b),
  )
  .map((freq) => ({
    title: FrequencyTypeLabels[freq],
    value: freq,
  }));

export const PaymentTypeLabels = {
  [PaymentType.JOINING_BONUS]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.joining-bonus',
    'Joining Bonus',
  ),
  [PaymentType.VARIABLE_PERFORMANCE_BONUS]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.variable-performance-bonus',
    'Variable Performance Bonus',
  ),
  [PaymentType.ALLOWANCES]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.Allowances',
    'Allowances',
  ),
  [PaymentType.OTHER]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.other',
    'Other',
  ),
  [PaymentType.THR_BONUS]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.thr-bonus',
    'THR Bonus',
  ),
  [PaymentType.THIRTEENTH_MONTH_BONUS]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.thirteenth-month-bonus',
    '13th Month Bonus',
  ),
  [PaymentType.FOURTEENTH_MONTH_BONUS]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.fourteenth-month-bonus',
    '14th Month Bonus',
  ),
};

export const PaymentTypeLabelsFeatureOn = {
  ...PaymentTypeLabels,
  [PaymentType.ALLOWANCES]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.other-allowances',
    'Other Allowances',
  ),
  [PaymentType.OTHER]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.others',
    'Others',
  ),
  [PaymentType.MOBILE_AND_PHONE_ALLOWANCE]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.mobile-and-phone-allowance',
    'Mobile and Phone Allowance',
  ),
  [PaymentType.INTERNET_ALLOWANCE]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.internet-allowance',
    'Internet Allowance',
  ),
  [PaymentType.DE_MINIMIS_ALLOWANCE]: i18n.t(
    'contract-onboarding.common:additional-pay.labels.de-minimis-allowance',
    'De Minimis Allowance',
  ),
};

export enum Occurrence {
  ONCE = 'ONCE',
  RECURRING = 'RECURRING',
}

export type AdditionalPayFormValues = {
  payType: PaymentType;
  name: string;
  label?: string;
  defaultDisplayLabel?: string;
  currency?: Maybe<CurrencyCode>;
  occurrence: Occurrence;
  frequency?: Maybe<RateFrequency> | number;
  amountType?: Maybe<PayAmountType>;
  payoutMonth: {
    month: number;
    year: number;
  };
  amount?: Maybe<number>;
  conditions: string;
  isDeletable?: Maybe<boolean>;
  payInInstallments?: Maybe<boolean>;
  instalments?: FixedPayComponent['instalments'];
  title?: string;
};

export const excludedPayItemsFromDisclaimer = [
  PaymentType.ALLOWANCES,
  PaymentType.THR_BONUS,
  PaymentType.THIRTEENTH_MONTH_BONUS,
];

export const excludedPayItemsForEmployee = [
  PaymentType.THR_BONUS,
  PaymentType.THIRTEENTH_MONTH_BONUS,
];

export const mapFrequency = ({
  frequency,
}: {
  frequency: number | Maybe<RateFrequency>;
}): string => {
  switch (frequency) {
    case 0.25:
      return 'once every two weeks';
    case 0.5:
      return 'twice every month';
    case 1:
      return 'once every month';
    case 2:
      return 'once every two months';
    case 3:
      return 'once every quarter';
    case 4:
      return 'thrice every year';
    case 6:
      return 'twice every year';
    case 12:
      return 'once every year';
    default:
      return '';
  }
};

export type AdditionalPayDropdownType = { title: string; value: PaymentType };

const defaultDropdown = [
  {
    title: PaymentTypeLabels[PaymentType.JOINING_BONUS],
    value: PaymentType.JOINING_BONUS,
  },
  {
    title: PaymentTypeLabels[PaymentType.VARIABLE_PERFORMANCE_BONUS],
    value: PaymentType.VARIABLE_PERFORMANCE_BONUS,
  },
  {
    title: PaymentTypeLabels[PaymentType.ALLOWANCES],
    value: PaymentType.ALLOWANCES,
  },
  {
    title: PaymentTypeLabels[PaymentType.OTHER],
    value: PaymentType.OTHER,
  },
];

const defaultDropdownWithFeature = [
  {
    title: PaymentTypeLabels[PaymentType.JOINING_BONUS],
    value: PaymentType.JOINING_BONUS,
  },
  {
    title: PaymentTypeLabels[PaymentType.VARIABLE_PERFORMANCE_BONUS],
    value: PaymentType.VARIABLE_PERFORMANCE_BONUS,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.MOBILE_AND_PHONE_ALLOWANCE],
    value: PaymentType.MOBILE_AND_PHONE_ALLOWANCE,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.INTERNET_ALLOWANCE],
    value: PaymentType.INTERNET_ALLOWANCE,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.DE_MINIMIS_ALLOWANCE],
    value: PaymentType.DE_MINIMIS_ALLOWANCE,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.ALLOWANCES],
    value: PaymentType.ALLOWANCES,
  },
  {
    title: PaymentTypeLabelsFeatureOn[PaymentType.OTHER],
    value: PaymentType.OTHER,
  },
];

const paymentTypesToRenderConditions = [
  PaymentType.ALLOWANCES,
  PaymentType.JOINING_BONUS,
  PaymentType.OTHER,
  PaymentType.VARIABLE_PERFORMANCE_BONUS,
  PaymentType.DE_MINIMIS_ALLOWANCE,
  PaymentType.INTERNET_ALLOWANCE,
  PaymentType.MOBILE_AND_PHONE_ALLOWANCE,
];

const paymentTypesToRenderPlaceholder = [
  PaymentType.ALLOWANCES,
  PaymentType.JOINING_BONUS,
  PaymentType.DE_MINIMIS_ALLOWANCE,
  PaymentType.INTERNET_ALLOWANCE,
  PaymentType.MOBILE_AND_PHONE_ALLOWANCE,
];

const AdditionalPayForm: React.FC<{
  contractType?: Contract['type'];
  countryCode?: Contract['country'];
  basePay?: number;
  payItem?: AdditionalPayFormValues;
  currency?: Maybe<CurrencyCode>;
  currencyOptions: Maybe<DropdownValue[]>;
  onCancel: () => void;
  onSubmit: (v: AdditionalPayFormValues) => void;
  existingPays?: { payType: PaymentType; name: string; label?: string }[];
  uncommittedSubFormChangesCallback?: (values: boolean) => void;
  additionalPaysSupported?: AdditionalPayDropdownType[];
  maxDate?: Date | string;
  minDate?: Date | string;
  defaultEffectiveDate?: Date;
  basePayPaymentFrequency?: FixedPayComponentInput['paymentFrequency'];
  sorOnboardingContext?: SorOnboardingContext;
}> = ({
  contractType,
  countryCode,
  basePay,
  payItem,
  onSubmit,
  currency,
  currencyOptions,
  onCancel,
  existingPays,
  uncommittedSubFormChangesCallback,
  additionalPaysSupported,
  minDate,
  maxDate,
  defaultEffectiveDate,
  basePayPaymentFrequency,
  sorOnboardingContext,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);
  const shouldApplyMandatoryAdditionalPays =
    contractType &&
    [
      ContractType.EMPLOYEE,
      ContractType.HR_MEMBER,
      ContractType.CONTRACTOR,
    ].includes(contractType);

  const isNewAllowancesConfigEnabled = useIsNewAllowancesConfigEnabled({
    type: contractType,
  });

  const { countryAllowancesConfig } = useGetCountryAllowancesConfig({
    country: countryCode,
    isNewAllowancesConfigEnabled,
  });

  const methods = useForm<AdditionalPayFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      shouldApplyMandatoryAdditionalPays
        ? getAdditionalPaySchema(
            t,
            currencyOptions,
            basePay,
            isNewAllowancesConfigEnabled,
          )
        : getAdditionalPaySchemaForFreelancer(t, currencyOptions, basePay),
    ),
  });

  const {
    control,
    setValue,
    watch,
    register,
    formState: { errors, isValid, isDirty },
    reset,
    getValues,
    trigger,
    clearErrors,
  } = methods;

  const [
    payTypeValue,
    frequency,
    occurrence,
    amount,
    nameValue,
    payoutMonth,
    payAmountType,
    payInInstallments,
    instalments,
    labelValue,
  ] = watch([
    'payType',
    'frequency',
    'occurrence',
    'amount',
    'name',
    'payoutMonth',
    'amountType',
    'payInInstallments',
    'instalments',
    'label',
  ]);

  const matchedHrAllowanceConfig = getMatchedHrAllowanceConfig({
    isNewAllowancesConfigEnabled,
    countryAllowancesConfig,
    payName: nameValue,
    payLabel: labelValue,
  });

  useEffect(() => {
    if (defaultEffectiveDate && !payoutMonth) {
      reset({
        payoutMonth: {
          month: defaultEffectiveDate.getMonth(),
          year: defaultEffectiveDate.getFullYear(),
        },
      });
    }
  }, [payoutMonth, defaultEffectiveDate]);

  const setJoiningBonusValues = () => {
    reset({
      ...getValues(),
      occurrence: Occurrence.ONCE,
      frequency: shouldApplyMandatoryAdditionalPays
        ? RateFrequency.ONCE
        : undefined,
      amountType: PayAmountType.FIXED_AMOUNT,
      name: mapAdditionalPayTypeToName(PaymentType.JOINING_BONUS),
      currency: currency ?? (currencyOptions?.[0]?.value as CurrencyCode),
    });
  };
  const setAllowanceValues = (name: PaymentType, isAllowance: boolean) => {
    reset({
      ...getValues(),
      occurrence: Occurrence.RECURRING,
      name:
        (shouldApplyMandatoryAdditionalPays && !isAllowance) ||
        isNewAllowancesConfigEnabled
          ? mapAdditionalPayTypeToName(name)
          : '',
      amountType: PayAmountType.FIXED_AMOUNT,
      currency: currency ?? (currencyOptions?.[0]?.value as CurrencyCode),
    });
  };
  const setVariablePerformanceBonusValues = () => {
    reset({
      ...getValues(),
      occurrence: Occurrence.RECURRING,
      name: mapAdditionalPayTypeToName(PaymentType.VARIABLE_PERFORMANCE_BONUS),
      amount: null,
      amountType: PayAmountType.VARIABLE_AMOUNT,
      currency: currency ?? (currencyOptions?.[0]?.value as CurrencyCode),
    });
  };
  const setOtherBonusValues = () => {
    reset({
      ...getValues(),
      name: '',
      amountType: PayAmountType.VARIABLE_AMOUNT,
      amount: null,
      currency: currency ?? (currencyOptions?.[0]?.value as CurrencyCode),
    });
  };

  useEffect(() => {
    if (payItem) {
      reset(payItem);
    } else if (isNewAllowancesConfigEnabled) {
      reset({
        payType: PaymentType.ALLOWANCES,
        currency: currency ?? undefined,
        isDeletable: true,
      });
      setAllowanceValues(PaymentType.ALLOWANCES, true);
    }
    if (payItem && contractType === ContractType.FREELANCER)
      setValue('currency', currency);
  }, [payItem, currency, isNewAllowancesConfigEnabled]);

  useEffect(() => {
    if (uncommittedSubFormChangesCallback && isDirty)
      uncommittedSubFormChangesCallback(isDirty);
  }, [isDirty]);

  const typeDropdown = useMemo(() => {
    if (shouldApplyMandatoryAdditionalPays) return defaultDropdownWithFeature;

    return defaultDropdown;
  }, [additionalPaysSupported]);

  const isExistingPay = useMemo(
    () =>
      existingPays
        ?.map((p) => `${p.payType}.${p.name}.${p.label}`)
        ?.includes(`${payTypeValue}.${nameValue}.${labelValue}`) ?? false,
    [existingPays, nameValue, payTypeValue, labelValue],
  );

  const showContractPreviewStatement = useMemo(() => {
    if (payTypeValue === PaymentType.JOINING_BONUS) {
      if (!payInInstallments) return !!payoutMonth?.year && amount;
      if (payInInstallments) {
        return instalments?.some(
          (instalment) =>
            instalment?.amount &&
            instalment?.payOn?.month != null &&
            instalment?.payOn?.year != null,
        );
      }
    }

    if (payTypeValue === PaymentType.VARIABLE_PERFORMANCE_BONUS)
      return !!payoutMonth?.year && frequency;

    if (payTypeValue === PaymentType.OTHER) {
      if (occurrence === Occurrence.ONCE)
        return !!payoutMonth?.year && nameValue;
      if (occurrence === Occurrence.RECURRING)
        return !!payoutMonth?.year && frequency && nameValue;
    }

    if (payTypeValue === PaymentType.ALLOWANCES)
      return !!payoutMonth?.year && frequency && amount && nameValue;

    if (
      payTypeValue === PaymentType.DE_MINIMIS_ALLOWANCE ||
      payTypeValue === PaymentType.INTERNET_ALLOWANCE ||
      payTypeValue === PaymentType.MOBILE_AND_PHONE_ALLOWANCE
    )
      return !!payoutMonth?.year && frequency && amount && payAmountType;
    return false;
  }, [
    payTypeValue,
    payoutMonth?.year,
    frequency,
    occurrence,
    amount,
    nameValue,
    payInInstallments,
    instalments,
  ]);

  const showNote = useMemo(() => {
    if (!shouldApplyMandatoryAdditionalPays) {
      return true;
    }
    return !(
      (payTypeValue === PaymentType.DE_MINIMIS_ALLOWANCE ||
        payTypeValue === PaymentType.ALLOWANCES ||
        payTypeValue === PaymentType.INTERNET_ALLOWANCE ||
        payTypeValue === PaymentType.MOBILE_AND_PHONE_ALLOWANCE) &&
      payAmountType === PayAmountType.FIXED_AMOUNT
    );
  }, [payAmountType, payTypeValue]);

  const inputStyle = payTypeValue ? tw`overflow-y-auto` : tw`overflow-visible`;

  return (
    <ConfirmationDialog.Lightbox>
      <ConfirmationDialog.Container
        css={[tw`w-[620px] max-h-[75%]`, inputStyle]}
      >
        <FormProvider {...methods}>
          <div
            css={[
              tw`bg-background-brand-faded rounded-t-base flex flex-col sticky top-none z-10`,
              !isNewThemeApplied && tw`bg-grey05`,
            ]}
            data-testid="additional-pay-item-form"
          >
            <div tw="flex flex-row justify-between m-base">
              <p tw="text-p font-semibold">
                {matchedHrAllowanceConfig
                  ? t('allowance.form.header', 'Allowance')
                  : t('additional-pay.form.header', 'Additional Pay Item')}
              </p>
              <button tw="right-base" type="button" onClick={onCancel}>
                <Icon name="cross" fill={theme`colors.icon-secondary`} />
              </button>
            </div>
          </div>

          <CardSeparator />
          <div tw="bg-background-white p-base gap-base flex flex-col rounded-b-base">
            {!matchedHrAllowanceConfig && (
              <TextInput.Container>
                <TextInput.Label>
                  {t('additional-pay.type.label', 'Pay type')}
                </TextInput.Label>
                <Controller
                  name="payType"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ComboBox
                      data-testid="select-payType"
                      variant="default"
                      dropdownValues={typeDropdown}
                      showArrow
                      value={value || ''}
                      placeholder={t(
                        'additional-pay.type.placeholder',
                        'Select additional pay type',
                      )}
                      onChange={(v) => {
                        onChange(v);
                        if (v === PaymentType.JOINING_BONUS) {
                          setJoiningBonusValues();
                        }
                        if (
                          v === PaymentType.ALLOWANCES ||
                          (shouldApplyMandatoryAdditionalPays &&
                            (v === PaymentType.DE_MINIMIS_ALLOWANCE ||
                              v === PaymentType.INTERNET_ALLOWANCE ||
                              v === PaymentType.MOBILE_AND_PHONE_ALLOWANCE))
                        ) {
                          setAllowanceValues(v, v === PaymentType.ALLOWANCES);
                        }
                        if (v === PaymentType.VARIABLE_PERFORMANCE_BONUS) {
                          setVariablePerformanceBonusValues();
                        }
                        if (v === PaymentType.OTHER) {
                          setOtherBonusValues();
                        }
                        clearErrors();
                        trigger();
                      }}
                    />
                  )}
                />
                {isExistingPay && (
                  <TextInput.Error>
                    {t(
                      'additional-pay.errors.payType.repeating',
                      'Already Existing',
                    )}
                  </TextInput.Error>
                )}
              </TextInput.Container>
            )}
            {(payTypeValue === PaymentType.THR_BONUS ||
              payTypeValue === PaymentType.THIRTEENTH_MONTH_BONUS) && (
              <ThrBonusFields currencyOptions={currencyOptions} />
            )}
            {payTypeValue === PaymentType.JOINING_BONUS && (
              <JoiningBonusFields
                currency={currency}
                currencyOptions={currencyOptions}
                contractType={contractType}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
            {payTypeValue === PaymentType.VARIABLE_PERFORMANCE_BONUS && (
              <VariablePerformanceBonusFields
                maxDate={maxDate}
                minDate={minDate}
                contractType={contractType}
              />
            )}
            {(payTypeValue === PaymentType.ALLOWANCES ||
              (shouldApplyMandatoryAdditionalPays &&
                (payTypeValue === PaymentType.DE_MINIMIS_ALLOWANCE ||
                  payTypeValue === PaymentType.INTERNET_ALLOWANCE ||
                  payTypeValue ===
                    PaymentType.MOBILE_AND_PHONE_ALLOWANCE))) && (
              <AllowancesFields
                currencyOptions={currencyOptions}
                contractType={contractType}
                countryCode={countryCode}
                maxDate={maxDate}
                minDate={minDate}
                basePayPaymentFrequency={basePayPaymentFrequency}
              />
            )}
            {payTypeValue === PaymentType.OTHER && (
              <OtherPayFields
                contractType={contractType}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
            {payTypeValue &&
              paymentTypesToRenderConditions.includes(payTypeValue) &&
              showNote && (
                <TextInput.Container>
                  <TextInput.Label>
                    {payTypeValue === PaymentType.OTHER
                      ? t(
                          'additional-pay.conditions.label.default',
                          'Condition (Optional)',
                        )
                      : t(
                          'additional-pay.conditions.label.optional',
                          'Condition',
                        )}
                  </TextInput.Label>
                  <Textarea
                    data-testid="pay-conditions"
                    placeholder={
                      shouldApplyMandatoryAdditionalPays
                        ? paymentTypesToRenderPlaceholder.includes(
                            payTypeValue,
                          ) && showNote
                          ? t(
                              'additional-pay.conditions.placeholder.variable-note',
                              ' Add disclaimers (optional)',
                            )
                          : t(
                              'additional-pay.conditions.placeholder.common-note',
                              'Add details here (optional)',
                            )
                        : [
                            PaymentType.OTHER,
                            PaymentType.VARIABLE_PERFORMANCE_BONUS,
                          ].includes(payTypeValue)
                        ? t(
                            'additional-pay.conditions.placeholder.other',
                            'Add amount/percentage and any other condition here',
                          )
                        : t(
                            'additional-pay.conditions.placeholder.common',
                            'Add a condition.',
                          )
                    }
                    {...register('conditions')}
                  />
                  {errors?.conditions && (
                    <TextInput.Error>
                      {errors?.conditions?.message}
                    </TextInput.Error>
                  )}
                </TextInput.Container>
              )}
            {payTypeValue &&
              showContractPreviewStatement &&
              paymentTypesToRenderConditions.includes(payTypeValue) && (
                <TextInput.Container>
                  <TextInput.Label>
                    {t('additional-pay.preview.label-v2', {
                      defaultValue:
                        'Preview of {{contractDocumentLabel}} Statement',
                      replace: {
                        contractDocumentLabel: getContractDocumentLabel(
                          contractType,
                        ),
                      },
                    })}
                  </TextInput.Label>
                  <PayoutContractStatement
                    contractType={contractType}
                    payment={watch()}
                  />
                </TextInput.Container>
              )}

            {payTypeValue && !sorOnboardingContext?.isHrisOnlyFlow && (
              <AdditionalPayDisclaimerSingle
                payType={payTypeValue}
                payAmountType={payAmountType}
                name={watch('name')}
                contractType={contractType}
              />
            )}

            <div tw="flex items-center justify-center">
              <Button
                variant="outline"
                size="small"
                tw="mr-base"
                onClick={onCancel}
              >
                {t('additional-pay.form.button.cancel', 'Cancel')}
              </Button>
              <Button
                variant="default"
                disabled={!isValid || isExistingPay || !payTypeValue}
                size="small"
                onClick={() => onSubmit({ ...getValues(), isDeletable: true })}
              >
                {t('additional-pay.form.button.save', 'Save')}
              </Button>
            </div>
          </div>
        </FormProvider>
      </ConfirmationDialog.Container>
    </ConfirmationDialog.Lightbox>
  );
};

export default AdditionalPayForm;
