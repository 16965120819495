import { gql } from '@apollo/client';

export default gql`
  query GetCompanyOfferings {
    company {
      id
      offerings {
        offering
        status
      }
    }
  }
`;
