/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, ThemeContext, useUpdateSearchParams } from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { SUB_TAB, TeamViewSubTab } from 'team/company/pages/team-view';

interface TeamViewSubFiltersProps {
  setSelectedSubTab: Dispatch<SetStateAction<TeamViewSubTab | undefined>>;
  selectedSubTab: TeamViewSubTab | undefined;
  needsAttentionCount: number;
}

const MainContainer = tw.div`
    flex flex-row mb-base
`;

const PillButton = tw.button`
    flex justify-center items-center py-extra-small px-base text-ps font-medium text-text-action bg-transparent border border-border-primary rounded-full
`;

const TeamViewSubFilters: React.FC<TeamViewSubFiltersProps> = ({
  selectedSubTab,
  needsAttentionCount,
  setSelectedSubTab,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('team-view');
  const updateSearchParams = useUpdateSearchParams();

  const handleButtonClick = () => {
    if (selectedSubTab) {
      setSelectedSubTab(undefined);
      updateSearchParams([], [SUB_TAB]);
    } else {
      setSelectedSubTab(TeamViewSubTab.NEEDS_ATTENTION);
      updateSearchParams([
        {
          key: SUB_TAB,
          value: TeamViewSubTab.NEEDS_ATTENTION,
        },
        {
          key: 'page',
          value: '1',
        },
      ]);
    }
  };

  const infoIconFill = useMemo(() => {
    if (needsAttentionCount === 0) {
      return theme`colors.icon-secondary`;
    }

    if (selectedSubTab) {
      return theme`colors.white`;
    }
    return !isNewThemeApplied
      ? theme`colors.primaryBlue600`
      : theme`colors.icon-secondary`;
  }, [needsAttentionCount, selectedSubTab, isNewThemeApplied]);

  return (
    <MainContainer>
      <PillButton
        onClick={handleButtonClick}
        css={[
          selectedSubTab &&
            tw`bg-background-action text-white border-border-action`,
          selectedSubTab &&
            isNewThemeApplied &&
            tw`bg-background-brand border-none`,
          needsAttentionCount === 0 &&
            tw`text-text-tertiary border-border-secondary bg-transparent`,
        ]}
        disabled={needsAttentionCount === 0}
      >
        <Icon
          name="exclamation-circle"
          fill={infoIconFill}
          tw="mr-[10px] w-base h-base"
        />
        {`${t(
          'sub-tab.needs-attention',
          'Needs Attention',
        )} (${needsAttentionCount})`}
        {selectedSubTab && (
          <Icon
            name="cross"
            fill={theme`colors.white`}
            tw="ml-small w-small h-small"
          />
        )}
      </PillButton>
    </MainContainer>
  );
};

export default TeamViewSubFilters;
