/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import tw, { styled } from 'twin.macro';

import { numberAsCurrency } from 'app/utils/format';
import TextInput from 'common/components/text-input';
import { vestingFrequency as vestingFrequencyLabel } from 'common/constants/default-labels';
import {
  extendGrantValueType,
  getRatioString,
} from 'contract-onboarding/company/components/esop-input';

import { Company, CurrencyCode, VestingFrequency } from '__generated__/graphql';

const BoldText = styled.span`
  ${tw`text-ps font-semibold`}
`;

const EsopPreviewStatement: React.FC<{
  companyName?: Company['displayName'];
  currency: CurrencyCode;
  amount?: number;
  vestedOver: number;
  vestingSchedule: { percentage: number; value: number }[];
  cliffPeriod: number;
  vestingFrequency: VestingFrequency;
  condition: string;
  esopAgreementOption: string;
  noOfEsops?: number;
}> = ({
  companyName,
  currency,
  amount,
  vestedOver,
  vestingSchedule,
  cliffPeriod,
  vestingFrequency,
  condition,
  noOfEsops,
  esopAgreementOption,
}) => {
  const { t, i18n } = useTranslation('contract-onboarding.common');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const amountString = useMemo(
    () => `${currency} ${numberAsCurrency(i18n.language, amount)}`,
    [amount, currency],
  );

  const ratioString = getRatioString(vestingSchedule);

  const getBothOptionsText = (
    <>
      In addition to the CTC offer enclosed, &apos;
      <BoldText data-testid="company-name">{{ companyName }}</BoldText>
      &apos; will also award you option{' '}
      <BoldText data-testid="esops-string">{{ noOfEsops }}</BoldText> shares
      equivalent of{' '}
      <BoldText data-testid="amount-string">{{ amountString }}</BoldText>,
      vested over{' '}
      <BoldText data-testid="vested-over-string">
        {{ vestedOver }} Years
      </BoldText>{' '}
      in the ratio of{' '}
      <BoldText data-testid="ratio-string">{{ ratioString }}</BoldText> from the
      date of grant, under the Employee Stock Option Plan (ESOP).
    </>
  );

  const getEsopsOptionsText = (
    <>
      In addition to the CTC offer enclosed, &apos;
      <BoldText data-testid="company-name">{{ companyName }}</BoldText>
      &apos; will also award you{' '}
      <BoldText data-testid="esops-string">{{ noOfEsops }}</BoldText> option
      shares, vested over{' '}
      <BoldText data-testid="vested-over-string">
        {{ vestedOver }} Years
      </BoldText>{' '}
      in the ratio of{' '}
      <BoldText data-testid="ratio-string">{{ ratioString }}</BoldText> from the
      date of grant, under the Employee Stock Option Plan (ESOP).
    </>
  );

  const getAmountOptionsText = (
    <>
      In addition to the CTC offer enclosed, &apos;
      <BoldText data-testid="company-name">{{ companyName }}</BoldText>
      &apos; will also award you option shares equivalent of{' '}
      <BoldText data-testid="amount-string">{{ amountString }}</BoldText>,
      vested over{' '}
      <BoldText data-testid="vested-over-string">
        {{ vestedOver }} Years
      </BoldText>{' '}
      in the ratio of{' '}
      <BoldText data-testid="ratio-string">{{ ratioString }}</BoldText> from the
      date of grant, under the Employee Stock Option Plan (ESOP).
    </>
  );

  return (
    <div tw="col-span-2 min-h-[190px]">
      <TextInput.Label
        htmlFor="condition"
        css={[
          tw`text-ps text-text-secondary font-normal leading-normal`,
          !isNewThemeApplied && tw`text-grey01`,
        ]}
      >
        {t(
          'definition-phase.compensation.esop-input.preview-statement.header',
          'Preview of Contract Statement',
        )}
      </TextInput.Label>

      <p tw="mt-tiny text-ps text-text-primary font-normal">
        {esopAgreementOption === extendGrantValueType.UNIT && !!noOfEsops && (
          <Trans
            i18nKey="definition-phase.compensation.esop-input.preview-statement.no-of-esops"
            t={t}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{
              companyName,
              noOfEsops,
              vestedOver,
              ratioString,
            }}
          >
            {getEsopsOptionsText}
          </Trans>
        )}
        {esopAgreementOption === extendGrantValueType.CASH && !!amount && (
          <Trans
            i18nKey="definition-phase.compensation.esop-input.preview-statement.amount"
            t={t}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{
              companyName,
              amountString,
              vestedOver,
              ratioString,
            }}
          >
            {getAmountOptionsText}
          </Trans>
        )}
        {esopAgreementOption === extendGrantValueType.BOTH &&
          !!noOfEsops &&
          !!amount && (
            <Trans
              i18nKey="definition-phase.compensation.esop-input.preview-statement.both"
              t={t}
              tOptions={{ interpolation: { escapeValue: false } }}
              values={{
                companyName,
                amountString,
                vestedOver,
                ratioString,
                noOfEsops,
              }}
            >
              {getBothOptionsText}
            </Trans>
          )}
      </p>
      <p tw="text-ps text-text-primary mt-small">
        <Trans
          i18nKey="definition-phase.compensation.esop-input.preview-statement.note"
          t={t}
        >
          Note: Minimum time period to be eligible for ESOPs is{' '}
          <BoldText data-testid="cliff-period-string">
            {{ cliffPeriod: cliffPeriod || '0' }} Months
          </BoldText>
          , post which your portion of the granted shares can be vested{' '}
          <BoldText data-testid="vesting-frequency-string">
            {{ vestingFrequency: vestingFrequencyLabel[vestingFrequency] }}
          </BoldText>
          . You shall receive a grant letter post joining as per the forthcoming
          cycle. The terms and conditions of the grant shall be governed by ESOP
          scheme document.
        </Trans>
      </p>
      <p tw="text-p text-text-primary mt-small">{condition}</p>
    </div>
  );
};

export default EsopPreviewStatement;
