import { useEffect, useState } from 'react';

import { useDeepCompareEffect } from 'react-use';

import { notEmpty } from 'app/utils/array';
import countryLabels from 'common/constants/country-labels';

import { CountryCode, useGetTeamQuery } from '__generated__/graphql';

import { getOnboardingBucketNumber } from '../pages/team-view';
import { ContractFromTeamQuery } from '../types';

export type SortParams = { sortBy: string; sortDesc: boolean };

export const handleSortTeam = (
  team: ContractFromTeamQuery[],
  sort: SortParams,
): ContractFromTeamQuery[] => {
  const { sortBy, sortDesc } = sort;
  return [...team].sort((current, other) => {
    let aVal: string | null | undefined | number = current.id;
    let bVal: string | null | undefined | number = other.id;

    switch (sortBy) {
      case 'name':
        aVal = `${current.member?.firstName?.toLowerCase()} ${current.member?.lastName?.toLowerCase()}`;
        bVal = `${other.member?.firstName?.toLowerCase()} ${other.member?.lastName?.toLowerCase()}`;
        break;
      case 'country':
        aVal = countryLabels[current.country as CountryCode] ?? '';
        bVal = countryLabels[other.country as CountryCode] ?? '';
        break;
      case 'createdOn':
      case 'startDate':
        aVal = current.startOn;
        bVal = other.startOn;
        break;
      case 'onboardingBucketNumber':
        aVal = getOnboardingBucketNumber(current.onboarding);
        bVal = getOnboardingBucketNumber(other.onboarding);
        break;
      default:
        break;
    }

    if (aVal && bVal && aVal < bVal) {
      return sortDesc ? 1 : -1;
    }
    return sortDesc ? -1 : 1;
  });
};

export default (): {
  team: ContractFromTeamQuery[];
  loading: boolean;
  sortTeam: (params: SortParams) => void;
} => {
  const [team, setTeam] = useState<ContractFromTeamQuery[]>([]);
  const [sort, setSort] = useState<SortParams>();

  const { data, loading } = useGetTeamQuery();

  useDeepCompareEffect(() => {
    if (data?.company?.contracts) {
      setTeam(data.company.contracts.filter(notEmpty));
    }
  }, [data || {}]);

  const sortTeam = (params: SortParams) => {
    setSort(params);
  };

  useEffect(() => {
    if (sort) {
      setTeam((prev) => handleSortTeam(prev, sort));
    }
  }, [data, sort]);

  return { team, loading, sortTeam };
};
