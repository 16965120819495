import React from 'react';

import { Location } from 'history';

import { notEmpty } from 'app/utils/array';
import {
  OnboardingStepProps,
  StepConfig,
} from 'contract-onboarding/components/step-navigation-footer';

import {
  ContractOnboarding,
  ContractOnboardingStep,
  Maybe,
} from '__generated__/graphql';

export const isCurrentStep = (
  step: ContractOnboardingStep,
  url: string,
  location: Location,
  stepConfig: { [key: string]: StepConfig },
): boolean => {
  const stepConfigRouteSplit = `${url}/${stepConfig[step].route()}`.split('/');
  const locationPathnameSplit = location.pathname.split('/');

  return (
    stepConfigRouteSplit[stepConfigRouteSplit.length - 1] ===
    locationPathnameSplit[locationPathnameSplit.length - 1]
  );
};

export const withStepMetadata = (
  WrappedComponent: React.FC<OnboardingStepProps>,
  step: ContractOnboardingStep,
  steps: Maybe<ContractOnboardingStep>[],
): React.FC => ({ ...props }) =>
  WrappedComponent ? (
    <WrappedComponent {...props} currentStep={step} onboardingSteps={steps} />
  ) : null;

export const isVisited = (
  index: number,
  onboardingSteps: Maybe<ContractOnboardingStep | string>[],
  current?: Maybe<ContractOnboardingStep | string>,
): boolean =>
  Boolean(current && index <= onboardingSteps.indexOf(current)) ?? false;

export const isLastStep = (
  onboardingSteps: (ContractOnboardingStep | null | string)[],
  index: number,
): boolean =>
  onboardingSteps
    ? index === onboardingSteps.filter(notEmpty).length - 1
    : false;

export const getNextStep = (
  onboarding: Maybe<ContractOnboarding> | undefined,
  currentStep: ContractOnboardingStep,
): ContractOnboardingStep | null => {
  if (onboarding?.steps) {
    const current = onboarding?.steps.find((x) => x === currentStep);
    const currentIndex = current && onboarding?.steps?.indexOf(current);
    const next =
      ((currentIndex || currentIndex === 0) &&
      onboarding.steps[currentIndex + 1]
        ? onboarding.steps[currentIndex + 1]
        : null) ?? (onboarding.current as ContractOnboardingStep);

    if (
      [
        ContractOnboardingStep.MEMBER_VISA_APPLICATION,
        ContractOnboardingStep.MEMBER_VISA_APPROVED,
      ].includes(next) &&
      next !== onboarding.current
    ) {
      return getNextStep(onboarding, next);
    }

    return next;
  }
  return null;
};
