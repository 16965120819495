/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, useModal } from '@multiplier/common';
import {
  useShouldDisplaySorFields,
  useSorOnboardingContext,
} from '@multiplier/hris-member-management';
import { format, isValid, parseISO } from 'date-fns';
import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import tw from 'twin.macro';
import * as yup from 'yup';

import { successNotification } from 'app/services/notification-services';
import ComboBox, { DropdownValue } from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import countryLabels from 'common/constants/country-labels';
import EndDateField from 'contract-onboarding/company/components/end-date-field';
import { WorkShiftInputComponent } from 'contract-onboarding/company/components/work-shift-input';
import { WorkShiftValue } from 'contract-onboarding/company/components/work-shift-input/components/work-shift-info';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import { buildRenderPropsForJobScope } from 'contract-onboarding/company/pages/definition-phase/pages/basic-details/validation-schema/job-scope-validation-schema';
import { convertWorkShiftToWorkShiftInput } from 'contract-onboarding/company/services/work-shift-utils';
import { contractTermLabels } from 'contract-onboarding/constants/labels';
import { useSubmitEmploymentDetails } from 'contract-onboarding/hooks';
import { Sections } from 'team/components/employee-details-component';
import { useGetOnboardingFieldConfig } from 'team/hooks';

import {
  Contract,
  ContractStatus,
  ContractTerm,
  ContractType,
  CountryCode,
  GetCountryComplianceLimitationsQuery,
  GetPositionsForIntegrationsQuery,
  WorkShiftInput,
} from '__generated__/graphql';

import StartDateField from '../../company/components/start-date-field';
import { ComplianceLimitation } from '../../company/pages/definition-phase/pages/basic-details/basic-details';
import { GroupedComplianceLimitationDefinition } from '../../company/pages/definition-phase/pages/basic-details/helpers';
import ContractReviewSectionModal, {
  FooterSection,
} from '../contract-review-section-modal';
import DetailRow from '../detail-row';
import { DetailForm, DetailGrid } from '../layout';
import SectionActionBar from '../section-action-bar';
import useEmploymentDetailsFormValidationSchema from './validation-schema';

export interface EmploymentDetailsForm {
  position: string | null;
  country: CountryCode | null;
  state: string | null;
  term: ContractTerm | null;
  startOn: Date;
  endOn: Date;
  scope?: string | null | undefined;
  workShift?: WorkShiftInput;
  workEmail?: string | null;
  employeeId?: string | null;
}

const EmploymentDetailSection: React.FC<{
  contract: Contract;
  countryCompliance?: GetCountryComplianceLimitationsQuery | undefined;
  edit: boolean;
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  renderAction?: React.ReactElement | null;
  contractBackdated?: boolean;
  toggle?: (key: string) => void;
  open?: boolean;
  supportedJobPositions?: GetPositionsForIntegrationsQuery['getPositionsForIntegrations'];
}> = ({
  contract: propsContract,
  countryCompliance,
  edit,
  uncommittedChangesCallback,
  renderAction,
  contractBackdated,
  toggle,
  open,
  supportedJobPositions,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const [showEdit, toggleEdit] = useToggle(false);
  const contract = useMemo(
    () => ({
      ...propsContract,
      endOn: propsContract.endedOn ?? propsContract.endOn,
    }),
    [propsContract],
  );
  const onboardingFieldConfig = useGetOnboardingFieldConfig(
    ['startOn'],
    contract.id,
  );

  const disabledFields = flow(
    compact,
    keyBy<ComplianceLimitation>('key'),
  )(countryCompliance?.country?.compliance?.limitations);

  const [
    showEditFormModal,
    handleCloseEditFormModal,
    handleOpenEditFormModal,
  ] = useModal(false);

  const modalCloseHandler = () => {
    toggleEdit(false);
    reset();
    handleCloseEditFormModal();
  };

  const schema = useEmploymentDetailsFormValidationSchema({
    type: contract.type,
    term: contract.term,
    country: contract.country,
    countryStateCode: contract.countryStateCode,
    workStatus: contract.workStatus,
    legalEntityId: contract.legalEntityId,
    onboardingFieldConfig,
    hasSupportedJobPositions: Boolean(
      supportedJobPositions && supportedJobPositions.length > 0,
    ),
  });

  const methods = useForm<EmploymentDetailsForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid: formValid, isDirty, dirtyFields },
    trigger,
  } = methods;

  useEffect(() => {
    reset({
      ...contract,
      startOn: parseISO(contract.startOn),
      endOn: contract.endOn ? parseISO(contract.endOn) : undefined,
      scope: contract.scope || undefined,
      workShift: contract.workShift
        ? convertWorkShiftToWorkShiftInput(contract.workShift)
        : undefined,
    });
  }, [contract]);

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.EMPLOYMENT_DETAILS,
        value: isDirty,
      });
  }, [isDirty]);

  const { onSubmit, loading } = useSubmitEmploymentDetails(contract.id, () => {
    modalCloseHandler();
    successNotification(
      t(
        'onboarding-phase.employment-details.success-notification',
        'Employment details have been updated in the contract.',
      ),
      '',
      false,
    );
  });

  useEffect(() => {
    trigger();
  }, [showEdit, trigger]);

  return (
    <Accordion
      name={
        contract.type === ContractType.CONTRACTOR
          ? t(
              'onboarding-phase.employment-details.contractor.header',
              'Agreement Details',
            )
          : t(
              'onboarding-phase.employment-details.header',
              'Employment Details',
            )
      }
      description={
        contract.type === ContractType.CONTRACTOR
          ? t(
              'onboarding-phase.employment-details.contractor.description',
              'Basic details of the agreement structure',
            )
          : t(
              'onboarding-phase.employment-details.description',
              'Category description to better explain what this section is about.',
            )
      }
      edit={
        edit &&
        !!(
          onboardingFieldConfig &&
          Object.values(onboardingFieldConfig).some((field) => field.editable)
        )
      } // Temporary workaround until onboarding data config is fully integrated
      // Previously, the edit prop used to control whether the fields below could be edited or not.
      // But since startOn is now configured to use the OnboardingDataConfig instead of the edit status,
      // both of these have to be checked to decide whether we show the edit button or not.
      // But now, since the edit button can even be visible in contract statuses such as ACTIVE,
      // we need to use the edit prop to decide whether fields that are not controlled by the onboarding data config
      // should be editable or not. This can be removed once the entire component depends on OnboardingDataConfig
      icon="tasks"
      onEditClick={() => {
        toggleEdit(true);
        handleOpenEditFormModal();
        if (toggle) toggle(Sections.EMPLOYMENT_DETAILS);
      }}
      warning={(uncommittedChangesCallback && isDirty) || contractBackdated}
      renderAction={renderAction ?? null}
      onClick={() => {
        if (toggle) toggle(Sections.EMPLOYMENT_DETAILS);
      }}
      open={open}
      editIconProps={{
        id: 'employment-details-section-edit-button',
      }}
    >
      <FormProvider {...methods}>
        <DetailForm onSubmit={handleSubmit(onSubmit)}>
          <FormData
            {...{
              ...contract,
              edit,
              disabledFields,
              supportedJobPositions,
              schema,
            }}
          />
        </DetailForm>
        {showEditFormModal && (
          <ContractReviewSectionModal
            modalTitle={t(
              'onboarding-phase.employment-details.modal-header',
              'Edit Employment Details',
            )}
            onClose={modalCloseHandler}
            tw="overflow-visible overflow-y-visible"
          >
            <DetailForm onSubmit={handleSubmit(onSubmit)} tw="gap-y-none">
              <div tw="flex flex-col p-extra-large py-none pt-base">
                <FormData
                  isFormEditing={showEdit}
                  {...{
                    ...contract,
                    edit,
                    disabledFields,
                    supportedJobPositions,
                    schema,
                  }}
                />
              </div>
              <FooterSection>
                <SectionActionBar
                  disabled={
                    loading ||
                    !formValid ||
                    Object.keys(dirtyFields).length === 0
                  }
                  onCancelClick={modalCloseHandler}
                  submitLoading={loading}
                  tw="mx-auto"
                />
              </FooterSection>
            </DetailForm>
          </ContractReviewSectionModal>
        )}
      </FormProvider>
    </Accordion>
  );
};

const FormData: React.FC<
  Contract & {
    isFormEditing?: boolean;
    edit: boolean;
    disabledFields: GroupedComplianceLimitationDefinition;
    supportedJobPositions: GetPositionsForIntegrationsQuery['getPositionsForIntegrations'];
    schema?:
      | yup.ObjectSchema<{
          scope?: yup.StringSchema<string | null | undefined>;
        }>
      | undefined;
  }
> = ({
  id,
  isFormEditing,
  type,
  position,
  country,
  countryStateCode,
  term,
  startOn,
  scope,
  endOn,
  workShift,
  status,
  workStatus,
  workEmail,
  employeeId,
  legalEntityId,
  edit,
  supportedJobPositions,
  schema,
}) => {
  const { t } = useTranslation('contract-onboarding.common');
  const onboardingFieldConfig = useGetOnboardingFieldConfig(['startOn'], id);
  const isContractOnboardingHourlyPayEnabled = useFeature(
    'contract-onboarding-hourly-pay',
  )?.on;

  const sorOnboardingContext = useSorOnboardingContext({
    type,
    legalEntityId,
  });
  const { shouldDisplayEmployeeIdAndWorkEmail } = useShouldDisplaySorFields({
    sorOnboardingContext,
  });

  const methods = useFormContext<EmploymentDetailsForm>();

  const {
    control,
    register,
    setValue,
    watch,
    formState: { defaultValues, errors },
  } = methods;

  const shouldShowEndDate =
    watch('term') === ContractTerm.FIXED ||
    [ContractStatus.OFFBOARDING, ContractStatus.ENDED].includes(
      status as ContractStatus,
    );

  const setWorkShiftValue = useCallback(
    (workShiftInput?: WorkShiftInput) => setValue('workShift', workShiftInput),
    [setValue],
  );

  const dropDownOptions: DropdownValue[] =
    supportedJobPositions?.map((i) => ({
      title: i?.designation ?? '',
      value: i?.designation ?? '',
    })) ?? [];

  const scopeOptions = buildRenderPropsForJobScope({ schema, defaultValues });

  return (
    <DetailGrid tw="mb-base">
      <DetailRow
        id="country-of-employment"
        label={t(
          'onboarding-phase.employment-details.country-of-employment',
          'Country of Employment',
        )}
        value={country && countryLabels[country]}
        isEditing={false}
      />
      <DetailRow
        id="country-state-of-employment"
        label={
          country === CountryCode.CAN
            ? t(
                'onboarding-phase.employment-details.country-province-of-employment',
                'Province',
              )
            : t(
                'onboarding-phase.employment-details.country-state-of-employment',
                'State',
              )
        }
        value={countryStateCode}
        isEditing={false}
      />
      <DetailRow
        id="job-title"
        label={
          type === ContractType.CONTRACTOR
            ? t(
                'onboarding-phase.employment-details.contractor.job-title',
                'Title',
              )
            : t('onboarding-phase.employment-details.job-title', 'Job Title')
        }
        value={position}
        isEditing={edit && isFormEditing} // Temporary workaround until onboarding data config is fully integrated
      >
        {supportedJobPositions ? (
          <Controller
            control={methods.control}
            name="position"
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="job-title"
                variant="default"
                value={value ?? ''}
                dropdownValues={dropDownOptions}
                placeholder={t(
                  'definition-phase.basic-details.job-title.placeholder',
                  'Software Engineer',
                )}
                onChange={onChange}
              />
            )}
          />
        ) : (
          <>
            <Controller
              control={methods.control}
              name="position"
              render={({ field }) => (
                <TextInput
                  id="job-title"
                  divStyles={tw`col-span-3`}
                  {...field}
                />
              )}
            />
            {errors.position && (
              <TextInput.Error tw="mt-8">
                {errors.position.message}
              </TextInput.Error>
            )}
          </>
        )}
      </DetailRow>
      <DetailRow
        id="employment-term"
        label={
          type === ContractType.CONTRACTOR
            ? t(
                'onboarding-phase.employment-details.agreement-term',
                'Agreement Term',
              )
            : t(
                'onboarding-phase.employment-details.employment-term',
                'Employment Term',
              )
        }
        value={term && contractTermLabels[term]}
        isEditing={false} // temporarily disabled until proper experience is built
      >
        <Controller
          control={control}
          name="term"
          render={({ field: { value, onChange } }) => (
            <ComboBox
              id="employment-term"
              tw="col-span-3"
              variant="default"
              value={value ?? ''}
              placeholder={t(
                'onboarding-phase.employment-term.placeholder',
                'Select',
              )}
              dropdownValues={[
                {
                  title: contractTermLabels[ContractTerm.FIXED],
                  value: ContractTerm.FIXED,
                },
                {
                  title: contractTermLabels[ContractTerm.PERMANENT],
                  value: ContractTerm.PERMANENT,
                },
              ]}
              onChange={onChange}
            />
          )}
        />
      </DetailRow>
      <DetailRow
        id="start-on"
        label={
          type === ContractType.CONTRACTOR
            ? t(
                'onboarding-phase.employment-details.agreement-start-date',
                'Agreement Start Date',
              )
            : t(
                'onboarding-phase.employment-details.employment-start-date',
                'Employment Start Date',
              )
        }
        value={
          startOn &&
          isValid(parseISO(startOn)) &&
          format(parseISO(startOn), 'dd MMMM yyyy')
        }
        isEditing={onboardingFieldConfig?.startOn?.editable && isFormEditing}
      >
        <StartDateField
          datePickerStyles={tw`col-span-3`}
          selectedCountry={country}
          selectedCountryState={countryStateCode}
          selectedContractType={type}
          selectedWorkStatus={workStatus}
          selectedLegalEntityId={legalEntityId}
        />
      </DetailRow>
      {shouldShowEndDate && (
        <DetailRow
          id="end-on"
          label={
            type === ContractType.CONTRACTOR
              ? t(
                  'onboarding-phase.employment-details.agreement-end-date',
                  'Agreement End Date',
                )
              : t(
                  'onboarding-phase.employment-details.employment-end-date',
                  'Employment End Date',
                )
          }
          value={
            endOn &&
            isValid(parseISO(endOn)) &&
            format(parseISO(endOn), 'dd MMMM yyyy')
          }
          isEditing={edit && isFormEditing} // Temporary workaround until onboarding data config is fully integrated
        >
          <EndDateField
            selectedCountry={country}
            selectedCountryState={countryStateCode}
            selectedContractType={type}
          />
        </DetailRow>
      )}
      {workShift && (
        <DetailRow
          id="work-shift"
          label={t(
            'onboarding-phase.employment-details.work-shift',
            'Work Shift',
          )}
          value={workShift?.startDay}
          renderValue={
            workShift ? (
              <WorkShiftValue
                tw="col-span-3"
                workShift={
                  convertWorkShiftToWorkShiftInput(workShift) as WorkShiftInput
                }
              />
            ) : undefined
          }
          isEditing={
            isContractOnboardingHourlyPayEnabled && edit && isFormEditing
          }
        >
          <div
            css={[
              tw`col-span-3`,
              !watch('workShift') && tw`flex items-center justify-between`,
            ]}
          >
            {!watch('workShift') && (
              <div>
                {t(
                  'onboarding-phase.employment-details.work-shift-not-available',
                  'No Working hours added',
                )}
              </div>
            )}
            <WorkShiftInputComponent
              country={country}
              workShift={watch('workShift')}
              onSave={setWorkShiftValue}
              countryStateCode={countryStateCode}
            />
          </div>
        </DetailRow>
      )}
      {shouldDisplayEmployeeIdAndWorkEmail && (
        <>
          <DetailRow
            id="work-email"
            label={t(
              'onboarding-phase.employment-details.work-email',
              'Work email',
            )}
            value={workEmail}
            isEditing={edit && isFormEditing}
          >
            <TextInput
              id="work-email"
              divStyles={tw`col-span-3`}
              {...register('workEmail')}
            />
          </DetailRow>
          <DetailRow
            id="employee-id"
            label={t(
              'onboarding-phase.employment-details.employee-id',
              'Employee ID',
            )}
            value={employeeId}
            isEditing={edit && isFormEditing}
          >
            <TextInput
              id="employee-id"
              divStyles={tw`col-span-3`}
              {...register('employeeId')}
            />
          </DetailRow>
        </>
      )}
      {scopeOptions.show && (
        <DetailRow
          id="jd"
          label={
            scopeOptions.required
              ? t('onboarding-phase.employment-details.jd', 'Job Description')
              : t(
                  'onboarding-phase.employment-details.jd-optional',
                  'Job Description (Optional)',
                )
          }
          value={scope}
          isEditing={edit && isFormEditing} // Temporary workaround until onboarding data config is fully integrated
        >
          <TextInput
            id="jd"
            divStyles={tw`col-span-3`}
            {...register('scope')}
          />
        </DetailRow>
      )}
    </DetailGrid>
  );
};

export default EmploymentDetailSection;
