/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';

import { Button, Icon, ThemeContext } from '@multiplier/common';
import tw, { css, styled, theme } from 'twin.macro';

import RequestAssetButton from 'equipment/components/request-asset-button';

import {
  Contract,
  CountryCode,
  Maybe,
  Product,
  ProductStatus,
  useGetProductsQuery,
} from '__generated__/graphql';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import useCreateEquipmentProcurement from '../../hooks/create-equipment-procurement';
import LaptopCard from '../laptop-card';

const MainContainer = tw.div`
  flex flex-col
`;

const Header = tw.div`
  flex flex-col mb-extra-large
`;

const Heading = tw.div`
  text-text-primary font-normal text-18
`;

const EquipmentDisplayContainer = tw.div`
  flex flex-row h-[110px] relative
`;

const CarouselWrapper = styled.div`
  ${tw`w-full`}
  ${css`
    .slider-wrapper {
      height: 110px;
    }
  `}
`;

const ArrowButton = styled.button(
  ({ newTheme, right = false }: { newTheme?: boolean; right?: boolean }) => [
    tw`flex absolute z-10 bg-white/95 h-[93px] w-[50px] justify-center items-center`,
    css`
      :hover {
        background: #eff6ff;
        box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.09);
      }
    `,
    newTheme && tw`bg-background-primary hover:bg-background-white`,
    newTheme &&
      right &&
      css`
        background: linear-gradient(to right, transparent 0%, white 100%);
      `,
    newTheme &&
      !right &&
      css`
        background: linear-gradient(to left, transparent 0%, white 100%);
      `,
  ],
);

const RequestContainer = tw.div`
  flex flex-col mt-40 justify-center items-center
`;
interface SelectLaptopContainerProps {
  contract?: Maybe<Contract>;
  numberOfLaptopCards?: number;
}

const SelectLaptopContainer: React.FC<SelectLaptopContainerProps> = ({
  contract,
  numberOfLaptopCards = 3,
}) => {
  const { t } = useTranslation('team.equipment');
  const [selectedLaptop, setSelectedLaptop] = React.useState('');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const { data } = useGetProductsQuery({
    variables: {
      code: contract?.country ?? CountryCode.USA,
      filter: {
        type: 'Laptop',
        status: ProductStatus.ACTIVE,
      },
    },
  });

  const { createProcurementRequest } = useCreateEquipmentProcurement();

  const carouselLaptopData = React.useMemo(() => {
    const products = data?.country?.productList?.products;
    const productsLength = products?.length ?? 0;
    const carouselData = [];
    for (
      let i = 0;
      i < Math.ceil(productsLength / numberOfLaptopCards);
      i += 1
    ) {
      const slicedCarouselData = products
        ?.slice(
          i * numberOfLaptopCards,
          Math.min(
            i * numberOfLaptopCards + numberOfLaptopCards,
            productsLength,
          ),
        )
        .map((item: Omit<Product, 'sku' | 'countryCode' | 'status'>) => (
          <LaptopCard
            key={item.id}
            item={item}
            setSelectedLaptop={setSelectedLaptop}
          />
        ));
      carouselData.push(
        <div key={`sliced-carousel-${i}`} tw="flex flex-row">
          {slicedCarouselData}
        </div>,
      );
    }
    return carouselData;
  }, [data]);

  const handleButtonClick = () => {
    createProcurementRequest(selectedLaptop, '', contract?.id ?? '');
  };

  return (
    <MainContainer data-testid="select-laptop-container">
      <Header>
        <Heading>
          {t(
            'select-laptop.heading',
            'Issue laptops & IT accessories to your employees',
          )}
        </Heading>
      </Header>
      <EquipmentDisplayContainer>
        <CarouselWrapper>
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            centerMode
            centerSlidePercentage={numberOfLaptopCards === 2 ? 83 : 93.5}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <ArrowButton
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    top: 0,
                    left: 0,
                  }}
                  newTheme={isNewThemeApplied}
                >
                  <Icon name="caret-left" fill={theme`colors.icon-primary`} />
                </ArrowButton>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <ArrowButton
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    top: 0,
                    right: 0,
                  }}
                  newTheme={isNewThemeApplied}
                  right
                >
                  <Icon name="caret-right" fill={theme`colors.icon-primary`} />
                </ArrowButton>
              )
            }
          >
            {carouselLaptopData}
          </Carousel>
        </CarouselWrapper>
      </EquipmentDisplayContainer>

      <RequestContainer>
        <Button
          data-testid="request-submit-button"
          type="submit"
          variant="default"
          tw=" h-[38px] w-[167px] text-p font-semibold m-auto mb-[20px]"
          disabled={!selectedLaptop}
          onClick={handleButtonClick}
        >
          {t('request-asset.button', 'Submit Request')}
        </Button>
        <RequestAssetButton
          selectedCountry={contract?.country ?? CountryCode.USA}
        />
      </RequestContainer>
    </MainContainer>
  );
};

export default SelectLaptopContainer;
