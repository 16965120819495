import React, { MouseEventHandler, useContext } from 'react';

import tw, { theme } from 'twin.macro';

import Card from '../card';
import Icon from '../icon';
import IconBadge from '../icon-badge';
import { ThemeContext } from '../theme-provider';

const getTypeStyles = (type: ToastType, isNewThemeApplied: boolean) => {
  switch (type) {
    case 'warning':
      return {
        icon: 'bell',
        background: tw`bg-background-warning-subtle`,
        foreground: theme`colors.icon-primary`,
        stroke: theme`colors.icon-primary`,
      };
    case 'error':
      return {
        icon: 'error-reverse',
        background: tw`bg-background-negative-subtle`,
        foreground: isNewThemeApplied ? undefined : theme`colors.error`,
        stroke: undefined,
      };
    case 'success':
      return {
        icon: 'check-circle-offset',
        background: tw`bg-background-positive-subtle`,
        foreground: isNewThemeApplied ? undefined : 'transparent',
        stroke: isNewThemeApplied ? undefined : theme`colors.slateGrey700`,
      };
    case 'info':
      return {
        icon: 'info',
        background: tw`bg-background-primary`,
        foreground: isNewThemeApplied ? undefined : theme`colors.primary`,
        stroke: undefined,
      };
    case 'uploadSuccess':
      return {
        icon: 'upload-cloud',
        background: tw`bg-background-primary`,
        foreground: isNewThemeApplied ? undefined : theme`colors.slateGrey700`,
        stroke: undefined,
      };
    case 'uploadError':
      return {
        icon: 'upload-cloud',
        background: tw`bg-background-negative-subtle`,
        foreground: isNewThemeApplied ? undefined : theme`colors.error`,
        stroke: undefined,
      };
    default:
      return {
        icon: 'info',
        background: tw`bg-background-primary`,
        foreground: isNewThemeApplied ? undefined : theme`colors.primary`,
        stroke: undefined,
      };
  }
};

export type ToastType =
  | 'warning'
  | 'error'
  | 'success'
  | 'info'
  | 'uploadSuccess'
  | 'uploadError';

interface ToastProps {
  type: ToastType;
  inline?: boolean;
  header: string;
  close?: boolean;
  handleClose?: MouseEventHandler<HTMLButtonElement>;
}

const Toast: React.FC<React.PropsWithChildren<ToastProps>> = ({
  type,
  inline = false,
  header,
  handleClose,
  close = false,
  children,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <Card
      css={[
        tw`flex flex-row p-base shadow-small items-center`,
        !isNewThemeApplied && tw`shadow-high`,
      ]}
      data-testid={`toast-${type}`}
      {...props}
    >
      <IconBadge
        iconContainerStyle={[
          tw`mr-base`,
          getTypeStyles(type, isNewThemeApplied).background,
        ]}
        icon={getTypeStyles(type, isNewThemeApplied).icon}
        color={getTypeStyles(type, isNewThemeApplied).foreground}
        stroke={getTypeStyles(type, isNewThemeApplied).stroke}
      />
      <div css={[tw`flex`, inline ? tw`flex-row` : tw`flex-col`]}>
        <div
          css={[
            inline && tw`mr-6`,
            isNewThemeApplied && tw`text-text-primary`,
            tw`font-semibold`,
          ]}
        >
          {header}
        </div>
        <div css={[isNewThemeApplied && tw`text-text-tertiary`]}>
          {children}
        </div>
      </div>
      <div tw="flex-grow" />
      {close && (
        <div tw="p-base">
          <button type="button" onClick={handleClose}>
            <Icon
              name="cross"
              fill={
                isNewThemeApplied
                  ? theme`colors.icon-primary`
                  : theme`colors.grey02`
              }
            />
          </button>
        </div>
      )}
    </Card>
  );
};

export { default as MobileToast } from './mobile-toast';

export default Toast;
