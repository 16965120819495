/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  ComboBox,
  DatePicker,
  FileUpload,
  Icon,
  IconButton,
  TextInput,
} from '@multiplier/common';
import { isEmpty, isNil } from 'lodash';
import tw, { styled, theme } from 'twin.macro';

import {
  genderLabel,
  relationshipLabel,
} from 'common/constants/default-labels';
import { InsuranceDetailsPrams } from 'contract-onboarding/member/pages/onboarding/pages/insurance-details';

import { Gender, Relationship } from '__generated__/graphql';

const defaultRelationshipOptions = [
  ...Object.values(Relationship).map((c) => ({
    label: relationshipLabel[c],
    value: c,
    title: relationshipLabel[c],
  })),
];

const defaultGenderOptions = [
  ...Object.values(Gender).map((c) => ({
    label: genderLabel[c],
    value: c,
    title: genderLabel[c],
  })),
];

const FormContainer = tw.div`grid grid-cols-2 gap-x-12 gap-y-16 mt-16`;

const DependantLabelContainer = tw.div`flex items-center p-0`;
const DependantLabel = tw.p`ml-8 text-p font-semibold text-slateGrey900`;

const InputLabel = styled(TextInput.Label)(() => [
  tw`text-ps text-slateGrey900 font-medium`,
]);

const Form: React.FC<{
  index: number;
  isUSA: boolean;
  remove: (id: number) => void;
}> = ({ index, isUSA, remove }) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
    trigger,
  } = useFormContext<InsuranceDetailsPrams>();

  const handleRemoveDependant = (idx: number) => {
    remove(idx);
  };

  return (
    <>
      <DependantLabelContainer>
        <div tw="bg-grey05 rounded-8 p-12">
          <Icon
            name="user"
            fill="none"
            stroke={theme`colors.primaryBlue600`}
            strokeWidth={2}
            width={24}
            height={24}
          />
        </div>
        <DependantLabel>Dependent {index + 1}</DependantLabel>
        <IconButton
          variant="outline"
          size="small"
          name="bin"
          stroke={theme`colors.primary`}
          fill="transparent"
          data-testid="delete-button"
          tw="ml-auto"
          onClick={() => handleRemoveDependant(index)}
        />
      </DependantLabelContainer>
      <FormContainer>
        <TextInput.Container>
          <InputLabel htmlFor="firstName">
            {t('dependent-form.first-name.label', 'First Name')}
          </InputLabel>
          <TextInput
            id="firstName"
            placeholder={t('dependent-form.first-name.placeholder', 'John')}
            {...register(`insuranceDetails.${index}.firstName`)}
          />
          {errors?.insuranceDetails?.[index]?.firstName && (
            <TextInput.Error>
              {errors?.insuranceDetails?.[index]?.firstName?.message}
            </TextInput.Error>
          )}
        </TextInput.Container>
        <TextInput.Container>
          <InputLabel htmlFor="lastName">
            {t('dependent-form.last-name.label', 'Last Name')}
          </InputLabel>
          <TextInput
            id="lastName"
            placeholder={t('dependent-form.last-name.placeholder', 'Doe')}
            {...register(`insuranceDetails.${index}.lastName`)}
          />
          {errors?.insuranceDetails?.[index]?.lastName && (
            <TextInput.Error>
              {errors?.insuranceDetails?.[index]?.lastName?.message}
            </TextInput.Error>
          )}
        </TextInput.Container>
        <TextInput.Container>
          <InputLabel>
            {t('dependent-form.date-of-birth.label', 'Date of Birth')}
          </InputLabel>
          <Controller
            control={control}
            name={`insuranceDetails.${index}.dateOfBirth`}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                data-testid="date-of-birth"
                disabledInput
                value={value as never}
                onChange={onChange}
              />
            )}
          />
          {errors?.insuranceDetails?.[index]?.dateOfBirth && (
            <TextInput.Error>
              {errors?.insuranceDetails?.[index]?.dateOfBirth?.message}
            </TextInput.Error>
          )}
        </TextInput.Container>
        <TextInput.Container>
          <InputLabel>{t('dependent-form.gender.label', 'Gender')}</InputLabel>
          <Controller
            control={control}
            name={`insuranceDetails.${index}.gender`}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="select-gender"
                variant="default"
                dropdownValues={defaultGenderOptions}
                showArrow
                value={value ?? ''}
                placeholder={t('dependent-form.gender.placeholder', 'Select')}
                onChange={onChange}
              />
            )}
          />
          {errors?.insuranceDetails?.[index]?.gender && (
            <TextInput.Error>
              {errors?.insuranceDetails?.[index]?.gender?.message}
            </TextInput.Error>
          )}
        </TextInput.Container>
        <TextInput.Container>
          <InputLabel>
            {t('dependent-form.relationship.label', 'Relationship to you')}
          </InputLabel>
          <Controller
            control={control}
            name={`insuranceDetails.${index}.relationship`}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                data-testid="select-relationship"
                variant="default"
                dropdownValues={defaultRelationshipOptions}
                showArrow
                value={value || ''}
                placeholder={t(
                  'dependent-form.relationship.placeholder',
                  'Select',
                )}
                onChange={(v) => {
                  onChange(v);
                  trigger(`insuranceDetails.${index}.nationalIdNumber`);
                }}
              />
            )}
          />
          {errors?.insuranceDetails?.[index]?.relationship && (
            <TextInput.Error>
              {errors?.insuranceDetails?.[index]?.relationship?.message}
            </TextInput.Error>
          )}
        </TextInput.Container>
        <TextInput.Container>
          {isUSA ? (
            <InputLabel>
              <Trans
                t={t}
                key="dependent-form.national-id-number.label-usa-non-optional"
              >
                SSN number
              </Trans>
            </InputLabel>
          ) : (
            <InputLabel>
              <Trans t={t} key="dependent-form.national-id-number.label">
                National ID number{' '}
                {[Relationship.DAUGHTER, Relationship.SON].includes(
                  watch(
                    `insuranceDetails.${index}.relationship`,
                  ) as Relationship,
                ) && (
                  <span tw="text-ps text-grey01 font-normal">
                    (optional for son/daughter)
                  </span>
                )}
              </Trans>
            </InputLabel>
          )}

          <TextInput
            id="nationalIdNumber"
            placeholder={
              isUSA
                ? t(
                    'dependent-form.national-id-number.placeholder-usa',
                    'Same number as SSN number',
                  )
                : t(
                    'dependent-form.national-id-number.placeholder',
                    'Same number as ID proof',
                  )
            }
            {...register(`insuranceDetails.${index}.nationalIdNumber`)}
          />
          {errors?.insuranceDetails?.[index]?.nationalIdNumber && (
            <TextInput.Error>
              {errors?.insuranceDetails?.[index]?.nationalIdNumber?.message}
            </TextInput.Error>
          )}
        </TextInput.Container>
        <div tw="col-span-2">
          <Controller
            name={`insuranceDetails.${index}.identity`}
            control={control}
            render={() => (
              <FileUpload
                accept={[
                  'image/jpeg',
                  'image/jpg',
                  'image/png',
                  'application/pdf',
                ]}
                multiple={false}
                maxSize={10 * 1024 * 1024}
                onChange={(e: File[]) => {
                  if (isEmpty(e) || isNil(e)) return;
                  setValue(`insuranceDetails.${index}.identity`, e[0]);
                }}
                title={
                  isUSA
                    ? t(
                        'dependent-form.file-upload.title-usa',
                        'Upload SSN ID proof (optional)',
                      )
                    : t(
                        'dependent-form.file-upload.title',
                        'Upload ID proof (optional)',
                      )
                }
                description={t(
                  'member.basic-details.file-upload.description',
                  'File types supported: JPEG, JPG, PNG, or PDF',
                )}
                minimal
              />
            )}
          />
        </div>
      </FormContainer>
    </>
  );
};

export default Form;
