import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractType } from '__generated__/graphql';

import { ContractOnboardingStatusConfig } from '../status-config';

const signatureEmployeeSent: ContractOnboardingStatusConfig = {
  title: (t, name, contractType) =>
    contractType === ContractType.CONTRACTOR
      ? t('onboarding-phase.signature-employee-sent-contractor.title', {
          defaultValue: 'Agreement sent to {{name}}',
          replace: {
            name,
          },
        })
      : t('onboarding-phase.signature-employee-sent.title', {
          defaultValue: 'Waiting for {{name}} to sign',
          replace: {
            name,
          },
        }),
  description: (t, firstName, gender, country, contractType) =>
    contractType === ContractType.CONTRACTOR
      ? t('onboarding-phase.signature-employee-sent-contractor.description', {
          defaultValue:
            "Agreement is sent to {{firstName}} and we're waiting for signatures. Once signed, {{firstName}} will be asked to create a Multiplier account to provide additional details.",
          replace: {
            firstName,
          },
          context: gender,
        })
      : t('onboarding-phase.signature-employee-sent.description', {
          defaultValue:
            "Contract is sent to {{firstName}} and we're waiting for signatures. Once signed, {{firstName}} will be asked to create a Multiplier account to provide additional details for payroll.",
          replace: {
            firstName,
          },
          context: gender,
        }),
  statusLabel: (t, contractType, name) => {
    switch (contractType) {
      case ContractType.FREELANCER:
        return t(
          'onboarding-phase.signature-freelancer-sent.label',
          'Contract emailed to Freelancer',
        );
      case ContractType.CONTRACTOR:
        return t('onboarding-phase.signature-contractor-sent.label', {
          defaultValue: 'Agreement sent to {{name}}',
          replace: {
            name,
          },
        });
      default:
        return t(
          'onboarding-phase.signature-employee-sent.label',
          'Contract emailed to employee',
        );
    }
  },
  pendingOn: (contractType) =>
    contractType === ContractType.CONTRACTOR
      ? PendingOn.CONTRACTOR
      : PendingOn.EMPLOYEE,
  pendingLabel: (t, contractType): string =>
    contractType === ContractType.CONTRACTOR
      ? t(
          'onboarding-phase.signature-employee-sent-contractor.status',
          'Pending on Contractor',
        )
      : t(
          'onboarding-phase.signature-employee-sent.status-v2',
          'Pending on Employee',
        ),
  allowRevoke: () => true,
  allowDelete: () => true,
};

export default signatureEmployeeSent;
