/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect, useToggle } from 'react-use';

import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, Button, UploadedDocumentViewer } from '@multiplier/common';
import tw from 'twin.macro';

import { notEmpty } from 'app/utils/array';
import { stringAsKebabCase } from 'app/utils/format';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import { useGetCountryLegalRequirements } from 'contract-onboarding/member/hooks';
import { DocumentFormParams } from 'contract-onboarding/member/pages/onboarding/pages/legal-documents';
import LegalDocumentsFormFields from 'contract-onboarding/member/pages/onboarding/pages/legal-documents/components/form-fields';
import {
  getLegalDocumentSchema,
  groupLegalDocumentDefinitionByKey,
} from 'contract-onboarding/member/services/legal-documents';

import {
  Contract,
  ContractType,
  Document,
  LegalDocumentInput,
  LegalDocumentRequirement,
  Member,
  useUpdateLegalDocumentsMutation,
} from '__generated__/graphql';

import { DetailForm } from '../layout';

const SectionActionBar = React.lazy(() => import('../section-action-bar'));

const DocumentsSection: React.FC<{
  memberId: Member['id'];
  documents: Member['legalDocuments'];
  edit?: boolean;
  workStatus: Contract['workStatus'];
  country: Contract['country'];
  contractType?: Contract['type'];
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  isTeamView?: boolean;
}> = ({
  memberId,
  documents,
  edit = false,
  workStatus,
  country,
  contractType,
  uncommittedChangesCallback,
  isTeamView = false,
}) => {
  const { t } = useTranslation([
    'contract-onboarding.common',
    'contract-onboarding.member',
  ]);

  const {
    getRequirements,
    loading: loadingCountryRequirements,
    requirements,
  } = useGetCountryLegalRequirements(workStatus);

  const [updateLegalDocuments, { loading }] = useUpdateLegalDocumentsMutation({
    onCompleted: () => {
      toggleEdit(false);
    },
  });

  const [showEdit, toggleEdit] = useToggle(false);

  const memberDocumentRequirements = useMemo<{
    [key: string]: LegalDocumentRequirement;
  }>(
    () =>
      groupLegalDocumentDefinitionByKey(
        requirements.memberDocumentRequirements,
      ),
    [requirements],
  );

  useEffect(() => {
    if (country && contractType === ContractType.FREELANCER)
      getRequirements(country, contractType);
    else if (country) getRequirements(country);
  }, [country]);

  const methods = useForm<DocumentFormParams>({
    mode: 'onChange',
    resolver: yupResolver(
      getLegalDocumentSchema(memberDocumentRequirements, t),
    ),
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  useDeepCompareEffect(() => {
    reset({
      legalDocuments:
        requirements.memberDocumentRequirements?.filter(notEmpty) ?? undefined,
    });
  }, [requirements]);

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.DOCUMENT_DETAILS,
        value: isDirty,
      });
  }, [isDirty]);

  const downloadAllDocuments = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const downloadLoop = (links: string[] | null) => {
      if (!links || links.length === 0) {
        clearInterval(interval);
        return;
      }
      const link = links.pop();
      window.open(link, '_self');
    };
    const interval = setInterval(
      downloadLoop,
      500,
      documents
        ?.flatMap((document) =>
          document?.files?.map(
            (nestedDoc: Document | null) => nestedDoc?.downloadUrl,
          ),
        )
        ?.filter((link) => typeof link === 'string'),
    );
  };

  return (
    <Accordion
      name={t('onboarding-phase.documents.header', 'Documents')}
      nameStyles={isTeamView ? tw`text-p` : tw``}
      description={
        !isTeamView
          ? t(
              'onboarding-phase.documents.description',
              'Category description to better explain what this section is about.',
            )
          : ''
      }
      icon={isTeamView ? '' : 'file'}
      edit={edit}
      onEditClick={toggleEdit}
      renderAction={
        !isTeamView && documents && documents.length > 0 ? (
          <Button
            tw="whitespace-nowrap"
            size="small"
            variant="secondary"
            onClick={downloadAllDocuments}
          >
            {t('download-all', 'Download All')}
          </Button>
        ) : null
      }
      warning={uncommittedChangesCallback && isDirty}
    >
      <DetailForm
        onSubmit={handleSubmit(async (v) => {
          const documentMap = v.legalDocuments.map((i) =>
            i.acceptMultiple
              ? { key: i.key, documents: i.value }
              : { key: i.key, documents: [i.value] },
          );

          await updateLegalDocuments({
            variables: {
              id: String(memberId),
              data: documentMap as LegalDocumentInput | LegalDocumentInput[],
            },
          });
        })}
      >
        {!showEdit && (
          <div tw="flex flex-row flex-wrap gap-small">
            {documents
              ?.filter(notEmpty)
              .map((document) =>
                document?.files
                  ?.filter(notEmpty)
                  .map((nestedDocument: Document) => (
                    <UploadedDocumentViewer
                      key={nestedDocument?.id}
                      label={t(
                        `contract-onboarding.member:legal-documents.${
                          document.label ?? stringAsKebabCase(document.key)
                        }`,
                        document?.label ?? document?.key ?? '',
                      )}
                      link={nestedDocument?.downloadUrl}
                    />
                  )),
              )}
          </div>
        )}
        {showEdit && (
          <>
            <div data-testid="documents-form" tw="flex flex-col -mt-large">
              <FormProvider {...methods}>
                <LegalDocumentsFormFields
                  workStatus={workStatus}
                  country={country}
                  legalDocuments={documents}
                  contractType={contractType}
                />
              </FormProvider>
            </div>
            <SectionActionBar
              disabled={!isValid}
              submitLoading={loading || loadingCountryRequirements}
              onCancelClick={() => {
                toggleEdit(false);
                reset();
              }}
            />
          </>
        )}
      </DetailForm>
    </Accordion>
  );
};

export default DocumentsSection;
