import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { StyledComponent } from '@emotion/styled';
import tw, { TwStyle, styled } from 'twin.macro';

type StyledRowProps = {
  bgDark?: boolean;
  hasHover?: boolean;
  highlight?: boolean;
  rowStyles?: TwStyle;
  isGroupedRow?: boolean;
  isNewThemeApplied: boolean;
};

const StyledRow: StyledComponent<
  any,
  DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
> = styled.tr(
  ({
    bgDark,
    hasHover,
    highlight,
    rowStyles,
    isGroupedRow,
    isNewThemeApplied,
  }: StyledRowProps) => [
    tw`text-left`,
    bgDark &&
      (isNewThemeApplied
        ? tw`bg-background-primary`
        : tw`bg-grey04 bg-opacity-20`),
    hasHover &&
      (isNewThemeApplied
        ? tw`hover:(cursor-pointer bg-background-secondary)`
        : tw`hover:(cursor-pointer bg-grey04 bg-opacity-50)`),
    highlight &&
      (isNewThemeApplied
        ? tw`bg-background-negative-faded`
        : tw`bg-error bg-opacity-5`),
    highlight &&
      bgDark &&
      (isNewThemeApplied
        ? tw`bg-background-negative-faded`
        : tw`bg-error bg-opacity-5`),
    isGroupedRow &&
      (isNewThemeApplied
        ? tw`border-y border-border-secondary bg-background-primary`
        : tw`border-y border-slateGrey200 bg-slateGrey50`),
    rowStyles,
  ],
);

export default StyledRow;
