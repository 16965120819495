import { getDaysInMonth, isSameDay, isValid } from 'date-fns';

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednessday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getYearRange = (min: number, max: number): number[] =>
  Array(max - min + 1)
    .fill(0)
    .map((_, idx) => min + idx);

export const getCalendarLayoutForMonth = (date: Date): Date[] => {
  const noOfDays = getDaysInMonth(date);
  const startOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
  );
  const emptyArrayLength =
    startOfMonth.getDay() === 0 ? 6 : startOfMonth.getDay() - 1;
  const monthLayout = [
    ...new Array(emptyArrayLength),
    ...new Array(noOfDays)
      .fill(0)
      .map((_, ind) => new Date(date.getFullYear(), date.getMonth(), ind + 1)),
  ];
  return monthLayout;
};

export const isValidDate = (d: string | number | Date | null): boolean => {
  if (d == null) return false;
  if (typeof d === 'number') {
    if (d > 8640000000000000) return false;
    if (d < -8640000000000000) return false;
  }
  const dateObj = new Date(d);
  return isValid(dateObj);
};

export const checkIfSameDay = (
  a: Date | null | undefined,
  b: Date | null | undefined,
): boolean => {
  if (!a || !b) {
    return false;
  }
  return isSameDay(a, b);
};
