/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { Button, Icon, TextInput, ThemeContext } from '@multiplier/common';
import tw from 'twin.macro';

import { Contract, WorkShiftInput } from '__generated__/graphql';

import Dialog from './components/dialog';
import WorkShiftInfo from './components/work-shift-info';

type WorkShiftInputProps = {
  country: Contract['country'];
  workShift?: WorkShiftInput;
  onSave: (workShiftInput?: WorkShiftInput) => void;
  countryStateCode: Contract['countryStateCode'];
};
export const WorkShiftInputComponent: React.FC<WorkShiftInputProps> = ({
  country,
  workShift,
  onSave,
  countryStateCode,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  const [edit, toggleEdit] = useToggle(false);
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <>
      {workShift ? (
        <WorkShiftInfo
          workShift={workShift}
          onDeleteClick={() => onSave(undefined)}
          onEditClick={() => toggleEdit(true)}
        />
      ) : (
        <Button variant="secondary" size="medium" onClick={() => toggleEdit()}>
          <div tw="flex items-center">
            <div
              css={[
                tw`rounded-full p-2 border border-border-primary`,
                !isNewThemeApplied && tw`border-primary`,
              ]}
            >
              <Icon
                name="plus"
                css={[
                  isNewThemeApplied && tw`h-base w-base`,
                  !isNewThemeApplied && tw`h-small w-small`,
                ]}
              />
            </div>
            &nbsp;&nbsp;
            {t('work-shift.add-work-shift.button', 'Add Working Hours')}
          </div>
        </Button>
      )}
      <Dialog
        show={edit}
        onClose={() => toggleEdit(false)}
        country={country}
        workShift={workShift}
        onSave={onSave}
        countryStateCode={countryStateCode}
      />
    </>
  );
};

// TODO: rebranding -- seems to be not used, we can remove it when this is confirmed
const WorkShiftInputWrapper: React.FC<WorkShiftInputProps> = ({
  country,
  workShift,
  onSave,
  countryStateCode,
}) => {
  const { t } = useTranslation('contract-onboarding.company');
  return (
    <div>
      <div tw="flex items-center gap-x-[10px]">
        <TextInput.Label>
          {t('work-shift.add-work-shift.title', 'Work Shift (Optional)')}
        </TextInput.Label>
        <Icon name="info" />
      </div>
      <p tw="text-ps text-grey01 mb-12">
        {t(
          'work-shift.add-work-shift.description',
          "Define employee's standard working hours & days",
        )}
      </p>
      <WorkShiftInputComponent
        country={country}
        workShift={workShift}
        onSave={onSave}
        countryStateCode={countryStateCode}
      />
    </div>
  );
};

export default WorkShiftInputWrapper;
