import { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  CurrencyCode,
  Maybe,
  useGetCompanyHomeCurrencyLazyQuery,
  useGetMemberHomeCurrencyLazyQuery,
} from '../../__generated__/graphql';
import { Experience } from '../models/module-config';
import { userVar } from '../vars';

const useHomeCurrency = (): {
  loading: boolean;
  homeCurrency?: Maybe<CurrencyCode>;
} => {
  const {
    experiences: { current },
  } = useReactiveVar(userVar);
  const [
    getMemberHomeCurrency,
    { data: memberCurrencyResponse, loading: loadingMemberHomeCurrency },
  ] = useGetMemberHomeCurrencyLazyQuery();

  const [
    getCompanyHomeCurrency,
    { data: companyCurrencyResponse, loading: loadingCompanyHomeCurrency },
  ] = useGetCompanyHomeCurrencyLazyQuery();

  const homeCurrency = useMemo(() => {
    if (current === Experience.COMPANY) {
      getCompanyHomeCurrency();
      return companyCurrencyResponse?.company?.primaryEntity?.currency;
    }

    getMemberHomeCurrency();
    return memberCurrencyResponse?.member?.contracts?.[0]?.currency;
  }, [current, memberCurrencyResponse, companyCurrencyResponse]);

  return {
    loading: loadingCompanyHomeCurrency || loadingMemberHomeCurrency,
    homeCurrency,
  };
};

export default useHomeCurrency;
