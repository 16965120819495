import { PendingOn } from 'contract-onboarding/components/pending-on-pill';

import { ContractType } from '__generated__/graphql';

import { ContractOnboardingStatusConfig } from '../status-config';

const signatureEmployeeSigned: ContractOnboardingStatusConfig = {
  title: (t, name) =>
    t('onboarding-phase.signature-employee-signed.title', {
      defaultValue: 'Waiting on Multiplier to sign contract',
      replace: {
        name,
      },
    }),
  description: (t, firstName, gender) =>
    t('onboarding-phase.signature-employee-signed.description', {
      defaultValue:
        "Contract is sent to {{firstName}} and we're waiting for signatures. Once signed, {{firstName}} will be asked to create a Multiplier account to provide additional details for payroll.",
      replace: {
        firstName,
      },
      context: gender,
    }),
  statusLabel: (t, contractType, name) => {
    switch (contractType) {
      case ContractType.FREELANCER:
        return t(
          'onboarding-phase.signature-freelancer-signed.label',
          'Contract signed by Freelancer',
        );
      case ContractType.CONTRACTOR:
        return t('onboarding-phase.signature-contractor-signed.label', {
          defaultValue: 'Agreement signed by {{name}}',
          replace: {
            name,
          },
        });
      default:
        return t(
          'onboarding-phase.signature-employee-signed.label',
          'Contract signed by employee',
        );
    }
  },
  pendingOn: () => PendingOn.MULTIPLIER,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.signature-employee-signed.status',
      'Pending on Multiplier',
    ),
  allowRevoke: () => true,
  allowDelete: () => true,
};

export default signatureEmployeeSigned;
