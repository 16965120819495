/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import tw, { styled } from 'twin.macro';

import { CompanyUser } from '__generated__/graphql';

interface ShowSignatoryContentProps {
  signatory?: CompanyUser | null;
  loading: boolean;
  hasSignatories: boolean;
  openSignatoryModal: () => void;
}

type SignatoryContentProps = Pick<ShowSignatoryContentProps, 'signatory'>;

const SignatoryContainer = styled.div`
  ${tw`rounded-8 flex flex-row items-center gap-x-24 font-size[14px]`}
`;

const SignatoryDetailWrapper = styled.div`
  ${tw`flex flex-row flex-grow justify-between`}
`;

const SignatoryDetailContainer = styled.div`
  ${tw`flex flex-col justify-between align-middle`}
`;

const Signatory = styled.p`
  ${tw`text-background`}
`;

const SignatoryEmail = styled.p`
  ${tw`text-background font-normal`}
`;

const SignatoryAction = styled.p`
  ${tw`flex justify-end align-middle`}
`;

const SignatoryContent: React.FC<
  React.PropsWithChildren<SignatoryContentProps>
> = ({ signatory, children }) => {
  const { t } = useTranslation('contract-onboarding.company');
  return (
    <SignatoryContainer>
      <p tw="font-semibold">
        {t(
          'onboarding-phase.progress-widget-content.show-signatory-content.signatory-prefix',
          'Signatory',
        )}
      </p>
      <SignatoryDetailWrapper>
        <SignatoryDetailContainer>
          <Signatory>
            {`${signatory?.firstName ?? ''} ${
              signatory?.lastName ?? ''
            }`.trim()}{' '}
            {signatory?.title && <>{`(${signatory?.title})`}</>}
          </Signatory>
          {signatory?.emails?.length && (
            <SignatoryEmail>{signatory?.emails[0]?.email ?? ''}</SignatoryEmail>
          )}
        </SignatoryDetailContainer>
        <SignatoryAction>{children}</SignatoryAction>
      </SignatoryDetailWrapper>
    </SignatoryContainer>
  );
};

export default SignatoryContent;
