/** @jsxImportSource @emotion/react */
import React from 'react';
import 'twin.macro';

import { useTranslation } from 'react-i18next';

import { Card, CardSeparator } from '@multiplier/common';

import { Contract, ContractAgreementType, Member } from '__generated__/graphql';

import DeleteAction from '../../../components/progress-widget-content/delete-contract-action';
import RevokeContractAction from '../../../components/progress-widget-content/revoke-contract-action';

const AdditionalActionsSection: React.FC<{
  contractId: Contract['id'];
  memberId: Member['id'];
  memberFirstName: Member['firstName'];
  compliance: Contract['compliance'];
  contractBlocked: Contract['contractBlocked'];
  allowRevoke?: boolean;
  allowDelete?: boolean;
  workflowUpdating: boolean;
  toggleWorkflowUpdating: (nextValue?: boolean) => void;
}> = ({
  contractId,
  memberId,
  memberFirstName,
  compliance,
  contractBlocked,
  allowRevoke,
  allowDelete,
  workflowUpdating,
  toggleWorkflowUpdating,
}) => {
  const { t } = useTranslation('contract-onboarding');

  const showContractRevokeButton =
    compliance?.contractAgreementType ===
    ContractAgreementType.MULTIPLIER_TEMPLATE;

  if (!allowRevoke && !allowDelete) return null;

  return (
    <div tw="flex flex-col gap-y-base">
      <h6 tw="text-h6 text-text-primary font-semibold">
        {t('onboarding-phase.additional-actions.header', 'Additional actions')}
      </h6>
      <Card tw="flex flex-col p-extra-large gap-y-extra-large mb-base">
        {allowRevoke && showContractRevokeButton && (
          <>
            <RevokeContractAction
              contractId={contractId}
              workflowUpdating={workflowUpdating}
              toggleWorkflowUpdating={toggleWorkflowUpdating}
            />
            <CardSeparator />
          </>
        )}
        {allowDelete && (
          <DeleteAction
            contractId={contractId}
            memberId={memberId}
            memberName={memberFirstName}
            workflowUpdating={workflowUpdating}
            toggleWorkflowUpdating={toggleWorkflowUpdating}
            contractBlocked={contractBlocked ?? false}
          />
        )}
      </Card>
    </div>
  );
};

export default AdditionalActionsSection;
