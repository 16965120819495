/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, ThemeContext, ToolTip } from '@multiplier/common';
import { TFunction } from 'i18next';
import tw from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import { useHomeCurrency } from 'app/hooks';
import BreakdownEntry from 'salary-calculator/components/breakdown/entry';

import {
  CalculationBreakdownFragment,
  CountryCode,
  RateFrequency,
} from '__generated__/graphql';

export const salaryCalculationBreakdownLabels: {
  [key: string]: (t: TFunction<'salary-calculator'>) => string;
} = {
  grossAnnualSalary: (t) =>
    t('breakdown.gross-annual-salary', 'Gross Annual Salary'),
  grossMonthlySalary: (t) =>
    t('breakdown.gross-monthly-salary', 'Gross Monthly Salary'),
  TotalYearlyCost: (t) => t('breakdown.total-yearly-cost', 'Total Annual Cost'),
  TotalMonthlyCost: (t) =>
    t('breakdown.total-monthly-cost', 'Total Monthly Cost'),
  incomeTax: (t) => t('breakdown.income-tax', 'Income Tax'),
  netAnnuallySalary: (t) =>
    t('breakdown.net-annually-salary', 'Net Annual Salary'),
  netMonthlySalary: (t) =>
    t('breakdown.net-monthly-salary', 'Net Monthly Salary'),
};

const BreakdownColumn: React.FC<{
  type: 'employee' | 'company';
  columnData: CalculationBreakdownFragment;
  country: CountryCode;
  frequency?: RateFrequency;
}> = ({ type, columnData, country, frequency, ...props }) => {
  const { t } = useTranslation('salary-calculator');
  const { isNewThemeApplied } = useContext(ThemeContext);

  const { homeCurrency } = useHomeCurrency();

  return (
    <div {...props}>
      <h6 tw="text-ps font-semibold text-text-primary mb-large">
        {type === 'company'
          ? t('breakdown.company-payable', 'Amount you pay')
          : t('breakdown.employee-payable', 'Amount employee gets')}
      </h6>
      {columnData?.entries?.map((entry) => (
        <BreakdownEntry
          key={entry?.name}
          name={
            entry?.name && salaryCalculationBreakdownLabels[entry.name]
              ? salaryCalculationBreakdownLabels[entry.name](t)
              : t(`breakdown.${country?.toLowerCase()}.${entry?.name}`)
          }
          currency={entry?.currency}
          amount={entry?.amount}
          nestedEntries={entry?.entries}
          country={country}
          frequency={frequency}
          minimal
          type={type}
          note={entry?.note}
        />
      ))}
      <BreakdownEntry
        name={
          columnData?.total?.name &&
          salaryCalculationBreakdownLabels[columnData.total.name]
            ? salaryCalculationBreakdownLabels[columnData.total.name](t)
            : t(
                `breakdown.${country?.toLowerCase()}.${
                  columnData?.total?.name
                }`,
              )
        }
        currency={columnData?.total?.currency}
        amount={columnData?.total?.amount}
        country={country}
        frequency={frequency}
        minimal
        type={type}
        note={columnData?.total?.note}
      >
        {type === 'company' && homeCurrency !== columnData.total.currency && (
          <div tw="flex mt-small items-center">
            <span
              css={[
                tw`text-ps text-text-tertiary`,
                !isNewThemeApplied && tw`text-grey01`,
              ]}
            >
              {t('breakdown.company-payable-approx', 'Approx.')}
            </span>
            <CurrencyHelper
              tw="mx-tiny"
              referenceCurrency={columnData?.total?.currency}
              amount={columnData?.total?.amount}
            />
            <ToolTip
              variant="top"
              content={t(
                'breakdown.company-payable-fx-info',
                'The FX rate used here is mid-market rate and is for indicative purposes only',
              )}
            >
              <Icon name="info" height="14" width="14" />
            </ToolTip>
          </div>
        )}
      </BreakdownEntry>
    </div>
  );
};

interface SalaryCalculatorBreakdownProps {
  country: CountryCode;
  data: {
    companyPayable?: CalculationBreakdownFragment;
    employeePayable?: CalculationBreakdownFragment;
  };
  frequency?: RateFrequency;
}

const SalaryCalculatorBreakdown: React.FC<SalaryCalculatorBreakdownProps> = ({
  data,
  country,
  frequency,
}) => (
  <div
    tw="flex flex-col rounded-base bg-background-white mb-none"
    data-testid="breakdown"
  >
    <div tw="flex flex-row mt-base">
      {data.companyPayable && (
        <BreakdownColumn
          type="company"
          tw="flex-1 mr-large"
          columnData={data.companyPayable}
          country={country}
          frequency={frequency}
        />
      )}
      {data.employeePayable && (
        <BreakdownColumn
          type="employee"
          tw="flex-1 ml-large"
          columnData={data.employeePayable}
          country={country}
          frequency={frequency}
        />
      )}
    </div>
  </div>
);

export default SalaryCalculatorBreakdown;
