import { useTranslation } from 'react-i18next';

import {
  errorNotification,
  successNotification,
} from 'app/services/notification-services';

import {
  Contract,
  useContractConfirmWetInkContractPrepareMutation,
} from '__generated__/graphql';

interface WetInkConfirmPreparingParams {
  contractId?: Contract['id'];
  closeContractModal?: () => void;
}

interface WetInkConfirmPreparingResult {
  loading: boolean;
  handleWetInkConfirmPreparing: () => Promise<void>;
}

const useWetInkConfirmPreparing = ({
  contractId,
  closeContractModal,
}: WetInkConfirmPreparingParams): WetInkConfirmPreparingResult => {
  const { t } = useTranslation('contract-onboarding.company');

  const [
    confirmWetinkContractPreparing,
    { loading },
  ] = useContractConfirmWetInkContractPrepareMutation({
    onError: () => {
      errorNotification(
        '',
        t(
          'onboarding-phase.wetink-confirm-preparing.failed',
          'Failed to send contract for wet ink signatures',
        ),
        false,
      );
    },
    onCompleted: () => {
      successNotification(
        '',
        t(
          'onboarding-phase.wetink-confirm-preparing.success',
          'Successfully sent contract for wet ink signatures',
        ),
        false,
      );
    },
  });

  const handleWetInkConfirmPreparing = async () => {
    if (!contractId) return;

    if (closeContractModal) {
      closeContractModal();
    }

    confirmWetinkContractPreparing({
      variables: {
        id: contractId,
      },
    });
  };

  return {
    loading,
    handleWetInkConfirmPreparing,
  };
};

export default useWetInkConfirmPreparing;
