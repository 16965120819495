import { addSeconds, parseISO } from 'date-fns';
import { TFunction } from 'i18next';

import {
  Document,
  DocumentReadable,
  UploadedFileFormat,
  UploadedFileFormatExpense,
  File as _File,
} from '../types';

export const fileIsDocumentReadable = (file: _File): file is DocumentReadable =>
  (file as DocumentReadable)?.__typename === 'DocumentReadable';

export const fileIsDocument = (
  file: Document | _File | null | undefined,
): file is Document => (file as Document)?.__typename === 'Document';

export const documentIsPDF = (document: Document): boolean =>
  Boolean(document.contentType && document.contentType === 'application/pdf');

export const openDocumentReadable = (document: DocumentReadable): void => {
  fetch(
    `data:${document.contentType ?? 'application/pdf'};base64,${document.blob}`,
  )
    .then((blobRes) => blobRes.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank', 'noopener,noreferrer');
    });
};

export const isUploadedFile = (
  file: UploadedFileFormat | File,
): file is UploadedFileFormat =>
  !!(!(file instanceof File) && (file as UploadedFileFormat)?.name);

export const isDocumentType = (
  file: Document | UploadedFileFormat | File,
): file is Document =>
  Boolean((file as Document)?.downloadUrl) &&
  (file as Document)?.__typename === 'Document';

export const isUploadedFileExpenses = (
  file: UploadedFileFormatExpense | File,
): file is UploadedFileFormatExpense =>
  !!(!(file instanceof File) && (file as UploadedFileFormatExpense)?.link);

export const getFileFromUploadedFile = (
  file: DocumentReadable,
): Promise<File> =>
  fetch(`data:${file.contentType ?? 'image/png'};base64,${file.blob}`)
    .then((blobRes) => blobRes.blob())
    .then(
      (blob) =>
        new File([blob], file.name ?? 'File', {
          type: file.contentType ?? 'image/png',
        }),
    );

export const getFileFromUploadedDocument = async (
  document: Document,
): Promise<File> => {
  if (!document.downloadUrl) return Promise.reject();

  return fetch(document.downloadUrl)
    .then((blobRes) => blobRes.blob())
    .then(
      (res) =>
        new File([res], document.name ?? 'File', {
          type: document.contentType ?? 'image/png',
        }),
    );
};

export const getFileFromFileLink = (file: UploadedFileFormat): Promise<File> =>
  fetch(file.link ?? '')
    .then((blobRes) => blobRes.blob())
    .then(
      (blob) =>
        new File([blob], file.name ?? 'File', {
          type: file.contentType ?? 'image/png',
        }),
    );

export const getFileFromUploadedFileExpenses = (
  file: UploadedFileFormat,
): Promise<File> =>
  fetch(file.link ?? '', {
    credentials: 'include',
  })
    .then((blobRes) => blobRes.blob())
    .then(
      (blob) =>
        new File([blob], file.name ?? 'File', {
          type: file.contentType ?? 'image/png',
        }),
    );

export const getImageDimensions = async ({ file }: { file: File }) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise<{
    width: number;
    height: number;
  }>((resolve, reject) => {
    reader.onload = (theFile) => {
      if (theFile.target) {
        const { result } = theFile.target;
        const image = new Image();
        image.src =
          typeof result === 'string'
            ? result
            : Buffer.from(result as ArrayBuffer).toString();
        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
      } else {
        reject();
      }
    };
  });
};

export const fileUploadErrorMapping = ({
  t,
  key,
  maxSize = 10 * 1024 * 1024,
  maxHeight = 500,
  maxWidth = 500,
  maxTotalSize = 10 * 1024 * 1024,
}: {
  t: TFunction<'common'>;
  key: string;
  maxSize?: number;
  maxHeight?: number;
  maxWidth?: number;
  maxTotalSize?: number;
}) => {
  const values: { [key: string]: string } = {
    'file-too-large': t('upload-files-file-too-large-error', {
      defaultValue: 'File size should be lower than {{maxSize}}mb.',
      replace: {
        maxSize: (maxSize / (1024 * 1024)).toFixed(0),
      },
    }),
    'file-invalid-type': t(
      'upload-files-invalid-file-type-error',
      'Unsupported File Type.',
    ),
    'already-accepted': t(
      'upload-files-file-already-accepted-error',
      'File Already Selected.',
    ),
    'max-size-exceeded': t('upload-files-total-too-large-error', {
      defaultValue: 'Total file size should be lower than {{maxTotalSize}}mb.',
      replace: {
        maxTotalSize: (maxTotalSize / (1024 * 1024)).toFixed(0),
      },
    }),
    'max-dimension-exceeded': t('upload-files-dimension-error', {
      defaultValue:
        'Image dimension should be within {{maxHeight}} x {{maxWidth}} pixels',
      replace: {
        maxHeight,
        maxWidth,
      },
    }),
  };

  return values[key];
};

export const shortenText = ({
  content,
  charLimit,
}: {
  content: string;
  charLimit: number;
}) =>
  content.substring(0, charLimit).replace(/[\s\n]+$/, '') +
  (charLimit >= content.length ? '' : '...');

export const isPreSignedUrlExpired = (url: string) => {
  try {
    const params = new URLSearchParams(url);
    const creationDate = parseISO(params.get('X-Amz-Date') ?? '');
    const expiresInSecs = Number(params.get('X-Amz-Expires') ?? '');

    const expiryDate = addSeconds(creationDate, expiresInSecs);
    return expiryDate < new Date();
  } catch {
    return false;
  }
};

export const downloadBlobAsPdf = (
  blob: string | undefined | null,
  fileName: string | undefined,
) => {
  const convertBase64ToBlobPdf = (blobStr: string) => {
    const binary = atob(blobStr.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(binary.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }
    return new Blob([view], { type: 'application/pdf' });
  };

  if (!blob) return;
  const blobObj = convertBase64ToBlobPdf(blob);

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blobObj);
  link.download = fileName ?? '';
  document.body.append(link);
  link.click();
  link.remove();
};
