/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from '@multiplier/common';
import { capitalize } from 'lodash';
import tw from 'twin.macro';

import { assureNumber } from 'app/utils/number';

import {
  ContractTimeOffEntitlement,
  InsightCategory,
  Maybe,
  TimeOffTypeDefinition,
  TimeOffUnit,
} from '__generated__/graphql';

import {
  CompliancePageInsightViewModel,
  CustomTimeOffInsightDefinition,
} from '../../../company/pages/definition-phase/pages/compliance';
import internalLeaveTypes from '../../../constants/internal-leave-types';
import { hasLeaveEntitlementsBesidesUnpaidType } from '../../../services/leave-entitlement';
import { periodLabels } from '../../compliance-entry';
import { LeaveEntitlementFormEntryValue } from '../index';

const RecommendationPill = React.lazy(
  () => import('../../recommendation-pill'),
);

export const GridContainer = tw.div`grid grid-template-columns[1fr 16px minmax(0,auto) minmax(0,auto)] gap-y-small gap-x-small self-start items-center`;

export const GridRow: React.FC<{
  label: string;
  value?: Maybe<number>;
  unit?: Maybe<TimeOffUnit>;
  insight?: CustomTimeOffInsightDefinition;
}> = ({ label, value, insight, unit }) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <>
      <span tw="text-ps text-text-secondary">{label}</span>
      <div />
      <div
        css={[
          tw`bg-background-primary rounded-tiny py-tiny px-small text-text-primary text-ps`,
          !isNewThemeApplied && tw`bg-primary bg-opacity-10`,
        ]}
      >
        {`${value} ${unit && value != null && periodLabels[unit](value)}`}
      </div>
      {insight ? (
        <RecommendationPill insight={insight} value={value} unit={unit} />
      ) : (
        <div />
      )}
    </>
  );
};

const LeaveEntitlementViewMode: React.FC<{
  assignedTimeOffEntitlements:
    | Maybe<Maybe<ContractTimeOffEntitlement>[]>
    | undefined;
  timeOffTypeToDefinitionMap: Record<string, TimeOffTypeDefinition>;
  insights?: CompliancePageInsightViewModel[InsightCategory.TIME_OFF];
}> = ({
  insights,
  timeOffTypeToDefinitionMap,
  assignedTimeOffEntitlements,
}) => {
  const { t } = useTranslation('contract-onboarding.company');

  const shouldBeVisible = useMemo(
    () => hasLeaveEntitlementsBesidesUnpaidType(assignedTimeOffEntitlements),
    [assignedTimeOffEntitlements],
  );

  const mandatoryFields = useWatch({ name: 'mandatory' });
  const customFields = useWatch({ name: 'custom' });

  const formLeaveEntitlements = useMemo<
    Record<string, LeaveEntitlementFormEntryValue>
  >(() => {
    if (mandatoryFields && customFields)
      return [...mandatoryFields, ...customFields].reduce(
        (prev, curr) => ({
          ...prev,
          [curr.key]: curr,
        }),
        {},
      );
    return {};
  }, [mandatoryFields, customFields]);

  if (!shouldBeVisible) return null;

  return (
    <GridContainer data-testid="view-mode">
      {mandatoryFields
        ?.filter(
          (field: LeaveEntitlementFormEntryValue) =>
            !internalLeaveTypes.includes(field.key),
        )
        ?.map((field: LeaveEntitlementFormEntryValue) => (
          <GridRow
            key={field.key}
            label={t(
              `compliance.leave-entitlement.mandatory-${
                timeOffTypeToDefinitionMap[field.key]?.label
              }`,
              capitalize(timeOffTypeToDefinitionMap[field.key]?.label ?? ''),
            )}
            value={
              field.defaultValue +
              assureNumber(formLeaveEntitlements[field.key]?.value)
            }
            unit={field.unit}
            insight={insights?.[field.key]}
          />
        ))}
      {customFields && customFields.length > 0 && (
        <span tw="text-ps text-text-primary col-span-4 mt-extra-small">
          {t('compliance.leave-entitlement.custom-header', 'Additional Leaves')}
        </span>
      )}
      {customFields?.map((field: LeaveEntitlementFormEntryValue) => (
        <GridRow
          key={field.key}
          label={capitalize(timeOffTypeToDefinitionMap[field.key]?.label ?? '')}
          value={field.value}
          unit={field.unit}
        />
      ))}
    </GridContainer>
  );
};

export default LeaveEntitlementViewMode;
