import {
  Experience,
  MemberNavGroups,
  ModuleConfig,
  WidgetPosition,
} from 'app/models/module-config';
import routes from 'team/member/routes';

const config: ModuleConfig = {
  id: 'member-personal-details',
  permission: 'team',
  folder: 'team/member/personal-details',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Personal details',
    route: `${routes.root}/${routes.personalDetails}`,
    sort: 1,
    home: false,
    group: MemberNavGroups.EMPLOYMENT,
  },
  widget: {
    sort: 1,
    list: [
      {
        show: true,
        file: 'personal-details-widget',
        position: WidgetPosition.LEFT,
      },
    ],
  },
  banner: {
    show: true,
    sort: 1,
  },
};

export default config;
