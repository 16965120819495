import React from 'react';

import tw, { TwStyle } from 'twin.macro';

import Icon, { IconProps } from '../icon';
import { CalloutVariant } from '../types';
import { getVariantStyles } from './config';

const CalloutIcon = ({
  name,
  variant,
  iconProps,
  iconStyle,
  isNewThemeApplied = false,
}: {
  name: string;
  variant: CalloutVariant;
  isNewThemeApplied?: boolean;
  iconProps?: IconProps;
  iconStyle?: TwStyle;
}) => (
  <Icon
    name={name}
    fill={getVariantStyles(variant, isNewThemeApplied).icon}
    css={[tw`min-w-[20px] h-[20px]`, iconStyle]}
    {...iconProps}
  />
);

const CalloutInfoIcon: React.FC<{
  variant: CalloutVariant;
  isNewThemeApplied?: boolean;
  iconProps?: IconProps;
  iconStyle?: TwStyle;
}> = ({ ...props }) => <CalloutIcon name="info" {...props} />;

CalloutIcon.Info = CalloutInfoIcon;

export default CalloutIcon;
