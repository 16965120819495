import { Experience, ModuleConfig, NavGroups } from 'app/models/module-config';
import { hideModuleBasedOnMemberLegalEntity } from 'app/utils/module-checks';

import routes from './routes';

const config: ModuleConfig = {
  id: 'contact-us',
  permission: 'contact-us',
  experience: Experience.MEMBER,
  folder: 'contact-us/member',
  nav: {
    defaultName: 'Contact us',
    route: routes.root,
    sort: 10,
    home: false,
    group: NavGroups.FOOTER,
    icon: {
      name: 'help',
    },
    activeIcon: {
      name: 'help-filled',
    },
    hide: hideModuleBasedOnMemberLegalEntity,
  },
};

export default config;
