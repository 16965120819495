import * as yup from 'yup';

import { DayOfWeek, WorkShiftInput } from '__generated__/graphql';

import { isValidTimeString } from './work-shift-utils';

const getWorkShiftSchema = (): yup.SchemaOf<WorkShiftInput> =>
  yup
    .object()
    .shape({
      startDay: yup.string().notRequired().oneOf(Object.values(DayOfWeek)),
      endDay: yup.string().notRequired().oneOf(Object.values(DayOfWeek)),
      workingHours: yup
        .object()
        .shape({
          startTime: yup.string().notRequired().test(isValidTimeString),
          endTime: yup.string().notRequired().test(isValidTimeString),
        })
        .nullable(true)
        .notRequired()
        .default(undefined),
    })
    .nullable(true)
    .notRequired()
    .default(undefined);

export default getWorkShiftSchema;
