/** @jsxImportSource @emotion/react */

import { WetInkOnboardingStatusHeaderDescription } from 'contract-onboarding/company/components/wet-ink-signature';
import { PendingOn } from 'contract-onboarding/components/pending-on-pill';
import OnboardingAction from 'contract-onboarding/models/onboarding-action';

import {
  ContractOnboardingStatusConfig,
  isWetInkSignatureEnabled,
} from '../status-config';

const contractPreparingConfirmation: ContractOnboardingStatusConfig = {
  title: (t) =>
    t(
      'onboarding-phase.contract-preparing-confirmation.title',
      'Contract preparing confirmation',
    ),
  description: (t, firstName, gender, country) =>
    isWetInkSignatureEnabled() ? (
      <WetInkOnboardingStatusHeaderDescription {...{ firstName, country }} />
    ) : (
      t('onboarding-phase.contract-preparing-confirmation.description', {
        defaultValue:
          'The contract for {{country}} has to be prepared by Multiplier team. Please click on "Confirm & Proceed" to initiate preparing contract.',
        replace: {
          country,
        },
        interpolation: { escapeValue: false },
      })
    ),
  statusLabel: (t) =>
    t('onboarding-phase.contract-preparing-confirmation.label', ''),
  pendingOn: () => PendingOn.YOU,
  pendingLabel: (t): string =>
    t(
      'onboarding-phase.contract-preparing-confirmation.status',
      'Pending on You',
    ),
  primaryAction: OnboardingAction.CONFIRM_CONTRACT,
  allowRevoke: () => false,
  allowDelete: () => true,
};

export default contractPreparingConfirmation;
