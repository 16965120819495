import { gql } from '@apollo/client';

export default gql`
  query GetCompliantCurrencies($country: CountryCode) {
    countries(country: $country) {
      code
      compliantCurrencies
    }
  }
`;
