import React, { useContext, useMemo } from 'react';

import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import tw, { theme } from 'twin.macro';

import Button from '../../../../../button';
import Card from '../../../../../card';
import Icon from '../../../../../icon';
import { ThemeContext } from '../../../../../theme-provider';
import ToolTip from '../../../../../tooltip';
import {
  TableFilterConfigType,
  TableFilterFormParams,
  TableFiltersProps,
} from '../../../../types';
import FilterKeyInput from '../filter-key-input';
import FilterValueInput from '../filter-value-input';

export interface FilterFormProps
  extends Pick<TableFiltersProps, 'submitBtnText' | 'disable'> {
  filteredFilters: TableFilterConfigType;
  loading: boolean;
  showAddNewFilter: boolean;
  fieldArray: UseFieldArrayReturn<TableFilterFormParams, 'filters', 'id'>; // workaround because of https://github.com/react-hook-form/react-hook-form/discussions/8229#discussioncomment-2611907
  onSubmit: (val: TableFilterFormParams) => void;
  handleFormResetToDefault: () => void;
}

const FilterForm: React.FC<FilterFormProps> = ({
  filteredFilters,
  loading,
  showAddNewFilter,
  fieldArray,
  onSubmit,
  handleFormResetToDefault,
  submitBtnText,
  disable,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('common.table');
  const methods = useFormContext<TableFilterFormParams>();

  const { fields, append, remove } = fieldArray;

  const primaryFilter = methods.watch('primaryFilter');

  const handleAddNewFilter = () => {
    append({
      key: '',
      value: '',
    });
  };

  const handleRemoveField = (index: number) => {
    remove(index);
  };

  const selectedKeys = methods.watch([
    ...(fields.map((_, index) => `filters.${index}.key`) as `filters.0.key`[]),
    'primaryFilter.key',
  ]);

  const nonSelectedFilters = useMemo(() => {
    if (!disable?.keyDuplication) return filteredFilters;

    const copiedFilters = { ...filteredFilters };
    selectedKeys
      .filter((item) => !!item)
      .forEach((key) => {
        delete copiedFilters[key]; // remove the selected filters from available filters
      });
    return copiedFilters;
  }, [selectedKeys, disable?.keyDuplication, filteredFilters]);

  const primaryFilters = useMemo(() => {
    // source for primary filter key
    const primaryKey = selectedKeys[selectedKeys.length - 1]; // always last item;
    return {
      ...(primaryKey && filteredFilters[primaryKey]
        ? { [primaryKey]: filteredFilters[primaryKey] }
        : {}),
      ...nonSelectedFilters,
    };
  }, [selectedKeys, nonSelectedFilters]);

  const isAllowAddMoreFilter = useMemo(() => {
    if (!disable?.keyDuplication) return showAddNewFilter;

    const nonSelectedFiltersLength = Object.keys(nonSelectedFilters).length;
    return (
      showAddNewFilter &&
      nonSelectedFiltersLength > 0 &&
      fields.length < Object.keys(filteredFilters).length
    ); // because duplication rule, then number of fields should be less than available filters
  }, [
    disable?.keyDuplication,
    showAddNewFilter,
    nonSelectedFilters,
    fields,
    filteredFilters,
  ]);

  const disabledSubmit = !methods?.formState?.isValid || disable?.form;

  return (
    <Card
      css={[
        tw`bg-background-primary w-full mb-large`,
        disable?.form && tw`opacity-50 pointer-events-none`,
      ]}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {primaryFilter && (
          <div
            tw="flex flex-row items-end gap-x-base mt-small mx-base mb-base"
            data-testid="primary-filter"
          >
            <FilterKeyInput
              filterKey={methods.watch('primaryFilter.key')}
              keyFieldName="primaryFilter.key"
              valueFieldName="primaryFilter.value"
              filters={primaryFilters}
              isPrimary
            />

            <FilterValueInput
              filterKey={methods.watch('primaryFilter.key')}
              fieldName="primaryFilter.value"
              filters={primaryFilters}
            />

            {/* fix for button alignment issue  */}
            <div tw="w-[46px]" />
          </div>
        )}
        {fields.map((field, index) => {
          const fieldKey = selectedKeys?.[index];
          const availableFilters = {
            ...(fieldKey && filteredFilters[fieldKey]
              ? { [fieldKey]: filteredFilters[fieldKey] }
              : {}),
            ...nonSelectedFilters,
          };
          return (
            <div
              key={field.id}
              tw="flex flex-row items-end gap-x-base mt-small mx-base mb-base"
              data-testid={fieldKey}
            >
              <FilterKeyInput
                filterKey={fieldKey}
                keyFieldName={`filters.${index}.key`}
                valueFieldName={`filters.${index}.value`}
                filters={availableFilters}
              />

              <FilterValueInput
                filterKey={methods.watch(`filters.${index}.key`)}
                fieldName={`filters.${index}.value`}
                filters={availableFilters}
              />
              {showAddNewFilter && ( // keep current numbers of filter: no add and no delete
                <Button
                  variant="outline"
                  size="medium"
                  tw="py-extra-small px-small"
                  data-testid="table-filter-remove-filter"
                  onClick={() => {
                    if (fields.length === 1 && !disable?.initialSubmission) {
                      handleFormResetToDefault();
                    } else {
                      handleRemoveField(index);
                    }
                  }}
                >
                  <Icon
                    name="trash-empty"
                    fill={isNewThemeApplied ? undefined : 'none'}
                    stroke={
                      isNewThemeApplied ? undefined : theme`colors.primary`
                    }
                    tw="w-large h-large min-w-large"
                  />
                </Button>
              )}
            </div>
          );
        })}
        {isAllowAddMoreFilter && (
          <div tw="mx-base">
            <Button
              css={[
                tw`text-ps font-semibold px-none bg-transparent shadow-none text-text-primary hover:bg-transparent mb-base`,
                !isNewThemeApplied && tw`text-primary`,
              ]}
              onClick={handleAddNewFilter}
              data-testid="table-filter-add-new-filter"
            >
              {t('filters.add-filter', '+ Add New Filter')}
            </Button>
          </div>
        )}

        <div tw="flex justify-center bg-background-secondary rounded-b-base">
          <ToolTip
            variant="top"
            content={
              !methods?.formState?.isValid
                ? t(
                    'filters.invalid-submit.tooltip',
                    `Some of the filter(s) don’t have value yet`,
                  )
                : null
            }
          >
            <Button
              tw="m-small"
              variant="primary"
              size="medium"
              disabled={disabledSubmit}
              type="submit"
              loading={loading}
            >
              {submitBtnText || t('filters.apply-filter', 'Apply Filter')}
            </Button>
          </ToolTip>
        </div>
      </form>
    </Card>
  );
};

export default FilterForm;
