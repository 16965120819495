/** @jsxImportSource @emotion/react */
import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from 'common/components/text-input';

import { BasicDetailsFormValues } from '../basic-details';

const WorkEmailInput: React.FC = () => {
  const { t } = useTranslation('contract-onboarding.common');

  const {
    register,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="work-email" tw="flex items-center">
        {t(
          'definition-phase.basic-details.work-email.label',
          'Work email (optional)',
        )}
      </TextInput.Label>
      <TextInput
        id="work-email"
        placeholder={t(
          'definition-phase.basic-details.work-email.placeholder',
          'john@gmail.com',
        )}
        {...register('workEmail')}
        error={!!errors.workEmail}
        autoComplete="off"
      />
      {errors.workEmail && (
        <TextInput.Error data-testid="workEmail-error">
          {errors.workEmail.message}
        </TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default WorkEmailInput;
