import { gql } from '@apollo/client';

export default gql`
  query GetProcurementsForContract($contractId: ID!) {
    contract(id: $contractId) {
      id
      equipmentProcurements {
        id
        companyId
        contractId
        totalAmount
        currencyCode
        requestedOn
        completedOn
        status
        detail
        items {
          id
          productId
          name
          type
          description
          amount
          currencyCode
        }
      }
    }
  }
`;
