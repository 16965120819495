import React, { useContext } from 'react';

import { format, isValid } from 'date-fns';
import tw, { TwStyle, styled } from 'twin.macro';

import DocumentPreviewModal from '../document-preview-modal';
import { useModal } from '../hooks';
import Icon from '../icon';
import IconButton from '../icon-button';
import { ThemeContext } from '../theme-provider';
import ToolTip from '../tooltip';
import { documentIsPDF, downloadBlobAsPdf } from '../utils';

const Button = styled.button<{ minimal: boolean; isNewTheme: boolean }>(
  ({ minimal, isNewTheme }) => [
    tw`flex shrink-0 items-center relative bg-background-white text-text-secondary py-small px-extra-small`,
    tw`rounded-base border border-border-primary`,
    tw`focus:(outline-none ring-transparent) focus-visible:(border-border-high-contrast)`,
    minimal
      ? tw`flex-row w-[150px] h-[30px]`
      : tw`flex-col w-[158px] h-[158px] justify-center mr-base`,
    !isNewTheme && tw`px-6 focus-visible:(outline-primary) bg-grey05`,
  ],
);

const Label = styled.p<{ minimal: boolean; isNewTheme: boolean }>(
  ({ minimal, isNewTheme }) => [
    tw` truncate text-text-primary`,
    minimal
      ? tw`w-[80%] text-pxs ml-tiny text-left`
      : tw`max-w-[120px] text-ps mt-base`,
    !isNewTheme && tw`text-grey01`,
  ],
);

interface UploadedDocumentViewerProps {
  label?: string | null;
  fileName?: string | null;
  link?: string | null;
  date?: Date | null;
  blob?: string | null;
  loading?: boolean;
  minimal?: boolean;
  allowPreview?: boolean;
  v2?: boolean;
  contentType?: string | null;
  styles?: TwStyle;
}

const UploadedDocumentViewerV2: React.FC<UploadedDocumentViewerProps> = ({
  label = 'Document',
  fileName,
  link,
  blob,
  loading,
  allowPreview = true,
  contentType,
  minimal = false,
  styles,
}) => {
  const [showPreviewModal, closePreviewModal, openPreviewModal] = useModal();
  const isPDF = documentIsPDF({ contentType });
  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <>
      <div
        css={[
          tw`w-full rounded p-large flex items-center gap-small h-[96px] bg-background-primary`,
          minimal && tw`h-auto bg-transparent p-none`,
          styles,
        ]}
      >
        {!minimal && (
          <>
            {loading ? (
              <Icon
                tw="absolute animate-spin m-auto left-none right-none top-none bottom-none"
                fill="transparent"
                name="spinner"
              />
            ) : (
              <Icon
                css={tw`h-48 w-48 shrink-0`}
                name={isPDF ? 'pdf-file' : 'image-file'}
              />
            )}
          </>
        )}

        <div tw="flex flex-col gap-tiny">
          {!minimal && (
            <p
              css={[
                tw`text-p font-normal text-text-primary`,
                !isNewThemeApplied && tw`text-background font-semibold`,
              ]}
            >
              {label}
            </p>
          )}
          <p
            css={[
              tw`text-pxs whitespace-normal text-text-secondary break-all`,
              minimal && tw`text-ps text-text-primary`,
              !isNewThemeApplied && tw`text-grey01`,
              minimal && !isNewThemeApplied && tw`text-background`,
            ]}
          >
            {fileName}
          </p>
        </div>
        <div tw="ml-auto flex items-center gap-small">
          {allowPreview && (
            <IconButton
              data-testid="preview-btn"
              name="eye-outline"
              size="small"
              variant="filled"
              onClick={openPreviewModal}
            />
          )}
          <IconButton
            data-testid="download-btn"
            name="download"
            size="small"
            variant="filled"
            onClick={() => {
              if (link) {
                window.open(link, '_blank');
              } else if (blob) {
                downloadBlobAsPdf(blob, label ?? '');
              }
            }}
          />
        </div>
      </div>
      <DocumentPreviewModal
        open={showPreviewModal}
        onClose={closePreviewModal}
        title={`Preview of ${label}`}
        loading={loading}
        document={{
          name: fileName,
          blob,
          link,
          contentType,
        }}
      />
    </>
  );
};

const UploadedDocumentViewerV1: React.FC<UploadedDocumentViewerProps> = ({
  label,
  link,
  date,
  blob,
  loading,
  minimal = false,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return (
    <div>
      <ToolTip variant="bottom" content={label}>
        <Button
          isNewTheme={isNewThemeApplied}
          minimal={minimal}
          type="button"
          data-testid="document"
          onClick={() => {
            if (link) {
              window.open(link, '_blank');
            } else if (blob) {
              downloadBlobAsPdf(blob, label ?? '');
            }
          }}
        >
          {loading ? (
            <Icon
              tw="absolute animate-spin m-auto left-none right-none top-none bottom-none"
              fill="transparent"
              name="spinner"
            />
          ) : (
            <Icon
              css={minimal ? tw`h-extra-large w-[20%]` : tw`h-64 w-64`}
              name="pdf-file"
            />
          )}
          <Label isNewTheme={isNewThemeApplied} minimal={minimal}>
            {label}
          </Label>
          {date && !minimal && (
            <div
              css={[
                tw`absolute bottom-[-12px] h-large bg-background-white px-base`,
                tw`border border-border-secondary rounded-large`,
                tw`text-text-primary font-normal text-pxs`,
                !isNewThemeApplied && tw`border-grey05 text-primary`,
              ]}
            >
              <p>
                {isValid(new Date(date))
                  ? format(new Date(date), 'MMMM yyyy')
                  : ''}
              </p>
            </div>
          )}
        </Button>
      </ToolTip>
    </div>
  );
};

const UploadedDocumentViewer: React.FC<UploadedDocumentViewerProps> = ({
  v2 = false,
  ...props
}) =>
  v2 ? (
    <UploadedDocumentViewerV2 {...props} />
  ) : (
    <UploadedDocumentViewerV1 {...props} />
  );
export default UploadedDocumentViewer;
