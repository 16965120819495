import React from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { isValid as isValidDate } from 'date-fns';

import DatePicker from '../../../../../date-picker';
import { TableFilterFormDateValue } from '../../../../types';
import Label from '../label';

const DateRangeFilterInput: React.FC<{
  value: TableFilterFormDateValue;
  onChange: (val: TableFilterFormDateValue) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation('common.table');

  return (
    <div tw="grid grid-cols-2 gap-small">
      <div>
        <Label>{t('filters.startDate', 'From:')}</Label>
        <DatePicker
          tw="mt-extra-small"
          data-testid="start-date"
          value={value?.startDate}
          max={value?.endDate ?? undefined}
          onChange={(dateString) => {
            const date = new Date(dateString);
            if (isValidDate(date)) {
              const start = new Date(date.setUTCHours(0, 0, 0, 0));
              onChange({
                startDate: start.toISOString(),
                endDate: value?.endDate,
              });
            } else {
              onChange({
                startDate: date,
                endDate: value?.endDate,
              });
            }
          }}
          loading={false}
          placeholder="DD MM YYYY"
        />
      </div>
      <div>
        <Label>{t('filters.endDate', 'To:')}</Label>
        <DatePicker
          tw="mt-extra-small"
          data-testid="end-date"
          value={value?.endDate}
          min={value?.startDate ?? undefined}
          onChange={(dateString) => {
            const date = new Date(dateString);
            if (isValidDate(date)) {
              const end = new Date(date.setUTCHours(0, 0, 0, 0));
              onChange({
                startDate: value?.startDate,
                endDate: end.toISOString(),
              });
            } else {
              onChange({
                startDate: value?.startDate,
                endDate: date,
              });
            }
          }}
          loading={false}
          placeholder="DD MM YYYY"
        />
      </div>
    </div>
  );
};

export default DateRangeFilterInput;
