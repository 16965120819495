/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';

import tw from 'twin.macro';

export const DependantContainer = tw.div`mb-16`;
export const DependantTitle = tw.p`text-p font-semibold text-primaryBlue800`;
export const DependantDescription = tw.p`text-ps text-text-secondary font-normal`;

const DependantHeader: React.FC<{ edit?: boolean }> = () => {
  const { t } = useTranslation('contract-onboarding.member');

  return (
    <DependantContainer data-testid="dependant-header">
      <DependantTitle css={tw`text-h6 text-slateGrey900 font-semibold`}>
        {t(
          'onboarding-phase.insurance.dependant-details.title',
          'Add Dependent details',
        )}
      </DependantTitle>
      <DependantDescription>
        {t(
          'onboarding-phase.insurance.dependant-details.review-edit-sub-title',
          'These details about your dependents (spouse / children / parents) will be verified post your joining date.',
        )}
      </DependantDescription>
    </DependantContainer>
  );
};

export default Object.assign(DependantHeader, {
  DependantContainer,
  DependantTitle,
  DependantDescription,
});
