import {
  Experience,
  ModuleConfig,
  NavGroups,
  WidgetPosition,
} from 'app/models/module-config';
import {
  showModuleBasedOnContractStatusAndContractStarted,
  showModuleBasedOnContractType,
} from 'app/utils/module-checks';
import routes from 'payroll/member/routes';

import { ContractStatus, ContractType } from '__generated__/graphql';

const config: ModuleConfig = {
  id: 'pay-statement',
  permission: 'payroll',
  folder: 'payroll/member',
  experience: Experience.MEMBER,
  nav: {
    defaultName: 'Pay statement',
    route: routes.root,
    sort: 4,
    home: false,
    icon: { name: 'monetization' },
    activeIcon: { name: 'monetization-filled' },
    group: NavGroups.MAIN,
  },
  widget: {
    sort: 1,
    list: [
      {
        show: true,
        file: 'payroll-widget',
        position: WidgetPosition.RIGHT,
      },
      {
        show: true,
        file: 'payslips-widget',
        position: WidgetPosition.LEFT,
      },
    ],
  },
  featureFlag: 'member-payroll-module',
  featureFlagDefaultValue: false,
  enabled: async () => {
    const shouldShow = await Promise.all([
      showModuleBasedOnContractType([
        ContractType.HR_MEMBER,
        ContractType.EMPLOYEE,
      ]),
      showModuleBasedOnContractStatusAndContractStarted([
        ContractStatus.ACTIVE,
        ContractStatus.ONBOARDING,
        ContractStatus.OFFBOARDING,
        ContractStatus.ENDED,
      ]),
    ]);

    return shouldShow.every((show) => show);
  },
};

export default config;
