/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { PillTag, PillVariant, ToolTip } from '@multiplier/common';

import {
  Contract,
  ContractOnboardingStatus,
  ContractStatus,
} from '__generated__/graphql';

interface MemberStatusDetails {
  label: string;
  variant: PillVariant;
  description: string;
}

const MemberStatus: React.FC<{
  status?: Contract['status'];
  onboardingStatus?: Contract['onboarding'];
  contractStarted?: Contract['contractStarted'];
  contractBlocked?: Contract['contractBlocked'];
}> = ({ onboardingStatus, status, contractStarted, contractBlocked }) => {
  const { t } = useTranslation('team.company');

  const statusDetails = useMemo(() => {
    const statusLabels: {
      [key: string]: MemberStatusDetails;
    } = {
      [ContractOnboardingStatus.DRAFT]: {
        label: t('member-status.draft.label', 'Draft'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.draft.description',
          'Continue the onboarding process',
        ),
      },
      [ContractOnboardingStatus.CREATED]: {
        label: t('member-status.created.label', 'Created'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.created.description',
          'An invitation has been sent to your employee to complete the onboarding process. Status will change when they start the onboarding',
        ),
      },
      [ContractOnboardingStatus.CREATED_CUSTOM]: {
        label: t('member-status.created-custom.label', 'Created (Custom)'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.created-custom.description',
          'Onboarding a member with a custom contract',
        ),
      },
      [ContractOnboardingStatus.REVOKED]: {
        label: t('member-status.revoked.label', 'Revoked'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.revoked.description',
          'The contract has been revoked.',
        ),
      },
      [ContractOnboardingStatus.CONTRACT_UPDATING]: {
        label: t('member-status.contract-updating.label', 'Contract Updating'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.contract-updating.description',
          'Multiplier is preparing the contract on your behalf',
        ),
      },
      [ContractOnboardingStatus.SIGNATURE_EMPLOYER_SENT]: {
        label: t(
          'member-status.signature-employer-sent.label',
          'Sent Contract to Employer',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.signature-employer-sent.description',
          'Sent Contract to Employer',
        ),
      },
      [ContractOnboardingStatus.SIGNATURE_EMPLOYER_SIGNED]: {
        label: t(
          'member-status.signature-employer-signed.label',
          'Employer Signed Contract',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.signature-employer-signed.description',
          'Employer Signed Contract',
        ),
      },
      [ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SENT]: {
        label: t(
          'member-status.signature-employee-sent.label',
          'Sent Contract to Member',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.signature-employee-sent.description',
          'Sent Contract to Member',
        ),
      },
      [ContractOnboardingStatus.SIGNATURE_EMPLOYEE_SIGNED]: {
        label: t(
          'member-status.signature-employee-signed.label',
          'Employee Signed Contract',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.signature-employee-signed.description',
          'Employee Signed Contract',
        ),
      },
      [ContractOnboardingStatus.CONTRACT_PREPARING]: {
        label: t(
          'member-status.contract-preparing.label',
          'Preparing Contract',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.contract-preparing.description',
          'Preparing Contract',
        ),
      },
      [ContractOnboardingStatus.CONTRACT_PREPARING_CONFIRMATION]: {
        label: t(
          'member-status.contract-preparing-confirmation.label',
          'Contract Preparing Confirmation',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.contract-preparing-confirmation.description',
          'Need confirmation to prepare the contract.',
        ),
      },
      [ContractOnboardingStatus.MEMBER_INVITED]: {
        label: t('member-status.member-invited.label', 'Member Invited'),
        variant: 'PROCESSING',
        description: t(
          'member-status.member-invited.description',
          'An invitation has been sent to your employee to complete the onboarding process. Status will change when they start the onboarding',
        ),
      },
      [ContractOnboardingStatus.MEMBER_STARTED]: {
        label: t('member-status.member-started.label', 'Member Started'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.member-started.description',
          'Member has started',
        ),
      },
      [ContractOnboardingStatus.MEMBER_DATA_ADDED]: {
        label: t('member-status.member-data-added.label', 'Member Data Added'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.member-data-added.description',
          'Member has added data',
        ),
      },
      [ContractOnboardingStatus.MEMBER_DATA_REVIEWED]: {
        label: t(
          'member-status.member-data-reviewed.label',
          'Member Data Reviewed',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.member-data-reviewed.description',
          'Member has reviewed their data',
        ),
      },
      [ContractOnboardingStatus.MEMBER_COMPLETED]: {
        label: t('member-status.member-completed.label', 'Member Completed'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.member-completed.description',
          'Member has completed the onboarding process',
        ),
      },
      [ContractOnboardingStatus.MEMBER_VERIFICATION_IN_PROGRESS]: {
        label: t(
          'member-status.member-verification-in-progress.label',
          'Verification In Progress',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.member-verification-in-progress.description',
          'Verification is in progress',
        ),
      },
      [ContractOnboardingStatus.MEMBER_VERIFICATION_COMPLETED]: {
        label: t(
          'member-status.member-verification-completed.label',
          'Verification Done',
        ),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.member-verification-completed.description',
          'The verification process has completed',
        ),
      },
      [ContractOnboardingStatus.MSA_SIGNING_PENDING]: {
        label: t('member-status.msa-signing-pending.label', 'MSA Not Sent'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.msa-signing-pending.description',
          'MSA Not Sent',
        ),
      },
      [ContractOnboardingStatus.MSA_SIGNING_IN_PROGRESS]: {
        label: t('member-status.msa-signing-in-progress.label', 'MSA Sent'),
        variant: 'PREPROCESSING',
        description: t(
          'member-status.msa-signing-in-progress.description',
          'MSA Sent',
        ),
      },
      [ContractOnboardingStatus.ACTIVE]: {
        label: t('member-status.active.label', 'Active'),
        variant: 'PREPROCESSING',
        description: t('member-status.active.description', 'Active'),
      },
    };

    const contractBlockedStatus: MemberStatusDetails = {
      label: t('member-status.blocked.label', 'Blocked'),
      variant: 'FAIL',
      description: t(
        'member-status.blocked.description',
        'Contract is blocked',
      ),
    };

    const notStartedStatus: MemberStatusDetails = {
      label: t('member-status.not-started.label', 'Not Started'),
      variant: 'PREPROCESSING',
      description: t(
        'member-status.not-started.description',
        'Waiting for the start date',
      ),
    };

    const defaultStatus: MemberStatusDetails = {
      label: 'N/A',
      variant: 'PREPROCESSING',
      description: t(
        'member-status.na.description',
        'Member is not in a serviceable state, please check with support for more details',
      ),
    };

    if (contractBlocked) return contractBlockedStatus;

    return onboardingStatus?.status
      ? statusLabels[onboardingStatus.status]
      : status === ContractStatus.ACTIVE && !contractStarted
      ? notStartedStatus
      : defaultStatus;
  }, [onboardingStatus]);

  return (
    <div tw="flex flex-col gap-y-2">
      <ToolTip variant="bottom" content={statusDetails?.description}>
        <PillTag
          tw="max-w-[180px] truncate"
          label={statusDetails?.label}
          variant={statusDetails?.variant}
        />
      </ToolTip>
    </div>
  );
};

export default MemberStatus;
