/** @jsxImportSource @emotion/react */
import React from 'react';

import 'twin.macro';

import { UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from 'common/components/text-input';

import {
  ContractType,
  CountryCode,
  DataFieldDefinition,
} from '__generated__/graphql';

import { MemberBasicDetailsFormValues } from '..';

interface AddressInputProps {
  methods: UseFormReturn<MemberBasicDetailsFormValues>;
  requirementMap?: { [key: string]: DataFieldDefinition };
  contractType?: ContractType | null;
}

const CityZipCodeInput: React.FC<AddressInputProps> = ({
  methods,
  requirementMap,
  contractType,
}) => {
  const { t } = useTranslation('contract-onboarding.member');

  const {
    control,
    register,
    formState: { errors, defaultValues },
  } = methods;

  const [selectedCountry] = useWatch({
    control,
    name: ['country'],
  });

  const isContractor = contractType === ContractType.CONTRACTOR;

  return (
    <div tw="grid grid-cols-2 gap-x-base gap-y-large">
      <TextInput.Container>
        <TextInput.Label htmlFor="city">
          {selectedCountry === CountryCode.CHN
            ? t('member.basic-details.city.label-china', 'Province')
            : t('member.basic-details.city.label', 'City')}
        </TextInput.Label>
        <TextInput
          id="city"
          placeholder={t('member.basic-details.city.placeholder', 'Orchard')}
          {...register('city')}
          disabled={
            (requirementMap?.['address.city']?.editable === false &&
              !!defaultValues?.city) ||
            isContractor
          }
          error={!!errors.city}
        />
      </TextInput.Container>
      <TextInput.Container>
        <TextInput.Label htmlFor="postal-code">
          {t('member.basic-details.postal-code.label', 'Postal Code/ Zip Code')}
        </TextInput.Label>
        <TextInput
          id="postal-code"
          placeholder={t(
            'member.basic-details.postal-code.placeholder',
            '158762',
          )}
          {...register('postalCode')}
          disabled={
            requirementMap?.['address.postalCode']?.editable === false ||
            isContractor
          }
          error={!!errors.postalCode}
        />
      </TextInput.Container>
    </div>
  );
};

export default CityZipCodeInput;
