import { gql } from '@apollo/client';

export default gql`
  query GetCompanyStatus {
    company {
      id
      status
    }
  }
`;
