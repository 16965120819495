/** @jsxImportSource @emotion/react */
import React from 'react';

import { useParams } from 'react-router-dom';

import { useContract } from 'contract-onboarding/company/hooks';
import { OnboardingStepProps } from 'contract-onboarding/components/step-navigation-footer';

import BankDetailsView from './bank-details';

const BankDetailsContainer: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { id } = useParams<{ id?: string }>();
  const { contract } = useContract(id);

  const { id: contractId, member } = contract;

  return (
    <BankDetailsView
      currentStep={currentStep}
      onboardingSteps={onboardingSteps}
      contract={contract}
      member={member}
      contractId={contractId}
    />
  );
};

export default BankDetailsContainer;
