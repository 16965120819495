/** @jsxImportSource @emotion/react */
import React from 'react';

import { useParams } from 'react-router-dom';

import { useGetInsuranceBenefitAndCoverage } from '@multiplier/insurance';
import tw from 'twin.macro';

import Loader from 'common/components/loader';
import {
  useContract,
  useSubmitDefinitionBenefits,
} from 'contract-onboarding/company/hooks';
import { StepLayout } from 'contract-onboarding/components/layout';
import { OnboardingStepProps } from 'contract-onboarding/components/step-navigation-footer';

import {
  BenefitPartnerCountry,
  InsuranceCoverageGroup,
} from '__generated__/graphql';

import BenefitsViewFormV2, { BenefitFormValues } from './benefit-view-form';

const BenefitsViewV2: React.FC<OnboardingStepProps> = ({
  currentStep,
  onboardingSteps,
}) => {
  const { id } = useParams<{ id?: string }>();
  const {
    contract: {
      id: contractId,
      country,
      benefits,
      countryStateCode,
      type: contractType,
    },
  } = useContract(id);

  const {
    insuranceCoverageGroups,
    benefitPartnerCountries,
    benefitPartnerCountriesLoading,
    onLoadBenefitAndCoverage,
  } = useGetInsuranceBenefitAndCoverage();

  const { loading, onSubmit } = useSubmitDefinitionBenefits(contractId);

  const handleSubmit = async (values: BenefitFormValues) => {
    const erSplitApplicable = benefitPartnerCountries?.[0]?.erSplitApplicable;
    const processedFormvalues = {
      ...values,
      employerPayPercentage: erSplitApplicable
        ? values.employerPayPercentage
        : null,
    };
    await onSubmit(processedFormvalues);
  };

  if (!contractId) {
    return null;
  }

  return (
    <StepLayout data-testid="benefits-view">
      {benefitPartnerCountriesLoading && <Loader.Table />}
      <div
        css={[
          benefitPartnerCountriesLoading && tw`invisible`, // prevent unmounted components from flickering
        ]}
      >
        <BenefitsViewFormV2
          currentStep={currentStep}
          onboardingSteps={onboardingSteps}
          id={id}
          country={country}
          benefits={benefits}
          countryStateCode={countryStateCode}
          loading={loading}
          onSubmit={handleSubmit}
          contractType={contractType}
          employerPayPercentage={benefits?.[0]?.employerPayPercentage}
          onLoadData={onLoadBenefitAndCoverage}
          benefitPartnerCountries={
            (benefitPartnerCountries as BenefitPartnerCountry[]) ?? []
          }
          coverageGroups={
            (insuranceCoverageGroups as InsuranceCoverageGroup[]) ?? []
          }
        />
      </div>
    </StepLayout>
  );
};

export default BenefitsViewV2;
