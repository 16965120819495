import React, { useEffect, useState } from 'react';

import { useMedia } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { MobileToast, Toast } from '@multiplier/common';
import { theme } from 'twin.macro';

import { notificationVar } from '../vars';

const Notification: React.FC = () => {
  const notificationVarArray = useReactiveVar(notificationVar);
  const [listed, setListed] = useState(notificationVar);
  const isMobile = useMedia(`(max-width: ${theme`screens.mobile.max`})`);
  const [indexToDelete, setIndexToDelete] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (listed.length && notificationVarArray.length) {
        if (listed[indexToDelete].persistent)
          setIndexToDelete((prev) => prev + 1);
        else handleClose(listed[indexToDelete].id);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [listed, notificationVarArray, indexToDelete]);

  useEffect(() => {
    setListed([...notificationVarArray]);
  }, [notificationVarArray, indexToDelete]);

  const handleClose = (id: number) => {
    const listItemIndex = listed.findIndex((e) => e.id === id);
    const toastListItem = listed.findIndex((e) => e.id === id);
    if (listed[listItemIndex]?.persistent) setIndexToDelete((prev) => prev - 1);
    listed.splice(listItemIndex, 1);
    notificationVarArray.splice(toastListItem, 1);
    notificationVar([...listed]);
  };

  return (
    <div tw="fixed bottom-32 z-50 left-0 right-0 m-auto w-5/6 max-width[740px]">
      {notificationVarArray.map(
        (list) =>
          list.shown && (
            <div key={list.id} tw="pb-small" data-testid="toast">
              {isMobile ? (
                <MobileToast
                  header={list.title}
                  type={list.severity}
                  handleClose={() => handleClose(list.id)}
                  inline={list.inline}
                  close
                >
                  {list.message}
                </MobileToast>
              ) : (
                <Toast
                  header={list.title}
                  type={list.severity}
                  handleClose={() => handleClose(list.id)}
                  inline={list.inline}
                  close
                >
                  {list.message}
                </Toast>
              )}
            </div>
          ),
      )}
    </div>
  );
};

export default Notification;
