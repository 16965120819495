/** @jsxImportSource @emotion/react */
import React from 'react';

import { Icon } from '@multiplier/common';
import tw, { css, styled, theme } from 'twin.macro';

import { NavIconType } from 'app/models/module-config';

import { EntryProps } from '.';

export const CompanyTitleWrapper = tw.div`flex flex-col items-stretch gap-y-base px-base my-extra-small mb-base`;

export const CompanyMainBar = styled.div(
  ({ isHover }: { isHover?: boolean }) => [
    tw`flex flex-row items-center pt-base`,
    isHover && tw`hover:(cursor-pointer)`,
  ],
);

export const SectionEntryWrapper = styled.div(
  ({
    notificationCount,
    isNested,
    isNewThemeApplied,
    isActive = false,
  }: Pick<EntryProps, 'isNested' | 'notificationCount'> & {
    isNewThemeApplied: boolean;
    isActive?: boolean;
  }) => [
    tw`flex flex-row relative items-center py-extra-small rounded-tiny`,
    tw`text-[#0e0500] font-medium text-ps`, // force 0e0500 for current theme
    isNewThemeApplied && tw`text-text-primary`,
    isNewThemeApplied
      ? tw`hover:bg-background-brand-subtle`
      : tw`hover:bg-[#f6efe1]`, // force brand-subtle
    isNested ? tw`pl-46` : tw`pl-base`,
    isActive &&
      !isNewThemeApplied &&
      tw`bg-[#fbf6ee] text-[#0e0500] font-semibold`, // force brand-faded
    isActive &&
      isNewThemeApplied &&
      tw`text-text-brand-link bg-background-brand-faded font-semibold`,
    notificationCount && notificationCount > 0 && tw`pr-extra-small`,
    notificationCount &&
      notificationCount > 0 &&
      css`
        ::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${theme`colors.background-negative-faded`};
          opacity: 0;
          border-radius: 8px;
          z-index: -1; /* set the z-index to move the blink element behind the tab */
          animation-name: blink;
          animation-duration: 750ms;
          animation-iteration-count: 3;
          animation-timing-function: ease-in-out;
        }

        /* Define the animation for the blink element */
        @keyframes blink {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `,
  ],
);

export const NavIcon: React.FC<{
  icon: NavIconType;
}> = ({ icon, ...props }) =>
  typeof icon === 'function' ? (
    <Icon tw="w-20 h-20" {...icon()} {...props} />
  ) : (
    <Icon tw="w-20 h-20" {...icon} forceCustom {...props} />
  );
