/* eslint-disable no-return-assign */
/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { ThemeContext } from '@multiplier/common';
import { setDefaulActiveModule } from '@multiplier/instrumentation';
import tw from 'twin.macro';

import {
  ModuleConfig,
  NavItemName,
  SideNavGroupConfig,
} from 'app/models/module-config';
import Sidebar, { EntryProps } from 'common/components/sidebar';
import { logout } from 'login/services/jwt';

import { userVar } from '../../vars';
import ExtendedSidebar from '../extended-sidebar';
import { groupModules } from './utils';

interface AppSidebarProps {
  closeable: boolean;
  isMultipleCompanyExperience?: boolean;
  showDropdown?: boolean;
  handleClose?: () => void;
  toggleModal?: () => void;
}

export const AppSidebarNavItemName: React.FC<{
  name: NavItemName;
  configId: string;
}> = ({ name, configId }) => {
  const { t } = useTranslation(['app.company', 'app.member']);

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  if (typeof name === 'function') {
    const defaultName = name();
    return t(
      `app.${current}:sidebar.dynamic.${configId}.${defaultName}`,
      defaultName,
    );
  }
  return t(`app.${current}:sidebar.${configId}`, name);
};

export interface SidebarEntryProps extends Pick<EntryProps, 'onLinkActive'> {
  id: ModuleConfig['id'];
  nav: ModuleConfig['nav'];
  handleClick?: () => void;
}

const AppSidebar = React.forwardRef<HTMLDivElement, AppSidebarProps>(
  (
    {
      isMultipleCompanyExperience = false,
      showDropdown = false,
      handleClose,
      toggleModal,
    },
    ref,
  ) => {
    const location = useLocation();
    const {
      firstName,
      lastName,
      modules,
      experiences: { current },
      selectedCompany,
    } = useReactiveVar(userVar);
    const { isNewThemeApplied } = useContext(ThemeContext);

    const [groupedModules, setGroupedModules] = useState<
      Array<SideNavGroupConfig | ModuleConfig>
    >([]);
    const [expandedSection, setExpandedSection] = useState('');

    const { t } = useTranslation(['app.company', 'app.member']);

    const navigate = useNavigate();

    useDeepCompareEffect(() => {
      let isSubscribed = true;
      groupModules(current, modules).then((value) => {
        if (isSubscribed) {
          setGroupedModules(value);
        }
      });
      return () => (isSubscribed = false as never);
    }, [modules, current]);

    const trackActiveModule = (moduleId: string) => {
      /**
       * Set default active module
       */
      const activeModule = modules.find(
        (module: ModuleConfig) => module.id === moduleId,
      );
      if (activeModule) {
        setDefaulActiveModule(activeModule.id);
      }
    };

    const handleLogout = async () => {
      await logout();
    };

    const onClose = () => {
      handleClose?.();
      setExpandedSection('');
    };

    const renderMenuItem = (config: ModuleConfig, isNested = false) =>
      config.nav.custom ? (
        <config.nav.custom
          key={config.id}
          id={config.id}
          nav={config.nav}
          handleClick={onClose}
          onLinkActive={trackActiveModule}
        />
      ) : (
        <Sidebar.SectionEntry
          key={config.id}
          route={config.nav.route}
          id={config.id}
          icon={config.nav.icon}
          activeIcon={config.nav.activeIcon}
          handleClick={onClose}
          isNested={isNested}
          onLinkActive={trackActiveModule}
        >
          <AppSidebarNavItemName
            name={config.nav.defaultName}
            configId={config.id}
          />
        </Sidebar.SectionEntry>
      );

    return (
      <div
        data-testid="sidebar"
        css={[
          tw`flex flex-col flex-shrink-0 bg-background-white h-screen z-40 width[240px] position[relative]`,
          tw`mobile:(fixed bottom-[0px]) tablet:(fixed bottom-[0px]) mobile:width[75%]`,
          isNewThemeApplied &&
            !showDropdown &&
            tw`border-r border-border-primary`,
        ]}
        ref={ref}
      >
        <Sidebar.Header
          company={`${selectedCompany?.displayName}`}
          logo={selectedCompany?.companyLogo}
          closeable={false}
          onClick={onClose}
          toggleGroupDropdown={toggleModal}
          showDropdown={showDropdown}
          isMultipleCompanyExperience={isMultipleCompanyExperience}
        />
        {showDropdown && isMultipleCompanyExperience && <ExtendedSidebar />}
        <Sidebar.Body>
          {groupedModules
            .sort((a, b) => (a?.nav?.sort ?? 0) - (b?.nav?.sort ?? 0))
            .map((module) => {
              const childMenuItems =
                (module as SideNavGroupConfig)?.items ?? [];
              const isGroupedSection = childMenuItems.length > 0;
              const sectionHasActiveItem = childMenuItems.some((config) => {
                const comparePath = `/${current}/${config.nav.route}`;
                return location.pathname.startsWith(comparePath);
              });

              const showMenuItems =
                sectionHasActiveItem ||
                (isGroupedSection && expandedSection === module.id);

              return (
                <Sidebar.Section key={module.id}>
                  {isGroupedSection ? (
                    <Sidebar.SectionEntry
                      key="header"
                      icon={module.nav.icon}
                      id={module.id}
                      handleClick={() => {
                        if (sectionHasActiveItem) return;
                        setExpandedSection(
                          expandedSection === module.id ? '' : module.id,
                        );
                      }}
                      styles={showMenuItems ? tw`mb-0` : undefined}
                    >
                      {t(
                        `app.${current}:sidebar-section.${module.id}`,
                        module.nav.defaultName,
                      )}
                    </Sidebar.SectionEntry>
                  ) : (
                    renderMenuItem(module as ModuleConfig, false)
                  )}
                  {showMenuItems && (
                    <div key="body">
                      {childMenuItems
                        .slice()
                        .sort((a, b) => a.nav.sort - b.nav.sort)
                        .map((config) => renderMenuItem(config, true))}
                    </div>
                  )}
                </Sidebar.Section>
              );
            })}
        </Sidebar.Body>
        <Sidebar.Footer
          key="footer"
          name={`${firstName} ${lastName}`}
          handleAccount={() => navigate(`/${current}/account-settings`)}
          handleLogout={handleLogout}
        />
      </div>
    );
  },
);

export default AppSidebar;
