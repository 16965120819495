import { Card } from '@multiplier/common';
import tw, { styled } from 'twin.macro';

export type CardVariant =
  | 'info'
  | 'warning'
  | 'danger'
  | 'silver'
  | 'bronze'
  | 'gold'
  | 'transparent';

export const CardWrapper = tw.div`grid grid-cols-2 mobile:flex mobile:flex-wrap mobile:m-base gap-base`;

export const DetailCard = styled(
  Card,
)(({ variant }: { variant: Omit<CardVariant, 'none' | 'transparent'> }) => [
  tw`border-t-4 p-large h-[max-content]`,
  variant === 'info' && tw`border-t-background-action`,
  variant === 'warning' && tw`border-t-background-warning`,
  variant === 'danger' && tw`border-t-background-negative`,
]);

export const CardTitleWrapper = tw.div`flex items-center mb-base`;

export const CardIcon = styled.div(({ variant }: { variant: CardVariant }) => [
  tw`rounded-base mr-small w-48 h-48 flex items-center justify-center`,
  variant === 'info' && tw`bg-background-secondary`,
  variant === 'warning' && tw`bg-background-warning-subtle`,
  variant === 'danger' && tw`bg-background-negative-subtle`,
  variant === 'silver' && tw`bg-background-brand-subtle`,
  variant === 'transparent' &&
    tw`bg-background-white border-border-secondary border-[1px]`,
  variant === 'bronze' && tw`bg-background-negative-subtle`,
  variant === 'gold' && tw`bg-background-warning-subtle`,
]);

export const CardTitle = tw.p`text-pl text-text-primary font-semibold`;

export const CardContentWrapper = tw.div`grid grid-cols-1 items-center justify-between gap-y-large mt-large`;

export const CardContent = tw.div`flex flex-col content-start flex-wrap items-start`;

export const CardContentTitle = tw.p`text-ps text-text-tertiary mb-tiny`;

export const CardSubTitle = tw.p`text-ps text-text-tertiary mb-extra-small`;

export const CardContentValue = tw.p`text-ps text-text-primary font-semibold`;

export const CardSecondaryTitle = tw.p`text-p text-text-primary font-semibold mb-tiny`;

export const CardDescription = tw.p`text-ps text-text-tertiary font-medium`;

export const InsuredCardWrapper = tw.div`grid grid-cols-1`;

export const InsuredCardDetailsWrapper = tw.div`border-border-primary grid grid-cols-1 border-[1px] rounded-base`;
