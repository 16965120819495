/** @jsxImportSource @emotion/react */
import React from 'react';

import { TableCell } from '@multiplier/common';
import 'twin.macro';

import countryLabels from 'common/constants/country-labels';

import { CountryCode, Maybe } from '__generated__/graphql';

const Country: React.FC<{
  country?: Maybe<CountryCode> | string;
  variant?: 'dark' | 'light';
  size?: 'medium' | 'large';
}> = ({ ...props }) => (
  <TableCell.Country countryLabels={countryLabels} {...props} />
);

export default {
  Country,
  Name: TableCell.Name,
  Amount: TableCell.Amount,
  Text: TableCell.Text,
};
