/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import { Icon } from '@multiplier/common';
import { unescape } from 'lodash';
import tw, { theme } from 'twin.macro';

import { Experience } from 'app/models/module-config';
import { userVar } from 'app/vars';

import {
  Contract,
  ContractType,
  Maybe,
  PayAmountType,
} from '__generated__/graphql';

import {
  PaymentType,
  PaymentTypeLabelsFeatureOn,
  excludedPayItemsForEmployee,
  excludedPayItemsFromDisclaimer,
} from '../additional-pay-form';

const DisclaimerBox = tw.span`rounded-base flex flex-row p-small text-ps text-text-primary bg-background-warning-faded gap-x-small`;
const BoldText = tw.span`font-semibold`;

const getPaymentName = (payType: PaymentType, name: string): string => {
  if (payType === PaymentType.OTHER) {
    return name;
  }
  return PaymentTypeLabelsFeatureOn[payType];
};

export const AdditionalPayDisclaimerSingle: React.FC<{
  payType: PaymentType;
  name: string;
  payAmountType?: Maybe<PayAmountType>;
  contractType?: Contract['type'];
}> = ({ payType, name, payAmountType, contractType }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  if (current === Experience.MEMBER) return null;

  if (isNotFreelancer && excludedPayItemsForEmployee.includes(payType))
    return null;
  if (!isNotFreelancer && excludedPayItemsFromDisclaimer.includes(payType))
    return null;

  let payTypeItemString = getPaymentName(payType, name);
  if (isNotFreelancer && payType === PaymentType.ALLOWANCES) {
    payTypeItemString = name;
  }

  const isAllowance =
    isNotFreelancer &&
    (payType === PaymentType.DE_MINIMIS_ALLOWANCE ||
      payType === PaymentType.INTERNET_ALLOWANCE ||
      payType === PaymentType.MOBILE_AND_PHONE_ALLOWANCE ||
      payType === PaymentType.ALLOWANCES);

  if (isAllowance && payAmountType === PayAmountType.FIXED_AMOUNT) {
    if (contractType === ContractType.CONTRACTOR) {
      return (
        <DisclaimerBox>
          <Icon tw="w-1/12" name="info" fill={theme`colors.icon-warning`} />
          <div>
            <Trans
              t={t}
              tOptions={{
                interpolation: { escapeValue: false },
              }}
              values={{
                payTypeItemString: unescape(payTypeItemString),
              }}
              i18nKey="additional-pay-disclaimer.allowances.fixed-contractor"
            >
              <p data-testid="fixed-payment-disclaimer">
                By adding this <BoldText>{{ payTypeItemString }}</BoldText> as
                an additional pay item, you <BoldText>agree </BoldText>that this
                amount will be paid out to your contractor periodically along
                with their salary.
              </p>
            </Trans>
          </div>
        </DisclaimerBox>
      );
    }
    return (
      <DisclaimerBox>
        <Icon tw="w-1/12" name="info" fill={theme`colors.icon-warning`} />
        <div>
          <Trans
            t={t}
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              payTypeItemString: unescape(payTypeItemString),
            }}
            i18nKey="additional-pay-disclaimer.allowances.fixed"
          >
            <p data-testid="fixed-payment-disclaimer">
              By adding this <BoldText>{{ payTypeItemString }}</BoldText> as an
              additional pay item, you <BoldText>agree </BoldText>that this
              amount will be paid out to your employee periodically along with
              their salary.
            </p>
          </Trans>
        </div>
      </DisclaimerBox>
    );
  }

  if (isAllowance && payAmountType === PayAmountType.VARIABLE_AMOUNT) {
    return (
      <DisclaimerBox>
        <Icon tw="w-1/12" name="info" fill={theme`colors.icon-warning`} />
        <div>
          <Trans
            t={t}
            i18nKey="additional-pay-disclaimer.allowances.variable"
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              payTypeItemString: unescape(payTypeItemString),
            }}
          >
            <p data-testid="variable-payment-disclaimer">
              By adding this <BoldText>{{ payTypeItemString }}</BoldText> as an
              additional pay item, you <BoldText>agree </BoldText>that this
              amount <BoldText>will not be paid</BoldText> out as part of the
              salary. Instead, will be paid out as pay supplements or expenses
              subject to successful submission and approval of the invoice.
              <br />
              <br />
              If you wish to have this automatically paid out, choose Fixed Pay
              type.
            </p>
          </Trans>
        </div>
      </DisclaimerBox>
    );
  }

  return (
    <DisclaimerBox>
      <Icon tw="w-1/12" name="info" fill={theme`colors.icon-warning`} />
      <div>
        {isNotFreelancer && contractType !== ContractType.CONTRACTOR ? (
          <Trans
            t={t}
            i18nKey="additional-pay-disclaimer.singular-clause-feature-on"
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              payTypeItemString: unescape(payTypeItemString),
            }}
          >
            <p data-testid="singular-clause">
              By adding this <BoldText>{{ payTypeItemString }}</BoldText> as an
              additional pay item, you <BoldText>agree to approve </BoldText>its
              payout via Pay Supplements{' '}
              <BoldText>before the payout month</BoldText>.
            </p>
          </Trans>
        ) : (
          <Trans t={t} i18nKey="additional-pay-disclaimer.singular-clause">
            <p data-testid="singular-clause">
              By adding this <BoldText>{{ payTypeItemString }}</BoldText> as an
              additional pay item, you <BoldText>agree to approve </BoldText>its
              payout via Pay Supplements{' '}
              <BoldText>before the payout month</BoldText>.
            </p>
          </Trans>
        )}
      </div>
    </DisclaimerBox>
  );
};

export const AdditionalPayDisclaimerFull: React.FC<{
  existingPays?: {
    payType: PaymentType;
    name: string;
    amountType?: Maybe<PayAmountType>;
  }[];
  amountType?: PayAmountType;
  contractType?: Contract['type'];
}> = ({ existingPays, amountType, contractType }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const isNotFreelancer = contractType !== ContractType.FREELANCER;

  const {
    experiences: { current },
  } = useReactiveVar(userVar);

  let existingPaysWithoutExcludes = existingPays?.filter(
    (p) => !excludedPayItemsFromDisclaimer.includes(p.payType),
  );

  if (isNotFreelancer) {
    existingPaysWithoutExcludes = existingPays
      ?.filter((item) => item.amountType === amountType)
      .filter((p) => !excludedPayItemsForEmployee.includes(p.payType));
  }

  const payTypeItemsString = existingPaysWithoutExcludes
    ?.map((p) => `${getPaymentName(p.payType, p.name)}, `)
    .join('');

  if (current === Experience.MEMBER) return null;

  if (existingPaysWithoutExcludes?.length === 0) return null;

  if (existingPaysWithoutExcludes?.length === 1 && !isNotFreelancer) {
    return (
      <AdditionalPayDisclaimerSingle
        payType={existingPaysWithoutExcludes[0].payType}
        name={existingPaysWithoutExcludes[0].name}
        contractType={contractType}
      />
    );
  }
  return (
    <DisclaimerBox>
      <Icon
        tw="w-1/12 flex-shrink-0"
        name="info"
        fill={theme`colors.icon-warning`}
      />
      <div>
        {isNotFreelancer ? (
          <>
            {amountType === PayAmountType.FIXED_AMOUNT && (
              <>
                {contractType === ContractType.CONTRACTOR ? (
                  <Trans
                    t={t}
                    i18nKey="additional-pay-disclaimer.full-clause-feature-on-fixed-contractor"
                  >
                    <p data-testid="full-clause-feature-on-fixed">
                      You <BoldText>agree </BoldText>that all pay items under
                      <BoldText> Automatic Payments </BoldText>will be paid out
                      to your contractor periodically, along with their salary.
                    </p>
                  </Trans>
                ) : (
                  <Trans
                    t={t}
                    i18nKey="additional-pay-disclaimer.full-clause-feature-on-fixed"
                  >
                    <p data-testid="full-clause-feature-on-fixed">
                      You <BoldText>agree </BoldText>that all pay items under
                      <BoldText> Automatic Payments </BoldText>will be paid out
                      to your employee periodically, along with their salary.
                    </p>
                  </Trans>
                )}
              </>
            )}
            {amountType === PayAmountType.VARIABLE_AMOUNT && (
              <Trans
                t={t}
                i18nKey="additional-pay-disclaimer.full-clause-feature-on-variable"
                tOptions={{
                  interpolation: { escapeValue: false },
                }}
                values={{
                  payTypeItemsString: unescape(payTypeItemsString),
                }}
              >
                <p data-testid="full-clause-feature-on-variable">
                  By adding <BoldText>{{ payTypeItemsString }}</BoldText> as
                  (an) additional pay item(s), you{' '}
                  <BoldText>agree to approve </BoldText>its/their payout via Pay
                  Supplements <BoldText>before the payout month</BoldText>.
                </p>
              </Trans>
            )}
          </>
        ) : (
          <Trans t={t} i18nKey="additional-pay-disclaimer.full-clause">
            <p data-testid="full-clause">
              By adding {{ payTypeItemsString }} as (an) additional pay item(s),
              you <BoldText>agree to approve </BoldText>its/their payout via Pay
              Supplements <BoldText>before the payout month</BoldText>.
            </p>
          </Trans>
        )}
      </div>
    </DisclaimerBox>
  );
};
