import { gql } from '@apollo/client';

import WORK_SHIFT_FRAGMENT from './fragments/work-shift';

export default gql`
  ${WORK_SHIFT_FRAGMENT}
  mutation CreateContract($input: ContractCreateInput!) {
    contractCreate(input: $input) {
      id
      member {
        id
        firstName
        lastName
        status
        maritalStatus
      }
      compensation {
        basePay {
          amount
          frequency
          currency
        }
      }
      type
      country
      workStatus
      countryStateCode
      alreadyHired
      term
      position
      startOn
      endOn
      endedOn
      offboardingNote
      scope
      status
      employeeId
      onboarding {
        id
        status
        steps
        current
        activationCutoff
        tasks {
          name
          order
          completed
          pendingOn {
            id
            persona
            firstName
            lastName
          }
        }
      }
      workShift {
        ...workShift
      }
      createdOn
      contractStarted
      contractBlocked
      lastWorkingDay
      offboarding {
        id
        type
        lastWorkingDay
        status
      }
    }
  }
`;
