/** @jsxImportSource @emotion/react */
import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { add, isAfter, isBefore, isSameDay } from 'date-fns';

import { ComplianceParamPeriodLimit, Contract } from '__generated__/graphql';

import { getAddDuration, isPresent } from './index';
import 'twin.macro';

const NoticeDuringProbation = ({
  notice,
}: {
  notice: ComplianceParamPeriodLimit | null;
}) => {
  const { t } = useTranslation('team.company');

  return (
    <>
      <div tw="text-18 text-background mb-6">
        {t(
          'offboard-member-dialog.notice.during-probation.title',
          'Notice During Probation',
        )}
      </div>
      <div tw="text-grey01 text-ps mb-32">
        {t('offboard-member-dialog.notice.during-probation.description', {
          defaultValue:
            'The Employment of the Employee may be terminated anytime during the probation period by giving {{ noticeDuration }} {{ noticeUnit }} of notice or payment in lieu of notice',
          replace: {
            noticeDuration: notice?.value,
            noticeUnit: notice?.unit?.toLowerCase(),
          },
        })}
      </div>
    </>
  );
};

const NoticeAfterProbation = ({
  notice,
}: {
  notice: ComplianceParamPeriodLimit | null;
}) => {
  const { t } = useTranslation('team.company');

  return (
    <>
      <div tw="text-18 text-background mb-6">
        {t(
          'offboard-member-dialog.notice.after-probation.title',
          'Notice Period',
        )}
      </div>
      <div tw="text-grey01 text-ps mb-32">
        {t('offboard-member-dialog.notice.after-probation.description', {
          defaultValue:
            'The Employment of the Employee may be terminated anytime after the probation period by giving {{ noticeDuration }} {{ noticeUnit }} of notice or payment in lieu of notice',
          replace: {
            noticeDuration: notice?.value,
            noticeUnit: notice?.unit?.toLowerCase(),
          },
        })}
      </div>
    </>
  );
};

interface NoticeInfoProps {
  startOn: Contract['startOn'];
  probationPolicy: ComplianceParamPeriodLimit | null;
  noticeAfterProbation: ComplianceParamPeriodLimit | null;
  noticeDuringProbation: ComplianceParamPeriodLimit | null;
}

const NoticeInfo = ({
  startOn,
  probationPolicy,
  noticeAfterProbation,
  noticeDuringProbation,
}: NoticeInfoProps): ReactElement | null => {
  const today = new Date();

  if (!isPresent(probationPolicy) && isPresent(noticeAfterProbation)) {
    return <NoticeAfterProbation notice={noticeAfterProbation} />;
  }

  if (isPresent(probationPolicy)) {
    const probationEndDate = add(
      startOn,
      getAddDuration(probationPolicy) ?? {},
    );

    if (
      (isBefore(today, probationEndDate) ||
        isSameDay(today, probationEndDate)) &&
      isPresent(noticeDuringProbation)
    )
      return <NoticeDuringProbation notice={noticeDuringProbation} />;

    if (isAfter(today, probationEndDate) && isPresent(noticeAfterProbation))
      return <NoticeAfterProbation notice={noticeAfterProbation} />;
  }

  return null;
};

export default NoticeInfo;
