/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { add, format, isValid, parseISO, startOfMonth } from 'date-fns';
import tw from 'twin.macro';
import * as yup from 'yup';

import Checkbox from 'common/components/checkbox';
import DatePicker from 'common/components/date-picker';
import Dialog from 'common/components/dialog';
import Textarea from 'common/components/textarea';
import Loader from 'team/company/components/dialog-loader';
import { useContractOffboard } from 'team/company/hooks';

import {
  Contract,
  ContractType,
  Maybe,
  useGetContractLazyQuery,
} from '__generated__/graphql';

export interface OffboardFreelancerDialogProps {
  show: boolean;
  onClose: () => void;
  contractId?: Maybe<string>;
}

const getFormSchema = (today: Date, endOn: Contract['endOn'] | null) => {
  if (endOn) {
    return yup.object().shape({
      endDate: yup
        .date()
        .min(startOfMonth(today))
        .max(endOn)
        .required()
        .typeError('Please provide a valid end date'),
      reason: yup.string().required(),
    });
  }

  return yup.object().shape({
    endDate: yup
      .date()
      .min(startOfMonth(today))
      .max(add(today, { years: 1 }))
      .required()
      .typeError('Please provide a valid end date'),
    reason: yup.string().required(),
  });
};

const OffboardFreelancerDialog: React.FC<OffboardFreelancerDialogProps> = ({
  show,
  onClose,
  contractId,
}) => {
  const { t } = useTranslation('team.company');
  const [consent, setConsent] = useState(false);
  const [
    getContract,
    { data, loading: contractLoading },
  ] = useGetContractLazyQuery();
  const { contractOffboardLoading, onSubmit } = useContractOffboard();

  const endOn = isValid(parseISO(data?.contract?.endOn))
    ? data?.contract?.endOn
    : null;

  const today = new Date();

  const schema = getFormSchema(today, endOn);

  const minDate = today;
  const maxDate = endOn ?? add(today, { years: 1 });

  useEffect(() => {
    if (!contractId || contractId === '') return;
    getContract({
      variables: {
        id: contractId,
      },
    });
  }, [contractId, getContract]);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    register,
    formState: { errors, isValid: isFormValid },
  } = useForm<{
    endDate: Date;
    reason: string;
  }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!endOn) return;
    reset({
      ...getValues(),
      endDate: endOn,
    });
  }, [endOn]);

  const handleConfirmClick = () => {
    if (!contractId || contractId === '') return;
    handleSubmit(({ endDate, reason }) => {
      onSubmit({
        id: contractId,
        lastWorkingDay: format(endDate, 'yyyy-MM-dd'),
        offboardingNote: reason ?? '',
      });
      onClose();
    })();
  };

  const title = endOn
    ? t('offboard-freelancer-dialog.has-end-date.title', 'Confirm End Date')
    : t('offboard-freelancer-dialog.no-end-date.title', 'Select End Date');

  const hasEndDateContent = (
    <div>
      <div tw="text-background text-18 mb-6">
        {t(
          'offboard-freelancer-dialog.has-end-date.header',
          'Contract End Date',
        )}
      </div>
      <div tw="text-grey01 text-ps mb-32">
        {t('offboard-freelancer-dialog.has-end-date.description', {
          defaultValue:
            'The end date mentioned in the contract is {{ endDate }}. Do you want to make changes to the contract end date?',
          replace: {
            endDate: endOn ? format(parseISO(endOn), 'dd/MM/yyyy') : '',
          },
          interpolation: { escapeValue: false },
        })}
      </div>
    </div>
  );

  const noEndDateContent = (
    <div>
      <div tw="text-background text-18 mb-6">
        {t(
          'offboard-freelancer-dialog.no-end-date.header',
          'Contract End Date?',
        )}
      </div>
      <div tw="text-grey01 text-ps mb-32">
        {t(
          'offboard-freelancer-dialog.no-end-date.description',
          'There is no end date mentioned in the contract. Select contract end date.',
        )}
      </div>
    </div>
  );

  if (contractLoading) return <Loader />;

  return (
    <Dialog
      open={show}
      disabled={!(isFormValid && consent)}
      onClose={onClose}
      onConfirm={handleConfirmClick}
      title={title}
      buttonText={t('offboard-freelancer-dialog.confirm', 'Continue')}
      loading={contractOffboardLoading}
      containerStyle={tw`overflow-visible`}
    >
      {endOn ? hasEndDateContent : noEndDateContent}
      <form>
        <div tw="text-background text-ps font-medium mb-12">
          {t('offboard-freelancer-dialog.form.last-working-day-input-label', {
            defaultValue: 'Select {{message}}',
            replace: {
              message:
                data?.contract?.type === ContractType.FREELANCER
                  ? "Freelancer's last working day"
                  : 'last working day',
            },
            interpolation: {
              escapeValue: false,
            },
          })}
        </div>
        <Controller
          control={control}
          name="endDate"
          render={({ field: { value, onChange } }) => (
            <DatePicker
              data-testid="endDate"
              tw="mb-32 z-50"
              value={value}
              onChange={onChange}
              min={minDate}
              max={maxDate}
              error={!!errors.endDate}
              helperText={errors.endDate?.message}
            />
          )}
        />
        <div tw="text-background text-ps mb-12">
          {t(
            'offboard-freelancer-dialog.form.reason',
            'Reason for Offboarding',
          )}
        </div>
        <Textarea
          data-testid="reason"
          rows={4}
          tw="flex w-full mb-40 resize-none"
          {...register('reason')}
        />
      </form>
      <div tw="flex mb-48">
        <Checkbox
          data-testid="consent"
          checkboxSize="large"
          tw="mr-12"
          defaultChecked={consent}
          onClick={() => setConsent(!consent)}
        />
        <div tw="text-ps text-background">
          {t(
            'offboard-freelancer-dialog.form.consent',
            'I agree that my employee consents their last working day on the above mentioned date',
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default OffboardFreelancerDialog;
