import { getAuthHeaders } from '@multiplier/user';
import * as Sentry from '@sentry/react';

import localStorageService from 'common/services/local-storage-service';
import importMetaEnv from 'import-meta-env';
import {
  forceLogout,
  invalidateToken,
  isJwtExpired,
  setSession,
} from 'login/services/jwt';

class Refresh {
  private refreshingPromise: Promise<void> | null;

  private token: string | null;

  constructor() {
    this.token = null;
    this.refreshingPromise = null;
  }

  private async clearTokenAndLogout() {
    this.token = null;
    await forceLogout();
  }

  private async refreshToken(oldToken: string): Promise<void> {
    return new Promise((resolve) => {
      try {
        return fetch(`${importMetaEnv.VITE_USER_SERVICE_URL}refresh-token`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            ...getAuthHeaders(oldToken),
            Platform: 'COMPANY_EXPERIENCE',
          },
        }).then((refreshResponse) => {
          invalidateToken();
          this.refreshingPromise = null;

          if (refreshResponse.status !== 200) {
            return this.clearTokenAndLogout().then(() => resolve());
          }

          return refreshResponse
            .json()
            .then((tokenData) => {
              if (tokenData.id_token) {
                setSession(tokenData.id_token);
                this.token = tokenData.id_token;
                return resolve();
              }
              return this.clearTokenAndLogout().then(resolve);
            })
            .catch(() => this.clearTokenAndLogout().then(resolve));
        });
      } catch (e) {
        Sentry.captureException(e);
        return this.clearTokenAndLogout().then(resolve);
      }
    });
  }

  private async refresh(oldToken: string) {
    if (!this.refreshingPromise) {
      this.refreshingPromise = this.refreshToken(oldToken);
    }

    return this.refreshingPromise;
  }

  public async getRefreshToken(): Promise<string | null> {
    const oldToken = localStorageService.getRawItem('jwt_token');
    this.token = oldToken;
    if (!oldToken) return null;

    if (isJwtExpired(oldToken)) {
      await this.refresh(oldToken);
      this.refreshingPromise = null;
    }

    return this.token;
  }
}

export default Refresh;

const refreshClient = new Refresh();

export const refreshAccessToken = async (): Promise<string | null> =>
  refreshClient.getRefreshToken();
