import growthBook, { AppFeature } from '@multiplier/growthbook';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import groupBy from 'lodash/groupBy';

import api from 'common/services/benefit-api';
import { CountryInsuranceResponse } from 'common/services/schema/benefit-service';

import {
  Benefit,
  BenefitDocument,
  BenefitType,
  ContractBenefitStatus,
  Maybe,
} from '__generated__/graphql';

import { BenefitTypeMap } from '../utils/utils';

export const getInsuranceForCountry = (
  country: string,
): Promise<AxiosResponse<CountryInsuranceResponse>> =>
  api.get(`benefit/group?country=${country}&type=INSURANCE`);

export const mapBenefitTypeWithOrder = (
  type: BenefitType,
): {
  type: BenefitType;
  sort: number;
} => {
  const benefitWithSort = {
    [BenefitType.FAMILY_INSURANCE]: 15,
    [BenefitType.INSURANCE]: 10,
  };
  return {
    type,
    sort: benefitWithSort[type] || 100,
  };
};

export const groupInsuranceByType = (
  benefits: Maybe<Benefit>[],
): Partial<Record<BenefitType, Maybe<Benefit>[]>> =>
  groupBy(benefits, (benefit) => benefit?.type);

export const getBenefitDocumentMap = (
  benefitDocuments: Maybe<Array<Maybe<BenefitDocument>>> | undefined,
): { [key: string]: BenefitDocument } =>
  flow(
    compact,
    keyBy<BenefitDocument>('benefitDocumentType'),
  )(benefitDocuments);

export const getInsuranceValidUntilDate = (
  endOn: string | null | undefined,
  policyStatus: string | null | undefined,
): string =>
  endOn && policyStatus !== BenefitTypeMap[ContractBenefitStatus.DEACTIVATED]
    ? format(new Date(endOn), 'dd MMMM yyyy')
    : 'NA';

export const getCompareFileUrl = (
  benefits: Maybe<Benefit>[],
  benefitType: string,
): string => {
  const matchingBenefitsForType = benefits.filter((benefit) =>
    growthBook.isOn(AppFeature.INSURANCE_EXPAND)
      ? benefit?.provider?.coverageGroup?.id === benefitType
      : benefit?.type === benefitType,
  );

  return matchingBenefitsForType[0]?.compareBenefits?.comparisonFileUrl ?? '';
};
