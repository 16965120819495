/* eslint-disable import/prefer-default-export */
import { notEmpty } from 'app/utils/array';

import { ContractBenefitDependent, Maybe } from '__generated__/graphql';

import {
  InsuranceDetailsPrams,
  InsuranceFormValues,
} from '../onboarding/pages/insurance-details';

const mapDependant = (
  dependant: ContractBenefitDependent,
): InsuranceFormValues => ({
  ...dependant,
  relationship: dependant.relationshipToMember,
  nationalIdNumber:
    dependant.legalData?.find((data) => data?.key === 'nationalIdNumber')
      ?.value ?? '',
  // identity: {...dependant.documents?.[0]?.documents?.[0], lastModified: 0, webkitRelativePath: '', arrayBuffer: }
});

const mapDependantToInsuranceFormValues = (
  dependants?: Maybe<Maybe<ContractBenefitDependent>[]>,
): InsuranceDetailsPrams => ({
  insuranceDetails: dependants
    ? dependants.filter(notEmpty).map(mapDependant)
    : [],
});

export { mapDependantToInsuranceFormValues };
