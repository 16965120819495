import { ReactElement, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '@multiplier/common';
import { handlers } from '@multiplier/notifications';
import { TFunction } from 'i18next';

import {
  Contract,
  ContractOffboarding,
  useContractOffboardingCancelMutation,
} from '../__generated__/graphql';
import useModal from './modal';

const { errorNotification, successNotification } = handlers;

interface CancelDialogReturnType {
  Dialog: () => ReactElement;
  cancelDialogOpen: boolean;
  cancelInProgress: boolean;
  handleCancelClick: (
    contractOffboardingId: ContractOffboarding['id'],
    contractId: Contract['id'],
  ) => void;
  handleCancelClose: () => void;
  handleCancelConfirm: () => void;
  contractOffboardingToCancel:
    | {
        contractOffboardingId: ContractOffboarding['id'];
        contractId: Contract['id'];
      }
    | undefined;
}

const sendErrorNotification = (t: TFunction<'contract-offboarding'>) => {
  errorNotification(
    '',
    t(
      'cancel-offboarding.notification.error',
      'Could not cancel offboarding for the member',
    ),
    false,
  );
};

export default (): CancelDialogReturnType => {
  const { t } = useTranslation('contract-offboarding');

  const [
    contractOffboardingToCancel,
    setContractOffboardingToCancel,
  ] = useState<{
    contractOffboardingId: ContractOffboarding['id'];
    contractId: Contract['id'];
  }>();
  const [showDialog, handleCloseDialog, handleOpenDialog] = useModal();

  const [onCancel, { loading }] = useContractOffboardingCancelMutation({
    onCompleted: () => {
      successNotification(
        '',
        t(
          'cancel-offboarding-member.notification.success',
          'Offboarding Cancelled Successfully, Employee moved to active.',
        ),
        false,
      );
    },
    onError: () => {
      errorNotification(
        '',
        t(
          'cancel-offboarding.notification.error',
          'Could not cancel offboarding for the member',
        ),
        false,
      );
    },
  });

  const handleCancelConfirm = async () => {
    if (!contractOffboardingToCancel) {
      errorNotification(
        '',
        t(
          'cancel-offboarding.notification.error',
          'Could not cancel offboarding for the member',
        ),
        false,
      );
      return;
    }

    const { contractOffboardingId, contractId } = contractOffboardingToCancel;

    if (!contractOffboardingId || !contractId) {
      sendErrorNotification(t);
      return;
    }

    await onCancel({
      variables: {
        input: {
          contractOffboardingId,
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            id: contractId,
            __typename: 'Contract',
          }),
          fields: {
            offboarding: () => ({
              ...data?.contractOffboardingCancelV2,
            }),
          },
        });
      },
    });

    setContractOffboardingToCancel(undefined);
    handleCloseDialog();
  };

  const handleCancelClick = (
    contractOffboardingId: ContractOffboarding['id'],
    contractId: Contract['id'],
  ) => {
    if (contractOffboardingId) {
      setContractOffboardingToCancel({ contractOffboardingId, contractId });
      handleOpenDialog();
    }
  };

  const handleCancelClose = () => {
    setContractOffboardingToCancel(undefined);
    handleCloseDialog();
  };

  const Dialog = () => (
    <ConfirmationDialog
      open={showDialog}
      onClose={handleCancelClose}
      onConfirm={handleCancelConfirm}
      icon="member-cross"
      title={t('main.cancel-offboarding.title', 'Cancel Offboarding?')}
      description={t(
        'main.cancel-offboarding.description',
        'are you sure you want to cancel offboarding for this employee?',
      )}
      buttonText={t('main.cancel-offboarding.continue', 'Yes, Cancel')}
      loading={loading}
    />
  );

  return {
    Dialog,
    cancelDialogOpen: showDialog,
    cancelInProgress: loading,
    handleCancelConfirm,
    handleCancelClick,
    handleCancelClose,
    contractOffboardingToCancel,
  };
};
