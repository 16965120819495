/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Card,
  Icon,
  ThemeContext,
  ToolTip,
  percentageDifference,
} from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import { dateAsString } from 'app/utils/format';
import { DropdownValue } from 'common/components/combo-box';
import AdditionalPaysTable from 'contract-onboarding/company/components/additional-pays-table';
import Esop from 'contract-onboarding/company/components/esop';
import { EsopForm } from 'contract-onboarding/company/components/esop-input';
import { convertBasePayToMonthlyPay } from 'contract-onboarding/company/services/compensation';
import { useAdditionalCompensationsMapper } from 'performance-reviews/components/performance-review-form/use-additional-compensation-mapper';

import {
  Compensation,
  Contract,
  ContractType,
  FixedPayComponent,
  Maybe,
  PerformanceReview,
  RateFrequency,
} from '__generated__/graphql';

import CompensationCtc from '../compensation-ctc';

interface CompensationCardProps {
  title?: string;
  compensation?: Maybe<Compensation>;
  companyName?: Maybe<string>;
  contractType?: Maybe<ContractType>;
  esop?: Maybe<EsopForm>;
  currencyOptions: Maybe<DropdownValue[]>;
  position?: Maybe<string>;
  performanceReview?: Maybe<PerformanceReview>;
  country: Contract['country'];
  countryStateCode: Contract['countryStateCode'];
  probationBasePay?: Maybe<FixedPayComponent>;
  isRevisionCard?: boolean;
  onEdit?: (() => void) | null;
}

const CompensationCard: React.FC<CompensationCardProps> = ({
  title,
  compensation,
  esop,
  companyName,
  contractType,
  currencyOptions,
  position,
  country,
  countryStateCode,
  probationBasePay,
  isRevisionCard,
  performanceReview,
  onEdit,
  ...props
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('team.company');
  const methods = useForm();

  const salaryDifference = useMemo(() => {
    const currentSalary =
      performanceReview?.salaryReview?.currentCompensation?.basePay?.amount ??
      0;
    const revisedSalary =
      performanceReview?.salaryReview?.revisedCompensation?.basePay?.amount ??
      0;
    return Number(revisedSalary) && Number(currentSalary)
      ? percentageDifference(Number(revisedSalary), Number(currentSalary))
      : 0;
  }, [performanceReview]);

  const {
    createdOn: createdDate,
    effectiveDate,
    memberSignedOn,
    companySignedOn,
  } = performanceReview ?? {};

  const mapperParams = useMemo(
    () => ({
      contract: {
        country,
        countryStateCode,
        type: contractType,
      } as Contract,
    }),
    [country, countryStateCode, contractType],
  );

  const { mapToAdditionalPayFormValues } = useAdditionalCompensationsMapper(
    mapperParams,
  );

  useEffect(() => {
    methods.reset({
      additionalPays: mapToAdditionalPayFormValues(
        compensation?.additionalPays,
      ),
      esop,
    });
  }, [mapToAdditionalPayFormValues]);

  return (
    <Card tw="h-full" {...props}>
      <FormProvider {...methods}>
        <Header
          css={[
            isRevisionCard && tw`bg-background-brand-faded text-text-primary`,
          ]}
        >
          <div tw="flex items-start gap-extra-small font-semibold">
            {isRevisionCard && (
              <Icon
                name="arrow-up-circle"
                stroke={
                  isNewThemeApplied ? undefined : theme`colors.icon-primary`
                }
                fill={isNewThemeApplied ? undefined : 'transparent'}
              />
            )}
            <div tw="flex flex-col gap-tiny">
              <p>{title}</p>
              {isRevisionCard && (
                <>
                  <p tw="text-ps font-normal">
                    {t('compensation-tab.compare-page.created-date', {
                      defaultValue: 'Created on: {{date}}',
                      replace: {
                        date: dateAsString(createdDate, 'do MMMM yyyy'),
                      },
                    })}
                  </p>
                  {companySignedOn && (
                    <p tw="text-ps font-normal">
                      {t('compensation-tab.compare-page.signatory-signed-on', {
                        defaultValue: 'Signatory signed on: {{date}}',
                        replace: {
                          date: dateAsString(companySignedOn, 'do MMMM yyyy'),
                        },
                      })}
                    </p>
                  )}
                  {memberSignedOn && (
                    <p tw="text-ps font-normal">
                      {t('compensation-tab.compare-page.employee-signed-on', {
                        defaultValue: 'Employee signed on: {{date}}',
                        replace: {
                          date: dateAsString(memberSignedOn, 'do MMMM yyyy'),
                        },
                      })}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          {isRevisionCard && onEdit && (
            <ToolTip
              variant="bottom"
              content={t(
                'compensation-card.edit-button-tooltip',
                'Revoke addendum and edit',
              )}
            >
              <Button
                variant="secondary"
                size="small"
                tw="px-small"
                onClick={onEdit}
              >
                <div tw="flex items-center gap-extra-small">
                  <Icon
                    name="pencil"
                    tw="h-small w-small"
                    fill={isNewThemeApplied ? undefined : 'transparent'}
                    stroke={
                      isNewThemeApplied ? undefined : theme`colors.primary`
                    }
                  />
                  {t('compensation-card.edit-button', 'Edit')}
                </div>
              </Button>
            </ToolTip>
          )}
        </Header>
        <Body>
          <CompensationCtc
            position={position}
            basePay={compensation?.basePay}
            probationBasePay={probationBasePay}
            postProbationBasePay={compensation?.postProbationBasePay}
            effectiveDate={effectiveDate}
            isRevisionCard={isRevisionCard}
            salaryDifference={salaryDifference}
            comparing
          />
          <AdditionalPaysTable
            contractType={contractType}
            currencyOptions={currencyOptions}
            basePay={convertBasePayToMonthlyPay(
              compensation?.basePay?.frequency ?? RateFrequency.MONTHLY,
              methods.watch('revisedSalary') ?? 0,
            )}
            currency={compensation?.basePay?.currency}
            companyName={companyName}
            titleStyle={tw`text-p`}
            hideListTitle
            additionalCompensationSubText
            containerStyle={tw`px-none border-none shadow-none`}
            countryCode={country}
            countryStateCode={countryStateCode}
          />
          {esop && (
            <Esop
              showEdit={false}
              containerStyle={tw`px-none border-none shadow-none`}
            />
          )}
        </Body>
      </FormProvider>
    </Card>
  );
};

const Header = tw.div`rounded-t-large p-base text-p text-text-tertiary flex items-center justify-between`;
const Body = tw.div`px-base`;

export default CompensationCard;
