import React from 'react';

import { DropdownValue } from '@multiplier/common';

import { Contract, DeductionDefinition, Maybe } from '__generated__/graphql';

import EmployeeProvidentFund from '../requirements/employee-provident-fund';
import { DeductionConstraintFromQuery, DeductionLabel } from '../types';

type EmployeeDeductionInputComponent = React.FC<{
  groupKey: DeductionDefinition['groupKey'];
  employerDeductionDefinitionId?: Maybe<string>;
  deductionDefinitionId?: Maybe<string>;
  constraint?: DeductionConstraintFromQuery;
  currencyOptions?: Maybe<DropdownValue[]>;
  countryCode?: Contract['country'];
}>;

const EmployeeDeductionInput: EmployeeDeductionInputComponent = ({
  groupKey,
  employerDeductionDefinitionId,
  deductionDefinitionId,
  constraint,
  currencyOptions,
  countryCode,
}) => {
  if (
    !employerDeductionDefinitionId ||
    !deductionDefinitionId ||
    !constraint?.name
  ) {
    return null;
  }

  switch (constraint.name) {
    case DeductionLabel.EMPLOYEE_PROVIDENT_FUND:
      return (
        <EmployeeProvidentFund
          key={constraint.name}
          employerDeductionDefinitionId={employerDeductionDefinitionId}
          deductionDefinitionId={deductionDefinitionId}
          groupKey={groupKey}
          constraint={constraint}
          currencyOptions={currencyOptions}
          countryCode={countryCode}
        />
      );
    default:
      return null;
  }
};

export default EmployeeDeductionInput;
