// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-explicit-any
export const notEmpty = <TValue extends any>(
  value: TValue | null | undefined,
): value is TValue => {
  if (value === null || value === undefined) return false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testDummy: TValue = value; // dummy compiler check
  return true;
};
