/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import {
  Card,
  ComboBox,
  DatePicker,
  Icon,
  ThemeContext,
  ToolTip,
  percentageDifference,
} from '@multiplier/common';
import tw, { theme } from 'twin.macro';

import CurrencyHelper from 'app/components/currency-helper';
import AppFeature from 'app/features';
import { DropdownValue } from 'common/components/combo-box';
import * as DropdownText from 'common/components/dropdown-text';
import TextInput from 'common/components/text-input';
import { getFixedPayInputLabelText } from 'contract-onboarding/company/components/fixed-pay-input';
import InfoNote from 'performance-reviews/components/info-note';
import { useSalaryRevisionNotes } from 'performance-reviews/hooks';
import { getMinimumEffectiveDate } from 'performance-reviews/hooks/performance-review-schema';

import {
  Contract,
  GetPositionsForIntegrationsQuery,
  Maybe,
} from '__generated__/graphql';

import { ProbationDisclaimerNote } from '../..';
import SalaryChangesNote from '../salary-changes-note';

const ReviseCompensationSection: React.FC<{
  contract?: Contract;
  hasProbationAmount: boolean;
  currencyOptions: Maybe<DropdownValue[]>;
  supportedJobPositions?: GetPositionsForIntegrationsQuery['getPositionsForIntegrations'];
}> = ({
  contract,
  hasProbationAmount,
  currencyOptions,
  supportedJobPositions,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  const { t } = useTranslation('performance-reviews');
  const { t: tContractOnboarding } = useTranslation(
    'contract-onboarding.common',
  );
  const isSalaryRevisionNotesEnabled = useFeature(
    AppFeature.SALARY_REVISION_NOTES,
  ).on;

  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const [revisedSalary, currency, effectiveMonth, promotedDesignation] = watch([
    'revisedSalary',
    'currency',
    'effectiveMonth',
    'promotedDesignation',
  ]);

  const { getNote, message } = useSalaryRevisionNotes();

  useEffect(() => {
    getNote(contract?.id, effectiveMonth);
  }, [contract?.id, effectiveMonth]);

  const salaryDifference = useMemo(() => {
    const currentSalary =
      contract?.compensation?.postProbationBasePay?.amount ?? 0;
    return Number(revisedSalary) && Number(currentSalary)
      ? percentageDifference(Number(revisedSalary), Number(currentSalary))
      : 0;
  }, [revisedSalary, contract]);

  const isEmployeeStartingOnThisMonth = useMemo(() => {
    const employeeStartMonth = new Date(contract?.startOn).getMonth();
    const employeeStartYear = new Date(contract?.startOn).getFullYear();
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    return (
      currentYear === employeeStartYear && currentMonth === employeeStartMonth
    );
  }, [contract]);

  const dropDownOptions: DropdownValue[] =
    supportedJobPositions?.map((i) => ({
      title: i?.designation ?? '',
      value: i?.designation ?? '',
    })) ?? [];

  return (
    <Card tw="p-large mt-base grid grid-cols-1 mobile:px-base">
      <p tw="text-[18px] font-semibold mb-large text-text-primary">
        {t(
          'form.current-salary.compensation-and-title',
          'Compensation & Job Title',
        )}
      </p>

      {hasProbationAmount && (
        <ProbationDisclaimerNote
          validTillDate={
            contract?.compensation?.probationBasePay?.validToExclusive
          }
        />
      )}

      <div tw="grid grid-cols-2 gap-x-small mb-large">
        <TextInput.Container>
          <TextInput.Label
            htmlFor="revised-salary"
            tw="text-ps font-semibold lowercase first-letter:uppercase"
          >
            {t('form.revised-salary.prefix', 'Revised')}{' '}
            {hasProbationAmount && (
              <span tw="text-text-primary">
                {t('form.revised-salary.post-probation', 'Post-Probation')}{' '}
              </span>
            )}
            {contract
              ? getFixedPayInputLabelText({
                  contractTerm: contract?.term,
                  rateType: contract?.compensation?.basePay?.rateType,
                  frequency: contract?.compensation?.basePay?.frequency,
                  label: contract?.compensation?.basePay?.label,
                  t: tContractOnboarding,
                })
              : t('form.revised-salary.fallback-suffix', 'Salary')}
          </TextInput.Label>
          <DropdownText.Container tw="flex-grow">
            <Controller
              name="currency"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  data-testid="currency-select"
                  variant="inline"
                  showArrow
                  value={value as string}
                  dropdownValues={currencyOptions || []}
                  placeholder=""
                  onChange={onChange}
                  disabled
                />
              )}
            />
            <DropdownText.Input
              id="revised-salary"
              currency
              type="number"
              step="0.01"
              placeholder={t('form.revised-salary.placeholder', 'Amount')}
              tw="appearance-none"
              data-testid="currency-input"
              divStyles={tw`flex-grow`}
              disabled={!contract}
              {...register('revisedSalary')}
            />
            <CurrencyHelper
              tw="mr-tiny whitespace-nowrap flex items-center"
              amount={revisedSalary}
              referenceCurrency={currency}
            />
          </DropdownText.Container>
          {errors.revisedSalary ? (
            <TextInput.Error>
              {errors.revisedSalary?.message as string}
            </TextInput.Error>
          ) : (
            salaryDifference !== 0 && (
              <SalaryChangesNote salaryDifference={salaryDifference} />
            )
          )}
        </TextInput.Container>
        <TextInput.Container>
          <div tw="flex items-center">
            <TextInput.Label htmlFor="designation" tw="text-ps font-semibold">
              {t('form.new-designation.label-new', 'New job title')}
            </TextInput.Label>
            <ToolTip
              variant="bottom"
              content={
                <div tw="max-width[30ch] leading-5">
                  {t(
                    'form.new-designation.description',
                    'Add New Designation if the employee has been promoted or if there is a change in job responsibilities',
                  )}
                </div>
              }
            >
              <Icon
                name="info"
                fill={theme`colors.icon-primary`}
                tw="ml-extra-small w-base h-base"
              />
            </ToolTip>
          </div>

          {supportedJobPositions ? (
            <Controller
              control={control}
              name="promotedDesignation"
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  data-testid="designation"
                  variant="default"
                  value={value}
                  dropdownValues={dropDownOptions}
                  placeholder={t(
                    'definition-phase.basic-details.job-title.placeholder',
                    'Software Engineer',
                  )}
                  onChange={onChange}
                />
              )}
            />
          ) : (
            <TextInput
              tw="w-full"
              id="designation"
              placeholder={t(
                'form.promoted-designation.placeholder',
                'Add new job title',
              )}
              error={!!errors.promotedDesignation}
              {...register('promotedDesignation')}
            />
          )}

          {errors.promotedDesignation ? (
            <TextInput.Error>
              {errors.promotedDesignation?.message as string}
            </TextInput.Error>
          ) : (
            <>
              {promotedDesignation && contract?.position && (
                <div tw="flex flex-row items-center">
                  <Icon
                    name="arrow-up-circle"
                    tw="w-base"
                    stroke={
                      !isNewThemeApplied
                        ? theme`colors.icon-secondary`
                        : undefined
                    }
                    fill={!isNewThemeApplied ? 'transparent' : undefined}
                  />
                  <span tw="text-ps text-text-tertiary ml-tiny">
                    {t(
                      'form.previous-designation.label',
                      'Previously: {{position}}',
                      { replace: { position: contract?.position } },
                    )}
                  </span>
                </div>
              )}
            </>
          )}
        </TextInput.Container>
      </div>
      <div tw="grid grid-cols-2 gap-x-small">
        <TextInput.Container>
          <div tw="flex items-center">
            <TextInput.Label
              htmlFor="effective-month"
              tw="text-ps font-semibold"
            >
              {t(
                'form.effective-month.label-new',
                'Effective date (Mandatory)',
              )}
            </TextInput.Label>
            <ToolTip
              variant="bottom"
              content={
                <div tw="max-width[30ch] leading-5">
                  {t(
                    'form.effective-month.description',
                    'The date is mandatory and will reflect in the contract addendum',
                  )}
                </div>
              }
            >
              <Icon
                name="info"
                fill={theme`colors.icon-primary`}
                tw="ml-extra-small w-base h-base"
              />
            </ToolTip>
          </div>

          <Controller
            control={control}
            name="effectiveMonth"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                data-testid="effective-month"
                id="effective-month"
                tw="mt-2"
                value={value}
                onChange={onChange}
                min={getMinimumEffectiveDate(
                  isSalaryRevisionNotesEnabled,
                  contract?.startOn,
                  isEmployeeStartingOnThisMonth,
                  contract?.compensation?.probationBasePay?.validToExclusive,
                )}
                error={!!errors.effectiveMonth}
                helperText={errors.effectiveMonth?.message as string}
                loading={false}
                placeholder={t(
                  'form.effective-month.placeholder',
                  'DD MM YYYY',
                )}
              />
            )}
          />
          {isSalaryRevisionNotesEnabled && message && (
            <InfoNote>{message}</InfoNote>
          )}
        </TextInput.Container>
      </div>
    </Card>
  );
};

export default ReviseCompensationSection;
