/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { ReactComponent as Logo } from '@multiplier/assets/files/common/logo.svg';
import {
  Avatar,
  Icon,
  IconButton,
  ThemeContext,
  ToggleButton,
  ToolTip,
} from '@multiplier/common';
import tw, { TwStyle, css, theme } from 'twin.macro';

import { Experience, NavIconType } from 'app/models/module-config';
import { userVar } from 'app/vars';
import { refresh } from 'login/services/startup';

import { Company } from '__generated__/graphql';

import useGetExperiences from './hooks/get-experiences';
import {
  CompanyMainBar,
  CompanyTitleWrapper,
  NavIcon,
  SectionEntryWrapper,
} from './layout';

export interface EntryProps {
  route?: string;
  id: string;
  icon?: NavIconType;
  activeIcon?: NavIconType;
  notificationCount?: number | null;
  label?: string;
  handleClick?: () => void;
  isNested?: boolean;
  onLinkActive?: (id: string) => void;
  styles?: TwStyle;
}

interface HeaderProps {
  company: string;
  isMultipleCompanyExperience: boolean;
  closeable?: boolean;
  logo?: Company['companyLogo'];
  showDropdown?: boolean;
  onClick?: () => void;
  toggleGroupDropdown?: () => void;
}

interface FooterProps {
  name: string;
  handleAccount?: () => void;
  handleLogout?: () => void;
}

const Sidebar: React.FC<React.PropsWithChildren> & {
  Header: React.FC<React.PropsWithChildren<HeaderProps>>;
  Body: React.FC<React.PropsWithChildren>;
  Section: React.FC<React.PropsWithChildren>;
  SectionHeader: React.FC<React.PropsWithChildren>;
  SectionEntry: React.FC<React.PropsWithChildren<EntryProps>>;
  Footer: React.FC<React.PropsWithChildren<FooterProps>>;
} = ({ children }) => <>{children}</>;

Sidebar.Body = ({ children }) => (
  <div
    css={[
      tw`flex-grow px-base overflow-auto gap-y-extra-small desktop:px-extra-small`,
      css`
        ::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

        mask-image: linear-gradient(180deg, #000 900%, transparent);
        -webkit-mask-image: linear-gradient(180deg, #000 90%, transparent);
      `,
    ]}
  >
    {children}
  </div>
);

Sidebar.Header = ({
  company,
  logo,
  closeable = false,
  showDropdown,
  isMultipleCompanyExperience = false,
  onClick,
  toggleGroupDropdown,
}) => {
  const navigate = useNavigate();
  const user = useReactiveVar(userVar);

  const { isNewThemeApplied } = useContext(ThemeContext);

  const onExperienceSwitch = async (experience: Experience) => {
    /**
     * Trigger page reload when switching experience to ensure fresh data and prevent race conditions issues with the internal "refresh" logic
     * FIXME: This is just workaround approach, need to find a proper solution to ensure smooth transition between experiences
     */
    window.location.href = `/${experience}`;

    // FIXME: For some reasons, removing these logics cause failed tests of "startup.test.ts" - hence just keep it for now but won't have any effect
    navigate(`/${experience}`);
    await refresh(`/${experience}`);
  };

  const {
    experiences: { current, authorized },
    defaultCompanyId,
    selectedCompany,
  } = user;

  const showSwitchView = useMemo(
    () =>
      authorized.includes(Experience.COMPANY) &&
      authorized.includes(Experience.MEMBER),
    [authorized],
  );

  const isDefaultCompany = defaultCompanyId === selectedCompany?.id;
  const availableExperiences = useGetExperiences(authorized);

  return (
    <CompanyTitleWrapper>
      <CompanyMainBar
        isHover={isMultipleCompanyExperience}
        onClick={toggleGroupDropdown}
      >
        {closeable && (
          <button
            tw="focus:(ring-transparent outline-none)"
            data-testid="close-nav"
            type="button"
            onClick={onClick}
          >
            <Icon
              fill={theme`colors.icon-tertiary`}
              name="cross"
              tw="mr-base"
            />
          </button>
        )}
        {logo?.link ? (
          <img src={logo.link} alt="company logo" tw="rounded-base w-32 h-32" />
        ) : (
          <Avatar size="extraSmall" name={company} greyBackground />
        )}
        <span css={[tw`text-ps font-semibold text-text-primary ml-small`]}>
          {company}
        </span>
        {isMultipleCompanyExperience && (
          <div tw="flex items-center flex-1 justify-end">
            <Icon name={showDropdown ? 'caret-up' : 'caret-down'} />
          </div>
        )}
      </CompanyMainBar>

      {showSwitchView && isDefaultCompany && (
        <ToggleButton
          data-testid="select-view"
          items={availableExperiences.map((experience) => ({
            id: experience.experience,
            element: <p>{experience.name}</p>,
          }))}
          onChange={(value) => {
            if (current !== value) onExperienceSwitch(value as Experience);
          }}
          selectedId={current}
          containerStyles={
            isNewThemeApplied ? tw`self-center rounded-full` : tw`self-center`
          }
          buttonStyles={
            isNewThemeApplied
              ? (selected) => [
                  tw`rounded-full px-base text-nowrap`,
                  selected ? tw`shadow-flat` : tw``,
                ]
              : () => [tw`px-base text-nowrap`]
          }
        />
      )}
    </CompanyTitleWrapper>
  );
};

Sidebar.Section = tw.div`flex flex-col`;

Sidebar.SectionHeader = ({ children }) => (
  <div tw="flex flex-row items-center px-base py-small">
    <span tw="text-text-primary text-ps font-semibold">{children}</span>
  </div>
);

Sidebar.SectionEntry = ({
  route,
  id,
  icon,
  activeIcon,
  notificationCount,
  label,
  handleClick,
  children,
  isNested = false,
  onLinkActive,
  styles = tw``,
}) => {
  const { isNewThemeApplied } = useContext(ThemeContext);

  const renderContent = (isActive: boolean) => (
    <SectionEntryWrapper
      notificationCount={notificationCount}
      isNested={isNested}
      isActive={isActive}
      css={[!isNested && tw`mb-extra-small`, styles]}
      isNewThemeApplied={isNewThemeApplied}
    >
      {icon && (
        /**
         * For Nav icons; use the CSS tricks to ensure icons will be preloaded before renders
         * This is to fixing flickering issue when toggle between active and inactive icons
         */
        <div tw="w-[30px]">
          <NavIcon
            key={icon.name}
            icon={
              {
                ...icon,
                fill: isNewThemeApplied
                  ? theme`colors.icon-primary`
                  : '#252322',
              } as NavIconType
            }
            css={[isActive ? tw`hidden` : tw`block`]}
          />
          <NavIcon
            key={`${icon.name}_active`}
            icon={
              {
                ...(activeIcon ?? icon),
                fill: isNewThemeApplied ? theme`colors.icon-brand` : '#252322',
              } as NavIconType
            }
            css={[isActive ? tw`block` : tw`hidden`]}
          />
        </div>
      )}
      {children}
      <div tw="flex-grow" />
      {notificationCount && notificationCount > 0 && (
        <div
          data-testid="pill"
          tw="flex items-center rounded-full bg-background-negative-faded px-small text-text-negative"
        >
          {notificationCount}
        </div>
      )}
      {label && (
        <div
          data-testid="label-pill"
          tw="flex items-center px-small bg-background-brand rounded-full text-pxs ml-tiny font-semibold text-text-inverted-primary"
        >
          {label}
        </div>
      )}
    </SectionEntryWrapper>
  );

  if (route) {
    return (
      <NavLink to={route} data-testid={`${id}-link`} onClick={handleClick}>
        {({ isActive }) => {
          if (isActive) {
            onLinkActive?.(id);
          }
          return renderContent(isActive);
        }}
      </NavLink>
    );
  }

  return (
    <div
      role="button"
      tabIndex={0}
      data-testid={`${id}-section`}
      onClick={handleClick}
      aria-hidden
    >
      {renderContent(false)}
    </div>
  );
};

Sidebar.Footer = ({ name, handleAccount, handleLogout }) => {
  const { t } = useTranslation('sidebar.view');

  const { isNewThemeApplied } = useContext(ThemeContext);

  return (
    <div tw="flex flex-row justify-between items-center bg-background-primary mt-auto p-base">
      {isNewThemeApplied ? (
        <Logo tw="w-96 h-extra-large" />
      ) : (
        <span tw="font-semibold text-[22px] tracking-wide text-text-primary">
          Multiplier
        </span>
      )}
      <div tw="flex items-center gap-extra-small">
        <ToolTip
          variant="top"
          content={
            <div tw="flex flex-col text-center gap-tiny">
              <p>{t('sidebar-footer.your-settings', 'Your settings')}</p>
              <p tw="text-text-tertiary">{name}</p>
            </div>
          }
        >
          <IconButton
            tw="border-border-primary"
            variant="outline"
            name="user"
            size="small"
            onClick={handleAccount}
            fill={isNewThemeApplied ? theme`colors.icon-primary` : 'none'}
            iconStyles={isNewThemeApplied ? tw`w-20 h-20` : undefined}
            buttonStyles={
              isNewThemeApplied
                ? tw`w-36 h-36 bg-background-white shadow-flat`
                : undefined
            }
          />
        </ToolTip>
        <ToolTip variant="top" content={t('sidebar-footer.logout', 'Log out')}>
          <IconButton
            tw="border-border-primary"
            variant="outline"
            name="log-in"
            size="small"
            onClick={handleLogout}
            fill={isNewThemeApplied ? theme`colors.icon-primary` : 'none'}
            iconStyles={isNewThemeApplied ? tw`w-20 h-20` : undefined}
            buttonStyles={
              isNewThemeApplied
                ? tw`w-36 h-36 bg-background-white shadow-flat`
                : undefined
            }
          />
        </ToolTip>
      </div>
    </div>
  );
};

export default Sidebar;
