/** @jsxImportSource @emotion/react */
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';
import { dateAsString } from '@multiplier/format';
import { AppFeature } from '@multiplier/growthbook';

import COMMON_LINKS from 'common/constants/common-links';
import countryLabels from 'common/constants/country-labels';

import { Contract, ContractType } from '__generated__/graphql';

import WetInkKnowMoreLink from '../wet-ink-signature/wet-ink-know-more-link';

interface LimidationProp {
  name: string;
  label: string;
  link: string | undefined;
}

const LIMITATOINS = {
  PRE_REGISTRATION: {
    name: 'local-employment',
    label: 'Local Employment',
    link: '',
  },
  VISA_APPLICATION: {
    name: 'visa-application',
    label: 'VISA Application',
    link: '',
  },
  WET_INK_SIGNATURE: {
    name: 'wet-ink-signature',
    label: 'Wet-Ink Signature',
    link: COMMON_LINKS.WIKI_WET_INK_SIGNATURE,
  },
};

const PILL_SEPARATOR = {
  COMMA: {
    name:
      'definition-phase.basic-details.employment-start-date.calendar-note-limitations-separator.comma',
    label: ', ',
  },
  AND: {
    name:
      'definition-phase.basic-details.employment-start-date.calendar-note-limitations-separator.and',
    label: ' and ',
  },
};

function getLimitions({
  visaWorkflowEnabled,
  visaCheckpointDate,
  isWetInkSignatureEnabled,
}: {
  visaWorkflowEnabled: boolean | undefined;
  visaCheckpointDate: Date | undefined;
  isWetInkSignatureEnabled: boolean | undefined;
}): LimidationProp[] {
  const limitations = [LIMITATOINS.PRE_REGISTRATION];

  if (visaWorkflowEnabled && visaCheckpointDate) {
    limitations.push(LIMITATOINS.VISA_APPLICATION);
  }

  if (isWetInkSignatureEnabled) {
    limitations.push(LIMITATOINS.WET_INK_SIGNATURE);
  }

  return limitations;
}

const LimitationPillSeperator: React.FC<{
  index: number;
  length: number;
}> = ({ index, length }) => {
  if (index < 1) return null;

  const { t } = useTranslation('contract-onboarding.common');
  const i18Props =
    index === length - 1 ? PILL_SEPARATOR.AND : PILL_SEPARATOR.COMMA;

  return <span>{t(i18Props.name, { defaultValue: i18Props.label })}</span>;
};

const LimitationPill: React.FC<LimidationProp> = ({ name, label, link }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const PillCommponent: React.FC<React.PropsWithChildren> = !link
    ? ({ children }) => <span>{children}</span>
    : ({ children }) => <WetInkKnowMoreLink>{children}</WetInkKnowMoreLink>;
  const transProps = {
    t,
    i18nKey: `definition-phase.basic-details.employment-start-date.calendar-note-limitations.${name}`,
    defaults: label,
  };

  return (
    <PillCommponent>
      <Trans {...transProps} />
    </PillCommponent>
  );
};

const Limitations: React.FC<{ limitations: LimidationProp[] }> = ({
  limitations,
}) => (
  <>
    {limitations.map((current, index) => (
      <span key={current.name}>
        <LimitationPillSeperator {...{ index, length: limitations.length }} />
        <LimitationPill {...current} />
      </span>
    ))}
  </>
);

const CalendarNote: React.FC<{
  contractType: Contract['type'];
  country: Contract['country'];
  earliestJoiningDate: Date | undefined;
  visaCheckpointDate: Date | undefined;
}> = ({ contractType, country, earliestJoiningDate, visaCheckpointDate }) => {
  const { t } = useTranslation('contract-onboarding.common');
  const visaWorkflowEnabled = useFeature('contract.onboarding.visa-workflow')
    .on;
  const isWetInkSignatureEnabled = useFeature(
    AppFeature.CONTRACT_ONBOARDING_WITH_WET_INK_ENABLED,
  ).on;

  if (contractType !== ContractType.EMPLOYEE) {
    return null;
  }

  const earliestStartDate = dateAsString(earliestJoiningDate, 'do MMM yy');
  const countryLabel = country ? countryLabels[country] : country;

  const limitations = getLimitions({
    visaWorkflowEnabled,
    visaCheckpointDate,
    isWetInkSignatureEnabled,
  });

  return (
    <Trans
      t={t}
      i18nKey="definition-phase.basic-details.employment-start-date.calendar-note-with-limitations"
      defaults="The earliest available employment start date is {{ earliestStartDate }}, taking into account <limitations/> regulations in {{ country }}"
      values={{ earliestStartDate, country: countryLabel }}
      components={{
        limitations: <Limitations limitations={limitations} />,
      }}
    />
  );
};

export default CalendarNote;
