import { TFunction } from 'i18next';

import { InsuranceCoverageGroup, Maybe } from '../__generated__/graphql';

export const isOnlyEmployeeCoverage = (
  coverageGroups?: Maybe<Maybe<InsuranceCoverageGroup>[]>,
): boolean =>
  coverageGroups?.length === 1 && coverageGroups?.[0]?.group?.length === 1;

export const isFamilyCoverage = (
  coverageGroup?: Maybe<InsuranceCoverageGroup>,
): boolean => (coverageGroup?.group?.length ?? 0) > 1;

export const hasFamilyCoverage = (
  coverageGroups?: Maybe<Maybe<InsuranceCoverageGroup>[]>,
): boolean =>
  coverageGroups?.some((coverageGroup) => isFamilyCoverage(coverageGroup)) ??
  false;

export const getCoverageGroupWithDependants = (
  t: TFunction,
  coverageGroup?: Maybe<InsuranceCoverageGroup>,
  numberOfDependents?: Maybe<number>,
): string => {
  if (!coverageGroup) return '';
  if (
    isOnlyEmployeeCoverage([coverageGroup]) &&
    (numberOfDependents ?? 0) > 0
  ) {
    return t(
      'insurance:coverage-group.employee-with-dependents-v2',
      'Employee + {{numberOfDependents}} dependents',
      { numberOfDependents },
    );
  }
  return coverageGroup?.name ?? '';
};
