/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@growthbook/growthbook-react';

import AppFeature from 'app/features';
import ComboBox from 'common/components/combo-box';
import TextInput from 'common/components/text-input';
import countryLabels from 'common/constants/country-labels';

import {
  ContractType,
  CountryCode,
  GetSupportedCountriesQuery,
} from '__generated__/graphql';

import { BasicDetailsFormValues } from '../basic-details';

const TaxResidencySelector: React.FC<{
  supportedCountries?: GetSupportedCountriesQuery;
}> = ({ supportedCountries }) => {
  const { t } = useTranslation('contract-onboarding.common');

  const excludedFreelancerCountries = useFeature(
    'contract-onboarding-countries-exclusion-list',
  )?.value?.FREELANCER;

  const aorOnboardingEnabled = useFeature(AppFeature.AOR_ONBOARDING).on;

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<BasicDetailsFormValues>();

  const taxResidencyDropdownValues = useMemo(
    () =>
      supportedCountries?.supportedCountries
        ?.filter(
          (c) => c?.code && !excludedFreelancerCountries?.includes(c.code),
        )
        .map((c) => ({
          label: countryLabels[c?.code as CountryCode],
          value: `${c?.code}`,
          title: `${c?.code}`,
        })) ?? [],
    [supportedCountries],
  );

  const contractType = watch('contractType');

  if (aorOnboardingEnabled || contractType !== ContractType.FREELANCER) {
    return null;
  }

  return (
    <TextInput.Container>
      <TextInput.Label htmlFor="tax-residency">
        {t(
          'definition-phase.basic-details.tax-residency.label',
          'Tax Residency',
        )}
      </TextInput.Label>
      {supportedCountries?.supportedCountries &&
        supportedCountries?.supportedCountries?.length > 0 && (
          <Controller
            name="taxResidency"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ComboBox
                id="tax-residency"
                data-testid="tax-residency"
                variant="autocomplete"
                dropdownValues={taxResidencyDropdownValues}
                showArrow
                value={value || ''}
                onChange={onChange}
                placeholder={t(
                  'definition-phase.basic-details.tax-residency.placeholder',
                  'Select Country',
                )}
              />
            )}
          />
        )}
      {errors.taxResidency && (
        <TextInput.Error>{errors.taxResidency.message}</TextInput.Error>
      )}
    </TextInput.Container>
  );
};

export default TaxResidencySelector;
