import { useContext } from 'react';

import tw, { styled } from 'twin.macro';

import { ThemeContext } from '../theme-provider';

const Card = styled.div(() => {
  const { isNewThemeApplied } = useContext(ThemeContext);
  return [
    tw`bg-background-white rounded-base shadow-flat`,
    !isNewThemeApplied && tw`shadow-none`,
  ];
});

export default Card;
