/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import 'twin.macro';
import { useTranslation } from 'react-i18next';

import { PillTag, PillVariant } from '@multiplier/common';

import { Contract, ContractStatus } from '__generated__/graphql';

interface MemberContractStatusDetails {
  label: string;
  variant: PillVariant;
}

const MemberContractStatus: React.FC<{
  status?: Contract['status'];
}> = ({ status }) => {
  const { t } = useTranslation('team.company');

  const statusDetails = useMemo(() => {
    const statusLabels: {
      [key: string]: MemberContractStatusDetails;
    } = {
      [ContractStatus.ACTIVE]: {
        label: t('member-contract-status.draft.label', 'Active'),
        variant: 'PREPROCESSING',
      },
      [ContractStatus.ONBOARDING]: {
        label: t('member-contract-status.created.label', 'Onboarding'),
        variant: 'PREPROCESSING',
      },
      [ContractStatus.ENDED]: {
        label: t('member-contract-status.ended.label', 'Ended'),
        variant: 'PREPROCESSING',
      },
      [ContractStatus.OFFBOARDING]: {
        label: t(
          'member-contract-status.signature-employer-sent.label',
          'Offboarding',
        ),
        variant: 'PREPROCESSING',
      },
    };

    return status && statusLabels[status];
  }, [status]);

  return (
    <PillTag
      tw="max-w-[150px]"
      label={statusDetails?.label ?? ''}
      variant={statusDetails?.variant ?? 'PREPROCESSING'}
    />
  );
};

export default MemberContractStatus;
