import { gql } from '@apollo/client';

export default gql`
  mutation UpdateWorkEmail($id: ID!, $email: EmailAddressInput!) {
    contractWorkEmailUpdate(id: $id, email: $email) {
      id
      workEmail
    }
  }
`;
