/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, useModal } from '@multiplier/common';
import {
  getAvailableBenefitsFromBenefitPartner,
  getBenefitPartnerCoverageIndexer,
  hasFamilyCoverage,
  isFamilyCoverage,
  isOnlyEmployeeCoverage,
  useGetInsuranceBenefitAndCoverage,
} from '@multiplier/insurance';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import 'twin.macro';
import * as yup from 'yup';

import { successNotification } from 'app/services/notification-services';
import {
  ChangeWarningStatusArgs,
  EmployeeDetailSections,
} from 'contract-onboarding/company/hooks/show-uncommitted-changes-warning';
import { useSubmitBenefits } from 'contract-onboarding/hooks';
import { Sections } from 'team/components/employee-details-component';

import {
  BenefitPartnerCountry,
  BenefitPartnerStatus,
  Contract,
} from '__generated__/graphql';

import { BenefitFormValues } from '../../../company/pages/definition-phase/pages/benefits/benefit-view-form';
import {
  getBenefitId,
  getDependentCount,
  getEmployerPayPercentage,
  getIncludeDependents,
} from '../../../company/services/benefits';
import ContractReviewSectionModal, {
  FooterSection,
} from '../../contract-review-section-modal';
import { DetailForm } from '../../layout';
import SectionActionBar from '../../section-action-bar';
import InsuranceFormDataV2 from './insurance-form-data-v2';

export enum InsuranceSectionVariant {
  MEMBER,
  COMPANY,
}

export interface InsuranceSectionProps {
  id: Contract['id'];
  benefits: Contract['benefits'];
  country: Contract['country'];
  contractType: Contract['type'];
  variant?: InsuranceSectionVariant;
  edit: boolean;
  uncommittedChangesCallback?: (values: ChangeWarningStatusArgs) => void;
  toggle?: (key: string) => void;
  open?: boolean;
}

const InsuranceSectionV2: React.FC<InsuranceSectionProps> = ({
  id,
  benefits,
  country,
  contractType,
  edit,
  uncommittedChangesCallback,
  toggle,
  open,
}) => {
  const { t } = useTranslation('contract-onboarding.common');

  const [coverageGroupId, setCoverageGroupId] = useState<string>('');

  const {
    insuranceCoverageGroups,
    benefitPartnerCountries,
    onLoadBenefitAndCoverage,
  } = useGetInsuranceBenefitAndCoverage();

  const benefitIndexer = getBenefitPartnerCoverageIndexer(
    benefitPartnerCountries,
  );
  const availableBenefits = getAvailableBenefitsFromBenefitPartner(
    benefitPartnerCountries,
  ); // hot-solution for new API response

  const currentCoverageGroup = insuranceCoverageGroups?.find?.(
    (item) => item?.id === coverageGroupId,
  );

  const benefit = benefits?.[0]?.benefit;

  const benefitPartnerCountryData = benefitIndexer?.[coverageGroupId] ?? {};

  const minimumErSplitPercentage =
    benefitPartnerCountryData?.minimumErSplitPercentage;

  const contractBenefit = useMemo(
    () => benefits?.filter((val) => !isNil(val))?.[0],
    [benefits],
  );

  const [showEdit, toggleEdit] = useToggle(false);
  const [
    showEditFormModal,
    handleCloseEditFormModal,
    handleOpenEditFormModal,
  ] = useModal(false);

  const modalCloseHandler = () => {
    toggleEdit(false);

    reset();
    handleCloseEditFormModal();
  };

  const methods = useForm<BenefitFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      benefitId: null,
      includeDependents: false,
    },
    resolver: yupResolver(
      yup.object().shape({
        benefitId: benefitPartnerCountryData?.isInsuranceMandatory
          ? yup.string().uuid().required()
          : yup.string().uuid().nullable(),
        includeDependents: yup.boolean().required(),
        dependentCount: yup.number().nullable(),
        employerPayPercentage: yup
          .number()
          .max(
            100,
            t(
              'insurance.er-split.max-validation',
              'Employer pay percentage cannot be more than 100%',
            ),
          )
          .test(
            'minimum-er-percentage',
            t('insurance.er-split.min-validation', {
              defaultValue:
                'Minimum contribution has to be {{minimumPercentage}}%',
              replace: {
                minimumPercentage: minimumErSplitPercentage,
              },
            }),
            (value) => {
              if (
                !isNil(value) &&
                benefitPartnerCountryData?.erSplitApplicable &&
                !isNil(minimumErSplitPercentage) &&
                value < minimumErSplitPercentage
              ) {
                return false;
              }

              return true;
            },
          )
          .typeError(
            t(
              'insurance.er-split.must-be-number',
              'Employer pay percentage must be a number',
            ),
          )
          .nullable(),
      }),
    ),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
    setValue,
    getValues,
  } = methods;

  const selectedBenefit = useMemo(
    () =>
      benefitPartnerCountryData?.benefits?.find(
        (ele) => ele?.id === watch('benefitId'),
      ),
    [benefitPartnerCountryData, watch('benefitId')],
  );

  useEffect(() => {
    if (watch('includeDependents') && watch('dependentCount') === '0') {
      setValue('dependentCount', '1'); // reset
    }
  }, [watch('includeDependents'), selectedBenefit]);

  useEffect(() => {
    const dependantCount = getDependentCount(benefits);
    if (benefits && benefits.length > 0) {
      const benefitId = getBenefitId(benefits);
      if (benefitId !== getValues('benefitId')) {
        const employerPayPercentage = getEmployerPayPercentage(benefits);
        if (benefits?.[0]?.benefit?.provider?.coverageGroup?.id) {
          setCoverageGroupId(
            benefits?.[0]?.benefit?.provider?.coverageGroup?.id,
          );
        }
        reset({
          ...getValues(),
          benefitId: getBenefitId(benefits),
          includeDependents: getIncludeDependents(benefits),
          employerPayPercentage: !isNil(employerPayPercentage)
            ? String(employerPayPercentage)
            : null,
        });
      }

      if (dependantCount) {
        reset({
          ...getValues(),
          dependentCount: `${dependantCount}`,
        });
      }
    }
  }, [benefits]);

  useEffect(() => {
    if (country && contractType) {
      onLoadBenefitAndCoverage({
        countries: [country],
        memberType: [contractType],
        statuses: [BenefitPartnerStatus.ACTIVE],
      });
    }
  }, [country, contractType]);

  useEffect(() => {
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.INSURANCE_DETAILS,
        value: isDirty,
      });
  }, [isDirty]);

  useEffect(() => {
    if (watch('benefitId') !== benefit?.id) {
      // prefer to use watch('benefitId') instead of selectedBenefit?.id because benefitPartnerCountryData can null before the data is loaded
      if (
        selectedBenefit &&
        isFamilyCoverage(
          benefitIndexer?.[
            selectedBenefit?.provider?.coverageGroup?.id as string
          ]?.coverageGroup,
        ) &&
        selectedBenefit?.dependentMaxCount !== null
      ) {
        setValue('dependentCount', `${selectedBenefit?.dependentMaxCount}`);
        setValue('includeDependents', true);
      } else {
        setValue('dependentCount', '0');
        setValue('includeDependents', false);
      }
    }
  }, [selectedBenefit, benefit]);

  const { onSubmit, loading } = useSubmitBenefits(id, () => {
    modalCloseHandler();
    successNotification(
      t(
        'onboarding-phase.insurance-section.success-notification',
        'Insurance details have been updated in the contract.',
      ),
      '',
      false,
    );
    if (uncommittedChangesCallback)
      uncommittedChangesCallback({
        sectionName: EmployeeDetailSections.INSURANCE_DETAILS,
        value: false,
      });
  });

  const processFormValues = async (values: BenefitFormValues) => {
    const erSplitApplicable = benefitPartnerCountries?.[0]?.erSplitApplicable;
    const processedFormvalues = {
      ...values,
      employerPayPercentage: erSplitApplicable
        ? values.employerPayPercentage
        : null,
    };
    await onSubmit(processedFormvalues);
  };

  const showDependents =
    isOnlyEmployeeCoverage(insuranceCoverageGroups) && Boolean(selectedBenefit);

  useEffect(() => {
    if (
      // not safewing
      selectedBenefit &&
      isOnlyEmployeeCoverage(
        currentCoverageGroup ? [currentCoverageGroup] : [],
      ) &&
      hasFamilyCoverage(insuranceCoverageGroups)
    ) {
      setValue('dependentCount', null);
      setValue('includeDependents', false);
    }
  }, [currentCoverageGroup, insuranceCoverageGroups, selectedBenefit]);

  const tooltipContent =
    benefitPartnerCountryData?.isInsuranceMandatory &&
    isNil(selectedBenefit?.id) ? (
      <div tw="font-medium text-pxs text-center py-8 px-12 self-stretch">
        {t(`benefits.${country}-insurance-continue-tooltip`, {
          defaultValue:
            'Select an insurance plan. Providing insurance is mandatory by law for employees in {{country}}.',
          replace: {
            country,
          },
        })}
      </div>
    ) : null;

  return (
    <Accordion
      name={t('onboarding-phase.insurance.header', 'Insurance')}
      description={t(
        'onboarding-phase.insurance.description',
        'Category description to better explain what this section is about.',
      )}
      edit={edit}
      icon="shield-check"
      onEditClick={() => {
        toggleEdit(true);
        handleOpenEditFormModal();
        if (toggle) toggle(Sections.INSURANCE);
      }}
      warning={uncommittedChangesCallback && isDirty}
      onClick={() => {
        if (toggle) toggle(Sections.INSURANCE);
      }}
      open={open}
    >
      <FormProvider {...methods}>
        <DetailForm onSubmit={handleSubmit(processFormValues)}>
          <InsuranceFormDataV2
            isFormEditing={false}
            benefitPartnerCountryData={
              benefitPartnerCountryData as BenefitPartnerCountry
            }
            availableBenefits={availableBenefits}
            selectedBenefit={selectedBenefit}
            contractBenefit={contractBenefit}
            setCoverageGroupId={setCoverageGroupId}
            coverageGroupId={coverageGroupId}
            showDependents={showDependents}
            benefits={benefits}
            country={country}
            contractType={contractType}
            coverageGroups={insuranceCoverageGroups}
          />
        </DetailForm>
        {showEditFormModal && (
          <ContractReviewSectionModal
            modalTitle="Edit Insurance Details"
            onClose={modalCloseHandler}
          >
            <DetailForm onSubmit={handleSubmit(processFormValues)}>
              <div
                tw="flex flex-col p-extra-large py-[20px] gap-y-large"
                data-testid="edit-insurance-detail-modal"
              >
                <InsuranceFormDataV2
                  availableBenefits={availableBenefits}
                  isFormEditing={showEdit}
                  benefitPartnerCountryData={
                    benefitPartnerCountryData as BenefitPartnerCountry
                  }
                  selectedBenefit={selectedBenefit}
                  contractBenefit={contractBenefit}
                  setCoverageGroupId={setCoverageGroupId}
                  coverageGroupId={coverageGroupId}
                  showDependents={showDependents}
                  benefits={benefits}
                  country={country}
                  contractType={contractType}
                  coverageGroups={insuranceCoverageGroups ?? []}
                />
              </div>
              <FooterSection tw="z-[3]">
                <SectionActionBar
                  onCancelClick={modalCloseHandler}
                  submitLoading={loading}
                  tw="mx-auto"
                  disabled={!isEmpty(errors) || !isDirty}
                  primaryActionTooltip={tooltipContent}
                />
              </FooterSection>
            </DetailForm>
          </ContractReviewSectionModal>
        )}
      </FormProvider>
    </Accordion>
  );
};

export default InsuranceSectionV2;
