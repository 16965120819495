import { TFunction } from 'i18next';
import { capitalize } from 'lodash';

import { DropdownValue } from 'common/components/combo-box';

import {
  Contract,
  CountryLabourStandards,
  DayOfWeek,
  Maybe,
  WorkShiftInput,
  WorkingHours,
} from '__generated__/graphql';

export const weekDays = [
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
  DayOfWeek.SUNDAY,
];

export const getMeridianValueCorrespondingToHour = (
  hour: number,
  t: TFunction,
): string => {
  if (hour === 0 || hour === 24) return t('workshift.am', 'am');
  if (hour === 12) return t('workshift.pm', 'pm');
  if (hour > 12) return t('workshift.pm', 'pm');
  return t('workshift.am', 'am');
};

export const get12HourFormatFrom24hour = (hour: number): number => {
  if (hour % 12 === 0) return 12;
  return hour % 12;
};

export const workShiftOptions: WorkingHours[] = Array.from(
  Array(24).keys(),
).map((start) => ({
  startTime: `${String(start).padStart(2, '0')}:00:00`,
  endTime: `${String((start + 9) % 24).padStart(2, '0')}:00:00`,
}));

export const convertWorkShiftOptionToDropdownValue = (
  workingHours: Maybe<WorkingHours>,
): string => `${workingHours?.startTime}-${workingHours?.endTime}`;

export const convertDropdownValueToWorkShiftOption = (
  value: string,
): WorkingHours => {
  const shift = value.split('-');
  return {
    startTime: shift?.[0],
    endTime: shift?.[1],
  };
};

export const getWeekDayLabel = (day: DayOfWeek, t: TFunction): string =>
  t(`week-day-options.${day}`, capitalize(day.slice(0, 3)));

export const convert24HourTo12HourFormat = (
  time: string,
  t: TFunction,
): string => {
  const [hour24format, minute = 0, second = 0] = time.split(':').map(Number);
  const meridian = getMeridianValueCorrespondingToHour(hour24format, t);
  const hour = get12HourFormatFrom24hour(hour24format);
  let timeString = `${hour}`;
  if (minute || second) {
    timeString = `${timeString}:${String(minute).padStart(2, '0')}`;
    if (second) {
      timeString = `${timeString}:${String(second).padStart(2, '0')}`;
    }
  }
  return `${timeString} ${meridian}`;
};

const preprocessTimeString = (time: string) => {
  const [hour, minute = 0, second = 0] = time.split(':').map(Number);
  return `${String(hour).padStart(2, '0')}:${String(minute).padStart(
    2,
    '0',
  )}:${String(second).padStart(2, '0')}`;
};

const isTimeStringsEqual = (time1: string, time2: string) =>
  preprocessTimeString(time1) === preprocessTimeString(time2);

const isSameShift = (
  shiftOption1?: Maybe<WorkingHours>,
  shiftOption2?: Maybe<WorkingHours>,
) =>
  shiftOption1 &&
  shiftOption2 &&
  isTimeStringsEqual(shiftOption1?.startTime, shiftOption2?.startTime) &&
  isTimeStringsEqual(shiftOption1?.endTime, shiftOption2?.endTime);

const getWorkShiftLabel = (
  workShiftOption: WorkingHours,
  defaultWorkShift: Maybe<WorkingHours> | undefined,
  t: TFunction,
) =>
  `${convert24HourTo12HourFormat(
    workShiftOption.startTime,
    t,
  )} - ${convert24HourTo12HourFormat(workShiftOption.endTime, t)}${
    isSameShift(workShiftOption, defaultWorkShift)
      ? ` ${t('workshift-options.standard-shift', '(std. shift)')}`
      : ''
  }`;

export const getWorkShiftDropdownValues = (
  defaultWorkShift: Maybe<WorkingHours> | undefined,
  t: TFunction,
): DropdownValue[] =>
  workShiftOptions.reduce((options, option) => {
    const dropdownValue = {
      title: getWorkShiftLabel(option, defaultWorkShift, t),
      label: getWorkShiftLabel(option, defaultWorkShift, t),
      value: `${option.startTime}-${option.endTime}`,
    };
    if (isSameShift(option, defaultWorkShift))
      return [dropdownValue, ...options];
    return [...options, dropdownValue];
  }, [] as DropdownValue[]);

export const isValidTimeString = (val?: string): boolean =>
  /^([01]?\d|2[0-3]):([0-5]?\d):([0-5]?\d)$/.test(val ?? '');

export const getDefaultWorkShiftFromCountryWorkShiftStandards = (
  workshiftStandards?: CountryLabourStandards['workShift'],
): WorkShiftInput | undefined => {
  if (
    workshiftStandards?.endDay &&
    workshiftStandards?.startDay &&
    workshiftStandards?.defaultWorkingHours?.endTime &&
    workshiftStandards?.defaultWorkingHours?.startTime
  )
    return {
      endDay: workshiftStandards?.endDay,
      startDay: workshiftStandards?.startDay,
      workingHours: {
        endTime: preprocessTimeString(
          workshiftStandards?.defaultWorkingHours?.endTime,
        ),
        startTime: preprocessTimeString(
          workshiftStandards?.defaultWorkingHours?.startTime,
        ),
      },
    };
  return undefined;
};

const getWorkShiftInput = ({
  startDay,
  endDay,
  endTime,
  startTime,
}: {
  startDay: DayOfWeek;
  endDay: DayOfWeek;
  endTime: string;
  startTime: string;
}): WorkShiftInput => ({
  endDay,
  startDay,
  workingHours: {
    endTime: preprocessTimeString(endTime),
    startTime: preprocessTimeString(startTime),
  },
});

export const convertWorkShiftToWorkShiftInput = (
  workShift?: Contract['workShift'],
): WorkShiftInput | undefined => {
  if (
    workShift?.endDay &&
    workShift?.startDay &&
    workShift?.workingHours?.endTime &&
    workShift?.workingHours?.startTime
  )
    return getWorkShiftInput({
      endDay: workShift?.endDay,
      startDay: workShift?.startDay,
      endTime: workShift?.workingHours?.endTime,
      startTime: workShift?.workingHours?.startTime,
    });
  return undefined;
};

export const getDefaultWorkShiftStandards = (
  workShift?: CountryLabourStandards['workShift'],
): WorkShiftInput | undefined => {
  if (
    workShift?.startDay &&
    workShift?.endDay &&
    workShift?.defaultWorkingHours
  ) {
    return getWorkShiftInput({
      endDay: workShift?.endDay,
      startDay: workShift?.startDay,
      endTime: workShift?.defaultWorkingHours?.endTime,
      startTime: workShift?.defaultWorkingHours?.startTime,
    });
  }
  return undefined;
};
